/**
          * @ag-grid-enterprise/all-modules - Advanced Data Grid / Data Table supporting Javascript / Typescript / React / Angular / Vue * @version v31.0.2
          * @link https://www.ag-grid.com/
          * @license Commercial
          */
import { PreConstruct, Bean, BeanStub, Autowired, RefSelector, PostConstruct, Component, ModuleNames, Events, _, KeyCode, AgRichSelect, VirtualListDragFeature, DragSourceType, AgInputDateField, AgInputNumberField, AgInputTextField, TooltipFeature, DragAndDropService, TabGuardComp, VirtualList, AgDialog, AgAutocomplete, ChangedPath, Optional, CsvExportModule, EventService, Column, ProvidedColumnGroup, AgMenuList, AgMenuItemComponent, CssClassApplier, TouchListener, PreDestroy, RowNode, ManagedFocusFeature, PositionableFeature, AgSelect, AgPromise, ModuleRegistry, XmlFactory, ExcelFactoryMode, RowType, BaseGridSerializingSession, Downloader, BaseCreator, ZipContainer, GridSerializer, CsvCreator, AgGroupComponent, AutoScrollService, AgRadioButton, AgToggleButton, AgCheckbox, AgAbstractLabel, AgSlider, DEFAULT_CHART_GROUPS, TabbedLayout, CHART_TOOLBAR_ALLOW_LIST, CHART_TOOL_PANEL_ALLOW_LIST, CHART_TOOL_PANEL_MENU_OPTIONS, AgPanel, CellRangeType, CellCtrl, SelectionHandleType, AgPickerField, createGrid, ColumnApi, ProvidedFilter, PopupComponent, NumberSequence, RowNodeBlock, ServerSideTransactionResultStatus, RowNodeBlockLoader, ExpansionService, TextFilter, GROUP_AUTO_COLUMN_ID, AllCommunityModules } from 'ag-grid-community';
export * from 'ag-grid-community';

var MD5 = /** @class */ (function () {
    function MD5() {
        this.ieCompatibility = false;
    }
    MD5.prototype.init = function () {
        this.ieCompatibility = (this.md5('hello') != '5d41402abc4b2a76b9719d911017c592');
    };
    MD5.prototype.md5cycle = function (x, k) {
        var a = x[0], b = x[1], c = x[2], d = x[3];
        a = this.ff(a, b, c, d, k[0], 7, -680876936);
        d = this.ff(d, a, b, c, k[1], 12, -389564586);
        c = this.ff(c, d, a, b, k[2], 17, 606105819);
        b = this.ff(b, c, d, a, k[3], 22, -1044525330);
        a = this.ff(a, b, c, d, k[4], 7, -176418897);
        d = this.ff(d, a, b, c, k[5], 12, 1200080426);
        c = this.ff(c, d, a, b, k[6], 17, -1473231341);
        b = this.ff(b, c, d, a, k[7], 22, -45705983);
        a = this.ff(a, b, c, d, k[8], 7, 1770035416);
        d = this.ff(d, a, b, c, k[9], 12, -1958414417);
        c = this.ff(c, d, a, b, k[10], 17, -42063);
        b = this.ff(b, c, d, a, k[11], 22, -1990404162);
        a = this.ff(a, b, c, d, k[12], 7, 1804603682);
        d = this.ff(d, a, b, c, k[13], 12, -40341101);
        c = this.ff(c, d, a, b, k[14], 17, -1502002290);
        b = this.ff(b, c, d, a, k[15], 22, 1236535329);
        a = this.gg(a, b, c, d, k[1], 5, -165796510);
        d = this.gg(d, a, b, c, k[6], 9, -1069501632);
        c = this.gg(c, d, a, b, k[11], 14, 643717713);
        b = this.gg(b, c, d, a, k[0], 20, -373897302);
        a = this.gg(a, b, c, d, k[5], 5, -701558691);
        d = this.gg(d, a, b, c, k[10], 9, 38016083);
        c = this.gg(c, d, a, b, k[15], 14, -660478335);
        b = this.gg(b, c, d, a, k[4], 20, -405537848);
        a = this.gg(a, b, c, d, k[9], 5, 568446438);
        d = this.gg(d, a, b, c, k[14], 9, -1019803690);
        c = this.gg(c, d, a, b, k[3], 14, -187363961);
        b = this.gg(b, c, d, a, k[8], 20, 1163531501);
        a = this.gg(a, b, c, d, k[13], 5, -1444681467);
        d = this.gg(d, a, b, c, k[2], 9, -51403784);
        c = this.gg(c, d, a, b, k[7], 14, 1735328473);
        b = this.gg(b, c, d, a, k[12], 20, -1926607734);
        a = this.hh(a, b, c, d, k[5], 4, -378558);
        d = this.hh(d, a, b, c, k[8], 11, -2022574463);
        c = this.hh(c, d, a, b, k[11], 16, 1839030562);
        b = this.hh(b, c, d, a, k[14], 23, -35309556);
        a = this.hh(a, b, c, d, k[1], 4, -1530992060);
        d = this.hh(d, a, b, c, k[4], 11, 1272893353);
        c = this.hh(c, d, a, b, k[7], 16, -155497632);
        b = this.hh(b, c, d, a, k[10], 23, -1094730640);
        a = this.hh(a, b, c, d, k[13], 4, 681279174);
        d = this.hh(d, a, b, c, k[0], 11, -358537222);
        c = this.hh(c, d, a, b, k[3], 16, -722521979);
        b = this.hh(b, c, d, a, k[6], 23, 76029189);
        a = this.hh(a, b, c, d, k[9], 4, -640364487);
        d = this.hh(d, a, b, c, k[12], 11, -421815835);
        c = this.hh(c, d, a, b, k[15], 16, 530742520);
        b = this.hh(b, c, d, a, k[2], 23, -995338651);
        a = this.ii(a, b, c, d, k[0], 6, -198630844);
        d = this.ii(d, a, b, c, k[7], 10, 1126891415);
        c = this.ii(c, d, a, b, k[14], 15, -1416354905);
        b = this.ii(b, c, d, a, k[5], 21, -57434055);
        a = this.ii(a, b, c, d, k[12], 6, 1700485571);
        d = this.ii(d, a, b, c, k[3], 10, -1894986606);
        c = this.ii(c, d, a, b, k[10], 15, -1051523);
        b = this.ii(b, c, d, a, k[1], 21, -2054922799);
        a = this.ii(a, b, c, d, k[8], 6, 1873313359);
        d = this.ii(d, a, b, c, k[15], 10, -30611744);
        c = this.ii(c, d, a, b, k[6], 15, -1560198380);
        b = this.ii(b, c, d, a, k[13], 21, 1309151649);
        a = this.ii(a, b, c, d, k[4], 6, -145523070);
        d = this.ii(d, a, b, c, k[11], 10, -1120210379);
        c = this.ii(c, d, a, b, k[2], 15, 718787259);
        b = this.ii(b, c, d, a, k[9], 21, -343485551);
        x[0] = this.add32(a, x[0]);
        x[1] = this.add32(b, x[1]);
        x[2] = this.add32(c, x[2]);
        x[3] = this.add32(d, x[3]);
    };
    MD5.prototype.cmn = function (q, a, b, x, s, t) {
        a = this.add32(this.add32(a, q), this.add32(x, t));
        return this.add32((a << s) | (a >>> (32 - s)), b);
    };
    MD5.prototype.ff = function (a, b, c, d, x, s, t) {
        return this.cmn((b & c) | ((~b) & d), a, b, x, s, t);
    };
    MD5.prototype.gg = function (a, b, c, d, x, s, t) {
        return this.cmn((b & d) | (c & (~d)), a, b, x, s, t);
    };
    MD5.prototype.hh = function (a, b, c, d, x, s, t) {
        return this.cmn(b ^ c ^ d, a, b, x, s, t);
    };
    MD5.prototype.ii = function (a, b, c, d, x, s, t) {
        return this.cmn(c ^ (b | (~d)), a, b, x, s, t);
    };
    MD5.prototype.md51 = function (s) {
        var n = s.length;
        var state = [1732584193, -271733879, -1732584194, 271733878];
        var i;
        for (i = 64; i <= s.length; i += 64) {
            this.md5cycle(state, this.md5blk(s.substring(i - 64, i)));
        }
        s = s.substring(i - 64);
        var tail = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        for (i = 0; i < s.length; i++) {
            tail[i >> 2] |= s.charCodeAt(i) << ((i % 4) << 3);
        }
        tail[i >> 2] |= 0x80 << ((i % 4) << 3);
        if (i > 55) {
            this.md5cycle(state, tail);
            for (i = 0; i < 16; i++) {
                tail[i] = 0;
            }
        }
        tail[14] = n * 8;
        this.md5cycle(state, tail);
        return state;
    };
    /* there needs to be support for Unicode here, * unless we pretend that we can redefine the MD-5
     * algorithm for multi-byte characters (perhaps by adding every four 16-bit characters and
     * shortening the sum to 32 bits). Otherwise I suthis.ggest performing MD-5 as if every character
     * was two bytes--e.g., 0040 0025 = @%--but then how will an ordinary MD-5 sum be matched?
     * There is no way to standardize text to something like UTF-8 before transformation; speed cost is
     * utterly prohibitive. The JavaScript standard itself needs to look at this: it should start
     * providing access to strings as preformed UTF-8 8-bit unsigned value arrays.
     */
    MD5.prototype.md5blk = function (s) {
        var md5blks = [];
        /* Andy King said do it this way. */
        for (var i = 0; i < 64; i += 4) {
            md5blks[i >> 2] = s.charCodeAt(i)
                + (s.charCodeAt(i + 1) << 8)
                + (s.charCodeAt(i + 2) << 16)
                + (s.charCodeAt(i + 3) << 24);
        }
        return md5blks;
    };
    MD5.prototype.rhex = function (n) {
        var hex_chr = '0123456789abcdef'.split('');
        var s = '', j = 0;
        for (; j < 4; j++) {
            s += hex_chr[(n >> (j * 8 + 4)) & 0x0F]
                + hex_chr[(n >> (j * 8)) & 0x0F];
        }
        return s;
    };
    MD5.prototype.hex = function (x) {
        for (var i = 0; i < x.length; i++) {
            x[i] = this.rhex(x[i]);
        }
        return x.join('');
    };
    MD5.prototype.md5 = function (s) {
        return this.hex(this.md51(s));
    };
    MD5.prototype.add32 = function (a, b) {
        return this.ieCompatibility ? this.add32Compat(a, b) : this.add32Std(a, b);
    };
    /* this function is much faster, so if possible we use it. Some IEs are the only ones I know of that
     need the idiotic second function, generated by an if clause.  */
    MD5.prototype.add32Std = function (a, b) {
        return (a + b) & 0xFFFFFFFF;
    };
    MD5.prototype.add32Compat = function (x, y) {
        var lsw = (x & 0xFFFF) + (y & 0xFFFF), msw = (x >> 16) + (y >> 16) + (lsw >> 16);
        return (msw << 16) | (lsw & 0xFFFF);
    };
    return MD5;
}());

var __read$G = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
// move to general utils
function missingOrEmpty(value) {
    return value == null || value.length === 0;
}
function exists(value, allowEmptyString) {
    if (allowEmptyString === void 0) { allowEmptyString = false; }
    return value != null && (value !== '' || allowEmptyString);
}
var LICENSE_TYPES = {
    '01': 'GRID',
    '02': 'CHARTS',
    '0102': 'BOTH'
};
var LicenseManager = /** @class */ (function () {
    function LicenseManager(document) {
        this.watermarkMessage = undefined;
        this.document = document;
        this.md5 = new MD5();
        this.md5.init();
    }
    LicenseManager.prototype.validateLicense = function () {
        var licenseDetails = this.getLicenseDetails(LicenseManager.licenseKey);
        if (licenseDetails.missing) {
            if (!this.isWebsiteUrl() || this.isForceWatermark()) {
                this.outputMissingLicenseKey();
            }
        }
        else if (!licenseDetails.valid) {
            this.outputInvalidLicenseKey(licenseDetails.incorrectLicenseType, licenseDetails.licenseType);
        }
        else if (licenseDetails.isTrial && licenseDetails.trialExpired) {
            this.outputExpiredTrialKey(licenseDetails.expiry);
        }
        else if (licenseDetails.expired) {
            var gridReleaseDate = LicenseManager.getGridReleaseDate();
            var formattedReleaseDate = LicenseManager.formatDate(gridReleaseDate);
            this.outputIncompatibleVersion(licenseDetails.expiry, formattedReleaseDate);
        }
    };
    LicenseManager.extractExpiry = function (license) {
        var restrictionHashed = license.substring(license.lastIndexOf('_') + 1, license.length);
        return new Date(parseInt(LicenseManager.decode(restrictionHashed), 10));
    };
    LicenseManager.extractLicenseComponents = function (licenseKey) {
        // when users copy the license key from a PDF extra zero width characters are sometimes copied too
        // carriage returns and line feeds are problematic too
        // all of which causes license key validation to fail - strip these out
        var cleanedLicenseKey = licenseKey.replace(/[\u200B-\u200D\uFEFF]/g, '');
        cleanedLicenseKey = cleanedLicenseKey.replace(/\r?\n|\r/g, '');
        // the hash that follows the key is 32 chars long
        if (licenseKey.length <= 32) {
            return { md5: null, license: licenseKey, version: null, isTrial: null };
        }
        var hashStart = cleanedLicenseKey.length - 32;
        var md5 = cleanedLicenseKey.substring(hashStart);
        var license = cleanedLicenseKey.substring(0, hashStart);
        var _a = __read$G(LicenseManager.extractBracketedInformation(cleanedLicenseKey), 3), version = _a[0], isTrial = _a[1], type = _a[2];
        return { md5: md5, license: license, version: version, isTrial: isTrial, type: type };
    };
    LicenseManager.prototype.getLicenseDetails = function (licenseKey) {
        if (missingOrEmpty(licenseKey)) {
            return {
                licenseKey: licenseKey,
                valid: false,
                missing: true
            };
        }
        var gridReleaseDate = LicenseManager.getGridReleaseDate();
        var _a = LicenseManager.extractLicenseComponents(licenseKey), md5 = _a.md5, license = _a.license, version = _a.version, isTrial = _a.isTrial, type = _a.type;
        var valid = (md5 === this.md5.md5(license)) && licenseKey.indexOf("For_Trialing_ag-Grid_Only") === -1;
        var trialExpired = undefined;
        var expired = undefined;
        var expiry = null;
        var incorrectLicenseType = undefined;
        var licenseType = undefined;
        function handleTrial() {
            var now = new Date();
            trialExpired = (expiry < now);
            expired = undefined;
        }
        if (valid) {
            expiry = LicenseManager.extractExpiry(license);
            valid = !isNaN(expiry.getTime());
            if (valid) {
                expired = (gridReleaseDate > expiry);
                switch (version) {
                    case "legacy":
                    case "2": {
                        if (isTrial) {
                            handleTrial();
                        }
                        break;
                    }
                    case "3": {
                        if (missingOrEmpty(type)) {
                            valid = false;
                        }
                        else {
                            if (type !== LICENSE_TYPES['01'] && type !== LICENSE_TYPES['0102']) {
                                valid = false;
                                incorrectLicenseType = true;
                                licenseType = type;
                            }
                            else if (isTrial) {
                                handleTrial();
                            }
                        }
                    }
                }
            }
        }
        if (!valid) {
            return {
                licenseKey: licenseKey,
                valid: valid,
                incorrectLicenseType: incorrectLicenseType,
                licenseType: licenseType
            };
        }
        return {
            licenseKey: licenseKey,
            valid: valid,
            expiry: LicenseManager.formatDate(expiry),
            expired: expired,
            version: version,
            isTrial: isTrial,
            trialExpired: trialExpired
        };
    };
    LicenseManager.prototype.isDisplayWatermark = function () {
        return this.isForceWatermark() || (!this.isLocalhost() && !this.isWebsiteUrl() && !missingOrEmpty(this.watermarkMessage));
    };
    LicenseManager.prototype.getWatermarkMessage = function () {
        return this.watermarkMessage || '';
    };
    LicenseManager.prototype.getHostname = function () {
        var win = (this.document.defaultView || window);
        var loc = win.location;
        var _a = loc.hostname, hostname = _a === void 0 ? '' : _a;
        return hostname;
    };
    LicenseManager.prototype.isForceWatermark = function () {
        var win = (this.document.defaultView || window);
        var loc = win.location;
        var pathname = loc.pathname;
        return pathname ? pathname.indexOf('forceWatermark') !== -1 : false;
    };
    LicenseManager.prototype.isWebsiteUrl = function () {
        var hostname = this.getHostname();
        return hostname.match(/^((?:\w+\.)?ag-grid\.com)$/) !== null;
    };
    LicenseManager.prototype.isLocalhost = function () {
        var hostname = this.getHostname();
        return hostname.match(/^(?:127\.0\.0\.1|localhost)$/) !== null;
    };
    LicenseManager.formatDate = function (date) {
        var monthNames = [
            'January', 'February', 'March',
            'April', 'May', 'June', 'July',
            'August', 'September', 'October',
            'November', 'December'
        ];
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
        return day + ' ' + monthNames[monthIndex] + ' ' + year;
    };
    LicenseManager.getGridReleaseDate = function () {
        return new Date(parseInt(LicenseManager.decode(LicenseManager.RELEASE_INFORMATION), 10));
    };
    LicenseManager.decode = function (input) {
        var keystr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
        var t = '';
        var n, r, i;
        var s, o, u, a;
        var f = 0;
        var e = input.replace(/[^A-Za-z0-9+/=]/g, '');
        while (f < e.length) {
            s = keystr.indexOf(e.charAt(f++));
            o = keystr.indexOf(e.charAt(f++));
            u = keystr.indexOf(e.charAt(f++));
            a = keystr.indexOf(e.charAt(f++));
            n = s << 2 | o >> 4;
            r = (o & 15) << 4 | u >> 2;
            i = (u & 3) << 6 | a;
            t = t + String.fromCharCode(n);
            if (u != 64) {
                t = t + String.fromCharCode(r);
            }
            if (a != 64) {
                t = t + String.fromCharCode(i);
            }
        }
        t = LicenseManager.utf8_decode(t);
        return t;
    };
    LicenseManager.utf8_decode = function (input) {
        input = input.replace(/rn/g, 'n');
        var t = '';
        for (var n = 0; n < input.length; n++) {
            var r = input.charCodeAt(n);
            if (r < 128) {
                t += String.fromCharCode(r);
            }
            else if (r > 127 && r < 2048) {
                t += String.fromCharCode(r >> 6 | 192);
                t += String.fromCharCode(r & 63 | 128);
            }
            else {
                t += String.fromCharCode(r >> 12 | 224);
                t += String.fromCharCode(r >> 6 & 63 | 128);
                t += String.fromCharCode(r & 63 | 128);
            }
        }
        return t;
    };
    LicenseManager.setLicenseKey = function (licenseKey) {
        this.licenseKey = licenseKey;
    };
    LicenseManager.extractBracketedInformation = function (licenseKey) {
        // legacy no trial key
        if (!licenseKey.includes("[")) {
            return ["legacy", false, undefined];
        }
        var matches = licenseKey.match(/\[(.*?)\]/g).map(function (match) { return match.replace("[", "").replace("]", ""); });
        if (!matches || matches.length === 0) {
            return ["legacy", false, undefined];
        }
        var isTrial = matches.filter(function (match) { return match === 'TRIAL'; }).length === 1;
        var rawVersion = matches.filter(function (match) { return match.indexOf("v") === 0; })[0];
        var version = rawVersion ? rawVersion.replace('v', '') : 'legacy';
        var type = LICENSE_TYPES[matches.filter(function (match) { return LICENSE_TYPES[match]; })[0]];
        return [version, isTrial, type];
    };
    LicenseManager.prototype.outputInvalidLicenseKey = function (incorrectLicenseType, licenseType) {
        console.error('*****************************************************************************************************************');
        console.error('***************************************** AG Grid Enterprise License ********************************************');
        console.error('********************************************* Invalid License ***************************************************');
        if (exists(incorrectLicenseType) && incorrectLicenseType && licenseType === 'CHARTS') {
            console.error('* The license supplied is for AG Charts Enterprise Only and does not cover AG Grid Enterprise                   *');
        }
        console.error('* Your license for AG Grid Enterprise is not valid - please contact info@ag-grid.com to obtain a valid license. *');
        console.error('*****************************************************************************************************************');
        console.error('*****************************************************************************************************************');
        this.watermarkMessage = "Invalid License";
    };
    LicenseManager.prototype.outputExpiredTrialKey = function (formattedExpiryDate) {
        console.error('****************************************************************************************************************');
        console.error('***************************************** AG Grid Enterprise License *******************************************');
        console.error('*****************************************   Trial Period Expired.    *******************************************');
        console.error("* Your license for AG Grid Enterprise expired on ".concat(formattedExpiryDate, ".                                                *"));
        console.error('* Please email info@ag-grid.com to purchase a license.                                                         *');
        console.error('****************************************************************************************************************');
        console.error('****************************************************************************************************************');
        this.watermarkMessage = "Trial Period Expired";
    };
    LicenseManager.prototype.outputMissingLicenseKey = function () {
        console.error('****************************************************************************************************************');
        console.error('***************************************** AG Grid Enterprise License *******************************************');
        console.error('****************************************** License Key Not Found ***********************************************');
        console.error('* All AG Grid Enterprise features are unlocked.                                                                *');
        console.error('* This is an evaluation only version, it is not licensed for development projects intended for production.     *');
        console.error('* If you want to hide the watermark, please email info@ag-grid.com for a trial license.                        *');
        console.error('****************************************************************************************************************');
        console.error('****************************************************************************************************************');
        this.watermarkMessage = "For Trial Use Only";
    };
    LicenseManager.prototype.outputIncompatibleVersion = function (formattedExpiryDate, formattedReleaseDate) {
        console.error('****************************************************************************************************************************');
        console.error('****************************************************************************************************************************');
        console.error('*                                             AG Grid Enterprise License                                                   *');
        console.error('*                           License not compatible with installed version of AG Grid Enterprise.                           *');
        console.error('*                                                                                                                          *');
        console.error("* Your AG Grid License entitles you to all versions of AG Grid that we release within the time covered by your license     *");
        console.error("* - typically we provide one year licenses which entitles you to all releases / updates of AG Grid within that year.       *");
        console.error("* Your license has an end (expiry) date which stops the license key working with versions of AG Grid released after the    *");
        console.error("* license end date. The license key that you have expires on ".concat(formattedExpiryDate, ", however the version of AG Grid you    *"));
        console.error("* are trying to use was released on ".concat(formattedReleaseDate, ".                                                               *"));
        console.error('*                                                                                                                          *');
        console.error('* Please contact info@ag-grid.com to renew your subscription to new versions and get a new license key to work with this   *');
        console.error('* version of AG Grid.                                                                                                      *');
        console.error('****************************************************************************************************************************');
        console.error('****************************************************************************************************************************');
        this.watermarkMessage = "License Expired";
    };
    LicenseManager.RELEASE_INFORMATION = 'MTcwNTIyNzE3MTAzNg==';
    return LicenseManager;
}());

var __extends$2z = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$20 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var GridLicenseManager = /** @class */ (function (_super) {
    __extends$2z(GridLicenseManager, _super);
    function GridLicenseManager() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GridLicenseManager.prototype.validateLicense = function () {
        this.licenseManager = new LicenseManager(this.gridOptionsService.getDocument());
        this.licenseManager.validateLicense();
    };
    GridLicenseManager.getLicenseDetails = function (licenseKey) {
        return new LicenseManager(null).getLicenseDetails(licenseKey);
    };
    GridLicenseManager.prototype.isDisplayWatermark = function () {
        return this.licenseManager.isDisplayWatermark();
    };
    GridLicenseManager.prototype.getWatermarkMessage = function () {
        return this.licenseManager.getWatermarkMessage();
    };
    GridLicenseManager.setLicenseKey = function (licenseKey) {
        LicenseManager.setLicenseKey(licenseKey);
    };
    __decorate$20([
        PreConstruct
    ], GridLicenseManager.prototype, "validateLicense", null);
    GridLicenseManager = __decorate$20([
        Bean('licenseManager')
    ], GridLicenseManager);
    return GridLicenseManager;
}(BeanStub));

var __extends$2y = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1$ = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var WatermarkComp = /** @class */ (function (_super) {
    __extends$2y(WatermarkComp, _super);
    function WatermarkComp() {
        return _super.call(this, /* html*/ "<div class=\"ag-watermark\">\n                <div ref=\"eLicenseTextRef\" class=\"ag-watermark-text\"></div>\n            </div>") || this;
    }
    WatermarkComp.prototype.postConstruct = function () {
        var _this = this;
        var show = this.shouldDisplayWatermark();
        this.setDisplayed(show);
        if (show) {
            this.eLicenseTextRef.innerText = this.licenseManager.getWatermarkMessage();
            window.setTimeout(function () { return _this.addCssClass('ag-opacity-zero'); }, 0);
            window.setTimeout(function () { return _this.setDisplayed(false); }, 5000);
        }
    };
    WatermarkComp.prototype.shouldDisplayWatermark = function () {
        return this.licenseManager.isDisplayWatermark();
    };
    __decorate$1$([
        Autowired('licenseManager')
    ], WatermarkComp.prototype, "licenseManager", void 0);
    __decorate$1$([
        RefSelector('eLicenseTextRef')
    ], WatermarkComp.prototype, "eLicenseTextRef", void 0);
    __decorate$1$([
        PostConstruct
    ], WatermarkComp.prototype, "postConstruct", null);
    return WatermarkComp;
}(Component));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$j = '31.0.2';

var EnterpriseCoreModule = {
    version: VERSION$j,
    moduleName: ModuleNames.EnterpriseCoreModule,
    beans: [GridLicenseManager],
    agStackComponents: [
        { componentName: 'AgWatermark', componentClass: WatermarkComp }
    ]
};

var __extends$2x = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1_ = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AdvancedFilterHeaderComp = /** @class */ (function (_super) {
    __extends$2x(AdvancedFilterHeaderComp, _super);
    function AdvancedFilterHeaderComp(enabled) {
        var _this = _super.call(this, /* html */ "\n            <div class=\"ag-advanced-filter-header\" role=\"row\">\n            </div>") || this;
        _this.enabled = enabled;
        return _this;
    }
    AdvancedFilterHeaderComp.prototype.postConstruct = function () {
        var _this = this;
        this.setupAdvancedFilter(this.enabled);
        this.addDestroyFunc(function () { return _this.destroyBean(_this.eAdvancedFilter); });
        this.addManagedListener(this.eventService, Events.EVENT_GRID_COLUMNS_CHANGED, function () { return _this.onGridColumnsChanged(); });
        this.addGuiEventListener('keydown', function (event) { return _this.onKeyDown(event); });
        this.addGuiEventListener('focusout', function (event) {
            if (!_this.getFocusableElement().contains(event.relatedTarget)) {
                _this.focusService.clearAdvancedFilterColumn();
            }
        });
    };
    AdvancedFilterHeaderComp.prototype.getFocusableElement = function () {
        var _a, _b;
        return (_b = (_a = this.eAdvancedFilter) === null || _a === void 0 ? void 0 : _a.getGui()) !== null && _b !== void 0 ? _b : this.getGui();
    };
    AdvancedFilterHeaderComp.prototype.setEnabled = function (enabled) {
        if (enabled === this.enabled) {
            return;
        }
        this.setupAdvancedFilter(enabled);
    };
    AdvancedFilterHeaderComp.prototype.refresh = function () {
        var _a;
        (_a = this.eAdvancedFilter) === null || _a === void 0 ? void 0 : _a.refresh();
    };
    AdvancedFilterHeaderComp.prototype.getHeight = function () {
        return this.height;
    };
    AdvancedFilterHeaderComp.prototype.setInputDisabled = function (disabled) {
        var _a;
        (_a = this.eAdvancedFilter) === null || _a === void 0 ? void 0 : _a.setInputDisabled(disabled);
    };
    AdvancedFilterHeaderComp.prototype.setupAdvancedFilter = function (enabled) {
        var eGui = this.getGui();
        if (enabled) {
            // unmanaged as can be recreated
            this.eAdvancedFilter = this.createBean(new AdvancedFilterComp());
            var eAdvancedFilterGui = this.eAdvancedFilter.getGui();
            this.eAdvancedFilter.addCssClass('ag-advanced-filter-header-cell');
            this.height = this.columnModel.getFloatingFiltersHeight();
            var height = "".concat(this.height, "px");
            eGui.style.height = height;
            eGui.style.minHeight = height;
            this.setAriaRowIndex();
            _.setAriaRole(eAdvancedFilterGui, 'gridcell');
            _.setAriaColIndex(eAdvancedFilterGui, 1);
            this.setAriaColumnCount(eAdvancedFilterGui);
            eGui.appendChild(eAdvancedFilterGui);
        }
        else {
            _.clearElement(eGui);
            this.destroyBean(this.eAdvancedFilter);
            this.height = 0;
        }
        _.setDisplayed(eGui, enabled);
        this.enabled = enabled;
    };
    AdvancedFilterHeaderComp.prototype.setAriaColumnCount = function (eAdvancedFilterGui) {
        _.setAriaColSpan(eAdvancedFilterGui, this.columnModel.getAllGridColumns().length);
    };
    AdvancedFilterHeaderComp.prototype.setAriaRowIndex = function () {
        _.setAriaRowIndex(this.getGui(), this.headerNavigationService.getHeaderRowCount());
    };
    AdvancedFilterHeaderComp.prototype.onGridColumnsChanged = function () {
        if (!this.eAdvancedFilter) {
            return;
        }
        this.setAriaColumnCount(this.eAdvancedFilter.getGui());
        this.setAriaRowIndex();
    };
    AdvancedFilterHeaderComp.prototype.onKeyDown = function (event) {
        switch (event.key) {
            case KeyCode.ENTER: {
                if (this.hasFocus()) {
                    if (this.focusService.focusInto(this.getFocusableElement())) {
                        event.preventDefault();
                    }
                }
                break;
            }
            case KeyCode.ESCAPE:
                if (!this.hasFocus()) {
                    this.getFocusableElement().focus();
                }
                break;
            case KeyCode.UP:
                this.navigateUpDown(true, event);
                break;
            case KeyCode.DOWN:
                this.navigateUpDown(false, event);
                break;
            case KeyCode.TAB:
                if (this.hasFocus()) {
                    this.navigateLeftRight(event);
                }
                else {
                    var nextFocusableEl = this.focusService.findNextFocusableElement(this.getFocusableElement(), null, event.shiftKey);
                    if (nextFocusableEl) {
                        event.preventDefault();
                        nextFocusableEl.focus();
                    }
                    else {
                        this.navigateLeftRight(event);
                    }
                }
                break;
        }
    };
    AdvancedFilterHeaderComp.prototype.navigateUpDown = function (backwards, event) {
        if (this.hasFocus()) {
            if (this.focusService.focusNextFromAdvancedFilter(backwards)) {
                event.preventDefault();
            }
        }
    };
    AdvancedFilterHeaderComp.prototype.navigateLeftRight = function (event) {
        if (event.shiftKey
            ? this.focusService.focusLastHeader()
            : this.focusService.focusNextFromAdvancedFilter(false, true)) {
            event.preventDefault();
        }
    };
    AdvancedFilterHeaderComp.prototype.hasFocus = function () {
        var eDocument = this.gridOptionsService.getDocument();
        return eDocument.activeElement === this.getFocusableElement();
    };
    __decorate$1_([
        Autowired('columnModel')
    ], AdvancedFilterHeaderComp.prototype, "columnModel", void 0);
    __decorate$1_([
        Autowired('focusService')
    ], AdvancedFilterHeaderComp.prototype, "focusService", void 0);
    __decorate$1_([
        Autowired('headerNavigationService')
    ], AdvancedFilterHeaderComp.prototype, "headerNavigationService", void 0);
    __decorate$1_([
        PostConstruct
    ], AdvancedFilterHeaderComp.prototype, "postConstruct", null);
    return AdvancedFilterHeaderComp;
}(Component));

var __extends$2w = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$A = (undefined && undefined.__assign) || function () {
    __assign$A = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$A.apply(this, arguments);
};
var AddDropdownComp = /** @class */ (function (_super) {
    __extends$2w(AddDropdownComp, _super);
    function AddDropdownComp(params) {
        var _this = _super.call(this, __assign$A(__assign$A({}, params), { template: /* html */ "\n                <div class=\"ag-picker-field\" role=\"presentation\">\n                    <div ref=\"eLabel\"></div>\n                    <div ref=\"eWrapper\" class=\"ag-wrapper ag-picker-collapsed\">\n                        <div ref=\"eDisplayField\" class=\"ag-picker-field-display\"></div>\n                        <ag-input-text-field ref=\"eInput\" class=\"ag-rich-select-field-input\"></ag-input-text-field>\n                        <div ref=\"eIcon\" class=\"ag-picker-field-icon\" aria-hidden=\"true\"></div>\n                    </div>\n                </div>" })) || this;
        _this.params = params;
        return _this;
    }
    AddDropdownComp.prototype.showPicker = function () {
        var _this = this;
        // avoid focus handling issues with multiple rich selects
        setTimeout(function () { return _super.prototype.showPicker.call(_this); });
    };
    AddDropdownComp.prototype.hidePicker = function () {
        var _this = this;
        // avoid focus handling issues with multiple rich selects
        setTimeout(function () { return _super.prototype.hidePicker.call(_this); });
    };
    AddDropdownComp.prototype.postConstruct = function () {
        _super.prototype.postConstruct.call(this);
        var _a = this.params, wrapperClassName = _a.wrapperClassName, ariaLabel = _a.ariaLabel;
        _.setDisplayed(this.eDisplayField, false);
        if (wrapperClassName) {
            this.eWrapper.classList.add(wrapperClassName);
        }
        _.setAriaLabelledBy(this.eWrapper, '');
        _.setAriaLabel(this.eWrapper, ariaLabel);
    };
    AddDropdownComp.prototype.onEnterKeyDown = function (event) {
        _.stopPropagationForAgGrid(event);
        if (this.isPickerDisplayed) {
            _super.prototype.onEnterKeyDown.call(this, event);
        }
        else {
            event.preventDefault();
            this.showPicker();
        }
    };
    return AddDropdownComp;
}(AgRichSelect));

var __extends$2v = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1Z = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AdvancedFilterBuilderDragFeature = /** @class */ (function (_super) {
    __extends$2v(AdvancedFilterBuilderDragFeature, _super);
    function AdvancedFilterBuilderDragFeature(comp, virtualList) {
        var _this = _super.call(this) || this;
        _this.comp = comp;
        _this.virtualList = virtualList;
        return _this;
    }
    AdvancedFilterBuilderDragFeature.prototype.postConstruct = function () {
        var _this = this;
        this.createManagedBean(new VirtualListDragFeature(this.comp, this.virtualList, {
            dragSourceType: DragSourceType.AdvancedFilterBuilder,
            listItemDragStartEvent: AdvancedFilterBuilderDragFeature.EVENT_DRAG_STARTED,
            listItemDragEndEvent: AdvancedFilterBuilderDragFeature.EVENT_DRAG_ENDED,
            eventSource: this,
            getCurrentDragValue: function (listItemDragStartEvent) { return _this.getCurrentDragValue(listItemDragStartEvent); },
            isMoveBlocked: function () { return false; },
            getNumRows: function (comp) { return comp.getNumItems(); },
            moveItem: function (currentDragValue, lastHoveredListItem) { return _this.moveItem(currentDragValue, lastHoveredListItem); }
        }));
    };
    AdvancedFilterBuilderDragFeature.prototype.getCurrentDragValue = function (listItemDragStartEvent) {
        return listItemDragStartEvent.item;
    };
    AdvancedFilterBuilderDragFeature.prototype.moveItem = function (currentDragValue, lastHoveredListItem) {
        this.comp.moveItem(currentDragValue, lastHoveredListItem);
    };
    AdvancedFilterBuilderDragFeature.EVENT_DRAG_STARTED = 'advancedFilterBuilderDragStarted';
    AdvancedFilterBuilderDragFeature.EVENT_DRAG_ENDED = 'advancedFilterBuilderDragEnded';
    __decorate$1Z([
        PostConstruct
    ], AdvancedFilterBuilderDragFeature.prototype, "postConstruct", null);
    return AdvancedFilterBuilderDragFeature;
}(BeanStub));

var __extends$2u = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1Y = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AdvancedFilterBuilderItemNavigationFeature = /** @class */ (function (_super) {
    __extends$2u(AdvancedFilterBuilderItemNavigationFeature, _super);
    function AdvancedFilterBuilderItemNavigationFeature(eGui, focusWrapper, eFocusableComp) {
        var _this = _super.call(this) || this;
        _this.eGui = eGui;
        _this.focusWrapper = focusWrapper;
        _this.eFocusableComp = eFocusableComp;
        return _this;
    }
    AdvancedFilterBuilderItemNavigationFeature.prototype.postConstruct = function () {
        var _this = this;
        this.addManagedListener(this.eGui, 'keydown', function (event) {
            switch (event.key) {
                case KeyCode.TAB:
                    if (!event.defaultPrevented) {
                        // tab guard handled the navigation. stop from reaching virtual list
                        _.stopPropagationForAgGrid(event);
                    }
                    break;
                case KeyCode.UP:
                case KeyCode.DOWN:
                    // if this hasn't been handled by an editor, prevent virtual list navigation
                    _.stopPropagationForAgGrid(event);
                    break;
                case KeyCode.ESCAPE:
                    if (_.isStopPropagationForAgGrid(event)) {
                        return;
                    }
                    var eDocument = _this.gridOptionsService.getDocument();
                    if (_this.eGui.contains(eDocument.activeElement)) {
                        event.preventDefault();
                        _.stopPropagationForAgGrid(event);
                        _this.focusWrapper.focus();
                    }
                    break;
            }
        });
        this.addManagedListener(this.focusWrapper, 'keydown', function (event) {
            switch (event.key) {
                case KeyCode.ENTER:
                    if (_.isStopPropagationForAgGrid(event)) {
                        return;
                    }
                    var eDocument = _this.gridOptionsService.getDocument();
                    if (eDocument.activeElement === _this.focusWrapper) {
                        event.preventDefault();
                        _.stopPropagationForAgGrid(event);
                        _this.eFocusableComp.getFocusableElement().focus();
                    }
                    break;
            }
        });
        this.addManagedListener(this.focusWrapper, 'focusin', function () {
            _this.focusWrapper.classList.add('ag-advanced-filter-builder-virtual-list-item-highlight');
        });
        this.addManagedListener(this.focusWrapper, 'focusout', function (event) {
            if (!_this.focusWrapper.contains(event.relatedTarget)) {
                _this.focusWrapper.classList.remove('ag-advanced-filter-builder-virtual-list-item-highlight');
            }
        });
    };
    __decorate$1Y([
        PostConstruct
    ], AdvancedFilterBuilderItemNavigationFeature.prototype, "postConstruct", null);
    return AdvancedFilterBuilderItemNavigationFeature;
}(BeanStub));

function getAdvancedFilterBuilderAddButtonParams(translate, maxPickerWidth) {
    return {
        pickerAriaLabelKey: 'ariaLabelAdvancedFilterBuilderAddField',
        pickerAriaLabelValue: 'Advanced Filter Builder Add Field',
        pickerType: 'ag-list',
        valueList: [{
                key: 'condition',
                displayValue: translate('advancedFilterBuilderAddCondition')
            }, {
                key: 'join',
                displayValue: translate('advancedFilterBuilderAddJoin')
            }],
        valueFormatter: function (value) { var _a; return value == null ? null : (_a = value.displayValue) !== null && _a !== void 0 ? _a : value.key; },
        pickerIcon: 'advancedFilterBuilderAdd',
        maxPickerWidth: "".concat(maxPickerWidth !== null && maxPickerWidth !== void 0 ? maxPickerWidth : 120, "px"),
        wrapperClassName: 'ag-advanced-filter-builder-item-button',
        ariaLabel: translate('advancedFilterBuilderAddButtonTooltip')
    };
}

var AdvancedFilterBuilderEvents = /** @class */ (function () {
    function AdvancedFilterBuilderEvents() {
    }
    AdvancedFilterBuilderEvents.EVENT_ADDED = 'advancedFilterBuilderAdded';
    AdvancedFilterBuilderEvents.EVENT_MOVED = 'advancedFilterBuilderMoved';
    AdvancedFilterBuilderEvents.EVENT_REMOVED = 'advancedFilterBuilderRemoved';
    AdvancedFilterBuilderEvents.EVENT_VALUE_CHANGED = 'advancedFilterBuilderValueChanged';
    AdvancedFilterBuilderEvents.EVENT_VALID_CHANGED = 'advancedFilterBuilderValidChanged';
    return AdvancedFilterBuilderEvents;
}());

var __extends$2t = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1X = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ConditionPillWrapperComp = /** @class */ (function (_super) {
    __extends$2t(ConditionPillWrapperComp, _super);
    function ConditionPillWrapperComp() {
        var _this = _super.call(this, /* html */ "\n            <div class=\"ag-advanced-filter-builder-item-condition\" role=\"presentation\"></div>\n        ") || this;
        _this.validationMessage = null;
        return _this;
    }
    ConditionPillWrapperComp.prototype.init = function (params) {
        var _this = this;
        var item = params.item, createPill = params.createPill;
        this.item = item;
        this.createPill = createPill;
        this.filterModel = item.filterModel;
        this.setupColumnCondition(this.filterModel);
        this.validate();
        this.addDestroyFunc(function () { return _this.destroyBeans([_this.eColumnPill, _this.eOperatorPill, _this.eOperandPill]); });
    };
    ConditionPillWrapperComp.prototype.getDragName = function () {
        return this.filterModel.colId
            ? this.advancedFilterExpressionService.parseColumnFilterModel(this.filterModel)
            : this.getDefaultColumnDisplayValue();
    };
    ConditionPillWrapperComp.prototype.getAriaLabel = function () {
        return "".concat(this.advancedFilterExpressionService.translate('ariaAdvancedFilterBuilderFilterItem'), " ").concat(this.getDragName());
    };
    ConditionPillWrapperComp.prototype.getValidationMessage = function () {
        return this.validationMessage;
    };
    ConditionPillWrapperComp.prototype.getFocusableElement = function () {
        return this.eColumnPill.getFocusableElement();
    };
    ConditionPillWrapperComp.prototype.setupColumnCondition = function (filterModel) {
        var _this = this;
        var _a;
        var columnDetails = this.advancedFilterExpressionService.getColumnDetails(filterModel.colId);
        this.baseCellDataType = columnDetails.baseCellDataType;
        this.column = columnDetails.column;
        this.numOperands = this.getNumOperands(this.getOperatorKey());
        this.eColumnPill = this.createPill({
            key: this.getColumnKey(),
            displayValue: (_a = this.getColumnDisplayValue()) !== null && _a !== void 0 ? _a : this.getDefaultColumnDisplayValue(),
            cssClass: 'ag-advanced-filter-builder-column-pill',
            isSelect: true,
            getEditorParams: function () { return ({ values: _this.advancedFilterExpressionService.getColumnAutocompleteEntries() }); },
            update: function (key) { return _this.setColumnKey(key); },
            pickerAriaLabelKey: 'ariaLabelAdvancedFilterBuilderColumnSelectField',
            pickerAriaLabelValue: 'Advanced Filter Builder Column Select Field',
            ariaLabel: this.advancedFilterExpressionService.translate('ariaAdvancedFilterBuilderColumn')
        });
        this.getGui().appendChild(this.eColumnPill.getGui());
        if (_.exists(this.getColumnKey())) {
            this.createOperatorPill();
            if (this.hasOperand()) {
                this.createOperandPill();
            }
        }
    };
    ConditionPillWrapperComp.prototype.createOperatorPill = function () {
        var _this = this;
        var _a;
        this.eOperatorPill = this.createPill({
            key: this.getOperatorKey(),
            displayValue: (_a = this.getOperatorDisplayValue()) !== null && _a !== void 0 ? _a : this.getDefaultOptionSelectValue(),
            cssClass: 'ag-advanced-filter-builder-option-pill',
            isSelect: true,
            getEditorParams: function () { return ({ values: _this.getOperatorAutocompleteEntries() }); },
            update: function (key) { return _this.setOperatorKey(key); },
            pickerAriaLabelKey: 'ariaLabelAdvancedFilterBuilderOptionSelectField',
            pickerAriaLabelValue: 'Advanced Filter Builder Option Select Field',
            ariaLabel: this.advancedFilterExpressionService.translate('ariaAdvancedFilterBuilderOption')
        });
        this.eColumnPill.getGui().insertAdjacentElement('afterend', this.eOperatorPill.getGui());
    };
    ConditionPillWrapperComp.prototype.createOperandPill = function () {
        var _this = this;
        var _a;
        var key = (_a = this.getOperandDisplayValue()) !== null && _a !== void 0 ? _a : '';
        this.eOperandPill = this.createPill({
            key: key,
            displayValue: key,
            baseCellDataType: this.baseCellDataType,
            cssClass: 'ag-advanced-filter-builder-value-pill',
            isSelect: false,
            update: function (key) { return _this.setOperand(key); },
            ariaLabel: this.advancedFilterExpressionService.translate('ariaAdvancedFilterBuilderValue')
        });
        this.getGui().appendChild(this.eOperandPill.getGui());
    };
    ConditionPillWrapperComp.prototype.getColumnKey = function () {
        return this.filterModel.colId;
    };
    ConditionPillWrapperComp.prototype.getColumnDisplayValue = function () {
        return this.advancedFilterExpressionService.getColumnDisplayValue(this.filterModel);
    };
    ConditionPillWrapperComp.prototype.getOperatorKey = function () {
        return this.filterModel.type;
    };
    ConditionPillWrapperComp.prototype.getOperatorDisplayValue = function () {
        return this.advancedFilterExpressionService.getOperatorDisplayValue(this.filterModel);
    };
    ConditionPillWrapperComp.prototype.getOperandDisplayValue = function () {
        return this.advancedFilterExpressionService.getOperandDisplayValue(this.filterModel, true);
    };
    ConditionPillWrapperComp.prototype.hasOperand = function () {
        return this.numOperands > 0;
    };
    ConditionPillWrapperComp.prototype.getOperatorAutocompleteEntries = function () {
        return this.column
            ? this.advancedFilterExpressionService.getOperatorAutocompleteEntries(this.column, this.baseCellDataType)
            : [];
    };
    ConditionPillWrapperComp.prototype.setColumnKey = function (colId) {
        if (!this.eOperatorPill) {
            this.createOperatorPill();
        }
        var newColumnDetails = this.advancedFilterExpressionService.getColumnDetails(colId);
        this.column = newColumnDetails.column;
        var newBaseCellDataType = newColumnDetails.baseCellDataType;
        if (this.baseCellDataType !== newBaseCellDataType) {
            this.baseCellDataType = newBaseCellDataType;
            this.setOperatorKey(undefined);
            if (this.eOperatorPill) {
                _.removeFromParent(this.eOperatorPill.getGui());
                this.destroyBean(this.eOperatorPill);
                this.createOperatorPill();
            }
            this.validate();
        }
        this.filterModel.colId = colId;
        this.filterModel.filterType = this.baseCellDataType;
    };
    ConditionPillWrapperComp.prototype.setOperatorKey = function (operator) {
        var newNumOperands = this.getNumOperands(operator);
        if (newNumOperands !== this.numOperands) {
            this.numOperands = newNumOperands;
            if (newNumOperands === 0) {
                this.destroyOperandPill();
            }
            else {
                this.createOperandPill();
                if (this.baseCellDataType !== 'number') {
                    this.setOperand('');
                }
            }
        }
        this.filterModel.type = operator;
        this.validate();
    };
    ConditionPillWrapperComp.prototype.setOperand = function (operand) {
        var _a;
        var parsedOperand = operand;
        if (this.column) {
            parsedOperand = (_a = this.advancedFilterExpressionService.getOperandModelValue(operand, this.baseCellDataType, this.column)) !== null && _a !== void 0 ? _a : '';
        }
        this.filterModel.filter = parsedOperand;
        this.validate();
    };
    ConditionPillWrapperComp.prototype.getNumOperands = function (operator) {
        var _a, _b;
        return (_b = (_a = this.advancedFilterExpressionService.getExpressionOperator(this.baseCellDataType, operator)) === null || _a === void 0 ? void 0 : _a.numOperands) !== null && _b !== void 0 ? _b : 0;
    };
    ConditionPillWrapperComp.prototype.destroyOperandPill = function () {
        delete this.filterModel.filter;
        this.getGui().removeChild(this.eOperandPill.getGui());
        this.destroyBean(this.eOperandPill);
        this.eOperandPill = undefined;
    };
    ConditionPillWrapperComp.prototype.validate = function () {
        var validationMessage = null;
        if (!_.exists(this.getColumnKey())) {
            validationMessage = this.advancedFilterExpressionService.translate('advancedFilterBuilderValidationSelectColumn');
        }
        else if (!_.exists(this.getOperatorKey())) {
            validationMessage = this.advancedFilterExpressionService.translate('advancedFilterBuilderValidationSelectOption');
        }
        else if (this.numOperands > 0 && !_.exists(this.getOperandDisplayValue())) {
            validationMessage = this.advancedFilterExpressionService.translate('advancedFilterBuilderValidationEnterValue');
        }
        this.item.valid = !validationMessage;
        if (validationMessage !== this.validationMessage) {
            this.validationMessage = validationMessage;
            this.dispatchEvent({
                type: AdvancedFilterBuilderEvents.EVENT_VALID_CHANGED
            });
        }
    };
    ConditionPillWrapperComp.prototype.getDefaultColumnDisplayValue = function () {
        return this.advancedFilterExpressionService.translate('advancedFilterBuilderSelectColumn');
    };
    ConditionPillWrapperComp.prototype.getDefaultOptionSelectValue = function () {
        return this.advancedFilterExpressionService.translate('advancedFilterBuilderSelectOption');
    };
    __decorate$1X([
        Autowired('advancedFilterExpressionService')
    ], ConditionPillWrapperComp.prototype, "advancedFilterExpressionService", void 0);
    __decorate$1X([
        Autowired('valueParserService')
    ], ConditionPillWrapperComp.prototype, "valueParserService", void 0);
    return ConditionPillWrapperComp;
}(Component));

var __extends$2s = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1W = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var InputPillComp = /** @class */ (function (_super) {
    __extends$2s(InputPillComp, _super);
    function InputPillComp(params) {
        var _this = _super.call(this, /* html */ "\n            <div class=\"ag-advanced-filter-builder-pill-wrapper\" role=\"presentation\">\n                <div ref=\"ePill\" class=\"ag-advanced-filter-builder-pill\" role=\"button\">\n                    <span ref=\"eLabel\" class=\"ag-advanced-filter-builder-pill-display\"></span>\n                </div>\n            </div>\n        ") || this;
        _this.params = params;
        _this.value = params.value;
        return _this;
    }
    InputPillComp.prototype.postConstruct = function () {
        var _this = this;
        var _a = this.params, cssClass = _a.cssClass, ariaLabel = _a.ariaLabel;
        this.ePill.classList.add(cssClass);
        this.activateTabIndex([this.ePill]);
        this.eLabel.id = "".concat(this.getCompId());
        _.setAriaDescribedBy(this.ePill, this.eLabel.id);
        _.setAriaLabel(this.ePill, ariaLabel);
        this.renderValue();
        this.addManagedListener(this.ePill, 'click', function (event) {
            event.preventDefault();
            _this.showEditor();
        });
        this.addManagedListener(this.ePill, 'keydown', function (event) {
            switch (event.key) {
                case KeyCode.ENTER:
                    event.preventDefault();
                    _.stopPropagationForAgGrid(event);
                    _this.showEditor();
                    break;
            }
        });
        this.addDestroyFunc(function () { return _this.destroyBean(_this.eEditor); });
    };
    InputPillComp.prototype.getFocusableElement = function () {
        return this.ePill;
    };
    InputPillComp.prototype.showEditor = function () {
        var _this = this;
        if (this.eEditor) {
            return;
        }
        _.setDisplayed(this.ePill, false);
        this.eEditor = this.createEditorComp(this.params.type);
        this.eEditor.setValue(this.value);
        var eEditorGui = this.eEditor.getGui();
        this.eEditor.addManagedListener(eEditorGui, 'keydown', function (event) {
            switch (event.key) {
                case KeyCode.ENTER:
                    event.preventDefault();
                    _.stopPropagationForAgGrid(event);
                    _this.updateValue(true);
                    break;
                case KeyCode.ESCAPE:
                    event.preventDefault();
                    _.stopPropagationForAgGrid(event);
                    _this.hideEditor(true);
                    break;
            }
        });
        this.eEditor.addManagedListener(eEditorGui, 'focusout', function () {
            _this.updateValue(false);
        });
        this.getGui().appendChild(eEditorGui);
        this.eEditor.getFocusableElement().focus();
    };
    InputPillComp.prototype.createEditorComp = function (type) {
        var comp;
        switch (type) {
            case 'text':
                comp = new AgInputTextField();
                break;
            case 'number':
                comp = new AgInputNumberField();
                break;
            case 'date':
                comp = new AgInputDateField();
                break;
        }
        return this.createBean(comp);
    };
    InputPillComp.prototype.hideEditor = function (keepFocus) {
        var eEditor = this.eEditor;
        if (!eEditor) {
            return;
        }
        this.eEditor = undefined;
        this.getGui().removeChild(eEditor.getGui());
        this.destroyBean(eEditor);
        _.setDisplayed(this.ePill, true);
        if (keepFocus) {
            this.ePill.focus();
        }
    };
    InputPillComp.prototype.renderValue = function () {
        var value;
        this.eLabel.classList.remove('ag-advanced-filter-builder-value-empty', 'ag-advanced-filter-builder-value-number', 'ag-advanced-filter-builder-value-text');
        if (!_.exists(this.value)) {
            value = this.advancedFilterExpressionService.translate('advancedFilterBuilderEnterValue');
            this.eLabel.classList.add('ag-advanced-filter-builder-value-empty');
        }
        else if (this.params.type === 'number') {
            value = this.value;
            this.eLabel.classList.add('ag-advanced-filter-builder-value-number');
        }
        else {
            value = "\"".concat(this.value, "\"");
            this.eLabel.classList.add('ag-advanced-filter-builder-value-text');
        }
        this.eLabel.innerText = value;
    };
    InputPillComp.prototype.updateValue = function (keepFocus) {
        var _a;
        if (!this.eEditor) {
            return;
        }
        var value = (_a = this.eEditor.getValue()) !== null && _a !== void 0 ? _a : '';
        this.dispatchEvent({
            type: Events.EVENT_FIELD_VALUE_CHANGED,
            value: value
        });
        this.value = value;
        this.renderValue();
        this.hideEditor(keepFocus);
    };
    __decorate$1W([
        RefSelector('ePill')
    ], InputPillComp.prototype, "ePill", void 0);
    __decorate$1W([
        RefSelector('eLabel')
    ], InputPillComp.prototype, "eLabel", void 0);
    __decorate$1W([
        Autowired('advancedFilterExpressionService')
    ], InputPillComp.prototype, "advancedFilterExpressionService", void 0);
    __decorate$1W([
        PostConstruct
    ], InputPillComp.prototype, "postConstruct", null);
    return InputPillComp;
}(Component));

var __extends$2r = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1V = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var JoinPillWrapperComp = /** @class */ (function (_super) {
    __extends$2r(JoinPillWrapperComp, _super);
    function JoinPillWrapperComp() {
        return _super.call(this, /* html */ "\n            <div class=\"ag-advanced-filter-builder-item-condition\" role=\"presentation\"></div>\n        ") || this;
    }
    JoinPillWrapperComp.prototype.init = function (params) {
        var _this = this;
        var item = params.item, createPill = params.createPill;
        var filterModel = item.filterModel;
        this.filterModel = filterModel;
        this.ePill = createPill({
            key: filterModel.type,
            displayValue: this.advancedFilterExpressionService.parseJoinOperator(filterModel),
            cssClass: 'ag-advanced-filter-builder-join-pill',
            isSelect: true,
            getEditorParams: function () { return ({ values: _this.advancedFilterExpressionService.getJoinOperatorAutocompleteEntries() }); },
            update: function (key) { return filterModel.type = key; },
            pickerAriaLabelKey: 'ariaLabelAdvancedFilterBuilderJoinSelectField',
            pickerAriaLabelValue: 'Advanced Filter Builder Join Operator Select Field',
            ariaLabel: this.advancedFilterExpressionService.translate('ariaAdvancedFilterBuilderJoinOperator')
        });
        this.getGui().appendChild(this.ePill.getGui());
        this.addDestroyFunc(function () { return _this.destroyBean(_this.ePill); });
    };
    JoinPillWrapperComp.prototype.getDragName = function () {
        return this.advancedFilterExpressionService.parseJoinOperator(this.filterModel);
    };
    JoinPillWrapperComp.prototype.getAriaLabel = function () {
        return "".concat(this.advancedFilterExpressionService.translate('ariaAdvancedFilterBuilderGroupItem'), " ").concat(this.getDragName());
    };
    JoinPillWrapperComp.prototype.getValidationMessage = function () {
        return null;
    };
    JoinPillWrapperComp.prototype.getFocusableElement = function () {
        return this.ePill.getFocusableElement();
    };
    __decorate$1V([
        Autowired('advancedFilterExpressionService')
    ], JoinPillWrapperComp.prototype, "advancedFilterExpressionService", void 0);
    return JoinPillWrapperComp;
}(Component));

var __extends$2q = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$z = (undefined && undefined.__assign) || function () {
    __assign$z = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$z.apply(this, arguments);
};
var SelectPillComp = /** @class */ (function (_super) {
    __extends$2q(SelectPillComp, _super);
    function SelectPillComp(params) {
        var _this = _super.call(this, __assign$z(__assign$z({}, params), { template: /* html */ "\n                <div class=\"ag-picker-field ag-advanced-filter-builder-pill-wrapper\" role=\"presentation\">\n                    <div ref=\"eLabel\"></div>\n                    <div ref=\"eWrapper\" class=\"ag-wrapper ag-advanced-filter-builder-pill ag-picker-collapsed\">\n                        <div ref=\"eDisplayField\" class=\"ag-picker-field-display ag-advanced-filter-builder-pill-display\"></div>\n                        <ag-input-text-field ref=\"eInput\" class=\"ag-rich-select-field-input\"></ag-input-text-field>\n                        <div ref=\"eIcon\" class=\"ag-picker-field-icon\" aria-hidden=\"true\"></div>\n                    </div>\n                </div>" })) || this;
        _this.params = params;
        return _this;
    }
    SelectPillComp.prototype.getFocusableElement = function () {
        return this.eWrapper;
    };
    SelectPillComp.prototype.showPicker = function () {
        var _this = this;
        // avoid focus handling issues with multiple rich selects
        setTimeout(function () { return _super.prototype.showPicker.call(_this); });
    };
    SelectPillComp.prototype.hidePicker = function () {
        var _this = this;
        // avoid focus handling issues with multiple rich selects
        setTimeout(function () { return _super.prototype.hidePicker.call(_this); });
    };
    SelectPillComp.prototype.postConstruct = function () {
        _super.prototype.postConstruct.call(this);
        var _a = this.params, wrapperClassName = _a.wrapperClassName, ariaLabel = _a.ariaLabel;
        this.eWrapper.classList.add(wrapperClassName);
        _.setAriaLabelledBy(this.eWrapper, '');
        _.setAriaLabel(this.eWrapper, ariaLabel);
    };
    SelectPillComp.prototype.createPickerComponent = function () {
        var _a;
        if (!this.values) {
            var values = this.params.getEditorParams().values;
            this.values = values;
            var key_1 = this.value.key;
            var value = (_a = values.find(function (value) { return value.key === key_1; })) !== null && _a !== void 0 ? _a : {
                key: key_1,
                displayValue: this.value.displayValue
            };
            this.value = value;
        }
        return _super.prototype.createPickerComponent.call(this);
    };
    SelectPillComp.prototype.onEnterKeyDown = function (event) {
        _.stopPropagationForAgGrid(event);
        if (this.isPickerDisplayed) {
            _super.prototype.onEnterKeyDown.call(this, event);
        }
        else {
            event.preventDefault();
            this.showPicker();
        }
    };
    return SelectPillComp;
}(AgRichSelect));

var __extends$2p = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1U = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AdvancedFilterBuilderItemComp = /** @class */ (function (_super) {
    __extends$2p(AdvancedFilterBuilderItemComp, _super);
    function AdvancedFilterBuilderItemComp(item, dragFeature, focusWrapper) {
        var _this = _super.call(this, /* html */ "\n            <div class=\"ag-advanced-filter-builder-item-wrapper\" role=\"presentation\">\n                <div ref=\"eItem\" class=\"ag-advanced-filter-builder-item\" role=\"presentation\">\n                    <div ref=\"eTreeLines\" class=\"ag-advanced-filter-builder-item-tree-lines\" aria-hidden=\"true\"></div>\n                    <span ref=\"eDragHandle\" class=\"ag-drag-handle\" aria-hidden=\"true\"></span>\n                    <span ref=\"eValidation\" class=\"ag-advanced-filter-builder-item-button ag-advanced-filter-builder-invalid\" aria-hidden=\"true\"></span>\n                </div>\n                <div ref=\"eButtons\" class=\"ag-advanced-filter-builder-item-buttons\">\n                    <span ref=\"eMoveUpButton\" class=\"ag-advanced-filter-builder-item-button\" role=\"button\"></span>\n                    <span ref=\"eMoveDownButton\" class=\"ag-advanced-filter-builder-item-button\" role=\"button\"></span>\n                    <div ref=\"eAddButton\" role=\"presentation\"></div>\n                    <span ref=\"eRemoveButton\" class=\"ag-advanced-filter-builder-item-button\" role=\"button\"></span>\n                </div>\n            </div>\n        ") || this;
        _this.item = item;
        _this.dragFeature = dragFeature;
        _this.focusWrapper = focusWrapper;
        _this.moveUpDisabled = false;
        _this.moveDownDisabled = false;
        return _this;
    }
    AdvancedFilterBuilderItemComp.prototype.postConstruct = function () {
        var _this = this;
        var _a = this.item, filterModel = _a.filterModel, level = _a.level, showMove = _a.showMove;
        var isJoin = filterModel.filterType === 'join';
        this.ePillWrapper = this.createManagedBean(isJoin ? new JoinPillWrapperComp() : new ConditionPillWrapperComp());
        this.ePillWrapper.init({ item: this.item, createPill: function (params) { return _this.createPill(params); } });
        this.eDragHandle.insertAdjacentElement('afterend', this.ePillWrapper.getGui());
        if (level === 0) {
            var eTreeLine = document.createElement('div');
            eTreeLine.classList.add('ag-advanced-filter-builder-item-tree-line-vertical-bottom');
            eTreeLine.classList.add('ag-advanced-filter-builder-item-tree-line-root');
            this.eTreeLines.appendChild(eTreeLine);
            _.setDisplayed(this.eDragHandle, false);
            _.setDisplayed(this.eButtons, false);
            _.setAriaExpanded(this.focusWrapper, true);
        }
        else {
            this.setupTreeLines(level);
            this.eDragHandle.appendChild(_.createIconNoSpan('advancedFilterBuilderDrag', this.gridOptionsService));
            this.setupValidation();
            this.setupMoveButtons(showMove);
            this.setupAddButton();
            this.setupRemoveButton();
            this.setupDragging();
            this.updateAriaExpanded();
        }
        _.setAriaLevel(this.focusWrapper, level + 1);
        this.initialiseTabGuard({});
        this.createManagedBean(new AdvancedFilterBuilderItemNavigationFeature(this.getGui(), this.focusWrapper, this.ePillWrapper));
        this.updateAriaLabel();
        this.addManagedListener(this.ePillWrapper, AdvancedFilterBuilderEvents.EVENT_VALUE_CHANGED, function () { return _this.dispatchEvent({
            type: AdvancedFilterBuilderEvents.EVENT_VALUE_CHANGED
        }); });
        this.addManagedListener(this.ePillWrapper, AdvancedFilterBuilderEvents.EVENT_VALID_CHANGED, function () { return _this.updateValidity(); });
    };
    AdvancedFilterBuilderItemComp.prototype.setState = function (params) {
        var level = this.item.level;
        if (level === 0) {
            return;
        }
        var showMove = this.item.showMove;
        var disableMoveUp = params.disableMoveUp, disableMoveDown = params.disableMoveDown, treeLines = params.treeLines, showStartTreeLine = params.showStartTreeLine;
        this.updateTreeLines(treeLines, showStartTreeLine);
        this.updateAriaExpanded();
        if (showMove) {
            this.moveUpDisabled = !!disableMoveUp;
            this.moveDownDisabled = !!disableMoveDown;
            this.eMoveUpButton.classList.toggle('ag-advanced-filter-builder-item-button-disabled', disableMoveUp);
            this.eMoveDownButton.classList.toggle('ag-advanced-filter-builder-item-button-disabled', disableMoveDown);
            _.setAriaDisabled(this.eMoveUpButton, !!disableMoveUp);
            _.setAriaDisabled(this.eMoveDownButton, !!disableMoveDown);
            this.moveUpTooltipFeature.refreshToolTip();
            this.moveDownTooltipFeature.refreshToolTip();
        }
    };
    AdvancedFilterBuilderItemComp.prototype.focusMoveButton = function (backwards) {
        (backwards ? this.eMoveUpButton : this.eMoveDownButton).focus();
    };
    AdvancedFilterBuilderItemComp.prototype.afterAdd = function () {
        this.ePillWrapper.getFocusableElement().focus();
    };
    AdvancedFilterBuilderItemComp.prototype.setupTreeLines = function (level) {
        for (var i = 0; i < level; i++) {
            var eTreeLine = document.createElement('div');
            this.eTreeLines.appendChild(eTreeLine);
        }
    };
    AdvancedFilterBuilderItemComp.prototype.updateTreeLines = function (treeLines, showStartTreeLine) {
        var lastTreeLineIndex = treeLines.length - 1;
        var children = this.eTreeLines.children;
        for (var i = 0; i < lastTreeLineIndex; i++) {
            var eTreeLine_1 = children.item(i);
            if (eTreeLine_1) {
                eTreeLine_1.classList.toggle('ag-advanced-filter-builder-item-tree-line-vertical', !treeLines[i]);
            }
        }
        var eTreeLine = children.item(lastTreeLineIndex);
        if (eTreeLine) {
            eTreeLine.classList.add('ag-advanced-filter-builder-item-tree-line-horizontal');
            var isLastChild = treeLines[lastTreeLineIndex];
            eTreeLine.classList.toggle('ag-advanced-filter-builder-item-tree-line-vertical-top', isLastChild);
            eTreeLine.classList.toggle('ag-advanced-filter-builder-item-tree-line-vertical', !isLastChild);
        }
        this.eDragHandle.classList.toggle('ag-advanced-filter-builder-item-tree-line-vertical-bottom', showStartTreeLine);
    };
    AdvancedFilterBuilderItemComp.prototype.setupValidation = function () {
        var _this = this;
        this.eValidation.appendChild(_.createIconNoSpan('advancedFilterBuilderInvalid', this.gridOptionsService));
        this.validationTooltipFeature = this.createManagedBean(new TooltipFeature({
            getGui: function () { return _this.eValidation; },
            getLocation: function () { return 'advancedFilter'; },
            getTooltipValue: function () { return _this.ePillWrapper.getValidationMessage(); },
            getTooltipShowDelayOverride: function () { return 1000; }
        }, this.beans));
        this.validationTooltipFeature.setComp(this.eValidation);
        this.updateValidity();
    };
    AdvancedFilterBuilderItemComp.prototype.setupAddButton = function () {
        var _this = this;
        var _a;
        var addButtonParams = getAdvancedFilterBuilderAddButtonParams(function (key) { return _this.advancedFilterExpressionService.translate(key); }, (_a = this.gridOptionsService.get('advancedFilterBuilderParams')) === null || _a === void 0 ? void 0 : _a.addSelectWidth);
        var eAddButton = this.createManagedBean(new AddDropdownComp(addButtonParams));
        this.addManagedListener(eAddButton, Events.EVENT_FIELD_PICKER_VALUE_SELECTED, function (_a) {
            var value = _a.value;
            return _this.dispatchEvent({
                type: AdvancedFilterBuilderEvents.EVENT_ADDED,
                item: _this.item,
                isJoin: value.key === 'join'
            });
        });
        this.eAddButton.appendChild(eAddButton.getGui());
        var tooltipFeature = this.createManagedBean(new TooltipFeature({
            getGui: function () { return _this.eAddButton; },
            getLocation: function () { return 'advancedFilter'; },
            getTooltipValue: function () { return _this.advancedFilterExpressionService.translate('advancedFilterBuilderAddButtonTooltip'); }
        }, this.beans));
        tooltipFeature.setComp(this.eAddButton);
    };
    AdvancedFilterBuilderItemComp.prototype.setupRemoveButton = function () {
        var _this = this;
        this.eRemoveButton.appendChild(_.createIconNoSpan('advancedFilterBuilderRemove', this.gridOptionsService));
        this.addManagedListener(this.eRemoveButton, 'click', function () { return _this.removeItem(); });
        this.addManagedListener(this.eRemoveButton, 'keydown', function (event) {
            switch (event.key) {
                case KeyCode.ENTER:
                    event.preventDefault();
                    _.stopPropagationForAgGrid(event);
                    _this.removeItem();
                    break;
            }
        });
        var tooltipFeature = this.createManagedBean(new TooltipFeature({
            getGui: function () { return _this.eRemoveButton; },
            getLocation: function () { return 'advancedFilter'; },
            getTooltipValue: function () { return _this.advancedFilterExpressionService.translate('advancedFilterBuilderRemoveButtonTooltip'); }
        }, this.beans));
        tooltipFeature.setComp(this.eRemoveButton);
        _.setAriaLabel(this.eRemoveButton, this.advancedFilterExpressionService.translate('advancedFilterBuilderRemoveButtonTooltip'));
        this.activateTabIndex([this.eRemoveButton]);
    };
    AdvancedFilterBuilderItemComp.prototype.setupMoveButtons = function (showMove) {
        var _this = this;
        if (showMove) {
            this.eMoveUpButton.appendChild(_.createIconNoSpan('advancedFilterBuilderMoveUp', this.gridOptionsService));
            this.addManagedListener(this.eMoveUpButton, 'click', function () { return _this.moveItem(true); });
            this.addManagedListener(this.eMoveUpButton, 'keydown', function (event) {
                switch (event.key) {
                    case KeyCode.ENTER:
                        event.preventDefault();
                        _.stopPropagationForAgGrid(event);
                        _this.moveItem(true);
                        break;
                }
            });
            this.moveUpTooltipFeature = this.createManagedBean(new TooltipFeature({
                getGui: function () { return _this.eMoveUpButton; },
                getLocation: function () { return 'advancedFilter'; },
                getTooltipValue: function () { return _this.moveUpDisabled
                    ? null
                    : _this.advancedFilterExpressionService.translate('advancedFilterBuilderMoveUpButtonTooltip'); }
            }, this.beans));
            this.moveUpTooltipFeature.setComp(this.eMoveUpButton);
            _.setAriaLabel(this.eMoveUpButton, this.advancedFilterExpressionService.translate('advancedFilterBuilderMoveUpButtonTooltip'));
            this.eMoveDownButton.appendChild(_.createIconNoSpan('advancedFilterBuilderMoveDown', this.gridOptionsService));
            this.addManagedListener(this.eMoveDownButton, 'click', function () { return _this.moveItem(false); });
            this.addManagedListener(this.eMoveDownButton, 'keydown', function (event) {
                switch (event.key) {
                    case KeyCode.ENTER:
                        event.preventDefault();
                        _.stopPropagationForAgGrid(event);
                        _this.moveItem(false);
                        break;
                }
            });
            this.moveDownTooltipFeature = this.createManagedBean(new TooltipFeature({
                getGui: function () { return _this.eMoveDownButton; },
                getLocation: function () { return 'advancedFilter'; },
                getTooltipValue: function () { return _this.moveDownDisabled
                    ? null
                    : _this.advancedFilterExpressionService.translate('advancedFilterBuilderMoveDownButtonTooltip'); }
            }, this.beans));
            this.moveDownTooltipFeature.setComp(this.eMoveDownButton);
            _.setAriaLabel(this.eMoveDownButton, this.advancedFilterExpressionService.translate('advancedFilterBuilderMoveDownButtonTooltip'));
            this.activateTabIndex([this.eMoveUpButton, this.eMoveDownButton]);
        }
        else {
            _.setDisplayed(this.eMoveUpButton, false);
            _.setDisplayed(this.eMoveDownButton, false);
        }
    };
    AdvancedFilterBuilderItemComp.prototype.updateValidity = function () {
        _.setVisible(this.eValidation, !this.item.valid);
        this.validationTooltipFeature.refreshToolTip();
        this.updateAriaLabel();
    };
    AdvancedFilterBuilderItemComp.prototype.createPill = function (params) {
        var _this = this;
        var _a, _b;
        var key = params.key, displayValue = params.displayValue, cssClass = params.cssClass, update = params.update, ariaLabel = params.ariaLabel;
        var onUpdated = function (key) {
            if (key == null) {
                return;
            }
            update(key);
            _this.dispatchEvent({
                type: AdvancedFilterBuilderEvents.EVENT_VALUE_CHANGED
            });
        };
        if (params.isSelect) {
            var getEditorParams = params.getEditorParams, pickerAriaLabelKey = params.pickerAriaLabelKey, pickerAriaLabelValue = params.pickerAriaLabelValue;
            var advancedFilterBuilderParams = this.gridOptionsService.get('advancedFilterBuilderParams');
            var minPickerWidth = "".concat((_a = advancedFilterBuilderParams === null || advancedFilterBuilderParams === void 0 ? void 0 : advancedFilterBuilderParams.pillSelectMinWidth) !== null && _a !== void 0 ? _a : 140, "px");
            var maxPickerWidth = "".concat((_b = advancedFilterBuilderParams === null || advancedFilterBuilderParams === void 0 ? void 0 : advancedFilterBuilderParams.pillSelectMaxWidth) !== null && _b !== void 0 ? _b : 200, "px");
            var comp = this.createBean(new SelectPillComp({
                pickerAriaLabelKey: pickerAriaLabelKey,
                pickerAriaLabelValue: pickerAriaLabelValue,
                pickerType: 'ag-list',
                value: {
                    key: key,
                    displayValue: displayValue
                },
                valueFormatter: function (value) { var _a; return value == null ? null : (_a = value.displayValue) !== null && _a !== void 0 ? _a : value.key; },
                variableWidth: true,
                minPickerWidth: minPickerWidth,
                maxPickerWidth: maxPickerWidth,
                getEditorParams: getEditorParams,
                wrapperClassName: cssClass,
                ariaLabel: ariaLabel
            }));
            this.addManagedListener(comp, Events.EVENT_FIELD_PICKER_VALUE_SELECTED, function (_a) {
                var value = _a.value;
                return onUpdated(value === null || value === void 0 ? void 0 : value.key);
            });
            return comp;
        }
        else {
            var comp = this.createBean(new InputPillComp({
                value: displayValue,
                cssClass: cssClass,
                type: this.getInputType(params.baseCellDataType),
                ariaLabel: ariaLabel
            }));
            this.addManagedListener(comp, Events.EVENT_FIELD_VALUE_CHANGED, function (_a) {
                var value = _a.value;
                return onUpdated(value);
            });
            return comp;
        }
    };
    AdvancedFilterBuilderItemComp.prototype.getInputType = function (baseCellDataType) {
        switch (baseCellDataType) {
            case 'text':
            case 'object':
            case 'boolean':
                return 'text';
            case 'number':
                return 'number';
            case 'date':
            case 'dateString':
                return 'date';
        }
    };
    AdvancedFilterBuilderItemComp.prototype.setupDragging = function () {
        var _this = this;
        var dragSource = {
            type: DragSourceType.AdvancedFilterBuilder,
            eElement: this.eDragHandle,
            dragItemName: function () { return _this.ePillWrapper.getDragName(); },
            getDefaultIconName: function () { return DragAndDropService.ICON_NOT_ALLOWED; },
            getDragItem: function () { return ({}); },
            onDragStarted: function () { return _this.dragFeature.dispatchEvent({
                type: AdvancedFilterBuilderDragFeature.EVENT_DRAG_STARTED,
                item: _this.item
            }); },
            onDragStopped: function () { return _this.dragFeature.dispatchEvent({
                type: AdvancedFilterBuilderDragFeature.EVENT_DRAG_ENDED
            }); }
        };
        this.dragAndDropService.addDragSource(dragSource, true);
        this.addDestroyFunc(function () { return _this.dragAndDropService.removeDragSource(dragSource); });
    };
    AdvancedFilterBuilderItemComp.prototype.updateAriaLabel = function () {
        var wrapperLabel = this.ePillWrapper.getAriaLabel();
        var level = "".concat(this.item.level + 1);
        var validationMessage = this.ePillWrapper.getValidationMessage();
        var ariaLabel;
        if (validationMessage) {
            ariaLabel = this.advancedFilterExpressionService.translate('ariaAdvancedFilterBuilderItemValidation', [wrapperLabel, level, validationMessage]);
        }
        else {
            ariaLabel = this.advancedFilterExpressionService.translate('ariaAdvancedFilterBuilderItem', [wrapperLabel, level]);
        }
        _.setAriaLabel(this.focusWrapper, ariaLabel);
    };
    AdvancedFilterBuilderItemComp.prototype.updateAriaExpanded = function () {
        _.removeAriaExpanded(this.focusWrapper);
        var filterModel = this.item.filterModel;
        if ((filterModel === null || filterModel === void 0 ? void 0 : filterModel.filterType) === 'join' && filterModel.conditions.length) {
            _.setAriaExpanded(this.focusWrapper, true);
        }
    };
    AdvancedFilterBuilderItemComp.prototype.removeItem = function () {
        this.dispatchEvent({
            type: AdvancedFilterBuilderEvents.EVENT_REMOVED,
            item: this.item
        });
    };
    AdvancedFilterBuilderItemComp.prototype.moveItem = function (backwards) {
        this.dispatchEvent({
            type: AdvancedFilterBuilderEvents.EVENT_MOVED,
            item: this.item,
            backwards: backwards
        });
    };
    __decorate$1U([
        RefSelector('eTreeLines')
    ], AdvancedFilterBuilderItemComp.prototype, "eTreeLines", void 0);
    __decorate$1U([
        RefSelector('eDragHandle')
    ], AdvancedFilterBuilderItemComp.prototype, "eDragHandle", void 0);
    __decorate$1U([
        RefSelector('eItem')
    ], AdvancedFilterBuilderItemComp.prototype, "eItem", void 0);
    __decorate$1U([
        RefSelector('eButtons')
    ], AdvancedFilterBuilderItemComp.prototype, "eButtons", void 0);
    __decorate$1U([
        RefSelector('eValidation')
    ], AdvancedFilterBuilderItemComp.prototype, "eValidation", void 0);
    __decorate$1U([
        RefSelector('eMoveUpButton')
    ], AdvancedFilterBuilderItemComp.prototype, "eMoveUpButton", void 0);
    __decorate$1U([
        RefSelector('eMoveDownButton')
    ], AdvancedFilterBuilderItemComp.prototype, "eMoveDownButton", void 0);
    __decorate$1U([
        RefSelector('eAddButton')
    ], AdvancedFilterBuilderItemComp.prototype, "eAddButton", void 0);
    __decorate$1U([
        RefSelector('eRemoveButton')
    ], AdvancedFilterBuilderItemComp.prototype, "eRemoveButton", void 0);
    __decorate$1U([
        Autowired('beans')
    ], AdvancedFilterBuilderItemComp.prototype, "beans", void 0);
    __decorate$1U([
        Autowired('dragAndDropService')
    ], AdvancedFilterBuilderItemComp.prototype, "dragAndDropService", void 0);
    __decorate$1U([
        Autowired('advancedFilterExpressionService')
    ], AdvancedFilterBuilderItemComp.prototype, "advancedFilterExpressionService", void 0);
    __decorate$1U([
        PostConstruct
    ], AdvancedFilterBuilderItemComp.prototype, "postConstruct", null);
    return AdvancedFilterBuilderItemComp;
}(TabGuardComp));

var __extends$2o = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1T = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AdvancedFilterBuilderItemAddComp = /** @class */ (function (_super) {
    __extends$2o(AdvancedFilterBuilderItemAddComp, _super);
    function AdvancedFilterBuilderItemAddComp(item, focusWrapper) {
        var _this = _super.call(this, /* html */ "\n            <div class=\"ag-advanced-filter-builder-item-wrapper\" role=\"presentation\">\n                <div ref=\"eItem\" class=\"ag-advanced-filter-builder-item\" role=\"presentation\">\n                    <div class=\"ag-advanced-filter-builder-item-tree-lines\" aria-hidden=\"true\">\n                        <div class=\"ag-advanced-filter-builder-item-tree-line-vertical-top ag-advanced-filter-builder-item-tree-line-horizontal\"></div>\n                    </div>\n                </div>\n            </div>\n        ") || this;
        _this.item = item;
        _this.focusWrapper = focusWrapper;
        return _this;
    }
    AdvancedFilterBuilderItemAddComp.prototype.postConstruct = function () {
        var _this = this;
        var _a;
        _.setAriaLevel(this.focusWrapper, 2);
        var addButtonParams = getAdvancedFilterBuilderAddButtonParams(function (key) { return _this.advancedFilterExpressionService.translate(key); }, (_a = this.gridOptionsService.get('advancedFilterBuilderParams')) === null || _a === void 0 ? void 0 : _a.addSelectWidth);
        var eAddButton = this.createManagedBean(new AddDropdownComp(addButtonParams));
        this.addManagedListener(eAddButton, Events.EVENT_FIELD_PICKER_VALUE_SELECTED, function (_a) {
            var value = _a.value;
            _this.dispatchEvent({
                type: AdvancedFilterBuilderEvents.EVENT_ADDED,
                item: _this.item,
                isJoin: value.key === 'join'
            });
        });
        this.eItem.appendChild(eAddButton.getGui());
        var tooltipFeature = this.createManagedBean(new TooltipFeature({
            getGui: function () { return eAddButton.getGui(); },
            getLocation: function () { return 'advancedFilter'; },
            getTooltipValue: function () { return _this.advancedFilterExpressionService.translate('advancedFilterBuilderAddButtonTooltip'); }
        }, this.beans));
        tooltipFeature.setComp(eAddButton.getGui());
        this.createManagedBean(new AdvancedFilterBuilderItemNavigationFeature(this.getGui(), this.focusWrapper, eAddButton));
        _.setAriaLabel(this.focusWrapper, this.advancedFilterExpressionService.translate('ariaAdvancedFilterBuilderItem', [
            this.advancedFilterExpressionService.translate('advancedFilterBuilderAddButtonTooltip'),
            "".concat(this.item.level + 1)
        ]));
    };
    AdvancedFilterBuilderItemAddComp.prototype.afterAdd = function () {
        // do nothing
    };
    __decorate$1T([
        Autowired('beans')
    ], AdvancedFilterBuilderItemAddComp.prototype, "beans", void 0);
    __decorate$1T([
        Autowired('advancedFilterExpressionService')
    ], AdvancedFilterBuilderItemAddComp.prototype, "advancedFilterExpressionService", void 0);
    __decorate$1T([
        RefSelector('eItem')
    ], AdvancedFilterBuilderItemAddComp.prototype, "eItem", void 0);
    __decorate$1T([
        PostConstruct
    ], AdvancedFilterBuilderItemAddComp.prototype, "postConstruct", null);
    return AdvancedFilterBuilderItemAddComp;
}(Component));

var __extends$2n = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1S = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$F = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$p = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var AdvancedFilterBuilderComp = /** @class */ (function (_super) {
    __extends$2n(AdvancedFilterBuilderComp, _super);
    function AdvancedFilterBuilderComp() {
        var _this = _super.call(this, /* html */ "\n            <div role=\"presentation\" class=\"ag-advanced-filter-builder\" tabindex=\"-1\">\n                <div role=\"presentation\" class=\"ag-advanced-filter-builder-list\" ref=\"eList\"></div>\n                <div role=\"presentation\" class=\"ag-advanced-filter-builder-button-panel\">\n                    <button class=\"ag-button ag-standard-button ag-advanced-filter-builder-apply-button\" ref=\"eApplyFilterButton\"></button>\n                    <button class=\"ag-button ag-standard-button ag-advanced-filter-builder-cancel-button\" ref=\"eCancelFilterButton\"></button>\n                </div>\n            </div>") || this;
        _this.validationMessage = null;
        return _this;
    }
    AdvancedFilterBuilderComp.prototype.postConstruct = function () {
        var _this = this;
        var _a;
        var showMoveButtons = ((_a = this.gridOptionsService.get('advancedFilterBuilderParams')) !== null && _a !== void 0 ? _a : {}).showMoveButtons;
        this.showMove = !!showMoveButtons;
        this.addManagedPropertyListener('advancedFilterBuilderParams', function (_a) {
            var currentValue = _a.currentValue;
            _this.showMove = !!(currentValue === null || currentValue === void 0 ? void 0 : currentValue.showMoveButtons);
            _this.refreshList(false);
        });
        this.filterModel = this.setupFilterModel();
        this.setupVirtualList();
        this.dragFeature = this.createManagedBean(new AdvancedFilterBuilderDragFeature(this, this.virtualList));
        this.setupButtons();
    };
    AdvancedFilterBuilderComp.prototype.refresh = function () {
        var indexToFocus = this.virtualList.getLastFocusedRow();
        this.setupFilterModel();
        this.validateItems();
        this.refreshList(false);
        if (indexToFocus != null) {
            // last focused row is cleared on focus out, so if defined, we need to put the focus back
            if (!this.virtualList.getComponentAt(indexToFocus)) {
                indexToFocus = 0;
            }
            this.virtualList.focusRow(indexToFocus);
        }
    };
    AdvancedFilterBuilderComp.prototype.getNumItems = function () {
        return this.items.length;
    };
    AdvancedFilterBuilderComp.prototype.moveItem = function (item, destination) {
        if (!destination || !item) {
            return;
        }
        this.moveItemToIndex(item, destination.rowIndex, destination.position);
    };
    AdvancedFilterBuilderComp.prototype.afterGuiAttached = function () {
        this.virtualList.focusRow(0);
    };
    AdvancedFilterBuilderComp.prototype.setupVirtualList = function () {
        var _this = this;
        this.virtualList = this.createManagedBean(new VirtualList({
            cssIdentifier: 'advanced-filter-builder',
            ariaRole: 'tree',
            listName: this.advancedFilterExpressionService.translate('ariaAdvancedFilterBuilderList')
        }));
        this.virtualList.setComponentCreator(this.createItemComponent.bind(this));
        this.virtualList.setComponentUpdater(this.updateItemComponent.bind(this));
        this.virtualList.setRowHeight(40);
        this.eList.appendChild(this.virtualList.getGui());
        this.virtualList.setModel({
            getRowCount: function () { return _this.items.length; },
            getRow: function (index) { return _this.items[index]; },
            areRowsEqual: function (oldRow, newRow) { return oldRow === newRow; }
        });
        this.buildList();
        this.virtualList.refresh();
    };
    AdvancedFilterBuilderComp.prototype.setupButtons = function () {
        var _this = this;
        this.eApplyFilterButton.innerText = this.advancedFilterExpressionService.translate('advancedFilterBuilderApply');
        this.activateTabIndex([this.eApplyFilterButton]);
        this.addManagedListener(this.eApplyFilterButton, 'click', function () {
            _this.advancedFilterService.setModel(_this.filterModel);
            _this.filterManager.onFilterChanged({ source: 'advancedFilter' });
            _this.close();
        });
        this.validationTooltipFeature = this.createManagedBean(new TooltipFeature({
            getGui: function () { return _this.eApplyFilterButton; },
            getLocation: function () { return 'advancedFilter'; },
            getTooltipValue: function () { return _this.validationMessage; },
            getTooltipShowDelayOverride: function () { return 1000; }
        }, this.beans));
        this.validationTooltipFeature.setComp(this.eApplyFilterButton);
        this.validate();
        this.addManagedListener(this.eApplyFilterButton, 'mouseenter', function () { return _this.addOrRemoveCssClass('ag-advanced-filter-builder-validation', true); });
        this.addManagedListener(this.eApplyFilterButton, 'mouseleave', function () { return _this.addOrRemoveCssClass('ag-advanced-filter-builder-validation', false); });
        this.eCancelFilterButton.innerText = this.advancedFilterExpressionService.translate('advancedFilterBuilderCancel');
        this.activateTabIndex([this.eCancelFilterButton]);
        this.addManagedListener(this.eCancelFilterButton, 'click', function () { return _this.close(); });
    };
    AdvancedFilterBuilderComp.prototype.removeItemFromParent = function (item) {
        var sourceParentIndex = item.parent.conditions.indexOf(item.filterModel);
        item.parent.conditions.splice(sourceParentIndex, 1);
        return sourceParentIndex;
    };
    AdvancedFilterBuilderComp.prototype.moveItemToIndex = function (item, destinationRowIndex, destinationPosition) {
        var _a;
        var destinationItem = this.items[destinationRowIndex];
        var destinationIsParent = ((_a = destinationItem.filterModel) === null || _a === void 0 ? void 0 : _a.filterType) === 'join' && destinationPosition === 'bottom';
        var destinationParent = destinationIsParent ? destinationItem.filterModel : destinationItem.parent;
        // trying to move before the root
        if (!destinationParent) {
            return;
        }
        // can't move into itself
        if (this.isChildOrSelf(destinationParent, item.filterModel) || destinationItem === item) {
            return;
        }
        this.removeItemFromParent(item);
        var destinationParentIndex;
        if (destinationIsParent) {
            destinationParentIndex = 0;
        }
        else {
            destinationParentIndex = destinationParent.conditions.indexOf(destinationItem.filterModel);
            if (destinationParentIndex === -1) {
                destinationParentIndex = destinationParent.conditions.length;
            }
            else if (destinationPosition === 'bottom') {
                destinationParentIndex += 1;
            }
        }
        destinationParent.conditions.splice(destinationParentIndex, 0, item.filterModel);
        this.refreshList(false);
    };
    AdvancedFilterBuilderComp.prototype.isChildOrSelf = function (modelToCheck, potentialParentModel) {
        var _this = this;
        return modelToCheck === potentialParentModel || (potentialParentModel.filterType === 'join' &&
            potentialParentModel.conditions.some(function (condition) { return _this.isChildOrSelf(modelToCheck, condition); }));
    };
    AdvancedFilterBuilderComp.prototype.setupFilterModel = function () {
        var filterModel = this.formatFilterModel(this.advancedFilterService.getModel());
        this.stringifiedModel = JSON.stringify(filterModel);
        return filterModel;
    };
    AdvancedFilterBuilderComp.prototype.formatFilterModel = function (filterModel) {
        filterModel = filterModel !== null && filterModel !== void 0 ? filterModel : {
            filterType: 'join',
            type: 'AND',
            conditions: []
        };
        if (filterModel.filterType !== 'join') {
            filterModel = {
                filterType: 'join',
                type: 'AND',
                conditions: [filterModel]
            };
        }
        return filterModel;
    };
    AdvancedFilterBuilderComp.prototype.buildList = function () {
        var _this = this;
        var parseFilterModel = function (filterModel, items, level, parent) {
            items.push({ filterModel: filterModel, level: level, parent: parent, valid: true, showMove: _this.showMove });
            if (filterModel.filterType === 'join') {
                filterModel.conditions.forEach(function (childFilterModel) { return parseFilterModel(childFilterModel, items, level + 1, filterModel); });
                if (level === 0) {
                    items.push({ filterModel: null, level: level + 1, parent: filterModel, valid: true });
                }
            }
        };
        this.items = [];
        parseFilterModel(this.filterModel, this.items, 0);
    };
    AdvancedFilterBuilderComp.prototype.refreshList = function (softRefresh) {
        if (!softRefresh) {
            var invalidModels_1 = [];
            this.items.forEach(function (item) {
                if (!item.valid) {
                    invalidModels_1.push(item.filterModel);
                }
            });
            this.buildList();
            if (invalidModels_1.length) {
                this.items.forEach(function (item) {
                    if (item.filterModel && invalidModels_1.includes(item.filterModel)) {
                        item.valid = false;
                    }
                });
            }
        }
        this.virtualList.refresh(softRefresh);
        this.validate();
    };
    AdvancedFilterBuilderComp.prototype.updateItemComponent = function (item, comp) {
        var _this = this;
        var index = this.items.indexOf(item);
        var populateTreeLines = function (filterModel, treeLines) {
            var parentItem = _this.items.find(function (itemToCheck) { return itemToCheck.filterModel === filterModel; });
            var parentFilterModel = parentItem === null || parentItem === void 0 ? void 0 : parentItem.parent;
            if (parentFilterModel) {
                var conditions = parentFilterModel.conditions;
                // check parent
                populateTreeLines(parentFilterModel, treeLines);
                treeLines.push(conditions[conditions.length - 1] === filterModel);
            }
        };
        var treeLines = [];
        var filterModel = item.filterModel;
        if (filterModel) {
            populateTreeLines(filterModel, treeLines);
            // the add item button is always last child
            treeLines[0] = false;
        }
        var showStartTreeLine = (filterModel === null || filterModel === void 0 ? void 0 : filterModel.filterType) === 'join' && !!filterModel.conditions.length;
        comp.setState({
            disableMoveUp: index === 1,
            disableMoveDown: !this.canMoveDown(item, index),
            treeLines: treeLines,
            showStartTreeLine: showStartTreeLine
        });
    };
    AdvancedFilterBuilderComp.prototype.createItemComponent = function (item, focusWrapper) {
        var _this = this;
        var itemComp = this.createBean(item.filterModel
            ? new AdvancedFilterBuilderItemComp(item, this.dragFeature, focusWrapper)
            : new AdvancedFilterBuilderItemAddComp(item, focusWrapper));
        itemComp.addManagedListener(itemComp, AdvancedFilterBuilderEvents.EVENT_REMOVED, function (_a) {
            var item = _a.item;
            return _this.removeItem(item);
        });
        itemComp.addManagedListener(itemComp, AdvancedFilterBuilderEvents.EVENT_VALUE_CHANGED, function () { return _this.validate(); });
        itemComp.addManagedListener(itemComp, AdvancedFilterBuilderEvents.EVENT_ADDED, function (_a) {
            var item = _a.item, isJoin = _a.isJoin;
            return _this.addItem(item, isJoin);
        });
        itemComp.addManagedListener(itemComp, AdvancedFilterBuilderEvents.EVENT_MOVED, function (_a) {
            var item = _a.item, backwards = _a.backwards;
            return _this.moveItemUpDown(item, backwards);
        });
        if (itemComp instanceof AdvancedFilterBuilderItemComp) {
            this.updateItemComponent(item, itemComp);
        }
        return itemComp;
    };
    AdvancedFilterBuilderComp.prototype.addItem = function (item, isJoin) {
        var _a;
        var _b;
        var itemParent = item.parent, level = item.level, itemFilterModel = item.filterModel;
        var itemIsJoin = (itemFilterModel === null || itemFilterModel === void 0 ? void 0 : itemFilterModel.filterType) === 'join';
        var filterModel = isJoin ? {
            filterType: 'join',
            type: 'AND',
            conditions: []
        } : {};
        var parent = (itemIsJoin ? itemFilterModel : itemParent);
        var insertIndex = itemIsJoin ? 0 : parent.conditions.indexOf(itemFilterModel);
        if (insertIndex >= 0) {
            if (!itemIsJoin) {
                insertIndex += 1;
            }
            parent.conditions.splice(insertIndex, 0, filterModel);
        }
        else {
            parent.conditions.push(filterModel);
        }
        var index = this.items.indexOf(item);
        var softRefresh = index >= 0;
        if (softRefresh) {
            if (item.filterModel) {
                index++;
            }
            var newItems = [{
                    filterModel: filterModel,
                    level: itemIsJoin ? level + 1 : level,
                    parent: parent,
                    valid: isJoin,
                    showMove: this.showMove
                }];
            (_a = this.items).splice.apply(_a, __spreadArray$p([index, 0], __read$F(newItems), false));
        }
        this.refreshList(softRefresh);
        if (softRefresh) {
            (_b = this.virtualList.getComponentAt(index)) === null || _b === void 0 ? void 0 : _b.afterAdd();
        }
    };
    AdvancedFilterBuilderComp.prototype.removeItem = function (item) {
        var _a;
        var parent = item.parent;
        var filterModel = item.filterModel;
        var parentIndex = parent.conditions.indexOf(filterModel);
        parent.conditions.splice(parentIndex, 1);
        var isJoin = ((_a = item.filterModel) === null || _a === void 0 ? void 0 : _a.filterType) === 'join';
        var index = this.items.indexOf(item);
        // if it's a join, we don't know how many children there are, so always rebuild
        var softRefresh = !isJoin && index >= 0;
        if (softRefresh) {
            this.items.splice(index, 1);
        }
        this.refreshList(softRefresh);
        if (index >= 0) {
            this.virtualList.focusRow(index);
        }
    };
    AdvancedFilterBuilderComp.prototype.moveItemUpDown = function (item, backwards) {
        var itemIndex = this.items.indexOf(item);
        var destinationIndex = backwards ? itemIndex - 1 : itemIndex + 1;
        if (destinationIndex === 0 || (!backwards && !this.canMoveDown(item, itemIndex))) {
            return;
        }
        var destinationItem = this.items[destinationIndex];
        var indexInParent = this.removeItemFromParent(item);
        var level = item.level, filterModel = item.filterModel, parent = item.parent;
        var destinationLevel = destinationItem.level, destinationFilterModel = destinationItem.filterModel, destinationParent = destinationItem.parent;
        if (backwards) {
            if (destinationLevel === level && destinationFilterModel.filterType === 'join') {
                // destination is empty join. move to last child
                destinationFilterModel.conditions.push(filterModel);
            }
            else if (destinationLevel <= level) {
                // same parent or first child. move above destination in destination parent
                var destinationIndex_1 = destinationParent.conditions.indexOf(destinationFilterModel);
                destinationParent.conditions.splice(destinationIndex_1, 0, filterModel);
            }
            else {
                // need to move up a level. move to end of previous item's children
                var newParentItem = parent.conditions[indexInParent - 1];
                newParentItem.conditions.push(filterModel);
            }
        }
        else {
            if (destinationLevel === level) {
                if (destinationFilterModel.filterType === 'join') {
                    // destination is join. move to first child
                    destinationFilterModel.conditions.splice(0, 0, filterModel);
                }
                else {
                    // switch positions
                    var destinationIndex_2 = destinationParent.conditions.indexOf(destinationFilterModel);
                    destinationParent.conditions.splice(destinationIndex_2 + 1, 0, filterModel);
                }
            }
            else {
                if (indexInParent < parent.conditions.length) {
                    // keep in parent, but swap with next child
                    parent.conditions.splice(indexInParent + 1, 0, filterModel);
                }
                else {
                    // need to move down a level. move after parent in its parent
                    var parentItem = this.items.find(function (itemToCheck) { return itemToCheck.filterModel === parent; });
                    var destinationIndex_3 = parentItem.parent.conditions.indexOf(parentItem.filterModel) + 1;
                    parentItem.parent.conditions.splice(destinationIndex_3, 0, filterModel);
                }
            }
        }
        this.refreshList(false);
        var newIndex = this.items.findIndex(function (_a) {
            var filterModelToCheck = _a.filterModel;
            return filterModelToCheck === filterModel;
        });
        if (newIndex >= 0) {
            var comp = this.virtualList.getComponentAt(newIndex);
            if (comp instanceof AdvancedFilterBuilderItemComp) {
                comp.focusMoveButton(backwards);
            }
        }
    };
    AdvancedFilterBuilderComp.prototype.canMoveDown = function (item, index) {
        return !((item.level === 1 && index === this.items.length - 2) ||
            (item.level === 1 && item.parent.conditions[item.parent.conditions.length - 1] === item.filterModel));
    };
    AdvancedFilterBuilderComp.prototype.close = function () {
        this.advancedFilterService.getCtrl().toggleFilterBuilder('ui');
    };
    AdvancedFilterBuilderComp.prototype.validate = function () {
        var disableApply = !this.items.every(function (_a) {
            var valid = _a.valid;
            return valid;
        });
        if (!disableApply) {
            disableApply = JSON.stringify(this.filterModel) === this.stringifiedModel;
            if (disableApply) {
                this.validationMessage = this.advancedFilterExpressionService.translate('advancedFilterBuilderValidationAlreadyApplied');
            }
            else {
                this.validationMessage = null;
            }
        }
        else {
            this.validationMessage = this.advancedFilterExpressionService.translate('advancedFilterBuilderValidationIncomplete');
        }
        _.setDisabled(this.eApplyFilterButton, disableApply);
        this.validationTooltipFeature.refreshToolTip();
    };
    AdvancedFilterBuilderComp.prototype.validateItems = function () {
        var _this = this;
        var clearOperator = function (filterModel) {
            filterModel.type = undefined;
        };
        var clearOperand = function (filterModel) {
            delete filterModel.filter;
        };
        this.items.forEach(function (item) {
            if (!item.valid || !item.filterModel || item.filterModel.filterType === 'join') {
                return;
            }
            var filterModel = item.filterModel;
            var colId = filterModel.colId;
            var hasColumn = _this.advancedFilterExpressionService.getColumnAutocompleteEntries().find(function (_a) {
                var key = _a.key;
                return key === colId;
            });
            var columnDetails = _this.advancedFilterExpressionService.getColumnDetails(filterModel.colId);
            if (!hasColumn || !columnDetails.column) {
                item.valid = false;
                filterModel.colId = undefined;
                clearOperator(filterModel);
                clearOperand(filterModel);
                return;
            }
            var operatorForType = _this.advancedFilterExpressionService.getDataTypeExpressionOperator(columnDetails.baseCellDataType);
            var operator = operatorForType.operators[filterModel.type];
            if (!operator) {
                item.valid = false;
                clearOperator(filterModel);
                clearOperand(filterModel);
                return;
            }
            if (operator.numOperands > 0 && !_.exists(filterModel.filter)) {
                item.valid = false;
                return;
            }
        });
    };
    __decorate$1S([
        RefSelector('eList')
    ], AdvancedFilterBuilderComp.prototype, "eList", void 0);
    __decorate$1S([
        RefSelector('eApplyFilterButton')
    ], AdvancedFilterBuilderComp.prototype, "eApplyFilterButton", void 0);
    __decorate$1S([
        RefSelector('eCancelFilterButton')
    ], AdvancedFilterBuilderComp.prototype, "eCancelFilterButton", void 0);
    __decorate$1S([
        Autowired('filterManager')
    ], AdvancedFilterBuilderComp.prototype, "filterManager", void 0);
    __decorate$1S([
        Autowired('advancedFilterService')
    ], AdvancedFilterBuilderComp.prototype, "advancedFilterService", void 0);
    __decorate$1S([
        Autowired('advancedFilterExpressionService')
    ], AdvancedFilterBuilderComp.prototype, "advancedFilterExpressionService", void 0);
    __decorate$1S([
        Autowired('beans')
    ], AdvancedFilterBuilderComp.prototype, "beans", void 0);
    __decorate$1S([
        PostConstruct
    ], AdvancedFilterBuilderComp.prototype, "postConstruct", null);
    return AdvancedFilterBuilderComp;
}(Component));

var __extends$2m = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1R = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$E = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$o = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var AdvancedFilterCtrl = /** @class */ (function (_super) {
    __extends$2m(AdvancedFilterCtrl, _super);
    function AdvancedFilterCtrl(enabled) {
        var _this = _super.call(this) || this;
        _this.enabled = enabled;
        return _this;
    }
    AdvancedFilterCtrl.prototype.postConstruct = function () {
        var _this = this;
        this.hasAdvancedFilterParent = !!this.gridOptionsService.get('advancedFilterParent');
        this.ctrlsService.whenReady(function () { return _this.setAdvancedFilterComp(); });
        this.addManagedListener(this.eventService, Events.EVENT_ADVANCED_FILTER_ENABLED_CHANGED, function (_a) {
            var enabled = _a.enabled;
            return _this.onEnabledChanged(enabled);
        });
        this.addManagedPropertyListener('advancedFilterParent', function () { return _this.updateComps(); });
        this.addDestroyFunc(function () {
            _this.destroyAdvancedFilterComp();
            _this.destroyBean(_this.eBuilderComp);
            if (_this.eBuilderDialog && _this.eBuilderDialog.isAlive()) {
                _this.destroyBean(_this.eBuilderDialog);
            }
        });
    };
    AdvancedFilterCtrl.prototype.setupHeaderComp = function (eCompToInsertBefore) {
        this.eHeaderComp = this.createManagedBean(new AdvancedFilterHeaderComp(this.enabled && !this.hasAdvancedFilterParent));
        eCompToInsertBefore.insertAdjacentElement('beforebegin', this.eHeaderComp.getGui());
    };
    AdvancedFilterCtrl.prototype.focusHeaderComp = function () {
        if (this.eHeaderComp) {
            this.eHeaderComp.getFocusableElement().focus();
            return true;
        }
        return false;
    };
    AdvancedFilterCtrl.prototype.refreshComp = function () {
        var _a, _b;
        (_a = this.eFilterComp) === null || _a === void 0 ? void 0 : _a.refresh();
        (_b = this.eHeaderComp) === null || _b === void 0 ? void 0 : _b.refresh();
    };
    AdvancedFilterCtrl.prototype.refreshBuilderComp = function () {
        var _a;
        (_a = this.eBuilderComp) === null || _a === void 0 ? void 0 : _a.refresh();
    };
    AdvancedFilterCtrl.prototype.getHeaderHeight = function () {
        var _a, _b;
        return (_b = (_a = this.eHeaderComp) === null || _a === void 0 ? void 0 : _a.getHeight()) !== null && _b !== void 0 ? _b : 0;
    };
    AdvancedFilterCtrl.prototype.setInputDisabled = function (disabled) {
        var _a, _b;
        (_a = this.eFilterComp) === null || _a === void 0 ? void 0 : _a.setInputDisabled(disabled);
        (_b = this.eHeaderComp) === null || _b === void 0 ? void 0 : _b.setInputDisabled(disabled);
    };
    AdvancedFilterCtrl.prototype.toggleFilterBuilder = function (source, force) {
        var _this = this;
        if ((force && this.eBuilderDialog) || (force === false && !this.eBuilderDialog)) {
            // state requested is already active
            return;
        }
        if (this.eBuilderDialog) {
            this.builderDestroySource = source;
            this.destroyBean(this.eBuilderDialog);
            return;
        }
        this.setInputDisabled(true);
        var _a = this.getBuilderDialogSize(), width = _a.width, height = _a.height, minWidth = _a.minWidth;
        this.eBuilderComp = this.createBean(new AdvancedFilterBuilderComp());
        this.eBuilderDialog = this.createBean(new AgDialog({
            title: this.advancedFilterExpressionService.translate('advancedFilterBuilderTitle'),
            component: this.eBuilderComp,
            width: width,
            height: height,
            resizable: true,
            movable: true,
            maximizable: true,
            centered: true,
            closable: true,
            minWidth: minWidth,
            afterGuiAttached: function () { var _a; return (_a = _this.eBuilderComp) === null || _a === void 0 ? void 0 : _a.afterGuiAttached(); }
        }));
        this.dispatchFilterBuilderVisibleChangedEvent(source, true);
        this.eBuilderDialog.addEventListener(AgDialog.EVENT_DESTROYED, function () {
            var _a;
            _this.destroyBean(_this.eBuilderComp);
            _this.eBuilderComp = undefined;
            _this.eBuilderDialog = undefined;
            _this.setInputDisabled(false);
            _this.dispatchEvent({
                type: AdvancedFilterCtrl.EVENT_BUILDER_CLOSED
            });
            _this.dispatchFilterBuilderVisibleChangedEvent((_a = _this.builderDestroySource) !== null && _a !== void 0 ? _a : 'ui', false);
            _this.builderDestroySource = undefined;
        });
    };
    AdvancedFilterCtrl.prototype.dispatchFilterBuilderVisibleChangedEvent = function (source, visible) {
        var event = {
            type: Events.EVENT_ADVANCED_FILTER_BUILDER_VISIBLE_CHANGED,
            source: source,
            visible: visible
        };
        this.eventService.dispatchEvent(event);
    };
    AdvancedFilterCtrl.prototype.getBuilderDialogSize = function () {
        var _a, _b;
        var minWidth = (_b = (_a = this.gridOptionsService.get('advancedFilterBuilderParams')) === null || _a === void 0 ? void 0 : _a.minWidth) !== null && _b !== void 0 ? _b : 500;
        var popupParent = this.popupService.getPopupParent();
        var maxWidth = Math.round(_.getAbsoluteWidth(popupParent)) - 2; // assume 1 pixel border
        var maxHeight = Math.round(_.getAbsoluteHeight(popupParent) * 0.75) - 2;
        var width = Math.min(Math.max(600, minWidth), maxWidth);
        var height = Math.min(600, maxHeight);
        return { width: width, height: height, minWidth: minWidth };
    };
    AdvancedFilterCtrl.prototype.onEnabledChanged = function (enabled) {
        this.enabled = enabled;
        this.updateComps();
    };
    AdvancedFilterCtrl.prototype.updateComps = function () {
        this.setAdvancedFilterComp();
        this.setHeaderCompEnabled();
        this.eventService.dispatchEvent({
            type: Events.EVENT_HEADER_HEIGHT_CHANGED
        });
    };
    AdvancedFilterCtrl.prototype.setAdvancedFilterComp = function () {
        var _a;
        this.destroyAdvancedFilterComp();
        if (!this.enabled) {
            return;
        }
        var advancedFilterParent = this.gridOptionsService.get('advancedFilterParent');
        this.hasAdvancedFilterParent = !!advancedFilterParent;
        if (advancedFilterParent) {
            // unmanaged as can be recreated
            var eAdvancedFilterComp = this.createBean(new AdvancedFilterComp());
            var eAdvancedFilterCompGui = eAdvancedFilterComp.getGui();
            var allThemes = this.environment.getTheme().allThemes;
            if (allThemes.length) {
                (_a = eAdvancedFilterCompGui.classList).add.apply(_a, __spreadArray$o([], __read$E(allThemes), false));
            }
            eAdvancedFilterCompGui.classList.add(this.gridOptionsService.get('enableRtl') ? 'ag-rtl' : 'ag-ltr');
            advancedFilterParent.appendChild(eAdvancedFilterCompGui);
            this.eFilterComp = eAdvancedFilterComp;
        }
    };
    AdvancedFilterCtrl.prototype.setHeaderCompEnabled = function () {
        var _a;
        (_a = this.eHeaderComp) === null || _a === void 0 ? void 0 : _a.setEnabled(this.enabled && !this.hasAdvancedFilterParent);
    };
    AdvancedFilterCtrl.prototype.destroyAdvancedFilterComp = function () {
        if (this.eFilterComp) {
            _.removeFromParent(this.eFilterComp.getGui());
            this.destroyBean(this.eFilterComp);
        }
    };
    AdvancedFilterCtrl.EVENT_BUILDER_CLOSED = 'advancedFilterBuilderClosed';
    __decorate$1R([
        Autowired('focusService')
    ], AdvancedFilterCtrl.prototype, "focusService", void 0);
    __decorate$1R([
        Autowired('ctrlsService')
    ], AdvancedFilterCtrl.prototype, "ctrlsService", void 0);
    __decorate$1R([
        Autowired('popupService')
    ], AdvancedFilterCtrl.prototype, "popupService", void 0);
    __decorate$1R([
        Autowired('advancedFilterExpressionService')
    ], AdvancedFilterCtrl.prototype, "advancedFilterExpressionService", void 0);
    __decorate$1R([
        PostConstruct
    ], AdvancedFilterCtrl.prototype, "postConstruct", null);
    return AdvancedFilterCtrl;
}(BeanStub));

var __extends$2l = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1Q = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AdvancedFilterComp = /** @class */ (function (_super) {
    __extends$2l(AdvancedFilterComp, _super);
    function AdvancedFilterComp() {
        var _this = _super.call(this, /* html */ "\n            <div class=\"ag-advanced-filter\" role=\"presentation\" tabindex=\"-1\">\n                <ag-autocomplete ref=\"eAutocomplete\"></ag-autocomplete>\n                <button class=\"ag-button ag-standard-button ag-advanced-filter-apply-button\" ref=\"eApplyFilterButton\"></button>\n                <button class=\"ag-advanced-filter-builder-button\" ref=\"eBuilderFilterButton\">\n                    <span ref=\"eBuilderFilterButtonIcon\" aria-hidden=\"true\"></span>\n                    <span class=\"ag-advanced-filter-builder-button-label\" ref=\"eBuilderFilterButtonLabel\"></span>\n                </button>\n            </div>") || this;
        _this.expressionParser = null;
        _this.isApplyDisabled = true;
        _this.builderOpen = false;
        return _this;
    }
    AdvancedFilterComp.prototype.postConstruct = function () {
        var _this = this;
        this.eAutocomplete
            .setListGenerator(function (_value, position) { return _this.generateAutocompleteListParams(position); })
            .setValidator(function () { return _this.validateValue(); })
            .setForceLastSelection(function (lastSelection, searchString) { return _this.forceLastSelection(lastSelection, searchString); })
            .setInputAriaLabel(this.advancedFilterExpressionService.translate('ariaAdvancedFilterInput'))
            .setListAriaLabel(this.advancedFilterExpressionService.translate('ariaLabelAdvancedFilterAutocomplete'));
        this.refresh();
        this.addManagedListener(this.eAutocomplete, AgAutocomplete.EVENT_VALUE_CHANGED, function (_a) {
            var value = _a.value;
            return _this.onValueChanged(value);
        });
        this.addManagedListener(this.eAutocomplete, AgAutocomplete.EVENT_VALUE_CONFIRMED, function (_a) {
            var isValid = _a.isValid;
            return _this.onValueConfirmed(isValid);
        });
        this.addManagedListener(this.eAutocomplete, AgAutocomplete.EVENT_OPTION_SELECTED, function (_a) {
            var position = _a.position, updateEntry = _a.updateEntry, autocompleteType = _a.autocompleteType;
            return _this.onOptionSelected(position, updateEntry, autocompleteType);
        });
        this.addManagedListener(this.eAutocomplete, AgAutocomplete.EVENT_VALID_CHANGED, function (_a) {
            var isValid = _a.isValid, validationMessage = _a.validationMessage;
            return _this.onValidChanged(isValid, validationMessage);
        });
        this.setupApplyButton();
        this.setupBuilderButton();
    };
    AdvancedFilterComp.prototype.refresh = function () {
        var expression = this.advancedFilterService.getExpressionDisplayValue();
        this.eAutocomplete.setValue({ value: expression !== null && expression !== void 0 ? expression : '', position: expression === null || expression === void 0 ? void 0 : expression.length, updateListOnlyIfOpen: true });
    };
    AdvancedFilterComp.prototype.setInputDisabled = function (disabled) {
        this.eAutocomplete.setInputDisabled(disabled);
        _.setDisabled(this.eApplyFilterButton, disabled || this.isApplyDisabled);
    };
    AdvancedFilterComp.prototype.getTooltipParams = function () {
        var res = _super.prototype.getTooltipParams.call(this);
        res.location = 'advancedFilter';
        return res;
    };
    AdvancedFilterComp.prototype.setupApplyButton = function () {
        var _this = this;
        this.eApplyFilterButton.innerText = this.advancedFilterExpressionService.translate('advancedFilterApply');
        this.activateTabIndex([this.eApplyFilterButton]);
        this.addManagedListener(this.eApplyFilterButton, 'click', function () { return _this.onValueConfirmed(_this.eAutocomplete.isValid()); });
        _.setDisabled(this.eApplyFilterButton, this.isApplyDisabled);
    };
    AdvancedFilterComp.prototype.setupBuilderButton = function () {
        var _this = this;
        this.eBuilderFilterButtonIcon.appendChild(_.createIconNoSpan('advancedFilterBuilder', this.gridOptionsService));
        this.eBuilderFilterButtonLabel.innerText = this.advancedFilterExpressionService.translate('advancedFilterBuilder');
        this.activateTabIndex([this.eBuilderFilterButton]);
        this.addManagedListener(this.eBuilderFilterButton, 'click', function () { return _this.openBuilder(); });
        this.addManagedListener(this.advancedFilterService.getCtrl(), AdvancedFilterCtrl.EVENT_BUILDER_CLOSED, function () { return _this.closeBuilder(); });
    };
    AdvancedFilterComp.prototype.onValueChanged = function (value) {
        var _a;
        value = _.makeNull(value);
        this.advancedFilterService.setExpressionDisplayValue(value);
        this.expressionParser = this.advancedFilterService.createExpressionParser(value);
        var updatedExpression = (_a = this.expressionParser) === null || _a === void 0 ? void 0 : _a.parseExpression();
        if (updatedExpression && updatedExpression !== value) {
            this.eAutocomplete.setValue({ value: updatedExpression, silent: true, restoreFocus: true });
        }
    };
    AdvancedFilterComp.prototype.onValueConfirmed = function (isValid) {
        if (!isValid || this.isApplyDisabled) {
            return;
        }
        _.setDisabled(this.eApplyFilterButton, true);
        this.advancedFilterService.applyExpression();
        this.filterManager.onFilterChanged({ source: 'advancedFilter' });
    };
    AdvancedFilterComp.prototype.onOptionSelected = function (position, updateEntry, type) {
        var _a = this.updateExpression(position, updateEntry, type), updatedValue = _a.updatedValue, updatedPosition = _a.updatedPosition, hideAutocomplete = _a.hideAutocomplete;
        this.eAutocomplete.setValue({
            value: updatedValue,
            position: updatedPosition,
            updateListOnlyIfOpen: hideAutocomplete,
            restoreFocus: true
        });
    };
    AdvancedFilterComp.prototype.validateValue = function () {
        var _a, _b, _c;
        return ((_a = this.expressionParser) === null || _a === void 0 ? void 0 : _a.isValid()) ? null : ((_c = (_b = this.expressionParser) === null || _b === void 0 ? void 0 : _b.getValidationMessage()) !== null && _c !== void 0 ? _c : null);
    };
    AdvancedFilterComp.prototype.onValidChanged = function (isValid, validationMessage) {
        this.isApplyDisabled = !isValid || this.advancedFilterService.isCurrentExpressionApplied();
        _.setDisabled(this.eApplyFilterButton, this.isApplyDisabled);
        this.setTooltip(validationMessage, 1000);
    };
    AdvancedFilterComp.prototype.generateAutocompleteListParams = function (position) {
        return this.expressionParser
            ? this.expressionParser.getAutocompleteListParams(position)
            : this.advancedFilterExpressionService.getDefaultAutocompleteListParams('');
    };
    AdvancedFilterComp.prototype.updateExpression = function (position, updateEntry, type) {
        var _a, _b;
        this.advancedFilterExpressionService.updateAutocompleteCache(updateEntry, type);
        return (_b = (_a = this.expressionParser) === null || _a === void 0 ? void 0 : _a.updateExpression(position, updateEntry, type)) !== null && _b !== void 0 ? _b : this.advancedFilterService.getDefaultExpression(updateEntry);
    };
    AdvancedFilterComp.prototype.forceLastSelection = function (_a, searchString) {
        var key = _a.key, displayValue = _a.displayValue;
        return !!searchString.toLocaleLowerCase().match("^".concat((displayValue !== null && displayValue !== void 0 ? displayValue : key).toLocaleLowerCase(), "\\s*$"));
    };
    AdvancedFilterComp.prototype.openBuilder = function () {
        if (this.builderOpen) {
            return;
        }
        this.builderOpen = true;
        _.setDisabled(this.eBuilderFilterButton, true);
        this.advancedFilterService.getCtrl().toggleFilterBuilder('ui');
    };
    AdvancedFilterComp.prototype.closeBuilder = function () {
        if (!this.builderOpen) {
            return;
        }
        this.builderOpen = false;
        _.setDisabled(this.eBuilderFilterButton, false);
        this.eBuilderFilterButton.focus();
    };
    __decorate$1Q([
        RefSelector('eAutocomplete')
    ], AdvancedFilterComp.prototype, "eAutocomplete", void 0);
    __decorate$1Q([
        RefSelector('eApplyFilterButton')
    ], AdvancedFilterComp.prototype, "eApplyFilterButton", void 0);
    __decorate$1Q([
        RefSelector('eBuilderFilterButton')
    ], AdvancedFilterComp.prototype, "eBuilderFilterButton", void 0);
    __decorate$1Q([
        RefSelector('eBuilderFilterButtonIcon')
    ], AdvancedFilterComp.prototype, "eBuilderFilterButtonIcon", void 0);
    __decorate$1Q([
        RefSelector('eBuilderFilterButtonLabel')
    ], AdvancedFilterComp.prototype, "eBuilderFilterButtonLabel", void 0);
    __decorate$1Q([
        Autowired('advancedFilterService')
    ], AdvancedFilterComp.prototype, "advancedFilterService", void 0);
    __decorate$1Q([
        Autowired('advancedFilterExpressionService')
    ], AdvancedFilterComp.prototype, "advancedFilterExpressionService", void 0);
    __decorate$1Q([
        Autowired('filterManager')
    ], AdvancedFilterComp.prototype, "filterManager", void 0);
    __decorate$1Q([
        PostConstruct
    ], AdvancedFilterComp.prototype, "postConstruct", null);
    return AdvancedFilterComp;
}(Component));

var ADVANCED_FILTER_LOCALE_TEXT = {
    ariaAdvancedFilterBuilderItem: function (variableValues) { return "".concat(variableValues[0], ". Level ").concat(variableValues[1], ". Press ENTER to edit."); },
    ariaAdvancedFilterBuilderItemValidation: function (variableValues) { return "".concat(variableValues[0], ". Level ").concat(variableValues[1], ". ").concat(variableValues[2], " Press ENTER to edit."); },
    ariaAdvancedFilterBuilderList: 'Advanced Filter Builder List',
    ariaAdvancedFilterBuilderFilterItem: 'Filter Condition',
    ariaAdvancedFilterBuilderGroupItem: 'Filter Group',
    ariaAdvancedFilterBuilderColumn: 'Column',
    ariaAdvancedFilterBuilderOption: 'Option',
    ariaAdvancedFilterBuilderValue: 'Value',
    ariaAdvancedFilterBuilderJoinOperator: 'Join Operator',
    ariaAdvancedFilterInput: 'Advanced Filter Input',
    ariaLabelAdvancedFilterAutocomplete: 'Advanced Filter Autocomplete',
    advancedFilterContains: 'contains',
    advancedFilterNotContains: 'does not contain',
    advancedFilterTextEquals: 'equals',
    advancedFilterTextNotEqual: 'does not equal',
    advancedFilterStartsWith: 'begins with',
    advancedFilterEndsWith: 'ends with',
    advancedFilterBlank: 'is blank',
    advancedFilterNotBlank: 'is not blank',
    advancedFilterEquals: '=',
    advancedFilterNotEqual: '!=',
    advancedFilterGreaterThan: '>',
    advancedFilterGreaterThanOrEqual: '>=',
    advancedFilterLessThan: '<',
    advancedFilterLessThanOrEqual: '<=',
    advancedFilterTrue: 'is true',
    advancedFilterFalse: 'is false',
    advancedFilterAnd: 'AND',
    advancedFilterOr: 'OR',
    advancedFilterApply: 'Apply',
    advancedFilterBuilder: 'Builder',
    advancedFilterValidationMissingColumn: 'Column is missing',
    advancedFilterValidationMissingOption: 'Option is missing',
    advancedFilterValidationMissingValue: 'Value is missing',
    advancedFilterValidationInvalidColumn: 'Column not found',
    advancedFilterValidationInvalidOption: 'Option not found',
    advancedFilterValidationMissingQuote: 'Value is missing an end quote',
    advancedFilterValidationNotANumber: 'Value is not a number',
    advancedFilterValidationInvalidDate: 'Value is not a valid date',
    advancedFilterValidationMissingCondition: 'Condition is missing',
    advancedFilterValidationJoinOperatorMismatch: 'Join operators within a condition must be the same',
    advancedFilterValidationInvalidJoinOperator: 'Join operator not found',
    advancedFilterValidationMissingEndBracket: 'Missing end bracket',
    advancedFilterValidationExtraEndBracket: 'Too many end brackets',
    advancedFilterValidationMessage: function (variableValues) { return "Expression has an error. ".concat(variableValues[0], " - ").concat(variableValues[1], "."); },
    advancedFilterValidationMessageAtEnd: function (variableValues) { return "Expression has an error. ".concat(variableValues[0], " at end of expression."); },
    advancedFilterBuilderTitle: 'Advanced Filter',
    advancedFilterBuilderApply: 'Apply',
    advancedFilterBuilderCancel: 'Cancel',
    advancedFilterBuilderAddButtonTooltip: 'Add Filter or Group',
    advancedFilterBuilderRemoveButtonTooltip: 'Remove',
    advancedFilterBuilderMoveUpButtonTooltip: 'Move Up',
    advancedFilterBuilderMoveDownButtonTooltip: 'Move Down',
    advancedFilterBuilderAddJoin: 'Add Group',
    advancedFilterBuilderAddCondition: 'Add Filter',
    advancedFilterBuilderSelectColumn: 'Select a column',
    advancedFilterBuilderSelectOption: 'Select an option',
    advancedFilterBuilderEnterValue: 'Enter a value...',
    advancedFilterBuilderValidationAlreadyApplied: 'Current filter already applied.',
    advancedFilterBuilderValidationIncomplete: 'Not all conditions are complete.',
    advancedFilterBuilderValidationSelectColumn: 'Must select a column.',
    advancedFilterBuilderValidationSelectOption: 'Must select an option.',
    advancedFilterBuilderValidationEnterValue: 'Must enter a value.',
};

function getSearchString(value, position, endPosition) {
    if (!value) {
        return '';
    }
    var numChars = endPosition - position;
    return numChars ? value.slice(0, value.length - numChars) : value;
}
function updateExpression(expression, startPosition, endPosition, updatedValuePart, appendSpace, appendQuote, empty) {
    var secondPartStartPosition = endPosition + (!expression.length || empty ? 0 : 1);
    var positionOffset = 0;
    if (appendSpace) {
        if (expression[secondPartStartPosition] === ' ') {
            // already a space, just move the position
            positionOffset = 1;
        }
        else {
            updatedValuePart += ' ';
            if (appendQuote) {
                updatedValuePart += "\"";
            }
        }
    }
    var updatedValue = expression.slice(0, startPosition) + updatedValuePart + expression.slice(secondPartStartPosition);
    return { updatedValue: updatedValue, updatedPosition: startPosition + updatedValuePart.length + positionOffset };
}
function findStartPosition(expression, position, endPosition) {
    var startPosition = position;
    while (startPosition < endPosition) {
        var char = expression[startPosition];
        if (char !== ' ') {
            break;
        }
        startPosition++;
    }
    return startPosition;
}
function findEndPosition(expression, position, includeCloseBracket, isStartPositionUnknown) {
    var endPosition = position;
    var isEmpty = false;
    while (endPosition < expression.length) {
        var char = expression[endPosition];
        if (char === '(') {
            if (isStartPositionUnknown && expression[endPosition - 1] === ' ') {
                isEmpty = true;
            }
            else {
                endPosition = endPosition - 1;
            }
            break;
        }
        else if (char === ' ' || (includeCloseBracket && char === ')')) {
            endPosition = endPosition - 1;
            break;
        }
        endPosition++;
    }
    return { endPosition: endPosition, isEmpty: isEmpty };
}
function checkAndUpdateExpression(params, userValue, displayValue, endPosition) {
    if (displayValue !== userValue) {
        params.expression = updateExpression(params.expression, endPosition - userValue.length + 1, endPosition, displayValue).updatedValue;
    }
}
function escapeQuotes(value) {
    return value.replace(/(['"])/, '\\$1');
}

var __assign$y = (undefined && undefined.__assign) || function () {
    __assign$y = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$y.apply(this, arguments);
};
var ColumnParser = /** @class */ (function () {
    function ColumnParser(params, startPosition) {
        this.params = params;
        this.startPosition = startPosition;
        this.type = 'column';
        this.valid = true;
        this.hasStartChar = false;
        this.hasEndChar = false;
        this.colName = '';
    }
    ColumnParser.prototype.parse = function (char, position) {
        if (char === ColFilterExpressionParser.COL_START_CHAR && !this.colName) {
            this.hasStartChar = true;
        }
        else if (char === ColFilterExpressionParser.COL_END_CHAR && this.hasStartChar) {
            var isMatch = this.parseColumn(false, position);
            if (isMatch) {
                this.hasEndChar = true;
                return false;
            }
            else {
                this.colName += char;
            }
        }
        else {
            this.colName += char;
        }
        return undefined;
    };
    ColumnParser.prototype.getDisplayValue = function () {
        return (this.hasStartChar ? ColFilterExpressionParser.COL_START_CHAR : '') +
            this.colName +
            (this.hasEndChar ? ColFilterExpressionParser.COL_END_CHAR : '');
    };
    ColumnParser.prototype.getColId = function () {
        return this.colId;
    };
    ColumnParser.prototype.complete = function (position) {
        this.parseColumn(true, position);
    };
    ColumnParser.prototype.getValidationError = function () {
        var _a;
        return this.valid ? null : {
            message: this.params.advancedFilterExpressionService.translate('advancedFilterValidationInvalidColumn'),
            startPosition: this.startPosition,
            endPosition: (_a = this.endPosition) !== null && _a !== void 0 ? _a : this.params.expression.length - 1
        };
    };
    ColumnParser.prototype.parseColumn = function (fromComplete, endPosition) {
        var _a;
        this.endPosition = endPosition;
        var colValue = this.params.advancedFilterExpressionService.getColId(this.colName);
        if (colValue && this.hasStartChar) {
            this.colId = colValue.colId;
            checkAndUpdateExpression(this.params, this.colName, colValue.columnName, endPosition - 1);
            this.colName = colValue.columnName;
            this.column = this.params.columnModel.getPrimaryColumn(this.colId);
            if (this.column) {
                this.baseCellDataType = (_a = this.params.dataTypeService.getBaseDataType(this.column)) !== null && _a !== void 0 ? _a : 'text';
                return true;
            }
        }
        if (fromComplete) {
            this.valid = false;
        }
        this.baseCellDataType = 'text';
        return false;
    };
    return ColumnParser;
}());
var OperatorParser$1 = /** @class */ (function () {
    function OperatorParser(params, startPosition, baseCellDataType) {
        this.params = params;
        this.startPosition = startPosition;
        this.baseCellDataType = baseCellDataType;
        this.type = 'operator';
        this.valid = true;
        this.expectedNumOperands = 0;
        this.operator = '';
    }
    OperatorParser.prototype.parse = function (char, position) {
        if (char === ' ' || char === ')') {
            var isMatch = this.parseOperator(false, position - 1);
            if (isMatch) {
                return true;
            }
            else {
                this.operator += char;
            }
        }
        else {
            this.operator += char;
        }
        return undefined;
    };
    OperatorParser.prototype.complete = function (position) {
        this.parseOperator(true, position);
    };
    OperatorParser.prototype.getValidationError = function () {
        var _a;
        return this.valid ? null : {
            message: this.params.advancedFilterExpressionService.translate('advancedFilterValidationInvalidOption'),
            startPosition: this.startPosition,
            endPosition: (_a = this.endPosition) !== null && _a !== void 0 ? _a : this.params.expression.length - 1
        };
    };
    OperatorParser.prototype.getDisplayValue = function () {
        return this.operator;
    };
    OperatorParser.prototype.getOperatorKey = function () {
        return this.parsedOperator;
    };
    OperatorParser.prototype.parseOperator = function (fromComplete, endPosition) {
        var operatorForType = this.params.advancedFilterExpressionService.getDataTypeExpressionOperator(this.baseCellDataType);
        var parsedOperator = operatorForType.findOperator(this.operator);
        this.endPosition = endPosition;
        if (parsedOperator) {
            this.parsedOperator = parsedOperator;
            var operator = operatorForType.operators[parsedOperator];
            this.expectedNumOperands = operator.numOperands;
            var operatorDisplayValue = operator.displayValue;
            checkAndUpdateExpression(this.params, this.operator, operatorDisplayValue, endPosition);
            this.operator = operatorDisplayValue;
            return true;
        }
        var isPartialMatch = parsedOperator === null;
        if (fromComplete || !isPartialMatch) {
            this.valid = false;
        }
        return false;
    };
    return OperatorParser;
}());
var OperandParser = /** @class */ (function () {
    function OperandParser(params, startPosition, baseCellDataType, column) {
        this.params = params;
        this.startPosition = startPosition;
        this.baseCellDataType = baseCellDataType;
        this.column = column;
        this.type = 'operand';
        this.valid = true;
        this.operand = '';
        this.validationMessage = null;
    }
    OperandParser.prototype.parse = function (char, position) {
        if (char === ' ') {
            if (this.quotes) {
                this.operand += char;
            }
            else {
                this.parseOperand(false, position);
                return true;
            }
        }
        else if (char === ')') {
            if (this.baseCellDataType === 'number' || !this.quotes) {
                this.parseOperand(false, position - 1);
                return true;
            }
            else {
                this.operand += char;
            }
        }
        else if (!this.operand && !this.quotes && (char === "'" || char === "\"")) {
            this.quotes = char;
        }
        else if (this.quotes && char === this.quotes) {
            this.parseOperand(false, position);
            return false;
        }
        else {
            this.operand += char;
        }
        return undefined;
    };
    OperandParser.prototype.complete = function (position) {
        this.parseOperand(true, position);
    };
    OperandParser.prototype.getValidationError = function () {
        var _a;
        return this.validationMessage ? {
            message: this.validationMessage,
            startPosition: this.startPosition,
            endPosition: (_a = this.endPosition) !== null && _a !== void 0 ? _a : this.params.expression.length - 1
        } : null;
    };
    OperandParser.prototype.getRawValue = function () {
        return this.operand;
    };
    OperandParser.prototype.getModelValue = function () {
        return this.modelValue;
    };
    OperandParser.prototype.parseOperand = function (fromComplete, position) {
        var advancedFilterExpressionService = this.params.advancedFilterExpressionService;
        this.endPosition = position;
        this.modelValue = this.operand;
        if (fromComplete && this.quotes) {
            // missing end quote
            this.valid = false;
            this.validationMessage = advancedFilterExpressionService.translate('advancedFilterValidationMissingQuote');
        }
        else if (this.modelValue === '') {
            this.valid = false;
            this.validationMessage = advancedFilterExpressionService.translate('advancedFilterValidationMissingValue');
        }
        else {
            var modelValue = advancedFilterExpressionService.getOperandModelValue(this.operand, this.baseCellDataType, this.column);
            if (modelValue != null) {
                this.modelValue = modelValue;
            }
            switch (this.baseCellDataType) {
                case 'number':
                    if (this.quotes || isNaN(this.modelValue)) {
                        this.valid = false;
                        this.validationMessage = advancedFilterExpressionService.translate('advancedFilterValidationNotANumber');
                    }
                    break;
                case 'date':
                case 'dateString':
                    if (modelValue == null) {
                        this.valid = false;
                        this.validationMessage = advancedFilterExpressionService.translate('advancedFilterValidationInvalidDate');
                    }
                    break;
            }
        }
    };
    return OperandParser;
}());
var ColFilterExpressionParser = /** @class */ (function () {
    function ColFilterExpressionParser(params, startPosition) {
        this.params = params;
        this.startPosition = startPosition;
        this.isAwaiting = true;
    }
    ColFilterExpressionParser.prototype.parseExpression = function () {
        var _a, _b;
        var i = this.startPosition;
        var expression = this.params.expression;
        while (i < expression.length) {
            var char = expression[i];
            if (char === ' ' && this.isAwaiting) ;
            else {
                this.isAwaiting = false;
                if (!this.parser) {
                    var parser = void 0;
                    if (!this.columnParser) {
                        this.columnParser = new ColumnParser(this.params, i);
                        parser = this.columnParser;
                    }
                    else if (!this.operatorParser) {
                        this.operatorParser = new OperatorParser$1(this.params, i, this.columnParser.baseCellDataType);
                        parser = this.operatorParser;
                    }
                    else {
                        this.operandParser = new OperandParser(this.params, i, this.columnParser.baseCellDataType, this.columnParser.column);
                        parser = this.operandParser;
                    }
                    this.parser = parser;
                }
                var hasCompletedOnPrevChar = this.parser.parse(char, i);
                if (hasCompletedOnPrevChar != null) {
                    if (this.isComplete()) {
                        return this.returnEndPosition(hasCompletedOnPrevChar ? i - 1 : i, true);
                    }
                    this.parser = undefined;
                    this.isAwaiting = true;
                }
            }
            i++;
        }
        (_b = (_a = this.parser) === null || _a === void 0 ? void 0 : _a.complete) === null || _b === void 0 ? void 0 : _b.call(_a, i - 1);
        return this.returnEndPosition(i);
    };
    ColFilterExpressionParser.prototype.isValid = function () {
        return this.isComplete() && this.columnParser.valid && this.operatorParser.valid && (!this.operandParser || this.operandParser.valid);
    };
    ColFilterExpressionParser.prototype.getValidationError = function () {
        var _a, _b, _c, _d, _e;
        var validationError = (_d = (_b = (_a = this.columnParser) === null || _a === void 0 ? void 0 : _a.getValidationError()) !== null && _b !== void 0 ? _b : (_c = this.operatorParser) === null || _c === void 0 ? void 0 : _c.getValidationError()) !== null && _d !== void 0 ? _d : (_e = this.operandParser) === null || _e === void 0 ? void 0 : _e.getValidationError();
        if (validationError) {
            return validationError;
        }
        var endPosition = this.params.expression.length;
        var translateKey;
        if (!this.columnParser) {
            translateKey = 'advancedFilterValidationMissingColumn';
        }
        else if (!this.operatorParser) {
            translateKey = 'advancedFilterValidationMissingOption';
        }
        else if (this.operatorParser.expectedNumOperands && !this.operandParser) {
            translateKey = 'advancedFilterValidationMissingValue';
        }
        if (translateKey) {
            return {
                message: this.params.advancedFilterExpressionService.translate(translateKey),
                startPosition: endPosition,
                endPosition: endPosition
            };
        }
        return null;
    };
    ColFilterExpressionParser.prototype.getFunction = function (params) {
        var _a, _b;
        var colId = this.columnParser.getColId();
        var escapedColId = escapeQuotes(colId);
        var operator = (_a = this.operatorParser) === null || _a === void 0 ? void 0 : _a.getOperatorKey();
        var operators = params.operators, evaluatorParams = params.evaluatorParams, operands = params.operands;
        var operatorForColumn = this.params.advancedFilterExpressionService.getExpressionOperator(this.columnParser.baseCellDataType, operator);
        var operatorIndex = this.addToListAndGetIndex(operators, operatorForColumn);
        var evaluatorParamsForColumn = this.params.advancedFilterExpressionService.getExpressionEvaluatorParams(colId);
        var evaluatorParamsIndex = this.addToListAndGetIndex(evaluatorParams, evaluatorParamsForColumn);
        var operand;
        if (((_b = this.operatorParser) === null || _b === void 0 ? void 0 : _b.expectedNumOperands) === 0) {
            operand = '';
        }
        else {
            var operandIndex = this.addToListAndGetIndex(operands, this.getOperandValue());
            operand = ", params.operands[".concat(operandIndex, "]");
        }
        return "params.operators[".concat(operatorIndex, "].evaluator(expressionProxy.getValue('").concat(escapedColId, "', node), node, params.evaluatorParams[").concat(evaluatorParamsIndex, "]").concat(operand, ")");
    };
    ColFilterExpressionParser.prototype.getAutocompleteListParams = function (position) {
        if (this.isColumnPosition(position)) {
            return this.getColumnAutocompleteListParams(position);
        }
        if (this.isOperatorPosition(position)) {
            return this.getOperatorAutocompleteListParams(position);
        }
        if (this.isBeyondEndPosition(position)) {
            return undefined;
        }
        return { enabled: false };
    };
    ColFilterExpressionParser.prototype.updateExpression = function (position, updateEntry, type) {
        var _a, _b, _c, _d, _e;
        var expression = this.params.expression;
        if (this.isColumnPosition(position)) {
            return updateExpression(this.params.expression, this.startPosition, ((_a = this.columnParser) === null || _a === void 0 ? void 0 : _a.getColId()) ? this.columnParser.endPosition : findEndPosition(expression, position).endPosition, this.params.advancedFilterExpressionService.getColumnValue(updateEntry), true);
        }
        else if (this.isOperatorPosition(position)) {
            var baseCellDataType = this.getBaseCellDataTypeFromOperatorAutocompleteType(type);
            var hasOperand = this.hasOperand(baseCellDataType, updateEntry.key);
            var doesOperandNeedQuotes = hasOperand && this.doesOperandNeedQuotes(baseCellDataType);
            var update = void 0;
            if (((_b = this.operatorParser) === null || _b === void 0 ? void 0 : _b.startPosition) != null && position < this.operatorParser.startPosition) {
                // in between multiple spaces, just insert direct
                update = updateExpression(expression, position, position, (_c = updateEntry.displayValue) !== null && _c !== void 0 ? _c : updateEntry.key, hasOperand, doesOperandNeedQuotes);
            }
            else {
                var endPosition = void 0;
                var empty = false;
                if ((_d = this.operatorParser) === null || _d === void 0 ? void 0 : _d.getOperatorKey()) {
                    endPosition = this.operatorParser.endPosition;
                }
                else {
                    var _f = findEndPosition(expression, position, true, true), calculatedEndPosition = _f.endPosition, isEmpty = _f.isEmpty;
                    endPosition = calculatedEndPosition;
                    empty = isEmpty;
                }
                update = updateExpression(expression, findStartPosition(expression, this.columnParser.endPosition + 1, endPosition), endPosition, (_e = updateEntry.displayValue) !== null && _e !== void 0 ? _e : updateEntry.key, hasOperand, doesOperandNeedQuotes, empty);
            }
            return __assign$y(__assign$y({}, update), { hideAutocomplete: !hasOperand });
        }
        return null;
    };
    ColFilterExpressionParser.prototype.getModel = function () {
        var colId = this.columnParser.getColId();
        var model = {
            filterType: this.columnParser.baseCellDataType,
            colId: colId,
            type: this.operatorParser.getOperatorKey(),
        };
        if (this.operatorParser.expectedNumOperands) {
            model.filter = this.operandParser.getModelValue();
        }
        return model;
    };
    ColFilterExpressionParser.prototype.getOperandValue = function () {
        var operand = this.operandParser.getRawValue();
        var _a = this.columnParser, baseCellDataType = _a.baseCellDataType, column = _a.column;
        switch (baseCellDataType) {
            case 'number':
                operand = Number(operand);
                break;
            case 'date':
            case 'dateString':
                operand = this.params.valueParserService.parseValue(column, null, operand, undefined);
                break;
        }
        if (baseCellDataType === 'dateString') {
            return this.params.dataTypeService.getDateParserFunction()(operand);
        }
        return operand;
    };
    ColFilterExpressionParser.prototype.isComplete = function () {
        return !!(this.operatorParser && (!this.operatorParser.expectedNumOperands || (this.operatorParser.expectedNumOperands && this.operandParser)));
    };
    ColFilterExpressionParser.prototype.isColumnPosition = function (position) {
        return !this.columnParser || this.columnParser.endPosition == null || position <= this.columnParser.endPosition + 1;
    };
    ColFilterExpressionParser.prototype.isOperatorPosition = function (position) {
        return !this.operatorParser || this.operatorParser.endPosition == null || position <= this.operatorParser.endPosition + 1;
    };
    ColFilterExpressionParser.prototype.isBeyondEndPosition = function (position) {
        return this.isComplete() && this.endPosition != null && position > this.endPosition + 1 && this.endPosition + 1 < this.params.expression.length;
    };
    ColFilterExpressionParser.prototype.returnEndPosition = function (returnPosition, treatAsEnd) {
        this.endPosition = treatAsEnd ? returnPosition : returnPosition - 1;
        return returnPosition;
    };
    ColFilterExpressionParser.prototype.getColumnAutocompleteListParams = function (position) {
        return this.params.advancedFilterExpressionService.generateAutocompleteListParams(this.params.advancedFilterExpressionService.getColumnAutocompleteEntries(), 'column', this.getColumnSearchString(position));
    };
    ColFilterExpressionParser.prototype.getColumnSearchString = function (position) {
        var _a, _b, _c, _d, _e;
        var columnName = (_b = (_a = this.columnParser) === null || _a === void 0 ? void 0 : _a.getDisplayValue()) !== null && _b !== void 0 ? _b : '';
        var searchString = getSearchString(columnName, position, ((_c = this.columnParser) === null || _c === void 0 ? void 0 : _c.endPosition) == null
            ? this.params.expression.length
            : (this.columnParser.endPosition + 1));
        var containsStartChar = ((_d = this.columnParser) === null || _d === void 0 ? void 0 : _d.hasStartChar) && searchString.length > 0;
        var containsEndChar = ((_e = this.columnParser) === null || _e === void 0 ? void 0 : _e.hasEndChar) && searchString.length === columnName.length + 2;
        if (containsStartChar) {
            return searchString.slice(1, containsEndChar ? -1 : undefined);
        }
        return searchString;
    };
    ColFilterExpressionParser.prototype.getOperatorAutocompleteListParams = function (position) {
        var _a, _b, _c, _d, _e;
        var column = (_a = this.columnParser) === null || _a === void 0 ? void 0 : _a.column;
        if (!column) {
            return { enabled: false };
        }
        var baseCellDataType = this.columnParser.baseCellDataType;
        var searchString = ((_b = this.operatorParser) === null || _b === void 0 ? void 0 : _b.startPosition) != null && position < this.operatorParser.startPosition ? '' : getSearchString((_d = (_c = this.operatorParser) === null || _c === void 0 ? void 0 : _c.getDisplayValue()) !== null && _d !== void 0 ? _d : '', position, ((_e = this.operatorParser) === null || _e === void 0 ? void 0 : _e.endPosition) == null ? this.params.expression.length : (this.operatorParser.endPosition + 1));
        return this.params.advancedFilterExpressionService.generateAutocompleteListParams(this.params.advancedFilterExpressionService.getOperatorAutocompleteEntries(column, baseCellDataType), "operator-".concat(baseCellDataType), searchString);
    };
    ColFilterExpressionParser.prototype.getBaseCellDataTypeFromOperatorAutocompleteType = function (type) {
        return type === null || type === void 0 ? void 0 : type.replace('operator-', '');
    };
    ColFilterExpressionParser.prototype.hasOperand = function (baseCellDataType, operator) {
        var _a, _b;
        return !baseCellDataType ||
            !operator ||
            ((_b = (_a = this.params.advancedFilterExpressionService.getExpressionOperator(baseCellDataType, operator)) === null || _a === void 0 ? void 0 : _a.numOperands) !== null && _b !== void 0 ? _b : 0) > 0;
    };
    ColFilterExpressionParser.prototype.doesOperandNeedQuotes = function (baseCellDataType) {
        return baseCellDataType !== 'number';
    };
    ColFilterExpressionParser.prototype.addToListAndGetIndex = function (list, value) {
        var index = list.length;
        list.push(value);
        return index;
    };
    ColFilterExpressionParser.COL_START_CHAR = '[';
    ColFilterExpressionParser.COL_END_CHAR = ']';
    return ColFilterExpressionParser;
}());

var __read$D = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
// null = partial match, undefined = no match
function findMatch(searchValue, values, getDisplayValue) {
    var partialMatch = false;
    var searchValueLowerCase = searchValue.toLocaleLowerCase();
    var partialSearchValue = searchValueLowerCase + ' ';
    var parsedValue = Object.entries(values).find(function (_a) {
        var _b = __read$D(_a, 2); _b[0]; var value = _b[1];
        var displayValueLowerCase = getDisplayValue(value).toLocaleLowerCase();
        if (displayValueLowerCase.startsWith(partialSearchValue)) {
            partialMatch = true;
        }
        return displayValueLowerCase === searchValueLowerCase;
    });
    if (parsedValue) {
        return parsedValue[0];
    }
    else if (partialMatch) {
        return null;
    }
    else {
        return undefined;
    }
}
function getEntries(operators, activeOperatorKeys) {
    var keys = activeOperatorKeys !== null && activeOperatorKeys !== void 0 ? activeOperatorKeys : Object.keys(operators);
    return keys.map(function (key) { return ({
        key: key,
        displayValue: operators[key].displayValue
    }); });
}
var TextFilterExpressionOperators = /** @class */ (function () {
    function TextFilterExpressionOperators(params) {
        this.params = params;
        this.initOperators();
    }
    TextFilterExpressionOperators.prototype.getEntries = function (activeOperators) {
        return getEntries(this.operators, activeOperators);
    };
    TextFilterExpressionOperators.prototype.findOperator = function (displayValue) {
        return findMatch(displayValue, this.operators, function (_a) {
            var displayValue = _a.displayValue;
            return displayValue;
        });
    };
    TextFilterExpressionOperators.prototype.initOperators = function () {
        var _this = this;
        var translate = this.params.translate;
        this.operators = {
            contains: {
                displayValue: translate('advancedFilterContains'),
                evaluator: function (value, node, params, operand1) { return _this.evaluateExpression(value, node, params, operand1, false, function (v, o) { return v.includes(o); }); },
                numOperands: 1
            },
            notContains: {
                displayValue: translate('advancedFilterNotContains'),
                evaluator: function (value, node, params, operand1) { return _this.evaluateExpression(value, node, params, operand1, true, function (v, o) { return !v.includes(o); }); },
                numOperands: 1
            },
            equals: {
                displayValue: translate('advancedFilterTextEquals'),
                evaluator: function (value, node, params, operand1) { return _this.evaluateExpression(value, node, params, operand1, false, function (v, o) { return v === o; }); },
                numOperands: 1
            },
            notEqual: {
                displayValue: translate('advancedFilterTextNotEqual'),
                evaluator: function (value, node, params, operand1) { return _this.evaluateExpression(value, node, params, operand1, true, function (v, o) { return v != o; }); },
                numOperands: 1
            },
            startsWith: {
                displayValue: translate('advancedFilterStartsWith'),
                evaluator: function (value, node, params, operand1) { return _this.evaluateExpression(value, node, params, operand1, false, function (v, o) { return v.startsWith(o); }); },
                numOperands: 1
            },
            endsWith: {
                displayValue: translate('advancedFilterEndsWith'),
                evaluator: function (value, node, params, operand1) { return _this.evaluateExpression(value, node, params, operand1, false, function (v, o) { return v.endsWith(o); }); },
                numOperands: 1
            },
            blank: {
                displayValue: translate('advancedFilterBlank'),
                evaluator: function (value) { return value == null || (typeof value === 'string' && value.trim().length === 0); },
                numOperands: 0
            },
            notBlank: {
                displayValue: translate('advancedFilterNotBlank'),
                evaluator: function (value) { return value != null && (typeof value !== 'string' || value.trim().length > 0); },
                numOperands: 0
            },
        };
    };
    TextFilterExpressionOperators.prototype.evaluateExpression = function (value, node, params, operand, nullsMatch, expression) {
        if (value == null) {
            return nullsMatch;
        }
        return params.caseSensitive
            ? expression(params.valueConverter(value, node), operand)
            : expression(params.valueConverter(value, node).toLocaleLowerCase(), operand.toLocaleLowerCase());
    };
    return TextFilterExpressionOperators;
}());
var ScalarFilterExpressionOperators = /** @class */ (function () {
    function ScalarFilterExpressionOperators(params) {
        this.params = params;
        this.initOperators();
    }
    ScalarFilterExpressionOperators.prototype.getEntries = function (activeOperators) {
        return getEntries(this.operators, activeOperators);
    };
    ScalarFilterExpressionOperators.prototype.findOperator = function (displayValue) {
        return findMatch(displayValue, this.operators, function (_a) {
            var displayValue = _a.displayValue;
            return displayValue;
        });
    };
    ScalarFilterExpressionOperators.prototype.initOperators = function () {
        var _this = this;
        var _a = this.params, translate = _a.translate, equals = _a.equals;
        this.operators = {
            equals: {
                displayValue: translate('advancedFilterEquals'),
                evaluator: function (value, node, params, operand1) { return _this.evaluateSingleOperandExpression(value, node, params, operand1, !!params.includeBlanksInEquals, equals); },
                numOperands: 1
            },
            notEqual: {
                displayValue: translate('advancedFilterNotEqual'),
                evaluator: function (value, node, params, operand1) { return _this.evaluateSingleOperandExpression(value, node, params, operand1, !!params.includeBlanksInEquals, function (v, o) { return !equals(v, o); }); },
                numOperands: 1
            },
            greaterThan: {
                displayValue: translate('advancedFilterGreaterThan'),
                evaluator: function (value, node, params, operand1) { return _this.evaluateSingleOperandExpression(value, node, params, operand1, !!params.includeBlanksInGreaterThan, function (v, o) { return v > o; }); },
                numOperands: 1
            },
            greaterThanOrEqual: {
                displayValue: translate('advancedFilterGreaterThanOrEqual'),
                evaluator: function (value, node, params, operand1) { return _this.evaluateSingleOperandExpression(value, node, params, operand1, !!params.includeBlanksInGreaterThan, function (v, o) { return v >= o; }); },
                numOperands: 1
            },
            lessThan: {
                displayValue: translate('advancedFilterLessThan'),
                evaluator: function (value, node, params, operand1) { return _this.evaluateSingleOperandExpression(value, node, params, operand1, !!params.includeBlanksInLessThan, function (v, o) { return v < o; }); },
                numOperands: 1
            },
            lessThanOrEqual: {
                displayValue: translate('advancedFilterLessThanOrEqual'),
                evaluator: function (value, node, params, operand1) { return _this.evaluateSingleOperandExpression(value, node, params, operand1, !!params.includeBlanksInLessThan, function (v, o) { return v <= o; }); },
                numOperands: 1
            },
            blank: {
                displayValue: translate('advancedFilterBlank'),
                evaluator: function (value) { return value == null; },
                numOperands: 0
            },
            notBlank: {
                displayValue: translate('advancedFilterNotBlank'),
                evaluator: function (value) { return value != null; },
                numOperands: 0
            }
        };
    };
    ScalarFilterExpressionOperators.prototype.evaluateSingleOperandExpression = function (value, node, params, operand, nullsMatch, expression) {
        if (value == null) {
            return nullsMatch;
        }
        return expression(params.valueConverter(value, node), operand);
    };
    return ScalarFilterExpressionOperators;
}());
var BooleanFilterExpressionOperators = /** @class */ (function () {
    function BooleanFilterExpressionOperators(params) {
        this.params = params;
        this.initOperators();
    }
    BooleanFilterExpressionOperators.prototype.getEntries = function (activeOperators) {
        return getEntries(this.operators, activeOperators);
    };
    BooleanFilterExpressionOperators.prototype.findOperator = function (displayValue) {
        return findMatch(displayValue, this.operators, function (_a) {
            var displayValue = _a.displayValue;
            return displayValue;
        });
    };
    BooleanFilterExpressionOperators.prototype.initOperators = function () {
        var translate = this.params.translate;
        this.operators = {
            true: {
                displayValue: translate('advancedFilterTrue'),
                evaluator: function (value) { return !!value; },
                numOperands: 0
            },
            false: {
                displayValue: translate('advancedFilterFalse'),
                evaluator: function (value) { return value === false; },
                numOperands: 0
            },
            blank: {
                displayValue: translate('advancedFilterBlank'),
                evaluator: function (value) { return value == null; },
                numOperands: 0
            },
            notBlank: {
                displayValue: translate('advancedFilterNotBlank'),
                evaluator: function (value) { return value != null; },
                numOperands: 0
            }
        };
    };
    return BooleanFilterExpressionOperators;
}());

var __extends$2k = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1P = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$C = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var AdvancedFilterExpressionService = /** @class */ (function (_super) {
    __extends$2k(AdvancedFilterExpressionService, _super);
    function AdvancedFilterExpressionService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.columnNameToIdMap = {};
        _this.columnAutocompleteEntries = null;
        _this.expressionEvaluatorParams = {};
        return _this;
    }
    AdvancedFilterExpressionService.prototype.postConstruct = function () {
        this.expressionJoinOperators = this.generateExpressionJoinOperators();
        this.expressionOperators = this.generateExpressionOperators();
    };
    AdvancedFilterExpressionService.prototype.parseJoinOperator = function (model) {
        var _a;
        var type = model.type;
        return (_a = this.expressionJoinOperators[type]) !== null && _a !== void 0 ? _a : type;
    };
    AdvancedFilterExpressionService.prototype.getColumnDisplayValue = function (model) {
        var colId = model.colId;
        var columnEntries = this.getColumnAutocompleteEntries();
        var columnEntry = columnEntries.find(function (_a) {
            var key = _a.key;
            return key === colId;
        });
        var columnName;
        if (columnEntry) {
            columnName = columnEntry.displayValue;
            this.columnNameToIdMap[columnName.toLocaleUpperCase()] = { colId: colId, columnName: columnName };
        }
        else {
            columnName = colId;
        }
        return columnName;
    };
    AdvancedFilterExpressionService.prototype.getOperatorDisplayValue = function (model) {
        var _a, _b;
        return (_b = (_a = this.getExpressionOperator(model.filterType, model.type)) === null || _a === void 0 ? void 0 : _a.displayValue) !== null && _b !== void 0 ? _b : model.type;
    };
    AdvancedFilterExpressionService.prototype.getOperandModelValue = function (operand, baseCellDataType, column) {
        var _a;
        switch (baseCellDataType) {
            case 'number':
                return _.exists(operand) ? Number(operand) : null;
            case 'date':
                return _.serialiseDate(this.valueParserService.parseValue(column, null, operand, undefined), false);
            case 'dateString':
                // displayed string format may be different from data string format, so parse before converting to date
                var parsedDateString = this.valueParserService.parseValue(column, null, operand, undefined);
                return _.serialiseDate((_a = this.dataTypeService.getDateParserFunction()(parsedDateString)) !== null && _a !== void 0 ? _a : null, false);
        }
        return operand;
    };
    AdvancedFilterExpressionService.prototype.getOperandDisplayValue = function (model, skipFormatting) {
        var _a, _b;
        var _c = model, colId = _c.colId, filter = _c.filter;
        var column = this.columnModel.getPrimaryColumn(colId);
        var operand = '';
        if (filter != null) {
            var operand1 = void 0;
            switch (model.filterType) {
                case 'number':
                    operand1 = (_a = _.toStringOrNull(filter)) !== null && _a !== void 0 ? _a : '';
                    break;
                case 'date':
                    var dateValue = _.parseDateTimeFromString(filter);
                    operand1 = column ? this.valueFormatterService.formatValue(column, null, dateValue) : null;
                    break;
                case 'dateString':
                    // need to convert from ISO date string to Date to data string format to formatted string format
                    var dateStringDateValue = _.parseDateTimeFromString(filter);
                    var dateStringStringValue = column
                        ? this.dataTypeService.getDateFormatterFunction()(dateStringDateValue !== null && dateStringDateValue !== void 0 ? dateStringDateValue : undefined)
                        : null;
                    operand1 = column ? this.valueFormatterService.formatValue(column, null, dateStringStringValue) : null;
                    break;
            }
            if (model.filterType !== 'number') {
                operand1 = (_b = operand1 !== null && operand1 !== void 0 ? operand1 : _.toStringOrNull(filter)) !== null && _b !== void 0 ? _b : '';
                if (!skipFormatting) {
                    operand1 = "\"".concat(operand1, "\"");
                }
            }
            operand = skipFormatting ? operand1 : " ".concat(operand1);
        }
        return operand;
    };
    AdvancedFilterExpressionService.prototype.parseColumnFilterModel = function (model) {
        var _a, _b;
        var columnName = (_a = this.getColumnDisplayValue(model)) !== null && _a !== void 0 ? _a : '';
        var operator = (_b = this.getOperatorDisplayValue(model)) !== null && _b !== void 0 ? _b : '';
        var operands = this.getOperandDisplayValue(model);
        return "[".concat(columnName, "] ").concat(operator).concat(operands);
    };
    AdvancedFilterExpressionService.prototype.updateAutocompleteCache = function (updateEntry, type) {
        if (type === 'column') {
            var colId = updateEntry.key, displayValue = updateEntry.displayValue;
            this.columnNameToIdMap[updateEntry.displayValue.toLocaleUpperCase()] = { colId: colId, columnName: displayValue };
        }
    };
    AdvancedFilterExpressionService.prototype.translate = function (key, variableValues) {
        var defaultValue = ADVANCED_FILTER_LOCALE_TEXT[key];
        if (typeof defaultValue === 'function') {
            defaultValue = defaultValue(variableValues);
        }
        return this.localeService.getLocaleTextFunc()(key, defaultValue, variableValues);
    };
    AdvancedFilterExpressionService.prototype.generateAutocompleteListParams = function (entries, type, searchString) {
        return {
            enabled: true,
            type: type,
            searchString: searchString,
            entries: entries
        };
    };
    AdvancedFilterExpressionService.prototype.getColumnAutocompleteEntries = function () {
        var _this = this;
        var _a;
        if (this.columnAutocompleteEntries) {
            return this.columnAutocompleteEntries;
        }
        var columns = (_a = this.columnModel.getAllPrimaryColumns()) !== null && _a !== void 0 ? _a : [];
        var entries = [];
        var includeHiddenColumns = this.gridOptionsService.get('includeHiddenColumnsInAdvancedFilter');
        columns.forEach(function (column) {
            if (column.getColDef().filter && (includeHiddenColumns || column.isVisible() || column.isRowGroupActive())) {
                entries.push({
                    key: column.getColId(),
                    displayValue: _this.columnModel.getDisplayNameForColumn(column, 'advancedFilter')
                });
            }
        });
        entries.sort(function (a, b) {
            var _a, _b;
            var aValue = (_a = a.displayValue) !== null && _a !== void 0 ? _a : '';
            var bValue = (_b = b.displayValue) !== null && _b !== void 0 ? _b : '';
            if (aValue < bValue) {
                return -1;
            }
            else if (bValue > aValue) {
                return 1;
            }
            return 0;
        });
        return entries;
    };
    AdvancedFilterExpressionService.prototype.getOperatorAutocompleteEntries = function (column, baseCellDataType) {
        var activeOperators = this.getActiveOperators(column);
        return this.getDataTypeExpressionOperator(baseCellDataType).getEntries(activeOperators);
    };
    AdvancedFilterExpressionService.prototype.getJoinOperatorAutocompleteEntries = function () {
        return Object.entries(this.expressionJoinOperators).map(function (_a) {
            var _b = __read$C(_a, 2), key = _b[0], displayValue = _b[1];
            return ({ key: key, displayValue: displayValue });
        });
    };
    AdvancedFilterExpressionService.prototype.getDefaultAutocompleteListParams = function (searchString) {
        return this.generateAutocompleteListParams(this.getColumnAutocompleteEntries(), 'column', searchString);
    };
    AdvancedFilterExpressionService.prototype.getDataTypeExpressionOperator = function (baseCellDataType) {
        return this.expressionOperators[baseCellDataType];
    };
    AdvancedFilterExpressionService.prototype.getExpressionOperator = function (baseCellDataType, operator) {
        var _a, _b;
        return (_b = (_a = this.getDataTypeExpressionOperator(baseCellDataType)) === null || _a === void 0 ? void 0 : _a.operators) === null || _b === void 0 ? void 0 : _b[operator];
    };
    AdvancedFilterExpressionService.prototype.getExpressionJoinOperators = function () {
        return this.expressionJoinOperators;
    };
    AdvancedFilterExpressionService.prototype.getColId = function (columnName) {
        var upperCaseColumnName = columnName.toLocaleUpperCase();
        var cachedColId = this.columnNameToIdMap[upperCaseColumnName];
        if (cachedColId) {
            return cachedColId;
        }
        var columnAutocompleteEntries = this.getColumnAutocompleteEntries();
        var colEntry = columnAutocompleteEntries.find(function (_a) {
            var displayValue = _a.displayValue;
            return displayValue.toLocaleUpperCase() === upperCaseColumnName;
        });
        if (colEntry) {
            var colId = colEntry.key, displayValue = colEntry.displayValue;
            var colValue = { colId: colId, columnName: displayValue };
            // cache for faster lookup
            this.columnNameToIdMap[upperCaseColumnName] = colValue;
            return colValue;
        }
        return null;
    };
    AdvancedFilterExpressionService.prototype.getExpressionEvaluatorParams = function (colId) {
        var _this = this;
        var params = this.expressionEvaluatorParams[colId];
        if (params) {
            return params;
        }
        var column = this.columnModel.getPrimaryColumn(colId);
        if (!column) {
            return { valueConverter: function (v) { return v; } };
        }
        var baseCellDataType = this.dataTypeService.getBaseDataType(column);
        switch (baseCellDataType) {
            case 'dateString':
                params = {
                    valueConverter: this.dataTypeService.getDateParserFunction()
                };
                break;
            case 'object':
                // If there's a filter value getter, assume the value is already a string. Otherwise we need to format it.
                if (column.getColDef().filterValueGetter) {
                    params = { valueConverter: function (v) { return v; } };
                }
                else {
                    params = {
                        valueConverter: function (value, node) {
                            var _a;
                            return (_a = _this.valueFormatterService.formatValue(column, node, value)) !== null && _a !== void 0 ? _a : (typeof value.toString === 'function' ? value.toString() : '');
                        }
                    };
                }
                break;
            case 'text':
            case undefined:
                params = { valueConverter: function (v) { return _.toStringOrNull(v); } };
                break;
            default:
                params = { valueConverter: function (v) { return v; } };
                break;
        }
        var filterParams = column.getColDef().filterParams;
        if (filterParams) {
            [
                'caseSensitive', 'includeBlanksInEquals', 'includeBlanksInLessThan', 'includeBlanksInGreaterThan'
            ].forEach(function (param) {
                var paramValue = filterParams[param];
                if (paramValue) {
                    params[param] = paramValue;
                }
            });
        }
        this.expressionEvaluatorParams[colId] = params;
        return params;
    };
    AdvancedFilterExpressionService.prototype.getColumnDetails = function (colId) {
        var _a, _b;
        var column = (_a = this.columnModel.getPrimaryColumn(colId)) !== null && _a !== void 0 ? _a : undefined;
        var baseCellDataType = (_b = (column ? this.dataTypeService.getBaseDataType(column) : undefined)) !== null && _b !== void 0 ? _b : 'text';
        return { column: column, baseCellDataType: baseCellDataType };
    };
    AdvancedFilterExpressionService.prototype.generateExpressionOperators = function () {
        var _this = this;
        var translate = function (key, variableValues) { return _this.translate(key, variableValues); };
        return {
            text: new TextFilterExpressionOperators({ translate: translate }),
            boolean: new BooleanFilterExpressionOperators({ translate: translate }),
            object: new TextFilterExpressionOperators({ translate: translate }),
            number: new ScalarFilterExpressionOperators({ translate: translate, equals: function (v, o) { return v === o; } }),
            date: new ScalarFilterExpressionOperators({ translate: translate, equals: function (v, o) { return v.getTime() === o.getTime(); } }),
            dateString: new ScalarFilterExpressionOperators({ translate: translate, equals: function (v, o) { return v.getTime() === o.getTime(); } })
        };
    };
    AdvancedFilterExpressionService.prototype.getColumnValue = function (_a) {
        var displayValue = _a.displayValue;
        return "".concat(ColFilterExpressionParser.COL_START_CHAR).concat(displayValue).concat(ColFilterExpressionParser.COL_END_CHAR);
    };
    AdvancedFilterExpressionService.prototype.generateExpressionJoinOperators = function () {
        return {
            AND: this.translate('advancedFilterAnd'),
            OR: this.translate('advancedFilterOr')
        };
    };
    AdvancedFilterExpressionService.prototype.getActiveOperators = function (column) {
        var _a;
        var filterOptions = (_a = column.getColDef().filterParams) === null || _a === void 0 ? void 0 : _a.filterOptions;
        if (!filterOptions) {
            return undefined;
        }
        var isValid = filterOptions.every(function (filterOption) { return typeof filterOption === 'string'; });
        return isValid ? filterOptions : undefined;
    };
    AdvancedFilterExpressionService.prototype.resetColumnCaches = function () {
        this.columnAutocompleteEntries = null;
        this.columnNameToIdMap = {};
        this.expressionEvaluatorParams = {};
    };
    __decorate$1P([
        Autowired('valueFormatterService')
    ], AdvancedFilterExpressionService.prototype, "valueFormatterService", void 0);
    __decorate$1P([
        Autowired('valueParserService')
    ], AdvancedFilterExpressionService.prototype, "valueParserService", void 0);
    __decorate$1P([
        Autowired('columnModel')
    ], AdvancedFilterExpressionService.prototype, "columnModel", void 0);
    __decorate$1P([
        Autowired('dataTypeService')
    ], AdvancedFilterExpressionService.prototype, "dataTypeService", void 0);
    __decorate$1P([
        PostConstruct
    ], AdvancedFilterExpressionService.prototype, "postConstruct", null);
    AdvancedFilterExpressionService = __decorate$1P([
        Bean('advancedFilterExpressionService')
    ], AdvancedFilterExpressionService);
    return AdvancedFilterExpressionService;
}(BeanStub));

var OperatorParser = /** @class */ (function () {
    function OperatorParser(params) {
        this.params = params;
        this.operators = [];
        this.operatorStartPositions = [];
        this.operatorEndPositions = [];
        this.activeOperator = 0;
        this.validationError = null;
    }
    OperatorParser.prototype.parseExpression = function (i) {
        this.operators.push('');
        this.operatorStartPositions.push(i);
        this.operatorEndPositions.push(undefined);
        var expression = this.params.expression;
        while (i < expression.length) {
            var char = expression[i];
            if (char === ' ') {
                var isComplete = this.parseOperator(i - 1);
                if (isComplete) {
                    this.activeOperator++;
                    return i - 1;
                }
                else {
                    this.operators[this.activeOperator] += char;
                }
            }
            else {
                this.operators[this.activeOperator] += char;
            }
            i++;
        }
        this.parseOperator(i - 1);
        return i;
    };
    OperatorParser.prototype.isValid = function () {
        return !this.validationError && (!this.operators.length || !!this.parsedOperator);
    };
    OperatorParser.prototype.getValidationError = function () {
        return this.validationError;
    };
    OperatorParser.prototype.getFunction = function () {
        return this.parsedOperator === 'OR' ? '||' : '&&';
    };
    OperatorParser.prototype.getModel = function () {
        return this.parsedOperator === 'OR' ? 'OR' : 'AND';
    };
    OperatorParser.prototype.getAutocompleteListParams = function (position, operatorIndex) {
        var _this = this;
        var searchString;
        if (operatorIndex == null) {
            searchString = '';
        }
        else {
            var operator = this.operators[operatorIndex];
            var operatorEndPosition = this.operatorEndPositions[operatorIndex];
            searchString = getSearchString(operator, position, operatorEndPosition == null ? this.params.expression.length : (operatorEndPosition + 1));
        }
        var entries = this.params.advancedFilterExpressionService.getJoinOperatorAutocompleteEntries();
        if (operatorIndex || (operatorIndex == null && this.activeOperator)) {
            // if operator already chosen, don't allow other operators
            entries = entries.filter(function (_a) {
                var key = _a.key;
                return key === _this.parsedOperator;
            });
        }
        return this.params.advancedFilterExpressionService.generateAutocompleteListParams(entries, 'join', searchString);
    };
    OperatorParser.prototype.updateExpression = function (position, updateEntry, operatorIndex) {
        var _a, _b;
        var expression = this.params.expression;
        var updatedValuePart = (_a = updateEntry.displayValue) !== null && _a !== void 0 ? _a : updateEntry.key;
        if (operatorIndex === 0) {
            // need to update all others
            for (var i = this.operatorEndPositions.length - 1; i > 0; i--) {
                var operatorEndPosition = this.operatorEndPositions[i];
                if (operatorEndPosition == null) {
                    continue;
                }
                expression = updateExpression(expression, this.operatorStartPositions[i], operatorEndPosition, updatedValuePart).updatedValue;
            }
        }
        // if we don't have a start position, haven't typed anything yet, so use current position
        var startPosition = this.operatorStartPositions.length > operatorIndex ? this.operatorStartPositions[operatorIndex] : position;
        var endPosition = (_b = (this.operatorEndPositions.length > operatorIndex ? this.operatorEndPositions[operatorIndex] : undefined)) !== null && _b !== void 0 ? _b : findEndPosition(expression, position, true).endPosition;
        return updateExpression(expression, startPosition, endPosition, updatedValuePart, true);
    };
    OperatorParser.prototype.getNumOperators = function () {
        return this.operators.length;
    };
    OperatorParser.prototype.getLastOperatorEndPosition = function () {
        return this.operatorEndPositions[this.operatorEndPositions.length - 1];
    };
    OperatorParser.prototype.parseOperator = function (endPosition) {
        var operator = this.operators.length > this.activeOperator ? this.operators[this.activeOperator] : '';
        var joinOperators = this.params.advancedFilterExpressionService.getExpressionJoinOperators();
        var parsedValue = findMatch(operator, joinOperators, function (v) { return v; });
        if (parsedValue) {
            // exact match
            this.operatorEndPositions[this.activeOperator] = endPosition;
            var displayValue = joinOperators[parsedValue];
            if (this.activeOperator) {
                if (parsedValue !== this.parsedOperator) {
                    if (!this.validationError) {
                        this.validationError = {
                            message: this.params.advancedFilterExpressionService.translate('advancedFilterValidationJoinOperatorMismatch'),
                            startPosition: endPosition - operator.length + 1,
                            endPosition: endPosition
                        };
                    }
                    return false;
                }
            }
            else {
                this.parsedOperator = parsedValue;
            }
            if (operator !== displayValue) {
                checkAndUpdateExpression(this.params, operator, displayValue, endPosition);
                this.operators[this.activeOperator] = displayValue;
            }
            return true;
        }
        else if (parsedValue === null) {
            // partial match
            return false;
        }
        else {
            // no match
            if (!this.validationError) {
                this.validationError = {
                    message: this.params.advancedFilterExpressionService.translate('advancedFilterValidationInvalidJoinOperator'),
                    startPosition: endPosition - operator.length + 1,
                    endPosition: endPosition
                };
            }
            return true;
        }
    };
    return OperatorParser;
}());
var JoinFilterExpressionParser = /** @class */ (function () {
    function JoinFilterExpressionParser(params, startPosition) {
        this.params = params;
        this.startPosition = startPosition;
        this.expectingExpression = true;
        this.expectingOperator = false;
        this.expressionParsers = [];
        this.operatorParser = new OperatorParser(this.params);
        this.missingEndBracket = false;
        this.extraEndBracket = false;
    }
    JoinFilterExpressionParser.prototype.parseExpression = function () {
        var i = this.startPosition;
        var expression = this.params.expression;
        while (i < expression.length) {
            var char = expression[i];
            if (char === '(' && !this.expectingOperator) {
                var nestedParser = new JoinFilterExpressionParser(this.params, i + 1);
                i = nestedParser.parseExpression();
                this.expressionParsers.push(nestedParser);
                this.expectingExpression = false;
                this.expectingOperator = true;
            }
            else if (char === ')') {
                this.endPosition = i - 1;
                if (this.startPosition === 0) {
                    this.extraEndBracket = true;
                }
                return i;
            }
            else if (char === ' ') ;
            else if (this.expectingExpression) {
                var nestedParser = new ColFilterExpressionParser(this.params, i);
                i = nestedParser.parseExpression();
                this.expressionParsers.push(nestedParser);
                this.expectingExpression = false;
                this.expectingOperator = true;
            }
            else if (this.expectingOperator) {
                i = this.operatorParser.parseExpression(i);
                this.expectingOperator = false;
                this.expectingExpression = true;
            }
            i++;
        }
        if (this.startPosition > 0) {
            this.missingEndBracket = true;
        }
        return i;
    };
    JoinFilterExpressionParser.prototype.isValid = function () {
        return !this.missingEndBracket &&
            !this.extraEndBracket &&
            this.expressionParsers.length === this.operatorParser.getNumOperators() + 1 &&
            this.operatorParser.isValid() &&
            this.expressionParsers.every(function (expressionParser) { return expressionParser.isValid(); });
    };
    JoinFilterExpressionParser.prototype.getValidationError = function () {
        var operatorError = this.operatorParser.getValidationError();
        for (var i = 0; i < this.expressionParsers.length; i++) {
            var expressionError = this.expressionParsers[i].getValidationError();
            if (expressionError) {
                return operatorError && operatorError.startPosition < expressionError.startPosition
                    ? operatorError
                    : expressionError;
            }
        }
        if (operatorError) {
            return operatorError;
        }
        if (this.extraEndBracket) {
            return {
                message: this.params.advancedFilterExpressionService.translate('advancedFilterValidationExtraEndBracket'),
                startPosition: this.endPosition + 1,
                endPosition: this.endPosition + 1
            };
        }
        var translateKey;
        if (this.expressionParsers.length === this.operatorParser.getNumOperators()) {
            translateKey = 'advancedFilterValidationMissingCondition';
        }
        else if (this.missingEndBracket) {
            translateKey = 'advancedFilterValidationMissingEndBracket';
        }
        if (translateKey) {
            return {
                message: this.params.advancedFilterExpressionService.translate(translateKey),
                startPosition: this.params.expression.length,
                endPosition: this.params.expression.length
            };
        }
        return null;
    };
    JoinFilterExpressionParser.prototype.getFunction = function (params) {
        var hasMultipleExpressions = this.expressionParsers.length > 1;
        var expression = this.expressionParsers.map(function (expressionParser) { return expressionParser.getFunction(params); }).join(" ".concat(this.operatorParser.getFunction(), " "));
        return hasMultipleExpressions ? "(".concat(expression, ")") : expression;
    };
    JoinFilterExpressionParser.prototype.getAutocompleteListParams = function (position) {
        if (this.endPosition != null && position > this.endPosition + 1) {
            return undefined;
        }
        if (!this.expressionParsers.length) {
            return this.getColumnAutocompleteListParams();
        }
        var expressionParserIndex = this.getExpressionParserIndex(position);
        if (expressionParserIndex == null) {
            if (this.params.expression[position] === '(') {
                return { enabled: false };
            }
            // positioned before the expression, so new expression
            return this.getColumnAutocompleteListParams();
        }
        var expressionParser = this.expressionParsers[expressionParserIndex];
        var autocompleteType = expressionParser.getAutocompleteListParams(position);
        if (!autocompleteType) {
            // beyond the end of the expression
            if (expressionParserIndex < this.expressionParsers.length - 1) {
                // in the middle of two expressions
                return this.operatorParser.getAutocompleteListParams(position, expressionParserIndex);
            }
            if (this.expressionParsers.length === this.operatorParser.getNumOperators()) {
                var operatorEndPosition = this.operatorParser.getLastOperatorEndPosition();
                return operatorEndPosition == null || position <= operatorEndPosition + 1
                    ? this.operatorParser.getAutocompleteListParams(position, this.operatorParser.getNumOperators() - 1)
                    : this.getColumnAutocompleteListParams();
            }
            if (this.params.expression[position - 1] === ')') {
                return { enabled: false };
            }
            return this.operatorParser.getAutocompleteListParams(position);
        }
        return autocompleteType;
    };
    JoinFilterExpressionParser.prototype.updateExpression = function (position, updateEntry, type) {
        var _a;
        var expression = this.params.expression;
        var expressionParserIndex = this.getExpressionParserIndex(position);
        if (expressionParserIndex == null) {
            // positioned before the expression
            var updatedValuePart = type === 'column'
                ? this.params.advancedFilterExpressionService.getColumnValue(updateEntry)
                : (_a = updateEntry.displayValue) !== null && _a !== void 0 ? _a : updateEntry.key;
            return updateExpression(expression, this.startPosition, this.startPosition, updatedValuePart, true);
        }
        var expressionParser = this.expressionParsers[expressionParserIndex];
        var updatedExpression = expressionParser.updateExpression(position, updateEntry, type);
        if (updatedExpression == null) {
            if (type === 'column') {
                // beyond the end of the expression, just do simple update
                return updateExpression(expression, position, expression.length - 1, this.params.advancedFilterExpressionService.getColumnValue(updateEntry), true);
            }
            else if (this.endPosition != null && position > this.endPosition + 1) {
                return null;
            }
            else {
                return this.operatorParser.updateExpression(position, updateEntry, expressionParserIndex);
            }
        }
        return updatedExpression;
    };
    JoinFilterExpressionParser.prototype.getModel = function () {
        if (this.expressionParsers.length > 1) {
            return {
                filterType: 'join',
                type: this.operatorParser.getModel(),
                conditions: this.expressionParsers.map(function (parser) { return parser.getModel(); })
            };
        }
        else {
            return this.expressionParsers[0].getModel();
        }
    };
    JoinFilterExpressionParser.prototype.getColumnAutocompleteListParams = function () {
        return this.params.advancedFilterExpressionService.generateAutocompleteListParams(this.params.advancedFilterExpressionService.getColumnAutocompleteEntries(), 'column', '');
    };
    JoinFilterExpressionParser.prototype.getExpressionParserIndex = function (position) {
        var expressionParserIndex;
        for (var i = 0; i < this.expressionParsers.length; i++) {
            var expressionParserToCheck = this.expressionParsers[i];
            if (expressionParserToCheck.startPosition > position) {
                break;
            }
            expressionParserIndex = i;
        }
        return expressionParserIndex;
    };
    return JoinFilterExpressionParser;
}());

var FilterExpressionParser = /** @class */ (function () {
    function FilterExpressionParser(params) {
        this.params = params;
        this.valid = false;
    }
    FilterExpressionParser.prototype.parseExpression = function () {
        this.joinExpressionParser = new JoinFilterExpressionParser(this.params, 0);
        var i = this.joinExpressionParser.parseExpression();
        this.valid = i >= this.params.expression.length - 1 && this.joinExpressionParser.isValid();
        return this.params.expression;
    };
    FilterExpressionParser.prototype.isValid = function () {
        return this.valid;
    };
    FilterExpressionParser.prototype.getValidationMessage = function () {
        var error = this.joinExpressionParser.getValidationError();
        if (!error) {
            return null;
        }
        var message = error.message, startPosition = error.startPosition, endPosition = error.endPosition;
        return startPosition < this.params.expression.length
            ? this.params.advancedFilterExpressionService.translate('advancedFilterValidationMessage', [
                message, this.params.expression.slice(startPosition, endPosition + 1).trim()
            ])
            : this.params.advancedFilterExpressionService.translate('advancedFilterValidationMessageAtEnd', [message]);
    };
    FilterExpressionParser.prototype.getFunction = function () {
        var params = {
            operands: [],
            operators: [],
            evaluatorParams: []
        };
        var functionBody = "return ".concat(this.joinExpressionParser.getFunction(params), ";");
        return {
            functionBody: functionBody,
            params: params
        };
    };
    FilterExpressionParser.prototype.getAutocompleteListParams = function (position) {
        var _a;
        return (_a = this.joinExpressionParser.getAutocompleteListParams(position)) !== null && _a !== void 0 ? _a : { enabled: false };
    };
    FilterExpressionParser.prototype.updateExpression = function (position, updateEntry, type) {
        return this.joinExpressionParser.updateExpression(position, updateEntry, type);
    };
    FilterExpressionParser.prototype.getModel = function () {
        return this.isValid() ? this.joinExpressionParser.getModel() : null;
    };
    return FilterExpressionParser;
}());

var __extends$2j = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1O = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AdvancedFilterService = /** @class */ (function (_super) {
    __extends$2j(AdvancedFilterService, _super);
    function AdvancedFilterService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.appliedExpression = null;
        /** The value displayed in the input, which may be invalid */
        _this.expression = null;
        _this.isValid = true;
        return _this;
    }
    AdvancedFilterService.prototype.postConstruct = function () {
        var _this = this;
        this.setEnabled(this.gridOptionsService.get('enableAdvancedFilter'), true);
        this.ctrl = this.createManagedBean(new AdvancedFilterCtrl(this.enabled));
        this.expressionProxy = {
            getValue: function (colId, node) {
                var column = _this.columnModel.getPrimaryColumn(colId);
                return column ? _this.valueService.getValue(column, node, true) : undefined;
            },
        };
        this.addManagedPropertyListener('enableAdvancedFilter', function (event) { return _this.setEnabled(!!event.currentValue); });
        this.addManagedListener(this.eventService, Events.EVENT_NEW_COLUMNS_LOADED, function (event) { return _this.onNewColumnsLoaded(event); });
        this.addManagedPropertyListener('includeHiddenColumnsInAdvancedFilter', function () { return _this.updateValidity(); });
    };
    AdvancedFilterService.prototype.isEnabled = function () {
        return this.enabled;
    };
    AdvancedFilterService.prototype.isFilterPresent = function () {
        return !!this.expressionFunction;
    };
    AdvancedFilterService.prototype.doesFilterPass = function (node) {
        return this.expressionFunction(this.expressionProxy, node, this.expressionParams);
    };
    AdvancedFilterService.prototype.getModel = function () {
        var _a;
        var expressionParser = this.createExpressionParser(this.appliedExpression);
        expressionParser === null || expressionParser === void 0 ? void 0 : expressionParser.parseExpression();
        return (_a = expressionParser === null || expressionParser === void 0 ? void 0 : expressionParser.getModel()) !== null && _a !== void 0 ? _a : null;
    };
    AdvancedFilterService.prototype.setModel = function (model) {
        var _this = this;
        var parseModel = function (model, isFirstParent) {
            if (model.filterType === 'join') {
                var operator = _this.advancedFilterExpressionService.parseJoinOperator(model);
                var expression_1 = model.conditions.map(function (condition) { return parseModel(condition); })
                    .filter(function (condition) { return _.exists(condition); })
                    .join(" ".concat(operator, " "));
                return isFirstParent || model.conditions.length <= 1 ? expression_1 : "(".concat(expression_1, ")");
            }
            else {
                return _this.advancedFilterExpressionService.parseColumnFilterModel(model);
            }
        };
        var expression = model ? parseModel(model, true) : null;
        this.setExpressionDisplayValue(expression);
        this.applyExpression();
        this.ctrl.refreshComp();
        this.ctrl.refreshBuilderComp();
    };
    AdvancedFilterService.prototype.getExpressionDisplayValue = function () {
        return this.expression;
    };
    AdvancedFilterService.prototype.setExpressionDisplayValue = function (expression) {
        this.expression = expression;
    };
    AdvancedFilterService.prototype.isCurrentExpressionApplied = function () {
        return this.appliedExpression === this.expression;
    };
    AdvancedFilterService.prototype.createExpressionParser = function (expression) {
        if (!expression) {
            return null;
        }
        return new FilterExpressionParser({
            expression: expression,
            columnModel: this.columnModel,
            dataTypeService: this.dataTypeService,
            valueParserService: this.valueParserService,
            advancedFilterExpressionService: this.advancedFilterExpressionService,
        });
    };
    AdvancedFilterService.prototype.getDefaultExpression = function (updateEntry) {
        var updatedValue = this.advancedFilterExpressionService.getColumnValue(updateEntry) + ' ';
        return {
            updatedValue: updatedValue,
            updatedPosition: updatedValue.length
        };
    };
    AdvancedFilterService.prototype.isHeaderActive = function () {
        return !this.gridOptionsService.get('advancedFilterParent');
    };
    AdvancedFilterService.prototype.getCtrl = function () {
        return this.ctrl;
    };
    AdvancedFilterService.prototype.setEnabled = function (enabled, silent) {
        var previousValue = this.enabled;
        var rowModelType = this.rowModel.getType();
        var isValidRowModel = rowModelType === 'clientSide' || rowModelType === 'serverSide';
        if (enabled && !rowModelType) {
            _.warnOnce('Advanced Filter is only supported with the Client-Side Row Model or Server-Side Row Model.');
        }
        this.enabled = enabled && isValidRowModel;
        if (!silent && this.enabled !== previousValue) {
            var event_1 = {
                type: Events.EVENT_ADVANCED_FILTER_ENABLED_CHANGED,
                enabled: this.enabled
            };
            this.eventService.dispatchEvent(event_1);
        }
    };
    AdvancedFilterService.prototype.applyExpression = function () {
        var expressionParser = this.createExpressionParser(this.expression);
        expressionParser === null || expressionParser === void 0 ? void 0 : expressionParser.parseExpression();
        this.applyExpressionFromParser(expressionParser);
    };
    AdvancedFilterService.prototype.applyExpressionFromParser = function (expressionParser) {
        this.isValid = !expressionParser || expressionParser.isValid();
        if (!expressionParser || !this.isValid) {
            this.expressionFunction = null;
            this.expressionParams = null;
            this.appliedExpression = null;
            return;
        }
        var _a = expressionParser.getFunction(), functionBody = _a.functionBody, params = _a.params;
        this.expressionFunction = new Function('expressionProxy', 'node', 'params', functionBody);
        this.expressionParams = params;
        this.appliedExpression = this.expression;
    };
    AdvancedFilterService.prototype.updateValidity = function () {
        this.advancedFilterExpressionService.resetColumnCaches();
        var expressionParser = this.createExpressionParser(this.expression);
        expressionParser === null || expressionParser === void 0 ? void 0 : expressionParser.parseExpression();
        var isValid = !expressionParser || expressionParser.isValid();
        var updatedValidity = isValid !== this.isValid;
        this.applyExpressionFromParser(expressionParser);
        this.ctrl.refreshComp();
        this.ctrl.refreshBuilderComp();
        return updatedValidity;
    };
    AdvancedFilterService.prototype.onNewColumnsLoaded = function (event) {
        var _this = this;
        if (event.source !== 'gridInitializing' || !this.dataTypeService.isPendingInference()) {
            return;
        }
        this.ctrl.setInputDisabled(true);
        var destroyFunc = this.addManagedListener(this.eventService, Events.EVENT_DATA_TYPES_INFERRED, function () {
            destroyFunc === null || destroyFunc === void 0 ? void 0 : destroyFunc();
            _this.ctrl.setInputDisabled(false);
        });
    };
    __decorate$1O([
        Autowired('valueService')
    ], AdvancedFilterService.prototype, "valueService", void 0);
    __decorate$1O([
        Autowired('columnModel')
    ], AdvancedFilterService.prototype, "columnModel", void 0);
    __decorate$1O([
        Autowired('dataTypeService')
    ], AdvancedFilterService.prototype, "dataTypeService", void 0);
    __decorate$1O([
        Autowired('valueParserService')
    ], AdvancedFilterService.prototype, "valueParserService", void 0);
    __decorate$1O([
        Autowired('rowModel')
    ], AdvancedFilterService.prototype, "rowModel", void 0);
    __decorate$1O([
        Autowired('advancedFilterExpressionService')
    ], AdvancedFilterService.prototype, "advancedFilterExpressionService", void 0);
    __decorate$1O([
        PostConstruct
    ], AdvancedFilterService.prototype, "postConstruct", null);
    AdvancedFilterService = __decorate$1O([
        Bean('advancedFilterService')
    ], AdvancedFilterService);
    return AdvancedFilterService;
}(BeanStub));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$i = '31.0.2';

var AdvancedFilterModule = {
    version: VERSION$i,
    moduleName: ModuleNames.AdvancedFilterModule,
    beans: [AdvancedFilterService, AdvancedFilterExpressionService],
    agStackComponents: [
        { componentName: 'agAdvancedFilter', componentClass: AdvancedFilterComp }
    ],
    dependantModules: [
        EnterpriseCoreModule
    ]
};

var __extends$2i = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1N = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$B = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$n = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values$b = (undefined && undefined.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
// Matches value in changeDetectionService
var SOURCE_PASTE = 'paste';
var EXPORT_TYPE_DRAG_COPY = 'dragCopy';
var EXPORT_TYPE_CLIPBOARD = 'clipboard';
var CellClearType;
(function (CellClearType) {
    CellClearType[CellClearType["CellRange"] = 0] = "CellRange";
    CellClearType[CellClearType["SelectedRows"] = 1] = "SelectedRows";
    CellClearType[CellClearType["FocusedCell"] = 2] = "FocusedCell";
})(CellClearType || (CellClearType = {}));
var apiError = function (method) { return "AG Grid: Unable to use the Clipboard API (navigator.clipboard.".concat(method, "()). ") +
    'The reason why it could not be used has been logged in the previous line. ' +
    'For this reason the grid has defaulted to using a workaround which doesn\'t perform as well. ' +
    'Either fix why Clipboard API is blocked, OR stop this message from appearing by setting grid ' +
    'property suppressClipboardApi=true (which will default the grid to using the workaround rather than the API.'; };
var ClipboardService = /** @class */ (function (_super) {
    __extends$2i(ClipboardService, _super);
    function ClipboardService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.lastPasteOperationTime = 0;
        _this.navigatorApiFailed = false;
        return _this;
    }
    ClipboardService_1 = ClipboardService;
    ClipboardService.prototype.init = function () {
        var _this = this;
        this.logger = this.loggerFactory.create('ClipboardService');
        if (this.rowModel.getType() === 'clientSide') {
            this.clientSideRowModel = this.rowModel;
        }
        this.ctrlsService.whenReady(function (p) {
            _this.gridCtrl = p.gridCtrl;
        });
    };
    ClipboardService.prototype.pasteFromClipboard = function () {
        var _this = this;
        this.logger.log('pasteFromClipboard');
        // Method 1 - native clipboard API, available in modern chrome browsers
        var allowNavigator = !this.gridOptionsService.get('suppressClipboardApi');
        // Some browsers (Firefox) do not allow Web Applications to read from
        // the clipboard so verify if not only the ClipboardAPI is available,
        // but also if the `readText` method is public.
        if (allowNavigator && !this.navigatorApiFailed && navigator.clipboard && navigator.clipboard.readText) {
            navigator.clipboard.readText()
                .then(this.processClipboardData.bind(this))
                .catch(function (e) {
                _.doOnce(function () {
                    console.warn(e);
                    console.warn(apiError('readText'));
                }, 'clipboardApiError');
                _this.navigatorApiFailed = true;
                _this.pasteFromClipboardLegacy();
            });
        }
        else {
            this.pasteFromClipboardLegacy();
        }
    };
    ClipboardService.prototype.pasteFromClipboardLegacy = function () {
        var _this = this;
        // Method 2 - if modern API fails, the old school hack
        var defaultPrevented = false;
        var handlePasteEvent = function (e) {
            var currentPastOperationTime = (new Date()).getTime();
            if (currentPastOperationTime - _this.lastPasteOperationTime < 50) {
                defaultPrevented = true;
                e.preventDefault();
            }
            _this.lastPasteOperationTime = currentPastOperationTime;
        };
        this.executeOnTempElement(function (textArea) {
            textArea.addEventListener('paste', handlePasteEvent);
            textArea.focus({ preventScroll: true });
        }, function (element) {
            var data = element.value;
            if (!defaultPrevented) {
                _this.processClipboardData(data);
            }
            else {
                _this.refocusLastFocusedCell();
            }
            element.removeEventListener('paste', handlePasteEvent);
        });
    };
    ClipboardService.prototype.refocusLastFocusedCell = function () {
        var focusedCell = this.focusService.getFocusedCell();
        if (focusedCell) {
            this.focusService.setFocusedCell({
                rowIndex: focusedCell.rowIndex,
                column: focusedCell.column,
                rowPinned: focusedCell.rowPinned,
                forceBrowserFocus: true
            });
        }
    };
    ClipboardService.prototype.getClipboardDelimiter = function () {
        var delimiter = this.gridOptionsService.get('clipboardDelimiter');
        return _.exists(delimiter) ? delimiter : '\t';
    };
    ClipboardService.prototype.processClipboardData = function (data) {
        var _this = this;
        if (data == null) {
            return;
        }
        var parsedData = ClipboardService_1.stringToArray(data, this.getClipboardDelimiter());
        var userFunc = this.gridOptionsService.getCallback('processDataFromClipboard');
        if (userFunc) {
            parsedData = userFunc({ data: parsedData });
        }
        if (parsedData == null) {
            return;
        }
        if (this.gridOptionsService.get('suppressLastEmptyLineOnPaste')) {
            this.removeLastLineIfBlank(parsedData);
        }
        var pasteOperation = function (cellsToFlash, updatedRowNodes, focusedCell, changedPath) {
            var rangeActive = _this.rangeService && _this.rangeService.isMoreThanOneCell();
            var pasteIntoRange = rangeActive && !_this.hasOnlyOneValueToPaste(parsedData);
            if (pasteIntoRange) {
                _this.pasteIntoActiveRange(parsedData, cellsToFlash, updatedRowNodes, changedPath);
            }
            else {
                _this.pasteStartingFromFocusedCell(parsedData, cellsToFlash, updatedRowNodes, focusedCell, changedPath);
            }
        };
        this.doPasteOperation(pasteOperation);
    };
    // This will parse a delimited string into an array of arrays.
    ClipboardService.stringToArray = function (strData, delimiter) {
        if (delimiter === void 0) { delimiter = ','; }
        var data = [];
        var isNewline = function (char) { return char === '\r' || char === '\n'; };
        var insideQuotedField = false;
        if (strData === '') {
            return [['']];
        }
        var _loop_1 = function (row, column, position) {
            var previousChar = strData[position - 1];
            var currentChar = strData[position];
            var nextChar = strData[position + 1];
            var ensureDataExists = function () {
                if (!data[row]) {
                    // create row if it doesn't exist
                    data[row] = [];
                }
                if (!data[row][column]) {
                    // create column if it doesn't exist
                    data[row][column] = '';
                }
            };
            ensureDataExists();
            if (currentChar === '"') {
                if (insideQuotedField) {
                    if (nextChar === '"') {
                        // unescape double quote
                        data[row][column] += '"';
                        position++;
                    }
                    else {
                        // exit quoted field
                        insideQuotedField = false;
                    }
                    // continue;
                }
                else if (previousChar === undefined || previousChar === delimiter || isNewline(previousChar)) {
                    // enter quoted field
                    insideQuotedField = true;
                    // continue;
                }
            }
            if (!insideQuotedField && currentChar !== '"') {
                if (currentChar === delimiter) {
                    // move to next column
                    column++;
                    ensureDataExists();
                    return out_row_1 = row, out_column_1 = column, out_position_1 = position, "continue";
                }
                else if (isNewline(currentChar)) {
                    // move to next row
                    column = 0;
                    row++;
                    ensureDataExists();
                    if (currentChar === '\r' && nextChar === '\n') {
                        // skip over second newline character if it exists
                        position++;
                    }
                    return out_row_1 = row, out_column_1 = column, out_position_1 = position, "continue";
                }
            }
            // add current character to current column
            data[row][column] += currentChar;
            out_row_1 = row;
            out_column_1 = column;
            out_position_1 = position;
        };
        var out_row_1, out_column_1, out_position_1;
        // iterate over each character, keep track of current row and column (of the returned array)
        for (var row = 0, column = 0, position = 0; position < strData.length; position++) {
            _loop_1(row, column, position);
            row = out_row_1;
            column = out_column_1;
            position = out_position_1;
        }
        return data;
    };
    // common code to paste operations, e.g. paste to cell, paste to range, and copy range down
    ClipboardService.prototype.doPasteOperation = function (pasteOperationFunc) {
        var source = 'clipboard';
        this.eventService.dispatchEvent({
            type: Events.EVENT_PASTE_START,
            source: source
        });
        var changedPath;
        if (this.clientSideRowModel) {
            var onlyChangedColumns = this.gridOptionsService.get('aggregateOnlyChangedColumns');
            changedPath = new ChangedPath(onlyChangedColumns, this.clientSideRowModel.getRootNode());
        }
        var cellsToFlash = {};
        var updatedRowNodes = [];
        var focusedCell = this.focusService.getFocusedCell();
        pasteOperationFunc(cellsToFlash, updatedRowNodes, focusedCell, changedPath);
        var nodesToRefresh = __spreadArray$n([], __read$B(updatedRowNodes), false);
        if (changedPath) {
            this.clientSideRowModel.doAggregate(changedPath);
            // add all nodes impacted by aggregation, as they need refreshed also.
            changedPath.forEachChangedNodeDepthFirst(function (rowNode) {
                nodesToRefresh.push(rowNode);
            });
        }
        // clipboardService has to do changeDetection itself, to prevent repeat logic in favour of batching.
        // changeDetectionService is disabled for this action.
        this.rowRenderer.refreshCells({ rowNodes: nodesToRefresh });
        this.dispatchFlashCells(cellsToFlash);
        this.fireRowChanged(updatedRowNodes);
        // if using the clipboard hack with a temp element, then the focus has been lost,
        // so need to put it back. otherwise paste operation loosed focus on cell and keyboard
        // navigation stops.
        this.refocusLastFocusedCell();
        var event = {
            type: Events.EVENT_PASTE_END,
            source: source
        };
        this.eventService.dispatchEvent(event);
    };
    ClipboardService.prototype.pasteIntoActiveRange = function (clipboardData, cellsToFlash, updatedRowNodes, changedPath) {
        var _this = this;
        // true if clipboard data can be evenly pasted into range, otherwise false
        var abortRepeatingPasteIntoRows = this.getRangeSize() % clipboardData.length != 0;
        var indexOffset = 0;
        var dataRowIndex = 0;
        var rowCallback = function (currentRow, rowNode, columns, index) {
            var atEndOfClipboardData = index - indexOffset >= clipboardData.length;
            if (atEndOfClipboardData) {
                if (abortRepeatingPasteIntoRows) {
                    return;
                }
                // increment offset and reset data index to repeat paste of data
                indexOffset += dataRowIndex;
                dataRowIndex = 0;
            }
            var currentRowData = clipboardData[index - indexOffset];
            // otherwise we are not the first row, so copy
            updatedRowNodes.push(rowNode);
            var processCellFromClipboardFunc = _this.gridOptionsService.getCallback('processCellFromClipboard');
            columns.forEach(function (column, idx) {
                if (!column.isCellEditable(rowNode) || column.isSuppressPaste(rowNode)) {
                    return;
                }
                // repeat data for columns we don't have data for - happens when to range is bigger than copied data range
                if (idx >= currentRowData.length) {
                    idx = idx % currentRowData.length;
                }
                var newValue = _this.processCell(rowNode, column, currentRowData[idx], EXPORT_TYPE_DRAG_COPY, processCellFromClipboardFunc, true);
                rowNode.setDataValue(column, newValue, SOURCE_PASTE);
                if (changedPath) {
                    changedPath.addParentNode(rowNode.parent, [column]);
                }
                var rowIndex = currentRow.rowIndex, rowPinned = currentRow.rowPinned;
                var cellId = _this.cellPositionUtils.createIdFromValues({ rowIndex: rowIndex, column: column, rowPinned: rowPinned });
                cellsToFlash[cellId] = true;
            });
            dataRowIndex++;
        };
        this.iterateActiveRanges(false, rowCallback);
    };
    ClipboardService.prototype.pasteStartingFromFocusedCell = function (parsedData, cellsToFlash, updatedRowNodes, focusedCell, changedPath) {
        if (!focusedCell) {
            return;
        }
        var currentRow = { rowIndex: focusedCell.rowIndex, rowPinned: focusedCell.rowPinned };
        var columnsToPasteInto = this.columnModel.getDisplayedColumnsStartingAt(focusedCell.column);
        if (this.isPasteSingleValueIntoRange(parsedData)) {
            this.pasteSingleValueIntoRange(parsedData, updatedRowNodes, cellsToFlash, changedPath);
        }
        else {
            this.pasteMultipleValues(parsedData, currentRow, updatedRowNodes, columnsToPasteInto, cellsToFlash, EXPORT_TYPE_CLIPBOARD, changedPath);
        }
    };
    // if range is active, and only one cell, then we paste this cell into all cells in the active range.
    ClipboardService.prototype.isPasteSingleValueIntoRange = function (parsedData) {
        return this.hasOnlyOneValueToPaste(parsedData)
            && this.rangeService != null
            && !this.rangeService.isEmpty();
    };
    ClipboardService.prototype.pasteSingleValueIntoRange = function (parsedData, updatedRowNodes, cellsToFlash, changedPath) {
        var _this = this;
        var value = parsedData[0][0];
        var rowCallback = function (currentRow, rowNode, columns) {
            updatedRowNodes.push(rowNode);
            columns.forEach(function (column) {
                return _this.updateCellValue(rowNode, column, value, cellsToFlash, EXPORT_TYPE_CLIPBOARD, changedPath);
            });
        };
        this.iterateActiveRanges(false, rowCallback);
    };
    ClipboardService.prototype.hasOnlyOneValueToPaste = function (parsedData) {
        return parsedData.length === 1 && parsedData[0].length === 1;
    };
    ClipboardService.prototype.copyRangeDown = function () {
        var _this = this;
        if (!this.rangeService || this.rangeService.isEmpty()) {
            return;
        }
        var firstRowValues = [];
        var pasteOperation = function (cellsToFlash, updatedRowNodes, focusedCell, changedPath) {
            var processCellForClipboardFunc = _this.gridOptionsService.getCallback('processCellForClipboard');
            var processCellFromClipboardFunc = _this.gridOptionsService.getCallback('processCellFromClipboard');
            var rowCallback = function (currentRow, rowNode, columns) {
                // take reference of first row, this is the one we will be using to copy from
                if (!firstRowValues.length) {
                    // two reasons for looping through columns
                    columns.forEach(function (column) {
                        // get the initial values to copy down
                        var value = _this.processCell(rowNode, column, _this.valueService.getValue(column, rowNode), EXPORT_TYPE_DRAG_COPY, processCellForClipboardFunc, false, true);
                        firstRowValues.push(value);
                    });
                }
                else {
                    // otherwise we are not the first row, so copy
                    updatedRowNodes.push(rowNode);
                    columns.forEach(function (column, index) {
                        if (!column.isCellEditable(rowNode) || column.isSuppressPaste(rowNode)) {
                            return;
                        }
                        var firstRowValue = _this.processCell(rowNode, column, firstRowValues[index], EXPORT_TYPE_DRAG_COPY, processCellFromClipboardFunc, true);
                        rowNode.setDataValue(column, firstRowValue, SOURCE_PASTE);
                        if (changedPath) {
                            changedPath.addParentNode(rowNode.parent, [column]);
                        }
                        var rowIndex = currentRow.rowIndex, rowPinned = currentRow.rowPinned;
                        var cellId = _this.cellPositionUtils.createIdFromValues({ rowIndex: rowIndex, column: column, rowPinned: rowPinned });
                        cellsToFlash[cellId] = true;
                    });
                }
            };
            _this.iterateActiveRanges(true, rowCallback);
        };
        this.doPasteOperation(pasteOperation);
    };
    ClipboardService.prototype.removeLastLineIfBlank = function (parsedData) {
        // remove last row if empty, excel puts empty last row in
        var lastLine = _.last(parsedData);
        var lastLineIsBlank = lastLine && lastLine.length === 1 && lastLine[0] === '';
        if (lastLineIsBlank) {
            // do not remove the last empty line when that is the only line pasted
            if (parsedData.length === 1) {
                return;
            }
            _.removeFromArray(parsedData, lastLine);
        }
    };
    ClipboardService.prototype.fireRowChanged = function (rowNodes) {
        var _this = this;
        if (this.gridOptionsService.get('editType') !== 'fullRow') {
            return;
        }
        rowNodes.forEach(function (rowNode) {
            var event = {
                type: Events.EVENT_ROW_VALUE_CHANGED,
                node: rowNode,
                data: rowNode.data,
                rowIndex: rowNode.rowIndex,
                rowPinned: rowNode.rowPinned
            };
            _this.eventService.dispatchEvent(event);
        });
    };
    ClipboardService.prototype.pasteMultipleValues = function (clipboardGridData, currentRow, updatedRowNodes, columnsToPasteInto, cellsToFlash, type, changedPath) {
        var _this = this;
        var rowPointer = currentRow;
        // if doing CSRM and NOT tree data, then it means groups are aggregates, which are read only,
        // so we should skip them when doing paste operations.
        var skipGroupRows = this.clientSideRowModel != null && !this.gridOptionsService.get('enableGroupEdit') && !this.gridOptionsService.get('treeData');
        var getNextGoodRowNode = function () {
            while (true) {
                if (!rowPointer) {
                    return null;
                }
                var res = _this.rowPositionUtils.getRowNode(rowPointer);
                // move to next row down for next set of values
                rowPointer = _this.cellNavigationService.getRowBelow({ rowPinned: rowPointer.rowPinned, rowIndex: rowPointer.rowIndex });
                // if no more rows, return null
                if (res == null) {
                    return null;
                }
                // skip details rows and footer rows, never paste into them as they don't hold data
                var skipRow = res.detail || res.footer || (skipGroupRows && res.group);
                // skipping row means we go into the next iteration of the while loop
                if (!skipRow) {
                    return res;
                }
            }
        };
        clipboardGridData.forEach(function (clipboardRowData) {
            var rowNode = getNextGoodRowNode();
            // if we have come to end of rows in grid, then skip
            if (!rowNode) {
                return;
            }
            clipboardRowData.forEach(function (value, index) {
                return _this.updateCellValue(rowNode, columnsToPasteInto[index], value, cellsToFlash, type, changedPath);
            });
            updatedRowNodes.push(rowNode);
        });
    };
    ClipboardService.prototype.updateCellValue = function (rowNode, column, value, cellsToFlash, type, changedPath) {
        if (!rowNode ||
            !column ||
            !column.isCellEditable(rowNode) ||
            column.isSuppressPaste(rowNode)) {
            return;
        }
        // if the cell is a group and the col is an aggregation, skip the cell.
        if (rowNode.group && column.isValueActive()) {
            return;
        }
        var processedValue = this.processCell(rowNode, column, value, type, this.gridOptionsService.getCallback('processCellFromClipboard'), true);
        rowNode.setDataValue(column, processedValue, SOURCE_PASTE);
        var rowIndex = rowNode.rowIndex, rowPinned = rowNode.rowPinned;
        var cellId = this.cellPositionUtils.createIdFromValues({ rowIndex: rowIndex, column: column, rowPinned: rowPinned });
        cellsToFlash[cellId] = true;
        if (changedPath) {
            changedPath.addParentNode(rowNode.parent, [column]);
        }
    };
    ClipboardService.prototype.copyToClipboard = function (params) {
        if (params === void 0) { params = {}; }
        this.copyOrCutToClipboard(params);
    };
    ClipboardService.prototype.cutToClipboard = function (params, source) {
        if (params === void 0) { params = {}; }
        if (source === void 0) { source = 'api'; }
        if (this.gridOptionsService.get('suppressCutToClipboard')) {
            return;
        }
        var startEvent = {
            type: Events.EVENT_CUT_START,
            source: source
        };
        this.eventService.dispatchEvent(startEvent);
        this.copyOrCutToClipboard(params, true);
        var endEvent = {
            type: Events.EVENT_CUT_END,
            source: source
        };
        this.eventService.dispatchEvent(endEvent);
    };
    ClipboardService.prototype.copyOrCutToClipboard = function (params, cut) {
        var includeHeaders = params.includeHeaders, includeGroupHeaders = params.includeGroupHeaders;
        this.logger.log("copyToClipboard: includeHeaders = ".concat(includeHeaders));
        // don't override 'includeHeaders' if it has been explicitly set to 'false'
        if (includeHeaders == null) {
            includeHeaders = this.gridOptionsService.get('copyHeadersToClipboard');
        }
        if (includeGroupHeaders == null) {
            includeGroupHeaders = this.gridOptionsService.get('copyGroupHeadersToClipboard');
        }
        var copyParams = { includeHeaders: includeHeaders, includeGroupHeaders: includeGroupHeaders };
        var shouldCopyRows = !this.gridOptionsService.get('suppressCopyRowsToClipboard');
        var cellClearType = null;
        // Copy priority is Range > Row > Focus
        if (this.rangeService && !this.rangeService.isEmpty() && !this.shouldSkipSingleCellRange()) {
            this.copySelectedRangeToClipboard(copyParams);
            cellClearType = CellClearType.CellRange;
        }
        else if (shouldCopyRows && !this.selectionService.isEmpty()) {
            this.copySelectedRowsToClipboard(copyParams);
            cellClearType = CellClearType.SelectedRows;
        }
        else if (this.focusService.isAnyCellFocused()) {
            this.copyFocusedCellToClipboard(copyParams);
            cellClearType = CellClearType.FocusedCell;
        }
        if (cut && cellClearType !== null) {
            this.clearCellsAfterCopy(cellClearType);
        }
    };
    ClipboardService.prototype.clearCellsAfterCopy = function (type) {
        this.eventService.dispatchEvent({ type: Events.EVENT_KEY_SHORTCUT_CHANGED_CELL_START });
        if (type === CellClearType.CellRange) {
            this.rangeService.clearCellRangeCellValues({ cellEventSource: 'clipboardService' });
        }
        else if (type === CellClearType.SelectedRows) {
            this.clearSelectedRows();
        }
        else {
            var focusedCell = this.focusService.getFocusedCell();
            if (focusedCell == null) {
                return;
            }
            var rowNode = this.rowPositionUtils.getRowNode(focusedCell);
            if (rowNode) {
                this.clearCellValue(rowNode, focusedCell.column);
            }
        }
        this.eventService.dispatchEvent({ type: Events.EVENT_KEY_SHORTCUT_CHANGED_CELL_END });
    };
    ClipboardService.prototype.clearSelectedRows = function () {
        var e_1, _a, e_2, _b;
        var selected = this.selectionService.getSelectedNodes();
        var columns = this.columnModel.getAllDisplayedColumns();
        try {
            for (var selected_1 = __values$b(selected), selected_1_1 = selected_1.next(); !selected_1_1.done; selected_1_1 = selected_1.next()) {
                var row = selected_1_1.value;
                try {
                    for (var columns_1 = (e_2 = void 0, __values$b(columns)), columns_1_1 = columns_1.next(); !columns_1_1.done; columns_1_1 = columns_1.next()) {
                        var col = columns_1_1.value;
                        this.clearCellValue(row, col);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (columns_1_1 && !columns_1_1.done && (_b = columns_1.return)) _b.call(columns_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (selected_1_1 && !selected_1_1.done && (_a = selected_1.return)) _a.call(selected_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    ClipboardService.prototype.clearCellValue = function (rowNode, column) {
        if (!column.isCellEditable(rowNode)) {
            return;
        }
        rowNode.setDataValue(column, null, 'clipboardService');
    };
    ClipboardService.prototype.shouldSkipSingleCellRange = function () {
        return this.gridOptionsService.get('suppressCopySingleCellRanges') && !this.rangeService.isMoreThanOneCell();
    };
    ClipboardService.prototype.iterateActiveRanges = function (onlyFirst, rowCallback, columnCallback) {
        var _this = this;
        if (!this.rangeService || this.rangeService.isEmpty()) {
            return;
        }
        var cellRanges = this.rangeService.getCellRanges();
        if (onlyFirst) {
            this.iterateActiveRange(cellRanges[0], rowCallback, columnCallback, true);
        }
        else {
            cellRanges.forEach(function (range, idx) { return _this.iterateActiveRange(range, rowCallback, columnCallback, idx === cellRanges.length - 1); });
        }
    };
    ClipboardService.prototype.iterateActiveRange = function (range, rowCallback, columnCallback, isLastRange) {
        if (!this.rangeService) {
            return;
        }
        var currentRow = this.rangeService.getRangeStartRow(range);
        var lastRow = this.rangeService.getRangeEndRow(range);
        if (columnCallback && range.columns) {
            columnCallback(range.columns);
        }
        var rangeIndex = 0;
        var isLastRow = false;
        // the currentRow could be missing if the user sets the active range manually, and sets a range
        // that is outside of the grid (eg. sets range rows 0 to 100, but grid has only 20 rows).
        while (!isLastRow && currentRow != null) {
            var rowNode = this.rowPositionUtils.getRowNode(currentRow);
            isLastRow = this.rowPositionUtils.sameRow(currentRow, lastRow);
            rowCallback(currentRow, rowNode, range.columns, rangeIndex++, isLastRow && isLastRange);
            currentRow = this.cellNavigationService.getRowBelow(currentRow);
        }
    };
    ClipboardService.prototype.copySelectedRangeToClipboard = function (params) {
        if (params === void 0) { params = {}; }
        if (!this.rangeService || this.rangeService.isEmpty()) {
            return;
        }
        var allRangesMerge = this.rangeService.areAllRangesAbleToMerge();
        var _a = allRangesMerge ? this.buildDataFromMergedRanges(params) : this.buildDataFromRanges(params), data = _a.data, cellsToFlash = _a.cellsToFlash;
        this.copyDataToClipboard(data);
        this.dispatchFlashCells(cellsToFlash);
    };
    ClipboardService.prototype.buildDataFromMergedRanges = function (params) {
        var _this = this;
        var columnsSet = new Set();
        var ranges = this.rangeService.getCellRanges();
        var rowPositionsMap = new Map();
        var allRowPositions = [];
        var allCellsToFlash = {};
        ranges.forEach(function (range) {
            range.columns.forEach(function (col) { return columnsSet.add(col); });
            var _a = _this.getRangeRowPositionsAndCellsToFlash(range), rowPositions = _a.rowPositions, cellsToFlash = _a.cellsToFlash;
            rowPositions.forEach(function (rowPosition) {
                var rowPositionAsString = "".concat(rowPosition.rowIndex, "-").concat(rowPosition.rowPinned || 'null');
                if (!rowPositionsMap.get(rowPositionAsString)) {
                    rowPositionsMap.set(rowPositionAsString, true);
                    allRowPositions.push(rowPosition);
                }
            });
            Object.assign(allCellsToFlash, cellsToFlash);
        });
        var allColumns = this.columnModel.getAllDisplayedColumns();
        var exportedColumns = Array.from(columnsSet);
        exportedColumns.sort(function (a, b) {
            var posA = allColumns.indexOf(a);
            var posB = allColumns.indexOf(b);
            return posA - posB;
        });
        var data = this.buildExportParams({
            columns: exportedColumns,
            rowPositions: allRowPositions,
            includeHeaders: params.includeHeaders,
            includeGroupHeaders: params.includeGroupHeaders,
        });
        return { data: data, cellsToFlash: allCellsToFlash };
    };
    ClipboardService.prototype.buildDataFromRanges = function (params) {
        var _this = this;
        var ranges = this.rangeService.getCellRanges();
        var data = [];
        var allCellsToFlash = {};
        ranges.forEach(function (range) {
            var _a = _this.getRangeRowPositionsAndCellsToFlash(range), rowPositions = _a.rowPositions, cellsToFlash = _a.cellsToFlash;
            Object.assign(allCellsToFlash, cellsToFlash);
            data.push(_this.buildExportParams({
                columns: range.columns,
                rowPositions: rowPositions,
                includeHeaders: params.includeHeaders,
                includeGroupHeaders: params.includeGroupHeaders,
            }));
        });
        return { data: data.join('\n'), cellsToFlash: allCellsToFlash };
    };
    ClipboardService.prototype.getRangeRowPositionsAndCellsToFlash = function (range) {
        var _this = this;
        var rowPositions = [];
        var cellsToFlash = {};
        var startRow = this.rangeService.getRangeStartRow(range);
        var lastRow = this.rangeService.getRangeEndRow(range);
        var node = startRow;
        while (node) {
            rowPositions.push(node);
            range.columns.forEach(function (column) {
                var _a = node, rowIndex = _a.rowIndex, rowPinned = _a.rowPinned;
                var cellId = _this.cellPositionUtils.createIdFromValues({ rowIndex: rowIndex, column: column, rowPinned: rowPinned });
                cellsToFlash[cellId] = true;
            });
            if (this.rowPositionUtils.sameRow(node, lastRow)) {
                break;
            }
            node = this.cellNavigationService.getRowBelow(node);
        }
        return { rowPositions: rowPositions, cellsToFlash: cellsToFlash };
    };
    ClipboardService.prototype.getCellsToFlashFromRowNodes = function (rowNodes) {
        var allDisplayedColumns = this.columnModel.getAllDisplayedColumns();
        var cellsToFlash = {};
        for (var i = 0; i < rowNodes.length; i++) {
            var _a = rowNodes[i], rowIndex = _a.rowIndex, rowPinned = _a.rowPinned;
            if (rowIndex == null) {
                continue;
            }
            for (var j = 0; j < allDisplayedColumns.length; j++) {
                var column = allDisplayedColumns[j];
                var cellId = this.cellPositionUtils.createIdFromValues({ rowIndex: rowIndex, column: column, rowPinned: rowPinned });
                cellsToFlash[cellId] = true;
            }
        }
        return cellsToFlash;
    };
    ClipboardService.prototype.copyFocusedCellToClipboard = function (params) {
        var _a;
        if (params === void 0) { params = {}; }
        var focusedCell = this.focusService.getFocusedCell();
        if (focusedCell == null) {
            return;
        }
        var cellId = this.cellPositionUtils.createId(focusedCell);
        var currentRow = { rowPinned: focusedCell.rowPinned, rowIndex: focusedCell.rowIndex };
        var column = focusedCell.column;
        var data = this.buildExportParams({
            columns: [column],
            rowPositions: [currentRow],
            includeHeaders: params.includeHeaders,
            includeGroupHeaders: params.includeGroupHeaders
        });
        this.copyDataToClipboard(data);
        this.dispatchFlashCells((_a = {}, _a[cellId] = true, _a));
    };
    ClipboardService.prototype.copySelectedRowsToClipboard = function (params) {
        if (params === void 0) { params = {}; }
        var columnKeys = params.columnKeys, includeHeaders = params.includeHeaders, includeGroupHeaders = params.includeGroupHeaders;
        var data = this.buildExportParams({
            columns: columnKeys,
            includeHeaders: includeHeaders,
            includeGroupHeaders: includeGroupHeaders
        });
        this.copyDataToClipboard(data);
        var rowNodes = this.selectionService.getSelectedNodes() || [];
        this.dispatchFlashCells(this.getCellsToFlashFromRowNodes(rowNodes));
    };
    ClipboardService.prototype.buildExportParams = function (params) {
        var _this = this;
        var columns = params.columns, rowPositions = params.rowPositions, _a = params.includeHeaders, includeHeaders = _a === void 0 ? false : _a, _b = params.includeGroupHeaders, includeGroupHeaders = _b === void 0 ? false : _b;
        var exportParams = {
            columnKeys: columns,
            rowPositions: rowPositions,
            skipColumnHeaders: !includeHeaders,
            skipColumnGroupHeaders: !includeGroupHeaders,
            suppressQuotes: true,
            columnSeparator: this.getClipboardDelimiter(),
            onlySelected: !rowPositions,
            processCellCallback: this.gridOptionsService.getCallback('processCellForClipboard'),
            processRowGroupCallback: function (params) { return _this.processRowGroupCallback(params); },
            processHeaderCallback: this.gridOptionsService.getCallback('processHeaderForClipboard'),
            processGroupHeaderCallback: this.gridOptionsService.getCallback('processGroupHeaderForClipboard')
        };
        return this.csvCreator.getDataAsCsv(exportParams, true);
    };
    ClipboardService.prototype.processRowGroupCallback = function (params) {
        var _this = this;
        var node = params.node, column = params.column;
        var isTreeData = this.gridOptionsService.get('treeData');
        var isSuppressGroupMaintainValueType = this.gridOptionsService.get('suppressGroupMaintainValueType');
        // if not tree data and not suppressGroupMaintainValueType then we get the value from the group data
        var getValueFromNode = function () {
            var _a, _b;
            if (isTreeData || isSuppressGroupMaintainValueType || !column) {
                return node.key;
            }
            var value = (_a = node.groupData) === null || _a === void 0 ? void 0 : _a[column.getId()];
            if (!value || !node.rowGroupColumn || node.rowGroupColumn.getColDef().useValueFormatterForExport === false) {
                return value;
            }
            return (_b = _this.valueFormatterService.formatValue(node.rowGroupColumn, node, value)) !== null && _b !== void 0 ? _b : value;
        };
        var value = getValueFromNode();
        if (params.node.footer) {
            var suffix = '';
            if (value && value.length) {
                suffix = " ".concat(value);
            }
            value = "Total".concat(suffix);
        }
        var processCellForClipboard = this.gridOptionsService.getCallback('processCellForClipboard');
        if (processCellForClipboard) {
            var column_1 = node.rowGroupColumn;
            if (!column_1 && node.footer && node.level === -1) {
                column_1 = this.columnModel.getRowGroupColumns()[0];
            }
            return processCellForClipboard({
                value: value,
                node: node,
                column: column_1,
                type: 'clipboard',
                formatValue: function (valueToFormat) { var _a; return (_a = _this.valueFormatterService.formatValue(column_1, node, valueToFormat)) !== null && _a !== void 0 ? _a : valueToFormat; },
                parseValue: function (valueToParse) { return _this.valueParserService.parseValue(column_1, node, valueToParse, _this.valueService.getValue(column_1, node)); }
            });
        }
        return value;
    };
    ClipboardService.prototype.dispatchFlashCells = function (cellsToFlash) {
        var _this = this;
        window.setTimeout(function () {
            var event = {
                type: Events.EVENT_FLASH_CELLS,
                cells: cellsToFlash
            };
            _this.eventService.dispatchEvent(event);
        }, 0);
    };
    ClipboardService.prototype.processCell = function (rowNode, column, value, type, func, canParse, canFormat) {
        var _this = this;
        var _a;
        if (func) {
            var params = {
                column: column,
                node: rowNode,
                value: value,
                type: type,
                formatValue: function (valueToFormat) { var _a; return (_a = _this.valueFormatterService.formatValue(column, rowNode !== null && rowNode !== void 0 ? rowNode : null, valueToFormat)) !== null && _a !== void 0 ? _a : valueToFormat; },
                parseValue: function (valueToParse) { return _this.valueParserService.parseValue(column, rowNode !== null && rowNode !== void 0 ? rowNode : null, valueToParse, _this.valueService.getValue(column, rowNode)); }
            };
            return func(params);
        }
        if (canParse && column.getColDef().useValueParserForImport !== false) {
            return this.valueParserService.parseValue(column, rowNode !== null && rowNode !== void 0 ? rowNode : null, value, this.valueService.getValue(column, rowNode));
        }
        else if (canFormat && column.getColDef().useValueFormatterForExport !== false) {
            return (_a = this.valueFormatterService.formatValue(column, rowNode !== null && rowNode !== void 0 ? rowNode : null, value)) !== null && _a !== void 0 ? _a : value;
        }
        return value;
    };
    ClipboardService.prototype.copyDataToClipboard = function (data) {
        var _this = this;
        var userProvidedFunc = this.gridOptionsService.getCallback('sendToClipboard');
        // method 1 - user provided func
        if (userProvidedFunc) {
            userProvidedFunc({ data: data });
            return;
        }
        // method 2 - native clipboard API, available in modern chrome browsers
        var allowNavigator = !this.gridOptionsService.get('suppressClipboardApi');
        if (allowNavigator && navigator.clipboard) {
            navigator.clipboard.writeText(data).catch(function (e) {
                _.doOnce(function () {
                    console.warn(e);
                    console.warn(apiError('writeText'));
                }, 'clipboardApiError');
                _this.copyDataToClipboardLegacy(data);
            });
            return;
        }
        this.copyDataToClipboardLegacy(data);
    };
    ClipboardService.prototype.copyDataToClipboardLegacy = function (data) {
        var _this = this;
        // method 3 - if all else fails, the old school hack
        this.executeOnTempElement(function (element) {
            var eDocument = _this.gridOptionsService.getDocument();
            var focusedElementBefore = eDocument.activeElement;
            element.value = data || ' '; // has to be non-empty value or execCommand will not do anything
            element.select();
            element.focus({ preventScroll: true });
            var result = eDocument.execCommand('copy');
            if (!result) {
                console.warn('AG Grid: Browser did not allow document.execCommand(\'copy\'). Ensure ' +
                    'api.copySelectedRowsToClipboard() is invoked via a user event, i.e. button click, otherwise ' +
                    'the browser will prevent it for security reasons.');
            }
            if (focusedElementBefore != null && focusedElementBefore.focus != null) {
                focusedElementBefore.focus({ preventScroll: true });
            }
        });
    };
    ClipboardService.prototype.executeOnTempElement = function (callbackNow, callbackAfter) {
        var eDoc = this.gridOptionsService.getDocument();
        var eTempInput = eDoc.createElement('textarea');
        eTempInput.style.width = '1px';
        eTempInput.style.height = '1px';
        // removing items from the DOM causes the document element to scroll to the
        // position where the element was positioned. Here we set scrollTop / scrollLeft
        // to prevent the document element from scrolling when we remove it from the DOM.
        eTempInput.style.top = eDoc.documentElement.scrollTop + 'px';
        eTempInput.style.left = eDoc.documentElement.scrollLeft + 'px';
        eTempInput.style.position = 'absolute';
        eTempInput.style.opacity = '0';
        var guiRoot = this.gridCtrl.getGui();
        guiRoot.appendChild(eTempInput);
        try {
            callbackNow(eTempInput);
        }
        catch (err) {
            console.warn('AG Grid: Browser does not support document.execCommand(\'copy\') for clipboard operations');
        }
        //It needs 100 otherwise OS X seemed to not always be able to paste... Go figure...
        if (callbackAfter) {
            window.setTimeout(function () {
                callbackAfter(eTempInput);
                guiRoot.removeChild(eTempInput);
            }, 100);
        }
        else {
            guiRoot.removeChild(eTempInput);
        }
    };
    ClipboardService.prototype.getRangeSize = function () {
        var ranges = this.rangeService.getCellRanges();
        var startRangeIndex = 0;
        var endRangeIndex = 0;
        if (ranges.length > 0) {
            startRangeIndex = this.rangeService.getRangeStartRow(ranges[0]).rowIndex;
            endRangeIndex = this.rangeService.getRangeEndRow(ranges[0]).rowIndex;
        }
        return startRangeIndex - endRangeIndex + 1;
    };
    var ClipboardService_1;
    __decorate$1N([
        Autowired('csvCreator')
    ], ClipboardService.prototype, "csvCreator", void 0);
    __decorate$1N([
        Autowired('loggerFactory')
    ], ClipboardService.prototype, "loggerFactory", void 0);
    __decorate$1N([
        Autowired('selectionService')
    ], ClipboardService.prototype, "selectionService", void 0);
    __decorate$1N([
        Optional('rangeService')
    ], ClipboardService.prototype, "rangeService", void 0);
    __decorate$1N([
        Autowired('rowModel')
    ], ClipboardService.prototype, "rowModel", void 0);
    __decorate$1N([
        Autowired('ctrlsService')
    ], ClipboardService.prototype, "ctrlsService", void 0);
    __decorate$1N([
        Autowired('valueService')
    ], ClipboardService.prototype, "valueService", void 0);
    __decorate$1N([
        Autowired('focusService')
    ], ClipboardService.prototype, "focusService", void 0);
    __decorate$1N([
        Autowired('rowRenderer')
    ], ClipboardService.prototype, "rowRenderer", void 0);
    __decorate$1N([
        Autowired('columnModel')
    ], ClipboardService.prototype, "columnModel", void 0);
    __decorate$1N([
        Autowired('cellNavigationService')
    ], ClipboardService.prototype, "cellNavigationService", void 0);
    __decorate$1N([
        Autowired('cellPositionUtils')
    ], ClipboardService.prototype, "cellPositionUtils", void 0);
    __decorate$1N([
        Autowired('rowPositionUtils')
    ], ClipboardService.prototype, "rowPositionUtils", void 0);
    __decorate$1N([
        Autowired('valueFormatterService')
    ], ClipboardService.prototype, "valueFormatterService", void 0);
    __decorate$1N([
        Autowired('valueParserService')
    ], ClipboardService.prototype, "valueParserService", void 0);
    __decorate$1N([
        PostConstruct
    ], ClipboardService.prototype, "init", null);
    ClipboardService = ClipboardService_1 = __decorate$1N([
        Bean('clipboardService')
    ], ClipboardService);
    return ClipboardService;
}(BeanStub));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$h = '31.0.2';

var ClipboardModule = {
    version: VERSION$h,
    moduleName: ModuleNames.ClipboardModule,
    beans: [ClipboardService],
    dependantModules: [
        EnterpriseCoreModule,
        CsvExportModule
    ]
};

var __extends$2h = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1M = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ExpandState;
(function (ExpandState) {
    ExpandState[ExpandState["EXPANDED"] = 0] = "EXPANDED";
    ExpandState[ExpandState["COLLAPSED"] = 1] = "COLLAPSED";
    ExpandState[ExpandState["INDETERMINATE"] = 2] = "INDETERMINATE";
})(ExpandState || (ExpandState = {}));
var PrimaryColsHeaderPanel = /** @class */ (function (_super) {
    __extends$2h(PrimaryColsHeaderPanel, _super);
    function PrimaryColsHeaderPanel() {
        return _super.call(this, PrimaryColsHeaderPanel.TEMPLATE) || this;
    }
    PrimaryColsHeaderPanel.prototype.postConstruct = function () {
        var _this = this;
        this.createExpandIcons();
        this.addManagedListener(this.eExpand, 'click', this.onExpandClicked.bind(this));
        this.addManagedListener(this.eExpand, 'keydown', function (e) {
            if (e.key === KeyCode.SPACE) {
                e.preventDefault();
                _this.onExpandClicked();
            }
        });
        this.addManagedListener(this.eSelect.getInputElement(), 'click', this.onSelectClicked.bind(this));
        this.addManagedPropertyListener('functionsReadOnly', function () { return _this.onFunctionsReadOnlyPropChanged(); });
        this.eFilterTextField
            .setAutoComplete(false)
            .onValueChange(function () { return _this.onFilterTextChanged(); });
        this.addManagedListener(this.eFilterTextField.getInputElement(), 'keydown', this.onMiniFilterKeyDown.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_NEW_COLUMNS_LOADED, this.showOrHideOptions.bind(this));
        var translate = this.localeService.getLocaleTextFunc();
        this.eSelect.setInputAriaLabel(translate('ariaColumnSelectAll', 'Toggle Select All Columns'));
        this.eFilterTextField.setInputAriaLabel(translate('ariaFilterColumnsInput', 'Filter Columns Input'));
        this.activateTabIndex([this.eExpand]);
    };
    PrimaryColsHeaderPanel.prototype.onFunctionsReadOnlyPropChanged = function () {
        var readOnly = this.gridOptionsService.get('functionsReadOnly');
        this.eSelect.setReadOnly(readOnly);
        this.eSelect.addOrRemoveCssClass('ag-column-select-column-readonly', readOnly);
    };
    PrimaryColsHeaderPanel.prototype.init = function (params) {
        this.params = params;
        var readOnly = this.gridOptionsService.get('functionsReadOnly');
        this.eSelect.setReadOnly(readOnly);
        this.eSelect.addOrRemoveCssClass('ag-column-select-column-readonly', readOnly);
        if (this.columnModel.isReady()) {
            this.showOrHideOptions();
        }
    };
    PrimaryColsHeaderPanel.prototype.createExpandIcons = function () {
        this.eExpand.appendChild((this.eExpandChecked = _.createIconNoSpan('columnSelectOpen', this.gridOptionsService)));
        this.eExpand.appendChild((this.eExpandUnchecked = _.createIconNoSpan('columnSelectClosed', this.gridOptionsService)));
        this.eExpand.appendChild((this.eExpandIndeterminate = _.createIconNoSpan('columnSelectIndeterminate', this.gridOptionsService)));
        this.setExpandState(ExpandState.EXPANDED);
    };
    // we only show expand / collapse if we are showing columns
    PrimaryColsHeaderPanel.prototype.showOrHideOptions = function () {
        var showFilter = !this.params.suppressColumnFilter;
        var showSelect = !this.params.suppressColumnSelectAll;
        var showExpand = !this.params.suppressColumnExpandAll;
        var groupsPresent = this.columnModel.isPrimaryColumnGroupsPresent();
        var translate = this.localeService.getLocaleTextFunc();
        this.eFilterTextField.setInputPlaceholder(translate('searchOoo', 'Search...'));
        _.setDisplayed(this.eFilterTextField.getGui(), showFilter);
        _.setDisplayed(this.eSelect.getGui(), showSelect);
        _.setDisplayed(this.eExpand, showExpand && groupsPresent);
    };
    PrimaryColsHeaderPanel.prototype.onFilterTextChanged = function () {
        var _this = this;
        if (!this.onFilterTextChangedDebounced) {
            this.onFilterTextChangedDebounced = _.debounce(function () {
                var filterText = _this.eFilterTextField.getValue();
                _this.dispatchEvent({ type: "filterChanged", filterText: filterText });
            }, PrimaryColsHeaderPanel.DEBOUNCE_DELAY);
        }
        this.onFilterTextChangedDebounced();
    };
    PrimaryColsHeaderPanel.prototype.onMiniFilterKeyDown = function (e) {
        var _this = this;
        if (e.key === KeyCode.ENTER) {
            // we need to add a delay that corresponds to the filter text debounce delay to ensure
            // the text filtering has happened, otherwise all columns will be deselected
            setTimeout(function () { return _this.onSelectClicked(); }, PrimaryColsHeaderPanel.DEBOUNCE_DELAY);
        }
    };
    PrimaryColsHeaderPanel.prototype.onSelectClicked = function () {
        this.dispatchEvent({ type: this.selectState ? 'unselectAll' : 'selectAll' });
    };
    PrimaryColsHeaderPanel.prototype.onExpandClicked = function () {
        this.dispatchEvent({ type: this.expandState === ExpandState.EXPANDED ? 'collapseAll' : 'expandAll' });
    };
    PrimaryColsHeaderPanel.prototype.setExpandState = function (state) {
        this.expandState = state;
        _.setDisplayed(this.eExpandChecked, this.expandState === ExpandState.EXPANDED);
        _.setDisplayed(this.eExpandUnchecked, this.expandState === ExpandState.COLLAPSED);
        _.setDisplayed(this.eExpandIndeterminate, this.expandState === ExpandState.INDETERMINATE);
    };
    PrimaryColsHeaderPanel.prototype.setSelectionState = function (state) {
        this.selectState = state;
        this.eSelect.setValue(this.selectState);
    };
    PrimaryColsHeaderPanel.DEBOUNCE_DELAY = 300;
    PrimaryColsHeaderPanel.TEMPLATE = "<div class=\"ag-column-select-header\" role=\"presentation\">\n            <div ref=\"eExpand\" class=\"ag-column-select-header-icon\"></div>\n            <ag-checkbox ref=\"eSelect\" class=\"ag-column-select-header-checkbox\"></ag-checkbox>\n            <ag-input-text-field class=\"ag-column-select-header-filter-wrapper\" ref=\"eFilterTextField\"></ag-input-text-field>\n        </div>";
    __decorate$1M([
        Autowired('columnModel')
    ], PrimaryColsHeaderPanel.prototype, "columnModel", void 0);
    __decorate$1M([
        RefSelector('eExpand')
    ], PrimaryColsHeaderPanel.prototype, "eExpand", void 0);
    __decorate$1M([
        RefSelector('eSelect')
    ], PrimaryColsHeaderPanel.prototype, "eSelect", void 0);
    __decorate$1M([
        RefSelector('eFilterTextField')
    ], PrimaryColsHeaderPanel.prototype, "eFilterTextField", void 0);
    __decorate$1M([
        PostConstruct
    ], PrimaryColsHeaderPanel.prototype, "postConstruct", null);
    return PrimaryColsHeaderPanel;
}(Component));

var ColumnModelItem = /** @class */ (function () {
    function ColumnModelItem(displayName, columnOrGroup, dept, group, expanded) {
        if (group === void 0) { group = false; }
        this.eventService = new EventService();
        this.displayName = displayName;
        this.dept = dept;
        this.group = group;
        if (group) {
            this.columnGroup = columnOrGroup;
            this.expanded = expanded;
            this.children = [];
        }
        else {
            this.column = columnOrGroup;
        }
    }
    ColumnModelItem.prototype.isGroup = function () { return this.group; };
    ColumnModelItem.prototype.getDisplayName = function () { return this.displayName; };
    ColumnModelItem.prototype.getColumnGroup = function () { return this.columnGroup; };
    ColumnModelItem.prototype.getColumn = function () { return this.column; };
    ColumnModelItem.prototype.getDept = function () { return this.dept; };
    ColumnModelItem.prototype.isExpanded = function () { return !!this.expanded; };
    ColumnModelItem.prototype.getChildren = function () { return this.children; };
    ColumnModelItem.prototype.isPassesFilter = function () { return this.passesFilter; };
    ColumnModelItem.prototype.setExpanded = function (expanded) {
        if (expanded === this.expanded) {
            return;
        }
        this.expanded = expanded;
        this.eventService.dispatchEvent({ type: ColumnModelItem.EVENT_EXPANDED_CHANGED });
    };
    ColumnModelItem.prototype.setPassesFilter = function (passesFilter) {
        this.passesFilter = passesFilter;
    };
    ColumnModelItem.prototype.addEventListener = function (eventType, listener) {
        this.eventService.addEventListener(eventType, listener);
    };
    ColumnModelItem.prototype.removeEventListener = function (eventType, listener) {
        this.eventService.removeEventListener(eventType, listener);
    };
    ColumnModelItem.EVENT_EXPANDED_CHANGED = 'expandedChanged';
    return ColumnModelItem;
}());

var __extends$2g = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1L = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$A = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$m = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values$a = (undefined && undefined.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var ToolPanelContextMenu = /** @class */ (function (_super) {
    __extends$2g(ToolPanelContextMenu, _super);
    function ToolPanelContextMenu(column, mouseEvent, parentEl) {
        var _this = _super.call(this, /* html */ "<div class=\"ag-menu\"></div>") || this;
        _this.column = column;
        _this.mouseEvent = mouseEvent;
        _this.parentEl = parentEl;
        _this.displayName = null;
        return _this;
    }
    ToolPanelContextMenu.prototype.postConstruct = function () {
        this.initializeProperties(this.column);
        this.buildMenuItemMap();
        if (this.column instanceof Column) {
            this.displayName = this.columnModel.getDisplayNameForColumn(this.column, 'columnToolPanel');
        }
        else {
            this.displayName = this.columnModel.getDisplayNameForProvidedColumnGroup(null, this.column, 'columnToolPanel');
        }
        if (this.isActive()) {
            this.mouseEvent.preventDefault();
            var menuItemsMapped = this.getMappedMenuItems();
            if (menuItemsMapped.length === 0) {
                return;
            }
            this.displayContextMenu(menuItemsMapped);
        }
    };
    ToolPanelContextMenu.prototype.initializeProperties = function (column) {
        if (column instanceof ProvidedColumnGroup) {
            this.columns = column.getLeafColumns();
        }
        else {
            this.columns = [column];
        }
        this.allowGrouping = this.columns.some(function (col) { return col.isPrimary() && col.isAllowRowGroup(); });
        this.allowValues = this.columns.some(function (col) { return col.isPrimary() && col.isAllowValue(); });
        this.allowPivoting = this.columnModel.isPivotMode() && this.columns.some(function (col) { return col.isPrimary() && col.isAllowPivot(); });
    };
    ToolPanelContextMenu.prototype.buildMenuItemMap = function () {
        var _this = this;
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        this.menuItemMap = new Map();
        this.menuItemMap.set('rowGroup', {
            allowedFunction: function (col) { return col.isPrimary() && col.isAllowRowGroup() && !_this.columnModel.isColumnGroupingLocked(col); },
            activeFunction: function (col) { return col.isRowGroupActive(); },
            activateLabel: function () { return "".concat(localeTextFunc('groupBy', 'Group by'), " ").concat(_this.displayName); },
            deactivateLabel: function () { return "".concat(localeTextFunc('ungroupBy', 'Un-Group by'), " ").concat(_this.displayName); },
            activateFunction: function () {
                var groupedColumns = _this.columnModel.getRowGroupColumns();
                _this.columnModel.setRowGroupColumns(_this.addColumnsToList(groupedColumns), "toolPanelUi");
            },
            deActivateFunction: function () {
                var groupedColumns = _this.columnModel.getRowGroupColumns();
                _this.columnModel.setRowGroupColumns(_this.removeColumnsFromList(groupedColumns), "toolPanelUi");
            },
            addIcon: 'menuAddRowGroup',
            removeIcon: 'menuRemoveRowGroup'
        });
        this.menuItemMap.set('value', {
            allowedFunction: function (col) { return col.isPrimary() && col.isAllowValue(); },
            activeFunction: function (col) { return col.isValueActive(); },
            activateLabel: function () { return localeTextFunc('addToValues', "Add ".concat(_this.displayName, " to values"), [_this.displayName]); },
            deactivateLabel: function () { return localeTextFunc('removeFromValues', "Remove ".concat(_this.displayName, " from values"), [_this.displayName]); },
            activateFunction: function () {
                var valueColumns = _this.columnModel.getValueColumns();
                _this.columnModel.setValueColumns(_this.addColumnsToList(valueColumns), "toolPanelUi");
            },
            deActivateFunction: function () {
                var valueColumns = _this.columnModel.getValueColumns();
                _this.columnModel.setValueColumns(_this.removeColumnsFromList(valueColumns), "toolPanelUi");
            },
            addIcon: 'valuePanel',
            removeIcon: 'valuePanel'
        });
        this.menuItemMap.set('pivot', {
            allowedFunction: function (col) { return _this.columnModel.isPivotMode() && col.isPrimary() && col.isAllowPivot(); },
            activeFunction: function (col) { return col.isPivotActive(); },
            activateLabel: function () { return localeTextFunc('addToLabels', "Add ".concat(_this.displayName, " to labels"), [_this.displayName]); },
            deactivateLabel: function () { return localeTextFunc('removeFromLabels', "Remove ".concat(_this.displayName, " from labels"), [_this.displayName]); },
            activateFunction: function () {
                var pivotColumns = _this.columnModel.getPivotColumns();
                _this.columnModel.setPivotColumns(_this.addColumnsToList(pivotColumns), "toolPanelUi");
            },
            deActivateFunction: function () {
                var pivotColumns = _this.columnModel.getPivotColumns();
                _this.columnModel.setPivotColumns(_this.removeColumnsFromList(pivotColumns), "toolPanelUi");
            },
            addIcon: 'pivotPanel',
            removeIcon: 'pivotPanel'
        });
    };
    ToolPanelContextMenu.prototype.addColumnsToList = function (columnList) {
        return __spreadArray$m([], __read$A(columnList), false).concat(this.columns.filter(function (col) { return columnList.indexOf(col) === -1; }));
    };
    ToolPanelContextMenu.prototype.removeColumnsFromList = function (columnList) {
        var _this = this;
        return columnList.filter(function (col) { return _this.columns.indexOf(col) === -1; });
    };
    ToolPanelContextMenu.prototype.displayContextMenu = function (menuItemsMapped) {
        var _this = this;
        var eGui = this.getGui();
        var menuList = this.createBean(new AgMenuList());
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        var hideFunc = function () { };
        eGui.appendChild(menuList.getGui());
        menuList.addMenuItems(menuItemsMapped);
        menuList.addManagedListener(menuList, AgMenuItemComponent.EVENT_MENU_ITEM_SELECTED, function () {
            _this.parentEl.focus();
            hideFunc();
        });
        var addPopupRes = this.popupService.addPopup({
            modal: true,
            eChild: eGui,
            closeOnEsc: true,
            afterGuiAttached: function () { return _this.focusService.focusInto(menuList.getGui()); },
            ariaLabel: localeTextFunc('ariaLabelContextMenu', 'Context Menu'),
            closedCallback: function (e) {
                if (e instanceof KeyboardEvent) {
                    _this.parentEl.focus();
                }
                _this.destroyBean(menuList);
            }
        });
        if (addPopupRes) {
            hideFunc = addPopupRes.hideFunc;
        }
        this.popupService.positionPopupUnderMouseEvent({
            type: 'columnContextMenu',
            mouseEvent: this.mouseEvent,
            ePopup: eGui
        });
    };
    ToolPanelContextMenu.prototype.isActive = function () {
        return this.allowGrouping || this.allowValues || this.allowPivoting;
    };
    ToolPanelContextMenu.prototype.getMappedMenuItems = function () {
        var e_1, _a;
        var ret = [];
        var _loop_1 = function (val) {
            var isInactive = this_1.columns.some(function (col) { return val.allowedFunction(col) && !val.activeFunction(col); });
            var isActive = this_1.columns.some(function (col) { return val.allowedFunction(col) && val.activeFunction(col); });
            if (isInactive) {
                ret.push({
                    name: val.activateLabel(this_1.displayName),
                    icon: _.createIconNoSpan(val.addIcon, this_1.gridOptionsService, null),
                    action: function () { return val.activateFunction(); }
                });
            }
            if (isActive) {
                ret.push({
                    name: val.deactivateLabel(this_1.displayName),
                    icon: _.createIconNoSpan(val.removeIcon, this_1.gridOptionsService, null),
                    action: function () { return val.deActivateFunction(); }
                });
            }
        };
        var this_1 = this;
        try {
            for (var _b = __values$a(this.menuItemMap.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var val = _c.value;
                _loop_1(val);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return ret;
    };
    __decorate$1L([
        Autowired('columnModel')
    ], ToolPanelContextMenu.prototype, "columnModel", void 0);
    __decorate$1L([
        Autowired('popupService')
    ], ToolPanelContextMenu.prototype, "popupService", void 0);
    __decorate$1L([
        Autowired('focusService')
    ], ToolPanelContextMenu.prototype, "focusService", void 0);
    __decorate$1L([
        PostConstruct
    ], ToolPanelContextMenu.prototype, "postConstruct", null);
    return ToolPanelContextMenu;
}(Component));

var __extends$2f = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1K = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ToolPanelColumnGroupComp = /** @class */ (function (_super) {
    __extends$2f(ToolPanelColumnGroupComp, _super);
    function ToolPanelColumnGroupComp(modelItem, allowDragging, eventType, focusWrapper) {
        var _this = _super.call(this) || this;
        _this.modelItem = modelItem;
        _this.allowDragging = allowDragging;
        _this.eventType = eventType;
        _this.focusWrapper = focusWrapper;
        _this.processingColumnStateChange = false;
        _this.modelItem = modelItem;
        _this.columnGroup = modelItem.getColumnGroup();
        _this.columnDept = modelItem.getDept();
        _this.displayName = modelItem.getDisplayName();
        _this.allowDragging = allowDragging;
        return _this;
    }
    ToolPanelColumnGroupComp.prototype.init = function () {
        var _this = this;
        this.setTemplate(ToolPanelColumnGroupComp.TEMPLATE);
        this.eDragHandle = _.createIconNoSpan('columnDrag', this.gridOptionsService);
        this.eDragHandle.classList.add('ag-drag-handle', 'ag-column-select-column-group-drag-handle');
        var checkboxGui = this.cbSelect.getGui();
        var checkboxInput = this.cbSelect.getInputElement();
        checkboxGui.insertAdjacentElement('afterend', this.eDragHandle);
        checkboxInput.setAttribute('tabindex', '-1');
        this.eLabel.innerHTML = this.displayName ? this.displayName : '';
        this.setupExpandContract();
        this.addCssClass('ag-column-select-indent-' + this.columnDept);
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_PIVOT_MODE_CHANGED, this.onColumnStateChanged.bind(this));
        this.addManagedListener(this.eLabel, 'click', this.onLabelClicked.bind(this));
        this.addManagedListener(this.cbSelect, Events.EVENT_FIELD_VALUE_CHANGED, this.onCheckboxChanged.bind(this));
        this.addManagedListener(this.modelItem, ColumnModelItem.EVENT_EXPANDED_CHANGED, this.onExpandChanged.bind(this));
        this.addManagedListener(this.focusWrapper, 'keydown', this.handleKeyDown.bind(this));
        this.addManagedListener(this.focusWrapper, 'contextmenu', this.onContextMenu.bind(this));
        this.setOpenClosedIcons();
        this.setupDragging();
        this.onColumnStateChanged();
        this.addVisibilityListenersToAllChildren();
        this.refreshAriaExpanded();
        this.refreshAriaLabel();
        this.setupTooltip();
        var classes = CssClassApplier.getToolPanelClassesFromColDef(this.columnGroup.getColGroupDef(), this.gridOptionsService, null, this.columnGroup);
        classes.forEach(function (c) { return _this.addOrRemoveCssClass(c, true); });
    };
    ToolPanelColumnGroupComp.prototype.getColumns = function () {
        return this.columnGroup.getLeafColumns();
    };
    ToolPanelColumnGroupComp.prototype.setupTooltip = function () {
        var _this = this;
        var colGroupDef = this.columnGroup.getColGroupDef();
        if (!colGroupDef) {
            return;
        }
        var refresh = function () {
            var newTooltipText = colGroupDef.headerTooltip;
            _this.setTooltip(newTooltipText);
        };
        refresh();
        this.addManagedListener(this.eventService, Events.EVENT_NEW_COLUMNS_LOADED, refresh);
    };
    ToolPanelColumnGroupComp.prototype.getTooltipParams = function () {
        var res = _super.prototype.getTooltipParams.call(this);
        res.location = 'columnToolPanelColumnGroup';
        return res;
    };
    ToolPanelColumnGroupComp.prototype.handleKeyDown = function (e) {
        switch (e.key) {
            case KeyCode.LEFT:
                e.preventDefault();
                this.modelItem.setExpanded(false);
                break;
            case KeyCode.RIGHT:
                e.preventDefault();
                this.modelItem.setExpanded(true);
                break;
            case KeyCode.SPACE:
                e.preventDefault();
                if (this.isSelectable()) {
                    this.onSelectAllChanged(!this.isSelected());
                }
                break;
        }
    };
    ToolPanelColumnGroupComp.prototype.onContextMenu = function (e) {
        var _this = this;
        var _a = this, columnGroup = _a.columnGroup, gridOptionsService = _a.gridOptionsService;
        if (gridOptionsService.get('functionsReadOnly')) {
            return;
        }
        var contextMenu = this.createBean(new ToolPanelContextMenu(columnGroup, e, this.focusWrapper));
        this.addDestroyFunc(function () {
            if (contextMenu.isAlive()) {
                _this.destroyBean(contextMenu);
            }
        });
    };
    ToolPanelColumnGroupComp.prototype.addVisibilityListenersToAllChildren = function () {
        var _this = this;
        this.columnGroup.getLeafColumns().forEach(function (column) {
            _this.addManagedListener(column, Column.EVENT_VISIBLE_CHANGED, _this.onColumnStateChanged.bind(_this));
            _this.addManagedListener(column, Column.EVENT_VALUE_CHANGED, _this.onColumnStateChanged.bind(_this));
            _this.addManagedListener(column, Column.EVENT_PIVOT_CHANGED, _this.onColumnStateChanged.bind(_this));
            _this.addManagedListener(column, Column.EVENT_ROW_GROUP_CHANGED, _this.onColumnStateChanged.bind(_this));
        });
    };
    ToolPanelColumnGroupComp.prototype.setupDragging = function () {
        var _this = this;
        if (!this.allowDragging) {
            _.setDisplayed(this.eDragHandle, false);
            return;
        }
        var hideColumnOnExit = !this.gridOptionsService.get('suppressDragLeaveHidesColumns');
        var dragSource = {
            type: DragSourceType.ToolPanel,
            eElement: this.eDragHandle,
            dragItemName: this.displayName,
            getDefaultIconName: function () { return hideColumnOnExit ? DragAndDropService.ICON_HIDE : DragAndDropService.ICON_NOT_ALLOWED; },
            getDragItem: function () { return _this.createDragItem(); },
            onDragStarted: function () {
                hideColumnOnExit = !_this.gridOptionsService.get('suppressDragLeaveHidesColumns');
                var event = {
                    type: Events.EVENT_COLUMN_PANEL_ITEM_DRAG_START,
                    column: _this.columnGroup
                };
                _this.eventService.dispatchEvent(event);
            },
            onDragStopped: function () {
                var event = {
                    type: Events.EVENT_COLUMN_PANEL_ITEM_DRAG_END
                };
                _this.eventService.dispatchEvent(event);
            },
            onGridEnter: function (dragItem) {
                if (hideColumnOnExit) {
                    // when dragged into the grid, restore the state that was active pre-drag
                    _this.modelItemUtils.updateColumns({
                        columns: _this.columnGroup.getLeafColumns(),
                        visibleState: dragItem === null || dragItem === void 0 ? void 0 : dragItem.visibleState,
                        pivotState: dragItem === null || dragItem === void 0 ? void 0 : dragItem.pivotState,
                        eventType: _this.eventType
                    });
                }
            },
            onGridExit: function () {
                if (hideColumnOnExit) {
                    // when dragged outside of the grid, mimic what happens when checkbox is disabled
                    // this handles the behaviour for pivot which is different to just hiding a column.
                    _this.onChangeCommon(false);
                }
            }
        };
        this.dragAndDropService.addDragSource(dragSource, true);
        this.addDestroyFunc(function () { return _this.dragAndDropService.removeDragSource(dragSource); });
    };
    ToolPanelColumnGroupComp.prototype.createDragItem = function () {
        var _this = this;
        var columns = this.columnGroup.getLeafColumns();
        var visibleState = {};
        var pivotState = {};
        columns.forEach(function (col) {
            var colId = col.getId();
            visibleState[colId] = col.isVisible();
            pivotState[colId] = _this.modelItemUtils.createPivotState(col);
        });
        return {
            columns: columns,
            visibleState: visibleState,
            pivotState: pivotState
        };
    };
    ToolPanelColumnGroupComp.prototype.setupExpandContract = function () {
        this.eGroupClosedIcon.appendChild(_.createIcon('columnSelectClosed', this.gridOptionsService, null));
        this.eGroupOpenedIcon.appendChild(_.createIcon('columnSelectOpen', this.gridOptionsService, null));
        this.addManagedListener(this.eGroupClosedIcon, 'click', this.onExpandOrContractClicked.bind(this));
        this.addManagedListener(this.eGroupOpenedIcon, 'click', this.onExpandOrContractClicked.bind(this));
        var touchListener = new TouchListener(this.eColumnGroupIcons, true);
        this.addManagedListener(touchListener, TouchListener.EVENT_TAP, this.onExpandOrContractClicked.bind(this));
        this.addDestroyFunc(touchListener.destroy.bind(touchListener));
    };
    ToolPanelColumnGroupComp.prototype.onLabelClicked = function () {
        var nextState = !this.cbSelect.getValue();
        this.onChangeCommon(nextState);
    };
    ToolPanelColumnGroupComp.prototype.onCheckboxChanged = function (event) {
        this.onChangeCommon(event.selected);
    };
    ToolPanelColumnGroupComp.prototype.getVisibleLeafColumns = function () {
        var childColumns = [];
        var extractCols = function (children) {
            children.forEach(function (child) {
                if (!child.isPassesFilter()) {
                    return;
                }
                if (child.isGroup()) {
                    extractCols(child.getChildren());
                }
                else {
                    childColumns.push(child.getColumn());
                }
            });
        };
        extractCols(this.modelItem.getChildren());
        return childColumns;
    };
    ToolPanelColumnGroupComp.prototype.onChangeCommon = function (nextState) {
        this.refreshAriaLabel();
        if (this.processingColumnStateChange) {
            return;
        }
        this.modelItemUtils.selectAllChildren(this.modelItem.getChildren(), nextState, this.eventType);
    };
    ToolPanelColumnGroupComp.prototype.refreshAriaLabel = function () {
        var translate = this.localeService.getLocaleTextFunc();
        var columnLabel = translate('ariaColumnGroup', 'Column Group');
        var checkboxValue = this.cbSelect.getValue();
        var state = checkboxValue === undefined ?
            translate('ariaIndeterminate', 'indeterminate') :
            (checkboxValue ? translate('ariaVisible', 'visible') : translate('ariaHidden', 'hidden'));
        var visibilityLabel = translate('ariaToggleVisibility', 'Press SPACE to toggle visibility');
        _.setAriaLabel(this.focusWrapper, "".concat(this.displayName, " ").concat(columnLabel));
        this.cbSelect.setInputAriaLabel("".concat(visibilityLabel, " (").concat(state, ")"));
        _.setAriaDescribedBy(this.focusWrapper, this.cbSelect.getInputElement().id);
    };
    ToolPanelColumnGroupComp.prototype.onColumnStateChanged = function () {
        var selectedValue = this.workOutSelectedValue();
        var readOnlyValue = this.workOutReadOnlyValue();
        this.processingColumnStateChange = true;
        this.cbSelect.setValue(selectedValue);
        this.cbSelect.setReadOnly(readOnlyValue);
        this.addOrRemoveCssClass('ag-column-select-column-group-readonly', readOnlyValue);
        this.processingColumnStateChange = false;
    };
    ToolPanelColumnGroupComp.prototype.workOutSelectedValue = function () {
        var _this = this;
        var pivotMode = this.columnModel.isPivotMode();
        var visibleLeafColumns = this.getVisibleLeafColumns();
        var checkedCount = 0;
        var uncheckedCount = 0;
        visibleLeafColumns.forEach(function (column) {
            if (!pivotMode && column.getColDef().lockVisible) {
                return;
            }
            if (_this.isColumnChecked(column, pivotMode)) {
                checkedCount++;
            }
            else {
                uncheckedCount++;
            }
        });
        if (checkedCount > 0 && uncheckedCount > 0) {
            return undefined;
        }
        return checkedCount > 0;
    };
    ToolPanelColumnGroupComp.prototype.workOutReadOnlyValue = function () {
        var pivotMode = this.columnModel.isPivotMode();
        var colsThatCanAction = 0;
        this.columnGroup.getLeafColumns().forEach(function (col) {
            if (pivotMode) {
                if (col.isAnyFunctionAllowed()) {
                    colsThatCanAction++;
                }
            }
            else {
                if (!col.getColDef().lockVisible) {
                    colsThatCanAction++;
                }
            }
        });
        return colsThatCanAction === 0;
    };
    ToolPanelColumnGroupComp.prototype.isColumnChecked = function (column, pivotMode) {
        if (pivotMode) {
            var pivoted = column.isPivotActive();
            var grouped = column.isRowGroupActive();
            var aggregated = column.isValueActive();
            return pivoted || grouped || aggregated;
        }
        return column.isVisible();
    };
    ToolPanelColumnGroupComp.prototype.onExpandOrContractClicked = function () {
        var oldState = this.modelItem.isExpanded();
        this.modelItem.setExpanded(!oldState);
    };
    ToolPanelColumnGroupComp.prototype.onExpandChanged = function () {
        this.setOpenClosedIcons();
        this.refreshAriaExpanded();
    };
    ToolPanelColumnGroupComp.prototype.setOpenClosedIcons = function () {
        var folderOpen = this.modelItem.isExpanded();
        _.setDisplayed(this.eGroupClosedIcon, !folderOpen);
        _.setDisplayed(this.eGroupOpenedIcon, folderOpen);
    };
    ToolPanelColumnGroupComp.prototype.refreshAriaExpanded = function () {
        _.setAriaExpanded(this.focusWrapper, this.modelItem.isExpanded());
    };
    ToolPanelColumnGroupComp.prototype.getDisplayName = function () {
        return this.displayName;
    };
    ToolPanelColumnGroupComp.prototype.onSelectAllChanged = function (value) {
        var cbValue = this.cbSelect.getValue();
        var readOnly = this.cbSelect.isReadOnly();
        if (!readOnly && ((value && !cbValue) || (!value && cbValue))) {
            this.cbSelect.toggle();
        }
    };
    ToolPanelColumnGroupComp.prototype.isSelected = function () {
        return this.cbSelect.getValue();
    };
    ToolPanelColumnGroupComp.prototype.isSelectable = function () {
        return !this.cbSelect.isReadOnly();
    };
    ToolPanelColumnGroupComp.prototype.setSelected = function (selected) {
        this.cbSelect.setValue(selected, true);
    };
    ToolPanelColumnGroupComp.TEMPLATE = "<div class=\"ag-column-select-column-group\" aria-hidden=\"true\">\n            <span class=\"ag-column-group-icons\" ref=\"eColumnGroupIcons\" >\n                <span class=\"ag-column-group-closed-icon\" ref=\"eGroupClosedIcon\"></span>\n                <span class=\"ag-column-group-opened-icon\" ref=\"eGroupOpenedIcon\"></span>\n            </span>\n            <ag-checkbox ref=\"cbSelect\" class=\"ag-column-select-checkbox\"></ag-checkbox>\n            <span class=\"ag-column-select-column-label\" ref=\"eLabel\"></span>\n        </div>";
    __decorate$1K([
        Autowired('columnModel')
    ], ToolPanelColumnGroupComp.prototype, "columnModel", void 0);
    __decorate$1K([
        Autowired('dragAndDropService')
    ], ToolPanelColumnGroupComp.prototype, "dragAndDropService", void 0);
    __decorate$1K([
        Autowired('modelItemUtils')
    ], ToolPanelColumnGroupComp.prototype, "modelItemUtils", void 0);
    __decorate$1K([
        RefSelector('cbSelect')
    ], ToolPanelColumnGroupComp.prototype, "cbSelect", void 0);
    __decorate$1K([
        RefSelector('eLabel')
    ], ToolPanelColumnGroupComp.prototype, "eLabel", void 0);
    __decorate$1K([
        RefSelector('eGroupOpenedIcon')
    ], ToolPanelColumnGroupComp.prototype, "eGroupOpenedIcon", void 0);
    __decorate$1K([
        RefSelector('eGroupClosedIcon')
    ], ToolPanelColumnGroupComp.prototype, "eGroupClosedIcon", void 0);
    __decorate$1K([
        RefSelector('eColumnGroupIcons')
    ], ToolPanelColumnGroupComp.prototype, "eColumnGroupIcons", void 0);
    __decorate$1K([
        PostConstruct
    ], ToolPanelColumnGroupComp.prototype, "init", null);
    return ToolPanelColumnGroupComp;
}(Component));

var __extends$2e = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1J = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var PrimaryColsListPanelItemDragFeature = /** @class */ (function (_super) {
    __extends$2e(PrimaryColsListPanelItemDragFeature, _super);
    function PrimaryColsListPanelItemDragFeature(comp, virtualList) {
        var _this = _super.call(this) || this;
        _this.comp = comp;
        _this.virtualList = virtualList;
        return _this;
    }
    PrimaryColsListPanelItemDragFeature.prototype.postConstruct = function () {
        var _this = this;
        this.createManagedBean(new VirtualListDragFeature(this.comp, this.virtualList, {
            dragSourceType: DragSourceType.ToolPanel,
            listItemDragStartEvent: Events.EVENT_COLUMN_PANEL_ITEM_DRAG_START,
            listItemDragEndEvent: Events.EVENT_COLUMN_PANEL_ITEM_DRAG_END,
            eventSource: this.eventService,
            getCurrentDragValue: function (listItemDragStartEvent) { return _this.getCurrentDragValue(listItemDragStartEvent); },
            isMoveBlocked: function (currentDragValue) { return _this.isMoveBlocked(currentDragValue); },
            getNumRows: function (comp) { return comp.getDisplayedColsList().length; },
            moveItem: function (currentDragValue, lastHoveredListItem) { return _this.moveItem(currentDragValue, lastHoveredListItem); }
        }));
    };
    PrimaryColsListPanelItemDragFeature.prototype.getCurrentDragValue = function (listItemDragStartEvent) {
        return listItemDragStartEvent.column;
    };
    PrimaryColsListPanelItemDragFeature.prototype.isMoveBlocked = function (currentDragValue) {
        var preventMoving = this.gridOptionsService.get('suppressMovableColumns');
        if (preventMoving) {
            return true;
        }
        var currentColumns = this.getCurrentColumns(currentDragValue);
        var hasNotMovable = currentColumns.find(function (col) {
            var colDef = col.getColDef();
            return !!colDef.suppressMovable || !!colDef.lockPosition;
        });
        return !!hasNotMovable;
    };
    PrimaryColsListPanelItemDragFeature.prototype.moveItem = function (currentDragValue, lastHoveredListItem) {
        var targetIndex = this.getTargetIndex(currentDragValue, lastHoveredListItem);
        var columnsToMove = this.getCurrentColumns(currentDragValue);
        if (targetIndex != null) {
            this.columnModel.moveColumns(columnsToMove, targetIndex, 'toolPanelUi');
        }
    };
    PrimaryColsListPanelItemDragFeature.prototype.getMoveDiff = function (currentDragValue, end) {
        var allColumns = this.columnModel.getAllGridColumns();
        var currentColumns = this.getCurrentColumns(currentDragValue);
        var currentColumn = currentColumns[0];
        var span = currentColumns.length;
        var currentIndex = allColumns.indexOf(currentColumn);
        if (currentIndex < end) {
            return span;
        }
        return 0;
    };
    PrimaryColsListPanelItemDragFeature.prototype.getCurrentColumns = function (currentDragValue) {
        if (currentDragValue instanceof ProvidedColumnGroup) {
            return currentDragValue.getLeafColumns();
        }
        return [currentDragValue];
    };
    PrimaryColsListPanelItemDragFeature.prototype.getTargetIndex = function (currentDragValue, lastHoveredListItem) {
        if (!lastHoveredListItem) {
            return null;
        }
        var columnItemComponent = lastHoveredListItem.component;
        var isBefore = lastHoveredListItem.position === 'top';
        var targetColumn;
        if (columnItemComponent instanceof ToolPanelColumnGroupComp) {
            var columns = columnItemComponent.getColumns();
            targetColumn = columns[0];
            isBefore = true;
        }
        else {
            targetColumn = columnItemComponent.getColumn();
        }
        // if the target col is in the cols to be moved, no index to move.
        var movingCols = this.getCurrentColumns(currentDragValue);
        if (movingCols.indexOf(targetColumn) !== -1) {
            return null;
        }
        var targetColumnIndex = this.columnModel.getAllGridColumns().indexOf(targetColumn);
        var adjustedTarget = isBefore ? targetColumnIndex : targetColumnIndex + 1;
        var diff = this.getMoveDiff(currentDragValue, adjustedTarget);
        return adjustedTarget - diff;
    };
    __decorate$1J([
        Autowired('columnModel')
    ], PrimaryColsListPanelItemDragFeature.prototype, "columnModel", void 0);
    __decorate$1J([
        Autowired('gridOptionsService')
    ], PrimaryColsListPanelItemDragFeature.prototype, "gridOptionsService", void 0);
    __decorate$1J([
        PostConstruct
    ], PrimaryColsListPanelItemDragFeature.prototype, "postConstruct", null);
    return PrimaryColsListPanelItemDragFeature;
}(BeanStub));

var __extends$2d = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1I = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ToolPanelColumnComp = /** @class */ (function (_super) {
    __extends$2d(ToolPanelColumnComp, _super);
    function ToolPanelColumnComp(modelItem, allowDragging, groupsExist, focusWrapper) {
        var _this = _super.call(this) || this;
        _this.allowDragging = allowDragging;
        _this.groupsExist = groupsExist;
        _this.focusWrapper = focusWrapper;
        _this.processingColumnStateChange = false;
        _this.column = modelItem.getColumn();
        _this.columnDept = modelItem.getDept();
        _this.displayName = modelItem.getDisplayName();
        return _this;
    }
    ToolPanelColumnComp.prototype.init = function () {
        var _this = this;
        this.setTemplate(ToolPanelColumnComp.TEMPLATE);
        this.eDragHandle = _.createIconNoSpan('columnDrag', this.gridOptionsService);
        this.eDragHandle.classList.add('ag-drag-handle', 'ag-column-select-column-drag-handle');
        var checkboxGui = this.cbSelect.getGui();
        var checkboxInput = this.cbSelect.getInputElement();
        checkboxGui.insertAdjacentElement('afterend', this.eDragHandle);
        checkboxInput.setAttribute('tabindex', '-1');
        var displayNameSanitised = _.escapeString(this.displayName);
        this.eLabel.innerHTML = displayNameSanitised;
        // if grouping, we add an extra level of indent, to cater for expand/contract icons we need to indent for
        var indent = this.columnDept;
        if (this.groupsExist) {
            this.addCssClass('ag-column-select-add-group-indent');
        }
        this.addCssClass("ag-column-select-indent-".concat(indent));
        this.setupDragging();
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_PIVOT_MODE_CHANGED, this.onColumnStateChanged.bind(this));
        this.addManagedListener(this.column, Column.EVENT_VALUE_CHANGED, this.onColumnStateChanged.bind(this));
        this.addManagedListener(this.column, Column.EVENT_PIVOT_CHANGED, this.onColumnStateChanged.bind(this));
        this.addManagedListener(this.column, Column.EVENT_ROW_GROUP_CHANGED, this.onColumnStateChanged.bind(this));
        this.addManagedListener(this.column, Column.EVENT_VISIBLE_CHANGED, this.onColumnStateChanged.bind(this));
        this.addManagedListener(this.focusWrapper, 'keydown', this.handleKeyDown.bind(this));
        this.addManagedListener(this.focusWrapper, 'contextmenu', this.onContextMenu.bind(this));
        this.addManagedPropertyListener('functionsReadOnly', this.onColumnStateChanged.bind(this));
        this.addManagedListener(this.cbSelect, Events.EVENT_FIELD_VALUE_CHANGED, this.onCheckboxChanged.bind(this));
        this.addManagedListener(this.eLabel, 'click', this.onLabelClicked.bind(this));
        this.onColumnStateChanged();
        this.refreshAriaLabel();
        this.setupTooltip();
        var classes = CssClassApplier.getToolPanelClassesFromColDef(this.column.getColDef(), this.gridOptionsService, this.column, null);
        classes.forEach(function (c) { return _this.addOrRemoveCssClass(c, true); });
    };
    ToolPanelColumnComp.prototype.getColumn = function () {
        return this.column;
    };
    ToolPanelColumnComp.prototype.setupTooltip = function () {
        var _this = this;
        var refresh = function () {
            var newTooltipText = _this.column.getColDef().headerTooltip;
            _this.setTooltip(newTooltipText);
        };
        refresh();
        this.addManagedListener(this.eventService, Events.EVENT_NEW_COLUMNS_LOADED, refresh);
    };
    ToolPanelColumnComp.prototype.getTooltipParams = function () {
        var res = _super.prototype.getTooltipParams.call(this);
        res.location = 'columnToolPanelColumn';
        res.colDef = this.column.getColDef();
        return res;
    };
    ToolPanelColumnComp.prototype.onContextMenu = function (e) {
        var _this = this;
        var _a = this, column = _a.column, gridOptionsService = _a.gridOptionsService;
        if (gridOptionsService.get('functionsReadOnly')) {
            return;
        }
        var contextMenu = this.createBean(new ToolPanelContextMenu(column, e, this.focusWrapper));
        this.addDestroyFunc(function () {
            if (contextMenu.isAlive()) {
                _this.destroyBean(contextMenu);
            }
        });
    };
    ToolPanelColumnComp.prototype.handleKeyDown = function (e) {
        if (e.key === KeyCode.SPACE) {
            e.preventDefault();
            if (this.isSelectable()) {
                this.onSelectAllChanged(!this.isSelected());
            }
        }
    };
    ToolPanelColumnComp.prototype.onLabelClicked = function () {
        if (this.gridOptionsService.get('functionsReadOnly')) {
            return;
        }
        var nextState = !this.cbSelect.getValue();
        this.onChangeCommon(nextState);
    };
    ToolPanelColumnComp.prototype.onCheckboxChanged = function (event) {
        this.onChangeCommon(event.selected);
    };
    ToolPanelColumnComp.prototype.onChangeCommon = function (nextState) {
        // ignore lock visible columns
        if (this.cbSelect.isReadOnly()) {
            return;
        }
        this.refreshAriaLabel();
        // only want to action if the user clicked the checkbox, not if we are setting the checkbox because
        // of a change in the model
        if (this.processingColumnStateChange) {
            return;
        }
        this.modelItemUtils.setColumn(this.column, nextState, 'toolPanelUi');
    };
    ToolPanelColumnComp.prototype.refreshAriaLabel = function () {
        var translate = this.localeService.getLocaleTextFunc();
        var columnLabel = translate('ariaColumn', 'Column');
        var state = this.cbSelect.getValue() ? translate('ariaVisible', 'visible') : translate('ariaHidden', 'hidden');
        var visibilityLabel = translate('ariaToggleVisibility', 'Press SPACE to toggle visibility');
        _.setAriaLabel(this.focusWrapper, "".concat(this.displayName, " ").concat(columnLabel));
        this.cbSelect.setInputAriaLabel("".concat(visibilityLabel, " (").concat(state, ")"));
        _.setAriaDescribedBy(this.focusWrapper, this.cbSelect.getInputElement().id);
    };
    ToolPanelColumnComp.prototype.setupDragging = function () {
        var _this = this;
        if (!this.allowDragging) {
            _.setDisplayed(this.eDragHandle, false);
            return;
        }
        var hideColumnOnExit = !this.gridOptionsService.get('suppressDragLeaveHidesColumns');
        var dragSource = {
            type: DragSourceType.ToolPanel,
            eElement: this.eDragHandle,
            dragItemName: this.displayName,
            getDefaultIconName: function () { return hideColumnOnExit ? DragAndDropService.ICON_HIDE : DragAndDropService.ICON_NOT_ALLOWED; },
            getDragItem: function () { return _this.createDragItem(); },
            onDragStarted: function () {
                hideColumnOnExit = !_this.gridOptionsService.get('suppressDragLeaveHidesColumns');
                var event = {
                    type: Events.EVENT_COLUMN_PANEL_ITEM_DRAG_START,
                    column: _this.column
                };
                _this.eventService.dispatchEvent(event);
            },
            onDragStopped: function () {
                var event = {
                    type: Events.EVENT_COLUMN_PANEL_ITEM_DRAG_END
                };
                _this.eventService.dispatchEvent(event);
            },
            onGridEnter: function (dragItem) {
                if (hideColumnOnExit) {
                    // when dragged into the grid, restore the state that was active pre-drag
                    _this.modelItemUtils.updateColumns({
                        columns: [_this.column],
                        visibleState: dragItem === null || dragItem === void 0 ? void 0 : dragItem.visibleState,
                        pivotState: dragItem === null || dragItem === void 0 ? void 0 : dragItem.pivotState,
                        eventType: 'toolPanelUi'
                    });
                }
            },
            onGridExit: function () {
                if (hideColumnOnExit) {
                    // when dragged outside of the grid, mimic what happens when checkbox is disabled
                    // this handles the behaviour for pivot which is different to just hiding a column.
                    _this.onChangeCommon(false);
                }
            }
        };
        this.dragAndDropService.addDragSource(dragSource, true);
        this.addDestroyFunc(function () { return _this.dragAndDropService.removeDragSource(dragSource); });
    };
    ToolPanelColumnComp.prototype.createDragItem = function () {
        var _a, _b;
        var colId = this.column.getColId();
        var visibleState = (_a = {}, _a[colId] = this.column.isVisible(), _a);
        var pivotState = (_b = {}, _b[colId] = this.modelItemUtils.createPivotState(this.column), _b);
        return {
            columns: [this.column],
            visibleState: visibleState,
            pivotState: pivotState
        };
    };
    ToolPanelColumnComp.prototype.onColumnStateChanged = function () {
        this.processingColumnStateChange = true;
        var isPivotMode = this.columnModel.isPivotMode();
        if (isPivotMode) {
            // if reducing, checkbox means column is one of pivot, value or group
            var anyFunctionActive = this.column.isAnyFunctionActive();
            this.cbSelect.setValue(anyFunctionActive);
        }
        else {
            // if not reducing, the checkbox tells us if column is visible or not
            this.cbSelect.setValue(this.column.isVisible());
        }
        var canBeToggled = true;
        var canBeDragged = true;
        if (isPivotMode) {
            // when in pivot mode, the item should be read only if:
            //  a) gui is not allowed make any changes
            var functionsReadOnly = this.gridOptionsService.get('functionsReadOnly');
            //  b) column is not allow any functions on it
            var noFunctionsAllowed = !this.column.isAnyFunctionAllowed();
            canBeToggled = !functionsReadOnly && !noFunctionsAllowed;
            canBeDragged = canBeToggled;
        }
        else {
            var _a = this.column.getColDef(), enableRowGroup = _a.enableRowGroup, enableValue = _a.enableValue, lockPosition = _a.lockPosition, suppressMovable = _a.suppressMovable, lockVisible = _a.lockVisible;
            var forceDraggable = !!enableRowGroup || !!enableValue;
            var disableDraggable = !!lockPosition || !!suppressMovable;
            canBeToggled = !lockVisible;
            canBeDragged = forceDraggable || !disableDraggable;
        }
        this.cbSelect.setReadOnly(!canBeToggled);
        this.eDragHandle.classList.toggle('ag-column-select-column-readonly', !canBeDragged);
        this.addOrRemoveCssClass('ag-column-select-column-readonly', !canBeDragged && !canBeToggled);
        var checkboxPassive = isPivotMode && this.gridOptionsService.get('functionsPassive');
        this.cbSelect.setPassive(checkboxPassive);
        this.processingColumnStateChange = false;
    };
    ToolPanelColumnComp.prototype.getDisplayName = function () {
        return this.displayName;
    };
    ToolPanelColumnComp.prototype.onSelectAllChanged = function (value) {
        if (value !== this.cbSelect.getValue()) {
            if (!this.cbSelect.isReadOnly()) {
                this.cbSelect.toggle();
            }
        }
    };
    ToolPanelColumnComp.prototype.isSelected = function () {
        return this.cbSelect.getValue();
    };
    ToolPanelColumnComp.prototype.isSelectable = function () {
        return !this.cbSelect.isReadOnly();
    };
    ToolPanelColumnComp.prototype.isExpandable = function () {
        return false;
    };
    ToolPanelColumnComp.prototype.setExpanded = function (value) {
        console.warn('AG Grid: can not expand a column item that does not represent a column group header');
    };
    ToolPanelColumnComp.TEMPLATE = "<div class=\"ag-column-select-column\" aria-hidden=\"true\">\n            <ag-checkbox ref=\"cbSelect\" class=\"ag-column-select-checkbox\"></ag-checkbox>\n            <span class=\"ag-column-select-column-label\" ref=\"eLabel\"></span>\n        </div>";
    __decorate$1I([
        Autowired('columnModel')
    ], ToolPanelColumnComp.prototype, "columnModel", void 0);
    __decorate$1I([
        Autowired('dragAndDropService')
    ], ToolPanelColumnComp.prototype, "dragAndDropService", void 0);
    __decorate$1I([
        Autowired('modelItemUtils')
    ], ToolPanelColumnComp.prototype, "modelItemUtils", void 0);
    __decorate$1I([
        RefSelector('eLabel')
    ], ToolPanelColumnComp.prototype, "eLabel", void 0);
    __decorate$1I([
        RefSelector('cbSelect')
    ], ToolPanelColumnComp.prototype, "cbSelect", void 0);
    __decorate$1I([
        PostConstruct
    ], ToolPanelColumnComp.prototype, "init", null);
    return ToolPanelColumnComp;
}(Component));

var __extends$2c = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1H = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var UIColumnModel = /** @class */ (function () {
    function UIColumnModel(items) {
        this.items = items;
    }
    UIColumnModel.prototype.getRowCount = function () {
        return this.items.length;
    };
    UIColumnModel.prototype.getRow = function (index) {
        return this.items[index];
    };
    return UIColumnModel;
}());
var PRIMARY_COLS_LIST_PANEL_CLASS = 'ag-column-select-list';
var PrimaryColsListPanel = /** @class */ (function (_super) {
    __extends$2c(PrimaryColsListPanel, _super);
    function PrimaryColsListPanel() {
        var _this = _super.call(this, PrimaryColsListPanel.TEMPLATE) || this;
        _this.destroyColumnItemFuncs = [];
        _this.hasLoadedInitialState = false;
        _this.isInitialState = false;
        return _this;
    }
    PrimaryColsListPanel.prototype.destroyColumnTree = function () {
        this.allColsTree = [];
        this.destroyColumnItemFuncs.forEach(function (f) { return f(); });
        this.destroyColumnItemFuncs = [];
    };
    PrimaryColsListPanel.prototype.init = function (params, allowDragging, eventType) {
        var _this = this;
        this.params = params;
        this.allowDragging = allowDragging;
        this.eventType = eventType;
        if (!this.params.suppressSyncLayoutWithGrid) {
            this.addManagedListener(this.eventService, Events.EVENT_COLUMN_MOVED, this.onColumnsChanged.bind(this));
        }
        this.addManagedListener(this.eventService, Events.EVENT_NEW_COLUMNS_LOADED, this.onColumnsChanged.bind(this));
        var eventsImpactingCheckedState = [
            Events.EVENT_COLUMN_PIVOT_CHANGED,
            Events.EVENT_COLUMN_PIVOT_MODE_CHANGED,
            Events.EVENT_COLUMN_ROW_GROUP_CHANGED,
            Events.EVENT_COLUMN_VALUE_CHANGED,
            Events.EVENT_COLUMN_VISIBLE,
            Events.EVENT_NEW_COLUMNS_LOADED
        ];
        eventsImpactingCheckedState.forEach(function (event) {
            // update header select all checkbox with current selection state
            _this.addManagedListener(_this.eventService, event, _this.fireSelectionChangedEvent.bind(_this));
        });
        this.expandGroupsByDefault = !this.params.contractColumnSelection;
        this.virtualList = this.createManagedBean(new VirtualList({
            cssIdentifier: 'column-select',
            ariaRole: 'tree',
        }));
        this.appendChild(this.virtualList.getGui());
        var ariaEl = this.virtualList.getAriaElement();
        _.setAriaLive(ariaEl, 'assertive');
        _.setAriaAtomic(ariaEl, false);
        _.setAriaRelevant(ariaEl, 'text');
        this.virtualList.setComponentCreator(function (item, listItemElement) {
            _.setAriaLevel(listItemElement, (item.getDept() + 1));
            return _this.createComponentFromItem(item, listItemElement);
        });
        if (this.columnModel.isReady()) {
            this.onColumnsChanged();
        }
        if (this.params.suppressColumnMove) {
            return;
        }
        this.colsListPanelItemDragFeature = this.createManagedBean(new PrimaryColsListPanelItemDragFeature(this, this.virtualList));
    };
    PrimaryColsListPanel.prototype.createComponentFromItem = function (item, listItemElement) {
        if (item.isGroup()) {
            var renderedGroup = new ToolPanelColumnGroupComp(item, this.allowDragging, this.eventType, listItemElement);
            this.getContext().createBean(renderedGroup);
            return renderedGroup;
        }
        var columnComp = new ToolPanelColumnComp(item, this.allowDragging, this.groupsExist, listItemElement);
        this.getContext().createBean(columnComp);
        return columnComp;
    };
    PrimaryColsListPanel.prototype.onColumnsChanged = function () {
        if (!this.hasLoadedInitialState) {
            this.hasLoadedInitialState = true;
            this.isInitialState = !!this.params.initialState;
        }
        var expandedStates = this.getExpandedStates();
        var pivotModeActive = this.columnModel.isPivotMode();
        var shouldSyncColumnLayoutWithGrid = !this.params.suppressSyncLayoutWithGrid && !pivotModeActive;
        if (shouldSyncColumnLayoutWithGrid) {
            this.buildTreeFromWhatGridIsDisplaying();
        }
        else {
            this.buildTreeFromProvidedColumnDefs();
        }
        this.setExpandedStates(expandedStates);
        this.markFilteredColumns();
        this.flattenAndFilterModel();
        this.isInitialState = false;
    };
    PrimaryColsListPanel.prototype.getDisplayedColsList = function () {
        return this.displayedColsList;
    };
    PrimaryColsListPanel.prototype.getExpandedStates = function () {
        var res = {};
        if (this.isInitialState) {
            var expandedGroupIds = this.params.initialState.expandedGroupIds;
            expandedGroupIds.forEach(function (id) {
                res[id] = true;
            });
            return res;
        }
        if (!this.allColsTree) {
            return {};
        }
        this.forEachItem(function (item) {
            if (!item.isGroup()) {
                return;
            }
            var colGroup = item.getColumnGroup();
            if (colGroup) { // group should always exist, this is defensive
                res[colGroup.getId()] = item.isExpanded();
            }
        });
        return res;
    };
    PrimaryColsListPanel.prototype.setExpandedStates = function (states) {
        if (!this.allColsTree) {
            return;
        }
        var isInitialState = this.isInitialState;
        this.forEachItem(function (item) {
            if (!item.isGroup()) {
                return;
            }
            var colGroup = item.getColumnGroup();
            if (colGroup) { // group should always exist, this is defensive
                var expanded = states[colGroup.getId()];
                var groupExistedLastTime = expanded != null;
                if (groupExistedLastTime || isInitialState) {
                    item.setExpanded(!!expanded);
                }
            }
        });
    };
    PrimaryColsListPanel.prototype.buildTreeFromWhatGridIsDisplaying = function () {
        this.colDefService.syncLayoutWithGrid(this.setColumnLayout.bind(this));
    };
    PrimaryColsListPanel.prototype.setColumnLayout = function (colDefs) {
        var columnTree = this.colDefService.createColumnTree(colDefs);
        this.buildListModel(columnTree);
        // using col defs to check if groups exist as it could be a custom layout
        this.groupsExist = colDefs.some(function (colDef) {
            return colDef && typeof colDef.children !== 'undefined';
        });
        this.markFilteredColumns();
        this.flattenAndFilterModel();
    };
    PrimaryColsListPanel.prototype.buildTreeFromProvidedColumnDefs = function () {
        // add column / group comps to tool panel
        this.buildListModel(this.columnModel.getPrimaryColumnTree());
        this.groupsExist = this.columnModel.isPrimaryColumnGroupsPresent();
    };
    PrimaryColsListPanel.prototype.buildListModel = function (columnTree) {
        var _this = this;
        var columnExpandedListener = this.onColumnExpanded.bind(this);
        var addListeners = function (item) {
            item.addEventListener(ColumnModelItem.EVENT_EXPANDED_CHANGED, columnExpandedListener);
            var removeFunc = item.removeEventListener.bind(item, ColumnModelItem.EVENT_EXPANDED_CHANGED, columnExpandedListener);
            _this.destroyColumnItemFuncs.push(removeFunc);
        };
        var recursivelyBuild = function (tree, dept, parentList) {
            tree.forEach(function (child) {
                if (child instanceof ProvidedColumnGroup) {
                    createGroupItem(child, dept, parentList);
                }
                else {
                    createColumnItem(child, dept, parentList);
                }
            });
        };
        var createGroupItem = function (columnGroup, dept, parentList) {
            var columnGroupDef = columnGroup.getColGroupDef();
            var skipThisGroup = columnGroupDef && columnGroupDef.suppressColumnsToolPanel;
            if (skipThisGroup) {
                return;
            }
            if (columnGroup.isPadding()) {
                recursivelyBuild(columnGroup.getChildren(), dept, parentList);
                return;
            }
            var displayName = _this.columnModel.getDisplayNameForProvidedColumnGroup(null, columnGroup, 'columnToolPanel');
            var item = new ColumnModelItem(displayName, columnGroup, dept, true, _this.expandGroupsByDefault);
            parentList.push(item);
            addListeners(item);
            recursivelyBuild(columnGroup.getChildren(), dept + 1, item.getChildren());
        };
        var createColumnItem = function (column, dept, parentList) {
            var skipThisColumn = column.getColDef() && column.getColDef().suppressColumnsToolPanel;
            if (skipThisColumn) {
                return;
            }
            var displayName = _this.columnModel.getDisplayNameForColumn(column, 'columnToolPanel');
            parentList.push(new ColumnModelItem(displayName, column, dept));
        };
        this.destroyColumnTree();
        recursivelyBuild(columnTree, 0, this.allColsTree);
    };
    PrimaryColsListPanel.prototype.onColumnExpanded = function () {
        this.flattenAndFilterModel();
    };
    PrimaryColsListPanel.prototype.flattenAndFilterModel = function () {
        var _this = this;
        this.displayedColsList = [];
        var recursiveFunc = function (item) {
            if (!item.isPassesFilter()) {
                return;
            }
            _this.displayedColsList.push(item);
            if (item.isGroup() && item.isExpanded()) {
                item.getChildren().forEach(recursiveFunc);
            }
        };
        this.allColsTree.forEach(recursiveFunc);
        this.virtualList.setModel(new UIColumnModel(this.displayedColsList));
        var focusedRow = this.virtualList.getLastFocusedRow();
        this.virtualList.refresh();
        if (focusedRow != null) {
            this.focusRowIfAlive(focusedRow);
        }
        this.notifyListeners();
        this.refreshAriaLabel();
    };
    PrimaryColsListPanel.prototype.refreshAriaLabel = function () {
        var translate = this.localeService.getLocaleTextFunc();
        var columnListName = translate('ariaColumnPanelList', 'Column List');
        var localeColumns = translate('columns', 'Columns');
        var items = this.displayedColsList.length;
        _.setAriaLabel(this.virtualList.getAriaElement(), "".concat(columnListName, " ").concat(items, " ").concat(localeColumns));
    };
    PrimaryColsListPanel.prototype.focusRowIfAlive = function (rowIndex) {
        var _this = this;
        window.setTimeout(function () {
            if (_this.isAlive()) {
                _this.virtualList.focusRow(rowIndex);
            }
        }, 0);
    };
    PrimaryColsListPanel.prototype.forEachItem = function (callback) {
        var recursiveFunc = function (items) {
            items.forEach(function (item) {
                callback(item);
                if (item.isGroup()) {
                    recursiveFunc(item.getChildren());
                }
            });
        };
        recursiveFunc(this.allColsTree);
    };
    PrimaryColsListPanel.prototype.doSetExpandedAll = function (value) {
        this.forEachItem(function (item) {
            if (item.isGroup()) {
                item.setExpanded(value);
            }
        });
    };
    PrimaryColsListPanel.prototype.setGroupsExpanded = function (expand, groupIds) {
        if (!groupIds) {
            this.doSetExpandedAll(expand);
            return;
        }
        var expandedGroupIds = [];
        this.forEachItem(function (item) {
            if (!item.isGroup()) {
                return;
            }
            var groupId = item.getColumnGroup().getId();
            if (groupIds.indexOf(groupId) >= 0) {
                item.setExpanded(expand);
                expandedGroupIds.push(groupId);
            }
        });
        var unrecognisedGroupIds = groupIds.filter(function (groupId) { return !_.includes(expandedGroupIds, groupId); });
        if (unrecognisedGroupIds.length > 0) {
            console.warn('AG Grid: unable to find group(s) for supplied groupIds:', unrecognisedGroupIds);
        }
    };
    PrimaryColsListPanel.prototype.getExpandState = function () {
        var expandedCount = 0;
        var notExpandedCount = 0;
        this.forEachItem(function (item) {
            if (!item.isGroup()) {
                return;
            }
            if (item.isExpanded()) {
                expandedCount++;
            }
            else {
                notExpandedCount++;
            }
        });
        if (expandedCount > 0 && notExpandedCount > 0) {
            return ExpandState.INDETERMINATE;
        }
        if (notExpandedCount > 0) {
            return ExpandState.COLLAPSED;
        }
        return ExpandState.EXPANDED;
    };
    PrimaryColsListPanel.prototype.doSetSelectedAll = function (selectAllChecked) {
        this.modelItemUtils.selectAllChildren(this.allColsTree, selectAllChecked, this.eventType);
    };
    PrimaryColsListPanel.prototype.getSelectionState = function () {
        var checkedCount = 0;
        var uncheckedCount = 0;
        var pivotMode = this.columnModel.isPivotMode();
        this.forEachItem(function (item) {
            if (item.isGroup()) {
                return;
            }
            if (!item.isPassesFilter()) {
                return;
            }
            var column = item.getColumn();
            var colDef = column.getColDef();
            var checked;
            if (pivotMode) {
                var noPivotModeOptionsAllowed = !column.isAllowPivot() && !column.isAllowRowGroup() && !column.isAllowValue();
                if (noPivotModeOptionsAllowed) {
                    return;
                }
                checked = column.isValueActive() || column.isPivotActive() || column.isRowGroupActive();
            }
            else {
                if (colDef.lockVisible) {
                    return;
                }
                checked = column.isVisible();
            }
            checked ? checkedCount++ : uncheckedCount++;
        });
        if (checkedCount > 0 && uncheckedCount > 0) {
            return undefined;
        }
        return !(checkedCount === 0 || uncheckedCount > 0);
    };
    PrimaryColsListPanel.prototype.setFilterText = function (filterText) {
        this.filterText = _.exists(filterText) ? filterText.toLowerCase() : null;
        this.markFilteredColumns();
        this.flattenAndFilterModel();
    };
    PrimaryColsListPanel.prototype.markFilteredColumns = function () {
        var _this = this;
        var passesFilter = function (item) {
            if (!_.exists(_this.filterText)) {
                return true;
            }
            var displayName = item.getDisplayName();
            return displayName == null || displayName.toLowerCase().indexOf(_this.filterText) !== -1;
        };
        var recursivelyCheckFilter = function (item, parentPasses) {
            var atLeastOneChildPassed = false;
            if (item.isGroup()) {
                var groupPasses_1 = passesFilter(item);
                item.getChildren().forEach(function (child) {
                    var childPasses = recursivelyCheckFilter(child, groupPasses_1 || parentPasses);
                    if (childPasses) {
                        atLeastOneChildPassed = childPasses;
                    }
                });
            }
            var filterPasses = (parentPasses || atLeastOneChildPassed) ? true : passesFilter(item);
            item.setPassesFilter(filterPasses);
            return filterPasses;
        };
        this.allColsTree.forEach(function (item) { return recursivelyCheckFilter(item, false); });
    };
    PrimaryColsListPanel.prototype.notifyListeners = function () {
        this.fireGroupExpandedEvent();
        this.fireSelectionChangedEvent();
    };
    PrimaryColsListPanel.prototype.fireGroupExpandedEvent = function () {
        var expandState = this.getExpandState();
        this.dispatchEvent({ type: 'groupExpanded', state: expandState });
    };
    PrimaryColsListPanel.prototype.fireSelectionChangedEvent = function () {
        if (!this.allColsTree) {
            return;
        }
        var selectionState = this.getSelectionState();
        this.dispatchEvent({ type: 'selectionChanged', state: selectionState });
    };
    PrimaryColsListPanel.prototype.getExpandedGroups = function () {
        var expandedGroupIds = [];
        if (!this.allColsTree) {
            return expandedGroupIds;
        }
        this.forEachItem(function (item) {
            if (item.isGroup() && item.isExpanded()) {
                expandedGroupIds.push(item.getColumnGroup().getId());
            }
        });
        return expandedGroupIds;
    };
    PrimaryColsListPanel.TEMPLATE = "<div class=\"".concat(PRIMARY_COLS_LIST_PANEL_CLASS, "\" role=\"presentation\"></div>");
    __decorate$1H([
        Autowired('columnModel')
    ], PrimaryColsListPanel.prototype, "columnModel", void 0);
    __decorate$1H([
        Autowired('toolPanelColDefService')
    ], PrimaryColsListPanel.prototype, "colDefService", void 0);
    __decorate$1H([
        Autowired('modelItemUtils')
    ], PrimaryColsListPanel.prototype, "modelItemUtils", void 0);
    __decorate$1H([
        PreDestroy
    ], PrimaryColsListPanel.prototype, "destroyColumnTree", null);
    return PrimaryColsListPanel;
}(Component));

var __extends$2b = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1G = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var PivotModePanel = /** @class */ (function (_super) {
    __extends$2b(PivotModePanel, _super);
    function PivotModePanel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PivotModePanel.prototype.createTemplate = function () {
        return /* html */ "<div class=\"ag-pivot-mode-panel\">\n                <ag-toggle-button ref=\"cbPivotMode\" class=\"ag-pivot-mode-select\"></ag-toggle-button>\n            </div>";
    };
    PivotModePanel.prototype.init = function () {
        this.setTemplate(this.createTemplate());
        this.cbPivotMode.setValue(this.columnModel.isPivotMode());
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        this.cbPivotMode.setLabel(localeTextFunc('pivotMode', 'Pivot Mode'));
        this.addManagedListener(this.cbPivotMode, Events.EVENT_FIELD_VALUE_CHANGED, this.onBtPivotMode.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_NEW_COLUMNS_LOADED, this.onPivotModeChanged.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_PIVOT_MODE_CHANGED, this.onPivotModeChanged.bind(this));
    };
    PivotModePanel.prototype.onBtPivotMode = function () {
        var newValue = !!this.cbPivotMode.getValue();
        if (newValue !== this.columnModel.isPivotMode()) {
            this.gridOptionsService.updateGridOptions({ options: { pivotMode: newValue }, source: 'toolPanelUi' });
            var api = this.api;
            if (api) {
                api.refreshHeader();
            }
        }
    };
    PivotModePanel.prototype.onPivotModeChanged = function () {
        var pivotModeActive = this.columnModel.isPivotMode();
        this.cbPivotMode.setValue(pivotModeActive);
    };
    __decorate$1G([
        Autowired('columnModel')
    ], PivotModePanel.prototype, "columnModel", void 0);
    __decorate$1G([
        Autowired('gridApi')
    ], PivotModePanel.prototype, "api", void 0);
    __decorate$1G([
        RefSelector('cbPivotMode')
    ], PivotModePanel.prototype, "cbPivotMode", void 0);
    __decorate$1G([
        PreConstruct
    ], PivotModePanel.prototype, "init", null);
    return PivotModePanel;
}(Component));

var __extends$2a = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1F = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AggregationStage = /** @class */ (function (_super) {
    __extends$2a(AggregationStage, _super);
    function AggregationStage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // it's possible to recompute the aggregate without doing the other parts
    // + api.refreshClientSideRowModel('aggregate')
    AggregationStage.prototype.execute = function (params) {
        // if changed path is active, it means we came from a) change detection or b) transaction update.
        // for both of these, if no value columns are present, it means there is nothing to aggregate now
        // and there is no cleanup to be done (as value columns don't change between transactions or change
        // detections). if no value columns and no changed path, means we have to go through all nodes in
        // case we need to clean up agg data from before.
        var noValueColumns = _.missingOrEmpty(this.columnModel.getValueColumns());
        var noUserAgg = !this.gridOptionsService.getCallback('getGroupRowAgg');
        var changedPathActive = params.changedPath && params.changedPath.isActive();
        if (noValueColumns && noUserAgg && changedPathActive) {
            return;
        }
        var aggDetails = this.createAggDetails(params);
        this.recursivelyCreateAggData(aggDetails);
    };
    AggregationStage.prototype.createAggDetails = function (params) {
        var pivotActive = this.columnModel.isPivotActive();
        var measureColumns = this.columnModel.getValueColumns();
        var pivotColumns = pivotActive ? this.columnModel.getPivotColumns() : [];
        var aggDetails = {
            alwaysAggregateAtRootLevel: this.gridOptionsService.get('alwaysAggregateAtRootLevel'),
            groupIncludeTotalFooter: this.gridOptionsService.get('groupIncludeTotalFooter'),
            changedPath: params.changedPath,
            valueColumns: measureColumns,
            pivotColumns: pivotColumns,
            filteredOnly: !this.isSuppressAggFilteredOnly(),
            userAggFunc: this.gridOptionsService.getCallback('getGroupRowAgg'),
        };
        return aggDetails;
    };
    AggregationStage.prototype.isSuppressAggFilteredOnly = function () {
        var isGroupAggFiltering = this.gridOptionsService.getGroupAggFiltering() !== undefined;
        return isGroupAggFiltering || this.gridOptionsService.get('suppressAggFilteredOnly');
    };
    AggregationStage.prototype.recursivelyCreateAggData = function (aggDetails) {
        var _this = this;
        var callback = function (rowNode) {
            var hasNoChildren = !rowNode.hasChildren();
            if (hasNoChildren) {
                // this check is needed for TreeData, in case the node is no longer a child,
                // but it was a child previously.
                if (rowNode.aggData) {
                    rowNode.setAggData(null);
                }
                // never agg data for leaf nodes
                return;
            }
            //Optionally enable the aggregation at the root Node
            var isRootNode = rowNode.level === -1;
            // if total footer is displayed, the value is in use
            if (isRootNode && !aggDetails.groupIncludeTotalFooter) {
                var notPivoting = !_this.columnModel.isPivotMode();
                if (!aggDetails.alwaysAggregateAtRootLevel && notPivoting) {
                    rowNode.setAggData(null);
                    return;
                }
            }
            _this.aggregateRowNode(rowNode, aggDetails);
        };
        aggDetails.changedPath.forEachChangedNodeDepthFirst(callback, true);
    };
    AggregationStage.prototype.aggregateRowNode = function (rowNode, aggDetails) {
        var measureColumnsMissing = aggDetails.valueColumns.length === 0;
        var pivotColumnsMissing = aggDetails.pivotColumns.length === 0;
        var aggResult;
        if (aggDetails.userAggFunc) {
            aggResult = aggDetails.userAggFunc({ nodes: rowNode.childrenAfterFilter });
        }
        else if (measureColumnsMissing) {
            aggResult = null;
        }
        else if (pivotColumnsMissing) {
            aggResult = this.aggregateRowNodeUsingValuesOnly(rowNode, aggDetails);
        }
        else {
            aggResult = this.aggregateRowNodeUsingValuesAndPivot(rowNode);
        }
        rowNode.setAggData(aggResult);
        // if we are grouping, then it's possible there is a sibling footer
        // to the group, so update the data here also if there is one
        if (rowNode.sibling) {
            rowNode.sibling.setAggData(aggResult);
        }
    };
    AggregationStage.prototype.aggregateRowNodeUsingValuesAndPivot = function (rowNode) {
        var _this = this;
        var _a;
        var result = {};
        var secondaryColumns = (_a = this.columnModel.getSecondaryColumns()) !== null && _a !== void 0 ? _a : [];
        secondaryColumns.forEach(function (secondaryCol) {
            var _a = secondaryCol.getColDef(), pivotValueColumn = _a.pivotValueColumn, pivotTotalColumnIds = _a.pivotTotalColumnIds, colId = _a.colId, pivotKeys = _a.pivotKeys;
            if (_.exists(pivotTotalColumnIds)) {
                return;
            }
            var keys = pivotKeys !== null && pivotKeys !== void 0 ? pivotKeys : [];
            var values;
            if (rowNode.leafGroup) {
                // lowest level group, get the values from the mapped set
                values = _this.getValuesFromMappedSet(rowNode.childrenMapped, keys, pivotValueColumn);
            }
            else {
                // value columns and pivot columns, non-leaf group
                values = _this.getValuesPivotNonLeaf(rowNode, colId);
            }
            result[colId] = _this.aggregateValues(values, pivotValueColumn.getAggFunc(), pivotValueColumn, rowNode, secondaryCol);
        });
        secondaryColumns.forEach(function (secondaryCol) {
            var _a = secondaryCol.getColDef(), pivotValueColumn = _a.pivotValueColumn, pivotTotalColumnIds = _a.pivotTotalColumnIds, colId = _a.colId;
            if (!_.exists(pivotTotalColumnIds)) {
                return;
            }
            var aggResults = [];
            //retrieve results for colIds associated with this pivot total column
            if (!pivotTotalColumnIds || !pivotTotalColumnIds.length) {
                return;
            }
            pivotTotalColumnIds.forEach(function (currentColId) {
                aggResults.push(result[currentColId]);
            });
            result[colId] = _this.aggregateValues(aggResults, pivotValueColumn.getAggFunc(), pivotValueColumn, rowNode, secondaryCol);
        });
        return result;
    };
    AggregationStage.prototype.aggregateRowNodeUsingValuesOnly = function (rowNode, aggDetails) {
        var _this = this;
        var result = {};
        var changedValueColumns = aggDetails.changedPath.isActive() ?
            aggDetails.changedPath.getValueColumnsForNode(rowNode, aggDetails.valueColumns)
            : aggDetails.valueColumns;
        var notChangedValueColumns = aggDetails.changedPath.isActive() ?
            aggDetails.changedPath.getNotValueColumnsForNode(rowNode, aggDetails.valueColumns)
            : null;
        var values2d = this.getValuesNormal(rowNode, changedValueColumns, aggDetails.filteredOnly);
        var oldValues = rowNode.aggData;
        changedValueColumns.forEach(function (valueColumn, index) {
            result[valueColumn.getId()] = _this.aggregateValues(values2d[index], valueColumn.getAggFunc(), valueColumn, rowNode);
        });
        if (notChangedValueColumns && oldValues) {
            notChangedValueColumns.forEach(function (valueColumn) {
                result[valueColumn.getId()] = oldValues[valueColumn.getId()];
            });
        }
        return result;
    };
    AggregationStage.prototype.getValuesPivotNonLeaf = function (rowNode, colId) {
        var values = [];
        rowNode.childrenAfterFilter.forEach(function (node) {
            var value = node.aggData[colId];
            values.push(value);
        });
        return values;
    };
    AggregationStage.prototype.getValuesFromMappedSet = function (mappedSet, keys, valueColumn) {
        var _this = this;
        var mapPointer = mappedSet;
        keys.forEach(function (key) { return (mapPointer = mapPointer ? mapPointer[key] : null); });
        if (!mapPointer) {
            return [];
        }
        var values = [];
        mapPointer.forEach(function (rowNode) {
            var value = _this.valueService.getValue(valueColumn, rowNode);
            values.push(value);
        });
        return values;
    };
    AggregationStage.prototype.getValuesNormal = function (rowNode, valueColumns, filteredOnly) {
        // create 2d array, of all values for all valueColumns
        var values = [];
        valueColumns.forEach(function () { return values.push([]); });
        var valueColumnCount = valueColumns.length;
        var nodeList = filteredOnly ? rowNode.childrenAfterFilter : rowNode.childrenAfterGroup;
        var rowCount = nodeList.length;
        for (var i = 0; i < rowCount; i++) {
            var childNode = nodeList[i];
            for (var j = 0; j < valueColumnCount; j++) {
                var valueColumn = valueColumns[j];
                // if the row is a group, then it will only have an agg result value,
                // which means valueGetter is never used.
                var value = this.valueService.getValue(valueColumn, childNode);
                values[j].push(value);
            }
        }
        return values;
    };
    AggregationStage.prototype.aggregateValues = function (values, aggFuncOrString, column, rowNode, pivotResultColumn) {
        var aggFunc = typeof aggFuncOrString === 'string' ?
            this.aggFuncService.getAggFunc(aggFuncOrString) :
            aggFuncOrString;
        if (typeof aggFunc !== 'function') {
            console.error("AG Grid: unrecognised aggregation function ".concat(aggFuncOrString));
            return null;
        }
        var aggFuncAny = aggFunc;
        var params = this.gridOptionsService.addGridCommonParams({
            values: values,
            column: column,
            colDef: column ? column.getColDef() : undefined,
            pivotResultColumn: pivotResultColumn,
            rowNode: rowNode,
            data: rowNode ? rowNode.data : undefined
        }); // the "as any" is needed to allow the deprecation warning messages
        return aggFuncAny(params);
    };
    __decorate$1F([
        Autowired('columnModel')
    ], AggregationStage.prototype, "columnModel", void 0);
    __decorate$1F([
        Autowired('valueService')
    ], AggregationStage.prototype, "valueService", void 0);
    __decorate$1F([
        Autowired('aggFuncService')
    ], AggregationStage.prototype, "aggFuncService", void 0);
    AggregationStage = __decorate$1F([
        Bean('aggregationStage')
    ], AggregationStage);
    return AggregationStage;
}(BeanStub));

var BatchRemover = /** @class */ (function () {
    function BatchRemover() {
        this.allSets = {};
        this.allParents = [];
    }
    BatchRemover.prototype.removeFromChildrenAfterGroup = function (parent, child) {
        var set = this.getSet(parent);
        set.removeFromChildrenAfterGroup[child.id] = true;
    };
    BatchRemover.prototype.isRemoveFromAllLeafChildren = function (parent, child) {
        var set = this.getSet(parent);
        return !!set.removeFromAllLeafChildren[child.id];
    };
    BatchRemover.prototype.preventRemoveFromAllLeafChildren = function (parent, child) {
        var set = this.getSet(parent);
        delete set.removeFromAllLeafChildren[child.id];
    };
    BatchRemover.prototype.removeFromAllLeafChildren = function (parent, child) {
        var set = this.getSet(parent);
        set.removeFromAllLeafChildren[child.id] = true;
    };
    BatchRemover.prototype.getSet = function (parent) {
        if (!this.allSets[parent.id]) {
            this.allSets[parent.id] = {
                removeFromAllLeafChildren: {},
                removeFromChildrenAfterGroup: {}
            };
            this.allParents.push(parent);
        }
        return this.allSets[parent.id];
    };
    BatchRemover.prototype.getAllParents = function () {
        return this.allParents;
    };
    BatchRemover.prototype.flush = function () {
        var _this = this;
        this.allParents.forEach(function (parent) {
            var nodeDetails = _this.allSets[parent.id];
            parent.childrenAfterGroup = parent.childrenAfterGroup.filter(function (child) { return !nodeDetails.removeFromChildrenAfterGroup[child.id]; });
            parent.allLeafChildren = parent.allLeafChildren.filter(function (child) { return !nodeDetails.removeFromAllLeafChildren[child.id]; });
            parent.updateHasChildren();
            if (parent.sibling) {
                parent.sibling.childrenAfterGroup = parent.childrenAfterGroup;
                parent.sibling.allLeafChildren = parent.allLeafChildren;
            }
        });
        this.allSets = {};
        this.allParents.length = 0;
    };
    return BatchRemover;
}());

var __extends$29 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1E = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$z = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$l = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var GroupStage = /** @class */ (function (_super) {
    __extends$29(GroupStage, _super);
    function GroupStage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GroupStage.prototype.execute = function (params) {
        var details = this.createGroupingDetails(params);
        if (details.transactions) {
            this.handleTransaction(details);
        }
        else {
            var afterColsChanged = params.afterColumnsChanged === true;
            this.shotgunResetEverything(details, afterColsChanged);
        }
        if (!details.usingTreeData) {
            // we don't do group sorting for tree data
            this.positionLeafsAndGroups(params.changedPath);
            this.orderGroups(details);
        }
        this.selectableService.updateSelectableAfterGrouping();
    };
    GroupStage.prototype.positionLeafsAndGroups = function (changedPath) {
        changedPath.forEachChangedNodeDepthFirst(function (group) {
            if (group.childrenAfterGroup) {
                var leafNodes_1 = [];
                var groupNodes_1 = [];
                var unbalancedNode_1;
                group.childrenAfterGroup.forEach(function (row) {
                    var _a;
                    if (!((_a = row.childrenAfterGroup) === null || _a === void 0 ? void 0 : _a.length)) {
                        leafNodes_1.push(row);
                    }
                    else {
                        if (row.key === '' && !unbalancedNode_1) {
                            unbalancedNode_1 = row;
                        }
                        else {
                            groupNodes_1.push(row);
                        }
                    }
                });
                if (unbalancedNode_1) {
                    groupNodes_1.push(unbalancedNode_1);
                }
                group.childrenAfterGroup = __spreadArray$l(__spreadArray$l([], __read$z(leafNodes_1), false), __read$z(groupNodes_1), false);
            }
        }, false);
    };
    GroupStage.prototype.createGroupingDetails = function (params) {
        var _a;
        var rowNode = params.rowNode, changedPath = params.changedPath, rowNodeTransactions = params.rowNodeTransactions, rowNodeOrder = params.rowNodeOrder;
        var usingTreeData = this.gridOptionsService.get('treeData');
        var groupedCols = usingTreeData ? null : this.columnModel.getRowGroupColumns();
        var details = {
            // someone complained that the parent attribute was causing some change detection
            // to break in an angular add-on.  Taking the parent out breaks a cyclic dependency, hence this flag got introduced.
            includeParents: !this.gridOptionsService.get('suppressParentsInRowNodes'),
            expandByDefault: this.gridOptionsService.get('groupDefaultExpanded'),
            groupedCols: groupedCols,
            rootNode: rowNode,
            pivotMode: this.columnModel.isPivotMode(),
            groupedColCount: usingTreeData || !groupedCols ? 0 : groupedCols.length,
            rowNodeOrder: rowNodeOrder,
            transactions: rowNodeTransactions,
            // if no transaction, then it's shotgun, changed path would be 'not active' at this point anyway
            changedPath: changedPath,
            groupAllowUnbalanced: this.gridOptionsService.get('groupAllowUnbalanced'),
            isGroupOpenByDefault: this.gridOptionsService.getCallback('isGroupOpenByDefault'),
            initialGroupOrderComparator: this.gridOptionsService.getCallback('initialGroupOrderComparator'),
            usingTreeData: usingTreeData,
            suppressGroupMaintainValueType: this.gridOptionsService.get('suppressGroupMaintainValueType'),
            getDataPath: usingTreeData ? this.gridOptionsService.get('getDataPath') : undefined,
            keyCreators: (_a = groupedCols === null || groupedCols === void 0 ? void 0 : groupedCols.map(function (column) { return column.getColDef().keyCreator; })) !== null && _a !== void 0 ? _a : []
        };
        return details;
    };
    GroupStage.prototype.handleTransaction = function (details) {
        var _this = this;
        details.transactions.forEach(function (tran) {
            // we don't allow batch remover for tree data as tree data uses Filler Nodes,
            // and creating/deleting filler nodes needs to be done alongside the node deleting
            // and moving. if we want to Batch Remover working with tree data then would need
            // to consider how Filler Nodes would be impacted (it's possible that it can be easily
            // modified to work, however for now I don't have the brain energy to work it all out).
            var batchRemover = !details.usingTreeData ? new BatchRemover() : undefined;
            // the order here of [add, remove, update] needs to be the same as in ClientSideNodeManager,
            // as the order is important when a record with the same id is added and removed in the same
            // transaction.
            if (_.existsAndNotEmpty(tran.remove)) {
                _this.removeNodes(tran.remove, details, batchRemover);
            }
            if (_.existsAndNotEmpty(tran.update)) {
                _this.moveNodesInWrongPath(tran.update, details, batchRemover);
            }
            if (_.existsAndNotEmpty(tran.add)) {
                _this.insertNodes(tran.add, details, false);
            }
            // must flush here, and not allow another transaction to be applied,
            // as each transaction must finish leaving the data in a consistent state.
            if (batchRemover) {
                var parentsWithChildrenRemoved = batchRemover.getAllParents().slice();
                batchRemover.flush();
                _this.removeEmptyGroups(parentsWithChildrenRemoved, details);
            }
        });
        if (details.rowNodeOrder) {
            this.sortChildren(details);
        }
    };
    // this is used when doing delta updates, eg Redux, keeps nodes in right order
    GroupStage.prototype.sortChildren = function (details) {
        details.changedPath.forEachChangedNodeDepthFirst(function (node) {
            if (!node.childrenAfterGroup) {
                return;
            }
            var didSort = _.sortRowNodesByOrder(node.childrenAfterGroup, details.rowNodeOrder);
            if (didSort) {
                details.changedPath.addParentNode(node);
            }
        }, false, true);
    };
    GroupStage.prototype.orderGroups = function (details) {
        var comparator = details.initialGroupOrderComparator;
        if (_.exists(comparator)) {
            recursiveSort(details.rootNode);
        }
        function recursiveSort(rowNode) {
            var doSort = _.exists(rowNode.childrenAfterGroup) &&
                // we only want to sort groups, so we do not sort leafs (a leaf group has leafs as children)
                !rowNode.leafGroup;
            if (doSort) {
                rowNode.childrenAfterGroup.sort(function (nodeA, nodeB) { return comparator({ nodeA: nodeA, nodeB: nodeB }); });
                rowNode.childrenAfterGroup.forEach(function (childNode) { return recursiveSort(childNode); });
            }
        }
    };
    GroupStage.prototype.getExistingPathForNode = function (node, details) {
        var res = [];
        // when doing tree data, the node is part of the path,
        // but when doing grid grouping, the node is not part of the path so we start with the parent.
        var pointer = details.usingTreeData ? node : node.parent;
        while (pointer && pointer !== details.rootNode) {
            res.push({
                key: pointer.key,
                rowGroupColumn: pointer.rowGroupColumn,
                field: pointer.field
            });
            pointer = pointer.parent;
        }
        res.reverse();
        return res;
    };
    GroupStage.prototype.moveNodesInWrongPath = function (childNodes, details, batchRemover) {
        var _this = this;
        childNodes.forEach(function (childNode) {
            // we add node, even if parent has not changed, as the data could have
            // changed, hence aggregations will be wrong
            if (details.changedPath.isActive()) {
                details.changedPath.addParentNode(childNode.parent);
            }
            var infoToKeyMapper = function (item) { return item.key; };
            var oldPath = _this.getExistingPathForNode(childNode, details).map(infoToKeyMapper);
            var newPath = _this.getGroupInfo(childNode, details).map(infoToKeyMapper);
            var nodeInCorrectPath = _.areEqual(oldPath, newPath);
            if (!nodeInCorrectPath) {
                _this.moveNode(childNode, details, batchRemover);
            }
        });
    };
    GroupStage.prototype.moveNode = function (childNode, details, batchRemover) {
        this.removeNodesInStages([childNode], details, batchRemover);
        this.insertOneNode(childNode, details, true, batchRemover);
        // hack - if we didn't do this, then renaming a tree item (ie changing rowNode.key) wouldn't get
        // refreshed into the gui.
        // this is needed to kick off the event that rowComp listens to for refresh. this in turn
        // then will get each cell in the row to refresh - which is what we need as we don't know which
        // columns will be displaying the rowNode.key info.
        childNode.setData(childNode.data);
        // we add both old and new parents to changed path, as both will need to be refreshed.
        // we already added the old parent (in calling method), so just add the new parent here
        if (details.changedPath.isActive()) {
            var newParent = childNode.parent;
            details.changedPath.addParentNode(newParent);
        }
    };
    GroupStage.prototype.removeNodes = function (leafRowNodes, details, batchRemover) {
        this.removeNodesInStages(leafRowNodes, details, batchRemover);
        if (details.changedPath.isActive()) {
            leafRowNodes.forEach(function (rowNode) { return details.changedPath.addParentNode(rowNode.parent); });
        }
    };
    GroupStage.prototype.removeNodesInStages = function (leafRowNodes, details, batchRemover) {
        this.removeNodesFromParents(leafRowNodes, details, batchRemover);
        if (details.usingTreeData) {
            this.postRemoveCreateFillerNodes(leafRowNodes, details);
            // When not TreeData, then removeEmptyGroups is called just before the BatchRemover is flushed.
            // However for TreeData, there is no BatchRemover, so we have to call removeEmptyGroups here.
            var nodeParents = leafRowNodes.map(function (n) { return n.parent; });
            this.removeEmptyGroups(nodeParents, details);
        }
    };
    GroupStage.prototype.forEachParentGroup = function (details, group, callback) {
        var pointer = group;
        while (pointer && pointer !== details.rootNode) {
            callback(pointer);
            pointer = pointer.parent;
        }
    };
    GroupStage.prototype.removeNodesFromParents = function (nodesToRemove, details, provided) {
        var _this = this;
        // this method can be called with BatchRemover as optional. if it is missed, we created a local version
        // and flush it at the end. if one is provided, we add to the provided one and it gets flushed elsewhere.
        var batchRemoverIsLocal = provided == null;
        var batchRemoverToUse = provided ? provided : new BatchRemover();
        nodesToRemove.forEach(function (nodeToRemove) {
            _this.removeFromParent(nodeToRemove, batchRemoverToUse);
            // remove from allLeafChildren. we clear down all parents EXCEPT the Root Node, as
            // the ClientSideNodeManager is responsible for the Root Node.
            _this.forEachParentGroup(details, nodeToRemove.parent, function (parentNode) {
                batchRemoverToUse.removeFromAllLeafChildren(parentNode, nodeToRemove);
            });
        });
        if (batchRemoverIsLocal) {
            batchRemoverToUse.flush();
        }
    };
    GroupStage.prototype.postRemoveCreateFillerNodes = function (nodesToRemove, details) {
        var _this = this;
        nodesToRemove.forEach(function (nodeToRemove) {
            // if not group, and children are present, need to move children to a group.
            // otherwise if no children, we can just remove without replacing.
            var replaceWithGroup = nodeToRemove.hasChildren();
            if (replaceWithGroup) {
                var oldPath = _this.getExistingPathForNode(nodeToRemove, details);
                // because we just removed the userGroup, this will always return new support group
                var newGroupNode_1 = _this.findParentForNode(nodeToRemove, oldPath, details);
                // these properties are the ones that will be incorrect in the newly created group,
                // so copy them from the old childNode
                newGroupNode_1.expanded = nodeToRemove.expanded;
                newGroupNode_1.allLeafChildren = nodeToRemove.allLeafChildren;
                newGroupNode_1.childrenAfterGroup = nodeToRemove.childrenAfterGroup;
                newGroupNode_1.childrenMapped = nodeToRemove.childrenMapped;
                newGroupNode_1.updateHasChildren();
                newGroupNode_1.childrenAfterGroup.forEach(function (rowNode) { return rowNode.parent = newGroupNode_1; });
            }
        });
    };
    GroupStage.prototype.removeEmptyGroups = function (possibleEmptyGroups, details) {
        var _this = this;
        // we do this multiple times, as when we remove groups, that means the parent of just removed
        // group can then be empty. to get around this, if we remove, then we check everything again for
        // newly emptied groups. the max number of times this will execute is the depth of the group tree.
        var checkAgain = true;
        var groupShouldBeRemoved = function (rowNode) {
            // because of the while loop below, it's possible we already moved the node,
            // so double check before trying to remove again.
            var mapKey = _this.getChildrenMappedKey(rowNode.key, rowNode.rowGroupColumn);
            var parentRowNode = rowNode.parent;
            var groupAlreadyRemoved = (parentRowNode && parentRowNode.childrenMapped) ?
                !parentRowNode.childrenMapped[mapKey] : true;
            if (groupAlreadyRemoved) {
                // if not linked, then group was already removed
                return false;
            }
            // if still not removed, then we remove if this group is empty
            return !!rowNode.isEmptyRowGroupNode();
        };
        var _loop_1 = function () {
            checkAgain = false;
            var batchRemover = new BatchRemover();
            possibleEmptyGroups.forEach(function (possibleEmptyGroup) {
                // remove empty groups
                _this.forEachParentGroup(details, possibleEmptyGroup, function (rowNode) {
                    if (groupShouldBeRemoved(rowNode)) {
                        checkAgain = true;
                        _this.removeFromParent(rowNode, batchRemover);
                        // we remove selection on filler nodes here, as the selection would not be removed
                        // from the RowNodeManager, as filler nodes don't exist on the RowNodeManager
                        rowNode.setSelectedParams({ newValue: false, source: 'rowGroupChanged' });
                    }
                });
            });
            batchRemover.flush();
        };
        while (checkAgain) {
            _loop_1();
        }
    };
    // removes the node from the parent by:
    // a) removing from childrenAfterGroup (using batchRemover if present, otherwise immediately)
    // b) removing from childrenMapped (immediately)
    // c) setRowTop(null) - as the rowRenderer uses this to know the RowNode is no longer needed
    // d) setRowIndex(null) - as the rowNode will no longer be displayed.
    GroupStage.prototype.removeFromParent = function (child, batchRemover) {
        if (child.parent) {
            if (batchRemover) {
                batchRemover.removeFromChildrenAfterGroup(child.parent, child);
            }
            else {
                _.removeFromArray(child.parent.childrenAfterGroup, child);
                child.parent.updateHasChildren();
            }
        }
        var mapKey = this.getChildrenMappedKey(child.key, child.rowGroupColumn);
        if (child.parent && child.parent.childrenMapped) {
            child.parent.childrenMapped[mapKey] = undefined;
        }
        // this is important for transition, see rowComp removeFirstPassFuncs. when doing animation and
        // remove, if rowTop is still present, the rowComp thinks it's just moved position.
        child.setRowTop(null);
        child.setRowIndex(null);
    };
    GroupStage.prototype.addToParent = function (child, parent) {
        var mapKey = this.getChildrenMappedKey(child.key, child.rowGroupColumn);
        if (parent) {
            var children = parent.childrenMapped != null;
            if (children) {
                parent.childrenMapped[mapKey] = child;
            }
            parent.childrenAfterGroup.push(child);
            parent.updateHasChildren();
        }
    };
    GroupStage.prototype.areGroupColsEqual = function (d1, d2) {
        if (d1 == null || d2 == null || d1.pivotMode !== d2.pivotMode) {
            return false;
        }
        return _.areEqual(d1.groupedCols, d2.groupedCols) && _.areEqual(d1.keyCreators, d2.keyCreators);
    };
    GroupStage.prototype.checkAllGroupDataAfterColsChanged = function (details) {
        var _this = this;
        var recurse = function (rowNodes) {
            if (!rowNodes) {
                return;
            }
            rowNodes.forEach(function (rowNode) {
                var isLeafNode = !details.usingTreeData && !rowNode.group;
                if (isLeafNode) {
                    return;
                }
                var groupInfo = {
                    field: rowNode.field,
                    key: rowNode.key,
                    rowGroupColumn: rowNode.rowGroupColumn,
                    leafNode: rowNode.allLeafChildren[0],
                };
                _this.setGroupData(rowNode, groupInfo, details);
                recurse(rowNode.childrenAfterGroup);
            });
        };
        recurse(details.rootNode.childrenAfterGroup);
    };
    GroupStage.prototype.shotgunResetEverything = function (details, afterColumnsChanged) {
        if (this.noChangeInGroupingColumns(details, afterColumnsChanged)) {
            return;
        }
        // groups are about to get disposed, so need to deselect any that are selected
        this.selectionService.filterFromSelection(function (node) { return node && !node.group; });
        var rootNode = details.rootNode, groupedCols = details.groupedCols;
        // because we are not creating the root node each time, we have the logic
        // here to change leafGroup once.
        // we set .leafGroup to false for tree data, as .leafGroup is only used when pivoting, and pivoting
        // isn't allowed with treeData, so the grid never actually use .leafGroup when doing treeData.
        rootNode.leafGroup = details.usingTreeData ? false : groupedCols.length === 0;
        // we are doing everything from scratch, so reset childrenAfterGroup and childrenMapped from the rootNode
        rootNode.childrenAfterGroup = [];
        rootNode.childrenMapped = {};
        rootNode.updateHasChildren();
        var sibling = rootNode.sibling;
        if (sibling) {
            sibling.childrenAfterGroup = rootNode.childrenAfterGroup;
            sibling.childrenMapped = rootNode.childrenMapped;
        }
        this.insertNodes(rootNode.allLeafChildren, details, false);
    };
    GroupStage.prototype.noChangeInGroupingColumns = function (details, afterColumnsChanged) {
        var noFurtherProcessingNeeded = false;
        var groupDisplayColumns = this.columnModel.getGroupDisplayColumns();
        var newGroupDisplayColIds = groupDisplayColumns ?
            groupDisplayColumns.map(function (c) { return c.getId(); }).join('-') : '';
        if (afterColumnsChanged) {
            // we only need to redo grouping if doing normal grouping (ie not tree data)
            // and the group cols have changed.
            noFurtherProcessingNeeded = details.usingTreeData || this.areGroupColsEqual(details, this.oldGroupingDetails);
            // if the group display cols have changed, then we need to update rowNode.groupData
            // (regardless of tree data or row grouping)
            if (this.oldGroupDisplayColIds !== newGroupDisplayColIds) {
                this.checkAllGroupDataAfterColsChanged(details);
            }
        }
        this.oldGroupingDetails = details;
        this.oldGroupDisplayColIds = newGroupDisplayColIds;
        return noFurtherProcessingNeeded;
    };
    GroupStage.prototype.insertNodes = function (newRowNodes, details, isMove) {
        var _this = this;
        newRowNodes.forEach(function (rowNode) {
            _this.insertOneNode(rowNode, details, isMove);
            if (details.changedPath.isActive()) {
                details.changedPath.addParentNode(rowNode.parent);
            }
        });
    };
    GroupStage.prototype.insertOneNode = function (childNode, details, isMove, batchRemover) {
        var path = this.getGroupInfo(childNode, details);
        var parentGroup = this.findParentForNode(childNode, path, details, batchRemover);
        if (!parentGroup.group) {
            console.warn("AG Grid: duplicate group keys for row data, keys should be unique", [parentGroup.data, childNode.data]);
        }
        if (details.usingTreeData) {
            this.swapGroupWithUserNode(parentGroup, childNode, isMove);
        }
        else {
            childNode.parent = parentGroup;
            childNode.level = path.length;
            parentGroup.childrenAfterGroup.push(childNode);
            parentGroup.updateHasChildren();
        }
    };
    GroupStage.prototype.findParentForNode = function (childNode, path, details, batchRemover) {
        var _this = this;
        var nextNode = details.rootNode;
        path.forEach(function (groupInfo, level) {
            nextNode = _this.getOrCreateNextNode(nextNode, groupInfo, level, details);
            // node gets added to all group nodes.
            // note: we do not add to rootNode here, as the rootNode is the master list of rowNodes
            if (!(batchRemover === null || batchRemover === void 0 ? void 0 : batchRemover.isRemoveFromAllLeafChildren(nextNode, childNode))) {
                nextNode.allLeafChildren.push(childNode);
            }
            else {
                // if this node is about to be removed, prevent that
                batchRemover === null || batchRemover === void 0 ? void 0 : batchRemover.preventRemoveFromAllLeafChildren(nextNode, childNode);
            }
        });
        return nextNode;
    };
    GroupStage.prototype.swapGroupWithUserNode = function (fillerGroup, userGroup, isMove) {
        userGroup.parent = fillerGroup.parent;
        userGroup.key = fillerGroup.key;
        userGroup.field = fillerGroup.field;
        userGroup.groupData = fillerGroup.groupData;
        userGroup.level = fillerGroup.level;
        // AG-3441 - preserve the existing expanded status of the node if we're moving it, so that
        // you can drag a sub tree from one parent to another without changing its expansion
        if (!isMove) {
            userGroup.expanded = fillerGroup.expanded;
        }
        // we set .leafGroup to false for tree data, as .leafGroup is only used when pivoting, and pivoting
        // isn't allowed with treeData, so the grid never actually use .leafGroup when doing treeData.
        userGroup.leafGroup = fillerGroup.leafGroup;
        // always null for userGroups, as row grouping is not allowed when doing tree data
        userGroup.rowGroupIndex = fillerGroup.rowGroupIndex;
        userGroup.allLeafChildren = fillerGroup.allLeafChildren;
        userGroup.childrenAfterGroup = fillerGroup.childrenAfterGroup;
        userGroup.childrenMapped = fillerGroup.childrenMapped;
        userGroup.sibling = fillerGroup.sibling;
        userGroup.updateHasChildren();
        this.removeFromParent(fillerGroup);
        userGroup.childrenAfterGroup.forEach(function (rowNode) { return rowNode.parent = userGroup; });
        this.addToParent(userGroup, fillerGroup.parent);
    };
    GroupStage.prototype.getOrCreateNextNode = function (parentGroup, groupInfo, level, details) {
        var key = this.getChildrenMappedKey(groupInfo.key, groupInfo.rowGroupColumn);
        var nextNode = parentGroup.childrenMapped ? parentGroup.childrenMapped[key] : undefined;
        if (!nextNode) {
            nextNode = this.createGroup(groupInfo, parentGroup, level, details);
            // attach the new group to the parent
            this.addToParent(nextNode, parentGroup);
        }
        return nextNode;
    };
    GroupStage.prototype.createGroup = function (groupInfo, parent, level, details) {
        var groupNode = new RowNode(this.beans);
        groupNode.group = true;
        groupNode.field = groupInfo.field;
        groupNode.rowGroupColumn = groupInfo.rowGroupColumn;
        this.setGroupData(groupNode, groupInfo, details);
        groupNode.key = groupInfo.key;
        groupNode.id = this.createGroupId(groupNode, parent, details.usingTreeData, level);
        groupNode.level = level;
        groupNode.leafGroup = details.usingTreeData ? false : level === (details.groupedColCount - 1);
        groupNode.allLeafChildren = [];
        // why is this done here? we are not updating the children count as we go,
        // i suspect this is updated in the filter stage
        groupNode.setAllChildrenCount(0);
        groupNode.rowGroupIndex = details.usingTreeData ? null : level;
        groupNode.childrenAfterGroup = [];
        groupNode.childrenMapped = {};
        groupNode.updateHasChildren();
        groupNode.parent = details.includeParents ? parent : null;
        this.setExpandedInitialValue(details, groupNode);
        return groupNode;
    };
    GroupStage.prototype.createGroupId = function (node, parent, usingTreeData, level) {
        var createGroupId;
        if (usingTreeData) {
            createGroupId = function (node, parent, level) {
                if (level < 0) {
                    return null;
                } // root node
                var parentId = parent ? createGroupId(parent, parent.parent, level - 1) : null;
                return "".concat(parentId == null ? '' : parentId + '-').concat(level, "-").concat(node.key);
            };
        }
        else {
            createGroupId = function (node, parent) {
                if (!node.rowGroupColumn) {
                    return null;
                } // root node
                var parentId = parent ? createGroupId(parent, parent.parent, 0) : null;
                return "".concat(parentId == null ? '' : parentId + '-').concat(node.rowGroupColumn.getColId(), "-").concat(node.key);
            };
        }
        // we put 'row-group-' before the group id, so it doesn't clash with standard row id's. we also use 't-' and 'b-'
        // for top pinned and bottom pinned rows.
        return RowNode.ID_PREFIX_ROW_GROUP + createGroupId(node, parent, level);
    };
    GroupStage.prototype.setGroupData = function (groupNode, groupInfo, details) {
        var _this = this;
        groupNode.groupData = {};
        var groupDisplayCols = this.columnModel.getGroupDisplayColumns();
        groupDisplayCols.forEach(function (col) {
            // newGroup.rowGroupColumn=null when working off GroupInfo, and we always display the group in the group column
            // if rowGroupColumn is present, then it's grid row grouping and we only include if configuration says so
            var isTreeData = details.usingTreeData;
            if (isTreeData) {
                groupNode.groupData[col.getColId()] = groupInfo.key;
                return;
            }
            var groupColumn = groupNode.rowGroupColumn;
            var isRowGroupDisplayed = groupColumn !== null && col.isRowGroupDisplayed(groupColumn.getId());
            if (isRowGroupDisplayed) {
                if (details.suppressGroupMaintainValueType) {
                    groupNode.groupData[col.getColId()] = groupInfo.key;
                }
                else {
                    // if maintain group value type, get the value from any leaf node.
                    groupNode.groupData[col.getColId()] = _this.valueService.getValue(groupColumn, groupInfo.leafNode);
                }
            }
        });
    };
    GroupStage.prototype.getChildrenMappedKey = function (key, rowGroupColumn) {
        if (rowGroupColumn) {
            // grouping by columns
            return rowGroupColumn.getId() + '-' + key;
        }
        // tree data - we don't have rowGroupColumns
        return key;
    };
    GroupStage.prototype.setExpandedInitialValue = function (details, groupNode) {
        // if pivoting the leaf group is never expanded as we do not show leaf rows
        if (details.pivotMode && groupNode.leafGroup) {
            groupNode.expanded = false;
            return;
        }
        // use callback if exists
        var userCallback = details.isGroupOpenByDefault;
        if (userCallback) {
            var params = {
                rowNode: groupNode,
                field: groupNode.field,
                key: groupNode.key,
                level: groupNode.level,
                rowGroupColumn: groupNode.rowGroupColumn
            };
            groupNode.expanded = userCallback(params) == true;
            return;
        }
        // use expandByDefault if exists
        var expandByDefault = details.expandByDefault;
        if (details.expandByDefault === -1) {
            groupNode.expanded = true;
            return;
        }
        // otherwise
        groupNode.expanded = groupNode.level < expandByDefault;
    };
    GroupStage.prototype.getGroupInfo = function (rowNode, details) {
        if (details.usingTreeData) {
            return this.getGroupInfoFromCallback(rowNode, details);
        }
        return this.getGroupInfoFromGroupColumns(rowNode, details);
    };
    GroupStage.prototype.getGroupInfoFromCallback = function (rowNode, details) {
        var keys = details.getDataPath ? details.getDataPath(rowNode.data) : null;
        if (keys === null || keys === undefined || keys.length === 0) {
            _.warnOnce("getDataPath() should not return an empty path for data ".concat(rowNode.data));
        }
        var groupInfoMapper = function (key) { return ({ key: key, field: null, rowGroupColumn: null }); };
        return keys ? keys.map(groupInfoMapper) : [];
    };
    GroupStage.prototype.getGroupInfoFromGroupColumns = function (rowNode, details) {
        var _this = this;
        var res = [];
        details.groupedCols.forEach(function (groupCol) {
            var key = _this.valueService.getKeyForNode(groupCol, rowNode);
            var keyExists = key !== null && key !== undefined && key !== '';
            // unbalanced tree and pivot mode don't work together - not because of the grid, it doesn't make
            // mathematical sense as you are building up a cube. so if pivot mode, we put in a blank key where missing.
            // this keeps the tree balanced and hence can be represented as a group.
            var createGroupForEmpty = details.pivotMode || !details.groupAllowUnbalanced;
            if (createGroupForEmpty && !keyExists) {
                key = '';
                keyExists = true;
            }
            if (keyExists) {
                var item = {
                    key: key,
                    field: groupCol.getColDef().field,
                    rowGroupColumn: groupCol,
                    leafNode: rowNode,
                };
                res.push(item);
            }
        });
        return res;
    };
    __decorate$1E([
        Autowired('columnModel')
    ], GroupStage.prototype, "columnModel", void 0);
    __decorate$1E([
        Autowired('selectableService')
    ], GroupStage.prototype, "selectableService", void 0);
    __decorate$1E([
        Autowired('valueService')
    ], GroupStage.prototype, "valueService", void 0);
    __decorate$1E([
        Autowired('beans')
    ], GroupStage.prototype, "beans", void 0);
    __decorate$1E([
        Autowired('selectionService')
    ], GroupStage.prototype, "selectionService", void 0);
    GroupStage = __decorate$1E([
        Bean('groupStage')
    ], GroupStage);
    return GroupStage;
}(BeanStub));

var __extends$28 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$x = (undefined && undefined.__assign) || function () {
    __assign$x = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$x.apply(this, arguments);
};
var __decorate$1D = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$y = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$k = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var PivotColDefService = /** @class */ (function (_super) {
    __extends$28(PivotColDefService, _super);
    function PivotColDefService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PivotColDefService_1 = PivotColDefService;
    PivotColDefService.prototype.init = function () {
        var _this = this;
        var getFieldSeparator = function () { var _a; return (_a = _this.gos.get('serverSidePivotResultFieldSeparator')) !== null && _a !== void 0 ? _a : '_'; };
        this.fieldSeparator = getFieldSeparator();
        this.addManagedPropertyListener('serverSidePivotResultFieldSeparator', function () { _this.fieldSeparator = getFieldSeparator(); });
        var getPivotDefaultExpanded = function () { return _this.gos.get('pivotDefaultExpanded'); };
        this.pivotDefaultExpanded = getPivotDefaultExpanded();
        this.addManagedPropertyListener('pivotDefaultExpanded', function () { _this.pivotDefaultExpanded = getPivotDefaultExpanded(); });
    };
    PivotColDefService.prototype.createPivotColumnDefs = function (uniqueValues) {
        // this is passed to the columnModel, to configure the columns and groups we show
        var pivotColumnGroupDefs = this.createPivotColumnsFromUniqueValues(uniqueValues);
        function extractColDefs(input, arr) {
            if (arr === void 0) { arr = []; }
            input.forEach(function (def) {
                if (def.children !== undefined) {
                    extractColDefs(def.children, arr);
                }
                else {
                    arr.push(def);
                }
            });
            return arr;
        }
        var pivotColumnDefs = extractColDefs(pivotColumnGroupDefs);
        // additional columns that contain the aggregated total for each value column per row
        this.addRowGroupTotals(pivotColumnGroupDefs, pivotColumnDefs);
        // additional group columns that contain child totals for each collapsed child column / group
        this.addExpandablePivotGroups(pivotColumnGroupDefs, pivotColumnDefs);
        // additional group columns that contain an aggregated total across all child columns
        this.addPivotTotalsToGroups(pivotColumnGroupDefs, pivotColumnDefs);
        // we clone, so the colDefs in pivotColumnsGroupDefs and pivotColumnDefs are not shared. this is so that
        // any changes the user makes (via processSecondaryColumnDefinitions) don't impact the internal aggregations,
        // as these use the col defs also
        var pivotColumnDefsClone = pivotColumnDefs.map(function (colDef) { return _.cloneObject(colDef); });
        return {
            pivotColumnGroupDefs: pivotColumnGroupDefs,
            pivotColumnDefs: pivotColumnDefsClone
        };
    };
    PivotColDefService.prototype.createPivotColumnsFromUniqueValues = function (uniqueValues) {
        var pivotColumns = this.columnModel.getPivotColumns();
        var maxDepth = pivotColumns.length;
        var pivotColumnGroupDefs = this.recursivelyBuildGroup(0, uniqueValues, [], maxDepth, pivotColumns);
        return pivotColumnGroupDefs;
    };
    PivotColDefService.prototype.recursivelyBuildGroup = function (index, uniqueValue, pivotKeys, maxDepth, primaryPivotColumns) {
        var _this = this;
        var measureColumns = this.columnModel.getValueColumns();
        if (index >= maxDepth) { // Base case - build the measure columns
            return this.buildMeasureCols(pivotKeys);
        }
        // sort by either user provided comparator, or our own one
        var primaryPivotColumnDefs = primaryPivotColumns[index].getColDef();
        var comparator = this.headerNameComparator.bind(this, primaryPivotColumnDefs.pivotComparator);
        // Base case for the compact layout, instead of recursing build the last layer of groups as measure columns instead
        if (measureColumns.length === 1 && this.gridOptionsService.get('removePivotHeaderRowWhenSingleValueColumn') && index === maxDepth - 1) {
            var leafCols_1 = [];
            _.iterateObject(uniqueValue, function (key) {
                var newPivotKeys = __spreadArray$k(__spreadArray$k([], __read$y(pivotKeys), false), [key], false);
                var colDef = _this.createColDef(measureColumns[0], key, newPivotKeys);
                colDef.columnGroupShow = 'open';
                leafCols_1.push(colDef);
            });
            leafCols_1.sort(comparator);
            return leafCols_1;
        }
        // Recursive case
        var groups = [];
        _.iterateObject(uniqueValue, function (key, value) {
            // expand group by default based on depth of group. (pivotDefaultExpanded provides desired level of depth for expanding group by default)
            var openByDefault = _this.pivotDefaultExpanded === -1 || (index < _this.pivotDefaultExpanded);
            var newPivotKeys = __spreadArray$k(__spreadArray$k([], __read$y(pivotKeys), false), [key], false);
            groups.push({
                children: _this.recursivelyBuildGroup(index + 1, value, newPivotKeys, maxDepth, primaryPivotColumns),
                headerName: key,
                pivotKeys: newPivotKeys,
                columnGroupShow: 'open',
                openByDefault: openByDefault,
                groupId: _this.generateColumnGroupId(newPivotKeys),
            });
        });
        groups.sort(comparator);
        return groups;
    };
    PivotColDefService.prototype.buildMeasureCols = function (pivotKeys) {
        var _this = this;
        var measureColumns = this.columnModel.getValueColumns();
        if (measureColumns.length === 0) {
            // if no value columns selected, then we insert one blank column, so the user at least sees columns
            // rendered. otherwise the grid would render with no columns (just empty groups) which would give the
            // impression that the grid is broken
            return [this.createColDef(null, '-', pivotKeys)];
        }
        return measureColumns.map(function (measureCol) {
            var columnName = _this.columnModel.getDisplayNameForColumn(measureCol, 'header');
            return __assign$x(__assign$x({}, _this.createColDef(measureCol, columnName, pivotKeys)), { columnGroupShow: 'open' });
        });
    };
    PivotColDefService.prototype.addExpandablePivotGroups = function (pivotColumnGroupDefs, pivotColumnDefs) {
        var _this = this;
        if (this.gridOptionsService.get('suppressExpandablePivotGroups') ||
            this.gridOptionsService.get('pivotColumnGroupTotals')) {
            return;
        }
        var recursivelyAddSubTotals = function (groupDef, currentPivotColumnDefs, acc) {
            var group = groupDef;
            if (group.children) {
                var childAcc_1 = new Map();
                group.children.forEach(function (grp) {
                    recursivelyAddSubTotals(grp, currentPivotColumnDefs, childAcc_1);
                });
                var firstGroup_1 = !group.children.some(function (child) { return child.children; });
                _this.columnModel.getValueColumns().forEach(function (valueColumn) {
                    var columnName = _this.columnModel.getDisplayNameForColumn(valueColumn, 'header');
                    var totalColDef = _this.createColDef(valueColumn, columnName, groupDef.pivotKeys);
                    totalColDef.pivotTotalColumnIds = childAcc_1.get(valueColumn.getColId());
                    totalColDef.columnGroupShow = 'closed';
                    totalColDef.aggFunc = valueColumn.getAggFunc();
                    if (!firstGroup_1) {
                        // add total colDef to group and pivot colDefs array
                        var children = groupDef.children;
                        children.push(totalColDef);
                        currentPivotColumnDefs.push(totalColDef);
                    }
                });
                _this.merge(acc, childAcc_1);
            }
            else {
                var def = groupDef;
                // check that value column exists, i.e. aggFunc is supplied
                if (!def.pivotValueColumn) {
                    return;
                }
                var pivotValueColId = def.pivotValueColumn.getColId();
                var arr = acc.has(pivotValueColId) ? acc.get(pivotValueColId) : [];
                arr.push(def.colId);
                acc.set(pivotValueColId, arr);
            }
        };
        pivotColumnGroupDefs.forEach(function (groupDef) {
            recursivelyAddSubTotals(groupDef, pivotColumnDefs, new Map());
        });
    };
    PivotColDefService.prototype.addPivotTotalsToGroups = function (pivotColumnGroupDefs, pivotColumnDefs) {
        var _this = this;
        if (!this.gridOptionsService.get('pivotColumnGroupTotals')) {
            return;
        }
        var insertAfter = this.gridOptionsService.get('pivotColumnGroupTotals') === 'after';
        var valueCols = this.columnModel.getValueColumns();
        var aggFuncs = valueCols.map(function (valueCol) { return valueCol.getAggFunc(); });
        // don't add pivot totals if there is less than 1 aggFunc or they are not all the same
        if (!aggFuncs || aggFuncs.length < 1 || !this.sameAggFuncs(aggFuncs)) {
            // console.warn('AG Grid: aborting adding pivot total columns - value columns require same aggFunc');
            return;
        }
        // arbitrarily select a value column to use as a template for pivot columns
        var valueColumn = valueCols[0];
        pivotColumnGroupDefs.forEach(function (groupDef) {
            _this.recursivelyAddPivotTotal(groupDef, pivotColumnDefs, valueColumn, insertAfter);
        });
    };
    PivotColDefService.prototype.recursivelyAddPivotTotal = function (groupDef, pivotColumnDefs, valueColumn, insertAfter) {
        var _this = this;
        var group = groupDef;
        if (!group.children) {
            var def = groupDef;
            return def.colId ? [def.colId] : null;
        }
        var colIds = [];
        // need to recurse children first to obtain colIds used in the aggregation stage
        group.children
            .forEach(function (grp) {
            var childColIds = _this.recursivelyAddPivotTotal(grp, pivotColumnDefs, valueColumn, insertAfter);
            if (childColIds) {
                colIds = colIds.concat(childColIds);
            }
        });
        // only add total colDef if there is more than 1 child node
        if (group.children.length > 1) {
            var localeTextFunc = this.localeService.getLocaleTextFunc();
            var headerName = localeTextFunc('pivotColumnGroupTotals', 'Total');
            //create total colDef using an arbitrary value column as a template
            var totalColDef = this.createColDef(valueColumn, headerName, groupDef.pivotKeys, true);
            totalColDef.pivotTotalColumnIds = colIds;
            totalColDef.aggFunc = valueColumn.getAggFunc();
            // add total colDef to group and pivot colDefs array
            var children = groupDef.children;
            insertAfter ? children.push(totalColDef) : children.unshift(totalColDef);
            pivotColumnDefs.push(totalColDef);
        }
        return colIds;
    };
    PivotColDefService.prototype.addRowGroupTotals = function (pivotColumnGroupDefs, pivotColumnDefs) {
        var _this = this;
        if (!this.gridOptionsService.get('pivotRowTotals')) {
            return;
        }
        var insertAfter = this.gridOptionsService.get('pivotRowTotals') === 'after';
        var valueColumns = this.columnModel.getValueColumns();
        // order of row group totals depends on position
        var valueCols = insertAfter ? valueColumns.slice() : valueColumns.slice().reverse();
        var _loop_1 = function (i) {
            var valueCol = valueCols[i];
            var colIds = [];
            pivotColumnGroupDefs.forEach(function (groupDef) {
                colIds = colIds.concat(_this.extractColIdsForValueColumn(groupDef, valueCol));
            });
            var withGroup = valueCols.length > 1 || !this_1.gridOptionsService.get('removePivotHeaderRowWhenSingleValueColumn');
            this_1.createRowGroupTotal(pivotColumnGroupDefs, pivotColumnDefs, valueCol, colIds, insertAfter, withGroup);
        };
        var this_1 = this;
        for (var i = 0; i < valueCols.length; i++) {
            _loop_1(i);
        }
    };
    PivotColDefService.prototype.extractColIdsForValueColumn = function (groupDef, valueColumn) {
        var _this = this;
        var group = groupDef;
        if (!group.children) {
            var colDef = group;
            return colDef.pivotValueColumn === valueColumn && colDef.colId ? [colDef.colId] : [];
        }
        var colIds = [];
        group.children
            .forEach(function (grp) {
            _this.extractColIdsForValueColumn(grp, valueColumn);
            var childColIds = _this.extractColIdsForValueColumn(grp, valueColumn);
            colIds = colIds.concat(childColIds);
        });
        return colIds;
    };
    PivotColDefService.prototype.createRowGroupTotal = function (parentChildren, pivotColumnDefs, valueColumn, colIds, insertAfter, addGroup) {
        var measureColumns = this.columnModel.getValueColumns();
        var colDef;
        if (measureColumns.length === 0) {
            colDef = this.createColDef(null, '-', []);
        }
        else {
            var columnName = this.columnModel.getDisplayNameForColumn(valueColumn, 'header');
            colDef = this.createColDef(valueColumn, columnName, []);
            colDef.pivotTotalColumnIds = colIds;
        }
        colDef.colId = PivotColDefService_1.PIVOT_ROW_TOTAL_PREFIX + colDef.colId;
        pivotColumnDefs.push(colDef);
        var valueGroup = addGroup ? {
            children: [colDef],
            pivotKeys: [],
            groupId: "".concat(PivotColDefService_1.PIVOT_ROW_TOTAL_PREFIX, "_pivotGroup_").concat(valueColumn.getColId()),
        } : colDef;
        insertAfter ? parentChildren.push(valueGroup) : parentChildren.unshift(valueGroup);
    };
    PivotColDefService.prototype.createColDef = function (valueColumn, headerName, pivotKeys, totalColumn) {
        if (totalColumn === void 0) { totalColumn = false; }
        var colDef = {};
        // This is null when there are no measure columns and we're creating placeholder columns
        if (valueColumn) {
            var colDefToCopy = valueColumn.getColDef();
            Object.assign(colDef, colDefToCopy);
            // even if original column was hidden, we always show the pivot value column, otherwise it would be
            // very confusing for people thinking the pivot is broken
            colDef.hide = false;
        }
        colDef.headerName = headerName;
        colDef.colId = this.generateColumnId(pivotKeys || [], valueColumn && !totalColumn ? valueColumn.getColId() : '');
        // pivot columns repeat over field, so it makes sense to use the unique id instead. For example if you want to
        // assign values to pinned bottom rows using setPinnedBottomRowData the value service will use this colId.
        colDef.field = colDef.colId;
        // this is to support using pinned rows, normally the data will be extracted from the aggData object using the colId
        // however pinned rows still access the data object by field, this prevents values with dots from being treated as complex objects
        colDef.valueGetter = function (params) { var _a; return (_a = params.data) === null || _a === void 0 ? void 0 : _a[params.colDef.field]; };
        colDef.pivotKeys = pivotKeys;
        colDef.pivotValueColumn = valueColumn;
        if (colDef.filter === true) {
            colDef.filter = 'agNumberColumnFilter';
        }
        return colDef;
    };
    PivotColDefService.prototype.sameAggFuncs = function (aggFuncs) {
        if (aggFuncs.length == 1) {
            return true;
        }
        //check if all aggFunc's match
        for (var i = 1; i < aggFuncs.length; i++) {
            if (aggFuncs[i] !== aggFuncs[0]) {
                return false;
            }
        }
        return true;
    };
    PivotColDefService.prototype.headerNameComparator = function (userComparator, a, b) {
        if (userComparator) {
            return userComparator(a.headerName, b.headerName);
        }
        else {
            if (a.headerName && !b.headerName) {
                return 1;
            }
            else if (!a.headerName && b.headerName) {
                return -1;
            }
            // slightly naff here - just to satify typescript
            // really should be &&, but if so ts complains
            // the above if/else checks would deal with either being falsy, so at this stage if either are falsy, both are
            // ..still naff though
            if (!a.headerName || !b.headerName) {
                return 0;
            }
            if (a.headerName < b.headerName) {
                return -1;
            }
            if (a.headerName > b.headerName) {
                return 1;
            }
            return 0;
        }
    };
    PivotColDefService.prototype.merge = function (m1, m2) {
        m2.forEach(function (value, key, map) {
            var existingList = m1.has(key) ? m1.get(key) : [];
            var updatedList = __spreadArray$k(__spreadArray$k([], __read$y(existingList), false), __read$y(value), false);
            m1.set(key, updatedList);
        });
    };
    PivotColDefService.prototype.generateColumnGroupId = function (pivotKeys) {
        var pivotCols = this.columnModel.getPivotColumns().map(function (col) { return col.getColId(); });
        return "pivotGroup_".concat(pivotCols.join('-'), "_").concat(pivotKeys.join('-'));
    };
    PivotColDefService.prototype.generateColumnId = function (pivotKeys, measureColumnId) {
        var pivotCols = this.columnModel.getPivotColumns().map(function (col) { return col.getColId(); });
        return "pivot_".concat(pivotCols.join('-'), "_").concat(pivotKeys.join('-'), "_").concat(measureColumnId);
    };
    /**
     * Used by the SSRM to create secondary columns from provided fields
     * @param fields
     */
    PivotColDefService.prototype.createColDefsFromFields = function (fields) {
        var _this = this;
        // tear the ids down into groups, while this could be done in-step with the next stage, the lookup is faster 
        // than searching col group children array for the right group
        var uniqueValues = {};
        for (var i = 0; i < fields.length; i++) {
            var field = fields[i];
            var parts = field.split(this.fieldSeparator);
            var level = uniqueValues;
            for (var p = 0; p < parts.length; p++) {
                var part = parts[p];
                if (level[part] == null) {
                    level[part] = {};
                }
                level = level[part];
            }
        }
        var uniqueValuesToGroups = function (id, key, uniqueValues, depth) {
            var _a;
            var children = [];
            for (var key_1 in uniqueValues) {
                var item = uniqueValues[key_1];
                var child = uniqueValuesToGroups("".concat(id).concat(_this.fieldSeparator).concat(key_1), key_1, item, depth + 1);
                children.push(child);
            }
            if (children.length === 0) {
                var potentialAggCol = _this.columnModel.getPrimaryColumn(key);
                if (potentialAggCol) {
                    var headerName = (_a = _this.columnModel.getDisplayNameForColumn(potentialAggCol, 'header')) !== null && _a !== void 0 ? _a : key;
                    var colDef = _this.createColDef(potentialAggCol, headerName, undefined, false);
                    colDef.colId = id;
                    colDef.aggFunc = potentialAggCol.getAggFunc();
                    colDef.valueGetter = function (params) { var _a; return (_a = params.data) === null || _a === void 0 ? void 0 : _a[id]; };
                    return colDef;
                }
                var col = {
                    colId: id,
                    headerName: key,
                    // this is to support using pinned rows, normally the data will be extracted from the aggData object using the colId
                    // however pinned rows still access the data object by field, this prevents values with dots from being treated as complex objects
                    valueGetter: function (params) { var _a; return (_a = params.data) === null || _a === void 0 ? void 0 : _a[id]; },
                };
                return col;
            }
            // this is a bit sketchy. As the fields can be anything we just build groups as deep as the fields go.
            // nothing says user has to give us groups the same depth.
            var collapseSingleChildren = _this.gridOptionsService.get('removePivotHeaderRowWhenSingleValueColumn');
            if (collapseSingleChildren && children.length === 1 && 'colId' in children[0]) {
                children[0].headerName = key;
                return children[0];
            }
            var group = {
                openByDefault: _this.pivotDefaultExpanded === -1 || depth < _this.pivotDefaultExpanded,
                groupId: id,
                headerName: key,
                children: children,
            };
            return group;
        };
        var res = [];
        for (var key in uniqueValues) {
            var item = uniqueValues[key];
            var col = uniqueValuesToGroups(key, key, item, 0);
            res.push(col);
        }
        return res;
    };
    var PivotColDefService_1;
    PivotColDefService.PIVOT_ROW_TOTAL_PREFIX = 'PivotRowTotal_';
    __decorate$1D([
        Autowired('columnModel')
    ], PivotColDefService.prototype, "columnModel", void 0);
    __decorate$1D([
        Autowired('gridOptionsService')
    ], PivotColDefService.prototype, "gos", void 0);
    __decorate$1D([
        PostConstruct
    ], PivotColDefService.prototype, "init", null);
    PivotColDefService = PivotColDefService_1 = __decorate$1D([
        Bean('pivotColDefService')
    ], PivotColDefService);
    return PivotColDefService;
}(BeanStub));

var __extends$27 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1C = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var PivotStage = /** @class */ (function (_super) {
    __extends$27(PivotStage, _super);
    function PivotStage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.uniqueValues = {};
        return _this;
    }
    PivotStage.prototype.execute = function (params) {
        var changedPath = params.changedPath;
        if (this.columnModel.isPivotActive()) {
            this.executePivotOn(changedPath);
        }
        else {
            this.executePivotOff(changedPath);
        }
    };
    PivotStage.prototype.executePivotOff = function (changedPath) {
        this.aggregationColumnsHashLastTime = null;
        this.uniqueValues = {};
        if (this.columnModel.isSecondaryColumnsPresent()) {
            this.columnModel.setSecondaryColumns(null, "rowModelUpdated");
            if (changedPath) {
                changedPath.setInactive();
            }
        }
    };
    PivotStage.prototype.executePivotOn = function (changedPath) {
        var uniqueValues = this.bucketUpRowNodes(changedPath);
        var uniqueValuesChanged = this.setUniqueValues(uniqueValues);
        var aggregationColumns = this.columnModel.getValueColumns();
        var aggregationColumnsHash = aggregationColumns.map(function (column) { return "".concat(column.getId(), "-").concat(column.getColDef().headerName); }).join('#');
        var aggregationFuncsHash = aggregationColumns.map(function (column) { return column.getAggFunc().toString(); }).join('#');
        var aggregationColumnsChanged = this.aggregationColumnsHashLastTime !== aggregationColumnsHash;
        var aggregationFuncsChanged = this.aggregationFuncsHashLastTime !== aggregationFuncsHash;
        this.aggregationColumnsHashLastTime = aggregationColumnsHash;
        this.aggregationFuncsHashLastTime = aggregationFuncsHash;
        var groupColumnsHash = this.columnModel.getRowGroupColumns().map(function (column) { return column.getId(); }).join('#');
        var groupColumnsChanged = groupColumnsHash !== this.groupColumnsHashLastTime;
        this.groupColumnsHashLastTime = groupColumnsHash;
        var pivotRowTotals = this.gridOptionsService.get('pivotRowTotals');
        var pivotColumnGroupTotals = this.gridOptionsService.get('pivotColumnGroupTotals');
        var suppressExpandablePivotGroups = this.gridOptionsService.get('suppressExpandablePivotGroups');
        var removePivotHeaderRowWhenSingleValueColumn = this.gridOptionsService.get('removePivotHeaderRowWhenSingleValueColumn');
        var anyGridOptionsChanged = (pivotRowTotals !== this.pivotRowTotalsLastTime || pivotColumnGroupTotals !== this.pivotColumnGroupTotalsLastTime ||
            suppressExpandablePivotGroups !== this.suppressExpandablePivotGroupsLastTime || removePivotHeaderRowWhenSingleValueColumn !== this.removePivotHeaderRowWhenSingleValueColumnLastTime);
        this.pivotRowTotalsLastTime = pivotRowTotals;
        this.pivotColumnGroupTotalsLastTime = pivotColumnGroupTotals;
        this.suppressExpandablePivotGroupsLastTime = suppressExpandablePivotGroups;
        this.removePivotHeaderRowWhenSingleValueColumnLastTime = removePivotHeaderRowWhenSingleValueColumn;
        if (uniqueValuesChanged || aggregationColumnsChanged || groupColumnsChanged || aggregationFuncsChanged || anyGridOptionsChanged) {
            var _a = this.pivotColDefService.createPivotColumnDefs(this.uniqueValues), pivotColumnGroupDefs = _a.pivotColumnGroupDefs, pivotColumnDefs = _a.pivotColumnDefs;
            this.pivotColumnDefs = pivotColumnDefs;
            this.columnModel.setSecondaryColumns(pivotColumnGroupDefs, "rowModelUpdated");
            // because the secondary columns have changed, then the aggregation needs to visit the whole
            // tree again, so we make the changedPath not active, to force aggregation to visit all paths.
            if (changedPath) {
                changedPath.setInactive();
            }
        }
    };
    PivotStage.prototype.setUniqueValues = function (newValues) {
        var json1 = JSON.stringify(newValues);
        var json2 = JSON.stringify(this.uniqueValues);
        var uniqueValuesChanged = json1 !== json2;
        // we only continue the below if the unique values are different, as otherwise
        // the result will be the same as the last time we did it
        if (uniqueValuesChanged) {
            this.uniqueValues = newValues;
            return true;
        }
        else {
            return false;
        }
    };
    PivotStage.prototype.bucketUpRowNodes = function (changedPath) {
        var _this = this;
        // accessed from inside inner function
        var uniqueValues = {};
        // ensure childrenMapped is cleared, as if a node has been filtered out it should not have mapped children.
        changedPath.forEachChangedNodeDepthFirst(function (node) {
            if (node.leafGroup) {
                node.childrenMapped = null;
            }
        });
        var recursivelyBucketFilteredChildren = function (node) {
            var _a;
            if (node.leafGroup) {
                _this.bucketRowNode(node, uniqueValues);
            }
            else {
                (_a = node.childrenAfterFilter) === null || _a === void 0 ? void 0 : _a.forEach(recursivelyBucketFilteredChildren);
            }
        };
        changedPath.executeFromRootNode(recursivelyBucketFilteredChildren);
        return uniqueValues;
    };
    PivotStage.prototype.bucketRowNode = function (rowNode, uniqueValues) {
        var pivotColumns = this.columnModel.getPivotColumns();
        if (pivotColumns.length === 0) {
            rowNode.childrenMapped = null;
        }
        else {
            rowNode.childrenMapped = this.bucketChildren(rowNode.childrenAfterFilter, pivotColumns, 0, uniqueValues);
        }
        if (rowNode.sibling) {
            rowNode.sibling.childrenMapped = rowNode.childrenMapped;
        }
    };
    PivotStage.prototype.bucketChildren = function (children, pivotColumns, pivotIndex, uniqueValues) {
        var _this = this;
        var mappedChildren = {};
        var pivotColumn = pivotColumns[pivotIndex];
        // map the children out based on the pivot column
        children.forEach(function (child) {
            var key = _this.valueService.getKeyForNode(pivotColumn, child);
            if (_.missing(key)) {
                key = '';
            }
            if (!uniqueValues[key]) {
                uniqueValues[key] = {};
            }
            if (!mappedChildren[key]) {
                mappedChildren[key] = [];
            }
            mappedChildren[key].push(child);
        });
        // if it's the last pivot column, return as is, otherwise go one level further in the map
        if (pivotIndex === pivotColumns.length - 1) {
            return mappedChildren;
        }
        else {
            var result_1 = {};
            _.iterateObject(mappedChildren, function (key, value) {
                result_1[key] = _this.bucketChildren(value, pivotColumns, pivotIndex + 1, uniqueValues[key]);
            });
            return result_1;
        }
    };
    PivotStage.prototype.getPivotColumnDefs = function () {
        return this.pivotColumnDefs;
    };
    __decorate$1C([
        Autowired('valueService')
    ], PivotStage.prototype, "valueService", void 0);
    __decorate$1C([
        Autowired('columnModel')
    ], PivotStage.prototype, "columnModel", void 0);
    __decorate$1C([
        Autowired('pivotColDefService')
    ], PivotStage.prototype, "pivotColDefService", void 0);
    PivotStage = __decorate$1C([
        Bean('pivotStage')
    ], PivotStage);
    return PivotStage;
}(BeanStub));

var __extends$26 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1B = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// @ts-ignore
var AGBigInt = typeof BigInt === 'undefined' ? null : BigInt;
var defaultAggFuncNames = {
    sum: 'Sum',
    first: 'First',
    last: 'Last',
    min: 'Min',
    max: 'Max',
    count: 'Count',
    avg: 'Average',
};
var AggFuncService = /** @class */ (function (_super) {
    __extends$26(AggFuncService, _super);
    function AggFuncService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.aggFuncsMap = {};
        _this.initialised = false;
        return _this;
    }
    AggFuncService_1 = AggFuncService;
    AggFuncService.prototype.init = function () {
        if (this.initialised) {
            return;
        }
        this.initialiseWithDefaultAggregations();
        this.addAggFuncs(this.gridOptionsService.get('aggFuncs'));
    };
    AggFuncService.prototype.initialiseWithDefaultAggregations = function () {
        this.aggFuncsMap[AggFuncService_1.AGG_SUM] = aggSum;
        this.aggFuncsMap[AggFuncService_1.AGG_FIRST] = aggFirst;
        this.aggFuncsMap[AggFuncService_1.AGG_LAST] = aggLast;
        this.aggFuncsMap[AggFuncService_1.AGG_MIN] = aggMin;
        this.aggFuncsMap[AggFuncService_1.AGG_MAX] = aggMax;
        this.aggFuncsMap[AggFuncService_1.AGG_COUNT] = aggCount;
        this.aggFuncsMap[AggFuncService_1.AGG_AVG] = aggAvg;
        this.initialised = true;
    };
    AggFuncService.prototype.isAggFuncPossible = function (column, func) {
        var allKeys = this.getFuncNames(column);
        var allowed = _.includes(allKeys, func);
        var funcExists = _.exists(this.aggFuncsMap[func]);
        return allowed && funcExists;
    };
    AggFuncService.prototype.getDefaultFuncLabel = function (fctName) {
        var _a;
        return (_a = defaultAggFuncNames[fctName]) !== null && _a !== void 0 ? _a : fctName;
    };
    AggFuncService.prototype.getDefaultAggFunc = function (column) {
        var defaultAgg = column.getColDef().defaultAggFunc;
        if (_.exists(defaultAgg) && this.isAggFuncPossible(column, defaultAgg)) {
            return defaultAgg;
        }
        if (this.isAggFuncPossible(column, AggFuncService_1.AGG_SUM)) {
            return AggFuncService_1.AGG_SUM;
        }
        var allKeys = this.getFuncNames(column);
        return _.existsAndNotEmpty(allKeys) ? allKeys[0] : null;
    };
    AggFuncService.prototype.addAggFuncs = function (aggFuncs) {
        _.iterateObject(aggFuncs, this.addAggFunc.bind(this));
    };
    AggFuncService.prototype.addAggFunc = function (key, aggFunc) {
        this.init();
        this.aggFuncsMap[key] = aggFunc;
    };
    AggFuncService.prototype.getAggFunc = function (name) {
        this.init();
        return this.aggFuncsMap[name];
    };
    AggFuncService.prototype.getFuncNames = function (column) {
        var userAllowedFuncs = column.getColDef().allowedAggFuncs;
        return userAllowedFuncs == null ? Object.keys(this.aggFuncsMap).sort() : userAllowedFuncs;
    };
    AggFuncService.prototype.clear = function () {
        this.aggFuncsMap = {};
    };
    var AggFuncService_1;
    AggFuncService.AGG_SUM = 'sum';
    AggFuncService.AGG_FIRST = 'first';
    AggFuncService.AGG_LAST = 'last';
    AggFuncService.AGG_MIN = 'min';
    AggFuncService.AGG_MAX = 'max';
    AggFuncService.AGG_COUNT = 'count';
    AggFuncService.AGG_AVG = 'avg';
    __decorate$1B([
        PostConstruct
    ], AggFuncService.prototype, "init", null);
    AggFuncService = AggFuncService_1 = __decorate$1B([
        Bean('aggFuncService')
    ], AggFuncService);
    return AggFuncService;
}(BeanStub));
function aggSum(params) {
    var values = params.values;
    var result = null; // the logic ensures that we never combine bigint arithmetic with numbers, but TS is hard to please
    // for optimum performance, we use a for loop here rather than calling any helper methods or using functional code
    for (var i = 0; i < values.length; i++) {
        var value = values[i];
        if (typeof value === 'number') {
            if (result === null) {
                result = value;
            }
            else {
                if (AGBigInt) {
                    result += typeof result === 'number' ? value : AGBigInt(value);
                }
                else {
                    result += value;
                }
            }
        }
        else if (typeof value === 'bigint') {
            if (result === null) {
                result = value;
            }
            else {
                result = (typeof result === 'bigint' ? result : AGBigInt(result)) + value;
            }
        }
    }
    return result;
}
function aggFirst(params) {
    return params.values.length > 0 ? params.values[0] : null;
}
function aggLast(params) {
    return params.values.length > 0 ? _.last(params.values) : null;
}
function aggMin(params) {
    var values = params.values;
    var result = null;
    // for optimum performance, we use a for loop here rather than calling any helper methods or using functional code
    for (var i = 0; i < values.length; i++) {
        var value = values[i];
        if ((typeof value === 'number' || typeof value === 'bigint') && (result === null || result > value)) {
            result = value;
        }
    }
    return result;
}
function aggMax(params) {
    var values = params.values;
    var result = null;
    // for optimum performance, we use a for loop here rather than calling any helper methods or using functional code
    for (var i = 0; i < values.length; i++) {
        var value = values[i];
        if ((typeof value === 'number' || typeof value === 'bigint') && (result === null || result < value)) {
            result = value;
        }
    }
    return result;
}
function aggCount(params) {
    var _a, _b;
    var values = params.values;
    var result = 0;
    // for optimum performance, we use a for loop here rather than calling any helper methods or using functional code
    for (var i = 0; i < values.length; i++) {
        var value = values[i];
        // check if the value is from a group, in which case use the group's count
        result += value != null && typeof value.value === 'number' ? value.value : 1;
    }
    // the previous aggregation data
    var existingAggData = (_b = (_a = params.rowNode) === null || _a === void 0 ? void 0 : _a.aggData) === null || _b === void 0 ? void 0 : _b[params.column.getColId()];
    if (existingAggData && existingAggData.value === result) {
        // the underlying values haven't changed, return the old object to avoid triggering change detection
        return existingAggData;
    }
    // it's important to wrap it in the object so we can determine if this is a group level
    return {
        value: result,
        toString: function () {
            return this.value.toString();
        },
        // used for sorting
        toNumber: function () {
            return this.value;
        }
    };
}
// the average function is tricky as the multiple levels require weighted averages
// for the non-leaf node aggregations.
function aggAvg(params) {
    var _a, _b, _c;
    var values = params.values;
    var sum = 0; // the logic ensures that we never combine bigint arithmetic with numbers, but TS is hard to please
    var count = 0;
    // for optimum performance, we use a for loop here rather than calling any helper methods or using functional code
    for (var i = 0; i < values.length; i++) {
        var currentValue = values[i];
        var valueToAdd = null;
        if (typeof currentValue === 'number' || typeof currentValue === 'bigint') {
            valueToAdd = currentValue;
            count++;
        }
        else if (currentValue != null && (typeof currentValue.value === 'number' || typeof currentValue.value === 'bigint') && typeof currentValue.count === 'number') {
            // we are aggregating groups, so we take the aggregated values to calculated a weighted average
            if (AGBigInt) {
                valueToAdd = currentValue.value * (typeof currentValue.value === 'number' ? currentValue.count : AGBigInt(currentValue.count));
            }
            else {
                valueToAdd = currentValue.value * currentValue.count;
            }
            count += currentValue.count;
        }
        if (typeof valueToAdd === 'number') {
            if (AGBigInt) {
                sum += typeof sum === 'number' ? valueToAdd : AGBigInt(valueToAdd);
            }
            else {
                sum += valueToAdd;
            }
        }
        else if (typeof valueToAdd === 'bigint') {
            sum = (typeof sum === 'bigint' ? sum : AGBigInt(sum)) + valueToAdd;
        }
    }
    var value = null;
    // avoid divide by zero error
    if (count > 0) {
        if (AGBigInt) {
            value = sum / (typeof sum === 'number' ? count : AGBigInt(count));
        }
        else {
            value = sum / count;
        }
    }
    // the previous aggregation data
    var existingAggData = (_b = (_a = params.rowNode) === null || _a === void 0 ? void 0 : _a.aggData) === null || _b === void 0 ? void 0 : _b[(_c = params.column) === null || _c === void 0 ? void 0 : _c.getColId()];
    if (existingAggData && existingAggData.count === count && existingAggData.value === value) {
        // the underlying values haven't changed, return the old object to avoid triggering change detection
        return existingAggData;
    }
    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    return {
        count: count,
        value: value,
        // the grid by default uses toString to render values for an object, so this
        // is a trick to get the default cellRenderer to display the avg value
        toString: function () {
            return typeof this.value === 'number' || typeof this.value === 'bigint' ? this.value.toString() : '';
        },
        // used for sorting
        toNumber: function () {
            return this.value;
        }
    };
}

var __extends$25 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1A = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var DropZoneColumnComp = /** @class */ (function (_super) {
    __extends$25(DropZoneColumnComp, _super);
    function DropZoneColumnComp(column, dragSourceDropTarget, ghost, dropZonePurpose, horizontal) {
        var _this = _super.call(this) || this;
        _this.column = column;
        _this.dragSourceDropTarget = dragSourceDropTarget;
        _this.ghost = ghost;
        _this.dropZonePurpose = dropZonePurpose;
        _this.horizontal = horizontal;
        _this.popupShowing = false;
        return _this;
    }
    DropZoneColumnComp.prototype.init = function () {
        var _this = this;
        this.setTemplate(DropZoneColumnComp.TEMPLATE);
        var eGui = this.getGui();
        var isFunctionsReadOnly = this.gridOptionsService.get('functionsReadOnly');
        this.addElementClasses(eGui);
        this.addElementClasses(this.eDragHandle, 'drag-handle');
        this.addElementClasses(this.eText, 'text');
        this.addElementClasses(this.eButton, 'button');
        this.eDragHandle.appendChild(_.createIconNoSpan('columnDrag', this.gridOptionsService));
        this.eButton.appendChild(_.createIconNoSpan('cancel', this.gridOptionsService));
        this.setupSort();
        this.displayName = this.columnModel.getDisplayNameForColumn(this.column, 'columnDrop');
        this.setupComponents();
        if (!this.ghost && !isFunctionsReadOnly) {
            this.addDragSource();
        }
        this.setupAria();
        this.addManagedListener(this.eventService, Column.EVENT_SORT_CHANGED, function () {
            _this.setupAria();
        });
        this.setupTooltip();
        this.activateTabIndex();
        var checkColumnLock = function () {
            var isLocked = _this.isGroupingAndLocked();
            _.setDisplayed(_this.eButton, !isLocked && !_this.gridOptionsService.get('functionsReadOnly'));
            _this.eDragHandle.classList.toggle('ag-column-select-column-readonly', isLocked);
            _this.setupAria();
        };
        checkColumnLock();
        if (this.isGroupingZone()) {
            this.addManagedPropertyListener('groupLockGroupColumns', function () { return checkColumnLock(); });
        }
    };
    DropZoneColumnComp.prototype.getColumn = function () {
        return this.column;
    };
    DropZoneColumnComp.prototype.setupAria = function () {
        var translate = this.localeService.getLocaleTextFunc();
        var _a = this.getColumnAndAggFuncName(), name = _a.name, aggFuncName = _a.aggFuncName;
        var aggSeparator = translate('ariaDropZoneColumnComponentAggFuncSeparator', ' of ');
        var sortDirection = {
            asc: translate('ariaDropZoneColumnComponentSortAscending', 'ascending'),
            desc: translate('ariaDropZoneColumnComponentSortDescending', 'descending'),
        };
        var columnSort = this.column.getSort();
        var isSortSuppressed = this.gridOptionsService.get('rowGroupPanelSuppressSort');
        var ariaInstructions = [
            [
                aggFuncName && "".concat(aggFuncName).concat(aggSeparator),
                name,
                this.isGroupingZone() && !isSortSuppressed && columnSort && ", ".concat(sortDirection[columnSort])
            ].filter(function (part) { return !!part; }).join(''),
        ];
        var isFunctionsReadOnly = this.gridOptionsService.get('functionsReadOnly');
        if (this.isAggregationZone() && !isFunctionsReadOnly) {
            var aggregationMenuAria = translate('ariaDropZoneColumnValueItemDescription', 'Press ENTER to change the aggregation type');
            ariaInstructions.push(aggregationMenuAria);
        }
        if (this.isGroupingZone() && this.column.isSortable() && !isSortSuppressed) {
            var sortProgressAria = translate('ariaDropZoneColumnGroupItemDescription', 'Press ENTER to sort');
            ariaInstructions.push(sortProgressAria);
        }
        if (!this.isGroupingAndLocked()) {
            var deleteAria = translate('ariaDropZoneColumnComponentDescription', 'Press DELETE to remove');
            ariaInstructions.push(deleteAria);
        }
        _.setAriaLabel(this.getGui(), ariaInstructions.join('. '));
    };
    DropZoneColumnComp.prototype.setupTooltip = function () {
        var _this = this;
        var refresh = function () {
            var newTooltipText = _this.column.getColDef().headerTooltip;
            _this.setTooltip(newTooltipText);
        };
        refresh();
        this.addManagedListener(this.eventService, Events.EVENT_NEW_COLUMNS_LOADED, refresh);
    };
    DropZoneColumnComp.prototype.setupSort = function () {
        var _this = this;
        var canSort = this.column.isSortable();
        var isGroupingZone = this.isGroupingZone();
        if (!canSort || !isGroupingZone) {
            return;
        }
        if (!this.gridOptionsService.get('rowGroupPanelSuppressSort')) {
            this.eSortIndicator.setupSort(this.column, true);
            var performSort_1 = function (event) {
                event.preventDefault();
                var sortUsingCtrl = _this.gridOptionsService.get('multiSortKey') === 'ctrl';
                var multiSort = sortUsingCtrl ? (event.ctrlKey || event.metaKey) : event.shiftKey;
                _this.sortController.progressSort(_this.column, multiSort, 'uiColumnSorted');
            };
            this.addGuiEventListener('click', performSort_1);
            this.addGuiEventListener('keydown', function (e) {
                var isEnter = e.key === KeyCode.ENTER;
                if (isEnter && _this.isGroupingZone()) {
                    performSort_1(e);
                }
            });
        }
    };
    DropZoneColumnComp.prototype.addDragSource = function () {
        var _this = this;
        var _a = this, dragAndDropService = _a.dragAndDropService, displayName = _a.displayName, eDragHandle = _a.eDragHandle, column = _a.column;
        var dragSource = {
            type: DragSourceType.ToolPanel,
            eElement: eDragHandle,
            getDefaultIconName: function () { return DragAndDropService.ICON_HIDE; },
            getDragItem: function () { return _this.createDragItem(column); },
            dragItemName: displayName
        };
        dragAndDropService.addDragSource(dragSource, true);
        this.addDestroyFunc(function () { return dragAndDropService.removeDragSource(dragSource); });
    };
    DropZoneColumnComp.prototype.createDragItem = function (column) {
        var visibleState = {};
        visibleState[column.getId()] = column.isVisible();
        return {
            columns: [column],
            visibleState: visibleState
        };
    };
    DropZoneColumnComp.prototype.setupComponents = function () {
        this.setTextValue();
        this.setupRemove();
        if (this.ghost) {
            this.addCssClass('ag-column-drop-cell-ghost');
        }
        if (this.isAggregationZone() && !this.gridOptionsService.get('functionsReadOnly')) {
            this.addGuiEventListener('click', this.onShowAggFuncSelection.bind(this));
        }
    };
    DropZoneColumnComp.prototype.isGroupingAndLocked = function () {
        return this.isGroupingZone() && this.columnModel.isColumnGroupingLocked(this.column);
    };
    DropZoneColumnComp.prototype.setupRemove = function () {
        var _this = this;
        _.setDisplayed(this.eButton, !this.isGroupingAndLocked() && !this.gridOptionsService.get('functionsReadOnly'));
        var agEvent = { type: DropZoneColumnComp.EVENT_COLUMN_REMOVE };
        this.addGuiEventListener('keydown', function (e) {
            var isEnter = e.key === KeyCode.ENTER;
            var isDelete = e.key === KeyCode.DELETE;
            if (isDelete) {
                if (!_this.isGroupingAndLocked()) {
                    e.preventDefault();
                    _this.dispatchEvent(agEvent);
                }
            }
            if (isEnter && _this.isAggregationZone() && !_this.gridOptionsService.get('functionsReadOnly')) {
                e.preventDefault();
                _this.onShowAggFuncSelection();
            }
        });
        this.addManagedListener(this.eButton, 'click', function (mouseEvent) {
            _this.dispatchEvent(agEvent);
            mouseEvent.stopPropagation();
        });
        var touchListener = new TouchListener(this.eButton);
        this.addManagedListener(touchListener, TouchListener.EVENT_TAP, function () {
            _this.dispatchEvent(agEvent);
        });
        this.addDestroyFunc(touchListener.destroy.bind(touchListener));
    };
    DropZoneColumnComp.prototype.getColumnAndAggFuncName = function () {
        var name = this.displayName;
        var aggFuncName = '';
        if (this.isAggregationZone()) {
            var aggFunc = this.column.getAggFunc();
            // if aggFunc is a string, we can use it, but if it's a function, then we swap with 'func'
            var aggFuncString = typeof aggFunc === 'string' ? aggFunc : 'agg';
            var localeTextFunc = this.localeService.getLocaleTextFunc();
            aggFuncName = localeTextFunc(aggFuncString, aggFuncString);
        }
        return { name: name, aggFuncName: aggFuncName };
    };
    DropZoneColumnComp.prototype.setTextValue = function () {
        var _a = this.getColumnAndAggFuncName(), name = _a.name, aggFuncName = _a.aggFuncName;
        var displayValue = this.isAggregationZone() ? "".concat(aggFuncName, "(").concat(name, ")") : name;
        var displayValueSanitised = _.escapeString(displayValue);
        this.eText.innerHTML = displayValueSanitised;
    };
    DropZoneColumnComp.prototype.onShowAggFuncSelection = function () {
        var _this = this;
        if (this.popupShowing) {
            return;
        }
        this.popupShowing = true;
        var virtualList = new VirtualList({ cssIdentifier: 'select-agg-func' });
        var rows = this.aggFuncService.getFuncNames(this.column);
        var eGui = this.getGui();
        var virtualListGui = virtualList.getGui();
        virtualList.setModel({
            getRow: function (index) { return rows[index]; },
            getRowCount: function () { return rows.length; }
        });
        this.getContext().createBean(virtualList);
        var ePopup = _.loadTemplate(/* html*/ "<div class=\"ag-select-agg-func-popup\"></div>");
        ePopup.style.top = '0px';
        ePopup.style.left = '0px';
        ePopup.appendChild(virtualListGui);
        ePopup.style.width = "".concat(eGui.clientWidth, "px");
        var focusoutListener = this.addManagedListener(ePopup, 'focusout', function (e) {
            if (!ePopup.contains(e.relatedTarget) && addPopupRes) {
                addPopupRes.hideFunc();
            }
        });
        var popupHiddenFunc = function (callbackEvent) {
            _this.destroyBean(virtualList);
            _this.popupShowing = false;
            if ((callbackEvent === null || callbackEvent === void 0 ? void 0 : callbackEvent.key) === 'Escape') {
                eGui.focus();
            }
            if (focusoutListener) {
                focusoutListener();
            }
        };
        var translate = this.localeService.getLocaleTextFunc();
        var addPopupRes = this.popupService.addPopup({
            modal: true,
            eChild: ePopup,
            closeOnEsc: true,
            closedCallback: popupHiddenFunc,
            ariaLabel: translate('ariaLabelAggregationFunction', 'Aggregation Function')
        });
        if (addPopupRes) {
            virtualList.setComponentCreator(this.createAggSelect.bind(this, addPopupRes.hideFunc));
        }
        virtualList.addGuiEventListener('keydown', function (e) {
            if (e.key === KeyCode.ENTER || e.key === KeyCode.SPACE) {
                var row = virtualList.getLastFocusedRow();
                if (row == null) {
                    return;
                }
                var comp = virtualList.getComponentAt(row);
                if (comp) {
                    comp.selectItem();
                }
            }
        });
        this.popupService.positionPopupByComponent({
            type: 'aggFuncSelect',
            eventSource: eGui,
            ePopup: ePopup,
            keepWithinBounds: true,
            column: this.column,
            position: 'under'
        });
        virtualList.refresh();
        var rowToFocus = rows.findIndex(function (r) { return r === _this.column.getAggFunc(); });
        if (rowToFocus === -1) {
            rowToFocus = 0;
        }
        virtualList.focusRow(rowToFocus);
    };
    DropZoneColumnComp.prototype.createAggSelect = function (hidePopup, value) {
        var _this = this;
        var itemSelected = function () {
            hidePopup();
            if (_this.gridOptionsService.get('functionsPassive')) {
                var event_1 = {
                    type: Events.EVENT_COLUMN_AGG_FUNC_CHANGE_REQUEST,
                    columns: [_this.column],
                    aggFunc: value
                };
                _this.eventService.dispatchEvent(event_1);
            }
            else {
                _this.columnModel.setColumnAggFunc(_this.column, value, "toolPanelDragAndDrop");
            }
        };
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        var aggFuncString = value.toString();
        var aggFuncStringTranslated = localeTextFunc(aggFuncString, aggFuncString);
        var comp = new AggItemComp(itemSelected, aggFuncStringTranslated);
        return comp;
    };
    DropZoneColumnComp.prototype.addElementClasses = function (el, suffix) {
        suffix = suffix ? "-".concat(suffix) : '';
        var direction = this.horizontal ? 'horizontal' : 'vertical';
        el.classList.add("ag-column-drop-cell".concat(suffix), "ag-column-drop-".concat(direction, "-cell").concat(suffix));
    };
    DropZoneColumnComp.prototype.isAggregationZone = function () {
        return this.dropZonePurpose === 'aggregation';
    };
    DropZoneColumnComp.prototype.isGroupingZone = function () {
        return this.dropZonePurpose === 'rowGroup';
    };
    DropZoneColumnComp.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
        this.column = null;
        this.dragSourceDropTarget = null;
    };
    DropZoneColumnComp.EVENT_COLUMN_REMOVE = 'columnRemove';
    DropZoneColumnComp.TEMPLATE = "<span role=\"option\">\n          <span ref=\"eDragHandle\" class=\"ag-drag-handle ag-column-drop-cell-drag-handle\" role=\"presentation\"></span>\n          <span ref=\"eText\" class=\"ag-column-drop-cell-text\" aria-hidden=\"true\"></span>\n          <ag-sort-indicator ref=\"eSortIndicator\"></ag-sort-indicator>\n          <span ref=\"eButton\" class=\"ag-column-drop-cell-button\" role=\"presentation\"></span>\n        </span>";
    __decorate$1A([
        Autowired('dragAndDropService')
    ], DropZoneColumnComp.prototype, "dragAndDropService", void 0);
    __decorate$1A([
        Autowired('columnModel')
    ], DropZoneColumnComp.prototype, "columnModel", void 0);
    __decorate$1A([
        Autowired('popupService')
    ], DropZoneColumnComp.prototype, "popupService", void 0);
    __decorate$1A([
        Optional('aggFuncService')
    ], DropZoneColumnComp.prototype, "aggFuncService", void 0);
    __decorate$1A([
        Autowired('sortController')
    ], DropZoneColumnComp.prototype, "sortController", void 0);
    __decorate$1A([
        RefSelector('eText')
    ], DropZoneColumnComp.prototype, "eText", void 0);
    __decorate$1A([
        RefSelector('eDragHandle')
    ], DropZoneColumnComp.prototype, "eDragHandle", void 0);
    __decorate$1A([
        RefSelector('eButton')
    ], DropZoneColumnComp.prototype, "eButton", void 0);
    __decorate$1A([
        RefSelector('eSortIndicator')
    ], DropZoneColumnComp.prototype, "eSortIndicator", void 0);
    __decorate$1A([
        PostConstruct
    ], DropZoneColumnComp.prototype, "init", null);
    return DropZoneColumnComp;
}(Component));
var AggItemComp = /** @class */ (function (_super) {
    __extends$25(AggItemComp, _super);
    function AggItemComp(itemSelected, value) {
        var _this = _super.call(this, /* html */ "<div class=\"ag-select-agg-func-item\"/>") || this;
        _this.selectItem = itemSelected;
        _this.getGui().innerText = value;
        _this.addGuiEventListener('click', _this.selectItem);
        return _this;
    }
    return AggItemComp;
}(Component));

var __extends$24 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1z = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$x = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$j = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var BaseDropZonePanel = /** @class */ (function (_super) {
    __extends$24(BaseDropZonePanel, _super);
    function BaseDropZonePanel(horizontal, dropZonePurpose) {
        var _this = _super.call(this, /* html */ "<div class=\"ag-unselectable\" role=\"presentation\"></div>") || this;
        _this.horizontal = horizontal;
        _this.dropZonePurpose = dropZonePurpose;
        _this.state = BaseDropZonePanel.STATE_NOT_DRAGGING;
        _this.guiDestroyFunctions = [];
        _this.childColumnComponents = [];
        _this.resizeEnabled = false;
        _this.addElementClasses(_this.getGui());
        _this.eColumnDropList = document.createElement('div');
        _this.addElementClasses(_this.eColumnDropList, 'list');
        _.setAriaRole(_this.eColumnDropList, 'listbox');
        return _this;
    }
    BaseDropZonePanel.prototype.isHorizontal = function () {
        return this.horizontal;
    };
    BaseDropZonePanel.prototype.toggleResizable = function (resizable) {
        this.positionableFeature.setResizable(resizable ? { bottom: true } : false);
        this.resizeEnabled = resizable;
    };
    BaseDropZonePanel.prototype.setBeans = function (beans) {
        this.beans = beans;
    };
    BaseDropZonePanel.prototype.isSourceEventFromTarget = function (draggingEvent) {
        var dropZoneTarget = draggingEvent.dropZoneTarget, dragSource = draggingEvent.dragSource;
        return dropZoneTarget.contains(dragSource.eElement);
    };
    BaseDropZonePanel.prototype.destroy = function () {
        this.destroyGui();
        _super.prototype.destroy.call(this);
    };
    BaseDropZonePanel.prototype.destroyGui = function () {
        this.guiDestroyFunctions.forEach(function (func) { return func(); });
        this.guiDestroyFunctions.length = 0;
        this.childColumnComponents.length = 0;
        _.clearElement(this.getGui());
        _.clearElement(this.eColumnDropList);
    };
    BaseDropZonePanel.prototype.init = function (params) {
        this.params = params;
        this.createManagedBean(new ManagedFocusFeature(this.getFocusableElement(), {
            handleKeyDown: this.handleKeyDown.bind(this)
        }));
        this.addManagedListener(this.beans.eventService, Events.EVENT_NEW_COLUMNS_LOADED, this.refreshGui.bind(this));
        this.addManagedPropertyListeners(['functionsReadOnly', 'rowGroupPanelSuppressSort', 'groupLockGroupColumns'], this.refreshGui.bind(this));
        this.setupDropTarget();
        this.positionableFeature = new PositionableFeature(this.getGui(), { minHeight: 100 });
        this.createManagedBean(this.positionableFeature);
        // we don't know if this bean will be initialised before columnModel.
        // if columnModel first, then below will work
        // if columnModel second, then below will put blank in, and then above event gets first when columnModel is set up
        this.refreshGui();
        _.setAriaLabel(this.eColumnDropList, this.getAriaLabel());
    };
    BaseDropZonePanel.prototype.handleKeyDown = function (e) {
        var isVertical = !this.horizontal;
        var isNext = e.key === KeyCode.DOWN;
        var isPrevious = e.key === KeyCode.UP;
        if (!isVertical) {
            var isRtl = this.gridOptionsService.get('enableRtl');
            isNext = (!isRtl && e.key === KeyCode.RIGHT) || (isRtl && e.key === KeyCode.LEFT);
            isPrevious = (!isRtl && e.key === KeyCode.LEFT) || (isRtl && e.key === KeyCode.RIGHT);
        }
        if (!isNext && !isPrevious) {
            return;
        }
        var el = this.focusService.findNextFocusableElement(this.getFocusableElement(), false, isPrevious);
        if (el) {
            e.preventDefault();
            el.focus();
        }
    };
    BaseDropZonePanel.prototype.addElementClasses = function (el, suffix) {
        suffix = suffix ? "-".concat(suffix) : '';
        var direction = this.horizontal ? 'horizontal' : 'vertical';
        el.classList.add("ag-column-drop".concat(suffix), "ag-column-drop-".concat(direction).concat(suffix));
    };
    BaseDropZonePanel.prototype.setupDropTarget = function () {
        this.dropTarget = {
            getContainer: this.getGui.bind(this),
            getIconName: this.getIconName.bind(this),
            onDragging: this.onDragging.bind(this),
            onDragEnter: this.onDragEnter.bind(this),
            onDragLeave: this.onDragLeave.bind(this),
            onDragStop: this.onDragStop.bind(this),
            isInterestedIn: this.isInterestedIn.bind(this)
        };
        this.beans.dragAndDropService.addDropTarget(this.dropTarget);
    };
    BaseDropZonePanel.prototype.isInterestedIn = function (type) {
        // not interested in row drags
        return type === DragSourceType.HeaderCell || type === DragSourceType.ToolPanel;
    };
    BaseDropZonePanel.prototype.minimumAllowedNewInsertIndex = function () {
        var numberOfLockedCols = this.gridOptionsService.get('groupLockGroupColumns');
        var numberOfGroupCols = this.colModel.getRowGroupColumns().length;
        if (numberOfLockedCols === -1) {
            return numberOfGroupCols;
        }
        return Math.min(numberOfLockedCols, numberOfGroupCols);
    };
    BaseDropZonePanel.prototype.checkInsertIndex = function (draggingEvent) {
        var newIndex = this.getNewInsertIndex(draggingEvent);
        // <0 happens when drag is no a direction we are interested in, eg drag is up/down but in horizontal panel
        if (newIndex < 0) {
            return false;
        }
        var minimumAllowedIndex = this.minimumAllowedNewInsertIndex();
        var newAdjustedIndex = Math.max(minimumAllowedIndex, newIndex);
        var changed = newAdjustedIndex !== this.insertIndex;
        if (changed) {
            this.insertIndex = newAdjustedIndex;
        }
        return changed;
    };
    BaseDropZonePanel.prototype.getNewInsertIndex = function (draggingEvent) {
        var _this = this;
        var mouseEvent = draggingEvent.event;
        var mouseLocation = this.horizontal ? mouseEvent.clientX : mouseEvent.clientY;
        var boundsList = this.childColumnComponents.map(function (col) { return (col.getGui().getBoundingClientRect()); });
        // find the non-ghost component we're hovering
        var hoveredIndex = boundsList.findIndex(function (rect) { return (_this.horizontal ? (rect.right > mouseLocation && rect.left < mouseLocation) : (rect.top < mouseLocation && rect.bottom > mouseLocation)); });
        // not hovering a non-ghost component
        if (hoveredIndex === -1) {
            var enableRtl = this.beans.gridOptionsService.get('enableRtl');
            // if mouse is below or right of all components then new index should be placed last
            var isLast = boundsList.every(function (rect) { return (mouseLocation > (_this.horizontal ? rect.right : rect.bottom)); });
            if (isLast) {
                return enableRtl && this.horizontal ? 0 : this.childColumnComponents.length;
            }
            // if mouse is above or left of all components, new index is first
            var isFirst = boundsList.every(function (rect) { return (mouseLocation < (_this.horizontal ? rect.left : rect.top)); });
            if (isFirst) {
                return enableRtl && this.horizontal ? this.childColumnComponents.length : 0;
            }
            // must be hovering a ghost, don't change the index
            return this.insertIndex;
        }
        // if the old index is equal to or less than the index of our new target
        // we need to shift right, to insert after rather than before
        if (this.insertIndex <= hoveredIndex) {
            return hoveredIndex + 1;
        }
        return hoveredIndex;
    };
    BaseDropZonePanel.prototype.checkDragStartedBySelf = function (draggingEvent) {
        if (this.state !== BaseDropZonePanel.STATE_NOT_DRAGGING) {
            return;
        }
        this.state = BaseDropZonePanel.STATE_REARRANGE_COLUMNS;
        this.potentialDndColumns = draggingEvent.dragSource.getDragItem().columns || [];
        this.refreshGui();
        this.checkInsertIndex(draggingEvent);
        this.refreshGui();
    };
    BaseDropZonePanel.prototype.onDragging = function (draggingEvent) {
        this.checkDragStartedBySelf(draggingEvent);
        if (this.checkInsertIndex(draggingEvent)) {
            this.refreshGui();
        }
    };
    BaseDropZonePanel.prototype.onDragEnter = function (draggingEvent) {
        var _this = this;
        // this will contain all columns that are potential drops
        var dragColumns = draggingEvent.dragSource.getDragItem().columns || [];
        this.state = BaseDropZonePanel.STATE_NEW_COLUMNS_IN;
        // take out columns that are not droppable
        var goodDragColumns = dragColumns.filter(function (col) { return _this.isColumnDroppable(col, draggingEvent); });
        var alreadyPresent = goodDragColumns.every(function (col) { return _this.childColumnComponents.map(function (cmp) { return cmp.getColumn(); }).indexOf(col) !== -1; });
        if (goodDragColumns.length === 0) {
            return;
        }
        this.potentialDndColumns = goodDragColumns;
        if (alreadyPresent) {
            this.state = BaseDropZonePanel.STATE_NOT_DRAGGING;
            return;
        }
        var hideColumnOnExit = this.isRowGroupPanel() && !this.gridOptionsService.get('suppressRowGroupHidesColumns') && !draggingEvent.fromNudge;
        if (hideColumnOnExit) {
            var dragItem = draggingEvent.dragSource.getDragItem();
            var columns = dragItem.columns;
            this.setColumnsVisible(columns, false, "uiColumnDragged");
        }
        this.checkInsertIndex(draggingEvent);
        this.refreshGui();
    };
    BaseDropZonePanel.prototype.setColumnsVisible = function (columns, visible, source) {
        if (source === void 0) { source = "api"; }
        if (columns) {
            var allowedCols = columns.filter(function (c) { return !c.getColDef().lockVisible; });
            this.colModel.setColumnsVisible(allowedCols, visible, source);
        }
    };
    BaseDropZonePanel.prototype.isPotentialDndColumns = function () {
        return _.existsAndNotEmpty(this.potentialDndColumns);
    };
    BaseDropZonePanel.prototype.isRowGroupPanel = function () {
        return this.dropZonePurpose === 'rowGroup';
    };
    BaseDropZonePanel.prototype.onDragLeave = function (draggingEvent) {
        // if the dragging started from us, we remove the group, however if it started
        // some place else, then we don't, as it was only 'asking'
        if (this.state === BaseDropZonePanel.STATE_REARRANGE_COLUMNS) {
            var columns = draggingEvent.dragSource.getDragItem().columns || [];
            this.removeColumns(columns);
        }
        if (this.isPotentialDndColumns()) {
            var showColumnOnExit = this.isRowGroupPanel() && !this.gridOptionsService.get('suppressMakeColumnVisibleAfterUnGroup') && !draggingEvent.fromNudge;
            if (showColumnOnExit) {
                var dragItem = draggingEvent.dragSource.getDragItem();
                this.setColumnsVisible(dragItem.columns, true, "uiColumnDragged");
            }
            this.potentialDndColumns = [];
            this.refreshGui();
        }
        this.state = BaseDropZonePanel.STATE_NOT_DRAGGING;
    };
    BaseDropZonePanel.prototype.onDragStop = function () {
        if (this.isPotentialDndColumns()) {
            var success = false;
            if (this.state === BaseDropZonePanel.STATE_NEW_COLUMNS_IN) {
                this.addColumns(this.potentialDndColumns);
                success = true;
            }
            else {
                success = this.rearrangeColumns(this.potentialDndColumns);
            }
            this.potentialDndColumns = [];
            // If the function is passive, then we don't refresh, as we assume the client application
            // is going to call setRowGroups / setPivots / setValues at a later point which will then
            // cause a refresh. This gives a nice GUI where the ghost stays until the app has caught
            // up with the changes. However, if there was no change in the order, then we do need to
            // refresh to reset the columns
            if (!this.beans.gridOptionsService.get('functionsPassive') || !success) {
                this.refreshGui();
            }
        }
        this.state = BaseDropZonePanel.STATE_NOT_DRAGGING;
    };
    BaseDropZonePanel.prototype.removeColumns = function (columnsToRemove) {
        var newColumnList = this.getExistingColumns().filter(function (col) { return !_.includes(columnsToRemove, col); });
        this.updateColumns(newColumnList);
    };
    BaseDropZonePanel.prototype.addColumns = function (columnsToAdd) {
        if (!columnsToAdd) {
            return;
        }
        var newColumnList = this.getExistingColumns().slice();
        var colsToAddNoDuplicates = columnsToAdd.filter(function (col) { return newColumnList.indexOf(col) < 0; });
        _.insertArrayIntoArray(newColumnList, colsToAddNoDuplicates, this.insertIndex);
        this.updateColumns(newColumnList);
    };
    BaseDropZonePanel.prototype.rearrangeColumns = function (columnsToAdd) {
        var newColumnList = this.getNonGhostColumns().slice();
        _.insertArrayIntoArray(newColumnList, columnsToAdd, this.insertIndex);
        if (_.areEqual(newColumnList, this.getExistingColumns())) {
            return false;
        }
        this.updateColumns(newColumnList);
        return true;
    };
    BaseDropZonePanel.prototype.refreshGui = function () {
        // we reset the scroll position after the refresh.
        // if we don't do this, then the list will always scroll to the top
        // each time we refresh it. this is because part of the refresh empties
        // out the list which sets scroll to zero. so the user could be just
        // reordering the list - we want to prevent the resetting of the scroll.
        // this is relevant for vertical display only (as horizontal has no scroll)
        var scrollTop = this.eColumnDropList.scrollTop;
        var resizeEnabled = this.resizeEnabled;
        var focusedIndex = this.getFocusedItem();
        var alternateElement = this.focusService.findNextFocusableElement();
        if (!alternateElement) {
            alternateElement = this.focusService.findNextFocusableElement(undefined, false, true);
        }
        this.toggleResizable(false);
        this.destroyGui();
        this.addIconAndTitleToGui();
        this.addEmptyMessageToGui();
        this.addColumnsToGui();
        if (!this.isHorizontal()) {
            this.eColumnDropList.scrollTop = scrollTop;
        }
        if (resizeEnabled) {
            this.toggleResizable(resizeEnabled);
        }
        // focus should only be restored when keyboard mode
        // otherwise mouse clicks will cause containers to scroll
        // without no apparent reason.
        if (this.focusService.isKeyboardMode()) {
            this.restoreFocus(focusedIndex, alternateElement);
        }
    };
    BaseDropZonePanel.prototype.getFocusedItem = function () {
        var eGui = this.getGui();
        var activeElement = this.gridOptionsService.getDocument().activeElement;
        if (!eGui.contains(activeElement)) {
            return -1;
        }
        var items = Array.from(eGui.querySelectorAll('.ag-column-drop-cell'));
        return items.indexOf(activeElement);
    };
    BaseDropZonePanel.prototype.restoreFocus = function (index, alternateElement) {
        var eGui = this.getGui();
        var items = Array.from(eGui.querySelectorAll('.ag-column-drop-cell'));
        if (index === -1) {
            return;
        }
        if (items.length === 0) {
            alternateElement.focus();
        }
        var indexToFocus = Math.min(items.length - 1, index);
        var el = items[indexToFocus];
        if (el) {
            el.focus();
        }
    };
    BaseDropZonePanel.prototype.getNonGhostColumns = function () {
        var _this = this;
        var existingColumns = this.getExistingColumns();
        if (this.isPotentialDndColumns()) {
            return existingColumns.filter(function (column) { return !_.includes(_this.potentialDndColumns, column); });
        }
        return existingColumns;
    };
    BaseDropZonePanel.prototype.addColumnsToGui = function () {
        var _this = this;
        var nonGhostColumns = this.getNonGhostColumns();
        var itemsToAddToGui = nonGhostColumns.map(function (column) { return (_this.createColumnComponent(column, false)); });
        if (this.isPotentialDndColumns()) {
            var dndColumns = this.potentialDndColumns.map(function (column) { return (_this.createColumnComponent(column, true)); });
            if (this.insertIndex >= itemsToAddToGui.length) {
                itemsToAddToGui.push.apply(itemsToAddToGui, __spreadArray$j([], __read$x(dndColumns), false));
            }
            else {
                itemsToAddToGui.splice.apply(itemsToAddToGui, __spreadArray$j([this.insertIndex, 0], __read$x(dndColumns), false));
            }
        }
        this.appendChild(this.eColumnDropList);
        itemsToAddToGui.forEach(function (columnComponent, index) {
            if (index > 0) {
                _this.addArrow(_this.eColumnDropList);
            }
            _this.eColumnDropList.appendChild(columnComponent.getGui());
        });
        this.addAriaLabelsToComponents();
    };
    BaseDropZonePanel.prototype.addAriaLabelsToComponents = function () {
        var _this = this;
        this.childColumnComponents.forEach(function (comp, idx) {
            var eGui = comp.getGui();
            _.setAriaPosInSet(eGui, idx + 1);
            _.setAriaSetSize(eGui, _this.childColumnComponents.length);
        });
    };
    BaseDropZonePanel.prototype.createColumnComponent = function (column, ghost) {
        var _this = this;
        var columnComponent = new DropZoneColumnComp(column, this.dropTarget, ghost, this.dropZonePurpose, this.horizontal);
        columnComponent.addEventListener(DropZoneColumnComp.EVENT_COLUMN_REMOVE, this.removeColumns.bind(this, [column]));
        this.beans.context.createBean(columnComponent);
        this.guiDestroyFunctions.push(function () { return _this.destroyBean(columnComponent); });
        if (!ghost) {
            this.childColumnComponents.push(columnComponent);
        }
        return columnComponent;
    };
    BaseDropZonePanel.prototype.addIconAndTitleToGui = function () {
        var eGroupIcon = this.params.icon;
        var eTitleBar = document.createElement('div');
        _.setAriaHidden(eTitleBar, true);
        this.addElementClasses(eTitleBar, 'title-bar');
        this.addElementClasses(eGroupIcon, 'icon');
        this.addOrRemoveCssClass('ag-column-drop-empty', this.isExistingColumnsEmpty());
        eTitleBar.appendChild(eGroupIcon);
        if (!this.horizontal) {
            var eTitle = document.createElement('span');
            this.addElementClasses(eTitle, 'title');
            eTitle.innerHTML = this.params.title;
            eTitleBar.appendChild(eTitle);
        }
        this.appendChild(eTitleBar);
    };
    BaseDropZonePanel.prototype.isExistingColumnsEmpty = function () {
        return this.getExistingColumns().length === 0;
    };
    BaseDropZonePanel.prototype.addEmptyMessageToGui = function () {
        if (!this.isExistingColumnsEmpty() || this.isPotentialDndColumns()) {
            return;
        }
        var eMessage = document.createElement('span');
        eMessage.innerHTML = this.params.emptyMessage;
        this.addElementClasses(eMessage, 'empty-message');
        this.eColumnDropList.appendChild(eMessage);
    };
    BaseDropZonePanel.prototype.addArrow = function (eParent) {
        // only add the arrows if the layout is horizontal
        if (this.horizontal) {
            // for RTL it's a left arrow, otherwise it's a right arrow
            var enableRtl = this.beans.gridOptionsService.get('enableRtl');
            var icon = _.createIconNoSpan(enableRtl ? 'smallLeft' : 'smallRight', this.beans.gridOptionsService);
            this.addElementClasses(icon, 'cell-separator');
            eParent.appendChild(icon);
        }
    };
    BaseDropZonePanel.STATE_NOT_DRAGGING = 'notDragging';
    BaseDropZonePanel.STATE_NEW_COLUMNS_IN = 'newColumnsIn';
    BaseDropZonePanel.STATE_REARRANGE_COLUMNS = 'rearrangeColumns';
    __decorate$1z([
        Autowired('columnModel')
    ], BaseDropZonePanel.prototype, "colModel", void 0);
    __decorate$1z([
        Autowired('focusService')
    ], BaseDropZonePanel.prototype, "focusService", void 0);
    return BaseDropZonePanel;
}(Component));

var __extends$23 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1y = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var RowGroupDropZonePanel = /** @class */ (function (_super) {
    __extends$23(RowGroupDropZonePanel, _super);
    function RowGroupDropZonePanel(horizontal) {
        return _super.call(this, horizontal, 'rowGroup') || this;
    }
    RowGroupDropZonePanel.prototype.passBeansUp = function () {
        _super.prototype.setBeans.call(this, {
            gridOptionsService: this.gridOptionsService,
            eventService: this.eventService,
            context: this.getContext(),
            loggerFactory: this.loggerFactory,
            dragAndDropService: this.dragAndDropService
        });
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        var emptyMessage = localeTextFunc('rowGroupColumnsEmptyMessage', 'Drag here to set row groups');
        var title = localeTextFunc('groups', 'Row Groups');
        _super.prototype.init.call(this, {
            dragAndDropIcon: DragAndDropService.ICON_GROUP,
            icon: _.createIconNoSpan('rowGroupPanel', this.gridOptionsService, null),
            emptyMessage: emptyMessage,
            title: title
        });
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_ROW_GROUP_CHANGED, this.refreshGui.bind(this));
    };
    RowGroupDropZonePanel.prototype.getAriaLabel = function () {
        var translate = this.localeService.getLocaleTextFunc();
        var label = translate('ariaRowGroupDropZonePanelLabel', 'Row Groups');
        return label;
    };
    RowGroupDropZonePanel.prototype.getTooltipParams = function () {
        var res = _super.prototype.getTooltipParams.call(this);
        res.location = 'rowGroupColumnsList';
        return res;
    };
    RowGroupDropZonePanel.prototype.isColumnDroppable = function (column, draggingEvent) {
        // we never allow grouping of secondary columns
        if (this.gridOptionsService.get('functionsReadOnly') || !column.isPrimary()) {
            return false;
        }
        return column.isAllowRowGroup() && (!column.isRowGroupActive() || this.isSourceEventFromTarget(draggingEvent));
    };
    RowGroupDropZonePanel.prototype.updateColumns = function (columns) {
        if (this.gridOptionsService.get('functionsPassive')) {
            var event_1 = {
                type: Events.EVENT_COLUMN_ROW_GROUP_CHANGE_REQUEST,
                columns: columns
            };
            this.eventService.dispatchEvent(event_1);
        }
        else {
            this.columnModel.setRowGroupColumns(columns, "toolPanelUi");
        }
    };
    RowGroupDropZonePanel.prototype.getIconName = function () {
        return this.isPotentialDndColumns() ? DragAndDropService.ICON_GROUP : DragAndDropService.ICON_NOT_ALLOWED;
    };
    RowGroupDropZonePanel.prototype.getExistingColumns = function () {
        return this.columnModel.getRowGroupColumns();
    };
    __decorate$1y([
        Autowired('columnModel')
    ], RowGroupDropZonePanel.prototype, "columnModel", void 0);
    __decorate$1y([
        Autowired('loggerFactory')
    ], RowGroupDropZonePanel.prototype, "loggerFactory", void 0);
    __decorate$1y([
        Autowired('dragAndDropService')
    ], RowGroupDropZonePanel.prototype, "dragAndDropService", void 0);
    __decorate$1y([
        PostConstruct
    ], RowGroupDropZonePanel.prototype, "passBeansUp", null);
    return RowGroupDropZonePanel;
}(BaseDropZonePanel));

var __extends$22 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1x = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var PivotDropZonePanel = /** @class */ (function (_super) {
    __extends$22(PivotDropZonePanel, _super);
    function PivotDropZonePanel(horizontal) {
        return _super.call(this, horizontal, 'pivot') || this;
    }
    PivotDropZonePanel.prototype.passBeansUp = function () {
        _super.prototype.setBeans.call(this, {
            gridOptionsService: this.gridOptionsService,
            eventService: this.eventService,
            context: this.getContext(),
            loggerFactory: this.loggerFactory,
            dragAndDropService: this.dragAndDropService
        });
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        var emptyMessage = localeTextFunc('pivotColumnsEmptyMessage', 'Drag here to set column labels');
        var title = localeTextFunc('pivots', 'Column Labels');
        _super.prototype.init.call(this, {
            dragAndDropIcon: DragAndDropService.ICON_GROUP,
            icon: _.createIconNoSpan('pivotPanel', this.gridOptionsService, null),
            emptyMessage: emptyMessage,
            title: title
        });
        this.addManagedListener(this.eventService, Events.EVENT_NEW_COLUMNS_LOADED, this.refresh.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_PIVOT_CHANGED, this.refresh.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_PIVOT_MODE_CHANGED, this.checkVisibility.bind(this));
        this.refresh();
    };
    PivotDropZonePanel.prototype.getAriaLabel = function () {
        var translate = this.localeService.getLocaleTextFunc();
        var label = translate('ariaPivotDropZonePanelLabel', 'Column Labels');
        return label;
    };
    PivotDropZonePanel.prototype.getTooltipParams = function () {
        var res = _super.prototype.getTooltipParams.call(this);
        res.location = 'pivotColumnsList';
        return res;
    };
    PivotDropZonePanel.prototype.refresh = function () {
        this.checkVisibility();
        this.refreshGui();
    };
    PivotDropZonePanel.prototype.checkVisibility = function () {
        var pivotMode = this.columnModel.isPivotMode();
        if (this.isHorizontal()) {
            // what we do for horizontal (ie the pivot panel at the top) depends
            // on the user property as well as pivotMode.
            switch (this.gridOptionsService.get('pivotPanelShow')) {
                case 'always':
                    this.setDisplayed(pivotMode);
                    break;
                case 'onlyWhenPivoting':
                    var pivotActive = this.columnModel.isPivotActive();
                    this.setDisplayed(pivotMode && pivotActive);
                    break;
                default:
                    // never show it
                    this.setDisplayed(false);
                    break;
            }
        }
        else {
            // in toolPanel, the pivot panel is always shown when pivot mode is on
            this.setDisplayed(pivotMode);
        }
    };
    PivotDropZonePanel.prototype.isColumnDroppable = function (column, draggingEvent) {
        // we never allow grouping of secondary columns
        if (this.gridOptionsService.get('functionsReadOnly') || !column.isPrimary()) {
            return false;
        }
        return column.isAllowPivot() && (!column.isPivotActive() || this.isSourceEventFromTarget(draggingEvent));
    };
    PivotDropZonePanel.prototype.updateColumns = function (columns) {
        if (this.gridOptionsService.get('functionsPassive')) {
            var event_1 = {
                type: Events.EVENT_COLUMN_PIVOT_CHANGE_REQUEST,
                columns: columns
            };
            this.eventService.dispatchEvent(event_1);
        }
        else {
            this.columnModel.setPivotColumns(columns, "toolPanelUi");
        }
    };
    PivotDropZonePanel.prototype.getIconName = function () {
        return this.isPotentialDndColumns() ? DragAndDropService.ICON_PIVOT : DragAndDropService.ICON_NOT_ALLOWED;
    };
    PivotDropZonePanel.prototype.getExistingColumns = function () {
        return this.columnModel.getPivotColumns();
    };
    __decorate$1x([
        Autowired('columnModel')
    ], PivotDropZonePanel.prototype, "columnModel", void 0);
    __decorate$1x([
        Autowired('loggerFactory')
    ], PivotDropZonePanel.prototype, "loggerFactory", void 0);
    __decorate$1x([
        Autowired('dragAndDropService')
    ], PivotDropZonePanel.prototype, "dragAndDropService", void 0);
    __decorate$1x([
        PostConstruct
    ], PivotDropZonePanel.prototype, "passBeansUp", null);
    return PivotDropZonePanel;
}(BaseDropZonePanel));

var __extends$21 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1w = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var GridHeaderDropZones = /** @class */ (function (_super) {
    __extends$21(GridHeaderDropZones, _super);
    function GridHeaderDropZones() {
        return _super.call(this) || this;
    }
    GridHeaderDropZones.prototype.postConstruct = function () {
        var _this = this;
        this.setGui(this.createNorthPanel());
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_ROW_GROUP_CHANGED, function () { return _this.onRowGroupChanged(); });
        this.addManagedListener(this.eventService, Events.EVENT_NEW_COLUMNS_LOADED, function () { return _this.onRowGroupChanged(); });
        this.addManagedPropertyListener('rowGroupPanelShow', function () { return _this.onRowGroupChanged(); });
        this.addManagedPropertyListener('pivotPanelShow', function () { return _this.onPivotPanelShow(); });
        this.onRowGroupChanged();
    };
    GridHeaderDropZones.prototype.createNorthPanel = function () {
        var _this = this;
        var topPanelGui = document.createElement('div');
        topPanelGui.classList.add('ag-column-drop-wrapper');
        _.setAriaRole(topPanelGui, 'presentation');
        this.rowGroupComp = new RowGroupDropZonePanel(true);
        this.createManagedBean(this.rowGroupComp);
        this.pivotComp = new PivotDropZonePanel(true);
        this.createManagedBean(this.pivotComp);
        topPanelGui.appendChild(this.rowGroupComp.getGui());
        topPanelGui.appendChild(this.pivotComp.getGui());
        this.addManagedListener(this.rowGroupComp, Component.EVENT_DISPLAYED_CHANGED, function () { return _this.onDropPanelVisible(); });
        this.addManagedListener(this.pivotComp, Component.EVENT_DISPLAYED_CHANGED, function () { return _this.onDropPanelVisible(); });
        this.onDropPanelVisible();
        return topPanelGui;
    };
    GridHeaderDropZones.prototype.onDropPanelVisible = function () {
        var bothDisplayed = this.rowGroupComp.isDisplayed() && this.pivotComp.isDisplayed();
        this.rowGroupComp.addOrRemoveCssClass('ag-column-drop-horizontal-half-width', bothDisplayed);
        this.pivotComp.addOrRemoveCssClass('ag-column-drop-horizontal-half-width', bothDisplayed);
    };
    GridHeaderDropZones.prototype.onRowGroupChanged = function () {
        if (!this.rowGroupComp) {
            return;
        }
        var rowGroupPanelShow = this.gridOptionsService.get('rowGroupPanelShow');
        if (rowGroupPanelShow === 'always') {
            this.rowGroupComp.setDisplayed(true);
        }
        else if (rowGroupPanelShow === 'onlyWhenGrouping') {
            var grouping = !this.columnModel.isRowGroupEmpty();
            this.rowGroupComp.setDisplayed(grouping);
        }
        else {
            this.rowGroupComp.setDisplayed(false);
        }
    };
    GridHeaderDropZones.prototype.onPivotPanelShow = function () {
        if (!this.pivotComp) {
            return;
        }
        var pivotPanelShow = this.gridOptionsService.get('pivotPanelShow');
        if (pivotPanelShow === 'always') {
            this.pivotComp.setDisplayed(true);
        }
        else if (pivotPanelShow === 'onlyWhenPivoting') {
            var pivoting = this.columnModel.isPivotActive();
            this.pivotComp.setDisplayed(pivoting);
        }
        else {
            this.pivotComp.setDisplayed(false);
        }
    };
    __decorate$1w([
        Autowired('columnModel')
    ], GridHeaderDropZones.prototype, "columnModel", void 0);
    __decorate$1w([
        PostConstruct
    ], GridHeaderDropZones.prototype, "postConstruct", null);
    return GridHeaderDropZones;
}(Component));

var __extends$20 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1v = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var FilterAggregatesStage = /** @class */ (function (_super) {
    __extends$20(FilterAggregatesStage, _super);
    function FilterAggregatesStage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FilterAggregatesStage.prototype.execute = function (params) {
        var _this = this;
        var isPivotMode = this.columnModel.isPivotMode();
        var isAggFilterActive = this.filterManager.isAggregateFilterPresent()
            || this.filterManager.isAggregateQuickFilterPresent();
        // This is the default filter for applying only to leaf nodes, realistically this should not apply as primary agg columns,
        // should not be applied by the filterManager if getGroupAggFiltering is missing. Predicate will apply filters to leaf level.
        var defaultPrimaryColumnPredicate = function (params) { return !params.node.group; };
        // Default secondary column predicate, selecting only leaf level groups.
        var defaultSecondaryColumnPredicate = (function (params) { return params.node.leafGroup; });
        // The predicate to determine whether filters should apply to this row. Either defined by the user in groupAggFiltering or a default depending
        // on current pivot mode status.
        var applyFilterToNode = this.gridOptionsService.getGroupAggFiltering()
            || (isPivotMode ? defaultSecondaryColumnPredicate : defaultPrimaryColumnPredicate);
        var changedPath = params.changedPath;
        var preserveChildren = function (node, recursive) {
            if (recursive === void 0) { recursive = false; }
            if (node.childrenAfterFilter) {
                node.childrenAfterAggFilter = node.childrenAfterFilter;
                if (recursive) {
                    node.childrenAfterAggFilter.forEach(function (child) { return preserveChildren(child, recursive); });
                }
                _this.setAllChildrenCount(node);
            }
            if (node.sibling) {
                node.sibling.childrenAfterAggFilter = node.childrenAfterAggFilter;
            }
        };
        var filterChildren = function (node) {
            var _a;
            node.childrenAfterAggFilter = ((_a = node.childrenAfterFilter) === null || _a === void 0 ? void 0 : _a.filter(function (child) {
                var _a;
                var shouldFilterRow = applyFilterToNode({ node: child });
                if (shouldFilterRow) {
                    var doesNodePassFilter = _this.filterManager.doesRowPassAggregateFilters({ rowNode: child });
                    if (doesNodePassFilter) {
                        // Node has passed, so preserve children
                        preserveChildren(child, true);
                        return true;
                    }
                }
                var hasChildPassed = (_a = child.childrenAfterAggFilter) === null || _a === void 0 ? void 0 : _a.length;
                return hasChildPassed;
            })) || null;
            _this.setAllChildrenCount(node);
            if (node.sibling) {
                node.sibling.childrenAfterAggFilter = node.childrenAfterAggFilter;
            }
        };
        changedPath.forEachChangedNodeDepthFirst(isAggFilterActive ? filterChildren : preserveChildren, true);
    };
    FilterAggregatesStage.prototype.setAllChildrenCountTreeData = function (rowNode) {
        // for tree data, we include all children, groups and leafs
        var allChildrenCount = 0;
        rowNode.childrenAfterAggFilter.forEach(function (child) {
            // include child itself
            allChildrenCount++;
            // include children of children
            allChildrenCount += child.allChildrenCount;
        });
        rowNode.setAllChildrenCount(allChildrenCount);
    };
    FilterAggregatesStage.prototype.setAllChildrenCountGridGrouping = function (rowNode) {
        // for grid data, we only count the leafs
        var allChildrenCount = 0;
        rowNode.childrenAfterAggFilter.forEach(function (child) {
            if (child.group) {
                allChildrenCount += child.allChildrenCount;
            }
            else {
                allChildrenCount++;
            }
        });
        rowNode.setAllChildrenCount(allChildrenCount);
    };
    FilterAggregatesStage.prototype.setAllChildrenCount = function (rowNode) {
        if (!rowNode.hasChildren()) {
            rowNode.setAllChildrenCount(null);
            return;
        }
        if (this.gridOptionsService.get('treeData')) {
            this.setAllChildrenCountTreeData(rowNode);
        }
        else {
            this.setAllChildrenCountGridGrouping(rowNode);
        }
    };
    __decorate$1v([
        Autowired('filterManager')
    ], FilterAggregatesStage.prototype, "filterManager", void 0);
    __decorate$1v([
        Autowired('columnModel')
    ], FilterAggregatesStage.prototype, "columnModel", void 0);
    FilterAggregatesStage = __decorate$1v([
        Bean('filterAggregatesStage')
    ], FilterAggregatesStage);
    return FilterAggregatesStage;
}(BeanStub));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$g = '31.0.2';

var __extends$1$ = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1u = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var GroupFilter = /** @class */ (function (_super) {
    __extends$1$(GroupFilter, _super);
    function GroupFilter() {
        return _super.call(this, /* html */ "\n            <div class=\"ag-group-filter\">\n                <div ref=\"eGroupField\"></div>\n                <div ref=\"eUnderlyingFilter\"></div>\n            </div>\n        ") || this;
    }
    GroupFilter.prototype.postConstruct = function () {
        this.initialiseTabGuard({});
    };
    GroupFilter.prototype.init = function (params) {
        var _this = this;
        this.params = params;
        this.validateParams();
        return this.updateGroups().then(function () {
            _this.addManagedListener(_this.eventService, Events.EVENT_COLUMN_ROW_GROUP_CHANGED, function () { return _this.onColumnRowGroupChanged(); });
        });
    };
    GroupFilter.prototype.validateParams = function () {
        var colDef = this.params.colDef;
        if (colDef.field) {
            _.warnOnce('Group Column Filter does not work with the colDef property "field". This property will be ignored.');
        }
        if (colDef.filterValueGetter) {
            _.warnOnce('Group Column Filter does not work with the colDef property "filterValueGetter". This property will be ignored.');
        }
        if (colDef.filterParams) {
            _.warnOnce('Group Column Filter does not work with the colDef property "filterParams". This property will be ignored.');
        }
    };
    GroupFilter.prototype.updateGroups = function () {
        var sourceColumns = this.updateGroupField();
        return this.getUnderlyingFilters(sourceColumns);
    };
    GroupFilter.prototype.getSourceColumns = function () {
        this.groupColumn = this.params.column;
        if (this.gridOptionsService.get('treeData')) {
            _.warnOnce('Group Column Filter does not work with Tree Data enabled. Please disable Tree Data, or use a different filter.');
            return [];
        }
        var sourceColumns = this.columnModel.getSourceColumnsForGroupColumn(this.groupColumn);
        if (!sourceColumns) {
            _.warnOnce('Group Column Filter only works on group columns. Please use a different filter.');
            return [];
        }
        return sourceColumns;
    };
    GroupFilter.prototype.updateGroupField = function () {
        var _this = this;
        _.clearElement(this.eGroupField);
        if (this.eGroupFieldSelect) {
            this.destroyBean(this.eGroupFieldSelect);
        }
        var allSourceColumns = this.getSourceColumns();
        var sourceColumns = allSourceColumns.filter(function (sourceColumn) { return sourceColumn.isFilterAllowed(); });
        if (!sourceColumns.length) {
            this.selectedColumn = undefined;
            _.setDisplayed(this.eGroupField, false);
            return null;
        }
        if (allSourceColumns.length === 1) {
            // we only want to hide the group field element if there's only one group column.
            // If there's one group column that has a filter, but multiple columns in total,
            // we should still show the select so the user knows which column it's for.
            this.selectedColumn = sourceColumns[0];
            _.setDisplayed(this.eGroupField, false);
        }
        else {
            // keep the old selected column if it's still valid
            if (!this.selectedColumn || !sourceColumns.some(function (column) { return column.getId() === _this.selectedColumn.getId(); })) {
                this.selectedColumn = sourceColumns[0];
            }
            this.createGroupFieldSelectElement(sourceColumns);
            this.eGroupField.appendChild(this.eGroupFieldSelect.getGui());
            this.eGroupField.appendChild(_.loadTemplate(/* html */ "<div class=\"ag-filter-separator\"></div>"));
            _.setDisplayed(this.eGroupField, true);
        }
        return sourceColumns;
    };
    GroupFilter.prototype.createGroupFieldSelectElement = function (sourceColumns) {
        var _this = this;
        this.eGroupFieldSelect = this.createManagedBean(new AgSelect());
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        this.eGroupFieldSelect.setLabel(localeTextFunc('groupFilterSelect', 'Select field:'));
        this.eGroupFieldSelect.setLabelAlignment('top');
        this.eGroupFieldSelect.addOptions(sourceColumns.map(function (sourceColumn) {
            var _a;
            return ({
                value: sourceColumn.getId(),
                text: (_a = _this.columnModel.getDisplayNameForColumn(sourceColumn, 'groupFilter', false)) !== null && _a !== void 0 ? _a : undefined
            });
        }));
        this.eGroupFieldSelect.setValue(this.selectedColumn.getId());
        this.eGroupFieldSelect.onValueChange(function (newValue) { return _this.updateSelectedColumn(newValue); });
        this.eGroupFieldSelect.addCssClass('ag-group-filter-field-select-wrapper');
        if (sourceColumns.length === 1) {
            this.eGroupFieldSelect.setDisabled(true);
        }
    };
    GroupFilter.prototype.getUnderlyingFilters = function (sourceColumns) {
        var _this = this;
        if (!sourceColumns) {
            this.filterColumnPairs = undefined;
            this.selectedFilter = undefined;
            this.groupColumn.setFilterActive(false, 'columnRowGroupChanged');
            return AgPromise.resolve();
        }
        var filterPromises = [];
        var filterColumnPairs = [];
        sourceColumns.forEach(function (column) {
            var filterWrapper = _this.filterManager.getOrCreateFilterWrapper(column, 'COLUMN_MENU');
            if (filterWrapper === null || filterWrapper === void 0 ? void 0 : filterWrapper.filterPromise) {
                filterPromises.push(filterWrapper.filterPromise.then(function (filter) {
                    if (filter) {
                        filterColumnPairs.push({
                            filter: filter,
                            column: column
                        });
                    }
                    if (column.getId() === _this.selectedColumn.getId()) {
                        _this.selectedFilter = filter !== null && filter !== void 0 ? filter : undefined;
                    }
                    return filter;
                }));
            }
        });
        return AgPromise.all(filterPromises).then(function () {
            _this.filterColumnPairs = filterColumnPairs;
            _this.groupColumn.setFilterActive(_this.isFilterActive(), 'columnRowGroupChanged');
        });
    };
    GroupFilter.prototype.addUnderlyingFilterElement = function () {
        var _this = this;
        _.clearElement(this.eUnderlyingFilter);
        if (!this.selectedColumn) {
            return AgPromise.resolve();
        }
        var filterWrapper = this.filterManager.getOrCreateFilterWrapper(this.selectedColumn, 'COLUMN_MENU');
        if (!filterWrapper) {
            return AgPromise.resolve();
        }
        return filterWrapper.guiPromise.then(function (gui) {
            var _a;
            _this.eUnderlyingFilter.appendChild(gui);
            (_a = filterWrapper.filterPromise) === null || _a === void 0 ? void 0 : _a.then(function (filter) {
                var _a, _b;
                (_a = filter === null || filter === void 0 ? void 0 : filter.afterGuiAttached) === null || _a === void 0 ? void 0 : _a.call(filter, _this.afterGuiAttachedParams);
                if (!((_b = _this.afterGuiAttachedParams) === null || _b === void 0 ? void 0 : _b.suppressFocus) && _this.eGroupFieldSelect && !_this.eGroupFieldSelect.isDisabled()) {
                    _this.eGroupFieldSelect.getFocusableElement().focus();
                }
            });
        });
    };
    GroupFilter.prototype.updateSelectedColumn = function (columnId) {
        var _a, _b;
        if (!columnId) {
            return;
        }
        (_b = (_a = this.selectedFilter) === null || _a === void 0 ? void 0 : _a.afterGuiDetached) === null || _b === void 0 ? void 0 : _b.call(_a);
        var selectedFilterColumnPair = this.getFilterColumnPair(columnId);
        this.selectedColumn = selectedFilterColumnPair === null || selectedFilterColumnPair === void 0 ? void 0 : selectedFilterColumnPair.column;
        this.selectedFilter = selectedFilterColumnPair === null || selectedFilterColumnPair === void 0 ? void 0 : selectedFilterColumnPair.filter;
        this.dispatchEvent({
            type: GroupFilter.EVENT_SELECTED_COLUMN_CHANGED
        });
        this.addUnderlyingFilterElement();
    };
    GroupFilter.prototype.isFilterActive = function () {
        var _a;
        return !!((_a = this.filterColumnPairs) === null || _a === void 0 ? void 0 : _a.some(function (_a) {
            var filter = _a.filter;
            return filter.isFilterActive();
        }));
    };
    GroupFilter.prototype.doesFilterPass = function () {
        return true;
    };
    GroupFilter.prototype.getModel = function () {
        return null;
    };
    GroupFilter.prototype.setModel = function () {
        return AgPromise.resolve();
    };
    GroupFilter.prototype.afterGuiAttached = function (params) {
        this.afterGuiAttachedParams = params;
        this.addUnderlyingFilterElement();
    };
    GroupFilter.prototype.afterGuiDetached = function () {
        var _a, _b;
        _.clearElement(this.eUnderlyingFilter);
        (_b = (_a = this.selectedFilter) === null || _a === void 0 ? void 0 : _a.afterGuiDetached) === null || _b === void 0 ? void 0 : _b.call(_a);
    };
    GroupFilter.prototype.onColumnRowGroupChanged = function () {
        var _this = this;
        this.updateGroups().then(function () {
            _this.dispatchEvent({
                type: GroupFilter.EVENT_COLUMN_ROW_GROUP_CHANGED
            });
            _this.eventService.dispatchEvent({
                type: 'filterAllowedUpdated'
            });
        });
    };
    GroupFilter.prototype.getFilterColumnPair = function (columnId) {
        var _a;
        if (!columnId) {
            return undefined;
        }
        return (_a = this.filterColumnPairs) === null || _a === void 0 ? void 0 : _a.find(function (_a) {
            var column = _a.column;
            return column.getId() === columnId;
        });
    };
    GroupFilter.prototype.getSelectedFilter = function () {
        return this.selectedFilter;
    };
    GroupFilter.prototype.getSelectedColumn = function () {
        return this.selectedColumn;
    };
    GroupFilter.prototype.isFilterAllowed = function () {
        return !!this.selectedColumn;
    };
    GroupFilter.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
    };
    GroupFilter.EVENT_COLUMN_ROW_GROUP_CHANGED = 'columnRowGroupChanged';
    GroupFilter.EVENT_SELECTED_COLUMN_CHANGED = 'selectedColumnChanged';
    __decorate$1u([
        Autowired('filterManager')
    ], GroupFilter.prototype, "filterManager", void 0);
    __decorate$1u([
        Autowired('columnModel')
    ], GroupFilter.prototype, "columnModel", void 0);
    __decorate$1u([
        RefSelector('eGroupField')
    ], GroupFilter.prototype, "eGroupField", void 0);
    __decorate$1u([
        RefSelector('eUnderlyingFilter')
    ], GroupFilter.prototype, "eUnderlyingFilter", void 0);
    __decorate$1u([
        PostConstruct
    ], GroupFilter.prototype, "postConstruct", null);
    return GroupFilter;
}(TabGuardComp));

var __extends$1_ = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1t = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var GroupFloatingFilterComp = /** @class */ (function (_super) {
    __extends$1_(GroupFloatingFilterComp, _super);
    function GroupFloatingFilterComp() {
        var _this = _super.call(this, /* html */ "\n            <div ref=\"eFloatingFilter\" class=\"ag-group-floating-filter ag-floating-filter-input\" role=\"presentation\"></div>\n        ") || this;
        _this.haveAddedColumnListeners = false;
        return _this;
    }
    GroupFloatingFilterComp.prototype.init = function (params) {
        var _this = this;
        this.params = params;
        // we only support showing the underlying floating filter for multiple group columns
        var canShowUnderlyingFloatingFilter = this.gridOptionsService.get('groupDisplayType') === 'multipleColumns';
        return new AgPromise(function (resolve) {
            _this.params.parentFilterInstance(function (parentFilterInstance) {
                _this.parentFilterInstance = parentFilterInstance;
                if (canShowUnderlyingFloatingFilter) {
                    _this.setupUnderlyingFloatingFilterElement().then(function () { return resolve(); });
                }
                else {
                    _this.setupReadOnlyFloatingFilterElement();
                    resolve();
                }
            });
        }).then(function () {
            _this.addManagedListener(_this.parentFilterInstance, GroupFilter.EVENT_SELECTED_COLUMN_CHANGED, function () { return _this.onSelectedColumnChanged(); });
            _this.addManagedListener(_this.parentFilterInstance, GroupFilter.EVENT_COLUMN_ROW_GROUP_CHANGED, function () { return _this.onColumnRowGroupChanged(); });
        });
    };
    GroupFloatingFilterComp.prototype.onParamsUpdated = function (params) {
        this.refresh(params);
    };
    GroupFloatingFilterComp.prototype.refresh = function (params) {
        this.params = params;
        this.setParams();
    };
    GroupFloatingFilterComp.prototype.setParams = function () {
        var _a;
        var displayName = this.columnModel.getDisplayNameForColumn(this.params.column, 'header', true);
        var translate = this.localeService.getLocaleTextFunc();
        (_a = this.eFloatingFilterText) === null || _a === void 0 ? void 0 : _a.setInputAriaLabel("".concat(displayName, " ").concat(translate('ariaFilterInput', 'Filter Input')));
    };
    GroupFloatingFilterComp.prototype.setupReadOnlyFloatingFilterElement = function () {
        var _this = this;
        if (!this.eFloatingFilterText) {
            this.eFloatingFilterText = this.createManagedBean(new AgInputTextField());
            this.eFloatingFilterText
                .setDisabled(true)
                .addGuiEventListener('click', function () { return _this.params.showParentFilter(); });
            this.setParams();
        }
        this.updateDisplayedValue();
        this.eFloatingFilter.appendChild(this.eFloatingFilterText.getGui());
    };
    GroupFloatingFilterComp.prototype.setupUnderlyingFloatingFilterElement = function () {
        var _this = this;
        this.showingUnderlyingFloatingFilter = false;
        this.underlyingFloatingFilter = undefined;
        _.clearElement(this.eFloatingFilter);
        var column = this.parentFilterInstance.getSelectedColumn();
        // we can only show the underlying filter if there is one instance (e.g. the underlying column is not visible)
        if (column && !column.isVisible()) {
            var compDetails = this.filterManager.getFloatingFilterCompDetails(column, this.params.showParentFilter);
            if (compDetails) {
                this.compDetails = compDetails;
                if (!this.haveAddedColumnListeners) {
                    this.haveAddedColumnListeners = true;
                    this.addManagedListener(column, Column.EVENT_VISIBLE_CHANGED, this.onColumnVisibleChanged.bind(this));
                    this.addManagedListener(column, Column.EVENT_COL_DEF_CHANGED, this.onColDefChanged.bind(this));
                }
                return compDetails.newAgStackInstance().then(function (floatingFilter) {
                    var _a, _b;
                    _this.underlyingFloatingFilter = floatingFilter;
                    (_a = _this.underlyingFloatingFilter) === null || _a === void 0 ? void 0 : _a.onParentModelChanged((_b = _this.parentFilterInstance.getSelectedFilter()) === null || _b === void 0 ? void 0 : _b.getModel());
                    _this.appendChild(floatingFilter.getGui());
                    _this.showingUnderlyingFloatingFilter = true;
                });
            }
        }
        // fallback to the read-only version
        this.setupReadOnlyFloatingFilterElement();
        return AgPromise.resolve();
    };
    GroupFloatingFilterComp.prototype.onColumnVisibleChanged = function () {
        this.setupUnderlyingFloatingFilterElement();
    };
    GroupFloatingFilterComp.prototype.onColDefChanged = function (event) {
        var _a, _b, _c;
        if (!event.column) {
            return;
        }
        var compDetails = this.filterManager.getFloatingFilterCompDetails(event.column, this.params.showParentFilter);
        if (compDetails) {
            if ((_a = this.underlyingFloatingFilter) === null || _a === void 0 ? void 0 : _a.refresh) {
                this.underlyingFloatingFilter.refresh(compDetails.params);
            }
            else {
                (_c = (_b = this.underlyingFloatingFilter) === null || _b === void 0 ? void 0 : _b.onParamsUpdated) === null || _c === void 0 ? void 0 : _c.call(_b, compDetails.params);
            }
        }
    };
    GroupFloatingFilterComp.prototype.onParentModelChanged = function (_model, event) {
        var _a, _b;
        if (this.showingUnderlyingFloatingFilter) {
            (_a = this.underlyingFloatingFilter) === null || _a === void 0 ? void 0 : _a.onParentModelChanged((_b = this.parentFilterInstance.getSelectedFilter()) === null || _b === void 0 ? void 0 : _b.getModel(), event);
        }
        else {
            this.updateDisplayedValue();
        }
    };
    GroupFloatingFilterComp.prototype.updateDisplayedValue = function () {
        if (!this.parentFilterInstance || !this.eFloatingFilterText) {
            return;
        }
        var selectedFilter = this.parentFilterInstance.getSelectedFilter();
        if (!selectedFilter) {
            this.eFloatingFilterText.setValue('');
            this.eFloatingFilterText.setDisplayed(false);
            return;
        }
        this.eFloatingFilterText.setDisplayed(true);
        if (selectedFilter.getModelAsString) {
            var filterModel = selectedFilter.getModel();
            this.eFloatingFilterText.setValue(filterModel == null ? '' : selectedFilter.getModelAsString(filterModel));
        }
        else {
            this.eFloatingFilterText.setValue('');
        }
    };
    GroupFloatingFilterComp.prototype.onSelectedColumnChanged = function () {
        if (!this.showingUnderlyingFloatingFilter) {
            this.updateDisplayedValue();
        }
    };
    GroupFloatingFilterComp.prototype.onColumnRowGroupChanged = function () {
        if (!this.showingUnderlyingFloatingFilter) {
            this.updateDisplayedValue();
        }
    };
    GroupFloatingFilterComp.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
    };
    __decorate$1t([
        Autowired('columnModel')
    ], GroupFloatingFilterComp.prototype, "columnModel", void 0);
    __decorate$1t([
        Autowired('filterManager')
    ], GroupFloatingFilterComp.prototype, "filterManager", void 0);
    __decorate$1t([
        RefSelector('eFloatingFilter')
    ], GroupFloatingFilterComp.prototype, "eFloatingFilter", void 0);
    return GroupFloatingFilterComp;
}(Component));

var RowGroupingModule = {
    version: VERSION$g,
    moduleName: ModuleNames.RowGroupingModule,
    beans: [AggregationStage, FilterAggregatesStage, GroupStage, PivotColDefService, PivotStage, AggFuncService],
    agStackComponents: [
        { componentName: 'AgGridHeaderDropZones', componentClass: GridHeaderDropZones }
    ],
    userComponents: [
        { componentName: 'agGroupColumnFilter', componentClass: GroupFilter },
        { componentName: 'agGroupColumnFloatingFilter', componentClass: GroupFloatingFilterComp },
    ],
    dependantModules: [
        EnterpriseCoreModule
    ]
};

var __extends$1Z = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1s = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ValuesDropZonePanel = /** @class */ (function (_super) {
    __extends$1Z(ValuesDropZonePanel, _super);
    function ValuesDropZonePanel(horizontal) {
        return _super.call(this, horizontal, 'aggregation') || this;
    }
    ValuesDropZonePanel.prototype.passBeansUp = function () {
        _super.prototype.setBeans.call(this, {
            gridOptionsService: this.gridOptionsService,
            eventService: this.eventService,
            context: this.getContext(),
            loggerFactory: this.loggerFactory,
            dragAndDropService: this.dragAndDropService
        });
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        var emptyMessage = localeTextFunc('valueColumnsEmptyMessage', 'Drag here to aggregate');
        var title = localeTextFunc('values', 'Values');
        _super.prototype.init.call(this, {
            dragAndDropIcon: DragAndDropService.ICON_AGGREGATE,
            icon: _.createIconNoSpan('valuePanel', this.gridOptionsService, null),
            emptyMessage: emptyMessage,
            title: title
        });
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_VALUE_CHANGED, this.refreshGui.bind(this));
    };
    ValuesDropZonePanel.prototype.getAriaLabel = function () {
        var translate = this.localeService.getLocaleTextFunc();
        var label = translate('ariaValuesDropZonePanelLabel', 'Values');
        return label;
    };
    ValuesDropZonePanel.prototype.getTooltipParams = function () {
        var res = _super.prototype.getTooltipParams.call(this);
        res.location = 'valueColumnsList';
        return res;
    };
    ValuesDropZonePanel.prototype.getIconName = function () {
        return this.isPotentialDndColumns() ? DragAndDropService.ICON_AGGREGATE : DragAndDropService.ICON_NOT_ALLOWED;
    };
    ValuesDropZonePanel.prototype.isColumnDroppable = function (column, draggingEvent) {
        // we never allow grouping of secondary columns
        if (this.gridOptionsService.get('functionsReadOnly') || !column.isPrimary()) {
            return false;
        }
        return column.isAllowValue() && (!column.isValueActive() || this.isSourceEventFromTarget(draggingEvent));
    };
    ValuesDropZonePanel.prototype.updateColumns = function (columns) {
        if (this.gridOptionsService.get('functionsPassive')) {
            var event_1 = {
                type: Events.EVENT_COLUMN_VALUE_CHANGE_REQUEST,
                columns: columns
            };
            this.eventService.dispatchEvent(event_1);
        }
        else {
            this.columnModel.setValueColumns(columns, "toolPanelUi");
        }
    };
    ValuesDropZonePanel.prototype.getExistingColumns = function () {
        return this.columnModel.getValueColumns();
    };
    __decorate$1s([
        Autowired('columnModel')
    ], ValuesDropZonePanel.prototype, "columnModel", void 0);
    __decorate$1s([
        Autowired('loggerFactory')
    ], ValuesDropZonePanel.prototype, "loggerFactory", void 0);
    __decorate$1s([
        Autowired('dragAndDropService')
    ], ValuesDropZonePanel.prototype, "dragAndDropService", void 0);
    __decorate$1s([
        PostConstruct
    ], ValuesDropZonePanel.prototype, "passBeansUp", null);
    return ValuesDropZonePanel;
}(BaseDropZonePanel));

var __extends$1Y = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1r = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var PrimaryColsPanel = /** @class */ (function (_super) {
    __extends$1Y(PrimaryColsPanel, _super);
    function PrimaryColsPanel() {
        return _super.call(this, PrimaryColsPanel.TEMPLATE) || this;
    }
    // we allow dragging in the toolPanel, but not when this component appears in the column menu
    PrimaryColsPanel.prototype.init = function (allowDragging, params, eventType) {
        this.allowDragging = allowDragging;
        this.params = params;
        this.eventType = eventType;
        this.primaryColsHeaderPanel.init(this.params);
        var hideFilter = this.params.suppressColumnFilter;
        var hideSelect = this.params.suppressColumnSelectAll;
        var hideExpand = this.params.suppressColumnExpandAll;
        if (hideExpand && hideFilter && hideSelect) {
            this.primaryColsHeaderPanel.setDisplayed(false);
        }
        this.addManagedListener(this.primaryColsListPanel, 'groupExpanded', this.onGroupExpanded.bind(this));
        this.addManagedListener(this.primaryColsListPanel, 'selectionChanged', this.onSelectionChange.bind(this));
        this.primaryColsListPanel.init(this.params, this.allowDragging, this.eventType);
        this.addManagedListener(this.primaryColsHeaderPanel, 'expandAll', this.onExpandAll.bind(this));
        this.addManagedListener(this.primaryColsHeaderPanel, 'collapseAll', this.onCollapseAll.bind(this));
        this.addManagedListener(this.primaryColsHeaderPanel, 'selectAll', this.onSelectAll.bind(this));
        this.addManagedListener(this.primaryColsHeaderPanel, 'unselectAll', this.onUnselectAll.bind(this));
        this.addManagedListener(this.primaryColsHeaderPanel, 'filterChanged', this.onFilterChanged.bind(this));
        this.positionableFeature = new PositionableFeature(this.getGui(), { minHeight: 100 });
        this.createManagedBean(this.positionableFeature);
    };
    PrimaryColsPanel.prototype.toggleResizable = function (resizable) {
        this.positionableFeature.setResizable(resizable ? { bottom: true } : false);
    };
    PrimaryColsPanel.prototype.onExpandAll = function () {
        this.primaryColsListPanel.doSetExpandedAll(true);
    };
    PrimaryColsPanel.prototype.onCollapseAll = function () {
        this.primaryColsListPanel.doSetExpandedAll(false);
    };
    PrimaryColsPanel.prototype.expandGroups = function (groupIds) {
        this.primaryColsListPanel.setGroupsExpanded(true, groupIds);
    };
    PrimaryColsPanel.prototype.collapseGroups = function (groupIds) {
        this.primaryColsListPanel.setGroupsExpanded(false, groupIds);
    };
    PrimaryColsPanel.prototype.setColumnLayout = function (colDefs) {
        this.primaryColsListPanel.setColumnLayout(colDefs);
    };
    PrimaryColsPanel.prototype.onFilterChanged = function (event) {
        this.primaryColsListPanel.setFilterText(event.filterText);
    };
    PrimaryColsPanel.prototype.syncLayoutWithGrid = function () {
        this.primaryColsListPanel.onColumnsChanged();
    };
    PrimaryColsPanel.prototype.onSelectAll = function () {
        this.primaryColsListPanel.doSetSelectedAll(true);
    };
    PrimaryColsPanel.prototype.onUnselectAll = function () {
        this.primaryColsListPanel.doSetSelectedAll(false);
    };
    PrimaryColsPanel.prototype.onGroupExpanded = function (event) {
        this.primaryColsHeaderPanel.setExpandState(event.state);
        this.params.onStateUpdated();
    };
    PrimaryColsPanel.prototype.onSelectionChange = function (event) {
        this.primaryColsHeaderPanel.setSelectionState(event.state);
    };
    PrimaryColsPanel.prototype.getExpandedGroups = function () {
        return this.primaryColsListPanel.getExpandedGroups();
    };
    PrimaryColsPanel.TEMPLATE = "<div class=\"ag-column-select\">\n            <ag-primary-cols-header ref=\"primaryColsHeaderPanel\"></ag-primary-cols-header>\n            <ag-primary-cols-list ref=\"primaryColsListPanel\"></ag-primary-cols-list>\n        </div>";
    __decorate$1r([
        RefSelector('primaryColsHeaderPanel')
    ], PrimaryColsPanel.prototype, "primaryColsHeaderPanel", void 0);
    __decorate$1r([
        RefSelector('primaryColsListPanel')
    ], PrimaryColsPanel.prototype, "primaryColsListPanel", void 0);
    return PrimaryColsPanel;
}(Component));

var __extends$1X = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$w = (undefined && undefined.__assign) || function () {
    __assign$w = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$w.apply(this, arguments);
};
var ColumnToolPanel = /** @class */ (function (_super) {
    __extends$1X(ColumnToolPanel, _super);
    function ColumnToolPanel() {
        var _this = _super.call(this, ColumnToolPanel.TEMPLATE) || this;
        _this.initialised = false;
        _this.childDestroyFuncs = [];
        return _this;
    }
    // lazy initialise the panel
    ColumnToolPanel.prototype.setVisible = function (visible) {
        _super.prototype.setDisplayed.call(this, visible);
        if (visible && !this.initialised) {
            this.init(this.params);
        }
    };
    ColumnToolPanel.prototype.init = function (params) {
        var _this = this;
        var defaultParams = this.gridOptionsService.addGridCommonParams({
            suppressColumnMove: false,
            suppressColumnSelectAll: false,
            suppressColumnFilter: false,
            suppressColumnExpandAll: false,
            contractColumnSelection: false,
            suppressPivotMode: false,
            suppressRowGroups: false,
            suppressValues: false,
            suppressPivots: false,
            suppressSyncLayoutWithGrid: false,
        });
        this.params = __assign$w(__assign$w({}, defaultParams), params);
        if (this.isRowGroupingModuleLoaded() && !this.params.suppressPivotMode) {
            // DO NOT CHANGE TO createManagedBean
            this.pivotModePanel = this.createBean(new PivotModePanel());
            this.childDestroyFuncs.push(function () { return _this.destroyBean(_this.pivotModePanel); });
            this.appendChild(this.pivotModePanel);
        }
        // DO NOT CHANGE TO createManagedBean
        this.primaryColsPanel = this.createBean(new PrimaryColsPanel());
        this.childDestroyFuncs.push(function () { return _this.destroyBean(_this.primaryColsPanel); });
        this.primaryColsPanel.init(true, this.params, "toolPanelUi");
        this.primaryColsPanel.addCssClass('ag-column-panel-column-select');
        this.appendChild(this.primaryColsPanel);
        if (this.isRowGroupingModuleLoaded()) {
            if (!this.params.suppressRowGroups) {
                // DO NOT CHANGE TO createManagedBean
                this.rowGroupDropZonePanel = this.createBean(new RowGroupDropZonePanel(false));
                this.childDestroyFuncs.push(function () { return _this.destroyBean(_this.rowGroupDropZonePanel); });
                this.appendChild(this.rowGroupDropZonePanel);
            }
            if (!this.params.suppressValues) {
                // DO NOT CHANGE TO createManagedBean
                this.valuesDropZonePanel = this.createBean(new ValuesDropZonePanel(false));
                this.childDestroyFuncs.push(function () { return _this.destroyBean(_this.valuesDropZonePanel); });
                this.appendChild(this.valuesDropZonePanel);
            }
            if (!this.params.suppressPivots) {
                // DO NOT CHANGE TO createManagedBean
                this.pivotDropZonePanel = this.createBean(new PivotDropZonePanel(false));
                this.childDestroyFuncs.push(function () { return _this.destroyBean(_this.pivotDropZonePanel); });
                this.appendChild(this.pivotDropZonePanel);
            }
            this.setLastVisible();
            var pivotModeListener_1 = this.addManagedListener(this.eventService, Events.EVENT_COLUMN_PIVOT_MODE_CHANGED, function () {
                _this.resetChildrenHeight();
                _this.setLastVisible();
            });
            this.childDestroyFuncs.push(function () { return pivotModeListener_1(); });
        }
        this.initialised = true;
    };
    ColumnToolPanel.prototype.setPivotModeSectionVisible = function (visible) {
        var _this = this;
        if (!this.isRowGroupingModuleLoaded()) {
            return;
        }
        if (this.pivotModePanel) {
            this.pivotModePanel.setDisplayed(visible);
        }
        else if (visible) {
            this.pivotModePanel = this.createBean(new PivotModePanel());
            // ensure pivot mode panel is positioned at the top of the columns tool panel
            this.getGui().insertBefore(this.pivotModePanel.getGui(), this.getGui().firstChild);
            this.childDestroyFuncs.push(function () { return _this.destroyBean(_this.pivotModePanel); });
        }
        this.setLastVisible();
    };
    ColumnToolPanel.prototype.setRowGroupsSectionVisible = function (visible) {
        if (!this.isRowGroupingModuleLoaded()) {
            return;
        }
        if (this.rowGroupDropZonePanel) {
            this.rowGroupDropZonePanel.setDisplayed(visible);
        }
        else if (visible) {
            this.rowGroupDropZonePanel = this.createManagedBean(new RowGroupDropZonePanel(false));
            this.appendChild(this.rowGroupDropZonePanel);
        }
        this.setLastVisible();
    };
    ColumnToolPanel.prototype.setValuesSectionVisible = function (visible) {
        if (!this.isRowGroupingModuleLoaded()) {
            return;
        }
        if (this.valuesDropZonePanel) {
            this.valuesDropZonePanel.setDisplayed(visible);
        }
        else if (visible) {
            this.valuesDropZonePanel = this.createManagedBean(new ValuesDropZonePanel(false));
            this.appendChild(this.valuesDropZonePanel);
        }
        this.setLastVisible();
    };
    ColumnToolPanel.prototype.setPivotSectionVisible = function (visible) {
        if (!this.isRowGroupingModuleLoaded()) {
            return;
        }
        if (this.pivotDropZonePanel) {
            this.pivotDropZonePanel.setDisplayed(visible);
        }
        else if (visible) {
            this.pivotDropZonePanel = this.createManagedBean(new PivotDropZonePanel(false));
            this.appendChild(this.pivotDropZonePanel);
            this.pivotDropZonePanel.setDisplayed(visible);
        }
        this.setLastVisible();
    };
    ColumnToolPanel.prototype.setResizers = function () {
        [
            this.primaryColsPanel,
            this.rowGroupDropZonePanel,
            this.valuesDropZonePanel,
            this.pivotDropZonePanel
        ].forEach(function (panel) {
            if (!panel) {
                return;
            }
            var eGui = panel.getGui();
            panel.toggleResizable(!eGui.classList.contains('ag-last-column-drop') && !eGui.classList.contains('ag-hidden'));
        });
    };
    ColumnToolPanel.prototype.setLastVisible = function () {
        var eGui = this.getGui();
        var columnDrops = Array.prototype.slice.call(eGui.querySelectorAll('.ag-column-drop'));
        columnDrops.forEach(function (columnDrop) { return columnDrop.classList.remove('ag-last-column-drop'); });
        var columnDropEls = eGui.querySelectorAll('.ag-column-drop:not(.ag-hidden)');
        var lastVisible = _.last(columnDropEls);
        if (lastVisible) {
            lastVisible.classList.add('ag-last-column-drop');
        }
        this.setResizers();
    };
    ColumnToolPanel.prototype.resetChildrenHeight = function () {
        var eGui = this.getGui();
        var children = eGui.children;
        for (var i = 0; i < children.length; i++) {
            var child = children[i];
            child.style.removeProperty('height');
            child.style.removeProperty('flex');
        }
    };
    ColumnToolPanel.prototype.isRowGroupingModuleLoaded = function () {
        return ModuleRegistry.__assertRegistered(ModuleNames.RowGroupingModule, 'Row Grouping', this.context.getGridId());
    };
    ColumnToolPanel.prototype.expandColumnGroups = function (groupIds) {
        this.primaryColsPanel.expandGroups(groupIds);
    };
    ColumnToolPanel.prototype.collapseColumnGroups = function (groupIds) {
        this.primaryColsPanel.collapseGroups(groupIds);
    };
    ColumnToolPanel.prototype.setColumnLayout = function (colDefs) {
        this.primaryColsPanel.setColumnLayout(colDefs);
    };
    ColumnToolPanel.prototype.syncLayoutWithGrid = function () {
        this.primaryColsPanel.syncLayoutWithGrid();
    };
    ColumnToolPanel.prototype.destroyChildren = function () {
        this.childDestroyFuncs.forEach(function (func) { return func(); });
        this.childDestroyFuncs.length = 0;
        _.clearElement(this.getGui());
    };
    ColumnToolPanel.prototype.refresh = function (params) {
        this.destroyChildren();
        this.init(params);
        return true;
    };
    ColumnToolPanel.prototype.getState = function () {
        return {
            expandedGroupIds: this.primaryColsPanel.getExpandedGroups()
        };
    };
    // this is a user component, and IComponent has "public destroy()" as part of the interface.
    // so this must be public.
    ColumnToolPanel.prototype.destroy = function () {
        this.destroyChildren();
        _super.prototype.destroy.call(this);
    };
    ColumnToolPanel.TEMPLATE = "<div class=\"ag-column-panel\"></div>";
    return ColumnToolPanel;
}(Component));

var __extends$1W = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1q = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var HorizontalResizeComp = /** @class */ (function (_super) {
    __extends$1W(HorizontalResizeComp, _super);
    function HorizontalResizeComp() {
        var _this = _super.call(this, /* html */ "<div class=\"ag-tool-panel-horizontal-resize\"></div>") || this;
        _this.minWidth = 100;
        _this.maxWidth = null;
        return _this;
    }
    HorizontalResizeComp.prototype.setElementToResize = function (elementToResize) {
        this.elementToResize = elementToResize;
    };
    HorizontalResizeComp.prototype.postConstruct = function () {
        var finishedWithResizeFunc = this.horizontalResizeService.addResizeBar({
            eResizeBar: this.getGui(),
            dragStartPixels: 1,
            onResizeStart: this.onResizeStart.bind(this),
            onResizing: this.onResizing.bind(this),
            onResizeEnd: this.onResizeEnd.bind(this)
        });
        this.addDestroyFunc(finishedWithResizeFunc);
        this.setInverted(this.gridOptionsService.get('enableRtl'));
    };
    HorizontalResizeComp.prototype.dispatchResizeEvent = function (start, end, width) {
        var event = {
            type: Events.EVENT_TOOL_PANEL_SIZE_CHANGED,
            width: width,
            started: start,
            ended: end,
        };
        this.eventService.dispatchEvent(event);
    };
    HorizontalResizeComp.prototype.onResizeStart = function () {
        this.startingWidth = this.elementToResize.offsetWidth;
        this.dispatchResizeEvent(true, false, this.startingWidth);
    };
    HorizontalResizeComp.prototype.onResizeEnd = function (delta) {
        return this.onResizing(delta, true);
    };
    HorizontalResizeComp.prototype.onResizing = function (delta, isEnd) {
        if (isEnd === void 0) { isEnd = false; }
        var direction = this.inverted ? -1 : 1;
        var newWidth = Math.max(this.minWidth, Math.floor(this.startingWidth - (delta * direction)));
        if (this.maxWidth != null) {
            newWidth = Math.min(this.maxWidth, newWidth);
        }
        this.elementToResize.style.width = "".concat(newWidth, "px");
        this.dispatchResizeEvent(false, isEnd, newWidth);
    };
    HorizontalResizeComp.prototype.setInverted = function (inverted) {
        this.inverted = inverted;
    };
    HorizontalResizeComp.prototype.setMaxWidth = function (value) {
        this.maxWidth = value;
    };
    HorizontalResizeComp.prototype.setMinWidth = function (value) {
        if (value != null) {
            this.minWidth = value;
        }
        else {
            this.minWidth = 100;
        }
    };
    __decorate$1q([
        Autowired('horizontalResizeService')
    ], HorizontalResizeComp.prototype, "horizontalResizeService", void 0);
    __decorate$1q([
        PostConstruct
    ], HorizontalResizeComp.prototype, "postConstruct", null);
    return HorizontalResizeComp;
}(Component));

var __extends$1V = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1p = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var SideBarButtonComp = /** @class */ (function (_super) {
    __extends$1V(SideBarButtonComp, _super);
    function SideBarButtonComp(toolPanelDef) {
        var _this = _super.call(this) || this;
        _this.toolPanelDef = toolPanelDef;
        return _this;
    }
    SideBarButtonComp.prototype.getToolPanelId = function () {
        return this.toolPanelDef.id;
    };
    SideBarButtonComp.prototype.postConstruct = function () {
        var template = this.createTemplate();
        this.setTemplate(template);
        this.setLabel();
        this.setIcon();
        this.addManagedListener(this.eToggleButton, 'click', this.onButtonPressed.bind(this));
        this.eToggleButton.setAttribute('id', "ag-".concat(this.getCompId(), "-button"));
    };
    SideBarButtonComp.prototype.createTemplate = function () {
        var res = /* html */ "<div class=\"ag-side-button\" role=\"presentation\">\n                <button type=\"button\" ref=\"eToggleButton\" tabindex=\"-1\" role=\"tab\" aria-expanded=\"false\" class=\"ag-button ag-side-button-button\">\n                    <div ref=\"eIconWrapper\" class=\"ag-side-button-icon-wrapper\" aria-hidden=\"true\"></div>\n                    <span ref =\"eLabel\" class=\"ag-side-button-label\"></span>\n                </button>\n            </div>";
        return res;
    };
    SideBarButtonComp.prototype.setLabel = function () {
        var translate = this.localeService.getLocaleTextFunc();
        var def = this.toolPanelDef;
        var label = translate(def.labelKey, def.labelDefault);
        this.eLabel.innerText = label;
    };
    SideBarButtonComp.prototype.setIcon = function () {
        this.eIconWrapper.insertAdjacentElement('afterbegin', _.createIconNoSpan(this.toolPanelDef.iconKey, this.gridOptionsService));
    };
    SideBarButtonComp.prototype.onButtonPressed = function () {
        this.dispatchEvent({ type: SideBarButtonComp.EVENT_TOGGLE_BUTTON_CLICKED });
    };
    SideBarButtonComp.prototype.setSelected = function (selected) {
        this.addOrRemoveCssClass('ag-selected', selected);
        _.setAriaExpanded(this.eToggleButton, selected);
    };
    SideBarButtonComp.prototype.getButtonElement = function () {
        return this.eToggleButton;
    };
    SideBarButtonComp.EVENT_TOGGLE_BUTTON_CLICKED = 'toggleButtonClicked';
    __decorate$1p([
        RefSelector('eToggleButton')
    ], SideBarButtonComp.prototype, "eToggleButton", void 0);
    __decorate$1p([
        RefSelector('eIconWrapper')
    ], SideBarButtonComp.prototype, "eIconWrapper", void 0);
    __decorate$1p([
        RefSelector('eLabel')
    ], SideBarButtonComp.prototype, "eLabel", void 0);
    __decorate$1p([
        PostConstruct
    ], SideBarButtonComp.prototype, "postConstruct", null);
    return SideBarButtonComp;
}(Component));

var __extends$1U = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1o = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var SideBarButtonsComp = /** @class */ (function (_super) {
    __extends$1U(SideBarButtonsComp, _super);
    function SideBarButtonsComp() {
        var _this = _super.call(this, SideBarButtonsComp.TEMPLATE) || this;
        _this.buttonComps = [];
        return _this;
    }
    SideBarButtonsComp.prototype.postConstruct = function () {
        this.addManagedListener(this.getFocusableElement(), 'keydown', this.handleKeyDown.bind(this));
    };
    SideBarButtonsComp.prototype.handleKeyDown = function (e) {
        if (e.key !== KeyCode.TAB || !e.shiftKey) {
            return;
        }
        var lastColumn = _.last(this.columnModel.getAllDisplayedColumns());
        if (this.focusService.focusGridView(lastColumn, true)) {
            e.preventDefault();
        }
    };
    SideBarButtonsComp.prototype.setActiveButton = function (id) {
        this.buttonComps.forEach(function (comp) {
            comp.setSelected(id === comp.getToolPanelId());
        });
    };
    SideBarButtonsComp.prototype.addButtonComp = function (def) {
        var _this = this;
        var buttonComp = this.createBean(new SideBarButtonComp(def));
        this.buttonComps.push(buttonComp);
        this.appendChild(buttonComp);
        buttonComp.addEventListener(SideBarButtonComp.EVENT_TOGGLE_BUTTON_CLICKED, function () {
            _this.dispatchEvent({
                type: SideBarButtonsComp.EVENT_SIDE_BAR_BUTTON_CLICKED,
                toolPanelId: def.id
            });
        });
        return buttonComp;
    };
    SideBarButtonsComp.prototype.clearButtons = function () {
        this.buttonComps = this.destroyBeans(this.buttonComps);
        _.clearElement(this.getGui());
    };
    SideBarButtonsComp.EVENT_SIDE_BAR_BUTTON_CLICKED = 'sideBarButtonClicked';
    SideBarButtonsComp.TEMPLATE = "<div class=\"ag-side-buttons\" role=\"tablist\"></div>";
    __decorate$1o([
        Autowired('focusService')
    ], SideBarButtonsComp.prototype, "focusService", void 0);
    __decorate$1o([
        Autowired('columnModel')
    ], SideBarButtonsComp.prototype, "columnModel", void 0);
    __decorate$1o([
        PostConstruct
    ], SideBarButtonsComp.prototype, "postConstruct", null);
    __decorate$1o([
        PreDestroy
    ], SideBarButtonsComp.prototype, "clearButtons", null);
    return SideBarButtonsComp;
}(Component));

var SideBarDefParser = /** @class */ (function () {
    function SideBarDefParser() {
    }
    SideBarDefParser.parse = function (toParse) {
        if (!toParse) {
            return undefined;
        }
        if (toParse === true) {
            return {
                toolPanels: [
                    SideBarDefParser.DEFAULT_COLUMN_COMP,
                    SideBarDefParser.DEFAULT_FILTER_COMP,
                ],
                defaultToolPanel: 'columns'
            };
        }
        if (typeof toParse === 'string') {
            return SideBarDefParser.parse([toParse]);
        }
        if (Array.isArray(toParse)) {
            var comps_1 = [];
            toParse.forEach(function (key) {
                var lookupResult = SideBarDefParser.DEFAULT_BY_KEY[key];
                if (!lookupResult) {
                    console.warn("AG Grid: the key ".concat(key, " is not a valid key for specifying a tool panel, valid keys are: ").concat(Object.keys(SideBarDefParser.DEFAULT_BY_KEY).join(',')));
                    return;
                }
                comps_1.push(lookupResult);
            });
            if (comps_1.length === 0) {
                return undefined;
            }
            return {
                toolPanels: comps_1,
                defaultToolPanel: comps_1[0].id
            };
        }
        var result = {
            toolPanels: SideBarDefParser.parseComponents(toParse.toolPanels),
            defaultToolPanel: toParse.defaultToolPanel,
            hiddenByDefault: toParse.hiddenByDefault,
            position: toParse.position
        };
        return result;
    };
    SideBarDefParser.parseComponents = function (from) {
        var result = [];
        if (!from) {
            return result;
        }
        from.forEach(function (it) {
            var toAdd = null;
            if (typeof it === 'string') {
                var lookupResult = SideBarDefParser.DEFAULT_BY_KEY[it];
                if (!lookupResult) {
                    console.warn("AG Grid: the key ".concat(it, " is not a valid key for specifying a tool panel, valid keys are: ").concat(Object.keys(SideBarDefParser.DEFAULT_BY_KEY).join(',')));
                    return;
                }
                toAdd = lookupResult;
            }
            else {
                toAdd = it;
            }
            result.push(toAdd);
        });
        return result;
    };
    SideBarDefParser.DEFAULT_COLUMN_COMP = {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
    };
    SideBarDefParser.DEFAULT_FILTER_COMP = {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
    };
    SideBarDefParser.DEFAULT_BY_KEY = {
        columns: SideBarDefParser.DEFAULT_COLUMN_COMP,
        filters: SideBarDefParser.DEFAULT_FILTER_COMP
    };
    return SideBarDefParser;
}());

var __extends$1T = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1n = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ToolPanelWrapper = /** @class */ (function (_super) {
    __extends$1T(ToolPanelWrapper, _super);
    function ToolPanelWrapper() {
        return _super.call(this, ToolPanelWrapper.TEMPLATE) || this;
    }
    ToolPanelWrapper.prototype.setupResize = function () {
        var eGui = this.getGui();
        var resizeBar = this.resizeBar = this.createManagedBean(new HorizontalResizeComp());
        eGui.setAttribute('id', "ag-".concat(this.getCompId()));
        resizeBar.setElementToResize(eGui);
        this.appendChild(resizeBar);
    };
    ToolPanelWrapper.prototype.getToolPanelId = function () {
        return this.toolPanelId;
    };
    ToolPanelWrapper.prototype.setToolPanelDef = function (toolPanelDef, params) {
        var id = toolPanelDef.id, minWidth = toolPanelDef.minWidth, maxWidth = toolPanelDef.maxWidth, width = toolPanelDef.width;
        this.toolPanelId = id;
        this.width = width;
        var compDetails = this.userComponentFactory.getToolPanelCompDetails(toolPanelDef, params);
        var componentPromise = compDetails.newAgStackInstance();
        this.params = compDetails.params;
        if (componentPromise == null) {
            console.warn("AG Grid: error processing tool panel component ".concat(id, ". You need to specify 'toolPanel'"));
            return;
        }
        componentPromise.then(this.setToolPanelComponent.bind(this));
        if (minWidth != null) {
            this.resizeBar.setMinWidth(minWidth);
        }
        if (maxWidth != null) {
            this.resizeBar.setMaxWidth(maxWidth);
        }
    };
    ToolPanelWrapper.prototype.setToolPanelComponent = function (compInstance) {
        var _this = this;
        this.toolPanelCompInstance = compInstance;
        this.appendChild(compInstance.getGui());
        this.addDestroyFunc(function () {
            _this.destroyBean(compInstance);
        });
        if (this.width) {
            this.getGui().style.width = "".concat(this.width, "px");
        }
    };
    ToolPanelWrapper.prototype.getToolPanelInstance = function () {
        return this.toolPanelCompInstance;
    };
    ToolPanelWrapper.prototype.setResizerSizerSide = function (side) {
        var isRtl = this.gridOptionsService.get('enableRtl');
        var isLeft = side === 'left';
        var inverted = isRtl ? isLeft : !isLeft;
        this.resizeBar.setInverted(inverted);
    };
    ToolPanelWrapper.prototype.refresh = function () {
        this.toolPanelCompInstance.refresh(this.params);
    };
    ToolPanelWrapper.TEMPLATE = "<div class=\"ag-tool-panel-wrapper\" role=\"tabpanel\"/>";
    __decorate$1n([
        Autowired("userComponentFactory")
    ], ToolPanelWrapper.prototype, "userComponentFactory", void 0);
    __decorate$1n([
        PostConstruct
    ], ToolPanelWrapper.prototype, "setupResize", null);
    return ToolPanelWrapper;
}(Component));

var __extends$1S = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$v = (undefined && undefined.__assign) || function () {
    __assign$v = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$v.apply(this, arguments);
};
var __decorate$1m = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __values$9 = (undefined && undefined.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var SideBarComp = /** @class */ (function (_super) {
    __extends$1S(SideBarComp, _super);
    function SideBarComp() {
        var _this = _super.call(this, SideBarComp.TEMPLATE) || this;
        _this.toolPanelWrappers = [];
        return _this;
    }
    SideBarComp.prototype.postConstruct = function () {
        var _a;
        this.sideBarButtonsComp.addEventListener(SideBarButtonsComp.EVENT_SIDE_BAR_BUTTON_CLICKED, this.onToolPanelButtonClicked.bind(this));
        var sideBarState = ((_a = this.gridOptionsService.get('initialState')) !== null && _a !== void 0 ? _a : {}).sideBar;
        this.setSideBarDef({
            sideBarDef: SideBarDefParser.parse(this.gridOptionsService.get('sideBar')),
            sideBarState: sideBarState
        });
        this.addManagedPropertyListener('sideBar', this.onSideBarUpdated.bind(this));
        this.sideBarService.registerSideBarComp(this);
        this.createManagedBean(new ManagedFocusFeature(this.getFocusableElement(), {
            onTabKeyDown: this.onTabKeyDown.bind(this),
            handleKeyDown: this.handleKeyDown.bind(this)
        }));
    };
    SideBarComp.prototype.onTabKeyDown = function (e) {
        if (e.defaultPrevented) {
            return;
        }
        var _a = this, focusService = _a.focusService, sideBarButtonsComp = _a.sideBarButtonsComp;
        var eGui = this.getGui();
        var sideBarGui = sideBarButtonsComp.getGui();
        var eDocument = this.gridOptionsService.getDocument();
        var activeElement = eDocument.activeElement;
        var openPanel = eGui.querySelector('.ag-tool-panel-wrapper:not(.ag-hidden)');
        var target = e.target;
        if (!openPanel) {
            return;
        }
        if (sideBarGui.contains(activeElement)) {
            if (focusService.focusInto(openPanel, e.shiftKey)) {
                e.preventDefault();
            }
            return;
        }
        // only handle backwards focus to target the sideBar buttons
        if (!e.shiftKey) {
            return;
        }
        var nextEl = null;
        if (openPanel.contains(activeElement)) {
            nextEl = this.focusService.findNextFocusableElement(openPanel, undefined, true);
        }
        else if (focusService.isTargetUnderManagedComponent(openPanel, target) && e.shiftKey) {
            nextEl = this.focusService.findFocusableElementBeforeTabGuard(openPanel, target);
        }
        if (!nextEl) {
            nextEl = sideBarGui.querySelector('.ag-selected button');
        }
        if (nextEl) {
            e.preventDefault();
            nextEl.focus();
        }
    };
    SideBarComp.prototype.handleKeyDown = function (e) {
        var eDocument = this.gridOptionsService.getDocument();
        if (!this.sideBarButtonsComp.getGui().contains(eDocument.activeElement)) {
            return;
        }
        var sideBarGui = this.sideBarButtonsComp.getGui();
        var buttons = Array.prototype.slice.call(sideBarGui.querySelectorAll('.ag-side-button'));
        var currentButton = eDocument.activeElement;
        var currentPos = buttons.findIndex(function (button) { return button.contains(currentButton); });
        var nextPos = null;
        switch (e.key) {
            case KeyCode.LEFT:
            case KeyCode.UP:
                nextPos = Math.max(0, currentPos - 1);
                break;
            case KeyCode.RIGHT:
            case KeyCode.DOWN:
                nextPos = Math.min(currentPos + 1, buttons.length - 1);
                break;
        }
        if (nextPos === null) {
            return;
        }
        var innerButton = buttons[nextPos].querySelector('button');
        if (innerButton) {
            innerButton.focus();
            e.preventDefault();
        }
    };
    SideBarComp.prototype.onToolPanelButtonClicked = function (event) {
        var id = event.toolPanelId;
        var openedItem = this.openedItem();
        // if item was already open, we close it
        if (openedItem === id) {
            this.openToolPanel(undefined, 'sideBarButtonClicked'); // passing undefined closes
        }
        else {
            this.openToolPanel(id, 'sideBarButtonClicked');
        }
    };
    SideBarComp.prototype.clearDownUi = function () {
        this.sideBarButtonsComp.clearButtons();
        this.destroyToolPanelWrappers();
    };
    SideBarComp.prototype.setSideBarDef = function (_a) {
        var sideBarDef = _a.sideBarDef, sideBarState = _a.sideBarState, existingToolPanelWrappers = _a.existingToolPanelWrappers;
        // initially hide side bar
        this.setDisplayed(false);
        this.sideBar = sideBarDef;
        if (!!this.sideBar && !!this.sideBar.toolPanels) {
            var toolPanelDefs = this.sideBar.toolPanels;
            this.createToolPanelsAndSideButtons(toolPanelDefs, sideBarState, existingToolPanelWrappers);
            if (!this.toolPanelWrappers.length) {
                return;
            }
            var shouldDisplaySideBar = sideBarState ? sideBarState.visible : !this.sideBar.hiddenByDefault;
            this.setDisplayed(shouldDisplaySideBar);
            this.setSideBarPosition(sideBarState ? sideBarState.position : this.sideBar.position);
            if (shouldDisplaySideBar) {
                if (sideBarState) {
                    var openToolPanel = sideBarState.openToolPanel;
                    if (openToolPanel) {
                        this.openToolPanel(openToolPanel, 'sideBarInitializing');
                    }
                }
                else {
                    this.openToolPanel(this.sideBar.defaultToolPanel, 'sideBarInitializing');
                }
            }
        }
    };
    SideBarComp.prototype.getDef = function () {
        return this.sideBar;
    };
    SideBarComp.prototype.setSideBarPosition = function (position) {
        if (!position) {
            position = 'right';
        }
        this.position = position;
        var isLeft = position === 'left';
        var resizerSide = isLeft ? 'right' : 'left';
        this.addOrRemoveCssClass('ag-side-bar-left', isLeft);
        this.addOrRemoveCssClass('ag-side-bar-right', !isLeft);
        this.toolPanelWrappers.forEach(function (wrapper) {
            wrapper.setResizerSizerSide(resizerSide);
        });
        this.eventService.dispatchEvent({ type: Events.EVENT_SIDE_BAR_UPDATED });
        return this;
    };
    SideBarComp.prototype.setDisplayed = function (displayed, options) {
        _super.prototype.setDisplayed.call(this, displayed, options);
        this.eventService.dispatchEvent({ type: Events.EVENT_SIDE_BAR_UPDATED });
    };
    SideBarComp.prototype.getState = function () {
        var toolPanels = {};
        this.toolPanelWrappers.forEach(function (wrapper) {
            var _a, _b;
            toolPanels[wrapper.getToolPanelId()] = (_b = (_a = wrapper.getToolPanelInstance()) === null || _a === void 0 ? void 0 : _a.getState) === null || _b === void 0 ? void 0 : _b.call(_a);
        });
        return {
            visible: this.isDisplayed(),
            position: this.position,
            openToolPanel: this.openedItem(),
            toolPanels: toolPanels
        };
    };
    SideBarComp.prototype.createToolPanelsAndSideButtons = function (defs, sideBarState, existingToolPanelWrappers) {
        var e_1, _a;
        var _b;
        try {
            for (var defs_1 = __values$9(defs), defs_1_1 = defs_1.next(); !defs_1_1.done; defs_1_1 = defs_1.next()) {
                var def = defs_1_1.value;
                this.createToolPanelAndSideButton(def, (_b = sideBarState === null || sideBarState === void 0 ? void 0 : sideBarState.toolPanels) === null || _b === void 0 ? void 0 : _b[def.id], existingToolPanelWrappers === null || existingToolPanelWrappers === void 0 ? void 0 : existingToolPanelWrappers[def.id]);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (defs_1_1 && !defs_1_1.done && (_a = defs_1.return)) _a.call(defs_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    SideBarComp.prototype.validateDef = function (def) {
        if (def.id == null) {
            console.warn("AG Grid: please review all your toolPanel components, it seems like at least one of them doesn't have an id");
            return false;
        }
        // helpers, in case user doesn't have the right module loaded
        if (def.toolPanel === 'agColumnsToolPanel') {
            var moduleMissing = !ModuleRegistry.__assertRegistered(ModuleNames.ColumnsToolPanelModule, 'Column Tool Panel', this.context.getGridId());
            if (moduleMissing) {
                return false;
            }
        }
        if (def.toolPanel === 'agFiltersToolPanel') {
            var moduleMissing = !ModuleRegistry.__assertRegistered(ModuleNames.FiltersToolPanelModule, 'Filters Tool Panel', this.context.getGridId());
            if (moduleMissing) {
                return false;
            }
            if (this.filterManager.isAdvancedFilterEnabled()) {
                _.warnOnce('Advanced Filter does not work with Filters Tool Panel. Filters Tool Panel has been disabled.');
                return false;
            }
        }
        return true;
    };
    SideBarComp.prototype.createToolPanelAndSideButton = function (def, initialState, existingToolPanelWrapper) {
        var _this = this;
        if (!this.validateDef(def)) {
            return;
        }
        var button = this.sideBarButtonsComp.addButtonComp(def);
        var wrapper;
        if (existingToolPanelWrapper) {
            wrapper = existingToolPanelWrapper;
        }
        else {
            wrapper = this.getContext().createBean(new ToolPanelWrapper());
            wrapper.setToolPanelDef(def, {
                initialState: initialState,
                onStateUpdated: function () { return _this.eventService.dispatchEvent({ type: Events.EVENT_SIDE_BAR_UPDATED }); }
            });
        }
        wrapper.setDisplayed(false);
        var wrapperGui = wrapper.getGui();
        this.appendChild(wrapperGui);
        this.toolPanelWrappers.push(wrapper);
        _.setAriaControls(button.getButtonElement(), wrapperGui);
    };
    SideBarComp.prototype.refresh = function () {
        this.toolPanelWrappers.forEach(function (wrapper) { return wrapper.refresh(); });
    };
    SideBarComp.prototype.openToolPanel = function (key, source) {
        if (source === void 0) { source = 'api'; }
        var currentlyOpenedKey = this.openedItem();
        if (currentlyOpenedKey === key) {
            return;
        }
        this.toolPanelWrappers.forEach(function (wrapper) {
            var show = key === wrapper.getToolPanelId();
            wrapper.setDisplayed(show);
        });
        var newlyOpenedKey = this.openedItem();
        var openToolPanelChanged = currentlyOpenedKey !== newlyOpenedKey;
        if (openToolPanelChanged) {
            this.sideBarButtonsComp.setActiveButton(key);
            this.raiseToolPanelVisibleEvent(key, currentlyOpenedKey !== null && currentlyOpenedKey !== void 0 ? currentlyOpenedKey : undefined, source);
        }
    };
    SideBarComp.prototype.getToolPanelInstance = function (key) {
        var toolPanelWrapper = this.toolPanelWrappers.filter(function (toolPanel) { return toolPanel.getToolPanelId() === key; })[0];
        if (!toolPanelWrapper) {
            console.warn("AG Grid: unable to lookup Tool Panel as invalid key supplied: ".concat(key));
            return;
        }
        return toolPanelWrapper.getToolPanelInstance();
    };
    SideBarComp.prototype.raiseToolPanelVisibleEvent = function (key, previousKey, source) {
        var switchingToolPanel = !!key && !!previousKey;
        if (previousKey) {
            var event_1 = {
                type: Events.EVENT_TOOL_PANEL_VISIBLE_CHANGED,
                source: source,
                key: previousKey,
                visible: false,
                switchingToolPanel: switchingToolPanel,
            };
            this.eventService.dispatchEvent(event_1);
        }
        if (key) {
            var event_2 = {
                type: Events.EVENT_TOOL_PANEL_VISIBLE_CHANGED,
                source: source,
                key: key,
                visible: true,
                switchingToolPanel: switchingToolPanel,
            };
            this.eventService.dispatchEvent(event_2);
        }
    };
    SideBarComp.prototype.close = function (source) {
        if (source === void 0) { source = 'api'; }
        this.openToolPanel(undefined, source);
    };
    SideBarComp.prototype.isToolPanelShowing = function () {
        return !!this.openedItem();
    };
    SideBarComp.prototype.openedItem = function () {
        var activeToolPanel = null;
        this.toolPanelWrappers.forEach(function (wrapper) {
            if (wrapper.isDisplayed()) {
                activeToolPanel = wrapper.getToolPanelId();
            }
        });
        return activeToolPanel;
    };
    SideBarComp.prototype.onSideBarUpdated = function () {
        var _this = this;
        var _a;
        var sideBarDef = SideBarDefParser.parse(this.gridOptionsService.get('sideBar'));
        var existingToolPanelWrappers = {};
        if (sideBarDef && this.sideBar) {
            (_a = sideBarDef.toolPanels) === null || _a === void 0 ? void 0 : _a.forEach(function (toolPanelDef) {
                var _a, _b;
                var id = toolPanelDef.id;
                if (!id) {
                    return;
                }
                var existingToolPanelDef = (_a = _this.sideBar.toolPanels) === null || _a === void 0 ? void 0 : _a.find(function (toolPanelDefToCheck) { return toolPanelDefToCheck.id === id; });
                if (!existingToolPanelDef || toolPanelDef.toolPanel !== existingToolPanelDef.toolPanel) {
                    return;
                }
                var toolPanelWrapper = _this.toolPanelWrappers.find(function (toolPanel) { return toolPanel.getToolPanelId() === id; });
                if (!toolPanelWrapper) {
                    return;
                }
                var params = _this.gridOptionsService.addGridCommonParams(__assign$v(__assign$v({}, ((_b = toolPanelDef.toolPanelParams) !== null && _b !== void 0 ? _b : {})), { onStateUpdated: function () { return _this.eventService.dispatchEvent({ type: Events.EVENT_SIDE_BAR_UPDATED }); } }));
                var hasRefreshed = toolPanelWrapper.getToolPanelInstance().refresh(params);
                if (hasRefreshed !== true) {
                    return;
                }
                _this.toolPanelWrappers = _this.toolPanelWrappers.filter(function (toolPanel) { return toolPanel !== toolPanelWrapper; });
                _.removeFromParent(toolPanelWrapper.getGui());
                existingToolPanelWrappers[id] = toolPanelWrapper;
            });
        }
        this.clearDownUi();
        // don't re-assign initial state
        this.setSideBarDef({ sideBarDef: sideBarDef, existingToolPanelWrappers: existingToolPanelWrappers });
    };
    SideBarComp.prototype.destroyToolPanelWrappers = function () {
        var _this = this;
        this.toolPanelWrappers.forEach(function (wrapper) {
            _.removeFromParent(wrapper.getGui());
            _this.destroyBean(wrapper);
        });
        this.toolPanelWrappers.length = 0;
    };
    SideBarComp.prototype.destroy = function () {
        this.destroyToolPanelWrappers();
        _super.prototype.destroy.call(this);
    };
    SideBarComp.TEMPLATE = "<div class=\"ag-side-bar ag-unselectable\">\n            <ag-side-bar-buttons ref=\"sideBarButtons\"></ag-side-bar-buttons>\n        </div>";
    __decorate$1m([
        Autowired('focusService')
    ], SideBarComp.prototype, "focusService", void 0);
    __decorate$1m([
        Autowired('filterManager')
    ], SideBarComp.prototype, "filterManager", void 0);
    __decorate$1m([
        Autowired('sideBarService')
    ], SideBarComp.prototype, "sideBarService", void 0);
    __decorate$1m([
        RefSelector('sideBarButtons')
    ], SideBarComp.prototype, "sideBarButtonsComp", void 0);
    __decorate$1m([
        PostConstruct
    ], SideBarComp.prototype, "postConstruct", null);
    return SideBarComp;
}(Component));

var __extends$1R = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1l = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ToolPanelColDefService = /** @class */ (function (_super) {
    __extends$1R(ToolPanelColDefService, _super);
    function ToolPanelColDefService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isColGroupDef = function (colDef) { return colDef && typeof colDef.children !== 'undefined'; };
        _this.getId = function (colDef) {
            return _this.isColGroupDef(colDef) ? colDef.groupId : colDef.colId;
        };
        return _this;
    }
    ToolPanelColDefService.prototype.createColumnTree = function (colDefs) {
        var _this = this;
        var invalidColIds = [];
        var createDummyColGroup = function (abstractColDef, depth) {
            if (_this.isColGroupDef(abstractColDef)) {
                // creating 'dummy' group which is not associated with grid column group
                var groupDef = abstractColDef;
                var groupId = (typeof groupDef.groupId !== 'undefined') ? groupDef.groupId : groupDef.headerName;
                var group = new ProvidedColumnGroup(groupDef, groupId, false, depth);
                var children_1 = [];
                groupDef.children.forEach(function (def) {
                    var child = createDummyColGroup(def, depth + 1);
                    // check column exists in case invalid colDef is supplied for primary column
                    if (child) {
                        children_1.push(child);
                    }
                });
                group.setChildren(children_1);
                return group;
            }
            else {
                var colDef = abstractColDef;
                var key = colDef.colId ? colDef.colId : colDef.field;
                var column = _this.columnModel.getPrimaryColumn(key);
                if (!column) {
                    invalidColIds.push(colDef);
                }
                return column;
            }
        };
        var mappedResults = [];
        colDefs.forEach(function (colDef) {
            var result = createDummyColGroup(colDef, 0);
            if (result) {
                // only return correctly mapped colDef results
                mappedResults.push(result);
            }
        });
        if (invalidColIds.length > 0) {
            console.warn('AG Grid: unable to find grid columns for the supplied colDef(s):', invalidColIds);
        }
        return mappedResults;
    };
    ToolPanelColDefService.prototype.syncLayoutWithGrid = function (syncLayoutCallback) {
        // extract ordered list of leaf path trees (column group hierarchy for each individual leaf column)
        var leafPathTrees = this.getLeafPathTrees();
        // merge leaf path tree taking split column groups into account
        var mergedColumnTrees = this.mergeLeafPathTrees(leafPathTrees);
        // sync layout with merged column trees
        syncLayoutCallback(mergedColumnTrees);
    };
    ToolPanelColDefService.prototype.getLeafPathTrees = function () {
        // leaf tree paths are obtained by walking up the tree starting at a column until we reach the top level group.
        var getLeafPathTree = function (node, childDef) {
            var leafPathTree;
            // build up tree in reverse order
            if (node instanceof ProvidedColumnGroup) {
                if (node.isPadding()) {
                    // skip over padding groups
                    leafPathTree = childDef;
                }
                else {
                    var groupDef = Object.assign({}, node.getColGroupDef());
                    // ensure group contains groupId
                    groupDef.groupId = node.getGroupId();
                    groupDef.children = [childDef];
                    leafPathTree = groupDef;
                }
            }
            else {
                var colDef = Object.assign({}, node.getColDef());
                // ensure col contains colId
                colDef.colId = node.getColId();
                leafPathTree = colDef;
            }
            // walk tree
            var parent = node.getOriginalParent();
            if (parent) {
                // keep walking up the tree until we reach the root
                return getLeafPathTree(parent, leafPathTree);
            }
            else {
                // we have reached the root - exit with resulting leaf path tree
                return leafPathTree;
            }
        };
        // obtain a sorted list of all grid columns
        var allGridColumns = this.columnModel.getAllGridColumns();
        // only primary columns and non row group columns should appear in the tool panel
        var allPrimaryGridColumns = allGridColumns.filter(function (column) {
            var colDef = column.getColDef();
            return column.isPrimary() && !colDef.showRowGroup;
        });
        // construct a leaf path tree for each column
        return allPrimaryGridColumns.map(function (col) { return getLeafPathTree(col, col.getColDef()); });
    };
    ToolPanelColDefService.prototype.mergeLeafPathTrees = function (leafPathTrees) {
        var _this = this;
        var matchingRootGroupIds = function (pathA, pathB) {
            var bothPathsAreGroups = _this.isColGroupDef(pathA) && _this.isColGroupDef(pathB);
            return bothPathsAreGroups && _this.getId(pathA) === _this.getId(pathB);
        };
        var mergeTrees = function (treeA, treeB) {
            if (!_this.isColGroupDef(treeB)) {
                return treeA;
            }
            var mergeResult = treeA;
            var groupToMerge = treeB;
            if (groupToMerge.children && groupToMerge.groupId) {
                var added = _this.addChildrenToGroup(mergeResult, groupToMerge.groupId, groupToMerge.children[0]);
                if (added) {
                    return mergeResult;
                }
            }
            groupToMerge.children.forEach(function (child) { return mergeTrees(mergeResult, child); });
            return mergeResult;
        };
        // we can't just merge the leaf path trees as groups can be split apart - instead only merge if leaf
        // path groups with the same root group id are contiguous.
        var mergeColDefs = [];
        for (var i = 1; i <= leafPathTrees.length; i++) {
            var first = leafPathTrees[i - 1];
            var second = leafPathTrees[i];
            if (matchingRootGroupIds(first, second)) {
                leafPathTrees[i] = mergeTrees(first, second);
            }
            else {
                mergeColDefs.push(first);
            }
        }
        return mergeColDefs;
    };
    ToolPanelColDefService.prototype.addChildrenToGroup = function (tree, groupId, colDef) {
        var _this = this;
        var subGroupIsSplit = function (currentSubGroup, currentSubGroupToAdd) {
            var existingChildIds = currentSubGroup.children.map(_this.getId);
            var childGroupAlreadyExists = _.includes(existingChildIds, _this.getId(currentSubGroupToAdd));
            var lastChild = _.last(currentSubGroup.children);
            var lastChildIsDifferent = lastChild && _this.getId(lastChild) !== _this.getId(currentSubGroupToAdd);
            return childGroupAlreadyExists && lastChildIsDifferent;
        };
        if (!this.isColGroupDef(tree)) {
            return true;
        }
        var currentGroup = tree;
        var groupToAdd = colDef;
        if (subGroupIsSplit(currentGroup, groupToAdd)) {
            currentGroup.children.push(groupToAdd);
            return true;
        }
        if (currentGroup.groupId === groupId) {
            // add children that don't already exist to group
            var existingChildIds = currentGroup.children.map(this.getId);
            var colDefAlreadyPresent = _.includes(existingChildIds, this.getId(groupToAdd));
            if (!colDefAlreadyPresent) {
                currentGroup.children.push(groupToAdd);
                return true;
            }
        }
        // recurse until correct group is found to add children
        currentGroup.children.forEach(function (subGroup) { return _this.addChildrenToGroup(subGroup, groupId, colDef); });
        return false;
    };
    __decorate$1l([
        Autowired('columnModel')
    ], ToolPanelColDefService.prototype, "columnModel", void 0);
    ToolPanelColDefService = __decorate$1l([
        Bean('toolPanelColDefService')
    ], ToolPanelColDefService);
    return ToolPanelColDefService;
}(BeanStub));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$f = '31.0.2';

var __extends$1Q = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1k = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var SideBarService = /** @class */ (function (_super) {
    __extends$1Q(SideBarService, _super);
    function SideBarService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SideBarService.prototype.registerSideBarComp = function (sideBarComp) {
        this.sideBarComp = sideBarComp;
    };
    SideBarService.prototype.getSideBarComp = function () {
        return this.sideBarComp;
    };
    SideBarService = __decorate$1k([
        Bean('sideBarService')
    ], SideBarService);
    return SideBarService;
}(BeanStub));

var SideBarModule = {
    version: VERSION$f,
    moduleName: ModuleNames.SideBarModule,
    beans: [ToolPanelColDefService, SideBarService],
    agStackComponents: [
        { componentName: 'AgHorizontalResize', componentClass: HorizontalResizeComp },
        { componentName: 'AgSideBar', componentClass: SideBarComp },
        { componentName: 'AgSideBarButtons', componentClass: SideBarButtonsComp },
    ],
    dependantModules: [
        EnterpriseCoreModule
    ]
};

var __decorate$1j = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ModelItemUtils = /** @class */ (function () {
    function ModelItemUtils() {
    }
    ModelItemUtils.prototype.selectAllChildren = function (colTree, selectAllChecked, eventType) {
        var cols = this.extractAllLeafColumns(colTree);
        this.setAllColumns(cols, selectAllChecked, eventType);
    };
    ModelItemUtils.prototype.setColumn = function (col, selectAllChecked, eventType) {
        this.setAllColumns([col], selectAllChecked, eventType);
    };
    ModelItemUtils.prototype.setAllColumns = function (cols, selectAllChecked, eventType) {
        if (this.columnModel.isPivotMode()) {
            this.setAllPivot(cols, selectAllChecked, eventType);
        }
        else {
            this.setAllVisible(cols, selectAllChecked, eventType);
        }
    };
    ModelItemUtils.prototype.extractAllLeafColumns = function (allItems) {
        var res = [];
        var recursiveFunc = function (items) {
            items.forEach(function (item) {
                if (!item.isPassesFilter()) {
                    return;
                }
                if (item.isGroup()) {
                    recursiveFunc(item.getChildren());
                }
                else {
                    res.push(item.getColumn());
                }
            });
        };
        recursiveFunc(allItems);
        return res;
    };
    ModelItemUtils.prototype.setAllVisible = function (columns, visible, eventType) {
        var colStateItems = [];
        columns.forEach(function (col) {
            if (col.getColDef().lockVisible) {
                return;
            }
            if (col.isVisible() != visible) {
                colStateItems.push({
                    colId: col.getId(),
                    hide: !visible
                });
            }
        });
        if (colStateItems.length > 0) {
            this.columnModel.applyColumnState({ state: colStateItems }, eventType);
        }
    };
    ModelItemUtils.prototype.setAllPivot = function (columns, value, eventType) {
        if (this.gridOptionsService.get('functionsPassive')) {
            this.setAllPivotPassive(columns, value);
        }
        else {
            this.setAllPivotActive(columns, value, eventType);
        }
    };
    ModelItemUtils.prototype.setAllPivotPassive = function (columns, value) {
        var copyOfPivotColumns = this.columnModel.getPivotColumns().slice();
        var copyOfValueColumns = this.columnModel.getValueColumns().slice();
        var copyOfRowGroupColumns = this.columnModel.getRowGroupColumns().slice();
        var pivotChanged = false;
        var valueChanged = false;
        var rowGroupChanged = false;
        var turnOnAction = function (col) {
            // don't change any column that's already got a function active
            if (col.isAnyFunctionActive()) {
                return;
            }
            if (col.isAllowValue()) {
                copyOfValueColumns.push(col);
                valueChanged = true;
            }
            else if (col.isAllowRowGroup()) {
                copyOfRowGroupColumns.push(col);
                pivotChanged = true;
            }
            else if (col.isAllowPivot()) {
                copyOfPivotColumns.push(col);
                rowGroupChanged = true;
            }
        };
        var turnOffAction = function (col) {
            if (!col.isAnyFunctionActive()) {
                return;
            }
            if (copyOfPivotColumns.indexOf(col) >= 0) {
                _.removeFromArray(copyOfPivotColumns, col);
                pivotChanged = true;
            }
            if (copyOfValueColumns.indexOf(col) >= 0) {
                _.removeFromArray(copyOfValueColumns, col);
                valueChanged = true;
            }
            if (copyOfRowGroupColumns.indexOf(col) >= 0) {
                _.removeFromArray(copyOfRowGroupColumns, col);
                rowGroupChanged = true;
            }
        };
        var action = value ? turnOnAction : turnOffAction;
        columns.forEach(action);
        if (pivotChanged) {
            var event_1 = {
                type: Events.EVENT_COLUMN_PIVOT_CHANGE_REQUEST,
                columns: copyOfPivotColumns
            };
            this.eventService.dispatchEvent(event_1);
        }
        if (rowGroupChanged) {
            var event_2 = {
                type: Events.EVENT_COLUMN_ROW_GROUP_CHANGE_REQUEST,
                columns: copyOfRowGroupColumns
            };
            this.eventService.dispatchEvent(event_2);
        }
        if (valueChanged) {
            var event_3 = {
                type: Events.EVENT_COLUMN_VALUE_CHANGE_REQUEST,
                columns: copyOfRowGroupColumns
            };
            this.eventService.dispatchEvent(event_3);
        }
    };
    ModelItemUtils.prototype.setAllPivotActive = function (columns, value, eventType) {
        var _this = this;
        var colStateItems = [];
        var turnOnAction = function (col) {
            // don't change any column that's already got a function active
            if (col.isAnyFunctionActive()) {
                return;
            }
            if (col.isAllowValue()) {
                var aggFunc = typeof col.getAggFunc() === 'string'
                    ? col.getAggFunc()
                    : _this.aggFuncService.getDefaultAggFunc(col);
                colStateItems.push({
                    colId: col.getId(),
                    aggFunc: aggFunc
                });
            }
            else if (col.isAllowRowGroup()) {
                colStateItems.push({
                    colId: col.getId(),
                    rowGroup: true
                });
            }
            else if (col.isAllowPivot()) {
                colStateItems.push({
                    colId: col.getId(),
                    pivot: true
                });
            }
        };
        var turnOffAction = function (col) {
            var isActive = col.isPivotActive() || col.isRowGroupActive() || col.isValueActive();
            if (isActive) {
                colStateItems.push({
                    colId: col.getId(),
                    pivot: false,
                    rowGroup: false,
                    aggFunc: null
                });
            }
        };
        var action = value ? turnOnAction : turnOffAction;
        columns.forEach(action);
        if (colStateItems.length > 0) {
            this.columnModel.applyColumnState({ state: colStateItems }, eventType);
        }
    };
    ModelItemUtils.prototype.updateColumns = function (params) {
        var _this = this;
        var columns = params.columns, visibleState = params.visibleState, pivotState = params.pivotState, eventType = params.eventType;
        var state = columns.map(function (column) {
            var colId = column.getColId();
            if (_this.columnModel.isPivotMode()) {
                var pivotStateForColumn = pivotState === null || pivotState === void 0 ? void 0 : pivotState[colId];
                return {
                    colId: colId,
                    pivot: pivotStateForColumn === null || pivotStateForColumn === void 0 ? void 0 : pivotStateForColumn.pivot,
                    rowGroup: pivotStateForColumn === null || pivotStateForColumn === void 0 ? void 0 : pivotStateForColumn.rowGroup,
                    aggFunc: pivotStateForColumn === null || pivotStateForColumn === void 0 ? void 0 : pivotStateForColumn.aggFunc,
                };
            }
            else {
                return {
                    colId: colId,
                    hide: !(visibleState === null || visibleState === void 0 ? void 0 : visibleState[colId])
                };
            }
        });
        this.columnModel.applyColumnState({ state: state }, eventType);
    };
    ModelItemUtils.prototype.createPivotState = function (column) {
        return {
            pivot: column.isPivotActive(),
            rowGroup: column.isRowGroupActive(),
            aggFunc: column.isValueActive() ? column.getAggFunc() : undefined
        };
    };
    __decorate$1j([
        Autowired('aggFuncService')
    ], ModelItemUtils.prototype, "aggFuncService", void 0);
    __decorate$1j([
        Autowired('columnModel')
    ], ModelItemUtils.prototype, "columnModel", void 0);
    __decorate$1j([
        Autowired('gridOptionsService')
    ], ModelItemUtils.prototype, "gridOptionsService", void 0);
    __decorate$1j([
        Autowired('eventService')
    ], ModelItemUtils.prototype, "eventService", void 0);
    ModelItemUtils = __decorate$1j([
        Bean('modelItemUtils')
    ], ModelItemUtils);
    return ModelItemUtils;
}());

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$e = '31.0.2';

var ColumnsToolPanelModule = {
    version: VERSION$e,
    moduleName: ModuleNames.ColumnsToolPanelModule,
    beans: [ModelItemUtils],
    agStackComponents: [
        { componentName: 'AgPrimaryColsHeader', componentClass: PrimaryColsHeaderPanel },
        { componentName: 'AgPrimaryColsList', componentClass: PrimaryColsListPanel },
        { componentName: 'AgPrimaryCols', componentClass: PrimaryColsPanel }
    ],
    userComponents: [
        { componentName: 'agColumnsToolPanel', componentClass: ColumnToolPanel },
    ],
    dependantModules: [
        EnterpriseCoreModule,
        RowGroupingModule,
        SideBarModule
    ]
};

var coreFactory = {
    getTemplate: function (author) {
        var dt = new Date();
        var jsonDate = dt.toJSON();
        return {
            name: 'cp:coreProperties',
            properties: {
                prefixedAttributes: [{
                        prefix: "xmlns:",
                        map: {
                            cp: "http://schemas.openxmlformats.org/package/2006/metadata/core-properties",
                            dc: 'http://purl.org/dc/elements/1.1/',
                            dcterms: 'http://purl.org/dc/terms/',
                            dcmitype: 'http://purl.org/dc/dcmitype/',
                            xsi: 'http://www.w3.org/2001/XMLSchema-instance'
                        }
                    }]
            },
            children: [{
                    name: 'dc:creator',
                    textNode: author
                }, {
                    name: 'dc:title',
                    textNode: 'Workbook'
                }, {
                    name: 'dcterms:created',
                    properties: {
                        rawMap: {
                            'xsi:type': 'dcterms:W3CDTF'
                        }
                    },
                    textNode: jsonDate
                }, {
                    name: 'dcterms:modified',
                    properties: {
                        rawMap: {
                            'xsi:type': 'dcterms:W3CDTF'
                        }
                    },
                    textNode: jsonDate
                }]
        };
    }
};

var contentTypeFactory = {
    getTemplate: function (config) {
        var name = config.name, ContentType = config.ContentType, Extension = config.Extension, PartName = config.PartName;
        return {
            name: name,
            properties: {
                rawMap: {
                    Extension: Extension,
                    PartName: PartName,
                    ContentType: ContentType
                }
            }
        };
    }
};

var __read$w = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$i = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var contentTypesFactory = {
    getTemplate: function (sheetLen) {
        var worksheets = new Array(sheetLen).fill(undefined).map(function (v, i) { return ({
            name: 'Override',
            ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet+xml',
            PartName: "/xl/worksheets/sheet".concat(i + 1, ".xml")
        }); });
        var sheetsWithImages = ExcelXlsxFactory.worksheetImages.size;
        var imageTypesObject = {};
        ExcelXlsxFactory.workbookImageIds.forEach(function (v) {
            imageTypesObject[v.type] = true;
        });
        var imageDocs = new Array(sheetsWithImages).fill(undefined).map(function (v, i) { return ({
            name: 'Override',
            ContentType: 'application/vnd.openxmlformats-officedocument.drawing+xml',
            PartName: "/xl/drawings/drawing".concat(i + 1, ".xml")
        }); });
        var imageTypes = Object.keys(imageTypesObject).map(function (ext) { return ({
            name: 'Default',
            ContentType: "image/".concat(ext),
            Extension: ext
        }); });
        var children = __spreadArray$i(__spreadArray$i(__spreadArray$i(__spreadArray$i(__spreadArray$i(__spreadArray$i([], __read$w(imageTypes), false), [
            {
                name: 'Default',
                Extension: 'rels',
                ContentType: 'application/vnd.openxmlformats-package.relationships+xml'
            }, {
                name: 'Default',
                ContentType: 'application/xml',
                Extension: 'xml'
            }, {
                name: 'Override',
                ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml',
                PartName: "/xl/workbook.xml"
            }
        ], false), __read$w(worksheets), false), [
            {
                name: 'Override',
                ContentType: 'application/vnd.openxmlformats-officedocument.theme+xml',
                PartName: '/xl/theme/theme1.xml'
            }, {
                name: 'Override',
                ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.styles+xml',
                PartName: '/xl/styles.xml'
            }, {
                name: 'Override',
                ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sharedStrings+xml',
                PartName: '/xl/sharedStrings.xml'
            }
        ], false), __read$w(imageDocs), false), [
            {
                name: 'Override',
                ContentType: 'application/vnd.openxmlformats-package.core-properties+xml',
                PartName: '/docProps/core.xml'
            }
        ], false).map(function (contentType) { return contentTypeFactory.getTemplate(contentType); });
        return {
            name: "Types",
            properties: {
                rawMap: {
                    xmlns: "http://schemas.openxmlformats.org/package/2006/content-types"
                }
            },
            children: children
        };
    }
};

var INCH_TO_EMU = 9525;
var numberFormatMap = {
    '0': 1,
    '0.00': 2,
    '#,##0': 3,
    '#,##0.00': 4,
    '0%': 9,
    '0.00%': 10,
    '0.00E+00': 11,
    '# ?/?': 12,
    '# ??/??': 13,
    'mm-dd-yy': 14,
    'd-mmm-yy': 15,
    'd-mmm': 16,
    'mmm-yy': 17,
    'h:mm AM/PM': 18,
    'h:mm:ss AM/PM': 19,
    'h:mm': 20,
    'h:mm:ss': 21,
    'm/d/yy h:mm': 22,
    '#,##0 ;(#,##0)': 37,
    '#,##0 ;[Red](#,##0)': 38,
    '#,##0.00;(#,##0.00)': 39,
    '#,##0.00;[Red](#,##0.00)': 40,
    'mm:ss': 45,
    '[h]:mm:ss': 46,
    'mmss.0': 47,
    '##0.0E+0': 48,
    '@': 49
};

var pixelsToPoint = function (pixels) {
    return Math.round(pixels * 72 / 96);
};
var pointsToPixel = function (points) {
    return Math.round(points * 96 / 72);
};
var pixelsToEMU = function (value) {
    return Math.ceil(value * INCH_TO_EMU);
};
var getFontFamilyId = function (name) {
    if (name === undefined) {
        return;
    }
    var families = ['Automatic', 'Roman', 'Swiss', 'Modern', 'Script', 'Decorative'];
    var pos = families.indexOf(name || 'Automatic');
    return Math.max(pos, 0);
};
var getHeightFromProperty = function (rowIndex, height) {
    if (!height) {
        return;
    }
    var finalHeight;
    if (typeof height === 'number') {
        finalHeight = height;
    }
    else {
        var heightFunc = height;
        finalHeight = heightFunc({ rowIndex: rowIndex });
    }
    return pixelsToPoint(finalHeight);
};
var setExcelImageTotalWidth = function (image, columnsToExport) {
    var _a = image.position, colSpan = _a.colSpan, column = _a.column;
    if (image.width) {
        if (colSpan) {
            var columnsInSpan = columnsToExport.slice(column - 1, column + colSpan - 1);
            var totalWidth = 0;
            for (var i = 0; i < columnsInSpan.length; i++) {
                var colWidth = columnsInSpan[i].getActualWidth();
                if (image.width < totalWidth + colWidth) {
                    image.position.colSpan = i + 1;
                    image.totalWidth = image.width;
                    image.width = image.totalWidth - totalWidth;
                    break;
                }
                totalWidth += colWidth;
            }
        }
        else {
            image.totalWidth = image.width;
        }
    }
};
var setExcelImageTotalHeight = function (image, rowHeight) {
    var _a = image.position, rowSpan = _a.rowSpan, row = _a.row;
    if (image.height) {
        if (rowSpan) {
            var totalHeight = 0;
            var counter = 0;
            for (var i = row; i < row + rowSpan; i++) {
                var nextRowHeight = pointsToPixel(getHeightFromProperty(i, rowHeight) || 20);
                if (image.height < totalHeight + nextRowHeight) {
                    image.position.rowSpan = counter + 1;
                    image.totalHeight = image.height;
                    image.height = image.totalHeight - totalHeight;
                    break;
                }
                totalHeight += nextRowHeight;
                counter++;
            }
        }
        else {
            image.totalHeight = image.height;
        }
    }
};
var createXmlPart = function (body) {
    var header = XmlFactory.createHeader({
        encoding: 'UTF-8',
        standalone: 'yes'
    });
    var xmlBody = XmlFactory.createXml(body);
    return "".concat(header).concat(xmlBody);
};
var getExcelColumnName = function (colIdx) {
    var startCode = 65;
    var tableWidth = 26;
    var fromCharCode = String.fromCharCode;
    var pos = Math.floor(colIdx / tableWidth);
    var tableIdx = colIdx % tableWidth;
    if (!pos || colIdx === tableWidth) {
        return fromCharCode(startCode + colIdx - 1);
    }
    if (!tableIdx) {
        return getExcelColumnName(pos - 1) + 'Z';
    }
    if (pos < tableWidth) {
        return fromCharCode(startCode + pos - 1) + fromCharCode(startCode + tableIdx - 1);
    }
    return getExcelColumnName(pos) + fromCharCode(startCode + tableIdx - 1);
};

var getAnchor = function (name, imageAnchor) { return ({
    name: "xdr:".concat(name),
    children: [{
            name: 'xdr:col',
            textNode: (imageAnchor.col).toString()
        }, {
            name: 'xdr:colOff',
            textNode: imageAnchor.offsetX.toString()
        }, {
            name: 'xdr:row',
            textNode: imageAnchor.row.toString()
        }, {
            name: 'xdr:rowOff',
            textNode: imageAnchor.offsetY.toString()
        }]
}); };
var getExt = function (image) {
    var children = [{
            name: 'a:ext',
            properties: {
                rawMap: {
                    uri: '{FF2B5EF4-FFF2-40B4-BE49-F238E27FC236}'
                }
            },
            children: [{
                    name: 'a16:creationId',
                    properties: {
                        rawMap: {
                            'id': '{822E6D20-D7BC-2841-A643-D49A6EF008A2}',
                            'xmlns:a16': 'http://schemas.microsoft.com/office/drawing/2014/main'
                        }
                    }
                }]
        }];
    var recolor = image.recolor && image.recolor.toLowerCase();
    switch (recolor) {
        case 'grayscale':
        case 'sepia':
        case 'washout':
            children.push({
                name: 'a:ext',
                properties: {
                    rawMap: {
                        uri: '{C183D7F6-B498-43B3-948B-1728B52AA6E4}'
                    }
                },
                children: [{
                        name: 'adec:decorative',
                        properties: {
                            rawMap: {
                                'val': '0',
                                'xmlns:adec': 'http://schemas.microsoft.com/office/drawing/2017/decorative'
                            }
                        }
                    }]
            });
    }
    return {
        name: 'a:extLst',
        children: children
    };
};
var getNvPicPr = function (image, index) { return ({
    name: 'xdr:nvPicPr',
    children: [{
            name: 'xdr:cNvPr',
            properties: {
                rawMap: {
                    id: index,
                    name: image.id,
                    descr: image.altText != null ? image.altText : undefined
                }
            },
            children: [getExt(image)]
        }, {
            name: 'xdr:cNvPicPr',
            properties: {
                rawMap: {
                    preferRelativeResize: '0'
                }
            },
            children: [{
                    name: 'a:picLocks'
                }]
        }]
}); };
var getColorDetails = function (color) {
    if (!color.saturation && !color.tint) {
        return;
    }
    var ret = [];
    if (color.saturation) {
        ret.push({
            name: 'a:satMod',
            properties: {
                rawMap: {
                    val: color.saturation * 1000
                }
            }
        });
    }
    if (color.tint) {
        ret.push({
            name: 'a:tint',
            properties: {
                rawMap: {
                    val: color.tint * 1000
                }
            }
        });
    }
    return ret;
};
var getDuoTone = function (primaryColor, secondaryColor) {
    return ({
        name: 'a:duotone',
        children: [{
                name: 'a:prstClr',
                properties: {
                    rawMap: {
                        val: primaryColor.color
                    }
                },
                children: getColorDetails(primaryColor)
            }, {
                name: 'a:srgbClr',
                properties: {
                    rawMap: {
                        val: secondaryColor.color
                    }
                },
                children: getColorDetails(secondaryColor)
            }]
    });
};
var getBlipFill = function (image, index) {
    var blipChildren;
    if (image.transparency) {
        var transparency = Math.min(Math.max(image.transparency, 0), 100);
        blipChildren = [{
                name: 'a:alphaModFix',
                properties: {
                    rawMap: {
                        amt: 100000 - Math.round(transparency * 1000),
                    }
                }
            }];
    }
    if (image.recolor) {
        if (!blipChildren) {
            blipChildren = [];
        }
        switch (image.recolor.toLocaleLowerCase()) {
            case 'grayscale':
                blipChildren.push({ name: 'a:grayscl' });
                break;
            case 'sepia':
                blipChildren.push(getDuoTone({ color: 'black' }, { color: 'D9C3A5', tint: 50, saturation: 180 }));
                break;
            case 'washout':
                blipChildren.push({
                    name: 'a:lum',
                    properties: {
                        rawMap: {
                            bright: '70000',
                            contrast: '-70000'
                        }
                    }
                });
                break;
        }
    }
    return ({
        name: 'xdr:blipFill',
        children: [{
                name: 'a:blip',
                properties: {
                    rawMap: {
                        'cstate': 'print',
                        'r:embed': "rId".concat(index),
                        'xmlns:r': 'http://schemas.openxmlformats.org/officeDocument/2006/relationships'
                    }
                },
                children: blipChildren
            }, {
                name: 'a:stretch',
                children: [{
                        name: 'a:fillRect'
                    }]
            }]
    });
};
var getSpPr = function (image, imageBoxSize) {
    var xfrm = {
        name: 'a:xfrm',
        children: [{
                name: 'a:off',
                properties: {
                    rawMap: {
                        x: 0,
                        y: 0
                    }
                }
            }, {
                name: 'a:ext',
                properties: {
                    rawMap: {
                        cx: imageBoxSize.width,
                        cy: imageBoxSize.height
                    }
                }
            }]
    };
    if (image.rotation) {
        var rotation = image.rotation;
        xfrm.properties = {
            rawMap: {
                rot: Math.min(Math.max(rotation, 0), 360) * 60000
            }
        };
    }
    var prstGeom = {
        name: 'a:prstGeom',
        properties: {
            rawMap: {
                prst: 'rect'
            }
        },
        children: [{ name: 'a:avLst' }]
    };
    var ret = {
        name: 'xdr:spPr',
        children: [xfrm, prstGeom]
    };
    return ret;
};
var getImageBoxSize = function (image) {
    image.fitCell = !!image.fitCell || (!image.width || !image.height);
    var _a = image.position, position = _a === void 0 ? {} : _a, fitCell = image.fitCell, _b = image.width, width = _b === void 0 ? 0 : _b, _c = image.height, height = _c === void 0 ? 0 : _c, totalHeight = image.totalHeight, totalWidth = image.totalWidth;
    var _d = position.offsetX, offsetX = _d === void 0 ? 0 : _d, _e = position.offsetY, offsetY = _e === void 0 ? 0 : _e, _f = position.row, row = _f === void 0 ? 1 : _f, _g = position.rowSpan, rowSpan = _g === void 0 ? 1 : _g, _h = position.column, column = _h === void 0 ? 1 : _h, _j = position.colSpan, colSpan = _j === void 0 ? 1 : _j;
    return {
        from: {
            row: row - 1,
            col: column - 1,
            offsetX: pixelsToEMU(offsetX),
            offsetY: pixelsToEMU(offsetY)
        },
        to: {
            row: (row - 1) + (fitCell ? 1 : rowSpan - 1),
            col: (column - 1) + (fitCell ? 1 : colSpan - 1),
            offsetX: pixelsToEMU(width + offsetX),
            offsetY: pixelsToEMU(height + offsetY)
        },
        height: pixelsToEMU(totalHeight || height),
        width: pixelsToEMU(totalWidth || width)
    };
};
var getPicture = function (image, currentIndex, worksheetImageIndex, imageBoxSize) {
    return {
        name: 'xdr:pic',
        children: [
            getNvPicPr(image, currentIndex + 1),
            getBlipFill(image, worksheetImageIndex + 1),
            getSpPr(image, imageBoxSize)
        ]
    };
};
var drawingFactory = {
    getTemplate: function (config) {
        var sheetIndex = config.sheetIndex;
        var sheetImages = ExcelXlsxFactory.worksheetImages.get(sheetIndex);
        var sheetImageIds = ExcelXlsxFactory.worksheetImageIds.get(sheetIndex);
        var children = sheetImages.map(function (image, idx) {
            var boxSize = getImageBoxSize(image);
            return ({
                name: 'xdr:twoCellAnchor',
                properties: {
                    rawMap: {
                        editAs: 'absolute'
                    }
                },
                children: [
                    getAnchor('from', boxSize.from),
                    getAnchor('to', boxSize.to),
                    getPicture(image, idx, sheetImageIds.get(image.id).index, boxSize),
                    { name: 'xdr:clientData' }
                ]
            });
        });
        return {
            name: 'xdr:wsDr',
            properties: {
                rawMap: {
                    'xmlns:a': 'http://schemas.openxmlformats.org/drawingml/2006/main',
                    'xmlns:xdr': 'http://schemas.openxmlformats.org/drawingml/2006/spreadsheetDrawing'
                }
            },
            children: children
        };
    }
};

var __read$v = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var getColorChildren = function (props) {
    var _a = __read$v(props, 4), type = _a[0], innerType = _a[1], val = _a[2], lastClr = _a[3];
    return {
        name: "a:".concat(type),
        children: [{
                name: "a:".concat(innerType),
                properties: {
                    rawMap: {
                        val: val,
                        lastClr: lastClr
                    }
                }
            }]
    };
};
var colorScheme = {
    getTemplate: function () {
        return {
            name: "a:clrScheme",
            properties: {
                rawMap: {
                    name: "Office"
                }
            },
            children: [
                getColorChildren(['dk1', 'sysClr', 'windowText', '000000']),
                getColorChildren(['lt1', 'sysClr', 'window', 'FFFFFF']),
                getColorChildren(['dk2', 'srgbClr', '44546A']),
                getColorChildren(['lt2', 'srgbClr', 'E7E6E6']),
                getColorChildren(['accent1', 'srgbClr', '4472C4']),
                getColorChildren(['accent2', 'srgbClr', 'ED7D31']),
                getColorChildren(['accent3', 'srgbClr', 'A5A5A5']),
                getColorChildren(['accent4', 'srgbClr', 'FFC000']),
                getColorChildren(['accent5', 'srgbClr', '5B9BD5']),
                getColorChildren(['accent6', 'srgbClr', '70AD47']),
                getColorChildren(['hlink', 'srgbClr', '0563C1']),
                getColorChildren(['folHlink', 'srgbClr', '954F72'])
            ]
        };
    }
};

var __read$u = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var getFont$2 = function (props) {
    var _a = __read$u(props, 4), type = _a[0], typeface = _a[1], script = _a[2], panose = _a[3];
    return {
        name: "a:".concat(type),
        properties: {
            rawMap: {
                script: script,
                typeface: typeface,
                panose: panose
            }
        }
    };
};
var fontScheme = {
    getTemplate: function () {
        var utf8_encode = _.utf8_encode;
        return {
            name: "a:fontScheme",
            properties: {
                rawMap: {
                    name: "Office"
                }
            },
            children: [{
                    name: 'a:majorFont',
                    children: [
                        getFont$2(['latin', 'Calibri Light', undefined, '020F0302020204030204']),
                        getFont$2(['ea', '']),
                        getFont$2(['cs', '']),
                        getFont$2(['font', utf8_encode('游ゴシック Light'), 'Jpan']),
                        getFont$2(['font', utf8_encode('맑은 고딕'), 'Hang']),
                        getFont$2(['font', utf8_encode('等线 Light'), 'Hans']),
                        getFont$2(['font', utf8_encode('新細明體'), 'Hant']),
                        getFont$2(['font', 'Times New Roman', 'Arab']),
                        getFont$2(['font', 'Times New Roman', 'Hebr']),
                        getFont$2(['font', 'Tahoma', 'Thai']),
                        getFont$2(['font', 'Nyala', 'Ethi']),
                        getFont$2(['font', 'Vrinda', 'Beng']),
                        getFont$2(['font', 'Shruti', 'Gujr']),
                        getFont$2(['font', 'MoolBoran', 'Khmr']),
                        getFont$2(['font', 'Tunga', 'Knda']),
                        getFont$2(['font', 'Raavi', 'Guru']),
                        getFont$2(['font', 'Euphemia', 'Cans']),
                        getFont$2(['font', 'Plantagenet Cherokee', 'Cher']),
                        getFont$2(['font', 'Microsoft Yi Baiti', 'Yiii']),
                        getFont$2(['font', 'Microsoft Himalaya', 'Tibt']),
                        getFont$2(['font', 'MV Boli', 'Thaa']),
                        getFont$2(['font', 'Mangal', 'Deva']),
                        getFont$2(['font', 'Gautami', 'Telu']),
                        getFont$2(['font', 'Latha', 'Taml']),
                        getFont$2(['font', 'Estrangelo Edessa', 'Syrc']),
                        getFont$2(['font', 'Kalinga', 'Orya']),
                        getFont$2(['font', 'Kartika', 'Mlym']),
                        getFont$2(['font', 'DokChampa', 'Laoo']),
                        getFont$2(['font', 'Iskoola Pota', 'Sinh']),
                        getFont$2(['font', 'Mongolian Baiti', 'Mong']),
                        getFont$2(['font', 'Times New Roman', 'Viet']),
                        getFont$2(['font', 'Microsoft Uighur', 'Uigh']),
                        getFont$2(['font', 'Sylfaen', 'Geor']),
                        getFont$2(['font', 'Arial', 'Armn']),
                        getFont$2(['font', 'Leelawadee UI', 'Bugi']),
                        getFont$2(['font', 'Microsoft JhengHei', 'Bopo']),
                        getFont$2(['font', 'Javanese Text', 'Java']),
                        getFont$2(['font', 'Segoe UI', 'Lisu']),
                        getFont$2(['font', 'Myanmar Text', 'Mymr']),
                        getFont$2(['font', 'Ebrima', 'Nkoo']),
                        getFont$2(['font', 'Nirmala UI', 'Olck']),
                        getFont$2(['font', 'Ebrima', 'Osma']),
                        getFont$2(['font', 'Phagspa', 'Phag']),
                        getFont$2(['font', 'Estrangelo Edessa', 'Syrn']),
                        getFont$2(['font', 'Estrangelo Edessa', 'Syrj']),
                        getFont$2(['font', 'Estrangelo Edessa', 'Syre']),
                        getFont$2(['font', 'Nirmala UI', 'Sora']),
                        getFont$2(['font', 'Microsoft Tai Le', 'Tale']),
                        getFont$2(['font', 'Microsoft New Tai Lue', 'Talu']),
                        getFont$2(['font', 'Ebrima', 'Tfng'])
                    ]
                }, {
                    name: 'a:minorFont',
                    children: [
                        getFont$2(['latin', 'Calibri', undefined, '020F0502020204030204']),
                        getFont$2(['ea', '']),
                        getFont$2(['cs', '']),
                        getFont$2(['font', utf8_encode('游ゴシック'), 'Jpan']),
                        getFont$2(['font', utf8_encode('맑은 고딕'), 'Hang']),
                        getFont$2(['font', utf8_encode('等线'), 'Hans']),
                        getFont$2(['font', utf8_encode('新細明體'), 'Hant']),
                        getFont$2(['font', 'Arial', 'Arab']),
                        getFont$2(['font', 'Arial', 'Hebr']),
                        getFont$2(['font', 'Tahoma', 'Thai']),
                        getFont$2(['font', 'Nyala', 'Ethi']),
                        getFont$2(['font', 'Vrinda', 'Beng']),
                        getFont$2(['font', 'Shruti', 'Gujr']),
                        getFont$2(['font', 'DaunPenh', 'Khmr']),
                        getFont$2(['font', 'Tunga', 'Knda']),
                        getFont$2(['font', 'Raavi', 'Guru']),
                        getFont$2(['font', 'Euphemia', 'Cans']),
                        getFont$2(['font', 'Plantagenet Cherokee', 'Cher']),
                        getFont$2(['font', 'Microsoft Yi Baiti', 'Yiii']),
                        getFont$2(['font', 'Microsoft Himalaya', 'Tibt']),
                        getFont$2(['font', 'MV Boli', 'Thaa']),
                        getFont$2(['font', 'Mangal', 'Deva']),
                        getFont$2(['font', 'Gautami', 'Telu']),
                        getFont$2(['font', 'Latha', 'Taml']),
                        getFont$2(['font', 'Estrangelo Edessa', 'Syrc']),
                        getFont$2(['font', 'Kalinga', 'Orya']),
                        getFont$2(['font', 'Kartika', 'Mlym']),
                        getFont$2(['font', 'DokChampa', 'Laoo']),
                        getFont$2(['font', 'Iskoola Pota', 'Sinh']),
                        getFont$2(['font', 'Mongolian Baiti', 'Mong']),
                        getFont$2(['font', 'Arial', 'Viet']),
                        getFont$2(['font', 'Microsoft Uighur', 'Uigh']),
                        getFont$2(['font', 'Sylfaen', 'Geor']),
                        getFont$2(['font', 'Arial', 'Armn']),
                        getFont$2(['font', 'Leelawadee UI', 'Bugi']),
                        getFont$2(['font', 'Microsoft JhengHei', 'Bopo']),
                        getFont$2(['font', 'Javanese Text', 'Java']),
                        getFont$2(['font', 'Segoe UI', 'Lisu']),
                        getFont$2(['font', 'Myanmar Text', 'Mymr']),
                        getFont$2(['font', 'Ebrima', 'Nkoo']),
                        getFont$2(['font', 'Nirmala UI', 'Olck']),
                        getFont$2(['font', 'Ebrima', 'Osma']),
                        getFont$2(['font', 'Phagspa', 'Phag']),
                        getFont$2(['font', 'Estrangelo Edessa', 'Syrn']),
                        getFont$2(['font', 'Estrangelo Edessa', 'Syrj']),
                        getFont$2(['font', 'Estrangelo Edessa', 'Syre']),
                        getFont$2(['font', 'Nirmala UI', 'Sora']),
                        getFont$2(['font', 'Microsoft Tai Le', 'Tale']),
                        getFont$2(['font', 'Microsoft New Tai Lue', 'Talu']),
                        getFont$2(['font', 'Ebrima', 'Tfng'])
                    ]
                }]
        };
    }
};

var __read$t = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var getPropertyVal = function (name, val, children) { return ({
    name: "a:".concat(name),
    properties: {
        rawMap: {
            val: val
        }
    },
    children: children
}); };
var getGs = function (props) {
    var _a = __read$t(props, 6), pos = _a[0], schemeColor = _a[1], satMod = _a[2], lumMod = _a[3], tint = _a[4], shade = _a[5];
    var children = [];
    children.push(getPropertyVal('satMod', satMod));
    if (lumMod) {
        children.push(getPropertyVal('lumMod', lumMod));
    }
    if (tint) {
        children.push(getPropertyVal('tint', tint));
    }
    if (shade) {
        children.push(getPropertyVal('shade', shade));
    }
    return {
        name: 'a:gs',
        properties: {
            rawMap: {
                pos: pos
            }
        },
        children: [{
                name: 'a:schemeClr',
                properties: {
                    rawMap: {
                        val: schemeColor
                    }
                },
                children: children
            }]
    };
};
var getSolidFill = function (val, children) { return ({
    name: 'a:solidFill',
    children: [getPropertyVal('schemeClr', val, children)]
}); };
var getGradFill = function (props) {
    var _a = __read$t(props, 5), rotWithShape = _a[0], gs1 = _a[1], gs2 = _a[2], gs3 = _a[3], lin = _a[4];
    var _b = __read$t(lin, 2), ang = _b[0], scaled = _b[1];
    return {
        name: 'a:gradFill',
        properties: {
            rawMap: {
                rotWithShape: rotWithShape
            }
        },
        children: [{
                name: 'a:gsLst',
                children: [
                    getGs(gs1),
                    getGs(gs2),
                    getGs(gs3)
                ]
            }, {
                name: 'a:lin',
                properties: {
                    rawMap: {
                        ang: ang,
                        scaled: scaled
                    }
                }
            }]
    };
};
var getLine = function (props) {
    var _a = __read$t(props, 4), w = _a[0], cap = _a[1], cmpd = _a[2], algn = _a[3];
    return {
        name: 'a:ln',
        properties: {
            rawMap: { w: w, cap: cap, cmpd: cmpd, algn: algn }
        },
        children: [
            getSolidFill('phClr'),
            getPropertyVal('prstDash', 'solid'),
            {
                name: 'a:miter',
                properties: {
                    rawMap: {
                        lim: '800000'
                    }
                }
            }
        ]
    };
};
var getEffectStyle = function (shadow) {
    var children = [];
    if (shadow) {
        var _a = __read$t(shadow, 5), blurRad = _a[0], dist = _a[1], dir = _a[2], algn = _a[3], rotWithShape = _a[4];
        children.push({
            name: 'a:outerShdw',
            properties: {
                rawMap: { blurRad: blurRad, dist: dist, dir: dir, algn: algn, rotWithShape: rotWithShape }
            },
            children: [
                getPropertyVal('srgbClr', '000000', [getPropertyVal('alpha', '63000')])
            ]
        });
    }
    return {
        name: 'a:effectStyle',
        children: [Object.assign({}, {
                name: 'a:effectLst'
            }, children.length ? { children: children } : {})]
    };
};
var getFillStyleList = function () { return ({
    name: 'a:fillStyleLst',
    children: [
        getSolidFill('phClr'),
        getGradFill([
            '1',
            ['0', 'phClr', '105000', '110000', '67000'],
            ['50000', 'phClr', '103000', '105000', '73000'],
            ['100000', 'phClr', '109000', '105000', '81000'],
            ['5400000', '0']
        ]),
        getGradFill([
            '1',
            ['0', 'phClr', '103000', '102000', '94000'],
            ['50000', 'phClr', '110000', '100000', undefined, '100000'],
            ['100000', 'phClr', '120000', '99000', undefined, '78000'],
            ['5400000', '0']
        ])
    ]
}); };
var getLineStyleList = function () { return ({
    name: 'a:lnStyleLst',
    children: [
        getLine(['6350', 'flat', 'sng', 'ctr']),
        getLine(['12700', 'flat', 'sng', 'ctr']),
        getLine(['19050', 'flat', 'sng', 'ctr'])
    ]
}); };
var getEffectStyleList = function () { return ({
    name: 'a:effectStyleLst',
    children: [
        getEffectStyle(),
        getEffectStyle(),
        getEffectStyle(['57150', '19050', '5400000', 'ctr', '0'])
    ]
}); };
var getBgFillStyleList = function () { return ({
    name: 'a:bgFillStyleLst',
    children: [
        getSolidFill('phClr'),
        getSolidFill('phClr', [
            getPropertyVal('tint', '95000'),
            getPropertyVal('satMod', '170000'),
        ]),
        getGradFill([
            '1',
            ['0', 'phClr', '150000', '102000', '93000', '98000'],
            ['50000', 'phClr', '130000', '103000', '98000', '90000'],
            ['100000', 'phClr', '120000', undefined, undefined, '63000'],
            ['5400000', '0']
        ])
    ]
}); };
var formatScheme = {
    getTemplate: function () {
        return {
            name: "a:fmtScheme",
            properties: {
                rawMap: {
                    name: "Office"
                }
            },
            children: [
                getFillStyleList(),
                getLineStyleList(),
                getEffectStyleList(),
                getBgFillStyleList()
            ]
        };
    }
};

var themeElements = {
    getTemplate: function () {
        return {
            name: "a:themeElements",
            children: [
                colorScheme.getTemplate(),
                fontScheme.getTemplate(),
                formatScheme.getTemplate()
            ]
        };
    }
};

var officeTheme = {
    getTemplate: function () {
        return {
            name: "a:theme",
            properties: {
                prefixedAttributes: [{
                        prefix: "xmlns:",
                        map: {
                            a: "http://schemas.openxmlformats.org/drawingml/2006/main"
                        },
                    }],
                rawMap: {
                    name: "Office Theme"
                }
            },
            children: [
                themeElements.getTemplate(),
                {
                    name: 'a:objectDefaults'
                },
                {
                    name: 'a:extraClrSchemeLst'
                }
            ]
        };
    }
};

var buildSharedString = function (strMap) {
    var ret = [];
    strMap.forEach(function (val, key) {
        var textNode = key.toString();
        var child = {
            name: 't',
            textNode: _.utf8_encode(_.escapeString(textNode))
        };
        // if we have leading or trailing spaces, instruct Excel not to trim them
        var preserveSpaces = textNode.trim().length !== textNode.length;
        if (preserveSpaces) {
            child.properties = {
                rawMap: {
                    "xml:space": "preserve"
                }
            };
        }
        ret.push({
            name: 'si',
            children: [child]
        });
    });
    return ret;
};
var sharedStrings = {
    getTemplate: function (strings) {
        return {
            name: "sst",
            properties: {
                rawMap: {
                    xmlns: 'http://schemas.openxmlformats.org/spreadsheetml/2006/main',
                    count: strings.size,
                    uniqueCount: strings.size
                }
            },
            children: buildSharedString(strings)
        };
    }
};

function prepareString(str) {
    var split = str.split(/(\[[^\]]*\])/);
    for (var i = 0; i < split.length; i++) {
        // excel formulas require symbols to be escaped. Excel also requires $ to be 
        // placed in quotes but only when the $ is not wrapped inside of square brackets.
        var currentString = split[i];
        if (!currentString.length) {
            continue;
        }
        if (!currentString.startsWith('[')) {
            currentString = currentString.replace(/\$/g, '"$"');
        }
        split[i] = _.escapeString(currentString);
    }
    return split.join('');
}
var numberFormatFactory = {
    getTemplate: function (numberFormat) {
        var formatCode = numberFormat.formatCode, numFmtId = numberFormat.numFmtId;
        if (formatCode.length) {
            formatCode = prepareString(formatCode);
        }
        return {
            name: "numFmt",
            properties: {
                rawMap: {
                    formatCode: formatCode,
                    numFmtId: numFmtId
                }
            }
        };
    }
};

var numberFormatsFactory = {
    getTemplate: function (numberFormats) {
        return {
            name: "numFmts",
            properties: {
                rawMap: {
                    count: numberFormats.length
                }
            },
            children: numberFormats.map(function (numberFormat) { return numberFormatFactory.getTemplate(numberFormat); })
        };
    }
};

var fontFactory = {
    getTemplate: function (font) {
        var size = font.size, colorTheme = font.colorTheme, _a = font.color, color = _a === void 0 ? 'FF000000' : _a, _b = font.fontName, fontName = _b === void 0 ? 'Calibri' : _b, family = font.family, scheme = font.scheme, italic = font.italic, bold = font.bold, strikeThrough = font.strikeThrough, outline = font.outline, shadow = font.shadow, underline = font.underline, verticalAlign = font.verticalAlign;
        var children = [
            { name: 'sz', properties: { rawMap: { val: size } } },
            { name: 'color', properties: { rawMap: { theme: colorTheme, rgb: color } } },
            { name: 'name', properties: { rawMap: { val: fontName } } }
        ];
        if (family) {
            children.push({ name: 'family', properties: { rawMap: { val: family } } });
        }
        if (scheme) {
            children.push({ name: 'scheme', properties: { rawMap: { val: scheme } } });
        }
        if (italic) {
            children.push({ name: 'i' });
        }
        if (bold) {
            children.push({ name: 'b' });
        }
        if (strikeThrough) {
            children.push({ name: 'strike' });
        }
        if (outline) {
            children.push({ name: 'outline' });
        }
        if (shadow) {
            children.push({ name: 'shadow' });
        }
        if (underline) {
            children.push({ name: 'u', properties: { rawMap: { val: underline } } });
        }
        if (verticalAlign) {
            children.push({ name: 'vertAlign', properties: { rawMap: { val: verticalAlign } } });
        }
        return { name: "font", children: children };
    }
};

var fontsFactory = {
    getTemplate: function (fonts) {
        return {
            name: "fonts",
            properties: {
                rawMap: {
                    count: fonts.length
                }
            },
            children: fonts.map(function (font) { return fontFactory.getTemplate(font); })
        };
    }
};

var fillFactory = {
    getTemplate: function (fill) {
        var patternType = fill.patternType, fgTheme = fill.fgTheme, fgTint = fill.fgTint, fgRgb = fill.fgRgb, bgRgb = fill.bgRgb, bgIndexed = fill.bgIndexed;
        var pf = {
            name: 'patternFill',
            properties: {
                rawMap: {
                    patternType: patternType
                }
            }
        };
        if (fgTheme || fgTint || fgRgb) {
            pf.children = [{
                    name: 'fgColor',
                    properties: {
                        rawMap: {
                            theme: fgTheme,
                            tint: fgTint,
                            rgb: fgRgb
                        }
                    }
                }];
        }
        if (bgIndexed || bgRgb) {
            if (!pf.children) {
                pf.children = [];
            }
            pf.children.push({
                name: 'bgColor',
                properties: {
                    rawMap: {
                        indexed: bgIndexed,
                        rgb: bgRgb
                    }
                }
            });
        }
        return {
            name: "fill",
            children: [pf]
        };
    }
};

var fillsFactory = {
    getTemplate: function (fills) {
        return {
            name: "fills",
            properties: {
                rawMap: {
                    count: fills.length
                }
            },
            children: fills.map(function (fill) { return fillFactory.getTemplate(fill); })
        };
    }
};

var getWeightName = function (value) {
    switch (value) {
        case 1: return 'thin';
        case 2: return 'medium';
        case 3: return 'thick';
        default: return 'hair';
    }
};
var mappedBorderNames = {
    None: 'None',
    Dot: 'Dotted',
    Dash: 'Dashed',
    Double: 'Double',
    DashDot: 'DashDot',
    DashDotDot: 'DashDotDot',
    SlantDashDot: 'SlantDashDot'
};
var mediumBorders = ['Dashed', 'DashDot', 'DashDotDot'];
var colorMap = {
    None: 'none',
    Solid: 'solid',
    Gray50: 'mediumGray',
    Gray75: 'darkGray',
    Gray25: 'lightGray',
    HorzStripe: 'darkHorizontal',
    VertStripe: 'darkVertical',
    ReverseDiagStripe: 'darkDown',
    DiagStripe: 'darkUp',
    DiagCross: 'darkGrid',
    ThickDiagCross: 'darkTrellis',
    ThinHorzStripe: 'lightHorizontal',
    ThinVertStripe: 'lightVertical',
    ThinReverseDiagStripe: 'lightDown',
    ThinDiagStripe: 'lightUp',
    ThinHorzCross: 'lightGrid',
    ThinDiagCross: 'lightTrellis',
    Gray125: 'gray125',
    Gray0625: 'gray0625'
};
var horizontalAlignmentMap = {
    Automatic: 'general',
    Left: 'left',
    Center: 'center',
    Right: 'right',
    Fill: 'fill',
    Justify: 'justify',
    CenterAcrossSelection: 'centerContinuous',
    Distributed: 'distributed',
    JustifyDistributed: 'justify'
};
var verticalAlignmentMap = {
    Automatic: undefined,
    Top: 'top',
    Bottom: 'bottom',
    Center: 'center',
    Justify: 'justify',
    Distributed: 'distributed',
    JustifyDistributed: 'justify'
};
var convertLegacyPattern = function (name) {
    if (!name) {
        return 'none';
    }
    return colorMap[name] || name;
};
var convertLegacyColor = function (color) {
    if (color == undefined) {
        return color;
    }
    if (color.charAt(0) === '#') {
        color = color.substring(1);
    }
    return color.length === 6 ? 'FF' + color : color;
};
var convertLegacyBorder = function (type, weight) {
    if (!type) {
        return 'thin';
    }
    // Legacy Types are: None, Continuous, Dash, Dot, DashDot, DashDotDot, SlantDashDot, and Double
    // Weight represents: 0—Hairline, 1—Thin , 2—Medium, 3—Thick
    // New types: none, thin, medium, dashed, dotted, thick, double, hair, mediumDashed, dashDot, mediumDashDot,
    // dashDotDot, mediumDashDotDot, slantDashDot
    var namedWeight = getWeightName(weight);
    var mappedName = mappedBorderNames[type];
    if (type === 'Continuous') {
        return namedWeight;
    }
    if (namedWeight === 'medium' && mediumBorders.indexOf(mappedName) !== -1) {
        return "medium".concat(mappedName);
    }
    return mappedName.charAt(0).toLowerCase() + mappedName.substring(1);
};
var convertLegacyHorizontalAlignment = function (alignment) {
    return horizontalAlignmentMap[alignment] || 'general';
};
var convertLegacyVerticalAlignment = function (alignment) {
    return verticalAlignmentMap[alignment] || undefined;
};

var getBorderColor = function (color) {
    return {
        name: 'color',
        properties: {
            rawMap: {
                rgb: convertLegacyColor(color || '#000000')
            }
        }
    };
};
var borderFactory$1 = {
    getTemplate: function (border) {
        var left = border.left, right = border.right, top = border.top, bottom = border.bottom, diagonal = border.diagonal;
        var leftChildren = left ? [getBorderColor(left.color)] : undefined;
        var rightChildren = right ? [getBorderColor(right.color)] : undefined;
        var topChildren = top ? [getBorderColor(top.color)] : undefined;
        var bottomChildren = bottom ? [getBorderColor(bottom.color)] : undefined;
        var diagonalChildren = diagonal ? [getBorderColor(diagonal.color)] : undefined;
        return {
            name: 'border',
            children: [{
                    name: 'left',
                    properties: { rawMap: { style: left && left.style } },
                    children: leftChildren
                }, {
                    name: 'right',
                    properties: { rawMap: { style: right && right.style } },
                    children: rightChildren
                }, {
                    name: 'top',
                    properties: { rawMap: { style: top && top.style } },
                    children: topChildren
                }, {
                    name: 'bottom',
                    properties: { rawMap: { style: bottom && bottom.style } },
                    children: bottomChildren
                }, {
                    name: 'diagonal',
                    properties: { rawMap: { style: diagonal && diagonal.style } },
                    children: diagonalChildren
                }]
        };
    }
};

var bordersFactory = {
    getTemplate: function (borders) {
        return {
            name: "borders",
            properties: {
                rawMap: {
                    count: borders.length
                }
            },
            children: borders.map(function (border) { return borderFactory$1.getTemplate(border); })
        };
    }
};

var getReadingOrderId = function (readingOrder) {
    var order = ['Context', 'LeftToRight', 'RightToLeft'];
    var pos = order.indexOf(readingOrder);
    return Math.max(pos, 0);
};
var alignmentFactory = {
    getTemplate: function (alignment) {
        var horizontal = alignment.horizontal, indent = alignment.indent, readingOrder = alignment.readingOrder, rotate = alignment.rotate, shrinkToFit = alignment.shrinkToFit, vertical = alignment.vertical, wrapText = alignment.wrapText;
        return {
            name: 'alignment',
            properties: {
                rawMap: {
                    horizontal: horizontal && convertLegacyHorizontalAlignment(horizontal),
                    indent: indent,
                    readingOrder: readingOrder && getReadingOrderId(readingOrder),
                    textRotation: rotate,
                    shrinkToFit: shrinkToFit,
                    vertical: vertical && convertLegacyVerticalAlignment(vertical),
                    wrapText: wrapText
                }
            }
        };
    }
};

var protectionFactory = {
    getTemplate: function (protection) {
        var locked = protection.protected === false ? 0 : 1;
        var hidden = protection.hideFormula === true ? 1 : 0;
        return {
            name: 'protection',
            properties: {
                rawMap: {
                    hidden: hidden,
                    locked: locked
                }
            }
        };
    }
};

var xfFactory = {
    getTemplate: function (xf) {
        var alignment = xf.alignment, borderId = xf.borderId, fillId = xf.fillId, fontId = xf.fontId, numFmtId = xf.numFmtId, protection = xf.protection, xfId = xf.xfId;
        var children = [];
        if (alignment) {
            children.push(alignmentFactory.getTemplate(alignment));
        }
        if (protection) {
            children.push(protectionFactory.getTemplate(protection));
        }
        return {
            name: "xf",
            properties: {
                rawMap: {
                    applyAlignment: alignment ? 1 : undefined,
                    applyProtection: protection ? 1 : undefined,
                    applyBorder: borderId ? 1 : undefined,
                    applyFill: fillId ? 1 : undefined,
                    borderId: borderId,
                    fillId: fillId,
                    applyFont: fontId ? 1 : undefined,
                    fontId: fontId,
                    applyNumberFormat: numFmtId ? 1 : undefined,
                    numFmtId: numFmtId,
                    xfId: xfId
                }
            },
            children: children.length ? children : undefined
        };
    }
};

var cellStylesXfsFactory = {
    getTemplate: function (xfs) {
        return {
            name: "cellStyleXfs",
            properties: {
                rawMap: {
                    count: xfs.length
                }
            },
            children: xfs.map(function (xf) { return xfFactory.getTemplate(xf); })
        };
    }
};

var cellXfsFactory = {
    getTemplate: function (xfs) {
        return {
            name: "cellXfs",
            properties: {
                rawMap: {
                    count: xfs.length
                }
            },
            children: xfs.map(function (xf) { return xfFactory.getTemplate(xf); })
        };
    }
};

var borderFactory = {
    getTemplate: function (cellStyle) {
        var builtinId = cellStyle.builtinId, name = cellStyle.name, xfId = cellStyle.xfId;
        return {
            name: "cellStyle",
            properties: {
                rawMap: {
                    builtinId: builtinId,
                    name: name,
                    xfId: xfId
                }
            }
        };
    }
};

var cellStylesFactory = {
    getTemplate: function (cellStyles) {
        return {
            name: "cellStyles",
            properties: {
                rawMap: {
                    count: cellStyles.length
                }
            },
            children: cellStyles.map(function (cellStyle) { return borderFactory.getTemplate(cellStyle); })
        };
    }
};

var __assign$u = (undefined && undefined.__assign) || function () {
    __assign$u = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$u.apply(this, arguments);
};
var stylesMap;
var registeredNumberFmts;
var registeredFonts;
var registeredFills;
var registeredBorders;
var registeredCellStyleXfs;
var registeredCellXfs;
var registeredCellStyles;
var currentSheet;
var getStyleName = function (name, currentSheet) {
    if (name.indexOf('mixedStyle') !== -1 && currentSheet > 1) {
        name += "_".concat(currentSheet);
    }
    return name;
};
var resetStylesheetValues = function () {
    stylesMap = { base: 0 };
    registeredNumberFmts = [];
    registeredFonts = [{ fontName: 'Calibri', colorTheme: '1', family: '2', scheme: 'minor' }];
    registeredFills = [{ patternType: 'none', }, { patternType: 'gray125' }];
    registeredBorders = [{ left: undefined, right: undefined, top: undefined, bottom: undefined, diagonal: undefined }];
    registeredCellStyleXfs = [{ borderId: 0, fillId: 0, fontId: 0, numFmtId: 0 }];
    registeredCellXfs = [{ borderId: 0, fillId: 0, fontId: 0, numFmtId: 0, xfId: 0 }];
    registeredCellStyles = [{ builtinId: 0, name: 'Normal', xfId: 0 }];
};
var registerFill = function (fill) {
    var convertedPattern = convertLegacyPattern(fill.pattern);
    var convertedFillColor = convertLegacyColor(fill.color);
    var convertedPatternColor = convertLegacyColor(fill.patternColor);
    var pos = registeredFills.findIndex(function (currentFill) {
        var patternType = currentFill.patternType, fgRgb = currentFill.fgRgb, bgRgb = currentFill.bgRgb;
        if (patternType != convertedPattern ||
            fgRgb != convertedFillColor ||
            bgRgb != convertedPatternColor) {
            return false;
        }
        return true;
    });
    if (pos === -1) {
        pos = registeredFills.length;
        registeredFills.push({ patternType: convertedPattern, fgRgb: convertedFillColor, bgRgb: convertedPatternColor });
    }
    return pos;
};
var registerNumberFmt = function (format) {
    format = _.utf8_encode(format);
    if (numberFormatMap[format]) {
        return numberFormatMap[format];
    }
    var pos = registeredNumberFmts.findIndex(function (currentFormat) { return currentFormat.formatCode === format; });
    if (pos === -1) {
        pos = registeredNumberFmts.length + 164;
        registeredNumberFmts.push({ formatCode: format, numFmtId: pos });
    }
    else {
        pos = registeredNumberFmts[pos].numFmtId;
    }
    return pos;
};
var registerBorders = function (borders) {
    var borderBottom = borders.borderBottom, borderTop = borders.borderTop, borderLeft = borders.borderLeft, borderRight = borders.borderRight;
    var bottomStyle;
    var topStyle;
    var leftStyle;
    var rightStyle;
    var bottomColor;
    var topColor;
    var leftColor;
    var rightColor;
    if (borderLeft) {
        leftStyle = convertLegacyBorder(borderLeft.lineStyle, borderLeft.weight);
        leftColor = convertLegacyColor(borderLeft.color);
    }
    if (borderRight) {
        rightStyle = convertLegacyBorder(borderRight.lineStyle, borderRight.weight);
        rightColor = convertLegacyColor(borderRight.color);
    }
    if (borderBottom) {
        bottomStyle = convertLegacyBorder(borderBottom.lineStyle, borderBottom.weight);
        bottomColor = convertLegacyColor(borderBottom.color);
    }
    if (borderTop) {
        topStyle = convertLegacyBorder(borderTop.lineStyle, borderTop.weight);
        topColor = convertLegacyColor(borderTop.color);
    }
    var pos = registeredBorders.findIndex(function (currentBorder) {
        var left = currentBorder.left, right = currentBorder.right, top = currentBorder.top, bottom = currentBorder.bottom;
        if (!left && (leftStyle || leftColor)) {
            return false;
        }
        if (!right && (rightStyle || rightColor)) {
            return false;
        }
        if (!top && (topStyle || topColor)) {
            return false;
        }
        if (!bottom && (bottomStyle || bottomColor)) {
            return false;
        }
        var _a = left || {}, clS = _a.style, clC = _a.color;
        var _b = right || {}, crS = _b.style, crC = _b.color;
        var _c = top || {}, ctS = _c.style, ctC = _c.color;
        var _d = bottom || {}, cbS = _d.style, cbC = _d.color;
        if (clS != leftStyle || clC != leftColor) {
            return false;
        }
        if (crS != rightStyle || crC != rightColor) {
            return false;
        }
        if (ctS != topStyle || ctC != topColor) {
            return false;
        }
        if (cbS != bottomStyle || cbC != bottomColor) {
            return false;
        }
        return true;
    });
    if (pos === -1) {
        pos = registeredBorders.length;
        registeredBorders.push({
            left: {
                style: leftStyle, color: leftColor
            },
            right: {
                style: rightStyle, color: rightColor
            },
            top: {
                style: topStyle, color: topColor
            },
            bottom: {
                style: bottomStyle, color: bottomColor
            },
            diagonal: {
                style: undefined,
                color: undefined
            }
        });
    }
    return pos;
};
var registerFont = function (font) {
    var _a = font.fontName, name = _a === void 0 ? 'Calibri' : _a, color = font.color, size = font.size, bold = font.bold, italic = font.italic, outline = font.outline, shadow = font.shadow, strikeThrough = font.strikeThrough, underline = font.underline, family = font.family, verticalAlign = font.verticalAlign;
    var utf8Name = name ? _.utf8_encode(name) : name;
    var convertedColor = convertLegacyColor(color);
    var familyId = getFontFamilyId(family);
    var convertedUnderline = underline ? underline.toLocaleLowerCase() : undefined;
    var convertedVerticalAlign = verticalAlign ? verticalAlign.toLocaleLowerCase() : undefined;
    var pos = registeredFonts.findIndex(function (currentFont) {
        if (currentFont.fontName != utf8Name ||
            currentFont.color != convertedColor ||
            currentFont.size != size ||
            currentFont.bold != bold ||
            currentFont.italic != italic ||
            currentFont.outline != outline ||
            currentFont.shadow != shadow ||
            currentFont.strikeThrough != strikeThrough ||
            currentFont.underline != convertedUnderline ||
            currentFont.verticalAlign != convertedVerticalAlign ||
            // @ts-ignore
            currentFont.family != familyId) {
            return false;
        }
        return true;
    });
    if (pos === -1) {
        pos = registeredFonts.length;
        registeredFonts.push({
            fontName: utf8Name,
            color: convertedColor,
            size: size,
            bold: bold,
            italic: italic,
            outline: outline,
            shadow: shadow,
            strikeThrough: strikeThrough,
            underline: convertedUnderline,
            verticalAlign: convertedVerticalAlign,
            family: familyId != null ? familyId.toString() : undefined
        });
    }
    return pos;
};
var registerStyle = function (config) {
    var alignment = config.alignment, borders = config.borders, font = config.font, interior = config.interior, numberFormat = config.numberFormat, protection = config.protection;
    var id = config.id;
    var currentFill = 0;
    var currentBorder = 0;
    var currentFont = 0;
    var currentNumberFmt = 0;
    if (!id) {
        return;
    }
    id = getStyleName(id, currentSheet);
    if (stylesMap[id] != undefined) {
        return;
    }
    if (interior) {
        currentFill = registerFill(interior);
    }
    if (borders) {
        currentBorder = registerBorders(borders);
    }
    if (font) {
        currentFont = registerFont(font);
    }
    if (numberFormat) {
        currentNumberFmt = registerNumberFmt(numberFormat.format);
    }
    stylesMap[id] = registeredCellXfs.length;
    registeredCellXfs.push({
        alignment: alignment,
        borderId: currentBorder || 0,
        fillId: currentFill || 0,
        fontId: currentFont || 0,
        numFmtId: currentNumberFmt || 0,
        protection: protection,
        xfId: 0
    });
};
var stylesheetFactory = {
    getTemplate: function (defaultFontSize) {
        var numberFormats = numberFormatsFactory.getTemplate(registeredNumberFmts);
        var fonts = fontsFactory.getTemplate(registeredFonts.map(function (font) { return (__assign$u(__assign$u({}, font), { size: font.size != null ? font.size : defaultFontSize })); }));
        var fills = fillsFactory.getTemplate(registeredFills);
        var borders = bordersFactory.getTemplate(registeredBorders);
        var cellStylesXfs = cellStylesXfsFactory.getTemplate(registeredCellStyleXfs);
        var cellXfs = cellXfsFactory.getTemplate(registeredCellXfs);
        var cellStyles = cellStylesFactory.getTemplate(registeredCellStyles);
        resetStylesheetValues();
        return {
            name: 'styleSheet',
            properties: {
                rawMap: {
                    'mc:Ignorable': 'x14ac x16r2 xr',
                    'xmlns': 'http://schemas.openxmlformats.org/spreadsheetml/2006/main',
                    'xmlns:mc': 'http://schemas.openxmlformats.org/markup-compatibility/2006',
                    'xmlns:x14ac': 'http://schemas.microsoft.com/office/spreadsheetml/2009/9/ac',
                    'xmlns:x16r2': 'http://schemas.microsoft.com/office/spreadsheetml/2015/02/main',
                    'xmlns:xr': 'http://schemas.microsoft.com/office/spreadsheetml/2014/revision'
                }
            },
            children: [
                numberFormats,
                fonts,
                fills,
                borders,
                cellStylesXfs,
                cellXfs,
                cellStyles,
                {
                    name: 'tableStyles',
                    properties: {
                        rawMap: {
                            count: 0,
                            defaultPivotStyle: 'PivotStyleLight16',
                            defaultTableStyle: 'TableStyleMedium2'
                        }
                    }
                }
            ]
        };
    }
};
var getStyleId = function (name, currentSheet) {
    return stylesMap[getStyleName(name, currentSheet)] || 0;
};
var registerStyles = function (styles, _currentSheet) {
    currentSheet = _currentSheet;
    if (currentSheet === 1) {
        resetStylesheetValues();
    }
    styles.forEach(registerStyle);
};

var sheetFactory = {
    getTemplate: function (name, idx) {
        var sheetId = (idx + 1).toString();
        return {
            name: "sheet",
            properties: {
                rawMap: {
                    "name": name,
                    "sheetId": sheetId,
                    "r:id": "rId".concat(sheetId)
                }
            }
        };
    }
};

var sheetsFactory = {
    getTemplate: function (names) {
        return {
            name: "sheets",
            children: names.map(function (sheet, idx) { return sheetFactory.getTemplate(sheet, idx); })
        };
    }
};

var workbookFactory = {
    getTemplate: function (names) {
        return {
            name: "workbook",
            properties: {
                prefixedAttributes: [{
                        prefix: "xmlns:",
                        map: {
                            r: "http://schemas.openxmlformats.org/officeDocument/2006/relationships"
                        },
                    }],
                rawMap: {
                    xmlns: "http://schemas.openxmlformats.org/spreadsheetml/2006/main"
                }
            },
            children: [sheetsFactory.getTemplate(names)]
        };
    }
};

// https://docs.microsoft.com/en-us/office/troubleshoot/excel/determine-column-widths
var getExcelCellWidth = function (width) { return Math.ceil((width - 12) / 7 + 1); };
var columnFactory = {
    getTemplate: function (config) {
        var min = config.min, max = config.max, outlineLevel = config.outlineLevel, s = config.s, width = config.width, hidden = config.hidden, bestFit = config.bestFit;
        var excelWidth = 1;
        var customWidth = '0';
        if (width > 1) {
            excelWidth = getExcelCellWidth(width);
            customWidth = '1';
        }
        return {
            name: 'col',
            properties: {
                rawMap: {
                    min: min,
                    max: max,
                    outlineLevel: outlineLevel != null ? outlineLevel : undefined,
                    width: excelWidth,
                    style: s,
                    hidden: hidden ? '1' : '0',
                    bestFit: bestFit ? '1' : '0',
                    customWidth: customWidth
                }
            }
        };
    }
};

var convertLegacyType = function (type) {
    var t = type.charAt(0).toLowerCase();
    return t === 's' ? 'inlineStr' : t;
};
var cellFactory = {
    getTemplate: function (config, idx, currentSheet) {
        var ref = config.ref, data = config.data, styleId = config.styleId;
        var _a = data || { type: 'empty', value: null }, type = _a.type, value = _a.value;
        var convertedType = type;
        if (type === 'f') {
            convertedType = 'str';
        }
        else if (type.charAt(0) === type.charAt(0).toUpperCase()) {
            convertedType = convertLegacyType(type);
        }
        var obj = {
            name: 'c',
            properties: {
                rawMap: {
                    r: ref,
                    t: convertedType === 'empty' ? undefined : convertedType,
                    s: styleId ? getStyleId(styleId, currentSheet) : undefined
                }
            }
        };
        if (convertedType === 'empty') {
            return obj;
        }
        var children;
        if (convertedType === 'str' && type === 'f') {
            children = [{
                    name: 'f',
                    textNode: _.escapeString(_.utf8_encode(value))
                }];
        }
        else if (convertedType === 'inlineStr') {
            children = [{
                    name: 'is',
                    children: [{
                            name: 't',
                            textNode: _.escapeString(_.utf8_encode(value))
                        }]
                }];
        }
        else {
            children = [{
                    name: 'v',
                    textNode: value
                }];
        }
        return Object.assign({}, obj, { children: children });
    }
};

var __read$s = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$h = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var addEmptyCells = function (cells, rowIdx) {
    var mergeMap = [];
    var posCounter = 0;
    for (var i = 0; i < cells.length; i++) {
        var cell = cells[i];
        if (cell.mergeAcross) {
            mergeMap.push({
                pos: i,
                excelPos: posCounter
            });
            posCounter += cell.mergeAcross;
        }
        posCounter++;
    }
    if (mergeMap.length) {
        for (var i = mergeMap.length - 1; i >= 0; i--) {
            var mergedCells = [];
            var cell = cells[mergeMap[i].pos];
            for (var j = 1; j <= cell.mergeAcross; j++) {
                mergedCells.push({
                    ref: "".concat(getExcelColumnName(mergeMap[i].excelPos + 1 + j)).concat(rowIdx + 1),
                    styleId: cell.styleId,
                    data: { type: 'empty', value: null }
                });
            }
            if (mergedCells.length) {
                cells.splice.apply(cells, __spreadArray$h([mergeMap[i].pos + 1, 0], __read$s(mergedCells), false));
            }
        }
    }
};
var shouldDisplayCell = function (cell) { var _a; return ((_a = cell.data) === null || _a === void 0 ? void 0 : _a.value) !== '' || cell.styleId !== undefined; };
var rowFactory = {
    getTemplate: function (config, idx, currentSheet) {
        var collapsed = config.collapsed, hidden = config.hidden, height = config.height, outlineLevel = config.outlineLevel, _a = config.cells, cells = _a === void 0 ? [] : _a;
        addEmptyCells(cells, idx);
        var children = cells.filter(shouldDisplayCell).map(function (cell, idx) { return cellFactory.getTemplate(cell, idx, currentSheet); });
        return {
            name: "row",
            properties: {
                rawMap: {
                    r: idx + 1,
                    collapsed: collapsed ? '1' : '0',
                    hidden: hidden ? '1' : '0',
                    ht: height,
                    customHeight: height != null ? '1' : '0',
                    spans: '1:1',
                    outlineLevel: outlineLevel || undefined
                }
            },
            children: children
        };
    }
};

var mergeCellFactory = {
    getTemplate: function (ref) {
        return {
            name: 'mergeCell',
            properties: {
                rawMap: {
                    ref: ref
                }
            }
        };
    }
};

var getMergedCellsAndAddColumnGroups = function (rows, cols, suppressColumnOutline) {
    var mergedCells = [];
    var cellsWithCollapsibleGroups = [];
    rows.forEach(function (currentRow, rowIdx) {
        var cells = currentRow.cells;
        var merges = 0;
        var lastCol;
        cells.forEach(function (currentCell, cellIdx) {
            var min = cellIdx + merges + 1;
            var start = getExcelColumnName(min);
            var outputRow = rowIdx + 1;
            if (currentCell.mergeAcross) {
                merges += currentCell.mergeAcross;
                var end = getExcelColumnName(cellIdx + merges + 1);
                mergedCells.push("".concat(start).concat(outputRow, ":").concat(end).concat(outputRow));
            }
            if (!cols[min - 1]) {
                cols[min - 1] = {};
            }
            var collapsibleRanges = currentCell.collapsibleRanges;
            if (collapsibleRanges) {
                collapsibleRanges.forEach(function (range) {
                    cellsWithCollapsibleGroups.push([min + range[0], min + range[1]]);
                });
            }
            lastCol = cols[min - 1];
            lastCol.min = min;
            lastCol.max = min;
            currentCell.ref = "".concat(start).concat(outputRow);
        });
    });
    cellsWithCollapsibleGroups.sort(function (a, b) {
        if (a[0] !== b[0]) {
            return a[0] - b[0];
        }
        return b[1] - a[1];
    });
    var rangeMap = new Map();
    var outlineLevel = new Map();
    cellsWithCollapsibleGroups.filter(function (currentRange) {
        var rangeString = currentRange.toString();
        var inMap = rangeMap.get(rangeString);
        if (inMap) {
            return false;
        }
        rangeMap.set(rangeString, true);
        return true;
    }).forEach(function (range) {
        var refCol = cols.find(function (col) { return col.min == range[0] && col.max == range[1]; });
        var currentOutlineLevel = outlineLevel.get(range[0]);
        cols.push({
            min: range[0],
            max: range[1],
            outlineLevel: suppressColumnOutline ? undefined : (currentOutlineLevel || 1),
            width: (refCol || { width: 100 }).width
        });
        outlineLevel.set(range[0], (currentOutlineLevel || 0) + 1);
    });
    return mergedCells;
};
var getPageOrientation = function (orientation) {
    if (!orientation || (orientation !== 'Portrait' && orientation !== 'Landscape')) {
        return 'portrait';
    }
    return orientation.toLocaleLowerCase();
};
var getPageSize = function (pageSize) {
    if (pageSize == null) {
        return 1;
    }
    var positions = ['Letter', 'Letter Small', 'Tabloid', 'Ledger', 'Legal', 'Statement', 'Executive', 'A3', 'A4', 'A4 Small', 'A5', 'A6', 'B4', 'B5', 'Folio', 'Envelope', 'Envelope DL', 'Envelope C5', 'Envelope B5', 'Envelope C3', 'Envelope C4', 'Envelope C6', 'Envelope Monarch', 'Japanese Postcard', 'Japanese Double Postcard'];
    var pos = positions.indexOf(pageSize);
    return pos === -1 ? 1 : (pos + 1);
};
var addColumns = function (columns) {
    return function (children) {
        if (columns.length) {
            children.push({
                name: 'cols',
                children: columns.map(function (column) { return columnFactory.getTemplate(column); })
            });
        }
        return children;
    };
};
var addSheetData = function (rows, sheetNumber) {
    return function (children) {
        if (rows.length) {
            children.push({
                name: 'sheetData',
                children: rows.map(function (row, idx) { return rowFactory.getTemplate(row, idx, sheetNumber); })
            });
        }
        return children;
    };
};
var addMergeCells = function (mergeCells) {
    return function (children) {
        if (mergeCells.length) {
            children.push({
                name: 'mergeCells',
                properties: {
                    rawMap: {
                        count: mergeCells.length
                    }
                },
                children: mergeCells.map(function (mergedCell) { return mergeCellFactory.getTemplate(mergedCell); })
            });
        }
        return children;
    };
};
var addPageMargins = function (margins) {
    return function (children) {
        var _a = margins.top, top = _a === void 0 ? 0.75 : _a, _b = margins.right, right = _b === void 0 ? 0.7 : _b, _c = margins.bottom, bottom = _c === void 0 ? 0.75 : _c, _d = margins.left, left = _d === void 0 ? 0.7 : _d, _e = margins.header, header = _e === void 0 ? 0.3 : _e, _f = margins.footer, footer = _f === void 0 ? 0.3 : _f;
        children.push({
            name: 'pageMargins',
            properties: {
                rawMap: { bottom: bottom, footer: footer, header: header, left: left, right: right, top: top }
            }
        });
        return children;
    };
};
var addPageSetup = function (pageSetup) {
    return function (children) {
        if (pageSetup) {
            children.push({
                name: 'pageSetup',
                properties: {
                    rawMap: {
                        horizontalDpi: 0,
                        verticalDpi: 0,
                        orientation: getPageOrientation(pageSetup.orientation),
                        paperSize: getPageSize(pageSetup.pageSize)
                    }
                }
            });
        }
        return children;
    };
};
var replaceHeaderFooterTokens = function (value) {
    var map = {
        '&[Page]': '&P',
        '&[Pages]': '&N',
        '&[Date]': '&D',
        '&[Time]': '&T',
        '&[Tab]': '&A',
        '&[Path]': '&Z',
        '&[File]': '&F'
    };
    _.iterateObject(map, function (key, val) {
        value = value.replace(key, val);
    });
    return value;
};
var getHeaderPosition = function (position) {
    if (position === 'Center') {
        return 'C';
    }
    if (position === 'Right') {
        return 'R';
    }
    return 'L';
};
var applyHeaderFontStyle = function (headerString, font) {
    if (!font) {
        return headerString;
    }
    headerString += '&amp;&quot;';
    headerString += font.fontName || 'Calibri';
    if (font.bold !== font.italic) {
        headerString += font.bold ? ',Bold' : ',Italic';
    }
    else if (font.bold) {
        headerString += ',Bold Italic';
    }
    else {
        headerString += ',Regular';
    }
    headerString += '&quot;';
    if (font.size) {
        headerString += "&amp;".concat(font.size);
    }
    if (font.strikeThrough) {
        headerString += '&amp;S';
    }
    if (font.underline) {
        headerString += "&amp;".concat(font.underline === 'Double' ? 'E' : 'U');
    }
    if (font.color) {
        headerString += "&amp;K".concat(font.color.replace('#', '').toUpperCase());
    }
    return headerString;
};
var processHeaderFooterContent = function (content) {
    return content.reduce(function (prev, curr) {
        var pos = getHeaderPosition(curr.position);
        var output = applyHeaderFontStyle("".concat(prev, "&amp;").concat(pos), curr.font);
        return "".concat(output).concat(_.escapeString(_.utf8_encode(replaceHeaderFooterTokens(curr.value))));
    }, '');
};
var buildHeaderFooter = function (headerFooterConfig) {
    var rules = ['all', 'first', 'even'];
    var headersAndFooters = [];
    rules.forEach(function (rule) {
        var headerFooter = headerFooterConfig[rule];
        var namePrefix = rule === 'all' ? 'odd' : rule;
        if (!headerFooter || (!headerFooter.header && !headerFooter.footer)) {
            return;
        }
        _.iterateObject(headerFooter, function (key, value) {
            var nameSuffix = "".concat(key.charAt(0).toUpperCase()).concat(key.slice(1));
            if (value) {
                headersAndFooters.push({
                    name: "".concat(namePrefix).concat(nameSuffix),
                    properties: {
                        rawMap: {
                            'xml:space': 'preserve'
                        }
                    },
                    textNode: processHeaderFooterContent(value)
                });
            }
        });
    });
    return headersAndFooters;
};
var addHeaderFooter = function (headerFooterConfig) {
    return function (children) {
        if (!headerFooterConfig) {
            return children;
        }
        var differentFirst = headerFooterConfig.first != null ? 1 : 0;
        var differentOddEven = headerFooterConfig.even != null ? 1 : 0;
        children.push({
            name: 'headerFooter',
            properties: {
                rawMap: {
                    differentFirst: differentFirst,
                    differentOddEven: differentOddEven
                }
            },
            children: buildHeaderFooter(headerFooterConfig)
        });
        return children;
    };
};
var addDrawingRel = function (currentSheet) {
    return function (children) {
        if (ExcelXlsxFactory.worksheetImages.get(currentSheet)) {
            children.push({
                name: 'drawing',
                properties: {
                    rawMap: {
                        'r:id': 'rId1'
                    }
                }
            });
        }
        return children;
    };
};
var addSheetPr = function () {
    return function (children) {
        children.push({
            name: 'sheetPr',
            children: [{
                    name: 'outlinePr',
                    properties: {
                        rawMap: {
                            summaryBelow: 0
                        }
                    }
                }]
        });
        return children;
    };
};
var addSheetFormatPr = function (rows) {
    return function (children) {
        var maxOutline = rows.reduce(function (prev, row) {
            if (row.outlineLevel && row.outlineLevel > prev) {
                return row.outlineLevel;
            }
            return prev;
        }, 0);
        children.push({
            name: 'sheetFormatPr',
            properties: {
                rawMap: {
                    baseColWidth: 10,
                    defaultRowHeight: 16,
                    outlineLevelRow: maxOutline ? maxOutline : undefined
                }
            }
        });
        return children;
    };
};
var worksheetFactory = {
    getTemplate: function (params) {
        var worksheet = params.worksheet, currentSheet = params.currentSheet, config = params.config;
        var _a = config.margins, margins = _a === void 0 ? {} : _a, pageSetup = config.pageSetup, headerFooterConfig = config.headerFooterConfig, suppressColumnOutline = config.suppressColumnOutline;
        var table = worksheet.table;
        var rows = table.rows, columns = table.columns;
        var mergedCells = (columns && columns.length) ? getMergedCellsAndAddColumnGroups(rows, columns, !!suppressColumnOutline) : [];
        var createWorksheetChildren = _.compose(addSheetPr(), addSheetFormatPr(rows), addColumns(columns), addSheetData(rows, currentSheet + 1), addMergeCells(mergedCells), addPageMargins(margins), addPageSetup(pageSetup), addHeaderFooter(headerFooterConfig), addDrawingRel(currentSheet));
        var children = createWorksheetChildren([]);
        return {
            name: "worksheet",
            properties: {
                prefixedAttributes: [{
                        prefix: "xmlns:",
                        map: {
                            r: "http://schemas.openxmlformats.org/officeDocument/2006/relationships"
                        }
                    }],
                rawMap: {
                    xmlns: "http://schemas.openxmlformats.org/spreadsheetml/2006/main"
                }
            },
            children: children
        };
    }
};

var relationshipFactory = {
    getTemplate: function (config) {
        var Id = config.Id, Type = config.Type, Target = config.Target;
        return {
            name: "Relationship",
            properties: {
                rawMap: {
                    Id: Id,
                    Type: Type,
                    Target: Target
                }
            }
        };
    }
};

var relationshipsFactory = {
    getTemplate: function (c) {
        var children = c.map(function (relationship) { return relationshipFactory.getTemplate(relationship); });
        return {
            name: "Relationships",
            properties: {
                rawMap: {
                    xmlns: "http://schemas.openxmlformats.org/package/2006/relationships"
                }
            },
            children: children
        };
    }
};

var __read$r = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$g = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * See https://www.ecma-international.org/wp-content/uploads/Office-Open-XML-White-Paper.pdf
 */
var ExcelXlsxFactory = /** @class */ (function () {
    function ExcelXlsxFactory() {
    }
    ExcelXlsxFactory.createExcel = function (styles, worksheet, config) {
        this.addSheetName(worksheet);
        registerStyles(styles, this.sheetNames.length);
        return this.createWorksheet(worksheet, config);
    };
    ExcelXlsxFactory.buildImageMap = function (image, rowIndex, col, columnsToExport, rowHeight) {
        var currentSheetIndex = this.sheetNames.length;
        var registeredImage = this.images.get(image.id);
        if (!image.position || !image.position.row || !image.position.column) {
            if (!image.position) {
                image.position = {};
            }
            image.position = Object.assign({}, image.position, {
                row: rowIndex,
                column: columnsToExport.indexOf(col) + 1
            });
        }
        var calculatedImage = image;
        setExcelImageTotalWidth(calculatedImage, columnsToExport);
        setExcelImageTotalHeight(calculatedImage, rowHeight);
        if (registeredImage) {
            var currentSheetImages = registeredImage.find(function (currentImage) { return currentImage.sheetId === currentSheetIndex; });
            if (currentSheetImages) {
                currentSheetImages.image.push(calculatedImage);
            }
            else {
                registeredImage.push({
                    sheetId: currentSheetIndex,
                    image: [calculatedImage]
                });
            }
        }
        else {
            this.images.set(calculatedImage.id, [{ sheetId: currentSheetIndex, image: [calculatedImage] }]);
            this.workbookImageIds.set(calculatedImage.id, { type: calculatedImage.imageType, index: this.workbookImageIds.size });
        }
        this.buildSheetImageMap(currentSheetIndex, calculatedImage);
    };
    ExcelXlsxFactory.buildSheetImageMap = function (sheetIndex, image) {
        var worksheetImageIdMap = this.worksheetImageIds.get(sheetIndex);
        if (!worksheetImageIdMap) {
            worksheetImageIdMap = new Map();
            this.worksheetImageIds.set(sheetIndex, worksheetImageIdMap);
        }
        var sheetImages = this.worksheetImages.get(sheetIndex);
        if (!sheetImages) {
            this.worksheetImages.set(sheetIndex, [image]);
            worksheetImageIdMap.set(image.id, { index: 0, type: image.imageType });
        }
        else {
            sheetImages.push(image);
            if (!worksheetImageIdMap.get(image.id)) {
                worksheetImageIdMap.set(image.id, { index: worksheetImageIdMap.size, type: image.imageType });
            }
        }
    };
    ExcelXlsxFactory.addSheetName = function (worksheet) {
        var name = _.escapeString(worksheet.name) || '';
        var append = '';
        while (this.sheetNames.indexOf("".concat(name).concat(append)) !== -1) {
            if (append === '') {
                append = '_1';
            }
            else {
                var curr = parseInt(append.slice(1), 10);
                append = "_".concat(curr + 1);
            }
        }
        worksheet.name = "".concat(name).concat(append);
        this.sheetNames.push(worksheet.name);
    };
    ExcelXlsxFactory.getStringPosition = function (str) {
        if (this.sharedStrings.has(str)) {
            return this.sharedStrings.get(str);
        }
        this.sharedStrings.set(str, this.sharedStrings.size);
        return this.sharedStrings.size - 1;
    };
    ExcelXlsxFactory.resetFactory = function () {
        this.sharedStrings = new Map();
        this.images = new Map();
        this.worksheetImages = new Map();
        this.workbookImageIds = new Map();
        this.worksheetImageIds = new Map();
        this.sheetNames = [];
        this.factoryMode = ExcelFactoryMode.SINGLE_SHEET;
    };
    ExcelXlsxFactory.createWorkbook = function () {
        return createXmlPart(workbookFactory.getTemplate(this.sheetNames));
    };
    ExcelXlsxFactory.createStylesheet = function (defaultFontSize) {
        return createXmlPart(stylesheetFactory.getTemplate(defaultFontSize));
    };
    ExcelXlsxFactory.createSharedStrings = function () {
        return createXmlPart(sharedStrings.getTemplate(this.sharedStrings));
    };
    ExcelXlsxFactory.createCore = function (author) {
        return createXmlPart(coreFactory.getTemplate(author));
    };
    ExcelXlsxFactory.createContentTypes = function (sheetLen) {
        return createXmlPart(contentTypesFactory.getTemplate(sheetLen));
    };
    ExcelXlsxFactory.createRels = function () {
        var rs = relationshipsFactory.getTemplate([{
                Id: 'rId1',
                Type: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument',
                Target: 'xl/workbook.xml'
            }, {
                Id: 'rId2',
                Type: 'http://schemas.openxmlformats.org/package/2006/relationships/metadata/core-properties',
                Target: 'docProps/core.xml'
            }]);
        return createXmlPart(rs);
    };
    ExcelXlsxFactory.createTheme = function () {
        return createXmlPart(officeTheme.getTemplate());
    };
    ExcelXlsxFactory.createWorkbookRels = function (sheetLen) {
        var worksheets = new Array(sheetLen).fill(undefined).map(function (v, i) { return ({
            Id: "rId".concat(i + 1),
            Type: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/worksheet',
            Target: "worksheets/sheet".concat(i + 1, ".xml")
        }); });
        var rs = relationshipsFactory.getTemplate(__spreadArray$g(__spreadArray$g([], __read$r(worksheets), false), [
            {
                Id: "rId".concat(sheetLen + 1),
                Type: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/theme',
                Target: 'theme/theme1.xml'
            }, {
                Id: "rId".concat(sheetLen + 2),
                Type: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/styles',
                Target: 'styles.xml'
            }, {
                Id: "rId".concat(sheetLen + 3),
                Type: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/sharedStrings',
                Target: 'sharedStrings.xml'
            }
        ], false));
        return createXmlPart(rs);
    };
    ExcelXlsxFactory.createDrawing = function (sheetIndex) {
        return createXmlPart(drawingFactory.getTemplate({ sheetIndex: sheetIndex }));
    };
    ExcelXlsxFactory.createDrawingRel = function (sheetIndex) {
        var _this = this;
        var worksheetImageIds = this.worksheetImageIds.get(sheetIndex);
        var XMLArr = [];
        worksheetImageIds.forEach(function (value, key) {
            XMLArr.push({
                Id: "rId".concat(value.index + 1),
                Type: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/image',
                Target: "../media/image".concat(_this.workbookImageIds.get(key).index + 1, ".").concat(value.type)
            });
        });
        return createXmlPart(relationshipsFactory.getTemplate(XMLArr));
    };
    ExcelXlsxFactory.createWorksheetDrawingRel = function (currentRelationIndex) {
        var rs = relationshipsFactory.getTemplate([{
                Id: 'rId1',
                Type: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/drawing',
                Target: "../drawings/drawing".concat(currentRelationIndex + 1, ".xml")
            }]);
        return createXmlPart(rs);
    };
    ExcelXlsxFactory.createWorksheet = function (worksheet, config) {
        return createXmlPart(worksheetFactory.getTemplate({
            worksheet: worksheet,
            currentSheet: this.sheetNames.length - 1,
            config: config
        }));
    };
    ExcelXlsxFactory.sharedStrings = new Map();
    ExcelXlsxFactory.sheetNames = [];
    /** Maps images to sheet */
    ExcelXlsxFactory.images = new Map();
    /** Maps sheets to images */
    ExcelXlsxFactory.worksheetImages = new Map();
    /** Maps all workbook images to a global Id */
    ExcelXlsxFactory.workbookImageIds = new Map();
    /** Maps all sheet images to unique Ids */
    ExcelXlsxFactory.worksheetImageIds = new Map();
    ExcelXlsxFactory.factoryMode = ExcelFactoryMode.SINGLE_SHEET;
    return ExcelXlsxFactory;
}());

var __extends$1P = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$t = (undefined && undefined.__assign) || function () {
    __assign$t = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$t.apply(this, arguments);
};
var __read$q = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$f = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var ExcelSerializingSession = /** @class */ (function (_super) {
    __extends$1P(ExcelSerializingSession, _super);
    function ExcelSerializingSession(config) {
        var _this = _super.call(this, config) || this;
        _this.mixedStyles = {};
        _this.mixedStyleCounter = 0;
        _this.rows = [];
        _this.config = Object.assign({}, config);
        _this.stylesByIds = {};
        _this.config.baseExcelStyles.forEach(function (style) {
            _this.stylesByIds[style.id] = style;
        });
        _this.excelStyles = __spreadArray$f([], __read$q(_this.config.baseExcelStyles), false);
        return _this;
    }
    ExcelSerializingSession.prototype.addCustomContent = function (customContent) {
        var _this = this;
        customContent.forEach(function (row) {
            var rowLen = _this.rows.length + 1;
            var outlineLevel;
            if (!_this.config.suppressRowOutline && row.outlineLevel != null) {
                outlineLevel = row.outlineLevel;
            }
            var rowObj = {
                height: getHeightFromProperty(rowLen, row.height || _this.config.rowHeight),
                cells: (row.cells || []).map(function (cell, idx) {
                    var _a, _b, _c;
                    var image = _this.addImage(rowLen, _this.columnsToExport[idx], (_a = cell.data) === null || _a === void 0 ? void 0 : _a.value);
                    var excelStyles = null;
                    if (cell.styleId) {
                        excelStyles = typeof cell.styleId === 'string' ? [cell.styleId] : cell.styleId;
                    }
                    var excelStyleId = _this.getStyleId(excelStyles);
                    if (image) {
                        return _this.createCell(excelStyleId, _this.getDataTypeForValue(image.value), image.value == null ? '' : image.value);
                    }
                    var value = (_c = (_b = cell.data) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : '';
                    var type = _this.getDataTypeForValue(value);
                    if (cell.mergeAcross) {
                        return _this.createMergedCell(excelStyleId, type, value, cell.mergeAcross);
                    }
                    return _this.createCell(excelStyleId, type, value);
                }),
                outlineLevel: outlineLevel
            };
            if (row.collapsed != null) {
                rowObj.collapsed = row.collapsed;
            }
            if (row.hidden != null) {
                rowObj.hidden = row.hidden;
            }
            _this.rows.push(rowObj);
        });
    };
    ExcelSerializingSession.prototype.onNewHeaderGroupingRow = function () {
        var _this = this;
        var currentCells = [];
        this.rows.push({
            cells: currentCells,
            height: getHeightFromProperty(this.rows.length + 1, this.config.headerRowHeight)
        });
        return {
            onColumn: function (columnGroup, header, index, span, collapsibleRanges) {
                var styleIds = _this.config.styleLinker({ rowType: RowType.HEADER_GROUPING, rowIndex: 1, value: "grouping-".concat(header), columnGroup: columnGroup });
                currentCells.push(__assign$t(__assign$t({}, _this.createMergedCell(_this.getStyleId(styleIds), _this.getDataTypeForValue('string'), header, span)), { collapsibleRanges: collapsibleRanges }));
            }
        };
    };
    ExcelSerializingSession.prototype.onNewHeaderRow = function () {
        return this.onNewRow(this.onNewHeaderColumn, this.config.headerRowHeight);
    };
    ExcelSerializingSession.prototype.onNewBodyRow = function (node) {
        var rowAccumulator = this.onNewRow(this.onNewBodyColumn, this.config.rowHeight);
        if (node) {
            this.addRowOutlineIfNecessary(node);
        }
        return rowAccumulator;
    };
    ExcelSerializingSession.prototype.prepare = function (columnsToExport) {
        var _this = this;
        _super.prototype.prepare.call(this, columnsToExport);
        this.columnsToExport = __spreadArray$f([], __read$q(columnsToExport), false);
        this.cols = columnsToExport.map(function (col, i) { return _this.convertColumnToExcel(col, i); });
    };
    ExcelSerializingSession.prototype.parse = function () {
        // adding custom content might have made some rows wider than the grid, so add new columns
        var longestRow = this.rows.reduce(function (a, b) { return Math.max(a, b.cells.length); }, 0);
        while (this.cols.length < longestRow) {
            this.cols.push(this.convertColumnToExcel(null, this.cols.length + 1));
        }
        var data = {
            name: this.config.sheetName,
            table: {
                columns: this.cols,
                rows: this.rows
            }
        };
        return this.createExcel(data);
    };
    ExcelSerializingSession.prototype.addRowOutlineIfNecessary = function (node) {
        var _a = this.config, gridOptionsService = _a.gridOptionsService, suppressRowOutline = _a.suppressRowOutline, _b = _a.rowGroupExpandState, rowGroupExpandState = _b === void 0 ? 'expanded' : _b;
        var isGroupHideOpenParents = gridOptionsService.get('groupHideOpenParents');
        if (isGroupHideOpenParents || suppressRowOutline || node.level == null) {
            return;
        }
        var padding = node.footer ? 1 : 0;
        var currentRow = _.last(this.rows);
        currentRow.outlineLevel = node.level + padding;
        if (rowGroupExpandState === 'expanded') {
            return;
        }
        var collapseAll = rowGroupExpandState === 'collapsed';
        if (node.isExpandable()) {
            var isExpanded = !collapseAll && node.expanded;
            currentRow.collapsed = !isExpanded;
        }
        currentRow.hidden =
            // always show the node if there is no parent to be expanded
            !!node.parent &&
                // or if it is a child of the root node
                node.parent.level !== -1 &&
                (collapseAll || this.isAnyParentCollapsed(node.parent));
    };
    ExcelSerializingSession.prototype.isAnyParentCollapsed = function (node) {
        while (node && node.level !== -1) {
            if (!node.expanded) {
                return true;
            }
            node = node.parent;
        }
        return false;
    };
    ExcelSerializingSession.prototype.convertColumnToExcel = function (column, index) {
        var columnWidth = this.config.columnWidth;
        if (columnWidth) {
            if (typeof columnWidth === 'number') {
                return { width: columnWidth };
            }
            return { width: columnWidth({ column: column, index: index }) };
        }
        if (column) {
            var smallestUsefulWidth = 75;
            return { width: Math.max(column.getActualWidth(), smallestUsefulWidth) };
        }
        return {};
    };
    ExcelSerializingSession.prototype.onNewHeaderColumn = function (rowIndex, currentCells) {
        var _this = this;
        return function (column, index) {
            var nameForCol = _this.extractHeaderValue(column);
            var styleIds = _this.config.styleLinker({ rowType: RowType.HEADER, rowIndex: rowIndex, value: nameForCol, column: column });
            currentCells.push(_this.createCell(_this.getStyleId(styleIds), _this.getDataTypeForValue('string'), nameForCol));
        };
    };
    ExcelSerializingSession.prototype.onNewBodyColumn = function (rowIndex, currentCells) {
        var _this = this;
        var skipCols = 0;
        return function (column, index, node) {
            if (skipCols > 0) {
                skipCols -= 1;
                return;
            }
            var _a = _this.extractRowCellValue(column, index, rowIndex, 'excel', node), valueForCell = _a.value, valueFormatted = _a.valueFormatted;
            var styleIds = _this.config.styleLinker({ rowType: RowType.BODY, rowIndex: rowIndex, value: valueForCell, column: column, node: node });
            var excelStyleId = _this.getStyleId(styleIds);
            var colSpan = column.getColSpan(node);
            var addedImage = _this.addImage(rowIndex, column, valueForCell);
            if (addedImage) {
                currentCells.push(_this.createCell(excelStyleId, _this.getDataTypeForValue(addedImage.value), addedImage.value == null ? '' : addedImage.value));
            }
            else if (colSpan > 1) {
                skipCols = colSpan - 1;
                currentCells.push(_this.createMergedCell(excelStyleId, _this.getDataTypeForValue(valueForCell), valueForCell, colSpan - 1));
            }
            else {
                currentCells.push(_this.createCell(excelStyleId, _this.getDataTypeForValue(valueForCell), valueForCell, valueFormatted));
            }
        };
    };
    ExcelSerializingSession.prototype.onNewRow = function (onNewColumnAccumulator, height) {
        var currentCells = [];
        this.rows.push({
            cells: currentCells,
            height: getHeightFromProperty(this.rows.length + 1, height)
        });
        return {
            onColumn: onNewColumnAccumulator.bind(this, this.rows.length, currentCells)()
        };
    };
    ExcelSerializingSession.prototype.createExcel = function (data) {
        var _a = this, excelStyles = _a.excelStyles, config = _a.config;
        return ExcelXlsxFactory.createExcel(excelStyles, data, config);
    };
    ExcelSerializingSession.prototype.getDataTypeForValue = function (valueForCell) {
        if (valueForCell === undefined) {
            return 'empty';
        }
        return this.isNumerical(valueForCell) ? 'n' : 's';
    };
    ExcelSerializingSession.prototype.getType = function (type, style, value) {
        if (this.isFormula(value)) {
            return 'f';
        }
        if (style && style.dataType) {
            switch (style.dataType.toLocaleLowerCase()) {
                case 'formula':
                    return 'f';
                case 'string':
                    return 's';
                case 'number':
                    return 'n';
                case 'datetime':
                    return 'd';
                case 'error':
                    return 'e';
                case 'boolean':
                    return 'b';
                default:
                    console.warn("AG Grid: Unrecognized data type for excel export [".concat(style.id, ".dataType=").concat(style.dataType, "]"));
            }
        }
        return type;
    };
    ExcelSerializingSession.prototype.addImage = function (rowIndex, column, value) {
        if (!this.config.addImageToCell) {
            return;
        }
        var addedImage = this.config.addImageToCell(rowIndex, column, value);
        if (!addedImage) {
            return;
        }
        ExcelXlsxFactory.buildImageMap(addedImage.image, rowIndex, column, this.columnsToExport, this.config.rowHeight);
        return addedImage;
    };
    ExcelSerializingSession.prototype.createCell = function (styleId, type, value, valueFormatted) {
        var actualStyle = this.getStyleById(styleId);
        if (!(actualStyle === null || actualStyle === void 0 ? void 0 : actualStyle.dataType) && type === 's' && valueFormatted) {
            value = valueFormatted;
        }
        var typeTransformed = this.getType(type, actualStyle, value) || type;
        return {
            styleId: actualStyle ? styleId : undefined,
            data: {
                type: typeTransformed,
                value: this.getCellValue(typeTransformed, value)
            }
        };
    };
    ExcelSerializingSession.prototype.createMergedCell = function (styleId, type, value, numOfCells) {
        var valueToUse = value == null ? '' : value;
        return {
            styleId: !!this.getStyleById(styleId) ? styleId : undefined,
            data: {
                type: type,
                value: type === 's' ? ExcelXlsxFactory.getStringPosition(valueToUse).toString() : value
            },
            mergeAcross: numOfCells
        };
    };
    ExcelSerializingSession.prototype.getCellValue = function (type, value) {
        if (value == null) {
            return ExcelXlsxFactory.getStringPosition('').toString();
        }
        switch (type) {
            case 's':
                return value === '' ? '' : ExcelXlsxFactory.getStringPosition(value).toString();
            case 'f':
                return value.slice(1);
            case 'n':
                return Number(value).toString();
            default:
                return value;
        }
    };
    ExcelSerializingSession.prototype.getStyleId = function (styleIds) {
        if (!styleIds || !styleIds.length) {
            return null;
        }
        if (styleIds.length === 1) {
            return styleIds[0];
        }
        var key = styleIds.join("-");
        if (!this.mixedStyles[key]) {
            this.addNewMixedStyle(styleIds);
        }
        return this.mixedStyles[key].excelID;
    };
    ExcelSerializingSession.prototype.addNewMixedStyle = function (styleIds) {
        var _this = this;
        this.mixedStyleCounter += 1;
        var excelId = "mixedStyle".concat(this.mixedStyleCounter);
        var resultantStyle = {};
        styleIds.forEach(function (styleId) {
            _this.excelStyles.forEach(function (excelStyle) {
                if (excelStyle.id === styleId) {
                    _.mergeDeep(resultantStyle, _.deepCloneObject(excelStyle));
                }
            });
        });
        resultantStyle.id = excelId;
        resultantStyle.name = excelId;
        var key = styleIds.join("-");
        this.mixedStyles[key] = {
            excelID: excelId,
            key: key,
            result: resultantStyle
        };
        this.excelStyles.push(resultantStyle);
        this.stylesByIds[excelId] = resultantStyle;
    };
    ExcelSerializingSession.prototype.isFormula = function (value) {
        if (value == null) {
            return false;
        }
        return this.config.autoConvertFormulas && value.toString().startsWith('=');
    };
    ExcelSerializingSession.prototype.isNumerical = function (value) {
        if (typeof value === 'bigint') {
            return true;
        }
        return isFinite(value) && value !== '' && !isNaN(parseFloat(value));
    };
    ExcelSerializingSession.prototype.getStyleById = function (styleId) {
        if (styleId == null) {
            return null;
        }
        return this.stylesByIds[styleId] || null;
    };
    return ExcelSerializingSession;
}(BaseGridSerializingSession));

var __extends$1O = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$s = (undefined && undefined.__assign) || function () {
    __assign$s = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$s.apply(this, arguments);
};
var __decorate$1i = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var getMultipleSheetsAsExcel = function (params) {
    var data = params.data, _a = params.fontSize, fontSize = _a === void 0 ? 11 : _a, _b = params.author, author = _b === void 0 ? 'AG Grid' : _b;
    var hasImages = ExcelXlsxFactory.images.size > 0;
    ZipContainer.addFolders([
        '_rels/',
        'docProps/',
        'xl/',
        'xl/theme/',
        'xl/_rels/',
        'xl/worksheets/'
    ]);
    if (hasImages) {
        ZipContainer.addFolders([
            'xl/worksheets/_rels',
            'xl/drawings/',
            'xl/drawings/_rels',
            'xl/media/',
        ]);
        var imgCounter_1 = 0;
        ExcelXlsxFactory.images.forEach(function (value) {
            var firstImage = value[0].image[0];
            var ext = firstImage.imageType;
            ZipContainer.addFile("xl/media/image".concat(++imgCounter_1, ".").concat(ext), firstImage.base64, true);
        });
    }
    if (!data || data.length === 0) {
        console.warn("AG Grid: Invalid params supplied to getMultipleSheetsAsExcel() - `ExcelExportParams.data` is empty.");
        ExcelXlsxFactory.resetFactory();
        return;
    }
    var sheetLen = data.length;
    var imageRelationCounter = 0;
    data.forEach(function (value, idx) {
        ZipContainer.addFile("xl/worksheets/sheet".concat(idx + 1, ".xml"), value);
        if (hasImages && ExcelXlsxFactory.worksheetImages.get(idx)) {
            createImageRelationsForSheet(idx, imageRelationCounter++);
        }
    });
    ZipContainer.addFile('xl/workbook.xml', ExcelXlsxFactory.createWorkbook());
    ZipContainer.addFile('xl/styles.xml', ExcelXlsxFactory.createStylesheet(fontSize));
    ZipContainer.addFile('xl/sharedStrings.xml', ExcelXlsxFactory.createSharedStrings());
    ZipContainer.addFile('xl/theme/theme1.xml', ExcelXlsxFactory.createTheme());
    ZipContainer.addFile('xl/_rels/workbook.xml.rels', ExcelXlsxFactory.createWorkbookRels(sheetLen));
    ZipContainer.addFile('docProps/core.xml', ExcelXlsxFactory.createCore(author));
    ZipContainer.addFile('[Content_Types].xml', ExcelXlsxFactory.createContentTypes(sheetLen));
    ZipContainer.addFile('_rels/.rels', ExcelXlsxFactory.createRels());
    ExcelXlsxFactory.resetFactory();
    var mimeType = params.mimeType || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    return ZipContainer.getContent(mimeType);
};
var exportMultipleSheetsAsExcel = function (params) {
    var _a = params.fileName, fileName = _a === void 0 ? 'export.xlsx' : _a;
    var contents = getMultipleSheetsAsExcel(params);
    if (contents) {
        Downloader.download(fileName, contents);
    }
};
var createImageRelationsForSheet = function (sheetIndex, currentRelationIndex) {
    var drawingFolder = 'xl/drawings';
    var drawingFileName = "".concat(drawingFolder, "/drawing").concat(currentRelationIndex + 1, ".xml");
    var relFileName = "".concat(drawingFolder, "/_rels/drawing").concat(currentRelationIndex + 1, ".xml.rels");
    var worksheetRelFile = "xl/worksheets/_rels/sheet".concat(sheetIndex + 1, ".xml.rels");
    ZipContainer.addFile(relFileName, ExcelXlsxFactory.createDrawingRel(sheetIndex));
    ZipContainer.addFile(drawingFileName, ExcelXlsxFactory.createDrawing(sheetIndex));
    ZipContainer.addFile(worksheetRelFile, ExcelXlsxFactory.createWorksheetDrawingRel(currentRelationIndex));
};
var ExcelCreator = /** @class */ (function (_super) {
    __extends$1O(ExcelCreator, _super);
    function ExcelCreator() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExcelCreator.prototype.postConstruct = function () {
        this.setBeans({
            gridSerializer: this.gridSerializer,
            gridOptionsService: this.gridOptionsService
        });
    };
    ExcelCreator.prototype.getMergedParams = function (params) {
        var baseParams = this.gridOptionsService.get('defaultExcelExportParams');
        return Object.assign({}, baseParams, params);
    };
    ExcelCreator.prototype.export = function (userParams) {
        if (this.isExportSuppressed()) {
            console.warn("AG Grid: Export cancelled. Export is not allowed as per your configuration.");
            return '';
        }
        var mergedParams = this.getMergedParams(userParams);
        var data = this.getData(mergedParams);
        var exportParams = {
            data: [data],
            fontSize: mergedParams.fontSize,
            author: mergedParams.author,
            mimeType: mergedParams.mimeType
        };
        var packageFile = this.packageFile(exportParams);
        if (packageFile) {
            Downloader.download(this.getFileName(mergedParams.fileName), packageFile);
        }
        return data;
    };
    ExcelCreator.prototype.exportDataAsExcel = function (params) {
        return this.export(params);
    };
    ExcelCreator.prototype.getDataAsExcel = function (params) {
        var mergedParams = this.getMergedParams(params);
        var data = this.getData(mergedParams);
        var exportParams = {
            data: [data],
            fontSize: mergedParams.fontSize,
            author: mergedParams.author,
            mimeType: mergedParams.mimeType
        };
        return this.packageFile(exportParams);
    };
    ExcelCreator.prototype.setFactoryMode = function (factoryMode) {
        ExcelXlsxFactory.factoryMode = factoryMode;
    };
    ExcelCreator.prototype.getFactoryMode = function () {
        return ExcelXlsxFactory.factoryMode;
    };
    ExcelCreator.prototype.getSheetDataForExcel = function (params) {
        var mergedParams = this.getMergedParams(params);
        var data = this.getData(mergedParams);
        return data;
    };
    ExcelCreator.prototype.getMultipleSheetsAsExcel = function (params) {
        return getMultipleSheetsAsExcel(params);
    };
    ExcelCreator.prototype.exportMultipleSheetsAsExcel = function (params) {
        return exportMultipleSheetsAsExcel(params);
    };
    ExcelCreator.prototype.getDefaultFileExtension = function () {
        return 'xlsx';
    };
    ExcelCreator.prototype.createSerializingSession = function (params) {
        var _a = this, columnModel = _a.columnModel, valueService = _a.valueService, gridOptionsService = _a.gridOptionsService, valueFormatterService = _a.valueFormatterService, valueParserService = _a.valueParserService;
        var sheetName = 'ag-grid';
        if (params.sheetName != null) {
            sheetName = _.utf8_encode(String(params.sheetName).substring(0, 31));
        }
        var config = __assign$s(__assign$s({}, params), { sheetName: sheetName, columnModel: columnModel, valueService: valueService, gridOptionsService: gridOptionsService, valueFormatterService: valueFormatterService, valueParserService: valueParserService, suppressRowOutline: params.suppressRowOutline || params.skipRowGroups, headerRowHeight: params.headerRowHeight || params.rowHeight, baseExcelStyles: this.gridOptionsService.get('excelStyles') || [], styleLinker: this.styleLinker.bind(this) });
        return new ExcelSerializingSession(config);
    };
    ExcelCreator.prototype.styleLinker = function (params) {
        var rowType = params.rowType, rowIndex = params.rowIndex, value = params.value, column = params.column, columnGroup = params.columnGroup, node = params.node;
        var isHeader = rowType === RowType.HEADER;
        var isGroupHeader = rowType === RowType.HEADER_GROUPING;
        var col = (isHeader ? column : columnGroup);
        var headerClasses = [];
        if (isHeader || isGroupHeader) {
            headerClasses.push('header');
            if (isGroupHeader) {
                headerClasses.push('headerGroup');
            }
            if (col) {
                headerClasses = headerClasses.concat(CssClassApplier.getHeaderClassesFromColDef(col.getDefinition(), this.gridOptionsService, column || null, columnGroup || null));
            }
            return headerClasses;
        }
        var styles = this.gridOptionsService.get('excelStyles');
        var applicableStyles = ["cell"];
        if (!styles || !styles.length) {
            return applicableStyles;
        }
        var styleIds = styles.map(function (it) {
            return it.id;
        });
        this.stylingService.processAllCellClasses(column.getDefinition(), this.gridOptionsService.addGridCommonParams({
            value: value,
            data: node.data,
            node: node,
            colDef: column.getDefinition(),
            column: column,
            rowIndex: rowIndex
        }), function (className) {
            if (styleIds.indexOf(className) > -1) {
                applicableStyles.push(className);
            }
        });
        return applicableStyles.sort(function (left, right) {
            return (styleIds.indexOf(left) < styleIds.indexOf(right)) ? -1 : 1;
        });
    };
    ExcelCreator.prototype.isExportSuppressed = function () {
        return this.gridOptionsService.get('suppressExcelExport');
    };
    ExcelCreator.prototype.packageFile = function (params) {
        return getMultipleSheetsAsExcel(params);
    };
    __decorate$1i([
        Autowired('columnModel')
    ], ExcelCreator.prototype, "columnModel", void 0);
    __decorate$1i([
        Autowired('valueService')
    ], ExcelCreator.prototype, "valueService", void 0);
    __decorate$1i([
        Autowired('stylingService')
    ], ExcelCreator.prototype, "stylingService", void 0);
    __decorate$1i([
        Autowired('gridSerializer')
    ], ExcelCreator.prototype, "gridSerializer", void 0);
    __decorate$1i([
        Autowired('gridOptionsService')
    ], ExcelCreator.prototype, "gridOptionsService", void 0);
    __decorate$1i([
        Autowired('valueFormatterService')
    ], ExcelCreator.prototype, "valueFormatterService", void 0);
    __decorate$1i([
        Autowired('valueParserService')
    ], ExcelCreator.prototype, "valueParserService", void 0);
    __decorate$1i([
        PostConstruct
    ], ExcelCreator.prototype, "postConstruct", null);
    ExcelCreator = __decorate$1i([
        Bean('excelCreator')
    ], ExcelCreator);
    return ExcelCreator;
}(BaseCreator));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$d = '31.0.2';

var ExcelExportModule = {
    version: VERSION$d,
    moduleName: ModuleNames.ExcelExportModule,
    beans: [
        // beans in this module
        ExcelCreator,
        // these beans are part of CSV Export module
        GridSerializer, CsvCreator
    ],
    dependantModules: [
        CsvExportModule,
        EnterpriseCoreModule
    ]
};

var __extends$1N = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1h = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var EXPAND_STATE;
(function (EXPAND_STATE) {
    EXPAND_STATE[EXPAND_STATE["EXPANDED"] = 0] = "EXPANDED";
    EXPAND_STATE[EXPAND_STATE["COLLAPSED"] = 1] = "COLLAPSED";
    EXPAND_STATE[EXPAND_STATE["INDETERMINATE"] = 2] = "INDETERMINATE";
})(EXPAND_STATE || (EXPAND_STATE = {}));
var FiltersToolPanelHeaderPanel = /** @class */ (function (_super) {
    __extends$1N(FiltersToolPanelHeaderPanel, _super);
    function FiltersToolPanelHeaderPanel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FiltersToolPanelHeaderPanel.prototype.preConstruct = function () {
        this.setTemplate(/* html */ "<div class=\"ag-filter-toolpanel-search\" role=\"presentation\">\n                <div ref=\"eExpand\" class=\"ag-filter-toolpanel-expand\"></div>\n                <ag-input-text-field ref=\"eFilterTextField\" class=\"ag-filter-toolpanel-search-input\"></ag-input-text-field>\n            </div>");
    };
    FiltersToolPanelHeaderPanel.prototype.postConstruct = function () {
        var translate = this.localeService.getLocaleTextFunc();
        this.eFilterTextField
            .setAutoComplete(false)
            .setInputAriaLabel(translate('ariaFilterColumnsInput', 'Filter Columns Input'))
            .onValueChange(this.onSearchTextChanged.bind(this));
        this.createExpandIcons();
        this.setExpandState(EXPAND_STATE.EXPANDED);
        this.addManagedListener(this.eExpand, 'click', this.onExpandClicked.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_NEW_COLUMNS_LOADED, this.showOrHideOptions.bind(this));
    };
    FiltersToolPanelHeaderPanel.prototype.init = function (params) {
        this.params = params;
        if (this.columnModel.isReady()) {
            this.showOrHideOptions();
        }
    };
    FiltersToolPanelHeaderPanel.prototype.createExpandIcons = function () {
        this.eExpand.appendChild(this.eExpandChecked = _.createIconNoSpan('columnSelectOpen', this.gridOptionsService));
        this.eExpand.appendChild(this.eExpandUnchecked = _.createIconNoSpan('columnSelectClosed', this.gridOptionsService));
        this.eExpand.appendChild(this.eExpandIndeterminate = _.createIconNoSpan('columnSelectIndeterminate', this.gridOptionsService));
    };
    // we only show expand / collapse if we are showing filters
    FiltersToolPanelHeaderPanel.prototype.showOrHideOptions = function () {
        var showFilterSearch = !this.params.suppressFilterSearch;
        var showExpand = !this.params.suppressExpandAll;
        var translate = this.localeService.getLocaleTextFunc();
        this.eFilterTextField.setInputPlaceholder(translate('searchOoo', 'Search...'));
        var isFilterGroupPresent = function (col) { return col.getOriginalParent() && col.isFilterAllowed(); };
        var filterGroupsPresent = this.columnModel.getAllGridColumns().some(isFilterGroupPresent);
        _.setDisplayed(this.eFilterTextField.getGui(), showFilterSearch);
        _.setDisplayed(this.eExpand, showExpand && filterGroupsPresent);
    };
    FiltersToolPanelHeaderPanel.prototype.onSearchTextChanged = function () {
        var _this = this;
        if (!this.onSearchTextChangedDebounced) {
            this.onSearchTextChangedDebounced = _.debounce(function () {
                _this.dispatchEvent({ type: 'searchChanged', searchText: _this.eFilterTextField.getValue() });
            }, 300);
        }
        this.onSearchTextChangedDebounced();
    };
    FiltersToolPanelHeaderPanel.prototype.onExpandClicked = function () {
        var event = this.currentExpandState === EXPAND_STATE.EXPANDED ? { type: 'collapseAll' } : { type: 'expandAll' };
        this.dispatchEvent(event);
    };
    FiltersToolPanelHeaderPanel.prototype.setExpandState = function (state) {
        this.currentExpandState = state;
        _.setDisplayed(this.eExpandChecked, this.currentExpandState === EXPAND_STATE.EXPANDED);
        _.setDisplayed(this.eExpandUnchecked, this.currentExpandState === EXPAND_STATE.COLLAPSED);
        _.setDisplayed(this.eExpandIndeterminate, this.currentExpandState === EXPAND_STATE.INDETERMINATE);
    };
    __decorate$1h([
        Autowired('columnModel')
    ], FiltersToolPanelHeaderPanel.prototype, "columnModel", void 0);
    __decorate$1h([
        RefSelector('eExpand')
    ], FiltersToolPanelHeaderPanel.prototype, "eExpand", void 0);
    __decorate$1h([
        RefSelector('eFilterTextField')
    ], FiltersToolPanelHeaderPanel.prototype, "eFilterTextField", void 0);
    __decorate$1h([
        PreConstruct
    ], FiltersToolPanelHeaderPanel.prototype, "preConstruct", null);
    __decorate$1h([
        PostConstruct
    ], FiltersToolPanelHeaderPanel.prototype, "postConstruct", null);
    return FiltersToolPanelHeaderPanel;
}(Component));

var __extends$1M = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1g = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ToolPanelFilterComp = /** @class */ (function (_super) {
    __extends$1M(ToolPanelFilterComp, _super);
    function ToolPanelFilterComp(hideHeader, expandedCallback) {
        var _this = _super.call(this, ToolPanelFilterComp.TEMPLATE) || this;
        _this.expandedCallback = expandedCallback;
        _this.expanded = false;
        _this.hideHeader = hideHeader;
        return _this;
    }
    ToolPanelFilterComp.prototype.postConstruct = function () {
        this.eExpandChecked = _.createIconNoSpan('columnSelectOpen', this.gridOptionsService);
        this.eExpandUnchecked = _.createIconNoSpan('columnSelectClosed', this.gridOptionsService);
        this.eExpand.appendChild(this.eExpandChecked);
        this.eExpand.appendChild(this.eExpandUnchecked);
    };
    ToolPanelFilterComp.prototype.setColumn = function (column) {
        var _this = this;
        this.column = column;
        this.eFilterName.innerText = this.columnModel.getDisplayNameForColumn(this.column, 'filterToolPanel', false) || '';
        this.addManagedListener(this.eFilterToolPanelHeader, 'click', this.toggleExpanded.bind(this));
        this.addManagedListener(this.eFilterToolPanelHeader, 'keydown', function (e) {
            if (e.key === KeyCode.ENTER || e.key === KeyCode.SPACE) {
                e.preventDefault();
                _this.toggleExpanded();
            }
        });
        this.addManagedListener(this.eventService, Events.EVENT_FILTER_OPENED, this.onFilterOpened.bind(this));
        this.addInIcon('filter', this.eFilterIcon, this.column);
        _.setDisplayed(this.eFilterIcon, this.isFilterActive(), { skipAriaHidden: true });
        _.setDisplayed(this.eExpandChecked, false);
        if (this.hideHeader) {
            _.setDisplayed(this.eFilterToolPanelHeader, false);
            this.eFilterToolPanelHeader.removeAttribute('tabindex');
        }
        else {
            this.eFilterToolPanelHeader.setAttribute('tabindex', '0');
        }
        this.addManagedListener(this.column, Column.EVENT_FILTER_CHANGED, this.onFilterChanged.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_FILTER_DESTROYED, this.onFilterDestroyed.bind(this));
    };
    ToolPanelFilterComp.prototype.getColumn = function () {
        return this.column;
    };
    ToolPanelFilterComp.prototype.getColumnFilterName = function () {
        return this.columnModel.getDisplayNameForColumn(this.column, 'filterToolPanel', false);
    };
    ToolPanelFilterComp.prototype.addCssClassToTitleBar = function (cssClass) {
        this.eFilterToolPanelHeader.classList.add(cssClass);
    };
    ToolPanelFilterComp.prototype.addInIcon = function (iconName, eParent, column) {
        if (eParent == null) {
            return;
        }
        var eIcon = _.createIconNoSpan(iconName, this.gridOptionsService, column);
        eParent.appendChild(eIcon);
    };
    ToolPanelFilterComp.prototype.isFilterActive = function () {
        return this.filterManager.isFilterActive(this.column);
    };
    ToolPanelFilterComp.prototype.onFilterChanged = function () {
        _.setDisplayed(this.eFilterIcon, this.isFilterActive(), { skipAriaHidden: true });
        this.dispatchEvent({ type: Column.EVENT_FILTER_CHANGED });
    };
    ToolPanelFilterComp.prototype.onFilterDestroyed = function (event) {
        if (this.expanded &&
            event.source === 'api' &&
            event.column.getId() === this.column.getId() &&
            this.columnModel.getPrimaryColumn(this.column)) {
            // filter was visible and has been destroyed by the API. If the column still exists, need to recreate UI component
            this.removeFilterElement();
            this.addFilterElement();
        }
    };
    ToolPanelFilterComp.prototype.toggleExpanded = function () {
        this.expanded ? this.collapse() : this.expand();
    };
    ToolPanelFilterComp.prototype.expand = function () {
        if (this.expanded) {
            return;
        }
        this.expanded = true;
        _.setAriaExpanded(this.eFilterToolPanelHeader, true);
        _.setDisplayed(this.eExpandChecked, true);
        _.setDisplayed(this.eExpandUnchecked, false);
        this.addFilterElement();
        this.expandedCallback();
    };
    ToolPanelFilterComp.prototype.addFilterElement = function () {
        var _this = this;
        var filterPanelWrapper = _.loadTemplate(/* html */ "<div class=\"ag-filter-toolpanel-instance-filter\"></div>");
        var filterWrapper = this.filterManager.getOrCreateFilterWrapper(this.column, 'TOOLBAR');
        if (!filterWrapper) {
            return;
        }
        var filterPromise = filterWrapper.filterPromise, guiPromise = filterWrapper.guiPromise;
        filterPromise === null || filterPromise === void 0 ? void 0 : filterPromise.then(function (filter) {
            _this.underlyingFilter = filter;
            if (!filter) {
                return;
            }
            guiPromise.then(function (filterContainerEl) {
                if (filterContainerEl) {
                    filterPanelWrapper.appendChild(filterContainerEl);
                }
                _this.agFilterToolPanelBody.appendChild(filterPanelWrapper);
                if (filter.afterGuiAttached) {
                    filter.afterGuiAttached({ container: 'toolPanel' });
                }
            });
        });
    };
    ToolPanelFilterComp.prototype.collapse = function () {
        var _a, _b;
        if (!this.expanded) {
            return;
        }
        this.expanded = false;
        _.setAriaExpanded(this.eFilterToolPanelHeader, false);
        this.removeFilterElement();
        _.setDisplayed(this.eExpandChecked, false);
        _.setDisplayed(this.eExpandUnchecked, true);
        (_b = (_a = this.underlyingFilter) === null || _a === void 0 ? void 0 : _a.afterGuiDetached) === null || _b === void 0 ? void 0 : _b.call(_a);
        this.expandedCallback();
    };
    ToolPanelFilterComp.prototype.removeFilterElement = function () {
        _.clearElement(this.agFilterToolPanelBody);
    };
    ToolPanelFilterComp.prototype.isExpanded = function () {
        return this.expanded;
    };
    ToolPanelFilterComp.prototype.refreshFilter = function (isDisplayed) {
        var _a;
        if (!this.expanded) {
            return;
        }
        var filter = this.underlyingFilter;
        if (!filter) {
            return;
        }
        if (isDisplayed) {
            // set filters should be updated when the filter has been changed elsewhere, i.e. via api. Note that we can't
            // use 'afterGuiAttached' to refresh the virtual list as it also focuses on the mini filter which changes the
            // scroll position in the filter list panel
            if (typeof filter.refreshVirtualList === 'function') {
                filter.refreshVirtualList();
            }
        }
        else {
            (_a = filter.afterGuiDetached) === null || _a === void 0 ? void 0 : _a.call(filter);
        }
    };
    ToolPanelFilterComp.prototype.onFilterOpened = function (event) {
        if (event.source !== 'COLUMN_MENU') {
            return;
        }
        if (event.column !== this.column) {
            return;
        }
        if (!this.expanded) {
            return;
        }
        this.collapse();
    };
    ToolPanelFilterComp.TEMPLATE = "\n        <div class=\"ag-filter-toolpanel-instance\">\n            <div class=\"ag-filter-toolpanel-header ag-filter-toolpanel-instance-header\" ref=\"eFilterToolPanelHeader\" role=\"button\" aria-expanded=\"false\">\n                <div ref=\"eExpand\" class=\"ag-filter-toolpanel-expand\"></div>\n                <span ref=\"eFilterName\" class=\"ag-header-cell-text\"></span>\n                <span ref=\"eFilterIcon\" class=\"ag-header-icon ag-filter-icon ag-filter-toolpanel-instance-header-icon\" aria-hidden=\"true\"></span>\n            </div>\n            <div class=\"ag-filter-toolpanel-instance-body ag-filter\" ref=\"agFilterToolPanelBody\"></div>\n        </div>";
    __decorate$1g([
        RefSelector('eFilterToolPanelHeader')
    ], ToolPanelFilterComp.prototype, "eFilterToolPanelHeader", void 0);
    __decorate$1g([
        RefSelector('eFilterName')
    ], ToolPanelFilterComp.prototype, "eFilterName", void 0);
    __decorate$1g([
        RefSelector('agFilterToolPanelBody')
    ], ToolPanelFilterComp.prototype, "agFilterToolPanelBody", void 0);
    __decorate$1g([
        RefSelector('eFilterIcon')
    ], ToolPanelFilterComp.prototype, "eFilterIcon", void 0);
    __decorate$1g([
        RefSelector('eExpand')
    ], ToolPanelFilterComp.prototype, "eExpand", void 0);
    __decorate$1g([
        Autowired('filterManager')
    ], ToolPanelFilterComp.prototype, "filterManager", void 0);
    __decorate$1g([
        Autowired('columnModel')
    ], ToolPanelFilterComp.prototype, "columnModel", void 0);
    __decorate$1g([
        PostConstruct
    ], ToolPanelFilterComp.prototype, "postConstruct", null);
    return ToolPanelFilterComp;
}(Component));

var __extends$1L = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1f = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ToolPanelFilterGroupComp = /** @class */ (function (_super) {
    __extends$1L(ToolPanelFilterGroupComp, _super);
    function ToolPanelFilterGroupComp(columnGroup, childFilterComps, expandedCallback, depth, showingColumn) {
        var _this = _super.call(this) || this;
        _this.columnGroup = columnGroup;
        _this.childFilterComps = childFilterComps;
        _this.depth = depth;
        _this.expandedCallback = expandedCallback;
        _this.showingColumn = showingColumn;
        return _this;
    }
    ToolPanelFilterGroupComp.prototype.preConstruct = function () {
        var groupParams = {
            cssIdentifier: 'filter-toolpanel',
            direction: 'vertical'
        };
        this.setTemplate(ToolPanelFilterGroupComp.TEMPLATE, { filterGroupComp: groupParams });
    };
    ToolPanelFilterGroupComp.prototype.init = function () {
        var _this = this;
        this.setGroupTitle();
        this.filterGroupComp.setAlignItems('stretch');
        this.filterGroupComp.addCssClass("ag-filter-toolpanel-group-level-".concat(this.depth));
        this.filterGroupComp.addCssClassToTitleBar("ag-filter-toolpanel-group-level-".concat(this.depth, "-header"));
        this.childFilterComps.forEach(function (filterComp) {
            _this.filterGroupComp.addItem(filterComp);
            filterComp.addCssClassToTitleBar("ag-filter-toolpanel-group-level-".concat(_this.depth + 1, "-header"));
        });
        this.refreshFilterClass();
        this.addExpandCollapseListeners();
        this.addFilterChangedListeners();
        this.setupTooltip();
    };
    ToolPanelFilterGroupComp.prototype.setupTooltip = function () {
        var _this = this;
        // we don't show tooltips for groups, as when the group expands, it's div contains the columns which also
        // have tooltips, so the tooltips would clash. Eg mouse over group, tooltip shows, mouse over column, another
        // tooltip shows but cos we didn't leave the group the group tooltip remains. this should be fixed in the future,
        // maybe the group shouldn't contain the children form a DOM perspective.
        if (!this.showingColumn) {
            return;
        }
        var refresh = function () {
            var newTooltipText = _this.columnGroup.getColDef().headerTooltip;
            _this.setTooltip(newTooltipText);
        };
        refresh();
        this.addManagedListener(this.eventService, Events.EVENT_NEW_COLUMNS_LOADED, refresh);
    };
    ToolPanelFilterGroupComp.prototype.getTooltipParams = function () {
        var res = _super.prototype.getTooltipParams.call(this);
        res.location = 'filterToolPanelColumnGroup';
        return res;
    };
    ToolPanelFilterGroupComp.prototype.addCssClassToTitleBar = function (cssClass) {
        this.filterGroupComp.addCssClassToTitleBar(cssClass);
    };
    ToolPanelFilterGroupComp.prototype.refreshFilters = function (isDisplayed) {
        this.childFilterComps.forEach(function (filterComp) {
            if (filterComp instanceof ToolPanelFilterGroupComp) {
                filterComp.refreshFilters(isDisplayed);
            }
            else {
                filterComp.refreshFilter(isDisplayed);
            }
        });
    };
    ToolPanelFilterGroupComp.prototype.isColumnGroup = function () {
        return this.columnGroup instanceof ProvidedColumnGroup;
    };
    ToolPanelFilterGroupComp.prototype.isExpanded = function () {
        return this.filterGroupComp.isExpanded();
    };
    ToolPanelFilterGroupComp.prototype.getChildren = function () {
        return this.childFilterComps;
    };
    ToolPanelFilterGroupComp.prototype.getFilterGroupName = function () {
        return this.filterGroupName ? this.filterGroupName : '';
    };
    ToolPanelFilterGroupComp.prototype.getFilterGroupId = function () {
        return this.columnGroup.getId();
    };
    ToolPanelFilterGroupComp.prototype.hideGroupItem = function (hide, index) {
        this.filterGroupComp.hideItem(hide, index);
    };
    ToolPanelFilterGroupComp.prototype.hideGroup = function (hide) {
        this.setDisplayed(!hide);
    };
    ToolPanelFilterGroupComp.prototype.forEachToolPanelFilterChild = function (action) {
        this.childFilterComps.forEach(function (filterComp) {
            if (filterComp instanceof ToolPanelFilterComp) {
                action(filterComp);
            }
        });
    };
    ToolPanelFilterGroupComp.prototype.addExpandCollapseListeners = function () {
        var _this = this;
        var expandListener = this.isColumnGroup() ?
            function () { return _this.expandedCallback(); } :
            function () { return _this.forEachToolPanelFilterChild(function (filterComp) { return filterComp.expand(); }); };
        var collapseListener = this.isColumnGroup() ?
            function () { return _this.expandedCallback(); } :
            function () { return _this.forEachToolPanelFilterChild(function (filterComp) { return filterComp.collapse(); }); };
        this.addManagedListener(this.filterGroupComp, AgGroupComponent.EVENT_EXPANDED, expandListener);
        this.addManagedListener(this.filterGroupComp, AgGroupComponent.EVENT_COLLAPSED, collapseListener);
    };
    ToolPanelFilterGroupComp.prototype.getColumns = function () {
        if (this.columnGroup instanceof ProvidedColumnGroup) {
            return this.columnGroup.getLeafColumns();
        }
        return [this.columnGroup];
    };
    ToolPanelFilterGroupComp.prototype.addFilterChangedListeners = function () {
        var _this = this;
        this.getColumns().forEach(function (column) {
            _this.addManagedListener(column, Column.EVENT_FILTER_CHANGED, function () { return _this.refreshFilterClass(); });
        });
        if (!(this.columnGroup instanceof ProvidedColumnGroup)) {
            this.addManagedListener(this.eventService, Events.EVENT_FILTER_OPENED, this.onFilterOpened.bind(this));
        }
    };
    ToolPanelFilterGroupComp.prototype.refreshFilterClass = function () {
        var columns = this.getColumns();
        var anyChildFiltersActive = function () { return columns.some(function (col) { return col.isFilterActive(); }); };
        this.filterGroupComp.addOrRemoveCssClass('ag-has-filter', anyChildFiltersActive());
    };
    ToolPanelFilterGroupComp.prototype.onFilterOpened = function (event) {
        // when a filter is opened elsewhere, i.e. column menu we close the filter comp so we also need to collapse
        // the column group. This approach means we don't need to try and sync filter models on the same column.
        if (event.source !== 'COLUMN_MENU') {
            return;
        }
        if (event.column !== this.columnGroup) {
            return;
        }
        if (!this.isExpanded()) {
            return;
        }
        this.collapse();
    };
    ToolPanelFilterGroupComp.prototype.expand = function () {
        this.filterGroupComp.toggleGroupExpand(true);
    };
    ToolPanelFilterGroupComp.prototype.collapse = function () {
        this.filterGroupComp.toggleGroupExpand(false);
    };
    ToolPanelFilterGroupComp.prototype.setGroupTitle = function () {
        this.filterGroupName = (this.columnGroup instanceof ProvidedColumnGroup) ?
            this.getColumnGroupName(this.columnGroup) : this.getColumnName(this.columnGroup);
        this.filterGroupComp.setTitle(this.filterGroupName || '');
    };
    ToolPanelFilterGroupComp.prototype.getColumnGroupName = function (columnGroup) {
        return this.columnModel.getDisplayNameForProvidedColumnGroup(null, columnGroup, 'filterToolPanel');
    };
    ToolPanelFilterGroupComp.prototype.getColumnName = function (column) {
        return this.columnModel.getDisplayNameForColumn(column, 'filterToolPanel', false);
    };
    ToolPanelFilterGroupComp.prototype.destroyFilters = function () {
        this.childFilterComps = this.destroyBeans(this.childFilterComps);
        _.clearElement(this.getGui());
    };
    ToolPanelFilterGroupComp.prototype.destroy = function () {
        this.destroyFilters();
        _super.prototype.destroy.call(this);
    };
    ToolPanelFilterGroupComp.TEMPLATE = "<div class=\"ag-filter-toolpanel-group-wrapper\">\n            <ag-group-component ref=\"filterGroupComp\"></ag-group-component>\n        </div>";
    __decorate$1f([
        RefSelector('filterGroupComp')
    ], ToolPanelFilterGroupComp.prototype, "filterGroupComp", void 0);
    __decorate$1f([
        Autowired('columnModel')
    ], ToolPanelFilterGroupComp.prototype, "columnModel", void 0);
    __decorate$1f([
        PreConstruct
    ], ToolPanelFilterGroupComp.prototype, "preConstruct", null);
    __decorate$1f([
        PostConstruct
    ], ToolPanelFilterGroupComp.prototype, "init", null);
    return ToolPanelFilterGroupComp;
}(Component));

var __extends$1K = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1e = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var FiltersToolPanelListPanel = /** @class */ (function (_super) {
    __extends$1K(FiltersToolPanelListPanel, _super);
    function FiltersToolPanelListPanel() {
        var _this = _super.call(this, FiltersToolPanelListPanel.TEMPLATE) || this;
        _this.initialised = false;
        _this.hasLoadedInitialState = false;
        _this.isInitialState = false;
        _this.filterGroupComps = [];
        // If a column drag is happening, we suppress handling the event until it has completed
        _this.suppressOnColumnsChanged = false;
        _this.onColumnsChangedPending = false;
        return _this;
    }
    FiltersToolPanelListPanel.prototype.init = function (params) {
        var _this = this;
        this.initialised = true;
        var defaultParams = this.gridOptionsService.addGridCommonParams({
            suppressExpandAll: false,
            suppressFilterSearch: false,
            suppressSyncLayoutWithGrid: false
        });
        _.mergeDeep(defaultParams, params);
        this.params = defaultParams;
        if (!this.params.suppressSyncLayoutWithGrid) {
            this.addManagedListener(this.eventService, Events.EVENT_COLUMN_MOVED, function () { return _this.onColumnsChanged(); });
        }
        this.addManagedListener(this.eventService, Events.EVENT_NEW_COLUMNS_LOADED, function () { return _this.onColumnsChanged(); });
        this.addManagedListener(this.eventService, Events.EVENT_TOOL_PANEL_VISIBLE_CHANGED, function (event) {
            // when re-entering the filters tool panel we need to refresh the virtual lists in the set filters in case
            // filters have been changed elsewhere, i.e. via an api call.
            if (event.key === 'filters') {
                _this.refreshFilters(event.visible);
            }
        });
        this.addManagedListener(this.eventService, Events.EVENT_DRAG_STARTED, function () {
            _this.suppressOnColumnsChanged = true;
        });
        this.addManagedListener(this.eventService, Events.EVENT_DRAG_STOPPED, function () {
            _this.suppressOnColumnsChanged = false;
            if (_this.onColumnsChangedPending) {
                _this.onColumnsChangedPending = false;
                _this.onColumnsChanged();
            }
        });
        if (this.columnModel.isReady()) {
            this.onColumnsChanged();
        }
        var ariaEl = this.getAriaElement();
        _.setAriaLive(ariaEl, 'assertive');
        _.setAriaAtomic(ariaEl, false);
        _.setAriaRelevant(ariaEl, 'text');
    };
    FiltersToolPanelListPanel.prototype.onColumnsChanged = function () {
        if (this.suppressOnColumnsChanged) {
            this.onColumnsChangedPending = true;
            return;
        }
        var pivotModeActive = this.columnModel.isPivotMode();
        var shouldSyncColumnLayoutWithGrid = !this.params.suppressSyncLayoutWithGrid && !pivotModeActive;
        shouldSyncColumnLayoutWithGrid ? this.syncFilterLayout() : this.buildTreeFromProvidedColumnDefs();
        this.refreshAriaLabel();
    };
    FiltersToolPanelListPanel.prototype.syncFilterLayout = function () {
        this.toolPanelColDefService.syncLayoutWithGrid(this.setFiltersLayout.bind(this));
        this.refreshAriaLabel();
    };
    FiltersToolPanelListPanel.prototype.buildTreeFromProvidedColumnDefs = function () {
        var columnTree = this.columnModel.getPrimaryColumnTree();
        this.recreateFilters(columnTree);
    };
    FiltersToolPanelListPanel.prototype.setFiltersLayout = function (colDefs) {
        var columnTree = this.toolPanelColDefService.createColumnTree(colDefs);
        this.recreateFilters(columnTree);
    };
    FiltersToolPanelListPanel.prototype.recreateFilters = function (columnTree) {
        var _this = this;
        // Underlying filter comp/element won't get recreated if the column still exists (the element just gets detached/re-attached).
        // We can therefore restore focus if an element in the filter tool panel was focused.
        var activeElement = this.gridOptionsService.getDocument().activeElement;
        if (!this.hasLoadedInitialState) {
            this.hasLoadedInitialState = true;
            this.isInitialState = !!this.params.initialState;
        }
        // Want to restore the expansion state where possible.
        var expansionState = this.getExpansionState();
        this.destroyFilters();
        this.filterGroupComps = this.recursivelyAddComps(columnTree, 0, expansionState);
        var len = this.filterGroupComps.length;
        if (len) {
            // skip the destroy function because this will be managed
            // by the `destroyFilters` function
            this.filterGroupComps.forEach(function (comp) { return _this.appendChild(comp); });
            this.setFirstAndLastVisible(0, len - 1);
        }
        // perform search if searchFilterText exists
        if (_.exists(this.searchFilterText)) {
            this.searchFilters(this.searchFilterText);
        }
        // notify header of expand
        this.fireExpandedEvent();
        // We only care about restoring focus if the originally focused element was in the filter tool panel.
        if (this.getGui().contains(activeElement)) {
            activeElement.focus();
        }
        this.isInitialState = false;
        this.refreshAriaLabel();
    };
    FiltersToolPanelListPanel.prototype.recursivelyAddComps = function (tree, depth, expansionState) {
        var _this = this;
        return _.flatten(tree.map(function (child) {
            if (child instanceof ProvidedColumnGroup) {
                return _.flatten(_this.recursivelyAddFilterGroupComps(child, depth, expansionState));
            }
            var column = child;
            if (!_this.shouldDisplayFilter(column)) {
                return [];
            }
            var hideFilterCompHeader = depth === 0;
            var filterComp = new ToolPanelFilterComp(hideFilterCompHeader, function () { return _this.onFilterExpanded(); });
            _this.createBean(filterComp);
            filterComp.setColumn(column);
            if (expansionState.get(column.getId())) {
                // Default state on creation and desired state are both collapsed. Expand if expanded before.
                filterComp.expand();
            }
            if (depth > 0) {
                return filterComp;
            }
            var filterGroupComp = _this.createBean(new ToolPanelFilterGroupComp(column, [filterComp], _this.onGroupExpanded.bind(_this), depth, true));
            filterGroupComp.addCssClassToTitleBar('ag-filter-toolpanel-header');
            if (!expansionState.get(filterGroupComp.getFilterGroupId())) {
                // Default state on creation is expanded. Desired initial state is collapsed. Always collapse unless expanded before.
                filterGroupComp.collapse();
            }
            return filterGroupComp;
        }));
    };
    FiltersToolPanelListPanel.prototype.refreshAriaLabel = function () {
        var translate = this.localeService.getLocaleTextFunc();
        var filterListName = translate('ariaFilterPanelList', 'Filter List');
        var localeFilters = translate('filters', 'Filters');
        var eGui = this.getGui();
        var groupSelector = '.ag-filter-toolpanel-group-wrapper';
        var itemSelector = '.ag-filter-toolpanel-group-item';
        var hiddenSelector = '.ag-hidden';
        var visibleItems = eGui.querySelectorAll("".concat(itemSelector, ":not(").concat(groupSelector, ", ").concat(hiddenSelector, ")"));
        var totalVisibleItems = visibleItems.length;
        _.setAriaLabel(this.getAriaElement(), "".concat(filterListName, " ").concat(totalVisibleItems, " ").concat(localeFilters));
    };
    FiltersToolPanelListPanel.prototype.recursivelyAddFilterGroupComps = function (columnGroup, depth, expansionState) {
        if (!this.filtersExistInChildren(columnGroup.getChildren())) {
            return;
        }
        var colGroupDef = columnGroup.getColGroupDef();
        if (colGroupDef && colGroupDef.suppressFiltersToolPanel) {
            return [];
        }
        var newDepth = columnGroup.isPadding() ? depth : depth + 1;
        var childFilterComps = _.flatten(this.recursivelyAddComps(columnGroup.getChildren(), newDepth, expansionState));
        if (columnGroup.isPadding()) {
            return childFilterComps;
        }
        var filterGroupComp = new ToolPanelFilterGroupComp(columnGroup, childFilterComps, this.onGroupExpanded.bind(this), depth, false);
        this.createBean(filterGroupComp);
        filterGroupComp.addCssClassToTitleBar('ag-filter-toolpanel-header');
        var expansionStateValue = expansionState.get(filterGroupComp.getFilterGroupId());
        if ((this.isInitialState && !expansionStateValue) || expansionStateValue === false) {
            // Default state on creation is expanded. Desired initial state is expanded. Only collapse if collapsed before or using initial state.
            filterGroupComp.collapse();
        }
        return [filterGroupComp];
    };
    FiltersToolPanelListPanel.prototype.filtersExistInChildren = function (tree) {
        var _this = this;
        return tree.some(function (child) {
            if (child instanceof ProvidedColumnGroup) {
                return _this.filtersExistInChildren(child.getChildren());
            }
            return _this.shouldDisplayFilter(child);
        });
    };
    FiltersToolPanelListPanel.prototype.shouldDisplayFilter = function (column) {
        var suppressFiltersToolPanel = column.getColDef() && column.getColDef().suppressFiltersToolPanel;
        return column.isFilterAllowed() && !suppressFiltersToolPanel;
    };
    FiltersToolPanelListPanel.prototype.getExpansionState = function () {
        var expansionState = new Map();
        if (this.isInitialState) {
            var _a = this.params.initialState, expandedColIds = _a.expandedColIds, expandedGroupIds = _a.expandedGroupIds;
            expandedColIds.forEach(function (id) { return expansionState.set(id, true); });
            expandedGroupIds.forEach(function (id) { return expansionState.set(id, true); });
            return expansionState;
        }
        var recursiveGetExpansionState = function (filterGroupComp) {
            expansionState.set(filterGroupComp.getFilterGroupId(), filterGroupComp.isExpanded());
            filterGroupComp.getChildren().forEach(function (child) {
                if (child instanceof ToolPanelFilterGroupComp) {
                    recursiveGetExpansionState(child);
                }
                else {
                    expansionState.set(child.getColumn().getId(), child.isExpanded());
                }
            });
        };
        this.filterGroupComps.forEach(recursiveGetExpansionState);
        return expansionState;
    };
    // we don't support refreshing, but must implement because it's on the tool panel interface
    FiltersToolPanelListPanel.prototype.refresh = function () { };
    // lazy initialise the panel
    FiltersToolPanelListPanel.prototype.setVisible = function (visible) {
        _super.prototype.setDisplayed.call(this, visible);
        if (visible && !this.initialised) {
            this.init(this.params);
        }
    };
    FiltersToolPanelListPanel.prototype.expandFilterGroups = function (expand, groupIds) {
        var updatedGroupIds = [];
        var updateGroupExpandState = function (filterGroup) {
            var groupId = filterGroup.getFilterGroupId();
            var shouldExpandOrCollapse = !groupIds || _.includes(groupIds, groupId);
            if (shouldExpandOrCollapse) {
                // don't expand 'column groups', i.e. top level columns wrapped in a group
                if (expand && filterGroup.isColumnGroup()) {
                    filterGroup.expand();
                }
                else {
                    filterGroup.collapse();
                }
                updatedGroupIds.push(groupId);
            }
            // recursively look for more groups to expand / collapse
            filterGroup.getChildren().forEach(function (child) {
                if (child instanceof ToolPanelFilterGroupComp) {
                    updateGroupExpandState(child);
                }
            });
        };
        this.filterGroupComps.forEach(updateGroupExpandState);
        // update header expand / collapse icon
        this.onGroupExpanded();
        if (groupIds) {
            var unrecognisedGroupIds = groupIds.filter(function (groupId) { return updatedGroupIds.indexOf(groupId) < 0; });
            if (unrecognisedGroupIds.length > 0) {
                console.warn('AG Grid: unable to find groups for these supplied groupIds:', unrecognisedGroupIds);
            }
        }
    };
    FiltersToolPanelListPanel.prototype.expandFilters = function (expand, colIds) {
        var updatedColIds = [];
        var updateGroupExpandState = function (filterComp) {
            if (filterComp instanceof ToolPanelFilterGroupComp) {
                var anyChildrenChanged_1 = false;
                filterComp.getChildren().forEach(function (child) {
                    var childUpdated = updateGroupExpandState(child);
                    if (childUpdated) {
                        if (expand) {
                            filterComp.expand();
                            anyChildrenChanged_1 = true;
                        }
                        else if (!filterComp.isColumnGroup()) {
                            // we only collapse columns wrapped in groups
                            filterComp.collapse();
                        }
                    }
                });
                return anyChildrenChanged_1;
            }
            var colId = filterComp.getColumn().getColId();
            var updateFilterExpandState = !colIds || _.includes(colIds, colId);
            if (updateFilterExpandState) {
                expand ? filterComp.expand() : filterComp.collapse();
                updatedColIds.push(colId);
            }
            return updateFilterExpandState;
        };
        this.filterGroupComps.forEach(updateGroupExpandState);
        // update header expand / collapse icon
        this.onGroupExpanded();
        if (colIds) {
            var unrecognisedColIds = colIds.filter(function (colId) { return updatedColIds.indexOf(colId) < 0; });
            if (unrecognisedColIds.length > 0) {
                console.warn('AG Grid: unable to find columns for these supplied colIds:', unrecognisedColIds);
            }
        }
    };
    FiltersToolPanelListPanel.prototype.onGroupExpanded = function () {
        this.fireExpandedEvent();
    };
    FiltersToolPanelListPanel.prototype.onFilterExpanded = function () {
        this.dispatchEvent({ type: 'filterExpanded' });
    };
    FiltersToolPanelListPanel.prototype.fireExpandedEvent = function () {
        var expandedCount = 0;
        var notExpandedCount = 0;
        var updateExpandCounts = function (filterGroup) {
            if (!filterGroup.isColumnGroup()) {
                return;
            }
            filterGroup.isExpanded() ? expandedCount++ : notExpandedCount++;
            filterGroup.getChildren().forEach(function (child) {
                if (child instanceof ToolPanelFilterGroupComp) {
                    updateExpandCounts(child);
                }
            });
        };
        this.filterGroupComps.forEach(updateExpandCounts);
        var state;
        if (expandedCount > 0 && notExpandedCount > 0) {
            state = EXPAND_STATE.INDETERMINATE;
        }
        else if (notExpandedCount > 0) {
            state = EXPAND_STATE.COLLAPSED;
        }
        else {
            state = EXPAND_STATE.EXPANDED;
        }
        this.dispatchEvent({ type: 'groupExpanded', state: state });
    };
    FiltersToolPanelListPanel.prototype.performFilterSearch = function (searchText) {
        this.searchFilterText = _.exists(searchText) ? searchText.toLowerCase() : null;
        this.searchFilters(this.searchFilterText);
    };
    FiltersToolPanelListPanel.prototype.searchFilters = function (searchFilter) {
        var passesFilter = function (groupName) {
            return !_.exists(searchFilter) || groupName.toLowerCase().indexOf(searchFilter) !== -1;
        };
        var recursivelySearch = function (filterItem, parentPasses) {
            if (!(filterItem instanceof ToolPanelFilterGroupComp)) {
                return passesFilter(filterItem.getColumnFilterName() || '');
            }
            var children = filterItem.getChildren();
            var groupNamePasses = passesFilter(filterItem.getFilterGroupName());
            // if group or parent already passed - ensure this group and all children are visible
            var alreadyPassed = parentPasses || groupNamePasses;
            if (alreadyPassed) {
                // ensure group visible
                filterItem.hideGroup(false);
                // ensure all children are visible
                for (var i = 0; i < children.length; i++) {
                    recursivelySearch(children[i], alreadyPassed);
                    filterItem.hideGroupItem(false, i);
                }
                return true;
            }
            // hide group item filters
            var anyChildPasses = false;
            children.forEach(function (child, index) {
                var childPasses = recursivelySearch(child, parentPasses);
                filterItem.hideGroupItem(!childPasses, index);
                if (childPasses) {
                    anyChildPasses = true;
                }
            });
            // hide group if no children pass
            filterItem.hideGroup(!anyChildPasses);
            return anyChildPasses;
        };
        var firstVisible;
        var lastVisible;
        this.filterGroupComps.forEach(function (filterGroup, idx) {
            recursivelySearch(filterGroup, false);
            if (firstVisible === undefined) {
                if (!filterGroup.containsCssClass('ag-hidden')) {
                    firstVisible = idx;
                    lastVisible = idx;
                }
            }
            else if (!filterGroup.containsCssClass('ag-hidden') && lastVisible !== idx) {
                lastVisible = idx;
            }
        });
        this.setFirstAndLastVisible(firstVisible, lastVisible);
        this.refreshAriaLabel();
    };
    FiltersToolPanelListPanel.prototype.setFirstAndLastVisible = function (firstIdx, lastIdx) {
        this.filterGroupComps.forEach(function (filterGroup, idx) {
            filterGroup.removeCssClass('ag-first-group-visible');
            filterGroup.removeCssClass('ag-last-group-visible');
            if (idx === firstIdx) {
                filterGroup.addCssClass('ag-first-group-visible');
            }
            if (idx === lastIdx) {
                filterGroup.addCssClass('ag-last-group-visible');
            }
        });
    };
    FiltersToolPanelListPanel.prototype.refreshFilters = function (isDisplayed) {
        this.filterGroupComps.forEach(function (filterGroupComp) { return filterGroupComp.refreshFilters(isDisplayed); });
    };
    FiltersToolPanelListPanel.prototype.getExpandedFiltersAndGroups = function () {
        var expandedGroupIds = [];
        var expandedColIds = new Set();
        var getExpandedFiltersAndGroups = function (filterComp) {
            if (filterComp instanceof ToolPanelFilterGroupComp) {
                filterComp.getChildren().forEach(function (child) { return getExpandedFiltersAndGroups(child); });
                var groupId = filterComp.getFilterGroupId();
                if (filterComp.isExpanded() && !expandedColIds.has(groupId)) {
                    expandedGroupIds.push(groupId);
                }
            }
            else {
                if (filterComp.isExpanded()) {
                    expandedColIds.add(filterComp.getColumn().getColId());
                }
            }
        };
        this.filterGroupComps.forEach(getExpandedFiltersAndGroups);
        return { expandedGroupIds: expandedGroupIds, expandedColIds: Array.from(expandedColIds) };
    };
    FiltersToolPanelListPanel.prototype.destroyFilters = function () {
        this.filterGroupComps = this.destroyBeans(this.filterGroupComps);
        _.clearElement(this.getGui());
    };
    FiltersToolPanelListPanel.prototype.destroy = function () {
        this.destroyFilters();
        _super.prototype.destroy.call(this);
    };
    FiltersToolPanelListPanel.TEMPLATE = "<div class=\"ag-filter-list-panel\"></div>";
    __decorate$1e([
        Autowired('toolPanelColDefService')
    ], FiltersToolPanelListPanel.prototype, "toolPanelColDefService", void 0);
    __decorate$1e([
        Autowired('columnModel')
    ], FiltersToolPanelListPanel.prototype, "columnModel", void 0);
    return FiltersToolPanelListPanel;
}(Component));

var __extends$1J = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$r = (undefined && undefined.__assign) || function () {
    __assign$r = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$r.apply(this, arguments);
};
var __decorate$1d = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var FiltersToolPanel = /** @class */ (function (_super) {
    __extends$1J(FiltersToolPanel, _super);
    function FiltersToolPanel() {
        var _this = _super.call(this, FiltersToolPanel.TEMPLATE) || this;
        _this.initialised = false;
        _this.listenerDestroyFuncs = [];
        return _this;
    }
    FiltersToolPanel.prototype.init = function (params) {
        // if initialised is true, means this is a refresh
        if (this.initialised) {
            this.listenerDestroyFuncs.forEach(function (func) { return func(); });
            this.listenerDestroyFuncs = [];
        }
        this.initialised = true;
        var defaultParams = this.gridOptionsService.addGridCommonParams({
            suppressExpandAll: false,
            suppressFilterSearch: false,
            suppressSyncLayoutWithGrid: false
        });
        this.params = __assign$r(__assign$r({}, defaultParams), params);
        this.filtersToolPanelHeaderPanel.init(this.params);
        this.filtersToolPanelListPanel.init(this.params);
        var hideExpand = this.params.suppressExpandAll;
        var hideSearch = this.params.suppressFilterSearch;
        if (hideExpand && hideSearch) {
            this.filtersToolPanelHeaderPanel.setDisplayed(false);
        }
        // this is necessary to prevent a memory leak while refreshing the tool panel
        this.listenerDestroyFuncs.push(this.addManagedListener(this.filtersToolPanelHeaderPanel, 'expandAll', this.onExpandAll.bind(this)), this.addManagedListener(this.filtersToolPanelHeaderPanel, 'collapseAll', this.onCollapseAll.bind(this)), this.addManagedListener(this.filtersToolPanelHeaderPanel, 'searchChanged', this.onSearchChanged.bind(this)), this.addManagedListener(this.filtersToolPanelListPanel, 'filterExpanded', this.onFilterExpanded.bind(this)), this.addManagedListener(this.filtersToolPanelListPanel, 'groupExpanded', this.onGroupExpanded.bind(this)));
    };
    // lazy initialise the panel
    FiltersToolPanel.prototype.setVisible = function (visible) {
        _super.prototype.setDisplayed.call(this, visible);
        if (visible && !this.initialised) {
            this.init(this.params);
        }
    };
    FiltersToolPanel.prototype.onExpandAll = function () {
        this.filtersToolPanelListPanel.expandFilterGroups(true);
    };
    FiltersToolPanel.prototype.onCollapseAll = function () {
        this.filtersToolPanelListPanel.expandFilterGroups(false);
    };
    FiltersToolPanel.prototype.onSearchChanged = function (event) {
        this.filtersToolPanelListPanel.performFilterSearch(event.searchText);
    };
    FiltersToolPanel.prototype.setFilterLayout = function (colDefs) {
        this.filtersToolPanelListPanel.setFiltersLayout(colDefs);
    };
    FiltersToolPanel.prototype.onFilterExpanded = function () {
        this.params.onStateUpdated();
    };
    FiltersToolPanel.prototype.onGroupExpanded = function (event) {
        this.filtersToolPanelHeaderPanel.setExpandState(event.state);
        this.params.onStateUpdated();
    };
    FiltersToolPanel.prototype.expandFilterGroups = function (groupIds) {
        this.filtersToolPanelListPanel.expandFilterGroups(true, groupIds);
    };
    FiltersToolPanel.prototype.collapseFilterGroups = function (groupIds) {
        this.filtersToolPanelListPanel.expandFilterGroups(false, groupIds);
    };
    FiltersToolPanel.prototype.expandFilters = function (colIds) {
        this.filtersToolPanelListPanel.expandFilters(true, colIds);
    };
    FiltersToolPanel.prototype.collapseFilters = function (colIds) {
        this.filtersToolPanelListPanel.expandFilters(false, colIds);
    };
    FiltersToolPanel.prototype.syncLayoutWithGrid = function () {
        this.filtersToolPanelListPanel.syncFilterLayout();
    };
    FiltersToolPanel.prototype.refresh = function (params) {
        this.init(params);
        return true;
    };
    FiltersToolPanel.prototype.getState = function () {
        return this.filtersToolPanelListPanel.getExpandedFiltersAndGroups();
    };
    // this is a user component, and IComponent has "public destroy()" as part of the interface.
    // so we need to override destroy() just to make the method public.
    FiltersToolPanel.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
    };
    FiltersToolPanel.TEMPLATE = "<div class=\"ag-filter-toolpanel\">\n            <ag-filters-tool-panel-header ref=\"filtersToolPanelHeaderPanel\"></ag-filters-tool-panel-header>\n            <ag-filters-tool-panel-list ref=\"filtersToolPanelListPanel\"></ag-filters-tool-panel-list>\n         </div>";
    __decorate$1d([
        RefSelector('filtersToolPanelHeaderPanel')
    ], FiltersToolPanel.prototype, "filtersToolPanelHeaderPanel", void 0);
    __decorate$1d([
        RefSelector('filtersToolPanelListPanel')
    ], FiltersToolPanel.prototype, "filtersToolPanelListPanel", void 0);
    return FiltersToolPanel;
}(Component));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$c = '31.0.2';

var FiltersToolPanelModule = {
    version: VERSION$c,
    moduleName: ModuleNames.FiltersToolPanelModule,
    beans: [],
    agStackComponents: [
        { componentName: 'AgFiltersToolPanelHeader', componentClass: FiltersToolPanelHeaderPanel },
        { componentName: 'AgFiltersToolPanelList', componentClass: FiltersToolPanelListPanel }
    ],
    userComponents: [
        { componentName: 'agFiltersToolPanel', componentClass: FiltersToolPanel },
    ],
    dependantModules: [
        SideBarModule,
        EnterpriseCoreModule
    ]
};

var __defProp$1 = Object.defineProperty;
var __defProps$1 = Object.defineProperties;
var __getOwnPropDesc$1 = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs$1 = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols$1 = Object.getOwnPropertySymbols;
var __getProtoOf$1 = Object.getPrototypeOf;
var __hasOwnProp$1 = Object.prototype.hasOwnProperty;
var __propIsEnum$1 = Object.prototype.propertyIsEnumerable;
var __reflectGet$1 = Reflect.get;
var __knownSymbol$1 = (name, symbol) => {
  return (symbol = Symbol[name]) ? symbol : Symbol.for("Symbol." + name);
};
var __pow$1 = Math.pow;
var __defNormalProp$1 = (obj, key, value) => key in obj ? __defProp$1(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues$1 = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp$1.call(b, prop))
      __defNormalProp$1(a, prop, b[prop]);
  if (__getOwnPropSymbols$1)
    for (var prop of __getOwnPropSymbols$1(b)) {
      if (__propIsEnum$1.call(b, prop))
        __defNormalProp$1(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps$1 = (a, b) => __defProps$1(a, __getOwnPropDescs$1(b));
var __objRest$1 = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp$1.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols$1)
    for (var prop of __getOwnPropSymbols$1(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum$1.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __export$1 = (target, all) => {
  for (var name in all)
    __defProp$1(target, name, { get: all[name], enumerable: true });
};
var __decorateClass$1 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc$1(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp$1(target, key, result);
  return result;
};
var __superGet$1 = (cls, obj, key) => __reflectGet$1(__getProtoOf$1(cls), key, obj);
var __async$1 = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
var __await$1 = function(promise, isYieldStar) {
  this[0] = promise;
  this[1] = isYieldStar;
};
var __yieldStar$1 = (value) => {
  var obj = value[__knownSymbol$1("asyncIterator")];
  var isAwait = false;
  var method;
  var it = {};
  if (obj == null) {
    obj = value[__knownSymbol$1("iterator")]();
    method = (k) => it[k] = (x) => obj[k](x);
  } else {
    obj = obj.call(value);
    method = (k) => it[k] = (v) => {
      if (isAwait) {
        isAwait = false;
        if (k === "throw")
          throw v;
        return v;
      }
      isAwait = true;
      return {
        done: false,
        value: new __await$1(new Promise((resolve) => {
          var x = obj[k](v);
          if (!(x instanceof Object))
            throw TypeError("Object expected");
          resolve(x);
        }), 1)
      };
    };
  }
  return it[__knownSymbol$1("iterator")] = () => it, method("next"), "throw" in obj ? method("throw") : it.throw = (x) => {
    throw x;
  }, "return" in obj && method("return"), it;
};

// packages/ag-charts-community/src/util/time/index.ts
var time_exports$1 = {};
__export$1(time_exports$1, {
  day: () => day$1,
  friday: () => friday$1,
  hour: () => hour$1,
  millisecond: () => millisecond$1,
  minute: () => minute$1,
  monday: () => monday$1,
  month: () => month$1,
  saturday: () => saturday$1,
  second: () => second$1,
  sunday: () => sunday$1,
  thursday: () => thursday$1,
  tuesday: () => tuesday$1,
  utcDay: () => utcDay$1,
  utcHour: () => utcHour$1,
  utcMinute: () => utcMinute$1,
  utcMonth: () => utcMonth$1,
  utcYear: () => utcYear$1,
  wednesday: () => wednesday$1,
  year: () => year$1
});

// packages/ag-charts-community/src/util/time/interval.ts
var TimeInterval$1 = class {
  constructor(_encode, _decode, _rangeCallback) {
    this._encode = _encode;
    this._decode = _decode;
    this._rangeCallback = _rangeCallback;
  }
  /**
   * Returns a new date representing the latest interval boundary date before or equal to date.
   * For example, `day.floor(date)` typically returns 12:00 AM local time on the given date.
   * @param date
   */
  floor(date) {
    const d = new Date(date);
    const e = this._encode(d);
    return this._decode(e);
  }
  /**
   * Returns a new date representing the earliest interval boundary date after or equal to date.
   * @param date
   */
  ceil(date) {
    const d = new Date(Number(date) - 1);
    const e = this._encode(d);
    return this._decode(e + 1);
  }
  /**
   * Returns an array of dates representing every interval boundary after or equal to start (inclusive) and before stop (exclusive).
   * @param start Range start.
   * @param stop Range end.
   * @param extend If specified, the requested range will be extended to the closest "nice" values.
   */
  range(start, stop, extend) {
    var _a;
    const rangeCallback = (_a = this._rangeCallback) == null ? void 0 : _a.call(this, start, stop);
    const e0 = this._encode(extend ? this.floor(start) : this.ceil(start));
    const e1 = this._encode(extend ? this.ceil(stop) : this.floor(stop));
    if (e1 < e0) {
      return [];
    }
    const range3 = [];
    for (let e = e0; e <= e1; e++) {
      const d = this._decode(e);
      range3.push(d);
    }
    rangeCallback == null ? void 0 : rangeCallback();
    return range3;
  }
};
var CountableTimeInterval$1 = class extends TimeInterval$1 {
  getOffset(snapTo, step) {
    const s = typeof snapTo === "number" || snapTo instanceof Date ? this._encode(new Date(snapTo)) : 0;
    return Math.floor(s) % step;
  }
  /**
   * Returns a filtered view of this interval representing every step'th date.
   * It can be a number of minutes, hours, days etc.
   * Must be a positive integer.
   * @param step
   */
  every(step, options) {
    let offset4 = 0;
    let rangeCallback;
    const { snapTo = "start" } = options != null ? options : {};
    if (typeof snapTo === "string") {
      const initialOffset = offset4;
      rangeCallback = (start, stop) => {
        const s = snapTo === "start" ? start : stop;
        offset4 = this.getOffset(s, step);
        return () => offset4 = initialOffset;
      };
    } else if (typeof snapTo === "number") {
      offset4 = this.getOffset(new Date(snapTo), step);
    } else if (snapTo instanceof Date) {
      offset4 = this.getOffset(snapTo, step);
    }
    const encode13 = (date) => {
      const e = this._encode(date);
      return Math.floor((e - offset4) / step);
    };
    const decode13 = (encoded) => {
      return this._decode(encoded * step + offset4);
    };
    return new TimeInterval$1(encode13, decode13, rangeCallback);
  }
};

// packages/ag-charts-community/src/util/time/millisecond.ts
function encode$1(date) {
  return date.getTime();
}
function decode$1(encoded) {
  return new Date(encoded);
}
var millisecond$1 = new CountableTimeInterval$1(encode$1, decode$1);
var millisecond_default$1 = millisecond$1;

// packages/ag-charts-community/src/util/time/duration.ts
(/* @__PURE__ */ new Date(0)).getFullYear();
var durationSecond$1 = 1e3;
var durationMinute$1 = durationSecond$1 * 60;
var durationHour$1 = durationMinute$1 * 60;
var durationDay$1 = durationHour$1 * 24;
var durationWeek$1 = durationDay$1 * 7;
var durationMonth$1 = durationDay$1 * 30;
var durationYear$1 = durationDay$1 * 365;

// packages/ag-charts-community/src/util/time/second.ts
var offset$1 = (/* @__PURE__ */ new Date()).getTimezoneOffset() * durationMinute$1;
function encode2$1(date) {
  return Math.floor((date.getTime() - offset$1) / durationSecond$1);
}
function decode2$1(encoded) {
  return new Date(offset$1 + encoded * durationSecond$1);
}
var second$1 = new CountableTimeInterval$1(encode2$1, decode2$1);
var second_default$1 = second$1;

// packages/ag-charts-community/src/util/time/minute.ts
var offset2$1 = (/* @__PURE__ */ new Date()).getTimezoneOffset() * durationMinute$1;
function encode3$1(date) {
  return Math.floor((date.getTime() - offset2$1) / durationMinute$1);
}
function decode3$1(encoded) {
  return new Date(offset2$1 + encoded * durationMinute$1);
}
var minute$1 = new CountableTimeInterval$1(encode3$1, decode3$1);
var minute_default$1 = minute$1;

// packages/ag-charts-community/src/util/time/hour.ts
var offset3$1 = (/* @__PURE__ */ new Date()).getTimezoneOffset() * durationMinute$1;
function encode4$1(date) {
  return Math.floor((date.getTime() - offset3$1) / durationHour$1);
}
function decode4$1(encoded) {
  return new Date(offset3$1 + encoded * durationHour$1);
}
var hour$1 = new CountableTimeInterval$1(encode4$1, decode4$1);
var hour_default$1 = hour$1;

// packages/ag-charts-community/src/util/time/day.ts
function encode5$1(date) {
  const tzOffsetMs = date.getTimezoneOffset() * durationMinute$1;
  return Math.floor((date.getTime() - tzOffsetMs) / durationDay$1);
}
function decode5$1(encoded) {
  const d = new Date(1970, 0, 1);
  d.setDate(d.getDate() + encoded);
  return d;
}
var day$1 = new CountableTimeInterval$1(encode5$1, decode5$1);
var day_default$1 = day$1;

// packages/ag-charts-community/src/util/time/week.ts
function weekday$1(weekStart) {
  const thursday2 = 4;
  const dayShift = (7 + weekStart - thursday2) % 7;
  function encode13(date) {
    const tzOffsetMs = date.getTimezoneOffset() * durationMinute$1;
    return Math.floor((date.getTime() - tzOffsetMs) / durationWeek$1 - dayShift / 7);
  }
  function decode13(encoded) {
    const d = new Date(1970, 0, 1);
    d.setDate(d.getDate() + encoded * 7 + dayShift);
    return d;
  }
  return new CountableTimeInterval$1(encode13, decode13);
}
var sunday$1 = weekday$1(0);
var monday$1 = weekday$1(1);
var tuesday$1 = weekday$1(2);
var wednesday$1 = weekday$1(3);
var thursday$1 = weekday$1(4);
var friday$1 = weekday$1(5);
var saturday$1 = weekday$1(6);
var week_default$1 = sunday$1;

// packages/ag-charts-community/src/util/time/month.ts
function encode6$1(date) {
  return date.getFullYear() * 12 + date.getMonth();
}
function decode6$1(encoded) {
  const year2 = Math.floor(encoded / 12);
  const month2 = encoded - year2 * 12;
  return new Date(year2, month2, 1);
}
var month$1 = new CountableTimeInterval$1(encode6$1, decode6$1);
var month_default$1 = month$1;

// packages/ag-charts-community/src/util/time/year.ts
function encode7$1(date) {
  return date.getFullYear();
}
function decode7$1(encoded) {
  const d = /* @__PURE__ */ new Date();
  d.setFullYear(encoded);
  d.setMonth(0, 1);
  d.setHours(0, 0, 0, 0);
  return d;
}
var year$1 = new CountableTimeInterval$1(encode7$1, decode7$1);
var year_default$1 = year$1;

// packages/ag-charts-community/src/util/time/utcMinute.ts
function encode8$1(date) {
  return Math.floor(date.getTime() / durationMinute$1);
}
function decode8$1(encoded) {
  return new Date(encoded * durationMinute$1);
}
var utcMinute$1 = new CountableTimeInterval$1(encode8$1, decode8$1);

// packages/ag-charts-community/src/util/time/utcHour.ts
function encode9$1(date) {
  return Math.floor(date.getTime() / durationHour$1);
}
function decode9$1(encoded) {
  return new Date(encoded * durationHour$1);
}
var utcHour$1 = new CountableTimeInterval$1(encode9$1, decode9$1);

// packages/ag-charts-community/src/util/time/utcDay.ts
function encode10$1(date) {
  return Math.floor(date.getTime() / durationDay$1);
}
function decode10$1(encoded) {
  const d = /* @__PURE__ */ new Date(0);
  d.setUTCDate(d.getUTCDate() + encoded);
  d.setUTCHours(0, 0, 0, 0);
  return d;
}
var utcDay$1 = new CountableTimeInterval$1(encode10$1, decode10$1);

// packages/ag-charts-community/src/util/time/utcMonth.ts
function encode11$1(date) {
  return date.getUTCFullYear() * 12 + date.getUTCMonth();
}
function decode11$1(encoded) {
  const year2 = Math.floor(encoded / 12);
  const month2 = encoded - year2 * 12;
  return new Date(Date.UTC(year2, month2, 1));
}
var utcMonth$1 = new CountableTimeInterval$1(encode11$1, decode11$1);

// packages/ag-charts-community/src/util/time/utcYear.ts
function encode12$1(date) {
  return date.getUTCFullYear();
}
function decode12$1(encoded) {
  const d = /* @__PURE__ */ new Date();
  d.setUTCFullYear(encoded);
  d.setUTCMonth(0, 1);
  d.setUTCHours(0, 0, 0, 0);
  return d;
}
var utcYear$1 = new CountableTimeInterval$1(encode12$1, decode12$1);

// packages/ag-charts-community/src/module/enterpriseModule.ts
var enterpriseModule$1 = {
  isEnterprise: false
};

// packages/ag-charts-community/src/module/module.ts
var BaseModuleInstance$1 = class {
  constructor() {
    this.destroyFns = [];
  }
  destroy() {
    for (const destroyFn of this.destroyFns) {
      destroyFn();
    }
  }
};
var REGISTERED_MODULES$1 = [];
function registerModule$1(module) {
  const otherModule = REGISTERED_MODULES$1.find((other) => {
    return module.type === other.type && module.optionsKey === other.optionsKey && module.identifier === other.identifier;
  });
  if (otherModule) {
    if (module.packageType === "enterprise" && otherModule.packageType === "community") {
      const index = REGISTERED_MODULES$1.indexOf(otherModule);
      REGISTERED_MODULES$1.splice(index, 1, module);
    }
  } else {
    REGISTERED_MODULES$1.push(module);
  }
}
function hasRegisteredEnterpriseModules$1() {
  return REGISTERED_MODULES$1.some((m) => m.packageType === "enterprise");
}
var MODULE_CONFLICTS$1 = /* @__PURE__ */ new Map();
function registerModuleConflicts$1(source, targets) {
  MODULE_CONFLICTS$1.set(source, targets);
}

// packages/ag-charts-community/src/util/array.ts
function extent$5(values) {
  const { length } = values;
  if (length === 0) {
    return void 0;
  }
  let min = Infinity;
  let max = -Infinity;
  for (let i = 0; i < length; i++) {
    let v = values[i];
    if (v instanceof Date) {
      v = v.getTime();
    }
    if (typeof v !== "number") {
      continue;
    }
    if (v < min) {
      min = v;
    }
    if (v > max) {
      max = v;
    }
  }
  const extent2 = [min, max];
  if (extent2.some((v) => !isFinite(v))) {
    return void 0;
  }
  return extent2;
}
function normalisedExtent$1(d, min, max) {
  return normalisedExtentWithMetadata$1(d, min, max).extent;
}
function normalisedExtentWithMetadata$1(d, min, max) {
  var _a;
  let clipped = false;
  if (d.length > 2) {
    d = (_a = extent$5(d)) != null ? _a : [NaN, NaN];
  }
  if (!isNaN(min)) {
    clipped || (clipped = min > d[0]);
    d = [min, d[1]];
  }
  if (!isNaN(max)) {
    clipped || (clipped = max < d[1]);
    d = [d[0], max];
  }
  if (d[0] > d[1]) {
    d = [];
  }
  return { extent: d, clipped };
}
function arraysEqual$1(a, b) {
  if (a == null || b == null || a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (Array.isArray(a[i]) && Array.isArray(b[i])) {
      if (!arraysEqual$1(a[i], b[i])) {
        return false;
      }
    } else if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}
function toArray$1(value) {
  if (typeof value === "undefined") {
    return [];
  }
  return Array.isArray(value) ? value : [value];
}
function unique$1(array) {
  return Array.from(new Set(array));
}

// packages/ag-charts-community/src/util/function.ts
var doOnceFlags$2 = {};
function doOnce$2(func, key) {
  if (doOnceFlags$2[key]) {
    return;
  }
  func();
  doOnceFlags$2[key] = true;
}

// packages/ag-charts-community/src/util/logger.ts
var Logger$1 = {
  log(...logContent) {
    console.log(...logContent);
  },
  warn(message, ...logContent) {
    console.warn(`AG Charts - ${message}`, ...logContent);
  },
  error(message, ...logContent) {
    if (typeof message === "object") {
      console.error(`AG Charts error`, message, ...logContent);
    } else {
      console.error(`AG Charts - ${message}`, ...logContent);
    }
  },
  table(...logContent) {
    console.table(...logContent);
  },
  warnOnce(message, ...logContent) {
    doOnce$2(() => Logger$1.warn(message, ...logContent), `Logger.warn: ${message}`);
  },
  errorOnce(message, ...logContent) {
    doOnce$2(() => Logger$1.error(message, ...logContent), `Logger.error: ${message}`);
  }
};

// packages/ag-charts-community/src/util/window.ts
function windowValue$1(name) {
  const WINDOW = typeof window !== "undefined" ? window : (
    // typeof global !== 'undefined' ? (global as any) :
    void 0
  );
  return WINDOW == null ? void 0 : WINDOW[name];
}

// packages/ag-charts-community/src/util/debug.ts
var LONG_TIME_PERIOD_THRESHOLD$1 = 2e3;
var timeOfLastLog$1 = Date.now();
var logTimeGap$1 = () => {
  const timeSinceLastLog = Date.now() - timeOfLastLog$1;
  if (timeSinceLastLog > LONG_TIME_PERIOD_THRESHOLD$1) {
    const prettyDuration = (Math.floor(timeSinceLastLog / 100) / 10).toFixed(1);
    Logger$1.log(`**** ${prettyDuration}s since last log message ****`);
  }
  timeOfLastLog$1 = Date.now();
};
var Debug$1 = {
  create(...debugSelectors) {
    return (...logContent) => {
      if (Debug$1.check(...debugSelectors)) {
        if (typeof logContent[0] === "function") {
          logContent = toArray$1(logContent[0]());
        }
        logTimeGap$1();
        Logger$1.log(...logContent);
      }
    };
  },
  check(...debugSelectors) {
    if (debugSelectors.length === 0) {
      debugSelectors.push(true);
    }
    const chartDebug = toArray$1(windowValue$1("agChartsDebug"));
    return chartDebug.some((selector) => debugSelectors.includes(selector));
  }
};

// packages/ag-charts-community/src/util/decorator.ts
var BREAK_TRANSFORM_CHAIN$1 = Symbol("BREAK");
var CONFIG_KEY$1 = "__decorator_config";
function initialiseConfig$1(target, propertyKeyOrSymbol) {
  if (Object.getOwnPropertyDescriptor(target, CONFIG_KEY$1) == null) {
    Object.defineProperty(target, CONFIG_KEY$1, { value: {} });
  }
  const config = target[CONFIG_KEY$1];
  const propertyKey = propertyKeyOrSymbol.toString();
  if (typeof config[propertyKey] !== "undefined") {
    return config[propertyKey];
  }
  const valuesMap = /* @__PURE__ */ new WeakMap();
  config[propertyKey] = { setters: [], getters: [], valuesMap };
  const descriptor = Object.getOwnPropertyDescriptor(target, propertyKeyOrSymbol);
  const prevSet = descriptor == null ? void 0 : descriptor.set;
  const prevGet = descriptor == null ? void 0 : descriptor.get;
  const getter = function() {
    let value = prevGet ? prevGet.call(this) : valuesMap.get(this);
    for (const transformFn of config[propertyKey].getters) {
      value = transformFn(this, propertyKeyOrSymbol, value);
      if (value === BREAK_TRANSFORM_CHAIN$1) {
        return;
      }
    }
    return value;
  };
  const setter = function(value) {
    const { setters } = config[propertyKey];
    let oldValue;
    if (setters.some((f) => f.length > 2)) {
      oldValue = prevGet ? prevGet.call(this) : valuesMap.get(this);
    }
    for (const transformFn of setters) {
      value = transformFn(this, propertyKeyOrSymbol, value, oldValue);
      if (value === BREAK_TRANSFORM_CHAIN$1) {
        return;
      }
    }
    if (prevSet) {
      prevSet.call(this, value);
    } else {
      valuesMap.set(this, value);
    }
  };
  Object.defineProperty(target, propertyKeyOrSymbol, {
    set: setter,
    get: getter,
    enumerable: true,
    configurable: false
  });
  return config[propertyKey];
}
function addTransformToInstanceProperty$1(setTransform, getTransform, configMetadata) {
  return (target, propertyKeyOrSymbol) => {
    const config = initialiseConfig$1(target, propertyKeyOrSymbol);
    config.setters.push(setTransform);
    if (getTransform) {
      config.getters.unshift(getTransform);
    }
    if (configMetadata) {
      Object.assign(config, configMetadata);
    }
  };
}
function isDecoratedObject$1(target) {
  return typeof target !== "undefined" && CONFIG_KEY$1 in target;
}
function listDecoratedProperties$1(target) {
  const targets = /* @__PURE__ */ new Set();
  while (isDecoratedObject$1(target)) {
    targets.add(target == null ? void 0 : target[CONFIG_KEY$1]);
    target = Object.getPrototypeOf(target);
  }
  return Array.from(targets).flatMap((configMap) => Object.keys(configMap));
}
function extractDecoratedProperties$1(target) {
  return listDecoratedProperties$1(target).reduce((result, key) => {
    var _a;
    result[key] = (_a = target[key]) != null ? _a : null;
    return result;
  }, {});
}
function extractDecoratedPropertyMetadata$1(target, propertyKeyOrSymbol) {
  const propertyKey = propertyKeyOrSymbol.toString();
  while (isDecoratedObject$1(target)) {
    const config = target[CONFIG_KEY$1];
    if (Object.hasOwn(config, propertyKey)) {
      return config[propertyKey];
    }
    target = Object.getPrototypeOf(target);
  }
}

// packages/ag-charts-community/src/util/deprecation.ts
function createDeprecationWarning$1() {
  return (key, message) => {
    const msg = [`Property [${key}] is deprecated.`, message].filter(Boolean).join(" ");
    Logger$1.warnOnce(msg);
  };
}
function Deprecated$1(message, opts) {
  const warnDeprecated = createDeprecationWarning$1();
  const def = opts == null ? void 0 : opts.default;
  return addTransformToInstanceProperty$1((_, key, value) => {
    if (value !== def) {
      warnDeprecated(key.toString(), message);
    }
    return value;
  });
}
function DeprecatedAndRenamedTo$1(newPropName, mapValue) {
  const warnDeprecated = createDeprecationWarning$1();
  return addTransformToInstanceProperty$1(
    (target, key, value) => {
      if (value !== target[newPropName]) {
        warnDeprecated(key.toString(), `Use [${newPropName}] instead.`);
        target[newPropName] = mapValue ? mapValue(value) : value;
      }
      return BREAK_TRANSFORM_CHAIN$1;
    },
    (target, key) => {
      warnDeprecated(key.toString(), `Use [${newPropName}] instead.`);
      return target[newPropName];
    }
  );
}

// packages/ag-charts-community/src/scene/changeDetectable.ts
var RedrawType$1 = /* @__PURE__ */ ((RedrawType2) => {
  RedrawType2[RedrawType2["NONE"] = 0] = "NONE";
  RedrawType2[RedrawType2["TRIVIAL"] = 1] = "TRIVIAL";
  RedrawType2[RedrawType2["MINOR"] = 2] = "MINOR";
  RedrawType2[RedrawType2["MAJOR"] = 3] = "MAJOR";
  return RedrawType2;
})(RedrawType$1 || {});
function functionConstructorAvailable$1() {
  try {
    new Function("return true");
    return true;
  } catch (e) {
    return false;
  }
}
var STRING_FUNCTION_USEABLE$1 = functionConstructorAvailable$1();
function SceneChangeDetection$1(opts) {
  const { changeCb, convertor } = opts != null ? opts : {};
  return function(target, key) {
    const privateKey = `__${key}`;
    if (target[key]) {
      return;
    }
    if (STRING_FUNCTION_USEABLE$1 && changeCb == null && convertor == null) {
      prepareFastGetSet$1(target, key, privateKey, opts);
    } else {
      prepareSlowGetSet$1(target, key, privateKey, opts);
    }
  };
}
function prepareFastGetSet$1(target, key, privateKey, opts) {
  const { redraw = 1 /* TRIVIAL */, type = "normal", checkDirtyOnAssignment = false } = opts != null ? opts : {};
  const setterJs = new Function(
    "value",
    `
        const oldValue = this.${privateKey};
        if (value !== oldValue) {
            this.${privateKey} = value;
            ${type === "normal" ? `this.markDirty(this, ${redraw});` : ""}
            ${type === "transform" ? `this.markDirtyTransform(${redraw});` : ""}
            ${type === "path" ? `if (!this._dirtyPath) { this._dirtyPath = true; this.markDirty(this, ${redraw}); }` : ""}
            ${type === "font" ? `if (!this._dirtyFont) { this._dirtyFont = true; this.markDirty(this, ${redraw}); }` : ""}
        }
        ${checkDirtyOnAssignment ? `if (value != null && value._dirty > ${0 /* NONE */}) { this.markDirty(value, value._dirty); }` : ""}
`
  );
  const getterJs = new Function(`return this.${privateKey};`);
  Object.defineProperty(target, key, {
    set: setterJs,
    get: getterJs,
    enumerable: true,
    configurable: true
  });
}
function prepareSlowGetSet$1(target, key, privateKey, opts) {
  const {
    redraw = 1 /* TRIVIAL */,
    type = "normal",
    changeCb,
    convertor,
    checkDirtyOnAssignment = false
  } = opts != null ? opts : {};
  const setter = function(value) {
    const oldValue = this[privateKey];
    value = convertor ? convertor(value) : value;
    if (value !== oldValue) {
      this[privateKey] = value;
      if (type === "normal")
        this.markDirty(this, redraw);
      if (type === "transform")
        this.markDirtyTransform(redraw);
      if (type === "path" && !this._dirtyPath) {
        this._dirtyPath = true;
        this.markDirty(this, redraw);
      }
      if (type === "font" && !this._dirtyFont) {
        this._dirtyFont = true;
        this.markDirty(this, redraw);
      }
      if (changeCb)
        changeCb(this);
    }
    if (checkDirtyOnAssignment && value != null && value._dirty > 0 /* NONE */)
      this.markDirty(value, value._dirty);
  };
  const getter = function() {
    return this[privateKey];
  };
  Object.defineProperty(target, key, {
    set: setter,
    get: getter,
    enumerable: true,
    configurable: true
  });
}
var ChangeDetectable$1 = class {
  constructor() {
    this._dirty = 3 /* MAJOR */;
  }
  markDirty(_source, type = 1 /* TRIVIAL */) {
    if (this._dirty > type) {
      return;
    }
    this._dirty = type;
  }
  markClean(_opts) {
    this._dirty = 0 /* NONE */;
  }
  isDirty() {
    return this._dirty > 0 /* NONE */;
  }
};

// packages/ag-charts-community/src/util/type-guards.ts
function isDefined$1(val) {
  return val != null;
}
function isArray$1(value) {
  return Array.isArray(value);
}
function isBoolean$2(value) {
  return typeof value === "boolean";
}
function isDate$2(value) {
  return value instanceof Date;
}
function isValidDate$1(value) {
  return isDate$2(value) && !isNaN(Number(value));
}
function isFunction$1(value) {
  return typeof value === "function";
}
function isObject$1(value) {
  return typeof value === "object" && value !== null && !isArray$1(value);
}
function isObjectLike$1(value) {
  return typeof value === "object" && value !== null;
}
function isPlainObject$1(value) {
  return typeof value === "object" && value !== null && value.constructor === Object;
}
function isString$3(value) {
  return typeof value === "string";
}
function isNumber$5(value) {
  return typeof value === "number";
}
function isFiniteNumber$1(value) {
  return isNumber$5(value) && Number.isFinite(value);
}
function isHtmlElement$1(value) {
  return typeof window !== "undefined" && value instanceof HTMLElement;
}

// packages/ag-charts-community/src/util/properties.ts
var BaseProperties$1 = class extends ChangeDetectable$1 {
  constructor(className) {
    super();
    this.className = className;
  }
  set(properties) {
    const keys = new Set(Object.keys(properties));
    for (const propertyKey of listDecoratedProperties$1(this)) {
      if (keys.has(propertyKey)) {
        const value = properties[propertyKey];
        const self = this;
        if (isProperties$1(self[propertyKey])) {
          self[propertyKey] = self[propertyKey] instanceof PropertiesArray$1 ? self[propertyKey].reset(value) : self[propertyKey].set(value);
        } else {
          self[propertyKey] = value;
        }
        keys.delete(propertyKey);
      }
    }
    for (const unknownKey of keys) {
      const { className = this.constructor.name } = this;
      Logger$1.warn(`unable to set [${unknownKey}] in ${className} - property is unknown`);
    }
    return this;
  }
  isValid() {
    return listDecoratedProperties$1(this).every((propertyKey) => {
      const { optional } = extractDecoratedPropertyMetadata$1(this, propertyKey);
      return optional || typeof this[propertyKey] !== "undefined";
    });
  }
  toJson() {
    return listDecoratedProperties$1(this).reduce((object, propertyKey) => {
      object[propertyKey] = this[propertyKey];
      return object;
    }, {});
  }
};
var PropertiesArray$1 = class _PropertiesArray extends Array {
  constructor(itemFactory, ...properties) {
    super(properties.length);
    Object.defineProperty(this, "itemFactory", { value: itemFactory, enumerable: false, configurable: false });
    this.set(properties);
  }
  set(properties) {
    if (isArray$1(properties)) {
      this.length = properties.length;
      for (let i = 0; i < properties.length; i++) {
        this[i] = new this.itemFactory().set(properties[i]);
      }
    }
    return this;
  }
  reset(properties) {
    return new _PropertiesArray(this.itemFactory, ...properties);
  }
};
function isProperties$1(value) {
  return value instanceof BaseProperties$1 || value instanceof PropertiesArray$1;
}

// packages/ag-charts-community/src/util/json.ts
var CLASS_INSTANCE_TYPE$1 = "class-instance";
function jsonDiff$1(source, target) {
  if (isArray$1(target)) {
    if (!isArray$1(source) || source.length !== target.length || target.some((v, i) => jsonDiff$1(source[i], v) != null)) {
      return target;
    }
  } else if (isObject$1(target)) {
    if (!isObject$1(source) || !isPlainObject$1(target)) {
      return target;
    }
    const result = {};
    const allKeys = /* @__PURE__ */ new Set([
      ...Object.keys(source),
      ...Object.keys(target)
    ]);
    for (const key of allKeys) {
      if (source[key] === target[key]) {
        continue;
      }
      if (typeof source[key] !== typeof target[key]) {
        result[key] = target[key];
      } else {
        const diff2 = jsonDiff$1(source[key], target[key]);
        if (diff2 !== null) {
          result[key] = diff2;
        }
      }
    }
    return Object.keys(result).length ? result : null;
  } else if (source !== target) {
    return target;
  }
  return null;
}
function jsonClone$1(source) {
  if (isArray$1(source)) {
    return source.map(jsonClone$1);
  }
  if (isPlainObject$1(source)) {
    return Object.entries(source).reduce((result, [key, value]) => {
      result[key] = jsonClone$1(value);
      return result;
    }, {});
  }
  return source;
}
var DELETE$1 = Symbol("<delete-property>");
var NOT_SPECIFIED$1 = Symbol("<unspecified-property>");
function jsonMerge$1(json, opts) {
  var _a;
  const avoidDeepClone = (_a = opts == null ? void 0 : opts.avoidDeepClone) != null ? _a : [];
  const jsonTypes = json.map((v) => classify$1(v));
  if (jsonTypes.some((v) => v === "array")) {
    const finalValue = json[json.length - 1];
    if (Array.isArray(finalValue)) {
      return finalValue.map((v) => {
        const type = classify$1(v);
        if (type === "array")
          return jsonMerge$1([[], v], opts);
        if (type === "object")
          return jsonMerge$1([{}, v], opts);
        return v;
      });
    }
    return finalValue;
  }
  const result = {};
  const props = new Set(json.map((v) => v != null ? Object.keys(v) : []).reduce((r, n) => r.concat(n), []));
  for (const nextProp of props) {
    const values = json.map((j) => {
      if (j != null && typeof j === "object" && nextProp in j) {
        return j[nextProp];
      }
      return NOT_SPECIFIED$1;
    }).filter((v) => v !== NOT_SPECIFIED$1);
    if (values.length === 0) {
      continue;
    }
    const lastValue = values[values.length - 1];
    if (lastValue === DELETE$1) {
      continue;
    }
    const types = values.map((v) => classify$1(v));
    const type = types[0];
    if (types.some((t) => t !== type)) {
      result[nextProp] = lastValue;
      continue;
    }
    if ((type === "array" || type === "object") && !avoidDeepClone.includes(nextProp)) {
      result[nextProp] = jsonMerge$1(values, opts);
    } else if (type === "array") {
      result[nextProp] = [...lastValue];
    } else {
      result[nextProp] = lastValue;
    }
  }
  return result;
}
function jsonApply$1(target, source, params = {}) {
  var _a, _b, _c;
  const {
    path,
    matcherPath = path ? path.replace(/(\[[0-9+]+])/i, "[]") : void 0,
    skip = [],
    constructors = {},
    constructedArrays = /* @__PURE__ */ new WeakMap(),
    allowedTypes = {},
    idx
  } = params;
  if (target == null) {
    throw new Error(`AG Charts - target is uninitialised: ${path != null ? path : "<root>"}`);
  }
  if (source == null) {
    return target;
  }
  if (isProperties$1(target)) {
    return target.set(source);
  }
  const targetAny = target;
  if (idx != null && "_declarationOrder" in targetAny) {
    targetAny["_declarationOrder"] = idx;
  }
  const targetType = classify$1(target);
  for (const property in source) {
    const propertyMatcherPath = `${matcherPath ? matcherPath + "." : ""}${property}`;
    if (skip.indexOf(propertyMatcherPath) >= 0) {
      continue;
    }
    const newValue = source[property];
    const propertyPath = `${path ? path + "." : ""}${property}`;
    const targetClass = targetAny.constructor;
    const currentValue = targetAny[property];
    let ctr = (_a = constructors[propertyMatcherPath]) != null ? _a : constructors[property];
    try {
      const currentValueType = classify$1(currentValue);
      const newValueType = classify$1(newValue);
      if (targetType === CLASS_INSTANCE_TYPE$1 && !(property in target || Object.hasOwn(targetAny, property))) {
        Logger$1.warn(`unable to set [${propertyPath}] in ${targetClass == null ? void 0 : targetClass.name} - property is unknown`);
        continue;
      }
      const allowableTypes = (_b = allowedTypes[propertyMatcherPath]) != null ? _b : [currentValueType];
      if (currentValueType === CLASS_INSTANCE_TYPE$1 && newValueType === "object") {
      } else if (currentValueType != null && newValueType != null && !allowableTypes.includes(newValueType)) {
        Logger$1.warn(
          `unable to set [${propertyPath}] in ${targetClass == null ? void 0 : targetClass.name} - can't apply type of [${newValueType}], allowed types are: [${allowableTypes}]`
        );
        continue;
      }
      if (newValueType === "array") {
        ctr = (_c = ctr != null ? ctr : constructedArrays.get(currentValue)) != null ? _c : constructors[`${propertyMatcherPath}[]`];
        if (isProperties$1(targetAny[property])) {
          targetAny[property].set(newValue);
        } else if (ctr != null) {
          const newValueArray = newValue;
          targetAny[property] = newValueArray.map(
            (v, idx2) => jsonApply$1(new ctr(), v, __spreadProps$1(__spreadValues$1({}, params), {
              path: propertyPath,
              matcherPath: propertyMatcherPath + "[]",
              idx: idx2
            }))
          );
        } else {
          targetAny[property] = newValue;
        }
      } else if (newValueType === CLASS_INSTANCE_TYPE$1) {
        targetAny[property] = newValue;
      } else if (newValueType === "object") {
        if (currentValue != null) {
          jsonApply$1(currentValue, newValue, __spreadProps$1(__spreadValues$1({}, params), {
            path: propertyPath,
            matcherPath: propertyMatcherPath,
            idx: void 0
          }));
        } else if (isProperties$1(targetAny[property])) {
          targetAny[property].set(newValue);
        } else if (ctr != null) {
          const obj = new ctr();
          if (isProperties$1(obj)) {
            targetAny[property] = obj.set(newValue);
          } else {
            targetAny[property] = jsonApply$1(obj, newValue, __spreadProps$1(__spreadValues$1({}, params), {
              path: propertyPath,
              matcherPath: propertyMatcherPath,
              idx: void 0
            }));
          }
        } else {
          targetAny[property] = newValue;
        }
      } else if (isProperties$1(targetAny[property])) {
        targetAny[property].set(newValue);
      } else {
        targetAny[property] = newValue;
      }
    } catch (error) {
      Logger$1.warn(`unable to set [${propertyPath}] in [${targetClass == null ? void 0 : targetClass.name}]; nested error is: ${error.message}`);
    }
  }
  return target;
}
function jsonWalk$1(json, visit, opts, ...jsons) {
  var _a;
  if (isArray$1(json)) {
    visit(json, ...jsons);
    json.forEach((node, index) => {
      jsonWalk$1(node, visit, opts, ...keyMapper$1(jsons, index));
    });
  } else if (isPlainObject$1(json)) {
    visit(json, ...jsons);
    for (const key of Object.keys(json)) {
      if ((_a = opts == null ? void 0 : opts.skip) == null ? void 0 : _a.includes(key)) {
        continue;
      }
      const value = json[key];
      if (isArray$1(value) || isPlainObject$1(value)) {
        jsonWalk$1(value, visit, opts, ...keyMapper$1(jsons, key));
      }
    }
  }
}
function keyMapper$1(data, key) {
  return data.map((dataObject) => dataObject == null ? void 0 : dataObject[key]);
}
function classify$1(value) {
  if (value == null) {
    return null;
  }
  if (isHtmlElement$1(value) || isDate$2(value)) {
    return "primitive";
  }
  if (isArray$1(value)) {
    return "array";
  }
  if (isObject$1(value)) {
    return isPlainObject$1(value) ? "object" : CLASS_INSTANCE_TYPE$1;
  }
  if (isFunction$1(value)) {
    return "function";
  }
  return "primitive";
}

// packages/ag-charts-community/src/util/angle.ts
var twoPi$1 = Math.PI * 2;
function normalizeAngle360$1(radians) {
  radians %= twoPi$1;
  radians += twoPi$1;
  radians %= twoPi$1;
  return radians;
}
function normalizeAngle360Inclusive$1(radians) {
  radians %= twoPi$1;
  radians += twoPi$1;
  if (radians !== twoPi$1) {
    radians %= twoPi$1;
  }
  return radians;
}
function normalizeAngle180$1(radians) {
  radians %= twoPi$1;
  if (radians < -Math.PI) {
    radians += twoPi$1;
  } else if (radians >= Math.PI) {
    radians -= twoPi$1;
  }
  return radians;
}
function toRadians$2(degrees) {
  return degrees / 180 * Math.PI;
}
function toDegrees$1(radians) {
  return radians / Math.PI * 180;
}
function angleBetween$1(angle0, angle1) {
  angle0 = normalizeAngle360$1(angle0);
  angle1 = normalizeAngle360$1(angle1);
  return angle1 - angle0 + (angle0 > angle1 ? 2 * Math.PI : 0);
}

// packages/ag-charts-community/src/scale/invalidating.ts
var Invalidating$1 = (target, propertyKey) => {
  const mappedProperty = Symbol(String(propertyKey));
  target[mappedProperty] = void 0;
  Object.defineProperty(target, propertyKey, {
    get() {
      return this[mappedProperty];
    },
    set(newValue) {
      const oldValue = this[mappedProperty];
      if (oldValue !== newValue) {
        this[mappedProperty] = newValue;
        this.invalid = true;
      }
    },
    enumerable: true,
    configurable: false
  });
};

// packages/ag-charts-community/src/scale/bandScale.ts
function clamp$1(x, min, max) {
  return Math.max(min, Math.min(max, x));
}
var BandScale$6 = class {
  constructor() {
    this.type = "band";
    this.invalid = true;
    this.interval = 1;
    /**
     * Maps datum to its index in the {@link domain} array.
     * Used to check for duplicate datums (not allowed).
     */
    this.index = /* @__PURE__ */ new Map();
    /**
     * The output range values for datum at each index.
     */
    this.ordinalRange = [];
    /**
     * Contains unique datums only. Since `{}` is used in place of `Map`
     * for IE11 compatibility, the datums are converted `toString` before
     * the uniqueness check.
     */
    this._domain = [];
    this.range = [0, 1];
    this._bandwidth = 1;
    this._step = 1;
    this._rawBandwidth = 1;
    /**
     * The ratio of the range that is reserved for space between bands.
     */
    this._paddingInner = 0;
    /**
     * The ratio of the range that is reserved for space before the first
     * and after the last band.
     */
    this._paddingOuter = 0;
    this.round = false;
  }
  refresh() {
    if (!this.invalid)
      return;
    this.invalid = false;
    this.update();
    if (this.invalid) {
      Logger$1.warnOnce("Expected update to not invalidate scale");
    }
  }
  set domain(values) {
    this.invalid = true;
    const domain = [];
    this.index = /* @__PURE__ */ new Map();
    const index = this.index;
    values.forEach((value) => {
      if (index.get(value) === void 0) {
        index.set(value, domain.push(value) - 1);
      }
    });
    this._domain = domain;
  }
  get domain() {
    return this._domain;
  }
  ticks() {
    this.refresh();
    const { interval = 1 } = this;
    const step = Math.abs(Math.round(interval));
    return this._domain.filter((_, i) => i % step === 0);
  }
  convert(d) {
    this.refresh();
    const i = this.index.get(d);
    if (i === void 0) {
      return NaN;
    }
    const r = this.ordinalRange[i];
    if (r === void 0) {
      return NaN;
    }
    return r;
  }
  invert(position) {
    this.refresh();
    const index = this.ordinalRange.findIndex((p) => p === position);
    return this.domain[index];
  }
  get bandwidth() {
    this.refresh();
    return this._bandwidth;
  }
  get step() {
    this.refresh();
    return this._step;
  }
  get rawBandwidth() {
    this.refresh();
    return this._rawBandwidth;
  }
  set padding(value) {
    value = clamp$1(value, 0, 1);
    this._paddingInner = value;
    this._paddingOuter = value;
  }
  get padding() {
    return this._paddingInner;
  }
  set paddingInner(value) {
    this._paddingInner = clamp$1(value, 0, 1);
  }
  get paddingInner() {
    return this._paddingInner;
  }
  set paddingOuter(value) {
    this._paddingOuter = clamp$1(value, 0, 1);
  }
  get paddingOuter() {
    return this._paddingOuter;
  }
  update() {
    const count2 = this._domain.length;
    if (count2 === 0) {
      return;
    }
    const round3 = this.round;
    const paddingInner = this._paddingInner;
    const paddingOuter = this._paddingOuter;
    const [r0, r1] = this.range;
    const width = r1 - r0;
    const rawStep = width / Math.max(1, count2 + 2 * paddingOuter - paddingInner);
    const step = round3 ? Math.floor(rawStep) : rawStep;
    const fullBandWidth = step * (count2 - paddingInner);
    const x0 = r0 + (width - fullBandWidth) / 2;
    const start = round3 ? Math.round(x0) : x0;
    const bw = step * (1 - paddingInner);
    const bandwidth = round3 ? Math.round(bw) : bw;
    const rawBandwidth = rawStep * (1 - paddingInner);
    const values = [];
    for (let i = 0; i < count2; i++) {
      values.push(start + step * i);
    }
    this._bandwidth = bandwidth;
    this._rawBandwidth = rawBandwidth;
    this._step = step;
    this.ordinalRange = values;
  }
};
__decorateClass$1([
  Invalidating$1
], BandScale$6.prototype, "interval", 2);
__decorateClass$1([
  Invalidating$1
], BandScale$6.prototype, "range", 2);
__decorateClass$1([
  Invalidating$1
], BandScale$6.prototype, "round", 2);

// packages/ag-charts-community/src/util/number.ts
function clamp2$1(min, value, max) {
  return Math.min(max, Math.max(min, value));
}
function clampArray$1(value, array) {
  return clamp2$1(Math.min(...array), value, Math.max(...array));
}
function isEqual$1(a, b, epsilon2 = 1e-10) {
  return Math.abs(a - b) < epsilon2;
}
function isNegative$1(a) {
  return Math.sign(a) < 0 || Object.is(a, -0);
}
function isReal$1(a) {
  return isFinite(a) && !isNaN(a);
}
function round$1(value, decimals = 2) {
  const pow = Math.pow(10, decimals);
  return Math.round(value * pow) / pow;
}
function toFixed$1(value, fractionOrSignificantDigits = 2) {
  const power = Math.floor(Math.log(Math.abs(value)) / Math.LN10);
  if (power >= 0 || !isFinite(power)) {
    return value.toFixed(fractionOrSignificantDigits);
  }
  return value.toFixed(Math.abs(power) - 1 + fractionOrSignificantDigits);
}
function toReal$1(value) {
  return isReal$1(value) ? value : 0;
}
function mod$1(n, m) {
  if (n >= 0) {
    return Math.floor(n % m);
  }
  return Math.floor(n % m + m);
}
var countFractionDigits$1 = (value, maxFractionDigits = 10) => {
  const decimal = (Math.abs(value) % 1).toFixed(maxFractionDigits);
  for (let i = decimal.length - 1; i >= 2; i -= 1) {
    if (decimal[i] !== "0") {
      return maxFractionDigits - (decimal.length - 1 - i);
    }
  }
  return 0;
};

// packages/ag-charts-community/src/util/color.ts
var srgbToLinear$1 = (value) => {
  const sign = value < 0 ? -1 : 1;
  const abs = Math.abs(value);
  if (abs <= 0.04045)
    return value / 12.92;
  return sign * __pow$1((abs + 0.055) / 1.055, 2.4);
};
var srgbFromLinear$1 = (value) => {
  const sign = value < 0 ? -1 : 1;
  const abs = Math.abs(value);
  if (abs > 31308e-7) {
    return sign * (1.055 * __pow$1(abs, 1 / 2.4) - 0.055);
  }
  return 12.92 * value;
};
var _Color$1 = class _Color {
  /**
   * Every color component should be in the [0, 1] range.
   * Some easing functions (such as elastic easing) can overshoot the target value by some amount.
   * So, when animating colors, if the source or target color components are already near
   * or at the edge of the allowed [0, 1] range, it is possible for the intermediate color
   * component value to end up outside of that range mid-animation. For this reason the constructor
   * performs range checking/constraining.
   * @param r Red component.
   * @param g Green component.
   * @param b Blue component.
   * @param a Alpha (opacity) component.
   */
  constructor(r, g, b, a = 1) {
    this.r = clamp2$1(0, r || 0, 1);
    this.g = clamp2$1(0, g || 0, 1);
    this.b = clamp2$1(0, b || 0, 1);
    this.a = clamp2$1(0, a || 0, 1);
  }
  /**
   * A color string can be in one of the following formats to be valid:
   * - #rgb
   * - #rrggbb
   * - rgb(r, g, b)
   * - rgba(r, g, b, a)
   * - CSS color name such as 'white', 'orange', 'cyan', etc.
   */
  static validColorString(str) {
    if (str.indexOf("#") >= 0) {
      return !!_Color.parseHex(str);
    }
    if (str.indexOf("rgb") >= 0) {
      return !!_Color.stringToRgba(str);
    }
    return !!_Color.nameToHex[str.toLowerCase()];
  }
  /**
   * The given string can be in one of the following formats:
   * - #rgb
   * - #rrggbb
   * - rgb(r, g, b)
   * - rgba(r, g, b, a)
   * - CSS color name such as 'white', 'orange', 'cyan', etc.
   * @param str
   */
  static fromString(str) {
    if (str.indexOf("#") >= 0) {
      return _Color.fromHexString(str);
    }
    const hex = _Color.nameToHex[str.toLowerCase()];
    if (hex) {
      return _Color.fromHexString(hex);
    }
    if (str.indexOf("rgb") >= 0) {
      return _Color.fromRgbaString(str);
    }
    throw new Error(`Invalid color string: '${str}'`);
  }
  static tryParseFromString(str) {
    try {
      return _Color.fromString(str);
    } catch (e) {
      Logger$1.warnOnce(`invalid color string: '${str}'.`);
      return _Color.fromArray([0, 0, 0]);
    }
  }
  // See https://drafts.csswg.org/css-color/#hex-notation
  static parseHex(input) {
    input = input.replace(/ /g, "").slice(1);
    let parts;
    switch (input.length) {
      case 6:
      case 8:
        parts = [];
        for (let i = 0; i < input.length; i += 2) {
          parts.push(parseInt(`${input[i]}${input[i + 1]}`, 16));
        }
        break;
      case 3:
      case 4:
        parts = input.split("").map((p) => parseInt(p, 16)).map((p) => p + p * 16);
        break;
    }
    if ((parts == null ? void 0 : parts.length) >= 3 && parts.every((p) => p >= 0)) {
      if (parts.length === 3) {
        parts.push(255);
      }
      return parts;
    }
  }
  static fromHexString(str) {
    const values = _Color.parseHex(str);
    if (values) {
      const [r, g, b, a] = values;
      return new _Color(r / 255, g / 255, b / 255, a / 255);
    }
    throw new Error(`Malformed hexadecimal color string: '${str}'`);
  }
  static stringToRgba(str) {
    let [po, pc] = [NaN, NaN];
    for (let i = 0; i < str.length; i++) {
      const c = str[i];
      if (!po && c === "(") {
        po = i;
      } else if (c === ")") {
        pc = i;
        break;
      }
    }
    const contents = po && pc && str.substring(po + 1, pc);
    if (!contents) {
      return;
    }
    const parts = contents.split(",");
    const rgba = [];
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      let value = parseFloat(part);
      if (isNaN(value)) {
        return;
      }
      if (part.indexOf("%") >= 0) {
        value = Math.max(0, Math.min(100, value));
        value /= 100;
      } else {
        if (i === 3) {
          value = Math.max(0, Math.min(1, value));
        } else {
          value = Math.max(0, Math.min(255, value));
          value /= 255;
        }
      }
      rgba.push(value);
    }
    return rgba;
  }
  static fromRgbaString(str) {
    const rgba = _Color.stringToRgba(str);
    if (rgba) {
      if (rgba.length === 3) {
        return new _Color(rgba[0], rgba[1], rgba[2]);
      } else if (rgba.length === 4) {
        return new _Color(rgba[0], rgba[1], rgba[2], rgba[3]);
      }
    }
    throw new Error(`Malformed rgb/rgba color string: '${str}'`);
  }
  static fromArray(arr) {
    if (arr.length === 4) {
      return new _Color(arr[0], arr[1], arr[2], arr[3]);
    }
    if (arr.length === 3) {
      return new _Color(arr[0], arr[1], arr[2]);
    }
    throw new Error("The given array should contain 3 or 4 color components (numbers).");
  }
  static fromHSB(h, s, b, alpha = 1) {
    const rgb = _Color.HSBtoRGB(h, s, b);
    return new _Color(rgb[0], rgb[1], rgb[2], alpha);
  }
  static fromHSL(h, s, l, alpha = 1) {
    const rgb = _Color.HSLtoRGB(h, s, l);
    return new _Color(rgb[0], rgb[1], rgb[2], alpha);
  }
  static fromOKLCH(l, c, h, alpha = 1) {
    const rgb = _Color.OKLCHtoRGB(l, c, h);
    return new _Color(rgb[0], rgb[1], rgb[2], alpha);
  }
  static padHex(str) {
    return str.length === 1 ? "0" + str : str;
  }
  toHexString() {
    let hex = "#" + _Color.padHex(Math.round(this.r * 255).toString(16)) + _Color.padHex(Math.round(this.g * 255).toString(16)) + _Color.padHex(Math.round(this.b * 255).toString(16));
    if (this.a < 1) {
      hex += _Color.padHex(Math.round(this.a * 255).toString(16));
    }
    return hex;
  }
  toRgbaString(fractionDigits = 3) {
    const components = [Math.round(this.r * 255), Math.round(this.g * 255), Math.round(this.b * 255)];
    const k = Math.pow(10, fractionDigits);
    if (this.a !== 1) {
      components.push(Math.round(this.a * k) / k);
      return `rgba(${components.join(", ")})`;
    }
    return `rgb(${components.join(", ")})`;
  }
  toString() {
    if (this.a === 1) {
      return this.toHexString();
    }
    return this.toRgbaString();
  }
  toHSB() {
    return _Color.RGBtoHSB(this.r, this.g, this.b);
  }
  static RGBtoOKLCH(r, g, b) {
    const LSRGB0 = srgbToLinear$1(r);
    const LSRGB1 = srgbToLinear$1(g);
    const LSRGB2 = srgbToLinear$1(b);
    const LMS0 = Math.cbrt(0.4122214708 * LSRGB0 + 0.5363325363 * LSRGB1 + 0.0514459929 * LSRGB2);
    const LMS1 = Math.cbrt(0.2119034982 * LSRGB0 + 0.6806995451 * LSRGB1 + 0.1073969566 * LSRGB2);
    const LMS2 = Math.cbrt(0.0883024619 * LSRGB0 + 0.2817188376 * LSRGB1 + 0.6299787005 * LSRGB2);
    const OKLAB0 = 0.2104542553 * LMS0 + 0.793617785 * LMS1 - 0.0040720468 * LMS2;
    const OKLAB1 = 1.9779984951 * LMS0 - 2.428592205 * LMS1 + 0.4505937099 * LMS2;
    const OKLAB2 = 0.0259040371 * LMS0 + 0.7827717662 * LMS1 - 0.808675766 * LMS2;
    const hue = Math.atan2(OKLAB2, OKLAB1) * 180 / Math.PI;
    const OKLCH0 = OKLAB0;
    const OKLCH1 = Math.hypot(OKLAB1, OKLAB2);
    const OKLCH2 = hue >= 0 ? hue : hue + 360;
    return [OKLCH0, OKLCH1, OKLCH2];
  }
  static OKLCHtoRGB(l, c, h) {
    const OKLAB0 = l;
    const OKLAB1 = c * Math.cos(h * Math.PI / 180);
    const OKLAB2 = c * Math.sin(h * Math.PI / 180);
    const LMS0 = __pow$1(OKLAB0 + 0.3963377774 * OKLAB1 + 0.2158037573 * OKLAB2, 3);
    const LMS1 = __pow$1(OKLAB0 - 0.1055613458 * OKLAB1 - 0.0638541728 * OKLAB2, 3);
    const LMS2 = __pow$1(OKLAB0 - 0.0894841775 * OKLAB1 - 1.291485548 * OKLAB2, 3);
    const LSRGB0 = 4.0767416621 * LMS0 - 3.3077115913 * LMS1 + 0.2309699292 * LMS2;
    const LSRGB1 = -1.2684380046 * LMS0 + 2.6097574011 * LMS1 - 0.3413193965 * LMS2;
    const LSRGB2 = -0.0041960863 * LMS0 - 0.7034186147 * LMS1 + 1.707614701 * LMS2;
    const SRGB0 = srgbFromLinear$1(LSRGB0);
    const SRGB1 = srgbFromLinear$1(LSRGB1);
    const SRGB2 = srgbFromLinear$1(LSRGB2);
    return [SRGB0, SRGB1, SRGB2];
  }
  static RGBtoHSL(r, g, b) {
    const min = Math.min(r, g, b);
    const max = Math.max(r, g, b);
    const l = (max + min) / 2;
    let h;
    let s;
    if (max === min) {
      h = NaN;
      s = 0;
    } else {
      const delta = max - min;
      s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);
      if (max === r) {
        h = (g - b) / delta + (g < b ? 6 : 0);
      } else if (max === g) {
        h = (b - r) / delta + 2;
      } else {
        h = (r - g) / delta + 4;
      }
      h *= 360 / 6;
    }
    return [h, s, l];
  }
  static HSLtoRGB(h, s, l) {
    if (s === 0) {
      return [l, l, l];
    }
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    function hueToRgb(t) {
      if (t < 0)
        t += 1;
      if (t > 1)
        t -= 1;
      if (t < 1 / 6)
        return p + (q - p) * 6 * t;
      if (t < 1 / 2)
        return q;
      if (t < 2 / 3)
        return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    }
    const r = hueToRgb(h / 360 + 1 / 3);
    const g = hueToRgb(h / 360);
    const b = hueToRgb(h / 360 - 1 / 3);
    return [r, g, b];
  }
  /**
   * Converts the given RGB triple to an array of HSB (HSV) components.
   * The hue component will be `NaN` for achromatic colors.
   */
  static RGBtoHSB(r, g, b) {
    const min = Math.min(r, g, b);
    const max = Math.max(r, g, b);
    const S = max !== 0 ? (max - min) / max : 0;
    let H = NaN;
    if (min !== max) {
      const delta = max - min;
      const rc = (max - r) / delta;
      const gc = (max - g) / delta;
      const bc = (max - b) / delta;
      if (r === max) {
        H = bc - gc;
      } else if (g === max) {
        H = 2 + rc - bc;
      } else {
        H = 4 + gc - rc;
      }
      H /= 6;
      if (H < 0) {
        H = H + 1;
      }
    }
    return [H * 360, S, max];
  }
  /**
   * Converts the given HSB (HSV) triple to an array of RGB components.
   */
  static HSBtoRGB(H, S, B) {
    if (isNaN(H)) {
      H = 0;
    }
    H = (H % 360 + 360) % 360 / 360;
    let r = 0;
    let g = 0;
    let b = 0;
    if (S === 0) {
      r = g = b = B;
    } else {
      const h = (H - Math.floor(H)) * 6;
      const f = h - Math.floor(h);
      const p = B * (1 - S);
      const q = B * (1 - S * f);
      const t = B * (1 - S * (1 - f));
      switch (h >> 0) {
        case 0:
          r = B;
          g = t;
          b = p;
          break;
        case 1:
          r = q;
          g = B;
          b = p;
          break;
        case 2:
          r = p;
          g = B;
          b = t;
          break;
        case 3:
          r = p;
          g = q;
          b = B;
          break;
        case 4:
          r = t;
          g = p;
          b = B;
          break;
        case 5:
          r = B;
          g = p;
          b = q;
          break;
      }
    }
    return [r, g, b];
  }
  derive(hueShift, saturationFactor, brightnessFactor, opacityFactor) {
    const hsb = _Color.RGBtoHSB(this.r, this.g, this.b);
    let b = hsb[2];
    if (b == 0 && brightnessFactor > 1) {
      b = 0.05;
    }
    const h = ((hsb[0] + hueShift) % 360 + 360) % 360;
    const s = Math.max(Math.min(hsb[1] * saturationFactor, 1), 0);
    b = Math.max(Math.min(b * brightnessFactor, 1), 0);
    const a = Math.max(Math.min(this.a * opacityFactor, 1), 0);
    const rgba = _Color.HSBtoRGB(h, s, b);
    rgba.push(a);
    return _Color.fromArray(rgba);
  }
  brighter() {
    return this.derive(0, 1, 1 / 0.7, 1);
  }
  darker() {
    return this.derive(0, 1, 0.7, 1);
  }
  static interpolate(color, other) {
    const c0 = _Color.tryParseFromString(color);
    const c1 = _Color.tryParseFromString(other);
    return (t) => {
      const i = (x, y) => x * (1 - t) + y * t;
      const c = new _Color(i(c0.r, c1.r), i(c0.g, c1.g), i(c0.b, c1.b), i(c0.a, c1.a));
      return c.toString();
    };
  }
};
_Color$1.didDebug = false;
/**
 * CSS Color Module Level 4:
 * https://drafts.csswg.org/css-color/#named-colors
 */
_Color$1.nameToHex = Object.freeze({
  aliceblue: "#F0F8FF",
  antiquewhite: "#FAEBD7",
  aqua: "#00FFFF",
  aquamarine: "#7FFFD4",
  azure: "#F0FFFF",
  beige: "#F5F5DC",
  bisque: "#FFE4C4",
  black: "#000000",
  blanchedalmond: "#FFEBCD",
  blue: "#0000FF",
  blueviolet: "#8A2BE2",
  brown: "#A52A2A",
  burlywood: "#DEB887",
  cadetblue: "#5F9EA0",
  chartreuse: "#7FFF00",
  chocolate: "#D2691E",
  coral: "#FF7F50",
  cornflowerblue: "#6495ED",
  cornsilk: "#FFF8DC",
  crimson: "#DC143C",
  cyan: "#00FFFF",
  darkblue: "#00008B",
  darkcyan: "#008B8B",
  darkgoldenrod: "#B8860B",
  darkgray: "#A9A9A9",
  darkgreen: "#006400",
  darkgrey: "#A9A9A9",
  darkkhaki: "#BDB76B",
  darkmagenta: "#8B008B",
  darkolivegreen: "#556B2F",
  darkorange: "#FF8C00",
  darkorchid: "#9932CC",
  darkred: "#8B0000",
  darksalmon: "#E9967A",
  darkseagreen: "#8FBC8F",
  darkslateblue: "#483D8B",
  darkslategray: "#2F4F4F",
  darkslategrey: "#2F4F4F",
  darkturquoise: "#00CED1",
  darkviolet: "#9400D3",
  deeppink: "#FF1493",
  deepskyblue: "#00BFFF",
  dimgray: "#696969",
  dimgrey: "#696969",
  dodgerblue: "#1E90FF",
  firebrick: "#B22222",
  floralwhite: "#FFFAF0",
  forestgreen: "#228B22",
  fuchsia: "#FF00FF",
  gainsboro: "#DCDCDC",
  ghostwhite: "#F8F8FF",
  gold: "#FFD700",
  goldenrod: "#DAA520",
  gray: "#808080",
  green: "#008000",
  greenyellow: "#ADFF2F",
  grey: "#808080",
  honeydew: "#F0FFF0",
  hotpink: "#FF69B4",
  indianred: "#CD5C5C",
  indigo: "#4B0082",
  ivory: "#FFFFF0",
  khaki: "#F0E68C",
  lavender: "#E6E6FA",
  lavenderblush: "#FFF0F5",
  lawngreen: "#7CFC00",
  lemonchiffon: "#FFFACD",
  lightblue: "#ADD8E6",
  lightcoral: "#F08080",
  lightcyan: "#E0FFFF",
  lightgoldenrodyellow: "#FAFAD2",
  lightgray: "#D3D3D3",
  lightgreen: "#90EE90",
  lightgrey: "#D3D3D3",
  lightpink: "#FFB6C1",
  lightsalmon: "#FFA07A",
  lightseagreen: "#20B2AA",
  lightskyblue: "#87CEFA",
  lightslategray: "#778899",
  lightslategrey: "#778899",
  lightsteelblue: "#B0C4DE",
  lightyellow: "#FFFFE0",
  lime: "#00FF00",
  limegreen: "#32CD32",
  linen: "#FAF0E6",
  magenta: "#FF00FF",
  maroon: "#800000",
  mediumaquamarine: "#66CDAA",
  mediumblue: "#0000CD",
  mediumorchid: "#BA55D3",
  mediumpurple: "#9370DB",
  mediumseagreen: "#3CB371",
  mediumslateblue: "#7B68EE",
  mediumspringgreen: "#00FA9A",
  mediumturquoise: "#48D1CC",
  mediumvioletred: "#C71585",
  midnightblue: "#191970",
  mintcream: "#F5FFFA",
  mistyrose: "#FFE4E1",
  moccasin: "#FFE4B5",
  navajowhite: "#FFDEAD",
  navy: "#000080",
  oldlace: "#FDF5E6",
  olive: "#808000",
  olivedrab: "#6B8E23",
  orange: "#FFA500",
  orangered: "#FF4500",
  orchid: "#DA70D6",
  palegoldenrod: "#EEE8AA",
  palegreen: "#98FB98",
  paleturquoise: "#AFEEEE",
  palevioletred: "#DB7093",
  papayawhip: "#FFEFD5",
  peachpuff: "#FFDAB9",
  peru: "#CD853F",
  pink: "#FFC0CB",
  plum: "#DDA0DD",
  powderblue: "#B0E0E6",
  purple: "#800080",
  rebeccapurple: "#663399",
  red: "#FF0000",
  rosybrown: "#BC8F8F",
  royalblue: "#4169E1",
  saddlebrown: "#8B4513",
  salmon: "#FA8072",
  sandybrown: "#F4A460",
  seagreen: "#2E8B57",
  seashell: "#FFF5EE",
  sienna: "#A0522D",
  silver: "#C0C0C0",
  skyblue: "#87CEEB",
  slateblue: "#6A5ACD",
  slategray: "#708090",
  slategrey: "#708090",
  snow: "#FFFAFA",
  springgreen: "#00FF7F",
  steelblue: "#4682B4",
  tan: "#D2B48C",
  teal: "#008080",
  thistle: "#D8BFD8",
  tomato: "#FF6347",
  transparent: "#00000000",
  turquoise: "#40E0D0",
  violet: "#EE82EE",
  wheat: "#F5DEB3",
  white: "#FFFFFF",
  whitesmoke: "#F5F5F5",
  yellow: "#FFFF00",
  yellowgreen: "#9ACD32"
});
var Color$1 = _Color$1;

// packages/ag-charts-community/src/util/validation.ts
function Validate$1(predicate, options = {}) {
  const { optional = false } = options;
  return addTransformToInstanceProperty$1(
    (target, property, value) => {
      var _a;
      const context = __spreadProps$1(__spreadValues$1({}, options), { target, property });
      if (optional && typeof value === "undefined" || predicate(value, context)) {
        if (isProperties$1(target[property]) && !isProperties$1(value)) {
          target[property].set(value);
          return target[property];
        }
        return value;
      }
      const cleanKey = String(property).replace(/^_*/, "");
      const targetName = (_a = target.constructor.className) != null ? _a : target.constructor.name.replace(/Properties$/, "");
      Logger$1.warn(
        `Property [${cleanKey}] of [${targetName}] cannot be set to [${stringify$1(value)}]${predicate.message ? `; expecting ${getPredicateMessage$1(predicate, context)}` : ""}, ignoring.`
      );
      return BREAK_TRANSFORM_CHAIN$1;
    },
    void 0,
    { optional }
  );
}
var AND$1 = (...predicates) => {
  const messages = [];
  return predicateWithMessage$1(
    (value, ctx) => {
      messages.length = 0;
      return predicates.every((predicate) => {
        const isValid = predicate(value, ctx);
        if (!isValid) {
          messages.push(getPredicateMessage$1(predicate, ctx));
        }
        return isValid;
      });
    },
    () => messages.filter(Boolean).join(" AND ")
  );
};
var OR$1 = (...predicates) => predicateWithMessage$1(
  (value, ctx) => predicates.some((predicate) => predicate(value, ctx)),
  (ctx) => predicates.map(getPredicateMessageMapper$1(ctx)).filter(Boolean).join(" OR ")
);
var OBJECT$1 = attachObjectRestrictions$1(
  predicateWithMessage$1(
    (value, ctx) => isProperties$1(value) || isObject$1(value) && isProperties$1(ctx.target[ctx.property]),
    "an object"
  )
);
var BOOLEAN$1 = predicateWithMessage$1(isBoolean$2, "a boolean");
var FUNCTION$1 = predicateWithMessage$1(isFunction$1, "a function");
var STRING$1 = predicateWithMessage$1(isString$3, "a string");
var NUMBER$1 = attachNumberRestrictions$1(predicateWithMessage$1(isFiniteNumber$1, "a number"));
var NAN$1 = predicateWithMessage$1((value) => isNumber$5(value) && isNaN(value), "NaN");
var POSITIVE_NUMBER$1 = NUMBER$1.restrict({ min: 0 });
var RATIO$1 = NUMBER$1.restrict({ min: 0, max: 1 });
var DEGREE$1 = NUMBER$1.restrict({ min: -360, max: 360 });
var NUMBER_OR_NAN$1 = OR$1(NUMBER$1, NAN$1);
var ARRAY$1 = attachArrayRestrictions$1(predicateWithMessage$1(isArray$1, "an array"));
var ARRAY_OF$1 = (predicate, message) => predicateWithMessage$1(
  (value, ctx) => isArray$1(value) && value.every((item) => predicate(item, ctx)),
  (ctx) => {
    var _a;
    const arrayMessage = (_a = getPredicateMessage$1(ARRAY$1, ctx)) != null ? _a : "";
    return message ? `${arrayMessage} of ${message}` : arrayMessage;
  }
);
var isComparable$1 = (value) => isFiniteNumber$1(value) || isValidDate$1(value);
var LESS_THAN$1 = (otherField) => predicateWithMessage$1(
  (v, ctx) => !isComparable$1(v) || !isComparable$1(ctx.target[otherField]) || v < ctx.target[otherField],
  `expected to be less than ${otherField}`
);
var GREATER_THAN$1 = (otherField) => predicateWithMessage$1(
  (v, ctx) => !isComparable$1(v) || !isComparable$1(ctx.target[otherField]) || v > ctx.target[otherField],
  `expected to be greater than ${otherField}`
);
var DATE$1 = predicateWithMessage$1(isValidDate$1, "Date object");
var DATE_OR_DATETIME_MS$1 = OR$1(DATE$1, POSITIVE_NUMBER$1);
var colorMessage$1 = `A color string can be in one of the following formats to be valid: #rgb, #rrggbb, rgb(r, g, b), rgba(r, g, b, a) or a CSS color name such as 'white', 'orange', 'cyan', etc`;
var COLOR_STRING$1 = predicateWithMessage$1(
  (v) => isString$3(v) && Color$1.validColorString(v),
  `color String. ${colorMessage$1}`
);
var COLOR_STRING_ARRAY$1 = predicateWithMessage$1(ARRAY_OF$1(COLOR_STRING$1), `color strings. ${colorMessage$1}`);
var BOOLEAN_ARRAY$1 = ARRAY_OF$1(BOOLEAN$1, "boolean values");
var NUMBER_ARRAY$1 = ARRAY_OF$1(NUMBER$1, "numbers");
var STRING_ARRAY$1 = ARRAY_OF$1(STRING$1, "strings");
var DATE_ARRAY$1 = predicateWithMessage$1(ARRAY_OF$1(DATE$1), "Date objects");
var OBJECT_ARRAY$1 = predicateWithMessage$1(ARRAY_OF$1(OBJECT$1), "objects");
var LINE_CAP$1 = UNION$1(["butt", "round", "square"], "a line cap");
var LINE_JOIN$1 = UNION$1(["round", "bevel", "miter"], "a line join");
var LINE_DASH$1 = predicateWithMessage$1(
  ARRAY_OF$1(POSITIVE_NUMBER$1),
  "numbers specifying the length in pixels of alternating dashes and gaps, for example, [6, 3] means dashes with a length of 6 pixels with gaps between of 3 pixels."
);
var POSITION$1 = UNION$1(["top", "right", "bottom", "left"], "a position");
var FONT_STYLE$1 = UNION$1(["normal", "italic", "oblique"], "a font style");
var FONT_WEIGHT$1 = OR$1(
  UNION$1(["normal", "bold", "bolder", "lighter"], "a font weight"),
  NUMBER$1.restrict({ min: 1, max: 1e3 })
);
var TEXT_WRAP$1 = UNION$1(["never", "always", "hyphenate", "on-space"], "a text wrap strategy");
var TEXT_ALIGN$1 = UNION$1(["left", "center", "right"], "a text align");
var VERTICAL_ALIGN$1 = UNION$1(["top", "middle", "bottom"], "a vertical align");
var OVERFLOW_STRATEGY$1 = UNION$1(["ellipsis", "hide"], "an overflow strategy");
var DIRECTION$1 = UNION$1(["horizontal", "vertical"], "a direction");
var PLACEMENT$1 = UNION$1(["inside", "outside"], "a placement");
var INTERACTION_RANGE$1 = OR$1(UNION$1(["exact", "nearest"], "interaction range"), NUMBER$1);
function UNION$1(options, message = "a") {
  return predicateWithMessage$1(
    (v) => options.includes(v),
    `${message} keyword such as ${joinUnionOptions$1(options)}`
  );
}
var MIN_SPACING$1 = OR$1(AND$1(NUMBER$1.restrict({ min: 1 }), LESS_THAN$1("maxSpacing")), NAN$1);
var MAX_SPACING$1 = OR$1(AND$1(NUMBER$1.restrict({ min: 1 }), GREATER_THAN$1("minSpacing")), NAN$1);
function predicateWithMessage$1(predicate, message) {
  predicate.message = message;
  return predicate;
}
function joinUnionOptions$1(options) {
  const values = options.map((option) => `'${option}'`);
  if (values.length === 1) {
    return values[0];
  }
  const lastValue = values.pop();
  return `${values.join(", ")} or ${lastValue}`;
}
function getPredicateMessage$1(predicate, ctx) {
  return isFunction$1(predicate.message) ? predicate.message(ctx) : predicate.message;
}
function getPredicateMessageMapper$1(ctx) {
  return (predicate) => getPredicateMessage$1(predicate, ctx);
}
function attachArrayRestrictions$1(predicate) {
  return Object.assign(predicate, {
    restrict({ length, minLength } = {}) {
      return predicateWithMessage$1(
        (value) => isArray$1(value) && (isNumber$5(length) ? value.length === length : true) && (isNumber$5(minLength) ? value.length >= minLength : true),
        isNumber$5(minLength) && minLength > 0 ? "a non-empty array" : isNumber$5(length) ? `an array of length ${length}` : "an array"
      );
    }
  });
}
function attachNumberRestrictions$1(predicate) {
  return Object.assign(predicate, {
    restrict({ min, max } = {}) {
      const message = ["a number"];
      const hasMin = isNumber$5(min);
      const hasMax = isNumber$5(max);
      if (hasMin && hasMax) {
        message.push(`between ${min} and ${max} inclusive`);
      } else if (hasMin) {
        message.push(`greater than or equal to ${min}`);
      } else if (hasMax) {
        message.push(`less than or equal to ${max}`);
      }
      return predicateWithMessage$1(
        (value) => isFiniteNumber$1(value) && (hasMin ? value >= min : true) && (hasMax ? value <= max : true),
        message.join(" ")
      );
    }
  });
}
function attachObjectRestrictions$1(predicate) {
  return Object.assign(predicate, {
    restrict(objectType) {
      const isInstanceOf = (value) => isProperties$1(value) && value instanceof objectType;
      return predicateWithMessage$1(
        (value, ctx) => isInstanceOf(value) || isObject$1(value) && isInstanceOf(ctx.target[ctx.property]),
        (ctx) => {
          var _a;
          return (_a = getPredicateMessage$1(predicate, ctx)) != null ? _a : "an object";
        }
      );
    }
  });
}
function stringify$1(value) {
  if (typeof value === "number") {
    if (isNaN(value))
      return "NaN";
    if (value === Infinity)
      return "Infinity";
    if (value === -Infinity)
      return "-Infinity";
  }
  return JSON.stringify(value);
}

// packages/ag-charts-community/src/chart/chartAxisDirection.ts
var ChartAxisDirection$1 = /* @__PURE__ */ ((ChartAxisDirection2) => {
  ChartAxisDirection2["X"] = "x";
  ChartAxisDirection2["Y"] = "y";
  return ChartAxisDirection2;
})(ChartAxisDirection$1 || {});

// packages/ag-charts-community/src/util/id.ts
var ID_MAP$1 = {};
function resetIds$1() {
  for (const key in ID_MAP$1) {
    delete ID_MAP$1[key];
  }
}
function createId$2(instance) {
  var _a;
  const constructor = instance.constructor;
  const className = Object.hasOwn(constructor, "className") ? constructor.className : constructor.name;
  if (!className) {
    throw new Error(`The ${constructor} is missing the 'className' property.`);
  }
  const nextId = ((_a = ID_MAP$1[className]) != null ? _a : 0) + 1;
  ID_MAP$1[className] = nextId;
  return className + "-" + nextId;
}

// packages/ag-charts-community/src/scene/nearest.ts
function nearestSquared$1(point, objects, maxDistanceSquared = Infinity) {
  const result = { nearest: void 0, distanceSquared: maxDistanceSquared };
  for (const obj of objects) {
    const thisDistance = obj.distanceSquared(point);
    if (thisDistance === 0) {
      return { nearest: obj, distanceSquared: 0 };
    } else if (thisDistance < result.distanceSquared) {
      result.nearest = obj;
      result.distanceSquared = thisDistance;
    }
  }
  return result;
}
function nearestSquaredInContainer$1(point, container, maxDistanceSquared = Infinity) {
  const tpoint = container.transformPoint(point.x, point.y);
  const result = { nearest: void 0, distanceSquared: maxDistanceSquared };
  for (const child of container.children) {
    const { nearest, distanceSquared } = child.nearestSquared(tpoint, result.distanceSquared);
    if (distanceSquared === 0) {
      return { nearest, distanceSquared };
    } else if (distanceSquared < result.distanceSquared) {
      result.nearest = nearest;
      result.distanceSquared = distanceSquared;
    }
  }
  return result;
}

// packages/ag-charts-community/src/scene/bbox.ts
var _BBox$1 = class _BBox {
  constructor(x, y, width, height) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }
  clone() {
    const { x, y, width, height } = this;
    return new _BBox(x, y, width, height);
  }
  equals(other) {
    return this.x === other.x && this.y === other.y && this.width === other.width && this.height === other.height;
  }
  containsPoint(x, y) {
    return x >= this.x && x <= this.x + this.width && y >= this.y && y <= this.y + this.height;
  }
  collidesBBox(other) {
    return this.x < other.x + other.width && this.x + this.width > other.x && this.y < other.y + other.height && this.y + this.height > other.y;
  }
  isInfinite() {
    return Math.abs(this.x) === Infinity || Math.abs(this.y) === Infinity || Math.abs(this.width) === Infinity || Math.abs(this.height) === Infinity;
  }
  distanceSquared(point) {
    if (this.containsPoint(point.x, point.y)) {
      return 0;
    }
    const dx = point.x - Math.max(this.x, Math.min(point.x, this.x + this.width));
    const dy = point.y - Math.max(this.y, Math.min(point.y, this.y + this.height));
    return dx * dx + dy * dy;
  }
  static nearestBox(point, boxes) {
    return nearestSquared$1(point, boxes);
  }
  shrink(amount, position) {
    const apply = (pos, amt) => {
      switch (pos) {
        case "top":
          this.y += amt;
        case "bottom":
          this.height -= amt;
          break;
        case "left":
          this.x += amt;
        case "right":
          this.width -= amt;
          break;
        case "vertical":
          this.y += amt;
          this.height -= amt * 2;
          break;
        case "horizontal":
          this.x += amt;
          this.width -= amt * 2;
          break;
        case void 0:
          this.x += amt;
          this.width -= amt * 2;
          this.y += amt;
          this.height -= amt * 2;
          break;
      }
    };
    if (typeof amount === "number") {
      apply(position, amount);
    } else if (typeof amount === "object") {
      Object.entries(amount).forEach(([pos, amt]) => apply(pos, amt));
    }
    return this;
  }
  grow(amount, position) {
    if (typeof amount === "number") {
      this.shrink(-amount, position);
    } else {
      const paddingCopy = __spreadValues$1({}, amount);
      for (const key in paddingCopy) {
        paddingCopy[key] *= -1;
      }
      this.shrink(paddingCopy);
    }
    return this;
  }
  static merge(boxes) {
    let left = Infinity;
    let top = Infinity;
    let right = -Infinity;
    let bottom = -Infinity;
    boxes.forEach((box) => {
      if (box.x < left) {
        left = box.x;
      }
      if (box.y < top) {
        top = box.y;
      }
      if (box.x + box.width > right) {
        right = box.x + box.width;
      }
      if (box.y + box.height > bottom) {
        bottom = box.y + box.height;
      }
    });
    return new _BBox(left, top, right - left, bottom - top);
  }
};
_BBox$1.zero = new _BBox$1(0, 0, 0, 0);
var BBox$1 = _BBox$1;

// packages/ag-charts-community/src/scene/matrix.ts
var _Matrix$1 = class _Matrix {
  get e() {
    return [...this.elements];
  }
  constructor(elements = [1, 0, 0, 1, 0, 0]) {
    this.elements = elements;
  }
  setElements(elements) {
    const e = this.elements;
    e[0] = elements[0];
    e[1] = elements[1];
    e[2] = elements[2];
    e[3] = elements[3];
    e[4] = elements[4];
    e[5] = elements[5];
    return this;
  }
  get identity() {
    const e = this.elements;
    return e[0] === 1 && e[1] === 0 && e[2] === 0 && e[3] === 1 && e[4] === 0 && e[5] === 0;
  }
  /**
   * Performs the AxB matrix multiplication and saves the result
   * to `C`, if given, or to `A` otherwise.
   */
  AxB(A, B, C) {
    const a = A[0] * B[0] + A[2] * B[1], b = A[1] * B[0] + A[3] * B[1], c = A[0] * B[2] + A[2] * B[3], d = A[1] * B[2] + A[3] * B[3], e = A[0] * B[4] + A[2] * B[5] + A[4], f = A[1] * B[4] + A[3] * B[5] + A[5];
    C = C != null ? C : A;
    C[0] = a;
    C[1] = b;
    C[2] = c;
    C[3] = d;
    C[4] = e;
    C[5] = f;
  }
  /**
   * The `other` matrix gets post-multiplied to the current matrix.
   * Returns the current matrix.
   * @param other
   */
  multiplySelf(other) {
    this.AxB(this.elements, other.elements);
    return this;
  }
  /**
   * The `other` matrix gets post-multiplied to the current matrix.
   * Returns a new matrix.
   * @param other
   */
  multiply(other) {
    const elements = new Array(6);
    this.AxB(this.elements, other.elements, elements);
    return new _Matrix(elements);
  }
  preMultiplySelf(other) {
    this.AxB(other.elements, this.elements, this.elements);
    return this;
  }
  /**
   * Returns the inverse of this matrix as a new matrix.
   */
  inverse() {
    const el = this.elements;
    let a = el[0], b = el[1], c = el[2], d = el[3];
    const e = el[4], f = el[5];
    const rD = 1 / (a * d - b * c);
    a *= rD;
    b *= rD;
    c *= rD;
    d *= rD;
    return new _Matrix([d, -b, -c, a, c * f - d * e, b * e - a * f]);
  }
  /**
   * Save the inverse of this matrix to the given matrix.
   */
  inverseTo(other) {
    const el = this.elements;
    let a = el[0], b = el[1], c = el[2], d = el[3];
    const e = el[4], f = el[5];
    const rD = 1 / (a * d - b * c);
    a *= rD;
    b *= rD;
    c *= rD;
    d *= rD;
    other.setElements([d, -b, -c, a, c * f - d * e, b * e - a * f]);
    return this;
  }
  invertSelf() {
    const el = this.elements;
    let a = el[0], b = el[1], c = el[2], d = el[3];
    const e = el[4], f = el[5];
    const rD = 1 / (a * d - b * c);
    a *= rD;
    b *= rD;
    c *= rD;
    d *= rD;
    el[0] = d;
    el[1] = -b;
    el[2] = -c;
    el[3] = a;
    el[4] = c * f - d * e;
    el[5] = b * e - a * f;
    return this;
  }
  transformPoint(x, y) {
    const e = this.elements;
    return {
      x: x * e[0] + y * e[2] + e[4],
      y: x * e[1] + y * e[3] + e[5]
    };
  }
  transformBBox(bbox, target) {
    const elements = this.elements;
    const xx = elements[0];
    const xy = elements[1];
    const yx = elements[2];
    const yy = elements[3];
    const h_w = bbox.width * 0.5;
    const h_h = bbox.height * 0.5;
    const cx = bbox.x + h_w;
    const cy = bbox.y + h_h;
    const w = Math.abs(h_w * xx) + Math.abs(h_h * yx);
    const h = Math.abs(h_w * xy) + Math.abs(h_h * yy);
    if (!target) {
      target = new BBox$1(0, 0, 0, 0);
    }
    target.x = cx * xx + cy * yx + elements[4] - w;
    target.y = cx * xy + cy * yy + elements[5] - h;
    target.width = w + w;
    target.height = h + h;
    return target;
  }
  toContext(ctx) {
    if (this.identity) {
      return;
    }
    const e = this.elements;
    ctx.transform(e[0], e[1], e[2], e[3], e[4], e[5]);
  }
  static flyweight(sourceMatrix) {
    return _Matrix.instance.setElements(sourceMatrix.elements);
  }
  static updateTransformMatrix(matrix, scalingX, scalingY, rotation, translationX, translationY, opts) {
    const [bbcx, bbcy] = [0, 0];
    const sx = scalingX;
    const sy = scalingY;
    let scx;
    let scy;
    if (sx === 1 && sy === 1) {
      scx = 0;
      scy = 0;
    } else {
      scx = (opts == null ? void 0 : opts.scalingCenterX) == null ? bbcx : opts == null ? void 0 : opts.scalingCenterX;
      scy = (opts == null ? void 0 : opts.scalingCenterY) == null ? bbcy : opts == null ? void 0 : opts.scalingCenterY;
    }
    const r = rotation;
    const cos = Math.cos(r);
    const sin = Math.sin(r);
    let rcx;
    let rcy;
    if (r === 0) {
      rcx = 0;
      rcy = 0;
    } else {
      rcx = (opts == null ? void 0 : opts.rotationCenterX) == null ? bbcx : opts == null ? void 0 : opts.rotationCenterX;
      rcy = (opts == null ? void 0 : opts.rotationCenterY) == null ? bbcy : opts == null ? void 0 : opts.rotationCenterY;
    }
    const tx = translationX;
    const ty = translationY;
    const tx4 = scx * (1 - sx) - rcx;
    const ty4 = scy * (1 - sy) - rcy;
    matrix.setElements([
      cos * sx,
      sin * sx,
      -sin * sy,
      cos * sy,
      cos * tx4 - sin * ty4 + rcx + tx,
      sin * tx4 + cos * ty4 + rcy + ty
    ]);
    return matrix;
  }
  static fromContext(ctx) {
    const domMatrix = ctx.getTransform();
    return new _Matrix([domMatrix.a, domMatrix.b, domMatrix.c, domMatrix.d, domMatrix.e, domMatrix.f]);
  }
};
_Matrix$1.instance = new _Matrix$1();
var Matrix$1 = _Matrix$1;

// packages/ag-charts-community/src/scene/node.ts
var PointerEvents$1 = /* @__PURE__ */ ((PointerEvents2) => {
  PointerEvents2[PointerEvents2["All"] = 0] = "All";
  PointerEvents2[PointerEvents2["None"] = 1] = "None";
  return PointerEvents2;
})(PointerEvents$1 || {});
var zIndexChangedCallback$1 = (o) => {
  if (o.parent) {
    o.parent.dirtyZIndex = true;
  }
  o.zIndexChanged();
};
var _Node$1 = class _Node extends ChangeDetectable$1 {
  constructor({ isVirtual, tag } = {}) {
    super();
    /** Unique number to allow creation order to be easily determined. */
    this.serialNumber = _Node._nextSerialNumber++;
    /**
     * Unique node ID in the form `ClassName-NaturalNumber`.
     */
    this.id = createId$2(this);
    /**
     * To simplify the type system (especially in Selections) we don't have the `Parent` node
     * (one that has children). Instead, we mimic HTML DOM, where any node can have children.
     * But we still need to distinguish regular leaf nodes from container leafs somehow.
     */
    this.isContainerNode = false;
    this._virtualChildren = [];
    this._children = [];
    // Used to check for duplicate nodes.
    this.childSet = {};
    // These matrices may need to have package level visibility
    // for performance optimization purposes.
    this.matrix = new Matrix$1();
    this.inverseMatrix = new Matrix$1();
    this.dirtyTransform = false;
    this.scalingX = 1;
    this.scalingY = 1;
    this.scalingCenterX = null;
    this.scalingCenterY = null;
    this.rotationCenterX = null;
    this.rotationCenterY = null;
    this.rotation = 0;
    this.translationX = 0;
    this.translationY = 0;
    this.visible = true;
    this.dirtyZIndex = false;
    this.zIndex = 0;
    /** Discriminators for render order within a zIndex. */
    this.zIndexSubOrder = void 0;
    this.pointerEvents = 0 /* All */;
    this.isVirtual = isVirtual != null ? isVirtual : false;
    this.tag = tag != null ? tag : NaN;
  }
  /**
   * Some arbitrary data bound to the node.
   */
  get datum() {
    var _a, _b;
    return (_b = this._datum) != null ? _b : (_a = this._parent) == null ? void 0 : _a.datum;
  }
  get previousDatum() {
    return this._previousDatum;
  }
  set datum(datum) {
    if (this._datum !== datum)
      this._previousDatum = this._datum;
    this._datum = datum;
  }
  _setLayerManager(value) {
    this._layerManager = value;
    this._debug = value == null ? void 0 : value.debug;
    for (const child of this._children) {
      child._setLayerManager(value);
    }
    for (const child of this._virtualChildren) {
      child._setLayerManager(value);
    }
  }
  get layerManager() {
    return this._layerManager;
  }
  get parent() {
    return this._parent;
  }
  get children() {
    if (this._virtualChildren.length === 0)
      return this._children;
    const result = [...this._children];
    for (const next of this._virtualChildren) {
      result.push(...next.children);
    }
    return result;
  }
  get virtualChildren() {
    return this._virtualChildren;
  }
  hasVirtualChildren() {
    return this._virtualChildren.length > 0;
  }
  // new Set<Node>()
  setProperties(styles, pickKeys) {
    const keys = pickKeys != null ? pickKeys : Object.keys(styles);
    for (const key of keys) {
      this[key] = styles[key];
    }
    return this;
  }
  /**
   * Appends one or more new node instances to this parent.
   * If one needs to:
   * - move a child to the end of the list of children
   * - move a child from one parent to another (including parents in other scenes)
   * one should use the {@link insertBefore} method instead.
   * @param nodes A node or nodes to append.
   */
  append(nodes) {
    if (!Array.isArray(nodes)) {
      nodes = [nodes];
    }
    for (const node of nodes) {
      if (node.parent) {
        throw new Error(`${node} already belongs to another parent: ${node.parent}.`);
      }
      if (node.layerManager) {
        throw new Error(`${node} already belongs to a scene: ${node.layerManager}.`);
      }
      if (this.childSet[node.id]) {
        throw new Error(`Duplicate ${node.constructor.name} node: ${node}`);
      }
      if (node.isVirtual) {
        this._virtualChildren.push(node);
      } else {
        this._children.push(node);
      }
      this.childSet[node.id] = true;
      node._parent = this;
      node._setLayerManager(this.layerManager);
    }
    this.dirtyZIndex = true;
    this.markDirty(this, 3 /* MAJOR */);
  }
  appendChild(node) {
    this.append(node);
    return node;
  }
  removeChild(node) {
    const error = () => {
      throw new Error(`The node to be removed is not a child of this node.`);
    };
    if (node.parent !== this) {
      error();
    }
    if (node.isVirtual) {
      const i = this._virtualChildren.indexOf(node);
      if (i < 0)
        error();
      this._virtualChildren.splice(i, 1);
    } else {
      const i = this._children.indexOf(node);
      if (i < 0)
        error();
      this._children.splice(i, 1);
    }
    delete this.childSet[node.id];
    node._parent = void 0;
    node._setLayerManager();
    this.dirtyZIndex = true;
    this.markDirty(node, 3 /* MAJOR */);
    return node;
  }
  calculateCumulativeMatrix() {
    this.computeTransformMatrix();
    const matrix = Matrix$1.flyweight(this.matrix);
    let parent = this.parent;
    while (parent) {
      parent.computeTransformMatrix();
      matrix.preMultiplySelf(parent.matrix);
      parent = parent.parent;
    }
    return matrix;
  }
  transformPoint(x, y) {
    const matrix = this.calculateCumulativeMatrix();
    return matrix.invertSelf().transformPoint(x, y);
  }
  inverseTransformPoint(x, y) {
    const matrix = this.calculateCumulativeMatrix();
    return matrix.transformPoint(x, y);
  }
  transformBBox(bbox) {
    const matrix = this.calculateCumulativeMatrix();
    return matrix.invertSelf().transformBBox(bbox);
  }
  inverseTransformBBox(bbox) {
    const matrix = this.calculateCumulativeMatrix();
    return matrix.transformBBox(bbox);
  }
  markDirtyTransform() {
    this.dirtyTransform = true;
    this.markDirty(this, 3 /* MAJOR */);
  }
  containsPoint(_x, _y) {
    return false;
  }
  /**
   * Hit testing method.
   * Recursively checks if the given point is inside this node or any of its children.
   * Returns the first matching node or `undefined`.
   * Nodes that render later (show on top) are hit tested first.
   */
  pickNode(x, y) {
    var _a;
    if (!this.visible || this.pointerEvents === 1 /* None */ || !this.containsPoint(x, y)) {
      return;
    }
    const children = this.children;
    if (children.length > 1e3) {
      for (let i = children.length - 1; i >= 0; i--) {
        const child = children[i];
        const containsPoint = (_a = child.computeTransformedBBox()) == null ? void 0 : _a.containsPoint(x, y);
        const hit = containsPoint ? child.pickNode(x, y) : void 0;
        if (hit) {
          return hit;
        }
      }
    } else if (children.length) {
      for (let i = children.length - 1; i >= 0; i--) {
        const hit = children[i].pickNode(x, y);
        if (hit) {
          return hit;
        }
      }
    } else if (!this.isContainerNode) {
      return this;
    }
  }
  findNodes(predicate) {
    const result = predicate(this) ? [this] : [];
    for (const child of this.children) {
      const childResult = child.findNodes(predicate);
      if (childResult) {
        result.push(...childResult);
      }
    }
    return result;
  }
  computeBBox() {
    return;
  }
  computeTransformedBBox() {
    const bbox = this.computeBBox();
    if (!bbox) {
      return void 0;
    }
    this.computeTransformMatrix();
    const matrix = Matrix$1.flyweight(this.matrix);
    let parent = this.parent;
    while (parent) {
      parent.computeTransformMatrix();
      matrix.preMultiplySelf(parent.matrix);
      parent = parent.parent;
    }
    matrix.transformBBox(bbox, bbox);
    return bbox;
  }
  computeTransformMatrix() {
    if (!this.dirtyTransform) {
      return;
    }
    const {
      matrix,
      scalingX,
      scalingY,
      rotation,
      translationX,
      translationY,
      scalingCenterX,
      scalingCenterY,
      rotationCenterX,
      rotationCenterY
    } = this;
    Matrix$1.updateTransformMatrix(matrix, scalingX, scalingY, rotation, translationX, translationY, {
      scalingCenterX,
      scalingCenterY,
      rotationCenterX,
      rotationCenterY
    });
    matrix.inverseTo(this.inverseMatrix);
    this.dirtyTransform = false;
  }
  render(renderCtx) {
    const { stats } = renderCtx;
    this._dirty = 0 /* NONE */;
    if (stats)
      stats.nodesRendered++;
  }
  clearBBox(ctx) {
    const bbox = this.computeBBox();
    if (bbox == null) {
      return;
    }
    const { x, y, width, height } = bbox;
    const topLeft = this.transformPoint(x, y);
    const bottomRight = this.transformPoint(x + width, y + height);
    ctx.clearRect(topLeft.x, topLeft.y, bottomRight.x - topLeft.x, bottomRight.y - topLeft.y);
  }
  markDirty(_source, type = 1 /* TRIVIAL */, parentType = type) {
    if (this._dirty > type) {
      return;
    }
    if (this._dirty === type && type === parentType) {
      return;
    }
    this._dirty = type;
    if (this.parent) {
      this.parent.markDirty(this, parentType);
    } else if (this.layerManager) {
      this.layerManager.markDirty();
    }
  }
  get dirty() {
    return this._dirty;
  }
  markClean(opts) {
    const { force = false, recursive = true } = opts != null ? opts : {};
    if (this._dirty === 0 /* NONE */ && !force) {
      return;
    }
    this._dirty = 0 /* NONE */;
    if (recursive !== false) {
      for (const child of this._virtualChildren) {
        child.markClean({ force });
      }
    }
    if (recursive === true) {
      for (const child of this._children) {
        child.markClean({ force });
      }
    }
  }
  visibilityChanged() {
  }
  get nodeCount() {
    let count2 = 1;
    let dirtyCount = this._dirty >= 0 /* NONE */ || this.dirtyTransform ? 1 : 0;
    let visibleCount = this.visible ? 1 : 0;
    const countChild = (child) => {
      const { count: childCount, visibleCount: childVisibleCount, dirtyCount: childDirtyCount } = child.nodeCount;
      count2 += childCount;
      visibleCount += childVisibleCount;
      dirtyCount += childDirtyCount;
    };
    for (const child of this._children) {
      countChild(child);
    }
    for (const child of this._virtualChildren) {
      countChild(child);
    }
    return { count: count2, visibleCount, dirtyCount };
  }
  zIndexChanged() {
  }
};
_Node$1._nextSerialNumber = 0;
__decorateClass$1([
  SceneChangeDetection$1({ type: "transform" })
], _Node$1.prototype, "scalingX", 2);
__decorateClass$1([
  SceneChangeDetection$1({ type: "transform" })
], _Node$1.prototype, "scalingY", 2);
__decorateClass$1([
  SceneChangeDetection$1({ type: "transform" })
], _Node$1.prototype, "scalingCenterX", 2);
__decorateClass$1([
  SceneChangeDetection$1({ type: "transform" })
], _Node$1.prototype, "scalingCenterY", 2);
__decorateClass$1([
  SceneChangeDetection$1({ type: "transform" })
], _Node$1.prototype, "rotationCenterX", 2);
__decorateClass$1([
  SceneChangeDetection$1({ type: "transform" })
], _Node$1.prototype, "rotationCenterY", 2);
__decorateClass$1([
  SceneChangeDetection$1({ type: "transform" })
], _Node$1.prototype, "rotation", 2);
__decorateClass$1([
  SceneChangeDetection$1({ type: "transform" })
], _Node$1.prototype, "translationX", 2);
__decorateClass$1([
  SceneChangeDetection$1({ type: "transform" })
], _Node$1.prototype, "translationY", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */, changeCb: (o) => o.visibilityChanged() })
], _Node$1.prototype, "visible", 2);
__decorateClass$1([
  SceneChangeDetection$1({
    redraw: 1 /* TRIVIAL */,
    changeCb: zIndexChangedCallback$1
  })
], _Node$1.prototype, "zIndex", 2);
__decorateClass$1([
  SceneChangeDetection$1({
    redraw: 1 /* TRIVIAL */,
    changeCb: zIndexChangedCallback$1
  })
], _Node$1.prototype, "zIndexSubOrder", 2);
var Node$1 = _Node$1;

// packages/ag-charts-community/src/util/userAgent.ts
function hasConstrainedCanvasMemory$1() {
  if (typeof navigator === "undefined")
    return false;
  const iPhoneOSMatch = navigator.userAgent.match(/\(iPhone; CPU iPhone OS (\d+_\d+_\d+) like Mac OS X\)/);
  if (iPhoneOSMatch == null)
    return false;
  const versionString = iPhoneOSMatch[1].split("_");
  const major = Number(versionString[0]);
  if (major > 16) {
    return false;
  } else if (major === 16) {
    const minor = Number(versionString[1]);
    return minor < 6;
  }
  return true;
}

// packages/ag-charts-community/src/scene/canvas/hdpiCanvas.ts
var _HdpiCanvas$1 = class _HdpiCanvas {
  // The width/height attributes of the Canvas element default to
  // 300/150 according to w3.org.
  constructor(opts) {
    this._enabled = true;
    // `NaN` is deliberate here, so that overrides are always applied
    // and the `resetTransform` inside the `resize` method works in IE11.
    this._pixelRatio = NaN;
    this._width = 0;
    this._height = 0;
    const {
      document: document2,
      window: window2,
      width = 600,
      height = 300,
      domLayer = false,
      zIndex = 0,
      name = void 0,
      overrideDevicePixelRatio = void 0
    } = opts;
    this.document = document2;
    this.window = window2;
    _HdpiCanvas.document = document2;
    this.element = document2.createElement("canvas");
    this.element.width = width;
    this.element.height = height;
    this.realContext = this.element.getContext("2d");
    this.imageSource = this.realContext.canvas;
    const { style } = this.element;
    style.userSelect = "none";
    style.display = "block";
    if (domLayer) {
      style.position = "absolute";
      style.zIndex = String(zIndex);
      style.top = "0";
      style.left = "0";
      style.pointerEvents = "none";
      style.opacity = `1`;
      if (name) {
        this.element.id = name;
      }
    }
    this.context = this.setPixelRatio(overrideDevicePixelRatio);
    this.resize(width, height);
  }
  set container(value) {
    if (this._container !== value) {
      this.remove();
      if (value) {
        value.appendChild(this.element);
      }
      this._container = value;
    }
  }
  get container() {
    return this._container;
  }
  set enabled(value) {
    this.element.style.display = value ? "block" : "none";
    this._enabled = !!value;
  }
  get enabled() {
    return this._enabled;
  }
  remove() {
    const { parentNode } = this.element;
    if (parentNode != null) {
      parentNode.removeChild(this.element);
    }
  }
  destroy() {
    this.element.remove();
    this.element.width = 0;
    this.element.height = 0;
    this.context.clearRect(0, 0, 0, 0);
    Object.freeze(this);
  }
  snapshot() {
  }
  clear() {
    this.context.save();
    this.context.resetTransform();
    this.context.clearRect(0, 0, this.width, this.height);
    this.context.restore();
  }
  toImage() {
    const img = this.document.createElement("img");
    img.src = this.getDataURL();
    return img;
  }
  getDataURL(type) {
    return this.element.toDataURL(type);
  }
  /**
   * @param fileName The name of the downloaded file.
   * @param fileFormat The file format, the default is `image/png`
   */
  download(fileName, fileFormat = "image/png") {
    fileName = (fileName != null ? fileName : "").trim() || "image";
    const dataUrl = this.getDataURL(fileFormat);
    const document2 = this.document;
    const a = document2.createElement("a");
    a.href = dataUrl;
    a.download = fileName;
    a.style.display = "none";
    document2.body.appendChild(a);
    a.click();
    document2.body.removeChild(a);
  }
  get pixelRatio() {
    return this._pixelRatio;
  }
  /**
   * Changes the pixel ratio of the Canvas element to the given value,
   * or uses the window.devicePixelRatio (default), then resizes the Canvas
   * element accordingly (default).
   */
  setPixelRatio(ratio) {
    let pixelRatio = ratio != null ? ratio : this.window.devicePixelRatio;
    if (hasConstrainedCanvasMemory$1()) {
      pixelRatio = 1;
    }
    this._pixelRatio = pixelRatio;
    return _HdpiCanvas.overrideScale(this.realContext, pixelRatio);
  }
  set pixelated(value) {
    this.element.style.imageRendering = value ? "pixelated" : "auto";
  }
  get pixelated() {
    return this.element.style.imageRendering === "pixelated";
  }
  get width() {
    return this._width;
  }
  get height() {
    return this._height;
  }
  resize(width, height) {
    if (!(width > 0 && height > 0)) {
      return;
    }
    const { element: element2, context, pixelRatio } = this;
    element2.width = Math.round(width * pixelRatio);
    element2.height = Math.round(height * pixelRatio);
    element2.style.width = width + "px";
    element2.style.height = height + "px";
    context.resetTransform();
    this._width = width;
    this._height = height;
  }
  static get textMeasuringContext() {
    if (this._textMeasuringContext) {
      return this._textMeasuringContext;
    }
    const canvas = this.document.createElement("canvas");
    this._textMeasuringContext = canvas.getContext("2d");
    return this._textMeasuringContext;
  }
  static get svgText() {
    if (this._svgText) {
      return this._svgText;
    }
    const xmlns = "http://www.w3.org/2000/svg";
    const svg = document.createElementNS(xmlns, "svg");
    svg.setAttribute("width", "100");
    svg.setAttribute("height", "100");
    if (svg.classList) {
      svg.classList.add("text-measuring-svg");
    } else {
      svg.setAttribute("class", "text-measuring-svg");
    }
    svg.style.position = "absolute";
    svg.style.top = "-1000px";
    svg.style.visibility = "hidden";
    const svgText = document.createElementNS(xmlns, "text");
    svgText.setAttribute("x", "0");
    svgText.setAttribute("y", "30");
    svgText.setAttribute("text", "black");
    svg.appendChild(svgText);
    document.body.appendChild(svg);
    this._svgText = svgText;
    return svgText;
  }
  static get has() {
    if (this._has) {
      return this._has;
    }
    const isChrome = typeof navigator === "undefined" || navigator.userAgent.indexOf("Chrome") > -1;
    const isFirefox = typeof navigator !== "undefined" && navigator.userAgent.indexOf("Firefox") > -1;
    const isSafari = !isChrome && typeof navigator !== "undefined" && navigator.userAgent.indexOf("Safari") > -1;
    this._has = Object.freeze({
      textMetrics: this.textMeasuringContext.measureText("test").actualBoundingBoxDescent !== void 0 && // Firefox implemented advanced TextMetrics object in v74:
      // https://bugzilla.mozilla.org/show_bug.cgi?id=1102584
      // but it's buggy, so we'll keep using the SVG for text measurement in Firefox for now.
      !isFirefox && !isSafari,
      getTransform: this.textMeasuringContext.getTransform !== void 0
    });
    return this._has;
  }
  static measureText(text, font, textBaseline, textAlign) {
    const ctx = this.textMeasuringContext;
    ctx.font = font;
    ctx.textBaseline = textBaseline;
    ctx.textAlign = textAlign;
    return ctx.measureText(text);
  }
  /**
   * Returns the width and height of the measured text.
   * @param text The single-line text to measure.
   * @param font The font shorthand string.
   */
  static getTextSize(text, font) {
    if (this.has.textMetrics) {
      const ctx = this.textMeasuringContext;
      ctx.font = font;
      const metrics = ctx.measureText(text);
      return {
        width: metrics.width,
        height: metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
      };
    } else {
      return this.measureSvgText(text, font);
    }
  }
  static measureSvgText(text, font) {
    const cache = this.textSizeCache;
    const fontCache = cache[font];
    if (fontCache) {
      const size2 = fontCache[text];
      if (size2) {
        return size2;
      }
    } else {
      cache[font] = {};
    }
    const svgText = this.svgText;
    svgText.style.font = font;
    svgText.textContent = text;
    const bbox = svgText.getBBox();
    const size = {
      width: bbox.width,
      height: bbox.height
    };
    cache[font][text] = size;
    return size;
  }
  static overrideScale(ctx, scale2) {
    let depth = 0;
    const overrides = {
      save() {
        this.$save();
        depth++;
      },
      restore() {
        if (depth > 0) {
          this.$restore();
          depth--;
        } else {
          throw new Error("AG Charts - Unable to restore() past depth 0");
        }
      },
      setTransform(a, b, c, d, e, f) {
        if (typeof a === "object") {
          this.$setTransform(a);
        } else {
          this.$setTransform(a * scale2, b * scale2, c * scale2, d * scale2, e * scale2, f * scale2);
        }
      },
      resetTransform() {
        this.$setTransform(scale2, 0, 0, scale2, 0, 0);
      },
      verifyDepthZero() {
        if (depth !== 0) {
          throw new Error("AG Charts - Save/restore depth is non-zero: " + depth);
        }
      }
    };
    for (const name in overrides) {
      if (Object.hasOwn(overrides, name)) {
        if (!ctx["$" + name]) {
          ctx["$" + name] = ctx[name];
        }
        ctx[name] = overrides[name];
      }
    }
    return ctx;
  }
};
_HdpiCanvas$1.document = globalThis.document;
_HdpiCanvas$1.textSizeCache = {};
var HdpiCanvas$1 = _HdpiCanvas$1;

// packages/ag-charts-community/src/scene/gradient/gradient.ts
var Gradient$1 = class {
  constructor() {
    this.stops = [];
  }
};

// packages/ag-charts-community/src/scene/gradient/linearGradient.ts
var LinearGradient$1 = class extends Gradient$1 {
  constructor() {
    super(...arguments);
    this.angle = 0;
  }
  createGradient(ctx, bbox) {
    const angleOffset = 90;
    const { stops, angle } = this;
    const radians = normalizeAngle360$1(toRadians$2(angle + angleOffset));
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const w = bbox.width;
    const h = bbox.height;
    const cx = bbox.x + w * 0.5;
    const cy = bbox.y + h * 0.5;
    if (w > 0 && h > 0) {
      const diagonal = Math.sqrt(h * h + w * w) / 2;
      const diagonalAngle = Math.atan2(h, w);
      let quarteredAngle;
      if (radians < Math.PI / 2) {
        quarteredAngle = radians;
      } else if (radians < Math.PI) {
        quarteredAngle = Math.PI - radians;
      } else if (radians < 3 * Math.PI / 2) {
        quarteredAngle = radians - Math.PI;
      } else {
        quarteredAngle = 2 * Math.PI - radians;
      }
      const l = diagonal * Math.abs(Math.cos(quarteredAngle - diagonalAngle));
      const gradient = ctx.createLinearGradient(cx + cos * l, cy + sin * l, cx - cos * l, cy - sin * l);
      stops.forEach((stop) => {
        gradient.addColorStop(stop.offset, stop.color);
      });
      return gradient;
    }
    return "black";
  }
};

// packages/ag-charts-community/src/scene/shape/shape.ts
var LINEAR_GRADIENT_REGEXP$1 = /^linear-gradient\((.*?)deg,\s*(.*?)\s*\)$/i;
var _Shape$1 = class _Shape extends Node$1 {
  constructor() {
    super(...arguments);
    this.fillOpacity = 1;
    this.strokeOpacity = 1;
    this.fill = _Shape.defaultStyles.fill;
    this.stroke = _Shape.defaultStyles.stroke;
    this.strokeWidth = _Shape.defaultStyles.strokeWidth;
    this.lineDash = _Shape.defaultStyles.lineDash;
    this.lineDashOffset = _Shape.defaultStyles.lineDashOffset;
    this.lineCap = _Shape.defaultStyles.lineCap;
    this.lineJoin = _Shape.defaultStyles.lineJoin;
    this.opacity = _Shape.defaultStyles.opacity;
    this.fillShadow = _Shape.defaultStyles.fillShadow;
  }
  /**
   * Restores the default styles introduced by this subclass.
   */
  restoreOwnStyles() {
    const styles = this.constructor.defaultStyles;
    const keys = Object.getOwnPropertyNames(styles);
    for (let i = 0, n = keys.length; i < n; i++) {
      const key = keys[i];
      this[key] = styles[key];
    }
  }
  updateGradient() {
    const { fill } = this;
    let linearGradientMatch;
    if ((fill == null ? void 0 : fill.startsWith("linear-gradient")) && (linearGradientMatch = LINEAR_GRADIENT_REGEXP$1.exec(fill))) {
      const angle = parseFloat(linearGradientMatch[1]);
      const colors = [];
      const colorsPart = linearGradientMatch[2];
      const colorRegex = /(#[0-9a-f]+)|(rgba?\(.+?\))|([a-z]+)/gi;
      let c;
      while (c = colorRegex.exec(colorsPart)) {
        colors.push(c[0]);
      }
      this.gradient = new LinearGradient$1();
      this.gradient.angle = angle;
      this.gradient.stops = colors.map((color, index) => {
        const offset4 = index / (colors.length - 1);
        return { offset: offset4, color };
      });
    } else {
      this.gradient = void 0;
    }
  }
  /**
   * Returns a device-pixel aligned coordinate (or length if length is supplied).
   *
   * NOTE: Not suitable for strokes, since the stroke needs to be offset to the middle
   * of a device pixel.
   */
  align(start, length) {
    var _a, _b, _c;
    const pixelRatio = (_c = (_b = (_a = this.layerManager) == null ? void 0 : _a.canvas) == null ? void 0 : _b.pixelRatio) != null ? _c : 1;
    const alignedStart = Math.round(start * pixelRatio) / pixelRatio;
    if (length == void 0) {
      return alignedStart;
    }
    if (length === 0) {
      return 0;
    }
    if (length < 1) {
      return Math.ceil(length * pixelRatio) / pixelRatio;
    }
    return Math.round((length + start) * pixelRatio) / pixelRatio - alignedStart;
  }
  fillStroke(ctx) {
    this.renderFill(ctx);
    this.renderStroke(ctx);
  }
  renderFill(ctx) {
    if (this.fill) {
      const { globalAlpha } = ctx;
      this.applyFill(ctx);
      this.applyFillAlpha(ctx);
      this.applyShadow(ctx);
      ctx.fill();
      ctx.globalAlpha = globalAlpha;
    }
    ctx.shadowColor = "rgba(0, 0, 0, 0)";
  }
  applyFill(ctx) {
    if (this.gradient) {
      ctx.fillStyle = this.gradient.createGradient(ctx, this.computeBBox());
    } else {
      ctx.fillStyle = this.fill;
    }
  }
  applyFillAlpha(ctx) {
    const { globalAlpha } = ctx;
    ctx.globalAlpha = globalAlpha * this.opacity * this.fillOpacity;
  }
  applyShadow(ctx) {
    var _a, _b;
    const pixelRatio = (_b = (_a = this.layerManager) == null ? void 0 : _a.canvas.pixelRatio) != null ? _b : 1;
    const fillShadow = this.fillShadow;
    if (fillShadow == null ? void 0 : fillShadow.enabled) {
      ctx.shadowColor = fillShadow.color;
      ctx.shadowOffsetX = fillShadow.xOffset * pixelRatio;
      ctx.shadowOffsetY = fillShadow.yOffset * pixelRatio;
      ctx.shadowBlur = fillShadow.blur * pixelRatio;
    }
  }
  renderStroke(ctx) {
    if (this.stroke && this.strokeWidth) {
      const { globalAlpha } = ctx;
      ctx.strokeStyle = this.stroke;
      ctx.globalAlpha = globalAlpha * this.opacity * this.strokeOpacity;
      ctx.lineWidth = this.strokeWidth;
      if (this.lineDash) {
        ctx.setLineDash(this.lineDash);
      }
      if (this.lineDashOffset) {
        ctx.lineDashOffset = this.lineDashOffset;
      }
      if (this.lineCap) {
        ctx.lineCap = this.lineCap;
      }
      if (this.lineJoin) {
        ctx.lineJoin = this.lineJoin;
      }
      ctx.stroke();
      ctx.globalAlpha = globalAlpha;
    }
  }
  containsPoint(x, y) {
    return this.isPointInPath(x, y);
  }
};
/**
 * Defaults for style properties. Note that properties that affect the position
 * and shape of the node are not considered style properties, for example:
 * `x`, `y`, `width`, `height`, `radius`, `rotation`, etc.
 * Can be used to reset to the original styling after some custom styling
 * has been applied (using the `restoreOwnStyles` method).
 * These static defaults are meant to be inherited by subclasses.
 */
_Shape$1.defaultStyles = Object.assign(
  {},
  {
    fill: "black",
    stroke: void 0,
    strokeWidth: 0,
    lineDash: void 0,
    lineDashOffset: 0,
    lineCap: void 0,
    lineJoin: void 0,
    opacity: 1,
    fillShadow: void 0
  }
);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */ })
], _Shape$1.prototype, "fillOpacity", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */ })
], _Shape$1.prototype, "strokeOpacity", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */, changeCb: (s) => s.updateGradient() })
], _Shape$1.prototype, "fill", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */ })
], _Shape$1.prototype, "stroke", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */ })
], _Shape$1.prototype, "strokeWidth", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */ })
], _Shape$1.prototype, "lineDash", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */ })
], _Shape$1.prototype, "lineDashOffset", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */ })
], _Shape$1.prototype, "lineCap", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */ })
], _Shape$1.prototype, "lineJoin", 2);
__decorateClass$1([
  SceneChangeDetection$1({
    redraw: 2 /* MINOR */,
    convertor: (v) => Math.min(1, Math.max(0, v))
  })
], _Shape$1.prototype, "opacity", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */, checkDirtyOnAssignment: true })
], _Shape$1.prototype, "fillShadow", 2);
var Shape$1 = _Shape$1;

// packages/ag-charts-community/src/scene/shape/text.ts
var ellipsis$1 = "\u2026";
function SceneFontChangeDetection$1(opts) {
  const { redraw = 3 /* MAJOR */, changeCb } = opts != null ? opts : {};
  return SceneChangeDetection$1({ redraw, type: "font", changeCb });
}
var _Text$1 = class _Text extends Shape$1 {
  constructor() {
    super(...arguments);
    this.x = 0;
    this.y = 0;
    this.lines = [];
    this.text = void 0;
    this._dirtyFont = true;
    this.fontSize = 10;
    this.fontFamily = "sans-serif";
    this.textAlign = _Text.defaultStyles.textAlign;
    this.textBaseline = _Text.defaultStyles.textBaseline;
    this.lineHeight = void 0;
  }
  _setLines() {
    this.lines = splitText$1(this.text);
  }
  get font() {
    if (this._font == null || this._dirtyFont) {
      this._dirtyFont = false;
      this._font = getFont$1(this);
    }
    return this._font;
  }
  computeBBox() {
    return HdpiCanvas$1.has.textMetrics ? getPreciseBBox$1(this.lines, this.x, this.y, this) : getApproximateBBox$1(this.lines, this.x, this.y, this);
  }
  getLineHeight(line) {
    var _a, _b;
    if (this.lineHeight)
      return this.lineHeight;
    if (HdpiCanvas$1.has.textMetrics) {
      const metrics = HdpiCanvas$1.measureText(line, this.font, this.textBaseline, this.textAlign);
      return ((_a = metrics.fontBoundingBoxAscent) != null ? _a : metrics.emHeightAscent) + ((_b = metrics.fontBoundingBoxDescent) != null ? _b : metrics.emHeightDescent);
    }
    return HdpiCanvas$1.getTextSize(line, this.font).height;
  }
  isPointInPath(x, y) {
    const point = this.transformPoint(x, y);
    const bbox = this.computeBBox();
    return bbox ? bbox.containsPoint(point.x, point.y) : false;
  }
  render(renderCtx) {
    const { ctx, forceRender, stats } = renderCtx;
    if (this.dirty === 0 /* NONE */ && !forceRender) {
      if (stats)
        stats.nodesSkipped += this.nodeCount.count;
      return;
    }
    if (!this.lines.length || !this.layerManager) {
      if (stats)
        stats.nodesSkipped += this.nodeCount.count;
      return;
    }
    this.computeTransformMatrix();
    this.matrix.toContext(ctx);
    const { fill, stroke, strokeWidth } = this;
    ctx.font = this.font;
    ctx.textAlign = this.textAlign;
    ctx.textBaseline = this.textBaseline;
    const pixelRatio = this.layerManager.canvas.pixelRatio || 1;
    const { globalAlpha } = ctx;
    if (fill) {
      ctx.fillStyle = fill;
      ctx.globalAlpha = globalAlpha * this.opacity * this.fillOpacity;
      const { fillShadow } = this;
      if (fillShadow == null ? void 0 : fillShadow.enabled) {
        ctx.shadowColor = fillShadow.color;
        ctx.shadowOffsetX = fillShadow.xOffset * pixelRatio;
        ctx.shadowOffsetY = fillShadow.yOffset * pixelRatio;
        ctx.shadowBlur = fillShadow.blur * pixelRatio;
      }
      this.renderLines((line, x, y) => ctx.fillText(line, x, y));
    }
    if (stroke && strokeWidth) {
      ctx.strokeStyle = stroke;
      ctx.lineWidth = strokeWidth;
      ctx.globalAlpha = globalAlpha * this.opacity * this.strokeOpacity;
      const { lineDash, lineDashOffset, lineCap, lineJoin } = this;
      if (lineDash) {
        ctx.setLineDash(lineDash);
      }
      if (lineDashOffset) {
        ctx.lineDashOffset = lineDashOffset;
      }
      if (lineCap) {
        ctx.lineCap = lineCap;
      }
      if (lineJoin) {
        ctx.lineJoin = lineJoin;
      }
      this.renderLines((line, x, y) => ctx.strokeText(line, x, y));
    }
    super.render(renderCtx);
  }
  renderLines(renderCallback) {
    const { lines, x, y } = this;
    const lineHeights = this.lines.map((line) => this.getLineHeight(line));
    const totalHeight = lineHeights.reduce((a, b) => a + b, 0);
    let offsetY = -(totalHeight - lineHeights[0]) * getVerticalOffset$1(this.textBaseline);
    for (let i = 0; i < lines.length; i++) {
      renderCallback(lines[i], x, y + offsetY);
      offsetY += lineHeights[i];
    }
  }
  static wrapLines(text, maxWidth, maxHeight, textProps, wrapping, overflow) {
    const canOverflow = overflow !== "hide";
    const font = getFont$1(textProps);
    const measurer = createTextMeasurer$1(font);
    const lines = text.split(/\r?\n/g);
    if (lines.length === 0) {
      return { lines: void 0, truncated: false };
    }
    if (wrapping === "never") {
      const { text: text2, truncated: truncated2 } = _Text.truncateLine(lines[0], maxWidth, measurer, canOverflow ? "auto" : "never");
      return { lines: text2 != null ? [text2] : void 0, truncated: truncated2 };
    }
    const wrappedLines = [];
    let cumulativeHeight = 0;
    let truncated = false;
    for (const line of lines) {
      const wrappedLine = _Text.wrapLine(
        line,
        maxWidth,
        maxHeight,
        measurer,
        textProps,
        wrapping,
        cumulativeHeight,
        canOverflow
      );
      if (wrappedLine == null) {
        return { lines: void 0, truncated: false };
      }
      wrappedLines.push(...wrappedLine.result);
      cumulativeHeight = wrappedLine.cumulativeHeight;
      if (wrappedLine.truncated) {
        truncated = true;
        break;
      }
    }
    return { lines: wrappedLines, truncated };
  }
  static wrap(text, maxWidth, maxHeight, textProps, wrapping, overflow = "ellipsis") {
    const { lines, truncated } = _Text.wrapLines(text, maxWidth, maxHeight, textProps, wrapping, overflow);
    return { text: lines != null ? lines.join("\n").trim() : "", truncated };
  }
  static wrapLine(text, maxWidth, maxHeight, measurer, textProps, wrapping, cumulativeHeight, canOverflow) {
    text = text.trim();
    if (!text) {
      return { result: [], truncated: false, cumulativeHeight };
    }
    const initialSize = measurer.size(text);
    if (initialSize.width <= maxWidth) {
      return {
        result: [text],
        truncated: false,
        cumulativeHeight: cumulativeHeight + initialSize.height
      };
    }
    if (initialSize.height > maxHeight || measurer.width("W") > maxWidth) {
      return canOverflow ? { result: [], truncated: true, cumulativeHeight } : void 0;
    }
    const words = text.split(/\s+/g);
    const wrapResult = _Text.wrapLineSequentially(
      words,
      maxWidth,
      maxHeight,
      measurer,
      textProps,
      wrapping,
      cumulativeHeight,
      canOverflow
    );
    if (wrapResult == null) {
      return void 0;
    }
    cumulativeHeight = wrapResult.cumulativeHeight;
    let { lines } = wrapResult;
    if (!(wrapResult.wordsBrokenOrTruncated || wrapResult.linesTruncated)) {
      const linesCount = wrapResult.lines.length;
      const balanced = _Text.wrapLineBalanced(words, maxWidth, measurer, linesCount);
      if (balanced.length === lines.length) {
        lines = balanced;
      }
    }
    const wrappedText = lines.map((ln) => ln.join(" "));
    return { result: wrappedText, truncated: wrapResult.linesTruncated, cumulativeHeight };
  }
  static breakWord(word, firstLineWidth, maxWidth, hyphens, measurer) {
    const isPunctuationAt = (index) => _Text.punctuationMarks.includes(word[index]);
    const h = hyphens ? measurer.width("-") : 0;
    const breaks = [];
    let partWidth = 0;
    let p = 0;
    for (let i = 0; i < word.length; i++) {
      const c = word[i];
      const w = measurer.width(c);
      const limit = p === 0 ? firstLineWidth : maxWidth;
      if (partWidth + w + h > limit) {
        breaks.push(i);
        partWidth = 0;
        p++;
      }
      partWidth += w;
    }
    const parts = [];
    let start = 0;
    for (const index of breaks) {
      let part = word.substring(start, index);
      if (hyphens && part.length > 0 && !isPunctuationAt(index - 1) && !isPunctuationAt(index)) {
        part += "-";
      }
      parts.push(part);
      start = index;
    }
    parts.push(word.substring(start));
    return parts;
  }
  static truncateLine(text, maxWidth, measurer, ellipsisMode) {
    text = text.trimEnd();
    const lineWidth = measurer.width(text);
    if (lineWidth > maxWidth && ellipsisMode === "never") {
      return { text: void 0, truncated: false };
    } else if (lineWidth <= maxWidth && ellipsisMode !== "force") {
      return { text, truncated: false };
    }
    const ellipsisWidth = measurer.width(ellipsis$1);
    let trunc = text;
    let truncWidth = lineWidth;
    while (trunc.length > 0 && truncWidth + ellipsisWidth > maxWidth) {
      trunc = trunc.slice(0, -1).trimEnd();
      truncWidth = measurer.width(trunc);
    }
    if (truncWidth + ellipsisWidth <= maxWidth) {
      return { text: `${trunc}${ellipsis$1}`, truncated: true };
    } else {
      return { text: void 0, truncated: false };
    }
  }
  static wrapLineSequentially(words, maxWidth, maxHeight, measurer, textProps, wrapping, cumulativeHeight, canOverflow) {
    const { fontSize = 0, lineHeight = fontSize * _Text.defaultLineHeightRatio } = textProps;
    const breakWord = wrapping === "always" || wrapping === "hyphenate";
    const hyphenate = wrapping === "hyphenate";
    const spaceWidth = measurer.width(" ");
    let wordsBrokenOrTruncated = false;
    let linesTruncated = false;
    const lines = [];
    let currentLine = [];
    let lineWidth = 0;
    const getReturnValue = () => ({
      lines,
      linesTruncated,
      wordsBrokenOrTruncated,
      cumulativeHeight
    });
    const truncateLastLine = () => {
      if (!canOverflow) {
        return void 0;
      }
      const lastLine = currentLine.join(" ");
      const { text } = _Text.truncateLine(lastLine, maxWidth, measurer, "force");
      if (text == null) {
        return void 0;
      }
      currentLine.splice(0, currentLine.length, text);
      linesTruncated = true;
      return getReturnValue();
    };
    const addNewLine = () => {
      const expectedHeight = cumulativeHeight + lineHeight;
      if (expectedHeight >= maxHeight) {
        return false;
      }
      currentLine = [];
      lineWidth = 0;
      cumulativeHeight = expectedHeight;
      lines.push(currentLine);
      return true;
    };
    if (!addNewLine()) {
      return truncateLastLine();
    }
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      const wordWidth = measurer.width(word);
      const expectedSpaceWidth = currentLine.length === 0 ? 0 : spaceWidth;
      const expectedLineWidth = lineWidth + expectedSpaceWidth + wordWidth;
      if (expectedLineWidth <= maxWidth) {
        currentLine.push(word);
        lineWidth = expectedLineWidth;
        continue;
      }
      if (wordWidth <= maxWidth) {
        if (!addNewLine()) {
          return truncateLastLine();
        }
        currentLine.push(word);
        lineWidth = wordWidth;
        continue;
      }
      wordsBrokenOrTruncated = true;
      if (breakWord) {
        const availWidth = maxWidth - lineWidth - expectedSpaceWidth;
        const parts = _Text.breakWord(word, availWidth, maxWidth, hyphenate, measurer);
        for (let p = 0; p < parts.length; p++) {
          const part = parts[p];
          part && currentLine.push(part);
          if (p === parts.length - 1) {
            lineWidth = measurer.width(part);
          } else if (!addNewLine()) {
            return truncateLastLine();
          }
        }
      } else if (canOverflow) {
        if (!addNewLine()) {
          return truncateLastLine();
        }
        const { text } = _Text.truncateLine(word, maxWidth, measurer, "force");
        if (text == null) {
          return void 0;
        }
        currentLine.push(text);
        if (i < words.length - 1) {
          linesTruncated = true;
        }
        break;
      } else {
        return void 0;
      }
    }
    return getReturnValue();
  }
  static wrapLineBalanced(words, maxWidth, measurer, linesCount) {
    const totalWordsWidth = words.reduce((sum2, w) => sum2 + measurer.width(w), 0);
    const spaceWidth = measurer.width(" ");
    const totalSpaceWidth = spaceWidth * (words.length - linesCount - 2);
    const averageLineWidth = (totalWordsWidth + totalSpaceWidth) / linesCount;
    const lines = [];
    let currentLine = [];
    let lineWidth = measurer.width(words[0]);
    let newLine = true;
    for (const word of words) {
      const width = measurer.width(word);
      if (newLine) {
        currentLine = [];
        currentLine.push(word);
        lineWidth = width;
        newLine = false;
        lines.push(currentLine);
        continue;
      }
      const expectedLineWidth = lineWidth + spaceWidth + width;
      if (expectedLineWidth <= averageLineWidth) {
        currentLine.push(word);
        lineWidth = expectedLineWidth;
      } else if (expectedLineWidth <= maxWidth) {
        currentLine.push(word);
        newLine = true;
      } else {
        currentLine = [word];
        lineWidth = width;
        lines.push(currentLine);
      }
    }
    return lines;
  }
  setFont(props) {
    this.fontFamily = props.fontFamily;
    this.fontSize = props.fontSize;
    this.fontStyle = props.fontStyle;
    this.fontWeight = props.fontWeight;
  }
  setAlign(props) {
    this.textAlign = props.textAlign;
    this.textBaseline = props.textBaseline;
  }
};
_Text$1.className = "Text";
// The default line spacing for document editors is usually 1.15
_Text$1.defaultLineHeightRatio = 1.15;
_Text$1.defaultStyles = Object.assign({}, Shape$1.defaultStyles, {
  textAlign: "start",
  fontStyle: void 0,
  fontWeight: void 0,
  fontSize: 10,
  fontFamily: "sans-serif",
  textBaseline: "alphabetic"
});
_Text$1.ellipsis = ellipsis$1;
_Text$1.punctuationMarks = [".", ",", "-", ":", ";", "!", "?", `'`, '"', "(", ")"];
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], _Text$1.prototype, "x", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], _Text$1.prototype, "y", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */, changeCb: (o) => o._setLines() })
], _Text$1.prototype, "text", 2);
__decorateClass$1([
  SceneFontChangeDetection$1()
], _Text$1.prototype, "fontStyle", 2);
__decorateClass$1([
  SceneFontChangeDetection$1()
], _Text$1.prototype, "fontWeight", 2);
__decorateClass$1([
  SceneFontChangeDetection$1()
], _Text$1.prototype, "fontSize", 2);
__decorateClass$1([
  SceneFontChangeDetection$1()
], _Text$1.prototype, "fontFamily", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], _Text$1.prototype, "textAlign", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], _Text$1.prototype, "textBaseline", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], _Text$1.prototype, "lineHeight", 2);
var Text$1 = _Text$1;
function createTextMeasurer$1(font) {
  const cache = /* @__PURE__ */ new Map();
  const getTextSize = (text) => HdpiCanvas$1.getTextSize(text, font);
  const getLineWidth = (text) => {
    if (cache.has(text)) {
      return cache.get(text);
    }
    const { width } = getTextSize(text);
    cache.set(text, width);
    return width;
  };
  return { size: getTextSize, width: getLineWidth };
}
function getFont$1(fontProps) {
  const { fontFamily, fontSize, fontStyle, fontWeight } = fontProps;
  return [fontStyle != null ? fontStyle : "", fontWeight != null ? fontWeight : "", fontSize + "px", fontFamily].join(" ").trim();
}
function measureText$1(lines, x, y, textProps) {
  return HdpiCanvas$1.has.textMetrics ? getPreciseBBox$1(lines, x, y, textProps) : getApproximateBBox$1(lines, x, y, textProps);
}
function getPreciseBBox$1(lines, x, y, textProps) {
  var _a, _b;
  let left = 0;
  let top = 0;
  let width = 0;
  let height = 0;
  let baselineDistance = 0;
  const font = getFont$1(textProps);
  const {
    lineHeight,
    textBaseline = Text$1.defaultStyles.textBaseline,
    textAlign = Text$1.defaultStyles.textAlign
  } = textProps;
  for (let i = 0; i < lines.length; i++) {
    const metrics = HdpiCanvas$1.measureText(lines[i], font, textBaseline, textAlign);
    left = Math.max(left, metrics.actualBoundingBoxLeft);
    width = Math.max(width, metrics.width);
    if (i == 0) {
      top += metrics.actualBoundingBoxAscent;
      height += metrics.actualBoundingBoxAscent;
    } else {
      baselineDistance += (_a = metrics.fontBoundingBoxAscent) != null ? _a : metrics.emHeightAscent;
    }
    if (i == lines.length - 1) {
      height += metrics.actualBoundingBoxDescent;
    } else {
      baselineDistance += (_b = metrics.fontBoundingBoxDescent) != null ? _b : metrics.emHeightDescent;
    }
  }
  if (lineHeight !== void 0) {
    baselineDistance = (lines.length - 1) * lineHeight;
  }
  height += baselineDistance;
  top += baselineDistance * getVerticalOffset$1(textBaseline);
  return new BBox$1(x - left, y - top, width, height);
}
function getApproximateBBox$1(lines, x, y, textProps) {
  let width = 0;
  let firstLineHeight = 0;
  let baselineDistance = 0;
  const font = getFont$1(textProps);
  const {
    lineHeight,
    textBaseline = Text$1.defaultStyles.textBaseline,
    textAlign = Text$1.defaultStyles.textAlign
  } = textProps;
  if (lines.length > 0) {
    const lineSize = HdpiCanvas$1.getTextSize(lines[0], font);
    width = lineSize.width;
    firstLineHeight = lineSize.height;
  }
  for (let i = 1; i < lines.length; i++) {
    const lineSize = HdpiCanvas$1.getTextSize(lines[i], font);
    width = Math.max(width, lineSize.width);
    baselineDistance += lineHeight != null ? lineHeight : lineSize.height;
  }
  switch (textAlign) {
    case "end":
    case "right":
      x -= width;
      break;
    case "center":
      x -= width / 2;
  }
  switch (textBaseline) {
    case "alphabetic":
      y -= firstLineHeight * 0.7 + baselineDistance * 0.5;
      break;
    case "middle":
      y -= firstLineHeight * 0.45 + baselineDistance * 0.5;
      break;
    case "ideographic":
      y -= firstLineHeight + baselineDistance;
      break;
    case "hanging":
      y -= firstLineHeight * 0.2 + baselineDistance * 0.5;
      break;
    case "bottom":
      y -= firstLineHeight + baselineDistance;
      break;
  }
  return new BBox$1(x, y, width, firstLineHeight + baselineDistance);
}
function getVerticalOffset$1(textBaseline) {
  switch (textBaseline) {
    case "top":
    case "hanging":
      return 0;
    case "bottom":
    case "alphabetic":
    case "ideographic":
      return 1;
    case "middle":
      return 0.5;
  }
}
function splitText$1(text) {
  return typeof text === "string" ? text.split(/\r?\n/g) : [];
}

// packages/ag-charts-community/src/util/proxy.ts
function ProxyProperty$1(...proxyProperties) {
  const property = proxyProperties[proxyProperties.length - 1];
  if (proxyProperties.length === 1) {
    return addTransformToInstanceProperty$1(
      (target, _, value) => {
        target[property] = value;
        return value;
      },
      (target, _) => {
        return target[property];
      }
    );
  }
  const getTarget = (target) => {
    let value = target;
    for (let i = 0; i < proxyProperties.length - 1; i += 1) {
      value = value[proxyProperties[i]];
    }
    return value;
  };
  return addTransformToInstanceProperty$1(
    (target, _, value) => {
      getTarget(target)[property] = value;
      return value;
    },
    (target, _) => {
      return getTarget(target)[property];
    }
  );
}
function ProxyOnWrite$1(proxyProperty) {
  return addTransformToInstanceProperty$1((target, _, value) => {
    target[proxyProperty] = value;
    return value;
  });
}
function ProxyPropertyOnWrite$1(childName, childProperty) {
  return addTransformToInstanceProperty$1((target, key, value) => {
    target[childName][childProperty != null ? childProperty : key] = value;
    return value;
  });
}
function ActionOnSet$1(opts) {
  const { newValue: newValueFn, oldValue: oldValueFn, changeValue: changeValueFn } = opts;
  return addTransformToInstanceProperty$1((target, _, newValue, oldValue) => {
    if (newValue !== oldValue) {
      if (oldValue !== void 0) {
        oldValueFn == null ? void 0 : oldValueFn.call(target, oldValue);
      }
      if (newValue !== void 0) {
        newValueFn == null ? void 0 : newValueFn.call(target, newValue);
      }
      changeValueFn == null ? void 0 : changeValueFn.call(target, newValue, oldValue);
    }
    return newValue;
  });
}

// packages/ag-charts-community/src/util/dom.ts
function injectStyle$1(document2, cssStyle) {
  const styleElement = document2.createElement("style");
  styleElement.innerHTML = cssStyle;
  document2.head.insertBefore(styleElement, document2.head.querySelector("style"));
}

// packages/ag-charts-community/src/chart/tooltip/tooltip.ts
var DEFAULT_TOOLTIP_CLASS$1 = "ag-chart-tooltip";
var DEFAULT_TOOLTIP_DARK_CLASS$1 = "ag-chart-dark-tooltip";
var defaultTooltipCss$2 = `
.${DEFAULT_TOOLTIP_CLASS$1} {
    transition: transform 0.1s ease;
    max-width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 99999;
    font: 12px Verdana, sans-serif;
    color: rgb(70, 70, 70);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.${DEFAULT_TOOLTIP_CLASS$1}-wrap-always {
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: none;
}

.${DEFAULT_TOOLTIP_CLASS$1}-wrap-hyphenate {
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
}

.${DEFAULT_TOOLTIP_CLASS$1}-wrap-on-space {
    overflow-wrap: normal;
    word-break: normal;
}

.${DEFAULT_TOOLTIP_CLASS$1}-wrap-never {
    white-space: pre;
    text-overflow: ellipsis;
}

.${DEFAULT_TOOLTIP_CLASS$1}-no-interaction {
    pointer-events: none;
    user-select: none;
}

.${DEFAULT_TOOLTIP_CLASS$1}-no-animation {
    transition: none !important;
}

.${DEFAULT_TOOLTIP_CLASS$1}-hidden {
    visibility: hidden;
}

.${DEFAULT_TOOLTIP_CLASS$1}-title {
    overflow: hidden;
    position: relative;
    padding: 8px 14px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    color: white;
    background-color: #888888;
    z-index: 1;
    text-overflow: inherit;
}

.${DEFAULT_TOOLTIP_CLASS$1}-title:only-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.${DEFAULT_TOOLTIP_CLASS$1}-content {
    overflow: hidden;
    padding: 6px 14px;
    line-height: 1.7em;
    background: white;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    overflow: hidden;
    text-overflow: inherit;
}

.${DEFAULT_TOOLTIP_CLASS$1}-arrow::before {
    content: "";

    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);

    border: 5px solid #d9d9d9;

    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;

    width: 0;
    height: 0;

    margin: 0 auto;
}

.${DEFAULT_TOOLTIP_CLASS$1}-arrow::after {
    content: "";

    position: absolute;
    top: calc(100% - 1px);
    left: 50%;
    transform: translateX(-50%);

    border: 5px solid white;

    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;

    width: 0;
    height: 0;

    margin: 0 auto;
}

.${DEFAULT_TOOLTIP_CLASS$1}.${DEFAULT_TOOLTIP_DARK_CLASS$1} {
    color: white;
    background: #15181c;
}

.${DEFAULT_TOOLTIP_CLASS$1}.${DEFAULT_TOOLTIP_DARK_CLASS$1} .${DEFAULT_TOOLTIP_CLASS$1}-content {
    border-color: rgba(255, 255, 255, 0.15);
}

.ag-chart-wrapper {
    box-sizing: border-box;
    overflow: hidden;
}
`;
function toTooltipHtml$2(input, defaults) {
  var _a, _b, _c;
  if (typeof input === "string") {
    return input;
  }
  const {
    content = (_a = defaults == null ? void 0 : defaults.content) != null ? _a : "",
    title = defaults == null ? void 0 : defaults.title,
    color = (_b = defaults == null ? void 0 : defaults.color) != null ? _b : "white",
    backgroundColor = (_c = defaults == null ? void 0 : defaults.backgroundColor) != null ? _c : "#888"
  } = input;
  const titleHtml = title ? `<div class="${DEFAULT_TOOLTIP_CLASS$1}-title"
        style="color: ${color}; background-color: ${backgroundColor}">${title}</div>` : "";
  const contentHtml = content ? `<div class="${DEFAULT_TOOLTIP_CLASS$1}-content">${content}</div>` : "";
  return `${titleHtml}${contentHtml}`;
}
var TooltipPosition$1 = class extends BaseProperties$1 {
  constructor() {
    super(...arguments);
    /** The type of positioning for the tooltip. By default, the tooltip follows the pointer. */
    this.type = "pointer";
    /** The horizontal offset in pixels for the position of the tooltip. */
    this.xOffset = 0;
    /** The vertical offset in pixels for the position of the tooltip. */
    this.yOffset = 0;
  }
};
__decorateClass$1([
  Validate$1(UNION$1(["pointer", "node"], "a position type"))
], TooltipPosition$1.prototype, "type", 2);
__decorateClass$1([
  Validate$1(NUMBER$1)
], TooltipPosition$1.prototype, "xOffset", 2);
__decorateClass$1([
  Validate$1(NUMBER$1)
], TooltipPosition$1.prototype, "yOffset", 2);
var _Tooltip$1 = class _Tooltip {
  constructor(canvasElement, document2, window2, container) {
    this.enableInteraction = false;
    this.enabled = true;
    this.showArrow = void 0;
    this.class = void 0;
    this.lastClass = void 0;
    this.delay = 0;
    this.range = "nearest";
    this.wrapping = "hyphenate";
    this.lastVisibilityChange = Date.now();
    this.position = new TooltipPosition$1();
    this.showTimeout = 0;
    this._showArrow = true;
    this.tooltipRoot = container;
    this.window = window2;
    const element2 = document2.createElement("div");
    this.element = this.tooltipRoot.appendChild(element2);
    this.element.classList.add(DEFAULT_TOOLTIP_CLASS$1);
    this.canvasElement = canvasElement;
    if (typeof IntersectionObserver !== "undefined") {
      const observer = new IntersectionObserver(
        (entries) => {
          for (const entry of entries) {
            if (entry.target === this.canvasElement && entry.intersectionRatio === 0) {
              this.toggle(false);
            }
          }
        },
        { root: this.tooltipRoot }
      );
      observer.observe(this.canvasElement);
      this.observer = observer;
    }
    if (_Tooltip.tooltipDocuments.indexOf(document2) < 0) {
      injectStyle$1(document2, defaultTooltipCss$2);
      _Tooltip.tooltipDocuments.push(document2);
    }
  }
  destroy() {
    const { parentNode } = this.element;
    if (parentNode) {
      parentNode.removeChild(this.element);
    }
    if (this.observer) {
      this.observer.unobserve(this.canvasElement);
    }
  }
  isVisible() {
    const { element: element2 } = this;
    return !element2.classList.contains(DEFAULT_TOOLTIP_CLASS$1 + "-hidden");
  }
  updateClass(visible, showArrow, addCustomClass = true) {
    const { element: element2, class: newClass, lastClass, enableInteraction, lastVisibilityChange } = this;
    const wasVisible = this.isVisible();
    const nowVisible = !!visible;
    let timeSinceLastVisibilityChangeMs = Infinity;
    if (wasVisible !== nowVisible) {
      const now = Date.now();
      timeSinceLastVisibilityChangeMs = now - lastVisibilityChange;
      this.lastVisibilityChange = now;
    }
    const toggleClass = (name, include) => {
      const className = `${DEFAULT_TOOLTIP_CLASS$1}-${name}`;
      if (include) {
        element2.classList.add(className);
      } else {
        element2.classList.remove(className);
      }
    };
    const animatedMoveThresholdMs = 100;
    const thrashingThresholdMs = 5;
    const noAnimation = !wasVisible && nowVisible && timeSinceLastVisibilityChangeMs > animatedMoveThresholdMs;
    if (timeSinceLastVisibilityChangeMs > thrashingThresholdMs) {
      toggleClass("no-animation", noAnimation);
    }
    toggleClass("no-interaction", !enableInteraction);
    toggleClass("hidden", !visible);
    toggleClass("arrow", !!showArrow);
    this.updateWrapping();
    if (addCustomClass) {
      if (newClass !== lastClass) {
        if (lastClass) {
          element2.classList.remove(lastClass);
        }
        if (newClass) {
          element2.classList.add(newClass);
        }
      }
      this.lastClass = newClass;
    } else {
      if (lastClass) {
        element2.classList.remove(lastClass);
      }
      this.lastClass = void 0;
    }
  }
  updateWrapping() {
    const { element: element2, wrapping } = this;
    const wrappingOptions = {
      always: false,
      hyphenate: false,
      "on-space": false,
      never: false
    };
    wrappingOptions[wrapping] = true;
    Object.entries(wrappingOptions).forEach(([name, force]) => {
      element2.classList.toggle(`${DEFAULT_TOOLTIP_CLASS$1}-wrap-${name}`, force);
    });
  }
  /**
   * Shows tooltip at the given event's coordinates.
   * If the `html` parameter is missing, moves the existing tooltip to the new position.
   */
  show(meta, html, instantly = false) {
    var _a, _b, _c, _d, _e, _f, _g;
    const { element: element2, canvasElement } = this;
    if (html !== void 0) {
      element2.innerHTML = html;
    } else if (!element2.innerHTML) {
      this.toggle(false);
      return;
    }
    const limit = (low, actual, high) => {
      return Math.max(Math.min(actual, high), low);
    };
    const xOffset = (_b = (_a = meta.position) == null ? void 0 : _a.xOffset) != null ? _b : 0;
    const yOffset = (_d = (_c = meta.position) == null ? void 0 : _c.yOffset) != null ? _d : 0;
    const canvasRect = canvasElement.getBoundingClientRect();
    const naiveLeft = canvasRect.left + meta.offsetX - element2.clientWidth / 2 + xOffset;
    const naiveTop = canvasRect.top + meta.offsetY - element2.clientHeight - 8 + yOffset;
    const windowBounds = this.getWindowBoundingBox();
    const maxLeft = windowBounds.x + windowBounds.width - element2.clientWidth - 1;
    const maxTop = windowBounds.y + windowBounds.height - element2.clientHeight;
    const left = limit(windowBounds.x, naiveLeft, maxLeft);
    const top = limit(windowBounds.y, naiveTop, maxTop);
    const constrained = left !== naiveLeft || top !== naiveTop;
    const defaultShowArrow = !constrained && !xOffset && !yOffset;
    const showArrow = (_f = (_e = meta.showArrow) != null ? _e : this.showArrow) != null ? _f : defaultShowArrow;
    this.updateShowArrow(showArrow);
    element2.style.transform = `translate(${Math.round(left)}px, ${Math.round(top)}px)`;
    this.enableInteraction = (_g = meta.enableInteraction) != null ? _g : false;
    if (this.delay > 0 && !instantly) {
      this.toggle(false);
      this.showTimeout = this.window.setTimeout(() => {
        this.toggle(true, meta.addCustomClass);
      }, this.delay);
      return;
    }
    this.toggle(true, meta.addCustomClass);
  }
  getWindowBoundingBox() {
    return new BBox$1(0, 0, this.window.innerWidth, this.window.innerHeight);
  }
  toggle(visible, addCustomClass) {
    if (!visible) {
      this.window.clearTimeout(this.showTimeout);
    }
    this.updateClass(visible, this._showArrow, addCustomClass);
  }
  pointerLeftOntoTooltip(event) {
    var _a;
    if (!this.enableInteraction)
      return false;
    const classList = (_a = event.sourceEvent.relatedTarget) == null ? void 0 : _a.classList;
    const classes = ["", "-title", "-content"];
    const classListContains = Boolean(classes.filter((c) => classList == null ? void 0 : classList.contains(`${DEFAULT_TOOLTIP_CLASS$1}${c}`)));
    return classList !== void 0 && classListContains;
  }
  updateShowArrow(show) {
    this._showArrow = show;
  }
};
_Tooltip$1.tooltipDocuments = [];
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], _Tooltip$1.prototype, "enabled", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1, { optional: true })
], _Tooltip$1.prototype, "showArrow", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], _Tooltip$1.prototype, "class", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], _Tooltip$1.prototype, "delay", 2);
__decorateClass$1([
  Validate$1(INTERACTION_RANGE$1)
], _Tooltip$1.prototype, "range", 2);
__decorateClass$1([
  Validate$1(TEXT_WRAP$1)
], _Tooltip$1.prototype, "wrapping", 2);
var Tooltip$1 = _Tooltip$1;

// packages/ag-charts-community/src/chart/caption.ts
var Caption$1 = class extends BaseProperties$1 {
  constructor() {
    super(...arguments);
    this.id = createId$2(this);
    this.node = new Text$1().setProperties({
      textAlign: "center",
      pointerEvents: 1 /* None */
    });
    this.enabled = false;
    this.textAlign = "center";
    this.fontSize = 10;
    this.fontFamily = "sans-serif";
    this.wrapping = "always";
    this.truncated = false;
  }
  registerInteraction(moduleCtx) {
    return moduleCtx.interactionManager.addListener("hover", (event) => this.handleMouseMove(moduleCtx, event));
  }
  computeTextWrap(containerWidth, containerHeight) {
    var _a, _b;
    const { text, wrapping } = this;
    const maxWidth = Math.min((_a = this.maxWidth) != null ? _a : Infinity, containerWidth);
    const maxHeight = (_b = this.maxHeight) != null ? _b : containerHeight;
    if (!isFinite(maxWidth) && !isFinite(maxHeight)) {
      this.node.text = text;
      return;
    }
    const { text: wrappedText, truncated } = Text$1.wrap(text != null ? text : "", maxWidth, maxHeight, this, wrapping);
    this.node.text = wrappedText;
    this.truncated = truncated;
  }
  handleMouseMove(moduleCtx, event) {
    if (!this.enabled) {
      return;
    }
    const bbox = this.node.computeBBox();
    const { pageX, pageY, offsetX, offsetY } = event;
    const pointerInsideCaption = this.node.visible && bbox.containsPoint(offsetX, offsetY);
    if (!pointerInsideCaption) {
      moduleCtx.tooltipManager.removeTooltip(this.id);
      return;
    }
    event.consume();
    if (!this.truncated) {
      moduleCtx.tooltipManager.removeTooltip(this.id);
      return;
    }
    moduleCtx.tooltipManager.updateTooltip(
      this.id,
      { pageX, pageY, offsetX, offsetY, event, showArrow: false, addCustomClass: false },
      toTooltipHtml$2({ content: this.text })
    );
  }
};
Caption$1.SMALL_PADDING = 10;
Caption$1.LARGE_PADDING = 20;
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], Caption$1.prototype, "enabled", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true }),
  ProxyPropertyOnWrite$1("node")
], Caption$1.prototype, "text", 2);
__decorateClass$1([
  Validate$1(TEXT_ALIGN$1, { optional: true }),
  ProxyPropertyOnWrite$1("node")
], Caption$1.prototype, "textAlign", 2);
__decorateClass$1([
  Validate$1(FONT_STYLE$1, { optional: true }),
  ProxyPropertyOnWrite$1("node")
], Caption$1.prototype, "fontStyle", 2);
__decorateClass$1([
  Validate$1(FONT_WEIGHT$1, { optional: true }),
  ProxyPropertyOnWrite$1("node")
], Caption$1.prototype, "fontWeight", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1),
  ProxyPropertyOnWrite$1("node")
], Caption$1.prototype, "fontSize", 2);
__decorateClass$1([
  Validate$1(STRING$1),
  ProxyPropertyOnWrite$1("node")
], Caption$1.prototype, "fontFamily", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true }),
  ProxyPropertyOnWrite$1("node", "fill")
], Caption$1.prototype, "color", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], Caption$1.prototype, "spacing", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], Caption$1.prototype, "lineHeight", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], Caption$1.prototype, "maxWidth", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], Caption$1.prototype, "maxHeight", 2);
__decorateClass$1([
  Validate$1(TEXT_WRAP$1)
], Caption$1.prototype, "wrapping", 2);

// packages/ag-charts-community/src/chart/axis/axisTitle.ts
var AxisTitle$1 = class {
  constructor() {
    this.enabled = false;
    this.spacing = Caption$1.SMALL_PADDING;
    this.fontSize = 10;
    this.fontFamily = "sans-serif";
    this.wrapping = "always";
  }
};
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], AxisTitle$1.prototype, "enabled", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], AxisTitle$1.prototype, "text", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], AxisTitle$1.prototype, "spacing", 2);
__decorateClass$1([
  Validate$1(FONT_STYLE$1, { optional: true })
], AxisTitle$1.prototype, "fontStyle", 2);
__decorateClass$1([
  Validate$1(FONT_WEIGHT$1, { optional: true })
], AxisTitle$1.prototype, "fontWeight", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], AxisTitle$1.prototype, "fontSize", 2);
__decorateClass$1([
  Validate$1(STRING$1)
], AxisTitle$1.prototype, "fontFamily", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true })
], AxisTitle$1.prototype, "color", 2);
__decorateClass$1([
  Validate$1(TEXT_WRAP$1)
], AxisTitle$1.prototype, "wrapping", 2);
__decorateClass$1([
  Validate$1(FUNCTION$1, { optional: true })
], AxisTitle$1.prototype, "formatter", 2);

// packages/ag-charts-community/src/chart/chartOptions.ts
var JSON_APPLY_PLUGINS$1 = {
  constructors: {},
  constructedArrays: /* @__PURE__ */ new WeakMap()
};
function assignJsonApplyConstructedArray$1(array, ctor) {
  var _a;
  (_a = JSON_APPLY_PLUGINS$1.constructedArrays) == null ? void 0 : _a.set(array, ctor);
}
var JSON_APPLY_OPTIONS = {
  constructors: {
    "axes[].title": AxisTitle$1
  },
  allowedTypes: {
    "legend.pagination.marker.shape": ["primitive", "function"],
    "axis[].tick.count": ["primitive", "class-instance"]
  }
};
function getJsonApplyOptions(ctx) {
  class CaptionWithContext extends Caption$1 {
    constructor() {
      super();
      this.registerInteraction(ctx);
    }
  }
  return {
    constructors: __spreadValues$1(__spreadProps$1(__spreadValues$1({}, JSON_APPLY_OPTIONS.constructors), {
      title: CaptionWithContext,
      subtitle: CaptionWithContext,
      footnote: CaptionWithContext
    }), JSON_APPLY_PLUGINS$1.constructors),
    constructedArrays: JSON_APPLY_PLUGINS$1.constructedArrays,
    allowedTypes: __spreadValues$1({}, JSON_APPLY_OPTIONS.allowedTypes)
  };
}

// packages/ag-charts-community/src/scale/continuousScale.ts
var _ContinuousScale$1 = class _ContinuousScale {
  constructor(domain, range3) {
    this.invalid = true;
    this.nice = false;
    this.interval = void 0;
    this.tickCount = _ContinuousScale.defaultTickCount;
    this.minTickCount = 0;
    this.maxTickCount = Infinity;
    this.niceDomain = [];
    this.defaultClampMode = "raw";
    this.domain = domain;
    this.range = range3;
  }
  static is(value) {
    return value instanceof _ContinuousScale;
  }
  transform(x) {
    return x;
  }
  transformInvert(x) {
    return x;
  }
  calcBandwidth(smallestInterval = 1) {
    const domain = this.getDomain();
    const maxRange = Math.max(...this.range);
    const intervals = Math.abs(domain[1] - domain[0]) / smallestInterval + 1;
    const maxBands = Math.floor(maxRange);
    const bands = Math.min(intervals, maxBands);
    return maxRange / Math.max(1, bands);
  }
  fromDomain(d) {
    if (typeof d === "number") {
      return d;
    } else if (d instanceof Date) {
      return d.getTime();
    }
    return NaN;
  }
  getDomain() {
    if (this.nice) {
      this.refresh();
      if (this.niceDomain.length) {
        return this.niceDomain;
      }
    }
    return this.domain;
  }
  convert(x, opts) {
    var _a;
    const clampMode = (_a = opts == null ? void 0 : opts.clampMode) != null ? _a : this.defaultClampMode;
    if (!this.domain || this.domain.length < 2) {
      return NaN;
    }
    this.refresh();
    const domain = this.getDomain().map((d) => this.transform(d));
    const [d0, d1] = domain;
    const { range: range3 } = this;
    const [r0, r1] = range3;
    x = this.transform(x);
    if (clampMode === "clamped") {
      const start = Math.min(this.fromDomain(d0), this.fromDomain(d1));
      const stop = Math.max(this.fromDomain(d0), this.fromDomain(d1));
      if (this.fromDomain(x) < start) {
        return r0;
      } else if (this.fromDomain(x) > stop) {
        return r1;
      }
    }
    if (d0 === d1) {
      return (r0 + r1) / 2;
    } else if (x === d0) {
      return r0;
    } else if (x === d1) {
      return r1;
    }
    return r0 + (this.fromDomain(x) - this.fromDomain(d0)) / (this.fromDomain(d1) - this.fromDomain(d0)) * (r1 - r0);
  }
  invert(x) {
    this.refresh();
    const domain = this.getDomain().map((d2) => this.transform(d2));
    const [d0, d1] = domain;
    const { range: range3 } = this;
    const [r0, r1] = range3;
    const isReversed = r0 > r1;
    const rMin = isReversed ? r1 : r0;
    const rMax = isReversed ? r0 : r1;
    let d;
    if (x < rMin) {
      return isReversed ? d1 : d0;
    } else if (x > rMax) {
      return isReversed ? d0 : d1;
    } else if (r0 === r1) {
      d = this.toDomain((this.fromDomain(d0) + this.fromDomain(d1)) / 2);
    } else {
      d = this.toDomain(
        this.fromDomain(d0) + (x - r0) / (r1 - r0) * (this.fromDomain(d1) - this.fromDomain(d0))
      );
    }
    return this.transformInvert(d);
  }
  refresh() {
    if (!this.invalid)
      return;
    this.invalid = false;
    this.update();
    if (this.invalid) {
      Logger$1.warnOnce("Expected update to not invalidate scale");
    }
  }
  getPixelRange() {
    const range3 = this.range.slice().sort((a, b) => a - b);
    return range3[1] - range3[0];
  }
  isDenseInterval({
    start,
    stop,
    interval,
    count: count2
  }) {
    const domain = stop - start;
    const availableRange = this.getPixelRange();
    const step = typeof interval === "number" ? interval : 1;
    count2 != null ? count2 : count2 = domain / step;
    if (count2 >= availableRange) {
      Logger$1.warn(
        `the configured interval results in more than 1 item per pixel, ignoring. Supply a larger interval or omit this configuration`
      );
      return true;
    }
    return false;
  }
};
_ContinuousScale$1.defaultTickCount = 5;
_ContinuousScale$1.defaultMaxTickCount = 6;
__decorateClass$1([
  Invalidating$1
], _ContinuousScale$1.prototype, "domain", 2);
__decorateClass$1([
  Invalidating$1
], _ContinuousScale$1.prototype, "range", 2);
__decorateClass$1([
  Invalidating$1
], _ContinuousScale$1.prototype, "nice", 2);
__decorateClass$1([
  Invalidating$1
], _ContinuousScale$1.prototype, "interval", 2);
__decorateClass$1([
  Invalidating$1
], _ContinuousScale$1.prototype, "tickCount", 2);
__decorateClass$1([
  Invalidating$1
], _ContinuousScale$1.prototype, "minTickCount", 2);
__decorateClass$1([
  Invalidating$1
], _ContinuousScale$1.prototype, "maxTickCount", 2);
var ContinuousScale$1 = _ContinuousScale$1;

// packages/ag-charts-community/src/util/compare.ts
function ascendingStringNumberUndefined$1(a, b) {
  let diff2 = 0;
  if (typeof a === "number" && typeof b === "number") {
    diff2 = a - b;
  } else if (typeof a === "string" && typeof b === "string") {
    diff2 = a.localeCompare(b);
  } else if (a == null && b == null) ; else if (a == null) {
    diff2 = -1;
  } else if (b == null) {
    diff2 = 1;
  } else {
    diff2 = String(a).localeCompare(String(b));
  }
  return diff2;
}
function compoundAscending$1(a, b, comparator) {
  const toLiteral = (v) => {
    if (typeof v === "function") {
      return v();
    }
    return v;
  };
  for (const idx in a) {
    const diff2 = comparator(toLiteral(a[idx]), toLiteral(b[idx]));
    if (diff2 !== 0) {
      return diff2;
    }
  }
  return 0;
}

// packages/ag-charts-community/src/scene/group.ts
var _Group$1 = class _Group extends Node$1 {
  constructor(opts) {
    var _a;
    super({ isVirtual: opts == null ? void 0 : opts.isVirtual });
    this.opts = opts;
    this.opacity = 1;
    this.lastBBox = void 0;
    const { zIndex, zIndexSubOrder } = opts != null ? opts : {};
    this.isContainerNode = true;
    if (zIndex !== void 0) {
      this.zIndex = zIndex;
    }
    if (zIndexSubOrder !== void 0) {
      this.zIndexSubOrder = zIndexSubOrder;
    }
    this.name = (_a = this.opts) == null ? void 0 : _a.name;
  }
  zIndexChanged() {
    var _a;
    if (this.layer) {
      (_a = this._layerManager) == null ? void 0 : _a.moveLayer(this.layer, this.zIndex, this.zIndexSubOrder);
    }
  }
  isLayer() {
    return this.layer != null;
  }
  _setLayerManager(scene) {
    var _a, _b;
    if (this._layerManager && this.layer) {
      this._layerManager.removeLayer(this.layer);
      this.layer = void 0;
    }
    if (this.layer) {
      throw new Error("AG Charts - unable to deregister scene rendering layer!");
    }
    super._setLayerManager(scene);
    if (scene && ((_a = this.opts) == null ? void 0 : _a.layer)) {
      const { zIndex, zIndexSubOrder, name } = (_b = this.opts) != null ? _b : {};
      const getComputedOpacity = () => this.getComputedOpacity();
      const getVisibility = () => this.getVisibility();
      this.layer = scene.addLayer({
        zIndex,
        zIndexSubOrder,
        name,
        getComputedOpacity,
        getVisibility
      });
    }
  }
  getComputedOpacity() {
    let opacity = 1;
    let node = this;
    do {
      if (node instanceof _Group) {
        opacity *= node.opacity;
      }
    } while (node = node.parent);
    return opacity;
  }
  getVisibility() {
    let node = this;
    let visible = this.visible;
    while (node = node.parent) {
      if (node.visible) {
        continue;
      }
      visible = node.visible;
    }
    return visible;
  }
  visibilityChanged() {
    if (this.layer) {
      this.layer.enabled = this.visible;
    }
  }
  markDirty(source, type = 1 /* TRIVIAL */) {
    if (this.isVirtual) {
      super.markDirty(source, type);
      return;
    }
    let parentType = type;
    if (type < 2 /* MINOR */ || this.layer != null) {
      parentType = 1 /* TRIVIAL */;
    }
    super.markDirty(source, type, parentType);
  }
  // We consider a group to be boundless, thus any point belongs to it.
  containsPoint(_x, _y) {
    return true;
  }
  computeBBox() {
    this.computeTransformMatrix();
    return _Group.computeBBox(this.children);
  }
  computeTransformedBBox() {
    return this.computeBBox();
  }
  render(renderCtx) {
    var _a, _b;
    const { opts: { name = void 0 } = {}, _debug: debug2 = () => {
    } } = this;
    const { dirty, dirtyZIndex, layer, children, clipRect, dirtyTransform } = this;
    let { ctx, forceRender, clipBBox } = renderCtx;
    const { resized, stats } = renderCtx;
    const canvasCtxTransform = ctx.getTransform();
    const isDirty = dirty >= 2 /* MINOR */ || dirtyZIndex || resized;
    let isChildDirty = isDirty;
    let isChildLayerDirty = false;
    for (const child of children) {
      isChildDirty || (isChildDirty = child.layerManager == null && child.dirty >= 1 /* TRIVIAL */);
      isChildLayerDirty || (isChildLayerDirty = child.layerManager != null && child.dirty >= 1 /* TRIVIAL */);
      if (isChildDirty) {
        break;
      }
    }
    if (name) {
      debug2({ name, group: this, isDirty, isChildDirty, dirtyTransform, renderCtx, forceRender });
    }
    if (dirtyTransform) {
      forceRender = "dirtyTransform";
    } else if (layer) {
      const currentBBox = this.computeBBox();
      if (this.lastBBox === void 0 || !this.lastBBox.equals(currentBBox)) {
        forceRender = "dirtyTransform";
        this.lastBBox = currentBBox;
      }
    }
    if (!isDirty && !isChildDirty && !isChildLayerDirty && !forceRender) {
      if (name && stats) {
        debug2({ name, result: "skipping", renderCtx, counts: this.nodeCount, group: this });
      }
      if (layer && stats) {
        stats.layersSkipped++;
        stats.nodesSkipped += this.nodeCount.count;
      }
      this.markClean({ recursive: false });
      return;
    }
    const groupVisible = this.visible;
    if (layer) {
      ctx = layer.context;
      ctx.save();
      ctx.resetTransform();
      if (forceRender !== "dirtyTransform") {
        forceRender = isChildDirty || dirtyZIndex;
      }
      if (forceRender)
        layer.clear();
      if (clipBBox) {
        const { width, height, x, y } = clipBBox;
        debug2(() => ({
          name,
          clipBBox,
          ctxTransform: ctx.getTransform(),
          renderCtx,
          group: this
        }));
        this.clipCtx(ctx, x, y, width, height);
      }
      ctx.setTransform(canvasCtxTransform);
    } else {
      ctx.globalAlpha *= this.opacity;
    }
    this.computeTransformMatrix();
    this.matrix.toContext(ctx);
    if (clipRect) {
      const { x, y, width, height } = clipRect;
      ctx.save();
      debug2(() => ({ name, clipRect, ctxTransform: ctx.getTransform(), renderCtx, group: this }));
      this.clipCtx(ctx, x, y, width, height);
      clipBBox = this.matrix.transformBBox(clipRect);
    }
    const hasVirtualChildren = this.hasVirtualChildren();
    if (dirtyZIndex) {
      this.sortChildren(children);
      if (forceRender !== "dirtyTransform")
        forceRender = true;
    } else if (hasVirtualChildren) {
      this.sortChildren(children);
    }
    const renderContextChanged = forceRender !== renderCtx.forceRender || clipBBox !== renderCtx.clipBBox || ctx !== renderCtx.ctx;
    const childRenderContext = renderContextChanged ? __spreadProps$1(__spreadValues$1({}, renderCtx), { ctx, forceRender, clipBBox }) : renderCtx;
    let skipped = 0;
    for (const child of children) {
      if (!child.visible || !groupVisible) {
        child.markClean();
        if (stats)
          skipped += child.nodeCount.count;
        continue;
      }
      if (!forceRender && child.dirty === 0 /* NONE */) {
        if (stats)
          skipped += child.nodeCount.count;
        continue;
      }
      ctx.save();
      child.render(childRenderContext);
      ctx.restore();
    }
    if (stats)
      stats.nodesSkipped += skipped;
    super.render(renderCtx);
    if (clipRect) {
      ctx.restore();
    }
    if (hasVirtualChildren) {
      for (const child of this.virtualChildren) {
        child.markClean({ recursive: "virtual" });
      }
    }
    if (layer) {
      if (stats)
        stats.layersRendered++;
      ctx.restore();
      if (forceRender)
        layer.snapshot();
      (_b = (_a = layer.context).verifyDepthZero) == null ? void 0 : _b.call(_a);
    }
    if (name && stats) {
      debug2({ name, result: "rendered", skipped, renderCtx, counts: this.nodeCount, group: this });
    }
  }
  sortChildren(children) {
    this.dirtyZIndex = false;
    children.sort((a, b) => {
      var _a, _b;
      return compoundAscending$1(
        [a.zIndex, ...(_a = a.zIndexSubOrder) != null ? _a : [void 0, void 0], a.serialNumber],
        [b.zIndex, ...(_b = b.zIndexSubOrder) != null ? _b : [void 0, void 0], b.serialNumber],
        ascendingStringNumberUndefined$1
      );
    });
  }
  clipCtx(ctx, x, y, width, height) {
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + width, y);
    ctx.lineTo(x + width, y + height);
    ctx.lineTo(x, y + height);
    ctx.closePath();
    ctx.clip();
  }
  static computeBBox(nodes) {
    let left = Infinity;
    let right = -Infinity;
    let top = Infinity;
    let bottom = -Infinity;
    nodes.forEach((n) => {
      if (!n.visible) {
        return;
      }
      const bbox = n.computeTransformedBBox();
      if (!bbox) {
        return;
      }
      const x = bbox.x;
      const y = bbox.y;
      if (x < left) {
        left = x;
      }
      if (y < top) {
        top = y;
      }
      if (x + bbox.width > right) {
        right = x + bbox.width;
      }
      if (y + bbox.height > bottom) {
        bottom = y + bbox.height;
      }
    });
    return new BBox$1(left, top, right - left, bottom - top);
  }
  /**
   * Transforms bbox given in the canvas coordinate space to bbox in this group's coordinate space and
   * sets this group's clipRect to the transformed bbox.
   * @param bbox clipRect bbox in the canvas coordinate space.
   */
  setClipRectInGroupCoordinateSpace(bbox) {
    this.clipRect = bbox ? this.transformBBox(bbox) : void 0;
  }
};
_Group$1.className = "Group";
__decorateClass$1([
  SceneChangeDetection$1({
    redraw: 3 /* MAJOR */,
    convertor: (v) => Math.min(1, Math.max(0, v))
  })
], _Group$1.prototype, "opacity", 2);
var Group$1 = _Group$1;

// packages/ag-charts-community/src/scene/shape/range.ts
var Range$1 = class extends Shape$1 {
  constructor(opts = {}) {
    super(opts);
    this.x1 = 0;
    this.y1 = 0;
    this.x2 = 0;
    this.y2 = 0;
    this.startLine = false;
    this.endLine = false;
    this.isRange = false;
    this.restoreOwnStyles();
  }
  computeBBox() {
    return new BBox$1(this.x1, this.y1, this.x2 - this.x1, this.y2 - this.y1);
  }
  isPointInPath(_x, _y) {
    return false;
  }
  render(renderCtx) {
    var _a;
    const { ctx, forceRender, stats } = renderCtx;
    if (this.dirty === 0 /* NONE */ && !forceRender) {
      if (stats)
        stats.nodesSkipped += this.nodeCount.count;
      return;
    }
    this.computeTransformMatrix();
    this.matrix.toContext(ctx);
    let { x1, y1, x2, y2 } = this;
    x1 = this.align(x1);
    y1 = this.align(y1);
    x2 = this.align(x2);
    y2 = this.align(y2);
    const { fill, opacity, isRange } = this;
    const fillActive = !!(isRange && fill);
    if (fillActive) {
      const { fillOpacity } = this;
      ctx.fillStyle = fill;
      ctx.globalAlpha = opacity * fillOpacity;
      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y1);
      ctx.lineTo(x2, y2);
      ctx.lineTo(x1, y2);
      ctx.closePath();
      ctx.fill();
    }
    const { stroke, strokeWidth, startLine, endLine } = this;
    const strokeActive = !!((startLine || endLine) && stroke && strokeWidth);
    if (strokeActive) {
      const { strokeOpacity, lineDash, lineDashOffset, lineCap, lineJoin } = this;
      ctx.strokeStyle = stroke;
      ctx.globalAlpha = opacity * strokeOpacity;
      ctx.lineWidth = strokeWidth;
      if (lineDash) {
        ctx.setLineDash(lineDash);
      }
      if (lineDashOffset) {
        ctx.lineDashOffset = lineDashOffset;
      }
      if (lineCap) {
        ctx.lineCap = lineCap;
      }
      if (lineJoin) {
        ctx.lineJoin = lineJoin;
      }
      ctx.beginPath();
      if (startLine) {
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y1);
      }
      if (endLine) {
        ctx.moveTo(x2, y2);
        ctx.lineTo(x1, y2);
      }
      ctx.stroke();
    }
    (_a = this.fillShadow) == null ? void 0 : _a.markClean();
    super.render(renderCtx);
  }
};
Range$1.className = "Range";
Range$1.defaultStyles = __spreadProps$1(__spreadValues$1({}, Shape$1.defaultStyles), {
  strokeWidth: 1
});
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */ })
], Range$1.prototype, "x1", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */ })
], Range$1.prototype, "y1", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */ })
], Range$1.prototype, "x2", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */ })
], Range$1.prototype, "y2", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */ })
], Range$1.prototype, "startLine", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */ })
], Range$1.prototype, "endLine", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 2 /* MINOR */ })
], Range$1.prototype, "isRange", 2);

// packages/ag-charts-community/src/chart/label.ts
var Label$2 = class extends BaseProperties$1 {
  constructor() {
    super(...arguments);
    this.enabled = true;
    this.color = "#464646";
    this.fontSize = 12;
    this.fontFamily = "Verdana, sans-serif";
  }
  getFont() {
    return getFont$1(this);
  }
};
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], Label$2.prototype, "enabled", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1)
], Label$2.prototype, "color", 2);
__decorateClass$1([
  Validate$1(FONT_STYLE$1, { optional: true })
], Label$2.prototype, "fontStyle", 2);
__decorateClass$1([
  Validate$1(FONT_WEIGHT$1, { optional: true })
], Label$2.prototype, "fontWeight", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], Label$2.prototype, "fontSize", 2);
__decorateClass$1([
  Validate$1(STRING$1)
], Label$2.prototype, "fontFamily", 2);
__decorateClass$1([
  Validate$1(FUNCTION$1, { optional: true })
], Label$2.prototype, "formatter", 2);
function calculateLabelRotation$1(opts) {
  const { parallelFlipRotation = 0, regularFlipRotation = 0 } = opts;
  const configuredRotation = opts.rotation ? normalizeAngle360$1(toRadians$2(opts.rotation)) : 0;
  const parallelFlipFlag = !configuredRotation && parallelFlipRotation >= 0 && parallelFlipRotation <= Math.PI ? -1 : 1;
  const regularFlipFlag = !configuredRotation && regularFlipRotation >= 0 && regularFlipRotation <= Math.PI ? -1 : 1;
  let defaultRotation = 0;
  if (opts.parallel) {
    defaultRotation = parallelFlipFlag * Math.PI / 2;
  } else if (regularFlipFlag === -1) {
    defaultRotation = Math.PI;
  }
  return { configuredRotation, defaultRotation, parallelFlipFlag, regularFlipFlag };
}
function getLabelSpacing$1(minSpacing, rotated) {
  if (!isNaN(minSpacing)) {
    return minSpacing;
  }
  return rotated ? 0 : 10;
}
function getTextBaseline$1(parallel, labelRotation, sideFlag, parallelFlipFlag) {
  if (parallel && !labelRotation) {
    return sideFlag * parallelFlipFlag === -1 ? "hanging" : "bottom";
  }
  return "middle";
}
function getTextAlign$1(parallel, labelRotation, labelAutoRotation, sideFlag, regularFlipFlag) {
  const labelRotated = labelRotation > 0 && labelRotation <= Math.PI;
  const labelAutoRotated = labelAutoRotation > 0 && labelAutoRotation <= Math.PI;
  const alignFlag = labelRotated || labelAutoRotated ? -1 : 1;
  if (parallel) {
    if (labelRotation || labelAutoRotation) {
      if (sideFlag * alignFlag === -1) {
        return "end";
      }
    } else {
      return "center";
    }
  } else if (sideFlag * regularFlipFlag === -1) {
    return "end";
  }
  return "start";
}
function calculateLabelBBox$1(text, bbox, labelX, labelY, labelMatrix) {
  const { width, height } = bbox;
  const translatedBBox = new BBox$1(labelX, labelY, 0, 0);
  labelMatrix.transformBBox(translatedBBox, bbox);
  const { x = 0, y = 0 } = bbox;
  bbox.width = width;
  bbox.height = height;
  return {
    point: { x, y, size: 0 },
    label: { width, height, text }
  };
}

// packages/ag-charts-community/src/chart/layers.ts
var Layers$1 = /* @__PURE__ */ ((Layers2) => {
  Layers2[Layers2["SERIES_BACKGROUND_ZINDEX"] = 0] = "SERIES_BACKGROUND_ZINDEX";
  Layers2[Layers2["AXIS_GRID_ZINDEX"] = 1] = "AXIS_GRID_ZINDEX";
  Layers2[Layers2["AXIS_ZINDEX"] = 2] = "AXIS_ZINDEX";
  Layers2[Layers2["SERIES_CROSSLINE_RANGE_ZINDEX"] = 3] = "SERIES_CROSSLINE_RANGE_ZINDEX";
  Layers2[Layers2["SERIES_LAYER_ZINDEX"] = 4] = "SERIES_LAYER_ZINDEX";
  Layers2[Layers2["AXIS_FOREGROUND_ZINDEX"] = 5] = "AXIS_FOREGROUND_ZINDEX";
  Layers2[Layers2["SERIES_CROSSHAIR_ZINDEX"] = 6] = "SERIES_CROSSHAIR_ZINDEX";
  Layers2[Layers2["SERIES_LABEL_ZINDEX"] = 7] = "SERIES_LABEL_ZINDEX";
  Layers2[Layers2["SERIES_CROSSLINE_LINE_ZINDEX"] = 8] = "SERIES_CROSSLINE_LINE_ZINDEX";
  Layers2[Layers2["LEGEND_ZINDEX"] = 9] = "LEGEND_ZINDEX";
  return Layers2;
})(Layers$1 || {});

// packages/ag-charts-community/src/integrated-charts-scene.ts
var integrated_charts_scene_exports$1 = {};
__export$1(integrated_charts_scene_exports$1, {
  Arc: () => Arc$1,
  BBox: () => BBox$1,
  BandScale: () => BandScale$6,
  Caption: () => Caption$1,
  Circle: () => Circle$1,
  ContinuousScale: () => ContinuousScale$1,
  Diamond: () => Diamond$1,
  DropShadow: () => DropShadow$1,
  Group: () => Group$1,
  HdpiCanvas: () => HdpiCanvas$1,
  Image: () => Image$1,
  Label: () => Label$2,
  Line: () => Line$1,
  LinearGradientFill: () => LinearGradientFill$1,
  LinearScale: () => LinearScale$2,
  Marker: () => Marker$1,
  Node: () => Node$1,
  Path: () => Path$1,
  Path2D: () => Path2D$1,
  PointerEvents: () => PointerEvents$1,
  Rect: () => Rect$1,
  RedrawType: () => RedrawType$1,
  Scene: () => Scene$1,
  SceneChangeDetection: () => SceneChangeDetection$1,
  ScenePathChangeDetection: () => ScenePathChangeDetection$1,
  Sector: () => Sector$1,
  Selection: () => Selection$1,
  Shape: () => Shape$1,
  Square: () => Square$1,
  Text: () => Text$1,
  Tooltip: () => Tooltip$1,
  Triangle: () => Triangle$1,
  easing: () => easing_exports$1,
  getFont: () => getFont$1,
  getMarker: () => getMarker$2,
  motion: () => motion$1,
  nearestSquared: () => nearestSquared$1,
  nearestSquaredInContainer: () => nearestSquaredInContainer$1,
  toRadians: () => toRadians$2,
  toTooltipHtml: () => toTooltipHtml$2
});

// packages/ag-charts-community/src/motion/fromToMotion.ts
var fromToMotion_exports$1 = {};
__export$1(fromToMotion_exports$1, {
  FROM_TO_MIXINS: () => FROM_TO_MIXINS$1,
  NODE_UPDATE_PHASES: () => NODE_UPDATE_PHASES$1,
  fromToMotion: () => fromToMotion$1,
  staticFromToMotion: () => staticFromToMotion$1
});

// packages/ag-charts-community/src/util/zip.ts
function zipObject$1(keys, values) {
  const zipped = {};
  if (Array.isArray(values)) {
    for (let i = 0; i < keys.length; i++) {
      zipped[`${keys[i]}`] = values[i];
    }
  } else {
    for (let i = 0; i < keys.length; i++) {
      zipped[`${keys[i]}`] = values;
    }
  }
  return zipped;
}

// packages/ag-charts-community/src/interpolate.ts
function interpolateNumber$1(a, b) {
  return (d) => Number(a) * (1 - d) + Number(b) * d;
}
function interpolateColor$1(a, b) {
  if (typeof a === "string") {
    try {
      a = Color$1.fromString(a);
    } catch (e) {
      a = Color$1.fromArray([0, 0, 0]);
    }
  }
  if (typeof b === "string") {
    try {
      b = Color$1.fromString(b);
    } catch (e) {
      b = Color$1.fromArray([0, 0, 0]);
    }
  }
  const red = interpolateNumber$1(a.r, b.r);
  const green = interpolateNumber$1(a.g, b.g);
  const blue = interpolateNumber$1(a.b, b.b);
  const alpha = interpolateNumber$1(a.a, b.a);
  return (d) => Color$1.fromArray([red(d), green(d), blue(d), alpha(d)]).toRgbaString();
}

// packages/ag-charts-community/src/motion/easing.ts
var easing_exports$1 = {};
__export$1(easing_exports$1, {
  easeIn: () => easeIn$1,
  easeInOut: () => easeInOut$1,
  easeInOutQuad: () => easeInOutQuad$1,
  easeInQuad: () => easeInQuad$1,
  easeOut: () => easeOut$1,
  easeOutQuad: () => easeOutQuad$1,
  inverseEaseOut: () => inverseEaseOut$1,
  linear: () => linear$1
});
var linear$1 = (n) => n;
var easeIn$1 = (n) => 1 - Math.cos(n * Math.PI / 2);
var easeOut$1 = (n) => Math.sin(n * Math.PI / 2);
var easeInOut$1 = (n) => -(Math.cos(n * Math.PI) - 1) / 2;
var easeInQuad$1 = (n) => n * n;
var easeOutQuad$1 = (n) => 1 - __pow$1(1 - n, 2);
var easeInOutQuad$1 = (n) => n < 0.5 ? 2 * n * n : 1 - __pow$1(-2 * n + 2, 2) / 2;
var inverseEaseOut$1 = (x) => 2 * Math.asin(x) / Math.PI;

// packages/ag-charts-community/src/motion/animation.ts
var QUICK_TRANSITION$1 = 0.2;
var INITIAL_LOAD$1 = {
  animationDuration: 1,
  animationDelay: 0
};
var REMOVE_PHASE$1 = {
  animationDuration: 0.25,
  animationDelay: 0
};
var UPDATE_PHASE$1 = {
  animationDuration: 0.5,
  animationDelay: 0.25
};
var ADD_PHASE$1 = {
  animationDuration: 0.25,
  animationDelay: 0.75
};
var LABEL_PHASE$1 = {
  animationDuration: QUICK_TRANSITION$1,
  animationDelay: 1
};
var RepeatType$1 = /* @__PURE__ */ ((RepeatType2) => {
  RepeatType2["Loop"] = "loop";
  RepeatType2["Reverse"] = "reverse";
  return RepeatType2;
})(RepeatType$1 || {});
function isNodeArray$1(array) {
  return array.every((n) => n instanceof Node$1);
}
function deconstructSelectionsOrNodes$1(selectionsOrNodes) {
  return isNodeArray$1(selectionsOrNodes) ? { nodes: selectionsOrNodes, selections: [] } : { nodes: [], selections: selectionsOrNodes };
}
var Animation$1 = class {
  constructor(opts) {
    this.elapsed = 0;
    this.iteration = 0;
    this.isPlaying = false;
    this.isReverse = false;
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
    this.id = opts.id;
    this.groupId = opts.groupId;
    this.autoplay = (_a = opts.autoplay) != null ? _a : true;
    this.delay = (_b = opts.delay) != null ? _b : 0;
    this.duration = (_c = opts.duration) != null ? _c : 1e3;
    this.ease = (_d = opts.ease) != null ? _d : linear$1;
    this.repeat = (_e = opts.repeat) != null ? _e : 0;
    this.repeatType = (_f = opts.repeatType) != null ? _f : "loop" /* Loop */;
    this.onComplete = opts.onComplete;
    this.onPlay = opts.onPlay;
    this.onStop = opts.onStop;
    this.onRepeat = opts.onRepeat;
    this.onUpdate = opts.onUpdate;
    this.interpolate = this.createInterpolator(opts.from, opts.to);
    if (opts.skip === true) {
      (_g = this.onUpdate) == null ? void 0 : _g.call(this, opts.to, false, this);
      (_h = this.onStop) == null ? void 0 : _h.call(this, this);
      (_i = this.onComplete) == null ? void 0 : _i.call(this, this);
    } else if (this.autoplay) {
      this.play();
      (_j = this.onUpdate) == null ? void 0 : _j.call(this, opts.from, true, this);
    }
  }
  play() {
    var _a;
    if (!this.isPlaying) {
      this.isPlaying = true;
      (_a = this.onPlay) == null ? void 0 : _a.call(this, this);
    }
    return this;
  }
  pause() {
    if (this.isPlaying) {
      this.isPlaying = false;
    }
    return this;
  }
  stop() {
    var _a;
    if (this.isPlaying) {
      this.isPlaying = false;
      (_a = this.onStop) == null ? void 0 : _a.call(this, this);
    }
    return this;
  }
  reset(opts) {
    const deltaState = this.interpolate(this.isReverse ? 1 - this.delta : this.delta);
    this.interpolate = this.createInterpolator(deltaState, opts.to);
    this.elapsed = 0;
    this.iteration = 0;
    if (typeof opts.delay === "number") {
      this.delay = opts.delay;
    }
    if (typeof opts.duration === "number") {
      this.duration = opts.duration;
    }
    if (typeof opts.ease === "function") {
      this.ease = opts.ease;
    }
    return this;
  }
  update(time) {
    var _a, _b, _c;
    this.elapsed += time;
    if (this.elapsed <= this.delay) {
      return this;
    }
    const value = this.interpolate(this.isReverse ? 1 - this.delta : this.delta);
    (_a = this.onUpdate) == null ? void 0 : _a.call(this, value, false, this);
    if (this.elapsed - this.delay >= this.duration) {
      if (this.iteration < this.repeat) {
        this.iteration++;
        this.elapsed = (this.elapsed - this.delay) % this.duration + this.delay;
        if (this.repeatType === "reverse" /* Reverse */) {
          this.isReverse = !this.isReverse;
        }
        (_b = this.onRepeat) == null ? void 0 : _b.call(this, this);
      } else {
        this.stop();
        (_c = this.onComplete) == null ? void 0 : _c.call(this, this);
      }
    }
    return this;
  }
  get delta() {
    return this.ease(clamp2$1(0, (this.elapsed - this.delay) / this.duration, 1));
  }
  createInterpolator(from, to) {
    if (typeof to !== "object") {
      return this.interpolateValue(from, to);
    }
    const interpolatorEntries = [];
    for (const key in to) {
      const interpolator = this.interpolateValue(from[key], to[key]);
      if (interpolator != null) {
        interpolatorEntries.push([key, interpolator]);
      }
    }
    return (d) => {
      const result = {};
      for (const [key, interpolator] of interpolatorEntries) {
        result[key] = interpolator(d);
      }
      return result;
    };
  }
  interpolateValue(a, b) {
    if (a === void 0 || b === void 0) {
      return void 0;
    }
    try {
      switch (typeof a) {
        case "number":
          return interpolateNumber$1(a, b);
        case "string":
          return interpolateColor$1(a, b);
      }
    } catch (e) {
    }
    throw new Error(`Unable to interpolate values: ${a}, ${b}`);
  }
};

// packages/ag-charts-community/src/motion/fromToMotion.ts
var NODE_UPDATE_PHASES$1 = ["removed", "updated", "added"];
var FROM_TO_MIXINS$1 = {
  added: ADD_PHASE$1,
  updated: UPDATE_PHASE$1,
  removed: REMOVE_PHASE$1,
  unknown: INITIAL_LOAD$1
};
function fromToMotion$1(groupId, subId, animationManager, selectionsOrNodes, fns, getDatumId, diff2) {
  const { defaultDuration } = animationManager;
  const { fromFn, toFn, intermediateFn } = fns;
  const { nodes, selections } = deconstructSelectionsOrNodes$1(selectionsOrNodes);
  const ids = { added: {}, removed: {} };
  if (getDatumId && diff2) {
    ids.added = zipObject$1(diff2.added, true);
    ids.removed = zipObject$1(diff2.removed, true);
  }
  const processNodes = (liveNodes, nodes2) => {
    var _c, _d;
    let prevFromProps;
    let liveNodeIndex = 0;
    let nodeIndex = 0;
    for (const node of nodes2) {
      const isLive = liveNodes[liveNodeIndex] === node;
      const ctx = {
        last: nodeIndex >= nodes2.length - 1,
        lastLive: liveNodeIndex >= liveNodes.length - 1,
        prev: nodes2[nodeIndex - 1],
        prevFromProps,
        prevLive: liveNodes[liveNodeIndex - 1],
        next: nodes2[nodeIndex + 1],
        nextLive: liveNodes[liveNodeIndex + (isLive ? 1 : 0)]
      };
      const animationId = `${groupId}_${subId}_${node.id}`;
      animationManager.stopByAnimationId(animationId);
      let status = "unknown";
      if (!isLive) {
        status = "removed";
      } else if (getDatumId && diff2) {
        status = calculateStatus$1(node, node.datum, getDatumId, ids);
      }
      const _a = fromFn(node, node.datum, status, ctx), {
        animationDelay: delay,
        animationDuration: duration,
        start = {},
        finish = {}
      } = _a, from = __objRest$1(_a, [
        "animationDelay",
        "animationDuration",
        "start",
        "finish"
      ]);
      const _b = toFn(node, node.datum, status, ctx), {
        animationDelay: toDelay,
        animationDuration: toDuration,
        start: toStart = {},
        finish: toFinish = {}
      } = _b, to = __objRest$1(_b, [
        "animationDelay",
        "animationDuration",
        "start",
        "finish"
      ]);
      animationManager.animate({
        id: animationId,
        groupId,
        from,
        to,
        ease: easeOut$1,
        onPlay: () => {
          node.setProperties(__spreadValues$1(__spreadValues$1({}, start), toStart));
        },
        onUpdate(props) {
          node.setProperties(props);
          if (intermediateFn) {
            node.setProperties(intermediateFn(node, node.datum, status, ctx));
          }
        },
        onStop: () => {
          node.setProperties(__spreadValues$1(__spreadValues$1(__spreadValues$1({}, to), finish), toFinish));
        },
        duration: ((_c = duration != null ? duration : toDuration) != null ? _c : 1) * defaultDuration,
        delay: ((_d = delay != null ? delay : toDelay) != null ? _d : 0) * defaultDuration
      });
      if (isLive) {
        liveNodeIndex++;
      }
      nodeIndex++;
      prevFromProps = from;
    }
  };
  let selectionIndex = 0;
  for (const selection of selections) {
    const nodes2 = selection.nodes();
    const liveNodes = nodes2.filter((n) => !selection.isGarbage(n));
    processNodes(liveNodes, nodes2);
    animationManager.animate({
      id: `${groupId}_${subId}_selection_${selectionIndex}`,
      groupId,
      from: 0,
      to: 1,
      ease: easeOut$1,
      onStop() {
        selection.cleanup();
      }
    });
    selectionIndex++;
  }
  processNodes(nodes, nodes);
}
function staticFromToMotion$1(groupId, subId, animationManager, selectionsOrNodes, from, to, extraOpts = {}) {
  const { nodes, selections } = deconstructSelectionsOrNodes$1(selectionsOrNodes);
  const { animationDelay = 0, animationDuration = 1, start = {}, finish = {} } = extraOpts;
  const { defaultDuration } = animationManager;
  animationManager.animate({
    id: `${groupId}_${subId}`,
    groupId,
    from,
    to,
    ease: easeOut$1,
    onPlay: () => {
      for (const node of nodes) {
        node.setProperties(start);
      }
      for (const selection of selections) {
        for (const node of selection.nodes()) {
          node.setProperties(start);
        }
      }
    },
    onUpdate(props) {
      for (const node of nodes) {
        node.setProperties(props);
      }
      for (const selection of selections) {
        for (const node of selection.nodes()) {
          node.setProperties(props);
        }
      }
    },
    onStop: () => {
      for (const node of nodes) {
        node.setProperties(__spreadValues$1(__spreadValues$1({}, to), finish));
      }
      for (const selection of selections) {
        for (const node of selection.nodes()) {
          node.setProperties(__spreadValues$1(__spreadValues$1({}, to), finish));
        }
      }
    },
    duration: animationDuration * defaultDuration,
    delay: animationDelay * defaultDuration
  });
}
function calculateStatus$1(node, datum, getDatumId, ids) {
  const id = getDatumId(node, datum);
  if (ids.added[id]) {
    return "added";
  } else if (ids.removed[id]) {
    return "removed";
  }
  return "updated";
}

// packages/ag-charts-community/src/motion/resetMotion.ts
var resetMotion_exports$1 = {};
__export$1(resetMotion_exports$1, {
  resetMotion: () => resetMotion$1
});
function resetMotion$1(selectionsOrNodes, propsFn) {
  const { nodes, selections } = deconstructSelectionsOrNodes$1(selectionsOrNodes);
  for (const selection of selections) {
    for (const node of selection.nodes()) {
      const from = propsFn(node, node.datum);
      node.setProperties(from);
    }
    selection.cleanup();
  }
  for (const node of nodes) {
    const from = propsFn(node, node.datum);
    node.setProperties(from);
  }
}

// packages/ag-charts-community/src/scene/dropShadow.ts
var DropShadow$1 = class extends BaseProperties$1 {
  constructor() {
    super(...arguments);
    this.enabled = true;
    this.color = "rgba(0, 0, 0, 0.5)";
    this.xOffset = 0;
    this.yOffset = 0;
    this.blur = 5;
  }
};
__decorateClass$1([
  Validate$1(BOOLEAN$1),
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], DropShadow$1.prototype, "enabled", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1),
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], DropShadow$1.prototype, "color", 2);
__decorateClass$1([
  Validate$1(NUMBER$1),
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], DropShadow$1.prototype, "xOffset", 2);
__decorateClass$1([
  Validate$1(NUMBER$1),
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], DropShadow$1.prototype, "yOffset", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1),
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], DropShadow$1.prototype, "blur", 2);

// packages/ag-charts-community/src/scene/canvas/hdpiOffscreenCanvas.ts
var HdpiOffscreenCanvas$1 = class {
  // The width/height attributes of the Canvas element default to
  // 300/150 according to w3.org.
  constructor({ width = 600, height = 300, overrideDevicePixelRatio }) {
    this.enabled = true;
    // `NaN` is deliberate here, so that overrides are always applied
    // and the `resetTransform` inside the `resize` method works in IE11.
    this._pixelRatio = NaN;
    this._width = 0;
    this._height = 0;
    this.canvas = new OffscreenCanvas(width, height);
    this.realContext = this.canvas.getContext("2d");
    this.imageSource = this.canvas.transferToImageBitmap();
    this.context = this.setPixelRatio(overrideDevicePixelRatio);
    this.resize(width, height);
  }
  static isSupported() {
    return typeof OffscreenCanvas !== "undefined" && OffscreenCanvas.prototype.transferToImageBitmap != null;
  }
  snapshot() {
    this.imageSource.close();
    this.imageSource = this.canvas.transferToImageBitmap();
  }
  destroy() {
    this.imageSource.close();
    this.canvas.width = 0;
    this.canvas.height = 0;
    this.context.clearRect(0, 0, 0, 0);
  }
  clear() {
    this.context.save();
    this.context.resetTransform();
    this.context.clearRect(0, 0, this.width, this.height);
    this.context.restore();
  }
  get pixelRatio() {
    return this._pixelRatio;
  }
  /**
   * Changes the pixel ratio of the Canvas element to the given value,
   * or uses the window.devicePixelRatio (default), then resizes the Canvas
   * element accordingly (default).
   */
  setPixelRatio(ratio) {
    let pixelRatio = ratio != null ? ratio : window.devicePixelRatio;
    if (hasConstrainedCanvasMemory$1()) {
      pixelRatio = 1;
    }
    this._pixelRatio = pixelRatio;
    return HdpiCanvas$1.overrideScale(this.realContext, pixelRatio);
  }
  get width() {
    return this._width;
  }
  get height() {
    return this._height;
  }
  resize(width, height) {
    if (!(width > 0 && height > 0)) {
      return;
    }
    const { canvas, context, pixelRatio } = this;
    canvas.width = Math.round(width * pixelRatio);
    canvas.height = Math.round(height * pixelRatio);
    context.resetTransform();
    this._width = width;
    this._height = height;
  }
};

// packages/ag-charts-community/src/scene/scene.ts
var advancedCompositeIdentifier$1 = "adv-composite";
var domCompositeIdentifier$1 = "dom-composite";
var Scene$1 = class {
  constructor(opts) {
    this.id = createId$2(this);
    this.layers = [];
    this._nextZIndex = 0;
    this._nextLayerId = 0;
    this._dirty = false;
    this._root = null;
    this.debug = Debug$1.create(true, "scene" /* SCENE */);
    var _a;
    const {
      document: document2,
      window: window2,
      mode = (_a = windowValue$1("agChartsSceneRenderModel")) != null ? _a : advancedCompositeIdentifier$1,
      width,
      height,
      overrideDevicePixelRatio = void 0
    } = opts;
    this.overrideDevicePixelRatio = overrideDevicePixelRatio;
    this.opts = { document: document2, window: window2, mode };
    this.canvas = new HdpiCanvas$1({ document: document2, window: window2, width, height, overrideDevicePixelRatio });
  }
  set container(value) {
    this.canvas.container = value;
  }
  get container() {
    return this.canvas.container;
  }
  download(fileName, fileFormat) {
    this.canvas.download(fileName, fileFormat);
  }
  getDataURL(type) {
    return this.canvas.getDataURL(type);
  }
  get width() {
    return this.pendingSize ? this.pendingSize[0] : this.canvas.width;
  }
  get height() {
    return this.pendingSize ? this.pendingSize[1] : this.canvas.height;
  }
  resize(width, height) {
    width = Math.round(width);
    height = Math.round(height);
    const lessThanZero = width <= 0 || height <= 0;
    const nan = isNaN(width) || isNaN(height);
    const unchanged = width === this.width && height === this.height;
    if (unchanged || nan || lessThanZero) {
      return false;
    }
    this.pendingSize = [width, height];
    this.markDirty();
    return true;
  }
  addLayer(opts) {
    var _a;
    const { mode } = this.opts;
    const layeredModes = ["composite", domCompositeIdentifier$1, advancedCompositeIdentifier$1];
    if (!layeredModes.includes(mode)) {
      return void 0;
    }
    const { zIndex = this._nextZIndex++, name, zIndexSubOrder, getComputedOpacity, getVisibility } = opts;
    const { width, height, overrideDevicePixelRatio } = this;
    const domLayer = mode === domCompositeIdentifier$1;
    const advLayer = mode === advancedCompositeIdentifier$1;
    const canvas = !advLayer || !HdpiOffscreenCanvas$1.isSupported() ? new HdpiCanvas$1({
      document: this.opts.document,
      window: this.opts.window,
      width,
      height,
      domLayer,
      zIndex,
      name,
      overrideDevicePixelRatio
    }) : new HdpiOffscreenCanvas$1({
      width,
      height,
      overrideDevicePixelRatio
    });
    const newLayer = {
      id: this._nextLayerId++,
      name,
      zIndex,
      zIndexSubOrder,
      canvas,
      getComputedOpacity,
      getVisibility
    };
    if (zIndex >= this._nextZIndex) {
      this._nextZIndex = zIndex + 1;
    }
    this.layers.push(newLayer);
    this.sortLayers();
    if (domLayer) {
      const domCanvases = this.layers.map((v) => v.canvas).filter((v) => v instanceof HdpiCanvas$1);
      const newLayerIndex = domCanvases.findIndex((v) => v === canvas);
      const lastLayer = (_a = domCanvases[newLayerIndex - 1]) != null ? _a : this.canvas;
      lastLayer.element.insertAdjacentElement("afterend", canvas.element);
    }
    this.debug("Scene.addLayer() - layers", this.layers);
    return newLayer.canvas;
  }
  removeLayer(canvas) {
    const index = this.layers.findIndex((l) => l.canvas === canvas);
    if (index >= 0) {
      this.layers.splice(index, 1);
      canvas.destroy();
      this.markDirty();
      this.debug("Scene.removeLayer() -  layers", this.layers);
    }
  }
  moveLayer(canvas, newZIndex, newZIndexSubOrder) {
    const layer = this.layers.find((l) => l.canvas === canvas);
    if (layer) {
      layer.zIndex = newZIndex;
      layer.zIndexSubOrder = newZIndexSubOrder;
      this.sortLayers();
      this.markDirty();
      this.debug("Scene.moveLayer() -  layers", this.layers);
    }
  }
  sortLayers() {
    this.layers.sort((a, b) => {
      var _a, _b;
      return compoundAscending$1(
        [a.zIndex, ...(_a = a.zIndexSubOrder) != null ? _a : [void 0, void 0], a.id],
        [b.zIndex, ...(_b = b.zIndexSubOrder) != null ? _b : [void 0, void 0], b.id],
        ascendingStringNumberUndefined$1
      );
    });
  }
  markDirty() {
    this._dirty = true;
  }
  get dirty() {
    return this._dirty;
  }
  set root(node) {
    var _a;
    if (node === this._root) {
      return;
    }
    (_a = this._root) == null ? void 0 : _a._setLayerManager();
    this._root = node;
    if (node) {
      node._setLayerManager({
        addLayer: (opts) => this.addLayer(opts),
        moveLayer: (...opts) => this.moveLayer(...opts),
        removeLayer: (...opts) => this.removeLayer(...opts),
        markDirty: () => this.markDirty(),
        canvas: this.canvas,
        debug: Debug$1.create("scene" /* SCENE */)
      });
    }
    this.markDirty();
  }
  get root() {
    return this._root;
  }
  /** Alternative to destroy() that preserves re-usable resources. */
  strip() {
    const { layers } = this;
    for (const layer of layers) {
      layer.canvas.destroy();
      delete layer["canvas"];
    }
    layers.splice(0, layers.length);
    this.root = null;
    this._dirty = false;
    this.canvas.context.resetTransform();
  }
  destroy() {
    this.container = void 0;
    this.strip();
    this.canvas.destroy();
    Object.assign(this, { canvas: void 0, ctx: void 0 });
  }
  render(opts) {
    return __async$1(this, null, function* () {
      var _a, _b;
      const { debugSplitTimes = { start: performance.now() }, extraDebugStats = {} } = opts != null ? opts : {};
      const {
        canvas,
        canvas: { context: ctx },
        root,
        layers,
        pendingSize,
        opts: { mode }
      } = this;
      if (pendingSize) {
        this.canvas.resize(...pendingSize);
        this.layers.forEach((layer) => layer.canvas.resize(...pendingSize));
        this.pendingSize = void 0;
      }
      if (root && !root.visible) {
        this._dirty = false;
        return;
      }
      if (root && !this.dirty) {
        this.debug("Scene.render() - no-op", {
          redrawType: RedrawType$1[root.dirty],
          tree: this.buildTree(root)
        });
        this.debugStats(debugSplitTimes, ctx, void 0, extraDebugStats);
        return;
      }
      const renderCtx = {
        ctx,
        devicePixelRatio: (_a = this.canvas.pixelRatio) != null ? _a : 1,
        forceRender: true,
        resized: !!pendingSize,
        debugNodes: {}
      };
      if (Debug$1.check("scene:stats:verbose" /* SCENE_STATS_VERBOSE */)) {
        renderCtx.stats = { layersRendered: 0, layersSkipped: 0, nodesRendered: 0, nodesSkipped: 0 };
      }
      let canvasCleared = false;
      if (!root || root.dirty >= 1 /* TRIVIAL */) {
        canvasCleared = true;
        canvas.clear();
      }
      if (root) {
        const { dirtyTree, paths } = this.buildDirtyTree(root);
        Debug$1.create("scene:dirtyTree" /* SCENE_DIRTY_TREE */)("Scene.render() - dirtyTree", {
          dirtyTree,
          paths
        });
      }
      if (root && canvasCleared) {
        this.debug("Scene.render() - before", {
          redrawType: RedrawType$1[root.dirty],
          canvasCleared,
          tree: this.buildTree(root)
        });
        if (root.visible) {
          ctx.save();
          root.render(renderCtx);
          ctx.restore();
        }
      }
      debugSplitTimes["\u270D\uFE0F"] = performance.now();
      if (mode !== domCompositeIdentifier$1 && layers.length > 0 && canvasCleared) {
        this.sortLayers();
        ctx.save();
        ctx.setTransform(1 / canvas.pixelRatio, 0, 0, 1 / canvas.pixelRatio, 0, 0);
        layers.forEach(({ canvas: { imageSource, enabled }, getComputedOpacity, getVisibility }) => {
          if (!enabled || !getVisibility()) {
            return;
          }
          ctx.globalAlpha = getComputedOpacity();
          ctx.drawImage(imageSource, 0, 0);
        });
        ctx.restore();
        debugSplitTimes["\u26D9"] = performance.now();
      }
      (_b = ctx.verifyDepthZero) == null ? void 0 : _b.call(ctx);
      this._dirty = false;
      this.debugStats(debugSplitTimes, ctx, renderCtx.stats, extraDebugStats);
      this.debugSceneNodeHighlight(ctx, renderCtx.debugNodes);
      if (root) {
        this.debug("Scene.render() - after", {
          redrawType: RedrawType$1[root.dirty],
          canvasCleared,
          tree: this.buildTree(root)
        });
      }
    });
  }
  debugStats(debugSplitTimes, ctx, renderCtxStats, extraDebugStats = {}) {
    if (Debug$1.check("scene:stats" /* SCENE_STATS */, "scene:stats:verbose" /* SCENE_STATS_VERBOSE */)) {
      const end = performance.now();
      const start = debugSplitTimes["start"];
      debugSplitTimes["end"] = performance.now();
      const pct = (rendered, skipped) => {
        const total = rendered + skipped;
        return `${rendered} / ${total} (${Math.round(100 * rendered / total)}%)`;
      };
      const time = (name, start2, end2) => {
        return `${name}: ${Math.round((end2 - start2) * 100) / 100}ms`;
      };
      const { layersRendered = 0, layersSkipped = 0, nodesRendered = 0, nodesSkipped = 0 } = renderCtxStats != null ? renderCtxStats : {};
      let lastSplit = 0;
      const splits = Object.entries(debugSplitTimes).filter(([n]) => n !== "end").map(([n, t], i) => {
        const result = i > 0 ? time(n, lastSplit, t) : null;
        lastSplit = t;
        return result;
      }).filter((v) => v != null).join(" + ");
      const extras = Object.entries(extraDebugStats).map(([k, v]) => `${k}: ${v}`).join(" ; ");
      const detailedStats = Debug$1.check("scene:stats:verbose" /* SCENE_STATS_VERBOSE */);
      const stats = [
        `${time("\u23F1\uFE0F", start, end)} (${splits})`,
        `${extras}`,
        `Layers: ${detailedStats ? pct(layersRendered, layersSkipped) : this.layers.length}`,
        detailedStats ? `Nodes: ${pct(nodesRendered, nodesSkipped)}` : null
      ].filter((v) => v != null);
      const statsSize = stats.map((t) => [t, HdpiCanvas$1.getTextSize(t, ctx.font)]);
      const width = Math.max(...statsSize.map(([, { width: width2 }]) => width2));
      const height = statsSize.reduce((total, [, { height: height2 }]) => total + height2, 0);
      ctx.save();
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, width, height);
      ctx.fillStyle = "black";
      let y = 0;
      for (const [stat, size] of statsSize) {
        y += size.height;
        ctx.fillText(stat, 2, y);
      }
      ctx.restore();
    }
  }
  debugSceneNodeHighlight(ctx, debugNodes) {
    var _a;
    const regexpPredicate = (matcher) => (n) => {
      if (matcher.test(n.id)) {
        return true;
      }
      return n instanceof Group$1 && n.name != null && matcher.test(n.name);
    };
    const stringPredicate = (match) => (n) => {
      if (match === n.id) {
        return true;
      }
      return n instanceof Group$1 && n.name != null && match === n.name;
    };
    const sceneNodeHighlight = toArray$1(windowValue$1("agChartsSceneDebug")).flatMap(
      (name) => name === "layout" ? ["seriesRoot", "legend", "root", /.*Axis-\d+-axis.*/] : name
    );
    for (const next of sceneNodeHighlight) {
      if (typeof next === "string" && debugNodes[next] != null)
        continue;
      const predicate = typeof next === "string" ? stringPredicate(next) : regexpPredicate(next);
      const nodes = (_a = this.root) == null ? void 0 : _a.findNodes(predicate);
      if (!nodes || nodes.length === 0) {
        Logger$1.log(`Scene.render() - no debugging node with id [${next}] in scene graph.`);
        continue;
      }
      for (const node of nodes) {
        if (node instanceof Group$1 && node.name) {
          debugNodes[node.name] = node;
        } else {
          debugNodes[node.id] = node;
        }
      }
    }
    ctx.save();
    for (const [name, node] of Object.entries(debugNodes)) {
      const bbox = node.computeTransformedBBox();
      if (!bbox) {
        Logger$1.log(`Scene.render() - no bbox for debugged node [${name}].`);
        continue;
      }
      ctx.globalAlpha = 0.8;
      ctx.strokeStyle = "red";
      ctx.lineWidth = 1;
      ctx.strokeRect(bbox.x, bbox.y, bbox.width, bbox.height);
      ctx.fillStyle = "red";
      ctx.strokeStyle = "white";
      ctx.font = "16px sans-serif";
      ctx.textBaseline = "top";
      ctx.textAlign = "left";
      ctx.lineWidth = 2;
      ctx.strokeText(name, bbox.x, bbox.y, bbox.width);
      ctx.fillText(name, bbox.x, bbox.y, bbox.width);
    }
    ctx.restore();
  }
  buildTree(node) {
    var _a, _b;
    const name = (_a = node instanceof Group$1 ? node.name : null) != null ? _a : node.id;
    return __spreadValues$1(__spreadValues$1({
      name,
      node,
      dirty: RedrawType$1[node.dirty]
    }, ((_b = node.parent) == null ? void 0 : _b.isVirtual) ? {
      virtualParentDirty: RedrawType$1[node.parent.dirty],
      virtualParent: node.parent
    } : {}), node.children.map((c) => this.buildTree(c)).reduce((result, childTree) => {
      let { name: treeNodeName } = childTree;
      const {
        node: { visible, opacity, zIndex, zIndexSubOrder },
        node: childNode,
        virtualParent
      } = childTree;
      if (!visible || opacity <= 0) {
        treeNodeName = `(${treeNodeName})`;
      }
      if (childNode instanceof Group$1 && childNode.isLayer()) {
        treeNodeName = `*${treeNodeName}*`;
      }
      const key = [
        `${treeNodeName != null ? treeNodeName : "<unknown>"}`,
        `z: ${zIndex}`,
        zIndexSubOrder && `zo: ${zIndexSubOrder.map((v) => typeof v === "function" ? `${v()} (fn)` : v).join(" / ")}`,
        virtualParent && `(virtual parent)`
      ].filter((v) => !!v).join(" ");
      let selectedKey = key;
      let index = 1;
      while (result[selectedKey] != null && index < 100) {
        selectedKey = `${key} (${index++})`;
      }
      result[selectedKey] = childTree;
      return result;
    }, {}));
  }
  buildDirtyTree(node) {
    var _a;
    if (node.dirty === 0 /* NONE */) {
      return { dirtyTree: {}, paths: [] };
    }
    const childrenDirtyTree = node.children.map((c) => this.buildDirtyTree(c)).filter((c) => c.paths.length > 0);
    const name = (_a = node instanceof Group$1 ? node.name : null) != null ? _a : node.id;
    const paths = childrenDirtyTree.length === 0 ? [name] : childrenDirtyTree.map((c) => c.paths).reduce((r, p) => r.concat(p), []).map((p) => `${name}.${p}`);
    return {
      dirtyTree: __spreadValues$1({
        name,
        node,
        dirty: RedrawType$1[node.dirty]
      }, childrenDirtyTree.map((c) => c.dirtyTree).filter((t) => t.dirty !== void 0).reduce((result, childTree) => {
        var _a2;
        result[(_a2 = childTree.name) != null ? _a2 : "<unknown>"] = childTree;
        return result;
      }, {})),
      paths
    };
  }
};
Scene$1.className = "Scene";

// packages/ag-charts-community/src/scene/selection.ts
var Selection$1 = class _Selection {
  constructor(parentNode, classOrFactory, autoCleanup = true) {
    this.parentNode = parentNode;
    this.autoCleanup = autoCleanup;
    this.garbageBin = /* @__PURE__ */ new Set();
    this._nodesMap = /* @__PURE__ */ new Map();
    this._nodes = [];
    this.data = [];
    this.debug = Debug$1.create(true, "scene", "scene:selections");
    this.nodeFactory = Object.prototype.isPrototypeOf.call(Node$1, classOrFactory) ? () => new classOrFactory() : classOrFactory;
  }
  static select(parent, classOrFactory, garbageCollection = true) {
    return new _Selection(parent, classOrFactory, garbageCollection);
  }
  static selectAll(parent, predicate) {
    const results = [];
    const traverse = (node) => {
      if (predicate(node)) {
        results.push(node);
      }
      node.children.forEach(traverse);
    };
    traverse(parent);
    return results;
  }
  static selectByClass(node, Class) {
    return _Selection.selectAll(node, (node2) => node2 instanceof Class);
  }
  static selectByTag(node, tag) {
    return _Selection.selectAll(node, (node2) => node2.tag === tag);
  }
  createNode(datum, initializer, idx) {
    const node = this.nodeFactory(datum);
    node.datum = datum;
    initializer == null ? void 0 : initializer(node);
    if (idx != null) {
      this._nodes.splice(idx, 0, node);
    } else {
      this._nodes.push(node);
    }
    this.parentNode.appendChild(node);
    return node;
  }
  /**
   * Update the data in a selection. If an `getDatumId()` function is provided, maintain a list of ids related to
   * the nodes. Otherwise, take the more efficient route of simply creating and destroying nodes at the end
   * of the array.
   */
  update(data, initializer, getDatumId) {
    if (this.garbageBin.size > 0) {
      this.debug(`Selection - update() called with pending garbage: ${data}`);
    }
    if (getDatumId) {
      const dataMap = new Map(
        data.map((datum, idx) => [getDatumId(datum), [datum, idx]])
      );
      for (const [node, datumId] of this._nodesMap.entries()) {
        if (dataMap.has(datumId)) {
          const [newDatum] = dataMap.get(datumId);
          node.datum = newDatum;
          this.garbageBin.delete(node);
          dataMap.delete(datumId);
        } else {
          this.garbageBin.add(node);
        }
      }
      for (const [datumId, [datum, idx]] of dataMap.entries()) {
        this._nodesMap.set(this.createNode(datum, initializer, idx), datumId);
      }
    } else {
      const maxLength = Math.max(data.length, this.data.length);
      for (let i = 0; i < maxLength; i++) {
        if (i >= data.length) {
          this.garbageBin.add(this._nodes[i]);
        } else if (i >= this._nodes.length) {
          this.createNode(data[i], initializer);
        } else {
          this._nodes[i].datum = data[i];
          this.garbageBin.delete(this._nodes[i]);
        }
      }
    }
    this.data = data.slice();
    if (this.autoCleanup) {
      this.cleanup();
    }
    return this;
  }
  cleanup() {
    if (this.garbageBin.size === 0) {
      return this;
    }
    this._nodes = this._nodes.filter((node) => {
      if (this.garbageBin.has(node)) {
        this._nodesMap.delete(node);
        this.garbageBin.delete(node);
        this.parentNode.removeChild(node);
        return false;
      }
      return true;
    });
    return this;
  }
  clear() {
    this.update([]);
    return this;
  }
  isGarbage(node) {
    return this.garbageBin.has(node);
  }
  hasGarbage() {
    return this.garbageBin.size > 0;
  }
  each(iterate) {
    this._nodes.forEach((node, i) => iterate(node, node.datum, i));
    return this;
  }
  *[Symbol.iterator]() {
    for (let index = 0; index < this._nodes.length; index++) {
      const node = this._nodes[index];
      const datum = this._nodes[index].datum;
      yield { node, datum, index };
    }
  }
  select(predicate) {
    return _Selection.selectAll(this.parentNode, predicate);
  }
  selectByClass(Class) {
    return _Selection.selectByClass(this.parentNode, Class);
  }
  selectByTag(tag) {
    return _Selection.selectByTag(this.parentNode, tag);
  }
  nodes() {
    return this._nodes;
  }
};

// packages/ag-charts-community/src/scene/polyRoots.ts
function linearRoot$1(a, b) {
  const t = -b / a;
  return a !== 0 && t >= 0 && t <= 1 ? [t] : [];
}
function quadraticRoots$1(a, b, c) {
  if (a === 0) {
    return linearRoot$1(b, c);
  }
  const D = b * b - 4 * a * c;
  const roots = [];
  if (D === 0) {
    const t = -b / (2 * a);
    if (t >= 0 && t <= 1) {
      roots.push(t);
    }
  } else if (D > 0) {
    const rD = Math.sqrt(D);
    const t1 = (-b - rD) / (2 * a);
    const t2 = (-b + rD) / (2 * a);
    if (t1 >= 0 && t1 <= 1) {
      roots.push(t1);
    }
    if (t2 >= 0 && t2 <= 1) {
      roots.push(t2);
    }
  }
  return roots;
}
function cubicRoots$1(a, b, c, d) {
  if (a === 0) {
    return quadraticRoots$1(b, c, d);
  }
  const A = b / a;
  const B = c / a;
  const C = d / a;
  const Q = (3 * B - A * A) / 9;
  const R = (9 * A * B - 27 * C - 2 * A * A * A) / 54;
  const D = Q * Q * Q + R * R;
  const third = 1 / 3;
  const roots = [];
  if (D >= 0) {
    const rD = Math.sqrt(D);
    const S = Math.sign(R + rD) * Math.pow(Math.abs(R + rD), third);
    const T = Math.sign(R - rD) * Math.pow(Math.abs(R - rD), third);
    const Im = Math.abs(Math.sqrt(3) * (S - T) / 2);
    const t = -third * A + (S + T);
    if (t >= 0 && t <= 1) {
      roots.push(t);
    }
    if (Im === 0) {
      const t2 = -third * A - (S + T) / 2;
      if (t2 >= 0 && t2 <= 1) {
        roots.push(t2);
      }
    }
  } else {
    const theta = Math.acos(R / Math.sqrt(-Q * Q * Q));
    const thirdA = third * A;
    const twoSqrtQ = 2 * Math.sqrt(-Q);
    const t1 = twoSqrtQ * Math.cos(third * theta) - thirdA;
    const t2 = twoSqrtQ * Math.cos(third * (theta + 2 * Math.PI)) - thirdA;
    const t3 = twoSqrtQ * Math.cos(third * (theta + 4 * Math.PI)) - thirdA;
    if (t1 >= 0 && t1 <= 1) {
      roots.push(t1);
    }
    if (t2 >= 0 && t2 <= 1) {
      roots.push(t2);
    }
    if (t3 >= 0 && t3 <= 1) {
      roots.push(t3);
    }
  }
  return roots;
}

// packages/ag-charts-community/src/scene/intersection.ts
function segmentIntersection$1(ax1, ay1, ax2, ay2, bx1, by1, bx2, by2) {
  const d = (ax2 - ax1) * (by2 - by1) - (ay2 - ay1) * (bx2 - bx1);
  if (d === 0) {
    return null;
  }
  const ua = ((bx2 - bx1) * (ay1 - by1) - (ax1 - bx1) * (by2 - by1)) / d;
  const ub = ((ax2 - ax1) * (ay1 - by1) - (ay2 - ay1) * (ax1 - bx1)) / d;
  if (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
    return {
      x: ax1 + ua * (ax2 - ax1),
      y: ay1 + ua * (ay2 - ay1)
    };
  }
  return null;
}
function cubicSegmentIntersections$1(px1, py1, px2, py2, px3, py3, px4, py4, x1, y1, x2, y2) {
  const intersections = [];
  const A = y1 - y2;
  const B = x2 - x1;
  const C = x1 * (y2 - y1) - y1 * (x2 - x1);
  const bx = bezierCoefficients$1(px1, px2, px3, px4);
  const by = bezierCoefficients$1(py1, py2, py3, py4);
  const a = A * bx[0] + B * by[0];
  const b = A * bx[1] + B * by[1];
  const c = A * bx[2] + B * by[2];
  const d = A * bx[3] + B * by[3] + C;
  const roots = cubicRoots$1(a, b, c, d);
  for (const t of roots) {
    const tt = t * t;
    const ttt = t * tt;
    const x = bx[0] * ttt + bx[1] * tt + bx[2] * t + bx[3];
    const y = by[0] * ttt + by[1] * tt + by[2] * t + by[3];
    let s;
    if (x1 !== x2) {
      s = (x - x1) / (x2 - x1);
    } else {
      s = (y - y1) / (y2 - y1);
    }
    if (s >= 0 && s <= 1) {
      intersections.push({ x, y });
    }
  }
  return intersections;
}
function bezierCoefficients$1(P1, P2, P3, P4) {
  return [
    // Bézier expressed as matrix operations:
    -P1 + 3 * P2 - 3 * P3 + P4,
    //                 |-1  3 -3  1| |P1|
    3 * P1 - 6 * P2 + 3 * P3,
    //   [t^3 t^2 t 1] | 3 -6  3  0| |P2|
    -3 * P1 + 3 * P2,
    //                 |-3  3  0  0| |P3|
    P1
    //                 | 1  0  0  0| |P4|
  ];
}
function arcIntersections$1(cx, cy, r, startAngle, endAngle, counterClockwise, x1, y1, x2, y2) {
  const k = (y2 - y1) / (x2 - x1);
  const y0 = y1 - k * x1;
  const a = Math.pow(k, 2) + 1;
  const b = 2 * (k * (y0 - cy) - cx);
  const c = Math.pow(cx, 2) + Math.pow(y0 - cy, 2) - Math.pow(r, 2);
  const d = Math.pow(b, 2) - 4 * a * c;
  if (d < 0) {
    return [];
  }
  const i1x = (-b + Math.sqrt(d)) / 2 / a;
  const i2x = (-b - Math.sqrt(d)) / 2 / a;
  const intersections = [];
  [i1x, i2x].forEach((x) => {
    const isXInsideLine = x >= Math.min(x1, x2) && x <= Math.max(x1, x2);
    if (!isXInsideLine) {
      return;
    }
    const y = k * x + y0;
    const a1 = normalizeAngle360$1(startAngle);
    let a2 = normalizeAngle360$1(endAngle);
    let a3 = normalizeAngle360$1(Math.atan2(y, x));
    if (a2 <= a1) {
      a2 += 2 * Math.PI;
    }
    if (a3 < a1) {
      a3 += 2 * Math.PI;
    }
    if (counterClockwise !== (a3 >= a1 && a3 <= a2)) {
      intersections.push({ x, y });
    }
  });
  return intersections;
}

// packages/ag-charts-community/src/scene/path2D.ts
var Path2D$1 = class {
  constructor() {
    this.previousCommands = [];
    this.previousParams = [];
    this.previousClosedPath = false;
    this.commands = [];
    this.params = [];
    this._closedPath = false;
  }
  isDirty() {
    if (this._closedPath !== this.previousClosedPath) {
      return true;
    }
    if (this.previousCommands.length !== this.commands.length) {
      return true;
    }
    if (this.previousParams.length !== this.params.length) {
      return true;
    }
    for (let i = 0; i < this.commands.length; i++) {
      if (this.commands[i] !== this.previousCommands[i]) {
        return true;
      }
    }
    for (let i = 0; i < this.params.length; i++) {
      if (this.params[i] !== this.previousParams[i]) {
        return true;
      }
    }
    return false;
  }
  draw(ctx) {
    const commands = this.commands;
    const params = this.params;
    let j = 0;
    ctx.beginPath();
    for (const command of commands) {
      switch (command) {
        case 0 /* Move */:
          ctx.moveTo(params[j++], params[j++]);
          break;
        case 1 /* Line */:
          ctx.lineTo(params[j++], params[j++]);
          break;
        case 3 /* Curve */:
          ctx.bezierCurveTo(params[j++], params[j++], params[j++], params[j++], params[j++], params[j++]);
          break;
        case 2 /* Arc */:
          ctx.arc(params[j++], params[j++], params[j++], params[j++], params[j++], params[j++] === 1);
          break;
        case 4 /* ClosePath */:
          ctx.closePath();
          break;
      }
    }
    if (commands.length === 0) {
      ctx.closePath();
    }
  }
  moveTo(x, y) {
    if (this.xy) {
      this.xy[0] = x;
      this.xy[1] = y;
    } else {
      this.xy = [x, y];
    }
    this.commands.push(0 /* Move */);
    this.params.push(x, y);
  }
  lineTo(x, y) {
    if (this.xy) {
      this.commands.push(1 /* Line */);
      this.params.push(x, y);
      this.xy[0] = x;
      this.xy[1] = y;
    } else {
      this.moveTo(x, y);
    }
  }
  rect(x, y, width, height) {
    this.moveTo(x, y);
    this.lineTo(x + width, y);
    this.lineTo(x + width, y + height);
    this.lineTo(x, y + height);
    this.closePath();
  }
  roundRect(x, y, width, height, radii) {
    radii = Math.min(radii, width / 2, height / 2);
    this.moveTo(x, y + radii);
    this.arc(x + radii, y + radii, radii, Math.PI, 3 * Math.PI / 2);
    this.lineTo(x + radii, y);
    this.lineTo(x + width - radii, y);
    this.arc(x + width - radii, y + radii, radii, 3 * Math.PI / 2, 2 * Math.PI);
    this.lineTo(x + width, y + radii);
    this.lineTo(x + width, y + height - radii);
    this.arc(x + width - radii, y + height - radii, radii, 0, Math.PI / 2);
    this.lineTo(x + width - radii, y + height);
    this.lineTo(x + radii, y + height);
    this.arc(x + +radii, y + height - radii, radii, Math.PI / 2, Math.PI);
    this.lineTo(x, y + height - radii);
    this.closePath();
  }
  arc(x, y, r, sAngle, eAngle, antiClockwise = false) {
    const endX = x + r * Math.cos(eAngle);
    const endY = y + r * Math.sin(eAngle);
    if (this.xy) {
      this.xy[0] = endX;
      this.xy[1] = endY;
    } else {
      this.xy = [endX, endY];
    }
    this.commands.push(2 /* Arc */);
    this.params.push(x, y, r, sAngle, eAngle, antiClockwise ? 1 : 0);
  }
  cubicCurveTo(cx1, cy1, cx2, cy2, x, y) {
    if (!this.xy) {
      this.moveTo(cx1, cy1);
    }
    this.commands.push(3 /* Curve */);
    this.params.push(cx1, cy1, cx2, cy2, x, y);
    if (this.xy) {
      this.xy[0] = x;
      this.xy[1] = y;
    }
  }
  get closedPath() {
    return this._closedPath;
  }
  closePath() {
    if (this.xy) {
      this.xy = void 0;
      this.commands.push(4 /* ClosePath */);
      this._closedPath = true;
    }
  }
  clear({ trackChanges } = { trackChanges: false }) {
    if (trackChanges) {
      this.previousCommands = this.commands;
      this.previousParams = this.params;
      this.previousClosedPath = this._closedPath;
      this.commands = [];
      this.params = [];
    } else {
      this.commands.length = 0;
      this.params.length = 0;
    }
    this.xy = void 0;
    this._closedPath = false;
  }
  isPointInPath(x, y) {
    const commands = this.commands;
    const params = this.params;
    const cn = commands.length;
    const ox = -1e4;
    const oy = -1e4;
    let sx = NaN;
    let sy = NaN;
    let px = 0;
    let py = 0;
    let intersectionCount = 0;
    for (let ci = 0, pi = 0; ci < cn; ci++) {
      switch (commands[ci]) {
        case 0 /* Move */:
          if (!isNaN(sx) && segmentIntersection$1(sx, sy, px, py, ox, oy, x, y)) {
            intersectionCount++;
          }
          px = params[pi++];
          sx = px;
          py = params[pi++];
          sy = py;
          break;
        case 1 /* Line */:
          if (segmentIntersection$1(px, py, params[pi++], params[pi++], ox, oy, x, y)) {
            intersectionCount++;
          }
          px = params[pi - 2];
          py = params[pi - 1];
          break;
        case 3 /* Curve */:
          intersectionCount += cubicSegmentIntersections$1(
            px,
            py,
            params[pi++],
            params[pi++],
            params[pi++],
            params[pi++],
            params[pi++],
            params[pi++],
            ox,
            oy,
            x,
            y
          ).length;
          px = params[pi - 2];
          py = params[pi - 1];
          break;
        case 2 /* Arc */:
          const cx = params[pi++];
          const cy = params[pi++];
          const r = params[pi++];
          const startAngle = params[pi++];
          const endAngle = params[pi++];
          const counterClockwise = Boolean(params[pi++]);
          intersectionCount += arcIntersections$1(
            cx,
            cy,
            r,
            startAngle,
            endAngle,
            counterClockwise,
            ox,
            oy,
            x,
            y
          ).length;
          px = cx + Math.cos(endAngle) * r;
          py = cy + Math.sin(endAngle) * r;
          break;
        case 4 /* ClosePath */:
          if (!isNaN(sx) && segmentIntersection$1(sx, sy, px, py, ox, oy, x, y)) {
            intersectionCount++;
          }
          break;
      }
    }
    return intersectionCount % 2 === 1;
  }
  getPoints() {
    const { commands, params } = this;
    const coords = [];
    let pi = 0;
    for (let ci = 0; ci < commands.length; ci++) {
      switch (commands[ci]) {
        case 0 /* Move */:
        case 1 /* Line */:
          coords.push({ x: params[pi++], y: params[pi++] });
          break;
        case 3 /* Curve */:
          pi += 4;
          coords.push({ x: params[pi++], y: params[pi++] });
          break;
        case 2 /* Arc */:
          coords.push({ x: params[pi++], y: params[pi++] });
          pi += 4;
          break;
      }
    }
    return coords;
  }
};

// packages/ag-charts-community/src/scene/shape/path.ts
function ScenePathChangeDetection$1(opts) {
  const { redraw = 3 /* MAJOR */, changeCb, convertor } = opts != null ? opts : {};
  return SceneChangeDetection$1({ redraw, type: "path", convertor, changeCb });
}
var Path$1 = class extends Shape$1 {
  constructor() {
    super(...arguments);
    /**
     * Declare a path to retain for later rendering and hit testing
     * using custom Path2D class. Think of it as a TypeScript version
     * of the native Path2D (with some differences) that works in all browsers.
     */
    this.path = new Path2D$1();
    this.clipScalingX = 1;
    this.clipScalingY = 1;
    /**
     * The path only has to be updated when certain attributes change.
     * For example, if transform attributes (such as `translationX`)
     * are changed, we don't have to update the path. The `dirtyPath` flag
     * is how we keep track if the path has to be updated or not.
     */
    this._dirtyPath = true;
  }
  set dirtyPath(value) {
    if (this._dirtyPath !== value) {
      this._dirtyPath = value;
      if (value) {
        this.markDirty(this, 3 /* MAJOR */);
      }
    }
  }
  get dirtyPath() {
    return this._dirtyPath;
  }
  checkPathDirty() {
    var _a, _b, _c, _d;
    if (this._dirtyPath) {
      return;
    }
    this.dirtyPath = this.path.isDirty() || ((_b = (_a = this.fillShadow) == null ? void 0 : _a.isDirty()) != null ? _b : false) || ((_d = (_c = this.clipPath) == null ? void 0 : _c.isDirty()) != null ? _d : false);
  }
  isPointInPath(x, y) {
    const point = this.transformPoint(x, y);
    return this.path.closedPath && this.path.isPointInPath(point.x, point.y);
  }
  isDirtyPath() {
    return false;
  }
  updatePath() {
  }
  clip(ctx, op) {
    const transform = ctx.getTransform();
    const clipScale = this.clipScalingX !== 1 || this.clipScalingY !== 1;
    if (clipScale) {
      ctx.scale(this.clipScalingX, this.clipScalingY);
    }
    op();
    if (clipScale) {
      ctx.setTransform(transform);
    }
  }
  render(renderCtx) {
    var _a;
    const { ctx, forceRender, stats } = renderCtx;
    if (this.dirty === 0 /* NONE */ && !forceRender) {
      if (stats)
        stats.nodesSkipped += this.nodeCount.count;
      return;
    }
    this.computeTransformMatrix();
    this.matrix.toContext(ctx);
    if (this.dirtyPath || this.isDirtyPath()) {
      this.updatePath();
      this.dirtyPath = false;
    }
    if (this.clipPath && this.clipMode != null) {
      ctx.save();
      if (this.clipMode === "normal") {
        this.clip(ctx, () => {
          var _a2;
          (_a2 = this.clipPath) == null ? void 0 : _a2.draw(ctx);
          ctx.clip();
        });
      }
      if (this.clipScalingX > 0 && this.clipScalingY > 0) {
        this.path.draw(ctx);
        this.fillStroke(ctx);
      }
      if (this.clipMode === "punch-out") {
        this.clip(ctx, () => {
          var _a2, _b;
          (_a2 = this.clipPath) == null ? void 0 : _a2.draw(ctx);
          ctx.clip();
          const { x = -1e4, y = -1e4, width = 2e4, height = 2e4 } = (_b = this.computeBBox()) != null ? _b : {};
          ctx.clearRect(x, y, width, height);
        });
      }
      ctx.restore();
    } else {
      this.path.draw(ctx);
      this.fillStroke(ctx);
    }
    (_a = this.fillShadow) == null ? void 0 : _a.markClean();
    super.render(renderCtx);
  }
};
Path$1.className = "Path";
__decorateClass$1([
  ScenePathChangeDetection$1()
], Path$1.prototype, "clipPath", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Path$1.prototype, "clipMode", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Path$1.prototype, "clipScalingX", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Path$1.prototype, "clipScalingY", 2);

// packages/ag-charts-community/src/scene/shape/arc.ts
var Arc$1 = class extends Path$1 {
  constructor() {
    super();
    this.centerX = 0;
    this.centerY = 0;
    this.radius = 10;
    this.startAngle = 0;
    this.endAngle = Math.PI * 2;
    this.counterClockwise = false;
    this.type = 0 /* Open */;
    this.restoreOwnStyles();
  }
  get fullPie() {
    return isEqual$1(normalizeAngle360$1(this.startAngle), normalizeAngle360$1(this.endAngle));
  }
  updatePath() {
    const path = this.path;
    path.clear();
    path.arc(this.centerX, this.centerY, this.radius, this.startAngle, this.endAngle, this.counterClockwise);
    if (this.type === 1 /* Chord */) {
      path.closePath();
    } else if (this.type === 2 /* Round */ && !this.fullPie) {
      path.lineTo(this.centerX, this.centerY);
      path.closePath();
    }
  }
  computeBBox() {
    return new BBox$1(this.centerX - this.radius, this.centerY - this.radius, this.radius * 2, this.radius * 2);
  }
  isPointInPath(x, y) {
    const point = this.transformPoint(x, y);
    const bbox = this.computeBBox();
    return this.type !== 0 /* Open */ && bbox.containsPoint(point.x, point.y) && this.path.isPointInPath(point.x, point.y);
  }
};
Arc$1.className = "Arc";
Arc$1.defaultStyles = Object.assign({}, Shape$1.defaultStyles, {
  lineWidth: 1,
  fillStyle: null
});
__decorateClass$1([
  ScenePathChangeDetection$1()
], Arc$1.prototype, "centerX", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Arc$1.prototype, "centerY", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Arc$1.prototype, "radius", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Arc$1.prototype, "startAngle", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Arc$1.prototype, "endAngle", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Arc$1.prototype, "counterClockwise", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Arc$1.prototype, "type", 2);

// packages/ag-charts-community/src/scene/shape/line.ts
var Line$1 = class extends Shape$1 {
  constructor(opts = {}) {
    super(opts);
    this.x1 = 0;
    this.y1 = 0;
    this.x2 = 0;
    this.y2 = 0;
    this.restoreOwnStyles();
  }
  set x(value) {
    this.x1 = value;
    this.x2 = value;
  }
  set y(value) {
    this.y1 = value;
    this.y2 = value;
  }
  computeBBox() {
    return new BBox$1(
      Math.min(this.x1, this.x2),
      Math.min(this.y1, this.y2),
      Math.abs(this.x2 - this.x1),
      Math.abs(this.y2 - this.y1)
    );
  }
  isPointInPath(px, py) {
    if (this.x1 === this.x2 || this.y1 === this.y2) {
      const { x, y } = this.transformPoint(px, py);
      return this.computeBBox().grow(this.strokeWidth / 2).containsPoint(x, y);
    }
    return false;
  }
  render(renderCtx) {
    var _a;
    const { ctx, forceRender, stats, devicePixelRatio } = renderCtx;
    if (this.dirty === 0 /* NONE */ && !forceRender) {
      if (stats)
        stats.nodesSkipped += this.nodeCount.count;
      return;
    }
    this.computeTransformMatrix();
    this.matrix.toContext(ctx);
    let { x1, y1, x2, y2 } = this;
    if (x1 === x2) {
      const { strokeWidth } = this;
      const x = Math.round(x1 * devicePixelRatio) / devicePixelRatio + Math.trunc(strokeWidth * devicePixelRatio) % 2 / (devicePixelRatio * 2);
      x1 = x;
      x2 = x;
    } else if (y1 === y2) {
      const { strokeWidth } = this;
      const y = Math.round(y1 * devicePixelRatio) / devicePixelRatio + Math.trunc(strokeWidth * devicePixelRatio) % 2 / (devicePixelRatio * 2);
      y1 = y;
      y2 = y;
    }
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    this.fillStroke(ctx);
    (_a = this.fillShadow) == null ? void 0 : _a.markClean();
    super.render(renderCtx);
  }
};
Line$1.className = "Line";
Line$1.defaultStyles = Object.assign({}, Shape$1.defaultStyles, {
  fill: void 0,
  strokeWidth: 1
});
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], Line$1.prototype, "x1", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], Line$1.prototype, "y1", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], Line$1.prototype, "x2", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], Line$1.prototype, "y2", 2);

// packages/ag-charts-community/src/scale/colorScale.ts
var convertColorStringToOklcha$1 = (v) => {
  const color = Color$1.fromString(v);
  const [l, c, h] = Color$1.RGBtoOKLCH(color.r, color.g, color.b);
  return { l, c, h, a: color.a };
};
var interpolateOklch$1 = (x, y, d) => {
  d = Math.min(Math.max(d, 0), 1);
  let h;
  let c;
  if (Number.isNaN(x.h) && Number.isNaN(y.h)) {
    h = 0;
    c = 0;
  } else if (Number.isNaN(x.h)) {
    h = y.h;
    c = y.c;
  } else if (Number.isNaN(y.h)) {
    h = x.h;
    c = x.c;
  } else {
    const xH = x.h;
    let yH = y.h;
    const deltaH = y.h - x.h;
    if (deltaH > 180) {
      yH -= 360;
    } else if (deltaH < -180) {
      yH += 360;
    }
    h = xH * (1 - d) + yH * d;
    c = x.c * (1 - d) + y.c * d;
  }
  const l = x.l * (1 - d) + y.l * d;
  const a = x.a * (1 - d) + y.a * d;
  return Color$1.fromOKLCH(l, c, h, a);
};
var ColorScale$1 = class {
  constructor() {
    this.invalid = true;
    this.domain = [0, 1];
    this.range = ["red", "blue"];
    this.parsedRange = this.range.map(convertColorStringToOklcha$1);
  }
  update() {
    const { domain, range: range3 } = this;
    if (domain.length < 2) {
      Logger$1.warnOnce("`colorDomain` should have at least 2 values.");
      if (domain.length === 0) {
        domain.push(0, 1);
      } else if (domain.length === 1) {
        domain.push(domain[0] + 1);
      }
    }
    for (let i = 1; i < domain.length; i++) {
      const a = domain[i - 1];
      const b = domain[i];
      if (a >= b) {
        Logger$1.warnOnce("`colorDomain` values should be supplied in ascending order.");
        domain.sort((a2, b2) => a2 - b2);
        break;
      }
    }
    if (range3.length < domain.length) {
      for (let i = range3.length; i < domain.length; i++) {
        range3.push(range3.length > 0 ? range3[0] : "black");
      }
    }
    this.parsedRange = this.range.map(convertColorStringToOklcha$1);
  }
  convert(x) {
    this.refresh();
    const { domain, range: range3, parsedRange } = this;
    const d0 = domain[0];
    const d1 = domain[domain.length - 1];
    const r0 = range3[0];
    const r1 = range3[range3.length - 1];
    if (x <= d0) {
      return r0;
    }
    if (x >= d1) {
      return r1;
    }
    let index;
    let q;
    if (domain.length === 2) {
      const t = (x - d0) / (d1 - d0);
      const step = 1 / (range3.length - 1);
      index = range3.length <= 2 ? 0 : Math.min(Math.floor(t * (range3.length - 1)), range3.length - 2);
      q = (t - index * step) / step;
    } else {
      for (index = 0; index < domain.length - 2; index++) {
        if (x < domain[index + 1]) {
          break;
        }
      }
      const a = domain[index];
      const b = domain[index + 1];
      q = (x - a) / (b - a);
    }
    const c0 = parsedRange[index];
    const c1 = parsedRange[index + 1];
    return interpolateOklch$1(c0, c1, q).toRgbaString();
  }
  refresh() {
    if (!this.invalid)
      return;
    this.invalid = false;
    this.update();
    if (this.invalid) {
      Logger$1.warnOnce("Expected update to not invalidate scale");
    }
  }
};
__decorateClass$1([
  Invalidating$1
], ColorScale$1.prototype, "domain", 2);
__decorateClass$1([
  Invalidating$1
], ColorScale$1.prototype, "range", 2);

// packages/ag-charts-community/src/scene/shape/linearGradientFill.ts
var LinearGradientFill$1 = class extends Shape$1 {
  constructor() {
    super(...arguments);
    this.direction = "to-right";
    this.stops = void 0;
    this._mask = void 0;
  }
  get mask() {
    return this._mask;
  }
  set mask(newMask) {
    if (this._mask != null) {
      this.removeChild(this._mask);
    }
    if (newMask != null) {
      this.appendChild(newMask);
    }
    this._mask = newMask;
  }
  isPointInPath(x, y) {
    var _a, _b;
    return (_b = (_a = this.mask) == null ? void 0 : _a.isPointInPath(x, y)) != null ? _b : false;
  }
  computeBBox() {
    var _a;
    return (_a = this.mask) == null ? void 0 : _a.computeBBox();
  }
  render(renderCtx) {
    const { mask, stops } = this;
    const { ctx, devicePixelRatio } = renderCtx;
    const pixelLength = 1 / devicePixelRatio;
    const maskBbox = mask == null ? void 0 : mask.computeTransformedBBox();
    if (mask == null || stops == null || maskBbox == null)
      return;
    if (mask.dirtyPath) {
      mask.updatePath();
      mask.dirtyPath = false;
    }
    ctx.save();
    ctx.beginPath();
    mask.path.draw(ctx);
    ctx.clip();
    ctx.resetTransform();
    const x0 = Math.floor(maskBbox.x);
    const x1 = Math.ceil(maskBbox.x + maskBbox.width);
    const y0 = Math.floor(maskBbox.y);
    const y1 = Math.ceil(maskBbox.y + maskBbox.height);
    const colorScale = new ColorScale$1();
    const [i0, i1] = this.direction === "to-right" ? [x0, x1] : [y0, y1];
    colorScale.domain = stops.map((_, index) => {
      return i0 + (i1 - i0) * index / (stops.length - 1);
    });
    colorScale.range = stops;
    colorScale.update();
    if (this.direction === "to-right") {
      const height = y1 - y0;
      for (let x = x0; x <= x1; x += pixelLength) {
        ctx.fillStyle = colorScale.convert(x);
        ctx.fillRect(x, y0, pixelLength, height);
      }
    } else {
      const width = x1 - x0;
      for (let y = y0; y <= y1; y += pixelLength) {
        ctx.fillStyle = colorScale.convert(y);
        ctx.fillRect(x0, y, width, pixelLength);
      }
    }
    ctx.restore();
  }
};
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], LinearGradientFill$1.prototype, "direction", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], LinearGradientFill$1.prototype, "stops", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], LinearGradientFill$1.prototype, "_mask", 2);

// packages/ag-charts-community/src/scene/shape/rect.ts
var epsilon$1 = 1e-6;
var cornerEdges$1 = (leadingEdge, trailingEdge, leadingInset, trailingInset, cornerRadius) => {
  let leadingClipped = false;
  let trailingClipped = false;
  let leading0 = trailingInset - Math.sqrt(Math.max(__pow$1(cornerRadius, 2) - __pow$1(leadingInset, 2), 0));
  let leading1 = 0;
  let trailing0 = 0;
  let trailing1 = leadingInset - Math.sqrt(Math.max(__pow$1(cornerRadius, 2) - __pow$1(trailingInset, 2), 0));
  if (leading0 > leadingEdge) {
    leadingClipped = true;
    leading0 = leadingEdge;
    leading1 = leadingInset - Math.sqrt(Math.max(__pow$1(cornerRadius, 2) - __pow$1(trailingInset - leadingEdge, 2)));
  } else if (leading0 < epsilon$1) {
    leading0 = 0;
  }
  if (trailing1 > trailingEdge) {
    trailingClipped = true;
    trailing0 = trailingInset - Math.sqrt(Math.max(__pow$1(cornerRadius, 2) - __pow$1(leadingInset - trailingEdge, 2)));
    trailing1 = trailingEdge;
  } else if (trailing1 < epsilon$1) {
    trailing1 = 0;
  }
  return { leading0, leading1, trailing0, trailing1, leadingClipped, trailingClipped };
};
var drawCorner$1 = (path, { x0, y0, x1, y1, cx, cy }, cornerRadius, move) => {
  if (move) {
    path.moveTo(x0, y0);
  }
  if (x0 !== x1 || y0 !== y1) {
    const r0 = Math.atan2(y0 - cy, x0 - cx);
    const r1 = Math.atan2(y1 - cy, x1 - cx);
    path.arc(cx, cy, cornerRadius, r0, r1);
  } else {
    path.lineTo(x0, y0);
  }
};
var insetCornerRadiusRect$1 = (path, x, y, width, height, cornerRadii, cornerRadiusBbox) => {
  let {
    topLeft: topLeftCornerRadius,
    topRight: topRightCornerRadius,
    bottomRight: bottomRightCornerRadius,
    bottomLeft: bottomLeftCornerRadius
  } = cornerRadii;
  const maxVerticalCornerRadius = Math.max(
    topLeftCornerRadius + bottomLeftCornerRadius,
    topRightCornerRadius + bottomRightCornerRadius
  );
  const maxHorizontalCornerRadius = Math.max(
    topLeftCornerRadius + topRightCornerRadius,
    bottomLeftCornerRadius + bottomRightCornerRadius
  );
  if (maxVerticalCornerRadius <= 0 && maxHorizontalCornerRadius <= 0) {
    path.rect(x, y, width, height);
    return;
  } else if (cornerRadiusBbox == null && topLeftCornerRadius === topRightCornerRadius && topLeftCornerRadius === bottomRightCornerRadius && topLeftCornerRadius === bottomLeftCornerRadius) {
    path.roundRect(x, y, width, height, topLeftCornerRadius);
    return;
  }
  if (width < 0) {
    x += width;
    width = Math.abs(width);
  }
  if (height < 0) {
    y += height;
    height = Math.abs(height);
  }
  if (cornerRadiusBbox != null) {
    const x0 = Math.max(x, cornerRadiusBbox.x);
    const x1 = Math.min(x + width, cornerRadiusBbox.x + cornerRadiusBbox.width);
    const y0 = Math.max(y, cornerRadiusBbox.y);
    const y1 = Math.min(y + height, cornerRadiusBbox.y + cornerRadiusBbox.height);
    x = x0;
    y = y0;
    width = x1 - x0;
    height = y1 - y0;
  }
  if (width <= 0 || height <= 0)
    return;
  cornerRadiusBbox != null ? cornerRadiusBbox : cornerRadiusBbox = new BBox$1(x, y, width, height);
  const borderScale = Math.max(
    maxVerticalCornerRadius / cornerRadiusBbox.height,
    maxHorizontalCornerRadius / cornerRadiusBbox.width,
    1
  );
  if (borderScale > 1) {
    topLeftCornerRadius /= borderScale;
    topRightCornerRadius /= borderScale;
    bottomRightCornerRadius /= borderScale;
    bottomLeftCornerRadius /= borderScale;
  }
  let drawTopLeftCorner = true;
  let drawTopRightCorner = true;
  let drawBottomRightCorner = true;
  let drawBottomLeftCorner = true;
  let topLeftCorner;
  let topRightCorner;
  let bottomRightCorner;
  let bottomLeftCorner;
  if (drawTopLeftCorner) {
    const nodes = cornerEdges$1(
      height,
      width,
      Math.max(cornerRadiusBbox.x + topLeftCornerRadius - x, 0),
      Math.max(cornerRadiusBbox.y + topLeftCornerRadius - y, 0),
      topLeftCornerRadius
    );
    if (nodes.leadingClipped)
      drawBottomLeftCorner = false;
    if (nodes.trailingClipped)
      drawTopRightCorner = false;
    const x0 = Math.max(x + nodes.leading1, x);
    const y0 = Math.max(y + nodes.leading0, y);
    const x1 = Math.max(x + nodes.trailing1, x);
    const y1 = Math.max(y + nodes.trailing0, y);
    const cx = cornerRadiusBbox.x + topLeftCornerRadius;
    const cy = cornerRadiusBbox.y + topLeftCornerRadius;
    topLeftCorner = { x0, y0, x1, y1, cx, cy };
  }
  if (drawTopRightCorner) {
    const nodes = cornerEdges$1(
      width,
      height,
      Math.max(cornerRadiusBbox.y + topRightCornerRadius - y, 0),
      Math.max(x + width - (cornerRadiusBbox.x + cornerRadiusBbox.width - topRightCornerRadius), 0),
      topRightCornerRadius
    );
    if (nodes.leadingClipped)
      drawTopLeftCorner = false;
    if (nodes.trailingClipped)
      drawBottomRightCorner = false;
    const x0 = Math.min(x + width - nodes.leading0, x + width);
    const y0 = Math.max(y + nodes.leading1, y);
    const x1 = Math.min(x + width - nodes.trailing0, x + width);
    const y1 = Math.max(y + nodes.trailing1, y);
    const cx = cornerRadiusBbox.x + cornerRadiusBbox.width - topRightCornerRadius;
    const cy = cornerRadiusBbox.y + topRightCornerRadius;
    topRightCorner = { x0, y0, x1, y1, cx, cy };
  }
  if (drawBottomRightCorner) {
    const nodes = cornerEdges$1(
      height,
      width,
      Math.max(x + width - (cornerRadiusBbox.x + cornerRadiusBbox.width - bottomRightCornerRadius), 0),
      Math.max(y + height - (cornerRadiusBbox.y + cornerRadiusBbox.height - bottomRightCornerRadius), 0),
      bottomRightCornerRadius
    );
    if (nodes.leadingClipped)
      drawTopRightCorner = false;
    if (nodes.trailingClipped)
      drawBottomLeftCorner = false;
    const x0 = Math.min(x + width - nodes.leading1, x + width);
    const y0 = Math.min(y + height - nodes.leading0, y + height);
    const x1 = Math.min(x + width - nodes.trailing1, x + width);
    const y1 = Math.min(y + height - nodes.trailing0, y + height);
    const cx = cornerRadiusBbox.x + cornerRadiusBbox.width - bottomRightCornerRadius;
    const cy = cornerRadiusBbox.y + cornerRadiusBbox.height - bottomRightCornerRadius;
    bottomRightCorner = { x0, y0, x1, y1, cx, cy };
  }
  if (drawBottomLeftCorner) {
    const nodes = cornerEdges$1(
      width,
      height,
      Math.max(y + height - (cornerRadiusBbox.y + cornerRadiusBbox.height - bottomLeftCornerRadius), 0),
      Math.max(cornerRadiusBbox.x + bottomLeftCornerRadius - x, 0),
      bottomLeftCornerRadius
    );
    if (nodes.leadingClipped)
      drawBottomRightCorner = false;
    if (nodes.trailingClipped)
      drawTopLeftCorner = false;
    const x0 = Math.max(x + nodes.leading0, x);
    const y0 = Math.min(y + height - nodes.leading1, y + height);
    const x1 = Math.max(x + nodes.trailing0, x);
    const y1 = Math.min(y + height - nodes.trailing1, y + height);
    const cx = cornerRadiusBbox.x + bottomLeftCornerRadius;
    const cy = cornerRadiusBbox.y + cornerRadiusBbox.height - bottomLeftCornerRadius;
    bottomLeftCorner = { x0, y0, x1, y1, cx, cy };
  }
  let didMove = false;
  if (drawTopLeftCorner && topLeftCorner != null) {
    drawCorner$1(path, topLeftCorner, topLeftCornerRadius, !didMove);
    didMove || (didMove = true);
  }
  if (drawTopRightCorner && topRightCorner != null) {
    drawCorner$1(path, topRightCorner, topRightCornerRadius, !didMove);
    didMove || (didMove = true);
  }
  if (drawBottomRightCorner && bottomRightCorner != null) {
    drawCorner$1(path, bottomRightCorner, bottomRightCornerRadius, !didMove);
    didMove || (didMove = true);
  }
  if (drawBottomLeftCorner && bottomLeftCorner != null) {
    drawCorner$1(path, bottomLeftCorner, bottomLeftCornerRadius, !didMove);
    didMove || (didMove = true);
  }
  path.closePath();
};
var Rect$1 = class extends Path$1 {
  constructor() {
    super(...arguments);
    this.borderPath = new Path2D$1();
    this.x = 0;
    this.y = 0;
    this.width = 10;
    this.height = 10;
    this.topLeftCornerRadius = 0;
    this.topRightCornerRadius = 0;
    this.bottomRightCornerRadius = 0;
    this.bottomLeftCornerRadius = 0;
    this.cornerRadiusBbox = void 0;
    this.crisp = false;
    this.lastUpdatePathStrokeWidth = Shape$1.defaultStyles.strokeWidth;
    this.effectiveStrokeWidth = Shape$1.defaultStyles.strokeWidth;
    /**
     * When the rectangle's width or height is less than a pixel
     * and crisp mode is on, the rectangle will still fit into the pixel,
     * but will be less opaque to make an effect of holding less space.
     */
    this.microPixelEffectOpacity = 1;
  }
  set cornerRadius(cornerRadius) {
    this.topLeftCornerRadius = cornerRadius;
    this.topRightCornerRadius = cornerRadius;
    this.bottomRightCornerRadius = cornerRadius;
    this.bottomLeftCornerRadius = cornerRadius;
  }
  isDirtyPath() {
    var _a;
    if (this.lastUpdatePathStrokeWidth !== this.strokeWidth) {
      return true;
    }
    return !!(this.path.isDirty() || this.borderPath.isDirty() || ((_a = this.clipPath) == null ? void 0 : _a.isDirty()));
  }
  updatePath() {
    var _a, _b, _c;
    const {
      path,
      borderPath,
      crisp,
      topLeftCornerRadius,
      topRightCornerRadius,
      bottomRightCornerRadius,
      bottomLeftCornerRadius
    } = this;
    let { x, y, width: w, height: h, strokeWidth, cornerRadiusBbox } = this;
    const pixelRatio = (_b = (_a = this.layerManager) == null ? void 0 : _a.canvas.pixelRatio) != null ? _b : 1;
    const pixelSize = 1 / pixelRatio;
    let microPixelEffectOpacity = 1;
    path.clear({ trackChanges: true });
    borderPath.clear({ trackChanges: true });
    if (crisp) {
      if (w <= pixelSize) {
        microPixelEffectOpacity *= w / pixelSize;
      }
      if (h <= pixelSize) {
        microPixelEffectOpacity *= h / pixelSize;
      }
      w = this.align(x, w);
      h = this.align(y, h);
      x = this.align(x);
      y = this.align(y);
      cornerRadiusBbox = cornerRadiusBbox != null ? new BBox$1(
        this.align(cornerRadiusBbox.x),
        this.align(cornerRadiusBbox.y),
        this.align(cornerRadiusBbox.x, cornerRadiusBbox.width),
        this.align(cornerRadiusBbox.y, cornerRadiusBbox.height)
      ) : void 0;
    }
    if (strokeWidth) {
      if (w < pixelSize) {
        const lx = x + pixelSize / 2;
        borderPath.moveTo(lx, y);
        borderPath.lineTo(lx, y + h);
        strokeWidth = pixelSize;
        this.borderClipPath = void 0;
      } else if (h < pixelSize) {
        const ly = y + pixelSize / 2;
        borderPath.moveTo(x, ly);
        borderPath.lineTo(x + w, ly);
        strokeWidth = pixelSize;
        this.borderClipPath = void 0;
      } else if (strokeWidth < w && strokeWidth < h) {
        const halfStrokeWidth = strokeWidth / 2;
        x += halfStrokeWidth;
        y += halfStrokeWidth;
        w -= strokeWidth;
        h -= strokeWidth;
        const adjustedCornerRadiusBbox = cornerRadiusBbox == null ? void 0 : cornerRadiusBbox.clone().shrink(halfStrokeWidth);
        const cornerRadii = {
          topLeft: topLeftCornerRadius > 0 ? topLeftCornerRadius - strokeWidth : 0,
          topRight: topRightCornerRadius > 0 ? topRightCornerRadius - strokeWidth : 0,
          bottomRight: bottomRightCornerRadius > 0 ? bottomRightCornerRadius - strokeWidth : 0,
          bottomLeft: bottomLeftCornerRadius > 0 ? bottomLeftCornerRadius - strokeWidth : 0
        };
        this.borderClipPath = void 0;
        insetCornerRadiusRect$1(path, x, y, w, h, cornerRadii, adjustedCornerRadiusBbox);
        insetCornerRadiusRect$1(borderPath, x, y, w, h, cornerRadii, adjustedCornerRadiusBbox);
      } else {
        this.borderClipPath = (_c = this.borderClipPath) != null ? _c : new Path2D$1();
        this.borderClipPath.clear({ trackChanges: true });
        this.borderClipPath.rect(x, y, w, h);
        borderPath.rect(x, y, w, h);
      }
    } else {
      const cornerRadii = {
        topLeft: topLeftCornerRadius,
        topRight: topRightCornerRadius,
        bottomRight: bottomRightCornerRadius,
        bottomLeft: bottomLeftCornerRadius
      };
      this.borderClipPath = void 0;
      insetCornerRadiusRect$1(path, x, y, w, h, cornerRadii, cornerRadiusBbox);
    }
    this.effectiveStrokeWidth = strokeWidth;
    this.lastUpdatePathStrokeWidth = strokeWidth;
    this.microPixelEffectOpacity = microPixelEffectOpacity;
  }
  computeBBox() {
    const { x, y, width, height } = this;
    return new BBox$1(x, y, width, height);
  }
  isPointInPath(x, y) {
    const point = this.transformPoint(x, y);
    const bbox = this.computeBBox();
    return bbox.containsPoint(point.x, point.y);
  }
  applyFillAlpha(ctx) {
    const { fillOpacity, microPixelEffectOpacity, opacity } = this;
    const { globalAlpha } = ctx;
    ctx.globalAlpha = globalAlpha * opacity * fillOpacity * microPixelEffectOpacity;
  }
  renderStroke(ctx) {
    const { stroke, effectiveStrokeWidth, borderPath, borderClipPath, opacity, microPixelEffectOpacity } = this;
    const borderActive = !!stroke && !!effectiveStrokeWidth;
    if (borderActive) {
      const { strokeOpacity, lineDash, lineDashOffset, lineCap, lineJoin } = this;
      if (borderClipPath) {
        borderClipPath.draw(ctx);
        ctx.clip();
      }
      borderPath.draw(ctx);
      const { globalAlpha } = ctx;
      ctx.strokeStyle = stroke;
      ctx.globalAlpha = globalAlpha * opacity * strokeOpacity * microPixelEffectOpacity;
      ctx.lineWidth = effectiveStrokeWidth;
      if (lineDash) {
        ctx.setLineDash(lineDash);
      }
      if (lineDashOffset) {
        ctx.lineDashOffset = lineDashOffset;
      }
      if (lineCap) {
        ctx.lineCap = lineCap;
      }
      if (lineJoin) {
        ctx.lineJoin = lineJoin;
      }
      ctx.stroke();
      ctx.globalAlpha = globalAlpha;
    }
  }
};
Rect$1.className = "Rect";
__decorateClass$1([
  ScenePathChangeDetection$1()
], Rect$1.prototype, "x", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Rect$1.prototype, "y", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Rect$1.prototype, "width", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Rect$1.prototype, "height", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Rect$1.prototype, "topLeftCornerRadius", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Rect$1.prototype, "topRightCornerRadius", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Rect$1.prototype, "bottomRightCornerRadius", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Rect$1.prototype, "bottomLeftCornerRadius", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Rect$1.prototype, "cornerRadiusBbox", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Rect$1.prototype, "crisp", 2);

// packages/ag-charts-community/src/util/sector.ts
function isPointInSector$1(x, y, sector) {
  const radius = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
  const { innerRadius, outerRadius } = sector;
  if (sector.startAngle === sector.endAngle || radius < Math.min(innerRadius, outerRadius) || radius > Math.max(innerRadius, outerRadius)) {
    return false;
  }
  const startAngle = normalizeAngle180$1(sector.startAngle);
  const endAngle = normalizeAngle180$1(sector.endAngle);
  const angle = Math.atan2(y, x);
  return startAngle < endAngle ? angle <= endAngle && angle >= startAngle : angle <= endAngle && angle >= -Math.PI || angle >= startAngle && angle <= Math.PI;
}
function lineCollidesSector$1(line, sector) {
  const { startAngle, endAngle, innerRadius, outerRadius } = sector;
  const outerStart = { x: outerRadius * Math.cos(startAngle), y: outerRadius * Math.sin(startAngle) };
  const outerEnd = { x: outerRadius * Math.cos(endAngle), y: outerRadius * Math.sin(endAngle) };
  const innerStart = innerRadius === 0 ? { x: 0, y: 0 } : { x: innerRadius * Math.cos(startAngle), y: innerRadius * Math.sin(startAngle) };
  const innerEnd = innerRadius === 0 ? { x: 0, y: 0 } : { x: innerRadius * Math.cos(endAngle), y: innerRadius * Math.sin(endAngle) };
  return segmentIntersection$1(
    line.start.x,
    line.start.y,
    line.end.x,
    line.end.y,
    outerStart.x,
    outerStart.y,
    innerStart.x,
    innerStart.y
  ) != null || segmentIntersection$1(
    line.start.x,
    line.start.y,
    line.end.x,
    line.end.y,
    outerEnd.x,
    outerEnd.y,
    innerEnd.x,
    innerEnd.y
  ) != null || arcIntersections$1(
    0,
    0,
    outerRadius,
    startAngle,
    endAngle,
    true,
    line.start.x,
    line.start.y,
    line.end.x,
    line.end.y
  ).length > 0;
}
function boxCollidesSector$1(box, sector) {
  const topLeft = { x: box.x, y: box.y };
  const topRight = { x: box.x + box.width, y: box.y };
  const bottomLeft = { x: box.x, y: box.y + box.height };
  const bottomRight = { x: box.x + box.width, y: box.y + box.height };
  return lineCollidesSector$1({ start: topLeft, end: topRight }, sector) || lineCollidesSector$1({ start: bottomLeft, end: bottomRight }, sector);
}

// packages/ag-charts-community/src/scene/shape/sector.ts
var Sector$1 = class extends Path$1 {
  constructor() {
    super(...arguments);
    this.centerX = 0;
    this.centerY = 0;
    this.innerRadius = 10;
    this.outerRadius = 20;
    this.startAngle = 0;
    this.endAngle = Math.PI * 2;
    this.angleOffset = 0;
    this.inset = 0;
  }
  computeBBox() {
    const radius = this.outerRadius;
    return new BBox$1(this.centerX - radius, this.centerY - radius, radius * 2, radius * 2);
  }
  updatePath() {
    const path = this.path;
    const { angleOffset, inset } = this;
    const startAngle = this.startAngle + angleOffset;
    const endAngle = this.endAngle + angleOffset;
    const sweep = startAngle <= endAngle ? endAngle - startAngle : Math.PI * 2 - (startAngle - endAngle);
    const innerRadius = Math.max(Math.min(this.innerRadius, this.outerRadius) + inset, 0);
    const outerRadius = Math.max(Math.max(this.innerRadius, this.outerRadius) - inset, 0);
    const fullPie = sweep >= 2 * Math.PI;
    const centerX = this.centerX;
    const centerY = this.centerY;
    path.clear();
    if (fullPie) {
      path.arc(centerX, centerY, outerRadius, startAngle, endAngle);
      if (innerRadius > inset) {
        path.moveTo(centerX + innerRadius * Math.cos(endAngle), centerY + innerRadius * Math.sin(endAngle));
        path.arc(centerX, centerY, innerRadius, endAngle, startAngle, true);
      }
    } else {
      const innerAngleOffset = innerRadius > 0 ? inset / innerRadius : 0;
      const outerAngleOffset = outerRadius > 0 ? inset / outerRadius : 0;
      const outerAngleExceeded = sweep < 2 * outerAngleOffset;
      if (outerAngleExceeded)
        return;
      const innerAngleExceeded = innerRadius <= inset || sweep < 2 * innerAngleOffset;
      if (innerAngleExceeded) {
        const x = sweep < Math.PI * 0.5 ? inset * (1 + Math.cos(sweep)) / Math.sin(sweep) : NaN;
        let r;
        if (x > 0 && x < outerRadius) {
          r = Math.max(Math.hypot(inset, x), innerRadius);
        } else {
          r = innerRadius;
        }
        const midAngle = startAngle + sweep * 0.5;
        path.moveTo(centerX + r * Math.cos(midAngle), centerY + r * Math.sin(midAngle));
      } else {
        path.moveTo(
          centerX + innerRadius * Math.cos(startAngle + innerAngleOffset),
          centerY + innerRadius * Math.sin(startAngle + innerAngleOffset)
        );
      }
      path.arc(centerX, centerY, outerRadius, startAngle + outerAngleOffset, endAngle - outerAngleOffset);
      if (innerAngleExceeded) ; else if (innerRadius > 0) {
        path.arc(
          centerX,
          centerY,
          innerRadius,
          endAngle - innerAngleOffset,
          startAngle + innerAngleOffset,
          true
        );
      } else {
        path.lineTo(centerX, centerY);
      }
    }
    path.closePath();
    this.dirtyPath = false;
  }
  isPointInPath(x, y) {
    const { angleOffset } = this;
    const startAngle = this.startAngle + angleOffset;
    const endAngle = this.endAngle + angleOffset;
    const innerRadius = Math.min(this.innerRadius, this.outerRadius);
    const outerRadius = Math.max(this.innerRadius, this.outerRadius);
    const point = this.transformPoint(x, y);
    return isPointInSector$1(point.x, point.y, { startAngle, endAngle, innerRadius, outerRadius });
  }
};
Sector$1.className = "Sector";
__decorateClass$1([
  ScenePathChangeDetection$1()
], Sector$1.prototype, "centerX", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Sector$1.prototype, "centerY", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Sector$1.prototype, "innerRadius", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Sector$1.prototype, "outerRadius", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Sector$1.prototype, "startAngle", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Sector$1.prototype, "endAngle", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Sector$1.prototype, "angleOffset", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Sector$1.prototype, "inset", 2);

// packages/ag-charts-community/src/util/numberFormat.ts
var group$1 = (content) => `(${content})`;
var optionalGroup$1 = (content) => `${group$1(content)}?`;
var nonCapturingGroup$1 = (content) => optionalGroup$1(`?:${content}`);
var formatRegEx$1 = (() => {
  const fill = ".";
  const align = "[<>=^]";
  const sign = "[+\\-( ]";
  const symbol = "[$\u20AC\xA3\xA5\u20A3\u20B9#]";
  const zero = "0";
  const width = "\\d+";
  const comma = ",";
  const precision = "\\d+";
  const tilde = "~";
  const type = "[%a-z]";
  return new RegExp(
    [
      "^",
      nonCapturingGroup$1(`${optionalGroup$1(fill)}${group$1(align)}`),
      optionalGroup$1(sign),
      optionalGroup$1(symbol),
      optionalGroup$1(zero),
      optionalGroup$1(width),
      optionalGroup$1(comma),
      nonCapturingGroup$1(`\\.${group$1(precision)}`),
      optionalGroup$1(tilde),
      optionalGroup$1(type),
      "$"
    ].join(""),
    "i"
  );
})();
var surroundedRegEx$1 = (() => {
  const prefix = ".*?";
  const content = ".+?";
  const suffix = ".*?";
  return new RegExp(["^", group$1(prefix), `#\\{${group$1(content)}\\}`, group$1(suffix), "$"].join(""));
})();
function parseFormatter$1(formatter) {
  let prefix;
  let suffix;
  const surrounded = surroundedRegEx$1.exec(formatter);
  if (surrounded) {
    [, prefix, formatter, suffix] = surrounded;
  }
  const match = formatRegEx$1.exec(formatter);
  if (!match) {
    throw new Error(`The number formatter is invalid: ${formatter}`);
  }
  const [, fill, align, sign, symbol, zero, width, comma, precision, trim, type] = match;
  return {
    fill,
    align,
    sign,
    symbol,
    zero,
    width: parseInt(width),
    comma,
    precision: parseInt(precision),
    trim: Boolean(trim),
    type,
    prefix,
    suffix
  };
}
function format$1(formatter) {
  const options = typeof formatter === "string" ? parseFormatter$1(formatter) : formatter;
  const { fill, align, sign = "-", symbol, zero, width, comma, type, prefix = "", suffix = "", precision } = options;
  let { trim } = options;
  const precisionIsNaN = precision === void 0 || isNaN(precision);
  let formatBody;
  if (!type) {
    formatBody = decimalTypes$1["g"];
    trim = true;
  } else if (type in decimalTypes$1 && type in integerTypes$1) {
    formatBody = precisionIsNaN ? integerTypes$1[type] : decimalTypes$1[type];
  } else if (type in decimalTypes$1) {
    formatBody = decimalTypes$1[type];
  } else if (type in integerTypes$1) {
    formatBody = integerTypes$1[type];
  } else {
    throw new Error(`The number formatter type is invalid: ${type}`);
  }
  let formatterPrecision;
  if (precision == null || precisionIsNaN) {
    formatterPrecision = type ? 6 : 12;
  } else {
    formatterPrecision = precision;
  }
  return (n) => {
    let result = formatBody(n, formatterPrecision);
    if (trim) {
      result = removeTrailingZeros$1(result);
    }
    if (comma) {
      result = insertSeparator$1(result, comma);
    }
    result = addSign$1(n, result, sign);
    if (symbol && symbol !== "#") {
      result = `${symbol}${result}`;
    }
    if (symbol === "#" && type === "x") {
      result = `0x${result}`;
    }
    if (type === "s") {
      result = `${result}${getSIPrefix$1(n)}`;
    }
    if (type === "%" || type === "p") {
      result = `${result}%`;
    }
    if (width != null && !isNaN(width)) {
      result = addPadding$1(result, width, fill != null ? fill : zero, align);
    }
    result = `${prefix}${result}${suffix}`;
    return result;
  };
}
var absFloor$1 = (n) => Math.floor(Math.abs(n));
var integerTypes$1 = {
  b: (n) => absFloor$1(n).toString(2),
  c: (n) => String.fromCharCode(n),
  d: (n) => Math.round(Math.abs(n)).toFixed(0),
  o: (n) => absFloor$1(n).toString(8),
  x: (n) => absFloor$1(n).toString(16),
  X: (n) => integerTypes$1.x(n).toUpperCase(),
  n: (n) => integerTypes$1.d(n),
  "%": (n) => `${absFloor$1(n * 100).toFixed(0)}`
};
var decimalTypes$1 = {
  e: (n, f) => Math.abs(n).toExponential(f),
  E: (n, f) => decimalTypes$1.e(n, f).toUpperCase(),
  f: (n, f) => Math.abs(n).toFixed(f),
  F: (n, f) => decimalTypes$1.f(n, f).toUpperCase(),
  g: (n, f) => {
    if (n === 0) {
      return "0";
    }
    const a = Math.abs(n);
    const p = Math.floor(Math.log10(a));
    if (p >= -4 && p < f) {
      return a.toFixed(f - 1 - p);
    }
    return a.toExponential(f - 1);
  },
  G: (n, f) => decimalTypes$1.g(n, f).toUpperCase(),
  n: (n, f) => decimalTypes$1.g(n, f),
  p: (n, f) => decimalTypes$1.r(n * 100, f),
  r: (n, f) => {
    if (n === 0) {
      return "0";
    }
    const a = Math.abs(n);
    const p = Math.floor(Math.log10(a));
    const q = p - (f - 1);
    if (q <= 0) {
      return a.toFixed(-q);
    }
    const x = Math.pow(10, q);
    return (Math.round(a / x) * x).toFixed();
  },
  s: (n, f) => {
    const p = getSIPrefixPower$1(n);
    return decimalTypes$1.r(n / Math.pow(10, p), f);
  },
  "%": (n, f) => decimalTypes$1.f(n * 100, f)
};
function removeTrailingZeros$1(numString) {
  return numString.replace(/\.0+$/, "").replace(/(\.[1-9])0+$/, "$1");
}
function insertSeparator$1(numString, separator) {
  let dotIndex = numString.indexOf(".");
  if (dotIndex < 0) {
    dotIndex = numString.length;
  }
  const integerChars = numString.substring(0, dotIndex).split("");
  const fractionalPart = numString.substring(dotIndex);
  for (let i = integerChars.length - 3; i > 0; i -= 3) {
    integerChars.splice(i, 0, separator);
  }
  return `${integerChars.join("")}${fractionalPart}`;
}
function getSIPrefix$1(n) {
  return siPrefixes$1[getSIPrefixPower$1(n)];
}
function getSIPrefixPower$1(n) {
  const power = Math.log10(Math.abs(n));
  const p = Math.floor(power / 3) * 3;
  return Math.max(minSIPrefix$1, Math.min(maxSIPrefix$1, p));
}
var minSIPrefix$1 = -24;
var maxSIPrefix$1 = 24;
var siPrefixes$1 = {
  [minSIPrefix$1]: "y",
  [-21]: "z",
  [-18]: "a",
  [-15]: "f",
  [-12]: "p",
  [-9]: "n",
  [-6]: "\xB5",
  [-3]: "m",
  [0]: "",
  [3]: "k",
  [6]: "M",
  [9]: "G",
  [12]: "T",
  [15]: "P",
  [18]: "E",
  [21]: "Z",
  [maxSIPrefix$1]: "Y"
};
var minusSign$1 = "\u2212";
function addSign$1(num, numString, signType = "") {
  if (signType === "(") {
    return num >= 0 ? numString : `(${numString})`;
  }
  const plusSign = signType === "+" ? "+" : "";
  return `${num >= 0 ? plusSign : minusSign$1}${numString}`;
}
function addPadding$1(numString, width, fill = " ", align = ">") {
  let result = numString;
  if (align === ">" || !align) {
    result = result.padStart(width, fill);
  } else if (align === "<") {
    result = result.padEnd(width, fill);
  } else if (align === "^") {
    const padWidth = Math.max(0, width - result.length);
    const padLeft = Math.ceil(padWidth / 2);
    const padRight = Math.floor(padWidth / 2);
    result = result.padStart(padLeft + result.length, fill);
    result = result.padEnd(padRight + result.length, fill);
  }
  return result;
}
function tickFormat$1(ticks, formatter) {
  const options = parseFormatter$1(formatter != null ? formatter : ",f");
  const { precision } = options;
  if (precision == null || isNaN(precision)) {
    if (options.type === "f" || options.type === "%") {
      options.precision = Math.max(
        ...ticks.map((x) => {
          if (typeof x !== "number" || x === 0) {
            return 0;
          }
          const l = Math.floor(Math.log10(Math.abs(x)));
          const digits = options.type ? 6 : 12;
          const exp = x.toExponential(digits - 1).replace(/\.?0+e/, "e");
          const dotIndex = exp.indexOf(".");
          if (dotIndex < 0) {
            return l >= 0 ? 0 : -l;
          }
          const s = exp.indexOf("e") - dotIndex;
          return Math.max(0, s - l - 1);
        })
      );
    } else if (!options.type || options.type in decimalTypes$1) {
      options.precision = Math.max(
        ...ticks.map((x) => {
          if (typeof x !== "number") {
            return 0;
          }
          const exp = x.toExponential((options.type ? 6 : 12) - 1).replace(/\.?0+e/, "e");
          return exp.substring(0, exp.indexOf("e")).replace(".", "").length;
        })
      );
    }
  }
  const f = format$1(options);
  return (n) => f(Number(n));
}

// packages/ag-charts-community/src/util/ticks.ts
var createNumericTicks$1 = (fractionDigits, takingValues = []) => Object.assign(takingValues, { fractionDigits });
function ticks_default$1(start, stop, count2, minCount, maxCount) {
  if (count2 < 2) {
    return range$1(start, stop, stop - start);
  }
  const step = tickStep$1(start, stop, count2, minCount, maxCount);
  if (isNaN(step)) {
    return createNumericTicks$1(0);
  }
  start = Math.ceil(start / step) * step;
  stop = Math.floor(stop / step) * step;
  return range$1(start, stop, step);
}
var tickMultipliers$1 = [1, 2, 5, 10];
function tickStep$1(a, b, count2, minCount = 0, maxCount = Infinity) {
  const extent2 = Math.abs(b - a);
  const rawStep = extent2 / count2;
  const power = Math.floor(Math.log10(rawStep));
  const step = Math.pow(10, power);
  const m = tickMultipliers$1.map((multiplier) => {
    const s = multiplier * step;
    const c = Math.ceil(extent2 / s);
    const isWithinBounds = c >= minCount && c <= maxCount;
    const diffCount = Math.abs(c - count2);
    return { multiplier, isWithinBounds, diffCount };
  }).sort((a2, b2) => {
    if (a2.isWithinBounds !== b2.isWithinBounds) {
      return a2.isWithinBounds ? -1 : 1;
    }
    return a2.diffCount - b2.diffCount;
  })[0].multiplier;
  if (!m || isNaN(m)) {
    return NaN;
  }
  return m * step;
}
function singleTickDomain$1(a, b) {
  const extent2 = Math.abs(b - a);
  const power = Math.floor(Math.log10(extent2));
  const step = Math.pow(10, power);
  const roundStart = a > b ? Math.ceil : Math.floor;
  const roundStop = b < a ? Math.floor : Math.ceil;
  return tickMultipliers$1.map((multiplier) => {
    const s = multiplier * step;
    const start = roundStart(a / s) * s;
    const end = roundStop(b / s) * s;
    const error = 1 - extent2 / Math.abs(end - start);
    const domain = [start, end];
    return { error, domain };
  }).sort((a2, b2) => a2.error - b2.error)[0].domain;
}
function range$1(start, stop, step) {
  const d0 = Math.min(start, stop);
  const d1 = Math.max(start, stop);
  const fractionalDigits = countFractionDigits$1(step);
  const f = Math.pow(10, fractionalDigits);
  const n = Math.ceil((d1 - d0) / step);
  const values = createNumericTicks$1(fractionalDigits);
  for (let i = 0; i <= n; i++) {
    const value = d0 + step * i;
    values.push(Math.round(value * f) / f);
  }
  return values;
}

// packages/ag-charts-community/src/scale/linearScale.ts
var LinearScale$2 = class extends ContinuousScale$1 {
  constructor() {
    super([0, 1], [0, 1]);
    this.type = "linear";
  }
  toDomain(d) {
    return d;
  }
  ticks() {
    var _a;
    const count2 = (_a = this.tickCount) != null ? _a : ContinuousScale$1.defaultTickCount;
    if (!this.domain || this.domain.length < 2 || count2 < 1 || this.domain.some((d) => !isFinite(d))) {
      return [];
    }
    this.refresh();
    const [d0, d1] = this.getDomain();
    const { interval } = this;
    if (interval) {
      const step = Math.abs(interval);
      if (!this.isDenseInterval({ start: d0, stop: d1, interval: step })) {
        return range$1(d0, d1, step);
      }
    }
    return ticks_default$1(d0, d1, count2, this.minTickCount, this.maxTickCount);
  }
  update() {
    if (!this.domain || this.domain.length < 2) {
      return;
    }
    if (this.nice) {
      this.updateNiceDomain();
    }
  }
  getTickStep(start, stop) {
    var _a, _b;
    const count2 = (_a = this.tickCount) != null ? _a : ContinuousScale$1.defaultTickCount;
    return (_b = this.interval) != null ? _b : tickStep$1(start, stop, count2, this.minTickCount, this.maxTickCount);
  }
  /**
   * Extends the domain so that it starts and ends on nice round values.
   */
  updateNiceDomain() {
    var _a;
    const count2 = (_a = this.tickCount) != null ? _a : ContinuousScale$1.defaultTickCount;
    if (count2 < 1) {
      this.niceDomain = [...this.domain];
      return;
    }
    let [start, stop] = this.domain;
    if (count2 === 1) {
      [start, stop] = singleTickDomain$1(start, stop);
    } else {
      const roundStart = start > stop ? Math.ceil : Math.floor;
      const roundStop = stop < start ? Math.floor : Math.ceil;
      const maxAttempts = 4;
      for (let i = 0; i < maxAttempts; i++) {
        const prev0 = start;
        const prev1 = stop;
        const step = this.getTickStep(start, stop);
        const [d0, d1] = this.domain;
        if (step >= 1) {
          start = roundStart(d0 / step) * step;
          stop = roundStop(d1 / step) * step;
        } else {
          const s = 1 / step;
          start = roundStart(d0 * s) / s;
          stop = roundStop(d1 * s) / s;
        }
        if (start === prev0 && stop === prev1) {
          break;
        }
      }
    }
    this.niceDomain = [start, stop];
  }
  tickFormat({ ticks, specifier }) {
    return tickFormat$1(ticks != null ? ticks : this.ticks(), specifier);
  }
};

// packages/ag-charts-community/src/chart/marker/marker.ts
var Marker$1 = class extends Path$1 {
  constructor() {
    super(...arguments);
    this.x = 0;
    this.y = 0;
    this.size = 12;
  }
  computeBBox() {
    const { x, y, size } = this;
    const half = size / 2;
    return new BBox$1(x - half, y - half, size, size);
  }
  applyPath(s, moves) {
    const { path } = this;
    let { x, y } = this;
    path.clear();
    for (const { x: mx, y: my, t } of moves) {
      x += mx * s;
      y += my * s;
      if (t === "move") {
        path.moveTo(x, y);
      } else {
        path.lineTo(x, y);
      }
    }
    path.closePath();
  }
};
__decorateClass$1([
  ScenePathChangeDetection$1()
], Marker$1.prototype, "x", 2);
__decorateClass$1([
  ScenePathChangeDetection$1()
], Marker$1.prototype, "y", 2);
__decorateClass$1([
  ScenePathChangeDetection$1({ convertor: Math.abs })
], Marker$1.prototype, "size", 2);

// packages/ag-charts-community/src/chart/marker/circle.ts
var Circle$1 = class extends Marker$1 {
  updatePath() {
    const { x, y, path, size } = this;
    const r = size / 2;
    path.clear();
    path.arc(x, y, r, 0, Math.PI * 2);
    path.closePath();
  }
};
Circle$1.className = "Circle";

// packages/ag-charts-community/src/chart/marker/cross.ts
var _Cross$1 = class _Cross extends Marker$1 {
  updatePath() {
    const s = this.size / 4.2;
    super.applyPath(s, _Cross.moves);
  }
};
_Cross$1.className = "Cross";
_Cross$1.moves = [
  { x: -1, y: 0, t: "move" },
  { x: -1, y: -1 },
  { x: 1, y: -1 },
  { x: 1, y: 1 },
  { x: 1, y: -1 },
  { x: 1, y: 1 },
  { x: -1, y: 1 },
  { x: 1, y: 1 },
  { x: -1, y: 1 },
  { x: -1, y: -1 },
  { x: -1, y: 1 },
  { x: -1, y: -1 }
];
var Cross$1 = _Cross$1;

// packages/ag-charts-community/src/chart/marker/diamond.ts
var _Diamond$1 = class _Diamond extends Marker$1 {
  updatePath() {
    const s = this.size / 2;
    super.applyPath(s, _Diamond.moves);
  }
};
_Diamond$1.className = "Diamond";
_Diamond$1.moves = [
  { x: 0, y: -1, t: "move" },
  { x: 1, y: 1 },
  { x: -1, y: 1 },
  { x: -1, y: -1 },
  { x: 1, y: -1 }
];
var Diamond$1 = _Diamond$1;

// packages/ag-charts-community/src/chart/marker/heart.ts
var Heart$1 = class extends Marker$1 {
  rad(degree) {
    return degree / 180 * Math.PI;
  }
  updatePath() {
    const { x, path, size, rad } = this;
    const r = size / 4;
    const y = this.y + r / 2;
    path.clear();
    path.arc(x - r, y - r, r, rad(130), rad(330));
    path.arc(x + r, y - r, r, rad(220), rad(50));
    path.lineTo(x, y + r);
    path.closePath();
  }
};
Heart$1.className = "Heart";

// packages/ag-charts-community/src/chart/marker/plus.ts
var _Plus$1 = class _Plus extends Marker$1 {
  updatePath() {
    const s = this.size / 3;
    super.applyPath(s, _Plus.moves);
  }
};
_Plus$1.className = "Plus";
_Plus$1.moves = [
  { x: -0.5, y: -0.5, t: "move" },
  { x: 0, y: -1 },
  { x: 1, y: 0 },
  { x: 0, y: 1 },
  { x: 1, y: 0 },
  { x: 0, y: 1 },
  { x: -1, y: 0 },
  { x: 0, y: 1 },
  { x: -1, y: 0 },
  { x: 0, y: -1 },
  { x: -1, y: 0 },
  { x: 0, y: -1 }
];
var Plus$1 = _Plus$1;

// packages/ag-charts-community/src/chart/marker/square.ts
var Square$1 = class extends Marker$1 {
  updatePath() {
    const { path, x, y } = this;
    const hs = this.size / 2;
    path.clear();
    path.moveTo(this.align(x - hs), this.align(y - hs));
    path.lineTo(this.align(x + hs), this.align(y - hs));
    path.lineTo(this.align(x + hs), this.align(y + hs));
    path.lineTo(this.align(x - hs), this.align(y + hs));
    path.closePath();
  }
};
Square$1.className = "Square";

// packages/ag-charts-community/src/chart/marker/triangle.ts
var _Triangle$1 = class _Triangle extends Marker$1 {
  updatePath() {
    const s = this.size * 1.1;
    super.applyPath(s, _Triangle.moves);
  }
};
_Triangle$1.className = "Triangle";
_Triangle$1.moves = [
  { x: 0, y: -0.48, t: "move" },
  { x: 0.5, y: 0.87 },
  { x: -1, y: 0 }
];
var Triangle$1 = _Triangle$1;

// packages/ag-charts-community/src/chart/marker/util.ts
var MARKER_SHAPES$1 = {
  circle: Circle$1,
  cross: Cross$1,
  diamond: Diamond$1,
  heart: Heart$1,
  plus: Plus$1,
  square: Square$1,
  triangle: Triangle$1
};
var MARKER_SUPPORTED_SHAPES$1 = Object.keys(MARKER_SHAPES$1);
function isMarkerShape$1(shape) {
  return typeof shape === "string" && MARKER_SUPPORTED_SHAPES$1.includes(shape);
}
function getMarker$2(shape = Square$1) {
  if (isMarkerShape$1(shape)) {
    return MARKER_SHAPES$1[shape];
  }
  if (typeof shape === "function") {
    return shape;
  }
  return Square$1;
}

// packages/ag-charts-community/src/scene/image.ts
var Image$1 = class extends Node$1 {
  constructor(sourceImage) {
    super();
    this.x = 0;
    this.y = 0;
    this.width = 0;
    this.height = 0;
    this.opacity = 1;
    this.sourceImage = sourceImage;
  }
  render(renderCtx) {
    const { ctx, forceRender, stats } = renderCtx;
    if (this.dirty === 0 /* NONE */ && !forceRender) {
      if (stats)
        stats.nodesSkipped++;
      return;
    }
    this.computeTransformMatrix();
    this.matrix.toContext(ctx);
    const image = this.sourceImage;
    ctx.globalAlpha = this.opacity;
    ctx.drawImage(image, 0, 0, image.width, image.height, this.x, this.y, this.width, this.height);
    super.render(renderCtx);
  }
};
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], Image$1.prototype, "x", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], Image$1.prototype, "y", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], Image$1.prototype, "width", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], Image$1.prototype, "height", 2);
__decorateClass$1([
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], Image$1.prototype, "opacity", 2);

// packages/ag-charts-community/src/integrated-charts-scene.ts
var motion$1 = __spreadValues$1(__spreadValues$1({}, fromToMotion_exports$1), resetMotion_exports$1);

// packages/ag-charts-community/src/util/value.ts
var isString2$1 = (v) => typeof v === "string";
var isStringObject$2 = (v) => !!v && Object.hasOwn(v, "toString") && isString2$1(v.toString());
var isDate2$1 = (v) => v instanceof Date && !isNaN(+v);
function isDiscrete$1(value) {
  return isString2$1(value) || isStringObject$2(value);
}
function isContinuous$1(value) {
  const isNumberObject = (v) => !!v && Object.hasOwn(v, "valueOf") && isNumber2$1(v.valueOf());
  const isDate3 = (v) => v instanceof Date && !isNaN(+v);
  return isNumber2$1(value) || isNumberObject(value) || isDate3(value);
}
function checkDatum$1(value, isContinuousScale) {
  if (isContinuousScale && isContinuous$1(value)) {
    return value;
  } else if (!isContinuousScale) {
    if (!isDiscrete$1(value)) {
      return String(value);
    }
    return value;
  }
  return void 0;
}
var isNumber2$1 = (v) => typeof v === "number" && Number.isFinite(v);

// packages/ag-charts-community/src/chart/crossline/crossLine.ts
var MATCHING_CROSSLINE_TYPE$1 = (property) => {
  return property === "value" ? predicateWithMessage$1(
    (_, ctx) => ctx.target["type"] === "line",
    (ctx) => ctx.target["type"] === "range" ? `crossLine type 'range' to have a 'range' property instead of 'value'` : `crossLine property 'type' to be 'line'`
  ) : predicateWithMessage$1(
    (_, ctx) => ctx.target["type"] === "range",
    (ctx) => ctx.target.type === "line" ? `crossLine type 'line' to have a 'value' property instead of 'range'` : `crossLine property 'type' to be 'range'`
  );
};
var validateCrossLineValues$1 = (type, value, range3, scale2) => {
  const lineCrossLine = type === "line" && value !== void 0;
  const rangeCrossLine = type === "range" && range3 !== void 0;
  if (!lineCrossLine && !rangeCrossLine) {
    return true;
  }
  const [start, end] = range3 != null ? range3 : [value, void 0];
  const isContinuous2 = ContinuousScale$1.is(scale2);
  const validStart = checkDatum$1(start, isContinuous2) != null && !isNaN(scale2.convert(start));
  const validEnd = checkDatum$1(end, isContinuous2) != null && !isNaN(scale2.convert(end));
  if (lineCrossLine && validStart || rangeCrossLine && validStart && validEnd) {
    return true;
  }
  const message = [`Expecting crossLine`];
  if (rangeCrossLine) {
    if (!validStart) {
      message.push(`range start ${stringify$1(start)}`);
    }
    if (!validEnd) {
      message.push(`${!validStart ? "and " : ""}range end ${stringify$1(end)}`);
    }
  } else {
    message.push(`value ${stringify$1(start)}`);
  }
  message.push(`to match the axis scale domain.`);
  Logger$1.warnOnce(message.join(" "));
  return false;
};

// packages/ag-charts-community/src/chart/crossline/crossLineLabelPosition.ts
var horizontalCrosslineTranslationDirections$1 = {
  top: { xTranslationDirection: 0, yTranslationDirection: -1 },
  bottom: { xTranslationDirection: 0, yTranslationDirection: 1 },
  left: { xTranslationDirection: -1, yTranslationDirection: 0 },
  right: { xTranslationDirection: 1, yTranslationDirection: 0 },
  topLeft: { xTranslationDirection: 1, yTranslationDirection: -1 },
  topRight: { xTranslationDirection: -1, yTranslationDirection: -1 },
  bottomLeft: { xTranslationDirection: 1, yTranslationDirection: 1 },
  bottomRight: { xTranslationDirection: -1, yTranslationDirection: 1 },
  inside: { xTranslationDirection: 0, yTranslationDirection: 0 },
  insideLeft: { xTranslationDirection: 1, yTranslationDirection: 0 },
  insideRight: { xTranslationDirection: -1, yTranslationDirection: 0 },
  insideTop: { xTranslationDirection: 0, yTranslationDirection: 1 },
  insideBottom: { xTranslationDirection: 0, yTranslationDirection: -1 },
  insideTopLeft: { xTranslationDirection: 1, yTranslationDirection: 1 },
  insideBottomLeft: { xTranslationDirection: 1, yTranslationDirection: -1 },
  insideTopRight: { xTranslationDirection: -1, yTranslationDirection: 1 },
  insideBottomRight: { xTranslationDirection: -1, yTranslationDirection: -1 }
};
var verticalCrossLineTranslationDirections$1 = {
  top: { xTranslationDirection: 1, yTranslationDirection: 0 },
  bottom: { xTranslationDirection: -1, yTranslationDirection: 0 },
  left: { xTranslationDirection: 0, yTranslationDirection: -1 },
  right: { xTranslationDirection: 0, yTranslationDirection: 1 },
  topLeft: { xTranslationDirection: -1, yTranslationDirection: -1 },
  topRight: { xTranslationDirection: -1, yTranslationDirection: 1 },
  bottomLeft: { xTranslationDirection: 1, yTranslationDirection: -1 },
  bottomRight: { xTranslationDirection: 1, yTranslationDirection: 1 },
  inside: { xTranslationDirection: 0, yTranslationDirection: 0 },
  insideLeft: { xTranslationDirection: 0, yTranslationDirection: 1 },
  insideRight: { xTranslationDirection: 0, yTranslationDirection: -1 },
  insideTop: { xTranslationDirection: -1, yTranslationDirection: 0 },
  insideBottom: { xTranslationDirection: 1, yTranslationDirection: 0 },
  insideTopLeft: { xTranslationDirection: -1, yTranslationDirection: 1 },
  insideBottomLeft: { xTranslationDirection: 1, yTranslationDirection: 1 },
  insideTopRight: { xTranslationDirection: -1, yTranslationDirection: -1 },
  insideBottomRight: { xTranslationDirection: 1, yTranslationDirection: -1 }
};
function calculateLabelTranslation$1({
  yDirection,
  padding = 0,
  position = "top",
  bbox
}) {
  const crossLineTranslationDirections = yDirection ? horizontalCrosslineTranslationDirections$1 : verticalCrossLineTranslationDirections$1;
  const { xTranslationDirection, yTranslationDirection } = crossLineTranslationDirections[position];
  const w = yDirection ? bbox.width : bbox.height;
  const h = yDirection ? bbox.height : bbox.width;
  const xTranslation = xTranslationDirection * (padding + w / 2);
  const yTranslation = yTranslationDirection * (padding + h / 2);
  return {
    xTranslation,
    yTranslation
  };
}
function calculateLabelChartPadding$1({
  yDirection,
  bbox,
  padding = 0,
  position = "top"
}) {
  const chartPadding = {};
  if (position.startsWith("inside"))
    return chartPadding;
  if (position === "top" && !yDirection) {
    chartPadding.top = padding + bbox.height;
  } else if (position === "bottom" && !yDirection) {
    chartPadding.bottom = padding + bbox.height;
  } else if (position === "left" && yDirection) {
    chartPadding.left = padding + bbox.width;
  } else if (position === "right" && yDirection) {
    chartPadding.right = padding + bbox.width;
  }
  return chartPadding;
}
var POSITION_TOP_COORDINATES$1 = ({ yDirection, xEnd, yStart, yEnd }) => {
  if (yDirection) {
    return { x: xEnd / 2, y: yStart };
  } else {
    return { x: xEnd, y: !isNaN(yEnd) ? (yStart + yEnd) / 2 : yStart };
  }
};
var POSITION_LEFT_COORDINATES$1 = ({ yDirection, xStart, xEnd, yStart, yEnd }) => {
  if (yDirection) {
    return { x: xStart, y: !isNaN(yEnd) ? (yStart + yEnd) / 2 : yStart };
  } else {
    return { x: xEnd / 2, y: yStart };
  }
};
var POSITION_RIGHT_COORDINATES$1 = ({ yDirection, xEnd, yStart, yEnd }) => {
  if (yDirection) {
    return { x: xEnd, y: !isNaN(yEnd) ? (yStart + yEnd) / 2 : yStart };
  } else {
    return { x: xEnd / 2, y: !isNaN(yEnd) ? yEnd : yStart };
  }
};
var POSITION_BOTTOM_COORDINATES$1 = ({ yDirection, xStart, xEnd, yStart, yEnd }) => {
  if (yDirection) {
    return { x: xEnd / 2, y: !isNaN(yEnd) ? yEnd : yStart };
  } else {
    return { x: xStart, y: !isNaN(yEnd) ? (yStart + yEnd) / 2 : yStart };
  }
};
var POSITION_INSIDE_COORDINATES$1 = ({ xEnd, yStart, yEnd }) => {
  return { x: xEnd / 2, y: !isNaN(yEnd) ? (yStart + yEnd) / 2 : yStart };
};
var POSITION_TOP_LEFT_COORDINATES$1 = ({ yDirection, xStart, xEnd, yStart }) => {
  if (yDirection) {
    return { x: xStart / 2, y: yStart };
  } else {
    return { x: xEnd, y: yStart };
  }
};
var POSITION_BOTTOM_LEFT_COORDINATES$1 = ({ yDirection, xStart, yStart, yEnd }) => {
  if (yDirection) {
    return { x: xStart, y: !isNaN(yEnd) ? yEnd : yStart };
  } else {
    return { x: xStart, y: yStart };
  }
};
var POSITION_TOP_RIGHT_COORDINATES$1 = ({ yDirection, xEnd, yStart, yEnd }) => {
  if (yDirection) {
    return { x: xEnd, y: yStart };
  } else {
    return { x: xEnd, y: !isNaN(yEnd) ? yEnd : yStart };
  }
};
var POSITION_BOTTOM_RIGHT_COORDINATES$1 = ({ yDirection, xStart, xEnd, yStart, yEnd }) => {
  if (yDirection) {
    return { x: xEnd, y: !isNaN(yEnd) ? yEnd : yStart };
  } else {
    return { x: xStart, y: !isNaN(yEnd) ? yEnd : yStart };
  }
};
var labelDirectionHandling$1 = {
  top: { c: POSITION_TOP_COORDINATES$1 },
  bottom: { c: POSITION_BOTTOM_COORDINATES$1 },
  left: { c: POSITION_LEFT_COORDINATES$1 },
  right: { c: POSITION_RIGHT_COORDINATES$1 },
  topLeft: { c: POSITION_TOP_LEFT_COORDINATES$1 },
  topRight: { c: POSITION_TOP_RIGHT_COORDINATES$1 },
  bottomLeft: { c: POSITION_BOTTOM_LEFT_COORDINATES$1 },
  bottomRight: { c: POSITION_BOTTOM_RIGHT_COORDINATES$1 },
  inside: { c: POSITION_INSIDE_COORDINATES$1 },
  insideLeft: { c: POSITION_LEFT_COORDINATES$1 },
  insideRight: { c: POSITION_RIGHT_COORDINATES$1 },
  insideTop: { c: POSITION_TOP_COORDINATES$1 },
  insideBottom: { c: POSITION_BOTTOM_COORDINATES$1 },
  insideTopLeft: { c: POSITION_TOP_LEFT_COORDINATES$1 },
  insideBottomLeft: { c: POSITION_BOTTOM_LEFT_COORDINATES$1 },
  insideTopRight: { c: POSITION_TOP_RIGHT_COORDINATES$1 },
  insideBottomRight: { c: POSITION_BOTTOM_RIGHT_COORDINATES$1 }
};

// packages/ag-charts-community/src/chart/crossline/cartesianCrossLine.ts
var CROSSLINE_LABEL_POSITION$1 = UNION$1(
  [
    "top",
    "left",
    "right",
    "bottom",
    "topLeft",
    "topRight",
    "bottomLeft",
    "bottomRight",
    "inside",
    "insideLeft",
    "insideRight",
    "insideTop",
    "insideBottom",
    "insideTopLeft",
    "insideBottomLeft",
    "insideTopRight",
    "insideBottomRight"
  ],
  "crossLine label position"
);
var CartesianCrossLineLabel$1 = class {
  constructor() {
    this.enabled = void 0;
    this.text = void 0;
    this.fontStyle = void 0;
    this.fontWeight = void 0;
    this.fontSize = 14;
    this.fontFamily = "Verdana, sans-serif";
    this.padding = 5;
    this.color = "rgba(87, 87, 87, 1)";
    this.position = void 0;
    this.rotation = void 0;
    this.parallel = void 0;
  }
};
__decorateClass$1([
  Validate$1(BOOLEAN$1, { optional: true })
], CartesianCrossLineLabel$1.prototype, "enabled", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], CartesianCrossLineLabel$1.prototype, "text", 2);
__decorateClass$1([
  Validate$1(FONT_STYLE$1, { optional: true })
], CartesianCrossLineLabel$1.prototype, "fontStyle", 2);
__decorateClass$1([
  Validate$1(FONT_WEIGHT$1, { optional: true })
], CartesianCrossLineLabel$1.prototype, "fontWeight", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], CartesianCrossLineLabel$1.prototype, "fontSize", 2);
__decorateClass$1([
  Validate$1(STRING$1)
], CartesianCrossLineLabel$1.prototype, "fontFamily", 2);
__decorateClass$1([
  Validate$1(NUMBER$1)
], CartesianCrossLineLabel$1.prototype, "padding", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true })
], CartesianCrossLineLabel$1.prototype, "color", 2);
__decorateClass$1([
  Validate$1(CROSSLINE_LABEL_POSITION$1, { optional: true })
], CartesianCrossLineLabel$1.prototype, "position", 2);
__decorateClass$1([
  Validate$1(DEGREE$1, { optional: true })
], CartesianCrossLineLabel$1.prototype, "rotation", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1, { optional: true })
], CartesianCrossLineLabel$1.prototype, "parallel", 2);
var _CartesianCrossLine$1 = class _CartesianCrossLine {
  constructor() {
    this.id = createId$2(this);
    this.enabled = void 0;
    this.type = void 0;
    this.range = void 0;
    this.value = void 0;
    this.fill = void 0;
    this.fillOpacity = void 0;
    this.stroke = void 0;
    this.strokeWidth = void 0;
    this.strokeOpacity = void 0;
    this.lineDash = void 0;
    this.label = new CartesianCrossLineLabel$1();
    this.scale = void 0;
    this.clippedRange = [-Infinity, Infinity];
    this.gridLength = 0;
    this.sideFlag = -1;
    this.parallelFlipRotation = 0;
    this.regularFlipRotation = 0;
    this.direction = "x" /* X */;
    this.group = new Group$1({ name: `${this.id}`, layer: true, zIndex: _CartesianCrossLine.LINE_LAYER_ZINDEX });
    this.labelGroup = new Group$1({ name: `${this.id}`, layer: true, zIndex: _CartesianCrossLine.LABEL_LAYER_ZINDEX });
    this.crossLineRange = new Range$1();
    this.crossLineLabel = new Text$1();
    this.labelPoint = void 0;
    this.data = [];
    this.startLine = false;
    this.endLine = false;
    this.isRange = false;
    const { group: group2, labelGroup, crossLineRange, crossLineLabel } = this;
    group2.append(crossLineRange);
    labelGroup.append(crossLineLabel);
    crossLineRange.pointerEvents = 1 /* None */;
  }
  update(visible) {
    const { enabled, data, type, value, range: range3, scale: scale2 } = this;
    if (!type || !scale2 || !enabled || !visible || !validateCrossLineValues$1(type, value, range3, scale2) || data.length === 0) {
      this.group.visible = false;
      this.labelGroup.visible = false;
      return;
    }
    this.group.visible = visible;
    this.labelGroup.visible = visible;
    this.group.zIndex = this.getZIndex(this.isRange);
    this.updateNodes();
  }
  calculateLayout(visible, reversedAxis) {
    if (!visible) {
      return;
    }
    const dataCreated = this.createNodeData(reversedAxis);
    if (!dataCreated) {
      return;
    }
    const { sideFlag, gridLength, data } = this;
    const boxes = [];
    const x1 = 0;
    const x2 = sideFlag * gridLength;
    const y1 = data[0];
    const y2 = data[1];
    const crossLineBox = new BBox$1(Math.min(x1, x2), Math.min(y1, y2), Math.abs(x1 - x2), Math.abs(y1 - y2));
    boxes.push(crossLineBox);
    const labelBox = this.computeLabelBBox();
    if (labelBox) {
      boxes.push(labelBox);
    }
    return BBox$1.merge(boxes);
  }
  updateNodes() {
    this.updateRangeNode();
    if (this.label.enabled) {
      this.updateLabel();
      this.positionLabel();
    }
  }
  createNodeData(reversedAxis) {
    var _a, _b, _c;
    const {
      scale: scale2,
      gridLength,
      sideFlag,
      direction,
      label: { position = "top" },
      clippedRange,
      strokeWidth = 0
    } = this;
    this.data = [];
    if (!scale2) {
      return false;
    }
    const bandwidth = (_a = scale2.bandwidth) != null ? _a : 0;
    const step = (_b = scale2.step) != null ? _b : 0;
    const padding = (reversedAxis ? -1 : 1) * (scale2 instanceof BandScale$6 ? (step - bandwidth) / 2 : 0);
    const [xStart, xEnd] = [0, sideFlag * gridLength];
    let [yStart, yEnd] = this.getRange();
    let [clampedYStart, clampedYEnd] = [
      Number(scale2.convert(yStart, { clampMode: "clamped" })) - padding,
      scale2.convert(yEnd, { clampMode: "clamped" }) + bandwidth + padding
    ];
    clampedYStart = clampArray$1(clampedYStart, clippedRange);
    clampedYEnd = clampArray$1(clampedYEnd, clippedRange);
    [yStart, yEnd] = [Number(scale2.convert(yStart)), scale2.convert(yEnd) + bandwidth];
    const validRange = (yStart === clampedYStart || yEnd === clampedYEnd || clampedYStart !== clampedYEnd) && Math.abs(clampedYEnd - clampedYStart) > 0;
    if (validRange && clampedYStart > clampedYEnd) {
      [clampedYStart, clampedYEnd] = [clampedYEnd, clampedYStart];
      [yStart, yEnd] = [yEnd, yStart];
    }
    if (yStart - padding >= clampedYStart)
      yStart -= padding;
    if (yEnd + padding <= clampedYEnd)
      yEnd += padding;
    this.isRange = validRange;
    this.startLine = strokeWidth > 0 && yStart >= clampedYStart && yStart <= clampedYStart + padding;
    this.endLine = strokeWidth > 0 && yEnd >= clampedYEnd - bandwidth - padding && yEnd <= clampedYEnd;
    if (!validRange && !this.startLine && !this.endLine) {
      return false;
    }
    this.data = [clampedYStart, clampedYEnd];
    if (this.label.enabled) {
      const yDirection = direction === "y" /* Y */;
      const { c = POSITION_TOP_COORDINATES$1 } = (_c = labelDirectionHandling$1[position]) != null ? _c : {};
      const { x: labelX, y: labelY } = c({
        yDirection,
        xStart,
        xEnd,
        yStart: clampedYStart,
        yEnd: clampedYEnd
      });
      this.labelPoint = {
        x: labelX,
        y: labelY
      };
    }
    return true;
  }
  updateRangeNode() {
    var _a;
    const {
      crossLineRange,
      sideFlag,
      gridLength,
      data,
      startLine,
      endLine,
      isRange,
      fill,
      fillOpacity,
      stroke,
      strokeWidth,
      lineDash
    } = this;
    crossLineRange.x1 = 0;
    crossLineRange.x2 = sideFlag * gridLength;
    crossLineRange.y1 = data[0];
    crossLineRange.y2 = data[1];
    crossLineRange.startLine = startLine;
    crossLineRange.endLine = endLine;
    crossLineRange.isRange = isRange;
    crossLineRange.fill = fill;
    crossLineRange.fillOpacity = fillOpacity != null ? fillOpacity : 1;
    crossLineRange.stroke = stroke;
    crossLineRange.strokeWidth = strokeWidth != null ? strokeWidth : 1;
    crossLineRange.strokeOpacity = (_a = this.strokeOpacity) != null ? _a : 1;
    crossLineRange.lineDash = lineDash;
  }
  updateLabel() {
    const { crossLineLabel, label } = this;
    if (!label.text) {
      return;
    }
    crossLineLabel.fontStyle = label.fontStyle;
    crossLineLabel.fontWeight = label.fontWeight;
    crossLineLabel.fontSize = label.fontSize;
    crossLineLabel.fontFamily = label.fontFamily;
    crossLineLabel.fill = label.color;
    crossLineLabel.text = label.text;
  }
  positionLabel() {
    const {
      crossLineLabel,
      labelPoint: { x = void 0, y = void 0 } = {},
      label: { parallel, rotation, position = "top", padding = 0 },
      direction,
      parallelFlipRotation,
      regularFlipRotation
    } = this;
    if (x === void 0 || y === void 0) {
      return;
    }
    const { defaultRotation, configuredRotation } = calculateLabelRotation$1({
      rotation,
      parallel,
      regularFlipRotation,
      parallelFlipRotation
    });
    crossLineLabel.rotation = defaultRotation + configuredRotation;
    crossLineLabel.textBaseline = "middle";
    crossLineLabel.textAlign = "center";
    const bbox = crossLineLabel.computeTransformedBBox();
    if (!bbox) {
      return;
    }
    const yDirection = direction === "y" /* Y */;
    const { xTranslation, yTranslation } = calculateLabelTranslation$1({
      yDirection,
      padding,
      position,
      bbox
    });
    crossLineLabel.translationX = x + xTranslation;
    crossLineLabel.translationY = y + yTranslation;
  }
  getZIndex(isRange = false) {
    if (isRange) {
      return _CartesianCrossLine.RANGE_LAYER_ZINDEX;
    }
    return _CartesianCrossLine.LINE_LAYER_ZINDEX;
  }
  getRange() {
    var _a;
    const { value, range: range3, scale: scale2 } = this;
    const isContinuous2 = ContinuousScale$1.is(scale2);
    const start = (_a = range3 == null ? void 0 : range3[0]) != null ? _a : value;
    let end = range3 == null ? void 0 : range3[1];
    if (!isContinuous2 && end === void 0) {
      end = start;
    }
    if (isContinuous2 && start === end) {
      end = void 0;
    }
    return [start, end];
  }
  computeLabelBBox() {
    const { label } = this;
    if (!label.enabled) {
      return void 0;
    }
    const tempText = new Text$1();
    tempText.fontFamily = label.fontFamily;
    tempText.fontSize = label.fontSize;
    tempText.fontStyle = label.fontStyle;
    tempText.fontWeight = label.fontWeight;
    tempText.text = label.text;
    const {
      labelPoint: { x = void 0, y = void 0 } = {},
      label: { parallel, rotation, position = "top", padding = 0 },
      direction,
      parallelFlipRotation,
      regularFlipRotation
    } = this;
    if (x === void 0 || y === void 0) {
      return void 0;
    }
    const { configuredRotation } = calculateLabelRotation$1({
      rotation,
      parallel,
      regularFlipRotation,
      parallelFlipRotation
    });
    tempText.rotation = configuredRotation;
    tempText.textBaseline = "middle";
    tempText.textAlign = "center";
    const bbox = tempText.computeTransformedBBox();
    if (!bbox) {
      return void 0;
    }
    const yDirection = direction === "y" /* Y */;
    const { xTranslation, yTranslation } = calculateLabelTranslation$1({
      yDirection,
      padding,
      position,
      bbox
    });
    tempText.translationX = x + xTranslation;
    tempText.translationY = y + yTranslation;
    return tempText.computeTransformedBBox();
  }
  calculatePadding(padding) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const {
      isRange,
      startLine,
      endLine,
      direction,
      label: { padding: labelPadding = 0, position = "top" }
    } = this;
    if (!isRange && !startLine && !endLine) {
      return;
    }
    const crossLineLabelBBox = this.computeLabelBBox();
    const labelX = crossLineLabelBBox == null ? void 0 : crossLineLabelBBox.x;
    const labelY = crossLineLabelBBox == null ? void 0 : crossLineLabelBBox.y;
    if (!crossLineLabelBBox || labelX == void 0 || labelY == void 0) {
      return;
    }
    const chartPadding = calculateLabelChartPadding$1({
      yDirection: direction === "y" /* Y */,
      padding: labelPadding,
      position,
      bbox: crossLineLabelBBox
    });
    padding.left = Math.max((_a = padding.left) != null ? _a : 0, (_b = chartPadding.left) != null ? _b : 0);
    padding.right = Math.max((_c = padding.right) != null ? _c : 0, (_d = chartPadding.right) != null ? _d : 0);
    padding.top = Math.max((_e = padding.top) != null ? _e : 0, (_f = chartPadding.top) != null ? _f : 0);
    padding.bottom = Math.max((_g = padding.bottom) != null ? _g : 0, (_h = chartPadding.bottom) != null ? _h : 0);
  }
};
_CartesianCrossLine$1.LINE_LAYER_ZINDEX = 8 /* SERIES_CROSSLINE_LINE_ZINDEX */;
_CartesianCrossLine$1.RANGE_LAYER_ZINDEX = 3 /* SERIES_CROSSLINE_RANGE_ZINDEX */;
_CartesianCrossLine$1.LABEL_LAYER_ZINDEX = 7 /* SERIES_LABEL_ZINDEX */;
_CartesianCrossLine$1.className = "CrossLine";
__decorateClass$1([
  Validate$1(BOOLEAN$1, { optional: true })
], _CartesianCrossLine$1.prototype, "enabled", 2);
__decorateClass$1([
  Validate$1(UNION$1(["range", "line"], "a crossLine type"), { optional: true })
], _CartesianCrossLine$1.prototype, "type", 2);
__decorateClass$1([
  Validate$1(AND$1(MATCHING_CROSSLINE_TYPE$1("range"), ARRAY$1.restrict({ length: 2 })), {
    optional: true
  })
], _CartesianCrossLine$1.prototype, "range", 2);
__decorateClass$1([
  Validate$1(MATCHING_CROSSLINE_TYPE$1("value"), { optional: true })
], _CartesianCrossLine$1.prototype, "value", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true })
], _CartesianCrossLine$1.prototype, "fill", 2);
__decorateClass$1([
  Validate$1(RATIO$1, { optional: true })
], _CartesianCrossLine$1.prototype, "fillOpacity", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true })
], _CartesianCrossLine$1.prototype, "stroke", 2);
__decorateClass$1([
  Validate$1(NUMBER$1, { optional: true })
], _CartesianCrossLine$1.prototype, "strokeWidth", 2);
__decorateClass$1([
  Validate$1(RATIO$1, { optional: true })
], _CartesianCrossLine$1.prototype, "strokeOpacity", 2);
__decorateClass$1([
  Validate$1(LINE_DASH$1, { optional: true })
], _CartesianCrossLine$1.prototype, "lineDash", 2);
var CartesianCrossLine$1 = _CartesianCrossLine$1;

// packages/ag-charts-community/src/module/moduleMap.ts
var ModuleMap$1 = class {
  constructor() {
    this.moduleMap = /* @__PURE__ */ new Map();
  }
  addModule(module, moduleFactory) {
    if (this.moduleMap.has(module.optionsKey)) {
      throw new Error(`AG Charts - module already initialised: ${module.optionsKey}`);
    }
    this.moduleMap.set(module.optionsKey, moduleFactory(module));
  }
  removeModule(module) {
    var _a;
    const moduleKey = isString$3(module) ? module : module.optionsKey;
    (_a = this.moduleMap.get(moduleKey)) == null ? void 0 : _a.destroy();
    this.moduleMap.delete(moduleKey);
  }
  isModuleEnabled(module) {
    return this.moduleMap.has(isString$3(module) ? module : module.optionsKey);
  }
  getModule(module) {
    return this.moduleMap.get(isString$3(module) ? module : module.optionsKey);
  }
  get modules() {
    return this.moduleMap.values();
  }
  mapValues(callback) {
    return Array.from(this.moduleMap.values()).map(callback);
  }
  destroy() {
    for (const optionsKey of this.moduleMap.keys()) {
      this.removeModule({ optionsKey });
    }
  }
};

// packages/ag-charts-community/src/motion/states.ts
var StateMachine$1 = class {
  constructor(initialState, states, preTransitionCb) {
    this.states = states;
    this.preTransitionCb = preTransitionCb;
    this.debug = Debug$1.create(true, "animation");
    this.state = initialState;
    this.debug(`%c${this.constructor.name} | init -> ${initialState}`, "color: green");
  }
  transition(event, data) {
    var _a, _b;
    const currentStateConfig = this.states[this.state];
    const destinationTransition = currentStateConfig == null ? void 0 : currentStateConfig[event];
    if (!destinationTransition) {
      this.debug(`%c${this.constructor.name} | ${this.state} -> ${event} -> ${this.state}`, "color: grey");
      return;
    }
    let destinationState = this.state;
    if (typeof destinationTransition === "string") {
      destinationState = destinationTransition;
    } else if (typeof destinationTransition === "object") {
      destinationState = destinationTransition.target;
    }
    this.debug(`%c${this.constructor.name} | ${this.state} -> ${event} -> ${destinationState}`, "color: green");
    (_a = this.preTransitionCb) == null ? void 0 : _a.call(this, this.state, destinationState);
    this.state = destinationState;
    if (typeof destinationTransition === "function") {
      destinationTransition(data);
    } else if (typeof destinationTransition === "object") {
      (_b = destinationTransition.action) == null ? void 0 : _b.call(destinationTransition, data);
    }
    return this.state;
  }
};

// packages/ag-charts-community/src/scale/logScale.ts
var identity$1 = (x) => x;
var _LogScale$1 = class _LogScale extends ContinuousScale$1 {
  constructor() {
    super([1, 10], [0, 1]);
    this.type = "log";
    this.base = 10;
    this.baseLog = identity$1;
    this.basePow = identity$1;
    this.log = (x) => {
      const start = Math.min(this.domain[0], this.domain[1]);
      return start >= 0 ? this.baseLog(x) : -this.baseLog(-x);
    };
    this.pow = (x) => {
      const start = Math.min(this.domain[0], this.domain[1]);
      return start >= 0 ? this.basePow(x) : -this.basePow(-x);
    };
    this.defaultClampMode = "clamped";
  }
  toDomain(d) {
    return d;
  }
  transform(x) {
    const start = Math.min(this.domain[0], this.domain[1]);
    return start >= 0 ? Math.log(x) : -Math.log(-x);
  }
  transformInvert(x) {
    const start = Math.min(this.domain[0], this.domain[1]);
    return start >= 0 ? Math.exp(x) : -Math.exp(-x);
  }
  refresh() {
    if (this.base <= 0) {
      this.base = 0;
      Logger$1.warnOnce("expecting a finite Number greater than to 0");
    }
    super.refresh();
  }
  update() {
    if (!this.domain || this.domain.length < 2) {
      return;
    }
    this.updateLogFn();
    this.updatePowFn();
    if (this.nice) {
      this.updateNiceDomain();
    }
  }
  updateLogFn() {
    const { base } = this;
    let log;
    if (base === 10) {
      log = Math.log10;
    } else if (base === Math.E) {
      log = Math.log;
    } else if (base === 2) {
      log = Math.log2;
    } else {
      const logBase = Math.log(base);
      log = (x) => Math.log(x) / logBase;
    }
    this.baseLog = log;
  }
  updatePowFn() {
    const { base } = this;
    let pow;
    if (base === 10) {
      pow = _LogScale.pow10;
    } else if (base === Math.E) {
      pow = Math.exp;
    } else {
      pow = (x) => Math.pow(base, x);
    }
    this.basePow = pow;
  }
  updateNiceDomain() {
    const [d0, d1] = this.domain;
    const roundStart = d0 > d1 ? Math.ceil : Math.floor;
    const roundStop = d1 < d0 ? Math.floor : Math.ceil;
    const n0 = this.pow(roundStart(this.log(d0)));
    const n1 = this.pow(roundStop(this.log(d1)));
    this.niceDomain = [n0, n1];
  }
  static pow10(x) {
    return x >= 0 ? Math.pow(10, x) : 1 / Math.pow(10, -x);
  }
  ticks() {
    var _a;
    const count2 = (_a = this.tickCount) != null ? _a : 10;
    if (!this.domain || this.domain.length < 2 || count2 < 1) {
      return [];
    }
    this.refresh();
    const base = this.base;
    const [d0, d1] = this.getDomain();
    const start = Math.min(d0, d1);
    const stop = Math.max(d0, d1);
    let p0 = this.log(start);
    let p1 = this.log(stop);
    if (this.interval) {
      const step = Math.abs(this.interval);
      const absDiff = Math.abs(p1 - p0);
      let ticks2 = range$1(p0, p1, Math.min(absDiff, step));
      ticks2 = createNumericTicks$1(
        ticks2.fractionDigits,
        ticks2.map((x) => this.pow(x)).filter((t) => t >= start && t <= stop)
      );
      if (!this.isDenseInterval({ start, stop, interval: step, count: ticks2.length })) {
        return ticks2;
      }
    }
    const isBaseInteger = base % 1 === 0;
    const isDiffLarge = p1 - p0 >= count2;
    if (!isBaseInteger || isDiffLarge) {
      let ticks2 = ticks_default$1(p0, p1, Math.min(p1 - p0, count2));
      ticks2 = createNumericTicks$1(
        ticks2.fractionDigits,
        ticks2.map((x) => this.pow(x))
      );
      return ticks2;
    }
    const ticks = [];
    const isPositive = start > 0;
    p0 = Math.floor(p0) - 1;
    p1 = Math.round(p1) + 1;
    const min = Math.min(...this.range);
    const max = Math.max(...this.range);
    const availableSpacing = (max - min) / count2;
    let lastTickPosition = Infinity;
    for (let p = p0; p <= p1; p++) {
      const nextMagnitudeTickPosition = this.convert(this.pow(p + 1));
      for (let k = 1; k < base; k++) {
        const q = isPositive ? k : base - k + 1;
        const t = this.pow(p) * q;
        const tickPosition = this.convert(t);
        const prevSpacing = Math.abs(lastTickPosition - tickPosition);
        const nextSpacing = Math.abs(tickPosition - nextMagnitudeTickPosition);
        const fits = prevSpacing >= availableSpacing && nextSpacing >= availableSpacing;
        if (t >= start && t <= stop && (k === 1 || fits)) {
          ticks.push(t);
          lastTickPosition = tickPosition;
        }
      }
    }
    return ticks;
  }
  tickFormat({
    count: count2,
    ticks,
    specifier
  }) {
    const { base } = this;
    if (specifier == null) {
      specifier = base === 10 ? ".0e" : ",";
    }
    if (typeof specifier === "string") {
      specifier = format$1(specifier);
    }
    if (count2 === Infinity) {
      return specifier;
    }
    if (ticks == null) {
      this.ticks();
    }
    return (d) => {
      return specifier(d);
    };
  }
};
__decorateClass$1([
  Invalidating$1
], _LogScale$1.prototype, "base", 2);
var LogScale$1 = _LogScale$1;

// packages/ag-charts-community/src/util/timeFormat.ts
var CONSTANTS$1 = {
  periods: ["AM", "PM"],
  days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
};
function dayOfYear$1(date, startOfYear = new Date(date.getFullYear(), 0, 1)) {
  const startOffset = date.getTimezoneOffset() - startOfYear.getTimezoneOffset();
  const timeDiff = date.getTime() - startOfYear.getTime() + startOffset * 6e4;
  const timeOneDay = 36e5 * 24;
  return Math.floor(timeDiff / timeOneDay);
}
function weekOfYear$1(date, startDay) {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const startOfYearDay = startOfYear.getDay();
  const firstWeekStartOffset = (startDay - startOfYearDay + 7) % 7;
  const startOffset = new Date(date.getFullYear(), 0, firstWeekStartOffset + 1);
  if (startOffset <= date) {
    return Math.floor(dayOfYear$1(date, startOffset) / 7) + 1;
  }
  return 0;
}
var SUNDAY$1 = 0;
var MONDAY$1 = 1;
var THURSDAY$1 = 4;
function isoWeekOfYear$1(date, year2 = date.getFullYear()) {
  const firstOfYear = new Date(year2, 0, 1);
  const firstOfYearDay = firstOfYear.getDay();
  const firstThursdayOffset = (THURSDAY$1 - firstOfYearDay + 7) % 7;
  const startOffset = new Date(year2, 0, firstThursdayOffset - (THURSDAY$1 - MONDAY$1) + 1);
  if (startOffset <= date) {
    return Math.floor(dayOfYear$1(date, startOffset) / 7) + 1;
  }
  return isoWeekOfYear$1(date, year2 - 1);
}
function timezone$1(date) {
  const offset4 = date.getTimezoneOffset();
  const unsignedOffset = Math.abs(offset4);
  const sign = offset4 > 0 ? "-" : "+";
  return `${sign}${pad$1(Math.floor(unsignedOffset / 60), 2, "0")}${pad$1(Math.floor(unsignedOffset % 60), 2, "0")}`;
}
var FORMATTERS$1 = {
  a: (d) => CONSTANTS$1.shortDays[d.getDay()],
  A: (d) => CONSTANTS$1.days[d.getDay()],
  b: (d) => CONSTANTS$1.shortMonths[d.getMonth()],
  B: (d) => CONSTANTS$1.months[d.getMonth()],
  c: "%x, %X",
  d: (d, p) => pad$1(d.getDate(), 2, p != null ? p : "0"),
  e: "%_d",
  f: (d, p) => pad$1(d.getMilliseconds() * 1e3, 6, p != null ? p : "0"),
  H: (d, p) => pad$1(d.getHours(), 2, p != null ? p : "0"),
  I: (d, p) => {
    const hours = d.getHours() % 12;
    return hours === 0 ? "12" : pad$1(hours, 2, p != null ? p : "0");
  },
  j: (d, p) => pad$1(dayOfYear$1(d) + 1, 3, p != null ? p : "0"),
  m: (d, p) => pad$1(d.getMonth() + 1, 2, p != null ? p : "0"),
  M: (d, p) => pad$1(d.getMinutes(), 2, p != null ? p : "0"),
  L: (d, p) => pad$1(d.getMilliseconds(), 3, p != null ? p : "0"),
  p: (d) => d.getHours() < 12 ? "AM" : "PM",
  Q: (d) => String(d.getTime()),
  s: (d) => String(Math.floor(d.getTime() / 1e3)),
  S: (d, p) => pad$1(d.getSeconds(), 2, p != null ? p : "0"),
  u: (d) => {
    let day2 = d.getDay();
    if (day2 < 1)
      day2 += 7;
    return String(day2 % 7);
  },
  U: (d, p) => pad$1(weekOfYear$1(d, SUNDAY$1), 2, p != null ? p : "0"),
  V: (d, p) => pad$1(isoWeekOfYear$1(d), 2, p != null ? p : "0"),
  w: (d, p) => pad$1(d.getDay(), 2, p != null ? p : "0"),
  W: (d, p) => pad$1(weekOfYear$1(d, MONDAY$1), 2, p != null ? p : "0"),
  x: "%-m/%-d/%Y",
  X: "%-I:%M:%S %p",
  y: (d, p) => pad$1(d.getFullYear() % 100, 2, p != null ? p : "0"),
  Y: (d, p) => pad$1(d.getFullYear(), 4, p != null ? p : "0"),
  Z: (d) => timezone$1(d),
  "%": () => "%"
};
var PADS$1 = {
  _: " ",
  "0": "0",
  "-": ""
};
function pad$1(value, size, padChar) {
  const output = String(Math.floor(value));
  if (output.length >= size) {
    return output;
  }
  return `${padChar.repeat(size - output.length)}${output}`;
}
function buildFormatter$1(formatString) {
  const formatParts = [];
  while (formatString.length > 0) {
    let nextEscapeIdx = formatString.indexOf("%");
    if (nextEscapeIdx !== 0) {
      const literalPart = nextEscapeIdx > 0 ? formatString.substring(0, nextEscapeIdx) : formatString;
      formatParts.push(literalPart);
    }
    if (nextEscapeIdx < 0)
      break;
    const maybePadSpecifier = formatString[nextEscapeIdx + 1];
    const maybePad = PADS$1[maybePadSpecifier];
    if (maybePad != null) {
      nextEscapeIdx++;
    }
    const maybeFormatterSpecifier = formatString[nextEscapeIdx + 1];
    const maybeFormatter = FORMATTERS$1[maybeFormatterSpecifier];
    if (typeof maybeFormatter === "function") {
      formatParts.push([maybeFormatter, maybePad]);
    } else if (typeof maybeFormatter === "string") {
      const formatter = buildFormatter$1(maybeFormatter);
      formatParts.push([formatter, maybePad]);
    } else {
      formatParts.push(`${maybePad != null ? maybePad : ""}${maybeFormatterSpecifier}`);
    }
    formatString = formatString.substring(nextEscapeIdx + 2);
  }
  return (dateTime) => {
    const dateTimeAsDate = typeof dateTime === "number" ? new Date(dateTime) : dateTime;
    return formatParts.map((c) => typeof c === "string" ? c : c[0](dateTimeAsDate, c[1])).join("");
  };
}

// packages/ag-charts-community/src/scale/timeScale.ts
var formatStrings$1 = {
  [0 /* MILLISECOND */]: ".%L",
  [1 /* SECOND */]: ":%S",
  [2 /* MINUTE */]: "%I:%M",
  [3 /* HOUR */]: "%I %p",
  [4 /* WEEK_DAY */]: "%a",
  [5 /* SHORT_MONTH */]: "%b %d",
  [6 /* MONTH */]: "%B",
  [7 /* SHORT_YEAR */]: "%y",
  [8 /* YEAR */]: "%Y"
};
function toNumber$1(x) {
  return x instanceof Date ? x.getTime() : x;
}
var TimeScale$2 = class extends ContinuousScale$1 {
  constructor() {
    super([new Date(2022, 11, 7), new Date(2022, 11, 8)], [0, 1]);
    this.type = "time";
    this.year = year_default$1;
    this.month = month_default$1;
    this.week = week_default$1;
    this.day = day_default$1;
    this.hour = hour_default$1;
    this.minute = minute_default$1;
    this.second = second_default$1;
    this.millisecond = millisecond_default$1;
    /**
     * Array of default tick intervals in the following format:
     *
     *     [
     *         interval (unit of time),
     *         number of units (step),
     *         the length of that number of units in milliseconds
     *     ]
     */
    this.tickIntervals = [
      [this.second, 1, durationSecond$1],
      [this.second, 5, 5 * durationSecond$1],
      [this.second, 15, 15 * durationSecond$1],
      [this.second, 30, 30 * durationSecond$1],
      [this.minute, 1, durationMinute$1],
      [this.minute, 5, 5 * durationMinute$1],
      [this.minute, 15, 15 * durationMinute$1],
      [this.minute, 30, 30 * durationMinute$1],
      [this.hour, 1, durationHour$1],
      [this.hour, 3, 3 * durationHour$1],
      [this.hour, 6, 6 * durationHour$1],
      [this.hour, 12, 12 * durationHour$1],
      [this.day, 1, durationDay$1],
      [this.day, 2, 2 * durationDay$1],
      [this.week, 1, durationWeek$1],
      [this.week, 2, 2 * durationWeek$1],
      [this.week, 3, 3 * durationWeek$1],
      [this.month, 1, durationMonth$1],
      [this.month, 2, 2 * durationMonth$1],
      [this.month, 3, 3 * durationMonth$1],
      [this.month, 4, 4 * durationMonth$1],
      [this.month, 6, 6 * durationMonth$1],
      [this.year, 1, durationYear$1]
    ];
  }
  toDomain(d) {
    return new Date(d);
  }
  calculateDefaultTickFormat(ticks = []) {
    let defaultTimeFormat = 8 /* YEAR */;
    const updateFormat = (format2) => {
      if (format2 < defaultTimeFormat) {
        defaultTimeFormat = format2;
      }
    };
    for (const value of ticks) {
      const format2 = this.getLowestGranularityFormat(value);
      updateFormat(format2);
    }
    const firstTick = toNumber$1(ticks[0]);
    const lastTick = toNumber$1(ticks[ticks.length - 1]);
    const startYear = new Date(firstTick).getFullYear();
    const stopYear = new Date(lastTick).getFullYear();
    const yearChange = stopYear - startYear > 0;
    return this.buildFormatString(defaultTimeFormat, yearChange);
  }
  buildFormatString(defaultTimeFormat, yearChange) {
    let formatStringArray = [formatStrings$1[defaultTimeFormat]];
    let timeEndIndex = 0;
    const domain = this.getDomain();
    const start = Math.min(...domain.map(toNumber$1));
    const stop = Math.max(...domain.map(toNumber$1));
    const extent2 = stop - start;
    switch (defaultTimeFormat) {
      case 1 /* SECOND */:
        if (extent2 / durationMinute$1 > 1) {
          formatStringArray.push(formatStrings$1[2 /* MINUTE */]);
        }
      case 2 /* MINUTE */:
        if (extent2 / durationHour$1 > 1) {
          formatStringArray.push(formatStrings$1[3 /* HOUR */]);
        }
      case 3 /* HOUR */:
        timeEndIndex = formatStringArray.length;
        if (extent2 / durationDay$1 > 1) {
          formatStringArray.push(formatStrings$1[4 /* WEEK_DAY */]);
        }
      case 4 /* WEEK_DAY */:
        if (extent2 / durationWeek$1 > 1 || yearChange) {
          const weekDayIndex = formatStringArray.indexOf(formatStrings$1[4 /* WEEK_DAY */]);
          if (weekDayIndex > -1) {
            formatStringArray.splice(weekDayIndex, 1, formatStrings$1[5 /* SHORT_MONTH */]);
          }
        }
      case 5 /* SHORT_MONTH */:
      case 6 /* MONTH */:
        if (extent2 / durationYear$1 > 1 || yearChange) {
          formatStringArray.push(formatStrings$1[8 /* YEAR */]);
        }
    }
    if (timeEndIndex < formatStringArray.length) {
      formatStringArray = [
        ...formatStringArray.slice(0, timeEndIndex),
        formatStringArray.slice(timeEndIndex).join(" ")
      ];
    }
    if (timeEndIndex > 0) {
      formatStringArray = [
        ...formatStringArray.slice(0, timeEndIndex).reverse(),
        ...formatStringArray.slice(timeEndIndex)
      ];
      if (timeEndIndex < formatStringArray.length) {
        formatStringArray.splice(timeEndIndex, 0, " ");
      }
    }
    return formatStringArray.join("");
  }
  getLowestGranularityFormat(value) {
    if (this.second.floor(value) < value) {
      return 0 /* MILLISECOND */;
    } else if (this.minute.floor(value) < value) {
      return 1 /* SECOND */;
    } else if (this.hour.floor(value) < value) {
      return 2 /* MINUTE */;
    } else if (this.day.floor(value) < value) {
      return 3 /* HOUR */;
    } else if (this.month.floor(value) < value) {
      if (this.week.floor(value) < value) {
        return 4 /* WEEK_DAY */;
      }
      return 5 /* SHORT_MONTH */;
    } else if (this.year.floor(value) < value) {
      return 6 /* MONTH */;
    }
    return 8 /* YEAR */;
  }
  defaultTickFormat(ticks) {
    const formatString = this.calculateDefaultTickFormat(ticks);
    return (date) => buildFormatter$1(formatString)(date);
  }
  /**
   * @param options Tick interval options.
   * @param options.start The start time (timestamp).
   * @param options.stop The end time (timestamp).
   * @param options.count Number of intervals between ticks.
   */
  getTickInterval({
    start,
    stop,
    count: count2,
    minCount,
    maxCount
  }) {
    const { tickIntervals } = this;
    let countableTimeInterval;
    let step;
    const tickCount = count2 != null ? count2 : ContinuousScale$1.defaultTickCount;
    const target = Math.abs(stop - start) / Math.max(tickCount, 1);
    let i = 0;
    while (i < tickIntervals.length && target > tickIntervals[i][2]) {
      i++;
    }
    if (i === 0) {
      step = Math.max(tickStep$1(start, stop, tickCount, minCount, maxCount), 1);
      countableTimeInterval = this.millisecond;
    } else if (i === tickIntervals.length) {
      const y0 = start / durationYear$1;
      const y1 = stop / durationYear$1;
      step = tickStep$1(y0, y1, tickCount, minCount, maxCount);
      countableTimeInterval = this.year;
    } else {
      const diff0 = target - tickIntervals[i - 1][2];
      const diff1 = tickIntervals[i][2] - target;
      const index = diff0 < diff1 ? i - 1 : i;
      [countableTimeInterval, step] = tickIntervals[index];
    }
    return countableTimeInterval.every(step);
  }
  invert(y) {
    return new Date(super.invert(y));
  }
  /**
   * Returns uniformly-spaced dates that represent the scale's domain.
   */
  ticks() {
    if (!this.domain || this.domain.length < 2) {
      return [];
    }
    this.refresh();
    const [t0, t1] = this.getDomain().map(toNumber$1);
    const start = Math.min(t0, t1);
    const stop = Math.max(t0, t1);
    if (this.interval !== void 0) {
      return this.getTicksForInterval({ start, stop });
    }
    if (this.nice) {
      const { tickCount } = this;
      if (tickCount === 2) {
        return this.niceDomain;
      }
      if (tickCount === 1) {
        return this.niceDomain.slice(0, 1);
      }
    }
    return this.getDefaultTicks({ start, stop });
  }
  getDefaultTicks({ start, stop }) {
    const t = this.getTickInterval({
      start,
      stop,
      count: this.tickCount,
      minCount: this.minTickCount,
      maxCount: this.maxTickCount
    });
    return t ? t.range(new Date(start), new Date(stop)) : [];
  }
  getTicksForInterval({ start, stop }) {
    const { interval, tickIntervals } = this;
    if (!interval) {
      return [];
    }
    if (interval instanceof TimeInterval$1) {
      const ticks2 = interval.range(new Date(start), new Date(stop));
      if (this.isDenseInterval({ start, stop, interval, count: ticks2.length })) {
        return this.getDefaultTicks({ start, stop });
      }
      return ticks2;
    }
    const absInterval = Math.abs(interval);
    if (this.isDenseInterval({ start, stop, interval: absInterval })) {
      return this.getDefaultTicks({ start, stop });
    }
    const reversedInterval = [...tickIntervals];
    reversedInterval.reverse();
    const timeInterval = reversedInterval.find((tickInterval) => absInterval % tickInterval[2] === 0);
    if (timeInterval) {
      const i = timeInterval[0].every(absInterval / (timeInterval[2] / timeInterval[1]));
      return i.range(new Date(start), new Date(stop));
    }
    let date = new Date(start);
    const stopDate = new Date(stop);
    const ticks = [];
    while (date <= stopDate) {
      ticks.push(date);
      date = new Date(date);
      date.setMilliseconds(date.getMilliseconds() + absInterval);
    }
    return ticks;
  }
  /**
   * Returns a time format function suitable for displaying tick values.
   * @param specifier If the specifier string is provided, this method is equivalent to
   * the {@link TimeLocaleObject.format} method.
   * If no specifier is provided, this method returns the default time format function.
   */
  tickFormat({ ticks, specifier }) {
    return specifier == void 0 ? this.defaultTickFormat(ticks) : buildFormatter$1(specifier);
  }
  update() {
    if (!this.domain || this.domain.length < 2) {
      return;
    }
    if (this.nice) {
      this.updateNiceDomain();
    }
  }
  /**
   * Extends the domain so that it starts and ends on nice round values.
   * This method typically modifies the scale’s domain, and may only extend the bounds to the nearest round value.
   */
  updateNiceDomain() {
    const maxAttempts = 4;
    let [d0, d1] = this.domain;
    for (let i = 0; i < maxAttempts; i++) {
      this.updateNiceDomainIteration(d0, d1);
      const [n0, n1] = this.niceDomain;
      if (toNumber$1(d0) === toNumber$1(n0) && toNumber$1(d1) === toNumber$1(n1)) {
        break;
      }
      d0 = n0;
      d1 = n1;
    }
  }
  updateNiceDomainIteration(d0, d1) {
    const start = Math.min(toNumber$1(d0), toNumber$1(d1));
    const stop = Math.max(toNumber$1(d0), toNumber$1(d1));
    const isReversed = d0 > d1;
    const { interval } = this;
    let i;
    if (interval instanceof TimeInterval$1) {
      i = interval;
    } else {
      const tickCount = typeof interval === "number" ? (stop - start) / Math.max(interval, 1) : this.tickCount;
      i = this.getTickInterval({
        start,
        stop,
        count: tickCount,
        minCount: this.minTickCount,
        maxCount: this.maxTickCount
      });
    }
    if (i) {
      const intervalRange = i.range(new Date(start), new Date(stop), true);
      const domain = isReversed ? [...intervalRange].reverse() : intervalRange;
      const n0 = domain[0];
      const n1 = domain[domain.length - 1];
      this.niceDomain = [n0, n1];
    }
  }
};

// packages/ag-charts-community/src/sparklines-util.ts
var sparklines_util_exports$1 = {};
__export$1(sparklines_util_exports$1, {
  Color: () => Color$1,
  DELETE: () => DELETE$1,
  Logger: () => Logger$1,
  Padding: () => Padding$2,
  angleBetween: () => angleBetween$1,
  checkDatum: () => checkDatum$1,
  clamp: () => clamp2$1,
  clampArray: () => clampArray$1,
  countFractionDigits: () => countFractionDigits$1,
  createId: () => createId$2,
  extent: () => extent$5,
  isContinuous: () => isContinuous$1,
  isDate: () => isDate2$1,
  isDiscrete: () => isDiscrete$1,
  isEqual: () => isEqual$1,
  isNegative: () => isNegative$1,
  isNumber: () => isNumber2$1,
  isNumberEqual: () => isEqual$1,
  isReal: () => isReal$1,
  isString: () => isString2$1,
  isStringObject: () => isStringObject$2,
  jsonApply: () => jsonApply$1,
  jsonClone: () => jsonClone$1,
  jsonDiff: () => jsonDiff$1,
  jsonMerge: () => jsonMerge$1,
  jsonWalk: () => jsonWalk$1,
  mod: () => mod$1,
  normalisedExtent: () => normalisedExtent$1,
  normalisedExtentWithMetadata: () => normalisedExtentWithMetadata$1,
  normalizeAngle180: () => normalizeAngle180$1,
  normalizeAngle360: () => normalizeAngle360$1,
  normalizeAngle360Inclusive: () => normalizeAngle360Inclusive$1,
  range: () => range$1,
  resetIds: () => resetIds$1,
  round: () => round$1,
  sanitizeHtml: () => sanitizeHtml$1,
  tickFormat: () => tickFormat$1,
  tickStep: () => tickStep$1,
  ticks: () => ticks_default$1,
  toDegrees: () => toDegrees$1,
  toFixed: () => toFixed$1,
  toRadians: () => toRadians$2,
  toReal: () => toReal$1,
  zipObject: () => zipObject$1
});

// packages/ag-charts-community/src/util/padding.ts
var Padding$2 = class {
  constructor(top = 0, right = top, bottom = top, left = right) {
    this.top = top;
    this.right = right;
    this.bottom = bottom;
    this.left = left;
  }
  clear() {
    this.top = this.right = this.bottom = this.left = 0;
  }
};
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], Padding$2.prototype, "top", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], Padding$2.prototype, "right", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], Padding$2.prototype, "bottom", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], Padding$2.prototype, "left", 2);

// packages/ag-charts-community/src/util/sanitize.ts
var element$1 = null;
function sanitizeHtml$1(text) {
  if (text == null) {
    return void 0;
  } else if (text === "") {
    return "";
  }
  element$1 != null ? element$1 : element$1 = document.createElement("div");
  element$1.textContent = String(text);
  return element$1.innerHTML;
}

// packages/ag-charts-community/src/util/equal.ts
function areArrayNumbersEqual$1(arrA, arrB) {
  return arrA.length === arrB.length && arrA.every((item, i) => Number(item) === Number(arrB[i]));
}

// packages/ag-charts-community/src/util/labelPlacement.ts
function circleRectOverlap$1(c, x, y, w, h) {
  let edgeX = c.x;
  if (c.x < x) {
    edgeX = x;
  } else if (c.x > x + w) {
    edgeX = x + w;
  }
  let edgeY = c.y;
  if (c.y < y) {
    edgeY = y;
  } else if (c.y > y + h) {
    edgeY = y + h;
  }
  const dx = c.x - edgeX;
  const dy = c.y - edgeY;
  const d = Math.sqrt(dx * dx + dy * dy);
  return d <= c.size * 0.5;
}
function rectRectOverlap$1(r1, x2, y2, w2, h2) {
  const xOverlap = r1.x + r1.width > x2 && r1.x < x2 + w2;
  const yOverlap = r1.y + r1.height > y2 && r1.y < y2 + h2;
  return xOverlap && yOverlap;
}
function rectContainsRect$1(r1, r2x, r2y, r2w, r2h) {
  return r2x + r2w < r1.x + r1.width && r2x > r1.x && r2y > r1.y && r2y + r2h < r1.y + r1.height;
}
function isPointLabelDatum$1(x) {
  return x != null && typeof x.point === "object" && typeof x.label === "object";
}
function placeLabels$1(data, bounds, padding = 5) {
  const result = [];
  data = data.map((d) => d.slice().sort((a, b) => b.point.size - a.point.size));
  for (let j = 0; j < data.length; j++) {
    const labels = result[j] = [];
    const datum = data[j];
    if (!((datum == null ? void 0 : datum.length) && datum[0].label)) {
      continue;
    }
    for (let i = 0, ln = datum.length; i < ln; i++) {
      const d = datum[i];
      const l = d.label;
      const r = d.point.size * 0.5;
      const x = d.point.x - l.width * 0.5;
      const y = d.point.y - r - l.height - padding;
      const { width, height } = l;
      const withinBounds = !bounds || rectContainsRect$1(bounds, x, y, width, height);
      if (!withinBounds) {
        continue;
      }
      const overlapPoints = data.some(
        (datum2) => datum2.some((d2) => circleRectOverlap$1(d2.point, x, y, width, height))
      );
      if (overlapPoints) {
        continue;
      }
      const overlapLabels = result.some((labels2) => labels2.some((l2) => rectRectOverlap$1(l2, x, y, width, height)));
      if (overlapLabels) {
        continue;
      }
      labels.push({
        index: i,
        text: l.text,
        x,
        y,
        width,
        height,
        datum: d
      });
    }
  }
  return result;
}
function axisLabelsOverlap$1(data, padding) {
  const result = [];
  for (let i = 0; i < data.length; i++) {
    const datum = data[i];
    const {
      point: { x, y },
      label: { text }
    } = datum;
    let {
      label: { width, height }
    } = datum;
    width += padding != null ? padding : 0;
    height += padding != null ? padding : 0;
    const overlapLabels = result.some((l) => {
      return rectRectOverlap$1(l, x, y, width, height);
    });
    if (overlapLabels) {
      return true;
    }
    result.push({
      index: i,
      text,
      x,
      y,
      width,
      height,
      datum
    });
  }
  return false;
}

// packages/ag-charts-community/src/chart/axis/axisGridLine.ts
var GRID_STYLE_KEYS$1 = ["stroke", "lineDash"];
var GRID_STYLE$1 = ARRAY_OF$1(
  (value) => isObject$1(value) && Object.keys(value).every((key) => GRID_STYLE_KEYS$1.includes(key)),
  "objects with gridline style properties such as 'stroke' or 'lineDash'"
);
var AxisGridLine$1 = class {
  constructor() {
    this.enabled = true;
    this.width = 1;
    this.style = [
      {
        stroke: void 0,
        lineDash: []
      }
    ];
  }
};
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], AxisGridLine$1.prototype, "enabled", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], AxisGridLine$1.prototype, "width", 2);
__decorateClass$1([
  Validate$1(GRID_STYLE$1)
], AxisGridLine$1.prototype, "style", 2);

// packages/ag-charts-community/src/util/default.ts
function Default$1(defaultValue, replaces = [void 0]) {
  return addTransformToInstanceProperty$1((_, __, v) => {
    if (replaces.includes(v)) {
      return defaultValue;
    }
    return v;
  });
}

// packages/ag-charts-community/src/chart/axis/axisLabel.ts
var AxisLabel$1 = class {
  constructor() {
    this.enabled = true;
    this.autoWrap = false;
    this.maxWidth = void 0;
    this.maxHeight = void 0;
    this.fontStyle = void 0;
    this.fontWeight = void 0;
    this.fontSize = 12;
    this.fontFamily = "Verdana, sans-serif";
    this.padding = 5;
    this.minSpacing = NaN;
    this.color = "rgba(87, 87, 87, 1)";
    this.rotation = void 0;
    this.avoidCollisions = true;
    this.mirrored = false;
    this.parallel = false;
    /**
     * In case {@param value} is a number, the {@param fractionDigits} parameter will
     * be provided as well. The `fractionDigits` corresponds to the number of fraction
     * digits used by the tick step. For example, if the tick step is `0.0005`,
     * the `fractionDigits` is 4.
     */
    this.formatter = void 0;
  }
  /**
   * The side of the axis line to position the labels on.
   * -1 = left (default)
   * 1 = right
   */
  getSideFlag() {
    return this.mirrored ? 1 : -1;
  }
  getFont() {
    return getFont$1(this);
  }
};
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], AxisLabel$1.prototype, "enabled", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1, { optional: true })
], AxisLabel$1.prototype, "autoWrap", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], AxisLabel$1.prototype, "maxWidth", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], AxisLabel$1.prototype, "maxHeight", 2);
__decorateClass$1([
  Validate$1(FONT_STYLE$1, { optional: true })
], AxisLabel$1.prototype, "fontStyle", 2);
__decorateClass$1([
  Validate$1(FONT_WEIGHT$1, { optional: true })
], AxisLabel$1.prototype, "fontWeight", 2);
__decorateClass$1([
  Validate$1(NUMBER$1.restrict({ min: 1 }))
], AxisLabel$1.prototype, "fontSize", 2);
__decorateClass$1([
  Validate$1(STRING$1)
], AxisLabel$1.prototype, "fontFamily", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], AxisLabel$1.prototype, "padding", 2);
__decorateClass$1([
  Validate$1(NUMBER_OR_NAN$1),
  Default$1(NaN)
], AxisLabel$1.prototype, "minSpacing", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true })
], AxisLabel$1.prototype, "color", 2);
__decorateClass$1([
  Validate$1(DEGREE$1, { optional: true })
], AxisLabel$1.prototype, "rotation", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], AxisLabel$1.prototype, "avoidCollisions", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], AxisLabel$1.prototype, "mirrored", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], AxisLabel$1.prototype, "parallel", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], AxisLabel$1.prototype, "format", 2);

// packages/ag-charts-community/src/chart/axis/axisLine.ts
var AxisLine$1 = class {
  constructor() {
    this.enabled = true;
    this.width = 1;
    this.color = void 0;
  }
};
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], AxisLine$1.prototype, "enabled", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], AxisLine$1.prototype, "width", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true })
], AxisLine$1.prototype, "color", 2);

// packages/ag-charts-community/src/chart/axis/axisTick.ts
var TICK_INTERVAL$1 = predicateWithMessage$1(
  (value) => isFiniteNumber$1(value) && value > 0 || value instanceof TimeInterval$1,
  `a non-zero positive Number value or, for a time axis, a Time Interval such as 'agCharts.time.month'`
);
var AxisTick$1 = class {
  constructor() {
    this.enabled = true;
    this.width = 1;
    this.size = 6;
    this.color = void 0;
    this.interval = void 0;
    this.values = void 0;
    this.minSpacing = NaN;
  }
};
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], AxisTick$1.prototype, "enabled", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], AxisTick$1.prototype, "width", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], AxisTick$1.prototype, "size", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true })
], AxisTick$1.prototype, "color", 2);
__decorateClass$1([
  Validate$1(TICK_INTERVAL$1, { optional: true })
], AxisTick$1.prototype, "interval", 2);
__decorateClass$1([
  Validate$1(ARRAY$1, { optional: true })
], AxisTick$1.prototype, "values", 2);
__decorateClass$1([
  Validate$1(MIN_SPACING$1),
  Default$1(NaN)
], AxisTick$1.prototype, "minSpacing", 2);

// packages/ag-charts-community/src/chart/axis/axisUtil.ts
function prepareAxisAnimationContext$1(axis) {
  const requestedRangeMin = Math.min(...axis.range);
  const requestedRangeMax = Math.max(...axis.range);
  const min = Math.floor(requestedRangeMin);
  const max = Math.ceil(requestedRangeMax);
  const visible = min !== max;
  return { min, max, visible };
}
var fullCircle$1 = Math.PI * 2;
var halfCircle$1 = fullCircle$1 / 2;
function normaliseEndRotation$1(start, end) {
  const directDistance = Math.abs(end - start);
  if (directDistance < halfCircle$1)
    return end;
  if (start > end)
    return end + fullCircle$1;
  return end - fullCircle$1;
}
function prepareAxisAnimationFunctions$1(ctx) {
  const outOfBounds = (y, range3) => {
    const min = range3 != null ? Math.min(...range3) : ctx.min;
    const max = range3 != null ? Math.max(...range3) : ctx.max;
    return y < min || y > max;
  };
  const calculateStatus2 = (node, datum, status) => {
    if (status !== "removed" && outOfBounds(node.translationY, node.datum.range)) {
      return "removed";
    } else if (status !== "added" && outOfBounds(datum.translationY, datum.range)) {
      return "added";
    }
    return status;
  };
  const tick = {
    fromFn(node, datum, status) {
      let y = node.y1 + node.translationY;
      let opacity = node.opacity;
      if (status === "added" || outOfBounds(node.datum.translationY, node.datum.range)) {
        y = datum.translationY;
        opacity = 0;
      }
      return __spreadValues$1({ y: 0, translationY: y, opacity }, FROM_TO_MIXINS$1[status]);
    },
    toFn(_node, datum, status) {
      const y = datum.translationY;
      let opacity = 1;
      if (status === "removed") {
        opacity = 0;
      }
      return {
        y: 0,
        translationY: y,
        opacity,
        finish: {
          // Set explicit y after animation so it's pixel aligned
          y,
          translationY: 0
        }
      };
    },
    intermediateFn(node, _datum, _status) {
      return { visible: !outOfBounds(node.y) };
    }
  };
  const label = {
    fromFn(node, newDatum, status) {
      var _a;
      const datum = (_a = node.previousDatum) != null ? _a : newDatum;
      status = calculateStatus2(node, newDatum, status);
      const x = datum.x;
      const y = datum.y;
      const rotationCenterX = datum.rotationCenterX;
      let translationY = Math.round(node.translationY);
      let rotation = datum.rotation;
      let opacity = node.opacity;
      if (status === "removed" || outOfBounds(datum.y, datum.range)) ; else if (status === "added" || outOfBounds(node.datum.y, node.datum.range)) {
        translationY = Math.round(datum.translationY);
        opacity = 0;
        rotation = newDatum.rotation;
      }
      return __spreadValues$1({ x, y, rotationCenterX, translationY, rotation, opacity }, FROM_TO_MIXINS$1[status]);
    },
    toFn(node, datum, status) {
      var _a, _b;
      const x = datum.x;
      const y = datum.y;
      const rotationCenterX = datum.rotationCenterX;
      const translationY = Math.round(datum.translationY);
      let rotation = 0;
      let opacity = 1;
      status = calculateStatus2(node, datum, status);
      if (status === "added") {
        opacity = 1;
        rotation = datum.rotation;
      } else if (status === "removed") {
        opacity = 0;
        rotation = datum.rotation;
      } else {
        rotation = normaliseEndRotation$1((_b = (_a = node.previousDatum) == null ? void 0 : _a.rotation) != null ? _b : datum.rotation, datum.rotation);
      }
      return { x, y, rotationCenterX, translationY, rotation, opacity, finish: { rotation: datum.rotation } };
    }
  };
  const line = {
    fromFn(node, datum) {
      var _a;
      return __spreadValues$1(__spreadValues$1({}, (_a = node.previousDatum) != null ? _a : datum), FROM_TO_MIXINS$1["updated"]);
    },
    toFn(_node, datum) {
      return __spreadValues$1({}, datum);
    }
  };
  const group2 = {
    fromFn(group3, _datum) {
      const { rotation, translationX, translationY } = group3;
      return __spreadValues$1({
        rotation,
        translationX,
        translationY
      }, FROM_TO_MIXINS$1["updated"]);
    },
    toFn(_group, datum) {
      const { rotation, translationX, translationY } = datum;
      return {
        rotation,
        translationX,
        translationY
      };
    }
  };
  return { tick, line, label, group: group2 };
}
function resetAxisGroupFn$1() {
  return (_node, datum) => {
    return {
      rotation: datum.rotation,
      rotationCenterX: datum.rotationCenterX,
      rotationCenterY: datum.rotationCenterY,
      translationX: datum.translationX,
      translationY: datum.translationY
    };
  };
}
function resetAxisSelectionFn$1(ctx) {
  const { visible: rangeVisible, min, max } = ctx;
  return (_node, datum) => {
    const y = datum.translationY;
    const visible = rangeVisible && y >= min && y <= max;
    return {
      y,
      translationY: 0,
      opacity: 1,
      visible
    };
  };
}
function resetAxisLabelSelectionFn$1() {
  return (_node, datum) => {
    return {
      x: datum.x,
      y: datum.y,
      translationY: datum.translationY,
      rotation: datum.rotation,
      rotationCenterX: datum.rotationCenterX
    };
  };
}
function resetAxisLineSelectionFn$1() {
  return (_node, datum) => {
    return __spreadValues$1({}, datum);
  };
}

// packages/ag-charts-community/src/chart/axis/axis.ts
var Tags$1 = /* @__PURE__ */ ((Tags2) => {
  Tags2[Tags2["TickLine"] = 0] = "TickLine";
  Tags2[Tags2["TickLabel"] = 1] = "TickLabel";
  Tags2[Tags2["GridLine"] = 2] = "GridLine";
  Tags2[Tags2["GridArc"] = 3] = "GridArc";
  Tags2[Tags2["AxisLine"] = 4] = "AxisLine";
  return Tags2;
})(Tags$1 || {});
var _Axis$1 = class _Axis {
  constructor(moduleCtx, scale2) {
    this.moduleCtx = moduleCtx;
    this.scale = scale2;
    this.id = createId$2(this);
    this.nice = true;
    this.reverse = void 0;
    this.dataDomain = { domain: [], clipped: false };
    this.keys = [];
    this.boundSeries = [];
    this.includeInvisibleDomains = false;
    this.axisGroup = new Group$1({ name: `${this.id}-axis`, zIndex: 2 /* AXIS_ZINDEX */ });
    this.lineNode = this.axisGroup.appendChild(new Line$1());
    this.tickLineGroup = this.axisGroup.appendChild(
      new Group$1({ name: `${this.id}-Axis-tick-lines`, zIndex: 2 /* AXIS_ZINDEX */ })
    );
    this.tickLabelGroup = this.axisGroup.appendChild(
      new Group$1({ name: `${this.id}-Axis-tick-labels`, zIndex: 2 /* AXIS_ZINDEX */ })
    );
    this.crossLineGroup = new Group$1({ name: `${this.id}-CrossLines` });
    this.gridGroup = new Group$1({ name: `${this.id}-Axis-grid` });
    this.gridLineGroup = this.gridGroup.appendChild(
      new Group$1({
        name: `${this.id}-gridLines`,
        zIndex: 1 /* AXIS_GRID_ZINDEX */
      })
    );
    this.tickLineGroupSelection = Selection$1.select(this.tickLineGroup, Line$1, false);
    this.tickLabelGroupSelection = Selection$1.select(this.tickLabelGroup, Text$1, false);
    this.gridLineGroupSelection = Selection$1.select(this.gridLineGroup, Line$1, false);
    this.line = new AxisLine$1();
    this.tick = this.createTick();
    this.gridLine = new AxisGridLine$1();
    this.label = this.createLabel();
    this.defaultTickMinSpacing = _Axis.defaultTickMinSpacing;
    this.translation = { x: 0, y: 0 };
    this.rotation = 0;
    // axis rotation angle in degrees
    this.layout = {
      label: {
        fractionDigits: 0,
        padding: this.label.padding,
        format: this.label.format
      }
    };
    this.destroyFns = [];
    this.range = [0, 1];
    this.visibleRange = [0, 1];
    this.title = void 0;
    this._titleCaption = new Caption$1();
    /**
     * The length of the grid. The grid is only visible in case of a non-zero value.
     * In case {@link radialGrid} is `true`, the value is interpreted as an angle
     * (in degrees).
     */
    this._gridLength = 0;
    this.fractionDigits = 0;
    /**
     * The distance between the grid ticks and the axis ticks.
     */
    this.gridPadding = 0;
    /**
     * Is used to avoid collisions between axis labels and series.
     */
    this.seriesAreaPadding = 0;
    this.tickGenerationResult = void 0;
    this.maxThickness = Infinity;
    this.moduleMap = new ModuleMap$1();
    this.refreshScale();
    this._titleCaption.registerInteraction(this.moduleCtx);
    this._titleCaption.node.rotation = -Math.PI / 2;
    this.axisGroup.appendChild(this._titleCaption.node);
    this.destroyFns.push(moduleCtx.interactionManager.addListener("hover", (e) => this.checkAxisHover(e)));
    this.animationManager = moduleCtx.animationManager;
    this.animationState = new StateMachine$1("empty", {
      empty: {
        update: {
          target: "ready",
          action: () => this.resetSelectionNodes()
        }
      },
      ready: {
        update: (data) => this.animateReadyUpdate(data),
        resize: () => this.resetSelectionNodes()
      }
    });
    this._crossLines = [];
    this.assignCrossLineArrayConstructor(this._crossLines);
    let previousSize = void 0;
    this.destroyFns.push(
      moduleCtx.layoutService.addListener("layout-complete", (e) => {
        if (previousSize != null && jsonDiff$1(e.chart, previousSize) != null) {
          this.animationState.transition("resize");
        }
        previousSize = __spreadValues$1({}, e.chart);
      })
    );
    this.destroyFns.push(
      moduleCtx.updateService.addListener("update-complete", (e) => {
        this.minRect = e.minRect;
      })
    );
  }
  get type() {
    var _a;
    return (_a = this.constructor.type) != null ? _a : "";
  }
  set crossLines(value) {
    var _a, _b;
    (_a = this._crossLines) == null ? void 0 : _a.forEach((crossLine) => this.detachCrossLine(crossLine));
    if (value) {
      this.assignCrossLineArrayConstructor(value);
    }
    this._crossLines = value;
    (_b = this._crossLines) == null ? void 0 : _b.forEach((crossLine) => {
      this.attachCrossLine(crossLine);
      this.initCrossLine(crossLine);
    });
  }
  get crossLines() {
    return this._crossLines;
  }
  attachCrossLine(crossLine) {
    this.crossLineGroup.appendChild(crossLine.group);
    this.crossLineGroup.appendChild(crossLine.labelGroup);
  }
  detachCrossLine(crossLine) {
    this.crossLineGroup.removeChild(crossLine.group);
    this.crossLineGroup.removeChild(crossLine.labelGroup);
  }
  destroy() {
    this.moduleMap.destroy();
    this.destroyFns.forEach((f) => f());
  }
  refreshScale() {
    var _a;
    this.range = this.scale.range.slice();
    (_a = this.crossLines) == null ? void 0 : _a.forEach((crossLine) => {
      this.initCrossLine(crossLine);
    });
  }
  updateRange() {
    var _a;
    const { range: rr, visibleRange: vr, scale: scale2 } = this;
    const span = (rr[1] - rr[0]) / (vr[1] - vr[0]);
    const shift = span * vr[0];
    const start = rr[0] - shift;
    scale2.range = [start, start + span];
    (_a = this.crossLines) == null ? void 0 : _a.forEach((crossLine) => {
      crossLine.clippedRange = [rr[0], rr[1]];
    });
  }
  setCrossLinesVisible(visible) {
    this.crossLineGroup.visible = visible;
  }
  attachAxis(axisNode, gridNode) {
    gridNode.appendChild(this.gridGroup);
    axisNode.appendChild(this.axisGroup);
    axisNode.appendChild(this.crossLineGroup);
  }
  detachAxis(axisNode, gridNode) {
    gridNode.removeChild(this.gridGroup);
    axisNode.removeChild(this.axisGroup);
    axisNode.removeChild(this.crossLineGroup);
  }
  /**
   * Checks if a point or an object is in range.
   * @param x A point (or object's starting point).
   * @param width Object's width.
   * @param tolerance Expands the range on both ends by this amount.
   */
  inRange(x, width = 0, tolerance = 0) {
    const min = Math.min(...this.range);
    const max = Math.max(...this.range);
    return x + width >= min - tolerance && x <= max + tolerance;
  }
  onLabelFormatChange(ticks, format2) {
    const { scale: scale2, fractionDigits } = this;
    const logScale = scale2 instanceof LogScale$1;
    const defaultLabelFormatter = !logScale && fractionDigits > 0 ? (x) => typeof x === "number" ? x.toFixed(fractionDigits) : String(x) : (x) => String(x);
    if (format2 && scale2 && scale2.tickFormat) {
      try {
        this.labelFormatter = scale2.tickFormat({ ticks, specifier: format2 });
      } catch (e) {
        this.labelFormatter = defaultLabelFormatter;
        Logger$1.warnOnce(`the axis label format string ${format2} is invalid. No formatting will be applied`);
      }
    } else {
      this.labelFormatter = defaultLabelFormatter;
    }
  }
  setDomain() {
    const {
      scale: scale2,
      dataDomain: { domain }
    } = this;
    scale2.domain = domain;
  }
  setTickInterval(interval) {
    var _a;
    this.scale.interval = (_a = this.tick.interval) != null ? _a : interval;
  }
  setTickCount(count2, minTickCount, maxTickCount) {
    const { scale: scale2 } = this;
    if (!(count2 && ContinuousScale$1.is(scale2))) {
      return;
    }
    if (typeof count2 === "number") {
      scale2.tickCount = count2;
      scale2.minTickCount = minTickCount != null ? minTickCount : 0;
      scale2.maxTickCount = maxTickCount != null ? maxTickCount : Infinity;
      return;
    }
    if (scale2 instanceof TimeScale$2) {
      this.setTickInterval(count2);
    }
  }
  set gridLength(value) {
    var _a;
    if (this._gridLength && !value || !this._gridLength && value) {
      this.gridLineGroupSelection.clear();
    }
    this._gridLength = value;
    (_a = this.crossLines) == null ? void 0 : _a.forEach((crossLine) => {
      this.initCrossLine(crossLine);
    });
  }
  get gridLength() {
    return this._gridLength;
  }
  createTick() {
    return new AxisTick$1();
  }
  createLabel() {
    return new AxisLabel$1();
  }
  checkAxisHover(event) {
    const bbox = this.computeBBox();
    const isInAxis = bbox.containsPoint(event.offsetX, event.offsetY);
    if (!isInAxis)
      return;
    this.moduleCtx.chartEventManager.axisHover(this.id, this.direction);
  }
  /**
   * Creates/removes/updates the scene graph nodes that constitute the axis.
   */
  update(primaryTickCount) {
    if (!this.tickGenerationResult) {
      return;
    }
    const { rotation, parallelFlipRotation, regularFlipRotation } = this.calculateRotations();
    const sideFlag = this.label.getSideFlag();
    this.updatePosition();
    const lineData = this.getAxisLineCoordinates();
    const _a = this.tickGenerationResult, { tickData, combinedRotation, textBaseline, textAlign } = _a, ticksResult = __objRest$1(_a, ["tickData", "combinedRotation", "textBaseline", "textAlign"]);
    const previousTicks = this.tickLabelGroupSelection.nodes().map((node) => node.datum.tickId);
    this.updateSelections(lineData, tickData.ticks, {
      combinedRotation,
      textAlign,
      textBaseline,
      range: this.scale.range
    });
    if (this.animationManager.isSkipped()) {
      this.resetSelectionNodes();
    } else {
      const diff2 = this.calculateUpdateDiff(previousTicks, tickData);
      this.animationState.transition("update", diff2);
    }
    this.updateAxisLine();
    this.updateLabels();
    this.updateVisibility();
    this.updateGridLines(sideFlag);
    this.updateTickLines();
    this.updateTitle({ anyTickVisible: tickData.ticks.length > 0 });
    this.updateCrossLines({ rotation, parallelFlipRotation, regularFlipRotation });
    this.updateLayoutState();
    primaryTickCount = ticksResult.primaryTickCount;
    return primaryTickCount;
  }
  getAxisLineCoordinates() {
    const {
      range: [start, end]
    } = this;
    const x = 0;
    const y1 = Math.min(start, end);
    const y2 = Math.max(start, end);
    return { x, y1, y2 };
  }
  getTickLineCoordinates(datum) {
    const { label } = this;
    const sideFlag = label.getSideFlag();
    const x = sideFlag * this.getTickSize();
    const x1 = Math.min(0, x);
    const x2 = x1 + Math.abs(x);
    const y = datum.translationY;
    return { x1, x2, y };
  }
  getTickLabelProps(datum, params) {
    const { label } = this;
    const { combinedRotation, textBaseline, textAlign, range: range3 } = params;
    const text = datum.tickLabel;
    const sideFlag = label.getSideFlag();
    const labelX = sideFlag * (this.getTickSize() + label.padding + this.seriesAreaPadding);
    const visible = text !== "" && text != void 0;
    return {
      tickId: datum.tickId,
      translationY: datum.translationY,
      fill: label.color,
      fontFamily: label.fontFamily,
      fontSize: label.fontSize,
      fontStyle: label.fontStyle,
      fontWeight: label.fontWeight,
      rotation: combinedRotation,
      rotationCenterX: labelX,
      text,
      textAlign,
      textBaseline,
      visible,
      x: labelX,
      y: 0,
      range: range3
    };
  }
  getTickSize() {
    return this.tick.enabled ? this.tick.size : this.createTick().size;
  }
  setTitleProps(caption, params) {
    var _a;
    const { title } = this;
    if (!title) {
      caption.enabled = false;
      return;
    }
    caption.color = title.color;
    caption.fontFamily = title.fontFamily;
    caption.fontSize = title.fontSize;
    caption.fontStyle = title.fontStyle;
    caption.fontWeight = title.fontWeight;
    caption.enabled = title.enabled;
    caption.wrapping = title.wrapping;
    if (title.enabled) {
      const titleNode = caption.node;
      const padding = ((_a = title.spacing) != null ? _a : 0) + params.spacing;
      const sideFlag = this.label.getSideFlag();
      const parallelFlipRotation = normalizeAngle360$1(this.rotation);
      const titleRotationFlag = sideFlag === -1 && parallelFlipRotation > Math.PI && parallelFlipRotation < Math.PI * 2 ? -1 : 1;
      const rotation = titleRotationFlag * sideFlag * Math.PI / 2;
      const textBaseline = titleRotationFlag === 1 ? "bottom" : "top";
      const { range: range3 } = this;
      const x = Math.floor(titleRotationFlag * sideFlag * (range3[0] + range3[1]) / 2);
      const y = sideFlag === -1 ? Math.floor(titleRotationFlag * -padding) : Math.floor(-padding);
      const { callbackCache } = this.moduleCtx;
      const { formatter = (params2) => params2.defaultValue } = title;
      const text = callbackCache.call(formatter, this.getTitleFormatterParams());
      titleNode.setProperties({
        rotation,
        text,
        textBaseline,
        visible: true,
        x,
        y
      });
    }
  }
  calculateLayout(primaryTickCount) {
    var _b;
    const { rotation, parallelFlipRotation, regularFlipRotation } = this.calculateRotations();
    const sideFlag = this.label.getSideFlag();
    const labelX = sideFlag * (this.getTickSize() + this.label.padding + this.seriesAreaPadding);
    this.updateScale();
    this.tickGenerationResult = this.generateTicks({
      primaryTickCount,
      parallelFlipRotation,
      regularFlipRotation,
      labelX,
      sideFlag
    });
    this.updateLayoutState();
    const _a = this.tickGenerationResult, { tickData, combinedRotation, textBaseline, textAlign } = _a, ticksResult = __objRest$1(_a, ["tickData", "combinedRotation", "textBaseline", "textAlign"]);
    const boxes = [];
    const { x, y1, y2 } = this.getAxisLineCoordinates();
    const lineBox = new BBox$1(
      x + Math.min(sideFlag * this.seriesAreaPadding, 0),
      y1,
      this.seriesAreaPadding,
      y2 - y1
    );
    boxes.push(lineBox);
    const { tick } = this;
    if (tick.enabled) {
      tickData.ticks.forEach((datum) => {
        const { x1, x2, y } = this.getTickLineCoordinates(datum);
        const tickLineBox = new BBox$1(x1, y, x2 - x1, 0);
        boxes.push(tickLineBox);
      });
    }
    const { label } = this;
    if (label.enabled) {
      const tempText = new Text$1();
      tickData.ticks.forEach((datum) => {
        const labelProps = this.getTickLabelProps(datum, {
          combinedRotation,
          textAlign,
          textBaseline,
          range: this.scale.range
        });
        if (!labelProps.visible) {
          return;
        }
        tempText.setProperties(__spreadProps$1(__spreadValues$1({}, labelProps), {
          translationY: Math.round(datum.translationY)
        }));
        const box = tempText.computeTransformedBBox();
        if (box) {
          boxes.push(box);
        }
      });
    }
    const getTransformBox = (bbox2) => {
      const matrix = new Matrix$1();
      const {
        rotation: axisRotation,
        translationX,
        translationY,
        rotationCenterX,
        rotationCenterY
      } = this.getAxisTransform();
      Matrix$1.updateTransformMatrix(matrix, 1, 1, axisRotation, translationX, translationY, {
        scalingCenterX: 0,
        scalingCenterY: 0,
        rotationCenterX,
        rotationCenterY
      });
      return matrix.transformBBox(bbox2);
    };
    const { title } = this;
    if (title == null ? void 0 : title.enabled) {
      const caption = new Caption$1();
      const spacing = BBox$1.merge(boxes).width;
      this.setTitleProps(caption, { spacing });
      const titleNode = caption.node;
      const titleBox = titleNode.computeTransformedBBox();
      if (titleBox) {
        boxes.push(titleBox);
      }
    }
    const bbox = BBox$1.merge(boxes);
    const transformedBBox = getTransformBox(bbox);
    const anySeriesActive = this.isAnySeriesActive();
    (_b = this.crossLines) == null ? void 0 : _b.forEach((crossLine) => {
      var _a2;
      crossLine.sideFlag = -sideFlag;
      crossLine.direction = rotation === -Math.PI / 2 ? "x" /* X */ : "y" /* Y */;
      if (crossLine instanceof CartesianCrossLine$1) {
        crossLine.label.parallel = (_a2 = crossLine.label.parallel) != null ? _a2 : this.label.parallel;
      }
      crossLine.parallelFlipRotation = parallelFlipRotation;
      crossLine.regularFlipRotation = regularFlipRotation;
      crossLine.calculateLayout(anySeriesActive, this.reverse);
    });
    primaryTickCount = ticksResult.primaryTickCount;
    return { primaryTickCount, bbox: transformedBBox };
  }
  updateLayoutState() {
    this.layout.label = {
      fractionDigits: this.fractionDigits,
      padding: this.label.padding,
      format: this.label.format
    };
  }
  updateScale() {
    this.updateRange();
    this.calculateDomain();
    this.setDomain();
    this.setTickInterval(this.tick.interval);
    const { scale: scale2, nice } = this;
    if (!ContinuousScale$1.is(scale2)) {
      return;
    }
    scale2.nice = nice;
    scale2.update();
  }
  calculateRotations() {
    const rotation = toRadians$2(this.rotation);
    const parallelFlipRotation = normalizeAngle360$1(rotation);
    const regularFlipRotation = normalizeAngle360$1(rotation - Math.PI / 2);
    return { rotation, parallelFlipRotation, regularFlipRotation };
  }
  generateTicks({
    primaryTickCount,
    parallelFlipRotation,
    regularFlipRotation,
    labelX,
    sideFlag
  }) {
    var _a;
    const {
      scale: scale2,
      tick,
      label: { parallel, rotation, fontFamily, fontSize, fontStyle, fontWeight }
    } = this;
    const secondaryAxis = primaryTickCount !== void 0;
    const { defaultRotation, configuredRotation, parallelFlipFlag, regularFlipFlag } = calculateLabelRotation$1({
      rotation,
      parallel,
      regularFlipRotation,
      parallelFlipRotation
    });
    const initialRotation = configuredRotation + defaultRotation;
    const labelMatrix = new Matrix$1();
    const { maxTickCount } = this.estimateTickCount({
      minSpacing: tick.minSpacing,
      maxSpacing: (_a = tick.maxSpacing) != null ? _a : NaN
    });
    const continuous = ContinuousScale$1.is(scale2);
    const maxIterations = !continuous || isNaN(maxTickCount) ? 10 : maxTickCount;
    let textAlign = getTextAlign$1(parallel, configuredRotation, 0, sideFlag, regularFlipFlag);
    const textBaseline = getTextBaseline$1(parallel, configuredRotation, sideFlag, parallelFlipFlag);
    const textProps = {
      fontFamily,
      fontSize,
      fontStyle,
      fontWeight,
      textBaseline,
      textAlign
    };
    let tickData = {
      rawTicks: [],
      ticks: [],
      labelCount: 0
    };
    let index = 0;
    let autoRotation = 0;
    let labelOverlap = true;
    let terminate = false;
    while (labelOverlap && index <= maxIterations) {
      if (terminate) {
        break;
      }
      autoRotation = 0;
      textAlign = getTextAlign$1(parallel, configuredRotation, 0, sideFlag, regularFlipFlag);
      const tickStrategies = this.getTickStrategies({ secondaryAxis, index });
      for (const strategy of tickStrategies) {
        ({ tickData, index, autoRotation, terminate } = strategy({
          index,
          tickData,
          textProps,
          labelOverlap,
          terminate,
          primaryTickCount
        }));
        const rotated = configuredRotation !== 0 || autoRotation !== 0;
        const rotation2 = initialRotation + autoRotation;
        textAlign = getTextAlign$1(parallel, configuredRotation, autoRotation, sideFlag, regularFlipFlag);
        labelOverlap = this.checkLabelOverlap(rotation2, rotated, labelMatrix, tickData.ticks, labelX, __spreadProps$1(__spreadValues$1({}, textProps), {
          textAlign
        }));
      }
    }
    const combinedRotation = defaultRotation + configuredRotation + autoRotation;
    if (!secondaryAxis && tickData.rawTicks.length > 0) {
      primaryTickCount = tickData.rawTicks.length;
    }
    return { tickData, primaryTickCount, combinedRotation, textBaseline, textAlign };
  }
  getTickStrategies({ index, secondaryAxis }) {
    const { scale: scale2, label, tick } = this;
    const continuous = ContinuousScale$1.is(scale2);
    const avoidLabelCollisions = label.enabled && label.avoidCollisions;
    const filterTicks = !continuous && index !== 0 && avoidLabelCollisions;
    const autoRotate = label.autoRotate === true && label.rotation === void 0;
    const strategies = [];
    let tickGenerationType;
    if (this.tick.values) {
      tickGenerationType = 3 /* VALUES */;
    } else if (secondaryAxis) {
      tickGenerationType = 1 /* CREATE_SECONDARY */;
    } else if (filterTicks) {
      tickGenerationType = 2 /* FILTER */;
    } else {
      tickGenerationType = 0 /* CREATE */;
    }
    const tickGenerationStrategy = ({ index: index2, tickData, primaryTickCount, terminate }) => this.createTickData(tickGenerationType, index2, tickData, terminate, primaryTickCount);
    strategies.push(tickGenerationStrategy);
    if (!continuous && !isNaN(tick.minSpacing)) {
      const tickFilterStrategy = ({ index: index2, tickData, primaryTickCount, terminate }) => this.createTickData(2 /* FILTER */, index2, tickData, terminate, primaryTickCount);
      strategies.push(tickFilterStrategy);
    }
    if (!avoidLabelCollisions) {
      return strategies;
    }
    if (label.autoWrap) {
      const autoWrapStrategy = ({ index: index2, tickData, textProps }) => this.wrapLabels(tickData, index2, textProps);
      strategies.push(autoWrapStrategy);
    } else if (autoRotate) {
      const autoRotateStrategy = ({ index: index2, tickData, labelOverlap, terminate }) => ({
        index: index2,
        tickData,
        autoRotation: this.getAutoRotation(labelOverlap),
        terminate
      });
      strategies.push(autoRotateStrategy);
    }
    return strategies;
  }
  createTickData(tickGenerationType, index, tickData, terminate, primaryTickCount) {
    var _a;
    const { scale: scale2, tick } = this;
    const { maxTickCount, minTickCount, defaultTickCount } = this.estimateTickCount({
      minSpacing: tick.minSpacing,
      maxSpacing: (_a = tick.maxSpacing) != null ? _a : NaN
    });
    const continuous = ContinuousScale$1.is(scale2);
    const maxIterations = !continuous || isNaN(maxTickCount) ? 10 : maxTickCount;
    let tickCount = continuous ? Math.max(defaultTickCount - index, minTickCount) : maxTickCount;
    const regenerateTicks = tick.interval === void 0 && tick.values === void 0 && tickCount > minTickCount && (continuous || tickGenerationType === 2 /* FILTER */);
    let unchanged = true;
    while (unchanged && index <= maxIterations) {
      const prevTicks = tickData.rawTicks;
      tickCount = continuous ? Math.max(defaultTickCount - index, minTickCount) : maxTickCount;
      const { rawTicks, ticks, labelCount } = this.getTicks({
        tickGenerationType,
        previousTicks: prevTicks,
        tickCount,
        minTickCount,
        maxTickCount,
        primaryTickCount
      });
      tickData.rawTicks = rawTicks;
      tickData.ticks = ticks;
      tickData.labelCount = labelCount;
      unchanged = regenerateTicks ? areArrayNumbersEqual$1(rawTicks, prevTicks) : false;
      index++;
    }
    const shouldTerminate = tick.interval !== void 0 || tick.values !== void 0;
    terminate || (terminate = shouldTerminate);
    return { tickData, index, autoRotation: 0, terminate };
  }
  checkLabelOverlap(rotation, rotated, labelMatrix, tickData, labelX, textProps) {
    Matrix$1.updateTransformMatrix(labelMatrix, 1, 1, rotation, 0, 0);
    const labelData = this.createLabelData(tickData, labelX, textProps, labelMatrix);
    const labelSpacing = getLabelSpacing$1(this.label.minSpacing, rotated);
    return axisLabelsOverlap$1(labelData, labelSpacing);
  }
  createLabelData(tickData, labelX, textProps, labelMatrix) {
    const labelData = [];
    for (const tickDatum of tickData) {
      const { tickLabel, translationY } = tickDatum;
      if (tickLabel === "" || tickLabel == void 0) {
        continue;
      }
      const lines = splitText$1(tickLabel);
      const { width, height } = measureText$1(lines, labelX, translationY, textProps);
      const bbox = new BBox$1(labelX, translationY, width, height);
      const labelDatum = calculateLabelBBox$1(tickLabel, bbox, labelX, translationY, labelMatrix);
      labelData.push(labelDatum);
    }
    return labelData;
  }
  getAutoRotation(labelOverlap) {
    var _a;
    return labelOverlap ? normalizeAngle360$1(toRadians$2((_a = this.label.autoRotateAngle) != null ? _a : 0)) : 0;
  }
  getTicks({
    tickGenerationType,
    previousTicks,
    tickCount,
    minTickCount,
    maxTickCount,
    primaryTickCount
  }) {
    var _a;
    const { range: range3, scale: scale2, visibleRange } = this;
    let rawTicks = [];
    switch (tickGenerationType) {
      case 3 /* VALUES */:
        if (ContinuousScale$1.is(scale2)) {
          const scaleDomain = scale2.getDomain();
          const start2 = scale2.fromDomain(scaleDomain[0]);
          const stop = scale2.fromDomain(scaleDomain[1]);
          const d0 = Math.min(start2, stop);
          const d1 = Math.max(start2, stop);
          rawTicks = this.tick.values.filter((value) => value >= d0 && value <= d1).sort((a, b) => a - b);
        } else {
          rawTicks = this.tick.values;
        }
        break;
      case 1 /* CREATE_SECONDARY */:
        rawTicks = this.updateSecondaryAxisTicks(primaryTickCount);
        break;
      case 2 /* FILTER */:
        rawTicks = this.filterTicks(previousTicks, tickCount);
        break;
      default:
        rawTicks = this.createTicks(tickCount, minTickCount, maxTickCount);
        break;
    }
    this.fractionDigits = rawTicks.fractionDigits >= 0 ? rawTicks.fractionDigits : 0;
    this.onLabelFormatChange(rawTicks, this.label.format);
    const halfBandwidth = ((_a = scale2.bandwidth) != null ? _a : 0) / 2;
    const ticks = [];
    let labelCount = 0;
    const tickIdCounts = /* @__PURE__ */ new Map();
    const start = Math.max(0, Math.floor(visibleRange[0] * rawTicks.length));
    const end = Math.min(rawTicks.length, Math.ceil(visibleRange[1] * rawTicks.length));
    for (let i = start; i < end; i++) {
      const rawTick = rawTicks[i];
      const translationY = scale2.convert(rawTick) + halfBandwidth;
      if (range3.length > 0 && !this.inRange(translationY, 0, 1e-3))
        continue;
      const tickLabel = this.formatTick(rawTick, i);
      let tickId = tickLabel;
      if (tickIdCounts.has(tickId)) {
        const count2 = tickIdCounts.get(tickId);
        tickIdCounts.set(tickId, count2 + 1);
        tickId = `${tickId}_${count2}`;
      } else {
        tickIdCounts.set(tickId, 1);
      }
      ticks.push({ tick: rawTick, tickId, tickLabel, translationY });
      if (tickLabel === "" || tickLabel == void 0) {
        continue;
      }
      labelCount++;
    }
    return { rawTicks, ticks, labelCount };
  }
  filterTicks(ticks, tickCount) {
    var _a;
    const tickSpacing = !isNaN(this.tick.minSpacing) || !isNaN((_a = this.tick.maxSpacing) != null ? _a : NaN);
    const keepEvery = tickSpacing ? Math.ceil(ticks.length / tickCount) : 2;
    return ticks.filter((_, i) => i % keepEvery === 0);
  }
  createTicks(tickCount, minTickCount, maxTickCount) {
    var _a, _b, _c;
    this.setTickCount(tickCount, minTickCount, maxTickCount);
    return (_c = (_b = (_a = this.scale).ticks) == null ? void 0 : _b.call(_a)) != null ? _c : [];
  }
  estimateTickCount({ minSpacing, maxSpacing }) {
    const { minRect } = this;
    const rangeWithBleed = this.calculateRangeWithBleed();
    const defaultMinSpacing = Math.max(
      this.defaultTickMinSpacing,
      rangeWithBleed / ContinuousScale$1.defaultMaxTickCount
    );
    let clampMaxTickCount = !isNaN(maxSpacing);
    if (isNaN(minSpacing)) {
      minSpacing = defaultMinSpacing;
    }
    if (isNaN(maxSpacing)) {
      maxSpacing = rangeWithBleed;
    }
    if (minSpacing > maxSpacing) {
      if (minSpacing === defaultMinSpacing) {
        minSpacing = maxSpacing;
      } else {
        maxSpacing = minSpacing;
      }
    }
    const minRectDistance = minRect ? this.direction === "x" /* X */ ? minRect.width : minRect.height : 1;
    clampMaxTickCount && (clampMaxTickCount = minRectDistance < defaultMinSpacing);
    const maxTickCount = clamp2$1(
      1,
      Math.floor(rangeWithBleed / minSpacing),
      clampMaxTickCount ? Math.floor(rangeWithBleed / minRectDistance) : Infinity
    );
    const minTickCount = Math.min(maxTickCount, Math.ceil(rangeWithBleed / maxSpacing));
    const defaultTickCount = clamp2$1(minTickCount, ContinuousScale$1.defaultTickCount, maxTickCount);
    return { minTickCount, maxTickCount, defaultTickCount };
  }
  updateVisibility() {
    if (this.moduleCtx.animationManager.isSkipped()) {
      this.resetSelectionNodes();
    }
    this.tickLineGroup.visible = this.tick.enabled;
    this.gridLineGroup.visible = this.gridLine.enabled;
    this.tickLabelGroup.visible = this.label.enabled;
  }
  updateCrossLines({
    rotation,
    parallelFlipRotation,
    regularFlipRotation
  }) {
    var _a;
    const sideFlag = this.label.getSideFlag();
    const anySeriesActive = this.isAnySeriesActive();
    (_a = this.crossLines) == null ? void 0 : _a.forEach((crossLine) => {
      var _a2;
      crossLine.sideFlag = -sideFlag;
      crossLine.direction = rotation === -Math.PI / 2 ? "x" /* X */ : "y" /* Y */;
      if (crossLine instanceof CartesianCrossLine$1) {
        crossLine.label.parallel = (_a2 = crossLine.label.parallel) != null ? _a2 : this.label.parallel;
      }
      crossLine.parallelFlipRotation = parallelFlipRotation;
      crossLine.regularFlipRotation = regularFlipRotation;
      crossLine.update(anySeriesActive);
    });
  }
  updateTickLines() {
    const { tick, label } = this;
    const sideFlag = label.getSideFlag();
    this.tickLineGroupSelection.each((line) => {
      line.strokeWidth = tick.width;
      line.stroke = tick.color;
      line.x1 = sideFlag * this.getTickSize();
      line.x2 = 0;
    });
  }
  calculateAvailableRange() {
    const { range: range3 } = this;
    const min = Math.min(...range3);
    const max = Math.max(...range3);
    return max - min;
  }
  /**
   * Calculates the available range with an additional "bleed" beyond the canvas that encompasses the full axis when
   * the visible range is only a portion of the axis.
   */
  calculateRangeWithBleed() {
    const { visibleRange } = this;
    const visibleScale = 1 / (visibleRange[1] - visibleRange[0]);
    return round$1(this.calculateAvailableRange() * visibleScale, 2);
  }
  calculateDomain() {
    if (this.linkedTo) {
      this.dataDomain = this.linkedTo.dataDomain;
    } else {
      const visibleSeries = this.boundSeries.filter((s) => this.includeInvisibleDomains || s.isEnabled());
      const domains = visibleSeries.flatMap((series) => series.getDomain(this.direction));
      const { domain, clipped } = this.normaliseDataDomain(domains);
      this.dataDomain = { domain: this.reverse ? [...domain].reverse() : domain, clipped };
    }
  }
  getAxisTransform() {
    return {
      rotation: toRadians$2(this.rotation),
      rotationCenterX: 0,
      rotationCenterY: 0,
      translationX: Math.floor(this.translation.x),
      translationY: Math.floor(this.translation.y)
    };
  }
  updatePosition() {
    const { crossLineGroup, axisGroup, gridGroup, translation, gridLineGroupSelection, gridPadding, gridLength } = this;
    const { rotation } = this.calculateRotations();
    const sideFlag = this.label.getSideFlag();
    const translationX = Math.floor(translation.x);
    const translationY = Math.floor(translation.y);
    crossLineGroup.setProperties({ rotation, translationX, translationY });
    axisGroup.datum = this.getAxisTransform();
    gridGroup.setProperties({ rotation, translationX, translationY });
    gridLineGroupSelection.each((line) => {
      line.x1 = gridPadding;
      line.x2 = -sideFlag * gridLength + gridPadding;
    });
  }
  updateSecondaryAxisTicks(_primaryTickCount) {
    throw new Error("AG Charts - unexpected call to updateSecondaryAxisTicks() - check axes configuration.");
  }
  updateSelections(lineData, data, params) {
    this.lineNode.datum = lineData;
    this.gridLineGroupSelection.update(
      this.gridLength ? data : [],
      (group2) => group2.append(new Line$1({ tag: 2 /* GridLine */ })),
      (datum) => datum.tickId
    );
    this.tickLineGroupSelection.update(
      data,
      (group2) => group2.appendChild(new Line$1({ tag: 0 /* TickLine */ })),
      (datum) => datum.tickId
    );
    this.tickLabelGroupSelection.update(
      data.map((d) => this.getTickLabelProps(d, params)),
      (group2) => group2.appendChild(new Text$1({ tag: 1 /* TickLabel */ })),
      (datum) => datum.tickId
    );
  }
  updateAxisLine() {
    const { line } = this;
    const strokeWidth = line.enabled ? line.width : 0;
    this.lineNode.setProperties({
      stroke: line.color,
      strokeWidth
    });
  }
  updateGridLines(sideFlag) {
    const {
      gridLine: { style, width },
      gridPadding,
      gridLength
    } = this;
    if (gridLength === 0 || style.length === 0) {
      return;
    }
    this.gridLineGroupSelection.each((line, _, index) => {
      const { stroke, lineDash } = style[index % style.length];
      line.setProperties({
        x1: gridPadding,
        x2: -sideFlag * gridLength + gridPadding,
        fill: void 0,
        stroke,
        strokeWidth: width,
        lineDash
      });
    });
  }
  updateLabels() {
    const { label } = this;
    if (!label.enabled) {
      return;
    }
    this.tickLabelGroupSelection.each((node, datum) => {
      node.setProperties(datum, [
        "fill",
        "fontFamily",
        "fontSize",
        "fontStyle",
        "fontWeight",
        "text",
        "textAlign",
        "textBaseline"
      ]);
    });
  }
  wrapLabels(tickData, index, labelProps) {
    const { parallel, maxWidth, maxHeight } = this.label;
    let defaultMaxWidth = this.maxThickness;
    let defaultMaxHeight = Math.round(this.calculateAvailableRange() / tickData.labelCount);
    if (parallel) {
      [defaultMaxWidth, defaultMaxHeight] = [defaultMaxHeight, defaultMaxWidth];
    }
    tickData.ticks.forEach((tickDatum) => {
      const { text } = Text$1.wrap(
        tickDatum.tickLabel,
        maxWidth != null ? maxWidth : defaultMaxWidth,
        maxHeight != null ? maxHeight : defaultMaxHeight,
        labelProps,
        "hyphenate"
      );
      tickDatum.tickLabel = text;
    });
    return { tickData, index, autoRotation: 0, terminate: true };
  }
  updateTitle(params) {
    const { rotation, title, _titleCaption, lineNode, tickLineGroup, tickLabelGroup } = this;
    if (!title) {
      _titleCaption.enabled = false;
      return;
    }
    let spacing = 0;
    if (title.enabled && params.anyTickVisible) {
      const tickBBox = Group$1.computeBBox([tickLineGroup, tickLabelGroup, lineNode]);
      const tickWidth = rotation === 0 ? tickBBox.width : tickBBox.height;
      spacing += tickWidth + (!this.tickLabelGroup.visible ? this.seriesAreaPadding : 0);
    }
    this.setTitleProps(_titleCaption, { spacing });
  }
  // For formatting (nice rounded) tick values.
  formatTick(datum, index) {
    var _a, _b;
    const {
      label,
      labelFormatter,
      fractionDigits,
      moduleCtx: { callbackCache }
    } = this;
    if (label.formatter) {
      const value = fractionDigits > 0 ? datum : String(datum);
      return (_a = callbackCache.call(label.formatter, {
        value,
        index,
        fractionDigits,
        formatter: labelFormatter
      })) != null ? _a : value;
    } else if (labelFormatter) {
      return (_b = callbackCache.call(labelFormatter, datum)) != null ? _b : String(datum);
    }
    return String(datum);
  }
  // For formatting arbitrary values between the ticks.
  formatDatum(datum) {
    return String(datum);
  }
  computeBBox() {
    return this.axisGroup.computeBBox();
  }
  initCrossLine(crossLine) {
    crossLine.scale = this.scale;
    crossLine.gridLength = this.gridLength;
  }
  isAnySeriesActive() {
    return this.boundSeries.some((s) => this.includeInvisibleDomains || s.isEnabled());
  }
  clipTickLines(x, y, width, height) {
    this.tickLineGroup.setClipRectInGroupCoordinateSpace(new BBox$1(x, y, width, height));
  }
  clipGrid(x, y, width, height) {
    this.gridGroup.setClipRectInGroupCoordinateSpace(new BBox$1(x, y, width, height));
  }
  calculatePadding(min, _max, reverse) {
    const start = reverse ? _max : min;
    return [Math.abs(start * 0.01), Math.abs(start * 0.01)];
  }
  getTitleFormatterParams() {
    var _a;
    const boundSeries = this.boundSeries.reduce((acc, next) => {
      const keys = next.getKeys(this.direction);
      const names = next.getNames(this.direction);
      for (let idx = 0; idx < keys.length; idx++) {
        acc.push({ key: keys[idx], name: names[idx] });
      }
      return acc;
    }, []);
    return {
      direction: this.direction,
      boundSeries,
      defaultValue: (_a = this.title) == null ? void 0 : _a.text
    };
  }
  normaliseDataDomain(d) {
    return { domain: d, clipped: false };
  }
  getLayoutState() {
    return __spreadValues$1({
      rect: this.computeBBox(),
      gridPadding: this.gridPadding,
      seriesAreaPadding: this.seriesAreaPadding,
      tickSize: this.getTickSize()
    }, this.layout);
  }
  getModuleMap() {
    return this.moduleMap;
  }
  createModuleContext() {
    var _a;
    (_a = this.axisContext) != null ? _a : this.axisContext = this.createAxisContext();
    return __spreadProps$1(__spreadValues$1({}, this.moduleCtx), { parent: this.axisContext });
  }
  createAxisContext() {
    return {
      axisId: this.id,
      direction: this.direction,
      continuous: ContinuousScale$1.is(this.scale),
      keys: () => this.boundSeries.flatMap((s) => s.getKeys(this.direction)),
      scaleValueFormatter: (specifier) => {
        var _a, _b;
        return (_b = (_a = this.scale).tickFormat) == null ? void 0 : _b.call(_a, { specifier });
      },
      scaleBandwidth: () => {
        var _a;
        return (_a = this.scale.bandwidth) != null ? _a : 0;
      },
      scaleConvert: (val) => this.scale.convert(val),
      scaleInvert: (val) => {
        var _a, _b;
        return (_b = (_a = this.scale).invert) == null ? void 0 : _b.call(_a, val);
      }
    };
  }
  animateReadyUpdate(diff2) {
    const { animationManager } = this.moduleCtx;
    const selectionCtx = prepareAxisAnimationContext$1(this);
    const fns = prepareAxisAnimationFunctions$1(selectionCtx);
    fromToMotion$1(this.id, "axis-group", animationManager, [this.axisGroup], fns.group);
    fromToMotion$1(this.id, "line", animationManager, [this.lineNode], fns.line);
    fromToMotion$1(
      this.id,
      "line-paths",
      animationManager,
      [this.gridLineGroupSelection, this.tickLineGroupSelection],
      fns.tick,
      (_, d) => d.tickId,
      diff2
    );
    fromToMotion$1(
      this.id,
      "tick-labels",
      animationManager,
      [this.tickLabelGroupSelection],
      fns.label,
      (_, d) => d.tickId,
      diff2
    );
  }
  resetSelectionNodes() {
    const { gridLineGroupSelection, tickLineGroupSelection, tickLabelGroupSelection, lineNode } = this;
    const selectionCtx = prepareAxisAnimationContext$1(this);
    resetMotion$1([this.axisGroup], resetAxisGroupFn$1());
    resetMotion$1([gridLineGroupSelection, tickLineGroupSelection], resetAxisSelectionFn$1(selectionCtx));
    resetMotion$1([tickLabelGroupSelection], resetAxisLabelSelectionFn$1());
    resetMotion$1([lineNode], resetAxisLineSelectionFn$1());
  }
  calculateUpdateDiff(previous, tickData) {
    const added = /* @__PURE__ */ new Set();
    const removed = /* @__PURE__ */ new Set();
    const tickCount = Math.max(previous.length, tickData.ticks.length);
    for (let i = 0; i < tickCount; i++) {
      const tickDatum = tickData.ticks[i];
      const prev = previous[i];
      const tick = tickDatum == null ? void 0 : tickDatum.tickId;
      if (prev === tick) {
        continue;
      }
      if (removed.has(tick)) {
        removed.delete(tick);
      } else if (tick) {
        added.add(tick);
      }
      if (added.has(prev)) {
        added.delete(prev);
      } else if (prev) {
        removed.add(prev);
      }
    }
    return {
      changed: added.size > 0 || removed.size > 0,
      added: [...added.values()],
      removed: [...removed.values()]
    };
  }
  isReversed() {
    return !!this.reverse;
  }
};
_Axis$1.defaultTickMinSpacing = 50;
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], _Axis$1.prototype, "nice", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1, { optional: true })
], _Axis$1.prototype, "reverse", 2);
__decorateClass$1([
  Validate$1(STRING_ARRAY$1)
], _Axis$1.prototype, "keys", 2);
__decorateClass$1([
  Validate$1(predicateWithMessage$1((title) => typeof title == "object", "Title object"), { optional: true })
], _Axis$1.prototype, "title", 2);
var Axis$1 = _Axis$1;

// packages/ag-charts-community/src/chart/axis/cartesianAxisLabel.ts
var CartesianAxisLabel$1 = class extends AxisLabel$1 {
  constructor() {
    super(...arguments);
    this.autoRotateAngle = 335;
  }
};
__decorateClass$1([
  Validate$1(BOOLEAN$1, { optional: true })
], CartesianAxisLabel$1.prototype, "autoRotate", 2);
__decorateClass$1([
  Validate$1(DEGREE$1)
], CartesianAxisLabel$1.prototype, "autoRotateAngle", 2);

// packages/ag-charts-community/src/chart/axis/cartesianAxis.ts
var CartesianAxis$1 = class extends Axis$1 {
  constructor() {
    super(...arguments);
    this.thickness = 0;
    this.position = "left";
  }
  get direction() {
    return ["top", "bottom"].includes(this.position) ? "x" /* X */ : "y" /* Y */;
  }
  updateDirection() {
    switch (this.position) {
      case "top":
        this.rotation = -90;
        this.label.mirrored = true;
        this.label.parallel = true;
        break;
      case "right":
        this.rotation = 0;
        this.label.mirrored = true;
        this.label.parallel = false;
        break;
      case "bottom":
        this.rotation = -90;
        this.label.mirrored = false;
        this.label.parallel = true;
        break;
      case "left":
        this.rotation = 0;
        this.label.mirrored = false;
        this.label.parallel = false;
        break;
    }
    if (this.axisContext) {
      this.axisContext.position = this.position;
      this.axisContext.direction = this.direction;
    }
  }
  update(primaryTickCount) {
    this.updateDirection();
    return super.update(primaryTickCount);
  }
  calculateLayout(primaryTickCount) {
    this.updateDirection();
    return super.calculateLayout(primaryTickCount);
  }
  createAxisContext() {
    return __spreadProps$1(__spreadValues$1({}, super.createAxisContext()), {
      position: this.position
    });
  }
  assignCrossLineArrayConstructor(crossLines) {
    assignJsonApplyConstructedArray$1(crossLines, CartesianCrossLine$1);
  }
  createLabel() {
    return new CartesianAxisLabel$1();
  }
};
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], CartesianAxis$1.prototype, "thickness", 2);
__decorateClass$1([
  Validate$1(POSITION$1)
], CartesianAxis$1.prototype, "position", 2);

// packages/ag-charts-community/src/chart/axis/categoryAxis.ts
var CategoryAxis$1 = class extends CartesianAxis$1 {
  constructor(moduleCtx) {
    super(moduleCtx, new BandScale$6());
    this._paddingOverrideEnabled = false;
    this.groupPaddingInner = 0.1;
    this.includeInvisibleDomains = true;
  }
  set paddingInner(value) {
    this._paddingOverrideEnabled = true;
    this.scale.paddingInner = value;
  }
  get paddingInner() {
    this._paddingOverrideEnabled = true;
    return this.scale.paddingInner;
  }
  set paddingOuter(value) {
    this.scale.paddingOuter = value;
  }
  get paddingOuter() {
    return this.scale.paddingOuter;
  }
  normaliseDataDomain(d) {
    const domain = [];
    const uniqueValues = /* @__PURE__ */ new Set();
    for (const v of d) {
      const key = v instanceof Date ? v.getTime() : v;
      if (!uniqueValues.has(key)) {
        uniqueValues.add(key);
        domain.push(v);
      }
    }
    return { domain, clipped: false };
  }
  calculateDomain() {
    if (!this._paddingOverrideEnabled) {
      const paddings = this.boundSeries.map((s) => {
        var _a;
        return (_a = s.getBandScalePadding) == null ? void 0 : _a.call(s);
      }).filter((p) => p != null);
      if (paddings.length > 0) {
        this.scale.paddingInner = Math.min(...paddings.map((p) => p.inner));
        this.scale.paddingOuter = Math.max(...paddings.map((p) => p.outer));
      }
    }
    return super.calculateDomain();
  }
};
CategoryAxis$1.className = "CategoryAxis";
CategoryAxis$1.type = "category";
__decorateClass$1([
  Validate$1(RATIO$1)
], CategoryAxis$1.prototype, "groupPaddingInner", 2);

// packages/ag-charts-community/src/chart/axis/tree.ts
var TreeNode$1 = class {
  // current number in sibling group (index)
  constructor(label = "", parent, number = 0) {
    this.x = 0;
    this.y = 0;
    this.subtreeLeft = NaN;
    this.subtreeRight = NaN;
    this.screenX = 0;
    this.screenY = 0;
    this.children = [];
    this.leafCount = 0;
    this.prelim = 0;
    this.mod = 0;
    this.ancestor = this;
    this.change = 0;
    this.shift = 0;
    this.label = label;
    this.parent = parent;
    this.depth = parent ? parent.depth + 1 : 0;
    this.number = number;
  }
  getLeftSibling() {
    return this.number > 0 && this.parent ? this.parent.children[this.number - 1] : void 0;
  }
  getLeftmostSibling() {
    return this.number > 0 && this.parent ? this.parent.children[0] : void 0;
  }
  // traverse the left contour of a subtree, return the successor of v on this contour
  nextLeft() {
    return this.children ? this.children[0] : this.thread;
  }
  // traverse the right contour of a subtree, return the successor of v on this contour
  nextRight() {
    return this.children ? this.children[this.children.length - 1] : this.thread;
  }
  getSiblings() {
    return this.parent ? this.parent.children.filter((_, i) => i !== this.number) : [];
  }
};
function ticksToTree$1(ticks, pad2 = true) {
  const root = new TreeNode$1();
  let depth = 0;
  if (pad2) {
    ticks.forEach((tick) => depth = Math.max(depth, tick.labels.length));
  }
  ticks.forEach((tick) => {
    if (pad2) {
      while (tick.labels.length < depth) {
        tick.labels.unshift("");
      }
    }
    insertTick$1(root, tick);
  });
  return root;
}
function insertTick$1(root, tick) {
  const pathParts = tick.labels.slice().reverse();
  const lastPartIndex = pathParts.length - 1;
  pathParts.forEach((pathPart, partIndex) => {
    const children = root.children;
    const existingNode = children.find((child) => child.label === pathPart);
    const isNotLeaf = partIndex !== lastPartIndex;
    if (existingNode && isNotLeaf) {
      root = existingNode;
    } else {
      const node = new TreeNode$1(pathPart, root);
      node.number = children.length;
      children.push(node);
      if (isNotLeaf) {
        root = node;
      }
    }
  });
}
function moveSubtree$1(wm, wp, shift) {
  const subtrees = wp.number - wm.number;
  const ratio = shift / subtrees;
  wp.change -= ratio;
  wp.shift += shift;
  wm.change += ratio;
  wp.prelim += shift;
  wp.mod += shift;
}
function ancestor$1(vim, v, defaultAncestor) {
  return v.getSiblings().indexOf(vim.ancestor) >= 0 ? vim.ancestor : defaultAncestor;
}
function executeShifts$1(v) {
  const children = v.children;
  if (children) {
    let shift = 0;
    let change = 0;
    for (let i = children.length - 1; i >= 0; i--) {
      const w = children[i];
      w.prelim += shift;
      w.mod += shift;
      change += w.change;
      shift += w.shift + change;
    }
  }
}
function apportion$1(v, defaultAncestor, distance) {
  const w = v.getLeftSibling();
  if (w) {
    let vop = v;
    let vip = v;
    let vim = w;
    let vom = vip.getLeftmostSibling();
    let sip = vip.mod;
    let sop = vop.mod;
    let sim = vim.mod;
    let som = vom.mod;
    while (vim.nextRight() && vip.nextLeft()) {
      vim = vim.nextRight();
      vip = vip.nextLeft();
      vom = vom.nextLeft();
      vop = vop.nextRight();
      vop.ancestor = v;
      const shift = vim.prelim + sim - (vip.prelim + sip) + distance;
      if (shift > 0) {
        moveSubtree$1(ancestor$1(vim, v, defaultAncestor), v, shift);
        sip += shift;
        sop += shift;
      }
      sim += vim.mod;
      sip += vip.mod;
      som += vom.mod;
      sop += vop.mod;
    }
    if (vim.nextRight() && !vop.nextRight()) {
      vop.thread = vim.nextRight();
      vop.mod += sim - sop;
    } else {
      if (vip.nextLeft() && !vom.nextLeft()) {
        vom.thread = vip.nextLeft();
        vom.mod += sip - som;
      }
      defaultAncestor = v;
    }
  }
  return defaultAncestor;
}
function firstWalk$1(node, distance) {
  const children = node.children;
  if (children.length) {
    let defaultAncestor = children[0];
    children.forEach((child) => {
      firstWalk$1(child, distance);
      defaultAncestor = apportion$1(child, defaultAncestor, distance);
    });
    executeShifts$1(node);
    const midpoint = (children[0].prelim + children[children.length - 1].prelim) / 2;
    const leftSibling = node.getLeftSibling();
    if (leftSibling) {
      node.prelim = leftSibling.prelim + distance;
      node.mod = node.prelim - midpoint;
    } else {
      node.prelim = midpoint;
    }
  } else {
    const leftSibling = node.getLeftSibling();
    node.prelim = leftSibling ? leftSibling.prelim + distance : 0;
  }
}
var Dimensions$1 = class {
  constructor() {
    this.top = Infinity;
    this.right = -Infinity;
    this.bottom = -Infinity;
    this.left = Infinity;
  }
  update(node, xy) {
    const { x, y } = xy(node);
    if (x > this.right) {
      this.right = x;
    }
    if (x < this.left) {
      this.left = x;
    }
    if (y > this.bottom) {
      this.bottom = y;
    }
    if (y < this.top) {
      this.top = y;
    }
  }
};
function secondWalk$1(v, m, layout) {
  v.x = v.prelim + m;
  v.y = v.depth;
  layout.update(v);
  v.children.forEach((w) => secondWalk$1(w, m + v.mod, layout));
}
function thirdWalk$1(v) {
  const children = v.children;
  let leafCount = 0;
  children.forEach((w) => {
    thirdWalk$1(w);
    if (w.children.length) {
      leafCount += w.leafCount;
    } else {
      leafCount++;
    }
  });
  v.leafCount = leafCount;
  if (children.length) {
    v.subtreeLeft = children[0].subtreeLeft;
    v.subtreeRight = children[v.children.length - 1].subtreeRight;
    v.x = (v.subtreeLeft + v.subtreeRight) / 2;
  } else {
    v.subtreeLeft = v.x;
    v.subtreeRight = v.x;
  }
}
function treeLayout$1(root) {
  const layout = new TreeLayout$1();
  firstWalk$1(root, 1);
  secondWalk$1(root, -root.prelim, layout);
  thirdWalk$1(root);
  return layout;
}
var TreeLayout$1 = class {
  constructor() {
    this.dimensions = new Dimensions$1();
    this.leafCount = 0;
    this.nodes = [];
    // One might want to process leaf nodes separately from the rest of the tree.
    // For example, position labels corresponding to leafs vertically, rather than horizontally.
    this.leafNodes = [];
    this.nonLeafNodes = [];
    this.depth = 0;
  }
  update(node) {
    this.dimensions.update(node, (node2) => ({ x: node2.x, y: node2.y }));
    if (!node.children.length) {
      this.leafCount++;
      this.leafNodes.push(node);
    } else {
      this.nonLeafNodes.push(node);
    }
    if (node.depth > this.depth) {
      this.depth = node.depth;
    }
    this.nodes.push(node);
  }
  resize(width, height, shiftX = 0, shiftY = 0, flipX = false) {
    const xSteps = this.leafCount - 1;
    const ySteps = this.depth;
    const dimensions = this.dimensions;
    let scalingX = 1;
    let scalingY = 1;
    if (width > 0 && xSteps) {
      const existingSpacingX = (dimensions.right - dimensions.left) / xSteps;
      const desiredSpacingX = width / xSteps;
      scalingX = desiredSpacingX / existingSpacingX;
      if (flipX) {
        scalingX = -scalingX;
      }
    }
    if (height > 0 && ySteps) {
      const existingSpacingY = (dimensions.bottom - dimensions.top) / ySteps;
      const desiredSpacingY = height / ySteps;
      scalingY = desiredSpacingY / existingSpacingY;
    }
    const screenDimensions = new Dimensions$1();
    this.nodes.forEach((node) => {
      node.screenX = node.x * scalingX;
      node.screenY = node.y * scalingY;
      screenDimensions.update(node, (node2) => ({ x: node2.screenX, y: node2.screenY }));
    });
    const offsetX = -screenDimensions.left;
    const offsetY = -screenDimensions.top;
    this.nodes.forEach((node) => {
      node.screenX += offsetX + shiftX;
      node.screenY += offsetY + shiftY;
    });
  }
};

// packages/ag-charts-community/src/chart/axis/groupedCategoryAxis.ts
var GroupedCategoryAxisLabel$1 = class extends AxisLabel$1 {
  constructor() {
    super(...arguments);
    this.grid = false;
  }
};
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], GroupedCategoryAxisLabel$1.prototype, "grid", 2);
var GroupedCategoryAxis$1 = class extends CartesianAxis$1 {
  constructor(moduleCtx) {
    super(moduleCtx, new BandScale$6());
    // Label scale (labels are positioned between ticks, tick count = label count + 1).
    // We don't call is `labelScale` for consistency with other axes.
    this.tickScale = new BandScale$6();
    this.line = new AxisLine$1();
    this.label = new GroupedCategoryAxisLabel$1();
    this.labelColor = "rgba(87, 87, 87, 1)";
    this.includeInvisibleDomains = true;
    const { tickLineGroup, tickLabelGroup, gridLineGroup, tickScale, scale: scale2 } = this;
    scale2.paddingOuter = 0.1;
    scale2.paddingInner = scale2.paddingOuter * 2;
    this.range = scale2.range.slice();
    this.refreshScale();
    tickScale.paddingInner = 1;
    tickScale.paddingOuter = 0;
    this.gridLineSelection = Selection$1.select(gridLineGroup, Line$1);
    this.axisLineSelection = Selection$1.select(tickLineGroup, Line$1);
    this.separatorSelection = Selection$1.select(tickLineGroup, Line$1);
    this.labelSelection = Selection$1.select(tickLabelGroup, Text$1);
  }
  updateRange() {
    const { range: rr, visibleRange: vr, scale: scale2 } = this;
    const span = (rr[1] - rr[0]) / (vr[1] - vr[0]);
    const shift = span * vr[0];
    const start = rr[0] - shift;
    this.tickScale.range = scale2.range = [start, start + span];
    this.resizeTickTree();
  }
  resizeTickTree() {
    var _a;
    const s = this.scale;
    const range3 = s.domain.length ? [s.convert(s.domain[0]), s.convert(s.domain[s.domain.length - 1])] : s.range;
    const layout = this.tickTreeLayout;
    const lineHeight = this.lineHeight;
    if (layout) {
      layout.resize(
        Math.abs(range3[1] - range3[0]),
        layout.depth * lineHeight,
        (Math.min(range3[0], range3[1]) || 0) + ((_a = s.bandwidth) != null ? _a : 0) / 2,
        -layout.depth * lineHeight,
        range3[1] - range3[0] < 0
      );
    }
  }
  get lineHeight() {
    return this.label.fontSize * 1.5;
  }
  /**
   * The length of the grid. The grid is only visible in case of a non-zero value.
   */
  set gridLength(value) {
    if (this._gridLength && !value || !this._gridLength && value) {
      this.gridLineSelection.clear();
      this.labelSelection.clear();
    }
    this._gridLength = value;
  }
  get gridLength() {
    return this._gridLength;
  }
  calculateDomain() {
    var _a;
    const { direction } = this;
    const domains = [];
    let isNumericX;
    this.boundSeries.filter((s) => s.visible).forEach((series) => {
      if (direction === "x" /* X */) {
        if (isNumericX === void 0) {
          const domain2 = series.getDomain(direction);
          domains.push(domain2);
          isNumericX = typeof domain2[0] === "number";
        } else if (isNumericX) {
          domains.push(series.getDomain(direction));
        }
      } else {
        domains.push(series.getDomain(direction));
      }
    });
    const domain = new Array().concat(...domains);
    const domainExtent = (_a = extent$5(domain)) != null ? _a : domain;
    const values = this.reverse ? [...domainExtent].reverse() : domainExtent;
    const { domain: normalisedDataDomain, clipped } = this.normaliseDataDomain(values);
    this.dataDomain = {
      domain: normalisedDataDomain,
      clipped
    };
    this.scale.domain = normalisedDataDomain;
  }
  normaliseDataDomain(d) {
    const values = d.filter((s, i, arr) => arr.indexOf(s) === i);
    const tickTree = ticksToTree$1(values);
    this.tickTreeLayout = treeLayout$1(tickTree);
    const tickScaleDomain = values.slice();
    tickScaleDomain.push("");
    this.tickScale.domain = tickScaleDomain;
    this.resizeTickTree();
    return { domain: values, clipped: false };
  }
  /**
   * Creates/removes/updates the scene graph nodes that constitute the axis.
   * Supposed to be called _manually_ after changing _any_ of the axis properties.
   * This allows to bulk set axis properties before updating the nodes.
   * The node changes made by this method are rendered on the next animation frame.
   * We could schedule this method call automatically on the next animation frame
   * when any of the axis properties change (the way we do when properties of scene graph's
   * nodes change), but this will mean that we first wait for the next animation
   * frame to make changes to the nodes of the axis, then wait for another animation
   * frame to render those changes. It's nice to have everything update automatically,
   * but this extra level of async indirection will not just introduce an unwanted delay,
   * it will also make it harder to reason about the program.
   */
  update() {
    if (!this.computedLayout) {
      return;
    }
    this.updatePosition();
    this.updateTitleCaption();
    this.updateCategoryLabels();
    this.updateSeparators();
    this.updateAxisLines();
    this.updateCategoryGridLines();
    this.resetSelectionNodes();
    return void 0;
  }
  updateTitleCaption() {
    const { _titleCaption } = this;
    _titleCaption.node.visible = false;
  }
  updateCategoryLabels() {
    if (!this.computedLayout)
      return;
    const { tickLabelLayout } = this.computedLayout;
    const labelSelection = this.labelSelection.update(tickLabelLayout);
    labelSelection.each((node, datum) => {
      node.setProperties(datum);
    });
  }
  updateSeparators() {
    if (!this.computedLayout)
      return;
    const { separatorLayout } = this.computedLayout;
    const { range: range3 } = this;
    const epsilon2 = 1e-7;
    const separatorSelection = this.separatorSelection.update(separatorLayout);
    separatorSelection.each((line, datum) => {
      line.x1 = datum.x1;
      line.x2 = datum.x2;
      line.y1 = datum.y;
      line.y2 = datum.y;
      line.visible = datum.y >= range3[0] - epsilon2 && datum.y <= range3[1] + epsilon2;
      line.stroke = this.tick.color;
      line.fill = void 0;
      line.strokeWidth = 1;
    });
  }
  updateAxisLines() {
    if (!this.computedLayout)
      return;
    const { axisLineLayout } = this.computedLayout;
    const axisLineSelection = this.axisLineSelection.update(axisLineLayout);
    axisLineSelection.each((line, datum) => {
      line.setProperties(__spreadProps$1(__spreadValues$1({}, datum), {
        stroke: this.line.color,
        strokeWidth: this.line.width
      }));
      line.x1 = datum.x;
      line.x2 = datum.x;
      line.y1 = datum.y1;
      line.y2 = datum.y2;
      line.strokeWidth = this.line.width;
      line.stroke = this.line.color;
    });
  }
  updateCategoryGridLines() {
    const { gridLength, gridLine, label, range: range3, tickScale } = this;
    const ticks = tickScale.ticks();
    const sideFlag = label.getSideFlag();
    const gridSelection = this.gridLineSelection.update(gridLength ? ticks : []);
    if (gridLength) {
      const { width, style } = gridLine;
      const styleCount = style.length;
      gridSelection.each((line, datum, index) => {
        const y = Math.round(tickScale.convert(datum));
        line.x1 = 0;
        line.x2 = -sideFlag * gridLength;
        line.y1 = y;
        line.y2 = y;
        line.visible = y >= range3[0] && y <= range3[1];
        const { stroke, lineDash } = style[index % styleCount];
        line.stroke = stroke;
        line.strokeWidth = width;
        line.lineDash = lineDash;
        line.fill = void 0;
      });
    }
  }
  computeLayout() {
    this.updateDirection();
    this.calculateDomain();
    this.updateRange();
    const {
      scale: scale2,
      label,
      label: { parallel },
      moduleCtx: { callbackCache },
      range: range3,
      title,
      title: { formatter = (p) => p.defaultValue } = {}
    } = this;
    const rangeStart = scale2.range[0];
    const rangeEnd = scale2.range[1];
    const rangeLength = Math.abs(rangeEnd - rangeStart);
    const bandwidth = rangeLength / scale2.domain.length || 0;
    const rotation = toRadians$2(this.rotation);
    const isHorizontal = Math.abs(Math.cos(rotation)) < 1e-8;
    const sideFlag = label.getSideFlag();
    const lineHeight = this.lineHeight;
    const tickTreeLayout = this.tickTreeLayout;
    const labels = scale2.ticks();
    const treeLabels = tickTreeLayout ? tickTreeLayout.nodes : [];
    const isLabelTree = tickTreeLayout ? tickTreeLayout.depth > 1 : false;
    const { defaultRotation, configuredRotation, parallelFlipFlag } = calculateLabelRotation$1({
      rotation: label.rotation,
      parallel,
      regularFlipRotation: normalizeAngle360$1(rotation - Math.PI / 2),
      parallelFlipRotation: normalizeAngle360$1(rotation)
    });
    const tickLabelLayout = [];
    const copyLabelProps = (node) => {
      return {
        fill: node.fill,
        fontFamily: node.fontFamily,
        fontSize: node.fontSize,
        fontStyle: node.fontStyle,
        fontWeight: node.fontWeight,
        rotation: node.rotation,
        rotationCenterX: node.rotationCenterX,
        rotationCenterY: node.rotationCenterY,
        text: node.text,
        textAlign: node.textAlign,
        textBaseline: node.textBaseline,
        translationX: node.translationX,
        translationY: node.translationY,
        visible: node.visible,
        x: node.x,
        y: node.y
      };
    };
    const labelBBoxes = /* @__PURE__ */ new Map();
    let maxLeafLabelWidth = 0;
    const tempText = new Text$1();
    const setLabelProps = (datum, index) => {
      var _a;
      tempText.setProperties({
        fill: label.color,
        fontFamily: label.fontFamily,
        fontSize: label.fontSize,
        fontStyle: label.fontStyle,
        fontWeight: label.fontWeight,
        textAlign: "center",
        textBaseline: parallelFlipFlag === -1 ? "bottom" : "hanging",
        translationX: datum.screenY - label.fontSize * 0.25,
        translationY: datum.screenX
      });
      if (index === 0) {
        const isCaptionEnabled = (title == null ? void 0 : title.enabled) && labels.length > 0;
        if (!isCaptionEnabled) {
          return false;
        }
        const text = callbackCache.call(formatter, this.getTitleFormatterParams());
        tempText.setProperties({
          fill: title.color,
          fontFamily: title.fontFamily,
          fontSize: title.fontSize,
          fontStyle: title.fontStyle,
          fontWeight: title.fontWeight,
          text,
          textBaseline: "hanging",
          translationX: datum.screenY - label.fontSize * 0.25,
          translationY: datum.screenX
        });
      } else {
        const isInRange = datum.screenX >= range3[0] && datum.screenX <= range3[1];
        if (!isInRange) {
          return false;
        }
        if (label.formatter) {
          tempText.text = (_a = callbackCache.call(label.formatter, {
            value: String(datum.label),
            index
          })) != null ? _a : String(datum.label);
        } else {
          tempText.text = String(datum.label);
        }
      }
      return true;
    };
    treeLabels.forEach((datum, index) => {
      const isVisible = setLabelProps(datum, index);
      if (isVisible) {
        const bbox2 = tempText.computeTransformedBBox();
        if (bbox2) {
          labelBBoxes.set(index, bbox2);
          const isLeaf = !datum.children.length;
          if (isLeaf && bbox2.width > maxLeafLabelWidth) {
            maxLeafLabelWidth = bbox2.width;
          }
        }
      }
    });
    const labelX = sideFlag * label.padding;
    const labelGrid = this.label.grid;
    const separatorData = [];
    treeLabels.forEach((datum, index) => {
      let visible = setLabelProps(datum, index);
      const id = index;
      tempText.x = labelX;
      tempText.rotationCenterX = labelX;
      const isLeaf = !datum.children.length;
      if (isLeaf) {
        tempText.rotation = configuredRotation;
        tempText.textAlign = "end";
        tempText.textBaseline = "middle";
        const bbox2 = labelBBoxes.get(id);
        if (bbox2 && bbox2.height > bandwidth) {
          visible = false;
          labelBBoxes.delete(id);
        }
      } else {
        tempText.translationX -= maxLeafLabelWidth - lineHeight + this.label.padding;
        const availableRange = datum.leafCount * bandwidth;
        const bbox2 = labelBBoxes.get(id);
        if (bbox2 && bbox2.width > availableRange) {
          visible = false;
          labelBBoxes.delete(id);
        } else if (isHorizontal) {
          tempText.rotation = defaultRotation;
        } else {
          tempText.rotation = -Math.PI / 2;
        }
      }
      if (datum.parent && isLabelTree) {
        const y = isLeaf ? datum.screenX - bandwidth / 2 : datum.screenX - datum.leafCount * bandwidth / 2;
        if (isLeaf) {
          if (datum.number !== datum.children.length - 1 || labelGrid) {
            separatorData.push({
              y,
              x1: 0,
              x2: -maxLeafLabelWidth - this.label.padding * 2
            });
          }
        } else {
          const x = -maxLeafLabelWidth - this.label.padding * 2 + datum.screenY;
          separatorData.push({
            y,
            x1: x + lineHeight,
            x2: x
          });
        }
      }
      let props;
      if (visible) {
        const bbox2 = tempText.computeTransformedBBox();
        if (bbox2) {
          labelBBoxes.set(index, bbox2);
        }
        props = __spreadProps$1(__spreadValues$1({}, copyLabelProps(tempText)), { visible });
      } else {
        labelBBoxes.delete(index);
        props = { visible };
      }
      tickLabelLayout.push(props);
    });
    let minX = 0;
    separatorData.forEach((d) => minX = Math.min(minX, d.x2));
    separatorData.push({
      y: Math.max(rangeStart, rangeEnd),
      x1: 0,
      x2: minX
    });
    const separatorLayout = [];
    const separatorBoxes = [];
    const epsilon2 = 1e-7;
    separatorData.forEach((datum) => {
      if (datum.y >= range3[0] - epsilon2 && datum.y <= range3[1] + epsilon2) {
        const { x1, x2, y } = datum;
        const separatorBox = new BBox$1(Math.min(x1, x2), y, Math.abs(x1 - x2), 0);
        separatorBoxes.push(separatorBox);
        separatorLayout.push({ x1, x2, y });
      }
    });
    const axisLineLayout = [];
    const axisLineBoxes = [];
    const lineCount = tickTreeLayout ? tickTreeLayout.depth + 1 : 1;
    for (let i = 0; i < lineCount; i++) {
      const visible = labels.length > 0 && (i === 0 || labelGrid && isLabelTree);
      const x = i > 0 ? -maxLeafLabelWidth - this.label.padding * 2 - (i - 1) * lineHeight : 0;
      const lineBox = new BBox$1(x, Math.min(...range3), 0, Math.abs(range3[1] - range3[0]));
      axisLineBoxes.push(lineBox);
      axisLineLayout.push({ x, y1: range3[0], y2: range3[1], visible });
    }
    const getTransformBox = (bbox2) => {
      const matrix = new Matrix$1();
      const {
        rotation: axisRotation,
        translationX,
        translationY,
        rotationCenterX,
        rotationCenterY
      } = this.getAxisTransform();
      Matrix$1.updateTransformMatrix(matrix, 1, 1, axisRotation, translationX, translationY, {
        scalingCenterX: 0,
        scalingCenterY: 0,
        rotationCenterX,
        rotationCenterY
      });
      return matrix.transformBBox(bbox2);
    };
    const bbox = BBox$1.merge([...labelBBoxes.values(), ...separatorBoxes, ...axisLineBoxes]);
    const transformedBBox = getTransformBox(bbox);
    return {
      bbox: transformedBBox,
      tickLabelLayout,
      separatorLayout,
      axisLineLayout
    };
  }
  calculateLayout() {
    const { axisLineLayout, separatorLayout, tickLabelLayout, bbox } = this.computeLayout();
    this.computedLayout = {
      axisLineLayout,
      separatorLayout,
      tickLabelLayout
    };
    return { bbox, primaryTickCount: void 0 };
  }
};
GroupedCategoryAxis$1.className = "GroupedCategoryAxis";
GroupedCategoryAxis$1.type = "grouped-category";
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true })
], GroupedCategoryAxis$1.prototype, "labelColor", 2);

// packages/ag-charts-community/src/util/async.ts
function sleep$1(sleepTimeoutMs) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(void 0), sleepTimeoutMs);
  });
}

// packages/ag-charts-community/src/util/callbackCache.ts
var CallbackCache$1 = class {
  constructor() {
    this.cache = /* @__PURE__ */ new WeakMap();
  }
  call(fn, ...params) {
    let serialisedParams;
    let paramCache = this.cache.get(fn);
    const invoke = () => {
      try {
        const result = fn(...params);
        if (paramCache && serialisedParams != null) {
          paramCache.set(serialisedParams, result);
        }
        return result;
      } catch (e) {
        Logger$1.warnOnce(`User callback errored, ignoring`, e);
        return void 0;
      }
    };
    try {
      serialisedParams = JSON.stringify(params);
    } catch (e) {
      return invoke();
    }
    if (paramCache == null) {
      paramCache = /* @__PURE__ */ new Map();
      this.cache.set(fn, paramCache);
    }
    if (!paramCache.has(serialisedParams)) {
      return invoke();
    }
    return paramCache.get(serialisedParams);
  }
  invalidateCache() {
    this.cache = /* @__PURE__ */ new WeakMap();
  }
};

// packages/ag-charts-community/src/util/mutex.ts
var Mutex$1 = class {
  constructor() {
    this.available = true;
    this.acquireQueue = [];
  }
  acquire(cb) {
    return new Promise((resolve) => {
      this.acquireQueue.push([cb, resolve]);
      if (this.available) {
        this.dispatchNext();
      }
    });
  }
  acquireImmediately(cb) {
    return __async$1(this, null, function* () {
      if (!this.available) {
        return false;
      }
      yield this.acquire(cb);
      return true;
    });
  }
  waitForClearAcquireQueue() {
    return __async$1(this, null, function* () {
      return this.acquire(() => __async$1(this, null, function* () {
        return void 0;
      }));
    });
  }
  dispatchNext() {
    return __async$1(this, null, function* () {
      var _a, _b;
      this.available = false;
      let [next, done] = (_a = this.acquireQueue.shift()) != null ? _a : [];
      while (next) {
        try {
          yield next();
          done == null ? void 0 : done();
        } catch (error) {
          Logger$1.error("mutex callback error", error);
          done == null ? void 0 : done();
        }
        [next, done] = (_b = this.acquireQueue.shift()) != null ? _b : [];
      }
      this.available = true;
    });
  }
};

// packages/ag-charts-community/src/util/observable.ts
var Observable$1 = class {
  constructor() {
    this.eventListeners = /* @__PURE__ */ new Map();
  }
  addEventListener(eventType, listener) {
    if (typeof listener !== "function") {
      throw new Error("AG Charts - listener must be a Function");
    }
    const eventTypeListeners = this.eventListeners.get(eventType);
    if (eventTypeListeners) {
      eventTypeListeners.add(listener);
    } else {
      this.eventListeners.set(eventType, /* @__PURE__ */ new Set([listener]));
    }
  }
  removeEventListener(type, listener) {
    var _a;
    (_a = this.eventListeners.get(type)) == null ? void 0 : _a.delete(listener);
    if (this.eventListeners.size === 0) {
      this.eventListeners.delete(type);
    }
  }
  hasEventListener(type) {
    return this.eventListeners.has(type);
  }
  clearEventListeners() {
    this.eventListeners.clear();
  }
  fireEvent(event) {
    var _a;
    (_a = this.eventListeners.get(event.type)) == null ? void 0 : _a.forEach((listener) => listener(event));
  }
};

// packages/ag-charts-community/src/util/render.ts
function debouncedAnimationFrame$1(cb) {
  return buildScheduler$1((cb2, _delayMs) => requestAnimationFrame(cb2), cb);
}
function debouncedCallback$1(cb) {
  return buildScheduler$1((cb2, delayMs = 0) => setTimeout(cb2, delayMs), cb);
}
function buildScheduler$1(scheduleFn, cb) {
  let scheduleCount = 0;
  let promiseRunning = false;
  let awaitingPromise;
  let awaitingDone;
  const busy = () => {
    return promiseRunning;
  };
  const done = () => {
    promiseRunning = false;
    awaitingDone == null ? void 0 : awaitingDone();
    awaitingDone = void 0;
    awaitingPromise = void 0;
    if (scheduleCount > 0) {
      scheduleFn(scheduleCb);
    }
  };
  const scheduleCb = () => {
    const count2 = scheduleCount;
    scheduleCount = 0;
    promiseRunning = true;
    const maybePromise = cb({ count: count2 });
    if (!maybePromise) {
      done();
      return;
    }
    maybePromise.then(done).catch(done);
  };
  return {
    schedule(delayMs) {
      if (scheduleCount === 0 && !busy()) {
        scheduleFn(scheduleCb, delayMs);
      }
      scheduleCount++;
    },
    await() {
      return __async$1(this, null, function* () {
        if (!busy()) {
          return;
        }
        if (awaitingPromise == null) {
          awaitingPromise = new Promise((resolve) => {
            awaitingDone = resolve;
          });
        }
        while (busy()) {
          yield awaitingPromise;
        }
      });
    }
  };
}

// packages/ag-charts-community/src/util/sizeMonitor.ts
var SizeMonitor$1 = class {
  static init(document2) {
    var _a;
    if (typeof ResizeObserver !== "undefined") {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { width, height } = entry.contentRect;
          this.checkSize(this.elements.get(entry.target), entry.target, width, height);
        }
      });
    } else {
      const step = () => {
        this.elements.forEach((entry, element2) => {
          this.checkClientSize(element2, entry);
        });
      };
      this.pollerHandler = (_a = document2.defaultView) == null ? void 0 : _a.setInterval(step, 100);
    }
    this.ownerDocument = document2;
    this.ready = true;
    this.documentReady = document2.readyState !== "loading";
    if (this.documentReady)
      return;
    this.readyEventFn = () => {
      const newState = document2.readyState !== "loading";
      const oldState = this.documentReady;
      this.documentReady = newState;
      if (!newState)
        return;
      if (newState === oldState)
        return;
      for (const [el, cb] of this.queuedObserveRequests) {
        this.observe(el, cb);
      }
      this.queuedObserveRequests.length = 0;
    };
    document2.addEventListener("DOMContentLoaded", this.readyEventFn);
  }
  static destroy() {
    var _a, _b;
    if (this.pollerHandler != null) {
      clearInterval(this.pollerHandler);
      this.pollerHandler = void 0;
    }
    if (this.readyEventFn) {
      (_a = this.ownerDocument) == null ? void 0 : _a.removeEventListener("DOMContentLoaded", this.readyEventFn);
      this.readyEventFn = void 0;
    }
    (_b = this.resizeObserver) == null ? void 0 : _b.disconnect();
    this.resizeObserver = void 0;
    this.ready = false;
    this.ownerDocument = void 0;
  }
  static checkSize(entry, element2, width, height) {
    if (!entry)
      return;
    if (!entry.size || width !== entry.size.width || height !== entry.size.height) {
      entry.size = { width, height };
      entry.cb(entry.size, element2);
    }
  }
  // Only a single callback is supported.
  static observe(element2, cb) {
    if (!this.ready) {
      this.init(element2.ownerDocument);
    }
    if (!this.documentReady) {
      this.queuedObserveRequests.push([element2, cb]);
      return;
    }
    this.unobserve(element2, false);
    if (this.resizeObserver) {
      this.resizeObserver.observe(element2);
    }
    this.elements.set(element2, { cb });
  }
  static unobserve(element2, cleanup = true) {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(element2);
    }
    this.elements.delete(element2);
    this.queuedObserveRequests = this.queuedObserveRequests.filter(([el]) => el === element2);
    if (cleanup && this.elements.size === 0) {
      this.destroy();
    }
  }
  static checkClientSize(element2, entry) {
    var _a, _b;
    const width = (_a = element2.clientWidth) != null ? _a : 0;
    const height = (_b = element2.clientHeight) != null ? _b : 0;
    this.checkSize(entry, element2, width, height);
  }
};
SizeMonitor$1.elements = /* @__PURE__ */ new Map();
SizeMonitor$1.ready = false;
SizeMonitor$1.documentReady = false;
SizeMonitor$1.queuedObserveRequests = [];

// packages/ag-charts-community/src/chart/chartHighlight.ts
var ChartHighlight$1 = class {
  constructor() {
    this.range = "tooltip";
  }
};
__decorateClass$1([
  Validate$1(UNION$1(["tooltip", "node"], "a range"))
], ChartHighlight$1.prototype, "range", 2);

// packages/ag-charts-community/src/chart/chartUpdateType.ts
var ChartUpdateType$1 = /* @__PURE__ */ ((ChartUpdateType2) => {
  ChartUpdateType2[ChartUpdateType2["FULL"] = 0] = "FULL";
  ChartUpdateType2[ChartUpdateType2["PROCESS_DATA"] = 1] = "PROCESS_DATA";
  ChartUpdateType2[ChartUpdateType2["PERFORM_LAYOUT"] = 2] = "PERFORM_LAYOUT";
  ChartUpdateType2[ChartUpdateType2["SERIES_UPDATE"] = 3] = "SERIES_UPDATE";
  ChartUpdateType2[ChartUpdateType2["TOOLTIP_RECALCULATION"] = 4] = "TOOLTIP_RECALCULATION";
  ChartUpdateType2[ChartUpdateType2["SCENE_RENDER"] = 5] = "SCENE_RENDER";
  ChartUpdateType2[ChartUpdateType2["NONE"] = 6] = "NONE";
  return ChartUpdateType2;
})(ChartUpdateType$1 || {});

// packages/ag-charts-community/src/chart/data/dataDomain.ts
var DataDomain$1 = class {
  constructor(type) {
    this.type = type;
    this.continuousDomain = [Infinity, -Infinity];
    this.discreteDomain = /* @__PURE__ */ new Set();
  }
  extend(val) {
    if (this.type === "discrete") {
      this.discreteDomain.add(val);
    } else if (this.type === "continuous") {
      if (this.continuousDomain[0] > val) {
        this.continuousDomain[0] = val;
      }
      if (this.continuousDomain[1] < val) {
        this.continuousDomain[1] = val;
      }
    }
  }
  getDomain() {
    if (this.type === "discrete") {
      return this.discreteDomain;
    } else if (this.type === "continuous") {
      return this.continuousDomain;
    }
    throw new Error("AG Charts - Unsupported data domain type: " + this.type);
  }
};

// packages/ag-charts-community/src/chart/data/utilFunctions.ts
function extendDomain$1(values, domain = [Infinity, -Infinity]) {
  for (const value of values) {
    if (typeof value !== "number") {
      continue;
    }
    if (value < domain[0]) {
      domain[0] = value;
    }
    if (value > domain[1]) {
      domain[1] = value;
    }
  }
  return domain;
}

// packages/ag-charts-community/src/chart/data/dataModel.ts
function toKeyString$1(keys) {
  return keys.map((v) => {
    if (v == null) {
      return v;
    } else if (typeof v === "number" || typeof v === "string" || typeof v === "boolean") {
      return v;
    } else if (typeof v === "object") {
      return JSON.stringify(v);
    }
    return v;
  }).join("-");
}
function round2$1(val) {
  const accuracy = 1e4;
  if (Number.isInteger(val)) {
    return val;
  } else if (Math.abs(val) > accuracy) {
    return Math.trunc(val);
  }
  return Math.round(val * accuracy) / accuracy;
}
function fixNumericExtentInternal$1(extent2) {
  if (extent2 === void 0) {
    return [];
  }
  let [min, max] = extent2;
  min = +min;
  max = +max;
  if (min === 0 && max === 0) {
    return [0, 1];
  }
  if (min === Infinity && max === -Infinity) {
    return [];
  }
  if (min === Infinity) {
    min = 0;
  }
  if (max === -Infinity) {
    max = 0;
  }
  if (!(isNumber2$1(min) && isNumber2$1(max))) {
    return [];
  }
  return [min, max];
}
function fixNumericExtent$1(extent2, axis) {
  var _a;
  const fixedExtent = fixNumericExtentInternal$1(extent2);
  if (fixedExtent.length === 0) {
    return fixedExtent;
  }
  let [min, max] = fixedExtent;
  if (min === max) {
    const [paddingMin, paddingMax] = (_a = axis == null ? void 0 : axis.calculatePadding(min, max, axis.isReversed())) != null ? _a : [1, 1];
    min -= paddingMin;
    max += paddingMax;
  }
  return [min, max];
}
var INVALID_VALUE$1 = Symbol("invalid");
var DataModel$1 = class {
  constructor(opts) {
    const { props, mode = "standalone" } = opts;
    this.mode = mode;
    let keys = true;
    for (const next of props) {
      if (next.type === "key" && !keys) {
        throw new Error("AG Charts - internal config error: keys must come before values.");
      }
      if (next.type === "value" && keys) {
        keys = false;
      }
    }
    this.opts = __spreadValues$1({ dataVisible: true }, opts);
    this.keys = props.filter((def) => def.type === "key").map((def, index) => __spreadProps$1(__spreadValues$1({}, def), { index, missing: 0 }));
    this.values = props.filter((def) => def.type === "value").map((def, index) => __spreadProps$1(__spreadValues$1({}, def), { index, missing: 0 }));
    this.aggregates = props.filter((def) => def.type === "aggregate").map((def, index) => __spreadProps$1(__spreadValues$1({}, def), { index }));
    this.groupProcessors = props.filter((def) => def.type === "group-value-processor").map((def, index) => __spreadProps$1(__spreadValues$1({}, def), { index }));
    this.propertyProcessors = props.filter((def) => def.type === "property-value-processor").map((def, index) => __spreadProps$1(__spreadValues$1({}, def), { index }));
    this.reducers = props.filter((def) => def.type === "reducer").map((def, index) => __spreadProps$1(__spreadValues$1({}, def), { index }));
    this.processors = props.filter((def) => def.type === "processor").map((def, index) => __spreadProps$1(__spreadValues$1({}, def), { index }));
    for (const def of this.values) {
      if (def.property == null) {
        throw new Error(
          `AG Charts - internal config error: no properties specified for value definitions: ${JSON.stringify(
            def
          )}`
        );
      }
    }
    const verifyMatchGroupId = ({ matchGroupIds }) => {
      for (const matchGroupId of matchGroupIds != null ? matchGroupIds : []) {
        if (!this.values.some((def) => def.groupId === matchGroupId)) {
          throw new Error(
            `AG Charts - internal config error: matchGroupIds properties must match defined groups (${matchGroupId}).`
          );
        }
      }
    };
    const verifyMatchIds = ({ matchIds }) => {
      for (const matchId of matchIds != null ? matchIds : []) {
        if (!this.values.some(
          (def) => {
            var _a;
            return (_a = def.ids) == null ? void 0 : _a.some(([scope, id]) => scope === matchId[0] && id === matchId[1]);
          }
        )) {
          throw new Error(
            `AG Charts - internal config error: matchGroupIds properties must match defined groups (${matchId}).`
          );
        }
      }
    };
    for (const def of [...this.groupProcessors, ...this.aggregates]) {
      verifyMatchIds(def);
      verifyMatchGroupId(def);
    }
  }
  resolveProcessedDataIndexById(scope, searchId) {
    var _a;
    const { index, def } = (_a = this.resolveProcessedDataDefById(scope, searchId)) != null ? _a : {};
    return { index, def };
  }
  resolveProcessedDataIndicesById(scope, searchId) {
    return this.resolveProcessedDataDefsById(scope, searchId).map(({ index, def }) => ({ index, def }));
  }
  resolveProcessedDataDefById(scope, searchId) {
    return this.resolveProcessedDataDefsById(scope, searchId)[0];
  }
  resolveProcessedDataDefsByIds(scope, searchIds) {
    const defs = [];
    for (const searchId of searchIds) {
      defs.push([searchId, this.resolveProcessedDataDefsById(scope, searchId)]);
    }
    return defs;
  }
  resolveProcessedDataDefsValues(defs, { keys, values }) {
    const result = {};
    for (const [searchId, [{ index, def }]] of defs) {
      const processedData = def.type === "key" ? keys : values;
      result[searchId] = processedData[index];
    }
    return result;
  }
  resolveProcessedDataDefsById(searchScope, searchId) {
    const { keys, values, aggregates, groupProcessors, reducers } = this;
    const match = (prop) => {
      const { ids, scopes } = prop;
      if (ids == null)
        return false;
      if (searchScope != null && !(scopes == null ? void 0 : scopes.some((scope) => scope === searchScope.id)))
        return false;
      return ids.some(
        ([scope, id]) => scope === searchScope.id && (typeof searchId === "string" ? id === searchId : searchId.test(id))
      );
    };
    const allDefs = [
      keys,
      values,
      aggregates,
      groupProcessors,
      reducers
    ];
    const result = [];
    for (const defs of allDefs) {
      result.push(...defs.filter(match).map((def) => ({ index: def.index, def })));
    }
    if (result.length > 0) {
      return result;
    }
    throw new Error(`AG Charts - didn't find property definition for [${searchId}, ${searchScope.id}]`);
  }
  getDomain(scope, searchId, type = "value", processedData) {
    var _a, _b, _c, _d;
    let matches;
    try {
      matches = this.resolveProcessedDataIndicesById(scope, searchId);
    } catch (e) {
      if (typeof searchId !== "string" && /didn't find property definition/.test(e.message))
        return [];
      throw e;
    }
    let domainProp;
    switch (type) {
      case "key":
        domainProp = "keys";
        break;
      case "value":
        domainProp = "values";
        break;
      case "aggregate":
        domainProp = "aggValues";
        break;
      case "group-value-processor":
        domainProp = "groups";
        break;
      default:
        return [];
    }
    const firstMatch = (_b = (_a = processedData.domain[domainProp]) == null ? void 0 : _a[matches[0].index]) != null ? _b : [];
    if (matches.length === 1) {
      return firstMatch;
    }
    const result = [...firstMatch];
    for (const idx of matches.slice(1)) {
      extendDomain$1((_d = (_c = processedData.domain[domainProp]) == null ? void 0 : _c[idx.index]) != null ? _d : [], result);
    }
    return result;
  }
  processData(data, sources) {
    const {
      opts: { groupByKeys, groupByFn },
      aggregates,
      groupProcessors,
      reducers,
      processors,
      propertyProcessors
    } = this;
    const start = performance.now();
    for (const def of [...this.keys, ...this.values]) {
      def.missing = 0;
    }
    if (groupByKeys && this.keys.length === 0) {
      return void 0;
    }
    let processedData = this.extractData(data, sources);
    if (groupByKeys) {
      processedData = this.groupData(processedData);
    } else if (groupByFn) {
      processedData = this.groupData(processedData, groupByFn(processedData));
    }
    if (groupProcessors.length > 0) {
      this.postProcessGroups(processedData);
    }
    if (aggregates.length > 0) {
      this.aggregateData(processedData);
    }
    if (propertyProcessors.length > 0) {
      this.postProcessProperties(processedData);
    }
    if (reducers.length > 0) {
      this.reduceData(processedData);
    }
    if (processors.length > 0) {
      this.postProcessData(processedData);
    }
    for (const def of [...this.keys, ...this.values]) {
      if (data.length > 0 && def.missing >= data.length) {
        Logger$1.warnOnce(`the key '${def.property}' was not found in any data element.`);
      }
    }
    const end = performance.now();
    processedData.time = end - start;
    if (Debug$1.check(true, "data-model")) {
      logProcessedData$1(processedData);
    }
    return processedData;
  }
  valueGroupIdxLookup({ matchGroupIds, matchIds }) {
    return this.values.map((def, index) => ({ def, index })).filter(({ def }) => {
      if (matchGroupIds && (def.groupId == null || !matchGroupIds.includes(def.groupId))) {
        return false;
      }
      if (!matchIds)
        return true;
      if (def.ids == null)
        return false;
      return matchIds.some(
        ([matchScope, matchId]) => {
          var _a;
          return (_a = def.ids) == null ? void 0 : _a.some(([defScope, defId]) => defScope === matchScope && defId === matchId);
        }
      );
    }).map(({ index }) => index);
  }
  valueIdxLookup(scopes, prop) {
    const noScopesToMatch = scopes == null || scopes.length === 0;
    const scopeMatch = (compareTo) => {
      const anyScope = compareTo == null;
      if (anyScope)
        return true;
      const noScopes = compareTo == null || compareTo.length === 0;
      if (noScopesToMatch === noScopes)
        return true;
      return compareTo == null ? void 0 : compareTo.some((s) => scopes.includes(s));
    };
    const propId = typeof prop === "string" ? prop : prop.id;
    const idMatch = ([scope, id]) => {
      return scopeMatch([scope]) && id === propId;
    };
    const result = this.values.findIndex((def) => {
      var _a;
      return scopeMatch(def.scopes) && (((_a = def.ids) == null ? void 0 : _a.some((id) => idMatch(id))) || def.property === propId || def.id === propId);
    });
    if (result >= 0) {
      return result;
    }
    throw new Error(
      `AG Charts - configuration error, unknown property ${JSON.stringify(prop)} in scope(s) ${JSON.stringify(
        scopes
      )}`
    );
  }
  extractData(data, sources) {
    var _a, _b, _c, _d, _e, _f;
    const {
      keys: keyDefs,
      values: valueDefs,
      opts: { dataVisible }
    } = this;
    const { dataDomain, processValue, scopes, allScopesHaveSameDefs } = this.initDataDomainProcessor();
    const resultData = new Array(dataVisible ? data.length : 0);
    let resultDataIdx = 0;
    let partialValidDataCount = 0;
    for (const [datumIdx, datum] of data.entries()) {
      const sourceDatums = {};
      const validScopes = scopes.size > 0 ? new Set(scopes) : void 0;
      const keys = dataVisible ? new Array(keyDefs.length) : void 0;
      let keyIdx = 0;
      let key;
      for (const def of keyDefs) {
        key = processValue(def, datum, key);
        if (key === INVALID_VALUE$1)
          break;
        if (keys) {
          keys[keyIdx++] = key;
        }
      }
      if (key === INVALID_VALUE$1)
        continue;
      const values = dataVisible && valueDefs.length > 0 ? new Array(valueDefs.length) : void 0;
      let value;
      const sourcesById = {};
      for (const source of sources != null ? sources : []) {
        sourcesById[source.id] = source;
      }
      for (const [valueDefIdx, def] of valueDefs.entries()) {
        for (const scope of (_a = def.scopes) != null ? _a : scopes) {
          const source = sourcesById[scope];
          const valueDatum = (_b = source == null ? void 0 : source.data[datumIdx]) != null ? _b : datum;
          value = processValue(def, valueDatum, value);
          if (value === INVALID_VALUE$1 || !values)
            continue;
          if (source !== void 0) {
            (_d = sourceDatums[_c = source.id]) != null ? _d : sourceDatums[_c] = {};
            sourceDatums[source.id][def.property] = value;
          }
          if (def.useScopedValues) {
            (_e = values[valueDefIdx]) != null ? _e : values[valueDefIdx] = {};
            values[valueDefIdx][scope] = value;
          } else {
            values[valueDefIdx] = value;
          }
        }
        if (value === INVALID_VALUE$1) {
          if (allScopesHaveSameDefs)
            break;
          for (const scope of (_f = def.scopes) != null ? _f : scopes) {
            validScopes == null ? void 0 : validScopes.delete(scope);
          }
          if ((validScopes == null ? void 0 : validScopes.size) === 0)
            break;
        }
      }
      if (value === INVALID_VALUE$1 && allScopesHaveSameDefs)
        continue;
      if ((validScopes == null ? void 0 : validScopes.size) === 0)
        continue;
      if (dataVisible) {
        const result = {
          datum: __spreadValues$1(__spreadValues$1({}, datum), sourceDatums),
          keys,
          values
        };
        if (!allScopesHaveSameDefs && validScopes && validScopes.size < scopes.size) {
          partialValidDataCount++;
          result.validScopes = [...validScopes];
        }
        resultData[resultDataIdx++] = result;
      }
    }
    resultData.length = resultDataIdx;
    const propertyDomain = (def) => {
      const result = dataDomain.get(def).getDomain();
      if (Array.isArray(result) && result[0] > result[1]) {
        return [];
      }
      return [...result];
    };
    return {
      type: "ungrouped",
      input: { count: data.length },
      data: resultData,
      domain: {
        keys: keyDefs.map((def) => propertyDomain(def)),
        values: valueDefs.map((def) => propertyDomain(def))
      },
      defs: {
        allScopesHaveSameDefs,
        keys: keyDefs,
        values: valueDefs
      },
      partialValidDataCount,
      time: 0
    };
  }
  groupData(data, groupingFn) {
    var _a, _b, _c, _d;
    const processedData = /* @__PURE__ */ new Map();
    for (const dataEntry of data.data) {
      const { keys, values, datum, validScopes } = dataEntry;
      const group2 = groupingFn ? groupingFn(dataEntry) : keys;
      const groupStr = toKeyString$1(group2);
      if (processedData.has(groupStr)) {
        const existingData = processedData.get(groupStr);
        existingData.values.push(values);
        existingData.datum.push(datum);
        if (validScopes != null) {
          for (let index = 0; index < ((_b = (_a = existingData.validScopes) == null ? void 0 : _a.length) != null ? _b : 0); index++) {
            const scope = (_c = existingData.validScopes) == null ? void 0 : _c[index];
            if (validScopes.some((s) => s === scope))
              continue;
            (_d = existingData.validScopes) == null ? void 0 : _d.splice(index, 1);
          }
        }
      } else {
        processedData.set(groupStr, {
          keys: group2,
          values: [values],
          datum: [datum],
          validScopes
        });
      }
    }
    const resultData = new Array(processedData.size);
    const resultGroups = new Array(processedData.size);
    let dataIndex = 0;
    for (const [, { keys, values, datum, validScopes }] of processedData.entries()) {
      if ((validScopes == null ? void 0 : validScopes.length) === 0)
        continue;
      resultGroups[dataIndex] = keys;
      resultData[dataIndex++] = {
        keys,
        values,
        datum,
        validScopes
      };
    }
    return __spreadProps$1(__spreadValues$1({}, data), {
      type: "grouped",
      data: resultData,
      domain: __spreadProps$1(__spreadValues$1({}, data.domain), {
        groups: resultGroups
      })
    });
  }
  aggregateData(processedData) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { aggregates: aggDefs } = this;
    if (!aggDefs)
      return;
    const resultAggValues = aggDefs.map(() => [Infinity, -Infinity]);
    const resultAggValueIndices = aggDefs.map((def) => this.valueGroupIdxLookup(def));
    const resultAggFns = aggDefs.map((def) => def.aggregateFunction);
    const resultGroupAggFns = aggDefs.map((def) => def.groupAggregateFunction);
    const resultFinalFns = aggDefs.map((def) => def.finalFunction);
    for (const group2 of processedData.data) {
      let { values } = group2;
      const { validScopes } = group2;
      (_a = group2.aggValues) != null ? _a : group2.aggValues = new Array(resultAggValueIndices.length);
      if (processedData.type === "ungrouped") {
        values = [values];
      }
      let resultIdx = 0;
      for (const indices of resultAggValueIndices) {
        const scopeValid = (_b = validScopes == null ? void 0 : validScopes.some((s) => {
          var _a2;
          return (_a2 = aggDefs[resultIdx].matchScopes) == null ? void 0 : _a2.some((as) => s === as);
        })) != null ? _b : true;
        if (!scopeValid) {
          resultIdx++;
          continue;
        }
        let groupAggValues = (_d = (_c = resultGroupAggFns[resultIdx]) == null ? void 0 : _c.call(resultGroupAggFns)) != null ? _d : extendDomain$1([]);
        for (const distinctValues of values) {
          const valuesToAgg = indices.map((valueIdx) => distinctValues[valueIdx]);
          const valuesAgg = resultAggFns[resultIdx](valuesToAgg, group2.keys);
          if (valuesAgg) {
            groupAggValues = (_f = (_e = resultGroupAggFns[resultIdx]) == null ? void 0 : _e.call(resultGroupAggFns, valuesAgg, groupAggValues)) != null ? _f : extendDomain$1(valuesAgg, groupAggValues);
          }
        }
        const finalValues = ((_h = (_g = resultFinalFns[resultIdx]) == null ? void 0 : _g.call(resultFinalFns, groupAggValues)) != null ? _h : groupAggValues).map(
          (v) => round2$1(v)
        );
        extendDomain$1(finalValues, resultAggValues[resultIdx]);
        group2.aggValues[resultIdx++] = finalValues;
      }
    }
    processedData.domain.aggValues = resultAggValues;
  }
  postProcessGroups(processedData) {
    var _a, _b, _c, _d, _e;
    const { groupProcessors } = this;
    if (!groupProcessors)
      return;
    const affectedIndices = /* @__PURE__ */ new Set();
    const updatedDomains = /* @__PURE__ */ new Map();
    const groupProcessorIndices = /* @__PURE__ */ new Map();
    const groupProcessorInitFns = /* @__PURE__ */ new Map();
    for (const processor of groupProcessors) {
      const indices = this.valueGroupIdxLookup(processor);
      groupProcessorIndices.set(processor, indices);
      groupProcessorInitFns.set(processor, processor.adjust());
      for (const idx of indices) {
        const valueDef = this.values[idx];
        affectedIndices.add(idx);
        updatedDomains.set(idx, new DataDomain$1(valueDef.valueType === "category" ? "discrete" : "continuous"));
      }
    }
    const updateDomains = (values) => {
      var _a2;
      for (const valueIndex of affectedIndices) {
        (_a2 = updatedDomains.get(valueIndex)) == null ? void 0 : _a2.extend(values[valueIndex]);
      }
    };
    for (const group2 of processedData.data) {
      for (const processor of groupProcessors) {
        const scopeValid = (_b = (_a = group2.validScopes) == null ? void 0 : _a.some((s) => {
          var _a2;
          return (_a2 = processor.matchScopes) == null ? void 0 : _a2.some((as) => s === as);
        })) != null ? _b : true;
        if (!scopeValid) {
          continue;
        }
        const valueIndexes = (_c = groupProcessorIndices.get(processor)) != null ? _c : [];
        const adjustFn = (_e = (_d = groupProcessorInitFns.get(processor)) == null ? void 0 : _d()) != null ? _e : () => void 0;
        if (processedData.type === "grouped") {
          for (const values of group2.values) {
            if (values) {
              adjustFn(values, valueIndexes);
            }
          }
          continue;
        }
        if (group2.values) {
          adjustFn(group2.values, valueIndexes);
        }
      }
      if (processedData.type === "grouped") {
        for (const values of group2.values) {
          updateDomains(values);
        }
      } else {
        updateDomains(group2.values);
      }
    }
    for (const [idx, dataDomain] of updatedDomains) {
      processedData.domain.values[idx] = [...dataDomain.getDomain()];
    }
  }
  postProcessProperties(processedData) {
    const { propertyProcessors } = this;
    if (!propertyProcessors)
      return;
    for (const { adjust, property, scopes } of propertyProcessors) {
      adjust()(processedData, this.valueIdxLookup(scopes != null ? scopes : [], property));
    }
  }
  reduceData(processedData) {
    var _a, _b, _c;
    const { reducers: reducerDefs } = this;
    const scopes = reducerDefs.map((def) => def.scopes);
    const reducers = reducerDefs.map((def) => def.reducer());
    const accValues = reducerDefs.map((def) => def.initialValue);
    for (const group2 of processedData.data) {
      let reducerIndex = 0;
      for (const reducer of reducers) {
        const scopeValid = (_b = (_a = group2.validScopes) == null ? void 0 : _a.some((s) => {
          var _a2;
          return (_a2 = scopes[reducerIndex]) == null ? void 0 : _a2.some((as) => s === as);
        })) != null ? _b : true;
        if (!scopeValid) {
          reducerIndex++;
          continue;
        }
        accValues[reducerIndex] = reducer(accValues[reducerIndex], group2);
        reducerIndex++;
      }
    }
    for (let accIdx = 0; accIdx < accValues.length; accIdx++) {
      (_c = processedData.reduced) != null ? _c : processedData.reduced = {};
      processedData.reduced[reducerDefs[accIdx].property] = accValues[accIdx];
    }
  }
  postProcessData(processedData) {
    var _a;
    const { processors: processorDefs } = this;
    for (const def of processorDefs) {
      (_a = processedData.reduced) != null ? _a : processedData.reduced = {};
      processedData.reduced[def.property] = def.calculate(processedData);
    }
  }
  initDataDomainProcessor() {
    var _a;
    const { keys: keyDefs, values: valueDefs } = this;
    const scopes = /* @__PURE__ */ new Set();
    for (const valueDef of valueDefs) {
      for (const scope of (_a = valueDef.scopes) != null ? _a : []) {
        scopes.add(scope);
      }
    }
    const scopesCount = scopes.size;
    const dataDomain = /* @__PURE__ */ new Map();
    const processorFns = /* @__PURE__ */ new Map();
    let allScopesHaveSameDefs = true;
    const initDataDomainKey = (key, type, updateDataDomain = dataDomain) => {
      var _a2;
      if (type === "category") {
        updateDataDomain.set(key, new DataDomain$1("discrete"));
      } else {
        updateDataDomain.set(key, new DataDomain$1("continuous"));
        allScopesHaveSameDefs && (allScopesHaveSameDefs = ((_a2 = key.scopes) != null ? _a2 : []).length === scopesCount);
      }
    };
    const initDataDomain = () => {
      keyDefs.forEach((def) => initDataDomainKey(def, def.valueType));
      valueDefs.forEach((def) => initDataDomainKey(def, def.valueType));
    };
    initDataDomain();
    const accessors = this.buildAccessors(...keyDefs, ...valueDefs);
    const processValue = (def, datum, previousDatum) => {
      var _a2, _b, _c, _d;
      const hasAccessor = def.property in accessors;
      let valueInDatum = false;
      let value;
      if (hasAccessor) {
        try {
          value = accessors[def.property](datum);
        } catch (error) {
        }
        valueInDatum = value !== void 0;
      } else {
        valueInDatum = def.property in datum;
        value = valueInDatum ? datum[def.property] : def.missingValue;
      }
      if (def.forceValue != null) {
        const valueNegative = valueInDatum && isNegative$1(value);
        value = valueNegative ? -1 * def.forceValue : def.forceValue;
        valueInDatum = true;
      }
      const missingValueDef = "missingValue" in def;
      if (!valueInDatum && !missingValueDef) {
        def.missing++;
      }
      if (!dataDomain.has(def)) {
        initDataDomain();
      }
      if (valueInDatum) {
        const valid = (_b = (_a2 = def.validation) == null ? void 0 : _a2.call(def, value, datum)) != null ? _b : true;
        if (!valid) {
          if ("invalidValue" in def) {
            value = def.invalidValue;
          } else {
            if (this.mode !== "integrated") {
              Logger$1.warnOnce(`invalid value of type [${typeof value}] ignored:`, `[${value}]`);
            }
            return INVALID_VALUE$1;
          }
        }
      }
      if (def.processor) {
        if (!processorFns.has(def)) {
          processorFns.set(def, def.processor());
        }
        value = (_c = processorFns.get(def)) == null ? void 0 : _c(value, previousDatum !== INVALID_VALUE$1 ? previousDatum : void 0);
      }
      (_d = dataDomain.get(def)) == null ? void 0 : _d.extend(value);
      return value;
    };
    return { dataDomain, processValue, initDataDomain, scopes, allScopesHaveSameDefs };
  }
  buildAccessors(...defs) {
    const result = {};
    if (this.mode === "integrated")
      return result;
    for (const def of defs) {
      const isPath = def.property.indexOf(".") >= 0 || def.property.indexOf("[") >= 0;
      if (!isPath)
        continue;
      let fnBody;
      if (def.property.startsWith("[")) {
        fnBody = `return datum${def.property};`;
      } else {
        fnBody = `return datum.${def.property};`;
      }
      result[def.property] = new Function("datum", fnBody);
    }
    return result;
  }
};
function logProcessedData$1(processedData) {
  var _a, _b;
  const logValues = (name, data) => {
    if (data.length > 0) {
      Logger$1.log(`DataModel.processData() - ${name}`);
      Logger$1.table(data);
    }
  };
  Logger$1.log("DataModel.processData() - processedData", processedData);
  logValues("Key Domains", processedData.domain.keys);
  logValues("Group Domains", (_a = processedData.domain.groups) != null ? _a : []);
  logValues("Value Domains", processedData.domain.values);
  logValues("Aggregate Domains", (_b = processedData.domain.aggValues) != null ? _b : []);
  if (processedData.type === "grouped") {
    const flattenedValues = processedData.data.reduce((acc, next) => {
      var _a2, _b2;
      const keys = (_a2 = next.keys) != null ? _a2 : [];
      const aggValues = (_b2 = next.aggValues) != null ? _b2 : [];
      const skipKeys = next.keys.map(() => void 0);
      const skipAggValues = aggValues == null ? void 0 : aggValues.map(() => void 0);
      acc.push(
        ...next.values.map((v, i) => [
          ...i === 0 ? keys : skipKeys,
          ...v != null ? v : [],
          ...i == 0 ? aggValues : skipAggValues
        ])
      );
      return acc;
    }, []);
    logValues("Values", flattenedValues);
  } else {
    const flattenedValues = processedData.data.reduce((acc, next) => {
      var _a2;
      const aggValues = (_a2 = next.aggValues) != null ? _a2 : [];
      acc.push([...next.keys, ...next.values, ...aggValues]);
      return acc;
    }, []);
    logValues("Values", flattenedValues);
  }
}

// packages/ag-charts-community/src/chart/data/dataController.ts
var DataController$1 = class {
  constructor(mode) {
    this.mode = mode;
    this.debug = Debug$1.create(true, "data-model");
    this.requested = [];
    this.status = "setup";
  }
  request(id, data, opts) {
    return __async$1(this, null, function* () {
      if (this.status !== "setup")
        throw new Error(`AG Charts - data request after data setup phase.`);
      return new Promise((resolve, reject) => {
        this.requested.push({
          id,
          opts,
          data,
          resultCb: resolve,
          reject
        });
      });
    });
  }
  execute() {
    return __async$1(this, null, function* () {
      if (this.status !== "setup")
        throw new Error(`AG Charts - data request after data setup phase.`);
      this.status = "executed";
      this.debug("DataController.execute() - requested", this.requested);
      const { valid, invalid } = this.validateRequests(this.requested);
      this.debug("DataController.execute() - validated", valid);
      const merged = this.mergeRequested(valid);
      this.debug("DataController.execute() - merged", merged);
      const debugMode = Debug$1.check(true, "data-model");
      if (debugMode) {
        window.processedData = [];
      }
      const multipleSources = valid.some((v) => v.data != null);
      for (const { opts, data, resultCbs, rejects, ids } of merged) {
        const needsValueExtraction = multipleSources || opts.props.some((p) => {
          var _a;
          if (p.type !== "value" && p.type !== "key")
            return false;
          return (_a = p.useScopedValues) != null ? _a : false;
        });
        try {
          const dataModel = new DataModel$1(__spreadProps$1(__spreadValues$1({}, opts), { mode: this.mode }));
          const processedData = dataModel.processData(data, valid);
          if (debugMode) {
            window.processedData.push(processedData);
          }
          if (processedData && processedData.partialValidDataCount === 0) {
            resultCbs.forEach((cb, requestIdx) => {
              const id = ids[requestIdx];
              let requestProcessedData = processedData;
              if (needsValueExtraction) {
                requestProcessedData = this.extractScopedData(id, processedData);
              }
              cb({ dataModel, processedData: requestProcessedData });
            });
          } else if (processedData) {
            this.splitResult(dataModel, processedData, ids, resultCbs);
          } else {
            rejects.forEach((cb) => cb(new Error(`AG Charts - no processed data generated`)));
          }
        } catch (error) {
          rejects.forEach((cb) => cb(error));
        }
      }
      invalid.forEach(({ error, reject }) => reject(error));
    });
  }
  extractScopedData(id, processedData) {
    const extractDatum = (datum) => {
      if (Array.isArray(datum)) {
        return datum.map(extractDatum);
      }
      return __spreadValues$1(__spreadValues$1({}, datum), datum[id]);
    };
    const extractValues = (values) => {
      var _a;
      if (Array.isArray(values)) {
        return values.map(extractValues);
      }
      return (_a = values == null ? void 0 : values[id]) != null ? _a : values;
    };
    return __spreadProps$1(__spreadValues$1({}, processedData), {
      data: processedData.data.map((datum) => __spreadProps$1(__spreadValues$1({}, datum), {
        datum: extractDatum(datum.datum),
        values: datum.values.map(extractValues)
      }))
    });
  }
  validateRequests(requested) {
    const valid = [];
    const invalid = [];
    for (const [index, request] of requested.entries()) {
      if (index > 0 && request.data.length !== requested[0].data.length && request.opts.groupByData === false) {
        invalid.push(__spreadProps$1(__spreadValues$1({}, request), {
          error: new Error("all series[].data arrays must be of the same length and have matching keys.")
        }));
      } else {
        valid.push(request);
      }
    }
    return { valid, invalid };
  }
  mergeRequested(requested) {
    const grouped = [];
    const keys = (props) => {
      return props.filter((p) => p.type === "key").map((p) => p.property).join(";");
    };
    const groupMatch = ({ opts, data }) => (gr) => {
      return (opts.groupByData === false || gr[0].data === data) && gr[0].opts.groupByKeys === opts.groupByKeys && gr[0].opts.dataVisible === opts.dataVisible && gr[0].opts.groupByFn === opts.groupByFn && keys(gr[0].opts.props) === keys(opts.props);
    };
    const propMatch = (prop) => (existing) => {
      var _a;
      if (existing.type !== prop.type)
        return false;
      const diff2 = (_a = jsonDiff$1(existing, prop)) != null ? _a : {};
      delete diff2["scopes"];
      delete diff2["id"];
      delete diff2["ids"];
      if ("useScopedValues" in diff2) {
        delete diff2["useScopedValues"];
      }
      return Object.keys(diff2).length === 0;
    };
    const updateKeyValueOpts = (prop) => {
      var _a;
      if (prop.type !== "key" && prop.type !== "value")
        return;
      const uniqueScopes = new Set((_a = prop.scopes) != null ? _a : []);
      prop.useScopedValues = uniqueScopes.size > 1;
    };
    const mergeOpts = (opts) => {
      return __spreadProps$1(__spreadValues$1({}, opts[0]), {
        props: opts.reduce((result, next) => {
          var _a, _b, _c, _d, _e, _f;
          for (const prop of next.props) {
            if (prop.id != null) {
              (_a = prop.ids) != null ? _a : prop.ids = [];
              (_b = prop.scopes) == null ? void 0 : _b.forEach((scope) => {
                var _a2;
                return (_a2 = prop.ids) == null ? void 0 : _a2.push([scope, prop.id]);
              });
            }
            const match = result.find(propMatch(prop));
            if (!match) {
              updateKeyValueOpts(prop);
              result.push(prop);
              continue;
            }
            (_c = match.scopes) != null ? _c : match.scopes = [];
            match.scopes.push(...(_d = prop.scopes) != null ? _d : []);
            updateKeyValueOpts(prop);
            if (match.type !== "key" && match.type !== "value")
              continue;
            (_f = match.ids) == null ? void 0 : _f.push(...(_e = prop.ids) != null ? _e : []);
          }
          return result;
        }, [])
      });
    };
    const merge = (props) => {
      return {
        ids: props.map(({ id }) => id),
        resultCbs: props.map(({ resultCb }) => resultCb),
        rejects: props.map(({ reject }) => reject),
        data: props[0].data,
        opts: mergeOpts(props.map(({ opts }) => opts))
      };
    };
    for (const request of requested) {
      const match = grouped.find(groupMatch(request));
      if (match) {
        match.push(request);
      } else {
        grouped.push([request]);
      }
    }
    return grouped.map(merge);
  }
  splitResult(dataModel, processedData, scopes, resultCbs) {
    for (let index = 0; index < scopes.length; index++) {
      const scope = scopes[index];
      const resultCb = resultCbs[index];
      resultCb({
        dataModel,
        processedData: __spreadProps$1(__spreadValues$1({}, processedData), {
          data: processedData.data.filter(({ validScopes }) => {
            return validScopes == null || validScopes.some((s) => s === scope);
          })
        })
      });
    }
  }
};

// packages/ag-charts-community/src/util/listeners.ts
var Listeners$1 = class {
  constructor() {
    this.registeredListeners = /* @__PURE__ */ new Map();
  }
  addListener(eventType, handler, meta) {
    const record = { symbol: Symbol(eventType), handler, meta };
    if (this.registeredListeners.has(eventType)) {
      this.registeredListeners.get(eventType).push(record);
    } else {
      this.registeredListeners.set(eventType, [record]);
    }
    return () => this.removeListener(record.symbol);
  }
  removeListener(eventSymbol) {
    for (const [type, listeners] of this.registeredListeners.entries()) {
      const matchIndex = listeners.findIndex((listener) => listener.symbol === eventSymbol);
      if (matchIndex >= 0) {
        listeners.splice(matchIndex, 1);
        if (listeners.length === 0) {
          this.registeredListeners.delete(type);
        }
        break;
      }
    }
  }
  dispatch(eventType, ...params) {
    for (const listener of this.getListenersByType(eventType)) {
      try {
        listener.handler(...params);
      } catch (e) {
        Logger$1.errorOnce(e);
      }
    }
  }
  dispatchWrapHandlers(eventType, wrapFn, ...params) {
    for (const listener of this.getListenersByType(eventType)) {
      try {
        wrapFn(listener.handler, listener.meta, ...params);
      } catch (e) {
        Logger$1.errorOnce(e);
      }
    }
  }
  getListenersByType(eventType) {
    var _a;
    return (_a = this.registeredListeners.get(eventType)) != null ? _a : [];
  }
};

// packages/ag-charts-community/src/chart/interaction/baseManager.ts
var BaseManager$1 = class {
  constructor() {
    this.listeners = new Listeners$1();
  }
  addListener(type, handler, meta) {
    return this.listeners.addListener(type, handler, meta);
  }
  removeListener(listenerSymbol) {
    this.listeners.removeListener(listenerSymbol);
  }
};

// packages/ag-charts-community/src/chart/interaction/animationManager.ts
var DEBUG_SELECTORS$1 = [true, "animation"];
var AnimationManager$1 = class extends BaseManager$1 {
  constructor(interactionManager, chartUpdateMutex) {
    super();
    this.interactionManager = interactionManager;
    this.chartUpdateMutex = chartUpdateMutex;
    this.defaultDuration = 1e3;
    this.batch = new AnimationBatch$1();
    this.debug = Debug$1.create(...DEBUG_SELECTORS$1);
    this.isPlaying = false;
    this.requestId = null;
    this.skipAnimations = false;
  }
  /**
   * Create an animation to tween a value between the `from` and `to` properties. If an animation already exists
   * with the same `id`, immediately stop it.
   */
  animate(_a) {
    var _b = _a, {
      disableInteractions = true,
      immutable = true
    } = _b, opts = __objRest$1(_b, [
      "disableInteractions",
      "immutable"
    ]);
    var _a2, _b2;
    const { batch } = this;
    try {
      if (opts.id != null && batch.controllers.has(opts.id)) {
        if (!immutable) {
          return batch.controllers.get(opts.id).reset(opts);
        }
        batch.controllers.get(opts.id).stop();
        this.debug(`Skipping animation batch due to update of existing animation: ${opts.id}`);
        this.batch.skip();
      }
    } catch (error) {
      this.failsafeOnError(error);
      return;
    }
    const id = (_a2 = opts.id) != null ? _a2 : Math.random().toString();
    const skip = this.isSkipped();
    if (skip) {
      this.debug("AnimationManager - skipping animation");
    }
    return new Animation$1(__spreadProps$1(__spreadValues$1({}, opts), {
      id,
      skip,
      autoplay: this.isPlaying ? opts.autoplay : false,
      duration: (_b2 = opts.duration) != null ? _b2 : this.defaultDuration,
      onPlay: (controller) => {
        var _a3;
        batch.controllers.set(id, controller);
        this.requestAnimation();
        if (disableInteractions) {
          this.interactionManager.pause("animation");
        }
        (_a3 = opts.onPlay) == null ? void 0 : _a3.call(controller, controller);
      },
      onStop: (controller) => {
        var _a3;
        batch.controllers.delete(id);
        if (disableInteractions) {
          this.interactionManager.resume("animation");
        }
        (_a3 = opts.onStop) == null ? void 0 : _a3.call(controller, controller);
      }
    }));
  }
  play() {
    if (this.isPlaying) {
      return;
    }
    this.isPlaying = true;
    this.debug("AnimationManager.play()");
    for (const controller of this.batch.controllers.values()) {
      try {
        controller.play();
      } catch (error) {
        this.failsafeOnError(error);
      }
    }
    this.requestAnimation();
  }
  pause() {
    if (!this.isPlaying) {
      return;
    }
    this.isPlaying = false;
    this.cancelAnimation();
    this.debug("AnimationManager.pause()");
    for (const controller of this.batch.controllers.values()) {
      try {
        controller.pause();
      } catch (error) {
        this.failsafeOnError(error);
      }
    }
  }
  stop() {
    this.isPlaying = false;
    this.cancelAnimation();
    this.debug("AnimationManager.stop()");
    for (const controller of this.batch.controllers.values()) {
      try {
        controller.stop();
      } catch (error) {
        this.failsafeOnError(error, false);
      }
    }
  }
  stopByAnimationId(id) {
    var _a;
    try {
      if (id != null && this.batch.controllers.has(id)) {
        (_a = this.batch.controllers.get(id)) == null ? void 0 : _a.stop();
      }
    } catch (error) {
      this.failsafeOnError(error);
      return;
    }
  }
  stopByAnimationGroupId(id) {
    for (const controller of this.batch.controllers.values()) {
      if (controller.groupId === id) {
        this.stopByAnimationId(controller.id);
      }
    }
  }
  reset() {
    if (this.isPlaying) {
      this.stop();
      this.play();
    } else {
      this.stop();
    }
  }
  skip(skip = true) {
    this.skipAnimations = skip;
  }
  isSkipped() {
    return this.skipAnimations || this.batch.isSkipped();
  }
  isActive() {
    return this.isPlaying && this.batch.isActive();
  }
  skipCurrentBatch() {
    if (Debug$1.check(...DEBUG_SELECTORS$1)) {
      this.debug(`AnimationManager - skipCurrentBatch()`, { stack: new Error().stack });
    }
    this.batch.skip();
  }
  /** Mocking point for tests to guarantee that animation updates happen. */
  isSkippingFrames() {
    return true;
  }
  /** Mocking point for tests to capture requestAnimationFrame callbacks. */
  scheduleAnimationFrame(cb) {
    this.requestId = requestAnimationFrame(cb);
  }
  requestAnimation() {
    if (!this.batch.isActive() || this.requestId !== null)
      return;
    let prevTime;
    const onAnimationFrame = (time) => __async$1(this, null, function* () {
      const executeAnimationFrame = () => __async$1(this, null, function* () {
        const deltaTime = time - (prevTime != null ? prevTime : time);
        prevTime = time;
        this.debug("AnimationManager - onAnimationFrame()", {
          controllersCount: this.batch.controllers.size
        });
        for (const controller of this.batch.controllers.values()) {
          try {
            controller.update(deltaTime);
          } catch (error) {
            this.failsafeOnError(error);
          }
        }
        this.listeners.dispatch("animation-frame", {
          type: "animation-frame",
          deltaMs: deltaTime
        });
      });
      if (this.isSkippingFrames()) {
        yield this.chartUpdateMutex.acquireImmediately(executeAnimationFrame);
      } else {
        yield this.chartUpdateMutex.acquire(executeAnimationFrame);
      }
      if (this.batch.isActive()) {
        this.scheduleAnimationFrame(onAnimationFrame);
      }
    });
    this.scheduleAnimationFrame(onAnimationFrame);
  }
  cancelAnimation() {
    if (this.requestId === null)
      return;
    cancelAnimationFrame(this.requestId);
    this.requestId = null;
    this.startBatch();
  }
  failsafeOnError(error, cancelAnimation = true) {
    Logger$1.error("Error during animation, skipping animations", error);
    if (cancelAnimation) {
      this.cancelAnimation();
    }
  }
  startBatch(skipAnimations) {
    this.debug(`AnimationManager - startBatch() with skipAnimations=${skipAnimations}.`);
    this.reset();
    this.batch.destroy();
    this.batch = new AnimationBatch$1();
    if (skipAnimations === true) {
      this.batch.skip();
    }
  }
  endBatch() {
    this.debug(
      `AnimationManager - endBatch() with ${this.batch.controllers.size} animations; skipped: ${this.batch.isSkipped()}.`
    );
    if (this.batch.isSkipped() && !this.batch.isActive()) {
      this.batch.skip(false);
    }
  }
};
var AnimationBatch$1 = class {
  constructor() {
    this.controllers = /* @__PURE__ */ new Map();
    this.skipAnimations = false;
  }
  // private phase?: 'initial-load' | 'remove' | 'update' | 'add';
  isActive() {
    return this.controllers.size > 0;
  }
  skip(skip = true) {
    if (this.skipAnimations === false && skip === true) {
      for (const controller of this.controllers.values()) {
        controller.stop();
      }
      this.controllers.clear();
    }
    this.skipAnimations = skip;
  }
  isSkipped() {
    return this.skipAnimations;
  }
  destroy() {
  }
};

// packages/ag-charts-community/src/chart/interaction/chartEventManager.ts
var ChartEventManager$1 = class extends BaseManager$1 {
  legendItemClick(series, itemId, enabled, legendItemName) {
    const event = {
      type: "legend-item-click",
      series,
      itemId,
      enabled,
      legendItemName
    };
    this.listeners.dispatch("legend-item-click", event);
  }
  legendItemDoubleClick(series, itemId, enabled, numVisibleItems, legendItemName) {
    const event = {
      type: "legend-item-double-click",
      series,
      itemId,
      enabled,
      legendItemName,
      numVisibleItems
    };
    this.listeners.dispatch("legend-item-double-click", event);
  }
  axisHover(axisId, direction) {
    const event = {
      type: "axis-hover",
      axisId,
      direction
    };
    this.listeners.dispatch("axis-hover", event);
  }
};

// packages/ag-charts-community/src/chart/interaction/cursorManager.ts
var CursorManager$1 = class {
  constructor(element2) {
    this.states = {};
    this.element = element2;
  }
  updateCursor(callerId, style) {
    delete this.states[callerId];
    if (style != null) {
      this.states[callerId] = { style };
    }
    this.applyStates();
  }
  applyStates() {
    let styleToApply = "default";
    Object.entries(this.states).reverse().slice(0, 1).forEach(([_, { style }]) => styleToApply = style);
    this.element.style.cursor = styleToApply;
  }
  getCursor() {
    return this.element.style.cursor;
  }
};

// packages/ag-charts-community/src/chart/interaction/highlightManager.ts
var HighlightManager$1 = class extends BaseManager$1 {
  constructor() {
    super(...arguments);
    this.highlightStates = /* @__PURE__ */ new Map();
    this.pickedStates = /* @__PURE__ */ new Map();
  }
  updateHighlight(callerId, highlightedDatum) {
    this.highlightStates.delete(callerId);
    if (highlightedDatum != null) {
      this.highlightStates.set(callerId, highlightedDatum);
    }
    this.applyHighlightStates();
  }
  getActiveHighlight() {
    return this.activeHighlight;
  }
  updatePicked(callerId, clickableDatum) {
    this.pickedStates.delete(callerId);
    if (clickableDatum != null) {
      this.pickedStates.set(callerId, clickableDatum);
    }
    this.applyPickedStates();
  }
  getActivePicked() {
    return this.activePicked;
  }
  applyHighlightStates() {
    const { activeHighlight: previousHighlight } = this;
    this.activeHighlight = Array.from(this.highlightStates.values()).pop();
    if (!this.isEqual(this.activeHighlight, previousHighlight)) {
      this.listeners.dispatch("highlight-change", {
        type: "highlight-change",
        currentHighlight: this.activeHighlight,
        previousHighlight
      });
    }
  }
  applyPickedStates() {
    this.activePicked = Array.from(this.pickedStates.values()).pop();
  }
  isEqual(a, b) {
    return a === b || (a == null ? void 0 : a.series) === (b == null ? void 0 : b.series) && (a == null ? void 0 : a.itemId) === (b == null ? void 0 : b.itemId) && (a == null ? void 0 : a.datum) === (b == null ? void 0 : b.datum);
  }
};

// packages/ag-charts-community/src/chart/interaction/interactionManager.ts
var WINDOW_EVENT_HANDLERS$1 = ["pagehide", "mousemove", "mouseup"];
var EVENT_HANDLERS$1 = [
  "click",
  "dblclick",
  "contextmenu",
  "mousedown",
  "mouseout",
  "mouseenter",
  "touchstart",
  "touchmove",
  "touchend",
  "touchcancel",
  "wheel"
];
var CSS$1 = `
.ag-chart-wrapper {
    touch-action: none;
}
`;
var _InteractionManager$1 = class _InteractionManager extends BaseManager$1 {
  constructor(element2, document2, window2) {
    super();
    this.eventHandler = (event) => this.processEvent(event);
    this.mouseDown = false;
    this.touchDown = false;
    this.pausers = { animation: 0, "context-menu": 0 };
    this.rootElement = document2.body;
    this.element = element2;
    this.window = window2;
    for (const type of EVENT_HANDLERS$1) {
      if (type.startsWith("touch")) {
        element2.addEventListener(type, this.eventHandler, { passive: true });
      } else if (type === "wheel") {
        element2.addEventListener(type, this.eventHandler, { passive: false });
      } else {
        element2.addEventListener(type, this.eventHandler);
      }
    }
    for (const type of WINDOW_EVENT_HANDLERS$1) {
      this.window.addEventListener(type, this.eventHandler);
    }
    if (!_InteractionManager.interactionDocuments.includes(document2)) {
      injectStyle$1(document2, CSS$1);
      _InteractionManager.interactionDocuments.push(document2);
    }
  }
  destroy() {
    for (const type of WINDOW_EVENT_HANDLERS$1) {
      this.window.removeEventListener(type, this.eventHandler);
    }
    for (const type of EVENT_HANDLERS$1) {
      this.element.removeEventListener(type, this.eventHandler);
    }
  }
  resume(pauseType) {
    this.pausers[pauseType]--;
  }
  pause(pauseType) {
    this.pausers[pauseType]++;
  }
  processEvent(event) {
    const types = this.decideInteractionEventTypes(event);
    if (types.length > 0) {
      this.dispatchEvent(event, types).catch((e) => Logger$1.errorOnce(e));
    }
  }
  dispatchEvent(event, types) {
    return __async$1(this, null, function* () {
      const coords = this.calculateCoordinates(event);
      if (coords == null) {
        return;
      }
      const pauses = Object.entries(this.pausers).filter(([, count2]) => count2 > 0).map(([pause]) => pause);
      for (const type of types) {
        this.listeners.dispatchWrapHandlers(
          type,
          (handler, meta, interactionEvent) => {
            var _a;
            if (pauses.length > 0 && !((_a = meta == null ? void 0 : meta.bypassPause) == null ? void 0 : _a.some((p) => pauses.includes(p)))) {
              return;
            }
            if (!interactionEvent.consumed) {
              handler(interactionEvent);
            }
          },
          this.buildEvent(__spreadValues$1({ type, event, pauses }, coords))
        );
      }
    });
  }
  decideInteractionEventTypes(event) {
    const dragStart = "drag-start";
    switch (event.type) {
      case "click":
        return ["click"];
      case "dblclick":
        return ["dblclick"];
      case "contextmenu":
        return ["contextmenu"];
      case "mousedown":
        this.mouseDown = true;
        this.dragStartElement = event.target;
        return [dragStart];
      case "touchstart":
        this.touchDown = true;
        this.dragStartElement = event.target;
        return [dragStart];
      case "touchmove":
      case "mousemove":
        if (!this.mouseDown && !this.touchDown && !this.isEventOverElement(event)) {
          return [];
        }
        return this.mouseDown || this.touchDown ? ["drag"] : ["hover"];
      case "mouseup":
        if (!this.mouseDown && !this.isEventOverElement(event)) {
          return [];
        }
        this.mouseDown = false;
        this.dragStartElement = void 0;
        return ["drag-end"];
      case "touchend":
        if (!this.touchDown && !this.isEventOverElement(event)) {
          return [];
        }
        this.touchDown = false;
        this.dragStartElement = void 0;
        return ["drag-end"];
      case "mouseout":
      case "touchcancel":
        return ["leave"];
      case "mouseenter":
        const mouseButtonDown = event instanceof MouseEvent && (event.buttons & 1) === 1;
        if (this.mouseDown !== mouseButtonDown) {
          this.mouseDown = mouseButtonDown;
          return [mouseButtonDown ? dragStart : "drag-end"];
        }
        return [];
      case "pagehide":
        return ["page-left"];
      case "wheel":
        return ["wheel"];
    }
    return [];
  }
  isEventOverElement(event) {
    var _a;
    return event.target === this.element || ((_a = event.target) == null ? void 0 : _a.parentElement) === this.element;
  }
  calculateCoordinates(event) {
    var _a;
    if (event instanceof MouseEvent) {
      const { clientX, clientY, pageX, pageY, offsetX, offsetY } = event;
      return this.fixOffsets(event, { clientX, clientY, pageX, pageY, offsetX, offsetY });
    } else if (typeof TouchEvent !== "undefined" && event instanceof TouchEvent) {
      const lastTouch = (_a = event.touches[0]) != null ? _a : event.changedTouches[0];
      const { clientX, clientY, pageX, pageY } = lastTouch;
      return __spreadProps$1(__spreadValues$1({}, _InteractionManager.NULL_COORDS), { clientX, clientY, pageX, pageY });
    } else if (event instanceof PageTransitionEvent) {
      if (event.persisted) {
        return;
      }
      return _InteractionManager.NULL_COORDS;
    }
  }
  fixOffsets(event, coords) {
    const offsets = (el) => {
      let x = 0;
      let y = 0;
      while (el) {
        x += el.offsetLeft;
        y += el.offsetTop;
        el = el.offsetParent;
      }
      return { x, y };
    };
    if (this.dragStartElement != null && event.target !== this.dragStartElement) {
      const offsetDragStart = offsets(this.dragStartElement);
      const offsetEvent = offsets(event.target);
      coords.offsetX -= offsetDragStart.x - offsetEvent.x;
      coords.offsetY -= offsetDragStart.y - offsetEvent.y;
    }
    return coords;
  }
  buildEvent(opts) {
    const { type, event, clientX, clientY, pauses } = opts;
    let { offsetX, offsetY, pageX, pageY } = opts;
    if (!isNumber2$1(offsetX) || !isNumber2$1(offsetY)) {
      const rect = this.element.getBoundingClientRect();
      offsetX = clientX - rect.left;
      offsetY = clientY - rect.top;
    }
    if (!isNumber2$1(pageX) || !isNumber2$1(pageY)) {
      const pageRect = this.rootElement.getBoundingClientRect();
      pageX = clientX - pageRect.left;
      pageY = clientY - pageRect.top;
    }
    const builtEvent = {
      type,
      offsetX,
      offsetY,
      pageX,
      pageY,
      sourceEvent: event,
      consumed: false,
      pauses,
      consume() {
        builtEvent.consumed = true;
      }
    };
    return builtEvent;
  }
};
_InteractionManager$1.interactionDocuments = [];
_InteractionManager$1.NULL_COORDS = {
  clientX: -Infinity,
  clientY: -Infinity,
  pageX: -Infinity,
  pageY: -Infinity,
  offsetX: -Infinity,
  offsetY: -Infinity
};
var InteractionManager$1 = _InteractionManager$1;

// packages/ag-charts-community/src/chart/interaction/tooltipManager.ts
var TooltipManager$1 = class {
  constructor(tooltip, interactionManager) {
    this.states = {};
    this.exclusiveAreas = {};
    this.destroyFns = [];
    this.tooltip = tooltip;
    this.destroyFns.push(interactionManager.addListener("hover", (e) => this.checkExclusiveRects(e)));
  }
  getRange() {
    return this.tooltip.range;
  }
  updateTooltip(callerId, meta, content) {
    var _a;
    if (content == null) {
      content = (_a = this.states[callerId]) == null ? void 0 : _a.content;
    }
    this.states[callerId] = { content, meta };
    this.applyStates();
  }
  updateExclusiveRect(callerId, area2) {
    if (area2) {
      this.exclusiveAreas[callerId] = area2;
    } else {
      delete this.exclusiveAreas[callerId];
    }
  }
  removeTooltip(callerId) {
    delete this.states[callerId];
    this.applyStates();
  }
  getTooltipMeta(callerId) {
    var _a;
    return (_a = this.states[callerId]) == null ? void 0 : _a.meta;
  }
  destroy() {
    for (const destroyFn of this.destroyFns) {
      destroyFn();
    }
  }
  checkExclusiveRects(e) {
    let newAppliedExclusiveArea;
    for (const [entryId, area2] of Object.entries(this.exclusiveAreas)) {
      if (!area2.containsPoint(e.offsetX, e.offsetY)) {
        continue;
      }
      newAppliedExclusiveArea = entryId;
      break;
    }
    if (newAppliedExclusiveArea === this.appliedExclusiveArea) {
      return;
    }
    this.appliedExclusiveArea = newAppliedExclusiveArea;
    this.applyStates();
  }
  applyStates() {
    var _a;
    const ids = this.appliedExclusiveArea ? [this.appliedExclusiveArea] : Object.keys(this.states);
    let contentToApply;
    let metaToApply;
    ids.reverse();
    ids.slice(0, 1).forEach((id) => {
      var _a2;
      const { content, meta } = (_a2 = this.states[id]) != null ? _a2 : {};
      contentToApply = content;
      metaToApply = meta;
    });
    if (metaToApply === void 0 || contentToApply === void 0) {
      this.appliedState = void 0;
      this.tooltip.toggle(false);
      return;
    }
    if (((_a = this.appliedState) == null ? void 0 : _a.content) === contentToApply) {
      const renderInstantly = this.tooltip.isVisible();
      this.tooltip.show(metaToApply, void 0, renderInstantly);
    } else {
      this.tooltip.show(metaToApply, contentToApply);
    }
    this.appliedState = { content: contentToApply, meta: metaToApply };
  }
  static makeTooltipMeta(event, canvas, datum, window2) {
    var _a, _b, _c, _d;
    const { pageX, pageY, offsetX, offsetY } = event;
    const { tooltip } = datum.series.properties;
    const position = {
      xOffset: tooltip.position.xOffset,
      yOffset: tooltip.position.yOffset
    };
    const meta = {
      pageX,
      pageY,
      offsetX,
      offsetY,
      event,
      showArrow: tooltip.showArrow,
      position
    };
    const refPoint = (_b = (_a = datum.yBar) == null ? void 0 : _a.upperPoint) != null ? _b : datum.midPoint;
    if (tooltip.position.type === "node" && refPoint) {
      const { x, y } = refPoint;
      const point = datum.series.contentGroup.inverseTransformPoint(x, y);
      const canvasRect = canvas.element.getBoundingClientRect();
      return __spreadProps$1(__spreadValues$1({}, meta), {
        pageX: Math.round(canvasRect.left + window2.scrollX + point.x),
        pageY: Math.round(canvasRect.top + window2.scrollY + point.y),
        offsetX: Math.round(point.x),
        offsetY: Math.round(point.y)
      });
    }
    meta.enableInteraction = (_d = (_c = tooltip.interaction) == null ? void 0 : _c.enabled) != null ? _d : false;
    return meta;
  }
};

// packages/ag-charts-community/src/chart/interaction/zoomManager.ts
var ZoomManager$1 = class extends BaseManager$1 {
  constructor() {
    super(...arguments);
    this.axes = {};
  }
  updateAxes(axes) {
    var _a;
    const removedAxes = new Set(Object.keys(this.axes));
    axes.forEach((axis) => {
      var _a2, _b, _c;
      removedAxes.delete(axis.id);
      (_c = (_a2 = this.axes)[_b = axis.id]) != null ? _c : _a2[_b] = new AxisZoomManager$1(axis);
    });
    removedAxes.forEach((axisId) => {
      delete this.axes[axisId];
    });
    if ((_a = this.initialZoom) == null ? void 0 : _a.newZoom) {
      this.updateZoom(this.initialZoom.callerId, this.initialZoom.newZoom);
    }
    this.initialZoom = void 0;
  }
  updateZoom(callerId, newZoom) {
    if (Object.keys(this.axes).length === 0) {
      this.initialZoom = { callerId, newZoom };
      return;
    }
    Object.values(this.axes).forEach((axis) => {
      axis.updateZoom(callerId, newZoom == null ? void 0 : newZoom[axis.getDirection()]);
    });
    this.applyStates();
  }
  updateAxisZoom(callerId, axisId, newZoom) {
    var _a;
    (_a = this.axes[axisId]) == null ? void 0 : _a.updateZoom(callerId, newZoom);
    this.applyStates();
  }
  getZoom() {
    let x;
    let y;
    Object.values(this.axes).forEach((axis) => {
      if (axis.getDirection() === "x" /* X */) {
        x = axis.getZoom();
      } else if (axis.getDirection() === "y" /* Y */) {
        y = axis.getZoom();
      }
    });
    if (x || y) {
      return { x, y };
    }
  }
  getAxisZoom(axisId) {
    var _a;
    return (_a = this.axes[axisId]) == null ? void 0 : _a.getZoom();
  }
  getAxisZooms() {
    const axes = {};
    for (const [axisId, axis] of Object.entries(this.axes)) {
      axes[axisId] = {
        direction: axis.getDirection(),
        zoom: axis.getZoom()
      };
    }
    return axes;
  }
  applyStates() {
    const changed = Object.values(this.axes).map((axis) => axis.applyStates()).some(Boolean);
    if (!changed) {
      return;
    }
    const currentZoom = this.getZoom();
    const axes = {};
    for (const [axisId, axis] of Object.entries(this.axes)) {
      axes[axisId] = axis.getZoom();
    }
    this.listeners.dispatch("zoom-change", __spreadProps$1(__spreadValues$1({
      type: "zoom-change"
    }, currentZoom != null ? currentZoom : {}), {
      axes
    }));
  }
};
var AxisZoomManager$1 = class {
  constructor(axis) {
    this.states = {};
    this.axis = axis;
    const [min = 0, max = 1] = axis.visibleRange;
    this.currentZoom = { min, max };
    this.states["__initial__"] = this.currentZoom;
  }
  getDirection() {
    return this.axis.direction;
  }
  updateZoom(callerId, newZoom) {
    delete this.states[callerId];
    if (newZoom != null) {
      this.states[callerId] = __spreadValues$1({}, newZoom);
    }
  }
  getZoom() {
    return this.currentZoom;
  }
  applyStates() {
    var _a, _b;
    const prevZoom = this.currentZoom;
    const last = Object.keys(this.states)[Object.keys(this.states).length - 1];
    this.currentZoom = __spreadValues$1({}, this.states[last]);
    return (prevZoom == null ? void 0 : prevZoom.min) !== ((_a = this.currentZoom) == null ? void 0 : _a.min) || (prevZoom == null ? void 0 : prevZoom.max) !== ((_b = this.currentZoom) == null ? void 0 : _b.max);
  }
};

// packages/ag-charts-community/src/chart/layout/layoutService.ts
var LayoutService$1 = class extends Listeners$1 {
  constructor() {
    super(...arguments);
    this.layoutComplete = "layout-complete";
  }
  addListener(eventType, handler) {
    if (this.isLayoutStage(eventType) || this.isLayoutComplete(eventType)) {
      return super.addListener(eventType, handler);
    }
    throw new Error(`AG Charts - unsupported listener type: ${eventType}`);
  }
  dispatchPerformLayout(stage, ctx) {
    if (this.isLayoutStage(stage)) {
      return this.getListenersByType(stage).reduce((result, listener) => {
        try {
          return listener.handler(result);
        } catch (e) {
          Logger$1.errorOnce(e);
          return result;
        }
      }, ctx);
    }
    return ctx;
  }
  dispatchLayoutComplete(event) {
    this.dispatch(this.layoutComplete, event);
  }
  isLayoutStage(eventType) {
    return eventType !== this.layoutComplete;
  }
  isLayoutComplete(eventType) {
    return eventType === this.layoutComplete;
  }
};

// packages/ag-charts-community/src/chart/gridLayout.ts
function gridLayout$1({
  orientation,
  bboxes,
  maxHeight,
  maxWidth,
  itemPaddingY = 0,
  itemPaddingX = 0,
  forceResult = false
}) {
  const horizontal = orientation === "horizontal";
  const primary = {
    max: horizontal ? maxWidth : maxHeight,
    fn: horizontal ? (b) => b.width : (b) => b.height,
    padding: horizontal ? itemPaddingX : itemPaddingY
  };
  const secondary = {
    max: !horizontal ? maxWidth : maxHeight,
    fn: !horizontal ? (b) => b.width : (b) => b.height,
    padding: !horizontal ? itemPaddingX : itemPaddingY
  };
  let processedBBoxCount = 0;
  const rawPages = [];
  while (processedBBoxCount < bboxes.length) {
    const unprocessedBBoxes = bboxes.slice(processedBBoxCount);
    const result = processBBoxes$1(unprocessedBBoxes, processedBBoxCount, primary, secondary, forceResult);
    if (!result) {
      return;
    }
    processedBBoxCount += result.processedBBoxCount;
    rawPages.push(result.pageIndices);
  }
  return buildPages$1(rawPages, orientation, bboxes, itemPaddingY, itemPaddingX);
}
function processBBoxes$1(bboxes, indexOffset, primary, secondary, forceResult) {
  const minGuess = 1;
  let startingGuess = estimateStartingGuess$1(bboxes, primary);
  if (startingGuess < minGuess) {
    if (!forceResult) {
      return void 0;
    }
    startingGuess = minGuess;
  }
  for (let guess = startingGuess; guess >= minGuess; guess--) {
    const pageIndices = calculatePage$1(bboxes, indexOffset, guess, primary, secondary, forceResult);
    if (pageIndices == null && guess <= minGuess) {
      return void 0;
    }
    if (pageIndices == null) {
      continue;
    }
    if (typeof pageIndices === "number") {
      if (pageIndices <= minGuess) {
        return void 0;
      }
      guess = pageIndices < guess && pageIndices > minGuess ? pageIndices : guess;
      continue;
    }
    const processedBBoxCount = pageIndices.length * pageIndices[0].length;
    return { processedBBoxCount, pageIndices };
  }
}
function calculatePage$1(bboxes, indexOffset, primaryCount, primary, secondary, forceResult) {
  var _a;
  const result = [];
  let sumSecondary = 0;
  let currentMaxSecondary = 0;
  let currentPrimaryIndices = [];
  const maxPrimaryValues = [];
  for (let bboxIndex = 0; bboxIndex < bboxes.length; bboxIndex++) {
    const primaryValueIdx = (bboxIndex + primaryCount) % primaryCount;
    if (primaryValueIdx === 0) {
      sumSecondary += currentMaxSecondary;
      currentMaxSecondary = 0;
      if (currentPrimaryIndices.length > 0) {
        result.push(currentPrimaryIndices);
      }
      currentPrimaryIndices = [];
    }
    const primaryValue = primary.fn(bboxes[bboxIndex]) + primary.padding;
    maxPrimaryValues[primaryValueIdx] = Math.max((_a = maxPrimaryValues[primaryValueIdx]) != null ? _a : 0, primaryValue);
    currentMaxSecondary = Math.max(currentMaxSecondary, secondary.fn(bboxes[bboxIndex]) + secondary.padding);
    const currentSecondaryDimension = sumSecondary + currentMaxSecondary;
    const returnResult = !forceResult || result.length > 0;
    if (currentSecondaryDimension > secondary.max && returnResult) {
      currentPrimaryIndices = [];
      break;
    }
    const sumPrimary = maxPrimaryValues.reduce((sum2, next) => sum2 + next, 0);
    if (sumPrimary > primary.max && !forceResult) {
      if (maxPrimaryValues.length < primaryCount) {
        return maxPrimaryValues.length;
      }
      return void 0;
    }
    currentPrimaryIndices.push(bboxIndex + indexOffset);
  }
  if (currentPrimaryIndices.length > 0) {
    result.push(currentPrimaryIndices);
  }
  return result.length > 0 ? result : void 0;
}
function buildPages$1(rawPages, orientation, bboxes, itemPaddingY, itemPaddingX) {
  let maxPageWidth = 0;
  let maxPageHeight = 0;
  const pages = rawPages.map((indices) => {
    if (orientation === "horizontal") {
      indices = transpose$1(indices);
    }
    let endIndex = 0;
    const columns = indices.map((colIndices) => {
      const colBBoxes = colIndices.map((bboxIndex) => {
        endIndex = Math.max(bboxIndex, endIndex);
        return bboxes[bboxIndex];
      });
      let columnHeight = 0;
      let columnWidth = 0;
      colBBoxes.forEach((bbox) => {
        columnHeight += bbox.height + itemPaddingY;
        columnWidth = Math.max(columnWidth, bbox.width + itemPaddingX);
      });
      return {
        indices: colIndices,
        bboxes: colBBoxes,
        columnHeight: Math.ceil(columnHeight),
        columnWidth: Math.ceil(columnWidth)
      };
    });
    let pageWidth = 0;
    let pageHeight = 0;
    columns.forEach((column) => {
      pageWidth += column.columnWidth;
      pageHeight = Math.max(pageHeight, column.columnHeight);
    });
    maxPageWidth = Math.max(pageWidth, maxPageWidth);
    maxPageHeight = Math.max(pageHeight, maxPageHeight);
    return {
      columns,
      startIndex: indices[0][0],
      endIndex,
      pageWidth,
      pageHeight
    };
  });
  return { pages, maxPageWidth, maxPageHeight };
}
function transpose$1(data) {
  const result = [];
  for (const _ of data[0]) {
    result.push([]);
  }
  data.forEach((innerData, dataIdx) => {
    innerData.forEach((item, itemIdx) => {
      result[itemIdx][dataIdx] = item;
    });
  });
  return result;
}
function estimateStartingGuess$1(bboxes, primary) {
  const n = bboxes.length;
  let primarySum = 0;
  for (let bboxIndex = 0; bboxIndex < n; bboxIndex++) {
    primarySum += primary.fn(bboxes[bboxIndex]) + primary.padding;
    if (primarySum > primary.max) {
      const ratio = n / bboxIndex;
      if (ratio < 2) {
        return Math.ceil(n / 2);
      }
      return bboxIndex;
    }
  }
  return n;
}

// packages/ag-charts-community/src/chart/markerLabel.ts
var MarkerLabel$1 = class extends Group$1 {
  constructor() {
    super({ name: "markerLabelGroup" });
    this.label = new Text$1();
    this.line = new Line$1();
    this._marker = new Square$1();
    this._markerSize = 15;
    this._spacing = 8;
    const { marker, label, line } = this;
    label.textBaseline = "middle";
    label.fontSize = 12;
    label.fontFamily = "Verdana, sans-serif";
    label.fill = "black";
    label.y = HdpiCanvas$1.has.textMetrics ? 1 : 0;
    this.append([line, marker, label]);
    this.update();
  }
  set marker(value) {
    if (this._marker !== value) {
      this.removeChild(this._marker);
      this._marker = value;
      this.appendChild(value);
      this.update();
    }
  }
  get marker() {
    return this._marker;
  }
  set markerSize(value) {
    if (this._markerSize !== value) {
      this._markerSize = value;
      this.update();
    }
  }
  get markerSize() {
    return this._markerSize;
  }
  set spacing(value) {
    if (this._spacing !== value) {
      this._spacing = value;
      this.update();
    }
  }
  get spacing() {
    return this._spacing;
  }
  setSeriesStrokeOffset(xOff) {
    const offset4 = this.marker.size / 2 + xOff;
    this.line.x1 = -offset4;
    this.line.x2 = offset4;
    this.line.y1 = 0;
    this.line.y2 = 0;
    this.line.markDirtyTransform();
    this.update();
  }
  update() {
    this.marker.size = this.markerSize;
    const lineEnd = this.line.visible ? this.line.x2 : -Infinity;
    const markerEnd = this.markerSize / 2;
    this.label.x = Math.max(lineEnd, markerEnd) + this.spacing;
  }
  render(renderCtx) {
    this.marker.opacity = this.opacity;
    this.label.opacity = this.opacity;
    this.line.opacity = this.opacity;
    super.render(renderCtx);
  }
};
MarkerLabel$1.className = "MarkerLabel";
__decorateClass$1([
  ProxyPropertyOnWrite$1("label")
], MarkerLabel$1.prototype, "text", 2);
__decorateClass$1([
  ProxyPropertyOnWrite$1("label")
], MarkerLabel$1.prototype, "fontStyle", 2);
__decorateClass$1([
  ProxyPropertyOnWrite$1("label")
], MarkerLabel$1.prototype, "fontWeight", 2);
__decorateClass$1([
  ProxyPropertyOnWrite$1("label")
], MarkerLabel$1.prototype, "fontSize", 2);
__decorateClass$1([
  ProxyPropertyOnWrite$1("label")
], MarkerLabel$1.prototype, "fontFamily", 2);
__decorateClass$1([
  ProxyPropertyOnWrite$1("label", "fill")
], MarkerLabel$1.prototype, "color", 2);
__decorateClass$1([
  ProxyPropertyOnWrite$1("marker", "fill")
], MarkerLabel$1.prototype, "markerFill", 2);
__decorateClass$1([
  ProxyPropertyOnWrite$1("marker", "stroke")
], MarkerLabel$1.prototype, "markerStroke", 2);
__decorateClass$1([
  ProxyPropertyOnWrite$1("marker", "strokeWidth")
], MarkerLabel$1.prototype, "markerStrokeWidth", 2);
__decorateClass$1([
  ProxyPropertyOnWrite$1("marker", "fillOpacity")
], MarkerLabel$1.prototype, "markerFillOpacity", 2);
__decorateClass$1([
  ProxyPropertyOnWrite$1("marker", "strokeOpacity")
], MarkerLabel$1.prototype, "markerStrokeOpacity", 2);
__decorateClass$1([
  ProxyPropertyOnWrite$1("marker", "visible")
], MarkerLabel$1.prototype, "markerVisible", 2);
__decorateClass$1([
  ProxyPropertyOnWrite$1("line", "stroke")
], MarkerLabel$1.prototype, "lineStroke", 2);
__decorateClass$1([
  ProxyPropertyOnWrite$1("line", "strokeWidth")
], MarkerLabel$1.prototype, "lineStrokeWidth", 2);
__decorateClass$1([
  ProxyPropertyOnWrite$1("line", "strokeOpacity")
], MarkerLabel$1.prototype, "lineStrokeOpacity", 2);
__decorateClass$1([
  ProxyPropertyOnWrite$1("line", "lineDash")
], MarkerLabel$1.prototype, "lineLineDash", 2);
__decorateClass$1([
  ProxyPropertyOnWrite$1("line", "visible")
], MarkerLabel$1.prototype, "lineVisible", 2);

// packages/ag-charts-community/src/chart/pagination/pagination.ts
var PaginationLabel$1 = class {
  constructor() {
    this.color = "black";
    this.fontStyle = void 0;
    this.fontWeight = void 0;
    this.fontSize = 12;
    this.fontFamily = "Verdana, sans-serif";
  }
};
__decorateClass$1([
  Validate$1(COLOR_STRING$1)
], PaginationLabel$1.prototype, "color", 2);
__decorateClass$1([
  Validate$1(FONT_STYLE$1, { optional: true })
], PaginationLabel$1.prototype, "fontStyle", 2);
__decorateClass$1([
  Validate$1(FONT_WEIGHT$1, { optional: true })
], PaginationLabel$1.prototype, "fontWeight", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], PaginationLabel$1.prototype, "fontSize", 2);
__decorateClass$1([
  Validate$1(STRING$1)
], PaginationLabel$1.prototype, "fontFamily", 2);
var PaginationMarkerStyle$1 = class {
  constructor() {
    this.size = 15;
    this.fill = void 0;
    this.fillOpacity = void 0;
    this.stroke = void 0;
    this.strokeWidth = 1;
    this.strokeOpacity = 1;
  }
};
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], PaginationMarkerStyle$1.prototype, "size", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true })
], PaginationMarkerStyle$1.prototype, "fill", 2);
__decorateClass$1([
  Validate$1(RATIO$1, { optional: true })
], PaginationMarkerStyle$1.prototype, "fillOpacity", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true })
], PaginationMarkerStyle$1.prototype, "stroke", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], PaginationMarkerStyle$1.prototype, "strokeWidth", 2);
__decorateClass$1([
  Validate$1(RATIO$1)
], PaginationMarkerStyle$1.prototype, "strokeOpacity", 2);
var PaginationMarker$1 = class {
  constructor(parent) {
    this.parent = parent;
    this.shape = Triangle$1;
    this.size = 15;
    this.padding = 8;
  }
};
__decorateClass$1([
  ActionOnSet$1({
    changeValue() {
      if (this.parent.marker === this) {
        this.parent.onMarkerShapeChange();
      }
    }
  })
], PaginationMarker$1.prototype, "shape", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], PaginationMarker$1.prototype, "size", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], PaginationMarker$1.prototype, "padding", 2);
var Pagination$1 = class {
  constructor(chartUpdateCallback, pageUpdateCallback, interactionManager, cursorManager) {
    this.chartUpdateCallback = chartUpdateCallback;
    this.pageUpdateCallback = pageUpdateCallback;
    this.interactionManager = interactionManager;
    this.cursorManager = cursorManager;
    this.id = createId$2(this);
    this.group = new Group$1({ name: "pagination" });
    this.labelNode = new Text$1();
    this.marker = new PaginationMarker$1(this);
    this.activeStyle = new PaginationMarkerStyle$1();
    this.inactiveStyle = new PaginationMarkerStyle$1();
    this.highlightStyle = new PaginationMarkerStyle$1();
    this.label = new PaginationLabel$1();
    this.totalPages = 0;
    this.currentPage = 0;
    this.translationX = 0;
    this.translationY = 0;
    this.nextButtonDisabled = false;
    this.previousButtonDisabled = false;
    this._visible = true;
    this._enabled = true;
    this._orientation = "vertical";
    this._nextButton = new Triangle$1();
    this._previousButton = new Triangle$1();
    const { labelNode } = this;
    labelNode.textBaseline = "middle";
    labelNode.fontSize = 12;
    labelNode.fontFamily = "Verdana, sans-serif";
    labelNode.fill = "black";
    labelNode.y = HdpiCanvas$1.has.textMetrics ? 1 : 0;
    this.group.append([this.nextButton, this.previousButton, labelNode]);
    this.interactionManager.addListener("click", (event) => this.onPaginationClick(event));
    this.interactionManager.addListener("hover", (event) => this.onPaginationMouseMove(event));
    this.update();
    this.updateMarkers();
  }
  set visible(value) {
    this._visible = value;
    this.updateGroupVisibility();
  }
  get visible() {
    return this._visible;
  }
  set enabled(value) {
    this._enabled = value;
    this.updateGroupVisibility();
  }
  get enabled() {
    return this._enabled;
  }
  updateGroupVisibility() {
    this.group.visible = this.enabled && this.visible;
  }
  set orientation(value) {
    this._orientation = value;
    switch (value) {
      case "horizontal": {
        this.previousButton.rotation = -Math.PI / 2;
        this.nextButton.rotation = Math.PI / 2;
        break;
      }
      case "vertical":
      default: {
        this.previousButton.rotation = 0;
        this.nextButton.rotation = Math.PI;
      }
    }
  }
  get orientation() {
    return this._orientation;
  }
  set nextButton(value) {
    if (this._nextButton !== value) {
      this.group.removeChild(this._nextButton);
      this._nextButton = value;
      this.group.appendChild(value);
    }
  }
  get nextButton() {
    return this._nextButton;
  }
  set previousButton(value) {
    if (this._previousButton !== value) {
      this.group.removeChild(this._previousButton);
      this._previousButton = value;
      this.group.appendChild(value);
    }
  }
  get previousButton() {
    return this._previousButton;
  }
  update() {
    this.updateLabel();
    this.updatePositions();
    this.enableOrDisableButtons();
  }
  updatePositions() {
    this.group.translationX = this.translationX;
    this.group.translationY = this.translationY;
    this.updateLabelPosition();
    this.updateNextButtonPosition();
  }
  updateLabelPosition() {
    const { size: markerSize, padding: markerPadding } = this.marker;
    this.nextButton.size = markerSize;
    this.previousButton.size = markerSize;
    this.labelNode.x = markerSize / 2 + markerPadding;
  }
  updateNextButtonPosition() {
    const labelBBox = this.labelNode.computeBBox();
    this.nextButton.translationX = labelBBox.x + labelBBox.width + this.marker.size / 2 + this.marker.padding;
  }
  updateLabel() {
    const {
      currentPage,
      totalPages: pages,
      labelNode,
      label: { color, fontStyle, fontWeight, fontSize, fontFamily }
    } = this;
    labelNode.text = `${currentPage + 1} / ${pages}`;
    labelNode.fill = color;
    labelNode.fontStyle = fontStyle;
    labelNode.fontWeight = fontWeight;
    labelNode.fontSize = fontSize;
    labelNode.fontFamily = fontFamily;
  }
  updateMarkers() {
    const {
      nextButton,
      previousButton,
      nextButtonDisabled,
      previousButtonDisabled,
      activeStyle,
      inactiveStyle,
      highlightStyle,
      highlightActive
    } = this;
    const buttonStyle = (button, disabled) => {
      if (disabled) {
        return inactiveStyle;
      } else if (button === highlightActive) {
        return highlightStyle;
      }
      return activeStyle;
    };
    this.updateMarker(nextButton, buttonStyle("next", nextButtonDisabled));
    this.updateMarker(previousButton, buttonStyle("previous", previousButtonDisabled));
  }
  updateMarker(marker, style) {
    var _a;
    const { size } = this.marker;
    marker.size = size;
    marker.fill = style.fill;
    marker.fillOpacity = (_a = style.fillOpacity) != null ? _a : 1;
    marker.stroke = style.stroke;
    marker.strokeWidth = style.strokeWidth;
    marker.strokeOpacity = style.strokeOpacity;
  }
  enableOrDisableButtons() {
    const { currentPage, totalPages } = this;
    const zeroPagesToDisplay = totalPages === 0;
    const onLastPage = currentPage === totalPages - 1;
    const onFirstPage = currentPage === 0;
    this.nextButtonDisabled = onLastPage || zeroPagesToDisplay;
    this.previousButtonDisabled = onFirstPage || zeroPagesToDisplay;
  }
  nextButtonContainsPoint(offsetX, offsetY) {
    return !this.nextButtonDisabled && this.nextButton.containsPoint(offsetX, offsetY);
  }
  previousButtonContainsPoint(offsetX, offsetY) {
    return !this.previousButtonDisabled && this.previousButton.containsPoint(offsetX, offsetY);
  }
  onPaginationClick(event) {
    const { offsetX, offsetY } = event;
    if (this.nextButtonContainsPoint(offsetX, offsetY)) {
      this.incrementPage();
      this.onPaginationChanged();
      event.consume();
    } else if (this.previousButtonContainsPoint(offsetX, offsetY)) {
      this.decrementPage();
      this.onPaginationChanged();
      event.consume();
    }
  }
  onPaginationMouseMove(event) {
    const { offsetX, offsetY } = event;
    if (this.nextButtonContainsPoint(offsetX, offsetY)) {
      this.cursorManager.updateCursor(this.id, "pointer");
      this.highlightActive = "next";
    } else if (this.previousButtonContainsPoint(offsetX, offsetY)) {
      this.cursorManager.updateCursor(this.id, "pointer");
      this.highlightActive = "previous";
    } else {
      this.cursorManager.updateCursor(this.id);
      this.highlightActive = void 0;
    }
    this.updateMarkers();
    this.chartUpdateCallback(5 /* SCENE_RENDER */);
  }
  onPaginationChanged() {
    this.pageUpdateCallback(this.currentPage);
  }
  incrementPage() {
    this.currentPage = Math.min(this.currentPage + 1, this.totalPages - 1);
  }
  decrementPage() {
    this.currentPage = Math.max(this.currentPage - 1, 0);
  }
  onMarkerShapeChange() {
    const Marker2 = getMarker$2(this.marker.shape || Triangle$1);
    this.previousButton = new Marker2();
    this.nextButton = new Marker2();
    this.updatePositions();
    this.updateMarkers();
    this.chartUpdateCallback(5 /* SCENE_RENDER */);
  }
  attachPagination(node) {
    node.append(this.group);
  }
  computeBBox() {
    return this.group.computeBBox();
  }
};
Pagination$1.className = "Pagination";

// packages/ag-charts-community/src/chart/legend.ts
var LegendLabel$1 = class {
  constructor() {
    this.maxLength = void 0;
    this.color = "black";
    this.fontStyle = void 0;
    this.fontWeight = void 0;
    this.fontSize = 12;
    this.fontFamily = "Verdana, sans-serif";
    this.formatter = void 0;
  }
};
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], LegendLabel$1.prototype, "maxLength", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1)
], LegendLabel$1.prototype, "color", 2);
__decorateClass$1([
  Validate$1(FONT_STYLE$1, { optional: true })
], LegendLabel$1.prototype, "fontStyle", 2);
__decorateClass$1([
  Validate$1(FONT_WEIGHT$1, { optional: true })
], LegendLabel$1.prototype, "fontWeight", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], LegendLabel$1.prototype, "fontSize", 2);
__decorateClass$1([
  Validate$1(STRING$1)
], LegendLabel$1.prototype, "fontFamily", 2);
__decorateClass$1([
  Validate$1(FUNCTION$1, { optional: true })
], LegendLabel$1.prototype, "formatter", 2);
var LegendMarker$1 = class {
  constructor() {
    this.size = 15;
    /**
     * If the marker type is set, the legend will always use that marker type for all its items,
     * regardless of the type that comes from the `data`.
     */
    this._shape = void 0;
    this.padding = 8;
    this.strokeWidth = void 0;
    this.enabled = true;
  }
  set shape(value) {
    var _a;
    this._shape = value;
    (_a = this.parent) == null ? void 0 : _a.onMarkerShapeChange();
  }
  get shape() {
    return this._shape;
  }
};
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], LegendMarker$1.prototype, "size", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], LegendMarker$1.prototype, "padding", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], LegendMarker$1.prototype, "strokeWidth", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1, { optional: true })
], LegendMarker$1.prototype, "enabled", 2);
var LegendLine$1 = class {
  constructor() {
    this.strokeWidth = void 0;
    this.length = void 0;
  }
};
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], LegendLine$1.prototype, "strokeWidth", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], LegendLine$1.prototype, "length", 2);
var LegendItem$1 = class {
  constructor() {
    this.marker = new LegendMarker$1();
    this.label = new LegendLabel$1();
    this.line = new LegendLine$1();
    this.maxWidth = void 0;
    this.paddingX = 16;
    this.paddingY = 8;
    this.toggleSeriesVisible = true;
    this.showSeriesStroke = false;
  }
};
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], LegendItem$1.prototype, "maxWidth", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], LegendItem$1.prototype, "paddingX", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], LegendItem$1.prototype, "paddingY", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], LegendItem$1.prototype, "toggleSeriesVisible", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], LegendItem$1.prototype, "showSeriesStroke", 2);
var LegendListeners$1 = class {
  constructor() {
    this.legendItemClick = void 0;
    this.legendItemDoubleClick = void 0;
  }
};
__decorateClass$1([
  Validate$1(FUNCTION$1, { optional: true })
], LegendListeners$1.prototype, "legendItemClick", 2);
var Legend$1 = class {
  constructor(ctx) {
    this.ctx = ctx;
    this.id = createId$2(this);
    this.group = new Group$1({ name: "legend", layer: true, zIndex: 9 /* LEGEND_ZINDEX */ });
    this.itemSelection = Selection$1.select(this.group, MarkerLabel$1);
    this.oldSize = [0, 0];
    this.pages = [];
    this.maxPageSize = [0, 0];
    /** Item index to track on re-pagination, so current page updates appropriately. */
    this.paginationTrackingIndex = 0;
    this.item = new LegendItem$1();
    this.listeners = new LegendListeners$1();
    this.truncatedItems = /* @__PURE__ */ new Set();
    this._data = [];
    this._enabled = true;
    this.position = "bottom";
    this.maxWidth = void 0;
    this.maxHeight = void 0;
    this.reverseOrder = void 0;
    this.preventHidingAll = void 0;
    this.destroyFns = [];
    this.spacing = 20;
    this.characterWidths = /* @__PURE__ */ new Map();
    this.size = [0, 0];
    this._visible = true;
    this.item.marker.parent = this;
    this.pagination = new Pagination$1(
      (type) => ctx.updateService.update(type),
      (page) => this.updatePageNumber(page),
      ctx.interactionManager,
      ctx.cursorManager
    );
    this.pagination.attachPagination(this.group);
    this.item.marker.parent = this;
    const bypass = { bypassPause: ["animation"] };
    this.destroyFns.push(
      ctx.interactionManager.addListener("click", (e) => this.checkLegendClick(e), bypass),
      ctx.interactionManager.addListener("dblclick", (e) => this.checkLegendDoubleClick(e), bypass),
      ctx.interactionManager.addListener("hover", (e) => this.handleLegendMouseMove(e)),
      ctx.layoutService.addListener("start-layout", (e) => this.positionLegend(e.shrinkRect)),
      () => this.detachLegend()
    );
  }
  set data(value) {
    this._data = value;
    this.updateGroupVisibility();
  }
  get data() {
    return this._data;
  }
  set enabled(value) {
    this._enabled = value;
    this.updateGroupVisibility();
  }
  get enabled() {
    return this._enabled;
  }
  getOrientation() {
    if (this.orientation !== void 0) {
      return this.orientation;
    }
    switch (this.position) {
      case "right":
      case "left":
        return "vertical";
      case "bottom":
      case "top":
        return "horizontal";
    }
  }
  destroy() {
    this.destroyFns.forEach((f) => f());
  }
  onMarkerShapeChange() {
    this.itemSelection.clear();
    this.group.markDirty(this.group, 2 /* MINOR */);
  }
  getCharacterWidths(font) {
    const { characterWidths } = this;
    if (characterWidths.has(font)) {
      return characterWidths.get(font);
    }
    const cw = {
      "...": HdpiCanvas$1.getTextSize("...", font).width
    };
    characterWidths.set(font, cw);
    return cw;
  }
  set visible(value) {
    this._visible = value;
    this.updateGroupVisibility();
  }
  get visible() {
    return this._visible;
  }
  updateGroupVisibility() {
    this.group.visible = this.enabled && this.visible && this.data.length > 0;
  }
  attachLegend(node) {
    node.append(this.group);
  }
  detachLegend() {
    var _a;
    (_a = this.group.parent) == null ? void 0 : _a.removeChild(this.group);
  }
  getItemLabel(datum) {
    const {
      ctx: { callbackCache }
    } = this;
    const { formatter } = this.item.label;
    if (formatter) {
      return callbackCache.call(formatter, {
        itemId: datum.itemId,
        value: datum.label.text,
        seriesId: datum.seriesId
      });
    }
    return datum.label.text;
  }
  /**
   * The method is given the desired size of the legend, which only serves as a hint.
   * The vertically oriented legend will take as much horizontal space as needed, but will
   * respect the height constraints, and the horizontal legend will take as much vertical
   * space as needed in an attempt not to exceed the given width.
   * After the layout is done, the {@link size} will contain the actual size of the legend.
   * If the actual size is not the same as the previous actual size, the legend will fire
   * the 'layoutChange' event to communicate that another layout is needed, and the above
   * process should be repeated.
   * @param width
   * @param height
   */
  performLayout(width, height) {
    const {
      paddingX,
      paddingY,
      label,
      maxWidth,
      marker: { size: markerSize, padding: markerPadding, shape: markerShape },
      label: { maxLength = Infinity, fontStyle, fontWeight, fontSize, fontFamily },
      line: itemLine,
      showSeriesStroke
    } = this.item;
    const data = [...this.data];
    if (this.reverseOrder) {
      data.reverse();
    }
    this.itemSelection.update(data);
    const bboxes = [];
    const font = getFont$1(label);
    const itemMaxWidthPercentage = 0.8;
    const maxItemWidth = maxWidth != null ? maxWidth : width * itemMaxWidthPercentage;
    const paddedMarkerWidth = markerSize + markerPadding + paddingX;
    this.itemSelection.each((markerLabel, datum) => {
      var _a, _b, _c;
      const Marker2 = getMarker$2(markerShape != null ? markerShape : datum.marker.shape);
      const markerEnabled = (_a = datum.marker.enabled) != null ? _a : this.item.marker.enabled;
      if (!(markerLabel.marker && markerLabel.marker instanceof Marker2)) {
        markerLabel.marker = new Marker2();
      }
      markerLabel.markerSize = markerSize;
      markerLabel.spacing = markerPadding;
      markerLabel.fontStyle = fontStyle;
      markerLabel.fontWeight = fontWeight;
      markerLabel.fontSize = fontSize;
      markerLabel.fontFamily = fontFamily;
      const id = (_b = datum.itemId) != null ? _b : datum.id;
      const labelText = this.getItemLabel(datum);
      const text = (labelText != null ? labelText : "<unknown>").replace(/\r?\n/g, " ");
      markerLabel.text = this.truncate(text, maxLength, maxItemWidth, paddedMarkerWidth, font, id);
      if (showSeriesStroke && datum.line !== void 0) {
        markerLabel.lineVisible = true;
        markerLabel.markerVisible = markerEnabled;
        markerLabel.setSeriesStrokeOffset((_c = itemLine.length) != null ? _c : 5);
      } else {
        markerLabel.lineVisible = false;
        markerLabel.markerVisible = true;
      }
      bboxes.push(markerLabel.computeBBox());
    });
    width = Math.max(1, width);
    height = Math.max(1, height);
    if (!isFinite(width)) {
      return false;
    }
    const size = this.size;
    const oldSize = this.oldSize;
    size[0] = width;
    size[1] = height;
    if (size[0] !== oldSize[0] || size[1] !== oldSize[1]) {
      oldSize[0] = size[0];
      oldSize[1] = size[1];
    }
    const { pages, maxPageHeight, maxPageWidth } = this.updatePagination(bboxes, width, height);
    this.pages = pages;
    this.maxPageSize = [maxPageWidth - paddingX, maxPageHeight - paddingY];
    const pageNumber = this.pagination.currentPage;
    const page = this.pages[pageNumber];
    if (this.pages.length < 1 || !page) {
      this.visible = false;
      return;
    }
    this.visible = true;
    this.updatePositions(pageNumber);
    this.update();
  }
  truncate(text, maxCharLength, maxItemWidth, paddedMarkerWidth, font, id) {
    const ellipsis2 = `...`;
    const textChars = text.split("");
    let addEllipsis = false;
    if (text.length > maxCharLength) {
      text = `${text.substring(0, maxCharLength)}`;
      addEllipsis = true;
    }
    const labelWidth = Math.floor(paddedMarkerWidth + HdpiCanvas$1.getTextSize(text, font).width);
    if (labelWidth > maxItemWidth) {
      let truncatedText = "";
      const characterWidths = this.getCharacterWidths(font);
      let cumulativeWidth = paddedMarkerWidth + characterWidths[ellipsis2];
      for (const char of textChars) {
        if (!characterWidths[char]) {
          characterWidths[char] = HdpiCanvas$1.getTextSize(char, font).width;
        }
        cumulativeWidth += characterWidths[char];
        if (cumulativeWidth > maxItemWidth) {
          break;
        }
        truncatedText += char;
      }
      text = truncatedText;
      addEllipsis = true;
    }
    if (addEllipsis) {
      text += ellipsis2;
      this.truncatedItems.add(id);
    } else {
      this.truncatedItems.delete(id);
    }
    return text;
  }
  updatePagination(bboxes, width, height) {
    const orientation = this.getOrientation();
    const trackingIndex = Math.min(this.paginationTrackingIndex, bboxes.length);
    this.pagination.orientation = orientation;
    this.pagination.translationX = 0;
    this.pagination.translationY = 0;
    const { pages, maxPageHeight, maxPageWidth, paginationBBox, paginationVertical } = this.calculatePagination(
      bboxes,
      width,
      height
    );
    const newCurrentPage = pages.findIndex((p) => p.endIndex >= trackingIndex);
    this.pagination.currentPage = Math.min(Math.max(newCurrentPage, 0), pages.length - 1);
    const { paddingX: itemPaddingX, paddingY: itemPaddingY } = this.item;
    const paginationComponentPadding = 8;
    const legendItemsWidth = maxPageWidth - itemPaddingX;
    const legendItemsHeight = maxPageHeight - itemPaddingY;
    let paginationX = 0;
    let paginationY = -paginationBBox.y - this.item.marker.size / 2;
    if (paginationVertical) {
      paginationY += legendItemsHeight + paginationComponentPadding;
    } else {
      paginationX += -paginationBBox.x + legendItemsWidth + paginationComponentPadding;
      paginationY += (legendItemsHeight - paginationBBox.height) / 2;
    }
    this.pagination.translationX = paginationX;
    this.pagination.translationY = paginationY;
    this.pagination.update();
    this.pagination.updateMarkers();
    return {
      maxPageHeight,
      maxPageWidth,
      pages
    };
  }
  calculatePagination(bboxes, width, height) {
    var _a, _b, _c;
    const { paddingX: itemPaddingX, paddingY: itemPaddingY } = this.item;
    const orientation = this.getOrientation();
    const paginationVertical = ["left", "right"].includes(this.position);
    let paginationBBox = this.pagination.computeBBox();
    let lastPassPaginationBBox = new BBox$1(0, 0, 0, 0);
    let pages = [];
    let maxPageWidth = 0;
    let maxPageHeight = 0;
    let count2 = 0;
    const stableOutput = (lastPassPaginationBBox2) => {
      const { width: width2, height: height2 } = lastPassPaginationBBox2;
      return width2 === paginationBBox.width && height2 === paginationBBox.height;
    };
    const forceResult = this.maxWidth !== void 0 || this.maxHeight !== void 0;
    do {
      if (count2++ > 10) {
        Logger$1.warn("unable to find stable legend layout.");
        break;
      }
      paginationBBox = lastPassPaginationBBox;
      const maxWidth = width - (paginationVertical ? 0 : paginationBBox.width);
      const maxHeight = height - (paginationVertical ? paginationBBox.height : 0);
      const layout = gridLayout$1({
        orientation,
        bboxes,
        maxHeight,
        maxWidth,
        itemPaddingY,
        itemPaddingX,
        forceResult
      });
      pages = (_a = layout == null ? void 0 : layout.pages) != null ? _a : [];
      maxPageWidth = (_b = layout == null ? void 0 : layout.maxPageWidth) != null ? _b : 0;
      maxPageHeight = (_c = layout == null ? void 0 : layout.maxPageHeight) != null ? _c : 0;
      const totalPages = pages.length;
      this.pagination.visible = totalPages > 1;
      this.pagination.totalPages = totalPages;
      this.pagination.update();
      lastPassPaginationBBox = this.pagination.computeBBox();
      if (!this.pagination.visible) {
        break;
      }
    } while (!stableOutput(lastPassPaginationBBox));
    return { maxPageWidth, maxPageHeight, pages, paginationBBox, paginationVertical };
  }
  updatePositions(pageNumber = 0) {
    const {
      item: { paddingY },
      itemSelection,
      pages
    } = this;
    if (pages.length < 1 || !pages[pageNumber]) {
      return;
    }
    const { columns, startIndex: visibleStart, endIndex: visibleEnd } = pages[pageNumber];
    let x = 0;
    let y = 0;
    const columnCount = columns.length;
    const rowCount = columns[0].indices.length;
    const horizontal = this.getOrientation() === "horizontal";
    const itemHeight = columns[0].bboxes[0].height + paddingY;
    const rowSumColumnWidths = [];
    itemSelection.each((markerLabel, _, i) => {
      var _a, _b;
      if (i < visibleStart || i > visibleEnd) {
        markerLabel.visible = false;
        return;
      }
      const pageIndex = i - visibleStart;
      let columnIndex = 0;
      let rowIndex = 0;
      if (horizontal) {
        columnIndex = pageIndex % columnCount;
        rowIndex = Math.floor(pageIndex / columnCount);
      } else {
        columnIndex = Math.floor(pageIndex / rowCount);
        rowIndex = pageIndex % rowCount;
      }
      markerLabel.visible = true;
      const column = columns[columnIndex];
      if (!column) {
        return;
      }
      y = itemHeight * rowIndex;
      x = (_a = rowSumColumnWidths[rowIndex]) != null ? _a : 0;
      rowSumColumnWidths[rowIndex] = ((_b = rowSumColumnWidths[rowIndex]) != null ? _b : 0) + column.columnWidth;
      markerLabel.translationX = Math.floor(x);
      markerLabel.translationY = Math.floor(y);
    });
  }
  updatePageNumber(pageNumber) {
    const { pages } = this;
    const { startIndex, endIndex } = pages[pageNumber];
    if (startIndex === 0) {
      this.paginationTrackingIndex = 0;
    } else if (pageNumber === pages.length - 1) {
      this.paginationTrackingIndex = endIndex;
    } else {
      this.paginationTrackingIndex = Math.floor((startIndex + endIndex) / 2);
    }
    this.pagination.update();
    this.pagination.updateMarkers();
    this.updatePositions(pageNumber);
    this.ctx.updateService.update(5 /* SCENE_RENDER */);
  }
  update() {
    const {
      label: { color },
      marker: itemMarker,
      line: itemLine,
      showSeriesStroke
    } = this.item;
    this.itemSelection.each((markerLabel, datum) => {
      var _a, _b;
      const marker = datum.marker;
      markerLabel.markerFill = marker.fill;
      markerLabel.markerStroke = marker.stroke;
      markerLabel.markerStrokeWidth = (_a = itemMarker.strokeWidth) != null ? _a : Math.min(2, marker.strokeWidth);
      markerLabel.markerFillOpacity = marker.fillOpacity;
      markerLabel.markerStrokeOpacity = marker.strokeOpacity;
      markerLabel.opacity = datum.enabled ? 1 : 0.5;
      markerLabel.color = color;
      const { line } = datum;
      if (showSeriesStroke && line !== void 0) {
        markerLabel.lineStroke = line.stroke;
        markerLabel.lineStrokeOpacity = line.strokeOpacity;
        markerLabel.lineStrokeWidth = (_b = itemLine.strokeWidth) != null ? _b : Math.min(2, line.strokeWidth);
        markerLabel.lineLineDash = line.lineDash;
      }
    });
  }
  getDatumForPoint(x, y) {
    const visibleChildBBoxes = [];
    const closestLeftTop = { dist: Infinity, datum: void 0 };
    for (const child of this.group.children) {
      if (!child.visible)
        continue;
      if (!(child instanceof MarkerLabel$1))
        continue;
      const childBBox = child.computeBBox();
      childBBox.grow(this.item.paddingX / 2, "horizontal");
      childBBox.grow(this.item.paddingY / 2, "vertical");
      if (childBBox.containsPoint(x, y)) {
        return child.datum;
      }
      const distX = x - childBBox.x - this.item.paddingX / 2;
      const distY = y - childBBox.y - this.item.paddingY / 2;
      const dist = __pow$1(distX, 2) + __pow$1(distY, 2);
      const toTheLeftTop = distX >= 0 && distY >= 0;
      if (toTheLeftTop && dist < closestLeftTop.dist) {
        closestLeftTop.dist = dist;
        closestLeftTop.datum = child.datum;
      }
      visibleChildBBoxes.push(childBBox);
    }
    const pageBBox = BBox$1.merge(visibleChildBBoxes);
    if (!pageBBox.containsPoint(x, y)) {
      return void 0;
    }
    return closestLeftTop.datum;
  }
  computeBBox() {
    return this.group.computeBBox();
  }
  computePagedBBox() {
    const actualBBox = this.group.computeBBox();
    if (this.pages.length <= 1) {
      return actualBBox;
    }
    const [maxPageWidth, maxPageHeight] = this.maxPageSize;
    actualBBox.height = Math.max(maxPageHeight, actualBBox.height);
    actualBBox.width = Math.max(maxPageWidth, actualBBox.width);
    return actualBBox;
  }
  checkLegendClick(event) {
    const {
      listeners: { legendItemClick },
      ctx: { chartService, highlightManager },
      item: { toggleSeriesVisible },
      preventHidingAll
    } = this;
    const { offsetX, offsetY } = event;
    const legendBBox = this.computeBBox();
    const pointerInsideLegend = this.group.visible && legendBBox.containsPoint(offsetX, offsetY);
    const datum = this.getDatumForPoint(offsetX, offsetY);
    if (!pointerInsideLegend || !datum) {
      return;
    }
    const { id, itemId, enabled } = datum;
    const series = chartService.series.find((s) => s.id === id);
    if (!series) {
      return;
    }
    event.consume();
    let newEnabled = enabled;
    if (toggleSeriesVisible) {
      newEnabled = !enabled;
      if (preventHidingAll && !newEnabled) {
        const numVisibleItems = chartService.series.flatMap((series2) => series2.getLegendData("category")).filter((datum2) => datum2.enabled).length;
        if (numVisibleItems < 2) {
          newEnabled = true;
        }
      }
      this.ctx.chartEventManager.legendItemClick(series, itemId, newEnabled, datum.legendItemName);
    }
    if (!newEnabled) {
      highlightManager.updateHighlight(this.id);
    } else {
      highlightManager.updateHighlight(this.id, {
        series,
        itemId,
        datum: void 0
      });
    }
    this.ctx.updateService.update(1 /* PROCESS_DATA */, { forceNodeDataRefresh: true });
    legendItemClick == null ? void 0 : legendItemClick({ type: "click", enabled: newEnabled, itemId, seriesId: series.id });
  }
  checkLegendDoubleClick(event) {
    var _a;
    const {
      listeners: { legendItemDoubleClick },
      ctx: { chartService },
      item: { toggleSeriesVisible }
    } = this;
    const { offsetX, offsetY } = event;
    if (chartService.mode === "integrated") {
      return;
    }
    const legendBBox = this.computeBBox();
    const pointerInsideLegend = this.group.visible && legendBBox.containsPoint(offsetX, offsetY);
    const datum = this.getDatumForPoint(offsetX, offsetY);
    if (!pointerInsideLegend || !datum) {
      return;
    }
    const { id, itemId, seriesId } = datum;
    const series = chartService.series.find((s) => s.id === id);
    if (!series) {
      return;
    }
    event.consume();
    if (toggleSeriesVisible) {
      const legendData = chartService.series.flatMap((series2) => series2.getLegendData("category"));
      const numVisibleItems = legendData.filter((datum2) => datum2.enabled).length;
      const clickedItem = legendData.find((d) => d.itemId === itemId && d.seriesId === seriesId);
      this.ctx.chartEventManager.legendItemDoubleClick(
        series,
        itemId,
        (_a = clickedItem == null ? void 0 : clickedItem.enabled) != null ? _a : false,
        numVisibleItems,
        clickedItem == null ? void 0 : clickedItem.legendItemName
      );
    }
    this.ctx.updateService.update(1 /* PROCESS_DATA */, { forceNodeDataRefresh: true });
    legendItemDoubleClick == null ? void 0 : legendItemDoubleClick({ type: "dblclick", enabled: true, itemId, seriesId: series.id });
  }
  handleLegendMouseMove(event) {
    var _a;
    const {
      enabled,
      item: { toggleSeriesVisible },
      listeners
    } = this;
    if (!enabled) {
      return;
    }
    const legendBBox = this.computeBBox();
    const { pageX, pageY, offsetX, offsetY } = event;
    const pointerInsideLegend = this.group.visible && legendBBox.containsPoint(offsetX, offsetY);
    if (!pointerInsideLegend) {
      this.ctx.cursorManager.updateCursor(this.id);
      this.ctx.highlightManager.updateHighlight(this.id);
      this.ctx.tooltipManager.removeTooltip(this.id);
      return;
    }
    event.consume();
    const datum = this.getDatumForPoint(offsetX, offsetY);
    const pointerOverLegendDatum = pointerInsideLegend && datum !== void 0;
    if (!pointerOverLegendDatum) {
      this.ctx.cursorManager.updateCursor(this.id);
      this.ctx.highlightManager.updateHighlight(this.id);
      return;
    }
    const series = datum ? this.ctx.chartService.series.find((series2) => series2.id === (datum == null ? void 0 : datum.id)) : void 0;
    if (datum && this.truncatedItems.has((_a = datum.itemId) != null ? _a : datum.id)) {
      this.ctx.tooltipManager.updateTooltip(
        this.id,
        { pageX, pageY, offsetX, offsetY, event, showArrow: false, addCustomClass: false },
        toTooltipHtml$2({ content: this.getItemLabel(datum) })
      );
    } else {
      this.ctx.tooltipManager.removeTooltip(this.id);
    }
    if (toggleSeriesVisible || listeners.legendItemClick != null || listeners.legendItemDoubleClick != null) {
      this.ctx.cursorManager.updateCursor(this.id, "pointer");
    }
    if ((datum == null ? void 0 : datum.enabled) && series) {
      this.ctx.highlightManager.updateHighlight(this.id, {
        series,
        itemId: datum == null ? void 0 : datum.itemId,
        datum: void 0
      });
    } else {
      this.ctx.highlightManager.updateHighlight(this.id);
    }
  }
  positionLegend(shrinkRect) {
    const newShrinkRect = shrinkRect.clone();
    if (!this.enabled || !this.data.length) {
      return { shrinkRect: newShrinkRect };
    }
    const [legendWidth, legendHeight] = this.calculateLegendDimensions(shrinkRect);
    this.group.translationX = 0;
    this.group.translationY = 0;
    this.performLayout(legendWidth, legendHeight);
    const legendBBox = this.computePagedBBox();
    const calculateTranslationPerpendicularDimension = () => {
      switch (this.position) {
        case "top":
        case "left":
          return 0;
        case "bottom":
          return shrinkRect.height - legendBBox.height;
        case "right":
        default:
          return shrinkRect.width - legendBBox.width;
      }
    };
    if (this.visible) {
      let translationX;
      let translationY;
      switch (this.position) {
        case "top":
        case "bottom":
          translationX = (shrinkRect.width - legendBBox.width) / 2;
          translationY = calculateTranslationPerpendicularDimension();
          newShrinkRect.shrink(legendBBox.height, this.position);
          break;
        case "left":
        case "right":
        default:
          translationX = calculateTranslationPerpendicularDimension();
          translationY = (shrinkRect.height - legendBBox.height) / 2;
          newShrinkRect.shrink(legendBBox.width, this.position);
      }
      this.group.translationX = Math.floor(-legendBBox.x + shrinkRect.x + translationX);
      this.group.translationY = Math.floor(-legendBBox.y + shrinkRect.y + translationY);
    }
    if (this.visible && this.enabled && this.data.length) {
      const legendPadding = this.spacing;
      newShrinkRect.shrink(legendPadding, this.position);
      const legendPositionedBBox = legendBBox.clone();
      legendPositionedBBox.x += this.group.translationX;
      legendPositionedBBox.y += this.group.translationY;
      this.ctx.tooltipManager.updateExclusiveRect(this.id, legendPositionedBBox);
    } else {
      this.ctx.tooltipManager.updateExclusiveRect(this.id);
    }
    return { shrinkRect: newShrinkRect };
  }
  calculateLegendDimensions(shrinkRect) {
    const { width, height } = shrinkRect;
    const aspectRatio = width / height;
    const maxCoefficient = 0.5;
    const minHeightCoefficient = 0.2;
    const minWidthCoefficient = 0.25;
    let legendWidth, legendHeight;
    switch (this.position) {
      case "top":
      case "bottom":
        const heightCoefficient = aspectRatio < 1 ? Math.min(maxCoefficient, minHeightCoefficient * (1 / aspectRatio)) : minHeightCoefficient;
        legendWidth = this.maxWidth ? Math.min(this.maxWidth, width) : width;
        legendHeight = this.maxHeight ? Math.min(this.maxHeight, height) : Math.round(height * heightCoefficient);
        break;
      case "left":
      case "right":
      default:
        const widthCoefficient = aspectRatio > 1 ? Math.min(maxCoefficient, minWidthCoefficient * aspectRatio) : minWidthCoefficient;
        legendWidth = this.maxWidth ? Math.min(this.maxWidth, width) : Math.round(width * widthCoefficient);
        legendHeight = this.maxHeight ? Math.min(this.maxHeight, height) : height;
    }
    return [legendWidth, legendHeight];
  }
};
Legend$1.className = "Legend";
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], Legend$1.prototype, "_enabled", 2);
__decorateClass$1([
  Validate$1(POSITION$1)
], Legend$1.prototype, "position", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], Legend$1.prototype, "maxWidth", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], Legend$1.prototype, "maxHeight", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1, { optional: true })
], Legend$1.prototype, "reverseOrder", 2);
__decorateClass$1([
  Validate$1(UNION$1(["horizontal", "vertical"], "an orientation"), { optional: true })
], Legend$1.prototype, "orientation", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1, { optional: true })
], Legend$1.prototype, "preventHidingAll", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], Legend$1.prototype, "spacing", 2);

// packages/ag-charts-community/src/chart/overlay/overlay.ts
var Overlay$1 = class {
  constructor(className, parentElement) {
    this.className = className;
    this.parentElement = parentElement;
  }
  show(rect) {
    var _a, _b;
    if (!this.element) {
      this.element = this.createElement("div");
      this.element.className = this.className;
    }
    const { element: element2 } = this;
    element2.style.position = "absolute";
    element2.style.left = `${rect.x}px`;
    element2.style.top = `${rect.y}px`;
    element2.style.width = `${rect.width}px`;
    element2.style.height = `${rect.height}px`;
    if (this.renderer) {
      element2.innerHTML = this.renderer();
    } else {
      const content = this.createElement("div");
      content.style.alignItems = "center";
      content.style.boxSizing = "border-box";
      content.style.display = "flex";
      content.style.justifyContent = "center";
      content.style.margin = "8px";
      content.style.height = "100%";
      content.style.font = "12px Verdana, sans-serif";
      content.innerText = (_a = this.text) != null ? _a : "No data to display";
      element2.replaceChildren(content);
    }
    (_b = this.parentElement) == null ? void 0 : _b.append(element2);
  }
  hide() {
    var _a;
    (_a = this.element) == null ? void 0 : _a.remove();
    this.element = void 0;
  }
  createElement(tagName, options) {
    return this.parentElement.ownerDocument.createElement(tagName, options);
  }
};
__decorateClass$1([
  Validate$1(FUNCTION$1, { optional: true })
], Overlay$1.prototype, "renderer", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], Overlay$1.prototype, "text", 2);

// packages/ag-charts-community/src/chart/overlay/chartOverlays.ts
var ChartOverlays$1 = class {
  constructor(parent) {
    this.noData = new Overlay$1("ag-chart-no-data-overlay", parent);
    this.noVisibleSeries = new Overlay$1("ag-chart-no-visible-series", parent);
    this.noVisibleSeries.text = "No visible series";
  }
  destroy() {
    this.noData.hide();
    this.noVisibleSeries.hide();
  }
};

// packages/ag-charts-community/src/util/object.ts
function deepMerge$2(target, source) {
  if (isPlainObject2$1(target) && isPlainObject2$1(source)) {
    const result = {};
    Object.keys(target).forEach((key) => {
      if (key in source) {
        result[key] = deepMerge$2(target[key], source[key]);
      } else {
        result[key] = target[key];
      }
    });
    Object.keys(source).forEach((key) => {
      if (!(key in target)) {
        result[key] = source[key];
      }
    });
    return result;
  }
  if (Array.isArray(target) && !Array.isArray(source) || isObjectLike2$1(target) && !isObjectLike2$1(source)) {
    return target;
  }
  return source;
}
function isObjectLike2$1(value) {
  return typeof value === "object" && value !== null;
}
function isObject2$1(value) {
  return isObjectLike2$1(value) && !Array.isArray(value);
}
function isPlainObject2$1(x) {
  return isObject2$1(x) && x.constructor === Object;
}
function mergeDefaults$1(...sources) {
  var _a;
  const target = {};
  for (const source of sources) {
    if (!source)
      continue;
    const keys = isDecoratedObject$1(source) ? listDecoratedProperties$1(source) : Object.keys(source);
    for (const key of keys) {
      if (isObject2$1(target[key]) && isObject2$1(source[key])) {
        target[key] = mergeDefaults$1(target[key], source[key]);
      } else {
        (_a = target[key]) != null ? _a : target[key] = source[key];
      }
    }
  }
  return target;
}
function partialAssign$1(keysToCopy, target, source) {
  if (source === void 0) {
    return target;
  }
  for (const key of keysToCopy) {
    const value = source[key];
    if (value !== void 0) {
      target[key] = value;
    }
  }
  return target;
}

// packages/ag-charts-community/src/chart/data/aggregateFunctions.ts
function sumValues$1(values, accumulator = [0, 0]) {
  for (const value of values) {
    if (typeof value !== "number") {
      continue;
    }
    if (value < 0) {
      accumulator[0] += value;
    }
    if (value > 0) {
      accumulator[1] += value;
    }
  }
  return accumulator;
}
function sum$1(scope, id, matchGroupId) {
  const result = {
    id,
    scopes: [scope.id],
    matchGroupIds: [matchGroupId],
    type: "aggregate",
    aggregateFunction: (values) => sumValues$1(values)
  };
  return result;
}
function groupSum$1(scope, id, matchGroupId) {
  return {
    id,
    scopes: [scope.id],
    type: "aggregate",
    matchGroupIds: matchGroupId ? [matchGroupId] : void 0,
    aggregateFunction: (values) => sumValues$1(values),
    groupAggregateFunction: (next, acc = [0, 0]) => {
      var _a, _b;
      acc[0] += (_a = next == null ? void 0 : next[0]) != null ? _a : 0;
      acc[1] += (_b = next == null ? void 0 : next[1]) != null ? _b : 0;
      return acc;
    }
  };
}
function range2$1(scope, id, matchGroupId) {
  const result = {
    id,
    scopes: [scope.id],
    matchGroupIds: [matchGroupId],
    type: "aggregate",
    aggregateFunction: (values) => extendDomain$1(values)
  };
  return result;
}
function count$1(scope, id) {
  const result = {
    id,
    scopes: [scope.id],
    type: "aggregate",
    aggregateFunction: () => [0, 1]
  };
  return result;
}
function groupCount$1(scope, id) {
  return {
    id,
    scopes: [scope.id],
    type: "aggregate",
    aggregateFunction: () => [0, 1],
    groupAggregateFunction: (next, acc = [0, 0]) => {
      var _a, _b;
      acc[0] += (_a = next == null ? void 0 : next[0]) != null ? _a : 0;
      acc[1] += (_b = next == null ? void 0 : next[1]) != null ? _b : 0;
      return acc;
    }
  };
}
function average$1(scope, id, matchGroupId) {
  const result = {
    id,
    scopes: [scope.id],
    matchGroupIds: [matchGroupId],
    type: "aggregate",
    aggregateFunction: (values) => sumValues$1(values).map((v) => v / values.length)
  };
  return result;
}
function groupAverage$1(scope, id, matchGroupId) {
  const result = {
    id,
    scopes: [scope.id],
    matchGroupIds: matchGroupId ? [matchGroupId] : void 0,
    type: "aggregate",
    aggregateFunction: (values) => sumValues$1(values),
    groupAggregateFunction: (next, acc = [0, 0, -1]) => {
      var _a, _b;
      acc[0] += (_a = next == null ? void 0 : next[0]) != null ? _a : 0;
      acc[1] += (_b = next == null ? void 0 : next[1]) != null ? _b : 0;
      acc[2]++;
      return acc;
    },
    finalFunction: (acc = [0, 0, 0]) => {
      const result2 = acc[0] + acc[1];
      if (result2 >= 0) {
        return [0, result2 / acc[2]];
      }
      return [result2 / acc[2], 0];
    }
  };
  return result;
}
function area$1(scope, id, aggFn, matchGroupId) {
  const result = {
    id,
    scopes: [scope.id],
    matchGroupIds: matchGroupId ? [matchGroupId] : void 0,
    type: "aggregate",
    aggregateFunction: (values, keyRange = []) => {
      const keyWidth = keyRange[1] - keyRange[0];
      return aggFn.aggregateFunction(values).map((v) => v / keyWidth);
    }
  };
  if (aggFn.groupAggregateFunction) {
    result.groupAggregateFunction = aggFn.groupAggregateFunction;
  }
  return result;
}
function accumulatedValue$1(onlyPositive) {
  return () => {
    let value = 0;
    return (datum) => {
      if (typeof datum !== "number")
        return datum;
      if (isNaN(datum))
        return datum;
      value += onlyPositive ? Math.max(0, datum) : datum;
      return value;
    };
  };
}
function trailingAccumulatedValue$1() {
  return () => {
    let value = 0;
    return (datum) => {
      if (typeof datum !== "number")
        return datum;
      if (isNaN(datum))
        return datum;
      const trailingValue2 = value;
      value += datum;
      return trailingValue2;
    };
  };
}

// packages/ag-charts-community/src/util/memo.ts
var memorizedFns$1 = /* @__PURE__ */ new Map();
function memo$1(params, fnGenerator) {
  var _a, _b, _c;
  const serialisedParams = JSON.stringify(params, null, 0);
  if (!memorizedFns$1.has(fnGenerator)) {
    memorizedFns$1.set(fnGenerator, /* @__PURE__ */ new Map());
  }
  if (!((_a = memorizedFns$1.get(fnGenerator)) == null ? void 0 : _a.has(serialisedParams))) {
    (_b = memorizedFns$1.get(fnGenerator)) == null ? void 0 : _b.set(serialisedParams, fnGenerator(params));
  }
  return (_c = memorizedFns$1.get(fnGenerator)) == null ? void 0 : _c.get(serialisedParams);
}

// packages/ag-charts-community/src/chart/data/processors.ts
var SMALLEST_KEY_INTERVAL$1 = {
  type: "reducer",
  property: "smallestKeyInterval",
  initialValue: Infinity,
  reducer: () => {
    let prevX = NaN;
    return (smallestSoFar = Infinity, next) => {
      const nextX = next.keys[0];
      const interval = Math.abs(nextX - prevX);
      prevX = nextX;
      if (!isNaN(interval) && interval > 0 && interval < smallestSoFar) {
        return interval;
      }
      return smallestSoFar;
    };
  }
};
var AGG_VALUES_EXTENT$1 = {
  type: "processor",
  property: "aggValuesExtent",
  calculate: (processedData) => {
    var _a, _b, _c, _d;
    const result = [...(_b = (_a = processedData.domain.aggValues) == null ? void 0 : _a[0]) != null ? _b : [0, 0]];
    for (const [min, max] of (_d = (_c = processedData.domain.aggValues) == null ? void 0 : _c.slice(1)) != null ? _d : []) {
      if (min < result[0]) {
        result[0] = min;
      }
      if (max > result[1]) {
        result[1] = max;
      }
    }
    return result;
  }
};
var SORT_DOMAIN_GROUPS$1 = {
  type: "processor",
  property: "sortedGroupDomain",
  calculate: ({ domain: { groups } }) => {
    if (groups == null)
      return void 0;
    return [...groups].sort((a, b) => {
      for (let i = 0; i < a.length; i++) {
        const result = a[i] - b[i];
        if (result !== 0) {
          return result;
        }
      }
      return 0;
    });
  }
};
function normaliseFnBuilder$1({ normaliseTo, mode }) {
  const normalise = (val, extent2) => {
    const result = val * normaliseTo / extent2;
    if (result >= 0) {
      return Math.min(normaliseTo, result);
    }
    return Math.max(-normaliseTo, result);
  };
  return () => () => (values, valueIndexes) => {
    const valuesExtent = [0, 0];
    for (const valueIdx of valueIndexes) {
      const value = values[valueIdx];
      const valIdx = value < 0 ? 0 : 1;
      if (mode === "sum") {
        valuesExtent[valIdx] += value;
      } else if (valIdx === 0) {
        valuesExtent[valIdx] = Math.min(valuesExtent[valIdx], value);
      } else {
        valuesExtent[valIdx] = Math.max(valuesExtent[valIdx], value);
      }
    }
    const extent2 = Math.max(Math.abs(valuesExtent[0]), valuesExtent[1]);
    for (const valueIdx of valueIndexes) {
      values[valueIdx] = normalise(values[valueIdx], extent2);
    }
  };
}
function normaliseGroupTo$1(scope, matchGroupIds, normaliseTo, mode = "sum") {
  return {
    scopes: [scope.id],
    type: "group-value-processor",
    matchGroupIds,
    adjust: memo$1({ normaliseTo, mode }, normaliseFnBuilder$1)
  };
}
function normalisePropertyFnBuilder$1({
  normaliseTo,
  zeroDomain,
  rangeMin,
  rangeMax
}) {
  const normaliseSpan = normaliseTo[1] - normaliseTo[0];
  const normalise = (val, start, span) => {
    const result = normaliseTo[0] + (val - start) / span * normaliseSpan;
    if (span === 0)
      return zeroDomain;
    if (result >= normaliseTo[1])
      return normaliseTo[1];
    if (result < normaliseTo[0])
      return normaliseTo[0];
    return result;
  };
  return () => (pData, pIdx) => {
    let [start, end] = pData.domain.values[pIdx];
    if (rangeMin != null)
      start = rangeMin;
    if (rangeMax != null)
      end = rangeMax;
    const span = end - start;
    pData.domain.values[pIdx] = [normaliseTo[0], normaliseTo[1]];
    for (const group2 of pData.data) {
      let groupValues = group2.values;
      if (pData.type === "ungrouped") {
        groupValues = [groupValues];
      }
      for (const values of groupValues) {
        values[pIdx] = normalise(values[pIdx], start, span);
      }
    }
  };
}
function normalisePropertyTo$1(scope, property, normaliseTo, zeroDomain, rangeMin, rangeMax) {
  return {
    scopes: [scope.id],
    type: "property-value-processor",
    property,
    adjust: memo$1({ normaliseTo, rangeMin, rangeMax, zeroDomain }, normalisePropertyFnBuilder$1)
  };
}
function animationValidation$1(scope, valueKeyIds = []) {
  return {
    type: "processor",
    scopes: [scope.id],
    property: "animationValidation",
    calculate(result) {
      var _a;
      const { keys, values } = result.defs;
      const { input, data } = result;
      let uniqueKeys = true;
      let orderedKeys = true;
      const valueKeys = [];
      for (let k = 0; k < values.length; k++) {
        if (!((_a = values[k].scopes) == null ? void 0 : _a.some((s) => s === scope.id)))
          continue;
        if (!valueKeyIds.some((v) => values[k].id === v))
          continue;
        valueKeys.push([k, values[k]]);
      }
      const processKey = (idx, def, type) => {
        var _a2;
        if (def.valueType === "category") {
          const keyValues = result.domain[type][idx];
          uniqueKeys && (uniqueKeys = keyValues.length === input.count);
          return;
        }
        let lastValue = (_a2 = data[0]) == null ? void 0 : _a2[type][idx];
        for (let d = 1; (uniqueKeys || orderedKeys) && d < data.length; d++) {
          const keyValue = data[d][type][idx];
          orderedKeys && (orderedKeys = lastValue <= keyValue);
          uniqueKeys && (uniqueKeys = lastValue !== keyValue);
          lastValue = keyValue;
        }
      };
      for (let k = 0; (uniqueKeys || orderedKeys) && k < keys.length; k++) {
        processKey(k, keys[k], "keys");
      }
      for (let k = 0; (uniqueKeys || orderedKeys) && k < valueKeys.length; k++) {
        const [idx, key] = valueKeys[k];
        processKey(idx, key, "values");
      }
      return { uniqueKeys, orderedKeys };
    }
  };
}
function buildGroupAccFn$1({ mode, separateNegative }) {
  return () => () => (values, valueIndexes) => {
    const acc = [0, 0];
    for (const valueIdx of valueIndexes) {
      const currentVal = values[valueIdx];
      const accIndex = isNegative$1(currentVal) && separateNegative ? 0 : 1;
      if (typeof currentVal !== "number" || isNaN(currentVal))
        continue;
      if (mode === "normal")
        acc[accIndex] += currentVal;
      values[valueIdx] = acc[accIndex];
      if (mode === "trailing")
        acc[accIndex] += currentVal;
    }
  };
}
function buildGroupWindowAccFn$1({ mode, sum: sum2 }) {
  return () => {
    const lastValues = [];
    let firstRow = true;
    return () => {
      return (values, valueIndexes) => {
        let acc = 0;
        for (const valueIdx of valueIndexes) {
          const currentVal = values[valueIdx];
          const lastValue = firstRow && sum2 === "current" ? 0 : lastValues[valueIdx];
          lastValues[valueIdx] = currentVal;
          const sumValue = sum2 === "current" ? currentVal : lastValue;
          if (typeof currentVal !== "number" || isNaN(currentVal)) {
            values[valueIdx] = acc;
            continue;
          }
          if (typeof lastValue !== "number" || isNaN(lastValue)) {
            values[valueIdx] = acc;
            continue;
          }
          if (mode === "normal")
            acc += sumValue;
          values[valueIdx] = acc;
          if (mode === "trailing")
            acc += sumValue;
        }
        firstRow = false;
      };
    };
  };
}
function accumulateGroup$1(scope, matchGroupId, mode, sum2, separateNegative = false) {
  let adjust;
  if (mode.startsWith("window")) {
    const modeParam = mode.endsWith("-trailing") ? "trailing" : "normal";
    adjust = memo$1({ mode: modeParam, sum: sum2 }, buildGroupWindowAccFn$1);
  } else {
    adjust = memo$1({ mode, separateNegative }, buildGroupAccFn$1);
  }
  return {
    scopes: [scope.id],
    type: "group-value-processor",
    matchGroupIds: [matchGroupId],
    adjust
  };
}
function diff$1(previousData, updateMovedDatums = true) {
  return {
    type: "processor",
    property: "diff",
    calculate: (processedData) => {
      const diff2 = {
        changed: false,
        moved: [],
        added: [],
        updated: [],
        removed: [],
        addedIndices: [],
        updatedIndices: [],
        removedIndices: []
      };
      const moved = /* @__PURE__ */ new Map();
      const added = /* @__PURE__ */ new Map();
      const updated = /* @__PURE__ */ new Map();
      const removed = /* @__PURE__ */ new Map();
      const addedIndices = /* @__PURE__ */ new Map();
      const updatedIndices = /* @__PURE__ */ new Map();
      const removedIndices = /* @__PURE__ */ new Map();
      for (let i = 0; i < Math.max(previousData.data.length, processedData.data.length); i++) {
        const prev = previousData.data[i];
        const datum = processedData.data[i];
        const prevId = prev ? createDatumId$1(prev.keys) : "";
        const datumId = datum ? createDatumId$1(datum.keys) : "";
        if (prevId === datumId) {
          if (!arraysEqual$1(prev.values, datum.values)) {
            updated.set(datumId, datum);
            updatedIndices.set(datumId, i);
          }
          continue;
        }
        if (removed.has(datumId)) {
          if (updateMovedDatums || !arraysEqual$1(removed.get(datumId).values, datum.values)) {
            updated.set(datumId, datum);
            updatedIndices.set(datumId, i);
            moved.set(datumId, datum);
          }
          removed.delete(datumId);
          removedIndices.delete(datumId);
        } else if (datum) {
          added.set(datumId, datum);
          addedIndices.set(datumId, i);
        }
        if (added.has(prevId)) {
          if (updateMovedDatums || !arraysEqual$1(added.get(prevId).values, prev.values)) {
            updated.set(prevId, prev);
            updatedIndices.set(prevId, i);
            moved.set(prevId, prev);
          }
          added.delete(prevId);
          addedIndices.delete(prevId);
        } else if (prev) {
          updated.delete(prevId);
          updatedIndices.delete(prevId);
          removed.set(prevId, prev);
          removedIndices.set(prevId, i);
        }
      }
      diff2.added = Array.from(added.keys());
      diff2.updated = Array.from(updated.keys());
      diff2.removed = Array.from(removed.keys());
      diff2.moved = Array.from(moved.keys());
      diff2.addedIndices = Array.from(addedIndices.values());
      diff2.updatedIndices = Array.from(updatedIndices.values());
      diff2.removedIndices = Array.from(removedIndices.values());
      diff2.changed = diff2.added.length > 0 || diff2.updated.length > 0 || diff2.removed.length > 0;
      return diff2;
    }
  };
}
function createDatumId$1(keys) {
  return keys.join("___");
}

// packages/ag-charts-community/src/chart/series/series.ts
var SeriesNodePickMode$1 = /* @__PURE__ */ ((SeriesNodePickMode2) => {
  SeriesNodePickMode2[SeriesNodePickMode2["EXACT_SHAPE_MATCH"] = 0] = "EXACT_SHAPE_MATCH";
  SeriesNodePickMode2[SeriesNodePickMode2["NEAREST_BY_MAIN_AXIS_FIRST"] = 1] = "NEAREST_BY_MAIN_AXIS_FIRST";
  SeriesNodePickMode2[SeriesNodePickMode2["NEAREST_BY_MAIN_CATEGORY_AXIS_FIRST"] = 2] = "NEAREST_BY_MAIN_CATEGORY_AXIS_FIRST";
  SeriesNodePickMode2[SeriesNodePickMode2["NEAREST_NODE"] = 3] = "NEAREST_NODE";
  return SeriesNodePickMode2;
})(SeriesNodePickMode$1 || {});
function basicContinuousCheckDatumValidation$1(v) {
  return checkDatum$1(v, true) != null;
}
function basicDiscreteCheckDatumValidation$1(v) {
  return checkDatum$1(v, false) != null;
}
function keyProperty$1(scope, propName, continuous, opts = {}) {
  const result = __spreadValues$1({
    scopes: [scope.id],
    property: propName,
    type: "key",
    valueType: continuous ? "range" : "category",
    validation: continuous ? basicContinuousCheckDatumValidation$1 : basicDiscreteCheckDatumValidation$1
  }, opts);
  return result;
}
function valueProperty$1(scope, propName, continuous, opts = {}) {
  const result = __spreadValues$1({
    scopes: [scope.id],
    property: propName,
    type: "value",
    valueType: continuous ? "range" : "category",
    validation: continuous ? basicContinuousCheckDatumValidation$1 : basicDiscreteCheckDatumValidation$1
  }, opts);
  return result;
}
function rangedValueProperty$1(scope, propName, opts = {}) {
  const _a = opts, { min = -Infinity, max = Infinity } = _a, defOpts = __objRest$1(_a, ["min", "max"]);
  return __spreadValues$1({
    scopes: [scope.id],
    type: "value",
    property: propName,
    valueType: "range",
    validation: basicContinuousCheckDatumValidation$1,
    processor: () => (datum) => {
      if (typeof datum !== "number")
        return datum;
      if (isNaN(datum))
        return datum;
      return Math.min(Math.max(datum, min), max);
    }
  }, defOpts);
}
function trailingValueProperty$1(scope, propName, continuous, opts = {}) {
  const result = __spreadProps$1(__spreadValues$1({}, valueProperty$1(scope, propName, continuous, opts)), {
    processor: trailingValue$1()
  });
  return result;
}
function trailingValue$1() {
  return () => {
    let value = 0;
    return (datum) => {
      const trailingValue2 = value;
      value = datum;
      return trailingValue2;
    };
  };
}
function accumulativeValueProperty$1(scope, propName, continuous, opts = {}) {
  const _a = opts, { onlyPositive } = _a, defOpts = __objRest$1(_a, ["onlyPositive"]);
  const result = __spreadProps$1(__spreadValues$1({}, valueProperty$1(scope, propName, continuous, defOpts)), {
    processor: accumulatedValue$1(onlyPositive)
  });
  return result;
}
function trailingAccumulatedValueProperty$1(scope, propName, continuous, opts = {}) {
  const result = __spreadProps$1(__spreadValues$1({}, valueProperty$1(scope, propName, continuous, opts)), {
    processor: trailingAccumulatedValue$1()
  });
  return result;
}
function groupAccumulativeValueProperty$1(scope, propName, continuous, mode, sum2 = "current", opts) {
  return [
    valueProperty$1(scope, propName, continuous, opts),
    accumulateGroup$1(scope, opts.groupId, mode, sum2, opts.separateNegative),
    ...opts.rangeId != null ? [range2$1(scope, opts.rangeId, opts.groupId)] : []
  ];
}
var SeriesNodeClickEvent$1 = class {
  constructor(type, event, { datum }, series) {
    this.type = type;
    this.event = event;
    this.datum = datum;
    this.seriesId = series.id;
  }
};
var Series$1 = class extends Observable$1 {
  constructor(seriesOpts) {
    super();
    this.destroyFns = [];
    this.seriesGrouping = void 0;
    this.NodeClickEvent = SeriesNodeClickEvent$1;
    this.internalId = createId$2(this);
    // The group node that contains all the nodes used to render this series.
    this.rootGroup = new Group$1({ name: "seriesRoot", isVirtual: true });
    this.axes = {
      ["x" /* X */]: void 0,
      ["y" /* Y */]: void 0
    };
    this.directions = ["x" /* X */, "y" /* Y */];
    // Flag to determine if we should recalculate node data.
    this.nodeDataRefresh = true;
    this.moduleMap = new ModuleMap$1();
    this._declarationOrder = -1;
    this.seriesListeners = new Listeners$1();
    const {
      moduleCtx,
      useLabelLayer = false,
      pickModes = [1 /* NEAREST_BY_MAIN_AXIS_FIRST */],
      directionKeys = {},
      directionNames = {},
      contentGroupVirtual = true,
      canHaveAxes = false
    } = seriesOpts;
    this.ctx = moduleCtx;
    this.directionKeys = directionKeys;
    this.directionNames = directionNames;
    this.canHaveAxes = canHaveAxes;
    this.contentGroup = this.rootGroup.appendChild(
      new Group$1({
        name: `${this.internalId}-content`,
        layer: !contentGroupVirtual,
        isVirtual: contentGroupVirtual,
        zIndex: 4 /* SERIES_LAYER_ZINDEX */,
        zIndexSubOrder: this.getGroupZIndexSubOrder("data")
      })
    );
    this.highlightGroup = new Group$1({
      name: `${this.internalId}-highlight`,
      layer: !contentGroupVirtual,
      isVirtual: contentGroupVirtual,
      zIndex: 4 /* SERIES_LAYER_ZINDEX */,
      zIndexSubOrder: this.getGroupZIndexSubOrder("highlight")
    });
    this.highlightNode = this.highlightGroup.appendChild(new Group$1({ name: "highlightNode", zIndex: 0 }));
    this.highlightLabel = this.highlightGroup.appendChild(new Group$1({ name: "highlightLabel", zIndex: 10 }));
    this.pickModes = pickModes;
    this.labelGroup = this.rootGroup.appendChild(
      new Group$1({
        name: `${this.internalId}-series-labels`,
        layer: useLabelLayer,
        zIndex: 7 /* SERIES_LABEL_ZINDEX */
      })
    );
    this.annotationGroup = new Group$1({
      name: `${this.id}-annotation`,
      layer: !contentGroupVirtual,
      isVirtual: contentGroupVirtual,
      zIndex: 4 /* SERIES_LAYER_ZINDEX */,
      zIndexSubOrder: this.getGroupZIndexSubOrder("annotation")
    });
  }
  get id() {
    var _a, _b;
    return (_b = (_a = this.properties) == null ? void 0 : _a.id) != null ? _b : this.internalId;
  }
  get type() {
    var _a;
    return (_a = this.constructor.type) != null ? _a : "";
  }
  set data(input) {
    this._data = input;
    this.onDataChange();
  }
  get data() {
    var _a;
    return (_a = this._data) != null ? _a : this._chartData;
  }
  set visible(value) {
    this.properties.visible = value;
    this.visibleChanged();
  }
  get visible() {
    return this.properties.visible;
  }
  onDataChange() {
    this.nodeDataRefresh = true;
  }
  setChartData(input) {
    this._chartData = input;
    if (this.data === input) {
      this.onDataChange();
    }
  }
  hasData() {
    const { data } = this;
    return data && (!Array.isArray(data) || data.length > 0);
  }
  onSeriesGroupingChange(prev, next) {
    const { internalId, type, visible, rootGroup, highlightGroup, annotationGroup } = this;
    if (prev) {
      this.ctx.seriesStateManager.deregisterSeries({ id: internalId, type });
    }
    if (next) {
      this.ctx.seriesStateManager.registerSeries({ id: internalId, type, visible, seriesGrouping: next });
    }
    if (this.rootGroup.parent == null)
      return;
    this.ctx.seriesLayerManager.changeGroup({
      internalId,
      type,
      rootGroup,
      highlightGroup,
      annotationGroup,
      getGroupZIndexSubOrder: (type2) => this.getGroupZIndexSubOrder(type2),
      seriesGrouping: next,
      oldGrouping: prev
    });
  }
  getBandScalePadding() {
    return { inner: 1, outer: 0 };
  }
  getGroupZIndexSubOrder(type, subIndex = 0) {
    let mainAdjust = 0;
    switch (type) {
      case "data":
      case "paths":
        break;
      case "labels":
        mainAdjust += 2e4;
        break;
      case "marker":
        mainAdjust += 1e4;
        break;
      case "highlight":
        subIndex += 15e3;
        break;
      case "annotation":
        mainAdjust += 15e3;
        break;
    }
    const main = () => this._declarationOrder + mainAdjust;
    return [main, subIndex];
  }
  addListener(type, listener) {
    return this.seriesListeners.addListener(type, listener);
  }
  dispatch(type, event) {
    this.seriesListeners.dispatch(type, event);
  }
  addChartEventListeners() {
    return;
  }
  destroy() {
    this.destroyFns.forEach((f) => f());
    this.ctx.seriesStateManager.deregisterSeries(this);
    this.ctx.seriesLayerManager.releaseGroup(this);
  }
  getDirectionValues(direction, properties) {
    const resolvedDirection = this.resolveKeyDirection(direction);
    const keys = properties == null ? void 0 : properties[resolvedDirection];
    const values = [];
    if (!keys)
      return values;
    const addValues = (...items) => {
      for (const value of items) {
        if (Array.isArray(value)) {
          addValues(...value);
        } else if (typeof value === "object") {
          addValues(...Object.values(value));
        } else {
          values.push(value);
        }
      }
    };
    addValues(...keys.map((key) => this.properties[key]));
    return values;
  }
  getKeys(direction) {
    return this.getDirectionValues(direction, this.directionKeys);
  }
  getNames(direction) {
    return this.getDirectionValues(direction, this.directionNames);
  }
  resolveKeyDirection(direction) {
    return direction;
  }
  // The union of the series domain ('community') and series-option domains ('enterprise').
  getDomain(direction) {
    const seriesDomain = this.getSeriesDomain(direction);
    const moduleDomains = this.moduleMap.mapValues((module) => module.getDomain(direction));
    return seriesDomain.concat(moduleDomains.flat());
  }
  // Indicate that something external changed and we should recalculate nodeData.
  markNodeDataDirty() {
    this.nodeDataRefresh = true;
  }
  visibleChanged() {
    this.ctx.seriesStateManager.registerSeries(this);
  }
  getOpacity() {
    const defaultOpacity = 1;
    const { dimOpacity = 1, enabled = true } = this.properties.highlightStyle.series;
    if (!enabled || dimOpacity === defaultOpacity) {
      return defaultOpacity;
    }
    switch (this.isItemIdHighlighted()) {
      case 0 /* None */:
      case 1 /* This */:
        return defaultOpacity;
      case 2 /* Other */:
      default:
        return dimOpacity;
    }
  }
  getStrokeWidth(defaultStrokeWidth) {
    const { strokeWidth, enabled = true } = this.properties.highlightStyle.series;
    if (!enabled || strokeWidth === void 0) {
      return defaultStrokeWidth;
    }
    switch (this.isItemIdHighlighted()) {
      case 1 /* This */:
        return strokeWidth;
      case 0 /* None */:
      case 2 /* Other */:
        return defaultStrokeWidth;
    }
  }
  isItemIdHighlighted() {
    var _a, _b;
    const { series } = (_b = (_a = this.ctx.highlightManager) == null ? void 0 : _a.getActiveHighlight()) != null ? _b : {};
    if (series == null) {
      return 0 /* None */;
    }
    if (series !== this) {
      return 2 /* Other */;
    }
    return 1 /* This */;
  }
  getModuleTooltipParams() {
    const params = this.moduleMap.mapValues((module) => module.getTooltipParams());
    return params.reduce((total, current) => __spreadValues$1(__spreadValues$1({}, current), total), {});
  }
  pickNode(point, limitPickModes) {
    const { pickModes, visible, rootGroup } = this;
    if (!visible || !rootGroup.visible) {
      return;
    }
    for (const pickMode of pickModes) {
      if (limitPickModes && !limitPickModes.includes(pickMode)) {
        continue;
      }
      let match;
      switch (pickMode) {
        case 0 /* EXACT_SHAPE_MATCH */:
          match = this.pickNodeExactShape(point);
          break;
        case 1 /* NEAREST_BY_MAIN_AXIS_FIRST */:
        case 2 /* NEAREST_BY_MAIN_CATEGORY_AXIS_FIRST */:
          match = this.pickNodeMainAxisFirst(
            point,
            pickMode === 2 /* NEAREST_BY_MAIN_CATEGORY_AXIS_FIRST */
          );
          break;
        case 3 /* NEAREST_NODE */:
          match = this.pickNodeClosestDatum(point);
          break;
      }
      if (match) {
        return { pickMode, match: match.datum, distance: match.distance };
      }
    }
  }
  pickNodeExactShape(point) {
    const match = this.contentGroup.pickNode(point.x, point.y);
    return match && { datum: match.datum, distance: 0 };
  }
  pickNodeClosestDatum(_point) {
    throw new Error("AG Charts - Series.pickNodeClosestDatum() not implemented");
  }
  pickNodeMainAxisFirst(_point, _requireCategoryAxis) {
    throw new Error("AG Charts - Series.pickNodeMainAxisFirst() not implemented");
  }
  fireNodeClickEvent(event, datum) {
    this.fireEvent(new this.NodeClickEvent("nodeClick", event, datum, this));
  }
  fireNodeDoubleClickEvent(event, datum) {
    this.fireEvent(new this.NodeClickEvent("nodeDoubleClick", event, datum, this));
  }
  toggleSeriesItem(itemId, enabled) {
    this.visible = enabled;
    this.nodeDataRefresh = true;
    this.dispatch("visibility-changed", { itemId, enabled });
  }
  isEnabled() {
    return this.visible;
  }
  getModuleMap() {
    return this.moduleMap;
  }
  createModuleContext() {
    return __spreadProps$1(__spreadValues$1({}, this.ctx), { series: this });
  }
  getLabelText(label, params, defaultFormatter = String) {
    var _a;
    if (label.formatter) {
      return (_a = this.ctx.callbackCache.call(label.formatter, __spreadValues$1({ seriesId: this.id }, params))) != null ? _a : defaultFormatter(params.value);
    }
    return defaultFormatter(params.value);
  }
  getMarkerStyle(marker, params, defaultStyle = marker.getStyle()) {
    var _a, _b;
    const defaultSize = { size: (_b = (_a = params.datum.point) == null ? void 0 : _a.size) != null ? _b : 0 };
    const markerStyle = mergeDefaults$1(defaultSize, defaultStyle);
    if (marker.formatter) {
      const style = this.ctx.callbackCache.call(marker.formatter, __spreadProps$1(__spreadValues$1(__spreadValues$1({
        seriesId: this.id
      }, markerStyle), params), {
        datum: params.datum.datum
      }));
      return mergeDefaults$1(style, markerStyle);
    }
    return markerStyle;
  }
  updateMarkerStyle(markerNode, marker, params, defaultStyle = marker.getStyle(), { applyTranslation = true } = {}) {
    const { point } = params.datum;
    const activeStyle = this.getMarkerStyle(marker, params, defaultStyle);
    const visible = this.visible && activeStyle.size > 0 && point && !isNaN(point.x) && !isNaN(point.y);
    if (applyTranslation) {
      markerNode.setProperties(__spreadProps$1(__spreadValues$1({ visible }, activeStyle), { translationX: point == null ? void 0 : point.x, translationY: point == null ? void 0 : point.y }));
    } else {
      markerNode.setProperties(__spreadValues$1({ visible }, activeStyle));
    }
    if (typeof marker.shape === "function" && !markerNode.dirtyPath) {
      markerNode.path.clear({ trackChanges: true });
      markerNode.updatePath();
      markerNode.checkPathDirty();
    }
  }
  getMinRect() {
    return void 0;
  }
  get nodeDataDependencies() {
    var _a;
    return (_a = this._nodeDataDependencies) != null ? _a : { seriesRectWidth: NaN, seriesRectHeight: NaN };
  }
  checkResize(newSeriesRect) {
    const { width: seriesRectWidth, height: seriesRectHeight } = newSeriesRect != null ? newSeriesRect : { width: NaN, height: NaN };
    const newNodeDataDependencies = newSeriesRect ? { seriesRectWidth, seriesRectHeight } : void 0;
    const resize = jsonDiff$1(this.nodeDataDependencies, newNodeDataDependencies) != null;
    if (resize) {
      this._nodeDataDependencies = newNodeDataDependencies;
      this.markNodeDataDirty();
    }
    return resize;
  }
};
Series$1.highlightedZIndex = 1e12;
__decorateClass$1([
  ActionOnSet$1({
    changeValue: function(newVal, oldVal) {
      this.onSeriesGroupingChange(oldVal, newVal);
    }
  })
], Series$1.prototype, "seriesGrouping", 2);

// packages/ag-charts-community/src/chart/series/seriesLayerManager.ts
var SERIES_THRESHOLD_FOR_AGGRESSIVE_LAYER_REDUCTION$1 = 30;
var SeriesLayerManager$1 = class {
  constructor(rootGroup) {
    this.groups = {};
    this.series = {};
    this.expectedSeriesCount = 1;
    this.mode = "normal";
    this.rootGroup = rootGroup;
  }
  setSeriesCount(count2) {
    this.expectedSeriesCount = count2;
  }
  requestGroup(seriesConfig) {
    var _a, _b, _c, _d;
    const {
      internalId,
      type,
      rootGroup: seriesRootGroup,
      highlightGroup: seriesHighlightGroup,
      annotationGroup: seriesAnnotationGroup,
      seriesGrouping
    } = seriesConfig;
    const { groupIndex = internalId } = seriesGrouping != null ? seriesGrouping : {};
    if (this.series[internalId] != null) {
      throw new Error(`AG Charts - series already has an allocated layer: ${this.series[internalId]}`);
    }
    if (Object.keys(this.series).length === 0) {
      this.mode = this.expectedSeriesCount >= SERIES_THRESHOLD_FOR_AGGRESSIVE_LAYER_REDUCTION$1 ? "aggressive-grouping" : "normal";
    }
    (_b = (_a = this.groups)[type]) != null ? _b : _a[type] = {};
    const lookupIndex = this.lookupIdx(groupIndex);
    let groupInfo = this.groups[type][lookupIndex];
    if (!groupInfo) {
      groupInfo = (_d = (_c = this.groups[type])[lookupIndex]) != null ? _d : _c[lookupIndex] = {
        seriesIds: [],
        group: this.rootGroup.appendChild(
          new Group$1({
            name: `${type}-content`,
            layer: true,
            zIndex: 4 /* SERIES_LAYER_ZINDEX */,
            zIndexSubOrder: seriesConfig.getGroupZIndexSubOrder("data")
          })
        ),
        highlight: this.rootGroup.appendChild(
          new Group$1({
            name: `${type}-highlight`,
            layer: true,
            zIndex: 4 /* SERIES_LAYER_ZINDEX */,
            zIndexSubOrder: seriesConfig.getGroupZIndexSubOrder("highlight")
          })
        ),
        annotation: this.rootGroup.appendChild(
          new Group$1({
            name: `${type}-annotation`,
            layer: true,
            zIndex: 4 /* SERIES_LAYER_ZINDEX */,
            zIndexSubOrder: seriesConfig.getGroupZIndexSubOrder("annotation")
          })
        )
      };
    }
    this.series[internalId] = { layerState: groupInfo, seriesConfig };
    groupInfo.seriesIds.push(internalId);
    groupInfo.group.appendChild(seriesRootGroup);
    groupInfo.highlight.appendChild(seriesHighlightGroup);
    groupInfo.annotation.appendChild(seriesAnnotationGroup);
    return groupInfo.group;
  }
  changeGroup(seriesConfig) {
    var _a, _b;
    const { internalId, seriesGrouping, type, rootGroup, highlightGroup, annotationGroup, oldGrouping } = seriesConfig;
    const { groupIndex = internalId } = seriesGrouping != null ? seriesGrouping : {};
    if ((_b = (_a = this.groups[type]) == null ? void 0 : _a[groupIndex]) == null ? void 0 : _b.seriesIds.includes(internalId)) {
      return;
    }
    if (this.series[internalId] != null) {
      this.releaseGroup({
        internalId,
        seriesGrouping: oldGrouping,
        type,
        rootGroup,
        highlightGroup,
        annotationGroup
      });
    }
    this.requestGroup(seriesConfig);
  }
  releaseGroup(seriesConfig) {
    var _a, _b, _c, _d, _e;
    const { internalId, seriesGrouping, rootGroup, highlightGroup, annotationGroup, type } = seriesConfig;
    const { groupIndex = internalId } = seriesGrouping != null ? seriesGrouping : {};
    if (this.series[internalId] == null) {
      throw new Error(`AG Charts - series doesn't have an allocated layer: ${internalId}`);
    }
    const lookupIndex = this.lookupIdx(groupIndex);
    const groupInfo = (_c = (_a = this.groups[type]) == null ? void 0 : _a[lookupIndex]) != null ? _c : (_b = this.series[internalId]) == null ? void 0 : _b.layerState;
    if (groupInfo) {
      groupInfo.seriesIds = groupInfo.seriesIds.filter((v) => v !== internalId);
      groupInfo.group.removeChild(rootGroup);
      groupInfo.highlight.removeChild(highlightGroup);
      groupInfo.annotation.removeChild(annotationGroup);
    }
    if ((groupInfo == null ? void 0 : groupInfo.seriesIds.length) === 0) {
      this.rootGroup.removeChild(groupInfo.group);
      this.rootGroup.removeChild(groupInfo.highlight);
      this.rootGroup.removeChild(groupInfo.annotation);
      delete this.groups[type][lookupIndex];
      delete this.groups[type][internalId];
    } else if ((groupInfo == null ? void 0 : groupInfo.seriesIds.length) > 0) {
      const leadSeriesConfig = (_e = this.series[(_d = groupInfo == null ? void 0 : groupInfo.seriesIds) == null ? void 0 : _d[0]]) == null ? void 0 : _e.seriesConfig;
      groupInfo.group.zIndexSubOrder = leadSeriesConfig == null ? void 0 : leadSeriesConfig.getGroupZIndexSubOrder("data");
      groupInfo.highlight.zIndexSubOrder = leadSeriesConfig == null ? void 0 : leadSeriesConfig.getGroupZIndexSubOrder("highlight");
      groupInfo.annotation.zIndexSubOrder = leadSeriesConfig == null ? void 0 : leadSeriesConfig.getGroupZIndexSubOrder("annotation");
    }
    delete this.series[internalId];
  }
  lookupIdx(groupIndex) {
    if (this.mode === "normal") {
      return groupIndex;
    }
    if (typeof groupIndex === "string") {
      groupIndex = Number(groupIndex.split("-").slice(-1)[0]);
      if (!groupIndex)
        return 0;
    }
    return Math.floor(
      Math.max(Math.min(groupIndex / this.expectedSeriesCount, 1), 0) * SERIES_THRESHOLD_FOR_AGGRESSIVE_LAYER_REDUCTION$1
    );
  }
  destroy() {
    for (const groups of Object.values(this.groups)) {
      for (const groupInfo of Object.values(groups)) {
        this.rootGroup.removeChild(groupInfo.group);
        this.rootGroup.removeChild(groupInfo.highlight);
        this.rootGroup.removeChild(groupInfo.annotation);
      }
    }
    this.groups = {};
    this.series = {};
  }
};

// packages/ag-charts-community/src/chart/series/seriesStateManager.ts
var SeriesStateManager$1 = class {
  constructor() {
    this.groups = {};
  }
  registerSeries({
    id,
    seriesGrouping,
    visible,
    type
  }) {
    var _a, _b;
    if (!seriesGrouping)
      return;
    (_b = (_a = this.groups)[type]) != null ? _b : _a[type] = {};
    this.groups[type][id] = { grouping: seriesGrouping, visible };
  }
  deregisterSeries({ id, type }) {
    if (this.groups[type]) {
      delete this.groups[type][id];
    }
    if (this.groups[type] && Object.keys(this.groups[type]).length === 0) {
      delete this.groups[type];
    }
  }
  getVisiblePeerGroupIndex({ type, seriesGrouping }) {
    var _a;
    if (!seriesGrouping)
      return { visibleGroupCount: 1, index: 0 };
    const visibleGroups = [
      ...Object.entries((_a = this.groups[type]) != null ? _a : {}).filter(([_, entry]) => entry.visible).reduce((result, [_, next]) => {
        if (next.visible) {
          result.add(next.grouping.groupIndex);
        }
        return result;
      }, /* @__PURE__ */ new Set()).values()
    ];
    visibleGroups.sort((a, b) => a - b);
    return {
      visibleGroupCount: visibleGroups.length,
      index: visibleGroups.indexOf(seriesGrouping.groupIndex)
    };
  }
};

// packages/ag-charts-community/src/chart/update/baseLayoutProcessor.ts
var BaseLayoutProcessor$1 = class {
  constructor(chartLike, layoutService) {
    this.chartLike = chartLike;
    this.layoutService = layoutService;
    this.destroyFns = [];
    this.destroyFns.push(
      // eslint-disable-next-line sonarjs/no-duplicate-string
      this.layoutService.addListener("layout-complete", (e) => this.layoutComplete(e)),
      this.layoutService.addListener("start-layout", (e) => this.positionPadding(e.shrinkRect)),
      this.layoutService.addListener("start-layout", (e) => this.positionCaptions(e.shrinkRect))
    );
  }
  destroy() {
    this.destroyFns.forEach((cb) => cb());
  }
  layoutComplete({ clipSeries, series: { paddedRect } }) {
    const { seriesArea, seriesRoot } = this.chartLike;
    if (seriesArea.clip || clipSeries) {
      seriesRoot.setClipRectInGroupCoordinateSpace(paddedRect);
    } else {
      seriesRoot.setClipRectInGroupCoordinateSpace();
    }
  }
  positionPadding(shrinkRect) {
    const { padding } = this.chartLike;
    shrinkRect.shrink(padding.left, "left");
    shrinkRect.shrink(padding.top, "top");
    shrinkRect.shrink(padding.right, "right");
    shrinkRect.shrink(padding.bottom, "bottom");
    return { shrinkRect };
  }
  positionCaptions(shrinkRect) {
    var _a, _b, _c, _d;
    const { title, subtitle, footnote } = this.chartLike;
    const newShrinkRect = shrinkRect.clone();
    const updateCaption = (caption) => {
      var _a2;
      const defaultCaptionHeight = shrinkRect.height / 10;
      const captionLineHeight = (_a2 = caption.lineHeight) != null ? _a2 : caption.fontSize * Text$1.defaultLineHeightRatio;
      const maxWidth = shrinkRect.width;
      const maxHeight = Math.max(captionLineHeight, defaultCaptionHeight);
      caption.computeTextWrap(maxWidth, maxHeight);
    };
    const computeX = (align) => {
      if (align === "left") {
        return newShrinkRect.x;
      } else if (align === "right") {
        return newShrinkRect.x + newShrinkRect.width;
      } else if (align !== "center") {
        Logger$1.error(`invalid textAlign value: ${align}`);
      }
      return newShrinkRect.x + newShrinkRect.width / 2;
    };
    const positionTopAndShrinkBBox = (caption, spacing) => {
      const baseY = newShrinkRect.y;
      caption.node.x = computeX(caption.textAlign);
      caption.node.y = baseY;
      caption.node.textBaseline = "top";
      updateCaption(caption);
      const bbox = caption.node.computeBBox();
      const bboxHeight = Math.ceil(bbox.y - baseY + bbox.height + spacing);
      newShrinkRect.shrink(bboxHeight, "top");
    };
    const positionBottomAndShrinkBBox = (caption, spacing) => {
      const baseY = newShrinkRect.y + newShrinkRect.height;
      caption.node.x = computeX(caption.textAlign);
      caption.node.y = baseY;
      caption.node.textBaseline = "bottom";
      updateCaption(caption);
      const bbox = caption.node.computeBBox();
      const bboxHeight = Math.ceil(baseY - bbox.y + spacing);
      newShrinkRect.shrink(bboxHeight, "bottom");
    };
    if (subtitle) {
      subtitle.node.visible = (_a = subtitle.enabled) != null ? _a : false;
    }
    if (title) {
      title.node.visible = title.enabled;
      if (title.node.visible) {
        const defaultTitleSpacing = (subtitle == null ? void 0 : subtitle.node.visible) ? Caption$1.SMALL_PADDING : Caption$1.LARGE_PADDING;
        const spacing = (_b = title.spacing) != null ? _b : defaultTitleSpacing;
        positionTopAndShrinkBBox(title, spacing);
      }
    }
    if (subtitle && subtitle.node.visible) {
      positionTopAndShrinkBBox(subtitle, (_c = subtitle.spacing) != null ? _c : 0);
    }
    if (footnote) {
      footnote.node.visible = footnote.enabled;
      if (footnote.node.visible) {
        positionBottomAndShrinkBBox(footnote, (_d = footnote.spacing) != null ? _d : 0);
      }
    }
    return { shrinkRect: newShrinkRect };
  }
};

// packages/ag-charts-community/src/chart/updateService.ts
var UpdateService$1 = class extends Listeners$1 {
  constructor(updateCallback) {
    super();
    this.updateCallback = updateCallback;
  }
  update(type = 0 /* FULL */, { forceNodeDataRefresh = false, skipAnimations = false } = {}) {
    this.updateCallback(type, { forceNodeDataRefresh, skipAnimations });
  }
  dispatchUpdateComplete(minRect) {
    const event = { type: "update-complete", minRect };
    this.dispatch("update-complete", event);
  }
};

// packages/ag-charts-community/src/chart/chart.ts
function initialiseSpecialOverrides$1(opts) {
  let globalWindow;
  if (opts.window != null) {
    globalWindow = opts.window;
  } else if (typeof window !== "undefined") {
    globalWindow = window;
  } else if (typeof global !== "undefined") {
    globalWindow = global.window;
  } else {
    throw new Error("AG Charts - unable to resolve global window");
  }
  let globalDocument;
  if (opts.document != null) {
    globalDocument = opts.document;
  } else if (typeof document !== "undefined") {
    globalDocument = document;
  } else if (typeof global !== "undefined") {
    globalDocument = global.document;
  } else {
    throw new Error("AG Charts - unable to resolve global document");
  }
  return {
    document: globalDocument,
    window: globalWindow,
    overrideDevicePixelRatio: opts.overrideDevicePixelRatio,
    sceneMode: opts.sceneMode
  };
}
var SeriesArea$1 = class {
  constructor() {
    this.clip = void 0;
    this.padding = new Padding$2(0);
  }
};
__decorateClass$1([
  Validate$1(BOOLEAN$1, { optional: true })
], SeriesArea$1.prototype, "clip", 2);
var chartsInstances$1 = /* @__PURE__ */ new WeakMap();
var Chart$1 = class extends Observable$1 {
  constructor(specialOverrides, resources) {
    var _a;
    super();
    this.id = createId$2(this);
    this.processedOptions = {};
    this.userOptions = {};
    this.queuedUserOptions = [];
    this.seriesRoot = new Group$1({ name: `${this.id}-Series-root` });
    this.debug = Debug$1.create();
    this.extraDebugStats = {};
    this.container = void 0;
    this.data = [];
    this._firstAutoSize = true;
    this.padding = new Padding$2(20);
    this._seriesArea = new SeriesArea$1();
    this.title = void 0;
    this.subtitle = void 0;
    this.footnote = void 0;
    this.mode = "standalone";
    this._destroyed = false;
    this._destroyFns = [];
    this.modules = /* @__PURE__ */ new Map();
    // TODO shouldn't be public
    this.legends = /* @__PURE__ */ new Map();
    this.processors = [];
    this._pendingFactoryUpdatesCount = 0;
    this._performUpdateNoRenderCount = 0;
    this._performUpdateType = 6 /* NONE */;
    this._performUpdateSkipAnimations = false;
    this.updateShortcutCount = 0;
    this.seriesToUpdate = /* @__PURE__ */ new Set();
    this.updateMutex = new Mutex$1();
    this.updateRequestors = {};
    this.performUpdateTrigger = debouncedCallback$1((_0) => __async$1(this, [_0], function* ({ count: count2 }) {
      if (this._destroyed)
        return;
      this.updateMutex.acquire(() => __async$1(this, null, function* () {
        try {
          yield this.performUpdate(count2);
        } catch (error) {
          this._lastPerformUpdateError = error;
          Logger$1.error("update error", error);
        }
      }));
    }));
    this._axes = [];
    this._series = [];
    this.lastInteractionEvent = void 0;
    this.pointerScheduler = debouncedAnimationFrame$1(() => {
      if (this.lastInteractionEvent) {
        this.handlePointer(this.lastInteractionEvent);
      }
      this.lastInteractionEvent = void 0;
    });
    this.onSeriesNodeClick = (event) => {
      const seriesNodeClickEvent = __spreadProps$1(__spreadValues$1({}, event), {
        type: "seriesNodeClick"
      });
      Object.defineProperty(seriesNodeClickEvent, "series", {
        enumerable: false,
        // Should display the deprecation warning
        get: () => event.series
      });
      this.fireEvent(seriesNodeClickEvent);
    };
    this.onSeriesNodeDoubleClick = (event) => {
      const seriesNodeDoubleClick = __spreadProps$1(__spreadValues$1({}, event), {
        type: "seriesNodeDoubleClick"
      });
      this.fireEvent(seriesNodeDoubleClick);
    };
    this.specialOverrides = initialiseSpecialOverrides$1(specialOverrides);
    const { window: window2, document: document2 } = this.specialOverrides;
    const scene = resources == null ? void 0 : resources.scene;
    const element2 = (_a = resources == null ? void 0 : resources.element) != null ? _a : document2.createElement("div");
    const container = resources == null ? void 0 : resources.container;
    const root = new Group$1({ name: "root" });
    root.visible = false;
    root.append(this.seriesRoot);
    this.axisGridGroup = new Group$1({ name: "Axes-Grids", layer: true, zIndex: 1 /* AXIS_GRID_ZINDEX */ });
    root.appendChild(this.axisGridGroup);
    this.axisGroup = new Group$1({ name: "Axes", layer: true, zIndex: 2 /* AXIS_ZINDEX */ });
    root.appendChild(this.axisGroup);
    this.element = element2;
    element2.classList.add("ag-chart-wrapper");
    element2.style.position = "relative";
    this.scene = scene != null ? scene : new Scene$1(this.specialOverrides);
    this.scene.root = root;
    this.scene.container = element2;
    this.autoSize = true;
    this.chartEventManager = new ChartEventManager$1();
    this.cursorManager = new CursorManager$1(element2);
    this.highlightManager = new HighlightManager$1();
    this.interactionManager = new InteractionManager$1(element2, document2, window2);
    this.zoomManager = new ZoomManager$1();
    this.layoutService = new LayoutService$1();
    this.updateService = new UpdateService$1(
      (type = 0 /* FULL */, { forceNodeDataRefresh, skipAnimations }) => this.update(type, { forceNodeDataRefresh, skipAnimations })
    );
    this.seriesStateManager = new SeriesStateManager$1();
    this.seriesLayerManager = new SeriesLayerManager$1(this.seriesRoot);
    this.callbackCache = new CallbackCache$1();
    this.animationManager = new AnimationManager$1(this.interactionManager, this.updateMutex);
    this.animationManager.skip();
    this.animationManager.play();
    this.processors = [new BaseLayoutProcessor$1(this, this.layoutService)];
    this.tooltip = new Tooltip$1(this.scene.canvas.element, document2, window2, document2.body);
    this.tooltipManager = new TooltipManager$1(this.tooltip, this.interactionManager);
    this.overlays = new ChartOverlays$1(this.element);
    this.highlight = new ChartHighlight$1();
    this.container = container;
    SizeMonitor$1.observe(this.element, (size) => this.rawResize(size));
    this._destroyFns.push(
      this.interactionManager.addListener("click", (event) => this.onClick(event)),
      this.interactionManager.addListener("dblclick", (event) => this.onDoubleClick(event)),
      this.interactionManager.addListener("hover", (event) => this.onMouseMove(event)),
      this.interactionManager.addListener("leave", (event) => this.onLeave(event)),
      this.interactionManager.addListener("page-left", () => this.destroy()),
      this.interactionManager.addListener("wheel", () => this.disablePointer()),
      // Block redundant and interfering attempts to update the hovered element during dragging.
      this.interactionManager.addListener("drag-start", () => this.disablePointer()),
      this.animationManager.addListener("animation-frame", (_) => {
        this.update(5 /* SCENE_RENDER */);
      }),
      this.highlightManager.addListener("highlight-change", (event) => this.changeHighlightDatum(event)),
      this.zoomManager.addListener(
        "zoom-change",
        (_) => this.update(1 /* PROCESS_DATA */, { forceNodeDataRefresh: true, skipAnimations: true })
      )
    );
    this.attachLegend("category", Legend$1);
    this.legend = this.legends.get("category");
  }
  static getInstance(element2) {
    return chartsInstances$1.get(element2);
  }
  getOptions() {
    var _a;
    const { queuedUserOptions } = this;
    const lastUpdateOptions = (_a = queuedUserOptions[queuedUserOptions.length - 1]) != null ? _a : this.userOptions;
    return jsonClone$1(lastUpdateOptions);
  }
  autoSizeChanged(value) {
    const { style } = this.element;
    if (value) {
      style.display = "block";
      style.width = "100%";
      style.height = "100%";
      if (!this._lastAutoSize) {
        return;
      }
      this.resize(void 0, void 0, "autoSize option");
    } else {
      style.display = "inline-block";
      style.width = "auto";
      style.height = "auto";
    }
  }
  download(fileName, fileFormat) {
    this.scene.download(fileName, fileFormat);
  }
  get seriesArea() {
    return this._seriesArea;
  }
  set seriesArea(newArea) {
    if (!newArea) {
      this._seriesArea = new SeriesArea$1();
    } else {
      this._seriesArea = newArea;
    }
  }
  get destroyed() {
    return this._destroyed;
  }
  addModule(module) {
    if (this.modules.has(module.optionsKey)) {
      throw new Error(`AG Charts - module already initialised: ${module.optionsKey}`);
    }
    const moduleInstance = new module.instanceConstructor(this.getModuleContext());
    if (module.type === "legend") {
      const legend = moduleInstance;
      this.legends.set(module.identifier, legend);
      legend.attachLegend(this.scene.root);
    }
    this.modules.set(module.optionsKey, moduleInstance);
  }
  removeModule(module) {
    var _a;
    if (module.type === "legend") {
      this.legends.delete(module.identifier);
    }
    (_a = this.modules.get(module.optionsKey)) == null ? void 0 : _a.destroy();
    this.modules.delete(module.optionsKey);
  }
  attachLegend(legendType, legendConstructor) {
    const legend = new legendConstructor(this.getModuleContext());
    this.legends.set(legendType, legend);
    legend.attachLegend(this.scene.root);
  }
  isModuleEnabled(module) {
    return this.modules.has(module.optionsKey);
  }
  getModuleContext() {
    const {
      scene,
      animationManager,
      chartEventManager,
      cursorManager,
      highlightManager,
      interactionManager,
      tooltipManager,
      zoomManager,
      layoutService,
      updateService,
      seriesStateManager,
      seriesLayerManager,
      callbackCache,
      specialOverrides: { window: window2, document: document2 }
    } = this;
    return {
      window: window2,
      document: document2,
      scene,
      animationManager,
      chartEventManager,
      cursorManager,
      highlightManager,
      interactionManager,
      tooltipManager,
      zoomManager,
      chartService: this,
      layoutService,
      updateService,
      seriesStateManager,
      seriesLayerManager,
      callbackCache
    };
  }
  destroy(opts) {
    if (this._destroyed) {
      return;
    }
    const keepTransferableResources = opts == null ? void 0 : opts.keepTransferableResources;
    let result;
    this._performUpdateType = 6 /* NONE */;
    this._destroyFns.forEach((fn) => fn());
    this.processors.forEach((p) => p.destroy());
    this.tooltipManager.destroy();
    this.tooltip.destroy();
    this.legends.forEach((legend) => legend.destroy());
    this.legends.clear();
    this.overlays.destroy();
    SizeMonitor$1.unobserve(this.element);
    for (const { instance: moduleInstance } of Object.values(this.modules)) {
      this.removeModule(moduleInstance);
    }
    this.interactionManager.destroy();
    this.animationManager.stop();
    if (keepTransferableResources) {
      this.scene.strip();
      result = { container: this.container, scene: this.scene, element: this.element };
    } else {
      this.scene.destroy();
      this.container = void 0;
    }
    this.removeAllSeries();
    this.seriesLayerManager.destroy();
    this.axes.forEach((a) => a.destroy());
    this.axes = [];
    this.callbackCache.invalidateCache();
    this._destroyed = true;
    return result;
  }
  disablePointer(highlightOnly = false) {
    if (!highlightOnly) {
      this.tooltipManager.removeTooltip(this.id);
    }
    this.highlightManager.updateHighlight(this.id);
    if (this.lastInteractionEvent) {
      this.lastInteractionEvent = void 0;
    }
  }
  requestFactoryUpdate(cb) {
    this._pendingFactoryUpdatesCount++;
    this.updateMutex.acquire(() => __async$1(this, null, function* () {
      yield cb();
      this._pendingFactoryUpdatesCount--;
    }));
  }
  get performUpdateType() {
    return this._performUpdateType;
  }
  get lastPerformUpdateError() {
    return this._lastPerformUpdateError;
  }
  update(type = 0 /* FULL */, opts) {
    var _a, _b;
    const {
      forceNodeDataRefresh = false,
      skipAnimations,
      seriesToUpdate = this.series,
      newAnimationBatch
    } = opts != null ? opts : {};
    if (forceNodeDataRefresh) {
      this.series.forEach((series) => series.markNodeDataDirty());
    }
    for (const series of seriesToUpdate) {
      this.seriesToUpdate.add(series);
    }
    if (skipAnimations) {
      this.animationManager.skipCurrentBatch();
      this._performUpdateSkipAnimations = true;
    }
    if (newAnimationBatch) {
      if (this.animationManager.isActive()) {
        this._performUpdateSkipAnimations = true;
      } else {
        (_a = this._performUpdateSkipAnimations) != null ? _a : this._performUpdateSkipAnimations = false;
      }
    }
    if (Debug$1.check(true)) {
      let stack = (_b = new Error().stack) != null ? _b : "<unknown>";
      stack = stack.replace(/\([^)]*/g, "");
      this.updateRequestors[stack] = type;
    }
    if (type < this._performUpdateType) {
      this._performUpdateType = type;
      this.performUpdateTrigger.schedule(opts == null ? void 0 : opts.backOffMs);
    }
  }
  performUpdate(count2) {
    return __async$1(this, null, function* () {
      var _a;
      const { _performUpdateType: performUpdateType, extraDebugStats } = this;
      const seriesToUpdate = [...this.seriesToUpdate];
      this._performUpdateType = 6 /* NONE */;
      this.seriesToUpdate.clear();
      if (this.updateShortcutCount === 0 && performUpdateType < 5 /* SCENE_RENDER */) {
        this.animationManager.startBatch(this._performUpdateSkipAnimations);
      }
      this.debug("Chart.performUpdate() - start", ChartUpdateType$1[performUpdateType]);
      const splits = { start: performance.now() };
      switch (performUpdateType) {
        case 0 /* FULL */:
        case 1 /* PROCESS_DATA */:
          yield this.processData();
          this.disablePointer(true);
          splits["\u{1F3ED}"] = performance.now();
        case 2 /* PERFORM_LAYOUT */:
          if (this.checkUpdateShortcut(2 /* PERFORM_LAYOUT */))
            break;
          if (!this.checkFirstAutoSize(seriesToUpdate))
            break;
          yield this.processLayout();
          splits["\u2316"] = performance.now();
        case 3 /* SERIES_UPDATE */:
          if (this.checkUpdateShortcut(3 /* SERIES_UPDATE */))
            break;
          const { seriesRect } = this;
          const seriesUpdates = [...seriesToUpdate].map((series) => series.update({ seriesRect }));
          yield Promise.all(seriesUpdates);
          splits["\u{1F914}"] = performance.now();
        case 4 /* TOOLTIP_RECALCULATION */:
          if (this.checkUpdateShortcut(4 /* TOOLTIP_RECALCULATION */))
            break;
          const tooltipMeta = this.tooltipManager.getTooltipMeta(this.id);
          const isHovered = ((_a = tooltipMeta == null ? void 0 : tooltipMeta.event) == null ? void 0 : _a.type) === "hover";
          if (performUpdateType <= 3 /* SERIES_UPDATE */ && isHovered) {
            this.handlePointer(tooltipMeta.event);
          }
          splits["\u2196"] = performance.now();
        case 5 /* SCENE_RENDER */:
          if (this.checkUpdateShortcut(5 /* SCENE_RENDER */))
            break;
          extraDebugStats["updateShortcutCount"] = this.updateShortcutCount;
          yield this.scene.render({ debugSplitTimes: splits, extraDebugStats });
          this.extraDebugStats = {};
        case 6 /* NONE */:
          this.updateShortcutCount = 0;
          this.updateRequestors = {};
          this._performUpdateSkipAnimations = void 0;
          this.animationManager.endBatch();
      }
      this.updateService.dispatchUpdateComplete(this.getMinRect());
      const end = performance.now();
      this.debug("Chart.performUpdate() - end", {
        chart: this,
        durationMs: Math.round((end - splits["start"]) * 100) / 100,
        count: count2,
        performUpdateType: ChartUpdateType$1[performUpdateType]
      });
    });
  }
  checkUpdateShortcut(checkUpdateType) {
    const maxShortcuts = 3;
    if (this.updateShortcutCount > maxShortcuts) {
      Logger$1.warn(
        `exceeded the maximum number of simultaneous updates (${maxShortcuts + 1}), discarding changes and rendering`,
        this.updateRequestors
      );
      return false;
    }
    if (this.performUpdateType <= checkUpdateType) {
      this.updateShortcutCount++;
      return true;
    }
    return false;
  }
  checkFirstAutoSize(seriesToUpdate) {
    if (this.autoSize && !this._lastAutoSize) {
      const count2 = this._performUpdateNoRenderCount++;
      const backOffMs = (count2 ^ 2) * 10;
      if (count2 < 8) {
        this.update(2 /* PERFORM_LAYOUT */, { seriesToUpdate, backOffMs });
        this.debug("Chart.checkFirstAutoSize() - backing off until first size update", backOffMs);
        return false;
      }
      this.debug("Chart.checkFirstAutoSize() - timeout for first size update.");
    }
    this._performUpdateNoRenderCount = 0;
    return true;
  }
  set axes(values) {
    const removedAxes = /* @__PURE__ */ new Set();
    this._axes.forEach((axis) => {
      axis.detachAxis(this.axisGroup, this.axisGridGroup);
      removedAxes.add(axis);
    });
    this._axes = values.filter((a) => !a.linkedTo).concat(values.filter((a) => a.linkedTo));
    this._axes.forEach((axis) => {
      axis.attachAxis(this.axisGroup, this.axisGridGroup);
      removedAxes.delete(axis);
    });
    this.zoomManager.updateAxes(this._axes);
    removedAxes.forEach((axis) => axis.destroy());
  }
  get axes() {
    return this._axes;
  }
  set series(values) {
    this.removeAllSeries();
    this.seriesLayerManager.setSeriesCount(values.length);
    values.forEach((series) => this.addSeries(series));
  }
  get series() {
    return this._series;
  }
  addSeries(series) {
    const { series: allSeries } = this;
    const canAdd = allSeries.indexOf(series) < 0;
    if (canAdd) {
      allSeries.push(series);
      if (series.rootGroup.parent == null) {
        this.seriesLayerManager.requestGroup(series);
      }
      this.initSeries(series);
      return true;
    }
    return false;
  }
  initSeries(series) {
    const chart = this;
    series.chart = {
      get mode() {
        return chart.mode;
      },
      get seriesRect() {
        return chart.seriesRect;
      },
      placeLabels() {
        return chart.placeLabels();
      }
    };
    series.setChartData(this.data);
    this.addSeriesListeners(series);
    series.addChartEventListeners();
  }
  removeAllSeries() {
    this.series.forEach((series) => {
      series.removeEventListener("nodeClick", this.onSeriesNodeClick);
      series.removeEventListener("nodeDoubleClick", this.onSeriesNodeDoubleClick);
      series.destroy();
      series.chart = void 0;
    });
    this._series = [];
  }
  addSeriesListeners(series) {
    if (this.hasEventListener("seriesNodeClick")) {
      series.addEventListener("nodeClick", this.onSeriesNodeClick);
    }
    if (this.hasEventListener("seriesNodeDoubleClick")) {
      series.addEventListener("nodeDoubleClick", this.onSeriesNodeDoubleClick);
    }
  }
  updateAllSeriesListeners() {
    this.series.forEach((series) => {
      series.removeEventListener("nodeClick", this.onSeriesNodeClick);
      series.removeEventListener("nodeDoubleClick", this.onSeriesNodeDoubleClick);
      this.addSeriesListeners(series);
    });
  }
  assignSeriesToAxes() {
    this.axes.forEach((axis) => {
      axis.boundSeries = this.series.filter((s) => {
        const seriesAxis = s.axes[axis.direction];
        return seriesAxis === axis;
      });
    });
  }
  assignAxesToSeries() {
    const directionToAxesMap = {};
    this.axes.forEach((axis) => {
      var _a;
      const direction = axis.direction;
      const directionAxes = (_a = directionToAxesMap[direction]) != null ? _a : directionToAxesMap[direction] = [];
      directionAxes.push(axis);
    });
    this.series.forEach((series) => {
      series.directions.forEach((direction) => {
        const directionAxes = directionToAxesMap[direction];
        if (!directionAxes) {
          Logger$1.warnOnce(
            `no available axis for direction [${direction}]; check series and axes configuration.`
          );
          return;
        }
        const seriesKeys = series.getKeys(direction);
        const newAxis = this.findMatchingAxis(directionAxes, seriesKeys);
        if (!newAxis) {
          Logger$1.warnOnce(
            `no matching axis for direction [${direction}] and keys [${seriesKeys}]; check series and axes configuration.`
          );
          return;
        }
        series.axes[direction] = newAxis;
      });
    });
  }
  findMatchingAxis(directionAxes, directionKeys) {
    for (const axis of directionAxes) {
      const axisKeys = axis.keys;
      if (!axisKeys.length) {
        return axis;
      }
      if (!directionKeys) {
        continue;
      }
      for (const directionKey of directionKeys) {
        if (axisKeys.indexOf(directionKey) >= 0) {
          return axis;
        }
      }
    }
  }
  rawResize(size) {
    var _a;
    let { width, height } = size;
    width = Math.floor(width);
    height = Math.floor(height);
    if (!this.autoSize) {
      return;
    }
    if (width === 0 && height === 0) {
      return;
    }
    const [autoWidth = 0, authHeight = 0] = (_a = this._lastAutoSize) != null ? _a : [];
    if (autoWidth === width && authHeight === height) {
      return;
    }
    this._lastAutoSize = [width, height];
    this.resize(void 0, void 0, "SizeMonitor");
  }
  resize(width, height, source) {
    var _a, _b, _c, _d;
    width != null ? width : width = (_b = this.width) != null ? _b : this.autoSize ? (_a = this._lastAutoSize) == null ? void 0 : _a[0] : this.scene.canvas.width;
    height != null ? height : height = (_d = this.height) != null ? _d : this.autoSize ? (_c = this._lastAutoSize) == null ? void 0 : _c[1] : this.scene.canvas.height;
    this.debug(`Chart.resize() from ${source}`, { width, height, stack: new Error().stack });
    if (!width || !height || !Number.isFinite(width) || !Number.isFinite(height))
      return;
    if (this.scene.resize(width, height)) {
      this.disablePointer();
      this.animationManager.reset();
      let skipAnimations = true;
      if (this.autoSize && this._firstAutoSize) {
        skipAnimations = false;
        this._firstAutoSize = false;
      }
      this.update(2 /* PERFORM_LAYOUT */, { forceNodeDataRefresh: true, skipAnimations });
    }
  }
  processData() {
    return __async$1(this, null, function* () {
      if (this.series.some((s) => s.canHaveAxes)) {
        this.assignAxesToSeries();
        this.assignSeriesToAxes();
      }
      const dataController = new DataController$1(this.mode);
      const seriesPromises = this.series.map((s) => s.processData(dataController));
      yield dataController.execute();
      yield Promise.all(seriesPromises);
      yield this.updateLegend();
    });
  }
  placeLabels() {
    const visibleSeries = [];
    const data = [];
    for (const series of this.series) {
      if (!series.visible) {
        continue;
      }
      const labelData = series.getLabelData();
      if (!(labelData && isPointLabelDatum$1(labelData[0]))) {
        continue;
      }
      data.push(labelData);
      visibleSeries.push(series);
    }
    const { seriesRect } = this;
    const { top, right, bottom, left } = this.seriesArea.padding;
    const labels = seriesRect && data.length > 0 ? placeLabels$1(data, {
      x: -left,
      y: -top,
      width: seriesRect.width + left + right,
      height: seriesRect.height + top + bottom
    }) : [];
    return new Map(labels.map((l, i) => [visibleSeries[i], l]));
  }
  updateLegend() {
    return __async$1(this, null, function* () {
      this.legends.forEach((legend, legendType) => {
        const isCategoryLegendData = (data) => data.every((d) => d.legendType === "category");
        const legendData = this.series.filter((s) => s.properties.showInLegend).flatMap((s) => s.getLegendData(legendType));
        if (isCategoryLegendData(legendData)) {
          this.validateCategoryLegendData(legendData);
        }
        legend.data = legendData;
      });
    });
  }
  validateCategoryLegendData(legendData) {
    const labelMarkerFills = {};
    legendData.forEach((d) => {
      var _a, _b, _c, _d, _e;
      const seriesType = (_a = this.series.find((s) => s.id === d.seriesId)) == null ? void 0 : _a.type;
      if (!seriesType)
        return;
      (_b = labelMarkerFills[seriesType]) != null ? _b : labelMarkerFills[seriesType] = {};
      (_e = (_c = labelMarkerFills[seriesType])[_d = d.label.text]) != null ? _e : _c[_d] = /* @__PURE__ */ new Set();
      if (d.marker.fill != null) {
        labelMarkerFills[seriesType][d.label.text].add(d.marker.fill);
      }
    });
    for (const seriesMarkers of Object.values(labelMarkerFills)) {
      for (const [name, fills] of Object.entries(seriesMarkers)) {
        if (fills.size > 1) {
          Logger$1.warnOnce(
            `legend item '${name}' has multiple fill colors, this may cause unexpected behaviour.`
          );
        }
      }
    }
  }
  processLayout() {
    return __async$1(this, null, function* () {
      var _a;
      const oldRect = this.animationRect;
      yield this.performLayout();
      if (oldRect && !((_a = this.animationRect) == null ? void 0 : _a.equals(oldRect))) {
        this.animationManager.skipCurrentBatch();
      }
      this.handleOverlays();
      this.debug("Chart.performUpdate() - seriesRect", this.seriesRect);
    });
  }
  performLayout() {
    return __async$1(this, null, function* () {
      if (this.scene.root) {
        this.scene.root.visible = true;
      }
      const { width, height } = this.scene;
      let ctx = { shrinkRect: new BBox$1(0, 0, width, height) };
      ctx = this.layoutService.dispatchPerformLayout("start-layout", ctx);
      ctx = this.layoutService.dispatchPerformLayout("before-series", ctx);
      return ctx.shrinkRect;
    });
  }
  // x/y are local canvas coordinates in CSS pixels, not actual pixels
  pickSeriesNode(point, exactMatchOnly, maxDistance) {
    var _a, _b;
    const start = performance.now();
    const pickModes = exactMatchOnly ? [0 /* EXACT_SHAPE_MATCH */] : void 0;
    const reverseSeries = [...this.series].reverse();
    let result;
    for (const series of reverseSeries) {
      if (!series.visible || !series.rootGroup.visible) {
        continue;
      }
      const { match, distance } = (_a = series.pickNode(point, pickModes)) != null ? _a : {};
      if (!match || distance == null) {
        continue;
      }
      if ((!result || result.distance > distance) && distance <= (maxDistance != null ? maxDistance : Infinity)) {
        result = { series, distance, datum: match };
      }
      if (distance === 0) {
        break;
      }
    }
    this.extraDebugStats["pickSeriesNode"] = Math.round(
      ((_b = this.extraDebugStats["pickSeriesNode"]) != null ? _b : 0) + (performance.now() - start)
    );
    return result;
  }
  onMouseMove(event) {
    this.lastInteractionEvent = event;
    this.pointerScheduler.schedule();
    this.extraDebugStats["mouseX"] = event.offsetX;
    this.extraDebugStats["mouseY"] = event.offsetY;
    this.update(5 /* SCENE_RENDER */);
  }
  onLeave(event) {
    if (this.tooltip.pointerLeftOntoTooltip(event)) {
      return;
    }
    this.disablePointer();
    this.update(5 /* SCENE_RENDER */);
  }
  handlePointer(event) {
    const { lastPick, hoverRect } = this;
    const { offsetX, offsetY } = event;
    const disablePointer = (highlightOnly = false) => {
      if (lastPick) {
        this.disablePointer(highlightOnly);
      }
    };
    if (!(hoverRect == null ? void 0 : hoverRect.containsPoint(offsetX, offsetY))) {
      disablePointer();
      return;
    }
    this.handlePointerTooltip(event, disablePointer);
    this.handlePointerNode(event);
  }
  handlePointerTooltip(event, disablePointer) {
    const { lastPick, tooltip } = this;
    const { range: range3 } = tooltip;
    const { offsetX, offsetY } = event;
    let pixelRange;
    if (typeof range3 === "number" && Number.isFinite(range3)) {
      pixelRange = range3;
    }
    const pick = this.pickSeriesNode({ x: offsetX, y: offsetY }, range3 === "exact", pixelRange);
    if (!pick) {
      this.tooltipManager.removeTooltip(this.id);
      if (this.highlight.range === "tooltip")
        disablePointer(true);
      return;
    }
    const isNewDatum = this.highlight.range === "node" || !lastPick || lastPick.datum !== pick.datum;
    let html;
    if (isNewDatum) {
      html = pick.series.getTooltipHtml(pick.datum);
      if (this.highlight.range === "tooltip") {
        this.highlightManager.updateHighlight(this.id, pick.datum);
      }
    } else if (lastPick) {
      lastPick.event = event.sourceEvent;
    }
    const isPixelRange = pixelRange != null;
    const tooltipEnabled = this.tooltip.enabled && pick.series.properties.tooltip.enabled;
    const exactlyMatched = range3 === "exact" && pick.distance === 0;
    const rangeMatched = range3 === "nearest" || isPixelRange || exactlyMatched;
    const shouldUpdateTooltip = tooltipEnabled && rangeMatched && (!isNewDatum || html !== void 0);
    const meta = TooltipManager$1.makeTooltipMeta(event, this.scene.canvas, pick.datum, this.specialOverrides.window);
    if (shouldUpdateTooltip) {
      this.tooltipManager.updateTooltip(this.id, meta, html);
    }
  }
  handlePointerNode(event) {
    const found = this.checkSeriesNodeRange(event, (series, datum) => {
      if (series.hasEventListener("nodeClick") || series.hasEventListener("nodeDoubleClick")) {
        this.cursorManager.updateCursor("chart", "pointer");
      }
      if (this.highlight.range === "node") {
        this.highlightManager.updateHighlight(this.id, datum);
      }
    });
    if (!found) {
      this.cursorManager.updateCursor("chart");
      if (this.highlight.range === "node") {
        this.highlightManager.updateHighlight(this.id);
      }
    }
  }
  onClick(event) {
    if (this.checkSeriesNodeClick(event)) {
      this.update(3 /* SERIES_UPDATE */);
      return;
    }
    this.fireEvent({
      type: "click",
      event: event.sourceEvent
    });
  }
  onDoubleClick(event) {
    if (this.checkSeriesNodeDoubleClick(event)) {
      this.update(3 /* SERIES_UPDATE */);
      return;
    }
    this.fireEvent({
      type: "doubleClick",
      event: event.sourceEvent
    });
  }
  checkSeriesNodeClick(event) {
    return this.checkSeriesNodeRange(event, (series, datum) => series.fireNodeClickEvent(event.sourceEvent, datum));
  }
  checkSeriesNodeDoubleClick(event) {
    return this.checkSeriesNodeRange(
      event,
      (series, datum) => series.fireNodeDoubleClickEvent(event.sourceEvent, datum)
    );
  }
  checkSeriesNodeRange(event, callback) {
    const nearestNode = this.pickSeriesNode({ x: event.offsetX, y: event.offsetY }, false);
    const datum = nearestNode == null ? void 0 : nearestNode.datum;
    const nodeClickRange = datum == null ? void 0 : datum.series.properties.nodeClickRange;
    let pixelRange;
    if (typeof nodeClickRange === "number" && Number.isFinite(nodeClickRange)) {
      pixelRange = nodeClickRange;
    }
    let pickedNode = this.pickSeriesNode({ x: event.offsetX, y: event.offsetY }, true);
    if (pickedNode) {
      this.highlightManager.updatePicked(this.id, pickedNode.datum);
    } else {
      this.highlightManager.updatePicked(this.id);
    }
    if (datum && nodeClickRange === "nearest") {
      callback(datum.series, datum);
      return true;
    }
    if (nodeClickRange !== "exact") {
      pickedNode = this.pickSeriesNode({ x: event.offsetX, y: event.offsetY }, false, pixelRange);
    }
    if (!pickedNode)
      return false;
    const isPixelRange = pixelRange != null;
    const exactlyMatched = nodeClickRange === "exact" && pickedNode.distance === 0;
    if (isPixelRange || exactlyMatched) {
      callback(pickedNode.series, pickedNode.datum);
      return true;
    }
    return false;
  }
  changeHighlightDatum(event) {
    var _a, _b;
    const seriesToUpdate = /* @__PURE__ */ new Set();
    const { series: newSeries = void 0, datum: newDatum } = (_a = event.currentHighlight) != null ? _a : {};
    const { series: lastSeries = void 0, datum: lastDatum } = (_b = event.previousHighlight) != null ? _b : {};
    if (lastSeries) {
      seriesToUpdate.add(lastSeries);
    }
    if (newSeries) {
      seriesToUpdate.add(newSeries);
    }
    if ((lastSeries == null ? void 0 : lastSeries.properties.cursor) && lastDatum) {
      this.cursorManager.updateCursor(lastSeries.id);
    }
    if ((newSeries == null ? void 0 : newSeries.properties.cursor) && newDatum) {
      this.cursorManager.updateCursor(newSeries.id, newSeries.properties.cursor);
    }
    this.lastPick = event.currentHighlight ? { datum: event.currentHighlight } : void 0;
    const updateAll = newSeries == null || lastSeries == null;
    if (updateAll) {
      this.update(3 /* SERIES_UPDATE */);
    } else {
      this.update(3 /* SERIES_UPDATE */, { seriesToUpdate });
    }
  }
  waitForUpdate(timeoutMs = 5e3) {
    return __async$1(this, null, function* () {
      const start = performance.now();
      if (this._pendingFactoryUpdatesCount > 0) {
        yield this.updateMutex.waitForClearAcquireQueue();
      }
      while (this._performUpdateType !== 6 /* NONE */) {
        if (performance.now() - start > timeoutMs) {
          throw new Error("waitForUpdate() timeout reached.");
        }
        yield sleep$1(5);
      }
      yield this.updateMutex.waitForClearAcquireQueue();
    });
  }
  handleOverlays() {
    const hasNoData = !this.series.some((s) => s.hasData());
    this.toggleOverlay(this.overlays.noData, hasNoData);
    if (!hasNoData) {
      const hasNoVisibleSeries = !this.series.some((series) => series.visible);
      this.toggleOverlay(this.overlays.noVisibleSeries, hasNoVisibleSeries);
    }
  }
  toggleOverlay(overlay, visible) {
    if (visible && this.seriesRect) {
      overlay.show(this.seriesRect);
    } else {
      overlay.hide();
    }
  }
  getMinRect() {
    const minRects = this.series.map((series) => series.getMinRect()).filter((rect) => rect !== void 0);
    if (!minRects.length)
      return void 0;
    return new BBox$1(
      0,
      0,
      minRects.reduce((max, rect) => Math.max(max, rect.width), 0),
      minRects.reduce((max, rect) => Math.max(max, rect.height), 0)
    );
  }
};
__decorateClass$1([
  ActionOnSet$1({
    newValue(value) {
      if (this.destroyed)
        return;
      value.setAttribute("data-ag-charts", "");
      value.appendChild(this.element);
      chartsInstances$1.set(value, this);
    },
    oldValue(value) {
      value.removeAttribute("data-ag-charts");
      value.removeChild(this.element);
      chartsInstances$1.delete(value);
    }
  })
], Chart$1.prototype, "container", 2);
__decorateClass$1([
  ActionOnSet$1({
    newValue(value) {
      var _a;
      (_a = this.series) == null ? void 0 : _a.forEach((series) => {
        series.setChartData(value);
      });
    }
  })
], Chart$1.prototype, "data", 2);
__decorateClass$1([
  ActionOnSet$1({
    newValue(value) {
      this.resize(value, void 0, "width option");
    }
  })
], Chart$1.prototype, "width", 2);
__decorateClass$1([
  ActionOnSet$1({
    newValue(value) {
      this.resize(void 0, value, "height option");
    }
  })
], Chart$1.prototype, "height", 2);
__decorateClass$1([
  ActionOnSet$1({
    changeValue(value) {
      this.autoSizeChanged(value);
    }
  }),
  Validate$1(BOOLEAN$1)
], Chart$1.prototype, "autoSize", 2);
__decorateClass$1([
  ActionOnSet$1({
    newValue(value) {
      var _a;
      (_a = this.scene.root) == null ? void 0 : _a.appendChild(value.node);
    },
    oldValue(oldValue) {
      var _a;
      (_a = this.scene.root) == null ? void 0 : _a.removeChild(oldValue.node);
    }
  })
], Chart$1.prototype, "title", 2);
__decorateClass$1([
  ActionOnSet$1({
    newValue(value) {
      var _a;
      (_a = this.scene.root) == null ? void 0 : _a.appendChild(value.node);
    },
    oldValue(oldValue) {
      var _a;
      (_a = this.scene.root) == null ? void 0 : _a.removeChild(oldValue.node);
    }
  })
], Chart$1.prototype, "subtitle", 2);
__decorateClass$1([
  ActionOnSet$1({
    newValue(value) {
      var _a;
      (_a = this.scene.root) == null ? void 0 : _a.appendChild(value.node);
    },
    oldValue(oldValue) {
      var _a;
      (_a = this.scene.root) == null ? void 0 : _a.removeChild(oldValue.node);
    }
  })
], Chart$1.prototype, "footnote", 2);
__decorateClass$1([
  Validate$1(UNION$1(["standalone", "integrated"], "a chart mode"))
], Chart$1.prototype, "mode", 2);

// packages/ag-charts-community/src/chart/series/dataModelSeries.ts
var DataModelSeries$1 = class extends Series$1 {
  isContinuous() {
    var _a, _b;
    const isContinuousX = ContinuousScale$1.is((_a = this.axes["x" /* X */]) == null ? void 0 : _a.scale);
    const isContinuousY = ContinuousScale$1.is((_b = this.axes["y" /* Y */]) == null ? void 0 : _b.scale);
    return { isContinuousX, isContinuousY };
  }
  getModulePropertyDefinitions() {
    return this.moduleMap.mapValues((mod2) => mod2.getPropertyDefinitions(this.isContinuous())).flat();
  }
  // Request data, but with message dispatching to series-options (modules).
  requestDataModel(dataController, data, opts) {
    return __async$1(this, null, function* () {
      opts.props.push(...this.getModulePropertyDefinitions());
      const { dataModel, processedData } = yield dataController.request(this.id, data != null ? data : [], opts);
      this.dataModel = dataModel;
      this.processedData = processedData;
      this.dispatch("data-processed", { dataModel, processedData });
      return { dataModel, processedData };
    });
  }
  isProcessedDataAnimatable() {
    var _a, _b;
    const validationResults = (_b = (_a = this.processedData) == null ? void 0 : _a.reduced) == null ? void 0 : _b.animationValidation;
    if (!validationResults) {
      return true;
    }
    const { orderedKeys, uniqueKeys } = validationResults;
    return orderedKeys && uniqueKeys;
  }
  checkProcessedDataAnimatable() {
    if (!this.isProcessedDataAnimatable()) {
      this.ctx.animationManager.skipCurrentBatch();
    }
  }
};

// packages/ag-charts-community/src/chart/series/seriesProperties.ts
var SeriesItemHighlightStyle$1 = class extends BaseProperties$1 {
  constructor() {
    super(...arguments);
    this.fill = "rgba(255,255,255, 0.33)";
    this.stroke = `rgba(0, 0, 0, 0.4)`;
    this.strokeWidth = 2;
  }
};
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true })
], SeriesItemHighlightStyle$1.prototype, "fill", 2);
__decorateClass$1([
  Validate$1(RATIO$1, { optional: true })
], SeriesItemHighlightStyle$1.prototype, "fillOpacity", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true })
], SeriesItemHighlightStyle$1.prototype, "stroke", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], SeriesItemHighlightStyle$1.prototype, "strokeWidth", 2);
__decorateClass$1([
  Validate$1(RATIO$1, { optional: true })
], SeriesItemHighlightStyle$1.prototype, "strokeOpacity", 2);
__decorateClass$1([
  Validate$1(LINE_DASH$1, { optional: true })
], SeriesItemHighlightStyle$1.prototype, "lineDash", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], SeriesItemHighlightStyle$1.prototype, "lineDashOffset", 2);
var SeriesHighlightStyle$1 = class extends BaseProperties$1 {
};
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], SeriesHighlightStyle$1.prototype, "strokeWidth", 2);
__decorateClass$1([
  Validate$1(RATIO$1, { optional: true })
], SeriesHighlightStyle$1.prototype, "dimOpacity", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1, { optional: true })
], SeriesHighlightStyle$1.prototype, "enabled", 2);
var TextHighlightStyle$1 = class extends BaseProperties$1 {
  constructor() {
    super(...arguments);
    this.color = "black";
  }
};
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true })
], TextHighlightStyle$1.prototype, "color", 2);
var HighlightStyle$1 = class extends BaseProperties$1 {
  constructor() {
    super(...arguments);
    this.item = new SeriesItemHighlightStyle$1();
    this.series = new SeriesHighlightStyle$1();
    this.text = new TextHighlightStyle$1();
  }
};
__decorateClass$1([
  Validate$1(OBJECT$1)
], HighlightStyle$1.prototype, "item", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], HighlightStyle$1.prototype, "series", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], HighlightStyle$1.prototype, "text", 2);
var SeriesProperties$1 = class extends BaseProperties$1 {
  constructor() {
    super(...arguments);
    this.visible = true;
    this.showInLegend = true;
    this.cursor = "default";
    this.nodeClickRange = "exact";
    this.highlightStyle = new HighlightStyle$1();
  }
};
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], SeriesProperties$1.prototype, "id", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], SeriesProperties$1.prototype, "visible", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], SeriesProperties$1.prototype, "showInLegend", 2);
__decorateClass$1([
  Validate$1(STRING$1)
], SeriesProperties$1.prototype, "cursor", 2);
__decorateClass$1([
  Validate$1(INTERACTION_RANGE$1)
], SeriesProperties$1.prototype, "nodeClickRange", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], SeriesProperties$1.prototype, "highlightStyle", 2);

// packages/ag-charts-community/src/chart/series/cartesian/cartesianSeries.ts
var DEFAULT_DIRECTION_KEYS$1 = {
  ["x" /* X */]: ["xKey"],
  ["y" /* Y */]: ["yKey"]
};
var DEFAULT_DIRECTION_NAMES$1 = {
  ["x" /* X */]: ["xName"],
  ["y" /* Y */]: ["yName"]
};
var CartesianSeriesNodeClickEvent$1 = class extends SeriesNodeClickEvent$1 {
  constructor(type, nativeEvent, datum, series) {
    super(type, nativeEvent, datum, series);
    this.xKey = series.properties.xKey;
    this.yKey = series.properties.yKey;
  }
};
var CartesianSeriesProperties$1 = class extends SeriesProperties$1 {
};
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], CartesianSeriesProperties$1.prototype, "legendItemName", 2);
var CartesianSeries$1 = class extends DataModelSeries$1 {
  constructor(_a) {
    var _b = _a, {
      pathsPerSeries = 1,
      hasMarkers = false,
      hasHighlightedLabels = false,
      pathsZIndexSubOrderOffset = [],
      directionKeys = DEFAULT_DIRECTION_KEYS$1,
      directionNames = DEFAULT_DIRECTION_NAMES$1,
      datumSelectionGarbageCollection = true,
      markerSelectionGarbageCollection = true,
      animationResetFns
    } = _b, otherOpts = __objRest$1(_b, [
      "pathsPerSeries",
      "hasMarkers",
      "hasHighlightedLabels",
      "pathsZIndexSubOrderOffset",
      "directionKeys",
      "directionNames",
      "datumSelectionGarbageCollection",
      "markerSelectionGarbageCollection",
      "animationResetFns"
    ]);
    super(__spreadValues$1({
      directionKeys,
      directionNames,
      useSeriesGroupLayer: true,
      canHaveAxes: true
    }, otherOpts));
    this._contextNodeData = [];
    this.NodeClickEvent = CartesianSeriesNodeClickEvent$1;
    this.highlightSelection = Selection$1.select(
      this.highlightNode,
      () => this.opts.hasMarkers ? this.markerFactory() : this.nodeFactory()
    );
    this.highlightLabelSelection = Selection$1.select(this.highlightLabel, Text$1);
    this.annotationSelections = /* @__PURE__ */ new Set();
    this.subGroups = [];
    this.subGroupId = 0;
    this.debug = Debug$1.create();
    this.opts = {
      pathsPerSeries,
      hasMarkers,
      hasHighlightedLabels,
      pathsZIndexSubOrderOffset,
      directionKeys,
      directionNames,
      animationResetFns,
      datumSelectionGarbageCollection,
      markerSelectionGarbageCollection
    };
    this.animationState = new StateMachine$1(
      "empty",
      {
        empty: {
          update: {
            target: "ready",
            action: (data) => this.animateEmptyUpdateReady(data)
          }
        },
        ready: {
          updateData: "waiting",
          clear: "clearing",
          highlight: (data) => this.animateReadyHighlight(data),
          highlightMarkers: (data) => this.animateReadyHighlightMarkers(data),
          resize: (data) => this.animateReadyResize(data)
        },
        waiting: {
          update: {
            target: "ready",
            action: (data) => this.animateWaitingUpdateReady(data)
          }
        },
        clearing: {
          update: {
            target: "empty",
            action: (data) => this.animateClearingUpdateEmpty(data)
          }
        }
      },
      () => this.checkProcessedDataAnimatable()
    );
  }
  get contextNodeData() {
    return this._contextNodeData.slice();
  }
  addChartEventListeners() {
    this.destroyFns.push(
      this.ctx.chartEventManager.addListener("legend-item-click", (event) => this.onLegendItemClick(event)),
      this.ctx.chartEventManager.addListener(
        "legend-item-double-click",
        (event) => this.onLegendItemDoubleClick(event)
      )
    );
  }
  destroy() {
    super.destroy();
    this._contextNodeData.splice(0, this._contextNodeData.length);
    this.subGroups.splice(0, this.subGroups.length);
  }
  update(_0) {
    return __async$1(this, arguments, function* ({ seriesRect }) {
      var _a, _b;
      const { visible, _contextNodeData: previousContextData } = this;
      const { series } = (_b = (_a = this.ctx.highlightManager) == null ? void 0 : _a.getActiveHighlight()) != null ? _b : {};
      const seriesHighlighted = series ? series === this : void 0;
      const resize = this.checkResize(seriesRect);
      const highlightItems = yield this.updateHighlightSelection(seriesHighlighted);
      yield this.updateSelections(visible);
      yield this.updateNodes(highlightItems, seriesHighlighted, visible);
      const animationData = this.getAnimationData(seriesRect, previousContextData);
      if (resize) {
        this.animationState.transition("resize", animationData);
      }
      this.animationState.transition("update", animationData);
    });
  }
  updateSelections(anySeriesItemEnabled) {
    return __async$1(this, null, function* () {
      if (!anySeriesItemEnabled && this.ctx.animationManager.isSkipped()) {
        return;
      }
      if (!this.nodeDataRefresh && !this.isPathOrSelectionDirty()) {
        return;
      }
      if (this.nodeDataRefresh) {
        this.nodeDataRefresh = false;
        this.debug(`CartesianSeries.updateSelections() - calling createNodeData() for`, this.id);
        this._contextNodeData = yield this.createNodeData();
        const animationValid = this.isProcessedDataAnimatable();
        this._contextNodeData.forEach((nodeData) => {
          var _a;
          (_a = nodeData.animationValid) != null ? _a : nodeData.animationValid = animationValid;
        });
        yield this.updateSeriesGroups();
        const { dataModel, processedData } = this;
        if (dataModel !== void 0 && processedData !== void 0) {
          this.dispatch("data-update", { dataModel, processedData });
        }
      }
      yield Promise.all(this.subGroups.map((g, i) => this.updateSeriesGroupSelections(g, i)));
    });
  }
  updateSeriesGroupSelections(subGroup, seriesIdx, seriesHighlighted) {
    return __async$1(this, null, function* () {
      const { datumSelection, labelSelection, markerSelection, paths } = subGroup;
      const contextData = this._contextNodeData[seriesIdx];
      const { nodeData, labelData, itemId } = contextData;
      yield this.updatePaths({ seriesHighlighted, itemId, contextData, paths, seriesIdx });
      subGroup.datumSelection = yield this.updateDatumSelection({ nodeData, datumSelection, seriesIdx });
      subGroup.labelSelection = yield this.updateLabelSelection({ labelData, labelSelection, seriesIdx });
      if (markerSelection) {
        subGroup.markerSelection = yield this.updateMarkerSelection({ nodeData, markerSelection, seriesIdx });
      }
    });
  }
  markerFactory() {
    const MarkerShape = getMarker$2();
    return new MarkerShape();
  }
  updateSeriesGroups() {
    return __async$1(this, null, function* () {
      const {
        _contextNodeData: contextNodeData,
        contentGroup,
        subGroups,
        opts: { pathsPerSeries, hasMarkers, datumSelectionGarbageCollection, markerSelectionGarbageCollection }
      } = this;
      if (contextNodeData.length === subGroups.length) {
        return;
      }
      if (contextNodeData.length < subGroups.length) {
        subGroups.splice(contextNodeData.length).forEach(({ dataNodeGroup, markerGroup, labelGroup, paths }) => {
          contentGroup.removeChild(dataNodeGroup);
          if (markerGroup) {
            contentGroup.removeChild(markerGroup);
          }
          if (labelGroup) {
            contentGroup.removeChild(labelGroup);
          }
          for (const path of paths) {
            contentGroup.removeChild(path);
          }
        });
      }
      const totalGroups = contextNodeData.length;
      while (totalGroups > subGroups.length) {
        const layer = false;
        const subGroupId = this.subGroupId++;
        const dataNodeGroup = new Group$1({
          name: `${this.id}-series-sub${subGroupId}-dataNodes`,
          layer,
          zIndex: 4 /* SERIES_LAYER_ZINDEX */,
          zIndexSubOrder: this.getGroupZIndexSubOrder("data", subGroupId)
        });
        const markerGroup = hasMarkers ? new Group$1({
          name: `${this.id}-series-sub${this.subGroupId++}-markers`,
          layer,
          zIndex: 4 /* SERIES_LAYER_ZINDEX */,
          zIndexSubOrder: this.getGroupZIndexSubOrder("marker", subGroupId)
        }) : void 0;
        const labelGroup = new Group$1({
          name: `${this.id}-series-sub${this.subGroupId++}-labels`,
          layer,
          zIndex: 7 /* SERIES_LABEL_ZINDEX */,
          zIndexSubOrder: this.getGroupZIndexSubOrder("labels", subGroupId)
        });
        contentGroup.appendChild(dataNodeGroup);
        contentGroup.appendChild(labelGroup);
        if (markerGroup) {
          contentGroup.appendChild(markerGroup);
        }
        const paths = [];
        for (let index = 0; index < pathsPerSeries; index++) {
          paths[index] = new Path$1();
          paths[index].zIndex = 4 /* SERIES_LAYER_ZINDEX */;
          paths[index].zIndexSubOrder = this.getGroupZIndexSubOrder("paths", index);
          contentGroup.appendChild(paths[index]);
        }
        subGroups.push({
          paths,
          dataNodeGroup,
          markerGroup,
          labelGroup,
          labelSelection: Selection$1.select(labelGroup, Text$1),
          datumSelection: Selection$1.select(
            dataNodeGroup,
            () => this.nodeFactory(),
            datumSelectionGarbageCollection
          ),
          markerSelection: markerGroup ? Selection$1.select(markerGroup, () => this.markerFactory(), markerSelectionGarbageCollection) : void 0
        });
      }
    });
  }
  getGroupZIndexSubOrder(type, subIndex = 0) {
    var _a;
    const result = super.getGroupZIndexSubOrder(type, subIndex);
    if (type === "paths") {
      const pathOffset = (_a = this.opts.pathsZIndexSubOrderOffset[subIndex]) != null ? _a : 0;
      const superFn = result[0];
      if (typeof superFn === "function") {
        result[0] = () => +superFn() + pathOffset;
      } else {
        result[0] = +superFn + pathOffset;
      }
    }
    return result;
  }
  updateNodes(highlightedItems, seriesHighlighted, anySeriesItemEnabled) {
    return __async$1(this, null, function* () {
      var _a;
      const {
        highlightSelection,
        highlightLabelSelection,
        opts: { hasMarkers, hasHighlightedLabels }
      } = this;
      const animationEnabled = !this.ctx.animationManager.isSkipped();
      const visible = this.visible && ((_a = this._contextNodeData) == null ? void 0 : _a.length) > 0 && anySeriesItemEnabled;
      this.rootGroup.visible = animationEnabled || visible;
      this.contentGroup.visible = animationEnabled || visible;
      this.highlightGroup.visible = (animationEnabled || visible) && !!seriesHighlighted;
      const subGroupOpacity = this.getOpacity();
      if (hasMarkers) {
        yield this.updateMarkerNodes({
          markerSelection: highlightSelection,
          isHighlight: true,
          seriesIdx: -1
        });
        this.animationState.transition("highlightMarkers", highlightSelection);
      } else {
        yield this.updateDatumNodes({
          datumSelection: highlightSelection,
          isHighlight: true,
          seriesIdx: -1
        });
        this.animationState.transition("highlight", highlightSelection);
      }
      if (hasHighlightedLabels) {
        yield this.updateLabelNodes({ labelSelection: highlightLabelSelection, seriesIdx: -1 });
      }
      yield Promise.all(
        this.subGroups.map((subGroup, seriesIdx) => __async$1(this, null, function* () {
          const {
            dataNodeGroup,
            markerGroup,
            datumSelection,
            labelSelection,
            markerSelection,
            paths,
            labelGroup
          } = subGroup;
          const { itemId } = this.contextNodeData[seriesIdx];
          const subGroupVisible = visible;
          dataNodeGroup.opacity = subGroupOpacity;
          dataNodeGroup.visible = animationEnabled || subGroupVisible;
          labelGroup.visible = subGroupVisible;
          if (markerGroup) {
            markerGroup.opacity = subGroupOpacity;
            markerGroup.zIndex = dataNodeGroup.zIndex >= 4 /* SERIES_LAYER_ZINDEX */ ? dataNodeGroup.zIndex : dataNodeGroup.zIndex + 1;
            markerGroup.visible = subGroupVisible;
          }
          if (labelGroup) {
            labelGroup.opacity = subGroupOpacity;
          }
          yield this.updatePathNodes({
            seriesHighlighted,
            itemId,
            paths,
            seriesIdx,
            opacity: subGroupOpacity,
            visible: subGroupVisible,
            animationEnabled
          });
          if (!dataNodeGroup.visible) {
            return;
          }
          yield this.updateDatumNodes({ datumSelection, highlightedItems, isHighlight: false, seriesIdx });
          yield this.updateLabelNodes({ labelSelection, seriesIdx });
          if (hasMarkers && markerSelection) {
            yield this.updateMarkerNodes({ markerSelection, isHighlight: false, seriesIdx });
          }
        }))
      );
    });
  }
  getHighlightLabelData(labelData, highlightedItem) {
    const labelItems = labelData.filter(
      (ld) => ld.datum === highlightedItem.datum && ld.itemId === highlightedItem.itemId
    );
    return labelItems.length !== 0 ? labelItems : void 0;
  }
  getHighlightData(_nodeData, highlightedItem) {
    return highlightedItem ? [highlightedItem] : void 0;
  }
  updateHighlightSelection(seriesHighlighted) {
    return __async$1(this, null, function* () {
      var _a;
      const { highlightSelection, highlightLabelSelection, _contextNodeData: contextNodeData } = this;
      const highlightedDatum = (_a = this.ctx.highlightManager) == null ? void 0 : _a.getActiveHighlight();
      const item = seriesHighlighted && (highlightedDatum == null ? void 0 : highlightedDatum.datum) ? highlightedDatum : void 0;
      let labelItems;
      let highlightItems;
      if (item != null) {
        const labelsEnabled = this.isLabelEnabled();
        for (const { labelData, nodeData } of contextNodeData) {
          highlightItems = this.getHighlightData(nodeData, item);
          labelItems = labelsEnabled ? this.getHighlightLabelData(labelData, item) : void 0;
          if ((!labelsEnabled || labelItems != null) && highlightItems != null) {
            break;
          }
        }
      }
      this.highlightSelection = yield this.updateHighlightSelectionItem({
        items: highlightItems,
        highlightSelection
      });
      this.highlightLabelSelection = yield this.updateHighlightSelectionLabel({
        items: labelItems,
        highlightLabelSelection
      });
      return highlightItems;
    });
  }
  pickNodeExactShape(point) {
    var _a;
    const result = super.pickNodeExactShape(point);
    if (result) {
      return result;
    }
    const { x, y } = point;
    const {
      opts: { hasMarkers }
    } = this;
    for (const { dataNodeGroup, markerGroup } of this.subGroups) {
      let match2 = dataNodeGroup.pickNode(x, y);
      if (!match2 && hasMarkers) {
        match2 = markerGroup == null ? void 0 : markerGroup.pickNode(x, y);
      }
      if (match2) {
        break;
      }
    }
    {
      for (const mod2 of this.moduleMap.modules) {
        const { datum } = (_a = mod2.pickNodeExact(point)) != null ? _a : {};
        if (datum !== void 0) {
          return { datum, distance: 0 };
        }
      }
    }
  }
  pickNodeClosestDatum(point) {
    var _a, _b;
    const { x, y } = point;
    const { axes, rootGroup, _contextNodeData: contextNodeData } = this;
    const xAxis = axes["x" /* X */];
    const yAxis = axes["y" /* Y */];
    const hitPoint = rootGroup.transformPoint(x, y);
    let minDistance = Infinity;
    let closestDatum;
    for (const context of contextNodeData) {
      for (const datum of context.nodeData) {
        const { point: { x: datumX = NaN, y: datumY = NaN } = {} } = datum;
        if (isNaN(datumX) || isNaN(datumY)) {
          continue;
        }
        const isInRange = (xAxis == null ? void 0 : xAxis.inRange(datumX)) && (yAxis == null ? void 0 : yAxis.inRange(datumY));
        if (!isInRange) {
          continue;
        }
        const distance = Math.max(__pow$1(hitPoint.x - datumX, 2) + __pow$1(hitPoint.y - datumY, 2), 0);
        if (distance < minDistance) {
          minDistance = distance;
          closestDatum = datum;
        }
      }
    }
    for (const mod2 of this.moduleMap.modules) {
      const modPick = mod2.pickNodeNearest(point);
      if (modPick !== void 0 && modPick.distanceSquared < minDistance) {
        minDistance = modPick.distanceSquared;
        closestDatum = modPick.datum;
        break;
      }
    }
    if (closestDatum) {
      const distance = Math.max(Math.sqrt(minDistance) - ((_b = (_a = closestDatum.point) == null ? void 0 : _a.size) != null ? _b : 0), 0);
      return { datum: closestDatum, distance };
    }
  }
  pickNodeMainAxisFirst(point, requireCategoryAxis) {
    var _a, _b;
    const { x, y } = point;
    const { axes, rootGroup, _contextNodeData: contextNodeData } = this;
    const xAxis = axes["x" /* X */];
    const yAxis = axes["y" /* Y */];
    const directions2 = [xAxis, yAxis].filter((a) => a instanceof CategoryAxis$1).map((a) => a.direction);
    if (requireCategoryAxis && directions2.length === 0) {
      return;
    }
    const [primaryDirection = "x" /* X */] = directions2;
    const hitPoint = rootGroup.transformPoint(x, y);
    const hitPointCoords = primaryDirection === "x" /* X */ ? [hitPoint.x, hitPoint.y] : [hitPoint.y, hitPoint.x];
    const minDistance = [Infinity, Infinity];
    let closestDatum;
    for (const context of contextNodeData) {
      for (const datum of context.nodeData) {
        const { point: { x: datumX = NaN, y: datumY = NaN } = {} } = datum;
        if (isNaN(datumX) || isNaN(datumY)) {
          continue;
        }
        const isInRange = (xAxis == null ? void 0 : xAxis.inRange(datumX)) && (yAxis == null ? void 0 : yAxis.inRange(datumY));
        if (!isInRange) {
          continue;
        }
        const point2 = primaryDirection === "x" /* X */ ? [datumX, datumY] : [datumY, datumX];
        let newMinDistance = true;
        for (let i = 0; i < point2.length; i++) {
          const dist = Math.abs(point2[i] - hitPointCoords[i]);
          if (dist > minDistance[i]) {
            newMinDistance = false;
            break;
          }
          if (dist < minDistance[i]) {
            minDistance[i] = dist;
            minDistance.fill(Infinity, i + 1, minDistance.length);
          }
        }
        if (newMinDistance) {
          closestDatum = datum;
        }
      }
    }
    if (closestDatum) {
      let closestDistanceSquared = Math.max(
        __pow$1(minDistance[0], 2) + __pow$1(minDistance[1], 2) - ((_b = (_a = closestDatum.point) == null ? void 0 : _a.size) != null ? _b : 0),
        0
      );
      for (const mod2 of this.moduleMap.modules) {
        const modPick = mod2.pickNodeMainAxisFirst(point);
        if (modPick !== void 0 && modPick.distanceSquared < closestDistanceSquared) {
          closestDatum = modPick.datum;
          closestDistanceSquared = modPick.distanceSquared;
          break;
        }
      }
      return { datum: closestDatum, distance: Math.sqrt(closestDistanceSquared) };
    }
  }
  onLegendItemClick(event) {
    const { legendItemName } = this.properties;
    const { enabled, itemId, series } = event;
    const matchedLegendItemName = legendItemName != null && legendItemName === event.legendItemName;
    if (series.id === this.id || matchedLegendItemName) {
      this.toggleSeriesItem(itemId, enabled);
    }
  }
  onLegendItemDoubleClick(event) {
    const { enabled, itemId, series, numVisibleItems } = event;
    const { legendItemName } = this.properties;
    const matchedLegendItemName = legendItemName != null && legendItemName === event.legendItemName;
    if (series.id === this.id || matchedLegendItemName) {
      this.toggleSeriesItem(itemId, true);
    } else if (enabled && numVisibleItems === 1) {
      this.toggleSeriesItem(itemId, true);
    } else {
      this.toggleSeriesItem(itemId, false);
    }
  }
  isPathOrSelectionDirty() {
    return false;
  }
  getLabelData() {
    return [];
  }
  shouldFlipXY() {
    return false;
  }
  /**
   * Get the minimum bounding box that contains any adjacent two nodes. The axes are treated independently, so this
   * may not represent the same two points for both directions. The dimensions represent the greatest distance
   * between any two adjacent nodes.
   */
  getMinRect() {
    const [context] = this._contextNodeData;
    if (!(context == null ? void 0 : context.nodeData.length)) {
      return;
    }
    const width = context.nodeData.map(({ midPoint }) => {
      var _a;
      return (_a = midPoint == null ? void 0 : midPoint.x) != null ? _a : 0;
    }).sort((a, b) => a - b).reduce((max, x, i, array) => i > 0 ? Math.max(max, x - array[i - 1]) : max, 0);
    const height = context.nodeData.map(({ midPoint }) => {
      var _a;
      return (_a = midPoint == null ? void 0 : midPoint.y) != null ? _a : 0;
    }).sort((a, b) => a - b).reduce((max, y, i, array) => i > 0 ? Math.max(max, y - array[i - 1]) : max, 0);
    return new BBox$1(0, 0, width, height);
  }
  updateHighlightSelectionItem(opts) {
    return __async$1(this, null, function* () {
      const {
        opts: { hasMarkers }
      } = this;
      const { items, highlightSelection } = opts;
      const nodeData = items != null ? items : [];
      if (hasMarkers) {
        const markerSelection = highlightSelection;
        return this.updateMarkerSelection({ nodeData, markerSelection, seriesIdx: -1 });
      } else {
        return this.updateDatumSelection({
          nodeData,
          datumSelection: highlightSelection,
          seriesIdx: -1
        });
      }
    });
  }
  updateHighlightSelectionLabel(opts) {
    return __async$1(this, null, function* () {
      var _a;
      return this.updateLabelSelection({
        labelData: (_a = opts.items) != null ? _a : [],
        labelSelection: opts.highlightLabelSelection,
        seriesIdx: -1
      });
    });
  }
  updateDatumSelection(opts) {
    return __async$1(this, null, function* () {
      return opts.datumSelection;
    });
  }
  updateDatumNodes(_opts) {
    return __async$1(this, null, function* () {
    });
  }
  updateMarkerSelection(opts) {
    return __async$1(this, null, function* () {
      return opts.markerSelection;
    });
  }
  updateMarkerNodes(_opts) {
    return __async$1(this, null, function* () {
    });
  }
  updatePaths(opts) {
    return __async$1(this, null, function* () {
      opts.paths.forEach((p) => p.visible = false);
    });
  }
  updatePathNodes(opts) {
    return __async$1(this, null, function* () {
      const { paths, opacity, visible } = opts;
      for (const path of paths) {
        path.opacity = opacity;
        path.visible = visible;
      }
    });
  }
  resetAllAnimation(data) {
    var _a, _b;
    const { path, datum, label, marker } = (_b = (_a = this.opts) == null ? void 0 : _a.animationResetFns) != null ? _b : {};
    this.ctx.animationManager.stopByAnimationGroupId(this.id);
    if (path) {
      data.paths.forEach((paths) => {
        resetMotion$1(paths, path);
      });
    }
    if (datum) {
      resetMotion$1(data.datumSelections, datum);
    }
    if (label) {
      resetMotion$1(data.labelSelections, label);
    }
    if (marker) {
      resetMotion$1(data.markerSelections, marker);
    }
    if (data.contextData.some((d) => d.animationValid === false)) {
      this.ctx.animationManager.skipCurrentBatch();
    }
  }
  animateEmptyUpdateReady(data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation(data);
  }
  animateWaitingUpdateReady(data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation(data);
  }
  animateReadyHighlight(data) {
    var _a, _b;
    const { datum } = (_b = (_a = this.opts) == null ? void 0 : _a.animationResetFns) != null ? _b : {};
    if (datum) {
      resetMotion$1([data], datum);
    }
  }
  animateReadyHighlightMarkers(data) {
    var _a, _b;
    const { marker } = (_b = (_a = this.opts) == null ? void 0 : _a.animationResetFns) != null ? _b : {};
    if (marker) {
      resetMotion$1([data], marker);
    }
  }
  animateReadyResize(data) {
    this.resetAllAnimation(data);
  }
  animateClearingUpdateEmpty(data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation(data);
  }
  animationTransitionClear() {
    this.animationState.transition("clear", this.getAnimationData());
  }
  getAnimationData(seriesRect, previousContextData) {
    const animationData = {
      datumSelections: this.subGroups.map(({ datumSelection }) => datumSelection),
      markerSelections: this.subGroups.filter(({ markerSelection }) => markerSelection !== void 0).map(({ markerSelection }) => markerSelection),
      labelSelections: this.subGroups.map(({ labelSelection }) => labelSelection),
      annotationSelections: [...this.annotationSelections],
      contextData: this._contextNodeData,
      previousContextData,
      paths: this.subGroups.map(({ paths }) => paths),
      seriesRect
    };
    return animationData;
  }
  calculateScaling() {
    const result = {};
    const addScale = (direction) => {
      const axis = this.axes[direction];
      if (!axis)
        return;
      if (axis.scale instanceof LogScale$1) {
        const { range: range3, domain } = axis.scale;
        result[direction] = {
          type: "log",
          convert: (domain2) => axis.scale.convert(domain2),
          domain: [domain[0], domain[1]],
          range: [range3[0], range3[1]]
        };
      } else if (axis.scale instanceof ContinuousScale$1) {
        const { range: range3 } = axis.scale;
        const domain = axis.scale.getDomain();
        result[direction] = {
          type: "continuous",
          domain: [domain[0], domain[1]],
          range: [range3[0], range3[1]]
        };
      } else if (axis.scale) {
        const { domain } = axis.scale;
        result[direction] = {
          type: "category",
          domain,
          range: domain.map((d) => axis.scale.convert(d))
        };
      }
    };
    addScale("x" /* X */);
    addScale("y" /* Y */);
    return result;
  }
};

// packages/ag-charts-community/src/chart/cartesianChart.ts
var directions$1 = ["top", "right", "bottom", "left"];
var _CartesianChart$1 = class _CartesianChart extends Chart$1 {
  constructor(specialOverrides, resources) {
    super(specialOverrides, resources);
    /** Integrated Charts feature state - not used in Standalone Charts. */
    this.paired = true;
    this._lastCrossLineIds = void 0;
    this._lastAxisWidths = {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    };
    this._lastVisibility = {
      crossLines: true,
      series: true
    };
  }
  performLayout() {
    return __async$1(this, null, function* () {
      const shrinkRect = yield __superGet$1(_CartesianChart.prototype, this, "performLayout").call(this);
      const { animationRect, seriesRect, visibility, clipSeries } = this.updateAxes(shrinkRect);
      this.seriesRoot.visible = visibility.series;
      this.seriesRect = seriesRect;
      this.animationRect = animationRect;
      this.seriesRoot.translationX = Math.floor(seriesRect.x);
      this.seriesRoot.translationY = Math.floor(seriesRect.y);
      const {
        seriesArea: { padding }
      } = this;
      const seriesPaddedRect = seriesRect.clone().grow({
        top: padding.top,
        right: padding.right,
        bottom: padding.bottom,
        left: padding.left
      });
      this.hoverRect = seriesPaddedRect;
      this.layoutService.dispatchLayoutComplete({
        type: "layout-complete",
        chart: { width: this.scene.width, height: this.scene.height },
        clipSeries,
        series: {
          rect: seriesRect,
          paddedRect: seriesPaddedRect,
          visible: visibility.series,
          shouldFlipXY: this.shouldFlipXY()
        },
        axes: this.axes.map((axis) => __spreadValues$1({ id: axis.id }, axis.getLayoutState()))
      });
      return shrinkRect;
    });
  }
  updateAxes(inputShrinkRect) {
    var _a;
    const crossLineIds = this.axes.flatMap((axis) => {
      var _a2;
      return (_a2 = axis.crossLines) != null ? _a2 : [];
    }).map((crossLine) => crossLine.id);
    const axesValid = this._lastCrossLineIds != null && this._lastCrossLineIds.length === crossLineIds.length && this._lastCrossLineIds.every((id, index) => crossLineIds[index] === id);
    let axisWidths;
    let visibility;
    if (axesValid) {
      axisWidths = __spreadValues$1({}, this._lastAxisWidths);
      visibility = __spreadValues$1({}, this._lastVisibility);
    } else {
      axisWidths = { top: 0, bottom: 0, left: 0, right: 0 };
      visibility = { crossLines: true, series: true };
      this._lastCrossLineIds = crossLineIds;
    }
    const liveAxisWidths = new Set(this._axes.map((a) => a.position));
    for (const position of Object.keys(axisWidths)) {
      if (!liveAxisWidths.has(position)) {
        delete axisWidths[position];
      }
    }
    const stableOutputs = (otherAxisWidths, otherVisibility) => {
      if (Object.keys(otherAxisWidths).some((k) => axisWidths[k] == null)) {
        return false;
      }
      return visibility.crossLines === otherVisibility.crossLines && visibility.series === otherVisibility.series && // Check for existing axis positions and equality.
      Object.entries(axisWidths).every(([p, w]) => {
        const otherW = otherAxisWidths[p];
        if (w != null || otherW != null) {
          return w === otherW;
        }
        return true;
      });
    };
    const ceilValues = (records) => {
      return Object.entries(records).reduce((out, [key, value]) => {
        if (value && Math.abs(value) === Infinity) {
          value = 0;
        }
        out[key] = value != null ? Math.ceil(value) : value;
        return out;
      }, {});
    };
    let lastPassAxisWidths = {};
    let lastPassVisibility = {};
    let clipSeries = false;
    let seriesRect = (_a = this.seriesRect) == null ? void 0 : _a.clone();
    let count2 = 0;
    let primaryTickCounts = {};
    do {
      Object.assign(axisWidths, lastPassAxisWidths);
      Object.assign(visibility, lastPassVisibility);
      const result = this.updateAxesPass(axisWidths, inputShrinkRect.clone(), seriesRect);
      lastPassAxisWidths = ceilValues(result.axisWidths);
      lastPassVisibility = result.visibility;
      clipSeries = result.clipSeries;
      seriesRect = result.seriesRect;
      primaryTickCounts = result.primaryTickCounts;
      if (count2++ > 10) {
        Logger$1.warn("unable to find stable axis layout.");
        break;
      }
    } while (!stableOutputs(lastPassAxisWidths, lastPassVisibility));
    this.axes.forEach((axis) => {
      const { direction } = axis;
      const primaryTickCount = primaryTickCounts[direction];
      axis.update(primaryTickCount);
    });
    const clipRectPadding = 5;
    this.axes.forEach((axis) => {
      axis.setCrossLinesVisible(visibility.crossLines);
      if (!seriesRect) {
        return;
      }
      axis.clipGrid(
        seriesRect.x,
        seriesRect.y,
        seriesRect.width + clipRectPadding,
        seriesRect.height + clipRectPadding
      );
      switch (axis.position) {
        case "left":
        case "right":
          axis.clipTickLines(
            inputShrinkRect.x,
            seriesRect.y,
            inputShrinkRect.width + clipRectPadding,
            seriesRect.height + clipRectPadding
          );
          break;
        case "top":
        case "bottom":
          axis.clipTickLines(
            seriesRect.x,
            inputShrinkRect.y,
            seriesRect.width + clipRectPadding,
            inputShrinkRect.height + clipRectPadding
          );
          break;
      }
    });
    this._lastAxisWidths = axisWidths;
    this._lastVisibility = visibility;
    return { seriesRect, animationRect: inputShrinkRect, visibility, clipSeries };
  }
  updateAxesPass(axisWidths, bounds, lastPassSeriesRect) {
    const { axes } = this;
    const visited = {};
    const newAxisWidths = {};
    const visibility = {
      series: true,
      crossLines: true
    };
    let clipSeries = false;
    const primaryTickCounts = {};
    const paddedBounds = this.applySeriesPadding(bounds);
    const crossLinePadding = lastPassSeriesRect ? this.buildCrossLinePadding(axisWidths) : {};
    const axisBound = this.buildAxisBound(paddedBounds, axisWidths, crossLinePadding, visibility);
    const seriesRect = this.buildSeriesRect(axisBound, axisWidths);
    axes.forEach((axis) => {
      var _a, _b;
      const { position = "left" } = axis;
      const {
        clipSeries: newClipSeries,
        axisThickness,
        axisOffset
      } = this.calculateAxisDimensions({
        axis,
        seriesRect,
        paddedBounds,
        axisWidths,
        newAxisWidths,
        primaryTickCounts,
        clipSeries,
        addInterAxisPadding: ((_a = visited[position]) != null ? _a : 0) > 0
      });
      visited[position] = ((_b = visited[position]) != null ? _b : 0) + 1;
      clipSeries = clipSeries || newClipSeries;
      this.positionAxis({
        axis,
        axisBound,
        axisOffset,
        axisThickness,
        axisWidths,
        primaryTickCounts,
        seriesRect
      });
    });
    return { clipSeries, seriesRect, axisWidths: newAxisWidths, visibility, primaryTickCounts };
  }
  buildCrossLinePadding(axisWidths) {
    var _a;
    const crossLinePadding = {};
    this.axes.forEach((axis) => {
      if (axis.crossLines) {
        axis.crossLines.forEach((crossLine) => {
          crossLine.calculatePadding(crossLinePadding);
        });
      }
    });
    for (const [side, padding = 0] of Object.entries(crossLinePadding)) {
      crossLinePadding[side] = Math.max(padding - ((_a = axisWidths[side]) != null ? _a : 0), 0);
    }
    return crossLinePadding;
  }
  applySeriesPadding(bounds) {
    const paddedRect = bounds.clone();
    const reversedAxes = this.axes.slice().reverse();
    directions$1.forEach((dir) => {
      const padding = this.seriesArea.padding[dir];
      const axis = reversedAxes.find((axis2) => axis2.position === dir);
      if (axis) {
        axis.seriesAreaPadding = padding;
      } else {
        paddedRect.shrink(padding, dir);
      }
    });
    return paddedRect;
  }
  buildAxisBound(bounds, axisWidths, crossLinePadding, visibility) {
    var _a, _b, _c, _d;
    const result = bounds.clone();
    const { top = 0, right = 0, bottom = 0, left = 0 } = crossLinePadding;
    const horizontalPadding = left + right;
    const verticalPadding = top + bottom;
    const totalWidth = ((_a = axisWidths.left) != null ? _a : 0) + ((_b = axisWidths.right) != null ? _b : 0) + horizontalPadding;
    const totalHeight = ((_c = axisWidths.top) != null ? _c : 0) + ((_d = axisWidths.bottom) != null ? _d : 0) + verticalPadding;
    if (result.width <= totalWidth || result.height <= totalHeight) {
      visibility.crossLines = false;
      visibility.series = false;
      return result;
    }
    result.x += left;
    result.y += top;
    result.width -= horizontalPadding;
    result.height -= verticalPadding;
    return result;
  }
  buildSeriesRect(axisBound, axisWidths) {
    const result = axisBound.clone();
    const { top, bottom, left, right } = axisWidths;
    result.x += left != null ? left : 0;
    result.y += top != null ? top : 0;
    result.width -= (left != null ? left : 0) + (right != null ? right : 0);
    result.height -= (top != null ? top : 0) + (bottom != null ? bottom : 0);
    result.width = Math.max(0, result.width);
    result.height = Math.max(0, result.height);
    return result;
  }
  clampToOutsideSeriesRect(seriesRect, value, dimension, direction) {
    const { x, y, width, height } = seriesRect;
    const clampBounds = [x, y, x + width, y + height];
    const fn = direction === 1 ? Math.min : Math.max;
    const compareTo = clampBounds[(dimension === "x" ? 0 : 1) + (direction === 1 ? 0 : 2)];
    return fn(value, compareTo);
  }
  calculateAxisDimensions(opts) {
    var _a, _b, _c, _d, _e;
    const { axis, seriesRect, paddedBounds, axisWidths, newAxisWidths, primaryTickCounts, addInterAxisPadding } = opts;
    let { clipSeries } = opts;
    const { position = "left", direction } = axis;
    const axisLeftRightRange = (axis2) => {
      if (axis2 instanceof CategoryAxis$1 || axis2 instanceof GroupedCategoryAxis$1) {
        return [0, seriesRect.height];
      }
      return [seriesRect.height, 0];
    };
    const axisOffset = (_a = newAxisWidths[position]) != null ? _a : 0;
    switch (position) {
      case "top":
      case "bottom":
        axis.range = [0, seriesRect.width];
        axis.gridLength = seriesRect.height;
        break;
      case "right":
      case "left":
        axis.range = axisLeftRightRange(axis);
        axis.gridLength = seriesRect.width;
        break;
    }
    const zoom = this.zoomManager.getAxisZoom(axis.id);
    const { min = 0, max = 1 } = zoom != null ? zoom : {};
    axis.visibleRange = [min, max];
    const rangeClipped = axis.dataDomain.clipped || axis.visibleRange[0] > 0 || axis.visibleRange[1] < 1;
    clipSeries || (clipSeries = rangeClipped);
    let primaryTickCount = axis.nice ? primaryTickCounts[direction] : void 0;
    const paddedBoundsCoefficient = 0.3;
    if (axis.thickness != null && axis.thickness > 0) {
      axis.maxThickness = axis.thickness;
    } else if (direction === "y" /* Y */) {
      axis.maxThickness = paddedBounds.width * paddedBoundsCoefficient;
    } else {
      axis.maxThickness = paddedBounds.height * paddedBoundsCoefficient;
    }
    const layout = axis.calculateLayout(primaryTickCount);
    primaryTickCount = layout.primaryTickCount;
    primaryTickCounts[direction] = (_b = primaryTickCounts[direction]) != null ? _b : primaryTickCount;
    let axisThickness = 0;
    if (axis.thickness != null && axis.thickness > 0) {
      axisThickness = axis.thickness;
    } else {
      const { bbox } = layout;
      axisThickness = direction === "x" /* X */ ? bbox.height : bbox.width;
    }
    const axisPadding = 15;
    if (addInterAxisPadding) {
      axisThickness += axisPadding;
    }
    axisThickness = Math.ceil(axisThickness);
    newAxisWidths[position] = ((_c = newAxisWidths[position]) != null ? _c : 0) + axisThickness;
    axis.gridPadding = ((_d = axisWidths[position]) != null ? _d : 0) - ((_e = newAxisWidths[position]) != null ? _e : 0);
    return { clipSeries, axisThickness, axisOffset, primaryTickCount };
  }
  positionAxis(opts) {
    var _a, _b, _c, _d;
    const { axis, axisBound, axisWidths, seriesRect, axisOffset, axisThickness } = opts;
    const { position } = axis;
    switch (position) {
      case "top":
        axis.translation.x = axisBound.x + ((_a = axisWidths.left) != null ? _a : 0);
        axis.translation.y = this.clampToOutsideSeriesRect(
          seriesRect,
          axisBound.y + 1 + axisOffset + axisThickness,
          "y",
          1
        );
        break;
      case "bottom":
        axis.translation.x = axisBound.x + ((_b = axisWidths.left) != null ? _b : 0);
        axis.translation.y = this.clampToOutsideSeriesRect(
          seriesRect,
          axisBound.y + axisBound.height + 1 - axisThickness - axisOffset,
          "y",
          -1
        );
        break;
      case "left":
        axis.translation.y = axisBound.y + ((_c = axisWidths.top) != null ? _c : 0);
        axis.translation.x = this.clampToOutsideSeriesRect(
          seriesRect,
          axisBound.x + axisOffset + axisThickness,
          "x",
          1
        );
        break;
      case "right":
        axis.translation.y = axisBound.y + ((_d = axisWidths.top) != null ? _d : 0);
        axis.translation.x = this.clampToOutsideSeriesRect(
          seriesRect,
          axisBound.x + axisBound.width - axisThickness - axisOffset,
          "x",
          -1
        );
        break;
    }
    axis.updatePosition({ rotation: toRadians$2(axis.rotation), sideFlag: axis.label.getSideFlag() });
  }
  shouldFlipXY() {
    return !this.series.some((series) => !(series instanceof CartesianSeries$1 && series.shouldFlipXY()));
  }
};
_CartesianChart$1.className = "CartesianChart";
_CartesianChart$1.type = "cartesian";
var CartesianChart = _CartesianChart$1;

// packages/ag-charts-community/src/chart/chartProxy.ts
var AgChartInstanceProxy = class _AgChartInstanceProxy {
  constructor(chart) {
    this.chart = chart;
  }
  static isInstance(x) {
    var _a;
    if (x instanceof _AgChartInstanceProxy) {
      return true;
    }
    if (((_a = x.constructor) == null ? void 0 : _a.name) === "AgChartInstanceProxy" && x.chart != null) {
      return true;
    }
    return x.chart != null && this.validateImplementation(x);
  }
  static validateImplementation(x) {
    var _a, _b;
    const chartProps = ["getOptions", "destroy"];
    const signatureProps = Object.keys((_b = (_a = x.constructor) == null ? void 0 : _a.prototype) != null ? _b : {});
    return chartProps.every((prop) => signatureProps.includes(prop));
  }
  getOptions() {
    return this.chart.getOptions();
  }
  destroy() {
    this.chart.destroy();
  }
};

// packages/ag-charts-community/src/util/secondaryAxisTicks.ts
function calculateNiceSecondaryAxis$1(domain, primaryTickCount, reverse) {
  let start = Math.floor(Math.min(domain[0], domain[1]));
  let stop = Math.max(domain[0], domain[1]);
  start = calculateNiceStart$1(start, stop, primaryTickCount);
  const step = getTickStep$1(start, stop, primaryTickCount);
  const segments = primaryTickCount - 1;
  stop = start + segments * step;
  const d = reverse ? [stop, start] : [start, stop];
  const ticks = getTicks$1(start, step, primaryTickCount);
  return [d, ticks];
}
function calculateNiceStart$1(a, b, count2) {
  const rawStep = Math.abs(b - a) / (count2 - 1);
  const order = Math.floor(Math.log10(rawStep));
  const magnitude = Math.pow(10, order);
  return Math.floor(a / magnitude) * magnitude;
}
function getTicks$1(start, step, count2) {
  const stepPower = Math.floor(Math.log10(step));
  const fractionDigits = step > 0 && step < 1 ? Math.abs(stepPower) : 0;
  const f = Math.pow(10, fractionDigits);
  const ticks = createNumericTicks$1(fractionDigits);
  for (let i = 0; i < count2; i++) {
    const tick = start + step * i;
    ticks[i] = Math.round(tick * f) / f;
  }
  return ticks;
}
function getTickStep$1(start, stop, count2) {
  const segments = count2 - 1;
  const rawStep = (stop - start) / segments;
  return calculateNextNiceStep$1(rawStep);
}
function calculateNextNiceStep$1(rawStep) {
  const order = Math.floor(Math.log10(rawStep));
  const magnitude = Math.pow(10, order);
  const step = rawStep / magnitude * 10;
  if (step > 0 && step <= 1) {
    return magnitude / 10;
  }
  if (step > 1 && step <= 2) {
    return 2 * magnitude / 10;
  }
  if (step > 1 && step <= 5) {
    return 5 * magnitude / 10;
  }
  if (step > 5 && step <= 10) {
    return 10 * magnitude / 10;
  }
  if (step > 10 && step <= 20) {
    return 20 * magnitude / 10;
  }
  if (step > 20 && step <= 40) {
    return 40 * magnitude / 10;
  }
  if (step > 40 && step <= 50) {
    return 50 * magnitude / 10;
  }
  if (step > 50 && step <= 100) {
    return 100 * magnitude / 10;
  }
  return step;
}

// packages/ag-charts-community/src/chart/axis/numberAxis.ts
var NumberAxisTick$1 = class extends AxisTick$1 {
  constructor() {
    super(...arguments);
    this.maxSpacing = NaN;
  }
};
__decorateClass$1([
  Validate$1(OR$1(AND$1(NUMBER$1.restrict({ min: 1 }), GREATER_THAN$1("minSpacing")), NAN$1)),
  Default$1(NaN)
], NumberAxisTick$1.prototype, "maxSpacing", 2);
var NumberAxis$1 = class extends CartesianAxis$1 {
  constructor(moduleCtx, scale2 = new LinearScale$2()) {
    super(moduleCtx, scale2);
    this.min = NaN;
    this.max = NaN;
  }
  normaliseDataDomain(d) {
    const { min, max } = this;
    const { extent: extent2, clipped } = normalisedExtentWithMetadata$1(d, min, max);
    return { domain: extent2, clipped };
  }
  formatDatum(datum) {
    if (typeof datum === "number") {
      return datum.toFixed(2);
    } else {
      Logger$1.warnOnce(
        "data contains Date objects which are being plotted against a number axis, please only use a number axis for numbers."
      );
      return String(datum);
    }
  }
  createTick() {
    return new NumberAxisTick$1();
  }
  updateSecondaryAxisTicks(primaryTickCount) {
    if (this.dataDomain == null) {
      throw new Error("AG Charts - dataDomain not calculated, cannot perform tick calculation.");
    }
    if (this.dataDomain.domain.length === 0)
      return [];
    const [d, ticks] = calculateNiceSecondaryAxis$1(this.dataDomain.domain, primaryTickCount != null ? primaryTickCount : 0, this.reverse);
    this.scale.nice = false;
    this.scale.domain = d;
    this.scale.update();
    return ticks;
  }
};
NumberAxis$1.className = "NumberAxis";
NumberAxis$1.type = "number";
__decorateClass$1([
  Validate$1(AND$1(NUMBER_OR_NAN$1, LESS_THAN$1("max"))),
  Default$1(NaN)
], NumberAxis$1.prototype, "min", 2);
__decorateClass$1([
  Validate$1(AND$1(NUMBER_OR_NAN$1, GREATER_THAN$1("min"))),
  Default$1(NaN)
], NumberAxis$1.prototype, "max", 2);

// packages/ag-charts-community/src/chart/axis/logAxis.ts
var NON_ZERO_NUMBER$1 = predicateWithMessage$1((value) => isNumber$5(value) && value !== 0, "a non-zero number");
var LogAxis$1 = class extends NumberAxis$1 {
  constructor(moduleCtx) {
    super(moduleCtx, new LogScale$1());
    this.min = NaN;
    this.max = NaN;
  }
  normaliseDataDomain(d) {
    const { min, max } = this;
    const { extent: extent2, clipped } = normalisedExtentWithMetadata$1(d, min, max);
    const isInverted = extent2[0] > extent2[1];
    const crossesZero = extent2[0] < 0 && extent2[1] > 0;
    const hasZeroExtent = extent2[0] === 0 && extent2[1] === 0;
    const invalidDomain = isInverted || crossesZero || hasZeroExtent;
    if (invalidDomain) {
      d = [];
      if (crossesZero) {
        Logger$1.warn(
          `the data domain crosses zero, the chart data cannot be rendered. See log axis documentation for more information.`
        );
      } else if (hasZeroExtent) {
        Logger$1.warn(`the data domain has 0 extent, no data is rendered.`);
      }
    }
    if (extent2[0] === 0) {
      extent2[0] = 1;
    }
    if (extent2[1] === 0) {
      extent2[1] = -1;
    }
    return { domain: extent2, clipped };
  }
  set base(value) {
    this.scale.base = value;
  }
  get base() {
    return this.scale.base;
  }
};
LogAxis$1.className = "LogAxis";
LogAxis$1.type = "log";
__decorateClass$1([
  Validate$1(AND$1(NUMBER_OR_NAN$1, NON_ZERO_NUMBER$1, LESS_THAN$1("max"))),
  Default$1(NaN)
], LogAxis$1.prototype, "min", 2);
__decorateClass$1([
  Validate$1(AND$1(NUMBER_OR_NAN$1, NON_ZERO_NUMBER$1, GREATER_THAN$1("min"))),
  Default$1(NaN)
], LogAxis$1.prototype, "max", 2);

// packages/ag-charts-community/src/chart/axis/timeAxis.ts
var MAX_SPACING2$1 = OR$1(AND$1(NUMBER$1.restrict({ min: 1 }), GREATER_THAN$1("minSpacing")), NAN$1);
var TimeAxisTick$1 = class extends AxisTick$1 {
  constructor() {
    super(...arguments);
    this.maxSpacing = NaN;
  }
};
__decorateClass$1([
  Validate$1(MAX_SPACING2$1),
  Default$1(NaN)
], TimeAxisTick$1.prototype, "maxSpacing", 2);
var TimeAxis$1 = class extends CartesianAxis$1 {
  constructor(moduleCtx) {
    super(moduleCtx, new TimeScale$2());
    this.datumFormat = "%m/%d/%y, %H:%M:%S";
    this.min = void 0;
    this.max = void 0;
    const { scale: scale2 } = this;
    this.refreshScale();
    this.datumFormatter = scale2.tickFormat({
      specifier: this.datumFormat
    });
  }
  normaliseDataDomain(d) {
    var _a;
    let { min, max } = this;
    let clipped = false;
    if (typeof min === "number") {
      min = new Date(min);
    }
    if (typeof max === "number") {
      max = new Date(max);
    }
    if (d.length > 2) {
      d = ((_a = extent$5(d)) != null ? _a : [0, 1e3]).map((x) => new Date(x));
    }
    if (min instanceof Date) {
      clipped || (clipped = min > d[0]);
      d = [min, d[1]];
    }
    if (max instanceof Date) {
      clipped || (clipped = max < d[1]);
      d = [d[0], max];
    }
    if (d[0] > d[1]) {
      d = [];
    }
    return { domain: d, clipped };
  }
  createTick() {
    return new TimeAxisTick$1();
  }
  onLabelFormatChange(ticks, format2) {
    if (format2) {
      super.onLabelFormatChange(ticks, format2);
    } else {
      this.labelFormatter = this.scale.tickFormat({ ticks });
    }
  }
  formatDatum(datum) {
    var _a;
    return (_a = this.moduleCtx.callbackCache.call(this.datumFormatter, datum)) != null ? _a : String(datum);
  }
  calculatePadding(_min, _max, reverse) {
    return reverse ? [1, 0] : [0, 1];
  }
};
TimeAxis$1.className = "TimeAxis";
TimeAxis$1.type = "time";
__decorateClass$1([
  Validate$1(AND$1(DATE_OR_DATETIME_MS$1, LESS_THAN$1("max")), { optional: true })
], TimeAxis$1.prototype, "min", 2);
__decorateClass$1([
  Validate$1(AND$1(DATE_OR_DATETIME_MS$1, GREATER_THAN$1("min")), { optional: true })
], TimeAxis$1.prototype, "max", 2);

// packages/ag-charts-community/src/chart/factory/axisTypes.ts
var AXIS_CONSTRUCTORS$1 = {
  [NumberAxis$1.type]: NumberAxis$1,
  [CategoryAxis$1.type]: CategoryAxis$1,
  [TimeAxis$1.type]: TimeAxis$1,
  [GroupedCategoryAxis$1.type]: GroupedCategoryAxis$1,
  [LogAxis$1.type]: LogAxis$1
};
function registerAxis(axisType, ctor) {
  AXIS_CONSTRUCTORS$1[axisType] = ctor;
}
function getAxis(axisType, moduleCtx) {
  const axisConstructor = AXIS_CONSTRUCTORS$1[axisType];
  if (axisConstructor) {
    return new axisConstructor(moduleCtx);
  }
  throw new Error(`AG Charts - unknown axis type: ${axisType}`);
}
var AXIS_TYPES$1 = {
  has(axisType) {
    return Object.hasOwn(AXIS_CONSTRUCTORS$1, axisType);
  },
  get axesTypes() {
    return Object.keys(AXIS_CONSTRUCTORS$1);
  }
};
var AXIS_THEME_TEMPLATES$1 = {};
function registerAxisThemeTemplate(axisType, theme) {
  AXIS_THEME_TEMPLATES$1[axisType] = theme;
}
function getAxisThemeTemplate$1(axisType) {
  var _a;
  return (_a = AXIS_THEME_TEMPLATES$1[axisType]) != null ? _a : {};
}

// packages/ag-charts-community/src/chart/factory/expectedEnterpriseModules.ts
var EXPECTED_ENTERPRISE_MODULES = [
  { type: "root", optionsKey: "animation", chartTypes: ["cartesian", "polar", "hierarchy"] },
  {
    type: "root",
    optionsKey: "background",
    chartTypes: ["cartesian", "polar", "hierarchy"],
    optionsInnerKey: "image"
  },
  { type: "root", optionsKey: "contextMenu", chartTypes: ["cartesian", "polar", "hierarchy"] },
  { type: "root", optionsKey: "zoom", chartTypes: ["cartesian"] },
  {
    type: "legend",
    optionsKey: "gradientLegend",
    chartTypes: ["cartesian", "polar", "hierarchy"],
    identifier: "gradient"
  },
  { type: "axis", optionsKey: "axes[]", chartTypes: ["polar"], identifier: "angle-category" },
  { type: "axis", optionsKey: "axes[]", chartTypes: ["polar"], identifier: "angle-number" },
  { type: "axis", optionsKey: "axes[]", chartTypes: ["polar"], identifier: "radius-category" },
  { type: "axis", optionsKey: "axes[]", chartTypes: ["polar"], identifier: "radius-number" },
  { type: "axis-option", optionsKey: "crosshair", chartTypes: ["cartesian"] },
  { type: "series", optionsKey: "series[]", chartTypes: ["cartesian"], identifier: "box-plot" },
  { type: "series", optionsKey: "series[]", chartTypes: ["cartesian"], identifier: "bullet" },
  { type: "series", optionsKey: "series[]", chartTypes: ["cartesian"], identifier: "heatmap" },
  { type: "series", optionsKey: "series[]", chartTypes: ["polar"], identifier: "nightingale" },
  { type: "series", optionsKey: "series[]", chartTypes: ["polar"], identifier: "radar-area" },
  { type: "series", optionsKey: "series[]", chartTypes: ["polar"], identifier: "radar-line" },
  { type: "series", optionsKey: "series[]", chartTypes: ["polar"], identifier: "radial-bar" },
  { type: "series", optionsKey: "series[]", chartTypes: ["polar"], identifier: "radial-column" },
  { type: "series", optionsKey: "series[]", chartTypes: ["cartesian"], identifier: "range-area" },
  { type: "series", optionsKey: "series[]", chartTypes: ["cartesian"], identifier: "range-bar" },
  { type: "series", optionsKey: "series[]", chartTypes: ["hierarchy"], identifier: "sunburst" },
  { type: "series", optionsKey: "series[]", chartTypes: ["hierarchy"], identifier: "treemap" },
  { type: "series", optionsKey: "series[]", chartTypes: ["cartesian"], identifier: "waterfall" },
  { type: "series-option", optionsKey: "errorBar", chartTypes: ["cartesian"], identifier: "error-bars" }
];
function isEnterpriseSeriesType(type) {
  return EXPECTED_ENTERPRISE_MODULES.some((s) => s.type === "series" && s.identifier === type);
}
function getEnterpriseSeriesChartTypes(type) {
  var _a;
  return (_a = EXPECTED_ENTERPRISE_MODULES.find((s) => s.type === "series" && s.identifier === type)) == null ? void 0 : _a.chartTypes;
}
function isEnterpriseSeriesTypeLoaded(type) {
  var _a, _b;
  return ((_b = (_a = EXPECTED_ENTERPRISE_MODULES.find((s) => s.type === "series" && s.identifier === type)) == null ? void 0 : _a.useCount) != null ? _b : 0) > 0;
}
function isEnterpriseCartesian(seriesType) {
  var _a;
  const type = (_a = getEnterpriseSeriesChartTypes(seriesType)) == null ? void 0 : _a.find((v) => v === "cartesian");
  return type === "cartesian";
}
function isEnterprisePolar(seriesType) {
  var _a;
  const type = (_a = getEnterpriseSeriesChartTypes(seriesType)) == null ? void 0 : _a.find((v) => v === "polar");
  return type === "polar";
}
function isEnterpriseHierarchy(seriesType) {
  var _a;
  const type = (_a = getEnterpriseSeriesChartTypes(seriesType)) == null ? void 0 : _a.find((v) => v === "hierarchy");
  return type === "hierarchy";
}
function verifyIfModuleExpected(module) {
  var _a;
  if (module.packageType !== "enterprise") {
    throw new Error("AG Charts - internal configuration error, only enterprise modules need verification.");
  }
  const stub = EXPECTED_ENTERPRISE_MODULES.find((s) => {
    return s.type === module.type && s.optionsKey === module.optionsKey && s.identifier === module.identifier && module.chartTypes.every((t) => s.chartTypes.includes(t));
  });
  if (stub) {
    (_a = stub.useCount) != null ? _a : stub.useCount = 0;
    stub.useCount++;
  }
  return stub != null;
}
function getUnusedExpectedModules() {
  return EXPECTED_ENTERPRISE_MODULES.filter(({ useCount }) => useCount == null || useCount === 0);
}
var LEGEND_KEYS = {
  category: "legend"
};
function registerLegend(type, key, ctr, theme) {
  LEGEND_KEYS[type] = key;
  LEGEND_THEME_TEMPLATES$1[key] = theme;
}
var LEGEND_THEME_TEMPLATES$1 = {};
function getLegendThemeTemplates$1() {
  return LEGEND_THEME_TEMPLATES$1;
}
function getLegendKeys() {
  return LEGEND_KEYS;
}

// packages/ag-charts-community/src/chart/background/background.ts
var Background$1 = class extends BaseModuleInstance$1 {
  constructor(ctx) {
    var _a;
    super();
    this.node = new Group$1({ name: "background", zIndex: 0 /* SERIES_BACKGROUND_ZINDEX */ });
    this.rectNode = new Rect$1();
    this.visible = true;
    this.fill = "white";
    // placeholder for enterprise module
    this.image = void 0;
    this.node.appendChild(this.rectNode);
    (_a = ctx.scene.root) == null ? void 0 : _a.appendChild(this.node);
    this.destroyFns.push(
      () => {
        var _a2;
        return (_a2 = ctx.scene.root) == null ? void 0 : _a2.removeChild(this.node);
      },
      ctx.layoutService.addListener("layout-complete", (e) => this.onLayoutComplete(e))
    );
  }
  onLayoutComplete(e) {
    const { width, height } = e.chart;
    this.rectNode.width = width;
    this.rectNode.height = height;
  }
};
__decorateClass$1([
  Validate$1(BOOLEAN$1),
  ProxyPropertyOnWrite$1("node", "visible")
], Background$1.prototype, "visible", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true }),
  ProxyPropertyOnWrite$1("rectNode", "fill")
], Background$1.prototype, "fill", 2);

// packages/ag-charts-community/src/chart/background/backgroundModule.ts
var BackgroundModule$1 = {
  type: "root",
  optionsKey: "background",
  packageType: "community",
  chartTypes: ["cartesian", "polar", "hierarchy"],
  instanceConstructor: Background$1
};

// packages/ag-charts-community/src/chart/navigator/navigatorHandle.ts
var NavigatorHandle$1 = class {
  set fill(value) {
    this.rh.fill = value;
  }
  get fill() {
    return this.rh.fill;
  }
  set stroke(value) {
    this.rh.stroke = value;
  }
  get stroke() {
    return this.rh.stroke;
  }
  set strokeWidth(value) {
    this.rh.strokeWidth = value;
  }
  get strokeWidth() {
    return this.rh.strokeWidth;
  }
  set width(value) {
    this.rh.width = value;
  }
  get width() {
    return this.rh.width;
  }
  set height(value) {
    this.rh.height = value;
  }
  get height() {
    return this.rh.height;
  }
  set gripLineGap(value) {
    this.rh.gripLineGap = value;
  }
  get gripLineGap() {
    return this.rh.gripLineGap;
  }
  set gripLineLength(value) {
    this.rh.gripLineLength = value;
  }
  get gripLineLength() {
    return this.rh.gripLineLength;
  }
  constructor(rangeHandle) {
    this.rh = rangeHandle;
  }
};

// packages/ag-charts-community/src/chart/navigator/navigatorMask.ts
var NavigatorMask$1 = class {
  set fill(value) {
    this.rm.fill = value;
  }
  get fill() {
    return this.rm.fill;
  }
  set stroke(value) {
    this.rm.stroke = value;
  }
  get stroke() {
    return this.rm.stroke;
  }
  set strokeWidth(value) {
    this.rm.strokeWidth = value;
  }
  get strokeWidth() {
    return this.rm.strokeWidth;
  }
  set fillOpacity(value) {
    this.rm.fillOpacity = value;
  }
  get fillOpacity() {
    return this.rm.fillOpacity;
  }
  constructor(rangeMask) {
    this.rm = rangeMask;
  }
};

// packages/ag-charts-community/src/chart/navigator/shapes/rangeHandle.ts
var RangeHandle$2 = class extends Path$1 {
  constructor() {
    super(...arguments);
    this._fill = "#f2f2f2";
    this._stroke = "#999999";
    this._strokeWidth = 1;
    this._lineCap = "square";
    this._centerX = 0;
    this._centerY = 0;
    this._width = 8;
    this._gripLineGap = 2;
    this._gripLineLength = 8;
    this._height = 16;
  }
  set centerX(value) {
    if (this._centerX !== value) {
      this._centerX = value;
      this.dirtyPath = true;
    }
  }
  get centerX() {
    return this._centerX;
  }
  set centerY(value) {
    if (this._centerY !== value) {
      this._centerY = value;
      this.dirtyPath = true;
    }
  }
  get centerY() {
    return this._centerY;
  }
  set width(value) {
    if (this._width !== value) {
      this._width = value;
      this.dirtyPath = true;
    }
  }
  get width() {
    return this._width;
  }
  set gripLineGap(value) {
    if (this._gripLineGap !== value) {
      this._gripLineGap = value;
      this.dirtyPath = true;
    }
  }
  get gripLineGap() {
    return this._gripLineGap;
  }
  set gripLineLength(value) {
    if (this._gripLineLength !== value) {
      this._gripLineLength = value;
      this.dirtyPath = true;
    }
  }
  get gripLineLength() {
    return this._gripLineLength;
  }
  set height(value) {
    if (this._height !== value) {
      this._height = value;
      this.dirtyPath = true;
    }
  }
  get height() {
    return this._height;
  }
  computeBBox() {
    const { centerX, centerY, width, height } = this;
    const x = centerX - width / 2;
    const y = centerY - height / 2;
    return new BBox$1(x, y, width, height);
  }
  isPointInPath(x, y) {
    const point = this.transformPoint(x, y);
    const bbox = this.computeBBox();
    return bbox.containsPoint(point.x, point.y);
  }
  updatePath() {
    const { path, centerX, centerY, width, height } = this;
    path.clear();
    const x = centerX - width / 2;
    const y = centerY - height / 2;
    const ax = this.align(x);
    const ay = this.align(y);
    const axw = ax + this.align(x, width);
    const ayh = ay + this.align(y, height);
    path.moveTo(ax, ay);
    path.lineTo(axw, ay);
    path.lineTo(axw, ayh);
    path.lineTo(ax, ayh);
    path.lineTo(ax, ay);
    const dx = this.gripLineGap / 2;
    const dy = this.gripLineLength / 2;
    path.moveTo(this.align(centerX - dx), this.align(centerY - dy));
    path.lineTo(this.align(centerX - dx), this.align(centerY + dy));
    path.moveTo(this.align(centerX + dx), this.align(centerY - dy));
    path.lineTo(this.align(centerX + dx), this.align(centerY + dy));
  }
};
RangeHandle$2.className = "RangeHandle";
__decorateClass$1([
  Validate$1(COLOR_STRING$1)
], RangeHandle$2.prototype, "_fill", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1)
], RangeHandle$2.prototype, "_stroke", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], RangeHandle$2.prototype, "_strokeWidth", 2);
__decorateClass$1([
  Validate$1(LINE_CAP$1)
], RangeHandle$2.prototype, "_lineCap", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], RangeHandle$2.prototype, "_width", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], RangeHandle$2.prototype, "_gripLineGap", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], RangeHandle$2.prototype, "_gripLineLength", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], RangeHandle$2.prototype, "_height", 2);

// packages/ag-charts-community/src/chart/navigator/shapes/rangeMask.ts
function markDirtyOnChange$1(newValue, oldValue) {
  if (newValue !== oldValue) {
    this.dirtyPath = true;
  }
}
var RangeMask$1 = class extends Path$1 {
  constructor() {
    super(...arguments);
    this.x = 0;
    this.y = 0;
    this.width = 200;
    this.height = 30;
    this.minRange = 0.05;
    this._min = 0;
    this._max = 1;
  }
  set min(value) {
    var _a;
    value = clamp2$1(0, value, this.max - this.minRange);
    if (this._min !== value && !isNaN(value)) {
      this._min = value;
      this.dirtyPath = true;
      (_a = this.onRangeChange) == null ? void 0 : _a.call(this);
    }
  }
  get min() {
    return this._min;
  }
  set max(value) {
    var _a;
    value = clamp2$1(this.min + this.minRange, value, 1);
    if (this._max !== value && !isNaN(value)) {
      this._max = value;
      this.dirtyPath = true;
      (_a = this.onRangeChange) == null ? void 0 : _a.call(this);
    }
  }
  get max() {
    return this._max;
  }
  computeBBox() {
    const { x, y, width, height } = this;
    return new BBox$1(x, y, width, height);
  }
  computeVisibleRangeBBox() {
    const { x, y, width, height, min, max } = this;
    const minX = x + width * min;
    const maxX = x + width * max;
    return new BBox$1(minX, y, maxX - minX, height);
  }
  updatePath() {
    const { path, x, y, width, height, min, max } = this;
    path.clear();
    const ax = this.align(x);
    const ay = this.align(y);
    const axw = ax + this.align(x, width);
    const ayh = ay + this.align(y, height);
    path.moveTo(ax, ay);
    path.lineTo(axw, ay);
    path.lineTo(axw, ayh);
    path.lineTo(ax, ayh);
    path.lineTo(ax, ay);
    const minX = this.align(x + width * min);
    const maxX = this.align(x + width * max);
    path.moveTo(minX, ay);
    path.lineTo(minX, ayh);
    path.lineTo(maxX, ayh);
    path.lineTo(maxX, ay);
    path.lineTo(minX, ay);
  }
};
RangeMask$1.className = "RangeMask";
__decorateClass$1([
  ActionOnSet$1({ changeValue: markDirtyOnChange$1 }),
  Validate$1(POSITIVE_NUMBER$1)
], RangeMask$1.prototype, "x", 2);
__decorateClass$1([
  ActionOnSet$1({ changeValue: markDirtyOnChange$1 }),
  Validate$1(POSITIVE_NUMBER$1)
], RangeMask$1.prototype, "y", 2);
__decorateClass$1([
  ActionOnSet$1({ changeValue: markDirtyOnChange$1 }),
  Validate$1(POSITIVE_NUMBER$1)
], RangeMask$1.prototype, "width", 2);
__decorateClass$1([
  ActionOnSet$1({ changeValue: markDirtyOnChange$1 }),
  Validate$1(POSITIVE_NUMBER$1)
], RangeMask$1.prototype, "height", 2);
__decorateClass$1([
  Validate$1(NUMBER$1)
], RangeMask$1.prototype, "_min", 2);
__decorateClass$1([
  Validate$1(NUMBER$1)
], RangeMask$1.prototype, "_max", 2);

// packages/ag-charts-community/src/chart/navigator/shapes/rangeSelector.ts
var _RangeSelector$1 = class _RangeSelector extends Group$1 {
  constructor() {
    super({ name: "rangeSelectorGroup" });
    this.minHandle = new RangeHandle$2();
    this.maxHandle = new RangeHandle$2();
    this.mask = (() => {
      const { x, y, width, height, min, max } = _RangeSelector.defaults;
      const mask = new RangeMask$1();
      mask.x = x;
      mask.y = y;
      mask.width = width;
      mask.height = height;
      mask.min = min;
      mask.max = max;
      const { minHandle, maxHandle } = this;
      minHandle.centerX = x;
      maxHandle.centerX = x + width;
      minHandle.centerY = maxHandle.centerY = y + height / 2;
      this.append([mask, minHandle, maxHandle]);
      mask.onRangeChange = () => {
        var _a;
        this.updateHandles();
        (_a = this.onRangeChange) == null ? void 0 : _a.call(this);
      };
      return mask;
    })();
    this._x = _RangeSelector.defaults.x;
    this._y = _RangeSelector.defaults.y;
    this._width = _RangeSelector.defaults.width;
    this._height = _RangeSelector.defaults.height;
    this._min = _RangeSelector.defaults.min;
    this._max = _RangeSelector.defaults.max;
    this.isContainerNode = true;
  }
  set x(value) {
    this.mask.x = value;
    this.updateHandles();
  }
  get x() {
    return this.mask.x;
  }
  set y(value) {
    this.mask.y = value;
    this.updateHandles();
  }
  get y() {
    return this.mask.y;
  }
  set width(value) {
    this.mask.width = value;
    this.updateHandles();
  }
  get width() {
    return this.mask.width;
  }
  set height(value) {
    this.mask.height = value;
    this.updateHandles();
  }
  get height() {
    return this.mask.height;
  }
  set min(value) {
    this.mask.min = value;
  }
  get min() {
    return this.mask.min;
  }
  set max(value) {
    this.mask.max = value;
  }
  get max() {
    return this.mask.max;
  }
  updateHandles() {
    const { minHandle, maxHandle, x, y, width, height, mask } = this;
    minHandle.centerX = x + width * mask.min;
    maxHandle.centerX = x + width * mask.max;
    minHandle.centerY = maxHandle.centerY = y + height / 2;
  }
  computeBBox() {
    return this.mask.computeBBox();
  }
  computeVisibleRangeBBox() {
    return this.mask.computeVisibleRangeBBox();
  }
  render(renderCtx) {
    const { ctx, forceRender, stats } = renderCtx;
    if (this.dirty === 0 /* NONE */ && !forceRender) {
      if (stats)
        stats.nodesSkipped++;
      return;
    }
    this.computeTransformMatrix();
    this.matrix.toContext(ctx);
    const { mask, minHandle, maxHandle } = this;
    [mask, minHandle, maxHandle].forEach((child) => {
      if (child.visible && (forceRender || child.dirty > 0 /* NONE */)) {
        ctx.save();
        child.render(__spreadProps$1(__spreadValues$1({}, renderCtx), { ctx, forceRender }));
        ctx.restore();
      }
    });
    this.markClean({ force: true });
    if (stats)
      stats.nodesRendered++;
  }
};
_RangeSelector$1.className = "Range";
_RangeSelector$1.defaults = {
  x: 0,
  y: 0,
  width: 200,
  height: 30,
  min: 0,
  max: 1
};
var RangeSelector$1 = _RangeSelector$1;

// packages/ag-charts-community/src/chart/navigator/navigator.ts
var Navigator$1 = class extends BaseModuleInstance$1 {
  constructor(ctx) {
    var _a;
    super();
    this.ctx = ctx;
    this.rs = new RangeSelector$1();
    // Wrappers to allow option application to the scene graph nodes.
    this.mask = new NavigatorMask$1(this.rs.mask);
    this.minHandle = new NavigatorHandle$1(this.rs.minHandle);
    this.maxHandle = new NavigatorHandle$1(this.rs.maxHandle);
    this.minHandleDragging = false;
    this.maxHandleDragging = false;
    this.panHandleOffset = NaN;
    this.enabled = false;
    this.margin = 10;
    this._visible = true;
    this.rs.onRangeChange = () => ctx.zoomManager.updateZoom("navigator", {
      x: { min: this.rs.min, max: this.rs.max },
      y: { min: 0, max: 1 }
    });
    (_a = ctx.scene.root) == null ? void 0 : _a.appendChild(this.rs);
    const interactionOpts = { bypassPause: ["animation"] };
    this.destroyFns.push(
      ctx.interactionManager.addListener("drag-start", (event) => this.onDragStart(event), interactionOpts),
      ctx.interactionManager.addListener("drag", (event) => this.onDrag(event), interactionOpts),
      ctx.interactionManager.addListener("hover", (event) => this.onDrag(event), interactionOpts),
      ctx.interactionManager.addListener("drag-end", () => this.onDragStop(), interactionOpts),
      ctx.layoutService.addListener("before-series", (event) => this.layout(event)),
      ctx.layoutService.addListener("layout-complete", (event) => this.layoutComplete(event)),
      () => {
        var _a2;
        return (_a2 = ctx.scene.root) == null ? void 0 : _a2.removeChild(this.rs);
      },
      () => this.ctx.zoomManager.updateZoom("navigator")
    );
    this.updateGroupVisibility();
  }
  set width(value) {
    this.rs.width = value;
  }
  get width() {
    return this.rs.width;
  }
  set height(value) {
    this.rs.height = value;
  }
  get height() {
    return this.rs.height;
  }
  set min(value) {
    this.rs.min = value;
  }
  get min() {
    return this.rs.min;
  }
  set max(value) {
    this.rs.max = value;
  }
  get max() {
    return this.rs.max;
  }
  set visible(value) {
    this._visible = value;
    this.updateGroupVisibility();
  }
  get visible() {
    return this._visible;
  }
  updateGroupVisibility() {
    const visible = this.enabled && this.visible;
    this.rs.visible = visible;
    if (visible) {
      this.ctx.zoomManager.updateZoom("navigator", {
        x: { min: this.rs.min, max: this.rs.max },
        y: { min: 0, max: 1 }
      });
    } else {
      this.ctx.zoomManager.updateZoom("navigator");
    }
  }
  layout({ shrinkRect }) {
    if (this.enabled) {
      const navigatorTotalHeight = this.rs.height + this.margin;
      shrinkRect.shrink(navigatorTotalHeight, "bottom");
      this.rs.y = shrinkRect.y + shrinkRect.height + this.margin;
    }
    return { shrinkRect };
  }
  layoutComplete({ series: { rect, visible } }) {
    if (this.enabled && visible) {
      this.rs.x = rect.x;
      this.rs.width = rect.width;
    }
    this.visible = visible;
  }
  onDragStart(offset4) {
    if (!this.enabled) {
      return;
    }
    const { offsetX, offsetY } = offset4;
    const { rs } = this;
    const { minHandle, maxHandle, x, width, min } = rs;
    const visibleRange = rs.computeVisibleRangeBBox();
    if (!(this.minHandleDragging || this.maxHandleDragging)) {
      if (minHandle.containsPoint(offsetX, offsetY)) {
        this.minHandleDragging = true;
      } else if (maxHandle.containsPoint(offsetX, offsetY)) {
        this.maxHandleDragging = true;
      } else if (visibleRange.containsPoint(offsetX, offsetY)) {
        this.panHandleOffset = (offsetX - x) / width - min;
      }
    }
  }
  onDrag(offset4) {
    if (!this.enabled) {
      return;
    }
    const { rs, panHandleOffset } = this;
    const { x, y, width, height, minHandle, maxHandle } = rs;
    const { offsetX, offsetY } = offset4;
    const minX = x + width * rs.min;
    const maxX = x + width * rs.max;
    const visibleRange = new BBox$1(minX, y, maxX - minX, height);
    const getRatio = () => Math.min(Math.max((offsetX - x) / width, 0), 1);
    if (minHandle.containsPoint(offsetX, offsetY) || maxHandle.containsPoint(offsetX, offsetY)) {
      this.ctx.cursorManager.updateCursor("navigator", "ew-resize");
    } else if (visibleRange.containsPoint(offsetX, offsetY)) {
      this.ctx.cursorManager.updateCursor("navigator", "grab");
    } else {
      this.ctx.cursorManager.updateCursor("navigator");
    }
    if (this.minHandleDragging) {
      rs.min = getRatio();
    } else if (this.maxHandleDragging) {
      rs.max = getRatio();
    } else if (!isNaN(panHandleOffset)) {
      const span = rs.max - rs.min;
      const min = Math.min(getRatio() - panHandleOffset, 1 - span);
      if (min <= rs.min) {
        rs.min = min;
        rs.max = rs.min + span;
      } else {
        rs.max = min + span;
        rs.min = rs.max - span;
      }
    }
  }
  onDragStop() {
    this.stopHandleDragging();
  }
  stopHandleDragging() {
    this.minHandleDragging = this.maxHandleDragging = false;
    this.panHandleOffset = NaN;
  }
};
__decorateClass$1([
  ActionOnSet$1({
    changeValue(newValue) {
      if (newValue) {
        this.min = 0;
        this.max = 1;
      }
      this.updateGroupVisibility();
    }
  }),
  Validate$1(BOOLEAN$1)
], Navigator$1.prototype, "enabled", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], Navigator$1.prototype, "margin", 2);

// packages/ag-charts-community/src/chart/navigator/navigatorModule.ts
var NavigatorModule = {
  type: "root",
  optionsKey: "navigator",
  packageType: "community",
  chartTypes: ["cartesian"],
  instanceConstructor: Navigator$1,
  themeTemplate: {
    navigator: {
      enabled: false,
      height: 30,
      mask: {
        fill: "#999999",
        stroke: "#999999",
        strokeWidth: 1,
        fillOpacity: 0.2
      },
      minHandle: {
        fill: "#f2f2f2",
        stroke: "#999999",
        strokeWidth: 1,
        width: 8,
        height: 16,
        gripLineGap: 2,
        gripLineLength: 8
      },
      maxHandle: {
        fill: "#f2f2f2",
        stroke: "#999999",
        strokeWidth: 1,
        width: 8,
        height: 16,
        gripLineGap: 2,
        gripLineLength: 8
      }
    }
  }
};

// packages/ag-charts-community/src/util/theme.ts
var singleSeriesPaletteFactory$1 = ({ takeColors }) => {
  const {
    fills: [fill],
    strokes: [stroke]
  } = takeColors(1);
  return { fill, stroke };
};
var markerPaletteFactory$1 = (params) => {
  const { fill, stroke } = singleSeriesPaletteFactory$1(params);
  return { marker: { fill, stroke } };
};

// packages/ag-charts-community/src/chart/themes/constants.ts
var FONT_SIZE$1 = {
  SMALL: 12,
  MEDIUM: 13,
  LARGE: 17
};
var FONT_WEIGHT2$1 = {
  NORMAL: "normal",
  BOLD: "bold",
  BOLDER: "bolder",
  LIGHTER: "lighter"
};
var BOTTOM$1 = "bottom";
var LEFT$1 = "left";
var TOP$1 = "top";
var CARTESIAN_AXIS_POSITIONS$1 = {
  BOTTOM: BOTTOM$1,
  LEFT: LEFT$1,
  TOP: TOP$1
};
var CATEGORY$1 = "category";
var NUMBER2$1 = "number";
var TIME$1 = "time";
var LOG$1 = "log";
var CARTESIAN_AXIS_TYPES$1 = {
  CATEGORY: CATEGORY$1,
  NUMBER: NUMBER2$1,
  TIME: TIME$1,
  LOG: LOG$1
};
var ANGLE_CATEGORY$1 = "angle-category";
var ANGLE_NUMBER$1 = "angle-number";
var RADIUS_CATEGORY$1 = "radius-category";
var RADIUS_NUMBER$1 = "radius-number";
var POLAR_AXIS_TYPES$1 = {
  ANGLE_CATEGORY: ANGLE_CATEGORY$1,
  ANGLE_NUMBER: ANGLE_NUMBER$1,
  RADIUS_CATEGORY: RADIUS_CATEGORY$1,
  RADIUS_NUMBER: RADIUS_NUMBER$1
};
var CIRCLE$1 = "circle";

// packages/ag-charts-community/src/chart/factory/chartTypes.ts
var TYPES$1 = {};
var DEFAULTS$1 = {};
var CHART_TYPES$1 = {
  has(seriesType) {
    return Object.hasOwn(TYPES$1, seriesType);
  },
  isCartesian(seriesType) {
    return TYPES$1[seriesType] === "cartesian";
  },
  isPolar(seriesType) {
    return TYPES$1[seriesType] === "polar";
  },
  isHierarchy(seriesType) {
    return TYPES$1[seriesType] === "hierarchy";
  },
  get seriesTypes() {
    return Object.keys(TYPES$1);
  },
  get cartesianTypes() {
    return this.seriesTypes.filter((t) => this.isCartesian(t));
  },
  get polarTypes() {
    return this.seriesTypes.filter((t) => this.isPolar(t));
  },
  get hierarchyTypes() {
    return this.seriesTypes.filter((t) => this.isHierarchy(t));
  }
};
function registerChartSeriesType(seriesType, chartType2) {
  TYPES$1[seriesType] = chartType2;
}
function registerChartDefaults(chartType2, defaults) {
  var _a;
  DEFAULTS$1[chartType2] = jsonMerge$1([(_a = DEFAULTS$1[chartType2]) != null ? _a : {}, defaults]);
}
function getChartDefaults$1(chartType2) {
  var _a;
  return (_a = DEFAULTS$1[chartType2]) != null ? _a : {};
}
function getChartType(seriesType) {
  var _a;
  return (_a = TYPES$1[seriesType]) != null ? _a : "unknown";
}

// packages/ag-charts-community/src/chart/mapping/types.ts
function optionsType(input) {
  var _a, _b, _c;
  return (_c = (_b = (_a = input.series) == null ? void 0 : _a[0]) == null ? void 0 : _b.type) != null ? _c : "line";
}
function isAgCartesianChartOptions(input) {
  const specifiedType = optionsType(input);
  if (specifiedType == null) {
    return true;
  }
  if (specifiedType === "cartesian") {
    Logger$1.warnOnce(`type '${specifiedType}' is deprecated, use a series type instead`);
    return true;
  }
  return CHART_TYPES$1.isCartesian(specifiedType) || isEnterpriseCartesian(specifiedType);
}
function isAgHierarchyChartOptions(input) {
  const specifiedType = optionsType(input);
  if (specifiedType == null) {
    return false;
  }
  if (specifiedType === "hierarchy") {
    Logger$1.warnOnce(`type '${specifiedType}' is deprecated, use a series type instead`);
    return true;
  }
  return CHART_TYPES$1.isHierarchy(specifiedType) || isEnterpriseHierarchy(specifiedType);
}
function isAgPolarChartOptions(input) {
  const specifiedType = optionsType(input);
  if (specifiedType == null) {
    return false;
  }
  if (specifiedType === "polar") {
    Logger$1.warnOnce(`type '${specifiedType}' is deprecated, use a series type instead`);
    return true;
  }
  return CHART_TYPES$1.isPolar(specifiedType) || isEnterprisePolar(specifiedType);
}
function isSeriesOptionType(input) {
  if (input == null) {
    return false;
  }
  return CHART_TYPES$1.has(input);
}
function isAxisOptionType(input) {
  if (input == null) {
    return false;
  }
  return AXIS_TYPES$1.has(input);
}

// packages/ag-charts-community/src/chart/mapping/defaults.ts
var DEFAULT_CARTESIAN_CHART_OVERRIDES = {
  axes: [
    {
      type: CARTESIAN_AXIS_TYPES$1.NUMBER,
      position: CARTESIAN_AXIS_POSITIONS$1.LEFT
    },
    {
      type: CARTESIAN_AXIS_TYPES$1.CATEGORY,
      position: CARTESIAN_AXIS_POSITIONS$1.BOTTOM
    }
  ]
};
function swapAxes(opts) {
  var _a;
  if (!isAgCartesianChartOptions(opts)) {
    return opts;
  }
  const [axis0, axis1] = (_a = opts.axes) != null ? _a : [];
  return __spreadProps$1(__spreadValues$1({}, opts), {
    axes: [
      __spreadProps$1(__spreadValues$1({}, axis0), { position: axis1.position }),
      __spreadProps$1(__spreadValues$1({}, axis1), { position: axis0.position })
    ]
  });
}
function resolveModuleConflicts(opts) {
  var _a, _b, _c, _d;
  const conflictOverrides = {};
  for (const [source, conflicts] of MODULE_CONFLICTS$1.entries()) {
    if (opts[source] == null || !conflicts.length) {
      continue;
    }
    (_a = conflictOverrides[source]) != null ? _a : conflictOverrides[source] = {};
    for (const conflict of conflicts) {
      if (((_b = opts[source]) == null ? void 0 : _b.enabled) && ((_c = opts[conflict]) == null ? void 0 : _c.enabled)) {
        Logger$1.warnOnce(
          `the [${source}] module can not be used at the same time as [${conflict}], it will be disabled.`
        );
        conflictOverrides[source].enabled = false;
      } else {
        conflictOverrides[source].enabled = (_d = opts[source]) == null ? void 0 : _d.enabled;
      }
    }
  }
  return conflictOverrides;
}

// packages/ag-charts-community/src/chart/themes/symbols.ts
var EXTENDS_CHART_DEFAULTS$1 = Symbol("extends-chart-defaults");
var EXTENDS_LEGEND_DEFAULTS$1 = Symbol("extends-legend-defaults");
var EXTENDS_LEGEND_ITEM_DEFAULTS$1 = Symbol("extends-legend-item-defaults");
var EXTENDS_LEGEND_ITEM_MARKER_DEFAULTS$1 = Symbol("extends-legend-item-marker-defaults");
var EXTENDS_AXES_DEFAULTS$1 = Symbol("extends-axes-defaults");
var EXTENDS_AXES_LABEL_DEFAULTS$1 = Symbol("extends-axes-label-defaults");
var EXTENDS_AXES_LINE_DEFAULTS$1 = Symbol("extends-axes-line-defaults");
var EXTENDS_AXES_TICK_DEFAULTS$1 = Symbol("extends-axes-tick-defaults");
var EXTENDS_AXES_GRID_LINE_DEFAULTS$1 = Symbol("extends-axes-grid-line-defaults");
var EXTENDS_SERIES_DEFAULTS$1 = Symbol("extends-series-defaults");
var EXTENDS_CARTESIAN_MARKER_DEFAULTS$1 = Symbol("extends-cartesian-marker-defaults");
var OVERRIDE_SERIES_LABEL_DEFAULTS$1 = Symbol("override-series-label-defaults");
var DEFAULT_FONT_FAMILY$1 = Symbol("default-font");
var DEFAULT_LABEL_COLOUR$1 = Symbol("default-label-colour");
var DEFAULT_INVERTED_LABEL_COLOUR$1 = Symbol("default-inverted-label-colour");
var DEFAULT_INSIDE_SERIES_LABEL_COLOUR$1 = Symbol("default-inside-series-label-colour");
var DEFAULT_MUTED_LABEL_COLOUR$1 = Symbol("default-muted-label-colour");
var DEFAULT_AXIS_GRID_COLOUR$1 = Symbol("default-axis-grid-colour");
var DEFAULT_AXIS_LINE_COLOUR$1 = Symbol("default-axis-line-colour");
var DEFAULT_CROSS_LINES_COLOUR$1 = Symbol("default-cross-lines-colour");
var DEFAULT_BACKGROUND_COLOUR$1 = Symbol("default-background-colour");
var DEFAULT_SHADOW_COLOUR$1 = Symbol("default-shadow-colour");
var DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS$1 = Symbol(
  "default-waterfall-series-positive-colors"
);
var DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS$1 = Symbol(
  "default-waterfall-series-negative-colors"
);
var DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS$1 = Symbol(
  "default-waterfall-series-total-colors"
);
var DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE$1 = Symbol(
  "default-waterfall-series-connector-line-stroke"
);
var DEFAULT_POLAR_SERIES_STROKE$1 = Symbol("default-polar-series-stroke");
var DEFAULT_DIVERGING_SERIES_COLOUR_RANGE$1 = Symbol(
  "default-diverging-series-colour-range"
);
var DEFAULT_HIERARCHY_FILLS$1 = Symbol("default-hierarchy-fills");
var DEFAULT_HIERARCHY_STROKES$1 = Symbol("default-hierarchy-strokes");

// packages/ag-charts-community/src/motion/pathMotion.ts
function pathMotion$1(groupId, subId, animationManager, paths, fns) {
  const { defaultDuration } = animationManager;
  const { addPhaseFn, updatePhaseFn, removePhaseFn } = fns;
  const animate = (phase, path, updateFn) => {
    animationManager.animate({
      id: `${groupId}_${subId}_${path.id}_${phase}`,
      groupId,
      from: 0,
      to: 1,
      ease: easeOut$1,
      onUpdate(ratio, preInit) {
        if (preInit && phase !== "removed")
          return;
        path.path.clear({ trackChanges: true });
        updateFn(ratio, path);
        path.checkPathDirty();
      },
      onStop() {
        if (phase !== "added")
          return;
        path.path.clear({ trackChanges: true });
        updateFn(1, path);
        path.checkPathDirty();
      },
      duration: FROM_TO_MIXINS$1[phase].animationDuration * defaultDuration,
      delay: FROM_TO_MIXINS$1[phase].animationDelay * defaultDuration
    });
  };
  for (const path of paths) {
    if (!animationManager.isSkipped()) {
      animate("removed", path, removePhaseFn);
      animate("updated", path, updatePhaseFn);
    }
    animate("added", path, addPhaseFn);
  }
}

// packages/ag-charts-community/src/chart/series/seriesLabelUtil.ts
function seriesLabelFadeInAnimation$1({ id }, subId, animationManager, labelSelections) {
  staticFromToMotion$1(id, subId, animationManager, labelSelections, { opacity: 0 }, { opacity: 1 }, LABEL_PHASE$1);
}
function seriesLabelFadeOutAnimation$1({ id }, subId, animationManager, labelSelections) {
  staticFromToMotion$1(id, subId, animationManager, labelSelections, { opacity: 1 }, { opacity: 0 }, LABEL_PHASE$1);
}
function resetLabelFn$1(_node) {
  return { opacity: 1 };
}

// packages/ag-charts-community/src/chart/series/seriesMarker.ts
var MARKER_SHAPE$1 = predicateWithMessage$1(
  (value) => isMarkerShape$1(value) || Object.getPrototypeOf(value) === Marker$1,
  `a marker shape keyword such as 'circle', 'diamond' or 'square' or an object extending the Marker class`
);
var SeriesMarker$1 = class extends BaseProperties$1 {
  constructor() {
    super(...arguments);
    this.enabled = true;
    this.shape = Circle$1;
    this.size = 6;
    this.fillOpacity = 1;
    this.strokeWidth = 1;
    this.strokeOpacity = 1;
  }
  getStyle() {
    const { size, fill, fillOpacity, stroke, strokeWidth, strokeOpacity } = this;
    return { size, fill, fillOpacity, stroke, strokeWidth, strokeOpacity };
  }
  getDiameter() {
    return this.size + this.strokeWidth;
  }
};
__decorateClass$1([
  Validate$1(BOOLEAN$1),
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], SeriesMarker$1.prototype, "enabled", 2);
__decorateClass$1([
  Validate$1(MARKER_SHAPE$1),
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], SeriesMarker$1.prototype, "shape", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1),
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], SeriesMarker$1.prototype, "size", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true }),
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], SeriesMarker$1.prototype, "fill", 2);
__decorateClass$1([
  Validate$1(RATIO$1),
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], SeriesMarker$1.prototype, "fillOpacity", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true }),
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], SeriesMarker$1.prototype, "stroke", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1),
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], SeriesMarker$1.prototype, "strokeWidth", 2);
__decorateClass$1([
  Validate$1(RATIO$1),
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], SeriesMarker$1.prototype, "strokeOpacity", 2);
__decorateClass$1([
  Validate$1(FUNCTION$1, { optional: true }),
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], SeriesMarker$1.prototype, "formatter", 2);

// packages/ag-charts-community/src/chart/series/seriesTooltip.ts
var SeriesTooltipInteraction$1 = class extends BaseProperties$1 {
  constructor() {
    super(...arguments);
    this.enabled = false;
  }
};
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], SeriesTooltipInteraction$1.prototype, "enabled", 2);
var SeriesTooltip$1 = class extends BaseProperties$1 {
  constructor() {
    super(...arguments);
    this.enabled = true;
    this.interaction = new SeriesTooltipInteraction$1();
    this.position = new TooltipPosition$1();
  }
  toTooltipHtml(defaults, params) {
    if (this.renderer) {
      return toTooltipHtml$2(this.renderer(params), defaults);
    }
    return toTooltipHtml$2(defaults);
  }
};
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], SeriesTooltip$1.prototype, "enabled", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1, { optional: true })
], SeriesTooltip$1.prototype, "showArrow", 2);
__decorateClass$1([
  Validate$1(FUNCTION$1, { optional: true })
], SeriesTooltip$1.prototype, "renderer", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], SeriesTooltip$1.prototype, "interaction", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], SeriesTooltip$1.prototype, "position", 2);

// packages/ag-charts-community/src/chart/series/cartesian/areaSeriesProperties.ts
var AreaSeriesProperties$1 = class extends CartesianSeriesProperties$1 {
  constructor() {
    super(...arguments);
    this.xName = void 0;
    this.fill = "#c16068";
    this.fillOpacity = 1;
    this.stroke = "#874349";
    this.strokeWidth = 2;
    this.strokeOpacity = 1;
    this.lineDash = [0];
    this.lineDashOffset = 0;
    this.shadow = new DropShadow$1();
    this.marker = new SeriesMarker$1();
    this.label = new Label$2();
    this.tooltip = new SeriesTooltip$1();
    this.connectMissingData = false;
  }
};
__decorateClass$1([
  Validate$1(STRING$1)
], AreaSeriesProperties$1.prototype, "xKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], AreaSeriesProperties$1.prototype, "xName", 2);
__decorateClass$1([
  Validate$1(STRING$1)
], AreaSeriesProperties$1.prototype, "yKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], AreaSeriesProperties$1.prototype, "yName", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], AreaSeriesProperties$1.prototype, "normalizedTo", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1)
], AreaSeriesProperties$1.prototype, "fill", 2);
__decorateClass$1([
  Validate$1(RATIO$1)
], AreaSeriesProperties$1.prototype, "fillOpacity", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1)
], AreaSeriesProperties$1.prototype, "stroke", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], AreaSeriesProperties$1.prototype, "strokeWidth", 2);
__decorateClass$1([
  Validate$1(RATIO$1)
], AreaSeriesProperties$1.prototype, "strokeOpacity", 2);
__decorateClass$1([
  Validate$1(LINE_DASH$1)
], AreaSeriesProperties$1.prototype, "lineDash", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], AreaSeriesProperties$1.prototype, "lineDashOffset", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], AreaSeriesProperties$1.prototype, "shadow", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], AreaSeriesProperties$1.prototype, "marker", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], AreaSeriesProperties$1.prototype, "label", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], AreaSeriesProperties$1.prototype, "tooltip", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], AreaSeriesProperties$1.prototype, "connectMissingData", 2);

// packages/ag-charts-community/src/chart/series/cartesian/markerUtil.ts
function markerFadeInAnimation$1({ id }, animationManager, markerSelections, status = "unknown") {
  const params = __spreadValues$1({}, FROM_TO_MIXINS$1[status]);
  staticFromToMotion$1(id, "markers", animationManager, markerSelections, { opacity: 0 }, { opacity: 1 }, params);
  markerSelections.forEach((s) => s.cleanup());
}
function markerScaleInAnimation$1({ id }, animationManager, markerSelections) {
  staticFromToMotion$1(
    id,
    "markers",
    animationManager,
    markerSelections,
    { scalingX: 0, scalingY: 0 },
    { scalingX: 1, scalingY: 1 }
  );
  markerSelections.forEach((s) => s.cleanup());
}
function markerSwipeScaleInAnimation$1({ id, nodeDataDependencies }, animationManager, markerSelections) {
  const seriesWidth = nodeDataDependencies.seriesRectWidth;
  const fromFn = (_, datum) => {
    var _a, _b;
    const x = (_b = (_a = datum.midPoint) == null ? void 0 : _a.x) != null ? _b : seriesWidth;
    const delay = clamp2$1(0, inverseEaseOut$1(x / seriesWidth), 1);
    const animationDuration = Debug$1.check("animationImmediateMarkerSwipeScaleIn") ? 0 : QUICK_TRANSITION$1;
    return { scalingX: 0, scalingY: 0, animationDelay: delay, animationDuration };
  };
  const toFn = () => {
    return { scalingX: 1, scalingY: 1 };
  };
  fromToMotion$1(id, "markers", animationManager, markerSelections, { fromFn, toFn });
}
function resetMarkerFn$1(_node) {
  return { opacity: 1, scalingX: 1, scalingY: 1 };
}
function resetMarkerPositionFn$1(_node, datum) {
  var _a, _b, _c, _d;
  return {
    translationX: (_b = (_a = datum.point) == null ? void 0 : _a.x) != null ? _b : NaN,
    translationY: (_d = (_c = datum.point) == null ? void 0 : _c.y) != null ? _d : NaN
  };
}
function prepareMarkerAnimation$1(pairMap, parentStatus) {
  const readFirstPair = (xValue, type) => {
    const val = pairMap[type][xValue];
    return Array.isArray(val) ? val[0] : val;
  };
  const markerStatus = (datum) => {
    const { xValue } = datum;
    if (pairMap.moved[xValue]) {
      return { point: readFirstPair(xValue, "moved"), status: "updated" };
    } else if (pairMap.removed[xValue]) {
      return { point: readFirstPair(xValue, "removed"), status: "removed" };
    } else if (pairMap.added[xValue]) {
      return { point: readFirstPair(xValue, "added"), status: "added" };
    }
    return { status: "unknown" };
  };
  const fromFn = (marker, datum) => {
    var _a, _b, _c, _d, _e, _f;
    const { status, point } = markerStatus(datum);
    if (status === "unknown")
      return { opacity: 0 };
    const defaults = __spreadValues$1({
      translationX: (_b = (_a = point == null ? void 0 : point.from) == null ? void 0 : _a.x) != null ? _b : marker.translationX,
      translationY: (_d = (_c = point == null ? void 0 : point.from) == null ? void 0 : _c.y) != null ? _d : marker.translationY,
      opacity: marker.opacity
    }, FROM_TO_MIXINS$1[status]);
    if (parentStatus === "added") {
      return __spreadValues$1(__spreadProps$1(__spreadValues$1({}, defaults), {
        opacity: 0,
        translationX: (_e = point == null ? void 0 : point.to) == null ? void 0 : _e.x,
        translationY: (_f = point == null ? void 0 : point.to) == null ? void 0 : _f.y
      }), FROM_TO_MIXINS$1["added"]);
    }
    if (status === "added") {
      defaults.opacity = 0;
    }
    return defaults;
  };
  const toFn = (_marker, datum) => {
    var _a, _b;
    const { status, point } = markerStatus(datum);
    if (status === "unknown")
      return { opacity: 0 };
    const defaults = __spreadValues$1({
      translationX: datum.point.x,
      translationY: datum.point.y,
      opacity: 1
    }, FROM_TO_MIXINS$1[status]);
    if (status === "removed" || parentStatus === "removed") {
      return __spreadValues$1(__spreadProps$1(__spreadValues$1({}, defaults), {
        translationX: (_a = point == null ? void 0 : point.to) == null ? void 0 : _a.x,
        translationY: (_b = point == null ? void 0 : point.to) == null ? void 0 : _b.y,
        opacity: 0
      }), FROM_TO_MIXINS$1["removed"]);
    }
    return defaults;
  };
  return { fromFn, toFn };
}

// packages/ag-charts-community/src/chart/series/cartesian/pathUtil.ts
function minMax$1(nodeData) {
  return nodeData.reduce(
    ({ min, max }, node) => {
      if (min == null || min.point.x > node.point.x) {
        min = node;
      }
      if (max == null || max.point.x < node.point.x) {
        max = node;
      }
      return { min, max };
    },
    {}
  );
}
function intersectionOnLine$1(a, b, targetX) {
  const m = (b.y - a.y) / (b.x - a.x);
  const y = (targetX - a.x) * m + a.y;
  return { x: targetX, y };
}
function backfillPathPoint$1(results, process, skip, processFn) {
  let prevMarkerIdx = -1, nextMarkerIdx = 0;
  const toProcess = [];
  while (nextMarkerIdx < results.length) {
    if (results[nextMarkerIdx].change === process) {
      toProcess.push(results[nextMarkerIdx]);
      nextMarkerIdx++;
      continue;
    }
    if (results[nextMarkerIdx].change === skip) {
      nextMarkerIdx++;
      continue;
    }
    if (toProcess.length > 0) {
      processFn(toProcess, prevMarkerIdx, nextMarkerIdx);
      toProcess.length = 0;
    }
    prevMarkerIdx = nextMarkerIdx;
    nextMarkerIdx++;
  }
  if (toProcess.length > 0) {
    processFn(toProcess, prevMarkerIdx, nextMarkerIdx);
  }
}
function backfillPathPointData$1(result, splitMode) {
  backfillPathPoint$1(result, "out", "in", (toProcess, sIdx, eIdx) => {
    var _a, _b;
    if (sIdx === -1 && result[eIdx]) {
      toProcess.forEach((d) => d.to = result[eIdx].from);
    } else if (eIdx === result.length && result[sIdx]) {
      toProcess.forEach((d) => d.to = result[sIdx].from);
    } else if (splitMode === "intersect" && ((_a = result[sIdx]) == null ? void 0 : _a.from) && ((_b = result[eIdx]) == null ? void 0 : _b.from)) {
      toProcess.forEach((d) => d.to = intersectionOnLine$1(result[sIdx].from, result[eIdx].from, d.from.x));
    } else {
      toProcess.forEach((d) => d.to = d.from);
    }
  });
  backfillPathPoint$1(result, "in", "out", (toProcess, sIdx, eIdx) => {
    var _a, _b;
    if (sIdx === -1 && result[eIdx]) {
      toProcess.forEach((d) => d.from = result[eIdx].to);
    } else if (eIdx === result.length && result[sIdx]) {
      toProcess.forEach((d) => d.from = result[sIdx].to);
    } else if (splitMode === "intersect" && ((_a = result[sIdx]) == null ? void 0 : _a.to) && ((_b = result[eIdx]) == null ? void 0 : _b.to)) {
      toProcess.forEach((d) => d.from = intersectionOnLine$1(result[sIdx].to, result[eIdx].to, d.to.x));
    } else {
      toProcess.forEach((d) => d.from = d.to);
    }
  });
}
function calculatePoint$1(from, to, ratio) {
  const x1 = isNaN(from.x) ? to.x : from.x;
  const y1 = isNaN(from.y) ? to.y : from.y;
  const xd = to.x - from.x;
  const yd = to.y - from.y;
  const xr = isNaN(xd) ? 0 : xd * ratio;
  const yr = isNaN(yd) ? 0 : yd * ratio;
  return {
    x: x1 + xr,
    y: y1 + yr
  };
}
function renderPartialPath$1(pairData, ratios, path) {
  const { path: linePath } = path;
  let previousTo;
  for (const data of pairData) {
    const ratio = ratios[data.change];
    if (ratio == null)
      continue;
    const { from, to } = data;
    if (from == null || to == null)
      continue;
    const { x, y } = calculatePoint$1(from, to, ratio);
    if (data.moveTo === false) {
      linePath.lineTo(x, y);
    } else if (data.moveTo === true || !previousTo) {
      linePath.moveTo(x, y);
    } else if (previousTo) {
      const moveToRatio = data.moveTo === "in" ? ratio : 1 - ratio;
      const { x: midPointX, y: midPointY } = calculatePoint$1(previousTo, { x, y }, moveToRatio);
      linePath.lineTo(midPointX, midPointY);
      linePath.moveTo(x, y);
    }
    previousTo = { x, y };
  }
}
function pathSwipeInAnimation$1({ id, visible }, animationManager, paths) {
  staticFromToMotion$1(
    id,
    "path_properties",
    animationManager,
    paths,
    { clipScalingX: 0 },
    { clipScalingX: 1 },
    {
      start: { clipMode: "normal", visible },
      finish: { clipMode: void 0, visible }
    }
  );
}
function pathFadeInAnimation$1({ id }, subId, animationManager, selection) {
  staticFromToMotion$1(id, subId, animationManager, selection, { opacity: 0 }, { opacity: 1 }, LABEL_PHASE$1);
}
function pathFadeOutAnimation$1({ id }, subId, animationManager, selection) {
  staticFromToMotion$1(id, subId, animationManager, selection, { opacity: 1 }, { opacity: 0 }, LABEL_PHASE$1);
}
function buildResetPathFn$1(opts) {
  return (_node) => {
    return { opacity: opts.getOpacity(), clipScalingX: 1, clipMode: void 0 };
  };
}
function updateClipPath$1({ nodeDataDependencies }, path) {
  var _a, _b;
  const { seriesRectHeight: height, seriesRectWidth: width } = nodeDataDependencies;
  if (path.clipPath == null) {
    path.clipPath = new Path2D$1();
    path.clipScalingX = 1;
    path.clipScalingY = 1;
  }
  (_a = path.clipPath) == null ? void 0 : _a.clear({ trackChanges: true });
  (_b = path.clipPath) == null ? void 0 : _b.rect(-25, -25, toReal$1(width) + 50, toReal$1(height) + 50);
}

// packages/ag-charts-community/src/chart/series/cartesian/scaling.ts
function isContinuousScaling$1(scaling) {
  return scaling.type === "continuous" || scaling.type === "log";
}
function isCategoryScaling$1(scaling) {
  return scaling.type === "category";
}
function areEqual$1(a, b) {
  return a.domain.length === b.domain.length && a.range.length === b.range.length && a.domain.every((val, index) => val === b.domain[index]) && a.range.every((val, index) => val === b.range[index]);
}
function areScalingEqual$1(a, b) {
  if (a === void 0 || b === void 0) {
    return a !== void 0 || b !== void 0;
  }
  if (isContinuousScaling$1(a) && isContinuousScaling$1(b)) {
    return a.type === b.type && areEqual$1(a, b);
  }
  if (isCategoryScaling$1(a) && isCategoryScaling$1(b)) {
    return areEqual$1(a, b);
  }
  return false;
}

// packages/ag-charts-community/src/chart/series/cartesian/lineUtil.ts
function scale$1(val, scaling) {
  if (!scaling)
    return NaN;
  if (val instanceof Date) {
    val = val.getTime();
  }
  if (scaling.type === "continuous" && typeof val === "number") {
    const domainRatio = (val - scaling.domain[0]) / (scaling.domain[1] - scaling.domain[0]);
    return domainRatio * (scaling.range[1] - scaling.range[0]) + scaling.range[0];
  }
  if (scaling.type === "log" && typeof val === "number") {
    return scaling.convert(val);
  }
  const matchingIndex = scaling.domain.findIndex((d) => d === val);
  if (matchingIndex >= 0) {
    return scaling.range[matchingIndex];
  }
  return NaN;
}
function scalesChanged$1(newData, oldData) {
  return !areScalingEqual$1(newData.scales.x, oldData.scales.x) || !areScalingEqual$1(newData.scales.y, oldData.scales.y);
}
function closeMatch$1(a, b) {
  const an = Number(a);
  const bn = Number(b);
  if (!isNaN(an) && !isNaN(bn)) {
    return Math.abs(bn - an) < 0.25;
  }
  return a === b;
}
function calculateMoveTo$1(from = false, to = false) {
  if (from === to) {
    return !!from;
  }
  return from ? "in" : "out";
}
function pairContinuousData$1(newData, oldData, opts = {}) {
  var _a, _b, _c, _d;
  const { backfillSplitMode = "intersect" } = opts;
  const toNewScale = (oldDatum) => {
    var _a2, _b2;
    return {
      x: scale$1((_a2 = oldDatum.xValue) != null ? _a2 : NaN, newData.scales.x),
      y: scale$1((_b2 = oldDatum.yValue) != null ? _b2 : NaN, newData.scales.y)
    };
  };
  const toOldScale = (newDatum) => {
    var _a2, _b2;
    return {
      x: scale$1((_a2 = newDatum.xValue) != null ? _a2 : NaN, oldData.scales.x),
      y: scale$1((_b2 = newDatum.yValue) != null ? _b2 : NaN, oldData.scales.y)
    };
  };
  const result = [];
  const resultMap = {
    added: {},
    moved: {},
    removed: {}
  };
  const pairUp = (from, to, xValue, change = "move") => {
    if (from && (isNaN(from.point.x) || isNaN(from.point.y))) {
      from = to;
    }
    const resultPoint = {
      from: from == null ? void 0 : from.point,
      to: to == null ? void 0 : to.point,
      moveTo: calculateMoveTo$1(from == null ? void 0 : from.point.moveTo, to == null ? void 0 : to.point.moveTo),
      change
    };
    if (change === "move") {
      resultMap.moved[xValue] = resultPoint;
      oldIdx++;
      newIdx++;
    } else if (change === "in") {
      resultMap.added[xValue] = resultPoint;
      newIdx++;
    } else if (change === "out") {
      resultMap.removed[xValue] = resultPoint;
      oldIdx++;
    }
    result.push(resultPoint);
  };
  const { min: minFromNode, max: maxFromNode } = minMax$1(oldData.nodeData);
  const { min: minToNode, max: maxToNode } = minMax$1(newData.nodeData);
  let oldIdx = 0;
  let newIdx = 0;
  while (oldIdx < oldData.nodeData.length || newIdx < newData.nodeData.length) {
    const from = oldData.nodeData[oldIdx];
    const to = newData.nodeData[newIdx];
    const fromShifted = from ? toNewScale(from) : void 0;
    const toUnshifted = to ? toOldScale(to) : void 0;
    const NA = void 0;
    if (fromShifted && closeMatch$1(fromShifted.x, to == null ? void 0 : to.point.x)) {
      pairUp(from, to, to.xValue, "move");
    } else if (fromShifted && fromShifted.x < ((_a = minToNode == null ? void 0 : minToNode.point.x) != null ? _a : -Infinity)) {
      pairUp(from, NA, from.xValue, "out");
    } else if (fromShifted && fromShifted.x > ((_b = maxToNode == null ? void 0 : maxToNode.point.x) != null ? _b : Infinity)) {
      pairUp(from, NA, from.xValue, "out");
    } else if (toUnshifted && toUnshifted.x < ((_c = minFromNode == null ? void 0 : minFromNode.point.x) != null ? _c : -Infinity)) {
      pairUp(NA, to, to.xValue, "in");
    } else if (toUnshifted && toUnshifted.x > ((_d = maxFromNode == null ? void 0 : maxFromNode.point.x) != null ? _d : Infinity)) {
      pairUp(NA, to, to.xValue, "in");
    } else if (fromShifted && fromShifted.x < (to == null ? void 0 : to.point.x)) {
      pairUp(from, NA, from.xValue, "out");
    } else if (toUnshifted && toUnshifted.x < (from == null ? void 0 : from.point.x)) {
      pairUp(NA, to, to.xValue, "in");
    } else if (from) {
      pairUp(from, NA, from.xValue, "out");
    } else if (to) {
      pairUp(NA, to, to.xValue, "in");
    } else {
      throw new Error("Unable to process points");
    }
  }
  backfillPathPointData$1(result, backfillSplitMode);
  return { result, resultMap };
}
function pairCategoryData$1(newData, oldData, diff2, opts = {}) {
  var _a, _b, _c;
  const { backfillSplitMode = "intersect", multiDatum = false } = opts;
  const result = [];
  const resultMapSingle = {
    added: {},
    moved: {},
    removed: {}
  };
  const resultMapMulti = {
    added: {},
    moved: {},
    removed: {}
  };
  let previousResultPoint = void 0;
  let previousXValue = void 0;
  const addToResultMap = (xValue, result2) => {
    var _a2, _b2;
    const type = result2.change === "move" ? "moved" : result2.change === "in" ? "added" : "removed";
    if (multiDatum) {
      (_b2 = (_a2 = resultMapMulti[type])[xValue]) != null ? _b2 : _a2[xValue] = [];
      resultMapMulti[type][xValue].push(result2);
    } else {
      resultMapSingle[type][xValue] = result2;
    }
    previousResultPoint = result2;
    previousXValue = xValue;
  };
  let oldIndex = 0;
  let newIndex = 0;
  let isXUnordered = false;
  while (oldIndex < oldData.nodeData.length || newIndex < newData.nodeData.length) {
    const before = oldData.nodeData[oldIndex];
    const after = newData.nodeData[newIndex];
    let resultPoint;
    if ((before == null ? void 0 : before.xValue) === (after == null ? void 0 : after.xValue)) {
      resultPoint = {
        change: "move",
        moveTo: calculateMoveTo$1((_a = before.point.moveTo) != null ? _a : false, after.point.moveTo),
        from: before.point,
        to: after.point
      };
      addToResultMap(before.xValue, resultPoint);
      oldIndex++;
      newIndex++;
    } else if (diff2 !== void 0 && diff2.removed.indexOf(before == null ? void 0 : before.xValue) >= 0) {
      resultPoint = {
        change: "out",
        moveTo: (_b = before.point.moveTo) != null ? _b : false,
        from: before.point
      };
      addToResultMap(before.xValue, resultPoint);
      oldIndex++;
    } else if (diff2 !== void 0 && diff2.added.indexOf(after == null ? void 0 : after.xValue) >= 0) {
      resultPoint = {
        change: "in",
        moveTo: (_c = after.point.moveTo) != null ? _c : false,
        to: after.point
      };
      addToResultMap(after.xValue, resultPoint);
      newIndex++;
    } else if (multiDatum && previousResultPoint && previousXValue === (before == null ? void 0 : before.xValue)) {
      resultPoint = __spreadValues$1({}, previousResultPoint);
      addToResultMap(before.xValue, resultPoint);
      oldIndex++;
    } else if (multiDatum && previousResultPoint && previousXValue === (after == null ? void 0 : after.xValue)) {
      resultPoint = __spreadValues$1({}, previousResultPoint);
      addToResultMap(after.xValue, resultPoint);
      newIndex++;
    } else {
      isXUnordered = true;
      break;
    }
    result.push(resultPoint);
  }
  let previousX = -Infinity;
  isXUnordered || (isXUnordered = result.some((pathPoint) => {
    const { change: marker, to: { x = -Infinity } = {} } = pathPoint;
    if (marker === "out")
      return;
    const result2 = x < previousX;
    previousX = x;
    return result2;
  }));
  if (isXUnordered) {
    return { result: void 0, resultMap: void 0 };
  }
  backfillPathPointData$1(result, backfillSplitMode);
  if (multiDatum) {
    return { result, resultMap: resultMapMulti };
  }
  return { result, resultMap: resultMapSingle };
}
function determinePathStatus$1(newData, oldData) {
  let status = "updated";
  const visible = (data) => {
    return data.visible;
  };
  if (!visible(oldData) && visible(newData)) {
    status = "added";
  } else if (visible(oldData) && !visible(newData)) {
    status = "removed";
  }
  return status;
}
function prepareLinePathPropertyAnimation$1(status, visibleToggleMode) {
  const phase = visibleToggleMode === "none" ? "updated" : status;
  const result = {
    fromFn: (_path) => {
      let mixin;
      if (status === "removed") {
        mixin = { finish: { visible: false } };
      } else if (status === "added") {
        mixin = { start: { visible: true } };
      } else {
        mixin = {};
      }
      return __spreadValues$1(__spreadValues$1({}, FROM_TO_MIXINS$1[phase]), mixin);
    },
    toFn: (_path) => {
      return __spreadValues$1({}, FROM_TO_MIXINS$1[phase]);
    }
  };
  if (visibleToggleMode === "fade") {
    return {
      fromFn: (path) => {
        const opacity = status === "added" ? 0 : path.opacity;
        return __spreadValues$1({ opacity }, result.fromFn(path));
      },
      toFn: (path) => {
        const opacity = status === "removed" ? 0 : 1;
        return __spreadValues$1({ opacity }, result.toFn(path));
      }
    };
  }
  return result;
}
function prepareLinePathAnimationFns$1(newData, oldData, pairData, visibleToggleMode, render) {
  const status = determinePathStatus$1(newData, oldData);
  const removePhaseFn = (ratio, path) => {
    render(pairData, { move: 0, out: ratio }, path);
  };
  const updatePhaseFn = (ratio, path) => {
    render(pairData, { move: ratio }, path);
  };
  const addPhaseFn = (ratio, path) => {
    render(pairData, { move: 1, in: ratio }, path);
  };
  const pathProperties = prepareLinePathPropertyAnimation$1(status, visibleToggleMode);
  return { status, path: { addPhaseFn, updatePhaseFn, removePhaseFn }, pathProperties };
}
function prepareLinePathAnimation$1(newData, oldData, diff2) {
  var _a, _b;
  const isCategoryBased = ((_a = newData.scales.x) == null ? void 0 : _a.type) === "category";
  const { result: pairData, resultMap: pairMap } = isCategoryBased ? pairCategoryData$1(newData, oldData, diff2) : pairContinuousData$1(newData, oldData);
  let status = "updated";
  if (oldData.visible && !newData.visible) {
    status = "removed";
  } else if (!oldData.visible && newData.visible) {
    status = "added";
  }
  if (pairData === void 0 || pairMap === void 0) {
    return;
  }
  const hasMotion = ((_b = diff2 == null ? void 0 : diff2.changed) != null ? _b : true) || scalesChanged$1(newData, oldData);
  const pathFns = prepareLinePathAnimationFns$1(newData, oldData, pairData, "fade", renderPartialPath$1);
  const marker = prepareMarkerAnimation$1(pairMap, status);
  return __spreadProps$1(__spreadValues$1({}, pathFns), { marker, hasMotion });
}

// packages/ag-charts-community/src/chart/series/cartesian/areaUtil.ts
var AreaSeriesTag$1 = /* @__PURE__ */ ((AreaSeriesTag2) => {
  AreaSeriesTag2[AreaSeriesTag2["Fill"] = 0] = "Fill";
  AreaSeriesTag2[AreaSeriesTag2["Stroke"] = 1] = "Stroke";
  AreaSeriesTag2[AreaSeriesTag2["Marker"] = 2] = "Marker";
  AreaSeriesTag2[AreaSeriesTag2["Label"] = 3] = "Label";
  return AreaSeriesTag2;
})(AreaSeriesTag$1 || {});
function splitFillPoints$1(context) {
  const { points } = context.fillData;
  return { top: points.slice(0, points.length / 2), bottom: points.slice(points.length / 2).reverse() };
}
function prepPoints$1(key, ctx, points) {
  return {
    scales: ctx.scales,
    nodeData: points[key],
    visible: ctx.visible
  };
}
function pairFillCategoryData$1(newData, oldData, diff2) {
  const oldPoints = splitFillPoints$1(oldData);
  const newPoints = splitFillPoints$1(newData);
  const pairOpts = { multiDatum: true };
  return {
    top: pairCategoryData$1(
      prepPoints$1("top", newData, newPoints),
      prepPoints$1("top", oldData, oldPoints),
      diff2,
      pairOpts
    ),
    bottom: pairCategoryData$1(
      prepPoints$1("bottom", newData, newPoints),
      prepPoints$1("bottom", oldData, oldPoints),
      diff2,
      pairOpts
    )
  };
}
function pairFillContinuousData$1(newData, oldData) {
  const oldPoints = splitFillPoints$1(oldData);
  const newPoints = splitFillPoints$1(newData);
  return {
    top: pairContinuousData$1(prepPoints$1("top", newData, newPoints), prepPoints$1("top", oldData, oldPoints)),
    bottom: pairContinuousData$1(prepPoints$1("bottom", newData, newPoints), prepPoints$1("bottom", oldData, oldPoints))
  };
}
function prepareAreaPathAnimation$1(newData, oldData, diff2) {
  var _a;
  const isCategoryBased = ((_a = newData.scales.x) == null ? void 0 : _a.type) === "category";
  let status = "updated";
  if (oldData.visible && !newData.visible) {
    status = "removed";
  } else if (!oldData.visible && newData.visible) {
    status = "added";
  }
  const prepareMarkerPairs = () => {
    if (isCategoryBased) {
      return pairCategoryData$1(newData, oldData, diff2, { backfillSplitMode: "static", multiDatum: true });
    }
    return pairContinuousData$1(newData, oldData, { backfillSplitMode: "static" });
  };
  const prepareFillPairs = () => {
    if (isCategoryBased) {
      return pairFillCategoryData$1(newData, oldData, diff2);
    }
    return pairFillContinuousData$1(newData, oldData);
  };
  const { resultMap: markerPairMap } = prepareMarkerPairs();
  const { top, bottom } = prepareFillPairs();
  if (markerPairMap === void 0 || top.result === void 0 || bottom.result === void 0) {
    return;
  }
  const pairData = [...top.result, ...bottom.result.reverse()];
  const fill = prepareLinePathAnimationFns$1(newData, oldData, pairData, "none", renderPartialPath$1);
  const marker = prepareMarkerAnimation$1(markerPairMap, status);
  return { fill, marker };
}

// packages/ag-charts-community/src/chart/series/cartesian/areaSeries.ts
var _AreaSeries$1 = class _AreaSeries extends CartesianSeries$1 {
  constructor(moduleCtx) {
    super({
      moduleCtx,
      pathsPerSeries: 2,
      pathsZIndexSubOrderOffset: [0, 1e3],
      hasMarkers: true,
      markerSelectionGarbageCollection: false,
      pickModes: [1 /* NEAREST_BY_MAIN_AXIS_FIRST */, 0 /* EXACT_SHAPE_MATCH */],
      animationResetFns: {
        path: buildResetPathFn$1({ getOpacity: () => this.getOpacity() }),
        label: resetLabelFn$1,
        marker: (node, datum) => __spreadValues$1(__spreadValues$1({}, resetMarkerFn$1()), resetMarkerPositionFn$1(node, datum))
      }
    });
    this.properties = new AreaSeriesProperties$1();
  }
  processData(dataController) {
    return __async$1(this, null, function* () {
      if (this.data == null || !this.properties.isValid()) {
        return;
      }
      const { data, visible, seriesGrouping: { groupIndex = this.id, stackCount = 1 } = {} } = this;
      const { xKey, yKey, connectMissingData, normalizedTo } = this.properties;
      const animationEnabled = !this.ctx.animationManager.isSkipped();
      const { isContinuousX, isContinuousY } = this.isContinuous();
      const ids = [
        `area-stack-${groupIndex}-yValues`,
        `area-stack-${groupIndex}-yValues-trailing`,
        `area-stack-${groupIndex}-yValues-prev`,
        `area-stack-${groupIndex}-yValues-trailing-prev`,
        `area-stack-${groupIndex}-yValues-marker`
      ];
      const extraProps = [];
      if (isDefined$1(normalizedTo)) {
        extraProps.push(normaliseGroupTo$1(this, [ids[0], ids[1], ids[4]], normalizedTo, "range"));
        extraProps.push(normaliseGroupTo$1(this, [ids[2], ids[3]], normalizedTo, "range"));
      }
      if (!isContinuousX && animationEnabled && this.processedData) {
        extraProps.push(diff$1(this.processedData));
      }
      if (animationEnabled) {
        extraProps.push(animationValidation$1(this));
      }
      const common = { invalidValue: null };
      if (connectMissingData && stackCount > 1) {
        common.invalidValue = 0;
      }
      if (!visible) {
        common.forceValue = 0;
      }
      yield this.requestDataModel(dataController, data, {
        props: [
          keyProperty$1(this, xKey, isContinuousX, { id: "xValue" }),
          valueProperty$1(this, yKey, isContinuousY, __spreadValues$1({ id: `yValueRaw` }, common)),
          ...groupAccumulativeValueProperty$1(this, yKey, isContinuousY, "window", "current", __spreadProps$1(__spreadValues$1({
            id: `yValueEnd`
          }, common), {
            groupId: ids[0]
          })),
          ...groupAccumulativeValueProperty$1(this, yKey, isContinuousY, "window-trailing", "current", __spreadProps$1(__spreadValues$1({
            id: `yValueStart`
          }, common), {
            groupId: ids[1]
          })),
          ...groupAccumulativeValueProperty$1(this, yKey, isContinuousY, "window", "last", __spreadProps$1(__spreadValues$1({
            id: `yValuePreviousEnd`
          }, common), {
            groupId: ids[2]
          })),
          ...groupAccumulativeValueProperty$1(this, yKey, isContinuousY, "window-trailing", "last", __spreadProps$1(__spreadValues$1({
            id: `yValuePreviousStart`
          }, common), {
            groupId: ids[3]
          })),
          ...groupAccumulativeValueProperty$1(this, yKey, isContinuousY, "normal", "current", __spreadProps$1(__spreadValues$1({
            id: `yValueCumulative`
          }, common), {
            groupId: ids[4]
          })),
          ...extraProps
        ],
        groupByKeys: true,
        groupByData: false
      });
      this.animationState.transition("updateData");
    });
  }
  getSeriesDomain(direction) {
    const { processedData, dataModel, axes } = this;
    if (!processedData || !dataModel || processedData.data.length === 0)
      return [];
    const xAxis = axes["x" /* X */];
    const yAxis = axes["y" /* Y */];
    const keyDef = dataModel.resolveProcessedDataDefById(this, `xValue`);
    const keys = dataModel.getDomain(this, `xValue`, "key", processedData);
    const yExtent = dataModel.getDomain(this, `yValueEnd`, "value", processedData);
    if (direction === "x" /* X */) {
      if ((keyDef == null ? void 0 : keyDef.def.type) === "key" && keyDef.def.valueType === "category") {
        return keys;
      }
      return fixNumericExtent$1(extent$5(keys), xAxis);
    } else if (yAxis instanceof LogAxis$1 || yAxis instanceof TimeAxis$1) {
      return fixNumericExtent$1(yExtent, yAxis);
    } else {
      const fixedYExtent = [yExtent[0] > 0 ? 0 : yExtent[0], yExtent[1] < 0 ? 0 : yExtent[1]];
      return fixNumericExtent$1(fixedYExtent, yAxis);
    }
  }
  createNodeData() {
    return __async$1(this, null, function* () {
      var _a;
      const { axes, data, processedData: { data: groupedData } = {}, dataModel } = this;
      const xAxis = axes["x" /* X */];
      const yAxis = axes["y" /* Y */];
      if (!xAxis || !yAxis || !data || !dataModel || !this.properties.isValid()) {
        return [];
      }
      const {
        yKey,
        xKey,
        marker,
        label,
        fill: seriesFill,
        stroke: seriesStroke,
        connectMissingData
      } = this.properties;
      const { scale: xScale } = xAxis;
      const { scale: yScale } = yAxis;
      const continuousY = ContinuousScale$1.is(yScale);
      const xOffset = ((_a = xScale.bandwidth) != null ? _a : 0) / 2;
      const defs = dataModel.resolveProcessedDataDefsByIds(this, [
        `yValueStart`,
        `yValueEnd`,
        `yValueRaw`,
        `yValuePreviousStart`,
        `yValuePreviousEnd`,
        `yValueCumulative`
      ]);
      const createMovePoint = (plainPoint) => {
        const _a2 = plainPoint, { point } = _a2, stroke = __objRest$1(_a2, ["point"]);
        return __spreadProps$1(__spreadValues$1({}, stroke), { point: __spreadProps$1(__spreadValues$1({}, point), { moveTo: true }) });
      };
      const createPathCoordinates = (xValue, lastYEnd, yEnd) => {
        const x = xScale.convert(xValue) + xOffset;
        const prevYCoordinate = yScale.convert(lastYEnd);
        const currYCoordinate = yScale.convert(yEnd);
        return [
          { point: { x, y: currYCoordinate }, yValue: yEnd, xValue },
          { point: { x, y: prevYCoordinate }, yValue: lastYEnd, xValue }
        ];
      };
      const createMarkerCoordinate = (xDatum, yEnd, rawYDatum) => {
        let currY;
        if (isDefined$1(this.properties.normalizedTo) ? continuousY && isContinuous$1(rawYDatum) : !isNaN(rawYDatum)) {
          currY = yEnd;
        }
        return {
          x: xScale.convert(xDatum) + xOffset,
          y: yScale.convert(currY),
          size: marker.size
        };
      };
      const itemId = yKey;
      const labelData = [];
      const markerData = [];
      const context = {
        itemId,
        fillData: { itemId, points: [] },
        strokeData: { itemId, points: [] },
        labelData,
        nodeData: markerData,
        scales: __superGet$1(_AreaSeries.prototype, this, "calculateScaling").call(this),
        visible: this.visible
      };
      const fillPoints = context.fillData.points;
      const fillPhantomPoints = [];
      const strokePoints = context.strokeData.points;
      let datumIdx = -1;
      let lastXDatum;
      let lastYDatum = -Infinity;
      groupedData == null ? void 0 : groupedData.forEach((datumGroup) => {
        const {
          keys,
          keys: [xDatum],
          datum: datumArray,
          values: valuesArray
        } = datumGroup;
        valuesArray.forEach((values, valueIdx) => {
          var _a2, _b, _c;
          datumIdx++;
          const seriesDatum = datumArray[valueIdx];
          const dataValues = dataModel.resolveProcessedDataDefsValues(defs, { keys, values });
          const { yValueRaw: yDatum, yValueCumulative } = dataValues;
          let { yValueStart, yValueEnd, yValuePreviousStart, yValuePreviousEnd } = dataValues;
          const validPoint = yDatum != null;
          const point = createMarkerCoordinate(xDatum, +yValueCumulative, yDatum);
          if (validPoint && marker) {
            markerData.push({
              index: datumIdx,
              series: this,
              itemId,
              datum: seriesDatum,
              midPoint: { x: point.x, y: point.y },
              cumulativeValue: yValueEnd,
              yValue: yDatum,
              xValue: xDatum,
              yKey,
              xKey,
              point,
              fill: (_a2 = marker.fill) != null ? _a2 : seriesFill,
              stroke: (_b = marker.stroke) != null ? _b : seriesStroke,
              strokeWidth: (_c = marker.strokeWidth) != null ? _c : this.getStrokeWidth(this.properties.strokeWidth)
            });
          }
          if (validPoint && label) {
            const labelText = this.getLabelText(
              label,
              {
                value: yDatum,
                datum: seriesDatum,
                xKey,
                yKey,
                xName: this.properties.xName,
                yName: this.properties.yName
              },
              (value) => isNumber2$1(value) ? value.toFixed(2) : String(value)
            );
            labelData.push({
              index: datumIdx,
              series: this,
              itemId: yKey,
              datum: seriesDatum,
              x: point.x,
              y: point.y,
              label: labelText ? {
                text: labelText,
                fontStyle: label.fontStyle,
                fontWeight: label.fontWeight,
                fontSize: label.fontSize,
                fontFamily: label.fontFamily,
                textAlign: "center",
                textBaseline: "bottom",
                fill: label.color
              } : void 0
            });
          }
          const xValid = lastXDatum != null && xDatum != null;
          const yValid = lastYDatum != null && validPoint;
          if (!yValid) {
            yValueStart = yValueStart != null ? yValueStart : 0;
            yValueEnd = yValueStart != null ? yValueStart : 0;
            yValuePreviousStart = yValuePreviousStart != null ? yValuePreviousStart : 0;
            yValuePreviousEnd = yValuePreviousStart != null ? yValuePreviousStart : 0;
          }
          const [prevTop, prevBottom] = createPathCoordinates(lastXDatum, yValuePreviousStart, yValuePreviousEnd);
          const [top, bottom] = createPathCoordinates(xDatum, yValueStart, yValueEnd);
          if (xValid && (!connectMissingData || yValid)) {
            fillPoints.push(prevTop);
            fillPhantomPoints.push(prevBottom);
            fillPoints.push(top);
            fillPhantomPoints.push(bottom);
          }
          if (yValid && datumIdx > 0) {
            strokePoints.push(createMovePoint(prevTop));
            strokePoints.push(top);
          }
          lastXDatum = xDatum;
          lastYDatum = yDatum;
        });
      });
      if (strokePoints.length > 0) {
        strokePoints[0] = createMovePoint(strokePoints[0]);
      }
      fillPhantomPoints.reverse();
      fillPoints.push(...fillPhantomPoints);
      return [context];
    });
  }
  isPathOrSelectionDirty() {
    return this.properties.marker.isDirty();
  }
  markerFactory() {
    const { shape } = this.properties.marker;
    const MarkerShape = getMarker$2(shape);
    return new MarkerShape();
  }
  updatePathNodes(opts) {
    return __async$1(this, null, function* () {
      const { opacity, visible, animationEnabled } = opts;
      const [fill, stroke] = opts.paths;
      const strokeWidth = this.getStrokeWidth(this.properties.strokeWidth);
      stroke.setProperties({
        tag: 1 /* Stroke */,
        fill: void 0,
        lineJoin: stroke.lineCap = "round",
        pointerEvents: 1 /* None */,
        stroke: this.properties.stroke,
        strokeWidth,
        strokeOpacity: this.properties.strokeOpacity,
        lineDash: this.properties.lineDash,
        lineDashOffset: this.properties.lineDashOffset,
        opacity,
        visible
      });
      fill.setProperties({
        tag: 0 /* Fill */,
        stroke: void 0,
        lineJoin: "round",
        pointerEvents: 1 /* None */,
        fill: this.properties.fill,
        fillOpacity: this.properties.fillOpacity,
        lineDash: this.properties.lineDash,
        lineDashOffset: this.properties.lineDashOffset,
        strokeOpacity: this.properties.strokeOpacity,
        fillShadow: this.properties.shadow,
        opacity,
        visible: visible || animationEnabled,
        strokeWidth
      });
      updateClipPath$1(this, stroke);
      updateClipPath$1(this, fill);
    });
  }
  updatePaths(opts) {
    return __async$1(this, null, function* () {
      this.updateAreaPaths([opts.paths], [opts.contextData]);
    });
  }
  updateAreaPaths(paths, contextData) {
    this.updateFillPath(paths, contextData);
    this.updateStrokePath(paths, contextData);
  }
  updateFillPath(paths, contextData) {
    contextData.forEach(({ fillData }, contextDataIndex) => {
      const [fill] = paths[contextDataIndex];
      const { path: fillPath } = fill;
      fillPath.clear({ trackChanges: true });
      for (const { point } of fillData.points) {
        if (point.moveTo) {
          fillPath.moveTo(point.x, point.y);
        } else {
          fillPath.lineTo(point.x, point.y);
        }
      }
      fillPath.closePath();
      fill.checkPathDirty();
    });
  }
  updateStrokePath(paths, contextData) {
    contextData.forEach(({ strokeData }, contextDataIndex) => {
      const [, stroke] = paths[contextDataIndex];
      const { path: strokePath } = stroke;
      strokePath.clear({ trackChanges: true });
      for (const { point } of strokeData.points) {
        if (point.moveTo) {
          strokePath.moveTo(point.x, point.y);
        } else {
          strokePath.lineTo(point.x, point.y);
        }
      }
      stroke.checkPathDirty();
    });
  }
  updateMarkerSelection(opts) {
    return __async$1(this, null, function* () {
      const { nodeData, markerSelection } = opts;
      if (this.properties.marker.isDirty()) {
        markerSelection.clear();
        markerSelection.cleanup();
      }
      return markerSelection.update(this.properties.marker.enabled ? nodeData : []);
    });
  }
  updateMarkerNodes(opts) {
    return __async$1(this, null, function* () {
      const { markerSelection, isHighlight: highlighted } = opts;
      const { xKey, yKey, marker, fill, stroke, strokeWidth, fillOpacity, strokeOpacity, highlightStyle } = this.properties;
      const baseStyle = mergeDefaults$1(highlighted && highlightStyle.item, marker.getStyle(), {
        fill,
        stroke,
        strokeWidth,
        fillOpacity,
        strokeOpacity
      });
      markerSelection.each((node, datum) => {
        this.updateMarkerStyle(node, marker, { datum, highlighted, xKey, yKey }, baseStyle);
      });
      if (!highlighted) {
        this.properties.marker.markClean();
      }
    });
  }
  updateLabelSelection(opts) {
    return __async$1(this, null, function* () {
      const { labelData, labelSelection } = opts;
      return labelSelection.update(labelData, (text) => {
        text.tag = 3 /* Label */;
      });
    });
  }
  updateLabelNodes(opts) {
    return __async$1(this, null, function* () {
      const { labelSelection } = opts;
      const { enabled: labelEnabled, fontStyle, fontWeight, fontSize, fontFamily, color } = this.properties.label;
      labelSelection.each((text, datum) => {
        const { x, y, label } = datum;
        if (label && labelEnabled && this.visible) {
          text.fontStyle = fontStyle;
          text.fontWeight = fontWeight;
          text.fontSize = fontSize;
          text.fontFamily = fontFamily;
          text.textAlign = label.textAlign;
          text.textBaseline = label.textBaseline;
          text.text = label.text;
          text.x = x;
          text.y = y - 10;
          text.fill = color;
          text.visible = true;
        } else {
          text.visible = false;
        }
      });
    });
  }
  getTooltipHtml(nodeDatum) {
    const { id: seriesId, axes, dataModel } = this;
    const { xKey, xName, yName, tooltip, marker } = this.properties;
    const { yKey, xValue, yValue, datum } = nodeDatum;
    const xAxis = axes["x" /* X */];
    const yAxis = axes["y" /* Y */];
    if (!this.properties.isValid() || !(xAxis && yAxis && isNumber2$1(yValue)) || !dataModel) {
      return "";
    }
    const xString = xAxis.formatDatum(xValue);
    const yString = yAxis.formatDatum(yValue);
    const title = sanitizeHtml$1(yName);
    const content = sanitizeHtml$1(xString + ": " + yString);
    const baseStyle = mergeDefaults$1({ fill: this.properties.fill }, marker.getStyle(), {
      stroke: this.properties.stroke,
      strokeWidth: this.properties.strokeWidth
    });
    const { fill: color } = this.getMarkerStyle(
      marker,
      { datum: nodeDatum, xKey, yKey, highlighted: false },
      baseStyle
    );
    return tooltip.toTooltipHtml(
      { title, content, backgroundColor: color },
      {
        datum,
        xKey,
        xName,
        yKey,
        yName,
        color,
        title,
        seriesId
      }
    );
  }
  getLegendData(legendType) {
    var _a, _b, _c, _d, _e, _f;
    if (!((_a = this.data) == null ? void 0 : _a.length) || !this.properties.isValid() || legendType !== "category") {
      return [];
    }
    const { yKey, yName, fill, stroke, fillOpacity, strokeOpacity, strokeWidth, lineDash, marker, visible } = this.properties;
    return [
      {
        legendType,
        id: this.id,
        itemId: yKey,
        seriesId: this.id,
        enabled: visible,
        label: {
          text: yName != null ? yName : yKey
        },
        marker: {
          shape: marker.shape,
          fill: (_b = marker.fill) != null ? _b : fill,
          stroke: (_c = marker.stroke) != null ? _c : stroke,
          fillOpacity: (_d = marker.fillOpacity) != null ? _d : fillOpacity,
          strokeOpacity: (_e = marker.strokeOpacity) != null ? _e : strokeOpacity,
          strokeWidth: (_f = marker.strokeWidth) != null ? _f : 0,
          enabled: marker.enabled || strokeWidth <= 0
        },
        line: {
          stroke,
          strokeOpacity,
          strokeWidth,
          lineDash
        }
      }
    ];
  }
  animateEmptyUpdateReady(animationData) {
    const { markerSelections, labelSelections, contextData, paths } = animationData;
    const { animationManager } = this.ctx;
    this.updateAreaPaths(paths, contextData);
    pathSwipeInAnimation$1(this, animationManager, paths.flat());
    resetMotion$1(markerSelections, resetMarkerPositionFn$1);
    markerSwipeScaleInAnimation$1(this, animationManager, markerSelections);
    seriesLabelFadeInAnimation$1(this, "labels", animationManager, labelSelections);
  }
  animateReadyResize(animationData) {
    const { contextData, paths } = animationData;
    this.updateAreaPaths(paths, contextData);
    super.animateReadyResize(animationData);
  }
  animateWaitingUpdateReady(animationData) {
    var _a, _b;
    const { animationManager } = this.ctx;
    const { markerSelections, labelSelections, contextData, paths, previousContextData } = animationData;
    super.resetAllAnimation(animationData);
    if (contextData.length === 0 || !previousContextData || previousContextData.length === 0) {
      animationManager.skipCurrentBatch();
      this.updateAreaPaths(paths, contextData);
      return;
    }
    const [[fill, stroke]] = paths;
    const [newData] = contextData;
    const [oldData] = previousContextData;
    const fns = prepareAreaPathAnimation$1(newData, oldData, (_b = (_a = this.processedData) == null ? void 0 : _a.reduced) == null ? void 0 : _b.diff);
    if (fns === void 0) {
      animationManager.skipCurrentBatch();
      this.updateAreaPaths(paths, contextData);
      return;
    }
    fromToMotion$1(this.id, "marker_update", animationManager, markerSelections, fns.marker);
    fromToMotion$1(this.id, "fill_path_properties", animationManager, [fill], fns.fill.pathProperties);
    pathMotion$1(this.id, "fill_path_update", animationManager, [fill], fns.fill.path);
    this.updateStrokePath(paths, contextData);
    pathFadeInAnimation$1(this, "stroke", animationManager, [stroke]);
    seriesLabelFadeInAnimation$1(this, "labels", animationManager, labelSelections);
  }
  isLabelEnabled() {
    return this.properties.label.enabled;
  }
  nodeFactory() {
    return new Group$1();
  }
};
_AreaSeries$1.className = "AreaSeries";
_AreaSeries$1.type = "area";
var AreaSeries = _AreaSeries$1;

// packages/ag-charts-community/src/chart/series/cartesian/areaSeriesModule.ts
var AreaSeriesModule = {
  type: "series",
  optionsKey: "series[]",
  packageType: "community",
  chartTypes: ["cartesian"],
  identifier: "area",
  instanceConstructor: AreaSeries,
  stackable: true,
  seriesDefaults: DEFAULT_CARTESIAN_CHART_OVERRIDES,
  themeTemplate: {
    __extends__: EXTENDS_SERIES_DEFAULTS$1,
    nodeClickRange: "nearest",
    tooltip: {
      position: {
        type: "node"
      }
    },
    fillOpacity: 0.8,
    strokeOpacity: 1,
    strokeWidth: 0,
    lineDash: [0],
    lineDashOffset: 0,
    shadow: {
      enabled: false,
      color: DEFAULT_SHADOW_COLOUR$1,
      xOffset: 3,
      yOffset: 3,
      blur: 5
    },
    marker: {
      __extends__: EXTENDS_CARTESIAN_MARKER_DEFAULTS$1,
      enabled: false,
      fillOpacity: 1,
      strokeOpacity: 1,
      strokeWidth: 0
    },
    label: {
      enabled: false,
      fontStyle: void 0,
      fontWeight: void 0,
      fontSize: 12,
      fontFamily: DEFAULT_FONT_FAMILY$1,
      color: DEFAULT_LABEL_COLOUR$1,
      formatter: void 0
    }
  },
  paletteFactory: (params) => {
    const { marker } = markerPaletteFactory$1(params);
    return {
      fill: marker.fill,
      stroke: marker.stroke,
      marker
    };
  }
};

// packages/ag-charts-community/src/chart/series/cartesian/abstractBarSeries.ts
var AbstractBarSeriesProperties$1 = class extends CartesianSeriesProperties$1 {
  constructor() {
    super(...arguments);
    this.direction = "vertical";
  }
};
__decorateClass$1([
  Validate$1(DIRECTION$1)
], AbstractBarSeriesProperties$1.prototype, "direction", 2);
var AbstractBarSeries$1 = class extends CartesianSeries$1 {
  getBandScalePadding() {
    return { inner: 0.2, outer: 0.1 };
  }
  shouldFlipXY() {
    return !this.isVertical();
  }
  isVertical() {
    return this.properties.direction === "vertical";
  }
  getBarDirection() {
    return this.shouldFlipXY() ? "x" /* X */ : "y" /* Y */;
  }
  getCategoryDirection() {
    return this.shouldFlipXY() ? "y" /* Y */ : "x" /* X */;
  }
  getValueAxis() {
    const direction = this.getBarDirection();
    return this.axes[direction];
  }
  getCategoryAxis() {
    const direction = this.getCategoryDirection();
    return this.axes[direction];
  }
};

// packages/ag-charts-community/src/chart/series/cartesian/barSeriesProperties.ts
var BarSeriesLabel$1 = class extends Label$2 {
  constructor() {
    super(...arguments);
    this.placement = "inside";
  }
};
__decorateClass$1([
  Validate$1(PLACEMENT$1)
], BarSeriesLabel$1.prototype, "placement", 2);
var BarSeriesProperties$1 = class extends AbstractBarSeriesProperties$1 {
  constructor() {
    super(...arguments);
    this.fill = "#c16068";
    this.fillOpacity = 1;
    this.stroke = "#874349";
    this.strokeWidth = 1;
    this.strokeOpacity = 1;
    this.lineDash = [0];
    this.lineDashOffset = 0;
    this.cornerRadius = 0;
    this.shadow = new DropShadow$1();
    this.label = new BarSeriesLabel$1();
    this.tooltip = new SeriesTooltip$1();
  }
};
__decorateClass$1([
  Validate$1(STRING$1)
], BarSeriesProperties$1.prototype, "xKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], BarSeriesProperties$1.prototype, "xName", 2);
__decorateClass$1([
  Validate$1(STRING$1)
], BarSeriesProperties$1.prototype, "yKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], BarSeriesProperties$1.prototype, "yName", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], BarSeriesProperties$1.prototype, "stackGroup", 2);
__decorateClass$1([
  Validate$1(NUMBER$1, { optional: true })
], BarSeriesProperties$1.prototype, "normalizedTo", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1)
], BarSeriesProperties$1.prototype, "fill", 2);
__decorateClass$1([
  Validate$1(RATIO$1)
], BarSeriesProperties$1.prototype, "fillOpacity", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1)
], BarSeriesProperties$1.prototype, "stroke", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], BarSeriesProperties$1.prototype, "strokeWidth", 2);
__decorateClass$1([
  Validate$1(RATIO$1)
], BarSeriesProperties$1.prototype, "strokeOpacity", 2);
__decorateClass$1([
  Validate$1(LINE_DASH$1)
], BarSeriesProperties$1.prototype, "lineDash", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], BarSeriesProperties$1.prototype, "lineDashOffset", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], BarSeriesProperties$1.prototype, "cornerRadius", 2);
__decorateClass$1([
  Validate$1(FUNCTION$1, { optional: true })
], BarSeriesProperties$1.prototype, "formatter", 2);
__decorateClass$1([
  Validate$1(OBJECT$1, { optional: true })
], BarSeriesProperties$1.prototype, "shadow", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], BarSeriesProperties$1.prototype, "label", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], BarSeriesProperties$1.prototype, "tooltip", 2);

// packages/ag-charts-community/src/chart/series/cartesian/barUtil.ts
function updateRect$1({ rect, config }) {
  const {
    crisp = true,
    fill,
    stroke,
    strokeWidth,
    fillOpacity,
    strokeOpacity,
    lineDash,
    lineDashOffset,
    fillShadow,
    cornerRadius = 0,
    topLeftCornerRadius,
    topRightCornerRadius,
    bottomRightCornerRadius,
    bottomLeftCornerRadius,
    cornerRadiusBbox,
    visible = true
  } = config;
  rect.crisp = crisp;
  rect.fill = fill;
  rect.stroke = stroke;
  rect.strokeWidth = strokeWidth;
  rect.fillOpacity = fillOpacity;
  rect.strokeOpacity = strokeOpacity;
  rect.lineDash = lineDash;
  rect.lineDashOffset = lineDashOffset;
  rect.fillShadow = fillShadow;
  rect.topLeftCornerRadius = topLeftCornerRadius ? cornerRadius : 0;
  rect.topRightCornerRadius = topRightCornerRadius ? cornerRadius : 0;
  rect.bottomRightCornerRadius = bottomRightCornerRadius ? cornerRadius : 0;
  rect.bottomLeftCornerRadius = bottomLeftCornerRadius ? cornerRadius : 0;
  rect.cornerRadiusBbox = cornerRadiusBbox;
  rect.visible = visible;
}
function getRectConfig$1(_a) {
  var _b = _a, {
    datum,
    isHighlighted,
    style,
    highlightStyle,
    formatter,
    seriesId,
    ctx: { callbackCache }
  } = _b, opts = __objRest$1(_b, [
    "datum",
    "isHighlighted",
    "style",
    "highlightStyle",
    "formatter",
    "seriesId",
    "ctx"
  ]);
  var _a2, _b2, _c, _d, _e, _f;
  const { fill, fillOpacity, stroke, strokeWidth } = mergeDefaults$1(isHighlighted && highlightStyle, style);
  const {
    strokeOpacity,
    fillShadow,
    lineDash,
    lineDashOffset,
    cornerRadius = 0,
    topLeftCornerRadius = true,
    topRightCornerRadius = true,
    bottomRightCornerRadius = true,
    bottomLeftCornerRadius = true,
    cornerRadiusBbox
  } = style;
  let format2;
  if (formatter) {
    format2 = callbackCache.call(formatter, __spreadValues$1({
      datum: datum.datum,
      xKey: datum.xKey,
      fill,
      stroke,
      strokeWidth,
      cornerRadius,
      highlighted: isHighlighted,
      seriesId
    }, opts));
  }
  return {
    fill: (_a2 = format2 == null ? void 0 : format2.fill) != null ? _a2 : fill,
    stroke: (_b2 = format2 == null ? void 0 : format2.stroke) != null ? _b2 : stroke,
    strokeWidth: (_c = format2 == null ? void 0 : format2.strokeWidth) != null ? _c : strokeWidth,
    fillOpacity: (_d = format2 == null ? void 0 : format2.fillOpacity) != null ? _d : fillOpacity,
    strokeOpacity: (_e = format2 == null ? void 0 : format2.strokeOpacity) != null ? _e : strokeOpacity,
    lineDash,
    lineDashOffset,
    fillShadow,
    // @ts-expect-error Remove once corner radius is officially added
    cornerRadius: (_f = format2 == null ? void 0 : format2.cornerRadius) != null ? _f : cornerRadius,
    topLeftCornerRadius,
    topRightCornerRadius,
    bottomRightCornerRadius,
    bottomLeftCornerRadius,
    cornerRadiusBbox
  };
}
function checkCrisp$1(visibleRange = []) {
  const [visibleMin, visibleMax] = visibleRange;
  const isZoomed = visibleMin !== 0 || visibleMax !== 1;
  return !isZoomed;
}
function collapsedStartingBarPosition$1(isVertical, axes) {
  const { startingX, startingY } = getStartingValues$1(isVertical, axes);
  const isDatumNegative = (datum) => {
    var _a;
    return isNegative$1((_a = datum["yValue"]) != null ? _a : 0);
  };
  const calculate = (datum, prevDatum) => {
    let x = isVertical ? datum.x : startingX;
    let y = isVertical ? startingY : datum.y;
    let width = isVertical ? datum.width : 0;
    let height = isVertical ? 0 : datum.height;
    if (prevDatum && (isNaN(x) || isNaN(y))) {
      ({ x, y } = prevDatum);
      width = isVertical ? prevDatum.width : 0;
      height = isVertical ? 0 : prevDatum.height;
      if (isVertical && !isDatumNegative(prevDatum)) {
        y += prevDatum.height;
      } else if (!isVertical && isDatumNegative(prevDatum)) {
        x += prevDatum.width;
      }
    }
    return { x, y, width, height };
  };
  return { isVertical, calculate };
}
function midpointStartingBarPosition$1(isVertical) {
  return {
    isVertical,
    calculate: (datum) => {
      return {
        x: isVertical ? datum.x : datum.x + datum.width / 2,
        y: isVertical ? datum.y + datum.height / 2 : datum.y,
        width: isVertical ? datum.width : 0,
        height: isVertical ? 0 : datum.height
      };
    }
  };
}
function prepareBarAnimationFunctions$1(initPos) {
  const isRemoved = (datum) => datum == null || isNaN(datum.x) || isNaN(datum.y);
  const fromFn = (rect, datum, status) => {
    if (status === "updated" && isRemoved(datum)) {
      status = "removed";
    } else if (status === "updated" && isRemoved(rect.previousDatum)) {
      status = "added";
    }
    let source = { x: rect.x, y: rect.y, width: rect.width, height: rect.height };
    if (status === "unknown" || status === "added") {
      source = initPos.calculate(datum, rect.previousDatum);
    }
    return __spreadValues$1(__spreadValues$1({}, source), FROM_TO_MIXINS$1[status]);
  };
  const toFn = (rect, datum, status) => {
    if (status === "removed" || isRemoved(datum)) {
      return initPos.calculate(datum, rect.previousDatum);
    }
    return { x: datum.x, y: datum.y, width: datum.width, height: datum.height };
  };
  return { toFn, fromFn };
}
function getStartingValues$1(isVertical, axes) {
  const axis = axes[isVertical ? "y" /* Y */ : "x" /* X */];
  let startingX = Infinity;
  let startingY = 0;
  if (!axis) {
    return { startingX, startingY };
  }
  if (isVertical) {
    startingY = axis.scale.convert(ContinuousScale$1.is(axis.scale) ? 0 : Math.max(...axis.range));
  } else {
    startingX = axis.scale.convert(ContinuousScale$1.is(axis.scale) ? 0 : Math.min(...axis.range));
  }
  return { startingX, startingY };
}
function resetBarSelectionsFn$1(_node, { x, y, width, height }) {
  return { x, y, width, height };
}

// packages/ag-charts-community/src/chart/series/cartesian/labelUtil.ts
function updateLabelNode$1(textNode, label, labelDatum) {
  if (label.enabled && labelDatum) {
    const { x, y, text, textAlign, textBaseline } = labelDatum;
    const { color: fill, fontStyle, fontWeight, fontSize, fontFamily } = label;
    textNode.setProperties({
      visible: true,
      x,
      y,
      text,
      fill,
      fontStyle,
      fontWeight,
      fontSize,
      fontFamily,
      textAlign,
      textBaseline
    });
  } else {
    textNode.visible = false;
  }
}
function adjustLabelPlacement$1({
  isPositive,
  isVertical,
  placement,
  padding = 0,
  rect
}) {
  let x = rect.x + rect.width / 2;
  let y = rect.y + rect.height / 2;
  let textAlign = "center";
  let textBaseline = "middle";
  switch (placement) {
    case "start": {
      if (isVertical) {
        y = isPositive ? rect.y + rect.height + padding : rect.y - padding;
        textBaseline = isPositive ? "top" : "bottom";
      } else {
        x = isPositive ? rect.x - padding : rect.x + rect.width + padding;
        textAlign = isPositive ? "start" : "end";
      }
      break;
    }
    case "outside":
    case "end": {
      if (isVertical) {
        y = isPositive ? rect.y - padding : rect.y + rect.height + padding;
        textBaseline = isPositive ? "bottom" : "top";
      } else {
        x = isPositive ? rect.x + rect.width + padding : rect.x - padding;
        textAlign = isPositive ? "start" : "end";
      }
      break;
    }
  }
  return { x, y, textAlign, textBaseline };
}

// packages/ag-charts-community/src/chart/series/cartesian/barSeries.ts
var _BarSeries$1 = class _BarSeries extends AbstractBarSeries$1 {
  constructor(moduleCtx) {
    super({
      moduleCtx,
      pickModes: [0 /* EXACT_SHAPE_MATCH */],
      pathsPerSeries: 0,
      hasHighlightedLabels: true,
      datumSelectionGarbageCollection: false,
      animationResetFns: {
        datum: resetBarSelectionsFn$1,
        label: resetLabelFn$1
      }
    });
    this.properties = new BarSeriesProperties$1();
    /**
     * Used to get the position of bars within each group.
     */
    this.groupScale = new BandScale$6();
    this.smallestDataInterval = void 0;
  }
  resolveKeyDirection(direction) {
    if (this.getBarDirection() === "x" /* X */) {
      if (direction === "x" /* X */) {
        return "y" /* Y */;
      }
      return "x" /* X */;
    }
    return direction;
  }
  processData(dataController) {
    return __async$1(this, null, function* () {
      var _a, _b, _c, _d;
      if (!this.properties.isValid() || !this.data) {
        return;
      }
      const { seriesGrouping: { groupIndex = this.id } = {}, data = [] } = this;
      const { xKey, yKey, normalizedTo } = this.properties;
      const animationEnabled = !this.ctx.animationManager.isSkipped();
      const normalizedToAbs = Math.abs(normalizedTo != null ? normalizedTo : NaN);
      const isContinuousX = ContinuousScale$1.is((_a = this.getCategoryAxis()) == null ? void 0 : _a.scale);
      const isContinuousY = ContinuousScale$1.is((_b = this.getValueAxis()) == null ? void 0 : _b.scale);
      const stackGroupName = `bar-stack-${groupIndex}-yValues`;
      const stackGroupTrailingName = `${stackGroupName}-trailing`;
      const normaliseTo = normalizedToAbs && isFinite(normalizedToAbs) ? normalizedToAbs : void 0;
      const extraProps = [];
      if (normaliseTo) {
        extraProps.push(normaliseGroupTo$1(this, [stackGroupName, stackGroupTrailingName], normaliseTo, "range"));
      }
      if (animationEnabled && this.processedData) {
        extraProps.push(diff$1(this.processedData));
      }
      if (animationEnabled) {
        extraProps.push(animationValidation$1(this));
      }
      const visibleProps = !this.visible ? { forceValue: 0 } : {};
      const { processedData } = yield this.requestDataModel(dataController, data, {
        props: [
          keyProperty$1(this, xKey, isContinuousX, { id: "xValue" }),
          valueProperty$1(this, yKey, isContinuousY, __spreadValues$1({ id: `yValue-raw`, invalidValue: null }, visibleProps)),
          ...groupAccumulativeValueProperty$1(this, yKey, isContinuousY, "normal", "current", __spreadValues$1({
            id: `yValue-end`,
            rangeId: `yValue-range`,
            invalidValue: null,
            missingValue: 0,
            groupId: stackGroupName,
            separateNegative: true
          }, visibleProps)),
          ...groupAccumulativeValueProperty$1(this, yKey, isContinuousY, "trailing", "current", __spreadValues$1({
            id: `yValue-start`,
            invalidValue: null,
            missingValue: 0,
            groupId: stackGroupTrailingName,
            separateNegative: true
          }, visibleProps)),
          ...isContinuousX ? [SMALLEST_KEY_INTERVAL$1] : [],
          ...extraProps
        ],
        groupByKeys: true,
        groupByData: false
      });
      this.smallestDataInterval = {
        x: (_d = (_c = processedData.reduced) == null ? void 0 : _c.smallestKeyInterval) != null ? _d : Infinity,
        y: Infinity
      };
      this.animationState.transition("updateData");
    });
  }
  getSeriesDomain(direction) {
    var _a;
    const { processedData, dataModel } = this;
    if (!processedData || !dataModel || processedData.data.length === 0)
      return [];
    const { reduced: { [SMALLEST_KEY_INTERVAL$1.property]: smallestX } = {} } = processedData;
    const categoryAxis = this.getCategoryAxis();
    const valueAxis = this.getValueAxis();
    const keyDef = dataModel.resolveProcessedDataDefById(this, `xValue`);
    const keys = dataModel.getDomain(this, `xValue`, "key", processedData);
    const yExtent = dataModel.getDomain(this, `yValue-end`, "value", processedData);
    if (direction === this.getCategoryDirection()) {
      if ((keyDef == null ? void 0 : keyDef.def.type) === "key" && (keyDef == null ? void 0 : keyDef.def.valueType) === "category") {
        return keys;
      }
      const scalePadding = smallestX != null && isFinite(smallestX) ? smallestX : 0;
      const keysExtent = (_a = extent$5(keys)) != null ? _a : [NaN, NaN];
      const isReversed = categoryAxis == null ? void 0 : categoryAxis.isReversed();
      if (direction === "y" /* Y */) {
        const d02 = keysExtent[0] + (isReversed ? 0 : -scalePadding);
        const d12 = keysExtent[1] + (isReversed ? scalePadding : 0);
        return fixNumericExtent$1([d02, d12], categoryAxis);
      }
      const d0 = keysExtent[0] + (isReversed ? -scalePadding : 0);
      const d1 = keysExtent[1] + (isReversed ? 0 : scalePadding);
      return fixNumericExtent$1([d0, d1], categoryAxis);
    } else if (this.getValueAxis() instanceof LogAxis$1) {
      return fixNumericExtent$1(yExtent, valueAxis);
    } else {
      const fixedYExtent = [yExtent[0] > 0 ? 0 : yExtent[0], yExtent[1] < 0 ? 0 : yExtent[1]];
      return fixNumericExtent$1(fixedYExtent, valueAxis);
    }
  }
  createNodeData() {
    return __async$1(this, null, function* () {
      const { dataModel } = this;
      const xAxis = this.getCategoryAxis();
      const yAxis = this.getValueAxis();
      if (!(dataModel && xAxis && yAxis && this.properties.isValid())) {
        return [];
      }
      const xScale = xAxis.scale;
      const yScale = yAxis.scale;
      const {
        groupScale,
        processedData,
        smallestDataInterval,
        ctx: { seriesStateManager }
      } = this;
      const { xKey, yKey, xName, yName, fill, stroke, strokeWidth, cornerRadius, legendItemName, label } = this.properties;
      const yReversed = yAxis.isReversed();
      const xBandWidth = ContinuousScale$1.is(xScale) ? xScale.calcBandwidth(smallestDataInterval == null ? void 0 : smallestDataInterval.x) : xScale.bandwidth;
      const domain = [];
      const { index: groupIndex, visibleGroupCount } = seriesStateManager.getVisiblePeerGroupIndex(this);
      for (let groupIdx = 0; groupIdx < visibleGroupCount; groupIdx++) {
        domain.push(String(groupIdx));
      }
      groupScale.domain = domain;
      groupScale.range = [0, xBandWidth != null ? xBandWidth : 0];
      if (xAxis instanceof CategoryAxis$1) {
        groupScale.paddingInner = xAxis.groupPaddingInner;
      } else if (xAxis instanceof GroupedCategoryAxis$1) {
        groupScale.padding = 0.1;
      } else {
        groupScale.padding = 0;
      }
      groupScale.round = groupScale.padding !== 0;
      const barWidth = groupScale.bandwidth >= 1 ? (
        // Pixel-rounded value for low-volume bar charts.
        groupScale.bandwidth
      ) : (
        // Handle high-volume bar charts gracefully.
        groupScale.rawBandwidth
      );
      const xIndex = dataModel.resolveProcessedDataIndexById(this, `xValue`).index;
      const yRawIndex = dataModel.resolveProcessedDataIndexById(this, `yValue-raw`).index;
      const yStartIndex = dataModel.resolveProcessedDataIndexById(this, `yValue-start`).index;
      const yEndIndex = dataModel.resolveProcessedDataIndexById(this, `yValue-end`).index;
      const yRangeIndex = dataModel.resolveProcessedDataDefById(this, `yValue-range`).index;
      const animationEnabled = !this.ctx.animationManager.isSkipped();
      const contexts = [];
      processedData == null ? void 0 : processedData.data.forEach(({ keys, datum: seriesDatum, values, aggValues }) => {
        values.forEach((value, contextIndex) => {
          var _a, _b;
          (_a = contexts[contextIndex]) != null ? _a : contexts[contextIndex] = {
            itemId: yKey,
            nodeData: [],
            labelData: [],
            scales: __superGet$1(_BarSeries.prototype, this, "calculateScaling").call(this),
            visible: this.visible || animationEnabled
          };
          const xValue = keys[xIndex];
          const x = xScale.convert(xValue);
          const currY = +value[yEndIndex];
          const prevY = +value[yStartIndex];
          const yRawValue = value[yRawIndex];
          const isPositive = yRawValue >= 0;
          const isUpward = isPositive !== yReversed;
          const yRange = (_b = aggValues == null ? void 0 : aggValues[yRangeIndex][isPositive ? 1 : 0]) != null ? _b : 0;
          const barX = x + groupScale.convert(String(groupIndex));
          if (isNaN(currY)) {
            return;
          }
          const y = yScale.convert(currY);
          const bottomY = yScale.convert(prevY);
          const barAlongX = this.getBarDirection() === "x" /* X */;
          const bboxHeight = yScale.convert(yRange);
          const bboxBottom = yScale.convert(0);
          const cornerRadiusBbox = new BBox$1(
            barAlongX ? Math.min(bboxBottom, bboxHeight) : barX,
            barAlongX ? barX : Math.min(bboxBottom, bboxHeight),
            barAlongX ? Math.abs(bboxBottom - bboxHeight) : barWidth,
            barAlongX ? barWidth : Math.abs(bboxBottom - bboxHeight)
          );
          const rect = {
            x: barAlongX ? Math.min(y, bottomY) : barX,
            y: barAlongX ? barX : Math.min(y, bottomY),
            width: barAlongX ? Math.abs(bottomY - y) : barWidth,
            height: barAlongX ? barWidth : Math.abs(bottomY - y),
            cornerRadiusBbox
          };
          const {
            fontStyle: labelFontStyle,
            fontWeight: labelFontWeight,
            fontSize: labelFontSize,
            fontFamily: labelFontFamily,
            color: labelColor,
            placement
          } = label;
          const labelText = this.getLabelText(
            this.properties.label,
            {
              datum: seriesDatum[contextIndex],
              value: yRawValue,
              xKey,
              yKey,
              xName,
              yName,
              legendItemName
            },
            (value2) => isNumber2$1(value2) ? value2.toFixed(2) : ""
          );
          const labelDatum = labelText ? __spreadValues$1({
            text: labelText,
            fill: labelColor,
            fontStyle: labelFontStyle,
            fontWeight: labelFontWeight,
            fontSize: labelFontSize,
            fontFamily: labelFontFamily
          }, adjustLabelPlacement$1({
            isPositive,
            isVertical: !barAlongX,
            placement,
            rect
          })) : void 0;
          const lengthRatioMultiplier = this.shouldFlipXY() ? rect.height : rect.width;
          const nodeData = {
            series: this,
            itemId: yKey,
            datum: seriesDatum[contextIndex],
            cumulativeValue: currY,
            xValue,
            yValue: yRawValue,
            yKey,
            xKey,
            capDefaults: {
              lengthRatioMultiplier,
              lengthMax: lengthRatioMultiplier
            },
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
            midPoint: { x: rect.x + rect.width / 2, y: rect.y + rect.height / 2 },
            fill,
            stroke,
            strokeWidth,
            cornerRadius,
            topLeftCornerRadius: !(barAlongX === isUpward),
            topRightCornerRadius: isUpward,
            bottomRightCornerRadius: barAlongX === isUpward,
            bottomLeftCornerRadius: !isUpward,
            cornerRadiusBbox,
            label: labelDatum
          };
          contexts[contextIndex].nodeData.push(nodeData);
          contexts[contextIndex].labelData.push(nodeData);
        });
      });
      return contexts;
    });
  }
  nodeFactory() {
    return new Rect$1();
  }
  updateDatumSelection(opts) {
    return __async$1(this, null, function* () {
      return opts.datumSelection.update(
        opts.nodeData,
        (rect) => {
          rect.tag = 0 /* Bar */;
        },
        (datum) => datum.xValue
      );
    });
  }
  updateDatumNodes(opts) {
    return __async$1(this, null, function* () {
      if (!this.properties.isValid()) {
        return;
      }
      const {
        yKey,
        stackGroup,
        fill,
        fillOpacity,
        stroke,
        strokeWidth,
        strokeOpacity,
        lineDash,
        lineDashOffset,
        formatter,
        shadow,
        highlightStyle: { item: itemHighlightStyle }
      } = this.properties;
      const xAxis = this.axes["x" /* X */];
      const crisp = checkCrisp$1(xAxis == null ? void 0 : xAxis.visibleRange);
      const categoryAlongX = this.getCategoryDirection() === "x" /* X */;
      opts.datumSelection.each((rect, datum) => {
        const style = {
          fill,
          stroke,
          fillOpacity,
          strokeOpacity,
          lineDash,
          lineDashOffset,
          fillShadow: shadow,
          strokeWidth: this.getStrokeWidth(strokeWidth),
          cornerRadius: datum.cornerRadius,
          topLeftCornerRadius: datum.topLeftCornerRadius,
          topRightCornerRadius: datum.topRightCornerRadius,
          bottomRightCornerRadius: datum.bottomRightCornerRadius,
          bottomLeftCornerRadius: datum.bottomLeftCornerRadius,
          cornerRadiusBbox: datum.cornerRadiusBbox
        };
        const visible = categoryAlongX ? datum.width > 0 : datum.height > 0;
        const config = getRectConfig$1({
          datum,
          ctx: this.ctx,
          seriesId: this.id,
          isHighlighted: opts.isHighlight,
          highlightStyle: itemHighlightStyle,
          yKey,
          style,
          formatter,
          stackGroup
        });
        config.crisp = crisp;
        config.visible = visible;
        updateRect$1({ rect, config });
      });
    });
  }
  updateLabelSelection(opts) {
    return __async$1(this, null, function* () {
      const data = this.isLabelEnabled() ? opts.labelData : [];
      return opts.labelSelection.update(data, (text) => {
        text.tag = 1 /* Label */;
        text.pointerEvents = 1 /* None */;
      });
    });
  }
  updateLabelNodes(opts) {
    return __async$1(this, null, function* () {
      opts.labelSelection.each((textNode, datum) => {
        updateLabelNode$1(textNode, this.properties.label, datum.label);
      });
    });
  }
  getTooltipHtml(nodeDatum) {
    var _a;
    const {
      id: seriesId,
      processedData,
      ctx: { callbackCache }
    } = this;
    const xAxis = this.getCategoryAxis();
    const yAxis = this.getValueAxis();
    if (!processedData || !this.properties.isValid() || !xAxis || !yAxis) {
      return "";
    }
    const { xKey, yKey, xName, yName, fill, stroke, strokeWidth, tooltip, formatter, stackGroup } = this.properties;
    const { xValue, yValue, datum } = nodeDatum;
    const xString = xAxis.formatDatum(xValue);
    const yString = yAxis.formatDatum(yValue);
    const title = sanitizeHtml$1(yName);
    const content = sanitizeHtml$1(xString + ": " + yString);
    let format2;
    if (formatter) {
      format2 = callbackCache.call(formatter, {
        seriesId,
        datum,
        xKey,
        yKey,
        stackGroup,
        fill,
        stroke,
        strokeWidth: this.getStrokeWidth(strokeWidth),
        highlighted: false
      });
    }
    const color = (_a = format2 == null ? void 0 : format2.fill) != null ? _a : fill;
    return tooltip.toTooltipHtml(
      { title, content, backgroundColor: color },
      __spreadValues$1({
        seriesId,
        datum,
        xKey,
        yKey,
        xName,
        yName,
        stackGroup,
        title,
        color
      }, this.getModuleTooltipParams())
    );
  }
  getLegendData(legendType) {
    var _a, _b;
    const { showInLegend } = this.properties;
    if (legendType !== "category" || !((_a = this.data) == null ? void 0 : _a.length) || !this.properties.isValid() || !showInLegend) {
      return [];
    }
    const { yKey, yName, fill, stroke, strokeWidth, fillOpacity, strokeOpacity, legendItemName, visible } = this.properties;
    return [
      {
        legendType: "category",
        id: this.id,
        itemId: yKey,
        seriesId: this.id,
        enabled: visible,
        label: { text: (_b = legendItemName != null ? legendItemName : yName) != null ? _b : yKey },
        marker: { fill, fillOpacity, stroke, strokeWidth, strokeOpacity },
        legendItemName
      }
    ];
  }
  animateEmptyUpdateReady({ datumSelections, labelSelections, annotationSelections }) {
    const fns = prepareBarAnimationFunctions$1(collapsedStartingBarPosition$1(this.isVertical(), this.axes));
    fromToMotion$1(this.id, "nodes", this.ctx.animationManager, datumSelections, fns);
    seriesLabelFadeInAnimation$1(this, "labels", this.ctx.animationManager, labelSelections);
    seriesLabelFadeInAnimation$1(this, "annotations", this.ctx.animationManager, annotationSelections);
  }
  animateWaitingUpdateReady(data) {
    var _a, _b;
    const { datumSelections, labelSelections, annotationSelections } = data;
    this.ctx.animationManager.stopByAnimationGroupId(this.id);
    const diff2 = (_b = (_a = this.processedData) == null ? void 0 : _a.reduced) == null ? void 0 : _b.diff;
    const fns = prepareBarAnimationFunctions$1(collapsedStartingBarPosition$1(this.isVertical(), this.axes));
    fromToMotion$1(
      this.id,
      "nodes",
      this.ctx.animationManager,
      datumSelections,
      fns,
      (_, datum) => String(datum.xValue),
      diff2
    );
    seriesLabelFadeInAnimation$1(this, "labels", this.ctx.animationManager, labelSelections);
    seriesLabelFadeInAnimation$1(this, "annotations", this.ctx.animationManager, annotationSelections);
  }
  isLabelEnabled() {
    return this.properties.label.enabled;
  }
};
_BarSeries$1.className = "BarSeries";
_BarSeries$1.type = "bar";
var BarSeries$1 = _BarSeries$1;

// packages/ag-charts-community/src/chart/series/cartesian/barSeriesModule.ts
var BarSeriesModule = {
  type: "series",
  optionsKey: "series[]",
  packageType: "community",
  chartTypes: ["cartesian"],
  identifier: "bar",
  instanceConstructor: BarSeries$1,
  stackable: true,
  groupable: true,
  seriesDefaults: {
    axes: [
      {
        type: CARTESIAN_AXIS_TYPES$1.NUMBER,
        position: CARTESIAN_AXIS_POSITIONS$1.LEFT
      },
      {
        type: CARTESIAN_AXIS_TYPES$1.CATEGORY,
        position: CARTESIAN_AXIS_POSITIONS$1.BOTTOM
      }
    ]
  },
  swapDefaultAxesCondition: (series) => (series == null ? void 0 : series.direction) === "horizontal",
  themeTemplate: {
    __extends__: EXTENDS_SERIES_DEFAULTS$1,
    fillOpacity: 1,
    strokeWidth: 0,
    lineDash: [0],
    lineDashOffset: 0,
    label: {
      enabled: false,
      fontStyle: void 0,
      fontWeight: FONT_WEIGHT2$1.NORMAL,
      fontSize: 12,
      fontFamily: DEFAULT_FONT_FAMILY$1,
      color: DEFAULT_INSIDE_SERIES_LABEL_COLOUR$1,
      formatter: void 0,
      placement: "inside"
    },
    shadow: {
      enabled: false,
      color: DEFAULT_SHADOW_COLOUR$1,
      xOffset: 3,
      yOffset: 3,
      blur: 5
    }
  },
  enterpriseThemeTemplate: {
    errorBar: {
      cap: {
        lengthRatio: 0.3
      }
    }
  },
  paletteFactory: singleSeriesPaletteFactory$1
};

// packages/ag-charts-community/src/chart/series/cartesian/bubbleSeriesProperties.ts
var BubbleSeriesMarker$1 = class extends SeriesMarker$1 {
  constructor() {
    super(...arguments);
    this.maxSize = 30;
  }
};
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1),
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], BubbleSeriesMarker$1.prototype, "maxSize", 2);
__decorateClass$1([
  Validate$1(NUMBER_ARRAY$1, { optional: true }),
  SceneChangeDetection$1({ redraw: 3 /* MAJOR */ })
], BubbleSeriesMarker$1.prototype, "domain", 2);
var BubbleSeriesProperties$1 = class extends CartesianSeriesProperties$1 {
  constructor() {
    super(...arguments);
    this.colorRange = ["#ffff00", "#00ff00", "#0000ff"];
    this.marker = new BubbleSeriesMarker$1();
    this.label = new Label$2();
    this.tooltip = new SeriesTooltip$1();
  }
};
__decorateClass$1([
  Validate$1(STRING$1)
], BubbleSeriesProperties$1.prototype, "xKey", 2);
__decorateClass$1([
  Validate$1(STRING$1)
], BubbleSeriesProperties$1.prototype, "yKey", 2);
__decorateClass$1([
  Validate$1(STRING$1)
], BubbleSeriesProperties$1.prototype, "sizeKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], BubbleSeriesProperties$1.prototype, "labelKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], BubbleSeriesProperties$1.prototype, "colorKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], BubbleSeriesProperties$1.prototype, "xName", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], BubbleSeriesProperties$1.prototype, "yName", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], BubbleSeriesProperties$1.prototype, "sizeName", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], BubbleSeriesProperties$1.prototype, "labelName", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], BubbleSeriesProperties$1.prototype, "colorName", 2);
__decorateClass$1([
  Validate$1(NUMBER_ARRAY$1, { optional: true })
], BubbleSeriesProperties$1.prototype, "colorDomain", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING_ARRAY$1)
], BubbleSeriesProperties$1.prototype, "colorRange", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], BubbleSeriesProperties$1.prototype, "title", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], BubbleSeriesProperties$1.prototype, "marker", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], BubbleSeriesProperties$1.prototype, "label", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], BubbleSeriesProperties$1.prototype, "tooltip", 2);

// packages/ag-charts-community/src/chart/series/cartesian/bubbleSeries.ts
var BubbleSeriesNodeClickEvent$1 = class extends CartesianSeriesNodeClickEvent$1 {
  constructor(type, nativeEvent, datum, series) {
    super(type, nativeEvent, datum, series);
    this.sizeKey = series.properties.sizeKey;
  }
};
var _BubbleSeries$1 = class _BubbleSeries extends CartesianSeries$1 {
  constructor(moduleCtx) {
    super({
      moduleCtx,
      pickModes: [
        2 /* NEAREST_BY_MAIN_CATEGORY_AXIS_FIRST */,
        3 /* NEAREST_NODE */,
        0 /* EXACT_SHAPE_MATCH */
      ],
      pathsPerSeries: 0,
      hasMarkers: true,
      markerSelectionGarbageCollection: false,
      animationResetFns: {
        label: resetLabelFn$1,
        marker: resetMarkerFn$1
      }
    });
    this.NodeClickEvent = BubbleSeriesNodeClickEvent$1;
    this.properties = new BubbleSeriesProperties$1();
    this.sizeScale = new LinearScale$2();
    this.colorScale = new ColorScale$1();
  }
  processData(dataController) {
    return __async$1(this, null, function* () {
      var _a, _b;
      if (!this.properties.isValid() || this.data == null) {
        return;
      }
      const { isContinuousX, isContinuousY } = this.isContinuous();
      const { xKey, yKey, sizeKey, labelKey, colorDomain, colorRange, colorKey, marker } = this.properties;
      const { dataModel, processedData } = yield this.requestDataModel(dataController, this.data, {
        props: [
          keyProperty$1(this, xKey, isContinuousX, { id: "xKey-raw" }),
          keyProperty$1(this, yKey, isContinuousY, { id: "yKey-raw" }),
          ...labelKey ? [keyProperty$1(this, labelKey, false, { id: `labelKey-raw` })] : [],
          valueProperty$1(this, xKey, isContinuousX, { id: `xValue` }),
          valueProperty$1(this, yKey, isContinuousY, { id: `yValue` }),
          valueProperty$1(this, sizeKey, true, { id: `sizeValue` }),
          ...colorKey ? [valueProperty$1(this, colorKey, true, { id: `colorValue` })] : [],
          ...labelKey ? [valueProperty$1(this, labelKey, false, { id: `labelValue` })] : []
        ],
        dataVisible: this.visible
      });
      const sizeKeyIdx = dataModel.resolveProcessedDataIndexById(this, `sizeValue`).index;
      const processedSize = (_a = processedData.domain.values[sizeKeyIdx]) != null ? _a : [];
      this.sizeScale.domain = marker.domain ? marker.domain : processedSize;
      if (colorKey) {
        const colorKeyIdx = dataModel.resolveProcessedDataIndexById(this, `colorValue`).index;
        this.colorScale.domain = (_b = colorDomain != null ? colorDomain : processedData.domain.values[colorKeyIdx]) != null ? _b : [];
        this.colorScale.range = colorRange;
        this.colorScale.update();
      }
      this.animationState.transition("updateData");
    });
  }
  getSeriesDomain(direction) {
    const { dataModel, processedData } = this;
    if (!processedData || !dataModel)
      return [];
    const id = direction === "x" /* X */ ? `xValue` : `yValue`;
    const dataDef = dataModel.resolveProcessedDataDefById(this, id);
    const domain = dataModel.getDomain(this, id, "value", processedData);
    if ((dataDef == null ? void 0 : dataDef.def.type) === "value" && (dataDef == null ? void 0 : dataDef.def.valueType) === "category") {
      return domain;
    }
    const axis = this.axes[direction];
    return fixNumericExtent$1(extent$5(domain), axis);
  }
  createNodeData() {
    return __async$1(this, null, function* () {
      var _a, _b, _c;
      const { axes, dataModel, processedData, colorScale, sizeScale } = this;
      const { xKey, yKey, sizeKey, labelKey, xName, yName, sizeName, labelName, label, colorKey, marker, visible } = this.properties;
      const xAxis = axes["x" /* X */];
      const yAxis = axes["y" /* Y */];
      if (!(dataModel && processedData && visible && xAxis && yAxis)) {
        return [];
      }
      const xDataIdx = dataModel.resolveProcessedDataIndexById(this, `xValue`).index;
      const yDataIdx = dataModel.resolveProcessedDataIndexById(this, `yValue`).index;
      const sizeDataIdx = sizeKey ? dataModel.resolveProcessedDataIndexById(this, `sizeValue`).index : -1;
      const colorDataIdx = colorKey ? dataModel.resolveProcessedDataIndexById(this, `colorValue`).index : -1;
      const labelDataIdx = labelKey ? dataModel.resolveProcessedDataIndexById(this, `labelValue`).index : -1;
      const xScale = xAxis.scale;
      const yScale = yAxis.scale;
      const xOffset = ((_a = xScale.bandwidth) != null ? _a : 0) / 2;
      const yOffset = ((_b = yScale.bandwidth) != null ? _b : 0) / 2;
      const nodeData = [];
      sizeScale.range = [marker.size, marker.maxSize];
      const font = label.getFont();
      for (const { values, datum } of (_c = processedData.data) != null ? _c : []) {
        const xDatum = values[xDataIdx];
        const yDatum = values[yDataIdx];
        const x = xScale.convert(xDatum) + xOffset;
        const y = yScale.convert(yDatum) + yOffset;
        const labelText = this.getLabelText(label, {
          value: labelKey ? values[labelDataIdx] : yDatum,
          datum,
          xKey,
          yKey,
          sizeKey,
          labelKey,
          xName,
          yName,
          sizeName,
          labelName
        });
        const size = HdpiCanvas$1.getTextSize(String(labelText), font);
        const markerSize = sizeKey ? sizeScale.convert(values[sizeDataIdx]) : marker.size;
        const fill = colorKey ? colorScale.convert(values[colorDataIdx]) : void 0;
        nodeData.push({
          series: this,
          itemId: yKey,
          yKey,
          xKey,
          datum,
          xValue: xDatum,
          yValue: yDatum,
          sizeValue: values[sizeDataIdx],
          point: { x, y, size: markerSize },
          midPoint: { x, y },
          fill,
          label: __spreadValues$1({ text: labelText }, size)
        });
      }
      return [
        {
          itemId: yKey,
          nodeData,
          labelData: nodeData,
          scales: __superGet$1(_BubbleSeries.prototype, this, "calculateScaling").call(this),
          visible: this.visible
        }
      ];
    });
  }
  isPathOrSelectionDirty() {
    return this.properties.marker.isDirty();
  }
  getLabelData() {
    var _a;
    return (_a = this.contextNodeData) == null ? void 0 : _a.reduce((r, n) => r.concat(n.labelData), []);
  }
  markerFactory() {
    const { shape } = this.properties.marker;
    const MarkerShape = getMarker$2(shape);
    return new MarkerShape();
  }
  updateMarkerSelection(opts) {
    return __async$1(this, null, function* () {
      const { nodeData, markerSelection } = opts;
      if (this.properties.marker.isDirty()) {
        markerSelection.clear();
        markerSelection.cleanup();
      }
      const data = this.properties.marker.enabled ? nodeData : [];
      return markerSelection.update(data, void 0, (datum) => this.getDatumId(datum));
    });
  }
  updateMarkerNodes(opts) {
    return __async$1(this, null, function* () {
      const { markerSelection, isHighlight: highlighted } = opts;
      const { xKey, yKey, sizeKey, labelKey, marker } = this.properties;
      const baseStyle = mergeDefaults$1(highlighted && this.properties.highlightStyle.item, marker.getStyle());
      this.sizeScale.range = [marker.size, marker.maxSize];
      markerSelection.each((node, datum) => {
        this.updateMarkerStyle(node, marker, { datum, highlighted, xKey, yKey, sizeKey, labelKey }, baseStyle);
      });
      if (!highlighted) {
        this.properties.marker.markClean();
      }
    });
  }
  updateLabelSelection(opts) {
    return __async$1(this, null, function* () {
      var _a, _b;
      const placedLabels = this.properties.label.enabled ? (_b = (_a = this.chart) == null ? void 0 : _a.placeLabels().get(this)) != null ? _b : [] : [];
      return opts.labelSelection.update(
        placedLabels.map((v) => __spreadProps$1(__spreadValues$1({}, v.datum), {
          point: {
            x: v.x,
            y: v.y,
            size: v.datum.point.size
          }
        }))
      );
    });
  }
  updateLabelNodes(opts) {
    return __async$1(this, null, function* () {
      const { label } = this.properties;
      opts.labelSelection.each((text, datum) => {
        var _a, _b, _c, _d;
        text.text = datum.label.text;
        text.fill = label.color;
        text.x = (_b = (_a = datum.point) == null ? void 0 : _a.x) != null ? _b : 0;
        text.y = (_d = (_c = datum.point) == null ? void 0 : _c.y) != null ? _d : 0;
        text.fontStyle = label.fontStyle;
        text.fontWeight = label.fontWeight;
        text.fontSize = label.fontSize;
        text.fontFamily = label.fontFamily;
        text.textAlign = "left";
        text.textBaseline = "top";
      });
    });
  }
  getTooltipHtml(nodeDatum) {
    var _a;
    const xAxis = this.axes["x" /* X */];
    const yAxis = this.axes["y" /* Y */];
    if (!this.properties.isValid() || !xAxis || !yAxis) {
      return "";
    }
    const { xKey, yKey, sizeKey, labelKey, xName, yName, sizeName, labelName, marker, tooltip } = this.properties;
    const title = (_a = this.properties.title) != null ? _a : yName;
    const baseStyle = mergeDefaults$1(
      { fill: nodeDatum.fill, strokeWidth: this.getStrokeWidth(marker.strokeWidth) },
      marker.getStyle()
    );
    const { fill: color = "gray" } = this.getMarkerStyle(
      marker,
      { datum: nodeDatum, highlighted: false, xKey, yKey, sizeKey, labelKey },
      baseStyle
    );
    const {
      datum,
      xValue,
      yValue,
      sizeValue,
      label: { text: labelText }
    } = nodeDatum;
    const xString = sanitizeHtml$1(xAxis.formatDatum(xValue));
    const yString = sanitizeHtml$1(yAxis.formatDatum(yValue));
    let content = `<b>${sanitizeHtml$1(xName != null ? xName : xKey)}</b>: ${xString}<br><b>${sanitizeHtml$1(yName != null ? yName : yKey)}</b>: ${yString}`;
    if (sizeKey) {
      content += `<br><b>${sanitizeHtml$1(sizeName != null ? sizeName : sizeKey)}</b>: ${sanitizeHtml$1(String(sizeValue))}`;
    }
    if (labelKey) {
      content = `<b>${sanitizeHtml$1(labelName != null ? labelName : labelKey)}</b>: ${sanitizeHtml$1(labelText)}<br>` + content;
    }
    return tooltip.toTooltipHtml(
      { title, content, backgroundColor: color },
      {
        datum,
        xKey,
        xName,
        yKey,
        yName,
        sizeKey,
        sizeName,
        labelKey,
        labelName,
        title,
        color,
        seriesId: this.id
      }
    );
  }
  getLegendData() {
    var _a, _b;
    if (!((_a = this.data) == null ? void 0 : _a.length) || !this.properties.isValid()) {
      return [];
    }
    const { yKey, yName, title, marker, visible } = this.properties;
    const { shape, fill, stroke, fillOpacity, strokeOpacity, strokeWidth } = marker;
    return [
      {
        legendType: "category",
        id: this.id,
        itemId: yKey,
        seriesId: this.id,
        enabled: visible,
        label: {
          text: (_b = title != null ? title : yName) != null ? _b : yKey
        },
        marker: {
          shape,
          fill: fill != null ? fill : "rgba(0, 0, 0, 0)",
          stroke: stroke != null ? stroke : "rgba(0, 0, 0, 0)",
          fillOpacity: fillOpacity != null ? fillOpacity : 1,
          strokeOpacity: strokeOpacity != null ? strokeOpacity : 1,
          strokeWidth: strokeWidth != null ? strokeWidth : 0
        }
      }
    ];
  }
  animateEmptyUpdateReady({ markerSelections, labelSelections }) {
    markerScaleInAnimation$1(this, this.ctx.animationManager, markerSelections);
    seriesLabelFadeInAnimation$1(this, "labels", this.ctx.animationManager, labelSelections);
  }
  getDatumId(datum) {
    return createDatumId$1([`${datum.xValue}`, `${datum.yValue}`, datum.label.text]);
  }
  isLabelEnabled() {
    return this.properties.label.enabled;
  }
  nodeFactory() {
    return new Group$1();
  }
};
_BubbleSeries$1.className = "BubbleSeries";
_BubbleSeries$1.type = "bubble";
var BubbleSeries$1 = _BubbleSeries$1;

// packages/ag-charts-community/src/chart/series/cartesian/bubbleSeriesModule.ts
var BubbleSeriesModule = {
  type: "series",
  optionsKey: "series[]",
  packageType: "community",
  chartTypes: ["cartesian"],
  identifier: "bubble",
  instanceConstructor: BubbleSeries$1,
  seriesDefaults: {
    axes: [
      { type: CARTESIAN_AXIS_TYPES$1.NUMBER, position: CARTESIAN_AXIS_POSITIONS$1.BOTTOM },
      { type: CARTESIAN_AXIS_TYPES$1.NUMBER, position: CARTESIAN_AXIS_POSITIONS$1.LEFT }
    ]
  },
  themeTemplate: {
    __extends__: EXTENDS_SERIES_DEFAULTS$1,
    tooltip: {
      position: {
        type: "node"
      }
    },
    marker: {
      __extends__: EXTENDS_CARTESIAN_MARKER_DEFAULTS$1,
      maxSize: 30,
      fillOpacity: 0.8
    },
    label: {
      enabled: false,
      fontStyle: void 0,
      fontWeight: void 0,
      fontSize: 12,
      fontFamily: DEFAULT_FONT_FAMILY$1,
      color: DEFAULT_LABEL_COLOUR$1
    }
  },
  paletteFactory: markerPaletteFactory$1
};

// packages/ag-charts-community/src/chart/series/cartesian/histogramSeriesProperties.ts
var HistogramSeriesProperties$1 = class extends CartesianSeriesProperties$1 {
  constructor() {
    super(...arguments);
    this.fillOpacity = 1;
    this.strokeWidth = 1;
    this.strokeOpacity = 1;
    this.lineDash = [0];
    this.lineDashOffset = 0;
    this.areaPlot = false;
    this.aggregation = "sum";
    this.shadow = new DropShadow$1();
    this.label = new Label$2();
    this.tooltip = new SeriesTooltip$1();
  }
};
__decorateClass$1([
  Validate$1(STRING$1)
], HistogramSeriesProperties$1.prototype, "xKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], HistogramSeriesProperties$1.prototype, "yKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], HistogramSeriesProperties$1.prototype, "xName", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], HistogramSeriesProperties$1.prototype, "yName", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true })
], HistogramSeriesProperties$1.prototype, "fill", 2);
__decorateClass$1([
  Validate$1(RATIO$1)
], HistogramSeriesProperties$1.prototype, "fillOpacity", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1, { optional: true })
], HistogramSeriesProperties$1.prototype, "stroke", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], HistogramSeriesProperties$1.prototype, "strokeWidth", 2);
__decorateClass$1([
  Validate$1(RATIO$1)
], HistogramSeriesProperties$1.prototype, "strokeOpacity", 2);
__decorateClass$1([
  Validate$1(LINE_DASH$1)
], HistogramSeriesProperties$1.prototype, "lineDash", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], HistogramSeriesProperties$1.prototype, "lineDashOffset", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], HistogramSeriesProperties$1.prototype, "areaPlot", 2);
__decorateClass$1([
  Validate$1(ARRAY$1, { optional: true })
], HistogramSeriesProperties$1.prototype, "bins", 2);
__decorateClass$1([
  Validate$1(UNION$1(["count", "sum", "mean"], "a histogram aggregation"))
], HistogramSeriesProperties$1.prototype, "aggregation", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], HistogramSeriesProperties$1.prototype, "binCount", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], HistogramSeriesProperties$1.prototype, "shadow", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], HistogramSeriesProperties$1.prototype, "label", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], HistogramSeriesProperties$1.prototype, "tooltip", 2);

// packages/ag-charts-community/src/chart/series/cartesian/histogramSeries.ts
var defaultBinCount$1 = 10;
var _HistogramSeries$1 = class _HistogramSeries extends CartesianSeries$1 {
  constructor(moduleCtx) {
    super({
      moduleCtx,
      pickModes: [0 /* EXACT_SHAPE_MATCH */],
      datumSelectionGarbageCollection: false,
      animationResetFns: {
        datum: resetBarSelectionsFn$1,
        label: resetLabelFn$1
      }
    });
    this.properties = new HistogramSeriesProperties$1();
    this.calculatedBins = [];
  }
  // During processData phase, used to unify different ways of the user specifying
  // the bins. Returns bins in format[[min1, max1], [min2, max2], ... ].
  deriveBins(xDomain) {
    if (this.properties.binCount) {
      return this.calculateNiceBins(xDomain, this.properties.binCount);
    }
    const binStarts = ticks_default$1(xDomain[0], xDomain[1], defaultBinCount$1);
    const binSize = tickStep$1(xDomain[0], xDomain[1], defaultBinCount$1);
    const [firstBinEnd] = binStarts;
    const expandStartToBin = (n) => [n, n + binSize];
    return [[firstBinEnd - binSize, firstBinEnd], ...binStarts.map(expandStartToBin)];
  }
  calculateNiceBins(domain, binCount) {
    const startGuess = Math.floor(domain[0]);
    const stop = domain[1];
    const segments = binCount || 1;
    const { start, binSize } = this.calculateNiceStart(startGuess, stop, segments);
    return this.getBins(start, stop, binSize, segments);
  }
  getBins(start, stop, step, count2) {
    const bins = [];
    const precision = this.calculatePrecision(step);
    for (let i = 0; i < count2; i++) {
      const a = Math.round((start + i * step) * precision) / precision;
      let b = Math.round((start + (i + 1) * step) * precision) / precision;
      if (i === count2 - 1) {
        b = Math.max(b, stop);
      }
      bins[i] = [a, b];
    }
    return bins;
  }
  calculatePrecision(step) {
    let precision = 10;
    if (isReal$1(step) && step > 0) {
      while (step < 1) {
        precision *= 10;
        step *= 10;
      }
    }
    return precision;
  }
  calculateNiceStart(a, b, segments) {
    const binSize = Math.abs(b - a) / segments;
    const order = Math.floor(Math.log10(binSize));
    const magnitude = Math.pow(10, order);
    const start = Math.floor(a / magnitude) * magnitude;
    return {
      start,
      binSize
    };
  }
  processData(dataController) {
    return __async$1(this, null, function* () {
      var _a;
      const { xKey, yKey, areaPlot, aggregation } = this.properties;
      const props = [keyProperty$1(this, xKey, true), SORT_DOMAIN_GROUPS$1];
      if (yKey) {
        let aggProp = groupCount$1(this, "groupCount");
        if (aggregation === "count") ; else if (aggregation === "sum") {
          aggProp = groupSum$1(this, "groupAgg");
        } else if (aggregation === "mean") {
          aggProp = groupAverage$1(this, "groupAgg");
        }
        if (areaPlot) {
          aggProp = area$1(this, "groupAgg", aggProp);
        }
        props.push(valueProperty$1(this, yKey, true, { invalidValue: void 0 }), aggProp);
      } else {
        let aggProp = groupCount$1(this, "groupAgg");
        if (areaPlot) {
          aggProp = area$1(this, "groupAgg", aggProp);
        }
        props.push(aggProp);
      }
      const groupByFn = (dataSet) => {
        var _a2;
        const xExtent = fixNumericExtent$1(dataSet.domain.keys[0]);
        if (xExtent.length === 0) {
          dataSet.domain.groups = [];
          return () => [];
        }
        const bins = (_a2 = this.properties.bins) != null ? _a2 : this.deriveBins(xExtent);
        const binCount = bins.length;
        this.calculatedBins = [...bins];
        return (item) => {
          const xValue = item.keys[0];
          for (let i = 0; i < binCount; i++) {
            const nextBin = bins[i];
            if (xValue >= nextBin[0] && xValue < nextBin[1]) {
              return nextBin;
            }
            if (i === binCount - 1 && xValue <= nextBin[1]) {
              return nextBin;
            }
          }
          return [];
        };
      };
      if (!this.ctx.animationManager.isSkipped() && this.processedData) {
        props.push(diff$1(this.processedData, false));
      }
      yield this.requestDataModel(dataController, (_a = this.data) != null ? _a : [], {
        props,
        dataVisible: this.visible,
        groupByFn
      });
      this.animationState.transition("updateData");
    });
  }
  getSeriesDomain(direction) {
    var _a, _b, _c, _d;
    const { processedData, dataModel } = this;
    if (!processedData || !dataModel || !this.calculatedBins.length)
      return [];
    const yDomain = dataModel.getDomain(this, `groupAgg`, "aggregate", processedData);
    const xDomainMin = (_a = this.calculatedBins) == null ? void 0 : _a[0][0];
    const xDomainMax = (_d = this.calculatedBins) == null ? void 0 : _d[((_c = (_b = this.calculatedBins) == null ? void 0 : _b.length) != null ? _c : 0) - 1][1];
    if (direction === "x" /* X */) {
      return fixNumericExtent$1([xDomainMin, xDomainMax]);
    }
    return fixNumericExtent$1(yDomain);
  }
  createNodeData() {
    return __async$1(this, null, function* () {
      var _a;
      const {
        id: seriesId,
        axes,
        processedData,
        ctx: { callbackCache }
      } = this;
      const xAxis = axes["x" /* X */];
      const yAxis = axes["y" /* Y */];
      if (!this.visible || !xAxis || !yAxis || !processedData || processedData.type !== "grouped") {
        return [];
      }
      const { scale: xScale } = xAxis;
      const { scale: yScale } = yAxis;
      const { xKey, yKey, xName, yName, fill, stroke, strokeWidth } = this.properties;
      const {
        formatter: labelFormatter = (params) => String(params.value),
        fontStyle: labelFontStyle,
        fontWeight: labelFontWeight,
        fontSize: labelFontSize,
        fontFamily: labelFontFamily,
        color: labelColor
      } = this.properties.label;
      const nodeData = [];
      processedData.data.forEach((group2) => {
        var _a2;
        const {
          aggValues: [[negativeAgg, positiveAgg]] = [[0, 0]],
          datum,
          datum: { length: frequency },
          keys: domain,
          keys: [xDomainMin, xDomainMax]
        } = group2;
        const xMinPx = xScale.convert(xDomainMin);
        const xMaxPx = xScale.convert(xDomainMax);
        const total = negativeAgg + positiveAgg;
        const yZeroPx = yScale.convert(0);
        const yMaxPx = yScale.convert(total);
        const w = Math.abs(xMaxPx - xMinPx);
        const h = Math.abs(yMaxPx - yZeroPx);
        const x = Math.min(xMinPx, xMaxPx);
        const y = Math.min(yZeroPx, yMaxPx);
        const selectionDatumLabel = total !== 0 ? {
          text: (_a2 = callbackCache.call(labelFormatter, {
            value: total,
            datum,
            seriesId,
            xKey,
            yKey,
            xName,
            yName
          })) != null ? _a2 : String(total),
          fontStyle: labelFontStyle,
          fontWeight: labelFontWeight,
          fontSize: labelFontSize,
          fontFamily: labelFontFamily,
          fill: labelColor,
          x: x + w / 2,
          y: y + h / 2
        } : void 0;
        const nodeMidPoint = {
          x: x + w / 2,
          y: y + h / 2
        };
        nodeData.push({
          series: this,
          datum,
          // required by SeriesNodeDatum, but might not make sense here
          // since each selection is an aggregation of multiple data.
          aggregatedValue: total,
          frequency,
          domain,
          yKey,
          xKey,
          x,
          y,
          xValue: xMinPx,
          yValue: yMaxPx,
          width: w,
          height: h,
          midPoint: nodeMidPoint,
          fill,
          stroke,
          strokeWidth,
          label: selectionDatumLabel
        });
      });
      return [
        {
          itemId: (_a = this.properties.yKey) != null ? _a : this.id,
          nodeData,
          labelData: nodeData,
          scales: __superGet$1(_HistogramSeries.prototype, this, "calculateScaling").call(this),
          animationValid: true,
          visible: this.visible
        }
      ];
    });
  }
  nodeFactory() {
    return new Rect$1();
  }
  updateDatumSelection(opts) {
    return __async$1(this, null, function* () {
      const { nodeData, datumSelection } = opts;
      return datumSelection.update(
        nodeData,
        (rect) => {
          rect.tag = 0 /* Bin */;
          rect.crisp = true;
        },
        (datum) => datum.domain.join("_")
      );
    });
  }
  updateDatumNodes(opts) {
    return __async$1(this, null, function* () {
      const { isHighlight: isDatumHighlighted } = opts;
      const {
        fillOpacity: seriesFillOpacity,
        strokeOpacity,
        lineDash,
        lineDashOffset,
        shadow,
        highlightStyle: {
          item: {
            fill: highlightedFill,
            fillOpacity: highlightFillOpacity = seriesFillOpacity,
            stroke: highlightedStroke,
            strokeWidth: highlightedDatumStrokeWidth
          }
        }
      } = this.properties;
      opts.datumSelection.each((rect, datum, index) => {
        var _a, _b;
        const strokeWidth = isDatumHighlighted && highlightedDatumStrokeWidth !== void 0 ? highlightedDatumStrokeWidth : datum.strokeWidth;
        const fillOpacity = isDatumHighlighted ? highlightFillOpacity : seriesFillOpacity;
        rect.fill = (_a = isDatumHighlighted ? highlightedFill : void 0) != null ? _a : datum.fill;
        rect.stroke = (_b = isDatumHighlighted ? highlightedStroke : void 0) != null ? _b : datum.stroke;
        rect.fillOpacity = fillOpacity;
        rect.strokeOpacity = strokeOpacity;
        rect.strokeWidth = strokeWidth;
        rect.lineDash = lineDash;
        rect.lineDashOffset = lineDashOffset;
        rect.fillShadow = shadow;
        rect.zIndex = isDatumHighlighted ? Series$1.highlightedZIndex : index;
        rect.visible = datum.height > 0;
      });
    });
  }
  updateLabelSelection(opts) {
    return __async$1(this, null, function* () {
      const { labelData, labelSelection } = opts;
      return labelSelection.update(labelData, (text) => {
        text.tag = 1 /* Label */;
        text.pointerEvents = 1 /* None */;
        text.textAlign = "center";
        text.textBaseline = "middle";
      });
    });
  }
  updateLabelNodes(opts) {
    return __async$1(this, null, function* () {
      const labelEnabled = this.isLabelEnabled();
      opts.labelSelection.each((text, datum) => {
        const label = datum.label;
        if (label && labelEnabled) {
          text.text = label.text;
          text.x = label.x;
          text.y = label.y;
          text.fontStyle = label.fontStyle;
          text.fontWeight = label.fontWeight;
          text.fontSize = label.fontSize;
          text.fontFamily = label.fontFamily;
          text.fill = label.fill;
          text.visible = true;
        } else {
          text.visible = false;
        }
      });
    });
  }
  getTooltipHtml(nodeDatum) {
    const xAxis = this.axes["x" /* X */];
    const yAxis = this.axes["y" /* Y */];
    if (!this.properties.isValid() || !xAxis || !yAxis) {
      return "";
    }
    const { xKey, yKey, xName, yName, fill: color, aggregation, tooltip } = this.properties;
    const {
      aggregatedValue,
      frequency,
      domain: [rangeMin, rangeMax]
    } = nodeDatum;
    const title = `${sanitizeHtml$1(xName != null ? xName : xKey)}: ${xAxis.formatDatum(rangeMin)} - ${xAxis.formatDatum(rangeMax)}`;
    let content = yKey ? `<b>${sanitizeHtml$1(yName != null ? yName : yKey)} (${aggregation})</b>: ${yAxis.formatDatum(aggregatedValue)}<br>` : "";
    content += `<b>Frequency</b>: ${frequency}`;
    const defaults = {
      title,
      backgroundColor: color,
      content
    };
    return tooltip.toTooltipHtml(defaults, {
      datum: {
        data: nodeDatum.datum,
        aggregatedValue: nodeDatum.aggregatedValue,
        domain: nodeDatum.domain,
        frequency: nodeDatum.frequency
      },
      xKey,
      xName,
      yKey,
      yName,
      color,
      title,
      seriesId: this.id
    });
  }
  getLegendData(legendType) {
    var _a, _b;
    if (!((_a = this.data) == null ? void 0 : _a.length) || legendType !== "category") {
      return [];
    }
    const { xKey, yName, fill, fillOpacity, stroke, strokeWidth, strokeOpacity, visible } = this.properties;
    return [
      {
        legendType: "category",
        id: this.id,
        itemId: xKey,
        seriesId: this.id,
        enabled: visible,
        label: {
          text: (_b = yName != null ? yName : xKey) != null ? _b : "Frequency"
        },
        marker: {
          fill: fill != null ? fill : "rgba(0, 0, 0, 0)",
          stroke: stroke != null ? stroke : "rgba(0, 0, 0, 0)",
          fillOpacity,
          strokeOpacity,
          strokeWidth
        }
      }
    ];
  }
  animateEmptyUpdateReady({ datumSelections, labelSelections }) {
    const fns = prepareBarAnimationFunctions$1(collapsedStartingBarPosition$1(true, this.axes));
    fromToMotion$1(this.id, "datums", this.ctx.animationManager, datumSelections, fns);
    seriesLabelFadeInAnimation$1(this, "labels", this.ctx.animationManager, labelSelections);
  }
  animateWaitingUpdateReady(data) {
    var _a, _b;
    const diff2 = (_b = (_a = this.processedData) == null ? void 0 : _a.reduced) == null ? void 0 : _b.diff;
    const fns = prepareBarAnimationFunctions$1(collapsedStartingBarPosition$1(true, this.axes));
    fromToMotion$1(
      this.id,
      "datums",
      this.ctx.animationManager,
      data.datumSelections,
      fns,
      (_, datum) => this.getDatumId(datum),
      diff2
    );
    seriesLabelFadeInAnimation$1(this, "labels", this.ctx.animationManager, data.labelSelections);
  }
  getDatumId(datum) {
    return createDatumId$1(datum.domain.map((d) => `${d}`));
  }
  isLabelEnabled() {
    return this.properties.label.enabled;
  }
};
_HistogramSeries$1.className = "HistogramSeries";
_HistogramSeries$1.type = "histogram";
var HistogramSeries$1 = _HistogramSeries$1;

// packages/ag-charts-community/src/chart/series/cartesian/histogramSeriesModule.ts
var HistogramSeriesModule = {
  type: "series",
  optionsKey: "series[]",
  packageType: "community",
  chartTypes: ["cartesian"],
  identifier: "histogram",
  instanceConstructor: HistogramSeries$1,
  seriesDefaults: {
    axes: [
      { type: CARTESIAN_AXIS_TYPES$1.NUMBER, position: CARTESIAN_AXIS_POSITIONS$1.BOTTOM },
      { type: CARTESIAN_AXIS_TYPES$1.NUMBER, position: CARTESIAN_AXIS_POSITIONS$1.LEFT }
    ]
  },
  themeTemplate: {
    __extends__: EXTENDS_SERIES_DEFAULTS$1,
    strokeWidth: 1,
    fillOpacity: 1,
    strokeOpacity: 1,
    lineDash: [0],
    lineDashOffset: 0,
    label: {
      enabled: false,
      fontStyle: void 0,
      fontWeight: void 0,
      fontSize: 12,
      fontFamily: DEFAULT_FONT_FAMILY$1,
      color: DEFAULT_INSIDE_SERIES_LABEL_COLOUR$1,
      formatter: void 0
    },
    shadow: {
      enabled: false,
      color: DEFAULT_SHADOW_COLOUR$1,
      xOffset: 3,
      yOffset: 3,
      blur: 5
    }
  },
  paletteFactory: ({ takeColors }) => {
    const {
      fills: [fill],
      strokes: [stroke]
    } = takeColors(1);
    return { fill, stroke };
  }
};

// packages/ag-charts-community/src/chart/series/cartesian/lineSeriesProperties.ts
var LineSeriesProperties$1 = class extends CartesianSeriesProperties$1 {
  constructor() {
    super(...arguments);
    this.stroke = "#874349";
    this.strokeWidth = 2;
    this.strokeOpacity = 1;
    this.lineDash = [0];
    this.lineDashOffset = 0;
    this.marker = new SeriesMarker$1();
    this.label = new Label$2();
    this.tooltip = new SeriesTooltip$1();
    this.connectMissingData = false;
  }
};
__decorateClass$1([
  Validate$1(STRING$1)
], LineSeriesProperties$1.prototype, "xKey", 2);
__decorateClass$1([
  Validate$1(STRING$1)
], LineSeriesProperties$1.prototype, "yKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], LineSeriesProperties$1.prototype, "xName", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], LineSeriesProperties$1.prototype, "yName", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], LineSeriesProperties$1.prototype, "title", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING$1)
], LineSeriesProperties$1.prototype, "stroke", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], LineSeriesProperties$1.prototype, "strokeWidth", 2);
__decorateClass$1([
  Validate$1(RATIO$1)
], LineSeriesProperties$1.prototype, "strokeOpacity", 2);
__decorateClass$1([
  Validate$1(LINE_DASH$1)
], LineSeriesProperties$1.prototype, "lineDash", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], LineSeriesProperties$1.prototype, "lineDashOffset", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], LineSeriesProperties$1.prototype, "marker", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], LineSeriesProperties$1.prototype, "label", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], LineSeriesProperties$1.prototype, "tooltip", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], LineSeriesProperties$1.prototype, "connectMissingData", 2);

// packages/ag-charts-community/src/chart/series/cartesian/lineSeries.ts
var _LineSeries$1 = class _LineSeries extends CartesianSeries$1 {
  constructor(moduleCtx) {
    super({
      moduleCtx,
      hasMarkers: true,
      pickModes: [
        2 /* NEAREST_BY_MAIN_CATEGORY_AXIS_FIRST */,
        3 /* NEAREST_NODE */,
        0 /* EXACT_SHAPE_MATCH */
      ],
      markerSelectionGarbageCollection: false,
      animationResetFns: {
        path: buildResetPathFn$1({ getOpacity: () => this.getOpacity() }),
        label: resetLabelFn$1,
        marker: (node, datum) => __spreadValues$1(__spreadValues$1({}, resetMarkerFn$1()), resetMarkerPositionFn$1(node, datum))
      }
    });
    this.properties = new LineSeriesProperties$1();
  }
  processData(dataController) {
    return __async$1(this, null, function* () {
      if (!this.properties.isValid() || this.data == null) {
        return;
      }
      const { xKey, yKey } = this.properties;
      const animationEnabled = !this.ctx.animationManager.isSkipped();
      const { isContinuousX, isContinuousY } = this.isContinuous();
      const props = [];
      if (!isContinuousX) {
        props.push(keyProperty$1(this, xKey, isContinuousX, { id: "xKey" }));
        if (animationEnabled && this.processedData) {
          props.push(diff$1(this.processedData));
        }
      }
      if (animationEnabled) {
        props.push(animationValidation$1(this, isContinuousX ? ["xValue"] : []));
      }
      props.push(
        valueProperty$1(this, xKey, isContinuousX, { id: "xValue" }),
        valueProperty$1(this, yKey, isContinuousY, { id: "yValue", invalidValue: void 0 })
      );
      yield this.requestDataModel(dataController, this.data, { props });
      this.animationState.transition("updateData");
    });
  }
  getSeriesDomain(direction) {
    const { axes, dataModel, processedData } = this;
    if (!processedData || !dataModel)
      return [];
    const xAxis = axes["x" /* X */];
    const yAxis = axes["y" /* Y */];
    const xDef = dataModel.resolveProcessedDataDefById(this, `xValue`);
    if (direction === "x" /* X */) {
      const domain = dataModel.getDomain(this, `xValue`, "value", processedData);
      if ((xDef == null ? void 0 : xDef.def.type) === "value" && xDef.def.valueType === "category") {
        return domain;
      }
      return fixNumericExtent$1(extent$5(domain), xAxis);
    } else {
      const domain = dataModel.getDomain(this, `yValue`, "value", processedData);
      return fixNumericExtent$1(domain, yAxis);
    }
  }
  createNodeData() {
    return __async$1(this, null, function* () {
      var _a, _b, _c;
      const { processedData, dataModel, axes } = this;
      const xAxis = axes["x" /* X */];
      const yAxis = axes["y" /* Y */];
      if (!processedData || !dataModel || !xAxis || !yAxis) {
        return [];
      }
      const { xKey, yKey, xName, yName, marker, label, connectMissingData } = this.properties;
      const xScale = xAxis.scale;
      const yScale = yAxis.scale;
      const xOffset = ((_a = xScale.bandwidth) != null ? _a : 0) / 2;
      const yOffset = ((_b = yScale.bandwidth) != null ? _b : 0) / 2;
      const nodeData = [];
      const size = marker.enabled ? marker.size : 0;
      const xIdx = dataModel.resolveProcessedDataIndexById(this, `xValue`).index;
      const yIdx = dataModel.resolveProcessedDataIndexById(this, `yValue`).index;
      let moveTo = true;
      let nextPoint;
      for (let i = 0; i < processedData.data.length; i++) {
        const { datum, values } = nextPoint != null ? nextPoint : processedData.data[i];
        const xDatum = values[xIdx];
        const yDatum = values[yIdx];
        if (yDatum === void 0) {
          moveTo = !connectMissingData;
        } else {
          const x = xScale.convert(xDatum) + xOffset;
          if (isNaN(x)) {
            moveTo = !connectMissingData;
            nextPoint = void 0;
            continue;
          }
          nextPoint = ((_c = processedData.data[i + 1]) == null ? void 0 : _c.values[yIdx]) === void 0 ? void 0 : processedData.data[i + 1];
          const y = yScale.convert(yDatum) + yOffset;
          const labelText = this.getLabelText(
            label,
            { value: yDatum, datum, xKey, yKey, xName, yName },
            (value) => isNumber2$1(value) ? value.toFixed(2) : String(value)
          );
          nodeData.push({
            series: this,
            datum,
            yKey,
            xKey,
            point: { x, y, moveTo, size },
            midPoint: { x, y },
            yValue: yDatum,
            xValue: xDatum,
            capDefaults: { lengthRatioMultiplier: this.properties.marker.getDiameter(), lengthMax: Infinity },
            label: labelText ? {
              text: labelText,
              fontStyle: label.fontStyle,
              fontWeight: label.fontWeight,
              fontSize: label.fontSize,
              fontFamily: label.fontFamily,
              textAlign: "center",
              textBaseline: "bottom",
              fill: label.color
            } : void 0
          });
          moveTo = false;
        }
      }
      return [
        {
          itemId: yKey,
          nodeData,
          labelData: nodeData,
          scales: __superGet$1(_LineSeries.prototype, this, "calculateScaling").call(this),
          visible: this.visible
        }
      ];
    });
  }
  isPathOrSelectionDirty() {
    return this.properties.marker.isDirty();
  }
  markerFactory() {
    const { shape } = this.properties.marker;
    const MarkerShape = getMarker$2(shape);
    return new MarkerShape();
  }
  updatePathNodes(opts) {
    return __async$1(this, null, function* () {
      const {
        paths: [lineNode],
        opacity,
        visible,
        animationEnabled
      } = opts;
      lineNode.setProperties({
        fill: void 0,
        lineJoin: "round",
        pointerEvents: 1 /* None */,
        opacity,
        stroke: this.properties.stroke,
        strokeWidth: this.getStrokeWidth(this.properties.strokeWidth),
        strokeOpacity: this.properties.strokeOpacity,
        lineDash: this.properties.lineDash,
        lineDashOffset: this.properties.lineDashOffset
      });
      if (!animationEnabled) {
        lineNode.visible = visible;
      }
      updateClipPath$1(this, lineNode);
    });
  }
  updateMarkerSelection(opts) {
    return __async$1(this, null, function* () {
      let { nodeData } = opts;
      const { markerSelection } = opts;
      const { shape, enabled } = this.properties.marker;
      nodeData = shape && enabled ? nodeData : [];
      if (this.properties.marker.isDirty()) {
        markerSelection.clear();
        markerSelection.cleanup();
      }
      return markerSelection.update(nodeData, void 0, (datum) => this.getDatumId(datum));
    });
  }
  updateMarkerNodes(opts) {
    return __async$1(this, null, function* () {
      const { markerSelection, isHighlight: highlighted } = opts;
      const { xKey, yKey, stroke, strokeWidth, strokeOpacity, marker, highlightStyle } = this.properties;
      const baseStyle = mergeDefaults$1(highlighted && highlightStyle.item, marker.getStyle(), {
        stroke,
        strokeWidth,
        strokeOpacity
      });
      const applyTranslation = this.ctx.animationManager.isSkipped();
      markerSelection.each((node, datum) => {
        this.updateMarkerStyle(node, marker, { datum, highlighted, xKey, yKey }, baseStyle, { applyTranslation });
      });
      if (!highlighted) {
        marker.markClean();
      }
    });
  }
  updateLabelSelection(opts) {
    return __async$1(this, null, function* () {
      return opts.labelSelection.update(this.isLabelEnabled() ? opts.labelData : []);
    });
  }
  updateLabelNodes(opts) {
    return __async$1(this, null, function* () {
      const { enabled, fontStyle, fontWeight, fontSize, fontFamily, color } = this.properties.label;
      opts.labelSelection.each((text, datum) => {
        const { point, label } = datum;
        if (datum && label && enabled) {
          text.fontStyle = fontStyle;
          text.fontWeight = fontWeight;
          text.fontSize = fontSize;
          text.fontFamily = fontFamily;
          text.textAlign = label.textAlign;
          text.textBaseline = label.textBaseline;
          text.text = label.text;
          text.x = point.x;
          text.y = point.y - 10;
          text.fill = color;
          text.visible = true;
        } else {
          text.visible = false;
        }
      });
    });
  }
  getTooltipHtml(nodeDatum) {
    var _a;
    const xAxis = this.axes["x" /* X */];
    const yAxis = this.axes["y" /* Y */];
    if (!this.properties.isValid() || !xAxis || !yAxis) {
      return "";
    }
    const { xKey, yKey, xName, yName, strokeWidth, marker, tooltip } = this.properties;
    const { datum, xValue, yValue } = nodeDatum;
    const xString = xAxis.formatDatum(xValue);
    const yString = yAxis.formatDatum(yValue);
    const title = sanitizeHtml$1((_a = this.properties.title) != null ? _a : yName);
    const content = sanitizeHtml$1(xString + ": " + yString);
    const baseStyle = mergeDefaults$1({ fill: marker.stroke }, marker.getStyle(), { strokeWidth });
    const { fill: color } = this.getMarkerStyle(
      marker,
      { datum: nodeDatum, xKey, yKey, highlighted: false },
      baseStyle
    );
    return tooltip.toTooltipHtml(
      { title, content, backgroundColor: color },
      __spreadValues$1({
        datum,
        xKey,
        xName,
        yKey,
        yName,
        title,
        color,
        seriesId: this.id
      }, this.getModuleTooltipParams())
    );
  }
  getLegendData(legendType) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i;
    if (!(((_a = this.data) == null ? void 0 : _a.length) && this.properties.isValid() && legendType === "category")) {
      return [];
    }
    const { yKey, yName, stroke, strokeOpacity, strokeWidth, lineDash, title, marker, visible } = this.properties;
    const color0 = "rgba(0, 0, 0, 0)";
    return [
      {
        legendType: "category",
        id: this.id,
        itemId: yKey,
        seriesId: this.id,
        enabled: visible,
        label: {
          text: (_b = title != null ? title : yName) != null ? _b : yKey
        },
        marker: {
          shape: marker.shape,
          fill: (_c = marker.fill) != null ? _c : color0,
          stroke: (_e = (_d = marker.stroke) != null ? _d : stroke) != null ? _e : color0,
          fillOpacity: (_f = marker.fillOpacity) != null ? _f : 1,
          strokeOpacity: (_h = (_g = marker.strokeOpacity) != null ? _g : strokeOpacity) != null ? _h : 1,
          strokeWidth: (_i = marker.strokeWidth) != null ? _i : 0,
          enabled: marker.enabled
        },
        line: {
          stroke: stroke != null ? stroke : color0,
          strokeOpacity,
          strokeWidth,
          lineDash
        }
      }
    ];
  }
  updatePaths(opts) {
    return __async$1(this, null, function* () {
      this.updateLinePaths([opts.paths], [opts.contextData]);
    });
  }
  updateLinePaths(paths, contextData) {
    contextData.forEach(({ nodeData }, contextDataIndex) => {
      const [lineNode] = paths[contextDataIndex];
      const { path: linePath } = lineNode;
      linePath.clear({ trackChanges: true });
      for (const data of nodeData) {
        if (data.point.moveTo) {
          linePath.moveTo(data.point.x, data.point.y);
        } else {
          linePath.lineTo(data.point.x, data.point.y);
        }
      }
      lineNode.checkPathDirty();
    });
  }
  animateEmptyUpdateReady(animationData) {
    const { markerSelections, labelSelections, annotationSelections, contextData, paths } = animationData;
    const { animationManager } = this.ctx;
    this.updateLinePaths(paths, contextData);
    pathSwipeInAnimation$1(this, animationManager, paths.flat());
    resetMotion$1(markerSelections, resetMarkerPositionFn$1);
    markerSwipeScaleInAnimation$1(this, animationManager, markerSelections);
    seriesLabelFadeInAnimation$1(this, "labels", animationManager, labelSelections);
    seriesLabelFadeInAnimation$1(this, "annotations", animationManager, annotationSelections);
  }
  animateReadyResize(animationData) {
    const { contextData, paths } = animationData;
    this.updateLinePaths(paths, contextData);
    super.animateReadyResize(animationData);
  }
  animateWaitingUpdateReady(animationData) {
    var _a, _b;
    const { animationManager } = this.ctx;
    const { markerSelections, labelSelections, annotationSelections, contextData, paths, previousContextData } = animationData;
    super.resetAllAnimation(animationData);
    if (contextData.length === 0 || !previousContextData || previousContextData.length === 0) {
      animationManager.skipCurrentBatch();
      this.updateLinePaths(paths, contextData);
      return;
    }
    const [path] = paths;
    const [newData] = contextData;
    const [oldData] = previousContextData;
    const fns = prepareLinePathAnimation$1(newData, oldData, (_b = (_a = this.processedData) == null ? void 0 : _a.reduced) == null ? void 0 : _b.diff);
    if (fns === void 0) {
      animationManager.skipCurrentBatch();
      this.updateLinePaths(paths, contextData);
      return;
    }
    fromToMotion$1(this.id, "marker", animationManager, markerSelections, fns.marker);
    fromToMotion$1(this.id, "path_properties", animationManager, path, fns.pathProperties);
    pathMotion$1(this.id, "path_update", animationManager, path, fns.path);
    if (fns.hasMotion) {
      seriesLabelFadeInAnimation$1(this, "labels", animationManager, labelSelections);
      seriesLabelFadeInAnimation$1(this, "annotations", animationManager, annotationSelections);
    }
  }
  getDatumId(datum) {
    return createDatumId$1([`${datum.xValue}`]);
  }
  isLabelEnabled() {
    return this.properties.label.enabled;
  }
  getBandScalePadding() {
    return { inner: 1, outer: 0.1 };
  }
  nodeFactory() {
    return new Group$1();
  }
};
_LineSeries$1.className = "LineSeries";
_LineSeries$1.type = "line";
var LineSeries = _LineSeries$1;

// packages/ag-charts-community/src/chart/series/cartesian/lineSeriesModule.ts
var LineSeriesModule = {
  type: "series",
  optionsKey: "series[]",
  packageType: "community",
  chartTypes: ["cartesian"],
  identifier: "line",
  instanceConstructor: LineSeries,
  seriesDefaults: DEFAULT_CARTESIAN_CHART_OVERRIDES,
  themeTemplate: {
    __extends__: EXTENDS_SERIES_DEFAULTS$1,
    tooltip: {
      position: {
        type: "node"
      }
    },
    strokeWidth: 2,
    strokeOpacity: 1,
    lineDash: [0],
    lineDashOffset: 0,
    marker: {
      __extends__: EXTENDS_CARTESIAN_MARKER_DEFAULTS$1,
      fillOpacity: 1,
      strokeOpacity: 1,
      strokeWidth: 0
    },
    label: {
      enabled: false,
      fontStyle: void 0,
      fontWeight: void 0,
      fontSize: 12,
      fontFamily: DEFAULT_FONT_FAMILY$1,
      color: DEFAULT_LABEL_COLOUR$1,
      formatter: void 0
    }
  },
  enterpriseThemeTemplate: {
    errorBar: {
      cap: {
        lengthRatio: 1
      }
    }
  },
  paletteFactory: (params) => {
    const { marker } = markerPaletteFactory$1(params);
    return {
      stroke: marker.fill,
      marker
    };
  }
};

// packages/ag-charts-community/src/chart/series/cartesian/scatterSeriesProperties.ts
var ScatterSeriesProperties$1 = class extends CartesianSeriesProperties$1 {
  constructor() {
    super(...arguments);
    this.colorRange = ["#ffff00", "#00ff00", "#0000ff"];
    this.marker = new SeriesMarker$1();
    this.label = new Label$2();
    this.tooltip = new SeriesTooltip$1();
  }
};
__decorateClass$1([
  Validate$1(STRING$1)
], ScatterSeriesProperties$1.prototype, "xKey", 2);
__decorateClass$1([
  Validate$1(STRING$1)
], ScatterSeriesProperties$1.prototype, "yKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], ScatterSeriesProperties$1.prototype, "labelKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], ScatterSeriesProperties$1.prototype, "colorKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], ScatterSeriesProperties$1.prototype, "xName", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], ScatterSeriesProperties$1.prototype, "yName", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], ScatterSeriesProperties$1.prototype, "labelName", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], ScatterSeriesProperties$1.prototype, "colorName", 2);
__decorateClass$1([
  Validate$1(NUMBER_ARRAY$1, { optional: true })
], ScatterSeriesProperties$1.prototype, "colorDomain", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING_ARRAY$1)
], ScatterSeriesProperties$1.prototype, "colorRange", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], ScatterSeriesProperties$1.prototype, "title", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], ScatterSeriesProperties$1.prototype, "marker", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], ScatterSeriesProperties$1.prototype, "label", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], ScatterSeriesProperties$1.prototype, "tooltip", 2);

// packages/ag-charts-community/src/chart/series/cartesian/scatterSeries.ts
var _ScatterSeries$1 = class _ScatterSeries extends CartesianSeries$1 {
  constructor(moduleCtx) {
    super({
      moduleCtx,
      pickModes: [
        2 /* NEAREST_BY_MAIN_CATEGORY_AXIS_FIRST */,
        3 /* NEAREST_NODE */,
        0 /* EXACT_SHAPE_MATCH */
      ],
      pathsPerSeries: 0,
      hasMarkers: true,
      markerSelectionGarbageCollection: false,
      animationResetFns: {
        marker: resetMarkerFn$1,
        label: resetLabelFn$1
      }
    });
    this.properties = new ScatterSeriesProperties$1();
    this.colorScale = new ColorScale$1();
  }
  processData(dataController) {
    return __async$1(this, null, function* () {
      var _a;
      if (!this.properties.isValid() || this.data == null) {
        return;
      }
      const { isContinuousX, isContinuousY } = this.isContinuous();
      const { xKey, yKey, labelKey, colorKey, colorDomain, colorRange } = this.properties;
      const { dataModel, processedData } = yield this.requestDataModel(dataController, this.data, {
        props: [
          keyProperty$1(this, xKey, isContinuousX, { id: "xKey-raw" }),
          keyProperty$1(this, yKey, isContinuousY, { id: "yKey-raw" }),
          ...labelKey ? [keyProperty$1(this, labelKey, false, { id: `labelKey-raw` })] : [],
          valueProperty$1(this, xKey, isContinuousX, { id: `xValue` }),
          valueProperty$1(this, yKey, isContinuousY, { id: `yValue` }),
          ...colorKey ? [valueProperty$1(this, colorKey, true, { id: `colorValue` })] : [],
          ...labelKey ? [valueProperty$1(this, labelKey, false, { id: `labelValue` })] : []
        ],
        dataVisible: this.visible
      });
      if (colorKey) {
        const colorKeyIdx = dataModel.resolveProcessedDataIndexById(this, `colorValue`).index;
        this.colorScale.domain = (_a = colorDomain != null ? colorDomain : processedData.domain.values[colorKeyIdx]) != null ? _a : [];
        this.colorScale.range = colorRange;
        this.colorScale.update();
      }
      this.animationState.transition("updateData");
    });
  }
  getSeriesDomain(direction) {
    const { dataModel, processedData } = this;
    if (!processedData || !dataModel)
      return [];
    const id = direction === "x" /* X */ ? `xValue` : `yValue`;
    const dataDef = dataModel.resolveProcessedDataDefById(this, id);
    const domain = dataModel.getDomain(this, id, "value", processedData);
    if ((dataDef == null ? void 0 : dataDef.def.type) === "value" && (dataDef == null ? void 0 : dataDef.def.valueType) === "category") {
      return domain;
    }
    const axis = this.axes[direction];
    return fixNumericExtent$1(extent$5(domain), axis);
  }
  createNodeData() {
    return __async$1(this, null, function* () {
      var _a, _b, _c;
      const { axes, dataModel, processedData, colorScale } = this;
      const { xKey, yKey, labelKey, colorKey, xName, yName, labelName, marker, label, visible } = this.properties;
      const xAxis = axes["x" /* X */];
      const yAxis = axes["y" /* Y */];
      if (!(dataModel && processedData && visible && xAxis && yAxis)) {
        return [];
      }
      const xDataIdx = dataModel.resolveProcessedDataIndexById(this, `xValue`).index;
      const yDataIdx = dataModel.resolveProcessedDataIndexById(this, `yValue`).index;
      const colorDataIdx = colorKey ? dataModel.resolveProcessedDataIndexById(this, `colorValue`).index : -1;
      const labelDataIdx = labelKey ? dataModel.resolveProcessedDataIndexById(this, `labelValue`).index : -1;
      const xScale = xAxis.scale;
      const yScale = yAxis.scale;
      const xOffset = ((_a = xScale.bandwidth) != null ? _a : 0) / 2;
      const yOffset = ((_b = yScale.bandwidth) != null ? _b : 0) / 2;
      const nodeData = [];
      const font = label.getFont();
      for (const { values, datum } of (_c = processedData.data) != null ? _c : []) {
        const xDatum = values[xDataIdx];
        const yDatum = values[yDataIdx];
        const x = xScale.convert(xDatum) + xOffset;
        const y = yScale.convert(yDatum) + yOffset;
        const labelText = this.getLabelText(label, {
          value: labelKey ? values[labelDataIdx] : yDatum,
          datum,
          xKey,
          yKey,
          labelKey,
          xName,
          yName,
          labelName
        });
        const size = HdpiCanvas$1.getTextSize(labelText, font);
        const fill = colorKey ? colorScale.convert(values[colorDataIdx]) : void 0;
        nodeData.push({
          series: this,
          itemId: yKey,
          yKey,
          xKey,
          datum,
          xValue: xDatum,
          yValue: yDatum,
          capDefaults: { lengthRatioMultiplier: marker.getDiameter(), lengthMax: Infinity },
          point: { x, y, size: marker.size },
          midPoint: { x, y },
          fill,
          label: __spreadValues$1({ text: labelText }, size)
        });
      }
      return [
        {
          itemId: yKey,
          nodeData,
          labelData: nodeData,
          scales: __superGet$1(_ScatterSeries.prototype, this, "calculateScaling").call(this),
          visible: this.visible
        }
      ];
    });
  }
  isPathOrSelectionDirty() {
    return this.properties.marker.isDirty();
  }
  getLabelData() {
    var _a;
    return (_a = this.contextNodeData) == null ? void 0 : _a.reduce((r, n) => r.concat(n.labelData), []);
  }
  markerFactory() {
    const { shape } = this.properties.marker;
    const MarkerShape = getMarker$2(shape);
    return new MarkerShape();
  }
  updateMarkerSelection(opts) {
    return __async$1(this, null, function* () {
      const { nodeData, markerSelection } = opts;
      if (this.properties.marker.isDirty()) {
        markerSelection.clear();
        markerSelection.cleanup();
      }
      return markerSelection.update(this.properties.marker.enabled ? nodeData : []);
    });
  }
  updateMarkerNodes(opts) {
    return __async$1(this, null, function* () {
      const { markerSelection, isHighlight: highlighted } = opts;
      const { xKey, yKey, labelKey, marker, highlightStyle } = this.properties;
      const baseStyle = mergeDefaults$1(highlighted && highlightStyle.item, marker.getStyle());
      markerSelection.each((node, datum) => {
        this.updateMarkerStyle(node, marker, { datum, highlighted, xKey, yKey, labelKey }, baseStyle);
      });
      if (!highlighted) {
        marker.markClean();
      }
    });
  }
  updateLabelSelection(opts) {
    return __async$1(this, null, function* () {
      var _a, _b;
      const placedLabels = this.isLabelEnabled() ? (_b = (_a = this.chart) == null ? void 0 : _a.placeLabels().get(this)) != null ? _b : [] : [];
      return opts.labelSelection.update(
        placedLabels.map(({ datum, x, y }) => __spreadProps$1(__spreadValues$1({}, datum), {
          point: { x, y, size: datum.point.size }
        })),
        (text) => {
          text.pointerEvents = 1 /* None */;
        }
      );
    });
  }
  updateLabelNodes(opts) {
    return __async$1(this, null, function* () {
      const { label } = this.properties;
      opts.labelSelection.each((text, datum) => {
        var _a, _b, _c, _d;
        text.text = datum.label.text;
        text.fill = label.color;
        text.x = (_b = (_a = datum.point) == null ? void 0 : _a.x) != null ? _b : 0;
        text.y = (_d = (_c = datum.point) == null ? void 0 : _c.y) != null ? _d : 0;
        text.fontStyle = label.fontStyle;
        text.fontWeight = label.fontWeight;
        text.fontSize = label.fontSize;
        text.fontFamily = label.fontFamily;
        text.textAlign = "left";
        text.textBaseline = "top";
      });
    });
  }
  getTooltipHtml(nodeDatum) {
    const xAxis = this.axes["x" /* X */];
    const yAxis = this.axes["y" /* Y */];
    if (!this.properties.isValid() || !xAxis || !yAxis) {
      return "";
    }
    const { xKey, yKey, labelKey, xName, yName, labelName, title = yName, marker, tooltip } = this.properties;
    const { datum, xValue, yValue, label } = nodeDatum;
    const baseStyle = mergeDefaults$1(
      { fill: nodeDatum.fill, strokeWidth: this.getStrokeWidth(marker.strokeWidth) },
      marker.getStyle()
    );
    const { fill: color = "gray" } = this.getMarkerStyle(
      marker,
      { datum: nodeDatum, highlighted: false, xKey, yKey, labelKey },
      baseStyle
    );
    const xString = sanitizeHtml$1(xAxis.formatDatum(xValue));
    const yString = sanitizeHtml$1(yAxis.formatDatum(yValue));
    let content = `<b>${sanitizeHtml$1(xName != null ? xName : xKey)}</b>: ${xString}<br><b>${sanitizeHtml$1(yName != null ? yName : yKey)}</b>: ${yString}`;
    if (labelKey) {
      content = `<b>${sanitizeHtml$1(labelName != null ? labelName : labelKey)}</b>: ${sanitizeHtml$1(label.text)}<br>` + content;
    }
    return tooltip.toTooltipHtml(
      { title, content, backgroundColor: color },
      __spreadValues$1({
        datum,
        xKey,
        xName,
        yKey,
        yName,
        labelKey,
        labelName,
        title,
        color,
        seriesId: this.id
      }, this.getModuleTooltipParams())
    );
  }
  getLegendData(legendType) {
    var _a, _b, _c, _d, _e, _f;
    const { yKey, yName, title, marker, visible } = this.properties;
    const { fill, stroke, fillOpacity, strokeOpacity, strokeWidth } = marker;
    if (!((_a = this.data) == null ? void 0 : _a.length) || !this.properties.isValid() || legendType !== "category") {
      return [];
    }
    return [
      {
        legendType: "category",
        id: this.id,
        itemId: yKey,
        seriesId: this.id,
        enabled: visible,
        label: {
          text: (_b = title != null ? title : yName) != null ? _b : yKey
        },
        marker: {
          shape: marker.shape,
          fill: (_d = (_c = marker.fill) != null ? _c : fill) != null ? _d : "rgba(0, 0, 0, 0)",
          stroke: (_f = (_e = marker.stroke) != null ? _e : stroke) != null ? _f : "rgba(0, 0, 0, 0)",
          fillOpacity: fillOpacity != null ? fillOpacity : 1,
          strokeOpacity: strokeOpacity != null ? strokeOpacity : 1,
          strokeWidth: strokeWidth != null ? strokeWidth : 0
        }
      }
    ];
  }
  animateEmptyUpdateReady(data) {
    const { markerSelections, labelSelections, annotationSelections } = data;
    markerScaleInAnimation$1(this, this.ctx.animationManager, markerSelections);
    seriesLabelFadeInAnimation$1(this, "labels", this.ctx.animationManager, labelSelections);
    seriesLabelFadeInAnimation$1(this, "annotations", this.ctx.animationManager, annotationSelections);
  }
  isLabelEnabled() {
    return this.properties.label.enabled;
  }
  nodeFactory() {
    return new Group$1();
  }
};
_ScatterSeries$1.className = "ScatterSeries";
_ScatterSeries$1.type = "scatter";
var ScatterSeries$1 = _ScatterSeries$1;

// packages/ag-charts-community/src/chart/series/cartesian/scatterSeriesModule.ts
var ScatterSeriesModule = {
  type: "series",
  optionsKey: "series[]",
  packageType: "community",
  chartTypes: ["cartesian"],
  identifier: "scatter",
  instanceConstructor: ScatterSeries$1,
  seriesDefaults: {
    axes: [
      { type: CARTESIAN_AXIS_TYPES$1.NUMBER, position: CARTESIAN_AXIS_POSITIONS$1.BOTTOM },
      { type: CARTESIAN_AXIS_TYPES$1.NUMBER, position: CARTESIAN_AXIS_POSITIONS$1.LEFT }
    ]
  },
  themeTemplate: {
    __extends__: EXTENDS_SERIES_DEFAULTS$1,
    tooltip: {
      position: {
        type: "node"
      }
    },
    marker: {
      __extends__: EXTENDS_CARTESIAN_MARKER_DEFAULTS$1,
      fillOpacity: 0.8
    },
    label: {
      enabled: false,
      fontStyle: void 0,
      fontWeight: void 0,
      fontSize: 12,
      fontFamily: DEFAULT_FONT_FAMILY$1,
      color: DEFAULT_LABEL_COLOUR$1
    }
  },
  enterpriseThemeTemplate: {
    errorBar: {
      cap: {
        lengthRatio: 1
      }
    }
  },
  paletteFactory: markerPaletteFactory$1
};

// packages/ag-charts-community/src/chart/themes/defaultColors.ts
var DEFAULT_FILLS$1 = {
  BLUE: "#5090dc",
  ORANGE: "#ffa03a",
  GREEN: "#459d55",
  CYAN: "#34bfe1",
  YELLOW: "#e1cc00",
  VIOLET: "#9669cb",
  GRAY: "#b5b5b5",
  MAGENTA: "#bd5aa7",
  BROWN: "#8a6224",
  RED: "#ef5452"
};
var DEFAULT_STROKES$1 = {
  BLUE: "#2b5c95",
  ORANGE: "#cc6f10",
  GREEN: "#1e652e",
  CYAN: "#18859e",
  YELLOW: "#a69400",
  VIOLET: "#603c88",
  GRAY: "#575757",
  MAGENTA: "#7d2f6d",
  BROWN: "#4f3508",
  RED: "#a82529"
};

// packages/ag-charts-community/src/chart/series/polar/pieSeriesProperties.ts
var PieTitle$1 = class extends Caption$1 {
  constructor() {
    super(...arguments);
    this.showInLegend = false;
  }
};
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], PieTitle$1.prototype, "showInLegend", 2);
var DoughnutInnerLabel$1 = class extends Label$2 {
  constructor() {
    super(...arguments);
    this.margin = 2;
  }
  set(properties, _reset) {
    return super.set(properties);
  }
};
__decorateClass$1([
  Validate$1(STRING$1)
], DoughnutInnerLabel$1.prototype, "text", 2);
__decorateClass$1([
  Validate$1(NUMBER$1)
], DoughnutInnerLabel$1.prototype, "margin", 2);
var DoughnutInnerCircle$1 = class extends BaseProperties$1 {
  constructor() {
    super(...arguments);
    this.fill = "transparent";
    this.fillOpacity = 1;
  }
};
__decorateClass$1([
  Validate$1(COLOR_STRING$1)
], DoughnutInnerCircle$1.prototype, "fill", 2);
__decorateClass$1([
  Validate$1(RATIO$1)
], DoughnutInnerCircle$1.prototype, "fillOpacity", 2);
var PieSeriesCalloutLabel$1 = class extends Label$2 {
  constructor() {
    super(...arguments);
    this.offset = 3;
    this.minAngle = 0;
    this.minSpacing = 4;
    this.maxCollisionOffset = 50;
    this.avoidCollisions = true;
  }
};
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], PieSeriesCalloutLabel$1.prototype, "offset", 2);
__decorateClass$1([
  Validate$1(DEGREE$1)
], PieSeriesCalloutLabel$1.prototype, "minAngle", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], PieSeriesCalloutLabel$1.prototype, "minSpacing", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], PieSeriesCalloutLabel$1.prototype, "maxCollisionOffset", 2);
__decorateClass$1([
  Validate$1(BOOLEAN$1)
], PieSeriesCalloutLabel$1.prototype, "avoidCollisions", 2);
var PieSeriesSectorLabel$1 = class extends Label$2 {
  constructor() {
    super(...arguments);
    this.positionOffset = 0;
    this.positionRatio = 0.5;
  }
};
__decorateClass$1([
  Validate$1(NUMBER$1)
], PieSeriesSectorLabel$1.prototype, "positionOffset", 2);
__decorateClass$1([
  Validate$1(RATIO$1)
], PieSeriesSectorLabel$1.prototype, "positionRatio", 2);
var PieSeriesCalloutLine$1 = class extends BaseProperties$1 {
  constructor() {
    super(...arguments);
    this.length = 10;
    this.strokeWidth = 1;
  }
};
__decorateClass$1([
  Validate$1(COLOR_STRING_ARRAY$1, { optional: true })
], PieSeriesCalloutLine$1.prototype, "colors", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], PieSeriesCalloutLine$1.prototype, "length", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], PieSeriesCalloutLine$1.prototype, "strokeWidth", 2);
var PieSeriesProperties$1 = class extends SeriesProperties$1 {
  constructor() {
    super(...arguments);
    this.fills = Object.values(DEFAULT_FILLS$1);
    this.strokes = Object.values(DEFAULT_STROKES$1);
    this.fillOpacity = 1;
    this.strokeOpacity = 1;
    this.lineDash = [0];
    this.lineDashOffset = 0;
    this.rotation = 0;
    this.outerRadiusOffset = 0;
    this.outerRadiusRatio = 1;
    this.innerRadiusOffset = 0;
    this.innerRadiusRatio = 1;
    this.strokeWidth = 1;
    this.sectorSpacing = void 0;
    this.innerLabels = new PropertiesArray$1(DoughnutInnerLabel$1);
    this.title = new PieTitle$1();
    this.innerCircle = new DoughnutInnerCircle$1();
    this.shadow = new DropShadow$1();
    this.calloutLabel = new PieSeriesCalloutLabel$1();
    this.sectorLabel = new PieSeriesSectorLabel$1();
    this.calloutLine = new PieSeriesCalloutLine$1();
    this.tooltip = new SeriesTooltip$1();
    this.__BACKGROUND_COLOR_DO_NOT_USE = void 0;
  }
};
__decorateClass$1([
  Validate$1(STRING$1)
], PieSeriesProperties$1.prototype, "angleKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], PieSeriesProperties$1.prototype, "angleName", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], PieSeriesProperties$1.prototype, "radiusKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], PieSeriesProperties$1.prototype, "radiusName", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], PieSeriesProperties$1.prototype, "radiusMin", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], PieSeriesProperties$1.prototype, "radiusMax", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], PieSeriesProperties$1.prototype, "calloutLabelKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], PieSeriesProperties$1.prototype, "calloutLabelName", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], PieSeriesProperties$1.prototype, "sectorLabelKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], PieSeriesProperties$1.prototype, "sectorLabelName", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], PieSeriesProperties$1.prototype, "legendItemKey", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING_ARRAY$1)
], PieSeriesProperties$1.prototype, "fills", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING_ARRAY$1)
], PieSeriesProperties$1.prototype, "strokes", 2);
__decorateClass$1([
  Validate$1(RATIO$1)
], PieSeriesProperties$1.prototype, "fillOpacity", 2);
__decorateClass$1([
  Validate$1(RATIO$1)
], PieSeriesProperties$1.prototype, "strokeOpacity", 2);
__decorateClass$1([
  Validate$1(LINE_DASH$1)
], PieSeriesProperties$1.prototype, "lineDash", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], PieSeriesProperties$1.prototype, "lineDashOffset", 2);
__decorateClass$1([
  Validate$1(FUNCTION$1, { optional: true })
], PieSeriesProperties$1.prototype, "formatter", 2);
__decorateClass$1([
  Validate$1(DEGREE$1)
], PieSeriesProperties$1.prototype, "rotation", 2);
__decorateClass$1([
  Validate$1(NUMBER$1)
], PieSeriesProperties$1.prototype, "outerRadiusOffset", 2);
__decorateClass$1([
  Validate$1(RATIO$1)
], PieSeriesProperties$1.prototype, "outerRadiusRatio", 2);
__decorateClass$1([
  Validate$1(NUMBER$1)
], PieSeriesProperties$1.prototype, "innerRadiusOffset", 2);
__decorateClass$1([
  Validate$1(RATIO$1)
], PieSeriesProperties$1.prototype, "innerRadiusRatio", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1)
], PieSeriesProperties$1.prototype, "strokeWidth", 2);
__decorateClass$1([
  Validate$1(POSITIVE_NUMBER$1, { optional: true })
], PieSeriesProperties$1.prototype, "sectorSpacing", 2);
__decorateClass$1([
  Validate$1(OBJECT_ARRAY$1)
], PieSeriesProperties$1.prototype, "innerLabels", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], PieSeriesProperties$1.prototype, "title", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], PieSeriesProperties$1.prototype, "innerCircle", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], PieSeriesProperties$1.prototype, "shadow", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], PieSeriesProperties$1.prototype, "calloutLabel", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], PieSeriesProperties$1.prototype, "sectorLabel", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], PieSeriesProperties$1.prototype, "calloutLine", 2);
__decorateClass$1([
  Validate$1(OBJECT$1)
], PieSeriesProperties$1.prototype, "tooltip", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], PieSeriesProperties$1.prototype, "__BACKGROUND_COLOR_DO_NOT_USE", 2);

// packages/ag-charts-community/src/chart/series/polar/pieUtil.ts
function preparePieSeriesAnimationFunctions$1(initialLoad, rotationDegrees, scaleFn, oldScaleFn) {
  const scale2 = [scaleFn.convert(0), scaleFn.convert(1)];
  const oldScale = [oldScaleFn.convert(0), oldScaleFn.convert(1)];
  const rotation = Math.PI / -2 + toRadians$2(rotationDegrees);
  const scaleToNewRadius = ({ radius }) => {
    return { innerRadius: scale2[0], outerRadius: scale2[0] + (scale2[1] - scale2[0]) * radius };
  };
  const scaleToOldRadius = ({ radius }) => {
    return { innerRadius: oldScale[0], outerRadius: oldScale[0] + (oldScale[1] - oldScale[0]) * radius };
  };
  const fromFn = (sect, datum, status, { prevFromProps }) => {
    var _a, _b, _c, _d, _e, _f;
    let { startAngle, endAngle, innerRadius, outerRadius } = sect;
    let { fill, stroke } = datum.sectorFormat;
    if (status === "unknown" || status === "added" && !prevFromProps) {
      startAngle = rotation;
      endAngle = rotation;
      innerRadius = datum.innerRadius;
      outerRadius = datum.outerRadius;
    } else if (status === "added" && prevFromProps) {
      startAngle = (_a = prevFromProps.endAngle) != null ? _a : rotation;
      endAngle = (_b = prevFromProps.endAngle) != null ? _b : rotation;
      innerRadius = (_c = prevFromProps.innerRadius) != null ? _c : datum.innerRadius;
      outerRadius = (_d = prevFromProps.outerRadius) != null ? _d : datum.outerRadius;
    }
    if (status === "added" && !initialLoad) {
      const radii = scaleToOldRadius(datum);
      innerRadius = radii.innerRadius;
      outerRadius = radii.outerRadius;
    }
    if (status === "updated") {
      fill = (_e = sect.fill) != null ? _e : fill;
      stroke = (_f = sect.stroke) != null ? _f : stroke;
    }
    return { startAngle, endAngle, innerRadius, outerRadius, fill, stroke };
  };
  const toFn = (_sect, datum, status, { prevLive }) => {
    var _a, _b;
    let { startAngle, endAngle, innerRadius, outerRadius } = datum;
    const { stroke, fill } = datum.sectorFormat;
    if (status === "removed" && prevLive) {
      startAngle = (_a = prevLive.datum) == null ? void 0 : _a.endAngle;
      endAngle = (_b = prevLive.datum) == null ? void 0 : _b.endAngle;
    } else if (status === "removed" && !prevLive) {
      startAngle = rotation;
      endAngle = rotation;
    }
    if (status === "removed") {
      const radii = scaleToNewRadius(datum);
      innerRadius = radii.innerRadius;
      outerRadius = radii.outerRadius;
    }
    return { startAngle, endAngle, outerRadius, innerRadius, stroke, fill };
  };
  const innerCircle = {
    fromFn: (node, _datum) => {
      var _a, _b, _c;
      return { size: (_c = (_b = (_a = node.previousDatum) == null ? void 0 : _a.radius) != null ? _b : node.size) != null ? _c : 0 };
    },
    toFn: (_node, datum) => {
      var _a;
      return { size: (_a = datum.radius) != null ? _a : 0 };
    }
  };
  return { nodes: { toFn, fromFn }, innerCircle };
}
function resetPieSelectionsFn$1(_node, datum) {
  return {
    startAngle: datum.startAngle,
    endAngle: datum.endAngle,
    innerRadius: datum.innerRadius,
    outerRadius: datum.outerRadius,
    fill: datum.sectorFormat.fill,
    stroke: datum.sectorFormat.stroke
  };
}

// packages/ag-charts-community/src/chart/series/polar/polarSeries.ts
var PolarSeries$1 = class extends DataModelSeries$1 {
  constructor(_a) {
    var _b = _a, {
      useLabelLayer = false,
      pickModes = [0 /* EXACT_SHAPE_MATCH */],
      canHaveAxes = false,
      animationResetFns
    } = _b, opts = __objRest$1(_b, [
      "useLabelLayer",
      "pickModes",
      "canHaveAxes",
      "animationResetFns"
    ]);
    super(__spreadProps$1(__spreadValues$1({}, opts), {
      useLabelLayer,
      pickModes,
      contentGroupVirtual: false,
      directionKeys: {
        ["x" /* X */]: ["angleKey"],
        ["y" /* Y */]: ["radiusKey"]
      },
      directionNames: {
        ["x" /* X */]: ["angleName"],
        ["y" /* Y */]: ["radiusName"]
      },
      canHaveAxes
    }));
    this.sectorGroup = this.contentGroup.appendChild(new Group$1());
    this.itemSelection = Selection$1.select(
      this.sectorGroup,
      () => this.nodeFactory(),
      false
    );
    this.labelSelection = Selection$1.select(this.labelGroup, Text$1, false);
    this.highlightSelection = Selection$1.select(
      this.highlightGroup,
      () => this.nodeFactory()
    );
    /**
     * The center of the polar series (for example, the center of a pie).
     * If the polar chart has multiple series, all of them will have their
     * center set to the same value as a result of the polar chart layout.
     * The center coordinates are not supposed to be set by the user.
     */
    this.centerX = 0;
    this.centerY = 0;
    /**
     * The maximum radius the series can use.
     * This value is set automatically as a result of the polar chart layout
     * and is not supposed to be set by the user.
     */
    this.radius = 0;
    this.sectorGroup.zIndexSubOrder = [() => this._declarationOrder, 1];
    this.animationResetFns = animationResetFns;
    this.animationState = new StateMachine$1(
      "empty",
      {
        empty: {
          update: {
            target: "ready",
            action: (data) => this.animateEmptyUpdateReady(data)
          }
        },
        ready: {
          updateData: "waiting",
          clear: "clearing",
          highlight: (data) => this.animateReadyHighlight(data),
          highlightMarkers: (data) => this.animateReadyHighlightMarkers(data),
          resize: (data) => this.animateReadyResize(data)
        },
        waiting: {
          update: {
            target: "ready",
            action: (data) => this.animateWaitingUpdateReady(data)
          }
        },
        clearing: {
          update: {
            target: "empty",
            action: (data) => this.animateClearingUpdateEmpty(data)
          }
        }
      },
      () => this.checkProcessedDataAnimatable()
    );
  }
  getLabelData() {
    return [];
  }
  computeLabelsBBox(_options, _seriesRect) {
    return null;
  }
  resetAllAnimation() {
    var _a;
    const { item, label } = (_a = this.animationResetFns) != null ? _a : {};
    this.ctx.animationManager.stopByAnimationGroupId(this.id);
    if (item) {
      resetMotion$1([this.itemSelection, this.highlightSelection], item);
    }
    if (label) {
      resetMotion$1([this.labelSelection], label);
    }
    this.itemSelection.cleanup();
    this.labelSelection.cleanup();
    this.highlightSelection.cleanup();
  }
  animateEmptyUpdateReady(_data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation();
  }
  animateWaitingUpdateReady(_data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation();
  }
  animateReadyHighlight(_data) {
    var _a;
    const { item } = (_a = this.animationResetFns) != null ? _a : {};
    if (item) {
      resetMotion$1([this.highlightSelection], item);
    }
  }
  animateReadyHighlightMarkers(_data) {
  }
  animateReadyResize(_data) {
    this.resetAllAnimation();
  }
  animateClearingUpdateEmpty(_data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation();
  }
  animationTransitionClear() {
    this.animationState.transition("clear", this.getAnimationData());
  }
  getAnimationData(seriesRect) {
    return { seriesRect };
  }
};

// packages/ag-charts-community/src/chart/series/polar/pieSeries.ts
var PieSeriesNodeClickEvent$1 = class extends SeriesNodeClickEvent$1 {
  constructor(type, nativeEvent, datum, series) {
    super(type, nativeEvent, datum, series);
    this.angleKey = series.properties.angleKey;
    this.radiusKey = series.properties.radiusKey;
    this.calloutLabelKey = series.properties.calloutLabelKey;
    this.sectorLabelKey = series.properties.sectorLabelKey;
  }
};
var PieSeries$1 = class extends PolarSeries$1 {
  constructor(moduleCtx) {
    super({
      moduleCtx,
      useLabelLayer: true,
      animationResetFns: { item: resetPieSelectionsFn$1, label: resetLabelFn$1 }
    });
    this.properties = new PieSeriesProperties$1();
    this.previousRadiusScale = new LinearScale$2();
    this.radiusScale = new LinearScale$2();
    // The group node that contains the background graphics.
    this.backgroundGroup = this.rootGroup.appendChild(
      new Group$1({
        name: `${this.id}-background`,
        layer: true,
        zIndex: 0 /* SERIES_BACKGROUND_ZINDEX */
      })
    );
    // AG-6193 If the sum of all datums is 0, then we'll draw 1 or 2 rings to represent the empty series.
    this.zerosumRingsGroup = this.backgroundGroup.appendChild(new Group$1({ name: `${this.id}-zerosumRings` }));
    this.zerosumOuterRing = this.zerosumRingsGroup.appendChild(new Circle$1());
    this.zerosumInnerRing = this.zerosumRingsGroup.appendChild(new Circle$1());
    this.innerCircleGroup = this.backgroundGroup.appendChild(new Group$1({ name: `${this.id}-innerCircle` }));
    this.nodeData = [];
    // When a user toggles a series item (e.g. from the legend), its boolean state is recorded here.
    this.seriesItemEnabled = [];
    this.surroundingRadius = void 0;
    this.NodeClickEvent = PieSeriesNodeClickEvent$1;
    this.angleScale = new LinearScale$2();
    this.angleScale.domain = [0, 1];
    this.angleScale.range = [-Math.PI, Math.PI].map((angle) => angle + Math.PI / 2);
    const pieCalloutLabels = new Group$1({ name: "pieCalloutLabels" });
    const pieSectorLabels = new Group$1({ name: "pieSectorLabels" });
    const innerLabels = new Group$1({ name: "innerLabels" });
    this.labelGroup.append(pieCalloutLabels);
    this.labelGroup.append(pieSectorLabels);
    this.labelGroup.append(innerLabels);
    this.calloutLabelSelection = Selection$1.select(pieCalloutLabels, Group$1);
    this.sectorLabelSelection = Selection$1.select(pieSectorLabels, Text$1);
    this.innerLabelsSelection = Selection$1.select(innerLabels, Text$1);
    this.innerCircleSelection = Selection$1.select(this.innerCircleGroup, Circle$1);
    for (const circle of [this.zerosumInnerRing, this.zerosumOuterRing]) {
      circle.fillOpacity = 0;
      circle.stroke = this.properties.calloutLabel.color;
      circle.strokeWidth = 1;
      circle.strokeOpacity = 1;
    }
  }
  addChartEventListeners() {
    var _a;
    (_a = this.ctx.chartEventManager) == null ? void 0 : _a.addListener("legend-item-click", (event) => this.onLegendItemClick(event));
  }
  visibleChanged() {
    this.processSeriesItemEnabled();
  }
  get visible() {
    return this.seriesItemEnabled.length ? this.seriesItemEnabled.some((visible) => visible) : super.visible;
  }
  processSeriesItemEnabled() {
    var _a;
    const { data, visible } = this;
    this.seriesItemEnabled = (_a = data == null ? void 0 : data.map(() => visible)) != null ? _a : [];
  }
  nodeFactory() {
    return new Sector$1();
  }
  getSeriesDomain(direction) {
    if (direction === "x" /* X */) {
      return this.angleScale.domain;
    } else {
      return this.radiusScale.domain;
    }
  }
  processData(dataController) {
    return __async$1(this, null, function* () {
      var _a, _b, _c, _d, _e;
      if (this.data == null || !this.properties.isValid()) {
        return;
      }
      let { data } = this;
      const { seriesItemEnabled } = this;
      const { angleKey, radiusKey, calloutLabelKey, sectorLabelKey, legendItemKey } = this.properties;
      const animationEnabled = !this.ctx.animationManager.isSkipped();
      const extraKeyProps = [];
      const extraProps = [];
      if (legendItemKey) {
        extraKeyProps.push(keyProperty$1(this, legendItemKey, false, { id: `legendItemKey` }));
      } else if (calloutLabelKey) {
        extraKeyProps.push(keyProperty$1(this, calloutLabelKey, false, { id: `calloutLabelKey` }));
      } else if (sectorLabelKey) {
        extraKeyProps.push(keyProperty$1(this, sectorLabelKey, false, { id: `sectorLabelKey` }));
      }
      if (radiusKey) {
        extraProps.push(
          rangedValueProperty$1(this, radiusKey, {
            id: "radiusValue",
            min: (_a = this.properties.radiusMin) != null ? _a : 0,
            max: this.properties.radiusMax
          }),
          valueProperty$1(this, radiusKey, true, { id: `radiusRaw` }),
          // Raw value pass-through.
          normalisePropertyTo$1(
            this,
            { id: "radiusValue" },
            [0, 1],
            1,
            (_b = this.properties.radiusMin) != null ? _b : 0,
            this.properties.radiusMax
          )
        );
      }
      if (calloutLabelKey) {
        extraProps.push(valueProperty$1(this, calloutLabelKey, false, { id: `calloutLabelValue` }));
      }
      if (sectorLabelKey) {
        extraProps.push(valueProperty$1(this, sectorLabelKey, false, { id: `sectorLabelValue` }));
      }
      if (legendItemKey) {
        extraProps.push(valueProperty$1(this, legendItemKey, false, { id: `legendItemValue` }));
      }
      if (animationEnabled && this.processedData && extraKeyProps.length > 0) {
        extraProps.push(diff$1(this.processedData));
      }
      extraProps.push(animationValidation$1(this));
      data = data.map((d, idx) => seriesItemEnabled[idx] ? d : __spreadProps$1(__spreadValues$1({}, d), { [angleKey]: 0 }));
      yield this.requestDataModel(dataController, data, {
        props: [
          ...extraKeyProps,
          accumulativeValueProperty$1(this, angleKey, true, { id: `angleValue`, onlyPositive: true }),
          valueProperty$1(this, angleKey, true, { id: `angleRaw` }),
          // Raw value pass-through.
          normalisePropertyTo$1(this, { id: "angleValue" }, [0, 1], 0, 0),
          ...extraProps
        ]
      });
      for (const valueDef of (_e = (_d = (_c = this.processedData) == null ? void 0 : _c.defs) == null ? void 0 : _d.values) != null ? _e : []) {
        const { id, missing, property } = valueDef;
        if (id !== "angleRaw" && missing !== void 0 && missing > 0) {
          Logger$1.warnOnce(
            `no value was found for the key '${String(property)}' on ${missing} data element${missing > 1 ? "s" : ""}`
          );
        }
      }
      this.animationState.transition("updateData");
    });
  }
  maybeRefreshNodeData() {
    return __async$1(this, null, function* () {
      if (!this.nodeDataRefresh)
        return;
      const [{ nodeData = [] } = {}] = yield this.createNodeData();
      this.nodeData = nodeData;
      this.nodeDataRefresh = false;
    });
  }
  getProcessedDataIndexes(dataModel) {
    const angleIdx = dataModel.resolveProcessedDataIndexById(this, `angleValue`).index;
    const radiusIdx = this.properties.radiusKey ? dataModel.resolveProcessedDataIndexById(this, `radiusValue`).index : -1;
    const calloutLabelIdx = this.properties.calloutLabelKey ? dataModel.resolveProcessedDataIndexById(this, `calloutLabelValue`).index : -1;
    const sectorLabelIdx = this.properties.sectorLabelKey ? dataModel.resolveProcessedDataIndexById(this, `sectorLabelValue`).index : -1;
    const legendItemIdx = this.properties.legendItemKey ? dataModel.resolveProcessedDataIndexById(this, `legendItemValue`).index : -1;
    return { angleIdx, radiusIdx, calloutLabelIdx, sectorLabelIdx, legendItemIdx };
  }
  createNodeData() {
    return __async$1(this, null, function* () {
      const { id: seriesId, processedData, dataModel, angleScale } = this;
      const { rotation } = this.properties;
      if (!processedData || !dataModel || processedData.type !== "ungrouped")
        return [];
      const { angleIdx, radiusIdx, calloutLabelIdx, sectorLabelIdx, legendItemIdx } = this.getProcessedDataIndexes(dataModel);
      let currentStart = 0;
      let sum2 = 0;
      const nodeData = processedData.data.map((group2, index) => {
        var _a;
        const { datum, values } = group2;
        const currentValue = values[angleIdx];
        const startAngle = angleScale.convert(currentStart) + toRadians$2(rotation);
        currentStart = currentValue;
        sum2 += currentValue;
        const endAngle = angleScale.convert(currentStart) + toRadians$2(rotation);
        const span = Math.abs(endAngle - startAngle);
        const midAngle = startAngle + span / 2;
        const angleValue = values[angleIdx + 1];
        const radius = radiusIdx >= 0 ? (_a = values[radiusIdx]) != null ? _a : 1 : 1;
        const radiusValue = radiusIdx >= 0 ? values[radiusIdx + 1] : void 0;
        const legendItemValue = legendItemIdx >= 0 ? values[legendItemIdx] : void 0;
        const labels = this.getLabels(
          datum,
          midAngle,
          span,
          true,
          values[calloutLabelIdx],
          values[sectorLabelIdx],
          legendItemValue
        );
        const sectorFormat = this.getSectorFormat(datum, index, false);
        return __spreadValues$1({
          itemId: index,
          series: this,
          datum,
          index,
          angleValue,
          midAngle,
          midCos: Math.cos(midAngle),
          midSin: Math.sin(midAngle),
          startAngle,
          endAngle,
          sectorFormat,
          radiusValue,
          radius,
          innerRadius: Math.max(this.radiusScale.convert(0), 0),
          outerRadius: Math.max(this.radiusScale.convert(radius), 0),
          legendItemValue
        }, labels);
      });
      this.zerosumOuterRing.visible = sum2 === 0;
      this.zerosumInnerRing.visible = sum2 === 0 && this.properties.innerRadiusRatio !== 1 && this.properties.innerRadiusRatio > 0;
      return [{ itemId: seriesId, nodeData, labelData: nodeData }];
    });
  }
  getLabels(datum, midAngle, span, skipDisabled, calloutLabelValue, sectorLabelValue, legendItemValue) {
    const { calloutLabel, sectorLabel, legendItemKey } = this.properties;
    const calloutLabelKey = !skipDisabled || calloutLabel.enabled ? this.properties.calloutLabelKey : void 0;
    const sectorLabelKey = !skipDisabled || sectorLabel.enabled ? this.properties.sectorLabelKey : void 0;
    if (!calloutLabelKey && !sectorLabelKey && !legendItemKey) {
      return {};
    }
    const labelFormatterParams = {
      datum,
      angleKey: this.properties.angleKey,
      angleName: this.properties.angleName,
      radiusKey: this.properties.radiusKey,
      radiusName: this.properties.radiusName,
      calloutLabelKey: this.properties.calloutLabelKey,
      calloutLabelName: this.properties.calloutLabelName,
      sectorLabelKey: this.properties.sectorLabelKey,
      sectorLabelName: this.properties.sectorLabelName,
      legendItemKey: this.properties.legendItemKey
    };
    const result = {};
    if (calloutLabelKey && span > toRadians$2(calloutLabel.minAngle)) {
      result.calloutLabel = __spreadProps$1(__spreadValues$1({}, this.getTextAlignment(midAngle)), {
        text: this.getLabelText(calloutLabel, __spreadProps$1(__spreadValues$1({}, labelFormatterParams), {
          value: calloutLabelValue
        })),
        hidden: false,
        collisionTextAlign: void 0,
        collisionOffsetY: 0,
        box: void 0
      });
    }
    if (sectorLabelKey) {
      result.sectorLabel = {
        text: this.getLabelText(sectorLabel, __spreadProps$1(__spreadValues$1({}, labelFormatterParams), {
          value: sectorLabelValue
        }))
      };
    }
    if (legendItemKey != null && legendItemValue != null) {
      result.legendItem = { key: legendItemKey, text: legendItemValue };
    }
    return result;
  }
  getTextAlignment(midAngle) {
    const quadrantTextOpts = [
      { textAlign: "center", textBaseline: "bottom" },
      { textAlign: "left", textBaseline: "middle" },
      { textAlign: "center", textBaseline: "hanging" },
      { textAlign: "right", textBaseline: "middle" }
    ];
    const midAngle180 = normalizeAngle180$1(midAngle);
    const quadrantStart = -3 * Math.PI / 4;
    const quadrantOffset = midAngle180 - quadrantStart;
    const quadrant = Math.floor(quadrantOffset / (Math.PI / 2));
    const quadrantIndex = mod$1(quadrant, quadrantTextOpts.length);
    return quadrantTextOpts[quadrantIndex];
  }
  getSectorFormat(datum, formatIndex, highlight) {
    var _a, _b, _c, _d, _e;
    const { callbackCache, highlightManager } = this.ctx;
    const { angleKey, radiusKey, fills, strokes, formatter, sectorSpacing, __BACKGROUND_COLOR_DO_NOT_USE } = this.properties;
    const highlightedDatum = highlightManager.getActiveHighlight();
    const isDatumHighlighted = highlight && (highlightedDatum == null ? void 0 : highlightedDatum.series) === this && formatIndex === highlightedDatum.itemId;
    const { fill, fillOpacity, stroke, strokeWidth, strokeOpacity } = mergeDefaults$1(
      isDatumHighlighted && this.properties.highlightStyle.item,
      {
        fill: fills.length > 0 ? fills[formatIndex % fills.length] : void 0,
        fillOpacity: this.properties.fillOpacity,
        // @todo(AG-10275) Remove sectorSpacing null case
        stroke: sectorSpacing != null ? strokes.length > 0 ? strokes[formatIndex % strokes.length] : void 0 : strokes.length > 0 ? strokes[formatIndex % strokes.length] : __BACKGROUND_COLOR_DO_NOT_USE,
        strokeWidth: this.getStrokeWidth(this.properties.strokeWidth),
        strokeOpacity: this.getOpacity()
      }
    );
    let format2;
    if (formatter) {
      format2 = callbackCache.call(formatter, {
        datum,
        angleKey,
        radiusKey,
        fill,
        stroke,
        fills,
        strokes,
        strokeWidth,
        highlighted: isDatumHighlighted,
        seriesId: this.id
      });
    }
    return {
      fill: (_a = format2 == null ? void 0 : format2.fill) != null ? _a : fill,
      fillOpacity: (_b = format2 == null ? void 0 : format2.fillOpacity) != null ? _b : fillOpacity,
      stroke: (_c = format2 == null ? void 0 : format2.stroke) != null ? _c : stroke,
      strokeWidth: (_d = format2 == null ? void 0 : format2.strokeWidth) != null ? _d : strokeWidth,
      strokeOpacity: (_e = format2 == null ? void 0 : format2.strokeOpacity) != null ? _e : strokeOpacity
    };
  }
  getInnerRadius() {
    const { radius } = this;
    const { innerRadiusRatio, innerRadiusOffset } = this.properties;
    const innerRadius = radius * innerRadiusRatio + innerRadiusOffset;
    if (innerRadius === radius || innerRadius < 0) {
      return 0;
    }
    return innerRadius;
  }
  getOuterRadius() {
    return Math.max(this.radius * this.properties.outerRadiusRatio + this.properties.outerRadiusOffset, 0);
  }
  updateRadiusScale(resize) {
    const newRange = [this.getInnerRadius(), this.getOuterRadius()];
    this.radiusScale.range = newRange;
    if (resize) {
      this.previousRadiusScale.range = newRange;
    }
    this.nodeData = this.nodeData.map((_a) => {
      var _b = _a, { radius } = _b, d = __objRest$1(_b, ["radius"]);
      return __spreadProps$1(__spreadValues$1({}, d), {
        radius,
        innerRadius: Math.max(this.radiusScale.convert(0), 0),
        outerRadius: Math.max(this.radiusScale.convert(radius), 0)
      });
    });
  }
  getTitleTranslationY() {
    var _a, _b;
    const outerRadius = Math.max(0, this.radiusScale.range[1]);
    if (outerRadius === 0) {
      return NaN;
    }
    const spacing = (_b = (_a = this.properties.title) == null ? void 0 : _a.spacing) != null ? _b : 0;
    const titleOffset = 2 + spacing;
    const dy = Math.max(0, -outerRadius);
    return -outerRadius - titleOffset - dy;
  }
  update(_0) {
    return __async$1(this, arguments, function* ({ seriesRect }) {
      const { title } = this.properties;
      const newNodeDataDependencies = {
        seriesRectWidth: seriesRect == null ? void 0 : seriesRect.width,
        seriesRectHeight: seriesRect == null ? void 0 : seriesRect.height
      };
      const resize = jsonDiff$1(this.nodeDataDependencies, newNodeDataDependencies) != null;
      if (resize) {
        this._nodeDataDependencies = newNodeDataDependencies;
      }
      yield this.maybeRefreshNodeData();
      this.updateTitleNodes();
      this.updateRadiusScale(resize);
      this.contentGroup.translationX = this.centerX;
      this.contentGroup.translationY = this.centerY;
      this.highlightGroup.translationX = this.centerX;
      this.highlightGroup.translationY = this.centerY;
      this.backgroundGroup.translationX = this.centerX;
      this.backgroundGroup.translationY = this.centerY;
      if (this.labelGroup) {
        this.labelGroup.translationX = this.centerX;
        this.labelGroup.translationY = this.centerY;
      }
      if (title) {
        const dy = this.getTitleTranslationY();
        const titleBox = title.node.computeBBox();
        title.node.visible = title.enabled && isFinite(dy) && !this.bboxIntersectsSurroundingSeries(titleBox, 0, dy);
        title.node.translationY = isFinite(dy) ? dy : 0;
      }
      this.updateNodeMidPoint();
      yield this.updateSelections();
      yield this.updateNodes(seriesRect);
    });
  }
  updateTitleNodes() {
    var _a, _b;
    const { oldTitle } = this;
    const { title } = this.properties;
    if (oldTitle !== title) {
      if (oldTitle) {
        (_a = this.labelGroup) == null ? void 0 : _a.removeChild(oldTitle.node);
      }
      if (title) {
        title.node.textBaseline = "bottom";
        (_b = this.labelGroup) == null ? void 0 : _b.appendChild(title.node);
      }
      this.oldTitle = title;
    }
  }
  updateNodeMidPoint() {
    this.nodeData.forEach((d) => {
      const radius = d.innerRadius + (d.outerRadius - d.innerRadius) / 2;
      d.midPoint = {
        x: d.midCos * Math.max(0, radius),
        y: d.midSin * Math.max(0, radius)
      };
    });
  }
  updateSelections() {
    return __async$1(this, null, function* () {
      yield this.updateGroupSelection();
      this.updateInnerCircleSelection();
    });
  }
  updateGroupSelection() {
    return __async$1(this, null, function* () {
      const { itemSelection, highlightSelection, calloutLabelSelection, sectorLabelSelection, innerLabelsSelection } = this;
      const update = (selection, clone) => {
        let nodeData = this.nodeData;
        if (clone) {
          nodeData = nodeData.map((datum) => __spreadProps$1(__spreadValues$1({}, datum), { sectorFormat: __spreadValues$1({}, datum.sectorFormat) }));
        }
        selection.update(nodeData);
        if (this.ctx.animationManager.isSkipped()) {
          selection.cleanup();
        }
      };
      update(itemSelection, false);
      update(highlightSelection, true);
      calloutLabelSelection.update(this.nodeData, (group2) => {
        const line = new Line$1();
        line.tag = 1 /* Callout */;
        line.pointerEvents = 1 /* None */;
        group2.appendChild(line);
        const text = new Text$1();
        text.tag = 2 /* Label */;
        text.pointerEvents = 1 /* None */;
        group2.appendChild(text);
      });
      sectorLabelSelection.update(this.nodeData, (node) => {
        node.pointerEvents = 1 /* None */;
      });
      innerLabelsSelection.update(this.properties.innerLabels, (node) => {
        node.pointerEvents = 1 /* None */;
      });
    });
  }
  updateInnerCircleSelection() {
    const { innerCircle } = this.properties;
    let radius = 0;
    const innerRadius = this.getInnerRadius();
    if (innerRadius > 0) {
      const circleRadius = Math.min(innerRadius, this.getOuterRadius());
      const antiAliasingPadding = 1;
      radius = Math.ceil(circleRadius * 2 + antiAliasingPadding);
    }
    const datums = innerCircle ? [{ radius }] : [];
    this.innerCircleSelection.update(datums);
  }
  updateNodes(seriesRect) {
    return __async$1(this, null, function* () {
      const highlightedDatum = this.ctx.highlightManager.getActiveHighlight();
      const isVisible = this.seriesItemEnabled.indexOf(true) >= 0;
      this.rootGroup.visible = isVisible;
      this.backgroundGroup.visible = isVisible;
      this.contentGroup.visible = isVisible;
      this.highlightGroup.visible = isVisible && (highlightedDatum == null ? void 0 : highlightedDatum.series) === this;
      if (this.labelGroup) {
        this.labelGroup.visible = isVisible;
      }
      this.contentGroup.opacity = this.getOpacity();
      this.innerCircleSelection.each((node, { radius }) => {
        var _a, _b;
        node.setProperties({
          fill: (_a = this.properties.innerCircle) == null ? void 0 : _a.fill,
          opacity: (_b = this.properties.innerCircle) == null ? void 0 : _b.fillOpacity,
          size: radius
        });
      });
      const updateSectorFn = (sector, datum, _index, isDatumHighlighted) => {
        const format2 = this.getSectorFormat(datum.datum, datum.itemId, isDatumHighlighted);
        datum.sectorFormat.fill = format2.fill;
        datum.sectorFormat.stroke = format2.stroke;
        const animationDisabled = this.ctx.animationManager.isSkipped();
        if (animationDisabled) {
          sector.startAngle = datum.startAngle;
          sector.endAngle = datum.endAngle;
          sector.innerRadius = datum.innerRadius;
          sector.outerRadius = datum.outerRadius;
        }
        if (isDatumHighlighted || animationDisabled) {
          sector.fill = format2.fill;
          sector.stroke = format2.stroke;
        }
        sector.strokeWidth = format2.strokeWidth;
        sector.fillOpacity = format2.fillOpacity;
        sector.strokeOpacity = this.properties.strokeOpacity;
        sector.lineDash = this.properties.lineDash;
        sector.lineDashOffset = this.properties.lineDashOffset;
        sector.fillShadow = this.properties.shadow;
        sector.inset = this.properties.sectorSpacing != null ? (this.properties.sectorSpacing + (format2.stroke != null ? format2.strokeWidth : 0)) / 2 : 0;
        sector.lineJoin = this.properties.sectorSpacing != null ? "miter" : "round";
      };
      this.itemSelection.each((node, datum, index) => updateSectorFn(node, datum, index, false));
      this.highlightSelection.each((node, datum, index) => {
        const isDatumHighlighted = (highlightedDatum == null ? void 0 : highlightedDatum.series) === this && node.datum.itemId === highlightedDatum.itemId;
        updateSectorFn(node, datum, index, isDatumHighlighted);
        node.visible = isDatumHighlighted;
      });
      this.updateCalloutLineNodes();
      this.updateCalloutLabelNodes(seriesRect);
      this.updateSectorLabelNodes();
      this.updateInnerLabelNodes();
      this.updateZerosumRings();
      this.animationState.transition("update");
    });
  }
  updateCalloutLineNodes() {
    var _a;
    const { calloutLine } = this.properties;
    const calloutLength = calloutLine.length;
    const calloutStrokeWidth = calloutLine.strokeWidth;
    const calloutColors = (_a = calloutLine.colors) != null ? _a : this.properties.strokes;
    const { offset: offset4 } = this.properties.calloutLabel;
    this.calloutLabelSelection.selectByTag(1 /* Callout */).forEach((line, index) => {
      const datum = line.datum;
      const { calloutLabel: label, outerRadius } = datum;
      if ((label == null ? void 0 : label.text) && !label.hidden && outerRadius !== 0) {
        line.visible = true;
        line.strokeWidth = calloutStrokeWidth;
        line.stroke = calloutColors[index % calloutColors.length];
        line.fill = void 0;
        const x1 = datum.midCos * outerRadius;
        const y1 = datum.midSin * outerRadius;
        let x2 = datum.midCos * (outerRadius + calloutLength);
        let y2 = datum.midSin * (outerRadius + calloutLength);
        const isMoved = label.collisionTextAlign || label.collisionOffsetY !== 0;
        if (isMoved && label.box != null) {
          const box = label.box;
          let cx = x2;
          let cy = y2;
          if (x2 < box.x) {
            cx = box.x;
          } else if (x2 > box.x + box.width) {
            cx = box.x + box.width;
          }
          if (y2 < box.y) {
            cy = box.y;
          } else if (y2 > box.y + box.height) {
            cy = box.y + box.height;
          }
          const dx = cx - x2;
          const dy = cy - y2;
          const length = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
          const paddedLength = length - offset4;
          if (paddedLength > 0) {
            x2 = x2 + dx * paddedLength / length;
            y2 = y2 + dy * paddedLength / length;
          }
        }
        line.x1 = x1;
        line.y1 = y1;
        line.x2 = x2;
        line.y2 = y2;
      } else {
        line.visible = false;
      }
    });
  }
  getLabelOverflow(text, box, seriesRect) {
    const seriesLeft = seriesRect.x - this.centerX;
    const seriesRight = seriesRect.x + seriesRect.width - this.centerX;
    const seriesTop = seriesRect.y - this.centerY;
    const seriesBottom = seriesRect.y + seriesRect.height - this.centerY;
    const errPx = 1;
    let visibleTextPart = 1;
    if (box.x + errPx < seriesLeft) {
      visibleTextPart = (box.x + box.width - seriesLeft) / box.width;
    } else if (box.x + box.width - errPx > seriesRight) {
      visibleTextPart = (seriesRight - box.x) / box.width;
    }
    const hasVerticalOverflow = box.y + errPx < seriesTop || box.y + box.height - errPx > seriesBottom;
    const textLength = visibleTextPart === 1 ? text.length : Math.floor(text.length * visibleTextPart) - 1;
    const hasSurroundingSeriesOverflow = this.bboxIntersectsSurroundingSeries(box);
    return { textLength, hasVerticalOverflow, hasSurroundingSeriesOverflow };
  }
  bboxIntersectsSurroundingSeries(box, dx = 0, dy = 0) {
    const { surroundingRadius } = this;
    if (surroundingRadius == null) {
      return false;
    }
    const corners = [
      { x: box.x + dx, y: box.y + dy },
      { x: box.x + box.width + dx, y: box.y + dy },
      { x: box.x + box.width + dx, y: box.y + box.height + dy },
      { x: box.x + dx, y: box.y + box.height + dy }
    ];
    const sur2 = __pow$1(surroundingRadius, 2);
    return corners.some((corner) => __pow$1(corner.x, 2) + __pow$1(corner.y, 2) > sur2);
  }
  computeCalloutLabelCollisionOffsets() {
    const { radiusScale } = this;
    const { calloutLabel, calloutLine } = this.properties;
    const { offset: offset4, minSpacing } = calloutLabel;
    const innerRadius = radiusScale.convert(0);
    const shouldSkip = (datum) => {
      const label = datum.calloutLabel;
      return !label || datum.outerRadius === 0;
    };
    const fullData = this.nodeData;
    const data = this.nodeData.filter((t) => !shouldSkip(t));
    data.forEach((datum) => {
      const label = datum.calloutLabel;
      if (label == null)
        return;
      label.hidden = false;
      label.collisionTextAlign = void 0;
      label.collisionOffsetY = 0;
    });
    if (data.length <= 1) {
      return;
    }
    const leftLabels = data.filter((d) => d.midCos < 0).sort((a, b) => a.midSin - b.midSin);
    const rightLabels = data.filter((d) => d.midCos >= 0).sort((a, b) => a.midSin - b.midSin);
    const topLabels = data.filter((d) => {
      var _a;
      return d.midSin < 0 && ((_a = d.calloutLabel) == null ? void 0 : _a.textAlign) === "center";
    }).sort((a, b) => a.midCos - b.midCos);
    const bottomLabels = data.filter((d) => {
      var _a;
      return d.midSin >= 0 && ((_a = d.calloutLabel) == null ? void 0 : _a.textAlign) === "center";
    }).sort((a, b) => a.midCos - b.midCos);
    const tempTextNode = new Text$1();
    const getTextBBox = (datum) => {
      var _a;
      const label = datum.calloutLabel;
      if (label == null)
        return new BBox$1(0, 0, 0, 0);
      const labelRadius = datum.outerRadius + calloutLine.length + offset4;
      const x = datum.midCos * labelRadius;
      const y = datum.midSin * labelRadius + label.collisionOffsetY;
      tempTextNode.text = label.text;
      tempTextNode.x = x;
      tempTextNode.y = y;
      tempTextNode.setFont(this.properties.calloutLabel);
      tempTextNode.setAlign({
        textAlign: (_a = label.collisionTextAlign) != null ? _a : label.textAlign,
        textBaseline: label.textBaseline
      });
      return tempTextNode.computeBBox();
    };
    const avoidNeighbourYCollision = (label, next, direction) => {
      const box = getTextBBox(label).grow(minSpacing / 2);
      const other = getTextBBox(next).grow(minSpacing / 2);
      const collidesOrBehind = box.x < other.x + other.width && box.x + box.width > other.x && (direction === "to-top" ? box.y < other.y + other.height : box.y + box.height > other.y);
      if (collidesOrBehind) {
        const dy = direction === "to-top" ? box.y - other.y - other.height : box.y + box.height - other.y;
        next.calloutLabel.collisionOffsetY = dy;
      }
    };
    const avoidYCollisions = (labels) => {
      const midLabel = labels.slice().sort((a, b) => Math.abs(a.midSin) - Math.abs(b.midSin))[0];
      const midIndex = labels.indexOf(midLabel);
      for (let i = midIndex - 1; i >= 0; i--) {
        const prev = labels[i + 1];
        const next = labels[i];
        avoidNeighbourYCollision(prev, next, "to-top");
      }
      for (let i = midIndex + 1; i < labels.length; i++) {
        const prev = labels[i - 1];
        const next = labels[i];
        avoidNeighbourYCollision(prev, next, "to-bottom");
      }
    };
    const avoidXCollisions = (labels) => {
      const labelsCollideLabelsByY = data.some((datum) => datum.calloutLabel.collisionOffsetY !== 0);
      const boxes = labels.map((label) => getTextBBox(label));
      const paddedBoxes = boxes.map((box) => box.clone().grow(minSpacing / 2));
      let labelsCollideLabelsByX = false;
      for (let i = 0; i < paddedBoxes.length && !labelsCollideLabelsByX; i++) {
        const box = paddedBoxes[i];
        for (let j = i + 1; j < labels.length; j++) {
          const other = paddedBoxes[j];
          if (box.collidesBBox(other)) {
            labelsCollideLabelsByX = true;
            break;
          }
        }
      }
      const sectors = fullData.map((datum) => {
        const { startAngle, endAngle, outerRadius } = datum;
        return { startAngle, endAngle, innerRadius, outerRadius };
      });
      const labelsCollideSectors = boxes.some((box) => {
        return sectors.some((sector) => boxCollidesSector$1(box, sector));
      });
      if (!labelsCollideLabelsByX && !labelsCollideLabelsByY && !labelsCollideSectors) {
        return;
      }
      labels.filter((d) => d.calloutLabel.textAlign === "center").forEach((d) => {
        const label = d.calloutLabel;
        if (d.midCos < 0) {
          label.collisionTextAlign = "right";
        } else if (d.midCos > 0) {
          label.collisionTextAlign = "left";
        } else {
          label.collisionTextAlign = "center";
        }
      });
    };
    avoidYCollisions(leftLabels);
    avoidYCollisions(rightLabels);
    avoidXCollisions(topLabels);
    avoidXCollisions(bottomLabels);
  }
  updateCalloutLabelNodes(seriesRect) {
    const { radiusScale } = this;
    const { calloutLabel, calloutLine } = this.properties;
    const calloutLength = calloutLine.length;
    const { offset: offset4, color } = calloutLabel;
    const tempTextNode = new Text$1();
    this.calloutLabelSelection.selectByTag(2 /* Label */).forEach((text) => {
      var _a;
      const { datum } = text;
      const label = datum.calloutLabel;
      const radius = radiusScale.convert(datum.radius);
      const outerRadius = Math.max(0, radius);
      if (!(label == null ? void 0 : label.text) || outerRadius === 0 || label.hidden) {
        text.visible = false;
        return;
      }
      const labelRadius = outerRadius + calloutLength + offset4;
      const x = datum.midCos * labelRadius;
      const y = datum.midSin * labelRadius + label.collisionOffsetY;
      const align = {
        textAlign: (_a = label.collisionTextAlign) != null ? _a : label.textAlign,
        textBaseline: label.textBaseline
      };
      tempTextNode.text = label.text;
      tempTextNode.x = x;
      tempTextNode.y = y;
      tempTextNode.setFont(this.properties.calloutLabel);
      tempTextNode.setAlign(align);
      const box = tempTextNode.computeBBox();
      let displayText = label.text;
      let visible = true;
      if (calloutLabel.avoidCollisions) {
        const { textLength, hasVerticalOverflow } = this.getLabelOverflow(label.text, box, seriesRect);
        displayText = label.text.length === textLength ? label.text : `${label.text.substring(0, textLength)}\u2026`;
        visible = !hasVerticalOverflow;
      }
      text.text = displayText;
      text.x = x;
      text.y = y;
      text.setFont(this.properties.calloutLabel);
      text.setAlign(align);
      text.fill = color;
      text.visible = visible;
    });
  }
  computeLabelsBBox(options, seriesRect) {
    return __async$1(this, null, function* () {
      const { calloutLabel, calloutLine } = this.properties;
      const calloutLength = calloutLine.length;
      const { offset: offset4, maxCollisionOffset, minSpacing } = calloutLabel;
      if (!calloutLabel.avoidCollisions) {
        return null;
      }
      yield this.maybeRefreshNodeData();
      this.updateRadiusScale(false);
      this.computeCalloutLabelCollisionOffsets();
      const textBoxes = [];
      const text = new Text$1();
      let titleBox;
      const { title } = this.properties;
      if ((title == null ? void 0 : title.text) && title.enabled) {
        const dy = this.getTitleTranslationY();
        if (isFinite(dy)) {
          text.text = title.text;
          text.x = 0;
          text.y = dy;
          text.setFont(title);
          text.setAlign({
            textBaseline: "bottom",
            textAlign: "center"
          });
          titleBox = text.computeBBox();
          textBoxes.push(titleBox);
        }
      }
      this.nodeData.forEach((datum) => {
        var _a;
        const label = datum.calloutLabel;
        if (!label || datum.outerRadius === 0) {
          return null;
        }
        const labelRadius = datum.outerRadius + calloutLength + offset4;
        const x = datum.midCos * labelRadius;
        const y = datum.midSin * labelRadius + label.collisionOffsetY;
        text.text = label.text;
        text.x = x;
        text.y = y;
        text.setFont(this.properties.calloutLabel);
        text.setAlign({
          textAlign: (_a = label.collisionTextAlign) != null ? _a : label.textAlign,
          textBaseline: label.textBaseline
        });
        const box = text.computeBBox();
        label.box = box;
        if (Math.abs(label.collisionOffsetY) > maxCollisionOffset) {
          label.hidden = true;
          return;
        }
        if (titleBox) {
          const seriesTop = seriesRect.y - this.centerY;
          const titleCleanArea = new BBox$1(
            titleBox.x - minSpacing,
            seriesTop,
            titleBox.width + 2 * minSpacing,
            titleBox.y + titleBox.height + minSpacing - seriesTop
          );
          if (box.collidesBBox(titleCleanArea)) {
            label.hidden = true;
            return;
          }
        }
        if (options.hideWhenNecessary) {
          const { textLength, hasVerticalOverflow, hasSurroundingSeriesOverflow } = this.getLabelOverflow(
            label.text,
            box,
            seriesRect
          );
          const isTooShort = label.text.length > 2 && textLength < 2;
          if (hasVerticalOverflow || isTooShort || hasSurroundingSeriesOverflow) {
            label.hidden = true;
            return;
          }
        }
        label.hidden = false;
        textBoxes.push(box);
      });
      if (textBoxes.length === 0) {
        return null;
      }
      return BBox$1.merge(textBoxes);
    });
  }
  updateSectorLabelNodes() {
    const { radiusScale } = this;
    const innerRadius = radiusScale.convert(0);
    const { fontSize, fontStyle, fontWeight, fontFamily, positionOffset, positionRatio, color } = this.properties.sectorLabel;
    const isDoughnut = innerRadius > 0;
    const singleVisibleSector = this.seriesItemEnabled.filter(Boolean).length === 1;
    this.sectorLabelSelection.each((text, datum) => {
      const { sectorLabel, outerRadius } = datum;
      let isTextVisible = false;
      if (sectorLabel && outerRadius !== 0) {
        const labelRadius = innerRadius * (1 - positionRatio) + outerRadius * positionRatio + positionOffset;
        text.fill = color;
        text.fontStyle = fontStyle;
        text.fontWeight = fontWeight;
        text.fontSize = fontSize;
        text.fontFamily = fontFamily;
        text.text = sectorLabel.text;
        const shouldPutTextInCenter = !isDoughnut && singleVisibleSector;
        if (shouldPutTextInCenter) {
          text.x = 0;
          text.y = 0;
        } else {
          text.x = datum.midCos * labelRadius;
          text.y = datum.midSin * labelRadius;
        }
        text.textAlign = "center";
        text.textBaseline = "middle";
        const bbox = text.computeBBox();
        const corners = [
          [bbox.x, bbox.y],
          [bbox.x + bbox.width, bbox.y],
          [bbox.x + bbox.width, bbox.y + bbox.height],
          [bbox.x, bbox.y + bbox.height]
        ];
        const { startAngle, endAngle } = datum;
        const sectorBounds = { startAngle, endAngle, innerRadius, outerRadius };
        if (corners.every(([x, y]) => isPointInSector$1(x, y, sectorBounds))) {
          isTextVisible = true;
        }
      }
      text.visible = isTextVisible;
    });
  }
  updateInnerLabelNodes() {
    const textBBoxes = [];
    const margins = [];
    this.innerLabelsSelection.each((text, datum) => {
      const { fontStyle, fontWeight, fontSize, fontFamily, color } = datum;
      text.fontStyle = fontStyle;
      text.fontWeight = fontWeight;
      text.fontSize = fontSize;
      text.fontFamily = fontFamily;
      text.text = datum.text;
      text.x = 0;
      text.y = 0;
      text.fill = color;
      text.textAlign = "center";
      text.textBaseline = "alphabetic";
      textBBoxes.push(text.computeBBox());
      margins.push(datum.margin);
    });
    const getMarginTop = (index) => index === 0 ? 0 : margins[index];
    const getMarginBottom = (index) => index === margins.length - 1 ? 0 : margins[index];
    const totalHeight = textBBoxes.reduce((sum2, bbox, i) => {
      return sum2 + bbox.height + getMarginTop(i) + getMarginBottom(i);
    }, 0);
    const totalWidth = Math.max(...textBBoxes.map((bbox) => bbox.width));
    const innerRadius = this.getInnerRadius();
    const labelRadius = Math.sqrt(Math.pow(totalWidth / 2, 2) + Math.pow(totalHeight / 2, 2));
    const labelsVisible = labelRadius <= (innerRadius > 0 ? innerRadius : this.getOuterRadius());
    const textBottoms = [];
    for (let i = 0, prev = -totalHeight / 2; i < textBBoxes.length; i++) {
      const bbox = textBBoxes[i];
      const bottom = bbox.height + prev + getMarginTop(i);
      textBottoms.push(bottom);
      prev = bottom + getMarginBottom(i);
    }
    this.innerLabelsSelection.each((text, _datum, index) => {
      text.y = textBottoms[index];
      text.visible = labelsVisible;
    });
  }
  updateZerosumRings() {
    this.zerosumOuterRing.size = this.getOuterRadius() * 2;
    this.zerosumInnerRing.size = this.getInnerRadius() * 2;
  }
  getDatumLegendName(nodeDatum) {
    const { angleKey, calloutLabelKey, sectorLabelKey, legendItemKey } = this.properties;
    const { sectorLabel, calloutLabel, legendItem } = nodeDatum;
    if (legendItemKey && legendItem !== void 0) {
      return legendItem.text;
    } else if (calloutLabelKey && calloutLabelKey !== angleKey && (calloutLabel == null ? void 0 : calloutLabel.text) !== void 0) {
      return calloutLabel.text;
    } else if (sectorLabelKey && sectorLabelKey !== angleKey && (sectorLabel == null ? void 0 : sectorLabel.text) !== void 0) {
      return sectorLabel.text;
    }
  }
  getTooltipHtml(nodeDatum) {
    var _a;
    if (!this.properties.isValid()) {
      return "";
    }
    const {
      datum,
      angleValue,
      sectorFormat: { fill: color }
    } = nodeDatum;
    const title = sanitizeHtml$1((_a = this.properties.title) == null ? void 0 : _a.text);
    const content = isNumber2$1(angleValue) ? toFixed$1(angleValue) : String(angleValue);
    const labelText = this.getDatumLegendName(nodeDatum);
    return this.properties.tooltip.toTooltipHtml(
      {
        title: title != null ? title : labelText,
        content: title && labelText ? `${labelText}: ${content}` : content,
        backgroundColor: color
      },
      {
        datum,
        title,
        color,
        seriesId: this.id,
        angleKey: this.properties.angleKey,
        angleName: this.properties.angleName,
        radiusKey: this.properties.radiusKey,
        radiusName: this.properties.radiusName,
        calloutLabelKey: this.properties.calloutLabelKey,
        calloutLabelName: this.properties.calloutLabelName,
        sectorLabelKey: this.properties.sectorLabelKey,
        sectorLabelName: this.properties.sectorLabelName
      }
    );
  }
  getLegendData(legendType) {
    var _a, _b, _c, _d, _e;
    const { processedData, dataModel } = this;
    if (!dataModel || !(processedData == null ? void 0 : processedData.data.length) || legendType !== "category") {
      return [];
    }
    const { angleKey, calloutLabelKey, sectorLabelKey, legendItemKey } = this.properties;
    if (!legendItemKey && (!calloutLabelKey || calloutLabelKey === angleKey) && (!sectorLabelKey || sectorLabelKey === angleKey))
      return [];
    const { calloutLabelIdx, sectorLabelIdx, legendItemIdx } = this.getProcessedDataIndexes(dataModel);
    const titleText = ((_a = this.properties.title) == null ? void 0 : _a.showInLegend) && this.properties.title.text;
    const legendData = [];
    for (let index = 0; index < processedData.data.length; index++) {
      const { datum, values } = processedData.data[index];
      const labelParts = [];
      if (titleText) {
        labelParts.push(titleText);
      }
      const labels = this.getLabels(
        datum,
        2 * Math.PI,
        2 * Math.PI,
        false,
        values[calloutLabelIdx],
        values[sectorLabelIdx],
        values[legendItemIdx]
      );
      if (legendItemKey && labels.legendItem !== void 0) {
        labelParts.push(labels.legendItem.text);
      } else if (calloutLabelKey && calloutLabelKey !== angleKey && ((_b = labels.calloutLabel) == null ? void 0 : _b.text) !== void 0) {
        labelParts.push((_c = labels.calloutLabel) == null ? void 0 : _c.text);
      } else if (sectorLabelKey && sectorLabelKey !== angleKey && ((_d = labels.sectorLabel) == null ? void 0 : _d.text) !== void 0) {
        labelParts.push((_e = labels.sectorLabel) == null ? void 0 : _e.text);
      }
      if (labelParts.length === 0)
        continue;
      const sectorFormat = this.getSectorFormat(datum, index, false);
      legendData.push({
        legendType: "category",
        id: this.id,
        itemId: index,
        seriesId: this.id,
        enabled: this.seriesItemEnabled[index],
        label: {
          text: labelParts.join(" - ")
        },
        marker: {
          fill: sectorFormat.fill,
          stroke: sectorFormat.stroke,
          fillOpacity: this.properties.fillOpacity,
          strokeOpacity: this.properties.strokeOpacity,
          strokeWidth: this.properties.strokeWidth
        }
      });
    }
    return legendData;
  }
  onLegendItemClick(event) {
    const { enabled, itemId, series } = event;
    if (series.id === this.id) {
      this.toggleSeriesItem(itemId, enabled);
    } else if (series.type === "pie") {
      this.toggleOtherSeriesItems(series, itemId, enabled);
    }
  }
  toggleSeriesItem(itemId, enabled) {
    this.seriesItemEnabled[itemId] = enabled;
    this.nodeDataRefresh = true;
  }
  toggleOtherSeriesItems(series, itemId, enabled) {
    var _a, _b;
    if (!this.properties.legendItemKey || !this.dataModel) {
      return;
    }
    const datumToggledLegendItemValue = series.properties.legendItemKey && ((_a = series.data) == null ? void 0 : _a.find((_, index) => index === itemId)[series.properties.legendItemKey]);
    if (!datumToggledLegendItemValue) {
      return;
    }
    const legendItemIdx = this.dataModel.resolveProcessedDataIndexById(this, `legendItemValue`).index;
    (_b = this.processedData) == null ? void 0 : _b.data.forEach(({ values }, datumItemId) => {
      if (values[legendItemIdx] === datumToggledLegendItemValue) {
        this.toggleSeriesItem(datumItemId, enabled);
      }
    });
  }
  animateEmptyUpdateReady(_data) {
    const { animationManager } = this.ctx;
    const fns = preparePieSeriesAnimationFunctions$1(
      true,
      this.properties.rotation,
      this.radiusScale,
      this.previousRadiusScale
    );
    fromToMotion$1(this.id, "nodes", animationManager, [this.itemSelection, this.highlightSelection], fns.nodes);
    fromToMotion$1(this.id, `innerCircle`, animationManager, [this.innerCircleSelection], fns.innerCircle);
    seriesLabelFadeInAnimation$1(this, "callout", animationManager, [this.calloutLabelSelection]);
    seriesLabelFadeInAnimation$1(this, "sector", animationManager, [this.sectorLabelSelection]);
    seriesLabelFadeInAnimation$1(this, "inner", animationManager, [this.innerLabelsSelection]);
    this.previousRadiusScale.range = this.radiusScale.range;
  }
  animateWaitingUpdateReady() {
    var _a, _b, _c, _d, _e, _f;
    const { itemSelection, highlightSelection, processedData, radiusScale, previousRadiusScale } = this;
    const { animationManager } = this.ctx;
    const diff2 = (_a = processedData == null ? void 0 : processedData.reduced) == null ? void 0 : _a.diff;
    this.ctx.animationManager.stopByAnimationGroupId(this.id);
    const supportedDiff = ((_b = diff2 == null ? void 0 : diff2.moved.length) != null ? _b : 0) === 0 && (diff2 == null ? void 0 : diff2.addedIndices.every((i) => !diff2.removedIndices.includes(i)));
    const hasKeys = ((_c = processedData == null ? void 0 : processedData.defs.keys.length) != null ? _c : 0) > 0;
    const hasUniqueKeys = (_f = (_e = (_d = processedData == null ? void 0 : processedData.reduced) == null ? void 0 : _d.animationValidation) == null ? void 0 : _e.uniqueKeys) != null ? _f : true;
    if (!supportedDiff || !hasKeys || !hasUniqueKeys) {
      this.ctx.animationManager.skipCurrentBatch();
    }
    const fns = preparePieSeriesAnimationFunctions$1(
      false,
      this.properties.rotation,
      radiusScale,
      previousRadiusScale
    );
    fromToMotion$1(
      this.id,
      "nodes",
      animationManager,
      [itemSelection, highlightSelection],
      fns.nodes,
      (_, datum) => this.getDatumId(datum),
      diff2
    );
    fromToMotion$1(this.id, `innerCircle`, animationManager, [this.innerCircleSelection], fns.innerCircle);
    seriesLabelFadeInAnimation$1(this, "callout", this.ctx.animationManager, [this.calloutLabelSelection]);
    seriesLabelFadeInAnimation$1(this, "sector", this.ctx.animationManager, [this.sectorLabelSelection]);
    seriesLabelFadeInAnimation$1(this, "inner", this.ctx.animationManager, [this.innerLabelsSelection]);
    this.previousRadiusScale.range = this.radiusScale.range;
  }
  animateClearingUpdateEmpty() {
    const { itemSelection, highlightSelection, radiusScale, previousRadiusScale } = this;
    const { animationManager } = this.ctx;
    const fns = preparePieSeriesAnimationFunctions$1(
      false,
      this.properties.rotation,
      radiusScale,
      previousRadiusScale
    );
    fromToMotion$1(this.id, "nodes", animationManager, [itemSelection, highlightSelection], fns.nodes);
    fromToMotion$1(this.id, `innerCircle`, animationManager, [this.innerCircleSelection], fns.innerCircle);
    seriesLabelFadeOutAnimation$1(this, "callout", this.ctx.animationManager, [this.calloutLabelSelection]);
    seriesLabelFadeOutAnimation$1(this, "sector", this.ctx.animationManager, [this.sectorLabelSelection]);
    seriesLabelFadeOutAnimation$1(this, "inner", this.ctx.animationManager, [this.innerLabelsSelection]);
    this.previousRadiusScale.range = this.radiusScale.range;
  }
  getDatumIdFromData(datum) {
    var _a, _b, _c;
    const { calloutLabelKey, sectorLabelKey, legendItemKey } = this.properties;
    if (!((_c = (_b = (_a = this.processedData) == null ? void 0 : _a.reduced) == null ? void 0 : _b.animationValidation) == null ? void 0 : _c.uniqueKeys)) {
      return;
    }
    if (legendItemKey) {
      return datum[legendItemKey];
    } else if (calloutLabelKey) {
      return datum[calloutLabelKey];
    } else if (sectorLabelKey) {
      return datum[sectorLabelKey];
    }
  }
  getDatumId(datum) {
    var _a;
    const { index } = datum;
    return (_a = this.getDatumIdFromData(datum.datum)) != null ? _a : `${index}`;
  }
  onDataChange() {
    this.processSeriesItemEnabled();
  }
};
PieSeries$1.className = "PieSeries";
PieSeries$1.type = "pie";

// packages/ag-charts-community/src/chart/series/polar/pieSeriesModule.ts
var PieSeriesModule = {
  type: "series",
  optionsKey: "series[]",
  packageType: "community",
  chartTypes: ["polar"],
  identifier: "pie",
  instanceConstructor: PieSeries$1,
  seriesDefaults: {},
  themeTemplate: {
    __extends__: EXTENDS_SERIES_DEFAULTS$1,
    title: {
      enabled: true,
      fontStyle: void 0,
      fontWeight: FONT_WEIGHT2$1.NORMAL,
      fontSize: 14,
      fontFamily: DEFAULT_FONT_FAMILY$1,
      color: DEFAULT_MUTED_LABEL_COLOUR$1,
      spacing: 5
    },
    calloutLabel: {
      enabled: true,
      fontStyle: void 0,
      fontWeight: void 0,
      fontSize: 12,
      fontFamily: DEFAULT_FONT_FAMILY$1,
      color: DEFAULT_LABEL_COLOUR$1,
      offset: 3,
      minAngle: 0
    },
    sectorLabel: {
      enabled: true,
      fontStyle: void 0,
      fontWeight: FONT_WEIGHT2$1.NORMAL,
      fontSize: 12,
      fontFamily: DEFAULT_FONT_FAMILY$1,
      color: DEFAULT_INSIDE_SERIES_LABEL_COLOUR$1,
      positionOffset: 0,
      positionRatio: 0.5
    },
    calloutLine: {
      length: 10,
      strokeWidth: 2
    },
    fillOpacity: 1,
    strokeOpacity: 1,
    strokeWidth: 1,
    lineDash: [0],
    lineDashOffset: 0,
    rotation: 0,
    outerRadiusOffset: 0,
    innerRadiusOffset: 0,
    // @todo(AG-10275) Uncomment this
    // sectorSpacing: 1,
    shadow: {
      enabled: false,
      color: DEFAULT_SHADOW_COLOUR$1,
      xOffset: 3,
      yOffset: 3,
      blur: 5
    },
    innerLabels: {
      fontStyle: void 0,
      fontWeight: void 0,
      fontSize: 12,
      fontFamily: DEFAULT_FONT_FAMILY$1,
      color: DEFAULT_LABEL_COLOUR$1,
      margin: 2
    },
    // @todo(AG-10275) Remove this
    // @ts-expect-error
    __BACKGROUND_COLOR_DO_NOT_USE: DEFAULT_BACKGROUND_COLOUR$1
  },
  paletteFactory: ({ takeColors, colorsCount, userPalette }) => {
    const { fills, strokes } = takeColors(colorsCount);
    return {
      fills,
      strokes: userPalette ? strokes : [],
      calloutLine: {
        colors: strokes
      }
    };
  }
};

// packages/ag-charts-community/src/chart/factory/registerInbuiltModules.ts
function registerInbuiltModules() {
  registerModule$1(BackgroundModule$1);
  registerModule$1(NavigatorModule);
  registerModule$1(AreaSeriesModule);
  registerModule$1(BarSeriesModule);
  registerModule$1(BubbleSeriesModule);
  registerModule$1(LineSeriesModule);
  registerModule$1(ScatterSeriesModule);
  registerModule$1(PieSeriesModule);
  registerModule$1(HistogramSeriesModule);
}

// packages/ag-charts-community/src/module-support.ts
var module_support_exports$1 = {};
__export$1(module_support_exports$1, {
  ADD_PHASE: () => ADD_PHASE$1,
  AGG_VALUES_EXTENT: () => AGG_VALUES_EXTENT$1,
  AND: () => AND$1,
  ARRAY: () => ARRAY$1,
  ARRAY_OF: () => ARRAY_OF$1,
  AbstractBarSeries: () => AbstractBarSeries$1,
  AbstractBarSeriesProperties: () => AbstractBarSeriesProperties$1,
  ActionOnSet: () => ActionOnSet$1,
  Animation: () => Animation$1,
  AnimationManager: () => AnimationManager$1,
  AreaSeriesTag: () => AreaSeriesTag$1,
  Axis: () => Axis$1,
  AxisLabel: () => AxisLabel$1,
  AxisTick: () => AxisTick$1,
  BOOLEAN: () => BOOLEAN$1,
  BOOLEAN_ARRAY: () => BOOLEAN_ARRAY$1,
  Background: () => Background$1,
  BackgroundModule: () => BackgroundModule$1,
  BaseModuleInstance: () => BaseModuleInstance$1,
  BaseProperties: () => BaseProperties$1,
  COLOR_STRING: () => COLOR_STRING$1,
  COLOR_STRING_ARRAY: () => COLOR_STRING_ARRAY$1,
  CartesianAxis: () => CartesianAxis$1,
  CartesianSeries: () => CartesianSeries$1,
  CartesianSeriesNodeClickEvent: () => CartesianSeriesNodeClickEvent$1,
  CartesianSeriesProperties: () => CartesianSeriesProperties$1,
  CategoryAxis: () => CategoryAxis$1,
  ChartAxisDirection: () => ChartAxisDirection$1,
  ChartEventManager: () => ChartEventManager$1,
  ChartUpdateType: () => ChartUpdateType$1,
  CursorManager: () => CursorManager$1,
  DATE: () => DATE$1,
  DATE_ARRAY: () => DATE_ARRAY$1,
  DATE_OR_DATETIME_MS: () => DATE_OR_DATETIME_MS$1,
  DEGREE: () => DEGREE$1,
  DIRECTION: () => DIRECTION$1,
  DataController: () => DataController$1,
  DataModel: () => DataModel$1,
  Default: () => Default$1,
  Deprecated: () => Deprecated$1,
  DeprecatedAndRenamedTo: () => DeprecatedAndRenamedTo$1,
  FONT_STYLE: () => FONT_STYLE$1,
  FONT_WEIGHT: () => FONT_WEIGHT$1,
  FROM_TO_MIXINS: () => FROM_TO_MIXINS$1,
  FUNCTION: () => FUNCTION$1,
  GREATER_THAN: () => GREATER_THAN$1,
  HierarchyNode: () => HierarchyNode$1,
  HierarchySeries: () => HierarchySeries$1,
  HierarchySeriesProperties: () => HierarchySeriesProperties$1,
  HighlightManager: () => HighlightManager$1,
  HighlightStyle: () => HighlightStyle$1,
  INITIAL_LOAD: () => INITIAL_LOAD$1,
  INTERACTION_RANGE: () => INTERACTION_RANGE$1,
  InteractionManager: () => InteractionManager$1,
  LABEL_PHASE: () => LABEL_PHASE$1,
  LESS_THAN: () => LESS_THAN$1,
  LINE_CAP: () => LINE_CAP$1,
  LINE_DASH: () => LINE_DASH$1,
  LINE_JOIN: () => LINE_JOIN$1,
  Layers: () => Layers$1,
  LayoutService: () => LayoutService$1,
  MATCHING_CROSSLINE_TYPE: () => MATCHING_CROSSLINE_TYPE$1,
  MAX_SPACING: () => MAX_SPACING$1,
  MIN_SPACING: () => MIN_SPACING$1,
  MODULE_CONFLICTS: () => MODULE_CONFLICTS$1,
  Motion: () => easing_exports$1,
  NAN: () => NAN$1,
  NODE_UPDATE_PHASES: () => NODE_UPDATE_PHASES$1,
  NUMBER: () => NUMBER$1,
  NUMBER_ARRAY: () => NUMBER_ARRAY$1,
  NUMBER_OR_NAN: () => NUMBER_OR_NAN$1,
  OBJECT: () => OBJECT$1,
  OBJECT_ARRAY: () => OBJECT_ARRAY$1,
  OR: () => OR$1,
  OVERFLOW_STRATEGY: () => OVERFLOW_STRATEGY$1,
  PLACEMENT: () => PLACEMENT$1,
  POLAR_AXIS_SHAPE: () => POLAR_AXIS_SHAPE$1,
  POSITION: () => POSITION$1,
  POSITIVE_NUMBER: () => POSITIVE_NUMBER$1,
  PolarAxis: () => PolarAxis$1,
  PolarSeries: () => PolarSeries$1,
  PropertiesArray: () => PropertiesArray$1,
  ProxyOnWrite: () => ProxyOnWrite$1,
  ProxyProperty: () => ProxyProperty$1,
  ProxyPropertyOnWrite: () => ProxyPropertyOnWrite$1,
  QUICK_TRANSITION: () => QUICK_TRANSITION$1,
  RATIO: () => RATIO$1,
  REGISTERED_MODULES: () => REGISTERED_MODULES$1,
  REMOVE_PHASE: () => REMOVE_PHASE$1,
  RepeatType: () => RepeatType$1,
  SMALLEST_KEY_INTERVAL: () => SMALLEST_KEY_INTERVAL$1,
  SORT_DOMAIN_GROUPS: () => SORT_DOMAIN_GROUPS$1,
  STRING: () => STRING$1,
  STRING_ARRAY: () => STRING_ARRAY$1,
  Series: () => Series$1,
  SeriesItemHighlightStyle: () => SeriesItemHighlightStyle$1,
  SeriesMarker: () => SeriesMarker$1,
  SeriesNodeClickEvent: () => SeriesNodeClickEvent$1,
  SeriesNodePickMode: () => SeriesNodePickMode$1,
  SeriesProperties: () => SeriesProperties$1,
  SeriesTooltip: () => SeriesTooltip$1,
  StateMachine: () => StateMachine$1,
  TEXT_ALIGN: () => TEXT_ALIGN$1,
  TEXT_WRAP: () => TEXT_WRAP$1,
  Tags: () => Tags$1,
  TooltipManager: () => TooltipManager$1,
  UNION: () => UNION$1,
  UPDATE_PHASE: () => UPDATE_PHASE$1,
  UpdateService: () => UpdateService$1,
  VERTICAL_ALIGN: () => VERTICAL_ALIGN$1,
  Validate: () => Validate$1,
  ZoomManager: () => ZoomManager$1,
  __FORCE_MODULE_DETECTION: () => __FORCE_MODULE_DETECTION2$1,
  accumulateGroup: () => accumulateGroup$1,
  accumulatedValue: () => accumulatedValue$1,
  accumulativeValueProperty: () => accumulativeValueProperty$1,
  adjustLabelPlacement: () => adjustLabelPlacement$1,
  animationValidation: () => animationValidation$1,
  area: () => area$1,
  arraysEqual: () => arraysEqual$1,
  assignJsonApplyConstructedArray: () => assignJsonApplyConstructedArray$1,
  average: () => average$1,
  backfillPathPointData: () => backfillPathPointData$1,
  buildResetPathFn: () => buildResetPathFn$1,
  checkCrisp: () => checkCrisp$1,
  clamp: () => clamp2$1,
  clampArray: () => clampArray$1,
  collapsedStartingBarPosition: () => collapsedStartingBarPosition$1,
  count: () => count$1,
  countFractionDigits: () => countFractionDigits$1,
  createDatumId: () => createDatumId$1,
  createDeprecationWarning: () => createDeprecationWarning$1,
  deconstructSelectionsOrNodes: () => deconstructSelectionsOrNodes$1,
  deepMerge: () => deepMerge$2,
  determinePathStatus: () => determinePathStatus$1,
  diff: () => diff$1,
  enterpriseModule: () => enterpriseModule$1,
  extent: () => extent$5,
  extractDecoratedProperties: () => extractDecoratedProperties$1,
  fixNumericExtent: () => fixNumericExtent$1,
  fromToMotion: () => fromToMotion$1,
  getRectConfig: () => getRectConfig$1,
  groupAccumulativeValueProperty: () => groupAccumulativeValueProperty$1,
  groupAverage: () => groupAverage$1,
  groupCount: () => groupCount$1,
  groupSum: () => groupSum$1,
  hasRegisteredEnterpriseModules: () => hasRegisteredEnterpriseModules$1,
  injectStyle: () => injectStyle$1,
  invertShapeDirection: () => invertShapeDirection$1,
  isArray: () => isArray$1,
  isBoolean: () => isBoolean$2,
  isDate: () => isDate$2,
  isDecoratedObject: () => isDecoratedObject$1,
  isDefined: () => isDefined$1,
  isEqual: () => isEqual$1,
  isFiniteNumber: () => isFiniteNumber$1,
  isFunction: () => isFunction$1,
  isHtmlElement: () => isHtmlElement$1,
  isNegative: () => isNegative$1,
  isNumber: () => isNumber$5,
  isObject: () => isObject$1,
  isObjectLike: () => isObjectLike$1,
  isPlainObject: () => isPlainObject$1,
  isProperties: () => isProperties$1,
  isReal: () => isReal$1,
  isString: () => isString$3,
  isValidDate: () => isValidDate$1,
  keyProperty: () => keyProperty$1,
  listDecoratedProperties: () => listDecoratedProperties$1,
  markerFadeInAnimation: () => markerFadeInAnimation$1,
  markerPaletteFactory: () => markerPaletteFactory$1,
  markerScaleInAnimation: () => markerScaleInAnimation$1,
  markerSwipeScaleInAnimation: () => markerSwipeScaleInAnimation$1,
  mergeDefaults: () => mergeDefaults$1,
  midpointStartingBarPosition: () => midpointStartingBarPosition$1,
  minMax: () => minMax$1,
  mod: () => mod$1,
  normaliseGroupTo: () => normaliseGroupTo$1,
  normalisePropertyTo: () => normalisePropertyTo$1,
  normalisedExtent: () => normalisedExtent$1,
  normalisedExtentWithMetadata: () => normalisedExtentWithMetadata$1,
  pairCategoryData: () => pairCategoryData$1,
  pairContinuousData: () => pairContinuousData$1,
  partialAssign: () => partialAssign$1,
  pathFadeInAnimation: () => pathFadeInAnimation$1,
  pathFadeOutAnimation: () => pathFadeOutAnimation$1,
  pathSwipeInAnimation: () => pathSwipeInAnimation$1,
  predicateWithMessage: () => predicateWithMessage$1,
  prepareAreaPathAnimation: () => prepareAreaPathAnimation$1,
  prepareAxisAnimationContext: () => prepareAxisAnimationContext$1,
  prepareAxisAnimationFunctions: () => prepareAxisAnimationFunctions$1,
  prepareBarAnimationFunctions: () => prepareBarAnimationFunctions$1,
  prepareLinePathAnimation: () => prepareLinePathAnimation$1,
  prepareLinePathAnimationFns: () => prepareLinePathAnimationFns$1,
  prepareMarkerAnimation: () => prepareMarkerAnimation$1,
  range: () => range2$1,
  rangedValueProperty: () => rangedValueProperty$1,
  registerModule: () => registerModule$1,
  registerModuleConflicts: () => registerModuleConflicts$1,
  renderPartialPath: () => renderPartialPath$1,
  resetAxisGroupFn: () => resetAxisGroupFn$1,
  resetAxisLabelSelectionFn: () => resetAxisLabelSelectionFn$1,
  resetAxisLineSelectionFn: () => resetAxisLineSelectionFn$1,
  resetAxisSelectionFn: () => resetAxisSelectionFn$1,
  resetBarSelectionsFn: () => resetBarSelectionsFn$1,
  resetIds: () => resetIds$1,
  resetLabelFn: () => resetLabelFn$1,
  resetMarkerFn: () => resetMarkerFn$1,
  resetMarkerPositionFn: () => resetMarkerPositionFn$1,
  resetMotion: () => resetMotion$1,
  round: () => round$1,
  seriesLabelFadeInAnimation: () => seriesLabelFadeInAnimation$1,
  seriesLabelFadeOutAnimation: () => seriesLabelFadeOutAnimation$1,
  singleSeriesPaletteFactory: () => singleSeriesPaletteFactory$1,
  staticFromToMotion: () => staticFromToMotion$1,
  stringify: () => stringify$1,
  sum: () => sum$1,
  toArray: () => toArray$1,
  toFixed: () => toFixed$1,
  toReal: () => toReal$1,
  trailingAccumulatedValue: () => trailingAccumulatedValue$1,
  trailingAccumulatedValueProperty: () => trailingAccumulatedValueProperty$1,
  trailingValue: () => trailingValue$1,
  trailingValueProperty: () => trailingValueProperty$1,
  unique: () => unique$1,
  updateClipPath: () => updateClipPath$1,
  updateLabelNode: () => updateLabelNode$1,
  updateRect: () => updateRect$1,
  validateCrossLineValues: () => validateCrossLineValues$1,
  valueProperty: () => valueProperty$1
});

// packages/ag-charts-community/src/util/shapes.ts
function invertShapeDirection$1(...supportedShapes) {
  for (const shape of supportedShapes) {
    if (shape instanceof Rect$1) {
      const { x, y, width, height } = shape;
      shape.setProperties({ x: y, y: x, width: height, height: width });
    } else if (shape instanceof Line$1) {
      const { x1, y1, x2, y2 } = shape;
      shape.setProperties({ x1: y1, y1: x1, x2: y2, y2: x2 });
    }
  }
}

// packages/ag-charts-community/src/chart/series/hierarchy/hierarchySeries.ts
var _HierarchyNode$1 = class _HierarchyNode {
  constructor(series, index, datum, size, colorValue, fill, stroke, sumSize, depth, parent, children) {
    this.series = series;
    this.index = index;
    this.datum = datum;
    this.size = size;
    this.colorValue = colorValue;
    this.fill = fill;
    this.stroke = stroke;
    this.sumSize = sumSize;
    this.depth = depth;
    this.parent = parent;
    this.children = children;
    this.midPoint = { x: 0, y: 0 };
  }
  contains(other) {
    let current = other;
    while (current != null && current.index >= this.index) {
      if (current === this) {
        return true;
      }
      current = current.parent;
    }
    return false;
  }
  walk(callback, order = _HierarchyNode.Walk.PreOrder) {
    if (order === _HierarchyNode.Walk.PreOrder) {
      callback(this);
    }
    this.children.forEach((child) => {
      child.walk(callback, order);
    });
    if (order === _HierarchyNode.Walk.PostOrder) {
      callback(this);
    }
  }
  *[Symbol.iterator]() {
    yield this;
    for (const child of this.children) {
      yield* __yieldStar$1(child);
    }
  }
};
_HierarchyNode$1.Walk = {
  PreOrder: 0,
  PostOrder: 1
};
var HierarchyNode$1 = _HierarchyNode$1;
var HierarchySeries$1 = class extends Series$1 {
  constructor(moduleCtx) {
    super({
      moduleCtx,
      pickModes: [0 /* EXACT_SHAPE_MATCH */],
      contentGroupVirtual: false
    });
    this.rootNode = new HierarchyNode$1(
      this,
      0,
      void 0,
      0,
      void 0,
      void 0,
      void 0,
      0,
      void 0,
      void 0,
      []
    );
    this.colorDomain = [0, 0];
    this.maxDepth = 0;
    this.animationState = new StateMachine$1(
      "empty",
      {
        empty: {
          update: {
            target: "ready",
            action: (data) => this.animateEmptyUpdateReady(data)
          }
        },
        ready: {
          updateData: "waiting",
          clear: "clearing",
          highlight: (data) => this.animateReadyHighlight(data),
          resize: (data) => this.animateReadyResize(data)
        },
        waiting: {
          update: {
            target: "ready",
            action: (data) => this.animateWaitingUpdateReady(data)
          }
        },
        clearing: {
          update: {
            target: "empty",
            action: (data) => this.animateClearingUpdateEmpty(data)
          }
        }
      },
      () => this.checkProcessedDataAnimatable()
    );
  }
  hasData() {
    return Array.isArray(this.data) && this.data.length > 0;
  }
  processData() {
    return __async$1(this, null, function* () {
      var _a, _b;
      const { childrenKey, sizeKey, colorKey, fills, strokes, colorRange } = this.properties;
      let index = 0;
      const getIndex = () => {
        index += 1;
        return index;
      };
      let maxDepth = 0;
      let minColor = Infinity;
      let maxColor = -Infinity;
      const colors = new Array(((_b = (_a = this.data) == null ? void 0 : _a.length) != null ? _b : 0) + 1).fill(void 0);
      const createNode = (datum, parent) => {
        const index2 = getIndex();
        const depth = parent.depth != null ? parent.depth + 1 : 0;
        const children = childrenKey != null ? datum[childrenKey] : void 0;
        const isLeaf = children == null || children.length === 0;
        let size = sizeKey != null ? datum[sizeKey] : void 0;
        if (Number.isFinite(size)) {
          size = Math.max(size, 0);
        } else {
          size = isLeaf ? 1 : 0;
        }
        const sumSize = size;
        maxDepth = Math.max(maxDepth, depth);
        const color = colorKey != null ? datum[colorKey] : void 0;
        if (typeof color === "number") {
          colors[index2] = color;
          minColor = Math.min(minColor, color);
          maxColor = Math.max(maxColor, color);
        }
        return appendChildren(
          new HierarchyNode$1(
            this,
            index2,
            datum,
            size,
            color,
            void 0,
            void 0,
            sumSize,
            depth,
            parent,
            []
          ),
          children
        );
      };
      const appendChildren = (node, data) => {
        data == null ? void 0 : data.forEach((datum) => {
          const child = createNode(datum, node);
          node.children.push(child);
          node.sumSize += child.sumSize;
        });
        return node;
      };
      const rootNode = appendChildren(
        new HierarchyNode$1(
          this,
          0,
          void 0,
          0,
          void 0,
          void 0,
          void 0,
          0,
          void 0,
          void 0,
          []
        ),
        this.data
      );
      const colorDomain = [minColor, maxColor];
      let colorScale;
      if (colorRange != null && Number.isFinite(minColor) && Number.isFinite(maxColor)) {
        colorScale = new ColorScale$1();
        colorScale.domain = colorDomain;
        colorScale.range = colorRange;
        colorScale.update();
      }
      rootNode.children.forEach((child, index2) => {
        child.walk((node) => {
          let fill;
          const color = colors[node.index];
          if (color != null) {
            fill = colorScale == null ? void 0 : colorScale.convert(color);
          }
          fill != null ? fill : fill = fills == null ? void 0 : fills[index2 % fills.length];
          node.fill = fill;
          node.stroke = colorScale == null ? strokes == null ? void 0 : strokes[index2 % strokes.length] : "rgba(0, 0, 0, 0.2)";
        });
      });
      this.rootNode = rootNode;
      this.maxDepth = maxDepth;
      this.colorDomain = colorDomain;
    });
  }
  update(_0) {
    return __async$1(this, arguments, function* ({ seriesRect }) {
      yield this.updateSelections();
      yield this.updateNodes();
      const animationData = this.getAnimationData();
      const resize = this.checkResize(seriesRect);
      if (resize) {
        this.animationState.transition("resize", animationData);
      }
      this.animationState.transition("update", animationData);
    });
  }
  resetAllAnimation(data) {
    var _a;
    const datum = (_a = this.animationResetFns) == null ? void 0 : _a.datum;
    this.ctx.animationManager.stopByAnimationGroupId(this.id);
    if (datum != null) {
      resetMotion$1(data.datumSelections, datum);
    }
  }
  animateEmptyUpdateReady(data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation(data);
  }
  animateWaitingUpdateReady(data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation(data);
  }
  animateReadyHighlight(data) {
    var _a;
    const datum = (_a = this.animationResetFns) == null ? void 0 : _a.datum;
    if (datum != null) {
      resetMotion$1([data], datum);
    }
  }
  animateReadyResize(data) {
    this.resetAllAnimation(data);
  }
  animateClearingUpdateEmpty(data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation(data);
  }
  animationTransitionClear() {
    this.animationState.transition("clear", this.getAnimationData());
  }
  getAnimationData() {
    const animationData = {
      datumSelections: [this.groupSelection]
    };
    return animationData;
  }
  isProcessedDataAnimatable() {
    return true;
  }
  checkProcessedDataAnimatable() {
    if (!this.isProcessedDataAnimatable()) {
      this.ctx.animationManager.skipCurrentBatch();
    }
  }
  getLabelData() {
    return [];
  }
  getSeriesDomain() {
    return [NaN, NaN];
  }
  getLegendData(legendType) {
    const { colorKey, colorName, colorRange, visible } = this.properties;
    return legendType === "gradient" && colorKey != null && colorRange != null ? [
      {
        legendType: "gradient",
        enabled: visible,
        seriesId: this.id,
        colorName,
        colorRange,
        colorDomain: this.colorDomain
      }
    ] : [];
  }
  getDatumIdFromData(node) {
    return `${node.index}`;
  }
  getDatumId(node) {
    return this.getDatumIdFromData(node);
  }
};

// packages/ag-charts-community/src/chart/series/hierarchy/hierarchySeriesProperties.ts
var HierarchySeriesProperties$1 = class extends SeriesProperties$1 {
  constructor() {
    super(...arguments);
    this.childrenKey = "children";
    this.fills = Object.values(DEFAULT_FILLS$1);
    this.strokes = Object.values(DEFAULT_STROKES$1);
  }
};
__decorateClass$1([
  Validate$1(STRING$1)
], HierarchySeriesProperties$1.prototype, "childrenKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], HierarchySeriesProperties$1.prototype, "sizeKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], HierarchySeriesProperties$1.prototype, "colorKey", 2);
__decorateClass$1([
  Validate$1(STRING$1, { optional: true })
], HierarchySeriesProperties$1.prototype, "colorName", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING_ARRAY$1)
], HierarchySeriesProperties$1.prototype, "fills", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING_ARRAY$1)
], HierarchySeriesProperties$1.prototype, "strokes", 2);
__decorateClass$1([
  Validate$1(COLOR_STRING_ARRAY$1, { optional: true })
], HierarchySeriesProperties$1.prototype, "colorRange", 2);

// packages/ag-charts-community/src/chart/axis/polarAxis.ts
var POLAR_AXIS_SHAPE$1 = UNION$1(["polygon", "circle"], "a polar axis shape");
var PolarAxis$1 = class extends Axis$1 {
  constructor() {
    super(...arguments);
    this.shape = "polygon";
    this.innerRadiusRatio = 0;
    this.defaultTickMinSpacing = 20;
  }
  computeLabelsBBox(_options, _seriesRect) {
    return null;
  }
};
__decorateClass$1([
  Validate$1(POLAR_AXIS_SHAPE$1)
], PolarAxis$1.prototype, "shape", 2);
__decorateClass$1([
  Validate$1(RATIO$1)
], PolarAxis$1.prototype, "innerRadiusRatio", 2);

// packages/ag-charts-community/src/chart/legendDatum.ts
var __FORCE_MODULE_DETECTION2$1 = 0;

// packages/ag-charts-community/src/chart/factory/seriesTypes.ts
var SERIES_FACTORIES = {};
var SERIES_DEFAULTS = {};
var SERIES_THEME_TEMPLATES$1 = {};
var ENTERPRISE_SERIES_THEME_TEMPLATES$1 = {};
var SERIES_PALETTE_FACTORIES = {};
var SOLO_SERIES_TYPES = /* @__PURE__ */ new Set();
var STACKABLE_SERIES_TYPES = /* @__PURE__ */ new Set();
var GROUPABLE_SERIES_TYPES = /* @__PURE__ */ new Set();
var STACKED_BY_DEFAULT_SERIES_TYPES = /* @__PURE__ */ new Set();
var SWAP_DEFAULT_AXES_CONDITIONS = {};
var CUSTOM_DEFAULTS_FUNCTIONS = {};
function registerSeries(seriesType, chartType2, cstr, defaults, theme, enterpriseTheme, paletteFactory, solo, stackable, groupable, stackedByDefault, swapDefaultAxesCondition, customDefaultsFunction) {
  SERIES_FACTORIES[seriesType] = cstr;
  SERIES_DEFAULTS[seriesType] = defaults;
  registerSeriesThemeTemplate(seriesType, theme, enterpriseTheme);
  if (paletteFactory) {
    addSeriesPaletteFactory(seriesType, paletteFactory);
  }
  if (solo) {
    addSoloSeriesType(seriesType);
  }
  if (stackable) {
    addStackableSeriesType(seriesType);
  }
  if (groupable) {
    addGroupableSeriesType(seriesType);
  }
  if (stackedByDefault) {
    addStackedByDefaultSeriesType(seriesType);
  }
  if (swapDefaultAxesCondition) {
    addSwapDefaultAxesCondition(seriesType, swapDefaultAxesCondition);
  }
  if (customDefaultsFunction) {
    addCustomDefaultsFunctions(seriesType, customDefaultsFunction);
  }
  registerChartSeriesType(seriesType, chartType2);
}
function registerSeriesThemeTemplate(seriesType, themeTemplate, enterpriseThemeTemplate = {}) {
  const existingTemplate = SERIES_THEME_TEMPLATES$1[seriesType];
  SERIES_THEME_TEMPLATES$1[seriesType] = jsonMerge$1([existingTemplate, themeTemplate]);
  ENTERPRISE_SERIES_THEME_TEMPLATES$1[seriesType] = jsonMerge$1([
    existingTemplate,
    themeTemplate,
    enterpriseThemeTemplate
  ]);
}
function getSeries(chartType2, moduleCtx) {
  const seriesConstructor = SERIES_FACTORIES[chartType2];
  if (seriesConstructor) {
    return new seriesConstructor(moduleCtx);
  }
  throw new Error(`AG Charts - unknown series type: ${chartType2}`);
}
function getSeriesDefaults(chartType2) {
  return SERIES_DEFAULTS[chartType2];
}
function getSeriesThemeTemplate$1(chartType2) {
  if (hasRegisteredEnterpriseModules$1()) {
    return ENTERPRISE_SERIES_THEME_TEMPLATES$1[chartType2];
  }
  return SERIES_THEME_TEMPLATES$1[chartType2];
}
function addSeriesPaletteFactory(seriesType, factory) {
  SERIES_PALETTE_FACTORIES[seriesType] = factory;
}
function getSeriesPaletteFactory(seriesType) {
  return SERIES_PALETTE_FACTORIES[seriesType];
}
function isSoloSeries(seriesType) {
  return SOLO_SERIES_TYPES.has(seriesType);
}
function isStackableSeries(seriesType) {
  return STACKABLE_SERIES_TYPES.has(seriesType);
}
function isGroupableSeries(seriesType) {
  return GROUPABLE_SERIES_TYPES.has(seriesType);
}
function isSeriesStackedByDefault(seriesType) {
  return STACKED_BY_DEFAULT_SERIES_TYPES.has(seriesType);
}
function addGroupableSeriesType(seriesType) {
  GROUPABLE_SERIES_TYPES.add(seriesType);
}
function addSoloSeriesType(seriesType) {
  SOLO_SERIES_TYPES.add(seriesType);
}
function addStackableSeriesType(seriesType) {
  STACKABLE_SERIES_TYPES.add(seriesType);
}
function addStackedByDefaultSeriesType(seriesType) {
  STACKED_BY_DEFAULT_SERIES_TYPES.add(seriesType);
}
function addSwapDefaultAxesCondition(seriesType, predicate) {
  SWAP_DEFAULT_AXES_CONDITIONS[seriesType] = predicate;
}
function addCustomDefaultsFunctions(seriesType, predicate) {
  CUSTOM_DEFAULTS_FUNCTIONS[seriesType] = predicate;
}
function isDefaultAxisSwapNeeded(opts) {
  var _a, _b;
  let result;
  for (const series of (_a = opts.series) != null ? _a : []) {
    const { type = "line" } = series;
    const isDefaultAxisSwapped = (_b = SWAP_DEFAULT_AXES_CONDITIONS[type]) == null ? void 0 : _b.call(SWAP_DEFAULT_AXES_CONDITIONS, series);
    if (isDefaultAxisSwapped != null) {
      if (result != null && result != isDefaultAxisSwapped) {
        throw new Error("AG Charts - The provided series have incompatible directions");
      }
      result = isDefaultAxisSwapped;
    }
  }
  return result;
}
function executeCustomDefaultsFunctions(opts, initialDefaults) {
  var _a;
  let result = initialDefaults;
  for (const series of (_a = opts.series) != null ? _a : []) {
    const { type } = series;
    const fn = type != null ? CUSTOM_DEFAULTS_FUNCTIONS[type] : void 0;
    if (fn !== void 0) {
      result = __spreadValues$1(__spreadValues$1({}, result), fn(series));
    }
  }
  return result;
}

// packages/ag-charts-community/src/chart/factory/setupModules.ts
function setupModules() {
  var _a;
  for (const m of REGISTERED_MODULES$1) {
    if (m.packageType === "enterprise" && !verifyIfModuleExpected(m)) {
      Logger$1.errorOnce("Unexpected enterprise module registered: " + m.identifier);
    }
    if (JSON_APPLY_PLUGINS$1.constructors != null && m.optionConstructors != null) {
      Object.assign(JSON_APPLY_PLUGINS$1.constructors, m.optionConstructors);
    }
    if (m.type === "root" && m.themeTemplate) {
      for (const chartType2 of m.chartTypes) {
        registerChartDefaults(chartType2, m.themeTemplate);
      }
    }
    if (m.type === "root" && ((_a = m.conflicts) == null ? void 0 : _a.length)) {
      registerModuleConflicts$1(m.optionsKey, m.conflicts);
    }
    if (m.type === "series") {
      if (m.chartTypes.length > 1)
        throw new Error("AG Charts - Module definition error: " + m.identifier);
      registerSeries(
        m.identifier,
        m.chartTypes[0],
        m.instanceConstructor,
        m.seriesDefaults,
        m.themeTemplate,
        m.enterpriseThemeTemplate,
        m.paletteFactory,
        m.solo,
        m.stackable,
        m.groupable,
        m.stackedByDefault,
        m.swapDefaultAxesCondition,
        m.customDefaultsFunction
      );
    }
    if (m.type === "series-option" && m.themeTemplate) {
      for (const seriesType of m.seriesTypes) {
        registerSeriesThemeTemplate(seriesType, m.themeTemplate);
      }
    }
    if (m.type === "axis-option" && m.themeTemplate) {
      for (const axisType of m.axisTypes) {
        const axisTypeTheme = m.themeTemplate[axisType];
        const theme = __spreadValues$1(__spreadValues$1({}, m.themeTemplate), typeof axisTypeTheme === "object" ? axisTypeTheme : {});
        for (const axisType2 of m.axisTypes) {
          delete theme[axisType2];
        }
        registerAxisThemeTemplate(axisType, theme);
      }
    }
    if (m.type === "axis") {
      registerAxis(m.identifier, m.instanceConstructor);
      if (m.themeTemplate) {
        registerAxisThemeTemplate(m.identifier, m.themeTemplate);
      }
    }
    if (m.type === "legend") {
      registerLegend(m.identifier, m.optionsKey, m.instanceConstructor, m.themeTemplate);
    }
  }
  if (hasRegisteredEnterpriseModules$1()) {
    const expectedButUnused = getUnusedExpectedModules();
    if (expectedButUnused.length > 0) {
      Logger$1.errorOnce("Enterprise modules expected but not registered: ", expectedButUnused);
    }
  }
}

// packages/ag-charts-community/src/chart/hierarchyChart.ts
var _HierarchyChart$1 = class _HierarchyChart extends Chart$1 {
  constructor(specialOverrides, resources) {
    super(specialOverrides, resources);
    this._data = {};
  }
  performLayout() {
    return __async$1(this, null, function* () {
      const shrinkRect = yield __superGet$1(_HierarchyChart.prototype, this, "performLayout").call(this);
      const {
        seriesArea: { padding },
        seriesRoot
      } = this;
      const fullSeriesRect = shrinkRect.clone();
      shrinkRect.shrink(padding.left, "left");
      shrinkRect.shrink(padding.top, "top");
      shrinkRect.shrink(padding.right, "right");
      shrinkRect.shrink(padding.bottom, "bottom");
      this.seriesRect = shrinkRect;
      this.animationRect = shrinkRect;
      this.hoverRect = shrinkRect;
      seriesRoot.translationX = Math.floor(shrinkRect.x);
      seriesRoot.translationY = Math.floor(shrinkRect.y);
      yield Promise.all(
        this.series.map((series) => __async$1(this, null, function* () {
          yield series.update({ seriesRect: shrinkRect });
        }))
      );
      seriesRoot.visible = this.series[0].visible;
      seriesRoot.setClipRectInGroupCoordinateSpace(
        new BBox$1(shrinkRect.x, shrinkRect.y, shrinkRect.width, shrinkRect.height)
      );
      this.layoutService.dispatchLayoutComplete({
        type: "layout-complete",
        chart: { width: this.scene.width, height: this.scene.height },
        clipSeries: false,
        series: { rect: fullSeriesRect, paddedRect: shrinkRect, visible: true },
        axes: []
      });
      return shrinkRect;
    });
  }
};
_HierarchyChart$1.className = "HierarchyChart";
_HierarchyChart$1.type = "hierarchy";
var HierarchyChart = _HierarchyChart$1;

// packages/ag-charts-community/src/chart/factory/processEnterpriseOptions.ts
function removeUsedEnterpriseOptions(options) {
  var _a, _b, _c, _d;
  const usedOptions = [];
  const optionsChartType = getChartType(optionsType(options));
  for (const { type, chartTypes, optionsKey, optionsInnerKey, identifier } of EXPECTED_ENTERPRISE_MODULES) {
    if (optionsChartType !== "unknown" && !chartTypes.includes(optionsChartType))
      continue;
    if (type === "root" || type === "legend") {
      const optionValue = options[optionsKey];
      if (optionValue == null)
        continue;
      if (!optionsInnerKey) {
        usedOptions.push(optionsKey);
        delete options[optionsKey];
      } else if (optionValue[optionsInnerKey]) {
        usedOptions.push(`${optionsKey}.${optionsInnerKey}`);
        delete optionValue[optionsInnerKey];
      }
    } else if (type === "axis") {
      if (!("axes" in options) || !((_a = options.axes) == null ? void 0 : _a.some((axis) => axis.type === identifier)))
        continue;
      usedOptions.push(`axis[type=${identifier}]`);
      options.axes = options.axes.filter((axis) => axis.type !== identifier);
    } else if (type === "axis-option") {
      if (!("axes" in options) || !((_b = options.axes) == null ? void 0 : _b.some((axis) => axis[optionsKey])))
        continue;
      usedOptions.push(`axis.${optionsKey}`);
      options.axes.forEach((axis) => {
        if (axis[optionsKey]) {
          delete axis[optionsKey];
        }
      });
    } else if (type === "series") {
      if (!((_c = options.series) == null ? void 0 : _c.some((series) => series.type === identifier)))
        continue;
      usedOptions.push(`series[type=${identifier}]`);
      options.series = options.series.filter((series) => series.type !== identifier);
    } else if (type === "series-option") {
      if (!((_d = options.series) == null ? void 0 : _d.some((series) => series[optionsKey])))
        continue;
      usedOptions.push(`series.${optionsKey}`);
      options.series.forEach((series) => {
        if (series[optionsKey]) {
          delete series[optionsKey];
        }
      });
    }
  }
  if (usedOptions.length > 0) {
    Logger$1.warnOnce(
      [
        `unable to use these enterprise features as 'ag-charts-enterprise' has not been loaded:`,
        ``,
        ...usedOptions,
        ``,
        "See: https://charts.ag-grid.com/javascript/installation/"
      ].join("\n")
    );
  }
}

// packages/ag-charts-community/src/chart/themes/chartTheme.ts
var DEFAULT_BACKGROUND_FILL$1 = "white";
var palette$1 = {
  fills: Array.from(Object.values(DEFAULT_FILLS$1)),
  strokes: Array.from(Object.values(DEFAULT_STROKES$1))
};
var CHART_TYPE_CONFIG$1 = {
  get cartesian() {
    return { seriesTypes: CHART_TYPES$1.cartesianTypes, commonOptions: ["zoom", "navigator"] };
  },
  get polar() {
    return { seriesTypes: CHART_TYPES$1.polarTypes, commonOptions: [] };
  },
  get hierarchy() {
    return { seriesTypes: CHART_TYPES$1.hierarchyTypes, commonOptions: [] };
  }
};
var CHART_TYPE_SPECIFIC_COMMON_OPTIONS$1 = Object.values(CHART_TYPE_CONFIG$1).reduce((r, { commonOptions }) => [...r, ...commonOptions], []);
function resolvePartialPalette$1(partialPalette, basePalette) {
  var _a, _b;
  if (partialPalette == null)
    return null;
  return {
    fills: (_a = partialPalette.fills) != null ? _a : basePalette.fills,
    strokes: (_b = partialPalette.strokes) != null ? _b : basePalette.strokes
  };
}
var _ChartTheme$1 = class _ChartTheme {
  getPalette() {
    return palette$1;
  }
  static getAxisDefaults() {
    return {
      top: {},
      right: {},
      bottom: {},
      left: {},
      title: {
        enabled: false,
        text: "Axis Title",
        spacing: 25,
        fontStyle: void 0,
        fontWeight: FONT_WEIGHT2$1.NORMAL,
        fontSize: FONT_SIZE$1.MEDIUM,
        fontFamily: DEFAULT_FONT_FAMILY$1,
        color: DEFAULT_LABEL_COLOUR$1
      },
      label: {
        fontStyle: void 0,
        fontWeight: void 0,
        fontSize: FONT_SIZE$1.SMALL,
        fontFamily: DEFAULT_FONT_FAMILY$1,
        padding: 5,
        rotation: void 0,
        color: DEFAULT_LABEL_COLOUR$1,
        formatter: void 0,
        avoidCollisions: true
      },
      line: {
        enabled: true,
        width: 1,
        color: DEFAULT_AXIS_LINE_COLOUR$1
      },
      tick: {
        enabled: false,
        width: 1,
        color: DEFAULT_AXIS_LINE_COLOUR$1
      },
      gridLine: {
        enabled: true,
        style: [
          {
            stroke: DEFAULT_AXIS_GRID_COLOUR$1,
            lineDash: []
          }
        ]
      },
      crossLines: {
        enabled: false,
        fill: DEFAULT_CROSS_LINES_COLOUR$1,
        stroke: DEFAULT_CROSS_LINES_COLOUR$1,
        fillOpacity: 0.1,
        strokeWidth: 1,
        label: {
          enabled: false,
          fontStyle: void 0,
          fontWeight: void 0,
          fontSize: FONT_SIZE$1.SMALL,
          fontFamily: DEFAULT_FONT_FAMILY$1,
          padding: 5,
          color: DEFAULT_LABEL_COLOUR$1
        }
      }
    };
  }
  static getSeriesDefaults() {
    return {
      tooltip: {
        enabled: true,
        renderer: void 0
      },
      visible: true,
      showInLegend: true,
      highlightStyle: {
        item: {
          fill: "rgba(255,255,255, 0.33)",
          stroke: `rgba(0, 0, 0, 0.4)`,
          strokeWidth: 2
        },
        series: {
          dimOpacity: 1
        },
        text: {
          color: "black"
        }
      },
      nodeClickRange: "exact"
    };
  }
  static getCartesianSeriesMarkerDefaults() {
    return {
      enabled: true,
      shape: "circle",
      size: 7,
      strokeWidth: 1,
      formatter: void 0
    };
  }
  static getLegendItemMarkerDefaults() {
    return {
      shape: void 0,
      size: 15,
      padding: 8
    };
  }
  static getCaptionWrappingDefaults() {
    return "hyphenate";
  }
  static getChartDefaults() {
    return {
      background: {
        visible: true,
        fill: DEFAULT_BACKGROUND_COLOUR$1
      },
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      title: {
        enabled: false,
        text: "Title",
        fontStyle: void 0,
        fontWeight: FONT_WEIGHT2$1.NORMAL,
        fontSize: FONT_SIZE$1.LARGE,
        fontFamily: DEFAULT_FONT_FAMILY$1,
        color: DEFAULT_LABEL_COLOUR$1,
        wrapping: _ChartTheme.getCaptionWrappingDefaults()
      },
      subtitle: {
        enabled: false,
        text: "Subtitle",
        spacing: 20,
        fontStyle: void 0,
        fontWeight: void 0,
        fontSize: FONT_SIZE$1.MEDIUM,
        fontFamily: DEFAULT_FONT_FAMILY$1,
        color: DEFAULT_MUTED_LABEL_COLOUR$1,
        wrapping: _ChartTheme.getCaptionWrappingDefaults()
      },
      footnote: {
        enabled: false,
        text: "Footnote",
        spacing: 20,
        fontStyle: void 0,
        fontWeight: void 0,
        fontSize: FONT_SIZE$1.MEDIUM,
        fontFamily: DEFAULT_FONT_FAMILY$1,
        color: "rgb(140, 140, 140)",
        wrapping: _ChartTheme.getCaptionWrappingDefaults()
      },
      legend: {
        position: BOTTOM$1,
        spacing: 30,
        listeners: {},
        item: {
          paddingX: 16,
          paddingY: 8,
          marker: _ChartTheme.getLegendItemMarkerDefaults(),
          label: {
            color: DEFAULT_LABEL_COLOUR$1,
            fontStyle: void 0,
            fontWeight: void 0,
            fontSize: FONT_SIZE$1.SMALL,
            fontFamily: DEFAULT_FONT_FAMILY$1,
            formatter: void 0
          }
        },
        reverseOrder: false,
        pagination: {
          marker: {
            size: 12
          },
          activeStyle: {
            fill: DEFAULT_LABEL_COLOUR$1
          },
          inactiveStyle: {
            fill: DEFAULT_MUTED_LABEL_COLOUR$1
          },
          highlightStyle: {
            fill: DEFAULT_LABEL_COLOUR$1
          },
          label: {
            color: DEFAULT_LABEL_COLOUR$1
          }
        }
      },
      tooltip: {
        enabled: true,
        range: "nearest",
        delay: 0
      },
      listeners: {}
    };
  }
  constructor(options) {
    var _a;
    options = deepMerge$2({}, options != null ? options : {});
    const { overrides = null, palette: palette11 = null } = options;
    const defaults = this.createChartConfigPerChartType(this.getDefaults());
    if (overrides) {
      const { common } = overrides;
      const applyOverrides = (seriesTypes, overrideOpts) => {
        if (!overrideOpts)
          return;
        for (const s of seriesTypes) {
          const seriesType = s;
          defaults[seriesType] = deepMerge$2(defaults[seriesType], overrideOpts);
        }
      };
      for (const [, { seriesTypes, commonOptions }] of Object.entries(CHART_TYPE_CONFIG$1)) {
        const cleanedCommon = __spreadValues$1({}, common);
        for (const commonKey of CHART_TYPE_SPECIFIC_COMMON_OPTIONS$1) {
          if (!commonOptions.includes(commonKey)) {
            delete cleanedCommon[commonKey];
          }
        }
        applyOverrides(seriesTypes, cleanedCommon);
      }
      CHART_TYPES$1.seriesTypes.forEach((s) => {
        const seriesType = s;
        if (overrides[seriesType]) {
          defaults[seriesType] = deepMerge$2(defaults[seriesType], overrides[seriesType]);
        }
      });
    }
    const basePalette = this.getPalette();
    this.palette = (_a = resolvePartialPalette$1(palette11, basePalette)) != null ? _a : basePalette;
    this.config = Object.freeze(this.templateTheme(defaults));
  }
  createChartConfigPerChartType(config) {
    Object.entries(CHART_TYPE_CONFIG$1).forEach(([nextType, { seriesTypes }]) => {
      const typeDefaults = getChartDefaults$1(nextType);
      seriesTypes.forEach((next) => {
        const alias = next;
        if (!config[alias]) {
          config[alias] = {};
          deepMerge$2(config[alias], typeDefaults);
        }
      });
    });
    return config;
  }
  getDefaults() {
    let defaults = {};
    const getChartTypeDefaults = (chartType2) => {
      return __spreadValues$1(__spreadValues$1(__spreadValues$1({}, getLegendThemeTemplates$1()), _ChartTheme.getChartDefaults()), getChartDefaults$1(chartType2));
    };
    const getOverridesByType = (chartType2, seriesTypes) => {
      var _a, _b, _c, _d;
      const chartDefaults = getChartTypeDefaults(chartType2);
      const result = {};
      for (const seriesType of seriesTypes) {
        (_a = result[seriesType]) != null ? _a : result[seriesType] = deepMerge$2({}, chartDefaults);
        const axes = (_c = (_b = result[seriesType]).axes) != null ? _c : _b.axes = {};
        const template = getSeriesThemeTemplate$1(seriesType);
        if (template) {
          result[seriesType].series = deepMerge$2(result[seriesType].series, template);
        }
        for (const axisType of AXIS_TYPES$1.axesTypes) {
          const template2 = getAxisThemeTemplate$1(axisType);
          if (chartType2 === "cartesian") {
            axes[axisType] = deepMerge$2(
              axes[axisType],
              (_d = _ChartTheme.cartesianAxisDefault[axisType]) != null ? _d : {}
            );
          }
          if (template2) {
            axes[axisType] = deepMerge$2(axes[axisType], template2);
          }
        }
      }
      return result;
    };
    defaults = deepMerge$2(defaults, getOverridesByType("cartesian", CHART_TYPES$1.cartesianTypes));
    defaults = deepMerge$2(defaults, getOverridesByType("polar", CHART_TYPES$1.polarTypes));
    defaults = deepMerge$2(defaults, getOverridesByType("hierarchy", CHART_TYPES$1.hierarchyTypes));
    return defaults;
  }
  templateTheme(themeTemplate) {
    const themeInstance = jsonClone$1(themeTemplate);
    const { extensions, properties } = this.getTemplateParameters();
    jsonWalk$1(themeInstance, (node) => {
      if (node["__extends__"]) {
        const key = node["__extends__"];
        const source = extensions.get(key);
        if (source == null) {
          throw new Error(`AG Charts - no template variable provided for: ${key}`);
        }
        Object.keys(source).forEach((key2) => {
          if (!(key2 in node)) {
            node[key2] = source[key2];
          }
        });
        delete node["__extends__"];
      }
      if (node["__overrides__"]) {
        const key = node["__overrides__"];
        const source = extensions.get(key);
        if (source == null) {
          throw new Error(`AG Charts - no template variable provided for: ${key}`);
        }
        Object.assign(node, source);
        delete node["__overrides__"];
      }
      if (Array.isArray(node)) {
        for (let i = 0; i < node.length; i++) {
          const symbol = node[i];
          if (properties.has(symbol)) {
            node[i] = properties.get(symbol);
          }
        }
      } else {
        for (const [name, value] of Object.entries(node)) {
          if (properties.has(value)) {
            node[name] = properties.get(value);
          }
        }
      }
    });
    return themeInstance;
  }
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: DEFAULT_FILLS$1.BLUE,
      stroke: DEFAULT_STROKES$1.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: DEFAULT_FILLS$1.ORANGE,
      stroke: DEFAULT_STROKES$1.ORANGE
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: DEFAULT_FILLS$1.GRAY,
      stroke: DEFAULT_STROKES$1.GRAY
    };
  }
  getTemplateParameters() {
    const extensions = /* @__PURE__ */ new Map();
    extensions.set(EXTENDS_CHART_DEFAULTS$1, _ChartTheme.getChartDefaults());
    extensions.set(EXTENDS_AXES_DEFAULTS$1, _ChartTheme.getAxisDefaults());
    extensions.set(EXTENDS_LEGEND_DEFAULTS$1, _ChartTheme.getChartDefaults().legend);
    extensions.set(EXTENDS_LEGEND_ITEM_DEFAULTS$1, _ChartTheme.getChartDefaults().legend.item);
    extensions.set(EXTENDS_LEGEND_ITEM_MARKER_DEFAULTS$1, _ChartTheme.getLegendItemMarkerDefaults());
    extensions.set(EXTENDS_AXES_LABEL_DEFAULTS$1, _ChartTheme.getAxisDefaults().label);
    extensions.set(EXTENDS_AXES_LINE_DEFAULTS$1, _ChartTheme.getAxisDefaults().line);
    extensions.set(EXTENDS_AXES_TICK_DEFAULTS$1, _ChartTheme.getAxisDefaults().tick);
    extensions.set(EXTENDS_AXES_GRID_LINE_DEFAULTS$1, _ChartTheme.getAxisDefaults().gridLine);
    extensions.set(EXTENDS_SERIES_DEFAULTS$1, _ChartTheme.getSeriesDefaults());
    extensions.set(OVERRIDE_SERIES_LABEL_DEFAULTS$1, {});
    extensions.set(EXTENDS_CARTESIAN_MARKER_DEFAULTS$1, _ChartTheme.getCartesianSeriesMarkerDefaults());
    const properties = /* @__PURE__ */ new Map();
    properties.set(DEFAULT_FONT_FAMILY$1, "Verdana, sans-serif");
    properties.set(DEFAULT_LABEL_COLOUR$1, "rgb(70, 70, 70)");
    properties.set(DEFAULT_INVERTED_LABEL_COLOUR$1, "white");
    properties.set(DEFAULT_MUTED_LABEL_COLOUR$1, "rgb(140, 140, 140)");
    properties.set(DEFAULT_AXIS_GRID_COLOUR$1, "rgb(224,234,241)");
    properties.set(DEFAULT_AXIS_LINE_COLOUR$1, "rgb(195, 195, 195)");
    properties.set(DEFAULT_CROSS_LINES_COLOUR$1, "rgb(70, 70, 70)");
    properties.set(DEFAULT_INSIDE_SERIES_LABEL_COLOUR$1, DEFAULT_BACKGROUND_FILL$1);
    properties.set(DEFAULT_BACKGROUND_COLOUR$1, DEFAULT_BACKGROUND_FILL$1);
    properties.set(DEFAULT_SHADOW_COLOUR$1, "rgba(0, 0, 0, 0.5)");
    properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE$1, [
      DEFAULT_FILLS$1.ORANGE,
      DEFAULT_FILLS$1.YELLOW,
      DEFAULT_FILLS$1.GREEN
    ]);
    properties.set(DEFAULT_HIERARCHY_FILLS$1, ["#ffffff", "#e0e5ea", "#c1ccd5", "#a3b4c1", "#859cad"]);
    properties.set(DEFAULT_HIERARCHY_STROKES$1, ["#ffffff", "#c5cbd1", "#a4b1bd", "#8498a9", "#648096"]);
    properties.set(DEFAULT_POLAR_SERIES_STROKE$1, DEFAULT_BACKGROUND_FILL$1);
    properties.set(DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS$1, _ChartTheme.getWaterfallSeriesDefaultPositiveColors());
    properties.set(DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS$1, _ChartTheme.getWaterfallSeriesDefaultNegativeColors());
    properties.set(DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS$1, _ChartTheme.getWaterfallSeriesDefaultTotalColors());
    properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE$1,
      _ChartTheme.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return {
      extensions,
      properties
    };
  }
};
_ChartTheme$1.cartesianAxisDefault = {
  number: __spreadProps$1(__spreadValues$1({}, _ChartTheme$1.getAxisDefaults()), {
    line: __spreadProps$1(__spreadValues$1({}, _ChartTheme$1.getAxisDefaults().line), {
      enabled: false
    })
  }),
  log: __spreadProps$1(__spreadValues$1({}, _ChartTheme$1.getAxisDefaults()), {
    base: 10,
    line: __spreadProps$1(__spreadValues$1({}, _ChartTheme$1.getAxisDefaults().line), {
      enabled: false
    })
  }),
  category: __spreadProps$1(__spreadValues$1({}, _ChartTheme$1.getAxisDefaults()), {
    groupPaddingInner: 0.1,
    label: __spreadProps$1(__spreadValues$1({}, _ChartTheme$1.getAxisDefaults().label), {
      autoRotate: true
    }),
    gridLine: __spreadProps$1(__spreadValues$1({}, _ChartTheme$1.getAxisDefaults().gridLine), {
      enabled: false
    })
  }),
  "grouped-category": __spreadValues$1({}, _ChartTheme$1.getAxisDefaults()),
  time: __spreadProps$1(__spreadValues$1({}, _ChartTheme$1.getAxisDefaults()), {
    gridLine: __spreadProps$1(__spreadValues$1({}, _ChartTheme$1.getAxisDefaults().gridLine), {
      enabled: false
    })
  })
};
var ChartTheme$1 = _ChartTheme$1;

// packages/ag-charts-community/src/chart/mapping/prepareSeries.ts
function groupSeriesByType(seriesOptions) {
  var _a, _b, _c;
  const groupMap = {};
  const stackMap = {};
  const defaultUnstackedGroup = "default-ag-charts-group";
  const result = [];
  for (const s of seriesOptions) {
    const type = (_a = s.type) != null ? _a : "line";
    const stackable = isStackableSeries(type);
    const groupable = isGroupableSeries(type);
    if (!stackable && !groupable) {
      result.push({ type: "ungrouped", opts: [s] });
      continue;
    }
    const { stacked: sStacked, stackGroup: sStackGroup, grouped: sGrouped = void 0, xKey } = s;
    const stacked = sStackGroup != null || sStacked === true;
    const grouped = sGrouped === true;
    let groupingKey = [sStackGroup != null ? sStackGroup : sStacked === true ? "stacked" : void 0, grouped ? "grouped" : void 0].filter((v) => v != null).join("-");
    if (!groupingKey) {
      groupingKey = defaultUnstackedGroup;
    }
    const indexKey = `${type}-${xKey}-${groupingKey}`;
    if (stacked && stackable) {
      const updated = (_b = stackMap[indexKey]) != null ? _b : stackMap[indexKey] = { type: "stack", opts: [] };
      if (updated.opts.length === 0)
        result.push(updated);
      updated.opts.push(s);
    } else if (grouped && groupable) {
      const updated = (_c = groupMap[indexKey]) != null ? _c : groupMap[indexKey] = { type: "group", opts: [] };
      if (updated.opts.length === 0)
        result.push(updated);
      updated.opts.push(s);
    } else {
      result.push({ type: "ungrouped", opts: [s] });
    }
  }
  return result;
}
function processSeriesOptions(_opts, seriesOptions) {
  var _a;
  const result = [];
  const preprocessed = seriesOptions.map((series) => {
    var _a2;
    const sType = (_a2 = series.type) != null ? _a2 : "line";
    const groupable = isGroupableSeries(sType);
    const stackable = isStackableSeries(sType);
    const stackedByDefault = isSeriesStackedByDefault(sType);
    if (series.grouped && !groupable) {
      Logger$1.warnOnce(`unsupported grouping of series type: ${sType}`);
    }
    if (series.stacked && !stackable) {
      Logger$1.warnOnce(`unsupported stacking of series type: ${sType}`);
    }
    if (!groupable && !stackable) {
      return series;
    }
    let stacked = false;
    let grouped2 = false;
    if (series.stacked === void 0 && series.grouped === void 0) {
      stacked = stackable && stackedByDefault;
      grouped2 = groupable && !stacked;
    } else if (series.stacked === void 0) {
      stacked = stackable && stackedByDefault && !(series.grouped && groupable);
      grouped2 = groupable && !stacked && !!series.grouped;
    } else if (series.grouped === void 0) {
      stacked = stackable && series.stacked;
      grouped2 = groupable && !stacked;
    } else {
      stacked = stackable && series.stacked;
      grouped2 = groupable && !stacked && series.grouped;
    }
    return __spreadProps$1(__spreadValues$1({}, series), { stacked, grouped: grouped2 });
  });
  const grouped = groupSeriesByType(preprocessed);
  const groupCount2 = grouped.reduce(
    (result2, next) => {
      var _a2, _b;
      if (next.type === "ungrouped")
        return result2;
      const seriesType = (_a2 = next.opts[0].type) != null ? _a2 : "line";
      (_b = result2[seriesType]) != null ? _b : result2[seriesType] = 0;
      result2[seriesType] += next.type === "stack" ? 1 : next.opts.length;
      return result2;
    },
    {}
  );
  const groupIdx = {};
  const addSeriesGroupingMeta = (group2) => {
    var _a2, _b;
    let stackIdx = 0;
    const seriesType = (_a2 = group2.opts[0].type) != null ? _a2 : "line";
    (_b = groupIdx[seriesType]) != null ? _b : groupIdx[seriesType] = 0;
    if (group2.type === "stack") {
      for (const opts of group2.opts) {
        opts.seriesGrouping = {
          groupIndex: groupIdx[seriesType],
          groupCount: groupCount2[seriesType],
          stackIndex: stackIdx++,
          stackCount: group2.opts.length
        };
      }
      groupIdx[seriesType]++;
    } else if (group2.type === "group") {
      for (const opts of group2.opts) {
        opts.seriesGrouping = {
          groupIndex: groupIdx[seriesType],
          groupCount: groupCount2[seriesType],
          stackIndex: 0,
          stackCount: 0
        };
        groupIdx[seriesType]++;
      }
    } else {
      for (const opts of group2.opts) {
        opts.seriesGrouping = void 0;
      }
    }
    return group2.opts;
  };
  Debug$1.create(true, "opts")("processSeriesOptions() - series grouping: ", grouped);
  for (const group2 of grouped) {
    const seriesType = (_a = group2.opts[0].type) != null ? _a : "line";
    if (isGroupableSeries(seriesType) || isStackableSeries(seriesType)) {
      result.push(...addSeriesGroupingMeta(group2));
    } else {
      result.push(...group2.opts);
    }
  }
  return result;
}

// packages/ag-charts-community/src/chart/themes/darkTheme.ts
var DEFAULT_DARK_BACKGROUND_FILL$1 = "#192232";
var DEFAULT_DARK_FILLS$1 = {
  BLUE: "#5090dc",
  ORANGE: "#ffa03a",
  GREEN: "#459d55",
  CYAN: "#34bfe1",
  YELLOW: "#e1cc00",
  VIOLET: "#9669cb",
  GRAY: "#b5b5b5",
  MAGENTA: "#bd5aa7",
  BROWN: "#8a6224",
  RED: "#ef5452"
};
var DEFAULT_DARK_STROKES$1 = {
  BLUE: "#74a8e6",
  ORANGE: "#ffbe70",
  GREEN: "#6cb176",
  CYAN: "#75d4ef",
  YELLOW: "#f6e559",
  VIOLET: "#aa86d8",
  GRAY: "#a1a1a1",
  MAGENTA: "#ce7ab9",
  BROWN: "#997b52",
  RED: "#ff7872"
};
var palette2$1 = {
  fills: Array.from(Object.values(DEFAULT_DARK_FILLS$1)),
  strokes: Array.from(Object.values(DEFAULT_DARK_STROKES$1))
};
var DarkTheme$1 = class _DarkTheme extends ChartTheme$1 {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: DEFAULT_DARK_FILLS$1.BLUE,
      stroke: DEFAULT_DARK_STROKES$1.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: DEFAULT_DARK_FILLS$1.ORANGE,
      stroke: DEFAULT_DARK_STROKES$1.ORANGE
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: DEFAULT_DARK_FILLS$1.GRAY,
      stroke: DEFAULT_DARK_STROKES$1.GRAY
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS$1,
      _DarkTheme.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS$1,
      _DarkTheme.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS$1, _DarkTheme.getWaterfallSeriesDefaultTotalColors());
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE$1,
      _DarkTheme.getWaterfallSeriesDefaultTotalColors().stroke
    );
    result.properties.set(DEFAULT_POLAR_SERIES_STROKE$1, DEFAULT_DARK_BACKGROUND_FILL$1);
    result.properties.set(DEFAULT_LABEL_COLOUR$1, "white");
    result.properties.set(DEFAULT_MUTED_LABEL_COLOUR$1, "#7D91A0");
    result.properties.set(DEFAULT_AXIS_GRID_COLOUR$1, "#545A6E");
    result.properties.set(DEFAULT_CROSS_LINES_COLOUR$1, "white");
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE$1, [
      DEFAULT_DARK_FILLS$1.ORANGE,
      DEFAULT_DARK_FILLS$1.YELLOW,
      DEFAULT_DARK_FILLS$1.GREEN
    ]);
    result.properties.set(DEFAULT_HIERARCHY_FILLS$1, ["#192834", "#253746", "#324859", "#3f596c", "#4d6a80"]);
    result.properties.set(DEFAULT_HIERARCHY_STROKES$1, ["#192834", "#3b5164", "#496275", "#577287", "#668399"]);
    result.properties.set(DEFAULT_BACKGROUND_COLOUR$1, DEFAULT_DARK_BACKGROUND_FILL$1);
    result.properties.set(DEFAULT_INSIDE_SERIES_LABEL_COLOUR$1, DEFAULT_DARK_BACKGROUND_FILL$1);
    return result;
  }
  getPalette() {
    return palette2$1;
  }
  constructor(options) {
    super(options);
  }
};

// packages/ag-charts-community/src/chart/themes/materialDark.ts
var MATERIAL_DARK_FILLS$1 = {
  BLUE: "#2196F3",
  ORANGE: "#FF9800",
  GREEN: "#4CAF50",
  CYAN: "#00BCD4",
  YELLOW: "#FFEB3B",
  VIOLET: "#7E57C2",
  GRAY: "#9E9E9E",
  MAGENTA: "#F06292",
  BROWN: "#795548",
  RED: "#F44336"
};
var MATERIAL_DARK_STROKES$1 = {
  BLUE: "#90CAF9",
  ORANGE: "#FFCC80",
  GREEN: "#A5D6A7",
  CYAN: "#80DEEA",
  YELLOW: "#FFF9C4",
  VIOLET: "#B39DDB",
  GRAY: "#E0E0E0",
  MAGENTA: "#F48FB1",
  BROWN: "#A1887F",
  RED: "#EF9A9A"
};
var palette3$1 = {
  fills: Array.from(Object.values(MATERIAL_DARK_FILLS$1)),
  strokes: Array.from(Object.values(MATERIAL_DARK_STROKES$1))
};
var MaterialDark$1 = class _MaterialDark extends DarkTheme$1 {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: MATERIAL_DARK_FILLS$1.BLUE,
      stroke: MATERIAL_DARK_STROKES$1.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: MATERIAL_DARK_FILLS$1.RED,
      stroke: MATERIAL_DARK_STROKES$1.RED
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: MATERIAL_DARK_FILLS$1.GRAY,
      stroke: MATERIAL_DARK_STROKES$1.GRAY
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS$1,
      _MaterialDark.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS$1,
      _MaterialDark.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS$1,
      _MaterialDark.getWaterfallSeriesDefaultTotalColors()
    );
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE$1, [
      MATERIAL_DARK_FILLS$1.ORANGE,
      MATERIAL_DARK_FILLS$1.YELLOW,
      MATERIAL_DARK_FILLS$1.GREEN
    ]);
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE$1,
      _MaterialDark.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return result;
  }
  getPalette() {
    return palette3$1;
  }
};

// packages/ag-charts-community/src/chart/themes/materialLight.ts
var MATERIAL_LIGHT_FILLS$1 = {
  BLUE: "#2196F3",
  ORANGE: "#FF9800",
  GREEN: "#4CAF50",
  CYAN: "#00BCD4",
  YELLOW: "#FFEB3B",
  VIOLET: "#7E57C2",
  GRAY: "#9E9E9E",
  MAGENTA: "#F06292",
  BROWN: "#795548",
  RED: "#F44336"
};
var MATERIAL_LIGHT_STROKES$1 = {
  BLUE: "#1565C0",
  ORANGE: "#E65100",
  GREEN: "#2E7D32",
  CYAN: "#00838F",
  YELLOW: "#F9A825",
  VIOLET: "#4527A0",
  GRAY: "#616161",
  MAGENTA: "#C2185B",
  BROWN: "#4E342E",
  RED: "#B71C1C"
};
var palette4$1 = {
  fills: Array.from(Object.values(MATERIAL_LIGHT_FILLS$1)),
  strokes: Array.from(Object.values(MATERIAL_LIGHT_STROKES$1))
};
var MaterialLight$1 = class _MaterialLight extends ChartTheme$1 {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: MATERIAL_LIGHT_FILLS$1.BLUE,
      stroke: MATERIAL_LIGHT_STROKES$1.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: MATERIAL_LIGHT_FILLS$1.RED,
      stroke: MATERIAL_LIGHT_STROKES$1.RED
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: MATERIAL_LIGHT_FILLS$1.GRAY,
      stroke: MATERIAL_LIGHT_STROKES$1.GRAY
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS$1,
      _MaterialLight.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS$1,
      _MaterialLight.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS$1,
      _MaterialLight.getWaterfallSeriesDefaultTotalColors()
    );
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE$1, [
      MATERIAL_LIGHT_FILLS$1.ORANGE,
      MATERIAL_LIGHT_FILLS$1.YELLOW,
      MATERIAL_LIGHT_FILLS$1.GREEN
    ]);
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE$1,
      _MaterialLight.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return result;
  }
  getPalette() {
    return palette4$1;
  }
};

// packages/ag-charts-community/src/chart/themes/polychromaDark.ts
var POLYCHROMA_DARK_FILLS$1 = {
  BLUE: "#436ff4",
  PURPLE: "#9a7bff",
  MAGENTA: "#d165d2",
  PINK: "#f0598b",
  RED: "#f47348",
  ORANGE: "#f2a602",
  YELLOW: "#e9e201",
  GREEN: "#21b448",
  CYAN: "#00b9a2",
  MODERATE_BLUE: "#00aee4"
};
var POLYCHROMA_DARK_STROKES$1 = {
  BLUE: "#6698ff",
  PURPLE: "#c0a3ff",
  MAGENTA: "#fc8dfc",
  PINK: "#ff82b1",
  RED: "#ff9b70",
  ORANGE: "#ffcf4e",
  YELLOW: "#ffff58",
  GREEN: "#58dd70",
  CYAN: "#51e2c9",
  MODERATE_BLUE: "#4fd7ff"
};
var POLYCHROMA_DARK_FILL_GRAY$1 = "#bbbbbb";
var POLYCHROMA_DARK_STROKE_GRAY$1 = "#eeeeee";
var palette5$1 = {
  fills: Array.from(Object.values(POLYCHROMA_DARK_FILLS$1)),
  strokes: Array.from(Object.values(POLYCHROMA_DARK_STROKES$1))
};
var PolychromaDark$1 = class _PolychromaDark extends DarkTheme$1 {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: POLYCHROMA_DARK_FILLS$1.BLUE,
      stroke: POLYCHROMA_DARK_STROKES$1.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: POLYCHROMA_DARK_FILLS$1.RED,
      stroke: POLYCHROMA_DARK_STROKES$1.RED
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: POLYCHROMA_DARK_FILL_GRAY$1,
      stroke: POLYCHROMA_DARK_STROKE_GRAY$1
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS$1,
      _PolychromaDark.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS$1,
      _PolychromaDark.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS$1,
      _PolychromaDark.getWaterfallSeriesDefaultTotalColors()
    );
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE$1, [
      POLYCHROMA_DARK_FILLS$1.BLUE,
      POLYCHROMA_DARK_FILLS$1.RED
    ]);
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE$1,
      _PolychromaDark.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return result;
  }
  getPalette() {
    return palette5$1;
  }
};

// packages/ag-charts-community/src/chart/themes/polychromaLight.ts
var POLYCHROMA_LIGHT_FILLS$1 = {
  BLUE: "#436ff4",
  PURPLE: "#9a7bff",
  MAGENTA: "#d165d2",
  PINK: "#f0598b",
  RED: "#f47348",
  ORANGE: "#f2a602",
  YELLOW: "#e9e201",
  GREEN: "#21b448",
  CYAN: "#00b9a2",
  MODERATE_BLUE: "#00aee4"
};
var POLYCHROMA_LIGHT_STROKES$1 = {
  BLUE: "#2346c9",
  PURPLE: "#7653d4",
  MAGENTA: "#a73da9",
  PINK: "#c32d66",
  RED: "#c84b1c",
  ORANGE: "#c87f00",
  YELLOW: "#c1b900",
  GREEN: "#008c1c",
  CYAN: "#00927c",
  MODERATE_BLUE: "#0087bb"
};
var POLYCHROMA_LIGHT_FILL_GRAY$1 = "#bbbbbb";
var POLYCHROMA_LIGHT_STROKE_GRAY$1 = "#888888";
var palette6$1 = {
  fills: Array.from(Object.values(POLYCHROMA_LIGHT_FILLS$1)),
  strokes: Array.from(Object.values(POLYCHROMA_LIGHT_STROKES$1))
};
var PolychromaLight$1 = class _PolychromaLight extends ChartTheme$1 {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: POLYCHROMA_LIGHT_FILLS$1.BLUE,
      stroke: POLYCHROMA_LIGHT_STROKES$1.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: POLYCHROMA_LIGHT_FILLS$1.RED,
      stroke: POLYCHROMA_LIGHT_STROKES$1.RED
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: POLYCHROMA_LIGHT_FILL_GRAY$1,
      stroke: POLYCHROMA_LIGHT_STROKE_GRAY$1
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS$1,
      _PolychromaLight.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS$1,
      _PolychromaLight.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS$1,
      _PolychromaLight.getWaterfallSeriesDefaultTotalColors()
    );
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE$1, [
      POLYCHROMA_LIGHT_FILLS$1.BLUE,
      POLYCHROMA_LIGHT_FILLS$1.RED
    ]);
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE$1,
      _PolychromaLight.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return result;
  }
  getPalette() {
    return palette6$1;
  }
};

// packages/ag-charts-community/src/chart/themes/sheetsDark.ts
var SHEETS_DARK_FILLS$1 = {
  BLUE: "#4472C4",
  ORANGE: "#ED7D31",
  GRAY: "#A5A5A5",
  YELLOW: "#FFC000",
  MODERATE_BLUE: "#5B9BD5",
  GREEN: "#70AD47",
  DARK_GRAY: "#7B7B7B",
  DARK_BLUE: "#264478",
  VERY_DARK_GRAY: "#636363",
  DARK_YELLOW: "#997300"
};
var SHEETS_DARK_STROKES$1 = {
  BLUE: "#6899ee",
  ORANGE: "#ffa55d",
  GRAY: "#cdcdcd",
  YELLOW: "#ffea53",
  MODERATE_BLUE: "#82c3ff",
  GREEN: "#96d56f",
  DARK_GRAY: "#a1a1a1",
  DARK_BLUE: "#47689f",
  VERY_DARK_GRAY: "#878787",
  DARK_YELLOW: "#c0993d"
};
var palette7$1 = {
  fills: Array.from(Object.values(SHEETS_DARK_FILLS$1)),
  strokes: Array.from(Object.values(SHEETS_DARK_STROKES$1))
};
var SheetsDark$1 = class _SheetsDark extends DarkTheme$1 {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: SHEETS_DARK_FILLS$1.BLUE,
      stroke: SHEETS_DARK_STROKES$1.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: SHEETS_DARK_FILLS$1.ORANGE,
      stroke: SHEETS_DARK_STROKES$1.ORANGE
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: SHEETS_DARK_FILLS$1.GRAY,
      stroke: SHEETS_DARK_STROKES$1.GRAY
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS$1,
      _SheetsDark.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS$1,
      _SheetsDark.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS$1,
      _SheetsDark.getWaterfallSeriesDefaultTotalColors()
    );
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE$1, [
      SHEETS_DARK_FILLS$1.ORANGE,
      SHEETS_DARK_FILLS$1.YELLOW,
      SHEETS_DARK_FILLS$1.GREEN
    ]);
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE$1,
      _SheetsDark.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return result;
  }
  getPalette() {
    return palette7$1;
  }
};

// packages/ag-charts-community/src/chart/themes/sheetsLight.ts
var SHEETS_LIGHT_FILLS$1 = {
  BLUE: "#5281d5",
  ORANGE: "#ff8d44",
  GRAY: "#b5b5b5",
  YELLOW: "#ffd02f",
  MODERATE_BLUE: "#6aabe6",
  GREEN: "#7fbd57",
  DARK_GRAY: "#8a8a8a",
  DARK_BLUE: "#335287",
  VERY_DARK_GRAY: "#717171",
  DARK_YELLOW: "#a98220"
};
var SHEETS_LIGHT_STROKES$1 = {
  BLUE: "#214d9b",
  ORANGE: "#c25600",
  GRAY: "#7f7f7f",
  YELLOW: "#d59800",
  MODERATE_BLUE: "#3575ac",
  GREEN: "#4b861a",
  DARK_GRAY: "#575757",
  DARK_BLUE: "#062253",
  VERY_DARK_GRAY: "#414141",
  DARK_YELLOW: "#734f00"
};
var palette8$1 = {
  fills: Array.from(Object.values(SHEETS_LIGHT_FILLS$1)),
  strokes: Array.from(Object.values(SHEETS_LIGHT_STROKES$1))
};
var SheetsLight$1 = class _SheetsLight extends ChartTheme$1 {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: SHEETS_LIGHT_FILLS$1.BLUE,
      stroke: SHEETS_LIGHT_STROKES$1.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: SHEETS_LIGHT_FILLS$1.ORANGE,
      stroke: SHEETS_LIGHT_STROKES$1.ORANGE
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: SHEETS_LIGHT_FILLS$1.GRAY,
      stroke: SHEETS_LIGHT_STROKES$1.GRAY
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS$1,
      _SheetsLight.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS$1,
      _SheetsLight.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS$1,
      _SheetsLight.getWaterfallSeriesDefaultTotalColors()
    );
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE$1, [
      SHEETS_LIGHT_FILLS$1.ORANGE,
      SHEETS_LIGHT_FILLS$1.YELLOW,
      SHEETS_LIGHT_FILLS$1.GREEN
    ]);
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE$1,
      _SheetsLight.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return result;
  }
  getPalette() {
    return palette8$1;
  }
};

// packages/ag-charts-community/src/chart/themes/vividDark.ts
var VIVID_DARK_FILLS$1 = {
  BLUE: "#0083ff",
  ORANGE: "#ff6600",
  GREEN: "#00af00",
  CYAN: "#00ccff",
  YELLOW: "#f7c700",
  VIOLET: "#ac26ff",
  GRAY: "#a7a7b7",
  MAGENTA: "#e800c5",
  BROWN: "#b54300",
  RED: "#ff0000"
};
var VIVID_DARK_STROKES$1 = {
  BLUE: "#67b7ff",
  ORANGE: "#ffc24d",
  GREEN: "#5cc86f",
  CYAN: "#54ebff",
  VIOLET: "#c18aff",
  YELLOW: "#fff653",
  GRAY: "#aeaeae",
  MAGENTA: "#f078d4",
  BROWN: "#ba8438",
  RED: "#ff726e"
};
var palette9$1 = {
  fills: Array.from(Object.values(VIVID_DARK_FILLS$1)),
  strokes: Array.from(Object.values(VIVID_DARK_STROKES$1))
};
var VividDark$1 = class _VividDark extends DarkTheme$1 {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: VIVID_DARK_FILLS$1.BLUE,
      stroke: VIVID_DARK_STROKES$1.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: VIVID_DARK_FILLS$1.ORANGE,
      stroke: VIVID_DARK_STROKES$1.ORANGE
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: VIVID_DARK_FILLS$1.GRAY,
      stroke: VIVID_DARK_STROKES$1.GRAY
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS$1,
      _VividDark.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS$1,
      _VividDark.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS$1, _VividDark.getWaterfallSeriesDefaultTotalColors());
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE$1, [
      VIVID_DARK_FILLS$1.ORANGE,
      VIVID_DARK_FILLS$1.YELLOW,
      VIVID_DARK_FILLS$1.GREEN
    ]);
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE$1,
      _VividDark.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return result;
  }
  getPalette() {
    return palette9$1;
  }
};

// packages/ag-charts-community/src/chart/themes/vividLight.ts
var VIVID_FILLS$1 = {
  BLUE: "#0083ff",
  ORANGE: "#ff6600",
  GREEN: "#00af00",
  CYAN: "#00ccff",
  YELLOW: "#f7c700",
  VIOLET: "#ac26ff",
  GRAY: "#a7a7b7",
  MAGENTA: "#e800c5",
  BROWN: "#b54300",
  RED: "#ff0000"
};
var VIVID_STROKES$1 = {
  BLUE: "#0f68c0",
  ORANGE: "#d47100",
  GREEN: "#007922",
  CYAN: "#009ac2",
  VIOLET: "#bca400",
  YELLOW: "#753cac",
  GRAY: "#646464",
  MAGENTA: "#9b2685",
  BROWN: "#6c3b00",
  RED: "#cb0021"
};
var palette10$1 = {
  fills: Array.from(Object.values(VIVID_FILLS$1)),
  strokes: Array.from(Object.values(VIVID_STROKES$1))
};
var VividLight$1 = class _VividLight extends ChartTheme$1 {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: VIVID_FILLS$1.BLUE,
      stroke: VIVID_STROKES$1.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: VIVID_FILLS$1.ORANGE,
      stroke: VIVID_STROKES$1.ORANGE
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: VIVID_FILLS$1.GRAY,
      stroke: VIVID_STROKES$1.GRAY
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS$1,
      _VividLight.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS$1,
      _VividLight.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS$1,
      _VividLight.getWaterfallSeriesDefaultTotalColors()
    );
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE$1, [
      VIVID_FILLS$1.ORANGE,
      VIVID_FILLS$1.YELLOW,
      VIVID_FILLS$1.GREEN
    ]);
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE$1,
      _VividLight.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return result;
  }
  getPalette() {
    return palette10$1;
  }
};

// packages/ag-charts-community/src/chart/mapping/themes.ts
var lightTheme$1 = () => new ChartTheme$1();
var darkTheme$1 = () => new DarkTheme$1();
var lightThemes$1 = {
  undefined: lightTheme$1,
  null: lightTheme$1,
  "ag-default": lightTheme$1,
  "ag-sheets": () => new SheetsLight$1(),
  "ag-polychroma": () => new PolychromaLight$1(),
  "ag-vivid": () => new VividLight$1(),
  "ag-material": () => new MaterialLight$1()
};
var darkThemes$1 = {
  undefined: darkTheme$1,
  null: darkTheme$1,
  "ag-default-dark": darkTheme$1,
  "ag-sheets-dark": () => new SheetsDark$1(),
  "ag-polychroma-dark": () => new PolychromaDark$1(),
  "ag-vivid-dark": () => new VividDark$1(),
  "ag-material-dark": () => new MaterialDark$1()
};
var themes$1 = __spreadValues$1(__spreadValues$1({}, darkThemes$1), lightThemes$1);
function validateChartThemeObject$1(unknownObject) {
  if (unknownObject === null) {
    return void 0;
  }
  let valid = true;
  const { baseTheme, palette: palette11, overrides } = unknownObject;
  if (baseTheme !== void 0 && typeof baseTheme !== "string" && typeof baseTheme !== "object") {
    Logger$1.warn(`invalid theme.baseTheme type ${typeof baseTheme}, expected (string | object).`);
    valid = false;
  }
  if (overrides !== void 0 && typeof overrides !== "object") {
    Logger$1.warn(`invalid theme.overrides type ${typeof overrides}, expected object.`);
    valid = false;
  }
  if (typeof palette11 === "object") {
    if (palette11 !== null) {
      const { fills, strokes } = palette11;
      if (fills !== void 0 && !Array.isArray(fills)) {
        Logger$1.warn(`theme.overrides.fills must be undefined or an array`);
        valid = false;
      }
      if (strokes !== void 0 && !Array.isArray(strokes)) {
        Logger$1.warn(`theme.overrides.strokes must be undefined or an array`);
        valid = false;
      }
    }
  } else if (palette11 !== void 0) {
    Logger$1.warn(`invalid theme.palette type ${typeof palette11}, expected object.`);
    valid = false;
  }
  if (valid) {
    return unknownObject;
  }
  return void 0;
}
function validateChartTheme$1(value) {
  if (value === void 0 || typeof value === "string" || value instanceof ChartTheme$1) {
    return value;
  }
  if (typeof value === "object") {
    return validateChartThemeObject$1(value);
  }
  Logger$1.warn(`invalid theme value type ${typeof value}, expected object.`);
  return void 0;
}
function getChartTheme$1(unvalidatedValue) {
  var _a;
  let value = validateChartTheme$1(unvalidatedValue);
  if (value instanceof ChartTheme$1) {
    return value;
  }
  if (value === void 0 || typeof value === "string") {
    const stockTheme = themes$1[value];
    if (stockTheme) {
      return stockTheme();
    }
    Logger$1.warnOnce(`the theme [${value}] is invalid, using [ag-default] instead.`);
    return lightTheme$1();
  }
  const overrides = [];
  let palette11;
  while (typeof value === "object") {
    overrides.push((_a = value.overrides) != null ? _a : {});
    if (value.palette && palette11 == null) {
      palette11 = value.palette;
    }
    value = value.baseTheme;
  }
  overrides.reverse();
  const flattenedTheme = __spreadValues$1({
    baseTheme: value,
    overrides: jsonMerge$1(overrides)
  }, palette11 ? { palette: palette11 } : {});
  const baseTheme = flattenedTheme.baseTheme ? getChartTheme$1(flattenedTheme.baseTheme) : lightTheme$1();
  return new baseTheme.constructor(flattenedTheme);
}

// packages/ag-charts-community/src/chart/mapping/prepare.ts
function takeColours(context, colours, maxCount) {
  const result = [];
  for (let count2 = 0; count2 < maxCount; count2++) {
    result.push(colours[(count2 + context.colourIndex) % colours.length]);
  }
  return result;
}
var noDataCloneMergeOptions = {
  avoidDeepClone: ["data"]
};
function getGlobalTooltipPositionOptions(position) {
  if (position === void 0 || typeof position !== "object" || position === null) {
    return {};
  }
  const { type, xOffset, yOffset } = position;
  const AgTooltipPositionTypeMap = { pointer: true, node: true };
  const result = {};
  const isTooltipPositionType = (value) => Object.keys(AgTooltipPositionTypeMap).includes(value);
  if (typeof type === "string" && isTooltipPositionType(type)) {
    result.type = type;
  }
  if (typeof xOffset === "number" && !isNaN(xOffset) && isFinite(xOffset)) {
    result.xOffset = xOffset;
  }
  if (typeof yOffset === "number" && !isNaN(yOffset) && isFinite(yOffset)) {
    result.yOffset = yOffset;
  }
  return result;
}
function prepareOptions(options) {
  var _a, _b, _c, _d;
  sanityCheckOptions(options);
  const type = optionsType(options);
  const checkSeriesType = (type2) => {
    if (type2 != null && !(isSeriesOptionType(type2) || isEnterpriseSeriesType(type2) || getSeriesDefaults(type2))) {
      throw new Error(`AG Charts - unknown series type: ${type2}; expected one of: ${CHART_TYPES$1.seriesTypes}`);
    }
  };
  for (const { type: seriesType } of (_a = options.series) != null ? _a : []) {
    if (seriesType == null)
      continue;
    checkSeriesType(seriesType);
  }
  options = validateSoloSeries(options);
  let defaultSeriesType = "line";
  if (isAgCartesianChartOptions(options)) {
    defaultSeriesType = "line";
  } else if (isAgHierarchyChartOptions(options)) {
    defaultSeriesType = "treemap";
  } else if (isAgPolarChartOptions(options)) {
    defaultSeriesType = "pie";
  }
  let defaultOverrides = getSeriesDefaults(type);
  if (isDefaultAxisSwapNeeded(options)) {
    defaultOverrides = swapAxes(defaultOverrides);
  }
  defaultOverrides = executeCustomDefaultsFunctions(options, defaultOverrides);
  const conflictOverrides = resolveModuleConflicts(options);
  removeDisabledOptions(options);
  const globalTooltipPositionOptions = getGlobalTooltipPositionOptions((_b = options.tooltip) == null ? void 0 : _b.position);
  const { context, mergedOptions, axesThemes, seriesThemes, theme } = prepareMainOptions(
    defaultOverrides,
    options,
    conflictOverrides
  );
  mergedOptions.series = processSeriesOptions(
    mergedOptions,
    ((_c = mergedOptions.series) != null ? _c : []).map((s) => {
      var _a2;
      const type2 = (_a2 = s.type) != null ? _a2 : defaultSeriesType;
      const mergedSeries = mergeSeriesOptions(s, type2, seriesThemes, globalTooltipPositionOptions);
      if (type2 === "pie") {
        preparePieOptions(seriesThemes.pie, s, mergedSeries);
      }
      return mergedSeries;
    })
  ).map((s) => prepareSeries(context, s)).map((s) => theme.templateTheme(s));
  const checkAxisType = (type2) => {
    const isAxisType = isAxisOptionType(type2);
    if (!isAxisType) {
      Logger$1.warnOnce(`unknown axis type: ${type2}; expected one of: ${AXIS_TYPES$1.axesTypes}, ignoring.`);
    }
    return isAxisType;
  };
  if ("axes" in mergedOptions) {
    let validAxesTypes = true;
    for (const { type: axisType } of (_d = mergedOptions.axes) != null ? _d : []) {
      validAxesTypes && (validAxesTypes = checkAxisType(axisType));
    }
    const axisSource = validAxesTypes ? mergedOptions.axes : defaultOverrides.axes;
    mergedOptions.axes = axisSource == null ? void 0 : axisSource.map((axis) => {
      var _a2, _b2, _c2, _d2, _e;
      const axisType = axis.type;
      let axisDefaults;
      if (validAxesTypes) {
        axisDefaults = (_a2 = defaultOverrides.axes) == null ? void 0 : _a2.find(
          ({ type: type2 }) => type2 === axisType
        );
      }
      const axesTheme = jsonMerge$1([
        (_b2 = axesThemes[axisType]) != null ? _b2 : {},
        (_e = (_d2 = axesThemes[axisType]) == null ? void 0 : _d2[(_c2 = axis.position) != null ? _c2 : "unknown"]) != null ? _e : {},
        axisDefaults
      ]);
      return prepareAxis(axis, axesTheme);
    });
    prepareLegendEnabledOption(options, mergedOptions);
  }
  prepareEnabledOptions(options, mergedOptions);
  return mergedOptions;
}
function sanityCheckOptions(options) {
  const deprecatedArrayProps = {
    yKeys: "yKey",
    yNames: "yName"
  };
  Object.entries(deprecatedArrayProps).forEach(([oldProp, newProp]) => {
    var _a;
    if ((_a = options.series) == null ? void 0 : _a.some((s) => s[oldProp] != null)) {
      Logger$1.warnOnce(
        `Property [series.${oldProp}] is deprecated, please use [series.${newProp}] and multiple series instead.`
      );
    }
  });
}
function hasSoloSeries(options) {
  return options.some((series) => isSoloSeries(series.type));
}
function validateSoloSeries(options) {
  if (options.series === void 0 || options.series.length <= 1 || !hasSoloSeries(options.series)) {
    return options;
  }
  let series = [...options.series];
  if (isSoloSeries(series[0].type)) {
    Logger$1.warn(
      `series[0] of type '${series[0].type}' is incompatible with other series types. Only processing series[0]`
    );
    series = series.slice(0, 1);
  } else {
    const rejects = Array.from(new Set(series.filter((s) => isSoloSeries(s.type)).map((s) => s.type)));
    Logger$1.warnOnce(`Unable to mix these series types with the lead series type: ${rejects}`);
    series = series.filter((s) => !isSoloSeries(s.type));
  }
  return __spreadProps$1(__spreadValues$1({}, options), { series });
}
function mergeSeriesOptions(series, type, seriesThemes, globalTooltipPositionOptions) {
  var _a, _b;
  const mergedTooltipPosition = jsonMerge$1(
    [__spreadValues$1({}, globalTooltipPositionOptions), (_a = series.tooltip) == null ? void 0 : _a.position],
    noDataCloneMergeOptions
  );
  return jsonMerge$1(
    [
      (_b = seriesThemes[type]) != null ? _b : {},
      __spreadProps$1(__spreadValues$1({}, series), { type, tooltip: __spreadProps$1(__spreadValues$1({}, series.tooltip), { position: mergedTooltipPosition }) })
    ],
    noDataCloneMergeOptions
  );
}
function prepareMainOptions(defaultOverrides, options, conflictOverrides) {
  const { theme, cleanedTheme, axesThemes, seriesThemes, userPalette: partialPalette } = prepareTheme(options);
  const userPalette = resolvePartialPalette$1(partialPalette, theme.palette);
  const context = { colourIndex: 0, palette: theme.palette, userPalette, theme };
  defaultOverrides = theme.templateTheme(defaultOverrides);
  const mergedOptions = jsonMerge$1(
    [defaultOverrides, cleanedTheme, options, conflictOverrides],
    noDataCloneMergeOptions
  );
  if (!enterpriseModule$1.isEnterprise) {
    removeUsedEnterpriseOptions(mergedOptions);
  }
  return { context, mergedOptions, axesThemes, seriesThemes, theme };
}
function prepareTheme(options) {
  var _a;
  const theme = getChartTheme$1(options.theme);
  const themeConfig = theme.config[optionsType(options)];
  const seriesThemes = Object.entries(theme.config).reduce((result, [seriesType, { series }]) => {
    result[seriesType] = series;
    return result;
  }, {});
  const userTheme = options.theme;
  const userPalette = typeof userTheme === "object" && userTheme.palette ? userTheme.palette : null;
  return {
    theme,
    axesThemes: (_a = themeConfig == null ? void 0 : themeConfig["axes"]) != null ? _a : {},
    seriesThemes,
    cleanedTheme: jsonMerge$1([themeConfig != null ? themeConfig : {}, { axes: DELETE$1, series: DELETE$1 }]),
    userPalette
  };
}
function prepareSeries(context, input, ...defaults) {
  const paletteOptions = calculateSeriesPalette(context, input);
  const removeOptions = { stacked: DELETE$1, grouped: DELETE$1 };
  return jsonMerge$1([...defaults, paletteOptions, input, removeOptions], noDataCloneMergeOptions);
}
function calculateSeriesPalette(context, input) {
  const paletteFactory = getSeriesPaletteFactory(input.type);
  if (!paletteFactory) {
    return {};
  }
  const {
    palette: { fills = [], strokes = [] },
    userPalette,
    theme
  } = context;
  const colorsCount = Math.max(fills.length, strokes.length);
  return paletteFactory({
    userPalette,
    themeTemplateParameters: theme.getTemplateParameters(),
    colorsCount,
    takeColors: (count2) => {
      const colors = {
        fills: takeColours(context, fills, count2),
        strokes: takeColours(context, strokes, count2)
      };
      context.colourIndex += count2;
      return colors;
    }
  });
}
function prepareAxis(axis, axisTheme) {
  var _a, _b;
  const removeOptions = { top: DELETE$1, bottom: DELETE$1, left: DELETE$1, right: DELETE$1 };
  if (axis.crossLines) {
    if (!Array.isArray(axis.crossLines)) {
      Logger$1.warn("axis[].crossLines should be an array.");
      axis.crossLines = [];
    }
    axis.crossLines = axis.crossLines.map((crossLine) => {
      var _a2;
      return jsonMerge$1([(_a2 = axisTheme.crossLines) != null ? _a2 : {}, crossLine]);
    });
  }
  const gridLineStyle = (_a = axisTheme.gridLine) == null ? void 0 : _a.style;
  if (((_b = axis.gridLine) == null ? void 0 : _b.style) !== void 0 && gridLineStyle !== void 0 && gridLineStyle.length > 0) {
    if (!Array.isArray(axis.gridLine.style)) {
      Logger$1.warn("axis[].gridLine.style should be an array.");
      axis.gridLine.style = [];
    }
    axis.gridLine.style = axis.gridLine.style.map((userStyle, index) => {
      if (userStyle.stroke === void 0 && userStyle.lineDash === void 0) {
        return userStyle;
      }
      const themeStyle = gridLineStyle[index % gridLineStyle.length];
      return jsonMerge$1([themeStyle, userStyle]);
    });
  }
  const cleanTheme = { crossLines: DELETE$1 };
  return jsonMerge$1([axisTheme, cleanTheme, axis, removeOptions], noDataCloneMergeOptions);
}
function removeDisabledOptions(options) {
  jsonWalk$1(
    options,
    (userOptionsNode) => {
      if ("enabled" in userOptionsNode && userOptionsNode.enabled === false) {
        Object.keys(userOptionsNode).forEach((key) => {
          if (key === "enabled")
            return;
          delete userOptionsNode[key];
        });
      }
    },
    { skip: ["data", "theme"] }
  );
}
function prepareLegendEnabledOption(options, mergedOptions) {
  var _a, _b, _c, _d;
  if (!isDefined$1((_a = options.legend) == null ? void 0 : _a.enabled) && !isDefined$1((_b = mergedOptions.legend) == null ? void 0 : _b.enabled)) {
    (_c = mergedOptions.legend) != null ? _c : mergedOptions.legend = {};
    mergedOptions.legend.enabled = ((_d = options.series) != null ? _d : []).length > 1;
  }
}
function prepareEnabledOptions(options, mergedOptions) {
  jsonWalk$1(
    options,
    (visitingUserOpts, visitingMergedOpts) => {
      if (visitingMergedOpts && "enabled" in visitingMergedOpts && !visitingMergedOpts._enabledFromTheme && visitingUserOpts.enabled == null) {
        visitingMergedOpts.enabled = true;
      }
    },
    { skip: ["data", "theme"] },
    mergedOptions
  );
  jsonWalk$1(
    mergedOptions,
    (visitingMergedOpts) => {
      if (visitingMergedOpts._enabledFromTheme != null) {
        delete visitingMergedOpts._enabledFromTheme;
      }
    },
    { skip: ["data", "theme"] }
  );
}
function preparePieOptions(pieSeriesTheme, seriesOptions, mergedSeries) {
  if (isArray$1(seriesOptions.innerLabels)) {
    mergedSeries.innerLabels = seriesOptions.innerLabels.map(
      (innerLabel) => jsonMerge$1([pieSeriesTheme.innerLabels, innerLabel])
    );
  } else {
    mergedSeries.innerLabels = DELETE$1;
  }
}

// packages/ag-charts-community/src/chart/mapping/prepareAxis.ts
var CARTESIAN_AXIS_POSITIONS2 = ["top", "right", "bottom", "left"];
var CARTESIAN_AXIS_TYPES2 = ["category", "grouped-category", "number", "log", "time"];
function hasCartesianAxisPosition(axis) {
  const allowedTypes = CARTESIAN_AXIS_TYPES2;
  return allowedTypes.includes(axis.type);
}
function isCartesianAxisOptions(options) {
  const allowedTypes = CARTESIAN_AXIS_TYPES2;
  return allowedTypes.includes(options.type);
}
function isAxisPosition(position) {
  const allowedPositions = CARTESIAN_AXIS_POSITIONS2;
  return typeof position === "string" && allowedPositions.includes(position);
}
var AxisPositionGuesser = class {
  constructor() {
    this.result = [];
    this.valid = [];
    this.invalid = [];
  }
  push(axis, options) {
    const { result, valid, invalid } = this;
    if (isCartesianAxisOptions(options)) {
      if (isAxisPosition(options.position)) {
        valid.push(axis);
      } else {
        invalid.push(axis);
      }
    }
    result.push(axis);
  }
  guessInvalidPositions() {
    const takenPosition = this.valid.filter((v) => hasCartesianAxisPosition(v)).map((v) => v.position).filter((v) => v !== void 0);
    const guesses = ["top", "right", "bottom", "left"];
    for (const invalidAxis of this.invalid) {
      let nextGuess = guesses.pop();
      while (takenPosition.includes(nextGuess) && nextGuess !== void 0) {
        nextGuess = guesses.pop();
      }
      if (nextGuess === void 0)
        break;
      invalidAxis.position = nextGuess;
    }
    return this.result;
  }
};

// packages/ag-charts-community/src/chart/polarChart.ts
var _PolarChart$1 = class _PolarChart extends Chart$1 {
  constructor(specialOverrides, resources) {
    super(specialOverrides, resources);
    this.padding = new Padding$2(40);
    this.axisGroup.zIndex = 5 /* AXIS_FOREGROUND_ZINDEX */;
  }
  performLayout() {
    return __async$1(this, null, function* () {
      const shrinkRect = yield __superGet$1(_PolarChart.prototype, this, "performLayout").call(this);
      const fullSeriesRect = shrinkRect.clone();
      this.computeSeriesRect(shrinkRect);
      yield this.computeCircle(shrinkRect);
      this.axes.forEach((axis) => axis.update());
      this.hoverRect = shrinkRect;
      this.layoutService.dispatchLayoutComplete({
        type: "layout-complete",
        chart: { width: this.scene.width, height: this.scene.height },
        clipSeries: false,
        series: { rect: fullSeriesRect, paddedRect: shrinkRect, visible: true },
        axes: []
      });
      return shrinkRect;
    });
  }
  updateAxes(cx, cy, radius) {
    var _a, _b;
    const angleAxis = this.axes.find((axis) => axis.direction === "x" /* X */);
    const radiusAxis = this.axes.find((axis) => axis.direction === "y" /* Y */);
    if (!(angleAxis instanceof PolarAxis$1) || !(radiusAxis instanceof PolarAxis$1)) {
      return;
    }
    const angleScale = angleAxis.scale;
    const angles = (_a = angleScale.ticks) == null ? void 0 : _a.call(angleScale).map((value) => angleScale.convert(value));
    const innerRadiusRatio = radiusAxis.innerRadiusRatio;
    angleAxis.innerRadiusRatio = innerRadiusRatio;
    (_b = angleAxis.computeRange) == null ? void 0 : _b.call(angleAxis);
    angleAxis.gridLength = radius;
    radiusAxis.gridAngles = angles;
    radiusAxis.gridRange = angleAxis.range;
    radiusAxis.range = [radius, radius * innerRadiusRatio];
    [angleAxis, radiusAxis].forEach((axis) => {
      axis.translation.x = cx;
      axis.translation.y = cy;
      axis.calculateLayout();
    });
  }
  computeSeriesRect(shrinkRect) {
    const {
      seriesArea: { padding }
    } = this;
    shrinkRect.shrink(padding.left, "left");
    shrinkRect.shrink(padding.top, "top");
    shrinkRect.shrink(padding.right, "right");
    shrinkRect.shrink(padding.bottom, "bottom");
    this.seriesRect = shrinkRect;
    this.animationRect = shrinkRect;
  }
  computeCircle(seriesBox) {
    return __async$1(this, null, function* () {
      const polarSeries = this.series.filter((series) => {
        return series instanceof PolarSeries$1;
      });
      const polarAxes = this.axes.filter((axis) => {
        return axis instanceof PolarAxis$1;
      });
      const setSeriesCircle = (cx, cy, r) => {
        this.updateAxes(cx, cy, r);
        polarSeries.forEach((series) => {
          series.centerX = cx;
          series.centerY = cy;
          series.radius = r;
        });
        const pieSeries = polarSeries.filter((s) => s instanceof PieSeries$1);
        if (pieSeries.length > 1) {
          const innerRadii = pieSeries.map((series) => {
            const innerRadius = series.getInnerRadius();
            return { series, innerRadius };
          }).sort((a, b) => a.innerRadius - b.innerRadius);
          innerRadii[innerRadii.length - 1].series.surroundingRadius = void 0;
          for (let i = 0; i < innerRadii.length - 1; i++) {
            innerRadii[i].series.surroundingRadius = innerRadii[i + 1].innerRadius;
          }
        }
      };
      const centerX = seriesBox.x + seriesBox.width / 2;
      const centerY = seriesBox.y + seriesBox.height / 2;
      const initialRadius = Math.max(0, Math.min(seriesBox.width, seriesBox.height) / 2);
      let radius = initialRadius;
      setSeriesCircle(centerX, centerY, radius);
      const shake = (..._0) => __async$1(this, [..._0], function* ({ hideWhenNecessary = false } = {}) {
        const labelBoxes = [];
        for (const series of [...polarAxes, ...polarSeries]) {
          const box = yield series.computeLabelsBBox({ hideWhenNecessary }, seriesBox);
          if (box) {
            labelBoxes.push(box);
          }
        }
        if (labelBoxes.length === 0) {
          setSeriesCircle(centerX, centerY, initialRadius);
          return;
        }
        const labelBox = BBox$1.merge(labelBoxes);
        const refined = this.refineCircle(labelBox, radius, seriesBox);
        setSeriesCircle(refined.centerX, refined.centerY, refined.radius);
        if (refined.radius === radius) {
          return;
        }
        radius = refined.radius;
      });
      yield shake();
      yield shake();
      yield shake();
      yield shake({ hideWhenNecessary: true });
      yield shake({ hideWhenNecessary: true });
      return { radius, centerX, centerY };
    });
  }
  refineCircle(labelsBox, radius, seriesBox) {
    const minCircleRatio = 0.5;
    const circleLeft = -radius;
    const circleTop = -radius;
    const circleRight = radius;
    const circleBottom = radius;
    let padLeft = Math.max(0, circleLeft - labelsBox.x);
    let padTop = Math.max(0, circleTop - labelsBox.y);
    let padRight = Math.max(0, labelsBox.x + labelsBox.width - circleRight);
    let padBottom = Math.max(0, labelsBox.y + labelsBox.height - circleBottom);
    padLeft = padRight = Math.max(padLeft, padRight);
    padTop = padBottom = Math.max(padTop, padBottom);
    const availCircleWidth = seriesBox.width - padLeft - padRight;
    const availCircleHeight = seriesBox.height - padTop - padBottom;
    let newRadius = Math.min(availCircleWidth, availCircleHeight) / 2;
    const minHorizontalRadius = minCircleRatio * seriesBox.width / 2;
    const minVerticalRadius = minCircleRatio * seriesBox.height / 2;
    const minRadius = Math.min(minHorizontalRadius, minVerticalRadius);
    if (newRadius < minRadius) {
      newRadius = minRadius;
      const horizontalPadding = padLeft + padRight;
      const verticalPadding = padTop + padBottom;
      if (2 * newRadius + verticalPadding > seriesBox.height) {
        const padHeight = seriesBox.height - 2 * newRadius;
        if (Math.min(padTop, padBottom) * 2 > padHeight) {
          padTop = padHeight / 2;
          padBottom = padHeight / 2;
        } else if (padTop > padBottom) {
          padTop = padHeight - padBottom;
        } else {
          padBottom = padHeight - padTop;
        }
      }
      if (2 * newRadius + horizontalPadding > seriesBox.width) {
        const padWidth = seriesBox.width - 2 * newRadius;
        if (Math.min(padLeft, padRight) * 2 > padWidth) {
          padLeft = padWidth / 2;
          padRight = padWidth / 2;
        } else if (padLeft > padRight) {
          padLeft = padWidth - padRight;
        } else {
          padRight = padWidth - padLeft;
        }
      }
    }
    const newWidth = padLeft + 2 * newRadius + padRight;
    const newHeight = padTop + 2 * newRadius + padBottom;
    return {
      centerX: seriesBox.x + (seriesBox.width - newWidth) / 2 + padLeft + newRadius,
      centerY: seriesBox.y + (seriesBox.height - newHeight) / 2 + padTop + newRadius,
      radius: newRadius
    };
  }
};
_PolarChart$1.className = "PolarChart";
_PolarChart$1.type = "polar";
var PolarChart = _PolarChart$1;

// packages/ag-charts-community/src/chart/agChartV2.ts
var debug = Debug$1.create(true, "opts");
function chartType(options) {
  if (isAgCartesianChartOptions(options)) {
    return "cartesian";
  } else if (isAgPolarChartOptions(options)) {
    return "polar";
  } else if (isAgHierarchyChartOptions(options)) {
    return "hierarchy";
  }
  throw new Error(`AG Chart - unknown type of chart for options with type: ${options.type}`);
}
var _AgCharts = class _AgCharts {
  static licenseCheck(options) {
    var _a, _b, _c, _d;
    if (this.licenseChecked)
      return;
    this.licenseManager = (_b = (_a = enterpriseModule$1).licenseManager) == null ? void 0 : _b.call(_a, options);
    (_c = this.licenseManager) == null ? void 0 : _c.setLicenseKey(this.licenseKey);
    (_d = this.licenseManager) == null ? void 0 : _d.validateLicense();
    this.licenseChecked = true;
  }
  static setLicenseKey(licenseKey) {
    this.licenseKey = licenseKey;
  }
  /**
   * Returns the `AgChartInstance` for a DOM node, if there is one.
   */
  static getInstance(element2) {
    return AgChartsInternal.getInstance(element2);
  }
  /**
   * Create a new `AgChartInstance` based upon the given configuration options.
   */
  static create(options) {
    var _a, _b, _c, _d;
    this.licenseCheck(options);
    const chart = AgChartsInternal.createOrUpdate(options);
    if ((_a = this.licenseManager) == null ? void 0 : _a.isDisplayWatermark()) {
      (_d = (_c = enterpriseModule$1).injectWatermark) == null ? void 0 : _d.call(
        _c,
        (_b = options.document) != null ? _b : document,
        chart.chart.element,
        this.licenseManager.getWatermarkMessage()
      );
    }
    return chart;
  }
  /**
   * Update an existing `AgChartInstance`. Options provided should be complete and not
   * partial.
   *
   * __NOTE__: As each call could trigger a chart redraw, multiple calls to update options in
   * quick succession could result in undesirable flickering, so callers should batch up and/or
   * debounce changes to avoid unintended partial update renderings.
   */
  static update(chart, options) {
    if (!AgChartInstanceProxy.isInstance(chart)) {
      throw new Error(_AgCharts.INVALID_CHART_REF_MESSAGE);
    }
    AgChartsInternal.createOrUpdate(options, chart);
  }
  /**
   * Update an existing `AgChartInstance` by applying a partial set of option changes.
   *
   * __NOTE__: As each call could trigger a chart redraw, each individual delta options update
   * should leave the chart in a valid options state. Also, multiple calls to update options in
   * quick succession could result in undesirable flickering, so callers should batch up and/or
   * debounce changes to avoid unintended partial update renderings.
   */
  static updateDelta(chart, deltaOptions) {
    if (!AgChartInstanceProxy.isInstance(chart)) {
      throw new Error(_AgCharts.INVALID_CHART_REF_MESSAGE);
    }
    AgChartsInternal.updateUserDelta(chart, deltaOptions);
  }
  /**
   * Starts a browser-based image download for the given `AgChartInstance`.
   */
  static download(chart, options) {
    if (!(chart instanceof AgChartInstanceProxy)) {
      throw new Error(_AgCharts.INVALID_CHART_REF_MESSAGE);
    }
    AgChartsInternal.download(chart, options);
  }
  /**
   * Returns a base64-encoded image data URL for the given `AgChartInstance`.
   */
  static getImageDataURL(chart, options) {
    if (!(chart instanceof AgChartInstanceProxy)) {
      throw new Error(_AgCharts.INVALID_CHART_REF_MESSAGE);
    }
    return AgChartsInternal.getImageDataURL(chart, options);
  }
};
_AgCharts.INVALID_CHART_REF_MESSAGE = "AG Charts - invalid chart reference passed";
_AgCharts.licenseChecked = false;
var AgCharts = _AgCharts;
var AgChart = class _AgChart {
  static warnDeprecated(memberName) {
    const warnDeprecated = createDeprecationWarning$1();
    warnDeprecated(`AgChart.${memberName}`, `Use AgCharts.${memberName} instead`);
  }
  static create(options) {
    _AgChart.warnDeprecated("create");
    return AgCharts.create(options);
  }
  static update(chart, options) {
    _AgChart.warnDeprecated("update");
    return AgCharts.update(chart, options);
  }
  static updateDelta(chart, deltaOptions) {
    _AgChart.warnDeprecated("updateDelta");
    return AgCharts.updateDelta(chart, deltaOptions);
  }
  static download(chart, options) {
    _AgChart.warnDeprecated("download");
    return AgCharts.download(chart, options);
  }
  static getImageDataURL(chart, options) {
    _AgChart.warnDeprecated("getImageDataURL");
    return AgCharts.getImageDataURL(chart, options);
  }
};
var proxyInstances = /* @__PURE__ */ new WeakMap();
var _AgChartsInternal = class _AgChartsInternal {
  static getInstance(element2) {
    const chart = Chart$1.getInstance(element2);
    return chart != null ? proxyInstances.get(chart) : void 0;
  }
  static initialiseModules() {
    if (_AgChartsInternal.initialised)
      return;
    registerInbuiltModules();
    setupModules();
    _AgChartsInternal.initialised = true;
  }
  static createOrUpdate(userOptions, proxy) {
    var _b;
    _AgChartsInternal.initialiseModules();
    debug(">>> AgChartV2.createOrUpdate() user options", userOptions);
    const _a = userOptions, { overrideDevicePixelRatio, document: document2, window: userWindow } = _a, chartOptions = __objRest$1(_a, ["overrideDevicePixelRatio", "document", "window"]);
    const specialOverrides = { overrideDevicePixelRatio, document: document2, window: userWindow };
    const processedOptions = prepareOptions(chartOptions);
    let chart = proxy == null ? void 0 : proxy.chart;
    if (chart != null) {
      proxyInstances.delete(chart);
    }
    if (chart == null || chartType(chartOptions) !== chartType(chart.processedOptions)) {
      chart = _AgChartsInternal.createChartInstance(processedOptions, specialOverrides, chart);
    }
    if (proxy == null) {
      proxy = new AgChartInstanceProxy(chart);
    } else {
      proxy.chart = chart;
    }
    proxyInstances.set(chart, proxy);
    if (Debug$1.check() && typeof window !== "undefined") {
      (_b = window.agChartInstances) != null ? _b : window.agChartInstances = {};
      window.agChartInstances[chart.id] = chart;
    }
    const chartToUpdate = chart;
    chartToUpdate.queuedUserOptions.push(chartOptions);
    const dequeue = () => {
      const queuedOptionsIdx = chartToUpdate.queuedUserOptions.indexOf(chartOptions);
      chartToUpdate.queuedUserOptions.splice(0, queuedOptionsIdx);
    };
    chartToUpdate.requestFactoryUpdate(() => __async$1(this, null, function* () {
      if (chartToUpdate.destroyed)
        return;
      const deltaOptions = jsonDiff$1(chartToUpdate.processedOptions, processedOptions);
      if (deltaOptions == null) {
        dequeue();
        return;
      }
      yield _AgChartsInternal.updateDelta(chartToUpdate, deltaOptions, chartOptions);
      dequeue();
    }));
    return proxy;
  }
  static updateUserDelta(proxy, deltaOptions) {
    var _a;
    const {
      chart,
      chart: { queuedUserOptions }
    } = proxy;
    const lastUpdateOptions = (_a = queuedUserOptions[queuedUserOptions.length - 1]) != null ? _a : chart.userOptions;
    const userOptions = jsonMerge$1([lastUpdateOptions, deltaOptions]);
    debug(">>> AgChartV2.updateUserDelta() user delta", deltaOptions);
    debug("AgChartV2.updateUserDelta() - base options", lastUpdateOptions);
    _AgChartsInternal.createOrUpdate(userOptions, proxy);
  }
  /**
   * Returns the content of the current canvas as an image.
   * @param opts The download options including `width` and `height` of the image as well as `fileName` and `fileFormat`.
   */
  static download(proxy, opts) {
    const asyncDownload = () => __async$1(this, null, function* () {
      const maybeClone = yield _AgChartsInternal.prepareResizedChart(proxy, opts);
      const { chart } = maybeClone;
      chart.scene.download(opts == null ? void 0 : opts.fileName, opts == null ? void 0 : opts.fileFormat);
      if (maybeClone !== proxy) {
        maybeClone.destroy();
      }
    });
    asyncDownload().catch((e) => Logger$1.errorOnce(e));
  }
  static getImageDataURL(proxy, opts) {
    return __async$1(this, null, function* () {
      const maybeClone = yield _AgChartsInternal.prepareResizedChart(proxy, opts);
      const { chart } = maybeClone;
      const result = chart.scene.canvas.getDataURL(opts == null ? void 0 : opts.fileFormat);
      if (maybeClone !== proxy) {
        maybeClone.destroy();
      }
      return result;
    });
  }
  static prepareResizedChart(proxy, opts) {
    return __async$1(this, null, function* () {
      var _a;
      const { chart } = proxy;
      let { width, height } = opts != null ? opts : {};
      const currentWidth = chart.width;
      const currentHeight = chart.height;
      const unchanged = width === void 0 && height === void 0 || chart.scene.canvas.pixelRatio === 1 && currentWidth === width && currentHeight === height;
      if (unchanged) {
        return proxy;
      }
      width != null ? width : width = currentWidth;
      height != null ? height : height = currentHeight;
      const options = __spreadProps$1(__spreadValues$1({}, chart.userOptions), {
        container: document.createElement("div"),
        width,
        height,
        autoSize: false,
        overrideDevicePixelRatio: 1
      });
      if (hasRegisteredEnterpriseModules$1()) {
        (_a = options.animation) != null ? _a : options.animation = {};
        options.animation.enabled = false;
      }
      const clonedChart = _AgChartsInternal.createOrUpdate(options);
      yield clonedChart.chart.waitForUpdate();
      return clonedChart;
    });
  }
  static createChartInstance(options, specialOverrides, oldChart) {
    const transferableResource = oldChart == null ? void 0 : oldChart.destroy({ keepTransferableResources: true });
    if (isAgCartesianChartOptions(options)) {
      return new CartesianChart(specialOverrides, transferableResource);
    } else if (isAgHierarchyChartOptions(options)) {
      return new HierarchyChart(specialOverrides, transferableResource);
    } else if (isAgPolarChartOptions(options)) {
      return new PolarChart(specialOverrides, transferableResource);
    }
    throw new Error(
      `AG Charts - couldn't apply configuration, check options are correctly structured and series types are specified`
    );
  }
  static updateDelta(chart, processedOptions, userOptions) {
    return __async$1(this, null, function* () {
      var _a;
      if (processedOptions.type == null) {
        processedOptions = __spreadProps$1(__spreadValues$1({}, processedOptions), {
          type: (_a = chart.processedOptions.type) != null ? _a : optionsType(processedOptions)
        });
      }
      if (chart.destroyed)
        return;
      debug("AgChartV2.updateDelta() - applying delta", processedOptions);
      applyChartOptions(chart, processedOptions, userOptions);
    });
  }
};
_AgChartsInternal.initialised = false;
var AgChartsInternal = _AgChartsInternal;
function applyChartOptions(chart, processedOptions, userOptions) {
  var _a, _b, _c;
  const completeOptions = jsonMerge$1([(_a = chart.processedOptions) != null ? _a : {}, processedOptions], noDataCloneMergeOptions);
  const modulesChanged = applyModules(chart, completeOptions);
  const skip = ["type", "data", "series", "listeners", "theme", "legend.listeners"];
  if (isAgCartesianChartOptions(processedOptions) || isAgPolarChartOptions(processedOptions)) {
    skip.push("axes");
  } else if (isAgHierarchyChartOptions(processedOptions)) ; else {
    throw new Error(
      `AG Charts - couldn't apply configuration, check type of options and chart: ${processedOptions["type"]}`
    );
  }
  if (processedOptions.listeners) {
    registerListeners(chart, processedOptions.listeners);
  }
  applyOptionValues(chart, chart.getModuleContext(), processedOptions, { skip });
  let forceNodeDataRefresh = false;
  let seriesRecreated = false;
  if (processedOptions.series && processedOptions.series.length > 0) {
    seriesRecreated = applySeries(chart, processedOptions);
    forceNodeDataRefresh = true;
  }
  if ("axes" in completeOptions && Array.isArray(completeOptions.axes)) {
    const axesPresent = applyAxes(chart, completeOptions, seriesRecreated);
    if (axesPresent) {
      forceNodeDataRefresh = true;
    }
  }
  const seriesOpts = processedOptions.series;
  const seriesDataUpdate = !!processedOptions.data || (seriesOpts == null ? void 0 : seriesOpts.some((s) => s.data != null));
  const legendKeys = getLegendKeys();
  const optionsHaveLegend = Object.values(legendKeys).some(
    (legendKey) => processedOptions[legendKey] != null
  );
  const otherRefreshUpdate = processedOptions.title != null && processedOptions.subtitle != null;
  forceNodeDataRefresh = forceNodeDataRefresh || seriesDataUpdate || optionsHaveLegend || otherRefreshUpdate;
  if (processedOptions.data) {
    chart.data = processedOptions.data;
  }
  if ((_b = processedOptions.legend) == null ? void 0 : _b.listeners) {
    Object.assign(chart.legend.listeners, processedOptions.legend.listeners);
  }
  if (processedOptions.listeners) {
    chart.updateAllSeriesListeners();
  }
  chart.processedOptions = completeOptions;
  chart.userOptions = jsonMerge$1([(_c = chart.userOptions) != null ? _c : {}, userOptions], noDataCloneMergeOptions);
  const majorChange = forceNodeDataRefresh || modulesChanged;
  const updateType = majorChange ? 1 /* PROCESS_DATA */ : 2 /* PERFORM_LAYOUT */;
  debug("AgChartV2.applyChartOptions() - update type", ChartUpdateType$1[updateType]);
  chart.update(updateType, { forceNodeDataRefresh, newAnimationBatch: true });
}
function applyModules(chart, options) {
  const matchingChartType = ({ chartTypes }) => chart instanceof CartesianChart && chartTypes.includes("cartesian") || chart instanceof PolarChart && chartTypes.includes("polar") || chart instanceof HierarchyChart && chartTypes.includes("hierarchy");
  let modulesChanged = false;
  for (const module of REGISTERED_MODULES$1) {
    if (module.type !== "root" && module.type !== "legend") {
      continue;
    }
    const shouldBeEnabled = matchingChartType(module) && options[module.optionsKey] != null;
    const isEnabled = chart.isModuleEnabled(module);
    if (shouldBeEnabled === isEnabled) {
      continue;
    }
    if (shouldBeEnabled) {
      chart.addModule(module);
      chart[module.optionsKey] = chart.modules.get(module.optionsKey);
    } else {
      chart.removeModule(module);
      delete chart[module.optionsKey];
    }
    modulesChanged = true;
  }
  return modulesChanged;
}
function applySeries(chart, options) {
  const optSeries = options.series;
  if (!optSeries) {
    return false;
  }
  const keysToConsider = ["direction", "xKey", "yKey", "sizeKey", "angleKey", "stacked", "stackGroup"];
  let matchingTypes = chart.series.length === optSeries.length;
  for (let i = 0; i < chart.series.length && matchingTypes; i++) {
    matchingTypes && (matchingTypes = chart.series[i].type === optSeries[i].type);
    for (const key of keysToConsider) {
      matchingTypes && (matchingTypes = chart.series[i].properties[key] === optSeries[i][key]);
    }
  }
  if (matchingTypes) {
    chart.series.forEach((s, i) => {
      var _a, _b, _c, _d;
      const previousOpts = (_c = (_b = (_a = chart.processedOptions) == null ? void 0 : _a.series) == null ? void 0 : _b[i]) != null ? _c : {};
      const seriesDiff = jsonDiff$1(previousOpts, (_d = optSeries[i]) != null ? _d : {});
      if (!seriesDiff) {
        return;
      }
      debug(`AgChartV2.applySeries() - applying series diff idx ${i}`, seriesDiff);
      applySeriesValues(s, seriesDiff);
      s.markNodeDataDirty();
    });
    return false;
  }
  debug(`AgChartV2.applySeries() - creating new series instances`);
  chart.series = createSeries(chart, optSeries);
  return true;
}
function applyAxes(chart, options, forceRecreate) {
  const optAxes = options.axes;
  if (!optAxes) {
    return false;
  }
  const matchingTypes = !forceRecreate && chart.axes.length === optAxes.length && chart.axes.every((a, i) => a.type === optAxes[i].type);
  if (matchingTypes) {
    const oldOpts = chart.processedOptions;
    const moduleContext = chart.getModuleContext();
    if (isAgCartesianChartOptions(oldOpts)) {
      chart.axes.forEach((a, i) => {
        var _a, _b;
        const previousOpts = (_b = (_a = oldOpts.axes) == null ? void 0 : _a[i]) != null ? _b : {};
        const axisDiff = jsonDiff$1(previousOpts, optAxes[i]);
        debug(`AgChartV2.applyAxes() - applying axis diff idx ${i}`, axisDiff);
        const path = `axes[${i}]`;
        const skip = ["axes[].type"];
        applyOptionValues(a, moduleContext, axisDiff, { path, skip });
      });
      return true;
    }
  }
  chart.axes = createAxis(chart, optAxes);
  return true;
}
function createSeries(chart, options) {
  var _a;
  const series = [];
  const moduleContext = chart.getModuleContext();
  for (const seriesOptions of options != null ? options : []) {
    const type = (_a = seriesOptions.type) != null ? _a : "unknown";
    if (isEnterpriseSeriesType(type) && !isEnterpriseSeriesTypeLoaded(type)) {
      continue;
    }
    const seriesInstance = getSeries(type, moduleContext);
    applySeriesOptionModules(seriesInstance, seriesOptions);
    applySeriesValues(seriesInstance, seriesOptions);
    series.push(seriesInstance);
  }
  return series;
}
function applySeriesOptionModules(series, options) {
  const seriesOptionModules = REGISTERED_MODULES$1.filter((m) => m.type === "series-option");
  const moduleContext = series.createModuleContext();
  const moduleMap = series.getModuleMap();
  for (const module of seriesOptionModules) {
    const supportedSeriesTypes = module.seriesTypes;
    if (module.optionsKey in options && supportedSeriesTypes.includes(series.type)) {
      moduleMap.addModule(module, (module2) => new module2.instanceConstructor(moduleContext));
      series[module.optionsKey] = moduleMap.getModule(module);
    }
  }
}
function createAxis(chart, options) {
  const guesser = new AxisPositionGuesser();
  const moduleContext = chart.getModuleContext();
  const skip = ["axes[].type"];
  let index = 0;
  for (const axisOptions of options != null ? options : []) {
    const axis = getAxis(axisOptions.type, moduleContext);
    const path = `axes[${index++}]`;
    applyAxisModules(axis, axisOptions);
    applyOptionValues(axis, moduleContext, axisOptions, { path, skip });
    guesser.push(axis, axisOptions);
  }
  return guesser.guessInvalidPositions();
}
function applyAxisModules(axis, options) {
  let modulesChanged = false;
  const rootModules = REGISTERED_MODULES$1.filter((m) => m.type === "axis-option");
  const moduleContext = axis.createModuleContext();
  for (const module of rootModules) {
    const shouldBeEnabled = options[module.optionsKey] != null;
    const moduleMap = axis.getModuleMap();
    const isEnabled = moduleMap.isModuleEnabled(module);
    if (shouldBeEnabled === isEnabled)
      continue;
    modulesChanged = true;
    if (shouldBeEnabled) {
      moduleMap.addModule(module, (module2) => new module2.instanceConstructor(moduleContext));
      axis[module.optionsKey] = moduleMap.getModule(module);
    } else {
      moduleMap.removeModule(module);
      delete axis[module.optionsKey];
    }
  }
  return modulesChanged;
}
function registerListeners(source, listeners) {
  source.clearEventListeners();
  const entries = Object.entries(listeners != null ? listeners : {});
  for (const [property, listener] of entries) {
    if (typeof listener !== "function")
      continue;
    source.addEventListener(property, listener);
  }
}
function applyOptionValues(target, moduleContext, options, { skip, path } = {}) {
  const applyOpts = __spreadValues$1(__spreadProps$1(__spreadValues$1({}, getJsonApplyOptions(moduleContext)), {
    skip
  }), path ? { path } : {});
  return jsonApply$1(target, options, applyOpts);
}
function applySeriesValues(target, options) {
  const moduleMap = target.getModuleMap();
  const _a = options, { type, data, errorBar, listeners, seriesGrouping } = _a, seriesOptions = __objRest$1(_a, ["type", "data", "errorBar", "listeners", "seriesGrouping"]);
  target.properties.set(seriesOptions);
  if ("data" in options) {
    target.data = options.data;
  }
  if ("errorBar" in options && moduleMap.isModuleEnabled("errorBar")) {
    moduleMap.getModule("errorBar").properties.set(options.errorBar);
  }
  if ((options == null ? void 0 : options.listeners) != null) {
    registerListeners(target, options.listeners);
  }
  if ("seriesGrouping" in options) {
    target.seriesGrouping = seriesGrouping ? Object.freeze(__spreadValues$1(__spreadValues$1({}, target.seriesGrouping), seriesGrouping)) : void 0;
  }
}

// packages/ag-charts-community/src/version.ts
var VERSION$b = "9.0.2";

// packages/ag-charts-community/src/integrated-charts-theme.ts
var integrated_charts_theme_exports$1 = {};
__export$1(integrated_charts_theme_exports$1, {
  BOTTOM: () => BOTTOM$1,
  CARTESIAN_AXIS_POSITIONS: () => CARTESIAN_AXIS_POSITIONS$1,
  CARTESIAN_AXIS_TYPES: () => CARTESIAN_AXIS_TYPES$1,
  CIRCLE: () => CIRCLE$1,
  ChartTheme: () => ChartTheme$1,
  DEFAULT_AXIS_GRID_COLOUR: () => DEFAULT_AXIS_GRID_COLOUR$1,
  DEFAULT_AXIS_LINE_COLOUR: () => DEFAULT_AXIS_LINE_COLOUR$1,
  DEFAULT_BACKGROUND_COLOUR: () => DEFAULT_BACKGROUND_COLOUR$1,
  DEFAULT_CROSS_LINES_COLOUR: () => DEFAULT_CROSS_LINES_COLOUR$1,
  DEFAULT_DIVERGING_SERIES_COLOUR_RANGE: () => DEFAULT_DIVERGING_SERIES_COLOUR_RANGE$1,
  DEFAULT_FONT_FAMILY: () => DEFAULT_FONT_FAMILY$1,
  DEFAULT_HIERARCHY_FILLS: () => DEFAULT_HIERARCHY_FILLS$1,
  DEFAULT_HIERARCHY_STROKES: () => DEFAULT_HIERARCHY_STROKES$1,
  DEFAULT_INSIDE_SERIES_LABEL_COLOUR: () => DEFAULT_INSIDE_SERIES_LABEL_COLOUR$1,
  DEFAULT_INVERTED_LABEL_COLOUR: () => DEFAULT_INVERTED_LABEL_COLOUR$1,
  DEFAULT_LABEL_COLOUR: () => DEFAULT_LABEL_COLOUR$1,
  DEFAULT_MUTED_LABEL_COLOUR: () => DEFAULT_MUTED_LABEL_COLOUR$1,
  DEFAULT_POLAR_SERIES_STROKE: () => DEFAULT_POLAR_SERIES_STROKE$1,
  DEFAULT_SHADOW_COLOUR: () => DEFAULT_SHADOW_COLOUR$1,
  DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE: () => DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE$1,
  DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS: () => DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS$1,
  DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS: () => DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS$1,
  DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS: () => DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS$1,
  EXTENDS_AXES_DEFAULTS: () => EXTENDS_AXES_DEFAULTS$1,
  EXTENDS_AXES_GRID_LINE_DEFAULTS: () => EXTENDS_AXES_GRID_LINE_DEFAULTS$1,
  EXTENDS_AXES_LABEL_DEFAULTS: () => EXTENDS_AXES_LABEL_DEFAULTS$1,
  EXTENDS_AXES_LINE_DEFAULTS: () => EXTENDS_AXES_LINE_DEFAULTS$1,
  EXTENDS_AXES_TICK_DEFAULTS: () => EXTENDS_AXES_TICK_DEFAULTS$1,
  EXTENDS_CARTESIAN_MARKER_DEFAULTS: () => EXTENDS_CARTESIAN_MARKER_DEFAULTS$1,
  EXTENDS_CHART_DEFAULTS: () => EXTENDS_CHART_DEFAULTS$1,
  EXTENDS_LEGEND_DEFAULTS: () => EXTENDS_LEGEND_DEFAULTS$1,
  EXTENDS_LEGEND_ITEM_DEFAULTS: () => EXTENDS_LEGEND_ITEM_DEFAULTS$1,
  EXTENDS_LEGEND_ITEM_MARKER_DEFAULTS: () => EXTENDS_LEGEND_ITEM_MARKER_DEFAULTS$1,
  EXTENDS_SERIES_DEFAULTS: () => EXTENDS_SERIES_DEFAULTS$1,
  FONT_SIZE: () => FONT_SIZE$1,
  FONT_WEIGHT: () => FONT_WEIGHT2$1,
  OVERRIDE_SERIES_LABEL_DEFAULTS: () => OVERRIDE_SERIES_LABEL_DEFAULTS$1,
  POLAR_AXIS_TYPES: () => POLAR_AXIS_TYPES$1,
  getChartTheme: () => getChartTheme$1,
  themes: () => themes2$1
});
var themes2$1 = Object.entries(themes$1).reduce(
  (obj, [name, factory]) => {
    obj[name] = factory();
    return obj;
  },
  {}
);

// packages/ag-charts-community/src/sparklines-scale.ts
var sparklines_scale_exports$1 = {};
__export$1(sparklines_scale_exports$1, {
  BandScale: () => BandScale$6,
  ColorScale: () => ColorScale$1,
  ContinuousScale: () => ContinuousScale$1,
  Invalidating: () => Invalidating$1,
  LinearScale: () => LinearScale$2,
  TimeScale: () => TimeScale$2
});

var VALID_SERIES_TYPES = [
    'area',
    'bar',
    'column',
    'histogram',
    'line',
    'pie',
    'scatter',
    'bubble'
];
var horizontalChartTypes = new Set(['bar', 'groupedBar', 'stackedBar', 'normalizedBar']);
function isHorizontal(chartType) {
    return horizontalChartTypes.has(chartType);
}
var stackedChartTypes = new Set(['stackedColumn', 'normalizedColumn', 'stackedBar', 'normalizedBar']);
function isStacked(chartType) {
    return stackedChartTypes.has(chartType);
}
function getSeriesType(chartType) {
    switch (chartType) {
        case 'bar':
        case 'groupedBar':
        case 'stackedBar':
        case 'normalizedBar':
            return 'bar';
        case 'column':
        case 'groupedColumn':
        case 'stackedColumn':
        case 'normalizedColumn':
            return 'bar';
        case 'line':
            return 'line';
        case 'area':
        case 'stackedArea':
        case 'normalizedArea':
            return 'area';
        case 'bubble':
            return 'bubble';
        case 'scatter':
            return 'scatter';
        case 'histogram':
            return 'histogram';
        case 'pie':
        case 'doughnut':
            return 'pie';
        default:
            return 'cartesian';
    }
}

var ALL_AXIS_TYPES = ['number', 'category', 'grouped-category', 'log', 'time'];
function getLegacyAxisType(chartType) {
    switch (chartType) {
        case 'bar':
        case 'stackedBar':
        case 'normalizedBar':
            return ['number', 'category'];
        case 'groupedBar':
            return ['number', 'grouped-category'];
        case 'column':
        case 'stackedColumn':
        case 'normalizedColumn':
        case 'line':
        case 'area':
        case 'stackedArea':
        case 'normalizedArea':
        case 'histogram':
            return ['category', 'number'];
        case 'groupedColumn':
            return ['grouped-category', 'number'];
        case 'scatter':
        case 'bubble':
            return ['number', 'number'];
        default:
            return undefined;
    }
}

var __assign$q = (undefined && undefined.__assign) || function () {
    __assign$q = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$q.apply(this, arguments);
};
function createAgChartTheme(chartProxyParams, proxy) {
    var _a;
    var chartOptionsToRestore = chartProxyParams.chartOptionsToRestore, chartPaletteToRestore = chartProxyParams.chartPaletteToRestore, chartThemeToRestore = chartProxyParams.chartThemeToRestore;
    var themeName = getSelectedTheme(chartProxyParams);
    var stockTheme = isStockTheme(themeName);
    var rootTheme = stockTheme
        ? { baseTheme: themeName }
        : (_a = lookupCustomChartTheme(chartProxyParams, themeName)) !== null && _a !== void 0 ? _a : {};
    var gridOptionsThemeOverrides = chartProxyParams.getGridOptionsChartThemeOverrides();
    var apiThemeOverrides = chartProxyParams.apiChartThemeOverrides;
    var standaloneChartType = getSeriesType(chartProxyParams.chartType);
    var crossFilterThemeOverridePoint = standaloneChartType === 'pie' ? 'pie' : 'cartesian';
    var crossFilteringOverrides = chartProxyParams.crossFiltering
        ? createCrossFilterThemeOverrides(proxy, chartProxyParams, crossFilterThemeOverridePoint)
        : undefined;
    var formattingPanelOverrides = __assign$q({}, (chartOptionsToRestore !== null && chartOptionsToRestore !== void 0 ? chartOptionsToRestore : {}));
    var isTitleEnabled = function () {
        var isTitleEnabled = function (obj) {
            if (!obj) {
                return false;
            }
            return Object.keys(obj).some(function (key) { return _.get(obj[key], 'title.enabled', false); });
        };
        return isTitleEnabled(gridOptionsThemeOverrides) || isTitleEnabled(apiThemeOverrides);
    };
    // Overrides in ascending precedence ordering.
    var overrides = [
        stockTheme ? inbuiltStockThemeOverrides(chartProxyParams, isTitleEnabled()) : undefined,
        crossFilteringOverrides,
        gridOptionsThemeOverrides,
        apiThemeOverrides,
        formattingPanelOverrides,
    ];
    // Recursively nest theme overrides so they are applied with correct precedence in
    // Standalone Charts - this is an undocumented feature.
    // Outermost theme overrides will be the formatting panel configured values, so they are
    // differentiated from grid-config and inbuilt overrides.
    var theme = overrides
        .filter(function (v) { return !!v; })
        .reduce(function (r, n) { return ({
        baseTheme: r,
        overrides: n,
    }); }, rootTheme);
    // Avoid explicitly setting the `theme.palette` property unless we're using the restored theme
    // AND the palette is actually different.
    if (chartPaletteToRestore && themeName === chartThemeToRestore) {
        var rootThemePalette = integrated_charts_theme_exports$1.getChartTheme(rootTheme).palette;
        if (!isIdenticalPalette(chartPaletteToRestore, rootThemePalette)) {
            theme.palette = chartPaletteToRestore;
        }
    }
    return theme;
}
function isIdenticalPalette(paletteA, paletteB) {
    var arrayCompare = function (arrA, arrB) {
        if (arrA.length !== arrB.length)
            return false;
        return arrA.every(function (v, i) { return v === arrB[i]; });
    };
    return arrayCompare(paletteA.fills, paletteB.fills) &&
        arrayCompare(paletteA.strokes, paletteB.strokes);
}
function isStockTheme(themeName) {
    return _.includes(Object.keys(integrated_charts_theme_exports$1.themes), themeName);
}
function createCrossFilterThemeOverrides(proxy, chartProxyParams, overrideType) {
    var _a;
    var legend = {
        listeners: {
            legendItemClick: function (e) {
                var chart = proxy.getChart();
                chart.series.forEach(function (s) {
                    s.toggleSeriesItem(e.itemId, e.enabled);
                    s.toggleSeriesItem("".concat(e.itemId, "-filtered-out"), e.enabled);
                });
            },
        },
    };
    var series = {};
    return _a = {},
        _a[overrideType] = {
            tooltip: {
                delay: 500,
            },
            legend: legend,
            listeners: {
                click: function (e) { return chartProxyParams.crossFilterCallback(e, true); },
            },
            series: series,
        },
        _a;
}
var STATIC_INBUILT_STOCK_THEME_AXES_OVERRIDES = ALL_AXIS_TYPES.reduce(function (r, n) {
    var _a;
    return (__assign$q(__assign$q({}, r), (_a = {}, _a[n] = { title: { _enabledFromTheme: true } }, _a)));
}, {});
function inbuiltStockThemeOverrides(params, titleEnabled) {
    var extraPadding = params.getExtraPaddingDirections();
    return {
        common: {
            axes: STATIC_INBUILT_STOCK_THEME_AXES_OVERRIDES,
            padding: {
                // don't add extra padding when a title is present!
                top: !titleEnabled && extraPadding.includes('top') ? 40 : 20,
                right: extraPadding.includes('right') ? 30 : 20,
                bottom: extraPadding.includes('bottom') ? 40 : 20,
                left: extraPadding.includes('left') ? 30 : 20,
            },
        },
        pie: {
            series: {
                title: { _enabledFromTheme: true },
                calloutLabel: { _enabledFromTheme: true },
                sectorLabel: {
                    enabled: false,
                    _enabledFromTheme: true,
                },
            },
        },
    };
}
function getSelectedTheme(chartProxyParams) {
    var chartThemeName = chartProxyParams.getChartThemeName();
    var availableThemes = chartProxyParams.getChartThemes();
    if (!_.includes(availableThemes, chartThemeName)) {
        chartThemeName = availableThemes[0];
    }
    return chartThemeName;
}
function lookupCustomChartTheme(chartProxyParams, name) {
    var customChartThemes = chartProxyParams.customChartThemes;
    var customChartTheme = customChartThemes && customChartThemes[name];
    if (!customChartTheme) {
        console.warn("AG Grid: no stock theme exists with the name '".concat(name, "' and no ") +
            "custom chart theme with that name was supplied to 'customChartThemes'");
    }
    return customChartTheme;
}

var __read$p = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$e = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values$8 = (undefined && undefined.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var validateIfDefined = function (validationFn) {
    return function (value) {
        if (value === undefined)
            return true;
        return validationFn(value);
    };
};
var isString$2 = function (value) { return typeof value === 'string'; };
var isBoolean$1 = function (value) { return typeof value === 'boolean'; };
var isValidSeriesChartType = function (value) { return typeof value === 'object'; };
var createWarnMessage = function (property, expectedType) {
    return function (value) { return "AG Grid - unable to update chart as invalid params supplied:  `".concat(property, ": ").concat(value, "`, expected ").concat(expectedType, "."); };
};
var UpdateParamsValidator = /** @class */ (function () {
    function UpdateParamsValidator() {
    }
    UpdateParamsValidator.validateChartParams = function (params) {
        var paramsToValidate = params;
        switch (paramsToValidate.type) {
            case 'rangeChartUpdate':
                return UpdateParamsValidator.validateUpdateRangeChartParams(params);
            case 'pivotChartUpdate':
                return UpdateParamsValidator.validateUpdatePivotChartParams(params);
            case 'crossFilterChartUpdate':
                return UpdateParamsValidator.validateUpdateCrossFilterChartParams(params);
            default:
                console.warn("AG Grid - Invalid value supplied for 'type': ".concat(params.type, ". It must be either 'rangeChartUpdate', 'pivotChartUpdate', or 'crossFilterChartUpdate'."));
                return false;
        }
    };
    UpdateParamsValidator.validateUpdateRangeChartParams = function (params) {
        var validations = __spreadArray$e(__spreadArray$e(__spreadArray$e([], __read$p(UpdateParamsValidator.commonValidations), false), __read$p(UpdateParamsValidator.cellRangeValidations), false), [
            {
                property: 'seriesChartTypes',
                validationFn: function (value) { return value === undefined || (Array.isArray(value) && value.every(isValidSeriesChartType)); },
                warnMessage: createWarnMessage('seriesChartTypes', 'Array of SeriesChartType'),
            },
        ], false);
        return UpdateParamsValidator.validateProperties(params, validations, ['type', 'chartId', 'chartType', 'chartThemeName', 'chartThemeOverrides', 'unlinkChart', 'cellRange', 'suppressChartRanges', 'aggFunc', 'seriesChartTypes'], 'UpdateRangeChartParams');
    };
    UpdateParamsValidator.validateUpdatePivotChartParams = function (params) {
        var validations = __spreadArray$e([], __read$p(UpdateParamsValidator.commonValidations), false);
        return UpdateParamsValidator.validateProperties(params, validations, ['type', 'chartId', 'chartType', 'chartThemeName', 'chartThemeOverrides', 'unlinkChart'], 'UpdatePivotChartParams');
    };
    UpdateParamsValidator.validateUpdateCrossFilterChartParams = function (params) {
        var validations = __spreadArray$e(__spreadArray$e([], __read$p(UpdateParamsValidator.commonValidations), false), __read$p(UpdateParamsValidator.cellRangeValidations), false);
        return UpdateParamsValidator.validateProperties(params, validations, ['type', 'chartId', 'chartType', 'chartThemeName', 'chartThemeOverrides', 'unlinkChart', 'cellRange', 'suppressChartRanges', 'aggFunc'], 'UpdateCrossFilterChartParams');
    };
    UpdateParamsValidator.validateProperties = function (params, validations, validPropertyNames, paramsType) {
        var e_1, _a;
        try {
            for (var validations_1 = __values$8(validations), validations_1_1 = validations_1.next(); !validations_1_1.done; validations_1_1 = validations_1.next()) {
                var validation = validations_1_1.value;
                var property = validation.property, validationFn = validation.validationFn, warnMessage = validation.warnMessage;
                if (property in params) {
                    var value = params[property];
                    if (!validationFn(value)) {
                        console.warn(warnMessage(value));
                        return false;
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (validations_1_1 && !validations_1_1.done && (_a = validations_1.return)) _a.call(validations_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        // Check for unexpected properties
        for (var property in params) {
            if (!validPropertyNames.includes(property)) {
                console.warn("AG Grid - Unexpected property supplied. ".concat(paramsType, " does not contain: `").concat(property, "`."));
                return false;
            }
        }
        return true;
    };
    UpdateParamsValidator.validChartTypes = [
        'column',
        'groupedColumn',
        'stackedColumn',
        'normalizedColumn',
        'bar',
        'groupedBar',
        'stackedBar',
        'normalizedBar',
        'line',
        'scatter',
        'bubble',
        'pie',
        'doughnut',
        'area',
        'stackedArea',
        'normalizedArea',
        'histogram',
        'columnLineCombo',
        'areaColumnCombo',
        'customCombo'
    ];
    UpdateParamsValidator.validateChartType = validateIfDefined(function (chartType) {
        return UpdateParamsValidator.validChartTypes.includes(chartType);
    });
    UpdateParamsValidator.validateAgChartThemeOverrides = validateIfDefined(function (themeOverrides) {
        // ensure supplied AgChartThemeOverrides is an object - can be improved if necessary?
        return typeof themeOverrides === 'object';
    });
    UpdateParamsValidator.validateChartParamsCellRange = validateIfDefined(function (cellRange) {
        // ensure supplied ChartParamsCellRange is an object - can be improved if necessary?
        return typeof cellRange === 'object';
    });
    UpdateParamsValidator.validateAggFunc = validateIfDefined(function (aggFunc) {
        // ensure supplied aggFunc is a `string` or `function` - can be improved if necessary?
        return typeof aggFunc === 'string' || typeof aggFunc === 'function';
    });
    UpdateParamsValidator.commonValidations = [
        { property: 'chartId', validationFn: isString$2, warnMessage: createWarnMessage('chartId', 'string') },
        {
            property: 'chartType',
            validationFn: UpdateParamsValidator.validateChartType,
            warnMessage: createWarnMessage('chartType', UpdateParamsValidator.validChartTypes.join(', '))
        },
        {
            property: 'chartThemeName',
            validationFn: isString$2,
            warnMessage: createWarnMessage('chartThemeName', 'string')
        },
        {
            property: 'chartThemeOverrides',
            validationFn: UpdateParamsValidator.validateAgChartThemeOverrides,
            warnMessage: createWarnMessage('chartThemeOverrides', 'AgChartThemeOverrides')
        },
        { property: 'unlinkChart', validationFn: isBoolean$1, warnMessage: createWarnMessage('unlinkChart', 'boolean') },
    ];
    UpdateParamsValidator.cellRangeValidations = [
        {
            property: 'cellRange',
            validationFn: UpdateParamsValidator.validateChartParamsCellRange,
            warnMessage: createWarnMessage('cellRange', 'ChartParamsCellRange')
        },
        {
            property: 'suppressChartRanges',
            validationFn: isBoolean$1,
            warnMessage: createWarnMessage('suppressChartRanges', 'boolean')
        },
        {
            property: 'aggFunc',
            validationFn: UpdateParamsValidator.validateAggFunc,
            warnMessage: createWarnMessage('aggFunc', 'string or IAggFunc')
        },
    ];
    return UpdateParamsValidator;
}());

var __extends$1I = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$p = (undefined && undefined.__assign) || function () {
    __assign$p = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$p.apply(this, arguments);
};
var __decorate$1c = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var DEFAULT_THEMES = ['ag-default', 'ag-material', 'ag-sheets', 'ag-polychroma', 'ag-vivid'];
var ChartController = /** @class */ (function (_super) {
    __extends$1I(ChartController, _super);
    function ChartController(model) {
        var _this = _super.call(this) || this;
        _this.model = model;
        return _this;
    }
    ChartController.prototype.init = function () {
        var _this = this;
        this.setChartRange();
        this.addManagedListener(this.eventService, Events.EVENT_RANGE_SELECTION_CHANGED, function (event) {
            if (event.id && event.id === _this.model.chartId) {
                _this.updateForRangeChange();
            }
        });
        if (this.model.unlinked) {
            if (this.rangeService) {
                this.rangeService.setCellRanges([]);
            }
        }
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_MOVED, this.updateForGridChange.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_PINNED, this.updateForGridChange.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_VISIBLE, this.updateForGridChange.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_ROW_GROUP_CHANGED, this.updateForGridChange.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_MODEL_UPDATED, this.updateForGridChange.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_CELL_VALUE_CHANGED, this.updateForDataChange.bind(this));
    };
    ChartController.prototype.update = function (params) {
        var _a, _b, _c, _d, _e, _f, _g;
        if (!this.validUpdateType(params) || !UpdateParamsValidator.validateChartParams(params)) {
            return false;
        }
        var chartId = params.chartId, chartType = params.chartType, chartThemeName = params.chartThemeName, unlinkChart = params.unlinkChart;
        // create a common base for the chart model parameters (this covers pivot chart updates)
        var common = {
            chartId: chartId,
            pivotChart: this.model.pivotChart,
            chartType: chartType !== null && chartType !== void 0 ? chartType : this.model.chartType,
            chartThemeName: chartThemeName !== null && chartThemeName !== void 0 ? chartThemeName : this.model.chartThemeName,
            unlinkChart: unlinkChart !== null && unlinkChart !== void 0 ? unlinkChart : this.model.unlinked,
            cellRange: this.model.suppliedCellRange,
            aggFunc: this.model.aggFunc,
            seriesChartTypes: undefined,
            suppressChartRanges: false,
            crossFiltering: false
        };
        var chartModelParams = __assign$p({}, common);
        // modify the chart model properties based on the type of update
        switch (params.type) {
            case 'rangeChartUpdate':
                chartModelParams.cellRange = (_a = this.createCellRange(params)) !== null && _a !== void 0 ? _a : this.model.suppliedCellRange;
                chartModelParams.aggFunc = (_b = params.aggFunc) !== null && _b !== void 0 ? _b : this.model.aggFunc;
                chartModelParams.seriesChartTypes = params.seriesChartTypes;
                chartModelParams.suppressChartRanges = (_c = params.suppressChartRanges) !== null && _c !== void 0 ? _c : this.model.suppressChartRanges;
                break;
            case 'crossFilterChartUpdate':
                chartModelParams.cellRange = (_d = this.createCellRange(params)) !== null && _d !== void 0 ? _d : this.model.suppliedCellRange;
                chartModelParams.aggFunc = (_e = params.aggFunc) !== null && _e !== void 0 ? _e : this.model.aggFunc;
                chartModelParams.crossFiltering = true;
                chartModelParams.suppressChartRanges = (_f = params.suppressChartRanges) !== null && _f !== void 0 ? _f : this.model.suppressChartRanges;
                break;
        }
        this.model.updateModel(chartModelParams);
        // if the chart should be unlinked or chart ranges suppressed, remove all cell ranges; otherwise, set the chart range
        var removeChartCellRanges = chartModelParams.unlinkChart || chartModelParams.suppressChartRanges;
        removeChartCellRanges ? (_g = this.rangeService) === null || _g === void 0 ? void 0 : _g.setCellRanges([]) : this.setChartRange();
        return true;
    };
    ChartController.prototype.updateForGridChange = function () {
        if (this.model.unlinked) {
            return;
        }
        this.model.updateCellRanges();
        this.model.updateData();
        this.setChartRange();
    };
    ChartController.prototype.updateForDataChange = function () {
        if (this.model.unlinked) {
            return;
        }
        this.model.updateData();
        this.raiseChartModelUpdateEvent();
    };
    ChartController.prototype.updateForRangeChange = function () {
        this.updateForGridChange();
        this.raiseChartRangeSelectionChangedEvent();
    };
    ChartController.prototype.updateForPanelChange = function (updatedCol) {
        this.model.updateCellRanges(updatedCol);
        this.model.updateData();
        this.setChartRange();
        this.raiseChartRangeSelectionChangedEvent();
    };
    ChartController.prototype.getChartUpdateParams = function (updatedOverrides) {
        var selectedCols = this.getSelectedValueColState();
        var fields = selectedCols.map(function (c) { return ({ colId: c.colId, displayName: c.displayName }); });
        var data = this.getChartData();
        var selectedDimension = this.getSelectedDimension();
        return {
            data: data,
            grouping: this.isGrouping(),
            category: {
                id: selectedDimension.colId,
                name: selectedDimension.displayName,
                chartDataType: this.model.getChartDataType(selectedDimension.colId)
            },
            fields: fields,
            chartId: this.getChartId(),
            getCrossFilteringContext: function () { return ({ lastSelectedChartId: 'xxx' }); },
            seriesChartTypes: this.getSeriesChartTypes(),
            updatedOverrides: updatedOverrides
        };
    };
    ChartController.prototype.getChartModel = function () {
        var modelType = this.model.pivotChart ? 'pivot' : 'range';
        var seriesChartTypes = this.isComboChart() ? this.model.comboChartModel.seriesChartTypes : undefined;
        return {
            modelType: modelType,
            chartId: this.model.chartId,
            chartType: this.model.chartType,
            chartThemeName: this.getChartThemeName(),
            chartOptions: this.chartProxy.getChartThemeOverrides(),
            chartPalette: this.chartProxy.getChartPalette(),
            cellRange: this.getCellRangeParams(),
            suppressChartRanges: this.model.suppressChartRanges,
            aggFunc: this.model.aggFunc,
            unlinkChart: this.model.unlinked,
            seriesChartTypes: seriesChartTypes
        };
    };
    ChartController.prototype.getChartId = function () {
        return this.model.chartId;
    };
    ChartController.prototype.getChartData = function () {
        return this.model.chartData;
    };
    ChartController.prototype.getChartType = function () {
        return this.model.chartType;
    };
    ChartController.prototype.setChartType = function (chartType) {
        this.model.chartType = chartType;
        this.model.comboChartModel.updateSeriesChartTypes();
        this.raiseChartModelUpdateEvent();
        this.raiseChartOptionsChangedEvent();
    };
    ChartController.prototype.setChartThemeName = function (chartThemeName, silent) {
        this.model.chartThemeName = chartThemeName;
        if (!silent) {
            this.raiseChartModelUpdateEvent();
            this.raiseChartOptionsChangedEvent();
        }
    };
    ChartController.prototype.getChartThemeName = function () {
        return this.model.chartThemeName;
    };
    ChartController.prototype.isPivotChart = function () {
        return this.model.pivotChart;
    };
    ChartController.prototype.isPivotMode = function () {
        return this.model.isPivotMode();
    };
    ChartController.prototype.isGrouping = function () {
        return this.model.isGrouping();
    };
    ChartController.prototype.isCrossFilterChart = function () {
        return this.model.crossFiltering;
    };
    ChartController.prototype.getThemes = function () {
        return this.gridOptionsService.get('chartThemes') || DEFAULT_THEMES;
    };
    ChartController.prototype.getPalettes = function () {
        var _this = this;
        var themeNames = this.getThemes();
        return themeNames.map(function (themeName) {
            var stockTheme = isStockTheme(themeName);
            var theme = stockTheme ? themeName : _this.chartProxy.lookupCustomChartTheme(themeName);
            return integrated_charts_theme_exports$1.getChartTheme(theme).palette;
        });
    };
    ChartController.prototype.getValueColState = function () {
        return this.model.valueColState.map(this.displayNameMapper.bind(this));
    };
    ChartController.prototype.getSelectedValueColState = function () {
        return this.getValueColState().filter(function (cs) { return cs.selected; });
    };
    ChartController.prototype.getSelectedDimension = function () {
        return this.model.getSelectedDimension();
    };
    ChartController.prototype.displayNameMapper = function (col) {
        var columnNames = this.model.columnNames[col.colId];
        col.displayName = columnNames ? columnNames.join(' - ') : this.model.getColDisplayName(col.column);
        return col;
    };
    ChartController.prototype.getColStateForMenu = function () {
        return { dimensionCols: this.model.dimensionColState, valueCols: this.getValueColState() };
    };
    ChartController.prototype.setChartRange = function (silent) {
        if (silent === void 0) { silent = false; }
        if (this.rangeService && !this.model.suppressChartRanges && !this.model.unlinked) {
            this.rangeService.setCellRanges(this.getCellRanges());
        }
        if (!silent) {
            this.raiseChartModelUpdateEvent();
        }
    };
    ChartController.prototype.detachChartRange = function () {
        // when chart is detached it won't listen to changes from the grid
        this.model.unlinked = !this.model.unlinked;
        if (this.model.unlinked) {
            // remove range from grid
            if (this.rangeService) {
                this.rangeService.setCellRanges([]);
            }
        }
        else {
            // update chart data may have changed
            this.updateForGridChange();
        }
    };
    ChartController.prototype.setChartProxy = function (chartProxy) {
        this.chartProxy = chartProxy;
    };
    ChartController.prototype.getChartProxy = function () {
        return this.chartProxy;
    };
    ChartController.prototype.isActiveXYChart = function () {
        return _.includes(['scatter', 'bubble'], this.getChartType());
    };
    ChartController.prototype.isChartLinked = function () {
        return !this.model.unlinked;
    };
    ChartController.prototype.customComboExists = function () {
        var savedCustomSeriesChartTypes = this.model.comboChartModel.savedCustomSeriesChartTypes;
        return savedCustomSeriesChartTypes && savedCustomSeriesChartTypes.length > 0;
    };
    ChartController.prototype.getSeriesChartTypes = function () {
        return this.model.comboChartModel.seriesChartTypes;
    };
    ChartController.prototype.isComboChart = function () {
        return this.model.isComboChart();
    };
    ChartController.prototype.updateSeriesChartType = function (colId, chartType, secondaryAxis) {
        var seriesChartType = this.model.comboChartModel.seriesChartTypes.find(function (s) { return s.colId === colId; });
        if (seriesChartType) {
            // once a combo chart has been modified it is now a 'customCombo' chart
            var updateChartType = this.model.chartType !== 'customCombo';
            if (updateChartType) {
                this.model.chartType = 'customCombo';
            }
            var prevSeriesChartType = seriesChartType.chartType;
            if (chartType != null) {
                seriesChartType.chartType = chartType;
            }
            if (secondaryAxis != null) {
                seriesChartType.secondaryAxis = secondaryAxis;
            }
            // replace existing custom series types with this latest version
            this.model.comboChartModel.savedCustomSeriesChartTypes = this.model.comboChartModel.seriesChartTypes;
            // series chart types can be modified, i.e. column chart types should be moved to primary axis
            this.model.comboChartModel.updateSeriesChartTypes();
            this.updateForDataChange();
            if (updateChartType) {
                // update the settings panel by raising an EVENT_CHART_TYPE_CHANGED event
                this.dispatchEvent({
                    type: ChartController.EVENT_CHART_TYPE_CHANGED
                });
            }
            if (prevSeriesChartType !== chartType) {
                // update the format panel by raising an EVENT_CHART_SERIES_CHART_TYPE_CHANGED event
                this.dispatchEvent({
                    type: ChartController.EVENT_CHART_SERIES_CHART_TYPE_CHANGED
                });
            }
            this.raiseChartOptionsChangedEvent();
        }
    };
    ChartController.prototype.getActiveSeriesChartTypes = function () {
        var selectedColIds = this.getSelectedValueColState().map(function (c) { return c.colId; });
        return this.getSeriesChartTypes().filter(function (s) { return selectedColIds.includes(s.colId); });
    };
    ChartController.prototype.getChartSeriesTypes = function () {
        var supportedComboSeriesTypes = ['line', 'bar', 'area'];
        return this.isComboChart() ? supportedComboSeriesTypes : [getSeriesType(this.getChartType())];
    };
    ChartController.prototype.getCellRanges = function () {
        return [this.model.dimensionCellRange, this.model.valueCellRange].filter(function (r) { return r; });
    };
    ChartController.prototype.createCellRange = function (params) {
        var _a;
        return params.cellRange && ((_a = this.rangeService) === null || _a === void 0 ? void 0 : _a.createCellRangeFromCellRangeParams(params.cellRange));
    };
    ChartController.prototype.validUpdateType = function (params) {
        var _this = this;
        var _a;
        if (!params.type) {
            console.warn("AG Grid - Unable to update chart as the 'type' is missing. It must be either 'rangeChartUpdate', 'pivotChartUpdate', or 'crossFilterChartUpdate'.");
            return false;
        }
        var chartTypeMap = {
            'Range Chart': function () { return !_this.isPivotChart() && !_this.isCrossFilterChart(); },
            'Pivot Chart': function () { return _this.isPivotChart(); },
            'Cross Filter Chart': function () { return _this.isCrossFilterChart(); }
        };
        var currentChartType = (_a = Object.keys(chartTypeMap).find(function (type) { return chartTypeMap[type](); })) !== null && _a !== void 0 ? _a : 'Range Chart';
        var valid = params.type ===
            "".concat(currentChartType[0].toLowerCase()).concat(currentChartType.slice(1).replace(/ /g, ''), "Update");
        if (!valid) {
            console.warn("AG Grid - Unable to update chart as a '".concat(params.type, "' update type is not permitted on a ").concat(currentChartType, "."));
        }
        return valid;
    };
    ChartController.prototype.getCellRangeParams = function () {
        var cellRanges = this.getCellRanges();
        var firstCellRange = cellRanges[0];
        var startRow = (firstCellRange && firstCellRange.startRow) || null;
        var endRow = (firstCellRange && firstCellRange.endRow) || null;
        return {
            rowStartIndex: startRow && startRow.rowIndex,
            rowStartPinned: startRow && startRow.rowPinned,
            rowEndIndex: endRow && endRow.rowIndex,
            rowEndPinned: endRow && endRow.rowPinned,
            columns: cellRanges.reduce(function (columns, value) { return columns.concat(value.columns.map(function (c) { return c.getId(); })); }, [])
        };
    };
    ChartController.prototype.raiseChartModelUpdateEvent = function () {
        var event = {
            type: ChartController.EVENT_CHART_MODEL_UPDATE
        };
        this.dispatchEvent(event);
    };
    ChartController.prototype.raiseChartUpdatedEvent = function () {
        var event = {
            type: ChartController.EVENT_CHART_UPDATED
        };
        this.dispatchEvent(event);
    };
    ChartController.prototype.raiseChartApiUpdateEvent = function () {
        var event = {
            type: ChartController.EVENT_CHART_API_UPDATE
        };
        this.dispatchEvent(event);
    };
    ChartController.prototype.raiseChartOptionsChangedEvent = function () {
        var _a = this.getChartModel(), chartId = _a.chartId, chartType = _a.chartType;
        var event = {
            type: Events.EVENT_CHART_OPTIONS_CHANGED,
            chartId: chartId,
            chartType: chartType,
            chartThemeName: this.getChartThemeName(),
            chartOptions: this.chartProxy.getChartThemeOverrides()
        };
        this.eventService.dispatchEvent(event);
    };
    ChartController.prototype.raiseChartRangeSelectionChangedEvent = function () {
        var event = {
            type: Events.EVENT_CHART_RANGE_SELECTION_CHANGED,
            id: this.model.chartId,
            chartId: this.model.chartId,
            cellRange: this.getCellRangeParams()
        };
        this.eventService.dispatchEvent(event);
    };
    ChartController.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
        if (this.rangeService) {
            this.rangeService.setCellRanges([]);
        }
    };
    ChartController.EVENT_CHART_UPDATED = 'chartUpdated';
    ChartController.EVENT_CHART_API_UPDATE = 'chartApiUpdate';
    ChartController.EVENT_CHART_MODEL_UPDATE = 'chartModelUpdate';
    ChartController.EVENT_CHART_TYPE_CHANGED = 'chartTypeChanged';
    ChartController.EVENT_CHART_SERIES_CHART_TYPE_CHANGED = 'chartSeriesChartTypeChanged';
    __decorate$1c([
        Autowired('rangeService')
    ], ChartController.prototype, "rangeService", void 0);
    __decorate$1c([
        PostConstruct
    ], ChartController.prototype, "init", null);
    return ChartController;
}(BeanStub));

var __extends$1H = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1b = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$o = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$d = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values$7 = (undefined && undefined.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var DefaultDataPanelDef = {
    groups: [
        { type: 'categories', isOpen: true },
        { type: 'series', isOpen: true },
        { type: 'seriesChartType', isOpen: true }
    ]
};
var ChartDataPanel = /** @class */ (function (_super) {
    __extends$1H(ChartDataPanel, _super);
    function ChartDataPanel(chartController, chartOptionsService) {
        var _this = _super.call(this, ChartDataPanel.TEMPLATE) || this;
        _this.chartController = chartController;
        _this.chartOptionsService = chartOptionsService;
        _this.columnComps = new Map();
        return _this;
    }
    ChartDataPanel.prototype.init = function () {
        this.updatePanels();
        this.addManagedListener(this.chartController, ChartController.EVENT_CHART_MODEL_UPDATE, this.updatePanels.bind(this));
        this.addManagedListener(this.chartController, ChartController.EVENT_CHART_API_UPDATE, this.updatePanels.bind(this));
        this.createAutoScrollService();
    };
    ChartDataPanel.prototype.destroy = function () {
        this.clearComponents();
        _super.prototype.destroy.call(this);
    };
    ChartDataPanel.prototype.updatePanels = function () {
        var _this = this;
        var _a, _b;
        var currentChartType = this.chartType;
        var _c = this.chartController.getColStateForMenu(), dimensionCols = _c.dimensionCols, valueCols = _c.valueCols;
        var colIds = dimensionCols.map(function (c) { return c.colId; }).concat(valueCols.map(function (c) { return c.colId; }));
        this.chartType = this.chartController.getChartType();
        var groupExpandedState = this.getGroupExpandedState();
        if (_.areEqual(_.keys(this.columnComps), colIds) && this.chartType === currentChartType) {
            // if possible, we just update existing components
            __spreadArray$d(__spreadArray$d([], __read$o(dimensionCols), false), __read$o(valueCols), false).forEach(function (col) {
                _this.columnComps.get(col.colId).setValue(col.selected, true);
            });
            if (this.chartController.isActiveXYChart()) {
                var getSeriesLabel_1 = this.generateGetSeriesLabel();
                valueCols.forEach(function (col) {
                    _this.columnComps.get(col.colId).setLabel(getSeriesLabel_1(col));
                });
            }
            // recreate series chart type group if it exists as series may be added or removed via series group panel
            _.removeFromParent(this.getGui().querySelector('#seriesChartTypeGroup'));
            this.seriesChartTypeGroupComp = this.destroyBean(this.seriesChartTypeGroupComp);
            var seriesChartTypeIndex = (_a = this.getDataPanelDef().groups) === null || _a === void 0 ? void 0 : _a.reduce(function (prevVal, _a, index) {
                var type = _a.type;
                if (type === 'seriesChartType') {
                    return index;
                }
                return prevVal;
            }, -1);
            if (seriesChartTypeIndex !== -1) {
                this.createSeriesChartTypeGroup(valueCols, seriesChartTypeIndex);
            }
        }
        else {
            // otherwise, we re-create everything
            this.clearComponents();
            (_b = this.getDataPanelDef().groups) === null || _b === void 0 ? void 0 : _b.forEach(function (_a) {
                var type = _a.type;
                if (type === 'categories') {
                    _this.createCategoriesGroup(dimensionCols);
                }
                else if (type === 'series') {
                    _this.createSeriesGroup(valueCols);
                }
                else if (type === 'seriesChartType') {
                    _this.createSeriesChartTypeGroup(valueCols);
                }
                else {
                    console.warn("AG Grid: invalid charts data panel group name supplied: '".concat(type, "'"));
                }
            });
        }
        this.restoreGroupExpandedState(groupExpandedState);
    };
    ChartDataPanel.prototype.getGroupExpandedState = function () {
        var _this = this;
        var groups = [
            { groupType: 'categories', comp: this.categoriesGroupComp },
            { groupType: 'series', comp: this.seriesGroupComp },
            { groupType: 'seriesChartType', comp: this.seriesChartTypeGroupComp }
        ];
        return groups.map(function (_a) {
            var _b, _c;
            var groupType = _a.groupType, comp = _a.comp;
            var defaultExpanded = Boolean((_c = (_b = _this.getDataPanelDef().groups) === null || _b === void 0 ? void 0 : _b.find(function (_a) {
                var type = _a.type;
                return type === groupType;
            })) === null || _c === void 0 ? void 0 : _c.isOpen);
            return !comp ? defaultExpanded : comp.isExpanded();
        });
    };
    ChartDataPanel.prototype.restoreGroupExpandedState = function (groupExpandedState) {
        [
            this.categoriesGroupComp,
            this.seriesGroupComp,
            this.seriesChartTypeGroupComp
        ].forEach(function (group, idx) {
            if (!group) {
                return;
            }
            group.toggleGroupExpand(groupExpandedState[idx]);
        });
    };
    ChartDataPanel.prototype.createAutoScrollService = function () {
        var eGui = this.getGui();
        this.autoScrollService = new AutoScrollService({
            scrollContainer: eGui,
            scrollAxis: 'y',
            getVerticalPosition: function () { return eGui.scrollTop; },
            setVerticalPosition: function (position) { return eGui.scrollTop = position; }
        });
    };
    ChartDataPanel.prototype.createComponent = function (component, id) {
        var eDiv = document.createElement('div');
        eDiv.id = id;
        eDiv.className = 'ag-chart-data-section';
        eDiv.appendChild(component.getGui());
        return eDiv;
    };
    ChartDataPanel.prototype.addComponent = function (parent, component, id) {
        var eDiv = this.createComponent(component, id);
        parent.appendChild(eDiv);
    };
    ChartDataPanel.prototype.addComponentAtIndex = function (parent, component, id, index) {
        var eDiv = this.createComponent(component, id);
        parent.insertBefore(eDiv, parent.children[index]);
    };
    ChartDataPanel.prototype.addChangeListener = function (component, columnState) {
        var _this = this;
        this.addManagedListener(component, Events.EVENT_FIELD_VALUE_CHANGED, function () {
            columnState.selected = component.getValue();
            _this.chartController.updateForPanelChange(columnState);
        });
    };
    ChartDataPanel.prototype.createCategoriesGroup = function (columns) {
        var _this = this;
        this.categoriesGroupComp = this.createBean(new AgGroupComponent({
            title: this.getCategoryGroupTitle(),
            enabled: true,
            suppressEnabledCheckbox: true,
            suppressOpenCloseIcons: false,
            cssIdentifier: 'charts-data'
        }));
        var inputName = "chartDimension".concat(this.getCompId());
        columns.forEach(function (col) {
            var comp = _this.categoriesGroupComp.createManagedBean(new AgRadioButton());
            comp.setLabel(_.escapeString(col.displayName));
            comp.setValue(col.selected);
            comp.setInputName(inputName);
            _this.addChangeListener(comp, col);
            _this.categoriesGroupComp.addItem(comp);
            _this.columnComps.set(col.colId, comp);
        });
        this.addComponent(this.getGui(), this.categoriesGroupComp, 'categoriesGroup');
    };
    ChartDataPanel.prototype.createSeriesGroup = function (columns) {
        var _this = this;
        this.seriesGroupComp = this.createManagedBean(new AgGroupComponent({
            title: this.getSeriesGroupTitle(),
            enabled: true,
            suppressEnabledCheckbox: true,
            suppressOpenCloseIcons: false,
            cssIdentifier: 'charts-data'
        }));
        if (this.chartController.isActiveXYChart()) {
            var pairedModeToggle = this.seriesGroupComp.createManagedBean(new AgToggleButton());
            pairedModeToggle
                .setLabel(this.chartTranslationService.translate('paired'))
                .setLabelAlignment('left')
                .setLabelWidth('flex')
                .setInputWidth('flex')
                .setValue(this.chartOptionsService.getPairedMode())
                .onValueChange(function (newValue) {
                _this.chartOptionsService.setPairedMode(!!newValue);
                _this.chartController.updateForGridChange();
            });
            this.seriesGroupComp.addItem(pairedModeToggle);
        }
        var getSeriesLabel = this.generateGetSeriesLabel();
        columns.forEach(function (col) {
            var comp = _this.seriesGroupComp.createManagedBean(new AgCheckbox());
            comp.addCssClass('ag-data-select-checkbox');
            var label = getSeriesLabel(col);
            comp.setLabel(label);
            comp.setValue(col.selected);
            _this.addChangeListener(comp, col);
            _this.seriesGroupComp.addItem(comp);
            _this.columnComps.set(col.colId, comp);
            _this.addDragHandle(comp, col);
        });
        var eGui = this.getGui();
        var seriesGroupGui = this.seriesGroupComp.getGui();
        this.addComponent(eGui, this.seriesGroupComp, 'seriesGroup');
        var dropTarget = {
            getIconName: function () { return DragAndDropService.ICON_MOVE; },
            getContainer: function () { return seriesGroupGui; },
            onDragging: function (params) { return _this.onDragging(params); },
            onDragLeave: function () { return _this.onDragLeave(); },
            isInterestedIn: this.isInterestedIn.bind(this),
            targetContainsSource: true
        };
        this.dragAndDropService.addDropTarget(dropTarget);
        this.addDestroyFunc(function () { return _this.dragAndDropService.removeDropTarget(dropTarget); });
    };
    ChartDataPanel.prototype.createSeriesChartTypeGroup = function (columns, index) {
        var _this = this;
        if (!this.chartController.isComboChart()) {
            return;
        }
        this.seriesChartTypeGroupComp = this.createManagedBean(new AgGroupComponent({
            title: this.chartTranslationService.translate('seriesChartType'),
            enabled: true,
            suppressEnabledCheckbox: true,
            suppressOpenCloseIcons: false,
            cssIdentifier: 'charts-data'
        }));
        var seriesChartTypes = this.chartController.getSeriesChartTypes();
        columns.forEach(function (col) {
            if (!col.selected) {
                return;
            }
            var seriesChartType = seriesChartTypes.filter(function (s) { return s.colId === col.colId; })[0];
            if (!seriesChartType) {
                return;
            }
            var seriesItemGroup = _this.seriesChartTypeGroupComp.createManagedBean(new AgGroupComponent({
                title: col.displayName,
                enabled: true,
                suppressEnabledCheckbox: true,
                suppressOpenCloseIcons: true,
                cssIdentifier: 'charts-format-sub-level'
            }));
            var secondaryAxisComp = _this.seriesChartTypeGroupComp
                .createManagedBean(new AgCheckbox())
                .setLabel(_this.chartTranslationService.translate('secondaryAxis'))
                .setLabelWidth("flex")
                .setDisabled(['groupedColumn', 'stackedColumn', 'stackedArea'].includes(seriesChartType.chartType))
                .setValue(!!seriesChartType.secondaryAxis)
                .onValueChange(function (enabled) { return _this.chartController.updateSeriesChartType(col.colId, undefined, enabled); });
            seriesItemGroup.addItem(secondaryAxisComp);
            var translate = function (key, defaultText) {
                return _this.chartTranslationService.translate(key, defaultText);
            };
            var availableChartTypes = [
                { value: 'line', text: translate('line', 'Line') },
                { value: 'area', text: translate('area', 'Area') },
                { value: 'stackedArea', text: translate('stackedArea', 'StackedArea') },
                { value: 'groupedColumn', text: translate('groupedColumn', 'Grouped Column') },
                { value: 'stackedColumn', text: translate('stackedColumn', 'Stacked Column') },
            ];
            var chartTypeComp = seriesItemGroup.createManagedBean(new AgSelect());
            chartTypeComp
                .setLabelAlignment('left')
                .setLabelWidth("flex")
                .addOptions(availableChartTypes)
                .setValue(seriesChartType.chartType)
                .onValueChange(function (chartType) { return _this.chartController.updateSeriesChartType(col.colId, chartType); });
            seriesItemGroup.addItem(chartTypeComp);
            _this.seriesChartTypeGroupComp.addItem(seriesItemGroup);
        });
        if (index === undefined) {
            this.addComponent(this.getGui(), this.seriesChartTypeGroupComp, 'seriesChartTypeGroup');
        }
        else {
            this.addComponentAtIndex(this.getGui(), this.seriesChartTypeGroupComp, 'seriesChartTypeGroup', index);
        }
    };
    ChartDataPanel.prototype.addDragHandle = function (comp, col) {
        var _this = this;
        var eDragHandle = _.createIconNoSpan('columnDrag', this.gridOptionsService);
        eDragHandle.classList.add('ag-drag-handle', 'ag-chart-data-column-drag-handle');
        comp.getGui().insertAdjacentElement('beforeend', eDragHandle);
        var dragSource = {
            type: DragSourceType.ChartPanel,
            eElement: eDragHandle,
            dragItemName: col.displayName,
            getDragItem: function () { return ({ columns: [col.column] }); },
            onDragStopped: function () { return _this.onDragStop(); }
        };
        this.dragAndDropService.addDragSource(dragSource, true);
        this.addDestroyFunc(function () { return _this.dragAndDropService.removeDragSource(dragSource); });
    };
    ChartDataPanel.prototype.generateGetSeriesLabel = function () {
        if (!this.chartController.isActiveXYChart()) {
            return function (col) { return _.escapeString(col.displayName); };
        }
        var isBubble = this.chartType === 'bubble';
        var isInPairedMode = this.isInPairedMode();
        var selectedValuesCount = 0;
        var indexToAxisLabel = new Map();
        indexToAxisLabel.set(0, 'X');
        indexToAxisLabel.set(1, 'Y');
        indexToAxisLabel.set(2, 'size');
        return function (col) {
            var escapedLabel = _.escapeString(col.displayName);
            if (!col.selected) {
                return escapedLabel;
            }
            var axisLabel;
            if (isInPairedMode) {
                axisLabel = indexToAxisLabel.get(selectedValuesCount % (isBubble ? 3 : 2));
            }
            else {
                if (selectedValuesCount === 0) {
                    axisLabel = 'X';
                }
                else {
                    axisLabel = isBubble && selectedValuesCount % 2 === 0 ? 'size' : 'Y';
                }
            }
            selectedValuesCount++;
            return "".concat(escapedLabel, " (").concat(axisLabel, ")");
        };
    };
    ChartDataPanel.prototype.getCategoryGroupTitle = function () {
        return this.chartTranslationService.translate(this.chartController.isActiveXYChart() ? 'labels' : 'categories');
    };
    ChartDataPanel.prototype.getSeriesGroupTitle = function () {
        return this.chartTranslationService.translate(this.chartController.isActiveXYChart() ? 'xyValues' : 'series');
    };
    ChartDataPanel.prototype.getDataPanelDef = function () {
        var _a;
        var userProvidedDataPanelDef = (_a = this.gridOptionsService.get('chartToolPanelsDef')) === null || _a === void 0 ? void 0 : _a.dataPanel;
        return userProvidedDataPanelDef ? userProvidedDataPanelDef : DefaultDataPanelDef;
    };
    ChartDataPanel.prototype.isInPairedMode = function () {
        return this.chartController.isActiveXYChart() && this.chartOptionsService.getSeriesOption('paired', 'scatter');
    };
    ChartDataPanel.prototype.clearComponents = function () {
        _.clearElement(this.getGui());
        this.categoriesGroupComp = this.destroyBean(this.categoriesGroupComp);
        this.seriesGroupComp = this.destroyBean(this.seriesGroupComp);
        this.seriesChartTypeGroupComp = this.destroyBean(this.seriesChartTypeGroupComp);
        this.columnComps.clear();
    };
    ChartDataPanel.prototype.onDragging = function (draggingEvent) {
        var itemHovered = this.checkHoveredItem(draggingEvent);
        if (!itemHovered) {
            return;
        }
        this.lastDraggedColumn = draggingEvent.dragItem.columns[0];
        var comp = itemHovered.comp, position = itemHovered.position;
        var _a = this.lastHoveredItem || {}, lastHoveredComp = _a.comp, lastHoveredPosition = _a.position;
        if (comp === lastHoveredComp && position === lastHoveredPosition) {
            return;
        }
        this.autoScrollService.check(draggingEvent.event);
        this.clearHoveredItems();
        this.lastHoveredItem = { comp: comp, position: position };
        var eGui = comp.getGui();
        eGui.classList.add('ag-list-item-hovered', "ag-item-highlight-".concat(position));
    };
    ChartDataPanel.prototype.checkHoveredItem = function (draggingEvent) {
        var e_1, _a;
        if (_.missing(draggingEvent.vDirection)) {
            return null;
        }
        var mouseEvent = draggingEvent.event;
        try {
            for (var _b = __values$7(this.columnComps.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var comp = _c.value;
                var eGui = comp.getGui();
                if (!eGui.querySelector('.ag-chart-data-column-drag-handle')) {
                    continue;
                }
                var rect = eGui.getBoundingClientRect();
                var isOverComp = mouseEvent.clientY >= rect.top && mouseEvent.clientY <= rect.bottom;
                if (isOverComp) {
                    var height = eGui.clientHeight;
                    var position = mouseEvent.clientY > rect.top + (height / 2) ? 'bottom' : 'top';
                    return { comp: comp, position: position };
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return null;
    };
    ChartDataPanel.prototype.onDragLeave = function () {
        this.clearHoveredItems();
    };
    ChartDataPanel.prototype.onDragStop = function () {
        var _this = this;
        if (this.lastHoveredItem) {
            var _a = this.chartController.getColStateForMenu(), dimensionCols = _a.dimensionCols, valueCols = _a.valueCols;
            var draggedColumnState = __spreadArray$d(__spreadArray$d([], __read$o(dimensionCols), false), __read$o(valueCols), false).find(function (state) { return state.column === _this.lastDraggedColumn; });
            if (draggedColumnState) {
                var targetIndex = Array.from(this.columnComps.values()).indexOf(this.lastHoveredItem.comp);
                if (this.lastHoveredItem.position === 'bottom') {
                    targetIndex++;
                }
                draggedColumnState.order = targetIndex;
                this.chartController.updateForPanelChange(draggedColumnState);
            }
        }
        this.clearHoveredItems();
        this.lastDraggedColumn = undefined;
        this.autoScrollService.ensureCleared();
    };
    ChartDataPanel.prototype.clearHoveredItems = function () {
        this.columnComps.forEach(function (columnComp) {
            columnComp.getGui().classList.remove('ag-list-item-hovered', 'ag-item-highlight-top', 'ag-item-highlight-bottom');
        });
        this.lastHoveredItem = undefined;
    };
    ChartDataPanel.prototype.isInterestedIn = function (type) {
        return type === DragSourceType.ChartPanel;
    };
    ChartDataPanel.TEMPLATE = "<div class=\"ag-chart-data-wrapper ag-scrollable-container\"></div>";
    __decorate$1b([
        Autowired('dragAndDropService')
    ], ChartDataPanel.prototype, "dragAndDropService", void 0);
    __decorate$1b([
        Autowired('chartTranslationService')
    ], ChartDataPanel.prototype, "chartTranslationService", void 0);
    __decorate$1b([
        PostConstruct
    ], ChartDataPanel.prototype, "init", null);
    return ChartDataPanel;
}(Component));

var __extends$1G = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1a = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var FontPanel = /** @class */ (function (_super) {
    __extends$1G(FontPanel, _super);
    function FontPanel(params) {
        var _this = _super.call(this) || this;
        _this.activeComps = [];
        _this.params = params;
        return _this;
    }
    FontPanel.prototype.init = function () {
        var groupParams = {
            cssIdentifier: 'charts-format-sub-level',
            direction: 'vertical',
            suppressOpenCloseIcons: true
        };
        this.setTemplate(FontPanel.TEMPLATE, { fontGroup: groupParams });
        this.initGroup();
        this.initFontFamilySelect();
        this.initFontWeightStyleSelect();
        this.initFontSizeSelect();
        this.initFontColorPicker();
    };
    FontPanel.prototype.addCompToPanel = function (comp) {
        this.fontGroup.addItem(comp);
        this.activeComps.push(comp);
    };
    FontPanel.prototype.setEnabled = function (enabled) {
        this.fontGroup.setEnabled(enabled);
    };
    FontPanel.prototype.initGroup = function () {
        var _this = this;
        this.fontGroup
            .setTitle(this.params.name || this.chartTranslationService.translate('font'))
            .setEnabled(this.params.enabled)
            .hideEnabledCheckbox(!!this.params.suppressEnabledCheckbox)
            .hideOpenCloseIcons(true)
            .onEnableChange(function (enabled) {
            if (_this.params.setEnabled) {
                _this.params.setEnabled(enabled);
            }
        });
    };
    FontPanel.prototype.initFontFamilySelect = function () {
        var _this = this;
        var families = [
            'Arial, sans-serif',
            'Aria Black, sans-serif',
            'Book Antiqua,  serif',
            'Charcoal, sans-serif',
            'Comic Sans MS, cursive',
            'Courier, monospace',
            'Courier New, monospace',
            'Gadget, sans-serif',
            'Geneva, sans-serif',
            'Helvetica, sans-serif',
            'Impact, sans-serif',
            'Lucida Console, monospace',
            'Lucida Grande, sans-serif',
            'Lucida Sans Unicode,  sans-serif',
            'Monaco, monospace',
            'Palatino Linotype, serif',
            'Palatino, serif',
            'Times New Roman, serif',
            'Times, serif',
            'Verdana, sans-serif'
        ];
        var family = this.params.initialFont.family;
        var initialValue = families[0];
        if (family) {
            // check for known values using lowercase
            var lowerCaseValues = families.map(function (f) { return f.toLowerCase(); });
            var valueIndex = lowerCaseValues.indexOf(family.toLowerCase());
            if (valueIndex >= 0) {
                initialValue = families[valueIndex];
            }
            else {
                // add user provided value to list
                var capitalisedFontValue = _.capitalise(family);
                families.push(capitalisedFontValue);
                initialValue = capitalisedFontValue;
            }
        }
        var options = families.sort().map(function (value) { return ({ value: value, text: value }); });
        this.familySelect.addOptions(options)
            .setInputWidth('flex')
            .setValue("".concat(initialValue))
            .onValueChange(function (newValue) { return _this.params.setFont({ family: newValue }); });
    };
    FontPanel.prototype.initFontSizeSelect = function () {
        var _this = this;
        var sizes = [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36];
        var size = this.params.initialFont.size;
        if (!_.includes(sizes, size)) {
            sizes.push(size);
        }
        var options = sizes.sort(function (a, b) { return a - b; }).map(function (value) { return ({ value: "".concat(value), text: "".concat(value) }); });
        this.sizeSelect.addOptions(options)
            .setInputWidth('flex')
            .setValue("".concat(size))
            .onValueChange(function (newValue) { return _this.params.setFont({ size: parseInt(newValue, 10) }); });
        this.sizeSelect.setLabel(this.chartTranslationService.translate('size'));
    };
    FontPanel.prototype.initFontWeightStyleSelect = function () {
        var _this = this;
        var _a = this.params.initialFont, _b = _a.weight, weight = _b === void 0 ? 'normal' : _b, _c = _a.style, style = _c === void 0 ? 'normal' : _c;
        var weightStyles = [
            { name: 'normal', weight: 'normal', style: 'normal' },
            { name: 'bold', weight: 'bold', style: 'normal' },
            { name: 'italic', weight: 'normal', style: 'italic' },
            { name: 'boldItalic', weight: 'bold', style: 'italic' }
        ];
        var selectedOption = weightStyles.find(function (x) { return x.weight === weight && x.style === style; });
        if (!selectedOption) {
            selectedOption = { name: 'predefined', weight: weight, style: style };
            weightStyles.unshift(selectedOption);
        }
        var options = weightStyles.map(function (ws) { return ({
            value: ws.name,
            text: _this.chartTranslationService.translate(ws.name),
        }); });
        this.weightStyleSelect.addOptions(options)
            .setInputWidth('flex')
            .setValue(selectedOption.name)
            .onValueChange(function (newValue) {
            var selectedWeightStyle = weightStyles.find(function (x) { return x.name === newValue; });
            _this.params.setFont({ weight: selectedWeightStyle.weight, style: selectedWeightStyle.style });
        });
    };
    FontPanel.prototype.initFontColorPicker = function () {
        var _this = this;
        this.colorPicker
            .setLabel(this.chartTranslationService.translate('color'))
            .setInputWidth('flex')
            .setValue("".concat(this.params.initialFont.color))
            .onValueChange(function (newColor) { return _this.params.setFont({ color: newColor }); });
    };
    FontPanel.prototype.addItemToPanel = function (item) {
        this.fontGroup.addItem(item);
        this.activeComps.push(item);
    };
    FontPanel.prototype.destroyActiveComps = function () {
        var _this = this;
        this.activeComps.forEach(function (comp) {
            _.removeFromParent(comp.getGui());
            _this.destroyBean(comp);
        });
    };
    FontPanel.prototype.destroy = function () {
        this.destroyActiveComps();
        _super.prototype.destroy.call(this);
    };
    FontPanel.TEMPLATE = "<div class=\"ag-font-panel\">\n            <ag-group-component ref=\"fontGroup\">\n                <ag-select ref=\"familySelect\"></ag-select>\n                <ag-select ref=\"weightStyleSelect\"></ag-select>\n                <div class=\"ag-charts-font-size-color\">\n                    <ag-select ref=\"sizeSelect\"></ag-select>\n                    <ag-color-picker ref=\"colorPicker\"></ag-color-picker>\n                </div>\n            </ag-group-component>\n        </div>";
    __decorate$1a([
        RefSelector('fontGroup')
    ], FontPanel.prototype, "fontGroup", void 0);
    __decorate$1a([
        RefSelector('familySelect')
    ], FontPanel.prototype, "familySelect", void 0);
    __decorate$1a([
        RefSelector('weightStyleSelect')
    ], FontPanel.prototype, "weightStyleSelect", void 0);
    __decorate$1a([
        RefSelector('sizeSelect')
    ], FontPanel.prototype, "sizeSelect", void 0);
    __decorate$1a([
        RefSelector('colorPicker')
    ], FontPanel.prototype, "colorPicker", void 0);
    __decorate$1a([
        Autowired('chartTranslationService')
    ], FontPanel.prototype, "chartTranslationService", void 0);
    __decorate$1a([
        PostConstruct
    ], FontPanel.prototype, "init", null);
    return FontPanel;
}(Component));

var __extends$1F = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$19 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var LegendPanel = /** @class */ (function (_super) {
    __extends$1F(LegendPanel, _super);
    function LegendPanel(_a) {
        var chartOptionsService = _a.chartOptionsService, _b = _a.isExpandedOnInit, isExpandedOnInit = _b === void 0 ? false : _b;
        var _this = _super.call(this) || this;
        _this.activePanels = [];
        _this.chartOptionsService = chartOptionsService;
        _this.isExpandedOnInit = isExpandedOnInit;
        return _this;
    }
    LegendPanel.prototype.init = function () {
        var groupParams = {
            cssIdentifier: 'charts-format-top-level',
            direction: 'vertical'
        };
        this.setTemplate(LegendPanel.TEMPLATE, { legendGroup: groupParams });
        this.initLegendGroup();
        this.initLegendPosition();
        this.initLegendPadding();
        this.initLegendItems();
        this.initLabelPanel();
    };
    LegendPanel.prototype.initLegendGroup = function () {
        var _this = this;
        this.legendGroup
            .setTitle(this.chartTranslationService.translate("legend"))
            .hideEnabledCheckbox(false)
            .setEnabled(this.chartOptionsService.getChartOption("legend.enabled") || false)
            .toggleGroupExpand(this.isExpandedOnInit)
            .onEnableChange(function (enabled) {
            _this.chartOptionsService.setChartOption("legend.enabled", enabled);
            _this.legendGroup.toggleGroupExpand(true);
        });
    };
    LegendPanel.prototype.initLegendPosition = function () {
        var _this = this;
        var positions = ['top', 'right', 'bottom', 'left'];
        this.legendPositionSelect
            .setLabel(this.chartTranslationService.translate("position"))
            .setLabelWidth("flex")
            .setInputWidth('flex')
            .addOptions(positions.map(function (position) { return ({
            value: position,
            text: _this.chartTranslationService.translate(position)
        }); }))
            .setValue(this.chartOptionsService.getChartOption("legend.position"))
            .onValueChange(function (newValue) { return _this.chartOptionsService.setChartOption("legend.position", newValue); });
    };
    LegendPanel.prototype.initLegendPadding = function () {
        var _this = this;
        var currentValue = this.chartOptionsService.getChartOption("legend.spacing");
        this.legendPaddingSlider
            .setLabel(this.chartTranslationService.translate("spacing"))
            .setMaxValue(getMaxValue(currentValue, 200))
            .setValue("".concat(currentValue))
            .setTextFieldWidth(45)
            .onValueChange(function (newValue) { return _this.chartOptionsService.setChartOption("legend.spacing", newValue); });
    };
    LegendPanel.prototype.initLegendItems = function () {
        var _this = this;
        var initSlider = function (expression, labelKey, input, defaultMaxValue) {
            var _a;
            var currentValue = (_a = _this.chartOptionsService.getChartOption("legend.".concat(expression))) !== null && _a !== void 0 ? _a : 0;
            input.setLabel(_this.chartTranslationService.translate(labelKey))
                .setMaxValue(getMaxValue(currentValue, defaultMaxValue))
                .setValue("".concat(currentValue))
                .setTextFieldWidth(45)
                .onValueChange(function (newValue) {
                _this.chartOptionsService.setChartOption("legend.".concat(expression), newValue);
            });
        };
        initSlider("item.marker.size", "markerSize", this.markerSizeSlider, 40);
        initSlider("item.marker.strokeWidth", "markerStroke", this.markerStrokeSlider, 10);
        initSlider("item.marker.padding", "itemSpacing", this.markerPaddingSlider, 20);
        initSlider("item.paddingX", "layoutHorizontalSpacing", this.itemPaddingXSlider, 50);
        initSlider("item.paddingY", "layoutVerticalSpacing", this.itemPaddingYSlider, 50);
    };
    LegendPanel.prototype.initLabelPanel = function () {
        var _this = this;
        var chartProxy = this.chartOptionsService;
        var initialFont = {
            family: chartProxy.getChartOption("legend.item.label.fontFamily"),
            style: chartProxy.getChartOption("legend.item.label.fontStyle"),
            weight: chartProxy.getChartOption("legend.item.label.fontWeight"),
            size: chartProxy.getChartOption("legend.item.label.fontSize"),
            color: chartProxy.getChartOption("legend.item.label.color")
        };
        var setFont = function (font) {
            var proxy = _this.chartOptionsService;
            if (font.family) {
                proxy.setChartOption("legend.item.label.fontFamily", font.family);
            }
            if (font.weight) {
                proxy.setChartOption("legend.item.label.fontWeight", font.weight);
            }
            if (font.style) {
                proxy.setChartOption("legend.item.label.fontStyle", font.style);
            }
            if (font.size) {
                proxy.setChartOption("legend.item.label.fontSize", font.size);
            }
            if (font.color) {
                proxy.setChartOption("legend.item.label.color", font.color);
            }
        };
        var params = {
            enabled: true,
            suppressEnabledCheckbox: true,
            initialFont: initialFont,
            setFont: setFont
        };
        var fontPanelComp = this.createBean(new FontPanel(params));
        this.legendGroup.addItem(fontPanelComp);
        this.activePanels.push(fontPanelComp);
    };
    LegendPanel.prototype.destroyActivePanels = function () {
        var _this = this;
        this.activePanels.forEach(function (panel) {
            _.removeFromParent(panel.getGui());
            _this.destroyBean(panel);
        });
    };
    LegendPanel.prototype.destroy = function () {
        this.destroyActivePanels();
        _super.prototype.destroy.call(this);
    };
    LegendPanel.TEMPLATE = "<div>\n            <ag-group-component ref=\"legendGroup\">\n                <ag-select ref=\"legendPositionSelect\"></ag-select>\n                <ag-slider ref=\"legendPaddingSlider\"></ag-slider>\n                <ag-slider ref=\"markerSizeSlider\"></ag-slider>\n                <ag-slider ref=\"markerStrokeSlider\"></ag-slider>\n                <ag-slider ref=\"markerPaddingSlider\"></ag-slider>\n                <ag-slider ref=\"itemPaddingXSlider\"></ag-slider>\n                <ag-slider ref=\"itemPaddingYSlider\"></ag-slider>\n            </ag-group-component>\n        </div>";
    __decorate$19([
        RefSelector('legendGroup')
    ], LegendPanel.prototype, "legendGroup", void 0);
    __decorate$19([
        RefSelector('legendPositionSelect')
    ], LegendPanel.prototype, "legendPositionSelect", void 0);
    __decorate$19([
        RefSelector('legendPaddingSlider')
    ], LegendPanel.prototype, "legendPaddingSlider", void 0);
    __decorate$19([
        RefSelector('markerSizeSlider')
    ], LegendPanel.prototype, "markerSizeSlider", void 0);
    __decorate$19([
        RefSelector('markerStrokeSlider')
    ], LegendPanel.prototype, "markerStrokeSlider", void 0);
    __decorate$19([
        RefSelector('markerPaddingSlider')
    ], LegendPanel.prototype, "markerPaddingSlider", void 0);
    __decorate$19([
        RefSelector('itemPaddingXSlider')
    ], LegendPanel.prototype, "itemPaddingXSlider", void 0);
    __decorate$19([
        RefSelector('itemPaddingYSlider')
    ], LegendPanel.prototype, "itemPaddingYSlider", void 0);
    __decorate$19([
        Autowired('chartTranslationService')
    ], LegendPanel.prototype, "chartTranslationService", void 0);
    __decorate$19([
        PostConstruct
    ], LegendPanel.prototype, "init", null);
    return LegendPanel;
}(Component));

var __extends$1E = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$18 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AxisTicksPanel = /** @class */ (function (_super) {
    __extends$1E(AxisTicksPanel, _super);
    function AxisTicksPanel(chartOptionsService) {
        var _this = _super.call(this) || this;
        _this.chartOptionsService = chartOptionsService;
        return _this;
    }
    AxisTicksPanel.prototype.init = function () {
        var groupParams = {
            cssIdentifier: 'charts-format-sub-level',
            direction: 'vertical',
            suppressOpenCloseIcons: true
        };
        this.setTemplate(AxisTicksPanel.TEMPLATE, { axisTicksGroup: groupParams });
        this.initAxisTicks();
    };
    AxisTicksPanel.prototype.initAxisTicks = function () {
        var _this = this;
        this.axisTicksGroup
            .setTitle(this.chartTranslationService.translate("ticks"))
            .setEnabled(this.chartOptionsService.getAxisProperty("tick.enabled"))
            .hideOpenCloseIcons(true)
            .hideEnabledCheckbox(false)
            .onEnableChange(function (newValue) { return _this.chartOptionsService.setAxisProperty("tick.enabled", newValue); });
        this.axisTicksColorPicker
            .setLabel(this.chartTranslationService.translate("color"))
            .setLabelWidth("flex")
            .setInputWidth('flex')
            .setValue(this.chartOptionsService.getAxisProperty("tick.color"))
            .onValueChange(function (newColor) { return _this.chartOptionsService.setAxisProperty("tick.color", newColor); });
        var initInput = function (expression, input, label, defaultMaxValue) {
            var currentValue = _this.chartOptionsService.getAxisProperty(expression);
            input.setLabel(label)
                .setMaxValue(getMaxValue(currentValue, defaultMaxValue))
                .setValue("".concat(currentValue))
                .setTextFieldWidth(45)
                .onValueChange(function (newValue) { return _this.chartOptionsService.setAxisProperty(expression, newValue); });
        };
        initInput("tick.width", this.axisTicksWidthSlider, this.chartTranslationService.translate("width"), 10);
        initInput("tick.size", this.axisTicksSizeSlider, this.chartTranslationService.translate("length"), 30);
    };
    AxisTicksPanel.TEMPLATE = "<div>\n            <ag-group-component ref=\"axisTicksGroup\">\n                <ag-color-picker ref=\"axisTicksColorPicker\"></ag-color-picker>\n                <ag-slider ref=\"axisTicksWidthSlider\"></ag-slider>\n                <ag-slider ref=\"axisTicksSizeSlider\"></ag-slider>\n            </ag-group-component>\n        </div>";
    __decorate$18([
        RefSelector('axisTicksGroup')
    ], AxisTicksPanel.prototype, "axisTicksGroup", void 0);
    __decorate$18([
        RefSelector('axisTicksColorPicker')
    ], AxisTicksPanel.prototype, "axisTicksColorPicker", void 0);
    __decorate$18([
        RefSelector('axisTicksWidthSlider')
    ], AxisTicksPanel.prototype, "axisTicksWidthSlider", void 0);
    __decorate$18([
        RefSelector('axisTicksSizeSlider')
    ], AxisTicksPanel.prototype, "axisTicksSizeSlider", void 0);
    __decorate$18([
        Autowired('chartTranslationService')
    ], AxisTicksPanel.prototype, "chartTranslationService", void 0);
    __decorate$18([
        PostConstruct
    ], AxisTicksPanel.prototype, "init", null);
    return AxisTicksPanel;
}(Component));

var __extends$1D = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$17 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AgAngleSelect = /** @class */ (function (_super) {
    __extends$1D(AgAngleSelect, _super);
    function AgAngleSelect(config) {
        var _this = _super.call(this, config, AgAngleSelect.TEMPLATE) || this;
        _this.radius = 0;
        _this.offsetX = 0;
        _this.offsetY = 0;
        return _this;
    }
    AgAngleSelect.prototype.postConstruct = function () {
        var _this = this;
        _super.prototype.postConstruct.call(this);
        this.dragListener = {
            eElement: this.eParentCircle,
            dragStartPixels: 0,
            onDragStart: function (e) {
                _this.parentCircleRect = _this.eParentCircle.getBoundingClientRect();
            },
            onDragging: function (e) { return _this.calculateAngleDrag(e); },
            onDragStop: function () { }
        };
        this.dragService.addDragSource(this.dragListener);
        this.eAngleValue
            .setLabel('')
            .setLabelWidth(5)
            .setInputWidth(45)
            .setMin(0)
            .setMax(360)
            .setValue("".concat(this.degrees))
            .onValueChange(function (value) {
            if (value == null || value === '') {
                value = '0';
            }
            value = _this.eAngleValue.normalizeValue(value);
            var floatValue = parseFloat(value);
            if (floatValue > 180) {
                floatValue = floatValue - 360;
            }
            _this.setValue(floatValue);
        });
        this.updateNumberInput();
        if (_.exists(this.getValue())) {
            this.eAngleValue.setValue(this.normalizeNegativeValue(this.getValue()).toString());
        }
        this.addManagedListener(this, Events.EVENT_FIELD_VALUE_CHANGED, function () {
            var eDocument = _this.gridOptionsService.getDocument();
            if (_this.eAngleValue.getInputElement().contains(eDocument.activeElement)) {
                return;
            }
            _this.updateNumberInput();
        });
    };
    AgAngleSelect.prototype.updateNumberInput = function () {
        var normalizedValue = this.normalizeNegativeValue(this.getValue());
        this.eAngleValue.setValue(normalizedValue.toString());
    };
    AgAngleSelect.prototype.positionChildCircle = function (radians) {
        var rect = this.parentCircleRect || { width: 24, height: 24 };
        var eChildCircle = this.eChildCircle;
        var centerX = rect.width / 2;
        var centerY = rect.height / 2;
        eChildCircle.style.left = "".concat(centerX + Math.cos(radians) * 8, "px");
        eChildCircle.style.top = "".concat(centerY + Math.sin(radians) * 8, "px");
    };
    AgAngleSelect.prototype.calculatePolar = function () {
        var x = this.offsetX;
        var y = this.offsetY;
        var radians = Math.atan2(y, x);
        this.degrees = this.toDegrees(radians);
        this.radius = Math.sqrt((x * x) + (y * y));
        this.positionChildCircle(radians);
    };
    AgAngleSelect.prototype.calculateCartesian = function () {
        var radians = this.toRadians(this.getValue());
        var radius = this.getRadius();
        this
            .setOffsetX(Math.cos(radians) * radius)
            .setOffsetY(Math.sin(radians) * radius);
    };
    AgAngleSelect.prototype.setOffsetX = function (offset) {
        if (this.offsetX !== offset) {
            this.offsetX = offset;
            this.calculatePolar();
        }
        return this;
    };
    AgAngleSelect.prototype.setOffsetY = function (offset) {
        if (this.offsetY !== offset) {
            this.offsetY = offset;
            this.calculatePolar();
        }
        return this;
    };
    AgAngleSelect.prototype.calculateAngleDrag = function (e) {
        var rect = this.parentCircleRect;
        var centerX = rect.width / 2;
        var centerY = rect.height / 2;
        var x = e.clientX - rect.left;
        var y = e.clientY - rect.top;
        var dx = x - centerX;
        var dy = y - centerY;
        var radians = Math.atan2(dy, dx);
        this.setValue(radians, true);
    };
    AgAngleSelect.prototype.toDegrees = function (radians) {
        return radians / Math.PI * 180;
    };
    AgAngleSelect.prototype.toRadians = function (degrees) {
        return degrees / 180 * Math.PI;
    };
    AgAngleSelect.prototype.normalizeNegativeValue = function (degrees) {
        return degrees < 0 ? 360 + degrees : degrees;
    };
    AgAngleSelect.prototype.normalizeAngle180 = function (radians) {
        radians %= Math.PI * 2;
        if (radians < -Math.PI) {
            radians += Math.PI * 2;
        }
        else if (radians >= Math.PI) {
            radians -= Math.PI * 2;
        }
        return radians;
    };
    AgAngleSelect.prototype.getRadius = function () {
        return this.radius;
    };
    AgAngleSelect.prototype.setRadius = function (r) {
        if (this.radius === r) {
            return this;
        }
        this.radius = r;
        this.calculateCartesian();
        return this;
    };
    AgAngleSelect.prototype.onValueChange = function (callbackFn) {
        var _this = this;
        this.addManagedListener(this, Events.EVENT_FIELD_VALUE_CHANGED, function () {
            callbackFn(_this.degrees);
        });
        return this;
    };
    AgAngleSelect.prototype.getValue = function (radians) {
        return radians ? this.toRadians(this.degrees) : this.degrees;
    };
    AgAngleSelect.prototype.setValue = function (degrees, radians) {
        var radiansValue;
        if (!radians) {
            radiansValue = this.normalizeAngle180(this.toRadians(degrees));
        }
        else {
            radiansValue = degrees;
        }
        degrees = this.toDegrees(radiansValue);
        if (this.degrees !== degrees) {
            this.degrees = Math.floor(degrees);
            this.calculateCartesian();
            this.positionChildCircle(radiansValue);
            this.dispatchEvent({ type: Events.EVENT_FIELD_VALUE_CHANGED });
        }
        return this;
    };
    AgAngleSelect.prototype.setWidth = function (width) {
        _.setFixedWidth(this.getGui(), width);
        return this;
    };
    AgAngleSelect.prototype.setDisabled = function (disabled) {
        _super.prototype.setDisabled.call(this, disabled);
        this.eAngleValue.setDisabled(disabled);
        return this;
    };
    AgAngleSelect.prototype.destroy = function () {
        this.dragService.removeDragSource(this.dragListener);
        _super.prototype.destroy.call(this);
    };
    AgAngleSelect.TEMPLATE = "<div class=\"ag-angle-select\">\n            <div ref=\"eLabel\"></div>\n            <div class=\"ag-wrapper ag-angle-select-wrapper\">\n                <div ref=\"eAngleSelectField\" class=\"ag-angle-select-field\">\n                    <div ref=\"eParentCircle\" class=\"ag-angle-select-parent-circle\">\n                        <div ref=\"eChildCircle\" class=\"ag-angle-select-child-circle\"></div>\n                    </div>\n                </div>\n                <ag-input-number-field ref=\"eAngleValue\"></ag-input-number-field>\n            </div>\n        </div>";
    __decorate$17([
        RefSelector('eLabel')
    ], AgAngleSelect.prototype, "eLabel", void 0);
    __decorate$17([
        RefSelector('eParentCircle')
    ], AgAngleSelect.prototype, "eParentCircle", void 0);
    __decorate$17([
        RefSelector('eChildCircle')
    ], AgAngleSelect.prototype, "eChildCircle", void 0);
    __decorate$17([
        RefSelector('eAngleValue')
    ], AgAngleSelect.prototype, "eAngleValue", void 0);
    __decorate$17([
        Autowired('dragService')
    ], AgAngleSelect.prototype, "dragService", void 0);
    return AgAngleSelect;
}(AgAbstractLabel));

var __extends$1C = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$16 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AxisPanel = /** @class */ (function (_super) {
    __extends$1C(AxisPanel, _super);
    function AxisPanel(_a) {
        var chartController = _a.chartController, chartOptionsService = _a.chartOptionsService, _b = _a.isExpandedOnInit, isExpandedOnInit = _b === void 0 ? false : _b;
        var _this = _super.call(this) || this;
        _this.activePanels = [];
        _this.axisLabelUpdateFuncs = [];
        _this.prevXRotation = 0;
        _this.prevYRotation = 0;
        _this.chartController = chartController;
        _this.chartOptionsService = chartOptionsService;
        _this.isExpandedOnInit = isExpandedOnInit;
        return _this;
    }
    AxisPanel.prototype.init = function () {
        var _this = this;
        var groupParams = {
            cssIdentifier: 'charts-format-top-level',
            direction: 'vertical'
        };
        this.setTemplate(AxisPanel.TEMPLATE, { axisGroup: groupParams });
        this.initAxis();
        this.initAxisTicks();
        this.initAxisLabels();
        var updateAxisLabelRotations = function () { return _this.axisLabelUpdateFuncs.forEach(function (func) { return func(); }); };
        this.addManagedListener(this.chartController, ChartController.EVENT_CHART_UPDATED, updateAxisLabelRotations);
    };
    AxisPanel.prototype.initAxis = function () {
        var _this = this;
        this.axisGroup
            .setTitle(this.translate("axis"))
            .toggleGroupExpand(this.isExpandedOnInit)
            .hideEnabledCheckbox(true);
        this.axisColorInput
            .setLabel(this.translate("color"))
            .setLabelWidth("flex")
            .setInputWidth("flex")
            .setValue(this.chartOptionsService.getAxisProperty("line.color"))
            .onValueChange(function (newColor) { return _this.chartOptionsService.setAxisProperty("line.color", newColor); });
        var currentValue = this.chartOptionsService.getAxisProperty("line.width");
        this.axisLineWidthSlider
            .setMaxValue(getMaxValue(currentValue, 10))
            .setLabel(this.translate("thickness"))
            .setTextFieldWidth(45)
            .setValue("".concat(currentValue))
            .onValueChange(function (newValue) { return _this.chartOptionsService.setAxisProperty("line.width", newValue); });
    };
    AxisPanel.prototype.initAxisTicks = function () {
        var axisTicksComp = this.createBean(new AxisTicksPanel(this.chartOptionsService));
        this.axisGroup.addItem(axisTicksComp);
        this.activePanels.push(axisTicksComp);
    };
    AxisPanel.prototype.initAxisLabels = function () {
        var _this = this;
        var initialFont = {
            family: this.chartOptionsService.getAxisProperty("label.fontFamily"),
            style: this.chartOptionsService.getAxisProperty("label.fontStyle"),
            weight: this.chartOptionsService.getAxisProperty("label.fontWeight"),
            size: this.chartOptionsService.getAxisProperty("label.fontSize"),
            color: this.chartOptionsService.getAxisProperty("label.color")
        };
        var setFont = function (font) {
            if (font.family) {
                _this.chartOptionsService.setAxisProperty("label.fontFamily", font.family);
            }
            if (font.weight) {
                _this.chartOptionsService.setAxisProperty("label.fontWeight", font.weight);
            }
            if (font.style) {
                _this.chartOptionsService.setAxisProperty("label.fontStyle", font.style);
            }
            if (font.size) {
                _this.chartOptionsService.setAxisProperty("label.fontSize", font.size);
            }
            if (font.color) {
                _this.chartOptionsService.setAxisProperty("label.color", font.color);
            }
        };
        var params = {
            name: this.translate("labels"),
            enabled: true,
            suppressEnabledCheckbox: true,
            initialFont: initialFont,
            setFont: setFont
        };
        var labelPanelComp = this.createBean(new FontPanel(params));
        this.axisGroup.addItem(labelPanelComp);
        this.activePanels.push(labelPanelComp);
        this.addAdditionalLabelComps(labelPanelComp);
    };
    AxisPanel.prototype.addAdditionalLabelComps = function (labelPanelComp) {
        this.addLabelPadding(labelPanelComp);
        var _a = this.createRotationWidgets(), xRotationComp = _a.xRotationComp, yRotationComp = _a.yRotationComp;
        var autoRotateCb = this.initLabelRotations(xRotationComp, yRotationComp);
        labelPanelComp.addCompToPanel(autoRotateCb);
        labelPanelComp.addCompToPanel(xRotationComp);
        labelPanelComp.addCompToPanel(yRotationComp);
    };
    AxisPanel.prototype.initLabelRotations = function (xRotationComp, yRotationComp) {
        var _this = this;
        var getLabelRotation = function (axisType) {
            return _this.chartOptionsService.getLabelRotation(axisType);
        };
        var setLabelRotation = function (axisType, value) {
            _this.chartOptionsService.setLabelRotation(axisType, value);
        };
        var updateAutoRotate = function (autoRotate) {
            _this.chartOptionsService.setAxisProperty("label.autoRotate", autoRotate);
            if (autoRotate) {
                // store prev rotations before we remove them from the options
                _this.prevXRotation = getLabelRotation("xAxis");
                _this.prevYRotation = getLabelRotation("yAxis");
                // `autoRotate` is only
                setLabelRotation("xAxis", undefined);
                setLabelRotation("yAxis", undefined);
            }
            else {
                // reinstate prev rotations
                setLabelRotation("xAxis", _this.prevXRotation);
                setLabelRotation("yAxis", _this.prevYRotation);
            }
            xRotationComp.setDisabled(autoRotate);
            yRotationComp.setDisabled(autoRotate);
        };
        var getAutoRotateValue = function () {
            var xRotation = getLabelRotation("xAxis");
            var yRotation = getLabelRotation("yAxis");
            if (xRotation == undefined && yRotation == undefined) {
                return _this.chartOptionsService.getAxisProperty("label.autoRotate");
            }
            return false;
        };
        var autoRotate = getAutoRotateValue();
        var autoRotateCheckbox = this.createBean(new AgCheckbox())
            .setLabel(this.translate('autoRotate'))
            .setValue(autoRotate)
            .onValueChange(updateAutoRotate);
        // init rotation comp state
        xRotationComp.setDisabled(autoRotate);
        yRotationComp.setDisabled(autoRotate);
        return autoRotateCheckbox;
    };
    AxisPanel.prototype.createRotationWidgets = function () {
        var _this = this;
        var degreesSymbol = String.fromCharCode(176);
        var createRotationComp = function (labelKey, axisType) {
            var label = "".concat(_this.chartTranslationService.translate(labelKey), " ").concat(degreesSymbol);
            var value = _this.chartOptionsService.getLabelRotation(axisType);
            var angleSelect = new AgAngleSelect()
                .setLabel(label)
                .setLabelWidth("flex")
                .setValue(value || 0)
                .onValueChange(function (newValue) { return _this.chartOptionsService.setLabelRotation(axisType, newValue); });
            // the axis label rotation needs to be updated when the default category changes in the data panel
            _this.axisLabelUpdateFuncs.push(function () {
                var value = _this.chartOptionsService.getLabelRotation(axisType);
                angleSelect.setValue(value || 0);
            });
            return _this.createBean(angleSelect);
        };
        return {
            xRotationComp: createRotationComp("xRotation", "xAxis"),
            yRotationComp: createRotationComp("yRotation", "yAxis")
        };
    };
    AxisPanel.prototype.addLabelPadding = function (labelPanelComp) {
        var _this = this;
        var labelPaddingSlider = this.createBean(new AgSlider());
        var currentValue = this.chartOptionsService.getAxisProperty("label.padding");
        labelPaddingSlider.setLabel(this.chartTranslationService.translate("padding"))
            .setMaxValue(getMaxValue(currentValue, 30))
            .setValue("".concat(currentValue))
            .setTextFieldWidth(45)
            .onValueChange(function (newValue) { return _this.chartOptionsService.setAxisProperty("label.padding", newValue); });
        labelPanelComp.addCompToPanel(labelPaddingSlider);
    };
    AxisPanel.prototype.translate = function (key, defaultText) {
        return this.chartTranslationService.translate(key, defaultText);
    };
    AxisPanel.prototype.destroyActivePanels = function () {
        var _this = this;
        this.activePanels.forEach(function (panel) {
            _.removeFromParent(panel.getGui());
            _this.destroyBean(panel);
        });
    };
    AxisPanel.prototype.destroy = function () {
        this.destroyActivePanels();
        _super.prototype.destroy.call(this);
    };
    AxisPanel.TEMPLATE = "<div>\n            <ag-group-component ref=\"axisGroup\">\n                <ag-color-picker ref=\"axisColorInput\"></ag-color-picker>\n                <ag-slider ref=\"axisLineWidthSlider\"></ag-slider>\n            </ag-group-component>\n        </div>";
    __decorate$16([
        RefSelector('axisGroup')
    ], AxisPanel.prototype, "axisGroup", void 0);
    __decorate$16([
        RefSelector('axisColorInput')
    ], AxisPanel.prototype, "axisColorInput", void 0);
    __decorate$16([
        RefSelector('axisLineWidthSlider')
    ], AxisPanel.prototype, "axisLineWidthSlider", void 0);
    __decorate$16([
        Autowired('chartTranslationService')
    ], AxisPanel.prototype, "chartTranslationService", void 0);
    __decorate$16([
        PostConstruct
    ], AxisPanel.prototype, "init", null);
    return AxisPanel;
}(Component));

var __extends$1B = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$15 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var NavigatorPanel = /** @class */ (function (_super) {
    __extends$1B(NavigatorPanel, _super);
    function NavigatorPanel(_a) {
        var chartOptionsService = _a.chartOptionsService, _b = _a.isExpandedOnInit, isExpandedOnInit = _b === void 0 ? false : _b;
        var _this = _super.call(this) || this;
        _this.chartOptionsService = chartOptionsService;
        _this.isExpandedOnInit = isExpandedOnInit;
        return _this;
    }
    NavigatorPanel.prototype.init = function () {
        var groupParams = {
            cssIdentifier: 'charts-format-top-level',
            direction: 'vertical'
        };
        this.setTemplate(NavigatorPanel.TEMPLATE, { navigatorGroup: groupParams });
        this.initNavigator();
    };
    NavigatorPanel.prototype.initNavigator = function () {
        var _this = this;
        var chartTranslationService = this.chartTranslationService;
        this.navigatorGroup
            .setTitle(chartTranslationService.translate("navigator"))
            .hideEnabledCheckbox(false)
            .setEnabled(this.chartOptionsService.getChartOption("navigator.enabled") || false)
            .onEnableChange(function (enabled) {
            _this.chartOptionsService.setChartOption("navigator.enabled", enabled);
            _this.navigatorGroup.toggleGroupExpand(true);
        })
            .toggleGroupExpand(this.isExpandedOnInit);
        var currentValue = this.chartOptionsService.getChartOption("navigator.height");
        this.navigatorHeightSlider
            .setLabel(chartTranslationService.translate("height"))
            .setMinValue(10)
            .setMaxValue(getMaxValue(currentValue, 60))
            .setTextFieldWidth(45)
            .setValue("".concat(currentValue || 30))
            .onValueChange(function (height) { return _this.chartOptionsService.setChartOption("navigator.height", height); });
    };
    NavigatorPanel.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
    };
    NavigatorPanel.TEMPLATE = "<div>\n            <ag-group-component ref=\"navigatorGroup\">\n                <ag-slider ref=\"navigatorHeightSlider\"></ag-slider>\n            </ag-group-component>\n        </div>";
    __decorate$15([
        RefSelector('navigatorGroup')
    ], NavigatorPanel.prototype, "navigatorGroup", void 0);
    __decorate$15([
        RefSelector('navigatorHeightSlider')
    ], NavigatorPanel.prototype, "navigatorHeightSlider", void 0);
    __decorate$15([
        Autowired('chartTranslationService')
    ], NavigatorPanel.prototype, "chartTranslationService", void 0);
    __decorate$15([
        PostConstruct
    ], NavigatorPanel.prototype, "init", null);
    return NavigatorPanel;
}(Component));

var __extends$1A = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$14 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$n = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$c = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var PaddingPanel = /** @class */ (function (_super) {
    __extends$1A(PaddingPanel, _super);
    function PaddingPanel(chartOptionsService, chartController) {
        var _this = _super.call(this) || this;
        _this.chartOptionsService = chartOptionsService;
        _this.chartController = chartController;
        return _this;
    }
    PaddingPanel.prototype.init = function () {
        var _this = this;
        var groupParams = {
            cssIdentifier: 'charts-format-sub-level',
            direction: 'vertical',
            suppressOpenCloseIcons: true
        };
        this.setTemplate(PaddingPanel.TEMPLATE, { chartPaddingGroup: groupParams });
        this.addManagedListener(this.eventService, Events.EVENT_CHART_OPTIONS_CHANGED, function (e) {
            _this.updateTopPadding(e.chartOptions);
        });
        this.initGroup();
        this.initChartPaddingItems();
    };
    PaddingPanel.prototype.initGroup = function () {
        this.chartPaddingGroup
            .setTitle(this.chartTranslationService.translate("padding"))
            .hideOpenCloseIcons(true)
            .hideEnabledCheckbox(true);
    };
    PaddingPanel.prototype.initChartPaddingItems = function () {
        var _this = this;
        var initInput = function (property, input) {
            var currentValue = _this.chartOptionsService.getChartOption('padding.' + property);
            input.setLabel(_this.chartTranslationService.translate(property))
                .setMaxValue(getMaxValue(currentValue, 200))
                .setValue("".concat(currentValue))
                .setTextFieldWidth(45)
                .onValueChange(function (newValue) { return _this.chartOptionsService.setChartOption('padding.' + property, newValue); });
        };
        initInput('top', this.paddingTopSlider);
        initInput('right', this.paddingRightSlider);
        initInput('bottom', this.paddingBottomSlider);
        initInput('left', this.paddingLeftSlider);
    };
    PaddingPanel.prototype.updateTopPadding = function (chartOptions) {
        // keep 'top' padding in sync with chart as toggling chart title on / off change the 'top' padding
        var topPadding = __spreadArray$c(__spreadArray$c([], __read$n(this.chartController.getChartSeriesTypes()), false), ['common'], false).map(function (seriesType) { var _a, _b; return (_b = (_a = chartOptions[seriesType]) === null || _a === void 0 ? void 0 : _a.padding) === null || _b === void 0 ? void 0 : _b.top; })
            .find(function (value) { return value != null; });
        if (topPadding != null) {
            this.paddingTopSlider.setValue(topPadding);
        }
    };
    PaddingPanel.TEMPLATE = "<div>\n            <ag-group-component ref=\"chartPaddingGroup\">\n                <ag-slider ref=\"paddingTopSlider\"></ag-slider>\n                <ag-slider ref=\"paddingRightSlider\"></ag-slider>\n                <ag-slider ref=\"paddingBottomSlider\"></ag-slider>\n                <ag-slider ref=\"paddingLeftSlider\"></ag-slider>\n            </ag-group-component>\n        <div>";
    __decorate$14([
        RefSelector('chartPaddingGroup')
    ], PaddingPanel.prototype, "chartPaddingGroup", void 0);
    __decorate$14([
        RefSelector('paddingTopSlider')
    ], PaddingPanel.prototype, "paddingTopSlider", void 0);
    __decorate$14([
        RefSelector('paddingRightSlider')
    ], PaddingPanel.prototype, "paddingRightSlider", void 0);
    __decorate$14([
        RefSelector('paddingBottomSlider')
    ], PaddingPanel.prototype, "paddingBottomSlider", void 0);
    __decorate$14([
        RefSelector('paddingLeftSlider')
    ], PaddingPanel.prototype, "paddingLeftSlider", void 0);
    __decorate$14([
        Autowired('chartTranslationService')
    ], PaddingPanel.prototype, "chartTranslationService", void 0);
    __decorate$14([
        PostConstruct
    ], PaddingPanel.prototype, "init", null);
    return PaddingPanel;
}(Component));

var __extends$1z = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$13 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var BackgroundPanel = /** @class */ (function (_super) {
    __extends$1z(BackgroundPanel, _super);
    function BackgroundPanel(chartOptionsService) {
        var _this = _super.call(this) || this;
        _this.chartOptionsService = chartOptionsService;
        return _this;
    }
    BackgroundPanel.prototype.init = function () {
        var groupParams = {
            cssIdentifier: 'charts-format-sub-level',
            direction: 'vertical',
            suppressOpenCloseIcons: true
        };
        this.setTemplate(BackgroundPanel.TEMPLATE, { chartBackgroundGroup: groupParams });
        this.initGroup();
        this.initColorPicker();
    };
    BackgroundPanel.prototype.initGroup = function () {
        var _this = this;
        this.group
            .setTitle(this.chartTranslationService.translate('background'))
            .setEnabled(this.chartOptionsService.getChartOption('background.visible'))
            .hideOpenCloseIcons(true)
            .hideEnabledCheckbox(false)
            .onEnableChange(function (enabled) { return _this.chartOptionsService.setChartOption('background.visible', enabled); });
    };
    BackgroundPanel.prototype.initColorPicker = function () {
        var _this = this;
        this.colorPicker
            .setLabel(this.chartTranslationService.translate('color'))
            .setLabelWidth('flex')
            .setInputWidth('flex')
            .setValue(this.chartOptionsService.getChartOption('background.fill'))
            .onValueChange(function (newColor) { return _this.chartOptionsService.setChartOption('background.fill', newColor); });
    };
    BackgroundPanel.TEMPLATE = "<div>\n            <ag-group-component ref=\"chartBackgroundGroup\">\n                <ag-color-picker ref=\"colorPicker\"></ag-color-picker>\n            </ag-group-component>\n        <div>";
    __decorate$13([
        RefSelector('chartBackgroundGroup')
    ], BackgroundPanel.prototype, "group", void 0);
    __decorate$13([
        RefSelector('colorPicker')
    ], BackgroundPanel.prototype, "colorPicker", void 0);
    __decorate$13([
        Autowired('chartTranslationService')
    ], BackgroundPanel.prototype, "chartTranslationService", void 0);
    __decorate$13([
        PostConstruct
    ], BackgroundPanel.prototype, "init", null);
    return BackgroundPanel;
}(Component));

var __extends$1y = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$12 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var TitlePanel = /** @class */ (function (_super) {
    __extends$1y(TitlePanel, _super);
    function TitlePanel(chartOptionsService) {
        var _this = _super.call(this, TitlePanel.TEMPLATE) || this;
        _this.chartOptionsService = chartOptionsService;
        _this.activePanels = [];
        return _this;
    }
    TitlePanel.prototype.init = function () {
        this.initFontPanel();
        this.titlePlaceholder = this.chartTranslationService.translate('titlePlaceholder');
    };
    TitlePanel.prototype.hasTitle = function () {
        var title = this.getOption('title');
        return title && title.enabled && title.text && title.text.length > 0;
    };
    TitlePanel.prototype.initFontPanel = function () {
        var _this = this;
        var hasTitle = this.hasTitle();
        var setFont = function (font, isSilent) {
            if (font.family) {
                _this.setOption('title.fontFamily', font.family, isSilent);
            }
            if (font.weight) {
                _this.setOption('title.fontWeight', font.weight, isSilent);
            }
            if (font.style) {
                _this.setOption('title.fontStyle', font.style, isSilent);
            }
            if (font.size) {
                _this.setOption('title.fontSize', font.size, isSilent);
            }
            if (font.color) {
                _this.setOption('title.color', font.color, isSilent);
            }
        };
        var initialFont = {
            family: this.getOption('title.fontFamily'),
            style: this.getOption('title.fontStyle'),
            weight: this.getOption('title.fontWeight'),
            size: this.getOption('title.fontSize'),
            color: this.getOption('title.color')
        };
        if (!hasTitle) {
            setFont(initialFont, true);
        }
        var fontPanelParams = {
            name: this.chartTranslationService.translate('title'),
            enabled: hasTitle,
            suppressEnabledCheckbox: false,
            initialFont: initialFont,
            setFont: setFont,
            setEnabled: function (enabled) {
                if (_this.toolbarExists()) {
                    // extra padding is only included when the toolbar is present
                    var topPadding = _this.getOption('padding.top');
                    _this.setOption('padding.top', enabled ? topPadding - 20 : topPadding + 20);
                }
                _this.setOption('title.enabled', enabled);
                var currentTitleText = _this.getOption('title.text');
                var replaceableTitleText = currentTitleText === 'Title' || (currentTitleText === null || currentTitleText === void 0 ? void 0 : currentTitleText.trim().length) === 0;
                if (enabled && replaceableTitleText) {
                    _this.setOption('title.text', _this.titlePlaceholder);
                }
            }
        };
        var fontPanelComp = this.createBean(new FontPanel(fontPanelParams));
        // add the title spacing slider to font panel
        fontPanelComp.addItemToPanel(this.createSpacingSlicer());
        this.getGui().appendChild(fontPanelComp.getGui());
        this.activePanels.push(fontPanelComp);
        // edits to the title can disable it, so keep the checkbox in sync:
        this.addManagedListener(this.eventService, 'chartTitleEdit', function () {
            fontPanelComp.setEnabled(_this.hasTitle());
        });
    };
    TitlePanel.prototype.createSpacingSlicer = function () {
        var _this = this;
        var spacingSlider = this.createBean(new AgSlider());
        var currentValue = this.chartOptionsService.getChartOption('title.spacing') || 10;
        spacingSlider.setLabel(this.chartTranslationService.translate('spacing'))
            .setMaxValue(Math.max(currentValue, 100))
            .setValue("".concat(currentValue))
            .setTextFieldWidth(45)
            .onValueChange(function (newValue) { return _this.chartOptionsService.setChartOption('title.spacing', newValue); });
        return spacingSlider;
    };
    TitlePanel.prototype.toolbarExists = function () {
        var toolbarItemsFunc = this.gridOptionsService.getCallback('getChartToolbarItems');
        if (!toolbarItemsFunc) {
            return true;
        }
        var params = {
            defaultItems: ['chartUnlink', 'chartDownload']
        };
        var topItems = ['chartLink', 'chartUnlink', 'chartDownload'];
        return topItems.some(function (v) { var _a; return (_a = (toolbarItemsFunc && toolbarItemsFunc(params))) === null || _a === void 0 ? void 0 : _a.includes(v); });
    };
    TitlePanel.prototype.getOption = function (expression) {
        return this.chartOptionsService.getChartOption(expression);
    };
    TitlePanel.prototype.setOption = function (property, value, isSilent) {
        this.chartOptionsService.setChartOption(property, value, isSilent);
    };
    TitlePanel.prototype.destroyActivePanels = function () {
        var _this = this;
        this.activePanels.forEach(function (panel) {
            _.removeFromParent(panel.getGui());
            _this.destroyBean(panel);
        });
    };
    TitlePanel.prototype.destroy = function () {
        this.destroyActivePanels();
        _super.prototype.destroy.call(this);
    };
    TitlePanel.TEMPLATE = "<div></div>";
    __decorate$12([
        Autowired('chartTranslationService')
    ], TitlePanel.prototype, "chartTranslationService", void 0);
    __decorate$12([
        PostConstruct
    ], TitlePanel.prototype, "init", null);
    return TitlePanel;
}(Component));

var __extends$1x = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$11 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ChartPanel = /** @class */ (function (_super) {
    __extends$1x(ChartPanel, _super);
    function ChartPanel(_a) {
        var chartController = _a.chartController, chartOptionsService = _a.chartOptionsService, _b = _a.isExpandedOnInit, isExpandedOnInit = _b === void 0 ? false : _b;
        var _this = _super.call(this) || this;
        _this.activePanels = [];
        _this.chartController = chartController;
        _this.chartOptionsService = chartOptionsService;
        _this.isExpandedOnInit = isExpandedOnInit;
        return _this;
    }
    ChartPanel.prototype.init = function () {
        var groupParams = {
            cssIdentifier: 'charts-format-top-level',
            direction: 'vertical'
        };
        this.setTemplate(ChartPanel.TEMPLATE, { chartGroup: groupParams });
        this.initGroup();
        this.initTitles();
        this.initPaddingPanel();
        this.initBackgroundPanel();
    };
    ChartPanel.prototype.initGroup = function () {
        this.chartGroup
            .setTitle(this.chartTranslationService.translate('chart'))
            .toggleGroupExpand(this.isExpandedOnInit)
            .hideEnabledCheckbox(true);
    };
    ChartPanel.prototype.initTitles = function () {
        var titlePanelComp = this.createBean(new TitlePanel(this.chartOptionsService));
        this.chartGroup.addItem(titlePanelComp);
        this.activePanels.push(titlePanelComp);
    };
    ChartPanel.prototype.initPaddingPanel = function () {
        var paddingPanelComp = this.createBean(new PaddingPanel(this.chartOptionsService, this.chartController));
        this.chartGroup.addItem(paddingPanelComp);
        this.activePanels.push(paddingPanelComp);
    };
    ChartPanel.prototype.initBackgroundPanel = function () {
        var backgroundPanelComp = this.createBean(new BackgroundPanel(this.chartOptionsService));
        this.chartGroup.addItem(backgroundPanelComp);
        this.activePanels.push(backgroundPanelComp);
    };
    ChartPanel.prototype.destroyActivePanels = function () {
        var _this = this;
        this.activePanels.forEach(function (panel) {
            _.removeFromParent(panel.getGui());
            _this.destroyBean(panel);
        });
    };
    ChartPanel.prototype.destroy = function () {
        this.destroyActivePanels();
        _super.prototype.destroy.call(this);
    };
    ChartPanel.TEMPLATE = "<div>\n            <ag-group-component ref=\"chartGroup\"></ag-group-component>\n        </div>";
    __decorate$11([
        RefSelector('chartGroup')
    ], ChartPanel.prototype, "chartGroup", void 0);
    __decorate$11([
        Autowired('chartTranslationService')
    ], ChartPanel.prototype, "chartTranslationService", void 0);
    __decorate$11([
        PostConstruct
    ], ChartPanel.prototype, "init", null);
    return ChartPanel;
}(Component));

var __extends$1w = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$10 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ShadowPanel = /** @class */ (function (_super) {
    __extends$1w(ShadowPanel, _super);
    function ShadowPanel(chartOptionsService, getSelectedSeries) {
        var _this = _super.call(this) || this;
        _this.chartOptionsService = chartOptionsService;
        _this.getSelectedSeries = getSelectedSeries;
        return _this;
    }
    ShadowPanel.prototype.init = function () {
        var groupParams = {
            cssIdentifier: 'charts-format-sub-level',
            direction: 'vertical',
            suppressOpenCloseIcons: true
        };
        this.setTemplate(ShadowPanel.TEMPLATE, { shadowGroup: groupParams });
        this.shadowBlurSlider.setTextFieldWidth(45);
        this.shadowXOffsetSlider.setTextFieldWidth(45);
        this.shadowYOffsetSlider.setTextFieldWidth(45);
        this.initSeriesShadow();
    };
    ShadowPanel.prototype.initSeriesShadow = function () {
        var _this = this;
        this.shadowGroup
            .setTitle(this.chartTranslationService.translate("shadow"))
            .setEnabled(this.chartOptionsService.getSeriesOption("shadow.enabled", this.getSelectedSeries()))
            .hideOpenCloseIcons(true)
            .hideEnabledCheckbox(false)
            .onEnableChange(function (newValue) { return _this.chartOptionsService.setSeriesOption("shadow.enabled", newValue, _this.getSelectedSeries()); });
        this.shadowColorPicker
            .setLabel(this.chartTranslationService.translate("color"))
            .setLabelWidth("flex")
            .setInputWidth('flex')
            .setValue(this.chartOptionsService.getSeriesOption("shadow.color", this.getSelectedSeries()))
            .onValueChange(function (newValue) { return _this.chartOptionsService.setSeriesOption("shadow.color", newValue, _this.getSelectedSeries()); });
        var initInput = function (input, property, minValue, defaultMaxValue) {
            var currentValue = _this.chartOptionsService.getSeriesOption("shadow.".concat(property), _this.getSelectedSeries());
            input.setLabel(_this.chartTranslationService.translate(property))
                .setMinValue(minValue)
                .setMaxValue(getMaxValue(currentValue, defaultMaxValue))
                .setValue("".concat(currentValue))
                .onValueChange(function (newValue) { return _this.chartOptionsService.setSeriesOption("shadow.".concat(property), newValue, _this.getSelectedSeries()); });
        };
        initInput(this.shadowBlurSlider, "blur", 0, 20);
        initInput(this.shadowXOffsetSlider, "xOffset", -10, 10);
        initInput(this.shadowYOffsetSlider, "yOffset", -10, 10);
    };
    ShadowPanel.TEMPLATE = "<div>\n            <ag-group-component ref=\"shadowGroup\">\n                <ag-color-picker ref=\"shadowColorPicker\"></ag-color-picker>\n                <ag-slider ref=\"shadowBlurSlider\"></ag-slider>\n                <ag-slider ref=\"shadowXOffsetSlider\"></ag-slider>\n                <ag-slider ref=\"shadowYOffsetSlider\"></ag-slider>\n            </ag-group-component>\n        </div>";
    __decorate$10([
        RefSelector('shadowGroup')
    ], ShadowPanel.prototype, "shadowGroup", void 0);
    __decorate$10([
        RefSelector('shadowColorPicker')
    ], ShadowPanel.prototype, "shadowColorPicker", void 0);
    __decorate$10([
        RefSelector('shadowBlurSlider')
    ], ShadowPanel.prototype, "shadowBlurSlider", void 0);
    __decorate$10([
        RefSelector('shadowXOffsetSlider')
    ], ShadowPanel.prototype, "shadowXOffsetSlider", void 0);
    __decorate$10([
        RefSelector('shadowYOffsetSlider')
    ], ShadowPanel.prototype, "shadowYOffsetSlider", void 0);
    __decorate$10([
        Autowired('chartTranslationService')
    ], ShadowPanel.prototype, "chartTranslationService", void 0);
    __decorate$10([
        PostConstruct
    ], ShadowPanel.prototype, "init", null);
    return ShadowPanel;
}(Component));

function initFontPanelParams(_a) {
    var labelName = _a.labelName, chartOptionsService = _a.chartOptionsService, getSelectedSeries = _a.getSelectedSeries, seriesOptionLabelProperty = _a.seriesOptionLabelProperty;
    var getFontOptionExpression = function (fontOption) {
        return "".concat(seriesOptionLabelProperty, ".").concat(fontOption);
    };
    var getFontOption = function (fontOption) {
        var expression = getFontOptionExpression(fontOption);
        return chartOptionsService.getSeriesOption(expression, getSelectedSeries());
    };
    var setFontOption = function (fontOption, value) {
        var expression = getFontOptionExpression(fontOption);
        chartOptionsService.setSeriesOption(expression, value, getSelectedSeries());
    };
    var initialFont = {
        family: getFontOption('fontFamily'),
        style: getFontOption('fontStyle'),
        weight: getFontOption('fontWeight'),
        size: getFontOption('fontSize'),
        color: getFontOption('color'),
    };
    var setFont = function (font) {
        if (font.family) {
            setFontOption('fontFamily', font.family);
        }
        if (font.weight) {
            setFontOption('fontWeight', font.weight);
        }
        if (font.style) {
            setFontOption('fontStyle', font.style);
        }
        if (font.size) {
            setFontOption('fontSize', font.size);
        }
        if (font.color) {
            setFontOption('color', font.color);
        }
    };
    var params = {
        name: labelName,
        enabled: getFontOption('enabled') || false,
        setEnabled: function (enabled) { return setFontOption('enabled', enabled); },
        suppressEnabledCheckbox: false,
        initialFont: initialFont,
        setFont: setFont
    };
    return params;
}

var __extends$1v = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$$ = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var MarkersPanel = /** @class */ (function (_super) {
    __extends$1v(MarkersPanel, _super);
    function MarkersPanel(chartOptionsService, getSelectedSeries) {
        var _this = _super.call(this) || this;
        _this.chartOptionsService = chartOptionsService;
        _this.getSelectedSeries = getSelectedSeries;
        return _this;
    }
    MarkersPanel.prototype.init = function () {
        var groupParams = {
            cssIdentifier: 'charts-format-sub-level',
            direction: 'vertical'
        };
        this.setTemplate(MarkersPanel.TEMPLATE, { seriesMarkersGroup: groupParams });
        this.initMarkers();
    };
    MarkersPanel.prototype.initMarkers = function () {
        var _this = this;
        var seriesMarkerShapeOptions = [
            {
                value: 'square',
                text: 'Square'
            },
            {
                value: 'circle',
                text: 'Circle'
            },
            {
                value: 'cross',
                text: 'Cross'
            },
            {
                value: 'diamond',
                text: 'Diamond'
            },
            {
                value: 'plus',
                text: 'Plus'
            },
            {
                value: 'triangle',
                text: 'Triangle'
            },
            {
                value: 'heart',
                text: 'Heart'
            }
        ];
        this.seriesMarkerShapeSelect
            .addOptions(seriesMarkerShapeOptions)
            .setLabel(this.chartTranslationService.translate('shape'))
            .setValue(this.getSeriesOption("marker.shape"))
            .onValueChange(function (value) { return _this.setSeriesOption("marker.shape", value); });
        // scatter charts should always show markers
        var chartType = this.chartOptionsService.getChartType();
        var shouldHideEnabledCheckbox = _.includes(['scatter', 'bubble'], chartType);
        this.seriesMarkersGroup
            .setTitle(this.chartTranslationService.translate("markers"))
            .hideEnabledCheckbox(shouldHideEnabledCheckbox)
            .setEnabled(this.getSeriesOption("marker.enabled") || false)
            .hideOpenCloseIcons(true)
            .onEnableChange(function (newValue) { return _this.setSeriesOption("marker.enabled", newValue); });
        var initInput = function (expression, input, labelKey, defaultMaxValue) {
            var currentValue = _this.getSeriesOption(expression);
            input.setLabel(_this.chartTranslationService.translate(labelKey))
                .setMaxValue(getMaxValue(currentValue, defaultMaxValue))
                .setValue("".concat(currentValue))
                .setTextFieldWidth(45)
                .onValueChange(function (newValue) { return _this.setSeriesOption(expression, newValue); });
        };
        if (chartType === 'bubble') {
            initInput("marker.maxSize", this.seriesMarkerMinSizeSlider, "maxSize", 60);
            initInput("marker.size", this.seriesMarkerSizeSlider, "minSize", 60);
        }
        else {
            this.seriesMarkerMinSizeSlider.setDisplayed(false);
            initInput("marker.size", this.seriesMarkerSizeSlider, "size", 60);
        }
        initInput("marker.strokeWidth", this.seriesMarkerStrokeWidthSlider, "strokeWidth", 10);
    };
    MarkersPanel.prototype.getSeriesOption = function (expression) {
        return this.chartOptionsService.getSeriesOption(expression, this.getSelectedSeries());
    };
    MarkersPanel.prototype.setSeriesOption = function (expression, newValue) {
        this.chartOptionsService.setSeriesOption(expression, newValue, this.getSelectedSeries());
    };
    MarkersPanel.TEMPLATE = "<div>\n            <ag-group-component ref=\"seriesMarkersGroup\">\n                <ag-select ref=\"seriesMarkerShapeSelect\"></ag-select>\n                <ag-slider ref=\"seriesMarkerMinSizeSlider\"></ag-slider>\n                <ag-slider ref=\"seriesMarkerSizeSlider\"></ag-slider>\n                <ag-slider ref=\"seriesMarkerStrokeWidthSlider\"></ag-slider>\n            </ag-group-component>\n        </div>";
    __decorate$$([
        RefSelector('seriesMarkersGroup')
    ], MarkersPanel.prototype, "seriesMarkersGroup", void 0);
    __decorate$$([
        RefSelector('seriesMarkerShapeSelect')
    ], MarkersPanel.prototype, "seriesMarkerShapeSelect", void 0);
    __decorate$$([
        RefSelector('seriesMarkerSizeSlider')
    ], MarkersPanel.prototype, "seriesMarkerSizeSlider", void 0);
    __decorate$$([
        RefSelector('seriesMarkerMinSizeSlider')
    ], MarkersPanel.prototype, "seriesMarkerMinSizeSlider", void 0);
    __decorate$$([
        RefSelector('seriesMarkerStrokeWidthSlider')
    ], MarkersPanel.prototype, "seriesMarkerStrokeWidthSlider", void 0);
    __decorate$$([
        Autowired('chartTranslationService')
    ], MarkersPanel.prototype, "chartTranslationService", void 0);
    __decorate$$([
        PostConstruct
    ], MarkersPanel.prototype, "init", null);
    return MarkersPanel;
}(Component));

var __extends$1u = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$_ = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var CalloutPanel = /** @class */ (function (_super) {
    __extends$1u(CalloutPanel, _super);
    function CalloutPanel(chartOptionsService, getSelectedSeries) {
        var _this = _super.call(this) || this;
        _this.chartOptionsService = chartOptionsService;
        _this.getSelectedSeries = getSelectedSeries;
        return _this;
    }
    CalloutPanel.prototype.init = function () {
        var groupParams = {
            cssIdentifier: 'charts-format-sub-level',
            direction: 'vertical'
        };
        this.setTemplate(CalloutPanel.TEMPLATE, { calloutGroup: groupParams });
        this.initCalloutOptions();
    };
    CalloutPanel.prototype.initCalloutOptions = function () {
        var _this = this;
        this.calloutGroup
            .setTitle(this.chartTranslationService.translate("callout"))
            .setEnabled(true)
            .hideOpenCloseIcons(true)
            .hideEnabledCheckbox(true);
        var initInput = function (expression, input, labelKey, defaultMaxValue) {
            var currentValue = _this.chartOptionsService.getSeriesOption(expression, _this.getSelectedSeries());
            input.setLabel(_this.chartTranslationService.translate(labelKey))
                .setMaxValue(getMaxValue(currentValue, defaultMaxValue))
                .setValue("".concat(currentValue))
                .setTextFieldWidth(45)
                .onValueChange(function (newValue) { return _this.chartOptionsService.setSeriesOption(expression, newValue, _this.getSelectedSeries()); });
        };
        initInput('calloutLine.length', this.calloutLengthSlider, 'length', 40);
        initInput('calloutLine.strokeWidth', this.calloutStrokeWidthSlider, 'strokeWidth', 10);
        initInput('calloutLabel.offset', this.labelOffsetSlider, 'offset', 30);
    };
    CalloutPanel.TEMPLATE = "<div>\n            <ag-group-component ref=\"calloutGroup\">\n                <ag-slider ref=\"calloutLengthSlider\"></ag-slider>\n                <ag-slider ref=\"calloutStrokeWidthSlider\"></ag-slider>\n                <ag-slider ref=\"labelOffsetSlider\"></ag-slider>\n            </ag-group-component>\n        </div>";
    __decorate$_([
        RefSelector('calloutGroup')
    ], CalloutPanel.prototype, "calloutGroup", void 0);
    __decorate$_([
        RefSelector('calloutLengthSlider')
    ], CalloutPanel.prototype, "calloutLengthSlider", void 0);
    __decorate$_([
        RefSelector('calloutStrokeWidthSlider')
    ], CalloutPanel.prototype, "calloutStrokeWidthSlider", void 0);
    __decorate$_([
        RefSelector('labelOffsetSlider')
    ], CalloutPanel.prototype, "labelOffsetSlider", void 0);
    __decorate$_([
        Autowired('chartTranslationService')
    ], CalloutPanel.prototype, "chartTranslationService", void 0);
    __decorate$_([
        PostConstruct
    ], CalloutPanel.prototype, "init", null);
    return CalloutPanel;
}(Component));

var __extends$1t = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$Z = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var SeriesPanel = /** @class */ (function (_super) {
    __extends$1t(SeriesPanel, _super);
    function SeriesPanel(_a) {
        var chartController = _a.chartController, chartOptionsService = _a.chartOptionsService, seriesType = _a.seriesType, _b = _a.isExpandedOnInit, isExpandedOnInit = _b === void 0 ? false : _b;
        var _this = _super.call(this) || this;
        _this.activePanels = [];
        _this.widgetFuncs = {
            'lineWidth': function () { return _this.initStrokeWidth('lineWidth'); },
            'strokeWidth': function () { return _this.initStrokeWidth('strokeWidth'); },
            'lineDash': function () { return _this.initLineDash(); },
            'lineOpacity': function () { return _this.initLineOpacity(); },
            'fillOpacity': function () { return _this.initFillOpacity(); },
            'markers': function () { return _this.initMarkers(); },
            'labels': function () { return _this.initLabels(); },
            'shadow': function () { return _this.initShadow(); },
            'tooltips': function () { return _this.initTooltips(); },
            'bins': function () { return _this.initBins(); },
        };
        _this.seriesWidgetMappings = {
            'area': ['tooltips', 'lineWidth', 'lineDash', 'lineOpacity', 'fillOpacity', 'markers', 'labels', 'shadow'],
            'bar': ['tooltips', 'strokeWidth', 'lineDash', 'lineOpacity', 'fillOpacity', 'labels', 'shadow'],
            'column': ['tooltips', 'strokeWidth', 'lineDash', 'lineOpacity', 'fillOpacity', 'labels', 'shadow'],
            'line': ['tooltips', 'lineWidth', 'lineDash', 'lineOpacity', 'markers', 'labels'],
            'histogram': ['tooltips', 'bins', 'strokeWidth', 'lineDash', 'lineOpacity', 'fillOpacity', 'labels', 'shadow'],
            'scatter': ['tooltips', 'markers', 'labels'],
            'bubble': ['tooltips', 'markers', 'labels'],
            'pie': ['tooltips', 'strokeWidth', 'lineOpacity', 'fillOpacity', 'labels', 'shadow'],
        };
        _this.chartController = chartController;
        _this.chartOptionsService = chartOptionsService;
        _this.seriesType = seriesType || _this.getChartSeriesType();
        _this.isExpandedOnInit = isExpandedOnInit;
        return _this;
    }
    SeriesPanel.prototype.init = function () {
        var groupParams = {
            cssIdentifier: 'charts-format-top-level',
            direction: 'vertical'
        };
        this.setTemplate(SeriesPanel.TEMPLATE, { seriesGroup: groupParams });
        this.seriesGroup
            .setTitle(this.translate("series"))
            .toggleGroupExpand(this.isExpandedOnInit)
            .hideEnabledCheckbox(true);
        this.addManagedListener(this.chartController, ChartController.EVENT_CHART_SERIES_CHART_TYPE_CHANGED, this.refreshWidgets.bind(this));
        this.refreshWidgets();
    };
    SeriesPanel.prototype.refreshWidgets = function () {
        var _this = this;
        this.destroyActivePanels();
        var chart = this.chartController.getChartProxy().getChart();
        chart.waitForUpdate().then(function () {
            var componentWasRemoved = !_this.isAlive();
            if (componentWasRemoved) {
                // It's possible that the component was unmounted during the async delay in updating the chart.
                // If this is the case we want to bail out to avoid operating on stale UI components.
                return;
            }
            if (_this.chartController.isComboChart()) {
                _this.updateSeriesType();
                _this.initSeriesSelect();
            }
            _this.seriesWidgetMappings[_this.seriesType].forEach(function (w) { return _this.widgetFuncs[w](); });
        })
            .catch(function (e) { return console.error("AG Grid - chart rendering failed", e); });
    };
    SeriesPanel.prototype.initSeriesSelect = function () {
        var _this = this;
        var seriesSelect = this.seriesGroup.createManagedBean(new AgSelect());
        seriesSelect
            .setLabel(this.translate('seriesType'))
            .setLabelAlignment("left")
            .setLabelWidth('flex')
            .setInputWidth('flex')
            .addOptions(this.getSeriesSelectOptions())
            .setValue("".concat(this.seriesType))
            .onValueChange(function (newValue) {
            _this.seriesType = newValue;
            _this.refreshWidgets();
        });
        this.seriesGroup.addItem(seriesSelect);
        this.activePanels.push(seriesSelect);
    };
    SeriesPanel.prototype.initTooltips = function () {
        var _this = this;
        var seriesTooltipsToggle = this.createBean(new AgToggleButton());
        seriesTooltipsToggle
            .setLabel(this.translate("tooltips"))
            .setLabelAlignment("left")
            .setLabelWidth("flex")
            .setInputWidth('flex')
            .setValue(this.getSeriesOption("tooltip.enabled") || false)
            .onValueChange(function (newValue) { return _this.setSeriesOption("tooltip.enabled", newValue); });
        this.addWidget(seriesTooltipsToggle);
    };
    SeriesPanel.prototype.initStrokeWidth = function (label) {
        var _this = this;
        var _a;
        var currentValue = (_a = this.getSeriesOption("strokeWidth")) !== null && _a !== void 0 ? _a : 0;
        var seriesStrokeWidthSlider = this.createBean(new AgSlider());
        seriesStrokeWidthSlider
            .setLabel(this.translate(label))
            .setMaxValue(getMaxValue(currentValue, 10))
            .setTextFieldWidth(45)
            .setValue("".concat(currentValue))
            .onValueChange(function (newValue) { return _this.setSeriesOption("strokeWidth", newValue); });
        this.addWidget(seriesStrokeWidthSlider);
    };
    SeriesPanel.prototype.initLineDash = function () {
        var _this = this;
        var lineDash = this.getSeriesOption("lineDash");
        var currentValue = lineDash ? lineDash[0] : 0;
        var seriesLineDashSlider = this.createBean(new AgSlider());
        seriesLineDashSlider
            .setLabel(this.translate('lineDash'))
            .setMaxValue(getMaxValue(currentValue, 30))
            .setTextFieldWidth(45)
            .setValue("".concat(currentValue))
            .onValueChange(function (newValue) { return _this.setSeriesOption("lineDash", [newValue]); });
        this.addWidget(seriesLineDashSlider);
    };
    SeriesPanel.prototype.initLineOpacity = function () {
        var _this = this;
        var _a;
        var currentValue = (_a = this.getSeriesOption("strokeOpacity")) !== null && _a !== void 0 ? _a : 0;
        var seriesLineOpacitySlider = this.createBean(new AgSlider());
        seriesLineOpacitySlider
            .setLabel(this.translate("strokeOpacity"))
            .setStep(0.05)
            .setMaxValue(getMaxValue(currentValue, 1))
            .setTextFieldWidth(45)
            .setValue("".concat(currentValue))
            .onValueChange(function (newValue) { return _this.setSeriesOption("strokeOpacity", newValue); });
        this.addWidget(seriesLineOpacitySlider);
    };
    SeriesPanel.prototype.initFillOpacity = function () {
        var _this = this;
        var _a;
        var currentValue = (_a = this.getSeriesOption("fillOpacity")) !== null && _a !== void 0 ? _a : 0;
        var seriesFillOpacitySlider = this.createBean(new AgSlider());
        seriesFillOpacitySlider
            .setLabel(this.translate("fillOpacity"))
            .setStep(0.05)
            .setMaxValue(getMaxValue(currentValue, 1))
            .setTextFieldWidth(45)
            .setValue("".concat(currentValue))
            .onValueChange(function (newValue) { return _this.setSeriesOption("fillOpacity", newValue); });
        this.addWidget(seriesFillOpacitySlider);
    };
    SeriesPanel.prototype.initLabels = function () {
        var _this = this;
        var seriesOptionLabelProperty = this.seriesType === 'pie' ? 'calloutLabel' : 'label';
        var labelName = this.seriesType === 'pie'
            ? this.chartTranslationService.translate('calloutLabels')
            : this.chartTranslationService.translate('labels');
        var labelParams = initFontPanelParams({
            labelName: labelName,
            chartOptionsService: this.chartOptionsService,
            getSelectedSeries: function () { return _this.seriesType; },
            seriesOptionLabelProperty: seriesOptionLabelProperty
        });
        var labelPanelComp = this.createBean(new FontPanel(labelParams));
        if (this.seriesType === 'pie') {
            var calloutPanelComp = this.createBean(new CalloutPanel(this.chartOptionsService, function () { return _this.seriesType; }));
            labelPanelComp.addCompToPanel(calloutPanelComp);
            this.activePanels.push(calloutPanelComp);
        }
        this.addWidget(labelPanelComp);
        if (this.seriesType === 'pie') {
            var sectorParams = initFontPanelParams({
                labelName: this.chartTranslationService.translate('sectorLabels'),
                chartOptionsService: this.chartOptionsService,
                getSelectedSeries: function () { return _this.seriesType; },
                seriesOptionLabelProperty: 'sectorLabel'
            });
            var sectorPanelComp = this.createBean(new FontPanel(sectorParams));
            var positionRatioComp = this.getSectorLabelPositionRatio();
            sectorPanelComp.addCompToPanel(positionRatioComp);
            this.addWidget(sectorPanelComp);
        }
    };
    SeriesPanel.prototype.getSectorLabelPositionRatio = function () {
        var _this = this;
        var expression = 'sectorLabel.positionRatio';
        var currentValue = this.chartOptionsService.getSeriesOption(expression, this.seriesType);
        var sectorLabelPositionRatioSlider = this.createBean(new AgSlider());
        return sectorLabelPositionRatioSlider
            .setLabel(this.translate("positionRatio"))
            .setStep(0.05)
            .setMaxValue(getMaxValue(currentValue, 1))
            .setTextFieldWidth(45)
            .setValue("".concat(currentValue))
            .onValueChange(function (newValue) { return _this.chartOptionsService.setSeriesOption(expression, newValue, _this.seriesType); });
    };
    SeriesPanel.prototype.initShadow = function () {
        var _this = this;
        var shadowPanelComp = this.createBean(new ShadowPanel(this.chartOptionsService, function () { return _this.seriesType; }));
        this.addWidget(shadowPanelComp);
    };
    SeriesPanel.prototype.initMarkers = function () {
        var _this = this;
        var markersPanelComp = this.createBean(new MarkersPanel(this.chartOptionsService, function () { return _this.seriesType; }));
        this.addWidget(markersPanelComp);
    };
    SeriesPanel.prototype.initBins = function () {
        var _this = this;
        var _a;
        var currentValue = ((_a = this.getSeriesOption("bins")) !== null && _a !== void 0 ? _a : this.getSeriesOption("calculatedBins", true)).length;
        var seriesBinCountSlider = this.createBean(new AgSlider());
        seriesBinCountSlider
            .setLabel(this.translate("histogramBinCount"))
            .setMinValue(0)
            .setMaxValue(getMaxValue(currentValue, 20))
            .setTextFieldWidth(45)
            .setValue("".concat(currentValue))
            .onValueChange(function (newValue) { return _this.setSeriesOption("binCount", newValue); });
        this.addWidget(seriesBinCountSlider);
    };
    SeriesPanel.prototype.addWidget = function (widget) {
        this.seriesGroup.addItem(widget);
        this.activePanels.push(widget);
    };
    SeriesPanel.prototype.getSeriesOption = function (expression, calculated) {
        return this.chartOptionsService.getSeriesOption(expression, this.seriesType, calculated);
    };
    SeriesPanel.prototype.setSeriesOption = function (expression, newValue) {
        this.chartOptionsService.setSeriesOption(expression, newValue, this.seriesType);
    };
    SeriesPanel.prototype.getChartSeriesType = function () {
        if (this.chartController.getSeriesChartTypes().length === 0) {
            return 'column';
        }
        var ct = this.chartController.getSeriesChartTypes()[0].chartType;
        if (ct === 'columnLineCombo') {
            return 'column';
        }
        if (ct === 'areaColumnCombo') {
            return 'area';
        }
        return getSeriesType(ct);
    };
    SeriesPanel.prototype.getSeriesSelectOptions = function () {
        var _this = this;
        if (!this.seriesSelectOptions) {
            // lazy init options as they are only required for combo charts
            this.seriesSelectOptions = new Map([
                ['area', { value: 'area', text: this.translate('area', 'Area') }],
                ['bar', { value: 'bar', text: this.translate('bar', 'Bar') }],
                ['column', { value: 'column', text: this.translate('column', 'Column') }],
                ['line', { value: 'line', text: this.translate('line', 'Line') }],
                ['scatter', { value: 'scatter', text: this.translate('scatter', 'Scatter') }],
                ['histogram', { value: 'histogram', text: this.translate('histogram', 'Histogram') }],
                ['pie', { value: 'pie', text: this.translate('pie', 'Pie') }],
            ]);
        }
        var seriesSelectOptions = new Set();
        this.chartController.getActiveSeriesChartTypes().forEach(function (s) {
            var chartType = getSeriesType(s.chartType);
            seriesSelectOptions.add(_this.seriesSelectOptions.get(chartType));
        });
        return Array.from(seriesSelectOptions);
    };
    SeriesPanel.prototype.updateSeriesType = function () {
        var activeChartTypes = this.chartController.getActiveSeriesChartTypes().map(function (s) { return getSeriesType(s.chartType); });
        var invalidSeriesType = !activeChartTypes.includes(this.seriesType);
        if (invalidSeriesType && activeChartTypes.length > 0) {
            this.seriesType = activeChartTypes[0]; // default to first active series type
        }
    };
    SeriesPanel.prototype.translate = function (key, defaultText) {
        return this.chartTranslationService.translate(key, defaultText);
    };
    SeriesPanel.prototype.destroyActivePanels = function () {
        var _this = this;
        this.activePanels.forEach(function (panel) {
            _.removeFromParent(panel.getGui());
            _this.destroyBean(panel);
        });
    };
    SeriesPanel.prototype.destroy = function () {
        this.destroyActivePanels();
        _super.prototype.destroy.call(this);
    };
    SeriesPanel.TEMPLATE = "<div>\n            <ag-group-component ref=\"seriesGroup\">\n            </ag-group-component>\n        </div>";
    __decorate$Z([
        RefSelector('seriesGroup')
    ], SeriesPanel.prototype, "seriesGroup", void 0);
    __decorate$Z([
        Autowired('chartTranslationService')
    ], SeriesPanel.prototype, "chartTranslationService", void 0);
    __decorate$Z([
        PostConstruct
    ], SeriesPanel.prototype, "init", null);
    return SeriesPanel;
}(Component));

var __extends$1s = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$Y = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
function getMaxValue(currentValue, defaultMaxValue) {
    return Math.max(currentValue, defaultMaxValue);
}
var DefaultFormatPanelDef = {
    groups: [
        { type: 'chart' },
        { type: 'legend' },
        { type: 'series' },
        { type: 'axis' },
        { type: 'navigator' },
    ]
};
var FormatPanel = /** @class */ (function (_super) {
    __extends$1s(FormatPanel, _super);
    function FormatPanel(chartController, chartOptionsService) {
        var _this = _super.call(this, FormatPanel.TEMPLATE) || this;
        _this.chartController = chartController;
        _this.chartOptionsService = chartOptionsService;
        _this.panels = [];
        _this.isGroupPanelShownInSeries = function (group, seriesType) {
            var commonGroupPanels = ['chart', 'legend', 'series'];
            if (commonGroupPanels.includes(group)) {
                return true;
            }
            var cartesianOnlyGroupPanels = ['axis', 'navigator'];
            var cartesianSeries = ['bar', 'column', 'line', 'area', 'scatter', 'bubble', 'histogram', 'cartesian'];
            return !!(cartesianOnlyGroupPanels.includes(group) && cartesianSeries.includes(seriesType));
        };
        return _this;
    }
    FormatPanel.prototype.init = function () {
        var _this = this;
        this.createPanels();
        this.addManagedListener(this.chartController, ChartController.EVENT_CHART_UPDATED, function () { return _this.createPanels(true); });
        this.addManagedListener(this.chartController, ChartController.EVENT_CHART_API_UPDATE, function () { return _this.createPanels(false); });
    };
    FormatPanel.prototype.createPanels = function (reuse) {
        var _this = this;
        var _a;
        var chartType = this.chartController.getChartType();
        var isGrouping = this.chartController.isGrouping();
        var seriesType = getSeriesType(chartType);
        if (reuse && chartType === this.chartType && isGrouping === this.isGrouping) {
            // existing panels can be re-used
            return;
        }
        this.destroyPanels();
        (_a = this.getFormatPanelDef().groups) === null || _a === void 0 ? void 0 : _a.forEach(function (groupDef) {
            var group = groupDef.type;
            // ensure the group should be displayed for the current series type
            if (!_this.isGroupPanelShownInSeries(group, seriesType)) {
                return;
            }
            var opts = {
                chartController: _this.chartController,
                chartOptionsService: _this.chartOptionsService,
                isExpandedOnInit: groupDef.isOpen,
                seriesType: seriesType
            };
            if (group === 'chart') {
                _this.addComponent(new ChartPanel(opts));
            }
            else if (group === 'legend') {
                _this.addComponent(new LegendPanel(opts));
            }
            else if (group === 'axis') {
                _this.addComponent(new AxisPanel(opts));
            }
            else if (group === 'series') {
                _this.addComponent(new SeriesPanel(opts));
            }
            else if (group === 'navigator') {
                _this.addComponent(new NavigatorPanel(opts));
            }
            else {
                console.warn("AG Grid: invalid charts format panel group name supplied: '".concat(groupDef.type, "'"));
            }
        });
        this.chartType = chartType;
        this.isGrouping = isGrouping;
    };
    FormatPanel.prototype.getFormatPanelDef = function () {
        var _a;
        var userProvidedFormatPanelDef = (_a = this.gridOptionsService.get('chartToolPanelsDef')) === null || _a === void 0 ? void 0 : _a.formatPanel;
        return userProvidedFormatPanelDef ? userProvidedFormatPanelDef : DefaultFormatPanelDef;
    };
    FormatPanel.prototype.addComponent = function (component) {
        this.createBean(component);
        this.panels.push(component);
        component.addCssClass('ag-chart-format-section');
        this.getGui().appendChild(component.getGui());
    };
    FormatPanel.prototype.destroyPanels = function () {
        var _this = this;
        this.panels.forEach(function (panel) {
            _.removeFromParent(panel.getGui());
            _this.destroyBean(panel);
        });
        this.panels = [];
    };
    FormatPanel.prototype.destroy = function () {
        this.destroyPanels();
        _super.prototype.destroy.call(this);
    };
    FormatPanel.TEMPLATE = "<div class=\"ag-chart-format-wrapper\"></div>";
    __decorate$Y([
        PostConstruct
    ], FormatPanel.prototype, "init", null);
    return FormatPanel;
}(Component));

var __extends$1r = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$X = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// import { _Scene } from "ag-charts-enterprise";
var CANVAS_CLASS = 'ag-chart-mini-thumbnail-canvas';
var ERROR_MESSAGE = 'AG Grid - chart update failed';
var MiniChart = /** @class */ (function (_super) {
    __extends$1r(MiniChart, _super);
    function MiniChart(container, tooltipName) {
        var _this = _super.call(this) || this;
        _this.size = 58;
        _this.padding = 5;
        _this.root = new integrated_charts_scene_exports$1.Group();
        var scene = new integrated_charts_scene_exports$1.Scene({
            window: window,
            document: window.document,
            width: _this.size,
            height: _this.size
        });
        scene.canvas.element.classList.add(CANVAS_CLASS);
        scene.root = _this.root;
        scene.container = container;
        _this.scene = scene;
        _this.tooltipName = tooltipName;
        return _this;
    }
    MiniChart.prototype.init = function () {
        this.scene.canvas.element.title = this.chartTranslationService.translate(this.tooltipName);
        // Necessary to force scene graph render as we are not using the standalone factory.
        this.scene.render()
            .catch(function (e) {
            console.error("".concat(ERROR_MESSAGE), e);
        });
    };
    __decorate$X([
        Autowired('chartTranslationService')
    ], MiniChart.prototype, "chartTranslationService", void 0);
    __decorate$X([
        PostConstruct
    ], MiniChart.prototype, "init", null);
    return MiniChart;
}(Component));

var __extends$1q = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$W = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// import { _Scene } from "ag-charts-enterprise";
var MiniChartWithAxes = /** @class */ (function (_super) {
    __extends$1q(MiniChartWithAxes, _super);
    function MiniChartWithAxes(container, tooltipName) {
        var _this = _super.call(this, container, tooltipName) || this;
        _this.stroke = 'gray';
        _this.axisOvershoot = 3;
        return _this;
    }
    MiniChartWithAxes.prototype.addAxes = function () {
        var size = this.size;
        var padding = this.padding;
        var leftAxis = new integrated_charts_scene_exports$1.Line();
        leftAxis.x1 = padding;
        leftAxis.y1 = padding;
        leftAxis.x2 = padding;
        leftAxis.y2 = size - padding + this.axisOvershoot;
        leftAxis.stroke = this.stroke;
        var bottomAxis = new integrated_charts_scene_exports$1.Line();
        bottomAxis.x1 = padding - this.axisOvershoot + 1;
        bottomAxis.y1 = size - padding;
        bottomAxis.x2 = size - padding + 1;
        bottomAxis.y2 = size - padding;
        bottomAxis.stroke = this.stroke;
        var root = this.root;
        root.append(leftAxis);
        root.append(bottomAxis);
    };
    __decorate$W([
        PostConstruct
    ], MiniChartWithAxes.prototype, "addAxes", null);
    return MiniChartWithAxes;
}(MiniChart));

function createColumnRects(params) {
    var stacked = params.stacked, size = params.size, padding = params.padding, xScalePadding = params.xScalePadding, xScaleDomain = params.xScaleDomain, yScaleDomain = params.yScaleDomain;
    var xScale = new integrated_charts_scene_exports$1.BandScale();
    xScale.domain = xScaleDomain;
    xScale.range = [padding, size - padding];
    xScale.paddingInner = xScalePadding;
    xScale.paddingOuter = xScalePadding;
    var yScale = new integrated_charts_scene_exports$1.LinearScale();
    yScale.domain = yScaleDomain;
    yScale.range = [size - padding, padding];
    var createBars = function (series, xScale, yScale) {
        return series.map(function (datum, i) {
            var top = yScale.convert(datum);
            var rect = new integrated_charts_scene_exports$1.Rect();
            rect.x = xScale.convert(i);
            rect.y = top;
            rect.width = xScale.bandwidth;
            rect.height = yScale.convert(0) - top;
            rect.strokeWidth = 1;
            rect.crisp = true;
            return rect;
        });
    };
    if (stacked) {
        return params.data.map(function (d) { return createBars(d, xScale, yScale); });
    }
    return createBars(params.data, xScale, yScale);
}
function createLinePaths(root, data, size, padding) {
    var xScale = new integrated_charts_scene_exports$1.LinearScale();
    xScale.domain = [0, 4];
    xScale.range = [padding, size - padding];
    var yScale = new integrated_charts_scene_exports$1.LinearScale();
    yScale.domain = [0, 10];
    yScale.range = [size - padding, padding];
    var lines = data.map(function (series) {
        var line = new integrated_charts_scene_exports$1.Path();
        line.strokeWidth = 3;
        line.lineCap = 'round';
        line.fill = undefined;
        series.forEach(function (datum, i) {
            line.path[i > 0 ? 'lineTo' : 'moveTo'](xScale.convert(i), yScale.convert(datum));
        });
        return line;
    });
    var linesGroup = new integrated_charts_scene_exports$1.Group();
    linesGroup.setClipRectInGroupCoordinateSpace(new integrated_charts_scene_exports$1.BBox(padding, padding, size - padding * 2, size - padding * 2));
    linesGroup.append(lines);
    root.append(linesGroup);
    return lines;
}

var __extends$1p = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var MiniColumn = /** @class */ (function (_super) {
    __extends$1p(MiniColumn, _super);
    function MiniColumn(container, fills, strokes) {
        var _this = _super.call(this, container, "groupedColumnTooltip") || this;
        _this.columnData = [2, 3, 4];
        var _a = _this, root = _a.root, columnData = _a.columnData, size = _a.size, padding = _a.padding;
        _this.columns = createColumnRects({
            stacked: false,
            root: root,
            data: columnData,
            size: size,
            padding: padding,
            xScaleDomain: [0, 1, 2],
            yScaleDomain: [0, 4],
            xScalePadding: 0.3
        });
        root.append(_this.columns);
        _this.updateColors(fills, strokes);
        return _this;
    }
    MiniColumn.prototype.updateColors = function (fills, strokes) {
        this.columns.forEach(function (column, i) {
            column.fill = fills[i];
            column.stroke = strokes[i];
        });
    };
    MiniColumn.chartType = 'groupedColumn';
    return MiniColumn;
}(MiniChartWithAxes));

var __extends$1o = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var MiniStackedColumn = /** @class */ (function (_super) {
    __extends$1o(MiniStackedColumn, _super);
    function MiniStackedColumn(container, fills, strokes, data, yScaleDomain, tooltipName) {
        if (data === void 0) { data = MiniStackedColumn.data; }
        if (yScaleDomain === void 0) { yScaleDomain = [0, 16]; }
        if (tooltipName === void 0) { tooltipName = "stackedColumnTooltip"; }
        var _this = _super.call(this, container, tooltipName) || this;
        var _a = _this, root = _a.root, size = _a.size, padding = _a.padding;
        _this.stackedColumns = createColumnRects({
            stacked: true,
            root: root,
            data: data,
            size: size,
            padding: padding,
            xScaleDomain: [0, 1, 2],
            yScaleDomain: yScaleDomain,
            xScalePadding: 0.3,
        });
        root.append([].concat.apply([], _this.stackedColumns));
        _this.updateColors(fills, strokes);
        return _this;
    }
    MiniStackedColumn.prototype.updateColors = function (fills, strokes) {
        this.stackedColumns.forEach(function (series, i) {
            return series.forEach(function (column) {
                column.fill = fills[i];
                column.stroke = strokes[i];
            });
        });
    };
    MiniStackedColumn.chartType = 'stackedColumn';
    MiniStackedColumn.data = [
        [8, 12, 16],
        [6, 9, 12],
        [2, 3, 4]
    ];
    return MiniStackedColumn;
}(MiniChartWithAxes));

var __extends$1n = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var MiniNormalizedColumn = /** @class */ (function (_super) {
    __extends$1n(MiniNormalizedColumn, _super);
    function MiniNormalizedColumn(container, fills, strokes) {
        return _super.call(this, container, fills, strokes, MiniNormalizedColumn.data, [0, 10], "normalizedColumnTooltip") || this;
    }
    MiniNormalizedColumn.chartType = 'normalizedColumn';
    MiniNormalizedColumn.data = [
        [10, 10, 10],
        [6, 7, 8],
        [2, 4, 6]
    ];
    return MiniNormalizedColumn;
}(MiniStackedColumn));

var __extends$1m = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var MiniBar = /** @class */ (function (_super) {
    __extends$1m(MiniBar, _super);
    function MiniBar(container, fills, strokes) {
        var _this = _super.call(this, container, "groupedBarTooltip") || this;
        var padding = _this.padding;
        var size = _this.size;
        var data = [2, 3, 4];
        var yScale = new integrated_charts_scene_exports$1.BandScale();
        yScale.domain = [0, 1, 2];
        yScale.range = [padding, size - padding];
        yScale.paddingInner = 0.3;
        yScale.paddingOuter = 0.3;
        var xScale = new integrated_charts_scene_exports$1.LinearScale();
        xScale.domain = [0, 4];
        xScale.range = [size - padding, padding];
        var bottom = xScale.convert(0);
        var height = yScale.bandwidth;
        _this.bars = data.map(function (datum, i) {
            var rect = new integrated_charts_scene_exports$1.Rect();
            rect.x = padding;
            rect.y = yScale.convert(i);
            rect.width = bottom - xScale.convert(datum);
            rect.height = height;
            rect.strokeWidth = 1;
            rect.crisp = true;
            return rect;
        });
        _this.updateColors(fills, strokes);
        _this.root.append(_this.bars);
        return _this;
    }
    MiniBar.prototype.updateColors = function (fills, strokes) {
        this.bars.forEach(function (bar, i) {
            bar.fill = fills[i];
            bar.stroke = strokes[i];
        });
    };
    MiniBar.chartType = 'groupedBar';
    return MiniBar;
}(MiniChartWithAxes));

var __extends$1l = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var MiniStackedBar = /** @class */ (function (_super) {
    __extends$1l(MiniStackedBar, _super);
    function MiniStackedBar(container, fills, strokes, data, xScaleDomain, tooltipName) {
        if (data === void 0) { data = MiniStackedBar.data; }
        if (xScaleDomain === void 0) { xScaleDomain = [0, 16]; }
        if (tooltipName === void 0) { tooltipName = "stackedBarTooltip"; }
        var _this = _super.call(this, container, tooltipName) || this;
        var size = _this.size;
        var padding = _this.padding;
        var yScale = new integrated_charts_scene_exports$1.BandScale();
        yScale.domain = [0, 1, 2];
        yScale.range = [padding, size - padding];
        yScale.paddingInner = 0.3;
        yScale.paddingOuter = 0.3;
        var xScale = new integrated_charts_scene_exports$1.LinearScale();
        xScale.domain = xScaleDomain;
        xScale.range = [size - padding, padding];
        var bottom = xScale.convert(0);
        var height = yScale.bandwidth;
        _this.bars = data.map(function (series) {
            return series.map(function (datum, i) {
                var rect = new integrated_charts_scene_exports$1.Rect();
                rect.x = padding;
                rect.y = yScale.convert(i);
                rect.width = bottom - xScale.convert(datum);
                rect.height = height;
                rect.strokeWidth = 1;
                rect.crisp = true;
                return rect;
            });
        });
        _this.updateColors(fills, strokes);
        _this.root.append([].concat.apply([], _this.bars));
        return _this;
    }
    MiniStackedBar.prototype.updateColors = function (fills, strokes) {
        this.bars.forEach(function (series, i) {
            return series.forEach(function (bar) {
                bar.fill = fills[i];
                bar.stroke = strokes[i];
            });
        });
    };
    MiniStackedBar.chartType = 'stackedBar';
    MiniStackedBar.data = [
        [8, 12, 16],
        [6, 9, 12],
        [2, 3, 4]
    ];
    return MiniStackedBar;
}(MiniChartWithAxes));

var __extends$1k = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var MiniNormalizedBar = /** @class */ (function (_super) {
    __extends$1k(MiniNormalizedBar, _super);
    function MiniNormalizedBar(container, fills, strokes) {
        return _super.call(this, container, fills, strokes, MiniNormalizedBar.data, [0, 10], "normalizedBarTooltip") || this;
    }
    MiniNormalizedBar.chartType = 'normalizedBar';
    MiniNormalizedBar.data = [
        [10, 10, 10],
        [6, 7, 8],
        [2, 4, 6]
    ];
    return MiniNormalizedBar;
}(MiniStackedBar));

var __extends$1j = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read$m = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var toRadians$1 = integrated_charts_scene_exports$1.toRadians;
var MiniDoughnut = /** @class */ (function (_super) {
    __extends$1j(MiniDoughnut, _super);
    function MiniDoughnut(container, fills, strokes, centerRadiusScaler, tooltipName) {
        if (centerRadiusScaler === void 0) { centerRadiusScaler = 0.6; }
        if (tooltipName === void 0) { tooltipName = "doughnutTooltip"; }
        var _this = _super.call(this, container, tooltipName) || this;
        var radius = (_this.size - _this.padding * 2) / 2;
        var center = radius + _this.padding;
        var angles = [
            [toRadians$1(-90), toRadians$1(30)],
            [toRadians$1(30), toRadians$1(120)],
            [toRadians$1(120), toRadians$1(180)],
            [toRadians$1(180), toRadians$1(210)],
            [toRadians$1(210), toRadians$1(240)],
            [toRadians$1(240), toRadians$1(270)]
        ];
        _this.sectors = angles.map(function (_a) {
            var _b = __read$m(_a, 2), startAngle = _b[0], endAngle = _b[1];
            var sector = new integrated_charts_scene_exports$1.Sector();
            sector.centerX = center;
            sector.centerY = center;
            sector.innerRadius = radius * centerRadiusScaler;
            sector.outerRadius = radius;
            sector.startAngle = startAngle;
            sector.endAngle = endAngle;
            sector.stroke = undefined;
            sector.strokeWidth = 1;
            return sector;
        });
        _this.updateColors(fills, strokes);
        _this.root.append(_this.sectors);
        return _this;
    }
    MiniDoughnut.prototype.updateColors = function (fills, strokes) {
        this.sectors.forEach(function (sector, i) {
            sector.fill = fills[i % fills.length];
            sector.stroke = strokes[i % strokes.length];
        });
    };
    MiniDoughnut.chartType = 'doughnut';
    return MiniDoughnut;
}(MiniChart));

var __extends$1i = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var MiniPie = /** @class */ (function (_super) {
    __extends$1i(MiniPie, _super);
    function MiniPie(container, fills, strokes) {
        return _super.call(this, container, fills, strokes, 0, "pieTooltip") || this;
    }
    MiniPie.chartType = 'pie';
    return MiniPie;
}(MiniDoughnut));

var __extends$1h = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var MiniLine = /** @class */ (function (_super) {
    __extends$1h(MiniLine, _super);
    function MiniLine(container, fills, strokes) {
        var _this = _super.call(this, container, "lineTooltip") || this;
        _this.data = [
            [9, 7, 8, 5, 6],
            [5, 6, 3, 4, 1],
            [1, 3, 4, 8, 7]
        ];
        _this.lines = createLinePaths(_this.root, _this.data, _this.size, _this.padding);
        _this.updateColors(fills, strokes);
        return _this;
    }
    MiniLine.prototype.updateColors = function (fills, strokes) {
        this.lines.forEach(function (line, i) {
            line.stroke = fills[i];
        });
    };
    MiniLine.chartType = 'line';
    return MiniLine;
}(MiniChartWithAxes));

var __extends$1g = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read$l = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var MiniScatter = /** @class */ (function (_super) {
    __extends$1g(MiniScatter, _super);
    function MiniScatter(container, fills, strokes) {
        var _this = _super.call(this, container, "scatterTooltip") || this;
        var size = _this.size;
        var padding = _this.padding;
        // [x, y] pairs
        var data = [
            [[0.3, 3], [1.1, 0.9], [2, 0.4], [3.4, 2.4]],
            [[0, 0.3], [1, 2], [2.4, 1.4], [3, 0]]
        ];
        var xScale = new integrated_charts_scene_exports$1.LinearScale();
        xScale.domain = [-0.5, 4];
        xScale.range = [padding * 2, size - padding];
        var yScale = new integrated_charts_scene_exports$1.LinearScale();
        yScale.domain = [-0.5, 3.5];
        yScale.range = [size - padding, padding];
        var points = [];
        data.forEach(function (series) {
            series.forEach(function (_a) {
                var _b = __read$l(_a, 2), x = _b[0], y = _b[1];
                var arc = new integrated_charts_scene_exports$1.Arc();
                arc.strokeWidth = 1;
                arc.centerX = xScale.convert(x);
                arc.centerY = yScale.convert(y);
                arc.radius = 2.5;
                points.push(arc);
            });
        });
        _this.points = points;
        _this.updateColors(fills, strokes);
        var pointsGroup = new integrated_charts_scene_exports$1.Group();
        pointsGroup.setClipRectInGroupCoordinateSpace(new integrated_charts_scene_exports$1.BBox(padding, padding, size - padding * 2, size - padding * 2));
        pointsGroup.append(_this.points);
        _this.root.append(pointsGroup);
        return _this;
    }
    MiniScatter.prototype.updateColors = function (fills, strokes) {
        this.points.forEach(function (line, i) {
            line.stroke = strokes[i % strokes.length];
            line.fill = fills[i % fills.length];
        });
    };
    MiniScatter.chartType = 'scatter';
    return MiniScatter;
}(MiniChartWithAxes));

var __extends$1f = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read$k = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var MiniBubble = /** @class */ (function (_super) {
    __extends$1f(MiniBubble, _super);
    function MiniBubble(container, fills, strokes) {
        var _this = _super.call(this, container, "bubbleTooltip") || this;
        var size = _this.size;
        var padding = _this.padding;
        // [x, y, radius] triples
        var data = [
            [[0.1, 0.3, 5], [0.5, 0.4, 7], [0.2, 0.8, 7]], [[0.8, 0.7, 5], [0.7, 0.3, 9]]
        ];
        var xScale = new integrated_charts_scene_exports$1.LinearScale();
        xScale.domain = [0, 1];
        xScale.range = [padding * 2, size - padding];
        var yScale = new integrated_charts_scene_exports$1.LinearScale();
        yScale.domain = [0, 1];
        yScale.range = [size - padding, padding];
        var points = [];
        data.forEach(function (series) {
            series.forEach(function (_a) {
                var _b = __read$k(_a, 3), x = _b[0], y = _b[1], radius = _b[2];
                var arc = new integrated_charts_scene_exports$1.Arc();
                arc.strokeWidth = 1;
                arc.centerX = xScale.convert(x);
                arc.centerY = yScale.convert(y);
                arc.radius = radius;
                arc.fillOpacity = 0.7;
                points.push(arc);
            });
        });
        _this.points = points;
        _this.updateColors(fills, strokes);
        var pointsGroup = new integrated_charts_scene_exports$1.Group();
        pointsGroup.setClipRectInGroupCoordinateSpace(new integrated_charts_scene_exports$1.BBox(padding, padding, size - padding * 2, size - padding * 2));
        pointsGroup.append(_this.points);
        _this.root.append(pointsGroup);
        return _this;
    }
    MiniBubble.prototype.updateColors = function (fills, strokes) {
        this.points.forEach(function (line, i) {
            line.stroke = strokes[i % strokes.length];
            line.fill = fills[i % fills.length];
        });
    };
    MiniBubble.chartType = 'bubble';
    return MiniBubble;
}(MiniChartWithAxes));

var __extends$1e = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var MiniArea = /** @class */ (function (_super) {
    __extends$1e(MiniArea, _super);
    function MiniArea(container, fills, strokes, data) {
        if (data === void 0) { data = MiniArea.data; }
        var _this = _super.call(this, container, "groupedAreaTooltip") || this;
        var size = _this.size;
        var padding = _this.padding;
        var xScale = new integrated_charts_scene_exports$1.BandScale();
        xScale.domain = [0, 1, 2];
        xScale.paddingInner = 1;
        xScale.paddingOuter = 0;
        xScale.range = [padding + 0.5, size - padding - 0.5];
        var yScale = new integrated_charts_scene_exports$1.LinearScale();
        yScale.domain = [0, 6];
        yScale.range = [size - padding + 0.5, padding];
        var xCount = data.length;
        var last = xCount * 2 - 1;
        var pathData = [];
        var bottomY = yScale.convert(0);
        data.forEach(function (datum, i) {
            var x = xScale.convert(i);
            datum.forEach(function (yDatum, j) {
                var y = yScale.convert(yDatum);
                var points = pathData[j] || (pathData[j] = []);
                points[i] = {
                    x: x,
                    y: y
                };
                points[last - i] = {
                    x: x,
                    y: bottomY
                };
            });
        });
        _this.areas = pathData.reverse().map(function (points) {
            var area = new integrated_charts_scene_exports$1.Path();
            area.strokeWidth = 1;
            area.fillOpacity = 0.7;
            var path = area.path;
            path.clear();
            points.forEach(function (point, i) { return path[i > 0 ? "lineTo" : "moveTo"](point.x, point.y); });
            path.closePath();
            return area;
        });
        _this.updateColors(fills, strokes);
        _this.root.append(_this.areas);
        return _this;
    }
    MiniArea.prototype.updateColors = function (fills, strokes) {
        this.areas.forEach(function (area, i) {
            area.fill = fills[i];
            area.stroke = strokes[i];
        });
    };
    MiniArea.chartType = 'area';
    MiniArea.data = [
        [1, 3, 5],
        [2, 6, 4],
        [5, 3, 1]
    ];
    return MiniArea;
}(MiniChartWithAxes));

var __extends$1d = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var MiniStackedArea = /** @class */ (function (_super) {
    __extends$1d(MiniStackedArea, _super);
    function MiniStackedArea(container, fills, strokes, data, tooltipName) {
        if (data === void 0) { data = MiniStackedArea.data; }
        if (tooltipName === void 0) { tooltipName = "stackedAreaTooltip"; }
        var _this = _super.call(this, container, tooltipName) || this;
        var size = _this.size;
        var padding = _this.padding;
        var xScale = new integrated_charts_scene_exports$1.BandScale();
        xScale.domain = [0, 1, 2];
        xScale.paddingInner = 1;
        xScale.paddingOuter = 0;
        xScale.range = [padding + 0.5, size - padding - 0.5];
        var yScale = new integrated_charts_scene_exports$1.LinearScale();
        yScale.domain = [0, 16];
        yScale.range = [size - padding + 0.5, padding + 0.5];
        var xCount = data.length;
        var last = xCount * 2 - 1;
        var pathData = [];
        data.forEach(function (datum, i) {
            var x = xScale.convert(i);
            var total = 0;
            datum.forEach(function (yDatum, j) {
                var y = yScale.convert(total + yDatum);
                var points = pathData[j] || (pathData[j] = []);
                points[i] = {
                    x: x,
                    y: y
                };
                points[last - i] = {
                    x: x,
                    y: yScale.convert(total) // bottom y
                };
                total += yDatum;
            });
        });
        _this.areas = pathData.map(function (points) {
            var area = new integrated_charts_scene_exports$1.Path();
            area.strokeWidth = 1;
            var path = area.path;
            path.clear();
            points.forEach(function (point, i) { return path[i > 0 ? "lineTo" : "moveTo"](point.x, point.y); });
            path.closePath();
            return area;
        });
        _this.updateColors(fills, strokes);
        _this.root.append(_this.areas);
        return _this;
    }
    MiniStackedArea.prototype.updateColors = function (fills, strokes) {
        this.areas.forEach(function (area, i) {
            area.fill = fills[i];
            area.stroke = strokes[i];
        });
    };
    MiniStackedArea.chartType = 'stackedArea';
    MiniStackedArea.data = [
        [2, 3, 2],
        [3, 6, 5],
        [6, 2, 2]
    ];
    return MiniStackedArea;
}(MiniChartWithAxes));

var __extends$1c = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var MiniNormalizedArea = /** @class */ (function (_super) {
    __extends$1c(MiniNormalizedArea, _super);
    function MiniNormalizedArea(container, fills, strokes, data) {
        if (data === void 0) { data = MiniNormalizedArea.data; }
        return _super.call(this, container, fills, strokes, data, "normalizedAreaTooltip") || this;
    }
    MiniNormalizedArea.chartType = 'normalizedArea';
    MiniNormalizedArea.data = MiniStackedArea.data.map(function (stack) {
        var sum = stack.reduce(function (p, c) { return p + c; }, 0);
        return stack.map(function (v) { return v / sum * 16; });
    });
    return MiniNormalizedArea;
}(MiniStackedArea));

var __extends$1b = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read$j = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var MiniHistogram = /** @class */ (function (_super) {
    __extends$1b(MiniHistogram, _super);
    function MiniHistogram(container, fills, strokes) {
        var _this = _super.call(this, container, "histogramTooltip") || this;
        var padding = _this.padding;
        var size = _this.size;
        // approx normal curve
        var data = [2, 5, 11, 13, 10, 6, 1];
        var xScale = new integrated_charts_scene_exports$1.LinearScale();
        xScale.domain = [0, data.length];
        xScale.range = [padding, size - padding];
        var yScale = new integrated_charts_scene_exports$1.LinearScale();
        yScale.domain = [0, data.reduce(function (a, b) { return Math.max(a, b); }, 0)];
        yScale.range = [size - padding, padding];
        var bottom = yScale.convert(0);
        _this.bars = data.map(function (datum, i) {
            var top = yScale.convert(datum);
            var left = xScale.convert(i);
            var right = xScale.convert(i + 1);
            var rect = new integrated_charts_scene_exports$1.Rect();
            rect.x = left;
            rect.y = top;
            rect.width = right - left;
            rect.height = bottom - top;
            rect.strokeWidth = 1;
            rect.crisp = true;
            return rect;
        });
        _this.updateColors(fills, strokes);
        _this.root.append(_this.bars);
        return _this;
    }
    MiniHistogram.prototype.updateColors = function (_a, _b) {
        var _c = __read$j(_a, 1), fill = _c[0];
        var _d = __read$j(_b, 1), stroke = _d[0];
        this.bars.forEach(function (bar) {
            bar.fill = fill;
            bar.stroke = stroke;
        });
    };
    MiniHistogram.chartType = 'histogram';
    return MiniHistogram;
}(MiniChartWithAxes));

var __extends$1a = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var MiniColumnLineCombo = /** @class */ (function (_super) {
    __extends$1a(MiniColumnLineCombo, _super);
    function MiniColumnLineCombo(container, fills, strokes) {
        var _this = _super.call(this, container, "columnLineComboTooltip") || this;
        _this.columnData = [3, 4];
        _this.lineData = [
            [5, 4, 6, 5, 4]
        ];
        var _a = _this, root = _a.root, columnData = _a.columnData, lineData = _a.lineData, size = _a.size, padding = _a.padding;
        _this.columns = createColumnRects({
            stacked: false,
            root: root,
            data: columnData,
            size: size,
            padding: padding,
            xScaleDomain: [0, 1],
            yScaleDomain: [0, 4],
            xScalePadding: 0.5
        });
        root.append(_this.columns);
        _this.lines = createLinePaths(root, lineData, size, padding);
        _this.updateColors(fills, strokes);
        return _this;
    }
    MiniColumnLineCombo.prototype.updateColors = function (fills, strokes) {
        this.columns.forEach(function (bar, i) {
            bar.fill = fills[i];
            bar.stroke = strokes[i];
        });
        this.lines.forEach(function (line, i) {
            line.stroke = fills[i + 2];
        });
    };
    MiniColumnLineCombo.chartType = 'columnLineCombo';
    return MiniColumnLineCombo;
}(MiniChartWithAxes));

var __extends$19 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var MiniAreaColumnCombo = /** @class */ (function (_super) {
    __extends$19(MiniAreaColumnCombo, _super);
    function MiniAreaColumnCombo(container, fills, strokes) {
        var _this = _super.call(this, container, "areaColumnComboTooltip") || this;
        _this.columnData = [3, 4.5];
        _this.areaData = [
            [5, 4, 6, 5, 4],
        ];
        var _a = _this, root = _a.root, columnData = _a.columnData, areaData = _a.areaData, size = _a.size, padding = _a.padding;
        _this.columns = createColumnRects({
            stacked: false,
            root: root,
            data: columnData,
            size: size,
            padding: padding,
            xScaleDomain: [0, 1],
            yScaleDomain: [0, 6],
            xScalePadding: 0.5,
        });
        // scale for area series
        var xScale = new integrated_charts_scene_exports$1.BandScale();
        xScale.range = [padding, size - padding];
        xScale.domain = [0, 1, 2, 3, 4];
        xScale.paddingInner = 1;
        xScale.paddingOuter = 0;
        var yScale = new integrated_charts_scene_exports$1.LinearScale();
        yScale.range = [size - padding, padding];
        yScale.domain = [0, 6];
        var pathData = [];
        var yZero = yScale.convert(0);
        var firstX = xScale.convert(0);
        areaData.forEach(function (series, i) {
            var points = pathData[i] || (pathData[i] = []);
            series.forEach(function (data, j) {
                var yDatum = data;
                var xDatum = j;
                var x = xScale.convert(xDatum);
                var y = yScale.convert(yDatum);
                points[j] = { x: x, y: y };
            });
            var lastX = xScale.convert(series.length - 1);
            pathData[i].push({
                x: lastX,
                y: yZero
            }, {
                x: firstX,
                y: yZero
            });
        });
        _this.areas = pathData.map(function (points) {
            var area = new integrated_charts_scene_exports$1.Path();
            area.strokeWidth = 1;
            area.fillOpacity = 0.8;
            var path = area.path;
            points.forEach(function (point, i) { return path[i > 0 ? 'lineTo' : 'moveTo'](point.x, point.y); });
            return area;
        });
        root.append(_this.areas);
        root.append([].concat.apply([], _this.columns));
        _this.updateColors(fills, strokes);
        return _this;
    }
    MiniAreaColumnCombo.prototype.updateColors = function (fills, strokes) {
        this.areas.forEach(function (area, i) {
            area.fill = fills[i];
            area.stroke = strokes[i];
        });
        this.columns.forEach(function (bar, i) {
            bar.fill = fills[i + 1];
            bar.stroke = strokes[i + 1];
        });
    };
    MiniAreaColumnCombo.chartType = 'areaColumnCombo';
    return MiniAreaColumnCombo;
}(MiniChartWithAxes));

var __extends$18 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var MiniCustomCombo = /** @class */ (function (_super) {
    __extends$18(MiniCustomCombo, _super);
    function MiniCustomCombo(container, fills, strokes) {
        var _this = _super.call(this, container, 'customComboTooltip') || this;
        _this.columnData = [3, 4];
        _this.lineData = [[5, 4, 6, 5, 4]];
        var _a = _this, root = _a.root, columnData = _a.columnData, lineData = _a.lineData, size = _a.size, padding = _a.padding;
        _this.columns = createColumnRects({
            stacked: false,
            root: root,
            data: columnData,
            size: size,
            padding: padding,
            xScaleDomain: [0, 1],
            yScaleDomain: [0, 4],
            xScalePadding: 0.5,
        });
        root.append(_this.columns);
        _this.lines = createLinePaths(root, lineData, size, padding);
        var axisStroke = 'grey';
        var axisOvershoot = 3;
        var leftAxis = new integrated_charts_scene_exports$1.Line();
        leftAxis.x1 = padding;
        leftAxis.y1 = padding;
        leftAxis.x2 = padding;
        leftAxis.y2 = size - padding + axisOvershoot;
        leftAxis.stroke = axisStroke;
        var bottomAxis = new integrated_charts_scene_exports$1.Line();
        bottomAxis.x1 = padding - axisOvershoot + 1;
        bottomAxis.y1 = size - padding;
        bottomAxis.x2 = size - padding + 1;
        bottomAxis.y2 = size - padding;
        bottomAxis.stroke = axisStroke;
        var penIcon = new integrated_charts_scene_exports$1.Path();
        _this.buildPenIconPath(penIcon);
        penIcon.fill = 'whitesmoke';
        penIcon.stroke = 'darkslategrey';
        penIcon.strokeWidth = 1;
        root.append([bottomAxis, leftAxis, penIcon]);
        _this.updateColors(fills, strokes);
        return _this;
    }
    MiniCustomCombo.prototype.updateColors = function (fills, strokes) {
        this.columns.forEach(function (bar, i) {
            bar.fill = fills[i];
            bar.stroke = strokes[i];
        });
        this.lines.forEach(function (line, i) {
            line.stroke = fills[i + 2];
        });
    };
    MiniCustomCombo.prototype.buildPenIconPath = function (penIcon) {
        var path = penIcon.path;
        path.moveTo(25.76, 43.46);
        path.lineTo(31.27, 48.53);
        path.moveTo(49.86, 22);
        path.lineTo(49.86, 22);
        path.cubicCurveTo(49.01994659053345, 21.317514933510974, 47.89593834348529, 21.09645997825817, 46.86, 21.41);
        path.lineTo(46.86, 21.41);
        path.cubicCurveTo(45.55460035985361, 21.77260167850787, 44.38777081121966, 22.517979360321792, 43.51, 23.55);
        path.lineTo(25.51, 43.8);
        path.lineTo(25.43, 43.89);
        path.lineTo(23.01, 51.89);
        path.lineTo(22.83, 52.46);
        path.lineTo(31.02, 48.86);
        path.lineTo(49.02, 28.52);
        path.lineTo(49.02, 28.52);
        path.cubicCurveTo(49.940716461596224, 27.521914221246085, 50.54302631059587, 26.2720342455763, 50.75, 24.93);
        path.lineTo(50.75, 24.93);
        path.cubicCurveTo(50.95363374988308, 23.866379846512814, 50.62080640232334, 22.77066734274871, 49.86, 22.0);
        path.closePath();
        path.moveTo(41.76, 25.5);
        path.lineTo(47.34, 30.5);
        path.moveTo(40.74, 26.65);
        path.lineTo(46.25, 31.71);
    };
    MiniCustomCombo.chartType = 'customCombo';
    return MiniCustomCombo;
}(MiniChart));

var __extends$17 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$o = (undefined && undefined.__assign) || function () {
    __assign$o = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$o.apply(this, arguments);
};
var __decorate$V = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var miniChartMapping = {
    columnGroup: {
        column: MiniColumn,
        stackedColumn: MiniStackedColumn,
        normalizedColumn: MiniNormalizedColumn
    },
    barGroup: {
        bar: MiniBar,
        stackedBar: MiniStackedBar,
        normalizedBar: MiniNormalizedBar
    },
    pieGroup: {
        pie: MiniPie,
        doughnut: MiniDoughnut
    },
    lineGroup: {
        line: MiniLine
    },
    scatterGroup: {
        scatter: MiniScatter,
        bubble: MiniBubble
    },
    areaGroup: {
        area: MiniArea,
        stackedArea: MiniStackedArea,
        normalizedArea: MiniNormalizedArea
    },
    histogramGroup: {
        histogram: MiniHistogram
    },
    combinationGroup: {
        columnLineCombo: MiniColumnLineCombo,
        areaColumnCombo: MiniAreaColumnCombo,
        customCombo: MiniCustomCombo
    }
};
var MiniChartsContainer = /** @class */ (function (_super) {
    __extends$17(MiniChartsContainer, _super);
    function MiniChartsContainer(chartController, fills, strokes, chartGroups) {
        if (chartGroups === void 0) { chartGroups = DEFAULT_CHART_GROUPS; }
        var _this = _super.call(this, MiniChartsContainer.TEMPLATE) || this;
        _this.wrappers = {};
        _this.chartController = chartController;
        _this.fills = fills;
        _this.strokes = strokes;
        _this.chartGroups = __assign$o({}, chartGroups);
        return _this;
    }
    MiniChartsContainer.prototype.init = function () {
        var _this = this;
        // hide MiniCustomCombo if no custom combo exists
        if (!this.chartController.customComboExists() && this.chartGroups.combinationGroup) {
            this.chartGroups.combinationGroup = this.chartGroups.combinationGroup.filter(function (chartType) { return chartType !== 'customCombo'; });
        }
        var eGui = this.getGui();
        Object.keys(this.chartGroups).forEach(function (group) {
            var chartGroupValues = _this.chartGroups[group];
            var groupComponent = _this.createBean(new AgGroupComponent({
                title: _this.chartTranslationService.translate(group),
                suppressEnabledCheckbox: true,
                enabled: true,
                suppressOpenCloseIcons: true,
                cssIdentifier: 'charts-settings',
                direction: 'horizontal'
            }));
            chartGroupValues.forEach(function (chartType) {
                var _a;
                var MiniClass = (_a = miniChartMapping[group]) === null || _a === void 0 ? void 0 : _a[chartType];
                if (!MiniClass) {
                    _.warnOnce("invalid chartGroupsDef config '".concat(group).concat(miniChartMapping[group] ? ".".concat(chartType) : '', "'"));
                    return;
                }
                var miniWrapper = document.createElement('div');
                miniWrapper.classList.add('ag-chart-mini-thumbnail');
                var miniClassChartType = MiniClass.chartType;
                _this.addManagedListener(miniWrapper, 'click', function () {
                    _this.chartController.setChartType(miniClassChartType);
                    _this.updateSelectedMiniChart();
                });
                _this.wrappers[miniClassChartType] = miniWrapper;
                _this.createBean(new MiniClass(miniWrapper, _this.fills, _this.strokes));
                groupComponent.addItem(miniWrapper);
            });
            eGui.appendChild(groupComponent.getGui());
        });
        this.updateSelectedMiniChart();
    };
    MiniChartsContainer.prototype.updateSelectedMiniChart = function () {
        var selectedChartType = this.chartController.getChartType();
        for (var miniChartType in this.wrappers) {
            var miniChart = this.wrappers[miniChartType];
            var selected = miniChartType === selectedChartType;
            miniChart.classList.toggle('ag-selected', selected);
        }
    };
    MiniChartsContainer.TEMPLATE = "<div class=\"ag-chart-settings-mini-wrapper\"></div>";
    __decorate$V([
        Autowired('chartTranslationService')
    ], MiniChartsContainer.prototype, "chartTranslationService", void 0);
    __decorate$V([
        PostConstruct
    ], MiniChartsContainer.prototype, "init", null);
    return MiniChartsContainer;
}(Component));

var __extends$16 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$U = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ChartSettingsPanel = /** @class */ (function (_super) {
    __extends$16(ChartSettingsPanel, _super);
    function ChartSettingsPanel(chartController) {
        var _this = _super.call(this, ChartSettingsPanel.TEMPLATE) || this;
        _this.miniChartsContainers = [];
        _this.cardItems = [];
        _this.activePaletteIndex = 0;
        _this.palettes = [];
        _this.themes = [];
        _this.chartController = chartController;
        return _this;
    }
    ChartSettingsPanel.prototype.postConstruct = function () {
        var _this = this;
        this.resetPalettes();
        this.ePrevBtn.insertAdjacentElement('afterbegin', _.createIconNoSpan('previous', this.gridOptionsService));
        this.eNextBtn.insertAdjacentElement('afterbegin', _.createIconNoSpan('next', this.gridOptionsService));
        this.addManagedListener(this.ePrevBtn, 'click', function () { return _this.setActivePalette(_this.getPrev(), 'left'); });
        this.addManagedListener(this.eNextBtn, 'click', function () { return _this.setActivePalette(_this.getNext(), 'right'); });
        // change the selected chart when a combo chart is modified via the data panel, i.e. the custom combo should be selected
        this.addManagedListener(this.chartController, ChartController.EVENT_CHART_TYPE_CHANGED, function () { return _this.resetPalettes(true); });
        this.addManagedListener(this.chartController, ChartController.EVENT_CHART_API_UPDATE, function () { return _this.resetPalettes(true); });
        this.scrollSelectedIntoView();
    };
    ChartSettingsPanel.prototype.scrollSelectedIntoView = function () {
        var _this = this;
        // the panel is not immediately visible due to the slide animation, so we add a
        // setTimeout to wait until the panel animation is over and is able to scroll
        setTimeout(function () {
            var isMiniChartsContainerVisible = function (miniChartsContainers) {
                return !miniChartsContainers.getGui().classList.contains('ag-hidden');
            };
            var currentMiniChartContainer = _this.miniChartsContainers.find(isMiniChartsContainerVisible);
            var currentChart = currentMiniChartContainer.getGui().querySelector('.ag-selected');
            if (currentChart) {
                var parent_1 = currentChart.offsetParent;
                if (parent_1) {
                    _this.eMiniChartsContainer.scrollTo(0, parent_1.offsetTop);
                }
            }
        }, 250);
    };
    ChartSettingsPanel.prototype.resetPalettes = function (forceReset) {
        var _this = this;
        var _a, _b;
        var palettes = this.chartController.getPalettes();
        var chartGroups = (_b = (_a = this.gridOptionsService.get('chartToolPanelsDef')) === null || _a === void 0 ? void 0 : _a.settingsPanel) === null || _b === void 0 ? void 0 : _b.chartGroupsDef;
        if ((_.shallowCompare(palettes, this.palettes) && !forceReset) || this.isAnimating) {
            return;
        }
        this.palettes = palettes;
        this.themes = this.chartController.getThemes();
        this.activePaletteIndex = this.themes.findIndex(function (name) { return name === _this.chartController.getChartThemeName(); });
        this.cardItems = [];
        _.clearElement(this.eCardSelector);
        this.destroyMiniCharts();
        this.palettes.forEach(function (palette, index) {
            var isActivePalette = _this.activePaletteIndex === index;
            var fills = palette.fills, strokes = palette.strokes;
            var miniChartsContainer = _this.createBean(new MiniChartsContainer(_this.chartController, fills, strokes, chartGroups));
            _this.miniChartsContainers.push(miniChartsContainer);
            _this.eMiniChartsContainer.appendChild(miniChartsContainer.getGui());
            _this.addCardLink(index);
            if (isActivePalette) {
                miniChartsContainer.updateSelectedMiniChart();
            }
            else {
                miniChartsContainer.setDisplayed(false);
            }
        });
        _.setDisplayed(this.eNavBar, this.palettes.length > 1);
        _.radioCssClass(this.cardItems[this.activePaletteIndex], 'ag-selected', 'ag-not-selected');
    };
    ChartSettingsPanel.prototype.addCardLink = function (index) {
        var _this = this;
        var link = document.createElement('div');
        link.classList.add('ag-chart-settings-card-item');
        this.addManagedListener(link, 'click', function () {
            _this.setActivePalette(index, index < _this.activePaletteIndex ? 'left' : 'right');
        });
        this.eCardSelector.appendChild(link);
        this.cardItems.push(link);
    };
    ChartSettingsPanel.prototype.getPrev = function () {
        var prev = this.activePaletteIndex - 1;
        if (prev < 0) {
            prev = this.palettes.length - 1;
        }
        return prev;
    };
    ChartSettingsPanel.prototype.getNext = function () {
        var next = this.activePaletteIndex + 1;
        if (next >= this.palettes.length) {
            next = 0;
        }
        return next;
    };
    ChartSettingsPanel.prototype.setActivePalette = function (index, animationDirection) {
        var _this = this;
        if (this.isAnimating || this.activePaletteIndex === index) {
            return;
        }
        _.radioCssClass(this.cardItems[index], 'ag-selected', 'ag-not-selected');
        var currentPalette = this.miniChartsContainers[this.activePaletteIndex];
        var currentGui = currentPalette.getGui();
        var futurePalette = this.miniChartsContainers[index];
        var nextGui = futurePalette.getGui();
        currentPalette.updateSelectedMiniChart();
        futurePalette.updateSelectedMiniChart();
        var multiplier = animationDirection === 'left' ? -1 : 1;
        var final = nextGui.style.left = "".concat((_.getAbsoluteWidth(this.getGui()) * multiplier), "px");
        this.activePaletteIndex = index;
        this.isAnimating = true;
        var animatingClass = 'ag-animating';
        futurePalette.setDisplayed(true);
        currentPalette.addCssClass(animatingClass);
        futurePalette.addCssClass(animatingClass);
        this.chartController.setChartThemeName(this.themes[index]);
        window.setTimeout(function () {
            currentGui.style.left = "".concat(-parseFloat(final), "px");
            nextGui.style.left = '0px';
        }, 0);
        window.setTimeout(function () {
            _this.isAnimating = false;
            currentPalette.removeCssClass(animatingClass);
            futurePalette.removeCssClass(animatingClass);
            currentPalette.setDisplayed(false);
        }, 300);
    };
    ChartSettingsPanel.prototype.destroyMiniCharts = function () {
        _.clearElement(this.eMiniChartsContainer);
        this.miniChartsContainers = this.destroyBeans(this.miniChartsContainers);
    };
    ChartSettingsPanel.prototype.destroy = function () {
        this.destroyMiniCharts();
        _super.prototype.destroy.call(this);
    };
    ChartSettingsPanel.TEMPLATE = "<div class=\"ag-chart-settings-wrapper\">\n            <div ref=\"eMiniChartsContainer\" class=\"ag-chart-settings-mini-charts-container ag-scrollable-container\"></div>\n            <div ref=\"eNavBar\" class=\"ag-chart-settings-nav-bar\">\n                <div ref=\"ePrevBtn\" class=\"ag-chart-settings-prev\">\n                    <button type=\"button\" class=\"ag-button ag-chart-settings-prev-button\"></button>\n                </div>\n                <div ref=\"eCardSelector\" class=\"ag-chart-settings-card-selector\"></div>\n                <div ref=\"eNextBtn\" class=\"ag-chart-settings-next\">\n                    <button type=\"button\" class=\"ag-button ag-chart-settings-next-button\"></button>\n                </div>\n            </div>\n        </div>";
    __decorate$U([
        Autowired('resizeObserverService')
    ], ChartSettingsPanel.prototype, "resizeObserverService", void 0);
    __decorate$U([
        RefSelector('eMiniChartsContainer')
    ], ChartSettingsPanel.prototype, "eMiniChartsContainer", void 0);
    __decorate$U([
        RefSelector('eNavBar')
    ], ChartSettingsPanel.prototype, "eNavBar", void 0);
    __decorate$U([
        RefSelector('eCardSelector')
    ], ChartSettingsPanel.prototype, "eCardSelector", void 0);
    __decorate$U([
        RefSelector('ePrevBtn')
    ], ChartSettingsPanel.prototype, "ePrevBtn", void 0);
    __decorate$U([
        RefSelector('eNextBtn')
    ], ChartSettingsPanel.prototype, "eNextBtn", void 0);
    __decorate$U([
        PostConstruct
    ], ChartSettingsPanel.prototype, "postConstruct", null);
    return ChartSettingsPanel;
}(Component));

var __extends$15 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$T = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var TabbedChartMenu = /** @class */ (function (_super) {
    __extends$15(TabbedChartMenu, _super);
    function TabbedChartMenu(params) {
        var _this = _super.call(this) || this;
        _this.tabs = [];
        var controller = params.controller, panels = params.panels, chartOptionsService = params.chartOptionsService;
        _this.chartController = controller;
        _this.chartOptionsService = chartOptionsService;
        _this.panels = panels;
        return _this;
    }
    TabbedChartMenu.prototype.init = function () {
        var _this = this;
        this.panels.forEach(function (panel) {
            var panelType = panel.replace('chart', '').toLowerCase();
            var _a = _this.createTab(panel, panelType, _this.getPanelClass(panelType)), comp = _a.comp, tab = _a.tab;
            _this.tabs.push(tab);
            _this.addDestroyFunc(function () { return _this.destroyBean(comp); });
        });
        this.tabbedLayout = new TabbedLayout({
            items: this.tabs,
            cssClass: 'ag-chart-tabbed-menu',
            keepScrollPosition: true
        });
        this.getContext().createBean(this.tabbedLayout);
    };
    TabbedChartMenu.prototype.createTab = function (name, title, TabPanelClass) {
        var eWrapperDiv = document.createElement('div');
        eWrapperDiv.classList.add('ag-chart-tab', "ag-chart-".concat(title));
        var comp = new TabPanelClass(this.chartController, this.chartOptionsService);
        this.getContext().createBean(comp);
        eWrapperDiv.appendChild(comp.getGui());
        var titleEl = document.createElement('div');
        var translatedTitle = this.chartTranslationService.translate(title);
        titleEl.innerText = translatedTitle;
        return {
            comp: comp,
            tab: {
                title: titleEl,
                titleLabel: translatedTitle,
                bodyPromise: AgPromise.resolve(eWrapperDiv),
                getScrollableContainer: function () {
                    var scrollableContainer = eWrapperDiv.querySelector('.ag-scrollable-container');
                    return (scrollableContainer || eWrapperDiv);
                },
                name: name
            }
        };
    };
    TabbedChartMenu.prototype.showTab = function (tab) {
        var tabItem = this.tabs[tab];
        this.tabbedLayout.showItem(tabItem);
    };
    TabbedChartMenu.prototype.getGui = function () {
        return this.tabbedLayout && this.tabbedLayout.getGui();
    };
    TabbedChartMenu.prototype.destroy = function () {
        if (this.parentComponent && this.parentComponent.isAlive()) {
            this.destroyBean(this.parentComponent);
        }
        _super.prototype.destroy.call(this);
    };
    TabbedChartMenu.prototype.getPanelClass = function (panelType) {
        switch (panelType) {
            case TabbedChartMenu.TAB_DATA:
                return ChartDataPanel;
            case TabbedChartMenu.TAB_FORMAT:
                return FormatPanel;
            default:
                return ChartSettingsPanel;
        }
    };
    TabbedChartMenu.TAB_DATA = 'data';
    TabbedChartMenu.TAB_FORMAT = 'format';
    __decorate$T([
        Autowired('chartTranslationService')
    ], TabbedChartMenu.prototype, "chartTranslationService", void 0);
    __decorate$T([
        PostConstruct
    ], TabbedChartMenu.prototype, "init", null);
    return TabbedChartMenu;
}(Component));

var __extends$14 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$S = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$i = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$b = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var ChartMenu = /** @class */ (function (_super) {
    __extends$14(ChartMenu, _super);
    function ChartMenu(eChartContainer, eMenuPanelContainer, chartController, chartOptionsService) {
        var _this = _super.call(this, ChartMenu.TEMPLATE) || this;
        _this.eChartContainer = eChartContainer;
        _this.eMenuPanelContainer = eMenuPanelContainer;
        _this.chartController = chartController;
        _this.chartOptionsService = chartOptionsService;
        _this.buttons = {
            chartSettings: ['menu', function () { return _this.showMenu(_this.defaultPanel); }],
            chartData: ['menu', function () { return _this.showMenu("chartData"); }],
            chartFormat: ['menu', function () { return _this.showMenu("chartFormat"); }],
            chartLink: ['linked', function (e) { return _this.toggleDetached(e); }],
            chartUnlink: ['unlinked', function (e) { return _this.toggleDetached(e); }],
            chartDownload: ['save', function () { return _this.saveChart(); }]
        };
        _this.panels = [];
        _this.buttonListenersDestroyFuncs = [];
        _this.menuVisible = false;
        return _this;
    }
    ChartMenu.prototype.postConstruct = function () {
        var _this = this;
        this.createButtons();
        this.addManagedListener(this.eventService, Events.EVENT_CHART_CREATED, function (e) {
            var _a;
            if (e.chartId === _this.chartController.getChartId()) {
                var showDefaultToolPanel = Boolean((_a = _this.gridOptionsService.get('chartToolPanelsDef')) === null || _a === void 0 ? void 0 : _a.defaultToolPanel);
                if (showDefaultToolPanel) {
                    _this.showMenu(_this.defaultPanel, false);
                }
            }
        });
        this.refreshMenuClasses();
        if (!this.gridOptionsService.get('suppressChartToolPanelsButton') && this.panels.length > 0) {
            this.getGui().classList.add('ag-chart-tool-panel-button-enable');
            this.addManagedListener(this.eHideButton, 'click', this.toggleMenu.bind(this));
        }
        this.addManagedListener(this.chartController, ChartController.EVENT_CHART_API_UPDATE, this.createButtons.bind(this));
    };
    ChartMenu.prototype.isVisible = function () {
        return this.menuVisible;
    };
    ChartMenu.prototype.getExtraPaddingDirections = function () {
        var _this = this;
        var topItems = ['chartLink', 'chartUnlink', 'chartDownload'];
        var rightItems = ['chartSettings', 'chartData', 'chartFormat'];
        var result = [];
        if (topItems.some(function (v) { return _this.chartToolbarOptions.includes(v); })) {
            result.push('top');
        }
        if (rightItems.some(function (v) { return _this.chartToolbarOptions.includes(v); })) {
            result.push(this.gridOptionsService.get('enableRtl') ? 'left' : 'right');
        }
        return result;
    };
    ChartMenu.prototype.getToolbarOptions = function () {
        var _this = this;
        var _a, _b, _c;
        var useChartToolPanelCustomisation = Boolean(this.gridOptionsService.get('chartToolPanelsDef'));
        if (useChartToolPanelCustomisation) {
            var defaultChartToolbarOptions = [
                this.chartController.isChartLinked() ? 'chartLink' : 'chartUnlink',
                'chartDownload'
            ];
            var toolbarItemsFunc = this.gridOptionsService.getCallback('getChartToolbarItems');
            var params = {
                defaultItems: defaultChartToolbarOptions
            };
            var chartToolbarOptions = toolbarItemsFunc
                ? toolbarItemsFunc(params).filter(function (option) {
                    if (!CHART_TOOLBAR_ALLOW_LIST.includes(option)) {
                        var msg = CHART_TOOL_PANEL_ALLOW_LIST.includes(option)
                            ? "AG Grid: '".concat(option, "' is a Chart Tool Panel option and will be ignored since 'chartToolPanelsDef' is used. Please use 'chartToolPanelsDef.panels' grid option instead")
                            : "AG Grid: '".concat(option, "' is not a valid Chart Toolbar Option");
                        console.warn(msg);
                        return false;
                    }
                    return true;
                })
                : defaultChartToolbarOptions;
            var panelsOverride = (_b = (_a = this.gridOptionsService.get('chartToolPanelsDef')) === null || _a === void 0 ? void 0 : _a.panels) === null || _b === void 0 ? void 0 : _b.map(function (panel) {
                var menuOption = CHART_TOOL_PANEL_MENU_OPTIONS[panel];
                if (!menuOption) {
                    console.warn("AG Grid - invalid panel in chartToolPanelsDef.panels: '".concat(panel, "'"));
                }
                return menuOption;
            }).filter(function (panel) { return Boolean(panel); });
            this.panels = panelsOverride
                ? panelsOverride
                : Object.values(CHART_TOOL_PANEL_MENU_OPTIONS);
            // pivot charts use the column tool panel instead of the data panel
            if (this.chartController.isPivotChart()) {
                this.panels = this.panels.filter(function (panel) { return panel !== 'chartData'; });
            }
            var defaultToolPanel = (_c = this.gridOptionsService.get('chartToolPanelsDef')) === null || _c === void 0 ? void 0 : _c.defaultToolPanel;
            this.defaultPanel = (defaultToolPanel && CHART_TOOL_PANEL_MENU_OPTIONS[defaultToolPanel]) || this.panels[0];
            return this.panels.length > 0
                // Only one panel is required to display menu icon in toolbar
                ? __spreadArray$b([this.panels[0]], __read$i(chartToolbarOptions), false) : chartToolbarOptions;
        }
        else { // To be deprecated in future. Toolbar options will be different to chart tool panels.
            var tabOptions = [
                'chartSettings',
                'chartData',
                'chartFormat',
                this.chartController.isChartLinked() ? 'chartLink' : 'chartUnlink',
                'chartDownload'
            ];
            var toolbarItemsFunc = this.gridOptionsService.getCallback('getChartToolbarItems');
            if (toolbarItemsFunc) {
                var isLegacyToolbar_1 = this.gridOptionsService.get('suppressChartToolPanelsButton');
                var params = {
                    defaultItems: isLegacyToolbar_1 ? tabOptions : CHART_TOOLBAR_ALLOW_LIST
                };
                tabOptions = toolbarItemsFunc(params).filter(function (option) {
                    if (!_this.buttons[option]) {
                        console.warn("AG Grid: '".concat(option, "' is not a valid Chart Toolbar Option"));
                        return false;
                    }
                    // If not legacy, remove chart tool panel options here,
                    // and add them all in one go below
                    else if (!isLegacyToolbar_1 && CHART_TOOL_PANEL_ALLOW_LIST.includes(option)) {
                        var msg = "AG Grid: '".concat(option, "' is a Chart Tool Panel option and will be ignored. Please use 'chartToolPanelsDef.panels' grid option instead");
                        console.warn(msg);
                        return false;
                    }
                    return true;
                });
                if (!isLegacyToolbar_1) {
                    // Add all the chart tool panels, as `chartToolPanelsDef.panels`
                    // should be used for configuration
                    tabOptions = tabOptions.concat(CHART_TOOL_PANEL_ALLOW_LIST);
                }
            }
            // pivot charts use the column tool panel instead of the data panel
            if (this.chartController.isPivotChart()) {
                tabOptions = tabOptions.filter(function (option) { return option !== 'chartData'; });
            }
            var ignoreOptions_1 = ['chartUnlink', 'chartLink', 'chartDownload'];
            this.panels = tabOptions.filter(function (option) { return ignoreOptions_1.indexOf(option) === -1; });
            this.defaultPanel = this.panels[0];
            return tabOptions.filter(function (value) {
                return ignoreOptions_1.indexOf(value) !== -1 ||
                    (_this.panels.length && value === _this.panels[0]);
            });
        }
    };
    ChartMenu.prototype.toggleDetached = function (e) {
        var target = e.target;
        var active = target.classList.contains('ag-icon-linked');
        target.classList.toggle('ag-icon-linked', !active);
        target.classList.toggle('ag-icon-unlinked', active);
        var tooltipKey = active ? 'chartUnlinkToolbarTooltip' : 'chartLinkToolbarTooltip';
        var tooltipTitle = this.chartTranslationService.translate(tooltipKey);
        if (tooltipTitle) {
            target.title = tooltipTitle;
        }
        this.chartController.detachChartRange();
    };
    ChartMenu.prototype.createButtons = function () {
        var _this = this;
        this.buttonListenersDestroyFuncs.forEach(function (func) { return func(); });
        this.buttonListenersDestroyFuncs = [];
        this.chartToolbarOptions = this.getToolbarOptions();
        var menuEl = this.eMenu;
        _.clearElement(menuEl);
        this.chartToolbarOptions.forEach(function (button) {
            var buttonConfig = _this.buttons[button];
            var _a = __read$i(buttonConfig, 2), iconName = _a[0], callback = _a[1];
            var buttonEl = _.createIconNoSpan(iconName, _this.gridOptionsService, undefined, true);
            buttonEl.classList.add('ag-chart-menu-icon');
            var tooltipTitle = _this.chartTranslationService.translate(button + 'ToolbarTooltip');
            if (tooltipTitle && buttonEl instanceof HTMLElement) {
                buttonEl.title = tooltipTitle;
            }
            _this.buttonListenersDestroyFuncs.push(_this.addManagedListener(buttonEl, 'click', callback));
            menuEl.appendChild(buttonEl);
        });
    };
    ChartMenu.prototype.saveChart = function () {
        var event = { type: ChartMenu.EVENT_DOWNLOAD_CHART };
        this.dispatchEvent(event);
    };
    ChartMenu.prototype.createMenuPanel = function (defaultTab) {
        var _this = this;
        var width = this.environment.chartMenuPanelWidth();
        var menuPanel = this.menuPanel = this.createBean(new AgPanel({
            minWidth: width,
            width: width,
            height: '100%',
            closable: true,
            hideTitleBar: true,
            cssIdentifier: 'chart-menu'
        }));
        menuPanel.setParentComponent(this);
        this.eMenuPanelContainer.appendChild(menuPanel.getGui());
        this.tabbedMenu = this.createBean(new TabbedChartMenu({
            controller: this.chartController,
            type: this.chartController.getChartType(),
            panels: this.panels,
            chartOptionsService: this.chartOptionsService
        }));
        this.addManagedListener(menuPanel, Component.EVENT_DESTROYED, function () { return _this.destroyBean(_this.tabbedMenu); });
        return new AgPromise(function (res) {
            window.setTimeout(function () {
                menuPanel.setBodyComponent(_this.tabbedMenu);
                _this.tabbedMenu.showTab(defaultTab);
                res(menuPanel);
                _this.addManagedListener(_this.eChartContainer, 'click', function (event) {
                    if (_this.getGui().contains(event.target)) {
                        return;
                    }
                    if (_this.menuVisible) {
                        _this.hideMenu();
                    }
                });
            }, 100);
        });
    };
    ChartMenu.prototype.showContainer = function () {
        if (!this.menuPanel) {
            return;
        }
        this.menuVisible = true;
        this.showParent(this.menuPanel.getWidth());
        this.refreshMenuClasses();
    };
    ChartMenu.prototype.toggleMenu = function () {
        this.menuVisible ? this.hideMenu() : this.showMenu();
    };
    ChartMenu.prototype.showMenu = function (
    /**
     * Menu panel to show. If empty, shows the existing menu, or creates the default menu if menu panel has not been created
     */
    panel, 
    /**
     * Whether to animate the menu opening
     */
    animate) {
        var _this = this;
        if (animate === void 0) { animate = true; }
        if (!animate) {
            this.eMenuPanelContainer.classList.add('ag-no-transition');
        }
        if (this.menuPanel && !panel) {
            this.showContainer();
        }
        else {
            var menuPanel = panel || this.defaultPanel;
            var tab = this.panels.indexOf(menuPanel);
            if (tab < 0) {
                console.warn("AG Grid: '".concat(panel, "' is not a valid Chart Tool Panel name"));
                tab = this.panels.indexOf(this.defaultPanel);
            }
            if (this.menuPanel) {
                this.tabbedMenu.showTab(tab);
                this.showContainer();
            }
            else {
                this.createMenuPanel(tab).then(this.showContainer.bind(this));
            }
        }
        if (!animate) {
            // Wait for menu to render
            setTimeout(function () {
                if (!_this.isAlive()) {
                    return;
                }
                _this.eMenuPanelContainer.classList.remove('ag-no-transition');
            }, 500);
        }
    };
    ChartMenu.prototype.hideMenu = function () {
        var _this = this;
        this.hideParent();
        window.setTimeout(function () {
            _this.menuVisible = false;
            _this.refreshMenuClasses();
        }, 500);
    };
    ChartMenu.prototype.refreshMenuClasses = function () {
        this.eChartContainer.classList.toggle('ag-chart-menu-visible', this.menuVisible);
        this.eChartContainer.classList.toggle('ag-chart-menu-hidden', !this.menuVisible);
        if (!this.gridOptionsService.get('suppressChartToolPanelsButton')) {
            this.eHideButtonIcon.classList.toggle('ag-icon-contracted', this.menuVisible);
            this.eHideButtonIcon.classList.toggle('ag-icon-expanded', !this.menuVisible);
        }
    };
    ChartMenu.prototype.showParent = function (width) {
        this.eMenuPanelContainer.style.minWidth = "".concat(width, "px");
    };
    ChartMenu.prototype.hideParent = function () {
        this.eMenuPanelContainer.style.minWidth = '0';
    };
    ChartMenu.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
        if (this.menuPanel && this.menuPanel.isAlive()) {
            this.destroyBean(this.menuPanel);
        }
        if (this.tabbedMenu && this.tabbedMenu.isAlive()) {
            this.destroyBean(this.tabbedMenu);
        }
    };
    ChartMenu.EVENT_DOWNLOAD_CHART = "downloadChart";
    ChartMenu.TEMPLATE = "<div>\n        <div class=\"ag-chart-menu\" ref=\"eMenu\"></div>\n        <button class=\"ag-button ag-chart-menu-close\" ref=\"eHideButton\">\n            <span class=\"ag-icon ag-icon-contracted\" ref=\"eHideButtonIcon\"></span>\n        </button>\n    </div>";
    __decorate$S([
        Autowired('chartTranslationService')
    ], ChartMenu.prototype, "chartTranslationService", void 0);
    __decorate$S([
        RefSelector("eMenu")
    ], ChartMenu.prototype, "eMenu", void 0);
    __decorate$S([
        RefSelector("eHideButton")
    ], ChartMenu.prototype, "eHideButton", void 0);
    __decorate$S([
        RefSelector("eHideButtonIcon")
    ], ChartMenu.prototype, "eHideButtonIcon", void 0);
    __decorate$S([
        PostConstruct
    ], ChartMenu.prototype, "postConstruct", null);
    return ChartMenu;
}(Component));

var __extends$13 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$n = (undefined && undefined.__assign) || function () {
    __assign$n = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$n.apply(this, arguments);
};
var __decorate$R = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __values$6 = (undefined && undefined.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var TitleEdit = /** @class */ (function (_super) {
    __extends$13(TitleEdit, _super);
    function TitleEdit(chartMenu) {
        var _this = _super.call(this, TitleEdit.TEMPLATE) || this;
        _this.chartMenu = chartMenu;
        _this.destroyableChartListeners = [];
        _this.editing = false;
        return _this;
    }
    TitleEdit.prototype.init = function () {
        var _this = this;
        this.addManagedListener(this.getGui(), 'keydown', function (e) {
            if (_this.editing && e.key === 'Enter' && !e.shiftKey) {
                _this.handleEndEditing();
                e.preventDefault();
            }
        });
        this.addManagedListener(this.getGui(), 'input', function () {
            if (_this.editing) {
                _this.updateHeight();
            }
        });
        this.addManagedListener(this.getGui(), 'blur', function () { return _this.endEditing(); });
    };
    /* should be called when the containing component changes to a new chart proxy */
    TitleEdit.prototype.refreshTitle = function (chartController, chartOptionsService) {
        var e_1, _a;
        var _this = this;
        this.chartController = chartController;
        this.chartOptionsService = chartOptionsService;
        try {
            for (var _b = __values$6(this.destroyableChartListeners), _c = _b.next(); !_c.done; _c = _b.next()) {
                var destroyFn = _c.value;
                destroyFn();
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.destroyableChartListeners = [];
        var chartProxy = this.chartController.getChartProxy();
        var chart = chartProxy.getChart();
        var canvas = chart.scene.canvas.element;
        var destroyDbleClickListener = this.addManagedListener(canvas, 'dblclick', function (event) {
            var title = chart.title;
            if (title && title.node.containsPoint(event.offsetX, event.offsetY)) {
                var bbox = title.node.computeBBox();
                var xy = title.node.inverseTransformPoint(bbox.x, bbox.y);
                _this.startEditing(__assign$n(__assign$n({}, bbox), xy), canvas.width);
            }
        });
        var wasInTitle = false;
        var destroyMouseMoveListener = this.addManagedListener(canvas, 'mousemove', function (event) {
            var title = chart.title;
            var inTitle = !!(title && title.enabled && title.node.containsPoint(event.offsetX, event.offsetY));
            if (wasInTitle !== inTitle) {
                canvas.style.cursor = inTitle ? 'pointer' : '';
            }
            wasInTitle = inTitle;
        });
        this.destroyableChartListeners = [
            destroyDbleClickListener,
            destroyMouseMoveListener
        ];
    };
    TitleEdit.prototype.startEditing = function (titleBBox, canvasWidth) {
        if (this.chartMenu && this.chartMenu.isVisible()) {
            // currently, we ignore requests to edit the chart title while the chart menu is showing
            // because the click to edit the chart will also close the chart menu, making the position
            // of the title change.
            return;
        }
        if (this.editing) {
            return;
        }
        this.editing = true;
        var minimumTargetInputWidth = 300;
        var inputWidth = Math.max(Math.min(titleBBox.width + 20, canvasWidth), minimumTargetInputWidth);
        var element = this.getGui();
        element.classList.add('currently-editing');
        var inputStyle = element.style;
        // match style of input to title that we're editing
        inputStyle.fontFamily = this.chartOptionsService.getChartOption('title.fontFamily');
        inputStyle.fontWeight = this.chartOptionsService.getChartOption('title.fontWeight');
        inputStyle.fontStyle = this.chartOptionsService.getChartOption('title.fontStyle');
        inputStyle.fontSize = this.chartOptionsService.getChartOption('title.fontSize') + 'px';
        inputStyle.color = this.chartOptionsService.getChartOption('title.color');
        // populate the input with the title, unless the title is the placeholder:
        var oldTitle = this.chartOptionsService.getChartOption('title.text');
        var isTitlePlaceholder = oldTitle === this.chartTranslationService.translate('titlePlaceholder');
        element.value = isTitlePlaceholder ? '' : oldTitle;
        var oldTitleLines = oldTitle.split(/\r?\n/g).length;
        inputStyle.left = Math.round(titleBBox.x + titleBBox.width / 2 - inputWidth / 2 - 1) + 'px';
        inputStyle.top = Math.round(titleBBox.y + titleBBox.height / 2 - (oldTitleLines * this.getLineHeight()) / 2 - 2) + 'px';
        inputStyle.width = Math.round(inputWidth) + 'px';
        inputStyle.lineHeight = this.getLineHeight() + 'px';
        this.updateHeight();
        element.focus();
    };
    TitleEdit.prototype.updateHeight = function () {
        var element = this.getGui();
        // The element should cover the title and provide enough space for the new one.
        var oldTitleLines = this.chartOptionsService.getChartOption('title.text').split(/\r?\n/g).length;
        var currentTitleLines = element.value.split(/\r?\n/g).length;
        element.style.height = (Math.round(Math.max(oldTitleLines, currentTitleLines) * this.getLineHeight()) + 4) + 'px';
    };
    TitleEdit.prototype.getLineHeight = function () {
        var fixedLineHeight = this.chartOptionsService.getChartOption('title.lineHeight');
        if (fixedLineHeight) {
            return parseInt(fixedLineHeight);
        }
        return Math.round(parseInt(this.chartOptionsService.getChartOption('title.fontSize')) * 1.2);
    };
    TitleEdit.prototype.handleEndEditing = function () {
        // special handling to avoid flicker caused by delay when swapping old and new titles
        var _this = this;
        // 1 - store current title color
        var titleColor = this.chartOptionsService.getChartOption('title.color');
        // 2 - hide title by making it transparent
        var transparentColor = 'rgba(0, 0, 0, 0)';
        this.chartOptionsService.setChartOption('title.color', transparentColor);
        // 3 - trigger 'end editing' - this will update the chart with the new title
        this.chartOptionsService.awaitChartOptionUpdate(function () { return _this.endEditing(); });
        // 4 - restore title color to its original value
        this.chartOptionsService.awaitChartOptionUpdate(function () {
            _this.chartOptionsService.setChartOption('title.color', titleColor);
        });
    };
    TitleEdit.prototype.endEditing = function () {
        var _this = this;
        if (!this.editing) {
            return;
        }
        this.editing = false;
        var value = this.getGui().value;
        if (value && value.trim() !== '') {
            this.chartOptionsService.setChartOption('title.text', value);
            this.chartOptionsService.setChartOption('title.enabled', true);
        }
        else {
            this.chartOptionsService.setChartOption('title.text', '');
            this.chartOptionsService.setChartOption('title.enabled', false);
        }
        this.getGui().classList.remove('currently-editing');
        // await chart updates so `chartTitleEdit` event consumers can read the new state correctly
        this.chartOptionsService.awaitChartOptionUpdate(function () {
            _this.eventService.dispatchEvent({ type: 'chartTitleEdit' });
        });
    };
    TitleEdit.TEMPLATE = "<textarea\n             class=\"ag-chart-title-edit\"\n             style=\"padding:0; border:none; border-radius: 0; min-height: 0; text-align: center; resize: none;\" />\n        ";
    __decorate$R([
        Autowired('chartTranslationService')
    ], TitleEdit.prototype, "chartTranslationService", void 0);
    __decorate$R([
        PostConstruct
    ], TitleEdit.prototype, "init", null);
    return TitleEdit;
}(Component));

var __extends$12 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$Q = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ChartDatasource = /** @class */ (function (_super) {
    __extends$12(ChartDatasource, _super);
    function ChartDatasource() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChartDatasource.prototype.getData = function (params) {
        if (params.crossFiltering) {
            if (params.grouping) {
                console.warn("AG Grid: crossing filtering with row grouping is not supported.");
                return { chartData: [], columnNames: {} };
            }
            if (!this.gridOptionsService.isRowModelType('clientSide')) {
                console.warn("AG Grid: crossing filtering is only supported in the client side row model.");
                return { chartData: [], columnNames: {} };
            }
        }
        var isServerSide = this.gridOptionsService.isRowModelType('serverSide');
        if (isServerSide && params.pivoting) {
            this.updatePivotKeysForSSRM();
        }
        var result = this.extractRowsFromGridRowModel(params);
        result.chartData = this.aggregateRowsByDimension(params, result.chartData);
        return result;
    };
    ChartDatasource.prototype.extractRowsFromGridRowModel = function (params) {
        var _this = this;
        var extractedRowData = [];
        var columnNames = {};
        // maps used to keep track of expanded groups that need to be removed
        var groupNodeIndexes = {};
        var groupsToRemove = {};
        // only used when cross filtering
        var filteredNodes = {};
        var allRowNodes = [];
        var numRows;
        if (params.crossFiltering) {
            filteredNodes = this.getFilteredRowNodes();
            allRowNodes = this.getAllRowNodes();
            numRows = allRowNodes.length;
        }
        else {
            // make sure enough rows in range to chart. if user filters and less rows, then end row will be
            // the last displayed row, not where the range ends.
            var modelLastRow = this.gridRowModel.getRowCount() - 1;
            var rangeLastRow = params.endRow >= 0 ? Math.min(params.endRow, modelLastRow) : modelLastRow;
            numRows = rangeLastRow - params.startRow + 1;
        }
        var _loop_1 = function (i) {
            var data = {};
            var rowNode = params.crossFiltering ? allRowNodes[i] : this_1.gridRowModel.getRow(i + params.startRow);
            // first get data for dimensions columns
            params.dimensionCols.forEach(function (col) {
                var colId = col.colId;
                var column = _this.columnModel.getGridColumn(colId);
                if (column) {
                    var valueObject = _this.valueService.getValue(column, rowNode);
                    // when grouping we also need to build up multi category labels for charts
                    if (params.grouping) {
                        var valueString = valueObject && valueObject.toString ? String(valueObject.toString()) : '';
                        // traverse parents to extract group label path
                        var labels = ChartDatasource.getGroupLabels(rowNode, valueString);
                        data[colId] = {
                            labels: labels,
                            toString: function () {
                                return this.labels.filter(function (l) { return !!l; }).reverse().join(' - ');
                            }
                        };
                        // keep track of group node indexes, so they can be padded when other groups are expanded
                        if (rowNode.group) {
                            groupNodeIndexes[labels.toString()] = i;
                        }
                        // if node (group or leaf) has parents then it is expanded and should be removed
                        var groupKey = labels.slice(1, labels.length).toString();
                        if (groupKey) {
                            groupsToRemove[groupKey] = groupNodeIndexes[groupKey];
                        }
                    }
                    else {
                        // leaf nodes can be directly added to dimension columns
                        data[colId] = valueObject;
                    }
                }
                else {
                    // introduce a default category when no dimensions exist with a value based off row index (+1)
                    data[ChartDataModel.DEFAULT_CATEGORY] = i + 1;
                }
            });
            // then get data for value columns
            params.valueCols.forEach(function (col) {
                var columnNamesArr = [];
                // pivot keys should be added first
                var pivotKeys = col.getColDef().pivotKeys;
                if (pivotKeys) {
                    columnNamesArr = pivotKeys.slice();
                }
                // then add column header name to results
                var headerName = col.getColDef().headerName;
                if (headerName) {
                    columnNamesArr.push(headerName);
                }
                // add array of column names to results
                if (columnNamesArr.length > 0) {
                    columnNames[col.getId()] = columnNamesArr;
                }
                var colId = col.getColId();
                if (params.crossFiltering) {
                    var filteredOutColId = colId + '-filtered-out';
                    // add data value to value column
                    var value = _this.valueService.getValue(col, rowNode);
                    var actualValue = value != null && typeof value.toNumber === 'function' ? value.toNumber() : value;
                    if (filteredNodes[rowNode.id]) {
                        data[colId] = actualValue;
                        data[filteredOutColId] = params.aggFunc || params.isScatter ? undefined : 0;
                    }
                    else {
                        data[colId] = params.aggFunc || params.isScatter ? undefined : 0;
                        data[filteredOutColId] = actualValue;
                    }
                }
                else {
                    // add data value to value column
                    var value = _this.valueService.getValue(col, rowNode);
                    // aggregated value
                    if (value && value.hasOwnProperty('toString')) {
                        value = parseFloat(value.toString());
                    }
                    data[colId] = value != null && typeof value.toNumber === 'function' ? value.toNumber() : value;
                }
            });
            // row data from footer nodes should not be included in charts
            if (rowNode.footer) {
                // 'stamping' data as footer to avoid impacting previously calculated `groupIndexesToRemove` and will
                // be removed from the results along with any expanded group nodes
                data.footer = true;
            }
            // add data to results
            extractedRowData.push(data);
        };
        var this_1 = this;
        for (var i = 0; i < numRows; i++) {
            _loop_1(i);
        }
        if (params.grouping) {
            var groupIndexesToRemove_1 = _.values(groupsToRemove);
            var filterFunc = function (data, index) { return !data.footer && !_.includes(groupIndexesToRemove_1, index); };
            extractedRowData = extractedRowData.filter(filterFunc);
        }
        return { chartData: extractedRowData, columnNames: columnNames };
    };
    ChartDatasource.prototype.aggregateRowsByDimension = function (params, dataFromGrid) {
        var _this = this;
        var dimensionCols = params.dimensionCols;
        if (!params.aggFunc || dimensionCols.length === 0) {
            return dataFromGrid;
        }
        var lastCol = _.last(dimensionCols);
        var lastColId = lastCol && lastCol.colId;
        var map = {};
        var dataAggregated = [];
        dataFromGrid.forEach(function (data) {
            var currentMap = map;
            dimensionCols.forEach(function (col) {
                var colId = col.colId;
                var key = data[colId];
                if (colId === lastColId) {
                    var groupItem_1 = currentMap[key];
                    if (!groupItem_1) {
                        groupItem_1 = { __children: [] };
                        dimensionCols.forEach(function (dimCol) {
                            var dimColId = dimCol.colId;
                            groupItem_1[dimColId] = data[dimColId];
                        });
                        currentMap[key] = groupItem_1;
                        dataAggregated.push(groupItem_1);
                    }
                    groupItem_1.__children.push(data);
                }
                else {
                    // map of maps
                    if (!currentMap[key]) {
                        currentMap[key] = {};
                    }
                    currentMap = currentMap[key];
                }
            });
        });
        if (ModuleRegistry.__assertRegistered(ModuleNames.RowGroupingModule, 'Charting Aggregation', this.context.getGridId())) {
            dataAggregated.forEach(function (groupItem) { return params.valueCols.forEach(function (col) {
                if (params.crossFiltering) {
                    params.valueCols.forEach(function (valueCol) {
                        var colId = valueCol.getColId();
                        // filtered data
                        var dataToAgg = groupItem.__children
                            .filter(function (child) { return typeof child[colId] !== 'undefined'; })
                            .map(function (child) { return child[colId]; });
                        var aggResult = _this.aggregationStage.aggregateValues(dataToAgg, params.aggFunc);
                        groupItem[valueCol.getId()] = aggResult && typeof aggResult.value !== 'undefined' ? aggResult.value : aggResult;
                        // filtered out data
                        var filteredOutColId = "".concat(colId, "-filtered-out");
                        var dataToAggFiltered = groupItem.__children
                            .filter(function (child) { return typeof child[filteredOutColId] !== 'undefined'; })
                            .map(function (child) { return child[filteredOutColId]; });
                        var aggResultFiltered = _this.aggregationStage.aggregateValues(dataToAggFiltered, params.aggFunc);
                        groupItem[filteredOutColId] = aggResultFiltered && typeof aggResultFiltered.value !== 'undefined' ? aggResultFiltered.value : aggResultFiltered;
                    });
                }
                else {
                    var dataToAgg = groupItem.__children.map(function (child) { return child[col.getId()]; });
                    var aggResult = 0;
                    if (ModuleRegistry.__assertRegistered(ModuleNames.RowGroupingModule, 'Charting Aggregation', _this.context.getGridId())) {
                        aggResult = _this.aggregationStage.aggregateValues(dataToAgg, params.aggFunc);
                    }
                    groupItem[col.getId()] = aggResult && typeof aggResult.value !== 'undefined' ? aggResult.value : aggResult;
                }
            }); });
        }
        return dataAggregated;
    };
    ChartDatasource.prototype.updatePivotKeysForSSRM = function () {
        var secondaryColumns = this.columnModel.getSecondaryColumns();
        if (!secondaryColumns) {
            return;
        }
        // we don't know what the application will use for the pivot key separator (i.e. '_' or '|' ) as the
        // secondary columns are provided to grid by the application via api.setSecondaryColumns()
        var pivotKeySeparator = this.extractPivotKeySeparator(secondaryColumns);
        // `pivotKeys` is not used by the SSRM for pivoting, so it is safe to reuse this colDef property. This way
        // the same logic can be used for CSRM and SSRM to extract legend names in extractRowsFromGridRowModel()
        secondaryColumns.forEach(function (col) {
            if (pivotKeySeparator === '') {
                col.getColDef().pivotKeys = [];
            }
            else {
                var keys = col.getColId().split(pivotKeySeparator);
                col.getColDef().pivotKeys = keys.slice(0, keys.length - 1);
            }
        });
    };
    ChartDatasource.prototype.extractPivotKeySeparator = function (secondaryColumns) {
        if (secondaryColumns.length === 0) {
            return '';
        }
        var extractSeparator = function (columnGroup, childId) {
            var groupId = columnGroup.getGroupId();
            if (!columnGroup.getParent()) {
                // removing groupId ('2000') from childId ('2000|Swimming') yields '|Swimming' so first char is separator
                return childId.split(groupId)[1][0];
            }
            return extractSeparator(columnGroup.getParent(), groupId);
        };
        var firstSecondaryCol = secondaryColumns[0];
        if (firstSecondaryCol.getParent() == null) {
            return '';
        }
        return extractSeparator(firstSecondaryCol.getParent(), firstSecondaryCol.getColId());
    };
    ChartDatasource.getGroupLabels = function (rowNode, initialLabel) {
        var labels = [initialLabel];
        while (rowNode && rowNode.level !== 0) {
            rowNode = rowNode.parent;
            if (rowNode) {
                labels.push(rowNode.key);
            }
        }
        return labels;
    };
    ChartDatasource.prototype.getFilteredRowNodes = function () {
        var filteredNodes = {};
        this.gridRowModel.forEachNodeAfterFilterAndSort(function (rowNode) {
            filteredNodes[rowNode.id] = rowNode;
        });
        return filteredNodes;
    };
    ChartDatasource.prototype.getAllRowNodes = function () {
        var allRowNodes = [];
        this.gridRowModel.forEachNode(function (rowNode) {
            allRowNodes.push(rowNode);
        });
        return this.sortRowNodes(allRowNodes);
    };
    ChartDatasource.prototype.sortRowNodes = function (rowNodes) {
        var sortOptions = this.sortController.getSortOptions();
        var noSort = !sortOptions || sortOptions.length == 0;
        if (noSort)
            return rowNodes;
        return this.rowNodeSorter.doFullSort(rowNodes, sortOptions);
    };
    __decorate$Q([
        Autowired('rowModel')
    ], ChartDatasource.prototype, "gridRowModel", void 0);
    __decorate$Q([
        Autowired('valueService')
    ], ChartDatasource.prototype, "valueService", void 0);
    __decorate$Q([
        Autowired('columnModel')
    ], ChartDatasource.prototype, "columnModel", void 0);
    __decorate$Q([
        Autowired('rowNodeSorter')
    ], ChartDatasource.prototype, "rowNodeSorter", void 0);
    __decorate$Q([
        Autowired('sortController')
    ], ChartDatasource.prototype, "sortController", void 0);
    __decorate$Q([
        Optional('aggregationStage')
    ], ChartDatasource.prototype, "aggregationStage", void 0);
    return ChartDatasource;
}(BeanStub));

var __extends$11 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$P = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ChartColumnService = /** @class */ (function (_super) {
    __extends$11(ChartColumnService, _super);
    function ChartColumnService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChartColumnService.prototype.getColumn = function (colId) {
        return this.columnModel.getPrimaryColumn(colId);
    };
    ChartColumnService.prototype.getAllDisplayedColumns = function () {
        return this.columnModel.getAllDisplayedColumns();
    };
    ChartColumnService.prototype.getColDisplayName = function (col) {
        return this.columnModel.getDisplayNameForColumn(col, 'chart');
    };
    ChartColumnService.prototype.getRowGroupColumns = function () {
        return this.columnModel.getRowGroupColumns();
    };
    ChartColumnService.prototype.getGroupDisplayColumns = function () {
        return this.columnModel.getGroupDisplayColumns();
    };
    ChartColumnService.prototype.isPivotMode = function () {
        return this.columnModel.isPivotMode();
    };
    ChartColumnService.prototype.isPivotActive = function () {
        return this.columnModel.isPivotActive();
    };
    ChartColumnService.prototype.getChartColumns = function () {
        var _this = this;
        var displayedCols = this.columnModel.getAllDisplayedColumns();
        var dimensionCols = new Set();
        var valueCols = new Set();
        displayedCols.forEach(function (col) {
            var colDef = col.getColDef();
            var chartDataType = colDef.chartDataType;
            if (chartDataType) {
                // chart data type was specified explicitly
                switch (chartDataType) {
                    case 'category':
                    case 'time':
                        dimensionCols.add(col);
                        return;
                    case 'series':
                        valueCols.add(col);
                        return;
                    case 'excluded':
                        return;
                    default:
                        console.warn("AG Grid: unexpected chartDataType value '".concat(chartDataType, "' supplied, instead use 'category', 'series' or 'excluded'"));
                        break;
                }
            }
            if (colDef.colId === 'ag-Grid-AutoColumn') {
                dimensionCols.add(col);
                return;
            }
            if (!col.isPrimary()) {
                valueCols.add(col);
                return;
            }
            // if 'chartDataType' is not provided then infer type based data contained in first row
            (_this.isNumberCol(col) ? valueCols : dimensionCols).add(col);
        });
        return { dimensionCols: dimensionCols, valueCols: valueCols };
    };
    ChartColumnService.prototype.isNumberCol = function (col) {
        if (col.getColId() === 'ag-Grid-AutoColumn') {
            return false;
        }
        var row = this.rowRenderer.getRowNode({ rowIndex: 0, rowPinned: null });
        if (!row) {
            return false;
        }
        var cellValue = this.valueService.getValue(col, row);
        if (cellValue == null) {
            cellValue = this.extractLeafData(row, col);
        }
        if (cellValue != null && typeof cellValue.toNumber === 'function') {
            cellValue = cellValue.toNumber();
        }
        return typeof cellValue === 'number';
    };
    ChartColumnService.prototype.extractLeafData = function (row, col) {
        if (!row.allLeafChildren) {
            return null;
        }
        for (var i = 0; i < row.allLeafChildren.length; i++) {
            var childRow = row.allLeafChildren[i];
            var value = this.valueService.getValue(col, childRow);
            if (value != null) {
                return value;
            }
        }
        return null;
    };
    __decorate$P([
        Autowired('columnModel')
    ], ChartColumnService.prototype, "columnModel", void 0);
    __decorate$P([
        Autowired('valueService')
    ], ChartColumnService.prototype, "valueService", void 0);
    __decorate$P([
        Autowired('rowRenderer')
    ], ChartColumnService.prototype, "rowRenderer", void 0);
    ChartColumnService = __decorate$P([
        Bean("chartColumnService")
    ], ChartColumnService);
    return ChartColumnService;
}(BeanStub));

var __extends$10 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$O = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ComboChartModel = /** @class */ (function (_super) {
    __extends$10(ComboChartModel, _super);
    function ComboChartModel(chartDataModel) {
        var _this = this;
        var _a;
        _this = _super.call(this) || this;
        // this control flag is used to only log warning for the initial user config
        _this.suppressComboChartWarnings = false;
        _this.chartDataModel = chartDataModel;
        _this.seriesChartTypes = (_a = chartDataModel.params.seriesChartTypes) !== null && _a !== void 0 ? _a : [];
        return _this;
    }
    ComboChartModel.prototype.init = function () {
        this.initComboCharts();
    };
    ComboChartModel.prototype.update = function (seriesChartTypes) {
        this.seriesChartTypes = seriesChartTypes !== null && seriesChartTypes !== void 0 ? seriesChartTypes : this.seriesChartTypes;
        this.initComboCharts();
        this.updateSeriesChartTypes();
    };
    ComboChartModel.prototype.initComboCharts = function () {
        var seriesChartTypesExist = this.seriesChartTypes && this.seriesChartTypes.length > 0;
        var customCombo = this.chartDataModel.chartType === 'customCombo' || seriesChartTypesExist;
        if (customCombo) {
            // it is not necessary to supply a chart type for combo charts when `seriesChartTypes` is supplied
            this.chartDataModel.chartType = 'customCombo';
            // cache supplied `seriesChartTypes` to allow switching between different chart types in the settings panel
            this.savedCustomSeriesChartTypes = this.seriesChartTypes || [];
        }
    };
    ComboChartModel.prototype.updateSeriesChartTypes = function () {
        if (!this.chartDataModel.isComboChart()) {
            return;
        }
        // ensure primary only chart types are not placed on secondary axis
        this.seriesChartTypes = this.seriesChartTypes.map(function (seriesChartType) {
            var primaryOnly = ['groupedColumn', 'stackedColumn', 'stackedArea'].includes(seriesChartType.chartType);
            seriesChartType.secondaryAxis = primaryOnly ? false : seriesChartType.secondaryAxis;
            return seriesChartType;
        });
        // note that when seriesChartTypes are supplied the chart type is also changed to 'customCombo'
        if (this.chartDataModel.chartType === 'customCombo') {
            this.updateSeriesChartTypesForCustomCombo();
            return;
        }
        this.updateChartSeriesTypesForBuiltInCombos();
    };
    ComboChartModel.prototype.updateSeriesChartTypesForCustomCombo = function () {
        var _this = this;
        var seriesChartTypesSupplied = this.seriesChartTypes && this.seriesChartTypes.length > 0;
        if (!seriesChartTypesSupplied && !this.suppressComboChartWarnings) {
            console.warn("AG Grid: 'seriesChartTypes' are required when the 'customCombo' chart type is specified.");
        }
        // ensure correct chartTypes are supplied
        this.seriesChartTypes = this.seriesChartTypes.map(function (s) {
            if (!ComboChartModel.SUPPORTED_COMBO_CHART_TYPES.includes(s.chartType)) {
                console.warn("AG Grid: invalid chartType '".concat(s.chartType, "' supplied in 'seriesChartTypes', converting to 'line' instead."));
                s.chartType = 'line';
            }
            return s;
        });
        var getSeriesChartType = function (valueCol) {
            if (!_this.savedCustomSeriesChartTypes || _this.savedCustomSeriesChartTypes.length === 0) {
                _this.savedCustomSeriesChartTypes = _this.seriesChartTypes;
            }
            var providedSeriesChartType = _this.savedCustomSeriesChartTypes.find(function (s) { return s.colId === valueCol.colId; });
            if (!providedSeriesChartType) {
                if (valueCol.selected && !_this.suppressComboChartWarnings) {
                    console.warn("AG Grid: no 'seriesChartType' found for colId = '".concat(valueCol.colId, "', defaulting to 'line'."));
                }
                return {
                    colId: valueCol.colId,
                    chartType: 'line',
                    secondaryAxis: false
                };
            }
            return providedSeriesChartType;
        };
        var updatedSeriesChartTypes = this.chartDataModel.valueColState.map(getSeriesChartType);
        this.seriesChartTypes = updatedSeriesChartTypes;
        // also cache custom `seriesChartTypes` to allow for switching between different chart types
        this.savedCustomSeriesChartTypes = updatedSeriesChartTypes;
        // turn off warnings as first combo chart attempt has completed
        this.suppressComboChartWarnings = true;
    };
    ComboChartModel.prototype.updateChartSeriesTypesForBuiltInCombos = function () {
        var _a = this.chartDataModel, chartType = _a.chartType, valueColState = _a.valueColState;
        var primaryChartType = chartType === 'columnLineCombo' ? 'groupedColumn' : 'stackedArea';
        var secondaryChartType = chartType === 'columnLineCombo' ? 'line' : 'groupedColumn';
        var selectedCols = valueColState.filter(function (cs) { return cs.selected; });
        var lineIndex = Math.ceil(selectedCols.length / 2);
        this.seriesChartTypes = selectedCols.map(function (valueCol, i) {
            var seriesType = (i >= lineIndex) ? secondaryChartType : primaryChartType;
            return { colId: valueCol.colId, chartType: seriesType, secondaryAxis: false };
        });
    };
    ComboChartModel.SUPPORTED_COMBO_CHART_TYPES = ['line', 'groupedColumn', 'stackedColumn', 'area', 'stackedArea'];
    __decorate$O([
        PostConstruct
    ], ComboChartModel.prototype, "init", null);
    return ComboChartModel;
}(BeanStub));

var __extends$$ = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$m = (undefined && undefined.__assign) || function () {
    __assign$m = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$m.apply(this, arguments);
};
var __decorate$N = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$h = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$a = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var ChartDataModel = /** @class */ (function (_super) {
    __extends$$(ChartDataModel, _super);
    function ChartDataModel(params) {
        var _this = _super.call(this) || this;
        _this.unlinked = false;
        _this.chartData = [];
        _this.valueColState = [];
        _this.dimensionColState = [];
        _this.columnNames = {};
        _this.crossFiltering = false;
        _this.grouping = false;
        _this.params = params;
        _this.chartId = params.chartId;
        _this.chartType = params.chartType;
        _this.pivotChart = params.pivotChart;
        _this.chartThemeName = params.chartThemeName;
        _this.aggFunc = params.aggFunc;
        _this.referenceCellRange = params.cellRange;
        _this.suppliedCellRange = params.cellRange;
        _this.suppressChartRanges = params.suppressChartRanges;
        _this.unlinked = !!params.unlinkChart;
        _this.crossFiltering = !!params.crossFiltering;
        return _this;
    }
    ChartDataModel.prototype.init = function () {
        this.datasource = this.createManagedBean(new ChartDatasource());
        this.chartColumnService = this.createManagedBean(new ChartColumnService());
        this.comboChartModel = this.createManagedBean(new ComboChartModel(this));
        this.updateCellRanges();
        this.updateData();
    };
    ChartDataModel.prototype.updateModel = function (params) {
        var cellRange = params.cellRange, chartType = params.chartType, pivotChart = params.pivotChart, chartThemeName = params.chartThemeName, aggFunc = params.aggFunc, suppressChartRanges = params.suppressChartRanges, unlinkChart = params.unlinkChart, crossFiltering = params.crossFiltering, seriesChartTypes = params.seriesChartTypes;
        if (cellRange !== this.suppliedCellRange) {
            this.dimensionCellRange = undefined;
            this.valueCellRange = undefined;
        }
        this.chartType = chartType;
        this.pivotChart = pivotChart;
        this.chartThemeName = chartThemeName;
        this.aggFunc = aggFunc;
        this.referenceCellRange = cellRange;
        this.suppliedCellRange = cellRange;
        this.suppressChartRanges = suppressChartRanges;
        this.unlinked = !!unlinkChart;
        this.crossFiltering = !!crossFiltering;
        this.updateSelectedDimension(cellRange === null || cellRange === void 0 ? void 0 : cellRange.columns);
        this.updateCellRanges();
        var shouldUpdateComboModel = this.isComboChart() || seriesChartTypes;
        if (shouldUpdateComboModel) {
            this.comboChartModel.update(seriesChartTypes);
        }
        if (!this.unlinked) {
            this.updateData();
        }
    };
    ChartDataModel.prototype.updateCellRanges = function (updatedColState) {
        if (this.valueCellRange) {
            this.referenceCellRange = this.valueCellRange;
        }
        var _a = this.chartColumnService.getChartColumns(), dimensionCols = _a.dimensionCols, valueCols = _a.valueCols;
        var allColsFromRanges = this.getAllColumnsFromRanges();
        if (updatedColState) {
            this.updateColumnState(updatedColState);
        }
        this.setDimensionCellRange(dimensionCols, allColsFromRanges, updatedColState);
        this.setValueCellRange(valueCols, allColsFromRanges, updatedColState);
        if (!updatedColState) {
            this.resetColumnState();
            // dimension / category cell range could be out of sync after resetting column state when row grouping
            this.syncDimensionCellRange();
        }
        this.comboChartModel.updateSeriesChartTypes();
    };
    ChartDataModel.prototype.updateData = function () {
        var _a = this.getRowIndexes(), startRow = _a.startRow, endRow = _a.endRow;
        if (this.pivotChart) {
            this.resetColumnState();
        }
        this.grouping = this.isGrouping();
        var params = {
            aggFunc: this.aggFunc,
            dimensionCols: [this.getSelectedDimension()],
            grouping: this.grouping,
            pivoting: this.isPivotActive(),
            crossFiltering: this.crossFiltering,
            valueCols: this.getSelectedValueCols(),
            startRow: startRow,
            endRow: endRow,
            isScatter: _.includes(['scatter', 'bubble'], this.chartType)
        };
        var _b = this.datasource.getData(params), chartData = _b.chartData, columnNames = _b.columnNames;
        this.chartData = chartData;
        this.columnNames = columnNames;
    };
    ChartDataModel.prototype.isGrouping = function () {
        var usingTreeData = this.gridOptionsService.get('treeData');
        var groupedCols = usingTreeData ? null : this.chartColumnService.getRowGroupColumns();
        var isGroupActive = usingTreeData || (groupedCols && groupedCols.length > 0);
        // charts only group when the selected category is a group column
        var colId = this.getSelectedDimension().colId;
        var displayedGroupCols = this.chartColumnService.getGroupDisplayColumns();
        var groupDimensionSelected = displayedGroupCols.map(function (col) { return col.getColId(); }).some(function (id) { return id === colId; });
        return !!isGroupActive && groupDimensionSelected;
    };
    ChartDataModel.prototype.getSelectedValueCols = function () {
        return this.valueColState.filter(function (cs) { return cs.selected; }).map(function (cs) { return cs.column; });
    };
    ChartDataModel.prototype.getSelectedDimension = function () {
        return this.dimensionColState.filter(function (cs) { return cs.selected; })[0];
    };
    ChartDataModel.prototype.getColDisplayName = function (col) {
        return this.chartColumnService.getColDisplayName(col);
    };
    ChartDataModel.prototype.isPivotMode = function () {
        return this.chartColumnService.isPivotMode();
    };
    ChartDataModel.prototype.getChartDataType = function (colId) {
        var column = this.chartColumnService.getColumn(colId);
        return column ? column.getColDef().chartDataType : undefined;
    };
    ChartDataModel.prototype.isPivotActive = function () {
        return this.chartColumnService.isPivotActive();
    };
    ChartDataModel.prototype.createCellRange = function (type) {
        var columns = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            columns[_i - 1] = arguments[_i];
        }
        return {
            id: this.chartId,
            startRow: this.referenceCellRange.startRow,
            endRow: this.referenceCellRange.endRow,
            columns: columns,
            startColumn: type === CellRangeType.DIMENSION ? columns[0] : this.referenceCellRange.startColumn,
            type: type
        };
    };
    ChartDataModel.prototype.getAllColumnsFromRanges = function () {
        if (this.pivotChart) {
            return _.convertToSet(this.chartColumnService.getAllDisplayedColumns());
        }
        var columns = this.dimensionCellRange || this.valueCellRange ? [] : this.referenceCellRange.columns;
        if (this.dimensionCellRange) {
            columns.push.apply(columns, __spreadArray$a([], __read$h(this.dimensionCellRange.columns), false));
        }
        if (this.valueCellRange) {
            columns.push.apply(columns, __spreadArray$a([], __read$h(this.valueCellRange.columns), false));
        }
        return _.convertToSet(columns);
    };
    ChartDataModel.prototype.getRowIndexes = function () {
        var startRow = 0, endRow = 0;
        var _a = this, rangeService = _a.rangeService, valueCellRange = _a.valueCellRange;
        if (rangeService && valueCellRange) {
            startRow = rangeService.getRangeStartRow(valueCellRange).rowIndex;
            // when the last row the cell range is a pinned 'bottom' row, the `endRow` index is set to -1 which results
            // in the ChartDatasource processing all non pinned rows from the `startRow` index.
            var endRowPosition = rangeService.getRangeEndRow(valueCellRange);
            endRow = endRowPosition.rowPinned === 'bottom' ? -1 : endRowPosition.rowIndex;
        }
        return { startRow: startRow, endRow: endRow };
    };
    ChartDataModel.prototype.resetColumnState = function () {
        var _this = this;
        var _a = this.chartColumnService.getChartColumns(), dimensionCols = _a.dimensionCols, valueCols = _a.valueCols;
        var allCols = this.getAllColumnsFromRanges();
        var isInitialising = this.valueColState.length < 1;
        this.dimensionColState = [];
        this.valueColState = [];
        var hasSelectedDimension = false;
        var order = 1;
        var aggFuncDimension = this.suppliedCellRange.columns[0]; //TODO
        dimensionCols.forEach(function (column) {
            var isAutoGroupCol = column.getColId() === 'ag-Grid-AutoColumn';
            var selected = false;
            if (_this.crossFiltering && _this.aggFunc) {
                if (aggFuncDimension.getColId() === column.getColId()) {
                    selected = true;
                }
            }
            else {
                selected = isAutoGroupCol ? true : !hasSelectedDimension && allCols.has(column);
            }
            _this.dimensionColState.push({
                column: column,
                colId: column.getColId(),
                displayName: _this.getColDisplayName(column),
                selected: selected,
                order: order++
            });
            if (selected) {
                hasSelectedDimension = true;
            }
        });
        var defaultCategory = {
            colId: ChartDataModel.DEFAULT_CATEGORY,
            displayName: this.chartTranslationService.translate('defaultCategory'),
            selected: !hasSelectedDimension,
            order: 0
        };
        this.dimensionColState.unshift(defaultCategory);
        var valueColumnsFromReferenceRange = this.referenceCellRange.columns.filter(function (c) { return valueCols.has(c); });
        valueCols.forEach(function (column) {
            // first time the value cell range is set, preserve the column order from the supplied range
            if (isInitialising && _.includes(_this.referenceCellRange.columns, column)) {
                column = valueColumnsFromReferenceRange.shift();
            }
            _this.valueColState.push({
                column: column,
                colId: column.getColId(),
                displayName: _this.getColDisplayName(column),
                selected: allCols.has(column),
                order: order++
            });
        });
    };
    ChartDataModel.prototype.updateColumnState = function (updatedCol) {
        var idsMatch = function (cs) { return cs.colId === updatedCol.colId; };
        var _a = this, dimensionColState = _a.dimensionColState, valueColState = _a.valueColState;
        if (dimensionColState.filter(idsMatch).length > 0) {
            // only one dimension should be selected
            dimensionColState.forEach(function (cs) { return cs.selected = idsMatch(cs); });
        }
        else {
            // just update the selected value on the supplied value column
            valueColState.filter(idsMatch).forEach(function (cs) { return cs.selected = updatedCol.selected; });
        }
        var allColumns = __spreadArray$a(__spreadArray$a([], __read$h(dimensionColState), false), __read$h(valueColState), false);
        var orderedColIds = [];
        // calculate new order
        allColumns.forEach(function (col, i) {
            if (i === updatedCol.order) {
                orderedColIds.push(updatedCol.colId);
            }
            if (col.colId !== updatedCol.colId) {
                orderedColIds.push(col.colId);
            }
        });
        // update col state with new order
        allColumns.forEach(function (col) {
            var order = orderedColIds.indexOf(col.colId);
            col.order = order >= 0 ? orderedColIds.indexOf(col.colId) : allColumns.length - 1;
        });
        this.reorderColState();
    };
    ChartDataModel.prototype.reorderColState = function () {
        var ascColStateOrder = function (a, b) { return a.order - b.order; };
        this.dimensionColState.sort(ascColStateOrder);
        this.valueColState.sort(ascColStateOrder);
    };
    ChartDataModel.prototype.setDimensionCellRange = function (dimensionCols, colsInRange, updatedColState) {
        var _this = this;
        this.dimensionCellRange = undefined;
        if (!updatedColState && !this.dimensionColState.length) {
            // use first dimension column in range by default
            dimensionCols.forEach(function (col) {
                if (_this.dimensionCellRange || !colsInRange.has(col)) {
                    return;
                }
                _this.dimensionCellRange = _this.createCellRange(CellRangeType.DIMENSION, col);
            });
            return;
        }
        var selectedDimensionColState = updatedColState;
        if (this.crossFiltering && this.aggFunc) {
            var aggFuncDimension_1 = this.suppliedCellRange.columns[0]; //TODO
            selectedDimensionColState = this.dimensionColState.filter(function (cs) { return cs.colId === aggFuncDimension_1.getColId(); })[0];
        }
        else if (!selectedDimensionColState || !dimensionCols.has(selectedDimensionColState.column)) {
            selectedDimensionColState = this.dimensionColState.filter(function (cs) { return cs.selected; })[0];
        }
        if (selectedDimensionColState && selectedDimensionColState.colId !== ChartDataModel.DEFAULT_CATEGORY) {
            this.dimensionCellRange = this.createCellRange(CellRangeType.DIMENSION, selectedDimensionColState.column);
        }
    };
    ChartDataModel.prototype.setValueCellRange = function (valueCols, colsInRange, updatedColState) {
        this.valueCellRange = undefined;
        var selectedValueCols = [];
        valueCols.forEach(function (col) {
            if (updatedColState && updatedColState.colId === col.getColId()) {
                if (updatedColState.selected) {
                    selectedValueCols.push(updatedColState.column);
                }
            }
            else if (colsInRange.has(col)) {
                selectedValueCols.push(col);
            }
        });
        if (selectedValueCols.length > 0) {
            var orderedColIds_1 = [];
            if (this.valueColState.length > 0) {
                orderedColIds_1 = this.valueColState.map(function (c) { return c.colId; });
            }
            else {
                colsInRange.forEach(function (c) { return orderedColIds_1.push(c.getColId()); });
            }
            selectedValueCols.sort(function (a, b) { return orderedColIds_1.indexOf(a.getColId()) - orderedColIds_1.indexOf(b.getColId()); });
            this.valueCellRange = this.createCellRange.apply(this, __spreadArray$a([CellRangeType.VALUE], __read$h(selectedValueCols), false));
        }
    };
    ChartDataModel.prototype.updateSelectedDimension = function (columns) {
        var colIdSet = new Set(columns.map(function (column) { return column.getColId(); }));
        // if no dimension found in supplied columns use the default category (always index = 0)
        var foundColState = this.dimensionColState.find(function (colState) { return colIdSet.has(colState.colId); }) || this.dimensionColState[0];
        this.dimensionColState = this.dimensionColState.map(function (colState) { return (__assign$m(__assign$m({}, colState), { selected: colState.colId === foundColState.colId })); });
    };
    ChartDataModel.prototype.syncDimensionCellRange = function () {
        var selectedDimension = this.getSelectedDimension();
        if (selectedDimension && selectedDimension.column) {
            this.dimensionCellRange = this.createCellRange(CellRangeType.DIMENSION, selectedDimension.column);
        }
    };
    ChartDataModel.prototype.isComboChart = function () {
        return ['columnLineCombo', 'areaColumnCombo', 'customCombo'].includes(this.chartType);
    };
    ChartDataModel.DEFAULT_CATEGORY = 'AG-GRID-DEFAULT-CATEGORY';
    __decorate$N([
        Autowired('rangeService')
    ], ChartDataModel.prototype, "rangeService", void 0);
    __decorate$N([
        Autowired('chartTranslationService')
    ], ChartDataModel.prototype, "chartTranslationService", void 0);
    __decorate$N([
        PostConstruct
    ], ChartDataModel.prototype, "init", null);
    return ChartDataModel;
}(BeanStub));

// import { _Scene, AgCartesianAxisType, AgChartInstance } from "ag-charts-enterprise";
function deproxy(chartOrProxy) {
    if (chartOrProxy.chart != null) {
        return chartOrProxy.chart;
    }
    return chartOrProxy;
}

var __assign$l = (undefined && undefined.__assign) || function () {
    __assign$l = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$l.apply(this, arguments);
};
var ChartProxy = /** @class */ (function () {
    function ChartProxy(chartProxyParams) {
        this.chartProxyParams = chartProxyParams;
        this.clearThemeOverrides = false;
        this.chart = chartProxyParams.chartInstance;
        this.chartType = chartProxyParams.chartType;
        this.crossFiltering = chartProxyParams.crossFiltering;
        this.crossFilterCallback = chartProxyParams.crossFilterCallback;
        this.standaloneChartType = getSeriesType(this.chartType);
        if (this.chart == null) {
            this.chart = AgCharts.create(this.getCommonChartOptions());
        }
        else {
            // On chart change, reset formatting panel changes.
            this.clearThemeOverrides = true;
        }
    }
    ChartProxy.prototype.getChart = function () {
        return deproxy(this.chart);
    };
    ChartProxy.prototype.getChartRef = function () {
        return this.chart;
    };
    ChartProxy.prototype.downloadChart = function (dimensions, fileName, fileFormat) {
        var chart = this.chart;
        var rawChart = deproxy(chart);
        var imageFileName = fileName || (rawChart.title ? rawChart.title.text : 'chart');
        var _a = dimensions || {}, width = _a.width, height = _a.height;
        AgCharts.download(chart, { width: width, height: height, fileName: imageFileName, fileFormat: fileFormat });
    };
    ChartProxy.prototype.getChartImageDataURL = function (type) {
        return this.getChart().scene.getDataURL(type);
    };
    ChartProxy.prototype.getChartOptions = function () {
        return this.chart.getOptions();
    };
    ChartProxy.prototype.getChartThemeOverrides = function () {
        var _a;
        var chartOptionsTheme = this.getChartOptions().theme;
        return (_a = chartOptionsTheme.overrides) !== null && _a !== void 0 ? _a : {};
    };
    ChartProxy.prototype.getChartPalette = function () {
        return integrated_charts_theme_exports$1.getChartTheme(this.getChartOptions().theme).palette;
    };
    ChartProxy.prototype.setPaired = function (paired) {
        var _a;
        // Special handling to make scatter charts operate in paired mode by default, where 
        // columns alternate between being X and Y (and size for bubble). In standard mode,
        // the first column is used for X and every other column is treated as Y
        // (or alternates between Y and size for bubble)
        var seriesType = getSeriesType(this.chartProxyParams.chartType);
        AgCharts.updateDelta(this.chart, { theme: { overrides: (_a = {}, _a[seriesType] = { paired: paired }, _a) } });
    };
    ChartProxy.prototype.isPaired = function () {
        var seriesType = getSeriesType(this.chartProxyParams.chartType);
        return _.get(this.getChartThemeOverrides(), "".concat(seriesType, ".paired"), true);
    };
    ChartProxy.prototype.lookupCustomChartTheme = function (themeName) {
        return lookupCustomChartTheme(this.chartProxyParams, themeName);
    };
    ChartProxy.prototype.transformData = function (data, categoryKey, categoryAxis) {
        if (categoryAxis) {
            // replace the values for the selected category with a complex object to allow for duplicated categories
            return data.map(function (d, index) {
                var value = d[categoryKey];
                var valueString = value && value.toString ? value.toString() : '';
                var datum = __assign$l({}, d);
                datum[categoryKey] = { id: index, value: value, toString: function () { return valueString; } };
                return datum;
            });
        }
        return data;
    };
    ChartProxy.prototype.getCommonChartOptions = function (updatedOverrides) {
        var _a, _b;
        // Only apply active overrides if chart is initialised.
        var existingOptions = this.clearThemeOverrides ? {} : (_b = (_a = this.chart) === null || _a === void 0 ? void 0 : _a.getOptions()) !== null && _b !== void 0 ? _b : {};
        var formattingPanelOverrides = this.chart != null ?
            { overrides: this.getActiveFormattingPanelOverrides() } : {};
        this.clearThemeOverrides = false;
        return __assign$l(__assign$l({}, existingOptions), { theme: __assign$l(__assign$l({}, createAgChartTheme(this.chartProxyParams, this)), (updatedOverrides ? { overrides: updatedOverrides } : formattingPanelOverrides)), container: this.chartProxyParams.parentElement, mode: 'integrated' });
    };
    ChartProxy.prototype.getActiveFormattingPanelOverrides = function () {
        var _a, _b;
        if (this.clearThemeOverrides) {
            return {};
        }
        var inUseTheme = (_a = this.chart) === null || _a === void 0 ? void 0 : _a.getOptions().theme;
        return (_b = inUseTheme === null || inUseTheme === void 0 ? void 0 : inUseTheme.overrides) !== null && _b !== void 0 ? _b : {};
    };
    ChartProxy.prototype.destroy = function (_a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.keepChartInstance, keepChartInstance = _c === void 0 ? false : _c;
        if (keepChartInstance) {
            return this.chart;
        }
        this.destroyChart();
    };
    ChartProxy.prototype.destroyChart = function () {
        if (this.chart) {
            this.chart.destroy();
            this.chart = undefined;
        }
    };
    return ChartProxy;
}());

var __extends$_ = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$k = (undefined && undefined.__assign) || function () {
    __assign$k = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$k.apply(this, arguments);
};
var CartesianChartProxy = /** @class */ (function (_super) {
    __extends$_(CartesianChartProxy, _super);
    function CartesianChartProxy(params) {
        var _this = _super.call(this, params) || this;
        _this.crossFilteringAllPoints = new Set();
        _this.crossFilteringSelectedPoints = [];
        return _this;
    }
    CartesianChartProxy.prototype.update = function (params) {
        var axes = this.getAxes(params);
        var options = __assign$k(__assign$k({}, this.getCommonChartOptions(params.updatedOverrides)), { data: this.getData(params, axes), axes: axes, series: this.getSeries(params) });
        AgCharts.update(this.getChartRef(), options);
    };
    CartesianChartProxy.prototype.getData = function (params, axes) {
        var supportsCrossFiltering = ['area', 'line'].includes(this.standaloneChartType);
        var xAxisIsCategory = axes.some(function (axes) { return (axes === null || axes === void 0 ? void 0 : axes.type) === 'category'; });
        return this.crossFiltering && supportsCrossFiltering ?
            this.getCrossFilterData(params) :
            this.getDataTransformedData(params, xAxisIsCategory);
    };
    CartesianChartProxy.prototype.getDataTransformedData = function (params, isCategoryAxis) {
        return this.transformData(params.data, params.category.id, isCategoryAxis);
    };
    CartesianChartProxy.prototype.getXAxisType = function (params) {
        if (params.grouping) {
            return 'grouped-category';
        }
        else if (CartesianChartProxy.isTimeAxis(params)) {
            return 'time';
        }
        return 'category';
    };
    CartesianChartProxy.isTimeAxis = function (params) {
        if (params.category && params.category.chartDataType) {
            return params.category.chartDataType === 'time';
        }
        var testDatum = params.data[0];
        return (testDatum && testDatum[params.category.id]) instanceof Date;
    };
    CartesianChartProxy.prototype.crossFilteringReset = function () {
        this.crossFilteringSelectedPoints = [];
        this.crossFilteringAllPoints.clear();
    };
    CartesianChartProxy.prototype.crossFilteringPointSelected = function (point) {
        return this.crossFilteringSelectedPoints.length == 0 || this.crossFilteringSelectedPoints.includes(point);
    };
    CartesianChartProxy.prototype.crossFilteringDeselectedPoints = function () {
        return this.crossFilteringSelectedPoints.length > 0 &&
            this.crossFilteringAllPoints.size !== this.crossFilteringSelectedPoints.length;
    };
    CartesianChartProxy.prototype.extractLineAreaCrossFilterSeries = function (series, params) {
        var _this = this;
        var getYKey = function (yKey) {
            if (_this.standaloneChartType === 'area') {
                var lastSelectedChartId = params.getCrossFilteringContext().lastSelectedChartId;
                return (lastSelectedChartId === params.chartId) ? yKey + '-total' : yKey;
            }
            return yKey + '-total';
        };
        return series.map(function (s) {
            s.yKey = getYKey(s.yKey);
            s.listeners = {
                nodeClick: function (e) {
                    var value = e.datum[s.xKey];
                    var multiSelection = e.event.metaKey || e.event.ctrlKey;
                    _this.crossFilteringAddSelectedPoint(multiSelection, value);
                    _this.crossFilterCallback(e);
                }
            };
            s.marker = {
                formatter: function (p) {
                    var category = p.datum[params.category.id];
                    return {
                        fill: p.highlighted ? 'yellow' : p.fill,
                        size: p.highlighted ? 14 : _this.crossFilteringPointSelected(category) ? 8 : 0,
                    };
                }
            };
            if (_this.standaloneChartType === 'area') {
                s.fillOpacity = _this.crossFilteringDeselectedPoints() ? 0.3 : 1;
            }
            if (_this.standaloneChartType === 'line') {
                s.strokeOpacity = _this.crossFilteringDeselectedPoints() ? 0.3 : 1;
            }
            return s;
        });
    };
    CartesianChartProxy.prototype.getCrossFilterData = function (params) {
        var _this = this;
        this.crossFilteringAllPoints.clear();
        var colId = params.fields[0].colId;
        var filteredOutColId = "".concat(colId, "-filtered-out");
        var lastSelectedChartId = params.getCrossFilteringContext().lastSelectedChartId;
        return params.data.map(function (d) {
            var category = d[params.category.id];
            _this.crossFilteringAllPoints.add(category);
            var pointSelected = _this.crossFilteringPointSelected(category);
            if (_this.standaloneChartType === 'area' && lastSelectedChartId === params.chartId) {
                d["".concat(colId, "-total")] = pointSelected ? d[colId] : d[colId] + d[filteredOutColId];
            }
            if (_this.standaloneChartType === 'line') {
                d["".concat(colId, "-total")] = pointSelected ? d[colId] : d[colId] + d[filteredOutColId];
            }
            return d;
        });
    };
    CartesianChartProxy.prototype.crossFilteringAddSelectedPoint = function (multiSelection, value) {
        multiSelection ? this.crossFilteringSelectedPoints.push(value) : this.crossFilteringSelectedPoints = [value];
    };
    return CartesianChartProxy;
}(ChartProxy));

// deepMerge
function emptyTarget(value) {
    return Array.isArray(value) ? [] : {};
}
function cloneUnlessOtherwiseSpecified(value, options) {
    return (options.clone !== false && options.isMergeableObject(value))
        ? deepMerge$1(emptyTarget(value), value, options)
        : value;
}
function defaultArrayMerge(target, source, options) {
    return target.concat(source).map(function (element) {
        return cloneUnlessOtherwiseSpecified(element, options);
    });
}
function getMergeFunction(key, options) {
    if (!options.customMerge) {
        return deepMerge$1;
    }
    var customMerge = options.customMerge(key);
    return typeof customMerge === 'function' ? customMerge : deepMerge$1;
}
function getEnumerableOwnPropertySymbols(target) {
    // @ts-ignore
    return Object.getOwnPropertySymbols
        // @ts-ignore
        ? Object.getOwnPropertySymbols(target).filter(function (symbol) {
            return target.propertyIsEnumerable(symbol);
        })
        : [];
}
function getKeys(target) {
    return Object.keys(target).concat(getEnumerableOwnPropertySymbols(target));
}
function propertyIsOnObject(object, property) {
    try {
        return property in object;
    }
    catch (_) {
        return false;
    }
}
// Protects from prototype poisoning and unexpected merging up the prototype chain.
function propertyIsUnsafe(target, key) {
    return propertyIsOnObject(target, key) // Properties are safe to merge if they don't exist in the target yet,
        && !(Object.hasOwnProperty.call(target, key) // unsafe if they exist up the prototype chain,
            && Object.propertyIsEnumerable.call(target, key)); // and also unsafe if they're nonenumerable.
}
function mergeObject(target, source, options) {
    if (target === void 0) { target = {}; }
    if (source === void 0) { source = {}; }
    var destination = {};
    if (options.isMergeableObject(target)) {
        getKeys(target).forEach(function (key) {
            destination[key] = cloneUnlessOtherwiseSpecified(target[key], options);
        });
    }
    getKeys(source).forEach(function (key) {
        if (propertyIsUnsafe(target, key)) {
            return;
        }
        if (propertyIsOnObject(target, key) && options.isMergeableObject(source[key])) {
            destination[key] = getMergeFunction(key, options)(target[key], source[key], options);
        }
        else {
            destination[key] = cloneUnlessOtherwiseSpecified(source[key], options);
        }
    });
    return destination;
}
function defaultIsMergeableObject(value) {
    return isNonNullObject(value) && !isSpecial(value);
}
function isNonNullObject(value) {
    return !!value && typeof value === 'object';
}
function isSpecial(value) {
    var stringValue = Object.prototype.toString.call(value);
    return stringValue === '[object RegExp]' || stringValue === '[object Date]';
}
function deepMerge$1(target, source, options) {
    options = options || {};
    options.arrayMerge = options.arrayMerge || defaultArrayMerge;
    options.isMergeableObject = options.isMergeableObject || defaultIsMergeableObject;
    // cloneUnlessOtherwiseSpecified is added to `options` so that custom arrayMerge()
    // implementations can use it. The caller may not replace it.
    options.cloneUnlessOtherwiseSpecified = cloneUnlessOtherwiseSpecified;
    var sourceIsArray = Array.isArray(source);
    var targetIsArray = Array.isArray(target);
    var sourceAndTargetTypesMatch = sourceIsArray === targetIsArray;
    if (!sourceAndTargetTypesMatch) {
        return cloneUnlessOtherwiseSpecified(source, options);
    }
    else if (sourceIsArray) {
        return options.arrayMerge(target, source, options);
    }
    else {
        return mergeObject(target, source, options);
    }
}

// import { _Util } from "ag-charts-enterprise";
function hexToRGBA(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16);
    var g = parseInt(hex.slice(3, 5), 16);
    var b = parseInt(hex.slice(5, 7), 16);
    return alpha ? "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(alpha, ")") : "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ")");
}
function changeOpacity(fills, alpha) {
    return fills.map(function (fill) {
        var c = sparklines_util_exports$1.Color.fromString(fill);
        return new sparklines_util_exports$1.Color(c.r, c.g, c.b, alpha).toHexString();
    });
}

var __extends$Z = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$j = (undefined && undefined.__assign) || function () {
    __assign$j = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$j.apply(this, arguments);
};
var BarChartProxy = /** @class */ (function (_super) {
    __extends$Z(BarChartProxy, _super);
    function BarChartProxy(params) {
        return _super.call(this, params) || this;
    }
    BarChartProxy.prototype.getAxes = function (params) {
        var axes = [
            {
                type: this.getXAxisType(params),
                position: isHorizontal(this.chartType) ? 'left' : 'bottom',
            },
            {
                type: 'number',
                position: isHorizontal(this.chartType) ? 'bottom' : 'left',
            },
        ];
        // Add a default label formatter to show '%' for normalized charts if none is provided
        if (this.isNormalised()) {
            var numberAxis = axes[1];
            numberAxis.label = __assign$j(__assign$j({}, numberAxis.label), { formatter: function (params) { return Math.round(params.value) + '%'; } });
        }
        return axes;
    };
    BarChartProxy.prototype.getSeries = function (params) {
        var _this = this;
        var series = params.fields.map(function (f) { return ({
            type: _this.standaloneChartType,
            direction: isHorizontal(_this.chartType) ? 'horizontal' : 'vertical',
            stacked: _this.crossFiltering || isStacked(_this.chartType),
            normalizedTo: _this.isNormalised() ? 100 : undefined,
            xKey: params.category.id,
            xName: params.category.name,
            yKey: f.colId,
            yName: f.displayName
        }); });
        return this.crossFiltering ? this.extractCrossFilterSeries(series) : series;
    };
    BarChartProxy.prototype.extractCrossFilterSeries = function (series) {
        var _this = this;
        var palette = this.getChartPalette();
        var updatePrimarySeries = function (seriesOptions, index) {
            return __assign$j(__assign$j({}, seriesOptions), { highlightStyle: { item: { fill: undefined } }, fill: palette === null || palette === void 0 ? void 0 : palette.fills[index], stroke: palette === null || palette === void 0 ? void 0 : palette.strokes[index], listeners: {
                    nodeClick: _this.crossFilterCallback
                } });
        };
        var updateFilteredOutSeries = function (seriesOptions) {
            var yKey = seriesOptions.yKey + '-filtered-out';
            return __assign$j(__assign$j({}, deepMerge$1({}, seriesOptions)), { yKey: yKey, fill: hexToRGBA(seriesOptions.fill, '0.3'), stroke: hexToRGBA(seriesOptions.stroke, '0.3'), showInLegend: false });
        };
        var allSeries = [];
        for (var i = 0; i < series.length; i++) {
            // update primary series
            var primarySeries = updatePrimarySeries(series[i], i);
            allSeries.push(primarySeries);
            // add 'filtered-out' series
            allSeries.push(updateFilteredOutSeries(primarySeries));
        }
        return allSeries;
    };
    BarChartProxy.prototype.isNormalised = function () {
        var normalisedCharts = ['normalizedColumn', 'normalizedBar'];
        return !this.crossFiltering && _.includes(normalisedCharts, this.chartType);
    };
    return BarChartProxy;
}(CartesianChartProxy));

var __extends$Y = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$i = (undefined && undefined.__assign) || function () {
    __assign$i = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$i.apply(this, arguments);
};
var AreaChartProxy = /** @class */ (function (_super) {
    __extends$Y(AreaChartProxy, _super);
    function AreaChartProxy(params) {
        return _super.call(this, params) || this;
    }
    AreaChartProxy.prototype.getAxes = function (params) {
        var axes = [
            {
                type: this.getXAxisType(params),
                position: 'bottom',
            },
            {
                type: 'number',
                position: 'left',
            },
        ];
        // Add a default label formatter to show '%' for normalized charts if none is provided
        if (this.isNormalised()) {
            var numberAxis = axes[1];
            numberAxis.label = __assign$i(__assign$i({}, numberAxis.label), { formatter: function (params) { return Math.round(params.value) + '%'; } });
        }
        return axes;
    };
    AreaChartProxy.prototype.getSeries = function (params) {
        var _this = this;
        var series = params.fields.map(function (f) { return ({
            type: _this.standaloneChartType,
            xKey: params.category.id,
            xName: params.category.name,
            yKey: f.colId,
            yName: f.displayName,
            normalizedTo: _this.chartType === 'normalizedArea' ? 100 : undefined,
            stacked: ['normalizedArea', 'stackedArea'].includes(_this.chartType)
        }); });
        return this.crossFiltering ? this.extractLineAreaCrossFilterSeries(series, params) : series;
    };
    AreaChartProxy.prototype.isNormalised = function () {
        return !this.crossFiltering && this.chartType === 'normalizedArea';
    };
    return AreaChartProxy;
}(CartesianChartProxy));

var __extends$X = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var LineChartProxy = /** @class */ (function (_super) {
    __extends$X(LineChartProxy, _super);
    function LineChartProxy(params) {
        return _super.call(this, params) || this;
    }
    LineChartProxy.prototype.getAxes = function (params) {
        return [
            {
                type: this.getXAxisType(params),
                position: 'bottom'
            },
            {
                type: 'number',
                position: 'left'
            },
        ];
    };
    LineChartProxy.prototype.getSeries = function (params) {
        var _this = this;
        var series = params.fields.map(function (f) { return ({
            type: _this.standaloneChartType,
            xKey: params.category.id,
            xName: params.category.name,
            yKey: f.colId,
            yName: f.displayName
        }); });
        return this.crossFiltering ? this.extractLineAreaCrossFilterSeries(series, params) : series;
    };
    return LineChartProxy;
}(CartesianChartProxy));

var __extends$W = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$h = (undefined && undefined.__assign) || function () {
    __assign$h = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$h.apply(this, arguments);
};
var PieChartProxy = /** @class */ (function (_super) {
    __extends$W(PieChartProxy, _super);
    function PieChartProxy(params) {
        return _super.call(this, params) || this;
    }
    PieChartProxy.prototype.update = function (params) {
        var data = params.data, category = params.category;
        var options = __assign$h(__assign$h({}, this.getCommonChartOptions(params.updatedOverrides)), { data: this.crossFiltering ? this.getCrossFilterData(params) : this.transformData(data, category.id), series: this.getSeries(params) });
        AgCharts.update(this.getChartRef(), options);
    };
    PieChartProxy.prototype.getSeries = function (params) {
        var _this = this;
        var numFields = params.fields.length;
        var offset = {
            currentOffset: 0,
            offsetAmount: numFields > 1 ? 20 : 40
        };
        var series = this.getFields(params).map(function (f) {
            var _a;
            // options shared by 'pie' and 'doughnut' charts
            var options = {
                type: _this.standaloneChartType,
                angleKey: f.colId,
                angleName: f.displayName,
                sectorLabelKey: f.colId,
                legendItemKey: params.category.id,
                calloutLabelName: params.category.name,
                calloutLabelKey: params.category.id,
            };
            if (_this.chartType === 'doughnut') {
                var _b = PieChartProxy.calculateOffsets(offset), outerRadiusOffset = _b.outerRadiusOffset, innerRadiusOffset = _b.innerRadiusOffset;
                var title = f.displayName ? {
                    title: { text: f.displayName, showInLegend: numFields > 1 },
                } : undefined;
                // augment shared options with 'doughnut' specific options
                return __assign$h(__assign$h(__assign$h(__assign$h({}, options), { outerRadiusOffset: outerRadiusOffset, innerRadiusOffset: innerRadiusOffset }), title), { calloutLine: {
                        colors: (_a = _this.getChartPalette()) === null || _a === void 0 ? void 0 : _a.strokes,
                    } });
            }
            return options;
        });
        return this.crossFiltering ? this.extractCrossFilterSeries(series) : series;
    };
    PieChartProxy.prototype.getCrossFilterData = function (params) {
        var colId = params.fields[0].colId;
        var filteredOutColId = "".concat(colId, "-filtered-out");
        return params.data.map(function (d) {
            var total = d[colId] + d[filteredOutColId];
            d["".concat(colId, "-total")] = total;
            d[filteredOutColId] = 1; // normalise to 1
            d[colId] = d[colId] / total; // fraction of 1
            return d;
        });
    };
    PieChartProxy.prototype.extractCrossFilterSeries = function (series) {
        var _this = this;
        var palette = this.getChartPalette();
        var primaryOptions = function (seriesOptions) {
            return __assign$h(__assign$h({}, seriesOptions), { calloutLabel: { enabled: false }, highlightStyle: { item: { fill: undefined } }, radiusKey: seriesOptions.angleKey, angleKey: seriesOptions.angleKey + '-total', radiusMin: 0, radiusMax: 1, listeners: {
                    nodeClick: _this.crossFilterCallback,
                } });
        };
        var filteredOutOptions = function (seriesOptions, angleKey) {
            var _a, _b;
            return __assign$h(__assign$h({}, deepMerge$1({}, primaryOpts)), { radiusKey: angleKey + '-filtered-out', fills: changeOpacity((_a = seriesOptions.fills) !== null && _a !== void 0 ? _a : palette.fills, 0.3), strokes: changeOpacity((_b = seriesOptions.strokes) !== null && _b !== void 0 ? _b : palette.strokes, 0.3), showInLegend: false });
        };
        // currently, only single 'doughnut' cross-filter series are supported
        var primarySeries = series[0];
        // update primary series
        var angleKey = primarySeries.angleKey;
        var primaryOpts = primaryOptions(primarySeries);
        return [
            filteredOutOptions(primarySeries, angleKey),
            primaryOpts,
        ];
    };
    PieChartProxy.calculateOffsets = function (offset) {
        var outerRadiusOffset = offset.currentOffset;
        offset.currentOffset -= offset.offsetAmount;
        var innerRadiusOffset = offset.currentOffset;
        offset.currentOffset -= offset.offsetAmount;
        return { outerRadiusOffset: outerRadiusOffset, innerRadiusOffset: innerRadiusOffset };
    };
    PieChartProxy.prototype.getFields = function (params) {
        return this.chartType === 'pie' ? params.fields.slice(0, 1) : params.fields;
    };
    PieChartProxy.prototype.crossFilteringReset = function () {
        // not required in pie charts
    };
    return PieChartProxy;
}(ChartProxy));

var __extends$V = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$g = (undefined && undefined.__assign) || function () {
    __assign$g = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$g.apply(this, arguments);
};
var __values$5 = (undefined && undefined.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read$g = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$9 = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var ScatterChartProxy = /** @class */ (function (_super) {
    __extends$V(ScatterChartProxy, _super);
    function ScatterChartProxy(params) {
        return _super.call(this, params) || this;
    }
    ScatterChartProxy.prototype.getAxes = function (_params) {
        return [
            {
                type: 'number',
                position: 'bottom',
            },
            {
                type: 'number',
                position: 'left',
            },
        ];
    };
    ScatterChartProxy.prototype.getSeries = function (params) {
        var paired = this.isPaired();
        var seriesDefinitions = this.getSeriesDefinitions(params.fields, paired);
        var labelFieldDefinition = params.category.id === ChartDataModel.DEFAULT_CATEGORY ? undefined : params.category;
        var series = seriesDefinitions.map(function (seriesDefinition) {
            var _a, _b, _c, _d, _e;
            if (seriesDefinition === null || seriesDefinition === void 0 ? void 0 : seriesDefinition.sizeField) {
                var opts_1 = {
                    type: 'bubble',
                    xKey: seriesDefinition.xField.colId,
                    xName: (_a = seriesDefinition.xField.displayName) !== null && _a !== void 0 ? _a : undefined,
                    yKey: seriesDefinition.yField.colId,
                    yName: (_b = seriesDefinition.yField.displayName) !== null && _b !== void 0 ? _b : undefined,
                    title: "".concat(seriesDefinition.yField.displayName, " vs ").concat(seriesDefinition.xField.displayName),
                    sizeKey: seriesDefinition.sizeField.colId,
                    sizeName: (_c = seriesDefinition.sizeField.displayName) !== null && _c !== void 0 ? _c : '',
                    labelKey: labelFieldDefinition ? labelFieldDefinition.id : seriesDefinition.yField.colId,
                    labelName: labelFieldDefinition ? labelFieldDefinition.name : undefined,
                };
                return opts_1;
            }
            var opts = {
                type: 'scatter',
                xKey: seriesDefinition.xField.colId,
                xName: (_d = seriesDefinition.xField.displayName) !== null && _d !== void 0 ? _d : undefined,
                yKey: seriesDefinition.yField.colId,
                yName: (_e = seriesDefinition.yField.displayName) !== null && _e !== void 0 ? _e : undefined,
                title: "".concat(seriesDefinition.yField.displayName, " vs ").concat(seriesDefinition.xField.displayName),
                labelKey: labelFieldDefinition ? labelFieldDefinition.id : seriesDefinition.yField.colId,
                labelName: labelFieldDefinition ? labelFieldDefinition.name : undefined,
            };
            return opts;
        });
        return this.crossFiltering ? this.extractCrossFilterSeries(series, params) : series;
    };
    ScatterChartProxy.prototype.extractCrossFilterSeries = function (series, params) {
        var _this = this;
        var data = params.data;
        var palette = this.getChartPalette();
        var filteredOutKey = function (key) { return "".concat(key, "-filtered-out"); };
        var calcMarkerDomain = function (data, sizeKey) {
            var e_1, _a;
            var _b;
            var markerDomain = [Infinity, -Infinity];
            if (sizeKey != null) {
                try {
                    for (var data_1 = __values$5(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                        var datum = data_1_1.value;
                        var value = (_b = datum[sizeKey]) !== null && _b !== void 0 ? _b : datum[filteredOutKey(sizeKey)];
                        if (value < markerDomain[0]) {
                            markerDomain[0] = value;
                        }
                        if (value > markerDomain[1]) {
                            markerDomain[1] = value;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            if (markerDomain[0] <= markerDomain[1]) {
                return markerDomain;
            }
            return undefined;
        };
        var updatePrimarySeries = function (series, idx) {
            var fill = palette === null || palette === void 0 ? void 0 : palette.fills[idx];
            var stroke = palette === null || palette === void 0 ? void 0 : palette.strokes[idx];
            var markerDomain = undefined;
            if (series.type === 'bubble') {
                var sizeKey = series.sizeKey;
                markerDomain = calcMarkerDomain(data, sizeKey);
            }
            var marker = __assign$g(__assign$g({}, series.marker), { fill: fill, stroke: stroke, domain: markerDomain });
            return __assign$g(__assign$g({}, series), { marker: marker, highlightStyle: { item: { fill: 'yellow' } }, listeners: __assign$g(__assign$g({}, series.listeners), { nodeClick: _this.crossFilterCallback }) });
        };
        var updateFilteredOutSeries = function (series) {
            var yKey = series.yKey, xKey = series.xKey;
            var alteredSizeKey = {};
            if (series.type === 'bubble') {
                alteredSizeKey = { sizeKey: filteredOutKey(series.sizeKey) };
            }
            return __assign$g(__assign$g(__assign$g({}, series), alteredSizeKey), { yKey: filteredOutKey(yKey), xKey: filteredOutKey(xKey), marker: __assign$g(__assign$g({}, series.marker), { fillOpacity: 0.3, strokeOpacity: 0.3 }), showInLegend: false, listeners: __assign$g(__assign$g({}, series.listeners), { nodeClick: function (e) {
                        var _a;
                        var value = e.datum[filteredOutKey(xKey)];
                        // Need to remove the `-filtered-out` suffixes from the event so that
                        // upstream processing maps the event correctly onto grid column ids.
                        var filterableEvent = __assign$g(__assign$g({}, e), { xKey: xKey, datum: __assign$g(__assign$g({}, e.datum), (_a = {}, _a[xKey] = value, _a)) });
                        _this.crossFilterCallback(filterableEvent);
                    } }) });
        };
        var updatedSeries = series.map(updatePrimarySeries);
        return __spreadArray$9(__spreadArray$9([], __read$g(updatedSeries), false), __read$g(updatedSeries.map(updateFilteredOutSeries)), false);
    };
    ScatterChartProxy.prototype.getSeriesDefinitions = function (fields, paired) {
        if (fields.length < 2) {
            return [];
        }
        var isBubbleChart = this.chartType === 'bubble';
        if (paired) {
            if (isBubbleChart) {
                return fields.map(function (currentXField, i) { return i % 3 === 0 ? ({
                    xField: currentXField,
                    yField: fields[i + 1],
                    sizeField: fields[i + 2],
                }) : null; }).filter(function (x) { return x && x.yField && x.sizeField; });
            }
            return fields.map(function (currentXField, i) { return i % 2 === 0 ? ({
                xField: currentXField,
                yField: fields[i + 1],
            }) : null; }).filter(function (x) { return x && x.yField; });
        }
        var xField = fields[0];
        if (isBubbleChart) {
            return fields
                .map(function (yField, i) { return i % 2 === 1 ? ({
                xField: xField,
                yField: yField,
                sizeField: fields[i + 1],
            }) : null; })
                .filter(function (x) { return x && x.sizeField; });
        }
        return fields.filter(function (value, i) { return i > 0; }).map(function (yField) { return ({ xField: xField, yField: yField }); });
    };
    return ScatterChartProxy;
}(CartesianChartProxy));

var __extends$U = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var HistogramChartProxy = /** @class */ (function (_super) {
    __extends$U(HistogramChartProxy, _super);
    function HistogramChartProxy(params) {
        return _super.call(this, params) || this;
    }
    HistogramChartProxy.prototype.getSeries = function (params) {
        var firstField = params.fields[0]; // multiple series are not supported!
        return [
            {
                type: this.standaloneChartType,
                xKey: firstField.colId,
                xName: firstField.displayName,
                yName: this.chartProxyParams.translate("histogramFrequency"),
                areaPlot: false, // only constant width is supported via integrated charts
            }
        ];
    };
    HistogramChartProxy.prototype.getAxes = function (_params) {
        return [
            {
                type: 'number',
                position: 'bottom',
            },
            {
                type: 'number',
                position: 'left',
            },
        ];
    };
    return HistogramChartProxy;
}(CartesianChartProxy));

var __extends$T = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var ChartOptionsService = /** @class */ (function (_super) {
    __extends$T(ChartOptionsService, _super);
    function ChartOptionsService(chartController) {
        var _this = _super.call(this) || this;
        _this.chartController = chartController;
        return _this;
    }
    ChartOptionsService.prototype.getChartOption = function (expression) {
        return _.get(this.getChart(), expression, undefined);
    };
    ChartOptionsService.prototype.setChartOption = function (expression, value, isSilent) {
        var _this = this;
        var chartSeriesTypes = this.chartController.getChartSeriesTypes();
        if (this.chartController.isComboChart()) {
            chartSeriesTypes.push('common');
        }
        var chartOptions = {};
        // we need to update chart options on each series type for combo charts
        chartSeriesTypes.forEach(function (seriesType) {
            chartOptions = deepMerge$1(chartOptions, _this.createChartOptions({
                seriesType: seriesType,
                expression: expression,
                value: value
            }));
        });
        this.updateChart(chartOptions);
        if (!isSilent) {
            this.raiseChartOptionsChangedEvent();
        }
    };
    ChartOptionsService.prototype.awaitChartOptionUpdate = function (func) {
        var chart = this.chartController.getChartProxy().getChart();
        chart.waitForUpdate().then(function () { return func(); })
            .catch(function (e) { return console.error("AG Grid - chart update failed", e); });
    };
    ChartOptionsService.prototype.getAxisProperty = function (expression) {
        var _a;
        return _.get((_a = this.getChart().axes) === null || _a === void 0 ? void 0 : _a[0], expression, undefined);
    };
    ChartOptionsService.prototype.setAxisProperty = function (expression, value) {
        var _this = this;
        var _a;
        // update axis options
        var chart = this.getChart();
        var chartOptions = {};
        (_a = chart.axes) === null || _a === void 0 ? void 0 : _a.forEach(function (axis) {
            chartOptions = deepMerge$1(chartOptions, _this.getUpdateAxisOptions(axis, expression, value));
        });
        this.updateChart(chartOptions);
        this.raiseChartOptionsChangedEvent();
    };
    ChartOptionsService.prototype.getLabelRotation = function (axisType) {
        var axis = this.getAxis(axisType);
        return _.get(axis, 'label.rotation', undefined);
    };
    ChartOptionsService.prototype.setLabelRotation = function (axisType, value) {
        var chartAxis = this.getAxis(axisType);
        if (chartAxis) {
            var chartOptions = this.getUpdateAxisOptions(chartAxis, 'label.rotation', value);
            this.updateChart(chartOptions);
            this.raiseChartOptionsChangedEvent();
        }
    };
    ChartOptionsService.prototype.getSeriesOption = function (expression, seriesType, calculated) {
        // N.B. 'calculated' here refers to the fact that the property exists on the internal series object itself,
        // rather than the properties object. This is due to us needing to reach inside the chart itself to retrieve
        // the value, and will likely be cleaned up in a future release
        var series = this.getChart().series.find(function (s) { return ChartOptionsService.isMatchingSeries(seriesType, s); });
        return _.get(series, calculated ? expression : "properties.".concat(expression), undefined);
    };
    ChartOptionsService.prototype.setSeriesOption = function (expression, value, seriesType) {
        var chartOptions = this.createChartOptions({
            seriesType: seriesType,
            expression: "series.".concat(expression),
            value: value
        });
        this.updateChart(chartOptions);
        this.raiseChartOptionsChangedEvent();
    };
    ChartOptionsService.prototype.getPairedMode = function () {
        return this.chartController.getChartProxy().isPaired();
    };
    ChartOptionsService.prototype.setPairedMode = function (paired) {
        this.chartController.getChartProxy().setPaired(paired);
    };
    ChartOptionsService.prototype.getAxis = function (axisType) {
        var chart = this.getChart();
        if (!chart.axes || chart.axes.length < 1) {
            return undefined;
        }
        if (axisType === 'xAxis') {
            return (chart.axes && chart.axes[0].direction === 'x') ? chart.axes[0] : chart.axes[1];
        }
        return (chart.axes && chart.axes[1].direction === 'y') ? chart.axes[1] : chart.axes[0];
    };
    ChartOptionsService.prototype.getUpdateAxisOptions = function (chartAxis, expression, value) {
        var _this = this;
        var chartSeriesTypes = this.chartController.getChartSeriesTypes();
        if (this.chartController.isComboChart()) {
            chartSeriesTypes.push('common');
        }
        var validAxisTypes = ['number', 'category', 'time', 'grouped-category'];
        if (!validAxisTypes.includes(chartAxis.type)) {
            return {};
        }
        return chartSeriesTypes
            .map(function (seriesType) { return _this.createChartOptions({
            seriesType: seriesType,
            expression: "axes.".concat(chartAxis.type, ".").concat(expression),
            value: value,
        }); })
            .reduce(function (combinedOptions, options) { return deepMerge$1(combinedOptions, options); });
    };
    ChartOptionsService.prototype.getChartType = function () {
        return this.chartController.getChartType();
    };
    ChartOptionsService.prototype.getChart = function () {
        return this.chartController.getChartProxy().getChart();
    };
    ChartOptionsService.prototype.updateChart = function (chartOptions) {
        var chartRef = this.chartController.getChartProxy().getChartRef();
        AgCharts.updateDelta(chartRef, chartOptions);
    };
    ChartOptionsService.prototype.createChartOptions = function (_a) {
        var seriesType = _a.seriesType, expression = _a.expression, value = _a.value;
        var overrides = {};
        var chartOptions = {
            theme: {
                overrides: overrides
            }
        };
        _.set(overrides, "".concat(seriesType, ".").concat(expression), value);
        return chartOptions;
    };
    ChartOptionsService.prototype.raiseChartOptionsChangedEvent = function () {
        var chartModel = this.chartController.getChartModel();
        var event = {
            type: Events.EVENT_CHART_OPTIONS_CHANGED,
            chartId: chartModel.chartId,
            chartType: chartModel.chartType,
            chartThemeName: this.chartController.getChartThemeName(),
            chartOptions: chartModel.chartOptions
        };
        this.eventService.dispatchEvent(event);
    };
    ChartOptionsService.isMatchingSeries = function (seriesType, series) {
        return VALID_SERIES_TYPES.includes(seriesType) && series.type === seriesType;
    };
    ChartOptionsService.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
    };
    return ChartOptionsService;
}(BeanStub));

var __extends$S = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$f = (undefined && undefined.__assign) || function () {
    __assign$f = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$f.apply(this, arguments);
};
var ComboChartProxy = /** @class */ (function (_super) {
    __extends$S(ComboChartProxy, _super);
    function ComboChartProxy(params) {
        return _super.call(this, params) || this;
    }
    ComboChartProxy.prototype.getAxes = function (params) {
        var fields = params ? params.fields : [];
        var fieldsMap = new Map(fields.map(function (f) { return [f.colId, f]; }));
        var _a = this.getYKeys(fields, params.seriesChartTypes), primaryYKeys = _a.primaryYKeys, secondaryYKeys = _a.secondaryYKeys;
        var axes = [
            {
                type: this.getXAxisType(params),
                position: 'bottom',
            },
        ];
        if (primaryYKeys.length > 0) {
            axes.push({
                type: 'number',
                keys: primaryYKeys,
                position: 'left',
            });
        }
        if (secondaryYKeys.length > 0) {
            secondaryYKeys.forEach(function (secondaryYKey) {
                var field = fieldsMap.get(secondaryYKey);
                var secondaryAxisIsVisible = field && field.colId === secondaryYKey;
                if (!secondaryAxisIsVisible) {
                    return;
                }
                var secondaryAxisOptions = {
                    type: 'number',
                    keys: [secondaryYKey],
                    position: 'right',
                };
                axes.push(secondaryAxisOptions);
            });
        }
        return axes;
    };
    ComboChartProxy.prototype.getSeries = function (params) {
        var fields = params.fields, category = params.category, seriesChartTypes = params.seriesChartTypes;
        return fields.map(function (field) {
            var seriesChartType = seriesChartTypes.find(function (s) { return s.colId === field.colId; });
            if (seriesChartType) {
                var chartType = seriesChartType.chartType;
                var grouped = ['groupedColumn', 'groupedBar'].includes(chartType);
                var groupedOpts = grouped ? { grouped: true } : {};
                return __assign$f({ type: getSeriesType(chartType), xKey: category.id, yKey: field.colId, yName: field.displayName, stacked: ['stackedArea', 'stackedColumn'].includes(chartType) }, groupedOpts);
            }
        });
    };
    ComboChartProxy.prototype.getYKeys = function (fields, seriesChartTypes) {
        var primaryYKeys = [];
        var secondaryYKeys = [];
        fields.forEach(function (field) {
            var colId = field.colId;
            var seriesChartType = seriesChartTypes.find(function (s) { return s.colId === colId; });
            if (seriesChartType) {
                seriesChartType.secondaryAxis ? secondaryYKeys.push(colId) : primaryYKeys.push(colId);
            }
        });
        return { primaryYKeys: primaryYKeys, secondaryYKeys: secondaryYKeys };
    };
    return ComboChartProxy;
}(CartesianChartProxy));

var __extends$R = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$M = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$f = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var GridChartComp = /** @class */ (function (_super) {
    __extends$R(GridChartComp, _super);
    function GridChartComp(params) {
        var _this = _super.call(this, GridChartComp.TEMPLATE) || this;
        _this.params = params;
        return _this;
    }
    GridChartComp.prototype.init = function () {
        var _this = this;
        var modelParams = {
            chartId: this.params.chartId,
            pivotChart: this.params.pivotChart,
            chartType: this.params.chartType,
            chartThemeName: this.getThemeName(),
            aggFunc: this.params.aggFunc,
            cellRange: this.params.cellRange,
            suppressChartRanges: this.params.suppressChartRanges,
            unlinkChart: this.params.unlinkChart,
            crossFiltering: this.params.crossFiltering,
            seriesChartTypes: this.params.seriesChartTypes,
        };
        var isRtl = this.gridOptionsService.get('enableRtl');
        this.addCssClass(isRtl ? 'ag-rtl' : 'ag-ltr');
        // only the chart controller interacts with the chart model
        var model = this.createBean(new ChartDataModel(modelParams));
        this.chartController = this.createManagedBean(new ChartController(model));
        this.validateCustomThemes();
        // create chart before dialog to ensure dialog is correct size
        this.createChart();
        if (this.params.insideDialog) {
            this.addDialog();
        }
        this.addMenu();
        this.addTitleEditComp();
        this.addManagedListener(this.getGui(), 'focusin', this.setActiveChartCellRange.bind(this));
        this.addManagedListener(this.chartController, ChartController.EVENT_CHART_MODEL_UPDATE, this.update.bind(this));
        this.addManagedPropertyListeners(['chartThemeOverrides', 'chartThemes'], this.reactivePropertyUpdate.bind(this));
        if (this.chartMenu) {
            // chart menu may not exist, i.e. cross filtering
            this.addManagedListener(this.chartMenu, ChartMenu.EVENT_DOWNLOAD_CHART, function () { return _this.downloadChart(); });
        }
        this.update();
        this.raiseChartCreatedEvent();
    };
    GridChartComp.prototype.createChart = function () {
        var _this = this;
        // if chart already exists, destroy it and remove it from DOM
        var chartInstance = undefined;
        if (this.chartProxy) {
            chartInstance = this.chartProxy.destroy({ keepChartInstance: true });
        }
        var crossFilterCallback = function (event, reset) {
            var ctx = _this.params.crossFilteringContext;
            ctx.lastSelectedChartId = reset ? '' : _this.chartController.getChartId();
            if (reset) {
                _this.params.crossFilteringResetCallback();
            }
            _this.crossFilterService.filter(event, reset);
        };
        var chartType = this.chartController.getChartType();
        var chartProxyParams = {
            chartType: chartType,
            chartInstance: chartInstance,
            getChartThemeName: this.getChartThemeName.bind(this),
            getChartThemes: this.getChartThemes.bind(this),
            customChartThemes: this.gridOptionsService.get('customChartThemes'),
            getGridOptionsChartThemeOverrides: function () { return _this.getGridOptionsChartThemeOverrides(); },
            getExtraPaddingDirections: function () { var _a, _b; return (_b = (_a = _this.chartMenu) === null || _a === void 0 ? void 0 : _a.getExtraPaddingDirections()) !== null && _b !== void 0 ? _b : []; },
            apiChartThemeOverrides: this.params.chartThemeOverrides,
            crossFiltering: this.params.crossFiltering,
            crossFilterCallback: crossFilterCallback,
            parentElement: this.eChart,
            grouping: this.chartController.isGrouping(),
            chartThemeToRestore: this.params.chartThemeName,
            chartOptionsToRestore: this.params.chartOptionsToRestore,
            chartPaletteToRestore: this.params.chartPaletteToRestore,
            seriesChartTypes: this.chartController.getSeriesChartTypes(),
            translate: function (toTranslate, defaultText) { return _this.chartTranslationService.translate(toTranslate, defaultText); },
        };
        // ensure 'restoring' options are not reused when switching chart types
        this.params.chartOptionsToRestore = undefined;
        // set local state used to detect when chart changes
        this.chartType = chartType;
        this.chartProxy = GridChartComp.createChartProxy(chartProxyParams);
        if (!this.chartProxy) {
            console.warn('AG Grid: invalid chart type supplied: ', chartProxyParams.chartType);
            return;
        }
        var canvas = this.eChart.querySelector('canvas');
        if (canvas) {
            canvas.classList.add('ag-charts-canvas');
        }
        this.chartController.setChartProxy(this.chartProxy);
        this.chartOptionsService = this.createBean(new ChartOptionsService(this.chartController));
        this.titleEdit && this.titleEdit.refreshTitle(this.chartController, this.chartOptionsService);
    };
    GridChartComp.prototype.getChartThemeName = function () {
        return this.chartController.getChartThemeName();
    };
    GridChartComp.prototype.getChartThemes = function () {
        return this.chartController.getThemes();
    };
    GridChartComp.prototype.getGridOptionsChartThemeOverrides = function () {
        return this.gridOptionsService.get('chartThemeOverrides');
    };
    GridChartComp.createChartProxy = function (chartProxyParams) {
        switch (chartProxyParams.chartType) {
            case 'column':
            case 'bar':
            case 'groupedColumn':
            case 'stackedColumn':
            case 'normalizedColumn':
            case 'groupedBar':
            case 'stackedBar':
            case 'normalizedBar':
                return new BarChartProxy(chartProxyParams);
            case 'pie':
            case 'doughnut':
                return new PieChartProxy(chartProxyParams);
            case 'area':
            case 'stackedArea':
            case 'normalizedArea':
                return new AreaChartProxy(chartProxyParams);
            case 'line':
                return new LineChartProxy(chartProxyParams);
            case 'scatter':
            case 'bubble':
                return new ScatterChartProxy(chartProxyParams);
            case 'histogram':
                return new HistogramChartProxy(chartProxyParams);
            case 'columnLineCombo':
            case 'areaColumnCombo':
            case 'customCombo':
                return new ComboChartProxy(chartProxyParams);
            default:
                throw "AG Grid: Unable to create chart as an invalid chartType = '".concat(chartProxyParams.chartType, "' was supplied.");
        }
    };
    GridChartComp.prototype.addDialog = function () {
        var _this = this;
        var title = this.chartTranslationService.translate(this.params.pivotChart ? 'pivotChartTitle' : 'rangeChartTitle');
        var _a = this.getBestDialogSize(), width = _a.width, height = _a.height;
        this.chartDialog = new AgDialog({
            resizable: true,
            movable: true,
            maximizable: true,
            title: title,
            width: width,
            height: height,
            component: this,
            centered: true,
            closable: true
        });
        this.getContext().createBean(this.chartDialog);
        this.chartDialog.addEventListener(AgDialog.EVENT_DESTROYED, function () { return _this.destroy(); });
    };
    GridChartComp.prototype.getBestDialogSize = function () {
        var popupParent = this.popupService.getPopupParent();
        var maxWidth = _.getAbsoluteWidth(popupParent) * 0.75;
        var maxHeight = _.getAbsoluteHeight(popupParent) * 0.75;
        var ratio = 0.553;
        var chart = this.chartProxy.getChart();
        var width = this.params.insideDialog ? 850 : chart.width;
        var height = this.params.insideDialog ? 470 : chart.height;
        if (width > maxWidth || height > maxHeight) {
            width = Math.min(width, maxWidth);
            height = Math.round(width * ratio);
            if (height > maxHeight) {
                height = maxHeight;
                width = Math.min(width, Math.round(height / ratio));
            }
        }
        return { width: width, height: height };
    };
    GridChartComp.prototype.addMenu = function () {
        if (!this.params.crossFiltering) {
            this.chartMenu = this.createBean(new ChartMenu(this.eChartContainer, this.eMenuContainer, this.chartController, this.chartOptionsService));
            this.eChartContainer.appendChild(this.chartMenu.getGui());
        }
    };
    GridChartComp.prototype.addTitleEditComp = function () {
        this.titleEdit = this.createBean(new TitleEdit(this.chartMenu));
        this.eTitleEditContainer.appendChild(this.titleEdit.getGui());
        if (this.chartProxy) {
            this.titleEdit.refreshTitle(this.chartController, this.chartOptionsService);
        }
    };
    GridChartComp.prototype.update = function (params) {
        var _this = this;
        // update chart model for api.updateChart()
        if (params === null || params === void 0 ? void 0 : params.chartId) {
            var validUpdate = this.chartController.update(params);
            if (!validUpdate) {
                return; // warning already logged!
            }
        }
        var chartTypeChanged = this.chartTypeChanged(params);
        // recreate chart if chart type has changed
        if (chartTypeChanged)
            this.createChart();
        // update chart options if chart type hasn't changed or if overrides are supplied
        this.updateChart(params === null || params === void 0 ? void 0 : params.chartThemeOverrides);
        if (params === null || params === void 0 ? void 0 : params.chartId) {
            this.chartProxy.getChart().waitForUpdate().then(function () {
                _this.chartController.raiseChartApiUpdateEvent();
            });
        }
    };
    GridChartComp.prototype.updateChart = function (updatedOverrides) {
        var _this = this;
        var chartProxy = this.chartProxy;
        var selectedCols = this.chartController.getSelectedValueColState();
        var fields = selectedCols.map(function (c) { return ({ colId: c.colId, displayName: c.displayName }); });
        var data = this.chartController.getChartData();
        var chartEmpty = this.handleEmptyChart(data, fields);
        if (chartEmpty) {
            return;
        }
        var chartUpdateParams = this.chartController.getChartUpdateParams(updatedOverrides);
        chartProxy.update(chartUpdateParams);
        this.chartProxy.getChart().waitForUpdate().then(function () {
            _this.chartController.raiseChartUpdatedEvent();
        });
        this.titleEdit.refreshTitle(this.chartController, this.chartOptionsService);
    };
    GridChartComp.prototype.chartTypeChanged = function (updateParams) {
        var _a = __read$f([this.chartController.getChartType(), updateParams === null || updateParams === void 0 ? void 0 : updateParams.chartType], 2), currentType = _a[0], updatedChartType = _a[1];
        return this.chartType !== currentType || (!!updatedChartType && this.chartType !== updatedChartType);
    };
    GridChartComp.prototype.getChartModel = function () {
        return this.chartController.getChartModel();
    };
    GridChartComp.prototype.getChartImageDataURL = function (fileFormat) {
        return this.chartProxy.getChartImageDataURL(fileFormat);
    };
    GridChartComp.prototype.handleEmptyChart = function (data, fields) {
        var pivotModeDisabled = this.chartController.isPivotChart() && !this.chartController.isPivotMode();
        var minFieldsRequired = 1;
        if (this.chartController.isActiveXYChart()) {
            minFieldsRequired = this.chartController.getChartType() === 'bubble' ? 3 : 2;
        }
        var isEmptyChart = fields.length < minFieldsRequired || data.length === 0;
        if (this.eChart) {
            var isEmpty = pivotModeDisabled || isEmptyChart;
            _.setDisplayed(this.eChart, !isEmpty);
            _.setDisplayed(this.eEmpty, isEmpty);
        }
        if (pivotModeDisabled) {
            this.eEmpty.innerText = this.chartTranslationService.translate('pivotChartRequiresPivotMode');
            return true;
        }
        if (isEmptyChart) {
            this.eEmpty.innerText = this.chartTranslationService.translate('noDataToChart');
            return true;
        }
        return false;
    };
    GridChartComp.prototype.downloadChart = function (dimensions, fileName, fileFormat) {
        this.chartProxy.downloadChart(dimensions, fileName, fileFormat);
    };
    GridChartComp.prototype.openChartToolPanel = function (panel) {
        var menuPanel = panel ? CHART_TOOL_PANEL_MENU_OPTIONS[panel] : panel;
        this.chartMenu.showMenu(menuPanel);
    };
    GridChartComp.prototype.closeChartToolPanel = function () {
        this.chartMenu.hideMenu();
    };
    GridChartComp.prototype.getChartId = function () {
        return this.chartController.getChartId();
    };
    GridChartComp.prototype.getUnderlyingChart = function () {
        return this.chartProxy.getChartRef();
    };
    GridChartComp.prototype.crossFilteringReset = function () {
        this.chartProxy.crossFilteringReset();
    };
    GridChartComp.prototype.setActiveChartCellRange = function (focusEvent) {
        if (this.getGui().contains(focusEvent.relatedTarget)) {
            return;
        }
        this.chartController.setChartRange(true);
        this.gridApi.focusService.clearFocusedCell();
    };
    GridChartComp.prototype.getThemeName = function () {
        var availableChartThemes = this.gridOptionsService.get('chartThemes') || DEFAULT_THEMES;
        if (availableChartThemes.length === 0) {
            throw new Error('Cannot create chart: no chart themes available.');
        }
        var chartThemeName = this.params.chartThemeName;
        return _.includes(availableChartThemes, chartThemeName) ? chartThemeName : availableChartThemes[0];
    };
    GridChartComp.prototype.validateCustomThemes = function () {
        var suppliedThemes = this.getChartThemes();
        var customChartThemes = this.gridOptionsService.get('customChartThemes');
        if (customChartThemes) {
            _.getAllKeysInObjects([customChartThemes]).forEach(function (customThemeName) {
                if (!_.includes(suppliedThemes, customThemeName)) {
                    console.warn("AG Grid: a custom chart theme with the name '" + customThemeName + "' has been " +
                        "supplied but not added to the 'chartThemes' list");
                }
            });
        }
    };
    GridChartComp.prototype.reactivePropertyUpdate = function () {
        // switch to the first theme if the current theme is unavailable
        this.chartController.setChartThemeName(this.getThemeName(), true);
        var chartId = this.getChartId();
        var modelType = this.chartController.isCrossFilterChart()
            ? 'crossFilter'
            : this.getChartModel().modelType;
        // standalone requires that `undefined` / `null` values are supplied as `{}`
        var chartThemeOverrides = this.gridOptionsService.get('chartThemeOverrides') || {};
        this.update({
            type: "".concat(modelType, "ChartUpdate"),
            chartId: chartId,
            chartThemeOverrides: chartThemeOverrides
        });
    };
    GridChartComp.prototype.raiseChartCreatedEvent = function () {
        var _this = this;
        var event = {
            type: Events.EVENT_CHART_CREATED,
            chartId: this.chartController.getChartId()
        };
        this.chartProxy.getChart().waitForUpdate().then(function () {
            _this.eventService.dispatchEvent(event);
        });
    };
    GridChartComp.prototype.raiseChartDestroyedEvent = function () {
        var event = {
            type: Events.EVENT_CHART_DESTROYED,
            chartId: this.chartController.getChartId(),
        };
        this.eventService.dispatchEvent(event);
    };
    GridChartComp.prototype.destroy = function () {
        var _a;
        _super.prototype.destroy.call(this);
        if (this.chartProxy) {
            this.chartProxy.destroy();
        }
        this.destroyBean(this.chartMenu);
        this.destroyBean(this.titleEdit);
        // don't want to invoke destroy() on the Dialog (prevents destroy loop)
        if (this.chartDialog && this.chartDialog.isAlive()) {
            this.destroyBean(this.chartDialog);
        }
        (_a = this.onDestroyColorSchemeChangeListener) === null || _a === void 0 ? void 0 : _a.call(this);
        // if the user is providing containers for the charts, we need to clean up, otherwise the old chart
        // data will still be visible although the chart is no longer bound to the grid
        var eGui = this.getGui();
        _.clearElement(eGui);
        // remove from parent, so if user provided container, we detach from the provided dom element
        _.removeFromParent(eGui);
        this.raiseChartDestroyedEvent();
    };
    GridChartComp.TEMPLATE = "<div class=\"ag-chart\" tabindex=\"-1\">\n            <div ref=\"eChartContainer\" tabindex=\"-1\" class=\"ag-chart-components-wrapper\">\n                <div ref=\"eChart\" class=\"ag-chart-canvas-wrapper\"></div>\n                <div ref=\"eEmpty\" class=\"ag-chart-empty-text ag-unselectable\"></div>\n            </div>\n            <div ref=\"eTitleEditContainer\"></div>\n            <div ref=\"eMenuContainer\" class=\"ag-chart-docked-container\" style=\"min-width: 0px;\"></div>\n        </div>";
    __decorate$M([
        RefSelector('eChart')
    ], GridChartComp.prototype, "eChart", void 0);
    __decorate$M([
        RefSelector('eChartContainer')
    ], GridChartComp.prototype, "eChartContainer", void 0);
    __decorate$M([
        RefSelector('eMenuContainer')
    ], GridChartComp.prototype, "eMenuContainer", void 0);
    __decorate$M([
        RefSelector('eEmpty')
    ], GridChartComp.prototype, "eEmpty", void 0);
    __decorate$M([
        RefSelector('eTitleEditContainer')
    ], GridChartComp.prototype, "eTitleEditContainer", void 0);
    __decorate$M([
        Autowired('chartCrossFilterService')
    ], GridChartComp.prototype, "crossFilterService", void 0);
    __decorate$M([
        Autowired('chartTranslationService')
    ], GridChartComp.prototype, "chartTranslationService", void 0);
    __decorate$M([
        Autowired('gridApi')
    ], GridChartComp.prototype, "gridApi", void 0);
    __decorate$M([
        Autowired('popupService')
    ], GridChartComp.prototype, "popupService", void 0);
    __decorate$M([
        PostConstruct
    ], GridChartComp.prototype, "init", null);
    return GridChartComp;
}(Component));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$a = '31.0.2';

var __assign$e = (undefined && undefined.__assign) || function () {
    __assign$e = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$e.apply(this, arguments);
};
var __rest$2 = (undefined && undefined.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read$e = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values$4 = (undefined && undefined.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
function upgradeChartModel(model) {
    model.version;
    if (model.version == null) {
        // Try to guess the version so we can apply the right subset of migrations.
        model.version = heuristicVersionDetection(model);
    }
    model = migrateIfBefore('23.0.0', model, migrateV23);
    model = migrateIfBefore('24.0.0', model, migrateV24);
    model = migrateIfBefore('25.1.0', model, migrateV25_1);
    model = migrateIfBefore('26.0.0', model, migrateV26);
    model = migrateIfBefore('26.1.0', model, migrateV26_1);
    // Switch from iChartOptions to iAgChartOptions....
    model = migrateIfBefore('26.2.0', model, migrateV26_2);
    model = migrateIfBefore('28.0.0', model, migrateV28);
    model = migrateIfBefore('28.2.0', model, migrateV28_2);
    model = migrateIfBefore('29.0.0', model, migrateV29);
    model = migrateIfBefore('29.1.0', model, migrateV29_1);
    model = migrateIfBefore('29.2.0', model, migrateV29_2);
    model = migrateIfBefore('30.0.0', model, migrateV30);
    model = migrateIfBefore('31.0.0', model, migrateV31);
    model = cleanup(model);
    // Bump version to latest.
    model = migrateIfBefore(VERSION$a, model, function (m) { return m; });
    return model;
}
function migrateV23(model) {
    // https://github.com/ag-grid/ag-grid/commit/76c6744ff2b732d298d1ade73c122188854b5bac
    model = jsonRename('chartOptions.legend.item.marker.type', 'shape', model);
    model = jsonRename('chartOptions.seriesDefaults.marker.type', 'shape', model);
    // https://github.com/ag-grid/ag-grid/commit/7bdf2cfd666acda758a818733a9f9cb35ac1d7a7
    model = jsonRename('chartOptions.legend.padding', 'spacing', model);
    return model;
}
function migrateV24(model) {
    var _a;
    // https://github.com/ag-grid/ag-grid/commit/f4e854e3dc459400fa00e6da2873cb8e9cfff6fe#
    model = jsonDelete('chartOptions.seriesDefaults.marker.minSize', model);
    var _b = model, chartType = _b.chartType, chartPalette = _b.chartPalette, // Migrate.
    _c = _b.chartOptions, xAxis = _c.xAxis, yAxis = _c.yAxis, chartOptions = __rest$2(_c, ["xAxis", "yAxis"]), chartModel = __rest$2(_b, ["chartType", "chartPalette", "chartOptions"]);
    var axesTypes = getLegacyAxisType(chartType);
    var axes = axesTypes === null || axesTypes === void 0 ? void 0 : axesTypes.map(function (type, i) { return (__assign$e({ type: type }, (i === 0 ? xAxis : yAxis))); });
    // Precise legacy palette fills/strokes can be found here for future reference:
    // https://github.com/ag-grid/ag-grid/blob/b22.1.0/grid-enterprise-modules/charts/src/charts/chart/palettes.ts
    var LEGACY_PALETTES = {
        borneo: 'ag-default',
        material: 'ag-material',
        bright: 'ag-vivid',
    };
    return __assign$e({ chartType: chartType, chartThemeName: (_a = LEGACY_PALETTES[chartPalette]) !== null && _a !== void 0 ? _a : 'ag-default', chartOptions: __assign$e(__assign$e({}, chartOptions), { axes: axes, xAxis: xAxis, yAxis: yAxis }) }, chartModel);
}
function migrateV25_1(model) {
    // https://github.com/ag-grid/ag-grid/commit/61943f9fecbfb5ac1b9a1fd93788f9fdd8687181
    model = jsonRename('chartOptions.seriesDefaults.label.minRequiredAngle', 'minAngle', model);
    return model;
}
function migrateV26(model) {
    // https://github.com/ag-grid/ag-grid/commit/df2445d88e64cb4e831d6163104a0fa60ccde3b5
    var highlightOptUpdate = function (_a) {
        var dimOpacity = _a.dimOpacity, opts = __rest$2(_a, ["dimOpacity"]);
        return (__assign$e(__assign$e({}, opts), (dimOpacity != null ? { series: { dimOpacity: dimOpacity } } : {})));
    };
    model = jsonMutate('chartOptions.seriesDefaults.highlightStyle', model, highlightOptUpdate);
    // https://github.com/ag-grid/ag-grid/commit/f4e854e3dc459400fa00e6da2873cb8e9cfff6fe#
    model = jsonDelete('chart', model);
    model = jsonDelete('chartOptions.seriesDefaults.tooltipClass', model);
    model = jsonDelete('chartOptions.seriesDefaults.tooltipTracking', model);
    // Cleanup label.rotation === 0, which was treated as 'use the default' on reload prior to 26.
    model = jsonDeleteDefault('chartOptions.axes[].label.rotation', 0, model);
    model = jsonDeleteDefault('chartOptions.axes[].label.rotation', 335, model);
    return model;
}
function migrateV26_1(model) {
    // https://github.com/ag-grid/ag-grid/commit/df2445d88e64cb4e831d6163104a0fa60ccde3b5
    var highlightOptUpdate = function (_a) {
        var item = _a.item, series = _a.series, opts = __rest$2(_a, ["item", "series"]);
        return (__assign$e({ item: __assign$e(__assign$e({}, opts), item) }, (series ? { series: series } : {})));
    };
    model = jsonMutate('chartOptions.seriesDefaults.highlightStyle', model, highlightOptUpdate);
    model = jsonMutate('chartOptions.series[].highlightStyle', model, highlightOptUpdate);
    return model;
}
function migrateV26_2(model) {
    // https://github.com/ag-grid/ag-grid/commit/8b2e223cb1a687cb6c1d70b9f75f52fa29d00341
    model = jsonMove('chartOptions.seriesDefaults.fill.opacity', 'chartOptions.seriesDefaults.fillOpacity', model);
    model = jsonMove('chartOptions.seriesDefaults.stroke.opacity', 'chartOptions.seriesDefaults.strokeOpacity', model);
    model = jsonMove('chartOptions.seriesDefaults.stroke.width', 'chartOptions.seriesDefaults.strokeWidth', model);
    model = jsonDelete('chartOptions.seriesDefaults.fill', model);
    model = jsonDelete('chartOptions.seriesDefaults.stroke', model);
    model = jsonDelete('chartOptions.seriesDefaults.callout.colors', model);
    model = jsonDelete('chartOptions.xAxis', model);
    model = jsonDelete('chartOptions.yAxis', model);
    var _a = model, chartType = _a.chartType, _b = _a.chartOptions, axes = _b.axes; _b.series; var seriesDefaults = _b.seriesDefaults, otherChartOptions = __rest$2(_b, ["axes", "series", "seriesDefaults"]), otherModelProps = __rest$2(_a, ["chartType", "chartOptions"]);
    // At 26.2.0 combination charts weren't supported, so we can safely assume a single series type.
    // We can't rely on the `series.type` field as it was incorrect (in v25.0.0 line chart has an
    // `area` series).
    var seriesTypes = [getSeriesType(chartType)];
    var chartTypeMixin = {};
    if (!seriesTypes.includes('pie')) {
        var minimalAxis_1 = { top: {}, bottom: {}, left: {}, right: {} };
        var updatedAxes_1 = axes
            .map(function (_a) {
            var _b;
            var type = _a.type, axisProps = __rest$2(_a, ["type"]);
            return (_b = {},
                _b[type] = __assign$e(__assign$e({}, minimalAxis_1), axisProps),
                _b);
        })
            .reduce(merge, {});
        ALL_AXIS_TYPES.filter(function (v) { return updatedAxes_1[v] == null; }).forEach(function (v) {
            updatedAxes_1[v] = __assign$e({}, minimalAxis_1);
        });
        chartTypeMixin.axes = updatedAxes_1;
    }
    var updatedChartOptions = seriesTypes
        .map(function (t) {
        var _a;
        return (_a = {},
            _a[t] = __assign$e(__assign$e(__assign$e({}, chartTypeMixin), { series: seriesDefaults }), otherChartOptions),
            _a);
    })
        .reduce(merge, {});
    model = __assign$e(__assign$e({}, otherModelProps), { chartType: chartType, chartOptions: updatedChartOptions });
    return model;
}
function migrateV28(model) {
    model = jsonDelete('chartOptions.*.title.padding', model);
    model = jsonDelete('chartOptions.*.subtitle.padding', model);
    model = jsonDelete('chartOptions.*.axes.*.title.padding', model);
    model = jsonBackfill('chartOptions.*.axes.*.title.enabled', false, model);
    return model;
}
function migrateV28_2(model) {
    model = jsonRename('chartOptions.pie.series.callout', 'calloutLine', model);
    model = jsonRename('chartOptions.pie.series.label', 'calloutLabel', model);
    model = jsonRename('chartOptions.pie.series.labelKey', 'sectorLabelKey', model);
    model = jsonRename('chartOptions.pie.series.labelName', 'sectorLabelName', model);
    // series.yKeys => yKey ?
    // series.yNames => yName ?
    return model;
}
function migrateV29(model) {
    model = jsonMoveIfMissing('chartOptions.scatter.series.fill', 'chartOptions.scatter.series.marker.fill', model);
    model = jsonMoveIfMissing('chartOptions.scatter.series.fillOpacity', 'chartOptions.scatter.series.marker.fillOpacity', model);
    model = jsonMoveIfMissing('chartOptions.scatter.series.stroke', 'chartOptions.scatter.series.marker.stroke', model);
    model = jsonMoveIfMissing('chartOptions.scatter.series.strokeOpacity', 'chartOptions.scatter.series.marker.strokeOpacity', model);
    model = jsonMoveIfMissing('chartOptions.scatter.series.strokeWidth', 'chartOptions.scatter.series.marker.strokeWidth', model);
    model = jsonMove('chartOptions.scatter.series.paired', 'chartOptions.scatter.paired', model);
    return model;
}
function migrateV29_1(model) {
    model = jsonDelete('chartOptions.axes[].tick.count', model);
    return model;
}
function migrateV29_2(model) {
    // https://github.com/ag-grid/ag-grid/commit/ce11956492e42e845932edb4e05d7b0b21db5c61
    var tooltipOptUpdate = function (_a) {
        var _b, _c, _d, _e;
        var tracking = _a.tracking, opts = __rest$2(_a, ["tracking"]);
        var output = __assign$e({}, opts);
        if (tracking === false) {
            (_b = output.position) !== null && _b !== void 0 ? _b : (output.position = { type: 'pointer' });
            (_c = output.range) !== null && _c !== void 0 ? _c : (output.range = 'nearest');
        }
        else if (tracking === true) {
            (_d = output.position) !== null && _d !== void 0 ? _d : (output.position = { type: 'node' });
            (_e = output.range) !== null && _e !== void 0 ? _e : (output.range = 'nearest');
        }
        return output;
    };
    model = jsonMutate('chartOptions.*.tooltip', model, tooltipOptUpdate);
    return model;
}
function migrateV30(model) {
    // Repeated from migrateV28_2() as they were applied retrospectively for the v30 release.
    model = jsonRename('chartOptions.pie.series.labelKey', 'sectorLabelKey', model);
    model = jsonRename('chartOptions.pie.series.labelName', 'sectorLabelName', model);
    // Late-applied migrations for deprecations in the 29.x.y range.
    model = migrateV29_1(model);
    model = migrateV29_2(model);
    // Actual v30 changes.
    model = jsonDelete('chartOptions.*.series.flipXY', model);
    model = jsonAdd('chartOptions.common.legend.enabled', true, model);
    model = jsonBackfill('chartOptions.common.legend.position', 'right', model);
    return model;
}
function migrateV31(model) {
    var V30_LEGACY_PALETTES = {
        'ag-pastel': 'ag-sheets',
        'ag-solar': 'ag-polychroma'
    };
    var updatedModel = jsonRename('chartOptions.column', 'bar', model);
    var chartThemeName = V30_LEGACY_PALETTES[updatedModel.chartThemeName] || updatedModel.chartThemeName;
    return __assign$e(__assign$e({}, updatedModel), { chartThemeName: chartThemeName });
}
function cleanup(model) {
    // Remove fixed width/height - this has never been supported via UI configuration.
    model = jsonDelete('chartOptions.*.width', model);
    model = jsonDelete('chartOptions.*.height', model);
    model = jsonBackfill('chartOptions.*.axes.category.label.autoRotate', true, model);
    return model;
}
function heuristicVersionDetection(model) {
    var _a, _b;
    var modelAny = model;
    if (model.version != null) {
        return model.version;
    }
    var hasKey = function (obj) {
        var keys = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            keys[_i - 1] = arguments[_i];
        }
        return Object.keys(obj || {}).some(function (k) { return keys.includes(k); });
    };
    var chartOptions = modelAny.chartOptions;
    var seriesOptions = hasKey(chartOptions, 'seriesDefaults')
        ? chartOptions === null || chartOptions === void 0 ? void 0 : chartOptions.seriesDefaults
        : chartOptions === null || chartOptions === void 0 ? void 0 : chartOptions[Object.keys(chartOptions)[0]];
    var hints = {
        '27.0.0': hasKey(modelAny, 'seriesChartTypes'),
        '26.2.0': !hasKey(chartOptions, 'seriesDefaults'),
        '26.1.0': hasKey(seriesOptions === null || seriesOptions === void 0 ? void 0 : seriesOptions.highlightStyle, 'item'),
        '26.0.0': hasKey(seriesOptions === null || seriesOptions === void 0 ? void 0 : seriesOptions.highlightStyle, 'series'),
        // '26.0.0': modelAny.chart === undefined,
        '25.1.0': hasKey(seriesOptions === null || seriesOptions === void 0 ? void 0 : seriesOptions.label, 'minAngle'),
        '25.0.0': hasKey(modelAny, 'modelType', 'aggFunc', 'unlinkChart', 'suppressChartRanges') ||
            hasKey(seriesOptions, 'lineDash', 'lineDashOffset'),
        '24.0.0': hasKey(modelAny, 'chartThemeName', 'chart') || hasKey(chartOptions, 'series'),
        '23.2.0': hasKey(chartOptions, 'navigator'),
        '23.0.0': hasKey((_b = (_a = chartOptions === null || chartOptions === void 0 ? void 0 : chartOptions.legend) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b.marker, 'shape'),
        '22.1.0': hasKey(modelAny, 'chartPalette', 'chartType'),
    };
    // Default to 27.1.0, the last version before we added `version`.
    var defaultVersion = '27.1.0';
    var matchingHints = Object.entries(hints).filter(function (_a) {
        var _b = __read$e(_a, 2); _b[0]; var match = _b[1];
        return match;
    });
    var _c = __read$e(matchingHints[0], 1), _d = _c[0], heuristicVersion = _d === void 0 ? defaultVersion : _d;
    return heuristicVersion;
}
function migrateIfBefore(maxVersion, model, migration) {
    if (versionNumber(maxVersion) > versionNumber(model.version)) {
        var result = migration(model);
        result.version = maxVersion;
        return result;
    }
    return model;
}
function versionParts(version) {
    var split = typeof version === 'string' ? version.split('.').map(function (v) { return Number(v); }) : [];
    if (split.length !== 3 || split.some(function (v) { return isNaN(v); })) {
        throw new Error('AG Grid - Illegal version string: ' + version);
    }
    return {
        major: split[0],
        minor: split[1],
        patch: split[2],
    };
}
function versionNumber(version) {
    var _a = versionParts(version), major = _a.major, minor = _a.minor, patch = _a.patch;
    // Return a number of the form MMmmPP.
    return major * 10000 + minor * 100 + patch;
}
function jsonDeleteDefault(path, defaultValue, json) {
    return jsonMutateProperty(path, true, json, function (parent, prop) {
        if (parent[prop] === defaultValue) {
            delete parent[prop];
        }
    });
}
function jsonBackfill(path, defaultValue, json) {
    return jsonMutateProperty(path, false, json, function (parent, prop) {
        if (parent[prop] == null) {
            parent[prop] = defaultValue;
        }
    });
}
function jsonAdd(path, value, json) {
    var _a;
    if (typeof path === 'string') {
        path = path.split('.');
    }
    var nextPath = path[0];
    if (path.length > 1) {
        json[nextPath] = jsonAdd(path.slice(1), value, (_a = json[nextPath]) !== null && _a !== void 0 ? _a : {});
    }
    var hasProperty = Object.keys(json).includes(nextPath);
    if (!hasProperty) {
        json[nextPath] = value;
    }
    return json;
}
function jsonMove(from, to, json) {
    var valueToMove = undefined;
    var valueFound = false;
    json = jsonMutateProperty(from, true, json, function (parent, prop) {
        valueFound = true;
        valueToMove = parent[prop];
        delete parent[prop];
    });
    if (!valueFound) {
        return json;
    }
    return jsonMutateProperty(to, false, json, function (parent, prop) {
        parent[prop] = valueToMove;
    });
}
function jsonMoveIfMissing(from, to, json) {
    var valueToMove = undefined;
    var valueFound = false;
    json = jsonMutateProperty(from, true, json, function (parent, prop) {
        valueFound = true;
        valueToMove = parent[prop];
        delete parent[prop];
    });
    if (!valueFound) {
        return json;
    }
    return jsonMutateProperty(to, false, json, function (parent, prop) {
        if (parent[prop] === undefined) {
            parent[prop] = valueToMove;
        }
    });
}
function jsonRename(path, renameTo, json) {
    return jsonMutateProperty(path, true, json, function (parent, prop) {
        parent[renameTo] = parent[prop];
        delete parent[prop];
    });
}
function jsonDelete(path, json) {
    return jsonMutateProperty(path, true, json, function (parent, prop) { return delete parent[prop]; });
}
function jsonMutateProperty(path, skipMissing, json, mutator) {
    var pathElements = path instanceof Array ? path : path.split('.');
    var parentPathElements = pathElements.slice(0, pathElements.length - 1);
    var targetName = pathElements[pathElements.length - 1];
    return jsonMutate(parentPathElements, json, function (parent) {
        var hasProperty = Object.keys(parent).includes(targetName);
        if (skipMissing && !hasProperty) {
            return parent;
        }
        var result = __assign$e({}, parent);
        mutator(result, targetName);
        return result;
    });
}
function jsonMutate(path, json, mutator) {
    var e_1, _a;
    var pathElements = path instanceof Array ? path : path.split('.');
    // Clone to avoid mutating original input.
    json = __assign$e({}, json);
    if (pathElements.length === 0) {
        return mutator(json);
    }
    else if (pathElements[0].startsWith('{')) {
        var pathOptions = pathElements[0].substring(1, pathElements[0].lastIndexOf('}')).split(',');
        try {
            for (var pathOptions_1 = __values$4(pathOptions), pathOptions_1_1 = pathOptions_1.next(); !pathOptions_1_1.done; pathOptions_1_1 = pathOptions_1.next()) {
                var pathOption = pathOptions_1_1.value;
                if (json[pathOption] != null) {
                    json[pathOption] = jsonMutate(pathElements.slice(1), json[pathOption], mutator);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (pathOptions_1_1 && !pathOptions_1_1.done && (_a = pathOptions_1.return)) _a.call(pathOptions_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    else if (pathElements[0].endsWith('[]')) {
        var arrayName = pathElements[0].substring(0, path[0].indexOf('['));
        if (json[arrayName] instanceof Array) {
            json[arrayName] = json[arrayName].map(function (v) { return jsonMutate(pathElements.slice(1), v, mutator); });
        }
    }
    else if (pathElements[0] === '*') {
        for (var jsonProp in json) {
            json[jsonProp] = jsonMutate(pathElements.slice(1), json[jsonProp], mutator);
        }
    }
    else if (json[pathElements[0]] != null) {
        json[pathElements[0]] = jsonMutate(pathElements.slice(1), json[pathElements[0]], mutator);
    }
    return json;
}
var merge = function (r, n) { return (__assign$e(__assign$e({}, r), n)); };

var __extends$Q = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$d = (undefined && undefined.__assign) || function () {
    __assign$d = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$d.apply(this, arguments);
};
var __decorate$L = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$d = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$8 = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var ChartService = /** @class */ (function (_super) {
    __extends$Q(ChartService, _super);
    function ChartService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // we destroy all charts bound to this grid when grid is destroyed. activeCharts contains all charts, including
        // those in developer provided containers.
        _this.activeCharts = new Set();
        _this.activeChartComps = new Set();
        // this shared (singleton) context is used by cross filtering in line and area charts
        _this.crossFilteringContext = {
            lastSelectedChartId: '',
        };
        return _this;
    }
    ChartService.prototype.updateChart = function (params) {
        if (this.activeChartComps.size === 0) {
            console.warn("AG Grid - No active charts to update.");
            return;
        }
        var chartComp = __spreadArray$8([], __read$d(this.activeChartComps), false).find(function (chartComp) { return chartComp.getChartId() === params.chartId; });
        if (!chartComp) {
            console.warn("AG Grid - Unable to update chart. No active chart found with ID: ".concat(params.chartId, "."));
            return;
        }
        chartComp.update(params);
    };
    ChartService.prototype.getChartModels = function () {
        var models = [];
        var versionedModel = function (c) {
            return __assign$d(__assign$d({}, c), { version: VERSION$a });
        };
        this.activeChartComps.forEach(function (c) { return models.push(versionedModel(c.getChartModel())); });
        return models;
    };
    ChartService.prototype.getChartRef = function (chartId) {
        var chartRef;
        this.activeCharts.forEach(function (cr) {
            if (cr.chartId === chartId) {
                chartRef = cr;
            }
        });
        return chartRef;
    };
    ChartService.prototype.getChartComp = function (chartId) {
        var chartComp;
        this.activeChartComps.forEach(function (comp) {
            if (comp.getChartId() === chartId) {
                chartComp = comp;
            }
        });
        return chartComp;
    };
    ChartService.prototype.getChartImageDataURL = function (params) {
        var url;
        this.activeChartComps.forEach(function (c) {
            if (c.getChartId() === params.chartId) {
                url = c.getChartImageDataURL(params.fileFormat);
            }
        });
        return url;
    };
    ChartService.prototype.downloadChart = function (params) {
        var chartComp = Array.from(this.activeChartComps).find(function (c) { return c.getChartId() === params.chartId; });
        chartComp === null || chartComp === void 0 ? void 0 : chartComp.downloadChart(params.dimensions, params.fileName, params.fileFormat);
    };
    ChartService.prototype.openChartToolPanel = function (params) {
        var chartComp = Array.from(this.activeChartComps).find(function (c) { return c.getChartId() === params.chartId; });
        chartComp === null || chartComp === void 0 ? void 0 : chartComp.openChartToolPanel(params.panel);
    };
    ChartService.prototype.closeChartToolPanel = function (chartId) {
        var chartComp = Array.from(this.activeChartComps).find(function (c) { return c.getChartId() === chartId; });
        chartComp === null || chartComp === void 0 ? void 0 : chartComp.closeChartToolPanel();
    };
    ChartService.prototype.createChartFromCurrentRange = function (chartType) {
        if (chartType === void 0) { chartType = 'groupedColumn'; }
        var selectedRange = this.getSelectedRange();
        return this.createChart(selectedRange, chartType);
    };
    ChartService.prototype.restoreChart = function (model, chartContainer) {
        var _this = this;
        if (!model) {
            console.warn("AG Grid - unable to restore chart as no chart model is provided");
            return;
        }
        if (model.version !== VERSION$a) {
            model = upgradeChartModel(model);
        }
        var params = {
            cellRange: model.cellRange,
            chartType: model.chartType,
            chartThemeName: model.chartThemeName,
            chartContainer: chartContainer,
            suppressChartRanges: model.suppressChartRanges,
            aggFunc: model.aggFunc,
            unlinkChart: model.unlinkChart,
            seriesChartTypes: model.seriesChartTypes
        };
        var getCellRange = function (cellRangeParams) {
            return _this.rangeService
                ? _this.rangeService.createCellRangeFromCellRangeParams(cellRangeParams)
                : undefined;
        };
        if (model.modelType === 'pivot') {
            // if required enter pivot mode
            this.gridOptionsService.updateGridOptions({ options: { pivotMode: true }, source: 'pivotChart' });
            // pivot chart range contains all visible column without a row range to include all rows
            var columns = this.columnModel.getAllDisplayedColumns().map(function (col) { return col.getColId(); });
            var chartAllRangeParams = {
                rowStartIndex: null,
                rowStartPinned: undefined,
                rowEndIndex: null,
                rowEndPinned: undefined,
                columns: columns
            };
            var cellRange_1 = getCellRange(chartAllRangeParams);
            if (!cellRange_1) {
                console.warn("AG Grid - unable to create chart as there are no columns in the grid.");
                return;
            }
            return this.createChart(cellRange_1, params.chartType, params.chartThemeName, true, true, params.chartContainer, undefined, undefined, params.unlinkChart, false, model.chartOptions);
        }
        var cellRange = getCellRange(params.cellRange);
        if (!cellRange) {
            console.warn("AG Grid - unable to create chart as no range is selected");
            return;
        }
        return this.createChart(cellRange, params.chartType, params.chartThemeName, false, params.suppressChartRanges, params.chartContainer, params.aggFunc, undefined, params.unlinkChart, false, model.chartOptions, model.chartPalette, params.seriesChartTypes);
    };
    ChartService.prototype.createRangeChart = function (params) {
        var _a;
        var cellRange = (_a = this.rangeService) === null || _a === void 0 ? void 0 : _a.createCellRangeFromCellRangeParams(params.cellRange);
        if (!cellRange) {
            console.warn("AG Grid - unable to create chart as no range is selected");
            return;
        }
        return this.createChart(cellRange, params.chartType, params.chartThemeName, false, params.suppressChartRanges, params.chartContainer, params.aggFunc, params.chartThemeOverrides, params.unlinkChart, undefined, undefined, undefined, params.seriesChartTypes);
    };
    ChartService.prototype.createPivotChart = function (params) {
        // if required enter pivot mode
        this.gridOptionsService.updateGridOptions({ options: { pivotMode: true }, source: 'pivotChart' });
        // pivot chart range contains all visible column without a row range to include all rows
        var chartAllRangeParams = {
            rowStartIndex: null,
            rowStartPinned: undefined,
            rowEndIndex: null,
            rowEndPinned: undefined,
            columns: this.columnModel.getAllDisplayedColumns().map(function (col) { return col.getColId(); })
        };
        var cellRange = this.rangeService
            ? this.rangeService.createCellRangeFromCellRangeParams(chartAllRangeParams)
            : undefined;
        if (!cellRange) {
            console.warn("AG Grid - unable to create chart as there are no columns in the grid.");
            return;
        }
        return this.createChart(cellRange, params.chartType, params.chartThemeName, true, true, params.chartContainer, undefined, params.chartThemeOverrides, params.unlinkChart);
    };
    ChartService.prototype.createCrossFilterChart = function (params) {
        var _a;
        var cellRange = (_a = this.rangeService) === null || _a === void 0 ? void 0 : _a.createCellRangeFromCellRangeParams(params.cellRange);
        if (!cellRange) {
            console.warn("AG Grid - unable to create chart as no range is selected");
            return;
        }
        var crossFiltering = true;
        var suppressChartRangesSupplied = typeof params.suppressChartRanges !== 'undefined' && params.suppressChartRanges !== null;
        var suppressChartRanges = suppressChartRangesSupplied ? params.suppressChartRanges : true;
        return this.createChart(cellRange, params.chartType, params.chartThemeName, false, suppressChartRanges, params.chartContainer, params.aggFunc, params.chartThemeOverrides, params.unlinkChart, crossFiltering);
    };
    ChartService.prototype.createChart = function (cellRange, chartType, chartThemeName, pivotChart, suppressChartRanges, container, aggFunc, chartThemeOverrides, unlinkChart, crossFiltering, chartOptionsToRestore, chartPaletteToRestore, seriesChartTypes) {
        var _this = this;
        if (pivotChart === void 0) { pivotChart = false; }
        if (suppressChartRanges === void 0) { suppressChartRanges = false; }
        if (unlinkChart === void 0) { unlinkChart = false; }
        if (crossFiltering === void 0) { crossFiltering = false; }
        var createChartContainerFunc = this.gridOptionsService.getCallback('createChartContainer');
        var params = {
            chartId: this.generateId(),
            pivotChart: pivotChart,
            cellRange: cellRange,
            chartType: chartType,
            chartThemeName: chartThemeName,
            insideDialog: !(container || createChartContainerFunc),
            suppressChartRanges: suppressChartRanges,
            aggFunc: aggFunc,
            chartThemeOverrides: chartThemeOverrides,
            unlinkChart: unlinkChart,
            crossFiltering: crossFiltering,
            crossFilteringContext: this.crossFilteringContext,
            chartOptionsToRestore: chartOptionsToRestore,
            chartPaletteToRestore: chartPaletteToRestore,
            seriesChartTypes: seriesChartTypes,
            crossFilteringResetCallback: function () { return _this.activeChartComps.forEach(function (c) { return c.crossFilteringReset(); }); }
        };
        var chartComp = new GridChartComp(params);
        this.context.createBean(chartComp);
        var chartRef = this.createChartRef(chartComp);
        if (container) {
            // if container exists, means developer initiated chart create via API, so place in provided container
            container.appendChild(chartComp.getGui());
            // if the chart container was placed outside an element that
            // has the grid's theme, we manually add the current theme to
            // make sure all styles for the chartMenu are rendered correctly
            var theme = this.environment.getTheme();
            if (theme.el && !theme.el.contains(container)) {
                container.classList.add(theme.theme);
            }
        }
        else if (createChartContainerFunc) {
            // otherwise, user created chart via grid UI, check if developer provides containers (e.g. if the application
            // is using its own dialogs rather than the grid provided dialogs)
            createChartContainerFunc(chartRef);
        }
        else {
            // add listener to remove from active charts list when charts are destroyed, e.g. closing chart dialog
            chartComp.addEventListener(GridChartComp.EVENT_DESTROYED, function () {
                _this.activeChartComps.delete(chartComp);
                _this.activeCharts.delete(chartRef);
            });
        }
        return chartRef;
    };
    ChartService.prototype.createChartRef = function (chartComp) {
        var _this = this;
        var chartRef = {
            destroyChart: function () {
                if (_this.activeCharts.has(chartRef)) {
                    _this.context.destroyBean(chartComp);
                    _this.activeChartComps.delete(chartComp);
                    _this.activeCharts.delete(chartRef);
                }
            },
            chartElement: chartComp.getGui(),
            chart: chartComp.getUnderlyingChart(),
            chartId: chartComp.getChartModel().chartId
        };
        this.activeCharts.add(chartRef);
        this.activeChartComps.add(chartComp);
        return chartRef;
    };
    ChartService.prototype.getSelectedRange = function () {
        var ranges = this.rangeService.getCellRanges();
        return ranges.length > 0 ? ranges[0] : {};
    };
    ChartService.prototype.generateId = function () {
        return "id-".concat(Math.random().toString(36).substring(2, 18));
    };
    ChartService.prototype.destroyAllActiveCharts = function () {
        this.activeCharts.forEach(function (chart) { return chart.destroyChart(); });
    };
    ChartService.CHARTS_VERSION = VERSION$b;
    __decorate$L([
        Optional('rangeService')
    ], ChartService.prototype, "rangeService", void 0);
    __decorate$L([
        Autowired('columnModel')
    ], ChartService.prototype, "columnModel", void 0);
    __decorate$L([
        PreDestroy
    ], ChartService.prototype, "destroyAllActiveCharts", null);
    ChartService = __decorate$L([
        Bean('chartService')
    ], ChartService);
    return ChartService;
}(BeanStub));

var __extends$P = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$K = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ChartTranslationService = /** @class */ (function (_super) {
    __extends$P(ChartTranslationService, _super);
    function ChartTranslationService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChartTranslationService_1 = ChartTranslationService;
    ChartTranslationService.prototype.translate = function (toTranslate, defaultText) {
        var translate = this.localeService.getLocaleTextFunc();
        var defaultTranslation = ChartTranslationService_1.DEFAULT_TRANSLATIONS[toTranslate] || defaultText;
        return translate(toTranslate, defaultTranslation);
    };
    var ChartTranslationService_1;
    ChartTranslationService.DEFAULT_TRANSLATIONS = {
        pivotChartTitle: 'Pivot Chart',
        rangeChartTitle: 'Range Chart',
        settings: 'Settings',
        data: 'Data',
        format: 'Format',
        categories: 'Categories',
        defaultCategory: '(None)',
        series: 'Series',
        xyValues: 'X Y Values',
        paired: 'Paired Mode',
        axis: 'Axis',
        navigator: 'Navigator',
        color: 'Color',
        thickness: 'Thickness',
        xType: 'X Type',
        automatic: 'Automatic',
        category: 'Category',
        number: 'Number',
        time: 'Time',
        autoRotate: 'Auto Rotate',
        xRotation: 'X Rotation',
        yRotation: 'Y Rotation',
        ticks: 'Ticks',
        width: 'Width',
        height: 'Height',
        length: 'Length',
        padding: 'Padding',
        spacing: 'Spacing',
        chart: 'Chart',
        title: 'Title',
        titlePlaceholder: 'Chart title - double click to edit',
        background: 'Background',
        font: 'Font',
        top: 'Top',
        right: 'Right',
        bottom: 'Bottom',
        left: 'Left',
        labels: 'Labels',
        calloutLabels: 'Callout Labels',
        sectorLabels: 'Sector Labels',
        positionRatio: 'Position Ratio',
        size: 'Size',
        shape: 'Shape',
        minSize: 'Minimum Size',
        maxSize: 'Maximum Size',
        legend: 'Legend',
        position: 'Position',
        markerSize: 'Marker Size',
        markerStroke: 'Marker Stroke',
        markerPadding: 'Marker Padding',
        itemSpacing: 'Item Spacing',
        itemPaddingX: 'Item Padding X',
        itemPaddingY: 'Item Padding Y',
        layoutHorizontalSpacing: 'Horizontal Spacing',
        layoutVerticalSpacing: 'Vertical Spacing',
        strokeWidth: 'Stroke Width',
        offset: 'Offset',
        offsets: 'Offsets',
        tooltips: 'Tooltips',
        callout: 'Callout',
        markers: 'Markers',
        shadow: 'Shadow',
        blur: 'Blur',
        xOffset: 'X Offset',
        yOffset: 'Y Offset',
        lineWidth: 'Line Width',
        lineDash: 'Line Dash',
        normal: 'Normal',
        bold: 'Bold',
        italic: 'Italic',
        boldItalic: 'Bold Italic',
        predefined: 'Predefined',
        fillOpacity: 'Fill Opacity',
        strokeOpacity: 'Line Opacity',
        histogramBinCount: 'Bin count',
        columnGroup: 'Column',
        barGroup: 'Bar',
        pieGroup: 'Pie',
        lineGroup: 'Line',
        scatterGroup: 'X Y (Scatter)',
        areaGroup: 'Area',
        histogramGroup: 'Histogram',
        combinationGroup: 'Combination',
        groupedColumnTooltip: 'Grouped',
        stackedColumnTooltip: 'Stacked',
        normalizedColumnTooltip: '100% Stacked',
        groupedBarTooltip: 'Grouped',
        stackedBarTooltip: 'Stacked',
        normalizedBarTooltip: '100% Stacked',
        pieTooltip: 'Pie',
        doughnutTooltip: 'Doughnut',
        lineTooltip: 'Line',
        groupedAreaTooltip: 'Area',
        stackedAreaTooltip: 'Stacked',
        normalizedAreaTooltip: '100% Stacked',
        scatterTooltip: 'Scatter',
        bubbleTooltip: 'Bubble',
        histogramTooltip: 'Histogram',
        columnLineComboTooltip: 'Column & Line',
        areaColumnComboTooltip: 'Area & Column',
        customComboTooltip: 'Custom Combination',
        noDataToChart: 'No data available to be charted.',
        pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
        chartSettingsToolbarTooltip: 'Menu',
        chartLinkToolbarTooltip: 'Linked to Grid',
        chartUnlinkToolbarTooltip: 'Unlinked from Grid',
        chartDownloadToolbarTooltip: 'Download Chart',
        histogramFrequency: "Frequency",
        seriesChartType: 'Series Chart Type',
        seriesType: 'Series Type',
        secondaryAxis: 'Secondary Axis',
    };
    ChartTranslationService = ChartTranslationService_1 = __decorate$K([
        Bean("chartTranslationService")
    ], ChartTranslationService);
    return ChartTranslationService;
}(BeanStub));

var __extends$O = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$J = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ChartCrossFilterService = /** @class */ (function (_super) {
    __extends$O(ChartCrossFilterService, _super);
    function ChartCrossFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChartCrossFilterService_1 = ChartCrossFilterService;
    ChartCrossFilterService.prototype.filter = function (event, reset) {
        if (reset === void 0) { reset = false; }
        var filterModel = this.gridApi.getFilterModel();
        // filters should be reset when user clicks on canvas background
        if (reset) {
            this.resetFilters(filterModel);
            return;
        }
        var colId = ChartCrossFilterService_1.extractFilterColId(event);
        if (this.isValidColumnFilter(colId)) {
            // update filters based on current chart selections
            this.updateFilters(filterModel, event, colId);
        }
        else {
            console.warn("AG Grid: cross filtering requires a 'agSetColumnFilter' or 'agMultiColumnFilter' " +
                "to be defined on the column with id: '" + colId + "'");
        }
    };
    ChartCrossFilterService.prototype.resetFilters = function (filterModel) {
        var filtersExist = Object.keys(filterModel).length > 0;
        if (filtersExist) {
            // only reset filters / charts when necessary to prevent undesirable flickering effect
            this.gridApi.setFilterModel(null);
            this.gridApi.onFilterChanged();
        }
    };
    ChartCrossFilterService.prototype.updateFilters = function (filterModel, event, colId) {
        var _a;
        var dataKey = ChartCrossFilterService_1.extractFilterColId(event);
        var rawValue = event.datum[dataKey];
        if (rawValue === undefined) {
            return;
        }
        var selectedValue = rawValue.toString();
        if (event.event.metaKey || event.event.ctrlKey) {
            var existingGridValues = this.getCurrentGridValuesForCategory(colId);
            var valueAlreadyExists = _.includes(existingGridValues, selectedValue);
            var updatedValues = void 0;
            if (valueAlreadyExists) {
                updatedValues = existingGridValues.filter(function (v) { return v !== selectedValue; });
            }
            else {
                updatedValues = existingGridValues;
                updatedValues.push(selectedValue);
            }
            filterModel[colId] = this.getUpdatedFilterModel(colId, updatedValues);
        }
        else {
            var updatedValues = [selectedValue];
            filterModel = (_a = {}, _a[colId] = this.getUpdatedFilterModel(colId, updatedValues), _a);
        }
        this.gridApi.setFilterModel(filterModel);
    };
    ChartCrossFilterService.prototype.getUpdatedFilterModel = function (colId, updatedValues) {
        var columnFilterType = this.getColumnFilterType(colId);
        if (columnFilterType === 'agMultiColumnFilter') {
            return { filterType: 'multi', filterModels: [null, { filterType: 'set', values: updatedValues }] };
        }
        return { filterType: 'set', values: updatedValues };
    };
    ChartCrossFilterService.prototype.getCurrentGridValuesForCategory = function (colId) {
        var _this = this;
        var filteredValues = [];
        var column = this.getColumnById(colId);
        this.gridApi.forEachNodeAfterFilter(function (rowNode) {
            if (column && !rowNode.group) {
                var value = _this.valueService.getValue(column, rowNode) + '';
                if (!filteredValues.includes(value)) {
                    filteredValues.push(value);
                }
            }
        });
        return filteredValues;
    };
    ChartCrossFilterService.extractFilterColId = function (event) {
        return event.xKey || event.calloutLabelKey;
    };
    ChartCrossFilterService.prototype.isValidColumnFilter = function (colId) {
        if (colId.indexOf('-filtered-out')) {
            colId = colId.replace('-filtered-out', '');
        }
        var filterType = this.getColumnFilterType(colId);
        if (typeof filterType === 'boolean') {
            return filterType;
        }
        return _.includes(['agSetColumnFilter', 'agMultiColumnFilter'], filterType);
    };
    ChartCrossFilterService.prototype.getColumnFilterType = function (colId) {
        var gridColumn = this.getColumnById(colId);
        if (gridColumn) {
            var colDef = gridColumn.getColDef();
            return colDef.filter;
        }
    };
    ChartCrossFilterService.prototype.getColumnById = function (colId) {
        return this.columnModel.getGridColumn(colId);
    };
    var ChartCrossFilterService_1;
    __decorate$J([
        Autowired('gridApi')
    ], ChartCrossFilterService.prototype, "gridApi", void 0);
    __decorate$J([
        Autowired('columnModel')
    ], ChartCrossFilterService.prototype, "columnModel", void 0);
    __decorate$J([
        Autowired('valueService')
    ], ChartCrossFilterService.prototype, "valueService", void 0);
    ChartCrossFilterService = ChartCrossFilterService_1 = __decorate$J([
        Bean("chartCrossFilterService")
    ], ChartCrossFilterService);
    return ChartCrossFilterService;
}(BeanStub));

var __extends$N = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$c = (undefined && undefined.__assign) || function () {
    __assign$c = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$c.apply(this, arguments);
};
var __decorate$I = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$c = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$7 = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values$3 = (undefined && undefined.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var RangeService = /** @class */ (function (_super) {
    __extends$N(RangeService, _super);
    function RangeService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cellRanges = [];
        _this.bodyScrollListener = _this.onBodyScroll.bind(_this);
        _this.dragging = false;
        _this.intersectionRange = false; // When dragging ends, the current range will be used to intersect all other ranges
        return _this;
    }
    RangeService.prototype.init = function () {
        var _this = this;
        this.addManagedListener(this.eventService, Events.EVENT_NEW_COLUMNS_LOADED, function () { return _this.onColumnsChanged(); });
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_VISIBLE, this.onColumnsChanged.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_VALUE_CHANGED, this.onColumnsChanged.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_PIVOT_MODE_CHANGED, function () { return _this.removeAllCellRanges(); });
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_ROW_GROUP_CHANGED, function () { return _this.removeAllCellRanges(); });
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_PIVOT_CHANGED, function () { return _this.removeAllCellRanges(); });
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_GROUP_OPENED, this.refreshLastRangeStart.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_MOVED, this.refreshLastRangeStart.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_PINNED, this.refreshLastRangeStart.bind(this));
        this.ctrlsService.whenReady(function () {
            var gridBodyCtrl = _this.ctrlsService.getGridBodyCtrl();
            _this.autoScrollService = new AutoScrollService({
                scrollContainer: gridBodyCtrl.getBodyViewportElement(),
                scrollAxis: 'xy',
                getVerticalPosition: function () { return gridBodyCtrl.getScrollFeature().getVScrollPosition().top; },
                setVerticalPosition: function (position) { return gridBodyCtrl.getScrollFeature().setVerticalScrollPosition(position); },
                getHorizontalPosition: function () { return gridBodyCtrl.getScrollFeature().getHScrollPosition().left; },
                setHorizontalPosition: function (position) { return gridBodyCtrl.getScrollFeature().setHorizontalScrollPosition(position); },
                shouldSkipVerticalScroll: function () { return !_this.gridOptionsService.isDomLayout('normal'); },
                shouldSkipHorizontalScroll: function () { return !gridBodyCtrl.getScrollFeature().isHorizontalScrollShowing(); }
            });
        });
    };
    // Called for both columns loaded & column visibility events
    RangeService.prototype.onColumnsChanged = function () {
        var _this = this;
        // first move start column in last cell range (i.e. series chart range)
        this.refreshLastRangeStart();
        var allColumns = this.columnModel.getAllDisplayedColumns();
        // check that the columns in each range still exist and are visible
        this.cellRanges.forEach(function (cellRange) {
            var beforeCols = cellRange.columns;
            // remove hidden or removed cols from cell range
            cellRange.columns = cellRange.columns.filter(function (col) { return col.isVisible() && allColumns.indexOf(col) !== -1; });
            var colsInRangeChanged = !_.areEqual(beforeCols, cellRange.columns);
            if (colsInRangeChanged) {
                // notify users and other parts of grid (i.e. status panel) that range has changed
                _this.dispatchChangedEvent(false, true, cellRange.id);
            }
        });
        // Remove empty cell ranges
        var countBefore = this.cellRanges.length;
        this.cellRanges = this.cellRanges.filter(function (range) { return range.columns.length > 0; });
        if (countBefore > this.cellRanges.length) {
            this.dispatchChangedEvent(false, true);
        }
    };
    RangeService.prototype.refreshLastRangeStart = function () {
        var lastRange = _.last(this.cellRanges);
        if (!lastRange) {
            return;
        }
        this.refreshRangeStart(lastRange);
    };
    RangeService.prototype.isContiguousRange = function (cellRange) {
        var rangeColumns = cellRange.columns;
        if (!rangeColumns.length) {
            return false;
        }
        var allColumns = this.columnModel.getAllDisplayedColumns();
        var allPositions = rangeColumns.map(function (c) { return allColumns.indexOf(c); }).sort(function (a, b) { return a - b; });
        return _.last(allPositions) - allPositions[0] + 1 === rangeColumns.length;
    };
    RangeService.prototype.getRangeStartRow = function (cellRange) {
        if (cellRange.startRow && cellRange.endRow) {
            return this.rowPositionUtils.before(cellRange.startRow, cellRange.endRow) ?
                cellRange.startRow : cellRange.endRow;
        }
        var rowPinned = this.pinnedRowModel.getPinnedTopRowCount() > 0 ? 'top' : null;
        return { rowIndex: 0, rowPinned: rowPinned };
    };
    RangeService.prototype.getRangeEndRow = function (cellRange) {
        if (cellRange.startRow && cellRange.endRow) {
            return this.rowPositionUtils.before(cellRange.startRow, cellRange.endRow) ?
                cellRange.endRow : cellRange.startRow;
        }
        var pinnedBottomRowCount = this.pinnedRowModel.getPinnedBottomRowCount();
        var pinnedBottom = pinnedBottomRowCount > 0;
        if (pinnedBottom) {
            return {
                rowIndex: pinnedBottomRowCount - 1,
                rowPinned: 'bottom'
            };
        }
        return {
            rowIndex: this.rowModel.getRowCount() - 1,
            rowPinned: null
        };
    };
    RangeService.prototype.setRangeToCell = function (cell, appendRange) {
        if (appendRange === void 0) { appendRange = false; }
        if (!this.gridOptionsService.get('enableRangeSelection')) {
            return;
        }
        var columns = this.calculateColumnsBetween(cell.column, cell.column);
        if (!columns) {
            return;
        }
        var suppressMultiRangeSelections = this.gridOptionsService.get('suppressMultiRangeSelection');
        // if not appending, then clear previous range selections
        if (suppressMultiRangeSelections || !appendRange || _.missing(this.cellRanges)) {
            this.removeAllCellRanges(true);
        }
        var rowForCell = {
            rowIndex: cell.rowIndex,
            rowPinned: cell.rowPinned
        };
        var cellRange = {
            startRow: rowForCell,
            endRow: rowForCell,
            columns: columns,
            startColumn: cell.column
        };
        this.cellRanges.push(cellRange);
        this.setNewestRangeStartCell(cell);
        this.onDragStop();
        this.dispatchChangedEvent(true, true);
    };
    RangeService.prototype.extendLatestRangeToCell = function (cellPosition) {
        if (this.isEmpty() || !this.newestRangeStartCell) {
            return;
        }
        var cellRange = _.last(this.cellRanges);
        this.updateRangeEnd(cellRange, cellPosition);
    };
    RangeService.prototype.updateRangeEnd = function (cellRange, cellPosition, silent) {
        if (silent === void 0) { silent = false; }
        var endColumn = cellPosition.column;
        var colsToAdd = this.calculateColumnsBetween(cellRange.startColumn, endColumn);
        if (!colsToAdd || this.isLastCellOfRange(cellRange, cellPosition)) {
            return;
        }
        cellRange.columns = colsToAdd;
        cellRange.endRow = { rowIndex: cellPosition.rowIndex, rowPinned: cellPosition.rowPinned };
        if (!silent) {
            this.dispatchChangedEvent(true, true, cellRange.id);
        }
    };
    RangeService.prototype.refreshRangeStart = function (cellRange) {
        var startColumn = cellRange.startColumn, columns = cellRange.columns;
        var moveColInCellRange = function (colToMove, moveToFront) {
            var otherCols = cellRange.columns.filter(function (col) { return col !== colToMove; });
            if (colToMove) {
                cellRange.startColumn = colToMove;
                cellRange.columns = moveToFront ? __spreadArray$7([colToMove], __read$c(otherCols), false) : __spreadArray$7(__spreadArray$7([], __read$c(otherCols), false), [colToMove], false);
            }
            else {
                cellRange.columns = otherCols;
            }
        };
        var _a = this.getRangeEdgeColumns(cellRange), left = _a.left, right = _a.right;
        var shouldMoveLeftCol = startColumn === columns[0] && startColumn !== left;
        if (shouldMoveLeftCol) {
            moveColInCellRange(left, true);
            return;
        }
        var shouldMoveRightCol = startColumn === _.last(columns) && startColumn === right;
        if (shouldMoveRightCol) {
            moveColInCellRange(right, false);
            return;
        }
    };
    RangeService.prototype.getRangeEdgeColumns = function (cellRange) {
        var allColumns = this.columnModel.getAllDisplayedColumns();
        var allIndices = cellRange.columns
            .map(function (c) { return allColumns.indexOf(c); })
            .filter(function (i) { return i > -1; })
            .sort(function (a, b) { return a - b; });
        return {
            left: allColumns[allIndices[0]],
            right: allColumns[_.last(allIndices)]
        };
    };
    // returns true if successful, false if not successful
    RangeService.prototype.extendLatestRangeInDirection = function (event) {
        if (this.isEmpty() || !this.newestRangeStartCell) {
            return;
        }
        var key = event.key;
        var ctrlKey = event.ctrlKey || event.metaKey;
        var lastRange = _.last(this.cellRanges);
        var startCell = this.newestRangeStartCell;
        var firstCol = lastRange.columns[0];
        var lastCol = _.last(lastRange.columns);
        // find the cell that is at the furthest away corner from the starting cell
        var endCellIndex = lastRange.endRow.rowIndex;
        var endCellFloating = lastRange.endRow.rowPinned;
        var endCellColumn = startCell.column === firstCol ? lastCol : firstCol;
        var endCell = { column: endCellColumn, rowIndex: endCellIndex, rowPinned: endCellFloating };
        var newEndCell = this.cellNavigationService.getNextCellToFocus(key, endCell, ctrlKey);
        // if user is at end of grid, so no cell to extend to, we return false
        if (!newEndCell) {
            return;
        }
        this.setCellRange({
            rowStartIndex: startCell.rowIndex,
            rowStartPinned: startCell.rowPinned,
            rowEndIndex: newEndCell.rowIndex,
            rowEndPinned: newEndCell.rowPinned,
            columnStart: startCell.column,
            columnEnd: newEndCell.column
        });
        return newEndCell;
    };
    RangeService.prototype.setCellRange = function (params) {
        if (!this.gridOptionsService.get('enableRangeSelection')) {
            return;
        }
        this.removeAllCellRanges(true);
        this.addCellRange(params);
    };
    RangeService.prototype.setCellRanges = function (cellRanges) {
        var _this = this;
        if (_.shallowCompare(this.cellRanges, cellRanges)) {
            return;
        }
        this.removeAllCellRanges(true);
        cellRanges.forEach(function (newRange) {
            if (newRange.columns && newRange.startRow) {
                _this.setNewestRangeStartCell({
                    rowIndex: newRange.startRow.rowIndex,
                    rowPinned: newRange.startRow.rowPinned,
                    column: newRange.columns[0]
                });
            }
            _this.cellRanges.push(newRange);
        });
        this.dispatchChangedEvent(false, true);
    };
    RangeService.prototype.setNewestRangeStartCell = function (position) {
        this.newestRangeStartCell = position;
    };
    RangeService.prototype.clearCellRangeCellValues = function (params) {
        var _this = this;
        var cellRanges = params.cellRanges;
        var _a = params.cellEventSource, cellEventSource = _a === void 0 ? 'rangeService' : _a, dispatchWrapperEvents = params.dispatchWrapperEvents, _b = params.wrapperEventSource, wrapperEventSource = _b === void 0 ? 'deleteKey' : _b;
        if (dispatchWrapperEvents) {
            var startEvent = {
                type: Events.EVENT_RANGE_DELETE_START,
                source: wrapperEventSource
            };
            this.eventService.dispatchEvent(startEvent);
        }
        if (!cellRanges) {
            cellRanges = this.cellRanges;
        }
        cellRanges.forEach(function (cellRange) {
            _this.forEachRowInRange(cellRange, function (rowPosition) {
                var rowNode = _this.rowPositionUtils.getRowNode(rowPosition);
                if (!rowNode) {
                    return;
                }
                for (var i = 0; i < cellRange.columns.length; i++) {
                    var column = _this.columnModel.getGridColumn(cellRange.columns[i]);
                    if (!column || !column.isCellEditable(rowNode)) {
                        continue;
                    }
                    rowNode.setDataValue(column, null, cellEventSource);
                }
            });
        });
        if (dispatchWrapperEvents) {
            var endEvent = {
                type: Events.EVENT_RANGE_DELETE_END,
                source: wrapperEventSource
            };
            this.eventService.dispatchEvent(endEvent);
        }
    };
    RangeService.prototype.createCellRangeFromCellRangeParams = function (params) {
        var _this = this;
        var columns;
        var startsOnTheRight = false;
        if (params.columns) {
            columns = params.columns.map(function (c) { return _this.columnModel.getColumnWithValidation(c); }).filter(function (c) { return c; });
        }
        else {
            var columnStart = this.columnModel.getColumnWithValidation(params.columnStart);
            var columnEnd = this.columnModel.getColumnWithValidation(params.columnEnd);
            if (!columnStart || !columnEnd) {
                return;
            }
            columns = this.calculateColumnsBetween(columnStart, columnEnd);
            if (columns && columns.length) {
                startsOnTheRight = columns[0] !== columnStart;
            }
        }
        if (!columns) {
            return;
        }
        var startRow = params.rowStartIndex != null ? {
            rowIndex: params.rowStartIndex,
            rowPinned: params.rowStartPinned || null
        } : undefined;
        var endRow = params.rowEndIndex != null ? {
            rowIndex: params.rowEndIndex,
            rowPinned: params.rowEndPinned || null
        } : undefined;
        return {
            startRow: startRow,
            endRow: endRow,
            columns: columns,
            startColumn: startsOnTheRight ? _.last(columns) : columns[0]
        };
    };
    RangeService.prototype.addCellRange = function (params) {
        if (!this.gridOptionsService.get('enableRangeSelection')) {
            return;
        }
        var newRange = this.createCellRangeFromCellRangeParams(params);
        if (newRange) {
            if (newRange.startRow) {
                this.setNewestRangeStartCell({
                    rowIndex: newRange.startRow.rowIndex,
                    rowPinned: newRange.startRow.rowPinned,
                    column: newRange.startColumn
                });
            }
            this.cellRanges.push(newRange);
            this.dispatchChangedEvent(false, true, newRange.id);
        }
    };
    RangeService.prototype.getCellRanges = function () {
        return this.cellRanges;
    };
    RangeService.prototype.isEmpty = function () {
        return this.cellRanges.length === 0;
    };
    RangeService.prototype.isMoreThanOneCell = function () {
        var len = this.cellRanges.length;
        if (len === 0) {
            return false;
        }
        if (len > 1) {
            return true; // Assumes a cell range must contain at least one cell
        }
        // only one range, return true if range has more than one
        var range = this.cellRanges[0];
        var startRow = this.getRangeStartRow(range);
        var endRow = this.getRangeEndRow(range);
        return startRow.rowPinned !== endRow.rowPinned ||
            startRow.rowIndex !== endRow.rowIndex ||
            range.columns.length !== 1;
    };
    RangeService.prototype.areAllRangesAbleToMerge = function () {
        var e_1, _a;
        var _this = this;
        var rowToColumnMap = new Map();
        var len = this.cellRanges.length;
        if (len <= 1)
            return true;
        this.cellRanges.forEach(function (range) {
            _this.forEachRowInRange(range, function (row) {
                var rowName = "".concat(row.rowPinned || 'normal', "_").concat(row.rowIndex);
                var columns = rowToColumnMap.get(rowName);
                var currentRangeColIds = range.columns.map(function (col) { return col.getId(); });
                if (columns) {
                    var filteredColumns = currentRangeColIds.filter(function (col) { return columns.indexOf(col) === -1; });
                    columns.push.apply(columns, __spreadArray$7([], __read$c(filteredColumns), false));
                }
                else {
                    rowToColumnMap.set(rowName, currentRangeColIds);
                }
            });
        });
        var columnsString;
        try {
            for (var _b = __values$3(rowToColumnMap.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var val = _c.value;
                var currentValString = val.sort().join();
                if (columnsString === undefined) {
                    columnsString = currentValString;
                    continue;
                }
                if (columnsString !== currentValString) {
                    return false;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return true;
    };
    RangeService.prototype.forEachRowInRange = function (cellRange, callback) {
        var topRow = this.getRangeStartRow(cellRange);
        var bottomRow = this.getRangeEndRow(cellRange);
        var currentRow = topRow;
        while (currentRow) {
            callback(currentRow);
            if (this.rowPositionUtils.sameRow(currentRow, bottomRow)) {
                break;
            }
            currentRow = this.cellNavigationService.getRowBelow(currentRow);
        }
    };
    RangeService.prototype.removeAllCellRanges = function (silent) {
        if (this.isEmpty()) {
            return;
        }
        this.onDragStop();
        this.cellRanges.length = 0;
        if (!silent) {
            this.dispatchChangedEvent(false, true);
        }
    };
    // as the user is dragging outside of the panel, the div starts to scroll, which in turn
    // means we are selection more (or less) cells, but the mouse isn't moving, so we recalculate
    // the selection my mimicking a new mouse event
    RangeService.prototype.onBodyScroll = function () {
        if (this.dragging && this.lastMouseEvent) {
            this.onDragging(this.lastMouseEvent);
        }
    };
    RangeService.prototype.isCellInAnyRange = function (cell) {
        return this.getCellRangeCount(cell) > 0;
    };
    RangeService.prototype.isCellInSpecificRange = function (cell, range) {
        var columnInRange = range.columns !== null && _.includes(range.columns, cell.column);
        var rowInRange = this.isRowInRange(cell.rowIndex, cell.rowPinned, range);
        return columnInRange && rowInRange;
    };
    RangeService.prototype.isLastCellOfRange = function (cellRange, cell) {
        var startRow = cellRange.startRow, endRow = cellRange.endRow;
        var lastRow = this.rowPositionUtils.before(startRow, endRow) ? endRow : startRow;
        var isLastRow = cell.rowIndex === lastRow.rowIndex && cell.rowPinned === lastRow.rowPinned;
        var rangeFirstIndexColumn = cellRange.columns[0];
        var rangeLastIndexColumn = _.last(cellRange.columns);
        var lastRangeColumn = cellRange.startColumn === rangeFirstIndexColumn ? rangeLastIndexColumn : rangeFirstIndexColumn;
        var isLastColumn = cell.column === lastRangeColumn;
        return isLastColumn && isLastRow;
    };
    RangeService.prototype.isBottomRightCell = function (cellRange, cell) {
        var allColumns = this.columnModel.getAllDisplayedColumns();
        var allPositions = cellRange.columns.map(function (c) { return allColumns.indexOf(c); }).sort(function (a, b) { return a - b; });
        var startRow = cellRange.startRow, endRow = cellRange.endRow;
        var lastRow = this.rowPositionUtils.before(startRow, endRow) ? endRow : startRow;
        var isRightColumn = allColumns.indexOf(cell.column) === _.last(allPositions);
        var isLastRow = cell.rowIndex === lastRow.rowIndex && _.makeNull(cell.rowPinned) === _.makeNull(lastRow.rowPinned);
        return isRightColumn && isLastRow;
    };
    // returns the number of ranges this cell is in
    RangeService.prototype.getCellRangeCount = function (cell) {
        var _this = this;
        if (this.isEmpty()) {
            return 0;
        }
        return this.cellRanges.filter(function (cellRange) { return _this.isCellInSpecificRange(cell, cellRange); }).length;
    };
    RangeService.prototype.isRowInRange = function (rowIndex, floating, cellRange) {
        var firstRow = this.getRangeStartRow(cellRange);
        var lastRow = this.getRangeEndRow(cellRange);
        var thisRow = { rowIndex: rowIndex, rowPinned: floating || null };
        // compare rowPinned with == instead of === because it can be `null` or `undefined`
        var equalsFirstRow = thisRow.rowIndex === firstRow.rowIndex && thisRow.rowPinned == firstRow.rowPinned;
        var equalsLastRow = thisRow.rowIndex === lastRow.rowIndex && thisRow.rowPinned == lastRow.rowPinned;
        if (equalsFirstRow || equalsLastRow) {
            return true;
        }
        var afterFirstRow = !this.rowPositionUtils.before(thisRow, firstRow);
        var beforeLastRow = this.rowPositionUtils.before(thisRow, lastRow);
        return afterFirstRow && beforeLastRow;
    };
    RangeService.prototype.getDraggingRange = function () {
        return this.draggingRange;
    };
    RangeService.prototype.onDragStart = function (mouseEvent) {
        if (!this.gridOptionsService.get('enableRangeSelection')) {
            return;
        }
        var ctrlKey = mouseEvent.ctrlKey, metaKey = mouseEvent.metaKey, shiftKey = mouseEvent.shiftKey;
        // ctrlKey for windows, metaKey for Apple
        var isMultiKey = ctrlKey || metaKey;
        var allowMulti = !this.gridOptionsService.get('suppressMultiRangeSelection');
        var isMultiSelect = allowMulti ? isMultiKey : false;
        var extendRange = shiftKey && _.existsAndNotEmpty(this.cellRanges);
        if (!isMultiSelect && (!extendRange || _.exists(_.last(this.cellRanges).type))) {
            this.removeAllCellRanges(true);
        }
        // The browser changes the Event target of cached events when working with the ShadowDOM
        // so we need to retrieve the initial DragStartTarget.
        var startTarget = this.dragService.getStartTarget();
        if (startTarget) {
            this.updateValuesOnMove(startTarget);
        }
        if (!this.lastCellHovered) {
            return;
        }
        this.dragging = true;
        this.lastMouseEvent = mouseEvent;
        this.intersectionRange = isMultiSelect && this.getCellRangeCount(this.lastCellHovered) > 1;
        if (!extendRange) {
            this.setNewestRangeStartCell(this.lastCellHovered);
        }
        // if we didn't clear the ranges, then dragging means the user clicked, and when the
        // user clicks it means a range of one cell was created. we need to extend this range
        // rather than creating another range. otherwise we end up with two distinct ranges
        // from a drag operation (one from click, and one from drag).
        if (this.cellRanges.length > 0) {
            this.draggingRange = _.last(this.cellRanges);
        }
        else {
            var mouseRowPosition = {
                rowIndex: this.lastCellHovered.rowIndex,
                rowPinned: this.lastCellHovered.rowPinned
            };
            this.draggingRange = {
                startRow: mouseRowPosition,
                endRow: mouseRowPosition,
                columns: [this.lastCellHovered.column],
                startColumn: this.newestRangeStartCell.column
            };
            this.cellRanges.push(this.draggingRange);
        }
        this.ctrlsService.getGridBodyCtrl().addScrollEventListener(this.bodyScrollListener);
        this.dispatchChangedEvent(true, false, this.draggingRange.id);
    };
    RangeService.prototype.intersectLastRange = function (fromMouseClick) {
        var _this = this;
        // when ranges are created due to a mouse click without drag (happens in cellMouseListener)
        // this method will be called with `fromMouseClick=true`.
        if (fromMouseClick && this.dragging) {
            return;
        }
        if (this.gridOptionsService.get('suppressMultiRangeSelection')) {
            return;
        }
        if (this.isEmpty()) {
            return;
        }
        var lastRange = _.last(this.cellRanges);
        var intersectionStartRow = this.getRangeStartRow(lastRange);
        var intersectionEndRow = this.getRangeEndRow(lastRange);
        var newRanges = [];
        this.cellRanges.slice(0, -1).forEach(function (range) {
            var startRow = _this.getRangeStartRow(range);
            var endRow = _this.getRangeEndRow(range);
            var cols = range.columns;
            var intersectCols = cols.filter(function (col) { return lastRange.columns.indexOf(col) === -1; });
            if (intersectCols.length === cols.length) {
                // No overlapping columns, retain previous range
                newRanges.push(range);
                return;
            }
            if (_this.rowPositionUtils.before(intersectionEndRow, startRow) || _this.rowPositionUtils.before(endRow, intersectionStartRow)) {
                // No overlapping rows, retain previous range
                newRanges.push(range);
                return;
            }
            var rangeCountBefore = newRanges.length;
            // Top
            if (_this.rowPositionUtils.before(startRow, intersectionStartRow)) {
                var top_1 = {
                    columns: __spreadArray$7([], __read$c(cols), false),
                    startColumn: lastRange.startColumn,
                    startRow: __assign$c({}, startRow),
                    endRow: _this.cellNavigationService.getRowAbove(intersectionStartRow),
                };
                newRanges.push(top_1);
            }
            // Left & Right (not contiguous with columns)
            if (intersectCols.length > 0) {
                var middle = {
                    columns: intersectCols,
                    startColumn: _.includes(intersectCols, lastRange.startColumn) ? lastRange.startColumn : intersectCols[0],
                    startRow: _this.rowPositionUtils.rowMax([__assign$c({}, intersectionStartRow), __assign$c({}, startRow)]),
                    endRow: _this.rowPositionUtils.rowMin([__assign$c({}, intersectionEndRow), __assign$c({}, endRow)]),
                };
                newRanges.push(middle);
            }
            // Bottom
            if (_this.rowPositionUtils.before(intersectionEndRow, endRow)) {
                newRanges.push({
                    columns: __spreadArray$7([], __read$c(cols), false),
                    startColumn: lastRange.startColumn,
                    startRow: _this.cellNavigationService.getRowBelow(intersectionEndRow),
                    endRow: __assign$c({}, endRow),
                });
            }
            if ((newRanges.length - rangeCountBefore) === 1) {
                // Only one range result from the intersection.
                // Copy the source range's id, since essentially we just reduced it's size
                newRanges[newRanges.length - 1].id = range.id;
            }
        });
        this.cellRanges = newRanges;
        // when this is called because of a clickEvent and the ranges were changed
        // we need to force a dragEnd event to update the UI.
        if (fromMouseClick) {
            this.dispatchChangedEvent(false, true);
        }
    };
    RangeService.prototype.updateValuesOnMove = function (eventTarget) {
        var cellCtrl = _.getCtrlForEventTarget(this.gridOptionsService, eventTarget, CellCtrl.DOM_DATA_KEY_CELL_CTRL);
        var cell = cellCtrl === null || cellCtrl === void 0 ? void 0 : cellCtrl.getCellPosition();
        this.cellHasChanged = false;
        if (!cell || (this.lastCellHovered && this.cellPositionUtils.equals(cell, this.lastCellHovered))) {
            return;
        }
        if (this.lastCellHovered) {
            this.cellHasChanged = true;
        }
        this.lastCellHovered = cell;
    };
    RangeService.prototype.onDragging = function (mouseEvent) {
        var _this = this;
        if (!this.dragging || !mouseEvent) {
            return;
        }
        this.updateValuesOnMove(mouseEvent.target);
        this.lastMouseEvent = mouseEvent;
        var cellPosition = this.lastCellHovered;
        var isMouseAndStartInPinned = function (position) {
            return cellPosition && cellPosition.rowPinned === position && _this.newestRangeStartCell.rowPinned === position;
        };
        var skipVerticalScroll = isMouseAndStartInPinned('top') || isMouseAndStartInPinned('bottom');
        this.autoScrollService.check(mouseEvent, skipVerticalScroll);
        if (!this.cellHasChanged) {
            return;
        }
        var columns = this.calculateColumnsBetween(this.newestRangeStartCell.column, cellPosition.column);
        if (!columns) {
            return;
        }
        this.draggingRange.endRow = {
            rowIndex: cellPosition.rowIndex,
            rowPinned: cellPosition.rowPinned
        };
        this.draggingRange.columns = columns;
        this.dispatchChangedEvent(false, false, this.draggingRange.id);
    };
    RangeService.prototype.onDragStop = function () {
        if (!this.dragging) {
            return;
        }
        var id = this.draggingRange.id;
        this.autoScrollService.ensureCleared();
        this.ctrlsService.getGridBodyCtrl().removeScrollEventListener(this.bodyScrollListener);
        this.lastMouseEvent = null;
        this.dragging = false;
        this.draggingRange = undefined;
        this.lastCellHovered = undefined;
        if (this.intersectionRange) {
            this.intersectionRange = false;
            this.intersectLastRange();
        }
        this.dispatchChangedEvent(false, true, id);
    };
    RangeService.prototype.dispatchChangedEvent = function (started, finished, id) {
        var event = {
            type: Events.EVENT_RANGE_SELECTION_CHANGED,
            started: started,
            finished: finished,
            id: id,
        };
        this.eventService.dispatchEvent(event);
    };
    RangeService.prototype.calculateColumnsBetween = function (columnFrom, columnTo) {
        var allColumns = this.columnModel.getAllDisplayedColumns();
        var isSameColumn = columnFrom === columnTo;
        var fromIndex = allColumns.indexOf(columnFrom);
        if (fromIndex < 0) {
            console.warn("AG Grid: column ".concat(columnFrom.getId(), " is not visible"));
            return;
        }
        var toIndex = isSameColumn ? fromIndex : allColumns.indexOf(columnTo);
        if (toIndex < 0) {
            console.warn("AG Grid: column ".concat(columnTo.getId(), " is not visible"));
            return;
        }
        if (isSameColumn) {
            return [columnFrom];
        }
        var firstIndex = Math.min(fromIndex, toIndex);
        var lastIndex = firstIndex === fromIndex ? toIndex : fromIndex;
        var columns = [];
        for (var i = firstIndex; i <= lastIndex; i++) {
            columns.push(allColumns[i]);
        }
        return columns;
    };
    __decorate$I([
        Autowired('rowModel')
    ], RangeService.prototype, "rowModel", void 0);
    __decorate$I([
        Autowired('dragService')
    ], RangeService.prototype, "dragService", void 0);
    __decorate$I([
        Autowired('columnModel')
    ], RangeService.prototype, "columnModel", void 0);
    __decorate$I([
        Autowired('cellNavigationService')
    ], RangeService.prototype, "cellNavigationService", void 0);
    __decorate$I([
        Autowired("pinnedRowModel")
    ], RangeService.prototype, "pinnedRowModel", void 0);
    __decorate$I([
        Autowired('rowPositionUtils')
    ], RangeService.prototype, "rowPositionUtils", void 0);
    __decorate$I([
        Autowired('cellPositionUtils')
    ], RangeService.prototype, "cellPositionUtils", void 0);
    __decorate$I([
        Autowired('ctrlsService')
    ], RangeService.prototype, "ctrlsService", void 0);
    __decorate$I([
        PostConstruct
    ], RangeService.prototype, "init", null);
    RangeService = __decorate$I([
        Bean('rangeService')
    ], RangeService);
    return RangeService;
}(BeanStub));

var __extends$M = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$H = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AbstractSelectionHandle = /** @class */ (function (_super) {
    __extends$M(AbstractSelectionHandle, _super);
    function AbstractSelectionHandle() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.changedCalculatedValues = false;
        _this.dragging = false;
        _this.shouldDestroyOnEndDragging = false;
        return _this;
    }
    AbstractSelectionHandle.prototype.init = function () {
        var _this = this;
        this.dragService.addDragSource({
            dragStartPixels: 0,
            eElement: this.getGui(),
            onDragStart: this.onDragStart.bind(this),
            onDragging: function (e) {
                _this.dragging = true;
                _this.rangeService.autoScrollService.check(e);
                if (_this.changedCalculatedValues) {
                    _this.onDrag(e);
                    _this.changedCalculatedValues = false;
                }
            },
            onDragStop: function (e) {
                _this.dragging = false;
                _this.onDragEnd(e);
                _this.clearValues();
                _this.rangeService.autoScrollService.ensureCleared();
                // TODO: this causes a bug where if there are multiple grids in the same page, all of them will
                // be affected by a drag on any. Move it to the root element.
                document.body.classList.remove(_this.getDraggingCssClass());
                if (_this.shouldDestroyOnEndDragging) {
                    _this.destroy();
                }
            }
        });
        this.addManagedListener(this.getGui(), 'mousedown', this.preventRangeExtension.bind(this));
    };
    AbstractSelectionHandle.prototype.isDragging = function () {
        return this.dragging;
    };
    AbstractSelectionHandle.prototype.getCellCtrl = function () {
        return this.cellCtrl;
    };
    AbstractSelectionHandle.prototype.setCellCtrl = function (cellComp) {
        this.cellCtrl = cellComp;
    };
    AbstractSelectionHandle.prototype.getCellRange = function () {
        return this.cellRange;
    };
    AbstractSelectionHandle.prototype.setCellRange = function (range) {
        this.cellRange = range;
    };
    AbstractSelectionHandle.prototype.getRangeStartRow = function () {
        return this.rangeStartRow;
    };
    AbstractSelectionHandle.prototype.setRangeStartRow = function (row) {
        this.rangeStartRow = row;
    };
    AbstractSelectionHandle.prototype.getRangeEndRow = function () {
        return this.rangeEndRow;
    };
    AbstractSelectionHandle.prototype.setRangeEndRow = function (row) {
        this.rangeEndRow = row;
    };
    AbstractSelectionHandle.prototype.getLastCellHovered = function () {
        return this.lastCellHovered;
    };
    AbstractSelectionHandle.prototype.preventRangeExtension = function (e) {
        e.stopPropagation();
    };
    AbstractSelectionHandle.prototype.onDragStart = function (e) {
        this.cellHoverListener = this.addManagedListener(this.ctrlsService.getGridCtrl().getGui(), 'mousemove', this.updateValuesOnMove.bind(this));
        document.body.classList.add(this.getDraggingCssClass());
    };
    AbstractSelectionHandle.prototype.getDraggingCssClass = function () {
        return "ag-dragging-".concat(this.type === SelectionHandleType.FILL ? 'fill' : 'range', "-handle");
    };
    AbstractSelectionHandle.prototype.updateValuesOnMove = function (e) {
        var cell = this.mouseEventService.getCellPositionForEvent(e);
        if (!cell || (this.lastCellHovered && this.cellPositionUtils.equals(cell, this.lastCellHovered))) {
            return;
        }
        this.lastCellHovered = cell;
        this.changedCalculatedValues = true;
    };
    AbstractSelectionHandle.prototype.getType = function () {
        return this.type;
    };
    AbstractSelectionHandle.prototype.refresh = function (cellCtrl) {
        var oldCellComp = this.getCellCtrl();
        var eGui = this.getGui();
        var cellRange = _.last(this.rangeService.getCellRanges());
        var start = cellRange.startRow;
        var end = cellRange.endRow;
        if (start && end) {
            var isBefore = this.rowPositionUtils.before(end, start);
            if (isBefore) {
                this.setRangeStartRow(end);
                this.setRangeEndRow(start);
            }
            else {
                this.setRangeStartRow(start);
                this.setRangeEndRow(end);
            }
        }
        if (oldCellComp !== cellCtrl || !_.isVisible(eGui)) {
            this.setCellCtrl(cellCtrl);
            var eParentOfValue = cellCtrl.getComp().getParentOfValue();
            if (eParentOfValue) {
                eParentOfValue.appendChild(eGui);
            }
        }
        this.setCellRange(cellRange);
    };
    AbstractSelectionHandle.prototype.clearValues = function () {
        this.lastCellHovered = undefined;
        this.removeListeners();
    };
    AbstractSelectionHandle.prototype.removeListeners = function () {
        if (this.cellHoverListener) {
            this.cellHoverListener();
            this.cellHoverListener = undefined;
        }
    };
    AbstractSelectionHandle.prototype.destroy = function () {
        if (!this.shouldDestroyOnEndDragging && this.isDragging()) {
            _.setDisplayed(this.getGui(), false);
            this.shouldDestroyOnEndDragging = true;
            return;
        }
        this.shouldDestroyOnEndDragging = false;
        _super.prototype.destroy.call(this);
        this.removeListeners();
        var eGui = this.getGui();
        if (eGui.parentElement) {
            eGui.parentElement.removeChild(eGui);
        }
    };
    __decorate$H([
        Autowired("rowRenderer")
    ], AbstractSelectionHandle.prototype, "rowRenderer", void 0);
    __decorate$H([
        Autowired("dragService")
    ], AbstractSelectionHandle.prototype, "dragService", void 0);
    __decorate$H([
        Autowired("rangeService")
    ], AbstractSelectionHandle.prototype, "rangeService", void 0);
    __decorate$H([
        Autowired("mouseEventService")
    ], AbstractSelectionHandle.prototype, "mouseEventService", void 0);
    __decorate$H([
        Autowired("columnModel")
    ], AbstractSelectionHandle.prototype, "columnModel", void 0);
    __decorate$H([
        Autowired("cellNavigationService")
    ], AbstractSelectionHandle.prototype, "cellNavigationService", void 0);
    __decorate$H([
        Autowired("navigationService")
    ], AbstractSelectionHandle.prototype, "navigationService", void 0);
    __decorate$H([
        Autowired('rowPositionUtils')
    ], AbstractSelectionHandle.prototype, "rowPositionUtils", void 0);
    __decorate$H([
        Autowired('cellPositionUtils')
    ], AbstractSelectionHandle.prototype, "cellPositionUtils", void 0);
    __decorate$H([
        Autowired('ctrlsService')
    ], AbstractSelectionHandle.prototype, "ctrlsService", void 0);
    __decorate$H([
        PostConstruct
    ], AbstractSelectionHandle.prototype, "init", null);
    return AbstractSelectionHandle;
}(Component));

function findLineByLeastSquares(values) {
    var len = values.length;
    var maxDecimals = 0;
    if (len <= 1) {
        return values;
    }
    for (var i = 0; i < values.length; i++) {
        var value = values[i];
        var splitExponent = value.toString().split('e-');
        if (splitExponent.length > 1) {
            maxDecimals = Math.max(maxDecimals, parseInt(splitExponent[1], 10));
            continue;
        }
        if (Math.floor(value) === value) {
            continue;
        }
        maxDecimals = Math.max(maxDecimals, value.toString().split('.')[1].length);
    }
    var sum_x = 0;
    var sum_y = 0;
    var sum_xy = 0;
    var sum_xx = 0;
    var y = 0;
    for (var x = 0; x < len; x++) {
        y = values[x];
        sum_x += x;
        sum_y += y;
        sum_xx += x * x;
        sum_xy += x * y;
    }
    var m = (len * sum_xy - sum_x * sum_y) / (len * sum_xx - sum_x * sum_x);
    var b = (sum_y / len) - (m * sum_x) / len;
    var result = [];
    for (var x = 0; x <= len; x++) {
        result.push(parseFloat((x * m + b).toFixed(maxDecimals)));
    }
    return result;
}

var __extends$L = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$b = (undefined && undefined.__assign) || function () {
    __assign$b = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$b.apply(this, arguments);
};
var __decorate$G = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$b = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$6 = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var FillHandle = /** @class */ (function (_super) {
    __extends$L(FillHandle, _super);
    function FillHandle() {
        var _this = _super.call(this, FillHandle.TEMPLATE) || this;
        _this.markedCells = [];
        _this.cellValues = [];
        _this.isUp = false;
        _this.isLeft = false;
        _this.isReduce = false;
        _this.type = SelectionHandleType.FILL;
        return _this;
    }
    FillHandle.prototype.updateValuesOnMove = function (e) {
        _super.prototype.updateValuesOnMove.call(this, e);
        if (!this.initialXY) {
            this.initialXY = this.mouseEventService.getNormalisedPosition(e);
        }
        var _a = this.initialXY, x = _a.x, y = _a.y;
        var _b = this.mouseEventService.getNormalisedPosition(e), newX = _b.x, newY = _b.y;
        var diffX = Math.abs(x - newX);
        var diffY = Math.abs(y - newY);
        var allowedDirection = this.getFillHandleDirection();
        var direction;
        if (allowedDirection === 'xy') {
            direction = diffX > diffY ? 'x' : 'y';
        }
        else {
            direction = allowedDirection;
        }
        if (direction !== this.dragAxis) {
            this.dragAxis = direction;
            this.changedCalculatedValues = true;
        }
    };
    FillHandle.prototype.onDrag = function (e) {
        if (!this.initialPosition) {
            var cellCtrl = this.getCellCtrl();
            if (!cellCtrl) {
                return;
            }
            this.initialPosition = cellCtrl.getCellPosition();
        }
        var lastCellHovered = this.getLastCellHovered();
        if (lastCellHovered) {
            this.markPathFrom(this.initialPosition, lastCellHovered);
        }
    };
    FillHandle.prototype.onDragEnd = function (e) {
        this.initialXY = null;
        if (!this.markedCells.length) {
            return;
        }
        var isX = this.dragAxis === 'x';
        var initialRange = this.getCellRange();
        var colLen = initialRange.columns.length;
        var rangeStartRow = this.getRangeStartRow();
        var rangeEndRow = this.getRangeEndRow();
        var finalRange;
        if (!this.isUp && !this.isLeft) {
            finalRange = this.rangeService.createCellRangeFromCellRangeParams({
                rowStartIndex: rangeStartRow.rowIndex,
                rowStartPinned: rangeStartRow.rowPinned,
                columnStart: initialRange.columns[0],
                rowEndIndex: isX ? rangeEndRow.rowIndex : this.lastCellMarked.rowIndex,
                rowEndPinned: isX ? rangeEndRow.rowPinned : this.lastCellMarked.rowPinned,
                columnEnd: isX ? this.lastCellMarked.column : initialRange.columns[colLen - 1]
            });
        }
        else {
            var startRow = isX ? rangeStartRow : this.lastCellMarked;
            finalRange = this.rangeService.createCellRangeFromCellRangeParams({
                rowStartIndex: startRow.rowIndex,
                rowStartPinned: startRow.rowPinned,
                columnStart: isX ? this.lastCellMarked.column : initialRange.columns[0],
                rowEndIndex: rangeEndRow.rowIndex,
                rowEndPinned: rangeEndRow.rowPinned,
                columnEnd: initialRange.columns[colLen - 1]
            });
        }
        if (finalRange) {
            // raising fill events for undo / redo
            this.raiseFillStartEvent();
            this.handleValueChanged(initialRange, finalRange, e);
            this.rangeService.setCellRanges([finalRange]);
            this.raiseFillEndEvent(initialRange, finalRange);
        }
    };
    FillHandle.prototype.getFillHandleDirection = function () {
        var direction = this.gridOptionsService.get('fillHandleDirection');
        if (!direction) {
            return 'xy';
        }
        if (direction !== 'x' && direction !== 'y' && direction !== 'xy') {
            _.warnOnce("valid values for fillHandleDirection are 'x', 'y' and 'xy'. Default to 'xy'.");
            return 'xy';
        }
        return direction;
    };
    FillHandle.prototype.raiseFillStartEvent = function () {
        var fillStartEvent = {
            type: Events.EVENT_FILL_START
        };
        this.eventService.dispatchEvent(fillStartEvent);
    };
    FillHandle.prototype.raiseFillEndEvent = function (initialRange, finalRange) {
        var fillEndEvent = {
            type: Events.EVENT_FILL_END,
            initialRange: initialRange,
            finalRange: finalRange
        };
        this.eventService.dispatchEvent(fillEndEvent);
    };
    FillHandle.prototype.handleValueChanged = function (initialRange, finalRange, e) {
        var _this = this;
        var initialRangeEndRow = this.rangeService.getRangeEndRow(initialRange);
        var initialRangeStartRow = this.rangeService.getRangeStartRow(initialRange);
        var finalRangeEndRow = this.rangeService.getRangeEndRow(finalRange);
        var finalRangeStartRow = this.rangeService.getRangeStartRow(finalRange);
        var isVertical = this.dragAxis === 'y';
        // if the range is being reduced in size, all we need to do is
        // clear the cells that are no longer part of the range
        if (this.isReduce && !this.gridOptionsService.get('suppressClearOnFillReduction')) {
            var columns = isVertical
                ? initialRange.columns
                : initialRange.columns.filter(function (col) { return finalRange.columns.indexOf(col) < 0; });
            var startRow = isVertical ? this.cellNavigationService.getRowBelow(finalRangeEndRow) : finalRangeStartRow;
            if (startRow) {
                this.clearCellsInRange(startRow, initialRangeEndRow, columns);
            }
            return;
        }
        var values = [];
        var initialValues = [];
        var initialNonAggregatedValues = [];
        var initialFormattedValues = [];
        var withinInitialRange = true;
        var idx = 0;
        var resetValues = function () {
            values.length = 0;
            initialValues.length = 0;
            initialNonAggregatedValues.length = 0;
            initialFormattedValues.length = 0;
            idx = 0;
        };
        var iterateAcrossCells = function (column, columns) {
            var currentRow = _this.isUp ? initialRangeEndRow : initialRangeStartRow;
            var finished = false;
            if (isVertical) {
                withinInitialRange = true;
                resetValues();
            }
            var _loop_1 = function () {
                var rowNode = _this.rowPositionUtils.getRowNode(currentRow);
                if (!rowNode) {
                    return "break";
                }
                if (isVertical && column) {
                    fillValues(values, column, rowNode, function () {
                        return !_this.rowPositionUtils.sameRow(currentRow, _this.isUp ? initialRangeStartRow : initialRangeEndRow);
                    });
                }
                else if (columns) {
                    withinInitialRange = true;
                    resetValues();
                    columns.forEach(function (col) { return fillValues(values, col, rowNode, function () { return col !== (_this.isLeft ? initialRange.columns[0] : _.last(initialRange.columns)); }); });
                }
                finished = _this.rowPositionUtils.sameRow(currentRow, _this.isUp ? finalRangeStartRow : finalRangeEndRow);
                currentRow = _this.isUp
                    ? _this.cellNavigationService.getRowAbove(currentRow)
                    : _this.cellNavigationService.getRowBelow(currentRow);
            };
            while (!finished && currentRow) {
                var state_1 = _loop_1();
                if (state_1 === "break")
                    break;
            }
        };
        var fillValues = function (currentValues, col, rowNode, updateInitialSet) {
            var _a, _b;
            var currentValue;
            var skipValue = false;
            if (withinInitialRange) {
                currentValue = _this.valueService.getValue(col, rowNode);
                initialValues.push(currentValue);
                initialNonAggregatedValues.push(_this.valueService.getValue(col, rowNode, undefined, true));
                initialFormattedValues.push(_this.valueFormatterService.formatValue(col, rowNode, currentValue));
                withinInitialRange = updateInitialSet();
            }
            else {
                var _c = _this.processValues({
                    event: e,
                    values: currentValues,
                    initialValues: initialValues,
                    initialNonAggregatedValues: initialNonAggregatedValues,
                    initialFormattedValues: initialFormattedValues,
                    col: col,
                    rowNode: rowNode,
                    idx: idx++
                }), value = _c.value, fromUserFunction = _c.fromUserFunction, sourceCol = _c.sourceCol, sourceRowNode = _c.sourceRowNode;
                currentValue = value;
                if (col.isCellEditable(rowNode)) {
                    var cellValue = _this.valueService.getValue(col, rowNode);
                    if (!fromUserFunction) {
                        if (sourceCol && ((_a = sourceCol.getColDef()) === null || _a === void 0 ? void 0 : _a.useValueFormatterForExport) !== false) {
                            currentValue = (_b = _this.valueFormatterService.formatValue(sourceCol, sourceRowNode, currentValue)) !== null && _b !== void 0 ? _b : currentValue;
                        }
                        if (col.getColDef().useValueParserForImport !== false) {
                            currentValue = _this.valueParserService.parseValue(col, rowNode, 
                            // if no sourceCol, then currentValue is a number
                            sourceCol ? currentValue : _.toStringOrNull(currentValue), cellValue);
                        }
                    }
                    if (!fromUserFunction || cellValue !== currentValue) {
                        rowNode.setDataValue(col, currentValue, 'rangeService');
                    }
                    else {
                        skipValue = true;
                    }
                }
            }
            if (!skipValue) {
                currentValues.push({
                    value: currentValue,
                    column: col,
                    rowNode: rowNode
                });
            }
        };
        if (isVertical) {
            initialRange.columns.forEach(function (col) {
                iterateAcrossCells(col);
            });
        }
        else {
            var columns = this.isLeft ? __spreadArray$6([], __read$b(finalRange.columns), false).reverse() : finalRange.columns;
            iterateAcrossCells(undefined, columns);
        }
    };
    FillHandle.prototype.clearCellsInRange = function (startRow, endRow, columns) {
        var cellRange = {
            startRow: startRow,
            endRow: endRow,
            columns: columns,
            startColumn: columns[0]
        };
        this.rangeService.clearCellRangeCellValues({ cellRanges: [cellRange] });
    };
    FillHandle.prototype.processValues = function (params) {
        var event = params.event, values = params.values, initialValues = params.initialValues, initialNonAggregatedValues = params.initialNonAggregatedValues, initialFormattedValues = params.initialFormattedValues, col = params.col, rowNode = params.rowNode, idx = params.idx;
        var userFillOperation = this.gridOptionsService.getCallback('fillOperation');
        var isVertical = this.dragAxis === 'y';
        var direction;
        if (isVertical) {
            direction = this.isUp ? 'up' : 'down';
        }
        else {
            direction = this.isLeft ? 'left' : 'right';
        }
        if (userFillOperation) {
            var params_1 = {
                event: event,
                values: values.map(function (_a) {
                    var value = _a.value;
                    return value;
                }),
                initialValues: initialValues,
                initialNonAggregatedValues: initialNonAggregatedValues,
                initialFormattedValues: initialFormattedValues,
                currentIndex: idx,
                currentCellValue: this.valueService.getValue(col, rowNode),
                direction: direction,
                column: col,
                rowNode: rowNode
            };
            var userResult = userFillOperation(params_1);
            if (userResult !== false) {
                return { value: userResult, fromUserFunction: true };
            }
        }
        var allNumbers = !values.some(function (_a) {
            var value = _a.value;
            var asFloat = parseFloat(value);
            return isNaN(asFloat) || asFloat.toString() !== value.toString();
        });
        // values should be copied in order if the alt key is pressed
        // or if the values contain strings and numbers
        // However, if we only have one initial value selected, and that
        // value is a number and we are also pressing alt, then we should
        // increment or decrement the value by 1 based on direction.
        if (event.altKey || !allNumbers) {
            if (allNumbers && initialValues.length === 1) {
                var multiplier = (this.isUp || this.isLeft) ? -1 : 1;
                return { value: parseFloat(_.last(values).value) + 1 * multiplier, fromUserFunction: false };
            }
            var _a = values[idx % values.length], value = _a.value, sourceCol = _a.column, sourceRowNode = _a.rowNode;
            return { value: value, fromUserFunction: false, sourceCol: sourceCol, sourceRowNode: sourceRowNode };
        }
        return { value: _.last(findLineByLeastSquares(values.map(function (_a) {
                var value = _a.value;
                return Number(value);
            }))), fromUserFunction: false };
    };
    FillHandle.prototype.clearValues = function () {
        this.clearMarkedPath();
        this.clearCellValues();
        this.lastCellMarked = undefined;
        _super.prototype.clearValues.call(this);
    };
    FillHandle.prototype.clearMarkedPath = function () {
        this.markedCells.forEach(function (cell) {
            if (!cell.isAlive()) {
                return;
            }
            var comp = cell.getComp();
            comp.addOrRemoveCssClass('ag-selection-fill-top', false);
            comp.addOrRemoveCssClass('ag-selection-fill-right', false);
            comp.addOrRemoveCssClass('ag-selection-fill-bottom', false);
            comp.addOrRemoveCssClass('ag-selection-fill-left', false);
        });
        this.markedCells.length = 0;
        this.isUp = false;
        this.isLeft = false;
        this.isReduce = false;
    };
    FillHandle.prototype.clearCellValues = function () {
        this.cellValues.length = 0;
    };
    FillHandle.prototype.markPathFrom = function (initialPosition, currentPosition) {
        this.clearMarkedPath();
        this.clearCellValues();
        if (this.dragAxis === 'y') {
            if (this.rowPositionUtils.sameRow(currentPosition, initialPosition)) {
                return;
            }
            var isBefore = this.rowPositionUtils.before(currentPosition, initialPosition);
            var rangeStartRow = this.getRangeStartRow();
            var rangeEndRow = this.getRangeEndRow();
            if (isBefore && ((currentPosition.rowPinned == rangeStartRow.rowPinned &&
                currentPosition.rowIndex >= rangeStartRow.rowIndex) ||
                (rangeStartRow.rowPinned != rangeEndRow.rowPinned &&
                    currentPosition.rowPinned == rangeEndRow.rowPinned &&
                    currentPosition.rowIndex <= rangeEndRow.rowIndex))) {
                this.reduceVertical(initialPosition, currentPosition);
                this.isReduce = true;
            }
            else {
                this.extendVertical(initialPosition, currentPosition, isBefore);
                this.isReduce = false;
            }
        }
        else {
            var initialColumn = initialPosition.column;
            var currentColumn = currentPosition.column;
            if (initialColumn === currentColumn) {
                return;
            }
            var displayedColumns = this.columnModel.getAllDisplayedColumns();
            var initialIndex = displayedColumns.indexOf(initialColumn);
            var currentIndex = displayedColumns.indexOf(currentColumn);
            if (currentIndex <= initialIndex && currentIndex >= displayedColumns.indexOf(this.getCellRange().columns[0])) {
                this.reduceHorizontal(initialPosition, currentPosition);
                this.isReduce = true;
            }
            else {
                this.extendHorizontal(initialPosition, currentPosition, currentIndex < initialIndex);
                this.isReduce = false;
            }
        }
        this.lastCellMarked = currentPosition;
    };
    FillHandle.prototype.extendVertical = function (initialPosition, endPosition, isMovingUp) {
        var _a = this, navigationService = _a.navigationService, rangeService = _a.rangeService;
        var row = initialPosition;
        do {
            var cellRange = this.getCellRange();
            var colLen = cellRange.columns.length;
            for (var i = 0; i < colLen; i++) {
                var column = cellRange.columns[i];
                var rowPos = { rowIndex: row.rowIndex, rowPinned: row.rowPinned };
                var cellPos = __assign$b(__assign$b({}, rowPos), { column: column });
                var cellInRange = rangeService.isCellInSpecificRange(cellPos, cellRange);
                var isInitialRow = this.rowPositionUtils.sameRow(row, initialPosition);
                if (isMovingUp) {
                    this.isUp = true;
                }
                if (!isInitialRow) {
                    var cell = navigationService.getCellByPosition(cellPos);
                    if (cell) {
                        this.markedCells.push(cell);
                        var cellCtrl = cell.getComp();
                        if (!cellInRange) {
                            cellCtrl.addOrRemoveCssClass('ag-selection-fill-left', i === 0);
                            cellCtrl.addOrRemoveCssClass('ag-selection-fill-right', i === colLen - 1);
                        }
                        cellCtrl.addOrRemoveCssClass(isMovingUp ? 'ag-selection-fill-top' : 'ag-selection-fill-bottom', this.rowPositionUtils.sameRow(row, endPosition));
                    }
                }
            }
            if (this.rowPositionUtils.sameRow(row, endPosition)) {
                break;
            }
        } while (
        // tslint:disable-next-line
        row = isMovingUp
            ? this.cellNavigationService.getRowAbove(row)
            : this.cellNavigationService.getRowBelow(row));
    };
    FillHandle.prototype.reduceVertical = function (initialPosition, endPosition) {
        var row = initialPosition;
        do {
            var cellRange = this.getCellRange();
            var colLen = cellRange.columns.length;
            var isLastRow = this.rowPositionUtils.sameRow(row, endPosition);
            for (var i = 0; i < colLen; i++) {
                var rowPos = { rowIndex: row.rowIndex, rowPinned: row.rowPinned };
                var celPos = __assign$b(__assign$b({}, rowPos), { column: cellRange.columns[i] });
                var cell = this.navigationService.getCellByPosition(celPos);
                if (cell) {
                    this.markedCells.push(cell);
                    var cellComp = cell.getComp();
                    cellComp.addOrRemoveCssClass('ag-selection-fill-bottom', this.rowPositionUtils.sameRow(row, endPosition));
                }
            }
            if (isLastRow) {
                break;
            }
            // tslint:disable-next-line
        } while (row = this.cellNavigationService.getRowAbove(row));
    };
    FillHandle.prototype.extendHorizontal = function (initialPosition, endPosition, isMovingLeft) {
        var _this = this;
        var allCols = this.columnModel.getAllDisplayedColumns();
        var startCol = allCols.indexOf(isMovingLeft ? endPosition.column : initialPosition.column);
        var endCol = allCols.indexOf(isMovingLeft ? this.getCellRange().columns[0] : endPosition.column);
        var offset = isMovingLeft ? 0 : 1;
        var colsToMark = allCols.slice(startCol + offset, endCol + offset);
        var rangeStartRow = this.getRangeStartRow();
        var rangeEndRow = this.getRangeEndRow();
        colsToMark.forEach(function (column) {
            var row = rangeStartRow;
            var isLastRow = false;
            do {
                isLastRow = _this.rowPositionUtils.sameRow(row, rangeEndRow);
                var cell = _this.navigationService.getCellByPosition({
                    rowIndex: row.rowIndex,
                    rowPinned: row.rowPinned,
                    column: column
                });
                if (cell) {
                    _this.markedCells.push(cell);
                    var cellComp = cell.getComp();
                    cellComp.addOrRemoveCssClass('ag-selection-fill-top', _this.rowPositionUtils.sameRow(row, rangeStartRow));
                    cellComp.addOrRemoveCssClass('ag-selection-fill-bottom', _this.rowPositionUtils.sameRow(row, rangeEndRow));
                    if (isMovingLeft) {
                        _this.isLeft = true;
                        cellComp.addOrRemoveCssClass('ag-selection-fill-left', column === colsToMark[0]);
                    }
                    else {
                        cellComp.addOrRemoveCssClass('ag-selection-fill-right', column === _.last(colsToMark));
                    }
                }
                row = _this.cellNavigationService.getRowBelow(row);
            } while (!isLastRow);
        });
    };
    FillHandle.prototype.reduceHorizontal = function (initialPosition, endPosition) {
        var _this = this;
        var allCols = this.columnModel.getAllDisplayedColumns();
        var startCol = allCols.indexOf(endPosition.column);
        var endCol = allCols.indexOf(initialPosition.column);
        var colsToMark = allCols.slice(startCol, endCol);
        var rangeStartRow = this.getRangeStartRow();
        var rangeEndRow = this.getRangeEndRow();
        colsToMark.forEach(function (column) {
            var row = rangeStartRow;
            var isLastRow = false;
            do {
                isLastRow = _this.rowPositionUtils.sameRow(row, rangeEndRow);
                var cell = _this.navigationService.getCellByPosition({
                    rowIndex: row.rowIndex,
                    rowPinned: row.rowPinned,
                    column: column
                });
                if (cell) {
                    _this.markedCells.push(cell);
                    var cellComp = cell.getComp();
                    cellComp.addOrRemoveCssClass('ag-selection-fill-right', column === colsToMark[0]);
                }
                row = _this.cellNavigationService.getRowBelow(row);
            } while (!isLastRow);
        });
    };
    FillHandle.prototype.refresh = function (cellCtrl) {
        var cellRange = this.rangeService.getCellRanges()[0];
        var isColumnRange = !cellRange.startRow || !cellRange.endRow;
        if (isColumnRange) {
            this.destroy();
            return;
        }
        _super.prototype.refresh.call(this, cellCtrl);
    };
    FillHandle.TEMPLATE = "<div class=\"ag-fill-handle\"></div>";
    __decorate$G([
        Autowired('valueService')
    ], FillHandle.prototype, "valueService", void 0);
    __decorate$G([
        Autowired('valueParserService')
    ], FillHandle.prototype, "valueParserService", void 0);
    __decorate$G([
        Autowired('valueFormatterService')
    ], FillHandle.prototype, "valueFormatterService", void 0);
    return FillHandle;
}(AbstractSelectionHandle));

var __extends$K = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$a = (undefined && undefined.__assign) || function () {
    __assign$a = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$a.apply(this, arguments);
};
var RangeHandle$1 = /** @class */ (function (_super) {
    __extends$K(RangeHandle, _super);
    function RangeHandle() {
        var _this = _super.call(this, RangeHandle.TEMPLATE) || this;
        _this.type = SelectionHandleType.RANGE;
        _this.rangeFixed = false;
        return _this;
    }
    RangeHandle.prototype.onDrag = function (e) {
        var lastCellHovered = this.getLastCellHovered();
        if (!lastCellHovered) {
            return;
        }
        var cellRanges = this.rangeService.getCellRanges();
        var lastRange = _.last(cellRanges);
        if (!this.rangeFixed) {
            this.fixRangeStartEnd(lastRange);
            this.rangeFixed = true;
        }
        this.endPosition = {
            rowIndex: lastCellHovered.rowIndex,
            rowPinned: lastCellHovered.rowPinned,
            column: lastCellHovered.column
        };
        // check if the cell ranges are for a chart
        if (cellRanges.length === 2 && cellRanges[0].type === CellRangeType.DIMENSION && lastRange.type === CellRangeType.VALUE) {
            var rowChanged = !this.rowPositionUtils.sameRow(this.endPosition, this.rangeService.getRangeEndRow(lastRange));
            if (rowChanged) {
                // ensure the dimension range is kept in sync with the value range (which has the handle)
                this.rangeService.updateRangeEnd(cellRanges[0], __assign$a(__assign$a({}, this.endPosition), { column: cellRanges[0].columns[0] }), true);
            }
        }
        this.rangeService.extendLatestRangeToCell(this.endPosition);
    };
    RangeHandle.prototype.onDragEnd = function (e) {
        var cellRange = _.last(this.rangeService.getCellRanges());
        this.fixRangeStartEnd(cellRange);
        this.rangeFixed = false;
    };
    RangeHandle.prototype.fixRangeStartEnd = function (cellRange) {
        var startRow = this.rangeService.getRangeStartRow(cellRange);
        var endRow = this.rangeService.getRangeEndRow(cellRange);
        var column = cellRange.columns[0];
        cellRange.startRow = startRow;
        cellRange.endRow = endRow;
        cellRange.startColumn = column;
    };
    RangeHandle.TEMPLATE = "<div class=\"ag-range-handle\"></div>";
    return RangeHandle;
}(AbstractSelectionHandle));

var __extends$J = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$F = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var SelectionHandleFactory = /** @class */ (function (_super) {
    __extends$J(SelectionHandleFactory, _super);
    function SelectionHandleFactory() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SelectionHandleFactory.prototype.createSelectionHandle = function (type) {
        return this.createBean(type === SelectionHandleType.RANGE ? new RangeHandle$1() : new FillHandle());
    };
    SelectionHandleFactory = __decorate$F([
        Bean('selectionHandleFactory')
    ], SelectionHandleFactory);
    return SelectionHandleFactory;
}(BeanStub));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$9 = '31.0.2';

var RangeSelectionModule = {
    version: VERSION$9,
    moduleName: ModuleNames.RangeSelectionModule,
    beans: [RangeService, SelectionHandleFactory],
    agStackComponents: [
        { componentName: 'AgFillHandle', componentClass: FillHandle },
        { componentName: 'AgRangeHandle', componentClass: RangeHandle$1 }
    ],
    dependantModules: [
        EnterpriseCoreModule
    ]
};

var __extends$I = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$E = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$a = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var AgColorPanel = /** @class */ (function (_super) {
    __extends$I(AgColorPanel, _super);
    function AgColorPanel(config) {
        var _this = _super.call(this, AgColorPanel.TEMPLATE) || this;
        _this.H = 1; // in the [0, 1] range
        _this.S = 1; // in the [0, 1] range
        _this.B = 1; // in the [0, 1] range
        _this.A = 1; // in the [0, 1] range
        _this.isSpectrumDragging = false;
        _this.isSpectrumHueDragging = false;
        _this.isSpectrumAlphaDragging = false;
        _this.colorChanged = false;
        _this.picker = config.picker;
        return _this;
    }
    AgColorPanel.prototype.postConstruct = function () {
        var _this = this;
        this.initTabIndex();
        this.initRecentColors();
        this.addGuiEventListener('focus', function () { return _this.spectrumColor.focus(); });
        this.addGuiEventListener('keydown', function (e) {
            if (e.key === KeyCode.ENTER && !e.defaultPrevented) {
                _this.destroy();
            }
        });
        this.addManagedListener(this.spectrumColor, 'keydown', function (e) { return _this.moveDragger(e); });
        this.addManagedListener(this.spectrumAlphaSlider, 'keydown', function (e) { return _this.moveAlphaSlider(e); });
        this.addManagedListener(this.spectrumHueSlider, 'keydown', function (e) { return _this.moveHueSlider(e); });
        this.addManagedListener(this.spectrumVal, 'mousedown', this.onSpectrumDraggerDown.bind(this));
        this.addManagedListener(this.spectrumHue, 'mousedown', this.onSpectrumHueDown.bind(this));
        this.addManagedListener(this.spectrumAlpha, 'mousedown', this.onSpectrumAlphaDown.bind(this));
        this.addGuiEventListener('mousemove', function (e) {
            _this.onSpectrumDraggerMove(e);
            _this.onSpectrumHueMove(e);
            _this.onSpectrumAlphaMove(e);
        });
        // Listening to `mouseup` on the document on purpose. The user might release the mouse button
        // outside the UI control. When the mouse returns back to the control's area, the dragging
        // of the thumb is not expected and seen as a bug.
        this.addManagedListener(document, 'mouseup', this.onMouseUp.bind(this));
        this.addManagedListener(this.recentColors, 'click', this.onRecentColorClick.bind(this));
        this.addManagedListener(this.recentColors, 'keydown', function (e) {
            if (e.key === KeyCode.ENTER || e.key === KeyCode.SPACE) {
                e.preventDefault();
                _this.onRecentColorClick(e);
            }
        });
    };
    AgColorPanel.prototype.initTabIndex = function () {
        var tabIndex = this.tabIndex = (this.gridOptionsService.get('tabIndex')).toString();
        this.spectrumColor.setAttribute('tabindex', tabIndex);
        this.spectrumHueSlider.setAttribute('tabindex', tabIndex);
        this.spectrumAlphaSlider.setAttribute('tabindex', tabIndex);
    };
    AgColorPanel.prototype.refreshSpectrumRect = function () {
        return this.spectrumValRect = this.spectrumVal.getBoundingClientRect();
    };
    AgColorPanel.prototype.refreshHueRect = function () {
        return this.spectrumHueRect = this.spectrumHue.getBoundingClientRect();
    };
    AgColorPanel.prototype.refreshAlphaRect = function () {
        return this.spectrumAlphaRect = this.spectrumAlpha.getBoundingClientRect();
    };
    AgColorPanel.prototype.onSpectrumDraggerDown = function (e) {
        this.refreshSpectrumRect();
        this.isSpectrumDragging = true;
        this.moveDragger(e);
    };
    AgColorPanel.prototype.onSpectrumDraggerMove = function (e) {
        if (this.isSpectrumDragging) {
            this.moveDragger(e);
        }
    };
    AgColorPanel.prototype.onSpectrumHueDown = function (e) {
        this.refreshHueRect();
        this.isSpectrumHueDragging = true;
        this.moveHueSlider(e);
    };
    AgColorPanel.prototype.onSpectrumHueMove = function (e) {
        if (this.isSpectrumHueDragging) {
            this.moveHueSlider(e);
        }
    };
    AgColorPanel.prototype.onSpectrumAlphaDown = function (e) {
        this.refreshAlphaRect();
        this.isSpectrumAlphaDragging = true;
        this.moveAlphaSlider(e);
    };
    AgColorPanel.prototype.onSpectrumAlphaMove = function (e) {
        if (this.isSpectrumAlphaDragging) {
            this.moveAlphaSlider(e);
        }
    };
    AgColorPanel.prototype.onMouseUp = function () {
        this.isSpectrumDragging = false;
        this.isSpectrumHueDragging = false;
        this.isSpectrumAlphaDragging = false;
    };
    AgColorPanel.prototype.moveDragger = function (e) {
        var valRect = this.spectrumValRect;
        if (!valRect) {
            return;
        }
        var x;
        var y;
        if (e instanceof MouseEvent) {
            x = e.clientX - valRect.left;
            y = e.clientY - valRect.top;
        }
        else {
            var isLeft = e.key === KeyCode.LEFT;
            var isRight = e.key === KeyCode.RIGHT;
            var isUp = e.key === KeyCode.UP;
            var isDown = e.key === KeyCode.DOWN;
            var isVertical = isUp || isDown;
            var isHorizontal = isLeft || isRight;
            if (!isVertical && !isHorizontal) {
                return;
            }
            e.preventDefault();
            var _a = this.getSpectrumValue(), currentX = _a.x, currentY = _a.y;
            x = currentX + (isHorizontal ? (isLeft ? -5 : 5) : 0);
            y = currentY + (isVertical ? (isUp ? -5 : 5) : 0);
        }
        x = Math.max(x, 0);
        x = Math.min(x, valRect.width);
        y = Math.max(y, 0);
        y = Math.min(y, valRect.height);
        this.setSpectrumValue(x / valRect.width, 1 - y / valRect.height);
    };
    AgColorPanel.prototype.moveHueSlider = function (e) {
        var rect = this.spectrumHueRect;
        if (!rect) {
            return;
        }
        var x = this.moveSlider(this.spectrumHueSlider, e);
        if (x == null) {
            return;
        }
        this.H = 1 - x / rect.width;
        this.update();
    };
    AgColorPanel.prototype.moveAlphaSlider = function (e) {
        var rect = this.spectrumAlphaRect;
        if (!rect) {
            return;
        }
        var x = this.moveSlider(this.spectrumAlphaSlider, e);
        if (x == null) {
            return;
        }
        this.A = x / rect.width;
        this.update();
    };
    AgColorPanel.prototype.moveSlider = function (slider, e) {
        var _a;
        var sliderRect = slider.getBoundingClientRect();
        var parentRect = (_a = slider.parentElement) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        if (!slider || !parentRect) {
            return null;
        }
        var x;
        if (e instanceof MouseEvent) {
            x = e.clientX - parentRect.left;
        }
        else {
            var isLeft = e.key === KeyCode.LEFT;
            var isRight = e.key === KeyCode.RIGHT;
            if (!isLeft && !isRight) {
                return null;
            }
            e.preventDefault();
            var diff = isLeft ? -5 : 5;
            x = (parseFloat(slider.style.left) - sliderRect.width / 2) + diff;
        }
        x = Math.max(x, 0);
        x = Math.min(x, parentRect.width);
        slider.style.left = (x + sliderRect.width / 2) + 'px';
        return x;
    };
    AgColorPanel.prototype.update = function () {
        var color = sparklines_util_exports$1.Color.fromHSB(this.H * 360, this.S, this.B, this.A);
        var spectrumColor = sparklines_util_exports$1.Color.fromHSB(this.H * 360, 1, 1);
        var rgbaColor = color.toRgbaString();
        // the recent color list needs to know color has actually changed
        var colorPicker = this.picker;
        var existingColor = sparklines_util_exports$1.Color.fromString(colorPicker.getValue());
        if (existingColor.toRgbaString() !== rgbaColor) {
            this.colorChanged = true;
        }
        colorPicker.setValue(rgbaColor);
        this.spectrumColor.style.backgroundColor = spectrumColor.toRgbaString();
        this.spectrumDragger.style.backgroundColor = rgbaColor;
    };
    /**
     * @param saturation In the [0, 1] interval.
     * @param brightness In the [0, 1] interval.
     */
    AgColorPanel.prototype.setSpectrumValue = function (saturation, brightness) {
        var valRect = this.spectrumValRect || this.refreshSpectrumRect();
        if (valRect == null) {
            return;
        }
        var dragger = this.spectrumDragger;
        var draggerRect = dragger.getBoundingClientRect();
        saturation = Math.max(0, saturation);
        saturation = Math.min(1, saturation);
        brightness = Math.max(0, brightness);
        brightness = Math.min(1, brightness);
        this.S = saturation;
        this.B = brightness;
        dragger.style.left = (saturation * valRect.width - draggerRect.width / 2) + 'px';
        dragger.style.top = ((1 - brightness) * valRect.height - draggerRect.height / 2) + 'px';
        this.update();
    };
    AgColorPanel.prototype.getSpectrumValue = function () {
        var dragger = this.spectrumDragger;
        var draggerRect = dragger.getBoundingClientRect();
        var x = parseFloat(dragger.style.left) + draggerRect.width / 2;
        var y = parseFloat(dragger.style.top) + draggerRect.height / 2;
        return { x: x, y: y };
    };
    AgColorPanel.prototype.initRecentColors = function () {
        var _this = this;
        var recentColors = AgColorPanel.recentColors;
        var innerHtml = recentColors.map(function (color, index) {
            return ( /* html */"<div class=\"ag-recent-color\" id=".concat(index, " style=\"background-color: ").concat(color, "; width: 15px; height: 15px;\" recent-color=\"").concat(color, "\" tabIndex=\"").concat(_this.tabIndex, "\"></div>"));
        });
        this.recentColors.innerHTML = innerHtml.join('');
    };
    AgColorPanel.prototype.setValue = function (val) {
        var color = sparklines_util_exports$1.Color.fromString(val);
        var _a = __read$a(color.toHSB(), 3), h = _a[0], s = _a[1], b = _a[2];
        this.H = (isNaN(h) ? 0 : h) / 360;
        this.A = color.a;
        var spectrumHueRect = this.spectrumHueRect || this.refreshHueRect();
        var spectrumAlphaRect = this.spectrumAlphaRect || this.refreshAlphaRect();
        this.spectrumHueSlider.style.left = "".concat(((this.H - 1) * -spectrumHueRect.width), "px");
        this.spectrumAlphaSlider.style.left = "".concat((this.A * spectrumAlphaRect.width), "px");
        this.setSpectrumValue(s, b);
    };
    AgColorPanel.prototype.onRecentColorClick = function (e) {
        var target = e.target;
        if (!_.exists(target.id)) {
            return;
        }
        var id = parseInt(target.id, 10);
        this.setValue(AgColorPanel.recentColors[id]);
        this.destroy();
    };
    AgColorPanel.prototype.addRecentColor = function () {
        var color = sparklines_util_exports$1.Color.fromHSB(this.H * 360, this.S, this.B, this.A);
        var rgbaColor = color.toRgbaString();
        var recentColors = AgColorPanel.recentColors;
        if (!this.colorChanged || recentColors[0] === rgbaColor) {
            return;
        }
        // remove duplicate color
        recentColors = recentColors.filter(function (currentColor) { return currentColor != rgbaColor; });
        // add color to head
        recentColors = [rgbaColor].concat(recentColors);
        // ensure we don't exceed max number of recent colors
        if (recentColors.length > AgColorPanel.maxRecentColors) {
            recentColors = recentColors.slice(0, AgColorPanel.maxRecentColors);
        }
        AgColorPanel.recentColors = recentColors;
    };
    AgColorPanel.prototype.destroy = function () {
        this.addRecentColor();
        _super.prototype.destroy.call(this);
    };
    AgColorPanel.maxRecentColors = 8;
    AgColorPanel.recentColors = [];
    AgColorPanel.TEMPLATE = "<div class=\"ag-color-panel\" tabindex=\"-1\">\n            <div ref=\"spectrumColor\" class=\"ag-spectrum-color\">\n                <div class=\"ag-spectrum-sat ag-spectrum-fill\">\n                    <div ref=\"spectrumVal\" class=\"ag-spectrum-val ag-spectrum-fill\">\n                        <div ref=\"spectrumDragger\" class=\"ag-spectrum-dragger\"></div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"ag-spectrum-tools\">\n                <div ref=\"spectrumHue\" class=\"ag-spectrum-hue ag-spectrum-tool\">\n                    <div class=\"ag-spectrum-hue-background\"></div>\n                    <div ref=\"spectrumHueSlider\" class=\"ag-spectrum-slider\"></div>\n                </div>\n                <div ref=\"spectrumAlpha\" class=\"ag-spectrum-alpha ag-spectrum-tool\">\n                    <div class=\"ag-spectrum-alpha-background\"></div>\n                    <div ref=\"spectrumAlphaSlider\" class=\"ag-spectrum-slider\"></div>\n                </div>\n                <div ref=\"recentColors\" class=\"ag-recent-colors\"></div>\n            </div>\n        </div>";
    __decorate$E([
        RefSelector('spectrumColor')
    ], AgColorPanel.prototype, "spectrumColor", void 0);
    __decorate$E([
        RefSelector('spectrumVal')
    ], AgColorPanel.prototype, "spectrumVal", void 0);
    __decorate$E([
        RefSelector('spectrumDragger')
    ], AgColorPanel.prototype, "spectrumDragger", void 0);
    __decorate$E([
        RefSelector('spectrumHue')
    ], AgColorPanel.prototype, "spectrumHue", void 0);
    __decorate$E([
        RefSelector('spectrumHueSlider')
    ], AgColorPanel.prototype, "spectrumHueSlider", void 0);
    __decorate$E([
        RefSelector('spectrumAlpha')
    ], AgColorPanel.prototype, "spectrumAlpha", void 0);
    __decorate$E([
        RefSelector('spectrumAlphaSlider')
    ], AgColorPanel.prototype, "spectrumAlphaSlider", void 0);
    __decorate$E([
        RefSelector('recentColors')
    ], AgColorPanel.prototype, "recentColors", void 0);
    __decorate$E([
        PostConstruct
    ], AgColorPanel.prototype, "postConstruct", null);
    return AgColorPanel;
}(Component));

var __extends$H = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$9 = (undefined && undefined.__assign) || function () {
    __assign$9 = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$9.apply(this, arguments);
};
var AgColorPicker = /** @class */ (function (_super) {
    __extends$H(AgColorPicker, _super);
    function AgColorPicker(config) {
        var _this = _super.call(this, __assign$9({ pickerAriaLabelKey: 'ariaLabelColorPicker', pickerAriaLabelValue: 'Color Picker', pickerType: 'ag-list', className: 'ag-color-picker', pickerIcon: 'colorPicker' }, config)) || this;
        if (config && config.color) {
            _this.value = config.color;
        }
        return _this;
    }
    AgColorPicker.prototype.postConstruct = function () {
        _super.prototype.postConstruct.call(this);
        if (this.value) {
            this.setValue(this.value);
        }
    };
    AgColorPicker.prototype.createPickerComponent = function () {
        var eGuiRect = this.getGui().getBoundingClientRect();
        var colorDialog = this.createBean(new AgDialog({
            closable: false,
            modal: true,
            hideTitleBar: true,
            minWidth: 190,
            width: 190,
            height: 250,
            x: eGuiRect.right - 190,
            y: eGuiRect.top - 250
        }));
        return colorDialog;
    };
    AgColorPicker.prototype.renderAndPositionPicker = function () {
        var _this = this;
        var pickerComponent = this.pickerComponent;
        var colorPanel = this.createBean(new AgColorPanel({ picker: this }));
        pickerComponent.addCssClass('ag-color-dialog');
        colorPanel.addDestroyFunc(function () {
            if (pickerComponent.isAlive()) {
                _this.destroyBean(pickerComponent);
            }
        });
        pickerComponent.setParentComponent(this);
        pickerComponent.setBodyComponent(colorPanel);
        colorPanel.setValue(this.getValue());
        colorPanel.getGui().focus();
        pickerComponent.addDestroyFunc(function () {
            // here we check if the picker was already being
            // destroyed to avoid a stack overflow
            if (!_this.isDestroyingPicker) {
                _this.beforeHidePicker();
                _this.isDestroyingPicker = true;
                if (colorPanel.isAlive()) {
                    _this.destroyBean(colorPanel);
                }
                if (_this.isAlive()) {
                    _this.getFocusableElement().focus();
                }
            }
            else {
                _this.isDestroyingPicker = false;
            }
        });
        return function () { var _a; return (_a = _this.pickerComponent) === null || _a === void 0 ? void 0 : _a.close(); };
    };
    AgColorPicker.prototype.setValue = function (color) {
        if (this.value === color) {
            return this;
        }
        this.eDisplayField.style.backgroundColor = color;
        return _super.prototype.setValue.call(this, color);
    };
    AgColorPicker.prototype.getValue = function () {
        return this.value;
    };
    return AgColorPicker;
}(AgPickerField));

var __read$9 = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var VERSION_CHECKING_FIRST_GRID_MAJOR_VERSION = 28;
var VERSION_CHECKING_FIRST_CHARTS_MAJOR_VERSION = 6;
function isValidVersion(version) {
    return version && version.match(/\d+\.\d+\.\d+/);
}
function isValidMajorVersion(_a) {
    var gridMajorVersion = _a.gridMajorVersion, chartsMajorVersion = _a.chartsMajorVersion;
    var gridMajor = parseInt(gridMajorVersion, 10);
    var chartsMajor = parseInt(chartsMajorVersion, 10);
    var gridMajorDifference = gridMajor - VERSION_CHECKING_FIRST_GRID_MAJOR_VERSION;
    var chartsMajorDifference = chartsMajor - VERSION_CHECKING_FIRST_CHARTS_MAJOR_VERSION;
    var isFirstOrAfterVersion = gridMajorDifference >= 0;
    return gridMajorDifference === chartsMajorDifference && isFirstOrAfterVersion;
}
function gridChartVersion(gridVersion) {
    if (!gridVersion || !isValidVersion(gridVersion)) {
        return undefined;
    }
    var _a = __read$9(gridVersion.split('.') || [], 2), gridMajor = _a[0], gridMinor = _a[1];
    var gridMajorMinor = "".concat(gridMajor, ".").concat(gridMinor, ".x");
    var gridMajorNumber = parseInt(gridMajor, 10);
    var chartsMajor = (gridMajorNumber - VERSION_CHECKING_FIRST_GRID_MAJOR_VERSION) + VERSION_CHECKING_FIRST_CHARTS_MAJOR_VERSION;
    if (chartsMajor < 0) {
        return undefined;
    }
    var chartsMinor = gridMinor;
    var chartsMajorMinor = "".concat(chartsMajor, ".").concat(chartsMinor, ".x");
    return {
        gridMajorMinor: gridMajorMinor,
        chartsMajorMinor: chartsMajorMinor
    };
}
function validGridChartsVersionErrorMessage(_a) {
    var type = _a.type, gridVersion = _a.gridVersion, chartsVersion = _a.chartsVersion;
    var invalidMessage = 'AG Grid: AG Grid version is incompatible. Please see https://www.ag-grid.com/javascript-data-grid/modules/ for more information.';
    if (!gridVersion) {
        return invalidMessage;
    }
    var version = gridChartVersion(gridVersion);
    if (!version) {
        return invalidMessage;
    }
    var gridMajorMinor = version.gridMajorMinor, chartsMajorMinor = version.chartsMajorMinor;
    if (type === 'incompatible') {
        return "AG Grid version ".concat(gridVersion, " and AG Charts version ").concat(chartsVersion, " is not supported. AG Grid version ").concat(gridMajorMinor, " should be used with AG Chart ").concat(chartsMajorMinor, ". Please see https://www.ag-grid.com/javascript-data-grid/modules/ for more information.");
    }
    else if (type === 'invalidCharts') {
        return "AG Grid version ".concat(gridMajorMinor, " should be used with AG Chart ").concat(chartsMajorMinor, ". Please see https://www.ag-grid.com/javascript-data-grid/modules/ for more information.");
    }
    return invalidMessage;
}
function validGridChartsVersion(_a) {
    var gridVersion = _a.gridVersion, chartsVersion = _a.chartsVersion;
    if (!isValidVersion(chartsVersion)) {
        return {
            isValid: false,
            message: validGridChartsVersionErrorMessage({ type: 'invalidCharts', gridVersion: gridVersion, chartsVersion: chartsVersion })
        };
    }
    if (!isValidVersion(gridVersion)) {
        return {
            isValid: false,
            message: validGridChartsVersionErrorMessage({ type: 'invalidGrid', gridVersion: gridVersion, chartsVersion: chartsVersion })
        };
    }
    var _b = __read$9(gridVersion.split('.') || [], 2), gridMajor = _b[0], gridMinor = _b[1];
    var _c = __read$9(chartsVersion.split('.') || [], 2), chartsMajor = _c[0], chartsMinor = _c[1];
    var isValidMajor = isValidMajorVersion({
        gridMajorVersion: gridMajor,
        chartsMajorVersion: chartsMajor
    });
    if (isValidMajor && gridMinor === chartsMinor) {
        return {
            isValid: true
        };
    }
    else if (!isValidMajor || gridMinor !== chartsMinor) {
        return {
            isValid: false,
            message: validGridChartsVersionErrorMessage({ type: 'incompatible', gridVersion: gridVersion, chartsVersion: chartsVersion })
        };
    }
    return {
        isValid: false,
        message: validGridChartsVersionErrorMessage({ type: 'invalid', gridVersion: gridVersion, chartsVersion: chartsVersion })
    };
}

var GridChartsModule = {
    version: VERSION$a,
    validate: function () {
        return validGridChartsVersion({
            gridVersion: VERSION$a,
            chartsVersion: ChartService.CHARTS_VERSION
        });
    },
    moduleName: ModuleNames.GridChartsModule,
    beans: [
        ChartService, ChartTranslationService, ChartCrossFilterService
    ],
    agStackComponents: [
        { componentName: 'AgColorPicker', componentClass: AgColorPicker },
        { componentName: 'AgAngleSelect', componentClass: AgAngleSelect },
    ],
    dependantModules: [
        RangeSelectionModule,
        EnterpriseCoreModule
    ]
};

/**
 * Internal Use Only: Used to ensure this file is treated as a module until we can use moduleDetection flag in Ts v4.7
 */
var __FORCE_MODULE_DETECTION_AG_GRID_CORE_EXT = 0;

// import { time, AgEnterpriseCharts } from "ag-charts-enterprise";
var agCharts = {
    time: time_exports$1,
    AgChart: AgChart
    // AgChart: AgEnterpriseCharts
};

var __extends$G = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$8 = (undefined && undefined.__assign) || function () {
    __assign$8 = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$8.apply(this, arguments);
};
var __decorate$D = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var DetailCellRendererCtrl = /** @class */ (function (_super) {
    __extends$G(DetailCellRendererCtrl, _super);
    function DetailCellRendererCtrl() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loadRowDataVersion = 0;
        return _this;
    }
    DetailCellRendererCtrl.prototype.init = function (comp, params) {
        this.params = params;
        this.comp = comp;
        var doNothingBecauseInsidePinnedSection = params.pinned != null;
        if (doNothingBecauseInsidePinnedSection) {
            return;
        }
        this.setAutoHeightClasses();
        this.setupRefreshStrategy();
        this.addThemeToDetailGrid();
        this.createDetailGrid();
        this.loadRowData();
        this.addManagedListener(this.eventService, Events.EVENT_FULL_WIDTH_ROW_FOCUSED, this.onFullWidthRowFocused.bind(this));
    };
    DetailCellRendererCtrl.prototype.onFullWidthRowFocused = function (e) {
        var params = this.params;
        var row = { rowIndex: params.node.rowIndex, rowPinned: params.node.rowPinned };
        var eventRow = { rowIndex: e.rowIndex, rowPinned: e.rowPinned };
        var isSameRow = this.rowPositionUtils.sameRow(row, eventRow);
        if (!isSameRow) {
            return;
        }
        this.focusService.focusInto(this.comp.getGui(), e.fromBelow);
    };
    DetailCellRendererCtrl.prototype.setAutoHeightClasses = function () {
        var autoHeight = this.gridOptionsService.get('detailRowAutoHeight');
        var parentClass = autoHeight ? 'ag-details-row-auto-height' : 'ag-details-row-fixed-height';
        var detailClass = autoHeight ? 'ag-details-grid-auto-height' : 'ag-details-grid-fixed-height';
        this.comp.addOrRemoveCssClass(parentClass, true);
        this.comp.addOrRemoveDetailGridCssClass(detailClass, true);
    };
    DetailCellRendererCtrl.prototype.setupRefreshStrategy = function () {
        var providedStrategy = this.params.refreshStrategy;
        var validSelection = providedStrategy == 'everything' || providedStrategy == 'nothing' || providedStrategy == 'rows';
        if (validSelection) {
            this.refreshStrategy = providedStrategy;
            return;
        }
        if (providedStrategy != null) {
            console.warn("AG Grid: invalid cellRendererParams.refreshStrategy = '" + providedStrategy +
                "' supplied, defaulting to refreshStrategy = 'rows'.");
        }
        this.refreshStrategy = 'rows';
    };
    DetailCellRendererCtrl.prototype.addThemeToDetailGrid = function () {
        // this is needed by environment service of the child grid, the class needs to be on
        // the grid div itself - the browser's CSS on the other hand just inherits from the parent grid theme.
        var theme = this.environment.getTheme().theme;
        if (theme) {
            this.comp.addOrRemoveDetailGridCssClass(theme, true);
        }
    };
    DetailCellRendererCtrl.prototype.createDetailGrid = function () {
        if (_.missing(this.params.detailGridOptions)) {
            console.warn('AG Grid: could not find detail grid options for master detail, ' +
                'please set gridOptions.detailCellRendererParams.detailGridOptions');
            return;
        }
        var autoHeight = this.gridOptionsService.get('detailRowAutoHeight');
        // we clone the detail grid options, as otherwise it would be shared
        // across many instances, and that would be a problem because we set
        // api and columnApi into gridOptions
        var gridOptions = __assign$8({}, this.params.detailGridOptions);
        if (autoHeight) {
            gridOptions.domLayout = 'autoHeight';
        }
        this.comp.setDetailGrid(gridOptions);
    };
    DetailCellRendererCtrl.prototype.registerDetailWithMaster = function (api, columnApi) {
        var rowId = this.params.node.id;
        var masterGridApi = this.params.api;
        var gridInfo = {
            id: rowId,
            api: api,
            columnApi: columnApi
        };
        var rowNode = this.params.node;
        // register with api
        masterGridApi.addDetailGridInfo(rowId, gridInfo);
        // register with node
        rowNode.detailGridInfo = gridInfo;
        this.addDestroyFunc(function () {
            // the gridInfo can be stale if a refresh happens and
            // a new row is created before the old one is destroyed.
            if (rowNode.detailGridInfo !== gridInfo) {
                return;
            }
            masterGridApi.removeDetailGridInfo(rowId); // unregister from api
            rowNode.detailGridInfo = null; // unregister from node
        });
    };
    DetailCellRendererCtrl.prototype.loadRowData = function () {
        var _this = this;
        var _a, _b, _c;
        // in case a refresh happens before the last refresh completes (as we depend on async
        // application logic) we keep track on what the latest call was.
        this.loadRowDataVersion++;
        var versionThisCall = this.loadRowDataVersion;
        if (((_a = this.params.detailGridOptions) === null || _a === void 0 ? void 0 : _a.rowModelType) === 'serverSide') {
            var node = this.params.node;
            (_c = (_b = node.detailGridInfo) === null || _b === void 0 ? void 0 : _b.api) === null || _c === void 0 ? void 0 : _c.refreshServerSide({ purge: true });
            return;
        }
        var userFunc = this.params.getDetailRowData;
        if (!userFunc) {
            console.warn('AG Grid: could not find getDetailRowData for master / detail, ' +
                'please set gridOptions.detailCellRendererParams.getDetailRowData');
            return;
        }
        var successCallback = function (rowData) {
            var mostRecentCall = _this.loadRowDataVersion === versionThisCall;
            if (mostRecentCall) {
                _this.comp.setRowData(rowData);
            }
        };
        var funcParams = {
            node: this.params.node,
            // we take data from node, rather than params.data
            // as the data could have been updated with new instance
            data: this.params.node.data,
            successCallback: successCallback,
            context: this.gridOptionsService.getGridCommonParams().context
        };
        userFunc(funcParams);
    };
    DetailCellRendererCtrl.prototype.refresh = function () {
        var GET_GRID_TO_REFRESH = false;
        var GET_GRID_TO_DO_NOTHING = true;
        switch (this.refreshStrategy) {
            // ignore this refresh, make grid think we've refreshed but do nothing
            case 'nothing': return GET_GRID_TO_DO_NOTHING;
            // grid will destroy and recreate the cell
            case 'everything': return GET_GRID_TO_REFRESH;
        }
        // do the refresh here, and tell the grid to do nothing
        this.loadRowData();
        return GET_GRID_TO_DO_NOTHING;
    };
    __decorate$D([
        Autowired('rowPositionUtils')
    ], DetailCellRendererCtrl.prototype, "rowPositionUtils", void 0);
    __decorate$D([
        Autowired('focusService')
    ], DetailCellRendererCtrl.prototype, "focusService", void 0);
    return DetailCellRendererCtrl;
}(BeanStub));

var __extends$F = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$C = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var DetailCellRenderer = /** @class */ (function (_super) {
    __extends$F(DetailCellRenderer, _super);
    function DetailCellRenderer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DetailCellRenderer.prototype.init = function (params) {
        var _this = this;
        this.params = params;
        this.selectAndSetTemplate();
        var compProxy = {
            addOrRemoveCssClass: function (cssClassName, on) { return _this.addOrRemoveCssClass(cssClassName, on); },
            addOrRemoveDetailGridCssClass: function (cssClassName, on) { return _this.eDetailGrid.classList.toggle(cssClassName, on); },
            setDetailGrid: function (gridOptions) { return _this.setDetailGrid(gridOptions); },
            setRowData: function (rowData) { return _this.setRowData(rowData); },
            getGui: function () { return _this.eDetailGrid; }
        };
        this.ctrl = this.createManagedBean(new DetailCellRendererCtrl());
        this.ctrl.init(compProxy, params);
    };
    DetailCellRenderer.prototype.refresh = function () {
        return this.ctrl && this.ctrl.refresh();
    };
    // this is a user component, and IComponent has "public destroy()" as part of the interface.
    // so we need to override destroy() just to make the method public.
    DetailCellRenderer.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
    };
    DetailCellRenderer.prototype.selectAndSetTemplate = function () {
        var _this = this;
        if (this.params.pinned) {
            this.setTemplate('<div class="ag-details-row"></div>');
            return;
        }
        var setDefaultTemplate = function () {
            _this.setTemplate(DetailCellRenderer.TEMPLATE);
        };
        if (_.missing(this.params.template)) {
            // use default template
            setDefaultTemplate();
        }
        else {
            // use user provided template
            if (typeof this.params.template === 'string') {
                this.setTemplate(this.params.template);
            }
            else if (typeof this.params.template === 'function') {
                var templateFunc = this.params.template;
                var template = templateFunc(this.params);
                this.setTemplate(template);
            }
            else {
                console.warn('AG Grid: detailCellRendererParams.template should be function or string');
                setDefaultTemplate();
            }
        }
        if (this.eDetailGrid == null) {
            console.warn('AG Grid: reference to eDetailGrid was missing from the details template. ' +
                'Please add ref="eDetailGrid" to the template.');
        }
    };
    DetailCellRenderer.prototype.setDetailGrid = function (gridOptions) {
        if (!this.eDetailGrid) {
            return;
        }
        // AG-1715
        // this is only needed when suppressReactUi=true, once we remove the old way
        // of doing react, and Master / Details is all native React, then we
        // can remove this code.
        var agGridReact = this.context.getBean('agGridReact');
        var agGridReactCloned = agGridReact ? _.cloneObject(agGridReact) : undefined;
        // when we create detail grid, the detail grid needs frameworkComponentWrapper so that
        // it created child components correctly, ie  Angular detail grid can have Angular cell renderer.
        // this is only used by Angular and Vue, as React uses native React AG Grid detail grids
        var frameworkComponentWrapper = this.context.getBean('frameworkComponentWrapper');
        var frameworkOverrides = this.getFrameworkOverrides();
        var api = createGrid(this.eDetailGrid, gridOptions, {
            frameworkOverrides: frameworkOverrides,
            providedBeanInstances: {
                agGridReact: agGridReactCloned,
                frameworkComponentWrapper: frameworkComponentWrapper,
            },
            modules: ModuleRegistry.__getGridRegisteredModules(this.params.api.getGridId()),
        });
        this.detailApi = api;
        this.ctrl.registerDetailWithMaster(api, new ColumnApi(api));
        this.addDestroyFunc(function () {
            api === null || api === void 0 ? void 0 : api.destroy();
        });
    };
    DetailCellRenderer.prototype.setRowData = function (rowData) {
        // ensure detail grid api still exists (grid may be destroyed when async call tries to set data)
        this.detailApi && this.detailApi.setGridOption('rowData', rowData);
    };
    DetailCellRenderer.TEMPLATE = "<div class=\"ag-details-row\" role=\"gridcell\">\n            <div ref=\"eDetailGrid\" class=\"ag-details-grid\" role=\"presentation\"></div>\n        </div>";
    __decorate$C([
        RefSelector('eDetailGrid')
    ], DetailCellRenderer.prototype, "eDetailGrid", void 0);
    return DetailCellRenderer;
}(Component));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$8 = '31.0.2';

var MasterDetailModule = {
    version: VERSION$8,
    moduleName: ModuleNames.MasterDetailModule,
    beans: [],
    userComponents: [
        { componentName: 'agDetailCellRenderer', componentClass: DetailCellRenderer }
    ],
    controllers: [
        { controllerName: 'detailCellRenderer', controllerClass: DetailCellRendererCtrl }
    ],
    dependantModules: [
        EnterpriseCoreModule
    ]
};

var __extends$E = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$B = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var EnterpriseMenuFactory = /** @class */ (function (_super) {
    __extends$E(EnterpriseMenuFactory, _super);
    function EnterpriseMenuFactory() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EnterpriseMenuFactory.prototype.hideActiveMenu = function () {
        this.destroyBean(this.activeMenu);
    };
    EnterpriseMenuFactory.prototype.showMenuAfterMouseEvent = function (column, mouseEvent, defaultTab) {
        var _this = this;
        this.showMenu(column, function (menu) {
            var ePopup = menu.getGui();
            _this.popupService.positionPopupUnderMouseEvent({
                type: 'columnMenu',
                column: column,
                mouseEvent: mouseEvent,
                ePopup: ePopup
            });
            if (defaultTab) {
                menu.showTab(defaultTab);
            }
        }, 'columnMenu', defaultTab, undefined, mouseEvent.target);
    };
    EnterpriseMenuFactory.prototype.showMenuAfterButtonClick = function (column, eventSource, containerType, defaultTab, restrictToTabs) {
        var _this = this;
        var multiplier = -1;
        var alignSide = 'left';
        if (this.gridOptionsService.get('enableRtl')) {
            multiplier = 1;
            alignSide = 'right';
        }
        this.showMenu(column, function (menu) {
            var ePopup = menu.getGui();
            _this.popupService.positionPopupByComponent({
                type: containerType,
                column: column,
                eventSource: eventSource,
                ePopup: ePopup,
                alignSide: alignSide,
                nudgeX: 9 * multiplier,
                nudgeY: -23,
                position: 'under',
                keepWithinBounds: true,
            });
            if (defaultTab) {
                menu.showTab(defaultTab);
            }
        }, containerType, defaultTab, restrictToTabs, eventSource);
    };
    EnterpriseMenuFactory.prototype.showMenu = function (column, positionCallback, containerType, defaultTab, restrictToTabs, eventSource) {
        var _this = this;
        var _a = this.getMenuParams(column, restrictToTabs, eventSource), menu = _a.menu, eMenuGui = _a.eMenuGui, currentHeaderPosition = _a.currentHeaderPosition, currentColumnIndex = _a.currentColumnIndex, anchorToElement = _a.anchorToElement;
        var closedFuncs = [];
        closedFuncs.push(this.getClosedCallback(column, menu, currentHeaderPosition, currentColumnIndex, eventSource));
        var translate = this.localeService.getLocaleTextFunc();
        // need to show filter before positioning, as only after filter
        // is visible can we find out what the width of it is
        this.popupService.addPopup({
            modal: true,
            eChild: eMenuGui,
            closeOnEsc: true,
            closedCallback: function (e) {
                closedFuncs.forEach(function (f) { return f(e); });
            },
            afterGuiAttached: function (params) { return menu.afterGuiAttached(Object.assign({}, { container: containerType }, params)); },
            // if defaultTab is not present, positionCallback will be called
            // after `showTabBasedOnPreviousSelection` is called.
            positionCallback: !!defaultTab ? function () { return positionCallback(menu); } : undefined,
            ariaLabel: translate('ariaLabelColumnMenu', 'Column Menu')
        });
        if (!defaultTab) {
            menu.showTabBasedOnPreviousSelection();
            // reposition the menu because the method above could load
            // an element that is bigger than enterpriseMenu header.
            positionCallback(menu);
        }
        // if user starts showing / hiding columns, or otherwise move the underlying column
        // for this menu, we want to stop tracking the menu with the column position. otherwise
        // the menu would move as the user is using the columns tab inside the menu.
        var stopAnchoringPromise = this.popupService.setPopupPositionRelatedToElement(eMenuGui, anchorToElement);
        if (stopAnchoringPromise) {
            this.addStopAnchoring(stopAnchoringPromise, column, closedFuncs);
        }
        menu.addEventListener(EnterpriseMenu.EVENT_TAB_SELECTED, function (event) {
            _this.lastSelectedTab = event.key;
        });
        column.setMenuVisible(true, 'contextMenu');
        this.activeMenu = menu;
        menu.addEventListener(BeanStub.EVENT_DESTROYED, function () {
            if (_this.activeMenu === menu) {
                _this.activeMenu = null;
            }
        });
    };
    EnterpriseMenuFactory.prototype.addStopAnchoring = function (stopAnchoringPromise, column, closedFuncsArr) {
        stopAnchoringPromise.then(function (stopAnchoringFunc) {
            column.addEventListener('leftChanged', stopAnchoringFunc);
            column.addEventListener('visibleChanged', stopAnchoringFunc);
            closedFuncsArr.push(function () {
                column.removeEventListener('leftChanged', stopAnchoringFunc);
                column.removeEventListener('visibleChanged', stopAnchoringFunc);
            });
        });
    };
    EnterpriseMenuFactory.prototype.getClosedCallback = function (column, menu, headerPosition, columnIndex, eventSource) {
        var _this = this;
        return function (e) {
            _this.destroyBean(menu);
            column.setMenuVisible(false, 'contextMenu');
            var isKeyboardEvent = e instanceof KeyboardEvent;
            if (!isKeyboardEvent || !eventSource) {
                return;
            }
            var isColumnStillVisible = _this.columnModel.getAllDisplayedColumns().some(function (col) { return col === column; });
            if (isColumnStillVisible && _.isVisible(eventSource)) {
                var focusableEl = _this.focusService.findTabbableParent(eventSource);
                if (focusableEl) {
                    if (column) {
                        _this.headerNavigationService.scrollToColumn(column);
                    }
                    focusableEl.focus();
                }
            }
            // if the focusEl is no longer in the DOM, we try to focus
            // the header that is closest to the previous header position
            else if (headerPosition && columnIndex !== -1) {
                var allColumns = _this.columnModel.getAllDisplayedColumns();
                var columnToFocus = allColumns[columnIndex] || _.last(allColumns);
                if (columnToFocus) {
                    _this.focusService.focusHeaderPosition({
                        headerPosition: {
                            headerRowIndex: headerPosition.headerRowIndex,
                            column: columnToFocus
                        }
                    });
                }
            }
        };
    };
    EnterpriseMenuFactory.prototype.getMenuParams = function (column, restrictToTabs, eventSource) {
        var menu = this.createBean(new EnterpriseMenu(column, this.lastSelectedTab, restrictToTabs));
        return {
            menu: menu,
            eMenuGui: menu.getGui(),
            currentHeaderPosition: this.focusService.getFocusedHeader(),
            currentColumnIndex: this.columnModel.getAllDisplayedColumns().indexOf(column),
            anchorToElement: eventSource || this.ctrlsService.getGridBodyCtrl().getGui()
        };
    };
    EnterpriseMenuFactory.prototype.isMenuEnabled = function (column) {
        // Determine whether there are any tabs to show in the menu, given that the filter tab may be hidden
        var isFilterDisabled = !this.filterManager.isFilterAllowed(column);
        var tabs = column.getMenuTabs(EnterpriseMenu.TABS_DEFAULT);
        var numActiveTabs = isFilterDisabled && tabs.includes(EnterpriseMenu.TAB_FILTER)
            ? tabs.length - 1
            : tabs.length;
        return numActiveTabs > 0;
    };
    __decorate$B([
        Autowired('popupService')
    ], EnterpriseMenuFactory.prototype, "popupService", void 0);
    __decorate$B([
        Autowired('focusService')
    ], EnterpriseMenuFactory.prototype, "focusService", void 0);
    __decorate$B([
        Autowired('headerNavigationService')
    ], EnterpriseMenuFactory.prototype, "headerNavigationService", void 0);
    __decorate$B([
        Autowired('ctrlsService')
    ], EnterpriseMenuFactory.prototype, "ctrlsService", void 0);
    __decorate$B([
        Autowired('columnModel')
    ], EnterpriseMenuFactory.prototype, "columnModel", void 0);
    __decorate$B([
        Autowired('filterManager')
    ], EnterpriseMenuFactory.prototype, "filterManager", void 0);
    EnterpriseMenuFactory = __decorate$B([
        Bean('menuFactory')
    ], EnterpriseMenuFactory);
    return EnterpriseMenuFactory;
}(BeanStub));
var EnterpriseMenu = /** @class */ (function (_super) {
    __extends$E(EnterpriseMenu, _super);
    function EnterpriseMenu(column, initialSelection, restrictTo) {
        var _this = _super.call(this) || this;
        _this.tabFactories = {};
        _this.includeChecks = {};
        _this.column = column;
        _this.initialSelection = initialSelection;
        _this.tabFactories[EnterpriseMenu.TAB_GENERAL] = _this.createMainPanel.bind(_this);
        _this.tabFactories[EnterpriseMenu.TAB_FILTER] = _this.createFilterPanel.bind(_this);
        _this.tabFactories[EnterpriseMenu.TAB_COLUMNS] = _this.createColumnsPanel.bind(_this);
        _this.includeChecks[EnterpriseMenu.TAB_GENERAL] = function () { return true; };
        _this.includeChecks[EnterpriseMenu.TAB_FILTER] = function () { return _this.filterManager.isFilterAllowed(column); };
        _this.includeChecks[EnterpriseMenu.TAB_COLUMNS] = function () { return true; };
        _this.restrictTo = restrictTo;
        return _this;
    }
    EnterpriseMenu.prototype.init = function () {
        var _this = this;
        var tabs = this.getTabsToCreate().map(function (name) { return _this.createTab(name); });
        this.tabbedLayout = new TabbedLayout({
            items: tabs,
            cssClass: 'ag-menu',
            onActiveItemClicked: this.onHidePopup.bind(this),
            onItemClicked: this.onTabItemClicked.bind(this)
        });
        this.createBean(this.tabbedLayout);
        if (this.mainMenuList) {
            this.mainMenuList.setParentComponent(this.tabbedLayout);
        }
        this.addDestroyFunc(function () { return _this.destroyBean(_this.tabbedLayout); });
    };
    EnterpriseMenu.prototype.getTabsToCreate = function () {
        var _this = this;
        if (this.restrictTo) {
            return this.restrictTo;
        }
        return this.column.getMenuTabs(EnterpriseMenu.TABS_DEFAULT)
            .filter(function (tabName) { return _this.isValidMenuTabItem(tabName); })
            .filter(function (tabName) { return _this.isNotSuppressed(tabName); })
            .filter(function (tabName) { return _this.isModuleLoaded(tabName); });
    };
    EnterpriseMenu.prototype.isModuleLoaded = function (menuTabName) {
        if (menuTabName === EnterpriseMenu.TAB_COLUMNS) {
            return ModuleRegistry.__isRegistered(ModuleNames.ColumnsToolPanelModule, this.context.getGridId());
        }
        return true;
    };
    EnterpriseMenu.prototype.isValidMenuTabItem = function (menuTabName) {
        var isValid = true;
        var itemsToConsider = EnterpriseMenu.TABS_DEFAULT;
        if (this.restrictTo != null) {
            isValid = this.restrictTo.indexOf(menuTabName) > -1;
            itemsToConsider = this.restrictTo;
        }
        isValid = isValid && EnterpriseMenu.TABS_DEFAULT.indexOf(menuTabName) > -1;
        if (!isValid) {
            console.warn("AG Grid: Trying to render an invalid menu item '".concat(menuTabName, "'. Check that your 'menuTabs' contains one of [").concat(itemsToConsider, "]"));
        }
        return isValid;
    };
    EnterpriseMenu.prototype.isNotSuppressed = function (menuTabName) {
        return this.includeChecks[menuTabName]();
    };
    EnterpriseMenu.prototype.createTab = function (name) {
        return this.tabFactories[name]();
    };
    EnterpriseMenu.prototype.showTabBasedOnPreviousSelection = function () {
        // show the tab the user was on last time they had a menu open
        this.showTab(this.initialSelection);
    };
    EnterpriseMenu.prototype.showTab = function (toShow) {
        if (this.tabItemColumns && toShow === EnterpriseMenu.TAB_COLUMNS) {
            this.tabbedLayout.showItem(this.tabItemColumns);
        }
        else if (this.tabItemFilter && toShow === EnterpriseMenu.TAB_FILTER) {
            this.tabbedLayout.showItem(this.tabItemFilter);
        }
        else if (this.tabItemGeneral && toShow === EnterpriseMenu.TAB_GENERAL) {
            this.tabbedLayout.showItem(this.tabItemGeneral);
        }
        else {
            this.tabbedLayout.showFirstItem();
        }
    };
    EnterpriseMenu.prototype.onTabItemClicked = function (event) {
        var key = null;
        switch (event.item) {
            case this.tabItemColumns:
                key = EnterpriseMenu.TAB_COLUMNS;
                break;
            case this.tabItemFilter:
                key = EnterpriseMenu.TAB_FILTER;
                break;
            case this.tabItemGeneral:
                key = EnterpriseMenu.TAB_GENERAL;
                break;
        }
        if (key) {
            this.activateTab(key);
        }
    };
    EnterpriseMenu.prototype.activateTab = function (tab) {
        var ev = {
            type: EnterpriseMenu.EVENT_TAB_SELECTED,
            key: tab
        };
        this.dispatchEvent(ev);
    };
    EnterpriseMenu.prototype.getMenuItems = function () {
        var defaultMenuOptions = this.getDefaultMenuOptions();
        var result;
        var userFunc = this.gridOptionsService.getCallback('getMainMenuItems');
        if (userFunc) {
            result = userFunc({
                column: this.column,
                defaultItems: defaultMenuOptions
            });
        }
        else {
            result = defaultMenuOptions;
        }
        // GUI looks weird when two separators are side by side. this can happen accidentally
        // if we remove items from the menu then two separators can edit up adjacent.
        _.removeRepeatsFromArray(result, EnterpriseMenu.MENU_ITEM_SEPARATOR);
        return result;
    };
    EnterpriseMenu.prototype.getDefaultMenuOptions = function () {
        var result = [];
        var allowPinning = !this.column.getColDef().lockPinned;
        var rowGroupCount = this.columnModel.getRowGroupColumns().length;
        var doingGrouping = rowGroupCount > 0;
        var allowValue = this.column.isAllowValue();
        var allowRowGroup = this.column.isAllowRowGroup();
        var isPrimary = this.column.isPrimary();
        var pivotModeOn = this.columnModel.isPivotMode();
        var isInMemoryRowModel = this.rowModel.getType() === 'clientSide';
        var usingTreeData = this.gridOptionsService.get('treeData');
        var allowValueAgg = 
        // if primary, then only allow aggValue if grouping and it's a value columns
        (isPrimary && doingGrouping && allowValue)
            // secondary columns can always have aggValue, as it means it's a pivot value column
            || !isPrimary;
        if (allowPinning) {
            result.push('pinSubMenu');
        }
        if (allowValueAgg) {
            result.push('valueAggSubMenu');
        }
        if (allowPinning || allowValueAgg) {
            result.push(EnterpriseMenu.MENU_ITEM_SEPARATOR);
        }
        result.push('autoSizeThis');
        result.push('autoSizeAll');
        result.push(EnterpriseMenu.MENU_ITEM_SEPARATOR);
        var showRowGroup = this.column.getColDef().showRowGroup;
        if (showRowGroup) {
            result.push('rowUnGroup');
        }
        else if (allowRowGroup && this.column.isPrimary()) {
            if (this.column.isRowGroupActive()) {
                var groupLocked = this.columnModel.isColumnGroupingLocked(this.column);
                if (!groupLocked) {
                    result.push('rowUnGroup');
                }
            }
            else {
                result.push('rowGroup');
            }
        }
        result.push(EnterpriseMenu.MENU_ITEM_SEPARATOR);
        result.push('resetColumns');
        // only add grouping expand/collapse if grouping in the InMemoryRowModel
        // if pivoting, we only have expandable groups if grouping by 2 or more columns
        // as the lowest level group is not expandable while pivoting.
        // if not pivoting, then any active row group can be expanded.
        var allowExpandAndContract = isInMemoryRowModel && (usingTreeData || rowGroupCount > (pivotModeOn ? 1 : 0));
        if (allowExpandAndContract) {
            result.push('expandAll');
            result.push('contractAll');
        }
        return result;
    };
    EnterpriseMenu.prototype.createMainPanel = function () {
        this.mainMenuList = this.createManagedBean(new AgMenuList());
        var menuItems = this.getMenuItems();
        var menuItemsMapped = this.menuItemMapper.mapWithStockItems(menuItems, this.column);
        this.mainMenuList.addMenuItems(menuItemsMapped);
        this.mainMenuList.addEventListener(AgMenuItemComponent.EVENT_MENU_ITEM_SELECTED, this.onHidePopup.bind(this));
        this.tabItemGeneral = {
            title: _.createIconNoSpan('menu', this.gridOptionsService, this.column),
            titleLabel: EnterpriseMenu.TAB_GENERAL.replace('MenuTab', ''),
            bodyPromise: AgPromise.resolve(this.mainMenuList.getGui()),
            name: EnterpriseMenu.TAB_GENERAL
        };
        return this.tabItemGeneral;
    };
    EnterpriseMenu.prototype.onHidePopup = function (event) {
        var keyboardEvent;
        if (event && event.event && event.event instanceof KeyboardEvent) {
            keyboardEvent = event.event;
        }
        this.hidePopupFunc(keyboardEvent && { keyboardEvent: keyboardEvent });
        // this method only gets called when the menu was closed by selection an option
        // in this case we highlight the cell that was previously highlighted
        var focusedCell = this.focusService.getFocusedCell();
        var eDocument = this.gridOptionsService.getDocument();
        if (eDocument.activeElement === eDocument.body && focusedCell) {
            var rowIndex = focusedCell.rowIndex, rowPinned = focusedCell.rowPinned, column = focusedCell.column;
            this.focusService.setFocusedCell({ rowIndex: rowIndex, column: column, rowPinned: rowPinned, forceBrowserFocus: true, preventScrollOnBrowserFocus: true });
        }
    };
    EnterpriseMenu.prototype.createFilterPanel = function () {
        var filterWrapper = this.filterManager.getOrCreateFilterWrapper(this.column, 'COLUMN_MENU');
        if (!filterWrapper) {
            throw new Error('AG Grid - Unable to instantiate filter');
        }
        var afterFilterAttachedCallback = function (params) {
            if (!(filterWrapper === null || filterWrapper === void 0 ? void 0 : filterWrapper.filterPromise)) {
                return;
            }
            // slightly odd block this - this promise will always have been resolved by the time it gets here, so won't be
            // async (_unless_ in react or similar, but if so why not encountered before now?).
            // I'd suggest a future improvement would be to remove/replace this promise as this block just wont work if it is
            // async and is confusing if you don't have this context
            filterWrapper.filterPromise.then(function (filter) {
                if (filter && filter.afterGuiAttached) {
                    filter.afterGuiAttached(params);
                }
            });
        };
        // see comment above
        var afterDetachedCallback = function () { var _a; return (_a = filterWrapper === null || filterWrapper === void 0 ? void 0 : filterWrapper.filterPromise) === null || _a === void 0 ? void 0 : _a.then(function (filter) { var _a; return (_a = filter === null || filter === void 0 ? void 0 : filter.afterGuiDetached) === null || _a === void 0 ? void 0 : _a.call(filter); }); };
        this.tabItemFilter = {
            title: _.createIconNoSpan('filter', this.gridOptionsService, this.column),
            titleLabel: EnterpriseMenu.TAB_FILTER.replace('MenuTab', ''),
            bodyPromise: filterWrapper === null || filterWrapper === void 0 ? void 0 : filterWrapper.guiPromise,
            afterAttachedCallback: afterFilterAttachedCallback,
            afterDetachedCallback: afterDetachedCallback,
            name: EnterpriseMenu.TAB_FILTER
        };
        return this.tabItemFilter;
    };
    EnterpriseMenu.prototype.createColumnsPanel = function () {
        var eWrapperDiv = document.createElement('div');
        eWrapperDiv.classList.add('ag-menu-column-select-wrapper');
        var columnSelectPanel = this.createManagedBean(new PrimaryColsPanel());
        var columnsMenuParams = this.column.getColDef().columnsMenuParams;
        if (!columnsMenuParams) {
            columnsMenuParams = {};
        }
        var contractColumnSelection = columnsMenuParams.contractColumnSelection, suppressColumnExpandAll = columnsMenuParams.suppressColumnExpandAll, suppressColumnFilter = columnsMenuParams.suppressColumnFilter, suppressColumnSelectAll = columnsMenuParams.suppressColumnSelectAll, suppressSyncLayoutWithGrid = columnsMenuParams.suppressSyncLayoutWithGrid, columnLayout = columnsMenuParams.columnLayout;
        columnSelectPanel.init(false, this.gridOptionsService.addGridCommonParams({
            suppressColumnMove: false,
            suppressValues: false,
            suppressPivots: false,
            suppressRowGroups: false,
            suppressPivotMode: false,
            contractColumnSelection: !!contractColumnSelection,
            suppressColumnExpandAll: !!suppressColumnExpandAll,
            suppressColumnFilter: !!suppressColumnFilter,
            suppressColumnSelectAll: !!suppressColumnSelectAll,
            suppressSyncLayoutWithGrid: !!columnLayout || !!suppressSyncLayoutWithGrid,
            onStateUpdated: function () { }
        }), 'columnMenu');
        if (columnLayout) {
            columnSelectPanel.setColumnLayout(columnLayout);
        }
        var columnSelectPanelGui = columnSelectPanel.getGui();
        columnSelectPanelGui.classList.add('ag-menu-column-select');
        eWrapperDiv.appendChild(columnSelectPanelGui);
        this.tabItemColumns = {
            title: _.createIconNoSpan('columns', this.gridOptionsService, this.column),
            titleLabel: EnterpriseMenu.TAB_COLUMNS.replace('MenuTab', ''),
            bodyPromise: AgPromise.resolve(eWrapperDiv),
            name: EnterpriseMenu.TAB_COLUMNS
        };
        return this.tabItemColumns;
    };
    EnterpriseMenu.prototype.afterGuiAttached = function (params) {
        var container = params.container, hidePopup = params.hidePopup;
        this.tabbedLayout.setAfterAttachedParams({ container: container, hidePopup: hidePopup });
        if (hidePopup) {
            this.hidePopupFunc = hidePopup;
            this.addDestroyFunc(hidePopup);
        }
    };
    EnterpriseMenu.prototype.getGui = function () {
        return this.tabbedLayout.getGui();
    };
    EnterpriseMenu.EVENT_TAB_SELECTED = 'tabSelected';
    EnterpriseMenu.TAB_FILTER = 'filterMenuTab';
    EnterpriseMenu.TAB_GENERAL = 'generalMenuTab';
    EnterpriseMenu.TAB_COLUMNS = 'columnsMenuTab';
    EnterpriseMenu.TABS_DEFAULT = [EnterpriseMenu.TAB_GENERAL, EnterpriseMenu.TAB_FILTER, EnterpriseMenu.TAB_COLUMNS];
    EnterpriseMenu.MENU_ITEM_SEPARATOR = 'separator';
    __decorate$B([
        Autowired('columnModel')
    ], EnterpriseMenu.prototype, "columnModel", void 0);
    __decorate$B([
        Autowired('filterManager')
    ], EnterpriseMenu.prototype, "filterManager", void 0);
    __decorate$B([
        Autowired('menuItemMapper')
    ], EnterpriseMenu.prototype, "menuItemMapper", void 0);
    __decorate$B([
        Autowired('rowModel')
    ], EnterpriseMenu.prototype, "rowModel", void 0);
    __decorate$B([
        Autowired('focusService')
    ], EnterpriseMenu.prototype, "focusService", void 0);
    __decorate$B([
        PostConstruct
    ], EnterpriseMenu.prototype, "init", null);
    return EnterpriseMenu;
}(BeanStub));

var __extends$D = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$7 = (undefined && undefined.__assign) || function () {
    __assign$7 = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$7.apply(this, arguments);
};
var __decorate$A = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var CSS_MENU = 'ag-menu';
var CSS_CONTEXT_MENU_OPEN = 'ag-context-menu-open';
var ContextMenuFactory = /** @class */ (function (_super) {
    __extends$D(ContextMenuFactory, _super);
    function ContextMenuFactory() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ContextMenuFactory.prototype.hideActiveMenu = function () {
        this.destroyBean(this.activeMenu);
    };
    ContextMenuFactory.prototype.getMenuItems = function (node, column, value) {
        var defaultMenuOptions = [];
        if (_.exists(node) && ModuleRegistry.__isRegistered(ModuleNames.ClipboardModule, this.context.getGridId())) {
            if (column) {
                // only makes sense if column exists, could have originated from a row
                if (!this.gridOptionsService.get('suppressCutToClipboard')) {
                    defaultMenuOptions.push('cut');
                }
                defaultMenuOptions.push('copy', 'copyWithHeaders', 'copyWithGroupHeaders', 'paste', 'separator');
            }
        }
        if (this.gridOptionsService.get('enableCharts') && ModuleRegistry.__isRegistered(ModuleNames.GridChartsModule, this.context.getGridId())) {
            if (this.columnModel.isPivotMode()) {
                defaultMenuOptions.push('pivotChart');
            }
            if (this.rangeService && !this.rangeService.isEmpty()) {
                defaultMenuOptions.push('chartRange');
            }
        }
        if (_.exists(node)) {
            // if user clicks a cell
            var csvModuleMissing = !ModuleRegistry.__isRegistered(ModuleNames.CsvExportModule, this.context.getGridId());
            var excelModuleMissing = !ModuleRegistry.__isRegistered(ModuleNames.ExcelExportModule, this.context.getGridId());
            var suppressExcel = this.gridOptionsService.get('suppressExcelExport') || excelModuleMissing;
            var suppressCsv = this.gridOptionsService.get('suppressCsvExport') || csvModuleMissing;
            var onIPad = _.isIOSUserAgent();
            var anyExport = !onIPad && (!suppressExcel || !suppressCsv);
            if (anyExport) {
                defaultMenuOptions.push('export');
            }
        }
        var userFunc = this.gridOptionsService.getCallback('getContextMenuItems');
        if (userFunc) {
            var params = {
                node: node,
                column: column,
                value: value,
                defaultItems: defaultMenuOptions.length ? defaultMenuOptions : undefined,
            };
            return userFunc(params);
        }
        return defaultMenuOptions;
    };
    ContextMenuFactory.prototype.onContextMenu = function (mouseEvent, touchEvent, rowNode, column, value, anchorToElement) {
        // to allow us to debug in chrome, we ignore the event if ctrl is pressed.
        // not everyone wants this, so first 'if' below allows to turn this hack off.
        if (!this.gridOptionsService.get('allowContextMenuWithControlKey')) {
            // then do the check
            if (mouseEvent && (mouseEvent.ctrlKey || mouseEvent.metaKey)) {
                return;
            }
        }
        // need to do this regardless of context menu showing or not, so doing
        // before the isSuppressContextMenu() check
        if (mouseEvent) {
            this.blockMiddleClickScrollsIfNeeded(mouseEvent);
        }
        if (this.gridOptionsService.get('suppressContextMenu')) {
            return;
        }
        var eventOrTouch = mouseEvent ? mouseEvent : touchEvent.touches[0];
        if (this.showMenu(rowNode, column, value, eventOrTouch, anchorToElement)) {
            var event_1 = mouseEvent ? mouseEvent : touchEvent;
            event_1.preventDefault();
        }
    };
    ContextMenuFactory.prototype.blockMiddleClickScrollsIfNeeded = function (mouseEvent) {
        // if we don't do this, then middle click will never result in a 'click' event, as 'mousedown'
        // will be consumed by the browser to mean 'scroll' (as you can scroll with the middle mouse
        // button in the browser). so this property allows the user to receive middle button clicks if
        // they want.
        var gridOptionsService = this.gridOptionsService;
        var which = mouseEvent.which;
        if (gridOptionsService.get('suppressMiddleClickScrolls') && which === 2) {
            mouseEvent.preventDefault();
        }
    };
    ContextMenuFactory.prototype.showMenu = function (node, column, value, mouseEvent, anchorToElement) {
        var _this = this;
        var menuItems = this.getMenuItems(node, column, value);
        var eGridBodyGui = this.ctrlsService.getGridBodyCtrl().getGui();
        if (menuItems === undefined || _.missingOrEmpty(menuItems)) {
            return false;
        }
        var menu = new ContextMenu(menuItems);
        this.createBean(menu);
        var eMenuGui = menu.getGui();
        var positionParams = {
            column: column,
            rowNode: node,
            type: 'contextMenu',
            mouseEvent: mouseEvent,
            ePopup: eMenuGui,
            // move one pixel away so that accidentally double clicking
            // won't show the browser's contextmenu
            nudgeY: 1
        };
        var translate = this.localeService.getLocaleTextFunc();
        var addPopupRes = this.popupService.addPopup({
            modal: true,
            eChild: eMenuGui,
            closeOnEsc: true,
            closedCallback: function () {
                eGridBodyGui.classList.remove(CSS_CONTEXT_MENU_OPEN);
                _this.destroyBean(menu);
            },
            click: mouseEvent,
            positionCallback: function () {
                var isRtl = _this.gridOptionsService.get('enableRtl');
                _this.popupService.positionPopupUnderMouseEvent(__assign$7(__assign$7({}, positionParams), { nudgeX: isRtl ? (eMenuGui.offsetWidth + 1) * -1 : 1 }));
            },
            // so when browser is scrolled down, or grid is scrolled, context menu stays with cell
            anchorToElement: anchorToElement,
            ariaLabel: translate('ariaLabelContextMenu', 'Context Menu')
        });
        if (addPopupRes) {
            eGridBodyGui.classList.add(CSS_CONTEXT_MENU_OPEN);
            menu.afterGuiAttached({ container: 'contextMenu', hidePopup: addPopupRes.hideFunc });
        }
        // there should never be an active menu at this point, however it was found
        // that you could right click a second time just 1 or 2 pixels from the first
        // click, and another menu would pop up. so somehow the logic for closing the
        // first menu (clicking outside should close it) was glitchy somehow. an easy
        // way to avoid this is just remove the old context menu here if it exists.
        if (this.activeMenu) {
            this.hideActiveMenu();
        }
        this.activeMenu = menu;
        menu.addEventListener(BeanStub.EVENT_DESTROYED, function () {
            if (_this.activeMenu === menu) {
                _this.activeMenu = null;
            }
        });
        // hide the popup if something gets selected
        if (addPopupRes) {
            menu.addEventListener(AgMenuItemComponent.EVENT_MENU_ITEM_SELECTED, addPopupRes.hideFunc);
        }
        return true;
    };
    __decorate$A([
        Autowired('popupService')
    ], ContextMenuFactory.prototype, "popupService", void 0);
    __decorate$A([
        Optional('rangeService')
    ], ContextMenuFactory.prototype, "rangeService", void 0);
    __decorate$A([
        Autowired('ctrlsService')
    ], ContextMenuFactory.prototype, "ctrlsService", void 0);
    __decorate$A([
        Autowired('columnModel')
    ], ContextMenuFactory.prototype, "columnModel", void 0);
    ContextMenuFactory = __decorate$A([
        Bean('contextMenuFactory')
    ], ContextMenuFactory);
    return ContextMenuFactory;
}(BeanStub));
var ContextMenu = /** @class */ (function (_super) {
    __extends$D(ContextMenu, _super);
    function ContextMenu(menuItems) {
        var _this = _super.call(this, /* html */ "<div class=\"".concat(CSS_MENU, "\" role=\"presentation\"></div>")) || this;
        _this.menuList = null;
        _this.focusedCell = null;
        _this.menuItems = menuItems;
        return _this;
    }
    ContextMenu.prototype.addMenuItems = function () {
        var _this = this;
        var menuList = this.createManagedBean(new AgMenuList());
        var menuItemsMapped = this.menuItemMapper.mapWithStockItems(this.menuItems, null);
        menuList.addMenuItems(menuItemsMapped);
        this.appendChild(menuList);
        this.menuList = menuList;
        menuList.addEventListener(AgMenuItemComponent.EVENT_MENU_ITEM_SELECTED, function (e) { return _this.dispatchEvent(e); });
    };
    ContextMenu.prototype.afterGuiAttached = function (params) {
        if (params.hidePopup) {
            this.addDestroyFunc(params.hidePopup);
        }
        this.focusedCell = this.focusService.getFocusedCell();
        if (this.menuList) {
            this.focusService.focusInto(this.menuList.getGui());
        }
    };
    ContextMenu.prototype.restoreFocusedCell = function () {
        var currentFocusedCell = this.focusService.getFocusedCell();
        if (currentFocusedCell && this.focusedCell && this.cellPositionUtils.equals(currentFocusedCell, this.focusedCell)) {
            var _a = this.focusedCell, rowIndex = _a.rowIndex, rowPinned = _a.rowPinned, column = _a.column;
            var doc = this.gridOptionsService.getDocument();
            if (doc.activeElement === doc.body) {
                this.focusService.setFocusedCell({ rowIndex: rowIndex, column: column, rowPinned: rowPinned, forceBrowserFocus: true });
            }
        }
    };
    ContextMenu.prototype.destroy = function () {
        this.restoreFocusedCell();
        _super.prototype.destroy.call(this);
    };
    __decorate$A([
        Autowired('menuItemMapper')
    ], ContextMenu.prototype, "menuItemMapper", void 0);
    __decorate$A([
        Autowired('focusService')
    ], ContextMenu.prototype, "focusService", void 0);
    __decorate$A([
        Autowired('cellPositionUtils')
    ], ContextMenu.prototype, "cellPositionUtils", void 0);
    __decorate$A([
        PostConstruct
    ], ContextMenu.prototype, "addMenuItems", null);
    return ContextMenu;
}(Component));

var __extends$C = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$6 = (undefined && undefined.__assign) || function () {
    __assign$6 = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$6.apply(this, arguments);
};
var __decorate$z = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var MenuItemMapper = /** @class */ (function (_super) {
    __extends$C(MenuItemMapper, _super);
    function MenuItemMapper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MenuItemMapper.prototype.mapWithStockItems = function (originalList, column) {
        var _this = this;
        if (!originalList) {
            return [];
        }
        var resultList = [];
        originalList.forEach(function (menuItemOrString) {
            var result;
            if (typeof menuItemOrString === 'string') {
                result = _this.getStockMenuItem(menuItemOrString, column);
            }
            else {
                // Spread to prevent leaking mapped subMenus back into the original menuItem
                result = __assign$6({}, menuItemOrString);
            }
            // if no mapping, can happen when module is not loaded but user tries to use module anyway
            if (!result) {
                return;
            }
            var resultDef = result;
            var subMenu = resultDef.subMenu;
            if (subMenu && subMenu instanceof Array) {
                resultDef.subMenu = _this.mapWithStockItems(subMenu, column);
            }
            if (result != null) {
                resultList.push(result);
            }
        });
        return resultList;
    };
    MenuItemMapper.prototype.getStockMenuItem = function (key, column) {
        var _this = this;
        var _a;
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        var skipHeaderOnAutoSize = this.gridOptionsService.get('skipHeaderOnAutoSize');
        switch (key) {
            case 'pinSubMenu':
                return {
                    name: localeTextFunc('pinColumn', 'Pin Column'),
                    icon: _.createIconNoSpan('menuPin', this.gridOptionsService, null),
                    subMenu: ['clearPinned', 'pinLeft', 'pinRight']
                };
            case 'pinLeft':
                return {
                    name: localeTextFunc('pinLeft', 'Pin Left'),
                    action: function () { return _this.columnModel.setColumnPinned(column, 'left', "contextMenu"); },
                    checked: !!column && column.isPinnedLeft()
                };
            case 'pinRight':
                return {
                    name: localeTextFunc('pinRight', 'Pin Right'),
                    action: function () { return _this.columnModel.setColumnPinned(column, 'right', "contextMenu"); },
                    checked: !!column && column.isPinnedRight()
                };
            case 'clearPinned':
                return {
                    name: localeTextFunc('noPin', 'No Pin'),
                    action: function () { return _this.columnModel.setColumnPinned(column, null, "contextMenu"); },
                    checked: !!column && !column.isPinned()
                };
            case 'valueAggSubMenu':
                if (ModuleRegistry.__assertRegistered(ModuleNames.RowGroupingModule, 'Aggregation from Menu', this.context.getGridId())) {
                    if (!(column === null || column === void 0 ? void 0 : column.isPrimary()) && !(column === null || column === void 0 ? void 0 : column.getColDef().pivotValueColumn)) {
                        return null;
                    }
                    return {
                        name: localeTextFunc('valueAggregation', 'Value Aggregation'),
                        icon: _.createIconNoSpan('menuValue', this.gridOptionsService, null),
                        subMenu: this.createAggregationSubMenu(column)
                    };
                }
                else {
                    return null;
                }
            case 'autoSizeThis':
                return {
                    name: localeTextFunc('autosizeThiscolumn', 'Autosize This Column'),
                    action: function () { return _this.columnModel.autoSizeColumn(column, skipHeaderOnAutoSize, "contextMenu"); }
                };
            case 'autoSizeAll':
                return {
                    name: localeTextFunc('autosizeAllColumns', 'Autosize All Columns'),
                    action: function () { return _this.columnModel.autoSizeAllColumns(skipHeaderOnAutoSize, "contextMenu"); }
                };
            case 'rowGroup':
                return {
                    name: localeTextFunc('groupBy', 'Group by') + ' ' + _.escapeString(this.columnModel.getDisplayNameForColumn(column, 'header')),
                    disabled: (column === null || column === void 0 ? void 0 : column.isRowGroupActive()) || !(column === null || column === void 0 ? void 0 : column.getColDef().enableRowGroup),
                    action: function () { return _this.columnModel.addRowGroupColumn(column, "contextMenu"); },
                    icon: _.createIconNoSpan('menuAddRowGroup', this.gridOptionsService, null)
                };
            case 'rowUnGroup':
                var icon = _.createIconNoSpan('menuRemoveRowGroup', this.gridOptionsService, null);
                var showRowGroup_1 = column === null || column === void 0 ? void 0 : column.getColDef().showRowGroup;
                var lockedGroups_1 = this.gridOptionsService.get('groupLockGroupColumns');
                // Handle single auto group column
                if (showRowGroup_1 === true) {
                    return {
                        name: localeTextFunc('ungroupAll', 'Un-Group All'),
                        disabled: lockedGroups_1 === -1 || lockedGroups_1 >= this.columnModel.getRowGroupColumns().length,
                        action: function () { return _this.columnModel.setRowGroupColumns(_this.columnModel.getRowGroupColumns().slice(0, lockedGroups_1), "contextMenu"); },
                        icon: icon
                    };
                }
                // Handle multiple auto group columns
                if (typeof showRowGroup_1 === 'string') {
                    var underlyingColumn = this.columnModel.getPrimaryColumn(showRowGroup_1);
                    var ungroupByName = (underlyingColumn != null) ? _.escapeString(this.columnModel.getDisplayNameForColumn(underlyingColumn, 'header')) : showRowGroup_1;
                    return {
                        name: localeTextFunc('ungroupBy', 'Un-Group by') + ' ' + ungroupByName,
                        disabled: underlyingColumn != null && this.columnModel.isColumnGroupingLocked(underlyingColumn),
                        action: function () { return _this.columnModel.removeRowGroupColumn(showRowGroup_1, "contextMenu"); },
                        icon: icon
                    };
                }
                // Handle primary column
                return {
                    name: localeTextFunc('ungroupBy', 'Un-Group by') + ' ' + _.escapeString(this.columnModel.getDisplayNameForColumn(column, 'header')),
                    disabled: !(column === null || column === void 0 ? void 0 : column.isRowGroupActive()) || !(column === null || column === void 0 ? void 0 : column.getColDef().enableRowGroup) || this.columnModel.isColumnGroupingLocked(column),
                    action: function () { return _this.columnModel.removeRowGroupColumn(column, "contextMenu"); },
                    icon: icon
                };
            case 'resetColumns':
                return {
                    name: localeTextFunc('resetColumns', 'Reset Columns'),
                    action: function () { return _this.columnModel.resetColumnState("contextMenu"); }
                };
            case 'expandAll':
                return {
                    name: localeTextFunc('expandAll', 'Expand All Row Groups'),
                    action: function () { return _this.gridApi.expandAll(); }
                };
            case 'contractAll':
                return {
                    name: localeTextFunc('collapseAll', 'Collapse All Row Groups'),
                    action: function () { return _this.gridApi.collapseAll(); }
                };
            case 'copy':
                if (ModuleRegistry.__assertRegistered(ModuleNames.ClipboardModule, 'Copy from Menu', this.context.getGridId())) {
                    return {
                        name: localeTextFunc('copy', 'Copy'),
                        shortcut: localeTextFunc('ctrlC', 'Ctrl+C'),
                        icon: _.createIconNoSpan('clipboardCopy', this.gridOptionsService, null),
                        action: function () { return _this.clipboardService.copyToClipboard(); }
                    };
                }
                else {
                    return null;
                }
            case 'copyWithHeaders':
                if (ModuleRegistry.__assertRegistered(ModuleNames.ClipboardModule, 'Copy with Headers from Menu', this.context.getGridId())) {
                    return {
                        name: localeTextFunc('copyWithHeaders', 'Copy with Headers'),
                        // shortcut: localeTextFunc('ctrlC','Ctrl+C'),
                        icon: _.createIconNoSpan('clipboardCopy', this.gridOptionsService, null),
                        action: function () { return _this.clipboardService.copyToClipboard({ includeHeaders: true }); }
                    };
                }
                else {
                    return null;
                }
            case 'copyWithGroupHeaders':
                if (ModuleRegistry.__assertRegistered(ModuleNames.ClipboardModule, 'Copy with Group Headers from Menu', this.context.getGridId())) {
                    return {
                        name: localeTextFunc('copyWithGroupHeaders', 'Copy with Group Headers'),
                        // shortcut: localeTextFunc('ctrlC','Ctrl+C'),
                        icon: _.createIconNoSpan('clipboardCopy', this.gridOptionsService, null),
                        action: function () { return _this.clipboardService.copyToClipboard({ includeHeaders: true, includeGroupHeaders: true }); }
                    };
                }
                else {
                    return null;
                }
            case 'cut':
                if (ModuleRegistry.__assertRegistered(ModuleNames.ClipboardModule, 'Cut from Menu', this.context.getGridId())) {
                    var focusedCell = this.focusService.getFocusedCell();
                    var rowNode = focusedCell ? this.rowPositionUtils.getRowNode(focusedCell) : null;
                    var isEditable = rowNode ? focusedCell === null || focusedCell === void 0 ? void 0 : focusedCell.column.isCellEditable(rowNode) : false;
                    return {
                        name: localeTextFunc('cut', 'Cut'),
                        shortcut: localeTextFunc('ctrlX', 'Ctrl+X'),
                        icon: _.createIconNoSpan('clipboardCut', this.gridOptionsService, null),
                        disabled: !isEditable || this.gridOptionsService.get('suppressCutToClipboard'),
                        action: function () { return _this.clipboardService.cutToClipboard(undefined, 'contextMenu'); }
                    };
                }
                else {
                    return null;
                }
            case 'paste':
                if (ModuleRegistry.__assertRegistered(ModuleNames.ClipboardModule, 'Paste from Clipboard', this.context.getGridId())) {
                    return {
                        name: localeTextFunc('paste', 'Paste'),
                        shortcut: localeTextFunc('ctrlV', 'Ctrl+V'),
                        disabled: true,
                        icon: _.createIconNoSpan('clipboardPaste', this.gridOptionsService, null),
                        action: function () { return _this.clipboardService.pasteFromClipboard(); }
                    };
                }
                else {
                    return null;
                }
            case 'export':
                var exportSubMenuItems = [];
                var csvModuleLoaded = ModuleRegistry.__isRegistered(ModuleNames.CsvExportModule, this.context.getGridId());
                var excelModuleLoaded = ModuleRegistry.__isRegistered(ModuleNames.ExcelExportModule, this.context.getGridId());
                if (!this.gridOptionsService.get('suppressCsvExport') && csvModuleLoaded) {
                    exportSubMenuItems.push('csvExport');
                }
                if (!this.gridOptionsService.get('suppressExcelExport') && excelModuleLoaded) {
                    exportSubMenuItems.push('excelExport');
                }
                return {
                    name: localeTextFunc('export', 'Export'),
                    subMenu: exportSubMenuItems,
                    icon: _.createIconNoSpan('save', this.gridOptionsService, null),
                };
            case 'csvExport':
                return {
                    name: localeTextFunc('csvExport', 'CSV Export'),
                    icon: _.createIconNoSpan('csvExport', this.gridOptionsService, null),
                    action: function () { return _this.gridApi.exportDataAsCsv({}); }
                };
            case 'excelExport':
                return {
                    name: localeTextFunc('excelExport', 'Excel Export'),
                    icon: _.createIconNoSpan('excelExport', this.gridOptionsService, null),
                    action: function () { return _this.gridApi.exportDataAsExcel(); }
                };
            case 'separator':
                return 'separator';
            case 'pivotChart':
            case 'chartRange':
                return (_a = this.chartMenuItemMapper.getChartItems(key)) !== null && _a !== void 0 ? _a : null;
            default: {
                console.warn("AG Grid: unknown menu item type ".concat(key));
                return null;
            }
        }
    };
    MenuItemMapper.prototype.createAggregationSubMenu = function (column) {
        var _this = this;
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        var columnToUse;
        if (column.isPrimary()) {
            columnToUse = column;
        }
        else {
            var pivotValueColumn = column.getColDef().pivotValueColumn;
            columnToUse = _.exists(pivotValueColumn) ? pivotValueColumn : undefined;
        }
        var result = [];
        if (columnToUse) {
            var columnIsAlreadyAggValue_1 = columnToUse.isValueActive();
            var funcNames = this.aggFuncService.getFuncNames(columnToUse);
            result.push({
                name: localeTextFunc('noAggregation', 'None'),
                action: function () {
                    _this.columnModel.removeValueColumn(columnToUse, "contextMenu");
                    _this.columnModel.setColumnAggFunc(columnToUse, undefined, "contextMenu");
                },
                checked: !columnIsAlreadyAggValue_1
            });
            funcNames.forEach(function (funcName) {
                result.push({
                    name: localeTextFunc(funcName, _this.aggFuncService.getDefaultFuncLabel(funcName)),
                    action: function () {
                        _this.columnModel.setColumnAggFunc(columnToUse, funcName, "contextMenu");
                        _this.columnModel.addValueColumn(columnToUse, "contextMenu");
                    },
                    checked: columnIsAlreadyAggValue_1 && columnToUse.getAggFunc() === funcName
                });
            });
        }
        return result;
    };
    __decorate$z([
        Autowired('columnModel')
    ], MenuItemMapper.prototype, "columnModel", void 0);
    __decorate$z([
        Autowired('gridApi')
    ], MenuItemMapper.prototype, "gridApi", void 0);
    __decorate$z([
        Optional('clipboardService')
    ], MenuItemMapper.prototype, "clipboardService", void 0);
    __decorate$z([
        Optional('aggFuncService')
    ], MenuItemMapper.prototype, "aggFuncService", void 0);
    __decorate$z([
        Autowired('focusService')
    ], MenuItemMapper.prototype, "focusService", void 0);
    __decorate$z([
        Autowired('rowPositionUtils')
    ], MenuItemMapper.prototype, "rowPositionUtils", void 0);
    __decorate$z([
        Autowired('chartMenuItemMapper')
    ], MenuItemMapper.prototype, "chartMenuItemMapper", void 0);
    MenuItemMapper = __decorate$z([
        Bean('menuItemMapper')
    ], MenuItemMapper);
    return MenuItemMapper;
}(BeanStub));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$7 = '31.0.2';

var __extends$B = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$5 = (undefined && undefined.__assign) || function () {
    __assign$5 = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$5.apply(this, arguments);
};
var __decorate$y = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$8 = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var ChartMenuItemMapper = /** @class */ (function (_super) {
    __extends$B(ChartMenuItemMapper, _super);
    function ChartMenuItemMapper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChartMenuItemMapper_1 = ChartMenuItemMapper;
    ChartMenuItemMapper.prototype.getChartItems = function (key) {
        var _a, _b;
        if (!this.chartService) {
            ModuleRegistry.__assertRegistered(ModuleNames.GridChartsModule, "the Context Menu key \"".concat(key, "\""), this.context.getGridId());
            return undefined;
        }
        var builder = key === 'pivotChart'
            ? new PivotMenuItemMapper(this.gridOptionsService, this.chartService, this.localeService)
            : new RangeMenuItemMapper(this.gridOptionsService, this.chartService, this.localeService);
        var topLevelMenuItem = builder.getMenuItem();
        var chartGroupsDef = (_b = (_a = this.gridOptionsService.get('chartToolPanelsDef')) === null || _a === void 0 ? void 0 : _a.settingsPanel) === null || _b === void 0 ? void 0 : _b.chartGroupsDef;
        if (chartGroupsDef) {
            // Apply filtering and ordering if chartGroupsDef provided
            topLevelMenuItem = ChartMenuItemMapper_1.filterAndOrderChartMenu(topLevelMenuItem, chartGroupsDef, builder.getConfigLookup());
        }
        return this.cleanInternals(topLevelMenuItem);
    };
    // Remove our internal _key properties so this does not leak out of the class on the menu items.
    ChartMenuItemMapper.prototype.cleanInternals = function (menuItem) {
        if (!menuItem) {
            return menuItem;
        }
        var removeKey = function (m) {
            var _a;
            m === null || m === void 0 ? true : delete m._key;
            (_a = m === null || m === void 0 ? void 0 : m.subMenu) === null || _a === void 0 ? void 0 : _a.forEach(function (s) { return removeKey(s); });
            return m;
        };
        return removeKey(menuItem);
    };
    ChartMenuItemMapper.buildLookup = function (menuItem) {
        var itemLookup = {};
        var addItem = function (item) {
            itemLookup[item._key] = item;
            if (item.subMenu) {
                item.subMenu.forEach(function (s) { return addItem(s); });
            }
        };
        addItem(menuItem);
        return itemLookup;
    };
    /**
     * Make the MenuItem match the charts provided and their ordering on the ChartGroupsDef config object as provided by the user.
     */
    ChartMenuItemMapper.filterAndOrderChartMenu = function (topLevelMenuItem, chartGroupsDef, configLookup) {
        var _a;
        var menuItemLookup = this.buildLookup(topLevelMenuItem);
        var orderedAndFiltered = __assign$5(__assign$5({}, topLevelMenuItem), { subMenu: [] });
        Object.entries(chartGroupsDef).forEach(function (_a) {
            var _b, _c;
            var _d = __read$8(_a, 2), group = _d[0], chartTypes = _d[1];
            var chartConfigGroup = configLookup[group];
            if (chartConfigGroup == undefined) {
                _.warnOnce("invalid chartGroupsDef config '".concat(group, "'"));
                return undefined;
            }
            var menuItem = menuItemLookup[chartConfigGroup._key];
            if (menuItem) {
                if (menuItem.subMenu) {
                    var subMenus = chartTypes.map(function (chartType) {
                        var itemKey = chartConfigGroup[chartType];
                        if (itemKey == undefined) {
                            _.warnOnce("invalid chartGroupsDef config '".concat(group, ".").concat(chartType, "'"));
                            return undefined;
                        }
                        return menuItemLookup[itemKey];
                    }).filter(function (s) { return s !== undefined; });
                    if (subMenus.length > 0) {
                        menuItem.subMenu = subMenus;
                        (_b = orderedAndFiltered.subMenu) === null || _b === void 0 ? void 0 : _b.push(menuItem);
                    }
                }
                else {
                    // Handles line case which is not actually a sub subMenu
                    (_c = orderedAndFiltered.subMenu) === null || _c === void 0 ? void 0 : _c.push(menuItem);
                }
            }
        });
        if (((_a = orderedAndFiltered.subMenu) === null || _a === void 0 ? void 0 : _a.length) == 0) {
            return undefined;
        }
        return orderedAndFiltered;
    };
    var ChartMenuItemMapper_1;
    __decorate$y([
        Optional('chartService')
    ], ChartMenuItemMapper.prototype, "chartService", void 0);
    ChartMenuItemMapper = ChartMenuItemMapper_1 = __decorate$y([
        Bean('chartMenuItemMapper')
    ], ChartMenuItemMapper);
    return ChartMenuItemMapper;
}(BeanStub));
var PivotMenuItemMapper = /** @class */ (function () {
    function PivotMenuItemMapper(gridOptionsService, chartService, localeService) {
        this.gridOptionsService = gridOptionsService;
        this.chartService = chartService;
        this.localeService = localeService;
    }
    PivotMenuItemMapper.prototype.getMenuItem = function () {
        var _this = this;
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        var getMenuItem = function (localeKey, defaultText, chartType, key) {
            return {
                name: localeTextFunc(localeKey, defaultText),
                action: function () { return _this.chartService.createPivotChart({ chartType: chartType }); },
                _key: key
            };
        };
        return {
            name: localeTextFunc('pivotChart', 'Pivot Chart'),
            _key: 'pivotChart',
            subMenu: [
                {
                    _key: 'pivotColumnChart',
                    name: localeTextFunc('columnChart', 'Column'),
                    subMenu: [
                        getMenuItem('groupedColumn', 'Grouped&lrm;', 'groupedColumn', 'pivotGroupedColumn'),
                        getMenuItem('stackedColumn', 'Stacked&lrm;', 'stackedColumn', 'pivotStackedColumn'),
                        getMenuItem('normalizedColumn', '100% Stacked&lrm;', 'normalizedColumn', 'pivotNormalizedColumn')
                    ]
                },
                {
                    _key: 'pivotBarChart',
                    name: localeTextFunc('barChart', 'Bar'),
                    subMenu: [
                        getMenuItem('groupedBar', 'Grouped&lrm;', 'groupedBar', 'pivotGroupedBar'),
                        getMenuItem('stackedBar', 'Stacked&lrm;', 'stackedBar', 'pivotStackedBar'),
                        getMenuItem('normalizedBar', '100% Stacked&lrm;', 'normalizedBar', 'pivotNormalizedBar')
                    ]
                },
                {
                    _key: 'pivotPieChart',
                    name: localeTextFunc('pieChart', 'Pie'),
                    subMenu: [
                        getMenuItem('pie', 'Pie&lrm;', 'pie', 'pivotPie'),
                        getMenuItem('doughnut', 'Doughnut&lrm;', 'doughnut', 'pivotDoughnut')
                    ]
                },
                getMenuItem('line', 'Line&lrm;', 'line', 'pivotLineChart'),
                {
                    _key: 'pivotXYChart',
                    name: localeTextFunc('xyChart', 'X Y (Scatter)'),
                    subMenu: [
                        getMenuItem('scatter', 'Scatter&lrm;', 'scatter', 'pivotScatter'),
                        getMenuItem('bubble', 'Bubble&lrm;', 'bubble', 'pivotBubble')
                    ]
                },
                {
                    _key: 'pivotAreaChart',
                    name: localeTextFunc('areaChart', 'Area'),
                    subMenu: [
                        getMenuItem('area', 'Area&lrm;', 'area', 'pivotArea'),
                        getMenuItem('stackedArea', 'Stacked&lrm;', 'stackedArea', 'pivotStackedArea'),
                        getMenuItem('normalizedArea', '100% Stacked&lrm;', 'normalizedArea', 'pivotNormalizedArea')
                    ]
                },
                getMenuItem('histogramChart', 'Histogram&lrm;', 'histogram', 'pivotHistogramChart'),
                {
                    _key: 'pivotCombinationChart',
                    name: localeTextFunc('combinationChart', 'Combination'),
                    subMenu: [
                        getMenuItem('columnLineCombo', 'Column & Line&lrm;', 'columnLineCombo', 'pivotColumnLineCombo'),
                        getMenuItem('AreaColumnCombo', 'Area & Column&lrm;', 'areaColumnCombo', 'pivotAreaColumnCombo')
                    ]
                }
            ],
            icon: _.createIconNoSpan('chart', this.gridOptionsService, undefined),
        };
    };
    PivotMenuItemMapper.prototype.getConfigLookup = function () {
        return {
            columnGroup: {
                _key: 'pivotColumnChart',
                column: 'pivotGroupedColumn',
                stackedColumn: 'pivotStackedColumn',
                normalizedColumn: 'pivotNormalizedColumn',
            },
            barGroup: {
                _key: 'pivotBarChart',
                bar: 'pivotGroupedBar',
                stackedBar: 'pivotStackedBar',
                normalizedBar: 'pivotNormalizedBar',
            },
            pieGroup: {
                _key: 'pivotPieChart',
                pie: 'pivotPie',
                doughnut: 'pivotDoughnut',
            },
            lineGroup: {
                _key: 'pivotLineChart',
                line: 'pivotLineChart',
            },
            scatterGroup: {
                _key: 'pivotXYChart',
                bubble: 'pivotBubble',
                scatter: 'pivotScatter',
            },
            areaGroup: {
                _key: 'pivotAreaChart',
                area: 'pivotArea',
                stackedArea: 'pivotStackedArea',
                normalizedArea: 'pivotNormalizedArea',
            },
            histogramGroup: {
                _key: 'pivotHistogramChart',
                histogram: 'pivotHistogramChart',
            },
            combinationGroup: {
                _key: 'pivotCombinationChart',
                columnLineCombo: 'pivotColumnLineCombo',
                areaColumnCombo: 'pivotAreaColumnCombo',
                customCombo: '' // Not currently supported but needs a value to separate from a missing value
            }
        };
    };
    return PivotMenuItemMapper;
}());
var RangeMenuItemMapper = /** @class */ (function () {
    function RangeMenuItemMapper(gridOptionsService, chartService, localeService) {
        this.gridOptionsService = gridOptionsService;
        this.chartService = chartService;
        this.localeService = localeService;
    }
    RangeMenuItemMapper.prototype.getMenuItem = function () {
        var _this = this;
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        var getMenuItem = function (localeKey, defaultText, chartType, key) {
            return {
                name: localeTextFunc(localeKey, defaultText),
                action: function () { return _this.chartService.createChartFromCurrentRange(chartType); },
                _key: key
            };
        };
        return {
            name: localeTextFunc('chartRange', 'Chart Range'),
            _key: 'chartRange',
            subMenu: [
                {
                    name: localeTextFunc('columnChart', 'Column'),
                    subMenu: [
                        getMenuItem('groupedColumn', 'Grouped&lrm;', 'groupedColumn', 'rangeGroupedColumn'),
                        getMenuItem('stackedColumn', 'Stacked&lrm;', 'stackedColumn', 'rangeStackedColumn'),
                        getMenuItem('normalizedColumn', '100% Stacked&lrm;', 'normalizedColumn', 'rangeNormalizedColumn')
                    ],
                    _key: 'rangeColumnChart'
                },
                {
                    name: localeTextFunc('barChart', 'Bar'),
                    subMenu: [
                        getMenuItem('groupedBar', 'Grouped&lrm;', 'groupedBar', 'rangeGroupedBar'),
                        getMenuItem('stackedBar', 'Stacked&lrm;', 'stackedBar', 'rangeStackedBar'),
                        getMenuItem('normalizedBar', '100% Stacked&lrm;', 'normalizedBar', 'rangeNormalizedBar')
                    ],
                    _key: 'rangeBarChart'
                },
                {
                    name: localeTextFunc('pieChart', 'Pie'),
                    subMenu: [
                        getMenuItem('pie', 'Pie&lrm;', 'pie', 'rangePie'),
                        getMenuItem('doughnut', 'Doughnut&lrm;', 'doughnut', 'rangeDoughnut')
                    ],
                    _key: 'rangePieChart'
                },
                getMenuItem('line', 'Line&lrm;', 'line', 'rangeLineChart'),
                {
                    name: localeTextFunc('xyChart', 'X Y (Scatter)'),
                    subMenu: [
                        getMenuItem('scatter', 'Scatter&lrm;', 'scatter', 'rangeScatter'),
                        getMenuItem('bubble', 'Bubble&lrm;', 'bubble', 'rangeBubble')
                    ],
                    _key: 'rangeXYChart'
                },
                {
                    name: localeTextFunc('areaChart', 'Area'),
                    subMenu: [
                        getMenuItem('area', 'Area&lrm;', 'area', 'rangeArea'),
                        getMenuItem('stackedArea', 'Stacked&lrm;', 'stackedArea', 'rangeStackedArea'),
                        getMenuItem('normalizedArea', '100% Stacked&lrm;', 'normalizedArea', 'rangeNormalizedArea')
                    ],
                    _key: 'rangeAreaChart'
                },
                getMenuItem('histogramChart', 'Histogram&lrm;', 'histogram', 'rangeHistogramChart'),
                {
                    name: localeTextFunc('combinationChart', 'Combination'),
                    subMenu: [
                        getMenuItem('columnLineCombo', 'Column & Line&lrm;', 'columnLineCombo', 'rangeColumnLineCombo'),
                        getMenuItem('AreaColumnCombo', 'Area & Column&lrm;', 'areaColumnCombo', 'rangeAreaColumnCombo')
                    ],
                    _key: 'rangeCombinationChart'
                }
            ],
            icon: _.createIconNoSpan('chart', this.gridOptionsService, undefined),
        };
    };
    RangeMenuItemMapper.prototype.getConfigLookup = function () {
        return {
            columnGroup: {
                _key: 'rangeColumnChart',
                column: 'rangeGroupedColumn',
                stackedColumn: 'rangeStackedColumn',
                normalizedColumn: 'rangeNormalizedColumn',
            },
            barGroup: {
                _key: 'rangeBarChart',
                bar: 'rangeGroupedBar',
                stackedBar: 'rangeStackedBar',
                normalizedBar: 'rangeNormalizedBar',
            },
            pieGroup: {
                _key: 'rangePieChart',
                pie: 'rangePie',
                doughnut: 'rangeDoughnut',
            },
            lineGroup: {
                _key: 'rangeLineChart',
                line: 'rangeLineChart',
            },
            scatterGroup: {
                _key: 'rangeXYChart',
                bubble: 'rangeBubble',
                scatter: 'rangeScatter',
            },
            areaGroup: {
                _key: 'rangeAreaChart',
                area: 'rangeArea',
                stackedArea: 'rangeStackedArea',
                normalizedArea: 'rangeNormalizedArea',
            },
            histogramGroup: {
                _key: 'rangeHistogramChart',
                histogram: 'rangeHistogramChart',
            },
            combinationGroup: {
                _key: 'rangeCombinationChart',
                columnLineCombo: 'rangeColumnLineCombo',
                areaColumnCombo: 'rangeAreaColumnCombo',
                customCombo: '' // Not currently supported but needs a value to separate from a missing value
            }
        };
    };
    return RangeMenuItemMapper;
}());

var MenuModule = {
    version: VERSION$7,
    moduleName: ModuleNames.MenuModule,
    beans: [EnterpriseMenuFactory, ContextMenuFactory, MenuItemMapper, ChartMenuItemMapper],
    dependantModules: [
        EnterpriseCoreModule
    ]
};

var __extends$A = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$4 = (undefined && undefined.__assign) || function () {
    __assign$4 = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$4.apply(this, arguments);
};
var __decorate$x = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var MultiFilter = /** @class */ (function (_super) {
    __extends$A(MultiFilter, _super);
    function MultiFilter() {
        var _this = _super.call(this, /* html */ "<div class=\"ag-multi-filter ag-menu-list-compact\"></div>") || this;
        _this.filterDefs = [];
        _this.filters = [];
        _this.guiDestroyFuncs = [];
        _this.activeFilterIndices = [];
        _this.lastActivatedMenuItem = null;
        _this.afterFiltersReadyFuncs = [];
        return _this;
    }
    MultiFilter.prototype.postConstruct = function () {
        var _this = this;
        this.initialiseTabGuard({
            onFocusIn: function (e) { return _this.onFocusIn(e); }
        });
    };
    MultiFilter.getFilterDefs = function (params) {
        var filters = params.filters;
        return filters && filters.length > 0 ?
            filters :
            [{ filter: 'agTextColumnFilter' }, { filter: 'agSetColumnFilter' }];
    };
    MultiFilter.prototype.init = function (params) {
        var _this = this;
        this.params = params;
        this.filterDefs = MultiFilter.getFilterDefs(params);
        var column = params.column, filterChangedCallback = params.filterChangedCallback;
        this.column = column;
        this.filterChangedCallback = filterChangedCallback;
        var filterPromises = [];
        this.filterDefs.forEach(function (filterDef, index) {
            var filterPromise = _this.createFilter(filterDef, index);
            if (filterPromise != null) {
                filterPromises.push(filterPromise);
            }
        });
        // we have to refresh the GUI here to ensure that Angular components are not rendered in odd places
        return AgPromise
            .all(filterPromises)
            .then(function (filters) {
            _this.filters = filters;
            _this.refreshGui('columnMenu');
            _this.afterFiltersReadyFuncs.forEach(function (f) { return f(); });
            _this.afterFiltersReadyFuncs.length = 0;
        });
    };
    MultiFilter.prototype.refreshGui = function (container) {
        var _this = this;
        if (container === this.lastOpenedInContainer) {
            return;
        }
        this.removeAllChildrenExceptTabGuards();
        this.destroyChildren();
        this.filters.forEach(function (filter, index) {
            if (index > 0) {
                _this.appendChild(_.loadTemplate(/* html */ "<div class=\"ag-filter-separator\"></div>"));
            }
            var filterDef = _this.filterDefs[index];
            var filterTitle = _this.getFilterTitle(filter, filterDef);
            var filterGui;
            if (filterDef.display === 'subMenu' && container !== 'toolPanel') {
                // prevent sub-menu being used in tool panel
                var menuItem = _this.insertFilterMenu(filter, filterTitle);
                filterGui = menuItem.getGui();
            }
            else if (filterDef.display === 'subMenu' || filterDef.display === 'accordion') {
                // sub-menus should appear as groups in the tool panel
                var group = _this.insertFilterGroup(filter, filterTitle);
                filterGui = group.getGui();
            }
            else {
                // display inline
                filterGui = filter.getGui();
            }
            _this.appendChild(filterGui);
        });
        this.lastOpenedInContainer = container;
    };
    MultiFilter.prototype.getFilterTitle = function (filter, filterDef) {
        if (filterDef.title != null) {
            return filterDef.title;
        }
        var filterWithoutType = filter;
        return typeof filterWithoutType.getFilterTitle === 'function' ? filterWithoutType.getFilterTitle() : 'Filter';
    };
    MultiFilter.prototype.destroyChildren = function () {
        this.guiDestroyFuncs.forEach(function (func) { return func(); });
        this.guiDestroyFuncs.length = 0;
    };
    MultiFilter.prototype.insertFilterMenu = function (filter, name) {
        var _this = this;
        var menuItem = this.createBean(new AgMenuItemComponent({
            name: name,
            subMenu: filter,
            cssClasses: ['ag-multi-filter-menu-item'],
            isCompact: true,
            isAnotherSubMenuOpen: function () { return false; },
        }));
        menuItem.setParentComponent(this);
        this.guiDestroyFuncs.push(function () { return _this.destroyBean(menuItem); });
        this.addManagedListener(menuItem, AgMenuItemComponent.EVENT_MENU_ITEM_ACTIVATED, function (event) {
            if (_this.lastActivatedMenuItem && _this.lastActivatedMenuItem !== event.menuItem) {
                _this.lastActivatedMenuItem.deactivate();
            }
            _this.lastActivatedMenuItem = event.menuItem;
        });
        menuItem.addGuiEventListener('focusin', function () { return menuItem.activate(); });
        menuItem.addGuiEventListener('focusout', function () {
            if (!menuItem.isSubMenuOpen()) {
                menuItem.deactivate();
            }
        });
        return menuItem;
    };
    MultiFilter.prototype.insertFilterGroup = function (filter, title) {
        var _this = this;
        var group = this.createBean(new AgGroupComponent({
            title: title,
            cssIdentifier: 'multi-filter',
        }));
        this.guiDestroyFuncs.push(function () { return _this.destroyBean(group); });
        group.addItem(filter.getGui());
        group.toggleGroupExpand(false);
        if (filter.afterGuiAttached) {
            group.addManagedListener(group, AgGroupComponent.EVENT_EXPANDED, function () { return filter.afterGuiAttached({
                container: _this.lastOpenedInContainer,
                suppressFocus: true,
                hidePopup: _this.hidePopup
            }); });
        }
        return group;
    };
    MultiFilter.prototype.isFilterActive = function () {
        return this.filters.some(function (filter) { return filter.isFilterActive(); });
    };
    MultiFilter.prototype.getLastActiveFilterIndex = function () {
        return this.activeFilterIndices.length > 0 ? this.activeFilterIndices[this.activeFilterIndices.length - 1] : null;
    };
    MultiFilter.prototype.doesFilterPass = function (params, filterToSkip) {
        var rowPasses = true;
        this.filters.forEach(function (filter) {
            if (!rowPasses || filter === filterToSkip || !filter.isFilterActive()) {
                return;
            }
            rowPasses = filter.doesFilterPass(params);
        });
        return rowPasses;
    };
    MultiFilter.prototype.getFilterType = function () {
        return 'multi';
    };
    MultiFilter.prototype.getModelFromUi = function () {
        var model = {
            filterType: this.getFilterType(),
            filterModels: this.filters.map(function (filter) {
                var providedFilter = filter;
                if (typeof providedFilter.getModelFromUi === 'function') {
                    return providedFilter.getModelFromUi();
                }
                return null;
            })
        };
        return model;
    };
    MultiFilter.prototype.getModel = function () {
        if (!this.isFilterActive()) {
            return null;
        }
        var model = {
            filterType: this.getFilterType(),
            filterModels: this.filters.map(function (filter) {
                if (filter.isFilterActive()) {
                    return filter.getModel();
                }
                return null;
            })
        };
        return model;
    };
    MultiFilter.prototype.setModel = function (model) {
        var _this = this;
        var setFilterModel = function (filter, filterModel) {
            return new AgPromise(function (resolve) {
                var promise = filter.setModel(filterModel);
                promise ? promise.then(function () { return resolve(); }) : resolve();
            });
        };
        var promises = [];
        if (model == null) {
            promises = this.filters.map(function (filter, index) {
                var res = setFilterModel(filter, null).then(function () {
                    _this.updateActiveList(index);
                });
                return res;
            });
        }
        else {
            this.filters.forEach(function (filter, index) {
                var filterModel = model.filterModels.length > index ? model.filterModels[index] : null;
                var res = setFilterModel(filter, filterModel).then(function () {
                    _this.updateActiveList(index);
                });
                promises.push(res);
            });
        }
        return AgPromise.all(promises).then(function () { });
    };
    MultiFilter.prototype.applyModel = function (source) {
        if (source === void 0) { source = 'api'; }
        var result = false;
        this.filters.forEach(function (filter) {
            if (filter instanceof ProvidedFilter) {
                result = filter.applyModel(source) || result;
            }
        });
        return result;
    };
    MultiFilter.prototype.getChildFilterInstance = function (index) {
        return this.filters[index];
    };
    MultiFilter.prototype.afterGuiAttached = function (params) {
        if (params) {
            this.hidePopup = params.hidePopup;
            this.refreshGui(params.container);
        }
        else {
            this.hidePopup = undefined;
        }
        var filters = this.params.filters;
        var suppressFocus = filters && filters.some(function (filter) { return filter.display && filter.display !== 'inline'; });
        this.executeFunctionIfExists('afterGuiAttached', __assign$4(__assign$4({}, params || {}), { suppressFocus: suppressFocus }));
        var eDocument = this.gridOptionsService.getDocument();
        var activeEl = eDocument.activeElement;
        // if suppress focus is true, we might run into two scenarios:
        // 1 - we are loading the filter for the first time and the component isn't ready,
        //     which means the document will have focus.
        // 2 - The focus will be somewhere inside the component due to auto focus
        // In both cases we need to force the focus somewhere valid but outside the filter.
        if (suppressFocus && (activeEl === eDocument.body || this.getGui().contains(activeEl))) {
            // reset focus to the top of the container, and blur
            this.forceFocusOutOfContainer(true);
        }
    };
    MultiFilter.prototype.afterGuiDetached = function () {
        this.executeFunctionIfExists('afterGuiDetached');
    };
    MultiFilter.prototype.onAnyFilterChanged = function () {
        this.executeFunctionIfExists('onAnyFilterChanged');
    };
    MultiFilter.prototype.onNewRowsLoaded = function () {
        this.executeFunctionIfExists('onNewRowsLoaded');
    };
    MultiFilter.prototype.destroy = function () {
        var _this = this;
        this.filters.forEach(function (filter) {
            filter.setModel(null);
            _this.destroyBean(filter);
        });
        this.filters.length = 0;
        this.destroyChildren();
        this.hidePopup = undefined;
        _super.prototype.destroy.call(this);
    };
    MultiFilter.prototype.executeFunctionIfExists = function (name) {
        var params = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            params[_i - 1] = arguments[_i];
        }
        // The first filter is always the "dominant" one. By iterating in reverse order we ensure the first filter
        // always gets the last say
        _.forEachReverse(this.filters, function (filter) {
            var func = filter[name];
            if (typeof func === 'function') {
                func.apply(filter, params);
            }
        });
    };
    MultiFilter.prototype.createFilter = function (filterDef, index) {
        var _this = this;
        var _a = this.params, filterModifiedCallback = _a.filterModifiedCallback, doesRowPassOtherFilter = _a.doesRowPassOtherFilter;
        var filterInstance;
        var filterParams = __assign$4(__assign$4({}, this.filterManager.createFilterParams(this.column, this.column.getColDef())), { filterModifiedCallback: filterModifiedCallback, filterChangedCallback: function (additionalEventAttributes) {
                _this.executeWhenAllFiltersReady(function () { return _this.filterChanged(index, additionalEventAttributes); });
            }, doesRowPassOtherFilter: function (node) {
                return doesRowPassOtherFilter(node) && _this.doesFilterPass({ node: node, data: node.data }, filterInstance);
            } });
        var compDetails = this.userComponentFactory.getFilterDetails(filterDef, filterParams, 'agTextColumnFilter');
        if (!compDetails) {
            return null;
        }
        var filterPromise = compDetails.newAgStackInstance();
        if (filterPromise) {
            filterPromise.then(function (filter) { return filterInstance = filter; });
        }
        return filterPromise;
    };
    MultiFilter.prototype.executeWhenAllFiltersReady = function (action) {
        if (this.filters && this.filters.length > 0) {
            action();
        }
        else {
            this.afterFiltersReadyFuncs.push(action);
        }
    };
    MultiFilter.prototype.updateActiveList = function (index) {
        var changedFilter = this.filters[index];
        _.removeFromArray(this.activeFilterIndices, index);
        if (changedFilter.isFilterActive()) {
            this.activeFilterIndices.push(index);
        }
    };
    MultiFilter.prototype.filterChanged = function (index, additionalEventAttributes) {
        this.updateActiveList(index);
        this.filterChangedCallback(additionalEventAttributes);
        var changedFilter = this.filters[index];
        this.filters.forEach(function (filter) {
            if (filter === changedFilter) {
                return;
            }
            if (typeof filter.onAnyFilterChanged === 'function') {
                filter.onAnyFilterChanged();
            }
        });
    };
    MultiFilter.prototype.onFocusIn = function (e) {
        if (this.lastActivatedMenuItem != null && !this.lastActivatedMenuItem.getGui().contains(e.target)) {
            this.lastActivatedMenuItem.deactivate();
            this.lastActivatedMenuItem = null;
        }
    };
    MultiFilter.prototype.getModelAsString = function (model) {
        var _a, _b, _c, _d;
        if (!this.filters || !((_a = model === null || model === void 0 ? void 0 : model.filterModels) === null || _a === void 0 ? void 0 : _a.length)) {
            return '';
        }
        var lastActiveIndex = (_b = this.getLastActiveFilterIndex()) !== null && _b !== void 0 ? _b : 0;
        var activeFilter = this.filters[lastActiveIndex];
        return (_d = (_c = activeFilter.getModelAsString) === null || _c === void 0 ? void 0 : _c.call(activeFilter, model.filterModels[lastActiveIndex])) !== null && _d !== void 0 ? _d : '';
    };
    __decorate$x([
        Autowired('filterManager')
    ], MultiFilter.prototype, "filterManager", void 0);
    __decorate$x([
        Autowired('userComponentFactory')
    ], MultiFilter.prototype, "userComponentFactory", void 0);
    __decorate$x([
        PostConstruct
    ], MultiFilter.prototype, "postConstruct", null);
    return MultiFilter;
}(TabGuardComp));

var __extends$z = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$3 = (undefined && undefined.__assign) || function () {
    __assign$3 = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$3.apply(this, arguments);
};
var __decorate$w = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var MultiFloatingFilterComp = /** @class */ (function (_super) {
    __extends$z(MultiFloatingFilterComp, _super);
    function MultiFloatingFilterComp() {
        var _this = _super.call(this, /* html */ "<div class=\"ag-multi-floating-filter ag-floating-filter-input\"></div>") || this;
        _this.floatingFilters = [];
        _this.compDetailsList = [];
        return _this;
    }
    MultiFloatingFilterComp.prototype.init = function (params) {
        this.params = params;
        var compDetailsList = this.getCompDetailsList(params).compDetailsList;
        return this.setParams(compDetailsList);
    };
    MultiFloatingFilterComp.prototype.setParams = function (compDetailsList) {
        var _this = this;
        var floatingFilterPromises = [];
        compDetailsList.forEach(function (compDetails) {
            var floatingFilterPromise = compDetails === null || compDetails === void 0 ? void 0 : compDetails.newAgStackInstance();
            if (floatingFilterPromise != null) {
                _this.compDetailsList.push(compDetails);
                floatingFilterPromises.push(floatingFilterPromise);
            }
        });
        return AgPromise.all(floatingFilterPromises).then(function (floatingFilters) {
            floatingFilters.forEach(function (floatingFilter, index) {
                _this.floatingFilters.push(floatingFilter);
                var gui = floatingFilter.getGui();
                _this.appendChild(gui);
                if (index > 0) {
                    _.setDisplayed(gui, false);
                }
            });
        });
    };
    MultiFloatingFilterComp.prototype.onParamsUpdated = function (params) {
        this.refresh(params);
    };
    MultiFloatingFilterComp.prototype.refresh = function (params) {
        var _this = this;
        this.params = params;
        var _a = this.getCompDetailsList(params), newCompDetailsList = _a.compDetailsList, floatingFilterParamsList = _a.floatingFilterParamsList;
        var allFloatingFilterCompsUnchanged = newCompDetailsList.length === this.compDetailsList.length
            && newCompDetailsList.every(function (newCompDetails, index) { return !_this.filterManager.areFilterCompsDifferent(_this.compDetailsList[index], newCompDetails); });
        if (allFloatingFilterCompsUnchanged) {
            floatingFilterParamsList.forEach(function (floatingFilterParams, index) {
                var _a;
                var floatingFilter = _this.floatingFilters[index];
                var hasRefreshed = false;
                if (floatingFilter.refresh) {
                    var result = floatingFilter.refresh(floatingFilterParams);
                    // framework wrapper always implements optional methods, but returns null if no underlying method
                    if (result !== null) {
                        hasRefreshed = true;
                    }
                }
                if (!hasRefreshed) {
                    (_a = floatingFilter.onParamsUpdated) === null || _a === void 0 ? void 0 : _a.call(floatingFilter, floatingFilterParams);
                }
            });
        }
        else {
            _.clearElement(this.getGui());
            this.destroyBeans(this.floatingFilters);
            this.floatingFilters = [];
            this.compDetailsList = [];
            this.setParams(newCompDetailsList);
        }
    };
    MultiFloatingFilterComp.prototype.getCompDetailsList = function (params) {
        var _this = this;
        var compDetailsList = [];
        var floatingFilterParamsList = [];
        var filterParams = params.filterParams;
        MultiFilter.getFilterDefs(filterParams).forEach(function (filterDef, index) {
            var floatingFilterParams = __assign$3(__assign$3({}, params), { 
                // set the parent filter instance for each floating filter to the relevant child filter instance
                parentFilterInstance: function (callback) {
                    _this.parentMultiFilterInstance(function (parent) {
                        var child = parent.getChildFilterInstance(index);
                        if (child == null) {
                            return;
                        }
                        callback(child);
                    });
                } });
            _.mergeDeep(floatingFilterParams.filterParams, filterDef.filterParams);
            var compDetails = _this.getCompDetails(filterDef, floatingFilterParams);
            if (compDetails) {
                compDetailsList.push(compDetails);
                floatingFilterParamsList.push(floatingFilterParams);
            }
        });
        return { compDetailsList: compDetailsList, floatingFilterParamsList: floatingFilterParamsList };
    };
    MultiFloatingFilterComp.prototype.onParentModelChanged = function (model, event) {
        var _this = this;
        // We don't want to update the floating filter if the floating filter caused the change,
        // because the UI is already in sync. if we didn't do this, the UI would behave strangely
        // as it would be updating as the user is typing
        if (event && event.afterFloatingFilter) {
            return;
        }
        this.parentMultiFilterInstance(function (parent) {
            if (model == null) {
                _this.floatingFilters.forEach(function (filter, i) {
                    filter.onParentModelChanged(null, event);
                    _.setDisplayed(filter.getGui(), i === 0);
                });
            }
            else {
                var lastActiveFloatingFilterIndex_1 = parent.getLastActiveFilterIndex();
                _this.floatingFilters.forEach(function (filter, i) {
                    var filterModel = model.filterModels.length > i ? model.filterModels[i] : null;
                    filter.onParentModelChanged(filterModel, event);
                    var shouldShow = lastActiveFloatingFilterIndex_1 == null ? i === 0 : i === lastActiveFloatingFilterIndex_1;
                    _.setDisplayed(filter.getGui(), shouldShow);
                });
            }
        });
    };
    MultiFloatingFilterComp.prototype.destroy = function () {
        this.destroyBeans(this.floatingFilters);
        this.floatingFilters.length = 0;
        _super.prototype.destroy.call(this);
    };
    MultiFloatingFilterComp.prototype.getCompDetails = function (filterDef, params) {
        var _this = this;
        var _a;
        var defaultComponentName = (_a = this.userComponentFactory.getDefaultFloatingFilterType(filterDef, function () { return _this.filterManager.getDefaultFloatingFilter(_this.params.column); })) !== null && _a !== void 0 ? _a : 'agReadOnlyFloatingFilter';
        return this.userComponentFactory.getFloatingFilterCompDetails(filterDef, params, defaultComponentName);
    };
    MultiFloatingFilterComp.prototype.parentMultiFilterInstance = function (cb) {
        this.params.parentFilterInstance(function (parent) {
            if (!(parent instanceof MultiFilter)) {
                throw new Error('AG Grid - MultiFloatingFilterComp expects MultiFilter as its parent');
            }
            cb(parent);
        });
    };
    __decorate$w([
        Autowired('userComponentFactory')
    ], MultiFloatingFilterComp.prototype, "userComponentFactory", void 0);
    __decorate$w([
        Autowired('filterManager')
    ], MultiFloatingFilterComp.prototype, "filterManager", void 0);
    return MultiFloatingFilterComp;
}(Component));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$6 = '31.0.2';

var MultiFilterModule = {
    version: VERSION$6,
    moduleName: ModuleNames.MultiFilterModule,
    beans: [],
    userComponents: [
        { componentName: 'agMultiColumnFilter', componentClass: MultiFilter },
        { componentName: 'agMultiColumnFloatingFilter', componentClass: MultiFloatingFilterComp },
    ],
    dependantModules: [
        EnterpriseCoreModule
    ]
};

var __extends$y = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RichSelectCellEditor = /** @class */ (function (_super) {
    __extends$y(RichSelectCellEditor, _super);
    function RichSelectCellEditor() {
        return _super.call(this, /* html */ "<div class=\"ag-cell-edit-wrapper\"></div>") || this;
    }
    RichSelectCellEditor.prototype.init = function (params) {
        var _this = this;
        this.params = params;
        var cellStartedEdit = params.cellStartedEdit, cellHeight = params.cellHeight, values = params.values;
        if (_.missing(values)) {
            console.warn('AG Grid: agRichSelectCellEditor requires cellEditorParams.values to be set');
        }
        var _a = this.buildRichSelectParams(), richSelectParams = _a.params, valuesPromise = _a.valuesPromise;
        this.richSelect = this.createManagedBean(new AgRichSelect(richSelectParams));
        this.richSelect.addCssClass('ag-cell-editor');
        this.appendChild(this.richSelect);
        if (valuesPromise) {
            valuesPromise.then(function (values) {
                _this.richSelect.setValueList({ valueList: values, refresh: true });
                var searchStringCallback = _this.getSearchStringCallback(values);
                if (searchStringCallback) {
                    _this.richSelect.setSearchStringCreator(searchStringCallback);
                }
            });
        }
        this.addManagedListener(this.richSelect, Events.EVENT_FIELD_PICKER_VALUE_SELECTED, this.onEditorPickerValueSelected.bind(this));
        this.addManagedListener(this.richSelect.getGui(), 'focusout', this.onEditorFocusOut.bind(this));
        this.focusAfterAttached = cellStartedEdit;
        if (_.exists(cellHeight)) {
            this.richSelect.setRowHeight(cellHeight);
        }
    };
    RichSelectCellEditor.prototype.onEditorPickerValueSelected = function (e) {
        this.params.stopEditing(!e.fromEnterKey);
    };
    RichSelectCellEditor.prototype.onEditorFocusOut = function (e) {
        if (this.richSelect.getGui().contains(e.relatedTarget)) {
            return;
        }
        this.params.stopEditing(true);
    };
    RichSelectCellEditor.prototype.buildRichSelectParams = function () {
        var _a = this.params, cellRenderer = _a.cellRenderer, value = _a.value, values = _a.values, formatValue = _a.formatValue, searchDebounceDelay = _a.searchDebounceDelay, valueListGap = _a.valueListGap, valueListMaxHeight = _a.valueListMaxHeight, valueListMaxWidth = _a.valueListMaxWidth, allowTyping = _a.allowTyping, filterList = _a.filterList, searchType = _a.searchType, highlightMatch = _a.highlightMatch, valuePlaceholder = _a.valuePlaceholder, eventKey = _a.eventKey;
        var ret = {
            value: value,
            cellRenderer: cellRenderer,
            searchDebounceDelay: searchDebounceDelay,
            valueFormatter: formatValue,
            pickerAriaLabelKey: 'ariaLabelRichSelectField',
            pickerAriaLabelValue: 'Rich Select Field',
            pickerType: 'virtual-list',
            pickerGap: valueListGap,
            allowTyping: allowTyping,
            filterList: filterList,
            searchType: searchType,
            highlightMatch: highlightMatch,
            maxPickerHeight: valueListMaxHeight,
            maxPickerWidth: valueListMaxWidth,
            placeholder: valuePlaceholder,
            initialInputValue: (eventKey === null || eventKey === void 0 ? void 0 : eventKey.length) === 1 ? eventKey : undefined
        };
        var valuesResult;
        var valuesPromise;
        if (typeof values === 'function') {
            valuesResult = values(this.params);
        }
        else {
            valuesResult = values !== null && values !== void 0 ? values : [];
        }
        if (Array.isArray(valuesResult)) {
            ret.valueList = valuesResult;
            ret.searchStringCreator = this.getSearchStringCallback(valuesResult);
        }
        else {
            valuesPromise = valuesResult;
        }
        return { params: ret, valuesPromise: valuesPromise };
    };
    RichSelectCellEditor.prototype.getSearchStringCallback = function (values) {
        var _this = this;
        var colDef = this.params.colDef;
        if (typeof values[0] !== 'object' || !colDef.keyCreator) {
            return;
        }
        return function (values) { return values.map(function (value) {
            var keyParams = _this.gridOptionsService.addGridCommonParams({
                value: value,
                colDef: _this.params.colDef,
                column: _this.params.column,
                node: _this.params.node,
                data: _this.params.data
            });
            return colDef.keyCreator(keyParams);
        }); };
    };
    // we need to have the gui attached before we can draw the virtual rows, as the
    // virtual row logic needs info about the gui state
    RichSelectCellEditor.prototype.afterGuiAttached = function () {
        var _this = this;
        var _a = this, focusAfterAttached = _a.focusAfterAttached, params = _a.params;
        setTimeout(function () {
            if (!_this.isAlive()) {
                return;
            }
            if (focusAfterAttached) {
                var focusableEl = _this.richSelect.getFocusableElement();
                focusableEl.focus();
                var _a = _this.params, allowTyping = _a.allowTyping, eventKey_1 = _a.eventKey;
                if (allowTyping && (!eventKey_1 || eventKey_1.length !== 1)) {
                    focusableEl.select();
                }
            }
            _this.richSelect.showPicker();
            var eventKey = params.eventKey;
            if (eventKey) {
                if ((eventKey === null || eventKey === void 0 ? void 0 : eventKey.length) === 1) {
                    _this.richSelect.searchTextFromString(eventKey);
                }
            }
        });
    };
    RichSelectCellEditor.prototype.getValue = function () {
        return this.richSelect.getValue();
    };
    RichSelectCellEditor.prototype.isPopup = function () {
        return false;
    };
    return RichSelectCellEditor;
}(PopupComponent));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$5 = '31.0.2';

var RichSelectModule = {
    version: VERSION$5,
    moduleName: ModuleNames.RichSelectModule,
    beans: [],
    userComponents: [
        { componentName: 'agRichSelect', componentClass: RichSelectCellEditor },
        { componentName: 'agRichSelectCellEditor', componentClass: RichSelectCellEditor }
    ],
    dependantModules: [
        EnterpriseCoreModule
    ]
};

var __extends$x = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$v = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var FullStore = /** @class */ (function (_super) {
    __extends$x(FullStore, _super);
    function FullStore(ssrmParams, storeParams, parentRowNode) {
        var _this = 
        // finite block represents a cache with just one block, thus 0 is the id, it's the first block
        _super.call(this, 0) || this;
        _this.nodeIdSequence = new NumberSequence();
        _this.info = {};
        _this.ssrmParams = ssrmParams;
        _this.parentRowNode = parentRowNode;
        _this.level = parentRowNode.level + 1;
        _this.groupLevel = ssrmParams.rowGroupCols ? _this.level < ssrmParams.rowGroupCols.length : undefined;
        _this.leafGroup = ssrmParams.rowGroupCols ? _this.level === ssrmParams.rowGroupCols.length - 1 : false;
        return _this;
    }
    FullStore.prototype.postConstruct = function () {
        var _this = this;
        this.usingTreeData = this.gridOptionsService.get('treeData');
        this.nodeIdPrefix = this.blockUtils.createNodeIdPrefix(this.parentRowNode);
        if (!this.usingTreeData && this.groupLevel) {
            var groupColVo = this.ssrmParams.rowGroupCols[this.level];
            this.groupField = groupColVo.field;
            this.rowGroupColumn = this.columnModel.getRowGroupColumns()[this.level];
        }
        var initialRowCount = 1;
        var isRootStore = this.parentRowNode.level === -1;
        var userInitialRowCount = this.storeUtils.getServerSideInitialRowCount();
        if (isRootStore && userInitialRowCount != null) {
            initialRowCount = userInitialRowCount;
        }
        this.initialiseRowNodes(initialRowCount);
        this.rowNodeBlockLoader.addBlock(this);
        this.addDestroyFunc(function () { return _this.rowNodeBlockLoader.removeBlock(_this); });
        this.postSortFunc = this.gridOptionsService.getCallback('postSortRows');
        if (userInitialRowCount != null) {
            this.eventService.dispatchEventOnce({
                type: Events.EVENT_ROW_COUNT_READY
            });
        }
    };
    FullStore.prototype.destroyRowNodes = function () {
        this.blockUtils.destroyRowNodes(this.allRowNodes);
        this.allRowNodes = [];
        this.nodesAfterSort = [];
        this.nodesAfterFilter = [];
        this.allNodesMap = {};
    };
    FullStore.prototype.initialiseRowNodes = function (loadingRowsCount, failedLoad) {
        if (failedLoad === void 0) { failedLoad = false; }
        this.destroyRowNodes();
        for (var i = 0; i < loadingRowsCount; i++) {
            var loadingRowNode = this.blockUtils.createRowNode({
                field: this.groupField, group: this.groupLevel, leafGroup: this.leafGroup,
                level: this.level, parent: this.parentRowNode, rowGroupColumn: this.rowGroupColumn
            });
            if (failedLoad) {
                loadingRowNode.failedLoad = true;
            }
            this.allRowNodes.push(loadingRowNode);
            this.nodesAfterFilter.push(loadingRowNode);
            this.nodesAfterSort.push(loadingRowNode);
        }
    };
    FullStore.prototype.getBlockStateJson = function () {
        return {
            id: this.nodeIdPrefix ? this.nodeIdPrefix : '',
            state: this.getState()
        };
    };
    FullStore.prototype.loadFromDatasource = function () {
        this.storeUtils.loadFromDatasource({
            startRow: undefined,
            endRow: undefined,
            parentBlock: this,
            parentNode: this.parentRowNode,
            storeParams: this.ssrmParams,
            success: this.success.bind(this, this.getVersion()),
            fail: this.pageLoadFailed.bind(this, this.getVersion())
        });
    };
    FullStore.prototype.getStartRow = function () {
        return 0; // always zero as not in a cache
    };
    FullStore.prototype.getEndRow = function () {
        return this.nodesAfterSort.length;
    };
    FullStore.prototype.createDataNode = function (data, index) {
        var rowNode = this.blockUtils.createRowNode({
            field: this.groupField, group: this.groupLevel, leafGroup: this.leafGroup,
            level: this.level, parent: this.parentRowNode, rowGroupColumn: this.rowGroupColumn
        });
        if (index != null) {
            _.insertIntoArray(this.allRowNodes, rowNode, index);
        }
        else {
            this.allRowNodes.push(rowNode);
        }
        var defaultId = this.prefixId(this.nodeIdSequence.next());
        this.blockUtils.setDataIntoRowNode(rowNode, data, defaultId, undefined);
        this.nodeManager.addRowNode(rowNode);
        this.blockUtils.checkOpenByDefault(rowNode);
        this.allNodesMap[rowNode.id] = rowNode;
        return rowNode;
    };
    FullStore.prototype.prefixId = function (id) {
        if (this.nodeIdPrefix) {
            return this.nodeIdPrefix + '-' + id;
        }
        else {
            return id.toString();
        }
    };
    FullStore.prototype.processServerFail = function () {
        this.initialiseRowNodes(1, true);
        this.fireStoreUpdatedEvent();
        this.flushAsyncTransactions();
    };
    FullStore.prototype.processServerResult = function (params) {
        if (!this.isAlive()) {
            return;
        }
        var info = params.groupLevelInfo;
        if (info) {
            Object.assign(this.info, info);
        }
        if (params.pivotResultFields) {
            this.serverSideRowModel.generateSecondaryColumns(params.pivotResultFields);
        }
        var nodesToRecycle = this.allRowNodes.length > 0 ? this.allNodesMap : undefined;
        this.allRowNodes = [];
        this.nodesAfterSort = [];
        this.nodesAfterFilter = [];
        this.allNodesMap = {};
        if (!params.rowData) {
            _.warnOnce('"params.data" is missing from Server-Side Row Model success() callback. Please use the "data" attribute. If no data is returned, set an empty list.');
        }
        this.createOrRecycleNodes(nodesToRecycle, params.rowData);
        if (nodesToRecycle) {
            this.blockUtils.destroyRowNodes(_.getAllValuesInObject(nodesToRecycle));
        }
        if (this.level === 0) {
            this.eventService.dispatchEventOnce({
                type: Events.EVENT_ROW_COUNT_READY
            });
        }
        this.filterAndSortNodes();
        this.fireStoreUpdatedEvent();
        this.flushAsyncTransactions();
    };
    FullStore.prototype.createOrRecycleNodes = function (nodesToRecycle, rowData) {
        var _this = this;
        if (!rowData) {
            return;
        }
        var lookupNodeToRecycle = function (data) {
            if (!nodesToRecycle) {
                return undefined;
            }
            var getRowIdFunc = _this.gridOptionsService.getCallback('getRowId');
            if (!getRowIdFunc) {
                return undefined;
            }
            var parentKeys = _this.parentRowNode.getGroupKeys();
            var level = _this.level;
            var id = getRowIdFunc({
                data: data,
                parentKeys: parentKeys.length > 0 ? parentKeys : undefined,
                level: level,
            });
            var foundNode = nodesToRecycle[id];
            if (!foundNode) {
                return undefined;
            }
            delete nodesToRecycle[id];
            return foundNode;
        };
        var recycleNode = function (rowNode, dataItem) {
            _this.allNodesMap[rowNode.id] = rowNode;
            _this.blockUtils.updateDataIntoRowNode(rowNode, dataItem);
            _this.allRowNodes.push(rowNode);
        };
        rowData.forEach(function (dataItem) {
            var nodeToRecycle = lookupNodeToRecycle(dataItem);
            if (nodeToRecycle) {
                recycleNode(nodeToRecycle, dataItem);
            }
            else {
                _this.createDataNode(dataItem);
            }
        });
    };
    FullStore.prototype.flushAsyncTransactions = function () {
        var _this = this;
        // we want to update the store with any outstanding transactions straight away,
        // as otherwise if waitTimeMillis is large (eg 5s), then the user could be looking
        // at old data for a few seconds before the transactions is applied, which isn't what
        // you would expect when we advertise 'transaction is applied when data is loaded'.
        // we do this in a timeout as flushAsyncTransactions expects the grid to be in a settled
        // state, not in the middle of loading rows! keeps the VM Turns more simple and deterministic.
        window.setTimeout(function () { return _this.transactionManager.flushAsyncTransactions(); }, 0);
    };
    FullStore.prototype.filterAndSortNodes = function () {
        this.filterRowNodes();
        this.sortRowNodes();
    };
    FullStore.prototype.sortRowNodes = function () {
        var serverIsSorting = this.storeUtils.isServerSideSortAllLevels() || this.storeUtils.isServerSideSortOnServer();
        var sortOptions = this.sortController.getSortOptions();
        var noSortApplied = !sortOptions || sortOptions.length == 0;
        if (serverIsSorting || noSortApplied) {
            this.nodesAfterSort = this.nodesAfterFilter;
            return;
        }
        this.nodesAfterSort = this.rowNodeSorter.doFullSort(this.nodesAfterFilter, sortOptions);
        if (this.postSortFunc) {
            var params = { nodes: this.nodesAfterSort };
            this.postSortFunc(params);
        }
    };
    FullStore.prototype.filterRowNodes = function () {
        var _this = this;
        var serverIsFiltering = !this.storeUtils.isServerSideOnlyRefreshFilteredGroups() || this.storeUtils.isServerSideFilterOnServer();
        // filtering for InFullStore only works at lowest level details.
        // reason is the logic for group filtering was to difficult to work out how it should work at time of writing.
        var groupLevel = this.groupLevel;
        if (serverIsFiltering || groupLevel) {
            this.nodesAfterFilter = this.allRowNodes;
            return;
        }
        this.nodesAfterFilter = this.allRowNodes.filter(function (rowNode) { return _this.filterManager.doesRowPassFilter({ rowNode: rowNode }); });
    };
    FullStore.prototype.clearDisplayIndexes = function () {
        var _this = this;
        this.displayIndexStart = undefined;
        this.displayIndexEnd = undefined;
        this.allRowNodes.forEach(function (rowNode) { return _this.blockUtils.clearDisplayIndex(rowNode); });
    };
    FullStore.prototype.getDisplayIndexEnd = function () {
        return this.displayIndexEnd;
    };
    FullStore.prototype.isDisplayIndexInStore = function (displayIndex) {
        if (this.getRowCount() === 0) {
            return false;
        }
        return displayIndex >= this.displayIndexStart && displayIndex < this.displayIndexEnd;
    };
    FullStore.prototype.setDisplayIndexes = function (displayIndexSeq, nextRowTop) {
        var _this = this;
        this.displayIndexStart = displayIndexSeq.peek();
        this.topPx = nextRowTop.value;
        var visibleNodeIds = {};
        // set on all visible nodes
        this.nodesAfterSort.forEach(function (rowNode) {
            _this.blockUtils.setDisplayIndex(rowNode, displayIndexSeq, nextRowTop);
            visibleNodeIds[rowNode.id] = true;
        });
        // and clear on all non-visible nodes
        this.allRowNodes.forEach(function (rowNode) {
            if (!visibleNodeIds[rowNode.id]) {
                _this.blockUtils.clearDisplayIndex(rowNode);
            }
        });
        this.displayIndexEnd = displayIndexSeq.peek();
        this.heightPx = nextRowTop.value - this.topPx;
    };
    FullStore.prototype.forEachStoreDeep = function (callback, sequence) {
        if (sequence === void 0) { sequence = new NumberSequence(); }
        callback(this, sequence.next());
        this.allRowNodes.forEach(function (rowNode) {
            var childCache = rowNode.childStore;
            if (childCache) {
                childCache.forEachStoreDeep(callback, sequence);
            }
        });
    };
    FullStore.prototype.forEachNodeDeep = function (callback, sequence) {
        if (sequence === void 0) { sequence = new NumberSequence(); }
        this.allRowNodes.forEach(function (rowNode) {
            callback(rowNode, sequence.next());
            var childCache = rowNode.childStore;
            if (childCache) {
                childCache.forEachNodeDeep(callback, sequence);
            }
        });
    };
    FullStore.prototype.forEachNodeDeepAfterFilterAndSort = function (callback, sequence, includeFooterNodes) {
        if (sequence === void 0) { sequence = new NumberSequence(); }
        if (includeFooterNodes === void 0) { includeFooterNodes = false; }
        this.nodesAfterSort.forEach(function (rowNode) {
            callback(rowNode, sequence.next());
            var childCache = rowNode.childStore;
            if (childCache) {
                childCache.forEachNodeDeepAfterFilterAndSort(callback, sequence, includeFooterNodes);
            }
        });
        if (includeFooterNodes && this.parentRowNode.sibling) {
            callback(this.parentRowNode.sibling, sequence.next());
        }
    };
    FullStore.prototype.getRowUsingDisplayIndex = function (displayRowIndex) {
        // this can happen if asking for a row that doesn't exist in the model,
        // eg if a cell range is selected, and the user filters so rows no longer exists
        if (!this.isDisplayIndexInStore(displayRowIndex)) {
            return undefined;
        }
        var res = this.blockUtils.binarySearchForDisplayIndex(displayRowIndex, this.nodesAfterSort);
        return res;
    };
    FullStore.prototype.getRowBounds = function (index) {
        for (var i = 0; i < this.nodesAfterSort.length; i++) {
            var rowNode = this.nodesAfterSort[i];
            var res = this.blockUtils.extractRowBounds(rowNode, index);
            if (res) {
                return res;
            }
        }
        return null;
    };
    FullStore.prototype.isPixelInRange = function (pixel) {
        return pixel >= this.topPx && pixel < (this.topPx + this.heightPx);
    };
    FullStore.prototype.getRowIndexAtPixel = function (pixel) {
        var _this = this;
        // if pixel before block, return first row
        var pixelBeforeThisStore = pixel <= this.topPx;
        if (pixelBeforeThisStore) {
            var firstNode = this.nodesAfterSort[0];
            return firstNode.rowIndex;
        }
        // if pixel after store, return last row, however the last
        // row could be a child store
        var pixelAfterThisStore = pixel >= (this.topPx + this.heightPx);
        if (pixelAfterThisStore) {
            var lastRowNode = this.nodesAfterSort[this.nodesAfterSort.length - 1];
            var lastRowNodeBottomPx = lastRowNode.rowTop + lastRowNode.rowHeight;
            if (pixel >= lastRowNodeBottomPx && lastRowNode.expanded) {
                if (lastRowNode.childStore && lastRowNode.childStore.getRowCount() > 0) {
                    return lastRowNode.childStore.getRowIndexAtPixel(pixel);
                }
                if (lastRowNode.detailNode) {
                    return lastRowNode.detailNode.rowIndex;
                }
            }
            return lastRowNode.rowIndex;
        }
        var res = null;
        this.nodesAfterSort.forEach(function (rowNode) {
            var res2 = _this.blockUtils.getIndexAtPixel(rowNode, pixel);
            if (res2 != null) {
                res = res2;
            }
        });
        var pixelIsPastLastRow = res == null;
        if (pixelIsPastLastRow) {
            return this.displayIndexEnd - 1;
        }
        return res;
    };
    FullStore.prototype.getChildStore = function (keys) {
        var _this = this;
        return this.storeUtils.getChildStore(keys, this, function (key) {
            var rowNode = _this.allRowNodes.find(function (currentRowNode) {
                return currentRowNode.key == key;
            });
            return rowNode;
        });
    };
    FullStore.prototype.forEachChildStoreShallow = function (callback) {
        this.allRowNodes.forEach(function (rowNode) {
            var childStore = rowNode.childStore;
            if (childStore) {
                callback(childStore);
            }
        });
    };
    FullStore.prototype.refreshAfterFilter = function (params) {
        var serverIsFiltering = this.storeUtils.isServerSideFilterOnServer();
        var storeIsImpacted = this.storeUtils.isServerRefreshNeeded(this.parentRowNode, this.ssrmParams.rowGroupCols, params);
        var serverIsFilteringAllLevels = !this.storeUtils.isServerSideOnlyRefreshFilteredGroups();
        if (serverIsFilteringAllLevels || (serverIsFiltering && storeIsImpacted)) {
            this.refreshStore(true);
            this.sortRowNodes();
            return;
        }
        this.filterRowNodes();
        this.sortRowNodes();
        this.forEachChildStoreShallow(function (store) { return store.refreshAfterFilter(params); });
    };
    FullStore.prototype.refreshAfterSort = function (params) {
        var serverIsSorting = this.storeUtils.isServerSideSortOnServer();
        var storeIsImpacted = this.storeUtils.isServerRefreshNeeded(this.parentRowNode, this.ssrmParams.rowGroupCols, params);
        var serverIsSortingAllLevels = this.storeUtils.isServerSideSortAllLevels();
        if (serverIsSortingAllLevels || (serverIsSorting && storeIsImpacted)) {
            this.refreshStore(true);
            this.filterRowNodes();
            return;
        }
        this.filterRowNodes();
        this.sortRowNodes();
        this.forEachChildStoreShallow(function (store) { return store.refreshAfterSort(params); });
    };
    FullStore.prototype.applyTransaction = function (transaction) {
        // we only apply transactions to loaded state
        switch (this.getState()) {
            case RowNodeBlock.STATE_FAILED:
                return { status: ServerSideTransactionResultStatus.StoreLoadingFailed };
            case RowNodeBlock.STATE_LOADING:
                return { status: ServerSideTransactionResultStatus.StoreLoading };
            case RowNodeBlock.STATE_WAITING_TO_LOAD:
                return { status: ServerSideTransactionResultStatus.StoreWaitingToLoad };
        }
        var applyCallback = this.gridOptionsService.getCallback('isApplyServerSideTransaction');
        if (applyCallback) {
            var params = {
                transaction: transaction,
                parentNode: this.parentRowNode,
                groupLevelInfo: this.info
            };
            var apply = applyCallback(params);
            if (!apply) {
                return { status: ServerSideTransactionResultStatus.Cancelled };
            }
        }
        var res = {
            status: ServerSideTransactionResultStatus.Applied,
            remove: [],
            update: [],
            add: []
        };
        var nodesToUnselect = [];
        this.executeAdd(transaction, res);
        this.executeRemove(transaction, res, nodesToUnselect);
        this.executeUpdate(transaction, res, nodesToUnselect);
        this.filterAndSortNodes();
        this.updateSelection(nodesToUnselect);
        return res;
    };
    FullStore.prototype.updateSelection = function (nodesToUnselect) {
        var selectionChanged = nodesToUnselect.length > 0;
        if (selectionChanged) {
            this.selectionService.setNodesSelected({
                newValue: false,
                nodes: nodesToUnselect,
                suppressFinishActions: true,
                clearSelection: false,
                source: 'rowDataChanged',
            });
            var event_1 = {
                type: Events.EVENT_SELECTION_CHANGED,
                source: 'rowDataChanged'
            };
            this.eventService.dispatchEvent(event_1);
        }
    };
    FullStore.prototype.executeAdd = function (rowDataTran, rowNodeTransaction) {
        var _this = this;
        var add = rowDataTran.add, addIndex = rowDataTran.addIndex;
        if (_.missingOrEmpty(add)) {
            return;
        }
        var useIndex = typeof addIndex === 'number' && addIndex >= 0;
        if (useIndex) {
            // items get inserted in reverse order for index insertion
            add.reverse().forEach(function (item) {
                var newRowNode = _this.createDataNode(item, addIndex);
                rowNodeTransaction.add.push(newRowNode);
            });
        }
        else {
            add.forEach(function (item) {
                var newRowNode = _this.createDataNode(item);
                rowNodeTransaction.add.push(newRowNode);
            });
        }
    };
    FullStore.prototype.executeRemove = function (rowDataTran, rowNodeTransaction, nodesToUnselect) {
        var _this = this;
        var remove = rowDataTran.remove;
        if (remove == null) {
            return;
        }
        var rowIdsRemoved = {};
        remove.forEach(function (item) {
            var rowNode = _this.lookupRowNode(item);
            if (!rowNode) {
                return;
            }
            // do delete - setting 'suppressFinishActions = true' to ensure EVENT_SELECTION_CHANGED is not raised for
            // each row node updated, instead it is raised once by the calling code if any selected nodes exist.
            if (rowNode.isSelected()) {
                nodesToUnselect.push(rowNode);
            }
            // so row renderer knows to fade row out (and not reposition it)
            rowNode.clearRowTopAndRowIndex();
            // NOTE: were we could remove from allLeaveChildren, however _.removeFromArray() is expensive, especially
            // if called multiple times (eg deleting lots of rows) and if allLeafChildren is a large list
            rowIdsRemoved[rowNode.id] = true;
            // _.removeFromArray(this.rootNode.allLeafChildren, rowNode);
            delete _this.allNodesMap[rowNode.id];
            rowNodeTransaction.remove.push(rowNode);
            _this.nodeManager.removeNode(rowNode);
        });
        this.allRowNodes = this.allRowNodes.filter(function (rowNode) { return !rowIdsRemoved[rowNode.id]; });
    };
    FullStore.prototype.executeUpdate = function (rowDataTran, rowNodeTransaction, nodesToUnselect) {
        var _this = this;
        var update = rowDataTran.update;
        if (update == null) {
            return;
        }
        update.forEach(function (item) {
            var rowNode = _this.lookupRowNode(item);
            if (!rowNode) {
                return;
            }
            _this.blockUtils.updateDataIntoRowNode(rowNode, item);
            if (!rowNode.selectable && rowNode.isSelected()) {
                nodesToUnselect.push(rowNode);
            }
            rowNodeTransaction.update.push(rowNode);
        });
    };
    FullStore.prototype.lookupRowNode = function (data) {
        var getRowIdFunc = this.gridOptionsService.getCallback('getRowId');
        var rowNode;
        if (getRowIdFunc != null) {
            // find rowNode using id
            var level = this.level;
            var parentKeys = this.parentRowNode.getGroupKeys();
            var id = getRowIdFunc({
                data: data,
                parentKeys: parentKeys.length > 0 ? parentKeys : undefined,
                level: level,
            });
            rowNode = this.allNodesMap[id];
            if (!rowNode) {
                console.error("AG Grid: could not find row id=".concat(id, ", data item was not found for this id"));
                return null;
            }
        }
        else {
            // find rowNode using object references
            rowNode = this.allRowNodes.find(function (currentRowNode) { return currentRowNode.data === data; });
            if (!rowNode) {
                console.error("AG Grid: could not find data item as object was not found", data);
                return null;
            }
        }
        return rowNode;
    };
    FullStore.prototype.addStoreStates = function (result) {
        result.push({
            suppressInfiniteScroll: true,
            route: this.parentRowNode.getGroupKeys(),
            rowCount: this.allRowNodes.length,
            info: this.info
        });
        this.forEachChildStoreShallow(function (childStore) { return childStore.addStoreStates(result); });
    };
    FullStore.prototype.refreshStore = function (purge) {
        if (purge) {
            var loadingRowsToShow = this.nodesAfterSort ? this.nodesAfterSort.length : 1;
            this.initialiseRowNodes(loadingRowsToShow);
        }
        this.scheduleLoad();
        this.fireStoreUpdatedEvent();
    };
    FullStore.prototype.retryLoads = function () {
        if (this.getState() === RowNodeBlock.STATE_FAILED) {
            this.initialiseRowNodes(1);
            this.scheduleLoad();
        }
        this.forEachChildStoreShallow(function (store) { return store.retryLoads(); });
    };
    FullStore.prototype.scheduleLoad = function () {
        this.setStateWaitingToLoad();
        this.rowNodeBlockLoader.checkBlockToLoad();
    };
    // gets called 1) row count changed 2) cache purged 3) items inserted
    FullStore.prototype.fireStoreUpdatedEvent = function () {
        // this results in row model firing ModelUpdated.
        // server side row model also updates the row indexes first
        var event = {
            type: Events.EVENT_STORE_UPDATED
        };
        this.eventService.dispatchEvent(event);
    };
    FullStore.prototype.getRowCount = function () {
        return this.nodesAfterSort.length;
    };
    FullStore.prototype.getTopLevelRowDisplayedIndex = function (topLevelIndex) {
        var rowNode = this.nodesAfterSort[topLevelIndex];
        return rowNode.rowIndex;
    };
    FullStore.prototype.isLastRowIndexKnown = function () {
        return this.getState() == RowNodeBlock.STATE_LOADED;
    };
    FullStore.prototype.getRowNodesInRange = function (firstInRange, lastInRange) {
        var result = [];
        var inActiveRange = false;
        // if only one node passed, we start the selection at the top
        if (_.missing(firstInRange)) {
            inActiveRange = true;
        }
        this.nodesAfterSort.forEach(function (rowNode) {
            var hitFirstOrLast = rowNode === firstInRange || rowNode === lastInRange;
            if (inActiveRange || hitFirstOrLast) {
                result.push(rowNode);
            }
            if (hitFirstOrLast) {
                inActiveRange = !inActiveRange;
            }
        });
        // inActiveRange will be still true if we never hit the second rowNode
        var invalidRange = inActiveRange;
        return invalidRange ? [] : result;
    };
    FullStore.prototype.getStoreBounds = function () {
        return {
            topPx: this.topPx,
            heightPx: this.heightPx,
        };
    };
    __decorate$v([
        Autowired('ssrmStoreUtils')
    ], FullStore.prototype, "storeUtils", void 0);
    __decorate$v([
        Autowired('ssrmBlockUtils')
    ], FullStore.prototype, "blockUtils", void 0);
    __decorate$v([
        Autowired('columnModel')
    ], FullStore.prototype, "columnModel", void 0);
    __decorate$v([
        Autowired('rowNodeBlockLoader')
    ], FullStore.prototype, "rowNodeBlockLoader", void 0);
    __decorate$v([
        Autowired('rowNodeSorter')
    ], FullStore.prototype, "rowNodeSorter", void 0);
    __decorate$v([
        Autowired('sortController')
    ], FullStore.prototype, "sortController", void 0);
    __decorate$v([
        Autowired('selectionService')
    ], FullStore.prototype, "selectionService", void 0);
    __decorate$v([
        Autowired('ssrmNodeManager')
    ], FullStore.prototype, "nodeManager", void 0);
    __decorate$v([
        Autowired('filterManager')
    ], FullStore.prototype, "filterManager", void 0);
    __decorate$v([
        Autowired('ssrmTransactionManager')
    ], FullStore.prototype, "transactionManager", void 0);
    __decorate$v([
        Autowired('rowModel')
    ], FullStore.prototype, "serverSideRowModel", void 0);
    __decorate$v([
        PostConstruct
    ], FullStore.prototype, "postConstruct", null);
    __decorate$v([
        PreDestroy
    ], FullStore.prototype, "destroyRowNodes", null);
    return FullStore;
}(RowNodeBlock));

var __extends$w = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$u = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$7 = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var LazyBlockLoader = /** @class */ (function (_super) {
    __extends$w(LazyBlockLoader, _super);
    function LazyBlockLoader(cache, parentNode, storeParams) {
        var _this = _super.call(this) || this;
        _this.loadingNodes = new Set();
        _this.checkForLoadQueued = false;
        _this.loaderTimeout = undefined;
        _this.nextBlockToLoad = undefined;
        _this.parentNode = parentNode;
        _this.cache = cache;
        _this.storeParams = storeParams;
        return _this;
    }
    LazyBlockLoader.prototype.init = function () {
        var _this = this;
        this.addManagedListener(this.rowNodeBlockLoader, RowNodeBlockLoader.BLOCK_LOADED_EVENT, function () { return _this.queueLoadAction(); });
    };
    LazyBlockLoader.prototype.isRowLoading = function (index) {
        return this.loadingNodes.has(index);
    };
    LazyBlockLoader.prototype.getBlockToLoad = function () {
        var _this = this;
        var _a;
        var firstRowInViewport = this.api.getFirstDisplayedRow();
        var lastRowInViewport = this.api.getLastDisplayedRow();
        // quick look-up for priority rows needing loading in viewport.
        for (var i = firstRowInViewport; i <= lastRowInViewport; i++) {
            var node = this.cache.getNodeCachedByDisplayIndex(i);
            if (!node) {
                // if no row details, ignore, as row hasn't been created
                // and it's too expensive to work out its location here
                continue;
            }
            var lazyNode = this.cache.getNodes().getBy('node', node);
            if (!lazyNode) {
                continue;
            }
            if (this.isRowLoading(lazyNode.index)) {
                continue;
            }
            if (node.__needsRefreshWhenVisible || (node.stub && !node.failedLoad)) {
                return this.getBlockStartIndexForIndex(lazyNode.index);
            }
        }
        var nodesToRefresh = this.cache.getNodesToRefresh();
        var nodeToRefresh = null;
        var nodeToRefreshDist = Number.MAX_SAFE_INTEGER;
        nodesToRefresh.forEach(function (node) {
            if (node.rowIndex == null) {
                nodeToRefresh = node;
                return;
            }
            if (_this.isRowLoading(node.rowIndex)) {
                return;
            }
            var distToViewportTop = Math.abs(firstRowInViewport - node.rowIndex);
            var distToViewportBottom = Math.abs(node.rowIndex - lastRowInViewport);
            if (distToViewportTop < nodeToRefreshDist) {
                nodeToRefresh = node;
                nodeToRefreshDist = distToViewportTop;
            }
            if (distToViewportBottom < nodeToRefreshDist) {
                nodeToRefresh = node;
                nodeToRefreshDist = distToViewportBottom;
            }
        });
        var lazyIndex = (_a = this.cache.getNodes().getBy('node', nodeToRefresh)) === null || _a === void 0 ? void 0 : _a.index;
        return lazyIndex == null ? undefined : this.getBlockStartIndexForIndex(lazyIndex);
    };
    LazyBlockLoader.prototype.reset = function () {
        this.loadingNodes.clear();
        clearTimeout(this.loaderTimeout);
        this.loaderTimeout = undefined;
    };
    LazyBlockLoader.prototype.executeLoad = function (startRow, endRow) {
        var _this = this;
        var _a;
        var ssrmParams = this.cache.getSsrmParams();
        var request = {
            startRow: startRow,
            endRow: endRow,
            rowGroupCols: ssrmParams.rowGroupCols,
            valueCols: ssrmParams.valueCols,
            pivotCols: ssrmParams.pivotCols,
            pivotMode: ssrmParams.pivotMode,
            groupKeys: this.parentNode.getGroupKeys(),
            filterModel: ssrmParams.filterModel,
            sortModel: ssrmParams.sortModel,
        };
        var removeNodesFromLoadingMap = function () {
            for (var i = 0; i < endRow - startRow; i++) {
                _this.loadingNodes.delete(startRow + i);
            }
        };
        var addNodesToLoadingMap = function () {
            for (var i = 0; i < endRow - startRow; i++) {
                _this.loadingNodes.add(startRow + i);
            }
        };
        var success = function (params) {
            _this.rowNodeBlockLoader.loadComplete();
            _this.cache.onLoadSuccess(startRow, endRow - startRow, params);
            removeNodesFromLoadingMap();
            _this.queueLoadAction();
        };
        var fail = function () {
            _this.rowNodeBlockLoader.loadComplete();
            _this.cache.onLoadFailed(startRow, endRow - startRow);
            removeNodesFromLoadingMap();
            _this.queueLoadAction();
        };
        var params = this.gridOptionsService.addGridCommonParams({
            request: request,
            success: success,
            fail: fail,
            parentNode: this.parentNode
        });
        addNodesToLoadingMap();
        (_a = this.cache.getSsrmParams().datasource) === null || _a === void 0 ? void 0 : _a.getRows(params);
    };
    LazyBlockLoader.prototype.getNextBlockToLoad = function () {
        var result = this.getBlockToLoad();
        if (result != null) {
            return [String(result), result + this.getBlockSize()];
        }
        return null;
    };
    LazyBlockLoader.prototype.queueLoadCheck = function () {
        var _this = this;
        // already going to check next cycle, ignore.
        if (this.checkForLoadQueued) {
            return;
        }
        this.checkForLoadQueued = true;
        window.queueMicrotask(function () {
            _this.checkForLoadQueued = false;
            _this.queueLoadAction();
        });
    };
    LazyBlockLoader.prototype.queueLoadAction = function () {
        var _this = this;
        var nextBlockToLoad = this.getNextBlockToLoad();
        if (!nextBlockToLoad) {
            // there's no block we should be loading right now, clear the timeouts
            window.clearTimeout(this.loaderTimeout);
            this.loaderTimeout = undefined;
            this.nextBlockToLoad = undefined;
            return;
        }
        // if the next required block has changed, reset the loading timeout
        if (!this.nextBlockToLoad || (this.nextBlockToLoad[0] !== nextBlockToLoad[0] && this.nextBlockToLoad[1] !== nextBlockToLoad[1])) {
            this.nextBlockToLoad = nextBlockToLoad;
            window.clearTimeout(this.loaderTimeout);
            var _a = __read$7(this.nextBlockToLoad, 2), startRowString = _a[0], endRow_1 = _a[1];
            var startRow_1 = Number(startRowString);
            this.loaderTimeout = window.setTimeout(function () {
                if (!_this.cache.isAlive()) {
                    return;
                }
                _this.loaderTimeout = undefined;
                _this.attemptLoad(startRow_1, endRow_1);
                _this.nextBlockToLoad = undefined;
            }, this.gridOptionsService.get('blockLoadDebounceMillis'));
        }
    };
    LazyBlockLoader.prototype.attemptLoad = function (start, end) {
        var availableLoadingCount = this.rowNodeBlockLoader.getAvailableLoadingCount();
        // too many loads already, ignore the request as a successful request will requeue itself anyway
        if (availableLoadingCount != null && availableLoadingCount === 0) {
            return;
        }
        this.rowNodeBlockLoader.registerLoads(1);
        this.executeLoad(start, end);
        this.queueLoadAction();
    };
    LazyBlockLoader.prototype.getBlockSize = function () {
        return this.storeParams.cacheBlockSize || LazyBlockLoader.DEFAULT_BLOCK_SIZE;
    };
    LazyBlockLoader.prototype.getBlockStartIndexForIndex = function (storeIndex) {
        var blockSize = this.getBlockSize();
        return storeIndex - (storeIndex % blockSize);
    };
    LazyBlockLoader.prototype.getBlockBoundsForIndex = function (storeIndex) {
        var startOfBlock = this.getBlockStartIndexForIndex(storeIndex);
        var blockSize = this.getBlockSize();
        return [startOfBlock, startOfBlock + blockSize];
    };
    LazyBlockLoader.DEFAULT_BLOCK_SIZE = 100;
    __decorate$u([
        Autowired('gridApi')
    ], LazyBlockLoader.prototype, "api", void 0);
    __decorate$u([
        Autowired('rowNodeBlockLoader')
    ], LazyBlockLoader.prototype, "rowNodeBlockLoader", void 0);
    __decorate$u([
        PostConstruct
    ], LazyBlockLoader.prototype, "init", null);
    return LazyBlockLoader;
}(BeanStub));

var MultiIndexMap = /** @class */ (function () {
    function MultiIndexMap() {
        var indexes = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            indexes[_i] = arguments[_i];
        }
        if (indexes.length < 1) {
            throw new Error('AG Grid: At least one index must be provided.');
        }
        this.indexes = indexes;
        this.maps = new Map(this.indexes.map(function (index) { return [index, new Map()]; }));
    }
    MultiIndexMap.prototype.getBy = function (index, key) {
        var map = this.maps.get(index);
        if (!map) {
            throw new Error("AG Grid: ".concat(String(index), " not found"));
        }
        return map.get(key);
    };
    MultiIndexMap.prototype.set = function (item) {
        var _this = this;
        this.indexes.forEach(function (index) {
            var map = _this.maps.get(index);
            if (!map) {
                throw new Error("AG Grid: ".concat(String(index), " not found"));
            }
            map.set(item[index], item);
        });
    };
    MultiIndexMap.prototype.delete = function (item) {
        var _this = this;
        this.indexes.forEach(function (index) {
            var map = _this.maps.get(index);
            if (!map) {
                throw new Error("AG Grid: ".concat(String(index), " not found"));
            }
            map.delete(item[index]);
        });
    };
    MultiIndexMap.prototype.clear = function () {
        this.maps.forEach(function (map) { return map.clear(); });
    };
    MultiIndexMap.prototype.getIterator = function (index) {
        var map = this.maps.get(index);
        if (!map) {
            throw new Error("AG Grid: ".concat(String(index), " not found"));
        }
        return map.values();
    };
    MultiIndexMap.prototype.forEach = function (callback) {
        var iterator = this.getIterator(this.indexes[0]);
        var pointer;
        while (pointer = iterator.next()) {
            if (pointer.done)
                break;
            callback(pointer.value);
        }
    };
    MultiIndexMap.prototype.find = function (callback) {
        var iterator = this.getIterator(this.indexes[0]);
        var pointer;
        while (pointer = iterator.next()) {
            if (pointer.done)
                break;
            if (callback(pointer.value)) {
                return pointer.value;
            }
        }
    };
    MultiIndexMap.prototype.filter = function (predicate) {
        var iterator = this.getIterator(this.indexes[0]);
        var pointer;
        var result = [];
        while (pointer = iterator.next()) {
            if (pointer.done)
                break;
            if (predicate(pointer.value)) {
                result.push(pointer.value);
            }
        }
        return result;
    };
    return MultiIndexMap;
}());

var __extends$v = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$t = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$6 = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$5 = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var LazyCache = /** @class */ (function (_super) {
    __extends$v(LazyCache, _super);
    function LazyCache(store, numberOfRows, storeParams) {
        var _this = _super.call(this) || this;
        /**
         * Indicates whether this is still the live dataset for this store (used for ignoring old requests after purge)
         */
        _this.live = true;
        /**
         * A cache of removed group nodes, this is retained for preserving group
         * state when the node moves in and out of the cache. Generally caused by
         * rows moving blocks.
         */
        _this.removedNodeCache = new Map();
        _this.store = store;
        _this.numberOfRows = numberOfRows;
        _this.isLastRowKnown = false;
        _this.storeParams = storeParams;
        return _this;
    }
    LazyCache.prototype.init = function () {
        // initiate the node map to be indexed at 'index', 'id' and 'node' for quick look-up.
        // it's important id isn't first, as stub nodes overwrite each-other, and the first index is
        // used for iteration.
        this.nodeMap = new MultiIndexMap('index', 'id', 'node');
        this.nodeDisplayIndexMap = new Map();
        this.nodesToRefresh = new Set();
        this.defaultNodeIdPrefix = this.blockUtils.createNodeIdPrefix(this.store.getParentNode());
        this.rowLoader = this.createManagedBean(new LazyBlockLoader(this, this.store.getParentNode(), this.storeParams));
        this.getRowIdFunc = this.gridOptionsService.getCallback('getRowId');
        this.isMasterDetail = this.gridOptionsService.get('masterDetail');
    };
    LazyCache.prototype.destroyRowNodes = function () {
        var _this = this;
        this.numberOfRows = 0;
        this.nodeMap.forEach(function (node) { return _this.blockUtils.destroyRowNode(node.node); });
        this.nodeMap.clear();
        this.nodeDisplayIndexMap.clear();
        this.nodesToRefresh.clear();
        this.live = false;
    };
    /**
     * Get the row node for a specific display index from this store
     * @param displayIndex the display index of the node to find
     * @returns undefined if the node is not in the store bounds, otherwise will always return a node
     */
    LazyCache.prototype.getRowByDisplayIndex = function (displayIndex) {
        var _a, _b, _c, _d;
        // if index isn't in store, nothing to return
        if (!this.store.isDisplayIndexInStore(displayIndex)) {
            return undefined;
        }
        // first try to directly look this node up in the display index map
        var node = this.nodeDisplayIndexMap.get(displayIndex);
        if (node) {
            // if we have the node, check if it needs refreshed when rendered
            if (node.stub || node.__needsRefreshWhenVisible) {
                this.rowLoader.queueLoadCheck();
            }
            return node;
        }
        // next check if this is the first row, if so return a stub node
        // this is a performance optimisation, as it is the most common scenario
        // and enables the node - 1 check to kick in more often.
        if (displayIndex === this.store.getDisplayIndexStart()) {
            return this.createStubNode(0, displayIndex);
        }
        // check if the row immediately prior is available in the store
        var contiguouslyPreviousNode = this.nodeDisplayIndexMap.get(displayIndex - 1);
        if (contiguouslyPreviousNode) {
            // if previous row is master detail, and expanded, this node must be detail
            if (this.isMasterDetail && contiguouslyPreviousNode.master && contiguouslyPreviousNode.expanded) {
                return contiguouslyPreviousNode.detailNode;
            }
            // if previous row is expanded group, this node will belong to that group.
            if (contiguouslyPreviousNode.expanded && ((_a = contiguouslyPreviousNode.childStore) === null || _a === void 0 ? void 0 : _a.isDisplayIndexInStore(displayIndex))) {
                return (_b = contiguouslyPreviousNode.childStore) === null || _b === void 0 ? void 0 : _b.getRowUsingDisplayIndex(displayIndex);
            }
            // otherwise, row must be a stub node
            var lazyCacheNode = this.nodeMap.getBy('node', contiguouslyPreviousNode);
            return this.createStubNode(lazyCacheNode.index + 1, displayIndex);
        }
        var adjacentNodes = this.getSurroundingNodesByDisplayIndex(displayIndex);
        // if no bounds skipped includes this, calculate from end index
        if (adjacentNodes == null) {
            var storeIndexFromEndIndex_1 = this.store.getRowCount() - (this.store.getDisplayIndexEnd() - displayIndex);
            return this.createStubNode(storeIndexFromEndIndex_1, displayIndex);
        }
        var previousNode = adjacentNodes.previousNode, nextNode = adjacentNodes.nextNode;
        // if the node before this node is expanded, this node might be a child of that node
        if (previousNode && previousNode.node.expanded && ((_c = previousNode.node.childStore) === null || _c === void 0 ? void 0 : _c.isDisplayIndexInStore(displayIndex))) {
            return (_d = previousNode.node.childStore) === null || _d === void 0 ? void 0 : _d.getRowUsingDisplayIndex(displayIndex);
        }
        // if we have the node after this node, we can calculate the store index of this node by the difference
        // in display indexes between the two nodes.
        if (nextNode) {
            var displayIndexDiff = nextNode.node.rowIndex - displayIndex;
            var newStoreIndex = nextNode.index - displayIndexDiff;
            return this.createStubNode(newStoreIndex, displayIndex);
        }
        // if no next node, calculate from end index of this store
        var storeIndexFromEndIndex = this.store.getRowCount() - (this.store.getDisplayIndexEnd() - displayIndex);
        return this.createStubNode(storeIndexFromEndIndex, displayIndex);
    };
    /**
     * Used for creating and positioning a stub node without firing a store updated event
     */
    LazyCache.prototype.createStubNode = function (storeIndex, displayIndex) {
        var _this = this;
        // bounds are acquired before creating the node, as otherwise it'll use it's own empty self to calculate
        var rowBounds = this.store.getRowBounds(displayIndex);
        var newNode = this.createRowAtIndex(storeIndex, null, function (node) {
            node.setRowIndex(displayIndex);
            node.setRowTop(rowBounds.rowTop);
            _this.nodeDisplayIndexMap.set(displayIndex, node);
        });
        this.rowLoader.queueLoadCheck();
        return newNode;
    };
    /**
     * @param index The row index relative to this store
     * @returns A rowNode at the given store index
     */
    LazyCache.prototype.getRowByStoreIndex = function (index) {
        var _a;
        return (_a = this.nodeMap.getBy('index', index)) === null || _a === void 0 ? void 0 : _a.node;
    };
    /**
     * Given a number of rows, skips through the given sequence & row top reference (using default row height)
     * @param numberOfRowsToSkip number of rows to skip over in the given sequence
     * @param displayIndexSeq the sequence in which to skip
     * @param nextRowTop the row top reference in which to skip
     */
    LazyCache.prototype.skipDisplayIndexes = function (numberOfRowsToSkip, displayIndexSeq, nextRowTop) {
        if (numberOfRowsToSkip === 0) {
            return;
        }
        var defaultRowHeight = this.gridOptionsService.getRowHeightAsNumber();
        displayIndexSeq.skip(numberOfRowsToSkip);
        nextRowTop.value += numberOfRowsToSkip * defaultRowHeight;
    };
    /**
     * @param displayIndexSeq the number sequence for generating the display index of each row
     * @param nextRowTop an object containing the next row top value intended to be modified by ref per row
     */
    LazyCache.prototype.setDisplayIndexes = function (displayIndexSeq, nextRowTop) {
        // Create a map of display index nodes for access speed
        this.nodeDisplayIndexMap.clear();
        // create an object indexed by store index, as this will sort all of the nodes when we iterate
        // the object
        var orderedMap = {};
        this.nodeMap.forEach(function (lazyNode) {
            orderedMap[lazyNode.index] = lazyNode.node;
        });
        var lastIndex = -1;
        // iterate over the nodes in order, setting the display index on each node.
        for (var stringIndex in orderedMap) {
            var node = orderedMap[stringIndex];
            var numericIndex = Number(stringIndex);
            // if any nodes aren't currently in the store, skip the display indexes too
            var numberOfRowsToSkip_1 = (numericIndex - 1) - lastIndex;
            this.skipDisplayIndexes(numberOfRowsToSkip_1, displayIndexSeq, nextRowTop);
            // set this nodes index and row top
            this.blockUtils.setDisplayIndex(node, displayIndexSeq, nextRowTop);
            this.nodeDisplayIndexMap.set(node.rowIndex, node);
            // store this index for skipping after this
            lastIndex = numericIndex;
        }
        // need to skip rows until the end of this store
        var numberOfRowsToSkip = (this.numberOfRows - 1) - lastIndex;
        this.skipDisplayIndexes(numberOfRowsToSkip, displayIndexSeq, nextRowTop);
        // this is not terribly efficient, and could probs be improved
        this.purgeExcessRows();
    };
    LazyCache.prototype.getRowCount = function () {
        return this.numberOfRows;
    };
    LazyCache.prototype.setRowCount = function (rowCount, isLastRowIndexKnown) {
        if (rowCount < 0) {
            throw new Error('AG Grid: setRowCount can only accept a positive row count.');
        }
        this.numberOfRows = rowCount;
        if (isLastRowIndexKnown != null) {
            this.isLastRowKnown = isLastRowIndexKnown;
            if (isLastRowIndexKnown === false) {
                this.numberOfRows += 1;
            }
        }
        this.fireStoreUpdatedEvent();
    };
    LazyCache.prototype.getNodes = function () {
        return this.nodeMap;
    };
    LazyCache.prototype.getNodeCachedByDisplayIndex = function (displayIndex) {
        var _a;
        return (_a = this.nodeDisplayIndexMap.get(displayIndex)) !== null && _a !== void 0 ? _a : null;
    };
    LazyCache.prototype.getNodesToRefresh = function () {
        return this.nodesToRefresh;
    };
    /**
     * @returns the previous and next loaded row nodes surrounding the given display index
     */
    LazyCache.prototype.getSurroundingNodesByDisplayIndex = function (displayIndex) {
        var nextNode;
        var previousNode;
        this.nodeMap.forEach(function (lazyNode) {
            // previous node
            if (displayIndex > lazyNode.node.rowIndex) {
                // get the largest previous node
                if (previousNode == null || previousNode.node.rowIndex < lazyNode.node.rowIndex) {
                    previousNode = lazyNode;
                }
                return;
            }
            // next node
            // get the smallest next node
            if (nextNode == null || nextNode.node.rowIndex > lazyNode.node.rowIndex) {
                nextNode = lazyNode;
                return;
            }
        });
        if (!previousNode && !nextNode)
            return null;
        return { previousNode: previousNode, nextNode: nextNode };
    };
    /**
     * Get or calculate the display index for a given store index
     * @param storeIndex the rows index within this store
     * @returns the rows visible display index relative to the grid
     */
    LazyCache.prototype.getDisplayIndexFromStoreIndex = function (storeIndex) {
        var _a, _b;
        var nodeAtIndex = this.nodeMap.getBy('index', storeIndex);
        if (nodeAtIndex) {
            return nodeAtIndex.node.rowIndex;
        }
        var nextNode;
        var previousNode;
        this.nodeMap.forEach(function (lazyNode) {
            // previous node
            if (storeIndex > lazyNode.index) {
                // get the largest previous node
                if (previousNode == null || previousNode.index < lazyNode.index) {
                    previousNode = lazyNode;
                }
                return;
            }
            // next node
            // get the smallest next node
            if (nextNode == null || nextNode.index > lazyNode.index) {
                nextNode = lazyNode;
                return;
            }
        });
        if (!nextNode) {
            return this.store.getDisplayIndexEnd() - (this.numberOfRows - storeIndex);
        }
        if (!previousNode) {
            return this.store.getDisplayIndexStart() + storeIndex;
        }
        var storeIndexDiff = storeIndex - previousNode.index;
        var previousDisplayIndex = ((_b = (_a = previousNode.node.childStore) === null || _a === void 0 ? void 0 : _a.getDisplayIndexEnd()) !== null && _b !== void 0 ? _b : previousNode.node.rowIndex);
        return previousDisplayIndex + storeIndexDiff;
    };
    /**
     * Creates a new row and inserts it at the given index
     * @param atStoreIndex the node index relative to this store
     * @param data the data object to populate the node with
     * @returns the new row node
     */
    LazyCache.prototype.createRowAtIndex = function (atStoreIndex, data, createNodeCallback) {
        var _a, _b;
        // make sure an existing node isn't being overwritten
        var lazyNode = this.nodeMap.getBy('index', atStoreIndex);
        // if node already exists, update it or destroy it
        if (lazyNode) {
            var node = lazyNode.node;
            node.__needsRefreshWhenVisible = false;
            // if the node is the same, just update the content
            if (this.doesNodeMatch(data, node)) {
                this.blockUtils.updateDataIntoRowNode(node, data);
                this.nodesToRefresh.delete(node);
                return node;
            }
            // if there's no id and this is an open group, protect this node from changes
            // hasChildren also checks for tree data and master detail
            if (this.getRowIdFunc == null && node.hasChildren() && node.expanded) {
                this.nodesToRefresh.delete(node);
                return node;
            }
            // destroy the old node, might be worth caching state here
            this.destroyRowAtIndex(atStoreIndex);
        }
        // if the node already exists elsewhere, update it and move it to the new location
        if (data && this.getRowIdFunc != null) {
            var id = this.getRowId(data);
            // the node was deleted at some point, but as we're refreshing
            // it's been cached and we can retrieve it for reuse.
            var deletedNode = id && ((_a = this.removedNodeCache) === null || _a === void 0 ? void 0 : _a.get(id));
            if (deletedNode) {
                (_b = this.removedNodeCache) === null || _b === void 0 ? void 0 : _b.delete(id);
                this.blockUtils.updateDataIntoRowNode(deletedNode, data);
                this.nodeMap.set({
                    id: deletedNode.id,
                    node: deletedNode,
                    index: atStoreIndex
                });
                return deletedNode;
            }
            var lazyNode_1 = this.nodeMap.getBy('id', id);
            if (lazyNode_1) {
                // delete old lazy node so we can insert it at different location
                this.nodeMap.delete(lazyNode_1);
                var node = lazyNode_1.node, index = lazyNode_1.index;
                this.blockUtils.updateDataIntoRowNode(node, data);
                this.nodeMap.set({
                    id: node.id,
                    node: node,
                    index: atStoreIndex
                });
                this.nodesToRefresh.delete(node);
                if (this.rowLoader.getBlockStartIndexForIndex(index) === this.rowLoader.getBlockStartIndexForIndex(atStoreIndex)) {
                    // if the block hasn't changed and we have a nodes map, we don't need to refresh the original block, as this block
                    // has just been refreshed.
                    return node;
                }
                // mark all of the old block as needsVerify to trigger it for a refresh, as nodes
                // should not be out of place
                this.markBlockForVerify(index);
                return node;
            }
        }
        // node doesn't exist, create a new one
        var newNode = this.blockUtils.createRowNode(this.store.getRowDetails());
        if (data != null) {
            var defaultId = this.getPrefixedId(this.store.getIdSequence().next());
            this.blockUtils.setDataIntoRowNode(newNode, data, defaultId, undefined);
            this.blockUtils.checkOpenByDefault(newNode);
            this.nodeManager.addRowNode(newNode);
        }
        // add the new node to the store, has to be done after the display index is calculated so it doesn't take itself into account
        this.nodeMap.set({
            id: newNode.id,
            node: newNode,
            index: atStoreIndex,
        });
        if (createNodeCallback) {
            createNodeCallback(newNode);
        }
        return newNode;
    };
    LazyCache.prototype.getBlockStates = function () {
        var _this = this;
        var blockCounts = {};
        var blockStates = {};
        this.nodeMap.forEach(function (_a) {
            var _b;
            var node = _a.node, index = _a.index;
            var blockStart = _this.rowLoader.getBlockStartIndexForIndex(index);
            if (!node.stub && !node.failedLoad) {
                blockCounts[blockStart] = ((_b = blockCounts[blockStart]) !== null && _b !== void 0 ? _b : 0) + 1;
            }
            var rowState = 'loaded';
            if (node.failedLoad) {
                rowState = 'failed';
            }
            else if (_this.rowLoader.isRowLoading(blockStart)) {
                rowState = 'loading';
            }
            else if (_this.nodesToRefresh.has(node) || node.stub) {
                rowState = 'needsLoading';
            }
            if (!blockStates[blockStart]) {
                blockStates[blockStart] = new Set();
            }
            blockStates[blockStart].add(rowState);
        });
        var statePriorityMap = {
            loading: 4,
            failed: 3,
            needsLoading: 2,
            loaded: 1,
        };
        var blockPrefix = this.blockUtils.createNodeIdPrefix(this.store.getParentNode());
        var results = {};
        Object.entries(blockStates).forEach(function (_a) {
            var _b;
            var _c = __read$6(_a, 2), blockStart = _c[0], uniqueStates = _c[1];
            var sortedStates = __spreadArray$5([], __read$6(uniqueStates), false).sort(function (a, b) { var _a, _b; return ((_a = statePriorityMap[a]) !== null && _a !== void 0 ? _a : 0) - ((_b = statePriorityMap[b]) !== null && _b !== void 0 ? _b : 0); });
            var priorityState = sortedStates[0];
            var blockNumber = Number(blockStart) / _this.rowLoader.getBlockSize();
            var blockId = blockPrefix ? "".concat(blockPrefix, "-").concat(blockNumber) : String(blockNumber);
            results[blockId] = {
                blockNumber: blockNumber,
                startRow: Number(blockStart),
                endRow: Number(blockStart) + _this.rowLoader.getBlockSize(),
                pageStatus: priorityState,
                loadedRowCount: (_b = blockCounts[blockStart]) !== null && _b !== void 0 ? _b : 0,
            };
        });
        return results;
    };
    LazyCache.prototype.destroyRowAtIndex = function (atStoreIndex) {
        var lazyNode = this.nodeMap.getBy('index', atStoreIndex);
        if (!lazyNode) {
            return;
        }
        this.nodeMap.delete(lazyNode);
        this.nodeDisplayIndexMap.delete(lazyNode.node.rowIndex);
        if (lazyNode.node.hasChildren() && this.nodesToRefresh.size > 0) {
            // while refreshing, we retain the group nodes so they can be moved
            // without losing state
            this.removedNodeCache.set(lazyNode.node.id, lazyNode.node);
        }
        else {
            this.blockUtils.destroyRowNode(lazyNode.node);
        }
        this.nodesToRefresh.delete(lazyNode.node);
    };
    LazyCache.prototype.getSsrmParams = function () {
        return this.store.getSsrmParams();
    };
    /**
     * @param id the base id to be prefixed
     * @returns a node id with prefix if required
     */
    LazyCache.prototype.getPrefixedId = function (id) {
        if (this.defaultNodeIdPrefix) {
            return this.defaultNodeIdPrefix + '-' + id;
        }
        else {
            return id.toString();
        }
    };
    LazyCache.prototype.markBlockForVerify = function (rowIndex) {
        var _a = __read$6(this.rowLoader.getBlockBoundsForIndex(rowIndex), 2), start = _a[0], end = _a[1];
        var lazyNodesInRange = this.nodeMap.filter(function (lazyNode) { return lazyNode.index >= start && lazyNode.index < end; });
        lazyNodesInRange.forEach(function (_a) {
            var node = _a.node;
            node.__needsRefreshWhenVisible = true;
        });
    };
    LazyCache.prototype.doesNodeMatch = function (data, node) {
        if (node.stub) {
            return false;
        }
        if (this.getRowIdFunc != null) {
            var id = this.getRowId(data);
            return node.id === id;
        }
        return node.data === data;
    };
    /**
     * Deletes any stub nodes not within the given range
     */
    LazyCache.prototype.purgeStubsOutsideOfViewport = function () {
        var _this = this;
        var firstRow = this.api.getFirstDisplayedRow();
        var lastRow = this.api.getLastDisplayedRow();
        var firstRowBlockStart = this.rowLoader.getBlockStartIndexForIndex(firstRow);
        var _a = __read$6(this.rowLoader.getBlockBoundsForIndex(lastRow), 2); _a[0]; var lastRowBlockEnd = _a[1];
        this.nodeMap.forEach(function (lazyNode) {
            // failed loads are still useful, so we don't purge them
            if (_this.rowLoader.isRowLoading(lazyNode.index) || lazyNode.node.failedLoad) {
                return;
            }
            if (lazyNode.node.stub && (lazyNode.index < firstRowBlockStart || lazyNode.index > lastRowBlockEnd)) {
                _this.destroyRowAtIndex(lazyNode.index);
            }
        });
    };
    LazyCache.prototype.getBlocksDistanceFromRow = function (nodes, otherDisplayIndex) {
        var _this = this;
        var blockDistanceToMiddle = {};
        nodes.forEach(function (_a) {
            var node = _a.node, index = _a.index;
            var _b = __read$6(_this.rowLoader.getBlockBoundsForIndex(index), 2), blockStart = _b[0], blockEnd = _b[1];
            if (blockStart in blockDistanceToMiddle) {
                return;
            }
            var distStart = Math.abs(node.rowIndex - otherDisplayIndex);
            var distEnd;
            // may not have an end node if the block came back small 
            var lastLazyNode = _this.nodeMap.getBy('index', [blockEnd - 1]);
            if (lastLazyNode)
                distEnd = Math.abs(lastLazyNode.node.rowIndex - otherDisplayIndex);
            var farthest = distEnd == null || distStart < distEnd ? distStart : distEnd;
            blockDistanceToMiddle[blockStart] = farthest;
        });
        return Object.entries(blockDistanceToMiddle);
    };
    LazyCache.prototype.purgeExcessRows = function () {
        var _this = this;
        var _a;
        // Delete all stub nodes which aren't in the viewport or already loading
        this.purgeStubsOutsideOfViewport();
        if (this.store.getDisplayIndexEnd() == null || this.storeParams.maxBlocksInCache == null) {
            // if group is collapsed, or max blocks missing, ignore the event
            return;
        }
        var firstRowInViewport = this.api.getFirstDisplayedRow();
        var lastRowInViewport = this.api.getLastDisplayedRow();
        // the start storeIndex of every block in this store
        var allLoadedBlocks = new Set();
        // the start storeIndex of every displayed block in this store
        var blocksInViewport = new Set();
        this.nodeMap.forEach(function (_a) {
            var index = _a.index, node = _a.node;
            var blockStart = _this.rowLoader.getBlockStartIndexForIndex(index);
            allLoadedBlocks.add(blockStart);
            var isInViewport = node.rowIndex >= firstRowInViewport && node.rowIndex <= lastRowInViewport;
            if (isInViewport) {
                blocksInViewport.add(blockStart);
            }
        });
        // if the viewport is larger than the max blocks, then the viewport size is minimum cache size
        var numberOfBlocksToRetain = Math.max(blocksInViewport.size, (_a = this.storeParams.maxBlocksInCache) !== null && _a !== void 0 ? _a : 0);
        // ensure there is blocks that can be removed
        var loadedBlockCount = allLoadedBlocks.size;
        var blocksToRemove = loadedBlockCount - numberOfBlocksToRetain;
        if (blocksToRemove <= 0) {
            return;
        }
        // the first and last block in the viewport
        var firstRowBlockStart = Number.MAX_SAFE_INTEGER;
        var lastRowBlockStart = Number.MIN_SAFE_INTEGER;
        blocksInViewport.forEach(function (blockStart) {
            if (firstRowBlockStart > blockStart) {
                firstRowBlockStart = blockStart;
            }
            if (lastRowBlockStart < blockStart) {
                lastRowBlockStart = blockStart;
            }
        });
        // all nodes which aren't cached or in the viewport, and so can be removed
        var disposableNodes = this.nodeMap.filter(function (_a) {
            var node = _a.node, index = _a.index;
            var rowBlockStart = _this.rowLoader.getBlockStartIndexForIndex(index);
            var rowBlockInViewport = rowBlockStart >= firstRowBlockStart && rowBlockStart <= lastRowBlockStart;
            return !rowBlockInViewport && !_this.isNodeCached(node);
        });
        if (disposableNodes.length === 0) {
            return;
        }
        var midViewportRow = firstRowInViewport + ((lastRowInViewport - firstRowInViewport) / 2);
        var blockDistanceArray = this.getBlocksDistanceFromRow(disposableNodes, midViewportRow);
        var blockSize = this.rowLoader.getBlockSize();
        // sort the blocks by distance from middle of viewport
        blockDistanceArray.sort(function (a, b) { return Math.sign(b[1] - a[1]); });
        // remove excess blocks, starting from furthest from viewport
        for (var i = 0; i < Math.min(blocksToRemove, blockDistanceArray.length); i++) {
            var blockStart = Number(blockDistanceArray[i][0]);
            for (var x = blockStart; x < blockStart + blockSize; x++) {
                var lazyNode = this.nodeMap.getBy('index', x);
                if (!lazyNode || this.isNodeCached(lazyNode.node)) {
                    continue;
                }
                this.destroyRowAtIndex(x);
            }
        }
    };
    LazyCache.prototype.isNodeFocused = function (node) {
        var focusedCell = this.focusService.getFocusCellToUseAfterRefresh();
        if (!focusedCell) {
            return false;
        }
        if (focusedCell.rowPinned != null) {
            return false;
        }
        var hasFocus = focusedCell.rowIndex === node.rowIndex;
        return hasFocus;
    };
    LazyCache.prototype.isNodeCached = function (node) {
        return (node.isExpandable() && node.expanded) || this.isNodeFocused(node);
    };
    LazyCache.prototype.extractDuplicateIds = function (rows) {
        var _this = this;
        if (this.getRowIdFunc == null) {
            return [];
        }
        var newIds = new Set();
        var duplicates = new Set();
        rows.forEach(function (data) {
            var id = _this.getRowId(data);
            if (newIds.has(id)) {
                duplicates.add(id);
                return;
            }
            newIds.add(id);
        });
        return __spreadArray$5([], __read$6(duplicates), false);
    };
    LazyCache.prototype.onLoadSuccess = function (firstRowIndex, numberOfRowsExpected, response) {
        var _this = this;
        if (!this.live)
            return;
        var info = response.groupLevelInfo;
        this.store.setStoreInfo(info);
        if (this.getRowIdFunc != null) {
            var duplicates = this.extractDuplicateIds(response.rowData);
            if (duplicates.length > 0) {
                var duplicateIdText = duplicates.join(', ');
                console.warn("AG Grid: Unable to display rows as duplicate row ids (".concat(duplicateIdText, ") were returned by the getRowId callback. Please modify the getRowId callback to provide unique ids."));
                this.onLoadFailed(firstRowIndex, numberOfRowsExpected);
                return;
            }
        }
        if (response.pivotResultFields) {
            this.serverSideRowModel.generateSecondaryColumns(response.pivotResultFields);
        }
        var wasRefreshing = this.nodesToRefresh.size > 0;
        response.rowData.forEach(function (data, responseRowIndex) {
            var _a;
            var rowIndex = firstRowIndex + responseRowIndex;
            var nodeFromCache = _this.nodeMap.getBy('index', rowIndex);
            // if stub, overwrite
            if ((_a = nodeFromCache === null || nodeFromCache === void 0 ? void 0 : nodeFromCache.node) === null || _a === void 0 ? void 0 : _a.stub) {
                _this.createRowAtIndex(rowIndex, data);
                return;
            }
            // node already exists, and same as node at designated position, update data
            if (nodeFromCache && _this.doesNodeMatch(data, nodeFromCache.node)) {
                _this.blockUtils.updateDataIntoRowNode(nodeFromCache.node, data);
                _this.nodesToRefresh.delete(nodeFromCache.node);
                nodeFromCache.node.__needsRefreshWhenVisible = false;
                return;
            }
            // create row will handle deleting the overwritten row
            _this.createRowAtIndex(rowIndex, data);
        });
        if (response.rowCount != undefined && response.rowCount !== -1) {
            // if the rowCount has been provided, set the row count
            this.numberOfRows = response.rowCount;
            this.isLastRowKnown = true;
        }
        else if (numberOfRowsExpected > response.rowData.length) {
            // infer the last row as the response came back short
            this.numberOfRows = firstRowIndex + response.rowData.length;
            this.isLastRowKnown = true;
        }
        else if (!this.isLastRowKnown) {
            // add 1 for loading row, as we don't know the last row
            var lastInferredRow = firstRowIndex + response.rowData.length + 1;
            if (lastInferredRow > this.numberOfRows) {
                this.numberOfRows = lastInferredRow;
            }
        }
        if (this.isLastRowKnown) {
            // delete any rows after the last index
            var lazyNodesAfterStoreEnd = this.nodeMap.filter(function (lazyNode) { return lazyNode.index >= _this.numberOfRows; });
            lazyNodesAfterStoreEnd.forEach(function (lazyNode) { return _this.destroyRowAtIndex(lazyNode.index); });
        }
        this.fireStoreUpdatedEvent();
        // Happens after store updated, as store updating can clear our excess rows.
        var finishedRefreshing = this.nodesToRefresh.size === 0;
        if (wasRefreshing && finishedRefreshing) {
            this.fireRefreshFinishedEvent();
        }
    };
    LazyCache.prototype.fireRefreshFinishedEvent = function () {
        var _this = this;
        var finishedRefreshing = this.nodesToRefresh.size === 0;
        // if anything refreshing currently, skip.
        if (!finishedRefreshing) {
            return;
        }
        // any nodes left in the map need to be cleaned up, this prevents us preserving nodes
        // indefinitely
        this.removedNodeCache.forEach(function (node) {
            _this.blockUtils.destroyRowNode(node);
        });
        this.removedNodeCache = new Map();
        this.store.fireRefreshFinishedEvent();
    };
    LazyCache.prototype.isLastRowIndexKnown = function () {
        return this.isLastRowKnown;
    };
    LazyCache.prototype.onLoadFailed = function (firstRowIndex, numberOfRowsExpected) {
        var _a;
        if (!this.live)
            return;
        var wasRefreshing = this.nodesToRefresh.size > 0;
        for (var i = firstRowIndex; i < firstRowIndex + numberOfRowsExpected && i < this.getRowCount(); i++) {
            var node = ((_a = this.nodeMap.getBy('index', i)) !== null && _a !== void 0 ? _a : {}).node;
            if (node) {
                this.nodesToRefresh.delete(node);
            }
            if (!node || !node.stub) {
                if (node && !node.stub) {
                    // if node is not a stub, we destroy it and recreate as nodes can't go from data to stub
                    this.destroyRowAtIndex(i);
                }
                node = this.createRowAtIndex(i);
            }
            // this node has been refreshed, even if it wasn't successful
            node.__needsRefreshWhenVisible = false;
            node.failedLoad = true;
        }
        var finishedRefreshing = this.nodesToRefresh.size === 0;
        if (wasRefreshing && finishedRefreshing) {
            this.fireRefreshFinishedEvent();
        }
        this.fireStoreUpdatedEvent();
    };
    LazyCache.prototype.markNodesForRefresh = function () {
        var _this = this;
        this.nodeMap.forEach(function (lazyNode) {
            if (lazyNode.node.stub && !lazyNode.node.failedLoad) {
                return;
            }
            _this.nodesToRefresh.add(lazyNode.node);
        });
        this.rowLoader.queueLoadCheck();
        if (this.isLastRowKnown && this.numberOfRows === 0) {
            this.numberOfRows = 1;
            this.isLastRowKnown = false;
            this.fireStoreUpdatedEvent();
        }
    };
    LazyCache.prototype.isNodeInCache = function (id) {
        return !!this.nodeMap.getBy('id', id);
    };
    // gets called 1) row count changed 2) cache purged 3) items inserted
    LazyCache.prototype.fireStoreUpdatedEvent = function () {
        if (!this.live) {
            return;
        }
        this.store.fireStoreUpdatedEvent();
    };
    LazyCache.prototype.getRowId = function (data) {
        if (this.getRowIdFunc == null) {
            return null;
        }
        // find rowNode using id
        var level = this.store.getRowDetails().level;
        var parentKeys = this.store.getParentNode().getGroupKeys();
        var id = this.getRowIdFunc({
            data: data,
            parentKeys: parentKeys.length > 0 ? parentKeys : undefined,
            level: level,
        });
        return String(id);
    };
    LazyCache.prototype.updateRowNodes = function (updates) {
        var _this = this;
        if (this.getRowIdFunc == null) {
            // throw error, as this is type checked in the store. User likely abusing internal apis if here.
            throw new Error('AG Grid: Transactions can only be applied when row ids are supplied.');
        }
        var updatedNodes = [];
        updates.forEach(function (data) {
            var id = _this.getRowId(data);
            var lazyNode = _this.nodeMap.getBy('id', id);
            if (lazyNode) {
                _this.blockUtils.updateDataIntoRowNode(lazyNode.node, data);
                updatedNodes.push(lazyNode.node);
            }
        });
        return updatedNodes;
    };
    LazyCache.prototype.insertRowNodes = function (inserts, indexToAdd) {
        var _this = this;
        // adjust row count to allow for footer row
        var realRowCount = this.store.getRowCount() - (this.store.getParentNode().sibling ? 1 : 0);
        // if missing and we know the last row, we're inserting at the end
        var addIndex = indexToAdd == null && this.isLastRowKnown ? realRowCount : indexToAdd;
        // can't insert nodes past the end of the store
        if (addIndex == null || realRowCount < addIndex) {
            return [];
        }
        if (this.getRowIdFunc == null) {
            // throw error, as this is type checked in the store. User likely abusing internal apis if here.
            throw new Error('AG Grid: Transactions can only be applied when row ids are supplied.');
        }
        var uniqueInsertsMap = {};
        inserts.forEach(function (data) {
            var dataId = _this.getRowId(data);
            if (dataId && _this.isNodeInCache(dataId)) {
                return;
            }
            uniqueInsertsMap[dataId] = data;
        });
        var uniqueInserts = Object.values(uniqueInsertsMap);
        var numberOfInserts = uniqueInserts.length;
        if (numberOfInserts === 0) {
            return [];
        }
        var nodesToMove = this.nodeMap.filter(function (node) { return node.index >= addIndex; });
        // delete all nodes which need moved first, so they don't get overwritten
        nodesToMove.forEach(function (lazyNode) { return _this.nodeMap.delete(lazyNode); });
        // then move the nodes to their new locations
        nodesToMove.forEach(function (lazyNode) {
            _this.nodeMap.set({
                node: lazyNode.node,
                index: lazyNode.index + numberOfInserts,
                id: lazyNode.id,
            });
        });
        // increase the store size to accommodate
        this.numberOfRows += numberOfInserts;
        // finally insert the new rows
        return uniqueInserts.map(function (data, uniqueInsertOffset) { return _this.createRowAtIndex(addIndex + uniqueInsertOffset, data); });
    };
    LazyCache.prototype.getOrderedNodeMap = function () {
        var obj = {};
        this.nodeMap.forEach(function (node) { return obj[node.index] = node; });
        return obj;
    };
    LazyCache.prototype.clearDisplayIndexes = function () {
        this.nodeDisplayIndexMap.clear();
    };
    LazyCache.prototype.removeRowNodes = function (idsToRemove) {
        if (this.getRowIdFunc == null) {
            // throw error, as this is type checked in the store. User likely abusing internal apis if here.
            throw new Error('AG Grid: Transactions can only be applied when row ids are supplied.');
        }
        var removedNodes = [];
        var nodesToVerify = [];
        // track how many nodes have been deleted, as when we pass other nodes we need to shift them up
        var deletedNodeCount = 0;
        var remainingIdsToRemove = __spreadArray$5([], __read$6(idsToRemove), false);
        var allNodes = this.getOrderedNodeMap();
        var contiguousIndex = -1;
        var _loop_1 = function (stringIndex) {
            contiguousIndex += 1;
            var node = allNodes[stringIndex];
            // finding the index allows the use of splice which should be slightly faster than both a check and filter
            var matchIndex = remainingIdsToRemove.findIndex(function (idToRemove) { return idToRemove === node.id; });
            if (matchIndex !== -1) {
                // found node, remove it from nodes to remove
                remainingIdsToRemove.splice(matchIndex, 1);
                this_1.destroyRowAtIndex(Number(stringIndex));
                removedNodes.push(node.node);
                deletedNodeCount += 1;
                return "continue";
            }
            // no nodes removed and this node doesn't match, so no need to shift
            if (deletedNodeCount === 0) {
                return "continue";
            }
            var numericStoreIndex = Number(stringIndex);
            if (contiguousIndex !== numericStoreIndex) {
                nodesToVerify.push(node.node);
            }
            // shift normal node up by number of deleted prior to this point
            this_1.nodeMap.delete(allNodes[stringIndex]);
            this_1.nodeMap.set({
                id: node.id,
                node: node.node,
                index: numericStoreIndex - deletedNodeCount,
            });
        };
        var this_1 = this;
        for (var stringIndex in allNodes) {
            _loop_1(stringIndex);
        }
        this.numberOfRows -= this.isLastRowIndexKnown() ? idsToRemove.length : deletedNodeCount;
        if (remainingIdsToRemove.length > 0 && nodesToVerify.length > 0) {
            nodesToVerify.forEach(function (node) { return node.__needsRefreshWhenVisible = true; });
            this.rowLoader.queueLoadCheck();
        }
        return removedNodes;
    };
    __decorate$t([
        Autowired('gridApi')
    ], LazyCache.prototype, "api", void 0);
    __decorate$t([
        Autowired('ssrmBlockUtils')
    ], LazyCache.prototype, "blockUtils", void 0);
    __decorate$t([
        Autowired('focusService')
    ], LazyCache.prototype, "focusService", void 0);
    __decorate$t([
        Autowired('ssrmNodeManager')
    ], LazyCache.prototype, "nodeManager", void 0);
    __decorate$t([
        Autowired('rowModel')
    ], LazyCache.prototype, "serverSideRowModel", void 0);
    __decorate$t([
        PostConstruct
    ], LazyCache.prototype, "init", null);
    __decorate$t([
        PreDestroy
    ], LazyCache.prototype, "destroyRowNodes", null);
    return LazyCache;
}(BeanStub));

var __extends$u = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$s = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$5 = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$4 = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var LazyStore = /** @class */ (function (_super) {
    __extends$u(LazyStore, _super);
    function LazyStore(ssrmParams, storeParams, parentRowNode) {
        var _this = _super.call(this) || this;
        _this.idSequence = new NumberSequence();
        _this.ssrmParams = ssrmParams;
        _this.parentRowNode = parentRowNode;
        _this.storeParams = storeParams;
        _this.level = parentRowNode.level + 1;
        _this.group = ssrmParams.rowGroupCols ? _this.level < ssrmParams.rowGroupCols.length : false;
        _this.leafGroup = ssrmParams.rowGroupCols ? _this.level === ssrmParams.rowGroupCols.length - 1 : false;
        _this.info = {};
        return _this;
    }
    LazyStore.prototype.init = function () {
        var _a;
        var numberOfRows = 1;
        if (this.level === 0) {
            numberOfRows = (_a = this.storeUtils.getServerSideInitialRowCount()) !== null && _a !== void 0 ? _a : 1;
            this.eventService.dispatchEventOnce({
                type: Events.EVENT_ROW_COUNT_READY
            });
        }
        this.cache = this.createManagedBean(new LazyCache(this, numberOfRows, this.storeParams));
        var usingTreeData = this.gridOptionsService.get('treeData');
        if (!usingTreeData && this.group) {
            var groupColVo = this.ssrmParams.rowGroupCols[this.level];
            this.groupField = groupColVo.field;
            this.rowGroupColumn = this.columnModel.getRowGroupColumns()[this.level];
        }
    };
    LazyStore.prototype.destroyRowNodes = function () {
        this.displayIndexStart = undefined;
        this.displayIndexEnd = undefined;
        this.destroyBean(this.cache);
    };
    /**
     * Given a server response, ingest the rows outside of the data source lifecycle.
     *
     * @param rowDataParams the server response containing the rows to ingest
     * @param startRow the index to start ingesting rows
     * @param expectedRows the expected number of rows in the response (used to determine if the last row index is known)
     */
    LazyStore.prototype.applyRowData = function (rowDataParams, startRow, expectedRows) {
        this.cache.onLoadSuccess(startRow, expectedRows, rowDataParams);
    };
    /**
     * Applies a given transaction to the data set within this store
     *
     * @param transaction an object containing delta instructions determining the changes to apply to this store
     * @returns an object determining the status of this transaction and effected nodes
     */
    LazyStore.prototype.applyTransaction = function (transaction) {
        var _this = this;
        var _a, _b, _c;
        var idFunc = this.gridOptionsService.getCallback('getRowId');
        if (!idFunc) {
            console.warn('AG Grid: getRowId callback must be implemented for transactions to work. Transaction was ignored.');
            return {
                status: ServerSideTransactionResultStatus.Cancelled,
            };
        }
        var applyCallback = this.gridOptionsService.getCallback('isApplyServerSideTransaction');
        if (applyCallback) {
            var params = {
                transaction: transaction,
                parentNode: this.parentRowNode,
                groupLevelInfo: this.info
            };
            var apply = applyCallback(params);
            if (!apply) {
                return { status: ServerSideTransactionResultStatus.Cancelled };
            }
        }
        var updatedNodes = undefined;
        if ((_a = transaction.update) === null || _a === void 0 ? void 0 : _a.length) {
            updatedNodes = this.cache.updateRowNodes(transaction.update);
        }
        var insertedNodes = undefined;
        if ((_b = transaction.add) === null || _b === void 0 ? void 0 : _b.length) {
            var addIndex = transaction.addIndex;
            if (addIndex != null && addIndex < 0) {
                addIndex = undefined;
            }
            insertedNodes = this.cache.insertRowNodes(transaction.add, addIndex);
        }
        var removedNodes = undefined;
        if ((_c = transaction.remove) === null || _c === void 0 ? void 0 : _c.length) {
            var allIdsToRemove = transaction.remove.map(function (data) { return (idFunc({ level: _this.level, parentKeys: _this.parentRowNode.getGroupKeys(), data: data })); });
            var allUniqueIdsToRemove = __spreadArray$4([], __read$5(new Set(allIdsToRemove)), false);
            removedNodes = this.cache.removeRowNodes(allUniqueIdsToRemove);
        }
        this.updateSelectionAfterTransaction(updatedNodes, removedNodes);
        return {
            status: ServerSideTransactionResultStatus.Applied,
            update: updatedNodes,
            add: insertedNodes,
            remove: removedNodes,
        };
    };
    LazyStore.prototype.updateSelectionAfterTransaction = function (updatedNodes, removedNodes) {
        var nodesToDeselect = [];
        updatedNodes === null || updatedNodes === void 0 ? void 0 : updatedNodes.forEach(function (node) {
            if (node.isSelected() && !node.selectable) {
                nodesToDeselect.push(node);
            }
        });
        removedNodes === null || removedNodes === void 0 ? void 0 : removedNodes.forEach(function (node) {
            if (node.isSelected()) {
                nodesToDeselect.push(node);
            }
        });
        if (nodesToDeselect.length) {
            this.selectionService.setNodesSelected({
                newValue: false,
                clearSelection: false,
                nodes: nodesToDeselect,
                source: 'rowDataChanged',
            });
        }
    };
    /**
     * Clear the display indexes, used for fading rows out when stores are not being destroyed
     */
    LazyStore.prototype.clearDisplayIndexes = function () {
        var _this = this;
        this.displayIndexStart = undefined;
        this.displayIndexEnd = undefined;
        this.cache.getNodes().forEach(function (lazyNode) { return _this.blockUtils.clearDisplayIndex(lazyNode.node); });
        if (this.parentRowNode.sibling) {
            this.blockUtils.clearDisplayIndex(this.parentRowNode.sibling);
        }
        this.cache.clearDisplayIndexes();
    };
    /**
     * @returns an index representing the last sequentially displayed row in the grid for this store
     */
    LazyStore.prototype.getDisplayIndexStart = function () {
        return this.displayIndexStart;
    };
    /**
     * @returns the index representing one after the last sequentially displayed row in the grid for this store
     */
    LazyStore.prototype.getDisplayIndexEnd = function () {
        return this.displayIndexEnd;
    };
    /**
     * @returns the virtual size of this store
     */
    LazyStore.prototype.getRowCount = function () {
        if (this.parentRowNode.sibling) {
            return this.cache.getRowCount() + 1;
        }
        return this.cache.getRowCount();
    };
    /**
     * Sets the current row count of the store, and whether the last row index is known
     */
    LazyStore.prototype.setRowCount = function (rowCount, isLastRowIndexKnown) {
        this.cache.setRowCount(rowCount, isLastRowIndexKnown);
    };
    /**
     * Given a display index, returns whether that row is within this store or a child store of this store
     *
     * @param displayIndex the visible index of a row
     * @returns whether or not the row exists within this store
     */
    LazyStore.prototype.isDisplayIndexInStore = function (displayIndex) {
        if (this.cache.getRowCount() === 0)
            return false;
        return this.displayIndexStart <= displayIndex && displayIndex < this.getDisplayIndexEnd();
    };
    /**
     * Recursively sets up the display indexes and top position of every node belonging to this store.
     *
     * Called after a row height changes, or a store updated event.
     *
     * @param displayIndexSeq the number sequence for generating the display index of each row
     * @param nextRowTop an object containing the next row top value intended to be modified by ref per row
     */
    LazyStore.prototype.setDisplayIndexes = function (displayIndexSeq, nextRowTop) {
        this.displayIndexStart = displayIndexSeq.peek();
        this.topPx = nextRowTop.value;
        // delegate to the store to set the row display indexes
        this.cache.setDisplayIndexes(displayIndexSeq, nextRowTop);
        if (this.parentRowNode.sibling) {
            this.blockUtils.setDisplayIndex(this.parentRowNode.sibling, displayIndexSeq, nextRowTop);
        }
        this.displayIndexEnd = displayIndexSeq.peek();
        this.heightPx = nextRowTop.value - this.topPx;
    };
    /**
     * Recursively applies a provided function to every node
     *
     * For the purpose of exclusively server side filtered stores, this is the same as getNodes().forEachDeepAfterFilterAndSort
     */
    LazyStore.prototype.forEachStoreDeep = function (callback, sequence) {
        if (sequence === void 0) { sequence = new NumberSequence(); }
        callback(this, sequence.next());
        this.cache.getNodes().forEach(function (lazyNode) {
            var childCache = lazyNode.node.childStore;
            if (childCache) {
                childCache.forEachStoreDeep(callback, sequence);
            }
        });
    };
    /**
     * Recursively applies a provided function to every node
     *
     * For the purpose of exclusively server side filtered stores, this is the same as getNodes().forEachDeepAfterFilterAndSort
     */
    LazyStore.prototype.forEachNodeDeep = function (callback, sequence) {
        if (sequence === void 0) { sequence = new NumberSequence(); }
        this.cache.getNodes().forEach(function (lazyNode) {
            callback(lazyNode.node, sequence.next());
            var childCache = lazyNode.node.childStore;
            if (childCache) {
                childCache.forEachNodeDeep(callback, sequence);
            }
        });
    };
    /**
     * Recursively applies a provided function to every node
     *
     * For the purpose of exclusively server side filtered stores, this is the same as getNodes().forEachDeep
     */
    LazyStore.prototype.forEachNodeDeepAfterFilterAndSort = function (callback, sequence, includeFooterNodes) {
        if (sequence === void 0) { sequence = new NumberSequence(); }
        if (includeFooterNodes === void 0) { includeFooterNodes = false; }
        var orderedNodes = this.cache.getOrderedNodeMap();
        for (var key in orderedNodes) {
            var lazyNode = orderedNodes[key];
            callback(lazyNode.node, sequence.next());
            var childCache = lazyNode.node.childStore;
            if (childCache) {
                childCache.forEachNodeDeepAfterFilterAndSort(callback, sequence, includeFooterNodes);
            }
        }
        if (includeFooterNodes && this.parentRowNode.sibling) {
            callback(this.parentRowNode.sibling, sequence.next());
        }
    };
    /**
     * Removes the failed status from all nodes, and marks them as stub to encourage reloading
     */
    LazyStore.prototype.retryLoads = function () {
        this.cache.getNodes().forEach(function (_a) {
            var node = _a.node;
            if (node.failedLoad) {
                node.failedLoad = false;
                node.__needsRefreshWhenVisible = true;
                node.stub = true;
            }
        });
        this.forEachChildStoreShallow(function (store) { return store.retryLoads(); });
        this.fireStoreUpdatedEvent();
    };
    /**
     * Given a display index, returns the row at that location.
     *
     * @param displayRowIndex the displayed index within the grid to search for
     * @returns the row node if the display index falls within the store, if it didn't exist this will create a new stub to return
     */
    LazyStore.prototype.getRowUsingDisplayIndex = function (displayRowIndex) {
        if (this.parentRowNode.sibling && displayRowIndex === this.parentRowNode.sibling.rowIndex) {
            return this.parentRowNode.sibling;
        }
        return this.cache.getRowByDisplayIndex(displayRowIndex);
    };
    /**
     * Given a display index, returns the row top and height for the row at that index.
     *
     * @param displayIndex the display index of the node
     * @returns an object containing the rowTop and rowHeight of the node at the given displayIndex
     */
    LazyStore.prototype.getRowBounds = function (displayIndex) {
        var _a;
        if (!this.isDisplayIndexInStore(displayIndex)) {
            return null;
        }
        var thisNode = this.cache.getNodeCachedByDisplayIndex(displayIndex);
        if (thisNode) {
            var boundsFromRow = this.blockUtils.extractRowBounds(thisNode, displayIndex);
            if (boundsFromRow) {
                return boundsFromRow;
            }
        }
        var _b = (_a = this.cache.getSurroundingNodesByDisplayIndex(displayIndex)) !== null && _a !== void 0 ? _a : {}, previousNode = _b.previousNode, nextNode = _b.nextNode;
        // previous node may equal, or catch via detail node or child of group
        if (previousNode) {
            var boundsFromRow = this.blockUtils.extractRowBounds(previousNode.node, displayIndex);
            if (boundsFromRow != null) {
                return boundsFromRow;
            }
        }
        var defaultRowHeight = this.gridOptionsService.getRowHeightAsNumber();
        // if node after this, can calculate backwards (and ignore detail/grouping)
        if (nextNode) {
            var numberOfRowDiff_1 = (nextNode.node.rowIndex - displayIndex) * defaultRowHeight;
            return {
                rowTop: nextNode.node.rowTop - numberOfRowDiff_1,
                rowHeight: defaultRowHeight,
            };
        }
        // otherwise calculate from end of store
        var lastTop = this.topPx + this.heightPx;
        var numberOfRowDiff = (this.getDisplayIndexEnd() - displayIndex) * defaultRowHeight;
        return {
            rowTop: lastTop - numberOfRowDiff,
            rowHeight: defaultRowHeight,
        };
    };
    /**
     * Given a vertical pixel, determines whether this store contains a row at that pixel
     *
     * @param pixel a vertical pixel position from the grid
     * @returns whether that pixel points to a virtual space belonging to this store
     */
    LazyStore.prototype.isPixelInRange = function (pixel) {
        return pixel >= this.topPx && pixel < (this.topPx + this.heightPx);
    };
    /**
     * Given a vertical pixel, returns the row existing at that pixel location
     *
     * @param pixel a vertical pixel position from the grid
     * @returns the display index at the given pixel location
     */
    LazyStore.prototype.getRowIndexAtPixel = function (pixel) {
        if (pixel < this.topPx) {
            return this.getDisplayIndexStart();
        }
        if (pixel >= this.topPx + this.heightPx) {
            return this.getDisplayIndexEnd() - 1;
        }
        var distToPreviousNodeTop = Number.MAX_SAFE_INTEGER;
        var previousNode = null;
        var distToNextNodeTop = Number.MAX_SAFE_INTEGER;
        var nextNode = null;
        this.cache.getNodes().forEach(function (_a) {
            var node = _a.node;
            var distBetween = Math.abs(pixel - node.rowTop);
            // previous node
            if (node.rowTop < pixel) {
                if (distBetween < distToPreviousNodeTop) {
                    distToPreviousNodeTop = distBetween;
                    previousNode = node;
                }
                return;
            }
            // next node
            if (distBetween < distToNextNodeTop) {
                distToNextNodeTop = distBetween;
                nextNode = node;
            }
        });
        // cast these back as typescript doesn't understand the forEach above
        previousNode = previousNode;
        nextNode = nextNode;
        // previous node may equal, or catch via detail node or child of group
        if (previousNode) {
            var indexOfRow = this.blockUtils.getIndexAtPixel(previousNode, pixel);
            if (indexOfRow != null) {
                return indexOfRow;
            }
        }
        var defaultRowHeight = this.gridOptionsService.getRowHeightAsNumber();
        // if node after this, can calculate backwards (and ignore detail/grouping)
        if (nextNode) {
            var nextTop_1 = nextNode.rowTop;
            var numberOfRowDiff_2 = Math.ceil((nextTop_1 - pixel) / defaultRowHeight);
            return nextNode.rowIndex - numberOfRowDiff_2;
        }
        // otherwise calculate from end of store
        var nextTop = this.topPx + this.heightPx;
        var numberOfRowDiff = Math.floor((nextTop - pixel) / defaultRowHeight);
        return this.getDisplayIndexEnd() - numberOfRowDiff;
    };
    /**
     * Given a path of group keys, returns the child store for that group.
     *
     * @param keys the grouping path to the desired store
     * @returns the child store for the given keys, or null if not found
     */
    LazyStore.prototype.getChildStore = function (keys) {
        var _this = this;
        return this.storeUtils.getChildStore(keys, this, function (key) {
            var lazyNode = _this.cache.getNodes().find(function (lazyNode) { return lazyNode.node.key == key; });
            if (!lazyNode) {
                return null;
            }
            return lazyNode.node;
        });
    };
    /**
     * Executes a provided callback on each child store belonging to this store
     *
     * @param cb the callback to execute
     */
    LazyStore.prototype.forEachChildStoreShallow = function (cb) {
        this.cache.getNodes().forEach(function (_a) {
            var node = _a.node;
            if (node.childStore) {
                cb(node.childStore);
            }
        });
    };
    /**
     * Executes after a change to sorting, determines recursively whether this store or a child requires refreshed.
     *
     * If a purge refresh occurs, the row count is preserved.
     *
     * @param params a set of properties pertaining to the sort changes
     */
    LazyStore.prototype.refreshAfterSort = function (params) {
        var serverSortsAllLevels = this.storeUtils.isServerSideSortAllLevels();
        if (serverSortsAllLevels || this.storeUtils.isServerRefreshNeeded(this.parentRowNode, this.ssrmParams.rowGroupCols, params)) {
            var oldCount = this.cache.getRowCount();
            this.destroyBean(this.cache);
            this.cache = this.createManagedBean(new LazyCache(this, oldCount, this.storeParams));
            this.fireStoreUpdatedEvent();
            return;
        }
        // call refreshAfterSort on children, as we did not purge.
        // if we did purge, no need to do this as all children were destroyed
        this.forEachChildStoreShallow(function (store) { return store.refreshAfterSort(params); });
    };
    /**
     * Executes after a change to filtering, determines recursively whether this store or a child requires refreshed.
     *
     * If a refresh occurs, the row count is reset.
     *
     * @param params a set of properties pertaining to the filter changes
     */
    LazyStore.prototype.refreshAfterFilter = function (params) {
        var serverFiltersAllLevels = !this.storeUtils.isServerSideOnlyRefreshFilteredGroups();
        if (serverFiltersAllLevels || this.storeUtils.isServerRefreshNeeded(this.parentRowNode, this.ssrmParams.rowGroupCols, params)) {
            this.refreshStore(true);
            return;
        }
        // call refreshAfterSort on children, as we did not purge.
        // if we did purge, no need to do this as all children were destroyed
        this.forEachChildStoreShallow(function (store) { return store.refreshAfterFilter(params); });
    };
    /**
     * Marks all existing nodes as requiring reloaded, and triggers a load check
     *
     * @param purge whether to remove all nodes and data in favour of stub nodes
     */
    LazyStore.prototype.refreshStore = function (purge) {
        if (purge) {
            this.destroyBean(this.cache);
            this.cache = this.createManagedBean(new LazyCache(this, 1, this.storeParams));
            this.fireStoreUpdatedEvent();
            return;
        }
        this.cache.markNodesForRefresh();
    };
    /**
     * Used for pagination, given a local/store index, returns the display index of that row
     *
     * @param topLevelIndex the store index of a row
     * @returns the display index for the given store index
     */
    LazyStore.prototype.getTopLevelRowDisplayedIndex = function (topLevelIndex) {
        var displayIndex = this.cache.getDisplayIndexFromStoreIndex(topLevelIndex);
        return displayIndex !== null && displayIndex !== void 0 ? displayIndex : topLevelIndex;
    };
    /**
     * Used for pagination to determine if the last page is known, and for aria to determine if the last grid row is known
     *
     * @returns whether the last index of this store is known, or if lazy loading still required
     */
    LazyStore.prototype.isLastRowIndexKnown = function () {
        return this.cache.isLastRowIndexKnown();
    };
    /**
     * Used by the selection service to select a range of nodes
     *
     * @param firstInRange the first node in the range to find
     * @param lastInRange the last node in the range to find
     * @returns a range of nodes between firstInRange and lastInRange inclusive
     */
    LazyStore.prototype.getRowNodesInRange = function (firstInRange, lastInRange) {
        // if only one node passed, we start the selection at the top
        if (_.missing(firstInRange)) ;
        return this.cache.getNodes().filter(function (_a) {
            var node = _a.node;
            return node.rowIndex >= firstInRange.rowIndex && node.rowIndex <= lastInRange.rowIndex;
        }).map(function (_a) {
            var node = _a.node;
            return node;
        });
    };
    /**
     * Mutates a given array to add this stores state, and recursively add all the children store states.
     *
     * @param result a mutable results array
     */
    LazyStore.prototype.addStoreStates = function (result) {
        result.push({
            suppressInfiniteScroll: false,
            route: this.parentRowNode.getGroupKeys(),
            rowCount: this.getRowCount(),
            lastRowIndexKnown: this.isLastRowIndexKnown(),
            info: this.info,
            maxBlocksInCache: this.storeParams.maxBlocksInCache,
            cacheBlockSize: this.storeParams.cacheBlockSize,
        });
        this.forEachChildStoreShallow(function (childStore) { return childStore.addStoreStates(result); });
    };
    LazyStore.prototype.getIdSequence = function () {
        return this.idSequence;
    };
    LazyStore.prototype.getParentNode = function () {
        return this.parentRowNode;
    };
    LazyStore.prototype.getRowDetails = function () {
        return {
            field: this.groupField,
            group: this.group,
            leafGroup: this.leafGroup,
            level: this.level,
            parent: this.parentRowNode,
            rowGroupColumn: this.rowGroupColumn,
        };
    };
    LazyStore.prototype.getSsrmParams = function () {
        return this.ssrmParams;
    };
    LazyStore.prototype.setStoreInfo = function (info) {
        if (info) {
            Object.assign(this.info, info);
        }
    };
    // gets called 1) row count changed 2) cache purged
    LazyStore.prototype.fireStoreUpdatedEvent = function () {
        // this results in row model firing ModelUpdated.
        // server side row model also updates the row indexes first
        var event = {
            type: Events.EVENT_STORE_UPDATED
        };
        this.eventService.dispatchEvent(event);
    };
    // gets called when row data updated, and no more refreshing needed
    LazyStore.prototype.fireRefreshFinishedEvent = function () {
        var event = {
            type: Events.EVENT_STORE_REFRESHED,
            route: this.parentRowNode.getRoute(),
        };
        this.eventService.dispatchEvent(event);
    };
    LazyStore.prototype.getBlockStates = function () {
        return this.cache.getBlockStates();
    };
    LazyStore.prototype.getStoreBounds = function () {
        return {
            topPx: this.topPx,
            heightPx: this.heightPx,
        };
    };
    __decorate$s([
        Autowired('ssrmBlockUtils')
    ], LazyStore.prototype, "blockUtils", void 0);
    __decorate$s([
        Autowired('ssrmStoreUtils')
    ], LazyStore.prototype, "storeUtils", void 0);
    __decorate$s([
        Autowired('columnModel')
    ], LazyStore.prototype, "columnModel", void 0);
    __decorate$s([
        Autowired('selectionService')
    ], LazyStore.prototype, "selectionService", void 0);
    __decorate$s([
        PostConstruct
    ], LazyStore.prototype, "init", null);
    __decorate$s([
        PreDestroy
    ], LazyStore.prototype, "destroyRowNodes", null);
    return LazyStore;
}(BeanStub));

var __extends$t = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$r = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$4 = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var ServerSideRowModel = /** @class */ (function (_super) {
    __extends$t(ServerSideRowModel, _super);
    function ServerSideRowModel() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onRowHeightChanged_debounced = _.debounce(_this.onRowHeightChanged.bind(_this), 100);
        _this.pauseStoreUpdateListening = false;
        _this.started = false;
        _this.managingPivotResultColumns = false;
        return _this;
    }
    // we don't implement as lazy row heights is not supported in this row model
    ServerSideRowModel.prototype.ensureRowHeightsValid = function () { return false; };
    ServerSideRowModel.prototype.start = function () {
        this.started = true;
        this.updateDatasource();
    };
    ServerSideRowModel.prototype.destroyDatasource = function () {
        if (!this.datasource) {
            return;
        }
        if (this.datasource.destroy) {
            this.datasource.destroy();
        }
        this.rowRenderer.datasourceChanged();
        this.datasource = undefined;
    };
    ServerSideRowModel.prototype.addEventListeners = function () {
        var _this = this;
        this.addManagedListener(this.eventService, Events.EVENT_NEW_COLUMNS_LOADED, this.onColumnEverything.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_STORE_UPDATED, this.onStoreUpdated.bind(this));
        var resetListener = this.resetRootStore.bind(this);
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_VALUE_CHANGED, resetListener);
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_PIVOT_CHANGED, resetListener);
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_ROW_GROUP_CHANGED, resetListener);
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_PIVOT_MODE_CHANGED, resetListener);
        this.addManagedPropertyListeners([
            /**
             * Following properties omitted as they are likely to come with undesired  side effects.
             * 'getRowId', 'isRowMaster', 'getRowHeight', 'isServerSideGroup', 'getServerSideGroupKey',
             * */
            'masterDetail', 'treeData', 'removePivotHeaderRowWhenSingleValueColumn',
            'suppressServerSideInfiniteScroll', 'cacheBlockSize',
        ], resetListener);
        this.addManagedPropertyListener('rowHeight', function () { return _this.resetRowHeights(); });
        this.verifyProps();
        this.addManagedPropertyListener('serverSideDatasource', function () { return _this.updateDatasource(); });
    };
    ServerSideRowModel.prototype.updateDatasource = function () {
        var datasource = this.gridOptionsService.get('serverSideDatasource');
        if (datasource) {
            this.setDatasource(datasource);
        }
    };
    ServerSideRowModel.prototype.verifyProps = function () {
        if (this.gridOptionsService.exists('initialGroupOrderComparator')) {
            _.warnOnce("initialGroupOrderComparator cannot be used with Server Side Row Model.");
        }
        if (this.gridOptionsService.isRowSelection() && !this.gridOptionsService.exists('getRowId')) {
            _.warnOnce("getRowId callback must be provided for Server Side Row Model selection to work correctly.");
        }
    };
    ServerSideRowModel.prototype.setDatasource = function (datasource) {
        // sometimes React, due to async, can call gridApi.setDatasource() before we have started.
        // this happens when React app does this:
        //      useEffect(() => setDatasource(ds), []);
        // thus if we set the datasource before the grid UI has finished initialising, we do not set it,
        // and the ssrm.start() method will set the datasoure when the grid is ready.
        if (!this.started) {
            return;
        }
        this.destroyDatasource();
        this.datasource = datasource;
        this.resetRootStore();
    };
    ServerSideRowModel.prototype.applyRowData = function (rowDataParams, startRow, route) {
        var rootStore = this.getRootStore();
        if (!rootStore) {
            return;
        }
        var storeToExecuteOn = rootStore.getChildStore(route);
        if (!storeToExecuteOn) {
            return;
        }
        if (storeToExecuteOn instanceof LazyStore) {
            storeToExecuteOn.applyRowData(rowDataParams, startRow, rowDataParams.rowData.length);
        }
        else if (storeToExecuteOn instanceof FullStore) {
            storeToExecuteOn.processServerResult(rowDataParams);
        }
    };
    ServerSideRowModel.prototype.isLastRowIndexKnown = function () {
        var cache = this.getRootStore();
        if (!cache) {
            return false;
        }
        return cache.isLastRowIndexKnown();
    };
    ServerSideRowModel.prototype.onColumnEverything = function () {
        // if first time, always reset
        if (!this.storeParams) {
            this.resetRootStore();
            return;
        }
        // check if anything pertaining to fetching data has changed, and if it has, reset, but if
        // it has not, don't reset
        var rowGroupColumnVos = this.columnsToValueObjects(this.columnModel.getRowGroupColumns());
        var valueColumnVos = this.columnsToValueObjects(this.columnModel.getValueColumns());
        var pivotColumnVos = this.columnsToValueObjects(this.columnModel.getPivotColumns());
        // compares two sets of columns, ensuring no columns have been added or removed (unless specified via allowRemovedColumns)
        // if the columns are found, also ensures the field and aggFunc properties have not been changed.
        var areColsSame = function (params) {
            var oldColsMap = {};
            params.oldCols.forEach(function (col) { return oldColsMap[col.id] = col; });
            var allColsUnchanged = params.newCols.every(function (col) {
                var equivalentCol = oldColsMap[col.id];
                if (equivalentCol) {
                    delete oldColsMap[col.id];
                }
                return equivalentCol && equivalentCol.field === col.field && equivalentCol.aggFunc === col.aggFunc;
            });
            var missingCols = !params.allowRemovedColumns && !!Object.values(oldColsMap).length;
            return allColsUnchanged && !missingCols;
        };
        var sortModelDifferent = !_.jsonEquals(this.storeParams.sortModel, this.sortController.getSortModel());
        var rowGroupDifferent = !areColsSame({
            oldCols: this.storeParams.rowGroupCols,
            newCols: rowGroupColumnVos,
        });
        var pivotDifferent = !areColsSame({
            oldCols: this.storeParams.pivotCols,
            newCols: pivotColumnVos,
        });
        var valuesDifferent = !!(rowGroupColumnVos === null || rowGroupColumnVos === void 0 ? void 0 : rowGroupColumnVos.length) && !areColsSame({
            oldCols: this.storeParams.valueCols,
            newCols: valueColumnVos,
            allowRemovedColumns: true,
        });
        var resetRequired = sortModelDifferent || rowGroupDifferent || pivotDifferent || valuesDifferent;
        if (resetRequired) {
            this.resetRootStore();
        }
        else {
            // cols may have changed even if we didn't do a reset. storeParams ref will be provided when getRows
            // is called, so it's important to keep it up to date.
            var newParams = this.createStoreParams();
            this.storeParams.rowGroupCols = newParams.rowGroupCols;
            this.storeParams.pivotCols = newParams.pivotCols;
            this.storeParams.valueCols = newParams.valueCols;
        }
    };
    ServerSideRowModel.prototype.destroyRootStore = function () {
        if (!this.rootNode || !this.rootNode.childStore) {
            return;
        }
        this.rootNode.childStore = this.destroyBean(this.rootNode.childStore);
        this.nodeManager.clear();
    };
    ServerSideRowModel.prototype.refreshAfterSort = function (newSortModel, params) {
        if (this.storeParams) {
            this.storeParams.sortModel = newSortModel;
        }
        var rootStore = this.getRootStore();
        if (!rootStore) {
            return;
        }
        rootStore.refreshAfterSort(params);
        this.onStoreUpdated();
    };
    ServerSideRowModel.prototype.generateSecondaryColumns = function (pivotFields) {
        var pivotColumnGroupDefs = this.pivotColDefService.createColDefsFromFields(pivotFields);
        this.managingPivotResultColumns = true;
        this.columnModel.setSecondaryColumns(pivotColumnGroupDefs, "rowModelUpdated");
    };
    ServerSideRowModel.prototype.resetRowHeights = function () {
        var atLeastOne = this.resetRowHeightsForAllRowNodes();
        var rootNodeHeight = this.gridOptionsService.getRowHeightForNode(this.rootNode);
        this.rootNode.setRowHeight(rootNodeHeight.height, rootNodeHeight.estimated);
        if (this.rootNode.sibling) {
            var rootNodeSibling = this.gridOptionsService.getRowHeightForNode(this.rootNode.sibling);
            this.rootNode.sibling.setRowHeight(rootNodeSibling.height, rootNodeSibling.estimated);
        }
        // when pivotMode but pivot not active, root node is displayed on its own
        // because it's only ever displayed alone, refreshing the model (onRowHeightChanged) is not required
        if (atLeastOne) {
            this.onRowHeightChanged();
        }
    };
    ServerSideRowModel.prototype.resetRowHeightsForAllRowNodes = function () {
        var _this = this;
        var atLeastOne = false;
        this.forEachNode(function (rowNode) {
            var rowHeightForNode = _this.gridOptionsService.getRowHeightForNode(rowNode);
            rowNode.setRowHeight(rowHeightForNode.height, rowHeightForNode.estimated);
            // we keep the height each row is at, however we set estimated=true rather than clear the height.
            // this means the grid will not reset the row heights back to defaults, rather it will re-calc
            // the height for each row as the row is displayed. otherwise the scroll will jump when heights are reset.
            var detailNode = rowNode.detailNode;
            if (detailNode) {
                var detailRowHeight = _this.gridOptionsService.getRowHeightForNode(detailNode);
                detailNode.setRowHeight(detailRowHeight.height, detailRowHeight.estimated);
            }
            if (rowNode.sibling) {
                var siblingRowHeight = _this.gridOptionsService.getRowHeightForNode(rowNode.sibling);
                detailNode.setRowHeight(siblingRowHeight.height, siblingRowHeight.estimated);
            }
            atLeastOne = true;
        });
        return atLeastOne;
    };
    ServerSideRowModel.prototype.resetRootStore = function () {
        this.destroyRootStore();
        this.rootNode = new RowNode(this.beans);
        this.rootNode.group = true;
        this.rootNode.level = -1;
        if (this.datasource) {
            this.storeParams = this.createStoreParams();
            this.rootNode.childStore = this.createBean(this.storeFactory.createStore(this.storeParams, this.rootNode));
            this.updateRowIndexesAndBounds();
        }
        if (this.managingPivotResultColumns) {
            // if managing pivot columns, also reset secondary columns.
            this.columnModel.setSecondaryColumns(null);
            this.managingPivotResultColumns = false;
        }
        // this gets the row to render rows (or remove the previously rendered rows, as it's blank to start).
        // important to NOT pass in an event with keepRenderedRows or animate, as we want the renderer
        // to treat the rows as new rows, as it's all new data
        this.dispatchModelUpdated(true);
    };
    ServerSideRowModel.prototype.columnsToValueObjects = function (columns) {
        var _this = this;
        return columns.map(function (col) { return ({
            id: col.getId(),
            aggFunc: col.getAggFunc(),
            displayName: _this.columnModel.getDisplayNameForColumn(col, 'model'),
            field: col.getColDef().field
        }); });
    };
    ServerSideRowModel.prototype.createStoreParams = function () {
        var rowGroupColumnVos = this.columnsToValueObjects(this.columnModel.getRowGroupColumns());
        var valueColumnVos = this.columnsToValueObjects(this.columnModel.getValueColumns());
        var pivotColumnVos = this.columnsToValueObjects(this.columnModel.getPivotColumns());
        var dynamicRowHeight = this.gridOptionsService.isGetRowHeightFunction();
        var params = {
            // the columns the user has grouped and aggregated by
            valueCols: valueColumnVos,
            rowGroupCols: rowGroupColumnVos,
            pivotCols: pivotColumnVos,
            pivotMode: this.columnModel.isPivotMode(),
            // sort and filter model
            filterModel: this.filterManager.isAdvancedFilterEnabled()
                ? this.filterManager.getAdvancedFilterModel()
                : this.filterManager.getFilterModel(),
            sortModel: this.sortController.getSortModel(),
            datasource: this.datasource,
            lastAccessedSequence: new NumberSequence(),
            // blockSize: blockSize == null ? 100 : blockSize,
            dynamicRowHeight: dynamicRowHeight
        };
        return params;
    };
    ServerSideRowModel.prototype.getParams = function () {
        return this.storeParams;
    };
    ServerSideRowModel.prototype.dispatchModelUpdated = function (reset) {
        if (reset === void 0) { reset = false; }
        var modelUpdatedEvent = {
            type: Events.EVENT_MODEL_UPDATED,
            animate: !reset,
            keepRenderedRows: !reset,
            newPage: false,
            newData: false
        };
        this.eventService.dispatchEvent(modelUpdatedEvent);
    };
    ServerSideRowModel.prototype.onStoreUpdated = function () {
        // sometimes if doing a batch update, we do the batch first,
        // then call onStoreUpdated manually. eg expandAll() method.
        if (this.pauseStoreUpdateListening) {
            return;
        }
        this.updateRowIndexesAndBounds();
        this.dispatchModelUpdated();
    };
    /** This method is debounced. It is used for row auto-height. If we don't debounce,
     * then the Row Models will end up recalculating each row position
     * for each row height change and result in the Row Renderer laying out rows.
     * This is particularly bad if using print layout, and showing eg 1,000 rows,
     * each row will change it's height, causing Row Model to update 1,000 times.
     */
    ServerSideRowModel.prototype.onRowHeightChangedDebounced = function () {
        this.onRowHeightChanged_debounced();
    };
    ServerSideRowModel.prototype.onRowHeightChanged = function () {
        this.updateRowIndexesAndBounds();
        this.dispatchModelUpdated();
    };
    ServerSideRowModel.prototype.updateRowIndexesAndBounds = function () {
        var rootStore = this.getRootStore();
        if (!rootStore) {
            return;
        }
        rootStore.setDisplayIndexes(new NumberSequence(), { value: 0 });
    };
    ServerSideRowModel.prototype.retryLoads = function () {
        var rootStore = this.getRootStore();
        if (!rootStore) {
            return;
        }
        rootStore.retryLoads();
        this.onStoreUpdated();
    };
    ServerSideRowModel.prototype.getRow = function (index) {
        var rootStore = this.getRootStore();
        if (!rootStore) {
            return undefined;
        }
        return rootStore.getRowUsingDisplayIndex(index);
    };
    ServerSideRowModel.prototype.expandAll = function (value) {
        // if we don't pause store updating, we are needlessly
        // recalculating row-indexes etc, and also getting rendering
        // engine to re-render (listens on ModelUpdated event)
        this.pauseStoreUpdateListening = true;
        this.forEachNode(function (node) {
            if (node.stub) {
                return;
            }
            if (node.hasChildren()) {
                node.setExpanded(value);
            }
        });
        this.pauseStoreUpdateListening = false;
        this.onStoreUpdated();
    };
    ServerSideRowModel.prototype.refreshAfterFilter = function (newFilterModel, params) {
        if (this.storeParams) {
            this.storeParams.filterModel = newFilterModel;
        }
        var rootStore = this.getRootStore();
        if (!rootStore) {
            return;
        }
        rootStore.refreshAfterFilter(params);
        this.onStoreUpdated();
    };
    ServerSideRowModel.prototype.getRootStore = function () {
        if (this.rootNode && this.rootNode.childStore) {
            return this.rootNode.childStore;
        }
    };
    ServerSideRowModel.prototype.getRowCount = function () {
        var rootStore = this.getRootStore();
        if (!rootStore) {
            return 0;
        }
        return rootStore.getDisplayIndexEnd();
    };
    ServerSideRowModel.prototype.getTopLevelRowCount = function () {
        var rootStore = this.getRootStore();
        if (!rootStore) {
            return 1;
        }
        return rootStore.getRowCount();
    };
    ServerSideRowModel.prototype.getTopLevelRowDisplayedIndex = function (topLevelIndex) {
        var rootStore = this.getRootStore();
        if (!rootStore) {
            return topLevelIndex;
        }
        return rootStore.getTopLevelRowDisplayedIndex(topLevelIndex);
    };
    ServerSideRowModel.prototype.getRowBounds = function (index) {
        var rootStore = this.getRootStore();
        if (!rootStore) {
            var rowHeight = this.gridOptionsService.getRowHeightAsNumber();
            return {
                rowTop: 0,
                rowHeight: rowHeight
            };
        }
        return rootStore.getRowBounds(index);
    };
    ServerSideRowModel.prototype.getBlockStates = function () {
        var root = this.getRootStore();
        if (!root) {
            return undefined;
        }
        var states = {};
        root.forEachStoreDeep(function (store) {
            if (store instanceof FullStore) {
                var _a = store.getBlockStateJson(), id = _a.id, state = _a.state;
                states[id] = state;
            }
            else if (store instanceof LazyStore) {
                Object.entries(store.getBlockStates()).forEach(function (_a) {
                    var _b = __read$4(_a, 2), block = _b[0], state = _b[1];
                    states[block] = state;
                });
            }
            else {
                throw new Error('AG Grid: Unsupported store type');
            }
        });
        return states;
    };
    ServerSideRowModel.prototype.getRowIndexAtPixel = function (pixel) {
        var rootStore = this.getRootStore();
        if (pixel <= 0 || !rootStore) {
            return 0;
        }
        return rootStore.getRowIndexAtPixel(pixel);
    };
    ServerSideRowModel.prototype.isEmpty = function () {
        return false;
    };
    ServerSideRowModel.prototype.isRowsToRender = function () {
        return this.getRootStore() != null && this.getRowCount() > 0;
    };
    ServerSideRowModel.prototype.getType = function () {
        return 'serverSide';
    };
    ServerSideRowModel.prototype.forEachNode = function (callback) {
        var rootStore = this.getRootStore();
        if (!rootStore) {
            return;
        }
        rootStore.forEachNodeDeep(callback);
    };
    ServerSideRowModel.prototype.forEachNodeAfterFilterAndSort = function (callback, includeFooterNodes) {
        if (includeFooterNodes === void 0) { includeFooterNodes = false; }
        var rootStore = this.getRootStore();
        if (!rootStore) {
            return;
        }
        rootStore.forEachNodeDeepAfterFilterAndSort(callback, undefined, includeFooterNodes);
    };
    /** @return false if store hasn't started */
    ServerSideRowModel.prototype.executeOnStore = function (route, callback) {
        if (!this.started) {
            return false;
        }
        var rootStore = this.getRootStore();
        if (!rootStore) {
            return true;
        }
        var storeToExecuteOn = rootStore.getChildStore(route);
        if (storeToExecuteOn) {
            callback(storeToExecuteOn);
        }
        return true;
    };
    ServerSideRowModel.prototype.refreshStore = function (params) {
        if (params === void 0) { params = {}; }
        var route = params.route ? params.route : [];
        this.executeOnStore(route, function (store) { return store.refreshStore(params.purge == true); });
    };
    ServerSideRowModel.prototype.getStoreState = function () {
        var res = [];
        var rootStore = this.getRootStore();
        if (rootStore) {
            rootStore.addStoreStates(res);
        }
        return res;
    };
    ServerSideRowModel.prototype.getNodesInRangeForSelection = function (firstInRange, lastInRange) {
        if (!_.exists(firstInRange)) {
            return [];
        }
        if (!lastInRange) {
            return [firstInRange];
        }
        var startIndex = firstInRange.rowIndex;
        var endIndex = lastInRange.rowIndex;
        if (startIndex === null || endIndex === null) {
            return [firstInRange];
        }
        var nodeRange = [];
        var _a = __read$4([startIndex, endIndex].sort(function (a, b) { return a - b; }), 2), firstIndex = _a[0], lastIndex = _a[1];
        this.forEachNode(function (node) {
            var thisRowIndex = node.rowIndex;
            if (thisRowIndex == null || node.stub) {
                return;
            }
            if (thisRowIndex >= firstIndex && thisRowIndex <= lastIndex) {
                nodeRange.push(node);
            }
        });
        // don't allow range selection if we don't have the full range of rows
        if (nodeRange.length !== (lastIndex - firstIndex + 1)) {
            return [firstInRange];
        }
        return nodeRange;
    };
    ServerSideRowModel.prototype.getRowNode = function (id) {
        var result;
        this.forEachNode(function (rowNode) {
            if (rowNode.id === id) {
                result = rowNode;
            }
            if (rowNode.detailNode && rowNode.detailNode.id === id) {
                result = rowNode.detailNode;
            }
        });
        return result;
    };
    ServerSideRowModel.prototype.isRowPresent = function (rowNode) {
        var foundRowNode = this.getRowNode(rowNode.id);
        return !!foundRowNode;
    };
    ServerSideRowModel.prototype.setRowCount = function (rowCount, lastRowIndexKnown) {
        var rootStore = this.getRootStore();
        if (rootStore) {
            if (rootStore instanceof LazyStore) {
                rootStore.setRowCount(rowCount, lastRowIndexKnown);
                return;
            }
            console.error('AG Grid: Infinite scrolling must be enabled in order to set the row count.');
        }
    };
    __decorate$r([
        Autowired('columnModel')
    ], ServerSideRowModel.prototype, "columnModel", void 0);
    __decorate$r([
        Autowired('filterManager')
    ], ServerSideRowModel.prototype, "filterManager", void 0);
    __decorate$r([
        Autowired('sortController')
    ], ServerSideRowModel.prototype, "sortController", void 0);
    __decorate$r([
        Autowired('rowRenderer')
    ], ServerSideRowModel.prototype, "rowRenderer", void 0);
    __decorate$r([
        Autowired('ssrmSortService')
    ], ServerSideRowModel.prototype, "sortListener", void 0);
    __decorate$r([
        Autowired('ssrmNodeManager')
    ], ServerSideRowModel.prototype, "nodeManager", void 0);
    __decorate$r([
        Autowired('ssrmStoreFactory')
    ], ServerSideRowModel.prototype, "storeFactory", void 0);
    __decorate$r([
        Autowired('beans')
    ], ServerSideRowModel.prototype, "beans", void 0);
    __decorate$r([
        Optional('pivotColDefService')
    ], ServerSideRowModel.prototype, "pivotColDefService", void 0);
    __decorate$r([
        PreDestroy
    ], ServerSideRowModel.prototype, "destroyDatasource", null);
    __decorate$r([
        PostConstruct
    ], ServerSideRowModel.prototype, "addEventListeners", null);
    __decorate$r([
        PreDestroy
    ], ServerSideRowModel.prototype, "destroyRootStore", null);
    ServerSideRowModel = __decorate$r([
        Bean('rowModel')
    ], ServerSideRowModel);
    return ServerSideRowModel;
}(BeanStub));

var __extends$s = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$q = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var StoreUtils = /** @class */ (function (_super) {
    __extends$s(StoreUtils, _super);
    function StoreUtils() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StoreUtils.prototype.loadFromDatasource = function (p) {
        var storeParams = p.storeParams, parentBlock = p.parentBlock, parentNode = p.parentNode;
        var groupKeys = parentNode.getGroupKeys();
        if (!storeParams.datasource) {
            return;
        }
        var request = {
            startRow: p.startRow,
            endRow: p.endRow,
            rowGroupCols: storeParams.rowGroupCols,
            valueCols: storeParams.valueCols,
            pivotCols: storeParams.pivotCols,
            pivotMode: storeParams.pivotMode,
            groupKeys: groupKeys,
            filterModel: storeParams.filterModel,
            sortModel: storeParams.sortModel
        };
        var getRowsParams = this.gridOptionsService.addGridCommonParams({
            success: p.success,
            fail: p.fail,
            request: request,
            parentNode: p.parentNode
        });
        window.setTimeout(function () {
            if (!storeParams.datasource || !parentBlock.isAlive()) {
                // failCallback() is important, to reduce the 'RowNodeBlockLoader.activeBlockLoadsCount' count
                p.fail();
                return;
            }
            storeParams.datasource.getRows(getRowsParams);
        }, 0);
    };
    StoreUtils.prototype.getChildStore = function (keys, currentCache, findNodeFunc) {
        if (_.missingOrEmpty(keys)) {
            return currentCache;
        }
        var nextKey = keys[0];
        var nextNode = findNodeFunc(nextKey);
        if (nextNode) {
            // if we have the final node, but not the final store, we create it to allow
            // early population of data
            if (keys.length === 1 && !nextNode.childStore) {
                var storeParams = this.serverSideRowModel.getParams();
                nextNode.childStore = this.createBean(this.storeFactory.createStore(storeParams, nextNode));
            }
            var keyListForNextLevel = keys.slice(1, keys.length);
            var nextStore = nextNode.childStore;
            return nextStore ? nextStore.getChildStore(keyListForNextLevel) : null;
        }
        return null;
    };
    StoreUtils.prototype.isServerRefreshNeeded = function (parentRowNode, rowGroupCols, params) {
        if (params.valueColChanged || params.secondaryColChanged) {
            return true;
        }
        var level = parentRowNode.level + 1;
        var grouping = level < rowGroupCols.length;
        var leafNodes = !grouping;
        if (leafNodes) {
            return true;
        }
        var colIdThisGroup = rowGroupCols[level].id;
        var actionOnThisGroup = params.changedColumns.indexOf(colIdThisGroup) > -1;
        if (actionOnThisGroup) {
            return true;
        }
        var allCols = this.columnModel.getAllGridColumns();
        var affectedGroupCols = allCols
            // find all impacted cols which also a group display column
            .filter(function (col) { return col.getColDef().showRowGroup && params.changedColumns.includes(col.getId()); })
            .map(function (col) { return col.getColDef().showRowGroup; })
            // if displaying all groups, or displaying the effected col for this group, refresh
            .some(function (group) { return group === true || group === colIdThisGroup; });
        return affectedGroupCols;
    };
    StoreUtils.prototype.getServerSideInitialRowCount = function () {
        return this.gridOptionsService.get('serverSideInitialRowCount');
    };
    StoreUtils.prototype.assertRowModelIsServerSide = function (key) {
        if (!this.gridOptionsService.isRowModelType('serverSide')) {
            _.warnOnce("The '".concat(key, "' property can only be used with the Server Side Row Model."));
            return false;
        }
        return true;
    };
    StoreUtils.prototype.assertNotTreeData = function (key) {
        if (this.gridOptionsService.get('treeData')) {
            _.warnOnce("The '".concat(key, "' property cannot be used while using tree data."));
            return false;
        }
        return true;
    };
    StoreUtils.prototype.isServerSideSortAllLevels = function () {
        return this.gridOptionsService.get('serverSideSortAllLevels') && this.assertRowModelIsServerSide('serverSideSortAllLevels');
    };
    StoreUtils.prototype.isServerSideOnlyRefreshFilteredGroups = function () {
        return this.gridOptionsService.get('serverSideOnlyRefreshFilteredGroups') && this.assertRowModelIsServerSide('serverSideOnlyRefreshFilteredGroups');
    };
    StoreUtils.prototype.isServerSideSortOnServer = function () {
        return this.gridOptionsService.get('serverSideSortOnServer') && this.assertRowModelIsServerSide('serverSideSortOnServer') && this.assertNotTreeData('serverSideSortOnServer');
    };
    StoreUtils.prototype.isServerSideFilterOnServer = function () {
        return this.gridOptionsService.get('serverSideFilterOnServer') && this.assertRowModelIsServerSide('serverSideFilterOnServer') && this.assertNotTreeData('serverSideFilterOnServer');
    };
    __decorate$q([
        Autowired('columnModel')
    ], StoreUtils.prototype, "columnModel", void 0);
    __decorate$q([
        Autowired('rowModel')
    ], StoreUtils.prototype, "serverSideRowModel", void 0);
    __decorate$q([
        Autowired('ssrmStoreFactory')
    ], StoreUtils.prototype, "storeFactory", void 0);
    StoreUtils = __decorate$q([
        Bean('ssrmStoreUtils')
    ], StoreUtils);
    return StoreUtils;
}(BeanStub));

var __extends$r = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$p = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var GROUP_MISSING_KEY_ID = 'ag-Grid-MissingKey';
var BlockUtils = /** @class */ (function (_super) {
    __extends$r(BlockUtils, _super);
    function BlockUtils() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BlockUtils.prototype.createRowNode = function (params) {
        var rowNode = new RowNode(this.beans);
        var rowHeight = params.rowHeight != null ? params.rowHeight : this.gridOptionsService.getRowHeightAsNumber();
        rowNode.setRowHeight(rowHeight);
        rowNode.group = params.group;
        rowNode.leafGroup = params.leafGroup;
        rowNode.level = params.level;
        rowNode.uiLevel = params.level;
        rowNode.parent = params.parent;
        // stub gets set to true here, and then false when this rowNode gets it's data
        rowNode.stub = true;
        rowNode.__needsRefreshWhenVisible = false;
        if (rowNode.group) {
            rowNode.expanded = false;
            rowNode.field = params.field;
            rowNode.rowGroupColumn = params.rowGroupColumn;
        }
        return rowNode;
    };
    BlockUtils.prototype.destroyRowNodes = function (rowNodes) {
        var _this = this;
        if (rowNodes) {
            rowNodes.forEach(function (row) { return _this.destroyRowNode(row); });
        }
    };
    BlockUtils.prototype.destroyRowNode = function (rowNode, preserveStore) {
        if (preserveStore === void 0) { preserveStore = false; }
        if (rowNode.childStore && !preserveStore) {
            this.destroyBean(rowNode.childStore);
            rowNode.childStore = null;
        }
        // if this has a footer, destroy that too
        if (rowNode.sibling && !rowNode.footer) {
            this.destroyRowNode(rowNode.sibling, false);
        }
        // this is needed, so row render knows to fade out the row, otherwise it
        // sees row top is present, and thinks the row should be shown. maybe
        // rowNode should have a flag on whether it is visible???
        rowNode.clearRowTopAndRowIndex();
        if (rowNode.id != null) {
            this.nodeManager.removeNode(rowNode);
        }
    };
    BlockUtils.prototype.setTreeGroupInfo = function (rowNode) {
        rowNode.updateHasChildren();
        var getKeyFunc = this.gridOptionsService.get('getServerSideGroupKey');
        if (rowNode.hasChildren() && getKeyFunc != null) {
            rowNode.key = getKeyFunc(rowNode.data);
        }
        if (!rowNode.hasChildren() && rowNode.childStore != null) {
            this.destroyBean(rowNode.childStore);
            rowNode.childStore = null;
            rowNode.expanded = false;
        }
    };
    BlockUtils.prototype.setRowGroupInfo = function (rowNode) {
        rowNode.key = this.valueService.getValue(rowNode.rowGroupColumn, rowNode);
        if (rowNode.key === null || rowNode.key === undefined) {
            _.doOnce(function () {
                console.warn("AG Grid: null and undefined values are not allowed for server side row model keys");
                if (rowNode.rowGroupColumn) {
                    console.warn("column = ".concat(rowNode.rowGroupColumn.getId()));
                }
                console.warn("data is ", rowNode.data);
            }, 'ServerSideBlock-CannotHaveNullOrUndefinedForKey');
        }
        var getGroupIncludeFooter = this.beans.gridOptionsService.getGroupIncludeFooter();
        var doesRowShowFooter = getGroupIncludeFooter({ node: rowNode });
        if (doesRowShowFooter) {
            rowNode.createFooter();
            if (rowNode.sibling) {
                rowNode.sibling.uiLevel = rowNode.uiLevel + 1;
            }
        }
    };
    BlockUtils.prototype.setMasterDetailInfo = function (rowNode) {
        var isMasterFunc = this.gridOptionsService.get('isRowMaster');
        if (isMasterFunc != null) {
            rowNode.master = isMasterFunc(rowNode.data);
        }
        else {
            rowNode.master = true;
        }
    };
    BlockUtils.prototype.updateDataIntoRowNode = function (rowNode, data) {
        rowNode.updateData(data);
        if (this.gridOptionsService.get('treeData')) {
            this.setTreeGroupInfo(rowNode);
            this.setChildCountIntoRowNode(rowNode);
        }
        else if (rowNode.group) {
            this.setChildCountIntoRowNode(rowNode);
            if (!rowNode.footer) {
                var getGroupIncludeFooter = this.beans.gridOptionsService.getGroupIncludeFooter();
                var doesRowShowFooter = getGroupIncludeFooter({ node: rowNode });
                if (doesRowShowFooter) {
                    if (rowNode.sibling) {
                        rowNode.sibling.updateData(data);
                    }
                    else {
                        rowNode.createFooter();
                    }
                }
                else if (rowNode.sibling) {
                    rowNode.destroyFooter();
                }
            }
            // it's not possible for a node to change whether it's a group or not
            // when doing row grouping (as only rows at certain levels are groups),
            // so nothing to do here
        }
        else if (this.gridOptionsService.get('masterDetail')) ;
    };
    BlockUtils.prototype.setDataIntoRowNode = function (rowNode, data, defaultId, cachedRowHeight) {
        var _a;
        rowNode.stub = false;
        var treeData = this.gridOptionsService.get('treeData');
        if (_.exists(data)) {
            rowNode.setDataAndId(data, defaultId);
            if (treeData) {
                this.setTreeGroupInfo(rowNode);
            }
            else if (rowNode.group) {
                this.setRowGroupInfo(rowNode);
            }
            else if (this.gridOptionsService.get('masterDetail')) {
                this.setMasterDetailInfo(rowNode);
            }
        }
        else {
            rowNode.setDataAndId(undefined, undefined);
            rowNode.key = null;
        }
        if (treeData || rowNode.group) {
            this.setGroupDataIntoRowNode(rowNode);
            this.setChildCountIntoRowNode(rowNode);
        }
        // this needs to be done AFTER setGroupDataIntoRowNode(), as the height can depend on the group data
        // getting set, if it's a group node and colDef.autoHeight=true
        if (_.exists(data)) {
            rowNode.setRowHeight(this.gridOptionsService.getRowHeightForNode(rowNode, false, cachedRowHeight).height);
            (_a = rowNode.sibling) === null || _a === void 0 ? void 0 : _a.setRowHeight(this.gridOptionsService.getRowHeightForNode(rowNode.sibling, false, cachedRowHeight).height);
        }
    };
    BlockUtils.prototype.setChildCountIntoRowNode = function (rowNode) {
        var getChildCount = this.gridOptionsService.get('getChildCount');
        if (getChildCount) {
            rowNode.setAllChildrenCount(getChildCount(rowNode.data));
        }
    };
    BlockUtils.prototype.setGroupDataIntoRowNode = function (rowNode) {
        var _this = this;
        var groupDisplayCols = this.columnModel.getGroupDisplayColumns();
        var usingTreeData = this.gridOptionsService.get('treeData');
        groupDisplayCols.forEach(function (col) {
            if (rowNode.groupData == null) {
                rowNode.groupData = {};
            }
            if (usingTreeData) {
                rowNode.groupData[col.getColId()] = rowNode.key;
            }
            else if (col.isRowGroupDisplayed(rowNode.rowGroupColumn.getId())) {
                var groupValue = _this.valueService.getValue(rowNode.rowGroupColumn, rowNode);
                rowNode.groupData[col.getColId()] = groupValue;
            }
        });
    };
    BlockUtils.prototype.clearDisplayIndex = function (rowNode) {
        rowNode.clearRowTopAndRowIndex();
        var hasChildStore = rowNode.hasChildren() && _.exists(rowNode.childStore);
        if (hasChildStore) {
            var childStore = rowNode.childStore;
            childStore.clearDisplayIndexes();
        }
        var hasDetailNode = rowNode.master && rowNode.detailNode;
        if (hasDetailNode) {
            rowNode.detailNode.clearRowTopAndRowIndex();
        }
    };
    BlockUtils.prototype.setDisplayIndex = function (rowNode, displayIndexSeq, nextRowTop) {
        // set this row
        rowNode.setRowIndex(displayIndexSeq.next());
        rowNode.setRowTop(nextRowTop.value);
        nextRowTop.value += rowNode.rowHeight;
        if (rowNode.footer) {
            return;
        }
        // set child for master / detail
        var hasDetailRow = rowNode.master;
        if (hasDetailRow) {
            if (rowNode.expanded && rowNode.detailNode) {
                rowNode.detailNode.setRowIndex(displayIndexSeq.next());
                rowNode.detailNode.setRowTop(nextRowTop.value);
                nextRowTop.value += rowNode.detailNode.rowHeight;
            }
            else if (rowNode.detailNode) {
                rowNode.detailNode.clearRowTopAndRowIndex();
            }
        }
        // set children for SSRM child rows
        var hasChildStore = rowNode.hasChildren() && _.exists(rowNode.childStore);
        if (hasChildStore) {
            var childStore = rowNode.childStore;
            if (rowNode.expanded) {
                childStore.setDisplayIndexes(displayIndexSeq, nextRowTop);
            }
            else {
                // we need to clear the row tops, as the row renderer depends on
                // this to know if the row should be faded out
                childStore.clearDisplayIndexes();
            }
        }
    };
    BlockUtils.prototype.binarySearchForDisplayIndex = function (displayRowIndex, rowNodes) {
        var bottomPointer = 0;
        var topPointer = rowNodes.length - 1;
        if (_.missing(topPointer) || _.missing(bottomPointer)) {
            console.warn("AG Grid: error: topPointer = ".concat(topPointer, ", bottomPointer = ").concat(bottomPointer));
            return undefined;
        }
        while (true) {
            var midPointer = Math.floor((bottomPointer + topPointer) / 2);
            var currentRowNode = rowNodes[midPointer];
            // first check current row for index
            if (currentRowNode.rowIndex === displayRowIndex) {
                return currentRowNode;
            }
            // then check if current row contains a detail row with the index
            var expandedMasterRow = currentRowNode.master && currentRowNode.expanded;
            var detailNode = currentRowNode.detailNode;
            if (expandedMasterRow && detailNode && detailNode.rowIndex === displayRowIndex) {
                return currentRowNode.detailNode;
            }
            // then check if child cache contains index
            var childStore = currentRowNode.childStore;
            if (currentRowNode.expanded && childStore && childStore.isDisplayIndexInStore(displayRowIndex)) {
                return childStore.getRowUsingDisplayIndex(displayRowIndex);
            }
            // otherwise adjust pointers to continue searching for index
            if (currentRowNode.rowIndex < displayRowIndex) {
                bottomPointer = midPointer + 1;
            }
            else if (currentRowNode.rowIndex > displayRowIndex) {
                topPointer = midPointer - 1;
            }
            else {
                console.warn("AG Grid: error: unable to locate rowIndex = ".concat(displayRowIndex, " in cache"));
                return undefined;
            }
        }
    };
    BlockUtils.prototype.extractRowBounds = function (rowNode, index) {
        var extractRowBounds = function (currentRowNode) { return ({
            rowHeight: currentRowNode.rowHeight,
            rowTop: currentRowNode.rowTop
        }); };
        if (rowNode.rowIndex === index) {
            return extractRowBounds(rowNode);
        }
        if (rowNode.hasChildren() && rowNode.expanded && _.exists(rowNode.childStore)) {
            var childStore = rowNode.childStore;
            if (childStore.isDisplayIndexInStore(index)) {
                return childStore.getRowBounds(index);
            }
        }
        else if (rowNode.master && rowNode.expanded && _.exists(rowNode.detailNode)) {
            if (rowNode.detailNode.rowIndex === index) {
                return extractRowBounds(rowNode.detailNode);
            }
        }
    };
    BlockUtils.prototype.getIndexAtPixel = function (rowNode, pixel) {
        // first check if pixel is in range of current row
        if (rowNode.isPixelInRange(pixel)) {
            return rowNode.rowIndex;
        }
        // then check if current row contains a detail row with pixel in range
        var expandedMasterRow = rowNode.master && rowNode.expanded;
        var detailNode = rowNode.detailNode;
        if (expandedMasterRow && detailNode && detailNode.isPixelInRange(pixel)) {
            return rowNode.detailNode.rowIndex;
        }
        // then check if it's a group row with a child cache with pixel in range
        if (rowNode.hasChildren() && rowNode.expanded && _.exists(rowNode.childStore)) {
            var childStore = rowNode.childStore;
            if (childStore.isPixelInRange(pixel)) {
                return childStore.getRowIndexAtPixel(pixel);
            }
        }
        return null;
        // pixel is not within this row node or it's children / detail, so return undefined
    };
    BlockUtils.prototype.createNodeIdPrefix = function (parentRowNode) {
        var parts = [];
        var rowNode = parentRowNode;
        // pull keys from all parent nodes, but do not include the root node
        while (rowNode && rowNode.level >= 0) {
            if (rowNode.key === '') {
                parts.push(GROUP_MISSING_KEY_ID);
            }
            else {
                parts.push(rowNode.key);
            }
            rowNode = rowNode.parent;
        }
        if (parts.length > 0) {
            return parts.reverse().join('-');
        }
        // no prefix, so node id's are left as they are
        return undefined;
    };
    BlockUtils.prototype.checkOpenByDefault = function (rowNode) {
        return this.expansionService.checkOpenByDefault(rowNode);
    };
    __decorate$p([
        Autowired('valueService')
    ], BlockUtils.prototype, "valueService", void 0);
    __decorate$p([
        Autowired('columnModel')
    ], BlockUtils.prototype, "columnModel", void 0);
    __decorate$p([
        Autowired('ssrmNodeManager')
    ], BlockUtils.prototype, "nodeManager", void 0);
    __decorate$p([
        Autowired('beans')
    ], BlockUtils.prototype, "beans", void 0);
    __decorate$p([
        Autowired('expansionService')
    ], BlockUtils.prototype, "expansionService", void 0);
    BlockUtils = __decorate$p([
        Bean('ssrmBlockUtils')
    ], BlockUtils);
    return BlockUtils;
}(BeanStub));

var __decorate$o = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var NodeManager = /** @class */ (function () {
    function NodeManager() {
        this.rowNodes = {};
    }
    NodeManager.prototype.addRowNode = function (rowNode) {
        var id = rowNode.id;
        if (this.rowNodes[id]) {
            console.warn("AG Grid: Duplicate node id ".concat(rowNode.id, ". Row ID's are provided via the getRowId() callback. Please modify the getRowId() callback code to provide unique row id values."));
            console.warn('first instance', this.rowNodes[id].data);
            console.warn('second instance', rowNode.data);
        }
        this.rowNodes[id] = rowNode;
    };
    NodeManager.prototype.removeNode = function (rowNode) {
        var id = rowNode.id;
        if (this.rowNodes[id]) {
            this.rowNodes[id] = undefined;
        }
    };
    NodeManager.prototype.clear = function () {
        this.rowNodes = {};
    };
    __decorate$o([
        PreDestroy
    ], NodeManager.prototype, "clear", null);
    NodeManager = __decorate$o([
        Bean('ssrmNodeManager')
    ], NodeManager);
    return NodeManager;
}());

var __extends$q = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$n = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var TransactionManager = /** @class */ (function (_super) {
    __extends$q(TransactionManager, _super);
    function TransactionManager() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.asyncTransactions = [];
        return _this;
    }
    TransactionManager.prototype.postConstruct = function () {
        // only want to be active if SSRM active, otherwise would be interfering with other row models
        if (!this.gridOptionsService.isRowModelType('serverSide')) {
            return;
        }
    };
    TransactionManager.prototype.applyTransactionAsync = function (transaction, callback) {
        if (this.asyncTransactionsTimeout == null) {
            this.scheduleExecuteAsync();
        }
        this.asyncTransactions.push({ transaction: transaction, callback: callback });
    };
    TransactionManager.prototype.scheduleExecuteAsync = function () {
        var _this = this;
        var waitMillis = this.gridOptionsService.getAsyncTransactionWaitMillis();
        this.asyncTransactionsTimeout = window.setTimeout(function () {
            _this.executeAsyncTransactions();
        }, waitMillis);
    };
    TransactionManager.prototype.executeAsyncTransactions = function () {
        var _this = this;
        if (!this.asyncTransactions) {
            return;
        }
        var resultFuncs = [];
        var resultsForEvent = [];
        var transactionsToRetry = [];
        var atLeastOneTransactionApplied = false;
        this.asyncTransactions.forEach(function (txWrapper) {
            var result;
            var hasStarted = _this.serverSideRowModel.executeOnStore(txWrapper.transaction.route, function (cache) {
                result = cache.applyTransaction(txWrapper.transaction);
            });
            if (!hasStarted) {
                result = { status: ServerSideTransactionResultStatus.StoreNotStarted };
            }
            else if (result == undefined) {
                result = { status: ServerSideTransactionResultStatus.StoreNotFound };
            }
            resultsForEvent.push(result);
            var retryTransaction = result.status == ServerSideTransactionResultStatus.StoreLoading;
            if (retryTransaction) {
                transactionsToRetry.push(txWrapper);
                return;
            }
            if (txWrapper.callback) {
                resultFuncs.push(function () { return txWrapper.callback(result); });
            }
            if (result.status === ServerSideTransactionResultStatus.Applied) {
                atLeastOneTransactionApplied = true;
            }
        });
        // do callbacks in next VM turn so it's async
        if (resultFuncs.length > 0) {
            window.setTimeout(function () {
                resultFuncs.forEach(function (func) { return func(); });
            }, 0);
        }
        this.asyncTransactionsTimeout = undefined;
        // this will be empty list if nothing to retry
        this.asyncTransactions = transactionsToRetry;
        if (atLeastOneTransactionApplied) {
            this.valueCache.onDataChanged();
            this.eventService.dispatchEvent({ type: Events.EVENT_STORE_UPDATED });
        }
        if (resultsForEvent.length > 0) {
            var event_1 = {
                type: Events.EVENT_ASYNC_TRANSACTIONS_FLUSHED,
                results: resultsForEvent
            };
            this.eventService.dispatchEvent(event_1);
        }
    };
    TransactionManager.prototype.flushAsyncTransactions = function () {
        // the timeout could be missing, if we are flushing due to row data loaded
        if (this.asyncTransactionsTimeout != null) {
            clearTimeout(this.asyncTransactionsTimeout);
        }
        this.executeAsyncTransactions();
    };
    TransactionManager.prototype.applyTransaction = function (transaction) {
        var res;
        var hasStarted = this.serverSideRowModel.executeOnStore(transaction.route, function (store) {
            res = store.applyTransaction(transaction);
        });
        if (!hasStarted) {
            return { status: ServerSideTransactionResultStatus.StoreNotStarted };
        }
        else if (res) {
            this.valueCache.onDataChanged();
            if (res.remove) {
                var removedRowIds = res.remove.map(function (row) { return row.id; });
                this.selectionService.deleteSelectionStateFromParent(transaction.route || [], removedRowIds);
            }
            this.eventService.dispatchEvent({ type: Events.EVENT_STORE_UPDATED });
            return res;
        }
        else {
            return { status: ServerSideTransactionResultStatus.StoreNotFound };
        }
    };
    __decorate$n([
        Autowired('rowNodeBlockLoader')
    ], TransactionManager.prototype, "rowNodeBlockLoader", void 0);
    __decorate$n([
        Autowired('valueCache')
    ], TransactionManager.prototype, "valueCache", void 0);
    __decorate$n([
        Autowired('rowModel')
    ], TransactionManager.prototype, "serverSideRowModel", void 0);
    __decorate$n([
        Autowired('rowRenderer')
    ], TransactionManager.prototype, "rowRenderer", void 0);
    __decorate$n([
        Autowired('selectionService')
    ], TransactionManager.prototype, "selectionService", void 0);
    __decorate$n([
        PostConstruct
    ], TransactionManager.prototype, "postConstruct", null);
    TransactionManager = __decorate$n([
        Bean('ssrmTransactionManager')
    ], TransactionManager);
    return TransactionManager;
}(BeanStub));

var __extends$p = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$m = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ExpandListener = /** @class */ (function (_super) {
    __extends$p(ExpandListener, _super);
    function ExpandListener() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExpandListener.prototype.postConstruct = function () {
        // only want to be active if SSRM active, otherwise would be interfering with other row models
        if (!this.gridOptionsService.isRowModelType('serverSide')) {
            return;
        }
        this.addManagedListener(this.eventService, Events.EVENT_ROW_GROUP_OPENED, this.onRowGroupOpened.bind(this));
    };
    ExpandListener.prototype.onRowGroupOpened = function (event) {
        var rowNode = event.node;
        if (rowNode.expanded) {
            if (rowNode.master) {
                this.createDetailNode(rowNode);
            }
            else if (_.missing(rowNode.childStore)) {
                var storeParams = this.serverSideRowModel.getParams();
                rowNode.childStore = this.createBean(this.storeFactory.createStore(storeParams, rowNode));
            }
        }
        else if (this.gridOptionsService.get('purgeClosedRowNodes') && _.exists(rowNode.childStore)) {
            rowNode.childStore = this.destroyBean(rowNode.childStore);
        }
        var storeUpdatedEvent = { type: Events.EVENT_STORE_UPDATED };
        this.eventService.dispatchEvent(storeUpdatedEvent);
    };
    ExpandListener.prototype.createDetailNode = function (masterNode) {
        if (_.exists(masterNode.detailNode)) {
            return masterNode.detailNode;
        }
        var detailNode = new RowNode(this.beans);
        detailNode.detail = true;
        detailNode.selectable = false;
        detailNode.parent = masterNode;
        if (_.exists(masterNode.id)) {
            detailNode.id = 'detail_' + masterNode.id;
        }
        detailNode.data = masterNode.data;
        detailNode.level = masterNode.level + 1;
        var defaultDetailRowHeight = 200;
        var rowHeight = this.gridOptionsService.getRowHeightForNode(detailNode).height;
        detailNode.rowHeight = rowHeight ? rowHeight : defaultDetailRowHeight;
        masterNode.detailNode = detailNode;
        return detailNode;
    };
    __decorate$m([
        Autowired('rowModel')
    ], ExpandListener.prototype, "serverSideRowModel", void 0);
    __decorate$m([
        Autowired('ssrmStoreFactory')
    ], ExpandListener.prototype, "storeFactory", void 0);
    __decorate$m([
        Autowired('beans')
    ], ExpandListener.prototype, "beans", void 0);
    __decorate$m([
        PostConstruct
    ], ExpandListener.prototype, "postConstruct", null);
    ExpandListener = __decorate$m([
        Bean('ssrmExpandListener')
    ], ExpandListener);
    return ExpandListener;
}(BeanStub));

var __extends$o = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$l = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var SortListener = /** @class */ (function (_super) {
    __extends$o(SortListener, _super);
    function SortListener() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SortListener.prototype.postConstruct = function () {
        // only want to be active if SSRM active, otherwise would be interfering with other row models
        if (!this.gridOptionsService.isRowModelType('serverSide')) {
            return;
        }
        this.addManagedListener(this.eventService, Events.EVENT_SORT_CHANGED, this.onSortChanged.bind(this));
    };
    SortListener.prototype.onSortChanged = function () {
        var storeParams = this.serverSideRowModel.getParams();
        if (!storeParams) {
            return;
        } // params is undefined if no datasource set
        var newSortModel = this.sortController.getSortModel();
        var oldSortModel = storeParams.sortModel;
        var changedColumns = this.findChangedColumnsInSort(newSortModel, oldSortModel);
        var valueColChanged = this.listenerUtils.isSortingWithValueColumn(changedColumns);
        var secondaryColChanged = this.listenerUtils.isSortingWithSecondaryColumn(changedColumns);
        var params = {
            valueColChanged: valueColChanged,
            secondaryColChanged: secondaryColChanged,
            changedColumns: changedColumns
        };
        this.serverSideRowModel.refreshAfterSort(newSortModel, params);
    };
    // returns back all the cols that were effected by the sorting. eg if we were sorting by col A,
    // and now we are sorting by col B, the list of impacted cols should be A and B. so if a cache
    // is impacted by sorting on A or B then it needs to be refreshed. this includes where the cache
    // was previously sorted by A and then the A sort now needs to be cleared.
    SortListener.prototype.findChangedColumnsInSort = function (newSortModel, oldSortModel) {
        var allColsInBothSorts = [];
        [newSortModel, oldSortModel].forEach(function (sortModel) {
            if (sortModel) {
                var ids = sortModel.map(function (sm) { return sm.colId; });
                allColsInBothSorts = allColsInBothSorts.concat(ids);
            }
        });
        var differentSorts = function (oldSortItem, newSortItem) {
            var oldSort = oldSortItem ? oldSortItem.sort : null;
            var newSort = newSortItem ? newSortItem.sort : null;
            return oldSort !== newSort;
        };
        var differentIndexes = function (oldSortItem, newSortItem) {
            var oldIndex = oldSortItem ? oldSortModel.indexOf(oldSortItem) : -1;
            var newIndex = newSortItem ? newSortModel.indexOf(newSortItem) : -1;
            return oldIndex !== newIndex;
        };
        return allColsInBothSorts.filter(function (colId) {
            var oldSortItem = oldSortModel.find(function (sm) { return sm.colId === colId; });
            var newSortItem = newSortModel.find(function (sm) { return sm.colId === colId; });
            return differentSorts(oldSortItem, newSortItem) || differentIndexes(oldSortItem, newSortItem);
        });
    };
    __decorate$l([
        Autowired('sortController')
    ], SortListener.prototype, "sortController", void 0);
    __decorate$l([
        Autowired('rowModel')
    ], SortListener.prototype, "serverSideRowModel", void 0);
    __decorate$l([
        Autowired('ssrmListenerUtils')
    ], SortListener.prototype, "listenerUtils", void 0);
    __decorate$l([
        PostConstruct
    ], SortListener.prototype, "postConstruct", null);
    SortListener = __decorate$l([
        Bean('ssrmSortService')
    ], SortListener);
    return SortListener;
}(BeanStub));

var __extends$n = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$k = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var FilterListener = /** @class */ (function (_super) {
    __extends$n(FilterListener, _super);
    function FilterListener() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FilterListener.prototype.postConstruct = function () {
        var _this = this;
        // only want to be active if SSRM active, otherwise would be interfering with other row models
        if (!this.gridOptionsService.isRowModelType('serverSide')) {
            return;
        }
        this.addManagedListener(this.eventService, Events.EVENT_ADVANCED_FILTER_ENABLED_CHANGED, function () { return _this.onFilterChanged(true); });
        this.addManagedListener(this.eventService, Events.EVENT_FILTER_CHANGED, function () { return _this.onFilterChanged(); });
    };
    FilterListener.prototype.onFilterChanged = function (advancedFilterEnabledChanged) {
        var storeParams = this.serverSideRowModel.getParams();
        if (!storeParams) {
            return;
        } // params is undefined if no datasource set
        var oldModel = storeParams.filterModel;
        var newModel;
        var changedColumns;
        if (this.filterManager.isAdvancedFilterEnabled()) {
            newModel = this.filterManager.getAdvancedFilterModel();
            // if advancedFilterEnabledChanged, old model is of type `FilterModel`
            var oldColumns = advancedFilterEnabledChanged ? Object.keys(oldModel !== null && oldModel !== void 0 ? oldModel : {}) : this.getAdvancedFilterColumns(oldModel);
            var newColumns_1 = this.getAdvancedFilterColumns(newModel);
            oldColumns.forEach(function (column) { return newColumns_1.add(column); });
            changedColumns = Array.from(newColumns_1);
        }
        else {
            newModel = this.filterManager.getFilterModel();
            if (advancedFilterEnabledChanged) {
                // old model is of type `AdvancedFilterModel | null`
                var oldColumns_1 = this.getAdvancedFilterColumns(oldModel);
                Object.keys(newModel).forEach(function (column) { return oldColumns_1.add(column); });
                changedColumns = Array.from(oldColumns_1);
            }
            else {
                changedColumns = this.findChangedColumns(oldModel, newModel);
            }
        }
        var valueColChanged = this.listenerUtils.isSortingWithValueColumn(changedColumns);
        var secondaryColChanged = this.listenerUtils.isSortingWithSecondaryColumn(changedColumns);
        var params = {
            valueColChanged: valueColChanged,
            secondaryColChanged: secondaryColChanged,
            changedColumns: changedColumns
        };
        this.serverSideRowModel.refreshAfterFilter(newModel, params);
    };
    FilterListener.prototype.findChangedColumns = function (oldModel, newModel) {
        var allColKeysMap = {};
        Object.keys(oldModel).forEach(function (key) { return allColKeysMap[key] = true; });
        Object.keys(newModel).forEach(function (key) { return allColKeysMap[key] = true; });
        var res = [];
        Object.keys(allColKeysMap).forEach(function (key) {
            var oldJson = JSON.stringify(oldModel[key]);
            var newJson = JSON.stringify(newModel[key]);
            var filterChanged = oldJson != newJson;
            if (filterChanged) {
                res.push(key);
            }
        });
        return res;
    };
    FilterListener.prototype.getAdvancedFilterColumns = function (model) {
        var columns = new Set();
        if (!model) {
            return columns;
        }
        var processAdvancedFilterModel = function (filterModel) {
            if (filterModel.filterType === 'join') {
                filterModel.conditions.forEach(function (condition) { return processAdvancedFilterModel(condition); });
            }
            else {
                columns.add(filterModel.colId);
            }
        };
        processAdvancedFilterModel(model);
        return columns;
    };
    __decorate$k([
        Autowired('rowModel')
    ], FilterListener.prototype, "serverSideRowModel", void 0);
    __decorate$k([
        Autowired('filterManager')
    ], FilterListener.prototype, "filterManager", void 0);
    __decorate$k([
        Autowired('ssrmListenerUtils')
    ], FilterListener.prototype, "listenerUtils", void 0);
    __decorate$k([
        PostConstruct
    ], FilterListener.prototype, "postConstruct", null);
    FilterListener = __decorate$k([
        Bean('ssrmFilterListener')
    ], FilterListener);
    return FilterListener;
}(BeanStub));

var __decorate$j = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var StoreFactory = /** @class */ (function () {
    function StoreFactory() {
    }
    StoreFactory.prototype.createStore = function (ssrmParams, parentNode) {
        var storeParams = this.getStoreParams(ssrmParams, parentNode);
        var CacheClass = storeParams.suppressInfiniteScroll ? FullStore : LazyStore;
        return new CacheClass(ssrmParams, storeParams, parentNode);
    };
    StoreFactory.prototype.getStoreParams = function (ssrmParams, parentNode) {
        var userStoreParams = this.getLevelSpecificParams(parentNode);
        // if user provided overrideParams, we take infiniteScroll from there if it exists
        var infiniteScroll = this.isInfiniteScroll(userStoreParams);
        var cacheBlockSize = this.getBlockSize(infiniteScroll, userStoreParams);
        var maxBlocksInCache = this.getMaxBlocksInCache(infiniteScroll, ssrmParams, userStoreParams);
        var storeParams = {
            suppressInfiniteScroll: !infiniteScroll,
            cacheBlockSize: cacheBlockSize,
            maxBlocksInCache: maxBlocksInCache
        };
        return storeParams;
    };
    StoreFactory.prototype.getMaxBlocksInCache = function (infiniteScroll, ssrmParams, userStoreParams) {
        if (!infiniteScroll) {
            return undefined;
        }
        var maxBlocksInCache = (userStoreParams && userStoreParams.maxBlocksInCache != null)
            ? userStoreParams.maxBlocksInCache
            : this.gridOptionsService.get('maxBlocksInCache');
        var maxBlocksActive = maxBlocksInCache != null && maxBlocksInCache >= 0;
        if (!maxBlocksActive) {
            return undefined;
        }
        if (ssrmParams.dynamicRowHeight) {
            var message = 'Server Side Row Model does not support Dynamic Row Height and Cache Purging. ' +
                'Either a) remove getRowHeight() callback or b) remove maxBlocksInCache property. Purging has been disabled.';
            _.warnOnce(message);
            return undefined;
        }
        if (this.columnModel.isAutoRowHeightActive()) {
            var message = 'Server Side Row Model does not support Auto Row Height and Cache Purging. ' +
                'Either a) remove colDef.autoHeight or b) remove maxBlocksInCache property. Purging has been disabled.';
            _.warnOnce(message);
            return undefined;
        }
        return maxBlocksInCache;
    };
    StoreFactory.prototype.getBlockSize = function (infiniteScroll, userStoreParams) {
        if (!infiniteScroll) {
            return undefined;
        }
        var blockSize = (userStoreParams && userStoreParams.cacheBlockSize != null)
            ? userStoreParams.cacheBlockSize
            : this.gridOptionsService.get('cacheBlockSize');
        if (blockSize != null && blockSize > 0) {
            return blockSize;
        }
        else {
            return 100;
        }
    };
    StoreFactory.prototype.getLevelSpecificParams = function (parentNode) {
        var callback = this.gridOptionsService.getCallback('getServerSideGroupLevelParams');
        if (!callback) {
            return undefined;
        }
        var params = {
            level: parentNode.level + 1,
            parentRowNode: parentNode.level >= 0 ? parentNode : undefined,
            rowGroupColumns: this.columnModel.getRowGroupColumns(),
            pivotColumns: this.columnModel.getPivotColumns(),
            pivotMode: this.columnModel.isPivotMode()
        };
        var res = callback(params);
        return res;
    };
    StoreFactory.prototype.isInfiniteScroll = function (storeParams) {
        var res = (storeParams && storeParams.suppressInfiniteScroll != null)
            ? storeParams.suppressInfiniteScroll
            : this.isSuppressServerSideInfiniteScroll();
        return !res;
    };
    StoreFactory.prototype.isSuppressServerSideInfiniteScroll = function () {
        return this.gridOptionsService.get('suppressServerSideInfiniteScroll');
    };
    __decorate$j([
        Autowired('gridOptionsService')
    ], StoreFactory.prototype, "gridOptionsService", void 0);
    __decorate$j([
        Autowired('columnModel')
    ], StoreFactory.prototype, "columnModel", void 0);
    StoreFactory = __decorate$j([
        Bean('ssrmStoreFactory')
    ], StoreFactory);
    return StoreFactory;
}());

var __decorate$i = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ListenerUtils = /** @class */ (function () {
    function ListenerUtils() {
    }
    ListenerUtils.prototype.isSortingWithValueColumn = function (changedColumnsInSort) {
        var valueColIds = this.columnModel.getValueColumns().map(function (col) { return col.getColId(); });
        for (var i = 0; i < changedColumnsInSort.length; i++) {
            if (valueColIds.indexOf(changedColumnsInSort[i]) > -1) {
                return true;
            }
        }
        return false;
    };
    ListenerUtils.prototype.isSortingWithSecondaryColumn = function (changedColumnsInSort) {
        if (!this.columnModel.getSecondaryColumns()) {
            return false;
        }
        var secondaryColIds = this.columnModel.getSecondaryColumns().map(function (col) { return col.getColId(); });
        for (var i = 0; i < changedColumnsInSort.length; i++) {
            if (secondaryColIds.indexOf(changedColumnsInSort[i]) > -1) {
                return true;
            }
        }
        return false;
    };
    __decorate$i([
        Autowired('columnModel')
    ], ListenerUtils.prototype, "columnModel", void 0);
    ListenerUtils = __decorate$i([
        Bean('ssrmListenerUtils')
    ], ListenerUtils);
    return ListenerUtils;
}());

var __extends$m = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$h = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read$3 = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$3 = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var DefaultStrategy = /** @class */ (function (_super) {
    __extends$m(DefaultStrategy, _super);
    function DefaultStrategy() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selectedState = { selectAll: false, toggledNodes: new Set() };
        _this.lastSelected = null;
        _this.selectAllUsed = false;
        // this is to prevent regressions, default selectionService retains reference of clicked nodes.
        _this.selectedNodes = {};
        return _this;
    }
    DefaultStrategy.prototype.init = function () {
        var _this = this;
        this.rowSelection = this.gridOptionsService.get('rowSelection');
        this.addManagedPropertyListener('rowSelection', function (propChange) {
            _this.rowSelection = propChange.currentValue;
        });
    };
    DefaultStrategy.prototype.getSelectedState = function () {
        return {
            selectAll: this.selectedState.selectAll,
            toggledNodes: __spreadArray$3([], __read$3(this.selectedState.toggledNodes), false),
        };
    };
    DefaultStrategy.prototype.setSelectedState = function (state) {
        // fire selection changed event
        var newState = {
            selectAll: false,
            toggledNodes: new Set(),
        };
        if (typeof state !== 'object') {
            console.error('AG Grid: The provided selection state should be an object.');
            return;
        }
        if ('selectAll' in state && typeof state.selectAll === 'boolean') {
            newState.selectAll = state.selectAll;
        }
        else {
            console.error('AG Grid: Select all status should be of boolean type.');
            return;
        }
        if ('toggledNodes' in state && Array.isArray(state.toggledNodes)) {
            state.toggledNodes.forEach(function (key) {
                if (typeof key === 'string') {
                    newState.toggledNodes.add(key);
                }
                else {
                    console.warn("AG Grid: Provided ids must be of string type. Invalid id provided: ".concat(key));
                }
            });
        }
        else {
            console.error('AG Grid: `toggledNodes` must be an array of string ids.');
            return;
        }
        this.selectedState = newState;
    };
    DefaultStrategy.prototype.deleteSelectionStateFromParent = function (parentPath, removedNodeIds) {
        var _this = this;
        if (this.selectedState.toggledNodes.size === 0) {
            return false;
        }
        var anyNodesToggled = false;
        removedNodeIds.forEach(function (id) {
            if (_this.selectedState.toggledNodes.delete(id)) {
                anyNodesToggled = true;
            }
        });
        return anyNodesToggled;
    };
    DefaultStrategy.prototype.setNodesSelected = function (params) {
        var _a;
        var _this = this;
        if (params.nodes.length === 0)
            return 0;
        var onlyThisNode = params.clearSelection && params.newValue && !params.rangeSelect;
        if (this.rowSelection !== 'multiple' || onlyThisNode) {
            if (params.nodes.length > 1) {
                throw new Error('AG Grid: cannot select multiple rows when rowSelection is set to \'single\'');
            }
            var node = params.nodes[0];
            if (params.newValue) {
                this.selectedNodes = (_a = {}, _a[node.id] = node, _a);
                this.selectedState = {
                    selectAll: false,
                    toggledNodes: new Set([node.id]),
                };
            }
            else {
                this.selectedNodes = {};
                this.selectedState = {
                    selectAll: false,
                    toggledNodes: new Set(),
                };
            }
            this.lastSelected = node.id;
            return 1;
        }
        var updateNodeState = function (node) {
            if (params.newValue) {
                _this.selectedNodes[node.id] = node;
            }
            else {
                delete _this.selectedNodes[node.id];
            }
            var isNodeSelectable = node.selectable;
            var doesNodeConform = params.newValue === _this.selectedState.selectAll;
            if (doesNodeConform || !isNodeSelectable) {
                _this.selectedState.toggledNodes.delete(node.id);
                return;
            }
            _this.selectedState.toggledNodes.add(node.id);
        };
        if (params.rangeSelect && this.lastSelected) {
            if (params.nodes.length > 1) {
                throw new Error('AG Grid: cannot select multiple rows when using rangeSelect');
            }
            var node = params.nodes[0];
            var lastSelectedNode = this.rowModel.getRowNode(this.lastSelected);
            this.rowModel.getNodesInRangeForSelection(node, lastSelectedNode !== null && lastSelectedNode !== void 0 ? lastSelectedNode : null).forEach(updateNodeState);
            this.lastSelected = node.id;
            return 1;
        }
        params.nodes.forEach(updateNodeState);
        this.lastSelected = params.nodes[params.nodes.length - 1].id;
        return 1;
    };
    DefaultStrategy.prototype.processNewRow = function (node) {
        if (this.selectedNodes[node.id]) {
            this.selectedNodes[node.id] = node;
        }
    };
    DefaultStrategy.prototype.isNodeSelected = function (node) {
        var isToggled = this.selectedState.toggledNodes.has(node.id);
        return this.selectedState.selectAll ? !isToggled : isToggled;
    };
    DefaultStrategy.prototype.getSelectedNodes = function () {
        if (this.selectAllUsed) {
            console.warn("AG Grid: getSelectedNodes and getSelectedRows functions cannot be used with select all functionality with the server-side row model.\n                Use `api.getServerSideSelectionState()` instead.");
        }
        return Object.values(this.selectedNodes);
    };
    DefaultStrategy.prototype.getSelectedRows = function () {
        return this.getSelectedNodes().map(function (node) { return node.data; });
    };
    DefaultStrategy.prototype.getSelectionCount = function () {
        if (this.selectedState.selectAll) {
            return -1;
        }
        return this.selectedState.toggledNodes.size;
    };
    DefaultStrategy.prototype.clearOtherNodes = function (rowNodeToKeepSelected, source) {
        var clearedRows = this.selectedState.selectAll ? 1 : this.selectedState.toggledNodes.size - 1;
        this.selectedState = {
            selectAll: false,
            toggledNodes: new Set([rowNodeToKeepSelected.id]),
        };
        this.rowModel.forEachNode(function (node) {
            if (node !== rowNodeToKeepSelected) {
                node.selectThisNode(false, undefined, source);
            }
        });
        var event = {
            type: Events.EVENT_SELECTION_CHANGED,
            source: source,
        };
        this.eventService.dispatchEvent(event);
        return clearedRows;
    };
    DefaultStrategy.prototype.isEmpty = function () {
        var _a;
        return !this.selectedState.selectAll && !((_a = this.selectedState.toggledNodes) === null || _a === void 0 ? void 0 : _a.size);
    };
    DefaultStrategy.prototype.selectAllRowNodes = function (params) {
        this.selectedState = { selectAll: true, toggledNodes: new Set() };
        this.selectedNodes = {};
        this.selectAllUsed = true;
    };
    DefaultStrategy.prototype.deselectAllRowNodes = function (params) {
        this.selectedState = { selectAll: false, toggledNodes: new Set() };
        this.selectedNodes = {};
    };
    DefaultStrategy.prototype.getSelectAllState = function (justFiltered, justCurrentPage) {
        if (this.selectedState.selectAll) {
            if (this.selectedState.toggledNodes.size > 0) {
                return null;
            }
            return true;
        }
        if (this.selectedState.toggledNodes.size > 0) {
            return null;
        }
        return false;
    };
    __decorate$h([
        Autowired('rowModel')
    ], DefaultStrategy.prototype, "rowModel", void 0);
    __decorate$h([
        PostConstruct
    ], DefaultStrategy.prototype, "init", null);
    return DefaultStrategy;
}(BeanStub));

var __extends$l = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$2 = (undefined && undefined.__assign) || function () {
    __assign$2 = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$2.apply(this, arguments);
};
var __decorate$g = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest$1 = (undefined && undefined.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read$2 = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$2 = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var GroupSelectsChildrenStrategy = /** @class */ (function (_super) {
    __extends$l(GroupSelectsChildrenStrategy, _super);
    function GroupSelectsChildrenStrategy() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selectedState = { selectAllChildren: false, toggledNodes: new Map() };
        _this.lastSelected = null;
        return _this;
    }
    GroupSelectsChildrenStrategy.prototype.init = function () {
        var _this = this;
        // if model has updated, a store may now be fully loaded to clean up indeterminate states
        this.addManagedListener(this.eventService, Events.EVENT_MODEL_UPDATED, function () { return _this.removeRedundantState(); });
        // when the grouping changes, the state no longer makes sense, so reset the state.
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_ROW_GROUP_CHANGED, function () { return _this.selectionService.reset('rowGroupChanged'); });
    };
    GroupSelectsChildrenStrategy.prototype.getSelectedState = function () {
        var _this = this;
        var treeData = this.gridOptionsService.get('treeData');
        var recursivelySerializeState = function (state, level, nodeId) {
            var normalisedState = {
                nodeId: nodeId,
            };
            if (treeData || level <= _this.columnModel.getRowGroupColumns().length) {
                normalisedState.selectAllChildren = state.selectAllChildren;
            }
            // omit toggledNodes if empty
            if (state.toggledNodes.size) {
                var toggledNodes_1 = [];
                state.toggledNodes.forEach(function (value, key) {
                    var newState = recursivelySerializeState(value, level + 1, key);
                    toggledNodes_1.push(newState);
                });
                normalisedState.toggledNodes = toggledNodes_1;
            }
            return normalisedState;
        };
        return recursivelySerializeState(this.selectedState, 0);
    };
    GroupSelectsChildrenStrategy.prototype.setSelectedState = function (state) {
        var recursivelyDeserializeState = function (normalisedState, parentSelected) {
            var _a, _b;
            if (typeof normalisedState !== 'object') {
                throw new Error('AG Grid: Each provided state object must be an object.');
            }
            if ('selectAllChildren' in normalisedState && typeof normalisedState.selectAllChildren !== 'boolean') {
                throw new Error('AG Grid: `selectAllChildren` must be a boolean value or undefined.');
            }
            if ('toggledNodes' in normalisedState) {
                if (!Array.isArray(normalisedState.toggledNodes)) {
                    throw new Error('AG Grid: `toggledNodes` must be an array.');
                }
                var allHaveIds = normalisedState.toggledNodes.every(function (innerState) { return (typeof innerState === 'object' && 'nodeId' in innerState && typeof innerState.nodeId === 'string'); });
                if (!allHaveIds) {
                    throw new Error('AG Grid: Every `toggledNode` requires an associated string id.');
                }
            }
            var isThisNodeSelected = (_a = normalisedState.selectAllChildren) !== null && _a !== void 0 ? _a : !parentSelected;
            var convertedChildren = (_b = normalisedState.toggledNodes) === null || _b === void 0 ? void 0 : _b.map(function (innerState) { return ([innerState.nodeId, recursivelyDeserializeState(innerState, isThisNodeSelected)]); });
            var doesRedundantStateExist = convertedChildren === null || convertedChildren === void 0 ? void 0 : convertedChildren.some(function (_a) {
                var _b = __read$2(_a, 2); _b[0]; var innerState = _b[1];
                return isThisNodeSelected === innerState.selectAllChildren && innerState.toggledNodes.size === 0;
            });
            if (doesRedundantStateExist) {
                throw new Error("\n                    AG Grid: AG Grid: Row selection state could not be parsed due to invalid data. Ensure all child state has toggledNodes or does not conform with the parent rule.\n                    Please rebuild the selection state and reapply it.\n                ");
            }
            return {
                selectAllChildren: isThisNodeSelected,
                toggledNodes: new Map(convertedChildren),
            };
        };
        try {
            this.selectedState = recursivelyDeserializeState(state, !!state.selectAllChildren);
        }
        catch (e) {
            console.error(e.message);
        }
    };
    GroupSelectsChildrenStrategy.prototype.deleteSelectionStateFromParent = function (parentRoute, removedNodeIds) {
        var parentState = this.selectedState;
        var remainingRoute = __spreadArray$2([], __read$2(parentRoute), false);
        while (parentState && remainingRoute.length) {
            parentState = parentState.toggledNodes.get(remainingRoute.pop());
        }
        // parent has no explicit state, nothing to remove
        if (!parentState) {
            return false;
        }
        var anyStateChanged = false;
        removedNodeIds.forEach(function (id) {
            if (parentState === null || parentState === void 0 ? void 0 : parentState.toggledNodes.delete(id)) {
                anyStateChanged = true;
            }
        });
        if (anyStateChanged) {
            this.removeRedundantState();
        }
        return anyStateChanged;
    };
    GroupSelectsChildrenStrategy.prototype.setNodesSelected = function (params) {
        var _this = this;
        var nodes = params.nodes, other = __rest$1(params, ["nodes"]);
        if (nodes.length === 0)
            return 0;
        if (params.rangeSelect) {
            if (nodes.length > 1) {
                throw new Error('AG Grid: cannot select multiple rows when using rangeSelect');
            }
            var node_1 = nodes[0];
            var rangeOfNodes = this.rowModel.getNodesInRangeForSelection(node_1, this.lastSelected);
            // sort the routes by route length, high to low, this means we can do the lowest level children first
            var routes = rangeOfNodes.map(this.getRouteToNode).sort(function (a, b) { return b.length - a.length; });
            // skip routes if we've already done a descendent
            var completedRoutes_1 = new Set();
            routes.forEach(function (route) {
                // skip routes if we've already selected a descendent
                if (completedRoutes_1.has(route[route.length - 1])) {
                    return;
                }
                route.forEach(function (part) { return completedRoutes_1.add(part); });
                _this.recursivelySelectNode(route, _this.selectedState, __assign$2({ node: node_1 }, other));
            });
            this.removeRedundantState();
            this.lastSelected = node_1;
            return 1;
        }
        params.nodes.forEach(function (node) {
            var idPathToNode = _this.getRouteToNode(node);
            _this.recursivelySelectNode(idPathToNode, _this.selectedState, __assign$2(__assign$2({}, other), { node: node }));
        });
        this.removeRedundantState();
        this.lastSelected = params.nodes[params.nodes.length - 1];
        return 1;
    };
    GroupSelectsChildrenStrategy.prototype.isNodeSelected = function (node) {
        var path = this.getRouteToNode(node);
        return this.isNodePathSelected(path, this.selectedState);
    };
    GroupSelectsChildrenStrategy.prototype.isNodePathSelected = function (_a, state) {
        var _b = __read$2(_a), nextNode = _b[0], nodes = _b.slice(1);
        if (nodes.length === 0) {
            var isToggled = state.toggledNodes.has(nextNode.id);
            if (nextNode.hasChildren()) {
                var groupState = state.toggledNodes.get(nextNode.id);
                if (groupState && groupState.toggledNodes.size) {
                    return undefined;
                }
            }
            return state.selectAllChildren ? !isToggled : isToggled;
        }
        // if there's a deeper level, check recursively
        if (state.toggledNodes.has(nextNode.id)) {
            var nextState = state.toggledNodes.get(nextNode.id);
            if (nextState) {
                return this.isNodePathSelected(nodes, nextState);
            }
        }
        // no deeper custom state, respect the closest default
        return !!state.selectAllChildren;
    };
    GroupSelectsChildrenStrategy.prototype.getRouteToNode = function (node) {
        var pathToNode = [];
        var tempNode = node;
        while (tempNode.parent) {
            pathToNode.push(tempNode);
            tempNode = tempNode.parent;
        }
        return pathToNode.reverse();
    };
    GroupSelectsChildrenStrategy.prototype.removeRedundantState = function () {
        var _this = this;
        if (this.filterManager.isAnyFilterPresent()) {
            return;
        }
        var recursivelyRemoveState = function (selectedState, store, node) {
            if (selectedState === void 0) { selectedState = _this.selectedState; }
            if (store === void 0) { store = _this.serverSideRowModel.getRootStore(); }
            var allChildNodesFound = true;
            var noIndeterminateChildren = true;
            selectedState.toggledNodes.forEach(function (state, id) {
                var parentNode = _this.rowModel.getRowNode(id);
                if (!parentNode) {
                    allChildNodesFound = false;
                }
                var nextStore = parentNode === null || parentNode === void 0 ? void 0 : parentNode.childStore;
                if (!nextStore) {
                    if (state.toggledNodes.size > 0) {
                        noIndeterminateChildren = false;
                    }
                    return;
                }
                // if child was cleared, check if this state is still relevant
                if (recursivelyRemoveState(state, nextStore, parentNode)) {
                    // cleans out groups which have no toggled nodes and an equivalent default to its parent
                    if (selectedState.selectAllChildren === state.selectAllChildren) {
                        selectedState.toggledNodes.delete(id);
                    }
                }
                if (state.toggledNodes.size > 0) {
                    noIndeterminateChildren = false;
                }
            });
            if (!store || !store.isLastRowIndexKnown() || store.getRowCount() !== selectedState.toggledNodes.size) {
                // if row count unknown, or doesn't match the size of toggledNodes, ignore.
                return false;
            }
            if (noIndeterminateChildren && allChildNodesFound) {
                selectedState.toggledNodes.clear();
                selectedState.selectAllChildren = !selectedState.selectAllChildren;
                // if node was indeterminate, it's not any more.
                if (node && (node === null || node === void 0 ? void 0 : node.isSelected()) !== selectedState.selectAllChildren) {
                    node.selectThisNode(selectedState.selectAllChildren, undefined, 'api');
                }
                return true;
            }
            return false;
        };
        recursivelyRemoveState();
    };
    GroupSelectsChildrenStrategy.prototype.recursivelySelectNode = function (_a, selectedState, params) {
        var _b = __read$2(_a), nextNode = _b[0], nodes = _b.slice(1);
        if (!nextNode) {
            return;
        }
        // if this is the last node, hard add/remove based on its selectAllChildren state
        var isLastNode = !nodes.length;
        if (isLastNode) {
            // if the node is not selectable, we should never have it in selection state
            var isNodeSelectable = nextNode.selectable;
            var doesNodeConform = selectedState.selectAllChildren === params.newValue;
            if (doesNodeConform || !isNodeSelectable) {
                selectedState.toggledNodes.delete(nextNode.id);
                return;
            }
            var newState = {
                selectAllChildren: params.newValue,
                toggledNodes: new Map(),
            };
            selectedState.toggledNodes.set(nextNode.id, newState);
            return;
        }
        var doesStateAlreadyExist = selectedState.toggledNodes.has(nextNode.id);
        var childState = doesStateAlreadyExist ? (selectedState.toggledNodes.get(nextNode.id)) : {
            selectAllChildren: selectedState.selectAllChildren,
            toggledNodes: new Map(),
        };
        if (!doesStateAlreadyExist) {
            selectedState.toggledNodes.set(nextNode.id, childState);
        }
        this.recursivelySelectNode(nodes, childState, params);
        // cleans out groups which have no toggled nodes and an equivalent default to its parent
        if (selectedState.selectAllChildren === childState.selectAllChildren && childState.toggledNodes.size === 0) {
            selectedState.toggledNodes.delete(nextNode.id);
        }
    };
    GroupSelectsChildrenStrategy.prototype.getSelectedNodes = function () {
        console.warn("AG Grid: `getSelectedNodes` and `getSelectedRows` functions cannot be used with `groupSelectsChildren` and the server-side row model.\n            Use `api.getServerSideSelectionState()` instead.");
        var selectedNodes = [];
        this.rowModel.forEachNode(function (node) {
            if (node.isSelected()) {
                selectedNodes.push(node);
            }
        });
        return selectedNodes;
    };
    GroupSelectsChildrenStrategy.prototype.processNewRow = function (node) {
        // This is used for updating outdated node refs, as this model entirely uses ids it's irrelevant
    };
    GroupSelectsChildrenStrategy.prototype.getSelectedRows = function () {
        return this.getSelectedNodes().map(function (node) { return node.data; });
    };
    GroupSelectsChildrenStrategy.prototype.getSelectionCount = function () {
        return -1;
    };
    GroupSelectsChildrenStrategy.prototype.isEmpty = function () {
        var _a;
        return !this.selectedState.selectAllChildren && !((_a = this.selectedState.toggledNodes) === null || _a === void 0 ? void 0 : _a.size);
    };
    GroupSelectsChildrenStrategy.prototype.selectAllRowNodes = function (params) {
        this.selectedState = { selectAllChildren: true, toggledNodes: new Map() };
    };
    GroupSelectsChildrenStrategy.prototype.deselectAllRowNodes = function (params) {
        this.selectedState = { selectAllChildren: false, toggledNodes: new Map() };
    };
    GroupSelectsChildrenStrategy.prototype.getSelectAllState = function (justFiltered, justCurrentPage) {
        if (this.selectedState.selectAllChildren) {
            if (this.selectedState.toggledNodes.size > 0) {
                return null;
            }
            return true;
        }
        if (this.selectedState.toggledNodes.size > 0) {
            return null;
        }
        return false;
    };
    __decorate$g([
        Autowired('rowModel')
    ], GroupSelectsChildrenStrategy.prototype, "rowModel", void 0);
    __decorate$g([
        Autowired('columnModel')
    ], GroupSelectsChildrenStrategy.prototype, "columnModel", void 0);
    __decorate$g([
        Autowired('filterManager')
    ], GroupSelectsChildrenStrategy.prototype, "filterManager", void 0);
    __decorate$g([
        Autowired('rowModel')
    ], GroupSelectsChildrenStrategy.prototype, "serverSideRowModel", void 0);
    __decorate$g([
        Autowired('selectionService')
    ], GroupSelectsChildrenStrategy.prototype, "selectionService", void 0);
    __decorate$g([
        PostConstruct
    ], GroupSelectsChildrenStrategy.prototype, "init", null);
    return GroupSelectsChildrenStrategy;
}(BeanStub));

var __extends$k = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign$1 = (undefined && undefined.__assign) || function () {
    __assign$1 = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$1.apply(this, arguments);
};
var __decorate$f = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (undefined && undefined.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var ServerSideSelectionService = /** @class */ (function (_super) {
    __extends$k(ServerSideSelectionService, _super);
    function ServerSideSelectionService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ServerSideSelectionService.prototype.init = function () {
        var _this = this;
        var groupSelectsChildren = this.gridOptionsService.get('groupSelectsChildren');
        this.addManagedPropertyListener('groupSelectsChildren', function (propChange) {
            _this.destroyBean(_this.selectionStrategy);
            var StrategyClazz = !propChange.currentValue ? DefaultStrategy : GroupSelectsChildrenStrategy;
            _this.selectionStrategy = _this.createManagedBean(new StrategyClazz());
            _this.shotgunResetNodeSelectionState();
            var event = {
                type: Events.EVENT_SELECTION_CHANGED,
                source: 'api',
            };
            _this.eventService.dispatchEvent(event);
        });
        this.addManagedPropertyListener('rowSelection', function () { return _this.deselectAllRowNodes({ source: 'api' }); });
        var StrategyClazz = !groupSelectsChildren ? DefaultStrategy : GroupSelectsChildrenStrategy;
        this.selectionStrategy = this.createManagedBean(new StrategyClazz());
    };
    ServerSideSelectionService.prototype.getSelectionState = function () {
        return this.selectionStrategy.getSelectedState();
    };
    ServerSideSelectionService.prototype.setSelectionState = function (state, source) {
        if (Array.isArray(state)) {
            return;
        }
        this.selectionStrategy.setSelectedState(state);
        this.shotgunResetNodeSelectionState();
        var event = {
            type: Events.EVENT_SELECTION_CHANGED,
            source: source,
        };
        this.eventService.dispatchEvent(event);
    };
    ServerSideSelectionService.prototype.setNodesSelected = function (params) {
        var nodes = params.nodes, otherParams = __rest(params, ["nodes"]);
        var rowSelection = this.gridOptionsService.get('rowSelection');
        if (nodes.length > 1 && rowSelection !== 'multiple') {
            console.warn("AG Grid: cannot multi select while rowSelection='single'");
            return 0;
        }
        if (nodes.length > 1 && params.rangeSelect) {
            console.warn("AG Grid: cannot use range selection when multi selecting rows");
            return 0;
        }
        var adjustedParams = __assign$1({ nodes: nodes.filter(function (node) { return node.selectable; }) }, otherParams);
        // if no selectable nodes, then return 0
        if (!adjustedParams.nodes.length) {
            return 0;
        }
        var changedNodes = this.selectionStrategy.setNodesSelected(adjustedParams);
        this.shotgunResetNodeSelectionState(adjustedParams.source);
        var event = {
            type: Events.EVENT_SELECTION_CHANGED,
            source: adjustedParams.source,
        };
        this.eventService.dispatchEvent(event);
        return changedNodes;
    };
    /**
     * Deletes the selection state for a set of nodes, for use after deleting nodes via
     * transaction. As this is designed for transactions, all nodes should belong to the same group.
     */
    ServerSideSelectionService.prototype.deleteSelectionStateFromParent = function (storeRoute, removedNodeIds) {
        var stateChanged = this.selectionStrategy.deleteSelectionStateFromParent(storeRoute, removedNodeIds);
        if (!stateChanged) {
            return;
        }
        this.shotgunResetNodeSelectionState();
        var event = {
            type: Events.EVENT_SELECTION_CHANGED,
            source: 'api',
        };
        this.eventService.dispatchEvent(event);
    };
    ServerSideSelectionService.prototype.shotgunResetNodeSelectionState = function (source) {
        var _this = this;
        this.rowModel.forEachNode(function (node) {
            if (node.stub) {
                return;
            }
            var isNodeSelected = _this.selectionStrategy.isNodeSelected(node);
            if (isNodeSelected !== node.isSelected()) {
                node.selectThisNode(isNodeSelected, undefined, source);
            }
        });
    };
    ServerSideSelectionService.prototype.getSelectedNodes = function () {
        return this.selectionStrategy.getSelectedNodes();
    };
    ServerSideSelectionService.prototype.getSelectedRows = function () {
        return this.selectionStrategy.getSelectedRows();
    };
    ServerSideSelectionService.prototype.getSelectionCount = function () {
        return this.selectionStrategy.getSelectionCount();
    };
    ServerSideSelectionService.prototype.syncInRowNode = function (rowNode, oldNode) {
        // update any refs being held in the strategies
        this.selectionStrategy.processNewRow(rowNode);
        var isNodeSelected = this.selectionStrategy.isNodeSelected(rowNode);
        // if the node was selected but node is not selectable, we deselect the node.
        // (could be due to user applying selected state directly, or a change in selectable)
        if (isNodeSelected != false && !rowNode.selectable) {
            this.selectionStrategy.setNodesSelected({
                nodes: [rowNode],
                newValue: false,
                source: 'api',
            });
            // we need to shotgun reset here as if this was hierarchical, some group nodes
            // may be changing from indeterminate to unchecked.
            this.shotgunResetNodeSelectionState();
            var event_1 = {
                type: Events.EVENT_SELECTION_CHANGED,
                source: 'api',
            };
            this.eventService.dispatchEvent(event_1);
            return;
        }
        rowNode.setSelectedInitialValue(isNodeSelected);
    };
    ServerSideSelectionService.prototype.reset = function () {
        this.selectionStrategy.deselectAllRowNodes({ source: 'api' });
    };
    ServerSideSelectionService.prototype.isEmpty = function () {
        return this.selectionStrategy.isEmpty();
    };
    ServerSideSelectionService.prototype.hasNodesToSelect = function (justFiltered, justCurrentPage) {
        return true;
    };
    ServerSideSelectionService.prototype.selectAllRowNodes = function (params) {
        if (params.justCurrentPage || params.justFiltered) {
            console.warn("AG Grid: selecting just filtered only works when gridOptions.rowModelType='clientSide'");
        }
        this.selectionStrategy.selectAllRowNodes(params);
        this.rowModel.forEachNode(function (node) {
            if (node.stub) {
                return;
            }
            node.selectThisNode(true, undefined, params.source);
        });
        var event = {
            type: Events.EVENT_SELECTION_CHANGED,
            source: params.source,
        };
        this.eventService.dispatchEvent(event);
    };
    ServerSideSelectionService.prototype.deselectAllRowNodes = function (params) {
        if (params.justCurrentPage || params.justFiltered) {
            console.warn("AG Grid: selecting just filtered only works when gridOptions.rowModelType='clientSide'");
        }
        this.selectionStrategy.deselectAllRowNodes(params);
        this.rowModel.forEachNode(function (node) {
            if (node.stub) {
                return;
            }
            node.selectThisNode(false, undefined, params.source);
        });
        var event = {
            type: Events.EVENT_SELECTION_CHANGED,
            source: params.source,
        };
        this.eventService.dispatchEvent(event);
    };
    ServerSideSelectionService.prototype.getSelectAllState = function (justFiltered, justCurrentPage) {
        return this.selectionStrategy.getSelectAllState(justFiltered, justCurrentPage);
    };
    // used by CSRM
    ServerSideSelectionService.prototype.updateGroupsFromChildrenSelections = function (source, changedPath) {
        return false;
    };
    // used by CSRM
    ServerSideSelectionService.prototype.getBestCostNodeSelection = function () {
        console.warn('AG Grid: calling gridApi.getBestCostNodeSelection() is only possible when using rowModelType=`clientSide`.');
        return undefined;
    };
    // used by CSRM
    ServerSideSelectionService.prototype.filterFromSelection = function () {
        return;
    };
    __decorate$f([
        Autowired('rowModel')
    ], ServerSideSelectionService.prototype, "rowModel", void 0);
    __decorate$f([
        PostConstruct
    ], ServerSideSelectionService.prototype, "init", null);
    ServerSideSelectionService = __decorate$f([
        Bean('selectionService')
    ], ServerSideSelectionService);
    return ServerSideSelectionService;
}(BeanStub));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$4 = '31.0.2';

var __extends$j = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$e = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ServerSideExpansionService = /** @class */ (function (_super) {
    __extends$j(ServerSideExpansionService, _super);
    function ServerSideExpansionService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.queuedRowIds = new Set();
        return _this;
    }
    ServerSideExpansionService.prototype.postConstruct = function () {
        var _this = this;
        _super.prototype.postConstruct.call(this);
        this.addManagedListener(this.eventService, Events.EVENT_COLUMN_ROW_GROUP_CHANGED, function () {
            _this.queuedRowIds.clear();
        });
    };
    ServerSideExpansionService.prototype.checkOpenByDefault = function (rowNode) {
        if (!rowNode.isExpandable()) {
            return;
        }
        var expandRowNode = function () {
            // we do this in a timeout, so that we don't expand a row node while in the middle
            // of setting up rows, setting up rows is complex enough without another chunk of work
            // getting added to the call stack. this is also helpful as openByDefault may or may
            // not happen (so makes setting up rows more deterministic by expands never happening)
            // and also checkOpenByDefault is shard with both store types, so easier control how it
            // impacts things by keeping it in new VM turn.
            window.setTimeout(function () { return rowNode.setExpanded(true); }, 0);
        };
        if (this.queuedRowIds.has(rowNode.id)) {
            this.queuedRowIds.delete(rowNode.id);
            expandRowNode();
            return;
        }
        var userFunc = this.gridOptionsService.getCallback('isServerSideGroupOpenByDefault');
        if (!userFunc) {
            return;
        }
        var params = {
            data: rowNode.data,
            rowNode: rowNode
        };
        var userFuncRes = userFunc(params);
        if (userFuncRes) {
            expandRowNode();
        }
    };
    ServerSideExpansionService.prototype.expandRows = function (rowIds) {
        var _this = this;
        rowIds.forEach(function (rowId) {
            var rowNode = _this.serverSideRowModel.getRowNode(rowId);
            if (rowNode) {
                rowNode.setExpanded(true);
            }
            else {
                _this.queuedRowIds.add(rowId);
            }
        });
    };
    ServerSideExpansionService.prototype.expandAll = function (value) {
        this.serverSideRowModel.expandAll(value);
    };
    ServerSideExpansionService.prototype.onGroupExpandedOrCollapsed = function () {
        // do nothing
    };
    __decorate$e([
        Autowired('rowModel')
    ], ServerSideExpansionService.prototype, "serverSideRowModel", void 0);
    ServerSideExpansionService = __decorate$e([
        Bean('expansionService')
    ], ServerSideExpansionService);
    return ServerSideExpansionService;
}(ExpansionService));

var ServerSideRowModelModule = {
    version: VERSION$4,
    moduleName: ModuleNames.ServerSideRowModelModule,
    rowModel: 'serverSide',
    beans: [ServerSideRowModel, ExpandListener, SortListener, StoreUtils, BlockUtils, NodeManager, TransactionManager,
        FilterListener, StoreFactory, ListenerUtils, ServerSideSelectionService, ServerSideExpansionService],
    dependantModules: [
        EnterpriseCoreModule
    ]
};

/** @param V type of value in the Set Filter */
var ClientSideValuesExtractor = /** @class */ (function () {
    function ClientSideValuesExtractor(rowModel, filterParams, createKey, caseFormat, columnModel, valueService, treeDataOrGrouping, treeData, getDataPath, groupAllowUnbalanced, addManagedListener) {
        this.rowModel = rowModel;
        this.filterParams = filterParams;
        this.createKey = createKey;
        this.caseFormat = caseFormat;
        this.columnModel = columnModel;
        this.valueService = valueService;
        this.treeDataOrGrouping = treeDataOrGrouping;
        this.treeData = treeData;
        this.getDataPath = getDataPath;
        this.groupAllowUnbalanced = groupAllowUnbalanced;
        this.addManagedListener = addManagedListener;
    }
    ClientSideValuesExtractor.prototype.extractUniqueValuesAsync = function (predicate, existingValues) {
        var _this = this;
        return new AgPromise(function (resolve) {
            if (_this.rowModel.isRowDataLoaded()) {
                resolve(_this.extractUniqueValues(predicate, existingValues));
            }
            else {
                var destroyFunc_1 = _this.addManagedListener(Events.EVENT_ROW_COUNT_READY, function () {
                    destroyFunc_1 === null || destroyFunc_1 === void 0 ? void 0 : destroyFunc_1();
                    resolve(_this.extractUniqueValues(predicate, existingValues));
                });
            }
        });
    };
    ClientSideValuesExtractor.prototype.extractUniqueValues = function (predicate, existingValues) {
        var _this = this;
        var values = new Map();
        var existingFormattedKeys = this.extractExistingFormattedKeys(existingValues);
        var formattedKeys = new Set();
        var treeData = this.treeData && !!this.getDataPath;
        var groupedCols = this.columnModel.getRowGroupColumns();
        var addValue = function (unformattedKey, value) {
            var formattedKey = _this.caseFormat(unformattedKey);
            if (!formattedKeys.has(formattedKey)) {
                formattedKeys.add(formattedKey);
                var keyToAdd = unformattedKey;
                var valueToAdd = _.makeNull(value);
                // when case insensitive, we pick the first value to use. if this is later filtered out,
                // we still want to use the original value and not one with a different case
                var existingUnformattedKey = existingFormattedKeys === null || existingFormattedKeys === void 0 ? void 0 : existingFormattedKeys.get(formattedKey);
                if (existingUnformattedKey != null) {
                    keyToAdd = existingUnformattedKey;
                    valueToAdd = existingValues.get(existingUnformattedKey);
                }
                values.set(keyToAdd, valueToAdd);
            }
        };
        this.rowModel.forEachLeafNode(function (node) {
            // only pull values from rows that have data. this means we skip filler group nodes.
            if (!node.data || !predicate(node)) {
                return;
            }
            if (_this.treeDataOrGrouping) {
                _this.addValueForTreeDataOrGrouping(node, treeData, groupedCols, addValue);
                return;
            }
            var value = _this.getValue(node);
            if (_this.filterParams.convertValuesToStrings) {
                // for backwards compatibility - keeping separate as it will eventually be removed
                _this.addValueForConvertValuesToString(node, value, addValue);
                return;
            }
            if (value != null && Array.isArray(value)) {
                value.forEach(function (x) {
                    addValue(_this.createKey(x, node), x);
                });
                if (value.length === 0) {
                    addValue(null, null);
                }
            }
            else {
                addValue(_this.createKey(value, node), value);
            }
        });
        return values;
    };
    ClientSideValuesExtractor.prototype.addValueForConvertValuesToString = function (node, value, addValue) {
        var key = this.createKey(value, node);
        if (key != null && Array.isArray(key)) {
            key.forEach(function (part) {
                var processedPart = _.toStringOrNull(_.makeNull(part));
                addValue(processedPart, processedPart);
            });
            if (key.length === 0) {
                addValue(null, null);
            }
        }
        else {
            addValue(key, key);
        }
    };
    ClientSideValuesExtractor.prototype.addValueForTreeDataOrGrouping = function (node, treeData, groupedCols, addValue) {
        var _this = this;
        var _a;
        var dataPath;
        if (treeData) {
            if ((_a = node.childrenAfterGroup) === null || _a === void 0 ? void 0 : _a.length) {
                return;
            }
            dataPath = this.getDataPath(node.data);
        }
        else {
            dataPath = groupedCols.map(function (groupCol) { return _this.valueService.getKeyForNode(groupCol, node); });
            dataPath.push(this.getValue(node));
        }
        if (dataPath) {
            dataPath = dataPath.map(function (treeKey) { return _.toStringOrNull(_.makeNull(treeKey)); });
        }
        if (!treeData && this.groupAllowUnbalanced && (dataPath === null || dataPath === void 0 ? void 0 : dataPath.some(function (treeKey) { return treeKey == null; }))) {
            dataPath = dataPath.filter(function (treeKey) { return treeKey != null; });
        }
        addValue(this.createKey(dataPath), dataPath);
    };
    ClientSideValuesExtractor.prototype.getValue = function (node) {
        return this.filterParams.getValue(node);
    };
    ClientSideValuesExtractor.prototype.extractExistingFormattedKeys = function (existingValues) {
        var _this = this;
        if (!existingValues) {
            return null;
        }
        var existingFormattedKeys = new Map();
        existingValues.forEach(function (_value, key) {
            existingFormattedKeys.set(_this.caseFormat(key), key);
        });
        return existingFormattedKeys;
    };
    return ClientSideValuesExtractor;
}());

var SetFilterDisplayValue = /** @class */ (function () {
    function SetFilterDisplayValue() {
    }
    SetFilterDisplayValue.SELECT_ALL = '__AG_SELECT_ALL__';
    SetFilterDisplayValue.ADD_SELECTION_TO_FILTER = '__AG_ADD_SELECTION_TO_FILTER__';
    return SetFilterDisplayValue;
}());

var __values$2 = (undefined && undefined.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var FlatSetDisplayValueModel = /** @class */ (function () {
    function FlatSetDisplayValueModel(valueFormatterService, valueFormatter, formatter, column) {
        this.valueFormatterService = valueFormatterService;
        this.valueFormatter = valueFormatter;
        this.formatter = formatter;
        this.column = column;
        /** All keys that are currently displayed, after the mini-filter has been applied. */
        this.displayedKeys = [];
    }
    FlatSetDisplayValueModel.prototype.updateDisplayedValuesToAllAvailable = function (_getValue, _allKeys, availableKeys) {
        this.displayedKeys = Array.from(availableKeys);
    };
    FlatSetDisplayValueModel.prototype.updateDisplayedValuesToMatchMiniFilter = function (getValue, _allKeys, availableKeys, matchesFilter, nullMatchesFilter) {
        var e_1, _a;
        this.displayedKeys = [];
        try {
            for (var availableKeys_1 = __values$2(availableKeys), availableKeys_1_1 = availableKeys_1.next(); !availableKeys_1_1.done; availableKeys_1_1 = availableKeys_1.next()) {
                var key = availableKeys_1_1.value;
                if (key == null) {
                    if (nullMatchesFilter) {
                        this.displayedKeys.push(key);
                    }
                }
                else {
                    var value = getValue(key);
                    var valueFormatterValue = this.valueFormatterService.formatValue(this.column, null, value, this.valueFormatter, false);
                    var textFormatterValue = this.formatter(valueFormatterValue);
                    if (matchesFilter(textFormatterValue)) {
                        this.displayedKeys.push(key);
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (availableKeys_1_1 && !availableKeys_1_1.done && (_a = availableKeys_1.return)) _a.call(availableKeys_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    FlatSetDisplayValueModel.prototype.getDisplayedValueCount = function () {
        return this.displayedKeys.length;
    };
    FlatSetDisplayValueModel.prototype.getDisplayedItem = function (index) {
        return this.displayedKeys[index];
    };
    FlatSetDisplayValueModel.prototype.getSelectAllItem = function () {
        return SetFilterDisplayValue.SELECT_ALL;
    };
    FlatSetDisplayValueModel.prototype.getAddSelectionToFilterItem = function () {
        return SetFilterDisplayValue.ADD_SELECTION_TO_FILTER;
    };
    FlatSetDisplayValueModel.prototype.getDisplayedKeys = function () {
        return this.displayedKeys;
    };
    FlatSetDisplayValueModel.prototype.forEachDisplayedKey = function (func) {
        this.displayedKeys.forEach(func);
    };
    FlatSetDisplayValueModel.prototype.someDisplayedKey = function (func) {
        return this.displayedKeys.some(func);
    };
    FlatSetDisplayValueModel.prototype.hasGroups = function () {
        return false;
    };
    FlatSetDisplayValueModel.prototype.refresh = function () {
        // not used
    };
    return FlatSetDisplayValueModel;
}());

var __read$1 = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray$1 = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values$1 = (undefined && undefined.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var TreeSetDisplayValueModel = /** @class */ (function () {
    function TreeSetDisplayValueModel(formatter, treeListPathGetter, treeListFormatter, treeDataOrGrouping) {
        this.formatter = formatter;
        this.treeListPathGetter = treeListPathGetter;
        this.treeListFormatter = treeListFormatter;
        this.treeDataOrGrouping = treeDataOrGrouping;
        /** all displayed items in a tree structure */
        this.allDisplayedItemsTree = [];
        /** all displayed items flattened and filtered */
        this.activeDisplayedItemsFlat = [];
        this.selectAllItem = {
            depth: 0,
            filterPasses: true,
            available: true,
            treeKey: SetFilterDisplayValue.SELECT_ALL,
            children: this.allDisplayedItemsTree,
            expanded: true,
            key: SetFilterDisplayValue.SELECT_ALL,
            parentTreeKeys: []
        };
        this.addSelectionToFilterItem = {
            depth: 0,
            filterPasses: true,
            available: true,
            treeKey: SetFilterDisplayValue.ADD_SELECTION_TO_FILTER,
            expanded: true,
            key: SetFilterDisplayValue.ADD_SELECTION_TO_FILTER,
            parentTreeKeys: []
        };
    }
    TreeSetDisplayValueModel.prototype.updateDisplayedValuesToAllAvailable = function (getValue, allKeys, availableKeys, source) {
        if (source === 'reload') {
            this.generateItemTree(getValue, allKeys, availableKeys);
        }
        else if (source === 'otherFilter') {
            this.updateAvailable(availableKeys);
            this.updateExpandAll();
        }
        else if (source === 'miniFilter') {
            this.resetFilter();
            this.updateExpandAll();
        }
        this.flattenItems();
    };
    TreeSetDisplayValueModel.prototype.updateDisplayedValuesToMatchMiniFilter = function (getValue, allKeys, availableKeys, matchesFilter, nullMatchesFilter, source) {
        if (source === 'reload') {
            this.generateItemTree(getValue, allKeys, availableKeys);
        }
        else if (source === 'otherFilter') {
            this.updateAvailable(availableKeys);
        }
        this.updateFilter(matchesFilter, nullMatchesFilter);
        this.updateExpandAll();
        this.flattenItems();
    };
    TreeSetDisplayValueModel.prototype.generateItemTree = function (getValue, allKeys, availableKeys) {
        var e_1, _a;
        var _b;
        this.allDisplayedItemsTree = [];
        this.groupsExist = false;
        var treeListPathGetter = this.getTreeListPathGetter(getValue, availableKeys);
        var _loop_1 = function (key) {
            var value = getValue(key);
            var dataPath = (_b = treeListPathGetter(value)) !== null && _b !== void 0 ? _b : [null];
            if (dataPath.length > 1) {
                this_1.groupsExist = true;
            }
            var available = availableKeys.has(key);
            var children = this_1.allDisplayedItemsTree;
            var item;
            var parentTreeKeys = [];
            dataPath.forEach(function (treeKey, depth) {
                if (!children) {
                    children = [];
                    item.children = children;
                }
                item = children.find(function (child) { var _a; return ((_a = child.treeKey) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === (treeKey === null || treeKey === void 0 ? void 0 : treeKey.toUpperCase()); });
                if (!item) {
                    item = { treeKey: treeKey, depth: depth, filterPasses: true, expanded: false, available: available, parentTreeKeys: parentTreeKeys };
                    if (depth === dataPath.length - 1) {
                        item.key = key;
                    }
                    children.push(item);
                }
                children = item.children;
                parentTreeKeys = __spreadArray$1(__spreadArray$1([], __read$1(parentTreeKeys), false), [treeKey], false);
            });
        };
        var this_1 = this;
        try {
            for (var allKeys_1 = __values$1(allKeys), allKeys_1_1 = allKeys_1.next(); !allKeys_1_1.done; allKeys_1_1 = allKeys_1.next()) {
                var key = allKeys_1_1.value;
                _loop_1(key);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (allKeys_1_1 && !allKeys_1_1.done && (_a = allKeys_1.return)) _a.call(allKeys_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        // update the parent availability based on the children
        this.updateAvailable(availableKeys);
        this.selectAllItem.children = this.allDisplayedItemsTree;
        this.selectAllItem.expanded = false;
    };
    TreeSetDisplayValueModel.prototype.getTreeListPathGetter = function (getValue, availableKeys) {
        var e_2, _a;
        if (this.treeListPathGetter) {
            return this.treeListPathGetter;
        }
        if (this.treeDataOrGrouping) {
            return function (value) { return value; };
        }
        // infer from data
        var isDate = false;
        try {
            for (var availableKeys_1 = __values$1(availableKeys), availableKeys_1_1 = availableKeys_1.next(); !availableKeys_1_1.done; availableKeys_1_1 = availableKeys_1.next()) {
                var availableKey = availableKeys_1_1.value;
                // find the first non-null value
                var value = getValue(availableKey);
                if (value instanceof Date) {
                    isDate = true;
                    break;
                }
                else if (value != null) {
                    break;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (availableKeys_1_1 && !availableKeys_1_1.done && (_a = availableKeys_1.return)) _a.call(availableKeys_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        if (isDate) {
            return TreeSetDisplayValueModel.DATE_TREE_LIST_PATH_GETTER;
        }
        _.warnOnce('property treeList=true for Set Filter params, but you did not provide a treeListPathGetter or values of type Date.');
        return function (value) { return [String(value)]; };
    };
    TreeSetDisplayValueModel.prototype.flattenItems = function () {
        var _this = this;
        this.activeDisplayedItemsFlat = [];
        var recursivelyFlattenDisplayedItems = function (items) {
            items.forEach(function (item) {
                if (!item.filterPasses || !item.available) {
                    return;
                }
                _this.activeDisplayedItemsFlat.push(item);
                if (item.children && item.expanded) {
                    recursivelyFlattenDisplayedItems(item.children);
                }
            });
        };
        recursivelyFlattenDisplayedItems(this.allDisplayedItemsTree);
    };
    TreeSetDisplayValueModel.prototype.resetFilter = function () {
        var recursiveFilterReset = function (item) {
            if (item.children) {
                item.children.forEach(function (child) {
                    recursiveFilterReset(child);
                });
            }
            item.filterPasses = true;
        };
        this.allDisplayedItemsTree.forEach(function (item) { return recursiveFilterReset(item); });
    };
    TreeSetDisplayValueModel.prototype.updateFilter = function (matchesFilter, nullMatchesFilter) {
        var _this = this;
        var passesFilter = function (item) {
            if (!item.available) {
                return false;
            }
            if (item.treeKey == null) {
                return nullMatchesFilter;
            }
            return matchesFilter(_this.formatter(_this.treeListFormatter ? _this.treeListFormatter(item.treeKey, item.depth, item.parentTreeKeys) : item.treeKey));
        };
        this.allDisplayedItemsTree.forEach(function (item) { return _this.recursiveItemCheck(item, false, passesFilter, 'filterPasses'); });
    };
    TreeSetDisplayValueModel.prototype.getDisplayedValueCount = function () {
        return this.activeDisplayedItemsFlat.length;
    };
    TreeSetDisplayValueModel.prototype.getDisplayedItem = function (index) {
        return this.activeDisplayedItemsFlat[index];
    };
    TreeSetDisplayValueModel.prototype.getSelectAllItem = function () {
        return this.selectAllItem;
    };
    TreeSetDisplayValueModel.prototype.getAddSelectionToFilterItem = function () {
        return this.addSelectionToFilterItem;
    };
    TreeSetDisplayValueModel.prototype.getDisplayedKeys = function () {
        var displayedKeys = [];
        this.forEachDisplayedKey(function (key) { return displayedKeys.push(key); });
        return displayedKeys;
    };
    TreeSetDisplayValueModel.prototype.forEachDisplayedKey = function (func) {
        var recursiveForEachItem = function (item, topParentExpanded) {
            if (item.children) {
                if (!item.expanded || !topParentExpanded) {
                    // if the parent is not expanded, we need to iterate the entire tree
                    item.children.forEach(function (child) {
                        if (child.filterPasses) {
                            recursiveForEachItem(child, false);
                        }
                    });
                }
            }
            else {
                func(item.key);
            }
        };
        this.activeDisplayedItemsFlat.forEach(function (item) { return recursiveForEachItem(item, true); });
    };
    TreeSetDisplayValueModel.prototype.someDisplayedKey = function (func) {
        var recursiveSomeItem = function (item, topParentExpanded) {
            if (item.children) {
                if (!item.expanded || !topParentExpanded) {
                    // if the parent is not expanded, we need to iterate the entire tree
                    return item.children.some(function (child) {
                        if (child.filterPasses) {
                            return recursiveSomeItem(child, false);
                        }
                        return false;
                    });
                }
            }
            else {
                return func(item.key);
            }
            return false;
        };
        return this.activeDisplayedItemsFlat.some(function (item) { return recursiveSomeItem(item, true); });
    };
    TreeSetDisplayValueModel.prototype.hasGroups = function () {
        return this.groupsExist;
    };
    TreeSetDisplayValueModel.prototype.refresh = function () {
        this.updateExpandAll();
        this.flattenItems();
    };
    TreeSetDisplayValueModel.prototype.updateExpandAll = function () {
        var recursiveExpansionCheck = function (items, someTrue, someFalse) {
            var e_3, _a;
            try {
                for (var items_1 = __values$1(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                    var item_1 = items_1_1.value;
                    if (!item_1.filterPasses || !item_1.available || !item_1.children) {
                        continue;
                    }
                    // indeterminate state only exists for expand all, so don't need to check for the current item
                    someTrue = someTrue || !!item_1.expanded;
                    someFalse = someFalse || !item_1.expanded;
                    if (someTrue && someFalse) {
                        // already indeterminate. No need to check the children
                        return undefined;
                    }
                    var childExpanded = recursiveExpansionCheck(item_1.children, someTrue, someFalse);
                    if (childExpanded === undefined) {
                        return undefined;
                    }
                    else if (childExpanded) {
                        someTrue = true;
                    }
                    else {
                        someFalse = true;
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
                }
                finally { if (e_3) throw e_3.error; }
            }
            return someTrue && someFalse ? undefined : someTrue;
        };
        var item = this.getSelectAllItem();
        item.expanded = recursiveExpansionCheck(item.children, false, false);
    };
    TreeSetDisplayValueModel.prototype.recursiveItemCheck = function (item, parentPasses, checkFunction, itemProp) {
        var _this = this;
        var atLeastOneChildPassed = false;
        if (item.children) {
            item.children.forEach(function (child) {
                var childPasses = _this.recursiveItemCheck(child, parentPasses || checkFunction(item), checkFunction, itemProp);
                atLeastOneChildPassed = atLeastOneChildPassed || childPasses;
            });
        }
        var itemPasses = parentPasses || atLeastOneChildPassed || checkFunction(item);
        item[itemProp] = itemPasses;
        return itemPasses;
    };
    TreeSetDisplayValueModel.prototype.updateAvailable = function (availableKeys) {
        var _this = this;
        var isAvailable = function (item) { return availableKeys.has(item.key); };
        this.allDisplayedItemsTree.forEach(function (item) { return _this.recursiveItemCheck(item, false, isAvailable, 'available'); });
    };
    TreeSetDisplayValueModel.DATE_TREE_LIST_PATH_GETTER = function (date) { return date ? [String(date.getFullYear()), String(date.getMonth() + 1), String(date.getDate())] : null; };
    return TreeSetDisplayValueModel;
}());

var SetValueModelFilteringKeys = /** @class */ (function () {
    function SetValueModelFilteringKeys(_a) {
        var caseFormat = _a.caseFormat;
        // To make the filtering fast, we store the keys in a Set rather than using the default array.
        this.filteringKeys = null;
        // This attribute contains keys that are actually used for filtering.
        // These keys take into account case sensitivity:
        // - When filtering is case-insensitive, all filtering keys are converted to upper case and stored here.
        // - When filtering is case-sensitive, this is the same as filteringKeys.
        this.filteringKeysCaseFormatted = null;
        this.hasNoAppliedFilteringKeys = false;
        this.caseFormat = caseFormat;
    }
    SetValueModelFilteringKeys.prototype.allFilteringKeys = function () {
        return this.filteringKeys;
    };
    SetValueModelFilteringKeys.prototype.allFilteringKeysCaseFormatted = function () {
        return this.filteringKeysCaseFormatted;
    };
    SetValueModelFilteringKeys.prototype.noAppliedFilteringKeys = function () {
        return this.hasNoAppliedFilteringKeys;
    };
    SetValueModelFilteringKeys.prototype.setFilteringKeys = function (filteringKeys) {
        var _this = this;
        this.filteringKeys = new Set(filteringKeys);
        this.hasNoAppliedFilteringKeys = !this.filteringKeys || this.filteringKeys.size === 0;
        this.filteringKeysCaseFormatted = new Set();
        this.filteringKeys.forEach(function (key) {
            return _this.filteringKeysCaseFormatted.add(_this.caseFormat(key));
        });
    };
    SetValueModelFilteringKeys.prototype.addFilteringKey = function (key) {
        if (this.filteringKeys == null) {
            this.filteringKeys = new Set();
            this.filteringKeysCaseFormatted = new Set();
        }
        this.filteringKeys.add(key);
        this.filteringKeysCaseFormatted.add(this.caseFormat(key));
        if (this.hasNoAppliedFilteringKeys) {
            this.hasNoAppliedFilteringKeys = false;
        }
    };
    SetValueModelFilteringKeys.prototype.hasCaseFormattedFilteringKey = function (key) {
        return this.filteringKeysCaseFormatted.has(this.caseFormat(key));
    };
    SetValueModelFilteringKeys.prototype.hasFilteringKey = function (key) {
        return this.filteringKeys.has(key);
    };
    SetValueModelFilteringKeys.prototype.reset = function () {
        this.filteringKeys = null;
        this.filteringKeysCaseFormatted = null;
        this.hasNoAppliedFilteringKeys = false;
    };
    return SetValueModelFilteringKeys;
}());

var __read = (undefined && undefined.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (undefined && undefined.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var SetFilterModelValuesType;
(function (SetFilterModelValuesType) {
    SetFilterModelValuesType[SetFilterModelValuesType["PROVIDED_LIST"] = 0] = "PROVIDED_LIST";
    SetFilterModelValuesType[SetFilterModelValuesType["PROVIDED_CALLBACK"] = 1] = "PROVIDED_CALLBACK";
    SetFilterModelValuesType[SetFilterModelValuesType["TAKEN_FROM_GRID_VALUES"] = 2] = "TAKEN_FROM_GRID_VALUES";
})(SetFilterModelValuesType || (SetFilterModelValuesType = {}));
/** @param V type of value in the Set Filter */
var SetValueModel = /** @class */ (function () {
    function SetValueModel(params) {
        var _this = this;
        var _a;
        this.localEventService = new EventService();
        this.miniFilterText = null;
        /** When true, in excelMode = 'windows', it adds previously selected filter items to newly checked filter selection */
        this.addCurrentSelectionToFilter = false;
        /** Values provided to the filter for use. */
        this.providedValues = null;
        /** All possible values for the filter, sorted if required. */
        this.allValues = new Map();
        /** Remaining keys when filters from other columns have been applied. */
        this.availableKeys = new Set();
        /** Keys that have been selected for this filter. */
        this.selectedKeys = new Set();
        this.initialised = false;
        var usingComplexObjects = params.usingComplexObjects, columnModel = params.columnModel, valueService = params.valueService, treeDataTreeList = params.treeDataTreeList, groupingTreeList = params.groupingTreeList, filterParams = params.filterParams, gridOptionsService = params.gridOptionsService, valueFormatterService = params.valueFormatterService, valueFormatter = params.valueFormatter, addManagedListener = params.addManagedListener;
        var column = filterParams.column, colDef = filterParams.colDef, textFormatter = filterParams.textFormatter, doesRowPassOtherFilter = filterParams.doesRowPassOtherFilter, suppressSorting = filterParams.suppressSorting, comparator = filterParams.comparator, rowModel = filterParams.rowModel, values = filterParams.values, caseSensitive = filterParams.caseSensitive, convertValuesToStrings = filterParams.convertValuesToStrings, treeList = filterParams.treeList, treeListPathGetter = filterParams.treeListPathGetter, treeListFormatter = filterParams.treeListFormatter;
        this.filterParams = filterParams;
        this.gridOptionsService = gridOptionsService;
        this.setIsLoading = params.setIsLoading;
        this.translate = params.translate;
        this.caseFormat = params.caseFormat;
        this.createKey = params.createKey;
        this.usingComplexObjects = !!params.usingComplexObjects;
        this.formatter = textFormatter || TextFilter.DEFAULT_FORMATTER;
        this.doesRowPassOtherFilters = doesRowPassOtherFilter;
        this.suppressSorting = suppressSorting || false;
        this.convertValuesToStrings = !!convertValuesToStrings;
        this.filteringKeys = new SetValueModelFilteringKeys({ caseFormat: this.caseFormat });
        var keyComparator = comparator !== null && comparator !== void 0 ? comparator : colDef.comparator;
        var treeDataOrGrouping = !!treeDataTreeList || !!groupingTreeList;
        // If using complex objects and a comparator is provided, sort by values, otherwise need to sort by the string keys.
        // Also if tree data, grouping, or date with tree list, then need to do value sort
        this.compareByValue = !!((usingComplexObjects && keyComparator) || treeDataOrGrouping || (treeList && !treeListPathGetter));
        if (treeDataOrGrouping && !keyComparator) {
            this.entryComparator = this.createTreeDataOrGroupingComparator();
        }
        else if (treeList && !treeListPathGetter && !keyComparator) {
            this.entryComparator = function (_a, _b) {
                var _c = __read(_a, 2); _c[0]; var aValue = _c[1];
                var _d = __read(_b, 2); _d[0]; var bValue = _d[1];
                return _.defaultComparator(aValue, bValue);
            };
        }
        else {
            this.entryComparator = function (_a, _b) {
                var _c = __read(_a, 2); _c[0]; var aValue = _c[1];
                var _d = __read(_b, 2); _d[0]; var bValue = _d[1];
                return keyComparator(aValue, bValue);
            };
        }
        this.keyComparator = (_a = keyComparator) !== null && _a !== void 0 ? _a : _.defaultComparator;
        this.caseSensitive = !!caseSensitive;
        var getDataPath = gridOptionsService.get('getDataPath');
        var groupAllowUnbalanced = gridOptionsService.get('groupAllowUnbalanced');
        if (rowModel.getType() === 'clientSide') {
            this.clientSideValuesExtractor = new ClientSideValuesExtractor(rowModel, this.filterParams, this.createKey, this.caseFormat, columnModel, valueService, treeDataOrGrouping, !!treeDataTreeList, getDataPath, groupAllowUnbalanced, addManagedListener);
        }
        if (values == null) {
            this.valuesType = SetFilterModelValuesType.TAKEN_FROM_GRID_VALUES;
        }
        else {
            this.valuesType = Array.isArray(values) ?
                SetFilterModelValuesType.PROVIDED_LIST :
                SetFilterModelValuesType.PROVIDED_CALLBACK;
            this.providedValues = values;
        }
        this.displayValueModel = treeList ? new TreeSetDisplayValueModel(this.formatter, treeListPathGetter, treeListFormatter, treeDataTreeList || groupingTreeList) : new FlatSetDisplayValueModel(valueFormatterService, valueFormatter, this.formatter, column);
        this.updateAllValues().then(function (updatedKeys) { return _this.resetSelectionState(updatedKeys || []); });
    }
    SetValueModel.prototype.addEventListener = function (eventType, listener, async) {
        this.localEventService.addEventListener(eventType, listener, async);
    };
    SetValueModel.prototype.removeEventListener = function (eventType, listener, async) {
        this.localEventService.removeEventListener(eventType, listener, async);
    };
    SetValueModel.prototype.updateOnParamsChange = function (filterParams) {
        var _this = this;
        return new AgPromise(function (resolve) {
            var values = filterParams.values, textFormatter = filterParams.textFormatter, suppressSorting = filterParams.suppressSorting;
            var currentProvidedValues = _this.providedValues;
            var currentSuppressSorting = _this.suppressSorting;
            _this.filterParams = filterParams;
            _this.formatter = textFormatter || TextFilter.DEFAULT_FORMATTER;
            _this.suppressSorting = suppressSorting || false;
            _this.providedValues = values !== null && values !== void 0 ? values : null;
            // Rebuild values when values or their sort order changes
            if (_this.providedValues !== currentProvidedValues || _this.suppressSorting !== currentSuppressSorting) {
                if (!values || values.length === 0) {
                    _this.valuesType = SetFilterModelValuesType.TAKEN_FROM_GRID_VALUES;
                    _this.providedValues = null;
                }
                else {
                    var isArrayOfCallback = Array.isArray(values) && values.length > 0 && typeof values[0] === 'function';
                    _this.valuesType = isArrayOfCallback ?
                        SetFilterModelValuesType.PROVIDED_CALLBACK :
                        SetFilterModelValuesType.PROVIDED_LIST;
                }
                var currentModel_1 = _this.getModel();
                _this.updateAllValues().then(function (updatedKeys) {
                    _this.setModel(currentModel_1).then(function () { return resolve(); });
                });
            }
            else {
                resolve();
            }
        });
    };
    /**
     * Re-fetches the values used in the filter from the value source.
     * If keepSelection is false, the filter selection will be reset to everything selected,
     * otherwise the current selection will be preserved.
     */
    SetValueModel.prototype.refreshValues = function () {
        var _this = this;
        return new AgPromise(function (resolve) {
            // don't get the model until values are resolved, as there could be queued setModel calls
            _this.allValuesPromise.then(function () {
                var currentModel = _this.getModel();
                _this.updateAllValues();
                // ensure model is updated for new values
                _this.setModel(currentModel).then(function () { return resolve(); });
            });
        });
    };
    /**
     * Overrides the current values being used for the set filter.
     * If keepSelection is false, the filter selection will be reset to everything selected,
     * otherwise the current selection will be preserved.
     */
    SetValueModel.prototype.overrideValues = function (valuesToUse) {
        var _this = this;
        return new AgPromise(function (resolve) {
            // wait for any existing values to be populated before overriding
            _this.allValuesPromise.then(function () {
                _this.valuesType = SetFilterModelValuesType.PROVIDED_LIST;
                _this.providedValues = valuesToUse;
                _this.refreshValues().then(function () { return resolve(); });
            });
        });
    };
    /** @return has anything been updated */
    SetValueModel.prototype.refreshAfterAnyFilterChanged = function () {
        var _this = this;
        if (this.showAvailableOnly()) {
            return this.allValuesPromise.then(function (keys) {
                _this.updateAvailableKeys(keys !== null && keys !== void 0 ? keys : [], 'otherFilter');
                return true;
            });
        }
        return AgPromise.resolve(false);
    };
    SetValueModel.prototype.isInitialised = function () {
        return this.initialised;
    };
    SetValueModel.prototype.updateAllValues = function () {
        var _this = this;
        this.allValuesPromise = new AgPromise(function (resolve) {
            switch (_this.valuesType) {
                case SetFilterModelValuesType.TAKEN_FROM_GRID_VALUES:
                    _this.getValuesFromRowsAsync(false).then(function (values) { return resolve(_this.processAllValues(values)); });
                    break;
                case SetFilterModelValuesType.PROVIDED_LIST: {
                    resolve(_this.processAllValues(_this.uniqueValues(_this.validateProvidedValues(_this.providedValues))));
                    break;
                }
                case SetFilterModelValuesType.PROVIDED_CALLBACK: {
                    _this.setIsLoading(true);
                    var callback_1 = _this.providedValues;
                    var _a = _this.filterParams, column = _a.column, colDef = _a.colDef;
                    var params_1 = _this.gridOptionsService.addGridCommonParams({
                        success: function (values) {
                            _this.setIsLoading(false);
                            resolve(_this.processAllValues(_this.uniqueValues(_this.validateProvidedValues(values))));
                        },
                        colDef: colDef,
                        column: column
                    });
                    window.setTimeout(function () { return callback_1(params_1); }, 0);
                    break;
                }
                default:
                    throw new Error('Unrecognised valuesType');
            }
        });
        this.allValuesPromise.then(function (values) { return _this.updateAvailableKeys(values || [], 'reload'); }).then(function () { return _this.initialised = true; });
        return this.allValuesPromise;
    };
    SetValueModel.prototype.processAllValues = function (values) {
        var sortedKeys = this.sortKeys(values);
        this.allValues = values !== null && values !== void 0 ? values : new Map();
        return sortedKeys;
    };
    SetValueModel.prototype.validateProvidedValues = function (values) {
        if (this.usingComplexObjects && (values === null || values === void 0 ? void 0 : values.length)) {
            var firstValue = values[0];
            if (firstValue && typeof firstValue !== 'object' && typeof firstValue !== 'function') {
                var firstKey = this.createKey(firstValue);
                if (firstKey == null) {
                    _.warnOnce('Set Filter Key Creator is returning null for provided values and provided values are primitives. Please provide complex objects or set convertValuesToStrings=true in the filterParams. See https://www.ag-grid.com/javascript-data-grid/filter-set-filter-list/#filter-value-types');
                }
                else {
                    _.warnOnce('Set Filter has a Key Creator, but provided values are primitives. Did you mean to provide complex objects or enable convertValuesToStrings?');
                }
            }
        }
        return values;
    };
    SetValueModel.prototype.setValuesType = function (value) {
        this.valuesType = value;
    };
    SetValueModel.prototype.getValuesType = function () {
        return this.valuesType;
    };
    SetValueModel.prototype.isKeyAvailable = function (key) {
        return this.availableKeys.has(key);
    };
    SetValueModel.prototype.showAvailableOnly = function () {
        return this.valuesType === SetFilterModelValuesType.TAKEN_FROM_GRID_VALUES;
    };
    SetValueModel.prototype.updateAvailableKeys = function (allKeys, source) {
        var availableKeys = this.showAvailableOnly() ? this.sortKeys(this.getValuesFromRows(true)) : allKeys;
        this.availableKeys = new Set(availableKeys);
        this.localEventService.dispatchEvent({ type: SetValueModel.EVENT_AVAILABLE_VALUES_CHANGED });
        this.updateDisplayedValues(source, allKeys);
    };
    SetValueModel.prototype.sortKeys = function (nullableValues) {
        var values = nullableValues !== null && nullableValues !== void 0 ? nullableValues : new Map();
        if (this.suppressSorting) {
            return Array.from(values.keys());
        }
        var sortedKeys;
        if (this.compareByValue) {
            sortedKeys = Array.from(values.entries()).sort(this.entryComparator).map(function (_a) {
                var _b = __read(_a, 1), key = _b[0];
                return key;
            });
        }
        else {
            sortedKeys = Array.from(values.keys()).sort(this.keyComparator);
        }
        if (this.filterParams.excelMode && values.has(null)) {
            // ensure the blank value always appears last
            sortedKeys = sortedKeys.filter(function (v) { return v != null; });
            sortedKeys.push(null);
        }
        return sortedKeys;
    };
    SetValueModel.prototype.getParamsForValuesFromRows = function (removeUnavailableValues) {
        var _this = this;
        if (removeUnavailableValues === void 0) { removeUnavailableValues = false; }
        if (!this.clientSideValuesExtractor) {
            _.doOnce(function () {
                console.error('AG Grid: Set Filter cannot initialise because you are using a row model that does not contain all rows in the browser. Either use a different filter type, or configure Set Filter such that you provide it with values');
            }, 'setFilterValueNotCSRM');
            return null;
        }
        var predicate = function (node) { return (!removeUnavailableValues || _this.doesRowPassOtherFilters(node)); };
        var existingValues = removeUnavailableValues && !this.caseSensitive ? this.allValues : undefined;
        return { predicate: predicate, existingValues: existingValues };
    };
    SetValueModel.prototype.getValuesFromRows = function (removeUnavailableValues) {
        if (removeUnavailableValues === void 0) { removeUnavailableValues = false; }
        var params = this.getParamsForValuesFromRows(removeUnavailableValues);
        if (!params) {
            return null;
        }
        return this.clientSideValuesExtractor.extractUniqueValues(params.predicate, params.existingValues);
    };
    SetValueModel.prototype.getValuesFromRowsAsync = function (removeUnavailableValues) {
        if (removeUnavailableValues === void 0) { removeUnavailableValues = false; }
        var params = this.getParamsForValuesFromRows(removeUnavailableValues);
        if (!params) {
            return AgPromise.resolve(null);
        }
        return this.clientSideValuesExtractor.extractUniqueValuesAsync(params.predicate, params.existingValues);
    };
    /** Sets mini filter value. Returns true if it changed from last value, otherwise false. */
    SetValueModel.prototype.setMiniFilter = function (value) {
        value = _.makeNull(value);
        if (this.miniFilterText === value) {
            //do nothing if filter has not changed
            return false;
        }
        if (value === null) {
            // Reset 'Add current selection to filter' checkbox when clearing mini filter
            this.setAddCurrentSelectionToFilter(false);
        }
        this.miniFilterText = value;
        this.updateDisplayedValues('miniFilter');
        return true;
    };
    SetValueModel.prototype.getMiniFilter = function () {
        return this.miniFilterText;
    };
    SetValueModel.prototype.updateDisplayedValues = function (source, allKeys) {
        var _this = this;
        if (source === 'expansion') {
            this.displayValueModel.refresh();
            return;
        }
        // if no filter, just display all available values
        if (this.miniFilterText == null) {
            this.displayValueModel.updateDisplayedValuesToAllAvailable(function (key) { return _this.getValue(key); }, allKeys, this.availableKeys, source);
            return;
        }
        // if filter present, we filter down the list
        // to allow for case insensitive searches, upper-case both filter text and value
        var formattedFilterText = this.caseFormat(this.formatter(this.miniFilterText) || '');
        var matchesFilter = function (valueToCheck) {
            return valueToCheck != null && _this.caseFormat(valueToCheck).indexOf(formattedFilterText) >= 0;
        };
        var nullMatchesFilter = !!this.filterParams.excelMode && matchesFilter(this.translate('blanks'));
        this.displayValueModel.updateDisplayedValuesToMatchMiniFilter(function (key) { return _this.getValue(key); }, allKeys, this.availableKeys, matchesFilter, nullMatchesFilter, source);
    };
    SetValueModel.prototype.getDisplayedValueCount = function () {
        return this.displayValueModel.getDisplayedValueCount();
    };
    SetValueModel.prototype.getDisplayedItem = function (index) {
        return this.displayValueModel.getDisplayedItem(index);
    };
    SetValueModel.prototype.getSelectAllItem = function () {
        return this.displayValueModel.getSelectAllItem();
    };
    SetValueModel.prototype.getAddSelectionToFilterItem = function () {
        return this.displayValueModel.getAddSelectionToFilterItem();
    };
    SetValueModel.prototype.hasSelections = function () {
        return this.filterParams.defaultToNothingSelected ?
            this.selectedKeys.size > 0 :
            this.allValues.size !== this.selectedKeys.size;
    };
    SetValueModel.prototype.getKeys = function () {
        return Array.from(this.allValues.keys());
    };
    SetValueModel.prototype.getValues = function () {
        return Array.from(this.allValues.values());
    };
    SetValueModel.prototype.getValue = function (key) {
        return this.allValues.get(key);
    };
    SetValueModel.prototype.setAddCurrentSelectionToFilter = function (value) {
        this.addCurrentSelectionToFilter = value;
    };
    SetValueModel.prototype.isInWindowsExcelMode = function () {
        return this.filterParams.excelMode === 'windows';
    };
    SetValueModel.prototype.isAddCurrentSelectionToFilterChecked = function () {
        return this.isInWindowsExcelMode() && this.addCurrentSelectionToFilter;
    };
    SetValueModel.prototype.showAddCurrentSelectionToFilter = function () {
        // We only show the 'Add current selection to filter' option
        // when excel mode is enabled with 'windows' mode
        // and when the users types a value in the mini filter.
        return (this.isInWindowsExcelMode()
            && _.exists(this.miniFilterText)
            && this.miniFilterText.length > 0);
    };
    SetValueModel.prototype.selectAllMatchingMiniFilter = function (clearExistingSelection) {
        var _this = this;
        if (clearExistingSelection === void 0) { clearExistingSelection = false; }
        if (this.miniFilterText == null) {
            // ensure everything is selected
            this.selectedKeys = new Set(this.allValues.keys());
        }
        else {
            // ensure everything that matches the mini filter is selected
            if (clearExistingSelection) {
                this.selectedKeys.clear();
            }
            this.displayValueModel.forEachDisplayedKey(function (key) { return _this.selectedKeys.add(key); });
        }
    };
    SetValueModel.prototype.deselectAllMatchingMiniFilter = function () {
        var _this = this;
        if (this.miniFilterText == null) {
            // ensure everything is deselected
            this.selectedKeys.clear();
        }
        else {
            // ensure everything that matches the mini filter is deselected
            this.displayValueModel.forEachDisplayedKey(function (key) { return _this.selectedKeys.delete(key); });
        }
    };
    SetValueModel.prototype.selectKey = function (key) {
        this.selectedKeys.add(key);
    };
    SetValueModel.prototype.deselectKey = function (key) {
        if (this.filterParams.excelMode && this.isEverythingVisibleSelected()) {
            // ensure we're starting from the correct "everything selected" state
            this.resetSelectionState(this.displayValueModel.getDisplayedKeys());
        }
        this.selectedKeys.delete(key);
    };
    SetValueModel.prototype.isKeySelected = function (key) {
        return this.selectedKeys.has(key);
    };
    SetValueModel.prototype.isEverythingVisibleSelected = function () {
        var _this = this;
        return !this.displayValueModel.someDisplayedKey(function (it) { return !_this.isKeySelected(it); });
    };
    SetValueModel.prototype.isNothingVisibleSelected = function () {
        var _this = this;
        return !this.displayValueModel.someDisplayedKey(function (it) { return _this.isKeySelected(it); });
    };
    SetValueModel.prototype.getModel = function () {
        if (!this.hasSelections()) {
            return null;
        }
        // When excelMode = 'windows' and the user has ticked 'Add current selection to filter'
        // the filtering keys can be different from the selected keys, and they should be included
        // in the model.
        var filteringKeys = this.isAddCurrentSelectionToFilterChecked()
            ? this.filteringKeys.allFilteringKeys()
            : null;
        if (filteringKeys && filteringKeys.size > 0) {
            if (this.selectedKeys) {
                // When existing filtering keys are present along with selected keys,
                // we combine them and return the result.
                // We use a set structure to avoid duplicates
                var modelKeys = new Set(__spreadArray(__spreadArray([], __read(Array.from(filteringKeys)), false), __read(Array.from(this.selectedKeys).filter(function (key) { return !filteringKeys.has(key); })), false));
                return Array.from(modelKeys);
            }
            return Array.from(filteringKeys);
        }
        // No extra filtering keys are present - so just return the selected keys
        return Array.from(this.selectedKeys);
    };
    SetValueModel.prototype.setModel = function (model) {
        var _this = this;
        return this.allValuesPromise.then(function (keys) {
            if (model == null) {
                _this.resetSelectionState(keys !== null && keys !== void 0 ? keys : []);
            }
            else {
                // select all values from the model that exist in the filter
                _this.selectedKeys.clear();
                var existingFormattedKeys_1 = new Map();
                _this.allValues.forEach(function (_value, key) {
                    existingFormattedKeys_1.set(_this.caseFormat(key), key);
                });
                model.forEach(function (unformattedKey) {
                    var formattedKey = _this.caseFormat(_.makeNull(unformattedKey));
                    var existingUnformattedKey = existingFormattedKeys_1.get(formattedKey);
                    if (existingUnformattedKey !== undefined) {
                        _this.selectKey(existingUnformattedKey);
                    }
                });
            }
        });
    };
    SetValueModel.prototype.uniqueValues = function (values) {
        var _this = this;
        var uniqueValues = new Map();
        var formattedKeys = new Set();
        (values !== null && values !== void 0 ? values : []).forEach(function (value) {
            var valueToUse = _.makeNull(value);
            var unformattedKey = _this.convertAndGetKey(valueToUse);
            var formattedKey = _this.caseFormat(unformattedKey);
            if (!formattedKeys.has(formattedKey)) {
                formattedKeys.add(formattedKey);
                uniqueValues.set(unformattedKey, valueToUse);
            }
        });
        return uniqueValues;
    };
    SetValueModel.prototype.convertAndGetKey = function (value) {
        return this.convertValuesToStrings ? value : this.createKey(value);
    };
    SetValueModel.prototype.resetSelectionState = function (keys) {
        if (this.filterParams.defaultToNothingSelected) {
            this.selectedKeys.clear();
        }
        else {
            this.selectedKeys = new Set(keys);
        }
    };
    SetValueModel.prototype.hasGroups = function () {
        return this.displayValueModel.hasGroups();
    };
    SetValueModel.prototype.createTreeDataOrGroupingComparator = function () {
        return function (_a, _b) {
            var _c = __read(_a, 2); _c[0]; var aValue = _c[1];
            var _d = __read(_b, 2); _d[0]; var bValue = _d[1];
            if (aValue == null) {
                return bValue == null ? 0 : -1;
            }
            else if (bValue == null) {
                return 1;
            }
            for (var i = 0; i < aValue.length; i++) {
                if (i >= bValue.length) {
                    return 1;
                }
                var diff = _.defaultComparator(aValue[i], bValue[i]);
                if (diff !== 0) {
                    return diff;
                }
            }
            return 0;
        };
    };
    SetValueModel.prototype.setAppliedModelKeys = function (appliedModelKeys) {
        this.filteringKeys.setFilteringKeys(appliedModelKeys);
    };
    SetValueModel.prototype.addToAppliedModelKeys = function (appliedModelKey) {
        this.filteringKeys.addFilteringKey(appliedModelKey);
    };
    SetValueModel.prototype.getAppliedModelKeys = function () {
        return this.filteringKeys.allFilteringKeys();
    };
    SetValueModel.prototype.getCaseFormattedAppliedModelKeys = function () {
        return this.filteringKeys.allFilteringKeysCaseFormatted();
    };
    SetValueModel.prototype.hasAppliedModelKey = function (appliedModelKey) {
        return this.filteringKeys.hasCaseFormattedFilteringKey(appliedModelKey);
    };
    SetValueModel.prototype.hasAnyAppliedModelKey = function () {
        return !this.filteringKeys.noAppliedFilteringKeys();
    };
    SetValueModel.EVENT_AVAILABLE_VALUES_CHANGED = 'availableValuesChanged';
    return SetValueModel;
}());

var __extends$i = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$d = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/** @param V type of value in the Set Filter */
var SetFilterListItem = /** @class */ (function (_super) {
    __extends$i(SetFilterListItem, _super);
    function SetFilterListItem(params) {
        var _this = this;
        var _a;
        _this = _super.call(this, params.isGroup ? SetFilterListItem.GROUP_TEMPLATE : SetFilterListItem.TEMPLATE) || this;
        _this.focusWrapper = params.focusWrapper;
        _this.value = params.value;
        _this.params = params.params;
        _this.translate = params.translate;
        _this.valueFormatter = params.valueFormatter;
        _this.item = params.item;
        _this.isSelected = params.isSelected;
        _this.isTree = params.isTree;
        _this.depth = (_a = params.depth) !== null && _a !== void 0 ? _a : 0;
        _this.isGroup = params.isGroup;
        _this.groupsExist = params.groupsExist;
        _this.isExpanded = params.isExpanded;
        _this.hasIndeterminateExpandState = params.hasIndeterminateExpandState;
        return _this;
    }
    SetFilterListItem.prototype.init = function () {
        var _this = this;
        this.addDestroyFunc(function () { var _a; return (_a = _this.destroyCellRendererComponent) === null || _a === void 0 ? void 0 : _a.call(_this); });
        this.render();
        this.eCheckbox
            .setLabelEllipsis(true)
            .setValue(this.isSelected, true)
            .setDisabled(!!this.params.readOnly)
            .getInputElement().setAttribute('tabindex', '-1');
        this.refreshVariableAriaLabels();
        if (this.isTree) {
            if (this.depth > 0) {
                this.addCssClass('ag-set-filter-indent-' + this.depth);
            }
            if (this.isGroup) {
                this.setupExpansion();
            }
            else {
                if (this.groupsExist) {
                    this.addCssClass('ag-set-filter-add-group-indent');
                }
            }
            _.setAriaLevel(this.getAriaElement(), this.depth + 1);
        }
        this.refreshAriaChecked();
        if (!!this.params.readOnly) {
            // Don't add event listeners if we're read-only.
            return;
        }
        this.eCheckbox.onValueChange(function (value) { return _this.onCheckboxChanged(!!value); });
    };
    SetFilterListItem.prototype.getFocusableElement = function () {
        return this.focusWrapper;
    };
    SetFilterListItem.prototype.setupExpansion = function () {
        this.eGroupClosedIcon.appendChild(_.createIcon('setFilterGroupClosed', this.gridOptionsService, null));
        this.eGroupOpenedIcon.appendChild(_.createIcon('setFilterGroupOpen', this.gridOptionsService, null));
        this.addManagedListener(this.eGroupClosedIcon, 'click', this.onExpandOrContractClicked.bind(this));
        this.addManagedListener(this.eGroupOpenedIcon, 'click', this.onExpandOrContractClicked.bind(this));
        if (this.hasIndeterminateExpandState) {
            this.eGroupIndeterminateIcon.appendChild(_.createIcon('setFilterGroupIndeterminate', this.gridOptionsService, null));
            this.addManagedListener(this.eGroupIndeterminateIcon, 'click', this.onExpandOrContractClicked.bind(this));
        }
        this.setExpandedIcons();
        this.refreshAriaExpanded();
    };
    SetFilterListItem.prototype.onExpandOrContractClicked = function () {
        this.setExpanded(!this.isExpanded);
    };
    SetFilterListItem.prototype.setExpanded = function (isExpanded, silent) {
        if (this.isGroup && isExpanded !== this.isExpanded) {
            this.isExpanded = isExpanded;
            var event_1 = {
                type: SetFilterListItem.EVENT_EXPANDED_CHANGED,
                isExpanded: !!isExpanded,
                item: this.item
            };
            if (!silent) {
                this.dispatchEvent(event_1);
            }
            this.setExpandedIcons();
            this.refreshAriaExpanded();
        }
    };
    SetFilterListItem.prototype.setExpandedIcons = function () {
        _.setDisplayed(this.eGroupClosedIcon, this.hasIndeterminateExpandState ? this.isExpanded === false : !this.isExpanded);
        _.setDisplayed(this.eGroupOpenedIcon, this.isExpanded === true);
        if (this.hasIndeterminateExpandState) {
            _.setDisplayed(this.eGroupIndeterminateIcon, this.isExpanded === undefined);
        }
    };
    SetFilterListItem.prototype.onCheckboxChanged = function (isSelected) {
        this.isSelected = isSelected;
        var event = {
            type: SetFilterListItem.EVENT_SELECTION_CHANGED,
            isSelected: isSelected,
            item: this.item
        };
        this.dispatchEvent(event);
        this.refreshVariableAriaLabels();
        this.refreshAriaChecked();
    };
    SetFilterListItem.prototype.toggleSelected = function () {
        if (!!this.params.readOnly) {
            return;
        }
        this.setSelected(!this.isSelected);
    };
    SetFilterListItem.prototype.setSelected = function (isSelected, silent) {
        this.isSelected = isSelected;
        this.eCheckbox.setValue(isSelected, silent);
        this.refreshAriaChecked();
    };
    SetFilterListItem.prototype.refreshVariableAriaLabels = function () {
        if (!this.isTree) {
            return;
        }
        var translate = this.localeService.getLocaleTextFunc();
        var checkboxValue = this.eCheckbox.getValue();
        var state = checkboxValue === undefined ?
            translate('ariaIndeterminate', 'indeterminate') :
            (checkboxValue ? translate('ariaVisible', 'visible') : translate('ariaHidden', 'hidden'));
        var visibilityLabel = translate('ariaToggleVisibility', 'Press SPACE to toggle visibility');
        _.setAriaLabelledBy(this.eCheckbox.getInputElement(), undefined);
        this.eCheckbox.setInputAriaLabel("".concat(visibilityLabel, " (").concat(state, ")"));
    };
    SetFilterListItem.prototype.setupFixedAriaLabels = function (value) {
        if (!this.isTree) {
            return;
        }
        var translate = this.localeService.getLocaleTextFunc();
        var itemLabel = translate('ariaFilterValue', 'Filter Value');
        var ariaEl = this.getAriaElement();
        _.setAriaLabel(ariaEl, "".concat(value, " ").concat(itemLabel));
        _.setAriaDescribedBy(ariaEl, this.eCheckbox.getInputElement().id);
    };
    SetFilterListItem.prototype.refreshAriaChecked = function () {
        var ariaEl = this.getAriaElement();
        _.setAriaChecked(ariaEl, this.eCheckbox.getValue());
    };
    SetFilterListItem.prototype.refreshAriaExpanded = function () {
        _.setAriaExpanded(this.getAriaElement(), !!this.isExpanded);
    };
    SetFilterListItem.prototype.refresh = function (item, isSelected, isExpanded) {
        var _a, _b;
        this.item = item;
        // setExpanded checks if value has changed, setSelected does not
        if (isSelected !== this.isSelected) {
            this.setSelected(isSelected, true);
        }
        this.setExpanded(isExpanded, true);
        if (this.valueFunction) {
            // underlying value might have changed, so call again and re-render
            var value = this.valueFunction();
            this.setTooltipAndCellRendererParams(value, value);
            if (!this.cellRendererComponent) {
                this.renderCellWithoutCellRenderer();
            }
        }
        if (this.cellRendererComponent) {
            var success = (_b = (_a = this.cellRendererComponent).refresh) === null || _b === void 0 ? void 0 : _b.call(_a, this.cellRendererParams);
            if (!success) {
                var oldComponent = this.cellRendererComponent;
                this.renderCell();
                this.destroyBean(oldComponent);
            }
        }
    };
    SetFilterListItem.prototype.render = function () {
        var column = this.params.column;
        var value = this.value;
        var formattedValue = null;
        if (typeof value === 'function') {
            this.valueFunction = value;
            formattedValue = this.valueFunction();
            // backwards compatibility for select all in value
            value = formattedValue;
        }
        else if (this.isTree) {
            // tree values are already formatted via treeListFormatter
            formattedValue = _.toStringOrNull(value);
        }
        else {
            formattedValue = this.getFormattedValue(column, value);
        }
        this.setTooltipAndCellRendererParams(value, formattedValue);
        this.renderCell();
    };
    SetFilterListItem.prototype.setTooltipAndCellRendererParams = function (value, formattedValue) {
        if (this.params.showTooltips) {
            var tooltipValue = formattedValue != null ? formattedValue : _.toStringOrNull(value);
            this.setTooltip(tooltipValue);
        }
        this.cellRendererParams = this.gridOptionsService.addGridCommonParams({
            value: value,
            valueFormatted: formattedValue,
            colDef: this.params.colDef,
            column: this.params.column,
        });
    };
    SetFilterListItem.prototype.getTooltipParams = function () {
        var res = _super.prototype.getTooltipParams.call(this);
        res.location = 'setFilterValue';
        res.colDef = this.getComponentHolder();
        if (this.isTree) {
            res.level = this.depth;
        }
        return res;
    };
    SetFilterListItem.prototype.getFormattedValue = function (column, value) {
        return this.valueFormatterService.formatValue(column, null, value, this.valueFormatter, false);
    };
    SetFilterListItem.prototype.renderCell = function () {
        var _this = this;
        var compDetails = this.userComponentFactory.getSetFilterCellRendererDetails(this.params, this.cellRendererParams);
        var cellRendererPromise = compDetails ? compDetails.newAgStackInstance() : undefined;
        if (cellRendererPromise == null) {
            this.renderCellWithoutCellRenderer();
            return;
        }
        cellRendererPromise.then(function (component) {
            if (component) {
                _this.cellRendererComponent = component;
                _this.eCheckbox.setLabel(component.getGui());
                _this.destroyCellRendererComponent = function () { return _this.destroyBean(component); };
            }
        });
    };
    SetFilterListItem.prototype.renderCellWithoutCellRenderer = function () {
        var _a;
        var valueToRender = (_a = (this.cellRendererParams.valueFormatted == null ? this.cellRendererParams.value : this.cellRendererParams.valueFormatted)) !== null && _a !== void 0 ? _a : this.translate('blanks');
        if (typeof valueToRender !== 'string') {
            _.warnOnce("Set Filter Value Formatter must return string values. Please ensure the Set Filter Value Formatter returns string values for complex objects, or set convertValuesToStrings=true in the filterParams. See ".concat(this.getFrameworkOverrides().getDocLink('filter-set-filter-list/#filter-value-types')));
            valueToRender = '';
        }
        this.eCheckbox.setLabel(valueToRender);
        this.setupFixedAriaLabels(valueToRender);
    };
    SetFilterListItem.prototype.getComponentHolder = function () {
        return this.params.column.getColDef();
    };
    SetFilterListItem.EVENT_SELECTION_CHANGED = 'selectionChanged';
    SetFilterListItem.EVENT_EXPANDED_CHANGED = 'expandedChanged';
    SetFilterListItem.GROUP_TEMPLATE = "\n        <div class=\"ag-set-filter-item\" aria-hidden=\"true\">\n            <span class=\"ag-set-filter-group-icons\">\n                <span class=\"ag-set-filter-group-closed-icon\" ref=\"eGroupClosedIcon\"></span>\n                <span class=\"ag-set-filter-group-opened-icon\" ref=\"eGroupOpenedIcon\"></span>\n                <span class=\"ag-set-filter-group-indeterminate-icon\" ref=\"eGroupIndeterminateIcon\"></span>\n            </span>\n            <ag-checkbox ref=\"eCheckbox\" class=\"ag-set-filter-item-checkbox\"></ag-checkbox>\n        </div>";
    SetFilterListItem.TEMPLATE = "\n        <div class=\"ag-set-filter-item\">\n            <ag-checkbox ref=\"eCheckbox\" class=\"ag-set-filter-item-checkbox\"></ag-checkbox>\n        </div>";
    __decorate$d([
        Autowired('valueFormatterService')
    ], SetFilterListItem.prototype, "valueFormatterService", void 0);
    __decorate$d([
        Autowired('userComponentFactory')
    ], SetFilterListItem.prototype, "userComponentFactory", void 0);
    __decorate$d([
        RefSelector('eCheckbox')
    ], SetFilterListItem.prototype, "eCheckbox", void 0);
    __decorate$d([
        RefSelector('eGroupOpenedIcon')
    ], SetFilterListItem.prototype, "eGroupOpenedIcon", void 0);
    __decorate$d([
        RefSelector('eGroupClosedIcon')
    ], SetFilterListItem.prototype, "eGroupClosedIcon", void 0);
    __decorate$d([
        RefSelector('eGroupIndeterminateIcon')
    ], SetFilterListItem.prototype, "eGroupIndeterminateIcon", void 0);
    __decorate$d([
        PostConstruct
    ], SetFilterListItem.prototype, "init", null);
    return SetFilterListItem;
}(Component));

var DEFAULT_LOCALE_TEXT = {
    loadingOoo: 'Loading...',
    blanks: '(Blanks)',
    searchOoo: 'Search...',
    selectAll: '(Select All)',
    selectAllSearchResults: '(Select All Search Results)',
    addCurrentSelectionToFilter: 'Add current selection to filter',
    noMatches: 'No matches.'
};

var SetFilterModelFormatter = /** @class */ (function () {
    function SetFilterModelFormatter() {
    }
    SetFilterModelFormatter.prototype.getModelAsString = function (model, setFilter) {
        var values = (model || setFilter.getModel() || {}).values;
        var valueModel = setFilter.getValueModel();
        if (values == null || valueModel == null) {
            return '';
        }
        var availableKeys = values.filter(function (v) { return valueModel.isKeyAvailable(v); });
        var numValues = availableKeys.length;
        var formattedValues = availableKeys.slice(0, 10).map(function (key) { return setFilter.getFormattedValue(key); });
        return "(".concat(numValues, ") ").concat(formattedValues.join(',')).concat(numValues > 10 ? ',...' : '');
    };
    return SetFilterModelFormatter;
}());

var __extends$h = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$c = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/** @param V type of value in the Set Filter */
var SetFilter = /** @class */ (function (_super) {
    __extends$h(SetFilter, _super);
    function SetFilter() {
        var _this = _super.call(this, 'setFilter') || this;
        _this.valueModel = null;
        _this.setFilterParams = null;
        _this.virtualList = null;
        _this.caseSensitive = false;
        _this.convertValuesToStrings = false;
        _this.treeDataTreeList = false;
        _this.groupingTreeList = false;
        _this.hardRefreshVirtualList = false;
        _this.noValueFormatterSupplied = false;
        _this.filterModelFormatter = new SetFilterModelFormatter();
        _this.updateSetFilterOnParamsChange = function (newParams) {
            var _a;
            _this.setFilterParams = newParams;
            _this.convertValuesToStrings = !!newParams.convertValuesToStrings;
            _this.caseSensitive = !!newParams.caseSensitive;
            var keyCreator = (_a = newParams.keyCreator) !== null && _a !== void 0 ? _a : newParams.colDef.keyCreator;
            _this.setValueFormatter(newParams.valueFormatter, keyCreator, _this.convertValuesToStrings, !!newParams.treeList, !!newParams.colDef.refData);
            var isGroupCol = newParams.column.getId().startsWith(GROUP_AUTO_COLUMN_ID);
            _this.treeDataTreeList = _this.gridOptionsService.get('treeData') && !!newParams.treeList && isGroupCol;
            _this.getDataPath = _this.gridOptionsService.get('getDataPath');
            _this.groupingTreeList = !!_this.columnModel.getRowGroupColumns().length && !!newParams.treeList && isGroupCol;
            _this.createKey = _this.generateCreateKey(keyCreator, _this.convertValuesToStrings, _this.treeDataTreeList || _this.groupingTreeList);
        };
        return _this;
    }
    SetFilter.prototype.postConstruct = function () {
        _super.prototype.postConstruct.call(this);
    };
    // unlike the simple filters, nothing in the set filter UI shows/hides.
    // maybe this method belongs in abstractSimpleFilter???
    SetFilter.prototype.updateUiVisibility = function () { };
    SetFilter.prototype.createBodyTemplate = function () {
        return /* html */ "\n            <div class=\"ag-set-filter\">\n                <div ref=\"eFilterLoading\" class=\"ag-filter-loading ag-hidden\">".concat(this.translateForSetFilter('loadingOoo'), "</div>\n                <ag-input-text-field class=\"ag-mini-filter\" ref=\"eMiniFilter\"></ag-input-text-field>\n                <div ref=\"eFilterNoMatches\" class=\"ag-filter-no-matches ag-hidden\">").concat(this.translateForSetFilter('noMatches'), "</div>\n                <div ref=\"eSetFilterList\" class=\"ag-set-filter-list\" role=\"presentation\"></div>\n            </div>");
    };
    SetFilter.prototype.handleKeyDown = function (e) {
        _super.prototype.handleKeyDown.call(this, e);
        if (e.defaultPrevented) {
            return;
        }
        switch (e.key) {
            case KeyCode.SPACE:
                this.handleKeySpace(e);
                break;
            case KeyCode.ENTER:
                this.handleKeyEnter(e);
                break;
            case KeyCode.LEFT:
                this.handleKeyLeft(e);
                break;
            case KeyCode.RIGHT:
                this.handleKeyRight(e);
                break;
        }
    };
    SetFilter.prototype.handleKeySpace = function (e) {
        var _a;
        (_a = this.getComponentForKeyEvent(e)) === null || _a === void 0 ? void 0 : _a.toggleSelected();
    };
    SetFilter.prototype.handleKeyEnter = function (e) {
        if (!this.setFilterParams) {
            return;
        }
        var _a = this.setFilterParams || {}, excelMode = _a.excelMode, readOnly = _a.readOnly;
        if (!excelMode || !!readOnly) {
            return;
        }
        e.preventDefault();
        // in Excel Mode, hitting Enter is the same as pressing the Apply button
        this.onBtApply(false, false, e);
        if (this.setFilterParams.excelMode === 'mac') {
            // in Mac version, select all the input text
            this.eMiniFilter.getInputElement().select();
        }
    };
    SetFilter.prototype.handleKeyLeft = function (e) {
        var _a;
        (_a = this.getComponentForKeyEvent(e)) === null || _a === void 0 ? void 0 : _a.setExpanded(false);
    };
    SetFilter.prototype.handleKeyRight = function (e) {
        var _a;
        (_a = this.getComponentForKeyEvent(e)) === null || _a === void 0 ? void 0 : _a.setExpanded(true);
    };
    SetFilter.prototype.getComponentForKeyEvent = function (e) {
        var _a;
        var eDocument = this.gridOptionsService.getDocument();
        if (!this.eSetFilterList.contains(eDocument.activeElement) || !this.virtualList) {
            return;
        }
        var currentItem = this.virtualList.getLastFocusedRow();
        if (currentItem == null) {
            return;
        }
        var component = this.virtualList.getComponentAt(currentItem);
        if (component == null) {
            return;
        }
        e.preventDefault();
        var readOnly = ((_a = this.setFilterParams) !== null && _a !== void 0 ? _a : {}).readOnly;
        if (!!readOnly) {
            return;
        }
        return component;
    };
    SetFilter.prototype.getCssIdentifier = function () {
        return 'set-filter';
    };
    SetFilter.prototype.setModel = function (model) {
        var _a;
        if (model == null && ((_a = this.valueModel) === null || _a === void 0 ? void 0 : _a.getModel()) == null) {
            // refreshing is expensive. if new and old model are both null (e.g. nothing set), skip.
            // mini filter isn't contained within the model, so always reset
            this.setMiniFilter(null);
            return AgPromise.resolve();
        }
        return _super.prototype.setModel.call(this, model);
    };
    SetFilter.prototype.refresh = function (params) {
        var _this = this;
        var _a, _b, _c;
        if (!_super.prototype.refresh.call(this, params)) {
            return false;
        }
        // Those params have a large impact and should trigger a reload when they change.
        var paramsThatForceReload = [
            'treeList', 'treeListFormatter', 'treeListPathGetter', 'keyCreator', 'convertValuesToStrings',
            'caseSensitive', 'comparator', 'suppressSelectAll', 'excelMode'
        ];
        if (paramsThatForceReload.some(function (param) { var _a; return params[param] !== ((_a = _this.setFilterParams) === null || _a === void 0 ? void 0 : _a[param]); })) {
            return false;
        }
        if (this.haveColDefParamsChanged(params.colDef)) {
            return false;
        }
        _super.prototype.updateParams.call(this, params);
        this.updateSetFilterOnParamsChange(params);
        this.updateMiniFilter();
        if (params.cellRenderer !== ((_a = this.setFilterParams) === null || _a === void 0 ? void 0 : _a.cellRenderer) ||
            params.valueFormatter !== ((_b = this.setFilterParams) === null || _b === void 0 ? void 0 : _b.valueFormatter)) {
            this.checkAndRefreshVirtualList();
        }
        (_c = this.valueModel) === null || _c === void 0 ? void 0 : _c.updateOnParamsChange(params).then(function () {
            var _a;
            if ((_a = _this.valueModel) === null || _a === void 0 ? void 0 : _a.hasSelections()) {
                _this.refreshFilterValues();
            }
        });
        return true;
    };
    SetFilter.prototype.haveColDefParamsChanged = function (colDef) {
        var _a;
        var paramsThatForceReload = [
            'keyCreator', 'filterValueGetter',
        ];
        var existingColDef = (_a = this.setFilterParams) === null || _a === void 0 ? void 0 : _a.colDef;
        return paramsThatForceReload.some(function (param) { return colDef[param] !== (existingColDef === null || existingColDef === void 0 ? void 0 : existingColDef[param]); });
    };
    SetFilter.prototype.setModelAndRefresh = function (values) {
        var _this = this;
        return this.valueModel ? this.valueModel.setModel(values).then(function () { return _this.checkAndRefreshVirtualList(); }) : AgPromise.resolve();
    };
    SetFilter.prototype.resetUiToDefaults = function () {
        this.setMiniFilter(null);
        return this.setModelAndRefresh(null);
    };
    SetFilter.prototype.setModelIntoUi = function (model) {
        this.setMiniFilter(null);
        var values = model == null ? null : model.values;
        return this.setModelAndRefresh(values);
    };
    SetFilter.prototype.getModelFromUi = function () {
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        var values = this.valueModel.getModel();
        if (!values) {
            return null;
        }
        return { values: values, filterType: this.getFilterType() };
    };
    SetFilter.prototype.getFilterType = function () {
        return 'set';
    };
    SetFilter.prototype.getValueModel = function () {
        return this.valueModel;
    };
    SetFilter.prototype.areModelsEqual = function (a, b) {
        // both are missing
        if (a == null && b == null) {
            return true;
        }
        return a != null && b != null && _.areEqual(a.values, b.values);
    };
    SetFilter.prototype.setParams = function (params) {
        var _this = this;
        var _a;
        this.applyExcelModeOptions(params);
        _super.prototype.setParams.call(this, params);
        this.updateSetFilterOnParamsChange(params);
        var keyCreator = (_a = params.keyCreator) !== null && _a !== void 0 ? _a : params.colDef.keyCreator;
        this.valueModel = new SetValueModel({
            filterParams: params,
            setIsLoading: function (loading) { return _this.setIsLoading(loading); },
            valueFormatterService: this.valueFormatterService,
            translate: function (key) { return _this.translateForSetFilter(key); },
            caseFormat: function (v) { return _this.caseFormat(v); },
            createKey: this.createKey,
            valueFormatter: this.valueFormatter,
            usingComplexObjects: !!keyCreator,
            gridOptionsService: this.gridOptionsService,
            columnModel: this.columnModel,
            valueService: this.valueService,
            treeDataTreeList: this.treeDataTreeList,
            groupingTreeList: this.groupingTreeList,
            addManagedListener: function (event, listener) { return _this.addManagedListener(_this.eventService, event, listener); }
        });
        this.initialiseFilterBodyUi();
        this.addEventListenersForDataChanges();
    };
    SetFilter.prototype.onAddCurrentSelectionToFilterChange = function (newValue) {
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        this.valueModel.setAddCurrentSelectionToFilter(newValue);
    };
    SetFilter.prototype.setValueFormatter = function (providedValueFormatter, keyCreator, convertValuesToStrings, treeList, isRefData) {
        var valueFormatter = providedValueFormatter;
        if (!valueFormatter) {
            if (keyCreator && !convertValuesToStrings && !treeList) {
                throw new Error('AG Grid: Must supply a Value Formatter in Set Filter params when using a Key Creator unless convertValuesToStrings is enabled');
            }
            this.noValueFormatterSupplied = true;
            // ref data is handled by ValueFormatterService
            if (!isRefData) {
                valueFormatter = function (params) { return _.toStringOrNull(params.value); };
            }
        }
        this.valueFormatter = valueFormatter;
    };
    SetFilter.prototype.generateCreateKey = function (keyCreator, convertValuesToStrings, treeDataOrGrouping) {
        var _this = this;
        if (treeDataOrGrouping && !keyCreator) {
            throw new Error('AG Grid: Must supply a Key Creator in Set Filter params when `treeList = true` on a group column, and Tree Data or Row Grouping is enabled.');
        }
        if (keyCreator) {
            return function (value, node) {
                if (node === void 0) { node = null; }
                var params = _this.getKeyCreatorParams(value, node);
                return _.makeNull(keyCreator(params));
            };
        }
        if (convertValuesToStrings) {
            // for backwards compatibility - keeping separate as it will eventually be removed
            return function (value) { return Array.isArray(value) ? value : _.makeNull(_.toStringOrNull(value)); };
        }
        else {
            return function (value) { return _.makeNull(_.toStringOrNull(value)); };
        }
    };
    SetFilter.prototype.getFormattedValue = function (key) {
        var _a;
        var value = this.valueModel.getValue(key);
        if (this.noValueFormatterSupplied && (this.treeDataTreeList || this.groupingTreeList) && Array.isArray(value)) {
            // essentially get back the cell value
            value = _.last(value);
        }
        var formattedValue = this.valueFormatterService.formatValue(this.setFilterParams.column, null, value, this.valueFormatter, false);
        return (_a = (formattedValue == null ? _.toStringOrNull(value) : formattedValue)) !== null && _a !== void 0 ? _a : this.translateForSetFilter('blanks');
    };
    SetFilter.prototype.applyExcelModeOptions = function (params) {
        // apply default options to match Excel behaviour, unless they have already been specified
        if (params.excelMode === 'windows') {
            if (!params.buttons) {
                params.buttons = ['apply', 'cancel'];
            }
            if (params.closeOnApply == null) {
                params.closeOnApply = true;
            }
        }
        else if (params.excelMode === 'mac') {
            if (!params.buttons) {
                params.buttons = ['reset'];
            }
            if (params.applyMiniFilterWhileTyping == null) {
                params.applyMiniFilterWhileTyping = true;
            }
            if (params.debounceMs == null) {
                params.debounceMs = 500;
            }
        }
        if (params.excelMode && params.defaultToNothingSelected) {
            params.defaultToNothingSelected = false;
            _.warnOnce('The Set Filter Parameter "defaultToNothingSelected" value was ignored because it does not work when "excelMode" is used.');
        }
    };
    SetFilter.prototype.addEventListenersForDataChanges = function () {
        var _this = this;
        if (!this.isValuesTakenFromGrid()) {
            return;
        }
        this.addManagedListener(this.eventService, Events.EVENT_CELL_VALUE_CHANGED, function (event) {
            // only interested in changes to do with this column
            if (_this.setFilterParams && event.column === _this.setFilterParams.column) {
                _this.syncAfterDataChange();
            }
        });
        this.addManagedPropertyListeners(['treeData', 'getDataPath', 'groupAllowUnbalanced'], function () {
            _this.syncAfterDataChange();
        });
    };
    SetFilter.prototype.syncAfterDataChange = function () {
        var _this = this;
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        var promise = this.valueModel.refreshValues();
        return promise.then(function () {
            _this.checkAndRefreshVirtualList();
            _this.onBtApply(false, true);
        });
    };
    SetFilter.prototype.setIsLoading = function (isLoading) {
        _.setDisplayed(this.eFilterLoading, isLoading);
        if (!isLoading) {
            // hard refresh when async data received
            this.hardRefreshVirtualList = true;
        }
    };
    SetFilter.prototype.initialiseFilterBodyUi = function () {
        this.initVirtualList();
        this.initMiniFilter();
    };
    SetFilter.prototype.initVirtualList = function () {
        var _this = this;
        if (!this.setFilterParams) {
            throw new Error('Set filter params have not been provided.');
        }
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        var translate = this.localeService.getLocaleTextFunc();
        var filterListName = translate('ariaFilterList', 'Filter List');
        var isTree = !!this.setFilterParams.treeList;
        var virtualList = this.virtualList = this.createBean(new VirtualList({
            cssIdentifier: 'filter',
            ariaRole: isTree ? 'tree' : 'listbox',
            listName: filterListName
        }));
        var eSetFilterList = this.getRefElement('eSetFilterList');
        if (isTree) {
            eSetFilterList.classList.add('ag-set-filter-tree-list');
        }
        if (eSetFilterList) {
            eSetFilterList.appendChild(virtualList.getGui());
        }
        var cellHeight = this.setFilterParams.cellHeight;
        if (cellHeight != null) {
            virtualList.setRowHeight(cellHeight);
        }
        var componentCreator = function (item, listItemElement) { return _this.createSetListItem(item, isTree, listItemElement); };
        virtualList.setComponentCreator(componentCreator);
        var componentUpdater = function (item, component) { return _this.updateSetListItem(item, component); };
        virtualList.setComponentUpdater(componentUpdater);
        var model;
        if (this.setFilterParams.suppressSelectAll) {
            model = new ModelWrapper(this.valueModel);
        }
        else {
            model = new ModelWrapperWithSelectAll(this.valueModel, function () { return _this.isSelectAllSelected(); });
        }
        if (isTree) {
            model = new TreeModelWrapper(model);
        }
        virtualList.setModel(model);
    };
    SetFilter.prototype.getSelectAllLabel = function () {
        if (!this.setFilterParams) {
            throw new Error('Set filter params have not been provided.');
        }
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        var key = this.valueModel.getMiniFilter() == null || !this.setFilterParams.excelMode ?
            'selectAll' : 'selectAllSearchResults';
        return this.translateForSetFilter(key);
    };
    SetFilter.prototype.getAddSelectionToFilterLabel = function () {
        if (!this.setFilterParams) {
            throw new Error('Set filter params have not been provided.');
        }
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        return this.translateForSetFilter('addCurrentSelectionToFilter');
    };
    SetFilter.prototype.createSetListItem = function (item, isTree, focusWrapper) {
        var _this = this;
        if (!this.setFilterParams) {
            throw new Error('Set filter params have not been provided.');
        }
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        var groupsExist = this.valueModel.hasGroups();
        var _a = this.isSelectedExpanded(item), isSelected = _a.isSelected, isExpanded = _a.isExpanded;
        var _b = this.newSetListItemAttributes(item, isTree), value = _b.value, depth = _b.depth, isGroup = _b.isGroup, hasIndeterminateExpandState = _b.hasIndeterminateExpandState, selectedListener = _b.selectedListener, expandedListener = _b.expandedListener;
        var itemParams = {
            focusWrapper: focusWrapper,
            value: value,
            params: this.setFilterParams,
            translate: function (translateKey) { return _this.translateForSetFilter(translateKey); },
            valueFormatter: this.valueFormatter,
            item: item,
            isSelected: isSelected,
            isTree: isTree,
            depth: depth,
            groupsExist: groupsExist,
            isGroup: isGroup,
            isExpanded: isExpanded,
            hasIndeterminateExpandState: hasIndeterminateExpandState,
        };
        var listItem = this.createBean(new SetFilterListItem(itemParams));
        listItem.addEventListener(SetFilterListItem.EVENT_SELECTION_CHANGED, selectedListener);
        if (expandedListener) {
            listItem.addEventListener(SetFilterListItem.EVENT_EXPANDED_CHANGED, expandedListener);
        }
        return listItem;
    };
    SetFilter.prototype.newSetTreeItemAttributes = function (item, isTree) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        if (!this.setFilterParams) {
            throw new Error('Set filter params have not been provided.');
        }
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        var groupsExist = this.valueModel.hasGroups();
        // Select all option
        if (item.key === SetFilterDisplayValue.SELECT_ALL) {
            return {
                value: function () { return _this.getSelectAllLabel(); },
                isGroup: groupsExist,
                depth: item.depth,
                hasIndeterminateExpandState: true,
                selectedListener: function (e) { return _this.onSelectAll(e.isSelected); },
                expandedListener: function (e) { return _this.onExpandAll(e.item, e.isExpanded); },
            };
        }
        // Add selection to filter option
        if (item.key === SetFilterDisplayValue.ADD_SELECTION_TO_FILTER) {
            return {
                value: function () { return _this.getAddSelectionToFilterLabel(); },
                depth: item.depth,
                isGroup: false,
                hasIndeterminateExpandState: false,
                selectedListener: function (e) {
                    _this.onAddCurrentSelectionToFilterChange(e.isSelected);
                }
            };
        }
        // Group
        if (item.children) {
            return {
                value: (_c = (_b = (_a = this.setFilterParams).treeListFormatter) === null || _b === void 0 ? void 0 : _b.call(_a, item.treeKey, item.depth, item.parentTreeKeys)) !== null && _c !== void 0 ? _c : item.treeKey,
                depth: item.depth,
                isGroup: true,
                selectedListener: function (e) { return _this.onGroupItemSelected(e.item, e.isSelected); },
                expandedListener: function (e) { return _this.onExpandedChanged(e.item, e.isExpanded); },
            };
        }
        // Leaf
        return {
            value: (_f = (_e = (_d = this.setFilterParams).treeListFormatter) === null || _e === void 0 ? void 0 : _e.call(_d, item.treeKey, item.depth, item.parentTreeKeys)) !== null && _f !== void 0 ? _f : item.treeKey,
            depth: item.depth,
            selectedListener: function (e) { return _this.onItemSelected(e.item.key, e.isSelected); },
        };
    };
    SetFilter.prototype.newSetListItemAttributes = function (item, isTree) {
        var _this = this;
        if (!this.setFilterParams) {
            throw new Error('Set filter params have not been provided.');
        }
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        // Tree item
        if (this.isSetFilterModelTreeItem(item)) {
            return this.newSetTreeItemAttributes(item, isTree);
        }
        // List item - 'Select All'
        if (item === SetFilterDisplayValue.SELECT_ALL) {
            return {
                value: function () { return _this.getSelectAllLabel(); },
                selectedListener: function (e) { return _this.onSelectAll(e.isSelected); },
            };
        }
        // List item - 'Add selection to filter'
        if (item === SetFilterDisplayValue.ADD_SELECTION_TO_FILTER) {
            return {
                value: function () { return _this.getAddSelectionToFilterLabel(); },
                selectedListener: function (e) {
                    _this.onAddCurrentSelectionToFilterChange(e.isSelected);
                },
            };
        }
        // List item
        return {
            value: this.valueModel.getValue(item),
            selectedListener: function (e) { return _this.onItemSelected(e.item, e.isSelected); },
        };
    };
    SetFilter.prototype.updateSetListItem = function (item, component) {
        var _a = this.isSelectedExpanded(item), isSelected = _a.isSelected, isExpanded = _a.isExpanded;
        component.refresh(item, isSelected, isExpanded);
    };
    SetFilter.prototype.isSelectedExpanded = function (item) {
        var isSelected;
        var isExpanded;
        if (this.isSetFilterModelTreeItem(item)) {
            isExpanded = item.expanded;
            if (item.key === SetFilterDisplayValue.SELECT_ALL) {
                isSelected = this.isSelectAllSelected();
            }
            else if (item.key === SetFilterDisplayValue.ADD_SELECTION_TO_FILTER) {
                isSelected = this.valueModel.isAddCurrentSelectionToFilterChecked();
            }
            else if (item.children) {
                isSelected = this.areAllChildrenSelected(item);
            }
            else {
                isSelected = this.valueModel.isKeySelected(item.key);
            }
        }
        else {
            if (item === SetFilterDisplayValue.SELECT_ALL) {
                isSelected = this.isSelectAllSelected();
            }
            else if (item === SetFilterDisplayValue.ADD_SELECTION_TO_FILTER) {
                isSelected = this.valueModel.isAddCurrentSelectionToFilterChecked();
            }
            else {
                isSelected = this.valueModel.isKeySelected(item);
            }
        }
        return { isSelected: isSelected, isExpanded: isExpanded };
    };
    SetFilter.prototype.isSetFilterModelTreeItem = function (item) {
        return (item === null || item === void 0 ? void 0 : item.treeKey) !== undefined;
    };
    SetFilter.prototype.initMiniFilter = function () {
        var _this = this;
        if (!this.setFilterParams) {
            throw new Error('Set filter params have not been provided.');
        }
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        var _a = this, eMiniFilter = _a.eMiniFilter, localeService = _a.localeService;
        var translate = localeService.getLocaleTextFunc();
        eMiniFilter.setDisplayed(!this.setFilterParams.suppressMiniFilter);
        eMiniFilter.setValue(this.valueModel.getMiniFilter());
        eMiniFilter.onValueChange(function () { return _this.onMiniFilterInput(); });
        eMiniFilter.setInputAriaLabel(translate('ariaSearchFilterValues', 'Search filter values'));
        this.addManagedListener(eMiniFilter.getInputElement(), 'keydown', function (e) { return _this.onMiniFilterKeyDown(e); });
    };
    SetFilter.prototype.updateMiniFilter = function () {
        if (!this.setFilterParams) {
            throw new Error('Set filter params have not been provided.');
        }
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        var eMiniFilter = this.eMiniFilter;
        if (eMiniFilter.isDisplayed() !== !this.setFilterParams.suppressMiniFilter) {
            eMiniFilter.setDisplayed(!this.setFilterParams.suppressMiniFilter);
        }
        var miniFilterValue = this.valueModel.getMiniFilter();
        if (eMiniFilter.getValue() !== miniFilterValue) {
            eMiniFilter.setValue(miniFilterValue);
        }
    };
    // we need to have the GUI attached before we can draw the virtual rows, as the
    // virtual row logic needs info about the GUI state
    SetFilter.prototype.afterGuiAttached = function (params) {
        if (!this.setFilterParams) {
            throw new Error('Set filter params have not been provided.');
        }
        _super.prototype.afterGuiAttached.call(this, params);
        // collapse all tree list items (if tree list)
        this.resetExpansion();
        this.refreshVirtualList();
        var eMiniFilter = this.eMiniFilter;
        eMiniFilter.setInputPlaceholder(this.translateForSetFilter('searchOoo'));
        if (!params || !params.suppressFocus) {
            eMiniFilter.getFocusableElement().focus();
        }
    };
    SetFilter.prototype.afterGuiDetached = function () {
        var _a, _b;
        _super.prototype.afterGuiDetached.call(this);
        // discard any unapplied UI state (reset to model)
        if ((_a = this.setFilterParams) === null || _a === void 0 ? void 0 : _a.excelMode) {
            this.resetMiniFilter();
        }
        var appliedModel = this.getModel();
        if (((_b = this.setFilterParams) === null || _b === void 0 ? void 0 : _b.excelMode) || !this.areModelsEqual(appliedModel, this.getModelFromUi())) {
            this.resetUiToActiveModel(appliedModel);
            this.showOrHideResults();
        }
    };
    SetFilter.prototype.applyModel = function (source) {
        var _this = this;
        if (source === void 0) { source = 'api'; }
        if (!this.setFilterParams) {
            throw new Error('Set filter params have not been provided.');
        }
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        if (this.setFilterParams.excelMode && source !== 'rowDataUpdated' && this.valueModel.isEverythingVisibleSelected()) {
            // In Excel, if the filter is applied with all visible values selected, then any active filter on the
            // column is removed. This ensures the filter is removed in this situation.
            this.valueModel.selectAllMatchingMiniFilter();
        }
        // Here we implement AG-9090 TC2
        // When 'Add current selection to filter' is visible and checked, but no filter is applied:
        // Do NOT apply the current selection as filter.
        var shouldKeepCurrentSelection = this.valueModel.showAddCurrentSelectionToFilter() && this.valueModel.isAddCurrentSelectionToFilterChecked();
        if (shouldKeepCurrentSelection && !this.getModel()) {
            return false;
        }
        var result = _super.prototype.applyModel.call(this, source);
        // keep appliedModelKeys in sync with the applied model
        var appliedModel = this.getModel();
        if (appliedModel) {
            if (!shouldKeepCurrentSelection) {
                this.valueModel.setAppliedModelKeys(new Set());
            }
            appliedModel.values.forEach(function (key) {
                _this.valueModel.addToAppliedModelKeys(key);
            });
        }
        else {
            if (!shouldKeepCurrentSelection) {
                this.valueModel.setAppliedModelKeys(null);
            }
        }
        return result;
    };
    SetFilter.prototype.isModelValid = function (model) {
        return this.setFilterParams && this.setFilterParams.excelMode ? model == null || model.values.length > 0 : true;
    };
    SetFilter.prototype.doesFilterPass = function (params) {
        var _this = this;
        if (!this.setFilterParams || !this.valueModel || !this.valueModel.getCaseFormattedAppliedModelKeys()) {
            return true;
        }
        // if nothing selected, don't need to check value
        if (!this.valueModel.hasAnyAppliedModelKey()) {
            return false;
        }
        var node = params.node, data = params.data;
        if (this.treeDataTreeList) {
            return this.doesFilterPassForTreeData(node, data);
        }
        if (this.groupingTreeList) {
            return this.doesFilterPassForGrouping(node);
        }
        var value = this.getValueFromNode(node);
        if (this.convertValuesToStrings) {
            // for backwards compatibility - keeping separate as it will eventually be removed
            return this.doesFilterPassForConvertValuesToString(node, value);
        }
        if (value != null && Array.isArray(value)) {
            if (value.length === 0) {
                return this.valueModel.hasAppliedModelKey(null);
            }
            return value.some(function (v) { return _this.isInAppliedModel(_this.createKey(v, node)); });
        }
        return this.isInAppliedModel(this.createKey(value, node));
    };
    SetFilter.prototype.doesFilterPassForConvertValuesToString = function (node, value) {
        var _this = this;
        var key = this.createKey(value, node);
        if (key != null && Array.isArray(key)) {
            if (key.length === 0) {
                return this.valueModel.hasAppliedModelKey(null);
            }
            return key.some(function (v) { return _this.isInAppliedModel(v); });
        }
        return this.isInAppliedModel(key);
    };
    SetFilter.prototype.doesFilterPassForTreeData = function (node, data) {
        var _a;
        if ((_a = node.childrenAfterGroup) === null || _a === void 0 ? void 0 : _a.length) {
            // only perform checking on leaves. The core filtering logic for tree data won't work properly otherwise
            return false;
        }
        return this.isInAppliedModel(this.createKey(this.checkMakeNullDataPath(this.getDataPath(data))));
    };
    SetFilter.prototype.doesFilterPassForGrouping = function (node) {
        var _this = this;
        var dataPath = this.columnModel.getRowGroupColumns().map(function (groupCol) { return _this.valueService.getKeyForNode(groupCol, node); });
        dataPath.push(this.getValueFromNode(node));
        return this.isInAppliedModel(this.createKey(this.checkMakeNullDataPath(dataPath)));
    };
    SetFilter.prototype.checkMakeNullDataPath = function (dataPath) {
        if (dataPath) {
            dataPath = dataPath.map(function (treeKey) { return _.toStringOrNull(_.makeNull(treeKey)); });
        }
        if (dataPath === null || dataPath === void 0 ? void 0 : dataPath.some(function (treeKey) { return treeKey == null; })) {
            return null;
        }
        return dataPath;
    };
    SetFilter.prototype.isInAppliedModel = function (key) {
        return this.valueModel.hasAppliedModelKey(key);
    };
    SetFilter.prototype.getValueFromNode = function (node) {
        return this.setFilterParams.getValue(node);
    };
    SetFilter.prototype.getKeyCreatorParams = function (value, node) {
        if (node === void 0) { node = null; }
        return {
            value: value,
            colDef: this.setFilterParams.colDef,
            column: this.setFilterParams.column,
            node: node,
            data: node === null || node === void 0 ? void 0 : node.data,
            api: this.setFilterParams.api,
            columnApi: this.setFilterParams.columnApi,
            context: this.setFilterParams.context
        };
    };
    SetFilter.prototype.onNewRowsLoaded = function () {
        if (!this.isValuesTakenFromGrid()) {
            return;
        }
        this.syncAfterDataChange();
    };
    SetFilter.prototype.isValuesTakenFromGrid = function () {
        if (!this.valueModel) {
            return false;
        }
        var valuesType = this.valueModel.getValuesType();
        return valuesType === SetFilterModelValuesType.TAKEN_FROM_GRID_VALUES;
    };
    //noinspection JSUnusedGlobalSymbols
    /**
     * Public method provided so the user can change the value of the filter once
     * the filter has been already started
     * @param values The values to use.
     */
    SetFilter.prototype.setFilterValues = function (values) {
        var _this = this;
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        this.valueModel.overrideValues(values).then(function () {
            _this.checkAndRefreshVirtualList();
            _this.onUiChanged();
        });
    };
    //noinspection JSUnusedGlobalSymbols
    /**
     * Public method provided so the user can reset the values of the filter once that it has started.
     */
    SetFilter.prototype.resetFilterValues = function () {
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        this.valueModel.setValuesType(SetFilterModelValuesType.TAKEN_FROM_GRID_VALUES);
        this.syncAfterDataChange();
    };
    SetFilter.prototype.refreshFilterValues = function () {
        var _this = this;
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        // the model is still being initialised
        if (!this.valueModel.isInitialised()) {
            return;
        }
        this.valueModel.refreshValues().then(function () {
            _this.checkAndRefreshVirtualList();
            _this.onUiChanged();
        });
    };
    SetFilter.prototype.onAnyFilterChanged = function () {
        var _this = this;
        // don't block the current action when updating the values for this filter
        setTimeout(function () {
            if (!_this.isAlive()) {
                return;
            }
            if (!_this.valueModel) {
                throw new Error('Value model has not been created.');
            }
            _this.valueModel.refreshAfterAnyFilterChanged().then(function (refresh) {
                if (refresh) {
                    _this.checkAndRefreshVirtualList();
                    _this.showOrHideResults();
                }
            });
        }, 0);
    };
    SetFilter.prototype.onMiniFilterInput = function () {
        if (!this.setFilterParams) {
            throw new Error('Set filter params have not been provided.');
        }
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        if (!this.valueModel.setMiniFilter(this.eMiniFilter.getValue())) {
            return;
        }
        var _a = this.setFilterParams || {}, applyMiniFilterWhileTyping = _a.applyMiniFilterWhileTyping, readOnly = _a.readOnly;
        if (!readOnly && applyMiniFilterWhileTyping) {
            this.filterOnAllVisibleValues(false);
        }
        else {
            this.updateUiAfterMiniFilterChange();
        }
    };
    SetFilter.prototype.updateUiAfterMiniFilterChange = function () {
        if (!this.setFilterParams) {
            throw new Error('Set filter params have not been provided.');
        }
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        var _a = this.setFilterParams || {}, excelMode = _a.excelMode, readOnly = _a.readOnly;
        if (excelMode == null || !!readOnly) {
            this.checkAndRefreshVirtualList();
        }
        else if (this.valueModel.getMiniFilter() == null) {
            this.resetUiToActiveModel(this.getModel());
        }
        else {
            this.valueModel.selectAllMatchingMiniFilter(true);
            this.checkAndRefreshVirtualList();
            this.onUiChanged();
        }
        this.showOrHideResults();
    };
    SetFilter.prototype.showOrHideResults = function () {
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        var hideResults = this.valueModel.getMiniFilter() != null && this.valueModel.getDisplayedValueCount() < 1;
        _.setDisplayed(this.eNoMatches, hideResults);
        _.setDisplayed(this.eSetFilterList, !hideResults);
    };
    SetFilter.prototype.resetMiniFilter = function () {
        var _a;
        this.eMiniFilter.setValue(null, true);
        (_a = this.valueModel) === null || _a === void 0 ? void 0 : _a.setMiniFilter(null);
    };
    SetFilter.prototype.resetUiToActiveModel = function (currentModel, afterUiUpdatedFunc) {
        var _this = this;
        // override the default behaviour as we don't always want to clear the mini filter
        this.setModelAndRefresh(currentModel == null ? null : currentModel.values).then(function () {
            _this.onUiChanged(false, 'prevent');
            afterUiUpdatedFunc === null || afterUiUpdatedFunc === void 0 ? void 0 : afterUiUpdatedFunc();
        });
    };
    SetFilter.prototype.handleCancelEnd = function (e) {
        this.setMiniFilter(null);
        _super.prototype.handleCancelEnd.call(this, e);
    };
    SetFilter.prototype.onMiniFilterKeyDown = function (e) {
        var _a = this.setFilterParams || {}, excelMode = _a.excelMode, readOnly = _a.readOnly;
        if (e.key === KeyCode.ENTER && !excelMode && !readOnly) {
            this.filterOnAllVisibleValues();
        }
    };
    SetFilter.prototype.filterOnAllVisibleValues = function (applyImmediately) {
        if (applyImmediately === void 0) { applyImmediately = true; }
        var readOnly = (this.setFilterParams || {}).readOnly;
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        if (!!readOnly) {
            throw new Error('Unable to filter in readOnly mode.');
        }
        this.valueModel.selectAllMatchingMiniFilter(true);
        this.checkAndRefreshVirtualList();
        this.onUiChanged(false, applyImmediately ? 'immediately' : 'debounce');
        this.showOrHideResults();
    };
    SetFilter.prototype.focusRowIfAlive = function (rowIndex) {
        var _this = this;
        if (rowIndex == null) {
            return;
        }
        window.setTimeout(function () {
            if (!_this.virtualList) {
                throw new Error('Virtual list has not been created.');
            }
            if (_this.isAlive()) {
                _this.virtualList.focusRow(rowIndex);
            }
        }, 0);
    };
    SetFilter.prototype.onSelectAll = function (isSelected) {
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        if (!this.virtualList) {
            throw new Error('Virtual list has not been created.');
        }
        if (isSelected) {
            this.valueModel.selectAllMatchingMiniFilter();
        }
        else {
            this.valueModel.deselectAllMatchingMiniFilter();
        }
        this.refreshAfterSelection();
    };
    SetFilter.prototype.onGroupItemSelected = function (item, isSelected) {
        var _this = this;
        var recursiveGroupSelection = function (i) {
            if (i.children) {
                i.children.forEach(function (childItem) { return recursiveGroupSelection(childItem); });
            }
            else {
                _this.selectItem(i.key, isSelected);
            }
        };
        recursiveGroupSelection(item);
        this.refreshAfterSelection();
    };
    SetFilter.prototype.onItemSelected = function (key, isSelected) {
        if (!this.valueModel) {
            throw new Error('Value model has not been created.');
        }
        if (!this.virtualList) {
            throw new Error('Virtual list has not been created.');
        }
        this.selectItem(key, isSelected);
        this.refreshAfterSelection();
    };
    SetFilter.prototype.selectItem = function (key, isSelected) {
        if (isSelected) {
            this.valueModel.selectKey(key);
        }
        else {
            this.valueModel.deselectKey(key);
        }
    };
    SetFilter.prototype.onExpandAll = function (item, isExpanded) {
        var recursiveExpansion = function (i) {
            if (i.filterPasses && i.available && i.children) {
                i.children.forEach(function (childItem) { return recursiveExpansion(childItem); });
                i.expanded = isExpanded;
            }
        };
        recursiveExpansion(item);
        this.refreshAfterExpansion();
    };
    SetFilter.prototype.onExpandedChanged = function (item, isExpanded) {
        item.expanded = isExpanded;
        this.refreshAfterExpansion();
    };
    SetFilter.prototype.refreshAfterExpansion = function () {
        var focusedRow = this.virtualList.getLastFocusedRow();
        this.valueModel.updateDisplayedValues('expansion');
        this.checkAndRefreshVirtualList();
        this.focusRowIfAlive(focusedRow);
    };
    SetFilter.prototype.refreshAfterSelection = function () {
        var focusedRow = this.virtualList.getLastFocusedRow();
        this.checkAndRefreshVirtualList();
        this.onUiChanged();
        this.focusRowIfAlive(focusedRow);
    };
    SetFilter.prototype.setMiniFilter = function (newMiniFilter) {
        this.eMiniFilter.setValue(newMiniFilter);
        this.onMiniFilterInput();
    };
    SetFilter.prototype.getMiniFilter = function () {
        return this.valueModel ? this.valueModel.getMiniFilter() : null;
    };
    SetFilter.prototype.checkAndRefreshVirtualList = function () {
        if (!this.virtualList) {
            throw new Error('Virtual list has not been created.');
        }
        this.virtualList.refresh(!this.hardRefreshVirtualList);
        if (this.hardRefreshVirtualList) {
            this.hardRefreshVirtualList = false;
        }
    };
    SetFilter.prototype.getFilterKeys = function () {
        return this.valueModel ? this.valueModel.getKeys() : [];
    };
    SetFilter.prototype.getFilterValues = function () {
        return this.valueModel ? this.valueModel.getValues() : [];
    };
    SetFilter.prototype.getValues = function () {
        return this.getFilterKeys();
    };
    SetFilter.prototype.refreshVirtualList = function () {
        if (this.setFilterParams && this.setFilterParams.refreshValuesOnOpen) {
            this.refreshFilterValues();
        }
        else {
            this.checkAndRefreshVirtualList();
        }
    };
    SetFilter.prototype.translateForSetFilter = function (key) {
        var translate = this.localeService.getLocaleTextFunc();
        return translate(key, DEFAULT_LOCALE_TEXT[key]);
    };
    SetFilter.prototype.isSelectAllSelected = function () {
        if (!this.setFilterParams || !this.valueModel) {
            return false;
        }
        if (!this.setFilterParams.defaultToNothingSelected) {
            // everything selected by default
            if (this.valueModel.hasSelections() && this.valueModel.isNothingVisibleSelected()) {
                return false;
            }
            if (this.valueModel.isEverythingVisibleSelected()) {
                return true;
            }
        }
        else {
            // nothing selected by default
            if (this.valueModel.hasSelections() && this.valueModel.isEverythingVisibleSelected()) {
                return true;
            }
            if (this.valueModel.isNothingVisibleSelected()) {
                return false;
            }
        }
        // returning `undefined` means the checkbox status is indeterminate.
        return undefined;
    };
    SetFilter.prototype.areAllChildrenSelected = function (item) {
        var _this = this;
        var recursiveChildSelectionCheck = function (i) {
            if (i.children) {
                var someTrue_1 = false;
                var someFalse_1 = false;
                var mixed = i.children.some(function (child) {
                    if (!child.filterPasses || !child.available) {
                        return false;
                    }
                    var childSelected = recursiveChildSelectionCheck(child);
                    if (childSelected === undefined) {
                        return true;
                    }
                    if (childSelected) {
                        someTrue_1 = true;
                    }
                    else {
                        someFalse_1 = true;
                    }
                    return someTrue_1 && someFalse_1;
                });
                // returning `undefined` means the checkbox status is indeterminate.
                // if not mixed and some true, all must be true
                return mixed ? undefined : someTrue_1;
            }
            else {
                return _this.valueModel.isKeySelected(i.key);
            }
        };
        if (!this.setFilterParams.defaultToNothingSelected) {
            // everything selected by default
            return recursiveChildSelectionCheck(item);
        }
        else {
            // nothing selected by default
            return this.valueModel.hasSelections() && recursiveChildSelectionCheck(item);
        }
    };
    SetFilter.prototype.destroy = function () {
        if (this.virtualList != null) {
            this.virtualList.destroy();
            this.virtualList = null;
        }
        _super.prototype.destroy.call(this);
    };
    SetFilter.prototype.caseFormat = function (valueToFormat) {
        if (valueToFormat == null || typeof valueToFormat !== 'string') {
            return valueToFormat;
        }
        return this.caseSensitive ? valueToFormat : valueToFormat.toUpperCase();
    };
    SetFilter.prototype.resetExpansion = function () {
        var _a, _b;
        if (!((_a = this.setFilterParams) === null || _a === void 0 ? void 0 : _a.treeList)) {
            return;
        }
        var selectAllItem = (_b = this.valueModel) === null || _b === void 0 ? void 0 : _b.getSelectAllItem();
        if (this.isSetFilterModelTreeItem(selectAllItem)) {
            var recursiveCollapse_1 = function (i) {
                if (i.children) {
                    i.children.forEach(function (childItem) { return recursiveCollapse_1(childItem); });
                    i.expanded = false;
                }
            };
            recursiveCollapse_1(selectAllItem);
            this.valueModel.updateDisplayedValues('expansion');
        }
    };
    SetFilter.prototype.getModelAsString = function (model) {
        return this.filterModelFormatter.getModelAsString(model, this);
    };
    SetFilter.prototype.getPositionableElement = function () {
        return this.eSetFilterList;
    };
    __decorate$c([
        RefSelector('eMiniFilter')
    ], SetFilter.prototype, "eMiniFilter", void 0);
    __decorate$c([
        RefSelector('eFilterLoading')
    ], SetFilter.prototype, "eFilterLoading", void 0);
    __decorate$c([
        RefSelector('eSetFilterList')
    ], SetFilter.prototype, "eSetFilterList", void 0);
    __decorate$c([
        RefSelector('eFilterNoMatches')
    ], SetFilter.prototype, "eNoMatches", void 0);
    __decorate$c([
        Autowired('valueFormatterService')
    ], SetFilter.prototype, "valueFormatterService", void 0);
    __decorate$c([
        Autowired('columnModel')
    ], SetFilter.prototype, "columnModel", void 0);
    __decorate$c([
        Autowired('valueService')
    ], SetFilter.prototype, "valueService", void 0);
    return SetFilter;
}(ProvidedFilter));
var ModelWrapper = /** @class */ (function () {
    function ModelWrapper(model) {
        this.model = model;
    }
    ModelWrapper.prototype.getRowCount = function () {
        return this.model.getDisplayedValueCount();
    };
    ModelWrapper.prototype.getRow = function (index) {
        return this.model.getDisplayedItem(index);
    };
    ModelWrapper.prototype.areRowsEqual = function (oldRow, newRow) {
        return oldRow === newRow;
    };
    return ModelWrapper;
}());
var ModelWrapperWithSelectAll = /** @class */ (function () {
    function ModelWrapperWithSelectAll(model, isSelectAllSelected) {
        this.model = model;
        this.isSelectAllSelected = isSelectAllSelected;
    }
    ModelWrapperWithSelectAll.prototype.getRowCount = function () {
        var showAddCurrentSelectionToFilter = this.model.showAddCurrentSelectionToFilter();
        var outboundItems = showAddCurrentSelectionToFilter ? 2 : 1;
        return this.model.getDisplayedValueCount() + outboundItems;
    };
    ModelWrapperWithSelectAll.prototype.getRow = function (index) {
        if (index === 0) {
            return this.model.getSelectAllItem();
        }
        var showAddCurrentSelectionToFilter = this.model.showAddCurrentSelectionToFilter();
        var outboundItems = showAddCurrentSelectionToFilter ? 2 : 1;
        if (index === 1 && showAddCurrentSelectionToFilter) {
            return this.model.getAddSelectionToFilterItem();
        }
        return this.model.getDisplayedItem(index - outboundItems);
    };
    ModelWrapperWithSelectAll.prototype.areRowsEqual = function (oldRow, newRow) {
        return oldRow === newRow;
    };
    return ModelWrapperWithSelectAll;
}());
// isRowSelected is used by VirtualList to add aria tags for flat lists. We want to suppress this when using trees
var TreeModelWrapper = /** @class */ (function () {
    function TreeModelWrapper(model) {
        this.model = model;
    }
    TreeModelWrapper.prototype.getRowCount = function () {
        return this.model.getRowCount();
    };
    TreeModelWrapper.prototype.getRow = function (index) {
        return this.model.getRow(index);
    };
    TreeModelWrapper.prototype.areRowsEqual = function (oldRow, newRow) {
        if (oldRow == null && newRow == null) {
            return true;
        }
        return oldRow != null && newRow != null && oldRow.treeKey === newRow.treeKey && oldRow.depth === newRow.depth;
    };
    return TreeModelWrapper;
}());

var __extends$g = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$b = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var SetFloatingFilterComp = /** @class */ (function (_super) {
    __extends$g(SetFloatingFilterComp, _super);
    function SetFloatingFilterComp() {
        var _this = _super.call(this, /* html */ "\n            <div class=\"ag-floating-filter-input ag-set-floating-filter-input\" role=\"presentation\">\n                <ag-input-text-field ref=\"eFloatingFilterText\"></ag-input-text-field>\n            </div>") || this;
        _this.availableValuesListenerAdded = false;
        _this.filterModelFormatter = new SetFilterModelFormatter();
        return _this;
    }
    // this is a user component, and IComponent has "public destroy()" as part of the interface.
    // so we need to override destroy() just to make the method public.
    SetFloatingFilterComp.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
    };
    SetFloatingFilterComp.prototype.init = function (params) {
        var _this = this;
        this.params = params;
        this.eFloatingFilterText
            .setDisabled(true)
            .addGuiEventListener('click', function () { return _this.params.showParentFilter(); });
        this.setParams(params);
    };
    SetFloatingFilterComp.prototype.setParams = function (params) {
        var displayName = this.columnModel.getDisplayNameForColumn(params.column, 'header', true);
        var translate = this.localeService.getLocaleTextFunc();
        this.eFloatingFilterText.setInputAriaLabel("".concat(displayName, " ").concat(translate('ariaFilterInput', 'Filter Input')));
    };
    SetFloatingFilterComp.prototype.onParamsUpdated = function (params) {
        this.refresh(params);
    };
    SetFloatingFilterComp.prototype.refresh = function (params) {
        this.params = params;
        this.setParams(params);
    };
    SetFloatingFilterComp.prototype.onParentModelChanged = function (parentModel) {
        this.updateFloatingFilterText(parentModel);
    };
    SetFloatingFilterComp.prototype.parentSetFilterInstance = function (cb) {
        this.params.parentFilterInstance(function (filter) {
            if (!(filter instanceof SetFilter)) {
                throw new Error('AG Grid - SetFloatingFilter expects SetFilter as its parent');
            }
            cb(filter);
        });
    };
    SetFloatingFilterComp.prototype.addAvailableValuesListener = function () {
        var _this = this;
        this.parentSetFilterInstance(function (setFilter) {
            var setValueModel = setFilter.getValueModel();
            if (!setValueModel) {
                return;
            }
            // unlike other filters, what we show in the floating filter can be different, even
            // if another filter changes. this is due to how set filter restricts its values based
            // on selections in other filters, e.g. if you filter Language to English, then the set filter
            // on Country will only show English speaking countries. Thus the list of items to show
            // in the floating filter can change.
            _this.addManagedListener(setValueModel, SetValueModel.EVENT_AVAILABLE_VALUES_CHANGED, function () { return _this.updateFloatingFilterText(); });
        });
        this.availableValuesListenerAdded = true;
    };
    SetFloatingFilterComp.prototype.updateFloatingFilterText = function (parentModel) {
        var _this = this;
        if (!this.availableValuesListenerAdded) {
            this.addAvailableValuesListener();
        }
        this.parentSetFilterInstance(function (setFilter) {
            _this.eFloatingFilterText.setValue(_this.filterModelFormatter.getModelAsString(parentModel, setFilter));
        });
    };
    __decorate$b([
        RefSelector('eFloatingFilterText')
    ], SetFloatingFilterComp.prototype, "eFloatingFilterText", void 0);
    __decorate$b([
        Autowired('columnModel')
    ], SetFloatingFilterComp.prototype, "columnModel", void 0);
    return SetFloatingFilterComp;
}(Component));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$3 = '31.0.2';

var SetFilterModule = {
    version: VERSION$3,
    moduleName: ModuleNames.SetFilterModule,
    beans: [],
    userComponents: [
        { componentName: 'agSetColumnFilter', componentClass: SetFilter },
        { componentName: 'agSetColumnFloatingFilter', componentClass: SetFloatingFilterComp },
    ],
    dependantModules: [
        EnterpriseCoreModule
    ]
};

var __extends$f = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$a = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var StatusBarService = /** @class */ (function (_super) {
    __extends$f(StatusBarService, _super);
    // tslint:disable-next-line
    function StatusBarService() {
        var _this = _super.call(this) || this;
        _this.allComponents = new Map();
        return _this;
    }
    StatusBarService.prototype.registerStatusPanel = function (key, component) {
        this.allComponents.set(key, component);
    };
    StatusBarService.prototype.unregisterStatusPanel = function (key) {
        this.allComponents.delete(key);
    };
    StatusBarService.prototype.unregisterAllComponents = function () {
        this.allComponents.clear();
    };
    StatusBarService.prototype.getStatusPanel = function (key) {
        return this.allComponents.get(key);
    };
    StatusBarService.prototype.destroy = function () {
        this.unregisterAllComponents();
        _super.prototype.destroy.call(this);
    };
    StatusBarService = __decorate$a([
        Bean('statusBarService')
    ], StatusBarService);
    return StatusBarService;
}(BeanStub));

var __extends$e = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$9 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var StatusBar = /** @class */ (function (_super) {
    __extends$e(StatusBar, _super);
    function StatusBar() {
        var _this = _super.call(this, StatusBar.TEMPLATE) || this;
        _this.compDestroyFunctions = {};
        return _this;
    }
    StatusBar.prototype.postConstruct = function () {
        this.processStatusPanels(new Map());
        this.addManagedPropertyListeners(['statusBar'], this.handleStatusBarChanged.bind(this));
    };
    StatusBar.prototype.processStatusPanels = function (existingStatusPanelsToReuse) {
        var _a;
        var statusPanels = (_a = this.gridOptionsService.get('statusBar')) === null || _a === void 0 ? void 0 : _a.statusPanels;
        if (statusPanels) {
            var leftStatusPanelComponents = statusPanels
                .filter(function (componentConfig) { return componentConfig.align === 'left'; });
            this.createAndRenderComponents(leftStatusPanelComponents, this.eStatusBarLeft, existingStatusPanelsToReuse);
            var centerStatusPanelComponents = statusPanels
                .filter(function (componentConfig) { return componentConfig.align === 'center'; });
            this.createAndRenderComponents(centerStatusPanelComponents, this.eStatusBarCenter, existingStatusPanelsToReuse);
            var rightStatusPanelComponents = statusPanels
                .filter(function (componentConfig) { return (!componentConfig.align || componentConfig.align === 'right'); });
            this.createAndRenderComponents(rightStatusPanelComponents, this.eStatusBarRight, existingStatusPanelsToReuse);
        }
        else {
            this.setDisplayed(false);
        }
    };
    StatusBar.prototype.handleStatusBarChanged = function () {
        var _this = this;
        var _a;
        var statusPanels = (_a = this.gridOptionsService.get('statusBar')) === null || _a === void 0 ? void 0 : _a.statusPanels;
        var validStatusBarPanelsProvided = Array.isArray(statusPanels) && statusPanels.length > 0;
        this.setDisplayed(validStatusBarPanelsProvided);
        var existingStatusPanelsToReuse = new Map();
        if (validStatusBarPanelsProvided) {
            statusPanels.forEach(function (statusPanelConfig) {
                var _a, _b;
                var key = (_a = statusPanelConfig.key) !== null && _a !== void 0 ? _a : statusPanelConfig.statusPanel;
                var existingStatusPanel = _this.statusBarService.getStatusPanel(key);
                if (existingStatusPanel === null || existingStatusPanel === void 0 ? void 0 : existingStatusPanel.refresh) {
                    var newParams = _this.gridOptionsService.addGridCommonParams((_b = statusPanelConfig.statusPanelParams) !== null && _b !== void 0 ? _b : {});
                    var hasRefreshed = existingStatusPanel.refresh(newParams);
                    if (hasRefreshed) {
                        existingStatusPanelsToReuse.set(key, existingStatusPanel);
                        delete _this.compDestroyFunctions[key];
                        _.removeFromParent(existingStatusPanel.getGui());
                    }
                }
            });
        }
        this.resetStatusBar();
        if (validStatusBarPanelsProvided) {
            this.processStatusPanels(existingStatusPanelsToReuse);
        }
    };
    StatusBar.prototype.resetStatusBar = function () {
        this.eStatusBarLeft.innerHTML = '';
        this.eStatusBarCenter.innerHTML = '';
        this.eStatusBarRight.innerHTML = '';
        this.destroyComponents();
        this.statusBarService.unregisterAllComponents();
    };
    StatusBar.prototype.destroyComponents = function () {
        Object.values(this.compDestroyFunctions).forEach(function (func) { return func(); });
        this.compDestroyFunctions = {};
    };
    StatusBar.prototype.createAndRenderComponents = function (statusBarComponents, ePanelComponent, existingStatusPanelsToReuse) {
        var _this = this;
        var componentDetails = [];
        statusBarComponents.forEach(function (componentConfig) {
            // default to the component name if no key supplied
            var key = componentConfig.key || componentConfig.statusPanel;
            var existingStatusPanel = existingStatusPanelsToReuse.get(key);
            var promise;
            if (existingStatusPanel) {
                promise = AgPromise.resolve(existingStatusPanel);
            }
            else {
                var params = {};
                var compDetails = _this.userComponentFactory.getStatusPanelCompDetails(componentConfig, params);
                promise = compDetails.newAgStackInstance();
                if (!promise) {
                    return;
                }
            }
            componentDetails.push({
                key: key,
                promise: promise
            });
        });
        AgPromise.all(componentDetails.map(function (details) { return details.promise; }))
            .then(function () {
            componentDetails.forEach(function (componentDetail) {
                componentDetail.promise.then(function (component) {
                    var destroyFunc = function () {
                        _this.getContext().destroyBean(component);
                    };
                    if (_this.isAlive()) {
                        _this.statusBarService.registerStatusPanel(componentDetail.key, component);
                        ePanelComponent.appendChild(component.getGui());
                        _this.compDestroyFunctions[componentDetail.key] = destroyFunc;
                    }
                    else {
                        destroyFunc();
                    }
                });
            });
        });
    };
    StatusBar.TEMPLATE = "<div class=\"ag-status-bar\">\n            <div ref=\"eStatusBarLeft\" class=\"ag-status-bar-left\" role=\"status\"></div>\n            <div ref=\"eStatusBarCenter\" class=\"ag-status-bar-center\" role=\"status\"></div>\n            <div ref=\"eStatusBarRight\" class=\"ag-status-bar-right\" role=\"status\"></div>\n        </div>";
    __decorate$9([
        Autowired('userComponentFactory')
    ], StatusBar.prototype, "userComponentFactory", void 0);
    __decorate$9([
        Autowired('statusBarService')
    ], StatusBar.prototype, "statusBarService", void 0);
    __decorate$9([
        RefSelector('eStatusBarLeft')
    ], StatusBar.prototype, "eStatusBarLeft", void 0);
    __decorate$9([
        RefSelector('eStatusBarCenter')
    ], StatusBar.prototype, "eStatusBarCenter", void 0);
    __decorate$9([
        RefSelector('eStatusBarRight')
    ], StatusBar.prototype, "eStatusBarRight", void 0);
    __decorate$9([
        PostConstruct
    ], StatusBar.prototype, "postConstruct", null);
    __decorate$9([
        PreDestroy
    ], StatusBar.prototype, "destroyComponents", null);
    return StatusBar;
}(Component));

var __extends$d = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$8 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var NameValueComp = /** @class */ (function (_super) {
    __extends$d(NameValueComp, _super);
    function NameValueComp() {
        return _super.call(this, NameValueComp.TEMPLATE) || this;
    }
    NameValueComp.prototype.setLabel = function (key, defaultValue) {
        // we want to hide until the first value comes in
        this.setDisplayed(false);
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        this.eLabel.innerHTML = localeTextFunc(key, defaultValue);
    };
    NameValueComp.prototype.setValue = function (value) {
        this.eValue.innerHTML = value;
    };
    NameValueComp.TEMPLATE = "<div class=\"ag-status-name-value\">\n            <span ref=\"eLabel\"></span>:&nbsp;\n            <span ref=\"eValue\" class=\"ag-status-name-value-value\"></span>\n        </div>";
    __decorate$8([
        RefSelector('eLabel')
    ], NameValueComp.prototype, "eLabel", void 0);
    __decorate$8([
        RefSelector('eValue')
    ], NameValueComp.prototype, "eValue", void 0);
    return NameValueComp;
}(Component));

var __extends$c = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$7 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var TotalAndFilteredRowsComp = /** @class */ (function (_super) {
    __extends$c(TotalAndFilteredRowsComp, _super);
    function TotalAndFilteredRowsComp() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TotalAndFilteredRowsComp.prototype.postConstruct = function () {
        // this component is only really useful with client side row model
        if (this.gridApi.getModel().getType() !== 'clientSide') {
            console.warn("AG Grid: agTotalAndFilteredRowCountComponent should only be used with the client side row model.");
            return;
        }
        this.setLabel('totalAndFilteredRows', 'Rows');
        this.addCssClass('ag-status-panel');
        this.addCssClass('ag-status-panel-total-and-filtered-row-count');
        this.setDisplayed(true);
        this.addManagedListener(this.eventService, Events.EVENT_MODEL_UPDATED, this.onDataChanged.bind(this));
        this.onDataChanged();
    };
    TotalAndFilteredRowsComp.prototype.onDataChanged = function () {
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        var thousandSeparator = localeTextFunc('thousandSeparator', ',');
        var decimalSeparator = localeTextFunc('decimalSeparator', '.');
        var rowCount = _.formatNumberCommas(this.getFilteredRowCountValue(), thousandSeparator, decimalSeparator);
        var totalRowCount = _.formatNumberCommas(this.getTotalRowCount(), thousandSeparator, decimalSeparator);
        if (rowCount === totalRowCount) {
            this.setValue(rowCount);
        }
        else {
            var localeTextFunc_1 = this.localeService.getLocaleTextFunc();
            this.setValue("".concat(rowCount, " ").concat(localeTextFunc_1('of', 'of'), " ").concat(totalRowCount));
        }
    };
    TotalAndFilteredRowsComp.prototype.getFilteredRowCountValue = function () {
        var filteredRowCount = 0;
        this.gridApi.forEachNodeAfterFilter(function (node) {
            if (!node.group) {
                filteredRowCount++;
            }
        });
        return filteredRowCount;
    };
    TotalAndFilteredRowsComp.prototype.getTotalRowCount = function () {
        var totalRowCount = 0;
        this.gridApi.forEachNode(function (node) {
            if (!node.group) {
                totalRowCount++;
            }
        });
        return totalRowCount;
    };
    TotalAndFilteredRowsComp.prototype.init = function () { };
    TotalAndFilteredRowsComp.prototype.refresh = function () {
        return true;
    };
    // this is a user component, and IComponent has "public destroy()" as part of the interface.
    // so we need to override destroy() just to make the method public.
    TotalAndFilteredRowsComp.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
    };
    __decorate$7([
        Autowired('gridApi')
    ], TotalAndFilteredRowsComp.prototype, "gridApi", void 0);
    __decorate$7([
        PostConstruct
    ], TotalAndFilteredRowsComp.prototype, "postConstruct", null);
    return TotalAndFilteredRowsComp;
}(NameValueComp));

var __extends$b = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$6 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var FilteredRowsComp = /** @class */ (function (_super) {
    __extends$b(FilteredRowsComp, _super);
    function FilteredRowsComp() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FilteredRowsComp.prototype.postConstruct = function () {
        this.setLabel('filteredRows', 'Filtered');
        // this component is only really useful with client side row model
        if (this.gridApi.getModel().getType() !== 'clientSide') {
            console.warn("AG Grid: agFilteredRowCountComponent should only be used with the client side row model.");
            return;
        }
        this.addCssClass('ag-status-panel');
        this.addCssClass('ag-status-panel-filtered-row-count');
        this.setDisplayed(true);
        var listener = this.onDataChanged.bind(this);
        this.addManagedListener(this.eventService, Events.EVENT_MODEL_UPDATED, listener);
        listener();
    };
    FilteredRowsComp.prototype.onDataChanged = function () {
        var totalRowCountValue = this.getTotalRowCountValue();
        var filteredRowCountValue = this.getFilteredRowCountValue();
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        var thousandSeparator = localeTextFunc('thousandSeparator', ',');
        var decimalSeparator = localeTextFunc('decimalSeparator', '.');
        this.setValue(_.formatNumberCommas(filteredRowCountValue, thousandSeparator, decimalSeparator));
        this.setDisplayed(totalRowCountValue !== filteredRowCountValue);
    };
    FilteredRowsComp.prototype.getTotalRowCountValue = function () {
        var totalRowCount = 0;
        this.gridApi.forEachNode(function (node) { return totalRowCount += 1; });
        return totalRowCount;
    };
    FilteredRowsComp.prototype.getFilteredRowCountValue = function () {
        var filteredRowCount = 0;
        this.gridApi.forEachNodeAfterFilter(function (node) {
            if (!node.group) {
                filteredRowCount += 1;
            }
        });
        return filteredRowCount;
    };
    FilteredRowsComp.prototype.init = function () { };
    FilteredRowsComp.prototype.refresh = function () {
        return true;
    };
    // this is a user component, and IComponent has "public destroy()" as part of the interface.
    // so we need to override destroy() just to make the method public.
    FilteredRowsComp.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
    };
    __decorate$6([
        Autowired('gridApi')
    ], FilteredRowsComp.prototype, "gridApi", void 0);
    __decorate$6([
        PostConstruct
    ], FilteredRowsComp.prototype, "postConstruct", null);
    return FilteredRowsComp;
}(NameValueComp));

var __extends$a = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$5 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var TotalRowsComp = /** @class */ (function (_super) {
    __extends$a(TotalRowsComp, _super);
    function TotalRowsComp() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TotalRowsComp.prototype.postConstruct = function () {
        this.setLabel('totalRows', 'Total Rows');
        // this component is only really useful with client side row model
        if (this.gridApi.getModel().getType() !== 'clientSide') {
            console.warn("AG Grid: agTotalRowCountComponent should only be used with the client side row model.");
            return;
        }
        this.addCssClass('ag-status-panel');
        this.addCssClass('ag-status-panel-total-row-count');
        this.setDisplayed(true);
        this.addManagedListener(this.eventService, Events.EVENT_MODEL_UPDATED, this.onDataChanged.bind(this));
        this.onDataChanged();
    };
    TotalRowsComp.prototype.onDataChanged = function () {
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        var thousandSeparator = localeTextFunc('thousandSeparator', ',');
        var decimalSeparator = localeTextFunc('decimalSeparator', '.');
        this.setValue(_.formatNumberCommas(this.getRowCountValue(), thousandSeparator, decimalSeparator));
    };
    TotalRowsComp.prototype.getRowCountValue = function () {
        var totalRowCount = 0;
        this.gridApi.forEachLeafNode(function (node) { return totalRowCount += 1; });
        return totalRowCount;
    };
    TotalRowsComp.prototype.init = function () {
    };
    TotalRowsComp.prototype.refresh = function () {
        return true;
    };
    // this is a user component, and IComponent has "public destroy()" as part of the interface.
    // so we need to override destroy() just to make the method public.
    TotalRowsComp.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
    };
    __decorate$5([
        Autowired('gridApi')
    ], TotalRowsComp.prototype, "gridApi", void 0);
    __decorate$5([
        PostConstruct
    ], TotalRowsComp.prototype, "postConstruct", null);
    return TotalRowsComp;
}(NameValueComp));

var __extends$9 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$4 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var SelectedRowsComp = /** @class */ (function (_super) {
    __extends$9(SelectedRowsComp, _super);
    function SelectedRowsComp() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SelectedRowsComp.prototype.postConstruct = function () {
        if (!this.isValidRowModel()) {
            console.warn("AG Grid: agSelectedRowCountComponent should only be used with the client and server side row model.");
            return;
        }
        this.setLabel('selectedRows', 'Selected');
        this.addCssClass('ag-status-panel');
        this.addCssClass('ag-status-panel-selected-row-count');
        this.onRowSelectionChanged();
        var eventListener = this.onRowSelectionChanged.bind(this);
        this.addManagedListener(this.eventService, Events.EVENT_MODEL_UPDATED, eventListener);
        this.addManagedListener(this.eventService, Events.EVENT_SELECTION_CHANGED, eventListener);
    };
    SelectedRowsComp.prototype.isValidRowModel = function () {
        // this component is only really useful with client or server side rowmodels
        var rowModelType = this.gridApi.getModel().getType();
        return rowModelType === 'clientSide' || rowModelType === 'serverSide';
    };
    SelectedRowsComp.prototype.onRowSelectionChanged = function () {
        var selectedRowCount = this.selectionService.getSelectionCount();
        if (selectedRowCount < 0) {
            this.setValue('?');
            this.setDisplayed(true);
            return;
        }
        var localeTextFunc = this.localeService.getLocaleTextFunc();
        var thousandSeparator = localeTextFunc('thousandSeparator', ',');
        var decimalSeparator = localeTextFunc('decimalSeparator', '.');
        this.setValue(_.formatNumberCommas(selectedRowCount, thousandSeparator, decimalSeparator));
        this.setDisplayed(selectedRowCount > 0);
    };
    SelectedRowsComp.prototype.init = function () {
    };
    SelectedRowsComp.prototype.refresh = function () {
        return true;
    };
    // this is a user component, and IComponent has "public destroy()" as part of the interface.
    // so we need to override destroy() just to make the method public.
    SelectedRowsComp.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
    };
    __decorate$4([
        Autowired('gridApi')
    ], SelectedRowsComp.prototype, "gridApi", void 0);
    __decorate$4([
        Autowired('selectionService')
    ], SelectedRowsComp.prototype, "selectionService", void 0);
    __decorate$4([
        PostConstruct
    ], SelectedRowsComp.prototype, "postConstruct", null);
    return SelectedRowsComp;
}(NameValueComp));

var __extends$8 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$3 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AggregationComp = /** @class */ (function (_super) {
    __extends$8(AggregationComp, _super);
    function AggregationComp() {
        return _super.call(this, AggregationComp.TEMPLATE) || this;
    }
    // this is a user component, and IComponent has "public destroy()" as part of the interface.
    // so we need to override destroy() just to make the method public.
    AggregationComp.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
    };
    AggregationComp.prototype.postConstruct = function () {
        if (!this.isValidRowModel()) {
            console.warn("AG Grid: agAggregationComponent should only be used with the client and server side row model.");
            return;
        }
        this.avgAggregationComp.setLabel('avg', 'Average');
        this.countAggregationComp.setLabel('count', 'Count');
        this.minAggregationComp.setLabel('min', 'Min');
        this.maxAggregationComp.setLabel('max', 'Max');
        this.sumAggregationComp.setLabel('sum', 'Sum');
        this.addManagedListener(this.eventService, Events.EVENT_RANGE_SELECTION_CHANGED, this.onRangeSelectionChanged.bind(this));
        this.addManagedListener(this.eventService, Events.EVENT_MODEL_UPDATED, this.onRangeSelectionChanged.bind(this));
    };
    AggregationComp.prototype.isValidRowModel = function () {
        // this component is only really useful with client or server side rowmodels
        var rowModelType = this.gridApi.getModel().getType();
        return rowModelType === 'clientSide' || rowModelType === 'serverSide';
    };
    AggregationComp.prototype.init = function (params) {
        this.params = params;
    };
    AggregationComp.prototype.refresh = function (params) {
        this.params = params;
        this.onRangeSelectionChanged();
        return true;
    };
    AggregationComp.prototype.setAggregationComponentValue = function (aggFuncName, value, visible) {
        var _a;
        var statusBarValueComponent = this.getAllowedAggregationValueComponent(aggFuncName);
        if (_.exists(statusBarValueComponent) && statusBarValueComponent) {
            var localeTextFunc = this.localeService.getLocaleTextFunc();
            var thousandSeparator = localeTextFunc('thousandSeparator', ',');
            var decimalSeparator = localeTextFunc('decimalSeparator', '.');
            statusBarValueComponent.setValue(_.formatNumberTwoDecimalPlacesAndCommas(value, thousandSeparator, decimalSeparator));
            statusBarValueComponent.setDisplayed(visible);
        }
        else {
            // might have previously been visible, so hide now
            (_a = this.getAggregationValueComponent(aggFuncName)) === null || _a === void 0 ? void 0 : _a.setDisplayed(false);
        }
    };
    AggregationComp.prototype.getAllowedAggregationValueComponent = function (aggFuncName) {
        // if the user has specified the agAggregationPanelComp but no aggFuncs we show the all
        // if the user has specified the agAggregationPanelComp and aggFuncs, then we only show the aggFuncs listed
        var aggFuncs = this.params.aggFuncs;
        if (!aggFuncs || aggFuncs.includes(aggFuncName)) {
            return this.getAggregationValueComponent(aggFuncName);
        }
        // either we can't find it (which would indicate a typo or similar user side), or the user has deliberately
        // not listed the component in aggFuncs
        return null;
    };
    AggregationComp.prototype.getAggregationValueComponent = function (aggFuncName) {
        // converts user supplied agg name to our reference - eg: sum => sumAggregationComp
        var refComponentName = "".concat(aggFuncName, "AggregationComp");
        return this[refComponentName];
    };
    AggregationComp.prototype.onRangeSelectionChanged = function () {
        var _this = this;
        var cellRanges = this.rangeService ? this.rangeService.getCellRanges() : undefined;
        var sum = 0;
        var count = 0;
        var numberCount = 0;
        var min = null;
        var max = null;
        var cellsSoFar = {};
        if (cellRanges && !_.missingOrEmpty(cellRanges)) {
            cellRanges.forEach(function (cellRange) {
                var currentRow = _this.rangeService.getRangeStartRow(cellRange);
                var lastRow = _this.rangeService.getRangeEndRow(cellRange);
                while (true) {
                    var finishedAllRows = _.missing(currentRow) || !currentRow || _this.rowPositionUtils.before(lastRow, currentRow);
                    if (finishedAllRows || !currentRow || !cellRange.columns) {
                        break;
                    }
                    cellRange.columns.forEach(function (col) {
                        if (currentRow === null) {
                            return;
                        }
                        // we only want to include each cell once, in case a cell is in multiple ranges
                        var cellId = _this.cellPositionUtils.createId({
                            rowPinned: currentRow.rowPinned,
                            column: col,
                            rowIndex: currentRow.rowIndex
                        });
                        if (cellsSoFar[cellId]) {
                            return;
                        }
                        cellsSoFar[cellId] = true;
                        var rowNode = _this.rowRenderer.getRowNode(currentRow);
                        if (_.missing(rowNode)) {
                            return;
                        }
                        var value = _this.valueService.getValue(col, rowNode);
                        // if empty cell, skip it, doesn't impact count or anything
                        if (_.missing(value) || value === '') {
                            return;
                        }
                        count++;
                        // see if value is wrapped, can happen when doing count() or avg() functions
                        if (typeof value === 'object' && 'value' in value) {
                            value = value.value;
                            // ensure that the new value wouldn't have been skipped by the previous check
                            if (value === '') {
                                return;
                            }
                        }
                        if (typeof value === 'string') {
                            value = Number(value);
                        }
                        if (typeof value === 'number' && !isNaN(value)) {
                            sum += value;
                            if (max === null || value > max) {
                                max = value;
                            }
                            if (min === null || value < min) {
                                min = value;
                            }
                            numberCount++;
                        }
                    });
                    currentRow = _this.cellNavigationService.getRowBelow(currentRow);
                }
            });
        }
        var gotResult = count > 1;
        var gotNumberResult = numberCount > 1;
        // we show count even if no numbers
        this.setAggregationComponentValue('count', count, gotResult);
        // show if numbers found
        this.setAggregationComponentValue('sum', sum, gotNumberResult);
        this.setAggregationComponentValue('min', min, gotNumberResult);
        this.setAggregationComponentValue('max', max, gotNumberResult);
        this.setAggregationComponentValue('avg', (sum / numberCount), gotNumberResult);
    };
    AggregationComp.TEMPLATE = "<div class=\"ag-status-panel ag-status-panel-aggregations\">\n            <ag-name-value ref=\"avgAggregationComp\"></ag-name-value>\n            <ag-name-value ref=\"countAggregationComp\"></ag-name-value>\n            <ag-name-value ref=\"minAggregationComp\"></ag-name-value>\n            <ag-name-value ref=\"maxAggregationComp\"></ag-name-value>\n            <ag-name-value ref=\"sumAggregationComp\"></ag-name-value>\n        </div>";
    __decorate$3([
        Optional('rangeService')
    ], AggregationComp.prototype, "rangeService", void 0);
    __decorate$3([
        Autowired('valueService')
    ], AggregationComp.prototype, "valueService", void 0);
    __decorate$3([
        Autowired('cellNavigationService')
    ], AggregationComp.prototype, "cellNavigationService", void 0);
    __decorate$3([
        Autowired('rowRenderer')
    ], AggregationComp.prototype, "rowRenderer", void 0);
    __decorate$3([
        Autowired('gridApi')
    ], AggregationComp.prototype, "gridApi", void 0);
    __decorate$3([
        Autowired('cellPositionUtils')
    ], AggregationComp.prototype, "cellPositionUtils", void 0);
    __decorate$3([
        Autowired('rowPositionUtils')
    ], AggregationComp.prototype, "rowPositionUtils", void 0);
    __decorate$3([
        RefSelector('sumAggregationComp')
    ], AggregationComp.prototype, "sumAggregationComp", void 0);
    __decorate$3([
        RefSelector('countAggregationComp')
    ], AggregationComp.prototype, "countAggregationComp", void 0);
    __decorate$3([
        RefSelector('minAggregationComp')
    ], AggregationComp.prototype, "minAggregationComp", void 0);
    __decorate$3([
        RefSelector('maxAggregationComp')
    ], AggregationComp.prototype, "maxAggregationComp", void 0);
    __decorate$3([
        RefSelector('avgAggregationComp')
    ], AggregationComp.prototype, "avgAggregationComp", void 0);
    __decorate$3([
        PostConstruct
    ], AggregationComp.prototype, "postConstruct", null);
    return AggregationComp;
}(Component));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$2 = '31.0.2';

var StatusBarModule = {
    version: VERSION$2,
    moduleName: ModuleNames.StatusBarModule,
    beans: [StatusBarService],
    agStackComponents: [
        { componentName: 'AgStatusBar', componentClass: StatusBar },
        { componentName: 'AgNameValue', componentClass: NameValueComp },
    ],
    userComponents: [
        { componentName: 'agAggregationComponent', componentClass: AggregationComp },
        { componentName: 'agSelectedRowCountComponent', componentClass: SelectedRowsComp },
        { componentName: 'agTotalRowCountComponent', componentClass: TotalRowsComp },
        { componentName: 'agFilteredRowCountComponent', componentClass: FilteredRowsComp },
        { componentName: 'agTotalAndFilteredRowCountComponent', componentClass: TotalAndFilteredRowsComp }
    ],
    dependantModules: [
        EnterpriseCoreModule
    ]
};

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION$1 = '31.0.2';

var __extends$7 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$2 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ViewportRowModel = /** @class */ (function (_super) {
    __extends$7(ViewportRowModel, _super);
    function ViewportRowModel() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // rowRenderer tells us these
        _this.firstRow = -1;
        _this.lastRow = -1;
        // datasource tells us this
        _this.rowCount = -1;
        _this.rowNodesByIndex = {};
        return _this;
    }
    // we don't implement as lazy row heights is not supported in this row model
    ViewportRowModel.prototype.ensureRowHeightsValid = function (startPixel, endPixel, startLimitIndex, endLimitIndex) { return false; };
    ViewportRowModel.prototype.init = function () {
        var _this = this;
        this.rowHeight = this.gridOptionsService.getRowHeightAsNumber();
        this.addManagedListener(this.eventService, Events.EVENT_VIEWPORT_CHANGED, this.onViewportChanged.bind(this));
        this.addManagedPropertyListener('viewportDatasource', function () { return _this.updateDatasource(); });
        this.addManagedPropertyListener('rowHeight', function () {
            _this.rowHeight = _this.gridOptionsService.getRowHeightAsNumber();
            _this.updateRowHeights();
        });
    };
    ViewportRowModel.prototype.start = function () {
        this.updateDatasource();
    };
    ViewportRowModel.prototype.isLastRowIndexKnown = function () {
        return true;
    };
    ViewportRowModel.prototype.destroyDatasource = function () {
        if (!this.viewportDatasource) {
            return;
        }
        if (this.viewportDatasource.destroy) {
            this.viewportDatasource.destroy();
        }
        this.rowRenderer.datasourceChanged();
        this.firstRow = -1;
        this.lastRow = -1;
    };
    ViewportRowModel.prototype.updateDatasource = function () {
        var datasource = this.gridOptionsService.get('viewportDatasource');
        if (datasource) {
            this.setViewportDatasource(datasource);
        }
    };
    ViewportRowModel.prototype.getViewportRowModelPageSize = function () {
        return this.gridOptionsService.get('viewportRowModelPageSize');
    };
    ViewportRowModel.prototype.getViewportRowModelBufferSize = function () {
        return this.gridOptionsService.get('viewportRowModelBufferSize');
    };
    ViewportRowModel.prototype.calculateFirstRow = function (firstRenderedRow) {
        var bufferSize = this.getViewportRowModelBufferSize();
        var pageSize = this.getViewportRowModelPageSize();
        var afterBuffer = firstRenderedRow - bufferSize;
        if (afterBuffer < 0) {
            return 0;
        }
        return Math.floor(afterBuffer / pageSize) * pageSize;
    };
    ViewportRowModel.prototype.calculateLastRow = function (lastRenderedRow) {
        if (lastRenderedRow === -1) {
            return lastRenderedRow;
        }
        var bufferSize = this.getViewportRowModelBufferSize();
        var pageSize = this.getViewportRowModelPageSize();
        var afterBuffer = lastRenderedRow + bufferSize;
        var result = Math.ceil(afterBuffer / pageSize) * pageSize;
        var lastRowIndex = this.rowCount - 1;
        return Math.min(result, lastRowIndex);
    };
    ViewportRowModel.prototype.onViewportChanged = function (event) {
        var newFirst = this.calculateFirstRow(event.firstRow);
        var newLast = this.calculateLastRow(event.lastRow);
        if (this.firstRow !== newFirst || this.lastRow !== newLast) {
            this.firstRow = newFirst;
            this.lastRow = newLast;
            this.purgeRowsNotInViewport();
            if (this.viewportDatasource) {
                this.viewportDatasource.setViewportRange(this.firstRow, this.lastRow);
            }
        }
    };
    ViewportRowModel.prototype.purgeRowsNotInViewport = function () {
        var _this = this;
        Object.keys(this.rowNodesByIndex).forEach(function (indexStr) {
            var index = parseInt(indexStr, 10);
            if (index < _this.firstRow || index > _this.lastRow) {
                if (_this.isRowFocused(index)) {
                    return;
                }
                delete _this.rowNodesByIndex[index];
            }
        });
    };
    ViewportRowModel.prototype.isRowFocused = function (rowIndex) {
        var focusedCell = this.focusService.getFocusCellToUseAfterRefresh();
        if (!focusedCell) {
            return false;
        }
        if (focusedCell.rowPinned != null) {
            return false;
        }
        var hasFocus = focusedCell.rowIndex === rowIndex;
        return hasFocus;
    };
    ViewportRowModel.prototype.setViewportDatasource = function (viewportDatasource) {
        this.destroyDatasource();
        this.viewportDatasource = viewportDatasource;
        this.rowCount = -1;
        if (!viewportDatasource.init) {
            console.warn('AG Grid: viewport is missing init method.');
        }
        else {
            viewportDatasource.init({
                setRowCount: this.setRowCount.bind(this),
                setRowData: this.setRowData.bind(this),
                getRow: this.getRow.bind(this)
            });
        }
    };
    ViewportRowModel.prototype.getType = function () {
        return 'viewport';
    };
    ViewportRowModel.prototype.getRow = function (rowIndex) {
        if (!this.rowNodesByIndex[rowIndex]) {
            this.rowNodesByIndex[rowIndex] = this.createBlankRowNode(rowIndex);
        }
        return this.rowNodesByIndex[rowIndex];
    };
    ViewportRowModel.prototype.getRowNode = function (id) {
        var result;
        this.forEachNode(function (rowNode) {
            if (rowNode.id === id) {
                result = rowNode;
            }
        });
        return result;
    };
    ViewportRowModel.prototype.getRowCount = function () {
        return this.rowCount === -1 ? 0 : this.rowCount;
    };
    ViewportRowModel.prototype.getRowIndexAtPixel = function (pixel) {
        if (this.rowHeight !== 0) { // avoid divide by zero error
            return Math.floor(pixel / this.rowHeight);
        }
        return 0;
    };
    ViewportRowModel.prototype.getRowBounds = function (index) {
        return {
            rowHeight: this.rowHeight,
            rowTop: this.rowHeight * index
        };
    };
    ViewportRowModel.prototype.updateRowHeights = function () {
        var _this = this;
        this.forEachNode(function (node) {
            node.setRowHeight(_this.rowHeight);
            node.setRowTop(_this.rowHeight * node.rowIndex);
        });
        var event = {
            type: Events.EVENT_MODEL_UPDATED,
            newData: false,
            newPage: false,
            keepRenderedRows: true,
            animate: false,
        };
        this.eventService.dispatchEvent(event);
    };
    ViewportRowModel.prototype.getTopLevelRowCount = function () {
        return this.getRowCount();
    };
    ViewportRowModel.prototype.getTopLevelRowDisplayedIndex = function (topLevelIndex) {
        return topLevelIndex;
    };
    ViewportRowModel.prototype.isEmpty = function () {
        return this.rowCount > 0;
    };
    ViewportRowModel.prototype.isRowsToRender = function () {
        return this.rowCount > 0;
    };
    ViewportRowModel.prototype.getNodesInRangeForSelection = function (firstInRange, lastInRange) {
        var firstIndex = _.missing(firstInRange) ? 0 : firstInRange.rowIndex;
        var lastIndex = lastInRange.rowIndex;
        var firstNodeOutOfRange = firstIndex < this.firstRow || firstIndex > this.lastRow;
        var lastNodeOutOfRange = lastIndex < this.firstRow || lastIndex > this.lastRow;
        if (firstNodeOutOfRange || lastNodeOutOfRange) {
            return [];
        }
        var result = [];
        var startIndex = firstIndex <= lastIndex ? firstIndex : lastIndex;
        var endIndex = firstIndex <= lastIndex ? lastIndex : firstIndex;
        for (var i = startIndex; i <= endIndex; i++) {
            result.push(this.rowNodesByIndex[i]);
        }
        return result;
    };
    ViewportRowModel.prototype.forEachNode = function (callback) {
        var _this = this;
        var callbackCount = 0;
        Object.keys(this.rowNodesByIndex).forEach(function (indexStr) {
            var index = parseInt(indexStr, 10);
            var rowNode = _this.rowNodesByIndex[index];
            callback(rowNode, callbackCount);
            callbackCount++;
        });
    };
    ViewportRowModel.prototype.setRowData = function (rowData) {
        var _this = this;
        _.iterateObject(rowData, function (indexStr, dataItem) {
            var index = parseInt(indexStr, 10);
            // we should never keep rows that we didn't specifically ask for, this
            // guarantees the contract we have with the server.
            if (index >= _this.firstRow && index <= _this.lastRow) {
                var rowNode = _this.rowNodesByIndex[index];
                // the abnormal case is we requested a row even though the grid didn't need it
                // as a result of the paging and buffer (ie the row is off screen), in which
                // case we need to create a new node now
                if (_.missing(rowNode)) {
                    rowNode = _this.createBlankRowNode(index);
                    _this.rowNodesByIndex[index] = rowNode;
                }
                // now we deffo have a row node, so set in the details
                // if the grid already asked for this row (the normal case), then we would
                // of put a placeholder node in place.
                rowNode.setDataAndId(dataItem, index.toString());
            }
        });
    };
    ViewportRowModel.prototype.createBlankRowNode = function (rowIndex) {
        var rowNode = new RowNode(this.beans);
        rowNode.setRowHeight(this.rowHeight);
        rowNode.setRowTop(this.rowHeight * rowIndex);
        rowNode.setRowIndex(rowIndex);
        return rowNode;
    };
    ViewportRowModel.prototype.setRowCount = function (rowCount, keepRenderedRows) {
        if (keepRenderedRows === void 0) { keepRenderedRows = false; }
        if (rowCount === this.rowCount) {
            return;
        }
        this.rowCount = rowCount;
        this.eventService.dispatchEventOnce({
            type: Events.EVENT_ROW_COUNT_READY
        });
        var event = {
            type: Events.EVENT_MODEL_UPDATED,
            newData: false,
            newPage: false,
            keepRenderedRows: keepRenderedRows,
            animate: false
        };
        this.eventService.dispatchEvent(event);
    };
    ViewportRowModel.prototype.isRowPresent = function (rowNode) {
        var foundRowNode = this.getRowNode(rowNode.id);
        return !!foundRowNode;
    };
    __decorate$2([
        Autowired('rowRenderer')
    ], ViewportRowModel.prototype, "rowRenderer", void 0);
    __decorate$2([
        Autowired('focusService')
    ], ViewportRowModel.prototype, "focusService", void 0);
    __decorate$2([
        Autowired('beans')
    ], ViewportRowModel.prototype, "beans", void 0);
    __decorate$2([
        PostConstruct
    ], ViewportRowModel.prototype, "init", null);
    __decorate$2([
        PreDestroy
    ], ViewportRowModel.prototype, "destroyDatasource", null);
    ViewportRowModel = __decorate$2([
        Bean('rowModel')
    ], ViewportRowModel);
    return ViewportRowModel;
}(BeanStub));

var ViewportRowModelModule = {
    version: VERSION$1,
    moduleName: ModuleNames.ViewportRowModelModule,
    rowModel: 'viewport',
    beans: [ViewportRowModel],
    dependantModules: [
        EnterpriseCoreModule
    ]
};

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __reflectGet = Reflect.get;
var __knownSymbol = (name, symbol) => {
  return (symbol = Symbol[name]) ? symbol : Symbol.for("Symbol." + name);
};
var __pow = Math.pow;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __superGet = (cls, obj, key) => __reflectGet(__getProtoOf(cls), key, obj);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
var __await = function(promise, isYieldStar) {
  this[0] = promise;
  this[1] = isYieldStar;
};
var __yieldStar = (value) => {
  var obj = value[__knownSymbol("asyncIterator")];
  var isAwait = false;
  var method;
  var it = {};
  if (obj == null) {
    obj = value[__knownSymbol("iterator")]();
    method = (k) => it[k] = (x) => obj[k](x);
  } else {
    obj = obj.call(value);
    method = (k) => it[k] = (v) => {
      if (isAwait) {
        isAwait = false;
        if (k === "throw")
          throw v;
        return v;
      }
      isAwait = true;
      return {
        done: false,
        value: new __await(new Promise((resolve) => {
          var x = obj[k](v);
          if (!(x instanceof Object))
            throw TypeError("Object expected");
          resolve(x);
        }), 1)
      };
    };
  }
  return it[__knownSymbol("iterator")] = () => it, method("next"), "throw" in obj ? method("throw") : it.throw = (x) => {
    throw x;
  }, "return" in obj && method("return"), it;
};

// packages/ag-charts-community/src/util/time/index.ts
var time_exports = {};
__export(time_exports, {
  day: () => day,
  friday: () => friday,
  hour: () => hour,
  millisecond: () => millisecond,
  minute: () => minute,
  monday: () => monday,
  month: () => month,
  saturday: () => saturday,
  second: () => second,
  sunday: () => sunday,
  thursday: () => thursday,
  tuesday: () => tuesday,
  utcDay: () => utcDay,
  utcHour: () => utcHour,
  utcMinute: () => utcMinute,
  utcMonth: () => utcMonth,
  utcYear: () => utcYear,
  wednesday: () => wednesday,
  year: () => year
});

// packages/ag-charts-community/src/util/time/interval.ts
var TimeInterval = class {
  constructor(_encode, _decode, _rangeCallback) {
    this._encode = _encode;
    this._decode = _decode;
    this._rangeCallback = _rangeCallback;
  }
  /**
   * Returns a new date representing the latest interval boundary date before or equal to date.
   * For example, `day.floor(date)` typically returns 12:00 AM local time on the given date.
   * @param date
   */
  floor(date) {
    const d = new Date(date);
    const e = this._encode(d);
    return this._decode(e);
  }
  /**
   * Returns a new date representing the earliest interval boundary date after or equal to date.
   * @param date
   */
  ceil(date) {
    const d = new Date(Number(date) - 1);
    const e = this._encode(d);
    return this._decode(e + 1);
  }
  /**
   * Returns an array of dates representing every interval boundary after or equal to start (inclusive) and before stop (exclusive).
   * @param start Range start.
   * @param stop Range end.
   * @param extend If specified, the requested range will be extended to the closest "nice" values.
   */
  range(start, stop, extend) {
    var _a;
    const rangeCallback = (_a = this._rangeCallback) == null ? void 0 : _a.call(this, start, stop);
    const e0 = this._encode(extend ? this.floor(start) : this.ceil(start));
    const e1 = this._encode(extend ? this.ceil(stop) : this.floor(stop));
    if (e1 < e0) {
      return [];
    }
    const range3 = [];
    for (let e = e0; e <= e1; e++) {
      const d = this._decode(e);
      range3.push(d);
    }
    rangeCallback == null ? void 0 : rangeCallback();
    return range3;
  }
};
var CountableTimeInterval = class extends TimeInterval {
  getOffset(snapTo, step) {
    const s = typeof snapTo === "number" || snapTo instanceof Date ? this._encode(new Date(snapTo)) : 0;
    return Math.floor(s) % step;
  }
  /**
   * Returns a filtered view of this interval representing every step'th date.
   * It can be a number of minutes, hours, days etc.
   * Must be a positive integer.
   * @param step
   */
  every(step, options) {
    let offset4 = 0;
    let rangeCallback;
    const { snapTo = "start" } = options != null ? options : {};
    if (typeof snapTo === "string") {
      const initialOffset = offset4;
      rangeCallback = (start, stop) => {
        const s = snapTo === "start" ? start : stop;
        offset4 = this.getOffset(s, step);
        return () => offset4 = initialOffset;
      };
    } else if (typeof snapTo === "number") {
      offset4 = this.getOffset(new Date(snapTo), step);
    } else if (snapTo instanceof Date) {
      offset4 = this.getOffset(snapTo, step);
    }
    const encode13 = (date) => {
      const e = this._encode(date);
      return Math.floor((e - offset4) / step);
    };
    const decode13 = (encoded) => {
      return this._decode(encoded * step + offset4);
    };
    return new TimeInterval(encode13, decode13, rangeCallback);
  }
};

// packages/ag-charts-community/src/util/time/millisecond.ts
function encode(date) {
  return date.getTime();
}
function decode(encoded) {
  return new Date(encoded);
}
var millisecond = new CountableTimeInterval(encode, decode);
var millisecond_default = millisecond;

// packages/ag-charts-community/src/util/time/duration.ts
(/* @__PURE__ */ new Date(0)).getFullYear();
var durationSecond = 1e3;
var durationMinute = durationSecond * 60;
var durationHour = durationMinute * 60;
var durationDay = durationHour * 24;
var durationWeek = durationDay * 7;
var durationMonth = durationDay * 30;
var durationYear = durationDay * 365;

// packages/ag-charts-community/src/util/time/second.ts
var offset = (/* @__PURE__ */ new Date()).getTimezoneOffset() * durationMinute;
function encode2(date) {
  return Math.floor((date.getTime() - offset) / durationSecond);
}
function decode2(encoded) {
  return new Date(offset + encoded * durationSecond);
}
var second = new CountableTimeInterval(encode2, decode2);
var second_default = second;

// packages/ag-charts-community/src/util/time/minute.ts
var offset2 = (/* @__PURE__ */ new Date()).getTimezoneOffset() * durationMinute;
function encode3(date) {
  return Math.floor((date.getTime() - offset2) / durationMinute);
}
function decode3(encoded) {
  return new Date(offset2 + encoded * durationMinute);
}
var minute = new CountableTimeInterval(encode3, decode3);
var minute_default = minute;

// packages/ag-charts-community/src/util/time/hour.ts
var offset3 = (/* @__PURE__ */ new Date()).getTimezoneOffset() * durationMinute;
function encode4(date) {
  return Math.floor((date.getTime() - offset3) / durationHour);
}
function decode4(encoded) {
  return new Date(offset3 + encoded * durationHour);
}
var hour = new CountableTimeInterval(encode4, decode4);
var hour_default = hour;

// packages/ag-charts-community/src/util/time/day.ts
function encode5(date) {
  const tzOffsetMs = date.getTimezoneOffset() * durationMinute;
  return Math.floor((date.getTime() - tzOffsetMs) / durationDay);
}
function decode5(encoded) {
  const d = new Date(1970, 0, 1);
  d.setDate(d.getDate() + encoded);
  return d;
}
var day = new CountableTimeInterval(encode5, decode5);
var day_default = day;

// packages/ag-charts-community/src/util/time/week.ts
function weekday(weekStart) {
  const thursday2 = 4;
  const dayShift = (7 + weekStart - thursday2) % 7;
  function encode13(date) {
    const tzOffsetMs = date.getTimezoneOffset() * durationMinute;
    return Math.floor((date.getTime() - tzOffsetMs) / durationWeek - dayShift / 7);
  }
  function decode13(encoded) {
    const d = new Date(1970, 0, 1);
    d.setDate(d.getDate() + encoded * 7 + dayShift);
    return d;
  }
  return new CountableTimeInterval(encode13, decode13);
}
var sunday = weekday(0);
var monday = weekday(1);
var tuesday = weekday(2);
var wednesday = weekday(3);
var thursday = weekday(4);
var friday = weekday(5);
var saturday = weekday(6);
var week_default = sunday;

// packages/ag-charts-community/src/util/time/month.ts
function encode6(date) {
  return date.getFullYear() * 12 + date.getMonth();
}
function decode6(encoded) {
  const year2 = Math.floor(encoded / 12);
  const month2 = encoded - year2 * 12;
  return new Date(year2, month2, 1);
}
var month = new CountableTimeInterval(encode6, decode6);
var month_default = month;

// packages/ag-charts-community/src/util/time/year.ts
function encode7(date) {
  return date.getFullYear();
}
function decode7(encoded) {
  const d = /* @__PURE__ */ new Date();
  d.setFullYear(encoded);
  d.setMonth(0, 1);
  d.setHours(0, 0, 0, 0);
  return d;
}
var year = new CountableTimeInterval(encode7, decode7);
var year_default = year;

// packages/ag-charts-community/src/util/time/utcMinute.ts
function encode8(date) {
  return Math.floor(date.getTime() / durationMinute);
}
function decode8(encoded) {
  return new Date(encoded * durationMinute);
}
var utcMinute = new CountableTimeInterval(encode8, decode8);

// packages/ag-charts-community/src/util/time/utcHour.ts
function encode9(date) {
  return Math.floor(date.getTime() / durationHour);
}
function decode9(encoded) {
  return new Date(encoded * durationHour);
}
var utcHour = new CountableTimeInterval(encode9, decode9);

// packages/ag-charts-community/src/util/time/utcDay.ts
function encode10(date) {
  return Math.floor(date.getTime() / durationDay);
}
function decode10(encoded) {
  const d = /* @__PURE__ */ new Date(0);
  d.setUTCDate(d.getUTCDate() + encoded);
  d.setUTCHours(0, 0, 0, 0);
  return d;
}
var utcDay = new CountableTimeInterval(encode10, decode10);

// packages/ag-charts-community/src/util/time/utcMonth.ts
function encode11(date) {
  return date.getUTCFullYear() * 12 + date.getUTCMonth();
}
function decode11(encoded) {
  const year2 = Math.floor(encoded / 12);
  const month2 = encoded - year2 * 12;
  return new Date(Date.UTC(year2, month2, 1));
}
var utcMonth = new CountableTimeInterval(encode11, decode11);

// packages/ag-charts-community/src/util/time/utcYear.ts
function encode12(date) {
  return date.getUTCFullYear();
}
function decode12(encoded) {
  const d = /* @__PURE__ */ new Date();
  d.setUTCFullYear(encoded);
  d.setUTCMonth(0, 1);
  d.setUTCHours(0, 0, 0, 0);
  return d;
}
var utcYear = new CountableTimeInterval(encode12, decode12);

// packages/ag-charts-community/src/module/enterpriseModule.ts
var enterpriseModule = {
  isEnterprise: false
};

// packages/ag-charts-community/src/module/module.ts
var BaseModuleInstance = class {
  constructor() {
    this.destroyFns = [];
  }
  destroy() {
    for (const destroyFn of this.destroyFns) {
      destroyFn();
    }
  }
};
var REGISTERED_MODULES = [];
function registerModule(module) {
  const otherModule = REGISTERED_MODULES.find((other) => {
    return module.type === other.type && module.optionsKey === other.optionsKey && module.identifier === other.identifier;
  });
  if (otherModule) {
    if (module.packageType === "enterprise" && otherModule.packageType === "community") {
      const index = REGISTERED_MODULES.indexOf(otherModule);
      REGISTERED_MODULES.splice(index, 1, module);
    }
  } else {
    REGISTERED_MODULES.push(module);
  }
}
function hasRegisteredEnterpriseModules() {
  return REGISTERED_MODULES.some((m) => m.packageType === "enterprise");
}
var MODULE_CONFLICTS = /* @__PURE__ */ new Map();
function registerModuleConflicts(source, targets) {
  MODULE_CONFLICTS.set(source, targets);
}

// packages/ag-charts-community/src/util/array.ts
function extent$4(values) {
  const { length } = values;
  if (length === 0) {
    return void 0;
  }
  let min = Infinity;
  let max = -Infinity;
  for (let i = 0; i < length; i++) {
    let v = values[i];
    if (v instanceof Date) {
      v = v.getTime();
    }
    if (typeof v !== "number") {
      continue;
    }
    if (v < min) {
      min = v;
    }
    if (v > max) {
      max = v;
    }
  }
  const extent2 = [min, max];
  if (extent2.some((v) => !isFinite(v))) {
    return void 0;
  }
  return extent2;
}
function normalisedExtent(d, min, max) {
  return normalisedExtentWithMetadata(d, min, max).extent;
}
function normalisedExtentWithMetadata(d, min, max) {
  var _a;
  let clipped = false;
  if (d.length > 2) {
    d = (_a = extent$4(d)) != null ? _a : [NaN, NaN];
  }
  if (!isNaN(min)) {
    clipped || (clipped = min > d[0]);
    d = [min, d[1]];
  }
  if (!isNaN(max)) {
    clipped || (clipped = max < d[1]);
    d = [d[0], max];
  }
  if (d[0] > d[1]) {
    d = [];
  }
  return { extent: d, clipped };
}
function arraysEqual(a, b) {
  if (a == null || b == null || a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (Array.isArray(a[i]) && Array.isArray(b[i])) {
      if (!arraysEqual(a[i], b[i])) {
        return false;
      }
    } else if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}
function toArray(value) {
  if (typeof value === "undefined") {
    return [];
  }
  return Array.isArray(value) ? value : [value];
}
function unique(array) {
  return Array.from(new Set(array));
}

// packages/ag-charts-community/src/util/function.ts
var doOnceFlags$1 = {};
function doOnce$1(func, key) {
  if (doOnceFlags$1[key]) {
    return;
  }
  func();
  doOnceFlags$1[key] = true;
}

// packages/ag-charts-community/src/util/logger.ts
var Logger = {
  log(...logContent) {
    console.log(...logContent);
  },
  warn(message, ...logContent) {
    console.warn(`AG Charts - ${message}`, ...logContent);
  },
  error(message, ...logContent) {
    if (typeof message === "object") {
      console.error(`AG Charts error`, message, ...logContent);
    } else {
      console.error(`AG Charts - ${message}`, ...logContent);
    }
  },
  table(...logContent) {
    console.table(...logContent);
  },
  warnOnce(message, ...logContent) {
    doOnce$1(() => Logger.warn(message, ...logContent), `Logger.warn: ${message}`);
  },
  errorOnce(message, ...logContent) {
    doOnce$1(() => Logger.error(message, ...logContent), `Logger.error: ${message}`);
  }
};

// packages/ag-charts-community/src/util/window.ts
function windowValue(name) {
  const WINDOW = typeof window !== "undefined" ? window : (
    // typeof global !== 'undefined' ? (global as any) :
    void 0
  );
  return WINDOW == null ? void 0 : WINDOW[name];
}

// packages/ag-charts-community/src/util/debug.ts
var LONG_TIME_PERIOD_THRESHOLD = 2e3;
var timeOfLastLog = Date.now();
var logTimeGap = () => {
  const timeSinceLastLog = Date.now() - timeOfLastLog;
  if (timeSinceLastLog > LONG_TIME_PERIOD_THRESHOLD) {
    const prettyDuration = (Math.floor(timeSinceLastLog / 100) / 10).toFixed(1);
    Logger.log(`**** ${prettyDuration}s since last log message ****`);
  }
  timeOfLastLog = Date.now();
};
var Debug = {
  create(...debugSelectors) {
    return (...logContent) => {
      if (Debug.check(...debugSelectors)) {
        if (typeof logContent[0] === "function") {
          logContent = toArray(logContent[0]());
        }
        logTimeGap();
        Logger.log(...logContent);
      }
    };
  },
  check(...debugSelectors) {
    if (debugSelectors.length === 0) {
      debugSelectors.push(true);
    }
    const chartDebug = toArray(windowValue("agChartsDebug"));
    return chartDebug.some((selector) => debugSelectors.includes(selector));
  }
};

// packages/ag-charts-community/src/util/decorator.ts
var BREAK_TRANSFORM_CHAIN = Symbol("BREAK");
var CONFIG_KEY = "__decorator_config";
function initialiseConfig(target, propertyKeyOrSymbol) {
  if (Object.getOwnPropertyDescriptor(target, CONFIG_KEY) == null) {
    Object.defineProperty(target, CONFIG_KEY, { value: {} });
  }
  const config = target[CONFIG_KEY];
  const propertyKey = propertyKeyOrSymbol.toString();
  if (typeof config[propertyKey] !== "undefined") {
    return config[propertyKey];
  }
  const valuesMap = /* @__PURE__ */ new WeakMap();
  config[propertyKey] = { setters: [], getters: [], valuesMap };
  const descriptor = Object.getOwnPropertyDescriptor(target, propertyKeyOrSymbol);
  const prevSet = descriptor == null ? void 0 : descriptor.set;
  const prevGet = descriptor == null ? void 0 : descriptor.get;
  const getter = function() {
    let value = prevGet ? prevGet.call(this) : valuesMap.get(this);
    for (const transformFn of config[propertyKey].getters) {
      value = transformFn(this, propertyKeyOrSymbol, value);
      if (value === BREAK_TRANSFORM_CHAIN) {
        return;
      }
    }
    return value;
  };
  const setter = function(value) {
    const { setters } = config[propertyKey];
    let oldValue;
    if (setters.some((f) => f.length > 2)) {
      oldValue = prevGet ? prevGet.call(this) : valuesMap.get(this);
    }
    for (const transformFn of setters) {
      value = transformFn(this, propertyKeyOrSymbol, value, oldValue);
      if (value === BREAK_TRANSFORM_CHAIN) {
        return;
      }
    }
    if (prevSet) {
      prevSet.call(this, value);
    } else {
      valuesMap.set(this, value);
    }
  };
  Object.defineProperty(target, propertyKeyOrSymbol, {
    set: setter,
    get: getter,
    enumerable: true,
    configurable: false
  });
  return config[propertyKey];
}
function addTransformToInstanceProperty(setTransform, getTransform, configMetadata) {
  return (target, propertyKeyOrSymbol) => {
    const config = initialiseConfig(target, propertyKeyOrSymbol);
    config.setters.push(setTransform);
    if (getTransform) {
      config.getters.unshift(getTransform);
    }
    if (configMetadata) {
      Object.assign(config, configMetadata);
    }
  };
}
function isDecoratedObject(target) {
  return typeof target !== "undefined" && CONFIG_KEY in target;
}
function listDecoratedProperties(target) {
  const targets = /* @__PURE__ */ new Set();
  while (isDecoratedObject(target)) {
    targets.add(target == null ? void 0 : target[CONFIG_KEY]);
    target = Object.getPrototypeOf(target);
  }
  return Array.from(targets).flatMap((configMap) => Object.keys(configMap));
}
function extractDecoratedProperties(target) {
  return listDecoratedProperties(target).reduce((result, key) => {
    var _a;
    result[key] = (_a = target[key]) != null ? _a : null;
    return result;
  }, {});
}
function extractDecoratedPropertyMetadata(target, propertyKeyOrSymbol) {
  const propertyKey = propertyKeyOrSymbol.toString();
  while (isDecoratedObject(target)) {
    const config = target[CONFIG_KEY];
    if (Object.hasOwn(config, propertyKey)) {
      return config[propertyKey];
    }
    target = Object.getPrototypeOf(target);
  }
}

// packages/ag-charts-community/src/util/deprecation.ts
function createDeprecationWarning() {
  return (key, message) => {
    const msg = [`Property [${key}] is deprecated.`, message].filter(Boolean).join(" ");
    Logger.warnOnce(msg);
  };
}
function Deprecated(message, opts) {
  const warnDeprecated = createDeprecationWarning();
  const def = opts == null ? void 0 : opts.default;
  return addTransformToInstanceProperty((_, key, value) => {
    if (value !== def) {
      warnDeprecated(key.toString(), message);
    }
    return value;
  });
}
function DeprecatedAndRenamedTo(newPropName, mapValue) {
  const warnDeprecated = createDeprecationWarning();
  return addTransformToInstanceProperty(
    (target, key, value) => {
      if (value !== target[newPropName]) {
        warnDeprecated(key.toString(), `Use [${newPropName}] instead.`);
        target[newPropName] = mapValue ? mapValue(value) : value;
      }
      return BREAK_TRANSFORM_CHAIN;
    },
    (target, key) => {
      warnDeprecated(key.toString(), `Use [${newPropName}] instead.`);
      return target[newPropName];
    }
  );
}

// packages/ag-charts-community/src/scene/changeDetectable.ts
var RedrawType = /* @__PURE__ */ ((RedrawType2) => {
  RedrawType2[RedrawType2["NONE"] = 0] = "NONE";
  RedrawType2[RedrawType2["TRIVIAL"] = 1] = "TRIVIAL";
  RedrawType2[RedrawType2["MINOR"] = 2] = "MINOR";
  RedrawType2[RedrawType2["MAJOR"] = 3] = "MAJOR";
  return RedrawType2;
})(RedrawType || {});
function functionConstructorAvailable() {
  try {
    new Function("return true");
    return true;
  } catch (e) {
    return false;
  }
}
var STRING_FUNCTION_USEABLE = functionConstructorAvailable();
function SceneChangeDetection(opts) {
  const { changeCb, convertor } = opts != null ? opts : {};
  return function(target, key) {
    const privateKey = `__${key}`;
    if (target[key]) {
      return;
    }
    if (STRING_FUNCTION_USEABLE && changeCb == null && convertor == null) {
      prepareFastGetSet(target, key, privateKey, opts);
    } else {
      prepareSlowGetSet(target, key, privateKey, opts);
    }
  };
}
function prepareFastGetSet(target, key, privateKey, opts) {
  const { redraw = 1 /* TRIVIAL */, type = "normal", checkDirtyOnAssignment = false } = opts != null ? opts : {};
  const setterJs = new Function(
    "value",
    `
        const oldValue = this.${privateKey};
        if (value !== oldValue) {
            this.${privateKey} = value;
            ${type === "normal" ? `this.markDirty(this, ${redraw});` : ""}
            ${type === "transform" ? `this.markDirtyTransform(${redraw});` : ""}
            ${type === "path" ? `if (!this._dirtyPath) { this._dirtyPath = true; this.markDirty(this, ${redraw}); }` : ""}
            ${type === "font" ? `if (!this._dirtyFont) { this._dirtyFont = true; this.markDirty(this, ${redraw}); }` : ""}
        }
        ${checkDirtyOnAssignment ? `if (value != null && value._dirty > ${0 /* NONE */}) { this.markDirty(value, value._dirty); }` : ""}
`
  );
  const getterJs = new Function(`return this.${privateKey};`);
  Object.defineProperty(target, key, {
    set: setterJs,
    get: getterJs,
    enumerable: true,
    configurable: true
  });
}
function prepareSlowGetSet(target, key, privateKey, opts) {
  const {
    redraw = 1 /* TRIVIAL */,
    type = "normal",
    changeCb,
    convertor,
    checkDirtyOnAssignment = false
  } = opts != null ? opts : {};
  const setter = function(value) {
    const oldValue = this[privateKey];
    value = convertor ? convertor(value) : value;
    if (value !== oldValue) {
      this[privateKey] = value;
      if (type === "normal")
        this.markDirty(this, redraw);
      if (type === "transform")
        this.markDirtyTransform(redraw);
      if (type === "path" && !this._dirtyPath) {
        this._dirtyPath = true;
        this.markDirty(this, redraw);
      }
      if (type === "font" && !this._dirtyFont) {
        this._dirtyFont = true;
        this.markDirty(this, redraw);
      }
      if (changeCb)
        changeCb(this);
    }
    if (checkDirtyOnAssignment && value != null && value._dirty > 0 /* NONE */)
      this.markDirty(value, value._dirty);
  };
  const getter = function() {
    return this[privateKey];
  };
  Object.defineProperty(target, key, {
    set: setter,
    get: getter,
    enumerable: true,
    configurable: true
  });
}
var ChangeDetectable = class {
  constructor() {
    this._dirty = 3 /* MAJOR */;
  }
  markDirty(_source, type = 1 /* TRIVIAL */) {
    if (this._dirty > type) {
      return;
    }
    this._dirty = type;
  }
  markClean(_opts) {
    this._dirty = 0 /* NONE */;
  }
  isDirty() {
    return this._dirty > 0 /* NONE */;
  }
};

// packages/ag-charts-community/src/util/type-guards.ts
function isDefined(val) {
  return val != null;
}
function isArray(value) {
  return Array.isArray(value);
}
function isBoolean(value) {
  return typeof value === "boolean";
}
function isDate$1(value) {
  return value instanceof Date;
}
function isValidDate(value) {
  return isDate$1(value) && !isNaN(Number(value));
}
function isFunction(value) {
  return typeof value === "function";
}
function isObject(value) {
  return typeof value === "object" && value !== null && !isArray(value);
}
function isObjectLike(value) {
  return typeof value === "object" && value !== null;
}
function isPlainObject(value) {
  return typeof value === "object" && value !== null && value.constructor === Object;
}
function isString$1(value) {
  return typeof value === "string";
}
function isNumber$4(value) {
  return typeof value === "number";
}
function isFiniteNumber(value) {
  return isNumber$4(value) && Number.isFinite(value);
}
function isHtmlElement(value) {
  return typeof window !== "undefined" && value instanceof HTMLElement;
}

// packages/ag-charts-community/src/util/properties.ts
var BaseProperties = class extends ChangeDetectable {
  constructor(className) {
    super();
    this.className = className;
  }
  set(properties) {
    const keys = new Set(Object.keys(properties));
    for (const propertyKey of listDecoratedProperties(this)) {
      if (keys.has(propertyKey)) {
        const value = properties[propertyKey];
        const self = this;
        if (isProperties(self[propertyKey])) {
          self[propertyKey] = self[propertyKey] instanceof PropertiesArray ? self[propertyKey].reset(value) : self[propertyKey].set(value);
        } else {
          self[propertyKey] = value;
        }
        keys.delete(propertyKey);
      }
    }
    for (const unknownKey of keys) {
      const { className = this.constructor.name } = this;
      Logger.warn(`unable to set [${unknownKey}] in ${className} - property is unknown`);
    }
    return this;
  }
  isValid() {
    return listDecoratedProperties(this).every((propertyKey) => {
      const { optional } = extractDecoratedPropertyMetadata(this, propertyKey);
      return optional || typeof this[propertyKey] !== "undefined";
    });
  }
  toJson() {
    return listDecoratedProperties(this).reduce((object, propertyKey) => {
      object[propertyKey] = this[propertyKey];
      return object;
    }, {});
  }
};
var PropertiesArray = class _PropertiesArray extends Array {
  constructor(itemFactory, ...properties) {
    super(properties.length);
    Object.defineProperty(this, "itemFactory", { value: itemFactory, enumerable: false, configurable: false });
    this.set(properties);
  }
  set(properties) {
    if (isArray(properties)) {
      this.length = properties.length;
      for (let i = 0; i < properties.length; i++) {
        this[i] = new this.itemFactory().set(properties[i]);
      }
    }
    return this;
  }
  reset(properties) {
    return new _PropertiesArray(this.itemFactory, ...properties);
  }
};
function isProperties(value) {
  return value instanceof BaseProperties || value instanceof PropertiesArray;
}

// packages/ag-charts-community/src/util/json.ts
var CLASS_INSTANCE_TYPE = "class-instance";
function jsonDiff(source, target) {
  if (isArray(target)) {
    if (!isArray(source) || source.length !== target.length || target.some((v, i) => jsonDiff(source[i], v) != null)) {
      return target;
    }
  } else if (isObject(target)) {
    if (!isObject(source) || !isPlainObject(target)) {
      return target;
    }
    const result = {};
    const allKeys = /* @__PURE__ */ new Set([
      ...Object.keys(source),
      ...Object.keys(target)
    ]);
    for (const key of allKeys) {
      if (source[key] === target[key]) {
        continue;
      }
      if (typeof source[key] !== typeof target[key]) {
        result[key] = target[key];
      } else {
        const diff2 = jsonDiff(source[key], target[key]);
        if (diff2 !== null) {
          result[key] = diff2;
        }
      }
    }
    return Object.keys(result).length ? result : null;
  } else if (source !== target) {
    return target;
  }
  return null;
}
function jsonClone(source) {
  if (isArray(source)) {
    return source.map(jsonClone);
  }
  if (isPlainObject(source)) {
    return Object.entries(source).reduce((result, [key, value]) => {
      result[key] = jsonClone(value);
      return result;
    }, {});
  }
  return source;
}
var DELETE = Symbol("<delete-property>");
var NOT_SPECIFIED = Symbol("<unspecified-property>");
function jsonMerge(json, opts) {
  var _a;
  const avoidDeepClone = (_a = opts == null ? void 0 : opts.avoidDeepClone) != null ? _a : [];
  const jsonTypes = json.map((v) => classify(v));
  if (jsonTypes.some((v) => v === "array")) {
    const finalValue = json[json.length - 1];
    if (Array.isArray(finalValue)) {
      return finalValue.map((v) => {
        const type = classify(v);
        if (type === "array")
          return jsonMerge([[], v], opts);
        if (type === "object")
          return jsonMerge([{}, v], opts);
        return v;
      });
    }
    return finalValue;
  }
  const result = {};
  const props = new Set(json.map((v) => v != null ? Object.keys(v) : []).reduce((r, n) => r.concat(n), []));
  for (const nextProp of props) {
    const values = json.map((j) => {
      if (j != null && typeof j === "object" && nextProp in j) {
        return j[nextProp];
      }
      return NOT_SPECIFIED;
    }).filter((v) => v !== NOT_SPECIFIED);
    if (values.length === 0) {
      continue;
    }
    const lastValue = values[values.length - 1];
    if (lastValue === DELETE) {
      continue;
    }
    const types = values.map((v) => classify(v));
    const type = types[0];
    if (types.some((t) => t !== type)) {
      result[nextProp] = lastValue;
      continue;
    }
    if ((type === "array" || type === "object") && !avoidDeepClone.includes(nextProp)) {
      result[nextProp] = jsonMerge(values, opts);
    } else if (type === "array") {
      result[nextProp] = [...lastValue];
    } else {
      result[nextProp] = lastValue;
    }
  }
  return result;
}
function jsonApply(target, source, params = {}) {
  var _a, _b, _c;
  const {
    path,
    matcherPath = path ? path.replace(/(\[[0-9+]+])/i, "[]") : void 0,
    skip = [],
    constructors = {},
    constructedArrays = /* @__PURE__ */ new WeakMap(),
    allowedTypes = {},
    idx
  } = params;
  if (target == null) {
    throw new Error(`AG Charts - target is uninitialised: ${path != null ? path : "<root>"}`);
  }
  if (source == null) {
    return target;
  }
  if (isProperties(target)) {
    return target.set(source);
  }
  const targetAny = target;
  if (idx != null && "_declarationOrder" in targetAny) {
    targetAny["_declarationOrder"] = idx;
  }
  const targetType = classify(target);
  for (const property in source) {
    const propertyMatcherPath = `${matcherPath ? matcherPath + "." : ""}${property}`;
    if (skip.indexOf(propertyMatcherPath) >= 0) {
      continue;
    }
    const newValue = source[property];
    const propertyPath = `${path ? path + "." : ""}${property}`;
    const targetClass = targetAny.constructor;
    const currentValue = targetAny[property];
    let ctr = (_a = constructors[propertyMatcherPath]) != null ? _a : constructors[property];
    try {
      const currentValueType = classify(currentValue);
      const newValueType = classify(newValue);
      if (targetType === CLASS_INSTANCE_TYPE && !(property in target || Object.hasOwn(targetAny, property))) {
        Logger.warn(`unable to set [${propertyPath}] in ${targetClass == null ? void 0 : targetClass.name} - property is unknown`);
        continue;
      }
      const allowableTypes = (_b = allowedTypes[propertyMatcherPath]) != null ? _b : [currentValueType];
      if (currentValueType === CLASS_INSTANCE_TYPE && newValueType === "object") {
      } else if (currentValueType != null && newValueType != null && !allowableTypes.includes(newValueType)) {
        Logger.warn(
          `unable to set [${propertyPath}] in ${targetClass == null ? void 0 : targetClass.name} - can't apply type of [${newValueType}], allowed types are: [${allowableTypes}]`
        );
        continue;
      }
      if (newValueType === "array") {
        ctr = (_c = ctr != null ? ctr : constructedArrays.get(currentValue)) != null ? _c : constructors[`${propertyMatcherPath}[]`];
        if (isProperties(targetAny[property])) {
          targetAny[property].set(newValue);
        } else if (ctr != null) {
          const newValueArray = newValue;
          targetAny[property] = newValueArray.map(
            (v, idx2) => jsonApply(new ctr(), v, __spreadProps(__spreadValues({}, params), {
              path: propertyPath,
              matcherPath: propertyMatcherPath + "[]",
              idx: idx2
            }))
          );
        } else {
          targetAny[property] = newValue;
        }
      } else if (newValueType === CLASS_INSTANCE_TYPE) {
        targetAny[property] = newValue;
      } else if (newValueType === "object") {
        if (currentValue != null) {
          jsonApply(currentValue, newValue, __spreadProps(__spreadValues({}, params), {
            path: propertyPath,
            matcherPath: propertyMatcherPath,
            idx: void 0
          }));
        } else if (isProperties(targetAny[property])) {
          targetAny[property].set(newValue);
        } else if (ctr != null) {
          const obj = new ctr();
          if (isProperties(obj)) {
            targetAny[property] = obj.set(newValue);
          } else {
            targetAny[property] = jsonApply(obj, newValue, __spreadProps(__spreadValues({}, params), {
              path: propertyPath,
              matcherPath: propertyMatcherPath,
              idx: void 0
            }));
          }
        } else {
          targetAny[property] = newValue;
        }
      } else if (isProperties(targetAny[property])) {
        targetAny[property].set(newValue);
      } else {
        targetAny[property] = newValue;
      }
    } catch (error) {
      Logger.warn(`unable to set [${propertyPath}] in [${targetClass == null ? void 0 : targetClass.name}]; nested error is: ${error.message}`);
    }
  }
  return target;
}
function jsonWalk(json, visit, opts, ...jsons) {
  var _a;
  if (isArray(json)) {
    visit(json, ...jsons);
    json.forEach((node, index) => {
      jsonWalk(node, visit, opts, ...keyMapper(jsons, index));
    });
  } else if (isPlainObject(json)) {
    visit(json, ...jsons);
    for (const key of Object.keys(json)) {
      if ((_a = opts == null ? void 0 : opts.skip) == null ? void 0 : _a.includes(key)) {
        continue;
      }
      const value = json[key];
      if (isArray(value) || isPlainObject(value)) {
        jsonWalk(value, visit, opts, ...keyMapper(jsons, key));
      }
    }
  }
}
function keyMapper(data, key) {
  return data.map((dataObject) => dataObject == null ? void 0 : dataObject[key]);
}
function classify(value) {
  if (value == null) {
    return null;
  }
  if (isHtmlElement(value) || isDate$1(value)) {
    return "primitive";
  }
  if (isArray(value)) {
    return "array";
  }
  if (isObject(value)) {
    return isPlainObject(value) ? "object" : CLASS_INSTANCE_TYPE;
  }
  if (isFunction(value)) {
    return "function";
  }
  return "primitive";
}

// packages/ag-charts-community/src/util/angle.ts
var twoPi = Math.PI * 2;
function normalizeAngle360(radians) {
  radians %= twoPi;
  radians += twoPi;
  radians %= twoPi;
  return radians;
}
function normalizeAngle360Inclusive(radians) {
  radians %= twoPi;
  radians += twoPi;
  if (radians !== twoPi) {
    radians %= twoPi;
  }
  return radians;
}
function normalizeAngle180(radians) {
  radians %= twoPi;
  if (radians < -Math.PI) {
    radians += twoPi;
  } else if (radians >= Math.PI) {
    radians -= twoPi;
  }
  return radians;
}
function toRadians(degrees) {
  return degrees / 180 * Math.PI;
}
function toDegrees(radians) {
  return radians / Math.PI * 180;
}
function angleBetween(angle0, angle1) {
  angle0 = normalizeAngle360(angle0);
  angle1 = normalizeAngle360(angle1);
  return angle1 - angle0 + (angle0 > angle1 ? 2 * Math.PI : 0);
}

// packages/ag-charts-community/src/scale/invalidating.ts
var Invalidating = (target, propertyKey) => {
  const mappedProperty = Symbol(String(propertyKey));
  target[mappedProperty] = void 0;
  Object.defineProperty(target, propertyKey, {
    get() {
      return this[mappedProperty];
    },
    set(newValue) {
      const oldValue = this[mappedProperty];
      if (oldValue !== newValue) {
        this[mappedProperty] = newValue;
        this.invalid = true;
      }
    },
    enumerable: true,
    configurable: false
  });
};

// packages/ag-charts-community/src/scale/bandScale.ts
function clamp(x, min, max) {
  return Math.max(min, Math.min(max, x));
}
var BandScale$5 = class {
  constructor() {
    this.type = "band";
    this.invalid = true;
    this.interval = 1;
    /**
     * Maps datum to its index in the {@link domain} array.
     * Used to check for duplicate datums (not allowed).
     */
    this.index = /* @__PURE__ */ new Map();
    /**
     * The output range values for datum at each index.
     */
    this.ordinalRange = [];
    /**
     * Contains unique datums only. Since `{}` is used in place of `Map`
     * for IE11 compatibility, the datums are converted `toString` before
     * the uniqueness check.
     */
    this._domain = [];
    this.range = [0, 1];
    this._bandwidth = 1;
    this._step = 1;
    this._rawBandwidth = 1;
    /**
     * The ratio of the range that is reserved for space between bands.
     */
    this._paddingInner = 0;
    /**
     * The ratio of the range that is reserved for space before the first
     * and after the last band.
     */
    this._paddingOuter = 0;
    this.round = false;
  }
  refresh() {
    if (!this.invalid)
      return;
    this.invalid = false;
    this.update();
    if (this.invalid) {
      Logger.warnOnce("Expected update to not invalidate scale");
    }
  }
  set domain(values) {
    this.invalid = true;
    const domain = [];
    this.index = /* @__PURE__ */ new Map();
    const index = this.index;
    values.forEach((value) => {
      if (index.get(value) === void 0) {
        index.set(value, domain.push(value) - 1);
      }
    });
    this._domain = domain;
  }
  get domain() {
    return this._domain;
  }
  ticks() {
    this.refresh();
    const { interval = 1 } = this;
    const step = Math.abs(Math.round(interval));
    return this._domain.filter((_, i) => i % step === 0);
  }
  convert(d) {
    this.refresh();
    const i = this.index.get(d);
    if (i === void 0) {
      return NaN;
    }
    const r = this.ordinalRange[i];
    if (r === void 0) {
      return NaN;
    }
    return r;
  }
  invert(position) {
    this.refresh();
    const index = this.ordinalRange.findIndex((p) => p === position);
    return this.domain[index];
  }
  get bandwidth() {
    this.refresh();
    return this._bandwidth;
  }
  get step() {
    this.refresh();
    return this._step;
  }
  get rawBandwidth() {
    this.refresh();
    return this._rawBandwidth;
  }
  set padding(value) {
    value = clamp(value, 0, 1);
    this._paddingInner = value;
    this._paddingOuter = value;
  }
  get padding() {
    return this._paddingInner;
  }
  set paddingInner(value) {
    this._paddingInner = clamp(value, 0, 1);
  }
  get paddingInner() {
    return this._paddingInner;
  }
  set paddingOuter(value) {
    this._paddingOuter = clamp(value, 0, 1);
  }
  get paddingOuter() {
    return this._paddingOuter;
  }
  update() {
    const count2 = this._domain.length;
    if (count2 === 0) {
      return;
    }
    const round3 = this.round;
    const paddingInner = this._paddingInner;
    const paddingOuter = this._paddingOuter;
    const [r0, r1] = this.range;
    const width = r1 - r0;
    const rawStep = width / Math.max(1, count2 + 2 * paddingOuter - paddingInner);
    const step = round3 ? Math.floor(rawStep) : rawStep;
    const fullBandWidth = step * (count2 - paddingInner);
    const x0 = r0 + (width - fullBandWidth) / 2;
    const start = round3 ? Math.round(x0) : x0;
    const bw = step * (1 - paddingInner);
    const bandwidth = round3 ? Math.round(bw) : bw;
    const rawBandwidth = rawStep * (1 - paddingInner);
    const values = [];
    for (let i = 0; i < count2; i++) {
      values.push(start + step * i);
    }
    this._bandwidth = bandwidth;
    this._rawBandwidth = rawBandwidth;
    this._step = step;
    this.ordinalRange = values;
  }
};
__decorateClass([
  Invalidating
], BandScale$5.prototype, "interval", 2);
__decorateClass([
  Invalidating
], BandScale$5.prototype, "range", 2);
__decorateClass([
  Invalidating
], BandScale$5.prototype, "round", 2);

// packages/ag-charts-community/src/util/number.ts
function clamp2(min, value, max) {
  return Math.min(max, Math.max(min, value));
}
function clampArray(value, array) {
  return clamp2(Math.min(...array), value, Math.max(...array));
}
function isEqual(a, b, epsilon2 = 1e-10) {
  return Math.abs(a - b) < epsilon2;
}
function isNegative(a) {
  return Math.sign(a) < 0 || Object.is(a, -0);
}
function isReal(a) {
  return isFinite(a) && !isNaN(a);
}
function round(value, decimals = 2) {
  const pow = Math.pow(10, decimals);
  return Math.round(value * pow) / pow;
}
function toFixed(value, fractionOrSignificantDigits = 2) {
  const power = Math.floor(Math.log(Math.abs(value)) / Math.LN10);
  if (power >= 0 || !isFinite(power)) {
    return value.toFixed(fractionOrSignificantDigits);
  }
  return value.toFixed(Math.abs(power) - 1 + fractionOrSignificantDigits);
}
function toReal(value) {
  return isReal(value) ? value : 0;
}
function mod(n, m) {
  if (n >= 0) {
    return Math.floor(n % m);
  }
  return Math.floor(n % m + m);
}
var countFractionDigits = (value, maxFractionDigits = 10) => {
  const decimal = (Math.abs(value) % 1).toFixed(maxFractionDigits);
  for (let i = decimal.length - 1; i >= 2; i -= 1) {
    if (decimal[i] !== "0") {
      return maxFractionDigits - (decimal.length - 1 - i);
    }
  }
  return 0;
};

// packages/ag-charts-community/src/util/color.ts
var srgbToLinear = (value) => {
  const sign = value < 0 ? -1 : 1;
  const abs = Math.abs(value);
  if (abs <= 0.04045)
    return value / 12.92;
  return sign * __pow((abs + 0.055) / 1.055, 2.4);
};
var srgbFromLinear = (value) => {
  const sign = value < 0 ? -1 : 1;
  const abs = Math.abs(value);
  if (abs > 31308e-7) {
    return sign * (1.055 * __pow(abs, 1 / 2.4) - 0.055);
  }
  return 12.92 * value;
};
var _Color = class _Color {
  /**
   * Every color component should be in the [0, 1] range.
   * Some easing functions (such as elastic easing) can overshoot the target value by some amount.
   * So, when animating colors, if the source or target color components are already near
   * or at the edge of the allowed [0, 1] range, it is possible for the intermediate color
   * component value to end up outside of that range mid-animation. For this reason the constructor
   * performs range checking/constraining.
   * @param r Red component.
   * @param g Green component.
   * @param b Blue component.
   * @param a Alpha (opacity) component.
   */
  constructor(r, g, b, a = 1) {
    this.r = clamp2(0, r || 0, 1);
    this.g = clamp2(0, g || 0, 1);
    this.b = clamp2(0, b || 0, 1);
    this.a = clamp2(0, a || 0, 1);
  }
  /**
   * A color string can be in one of the following formats to be valid:
   * - #rgb
   * - #rrggbb
   * - rgb(r, g, b)
   * - rgba(r, g, b, a)
   * - CSS color name such as 'white', 'orange', 'cyan', etc.
   */
  static validColorString(str) {
    if (str.indexOf("#") >= 0) {
      return !!_Color.parseHex(str);
    }
    if (str.indexOf("rgb") >= 0) {
      return !!_Color.stringToRgba(str);
    }
    return !!_Color.nameToHex[str.toLowerCase()];
  }
  /**
   * The given string can be in one of the following formats:
   * - #rgb
   * - #rrggbb
   * - rgb(r, g, b)
   * - rgba(r, g, b, a)
   * - CSS color name such as 'white', 'orange', 'cyan', etc.
   * @param str
   */
  static fromString(str) {
    if (str.indexOf("#") >= 0) {
      return _Color.fromHexString(str);
    }
    const hex = _Color.nameToHex[str.toLowerCase()];
    if (hex) {
      return _Color.fromHexString(hex);
    }
    if (str.indexOf("rgb") >= 0) {
      return _Color.fromRgbaString(str);
    }
    throw new Error(`Invalid color string: '${str}'`);
  }
  static tryParseFromString(str) {
    try {
      return _Color.fromString(str);
    } catch (e) {
      Logger.warnOnce(`invalid color string: '${str}'.`);
      return _Color.fromArray([0, 0, 0]);
    }
  }
  // See https://drafts.csswg.org/css-color/#hex-notation
  static parseHex(input) {
    input = input.replace(/ /g, "").slice(1);
    let parts;
    switch (input.length) {
      case 6:
      case 8:
        parts = [];
        for (let i = 0; i < input.length; i += 2) {
          parts.push(parseInt(`${input[i]}${input[i + 1]}`, 16));
        }
        break;
      case 3:
      case 4:
        parts = input.split("").map((p) => parseInt(p, 16)).map((p) => p + p * 16);
        break;
    }
    if ((parts == null ? void 0 : parts.length) >= 3 && parts.every((p) => p >= 0)) {
      if (parts.length === 3) {
        parts.push(255);
      }
      return parts;
    }
  }
  static fromHexString(str) {
    const values = _Color.parseHex(str);
    if (values) {
      const [r, g, b, a] = values;
      return new _Color(r / 255, g / 255, b / 255, a / 255);
    }
    throw new Error(`Malformed hexadecimal color string: '${str}'`);
  }
  static stringToRgba(str) {
    let [po, pc] = [NaN, NaN];
    for (let i = 0; i < str.length; i++) {
      const c = str[i];
      if (!po && c === "(") {
        po = i;
      } else if (c === ")") {
        pc = i;
        break;
      }
    }
    const contents = po && pc && str.substring(po + 1, pc);
    if (!contents) {
      return;
    }
    const parts = contents.split(",");
    const rgba = [];
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      let value = parseFloat(part);
      if (isNaN(value)) {
        return;
      }
      if (part.indexOf("%") >= 0) {
        value = Math.max(0, Math.min(100, value));
        value /= 100;
      } else {
        if (i === 3) {
          value = Math.max(0, Math.min(1, value));
        } else {
          value = Math.max(0, Math.min(255, value));
          value /= 255;
        }
      }
      rgba.push(value);
    }
    return rgba;
  }
  static fromRgbaString(str) {
    const rgba = _Color.stringToRgba(str);
    if (rgba) {
      if (rgba.length === 3) {
        return new _Color(rgba[0], rgba[1], rgba[2]);
      } else if (rgba.length === 4) {
        return new _Color(rgba[0], rgba[1], rgba[2], rgba[3]);
      }
    }
    throw new Error(`Malformed rgb/rgba color string: '${str}'`);
  }
  static fromArray(arr) {
    if (arr.length === 4) {
      return new _Color(arr[0], arr[1], arr[2], arr[3]);
    }
    if (arr.length === 3) {
      return new _Color(arr[0], arr[1], arr[2]);
    }
    throw new Error("The given array should contain 3 or 4 color components (numbers).");
  }
  static fromHSB(h, s, b, alpha = 1) {
    const rgb = _Color.HSBtoRGB(h, s, b);
    return new _Color(rgb[0], rgb[1], rgb[2], alpha);
  }
  static fromHSL(h, s, l, alpha = 1) {
    const rgb = _Color.HSLtoRGB(h, s, l);
    return new _Color(rgb[0], rgb[1], rgb[2], alpha);
  }
  static fromOKLCH(l, c, h, alpha = 1) {
    const rgb = _Color.OKLCHtoRGB(l, c, h);
    return new _Color(rgb[0], rgb[1], rgb[2], alpha);
  }
  static padHex(str) {
    return str.length === 1 ? "0" + str : str;
  }
  toHexString() {
    let hex = "#" + _Color.padHex(Math.round(this.r * 255).toString(16)) + _Color.padHex(Math.round(this.g * 255).toString(16)) + _Color.padHex(Math.round(this.b * 255).toString(16));
    if (this.a < 1) {
      hex += _Color.padHex(Math.round(this.a * 255).toString(16));
    }
    return hex;
  }
  toRgbaString(fractionDigits = 3) {
    const components = [Math.round(this.r * 255), Math.round(this.g * 255), Math.round(this.b * 255)];
    const k = Math.pow(10, fractionDigits);
    if (this.a !== 1) {
      components.push(Math.round(this.a * k) / k);
      return `rgba(${components.join(", ")})`;
    }
    return `rgb(${components.join(", ")})`;
  }
  toString() {
    if (this.a === 1) {
      return this.toHexString();
    }
    return this.toRgbaString();
  }
  toHSB() {
    return _Color.RGBtoHSB(this.r, this.g, this.b);
  }
  static RGBtoOKLCH(r, g, b) {
    const LSRGB0 = srgbToLinear(r);
    const LSRGB1 = srgbToLinear(g);
    const LSRGB2 = srgbToLinear(b);
    const LMS0 = Math.cbrt(0.4122214708 * LSRGB0 + 0.5363325363 * LSRGB1 + 0.0514459929 * LSRGB2);
    const LMS1 = Math.cbrt(0.2119034982 * LSRGB0 + 0.6806995451 * LSRGB1 + 0.1073969566 * LSRGB2);
    const LMS2 = Math.cbrt(0.0883024619 * LSRGB0 + 0.2817188376 * LSRGB1 + 0.6299787005 * LSRGB2);
    const OKLAB0 = 0.2104542553 * LMS0 + 0.793617785 * LMS1 - 0.0040720468 * LMS2;
    const OKLAB1 = 1.9779984951 * LMS0 - 2.428592205 * LMS1 + 0.4505937099 * LMS2;
    const OKLAB2 = 0.0259040371 * LMS0 + 0.7827717662 * LMS1 - 0.808675766 * LMS2;
    const hue = Math.atan2(OKLAB2, OKLAB1) * 180 / Math.PI;
    const OKLCH0 = OKLAB0;
    const OKLCH1 = Math.hypot(OKLAB1, OKLAB2);
    const OKLCH2 = hue >= 0 ? hue : hue + 360;
    return [OKLCH0, OKLCH1, OKLCH2];
  }
  static OKLCHtoRGB(l, c, h) {
    const OKLAB0 = l;
    const OKLAB1 = c * Math.cos(h * Math.PI / 180);
    const OKLAB2 = c * Math.sin(h * Math.PI / 180);
    const LMS0 = __pow(OKLAB0 + 0.3963377774 * OKLAB1 + 0.2158037573 * OKLAB2, 3);
    const LMS1 = __pow(OKLAB0 - 0.1055613458 * OKLAB1 - 0.0638541728 * OKLAB2, 3);
    const LMS2 = __pow(OKLAB0 - 0.0894841775 * OKLAB1 - 1.291485548 * OKLAB2, 3);
    const LSRGB0 = 4.0767416621 * LMS0 - 3.3077115913 * LMS1 + 0.2309699292 * LMS2;
    const LSRGB1 = -1.2684380046 * LMS0 + 2.6097574011 * LMS1 - 0.3413193965 * LMS2;
    const LSRGB2 = -0.0041960863 * LMS0 - 0.7034186147 * LMS1 + 1.707614701 * LMS2;
    const SRGB0 = srgbFromLinear(LSRGB0);
    const SRGB1 = srgbFromLinear(LSRGB1);
    const SRGB2 = srgbFromLinear(LSRGB2);
    return [SRGB0, SRGB1, SRGB2];
  }
  static RGBtoHSL(r, g, b) {
    const min = Math.min(r, g, b);
    const max = Math.max(r, g, b);
    const l = (max + min) / 2;
    let h;
    let s;
    if (max === min) {
      h = NaN;
      s = 0;
    } else {
      const delta = max - min;
      s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);
      if (max === r) {
        h = (g - b) / delta + (g < b ? 6 : 0);
      } else if (max === g) {
        h = (b - r) / delta + 2;
      } else {
        h = (r - g) / delta + 4;
      }
      h *= 360 / 6;
    }
    return [h, s, l];
  }
  static HSLtoRGB(h, s, l) {
    if (s === 0) {
      return [l, l, l];
    }
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    function hueToRgb(t) {
      if (t < 0)
        t += 1;
      if (t > 1)
        t -= 1;
      if (t < 1 / 6)
        return p + (q - p) * 6 * t;
      if (t < 1 / 2)
        return q;
      if (t < 2 / 3)
        return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    }
    const r = hueToRgb(h / 360 + 1 / 3);
    const g = hueToRgb(h / 360);
    const b = hueToRgb(h / 360 - 1 / 3);
    return [r, g, b];
  }
  /**
   * Converts the given RGB triple to an array of HSB (HSV) components.
   * The hue component will be `NaN` for achromatic colors.
   */
  static RGBtoHSB(r, g, b) {
    const min = Math.min(r, g, b);
    const max = Math.max(r, g, b);
    const S = max !== 0 ? (max - min) / max : 0;
    let H = NaN;
    if (min !== max) {
      const delta = max - min;
      const rc = (max - r) / delta;
      const gc = (max - g) / delta;
      const bc = (max - b) / delta;
      if (r === max) {
        H = bc - gc;
      } else if (g === max) {
        H = 2 + rc - bc;
      } else {
        H = 4 + gc - rc;
      }
      H /= 6;
      if (H < 0) {
        H = H + 1;
      }
    }
    return [H * 360, S, max];
  }
  /**
   * Converts the given HSB (HSV) triple to an array of RGB components.
   */
  static HSBtoRGB(H, S, B) {
    if (isNaN(H)) {
      H = 0;
    }
    H = (H % 360 + 360) % 360 / 360;
    let r = 0;
    let g = 0;
    let b = 0;
    if (S === 0) {
      r = g = b = B;
    } else {
      const h = (H - Math.floor(H)) * 6;
      const f = h - Math.floor(h);
      const p = B * (1 - S);
      const q = B * (1 - S * f);
      const t = B * (1 - S * (1 - f));
      switch (h >> 0) {
        case 0:
          r = B;
          g = t;
          b = p;
          break;
        case 1:
          r = q;
          g = B;
          b = p;
          break;
        case 2:
          r = p;
          g = B;
          b = t;
          break;
        case 3:
          r = p;
          g = q;
          b = B;
          break;
        case 4:
          r = t;
          g = p;
          b = B;
          break;
        case 5:
          r = B;
          g = p;
          b = q;
          break;
      }
    }
    return [r, g, b];
  }
  derive(hueShift, saturationFactor, brightnessFactor, opacityFactor) {
    const hsb = _Color.RGBtoHSB(this.r, this.g, this.b);
    let b = hsb[2];
    if (b == 0 && brightnessFactor > 1) {
      b = 0.05;
    }
    const h = ((hsb[0] + hueShift) % 360 + 360) % 360;
    const s = Math.max(Math.min(hsb[1] * saturationFactor, 1), 0);
    b = Math.max(Math.min(b * brightnessFactor, 1), 0);
    const a = Math.max(Math.min(this.a * opacityFactor, 1), 0);
    const rgba = _Color.HSBtoRGB(h, s, b);
    rgba.push(a);
    return _Color.fromArray(rgba);
  }
  brighter() {
    return this.derive(0, 1, 1 / 0.7, 1);
  }
  darker() {
    return this.derive(0, 1, 0.7, 1);
  }
  static interpolate(color, other) {
    const c0 = _Color.tryParseFromString(color);
    const c1 = _Color.tryParseFromString(other);
    return (t) => {
      const i = (x, y) => x * (1 - t) + y * t;
      const c = new _Color(i(c0.r, c1.r), i(c0.g, c1.g), i(c0.b, c1.b), i(c0.a, c1.a));
      return c.toString();
    };
  }
};
_Color.didDebug = false;
/**
 * CSS Color Module Level 4:
 * https://drafts.csswg.org/css-color/#named-colors
 */
_Color.nameToHex = Object.freeze({
  aliceblue: "#F0F8FF",
  antiquewhite: "#FAEBD7",
  aqua: "#00FFFF",
  aquamarine: "#7FFFD4",
  azure: "#F0FFFF",
  beige: "#F5F5DC",
  bisque: "#FFE4C4",
  black: "#000000",
  blanchedalmond: "#FFEBCD",
  blue: "#0000FF",
  blueviolet: "#8A2BE2",
  brown: "#A52A2A",
  burlywood: "#DEB887",
  cadetblue: "#5F9EA0",
  chartreuse: "#7FFF00",
  chocolate: "#D2691E",
  coral: "#FF7F50",
  cornflowerblue: "#6495ED",
  cornsilk: "#FFF8DC",
  crimson: "#DC143C",
  cyan: "#00FFFF",
  darkblue: "#00008B",
  darkcyan: "#008B8B",
  darkgoldenrod: "#B8860B",
  darkgray: "#A9A9A9",
  darkgreen: "#006400",
  darkgrey: "#A9A9A9",
  darkkhaki: "#BDB76B",
  darkmagenta: "#8B008B",
  darkolivegreen: "#556B2F",
  darkorange: "#FF8C00",
  darkorchid: "#9932CC",
  darkred: "#8B0000",
  darksalmon: "#E9967A",
  darkseagreen: "#8FBC8F",
  darkslateblue: "#483D8B",
  darkslategray: "#2F4F4F",
  darkslategrey: "#2F4F4F",
  darkturquoise: "#00CED1",
  darkviolet: "#9400D3",
  deeppink: "#FF1493",
  deepskyblue: "#00BFFF",
  dimgray: "#696969",
  dimgrey: "#696969",
  dodgerblue: "#1E90FF",
  firebrick: "#B22222",
  floralwhite: "#FFFAF0",
  forestgreen: "#228B22",
  fuchsia: "#FF00FF",
  gainsboro: "#DCDCDC",
  ghostwhite: "#F8F8FF",
  gold: "#FFD700",
  goldenrod: "#DAA520",
  gray: "#808080",
  green: "#008000",
  greenyellow: "#ADFF2F",
  grey: "#808080",
  honeydew: "#F0FFF0",
  hotpink: "#FF69B4",
  indianred: "#CD5C5C",
  indigo: "#4B0082",
  ivory: "#FFFFF0",
  khaki: "#F0E68C",
  lavender: "#E6E6FA",
  lavenderblush: "#FFF0F5",
  lawngreen: "#7CFC00",
  lemonchiffon: "#FFFACD",
  lightblue: "#ADD8E6",
  lightcoral: "#F08080",
  lightcyan: "#E0FFFF",
  lightgoldenrodyellow: "#FAFAD2",
  lightgray: "#D3D3D3",
  lightgreen: "#90EE90",
  lightgrey: "#D3D3D3",
  lightpink: "#FFB6C1",
  lightsalmon: "#FFA07A",
  lightseagreen: "#20B2AA",
  lightskyblue: "#87CEFA",
  lightslategray: "#778899",
  lightslategrey: "#778899",
  lightsteelblue: "#B0C4DE",
  lightyellow: "#FFFFE0",
  lime: "#00FF00",
  limegreen: "#32CD32",
  linen: "#FAF0E6",
  magenta: "#FF00FF",
  maroon: "#800000",
  mediumaquamarine: "#66CDAA",
  mediumblue: "#0000CD",
  mediumorchid: "#BA55D3",
  mediumpurple: "#9370DB",
  mediumseagreen: "#3CB371",
  mediumslateblue: "#7B68EE",
  mediumspringgreen: "#00FA9A",
  mediumturquoise: "#48D1CC",
  mediumvioletred: "#C71585",
  midnightblue: "#191970",
  mintcream: "#F5FFFA",
  mistyrose: "#FFE4E1",
  moccasin: "#FFE4B5",
  navajowhite: "#FFDEAD",
  navy: "#000080",
  oldlace: "#FDF5E6",
  olive: "#808000",
  olivedrab: "#6B8E23",
  orange: "#FFA500",
  orangered: "#FF4500",
  orchid: "#DA70D6",
  palegoldenrod: "#EEE8AA",
  palegreen: "#98FB98",
  paleturquoise: "#AFEEEE",
  palevioletred: "#DB7093",
  papayawhip: "#FFEFD5",
  peachpuff: "#FFDAB9",
  peru: "#CD853F",
  pink: "#FFC0CB",
  plum: "#DDA0DD",
  powderblue: "#B0E0E6",
  purple: "#800080",
  rebeccapurple: "#663399",
  red: "#FF0000",
  rosybrown: "#BC8F8F",
  royalblue: "#4169E1",
  saddlebrown: "#8B4513",
  salmon: "#FA8072",
  sandybrown: "#F4A460",
  seagreen: "#2E8B57",
  seashell: "#FFF5EE",
  sienna: "#A0522D",
  silver: "#C0C0C0",
  skyblue: "#87CEEB",
  slateblue: "#6A5ACD",
  slategray: "#708090",
  slategrey: "#708090",
  snow: "#FFFAFA",
  springgreen: "#00FF7F",
  steelblue: "#4682B4",
  tan: "#D2B48C",
  teal: "#008080",
  thistle: "#D8BFD8",
  tomato: "#FF6347",
  transparent: "#00000000",
  turquoise: "#40E0D0",
  violet: "#EE82EE",
  wheat: "#F5DEB3",
  white: "#FFFFFF",
  whitesmoke: "#F5F5F5",
  yellow: "#FFFF00",
  yellowgreen: "#9ACD32"
});
var Color = _Color;

// packages/ag-charts-community/src/util/validation.ts
function Validate(predicate, options = {}) {
  const { optional = false } = options;
  return addTransformToInstanceProperty(
    (target, property, value) => {
      var _a;
      const context = __spreadProps(__spreadValues({}, options), { target, property });
      if (optional && typeof value === "undefined" || predicate(value, context)) {
        if (isProperties(target[property]) && !isProperties(value)) {
          target[property].set(value);
          return target[property];
        }
        return value;
      }
      const cleanKey = String(property).replace(/^_*/, "");
      const targetName = (_a = target.constructor.className) != null ? _a : target.constructor.name.replace(/Properties$/, "");
      Logger.warn(
        `Property [${cleanKey}] of [${targetName}] cannot be set to [${stringify(value)}]${predicate.message ? `; expecting ${getPredicateMessage(predicate, context)}` : ""}, ignoring.`
      );
      return BREAK_TRANSFORM_CHAIN;
    },
    void 0,
    { optional }
  );
}
var AND = (...predicates) => {
  const messages = [];
  return predicateWithMessage(
    (value, ctx) => {
      messages.length = 0;
      return predicates.every((predicate) => {
        const isValid = predicate(value, ctx);
        if (!isValid) {
          messages.push(getPredicateMessage(predicate, ctx));
        }
        return isValid;
      });
    },
    () => messages.filter(Boolean).join(" AND ")
  );
};
var OR = (...predicates) => predicateWithMessage(
  (value, ctx) => predicates.some((predicate) => predicate(value, ctx)),
  (ctx) => predicates.map(getPredicateMessageMapper(ctx)).filter(Boolean).join(" OR ")
);
var OBJECT = attachObjectRestrictions(
  predicateWithMessage(
    (value, ctx) => isProperties(value) || isObject(value) && isProperties(ctx.target[ctx.property]),
    "an object"
  )
);
var BOOLEAN = predicateWithMessage(isBoolean, "a boolean");
var FUNCTION = predicateWithMessage(isFunction, "a function");
var STRING = predicateWithMessage(isString$1, "a string");
var NUMBER = attachNumberRestrictions(predicateWithMessage(isFiniteNumber, "a number"));
var NAN = predicateWithMessage((value) => isNumber$4(value) && isNaN(value), "NaN");
var POSITIVE_NUMBER = NUMBER.restrict({ min: 0 });
var RATIO = NUMBER.restrict({ min: 0, max: 1 });
var DEGREE = NUMBER.restrict({ min: -360, max: 360 });
var NUMBER_OR_NAN = OR(NUMBER, NAN);
var ARRAY = attachArrayRestrictions(predicateWithMessage(isArray, "an array"));
var ARRAY_OF = (predicate, message) => predicateWithMessage(
  (value, ctx) => isArray(value) && value.every((item) => predicate(item, ctx)),
  (ctx) => {
    var _a;
    const arrayMessage = (_a = getPredicateMessage(ARRAY, ctx)) != null ? _a : "";
    return message ? `${arrayMessage} of ${message}` : arrayMessage;
  }
);
var isComparable = (value) => isFiniteNumber(value) || isValidDate(value);
var LESS_THAN = (otherField) => predicateWithMessage(
  (v, ctx) => !isComparable(v) || !isComparable(ctx.target[otherField]) || v < ctx.target[otherField],
  `expected to be less than ${otherField}`
);
var GREATER_THAN = (otherField) => predicateWithMessage(
  (v, ctx) => !isComparable(v) || !isComparable(ctx.target[otherField]) || v > ctx.target[otherField],
  `expected to be greater than ${otherField}`
);
var DATE = predicateWithMessage(isValidDate, "Date object");
var DATE_OR_DATETIME_MS = OR(DATE, POSITIVE_NUMBER);
var colorMessage = `A color string can be in one of the following formats to be valid: #rgb, #rrggbb, rgb(r, g, b), rgba(r, g, b, a) or a CSS color name such as 'white', 'orange', 'cyan', etc`;
var COLOR_STRING = predicateWithMessage(
  (v) => isString$1(v) && Color.validColorString(v),
  `color String. ${colorMessage}`
);
var COLOR_STRING_ARRAY = predicateWithMessage(ARRAY_OF(COLOR_STRING), `color strings. ${colorMessage}`);
var BOOLEAN_ARRAY = ARRAY_OF(BOOLEAN, "boolean values");
var NUMBER_ARRAY = ARRAY_OF(NUMBER, "numbers");
var STRING_ARRAY = ARRAY_OF(STRING, "strings");
var DATE_ARRAY = predicateWithMessage(ARRAY_OF(DATE), "Date objects");
var OBJECT_ARRAY = predicateWithMessage(ARRAY_OF(OBJECT), "objects");
var LINE_CAP = UNION(["butt", "round", "square"], "a line cap");
var LINE_JOIN = UNION(["round", "bevel", "miter"], "a line join");
var LINE_DASH = predicateWithMessage(
  ARRAY_OF(POSITIVE_NUMBER),
  "numbers specifying the length in pixels of alternating dashes and gaps, for example, [6, 3] means dashes with a length of 6 pixels with gaps between of 3 pixels."
);
var POSITION = UNION(["top", "right", "bottom", "left"], "a position");
var FONT_STYLE = UNION(["normal", "italic", "oblique"], "a font style");
var FONT_WEIGHT = OR(
  UNION(["normal", "bold", "bolder", "lighter"], "a font weight"),
  NUMBER.restrict({ min: 1, max: 1e3 })
);
var TEXT_WRAP = UNION(["never", "always", "hyphenate", "on-space"], "a text wrap strategy");
var TEXT_ALIGN = UNION(["left", "center", "right"], "a text align");
var VERTICAL_ALIGN = UNION(["top", "middle", "bottom"], "a vertical align");
var OVERFLOW_STRATEGY = UNION(["ellipsis", "hide"], "an overflow strategy");
var DIRECTION = UNION(["horizontal", "vertical"], "a direction");
var PLACEMENT = UNION(["inside", "outside"], "a placement");
var INTERACTION_RANGE = OR(UNION(["exact", "nearest"], "interaction range"), NUMBER);
function UNION(options, message = "a") {
  return predicateWithMessage(
    (v) => options.includes(v),
    `${message} keyword such as ${joinUnionOptions(options)}`
  );
}
var MIN_SPACING = OR(AND(NUMBER.restrict({ min: 1 }), LESS_THAN("maxSpacing")), NAN);
var MAX_SPACING = OR(AND(NUMBER.restrict({ min: 1 }), GREATER_THAN("minSpacing")), NAN);
function predicateWithMessage(predicate, message) {
  predicate.message = message;
  return predicate;
}
function joinUnionOptions(options) {
  const values = options.map((option) => `'${option}'`);
  if (values.length === 1) {
    return values[0];
  }
  const lastValue = values.pop();
  return `${values.join(", ")} or ${lastValue}`;
}
function getPredicateMessage(predicate, ctx) {
  return isFunction(predicate.message) ? predicate.message(ctx) : predicate.message;
}
function getPredicateMessageMapper(ctx) {
  return (predicate) => getPredicateMessage(predicate, ctx);
}
function attachArrayRestrictions(predicate) {
  return Object.assign(predicate, {
    restrict({ length, minLength } = {}) {
      return predicateWithMessage(
        (value) => isArray(value) && (isNumber$4(length) ? value.length === length : true) && (isNumber$4(minLength) ? value.length >= minLength : true),
        isNumber$4(minLength) && minLength > 0 ? "a non-empty array" : isNumber$4(length) ? `an array of length ${length}` : "an array"
      );
    }
  });
}
function attachNumberRestrictions(predicate) {
  return Object.assign(predicate, {
    restrict({ min, max } = {}) {
      const message = ["a number"];
      const hasMin = isNumber$4(min);
      const hasMax = isNumber$4(max);
      if (hasMin && hasMax) {
        message.push(`between ${min} and ${max} inclusive`);
      } else if (hasMin) {
        message.push(`greater than or equal to ${min}`);
      } else if (hasMax) {
        message.push(`less than or equal to ${max}`);
      }
      return predicateWithMessage(
        (value) => isFiniteNumber(value) && (hasMin ? value >= min : true) && (hasMax ? value <= max : true),
        message.join(" ")
      );
    }
  });
}
function attachObjectRestrictions(predicate) {
  return Object.assign(predicate, {
    restrict(objectType) {
      const isInstanceOf = (value) => isProperties(value) && value instanceof objectType;
      return predicateWithMessage(
        (value, ctx) => isInstanceOf(value) || isObject(value) && isInstanceOf(ctx.target[ctx.property]),
        (ctx) => {
          var _a;
          return (_a = getPredicateMessage(predicate, ctx)) != null ? _a : "an object";
        }
      );
    }
  });
}
function stringify(value) {
  if (typeof value === "number") {
    if (isNaN(value))
      return "NaN";
    if (value === Infinity)
      return "Infinity";
    if (value === -Infinity)
      return "-Infinity";
  }
  return JSON.stringify(value);
}

// packages/ag-charts-community/src/chart/chartAxisDirection.ts
var ChartAxisDirection = /* @__PURE__ */ ((ChartAxisDirection2) => {
  ChartAxisDirection2["X"] = "x";
  ChartAxisDirection2["Y"] = "y";
  return ChartAxisDirection2;
})(ChartAxisDirection || {});

// packages/ag-charts-community/src/util/id.ts
var ID_MAP = {};
function resetIds() {
  for (const key in ID_MAP) {
    delete ID_MAP[key];
  }
}
function createId$1(instance) {
  var _a;
  const constructor = instance.constructor;
  const className = Object.hasOwn(constructor, "className") ? constructor.className : constructor.name;
  if (!className) {
    throw new Error(`The ${constructor} is missing the 'className' property.`);
  }
  const nextId = ((_a = ID_MAP[className]) != null ? _a : 0) + 1;
  ID_MAP[className] = nextId;
  return className + "-" + nextId;
}

// packages/ag-charts-community/src/scene/nearest.ts
function nearestSquared(point, objects, maxDistanceSquared = Infinity) {
  const result = { nearest: void 0, distanceSquared: maxDistanceSquared };
  for (const obj of objects) {
    const thisDistance = obj.distanceSquared(point);
    if (thisDistance === 0) {
      return { nearest: obj, distanceSquared: 0 };
    } else if (thisDistance < result.distanceSquared) {
      result.nearest = obj;
      result.distanceSquared = thisDistance;
    }
  }
  return result;
}
function nearestSquaredInContainer(point, container, maxDistanceSquared = Infinity) {
  const tpoint = container.transformPoint(point.x, point.y);
  const result = { nearest: void 0, distanceSquared: maxDistanceSquared };
  for (const child of container.children) {
    const { nearest, distanceSquared } = child.nearestSquared(tpoint, result.distanceSquared);
    if (distanceSquared === 0) {
      return { nearest, distanceSquared };
    } else if (distanceSquared < result.distanceSquared) {
      result.nearest = nearest;
      result.distanceSquared = distanceSquared;
    }
  }
  return result;
}

// packages/ag-charts-community/src/scene/bbox.ts
var _BBox = class _BBox {
  constructor(x, y, width, height) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }
  clone() {
    const { x, y, width, height } = this;
    return new _BBox(x, y, width, height);
  }
  equals(other) {
    return this.x === other.x && this.y === other.y && this.width === other.width && this.height === other.height;
  }
  containsPoint(x, y) {
    return x >= this.x && x <= this.x + this.width && y >= this.y && y <= this.y + this.height;
  }
  collidesBBox(other) {
    return this.x < other.x + other.width && this.x + this.width > other.x && this.y < other.y + other.height && this.y + this.height > other.y;
  }
  isInfinite() {
    return Math.abs(this.x) === Infinity || Math.abs(this.y) === Infinity || Math.abs(this.width) === Infinity || Math.abs(this.height) === Infinity;
  }
  distanceSquared(point) {
    if (this.containsPoint(point.x, point.y)) {
      return 0;
    }
    const dx = point.x - Math.max(this.x, Math.min(point.x, this.x + this.width));
    const dy = point.y - Math.max(this.y, Math.min(point.y, this.y + this.height));
    return dx * dx + dy * dy;
  }
  static nearestBox(point, boxes) {
    return nearestSquared(point, boxes);
  }
  shrink(amount, position) {
    const apply = (pos, amt) => {
      switch (pos) {
        case "top":
          this.y += amt;
        case "bottom":
          this.height -= amt;
          break;
        case "left":
          this.x += amt;
        case "right":
          this.width -= amt;
          break;
        case "vertical":
          this.y += amt;
          this.height -= amt * 2;
          break;
        case "horizontal":
          this.x += amt;
          this.width -= amt * 2;
          break;
        case void 0:
          this.x += amt;
          this.width -= amt * 2;
          this.y += amt;
          this.height -= amt * 2;
          break;
      }
    };
    if (typeof amount === "number") {
      apply(position, amount);
    } else if (typeof amount === "object") {
      Object.entries(amount).forEach(([pos, amt]) => apply(pos, amt));
    }
    return this;
  }
  grow(amount, position) {
    if (typeof amount === "number") {
      this.shrink(-amount, position);
    } else {
      const paddingCopy = __spreadValues({}, amount);
      for (const key in paddingCopy) {
        paddingCopy[key] *= -1;
      }
      this.shrink(paddingCopy);
    }
    return this;
  }
  static merge(boxes) {
    let left = Infinity;
    let top = Infinity;
    let right = -Infinity;
    let bottom = -Infinity;
    boxes.forEach((box) => {
      if (box.x < left) {
        left = box.x;
      }
      if (box.y < top) {
        top = box.y;
      }
      if (box.x + box.width > right) {
        right = box.x + box.width;
      }
      if (box.y + box.height > bottom) {
        bottom = box.y + box.height;
      }
    });
    return new _BBox(left, top, right - left, bottom - top);
  }
};
_BBox.zero = new _BBox(0, 0, 0, 0);
var BBox = _BBox;

// packages/ag-charts-community/src/scene/matrix.ts
var _Matrix = class _Matrix {
  get e() {
    return [...this.elements];
  }
  constructor(elements = [1, 0, 0, 1, 0, 0]) {
    this.elements = elements;
  }
  setElements(elements) {
    const e = this.elements;
    e[0] = elements[0];
    e[1] = elements[1];
    e[2] = elements[2];
    e[3] = elements[3];
    e[4] = elements[4];
    e[5] = elements[5];
    return this;
  }
  get identity() {
    const e = this.elements;
    return e[0] === 1 && e[1] === 0 && e[2] === 0 && e[3] === 1 && e[4] === 0 && e[5] === 0;
  }
  /**
   * Performs the AxB matrix multiplication and saves the result
   * to `C`, if given, or to `A` otherwise.
   */
  AxB(A, B, C) {
    const a = A[0] * B[0] + A[2] * B[1], b = A[1] * B[0] + A[3] * B[1], c = A[0] * B[2] + A[2] * B[3], d = A[1] * B[2] + A[3] * B[3], e = A[0] * B[4] + A[2] * B[5] + A[4], f = A[1] * B[4] + A[3] * B[5] + A[5];
    C = C != null ? C : A;
    C[0] = a;
    C[1] = b;
    C[2] = c;
    C[3] = d;
    C[4] = e;
    C[5] = f;
  }
  /**
   * The `other` matrix gets post-multiplied to the current matrix.
   * Returns the current matrix.
   * @param other
   */
  multiplySelf(other) {
    this.AxB(this.elements, other.elements);
    return this;
  }
  /**
   * The `other` matrix gets post-multiplied to the current matrix.
   * Returns a new matrix.
   * @param other
   */
  multiply(other) {
    const elements = new Array(6);
    this.AxB(this.elements, other.elements, elements);
    return new _Matrix(elements);
  }
  preMultiplySelf(other) {
    this.AxB(other.elements, this.elements, this.elements);
    return this;
  }
  /**
   * Returns the inverse of this matrix as a new matrix.
   */
  inverse() {
    const el = this.elements;
    let a = el[0], b = el[1], c = el[2], d = el[3];
    const e = el[4], f = el[5];
    const rD = 1 / (a * d - b * c);
    a *= rD;
    b *= rD;
    c *= rD;
    d *= rD;
    return new _Matrix([d, -b, -c, a, c * f - d * e, b * e - a * f]);
  }
  /**
   * Save the inverse of this matrix to the given matrix.
   */
  inverseTo(other) {
    const el = this.elements;
    let a = el[0], b = el[1], c = el[2], d = el[3];
    const e = el[4], f = el[5];
    const rD = 1 / (a * d - b * c);
    a *= rD;
    b *= rD;
    c *= rD;
    d *= rD;
    other.setElements([d, -b, -c, a, c * f - d * e, b * e - a * f]);
    return this;
  }
  invertSelf() {
    const el = this.elements;
    let a = el[0], b = el[1], c = el[2], d = el[3];
    const e = el[4], f = el[5];
    const rD = 1 / (a * d - b * c);
    a *= rD;
    b *= rD;
    c *= rD;
    d *= rD;
    el[0] = d;
    el[1] = -b;
    el[2] = -c;
    el[3] = a;
    el[4] = c * f - d * e;
    el[5] = b * e - a * f;
    return this;
  }
  transformPoint(x, y) {
    const e = this.elements;
    return {
      x: x * e[0] + y * e[2] + e[4],
      y: x * e[1] + y * e[3] + e[5]
    };
  }
  transformBBox(bbox, target) {
    const elements = this.elements;
    const xx = elements[0];
    const xy = elements[1];
    const yx = elements[2];
    const yy = elements[3];
    const h_w = bbox.width * 0.5;
    const h_h = bbox.height * 0.5;
    const cx = bbox.x + h_w;
    const cy = bbox.y + h_h;
    const w = Math.abs(h_w * xx) + Math.abs(h_h * yx);
    const h = Math.abs(h_w * xy) + Math.abs(h_h * yy);
    if (!target) {
      target = new BBox(0, 0, 0, 0);
    }
    target.x = cx * xx + cy * yx + elements[4] - w;
    target.y = cx * xy + cy * yy + elements[5] - h;
    target.width = w + w;
    target.height = h + h;
    return target;
  }
  toContext(ctx) {
    if (this.identity) {
      return;
    }
    const e = this.elements;
    ctx.transform(e[0], e[1], e[2], e[3], e[4], e[5]);
  }
  static flyweight(sourceMatrix) {
    return _Matrix.instance.setElements(sourceMatrix.elements);
  }
  static updateTransformMatrix(matrix, scalingX, scalingY, rotation, translationX, translationY, opts) {
    const [bbcx, bbcy] = [0, 0];
    const sx = scalingX;
    const sy = scalingY;
    let scx;
    let scy;
    if (sx === 1 && sy === 1) {
      scx = 0;
      scy = 0;
    } else {
      scx = (opts == null ? void 0 : opts.scalingCenterX) == null ? bbcx : opts == null ? void 0 : opts.scalingCenterX;
      scy = (opts == null ? void 0 : opts.scalingCenterY) == null ? bbcy : opts == null ? void 0 : opts.scalingCenterY;
    }
    const r = rotation;
    const cos = Math.cos(r);
    const sin = Math.sin(r);
    let rcx;
    let rcy;
    if (r === 0) {
      rcx = 0;
      rcy = 0;
    } else {
      rcx = (opts == null ? void 0 : opts.rotationCenterX) == null ? bbcx : opts == null ? void 0 : opts.rotationCenterX;
      rcy = (opts == null ? void 0 : opts.rotationCenterY) == null ? bbcy : opts == null ? void 0 : opts.rotationCenterY;
    }
    const tx = translationX;
    const ty = translationY;
    const tx4 = scx * (1 - sx) - rcx;
    const ty4 = scy * (1 - sy) - rcy;
    matrix.setElements([
      cos * sx,
      sin * sx,
      -sin * sy,
      cos * sy,
      cos * tx4 - sin * ty4 + rcx + tx,
      sin * tx4 + cos * ty4 + rcy + ty
    ]);
    return matrix;
  }
  static fromContext(ctx) {
    const domMatrix = ctx.getTransform();
    return new _Matrix([domMatrix.a, domMatrix.b, domMatrix.c, domMatrix.d, domMatrix.e, domMatrix.f]);
  }
};
_Matrix.instance = new _Matrix();
var Matrix = _Matrix;

// packages/ag-charts-community/src/scene/node.ts
var PointerEvents = /* @__PURE__ */ ((PointerEvents2) => {
  PointerEvents2[PointerEvents2["All"] = 0] = "All";
  PointerEvents2[PointerEvents2["None"] = 1] = "None";
  return PointerEvents2;
})(PointerEvents || {});
var zIndexChangedCallback = (o) => {
  if (o.parent) {
    o.parent.dirtyZIndex = true;
  }
  o.zIndexChanged();
};
var _Node = class _Node extends ChangeDetectable {
  constructor({ isVirtual, tag } = {}) {
    super();
    /** Unique number to allow creation order to be easily determined. */
    this.serialNumber = _Node._nextSerialNumber++;
    /**
     * Unique node ID in the form `ClassName-NaturalNumber`.
     */
    this.id = createId$1(this);
    /**
     * To simplify the type system (especially in Selections) we don't have the `Parent` node
     * (one that has children). Instead, we mimic HTML DOM, where any node can have children.
     * But we still need to distinguish regular leaf nodes from container leafs somehow.
     */
    this.isContainerNode = false;
    this._virtualChildren = [];
    this._children = [];
    // Used to check for duplicate nodes.
    this.childSet = {};
    // These matrices may need to have package level visibility
    // for performance optimization purposes.
    this.matrix = new Matrix();
    this.inverseMatrix = new Matrix();
    this.dirtyTransform = false;
    this.scalingX = 1;
    this.scalingY = 1;
    this.scalingCenterX = null;
    this.scalingCenterY = null;
    this.rotationCenterX = null;
    this.rotationCenterY = null;
    this.rotation = 0;
    this.translationX = 0;
    this.translationY = 0;
    this.visible = true;
    this.dirtyZIndex = false;
    this.zIndex = 0;
    /** Discriminators for render order within a zIndex. */
    this.zIndexSubOrder = void 0;
    this.pointerEvents = 0 /* All */;
    this.isVirtual = isVirtual != null ? isVirtual : false;
    this.tag = tag != null ? tag : NaN;
  }
  /**
   * Some arbitrary data bound to the node.
   */
  get datum() {
    var _a, _b;
    return (_b = this._datum) != null ? _b : (_a = this._parent) == null ? void 0 : _a.datum;
  }
  get previousDatum() {
    return this._previousDatum;
  }
  set datum(datum) {
    if (this._datum !== datum)
      this._previousDatum = this._datum;
    this._datum = datum;
  }
  _setLayerManager(value) {
    this._layerManager = value;
    this._debug = value == null ? void 0 : value.debug;
    for (const child of this._children) {
      child._setLayerManager(value);
    }
    for (const child of this._virtualChildren) {
      child._setLayerManager(value);
    }
  }
  get layerManager() {
    return this._layerManager;
  }
  get parent() {
    return this._parent;
  }
  get children() {
    if (this._virtualChildren.length === 0)
      return this._children;
    const result = [...this._children];
    for (const next of this._virtualChildren) {
      result.push(...next.children);
    }
    return result;
  }
  get virtualChildren() {
    return this._virtualChildren;
  }
  hasVirtualChildren() {
    return this._virtualChildren.length > 0;
  }
  // new Set<Node>()
  setProperties(styles, pickKeys) {
    const keys = pickKeys != null ? pickKeys : Object.keys(styles);
    for (const key of keys) {
      this[key] = styles[key];
    }
    return this;
  }
  /**
   * Appends one or more new node instances to this parent.
   * If one needs to:
   * - move a child to the end of the list of children
   * - move a child from one parent to another (including parents in other scenes)
   * one should use the {@link insertBefore} method instead.
   * @param nodes A node or nodes to append.
   */
  append(nodes) {
    if (!Array.isArray(nodes)) {
      nodes = [nodes];
    }
    for (const node of nodes) {
      if (node.parent) {
        throw new Error(`${node} already belongs to another parent: ${node.parent}.`);
      }
      if (node.layerManager) {
        throw new Error(`${node} already belongs to a scene: ${node.layerManager}.`);
      }
      if (this.childSet[node.id]) {
        throw new Error(`Duplicate ${node.constructor.name} node: ${node}`);
      }
      if (node.isVirtual) {
        this._virtualChildren.push(node);
      } else {
        this._children.push(node);
      }
      this.childSet[node.id] = true;
      node._parent = this;
      node._setLayerManager(this.layerManager);
    }
    this.dirtyZIndex = true;
    this.markDirty(this, 3 /* MAJOR */);
  }
  appendChild(node) {
    this.append(node);
    return node;
  }
  removeChild(node) {
    const error = () => {
      throw new Error(`The node to be removed is not a child of this node.`);
    };
    if (node.parent !== this) {
      error();
    }
    if (node.isVirtual) {
      const i = this._virtualChildren.indexOf(node);
      if (i < 0)
        error();
      this._virtualChildren.splice(i, 1);
    } else {
      const i = this._children.indexOf(node);
      if (i < 0)
        error();
      this._children.splice(i, 1);
    }
    delete this.childSet[node.id];
    node._parent = void 0;
    node._setLayerManager();
    this.dirtyZIndex = true;
    this.markDirty(node, 3 /* MAJOR */);
    return node;
  }
  calculateCumulativeMatrix() {
    this.computeTransformMatrix();
    const matrix = Matrix.flyweight(this.matrix);
    let parent = this.parent;
    while (parent) {
      parent.computeTransformMatrix();
      matrix.preMultiplySelf(parent.matrix);
      parent = parent.parent;
    }
    return matrix;
  }
  transformPoint(x, y) {
    const matrix = this.calculateCumulativeMatrix();
    return matrix.invertSelf().transformPoint(x, y);
  }
  inverseTransformPoint(x, y) {
    const matrix = this.calculateCumulativeMatrix();
    return matrix.transformPoint(x, y);
  }
  transformBBox(bbox) {
    const matrix = this.calculateCumulativeMatrix();
    return matrix.invertSelf().transformBBox(bbox);
  }
  inverseTransformBBox(bbox) {
    const matrix = this.calculateCumulativeMatrix();
    return matrix.transformBBox(bbox);
  }
  markDirtyTransform() {
    this.dirtyTransform = true;
    this.markDirty(this, 3 /* MAJOR */);
  }
  containsPoint(_x, _y) {
    return false;
  }
  /**
   * Hit testing method.
   * Recursively checks if the given point is inside this node or any of its children.
   * Returns the first matching node or `undefined`.
   * Nodes that render later (show on top) are hit tested first.
   */
  pickNode(x, y) {
    var _a;
    if (!this.visible || this.pointerEvents === 1 /* None */ || !this.containsPoint(x, y)) {
      return;
    }
    const children = this.children;
    if (children.length > 1e3) {
      for (let i = children.length - 1; i >= 0; i--) {
        const child = children[i];
        const containsPoint = (_a = child.computeTransformedBBox()) == null ? void 0 : _a.containsPoint(x, y);
        const hit = containsPoint ? child.pickNode(x, y) : void 0;
        if (hit) {
          return hit;
        }
      }
    } else if (children.length) {
      for (let i = children.length - 1; i >= 0; i--) {
        const hit = children[i].pickNode(x, y);
        if (hit) {
          return hit;
        }
      }
    } else if (!this.isContainerNode) {
      return this;
    }
  }
  findNodes(predicate) {
    const result = predicate(this) ? [this] : [];
    for (const child of this.children) {
      const childResult = child.findNodes(predicate);
      if (childResult) {
        result.push(...childResult);
      }
    }
    return result;
  }
  computeBBox() {
    return;
  }
  computeTransformedBBox() {
    const bbox = this.computeBBox();
    if (!bbox) {
      return void 0;
    }
    this.computeTransformMatrix();
    const matrix = Matrix.flyweight(this.matrix);
    let parent = this.parent;
    while (parent) {
      parent.computeTransformMatrix();
      matrix.preMultiplySelf(parent.matrix);
      parent = parent.parent;
    }
    matrix.transformBBox(bbox, bbox);
    return bbox;
  }
  computeTransformMatrix() {
    if (!this.dirtyTransform) {
      return;
    }
    const {
      matrix,
      scalingX,
      scalingY,
      rotation,
      translationX,
      translationY,
      scalingCenterX,
      scalingCenterY,
      rotationCenterX,
      rotationCenterY
    } = this;
    Matrix.updateTransformMatrix(matrix, scalingX, scalingY, rotation, translationX, translationY, {
      scalingCenterX,
      scalingCenterY,
      rotationCenterX,
      rotationCenterY
    });
    matrix.inverseTo(this.inverseMatrix);
    this.dirtyTransform = false;
  }
  render(renderCtx) {
    const { stats } = renderCtx;
    this._dirty = 0 /* NONE */;
    if (stats)
      stats.nodesRendered++;
  }
  clearBBox(ctx) {
    const bbox = this.computeBBox();
    if (bbox == null) {
      return;
    }
    const { x, y, width, height } = bbox;
    const topLeft = this.transformPoint(x, y);
    const bottomRight = this.transformPoint(x + width, y + height);
    ctx.clearRect(topLeft.x, topLeft.y, bottomRight.x - topLeft.x, bottomRight.y - topLeft.y);
  }
  markDirty(_source, type = 1 /* TRIVIAL */, parentType = type) {
    if (this._dirty > type) {
      return;
    }
    if (this._dirty === type && type === parentType) {
      return;
    }
    this._dirty = type;
    if (this.parent) {
      this.parent.markDirty(this, parentType);
    } else if (this.layerManager) {
      this.layerManager.markDirty();
    }
  }
  get dirty() {
    return this._dirty;
  }
  markClean(opts) {
    const { force = false, recursive = true } = opts != null ? opts : {};
    if (this._dirty === 0 /* NONE */ && !force) {
      return;
    }
    this._dirty = 0 /* NONE */;
    if (recursive !== false) {
      for (const child of this._virtualChildren) {
        child.markClean({ force });
      }
    }
    if (recursive === true) {
      for (const child of this._children) {
        child.markClean({ force });
      }
    }
  }
  visibilityChanged() {
  }
  get nodeCount() {
    let count2 = 1;
    let dirtyCount = this._dirty >= 0 /* NONE */ || this.dirtyTransform ? 1 : 0;
    let visibleCount = this.visible ? 1 : 0;
    const countChild = (child) => {
      const { count: childCount, visibleCount: childVisibleCount, dirtyCount: childDirtyCount } = child.nodeCount;
      count2 += childCount;
      visibleCount += childVisibleCount;
      dirtyCount += childDirtyCount;
    };
    for (const child of this._children) {
      countChild(child);
    }
    for (const child of this._virtualChildren) {
      countChild(child);
    }
    return { count: count2, visibleCount, dirtyCount };
  }
  zIndexChanged() {
  }
};
_Node._nextSerialNumber = 0;
__decorateClass([
  SceneChangeDetection({ type: "transform" })
], _Node.prototype, "scalingX", 2);
__decorateClass([
  SceneChangeDetection({ type: "transform" })
], _Node.prototype, "scalingY", 2);
__decorateClass([
  SceneChangeDetection({ type: "transform" })
], _Node.prototype, "scalingCenterX", 2);
__decorateClass([
  SceneChangeDetection({ type: "transform" })
], _Node.prototype, "scalingCenterY", 2);
__decorateClass([
  SceneChangeDetection({ type: "transform" })
], _Node.prototype, "rotationCenterX", 2);
__decorateClass([
  SceneChangeDetection({ type: "transform" })
], _Node.prototype, "rotationCenterY", 2);
__decorateClass([
  SceneChangeDetection({ type: "transform" })
], _Node.prototype, "rotation", 2);
__decorateClass([
  SceneChangeDetection({ type: "transform" })
], _Node.prototype, "translationX", 2);
__decorateClass([
  SceneChangeDetection({ type: "transform" })
], _Node.prototype, "translationY", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */, changeCb: (o) => o.visibilityChanged() })
], _Node.prototype, "visible", 2);
__decorateClass([
  SceneChangeDetection({
    redraw: 1 /* TRIVIAL */,
    changeCb: zIndexChangedCallback
  })
], _Node.prototype, "zIndex", 2);
__decorateClass([
  SceneChangeDetection({
    redraw: 1 /* TRIVIAL */,
    changeCb: zIndexChangedCallback
  })
], _Node.prototype, "zIndexSubOrder", 2);
var Node = _Node;

// packages/ag-charts-community/src/util/userAgent.ts
function hasConstrainedCanvasMemory() {
  if (typeof navigator === "undefined")
    return false;
  const iPhoneOSMatch = navigator.userAgent.match(/\(iPhone; CPU iPhone OS (\d+_\d+_\d+) like Mac OS X\)/);
  if (iPhoneOSMatch == null)
    return false;
  const versionString = iPhoneOSMatch[1].split("_");
  const major = Number(versionString[0]);
  if (major > 16) {
    return false;
  } else if (major === 16) {
    const minor = Number(versionString[1]);
    return minor < 6;
  }
  return true;
}

// packages/ag-charts-community/src/scene/canvas/hdpiCanvas.ts
var _HdpiCanvas = class _HdpiCanvas {
  // The width/height attributes of the Canvas element default to
  // 300/150 according to w3.org.
  constructor(opts) {
    this._enabled = true;
    // `NaN` is deliberate here, so that overrides are always applied
    // and the `resetTransform` inside the `resize` method works in IE11.
    this._pixelRatio = NaN;
    this._width = 0;
    this._height = 0;
    const {
      document: document2,
      window: window2,
      width = 600,
      height = 300,
      domLayer = false,
      zIndex = 0,
      name = void 0,
      overrideDevicePixelRatio = void 0
    } = opts;
    this.document = document2;
    this.window = window2;
    _HdpiCanvas.document = document2;
    this.element = document2.createElement("canvas");
    this.element.width = width;
    this.element.height = height;
    this.realContext = this.element.getContext("2d");
    this.imageSource = this.realContext.canvas;
    const { style } = this.element;
    style.userSelect = "none";
    style.display = "block";
    if (domLayer) {
      style.position = "absolute";
      style.zIndex = String(zIndex);
      style.top = "0";
      style.left = "0";
      style.pointerEvents = "none";
      style.opacity = `1`;
      if (name) {
        this.element.id = name;
      }
    }
    this.context = this.setPixelRatio(overrideDevicePixelRatio);
    this.resize(width, height);
  }
  set container(value) {
    if (this._container !== value) {
      this.remove();
      if (value) {
        value.appendChild(this.element);
      }
      this._container = value;
    }
  }
  get container() {
    return this._container;
  }
  set enabled(value) {
    this.element.style.display = value ? "block" : "none";
    this._enabled = !!value;
  }
  get enabled() {
    return this._enabled;
  }
  remove() {
    const { parentNode } = this.element;
    if (parentNode != null) {
      parentNode.removeChild(this.element);
    }
  }
  destroy() {
    this.element.remove();
    this.element.width = 0;
    this.element.height = 0;
    this.context.clearRect(0, 0, 0, 0);
    Object.freeze(this);
  }
  snapshot() {
  }
  clear() {
    this.context.save();
    this.context.resetTransform();
    this.context.clearRect(0, 0, this.width, this.height);
    this.context.restore();
  }
  toImage() {
    const img = this.document.createElement("img");
    img.src = this.getDataURL();
    return img;
  }
  getDataURL(type) {
    return this.element.toDataURL(type);
  }
  /**
   * @param fileName The name of the downloaded file.
   * @param fileFormat The file format, the default is `image/png`
   */
  download(fileName, fileFormat = "image/png") {
    fileName = (fileName != null ? fileName : "").trim() || "image";
    const dataUrl = this.getDataURL(fileFormat);
    const document2 = this.document;
    const a = document2.createElement("a");
    a.href = dataUrl;
    a.download = fileName;
    a.style.display = "none";
    document2.body.appendChild(a);
    a.click();
    document2.body.removeChild(a);
  }
  get pixelRatio() {
    return this._pixelRatio;
  }
  /**
   * Changes the pixel ratio of the Canvas element to the given value,
   * or uses the window.devicePixelRatio (default), then resizes the Canvas
   * element accordingly (default).
   */
  setPixelRatio(ratio) {
    let pixelRatio = ratio != null ? ratio : this.window.devicePixelRatio;
    if (hasConstrainedCanvasMemory()) {
      pixelRatio = 1;
    }
    this._pixelRatio = pixelRatio;
    return _HdpiCanvas.overrideScale(this.realContext, pixelRatio);
  }
  set pixelated(value) {
    this.element.style.imageRendering = value ? "pixelated" : "auto";
  }
  get pixelated() {
    return this.element.style.imageRendering === "pixelated";
  }
  get width() {
    return this._width;
  }
  get height() {
    return this._height;
  }
  resize(width, height) {
    if (!(width > 0 && height > 0)) {
      return;
    }
    const { element: element2, context, pixelRatio } = this;
    element2.width = Math.round(width * pixelRatio);
    element2.height = Math.round(height * pixelRatio);
    element2.style.width = width + "px";
    element2.style.height = height + "px";
    context.resetTransform();
    this._width = width;
    this._height = height;
  }
  static get textMeasuringContext() {
    if (this._textMeasuringContext) {
      return this._textMeasuringContext;
    }
    const canvas = this.document.createElement("canvas");
    this._textMeasuringContext = canvas.getContext("2d");
    return this._textMeasuringContext;
  }
  static get svgText() {
    if (this._svgText) {
      return this._svgText;
    }
    const xmlns = "http://www.w3.org/2000/svg";
    const svg = document.createElementNS(xmlns, "svg");
    svg.setAttribute("width", "100");
    svg.setAttribute("height", "100");
    if (svg.classList) {
      svg.classList.add("text-measuring-svg");
    } else {
      svg.setAttribute("class", "text-measuring-svg");
    }
    svg.style.position = "absolute";
    svg.style.top = "-1000px";
    svg.style.visibility = "hidden";
    const svgText = document.createElementNS(xmlns, "text");
    svgText.setAttribute("x", "0");
    svgText.setAttribute("y", "30");
    svgText.setAttribute("text", "black");
    svg.appendChild(svgText);
    document.body.appendChild(svg);
    this._svgText = svgText;
    return svgText;
  }
  static get has() {
    if (this._has) {
      return this._has;
    }
    const isChrome = typeof navigator === "undefined" || navigator.userAgent.indexOf("Chrome") > -1;
    const isFirefox = typeof navigator !== "undefined" && navigator.userAgent.indexOf("Firefox") > -1;
    const isSafari = !isChrome && typeof navigator !== "undefined" && navigator.userAgent.indexOf("Safari") > -1;
    this._has = Object.freeze({
      textMetrics: this.textMeasuringContext.measureText("test").actualBoundingBoxDescent !== void 0 && // Firefox implemented advanced TextMetrics object in v74:
      // https://bugzilla.mozilla.org/show_bug.cgi?id=1102584
      // but it's buggy, so we'll keep using the SVG for text measurement in Firefox for now.
      !isFirefox && !isSafari,
      getTransform: this.textMeasuringContext.getTransform !== void 0
    });
    return this._has;
  }
  static measureText(text, font, textBaseline, textAlign) {
    const ctx = this.textMeasuringContext;
    ctx.font = font;
    ctx.textBaseline = textBaseline;
    ctx.textAlign = textAlign;
    return ctx.measureText(text);
  }
  /**
   * Returns the width and height of the measured text.
   * @param text The single-line text to measure.
   * @param font The font shorthand string.
   */
  static getTextSize(text, font) {
    if (this.has.textMetrics) {
      const ctx = this.textMeasuringContext;
      ctx.font = font;
      const metrics = ctx.measureText(text);
      return {
        width: metrics.width,
        height: metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
      };
    } else {
      return this.measureSvgText(text, font);
    }
  }
  static measureSvgText(text, font) {
    const cache = this.textSizeCache;
    const fontCache = cache[font];
    if (fontCache) {
      const size2 = fontCache[text];
      if (size2) {
        return size2;
      }
    } else {
      cache[font] = {};
    }
    const svgText = this.svgText;
    svgText.style.font = font;
    svgText.textContent = text;
    const bbox = svgText.getBBox();
    const size = {
      width: bbox.width,
      height: bbox.height
    };
    cache[font][text] = size;
    return size;
  }
  static overrideScale(ctx, scale2) {
    let depth = 0;
    const overrides = {
      save() {
        this.$save();
        depth++;
      },
      restore() {
        if (depth > 0) {
          this.$restore();
          depth--;
        } else {
          throw new Error("AG Charts - Unable to restore() past depth 0");
        }
      },
      setTransform(a, b, c, d, e, f) {
        if (typeof a === "object") {
          this.$setTransform(a);
        } else {
          this.$setTransform(a * scale2, b * scale2, c * scale2, d * scale2, e * scale2, f * scale2);
        }
      },
      resetTransform() {
        this.$setTransform(scale2, 0, 0, scale2, 0, 0);
      },
      verifyDepthZero() {
        if (depth !== 0) {
          throw new Error("AG Charts - Save/restore depth is non-zero: " + depth);
        }
      }
    };
    for (const name in overrides) {
      if (Object.hasOwn(overrides, name)) {
        if (!ctx["$" + name]) {
          ctx["$" + name] = ctx[name];
        }
        ctx[name] = overrides[name];
      }
    }
    return ctx;
  }
};
_HdpiCanvas.document = globalThis.document;
_HdpiCanvas.textSizeCache = {};
var HdpiCanvas = _HdpiCanvas;

// packages/ag-charts-community/src/scene/gradient/gradient.ts
var Gradient = class {
  constructor() {
    this.stops = [];
  }
};

// packages/ag-charts-community/src/scene/gradient/linearGradient.ts
var LinearGradient = class extends Gradient {
  constructor() {
    super(...arguments);
    this.angle = 0;
  }
  createGradient(ctx, bbox) {
    const angleOffset = 90;
    const { stops, angle } = this;
    const radians = normalizeAngle360(toRadians(angle + angleOffset));
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const w = bbox.width;
    const h = bbox.height;
    const cx = bbox.x + w * 0.5;
    const cy = bbox.y + h * 0.5;
    if (w > 0 && h > 0) {
      const diagonal = Math.sqrt(h * h + w * w) / 2;
      const diagonalAngle = Math.atan2(h, w);
      let quarteredAngle;
      if (radians < Math.PI / 2) {
        quarteredAngle = radians;
      } else if (radians < Math.PI) {
        quarteredAngle = Math.PI - radians;
      } else if (radians < 3 * Math.PI / 2) {
        quarteredAngle = radians - Math.PI;
      } else {
        quarteredAngle = 2 * Math.PI - radians;
      }
      const l = diagonal * Math.abs(Math.cos(quarteredAngle - diagonalAngle));
      const gradient = ctx.createLinearGradient(cx + cos * l, cy + sin * l, cx - cos * l, cy - sin * l);
      stops.forEach((stop) => {
        gradient.addColorStop(stop.offset, stop.color);
      });
      return gradient;
    }
    return "black";
  }
};

// packages/ag-charts-community/src/scene/shape/shape.ts
var LINEAR_GRADIENT_REGEXP = /^linear-gradient\((.*?)deg,\s*(.*?)\s*\)$/i;
var _Shape = class _Shape extends Node {
  constructor() {
    super(...arguments);
    this.fillOpacity = 1;
    this.strokeOpacity = 1;
    this.fill = _Shape.defaultStyles.fill;
    this.stroke = _Shape.defaultStyles.stroke;
    this.strokeWidth = _Shape.defaultStyles.strokeWidth;
    this.lineDash = _Shape.defaultStyles.lineDash;
    this.lineDashOffset = _Shape.defaultStyles.lineDashOffset;
    this.lineCap = _Shape.defaultStyles.lineCap;
    this.lineJoin = _Shape.defaultStyles.lineJoin;
    this.opacity = _Shape.defaultStyles.opacity;
    this.fillShadow = _Shape.defaultStyles.fillShadow;
  }
  /**
   * Restores the default styles introduced by this subclass.
   */
  restoreOwnStyles() {
    const styles = this.constructor.defaultStyles;
    const keys = Object.getOwnPropertyNames(styles);
    for (let i = 0, n = keys.length; i < n; i++) {
      const key = keys[i];
      this[key] = styles[key];
    }
  }
  updateGradient() {
    const { fill } = this;
    let linearGradientMatch;
    if ((fill == null ? void 0 : fill.startsWith("linear-gradient")) && (linearGradientMatch = LINEAR_GRADIENT_REGEXP.exec(fill))) {
      const angle = parseFloat(linearGradientMatch[1]);
      const colors = [];
      const colorsPart = linearGradientMatch[2];
      const colorRegex = /(#[0-9a-f]+)|(rgba?\(.+?\))|([a-z]+)/gi;
      let c;
      while (c = colorRegex.exec(colorsPart)) {
        colors.push(c[0]);
      }
      this.gradient = new LinearGradient();
      this.gradient.angle = angle;
      this.gradient.stops = colors.map((color, index) => {
        const offset4 = index / (colors.length - 1);
        return { offset: offset4, color };
      });
    } else {
      this.gradient = void 0;
    }
  }
  /**
   * Returns a device-pixel aligned coordinate (or length if length is supplied).
   *
   * NOTE: Not suitable for strokes, since the stroke needs to be offset to the middle
   * of a device pixel.
   */
  align(start, length) {
    var _a, _b, _c;
    const pixelRatio = (_c = (_b = (_a = this.layerManager) == null ? void 0 : _a.canvas) == null ? void 0 : _b.pixelRatio) != null ? _c : 1;
    const alignedStart = Math.round(start * pixelRatio) / pixelRatio;
    if (length == void 0) {
      return alignedStart;
    }
    if (length === 0) {
      return 0;
    }
    if (length < 1) {
      return Math.ceil(length * pixelRatio) / pixelRatio;
    }
    return Math.round((length + start) * pixelRatio) / pixelRatio - alignedStart;
  }
  fillStroke(ctx) {
    this.renderFill(ctx);
    this.renderStroke(ctx);
  }
  renderFill(ctx) {
    if (this.fill) {
      const { globalAlpha } = ctx;
      this.applyFill(ctx);
      this.applyFillAlpha(ctx);
      this.applyShadow(ctx);
      ctx.fill();
      ctx.globalAlpha = globalAlpha;
    }
    ctx.shadowColor = "rgba(0, 0, 0, 0)";
  }
  applyFill(ctx) {
    if (this.gradient) {
      ctx.fillStyle = this.gradient.createGradient(ctx, this.computeBBox());
    } else {
      ctx.fillStyle = this.fill;
    }
  }
  applyFillAlpha(ctx) {
    const { globalAlpha } = ctx;
    ctx.globalAlpha = globalAlpha * this.opacity * this.fillOpacity;
  }
  applyShadow(ctx) {
    var _a, _b;
    const pixelRatio = (_b = (_a = this.layerManager) == null ? void 0 : _a.canvas.pixelRatio) != null ? _b : 1;
    const fillShadow = this.fillShadow;
    if (fillShadow == null ? void 0 : fillShadow.enabled) {
      ctx.shadowColor = fillShadow.color;
      ctx.shadowOffsetX = fillShadow.xOffset * pixelRatio;
      ctx.shadowOffsetY = fillShadow.yOffset * pixelRatio;
      ctx.shadowBlur = fillShadow.blur * pixelRatio;
    }
  }
  renderStroke(ctx) {
    if (this.stroke && this.strokeWidth) {
      const { globalAlpha } = ctx;
      ctx.strokeStyle = this.stroke;
      ctx.globalAlpha = globalAlpha * this.opacity * this.strokeOpacity;
      ctx.lineWidth = this.strokeWidth;
      if (this.lineDash) {
        ctx.setLineDash(this.lineDash);
      }
      if (this.lineDashOffset) {
        ctx.lineDashOffset = this.lineDashOffset;
      }
      if (this.lineCap) {
        ctx.lineCap = this.lineCap;
      }
      if (this.lineJoin) {
        ctx.lineJoin = this.lineJoin;
      }
      ctx.stroke();
      ctx.globalAlpha = globalAlpha;
    }
  }
  containsPoint(x, y) {
    return this.isPointInPath(x, y);
  }
};
/**
 * Defaults for style properties. Note that properties that affect the position
 * and shape of the node are not considered style properties, for example:
 * `x`, `y`, `width`, `height`, `radius`, `rotation`, etc.
 * Can be used to reset to the original styling after some custom styling
 * has been applied (using the `restoreOwnStyles` method).
 * These static defaults are meant to be inherited by subclasses.
 */
_Shape.defaultStyles = Object.assign(
  {},
  {
    fill: "black",
    stroke: void 0,
    strokeWidth: 0,
    lineDash: void 0,
    lineDashOffset: 0,
    lineCap: void 0,
    lineJoin: void 0,
    opacity: 1,
    fillShadow: void 0
  }
);
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */ })
], _Shape.prototype, "fillOpacity", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */ })
], _Shape.prototype, "strokeOpacity", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */, changeCb: (s) => s.updateGradient() })
], _Shape.prototype, "fill", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */ })
], _Shape.prototype, "stroke", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */ })
], _Shape.prototype, "strokeWidth", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */ })
], _Shape.prototype, "lineDash", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */ })
], _Shape.prototype, "lineDashOffset", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */ })
], _Shape.prototype, "lineCap", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */ })
], _Shape.prototype, "lineJoin", 2);
__decorateClass([
  SceneChangeDetection({
    redraw: 2 /* MINOR */,
    convertor: (v) => Math.min(1, Math.max(0, v))
  })
], _Shape.prototype, "opacity", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */, checkDirtyOnAssignment: true })
], _Shape.prototype, "fillShadow", 2);
var Shape = _Shape;

// packages/ag-charts-community/src/scene/shape/text.ts
var ellipsis = "\u2026";
function SceneFontChangeDetection(opts) {
  const { redraw = 3 /* MAJOR */, changeCb } = opts != null ? opts : {};
  return SceneChangeDetection({ redraw, type: "font", changeCb });
}
var _Text = class _Text extends Shape {
  constructor() {
    super(...arguments);
    this.x = 0;
    this.y = 0;
    this.lines = [];
    this.text = void 0;
    this._dirtyFont = true;
    this.fontSize = 10;
    this.fontFamily = "sans-serif";
    this.textAlign = _Text.defaultStyles.textAlign;
    this.textBaseline = _Text.defaultStyles.textBaseline;
    this.lineHeight = void 0;
  }
  _setLines() {
    this.lines = splitText(this.text);
  }
  get font() {
    if (this._font == null || this._dirtyFont) {
      this._dirtyFont = false;
      this._font = getFont(this);
    }
    return this._font;
  }
  computeBBox() {
    return HdpiCanvas.has.textMetrics ? getPreciseBBox(this.lines, this.x, this.y, this) : getApproximateBBox(this.lines, this.x, this.y, this);
  }
  getLineHeight(line) {
    var _a, _b;
    if (this.lineHeight)
      return this.lineHeight;
    if (HdpiCanvas.has.textMetrics) {
      const metrics = HdpiCanvas.measureText(line, this.font, this.textBaseline, this.textAlign);
      return ((_a = metrics.fontBoundingBoxAscent) != null ? _a : metrics.emHeightAscent) + ((_b = metrics.fontBoundingBoxDescent) != null ? _b : metrics.emHeightDescent);
    }
    return HdpiCanvas.getTextSize(line, this.font).height;
  }
  isPointInPath(x, y) {
    const point = this.transformPoint(x, y);
    const bbox = this.computeBBox();
    return bbox ? bbox.containsPoint(point.x, point.y) : false;
  }
  render(renderCtx) {
    const { ctx, forceRender, stats } = renderCtx;
    if (this.dirty === 0 /* NONE */ && !forceRender) {
      if (stats)
        stats.nodesSkipped += this.nodeCount.count;
      return;
    }
    if (!this.lines.length || !this.layerManager) {
      if (stats)
        stats.nodesSkipped += this.nodeCount.count;
      return;
    }
    this.computeTransformMatrix();
    this.matrix.toContext(ctx);
    const { fill, stroke, strokeWidth } = this;
    ctx.font = this.font;
    ctx.textAlign = this.textAlign;
    ctx.textBaseline = this.textBaseline;
    const pixelRatio = this.layerManager.canvas.pixelRatio || 1;
    const { globalAlpha } = ctx;
    if (fill) {
      ctx.fillStyle = fill;
      ctx.globalAlpha = globalAlpha * this.opacity * this.fillOpacity;
      const { fillShadow } = this;
      if (fillShadow == null ? void 0 : fillShadow.enabled) {
        ctx.shadowColor = fillShadow.color;
        ctx.shadowOffsetX = fillShadow.xOffset * pixelRatio;
        ctx.shadowOffsetY = fillShadow.yOffset * pixelRatio;
        ctx.shadowBlur = fillShadow.blur * pixelRatio;
      }
      this.renderLines((line, x, y) => ctx.fillText(line, x, y));
    }
    if (stroke && strokeWidth) {
      ctx.strokeStyle = stroke;
      ctx.lineWidth = strokeWidth;
      ctx.globalAlpha = globalAlpha * this.opacity * this.strokeOpacity;
      const { lineDash, lineDashOffset, lineCap, lineJoin } = this;
      if (lineDash) {
        ctx.setLineDash(lineDash);
      }
      if (lineDashOffset) {
        ctx.lineDashOffset = lineDashOffset;
      }
      if (lineCap) {
        ctx.lineCap = lineCap;
      }
      if (lineJoin) {
        ctx.lineJoin = lineJoin;
      }
      this.renderLines((line, x, y) => ctx.strokeText(line, x, y));
    }
    super.render(renderCtx);
  }
  renderLines(renderCallback) {
    const { lines, x, y } = this;
    const lineHeights = this.lines.map((line) => this.getLineHeight(line));
    const totalHeight = lineHeights.reduce((a, b) => a + b, 0);
    let offsetY = -(totalHeight - lineHeights[0]) * getVerticalOffset(this.textBaseline);
    for (let i = 0; i < lines.length; i++) {
      renderCallback(lines[i], x, y + offsetY);
      offsetY += lineHeights[i];
    }
  }
  static wrapLines(text, maxWidth, maxHeight, textProps, wrapping, overflow) {
    const canOverflow = overflow !== "hide";
    const font = getFont(textProps);
    const measurer = createTextMeasurer(font);
    const lines = text.split(/\r?\n/g);
    if (lines.length === 0) {
      return { lines: void 0, truncated: false };
    }
    if (wrapping === "never") {
      const { text: text2, truncated: truncated2 } = _Text.truncateLine(lines[0], maxWidth, measurer, canOverflow ? "auto" : "never");
      return { lines: text2 != null ? [text2] : void 0, truncated: truncated2 };
    }
    const wrappedLines = [];
    let cumulativeHeight = 0;
    let truncated = false;
    for (const line of lines) {
      const wrappedLine = _Text.wrapLine(
        line,
        maxWidth,
        maxHeight,
        measurer,
        textProps,
        wrapping,
        cumulativeHeight,
        canOverflow
      );
      if (wrappedLine == null) {
        return { lines: void 0, truncated: false };
      }
      wrappedLines.push(...wrappedLine.result);
      cumulativeHeight = wrappedLine.cumulativeHeight;
      if (wrappedLine.truncated) {
        truncated = true;
        break;
      }
    }
    return { lines: wrappedLines, truncated };
  }
  static wrap(text, maxWidth, maxHeight, textProps, wrapping, overflow = "ellipsis") {
    const { lines, truncated } = _Text.wrapLines(text, maxWidth, maxHeight, textProps, wrapping, overflow);
    return { text: lines != null ? lines.join("\n").trim() : "", truncated };
  }
  static wrapLine(text, maxWidth, maxHeight, measurer, textProps, wrapping, cumulativeHeight, canOverflow) {
    text = text.trim();
    if (!text) {
      return { result: [], truncated: false, cumulativeHeight };
    }
    const initialSize = measurer.size(text);
    if (initialSize.width <= maxWidth) {
      return {
        result: [text],
        truncated: false,
        cumulativeHeight: cumulativeHeight + initialSize.height
      };
    }
    if (initialSize.height > maxHeight || measurer.width("W") > maxWidth) {
      return canOverflow ? { result: [], truncated: true, cumulativeHeight } : void 0;
    }
    const words = text.split(/\s+/g);
    const wrapResult = _Text.wrapLineSequentially(
      words,
      maxWidth,
      maxHeight,
      measurer,
      textProps,
      wrapping,
      cumulativeHeight,
      canOverflow
    );
    if (wrapResult == null) {
      return void 0;
    }
    cumulativeHeight = wrapResult.cumulativeHeight;
    let { lines } = wrapResult;
    if (!(wrapResult.wordsBrokenOrTruncated || wrapResult.linesTruncated)) {
      const linesCount = wrapResult.lines.length;
      const balanced = _Text.wrapLineBalanced(words, maxWidth, measurer, linesCount);
      if (balanced.length === lines.length) {
        lines = balanced;
      }
    }
    const wrappedText = lines.map((ln) => ln.join(" "));
    return { result: wrappedText, truncated: wrapResult.linesTruncated, cumulativeHeight };
  }
  static breakWord(word, firstLineWidth, maxWidth, hyphens, measurer) {
    const isPunctuationAt = (index) => _Text.punctuationMarks.includes(word[index]);
    const h = hyphens ? measurer.width("-") : 0;
    const breaks = [];
    let partWidth = 0;
    let p = 0;
    for (let i = 0; i < word.length; i++) {
      const c = word[i];
      const w = measurer.width(c);
      const limit = p === 0 ? firstLineWidth : maxWidth;
      if (partWidth + w + h > limit) {
        breaks.push(i);
        partWidth = 0;
        p++;
      }
      partWidth += w;
    }
    const parts = [];
    let start = 0;
    for (const index of breaks) {
      let part = word.substring(start, index);
      if (hyphens && part.length > 0 && !isPunctuationAt(index - 1) && !isPunctuationAt(index)) {
        part += "-";
      }
      parts.push(part);
      start = index;
    }
    parts.push(word.substring(start));
    return parts;
  }
  static truncateLine(text, maxWidth, measurer, ellipsisMode) {
    text = text.trimEnd();
    const lineWidth = measurer.width(text);
    if (lineWidth > maxWidth && ellipsisMode === "never") {
      return { text: void 0, truncated: false };
    } else if (lineWidth <= maxWidth && ellipsisMode !== "force") {
      return { text, truncated: false };
    }
    const ellipsisWidth = measurer.width(ellipsis);
    let trunc = text;
    let truncWidth = lineWidth;
    while (trunc.length > 0 && truncWidth + ellipsisWidth > maxWidth) {
      trunc = trunc.slice(0, -1).trimEnd();
      truncWidth = measurer.width(trunc);
    }
    if (truncWidth + ellipsisWidth <= maxWidth) {
      return { text: `${trunc}${ellipsis}`, truncated: true };
    } else {
      return { text: void 0, truncated: false };
    }
  }
  static wrapLineSequentially(words, maxWidth, maxHeight, measurer, textProps, wrapping, cumulativeHeight, canOverflow) {
    const { fontSize = 0, lineHeight = fontSize * _Text.defaultLineHeightRatio } = textProps;
    const breakWord = wrapping === "always" || wrapping === "hyphenate";
    const hyphenate = wrapping === "hyphenate";
    const spaceWidth = measurer.width(" ");
    let wordsBrokenOrTruncated = false;
    let linesTruncated = false;
    const lines = [];
    let currentLine = [];
    let lineWidth = 0;
    const getReturnValue = () => ({
      lines,
      linesTruncated,
      wordsBrokenOrTruncated,
      cumulativeHeight
    });
    const truncateLastLine = () => {
      if (!canOverflow) {
        return void 0;
      }
      const lastLine = currentLine.join(" ");
      const { text } = _Text.truncateLine(lastLine, maxWidth, measurer, "force");
      if (text == null) {
        return void 0;
      }
      currentLine.splice(0, currentLine.length, text);
      linesTruncated = true;
      return getReturnValue();
    };
    const addNewLine = () => {
      const expectedHeight = cumulativeHeight + lineHeight;
      if (expectedHeight >= maxHeight) {
        return false;
      }
      currentLine = [];
      lineWidth = 0;
      cumulativeHeight = expectedHeight;
      lines.push(currentLine);
      return true;
    };
    if (!addNewLine()) {
      return truncateLastLine();
    }
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      const wordWidth = measurer.width(word);
      const expectedSpaceWidth = currentLine.length === 0 ? 0 : spaceWidth;
      const expectedLineWidth = lineWidth + expectedSpaceWidth + wordWidth;
      if (expectedLineWidth <= maxWidth) {
        currentLine.push(word);
        lineWidth = expectedLineWidth;
        continue;
      }
      if (wordWidth <= maxWidth) {
        if (!addNewLine()) {
          return truncateLastLine();
        }
        currentLine.push(word);
        lineWidth = wordWidth;
        continue;
      }
      wordsBrokenOrTruncated = true;
      if (breakWord) {
        const availWidth = maxWidth - lineWidth - expectedSpaceWidth;
        const parts = _Text.breakWord(word, availWidth, maxWidth, hyphenate, measurer);
        for (let p = 0; p < parts.length; p++) {
          const part = parts[p];
          part && currentLine.push(part);
          if (p === parts.length - 1) {
            lineWidth = measurer.width(part);
          } else if (!addNewLine()) {
            return truncateLastLine();
          }
        }
      } else if (canOverflow) {
        if (!addNewLine()) {
          return truncateLastLine();
        }
        const { text } = _Text.truncateLine(word, maxWidth, measurer, "force");
        if (text == null) {
          return void 0;
        }
        currentLine.push(text);
        if (i < words.length - 1) {
          linesTruncated = true;
        }
        break;
      } else {
        return void 0;
      }
    }
    return getReturnValue();
  }
  static wrapLineBalanced(words, maxWidth, measurer, linesCount) {
    const totalWordsWidth = words.reduce((sum2, w) => sum2 + measurer.width(w), 0);
    const spaceWidth = measurer.width(" ");
    const totalSpaceWidth = spaceWidth * (words.length - linesCount - 2);
    const averageLineWidth = (totalWordsWidth + totalSpaceWidth) / linesCount;
    const lines = [];
    let currentLine = [];
    let lineWidth = measurer.width(words[0]);
    let newLine = true;
    for (const word of words) {
      const width = measurer.width(word);
      if (newLine) {
        currentLine = [];
        currentLine.push(word);
        lineWidth = width;
        newLine = false;
        lines.push(currentLine);
        continue;
      }
      const expectedLineWidth = lineWidth + spaceWidth + width;
      if (expectedLineWidth <= averageLineWidth) {
        currentLine.push(word);
        lineWidth = expectedLineWidth;
      } else if (expectedLineWidth <= maxWidth) {
        currentLine.push(word);
        newLine = true;
      } else {
        currentLine = [word];
        lineWidth = width;
        lines.push(currentLine);
      }
    }
    return lines;
  }
  setFont(props) {
    this.fontFamily = props.fontFamily;
    this.fontSize = props.fontSize;
    this.fontStyle = props.fontStyle;
    this.fontWeight = props.fontWeight;
  }
  setAlign(props) {
    this.textAlign = props.textAlign;
    this.textBaseline = props.textBaseline;
  }
};
_Text.className = "Text";
// The default line spacing for document editors is usually 1.15
_Text.defaultLineHeightRatio = 1.15;
_Text.defaultStyles = Object.assign({}, Shape.defaultStyles, {
  textAlign: "start",
  fontStyle: void 0,
  fontWeight: void 0,
  fontSize: 10,
  fontFamily: "sans-serif",
  textBaseline: "alphabetic"
});
_Text.ellipsis = ellipsis;
_Text.punctuationMarks = [".", ",", "-", ":", ";", "!", "?", `'`, '"', "(", ")"];
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], _Text.prototype, "x", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], _Text.prototype, "y", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */, changeCb: (o) => o._setLines() })
], _Text.prototype, "text", 2);
__decorateClass([
  SceneFontChangeDetection()
], _Text.prototype, "fontStyle", 2);
__decorateClass([
  SceneFontChangeDetection()
], _Text.prototype, "fontWeight", 2);
__decorateClass([
  SceneFontChangeDetection()
], _Text.prototype, "fontSize", 2);
__decorateClass([
  SceneFontChangeDetection()
], _Text.prototype, "fontFamily", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], _Text.prototype, "textAlign", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], _Text.prototype, "textBaseline", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], _Text.prototype, "lineHeight", 2);
var Text = _Text;
function createTextMeasurer(font) {
  const cache = /* @__PURE__ */ new Map();
  const getTextSize = (text) => HdpiCanvas.getTextSize(text, font);
  const getLineWidth = (text) => {
    if (cache.has(text)) {
      return cache.get(text);
    }
    const { width } = getTextSize(text);
    cache.set(text, width);
    return width;
  };
  return { size: getTextSize, width: getLineWidth };
}
function getFont(fontProps) {
  const { fontFamily, fontSize, fontStyle, fontWeight } = fontProps;
  return [fontStyle != null ? fontStyle : "", fontWeight != null ? fontWeight : "", fontSize + "px", fontFamily].join(" ").trim();
}
function measureText(lines, x, y, textProps) {
  return HdpiCanvas.has.textMetrics ? getPreciseBBox(lines, x, y, textProps) : getApproximateBBox(lines, x, y, textProps);
}
function getPreciseBBox(lines, x, y, textProps) {
  var _a, _b;
  let left = 0;
  let top = 0;
  let width = 0;
  let height = 0;
  let baselineDistance = 0;
  const font = getFont(textProps);
  const {
    lineHeight,
    textBaseline = Text.defaultStyles.textBaseline,
    textAlign = Text.defaultStyles.textAlign
  } = textProps;
  for (let i = 0; i < lines.length; i++) {
    const metrics = HdpiCanvas.measureText(lines[i], font, textBaseline, textAlign);
    left = Math.max(left, metrics.actualBoundingBoxLeft);
    width = Math.max(width, metrics.width);
    if (i == 0) {
      top += metrics.actualBoundingBoxAscent;
      height += metrics.actualBoundingBoxAscent;
    } else {
      baselineDistance += (_a = metrics.fontBoundingBoxAscent) != null ? _a : metrics.emHeightAscent;
    }
    if (i == lines.length - 1) {
      height += metrics.actualBoundingBoxDescent;
    } else {
      baselineDistance += (_b = metrics.fontBoundingBoxDescent) != null ? _b : metrics.emHeightDescent;
    }
  }
  if (lineHeight !== void 0) {
    baselineDistance = (lines.length - 1) * lineHeight;
  }
  height += baselineDistance;
  top += baselineDistance * getVerticalOffset(textBaseline);
  return new BBox(x - left, y - top, width, height);
}
function getApproximateBBox(lines, x, y, textProps) {
  let width = 0;
  let firstLineHeight = 0;
  let baselineDistance = 0;
  const font = getFont(textProps);
  const {
    lineHeight,
    textBaseline = Text.defaultStyles.textBaseline,
    textAlign = Text.defaultStyles.textAlign
  } = textProps;
  if (lines.length > 0) {
    const lineSize = HdpiCanvas.getTextSize(lines[0], font);
    width = lineSize.width;
    firstLineHeight = lineSize.height;
  }
  for (let i = 1; i < lines.length; i++) {
    const lineSize = HdpiCanvas.getTextSize(lines[i], font);
    width = Math.max(width, lineSize.width);
    baselineDistance += lineHeight != null ? lineHeight : lineSize.height;
  }
  switch (textAlign) {
    case "end":
    case "right":
      x -= width;
      break;
    case "center":
      x -= width / 2;
  }
  switch (textBaseline) {
    case "alphabetic":
      y -= firstLineHeight * 0.7 + baselineDistance * 0.5;
      break;
    case "middle":
      y -= firstLineHeight * 0.45 + baselineDistance * 0.5;
      break;
    case "ideographic":
      y -= firstLineHeight + baselineDistance;
      break;
    case "hanging":
      y -= firstLineHeight * 0.2 + baselineDistance * 0.5;
      break;
    case "bottom":
      y -= firstLineHeight + baselineDistance;
      break;
  }
  return new BBox(x, y, width, firstLineHeight + baselineDistance);
}
function getVerticalOffset(textBaseline) {
  switch (textBaseline) {
    case "top":
    case "hanging":
      return 0;
    case "bottom":
    case "alphabetic":
    case "ideographic":
      return 1;
    case "middle":
      return 0.5;
  }
}
function splitText(text) {
  return typeof text === "string" ? text.split(/\r?\n/g) : [];
}

// packages/ag-charts-community/src/util/proxy.ts
function ProxyProperty(...proxyProperties) {
  const property = proxyProperties[proxyProperties.length - 1];
  if (proxyProperties.length === 1) {
    return addTransformToInstanceProperty(
      (target, _, value) => {
        target[property] = value;
        return value;
      },
      (target, _) => {
        return target[property];
      }
    );
  }
  const getTarget = (target) => {
    let value = target;
    for (let i = 0; i < proxyProperties.length - 1; i += 1) {
      value = value[proxyProperties[i]];
    }
    return value;
  };
  return addTransformToInstanceProperty(
    (target, _, value) => {
      getTarget(target)[property] = value;
      return value;
    },
    (target, _) => {
      return getTarget(target)[property];
    }
  );
}
function ProxyOnWrite(proxyProperty) {
  return addTransformToInstanceProperty((target, _, value) => {
    target[proxyProperty] = value;
    return value;
  });
}
function ProxyPropertyOnWrite(childName, childProperty) {
  return addTransformToInstanceProperty((target, key, value) => {
    target[childName][childProperty != null ? childProperty : key] = value;
    return value;
  });
}
function ActionOnSet(opts) {
  const { newValue: newValueFn, oldValue: oldValueFn, changeValue: changeValueFn } = opts;
  return addTransformToInstanceProperty((target, _, newValue, oldValue) => {
    if (newValue !== oldValue) {
      if (oldValue !== void 0) {
        oldValueFn == null ? void 0 : oldValueFn.call(target, oldValue);
      }
      if (newValue !== void 0) {
        newValueFn == null ? void 0 : newValueFn.call(target, newValue);
      }
      changeValueFn == null ? void 0 : changeValueFn.call(target, newValue, oldValue);
    }
    return newValue;
  });
}

// packages/ag-charts-community/src/util/dom.ts
function injectStyle(document2, cssStyle) {
  const styleElement = document2.createElement("style");
  styleElement.innerHTML = cssStyle;
  document2.head.insertBefore(styleElement, document2.head.querySelector("style"));
}

// packages/ag-charts-community/src/chart/tooltip/tooltip.ts
var DEFAULT_TOOLTIP_CLASS = "ag-chart-tooltip";
var DEFAULT_TOOLTIP_DARK_CLASS = "ag-chart-dark-tooltip";
var defaultTooltipCss$1 = `
.${DEFAULT_TOOLTIP_CLASS} {
    transition: transform 0.1s ease;
    max-width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 99999;
    font: 12px Verdana, sans-serif;
    color: rgb(70, 70, 70);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.${DEFAULT_TOOLTIP_CLASS}-wrap-always {
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: none;
}

.${DEFAULT_TOOLTIP_CLASS}-wrap-hyphenate {
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
}

.${DEFAULT_TOOLTIP_CLASS}-wrap-on-space {
    overflow-wrap: normal;
    word-break: normal;
}

.${DEFAULT_TOOLTIP_CLASS}-wrap-never {
    white-space: pre;
    text-overflow: ellipsis;
}

.${DEFAULT_TOOLTIP_CLASS}-no-interaction {
    pointer-events: none;
    user-select: none;
}

.${DEFAULT_TOOLTIP_CLASS}-no-animation {
    transition: none !important;
}

.${DEFAULT_TOOLTIP_CLASS}-hidden {
    visibility: hidden;
}

.${DEFAULT_TOOLTIP_CLASS}-title {
    overflow: hidden;
    position: relative;
    padding: 8px 14px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    color: white;
    background-color: #888888;
    z-index: 1;
    text-overflow: inherit;
}

.${DEFAULT_TOOLTIP_CLASS}-title:only-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.${DEFAULT_TOOLTIP_CLASS}-content {
    overflow: hidden;
    padding: 6px 14px;
    line-height: 1.7em;
    background: white;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    overflow: hidden;
    text-overflow: inherit;
}

.${DEFAULT_TOOLTIP_CLASS}-arrow::before {
    content: "";

    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);

    border: 5px solid #d9d9d9;

    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;

    width: 0;
    height: 0;

    margin: 0 auto;
}

.${DEFAULT_TOOLTIP_CLASS}-arrow::after {
    content: "";

    position: absolute;
    top: calc(100% - 1px);
    left: 50%;
    transform: translateX(-50%);

    border: 5px solid white;

    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;

    width: 0;
    height: 0;

    margin: 0 auto;
}

.${DEFAULT_TOOLTIP_CLASS}.${DEFAULT_TOOLTIP_DARK_CLASS} {
    color: white;
    background: #15181c;
}

.${DEFAULT_TOOLTIP_CLASS}.${DEFAULT_TOOLTIP_DARK_CLASS} .${DEFAULT_TOOLTIP_CLASS}-content {
    border-color: rgba(255, 255, 255, 0.15);
}

.ag-chart-wrapper {
    box-sizing: border-box;
    overflow: hidden;
}
`;
function toTooltipHtml$1(input, defaults) {
  var _a, _b, _c;
  if (typeof input === "string") {
    return input;
  }
  const {
    content = (_a = defaults == null ? void 0 : defaults.content) != null ? _a : "",
    title = defaults == null ? void 0 : defaults.title,
    color = (_b = defaults == null ? void 0 : defaults.color) != null ? _b : "white",
    backgroundColor = (_c = defaults == null ? void 0 : defaults.backgroundColor) != null ? _c : "#888"
  } = input;
  const titleHtml = title ? `<div class="${DEFAULT_TOOLTIP_CLASS}-title"
        style="color: ${color}; background-color: ${backgroundColor}">${title}</div>` : "";
  const contentHtml = content ? `<div class="${DEFAULT_TOOLTIP_CLASS}-content">${content}</div>` : "";
  return `${titleHtml}${contentHtml}`;
}
var TooltipPosition = class extends BaseProperties {
  constructor() {
    super(...arguments);
    /** The type of positioning for the tooltip. By default, the tooltip follows the pointer. */
    this.type = "pointer";
    /** The horizontal offset in pixels for the position of the tooltip. */
    this.xOffset = 0;
    /** The vertical offset in pixels for the position of the tooltip. */
    this.yOffset = 0;
  }
};
__decorateClass([
  Validate(UNION(["pointer", "node"], "a position type"))
], TooltipPosition.prototype, "type", 2);
__decorateClass([
  Validate(NUMBER)
], TooltipPosition.prototype, "xOffset", 2);
__decorateClass([
  Validate(NUMBER)
], TooltipPosition.prototype, "yOffset", 2);
var _Tooltip = class _Tooltip {
  constructor(canvasElement, document2, window2, container) {
    this.enableInteraction = false;
    this.enabled = true;
    this.showArrow = void 0;
    this.class = void 0;
    this.lastClass = void 0;
    this.delay = 0;
    this.range = "nearest";
    this.wrapping = "hyphenate";
    this.lastVisibilityChange = Date.now();
    this.position = new TooltipPosition();
    this.showTimeout = 0;
    this._showArrow = true;
    this.tooltipRoot = container;
    this.window = window2;
    const element2 = document2.createElement("div");
    this.element = this.tooltipRoot.appendChild(element2);
    this.element.classList.add(DEFAULT_TOOLTIP_CLASS);
    this.canvasElement = canvasElement;
    if (typeof IntersectionObserver !== "undefined") {
      const observer = new IntersectionObserver(
        (entries) => {
          for (const entry of entries) {
            if (entry.target === this.canvasElement && entry.intersectionRatio === 0) {
              this.toggle(false);
            }
          }
        },
        { root: this.tooltipRoot }
      );
      observer.observe(this.canvasElement);
      this.observer = observer;
    }
    if (_Tooltip.tooltipDocuments.indexOf(document2) < 0) {
      injectStyle(document2, defaultTooltipCss$1);
      _Tooltip.tooltipDocuments.push(document2);
    }
  }
  destroy() {
    const { parentNode } = this.element;
    if (parentNode) {
      parentNode.removeChild(this.element);
    }
    if (this.observer) {
      this.observer.unobserve(this.canvasElement);
    }
  }
  isVisible() {
    const { element: element2 } = this;
    return !element2.classList.contains(DEFAULT_TOOLTIP_CLASS + "-hidden");
  }
  updateClass(visible, showArrow, addCustomClass = true) {
    const { element: element2, class: newClass, lastClass, enableInteraction, lastVisibilityChange } = this;
    const wasVisible = this.isVisible();
    const nowVisible = !!visible;
    let timeSinceLastVisibilityChangeMs = Infinity;
    if (wasVisible !== nowVisible) {
      const now = Date.now();
      timeSinceLastVisibilityChangeMs = now - lastVisibilityChange;
      this.lastVisibilityChange = now;
    }
    const toggleClass = (name, include) => {
      const className = `${DEFAULT_TOOLTIP_CLASS}-${name}`;
      if (include) {
        element2.classList.add(className);
      } else {
        element2.classList.remove(className);
      }
    };
    const animatedMoveThresholdMs = 100;
    const thrashingThresholdMs = 5;
    const noAnimation = !wasVisible && nowVisible && timeSinceLastVisibilityChangeMs > animatedMoveThresholdMs;
    if (timeSinceLastVisibilityChangeMs > thrashingThresholdMs) {
      toggleClass("no-animation", noAnimation);
    }
    toggleClass("no-interaction", !enableInteraction);
    toggleClass("hidden", !visible);
    toggleClass("arrow", !!showArrow);
    this.updateWrapping();
    if (addCustomClass) {
      if (newClass !== lastClass) {
        if (lastClass) {
          element2.classList.remove(lastClass);
        }
        if (newClass) {
          element2.classList.add(newClass);
        }
      }
      this.lastClass = newClass;
    } else {
      if (lastClass) {
        element2.classList.remove(lastClass);
      }
      this.lastClass = void 0;
    }
  }
  updateWrapping() {
    const { element: element2, wrapping } = this;
    const wrappingOptions = {
      always: false,
      hyphenate: false,
      "on-space": false,
      never: false
    };
    wrappingOptions[wrapping] = true;
    Object.entries(wrappingOptions).forEach(([name, force]) => {
      element2.classList.toggle(`${DEFAULT_TOOLTIP_CLASS}-wrap-${name}`, force);
    });
  }
  /**
   * Shows tooltip at the given event's coordinates.
   * If the `html` parameter is missing, moves the existing tooltip to the new position.
   */
  show(meta, html, instantly = false) {
    var _a, _b, _c, _d, _e, _f, _g;
    const { element: element2, canvasElement } = this;
    if (html !== void 0) {
      element2.innerHTML = html;
    } else if (!element2.innerHTML) {
      this.toggle(false);
      return;
    }
    const limit = (low, actual, high) => {
      return Math.max(Math.min(actual, high), low);
    };
    const xOffset = (_b = (_a = meta.position) == null ? void 0 : _a.xOffset) != null ? _b : 0;
    const yOffset = (_d = (_c = meta.position) == null ? void 0 : _c.yOffset) != null ? _d : 0;
    const canvasRect = canvasElement.getBoundingClientRect();
    const naiveLeft = canvasRect.left + meta.offsetX - element2.clientWidth / 2 + xOffset;
    const naiveTop = canvasRect.top + meta.offsetY - element2.clientHeight - 8 + yOffset;
    const windowBounds = this.getWindowBoundingBox();
    const maxLeft = windowBounds.x + windowBounds.width - element2.clientWidth - 1;
    const maxTop = windowBounds.y + windowBounds.height - element2.clientHeight;
    const left = limit(windowBounds.x, naiveLeft, maxLeft);
    const top = limit(windowBounds.y, naiveTop, maxTop);
    const constrained = left !== naiveLeft || top !== naiveTop;
    const defaultShowArrow = !constrained && !xOffset && !yOffset;
    const showArrow = (_f = (_e = meta.showArrow) != null ? _e : this.showArrow) != null ? _f : defaultShowArrow;
    this.updateShowArrow(showArrow);
    element2.style.transform = `translate(${Math.round(left)}px, ${Math.round(top)}px)`;
    this.enableInteraction = (_g = meta.enableInteraction) != null ? _g : false;
    if (this.delay > 0 && !instantly) {
      this.toggle(false);
      this.showTimeout = this.window.setTimeout(() => {
        this.toggle(true, meta.addCustomClass);
      }, this.delay);
      return;
    }
    this.toggle(true, meta.addCustomClass);
  }
  getWindowBoundingBox() {
    return new BBox(0, 0, this.window.innerWidth, this.window.innerHeight);
  }
  toggle(visible, addCustomClass) {
    if (!visible) {
      this.window.clearTimeout(this.showTimeout);
    }
    this.updateClass(visible, this._showArrow, addCustomClass);
  }
  pointerLeftOntoTooltip(event) {
    var _a;
    if (!this.enableInteraction)
      return false;
    const classList = (_a = event.sourceEvent.relatedTarget) == null ? void 0 : _a.classList;
    const classes = ["", "-title", "-content"];
    const classListContains = Boolean(classes.filter((c) => classList == null ? void 0 : classList.contains(`${DEFAULT_TOOLTIP_CLASS}${c}`)));
    return classList !== void 0 && classListContains;
  }
  updateShowArrow(show) {
    this._showArrow = show;
  }
};
_Tooltip.tooltipDocuments = [];
__decorateClass([
  Validate(BOOLEAN)
], _Tooltip.prototype, "enabled", 2);
__decorateClass([
  Validate(BOOLEAN, { optional: true })
], _Tooltip.prototype, "showArrow", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], _Tooltip.prototype, "class", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], _Tooltip.prototype, "delay", 2);
__decorateClass([
  Validate(INTERACTION_RANGE)
], _Tooltip.prototype, "range", 2);
__decorateClass([
  Validate(TEXT_WRAP)
], _Tooltip.prototype, "wrapping", 2);
var Tooltip = _Tooltip;

// packages/ag-charts-community/src/chart/caption.ts
var Caption = class extends BaseProperties {
  constructor() {
    super(...arguments);
    this.id = createId$1(this);
    this.node = new Text().setProperties({
      textAlign: "center",
      pointerEvents: 1 /* None */
    });
    this.enabled = false;
    this.textAlign = "center";
    this.fontSize = 10;
    this.fontFamily = "sans-serif";
    this.wrapping = "always";
    this.truncated = false;
  }
  registerInteraction(moduleCtx) {
    return moduleCtx.interactionManager.addListener("hover", (event) => this.handleMouseMove(moduleCtx, event));
  }
  computeTextWrap(containerWidth, containerHeight) {
    var _a, _b;
    const { text, wrapping } = this;
    const maxWidth = Math.min((_a = this.maxWidth) != null ? _a : Infinity, containerWidth);
    const maxHeight = (_b = this.maxHeight) != null ? _b : containerHeight;
    if (!isFinite(maxWidth) && !isFinite(maxHeight)) {
      this.node.text = text;
      return;
    }
    const { text: wrappedText, truncated } = Text.wrap(text != null ? text : "", maxWidth, maxHeight, this, wrapping);
    this.node.text = wrappedText;
    this.truncated = truncated;
  }
  handleMouseMove(moduleCtx, event) {
    if (!this.enabled) {
      return;
    }
    const bbox = this.node.computeBBox();
    const { pageX, pageY, offsetX, offsetY } = event;
    const pointerInsideCaption = this.node.visible && bbox.containsPoint(offsetX, offsetY);
    if (!pointerInsideCaption) {
      moduleCtx.tooltipManager.removeTooltip(this.id);
      return;
    }
    event.consume();
    if (!this.truncated) {
      moduleCtx.tooltipManager.removeTooltip(this.id);
      return;
    }
    moduleCtx.tooltipManager.updateTooltip(
      this.id,
      { pageX, pageY, offsetX, offsetY, event, showArrow: false, addCustomClass: false },
      toTooltipHtml$1({ content: this.text })
    );
  }
};
Caption.SMALL_PADDING = 10;
Caption.LARGE_PADDING = 20;
__decorateClass([
  Validate(BOOLEAN)
], Caption.prototype, "enabled", 2);
__decorateClass([
  Validate(STRING, { optional: true }),
  ProxyPropertyOnWrite("node")
], Caption.prototype, "text", 2);
__decorateClass([
  Validate(TEXT_ALIGN, { optional: true }),
  ProxyPropertyOnWrite("node")
], Caption.prototype, "textAlign", 2);
__decorateClass([
  Validate(FONT_STYLE, { optional: true }),
  ProxyPropertyOnWrite("node")
], Caption.prototype, "fontStyle", 2);
__decorateClass([
  Validate(FONT_WEIGHT, { optional: true }),
  ProxyPropertyOnWrite("node")
], Caption.prototype, "fontWeight", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER),
  ProxyPropertyOnWrite("node")
], Caption.prototype, "fontSize", 2);
__decorateClass([
  Validate(STRING),
  ProxyPropertyOnWrite("node")
], Caption.prototype, "fontFamily", 2);
__decorateClass([
  Validate(COLOR_STRING, { optional: true }),
  ProxyPropertyOnWrite("node", "fill")
], Caption.prototype, "color", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], Caption.prototype, "spacing", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], Caption.prototype, "lineHeight", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], Caption.prototype, "maxWidth", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], Caption.prototype, "maxHeight", 2);
__decorateClass([
  Validate(TEXT_WRAP)
], Caption.prototype, "wrapping", 2);

// packages/ag-charts-community/src/chart/axis/axisTitle.ts
var AxisTitle = class {
  constructor() {
    this.enabled = false;
    this.spacing = Caption.SMALL_PADDING;
    this.fontSize = 10;
    this.fontFamily = "sans-serif";
    this.wrapping = "always";
  }
};
__decorateClass([
  Validate(BOOLEAN)
], AxisTitle.prototype, "enabled", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], AxisTitle.prototype, "text", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], AxisTitle.prototype, "spacing", 2);
__decorateClass([
  Validate(FONT_STYLE, { optional: true })
], AxisTitle.prototype, "fontStyle", 2);
__decorateClass([
  Validate(FONT_WEIGHT, { optional: true })
], AxisTitle.prototype, "fontWeight", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], AxisTitle.prototype, "fontSize", 2);
__decorateClass([
  Validate(STRING)
], AxisTitle.prototype, "fontFamily", 2);
__decorateClass([
  Validate(COLOR_STRING, { optional: true })
], AxisTitle.prototype, "color", 2);
__decorateClass([
  Validate(TEXT_WRAP)
], AxisTitle.prototype, "wrapping", 2);
__decorateClass([
  Validate(FUNCTION, { optional: true })
], AxisTitle.prototype, "formatter", 2);

// packages/ag-charts-community/src/chart/chartOptions.ts
var JSON_APPLY_PLUGINS = {
  constructors: {},
  constructedArrays: /* @__PURE__ */ new WeakMap()
};
function assignJsonApplyConstructedArray(array, ctor) {
  var _a;
  (_a = JSON_APPLY_PLUGINS.constructedArrays) == null ? void 0 : _a.set(array, ctor);
}

// packages/ag-charts-community/src/scale/continuousScale.ts
var _ContinuousScale = class _ContinuousScale {
  constructor(domain, range3) {
    this.invalid = true;
    this.nice = false;
    this.interval = void 0;
    this.tickCount = _ContinuousScale.defaultTickCount;
    this.minTickCount = 0;
    this.maxTickCount = Infinity;
    this.niceDomain = [];
    this.defaultClampMode = "raw";
    this.domain = domain;
    this.range = range3;
  }
  static is(value) {
    return value instanceof _ContinuousScale;
  }
  transform(x) {
    return x;
  }
  transformInvert(x) {
    return x;
  }
  calcBandwidth(smallestInterval = 1) {
    const domain = this.getDomain();
    const maxRange = Math.max(...this.range);
    const intervals = Math.abs(domain[1] - domain[0]) / smallestInterval + 1;
    const maxBands = Math.floor(maxRange);
    const bands = Math.min(intervals, maxBands);
    return maxRange / Math.max(1, bands);
  }
  fromDomain(d) {
    if (typeof d === "number") {
      return d;
    } else if (d instanceof Date) {
      return d.getTime();
    }
    return NaN;
  }
  getDomain() {
    if (this.nice) {
      this.refresh();
      if (this.niceDomain.length) {
        return this.niceDomain;
      }
    }
    return this.domain;
  }
  convert(x, opts) {
    var _a;
    const clampMode = (_a = opts == null ? void 0 : opts.clampMode) != null ? _a : this.defaultClampMode;
    if (!this.domain || this.domain.length < 2) {
      return NaN;
    }
    this.refresh();
    const domain = this.getDomain().map((d) => this.transform(d));
    const [d0, d1] = domain;
    const { range: range3 } = this;
    const [r0, r1] = range3;
    x = this.transform(x);
    if (clampMode === "clamped") {
      const start = Math.min(this.fromDomain(d0), this.fromDomain(d1));
      const stop = Math.max(this.fromDomain(d0), this.fromDomain(d1));
      if (this.fromDomain(x) < start) {
        return r0;
      } else if (this.fromDomain(x) > stop) {
        return r1;
      }
    }
    if (d0 === d1) {
      return (r0 + r1) / 2;
    } else if (x === d0) {
      return r0;
    } else if (x === d1) {
      return r1;
    }
    return r0 + (this.fromDomain(x) - this.fromDomain(d0)) / (this.fromDomain(d1) - this.fromDomain(d0)) * (r1 - r0);
  }
  invert(x) {
    this.refresh();
    const domain = this.getDomain().map((d2) => this.transform(d2));
    const [d0, d1] = domain;
    const { range: range3 } = this;
    const [r0, r1] = range3;
    const isReversed = r0 > r1;
    const rMin = isReversed ? r1 : r0;
    const rMax = isReversed ? r0 : r1;
    let d;
    if (x < rMin) {
      return isReversed ? d1 : d0;
    } else if (x > rMax) {
      return isReversed ? d0 : d1;
    } else if (r0 === r1) {
      d = this.toDomain((this.fromDomain(d0) + this.fromDomain(d1)) / 2);
    } else {
      d = this.toDomain(
        this.fromDomain(d0) + (x - r0) / (r1 - r0) * (this.fromDomain(d1) - this.fromDomain(d0))
      );
    }
    return this.transformInvert(d);
  }
  refresh() {
    if (!this.invalid)
      return;
    this.invalid = false;
    this.update();
    if (this.invalid) {
      Logger.warnOnce("Expected update to not invalidate scale");
    }
  }
  getPixelRange() {
    const range3 = this.range.slice().sort((a, b) => a - b);
    return range3[1] - range3[0];
  }
  isDenseInterval({
    start,
    stop,
    interval,
    count: count2
  }) {
    const domain = stop - start;
    const availableRange = this.getPixelRange();
    const step = typeof interval === "number" ? interval : 1;
    count2 != null ? count2 : count2 = domain / step;
    if (count2 >= availableRange) {
      Logger.warn(
        `the configured interval results in more than 1 item per pixel, ignoring. Supply a larger interval or omit this configuration`
      );
      return true;
    }
    return false;
  }
};
_ContinuousScale.defaultTickCount = 5;
_ContinuousScale.defaultMaxTickCount = 6;
__decorateClass([
  Invalidating
], _ContinuousScale.prototype, "domain", 2);
__decorateClass([
  Invalidating
], _ContinuousScale.prototype, "range", 2);
__decorateClass([
  Invalidating
], _ContinuousScale.prototype, "nice", 2);
__decorateClass([
  Invalidating
], _ContinuousScale.prototype, "interval", 2);
__decorateClass([
  Invalidating
], _ContinuousScale.prototype, "tickCount", 2);
__decorateClass([
  Invalidating
], _ContinuousScale.prototype, "minTickCount", 2);
__decorateClass([
  Invalidating
], _ContinuousScale.prototype, "maxTickCount", 2);
var ContinuousScale = _ContinuousScale;

// packages/ag-charts-community/src/util/compare.ts
function ascendingStringNumberUndefined(a, b) {
  let diff2 = 0;
  if (typeof a === "number" && typeof b === "number") {
    diff2 = a - b;
  } else if (typeof a === "string" && typeof b === "string") {
    diff2 = a.localeCompare(b);
  } else if (a == null && b == null) ; else if (a == null) {
    diff2 = -1;
  } else if (b == null) {
    diff2 = 1;
  } else {
    diff2 = String(a).localeCompare(String(b));
  }
  return diff2;
}
function compoundAscending(a, b, comparator) {
  const toLiteral = (v) => {
    if (typeof v === "function") {
      return v();
    }
    return v;
  };
  for (const idx in a) {
    const diff2 = comparator(toLiteral(a[idx]), toLiteral(b[idx]));
    if (diff2 !== 0) {
      return diff2;
    }
  }
  return 0;
}

// packages/ag-charts-community/src/scene/group.ts
var _Group = class _Group extends Node {
  constructor(opts) {
    var _a;
    super({ isVirtual: opts == null ? void 0 : opts.isVirtual });
    this.opts = opts;
    this.opacity = 1;
    this.lastBBox = void 0;
    const { zIndex, zIndexSubOrder } = opts != null ? opts : {};
    this.isContainerNode = true;
    if (zIndex !== void 0) {
      this.zIndex = zIndex;
    }
    if (zIndexSubOrder !== void 0) {
      this.zIndexSubOrder = zIndexSubOrder;
    }
    this.name = (_a = this.opts) == null ? void 0 : _a.name;
  }
  zIndexChanged() {
    var _a;
    if (this.layer) {
      (_a = this._layerManager) == null ? void 0 : _a.moveLayer(this.layer, this.zIndex, this.zIndexSubOrder);
    }
  }
  isLayer() {
    return this.layer != null;
  }
  _setLayerManager(scene) {
    var _a, _b;
    if (this._layerManager && this.layer) {
      this._layerManager.removeLayer(this.layer);
      this.layer = void 0;
    }
    if (this.layer) {
      throw new Error("AG Charts - unable to deregister scene rendering layer!");
    }
    super._setLayerManager(scene);
    if (scene && ((_a = this.opts) == null ? void 0 : _a.layer)) {
      const { zIndex, zIndexSubOrder, name } = (_b = this.opts) != null ? _b : {};
      const getComputedOpacity = () => this.getComputedOpacity();
      const getVisibility = () => this.getVisibility();
      this.layer = scene.addLayer({
        zIndex,
        zIndexSubOrder,
        name,
        getComputedOpacity,
        getVisibility
      });
    }
  }
  getComputedOpacity() {
    let opacity = 1;
    let node = this;
    do {
      if (node instanceof _Group) {
        opacity *= node.opacity;
      }
    } while (node = node.parent);
    return opacity;
  }
  getVisibility() {
    let node = this;
    let visible = this.visible;
    while (node = node.parent) {
      if (node.visible) {
        continue;
      }
      visible = node.visible;
    }
    return visible;
  }
  visibilityChanged() {
    if (this.layer) {
      this.layer.enabled = this.visible;
    }
  }
  markDirty(source, type = 1 /* TRIVIAL */) {
    if (this.isVirtual) {
      super.markDirty(source, type);
      return;
    }
    let parentType = type;
    if (type < 2 /* MINOR */ || this.layer != null) {
      parentType = 1 /* TRIVIAL */;
    }
    super.markDirty(source, type, parentType);
  }
  // We consider a group to be boundless, thus any point belongs to it.
  containsPoint(_x, _y) {
    return true;
  }
  computeBBox() {
    this.computeTransformMatrix();
    return _Group.computeBBox(this.children);
  }
  computeTransformedBBox() {
    return this.computeBBox();
  }
  render(renderCtx) {
    var _a, _b;
    const { opts: { name = void 0 } = {}, _debug: debug2 = () => {
    } } = this;
    const { dirty, dirtyZIndex, layer, children, clipRect, dirtyTransform } = this;
    let { ctx, forceRender, clipBBox } = renderCtx;
    const { resized, stats } = renderCtx;
    const canvasCtxTransform = ctx.getTransform();
    const isDirty = dirty >= 2 /* MINOR */ || dirtyZIndex || resized;
    let isChildDirty = isDirty;
    let isChildLayerDirty = false;
    for (const child of children) {
      isChildDirty || (isChildDirty = child.layerManager == null && child.dirty >= 1 /* TRIVIAL */);
      isChildLayerDirty || (isChildLayerDirty = child.layerManager != null && child.dirty >= 1 /* TRIVIAL */);
      if (isChildDirty) {
        break;
      }
    }
    if (name) {
      debug2({ name, group: this, isDirty, isChildDirty, dirtyTransform, renderCtx, forceRender });
    }
    if (dirtyTransform) {
      forceRender = "dirtyTransform";
    } else if (layer) {
      const currentBBox = this.computeBBox();
      if (this.lastBBox === void 0 || !this.lastBBox.equals(currentBBox)) {
        forceRender = "dirtyTransform";
        this.lastBBox = currentBBox;
      }
    }
    if (!isDirty && !isChildDirty && !isChildLayerDirty && !forceRender) {
      if (name && stats) {
        debug2({ name, result: "skipping", renderCtx, counts: this.nodeCount, group: this });
      }
      if (layer && stats) {
        stats.layersSkipped++;
        stats.nodesSkipped += this.nodeCount.count;
      }
      this.markClean({ recursive: false });
      return;
    }
    const groupVisible = this.visible;
    if (layer) {
      ctx = layer.context;
      ctx.save();
      ctx.resetTransform();
      if (forceRender !== "dirtyTransform") {
        forceRender = isChildDirty || dirtyZIndex;
      }
      if (forceRender)
        layer.clear();
      if (clipBBox) {
        const { width, height, x, y } = clipBBox;
        debug2(() => ({
          name,
          clipBBox,
          ctxTransform: ctx.getTransform(),
          renderCtx,
          group: this
        }));
        this.clipCtx(ctx, x, y, width, height);
      }
      ctx.setTransform(canvasCtxTransform);
    } else {
      ctx.globalAlpha *= this.opacity;
    }
    this.computeTransformMatrix();
    this.matrix.toContext(ctx);
    if (clipRect) {
      const { x, y, width, height } = clipRect;
      ctx.save();
      debug2(() => ({ name, clipRect, ctxTransform: ctx.getTransform(), renderCtx, group: this }));
      this.clipCtx(ctx, x, y, width, height);
      clipBBox = this.matrix.transformBBox(clipRect);
    }
    const hasVirtualChildren = this.hasVirtualChildren();
    if (dirtyZIndex) {
      this.sortChildren(children);
      if (forceRender !== "dirtyTransform")
        forceRender = true;
    } else if (hasVirtualChildren) {
      this.sortChildren(children);
    }
    const renderContextChanged = forceRender !== renderCtx.forceRender || clipBBox !== renderCtx.clipBBox || ctx !== renderCtx.ctx;
    const childRenderContext = renderContextChanged ? __spreadProps(__spreadValues({}, renderCtx), { ctx, forceRender, clipBBox }) : renderCtx;
    let skipped = 0;
    for (const child of children) {
      if (!child.visible || !groupVisible) {
        child.markClean();
        if (stats)
          skipped += child.nodeCount.count;
        continue;
      }
      if (!forceRender && child.dirty === 0 /* NONE */) {
        if (stats)
          skipped += child.nodeCount.count;
        continue;
      }
      ctx.save();
      child.render(childRenderContext);
      ctx.restore();
    }
    if (stats)
      stats.nodesSkipped += skipped;
    super.render(renderCtx);
    if (clipRect) {
      ctx.restore();
    }
    if (hasVirtualChildren) {
      for (const child of this.virtualChildren) {
        child.markClean({ recursive: "virtual" });
      }
    }
    if (layer) {
      if (stats)
        stats.layersRendered++;
      ctx.restore();
      if (forceRender)
        layer.snapshot();
      (_b = (_a = layer.context).verifyDepthZero) == null ? void 0 : _b.call(_a);
    }
    if (name && stats) {
      debug2({ name, result: "rendered", skipped, renderCtx, counts: this.nodeCount, group: this });
    }
  }
  sortChildren(children) {
    this.dirtyZIndex = false;
    children.sort((a, b) => {
      var _a, _b;
      return compoundAscending(
        [a.zIndex, ...(_a = a.zIndexSubOrder) != null ? _a : [void 0, void 0], a.serialNumber],
        [b.zIndex, ...(_b = b.zIndexSubOrder) != null ? _b : [void 0, void 0], b.serialNumber],
        ascendingStringNumberUndefined
      );
    });
  }
  clipCtx(ctx, x, y, width, height) {
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + width, y);
    ctx.lineTo(x + width, y + height);
    ctx.lineTo(x, y + height);
    ctx.closePath();
    ctx.clip();
  }
  static computeBBox(nodes) {
    let left = Infinity;
    let right = -Infinity;
    let top = Infinity;
    let bottom = -Infinity;
    nodes.forEach((n) => {
      if (!n.visible) {
        return;
      }
      const bbox = n.computeTransformedBBox();
      if (!bbox) {
        return;
      }
      const x = bbox.x;
      const y = bbox.y;
      if (x < left) {
        left = x;
      }
      if (y < top) {
        top = y;
      }
      if (x + bbox.width > right) {
        right = x + bbox.width;
      }
      if (y + bbox.height > bottom) {
        bottom = y + bbox.height;
      }
    });
    return new BBox(left, top, right - left, bottom - top);
  }
  /**
   * Transforms bbox given in the canvas coordinate space to bbox in this group's coordinate space and
   * sets this group's clipRect to the transformed bbox.
   * @param bbox clipRect bbox in the canvas coordinate space.
   */
  setClipRectInGroupCoordinateSpace(bbox) {
    this.clipRect = bbox ? this.transformBBox(bbox) : void 0;
  }
};
_Group.className = "Group";
__decorateClass([
  SceneChangeDetection({
    redraw: 3 /* MAJOR */,
    convertor: (v) => Math.min(1, Math.max(0, v))
  })
], _Group.prototype, "opacity", 2);
var Group = _Group;

// packages/ag-charts-community/src/scene/shape/range.ts
var Range = class extends Shape {
  constructor(opts = {}) {
    super(opts);
    this.x1 = 0;
    this.y1 = 0;
    this.x2 = 0;
    this.y2 = 0;
    this.startLine = false;
    this.endLine = false;
    this.isRange = false;
    this.restoreOwnStyles();
  }
  computeBBox() {
    return new BBox(this.x1, this.y1, this.x2 - this.x1, this.y2 - this.y1);
  }
  isPointInPath(_x, _y) {
    return false;
  }
  render(renderCtx) {
    var _a;
    const { ctx, forceRender, stats } = renderCtx;
    if (this.dirty === 0 /* NONE */ && !forceRender) {
      if (stats)
        stats.nodesSkipped += this.nodeCount.count;
      return;
    }
    this.computeTransformMatrix();
    this.matrix.toContext(ctx);
    let { x1, y1, x2, y2 } = this;
    x1 = this.align(x1);
    y1 = this.align(y1);
    x2 = this.align(x2);
    y2 = this.align(y2);
    const { fill, opacity, isRange } = this;
    const fillActive = !!(isRange && fill);
    if (fillActive) {
      const { fillOpacity } = this;
      ctx.fillStyle = fill;
      ctx.globalAlpha = opacity * fillOpacity;
      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y1);
      ctx.lineTo(x2, y2);
      ctx.lineTo(x1, y2);
      ctx.closePath();
      ctx.fill();
    }
    const { stroke, strokeWidth, startLine, endLine } = this;
    const strokeActive = !!((startLine || endLine) && stroke && strokeWidth);
    if (strokeActive) {
      const { strokeOpacity, lineDash, lineDashOffset, lineCap, lineJoin } = this;
      ctx.strokeStyle = stroke;
      ctx.globalAlpha = opacity * strokeOpacity;
      ctx.lineWidth = strokeWidth;
      if (lineDash) {
        ctx.setLineDash(lineDash);
      }
      if (lineDashOffset) {
        ctx.lineDashOffset = lineDashOffset;
      }
      if (lineCap) {
        ctx.lineCap = lineCap;
      }
      if (lineJoin) {
        ctx.lineJoin = lineJoin;
      }
      ctx.beginPath();
      if (startLine) {
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y1);
      }
      if (endLine) {
        ctx.moveTo(x2, y2);
        ctx.lineTo(x1, y2);
      }
      ctx.stroke();
    }
    (_a = this.fillShadow) == null ? void 0 : _a.markClean();
    super.render(renderCtx);
  }
};
Range.className = "Range";
Range.defaultStyles = __spreadProps(__spreadValues({}, Shape.defaultStyles), {
  strokeWidth: 1
});
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */ })
], Range.prototype, "x1", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */ })
], Range.prototype, "y1", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */ })
], Range.prototype, "x2", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */ })
], Range.prototype, "y2", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */ })
], Range.prototype, "startLine", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */ })
], Range.prototype, "endLine", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 2 /* MINOR */ })
], Range.prototype, "isRange", 2);

// packages/ag-charts-community/src/chart/label.ts
var Label$1 = class extends BaseProperties {
  constructor() {
    super(...arguments);
    this.enabled = true;
    this.color = "#464646";
    this.fontSize = 12;
    this.fontFamily = "Verdana, sans-serif";
  }
  getFont() {
    return getFont(this);
  }
};
__decorateClass([
  Validate(BOOLEAN)
], Label$1.prototype, "enabled", 2);
__decorateClass([
  Validate(COLOR_STRING)
], Label$1.prototype, "color", 2);
__decorateClass([
  Validate(FONT_STYLE, { optional: true })
], Label$1.prototype, "fontStyle", 2);
__decorateClass([
  Validate(FONT_WEIGHT, { optional: true })
], Label$1.prototype, "fontWeight", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], Label$1.prototype, "fontSize", 2);
__decorateClass([
  Validate(STRING)
], Label$1.prototype, "fontFamily", 2);
__decorateClass([
  Validate(FUNCTION, { optional: true })
], Label$1.prototype, "formatter", 2);
function calculateLabelRotation(opts) {
  const { parallelFlipRotation = 0, regularFlipRotation = 0 } = opts;
  const configuredRotation = opts.rotation ? normalizeAngle360(toRadians(opts.rotation)) : 0;
  const parallelFlipFlag = !configuredRotation && parallelFlipRotation >= 0 && parallelFlipRotation <= Math.PI ? -1 : 1;
  const regularFlipFlag = !configuredRotation && regularFlipRotation >= 0 && regularFlipRotation <= Math.PI ? -1 : 1;
  let defaultRotation = 0;
  if (opts.parallel) {
    defaultRotation = parallelFlipFlag * Math.PI / 2;
  } else if (regularFlipFlag === -1) {
    defaultRotation = Math.PI;
  }
  return { configuredRotation, defaultRotation, parallelFlipFlag, regularFlipFlag };
}
function getLabelSpacing(minSpacing, rotated) {
  if (!isNaN(minSpacing)) {
    return minSpacing;
  }
  return rotated ? 0 : 10;
}
function getTextBaseline(parallel, labelRotation, sideFlag, parallelFlipFlag) {
  if (parallel && !labelRotation) {
    return sideFlag * parallelFlipFlag === -1 ? "hanging" : "bottom";
  }
  return "middle";
}
function getTextAlign(parallel, labelRotation, labelAutoRotation, sideFlag, regularFlipFlag) {
  const labelRotated = labelRotation > 0 && labelRotation <= Math.PI;
  const labelAutoRotated = labelAutoRotation > 0 && labelAutoRotation <= Math.PI;
  const alignFlag = labelRotated || labelAutoRotated ? -1 : 1;
  if (parallel) {
    if (labelRotation || labelAutoRotation) {
      if (sideFlag * alignFlag === -1) {
        return "end";
      }
    } else {
      return "center";
    }
  } else if (sideFlag * regularFlipFlag === -1) {
    return "end";
  }
  return "start";
}
function calculateLabelBBox(text, bbox, labelX, labelY, labelMatrix) {
  const { width, height } = bbox;
  const translatedBBox = new BBox(labelX, labelY, 0, 0);
  labelMatrix.transformBBox(translatedBBox, bbox);
  const { x = 0, y = 0 } = bbox;
  bbox.width = width;
  bbox.height = height;
  return {
    point: { x, y, size: 0 },
    label: { width, height, text }
  };
}

// packages/ag-charts-community/src/chart/layers.ts
var Layers = /* @__PURE__ */ ((Layers2) => {
  Layers2[Layers2["SERIES_BACKGROUND_ZINDEX"] = 0] = "SERIES_BACKGROUND_ZINDEX";
  Layers2[Layers2["AXIS_GRID_ZINDEX"] = 1] = "AXIS_GRID_ZINDEX";
  Layers2[Layers2["AXIS_ZINDEX"] = 2] = "AXIS_ZINDEX";
  Layers2[Layers2["SERIES_CROSSLINE_RANGE_ZINDEX"] = 3] = "SERIES_CROSSLINE_RANGE_ZINDEX";
  Layers2[Layers2["SERIES_LAYER_ZINDEX"] = 4] = "SERIES_LAYER_ZINDEX";
  Layers2[Layers2["AXIS_FOREGROUND_ZINDEX"] = 5] = "AXIS_FOREGROUND_ZINDEX";
  Layers2[Layers2["SERIES_CROSSHAIR_ZINDEX"] = 6] = "SERIES_CROSSHAIR_ZINDEX";
  Layers2[Layers2["SERIES_LABEL_ZINDEX"] = 7] = "SERIES_LABEL_ZINDEX";
  Layers2[Layers2["SERIES_CROSSLINE_LINE_ZINDEX"] = 8] = "SERIES_CROSSLINE_LINE_ZINDEX";
  Layers2[Layers2["LEGEND_ZINDEX"] = 9] = "LEGEND_ZINDEX";
  return Layers2;
})(Layers || {});

// packages/ag-charts-community/src/integrated-charts-scene.ts
var integrated_charts_scene_exports = {};
__export(integrated_charts_scene_exports, {
  Arc: () => Arc,
  BBox: () => BBox,
  BandScale: () => BandScale$5,
  Caption: () => Caption,
  Circle: () => Circle,
  ContinuousScale: () => ContinuousScale,
  Diamond: () => Diamond,
  DropShadow: () => DropShadow,
  Group: () => Group,
  HdpiCanvas: () => HdpiCanvas,
  Image: () => Image,
  Label: () => Label$1,
  Line: () => Line,
  LinearGradientFill: () => LinearGradientFill,
  LinearScale: () => LinearScale$1,
  Marker: () => Marker,
  Node: () => Node,
  Path: () => Path,
  Path2D: () => Path2D,
  PointerEvents: () => PointerEvents,
  Rect: () => Rect,
  RedrawType: () => RedrawType,
  Scene: () => Scene,
  SceneChangeDetection: () => SceneChangeDetection,
  ScenePathChangeDetection: () => ScenePathChangeDetection,
  Sector: () => Sector,
  Selection: () => Selection,
  Shape: () => Shape,
  Square: () => Square,
  Text: () => Text,
  Tooltip: () => Tooltip,
  Triangle: () => Triangle,
  easing: () => easing_exports,
  getFont: () => getFont,
  getMarker: () => getMarker$1,
  motion: () => motion,
  nearestSquared: () => nearestSquared,
  nearestSquaredInContainer: () => nearestSquaredInContainer,
  toRadians: () => toRadians,
  toTooltipHtml: () => toTooltipHtml$1
});

// packages/ag-charts-community/src/motion/fromToMotion.ts
var fromToMotion_exports = {};
__export(fromToMotion_exports, {
  FROM_TO_MIXINS: () => FROM_TO_MIXINS,
  NODE_UPDATE_PHASES: () => NODE_UPDATE_PHASES,
  fromToMotion: () => fromToMotion,
  staticFromToMotion: () => staticFromToMotion
});

// packages/ag-charts-community/src/util/zip.ts
function zipObject(keys, values) {
  const zipped = {};
  if (Array.isArray(values)) {
    for (let i = 0; i < keys.length; i++) {
      zipped[`${keys[i]}`] = values[i];
    }
  } else {
    for (let i = 0; i < keys.length; i++) {
      zipped[`${keys[i]}`] = values;
    }
  }
  return zipped;
}

// packages/ag-charts-community/src/interpolate.ts
function interpolateNumber(a, b) {
  return (d) => Number(a) * (1 - d) + Number(b) * d;
}
function interpolateColor(a, b) {
  if (typeof a === "string") {
    try {
      a = Color.fromString(a);
    } catch (e) {
      a = Color.fromArray([0, 0, 0]);
    }
  }
  if (typeof b === "string") {
    try {
      b = Color.fromString(b);
    } catch (e) {
      b = Color.fromArray([0, 0, 0]);
    }
  }
  const red = interpolateNumber(a.r, b.r);
  const green = interpolateNumber(a.g, b.g);
  const blue = interpolateNumber(a.b, b.b);
  const alpha = interpolateNumber(a.a, b.a);
  return (d) => Color.fromArray([red(d), green(d), blue(d), alpha(d)]).toRgbaString();
}

// packages/ag-charts-community/src/motion/easing.ts
var easing_exports = {};
__export(easing_exports, {
  easeIn: () => easeIn,
  easeInOut: () => easeInOut,
  easeInOutQuad: () => easeInOutQuad,
  easeInQuad: () => easeInQuad,
  easeOut: () => easeOut,
  easeOutQuad: () => easeOutQuad,
  inverseEaseOut: () => inverseEaseOut,
  linear: () => linear
});
var linear = (n) => n;
var easeIn = (n) => 1 - Math.cos(n * Math.PI / 2);
var easeOut = (n) => Math.sin(n * Math.PI / 2);
var easeInOut = (n) => -(Math.cos(n * Math.PI) - 1) / 2;
var easeInQuad = (n) => n * n;
var easeOutQuad = (n) => 1 - __pow(1 - n, 2);
var easeInOutQuad = (n) => n < 0.5 ? 2 * n * n : 1 - __pow(-2 * n + 2, 2) / 2;
var inverseEaseOut = (x) => 2 * Math.asin(x) / Math.PI;

// packages/ag-charts-community/src/motion/animation.ts
var QUICK_TRANSITION = 0.2;
var INITIAL_LOAD = {
  animationDuration: 1,
  animationDelay: 0
};
var REMOVE_PHASE = {
  animationDuration: 0.25,
  animationDelay: 0
};
var UPDATE_PHASE = {
  animationDuration: 0.5,
  animationDelay: 0.25
};
var ADD_PHASE = {
  animationDuration: 0.25,
  animationDelay: 0.75
};
var LABEL_PHASE = {
  animationDuration: QUICK_TRANSITION,
  animationDelay: 1
};
var RepeatType = /* @__PURE__ */ ((RepeatType2) => {
  RepeatType2["Loop"] = "loop";
  RepeatType2["Reverse"] = "reverse";
  return RepeatType2;
})(RepeatType || {});
function isNodeArray(array) {
  return array.every((n) => n instanceof Node);
}
function deconstructSelectionsOrNodes(selectionsOrNodes) {
  return isNodeArray(selectionsOrNodes) ? { nodes: selectionsOrNodes, selections: [] } : { nodes: [], selections: selectionsOrNodes };
}
var Animation = class {
  constructor(opts) {
    this.elapsed = 0;
    this.iteration = 0;
    this.isPlaying = false;
    this.isReverse = false;
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
    this.id = opts.id;
    this.groupId = opts.groupId;
    this.autoplay = (_a = opts.autoplay) != null ? _a : true;
    this.delay = (_b = opts.delay) != null ? _b : 0;
    this.duration = (_c = opts.duration) != null ? _c : 1e3;
    this.ease = (_d = opts.ease) != null ? _d : linear;
    this.repeat = (_e = opts.repeat) != null ? _e : 0;
    this.repeatType = (_f = opts.repeatType) != null ? _f : "loop" /* Loop */;
    this.onComplete = opts.onComplete;
    this.onPlay = opts.onPlay;
    this.onStop = opts.onStop;
    this.onRepeat = opts.onRepeat;
    this.onUpdate = opts.onUpdate;
    this.interpolate = this.createInterpolator(opts.from, opts.to);
    if (opts.skip === true) {
      (_g = this.onUpdate) == null ? void 0 : _g.call(this, opts.to, false, this);
      (_h = this.onStop) == null ? void 0 : _h.call(this, this);
      (_i = this.onComplete) == null ? void 0 : _i.call(this, this);
    } else if (this.autoplay) {
      this.play();
      (_j = this.onUpdate) == null ? void 0 : _j.call(this, opts.from, true, this);
    }
  }
  play() {
    var _a;
    if (!this.isPlaying) {
      this.isPlaying = true;
      (_a = this.onPlay) == null ? void 0 : _a.call(this, this);
    }
    return this;
  }
  pause() {
    if (this.isPlaying) {
      this.isPlaying = false;
    }
    return this;
  }
  stop() {
    var _a;
    if (this.isPlaying) {
      this.isPlaying = false;
      (_a = this.onStop) == null ? void 0 : _a.call(this, this);
    }
    return this;
  }
  reset(opts) {
    const deltaState = this.interpolate(this.isReverse ? 1 - this.delta : this.delta);
    this.interpolate = this.createInterpolator(deltaState, opts.to);
    this.elapsed = 0;
    this.iteration = 0;
    if (typeof opts.delay === "number") {
      this.delay = opts.delay;
    }
    if (typeof opts.duration === "number") {
      this.duration = opts.duration;
    }
    if (typeof opts.ease === "function") {
      this.ease = opts.ease;
    }
    return this;
  }
  update(time) {
    var _a, _b, _c;
    this.elapsed += time;
    if (this.elapsed <= this.delay) {
      return this;
    }
    const value = this.interpolate(this.isReverse ? 1 - this.delta : this.delta);
    (_a = this.onUpdate) == null ? void 0 : _a.call(this, value, false, this);
    if (this.elapsed - this.delay >= this.duration) {
      if (this.iteration < this.repeat) {
        this.iteration++;
        this.elapsed = (this.elapsed - this.delay) % this.duration + this.delay;
        if (this.repeatType === "reverse" /* Reverse */) {
          this.isReverse = !this.isReverse;
        }
        (_b = this.onRepeat) == null ? void 0 : _b.call(this, this);
      } else {
        this.stop();
        (_c = this.onComplete) == null ? void 0 : _c.call(this, this);
      }
    }
    return this;
  }
  get delta() {
    return this.ease(clamp2(0, (this.elapsed - this.delay) / this.duration, 1));
  }
  createInterpolator(from, to) {
    if (typeof to !== "object") {
      return this.interpolateValue(from, to);
    }
    const interpolatorEntries = [];
    for (const key in to) {
      const interpolator = this.interpolateValue(from[key], to[key]);
      if (interpolator != null) {
        interpolatorEntries.push([key, interpolator]);
      }
    }
    return (d) => {
      const result = {};
      for (const [key, interpolator] of interpolatorEntries) {
        result[key] = interpolator(d);
      }
      return result;
    };
  }
  interpolateValue(a, b) {
    if (a === void 0 || b === void 0) {
      return void 0;
    }
    try {
      switch (typeof a) {
        case "number":
          return interpolateNumber(a, b);
        case "string":
          return interpolateColor(a, b);
      }
    } catch (e) {
    }
    throw new Error(`Unable to interpolate values: ${a}, ${b}`);
  }
};

// packages/ag-charts-community/src/motion/fromToMotion.ts
var NODE_UPDATE_PHASES = ["removed", "updated", "added"];
var FROM_TO_MIXINS = {
  added: ADD_PHASE,
  updated: UPDATE_PHASE,
  removed: REMOVE_PHASE,
  unknown: INITIAL_LOAD
};
function fromToMotion(groupId, subId, animationManager, selectionsOrNodes, fns, getDatumId, diff2) {
  const { defaultDuration } = animationManager;
  const { fromFn, toFn, intermediateFn } = fns;
  const { nodes, selections } = deconstructSelectionsOrNodes(selectionsOrNodes);
  const ids = { added: {}, removed: {} };
  if (getDatumId && diff2) {
    ids.added = zipObject(diff2.added, true);
    ids.removed = zipObject(diff2.removed, true);
  }
  const processNodes = (liveNodes, nodes2) => {
    var _c, _d;
    let prevFromProps;
    let liveNodeIndex = 0;
    let nodeIndex = 0;
    for (const node of nodes2) {
      const isLive = liveNodes[liveNodeIndex] === node;
      const ctx = {
        last: nodeIndex >= nodes2.length - 1,
        lastLive: liveNodeIndex >= liveNodes.length - 1,
        prev: nodes2[nodeIndex - 1],
        prevFromProps,
        prevLive: liveNodes[liveNodeIndex - 1],
        next: nodes2[nodeIndex + 1],
        nextLive: liveNodes[liveNodeIndex + (isLive ? 1 : 0)]
      };
      const animationId = `${groupId}_${subId}_${node.id}`;
      animationManager.stopByAnimationId(animationId);
      let status = "unknown";
      if (!isLive) {
        status = "removed";
      } else if (getDatumId && diff2) {
        status = calculateStatus(node, node.datum, getDatumId, ids);
      }
      const _a = fromFn(node, node.datum, status, ctx), {
        animationDelay: delay,
        animationDuration: duration,
        start = {},
        finish = {}
      } = _a, from = __objRest(_a, [
        "animationDelay",
        "animationDuration",
        "start",
        "finish"
      ]);
      const _b = toFn(node, node.datum, status, ctx), {
        animationDelay: toDelay,
        animationDuration: toDuration,
        start: toStart = {},
        finish: toFinish = {}
      } = _b, to = __objRest(_b, [
        "animationDelay",
        "animationDuration",
        "start",
        "finish"
      ]);
      animationManager.animate({
        id: animationId,
        groupId,
        from,
        to,
        ease: easeOut,
        onPlay: () => {
          node.setProperties(__spreadValues(__spreadValues({}, start), toStart));
        },
        onUpdate(props) {
          node.setProperties(props);
          if (intermediateFn) {
            node.setProperties(intermediateFn(node, node.datum, status, ctx));
          }
        },
        onStop: () => {
          node.setProperties(__spreadValues(__spreadValues(__spreadValues({}, to), finish), toFinish));
        },
        duration: ((_c = duration != null ? duration : toDuration) != null ? _c : 1) * defaultDuration,
        delay: ((_d = delay != null ? delay : toDelay) != null ? _d : 0) * defaultDuration
      });
      if (isLive) {
        liveNodeIndex++;
      }
      nodeIndex++;
      prevFromProps = from;
    }
  };
  let selectionIndex = 0;
  for (const selection of selections) {
    const nodes2 = selection.nodes();
    const liveNodes = nodes2.filter((n) => !selection.isGarbage(n));
    processNodes(liveNodes, nodes2);
    animationManager.animate({
      id: `${groupId}_${subId}_selection_${selectionIndex}`,
      groupId,
      from: 0,
      to: 1,
      ease: easeOut,
      onStop() {
        selection.cleanup();
      }
    });
    selectionIndex++;
  }
  processNodes(nodes, nodes);
}
function staticFromToMotion(groupId, subId, animationManager, selectionsOrNodes, from, to, extraOpts = {}) {
  const { nodes, selections } = deconstructSelectionsOrNodes(selectionsOrNodes);
  const { animationDelay = 0, animationDuration = 1, start = {}, finish = {} } = extraOpts;
  const { defaultDuration } = animationManager;
  animationManager.animate({
    id: `${groupId}_${subId}`,
    groupId,
    from,
    to,
    ease: easeOut,
    onPlay: () => {
      for (const node of nodes) {
        node.setProperties(start);
      }
      for (const selection of selections) {
        for (const node of selection.nodes()) {
          node.setProperties(start);
        }
      }
    },
    onUpdate(props) {
      for (const node of nodes) {
        node.setProperties(props);
      }
      for (const selection of selections) {
        for (const node of selection.nodes()) {
          node.setProperties(props);
        }
      }
    },
    onStop: () => {
      for (const node of nodes) {
        node.setProperties(__spreadValues(__spreadValues({}, to), finish));
      }
      for (const selection of selections) {
        for (const node of selection.nodes()) {
          node.setProperties(__spreadValues(__spreadValues({}, to), finish));
        }
      }
    },
    duration: animationDuration * defaultDuration,
    delay: animationDelay * defaultDuration
  });
}
function calculateStatus(node, datum, getDatumId, ids) {
  const id = getDatumId(node, datum);
  if (ids.added[id]) {
    return "added";
  } else if (ids.removed[id]) {
    return "removed";
  }
  return "updated";
}

// packages/ag-charts-community/src/motion/resetMotion.ts
var resetMotion_exports = {};
__export(resetMotion_exports, {
  resetMotion: () => resetMotion
});
function resetMotion(selectionsOrNodes, propsFn) {
  const { nodes, selections } = deconstructSelectionsOrNodes(selectionsOrNodes);
  for (const selection of selections) {
    for (const node of selection.nodes()) {
      const from = propsFn(node, node.datum);
      node.setProperties(from);
    }
    selection.cleanup();
  }
  for (const node of nodes) {
    const from = propsFn(node, node.datum);
    node.setProperties(from);
  }
}

// packages/ag-charts-community/src/scene/dropShadow.ts
var DropShadow = class extends BaseProperties {
  constructor() {
    super(...arguments);
    this.enabled = true;
    this.color = "rgba(0, 0, 0, 0.5)";
    this.xOffset = 0;
    this.yOffset = 0;
    this.blur = 5;
  }
};
__decorateClass([
  Validate(BOOLEAN),
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], DropShadow.prototype, "enabled", 2);
__decorateClass([
  Validate(COLOR_STRING),
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], DropShadow.prototype, "color", 2);
__decorateClass([
  Validate(NUMBER),
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], DropShadow.prototype, "xOffset", 2);
__decorateClass([
  Validate(NUMBER),
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], DropShadow.prototype, "yOffset", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER),
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], DropShadow.prototype, "blur", 2);

// packages/ag-charts-community/src/scene/canvas/hdpiOffscreenCanvas.ts
var HdpiOffscreenCanvas = class {
  // The width/height attributes of the Canvas element default to
  // 300/150 according to w3.org.
  constructor({ width = 600, height = 300, overrideDevicePixelRatio }) {
    this.enabled = true;
    // `NaN` is deliberate here, so that overrides are always applied
    // and the `resetTransform` inside the `resize` method works in IE11.
    this._pixelRatio = NaN;
    this._width = 0;
    this._height = 0;
    this.canvas = new OffscreenCanvas(width, height);
    this.realContext = this.canvas.getContext("2d");
    this.imageSource = this.canvas.transferToImageBitmap();
    this.context = this.setPixelRatio(overrideDevicePixelRatio);
    this.resize(width, height);
  }
  static isSupported() {
    return typeof OffscreenCanvas !== "undefined" && OffscreenCanvas.prototype.transferToImageBitmap != null;
  }
  snapshot() {
    this.imageSource.close();
    this.imageSource = this.canvas.transferToImageBitmap();
  }
  destroy() {
    this.imageSource.close();
    this.canvas.width = 0;
    this.canvas.height = 0;
    this.context.clearRect(0, 0, 0, 0);
  }
  clear() {
    this.context.save();
    this.context.resetTransform();
    this.context.clearRect(0, 0, this.width, this.height);
    this.context.restore();
  }
  get pixelRatio() {
    return this._pixelRatio;
  }
  /**
   * Changes the pixel ratio of the Canvas element to the given value,
   * or uses the window.devicePixelRatio (default), then resizes the Canvas
   * element accordingly (default).
   */
  setPixelRatio(ratio) {
    let pixelRatio = ratio != null ? ratio : window.devicePixelRatio;
    if (hasConstrainedCanvasMemory()) {
      pixelRatio = 1;
    }
    this._pixelRatio = pixelRatio;
    return HdpiCanvas.overrideScale(this.realContext, pixelRatio);
  }
  get width() {
    return this._width;
  }
  get height() {
    return this._height;
  }
  resize(width, height) {
    if (!(width > 0 && height > 0)) {
      return;
    }
    const { canvas, context, pixelRatio } = this;
    canvas.width = Math.round(width * pixelRatio);
    canvas.height = Math.round(height * pixelRatio);
    context.resetTransform();
    this._width = width;
    this._height = height;
  }
};

// packages/ag-charts-community/src/scene/scene.ts
var advancedCompositeIdentifier = "adv-composite";
var domCompositeIdentifier = "dom-composite";
var Scene = class {
  constructor(opts) {
    this.id = createId$1(this);
    this.layers = [];
    this._nextZIndex = 0;
    this._nextLayerId = 0;
    this._dirty = false;
    this._root = null;
    this.debug = Debug.create(true, "scene" /* SCENE */);
    var _a;
    const {
      document: document2,
      window: window2,
      mode = (_a = windowValue("agChartsSceneRenderModel")) != null ? _a : advancedCompositeIdentifier,
      width,
      height,
      overrideDevicePixelRatio = void 0
    } = opts;
    this.overrideDevicePixelRatio = overrideDevicePixelRatio;
    this.opts = { document: document2, window: window2, mode };
    this.canvas = new HdpiCanvas({ document: document2, window: window2, width, height, overrideDevicePixelRatio });
  }
  set container(value) {
    this.canvas.container = value;
  }
  get container() {
    return this.canvas.container;
  }
  download(fileName, fileFormat) {
    this.canvas.download(fileName, fileFormat);
  }
  getDataURL(type) {
    return this.canvas.getDataURL(type);
  }
  get width() {
    return this.pendingSize ? this.pendingSize[0] : this.canvas.width;
  }
  get height() {
    return this.pendingSize ? this.pendingSize[1] : this.canvas.height;
  }
  resize(width, height) {
    width = Math.round(width);
    height = Math.round(height);
    const lessThanZero = width <= 0 || height <= 0;
    const nan = isNaN(width) || isNaN(height);
    const unchanged = width === this.width && height === this.height;
    if (unchanged || nan || lessThanZero) {
      return false;
    }
    this.pendingSize = [width, height];
    this.markDirty();
    return true;
  }
  addLayer(opts) {
    var _a;
    const { mode } = this.opts;
    const layeredModes = ["composite", domCompositeIdentifier, advancedCompositeIdentifier];
    if (!layeredModes.includes(mode)) {
      return void 0;
    }
    const { zIndex = this._nextZIndex++, name, zIndexSubOrder, getComputedOpacity, getVisibility } = opts;
    const { width, height, overrideDevicePixelRatio } = this;
    const domLayer = mode === domCompositeIdentifier;
    const advLayer = mode === advancedCompositeIdentifier;
    const canvas = !advLayer || !HdpiOffscreenCanvas.isSupported() ? new HdpiCanvas({
      document: this.opts.document,
      window: this.opts.window,
      width,
      height,
      domLayer,
      zIndex,
      name,
      overrideDevicePixelRatio
    }) : new HdpiOffscreenCanvas({
      width,
      height,
      overrideDevicePixelRatio
    });
    const newLayer = {
      id: this._nextLayerId++,
      name,
      zIndex,
      zIndexSubOrder,
      canvas,
      getComputedOpacity,
      getVisibility
    };
    if (zIndex >= this._nextZIndex) {
      this._nextZIndex = zIndex + 1;
    }
    this.layers.push(newLayer);
    this.sortLayers();
    if (domLayer) {
      const domCanvases = this.layers.map((v) => v.canvas).filter((v) => v instanceof HdpiCanvas);
      const newLayerIndex = domCanvases.findIndex((v) => v === canvas);
      const lastLayer = (_a = domCanvases[newLayerIndex - 1]) != null ? _a : this.canvas;
      lastLayer.element.insertAdjacentElement("afterend", canvas.element);
    }
    this.debug("Scene.addLayer() - layers", this.layers);
    return newLayer.canvas;
  }
  removeLayer(canvas) {
    const index = this.layers.findIndex((l) => l.canvas === canvas);
    if (index >= 0) {
      this.layers.splice(index, 1);
      canvas.destroy();
      this.markDirty();
      this.debug("Scene.removeLayer() -  layers", this.layers);
    }
  }
  moveLayer(canvas, newZIndex, newZIndexSubOrder) {
    const layer = this.layers.find((l) => l.canvas === canvas);
    if (layer) {
      layer.zIndex = newZIndex;
      layer.zIndexSubOrder = newZIndexSubOrder;
      this.sortLayers();
      this.markDirty();
      this.debug("Scene.moveLayer() -  layers", this.layers);
    }
  }
  sortLayers() {
    this.layers.sort((a, b) => {
      var _a, _b;
      return compoundAscending(
        [a.zIndex, ...(_a = a.zIndexSubOrder) != null ? _a : [void 0, void 0], a.id],
        [b.zIndex, ...(_b = b.zIndexSubOrder) != null ? _b : [void 0, void 0], b.id],
        ascendingStringNumberUndefined
      );
    });
  }
  markDirty() {
    this._dirty = true;
  }
  get dirty() {
    return this._dirty;
  }
  set root(node) {
    var _a;
    if (node === this._root) {
      return;
    }
    (_a = this._root) == null ? void 0 : _a._setLayerManager();
    this._root = node;
    if (node) {
      node._setLayerManager({
        addLayer: (opts) => this.addLayer(opts),
        moveLayer: (...opts) => this.moveLayer(...opts),
        removeLayer: (...opts) => this.removeLayer(...opts),
        markDirty: () => this.markDirty(),
        canvas: this.canvas,
        debug: Debug.create("scene" /* SCENE */)
      });
    }
    this.markDirty();
  }
  get root() {
    return this._root;
  }
  /** Alternative to destroy() that preserves re-usable resources. */
  strip() {
    const { layers } = this;
    for (const layer of layers) {
      layer.canvas.destroy();
      delete layer["canvas"];
    }
    layers.splice(0, layers.length);
    this.root = null;
    this._dirty = false;
    this.canvas.context.resetTransform();
  }
  destroy() {
    this.container = void 0;
    this.strip();
    this.canvas.destroy();
    Object.assign(this, { canvas: void 0, ctx: void 0 });
  }
  render(opts) {
    return __async(this, null, function* () {
      var _a, _b;
      const { debugSplitTimes = { start: performance.now() }, extraDebugStats = {} } = opts != null ? opts : {};
      const {
        canvas,
        canvas: { context: ctx },
        root,
        layers,
        pendingSize,
        opts: { mode }
      } = this;
      if (pendingSize) {
        this.canvas.resize(...pendingSize);
        this.layers.forEach((layer) => layer.canvas.resize(...pendingSize));
        this.pendingSize = void 0;
      }
      if (root && !root.visible) {
        this._dirty = false;
        return;
      }
      if (root && !this.dirty) {
        this.debug("Scene.render() - no-op", {
          redrawType: RedrawType[root.dirty],
          tree: this.buildTree(root)
        });
        this.debugStats(debugSplitTimes, ctx, void 0, extraDebugStats);
        return;
      }
      const renderCtx = {
        ctx,
        devicePixelRatio: (_a = this.canvas.pixelRatio) != null ? _a : 1,
        forceRender: true,
        resized: !!pendingSize,
        debugNodes: {}
      };
      if (Debug.check("scene:stats:verbose" /* SCENE_STATS_VERBOSE */)) {
        renderCtx.stats = { layersRendered: 0, layersSkipped: 0, nodesRendered: 0, nodesSkipped: 0 };
      }
      let canvasCleared = false;
      if (!root || root.dirty >= 1 /* TRIVIAL */) {
        canvasCleared = true;
        canvas.clear();
      }
      if (root) {
        const { dirtyTree, paths } = this.buildDirtyTree(root);
        Debug.create("scene:dirtyTree" /* SCENE_DIRTY_TREE */)("Scene.render() - dirtyTree", {
          dirtyTree,
          paths
        });
      }
      if (root && canvasCleared) {
        this.debug("Scene.render() - before", {
          redrawType: RedrawType[root.dirty],
          canvasCleared,
          tree: this.buildTree(root)
        });
        if (root.visible) {
          ctx.save();
          root.render(renderCtx);
          ctx.restore();
        }
      }
      debugSplitTimes["\u270D\uFE0F"] = performance.now();
      if (mode !== domCompositeIdentifier && layers.length > 0 && canvasCleared) {
        this.sortLayers();
        ctx.save();
        ctx.setTransform(1 / canvas.pixelRatio, 0, 0, 1 / canvas.pixelRatio, 0, 0);
        layers.forEach(({ canvas: { imageSource, enabled }, getComputedOpacity, getVisibility }) => {
          if (!enabled || !getVisibility()) {
            return;
          }
          ctx.globalAlpha = getComputedOpacity();
          ctx.drawImage(imageSource, 0, 0);
        });
        ctx.restore();
        debugSplitTimes["\u26D9"] = performance.now();
      }
      (_b = ctx.verifyDepthZero) == null ? void 0 : _b.call(ctx);
      this._dirty = false;
      this.debugStats(debugSplitTimes, ctx, renderCtx.stats, extraDebugStats);
      this.debugSceneNodeHighlight(ctx, renderCtx.debugNodes);
      if (root) {
        this.debug("Scene.render() - after", {
          redrawType: RedrawType[root.dirty],
          canvasCleared,
          tree: this.buildTree(root)
        });
      }
    });
  }
  debugStats(debugSplitTimes, ctx, renderCtxStats, extraDebugStats = {}) {
    if (Debug.check("scene:stats" /* SCENE_STATS */, "scene:stats:verbose" /* SCENE_STATS_VERBOSE */)) {
      const end = performance.now();
      const start = debugSplitTimes["start"];
      debugSplitTimes["end"] = performance.now();
      const pct = (rendered, skipped) => {
        const total = rendered + skipped;
        return `${rendered} / ${total} (${Math.round(100 * rendered / total)}%)`;
      };
      const time = (name, start2, end2) => {
        return `${name}: ${Math.round((end2 - start2) * 100) / 100}ms`;
      };
      const { layersRendered = 0, layersSkipped = 0, nodesRendered = 0, nodesSkipped = 0 } = renderCtxStats != null ? renderCtxStats : {};
      let lastSplit = 0;
      const splits = Object.entries(debugSplitTimes).filter(([n]) => n !== "end").map(([n, t], i) => {
        const result = i > 0 ? time(n, lastSplit, t) : null;
        lastSplit = t;
        return result;
      }).filter((v) => v != null).join(" + ");
      const extras = Object.entries(extraDebugStats).map(([k, v]) => `${k}: ${v}`).join(" ; ");
      const detailedStats = Debug.check("scene:stats:verbose" /* SCENE_STATS_VERBOSE */);
      const stats = [
        `${time("\u23F1\uFE0F", start, end)} (${splits})`,
        `${extras}`,
        `Layers: ${detailedStats ? pct(layersRendered, layersSkipped) : this.layers.length}`,
        detailedStats ? `Nodes: ${pct(nodesRendered, nodesSkipped)}` : null
      ].filter((v) => v != null);
      const statsSize = stats.map((t) => [t, HdpiCanvas.getTextSize(t, ctx.font)]);
      const width = Math.max(...statsSize.map(([, { width: width2 }]) => width2));
      const height = statsSize.reduce((total, [, { height: height2 }]) => total + height2, 0);
      ctx.save();
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, width, height);
      ctx.fillStyle = "black";
      let y = 0;
      for (const [stat, size] of statsSize) {
        y += size.height;
        ctx.fillText(stat, 2, y);
      }
      ctx.restore();
    }
  }
  debugSceneNodeHighlight(ctx, debugNodes) {
    var _a;
    const regexpPredicate = (matcher) => (n) => {
      if (matcher.test(n.id)) {
        return true;
      }
      return n instanceof Group && n.name != null && matcher.test(n.name);
    };
    const stringPredicate = (match) => (n) => {
      if (match === n.id) {
        return true;
      }
      return n instanceof Group && n.name != null && match === n.name;
    };
    const sceneNodeHighlight = toArray(windowValue("agChartsSceneDebug")).flatMap(
      (name) => name === "layout" ? ["seriesRoot", "legend", "root", /.*Axis-\d+-axis.*/] : name
    );
    for (const next of sceneNodeHighlight) {
      if (typeof next === "string" && debugNodes[next] != null)
        continue;
      const predicate = typeof next === "string" ? stringPredicate(next) : regexpPredicate(next);
      const nodes = (_a = this.root) == null ? void 0 : _a.findNodes(predicate);
      if (!nodes || nodes.length === 0) {
        Logger.log(`Scene.render() - no debugging node with id [${next}] in scene graph.`);
        continue;
      }
      for (const node of nodes) {
        if (node instanceof Group && node.name) {
          debugNodes[node.name] = node;
        } else {
          debugNodes[node.id] = node;
        }
      }
    }
    ctx.save();
    for (const [name, node] of Object.entries(debugNodes)) {
      const bbox = node.computeTransformedBBox();
      if (!bbox) {
        Logger.log(`Scene.render() - no bbox for debugged node [${name}].`);
        continue;
      }
      ctx.globalAlpha = 0.8;
      ctx.strokeStyle = "red";
      ctx.lineWidth = 1;
      ctx.strokeRect(bbox.x, bbox.y, bbox.width, bbox.height);
      ctx.fillStyle = "red";
      ctx.strokeStyle = "white";
      ctx.font = "16px sans-serif";
      ctx.textBaseline = "top";
      ctx.textAlign = "left";
      ctx.lineWidth = 2;
      ctx.strokeText(name, bbox.x, bbox.y, bbox.width);
      ctx.fillText(name, bbox.x, bbox.y, bbox.width);
    }
    ctx.restore();
  }
  buildTree(node) {
    var _a, _b;
    const name = (_a = node instanceof Group ? node.name : null) != null ? _a : node.id;
    return __spreadValues(__spreadValues({
      name,
      node,
      dirty: RedrawType[node.dirty]
    }, ((_b = node.parent) == null ? void 0 : _b.isVirtual) ? {
      virtualParentDirty: RedrawType[node.parent.dirty],
      virtualParent: node.parent
    } : {}), node.children.map((c) => this.buildTree(c)).reduce((result, childTree) => {
      let { name: treeNodeName } = childTree;
      const {
        node: { visible, opacity, zIndex, zIndexSubOrder },
        node: childNode,
        virtualParent
      } = childTree;
      if (!visible || opacity <= 0) {
        treeNodeName = `(${treeNodeName})`;
      }
      if (childNode instanceof Group && childNode.isLayer()) {
        treeNodeName = `*${treeNodeName}*`;
      }
      const key = [
        `${treeNodeName != null ? treeNodeName : "<unknown>"}`,
        `z: ${zIndex}`,
        zIndexSubOrder && `zo: ${zIndexSubOrder.map((v) => typeof v === "function" ? `${v()} (fn)` : v).join(" / ")}`,
        virtualParent && `(virtual parent)`
      ].filter((v) => !!v).join(" ");
      let selectedKey = key;
      let index = 1;
      while (result[selectedKey] != null && index < 100) {
        selectedKey = `${key} (${index++})`;
      }
      result[selectedKey] = childTree;
      return result;
    }, {}));
  }
  buildDirtyTree(node) {
    var _a;
    if (node.dirty === 0 /* NONE */) {
      return { dirtyTree: {}, paths: [] };
    }
    const childrenDirtyTree = node.children.map((c) => this.buildDirtyTree(c)).filter((c) => c.paths.length > 0);
    const name = (_a = node instanceof Group ? node.name : null) != null ? _a : node.id;
    const paths = childrenDirtyTree.length === 0 ? [name] : childrenDirtyTree.map((c) => c.paths).reduce((r, p) => r.concat(p), []).map((p) => `${name}.${p}`);
    return {
      dirtyTree: __spreadValues({
        name,
        node,
        dirty: RedrawType[node.dirty]
      }, childrenDirtyTree.map((c) => c.dirtyTree).filter((t) => t.dirty !== void 0).reduce((result, childTree) => {
        var _a2;
        result[(_a2 = childTree.name) != null ? _a2 : "<unknown>"] = childTree;
        return result;
      }, {})),
      paths
    };
  }
};
Scene.className = "Scene";

// packages/ag-charts-community/src/scene/selection.ts
var Selection = class _Selection {
  constructor(parentNode, classOrFactory, autoCleanup = true) {
    this.parentNode = parentNode;
    this.autoCleanup = autoCleanup;
    this.garbageBin = /* @__PURE__ */ new Set();
    this._nodesMap = /* @__PURE__ */ new Map();
    this._nodes = [];
    this.data = [];
    this.debug = Debug.create(true, "scene", "scene:selections");
    this.nodeFactory = Object.prototype.isPrototypeOf.call(Node, classOrFactory) ? () => new classOrFactory() : classOrFactory;
  }
  static select(parent, classOrFactory, garbageCollection = true) {
    return new _Selection(parent, classOrFactory, garbageCollection);
  }
  static selectAll(parent, predicate) {
    const results = [];
    const traverse = (node) => {
      if (predicate(node)) {
        results.push(node);
      }
      node.children.forEach(traverse);
    };
    traverse(parent);
    return results;
  }
  static selectByClass(node, Class) {
    return _Selection.selectAll(node, (node2) => node2 instanceof Class);
  }
  static selectByTag(node, tag) {
    return _Selection.selectAll(node, (node2) => node2.tag === tag);
  }
  createNode(datum, initializer, idx) {
    const node = this.nodeFactory(datum);
    node.datum = datum;
    initializer == null ? void 0 : initializer(node);
    if (idx != null) {
      this._nodes.splice(idx, 0, node);
    } else {
      this._nodes.push(node);
    }
    this.parentNode.appendChild(node);
    return node;
  }
  /**
   * Update the data in a selection. If an `getDatumId()` function is provided, maintain a list of ids related to
   * the nodes. Otherwise, take the more efficient route of simply creating and destroying nodes at the end
   * of the array.
   */
  update(data, initializer, getDatumId) {
    if (this.garbageBin.size > 0) {
      this.debug(`Selection - update() called with pending garbage: ${data}`);
    }
    if (getDatumId) {
      const dataMap = new Map(
        data.map((datum, idx) => [getDatumId(datum), [datum, idx]])
      );
      for (const [node, datumId] of this._nodesMap.entries()) {
        if (dataMap.has(datumId)) {
          const [newDatum] = dataMap.get(datumId);
          node.datum = newDatum;
          this.garbageBin.delete(node);
          dataMap.delete(datumId);
        } else {
          this.garbageBin.add(node);
        }
      }
      for (const [datumId, [datum, idx]] of dataMap.entries()) {
        this._nodesMap.set(this.createNode(datum, initializer, idx), datumId);
      }
    } else {
      const maxLength = Math.max(data.length, this.data.length);
      for (let i = 0; i < maxLength; i++) {
        if (i >= data.length) {
          this.garbageBin.add(this._nodes[i]);
        } else if (i >= this._nodes.length) {
          this.createNode(data[i], initializer);
        } else {
          this._nodes[i].datum = data[i];
          this.garbageBin.delete(this._nodes[i]);
        }
      }
    }
    this.data = data.slice();
    if (this.autoCleanup) {
      this.cleanup();
    }
    return this;
  }
  cleanup() {
    if (this.garbageBin.size === 0) {
      return this;
    }
    this._nodes = this._nodes.filter((node) => {
      if (this.garbageBin.has(node)) {
        this._nodesMap.delete(node);
        this.garbageBin.delete(node);
        this.parentNode.removeChild(node);
        return false;
      }
      return true;
    });
    return this;
  }
  clear() {
    this.update([]);
    return this;
  }
  isGarbage(node) {
    return this.garbageBin.has(node);
  }
  hasGarbage() {
    return this.garbageBin.size > 0;
  }
  each(iterate) {
    this._nodes.forEach((node, i) => iterate(node, node.datum, i));
    return this;
  }
  *[Symbol.iterator]() {
    for (let index = 0; index < this._nodes.length; index++) {
      const node = this._nodes[index];
      const datum = this._nodes[index].datum;
      yield { node, datum, index };
    }
  }
  select(predicate) {
    return _Selection.selectAll(this.parentNode, predicate);
  }
  selectByClass(Class) {
    return _Selection.selectByClass(this.parentNode, Class);
  }
  selectByTag(tag) {
    return _Selection.selectByTag(this.parentNode, tag);
  }
  nodes() {
    return this._nodes;
  }
};

// packages/ag-charts-community/src/scene/polyRoots.ts
function linearRoot(a, b) {
  const t = -b / a;
  return a !== 0 && t >= 0 && t <= 1 ? [t] : [];
}
function quadraticRoots(a, b, c) {
  if (a === 0) {
    return linearRoot(b, c);
  }
  const D = b * b - 4 * a * c;
  const roots = [];
  if (D === 0) {
    const t = -b / (2 * a);
    if (t >= 0 && t <= 1) {
      roots.push(t);
    }
  } else if (D > 0) {
    const rD = Math.sqrt(D);
    const t1 = (-b - rD) / (2 * a);
    const t2 = (-b + rD) / (2 * a);
    if (t1 >= 0 && t1 <= 1) {
      roots.push(t1);
    }
    if (t2 >= 0 && t2 <= 1) {
      roots.push(t2);
    }
  }
  return roots;
}
function cubicRoots(a, b, c, d) {
  if (a === 0) {
    return quadraticRoots(b, c, d);
  }
  const A = b / a;
  const B = c / a;
  const C = d / a;
  const Q = (3 * B - A * A) / 9;
  const R = (9 * A * B - 27 * C - 2 * A * A * A) / 54;
  const D = Q * Q * Q + R * R;
  const third = 1 / 3;
  const roots = [];
  if (D >= 0) {
    const rD = Math.sqrt(D);
    const S = Math.sign(R + rD) * Math.pow(Math.abs(R + rD), third);
    const T = Math.sign(R - rD) * Math.pow(Math.abs(R - rD), third);
    const Im = Math.abs(Math.sqrt(3) * (S - T) / 2);
    const t = -third * A + (S + T);
    if (t >= 0 && t <= 1) {
      roots.push(t);
    }
    if (Im === 0) {
      const t2 = -third * A - (S + T) / 2;
      if (t2 >= 0 && t2 <= 1) {
        roots.push(t2);
      }
    }
  } else {
    const theta = Math.acos(R / Math.sqrt(-Q * Q * Q));
    const thirdA = third * A;
    const twoSqrtQ = 2 * Math.sqrt(-Q);
    const t1 = twoSqrtQ * Math.cos(third * theta) - thirdA;
    const t2 = twoSqrtQ * Math.cos(third * (theta + 2 * Math.PI)) - thirdA;
    const t3 = twoSqrtQ * Math.cos(third * (theta + 4 * Math.PI)) - thirdA;
    if (t1 >= 0 && t1 <= 1) {
      roots.push(t1);
    }
    if (t2 >= 0 && t2 <= 1) {
      roots.push(t2);
    }
    if (t3 >= 0 && t3 <= 1) {
      roots.push(t3);
    }
  }
  return roots;
}

// packages/ag-charts-community/src/scene/intersection.ts
function segmentIntersection(ax1, ay1, ax2, ay2, bx1, by1, bx2, by2) {
  const d = (ax2 - ax1) * (by2 - by1) - (ay2 - ay1) * (bx2 - bx1);
  if (d === 0) {
    return null;
  }
  const ua = ((bx2 - bx1) * (ay1 - by1) - (ax1 - bx1) * (by2 - by1)) / d;
  const ub = ((ax2 - ax1) * (ay1 - by1) - (ay2 - ay1) * (ax1 - bx1)) / d;
  if (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
    return {
      x: ax1 + ua * (ax2 - ax1),
      y: ay1 + ua * (ay2 - ay1)
    };
  }
  return null;
}
function cubicSegmentIntersections(px1, py1, px2, py2, px3, py3, px4, py4, x1, y1, x2, y2) {
  const intersections = [];
  const A = y1 - y2;
  const B = x2 - x1;
  const C = x1 * (y2 - y1) - y1 * (x2 - x1);
  const bx = bezierCoefficients(px1, px2, px3, px4);
  const by = bezierCoefficients(py1, py2, py3, py4);
  const a = A * bx[0] + B * by[0];
  const b = A * bx[1] + B * by[1];
  const c = A * bx[2] + B * by[2];
  const d = A * bx[3] + B * by[3] + C;
  const roots = cubicRoots(a, b, c, d);
  for (const t of roots) {
    const tt = t * t;
    const ttt = t * tt;
    const x = bx[0] * ttt + bx[1] * tt + bx[2] * t + bx[3];
    const y = by[0] * ttt + by[1] * tt + by[2] * t + by[3];
    let s;
    if (x1 !== x2) {
      s = (x - x1) / (x2 - x1);
    } else {
      s = (y - y1) / (y2 - y1);
    }
    if (s >= 0 && s <= 1) {
      intersections.push({ x, y });
    }
  }
  return intersections;
}
function bezierCoefficients(P1, P2, P3, P4) {
  return [
    // Bézier expressed as matrix operations:
    -P1 + 3 * P2 - 3 * P3 + P4,
    //                 |-1  3 -3  1| |P1|
    3 * P1 - 6 * P2 + 3 * P3,
    //   [t^3 t^2 t 1] | 3 -6  3  0| |P2|
    -3 * P1 + 3 * P2,
    //                 |-3  3  0  0| |P3|
    P1
    //                 | 1  0  0  0| |P4|
  ];
}
function arcIntersections(cx, cy, r, startAngle, endAngle, counterClockwise, x1, y1, x2, y2) {
  const k = (y2 - y1) / (x2 - x1);
  const y0 = y1 - k * x1;
  const a = Math.pow(k, 2) + 1;
  const b = 2 * (k * (y0 - cy) - cx);
  const c = Math.pow(cx, 2) + Math.pow(y0 - cy, 2) - Math.pow(r, 2);
  const d = Math.pow(b, 2) - 4 * a * c;
  if (d < 0) {
    return [];
  }
  const i1x = (-b + Math.sqrt(d)) / 2 / a;
  const i2x = (-b - Math.sqrt(d)) / 2 / a;
  const intersections = [];
  [i1x, i2x].forEach((x) => {
    const isXInsideLine = x >= Math.min(x1, x2) && x <= Math.max(x1, x2);
    if (!isXInsideLine) {
      return;
    }
    const y = k * x + y0;
    const a1 = normalizeAngle360(startAngle);
    let a2 = normalizeAngle360(endAngle);
    let a3 = normalizeAngle360(Math.atan2(y, x));
    if (a2 <= a1) {
      a2 += 2 * Math.PI;
    }
    if (a3 < a1) {
      a3 += 2 * Math.PI;
    }
    if (counterClockwise !== (a3 >= a1 && a3 <= a2)) {
      intersections.push({ x, y });
    }
  });
  return intersections;
}

// packages/ag-charts-community/src/scene/path2D.ts
var Path2D = class {
  constructor() {
    this.previousCommands = [];
    this.previousParams = [];
    this.previousClosedPath = false;
    this.commands = [];
    this.params = [];
    this._closedPath = false;
  }
  isDirty() {
    if (this._closedPath !== this.previousClosedPath) {
      return true;
    }
    if (this.previousCommands.length !== this.commands.length) {
      return true;
    }
    if (this.previousParams.length !== this.params.length) {
      return true;
    }
    for (let i = 0; i < this.commands.length; i++) {
      if (this.commands[i] !== this.previousCommands[i]) {
        return true;
      }
    }
    for (let i = 0; i < this.params.length; i++) {
      if (this.params[i] !== this.previousParams[i]) {
        return true;
      }
    }
    return false;
  }
  draw(ctx) {
    const commands = this.commands;
    const params = this.params;
    let j = 0;
    ctx.beginPath();
    for (const command of commands) {
      switch (command) {
        case 0 /* Move */:
          ctx.moveTo(params[j++], params[j++]);
          break;
        case 1 /* Line */:
          ctx.lineTo(params[j++], params[j++]);
          break;
        case 3 /* Curve */:
          ctx.bezierCurveTo(params[j++], params[j++], params[j++], params[j++], params[j++], params[j++]);
          break;
        case 2 /* Arc */:
          ctx.arc(params[j++], params[j++], params[j++], params[j++], params[j++], params[j++] === 1);
          break;
        case 4 /* ClosePath */:
          ctx.closePath();
          break;
      }
    }
    if (commands.length === 0) {
      ctx.closePath();
    }
  }
  moveTo(x, y) {
    if (this.xy) {
      this.xy[0] = x;
      this.xy[1] = y;
    } else {
      this.xy = [x, y];
    }
    this.commands.push(0 /* Move */);
    this.params.push(x, y);
  }
  lineTo(x, y) {
    if (this.xy) {
      this.commands.push(1 /* Line */);
      this.params.push(x, y);
      this.xy[0] = x;
      this.xy[1] = y;
    } else {
      this.moveTo(x, y);
    }
  }
  rect(x, y, width, height) {
    this.moveTo(x, y);
    this.lineTo(x + width, y);
    this.lineTo(x + width, y + height);
    this.lineTo(x, y + height);
    this.closePath();
  }
  roundRect(x, y, width, height, radii) {
    radii = Math.min(radii, width / 2, height / 2);
    this.moveTo(x, y + radii);
    this.arc(x + radii, y + radii, radii, Math.PI, 3 * Math.PI / 2);
    this.lineTo(x + radii, y);
    this.lineTo(x + width - radii, y);
    this.arc(x + width - radii, y + radii, radii, 3 * Math.PI / 2, 2 * Math.PI);
    this.lineTo(x + width, y + radii);
    this.lineTo(x + width, y + height - radii);
    this.arc(x + width - radii, y + height - radii, radii, 0, Math.PI / 2);
    this.lineTo(x + width - radii, y + height);
    this.lineTo(x + radii, y + height);
    this.arc(x + +radii, y + height - radii, radii, Math.PI / 2, Math.PI);
    this.lineTo(x, y + height - radii);
    this.closePath();
  }
  arc(x, y, r, sAngle, eAngle, antiClockwise = false) {
    const endX = x + r * Math.cos(eAngle);
    const endY = y + r * Math.sin(eAngle);
    if (this.xy) {
      this.xy[0] = endX;
      this.xy[1] = endY;
    } else {
      this.xy = [endX, endY];
    }
    this.commands.push(2 /* Arc */);
    this.params.push(x, y, r, sAngle, eAngle, antiClockwise ? 1 : 0);
  }
  cubicCurveTo(cx1, cy1, cx2, cy2, x, y) {
    if (!this.xy) {
      this.moveTo(cx1, cy1);
    }
    this.commands.push(3 /* Curve */);
    this.params.push(cx1, cy1, cx2, cy2, x, y);
    if (this.xy) {
      this.xy[0] = x;
      this.xy[1] = y;
    }
  }
  get closedPath() {
    return this._closedPath;
  }
  closePath() {
    if (this.xy) {
      this.xy = void 0;
      this.commands.push(4 /* ClosePath */);
      this._closedPath = true;
    }
  }
  clear({ trackChanges } = { trackChanges: false }) {
    if (trackChanges) {
      this.previousCommands = this.commands;
      this.previousParams = this.params;
      this.previousClosedPath = this._closedPath;
      this.commands = [];
      this.params = [];
    } else {
      this.commands.length = 0;
      this.params.length = 0;
    }
    this.xy = void 0;
    this._closedPath = false;
  }
  isPointInPath(x, y) {
    const commands = this.commands;
    const params = this.params;
    const cn = commands.length;
    const ox = -1e4;
    const oy = -1e4;
    let sx = NaN;
    let sy = NaN;
    let px = 0;
    let py = 0;
    let intersectionCount = 0;
    for (let ci = 0, pi = 0; ci < cn; ci++) {
      switch (commands[ci]) {
        case 0 /* Move */:
          if (!isNaN(sx) && segmentIntersection(sx, sy, px, py, ox, oy, x, y)) {
            intersectionCount++;
          }
          px = params[pi++];
          sx = px;
          py = params[pi++];
          sy = py;
          break;
        case 1 /* Line */:
          if (segmentIntersection(px, py, params[pi++], params[pi++], ox, oy, x, y)) {
            intersectionCount++;
          }
          px = params[pi - 2];
          py = params[pi - 1];
          break;
        case 3 /* Curve */:
          intersectionCount += cubicSegmentIntersections(
            px,
            py,
            params[pi++],
            params[pi++],
            params[pi++],
            params[pi++],
            params[pi++],
            params[pi++],
            ox,
            oy,
            x,
            y
          ).length;
          px = params[pi - 2];
          py = params[pi - 1];
          break;
        case 2 /* Arc */:
          const cx = params[pi++];
          const cy = params[pi++];
          const r = params[pi++];
          const startAngle = params[pi++];
          const endAngle = params[pi++];
          const counterClockwise = Boolean(params[pi++]);
          intersectionCount += arcIntersections(
            cx,
            cy,
            r,
            startAngle,
            endAngle,
            counterClockwise,
            ox,
            oy,
            x,
            y
          ).length;
          px = cx + Math.cos(endAngle) * r;
          py = cy + Math.sin(endAngle) * r;
          break;
        case 4 /* ClosePath */:
          if (!isNaN(sx) && segmentIntersection(sx, sy, px, py, ox, oy, x, y)) {
            intersectionCount++;
          }
          break;
      }
    }
    return intersectionCount % 2 === 1;
  }
  getPoints() {
    const { commands, params } = this;
    const coords = [];
    let pi = 0;
    for (let ci = 0; ci < commands.length; ci++) {
      switch (commands[ci]) {
        case 0 /* Move */:
        case 1 /* Line */:
          coords.push({ x: params[pi++], y: params[pi++] });
          break;
        case 3 /* Curve */:
          pi += 4;
          coords.push({ x: params[pi++], y: params[pi++] });
          break;
        case 2 /* Arc */:
          coords.push({ x: params[pi++], y: params[pi++] });
          pi += 4;
          break;
      }
    }
    return coords;
  }
};

// packages/ag-charts-community/src/scene/shape/path.ts
function ScenePathChangeDetection(opts) {
  const { redraw = 3 /* MAJOR */, changeCb, convertor } = opts != null ? opts : {};
  return SceneChangeDetection({ redraw, type: "path", convertor, changeCb });
}
var Path = class extends Shape {
  constructor() {
    super(...arguments);
    /**
     * Declare a path to retain for later rendering and hit testing
     * using custom Path2D class. Think of it as a TypeScript version
     * of the native Path2D (with some differences) that works in all browsers.
     */
    this.path = new Path2D();
    this.clipScalingX = 1;
    this.clipScalingY = 1;
    /**
     * The path only has to be updated when certain attributes change.
     * For example, if transform attributes (such as `translationX`)
     * are changed, we don't have to update the path. The `dirtyPath` flag
     * is how we keep track if the path has to be updated or not.
     */
    this._dirtyPath = true;
  }
  set dirtyPath(value) {
    if (this._dirtyPath !== value) {
      this._dirtyPath = value;
      if (value) {
        this.markDirty(this, 3 /* MAJOR */);
      }
    }
  }
  get dirtyPath() {
    return this._dirtyPath;
  }
  checkPathDirty() {
    var _a, _b, _c, _d;
    if (this._dirtyPath) {
      return;
    }
    this.dirtyPath = this.path.isDirty() || ((_b = (_a = this.fillShadow) == null ? void 0 : _a.isDirty()) != null ? _b : false) || ((_d = (_c = this.clipPath) == null ? void 0 : _c.isDirty()) != null ? _d : false);
  }
  isPointInPath(x, y) {
    const point = this.transformPoint(x, y);
    return this.path.closedPath && this.path.isPointInPath(point.x, point.y);
  }
  isDirtyPath() {
    return false;
  }
  updatePath() {
  }
  clip(ctx, op) {
    const transform = ctx.getTransform();
    const clipScale = this.clipScalingX !== 1 || this.clipScalingY !== 1;
    if (clipScale) {
      ctx.scale(this.clipScalingX, this.clipScalingY);
    }
    op();
    if (clipScale) {
      ctx.setTransform(transform);
    }
  }
  render(renderCtx) {
    var _a;
    const { ctx, forceRender, stats } = renderCtx;
    if (this.dirty === 0 /* NONE */ && !forceRender) {
      if (stats)
        stats.nodesSkipped += this.nodeCount.count;
      return;
    }
    this.computeTransformMatrix();
    this.matrix.toContext(ctx);
    if (this.dirtyPath || this.isDirtyPath()) {
      this.updatePath();
      this.dirtyPath = false;
    }
    if (this.clipPath && this.clipMode != null) {
      ctx.save();
      if (this.clipMode === "normal") {
        this.clip(ctx, () => {
          var _a2;
          (_a2 = this.clipPath) == null ? void 0 : _a2.draw(ctx);
          ctx.clip();
        });
      }
      if (this.clipScalingX > 0 && this.clipScalingY > 0) {
        this.path.draw(ctx);
        this.fillStroke(ctx);
      }
      if (this.clipMode === "punch-out") {
        this.clip(ctx, () => {
          var _a2, _b;
          (_a2 = this.clipPath) == null ? void 0 : _a2.draw(ctx);
          ctx.clip();
          const { x = -1e4, y = -1e4, width = 2e4, height = 2e4 } = (_b = this.computeBBox()) != null ? _b : {};
          ctx.clearRect(x, y, width, height);
        });
      }
      ctx.restore();
    } else {
      this.path.draw(ctx);
      this.fillStroke(ctx);
    }
    (_a = this.fillShadow) == null ? void 0 : _a.markClean();
    super.render(renderCtx);
  }
};
Path.className = "Path";
__decorateClass([
  ScenePathChangeDetection()
], Path.prototype, "clipPath", 2);
__decorateClass([
  ScenePathChangeDetection()
], Path.prototype, "clipMode", 2);
__decorateClass([
  ScenePathChangeDetection()
], Path.prototype, "clipScalingX", 2);
__decorateClass([
  ScenePathChangeDetection()
], Path.prototype, "clipScalingY", 2);

// packages/ag-charts-community/src/scene/shape/arc.ts
var Arc = class extends Path {
  constructor() {
    super();
    this.centerX = 0;
    this.centerY = 0;
    this.radius = 10;
    this.startAngle = 0;
    this.endAngle = Math.PI * 2;
    this.counterClockwise = false;
    this.type = 0 /* Open */;
    this.restoreOwnStyles();
  }
  get fullPie() {
    return isEqual(normalizeAngle360(this.startAngle), normalizeAngle360(this.endAngle));
  }
  updatePath() {
    const path = this.path;
    path.clear();
    path.arc(this.centerX, this.centerY, this.radius, this.startAngle, this.endAngle, this.counterClockwise);
    if (this.type === 1 /* Chord */) {
      path.closePath();
    } else if (this.type === 2 /* Round */ && !this.fullPie) {
      path.lineTo(this.centerX, this.centerY);
      path.closePath();
    }
  }
  computeBBox() {
    return new BBox(this.centerX - this.radius, this.centerY - this.radius, this.radius * 2, this.radius * 2);
  }
  isPointInPath(x, y) {
    const point = this.transformPoint(x, y);
    const bbox = this.computeBBox();
    return this.type !== 0 /* Open */ && bbox.containsPoint(point.x, point.y) && this.path.isPointInPath(point.x, point.y);
  }
};
Arc.className = "Arc";
Arc.defaultStyles = Object.assign({}, Shape.defaultStyles, {
  lineWidth: 1,
  fillStyle: null
});
__decorateClass([
  ScenePathChangeDetection()
], Arc.prototype, "centerX", 2);
__decorateClass([
  ScenePathChangeDetection()
], Arc.prototype, "centerY", 2);
__decorateClass([
  ScenePathChangeDetection()
], Arc.prototype, "radius", 2);
__decorateClass([
  ScenePathChangeDetection()
], Arc.prototype, "startAngle", 2);
__decorateClass([
  ScenePathChangeDetection()
], Arc.prototype, "endAngle", 2);
__decorateClass([
  ScenePathChangeDetection()
], Arc.prototype, "counterClockwise", 2);
__decorateClass([
  ScenePathChangeDetection()
], Arc.prototype, "type", 2);

// packages/ag-charts-community/src/scene/shape/line.ts
var Line = class extends Shape {
  constructor(opts = {}) {
    super(opts);
    this.x1 = 0;
    this.y1 = 0;
    this.x2 = 0;
    this.y2 = 0;
    this.restoreOwnStyles();
  }
  set x(value) {
    this.x1 = value;
    this.x2 = value;
  }
  set y(value) {
    this.y1 = value;
    this.y2 = value;
  }
  computeBBox() {
    return new BBox(
      Math.min(this.x1, this.x2),
      Math.min(this.y1, this.y2),
      Math.abs(this.x2 - this.x1),
      Math.abs(this.y2 - this.y1)
    );
  }
  isPointInPath(px, py) {
    if (this.x1 === this.x2 || this.y1 === this.y2) {
      const { x, y } = this.transformPoint(px, py);
      return this.computeBBox().grow(this.strokeWidth / 2).containsPoint(x, y);
    }
    return false;
  }
  render(renderCtx) {
    var _a;
    const { ctx, forceRender, stats, devicePixelRatio } = renderCtx;
    if (this.dirty === 0 /* NONE */ && !forceRender) {
      if (stats)
        stats.nodesSkipped += this.nodeCount.count;
      return;
    }
    this.computeTransformMatrix();
    this.matrix.toContext(ctx);
    let { x1, y1, x2, y2 } = this;
    if (x1 === x2) {
      const { strokeWidth } = this;
      const x = Math.round(x1 * devicePixelRatio) / devicePixelRatio + Math.trunc(strokeWidth * devicePixelRatio) % 2 / (devicePixelRatio * 2);
      x1 = x;
      x2 = x;
    } else if (y1 === y2) {
      const { strokeWidth } = this;
      const y = Math.round(y1 * devicePixelRatio) / devicePixelRatio + Math.trunc(strokeWidth * devicePixelRatio) % 2 / (devicePixelRatio * 2);
      y1 = y;
      y2 = y;
    }
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    this.fillStroke(ctx);
    (_a = this.fillShadow) == null ? void 0 : _a.markClean();
    super.render(renderCtx);
  }
};
Line.className = "Line";
Line.defaultStyles = Object.assign({}, Shape.defaultStyles, {
  fill: void 0,
  strokeWidth: 1
});
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], Line.prototype, "x1", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], Line.prototype, "y1", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], Line.prototype, "x2", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], Line.prototype, "y2", 2);

// packages/ag-charts-community/src/scale/colorScale.ts
var convertColorStringToOklcha = (v) => {
  const color = Color.fromString(v);
  const [l, c, h] = Color.RGBtoOKLCH(color.r, color.g, color.b);
  return { l, c, h, a: color.a };
};
var interpolateOklch = (x, y, d) => {
  d = Math.min(Math.max(d, 0), 1);
  let h;
  let c;
  if (Number.isNaN(x.h) && Number.isNaN(y.h)) {
    h = 0;
    c = 0;
  } else if (Number.isNaN(x.h)) {
    h = y.h;
    c = y.c;
  } else if (Number.isNaN(y.h)) {
    h = x.h;
    c = x.c;
  } else {
    const xH = x.h;
    let yH = y.h;
    const deltaH = y.h - x.h;
    if (deltaH > 180) {
      yH -= 360;
    } else if (deltaH < -180) {
      yH += 360;
    }
    h = xH * (1 - d) + yH * d;
    c = x.c * (1 - d) + y.c * d;
  }
  const l = x.l * (1 - d) + y.l * d;
  const a = x.a * (1 - d) + y.a * d;
  return Color.fromOKLCH(l, c, h, a);
};
var ColorScale = class {
  constructor() {
    this.invalid = true;
    this.domain = [0, 1];
    this.range = ["red", "blue"];
    this.parsedRange = this.range.map(convertColorStringToOklcha);
  }
  update() {
    const { domain, range: range3 } = this;
    if (domain.length < 2) {
      Logger.warnOnce("`colorDomain` should have at least 2 values.");
      if (domain.length === 0) {
        domain.push(0, 1);
      } else if (domain.length === 1) {
        domain.push(domain[0] + 1);
      }
    }
    for (let i = 1; i < domain.length; i++) {
      const a = domain[i - 1];
      const b = domain[i];
      if (a >= b) {
        Logger.warnOnce("`colorDomain` values should be supplied in ascending order.");
        domain.sort((a2, b2) => a2 - b2);
        break;
      }
    }
    if (range3.length < domain.length) {
      for (let i = range3.length; i < domain.length; i++) {
        range3.push(range3.length > 0 ? range3[0] : "black");
      }
    }
    this.parsedRange = this.range.map(convertColorStringToOklcha);
  }
  convert(x) {
    this.refresh();
    const { domain, range: range3, parsedRange } = this;
    const d0 = domain[0];
    const d1 = domain[domain.length - 1];
    const r0 = range3[0];
    const r1 = range3[range3.length - 1];
    if (x <= d0) {
      return r0;
    }
    if (x >= d1) {
      return r1;
    }
    let index;
    let q;
    if (domain.length === 2) {
      const t = (x - d0) / (d1 - d0);
      const step = 1 / (range3.length - 1);
      index = range3.length <= 2 ? 0 : Math.min(Math.floor(t * (range3.length - 1)), range3.length - 2);
      q = (t - index * step) / step;
    } else {
      for (index = 0; index < domain.length - 2; index++) {
        if (x < domain[index + 1]) {
          break;
        }
      }
      const a = domain[index];
      const b = domain[index + 1];
      q = (x - a) / (b - a);
    }
    const c0 = parsedRange[index];
    const c1 = parsedRange[index + 1];
    return interpolateOklch(c0, c1, q).toRgbaString();
  }
  refresh() {
    if (!this.invalid)
      return;
    this.invalid = false;
    this.update();
    if (this.invalid) {
      Logger.warnOnce("Expected update to not invalidate scale");
    }
  }
};
__decorateClass([
  Invalidating
], ColorScale.prototype, "domain", 2);
__decorateClass([
  Invalidating
], ColorScale.prototype, "range", 2);

// packages/ag-charts-community/src/scene/shape/linearGradientFill.ts
var LinearGradientFill = class extends Shape {
  constructor() {
    super(...arguments);
    this.direction = "to-right";
    this.stops = void 0;
    this._mask = void 0;
  }
  get mask() {
    return this._mask;
  }
  set mask(newMask) {
    if (this._mask != null) {
      this.removeChild(this._mask);
    }
    if (newMask != null) {
      this.appendChild(newMask);
    }
    this._mask = newMask;
  }
  isPointInPath(x, y) {
    var _a, _b;
    return (_b = (_a = this.mask) == null ? void 0 : _a.isPointInPath(x, y)) != null ? _b : false;
  }
  computeBBox() {
    var _a;
    return (_a = this.mask) == null ? void 0 : _a.computeBBox();
  }
  render(renderCtx) {
    const { mask, stops } = this;
    const { ctx, devicePixelRatio } = renderCtx;
    const pixelLength = 1 / devicePixelRatio;
    const maskBbox = mask == null ? void 0 : mask.computeTransformedBBox();
    if (mask == null || stops == null || maskBbox == null)
      return;
    if (mask.dirtyPath) {
      mask.updatePath();
      mask.dirtyPath = false;
    }
    ctx.save();
    ctx.beginPath();
    mask.path.draw(ctx);
    ctx.clip();
    ctx.resetTransform();
    const x0 = Math.floor(maskBbox.x);
    const x1 = Math.ceil(maskBbox.x + maskBbox.width);
    const y0 = Math.floor(maskBbox.y);
    const y1 = Math.ceil(maskBbox.y + maskBbox.height);
    const colorScale = new ColorScale();
    const [i0, i1] = this.direction === "to-right" ? [x0, x1] : [y0, y1];
    colorScale.domain = stops.map((_, index) => {
      return i0 + (i1 - i0) * index / (stops.length - 1);
    });
    colorScale.range = stops;
    colorScale.update();
    if (this.direction === "to-right") {
      const height = y1 - y0;
      for (let x = x0; x <= x1; x += pixelLength) {
        ctx.fillStyle = colorScale.convert(x);
        ctx.fillRect(x, y0, pixelLength, height);
      }
    } else {
      const width = x1 - x0;
      for (let y = y0; y <= y1; y += pixelLength) {
        ctx.fillStyle = colorScale.convert(y);
        ctx.fillRect(x0, y, width, pixelLength);
      }
    }
    ctx.restore();
  }
};
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], LinearGradientFill.prototype, "direction", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], LinearGradientFill.prototype, "stops", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], LinearGradientFill.prototype, "_mask", 2);

// packages/ag-charts-community/src/scene/shape/rect.ts
var epsilon = 1e-6;
var cornerEdges = (leadingEdge, trailingEdge, leadingInset, trailingInset, cornerRadius) => {
  let leadingClipped = false;
  let trailingClipped = false;
  let leading0 = trailingInset - Math.sqrt(Math.max(__pow(cornerRadius, 2) - __pow(leadingInset, 2), 0));
  let leading1 = 0;
  let trailing0 = 0;
  let trailing1 = leadingInset - Math.sqrt(Math.max(__pow(cornerRadius, 2) - __pow(trailingInset, 2), 0));
  if (leading0 > leadingEdge) {
    leadingClipped = true;
    leading0 = leadingEdge;
    leading1 = leadingInset - Math.sqrt(Math.max(__pow(cornerRadius, 2) - __pow(trailingInset - leadingEdge, 2)));
  } else if (leading0 < epsilon) {
    leading0 = 0;
  }
  if (trailing1 > trailingEdge) {
    trailingClipped = true;
    trailing0 = trailingInset - Math.sqrt(Math.max(__pow(cornerRadius, 2) - __pow(leadingInset - trailingEdge, 2)));
    trailing1 = trailingEdge;
  } else if (trailing1 < epsilon) {
    trailing1 = 0;
  }
  return { leading0, leading1, trailing0, trailing1, leadingClipped, trailingClipped };
};
var drawCorner = (path, { x0, y0, x1, y1, cx, cy }, cornerRadius, move) => {
  if (move) {
    path.moveTo(x0, y0);
  }
  if (x0 !== x1 || y0 !== y1) {
    const r0 = Math.atan2(y0 - cy, x0 - cx);
    const r1 = Math.atan2(y1 - cy, x1 - cx);
    path.arc(cx, cy, cornerRadius, r0, r1);
  } else {
    path.lineTo(x0, y0);
  }
};
var insetCornerRadiusRect = (path, x, y, width, height, cornerRadii, cornerRadiusBbox) => {
  let {
    topLeft: topLeftCornerRadius,
    topRight: topRightCornerRadius,
    bottomRight: bottomRightCornerRadius,
    bottomLeft: bottomLeftCornerRadius
  } = cornerRadii;
  const maxVerticalCornerRadius = Math.max(
    topLeftCornerRadius + bottomLeftCornerRadius,
    topRightCornerRadius + bottomRightCornerRadius
  );
  const maxHorizontalCornerRadius = Math.max(
    topLeftCornerRadius + topRightCornerRadius,
    bottomLeftCornerRadius + bottomRightCornerRadius
  );
  if (maxVerticalCornerRadius <= 0 && maxHorizontalCornerRadius <= 0) {
    path.rect(x, y, width, height);
    return;
  } else if (cornerRadiusBbox == null && topLeftCornerRadius === topRightCornerRadius && topLeftCornerRadius === bottomRightCornerRadius && topLeftCornerRadius === bottomLeftCornerRadius) {
    path.roundRect(x, y, width, height, topLeftCornerRadius);
    return;
  }
  if (width < 0) {
    x += width;
    width = Math.abs(width);
  }
  if (height < 0) {
    y += height;
    height = Math.abs(height);
  }
  if (cornerRadiusBbox != null) {
    const x0 = Math.max(x, cornerRadiusBbox.x);
    const x1 = Math.min(x + width, cornerRadiusBbox.x + cornerRadiusBbox.width);
    const y0 = Math.max(y, cornerRadiusBbox.y);
    const y1 = Math.min(y + height, cornerRadiusBbox.y + cornerRadiusBbox.height);
    x = x0;
    y = y0;
    width = x1 - x0;
    height = y1 - y0;
  }
  if (width <= 0 || height <= 0)
    return;
  cornerRadiusBbox != null ? cornerRadiusBbox : cornerRadiusBbox = new BBox(x, y, width, height);
  const borderScale = Math.max(
    maxVerticalCornerRadius / cornerRadiusBbox.height,
    maxHorizontalCornerRadius / cornerRadiusBbox.width,
    1
  );
  if (borderScale > 1) {
    topLeftCornerRadius /= borderScale;
    topRightCornerRadius /= borderScale;
    bottomRightCornerRadius /= borderScale;
    bottomLeftCornerRadius /= borderScale;
  }
  let drawTopLeftCorner = true;
  let drawTopRightCorner = true;
  let drawBottomRightCorner = true;
  let drawBottomLeftCorner = true;
  let topLeftCorner;
  let topRightCorner;
  let bottomRightCorner;
  let bottomLeftCorner;
  if (drawTopLeftCorner) {
    const nodes = cornerEdges(
      height,
      width,
      Math.max(cornerRadiusBbox.x + topLeftCornerRadius - x, 0),
      Math.max(cornerRadiusBbox.y + topLeftCornerRadius - y, 0),
      topLeftCornerRadius
    );
    if (nodes.leadingClipped)
      drawBottomLeftCorner = false;
    if (nodes.trailingClipped)
      drawTopRightCorner = false;
    const x0 = Math.max(x + nodes.leading1, x);
    const y0 = Math.max(y + nodes.leading0, y);
    const x1 = Math.max(x + nodes.trailing1, x);
    const y1 = Math.max(y + nodes.trailing0, y);
    const cx = cornerRadiusBbox.x + topLeftCornerRadius;
    const cy = cornerRadiusBbox.y + topLeftCornerRadius;
    topLeftCorner = { x0, y0, x1, y1, cx, cy };
  }
  if (drawTopRightCorner) {
    const nodes = cornerEdges(
      width,
      height,
      Math.max(cornerRadiusBbox.y + topRightCornerRadius - y, 0),
      Math.max(x + width - (cornerRadiusBbox.x + cornerRadiusBbox.width - topRightCornerRadius), 0),
      topRightCornerRadius
    );
    if (nodes.leadingClipped)
      drawTopLeftCorner = false;
    if (nodes.trailingClipped)
      drawBottomRightCorner = false;
    const x0 = Math.min(x + width - nodes.leading0, x + width);
    const y0 = Math.max(y + nodes.leading1, y);
    const x1 = Math.min(x + width - nodes.trailing0, x + width);
    const y1 = Math.max(y + nodes.trailing1, y);
    const cx = cornerRadiusBbox.x + cornerRadiusBbox.width - topRightCornerRadius;
    const cy = cornerRadiusBbox.y + topRightCornerRadius;
    topRightCorner = { x0, y0, x1, y1, cx, cy };
  }
  if (drawBottomRightCorner) {
    const nodes = cornerEdges(
      height,
      width,
      Math.max(x + width - (cornerRadiusBbox.x + cornerRadiusBbox.width - bottomRightCornerRadius), 0),
      Math.max(y + height - (cornerRadiusBbox.y + cornerRadiusBbox.height - bottomRightCornerRadius), 0),
      bottomRightCornerRadius
    );
    if (nodes.leadingClipped)
      drawTopRightCorner = false;
    if (nodes.trailingClipped)
      drawBottomLeftCorner = false;
    const x0 = Math.min(x + width - nodes.leading1, x + width);
    const y0 = Math.min(y + height - nodes.leading0, y + height);
    const x1 = Math.min(x + width - nodes.trailing1, x + width);
    const y1 = Math.min(y + height - nodes.trailing0, y + height);
    const cx = cornerRadiusBbox.x + cornerRadiusBbox.width - bottomRightCornerRadius;
    const cy = cornerRadiusBbox.y + cornerRadiusBbox.height - bottomRightCornerRadius;
    bottomRightCorner = { x0, y0, x1, y1, cx, cy };
  }
  if (drawBottomLeftCorner) {
    const nodes = cornerEdges(
      width,
      height,
      Math.max(y + height - (cornerRadiusBbox.y + cornerRadiusBbox.height - bottomLeftCornerRadius), 0),
      Math.max(cornerRadiusBbox.x + bottomLeftCornerRadius - x, 0),
      bottomLeftCornerRadius
    );
    if (nodes.leadingClipped)
      drawBottomRightCorner = false;
    if (nodes.trailingClipped)
      drawTopLeftCorner = false;
    const x0 = Math.max(x + nodes.leading0, x);
    const y0 = Math.min(y + height - nodes.leading1, y + height);
    const x1 = Math.max(x + nodes.trailing0, x);
    const y1 = Math.min(y + height - nodes.trailing1, y + height);
    const cx = cornerRadiusBbox.x + bottomLeftCornerRadius;
    const cy = cornerRadiusBbox.y + cornerRadiusBbox.height - bottomLeftCornerRadius;
    bottomLeftCorner = { x0, y0, x1, y1, cx, cy };
  }
  let didMove = false;
  if (drawTopLeftCorner && topLeftCorner != null) {
    drawCorner(path, topLeftCorner, topLeftCornerRadius, !didMove);
    didMove || (didMove = true);
  }
  if (drawTopRightCorner && topRightCorner != null) {
    drawCorner(path, topRightCorner, topRightCornerRadius, !didMove);
    didMove || (didMove = true);
  }
  if (drawBottomRightCorner && bottomRightCorner != null) {
    drawCorner(path, bottomRightCorner, bottomRightCornerRadius, !didMove);
    didMove || (didMove = true);
  }
  if (drawBottomLeftCorner && bottomLeftCorner != null) {
    drawCorner(path, bottomLeftCorner, bottomLeftCornerRadius, !didMove);
    didMove || (didMove = true);
  }
  path.closePath();
};
var Rect = class extends Path {
  constructor() {
    super(...arguments);
    this.borderPath = new Path2D();
    this.x = 0;
    this.y = 0;
    this.width = 10;
    this.height = 10;
    this.topLeftCornerRadius = 0;
    this.topRightCornerRadius = 0;
    this.bottomRightCornerRadius = 0;
    this.bottomLeftCornerRadius = 0;
    this.cornerRadiusBbox = void 0;
    this.crisp = false;
    this.lastUpdatePathStrokeWidth = Shape.defaultStyles.strokeWidth;
    this.effectiveStrokeWidth = Shape.defaultStyles.strokeWidth;
    /**
     * When the rectangle's width or height is less than a pixel
     * and crisp mode is on, the rectangle will still fit into the pixel,
     * but will be less opaque to make an effect of holding less space.
     */
    this.microPixelEffectOpacity = 1;
  }
  set cornerRadius(cornerRadius) {
    this.topLeftCornerRadius = cornerRadius;
    this.topRightCornerRadius = cornerRadius;
    this.bottomRightCornerRadius = cornerRadius;
    this.bottomLeftCornerRadius = cornerRadius;
  }
  isDirtyPath() {
    var _a;
    if (this.lastUpdatePathStrokeWidth !== this.strokeWidth) {
      return true;
    }
    return !!(this.path.isDirty() || this.borderPath.isDirty() || ((_a = this.clipPath) == null ? void 0 : _a.isDirty()));
  }
  updatePath() {
    var _a, _b, _c;
    const {
      path,
      borderPath,
      crisp,
      topLeftCornerRadius,
      topRightCornerRadius,
      bottomRightCornerRadius,
      bottomLeftCornerRadius
    } = this;
    let { x, y, width: w, height: h, strokeWidth, cornerRadiusBbox } = this;
    const pixelRatio = (_b = (_a = this.layerManager) == null ? void 0 : _a.canvas.pixelRatio) != null ? _b : 1;
    const pixelSize = 1 / pixelRatio;
    let microPixelEffectOpacity = 1;
    path.clear({ trackChanges: true });
    borderPath.clear({ trackChanges: true });
    if (crisp) {
      if (w <= pixelSize) {
        microPixelEffectOpacity *= w / pixelSize;
      }
      if (h <= pixelSize) {
        microPixelEffectOpacity *= h / pixelSize;
      }
      w = this.align(x, w);
      h = this.align(y, h);
      x = this.align(x);
      y = this.align(y);
      cornerRadiusBbox = cornerRadiusBbox != null ? new BBox(
        this.align(cornerRadiusBbox.x),
        this.align(cornerRadiusBbox.y),
        this.align(cornerRadiusBbox.x, cornerRadiusBbox.width),
        this.align(cornerRadiusBbox.y, cornerRadiusBbox.height)
      ) : void 0;
    }
    if (strokeWidth) {
      if (w < pixelSize) {
        const lx = x + pixelSize / 2;
        borderPath.moveTo(lx, y);
        borderPath.lineTo(lx, y + h);
        strokeWidth = pixelSize;
        this.borderClipPath = void 0;
      } else if (h < pixelSize) {
        const ly = y + pixelSize / 2;
        borderPath.moveTo(x, ly);
        borderPath.lineTo(x + w, ly);
        strokeWidth = pixelSize;
        this.borderClipPath = void 0;
      } else if (strokeWidth < w && strokeWidth < h) {
        const halfStrokeWidth = strokeWidth / 2;
        x += halfStrokeWidth;
        y += halfStrokeWidth;
        w -= strokeWidth;
        h -= strokeWidth;
        const adjustedCornerRadiusBbox = cornerRadiusBbox == null ? void 0 : cornerRadiusBbox.clone().shrink(halfStrokeWidth);
        const cornerRadii = {
          topLeft: topLeftCornerRadius > 0 ? topLeftCornerRadius - strokeWidth : 0,
          topRight: topRightCornerRadius > 0 ? topRightCornerRadius - strokeWidth : 0,
          bottomRight: bottomRightCornerRadius > 0 ? bottomRightCornerRadius - strokeWidth : 0,
          bottomLeft: bottomLeftCornerRadius > 0 ? bottomLeftCornerRadius - strokeWidth : 0
        };
        this.borderClipPath = void 0;
        insetCornerRadiusRect(path, x, y, w, h, cornerRadii, adjustedCornerRadiusBbox);
        insetCornerRadiusRect(borderPath, x, y, w, h, cornerRadii, adjustedCornerRadiusBbox);
      } else {
        this.borderClipPath = (_c = this.borderClipPath) != null ? _c : new Path2D();
        this.borderClipPath.clear({ trackChanges: true });
        this.borderClipPath.rect(x, y, w, h);
        borderPath.rect(x, y, w, h);
      }
    } else {
      const cornerRadii = {
        topLeft: topLeftCornerRadius,
        topRight: topRightCornerRadius,
        bottomRight: bottomRightCornerRadius,
        bottomLeft: bottomLeftCornerRadius
      };
      this.borderClipPath = void 0;
      insetCornerRadiusRect(path, x, y, w, h, cornerRadii, cornerRadiusBbox);
    }
    this.effectiveStrokeWidth = strokeWidth;
    this.lastUpdatePathStrokeWidth = strokeWidth;
    this.microPixelEffectOpacity = microPixelEffectOpacity;
  }
  computeBBox() {
    const { x, y, width, height } = this;
    return new BBox(x, y, width, height);
  }
  isPointInPath(x, y) {
    const point = this.transformPoint(x, y);
    const bbox = this.computeBBox();
    return bbox.containsPoint(point.x, point.y);
  }
  applyFillAlpha(ctx) {
    const { fillOpacity, microPixelEffectOpacity, opacity } = this;
    const { globalAlpha } = ctx;
    ctx.globalAlpha = globalAlpha * opacity * fillOpacity * microPixelEffectOpacity;
  }
  renderStroke(ctx) {
    const { stroke, effectiveStrokeWidth, borderPath, borderClipPath, opacity, microPixelEffectOpacity } = this;
    const borderActive = !!stroke && !!effectiveStrokeWidth;
    if (borderActive) {
      const { strokeOpacity, lineDash, lineDashOffset, lineCap, lineJoin } = this;
      if (borderClipPath) {
        borderClipPath.draw(ctx);
        ctx.clip();
      }
      borderPath.draw(ctx);
      const { globalAlpha } = ctx;
      ctx.strokeStyle = stroke;
      ctx.globalAlpha = globalAlpha * opacity * strokeOpacity * microPixelEffectOpacity;
      ctx.lineWidth = effectiveStrokeWidth;
      if (lineDash) {
        ctx.setLineDash(lineDash);
      }
      if (lineDashOffset) {
        ctx.lineDashOffset = lineDashOffset;
      }
      if (lineCap) {
        ctx.lineCap = lineCap;
      }
      if (lineJoin) {
        ctx.lineJoin = lineJoin;
      }
      ctx.stroke();
      ctx.globalAlpha = globalAlpha;
    }
  }
};
Rect.className = "Rect";
__decorateClass([
  ScenePathChangeDetection()
], Rect.prototype, "x", 2);
__decorateClass([
  ScenePathChangeDetection()
], Rect.prototype, "y", 2);
__decorateClass([
  ScenePathChangeDetection()
], Rect.prototype, "width", 2);
__decorateClass([
  ScenePathChangeDetection()
], Rect.prototype, "height", 2);
__decorateClass([
  ScenePathChangeDetection()
], Rect.prototype, "topLeftCornerRadius", 2);
__decorateClass([
  ScenePathChangeDetection()
], Rect.prototype, "topRightCornerRadius", 2);
__decorateClass([
  ScenePathChangeDetection()
], Rect.prototype, "bottomRightCornerRadius", 2);
__decorateClass([
  ScenePathChangeDetection()
], Rect.prototype, "bottomLeftCornerRadius", 2);
__decorateClass([
  ScenePathChangeDetection()
], Rect.prototype, "cornerRadiusBbox", 2);
__decorateClass([
  ScenePathChangeDetection()
], Rect.prototype, "crisp", 2);

// packages/ag-charts-community/src/util/sector.ts
function isPointInSector(x, y, sector) {
  const radius = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
  const { innerRadius, outerRadius } = sector;
  if (sector.startAngle === sector.endAngle || radius < Math.min(innerRadius, outerRadius) || radius > Math.max(innerRadius, outerRadius)) {
    return false;
  }
  const startAngle = normalizeAngle180(sector.startAngle);
  const endAngle = normalizeAngle180(sector.endAngle);
  const angle = Math.atan2(y, x);
  return startAngle < endAngle ? angle <= endAngle && angle >= startAngle : angle <= endAngle && angle >= -Math.PI || angle >= startAngle && angle <= Math.PI;
}
function lineCollidesSector(line, sector) {
  const { startAngle, endAngle, innerRadius, outerRadius } = sector;
  const outerStart = { x: outerRadius * Math.cos(startAngle), y: outerRadius * Math.sin(startAngle) };
  const outerEnd = { x: outerRadius * Math.cos(endAngle), y: outerRadius * Math.sin(endAngle) };
  const innerStart = innerRadius === 0 ? { x: 0, y: 0 } : { x: innerRadius * Math.cos(startAngle), y: innerRadius * Math.sin(startAngle) };
  const innerEnd = innerRadius === 0 ? { x: 0, y: 0 } : { x: innerRadius * Math.cos(endAngle), y: innerRadius * Math.sin(endAngle) };
  return segmentIntersection(
    line.start.x,
    line.start.y,
    line.end.x,
    line.end.y,
    outerStart.x,
    outerStart.y,
    innerStart.x,
    innerStart.y
  ) != null || segmentIntersection(
    line.start.x,
    line.start.y,
    line.end.x,
    line.end.y,
    outerEnd.x,
    outerEnd.y,
    innerEnd.x,
    innerEnd.y
  ) != null || arcIntersections(
    0,
    0,
    outerRadius,
    startAngle,
    endAngle,
    true,
    line.start.x,
    line.start.y,
    line.end.x,
    line.end.y
  ).length > 0;
}
function boxCollidesSector(box, sector) {
  const topLeft = { x: box.x, y: box.y };
  const topRight = { x: box.x + box.width, y: box.y };
  const bottomLeft = { x: box.x, y: box.y + box.height };
  const bottomRight = { x: box.x + box.width, y: box.y + box.height };
  return lineCollidesSector({ start: topLeft, end: topRight }, sector) || lineCollidesSector({ start: bottomLeft, end: bottomRight }, sector);
}

// packages/ag-charts-community/src/scene/shape/sector.ts
var Sector = class extends Path {
  constructor() {
    super(...arguments);
    this.centerX = 0;
    this.centerY = 0;
    this.innerRadius = 10;
    this.outerRadius = 20;
    this.startAngle = 0;
    this.endAngle = Math.PI * 2;
    this.angleOffset = 0;
    this.inset = 0;
  }
  computeBBox() {
    const radius = this.outerRadius;
    return new BBox(this.centerX - radius, this.centerY - radius, radius * 2, radius * 2);
  }
  updatePath() {
    const path = this.path;
    const { angleOffset, inset } = this;
    const startAngle = this.startAngle + angleOffset;
    const endAngle = this.endAngle + angleOffset;
    const sweep = startAngle <= endAngle ? endAngle - startAngle : Math.PI * 2 - (startAngle - endAngle);
    const innerRadius = Math.max(Math.min(this.innerRadius, this.outerRadius) + inset, 0);
    const outerRadius = Math.max(Math.max(this.innerRadius, this.outerRadius) - inset, 0);
    const fullPie = sweep >= 2 * Math.PI;
    const centerX = this.centerX;
    const centerY = this.centerY;
    path.clear();
    if (fullPie) {
      path.arc(centerX, centerY, outerRadius, startAngle, endAngle);
      if (innerRadius > inset) {
        path.moveTo(centerX + innerRadius * Math.cos(endAngle), centerY + innerRadius * Math.sin(endAngle));
        path.arc(centerX, centerY, innerRadius, endAngle, startAngle, true);
      }
    } else {
      const innerAngleOffset = innerRadius > 0 ? inset / innerRadius : 0;
      const outerAngleOffset = outerRadius > 0 ? inset / outerRadius : 0;
      const outerAngleExceeded = sweep < 2 * outerAngleOffset;
      if (outerAngleExceeded)
        return;
      const innerAngleExceeded = innerRadius <= inset || sweep < 2 * innerAngleOffset;
      if (innerAngleExceeded) {
        const x = sweep < Math.PI * 0.5 ? inset * (1 + Math.cos(sweep)) / Math.sin(sweep) : NaN;
        let r;
        if (x > 0 && x < outerRadius) {
          r = Math.max(Math.hypot(inset, x), innerRadius);
        } else {
          r = innerRadius;
        }
        const midAngle = startAngle + sweep * 0.5;
        path.moveTo(centerX + r * Math.cos(midAngle), centerY + r * Math.sin(midAngle));
      } else {
        path.moveTo(
          centerX + innerRadius * Math.cos(startAngle + innerAngleOffset),
          centerY + innerRadius * Math.sin(startAngle + innerAngleOffset)
        );
      }
      path.arc(centerX, centerY, outerRadius, startAngle + outerAngleOffset, endAngle - outerAngleOffset);
      if (innerAngleExceeded) ; else if (innerRadius > 0) {
        path.arc(
          centerX,
          centerY,
          innerRadius,
          endAngle - innerAngleOffset,
          startAngle + innerAngleOffset,
          true
        );
      } else {
        path.lineTo(centerX, centerY);
      }
    }
    path.closePath();
    this.dirtyPath = false;
  }
  isPointInPath(x, y) {
    const { angleOffset } = this;
    const startAngle = this.startAngle + angleOffset;
    const endAngle = this.endAngle + angleOffset;
    const innerRadius = Math.min(this.innerRadius, this.outerRadius);
    const outerRadius = Math.max(this.innerRadius, this.outerRadius);
    const point = this.transformPoint(x, y);
    return isPointInSector(point.x, point.y, { startAngle, endAngle, innerRadius, outerRadius });
  }
};
Sector.className = "Sector";
__decorateClass([
  ScenePathChangeDetection()
], Sector.prototype, "centerX", 2);
__decorateClass([
  ScenePathChangeDetection()
], Sector.prototype, "centerY", 2);
__decorateClass([
  ScenePathChangeDetection()
], Sector.prototype, "innerRadius", 2);
__decorateClass([
  ScenePathChangeDetection()
], Sector.prototype, "outerRadius", 2);
__decorateClass([
  ScenePathChangeDetection()
], Sector.prototype, "startAngle", 2);
__decorateClass([
  ScenePathChangeDetection()
], Sector.prototype, "endAngle", 2);
__decorateClass([
  ScenePathChangeDetection()
], Sector.prototype, "angleOffset", 2);
__decorateClass([
  ScenePathChangeDetection()
], Sector.prototype, "inset", 2);

// packages/ag-charts-community/src/util/numberFormat.ts
var group = (content) => `(${content})`;
var optionalGroup = (content) => `${group(content)}?`;
var nonCapturingGroup = (content) => optionalGroup(`?:${content}`);
var formatRegEx = (() => {
  const fill = ".";
  const align = "[<>=^]";
  const sign = "[+\\-( ]";
  const symbol = "[$\u20AC\xA3\xA5\u20A3\u20B9#]";
  const zero = "0";
  const width = "\\d+";
  const comma = ",";
  const precision = "\\d+";
  const tilde = "~";
  const type = "[%a-z]";
  return new RegExp(
    [
      "^",
      nonCapturingGroup(`${optionalGroup(fill)}${group(align)}`),
      optionalGroup(sign),
      optionalGroup(symbol),
      optionalGroup(zero),
      optionalGroup(width),
      optionalGroup(comma),
      nonCapturingGroup(`\\.${group(precision)}`),
      optionalGroup(tilde),
      optionalGroup(type),
      "$"
    ].join(""),
    "i"
  );
})();
var surroundedRegEx = (() => {
  const prefix = ".*?";
  const content = ".+?";
  const suffix = ".*?";
  return new RegExp(["^", group(prefix), `#\\{${group(content)}\\}`, group(suffix), "$"].join(""));
})();
function parseFormatter(formatter) {
  let prefix;
  let suffix;
  const surrounded = surroundedRegEx.exec(formatter);
  if (surrounded) {
    [, prefix, formatter, suffix] = surrounded;
  }
  const match = formatRegEx.exec(formatter);
  if (!match) {
    throw new Error(`The number formatter is invalid: ${formatter}`);
  }
  const [, fill, align, sign, symbol, zero, width, comma, precision, trim, type] = match;
  return {
    fill,
    align,
    sign,
    symbol,
    zero,
    width: parseInt(width),
    comma,
    precision: parseInt(precision),
    trim: Boolean(trim),
    type,
    prefix,
    suffix
  };
}
function format(formatter) {
  const options = typeof formatter === "string" ? parseFormatter(formatter) : formatter;
  const { fill, align, sign = "-", symbol, zero, width, comma, type, prefix = "", suffix = "", precision } = options;
  let { trim } = options;
  const precisionIsNaN = precision === void 0 || isNaN(precision);
  let formatBody;
  if (!type) {
    formatBody = decimalTypes["g"];
    trim = true;
  } else if (type in decimalTypes && type in integerTypes) {
    formatBody = precisionIsNaN ? integerTypes[type] : decimalTypes[type];
  } else if (type in decimalTypes) {
    formatBody = decimalTypes[type];
  } else if (type in integerTypes) {
    formatBody = integerTypes[type];
  } else {
    throw new Error(`The number formatter type is invalid: ${type}`);
  }
  let formatterPrecision;
  if (precision == null || precisionIsNaN) {
    formatterPrecision = type ? 6 : 12;
  } else {
    formatterPrecision = precision;
  }
  return (n) => {
    let result = formatBody(n, formatterPrecision);
    if (trim) {
      result = removeTrailingZeros(result);
    }
    if (comma) {
      result = insertSeparator(result, comma);
    }
    result = addSign(n, result, sign);
    if (symbol && symbol !== "#") {
      result = `${symbol}${result}`;
    }
    if (symbol === "#" && type === "x") {
      result = `0x${result}`;
    }
    if (type === "s") {
      result = `${result}${getSIPrefix(n)}`;
    }
    if (type === "%" || type === "p") {
      result = `${result}%`;
    }
    if (width != null && !isNaN(width)) {
      result = addPadding(result, width, fill != null ? fill : zero, align);
    }
    result = `${prefix}${result}${suffix}`;
    return result;
  };
}
var absFloor = (n) => Math.floor(Math.abs(n));
var integerTypes = {
  b: (n) => absFloor(n).toString(2),
  c: (n) => String.fromCharCode(n),
  d: (n) => Math.round(Math.abs(n)).toFixed(0),
  o: (n) => absFloor(n).toString(8),
  x: (n) => absFloor(n).toString(16),
  X: (n) => integerTypes.x(n).toUpperCase(),
  n: (n) => integerTypes.d(n),
  "%": (n) => `${absFloor(n * 100).toFixed(0)}`
};
var decimalTypes = {
  e: (n, f) => Math.abs(n).toExponential(f),
  E: (n, f) => decimalTypes.e(n, f).toUpperCase(),
  f: (n, f) => Math.abs(n).toFixed(f),
  F: (n, f) => decimalTypes.f(n, f).toUpperCase(),
  g: (n, f) => {
    if (n === 0) {
      return "0";
    }
    const a = Math.abs(n);
    const p = Math.floor(Math.log10(a));
    if (p >= -4 && p < f) {
      return a.toFixed(f - 1 - p);
    }
    return a.toExponential(f - 1);
  },
  G: (n, f) => decimalTypes.g(n, f).toUpperCase(),
  n: (n, f) => decimalTypes.g(n, f),
  p: (n, f) => decimalTypes.r(n * 100, f),
  r: (n, f) => {
    if (n === 0) {
      return "0";
    }
    const a = Math.abs(n);
    const p = Math.floor(Math.log10(a));
    const q = p - (f - 1);
    if (q <= 0) {
      return a.toFixed(-q);
    }
    const x = Math.pow(10, q);
    return (Math.round(a / x) * x).toFixed();
  },
  s: (n, f) => {
    const p = getSIPrefixPower(n);
    return decimalTypes.r(n / Math.pow(10, p), f);
  },
  "%": (n, f) => decimalTypes.f(n * 100, f)
};
function removeTrailingZeros(numString) {
  return numString.replace(/\.0+$/, "").replace(/(\.[1-9])0+$/, "$1");
}
function insertSeparator(numString, separator) {
  let dotIndex = numString.indexOf(".");
  if (dotIndex < 0) {
    dotIndex = numString.length;
  }
  const integerChars = numString.substring(0, dotIndex).split("");
  const fractionalPart = numString.substring(dotIndex);
  for (let i = integerChars.length - 3; i > 0; i -= 3) {
    integerChars.splice(i, 0, separator);
  }
  return `${integerChars.join("")}${fractionalPart}`;
}
function getSIPrefix(n) {
  return siPrefixes[getSIPrefixPower(n)];
}
function getSIPrefixPower(n) {
  const power = Math.log10(Math.abs(n));
  const p = Math.floor(power / 3) * 3;
  return Math.max(minSIPrefix, Math.min(maxSIPrefix, p));
}
var minSIPrefix = -24;
var maxSIPrefix = 24;
var siPrefixes = {
  [minSIPrefix]: "y",
  [-21]: "z",
  [-18]: "a",
  [-15]: "f",
  [-12]: "p",
  [-9]: "n",
  [-6]: "\xB5",
  [-3]: "m",
  [0]: "",
  [3]: "k",
  [6]: "M",
  [9]: "G",
  [12]: "T",
  [15]: "P",
  [18]: "E",
  [21]: "Z",
  [maxSIPrefix]: "Y"
};
var minusSign = "\u2212";
function addSign(num, numString, signType = "") {
  if (signType === "(") {
    return num >= 0 ? numString : `(${numString})`;
  }
  const plusSign = signType === "+" ? "+" : "";
  return `${num >= 0 ? plusSign : minusSign}${numString}`;
}
function addPadding(numString, width, fill = " ", align = ">") {
  let result = numString;
  if (align === ">" || !align) {
    result = result.padStart(width, fill);
  } else if (align === "<") {
    result = result.padEnd(width, fill);
  } else if (align === "^") {
    const padWidth = Math.max(0, width - result.length);
    const padLeft = Math.ceil(padWidth / 2);
    const padRight = Math.floor(padWidth / 2);
    result = result.padStart(padLeft + result.length, fill);
    result = result.padEnd(padRight + result.length, fill);
  }
  return result;
}
function tickFormat(ticks, formatter) {
  const options = parseFormatter(formatter != null ? formatter : ",f");
  const { precision } = options;
  if (precision == null || isNaN(precision)) {
    if (options.type === "f" || options.type === "%") {
      options.precision = Math.max(
        ...ticks.map((x) => {
          if (typeof x !== "number" || x === 0) {
            return 0;
          }
          const l = Math.floor(Math.log10(Math.abs(x)));
          const digits = options.type ? 6 : 12;
          const exp = x.toExponential(digits - 1).replace(/\.?0+e/, "e");
          const dotIndex = exp.indexOf(".");
          if (dotIndex < 0) {
            return l >= 0 ? 0 : -l;
          }
          const s = exp.indexOf("e") - dotIndex;
          return Math.max(0, s - l - 1);
        })
      );
    } else if (!options.type || options.type in decimalTypes) {
      options.precision = Math.max(
        ...ticks.map((x) => {
          if (typeof x !== "number") {
            return 0;
          }
          const exp = x.toExponential((options.type ? 6 : 12) - 1).replace(/\.?0+e/, "e");
          return exp.substring(0, exp.indexOf("e")).replace(".", "").length;
        })
      );
    }
  }
  const f = format(options);
  return (n) => f(Number(n));
}

// packages/ag-charts-community/src/util/ticks.ts
var createNumericTicks = (fractionDigits, takingValues = []) => Object.assign(takingValues, { fractionDigits });
function ticks_default(start, stop, count2, minCount, maxCount) {
  if (count2 < 2) {
    return range(start, stop, stop - start);
  }
  const step = tickStep(start, stop, count2, minCount, maxCount);
  if (isNaN(step)) {
    return createNumericTicks(0);
  }
  start = Math.ceil(start / step) * step;
  stop = Math.floor(stop / step) * step;
  return range(start, stop, step);
}
var tickMultipliers = [1, 2, 5, 10];
function tickStep(a, b, count2, minCount = 0, maxCount = Infinity) {
  const extent2 = Math.abs(b - a);
  const rawStep = extent2 / count2;
  const power = Math.floor(Math.log10(rawStep));
  const step = Math.pow(10, power);
  const m = tickMultipliers.map((multiplier) => {
    const s = multiplier * step;
    const c = Math.ceil(extent2 / s);
    const isWithinBounds = c >= minCount && c <= maxCount;
    const diffCount = Math.abs(c - count2);
    return { multiplier, isWithinBounds, diffCount };
  }).sort((a2, b2) => {
    if (a2.isWithinBounds !== b2.isWithinBounds) {
      return a2.isWithinBounds ? -1 : 1;
    }
    return a2.diffCount - b2.diffCount;
  })[0].multiplier;
  if (!m || isNaN(m)) {
    return NaN;
  }
  return m * step;
}
function singleTickDomain(a, b) {
  const extent2 = Math.abs(b - a);
  const power = Math.floor(Math.log10(extent2));
  const step = Math.pow(10, power);
  const roundStart = a > b ? Math.ceil : Math.floor;
  const roundStop = b < a ? Math.floor : Math.ceil;
  return tickMultipliers.map((multiplier) => {
    const s = multiplier * step;
    const start = roundStart(a / s) * s;
    const end = roundStop(b / s) * s;
    const error = 1 - extent2 / Math.abs(end - start);
    const domain = [start, end];
    return { error, domain };
  }).sort((a2, b2) => a2.error - b2.error)[0].domain;
}
function range(start, stop, step) {
  const d0 = Math.min(start, stop);
  const d1 = Math.max(start, stop);
  const fractionalDigits = countFractionDigits(step);
  const f = Math.pow(10, fractionalDigits);
  const n = Math.ceil((d1 - d0) / step);
  const values = createNumericTicks(fractionalDigits);
  for (let i = 0; i <= n; i++) {
    const value = d0 + step * i;
    values.push(Math.round(value * f) / f);
  }
  return values;
}

// packages/ag-charts-community/src/scale/linearScale.ts
var LinearScale$1 = class extends ContinuousScale {
  constructor() {
    super([0, 1], [0, 1]);
    this.type = "linear";
  }
  toDomain(d) {
    return d;
  }
  ticks() {
    var _a;
    const count2 = (_a = this.tickCount) != null ? _a : ContinuousScale.defaultTickCount;
    if (!this.domain || this.domain.length < 2 || count2 < 1 || this.domain.some((d) => !isFinite(d))) {
      return [];
    }
    this.refresh();
    const [d0, d1] = this.getDomain();
    const { interval } = this;
    if (interval) {
      const step = Math.abs(interval);
      if (!this.isDenseInterval({ start: d0, stop: d1, interval: step })) {
        return range(d0, d1, step);
      }
    }
    return ticks_default(d0, d1, count2, this.minTickCount, this.maxTickCount);
  }
  update() {
    if (!this.domain || this.domain.length < 2) {
      return;
    }
    if (this.nice) {
      this.updateNiceDomain();
    }
  }
  getTickStep(start, stop) {
    var _a, _b;
    const count2 = (_a = this.tickCount) != null ? _a : ContinuousScale.defaultTickCount;
    return (_b = this.interval) != null ? _b : tickStep(start, stop, count2, this.minTickCount, this.maxTickCount);
  }
  /**
   * Extends the domain so that it starts and ends on nice round values.
   */
  updateNiceDomain() {
    var _a;
    const count2 = (_a = this.tickCount) != null ? _a : ContinuousScale.defaultTickCount;
    if (count2 < 1) {
      this.niceDomain = [...this.domain];
      return;
    }
    let [start, stop] = this.domain;
    if (count2 === 1) {
      [start, stop] = singleTickDomain(start, stop);
    } else {
      const roundStart = start > stop ? Math.ceil : Math.floor;
      const roundStop = stop < start ? Math.floor : Math.ceil;
      const maxAttempts = 4;
      for (let i = 0; i < maxAttempts; i++) {
        const prev0 = start;
        const prev1 = stop;
        const step = this.getTickStep(start, stop);
        const [d0, d1] = this.domain;
        if (step >= 1) {
          start = roundStart(d0 / step) * step;
          stop = roundStop(d1 / step) * step;
        } else {
          const s = 1 / step;
          start = roundStart(d0 * s) / s;
          stop = roundStop(d1 * s) / s;
        }
        if (start === prev0 && stop === prev1) {
          break;
        }
      }
    }
    this.niceDomain = [start, stop];
  }
  tickFormat({ ticks, specifier }) {
    return tickFormat(ticks != null ? ticks : this.ticks(), specifier);
  }
};

// packages/ag-charts-community/src/chart/marker/marker.ts
var Marker = class extends Path {
  constructor() {
    super(...arguments);
    this.x = 0;
    this.y = 0;
    this.size = 12;
  }
  computeBBox() {
    const { x, y, size } = this;
    const half = size / 2;
    return new BBox(x - half, y - half, size, size);
  }
  applyPath(s, moves) {
    const { path } = this;
    let { x, y } = this;
    path.clear();
    for (const { x: mx, y: my, t } of moves) {
      x += mx * s;
      y += my * s;
      if (t === "move") {
        path.moveTo(x, y);
      } else {
        path.lineTo(x, y);
      }
    }
    path.closePath();
  }
};
__decorateClass([
  ScenePathChangeDetection()
], Marker.prototype, "x", 2);
__decorateClass([
  ScenePathChangeDetection()
], Marker.prototype, "y", 2);
__decorateClass([
  ScenePathChangeDetection({ convertor: Math.abs })
], Marker.prototype, "size", 2);

// packages/ag-charts-community/src/chart/marker/circle.ts
var Circle = class extends Marker {
  updatePath() {
    const { x, y, path, size } = this;
    const r = size / 2;
    path.clear();
    path.arc(x, y, r, 0, Math.PI * 2);
    path.closePath();
  }
};
Circle.className = "Circle";

// packages/ag-charts-community/src/chart/marker/cross.ts
var _Cross = class _Cross extends Marker {
  updatePath() {
    const s = this.size / 4.2;
    super.applyPath(s, _Cross.moves);
  }
};
_Cross.className = "Cross";
_Cross.moves = [
  { x: -1, y: 0, t: "move" },
  { x: -1, y: -1 },
  { x: 1, y: -1 },
  { x: 1, y: 1 },
  { x: 1, y: -1 },
  { x: 1, y: 1 },
  { x: -1, y: 1 },
  { x: 1, y: 1 },
  { x: -1, y: 1 },
  { x: -1, y: -1 },
  { x: -1, y: 1 },
  { x: -1, y: -1 }
];
var Cross = _Cross;

// packages/ag-charts-community/src/chart/marker/diamond.ts
var _Diamond = class _Diamond extends Marker {
  updatePath() {
    const s = this.size / 2;
    super.applyPath(s, _Diamond.moves);
  }
};
_Diamond.className = "Diamond";
_Diamond.moves = [
  { x: 0, y: -1, t: "move" },
  { x: 1, y: 1 },
  { x: -1, y: 1 },
  { x: -1, y: -1 },
  { x: 1, y: -1 }
];
var Diamond = _Diamond;

// packages/ag-charts-community/src/chart/marker/heart.ts
var Heart = class extends Marker {
  rad(degree) {
    return degree / 180 * Math.PI;
  }
  updatePath() {
    const { x, path, size, rad } = this;
    const r = size / 4;
    const y = this.y + r / 2;
    path.clear();
    path.arc(x - r, y - r, r, rad(130), rad(330));
    path.arc(x + r, y - r, r, rad(220), rad(50));
    path.lineTo(x, y + r);
    path.closePath();
  }
};
Heart.className = "Heart";

// packages/ag-charts-community/src/chart/marker/plus.ts
var _Plus = class _Plus extends Marker {
  updatePath() {
    const s = this.size / 3;
    super.applyPath(s, _Plus.moves);
  }
};
_Plus.className = "Plus";
_Plus.moves = [
  { x: -0.5, y: -0.5, t: "move" },
  { x: 0, y: -1 },
  { x: 1, y: 0 },
  { x: 0, y: 1 },
  { x: 1, y: 0 },
  { x: 0, y: 1 },
  { x: -1, y: 0 },
  { x: 0, y: 1 },
  { x: -1, y: 0 },
  { x: 0, y: -1 },
  { x: -1, y: 0 },
  { x: 0, y: -1 }
];
var Plus = _Plus;

// packages/ag-charts-community/src/chart/marker/square.ts
var Square = class extends Marker {
  updatePath() {
    const { path, x, y } = this;
    const hs = this.size / 2;
    path.clear();
    path.moveTo(this.align(x - hs), this.align(y - hs));
    path.lineTo(this.align(x + hs), this.align(y - hs));
    path.lineTo(this.align(x + hs), this.align(y + hs));
    path.lineTo(this.align(x - hs), this.align(y + hs));
    path.closePath();
  }
};
Square.className = "Square";

// packages/ag-charts-community/src/chart/marker/triangle.ts
var _Triangle = class _Triangle extends Marker {
  updatePath() {
    const s = this.size * 1.1;
    super.applyPath(s, _Triangle.moves);
  }
};
_Triangle.className = "Triangle";
_Triangle.moves = [
  { x: 0, y: -0.48, t: "move" },
  { x: 0.5, y: 0.87 },
  { x: -1, y: 0 }
];
var Triangle = _Triangle;

// packages/ag-charts-community/src/chart/marker/util.ts
var MARKER_SHAPES = {
  circle: Circle,
  cross: Cross,
  diamond: Diamond,
  heart: Heart,
  plus: Plus,
  square: Square,
  triangle: Triangle
};
var MARKER_SUPPORTED_SHAPES = Object.keys(MARKER_SHAPES);
function isMarkerShape(shape) {
  return typeof shape === "string" && MARKER_SUPPORTED_SHAPES.includes(shape);
}
function getMarker$1(shape = Square) {
  if (isMarkerShape(shape)) {
    return MARKER_SHAPES[shape];
  }
  if (typeof shape === "function") {
    return shape;
  }
  return Square;
}

// packages/ag-charts-community/src/scene/image.ts
var Image = class extends Node {
  constructor(sourceImage) {
    super();
    this.x = 0;
    this.y = 0;
    this.width = 0;
    this.height = 0;
    this.opacity = 1;
    this.sourceImage = sourceImage;
  }
  render(renderCtx) {
    const { ctx, forceRender, stats } = renderCtx;
    if (this.dirty === 0 /* NONE */ && !forceRender) {
      if (stats)
        stats.nodesSkipped++;
      return;
    }
    this.computeTransformMatrix();
    this.matrix.toContext(ctx);
    const image = this.sourceImage;
    ctx.globalAlpha = this.opacity;
    ctx.drawImage(image, 0, 0, image.width, image.height, this.x, this.y, this.width, this.height);
    super.render(renderCtx);
  }
};
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], Image.prototype, "x", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], Image.prototype, "y", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], Image.prototype, "width", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], Image.prototype, "height", 2);
__decorateClass([
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], Image.prototype, "opacity", 2);

// packages/ag-charts-community/src/integrated-charts-scene.ts
var motion = __spreadValues(__spreadValues({}, fromToMotion_exports), resetMotion_exports);

// packages/ag-charts-community/src/util/value.ts
var isString2 = (v) => typeof v === "string";
var isStringObject$1 = (v) => !!v && Object.hasOwn(v, "toString") && isString2(v.toString());
var isDate2 = (v) => v instanceof Date && !isNaN(+v);
function isDiscrete(value) {
  return isString2(value) || isStringObject$1(value);
}
function isContinuous(value) {
  const isNumberObject = (v) => !!v && Object.hasOwn(v, "valueOf") && isNumber2(v.valueOf());
  const isDate3 = (v) => v instanceof Date && !isNaN(+v);
  return isNumber2(value) || isNumberObject(value) || isDate3(value);
}
function checkDatum(value, isContinuousScale) {
  if (isContinuousScale && isContinuous(value)) {
    return value;
  } else if (!isContinuousScale) {
    if (!isDiscrete(value)) {
      return String(value);
    }
    return value;
  }
  return void 0;
}
var isNumber2 = (v) => typeof v === "number" && Number.isFinite(v);

// packages/ag-charts-community/src/chart/crossline/crossLine.ts
var MATCHING_CROSSLINE_TYPE = (property) => {
  return property === "value" ? predicateWithMessage(
    (_, ctx) => ctx.target["type"] === "line",
    (ctx) => ctx.target["type"] === "range" ? `crossLine type 'range' to have a 'range' property instead of 'value'` : `crossLine property 'type' to be 'line'`
  ) : predicateWithMessage(
    (_, ctx) => ctx.target["type"] === "range",
    (ctx) => ctx.target.type === "line" ? `crossLine type 'line' to have a 'value' property instead of 'range'` : `crossLine property 'type' to be 'range'`
  );
};
var validateCrossLineValues = (type, value, range3, scale2) => {
  const lineCrossLine = type === "line" && value !== void 0;
  const rangeCrossLine = type === "range" && range3 !== void 0;
  if (!lineCrossLine && !rangeCrossLine) {
    return true;
  }
  const [start, end] = range3 != null ? range3 : [value, void 0];
  const isContinuous2 = ContinuousScale.is(scale2);
  const validStart = checkDatum(start, isContinuous2) != null && !isNaN(scale2.convert(start));
  const validEnd = checkDatum(end, isContinuous2) != null && !isNaN(scale2.convert(end));
  if (lineCrossLine && validStart || rangeCrossLine && validStart && validEnd) {
    return true;
  }
  const message = [`Expecting crossLine`];
  if (rangeCrossLine) {
    if (!validStart) {
      message.push(`range start ${stringify(start)}`);
    }
    if (!validEnd) {
      message.push(`${!validStart ? "and " : ""}range end ${stringify(end)}`);
    }
  } else {
    message.push(`value ${stringify(start)}`);
  }
  message.push(`to match the axis scale domain.`);
  Logger.warnOnce(message.join(" "));
  return false;
};

// packages/ag-charts-community/src/chart/crossline/crossLineLabelPosition.ts
var horizontalCrosslineTranslationDirections = {
  top: { xTranslationDirection: 0, yTranslationDirection: -1 },
  bottom: { xTranslationDirection: 0, yTranslationDirection: 1 },
  left: { xTranslationDirection: -1, yTranslationDirection: 0 },
  right: { xTranslationDirection: 1, yTranslationDirection: 0 },
  topLeft: { xTranslationDirection: 1, yTranslationDirection: -1 },
  topRight: { xTranslationDirection: -1, yTranslationDirection: -1 },
  bottomLeft: { xTranslationDirection: 1, yTranslationDirection: 1 },
  bottomRight: { xTranslationDirection: -1, yTranslationDirection: 1 },
  inside: { xTranslationDirection: 0, yTranslationDirection: 0 },
  insideLeft: { xTranslationDirection: 1, yTranslationDirection: 0 },
  insideRight: { xTranslationDirection: -1, yTranslationDirection: 0 },
  insideTop: { xTranslationDirection: 0, yTranslationDirection: 1 },
  insideBottom: { xTranslationDirection: 0, yTranslationDirection: -1 },
  insideTopLeft: { xTranslationDirection: 1, yTranslationDirection: 1 },
  insideBottomLeft: { xTranslationDirection: 1, yTranslationDirection: -1 },
  insideTopRight: { xTranslationDirection: -1, yTranslationDirection: 1 },
  insideBottomRight: { xTranslationDirection: -1, yTranslationDirection: -1 }
};
var verticalCrossLineTranslationDirections = {
  top: { xTranslationDirection: 1, yTranslationDirection: 0 },
  bottom: { xTranslationDirection: -1, yTranslationDirection: 0 },
  left: { xTranslationDirection: 0, yTranslationDirection: -1 },
  right: { xTranslationDirection: 0, yTranslationDirection: 1 },
  topLeft: { xTranslationDirection: -1, yTranslationDirection: -1 },
  topRight: { xTranslationDirection: -1, yTranslationDirection: 1 },
  bottomLeft: { xTranslationDirection: 1, yTranslationDirection: -1 },
  bottomRight: { xTranslationDirection: 1, yTranslationDirection: 1 },
  inside: { xTranslationDirection: 0, yTranslationDirection: 0 },
  insideLeft: { xTranslationDirection: 0, yTranslationDirection: 1 },
  insideRight: { xTranslationDirection: 0, yTranslationDirection: -1 },
  insideTop: { xTranslationDirection: -1, yTranslationDirection: 0 },
  insideBottom: { xTranslationDirection: 1, yTranslationDirection: 0 },
  insideTopLeft: { xTranslationDirection: -1, yTranslationDirection: 1 },
  insideBottomLeft: { xTranslationDirection: 1, yTranslationDirection: 1 },
  insideTopRight: { xTranslationDirection: -1, yTranslationDirection: -1 },
  insideBottomRight: { xTranslationDirection: 1, yTranslationDirection: -1 }
};
function calculateLabelTranslation({
  yDirection,
  padding = 0,
  position = "top",
  bbox
}) {
  const crossLineTranslationDirections = yDirection ? horizontalCrosslineTranslationDirections : verticalCrossLineTranslationDirections;
  const { xTranslationDirection, yTranslationDirection } = crossLineTranslationDirections[position];
  const w = yDirection ? bbox.width : bbox.height;
  const h = yDirection ? bbox.height : bbox.width;
  const xTranslation = xTranslationDirection * (padding + w / 2);
  const yTranslation = yTranslationDirection * (padding + h / 2);
  return {
    xTranslation,
    yTranslation
  };
}
function calculateLabelChartPadding({
  yDirection,
  bbox,
  padding = 0,
  position = "top"
}) {
  const chartPadding = {};
  if (position.startsWith("inside"))
    return chartPadding;
  if (position === "top" && !yDirection) {
    chartPadding.top = padding + bbox.height;
  } else if (position === "bottom" && !yDirection) {
    chartPadding.bottom = padding + bbox.height;
  } else if (position === "left" && yDirection) {
    chartPadding.left = padding + bbox.width;
  } else if (position === "right" && yDirection) {
    chartPadding.right = padding + bbox.width;
  }
  return chartPadding;
}
var POSITION_TOP_COORDINATES = ({ yDirection, xEnd, yStart, yEnd }) => {
  if (yDirection) {
    return { x: xEnd / 2, y: yStart };
  } else {
    return { x: xEnd, y: !isNaN(yEnd) ? (yStart + yEnd) / 2 : yStart };
  }
};
var POSITION_LEFT_COORDINATES = ({ yDirection, xStart, xEnd, yStart, yEnd }) => {
  if (yDirection) {
    return { x: xStart, y: !isNaN(yEnd) ? (yStart + yEnd) / 2 : yStart };
  } else {
    return { x: xEnd / 2, y: yStart };
  }
};
var POSITION_RIGHT_COORDINATES = ({ yDirection, xEnd, yStart, yEnd }) => {
  if (yDirection) {
    return { x: xEnd, y: !isNaN(yEnd) ? (yStart + yEnd) / 2 : yStart };
  } else {
    return { x: xEnd / 2, y: !isNaN(yEnd) ? yEnd : yStart };
  }
};
var POSITION_BOTTOM_COORDINATES = ({ yDirection, xStart, xEnd, yStart, yEnd }) => {
  if (yDirection) {
    return { x: xEnd / 2, y: !isNaN(yEnd) ? yEnd : yStart };
  } else {
    return { x: xStart, y: !isNaN(yEnd) ? (yStart + yEnd) / 2 : yStart };
  }
};
var POSITION_INSIDE_COORDINATES = ({ xEnd, yStart, yEnd }) => {
  return { x: xEnd / 2, y: !isNaN(yEnd) ? (yStart + yEnd) / 2 : yStart };
};
var POSITION_TOP_LEFT_COORDINATES = ({ yDirection, xStart, xEnd, yStart }) => {
  if (yDirection) {
    return { x: xStart / 2, y: yStart };
  } else {
    return { x: xEnd, y: yStart };
  }
};
var POSITION_BOTTOM_LEFT_COORDINATES = ({ yDirection, xStart, yStart, yEnd }) => {
  if (yDirection) {
    return { x: xStart, y: !isNaN(yEnd) ? yEnd : yStart };
  } else {
    return { x: xStart, y: yStart };
  }
};
var POSITION_TOP_RIGHT_COORDINATES = ({ yDirection, xEnd, yStart, yEnd }) => {
  if (yDirection) {
    return { x: xEnd, y: yStart };
  } else {
    return { x: xEnd, y: !isNaN(yEnd) ? yEnd : yStart };
  }
};
var POSITION_BOTTOM_RIGHT_COORDINATES = ({ yDirection, xStart, xEnd, yStart, yEnd }) => {
  if (yDirection) {
    return { x: xEnd, y: !isNaN(yEnd) ? yEnd : yStart };
  } else {
    return { x: xStart, y: !isNaN(yEnd) ? yEnd : yStart };
  }
};
var labelDirectionHandling = {
  top: { c: POSITION_TOP_COORDINATES },
  bottom: { c: POSITION_BOTTOM_COORDINATES },
  left: { c: POSITION_LEFT_COORDINATES },
  right: { c: POSITION_RIGHT_COORDINATES },
  topLeft: { c: POSITION_TOP_LEFT_COORDINATES },
  topRight: { c: POSITION_TOP_RIGHT_COORDINATES },
  bottomLeft: { c: POSITION_BOTTOM_LEFT_COORDINATES },
  bottomRight: { c: POSITION_BOTTOM_RIGHT_COORDINATES },
  inside: { c: POSITION_INSIDE_COORDINATES },
  insideLeft: { c: POSITION_LEFT_COORDINATES },
  insideRight: { c: POSITION_RIGHT_COORDINATES },
  insideTop: { c: POSITION_TOP_COORDINATES },
  insideBottom: { c: POSITION_BOTTOM_COORDINATES },
  insideTopLeft: { c: POSITION_TOP_LEFT_COORDINATES },
  insideBottomLeft: { c: POSITION_BOTTOM_LEFT_COORDINATES },
  insideTopRight: { c: POSITION_TOP_RIGHT_COORDINATES },
  insideBottomRight: { c: POSITION_BOTTOM_RIGHT_COORDINATES }
};

// packages/ag-charts-community/src/chart/crossline/cartesianCrossLine.ts
var CROSSLINE_LABEL_POSITION = UNION(
  [
    "top",
    "left",
    "right",
    "bottom",
    "topLeft",
    "topRight",
    "bottomLeft",
    "bottomRight",
    "inside",
    "insideLeft",
    "insideRight",
    "insideTop",
    "insideBottom",
    "insideTopLeft",
    "insideBottomLeft",
    "insideTopRight",
    "insideBottomRight"
  ],
  "crossLine label position"
);
var CartesianCrossLineLabel = class {
  constructor() {
    this.enabled = void 0;
    this.text = void 0;
    this.fontStyle = void 0;
    this.fontWeight = void 0;
    this.fontSize = 14;
    this.fontFamily = "Verdana, sans-serif";
    this.padding = 5;
    this.color = "rgba(87, 87, 87, 1)";
    this.position = void 0;
    this.rotation = void 0;
    this.parallel = void 0;
  }
};
__decorateClass([
  Validate(BOOLEAN, { optional: true })
], CartesianCrossLineLabel.prototype, "enabled", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], CartesianCrossLineLabel.prototype, "text", 2);
__decorateClass([
  Validate(FONT_STYLE, { optional: true })
], CartesianCrossLineLabel.prototype, "fontStyle", 2);
__decorateClass([
  Validate(FONT_WEIGHT, { optional: true })
], CartesianCrossLineLabel.prototype, "fontWeight", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], CartesianCrossLineLabel.prototype, "fontSize", 2);
__decorateClass([
  Validate(STRING)
], CartesianCrossLineLabel.prototype, "fontFamily", 2);
__decorateClass([
  Validate(NUMBER)
], CartesianCrossLineLabel.prototype, "padding", 2);
__decorateClass([
  Validate(COLOR_STRING, { optional: true })
], CartesianCrossLineLabel.prototype, "color", 2);
__decorateClass([
  Validate(CROSSLINE_LABEL_POSITION, { optional: true })
], CartesianCrossLineLabel.prototype, "position", 2);
__decorateClass([
  Validate(DEGREE, { optional: true })
], CartesianCrossLineLabel.prototype, "rotation", 2);
__decorateClass([
  Validate(BOOLEAN, { optional: true })
], CartesianCrossLineLabel.prototype, "parallel", 2);
var _CartesianCrossLine = class _CartesianCrossLine {
  constructor() {
    this.id = createId$1(this);
    this.enabled = void 0;
    this.type = void 0;
    this.range = void 0;
    this.value = void 0;
    this.fill = void 0;
    this.fillOpacity = void 0;
    this.stroke = void 0;
    this.strokeWidth = void 0;
    this.strokeOpacity = void 0;
    this.lineDash = void 0;
    this.label = new CartesianCrossLineLabel();
    this.scale = void 0;
    this.clippedRange = [-Infinity, Infinity];
    this.gridLength = 0;
    this.sideFlag = -1;
    this.parallelFlipRotation = 0;
    this.regularFlipRotation = 0;
    this.direction = "x" /* X */;
    this.group = new Group({ name: `${this.id}`, layer: true, zIndex: _CartesianCrossLine.LINE_LAYER_ZINDEX });
    this.labelGroup = new Group({ name: `${this.id}`, layer: true, zIndex: _CartesianCrossLine.LABEL_LAYER_ZINDEX });
    this.crossLineRange = new Range();
    this.crossLineLabel = new Text();
    this.labelPoint = void 0;
    this.data = [];
    this.startLine = false;
    this.endLine = false;
    this.isRange = false;
    const { group: group2, labelGroup, crossLineRange, crossLineLabel } = this;
    group2.append(crossLineRange);
    labelGroup.append(crossLineLabel);
    crossLineRange.pointerEvents = 1 /* None */;
  }
  update(visible) {
    const { enabled, data, type, value, range: range3, scale: scale2 } = this;
    if (!type || !scale2 || !enabled || !visible || !validateCrossLineValues(type, value, range3, scale2) || data.length === 0) {
      this.group.visible = false;
      this.labelGroup.visible = false;
      return;
    }
    this.group.visible = visible;
    this.labelGroup.visible = visible;
    this.group.zIndex = this.getZIndex(this.isRange);
    this.updateNodes();
  }
  calculateLayout(visible, reversedAxis) {
    if (!visible) {
      return;
    }
    const dataCreated = this.createNodeData(reversedAxis);
    if (!dataCreated) {
      return;
    }
    const { sideFlag, gridLength, data } = this;
    const boxes = [];
    const x1 = 0;
    const x2 = sideFlag * gridLength;
    const y1 = data[0];
    const y2 = data[1];
    const crossLineBox = new BBox(Math.min(x1, x2), Math.min(y1, y2), Math.abs(x1 - x2), Math.abs(y1 - y2));
    boxes.push(crossLineBox);
    const labelBox = this.computeLabelBBox();
    if (labelBox) {
      boxes.push(labelBox);
    }
    return BBox.merge(boxes);
  }
  updateNodes() {
    this.updateRangeNode();
    if (this.label.enabled) {
      this.updateLabel();
      this.positionLabel();
    }
  }
  createNodeData(reversedAxis) {
    var _a, _b, _c;
    const {
      scale: scale2,
      gridLength,
      sideFlag,
      direction,
      label: { position = "top" },
      clippedRange,
      strokeWidth = 0
    } = this;
    this.data = [];
    if (!scale2) {
      return false;
    }
    const bandwidth = (_a = scale2.bandwidth) != null ? _a : 0;
    const step = (_b = scale2.step) != null ? _b : 0;
    const padding = (reversedAxis ? -1 : 1) * (scale2 instanceof BandScale$5 ? (step - bandwidth) / 2 : 0);
    const [xStart, xEnd] = [0, sideFlag * gridLength];
    let [yStart, yEnd] = this.getRange();
    let [clampedYStart, clampedYEnd] = [
      Number(scale2.convert(yStart, { clampMode: "clamped" })) - padding,
      scale2.convert(yEnd, { clampMode: "clamped" }) + bandwidth + padding
    ];
    clampedYStart = clampArray(clampedYStart, clippedRange);
    clampedYEnd = clampArray(clampedYEnd, clippedRange);
    [yStart, yEnd] = [Number(scale2.convert(yStart)), scale2.convert(yEnd) + bandwidth];
    const validRange = (yStart === clampedYStart || yEnd === clampedYEnd || clampedYStart !== clampedYEnd) && Math.abs(clampedYEnd - clampedYStart) > 0;
    if (validRange && clampedYStart > clampedYEnd) {
      [clampedYStart, clampedYEnd] = [clampedYEnd, clampedYStart];
      [yStart, yEnd] = [yEnd, yStart];
    }
    if (yStart - padding >= clampedYStart)
      yStart -= padding;
    if (yEnd + padding <= clampedYEnd)
      yEnd += padding;
    this.isRange = validRange;
    this.startLine = strokeWidth > 0 && yStart >= clampedYStart && yStart <= clampedYStart + padding;
    this.endLine = strokeWidth > 0 && yEnd >= clampedYEnd - bandwidth - padding && yEnd <= clampedYEnd;
    if (!validRange && !this.startLine && !this.endLine) {
      return false;
    }
    this.data = [clampedYStart, clampedYEnd];
    if (this.label.enabled) {
      const yDirection = direction === "y" /* Y */;
      const { c = POSITION_TOP_COORDINATES } = (_c = labelDirectionHandling[position]) != null ? _c : {};
      const { x: labelX, y: labelY } = c({
        yDirection,
        xStart,
        xEnd,
        yStart: clampedYStart,
        yEnd: clampedYEnd
      });
      this.labelPoint = {
        x: labelX,
        y: labelY
      };
    }
    return true;
  }
  updateRangeNode() {
    var _a;
    const {
      crossLineRange,
      sideFlag,
      gridLength,
      data,
      startLine,
      endLine,
      isRange,
      fill,
      fillOpacity,
      stroke,
      strokeWidth,
      lineDash
    } = this;
    crossLineRange.x1 = 0;
    crossLineRange.x2 = sideFlag * gridLength;
    crossLineRange.y1 = data[0];
    crossLineRange.y2 = data[1];
    crossLineRange.startLine = startLine;
    crossLineRange.endLine = endLine;
    crossLineRange.isRange = isRange;
    crossLineRange.fill = fill;
    crossLineRange.fillOpacity = fillOpacity != null ? fillOpacity : 1;
    crossLineRange.stroke = stroke;
    crossLineRange.strokeWidth = strokeWidth != null ? strokeWidth : 1;
    crossLineRange.strokeOpacity = (_a = this.strokeOpacity) != null ? _a : 1;
    crossLineRange.lineDash = lineDash;
  }
  updateLabel() {
    const { crossLineLabel, label } = this;
    if (!label.text) {
      return;
    }
    crossLineLabel.fontStyle = label.fontStyle;
    crossLineLabel.fontWeight = label.fontWeight;
    crossLineLabel.fontSize = label.fontSize;
    crossLineLabel.fontFamily = label.fontFamily;
    crossLineLabel.fill = label.color;
    crossLineLabel.text = label.text;
  }
  positionLabel() {
    const {
      crossLineLabel,
      labelPoint: { x = void 0, y = void 0 } = {},
      label: { parallel, rotation, position = "top", padding = 0 },
      direction,
      parallelFlipRotation,
      regularFlipRotation
    } = this;
    if (x === void 0 || y === void 0) {
      return;
    }
    const { defaultRotation, configuredRotation } = calculateLabelRotation({
      rotation,
      parallel,
      regularFlipRotation,
      parallelFlipRotation
    });
    crossLineLabel.rotation = defaultRotation + configuredRotation;
    crossLineLabel.textBaseline = "middle";
    crossLineLabel.textAlign = "center";
    const bbox = crossLineLabel.computeTransformedBBox();
    if (!bbox) {
      return;
    }
    const yDirection = direction === "y" /* Y */;
    const { xTranslation, yTranslation } = calculateLabelTranslation({
      yDirection,
      padding,
      position,
      bbox
    });
    crossLineLabel.translationX = x + xTranslation;
    crossLineLabel.translationY = y + yTranslation;
  }
  getZIndex(isRange = false) {
    if (isRange) {
      return _CartesianCrossLine.RANGE_LAYER_ZINDEX;
    }
    return _CartesianCrossLine.LINE_LAYER_ZINDEX;
  }
  getRange() {
    var _a;
    const { value, range: range3, scale: scale2 } = this;
    const isContinuous2 = ContinuousScale.is(scale2);
    const start = (_a = range3 == null ? void 0 : range3[0]) != null ? _a : value;
    let end = range3 == null ? void 0 : range3[1];
    if (!isContinuous2 && end === void 0) {
      end = start;
    }
    if (isContinuous2 && start === end) {
      end = void 0;
    }
    return [start, end];
  }
  computeLabelBBox() {
    const { label } = this;
    if (!label.enabled) {
      return void 0;
    }
    const tempText = new Text();
    tempText.fontFamily = label.fontFamily;
    tempText.fontSize = label.fontSize;
    tempText.fontStyle = label.fontStyle;
    tempText.fontWeight = label.fontWeight;
    tempText.text = label.text;
    const {
      labelPoint: { x = void 0, y = void 0 } = {},
      label: { parallel, rotation, position = "top", padding = 0 },
      direction,
      parallelFlipRotation,
      regularFlipRotation
    } = this;
    if (x === void 0 || y === void 0) {
      return void 0;
    }
    const { configuredRotation } = calculateLabelRotation({
      rotation,
      parallel,
      regularFlipRotation,
      parallelFlipRotation
    });
    tempText.rotation = configuredRotation;
    tempText.textBaseline = "middle";
    tempText.textAlign = "center";
    const bbox = tempText.computeTransformedBBox();
    if (!bbox) {
      return void 0;
    }
    const yDirection = direction === "y" /* Y */;
    const { xTranslation, yTranslation } = calculateLabelTranslation({
      yDirection,
      padding,
      position,
      bbox
    });
    tempText.translationX = x + xTranslation;
    tempText.translationY = y + yTranslation;
    return tempText.computeTransformedBBox();
  }
  calculatePadding(padding) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const {
      isRange,
      startLine,
      endLine,
      direction,
      label: { padding: labelPadding = 0, position = "top" }
    } = this;
    if (!isRange && !startLine && !endLine) {
      return;
    }
    const crossLineLabelBBox = this.computeLabelBBox();
    const labelX = crossLineLabelBBox == null ? void 0 : crossLineLabelBBox.x;
    const labelY = crossLineLabelBBox == null ? void 0 : crossLineLabelBBox.y;
    if (!crossLineLabelBBox || labelX == void 0 || labelY == void 0) {
      return;
    }
    const chartPadding = calculateLabelChartPadding({
      yDirection: direction === "y" /* Y */,
      padding: labelPadding,
      position,
      bbox: crossLineLabelBBox
    });
    padding.left = Math.max((_a = padding.left) != null ? _a : 0, (_b = chartPadding.left) != null ? _b : 0);
    padding.right = Math.max((_c = padding.right) != null ? _c : 0, (_d = chartPadding.right) != null ? _d : 0);
    padding.top = Math.max((_e = padding.top) != null ? _e : 0, (_f = chartPadding.top) != null ? _f : 0);
    padding.bottom = Math.max((_g = padding.bottom) != null ? _g : 0, (_h = chartPadding.bottom) != null ? _h : 0);
  }
};
_CartesianCrossLine.LINE_LAYER_ZINDEX = 8 /* SERIES_CROSSLINE_LINE_ZINDEX */;
_CartesianCrossLine.RANGE_LAYER_ZINDEX = 3 /* SERIES_CROSSLINE_RANGE_ZINDEX */;
_CartesianCrossLine.LABEL_LAYER_ZINDEX = 7 /* SERIES_LABEL_ZINDEX */;
_CartesianCrossLine.className = "CrossLine";
__decorateClass([
  Validate(BOOLEAN, { optional: true })
], _CartesianCrossLine.prototype, "enabled", 2);
__decorateClass([
  Validate(UNION(["range", "line"], "a crossLine type"), { optional: true })
], _CartesianCrossLine.prototype, "type", 2);
__decorateClass([
  Validate(AND(MATCHING_CROSSLINE_TYPE("range"), ARRAY.restrict({ length: 2 })), {
    optional: true
  })
], _CartesianCrossLine.prototype, "range", 2);
__decorateClass([
  Validate(MATCHING_CROSSLINE_TYPE("value"), { optional: true })
], _CartesianCrossLine.prototype, "value", 2);
__decorateClass([
  Validate(COLOR_STRING, { optional: true })
], _CartesianCrossLine.prototype, "fill", 2);
__decorateClass([
  Validate(RATIO, { optional: true })
], _CartesianCrossLine.prototype, "fillOpacity", 2);
__decorateClass([
  Validate(COLOR_STRING, { optional: true })
], _CartesianCrossLine.prototype, "stroke", 2);
__decorateClass([
  Validate(NUMBER, { optional: true })
], _CartesianCrossLine.prototype, "strokeWidth", 2);
__decorateClass([
  Validate(RATIO, { optional: true })
], _CartesianCrossLine.prototype, "strokeOpacity", 2);
__decorateClass([
  Validate(LINE_DASH, { optional: true })
], _CartesianCrossLine.prototype, "lineDash", 2);
var CartesianCrossLine = _CartesianCrossLine;

// packages/ag-charts-community/src/module/moduleMap.ts
var ModuleMap = class {
  constructor() {
    this.moduleMap = /* @__PURE__ */ new Map();
  }
  addModule(module, moduleFactory) {
    if (this.moduleMap.has(module.optionsKey)) {
      throw new Error(`AG Charts - module already initialised: ${module.optionsKey}`);
    }
    this.moduleMap.set(module.optionsKey, moduleFactory(module));
  }
  removeModule(module) {
    var _a;
    const moduleKey = isString$1(module) ? module : module.optionsKey;
    (_a = this.moduleMap.get(moduleKey)) == null ? void 0 : _a.destroy();
    this.moduleMap.delete(moduleKey);
  }
  isModuleEnabled(module) {
    return this.moduleMap.has(isString$1(module) ? module : module.optionsKey);
  }
  getModule(module) {
    return this.moduleMap.get(isString$1(module) ? module : module.optionsKey);
  }
  get modules() {
    return this.moduleMap.values();
  }
  mapValues(callback) {
    return Array.from(this.moduleMap.values()).map(callback);
  }
  destroy() {
    for (const optionsKey of this.moduleMap.keys()) {
      this.removeModule({ optionsKey });
    }
  }
};

// packages/ag-charts-community/src/motion/states.ts
var StateMachine = class {
  constructor(initialState, states, preTransitionCb) {
    this.states = states;
    this.preTransitionCb = preTransitionCb;
    this.debug = Debug.create(true, "animation");
    this.state = initialState;
    this.debug(`%c${this.constructor.name} | init -> ${initialState}`, "color: green");
  }
  transition(event, data) {
    var _a, _b;
    const currentStateConfig = this.states[this.state];
    const destinationTransition = currentStateConfig == null ? void 0 : currentStateConfig[event];
    if (!destinationTransition) {
      this.debug(`%c${this.constructor.name} | ${this.state} -> ${event} -> ${this.state}`, "color: grey");
      return;
    }
    let destinationState = this.state;
    if (typeof destinationTransition === "string") {
      destinationState = destinationTransition;
    } else if (typeof destinationTransition === "object") {
      destinationState = destinationTransition.target;
    }
    this.debug(`%c${this.constructor.name} | ${this.state} -> ${event} -> ${destinationState}`, "color: green");
    (_a = this.preTransitionCb) == null ? void 0 : _a.call(this, this.state, destinationState);
    this.state = destinationState;
    if (typeof destinationTransition === "function") {
      destinationTransition(data);
    } else if (typeof destinationTransition === "object") {
      (_b = destinationTransition.action) == null ? void 0 : _b.call(destinationTransition, data);
    }
    return this.state;
  }
};

// packages/ag-charts-community/src/scale/logScale.ts
var identity = (x) => x;
var _LogScale = class _LogScale extends ContinuousScale {
  constructor() {
    super([1, 10], [0, 1]);
    this.type = "log";
    this.base = 10;
    this.baseLog = identity;
    this.basePow = identity;
    this.log = (x) => {
      const start = Math.min(this.domain[0], this.domain[1]);
      return start >= 0 ? this.baseLog(x) : -this.baseLog(-x);
    };
    this.pow = (x) => {
      const start = Math.min(this.domain[0], this.domain[1]);
      return start >= 0 ? this.basePow(x) : -this.basePow(-x);
    };
    this.defaultClampMode = "clamped";
  }
  toDomain(d) {
    return d;
  }
  transform(x) {
    const start = Math.min(this.domain[0], this.domain[1]);
    return start >= 0 ? Math.log(x) : -Math.log(-x);
  }
  transformInvert(x) {
    const start = Math.min(this.domain[0], this.domain[1]);
    return start >= 0 ? Math.exp(x) : -Math.exp(-x);
  }
  refresh() {
    if (this.base <= 0) {
      this.base = 0;
      Logger.warnOnce("expecting a finite Number greater than to 0");
    }
    super.refresh();
  }
  update() {
    if (!this.domain || this.domain.length < 2) {
      return;
    }
    this.updateLogFn();
    this.updatePowFn();
    if (this.nice) {
      this.updateNiceDomain();
    }
  }
  updateLogFn() {
    const { base } = this;
    let log;
    if (base === 10) {
      log = Math.log10;
    } else if (base === Math.E) {
      log = Math.log;
    } else if (base === 2) {
      log = Math.log2;
    } else {
      const logBase = Math.log(base);
      log = (x) => Math.log(x) / logBase;
    }
    this.baseLog = log;
  }
  updatePowFn() {
    const { base } = this;
    let pow;
    if (base === 10) {
      pow = _LogScale.pow10;
    } else if (base === Math.E) {
      pow = Math.exp;
    } else {
      pow = (x) => Math.pow(base, x);
    }
    this.basePow = pow;
  }
  updateNiceDomain() {
    const [d0, d1] = this.domain;
    const roundStart = d0 > d1 ? Math.ceil : Math.floor;
    const roundStop = d1 < d0 ? Math.floor : Math.ceil;
    const n0 = this.pow(roundStart(this.log(d0)));
    const n1 = this.pow(roundStop(this.log(d1)));
    this.niceDomain = [n0, n1];
  }
  static pow10(x) {
    return x >= 0 ? Math.pow(10, x) : 1 / Math.pow(10, -x);
  }
  ticks() {
    var _a;
    const count2 = (_a = this.tickCount) != null ? _a : 10;
    if (!this.domain || this.domain.length < 2 || count2 < 1) {
      return [];
    }
    this.refresh();
    const base = this.base;
    const [d0, d1] = this.getDomain();
    const start = Math.min(d0, d1);
    const stop = Math.max(d0, d1);
    let p0 = this.log(start);
    let p1 = this.log(stop);
    if (this.interval) {
      const step = Math.abs(this.interval);
      const absDiff = Math.abs(p1 - p0);
      let ticks2 = range(p0, p1, Math.min(absDiff, step));
      ticks2 = createNumericTicks(
        ticks2.fractionDigits,
        ticks2.map((x) => this.pow(x)).filter((t) => t >= start && t <= stop)
      );
      if (!this.isDenseInterval({ start, stop, interval: step, count: ticks2.length })) {
        return ticks2;
      }
    }
    const isBaseInteger = base % 1 === 0;
    const isDiffLarge = p1 - p0 >= count2;
    if (!isBaseInteger || isDiffLarge) {
      let ticks2 = ticks_default(p0, p1, Math.min(p1 - p0, count2));
      ticks2 = createNumericTicks(
        ticks2.fractionDigits,
        ticks2.map((x) => this.pow(x))
      );
      return ticks2;
    }
    const ticks = [];
    const isPositive = start > 0;
    p0 = Math.floor(p0) - 1;
    p1 = Math.round(p1) + 1;
    const min = Math.min(...this.range);
    const max = Math.max(...this.range);
    const availableSpacing = (max - min) / count2;
    let lastTickPosition = Infinity;
    for (let p = p0; p <= p1; p++) {
      const nextMagnitudeTickPosition = this.convert(this.pow(p + 1));
      for (let k = 1; k < base; k++) {
        const q = isPositive ? k : base - k + 1;
        const t = this.pow(p) * q;
        const tickPosition = this.convert(t);
        const prevSpacing = Math.abs(lastTickPosition - tickPosition);
        const nextSpacing = Math.abs(tickPosition - nextMagnitudeTickPosition);
        const fits = prevSpacing >= availableSpacing && nextSpacing >= availableSpacing;
        if (t >= start && t <= stop && (k === 1 || fits)) {
          ticks.push(t);
          lastTickPosition = tickPosition;
        }
      }
    }
    return ticks;
  }
  tickFormat({
    count: count2,
    ticks,
    specifier
  }) {
    const { base } = this;
    if (specifier == null) {
      specifier = base === 10 ? ".0e" : ",";
    }
    if (typeof specifier === "string") {
      specifier = format(specifier);
    }
    if (count2 === Infinity) {
      return specifier;
    }
    if (ticks == null) {
      this.ticks();
    }
    return (d) => {
      return specifier(d);
    };
  }
};
__decorateClass([
  Invalidating
], _LogScale.prototype, "base", 2);
var LogScale = _LogScale;

// packages/ag-charts-community/src/util/timeFormat.ts
var CONSTANTS = {
  periods: ["AM", "PM"],
  days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
};
function dayOfYear(date, startOfYear = new Date(date.getFullYear(), 0, 1)) {
  const startOffset = date.getTimezoneOffset() - startOfYear.getTimezoneOffset();
  const timeDiff = date.getTime() - startOfYear.getTime() + startOffset * 6e4;
  const timeOneDay = 36e5 * 24;
  return Math.floor(timeDiff / timeOneDay);
}
function weekOfYear(date, startDay) {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const startOfYearDay = startOfYear.getDay();
  const firstWeekStartOffset = (startDay - startOfYearDay + 7) % 7;
  const startOffset = new Date(date.getFullYear(), 0, firstWeekStartOffset + 1);
  if (startOffset <= date) {
    return Math.floor(dayOfYear(date, startOffset) / 7) + 1;
  }
  return 0;
}
var SUNDAY = 0;
var MONDAY = 1;
var THURSDAY = 4;
function isoWeekOfYear(date, year2 = date.getFullYear()) {
  const firstOfYear = new Date(year2, 0, 1);
  const firstOfYearDay = firstOfYear.getDay();
  const firstThursdayOffset = (THURSDAY - firstOfYearDay + 7) % 7;
  const startOffset = new Date(year2, 0, firstThursdayOffset - (THURSDAY - MONDAY) + 1);
  if (startOffset <= date) {
    return Math.floor(dayOfYear(date, startOffset) / 7) + 1;
  }
  return isoWeekOfYear(date, year2 - 1);
}
function timezone(date) {
  const offset4 = date.getTimezoneOffset();
  const unsignedOffset = Math.abs(offset4);
  const sign = offset4 > 0 ? "-" : "+";
  return `${sign}${pad(Math.floor(unsignedOffset / 60), 2, "0")}${pad(Math.floor(unsignedOffset % 60), 2, "0")}`;
}
var FORMATTERS = {
  a: (d) => CONSTANTS.shortDays[d.getDay()],
  A: (d) => CONSTANTS.days[d.getDay()],
  b: (d) => CONSTANTS.shortMonths[d.getMonth()],
  B: (d) => CONSTANTS.months[d.getMonth()],
  c: "%x, %X",
  d: (d, p) => pad(d.getDate(), 2, p != null ? p : "0"),
  e: "%_d",
  f: (d, p) => pad(d.getMilliseconds() * 1e3, 6, p != null ? p : "0"),
  H: (d, p) => pad(d.getHours(), 2, p != null ? p : "0"),
  I: (d, p) => {
    const hours = d.getHours() % 12;
    return hours === 0 ? "12" : pad(hours, 2, p != null ? p : "0");
  },
  j: (d, p) => pad(dayOfYear(d) + 1, 3, p != null ? p : "0"),
  m: (d, p) => pad(d.getMonth() + 1, 2, p != null ? p : "0"),
  M: (d, p) => pad(d.getMinutes(), 2, p != null ? p : "0"),
  L: (d, p) => pad(d.getMilliseconds(), 3, p != null ? p : "0"),
  p: (d) => d.getHours() < 12 ? "AM" : "PM",
  Q: (d) => String(d.getTime()),
  s: (d) => String(Math.floor(d.getTime() / 1e3)),
  S: (d, p) => pad(d.getSeconds(), 2, p != null ? p : "0"),
  u: (d) => {
    let day2 = d.getDay();
    if (day2 < 1)
      day2 += 7;
    return String(day2 % 7);
  },
  U: (d, p) => pad(weekOfYear(d, SUNDAY), 2, p != null ? p : "0"),
  V: (d, p) => pad(isoWeekOfYear(d), 2, p != null ? p : "0"),
  w: (d, p) => pad(d.getDay(), 2, p != null ? p : "0"),
  W: (d, p) => pad(weekOfYear(d, MONDAY), 2, p != null ? p : "0"),
  x: "%-m/%-d/%Y",
  X: "%-I:%M:%S %p",
  y: (d, p) => pad(d.getFullYear() % 100, 2, p != null ? p : "0"),
  Y: (d, p) => pad(d.getFullYear(), 4, p != null ? p : "0"),
  Z: (d) => timezone(d),
  "%": () => "%"
};
var PADS = {
  _: " ",
  "0": "0",
  "-": ""
};
function pad(value, size, padChar) {
  const output = String(Math.floor(value));
  if (output.length >= size) {
    return output;
  }
  return `${padChar.repeat(size - output.length)}${output}`;
}
function buildFormatter(formatString) {
  const formatParts = [];
  while (formatString.length > 0) {
    let nextEscapeIdx = formatString.indexOf("%");
    if (nextEscapeIdx !== 0) {
      const literalPart = nextEscapeIdx > 0 ? formatString.substring(0, nextEscapeIdx) : formatString;
      formatParts.push(literalPart);
    }
    if (nextEscapeIdx < 0)
      break;
    const maybePadSpecifier = formatString[nextEscapeIdx + 1];
    const maybePad = PADS[maybePadSpecifier];
    if (maybePad != null) {
      nextEscapeIdx++;
    }
    const maybeFormatterSpecifier = formatString[nextEscapeIdx + 1];
    const maybeFormatter = FORMATTERS[maybeFormatterSpecifier];
    if (typeof maybeFormatter === "function") {
      formatParts.push([maybeFormatter, maybePad]);
    } else if (typeof maybeFormatter === "string") {
      const formatter = buildFormatter(maybeFormatter);
      formatParts.push([formatter, maybePad]);
    } else {
      formatParts.push(`${maybePad != null ? maybePad : ""}${maybeFormatterSpecifier}`);
    }
    formatString = formatString.substring(nextEscapeIdx + 2);
  }
  return (dateTime) => {
    const dateTimeAsDate = typeof dateTime === "number" ? new Date(dateTime) : dateTime;
    return formatParts.map((c) => typeof c === "string" ? c : c[0](dateTimeAsDate, c[1])).join("");
  };
}

// packages/ag-charts-community/src/scale/timeScale.ts
var formatStrings = {
  [0 /* MILLISECOND */]: ".%L",
  [1 /* SECOND */]: ":%S",
  [2 /* MINUTE */]: "%I:%M",
  [3 /* HOUR */]: "%I %p",
  [4 /* WEEK_DAY */]: "%a",
  [5 /* SHORT_MONTH */]: "%b %d",
  [6 /* MONTH */]: "%B",
  [7 /* SHORT_YEAR */]: "%y",
  [8 /* YEAR */]: "%Y"
};
function toNumber(x) {
  return x instanceof Date ? x.getTime() : x;
}
var TimeScale$1 = class extends ContinuousScale {
  constructor() {
    super([new Date(2022, 11, 7), new Date(2022, 11, 8)], [0, 1]);
    this.type = "time";
    this.year = year_default;
    this.month = month_default;
    this.week = week_default;
    this.day = day_default;
    this.hour = hour_default;
    this.minute = minute_default;
    this.second = second_default;
    this.millisecond = millisecond_default;
    /**
     * Array of default tick intervals in the following format:
     *
     *     [
     *         interval (unit of time),
     *         number of units (step),
     *         the length of that number of units in milliseconds
     *     ]
     */
    this.tickIntervals = [
      [this.second, 1, durationSecond],
      [this.second, 5, 5 * durationSecond],
      [this.second, 15, 15 * durationSecond],
      [this.second, 30, 30 * durationSecond],
      [this.minute, 1, durationMinute],
      [this.minute, 5, 5 * durationMinute],
      [this.minute, 15, 15 * durationMinute],
      [this.minute, 30, 30 * durationMinute],
      [this.hour, 1, durationHour],
      [this.hour, 3, 3 * durationHour],
      [this.hour, 6, 6 * durationHour],
      [this.hour, 12, 12 * durationHour],
      [this.day, 1, durationDay],
      [this.day, 2, 2 * durationDay],
      [this.week, 1, durationWeek],
      [this.week, 2, 2 * durationWeek],
      [this.week, 3, 3 * durationWeek],
      [this.month, 1, durationMonth],
      [this.month, 2, 2 * durationMonth],
      [this.month, 3, 3 * durationMonth],
      [this.month, 4, 4 * durationMonth],
      [this.month, 6, 6 * durationMonth],
      [this.year, 1, durationYear]
    ];
  }
  toDomain(d) {
    return new Date(d);
  }
  calculateDefaultTickFormat(ticks = []) {
    let defaultTimeFormat = 8 /* YEAR */;
    const updateFormat = (format2) => {
      if (format2 < defaultTimeFormat) {
        defaultTimeFormat = format2;
      }
    };
    for (const value of ticks) {
      const format2 = this.getLowestGranularityFormat(value);
      updateFormat(format2);
    }
    const firstTick = toNumber(ticks[0]);
    const lastTick = toNumber(ticks[ticks.length - 1]);
    const startYear = new Date(firstTick).getFullYear();
    const stopYear = new Date(lastTick).getFullYear();
    const yearChange = stopYear - startYear > 0;
    return this.buildFormatString(defaultTimeFormat, yearChange);
  }
  buildFormatString(defaultTimeFormat, yearChange) {
    let formatStringArray = [formatStrings[defaultTimeFormat]];
    let timeEndIndex = 0;
    const domain = this.getDomain();
    const start = Math.min(...domain.map(toNumber));
    const stop = Math.max(...domain.map(toNumber));
    const extent2 = stop - start;
    switch (defaultTimeFormat) {
      case 1 /* SECOND */:
        if (extent2 / durationMinute > 1) {
          formatStringArray.push(formatStrings[2 /* MINUTE */]);
        }
      case 2 /* MINUTE */:
        if (extent2 / durationHour > 1) {
          formatStringArray.push(formatStrings[3 /* HOUR */]);
        }
      case 3 /* HOUR */:
        timeEndIndex = formatStringArray.length;
        if (extent2 / durationDay > 1) {
          formatStringArray.push(formatStrings[4 /* WEEK_DAY */]);
        }
      case 4 /* WEEK_DAY */:
        if (extent2 / durationWeek > 1 || yearChange) {
          const weekDayIndex = formatStringArray.indexOf(formatStrings[4 /* WEEK_DAY */]);
          if (weekDayIndex > -1) {
            formatStringArray.splice(weekDayIndex, 1, formatStrings[5 /* SHORT_MONTH */]);
          }
        }
      case 5 /* SHORT_MONTH */:
      case 6 /* MONTH */:
        if (extent2 / durationYear > 1 || yearChange) {
          formatStringArray.push(formatStrings[8 /* YEAR */]);
        }
    }
    if (timeEndIndex < formatStringArray.length) {
      formatStringArray = [
        ...formatStringArray.slice(0, timeEndIndex),
        formatStringArray.slice(timeEndIndex).join(" ")
      ];
    }
    if (timeEndIndex > 0) {
      formatStringArray = [
        ...formatStringArray.slice(0, timeEndIndex).reverse(),
        ...formatStringArray.slice(timeEndIndex)
      ];
      if (timeEndIndex < formatStringArray.length) {
        formatStringArray.splice(timeEndIndex, 0, " ");
      }
    }
    return formatStringArray.join("");
  }
  getLowestGranularityFormat(value) {
    if (this.second.floor(value) < value) {
      return 0 /* MILLISECOND */;
    } else if (this.minute.floor(value) < value) {
      return 1 /* SECOND */;
    } else if (this.hour.floor(value) < value) {
      return 2 /* MINUTE */;
    } else if (this.day.floor(value) < value) {
      return 3 /* HOUR */;
    } else if (this.month.floor(value) < value) {
      if (this.week.floor(value) < value) {
        return 4 /* WEEK_DAY */;
      }
      return 5 /* SHORT_MONTH */;
    } else if (this.year.floor(value) < value) {
      return 6 /* MONTH */;
    }
    return 8 /* YEAR */;
  }
  defaultTickFormat(ticks) {
    const formatString = this.calculateDefaultTickFormat(ticks);
    return (date) => buildFormatter(formatString)(date);
  }
  /**
   * @param options Tick interval options.
   * @param options.start The start time (timestamp).
   * @param options.stop The end time (timestamp).
   * @param options.count Number of intervals between ticks.
   */
  getTickInterval({
    start,
    stop,
    count: count2,
    minCount,
    maxCount
  }) {
    const { tickIntervals } = this;
    let countableTimeInterval;
    let step;
    const tickCount = count2 != null ? count2 : ContinuousScale.defaultTickCount;
    const target = Math.abs(stop - start) / Math.max(tickCount, 1);
    let i = 0;
    while (i < tickIntervals.length && target > tickIntervals[i][2]) {
      i++;
    }
    if (i === 0) {
      step = Math.max(tickStep(start, stop, tickCount, minCount, maxCount), 1);
      countableTimeInterval = this.millisecond;
    } else if (i === tickIntervals.length) {
      const y0 = start / durationYear;
      const y1 = stop / durationYear;
      step = tickStep(y0, y1, tickCount, minCount, maxCount);
      countableTimeInterval = this.year;
    } else {
      const diff0 = target - tickIntervals[i - 1][2];
      const diff1 = tickIntervals[i][2] - target;
      const index = diff0 < diff1 ? i - 1 : i;
      [countableTimeInterval, step] = tickIntervals[index];
    }
    return countableTimeInterval.every(step);
  }
  invert(y) {
    return new Date(super.invert(y));
  }
  /**
   * Returns uniformly-spaced dates that represent the scale's domain.
   */
  ticks() {
    if (!this.domain || this.domain.length < 2) {
      return [];
    }
    this.refresh();
    const [t0, t1] = this.getDomain().map(toNumber);
    const start = Math.min(t0, t1);
    const stop = Math.max(t0, t1);
    if (this.interval !== void 0) {
      return this.getTicksForInterval({ start, stop });
    }
    if (this.nice) {
      const { tickCount } = this;
      if (tickCount === 2) {
        return this.niceDomain;
      }
      if (tickCount === 1) {
        return this.niceDomain.slice(0, 1);
      }
    }
    return this.getDefaultTicks({ start, stop });
  }
  getDefaultTicks({ start, stop }) {
    const t = this.getTickInterval({
      start,
      stop,
      count: this.tickCount,
      minCount: this.minTickCount,
      maxCount: this.maxTickCount
    });
    return t ? t.range(new Date(start), new Date(stop)) : [];
  }
  getTicksForInterval({ start, stop }) {
    const { interval, tickIntervals } = this;
    if (!interval) {
      return [];
    }
    if (interval instanceof TimeInterval) {
      const ticks2 = interval.range(new Date(start), new Date(stop));
      if (this.isDenseInterval({ start, stop, interval, count: ticks2.length })) {
        return this.getDefaultTicks({ start, stop });
      }
      return ticks2;
    }
    const absInterval = Math.abs(interval);
    if (this.isDenseInterval({ start, stop, interval: absInterval })) {
      return this.getDefaultTicks({ start, stop });
    }
    const reversedInterval = [...tickIntervals];
    reversedInterval.reverse();
    const timeInterval = reversedInterval.find((tickInterval) => absInterval % tickInterval[2] === 0);
    if (timeInterval) {
      const i = timeInterval[0].every(absInterval / (timeInterval[2] / timeInterval[1]));
      return i.range(new Date(start), new Date(stop));
    }
    let date = new Date(start);
    const stopDate = new Date(stop);
    const ticks = [];
    while (date <= stopDate) {
      ticks.push(date);
      date = new Date(date);
      date.setMilliseconds(date.getMilliseconds() + absInterval);
    }
    return ticks;
  }
  /**
   * Returns a time format function suitable for displaying tick values.
   * @param specifier If the specifier string is provided, this method is equivalent to
   * the {@link TimeLocaleObject.format} method.
   * If no specifier is provided, this method returns the default time format function.
   */
  tickFormat({ ticks, specifier }) {
    return specifier == void 0 ? this.defaultTickFormat(ticks) : buildFormatter(specifier);
  }
  update() {
    if (!this.domain || this.domain.length < 2) {
      return;
    }
    if (this.nice) {
      this.updateNiceDomain();
    }
  }
  /**
   * Extends the domain so that it starts and ends on nice round values.
   * This method typically modifies the scale’s domain, and may only extend the bounds to the nearest round value.
   */
  updateNiceDomain() {
    const maxAttempts = 4;
    let [d0, d1] = this.domain;
    for (let i = 0; i < maxAttempts; i++) {
      this.updateNiceDomainIteration(d0, d1);
      const [n0, n1] = this.niceDomain;
      if (toNumber(d0) === toNumber(n0) && toNumber(d1) === toNumber(n1)) {
        break;
      }
      d0 = n0;
      d1 = n1;
    }
  }
  updateNiceDomainIteration(d0, d1) {
    const start = Math.min(toNumber(d0), toNumber(d1));
    const stop = Math.max(toNumber(d0), toNumber(d1));
    const isReversed = d0 > d1;
    const { interval } = this;
    let i;
    if (interval instanceof TimeInterval) {
      i = interval;
    } else {
      const tickCount = typeof interval === "number" ? (stop - start) / Math.max(interval, 1) : this.tickCount;
      i = this.getTickInterval({
        start,
        stop,
        count: tickCount,
        minCount: this.minTickCount,
        maxCount: this.maxTickCount
      });
    }
    if (i) {
      const intervalRange = i.range(new Date(start), new Date(stop), true);
      const domain = isReversed ? [...intervalRange].reverse() : intervalRange;
      const n0 = domain[0];
      const n1 = domain[domain.length - 1];
      this.niceDomain = [n0, n1];
    }
  }
};

// packages/ag-charts-community/src/sparklines-util.ts
var sparklines_util_exports = {};
__export(sparklines_util_exports, {
  Color: () => Color,
  DELETE: () => DELETE,
  Logger: () => Logger,
  Padding: () => Padding$1,
  angleBetween: () => angleBetween,
  checkDatum: () => checkDatum,
  clamp: () => clamp2,
  clampArray: () => clampArray,
  countFractionDigits: () => countFractionDigits,
  createId: () => createId$1,
  extent: () => extent$4,
  isContinuous: () => isContinuous,
  isDate: () => isDate2,
  isDiscrete: () => isDiscrete,
  isEqual: () => isEqual,
  isNegative: () => isNegative,
  isNumber: () => isNumber2,
  isNumberEqual: () => isEqual,
  isReal: () => isReal,
  isString: () => isString2,
  isStringObject: () => isStringObject$1,
  jsonApply: () => jsonApply,
  jsonClone: () => jsonClone,
  jsonDiff: () => jsonDiff,
  jsonMerge: () => jsonMerge,
  jsonWalk: () => jsonWalk,
  mod: () => mod,
  normalisedExtent: () => normalisedExtent,
  normalisedExtentWithMetadata: () => normalisedExtentWithMetadata,
  normalizeAngle180: () => normalizeAngle180,
  normalizeAngle360: () => normalizeAngle360,
  normalizeAngle360Inclusive: () => normalizeAngle360Inclusive,
  range: () => range,
  resetIds: () => resetIds,
  round: () => round,
  sanitizeHtml: () => sanitizeHtml,
  tickFormat: () => tickFormat,
  tickStep: () => tickStep,
  ticks: () => ticks_default,
  toDegrees: () => toDegrees,
  toFixed: () => toFixed,
  toRadians: () => toRadians,
  toReal: () => toReal,
  zipObject: () => zipObject
});

// packages/ag-charts-community/src/util/padding.ts
var Padding$1 = class {
  constructor(top = 0, right = top, bottom = top, left = right) {
    this.top = top;
    this.right = right;
    this.bottom = bottom;
    this.left = left;
  }
  clear() {
    this.top = this.right = this.bottom = this.left = 0;
  }
};
__decorateClass([
  Validate(POSITIVE_NUMBER)
], Padding$1.prototype, "top", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], Padding$1.prototype, "right", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], Padding$1.prototype, "bottom", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], Padding$1.prototype, "left", 2);

// packages/ag-charts-community/src/util/sanitize.ts
var element = null;
function sanitizeHtml(text) {
  if (text == null) {
    return void 0;
  } else if (text === "") {
    return "";
  }
  element != null ? element : element = document.createElement("div");
  element.textContent = String(text);
  return element.innerHTML;
}

// packages/ag-charts-community/src/util/equal.ts
function areArrayNumbersEqual(arrA, arrB) {
  return arrA.length === arrB.length && arrA.every((item, i) => Number(item) === Number(arrB[i]));
}

// packages/ag-charts-community/src/util/labelPlacement.ts
function circleRectOverlap(c, x, y, w, h) {
  let edgeX = c.x;
  if (c.x < x) {
    edgeX = x;
  } else if (c.x > x + w) {
    edgeX = x + w;
  }
  let edgeY = c.y;
  if (c.y < y) {
    edgeY = y;
  } else if (c.y > y + h) {
    edgeY = y + h;
  }
  const dx = c.x - edgeX;
  const dy = c.y - edgeY;
  const d = Math.sqrt(dx * dx + dy * dy);
  return d <= c.size * 0.5;
}
function rectRectOverlap(r1, x2, y2, w2, h2) {
  const xOverlap = r1.x + r1.width > x2 && r1.x < x2 + w2;
  const yOverlap = r1.y + r1.height > y2 && r1.y < y2 + h2;
  return xOverlap && yOverlap;
}
function rectContainsRect(r1, r2x, r2y, r2w, r2h) {
  return r2x + r2w < r1.x + r1.width && r2x > r1.x && r2y > r1.y && r2y + r2h < r1.y + r1.height;
}
function isPointLabelDatum(x) {
  return x != null && typeof x.point === "object" && typeof x.label === "object";
}
function placeLabels(data, bounds, padding = 5) {
  const result = [];
  data = data.map((d) => d.slice().sort((a, b) => b.point.size - a.point.size));
  for (let j = 0; j < data.length; j++) {
    const labels = result[j] = [];
    const datum = data[j];
    if (!((datum == null ? void 0 : datum.length) && datum[0].label)) {
      continue;
    }
    for (let i = 0, ln = datum.length; i < ln; i++) {
      const d = datum[i];
      const l = d.label;
      const r = d.point.size * 0.5;
      const x = d.point.x - l.width * 0.5;
      const y = d.point.y - r - l.height - padding;
      const { width, height } = l;
      const withinBounds = !bounds || rectContainsRect(bounds, x, y, width, height);
      if (!withinBounds) {
        continue;
      }
      const overlapPoints = data.some(
        (datum2) => datum2.some((d2) => circleRectOverlap(d2.point, x, y, width, height))
      );
      if (overlapPoints) {
        continue;
      }
      const overlapLabels = result.some((labels2) => labels2.some((l2) => rectRectOverlap(l2, x, y, width, height)));
      if (overlapLabels) {
        continue;
      }
      labels.push({
        index: i,
        text: l.text,
        x,
        y,
        width,
        height,
        datum: d
      });
    }
  }
  return result;
}
function axisLabelsOverlap(data, padding) {
  const result = [];
  for (let i = 0; i < data.length; i++) {
    const datum = data[i];
    const {
      point: { x, y },
      label: { text }
    } = datum;
    let {
      label: { width, height }
    } = datum;
    width += padding != null ? padding : 0;
    height += padding != null ? padding : 0;
    const overlapLabels = result.some((l) => {
      return rectRectOverlap(l, x, y, width, height);
    });
    if (overlapLabels) {
      return true;
    }
    result.push({
      index: i,
      text,
      x,
      y,
      width,
      height,
      datum
    });
  }
  return false;
}

// packages/ag-charts-community/src/chart/axis/axisGridLine.ts
var GRID_STYLE_KEYS = ["stroke", "lineDash"];
var GRID_STYLE = ARRAY_OF(
  (value) => isObject(value) && Object.keys(value).every((key) => GRID_STYLE_KEYS.includes(key)),
  "objects with gridline style properties such as 'stroke' or 'lineDash'"
);
var AxisGridLine = class {
  constructor() {
    this.enabled = true;
    this.width = 1;
    this.style = [
      {
        stroke: void 0,
        lineDash: []
      }
    ];
  }
};
__decorateClass([
  Validate(BOOLEAN)
], AxisGridLine.prototype, "enabled", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], AxisGridLine.prototype, "width", 2);
__decorateClass([
  Validate(GRID_STYLE)
], AxisGridLine.prototype, "style", 2);

// packages/ag-charts-community/src/util/default.ts
function Default(defaultValue, replaces = [void 0]) {
  return addTransformToInstanceProperty((_, __, v) => {
    if (replaces.includes(v)) {
      return defaultValue;
    }
    return v;
  });
}

// packages/ag-charts-community/src/chart/axis/axisLabel.ts
var AxisLabel = class {
  constructor() {
    this.enabled = true;
    this.autoWrap = false;
    this.maxWidth = void 0;
    this.maxHeight = void 0;
    this.fontStyle = void 0;
    this.fontWeight = void 0;
    this.fontSize = 12;
    this.fontFamily = "Verdana, sans-serif";
    this.padding = 5;
    this.minSpacing = NaN;
    this.color = "rgba(87, 87, 87, 1)";
    this.rotation = void 0;
    this.avoidCollisions = true;
    this.mirrored = false;
    this.parallel = false;
    /**
     * In case {@param value} is a number, the {@param fractionDigits} parameter will
     * be provided as well. The `fractionDigits` corresponds to the number of fraction
     * digits used by the tick step. For example, if the tick step is `0.0005`,
     * the `fractionDigits` is 4.
     */
    this.formatter = void 0;
  }
  /**
   * The side of the axis line to position the labels on.
   * -1 = left (default)
   * 1 = right
   */
  getSideFlag() {
    return this.mirrored ? 1 : -1;
  }
  getFont() {
    return getFont(this);
  }
};
__decorateClass([
  Validate(BOOLEAN)
], AxisLabel.prototype, "enabled", 2);
__decorateClass([
  Validate(BOOLEAN, { optional: true })
], AxisLabel.prototype, "autoWrap", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], AxisLabel.prototype, "maxWidth", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], AxisLabel.prototype, "maxHeight", 2);
__decorateClass([
  Validate(FONT_STYLE, { optional: true })
], AxisLabel.prototype, "fontStyle", 2);
__decorateClass([
  Validate(FONT_WEIGHT, { optional: true })
], AxisLabel.prototype, "fontWeight", 2);
__decorateClass([
  Validate(NUMBER.restrict({ min: 1 }))
], AxisLabel.prototype, "fontSize", 2);
__decorateClass([
  Validate(STRING)
], AxisLabel.prototype, "fontFamily", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], AxisLabel.prototype, "padding", 2);
__decorateClass([
  Validate(NUMBER_OR_NAN),
  Default(NaN)
], AxisLabel.prototype, "minSpacing", 2);
__decorateClass([
  Validate(COLOR_STRING, { optional: true })
], AxisLabel.prototype, "color", 2);
__decorateClass([
  Validate(DEGREE, { optional: true })
], AxisLabel.prototype, "rotation", 2);
__decorateClass([
  Validate(BOOLEAN)
], AxisLabel.prototype, "avoidCollisions", 2);
__decorateClass([
  Validate(BOOLEAN)
], AxisLabel.prototype, "mirrored", 2);
__decorateClass([
  Validate(BOOLEAN)
], AxisLabel.prototype, "parallel", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], AxisLabel.prototype, "format", 2);

// packages/ag-charts-community/src/chart/axis/axisLine.ts
var AxisLine = class {
  constructor() {
    this.enabled = true;
    this.width = 1;
    this.color = void 0;
  }
};
__decorateClass([
  Validate(BOOLEAN)
], AxisLine.prototype, "enabled", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], AxisLine.prototype, "width", 2);
__decorateClass([
  Validate(COLOR_STRING, { optional: true })
], AxisLine.prototype, "color", 2);

// packages/ag-charts-community/src/chart/axis/axisTick.ts
var TICK_INTERVAL = predicateWithMessage(
  (value) => isFiniteNumber(value) && value > 0 || value instanceof TimeInterval,
  `a non-zero positive Number value or, for a time axis, a Time Interval such as 'agCharts.time.month'`
);
var AxisTick = class {
  constructor() {
    this.enabled = true;
    this.width = 1;
    this.size = 6;
    this.color = void 0;
    this.interval = void 0;
    this.values = void 0;
    this.minSpacing = NaN;
  }
};
__decorateClass([
  Validate(BOOLEAN)
], AxisTick.prototype, "enabled", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], AxisTick.prototype, "width", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], AxisTick.prototype, "size", 2);
__decorateClass([
  Validate(COLOR_STRING, { optional: true })
], AxisTick.prototype, "color", 2);
__decorateClass([
  Validate(TICK_INTERVAL, { optional: true })
], AxisTick.prototype, "interval", 2);
__decorateClass([
  Validate(ARRAY, { optional: true })
], AxisTick.prototype, "values", 2);
__decorateClass([
  Validate(MIN_SPACING),
  Default(NaN)
], AxisTick.prototype, "minSpacing", 2);

// packages/ag-charts-community/src/chart/axis/axisUtil.ts
function prepareAxisAnimationContext(axis) {
  const requestedRangeMin = Math.min(...axis.range);
  const requestedRangeMax = Math.max(...axis.range);
  const min = Math.floor(requestedRangeMin);
  const max = Math.ceil(requestedRangeMax);
  const visible = min !== max;
  return { min, max, visible };
}
var fullCircle = Math.PI * 2;
var halfCircle = fullCircle / 2;
function normaliseEndRotation(start, end) {
  const directDistance = Math.abs(end - start);
  if (directDistance < halfCircle)
    return end;
  if (start > end)
    return end + fullCircle;
  return end - fullCircle;
}
function prepareAxisAnimationFunctions(ctx) {
  const outOfBounds = (y, range3) => {
    const min = range3 != null ? Math.min(...range3) : ctx.min;
    const max = range3 != null ? Math.max(...range3) : ctx.max;
    return y < min || y > max;
  };
  const calculateStatus2 = (node, datum, status) => {
    if (status !== "removed" && outOfBounds(node.translationY, node.datum.range)) {
      return "removed";
    } else if (status !== "added" && outOfBounds(datum.translationY, datum.range)) {
      return "added";
    }
    return status;
  };
  const tick = {
    fromFn(node, datum, status) {
      let y = node.y1 + node.translationY;
      let opacity = node.opacity;
      if (status === "added" || outOfBounds(node.datum.translationY, node.datum.range)) {
        y = datum.translationY;
        opacity = 0;
      }
      return __spreadValues({ y: 0, translationY: y, opacity }, FROM_TO_MIXINS[status]);
    },
    toFn(_node, datum, status) {
      const y = datum.translationY;
      let opacity = 1;
      if (status === "removed") {
        opacity = 0;
      }
      return {
        y: 0,
        translationY: y,
        opacity,
        finish: {
          // Set explicit y after animation so it's pixel aligned
          y,
          translationY: 0
        }
      };
    },
    intermediateFn(node, _datum, _status) {
      return { visible: !outOfBounds(node.y) };
    }
  };
  const label = {
    fromFn(node, newDatum, status) {
      var _a;
      const datum = (_a = node.previousDatum) != null ? _a : newDatum;
      status = calculateStatus2(node, newDatum, status);
      const x = datum.x;
      const y = datum.y;
      const rotationCenterX = datum.rotationCenterX;
      let translationY = Math.round(node.translationY);
      let rotation = datum.rotation;
      let opacity = node.opacity;
      if (status === "removed" || outOfBounds(datum.y, datum.range)) ; else if (status === "added" || outOfBounds(node.datum.y, node.datum.range)) {
        translationY = Math.round(datum.translationY);
        opacity = 0;
        rotation = newDatum.rotation;
      }
      return __spreadValues({ x, y, rotationCenterX, translationY, rotation, opacity }, FROM_TO_MIXINS[status]);
    },
    toFn(node, datum, status) {
      var _a, _b;
      const x = datum.x;
      const y = datum.y;
      const rotationCenterX = datum.rotationCenterX;
      const translationY = Math.round(datum.translationY);
      let rotation = 0;
      let opacity = 1;
      status = calculateStatus2(node, datum, status);
      if (status === "added") {
        opacity = 1;
        rotation = datum.rotation;
      } else if (status === "removed") {
        opacity = 0;
        rotation = datum.rotation;
      } else {
        rotation = normaliseEndRotation((_b = (_a = node.previousDatum) == null ? void 0 : _a.rotation) != null ? _b : datum.rotation, datum.rotation);
      }
      return { x, y, rotationCenterX, translationY, rotation, opacity, finish: { rotation: datum.rotation } };
    }
  };
  const line = {
    fromFn(node, datum) {
      var _a;
      return __spreadValues(__spreadValues({}, (_a = node.previousDatum) != null ? _a : datum), FROM_TO_MIXINS["updated"]);
    },
    toFn(_node, datum) {
      return __spreadValues({}, datum);
    }
  };
  const group2 = {
    fromFn(group3, _datum) {
      const { rotation, translationX, translationY } = group3;
      return __spreadValues({
        rotation,
        translationX,
        translationY
      }, FROM_TO_MIXINS["updated"]);
    },
    toFn(_group, datum) {
      const { rotation, translationX, translationY } = datum;
      return {
        rotation,
        translationX,
        translationY
      };
    }
  };
  return { tick, line, label, group: group2 };
}
function resetAxisGroupFn() {
  return (_node, datum) => {
    return {
      rotation: datum.rotation,
      rotationCenterX: datum.rotationCenterX,
      rotationCenterY: datum.rotationCenterY,
      translationX: datum.translationX,
      translationY: datum.translationY
    };
  };
}
function resetAxisSelectionFn(ctx) {
  const { visible: rangeVisible, min, max } = ctx;
  return (_node, datum) => {
    const y = datum.translationY;
    const visible = rangeVisible && y >= min && y <= max;
    return {
      y,
      translationY: 0,
      opacity: 1,
      visible
    };
  };
}
function resetAxisLabelSelectionFn() {
  return (_node, datum) => {
    return {
      x: datum.x,
      y: datum.y,
      translationY: datum.translationY,
      rotation: datum.rotation,
      rotationCenterX: datum.rotationCenterX
    };
  };
}
function resetAxisLineSelectionFn() {
  return (_node, datum) => {
    return __spreadValues({}, datum);
  };
}

// packages/ag-charts-community/src/chart/axis/axis.ts
var Tags = /* @__PURE__ */ ((Tags2) => {
  Tags2[Tags2["TickLine"] = 0] = "TickLine";
  Tags2[Tags2["TickLabel"] = 1] = "TickLabel";
  Tags2[Tags2["GridLine"] = 2] = "GridLine";
  Tags2[Tags2["GridArc"] = 3] = "GridArc";
  Tags2[Tags2["AxisLine"] = 4] = "AxisLine";
  return Tags2;
})(Tags || {});
var _Axis = class _Axis {
  constructor(moduleCtx, scale2) {
    this.moduleCtx = moduleCtx;
    this.scale = scale2;
    this.id = createId$1(this);
    this.nice = true;
    this.reverse = void 0;
    this.dataDomain = { domain: [], clipped: false };
    this.keys = [];
    this.boundSeries = [];
    this.includeInvisibleDomains = false;
    this.axisGroup = new Group({ name: `${this.id}-axis`, zIndex: 2 /* AXIS_ZINDEX */ });
    this.lineNode = this.axisGroup.appendChild(new Line());
    this.tickLineGroup = this.axisGroup.appendChild(
      new Group({ name: `${this.id}-Axis-tick-lines`, zIndex: 2 /* AXIS_ZINDEX */ })
    );
    this.tickLabelGroup = this.axisGroup.appendChild(
      new Group({ name: `${this.id}-Axis-tick-labels`, zIndex: 2 /* AXIS_ZINDEX */ })
    );
    this.crossLineGroup = new Group({ name: `${this.id}-CrossLines` });
    this.gridGroup = new Group({ name: `${this.id}-Axis-grid` });
    this.gridLineGroup = this.gridGroup.appendChild(
      new Group({
        name: `${this.id}-gridLines`,
        zIndex: 1 /* AXIS_GRID_ZINDEX */
      })
    );
    this.tickLineGroupSelection = Selection.select(this.tickLineGroup, Line, false);
    this.tickLabelGroupSelection = Selection.select(this.tickLabelGroup, Text, false);
    this.gridLineGroupSelection = Selection.select(this.gridLineGroup, Line, false);
    this.line = new AxisLine();
    this.tick = this.createTick();
    this.gridLine = new AxisGridLine();
    this.label = this.createLabel();
    this.defaultTickMinSpacing = _Axis.defaultTickMinSpacing;
    this.translation = { x: 0, y: 0 };
    this.rotation = 0;
    // axis rotation angle in degrees
    this.layout = {
      label: {
        fractionDigits: 0,
        padding: this.label.padding,
        format: this.label.format
      }
    };
    this.destroyFns = [];
    this.range = [0, 1];
    this.visibleRange = [0, 1];
    this.title = void 0;
    this._titleCaption = new Caption();
    /**
     * The length of the grid. The grid is only visible in case of a non-zero value.
     * In case {@link radialGrid} is `true`, the value is interpreted as an angle
     * (in degrees).
     */
    this._gridLength = 0;
    this.fractionDigits = 0;
    /**
     * The distance between the grid ticks and the axis ticks.
     */
    this.gridPadding = 0;
    /**
     * Is used to avoid collisions between axis labels and series.
     */
    this.seriesAreaPadding = 0;
    this.tickGenerationResult = void 0;
    this.maxThickness = Infinity;
    this.moduleMap = new ModuleMap();
    this.refreshScale();
    this._titleCaption.registerInteraction(this.moduleCtx);
    this._titleCaption.node.rotation = -Math.PI / 2;
    this.axisGroup.appendChild(this._titleCaption.node);
    this.destroyFns.push(moduleCtx.interactionManager.addListener("hover", (e) => this.checkAxisHover(e)));
    this.animationManager = moduleCtx.animationManager;
    this.animationState = new StateMachine("empty", {
      empty: {
        update: {
          target: "ready",
          action: () => this.resetSelectionNodes()
        }
      },
      ready: {
        update: (data) => this.animateReadyUpdate(data),
        resize: () => this.resetSelectionNodes()
      }
    });
    this._crossLines = [];
    this.assignCrossLineArrayConstructor(this._crossLines);
    let previousSize = void 0;
    this.destroyFns.push(
      moduleCtx.layoutService.addListener("layout-complete", (e) => {
        if (previousSize != null && jsonDiff(e.chart, previousSize) != null) {
          this.animationState.transition("resize");
        }
        previousSize = __spreadValues({}, e.chart);
      })
    );
    this.destroyFns.push(
      moduleCtx.updateService.addListener("update-complete", (e) => {
        this.minRect = e.minRect;
      })
    );
  }
  get type() {
    var _a;
    return (_a = this.constructor.type) != null ? _a : "";
  }
  set crossLines(value) {
    var _a, _b;
    (_a = this._crossLines) == null ? void 0 : _a.forEach((crossLine) => this.detachCrossLine(crossLine));
    if (value) {
      this.assignCrossLineArrayConstructor(value);
    }
    this._crossLines = value;
    (_b = this._crossLines) == null ? void 0 : _b.forEach((crossLine) => {
      this.attachCrossLine(crossLine);
      this.initCrossLine(crossLine);
    });
  }
  get crossLines() {
    return this._crossLines;
  }
  attachCrossLine(crossLine) {
    this.crossLineGroup.appendChild(crossLine.group);
    this.crossLineGroup.appendChild(crossLine.labelGroup);
  }
  detachCrossLine(crossLine) {
    this.crossLineGroup.removeChild(crossLine.group);
    this.crossLineGroup.removeChild(crossLine.labelGroup);
  }
  destroy() {
    this.moduleMap.destroy();
    this.destroyFns.forEach((f) => f());
  }
  refreshScale() {
    var _a;
    this.range = this.scale.range.slice();
    (_a = this.crossLines) == null ? void 0 : _a.forEach((crossLine) => {
      this.initCrossLine(crossLine);
    });
  }
  updateRange() {
    var _a;
    const { range: rr, visibleRange: vr, scale: scale2 } = this;
    const span = (rr[1] - rr[0]) / (vr[1] - vr[0]);
    const shift = span * vr[0];
    const start = rr[0] - shift;
    scale2.range = [start, start + span];
    (_a = this.crossLines) == null ? void 0 : _a.forEach((crossLine) => {
      crossLine.clippedRange = [rr[0], rr[1]];
    });
  }
  setCrossLinesVisible(visible) {
    this.crossLineGroup.visible = visible;
  }
  attachAxis(axisNode, gridNode) {
    gridNode.appendChild(this.gridGroup);
    axisNode.appendChild(this.axisGroup);
    axisNode.appendChild(this.crossLineGroup);
  }
  detachAxis(axisNode, gridNode) {
    gridNode.removeChild(this.gridGroup);
    axisNode.removeChild(this.axisGroup);
    axisNode.removeChild(this.crossLineGroup);
  }
  /**
   * Checks if a point or an object is in range.
   * @param x A point (or object's starting point).
   * @param width Object's width.
   * @param tolerance Expands the range on both ends by this amount.
   */
  inRange(x, width = 0, tolerance = 0) {
    const min = Math.min(...this.range);
    const max = Math.max(...this.range);
    return x + width >= min - tolerance && x <= max + tolerance;
  }
  onLabelFormatChange(ticks, format2) {
    const { scale: scale2, fractionDigits } = this;
    const logScale = scale2 instanceof LogScale;
    const defaultLabelFormatter = !logScale && fractionDigits > 0 ? (x) => typeof x === "number" ? x.toFixed(fractionDigits) : String(x) : (x) => String(x);
    if (format2 && scale2 && scale2.tickFormat) {
      try {
        this.labelFormatter = scale2.tickFormat({ ticks, specifier: format2 });
      } catch (e) {
        this.labelFormatter = defaultLabelFormatter;
        Logger.warnOnce(`the axis label format string ${format2} is invalid. No formatting will be applied`);
      }
    } else {
      this.labelFormatter = defaultLabelFormatter;
    }
  }
  setDomain() {
    const {
      scale: scale2,
      dataDomain: { domain }
    } = this;
    scale2.domain = domain;
  }
  setTickInterval(interval) {
    var _a;
    this.scale.interval = (_a = this.tick.interval) != null ? _a : interval;
  }
  setTickCount(count2, minTickCount, maxTickCount) {
    const { scale: scale2 } = this;
    if (!(count2 && ContinuousScale.is(scale2))) {
      return;
    }
    if (typeof count2 === "number") {
      scale2.tickCount = count2;
      scale2.minTickCount = minTickCount != null ? minTickCount : 0;
      scale2.maxTickCount = maxTickCount != null ? maxTickCount : Infinity;
      return;
    }
    if (scale2 instanceof TimeScale$1) {
      this.setTickInterval(count2);
    }
  }
  set gridLength(value) {
    var _a;
    if (this._gridLength && !value || !this._gridLength && value) {
      this.gridLineGroupSelection.clear();
    }
    this._gridLength = value;
    (_a = this.crossLines) == null ? void 0 : _a.forEach((crossLine) => {
      this.initCrossLine(crossLine);
    });
  }
  get gridLength() {
    return this._gridLength;
  }
  createTick() {
    return new AxisTick();
  }
  createLabel() {
    return new AxisLabel();
  }
  checkAxisHover(event) {
    const bbox = this.computeBBox();
    const isInAxis = bbox.containsPoint(event.offsetX, event.offsetY);
    if (!isInAxis)
      return;
    this.moduleCtx.chartEventManager.axisHover(this.id, this.direction);
  }
  /**
   * Creates/removes/updates the scene graph nodes that constitute the axis.
   */
  update(primaryTickCount) {
    if (!this.tickGenerationResult) {
      return;
    }
    const { rotation, parallelFlipRotation, regularFlipRotation } = this.calculateRotations();
    const sideFlag = this.label.getSideFlag();
    this.updatePosition();
    const lineData = this.getAxisLineCoordinates();
    const _a = this.tickGenerationResult, { tickData, combinedRotation, textBaseline, textAlign } = _a, ticksResult = __objRest(_a, ["tickData", "combinedRotation", "textBaseline", "textAlign"]);
    const previousTicks = this.tickLabelGroupSelection.nodes().map((node) => node.datum.tickId);
    this.updateSelections(lineData, tickData.ticks, {
      combinedRotation,
      textAlign,
      textBaseline,
      range: this.scale.range
    });
    if (this.animationManager.isSkipped()) {
      this.resetSelectionNodes();
    } else {
      const diff2 = this.calculateUpdateDiff(previousTicks, tickData);
      this.animationState.transition("update", diff2);
    }
    this.updateAxisLine();
    this.updateLabels();
    this.updateVisibility();
    this.updateGridLines(sideFlag);
    this.updateTickLines();
    this.updateTitle({ anyTickVisible: tickData.ticks.length > 0 });
    this.updateCrossLines({ rotation, parallelFlipRotation, regularFlipRotation });
    this.updateLayoutState();
    primaryTickCount = ticksResult.primaryTickCount;
    return primaryTickCount;
  }
  getAxisLineCoordinates() {
    const {
      range: [start, end]
    } = this;
    const x = 0;
    const y1 = Math.min(start, end);
    const y2 = Math.max(start, end);
    return { x, y1, y2 };
  }
  getTickLineCoordinates(datum) {
    const { label } = this;
    const sideFlag = label.getSideFlag();
    const x = sideFlag * this.getTickSize();
    const x1 = Math.min(0, x);
    const x2 = x1 + Math.abs(x);
    const y = datum.translationY;
    return { x1, x2, y };
  }
  getTickLabelProps(datum, params) {
    const { label } = this;
    const { combinedRotation, textBaseline, textAlign, range: range3 } = params;
    const text = datum.tickLabel;
    const sideFlag = label.getSideFlag();
    const labelX = sideFlag * (this.getTickSize() + label.padding + this.seriesAreaPadding);
    const visible = text !== "" && text != void 0;
    return {
      tickId: datum.tickId,
      translationY: datum.translationY,
      fill: label.color,
      fontFamily: label.fontFamily,
      fontSize: label.fontSize,
      fontStyle: label.fontStyle,
      fontWeight: label.fontWeight,
      rotation: combinedRotation,
      rotationCenterX: labelX,
      text,
      textAlign,
      textBaseline,
      visible,
      x: labelX,
      y: 0,
      range: range3
    };
  }
  getTickSize() {
    return this.tick.enabled ? this.tick.size : this.createTick().size;
  }
  setTitleProps(caption, params) {
    var _a;
    const { title } = this;
    if (!title) {
      caption.enabled = false;
      return;
    }
    caption.color = title.color;
    caption.fontFamily = title.fontFamily;
    caption.fontSize = title.fontSize;
    caption.fontStyle = title.fontStyle;
    caption.fontWeight = title.fontWeight;
    caption.enabled = title.enabled;
    caption.wrapping = title.wrapping;
    if (title.enabled) {
      const titleNode = caption.node;
      const padding = ((_a = title.spacing) != null ? _a : 0) + params.spacing;
      const sideFlag = this.label.getSideFlag();
      const parallelFlipRotation = normalizeAngle360(this.rotation);
      const titleRotationFlag = sideFlag === -1 && parallelFlipRotation > Math.PI && parallelFlipRotation < Math.PI * 2 ? -1 : 1;
      const rotation = titleRotationFlag * sideFlag * Math.PI / 2;
      const textBaseline = titleRotationFlag === 1 ? "bottom" : "top";
      const { range: range3 } = this;
      const x = Math.floor(titleRotationFlag * sideFlag * (range3[0] + range3[1]) / 2);
      const y = sideFlag === -1 ? Math.floor(titleRotationFlag * -padding) : Math.floor(-padding);
      const { callbackCache } = this.moduleCtx;
      const { formatter = (params2) => params2.defaultValue } = title;
      const text = callbackCache.call(formatter, this.getTitleFormatterParams());
      titleNode.setProperties({
        rotation,
        text,
        textBaseline,
        visible: true,
        x,
        y
      });
    }
  }
  calculateLayout(primaryTickCount) {
    var _b;
    const { rotation, parallelFlipRotation, regularFlipRotation } = this.calculateRotations();
    const sideFlag = this.label.getSideFlag();
    const labelX = sideFlag * (this.getTickSize() + this.label.padding + this.seriesAreaPadding);
    this.updateScale();
    this.tickGenerationResult = this.generateTicks({
      primaryTickCount,
      parallelFlipRotation,
      regularFlipRotation,
      labelX,
      sideFlag
    });
    this.updateLayoutState();
    const _a = this.tickGenerationResult, { tickData, combinedRotation, textBaseline, textAlign } = _a, ticksResult = __objRest(_a, ["tickData", "combinedRotation", "textBaseline", "textAlign"]);
    const boxes = [];
    const { x, y1, y2 } = this.getAxisLineCoordinates();
    const lineBox = new BBox(
      x + Math.min(sideFlag * this.seriesAreaPadding, 0),
      y1,
      this.seriesAreaPadding,
      y2 - y1
    );
    boxes.push(lineBox);
    const { tick } = this;
    if (tick.enabled) {
      tickData.ticks.forEach((datum) => {
        const { x1, x2, y } = this.getTickLineCoordinates(datum);
        const tickLineBox = new BBox(x1, y, x2 - x1, 0);
        boxes.push(tickLineBox);
      });
    }
    const { label } = this;
    if (label.enabled) {
      const tempText = new Text();
      tickData.ticks.forEach((datum) => {
        const labelProps = this.getTickLabelProps(datum, {
          combinedRotation,
          textAlign,
          textBaseline,
          range: this.scale.range
        });
        if (!labelProps.visible) {
          return;
        }
        tempText.setProperties(__spreadProps(__spreadValues({}, labelProps), {
          translationY: Math.round(datum.translationY)
        }));
        const box = tempText.computeTransformedBBox();
        if (box) {
          boxes.push(box);
        }
      });
    }
    const getTransformBox = (bbox2) => {
      const matrix = new Matrix();
      const {
        rotation: axisRotation,
        translationX,
        translationY,
        rotationCenterX,
        rotationCenterY
      } = this.getAxisTransform();
      Matrix.updateTransformMatrix(matrix, 1, 1, axisRotation, translationX, translationY, {
        scalingCenterX: 0,
        scalingCenterY: 0,
        rotationCenterX,
        rotationCenterY
      });
      return matrix.transformBBox(bbox2);
    };
    const { title } = this;
    if (title == null ? void 0 : title.enabled) {
      const caption = new Caption();
      const spacing = BBox.merge(boxes).width;
      this.setTitleProps(caption, { spacing });
      const titleNode = caption.node;
      const titleBox = titleNode.computeTransformedBBox();
      if (titleBox) {
        boxes.push(titleBox);
      }
    }
    const bbox = BBox.merge(boxes);
    const transformedBBox = getTransformBox(bbox);
    const anySeriesActive = this.isAnySeriesActive();
    (_b = this.crossLines) == null ? void 0 : _b.forEach((crossLine) => {
      var _a2;
      crossLine.sideFlag = -sideFlag;
      crossLine.direction = rotation === -Math.PI / 2 ? "x" /* X */ : "y" /* Y */;
      if (crossLine instanceof CartesianCrossLine) {
        crossLine.label.parallel = (_a2 = crossLine.label.parallel) != null ? _a2 : this.label.parallel;
      }
      crossLine.parallelFlipRotation = parallelFlipRotation;
      crossLine.regularFlipRotation = regularFlipRotation;
      crossLine.calculateLayout(anySeriesActive, this.reverse);
    });
    primaryTickCount = ticksResult.primaryTickCount;
    return { primaryTickCount, bbox: transformedBBox };
  }
  updateLayoutState() {
    this.layout.label = {
      fractionDigits: this.fractionDigits,
      padding: this.label.padding,
      format: this.label.format
    };
  }
  updateScale() {
    this.updateRange();
    this.calculateDomain();
    this.setDomain();
    this.setTickInterval(this.tick.interval);
    const { scale: scale2, nice } = this;
    if (!ContinuousScale.is(scale2)) {
      return;
    }
    scale2.nice = nice;
    scale2.update();
  }
  calculateRotations() {
    const rotation = toRadians(this.rotation);
    const parallelFlipRotation = normalizeAngle360(rotation);
    const regularFlipRotation = normalizeAngle360(rotation - Math.PI / 2);
    return { rotation, parallelFlipRotation, regularFlipRotation };
  }
  generateTicks({
    primaryTickCount,
    parallelFlipRotation,
    regularFlipRotation,
    labelX,
    sideFlag
  }) {
    var _a;
    const {
      scale: scale2,
      tick,
      label: { parallel, rotation, fontFamily, fontSize, fontStyle, fontWeight }
    } = this;
    const secondaryAxis = primaryTickCount !== void 0;
    const { defaultRotation, configuredRotation, parallelFlipFlag, regularFlipFlag } = calculateLabelRotation({
      rotation,
      parallel,
      regularFlipRotation,
      parallelFlipRotation
    });
    const initialRotation = configuredRotation + defaultRotation;
    const labelMatrix = new Matrix();
    const { maxTickCount } = this.estimateTickCount({
      minSpacing: tick.minSpacing,
      maxSpacing: (_a = tick.maxSpacing) != null ? _a : NaN
    });
    const continuous = ContinuousScale.is(scale2);
    const maxIterations = !continuous || isNaN(maxTickCount) ? 10 : maxTickCount;
    let textAlign = getTextAlign(parallel, configuredRotation, 0, sideFlag, regularFlipFlag);
    const textBaseline = getTextBaseline(parallel, configuredRotation, sideFlag, parallelFlipFlag);
    const textProps = {
      fontFamily,
      fontSize,
      fontStyle,
      fontWeight,
      textBaseline,
      textAlign
    };
    let tickData = {
      rawTicks: [],
      ticks: [],
      labelCount: 0
    };
    let index = 0;
    let autoRotation = 0;
    let labelOverlap = true;
    let terminate = false;
    while (labelOverlap && index <= maxIterations) {
      if (terminate) {
        break;
      }
      autoRotation = 0;
      textAlign = getTextAlign(parallel, configuredRotation, 0, sideFlag, regularFlipFlag);
      const tickStrategies = this.getTickStrategies({ secondaryAxis, index });
      for (const strategy of tickStrategies) {
        ({ tickData, index, autoRotation, terminate } = strategy({
          index,
          tickData,
          textProps,
          labelOverlap,
          terminate,
          primaryTickCount
        }));
        const rotated = configuredRotation !== 0 || autoRotation !== 0;
        const rotation2 = initialRotation + autoRotation;
        textAlign = getTextAlign(parallel, configuredRotation, autoRotation, sideFlag, regularFlipFlag);
        labelOverlap = this.checkLabelOverlap(rotation2, rotated, labelMatrix, tickData.ticks, labelX, __spreadProps(__spreadValues({}, textProps), {
          textAlign
        }));
      }
    }
    const combinedRotation = defaultRotation + configuredRotation + autoRotation;
    if (!secondaryAxis && tickData.rawTicks.length > 0) {
      primaryTickCount = tickData.rawTicks.length;
    }
    return { tickData, primaryTickCount, combinedRotation, textBaseline, textAlign };
  }
  getTickStrategies({ index, secondaryAxis }) {
    const { scale: scale2, label, tick } = this;
    const continuous = ContinuousScale.is(scale2);
    const avoidLabelCollisions = label.enabled && label.avoidCollisions;
    const filterTicks = !continuous && index !== 0 && avoidLabelCollisions;
    const autoRotate = label.autoRotate === true && label.rotation === void 0;
    const strategies = [];
    let tickGenerationType;
    if (this.tick.values) {
      tickGenerationType = 3 /* VALUES */;
    } else if (secondaryAxis) {
      tickGenerationType = 1 /* CREATE_SECONDARY */;
    } else if (filterTicks) {
      tickGenerationType = 2 /* FILTER */;
    } else {
      tickGenerationType = 0 /* CREATE */;
    }
    const tickGenerationStrategy = ({ index: index2, tickData, primaryTickCount, terminate }) => this.createTickData(tickGenerationType, index2, tickData, terminate, primaryTickCount);
    strategies.push(tickGenerationStrategy);
    if (!continuous && !isNaN(tick.minSpacing)) {
      const tickFilterStrategy = ({ index: index2, tickData, primaryTickCount, terminate }) => this.createTickData(2 /* FILTER */, index2, tickData, terminate, primaryTickCount);
      strategies.push(tickFilterStrategy);
    }
    if (!avoidLabelCollisions) {
      return strategies;
    }
    if (label.autoWrap) {
      const autoWrapStrategy = ({ index: index2, tickData, textProps }) => this.wrapLabels(tickData, index2, textProps);
      strategies.push(autoWrapStrategy);
    } else if (autoRotate) {
      const autoRotateStrategy = ({ index: index2, tickData, labelOverlap, terminate }) => ({
        index: index2,
        tickData,
        autoRotation: this.getAutoRotation(labelOverlap),
        terminate
      });
      strategies.push(autoRotateStrategy);
    }
    return strategies;
  }
  createTickData(tickGenerationType, index, tickData, terminate, primaryTickCount) {
    var _a;
    const { scale: scale2, tick } = this;
    const { maxTickCount, minTickCount, defaultTickCount } = this.estimateTickCount({
      minSpacing: tick.minSpacing,
      maxSpacing: (_a = tick.maxSpacing) != null ? _a : NaN
    });
    const continuous = ContinuousScale.is(scale2);
    const maxIterations = !continuous || isNaN(maxTickCount) ? 10 : maxTickCount;
    let tickCount = continuous ? Math.max(defaultTickCount - index, minTickCount) : maxTickCount;
    const regenerateTicks = tick.interval === void 0 && tick.values === void 0 && tickCount > minTickCount && (continuous || tickGenerationType === 2 /* FILTER */);
    let unchanged = true;
    while (unchanged && index <= maxIterations) {
      const prevTicks = tickData.rawTicks;
      tickCount = continuous ? Math.max(defaultTickCount - index, minTickCount) : maxTickCount;
      const { rawTicks, ticks, labelCount } = this.getTicks({
        tickGenerationType,
        previousTicks: prevTicks,
        tickCount,
        minTickCount,
        maxTickCount,
        primaryTickCount
      });
      tickData.rawTicks = rawTicks;
      tickData.ticks = ticks;
      tickData.labelCount = labelCount;
      unchanged = regenerateTicks ? areArrayNumbersEqual(rawTicks, prevTicks) : false;
      index++;
    }
    const shouldTerminate = tick.interval !== void 0 || tick.values !== void 0;
    terminate || (terminate = shouldTerminate);
    return { tickData, index, autoRotation: 0, terminate };
  }
  checkLabelOverlap(rotation, rotated, labelMatrix, tickData, labelX, textProps) {
    Matrix.updateTransformMatrix(labelMatrix, 1, 1, rotation, 0, 0);
    const labelData = this.createLabelData(tickData, labelX, textProps, labelMatrix);
    const labelSpacing = getLabelSpacing(this.label.minSpacing, rotated);
    return axisLabelsOverlap(labelData, labelSpacing);
  }
  createLabelData(tickData, labelX, textProps, labelMatrix) {
    const labelData = [];
    for (const tickDatum of tickData) {
      const { tickLabel, translationY } = tickDatum;
      if (tickLabel === "" || tickLabel == void 0) {
        continue;
      }
      const lines = splitText(tickLabel);
      const { width, height } = measureText(lines, labelX, translationY, textProps);
      const bbox = new BBox(labelX, translationY, width, height);
      const labelDatum = calculateLabelBBox(tickLabel, bbox, labelX, translationY, labelMatrix);
      labelData.push(labelDatum);
    }
    return labelData;
  }
  getAutoRotation(labelOverlap) {
    var _a;
    return labelOverlap ? normalizeAngle360(toRadians((_a = this.label.autoRotateAngle) != null ? _a : 0)) : 0;
  }
  getTicks({
    tickGenerationType,
    previousTicks,
    tickCount,
    minTickCount,
    maxTickCount,
    primaryTickCount
  }) {
    var _a;
    const { range: range3, scale: scale2, visibleRange } = this;
    let rawTicks = [];
    switch (tickGenerationType) {
      case 3 /* VALUES */:
        if (ContinuousScale.is(scale2)) {
          const scaleDomain = scale2.getDomain();
          const start2 = scale2.fromDomain(scaleDomain[0]);
          const stop = scale2.fromDomain(scaleDomain[1]);
          const d0 = Math.min(start2, stop);
          const d1 = Math.max(start2, stop);
          rawTicks = this.tick.values.filter((value) => value >= d0 && value <= d1).sort((a, b) => a - b);
        } else {
          rawTicks = this.tick.values;
        }
        break;
      case 1 /* CREATE_SECONDARY */:
        rawTicks = this.updateSecondaryAxisTicks(primaryTickCount);
        break;
      case 2 /* FILTER */:
        rawTicks = this.filterTicks(previousTicks, tickCount);
        break;
      default:
        rawTicks = this.createTicks(tickCount, minTickCount, maxTickCount);
        break;
    }
    this.fractionDigits = rawTicks.fractionDigits >= 0 ? rawTicks.fractionDigits : 0;
    this.onLabelFormatChange(rawTicks, this.label.format);
    const halfBandwidth = ((_a = scale2.bandwidth) != null ? _a : 0) / 2;
    const ticks = [];
    let labelCount = 0;
    const tickIdCounts = /* @__PURE__ */ new Map();
    const start = Math.max(0, Math.floor(visibleRange[0] * rawTicks.length));
    const end = Math.min(rawTicks.length, Math.ceil(visibleRange[1] * rawTicks.length));
    for (let i = start; i < end; i++) {
      const rawTick = rawTicks[i];
      const translationY = scale2.convert(rawTick) + halfBandwidth;
      if (range3.length > 0 && !this.inRange(translationY, 0, 1e-3))
        continue;
      const tickLabel = this.formatTick(rawTick, i);
      let tickId = tickLabel;
      if (tickIdCounts.has(tickId)) {
        const count2 = tickIdCounts.get(tickId);
        tickIdCounts.set(tickId, count2 + 1);
        tickId = `${tickId}_${count2}`;
      } else {
        tickIdCounts.set(tickId, 1);
      }
      ticks.push({ tick: rawTick, tickId, tickLabel, translationY });
      if (tickLabel === "" || tickLabel == void 0) {
        continue;
      }
      labelCount++;
    }
    return { rawTicks, ticks, labelCount };
  }
  filterTicks(ticks, tickCount) {
    var _a;
    const tickSpacing = !isNaN(this.tick.minSpacing) || !isNaN((_a = this.tick.maxSpacing) != null ? _a : NaN);
    const keepEvery = tickSpacing ? Math.ceil(ticks.length / tickCount) : 2;
    return ticks.filter((_, i) => i % keepEvery === 0);
  }
  createTicks(tickCount, minTickCount, maxTickCount) {
    var _a, _b, _c;
    this.setTickCount(tickCount, minTickCount, maxTickCount);
    return (_c = (_b = (_a = this.scale).ticks) == null ? void 0 : _b.call(_a)) != null ? _c : [];
  }
  estimateTickCount({ minSpacing, maxSpacing }) {
    const { minRect } = this;
    const rangeWithBleed = this.calculateRangeWithBleed();
    const defaultMinSpacing = Math.max(
      this.defaultTickMinSpacing,
      rangeWithBleed / ContinuousScale.defaultMaxTickCount
    );
    let clampMaxTickCount = !isNaN(maxSpacing);
    if (isNaN(minSpacing)) {
      minSpacing = defaultMinSpacing;
    }
    if (isNaN(maxSpacing)) {
      maxSpacing = rangeWithBleed;
    }
    if (minSpacing > maxSpacing) {
      if (minSpacing === defaultMinSpacing) {
        minSpacing = maxSpacing;
      } else {
        maxSpacing = minSpacing;
      }
    }
    const minRectDistance = minRect ? this.direction === "x" /* X */ ? minRect.width : minRect.height : 1;
    clampMaxTickCount && (clampMaxTickCount = minRectDistance < defaultMinSpacing);
    const maxTickCount = clamp2(
      1,
      Math.floor(rangeWithBleed / minSpacing),
      clampMaxTickCount ? Math.floor(rangeWithBleed / minRectDistance) : Infinity
    );
    const minTickCount = Math.min(maxTickCount, Math.ceil(rangeWithBleed / maxSpacing));
    const defaultTickCount = clamp2(minTickCount, ContinuousScale.defaultTickCount, maxTickCount);
    return { minTickCount, maxTickCount, defaultTickCount };
  }
  updateVisibility() {
    if (this.moduleCtx.animationManager.isSkipped()) {
      this.resetSelectionNodes();
    }
    this.tickLineGroup.visible = this.tick.enabled;
    this.gridLineGroup.visible = this.gridLine.enabled;
    this.tickLabelGroup.visible = this.label.enabled;
  }
  updateCrossLines({
    rotation,
    parallelFlipRotation,
    regularFlipRotation
  }) {
    var _a;
    const sideFlag = this.label.getSideFlag();
    const anySeriesActive = this.isAnySeriesActive();
    (_a = this.crossLines) == null ? void 0 : _a.forEach((crossLine) => {
      var _a2;
      crossLine.sideFlag = -sideFlag;
      crossLine.direction = rotation === -Math.PI / 2 ? "x" /* X */ : "y" /* Y */;
      if (crossLine instanceof CartesianCrossLine) {
        crossLine.label.parallel = (_a2 = crossLine.label.parallel) != null ? _a2 : this.label.parallel;
      }
      crossLine.parallelFlipRotation = parallelFlipRotation;
      crossLine.regularFlipRotation = regularFlipRotation;
      crossLine.update(anySeriesActive);
    });
  }
  updateTickLines() {
    const { tick, label } = this;
    const sideFlag = label.getSideFlag();
    this.tickLineGroupSelection.each((line) => {
      line.strokeWidth = tick.width;
      line.stroke = tick.color;
      line.x1 = sideFlag * this.getTickSize();
      line.x2 = 0;
    });
  }
  calculateAvailableRange() {
    const { range: range3 } = this;
    const min = Math.min(...range3);
    const max = Math.max(...range3);
    return max - min;
  }
  /**
   * Calculates the available range with an additional "bleed" beyond the canvas that encompasses the full axis when
   * the visible range is only a portion of the axis.
   */
  calculateRangeWithBleed() {
    const { visibleRange } = this;
    const visibleScale = 1 / (visibleRange[1] - visibleRange[0]);
    return round(this.calculateAvailableRange() * visibleScale, 2);
  }
  calculateDomain() {
    if (this.linkedTo) {
      this.dataDomain = this.linkedTo.dataDomain;
    } else {
      const visibleSeries = this.boundSeries.filter((s) => this.includeInvisibleDomains || s.isEnabled());
      const domains = visibleSeries.flatMap((series) => series.getDomain(this.direction));
      const { domain, clipped } = this.normaliseDataDomain(domains);
      this.dataDomain = { domain: this.reverse ? [...domain].reverse() : domain, clipped };
    }
  }
  getAxisTransform() {
    return {
      rotation: toRadians(this.rotation),
      rotationCenterX: 0,
      rotationCenterY: 0,
      translationX: Math.floor(this.translation.x),
      translationY: Math.floor(this.translation.y)
    };
  }
  updatePosition() {
    const { crossLineGroup, axisGroup, gridGroup, translation, gridLineGroupSelection, gridPadding, gridLength } = this;
    const { rotation } = this.calculateRotations();
    const sideFlag = this.label.getSideFlag();
    const translationX = Math.floor(translation.x);
    const translationY = Math.floor(translation.y);
    crossLineGroup.setProperties({ rotation, translationX, translationY });
    axisGroup.datum = this.getAxisTransform();
    gridGroup.setProperties({ rotation, translationX, translationY });
    gridLineGroupSelection.each((line) => {
      line.x1 = gridPadding;
      line.x2 = -sideFlag * gridLength + gridPadding;
    });
  }
  updateSecondaryAxisTicks(_primaryTickCount) {
    throw new Error("AG Charts - unexpected call to updateSecondaryAxisTicks() - check axes configuration.");
  }
  updateSelections(lineData, data, params) {
    this.lineNode.datum = lineData;
    this.gridLineGroupSelection.update(
      this.gridLength ? data : [],
      (group2) => group2.append(new Line({ tag: 2 /* GridLine */ })),
      (datum) => datum.tickId
    );
    this.tickLineGroupSelection.update(
      data,
      (group2) => group2.appendChild(new Line({ tag: 0 /* TickLine */ })),
      (datum) => datum.tickId
    );
    this.tickLabelGroupSelection.update(
      data.map((d) => this.getTickLabelProps(d, params)),
      (group2) => group2.appendChild(new Text({ tag: 1 /* TickLabel */ })),
      (datum) => datum.tickId
    );
  }
  updateAxisLine() {
    const { line } = this;
    const strokeWidth = line.enabled ? line.width : 0;
    this.lineNode.setProperties({
      stroke: line.color,
      strokeWidth
    });
  }
  updateGridLines(sideFlag) {
    const {
      gridLine: { style, width },
      gridPadding,
      gridLength
    } = this;
    if (gridLength === 0 || style.length === 0) {
      return;
    }
    this.gridLineGroupSelection.each((line, _, index) => {
      const { stroke, lineDash } = style[index % style.length];
      line.setProperties({
        x1: gridPadding,
        x2: -sideFlag * gridLength + gridPadding,
        fill: void 0,
        stroke,
        strokeWidth: width,
        lineDash
      });
    });
  }
  updateLabels() {
    const { label } = this;
    if (!label.enabled) {
      return;
    }
    this.tickLabelGroupSelection.each((node, datum) => {
      node.setProperties(datum, [
        "fill",
        "fontFamily",
        "fontSize",
        "fontStyle",
        "fontWeight",
        "text",
        "textAlign",
        "textBaseline"
      ]);
    });
  }
  wrapLabels(tickData, index, labelProps) {
    const { parallel, maxWidth, maxHeight } = this.label;
    let defaultMaxWidth = this.maxThickness;
    let defaultMaxHeight = Math.round(this.calculateAvailableRange() / tickData.labelCount);
    if (parallel) {
      [defaultMaxWidth, defaultMaxHeight] = [defaultMaxHeight, defaultMaxWidth];
    }
    tickData.ticks.forEach((tickDatum) => {
      const { text } = Text.wrap(
        tickDatum.tickLabel,
        maxWidth != null ? maxWidth : defaultMaxWidth,
        maxHeight != null ? maxHeight : defaultMaxHeight,
        labelProps,
        "hyphenate"
      );
      tickDatum.tickLabel = text;
    });
    return { tickData, index, autoRotation: 0, terminate: true };
  }
  updateTitle(params) {
    const { rotation, title, _titleCaption, lineNode, tickLineGroup, tickLabelGroup } = this;
    if (!title) {
      _titleCaption.enabled = false;
      return;
    }
    let spacing = 0;
    if (title.enabled && params.anyTickVisible) {
      const tickBBox = Group.computeBBox([tickLineGroup, tickLabelGroup, lineNode]);
      const tickWidth = rotation === 0 ? tickBBox.width : tickBBox.height;
      spacing += tickWidth + (!this.tickLabelGroup.visible ? this.seriesAreaPadding : 0);
    }
    this.setTitleProps(_titleCaption, { spacing });
  }
  // For formatting (nice rounded) tick values.
  formatTick(datum, index) {
    var _a, _b;
    const {
      label,
      labelFormatter,
      fractionDigits,
      moduleCtx: { callbackCache }
    } = this;
    if (label.formatter) {
      const value = fractionDigits > 0 ? datum : String(datum);
      return (_a = callbackCache.call(label.formatter, {
        value,
        index,
        fractionDigits,
        formatter: labelFormatter
      })) != null ? _a : value;
    } else if (labelFormatter) {
      return (_b = callbackCache.call(labelFormatter, datum)) != null ? _b : String(datum);
    }
    return String(datum);
  }
  // For formatting arbitrary values between the ticks.
  formatDatum(datum) {
    return String(datum);
  }
  computeBBox() {
    return this.axisGroup.computeBBox();
  }
  initCrossLine(crossLine) {
    crossLine.scale = this.scale;
    crossLine.gridLength = this.gridLength;
  }
  isAnySeriesActive() {
    return this.boundSeries.some((s) => this.includeInvisibleDomains || s.isEnabled());
  }
  clipTickLines(x, y, width, height) {
    this.tickLineGroup.setClipRectInGroupCoordinateSpace(new BBox(x, y, width, height));
  }
  clipGrid(x, y, width, height) {
    this.gridGroup.setClipRectInGroupCoordinateSpace(new BBox(x, y, width, height));
  }
  calculatePadding(min, _max, reverse) {
    const start = reverse ? _max : min;
    return [Math.abs(start * 0.01), Math.abs(start * 0.01)];
  }
  getTitleFormatterParams() {
    var _a;
    const boundSeries = this.boundSeries.reduce((acc, next) => {
      const keys = next.getKeys(this.direction);
      const names = next.getNames(this.direction);
      for (let idx = 0; idx < keys.length; idx++) {
        acc.push({ key: keys[idx], name: names[idx] });
      }
      return acc;
    }, []);
    return {
      direction: this.direction,
      boundSeries,
      defaultValue: (_a = this.title) == null ? void 0 : _a.text
    };
  }
  normaliseDataDomain(d) {
    return { domain: d, clipped: false };
  }
  getLayoutState() {
    return __spreadValues({
      rect: this.computeBBox(),
      gridPadding: this.gridPadding,
      seriesAreaPadding: this.seriesAreaPadding,
      tickSize: this.getTickSize()
    }, this.layout);
  }
  getModuleMap() {
    return this.moduleMap;
  }
  createModuleContext() {
    var _a;
    (_a = this.axisContext) != null ? _a : this.axisContext = this.createAxisContext();
    return __spreadProps(__spreadValues({}, this.moduleCtx), { parent: this.axisContext });
  }
  createAxisContext() {
    return {
      axisId: this.id,
      direction: this.direction,
      continuous: ContinuousScale.is(this.scale),
      keys: () => this.boundSeries.flatMap((s) => s.getKeys(this.direction)),
      scaleValueFormatter: (specifier) => {
        var _a, _b;
        return (_b = (_a = this.scale).tickFormat) == null ? void 0 : _b.call(_a, { specifier });
      },
      scaleBandwidth: () => {
        var _a;
        return (_a = this.scale.bandwidth) != null ? _a : 0;
      },
      scaleConvert: (val) => this.scale.convert(val),
      scaleInvert: (val) => {
        var _a, _b;
        return (_b = (_a = this.scale).invert) == null ? void 0 : _b.call(_a, val);
      }
    };
  }
  animateReadyUpdate(diff2) {
    const { animationManager } = this.moduleCtx;
    const selectionCtx = prepareAxisAnimationContext(this);
    const fns = prepareAxisAnimationFunctions(selectionCtx);
    fromToMotion(this.id, "axis-group", animationManager, [this.axisGroup], fns.group);
    fromToMotion(this.id, "line", animationManager, [this.lineNode], fns.line);
    fromToMotion(
      this.id,
      "line-paths",
      animationManager,
      [this.gridLineGroupSelection, this.tickLineGroupSelection],
      fns.tick,
      (_, d) => d.tickId,
      diff2
    );
    fromToMotion(
      this.id,
      "tick-labels",
      animationManager,
      [this.tickLabelGroupSelection],
      fns.label,
      (_, d) => d.tickId,
      diff2
    );
  }
  resetSelectionNodes() {
    const { gridLineGroupSelection, tickLineGroupSelection, tickLabelGroupSelection, lineNode } = this;
    const selectionCtx = prepareAxisAnimationContext(this);
    resetMotion([this.axisGroup], resetAxisGroupFn());
    resetMotion([gridLineGroupSelection, tickLineGroupSelection], resetAxisSelectionFn(selectionCtx));
    resetMotion([tickLabelGroupSelection], resetAxisLabelSelectionFn());
    resetMotion([lineNode], resetAxisLineSelectionFn());
  }
  calculateUpdateDiff(previous, tickData) {
    const added = /* @__PURE__ */ new Set();
    const removed = /* @__PURE__ */ new Set();
    const tickCount = Math.max(previous.length, tickData.ticks.length);
    for (let i = 0; i < tickCount; i++) {
      const tickDatum = tickData.ticks[i];
      const prev = previous[i];
      const tick = tickDatum == null ? void 0 : tickDatum.tickId;
      if (prev === tick) {
        continue;
      }
      if (removed.has(tick)) {
        removed.delete(tick);
      } else if (tick) {
        added.add(tick);
      }
      if (added.has(prev)) {
        added.delete(prev);
      } else if (prev) {
        removed.add(prev);
      }
    }
    return {
      changed: added.size > 0 || removed.size > 0,
      added: [...added.values()],
      removed: [...removed.values()]
    };
  }
  isReversed() {
    return !!this.reverse;
  }
};
_Axis.defaultTickMinSpacing = 50;
__decorateClass([
  Validate(BOOLEAN)
], _Axis.prototype, "nice", 2);
__decorateClass([
  Validate(BOOLEAN, { optional: true })
], _Axis.prototype, "reverse", 2);
__decorateClass([
  Validate(STRING_ARRAY)
], _Axis.prototype, "keys", 2);
__decorateClass([
  Validate(predicateWithMessage((title) => typeof title == "object", "Title object"), { optional: true })
], _Axis.prototype, "title", 2);
var Axis = _Axis;

// packages/ag-charts-community/src/chart/axis/cartesianAxisLabel.ts
var CartesianAxisLabel = class extends AxisLabel {
  constructor() {
    super(...arguments);
    this.autoRotateAngle = 335;
  }
};
__decorateClass([
  Validate(BOOLEAN, { optional: true })
], CartesianAxisLabel.prototype, "autoRotate", 2);
__decorateClass([
  Validate(DEGREE)
], CartesianAxisLabel.prototype, "autoRotateAngle", 2);

// packages/ag-charts-community/src/chart/axis/cartesianAxis.ts
var CartesianAxis = class extends Axis {
  constructor() {
    super(...arguments);
    this.thickness = 0;
    this.position = "left";
  }
  get direction() {
    return ["top", "bottom"].includes(this.position) ? "x" /* X */ : "y" /* Y */;
  }
  updateDirection() {
    switch (this.position) {
      case "top":
        this.rotation = -90;
        this.label.mirrored = true;
        this.label.parallel = true;
        break;
      case "right":
        this.rotation = 0;
        this.label.mirrored = true;
        this.label.parallel = false;
        break;
      case "bottom":
        this.rotation = -90;
        this.label.mirrored = false;
        this.label.parallel = true;
        break;
      case "left":
        this.rotation = 0;
        this.label.mirrored = false;
        this.label.parallel = false;
        break;
    }
    if (this.axisContext) {
      this.axisContext.position = this.position;
      this.axisContext.direction = this.direction;
    }
  }
  update(primaryTickCount) {
    this.updateDirection();
    return super.update(primaryTickCount);
  }
  calculateLayout(primaryTickCount) {
    this.updateDirection();
    return super.calculateLayout(primaryTickCount);
  }
  createAxisContext() {
    return __spreadProps(__spreadValues({}, super.createAxisContext()), {
      position: this.position
    });
  }
  assignCrossLineArrayConstructor(crossLines) {
    assignJsonApplyConstructedArray(crossLines, CartesianCrossLine);
  }
  createLabel() {
    return new CartesianAxisLabel();
  }
};
__decorateClass([
  Validate(POSITIVE_NUMBER)
], CartesianAxis.prototype, "thickness", 2);
__decorateClass([
  Validate(POSITION)
], CartesianAxis.prototype, "position", 2);

// packages/ag-charts-community/src/chart/axis/categoryAxis.ts
var CategoryAxis = class extends CartesianAxis {
  constructor(moduleCtx) {
    super(moduleCtx, new BandScale$5());
    this._paddingOverrideEnabled = false;
    this.groupPaddingInner = 0.1;
    this.includeInvisibleDomains = true;
  }
  set paddingInner(value) {
    this._paddingOverrideEnabled = true;
    this.scale.paddingInner = value;
  }
  get paddingInner() {
    this._paddingOverrideEnabled = true;
    return this.scale.paddingInner;
  }
  set paddingOuter(value) {
    this.scale.paddingOuter = value;
  }
  get paddingOuter() {
    return this.scale.paddingOuter;
  }
  normaliseDataDomain(d) {
    const domain = [];
    const uniqueValues = /* @__PURE__ */ new Set();
    for (const v of d) {
      const key = v instanceof Date ? v.getTime() : v;
      if (!uniqueValues.has(key)) {
        uniqueValues.add(key);
        domain.push(v);
      }
    }
    return { domain, clipped: false };
  }
  calculateDomain() {
    if (!this._paddingOverrideEnabled) {
      const paddings = this.boundSeries.map((s) => {
        var _a;
        return (_a = s.getBandScalePadding) == null ? void 0 : _a.call(s);
      }).filter((p) => p != null);
      if (paddings.length > 0) {
        this.scale.paddingInner = Math.min(...paddings.map((p) => p.inner));
        this.scale.paddingOuter = Math.max(...paddings.map((p) => p.outer));
      }
    }
    return super.calculateDomain();
  }
};
CategoryAxis.className = "CategoryAxis";
CategoryAxis.type = "category";
__decorateClass([
  Validate(RATIO)
], CategoryAxis.prototype, "groupPaddingInner", 2);

// packages/ag-charts-community/src/chart/axis/tree.ts
var TreeNode = class {
  // current number in sibling group (index)
  constructor(label = "", parent, number = 0) {
    this.x = 0;
    this.y = 0;
    this.subtreeLeft = NaN;
    this.subtreeRight = NaN;
    this.screenX = 0;
    this.screenY = 0;
    this.children = [];
    this.leafCount = 0;
    this.prelim = 0;
    this.mod = 0;
    this.ancestor = this;
    this.change = 0;
    this.shift = 0;
    this.label = label;
    this.parent = parent;
    this.depth = parent ? parent.depth + 1 : 0;
    this.number = number;
  }
  getLeftSibling() {
    return this.number > 0 && this.parent ? this.parent.children[this.number - 1] : void 0;
  }
  getLeftmostSibling() {
    return this.number > 0 && this.parent ? this.parent.children[0] : void 0;
  }
  // traverse the left contour of a subtree, return the successor of v on this contour
  nextLeft() {
    return this.children ? this.children[0] : this.thread;
  }
  // traverse the right contour of a subtree, return the successor of v on this contour
  nextRight() {
    return this.children ? this.children[this.children.length - 1] : this.thread;
  }
  getSiblings() {
    return this.parent ? this.parent.children.filter((_, i) => i !== this.number) : [];
  }
};
function ticksToTree(ticks, pad2 = true) {
  const root = new TreeNode();
  let depth = 0;
  if (pad2) {
    ticks.forEach((tick) => depth = Math.max(depth, tick.labels.length));
  }
  ticks.forEach((tick) => {
    if (pad2) {
      while (tick.labels.length < depth) {
        tick.labels.unshift("");
      }
    }
    insertTick(root, tick);
  });
  return root;
}
function insertTick(root, tick) {
  const pathParts = tick.labels.slice().reverse();
  const lastPartIndex = pathParts.length - 1;
  pathParts.forEach((pathPart, partIndex) => {
    const children = root.children;
    const existingNode = children.find((child) => child.label === pathPart);
    const isNotLeaf = partIndex !== lastPartIndex;
    if (existingNode && isNotLeaf) {
      root = existingNode;
    } else {
      const node = new TreeNode(pathPart, root);
      node.number = children.length;
      children.push(node);
      if (isNotLeaf) {
        root = node;
      }
    }
  });
}
function moveSubtree(wm, wp, shift) {
  const subtrees = wp.number - wm.number;
  const ratio = shift / subtrees;
  wp.change -= ratio;
  wp.shift += shift;
  wm.change += ratio;
  wp.prelim += shift;
  wp.mod += shift;
}
function ancestor(vim, v, defaultAncestor) {
  return v.getSiblings().indexOf(vim.ancestor) >= 0 ? vim.ancestor : defaultAncestor;
}
function executeShifts(v) {
  const children = v.children;
  if (children) {
    let shift = 0;
    let change = 0;
    for (let i = children.length - 1; i >= 0; i--) {
      const w = children[i];
      w.prelim += shift;
      w.mod += shift;
      change += w.change;
      shift += w.shift + change;
    }
  }
}
function apportion(v, defaultAncestor, distance) {
  const w = v.getLeftSibling();
  if (w) {
    let vop = v;
    let vip = v;
    let vim = w;
    let vom = vip.getLeftmostSibling();
    let sip = vip.mod;
    let sop = vop.mod;
    let sim = vim.mod;
    let som = vom.mod;
    while (vim.nextRight() && vip.nextLeft()) {
      vim = vim.nextRight();
      vip = vip.nextLeft();
      vom = vom.nextLeft();
      vop = vop.nextRight();
      vop.ancestor = v;
      const shift = vim.prelim + sim - (vip.prelim + sip) + distance;
      if (shift > 0) {
        moveSubtree(ancestor(vim, v, defaultAncestor), v, shift);
        sip += shift;
        sop += shift;
      }
      sim += vim.mod;
      sip += vip.mod;
      som += vom.mod;
      sop += vop.mod;
    }
    if (vim.nextRight() && !vop.nextRight()) {
      vop.thread = vim.nextRight();
      vop.mod += sim - sop;
    } else {
      if (vip.nextLeft() && !vom.nextLeft()) {
        vom.thread = vip.nextLeft();
        vom.mod += sip - som;
      }
      defaultAncestor = v;
    }
  }
  return defaultAncestor;
}
function firstWalk(node, distance) {
  const children = node.children;
  if (children.length) {
    let defaultAncestor = children[0];
    children.forEach((child) => {
      firstWalk(child, distance);
      defaultAncestor = apportion(child, defaultAncestor, distance);
    });
    executeShifts(node);
    const midpoint = (children[0].prelim + children[children.length - 1].prelim) / 2;
    const leftSibling = node.getLeftSibling();
    if (leftSibling) {
      node.prelim = leftSibling.prelim + distance;
      node.mod = node.prelim - midpoint;
    } else {
      node.prelim = midpoint;
    }
  } else {
    const leftSibling = node.getLeftSibling();
    node.prelim = leftSibling ? leftSibling.prelim + distance : 0;
  }
}
var Dimensions = class {
  constructor() {
    this.top = Infinity;
    this.right = -Infinity;
    this.bottom = -Infinity;
    this.left = Infinity;
  }
  update(node, xy) {
    const { x, y } = xy(node);
    if (x > this.right) {
      this.right = x;
    }
    if (x < this.left) {
      this.left = x;
    }
    if (y > this.bottom) {
      this.bottom = y;
    }
    if (y < this.top) {
      this.top = y;
    }
  }
};
function secondWalk(v, m, layout) {
  v.x = v.prelim + m;
  v.y = v.depth;
  layout.update(v);
  v.children.forEach((w) => secondWalk(w, m + v.mod, layout));
}
function thirdWalk(v) {
  const children = v.children;
  let leafCount = 0;
  children.forEach((w) => {
    thirdWalk(w);
    if (w.children.length) {
      leafCount += w.leafCount;
    } else {
      leafCount++;
    }
  });
  v.leafCount = leafCount;
  if (children.length) {
    v.subtreeLeft = children[0].subtreeLeft;
    v.subtreeRight = children[v.children.length - 1].subtreeRight;
    v.x = (v.subtreeLeft + v.subtreeRight) / 2;
  } else {
    v.subtreeLeft = v.x;
    v.subtreeRight = v.x;
  }
}
function treeLayout(root) {
  const layout = new TreeLayout();
  firstWalk(root, 1);
  secondWalk(root, -root.prelim, layout);
  thirdWalk(root);
  return layout;
}
var TreeLayout = class {
  constructor() {
    this.dimensions = new Dimensions();
    this.leafCount = 0;
    this.nodes = [];
    // One might want to process leaf nodes separately from the rest of the tree.
    // For example, position labels corresponding to leafs vertically, rather than horizontally.
    this.leafNodes = [];
    this.nonLeafNodes = [];
    this.depth = 0;
  }
  update(node) {
    this.dimensions.update(node, (node2) => ({ x: node2.x, y: node2.y }));
    if (!node.children.length) {
      this.leafCount++;
      this.leafNodes.push(node);
    } else {
      this.nonLeafNodes.push(node);
    }
    if (node.depth > this.depth) {
      this.depth = node.depth;
    }
    this.nodes.push(node);
  }
  resize(width, height, shiftX = 0, shiftY = 0, flipX = false) {
    const xSteps = this.leafCount - 1;
    const ySteps = this.depth;
    const dimensions = this.dimensions;
    let scalingX = 1;
    let scalingY = 1;
    if (width > 0 && xSteps) {
      const existingSpacingX = (dimensions.right - dimensions.left) / xSteps;
      const desiredSpacingX = width / xSteps;
      scalingX = desiredSpacingX / existingSpacingX;
      if (flipX) {
        scalingX = -scalingX;
      }
    }
    if (height > 0 && ySteps) {
      const existingSpacingY = (dimensions.bottom - dimensions.top) / ySteps;
      const desiredSpacingY = height / ySteps;
      scalingY = desiredSpacingY / existingSpacingY;
    }
    const screenDimensions = new Dimensions();
    this.nodes.forEach((node) => {
      node.screenX = node.x * scalingX;
      node.screenY = node.y * scalingY;
      screenDimensions.update(node, (node2) => ({ x: node2.screenX, y: node2.screenY }));
    });
    const offsetX = -screenDimensions.left;
    const offsetY = -screenDimensions.top;
    this.nodes.forEach((node) => {
      node.screenX += offsetX + shiftX;
      node.screenY += offsetY + shiftY;
    });
  }
};

// packages/ag-charts-community/src/chart/axis/groupedCategoryAxis.ts
var GroupedCategoryAxisLabel = class extends AxisLabel {
  constructor() {
    super(...arguments);
    this.grid = false;
  }
};
__decorateClass([
  Validate(BOOLEAN)
], GroupedCategoryAxisLabel.prototype, "grid", 2);
var GroupedCategoryAxis = class extends CartesianAxis {
  constructor(moduleCtx) {
    super(moduleCtx, new BandScale$5());
    // Label scale (labels are positioned between ticks, tick count = label count + 1).
    // We don't call is `labelScale` for consistency with other axes.
    this.tickScale = new BandScale$5();
    this.line = new AxisLine();
    this.label = new GroupedCategoryAxisLabel();
    this.labelColor = "rgba(87, 87, 87, 1)";
    this.includeInvisibleDomains = true;
    const { tickLineGroup, tickLabelGroup, gridLineGroup, tickScale, scale: scale2 } = this;
    scale2.paddingOuter = 0.1;
    scale2.paddingInner = scale2.paddingOuter * 2;
    this.range = scale2.range.slice();
    this.refreshScale();
    tickScale.paddingInner = 1;
    tickScale.paddingOuter = 0;
    this.gridLineSelection = Selection.select(gridLineGroup, Line);
    this.axisLineSelection = Selection.select(tickLineGroup, Line);
    this.separatorSelection = Selection.select(tickLineGroup, Line);
    this.labelSelection = Selection.select(tickLabelGroup, Text);
  }
  updateRange() {
    const { range: rr, visibleRange: vr, scale: scale2 } = this;
    const span = (rr[1] - rr[0]) / (vr[1] - vr[0]);
    const shift = span * vr[0];
    const start = rr[0] - shift;
    this.tickScale.range = scale2.range = [start, start + span];
    this.resizeTickTree();
  }
  resizeTickTree() {
    var _a;
    const s = this.scale;
    const range3 = s.domain.length ? [s.convert(s.domain[0]), s.convert(s.domain[s.domain.length - 1])] : s.range;
    const layout = this.tickTreeLayout;
    const lineHeight = this.lineHeight;
    if (layout) {
      layout.resize(
        Math.abs(range3[1] - range3[0]),
        layout.depth * lineHeight,
        (Math.min(range3[0], range3[1]) || 0) + ((_a = s.bandwidth) != null ? _a : 0) / 2,
        -layout.depth * lineHeight,
        range3[1] - range3[0] < 0
      );
    }
  }
  get lineHeight() {
    return this.label.fontSize * 1.5;
  }
  /**
   * The length of the grid. The grid is only visible in case of a non-zero value.
   */
  set gridLength(value) {
    if (this._gridLength && !value || !this._gridLength && value) {
      this.gridLineSelection.clear();
      this.labelSelection.clear();
    }
    this._gridLength = value;
  }
  get gridLength() {
    return this._gridLength;
  }
  calculateDomain() {
    var _a;
    const { direction } = this;
    const domains = [];
    let isNumericX;
    this.boundSeries.filter((s) => s.visible).forEach((series) => {
      if (direction === "x" /* X */) {
        if (isNumericX === void 0) {
          const domain2 = series.getDomain(direction);
          domains.push(domain2);
          isNumericX = typeof domain2[0] === "number";
        } else if (isNumericX) {
          domains.push(series.getDomain(direction));
        }
      } else {
        domains.push(series.getDomain(direction));
      }
    });
    const domain = new Array().concat(...domains);
    const domainExtent = (_a = extent$4(domain)) != null ? _a : domain;
    const values = this.reverse ? [...domainExtent].reverse() : domainExtent;
    const { domain: normalisedDataDomain, clipped } = this.normaliseDataDomain(values);
    this.dataDomain = {
      domain: normalisedDataDomain,
      clipped
    };
    this.scale.domain = normalisedDataDomain;
  }
  normaliseDataDomain(d) {
    const values = d.filter((s, i, arr) => arr.indexOf(s) === i);
    const tickTree = ticksToTree(values);
    this.tickTreeLayout = treeLayout(tickTree);
    const tickScaleDomain = values.slice();
    tickScaleDomain.push("");
    this.tickScale.domain = tickScaleDomain;
    this.resizeTickTree();
    return { domain: values, clipped: false };
  }
  /**
   * Creates/removes/updates the scene graph nodes that constitute the axis.
   * Supposed to be called _manually_ after changing _any_ of the axis properties.
   * This allows to bulk set axis properties before updating the nodes.
   * The node changes made by this method are rendered on the next animation frame.
   * We could schedule this method call automatically on the next animation frame
   * when any of the axis properties change (the way we do when properties of scene graph's
   * nodes change), but this will mean that we first wait for the next animation
   * frame to make changes to the nodes of the axis, then wait for another animation
   * frame to render those changes. It's nice to have everything update automatically,
   * but this extra level of async indirection will not just introduce an unwanted delay,
   * it will also make it harder to reason about the program.
   */
  update() {
    if (!this.computedLayout) {
      return;
    }
    this.updatePosition();
    this.updateTitleCaption();
    this.updateCategoryLabels();
    this.updateSeparators();
    this.updateAxisLines();
    this.updateCategoryGridLines();
    this.resetSelectionNodes();
    return void 0;
  }
  updateTitleCaption() {
    const { _titleCaption } = this;
    _titleCaption.node.visible = false;
  }
  updateCategoryLabels() {
    if (!this.computedLayout)
      return;
    const { tickLabelLayout } = this.computedLayout;
    const labelSelection = this.labelSelection.update(tickLabelLayout);
    labelSelection.each((node, datum) => {
      node.setProperties(datum);
    });
  }
  updateSeparators() {
    if (!this.computedLayout)
      return;
    const { separatorLayout } = this.computedLayout;
    const { range: range3 } = this;
    const epsilon2 = 1e-7;
    const separatorSelection = this.separatorSelection.update(separatorLayout);
    separatorSelection.each((line, datum) => {
      line.x1 = datum.x1;
      line.x2 = datum.x2;
      line.y1 = datum.y;
      line.y2 = datum.y;
      line.visible = datum.y >= range3[0] - epsilon2 && datum.y <= range3[1] + epsilon2;
      line.stroke = this.tick.color;
      line.fill = void 0;
      line.strokeWidth = 1;
    });
  }
  updateAxisLines() {
    if (!this.computedLayout)
      return;
    const { axisLineLayout } = this.computedLayout;
    const axisLineSelection = this.axisLineSelection.update(axisLineLayout);
    axisLineSelection.each((line, datum) => {
      line.setProperties(__spreadProps(__spreadValues({}, datum), {
        stroke: this.line.color,
        strokeWidth: this.line.width
      }));
      line.x1 = datum.x;
      line.x2 = datum.x;
      line.y1 = datum.y1;
      line.y2 = datum.y2;
      line.strokeWidth = this.line.width;
      line.stroke = this.line.color;
    });
  }
  updateCategoryGridLines() {
    const { gridLength, gridLine, label, range: range3, tickScale } = this;
    const ticks = tickScale.ticks();
    const sideFlag = label.getSideFlag();
    const gridSelection = this.gridLineSelection.update(gridLength ? ticks : []);
    if (gridLength) {
      const { width, style } = gridLine;
      const styleCount = style.length;
      gridSelection.each((line, datum, index) => {
        const y = Math.round(tickScale.convert(datum));
        line.x1 = 0;
        line.x2 = -sideFlag * gridLength;
        line.y1 = y;
        line.y2 = y;
        line.visible = y >= range3[0] && y <= range3[1];
        const { stroke, lineDash } = style[index % styleCount];
        line.stroke = stroke;
        line.strokeWidth = width;
        line.lineDash = lineDash;
        line.fill = void 0;
      });
    }
  }
  computeLayout() {
    this.updateDirection();
    this.calculateDomain();
    this.updateRange();
    const {
      scale: scale2,
      label,
      label: { parallel },
      moduleCtx: { callbackCache },
      range: range3,
      title,
      title: { formatter = (p) => p.defaultValue } = {}
    } = this;
    const rangeStart = scale2.range[0];
    const rangeEnd = scale2.range[1];
    const rangeLength = Math.abs(rangeEnd - rangeStart);
    const bandwidth = rangeLength / scale2.domain.length || 0;
    const rotation = toRadians(this.rotation);
    const isHorizontal = Math.abs(Math.cos(rotation)) < 1e-8;
    const sideFlag = label.getSideFlag();
    const lineHeight = this.lineHeight;
    const tickTreeLayout = this.tickTreeLayout;
    const labels = scale2.ticks();
    const treeLabels = tickTreeLayout ? tickTreeLayout.nodes : [];
    const isLabelTree = tickTreeLayout ? tickTreeLayout.depth > 1 : false;
    const { defaultRotation, configuredRotation, parallelFlipFlag } = calculateLabelRotation({
      rotation: label.rotation,
      parallel,
      regularFlipRotation: normalizeAngle360(rotation - Math.PI / 2),
      parallelFlipRotation: normalizeAngle360(rotation)
    });
    const tickLabelLayout = [];
    const copyLabelProps = (node) => {
      return {
        fill: node.fill,
        fontFamily: node.fontFamily,
        fontSize: node.fontSize,
        fontStyle: node.fontStyle,
        fontWeight: node.fontWeight,
        rotation: node.rotation,
        rotationCenterX: node.rotationCenterX,
        rotationCenterY: node.rotationCenterY,
        text: node.text,
        textAlign: node.textAlign,
        textBaseline: node.textBaseline,
        translationX: node.translationX,
        translationY: node.translationY,
        visible: node.visible,
        x: node.x,
        y: node.y
      };
    };
    const labelBBoxes = /* @__PURE__ */ new Map();
    let maxLeafLabelWidth = 0;
    const tempText = new Text();
    const setLabelProps = (datum, index) => {
      var _a;
      tempText.setProperties({
        fill: label.color,
        fontFamily: label.fontFamily,
        fontSize: label.fontSize,
        fontStyle: label.fontStyle,
        fontWeight: label.fontWeight,
        textAlign: "center",
        textBaseline: parallelFlipFlag === -1 ? "bottom" : "hanging",
        translationX: datum.screenY - label.fontSize * 0.25,
        translationY: datum.screenX
      });
      if (index === 0) {
        const isCaptionEnabled = (title == null ? void 0 : title.enabled) && labels.length > 0;
        if (!isCaptionEnabled) {
          return false;
        }
        const text = callbackCache.call(formatter, this.getTitleFormatterParams());
        tempText.setProperties({
          fill: title.color,
          fontFamily: title.fontFamily,
          fontSize: title.fontSize,
          fontStyle: title.fontStyle,
          fontWeight: title.fontWeight,
          text,
          textBaseline: "hanging",
          translationX: datum.screenY - label.fontSize * 0.25,
          translationY: datum.screenX
        });
      } else {
        const isInRange = datum.screenX >= range3[0] && datum.screenX <= range3[1];
        if (!isInRange) {
          return false;
        }
        if (label.formatter) {
          tempText.text = (_a = callbackCache.call(label.formatter, {
            value: String(datum.label),
            index
          })) != null ? _a : String(datum.label);
        } else {
          tempText.text = String(datum.label);
        }
      }
      return true;
    };
    treeLabels.forEach((datum, index) => {
      const isVisible = setLabelProps(datum, index);
      if (isVisible) {
        const bbox2 = tempText.computeTransformedBBox();
        if (bbox2) {
          labelBBoxes.set(index, bbox2);
          const isLeaf = !datum.children.length;
          if (isLeaf && bbox2.width > maxLeafLabelWidth) {
            maxLeafLabelWidth = bbox2.width;
          }
        }
      }
    });
    const labelX = sideFlag * label.padding;
    const labelGrid = this.label.grid;
    const separatorData = [];
    treeLabels.forEach((datum, index) => {
      let visible = setLabelProps(datum, index);
      const id = index;
      tempText.x = labelX;
      tempText.rotationCenterX = labelX;
      const isLeaf = !datum.children.length;
      if (isLeaf) {
        tempText.rotation = configuredRotation;
        tempText.textAlign = "end";
        tempText.textBaseline = "middle";
        const bbox2 = labelBBoxes.get(id);
        if (bbox2 && bbox2.height > bandwidth) {
          visible = false;
          labelBBoxes.delete(id);
        }
      } else {
        tempText.translationX -= maxLeafLabelWidth - lineHeight + this.label.padding;
        const availableRange = datum.leafCount * bandwidth;
        const bbox2 = labelBBoxes.get(id);
        if (bbox2 && bbox2.width > availableRange) {
          visible = false;
          labelBBoxes.delete(id);
        } else if (isHorizontal) {
          tempText.rotation = defaultRotation;
        } else {
          tempText.rotation = -Math.PI / 2;
        }
      }
      if (datum.parent && isLabelTree) {
        const y = isLeaf ? datum.screenX - bandwidth / 2 : datum.screenX - datum.leafCount * bandwidth / 2;
        if (isLeaf) {
          if (datum.number !== datum.children.length - 1 || labelGrid) {
            separatorData.push({
              y,
              x1: 0,
              x2: -maxLeafLabelWidth - this.label.padding * 2
            });
          }
        } else {
          const x = -maxLeafLabelWidth - this.label.padding * 2 + datum.screenY;
          separatorData.push({
            y,
            x1: x + lineHeight,
            x2: x
          });
        }
      }
      let props;
      if (visible) {
        const bbox2 = tempText.computeTransformedBBox();
        if (bbox2) {
          labelBBoxes.set(index, bbox2);
        }
        props = __spreadProps(__spreadValues({}, copyLabelProps(tempText)), { visible });
      } else {
        labelBBoxes.delete(index);
        props = { visible };
      }
      tickLabelLayout.push(props);
    });
    let minX = 0;
    separatorData.forEach((d) => minX = Math.min(minX, d.x2));
    separatorData.push({
      y: Math.max(rangeStart, rangeEnd),
      x1: 0,
      x2: minX
    });
    const separatorLayout = [];
    const separatorBoxes = [];
    const epsilon2 = 1e-7;
    separatorData.forEach((datum) => {
      if (datum.y >= range3[0] - epsilon2 && datum.y <= range3[1] + epsilon2) {
        const { x1, x2, y } = datum;
        const separatorBox = new BBox(Math.min(x1, x2), y, Math.abs(x1 - x2), 0);
        separatorBoxes.push(separatorBox);
        separatorLayout.push({ x1, x2, y });
      }
    });
    const axisLineLayout = [];
    const axisLineBoxes = [];
    const lineCount = tickTreeLayout ? tickTreeLayout.depth + 1 : 1;
    for (let i = 0; i < lineCount; i++) {
      const visible = labels.length > 0 && (i === 0 || labelGrid && isLabelTree);
      const x = i > 0 ? -maxLeafLabelWidth - this.label.padding * 2 - (i - 1) * lineHeight : 0;
      const lineBox = new BBox(x, Math.min(...range3), 0, Math.abs(range3[1] - range3[0]));
      axisLineBoxes.push(lineBox);
      axisLineLayout.push({ x, y1: range3[0], y2: range3[1], visible });
    }
    const getTransformBox = (bbox2) => {
      const matrix = new Matrix();
      const {
        rotation: axisRotation,
        translationX,
        translationY,
        rotationCenterX,
        rotationCenterY
      } = this.getAxisTransform();
      Matrix.updateTransformMatrix(matrix, 1, 1, axisRotation, translationX, translationY, {
        scalingCenterX: 0,
        scalingCenterY: 0,
        rotationCenterX,
        rotationCenterY
      });
      return matrix.transformBBox(bbox2);
    };
    const bbox = BBox.merge([...labelBBoxes.values(), ...separatorBoxes, ...axisLineBoxes]);
    const transformedBBox = getTransformBox(bbox);
    return {
      bbox: transformedBBox,
      tickLabelLayout,
      separatorLayout,
      axisLineLayout
    };
  }
  calculateLayout() {
    const { axisLineLayout, separatorLayout, tickLabelLayout, bbox } = this.computeLayout();
    this.computedLayout = {
      axisLineLayout,
      separatorLayout,
      tickLabelLayout
    };
    return { bbox, primaryTickCount: void 0 };
  }
};
GroupedCategoryAxis.className = "GroupedCategoryAxis";
GroupedCategoryAxis.type = "grouped-category";
__decorateClass([
  Validate(COLOR_STRING, { optional: true })
], GroupedCategoryAxis.prototype, "labelColor", 2);

// packages/ag-charts-community/src/util/async.ts
function sleep(sleepTimeoutMs) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(void 0), sleepTimeoutMs);
  });
}

// packages/ag-charts-community/src/util/callbackCache.ts
var CallbackCache = class {
  constructor() {
    this.cache = /* @__PURE__ */ new WeakMap();
  }
  call(fn, ...params) {
    let serialisedParams;
    let paramCache = this.cache.get(fn);
    const invoke = () => {
      try {
        const result = fn(...params);
        if (paramCache && serialisedParams != null) {
          paramCache.set(serialisedParams, result);
        }
        return result;
      } catch (e) {
        Logger.warnOnce(`User callback errored, ignoring`, e);
        return void 0;
      }
    };
    try {
      serialisedParams = JSON.stringify(params);
    } catch (e) {
      return invoke();
    }
    if (paramCache == null) {
      paramCache = /* @__PURE__ */ new Map();
      this.cache.set(fn, paramCache);
    }
    if (!paramCache.has(serialisedParams)) {
      return invoke();
    }
    return paramCache.get(serialisedParams);
  }
  invalidateCache() {
    this.cache = /* @__PURE__ */ new WeakMap();
  }
};

// packages/ag-charts-community/src/util/mutex.ts
var Mutex = class {
  constructor() {
    this.available = true;
    this.acquireQueue = [];
  }
  acquire(cb) {
    return new Promise((resolve) => {
      this.acquireQueue.push([cb, resolve]);
      if (this.available) {
        this.dispatchNext();
      }
    });
  }
  acquireImmediately(cb) {
    return __async(this, null, function* () {
      if (!this.available) {
        return false;
      }
      yield this.acquire(cb);
      return true;
    });
  }
  waitForClearAcquireQueue() {
    return __async(this, null, function* () {
      return this.acquire(() => __async(this, null, function* () {
        return void 0;
      }));
    });
  }
  dispatchNext() {
    return __async(this, null, function* () {
      var _a, _b;
      this.available = false;
      let [next, done] = (_a = this.acquireQueue.shift()) != null ? _a : [];
      while (next) {
        try {
          yield next();
          done == null ? void 0 : done();
        } catch (error) {
          Logger.error("mutex callback error", error);
          done == null ? void 0 : done();
        }
        [next, done] = (_b = this.acquireQueue.shift()) != null ? _b : [];
      }
      this.available = true;
    });
  }
};

// packages/ag-charts-community/src/util/observable.ts
var Observable = class {
  constructor() {
    this.eventListeners = /* @__PURE__ */ new Map();
  }
  addEventListener(eventType, listener) {
    if (typeof listener !== "function") {
      throw new Error("AG Charts - listener must be a Function");
    }
    const eventTypeListeners = this.eventListeners.get(eventType);
    if (eventTypeListeners) {
      eventTypeListeners.add(listener);
    } else {
      this.eventListeners.set(eventType, /* @__PURE__ */ new Set([listener]));
    }
  }
  removeEventListener(type, listener) {
    var _a;
    (_a = this.eventListeners.get(type)) == null ? void 0 : _a.delete(listener);
    if (this.eventListeners.size === 0) {
      this.eventListeners.delete(type);
    }
  }
  hasEventListener(type) {
    return this.eventListeners.has(type);
  }
  clearEventListeners() {
    this.eventListeners.clear();
  }
  fireEvent(event) {
    var _a;
    (_a = this.eventListeners.get(event.type)) == null ? void 0 : _a.forEach((listener) => listener(event));
  }
};

// packages/ag-charts-community/src/util/render.ts
function debouncedAnimationFrame(cb) {
  return buildScheduler((cb2, _delayMs) => requestAnimationFrame(cb2), cb);
}
function debouncedCallback(cb) {
  return buildScheduler((cb2, delayMs = 0) => setTimeout(cb2, delayMs), cb);
}
function buildScheduler(scheduleFn, cb) {
  let scheduleCount = 0;
  let promiseRunning = false;
  let awaitingPromise;
  let awaitingDone;
  const busy = () => {
    return promiseRunning;
  };
  const done = () => {
    promiseRunning = false;
    awaitingDone == null ? void 0 : awaitingDone();
    awaitingDone = void 0;
    awaitingPromise = void 0;
    if (scheduleCount > 0) {
      scheduleFn(scheduleCb);
    }
  };
  const scheduleCb = () => {
    const count2 = scheduleCount;
    scheduleCount = 0;
    promiseRunning = true;
    const maybePromise = cb({ count: count2 });
    if (!maybePromise) {
      done();
      return;
    }
    maybePromise.then(done).catch(done);
  };
  return {
    schedule(delayMs) {
      if (scheduleCount === 0 && !busy()) {
        scheduleFn(scheduleCb, delayMs);
      }
      scheduleCount++;
    },
    await() {
      return __async(this, null, function* () {
        if (!busy()) {
          return;
        }
        if (awaitingPromise == null) {
          awaitingPromise = new Promise((resolve) => {
            awaitingDone = resolve;
          });
        }
        while (busy()) {
          yield awaitingPromise;
        }
      });
    }
  };
}

// packages/ag-charts-community/src/util/sizeMonitor.ts
var SizeMonitor = class {
  static init(document2) {
    var _a;
    if (typeof ResizeObserver !== "undefined") {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { width, height } = entry.contentRect;
          this.checkSize(this.elements.get(entry.target), entry.target, width, height);
        }
      });
    } else {
      const step = () => {
        this.elements.forEach((entry, element2) => {
          this.checkClientSize(element2, entry);
        });
      };
      this.pollerHandler = (_a = document2.defaultView) == null ? void 0 : _a.setInterval(step, 100);
    }
    this.ownerDocument = document2;
    this.ready = true;
    this.documentReady = document2.readyState !== "loading";
    if (this.documentReady)
      return;
    this.readyEventFn = () => {
      const newState = document2.readyState !== "loading";
      const oldState = this.documentReady;
      this.documentReady = newState;
      if (!newState)
        return;
      if (newState === oldState)
        return;
      for (const [el, cb] of this.queuedObserveRequests) {
        this.observe(el, cb);
      }
      this.queuedObserveRequests.length = 0;
    };
    document2.addEventListener("DOMContentLoaded", this.readyEventFn);
  }
  static destroy() {
    var _a, _b;
    if (this.pollerHandler != null) {
      clearInterval(this.pollerHandler);
      this.pollerHandler = void 0;
    }
    if (this.readyEventFn) {
      (_a = this.ownerDocument) == null ? void 0 : _a.removeEventListener("DOMContentLoaded", this.readyEventFn);
      this.readyEventFn = void 0;
    }
    (_b = this.resizeObserver) == null ? void 0 : _b.disconnect();
    this.resizeObserver = void 0;
    this.ready = false;
    this.ownerDocument = void 0;
  }
  static checkSize(entry, element2, width, height) {
    if (!entry)
      return;
    if (!entry.size || width !== entry.size.width || height !== entry.size.height) {
      entry.size = { width, height };
      entry.cb(entry.size, element2);
    }
  }
  // Only a single callback is supported.
  static observe(element2, cb) {
    if (!this.ready) {
      this.init(element2.ownerDocument);
    }
    if (!this.documentReady) {
      this.queuedObserveRequests.push([element2, cb]);
      return;
    }
    this.unobserve(element2, false);
    if (this.resizeObserver) {
      this.resizeObserver.observe(element2);
    }
    this.elements.set(element2, { cb });
  }
  static unobserve(element2, cleanup = true) {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(element2);
    }
    this.elements.delete(element2);
    this.queuedObserveRequests = this.queuedObserveRequests.filter(([el]) => el === element2);
    if (cleanup && this.elements.size === 0) {
      this.destroy();
    }
  }
  static checkClientSize(element2, entry) {
    var _a, _b;
    const width = (_a = element2.clientWidth) != null ? _a : 0;
    const height = (_b = element2.clientHeight) != null ? _b : 0;
    this.checkSize(entry, element2, width, height);
  }
};
SizeMonitor.elements = /* @__PURE__ */ new Map();
SizeMonitor.ready = false;
SizeMonitor.documentReady = false;
SizeMonitor.queuedObserveRequests = [];

// packages/ag-charts-community/src/chart/chartHighlight.ts
var ChartHighlight = class {
  constructor() {
    this.range = "tooltip";
  }
};
__decorateClass([
  Validate(UNION(["tooltip", "node"], "a range"))
], ChartHighlight.prototype, "range", 2);

// packages/ag-charts-community/src/chart/chartUpdateType.ts
var ChartUpdateType = /* @__PURE__ */ ((ChartUpdateType2) => {
  ChartUpdateType2[ChartUpdateType2["FULL"] = 0] = "FULL";
  ChartUpdateType2[ChartUpdateType2["PROCESS_DATA"] = 1] = "PROCESS_DATA";
  ChartUpdateType2[ChartUpdateType2["PERFORM_LAYOUT"] = 2] = "PERFORM_LAYOUT";
  ChartUpdateType2[ChartUpdateType2["SERIES_UPDATE"] = 3] = "SERIES_UPDATE";
  ChartUpdateType2[ChartUpdateType2["TOOLTIP_RECALCULATION"] = 4] = "TOOLTIP_RECALCULATION";
  ChartUpdateType2[ChartUpdateType2["SCENE_RENDER"] = 5] = "SCENE_RENDER";
  ChartUpdateType2[ChartUpdateType2["NONE"] = 6] = "NONE";
  return ChartUpdateType2;
})(ChartUpdateType || {});

// packages/ag-charts-community/src/chart/data/dataDomain.ts
var DataDomain = class {
  constructor(type) {
    this.type = type;
    this.continuousDomain = [Infinity, -Infinity];
    this.discreteDomain = /* @__PURE__ */ new Set();
  }
  extend(val) {
    if (this.type === "discrete") {
      this.discreteDomain.add(val);
    } else if (this.type === "continuous") {
      if (this.continuousDomain[0] > val) {
        this.continuousDomain[0] = val;
      }
      if (this.continuousDomain[1] < val) {
        this.continuousDomain[1] = val;
      }
    }
  }
  getDomain() {
    if (this.type === "discrete") {
      return this.discreteDomain;
    } else if (this.type === "continuous") {
      return this.continuousDomain;
    }
    throw new Error("AG Charts - Unsupported data domain type: " + this.type);
  }
};

// packages/ag-charts-community/src/chart/data/utilFunctions.ts
function extendDomain(values, domain = [Infinity, -Infinity]) {
  for (const value of values) {
    if (typeof value !== "number") {
      continue;
    }
    if (value < domain[0]) {
      domain[0] = value;
    }
    if (value > domain[1]) {
      domain[1] = value;
    }
  }
  return domain;
}

// packages/ag-charts-community/src/chart/data/dataModel.ts
function toKeyString(keys) {
  return keys.map((v) => {
    if (v == null) {
      return v;
    } else if (typeof v === "number" || typeof v === "string" || typeof v === "boolean") {
      return v;
    } else if (typeof v === "object") {
      return JSON.stringify(v);
    }
    return v;
  }).join("-");
}
function round2(val) {
  const accuracy = 1e4;
  if (Number.isInteger(val)) {
    return val;
  } else if (Math.abs(val) > accuracy) {
    return Math.trunc(val);
  }
  return Math.round(val * accuracy) / accuracy;
}
function fixNumericExtentInternal(extent2) {
  if (extent2 === void 0) {
    return [];
  }
  let [min, max] = extent2;
  min = +min;
  max = +max;
  if (min === 0 && max === 0) {
    return [0, 1];
  }
  if (min === Infinity && max === -Infinity) {
    return [];
  }
  if (min === Infinity) {
    min = 0;
  }
  if (max === -Infinity) {
    max = 0;
  }
  if (!(isNumber2(min) && isNumber2(max))) {
    return [];
  }
  return [min, max];
}
function fixNumericExtent(extent2, axis) {
  var _a;
  const fixedExtent = fixNumericExtentInternal(extent2);
  if (fixedExtent.length === 0) {
    return fixedExtent;
  }
  let [min, max] = fixedExtent;
  if (min === max) {
    const [paddingMin, paddingMax] = (_a = axis == null ? void 0 : axis.calculatePadding(min, max, axis.isReversed())) != null ? _a : [1, 1];
    min -= paddingMin;
    max += paddingMax;
  }
  return [min, max];
}
var INVALID_VALUE = Symbol("invalid");
var DataModel = class {
  constructor(opts) {
    const { props, mode = "standalone" } = opts;
    this.mode = mode;
    let keys = true;
    for (const next of props) {
      if (next.type === "key" && !keys) {
        throw new Error("AG Charts - internal config error: keys must come before values.");
      }
      if (next.type === "value" && keys) {
        keys = false;
      }
    }
    this.opts = __spreadValues({ dataVisible: true }, opts);
    this.keys = props.filter((def) => def.type === "key").map((def, index) => __spreadProps(__spreadValues({}, def), { index, missing: 0 }));
    this.values = props.filter((def) => def.type === "value").map((def, index) => __spreadProps(__spreadValues({}, def), { index, missing: 0 }));
    this.aggregates = props.filter((def) => def.type === "aggregate").map((def, index) => __spreadProps(__spreadValues({}, def), { index }));
    this.groupProcessors = props.filter((def) => def.type === "group-value-processor").map((def, index) => __spreadProps(__spreadValues({}, def), { index }));
    this.propertyProcessors = props.filter((def) => def.type === "property-value-processor").map((def, index) => __spreadProps(__spreadValues({}, def), { index }));
    this.reducers = props.filter((def) => def.type === "reducer").map((def, index) => __spreadProps(__spreadValues({}, def), { index }));
    this.processors = props.filter((def) => def.type === "processor").map((def, index) => __spreadProps(__spreadValues({}, def), { index }));
    for (const def of this.values) {
      if (def.property == null) {
        throw new Error(
          `AG Charts - internal config error: no properties specified for value definitions: ${JSON.stringify(
            def
          )}`
        );
      }
    }
    const verifyMatchGroupId = ({ matchGroupIds }) => {
      for (const matchGroupId of matchGroupIds != null ? matchGroupIds : []) {
        if (!this.values.some((def) => def.groupId === matchGroupId)) {
          throw new Error(
            `AG Charts - internal config error: matchGroupIds properties must match defined groups (${matchGroupId}).`
          );
        }
      }
    };
    const verifyMatchIds = ({ matchIds }) => {
      for (const matchId of matchIds != null ? matchIds : []) {
        if (!this.values.some(
          (def) => {
            var _a;
            return (_a = def.ids) == null ? void 0 : _a.some(([scope, id]) => scope === matchId[0] && id === matchId[1]);
          }
        )) {
          throw new Error(
            `AG Charts - internal config error: matchGroupIds properties must match defined groups (${matchId}).`
          );
        }
      }
    };
    for (const def of [...this.groupProcessors, ...this.aggregates]) {
      verifyMatchIds(def);
      verifyMatchGroupId(def);
    }
  }
  resolveProcessedDataIndexById(scope, searchId) {
    var _a;
    const { index, def } = (_a = this.resolveProcessedDataDefById(scope, searchId)) != null ? _a : {};
    return { index, def };
  }
  resolveProcessedDataIndicesById(scope, searchId) {
    return this.resolveProcessedDataDefsById(scope, searchId).map(({ index, def }) => ({ index, def }));
  }
  resolveProcessedDataDefById(scope, searchId) {
    return this.resolveProcessedDataDefsById(scope, searchId)[0];
  }
  resolveProcessedDataDefsByIds(scope, searchIds) {
    const defs = [];
    for (const searchId of searchIds) {
      defs.push([searchId, this.resolveProcessedDataDefsById(scope, searchId)]);
    }
    return defs;
  }
  resolveProcessedDataDefsValues(defs, { keys, values }) {
    const result = {};
    for (const [searchId, [{ index, def }]] of defs) {
      const processedData = def.type === "key" ? keys : values;
      result[searchId] = processedData[index];
    }
    return result;
  }
  resolveProcessedDataDefsById(searchScope, searchId) {
    const { keys, values, aggregates, groupProcessors, reducers } = this;
    const match = (prop) => {
      const { ids, scopes } = prop;
      if (ids == null)
        return false;
      if (searchScope != null && !(scopes == null ? void 0 : scopes.some((scope) => scope === searchScope.id)))
        return false;
      return ids.some(
        ([scope, id]) => scope === searchScope.id && (typeof searchId === "string" ? id === searchId : searchId.test(id))
      );
    };
    const allDefs = [
      keys,
      values,
      aggregates,
      groupProcessors,
      reducers
    ];
    const result = [];
    for (const defs of allDefs) {
      result.push(...defs.filter(match).map((def) => ({ index: def.index, def })));
    }
    if (result.length > 0) {
      return result;
    }
    throw new Error(`AG Charts - didn't find property definition for [${searchId}, ${searchScope.id}]`);
  }
  getDomain(scope, searchId, type = "value", processedData) {
    var _a, _b, _c, _d;
    let matches;
    try {
      matches = this.resolveProcessedDataIndicesById(scope, searchId);
    } catch (e) {
      if (typeof searchId !== "string" && /didn't find property definition/.test(e.message))
        return [];
      throw e;
    }
    let domainProp;
    switch (type) {
      case "key":
        domainProp = "keys";
        break;
      case "value":
        domainProp = "values";
        break;
      case "aggregate":
        domainProp = "aggValues";
        break;
      case "group-value-processor":
        domainProp = "groups";
        break;
      default:
        return [];
    }
    const firstMatch = (_b = (_a = processedData.domain[domainProp]) == null ? void 0 : _a[matches[0].index]) != null ? _b : [];
    if (matches.length === 1) {
      return firstMatch;
    }
    const result = [...firstMatch];
    for (const idx of matches.slice(1)) {
      extendDomain((_d = (_c = processedData.domain[domainProp]) == null ? void 0 : _c[idx.index]) != null ? _d : [], result);
    }
    return result;
  }
  processData(data, sources) {
    const {
      opts: { groupByKeys, groupByFn },
      aggregates,
      groupProcessors,
      reducers,
      processors,
      propertyProcessors
    } = this;
    const start = performance.now();
    for (const def of [...this.keys, ...this.values]) {
      def.missing = 0;
    }
    if (groupByKeys && this.keys.length === 0) {
      return void 0;
    }
    let processedData = this.extractData(data, sources);
    if (groupByKeys) {
      processedData = this.groupData(processedData);
    } else if (groupByFn) {
      processedData = this.groupData(processedData, groupByFn(processedData));
    }
    if (groupProcessors.length > 0) {
      this.postProcessGroups(processedData);
    }
    if (aggregates.length > 0) {
      this.aggregateData(processedData);
    }
    if (propertyProcessors.length > 0) {
      this.postProcessProperties(processedData);
    }
    if (reducers.length > 0) {
      this.reduceData(processedData);
    }
    if (processors.length > 0) {
      this.postProcessData(processedData);
    }
    for (const def of [...this.keys, ...this.values]) {
      if (data.length > 0 && def.missing >= data.length) {
        Logger.warnOnce(`the key '${def.property}' was not found in any data element.`);
      }
    }
    const end = performance.now();
    processedData.time = end - start;
    if (Debug.check(true, "data-model")) {
      logProcessedData(processedData);
    }
    return processedData;
  }
  valueGroupIdxLookup({ matchGroupIds, matchIds }) {
    return this.values.map((def, index) => ({ def, index })).filter(({ def }) => {
      if (matchGroupIds && (def.groupId == null || !matchGroupIds.includes(def.groupId))) {
        return false;
      }
      if (!matchIds)
        return true;
      if (def.ids == null)
        return false;
      return matchIds.some(
        ([matchScope, matchId]) => {
          var _a;
          return (_a = def.ids) == null ? void 0 : _a.some(([defScope, defId]) => defScope === matchScope && defId === matchId);
        }
      );
    }).map(({ index }) => index);
  }
  valueIdxLookup(scopes, prop) {
    const noScopesToMatch = scopes == null || scopes.length === 0;
    const scopeMatch = (compareTo) => {
      const anyScope = compareTo == null;
      if (anyScope)
        return true;
      const noScopes = compareTo == null || compareTo.length === 0;
      if (noScopesToMatch === noScopes)
        return true;
      return compareTo == null ? void 0 : compareTo.some((s) => scopes.includes(s));
    };
    const propId = typeof prop === "string" ? prop : prop.id;
    const idMatch = ([scope, id]) => {
      return scopeMatch([scope]) && id === propId;
    };
    const result = this.values.findIndex((def) => {
      var _a;
      return scopeMatch(def.scopes) && (((_a = def.ids) == null ? void 0 : _a.some((id) => idMatch(id))) || def.property === propId || def.id === propId);
    });
    if (result >= 0) {
      return result;
    }
    throw new Error(
      `AG Charts - configuration error, unknown property ${JSON.stringify(prop)} in scope(s) ${JSON.stringify(
        scopes
      )}`
    );
  }
  extractData(data, sources) {
    var _a, _b, _c, _d, _e, _f;
    const {
      keys: keyDefs,
      values: valueDefs,
      opts: { dataVisible }
    } = this;
    const { dataDomain, processValue, scopes, allScopesHaveSameDefs } = this.initDataDomainProcessor();
    const resultData = new Array(dataVisible ? data.length : 0);
    let resultDataIdx = 0;
    let partialValidDataCount = 0;
    for (const [datumIdx, datum] of data.entries()) {
      const sourceDatums = {};
      const validScopes = scopes.size > 0 ? new Set(scopes) : void 0;
      const keys = dataVisible ? new Array(keyDefs.length) : void 0;
      let keyIdx = 0;
      let key;
      for (const def of keyDefs) {
        key = processValue(def, datum, key);
        if (key === INVALID_VALUE)
          break;
        if (keys) {
          keys[keyIdx++] = key;
        }
      }
      if (key === INVALID_VALUE)
        continue;
      const values = dataVisible && valueDefs.length > 0 ? new Array(valueDefs.length) : void 0;
      let value;
      const sourcesById = {};
      for (const source of sources != null ? sources : []) {
        sourcesById[source.id] = source;
      }
      for (const [valueDefIdx, def] of valueDefs.entries()) {
        for (const scope of (_a = def.scopes) != null ? _a : scopes) {
          const source = sourcesById[scope];
          const valueDatum = (_b = source == null ? void 0 : source.data[datumIdx]) != null ? _b : datum;
          value = processValue(def, valueDatum, value);
          if (value === INVALID_VALUE || !values)
            continue;
          if (source !== void 0) {
            (_d = sourceDatums[_c = source.id]) != null ? _d : sourceDatums[_c] = {};
            sourceDatums[source.id][def.property] = value;
          }
          if (def.useScopedValues) {
            (_e = values[valueDefIdx]) != null ? _e : values[valueDefIdx] = {};
            values[valueDefIdx][scope] = value;
          } else {
            values[valueDefIdx] = value;
          }
        }
        if (value === INVALID_VALUE) {
          if (allScopesHaveSameDefs)
            break;
          for (const scope of (_f = def.scopes) != null ? _f : scopes) {
            validScopes == null ? void 0 : validScopes.delete(scope);
          }
          if ((validScopes == null ? void 0 : validScopes.size) === 0)
            break;
        }
      }
      if (value === INVALID_VALUE && allScopesHaveSameDefs)
        continue;
      if ((validScopes == null ? void 0 : validScopes.size) === 0)
        continue;
      if (dataVisible) {
        const result = {
          datum: __spreadValues(__spreadValues({}, datum), sourceDatums),
          keys,
          values
        };
        if (!allScopesHaveSameDefs && validScopes && validScopes.size < scopes.size) {
          partialValidDataCount++;
          result.validScopes = [...validScopes];
        }
        resultData[resultDataIdx++] = result;
      }
    }
    resultData.length = resultDataIdx;
    const propertyDomain = (def) => {
      const result = dataDomain.get(def).getDomain();
      if (Array.isArray(result) && result[0] > result[1]) {
        return [];
      }
      return [...result];
    };
    return {
      type: "ungrouped",
      input: { count: data.length },
      data: resultData,
      domain: {
        keys: keyDefs.map((def) => propertyDomain(def)),
        values: valueDefs.map((def) => propertyDomain(def))
      },
      defs: {
        allScopesHaveSameDefs,
        keys: keyDefs,
        values: valueDefs
      },
      partialValidDataCount,
      time: 0
    };
  }
  groupData(data, groupingFn) {
    var _a, _b, _c, _d;
    const processedData = /* @__PURE__ */ new Map();
    for (const dataEntry of data.data) {
      const { keys, values, datum, validScopes } = dataEntry;
      const group2 = groupingFn ? groupingFn(dataEntry) : keys;
      const groupStr = toKeyString(group2);
      if (processedData.has(groupStr)) {
        const existingData = processedData.get(groupStr);
        existingData.values.push(values);
        existingData.datum.push(datum);
        if (validScopes != null) {
          for (let index = 0; index < ((_b = (_a = existingData.validScopes) == null ? void 0 : _a.length) != null ? _b : 0); index++) {
            const scope = (_c = existingData.validScopes) == null ? void 0 : _c[index];
            if (validScopes.some((s) => s === scope))
              continue;
            (_d = existingData.validScopes) == null ? void 0 : _d.splice(index, 1);
          }
        }
      } else {
        processedData.set(groupStr, {
          keys: group2,
          values: [values],
          datum: [datum],
          validScopes
        });
      }
    }
    const resultData = new Array(processedData.size);
    const resultGroups = new Array(processedData.size);
    let dataIndex = 0;
    for (const [, { keys, values, datum, validScopes }] of processedData.entries()) {
      if ((validScopes == null ? void 0 : validScopes.length) === 0)
        continue;
      resultGroups[dataIndex] = keys;
      resultData[dataIndex++] = {
        keys,
        values,
        datum,
        validScopes
      };
    }
    return __spreadProps(__spreadValues({}, data), {
      type: "grouped",
      data: resultData,
      domain: __spreadProps(__spreadValues({}, data.domain), {
        groups: resultGroups
      })
    });
  }
  aggregateData(processedData) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { aggregates: aggDefs } = this;
    if (!aggDefs)
      return;
    const resultAggValues = aggDefs.map(() => [Infinity, -Infinity]);
    const resultAggValueIndices = aggDefs.map((def) => this.valueGroupIdxLookup(def));
    const resultAggFns = aggDefs.map((def) => def.aggregateFunction);
    const resultGroupAggFns = aggDefs.map((def) => def.groupAggregateFunction);
    const resultFinalFns = aggDefs.map((def) => def.finalFunction);
    for (const group2 of processedData.data) {
      let { values } = group2;
      const { validScopes } = group2;
      (_a = group2.aggValues) != null ? _a : group2.aggValues = new Array(resultAggValueIndices.length);
      if (processedData.type === "ungrouped") {
        values = [values];
      }
      let resultIdx = 0;
      for (const indices of resultAggValueIndices) {
        const scopeValid = (_b = validScopes == null ? void 0 : validScopes.some((s) => {
          var _a2;
          return (_a2 = aggDefs[resultIdx].matchScopes) == null ? void 0 : _a2.some((as) => s === as);
        })) != null ? _b : true;
        if (!scopeValid) {
          resultIdx++;
          continue;
        }
        let groupAggValues = (_d = (_c = resultGroupAggFns[resultIdx]) == null ? void 0 : _c.call(resultGroupAggFns)) != null ? _d : extendDomain([]);
        for (const distinctValues of values) {
          const valuesToAgg = indices.map((valueIdx) => distinctValues[valueIdx]);
          const valuesAgg = resultAggFns[resultIdx](valuesToAgg, group2.keys);
          if (valuesAgg) {
            groupAggValues = (_f = (_e = resultGroupAggFns[resultIdx]) == null ? void 0 : _e.call(resultGroupAggFns, valuesAgg, groupAggValues)) != null ? _f : extendDomain(valuesAgg, groupAggValues);
          }
        }
        const finalValues = ((_h = (_g = resultFinalFns[resultIdx]) == null ? void 0 : _g.call(resultFinalFns, groupAggValues)) != null ? _h : groupAggValues).map(
          (v) => round2(v)
        );
        extendDomain(finalValues, resultAggValues[resultIdx]);
        group2.aggValues[resultIdx++] = finalValues;
      }
    }
    processedData.domain.aggValues = resultAggValues;
  }
  postProcessGroups(processedData) {
    var _a, _b, _c, _d, _e;
    const { groupProcessors } = this;
    if (!groupProcessors)
      return;
    const affectedIndices = /* @__PURE__ */ new Set();
    const updatedDomains = /* @__PURE__ */ new Map();
    const groupProcessorIndices = /* @__PURE__ */ new Map();
    const groupProcessorInitFns = /* @__PURE__ */ new Map();
    for (const processor of groupProcessors) {
      const indices = this.valueGroupIdxLookup(processor);
      groupProcessorIndices.set(processor, indices);
      groupProcessorInitFns.set(processor, processor.adjust());
      for (const idx of indices) {
        const valueDef = this.values[idx];
        affectedIndices.add(idx);
        updatedDomains.set(idx, new DataDomain(valueDef.valueType === "category" ? "discrete" : "continuous"));
      }
    }
    const updateDomains = (values) => {
      var _a2;
      for (const valueIndex of affectedIndices) {
        (_a2 = updatedDomains.get(valueIndex)) == null ? void 0 : _a2.extend(values[valueIndex]);
      }
    };
    for (const group2 of processedData.data) {
      for (const processor of groupProcessors) {
        const scopeValid = (_b = (_a = group2.validScopes) == null ? void 0 : _a.some((s) => {
          var _a2;
          return (_a2 = processor.matchScopes) == null ? void 0 : _a2.some((as) => s === as);
        })) != null ? _b : true;
        if (!scopeValid) {
          continue;
        }
        const valueIndexes = (_c = groupProcessorIndices.get(processor)) != null ? _c : [];
        const adjustFn = (_e = (_d = groupProcessorInitFns.get(processor)) == null ? void 0 : _d()) != null ? _e : () => void 0;
        if (processedData.type === "grouped") {
          for (const values of group2.values) {
            if (values) {
              adjustFn(values, valueIndexes);
            }
          }
          continue;
        }
        if (group2.values) {
          adjustFn(group2.values, valueIndexes);
        }
      }
      if (processedData.type === "grouped") {
        for (const values of group2.values) {
          updateDomains(values);
        }
      } else {
        updateDomains(group2.values);
      }
    }
    for (const [idx, dataDomain] of updatedDomains) {
      processedData.domain.values[idx] = [...dataDomain.getDomain()];
    }
  }
  postProcessProperties(processedData) {
    const { propertyProcessors } = this;
    if (!propertyProcessors)
      return;
    for (const { adjust, property, scopes } of propertyProcessors) {
      adjust()(processedData, this.valueIdxLookup(scopes != null ? scopes : [], property));
    }
  }
  reduceData(processedData) {
    var _a, _b, _c;
    const { reducers: reducerDefs } = this;
    const scopes = reducerDefs.map((def) => def.scopes);
    const reducers = reducerDefs.map((def) => def.reducer());
    const accValues = reducerDefs.map((def) => def.initialValue);
    for (const group2 of processedData.data) {
      let reducerIndex = 0;
      for (const reducer of reducers) {
        const scopeValid = (_b = (_a = group2.validScopes) == null ? void 0 : _a.some((s) => {
          var _a2;
          return (_a2 = scopes[reducerIndex]) == null ? void 0 : _a2.some((as) => s === as);
        })) != null ? _b : true;
        if (!scopeValid) {
          reducerIndex++;
          continue;
        }
        accValues[reducerIndex] = reducer(accValues[reducerIndex], group2);
        reducerIndex++;
      }
    }
    for (let accIdx = 0; accIdx < accValues.length; accIdx++) {
      (_c = processedData.reduced) != null ? _c : processedData.reduced = {};
      processedData.reduced[reducerDefs[accIdx].property] = accValues[accIdx];
    }
  }
  postProcessData(processedData) {
    var _a;
    const { processors: processorDefs } = this;
    for (const def of processorDefs) {
      (_a = processedData.reduced) != null ? _a : processedData.reduced = {};
      processedData.reduced[def.property] = def.calculate(processedData);
    }
  }
  initDataDomainProcessor() {
    var _a;
    const { keys: keyDefs, values: valueDefs } = this;
    const scopes = /* @__PURE__ */ new Set();
    for (const valueDef of valueDefs) {
      for (const scope of (_a = valueDef.scopes) != null ? _a : []) {
        scopes.add(scope);
      }
    }
    const scopesCount = scopes.size;
    const dataDomain = /* @__PURE__ */ new Map();
    const processorFns = /* @__PURE__ */ new Map();
    let allScopesHaveSameDefs = true;
    const initDataDomainKey = (key, type, updateDataDomain = dataDomain) => {
      var _a2;
      if (type === "category") {
        updateDataDomain.set(key, new DataDomain("discrete"));
      } else {
        updateDataDomain.set(key, new DataDomain("continuous"));
        allScopesHaveSameDefs && (allScopesHaveSameDefs = ((_a2 = key.scopes) != null ? _a2 : []).length === scopesCount);
      }
    };
    const initDataDomain = () => {
      keyDefs.forEach((def) => initDataDomainKey(def, def.valueType));
      valueDefs.forEach((def) => initDataDomainKey(def, def.valueType));
    };
    initDataDomain();
    const accessors = this.buildAccessors(...keyDefs, ...valueDefs);
    const processValue = (def, datum, previousDatum) => {
      var _a2, _b, _c, _d;
      const hasAccessor = def.property in accessors;
      let valueInDatum = false;
      let value;
      if (hasAccessor) {
        try {
          value = accessors[def.property](datum);
        } catch (error) {
        }
        valueInDatum = value !== void 0;
      } else {
        valueInDatum = def.property in datum;
        value = valueInDatum ? datum[def.property] : def.missingValue;
      }
      if (def.forceValue != null) {
        const valueNegative = valueInDatum && isNegative(value);
        value = valueNegative ? -1 * def.forceValue : def.forceValue;
        valueInDatum = true;
      }
      const missingValueDef = "missingValue" in def;
      if (!valueInDatum && !missingValueDef) {
        def.missing++;
      }
      if (!dataDomain.has(def)) {
        initDataDomain();
      }
      if (valueInDatum) {
        const valid = (_b = (_a2 = def.validation) == null ? void 0 : _a2.call(def, value, datum)) != null ? _b : true;
        if (!valid) {
          if ("invalidValue" in def) {
            value = def.invalidValue;
          } else {
            if (this.mode !== "integrated") {
              Logger.warnOnce(`invalid value of type [${typeof value}] ignored:`, `[${value}]`);
            }
            return INVALID_VALUE;
          }
        }
      }
      if (def.processor) {
        if (!processorFns.has(def)) {
          processorFns.set(def, def.processor());
        }
        value = (_c = processorFns.get(def)) == null ? void 0 : _c(value, previousDatum !== INVALID_VALUE ? previousDatum : void 0);
      }
      (_d = dataDomain.get(def)) == null ? void 0 : _d.extend(value);
      return value;
    };
    return { dataDomain, processValue, initDataDomain, scopes, allScopesHaveSameDefs };
  }
  buildAccessors(...defs) {
    const result = {};
    if (this.mode === "integrated")
      return result;
    for (const def of defs) {
      const isPath = def.property.indexOf(".") >= 0 || def.property.indexOf("[") >= 0;
      if (!isPath)
        continue;
      let fnBody;
      if (def.property.startsWith("[")) {
        fnBody = `return datum${def.property};`;
      } else {
        fnBody = `return datum.${def.property};`;
      }
      result[def.property] = new Function("datum", fnBody);
    }
    return result;
  }
};
function logProcessedData(processedData) {
  var _a, _b;
  const logValues = (name, data) => {
    if (data.length > 0) {
      Logger.log(`DataModel.processData() - ${name}`);
      Logger.table(data);
    }
  };
  Logger.log("DataModel.processData() - processedData", processedData);
  logValues("Key Domains", processedData.domain.keys);
  logValues("Group Domains", (_a = processedData.domain.groups) != null ? _a : []);
  logValues("Value Domains", processedData.domain.values);
  logValues("Aggregate Domains", (_b = processedData.domain.aggValues) != null ? _b : []);
  if (processedData.type === "grouped") {
    const flattenedValues = processedData.data.reduce((acc, next) => {
      var _a2, _b2;
      const keys = (_a2 = next.keys) != null ? _a2 : [];
      const aggValues = (_b2 = next.aggValues) != null ? _b2 : [];
      const skipKeys = next.keys.map(() => void 0);
      const skipAggValues = aggValues == null ? void 0 : aggValues.map(() => void 0);
      acc.push(
        ...next.values.map((v, i) => [
          ...i === 0 ? keys : skipKeys,
          ...v != null ? v : [],
          ...i == 0 ? aggValues : skipAggValues
        ])
      );
      return acc;
    }, []);
    logValues("Values", flattenedValues);
  } else {
    const flattenedValues = processedData.data.reduce((acc, next) => {
      var _a2;
      const aggValues = (_a2 = next.aggValues) != null ? _a2 : [];
      acc.push([...next.keys, ...next.values, ...aggValues]);
      return acc;
    }, []);
    logValues("Values", flattenedValues);
  }
}

// packages/ag-charts-community/src/chart/data/dataController.ts
var DataController = class {
  constructor(mode) {
    this.mode = mode;
    this.debug = Debug.create(true, "data-model");
    this.requested = [];
    this.status = "setup";
  }
  request(id, data, opts) {
    return __async(this, null, function* () {
      if (this.status !== "setup")
        throw new Error(`AG Charts - data request after data setup phase.`);
      return new Promise((resolve, reject) => {
        this.requested.push({
          id,
          opts,
          data,
          resultCb: resolve,
          reject
        });
      });
    });
  }
  execute() {
    return __async(this, null, function* () {
      if (this.status !== "setup")
        throw new Error(`AG Charts - data request after data setup phase.`);
      this.status = "executed";
      this.debug("DataController.execute() - requested", this.requested);
      const { valid, invalid } = this.validateRequests(this.requested);
      this.debug("DataController.execute() - validated", valid);
      const merged = this.mergeRequested(valid);
      this.debug("DataController.execute() - merged", merged);
      const debugMode = Debug.check(true, "data-model");
      if (debugMode) {
        window.processedData = [];
      }
      const multipleSources = valid.some((v) => v.data != null);
      for (const { opts, data, resultCbs, rejects, ids } of merged) {
        const needsValueExtraction = multipleSources || opts.props.some((p) => {
          var _a;
          if (p.type !== "value" && p.type !== "key")
            return false;
          return (_a = p.useScopedValues) != null ? _a : false;
        });
        try {
          const dataModel = new DataModel(__spreadProps(__spreadValues({}, opts), { mode: this.mode }));
          const processedData = dataModel.processData(data, valid);
          if (debugMode) {
            window.processedData.push(processedData);
          }
          if (processedData && processedData.partialValidDataCount === 0) {
            resultCbs.forEach((cb, requestIdx) => {
              const id = ids[requestIdx];
              let requestProcessedData = processedData;
              if (needsValueExtraction) {
                requestProcessedData = this.extractScopedData(id, processedData);
              }
              cb({ dataModel, processedData: requestProcessedData });
            });
          } else if (processedData) {
            this.splitResult(dataModel, processedData, ids, resultCbs);
          } else {
            rejects.forEach((cb) => cb(new Error(`AG Charts - no processed data generated`)));
          }
        } catch (error) {
          rejects.forEach((cb) => cb(error));
        }
      }
      invalid.forEach(({ error, reject }) => reject(error));
    });
  }
  extractScopedData(id, processedData) {
    const extractDatum = (datum) => {
      if (Array.isArray(datum)) {
        return datum.map(extractDatum);
      }
      return __spreadValues(__spreadValues({}, datum), datum[id]);
    };
    const extractValues = (values) => {
      var _a;
      if (Array.isArray(values)) {
        return values.map(extractValues);
      }
      return (_a = values == null ? void 0 : values[id]) != null ? _a : values;
    };
    return __spreadProps(__spreadValues({}, processedData), {
      data: processedData.data.map((datum) => __spreadProps(__spreadValues({}, datum), {
        datum: extractDatum(datum.datum),
        values: datum.values.map(extractValues)
      }))
    });
  }
  validateRequests(requested) {
    const valid = [];
    const invalid = [];
    for (const [index, request] of requested.entries()) {
      if (index > 0 && request.data.length !== requested[0].data.length && request.opts.groupByData === false) {
        invalid.push(__spreadProps(__spreadValues({}, request), {
          error: new Error("all series[].data arrays must be of the same length and have matching keys.")
        }));
      } else {
        valid.push(request);
      }
    }
    return { valid, invalid };
  }
  mergeRequested(requested) {
    const grouped = [];
    const keys = (props) => {
      return props.filter((p) => p.type === "key").map((p) => p.property).join(";");
    };
    const groupMatch = ({ opts, data }) => (gr) => {
      return (opts.groupByData === false || gr[0].data === data) && gr[0].opts.groupByKeys === opts.groupByKeys && gr[0].opts.dataVisible === opts.dataVisible && gr[0].opts.groupByFn === opts.groupByFn && keys(gr[0].opts.props) === keys(opts.props);
    };
    const propMatch = (prop) => (existing) => {
      var _a;
      if (existing.type !== prop.type)
        return false;
      const diff2 = (_a = jsonDiff(existing, prop)) != null ? _a : {};
      delete diff2["scopes"];
      delete diff2["id"];
      delete diff2["ids"];
      if ("useScopedValues" in diff2) {
        delete diff2["useScopedValues"];
      }
      return Object.keys(diff2).length === 0;
    };
    const updateKeyValueOpts = (prop) => {
      var _a;
      if (prop.type !== "key" && prop.type !== "value")
        return;
      const uniqueScopes = new Set((_a = prop.scopes) != null ? _a : []);
      prop.useScopedValues = uniqueScopes.size > 1;
    };
    const mergeOpts = (opts) => {
      return __spreadProps(__spreadValues({}, opts[0]), {
        props: opts.reduce((result, next) => {
          var _a, _b, _c, _d, _e, _f;
          for (const prop of next.props) {
            if (prop.id != null) {
              (_a = prop.ids) != null ? _a : prop.ids = [];
              (_b = prop.scopes) == null ? void 0 : _b.forEach((scope) => {
                var _a2;
                return (_a2 = prop.ids) == null ? void 0 : _a2.push([scope, prop.id]);
              });
            }
            const match = result.find(propMatch(prop));
            if (!match) {
              updateKeyValueOpts(prop);
              result.push(prop);
              continue;
            }
            (_c = match.scopes) != null ? _c : match.scopes = [];
            match.scopes.push(...(_d = prop.scopes) != null ? _d : []);
            updateKeyValueOpts(prop);
            if (match.type !== "key" && match.type !== "value")
              continue;
            (_f = match.ids) == null ? void 0 : _f.push(...(_e = prop.ids) != null ? _e : []);
          }
          return result;
        }, [])
      });
    };
    const merge = (props) => {
      return {
        ids: props.map(({ id }) => id),
        resultCbs: props.map(({ resultCb }) => resultCb),
        rejects: props.map(({ reject }) => reject),
        data: props[0].data,
        opts: mergeOpts(props.map(({ opts }) => opts))
      };
    };
    for (const request of requested) {
      const match = grouped.find(groupMatch(request));
      if (match) {
        match.push(request);
      } else {
        grouped.push([request]);
      }
    }
    return grouped.map(merge);
  }
  splitResult(dataModel, processedData, scopes, resultCbs) {
    for (let index = 0; index < scopes.length; index++) {
      const scope = scopes[index];
      const resultCb = resultCbs[index];
      resultCb({
        dataModel,
        processedData: __spreadProps(__spreadValues({}, processedData), {
          data: processedData.data.filter(({ validScopes }) => {
            return validScopes == null || validScopes.some((s) => s === scope);
          })
        })
      });
    }
  }
};

// packages/ag-charts-community/src/util/listeners.ts
var Listeners = class {
  constructor() {
    this.registeredListeners = /* @__PURE__ */ new Map();
  }
  addListener(eventType, handler, meta) {
    const record = { symbol: Symbol(eventType), handler, meta };
    if (this.registeredListeners.has(eventType)) {
      this.registeredListeners.get(eventType).push(record);
    } else {
      this.registeredListeners.set(eventType, [record]);
    }
    return () => this.removeListener(record.symbol);
  }
  removeListener(eventSymbol) {
    for (const [type, listeners] of this.registeredListeners.entries()) {
      const matchIndex = listeners.findIndex((listener) => listener.symbol === eventSymbol);
      if (matchIndex >= 0) {
        listeners.splice(matchIndex, 1);
        if (listeners.length === 0) {
          this.registeredListeners.delete(type);
        }
        break;
      }
    }
  }
  dispatch(eventType, ...params) {
    for (const listener of this.getListenersByType(eventType)) {
      try {
        listener.handler(...params);
      } catch (e) {
        Logger.errorOnce(e);
      }
    }
  }
  dispatchWrapHandlers(eventType, wrapFn, ...params) {
    for (const listener of this.getListenersByType(eventType)) {
      try {
        wrapFn(listener.handler, listener.meta, ...params);
      } catch (e) {
        Logger.errorOnce(e);
      }
    }
  }
  getListenersByType(eventType) {
    var _a;
    return (_a = this.registeredListeners.get(eventType)) != null ? _a : [];
  }
};

// packages/ag-charts-community/src/chart/interaction/baseManager.ts
var BaseManager = class {
  constructor() {
    this.listeners = new Listeners();
  }
  addListener(type, handler, meta) {
    return this.listeners.addListener(type, handler, meta);
  }
  removeListener(listenerSymbol) {
    this.listeners.removeListener(listenerSymbol);
  }
};

// packages/ag-charts-community/src/chart/interaction/animationManager.ts
var DEBUG_SELECTORS = [true, "animation"];
var AnimationManager = class extends BaseManager {
  constructor(interactionManager, chartUpdateMutex) {
    super();
    this.interactionManager = interactionManager;
    this.chartUpdateMutex = chartUpdateMutex;
    this.defaultDuration = 1e3;
    this.batch = new AnimationBatch();
    this.debug = Debug.create(...DEBUG_SELECTORS);
    this.isPlaying = false;
    this.requestId = null;
    this.skipAnimations = false;
  }
  /**
   * Create an animation to tween a value between the `from` and `to` properties. If an animation already exists
   * with the same `id`, immediately stop it.
   */
  animate(_a) {
    var _b = _a, {
      disableInteractions = true,
      immutable = true
    } = _b, opts = __objRest(_b, [
      "disableInteractions",
      "immutable"
    ]);
    var _a2, _b2;
    const { batch } = this;
    try {
      if (opts.id != null && batch.controllers.has(opts.id)) {
        if (!immutable) {
          return batch.controllers.get(opts.id).reset(opts);
        }
        batch.controllers.get(opts.id).stop();
        this.debug(`Skipping animation batch due to update of existing animation: ${opts.id}`);
        this.batch.skip();
      }
    } catch (error) {
      this.failsafeOnError(error);
      return;
    }
    const id = (_a2 = opts.id) != null ? _a2 : Math.random().toString();
    const skip = this.isSkipped();
    if (skip) {
      this.debug("AnimationManager - skipping animation");
    }
    return new Animation(__spreadProps(__spreadValues({}, opts), {
      id,
      skip,
      autoplay: this.isPlaying ? opts.autoplay : false,
      duration: (_b2 = opts.duration) != null ? _b2 : this.defaultDuration,
      onPlay: (controller) => {
        var _a3;
        batch.controllers.set(id, controller);
        this.requestAnimation();
        if (disableInteractions) {
          this.interactionManager.pause("animation");
        }
        (_a3 = opts.onPlay) == null ? void 0 : _a3.call(controller, controller);
      },
      onStop: (controller) => {
        var _a3;
        batch.controllers.delete(id);
        if (disableInteractions) {
          this.interactionManager.resume("animation");
        }
        (_a3 = opts.onStop) == null ? void 0 : _a3.call(controller, controller);
      }
    }));
  }
  play() {
    if (this.isPlaying) {
      return;
    }
    this.isPlaying = true;
    this.debug("AnimationManager.play()");
    for (const controller of this.batch.controllers.values()) {
      try {
        controller.play();
      } catch (error) {
        this.failsafeOnError(error);
      }
    }
    this.requestAnimation();
  }
  pause() {
    if (!this.isPlaying) {
      return;
    }
    this.isPlaying = false;
    this.cancelAnimation();
    this.debug("AnimationManager.pause()");
    for (const controller of this.batch.controllers.values()) {
      try {
        controller.pause();
      } catch (error) {
        this.failsafeOnError(error);
      }
    }
  }
  stop() {
    this.isPlaying = false;
    this.cancelAnimation();
    this.debug("AnimationManager.stop()");
    for (const controller of this.batch.controllers.values()) {
      try {
        controller.stop();
      } catch (error) {
        this.failsafeOnError(error, false);
      }
    }
  }
  stopByAnimationId(id) {
    var _a;
    try {
      if (id != null && this.batch.controllers.has(id)) {
        (_a = this.batch.controllers.get(id)) == null ? void 0 : _a.stop();
      }
    } catch (error) {
      this.failsafeOnError(error);
      return;
    }
  }
  stopByAnimationGroupId(id) {
    for (const controller of this.batch.controllers.values()) {
      if (controller.groupId === id) {
        this.stopByAnimationId(controller.id);
      }
    }
  }
  reset() {
    if (this.isPlaying) {
      this.stop();
      this.play();
    } else {
      this.stop();
    }
  }
  skip(skip = true) {
    this.skipAnimations = skip;
  }
  isSkipped() {
    return this.skipAnimations || this.batch.isSkipped();
  }
  isActive() {
    return this.isPlaying && this.batch.isActive();
  }
  skipCurrentBatch() {
    if (Debug.check(...DEBUG_SELECTORS)) {
      this.debug(`AnimationManager - skipCurrentBatch()`, { stack: new Error().stack });
    }
    this.batch.skip();
  }
  /** Mocking point for tests to guarantee that animation updates happen. */
  isSkippingFrames() {
    return true;
  }
  /** Mocking point for tests to capture requestAnimationFrame callbacks. */
  scheduleAnimationFrame(cb) {
    this.requestId = requestAnimationFrame(cb);
  }
  requestAnimation() {
    if (!this.batch.isActive() || this.requestId !== null)
      return;
    let prevTime;
    const onAnimationFrame = (time) => __async(this, null, function* () {
      const executeAnimationFrame = () => __async(this, null, function* () {
        const deltaTime = time - (prevTime != null ? prevTime : time);
        prevTime = time;
        this.debug("AnimationManager - onAnimationFrame()", {
          controllersCount: this.batch.controllers.size
        });
        for (const controller of this.batch.controllers.values()) {
          try {
            controller.update(deltaTime);
          } catch (error) {
            this.failsafeOnError(error);
          }
        }
        this.listeners.dispatch("animation-frame", {
          type: "animation-frame",
          deltaMs: deltaTime
        });
      });
      if (this.isSkippingFrames()) {
        yield this.chartUpdateMutex.acquireImmediately(executeAnimationFrame);
      } else {
        yield this.chartUpdateMutex.acquire(executeAnimationFrame);
      }
      if (this.batch.isActive()) {
        this.scheduleAnimationFrame(onAnimationFrame);
      }
    });
    this.scheduleAnimationFrame(onAnimationFrame);
  }
  cancelAnimation() {
    if (this.requestId === null)
      return;
    cancelAnimationFrame(this.requestId);
    this.requestId = null;
    this.startBatch();
  }
  failsafeOnError(error, cancelAnimation = true) {
    Logger.error("Error during animation, skipping animations", error);
    if (cancelAnimation) {
      this.cancelAnimation();
    }
  }
  startBatch(skipAnimations) {
    this.debug(`AnimationManager - startBatch() with skipAnimations=${skipAnimations}.`);
    this.reset();
    this.batch.destroy();
    this.batch = new AnimationBatch();
    if (skipAnimations === true) {
      this.batch.skip();
    }
  }
  endBatch() {
    this.debug(
      `AnimationManager - endBatch() with ${this.batch.controllers.size} animations; skipped: ${this.batch.isSkipped()}.`
    );
    if (this.batch.isSkipped() && !this.batch.isActive()) {
      this.batch.skip(false);
    }
  }
};
var AnimationBatch = class {
  constructor() {
    this.controllers = /* @__PURE__ */ new Map();
    this.skipAnimations = false;
  }
  // private phase?: 'initial-load' | 'remove' | 'update' | 'add';
  isActive() {
    return this.controllers.size > 0;
  }
  skip(skip = true) {
    if (this.skipAnimations === false && skip === true) {
      for (const controller of this.controllers.values()) {
        controller.stop();
      }
      this.controllers.clear();
    }
    this.skipAnimations = skip;
  }
  isSkipped() {
    return this.skipAnimations;
  }
  destroy() {
  }
};

// packages/ag-charts-community/src/chart/interaction/chartEventManager.ts
var ChartEventManager = class extends BaseManager {
  legendItemClick(series, itemId, enabled, legendItemName) {
    const event = {
      type: "legend-item-click",
      series,
      itemId,
      enabled,
      legendItemName
    };
    this.listeners.dispatch("legend-item-click", event);
  }
  legendItemDoubleClick(series, itemId, enabled, numVisibleItems, legendItemName) {
    const event = {
      type: "legend-item-double-click",
      series,
      itemId,
      enabled,
      legendItemName,
      numVisibleItems
    };
    this.listeners.dispatch("legend-item-double-click", event);
  }
  axisHover(axisId, direction) {
    const event = {
      type: "axis-hover",
      axisId,
      direction
    };
    this.listeners.dispatch("axis-hover", event);
  }
};

// packages/ag-charts-community/src/chart/interaction/cursorManager.ts
var CursorManager = class {
  constructor(element2) {
    this.states = {};
    this.element = element2;
  }
  updateCursor(callerId, style) {
    delete this.states[callerId];
    if (style != null) {
      this.states[callerId] = { style };
    }
    this.applyStates();
  }
  applyStates() {
    let styleToApply = "default";
    Object.entries(this.states).reverse().slice(0, 1).forEach(([_, { style }]) => styleToApply = style);
    this.element.style.cursor = styleToApply;
  }
  getCursor() {
    return this.element.style.cursor;
  }
};

// packages/ag-charts-community/src/chart/interaction/highlightManager.ts
var HighlightManager = class extends BaseManager {
  constructor() {
    super(...arguments);
    this.highlightStates = /* @__PURE__ */ new Map();
    this.pickedStates = /* @__PURE__ */ new Map();
  }
  updateHighlight(callerId, highlightedDatum) {
    this.highlightStates.delete(callerId);
    if (highlightedDatum != null) {
      this.highlightStates.set(callerId, highlightedDatum);
    }
    this.applyHighlightStates();
  }
  getActiveHighlight() {
    return this.activeHighlight;
  }
  updatePicked(callerId, clickableDatum) {
    this.pickedStates.delete(callerId);
    if (clickableDatum != null) {
      this.pickedStates.set(callerId, clickableDatum);
    }
    this.applyPickedStates();
  }
  getActivePicked() {
    return this.activePicked;
  }
  applyHighlightStates() {
    const { activeHighlight: previousHighlight } = this;
    this.activeHighlight = Array.from(this.highlightStates.values()).pop();
    if (!this.isEqual(this.activeHighlight, previousHighlight)) {
      this.listeners.dispatch("highlight-change", {
        type: "highlight-change",
        currentHighlight: this.activeHighlight,
        previousHighlight
      });
    }
  }
  applyPickedStates() {
    this.activePicked = Array.from(this.pickedStates.values()).pop();
  }
  isEqual(a, b) {
    return a === b || (a == null ? void 0 : a.series) === (b == null ? void 0 : b.series) && (a == null ? void 0 : a.itemId) === (b == null ? void 0 : b.itemId) && (a == null ? void 0 : a.datum) === (b == null ? void 0 : b.datum);
  }
};

// packages/ag-charts-community/src/chart/interaction/interactionManager.ts
var WINDOW_EVENT_HANDLERS = ["pagehide", "mousemove", "mouseup"];
var EVENT_HANDLERS = [
  "click",
  "dblclick",
  "contextmenu",
  "mousedown",
  "mouseout",
  "mouseenter",
  "touchstart",
  "touchmove",
  "touchend",
  "touchcancel",
  "wheel"
];
var CSS = `
.ag-chart-wrapper {
    touch-action: none;
}
`;
var _InteractionManager = class _InteractionManager extends BaseManager {
  constructor(element2, document2, window2) {
    super();
    this.eventHandler = (event) => this.processEvent(event);
    this.mouseDown = false;
    this.touchDown = false;
    this.pausers = { animation: 0, "context-menu": 0 };
    this.rootElement = document2.body;
    this.element = element2;
    this.window = window2;
    for (const type of EVENT_HANDLERS) {
      if (type.startsWith("touch")) {
        element2.addEventListener(type, this.eventHandler, { passive: true });
      } else if (type === "wheel") {
        element2.addEventListener(type, this.eventHandler, { passive: false });
      } else {
        element2.addEventListener(type, this.eventHandler);
      }
    }
    for (const type of WINDOW_EVENT_HANDLERS) {
      this.window.addEventListener(type, this.eventHandler);
    }
    if (!_InteractionManager.interactionDocuments.includes(document2)) {
      injectStyle(document2, CSS);
      _InteractionManager.interactionDocuments.push(document2);
    }
  }
  destroy() {
    for (const type of WINDOW_EVENT_HANDLERS) {
      this.window.removeEventListener(type, this.eventHandler);
    }
    for (const type of EVENT_HANDLERS) {
      this.element.removeEventListener(type, this.eventHandler);
    }
  }
  resume(pauseType) {
    this.pausers[pauseType]--;
  }
  pause(pauseType) {
    this.pausers[pauseType]++;
  }
  processEvent(event) {
    const types = this.decideInteractionEventTypes(event);
    if (types.length > 0) {
      this.dispatchEvent(event, types).catch((e) => Logger.errorOnce(e));
    }
  }
  dispatchEvent(event, types) {
    return __async(this, null, function* () {
      const coords = this.calculateCoordinates(event);
      if (coords == null) {
        return;
      }
      const pauses = Object.entries(this.pausers).filter(([, count2]) => count2 > 0).map(([pause]) => pause);
      for (const type of types) {
        this.listeners.dispatchWrapHandlers(
          type,
          (handler, meta, interactionEvent) => {
            var _a;
            if (pauses.length > 0 && !((_a = meta == null ? void 0 : meta.bypassPause) == null ? void 0 : _a.some((p) => pauses.includes(p)))) {
              return;
            }
            if (!interactionEvent.consumed) {
              handler(interactionEvent);
            }
          },
          this.buildEvent(__spreadValues({ type, event, pauses }, coords))
        );
      }
    });
  }
  decideInteractionEventTypes(event) {
    const dragStart = "drag-start";
    switch (event.type) {
      case "click":
        return ["click"];
      case "dblclick":
        return ["dblclick"];
      case "contextmenu":
        return ["contextmenu"];
      case "mousedown":
        this.mouseDown = true;
        this.dragStartElement = event.target;
        return [dragStart];
      case "touchstart":
        this.touchDown = true;
        this.dragStartElement = event.target;
        return [dragStart];
      case "touchmove":
      case "mousemove":
        if (!this.mouseDown && !this.touchDown && !this.isEventOverElement(event)) {
          return [];
        }
        return this.mouseDown || this.touchDown ? ["drag"] : ["hover"];
      case "mouseup":
        if (!this.mouseDown && !this.isEventOverElement(event)) {
          return [];
        }
        this.mouseDown = false;
        this.dragStartElement = void 0;
        return ["drag-end"];
      case "touchend":
        if (!this.touchDown && !this.isEventOverElement(event)) {
          return [];
        }
        this.touchDown = false;
        this.dragStartElement = void 0;
        return ["drag-end"];
      case "mouseout":
      case "touchcancel":
        return ["leave"];
      case "mouseenter":
        const mouseButtonDown = event instanceof MouseEvent && (event.buttons & 1) === 1;
        if (this.mouseDown !== mouseButtonDown) {
          this.mouseDown = mouseButtonDown;
          return [mouseButtonDown ? dragStart : "drag-end"];
        }
        return [];
      case "pagehide":
        return ["page-left"];
      case "wheel":
        return ["wheel"];
    }
    return [];
  }
  isEventOverElement(event) {
    var _a;
    return event.target === this.element || ((_a = event.target) == null ? void 0 : _a.parentElement) === this.element;
  }
  calculateCoordinates(event) {
    var _a;
    if (event instanceof MouseEvent) {
      const { clientX, clientY, pageX, pageY, offsetX, offsetY } = event;
      return this.fixOffsets(event, { clientX, clientY, pageX, pageY, offsetX, offsetY });
    } else if (typeof TouchEvent !== "undefined" && event instanceof TouchEvent) {
      const lastTouch = (_a = event.touches[0]) != null ? _a : event.changedTouches[0];
      const { clientX, clientY, pageX, pageY } = lastTouch;
      return __spreadProps(__spreadValues({}, _InteractionManager.NULL_COORDS), { clientX, clientY, pageX, pageY });
    } else if (event instanceof PageTransitionEvent) {
      if (event.persisted) {
        return;
      }
      return _InteractionManager.NULL_COORDS;
    }
  }
  fixOffsets(event, coords) {
    const offsets = (el) => {
      let x = 0;
      let y = 0;
      while (el) {
        x += el.offsetLeft;
        y += el.offsetTop;
        el = el.offsetParent;
      }
      return { x, y };
    };
    if (this.dragStartElement != null && event.target !== this.dragStartElement) {
      const offsetDragStart = offsets(this.dragStartElement);
      const offsetEvent = offsets(event.target);
      coords.offsetX -= offsetDragStart.x - offsetEvent.x;
      coords.offsetY -= offsetDragStart.y - offsetEvent.y;
    }
    return coords;
  }
  buildEvent(opts) {
    const { type, event, clientX, clientY, pauses } = opts;
    let { offsetX, offsetY, pageX, pageY } = opts;
    if (!isNumber2(offsetX) || !isNumber2(offsetY)) {
      const rect = this.element.getBoundingClientRect();
      offsetX = clientX - rect.left;
      offsetY = clientY - rect.top;
    }
    if (!isNumber2(pageX) || !isNumber2(pageY)) {
      const pageRect = this.rootElement.getBoundingClientRect();
      pageX = clientX - pageRect.left;
      pageY = clientY - pageRect.top;
    }
    const builtEvent = {
      type,
      offsetX,
      offsetY,
      pageX,
      pageY,
      sourceEvent: event,
      consumed: false,
      pauses,
      consume() {
        builtEvent.consumed = true;
      }
    };
    return builtEvent;
  }
};
_InteractionManager.interactionDocuments = [];
_InteractionManager.NULL_COORDS = {
  clientX: -Infinity,
  clientY: -Infinity,
  pageX: -Infinity,
  pageY: -Infinity,
  offsetX: -Infinity,
  offsetY: -Infinity
};
var InteractionManager = _InteractionManager;

// packages/ag-charts-community/src/chart/interaction/tooltipManager.ts
var TooltipManager = class {
  constructor(tooltip, interactionManager) {
    this.states = {};
    this.exclusiveAreas = {};
    this.destroyFns = [];
    this.tooltip = tooltip;
    this.destroyFns.push(interactionManager.addListener("hover", (e) => this.checkExclusiveRects(e)));
  }
  getRange() {
    return this.tooltip.range;
  }
  updateTooltip(callerId, meta, content) {
    var _a;
    if (content == null) {
      content = (_a = this.states[callerId]) == null ? void 0 : _a.content;
    }
    this.states[callerId] = { content, meta };
    this.applyStates();
  }
  updateExclusiveRect(callerId, area2) {
    if (area2) {
      this.exclusiveAreas[callerId] = area2;
    } else {
      delete this.exclusiveAreas[callerId];
    }
  }
  removeTooltip(callerId) {
    delete this.states[callerId];
    this.applyStates();
  }
  getTooltipMeta(callerId) {
    var _a;
    return (_a = this.states[callerId]) == null ? void 0 : _a.meta;
  }
  destroy() {
    for (const destroyFn of this.destroyFns) {
      destroyFn();
    }
  }
  checkExclusiveRects(e) {
    let newAppliedExclusiveArea;
    for (const [entryId, area2] of Object.entries(this.exclusiveAreas)) {
      if (!area2.containsPoint(e.offsetX, e.offsetY)) {
        continue;
      }
      newAppliedExclusiveArea = entryId;
      break;
    }
    if (newAppliedExclusiveArea === this.appliedExclusiveArea) {
      return;
    }
    this.appliedExclusiveArea = newAppliedExclusiveArea;
    this.applyStates();
  }
  applyStates() {
    var _a;
    const ids = this.appliedExclusiveArea ? [this.appliedExclusiveArea] : Object.keys(this.states);
    let contentToApply;
    let metaToApply;
    ids.reverse();
    ids.slice(0, 1).forEach((id) => {
      var _a2;
      const { content, meta } = (_a2 = this.states[id]) != null ? _a2 : {};
      contentToApply = content;
      metaToApply = meta;
    });
    if (metaToApply === void 0 || contentToApply === void 0) {
      this.appliedState = void 0;
      this.tooltip.toggle(false);
      return;
    }
    if (((_a = this.appliedState) == null ? void 0 : _a.content) === contentToApply) {
      const renderInstantly = this.tooltip.isVisible();
      this.tooltip.show(metaToApply, void 0, renderInstantly);
    } else {
      this.tooltip.show(metaToApply, contentToApply);
    }
    this.appliedState = { content: contentToApply, meta: metaToApply };
  }
  static makeTooltipMeta(event, canvas, datum, window2) {
    var _a, _b, _c, _d;
    const { pageX, pageY, offsetX, offsetY } = event;
    const { tooltip } = datum.series.properties;
    const position = {
      xOffset: tooltip.position.xOffset,
      yOffset: tooltip.position.yOffset
    };
    const meta = {
      pageX,
      pageY,
      offsetX,
      offsetY,
      event,
      showArrow: tooltip.showArrow,
      position
    };
    const refPoint = (_b = (_a = datum.yBar) == null ? void 0 : _a.upperPoint) != null ? _b : datum.midPoint;
    if (tooltip.position.type === "node" && refPoint) {
      const { x, y } = refPoint;
      const point = datum.series.contentGroup.inverseTransformPoint(x, y);
      const canvasRect = canvas.element.getBoundingClientRect();
      return __spreadProps(__spreadValues({}, meta), {
        pageX: Math.round(canvasRect.left + window2.scrollX + point.x),
        pageY: Math.round(canvasRect.top + window2.scrollY + point.y),
        offsetX: Math.round(point.x),
        offsetY: Math.round(point.y)
      });
    }
    meta.enableInteraction = (_d = (_c = tooltip.interaction) == null ? void 0 : _c.enabled) != null ? _d : false;
    return meta;
  }
};

// packages/ag-charts-community/src/chart/interaction/zoomManager.ts
var ZoomManager = class extends BaseManager {
  constructor() {
    super(...arguments);
    this.axes = {};
  }
  updateAxes(axes) {
    var _a;
    const removedAxes = new Set(Object.keys(this.axes));
    axes.forEach((axis) => {
      var _a2, _b, _c;
      removedAxes.delete(axis.id);
      (_c = (_a2 = this.axes)[_b = axis.id]) != null ? _c : _a2[_b] = new AxisZoomManager(axis);
    });
    removedAxes.forEach((axisId) => {
      delete this.axes[axisId];
    });
    if ((_a = this.initialZoom) == null ? void 0 : _a.newZoom) {
      this.updateZoom(this.initialZoom.callerId, this.initialZoom.newZoom);
    }
    this.initialZoom = void 0;
  }
  updateZoom(callerId, newZoom) {
    if (Object.keys(this.axes).length === 0) {
      this.initialZoom = { callerId, newZoom };
      return;
    }
    Object.values(this.axes).forEach((axis) => {
      axis.updateZoom(callerId, newZoom == null ? void 0 : newZoom[axis.getDirection()]);
    });
    this.applyStates();
  }
  updateAxisZoom(callerId, axisId, newZoom) {
    var _a;
    (_a = this.axes[axisId]) == null ? void 0 : _a.updateZoom(callerId, newZoom);
    this.applyStates();
  }
  getZoom() {
    let x;
    let y;
    Object.values(this.axes).forEach((axis) => {
      if (axis.getDirection() === "x" /* X */) {
        x = axis.getZoom();
      } else if (axis.getDirection() === "y" /* Y */) {
        y = axis.getZoom();
      }
    });
    if (x || y) {
      return { x, y };
    }
  }
  getAxisZoom(axisId) {
    var _a;
    return (_a = this.axes[axisId]) == null ? void 0 : _a.getZoom();
  }
  getAxisZooms() {
    const axes = {};
    for (const [axisId, axis] of Object.entries(this.axes)) {
      axes[axisId] = {
        direction: axis.getDirection(),
        zoom: axis.getZoom()
      };
    }
    return axes;
  }
  applyStates() {
    const changed = Object.values(this.axes).map((axis) => axis.applyStates()).some(Boolean);
    if (!changed) {
      return;
    }
    const currentZoom = this.getZoom();
    const axes = {};
    for (const [axisId, axis] of Object.entries(this.axes)) {
      axes[axisId] = axis.getZoom();
    }
    this.listeners.dispatch("zoom-change", __spreadProps(__spreadValues({
      type: "zoom-change"
    }, currentZoom != null ? currentZoom : {}), {
      axes
    }));
  }
};
var AxisZoomManager = class {
  constructor(axis) {
    this.states = {};
    this.axis = axis;
    const [min = 0, max = 1] = axis.visibleRange;
    this.currentZoom = { min, max };
    this.states["__initial__"] = this.currentZoom;
  }
  getDirection() {
    return this.axis.direction;
  }
  updateZoom(callerId, newZoom) {
    delete this.states[callerId];
    if (newZoom != null) {
      this.states[callerId] = __spreadValues({}, newZoom);
    }
  }
  getZoom() {
    return this.currentZoom;
  }
  applyStates() {
    var _a, _b;
    const prevZoom = this.currentZoom;
    const last = Object.keys(this.states)[Object.keys(this.states).length - 1];
    this.currentZoom = __spreadValues({}, this.states[last]);
    return (prevZoom == null ? void 0 : prevZoom.min) !== ((_a = this.currentZoom) == null ? void 0 : _a.min) || (prevZoom == null ? void 0 : prevZoom.max) !== ((_b = this.currentZoom) == null ? void 0 : _b.max);
  }
};

// packages/ag-charts-community/src/chart/layout/layoutService.ts
var LayoutService = class extends Listeners {
  constructor() {
    super(...arguments);
    this.layoutComplete = "layout-complete";
  }
  addListener(eventType, handler) {
    if (this.isLayoutStage(eventType) || this.isLayoutComplete(eventType)) {
      return super.addListener(eventType, handler);
    }
    throw new Error(`AG Charts - unsupported listener type: ${eventType}`);
  }
  dispatchPerformLayout(stage, ctx) {
    if (this.isLayoutStage(stage)) {
      return this.getListenersByType(stage).reduce((result, listener) => {
        try {
          return listener.handler(result);
        } catch (e) {
          Logger.errorOnce(e);
          return result;
        }
      }, ctx);
    }
    return ctx;
  }
  dispatchLayoutComplete(event) {
    this.dispatch(this.layoutComplete, event);
  }
  isLayoutStage(eventType) {
    return eventType !== this.layoutComplete;
  }
  isLayoutComplete(eventType) {
    return eventType === this.layoutComplete;
  }
};

// packages/ag-charts-community/src/chart/gridLayout.ts
function gridLayout({
  orientation,
  bboxes,
  maxHeight,
  maxWidth,
  itemPaddingY = 0,
  itemPaddingX = 0,
  forceResult = false
}) {
  const horizontal = orientation === "horizontal";
  const primary = {
    max: horizontal ? maxWidth : maxHeight,
    fn: horizontal ? (b) => b.width : (b) => b.height,
    padding: horizontal ? itemPaddingX : itemPaddingY
  };
  const secondary = {
    max: !horizontal ? maxWidth : maxHeight,
    fn: !horizontal ? (b) => b.width : (b) => b.height,
    padding: !horizontal ? itemPaddingX : itemPaddingY
  };
  let processedBBoxCount = 0;
  const rawPages = [];
  while (processedBBoxCount < bboxes.length) {
    const unprocessedBBoxes = bboxes.slice(processedBBoxCount);
    const result = processBBoxes(unprocessedBBoxes, processedBBoxCount, primary, secondary, forceResult);
    if (!result) {
      return;
    }
    processedBBoxCount += result.processedBBoxCount;
    rawPages.push(result.pageIndices);
  }
  return buildPages(rawPages, orientation, bboxes, itemPaddingY, itemPaddingX);
}
function processBBoxes(bboxes, indexOffset, primary, secondary, forceResult) {
  const minGuess = 1;
  let startingGuess = estimateStartingGuess(bboxes, primary);
  if (startingGuess < minGuess) {
    if (!forceResult) {
      return void 0;
    }
    startingGuess = minGuess;
  }
  for (let guess = startingGuess; guess >= minGuess; guess--) {
    const pageIndices = calculatePage(bboxes, indexOffset, guess, primary, secondary, forceResult);
    if (pageIndices == null && guess <= minGuess) {
      return void 0;
    }
    if (pageIndices == null) {
      continue;
    }
    if (typeof pageIndices === "number") {
      if (pageIndices <= minGuess) {
        return void 0;
      }
      guess = pageIndices < guess && pageIndices > minGuess ? pageIndices : guess;
      continue;
    }
    const processedBBoxCount = pageIndices.length * pageIndices[0].length;
    return { processedBBoxCount, pageIndices };
  }
}
function calculatePage(bboxes, indexOffset, primaryCount, primary, secondary, forceResult) {
  var _a;
  const result = [];
  let sumSecondary = 0;
  let currentMaxSecondary = 0;
  let currentPrimaryIndices = [];
  const maxPrimaryValues = [];
  for (let bboxIndex = 0; bboxIndex < bboxes.length; bboxIndex++) {
    const primaryValueIdx = (bboxIndex + primaryCount) % primaryCount;
    if (primaryValueIdx === 0) {
      sumSecondary += currentMaxSecondary;
      currentMaxSecondary = 0;
      if (currentPrimaryIndices.length > 0) {
        result.push(currentPrimaryIndices);
      }
      currentPrimaryIndices = [];
    }
    const primaryValue = primary.fn(bboxes[bboxIndex]) + primary.padding;
    maxPrimaryValues[primaryValueIdx] = Math.max((_a = maxPrimaryValues[primaryValueIdx]) != null ? _a : 0, primaryValue);
    currentMaxSecondary = Math.max(currentMaxSecondary, secondary.fn(bboxes[bboxIndex]) + secondary.padding);
    const currentSecondaryDimension = sumSecondary + currentMaxSecondary;
    const returnResult = !forceResult || result.length > 0;
    if (currentSecondaryDimension > secondary.max && returnResult) {
      currentPrimaryIndices = [];
      break;
    }
    const sumPrimary = maxPrimaryValues.reduce((sum2, next) => sum2 + next, 0);
    if (sumPrimary > primary.max && !forceResult) {
      if (maxPrimaryValues.length < primaryCount) {
        return maxPrimaryValues.length;
      }
      return void 0;
    }
    currentPrimaryIndices.push(bboxIndex + indexOffset);
  }
  if (currentPrimaryIndices.length > 0) {
    result.push(currentPrimaryIndices);
  }
  return result.length > 0 ? result : void 0;
}
function buildPages(rawPages, orientation, bboxes, itemPaddingY, itemPaddingX) {
  let maxPageWidth = 0;
  let maxPageHeight = 0;
  const pages = rawPages.map((indices) => {
    if (orientation === "horizontal") {
      indices = transpose(indices);
    }
    let endIndex = 0;
    const columns = indices.map((colIndices) => {
      const colBBoxes = colIndices.map((bboxIndex) => {
        endIndex = Math.max(bboxIndex, endIndex);
        return bboxes[bboxIndex];
      });
      let columnHeight = 0;
      let columnWidth = 0;
      colBBoxes.forEach((bbox) => {
        columnHeight += bbox.height + itemPaddingY;
        columnWidth = Math.max(columnWidth, bbox.width + itemPaddingX);
      });
      return {
        indices: colIndices,
        bboxes: colBBoxes,
        columnHeight: Math.ceil(columnHeight),
        columnWidth: Math.ceil(columnWidth)
      };
    });
    let pageWidth = 0;
    let pageHeight = 0;
    columns.forEach((column) => {
      pageWidth += column.columnWidth;
      pageHeight = Math.max(pageHeight, column.columnHeight);
    });
    maxPageWidth = Math.max(pageWidth, maxPageWidth);
    maxPageHeight = Math.max(pageHeight, maxPageHeight);
    return {
      columns,
      startIndex: indices[0][0],
      endIndex,
      pageWidth,
      pageHeight
    };
  });
  return { pages, maxPageWidth, maxPageHeight };
}
function transpose(data) {
  const result = [];
  for (const _ of data[0]) {
    result.push([]);
  }
  data.forEach((innerData, dataIdx) => {
    innerData.forEach((item, itemIdx) => {
      result[itemIdx][dataIdx] = item;
    });
  });
  return result;
}
function estimateStartingGuess(bboxes, primary) {
  const n = bboxes.length;
  let primarySum = 0;
  for (let bboxIndex = 0; bboxIndex < n; bboxIndex++) {
    primarySum += primary.fn(bboxes[bboxIndex]) + primary.padding;
    if (primarySum > primary.max) {
      const ratio = n / bboxIndex;
      if (ratio < 2) {
        return Math.ceil(n / 2);
      }
      return bboxIndex;
    }
  }
  return n;
}

// packages/ag-charts-community/src/chart/markerLabel.ts
var MarkerLabel = class extends Group {
  constructor() {
    super({ name: "markerLabelGroup" });
    this.label = new Text();
    this.line = new Line();
    this._marker = new Square();
    this._markerSize = 15;
    this._spacing = 8;
    const { marker, label, line } = this;
    label.textBaseline = "middle";
    label.fontSize = 12;
    label.fontFamily = "Verdana, sans-serif";
    label.fill = "black";
    label.y = HdpiCanvas.has.textMetrics ? 1 : 0;
    this.append([line, marker, label]);
    this.update();
  }
  set marker(value) {
    if (this._marker !== value) {
      this.removeChild(this._marker);
      this._marker = value;
      this.appendChild(value);
      this.update();
    }
  }
  get marker() {
    return this._marker;
  }
  set markerSize(value) {
    if (this._markerSize !== value) {
      this._markerSize = value;
      this.update();
    }
  }
  get markerSize() {
    return this._markerSize;
  }
  set spacing(value) {
    if (this._spacing !== value) {
      this._spacing = value;
      this.update();
    }
  }
  get spacing() {
    return this._spacing;
  }
  setSeriesStrokeOffset(xOff) {
    const offset4 = this.marker.size / 2 + xOff;
    this.line.x1 = -offset4;
    this.line.x2 = offset4;
    this.line.y1 = 0;
    this.line.y2 = 0;
    this.line.markDirtyTransform();
    this.update();
  }
  update() {
    this.marker.size = this.markerSize;
    const lineEnd = this.line.visible ? this.line.x2 : -Infinity;
    const markerEnd = this.markerSize / 2;
    this.label.x = Math.max(lineEnd, markerEnd) + this.spacing;
  }
  render(renderCtx) {
    this.marker.opacity = this.opacity;
    this.label.opacity = this.opacity;
    this.line.opacity = this.opacity;
    super.render(renderCtx);
  }
};
MarkerLabel.className = "MarkerLabel";
__decorateClass([
  ProxyPropertyOnWrite("label")
], MarkerLabel.prototype, "text", 2);
__decorateClass([
  ProxyPropertyOnWrite("label")
], MarkerLabel.prototype, "fontStyle", 2);
__decorateClass([
  ProxyPropertyOnWrite("label")
], MarkerLabel.prototype, "fontWeight", 2);
__decorateClass([
  ProxyPropertyOnWrite("label")
], MarkerLabel.prototype, "fontSize", 2);
__decorateClass([
  ProxyPropertyOnWrite("label")
], MarkerLabel.prototype, "fontFamily", 2);
__decorateClass([
  ProxyPropertyOnWrite("label", "fill")
], MarkerLabel.prototype, "color", 2);
__decorateClass([
  ProxyPropertyOnWrite("marker", "fill")
], MarkerLabel.prototype, "markerFill", 2);
__decorateClass([
  ProxyPropertyOnWrite("marker", "stroke")
], MarkerLabel.prototype, "markerStroke", 2);
__decorateClass([
  ProxyPropertyOnWrite("marker", "strokeWidth")
], MarkerLabel.prototype, "markerStrokeWidth", 2);
__decorateClass([
  ProxyPropertyOnWrite("marker", "fillOpacity")
], MarkerLabel.prototype, "markerFillOpacity", 2);
__decorateClass([
  ProxyPropertyOnWrite("marker", "strokeOpacity")
], MarkerLabel.prototype, "markerStrokeOpacity", 2);
__decorateClass([
  ProxyPropertyOnWrite("marker", "visible")
], MarkerLabel.prototype, "markerVisible", 2);
__decorateClass([
  ProxyPropertyOnWrite("line", "stroke")
], MarkerLabel.prototype, "lineStroke", 2);
__decorateClass([
  ProxyPropertyOnWrite("line", "strokeWidth")
], MarkerLabel.prototype, "lineStrokeWidth", 2);
__decorateClass([
  ProxyPropertyOnWrite("line", "strokeOpacity")
], MarkerLabel.prototype, "lineStrokeOpacity", 2);
__decorateClass([
  ProxyPropertyOnWrite("line", "lineDash")
], MarkerLabel.prototype, "lineLineDash", 2);
__decorateClass([
  ProxyPropertyOnWrite("line", "visible")
], MarkerLabel.prototype, "lineVisible", 2);

// packages/ag-charts-community/src/chart/pagination/pagination.ts
var PaginationLabel = class {
  constructor() {
    this.color = "black";
    this.fontStyle = void 0;
    this.fontWeight = void 0;
    this.fontSize = 12;
    this.fontFamily = "Verdana, sans-serif";
  }
};
__decorateClass([
  Validate(COLOR_STRING)
], PaginationLabel.prototype, "color", 2);
__decorateClass([
  Validate(FONT_STYLE, { optional: true })
], PaginationLabel.prototype, "fontStyle", 2);
__decorateClass([
  Validate(FONT_WEIGHT, { optional: true })
], PaginationLabel.prototype, "fontWeight", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], PaginationLabel.prototype, "fontSize", 2);
__decorateClass([
  Validate(STRING)
], PaginationLabel.prototype, "fontFamily", 2);
var PaginationMarkerStyle = class {
  constructor() {
    this.size = 15;
    this.fill = void 0;
    this.fillOpacity = void 0;
    this.stroke = void 0;
    this.strokeWidth = 1;
    this.strokeOpacity = 1;
  }
};
__decorateClass([
  Validate(POSITIVE_NUMBER)
], PaginationMarkerStyle.prototype, "size", 2);
__decorateClass([
  Validate(COLOR_STRING, { optional: true })
], PaginationMarkerStyle.prototype, "fill", 2);
__decorateClass([
  Validate(RATIO, { optional: true })
], PaginationMarkerStyle.prototype, "fillOpacity", 2);
__decorateClass([
  Validate(COLOR_STRING, { optional: true })
], PaginationMarkerStyle.prototype, "stroke", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], PaginationMarkerStyle.prototype, "strokeWidth", 2);
__decorateClass([
  Validate(RATIO)
], PaginationMarkerStyle.prototype, "strokeOpacity", 2);
var PaginationMarker = class {
  constructor(parent) {
    this.parent = parent;
    this.shape = Triangle;
    this.size = 15;
    this.padding = 8;
  }
};
__decorateClass([
  ActionOnSet({
    changeValue() {
      if (this.parent.marker === this) {
        this.parent.onMarkerShapeChange();
      }
    }
  })
], PaginationMarker.prototype, "shape", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], PaginationMarker.prototype, "size", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], PaginationMarker.prototype, "padding", 2);
var Pagination = class {
  constructor(chartUpdateCallback, pageUpdateCallback, interactionManager, cursorManager) {
    this.chartUpdateCallback = chartUpdateCallback;
    this.pageUpdateCallback = pageUpdateCallback;
    this.interactionManager = interactionManager;
    this.cursorManager = cursorManager;
    this.id = createId$1(this);
    this.group = new Group({ name: "pagination" });
    this.labelNode = new Text();
    this.marker = new PaginationMarker(this);
    this.activeStyle = new PaginationMarkerStyle();
    this.inactiveStyle = new PaginationMarkerStyle();
    this.highlightStyle = new PaginationMarkerStyle();
    this.label = new PaginationLabel();
    this.totalPages = 0;
    this.currentPage = 0;
    this.translationX = 0;
    this.translationY = 0;
    this.nextButtonDisabled = false;
    this.previousButtonDisabled = false;
    this._visible = true;
    this._enabled = true;
    this._orientation = "vertical";
    this._nextButton = new Triangle();
    this._previousButton = new Triangle();
    const { labelNode } = this;
    labelNode.textBaseline = "middle";
    labelNode.fontSize = 12;
    labelNode.fontFamily = "Verdana, sans-serif";
    labelNode.fill = "black";
    labelNode.y = HdpiCanvas.has.textMetrics ? 1 : 0;
    this.group.append([this.nextButton, this.previousButton, labelNode]);
    this.interactionManager.addListener("click", (event) => this.onPaginationClick(event));
    this.interactionManager.addListener("hover", (event) => this.onPaginationMouseMove(event));
    this.update();
    this.updateMarkers();
  }
  set visible(value) {
    this._visible = value;
    this.updateGroupVisibility();
  }
  get visible() {
    return this._visible;
  }
  set enabled(value) {
    this._enabled = value;
    this.updateGroupVisibility();
  }
  get enabled() {
    return this._enabled;
  }
  updateGroupVisibility() {
    this.group.visible = this.enabled && this.visible;
  }
  set orientation(value) {
    this._orientation = value;
    switch (value) {
      case "horizontal": {
        this.previousButton.rotation = -Math.PI / 2;
        this.nextButton.rotation = Math.PI / 2;
        break;
      }
      case "vertical":
      default: {
        this.previousButton.rotation = 0;
        this.nextButton.rotation = Math.PI;
      }
    }
  }
  get orientation() {
    return this._orientation;
  }
  set nextButton(value) {
    if (this._nextButton !== value) {
      this.group.removeChild(this._nextButton);
      this._nextButton = value;
      this.group.appendChild(value);
    }
  }
  get nextButton() {
    return this._nextButton;
  }
  set previousButton(value) {
    if (this._previousButton !== value) {
      this.group.removeChild(this._previousButton);
      this._previousButton = value;
      this.group.appendChild(value);
    }
  }
  get previousButton() {
    return this._previousButton;
  }
  update() {
    this.updateLabel();
    this.updatePositions();
    this.enableOrDisableButtons();
  }
  updatePositions() {
    this.group.translationX = this.translationX;
    this.group.translationY = this.translationY;
    this.updateLabelPosition();
    this.updateNextButtonPosition();
  }
  updateLabelPosition() {
    const { size: markerSize, padding: markerPadding } = this.marker;
    this.nextButton.size = markerSize;
    this.previousButton.size = markerSize;
    this.labelNode.x = markerSize / 2 + markerPadding;
  }
  updateNextButtonPosition() {
    const labelBBox = this.labelNode.computeBBox();
    this.nextButton.translationX = labelBBox.x + labelBBox.width + this.marker.size / 2 + this.marker.padding;
  }
  updateLabel() {
    const {
      currentPage,
      totalPages: pages,
      labelNode,
      label: { color, fontStyle, fontWeight, fontSize, fontFamily }
    } = this;
    labelNode.text = `${currentPage + 1} / ${pages}`;
    labelNode.fill = color;
    labelNode.fontStyle = fontStyle;
    labelNode.fontWeight = fontWeight;
    labelNode.fontSize = fontSize;
    labelNode.fontFamily = fontFamily;
  }
  updateMarkers() {
    const {
      nextButton,
      previousButton,
      nextButtonDisabled,
      previousButtonDisabled,
      activeStyle,
      inactiveStyle,
      highlightStyle,
      highlightActive
    } = this;
    const buttonStyle = (button, disabled) => {
      if (disabled) {
        return inactiveStyle;
      } else if (button === highlightActive) {
        return highlightStyle;
      }
      return activeStyle;
    };
    this.updateMarker(nextButton, buttonStyle("next", nextButtonDisabled));
    this.updateMarker(previousButton, buttonStyle("previous", previousButtonDisabled));
  }
  updateMarker(marker, style) {
    var _a;
    const { size } = this.marker;
    marker.size = size;
    marker.fill = style.fill;
    marker.fillOpacity = (_a = style.fillOpacity) != null ? _a : 1;
    marker.stroke = style.stroke;
    marker.strokeWidth = style.strokeWidth;
    marker.strokeOpacity = style.strokeOpacity;
  }
  enableOrDisableButtons() {
    const { currentPage, totalPages } = this;
    const zeroPagesToDisplay = totalPages === 0;
    const onLastPage = currentPage === totalPages - 1;
    const onFirstPage = currentPage === 0;
    this.nextButtonDisabled = onLastPage || zeroPagesToDisplay;
    this.previousButtonDisabled = onFirstPage || zeroPagesToDisplay;
  }
  nextButtonContainsPoint(offsetX, offsetY) {
    return !this.nextButtonDisabled && this.nextButton.containsPoint(offsetX, offsetY);
  }
  previousButtonContainsPoint(offsetX, offsetY) {
    return !this.previousButtonDisabled && this.previousButton.containsPoint(offsetX, offsetY);
  }
  onPaginationClick(event) {
    const { offsetX, offsetY } = event;
    if (this.nextButtonContainsPoint(offsetX, offsetY)) {
      this.incrementPage();
      this.onPaginationChanged();
      event.consume();
    } else if (this.previousButtonContainsPoint(offsetX, offsetY)) {
      this.decrementPage();
      this.onPaginationChanged();
      event.consume();
    }
  }
  onPaginationMouseMove(event) {
    const { offsetX, offsetY } = event;
    if (this.nextButtonContainsPoint(offsetX, offsetY)) {
      this.cursorManager.updateCursor(this.id, "pointer");
      this.highlightActive = "next";
    } else if (this.previousButtonContainsPoint(offsetX, offsetY)) {
      this.cursorManager.updateCursor(this.id, "pointer");
      this.highlightActive = "previous";
    } else {
      this.cursorManager.updateCursor(this.id);
      this.highlightActive = void 0;
    }
    this.updateMarkers();
    this.chartUpdateCallback(5 /* SCENE_RENDER */);
  }
  onPaginationChanged() {
    this.pageUpdateCallback(this.currentPage);
  }
  incrementPage() {
    this.currentPage = Math.min(this.currentPage + 1, this.totalPages - 1);
  }
  decrementPage() {
    this.currentPage = Math.max(this.currentPage - 1, 0);
  }
  onMarkerShapeChange() {
    const Marker2 = getMarker$1(this.marker.shape || Triangle);
    this.previousButton = new Marker2();
    this.nextButton = new Marker2();
    this.updatePositions();
    this.updateMarkers();
    this.chartUpdateCallback(5 /* SCENE_RENDER */);
  }
  attachPagination(node) {
    node.append(this.group);
  }
  computeBBox() {
    return this.group.computeBBox();
  }
};
Pagination.className = "Pagination";

// packages/ag-charts-community/src/chart/legend.ts
var LegendLabel = class {
  constructor() {
    this.maxLength = void 0;
    this.color = "black";
    this.fontStyle = void 0;
    this.fontWeight = void 0;
    this.fontSize = 12;
    this.fontFamily = "Verdana, sans-serif";
    this.formatter = void 0;
  }
};
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], LegendLabel.prototype, "maxLength", 2);
__decorateClass([
  Validate(COLOR_STRING)
], LegendLabel.prototype, "color", 2);
__decorateClass([
  Validate(FONT_STYLE, { optional: true })
], LegendLabel.prototype, "fontStyle", 2);
__decorateClass([
  Validate(FONT_WEIGHT, { optional: true })
], LegendLabel.prototype, "fontWeight", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], LegendLabel.prototype, "fontSize", 2);
__decorateClass([
  Validate(STRING)
], LegendLabel.prototype, "fontFamily", 2);
__decorateClass([
  Validate(FUNCTION, { optional: true })
], LegendLabel.prototype, "formatter", 2);
var LegendMarker = class {
  constructor() {
    this.size = 15;
    /**
     * If the marker type is set, the legend will always use that marker type for all its items,
     * regardless of the type that comes from the `data`.
     */
    this._shape = void 0;
    this.padding = 8;
    this.strokeWidth = void 0;
    this.enabled = true;
  }
  set shape(value) {
    var _a;
    this._shape = value;
    (_a = this.parent) == null ? void 0 : _a.onMarkerShapeChange();
  }
  get shape() {
    return this._shape;
  }
};
__decorateClass([
  Validate(POSITIVE_NUMBER)
], LegendMarker.prototype, "size", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], LegendMarker.prototype, "padding", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], LegendMarker.prototype, "strokeWidth", 2);
__decorateClass([
  Validate(BOOLEAN, { optional: true })
], LegendMarker.prototype, "enabled", 2);
var LegendLine = class {
  constructor() {
    this.strokeWidth = void 0;
    this.length = void 0;
  }
};
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], LegendLine.prototype, "strokeWidth", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], LegendLine.prototype, "length", 2);
var LegendItem = class {
  constructor() {
    this.marker = new LegendMarker();
    this.label = new LegendLabel();
    this.line = new LegendLine();
    this.maxWidth = void 0;
    this.paddingX = 16;
    this.paddingY = 8;
    this.toggleSeriesVisible = true;
    this.showSeriesStroke = false;
  }
};
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], LegendItem.prototype, "maxWidth", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], LegendItem.prototype, "paddingX", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], LegendItem.prototype, "paddingY", 2);
__decorateClass([
  Validate(BOOLEAN)
], LegendItem.prototype, "toggleSeriesVisible", 2);
__decorateClass([
  Validate(BOOLEAN)
], LegendItem.prototype, "showSeriesStroke", 2);
var LegendListeners = class {
  constructor() {
    this.legendItemClick = void 0;
    this.legendItemDoubleClick = void 0;
  }
};
__decorateClass([
  Validate(FUNCTION, { optional: true })
], LegendListeners.prototype, "legendItemClick", 2);
var Legend = class {
  constructor(ctx) {
    this.ctx = ctx;
    this.id = createId$1(this);
    this.group = new Group({ name: "legend", layer: true, zIndex: 9 /* LEGEND_ZINDEX */ });
    this.itemSelection = Selection.select(this.group, MarkerLabel);
    this.oldSize = [0, 0];
    this.pages = [];
    this.maxPageSize = [0, 0];
    /** Item index to track on re-pagination, so current page updates appropriately. */
    this.paginationTrackingIndex = 0;
    this.item = new LegendItem();
    this.listeners = new LegendListeners();
    this.truncatedItems = /* @__PURE__ */ new Set();
    this._data = [];
    this._enabled = true;
    this.position = "bottom";
    this.maxWidth = void 0;
    this.maxHeight = void 0;
    this.reverseOrder = void 0;
    this.preventHidingAll = void 0;
    this.destroyFns = [];
    this.spacing = 20;
    this.characterWidths = /* @__PURE__ */ new Map();
    this.size = [0, 0];
    this._visible = true;
    this.item.marker.parent = this;
    this.pagination = new Pagination(
      (type) => ctx.updateService.update(type),
      (page) => this.updatePageNumber(page),
      ctx.interactionManager,
      ctx.cursorManager
    );
    this.pagination.attachPagination(this.group);
    this.item.marker.parent = this;
    const bypass = { bypassPause: ["animation"] };
    this.destroyFns.push(
      ctx.interactionManager.addListener("click", (e) => this.checkLegendClick(e), bypass),
      ctx.interactionManager.addListener("dblclick", (e) => this.checkLegendDoubleClick(e), bypass),
      ctx.interactionManager.addListener("hover", (e) => this.handleLegendMouseMove(e)),
      ctx.layoutService.addListener("start-layout", (e) => this.positionLegend(e.shrinkRect)),
      () => this.detachLegend()
    );
  }
  set data(value) {
    this._data = value;
    this.updateGroupVisibility();
  }
  get data() {
    return this._data;
  }
  set enabled(value) {
    this._enabled = value;
    this.updateGroupVisibility();
  }
  get enabled() {
    return this._enabled;
  }
  getOrientation() {
    if (this.orientation !== void 0) {
      return this.orientation;
    }
    switch (this.position) {
      case "right":
      case "left":
        return "vertical";
      case "bottom":
      case "top":
        return "horizontal";
    }
  }
  destroy() {
    this.destroyFns.forEach((f) => f());
  }
  onMarkerShapeChange() {
    this.itemSelection.clear();
    this.group.markDirty(this.group, 2 /* MINOR */);
  }
  getCharacterWidths(font) {
    const { characterWidths } = this;
    if (characterWidths.has(font)) {
      return characterWidths.get(font);
    }
    const cw = {
      "...": HdpiCanvas.getTextSize("...", font).width
    };
    characterWidths.set(font, cw);
    return cw;
  }
  set visible(value) {
    this._visible = value;
    this.updateGroupVisibility();
  }
  get visible() {
    return this._visible;
  }
  updateGroupVisibility() {
    this.group.visible = this.enabled && this.visible && this.data.length > 0;
  }
  attachLegend(node) {
    node.append(this.group);
  }
  detachLegend() {
    var _a;
    (_a = this.group.parent) == null ? void 0 : _a.removeChild(this.group);
  }
  getItemLabel(datum) {
    const {
      ctx: { callbackCache }
    } = this;
    const { formatter } = this.item.label;
    if (formatter) {
      return callbackCache.call(formatter, {
        itemId: datum.itemId,
        value: datum.label.text,
        seriesId: datum.seriesId
      });
    }
    return datum.label.text;
  }
  /**
   * The method is given the desired size of the legend, which only serves as a hint.
   * The vertically oriented legend will take as much horizontal space as needed, but will
   * respect the height constraints, and the horizontal legend will take as much vertical
   * space as needed in an attempt not to exceed the given width.
   * After the layout is done, the {@link size} will contain the actual size of the legend.
   * If the actual size is not the same as the previous actual size, the legend will fire
   * the 'layoutChange' event to communicate that another layout is needed, and the above
   * process should be repeated.
   * @param width
   * @param height
   */
  performLayout(width, height) {
    const {
      paddingX,
      paddingY,
      label,
      maxWidth,
      marker: { size: markerSize, padding: markerPadding, shape: markerShape },
      label: { maxLength = Infinity, fontStyle, fontWeight, fontSize, fontFamily },
      line: itemLine,
      showSeriesStroke
    } = this.item;
    const data = [...this.data];
    if (this.reverseOrder) {
      data.reverse();
    }
    this.itemSelection.update(data);
    const bboxes = [];
    const font = getFont(label);
    const itemMaxWidthPercentage = 0.8;
    const maxItemWidth = maxWidth != null ? maxWidth : width * itemMaxWidthPercentage;
    const paddedMarkerWidth = markerSize + markerPadding + paddingX;
    this.itemSelection.each((markerLabel, datum) => {
      var _a, _b, _c;
      const Marker2 = getMarker$1(markerShape != null ? markerShape : datum.marker.shape);
      const markerEnabled = (_a = datum.marker.enabled) != null ? _a : this.item.marker.enabled;
      if (!(markerLabel.marker && markerLabel.marker instanceof Marker2)) {
        markerLabel.marker = new Marker2();
      }
      markerLabel.markerSize = markerSize;
      markerLabel.spacing = markerPadding;
      markerLabel.fontStyle = fontStyle;
      markerLabel.fontWeight = fontWeight;
      markerLabel.fontSize = fontSize;
      markerLabel.fontFamily = fontFamily;
      const id = (_b = datum.itemId) != null ? _b : datum.id;
      const labelText = this.getItemLabel(datum);
      const text = (labelText != null ? labelText : "<unknown>").replace(/\r?\n/g, " ");
      markerLabel.text = this.truncate(text, maxLength, maxItemWidth, paddedMarkerWidth, font, id);
      if (showSeriesStroke && datum.line !== void 0) {
        markerLabel.lineVisible = true;
        markerLabel.markerVisible = markerEnabled;
        markerLabel.setSeriesStrokeOffset((_c = itemLine.length) != null ? _c : 5);
      } else {
        markerLabel.lineVisible = false;
        markerLabel.markerVisible = true;
      }
      bboxes.push(markerLabel.computeBBox());
    });
    width = Math.max(1, width);
    height = Math.max(1, height);
    if (!isFinite(width)) {
      return false;
    }
    const size = this.size;
    const oldSize = this.oldSize;
    size[0] = width;
    size[1] = height;
    if (size[0] !== oldSize[0] || size[1] !== oldSize[1]) {
      oldSize[0] = size[0];
      oldSize[1] = size[1];
    }
    const { pages, maxPageHeight, maxPageWidth } = this.updatePagination(bboxes, width, height);
    this.pages = pages;
    this.maxPageSize = [maxPageWidth - paddingX, maxPageHeight - paddingY];
    const pageNumber = this.pagination.currentPage;
    const page = this.pages[pageNumber];
    if (this.pages.length < 1 || !page) {
      this.visible = false;
      return;
    }
    this.visible = true;
    this.updatePositions(pageNumber);
    this.update();
  }
  truncate(text, maxCharLength, maxItemWidth, paddedMarkerWidth, font, id) {
    const ellipsis2 = `...`;
    const textChars = text.split("");
    let addEllipsis = false;
    if (text.length > maxCharLength) {
      text = `${text.substring(0, maxCharLength)}`;
      addEllipsis = true;
    }
    const labelWidth = Math.floor(paddedMarkerWidth + HdpiCanvas.getTextSize(text, font).width);
    if (labelWidth > maxItemWidth) {
      let truncatedText = "";
      const characterWidths = this.getCharacterWidths(font);
      let cumulativeWidth = paddedMarkerWidth + characterWidths[ellipsis2];
      for (const char of textChars) {
        if (!characterWidths[char]) {
          characterWidths[char] = HdpiCanvas.getTextSize(char, font).width;
        }
        cumulativeWidth += characterWidths[char];
        if (cumulativeWidth > maxItemWidth) {
          break;
        }
        truncatedText += char;
      }
      text = truncatedText;
      addEllipsis = true;
    }
    if (addEllipsis) {
      text += ellipsis2;
      this.truncatedItems.add(id);
    } else {
      this.truncatedItems.delete(id);
    }
    return text;
  }
  updatePagination(bboxes, width, height) {
    const orientation = this.getOrientation();
    const trackingIndex = Math.min(this.paginationTrackingIndex, bboxes.length);
    this.pagination.orientation = orientation;
    this.pagination.translationX = 0;
    this.pagination.translationY = 0;
    const { pages, maxPageHeight, maxPageWidth, paginationBBox, paginationVertical } = this.calculatePagination(
      bboxes,
      width,
      height
    );
    const newCurrentPage = pages.findIndex((p) => p.endIndex >= trackingIndex);
    this.pagination.currentPage = Math.min(Math.max(newCurrentPage, 0), pages.length - 1);
    const { paddingX: itemPaddingX, paddingY: itemPaddingY } = this.item;
    const paginationComponentPadding = 8;
    const legendItemsWidth = maxPageWidth - itemPaddingX;
    const legendItemsHeight = maxPageHeight - itemPaddingY;
    let paginationX = 0;
    let paginationY = -paginationBBox.y - this.item.marker.size / 2;
    if (paginationVertical) {
      paginationY += legendItemsHeight + paginationComponentPadding;
    } else {
      paginationX += -paginationBBox.x + legendItemsWidth + paginationComponentPadding;
      paginationY += (legendItemsHeight - paginationBBox.height) / 2;
    }
    this.pagination.translationX = paginationX;
    this.pagination.translationY = paginationY;
    this.pagination.update();
    this.pagination.updateMarkers();
    return {
      maxPageHeight,
      maxPageWidth,
      pages
    };
  }
  calculatePagination(bboxes, width, height) {
    var _a, _b, _c;
    const { paddingX: itemPaddingX, paddingY: itemPaddingY } = this.item;
    const orientation = this.getOrientation();
    const paginationVertical = ["left", "right"].includes(this.position);
    let paginationBBox = this.pagination.computeBBox();
    let lastPassPaginationBBox = new BBox(0, 0, 0, 0);
    let pages = [];
    let maxPageWidth = 0;
    let maxPageHeight = 0;
    let count2 = 0;
    const stableOutput = (lastPassPaginationBBox2) => {
      const { width: width2, height: height2 } = lastPassPaginationBBox2;
      return width2 === paginationBBox.width && height2 === paginationBBox.height;
    };
    const forceResult = this.maxWidth !== void 0 || this.maxHeight !== void 0;
    do {
      if (count2++ > 10) {
        Logger.warn("unable to find stable legend layout.");
        break;
      }
      paginationBBox = lastPassPaginationBBox;
      const maxWidth = width - (paginationVertical ? 0 : paginationBBox.width);
      const maxHeight = height - (paginationVertical ? paginationBBox.height : 0);
      const layout = gridLayout({
        orientation,
        bboxes,
        maxHeight,
        maxWidth,
        itemPaddingY,
        itemPaddingX,
        forceResult
      });
      pages = (_a = layout == null ? void 0 : layout.pages) != null ? _a : [];
      maxPageWidth = (_b = layout == null ? void 0 : layout.maxPageWidth) != null ? _b : 0;
      maxPageHeight = (_c = layout == null ? void 0 : layout.maxPageHeight) != null ? _c : 0;
      const totalPages = pages.length;
      this.pagination.visible = totalPages > 1;
      this.pagination.totalPages = totalPages;
      this.pagination.update();
      lastPassPaginationBBox = this.pagination.computeBBox();
      if (!this.pagination.visible) {
        break;
      }
    } while (!stableOutput(lastPassPaginationBBox));
    return { maxPageWidth, maxPageHeight, pages, paginationBBox, paginationVertical };
  }
  updatePositions(pageNumber = 0) {
    const {
      item: { paddingY },
      itemSelection,
      pages
    } = this;
    if (pages.length < 1 || !pages[pageNumber]) {
      return;
    }
    const { columns, startIndex: visibleStart, endIndex: visibleEnd } = pages[pageNumber];
    let x = 0;
    let y = 0;
    const columnCount = columns.length;
    const rowCount = columns[0].indices.length;
    const horizontal = this.getOrientation() === "horizontal";
    const itemHeight = columns[0].bboxes[0].height + paddingY;
    const rowSumColumnWidths = [];
    itemSelection.each((markerLabel, _, i) => {
      var _a, _b;
      if (i < visibleStart || i > visibleEnd) {
        markerLabel.visible = false;
        return;
      }
      const pageIndex = i - visibleStart;
      let columnIndex = 0;
      let rowIndex = 0;
      if (horizontal) {
        columnIndex = pageIndex % columnCount;
        rowIndex = Math.floor(pageIndex / columnCount);
      } else {
        columnIndex = Math.floor(pageIndex / rowCount);
        rowIndex = pageIndex % rowCount;
      }
      markerLabel.visible = true;
      const column = columns[columnIndex];
      if (!column) {
        return;
      }
      y = itemHeight * rowIndex;
      x = (_a = rowSumColumnWidths[rowIndex]) != null ? _a : 0;
      rowSumColumnWidths[rowIndex] = ((_b = rowSumColumnWidths[rowIndex]) != null ? _b : 0) + column.columnWidth;
      markerLabel.translationX = Math.floor(x);
      markerLabel.translationY = Math.floor(y);
    });
  }
  updatePageNumber(pageNumber) {
    const { pages } = this;
    const { startIndex, endIndex } = pages[pageNumber];
    if (startIndex === 0) {
      this.paginationTrackingIndex = 0;
    } else if (pageNumber === pages.length - 1) {
      this.paginationTrackingIndex = endIndex;
    } else {
      this.paginationTrackingIndex = Math.floor((startIndex + endIndex) / 2);
    }
    this.pagination.update();
    this.pagination.updateMarkers();
    this.updatePositions(pageNumber);
    this.ctx.updateService.update(5 /* SCENE_RENDER */);
  }
  update() {
    const {
      label: { color },
      marker: itemMarker,
      line: itemLine,
      showSeriesStroke
    } = this.item;
    this.itemSelection.each((markerLabel, datum) => {
      var _a, _b;
      const marker = datum.marker;
      markerLabel.markerFill = marker.fill;
      markerLabel.markerStroke = marker.stroke;
      markerLabel.markerStrokeWidth = (_a = itemMarker.strokeWidth) != null ? _a : Math.min(2, marker.strokeWidth);
      markerLabel.markerFillOpacity = marker.fillOpacity;
      markerLabel.markerStrokeOpacity = marker.strokeOpacity;
      markerLabel.opacity = datum.enabled ? 1 : 0.5;
      markerLabel.color = color;
      const { line } = datum;
      if (showSeriesStroke && line !== void 0) {
        markerLabel.lineStroke = line.stroke;
        markerLabel.lineStrokeOpacity = line.strokeOpacity;
        markerLabel.lineStrokeWidth = (_b = itemLine.strokeWidth) != null ? _b : Math.min(2, line.strokeWidth);
        markerLabel.lineLineDash = line.lineDash;
      }
    });
  }
  getDatumForPoint(x, y) {
    const visibleChildBBoxes = [];
    const closestLeftTop = { dist: Infinity, datum: void 0 };
    for (const child of this.group.children) {
      if (!child.visible)
        continue;
      if (!(child instanceof MarkerLabel))
        continue;
      const childBBox = child.computeBBox();
      childBBox.grow(this.item.paddingX / 2, "horizontal");
      childBBox.grow(this.item.paddingY / 2, "vertical");
      if (childBBox.containsPoint(x, y)) {
        return child.datum;
      }
      const distX = x - childBBox.x - this.item.paddingX / 2;
      const distY = y - childBBox.y - this.item.paddingY / 2;
      const dist = __pow(distX, 2) + __pow(distY, 2);
      const toTheLeftTop = distX >= 0 && distY >= 0;
      if (toTheLeftTop && dist < closestLeftTop.dist) {
        closestLeftTop.dist = dist;
        closestLeftTop.datum = child.datum;
      }
      visibleChildBBoxes.push(childBBox);
    }
    const pageBBox = BBox.merge(visibleChildBBoxes);
    if (!pageBBox.containsPoint(x, y)) {
      return void 0;
    }
    return closestLeftTop.datum;
  }
  computeBBox() {
    return this.group.computeBBox();
  }
  computePagedBBox() {
    const actualBBox = this.group.computeBBox();
    if (this.pages.length <= 1) {
      return actualBBox;
    }
    const [maxPageWidth, maxPageHeight] = this.maxPageSize;
    actualBBox.height = Math.max(maxPageHeight, actualBBox.height);
    actualBBox.width = Math.max(maxPageWidth, actualBBox.width);
    return actualBBox;
  }
  checkLegendClick(event) {
    const {
      listeners: { legendItemClick },
      ctx: { chartService, highlightManager },
      item: { toggleSeriesVisible },
      preventHidingAll
    } = this;
    const { offsetX, offsetY } = event;
    const legendBBox = this.computeBBox();
    const pointerInsideLegend = this.group.visible && legendBBox.containsPoint(offsetX, offsetY);
    const datum = this.getDatumForPoint(offsetX, offsetY);
    if (!pointerInsideLegend || !datum) {
      return;
    }
    const { id, itemId, enabled } = datum;
    const series = chartService.series.find((s) => s.id === id);
    if (!series) {
      return;
    }
    event.consume();
    let newEnabled = enabled;
    if (toggleSeriesVisible) {
      newEnabled = !enabled;
      if (preventHidingAll && !newEnabled) {
        const numVisibleItems = chartService.series.flatMap((series2) => series2.getLegendData("category")).filter((datum2) => datum2.enabled).length;
        if (numVisibleItems < 2) {
          newEnabled = true;
        }
      }
      this.ctx.chartEventManager.legendItemClick(series, itemId, newEnabled, datum.legendItemName);
    }
    if (!newEnabled) {
      highlightManager.updateHighlight(this.id);
    } else {
      highlightManager.updateHighlight(this.id, {
        series,
        itemId,
        datum: void 0
      });
    }
    this.ctx.updateService.update(1 /* PROCESS_DATA */, { forceNodeDataRefresh: true });
    legendItemClick == null ? void 0 : legendItemClick({ type: "click", enabled: newEnabled, itemId, seriesId: series.id });
  }
  checkLegendDoubleClick(event) {
    var _a;
    const {
      listeners: { legendItemDoubleClick },
      ctx: { chartService },
      item: { toggleSeriesVisible }
    } = this;
    const { offsetX, offsetY } = event;
    if (chartService.mode === "integrated") {
      return;
    }
    const legendBBox = this.computeBBox();
    const pointerInsideLegend = this.group.visible && legendBBox.containsPoint(offsetX, offsetY);
    const datum = this.getDatumForPoint(offsetX, offsetY);
    if (!pointerInsideLegend || !datum) {
      return;
    }
    const { id, itemId, seriesId } = datum;
    const series = chartService.series.find((s) => s.id === id);
    if (!series) {
      return;
    }
    event.consume();
    if (toggleSeriesVisible) {
      const legendData = chartService.series.flatMap((series2) => series2.getLegendData("category"));
      const numVisibleItems = legendData.filter((datum2) => datum2.enabled).length;
      const clickedItem = legendData.find((d) => d.itemId === itemId && d.seriesId === seriesId);
      this.ctx.chartEventManager.legendItemDoubleClick(
        series,
        itemId,
        (_a = clickedItem == null ? void 0 : clickedItem.enabled) != null ? _a : false,
        numVisibleItems,
        clickedItem == null ? void 0 : clickedItem.legendItemName
      );
    }
    this.ctx.updateService.update(1 /* PROCESS_DATA */, { forceNodeDataRefresh: true });
    legendItemDoubleClick == null ? void 0 : legendItemDoubleClick({ type: "dblclick", enabled: true, itemId, seriesId: series.id });
  }
  handleLegendMouseMove(event) {
    var _a;
    const {
      enabled,
      item: { toggleSeriesVisible },
      listeners
    } = this;
    if (!enabled) {
      return;
    }
    const legendBBox = this.computeBBox();
    const { pageX, pageY, offsetX, offsetY } = event;
    const pointerInsideLegend = this.group.visible && legendBBox.containsPoint(offsetX, offsetY);
    if (!pointerInsideLegend) {
      this.ctx.cursorManager.updateCursor(this.id);
      this.ctx.highlightManager.updateHighlight(this.id);
      this.ctx.tooltipManager.removeTooltip(this.id);
      return;
    }
    event.consume();
    const datum = this.getDatumForPoint(offsetX, offsetY);
    const pointerOverLegendDatum = pointerInsideLegend && datum !== void 0;
    if (!pointerOverLegendDatum) {
      this.ctx.cursorManager.updateCursor(this.id);
      this.ctx.highlightManager.updateHighlight(this.id);
      return;
    }
    const series = datum ? this.ctx.chartService.series.find((series2) => series2.id === (datum == null ? void 0 : datum.id)) : void 0;
    if (datum && this.truncatedItems.has((_a = datum.itemId) != null ? _a : datum.id)) {
      this.ctx.tooltipManager.updateTooltip(
        this.id,
        { pageX, pageY, offsetX, offsetY, event, showArrow: false, addCustomClass: false },
        toTooltipHtml$1({ content: this.getItemLabel(datum) })
      );
    } else {
      this.ctx.tooltipManager.removeTooltip(this.id);
    }
    if (toggleSeriesVisible || listeners.legendItemClick != null || listeners.legendItemDoubleClick != null) {
      this.ctx.cursorManager.updateCursor(this.id, "pointer");
    }
    if ((datum == null ? void 0 : datum.enabled) && series) {
      this.ctx.highlightManager.updateHighlight(this.id, {
        series,
        itemId: datum == null ? void 0 : datum.itemId,
        datum: void 0
      });
    } else {
      this.ctx.highlightManager.updateHighlight(this.id);
    }
  }
  positionLegend(shrinkRect) {
    const newShrinkRect = shrinkRect.clone();
    if (!this.enabled || !this.data.length) {
      return { shrinkRect: newShrinkRect };
    }
    const [legendWidth, legendHeight] = this.calculateLegendDimensions(shrinkRect);
    this.group.translationX = 0;
    this.group.translationY = 0;
    this.performLayout(legendWidth, legendHeight);
    const legendBBox = this.computePagedBBox();
    const calculateTranslationPerpendicularDimension = () => {
      switch (this.position) {
        case "top":
        case "left":
          return 0;
        case "bottom":
          return shrinkRect.height - legendBBox.height;
        case "right":
        default:
          return shrinkRect.width - legendBBox.width;
      }
    };
    if (this.visible) {
      let translationX;
      let translationY;
      switch (this.position) {
        case "top":
        case "bottom":
          translationX = (shrinkRect.width - legendBBox.width) / 2;
          translationY = calculateTranslationPerpendicularDimension();
          newShrinkRect.shrink(legendBBox.height, this.position);
          break;
        case "left":
        case "right":
        default:
          translationX = calculateTranslationPerpendicularDimension();
          translationY = (shrinkRect.height - legendBBox.height) / 2;
          newShrinkRect.shrink(legendBBox.width, this.position);
      }
      this.group.translationX = Math.floor(-legendBBox.x + shrinkRect.x + translationX);
      this.group.translationY = Math.floor(-legendBBox.y + shrinkRect.y + translationY);
    }
    if (this.visible && this.enabled && this.data.length) {
      const legendPadding = this.spacing;
      newShrinkRect.shrink(legendPadding, this.position);
      const legendPositionedBBox = legendBBox.clone();
      legendPositionedBBox.x += this.group.translationX;
      legendPositionedBBox.y += this.group.translationY;
      this.ctx.tooltipManager.updateExclusiveRect(this.id, legendPositionedBBox);
    } else {
      this.ctx.tooltipManager.updateExclusiveRect(this.id);
    }
    return { shrinkRect: newShrinkRect };
  }
  calculateLegendDimensions(shrinkRect) {
    const { width, height } = shrinkRect;
    const aspectRatio = width / height;
    const maxCoefficient = 0.5;
    const minHeightCoefficient = 0.2;
    const minWidthCoefficient = 0.25;
    let legendWidth, legendHeight;
    switch (this.position) {
      case "top":
      case "bottom":
        const heightCoefficient = aspectRatio < 1 ? Math.min(maxCoefficient, minHeightCoefficient * (1 / aspectRatio)) : minHeightCoefficient;
        legendWidth = this.maxWidth ? Math.min(this.maxWidth, width) : width;
        legendHeight = this.maxHeight ? Math.min(this.maxHeight, height) : Math.round(height * heightCoefficient);
        break;
      case "left":
      case "right":
      default:
        const widthCoefficient = aspectRatio > 1 ? Math.min(maxCoefficient, minWidthCoefficient * aspectRatio) : minWidthCoefficient;
        legendWidth = this.maxWidth ? Math.min(this.maxWidth, width) : Math.round(width * widthCoefficient);
        legendHeight = this.maxHeight ? Math.min(this.maxHeight, height) : height;
    }
    return [legendWidth, legendHeight];
  }
};
Legend.className = "Legend";
__decorateClass([
  Validate(BOOLEAN)
], Legend.prototype, "_enabled", 2);
__decorateClass([
  Validate(POSITION)
], Legend.prototype, "position", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], Legend.prototype, "maxWidth", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], Legend.prototype, "maxHeight", 2);
__decorateClass([
  Validate(BOOLEAN, { optional: true })
], Legend.prototype, "reverseOrder", 2);
__decorateClass([
  Validate(UNION(["horizontal", "vertical"], "an orientation"), { optional: true })
], Legend.prototype, "orientation", 2);
__decorateClass([
  Validate(BOOLEAN, { optional: true })
], Legend.prototype, "preventHidingAll", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], Legend.prototype, "spacing", 2);

// packages/ag-charts-community/src/chart/overlay/overlay.ts
var Overlay = class {
  constructor(className, parentElement) {
    this.className = className;
    this.parentElement = parentElement;
  }
  show(rect) {
    var _a, _b;
    if (!this.element) {
      this.element = this.createElement("div");
      this.element.className = this.className;
    }
    const { element: element2 } = this;
    element2.style.position = "absolute";
    element2.style.left = `${rect.x}px`;
    element2.style.top = `${rect.y}px`;
    element2.style.width = `${rect.width}px`;
    element2.style.height = `${rect.height}px`;
    if (this.renderer) {
      element2.innerHTML = this.renderer();
    } else {
      const content = this.createElement("div");
      content.style.alignItems = "center";
      content.style.boxSizing = "border-box";
      content.style.display = "flex";
      content.style.justifyContent = "center";
      content.style.margin = "8px";
      content.style.height = "100%";
      content.style.font = "12px Verdana, sans-serif";
      content.innerText = (_a = this.text) != null ? _a : "No data to display";
      element2.replaceChildren(content);
    }
    (_b = this.parentElement) == null ? void 0 : _b.append(element2);
  }
  hide() {
    var _a;
    (_a = this.element) == null ? void 0 : _a.remove();
    this.element = void 0;
  }
  createElement(tagName, options) {
    return this.parentElement.ownerDocument.createElement(tagName, options);
  }
};
__decorateClass([
  Validate(FUNCTION, { optional: true })
], Overlay.prototype, "renderer", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], Overlay.prototype, "text", 2);

// packages/ag-charts-community/src/chart/overlay/chartOverlays.ts
var ChartOverlays = class {
  constructor(parent) {
    this.noData = new Overlay("ag-chart-no-data-overlay", parent);
    this.noVisibleSeries = new Overlay("ag-chart-no-visible-series", parent);
    this.noVisibleSeries.text = "No visible series";
  }
  destroy() {
    this.noData.hide();
    this.noVisibleSeries.hide();
  }
};

// packages/ag-charts-community/src/util/object.ts
function deepMerge(target, source) {
  if (isPlainObject2(target) && isPlainObject2(source)) {
    const result = {};
    Object.keys(target).forEach((key) => {
      if (key in source) {
        result[key] = deepMerge(target[key], source[key]);
      } else {
        result[key] = target[key];
      }
    });
    Object.keys(source).forEach((key) => {
      if (!(key in target)) {
        result[key] = source[key];
      }
    });
    return result;
  }
  if (Array.isArray(target) && !Array.isArray(source) || isObjectLike2(target) && !isObjectLike2(source)) {
    return target;
  }
  return source;
}
function isObjectLike2(value) {
  return typeof value === "object" && value !== null;
}
function isObject2(value) {
  return isObjectLike2(value) && !Array.isArray(value);
}
function isPlainObject2(x) {
  return isObject2(x) && x.constructor === Object;
}
function mergeDefaults(...sources) {
  var _a;
  const target = {};
  for (const source of sources) {
    if (!source)
      continue;
    const keys = isDecoratedObject(source) ? listDecoratedProperties(source) : Object.keys(source);
    for (const key of keys) {
      if (isObject2(target[key]) && isObject2(source[key])) {
        target[key] = mergeDefaults(target[key], source[key]);
      } else {
        (_a = target[key]) != null ? _a : target[key] = source[key];
      }
    }
  }
  return target;
}
function partialAssign(keysToCopy, target, source) {
  if (source === void 0) {
    return target;
  }
  for (const key of keysToCopy) {
    const value = source[key];
    if (value !== void 0) {
      target[key] = value;
    }
  }
  return target;
}

// packages/ag-charts-community/src/chart/data/aggregateFunctions.ts
function sumValues(values, accumulator = [0, 0]) {
  for (const value of values) {
    if (typeof value !== "number") {
      continue;
    }
    if (value < 0) {
      accumulator[0] += value;
    }
    if (value > 0) {
      accumulator[1] += value;
    }
  }
  return accumulator;
}
function sum(scope, id, matchGroupId) {
  const result = {
    id,
    scopes: [scope.id],
    matchGroupIds: [matchGroupId],
    type: "aggregate",
    aggregateFunction: (values) => sumValues(values)
  };
  return result;
}
function groupSum(scope, id, matchGroupId) {
  return {
    id,
    scopes: [scope.id],
    type: "aggregate",
    matchGroupIds: matchGroupId ? [matchGroupId] : void 0,
    aggregateFunction: (values) => sumValues(values),
    groupAggregateFunction: (next, acc = [0, 0]) => {
      var _a, _b;
      acc[0] += (_a = next == null ? void 0 : next[0]) != null ? _a : 0;
      acc[1] += (_b = next == null ? void 0 : next[1]) != null ? _b : 0;
      return acc;
    }
  };
}
function range2(scope, id, matchGroupId) {
  const result = {
    id,
    scopes: [scope.id],
    matchGroupIds: [matchGroupId],
    type: "aggregate",
    aggregateFunction: (values) => extendDomain(values)
  };
  return result;
}
function count(scope, id) {
  const result = {
    id,
    scopes: [scope.id],
    type: "aggregate",
    aggregateFunction: () => [0, 1]
  };
  return result;
}
function groupCount(scope, id) {
  return {
    id,
    scopes: [scope.id],
    type: "aggregate",
    aggregateFunction: () => [0, 1],
    groupAggregateFunction: (next, acc = [0, 0]) => {
      var _a, _b;
      acc[0] += (_a = next == null ? void 0 : next[0]) != null ? _a : 0;
      acc[1] += (_b = next == null ? void 0 : next[1]) != null ? _b : 0;
      return acc;
    }
  };
}
function average(scope, id, matchGroupId) {
  const result = {
    id,
    scopes: [scope.id],
    matchGroupIds: [matchGroupId],
    type: "aggregate",
    aggregateFunction: (values) => sumValues(values).map((v) => v / values.length)
  };
  return result;
}
function groupAverage(scope, id, matchGroupId) {
  const result = {
    id,
    scopes: [scope.id],
    matchGroupIds: matchGroupId ? [matchGroupId] : void 0,
    type: "aggregate",
    aggregateFunction: (values) => sumValues(values),
    groupAggregateFunction: (next, acc = [0, 0, -1]) => {
      var _a, _b;
      acc[0] += (_a = next == null ? void 0 : next[0]) != null ? _a : 0;
      acc[1] += (_b = next == null ? void 0 : next[1]) != null ? _b : 0;
      acc[2]++;
      return acc;
    },
    finalFunction: (acc = [0, 0, 0]) => {
      const result2 = acc[0] + acc[1];
      if (result2 >= 0) {
        return [0, result2 / acc[2]];
      }
      return [result2 / acc[2], 0];
    }
  };
  return result;
}
function area(scope, id, aggFn, matchGroupId) {
  const result = {
    id,
    scopes: [scope.id],
    matchGroupIds: matchGroupId ? [matchGroupId] : void 0,
    type: "aggregate",
    aggregateFunction: (values, keyRange = []) => {
      const keyWidth = keyRange[1] - keyRange[0];
      return aggFn.aggregateFunction(values).map((v) => v / keyWidth);
    }
  };
  if (aggFn.groupAggregateFunction) {
    result.groupAggregateFunction = aggFn.groupAggregateFunction;
  }
  return result;
}
function accumulatedValue(onlyPositive) {
  return () => {
    let value = 0;
    return (datum) => {
      if (typeof datum !== "number")
        return datum;
      if (isNaN(datum))
        return datum;
      value += onlyPositive ? Math.max(0, datum) : datum;
      return value;
    };
  };
}
function trailingAccumulatedValue() {
  return () => {
    let value = 0;
    return (datum) => {
      if (typeof datum !== "number")
        return datum;
      if (isNaN(datum))
        return datum;
      const trailingValue2 = value;
      value += datum;
      return trailingValue2;
    };
  };
}

// packages/ag-charts-community/src/util/memo.ts
var memorizedFns = /* @__PURE__ */ new Map();
function memo(params, fnGenerator) {
  var _a, _b, _c;
  const serialisedParams = JSON.stringify(params, null, 0);
  if (!memorizedFns.has(fnGenerator)) {
    memorizedFns.set(fnGenerator, /* @__PURE__ */ new Map());
  }
  if (!((_a = memorizedFns.get(fnGenerator)) == null ? void 0 : _a.has(serialisedParams))) {
    (_b = memorizedFns.get(fnGenerator)) == null ? void 0 : _b.set(serialisedParams, fnGenerator(params));
  }
  return (_c = memorizedFns.get(fnGenerator)) == null ? void 0 : _c.get(serialisedParams);
}

// packages/ag-charts-community/src/chart/data/processors.ts
var SMALLEST_KEY_INTERVAL = {
  type: "reducer",
  property: "smallestKeyInterval",
  initialValue: Infinity,
  reducer: () => {
    let prevX = NaN;
    return (smallestSoFar = Infinity, next) => {
      const nextX = next.keys[0];
      const interval = Math.abs(nextX - prevX);
      prevX = nextX;
      if (!isNaN(interval) && interval > 0 && interval < smallestSoFar) {
        return interval;
      }
      return smallestSoFar;
    };
  }
};
var AGG_VALUES_EXTENT = {
  type: "processor",
  property: "aggValuesExtent",
  calculate: (processedData) => {
    var _a, _b, _c, _d;
    const result = [...(_b = (_a = processedData.domain.aggValues) == null ? void 0 : _a[0]) != null ? _b : [0, 0]];
    for (const [min, max] of (_d = (_c = processedData.domain.aggValues) == null ? void 0 : _c.slice(1)) != null ? _d : []) {
      if (min < result[0]) {
        result[0] = min;
      }
      if (max > result[1]) {
        result[1] = max;
      }
    }
    return result;
  }
};
var SORT_DOMAIN_GROUPS = {
  type: "processor",
  property: "sortedGroupDomain",
  calculate: ({ domain: { groups } }) => {
    if (groups == null)
      return void 0;
    return [...groups].sort((a, b) => {
      for (let i = 0; i < a.length; i++) {
        const result = a[i] - b[i];
        if (result !== 0) {
          return result;
        }
      }
      return 0;
    });
  }
};
function normaliseFnBuilder({ normaliseTo, mode }) {
  const normalise = (val, extent2) => {
    const result = val * normaliseTo / extent2;
    if (result >= 0) {
      return Math.min(normaliseTo, result);
    }
    return Math.max(-normaliseTo, result);
  };
  return () => () => (values, valueIndexes) => {
    const valuesExtent = [0, 0];
    for (const valueIdx of valueIndexes) {
      const value = values[valueIdx];
      const valIdx = value < 0 ? 0 : 1;
      if (mode === "sum") {
        valuesExtent[valIdx] += value;
      } else if (valIdx === 0) {
        valuesExtent[valIdx] = Math.min(valuesExtent[valIdx], value);
      } else {
        valuesExtent[valIdx] = Math.max(valuesExtent[valIdx], value);
      }
    }
    const extent2 = Math.max(Math.abs(valuesExtent[0]), valuesExtent[1]);
    for (const valueIdx of valueIndexes) {
      values[valueIdx] = normalise(values[valueIdx], extent2);
    }
  };
}
function normaliseGroupTo(scope, matchGroupIds, normaliseTo, mode = "sum") {
  return {
    scopes: [scope.id],
    type: "group-value-processor",
    matchGroupIds,
    adjust: memo({ normaliseTo, mode }, normaliseFnBuilder)
  };
}
function normalisePropertyFnBuilder({
  normaliseTo,
  zeroDomain,
  rangeMin,
  rangeMax
}) {
  const normaliseSpan = normaliseTo[1] - normaliseTo[0];
  const normalise = (val, start, span) => {
    const result = normaliseTo[0] + (val - start) / span * normaliseSpan;
    if (span === 0)
      return zeroDomain;
    if (result >= normaliseTo[1])
      return normaliseTo[1];
    if (result < normaliseTo[0])
      return normaliseTo[0];
    return result;
  };
  return () => (pData, pIdx) => {
    let [start, end] = pData.domain.values[pIdx];
    if (rangeMin != null)
      start = rangeMin;
    if (rangeMax != null)
      end = rangeMax;
    const span = end - start;
    pData.domain.values[pIdx] = [normaliseTo[0], normaliseTo[1]];
    for (const group2 of pData.data) {
      let groupValues = group2.values;
      if (pData.type === "ungrouped") {
        groupValues = [groupValues];
      }
      for (const values of groupValues) {
        values[pIdx] = normalise(values[pIdx], start, span);
      }
    }
  };
}
function normalisePropertyTo(scope, property, normaliseTo, zeroDomain, rangeMin, rangeMax) {
  return {
    scopes: [scope.id],
    type: "property-value-processor",
    property,
    adjust: memo({ normaliseTo, rangeMin, rangeMax, zeroDomain }, normalisePropertyFnBuilder)
  };
}
function animationValidation(scope, valueKeyIds = []) {
  return {
    type: "processor",
    scopes: [scope.id],
    property: "animationValidation",
    calculate(result) {
      var _a;
      const { keys, values } = result.defs;
      const { input, data } = result;
      let uniqueKeys = true;
      let orderedKeys = true;
      const valueKeys = [];
      for (let k = 0; k < values.length; k++) {
        if (!((_a = values[k].scopes) == null ? void 0 : _a.some((s) => s === scope.id)))
          continue;
        if (!valueKeyIds.some((v) => values[k].id === v))
          continue;
        valueKeys.push([k, values[k]]);
      }
      const processKey = (idx, def, type) => {
        var _a2;
        if (def.valueType === "category") {
          const keyValues = result.domain[type][idx];
          uniqueKeys && (uniqueKeys = keyValues.length === input.count);
          return;
        }
        let lastValue = (_a2 = data[0]) == null ? void 0 : _a2[type][idx];
        for (let d = 1; (uniqueKeys || orderedKeys) && d < data.length; d++) {
          const keyValue = data[d][type][idx];
          orderedKeys && (orderedKeys = lastValue <= keyValue);
          uniqueKeys && (uniqueKeys = lastValue !== keyValue);
          lastValue = keyValue;
        }
      };
      for (let k = 0; (uniqueKeys || orderedKeys) && k < keys.length; k++) {
        processKey(k, keys[k], "keys");
      }
      for (let k = 0; (uniqueKeys || orderedKeys) && k < valueKeys.length; k++) {
        const [idx, key] = valueKeys[k];
        processKey(idx, key, "values");
      }
      return { uniqueKeys, orderedKeys };
    }
  };
}
function buildGroupAccFn({ mode, separateNegative }) {
  return () => () => (values, valueIndexes) => {
    const acc = [0, 0];
    for (const valueIdx of valueIndexes) {
      const currentVal = values[valueIdx];
      const accIndex = isNegative(currentVal) && separateNegative ? 0 : 1;
      if (typeof currentVal !== "number" || isNaN(currentVal))
        continue;
      if (mode === "normal")
        acc[accIndex] += currentVal;
      values[valueIdx] = acc[accIndex];
      if (mode === "trailing")
        acc[accIndex] += currentVal;
    }
  };
}
function buildGroupWindowAccFn({ mode, sum: sum2 }) {
  return () => {
    const lastValues = [];
    let firstRow = true;
    return () => {
      return (values, valueIndexes) => {
        let acc = 0;
        for (const valueIdx of valueIndexes) {
          const currentVal = values[valueIdx];
          const lastValue = firstRow && sum2 === "current" ? 0 : lastValues[valueIdx];
          lastValues[valueIdx] = currentVal;
          const sumValue = sum2 === "current" ? currentVal : lastValue;
          if (typeof currentVal !== "number" || isNaN(currentVal)) {
            values[valueIdx] = acc;
            continue;
          }
          if (typeof lastValue !== "number" || isNaN(lastValue)) {
            values[valueIdx] = acc;
            continue;
          }
          if (mode === "normal")
            acc += sumValue;
          values[valueIdx] = acc;
          if (mode === "trailing")
            acc += sumValue;
        }
        firstRow = false;
      };
    };
  };
}
function accumulateGroup(scope, matchGroupId, mode, sum2, separateNegative = false) {
  let adjust;
  if (mode.startsWith("window")) {
    const modeParam = mode.endsWith("-trailing") ? "trailing" : "normal";
    adjust = memo({ mode: modeParam, sum: sum2 }, buildGroupWindowAccFn);
  } else {
    adjust = memo({ mode, separateNegative }, buildGroupAccFn);
  }
  return {
    scopes: [scope.id],
    type: "group-value-processor",
    matchGroupIds: [matchGroupId],
    adjust
  };
}
function diff(previousData, updateMovedDatums = true) {
  return {
    type: "processor",
    property: "diff",
    calculate: (processedData) => {
      const diff2 = {
        changed: false,
        moved: [],
        added: [],
        updated: [],
        removed: [],
        addedIndices: [],
        updatedIndices: [],
        removedIndices: []
      };
      const moved = /* @__PURE__ */ new Map();
      const added = /* @__PURE__ */ new Map();
      const updated = /* @__PURE__ */ new Map();
      const removed = /* @__PURE__ */ new Map();
      const addedIndices = /* @__PURE__ */ new Map();
      const updatedIndices = /* @__PURE__ */ new Map();
      const removedIndices = /* @__PURE__ */ new Map();
      for (let i = 0; i < Math.max(previousData.data.length, processedData.data.length); i++) {
        const prev = previousData.data[i];
        const datum = processedData.data[i];
        const prevId = prev ? createDatumId(prev.keys) : "";
        const datumId = datum ? createDatumId(datum.keys) : "";
        if (prevId === datumId) {
          if (!arraysEqual(prev.values, datum.values)) {
            updated.set(datumId, datum);
            updatedIndices.set(datumId, i);
          }
          continue;
        }
        if (removed.has(datumId)) {
          if (updateMovedDatums || !arraysEqual(removed.get(datumId).values, datum.values)) {
            updated.set(datumId, datum);
            updatedIndices.set(datumId, i);
            moved.set(datumId, datum);
          }
          removed.delete(datumId);
          removedIndices.delete(datumId);
        } else if (datum) {
          added.set(datumId, datum);
          addedIndices.set(datumId, i);
        }
        if (added.has(prevId)) {
          if (updateMovedDatums || !arraysEqual(added.get(prevId).values, prev.values)) {
            updated.set(prevId, prev);
            updatedIndices.set(prevId, i);
            moved.set(prevId, prev);
          }
          added.delete(prevId);
          addedIndices.delete(prevId);
        } else if (prev) {
          updated.delete(prevId);
          updatedIndices.delete(prevId);
          removed.set(prevId, prev);
          removedIndices.set(prevId, i);
        }
      }
      diff2.added = Array.from(added.keys());
      diff2.updated = Array.from(updated.keys());
      diff2.removed = Array.from(removed.keys());
      diff2.moved = Array.from(moved.keys());
      diff2.addedIndices = Array.from(addedIndices.values());
      diff2.updatedIndices = Array.from(updatedIndices.values());
      diff2.removedIndices = Array.from(removedIndices.values());
      diff2.changed = diff2.added.length > 0 || diff2.updated.length > 0 || diff2.removed.length > 0;
      return diff2;
    }
  };
}
function createDatumId(keys) {
  return keys.join("___");
}

// packages/ag-charts-community/src/chart/series/series.ts
var SeriesNodePickMode = /* @__PURE__ */ ((SeriesNodePickMode2) => {
  SeriesNodePickMode2[SeriesNodePickMode2["EXACT_SHAPE_MATCH"] = 0] = "EXACT_SHAPE_MATCH";
  SeriesNodePickMode2[SeriesNodePickMode2["NEAREST_BY_MAIN_AXIS_FIRST"] = 1] = "NEAREST_BY_MAIN_AXIS_FIRST";
  SeriesNodePickMode2[SeriesNodePickMode2["NEAREST_BY_MAIN_CATEGORY_AXIS_FIRST"] = 2] = "NEAREST_BY_MAIN_CATEGORY_AXIS_FIRST";
  SeriesNodePickMode2[SeriesNodePickMode2["NEAREST_NODE"] = 3] = "NEAREST_NODE";
  return SeriesNodePickMode2;
})(SeriesNodePickMode || {});
function basicContinuousCheckDatumValidation(v) {
  return checkDatum(v, true) != null;
}
function basicDiscreteCheckDatumValidation(v) {
  return checkDatum(v, false) != null;
}
function keyProperty(scope, propName, continuous, opts = {}) {
  const result = __spreadValues({
    scopes: [scope.id],
    property: propName,
    type: "key",
    valueType: continuous ? "range" : "category",
    validation: continuous ? basicContinuousCheckDatumValidation : basicDiscreteCheckDatumValidation
  }, opts);
  return result;
}
function valueProperty(scope, propName, continuous, opts = {}) {
  const result = __spreadValues({
    scopes: [scope.id],
    property: propName,
    type: "value",
    valueType: continuous ? "range" : "category",
    validation: continuous ? basicContinuousCheckDatumValidation : basicDiscreteCheckDatumValidation
  }, opts);
  return result;
}
function rangedValueProperty(scope, propName, opts = {}) {
  const _a = opts, { min = -Infinity, max = Infinity } = _a, defOpts = __objRest(_a, ["min", "max"]);
  return __spreadValues({
    scopes: [scope.id],
    type: "value",
    property: propName,
    valueType: "range",
    validation: basicContinuousCheckDatumValidation,
    processor: () => (datum) => {
      if (typeof datum !== "number")
        return datum;
      if (isNaN(datum))
        return datum;
      return Math.min(Math.max(datum, min), max);
    }
  }, defOpts);
}
function trailingValueProperty(scope, propName, continuous, opts = {}) {
  const result = __spreadProps(__spreadValues({}, valueProperty(scope, propName, continuous, opts)), {
    processor: trailingValue()
  });
  return result;
}
function trailingValue() {
  return () => {
    let value = 0;
    return (datum) => {
      const trailingValue2 = value;
      value = datum;
      return trailingValue2;
    };
  };
}
function accumulativeValueProperty(scope, propName, continuous, opts = {}) {
  const _a = opts, { onlyPositive } = _a, defOpts = __objRest(_a, ["onlyPositive"]);
  const result = __spreadProps(__spreadValues({}, valueProperty(scope, propName, continuous, defOpts)), {
    processor: accumulatedValue(onlyPositive)
  });
  return result;
}
function trailingAccumulatedValueProperty(scope, propName, continuous, opts = {}) {
  const result = __spreadProps(__spreadValues({}, valueProperty(scope, propName, continuous, opts)), {
    processor: trailingAccumulatedValue()
  });
  return result;
}
function groupAccumulativeValueProperty(scope, propName, continuous, mode, sum2 = "current", opts) {
  return [
    valueProperty(scope, propName, continuous, opts),
    accumulateGroup(scope, opts.groupId, mode, sum2, opts.separateNegative),
    ...opts.rangeId != null ? [range2(scope, opts.rangeId, opts.groupId)] : []
  ];
}
var SeriesNodeClickEvent = class {
  constructor(type, event, { datum }, series) {
    this.type = type;
    this.event = event;
    this.datum = datum;
    this.seriesId = series.id;
  }
};
var Series = class extends Observable {
  constructor(seriesOpts) {
    super();
    this.destroyFns = [];
    this.seriesGrouping = void 0;
    this.NodeClickEvent = SeriesNodeClickEvent;
    this.internalId = createId$1(this);
    // The group node that contains all the nodes used to render this series.
    this.rootGroup = new Group({ name: "seriesRoot", isVirtual: true });
    this.axes = {
      ["x" /* X */]: void 0,
      ["y" /* Y */]: void 0
    };
    this.directions = ["x" /* X */, "y" /* Y */];
    // Flag to determine if we should recalculate node data.
    this.nodeDataRefresh = true;
    this.moduleMap = new ModuleMap();
    this._declarationOrder = -1;
    this.seriesListeners = new Listeners();
    const {
      moduleCtx,
      useLabelLayer = false,
      pickModes = [1 /* NEAREST_BY_MAIN_AXIS_FIRST */],
      directionKeys = {},
      directionNames = {},
      contentGroupVirtual = true,
      canHaveAxes = false
    } = seriesOpts;
    this.ctx = moduleCtx;
    this.directionKeys = directionKeys;
    this.directionNames = directionNames;
    this.canHaveAxes = canHaveAxes;
    this.contentGroup = this.rootGroup.appendChild(
      new Group({
        name: `${this.internalId}-content`,
        layer: !contentGroupVirtual,
        isVirtual: contentGroupVirtual,
        zIndex: 4 /* SERIES_LAYER_ZINDEX */,
        zIndexSubOrder: this.getGroupZIndexSubOrder("data")
      })
    );
    this.highlightGroup = new Group({
      name: `${this.internalId}-highlight`,
      layer: !contentGroupVirtual,
      isVirtual: contentGroupVirtual,
      zIndex: 4 /* SERIES_LAYER_ZINDEX */,
      zIndexSubOrder: this.getGroupZIndexSubOrder("highlight")
    });
    this.highlightNode = this.highlightGroup.appendChild(new Group({ name: "highlightNode", zIndex: 0 }));
    this.highlightLabel = this.highlightGroup.appendChild(new Group({ name: "highlightLabel", zIndex: 10 }));
    this.pickModes = pickModes;
    this.labelGroup = this.rootGroup.appendChild(
      new Group({
        name: `${this.internalId}-series-labels`,
        layer: useLabelLayer,
        zIndex: 7 /* SERIES_LABEL_ZINDEX */
      })
    );
    this.annotationGroup = new Group({
      name: `${this.id}-annotation`,
      layer: !contentGroupVirtual,
      isVirtual: contentGroupVirtual,
      zIndex: 4 /* SERIES_LAYER_ZINDEX */,
      zIndexSubOrder: this.getGroupZIndexSubOrder("annotation")
    });
  }
  get id() {
    var _a, _b;
    return (_b = (_a = this.properties) == null ? void 0 : _a.id) != null ? _b : this.internalId;
  }
  get type() {
    var _a;
    return (_a = this.constructor.type) != null ? _a : "";
  }
  set data(input) {
    this._data = input;
    this.onDataChange();
  }
  get data() {
    var _a;
    return (_a = this._data) != null ? _a : this._chartData;
  }
  set visible(value) {
    this.properties.visible = value;
    this.visibleChanged();
  }
  get visible() {
    return this.properties.visible;
  }
  onDataChange() {
    this.nodeDataRefresh = true;
  }
  setChartData(input) {
    this._chartData = input;
    if (this.data === input) {
      this.onDataChange();
    }
  }
  hasData() {
    const { data } = this;
    return data && (!Array.isArray(data) || data.length > 0);
  }
  onSeriesGroupingChange(prev, next) {
    const { internalId, type, visible, rootGroup, highlightGroup, annotationGroup } = this;
    if (prev) {
      this.ctx.seriesStateManager.deregisterSeries({ id: internalId, type });
    }
    if (next) {
      this.ctx.seriesStateManager.registerSeries({ id: internalId, type, visible, seriesGrouping: next });
    }
    if (this.rootGroup.parent == null)
      return;
    this.ctx.seriesLayerManager.changeGroup({
      internalId,
      type,
      rootGroup,
      highlightGroup,
      annotationGroup,
      getGroupZIndexSubOrder: (type2) => this.getGroupZIndexSubOrder(type2),
      seriesGrouping: next,
      oldGrouping: prev
    });
  }
  getBandScalePadding() {
    return { inner: 1, outer: 0 };
  }
  getGroupZIndexSubOrder(type, subIndex = 0) {
    let mainAdjust = 0;
    switch (type) {
      case "data":
      case "paths":
        break;
      case "labels":
        mainAdjust += 2e4;
        break;
      case "marker":
        mainAdjust += 1e4;
        break;
      case "highlight":
        subIndex += 15e3;
        break;
      case "annotation":
        mainAdjust += 15e3;
        break;
    }
    const main = () => this._declarationOrder + mainAdjust;
    return [main, subIndex];
  }
  addListener(type, listener) {
    return this.seriesListeners.addListener(type, listener);
  }
  dispatch(type, event) {
    this.seriesListeners.dispatch(type, event);
  }
  addChartEventListeners() {
    return;
  }
  destroy() {
    this.destroyFns.forEach((f) => f());
    this.ctx.seriesStateManager.deregisterSeries(this);
    this.ctx.seriesLayerManager.releaseGroup(this);
  }
  getDirectionValues(direction, properties) {
    const resolvedDirection = this.resolveKeyDirection(direction);
    const keys = properties == null ? void 0 : properties[resolvedDirection];
    const values = [];
    if (!keys)
      return values;
    const addValues = (...items) => {
      for (const value of items) {
        if (Array.isArray(value)) {
          addValues(...value);
        } else if (typeof value === "object") {
          addValues(...Object.values(value));
        } else {
          values.push(value);
        }
      }
    };
    addValues(...keys.map((key) => this.properties[key]));
    return values;
  }
  getKeys(direction) {
    return this.getDirectionValues(direction, this.directionKeys);
  }
  getNames(direction) {
    return this.getDirectionValues(direction, this.directionNames);
  }
  resolveKeyDirection(direction) {
    return direction;
  }
  // The union of the series domain ('community') and series-option domains ('enterprise').
  getDomain(direction) {
    const seriesDomain = this.getSeriesDomain(direction);
    const moduleDomains = this.moduleMap.mapValues((module) => module.getDomain(direction));
    return seriesDomain.concat(moduleDomains.flat());
  }
  // Indicate that something external changed and we should recalculate nodeData.
  markNodeDataDirty() {
    this.nodeDataRefresh = true;
  }
  visibleChanged() {
    this.ctx.seriesStateManager.registerSeries(this);
  }
  getOpacity() {
    const defaultOpacity = 1;
    const { dimOpacity = 1, enabled = true } = this.properties.highlightStyle.series;
    if (!enabled || dimOpacity === defaultOpacity) {
      return defaultOpacity;
    }
    switch (this.isItemIdHighlighted()) {
      case 0 /* None */:
      case 1 /* This */:
        return defaultOpacity;
      case 2 /* Other */:
      default:
        return dimOpacity;
    }
  }
  getStrokeWidth(defaultStrokeWidth) {
    const { strokeWidth, enabled = true } = this.properties.highlightStyle.series;
    if (!enabled || strokeWidth === void 0) {
      return defaultStrokeWidth;
    }
    switch (this.isItemIdHighlighted()) {
      case 1 /* This */:
        return strokeWidth;
      case 0 /* None */:
      case 2 /* Other */:
        return defaultStrokeWidth;
    }
  }
  isItemIdHighlighted() {
    var _a, _b;
    const { series } = (_b = (_a = this.ctx.highlightManager) == null ? void 0 : _a.getActiveHighlight()) != null ? _b : {};
    if (series == null) {
      return 0 /* None */;
    }
    if (series !== this) {
      return 2 /* Other */;
    }
    return 1 /* This */;
  }
  getModuleTooltipParams() {
    const params = this.moduleMap.mapValues((module) => module.getTooltipParams());
    return params.reduce((total, current) => __spreadValues(__spreadValues({}, current), total), {});
  }
  pickNode(point, limitPickModes) {
    const { pickModes, visible, rootGroup } = this;
    if (!visible || !rootGroup.visible) {
      return;
    }
    for (const pickMode of pickModes) {
      if (limitPickModes && !limitPickModes.includes(pickMode)) {
        continue;
      }
      let match;
      switch (pickMode) {
        case 0 /* EXACT_SHAPE_MATCH */:
          match = this.pickNodeExactShape(point);
          break;
        case 1 /* NEAREST_BY_MAIN_AXIS_FIRST */:
        case 2 /* NEAREST_BY_MAIN_CATEGORY_AXIS_FIRST */:
          match = this.pickNodeMainAxisFirst(
            point,
            pickMode === 2 /* NEAREST_BY_MAIN_CATEGORY_AXIS_FIRST */
          );
          break;
        case 3 /* NEAREST_NODE */:
          match = this.pickNodeClosestDatum(point);
          break;
      }
      if (match) {
        return { pickMode, match: match.datum, distance: match.distance };
      }
    }
  }
  pickNodeExactShape(point) {
    const match = this.contentGroup.pickNode(point.x, point.y);
    return match && { datum: match.datum, distance: 0 };
  }
  pickNodeClosestDatum(_point) {
    throw new Error("AG Charts - Series.pickNodeClosestDatum() not implemented");
  }
  pickNodeMainAxisFirst(_point, _requireCategoryAxis) {
    throw new Error("AG Charts - Series.pickNodeMainAxisFirst() not implemented");
  }
  fireNodeClickEvent(event, datum) {
    this.fireEvent(new this.NodeClickEvent("nodeClick", event, datum, this));
  }
  fireNodeDoubleClickEvent(event, datum) {
    this.fireEvent(new this.NodeClickEvent("nodeDoubleClick", event, datum, this));
  }
  toggleSeriesItem(itemId, enabled) {
    this.visible = enabled;
    this.nodeDataRefresh = true;
    this.dispatch("visibility-changed", { itemId, enabled });
  }
  isEnabled() {
    return this.visible;
  }
  getModuleMap() {
    return this.moduleMap;
  }
  createModuleContext() {
    return __spreadProps(__spreadValues({}, this.ctx), { series: this });
  }
  getLabelText(label, params, defaultFormatter = String) {
    var _a;
    if (label.formatter) {
      return (_a = this.ctx.callbackCache.call(label.formatter, __spreadValues({ seriesId: this.id }, params))) != null ? _a : defaultFormatter(params.value);
    }
    return defaultFormatter(params.value);
  }
  getMarkerStyle(marker, params, defaultStyle = marker.getStyle()) {
    var _a, _b;
    const defaultSize = { size: (_b = (_a = params.datum.point) == null ? void 0 : _a.size) != null ? _b : 0 };
    const markerStyle = mergeDefaults(defaultSize, defaultStyle);
    if (marker.formatter) {
      const style = this.ctx.callbackCache.call(marker.formatter, __spreadProps(__spreadValues(__spreadValues({
        seriesId: this.id
      }, markerStyle), params), {
        datum: params.datum.datum
      }));
      return mergeDefaults(style, markerStyle);
    }
    return markerStyle;
  }
  updateMarkerStyle(markerNode, marker, params, defaultStyle = marker.getStyle(), { applyTranslation = true } = {}) {
    const { point } = params.datum;
    const activeStyle = this.getMarkerStyle(marker, params, defaultStyle);
    const visible = this.visible && activeStyle.size > 0 && point && !isNaN(point.x) && !isNaN(point.y);
    if (applyTranslation) {
      markerNode.setProperties(__spreadProps(__spreadValues({ visible }, activeStyle), { translationX: point == null ? void 0 : point.x, translationY: point == null ? void 0 : point.y }));
    } else {
      markerNode.setProperties(__spreadValues({ visible }, activeStyle));
    }
    if (typeof marker.shape === "function" && !markerNode.dirtyPath) {
      markerNode.path.clear({ trackChanges: true });
      markerNode.updatePath();
      markerNode.checkPathDirty();
    }
  }
  getMinRect() {
    return void 0;
  }
  get nodeDataDependencies() {
    var _a;
    return (_a = this._nodeDataDependencies) != null ? _a : { seriesRectWidth: NaN, seriesRectHeight: NaN };
  }
  checkResize(newSeriesRect) {
    const { width: seriesRectWidth, height: seriesRectHeight } = newSeriesRect != null ? newSeriesRect : { width: NaN, height: NaN };
    const newNodeDataDependencies = newSeriesRect ? { seriesRectWidth, seriesRectHeight } : void 0;
    const resize = jsonDiff(this.nodeDataDependencies, newNodeDataDependencies) != null;
    if (resize) {
      this._nodeDataDependencies = newNodeDataDependencies;
      this.markNodeDataDirty();
    }
    return resize;
  }
};
Series.highlightedZIndex = 1e12;
__decorateClass([
  ActionOnSet({
    changeValue: function(newVal, oldVal) {
      this.onSeriesGroupingChange(oldVal, newVal);
    }
  })
], Series.prototype, "seriesGrouping", 2);

// packages/ag-charts-community/src/chart/series/seriesLayerManager.ts
var SERIES_THRESHOLD_FOR_AGGRESSIVE_LAYER_REDUCTION = 30;
var SeriesLayerManager = class {
  constructor(rootGroup) {
    this.groups = {};
    this.series = {};
    this.expectedSeriesCount = 1;
    this.mode = "normal";
    this.rootGroup = rootGroup;
  }
  setSeriesCount(count2) {
    this.expectedSeriesCount = count2;
  }
  requestGroup(seriesConfig) {
    var _a, _b, _c, _d;
    const {
      internalId,
      type,
      rootGroup: seriesRootGroup,
      highlightGroup: seriesHighlightGroup,
      annotationGroup: seriesAnnotationGroup,
      seriesGrouping
    } = seriesConfig;
    const { groupIndex = internalId } = seriesGrouping != null ? seriesGrouping : {};
    if (this.series[internalId] != null) {
      throw new Error(`AG Charts - series already has an allocated layer: ${this.series[internalId]}`);
    }
    if (Object.keys(this.series).length === 0) {
      this.mode = this.expectedSeriesCount >= SERIES_THRESHOLD_FOR_AGGRESSIVE_LAYER_REDUCTION ? "aggressive-grouping" : "normal";
    }
    (_b = (_a = this.groups)[type]) != null ? _b : _a[type] = {};
    const lookupIndex = this.lookupIdx(groupIndex);
    let groupInfo = this.groups[type][lookupIndex];
    if (!groupInfo) {
      groupInfo = (_d = (_c = this.groups[type])[lookupIndex]) != null ? _d : _c[lookupIndex] = {
        seriesIds: [],
        group: this.rootGroup.appendChild(
          new Group({
            name: `${type}-content`,
            layer: true,
            zIndex: 4 /* SERIES_LAYER_ZINDEX */,
            zIndexSubOrder: seriesConfig.getGroupZIndexSubOrder("data")
          })
        ),
        highlight: this.rootGroup.appendChild(
          new Group({
            name: `${type}-highlight`,
            layer: true,
            zIndex: 4 /* SERIES_LAYER_ZINDEX */,
            zIndexSubOrder: seriesConfig.getGroupZIndexSubOrder("highlight")
          })
        ),
        annotation: this.rootGroup.appendChild(
          new Group({
            name: `${type}-annotation`,
            layer: true,
            zIndex: 4 /* SERIES_LAYER_ZINDEX */,
            zIndexSubOrder: seriesConfig.getGroupZIndexSubOrder("annotation")
          })
        )
      };
    }
    this.series[internalId] = { layerState: groupInfo, seriesConfig };
    groupInfo.seriesIds.push(internalId);
    groupInfo.group.appendChild(seriesRootGroup);
    groupInfo.highlight.appendChild(seriesHighlightGroup);
    groupInfo.annotation.appendChild(seriesAnnotationGroup);
    return groupInfo.group;
  }
  changeGroup(seriesConfig) {
    var _a, _b;
    const { internalId, seriesGrouping, type, rootGroup, highlightGroup, annotationGroup, oldGrouping } = seriesConfig;
    const { groupIndex = internalId } = seriesGrouping != null ? seriesGrouping : {};
    if ((_b = (_a = this.groups[type]) == null ? void 0 : _a[groupIndex]) == null ? void 0 : _b.seriesIds.includes(internalId)) {
      return;
    }
    if (this.series[internalId] != null) {
      this.releaseGroup({
        internalId,
        seriesGrouping: oldGrouping,
        type,
        rootGroup,
        highlightGroup,
        annotationGroup
      });
    }
    this.requestGroup(seriesConfig);
  }
  releaseGroup(seriesConfig) {
    var _a, _b, _c, _d, _e;
    const { internalId, seriesGrouping, rootGroup, highlightGroup, annotationGroup, type } = seriesConfig;
    const { groupIndex = internalId } = seriesGrouping != null ? seriesGrouping : {};
    if (this.series[internalId] == null) {
      throw new Error(`AG Charts - series doesn't have an allocated layer: ${internalId}`);
    }
    const lookupIndex = this.lookupIdx(groupIndex);
    const groupInfo = (_c = (_a = this.groups[type]) == null ? void 0 : _a[lookupIndex]) != null ? _c : (_b = this.series[internalId]) == null ? void 0 : _b.layerState;
    if (groupInfo) {
      groupInfo.seriesIds = groupInfo.seriesIds.filter((v) => v !== internalId);
      groupInfo.group.removeChild(rootGroup);
      groupInfo.highlight.removeChild(highlightGroup);
      groupInfo.annotation.removeChild(annotationGroup);
    }
    if ((groupInfo == null ? void 0 : groupInfo.seriesIds.length) === 0) {
      this.rootGroup.removeChild(groupInfo.group);
      this.rootGroup.removeChild(groupInfo.highlight);
      this.rootGroup.removeChild(groupInfo.annotation);
      delete this.groups[type][lookupIndex];
      delete this.groups[type][internalId];
    } else if ((groupInfo == null ? void 0 : groupInfo.seriesIds.length) > 0) {
      const leadSeriesConfig = (_e = this.series[(_d = groupInfo == null ? void 0 : groupInfo.seriesIds) == null ? void 0 : _d[0]]) == null ? void 0 : _e.seriesConfig;
      groupInfo.group.zIndexSubOrder = leadSeriesConfig == null ? void 0 : leadSeriesConfig.getGroupZIndexSubOrder("data");
      groupInfo.highlight.zIndexSubOrder = leadSeriesConfig == null ? void 0 : leadSeriesConfig.getGroupZIndexSubOrder("highlight");
      groupInfo.annotation.zIndexSubOrder = leadSeriesConfig == null ? void 0 : leadSeriesConfig.getGroupZIndexSubOrder("annotation");
    }
    delete this.series[internalId];
  }
  lookupIdx(groupIndex) {
    if (this.mode === "normal") {
      return groupIndex;
    }
    if (typeof groupIndex === "string") {
      groupIndex = Number(groupIndex.split("-").slice(-1)[0]);
      if (!groupIndex)
        return 0;
    }
    return Math.floor(
      Math.max(Math.min(groupIndex / this.expectedSeriesCount, 1), 0) * SERIES_THRESHOLD_FOR_AGGRESSIVE_LAYER_REDUCTION
    );
  }
  destroy() {
    for (const groups of Object.values(this.groups)) {
      for (const groupInfo of Object.values(groups)) {
        this.rootGroup.removeChild(groupInfo.group);
        this.rootGroup.removeChild(groupInfo.highlight);
        this.rootGroup.removeChild(groupInfo.annotation);
      }
    }
    this.groups = {};
    this.series = {};
  }
};

// packages/ag-charts-community/src/chart/series/seriesStateManager.ts
var SeriesStateManager = class {
  constructor() {
    this.groups = {};
  }
  registerSeries({
    id,
    seriesGrouping,
    visible,
    type
  }) {
    var _a, _b;
    if (!seriesGrouping)
      return;
    (_b = (_a = this.groups)[type]) != null ? _b : _a[type] = {};
    this.groups[type][id] = { grouping: seriesGrouping, visible };
  }
  deregisterSeries({ id, type }) {
    if (this.groups[type]) {
      delete this.groups[type][id];
    }
    if (this.groups[type] && Object.keys(this.groups[type]).length === 0) {
      delete this.groups[type];
    }
  }
  getVisiblePeerGroupIndex({ type, seriesGrouping }) {
    var _a;
    if (!seriesGrouping)
      return { visibleGroupCount: 1, index: 0 };
    const visibleGroups = [
      ...Object.entries((_a = this.groups[type]) != null ? _a : {}).filter(([_, entry]) => entry.visible).reduce((result, [_, next]) => {
        if (next.visible) {
          result.add(next.grouping.groupIndex);
        }
        return result;
      }, /* @__PURE__ */ new Set()).values()
    ];
    visibleGroups.sort((a, b) => a - b);
    return {
      visibleGroupCount: visibleGroups.length,
      index: visibleGroups.indexOf(seriesGrouping.groupIndex)
    };
  }
};

// packages/ag-charts-community/src/chart/update/baseLayoutProcessor.ts
var BaseLayoutProcessor = class {
  constructor(chartLike, layoutService) {
    this.chartLike = chartLike;
    this.layoutService = layoutService;
    this.destroyFns = [];
    this.destroyFns.push(
      // eslint-disable-next-line sonarjs/no-duplicate-string
      this.layoutService.addListener("layout-complete", (e) => this.layoutComplete(e)),
      this.layoutService.addListener("start-layout", (e) => this.positionPadding(e.shrinkRect)),
      this.layoutService.addListener("start-layout", (e) => this.positionCaptions(e.shrinkRect))
    );
  }
  destroy() {
    this.destroyFns.forEach((cb) => cb());
  }
  layoutComplete({ clipSeries, series: { paddedRect } }) {
    const { seriesArea, seriesRoot } = this.chartLike;
    if (seriesArea.clip || clipSeries) {
      seriesRoot.setClipRectInGroupCoordinateSpace(paddedRect);
    } else {
      seriesRoot.setClipRectInGroupCoordinateSpace();
    }
  }
  positionPadding(shrinkRect) {
    const { padding } = this.chartLike;
    shrinkRect.shrink(padding.left, "left");
    shrinkRect.shrink(padding.top, "top");
    shrinkRect.shrink(padding.right, "right");
    shrinkRect.shrink(padding.bottom, "bottom");
    return { shrinkRect };
  }
  positionCaptions(shrinkRect) {
    var _a, _b, _c, _d;
    const { title, subtitle, footnote } = this.chartLike;
    const newShrinkRect = shrinkRect.clone();
    const updateCaption = (caption) => {
      var _a2;
      const defaultCaptionHeight = shrinkRect.height / 10;
      const captionLineHeight = (_a2 = caption.lineHeight) != null ? _a2 : caption.fontSize * Text.defaultLineHeightRatio;
      const maxWidth = shrinkRect.width;
      const maxHeight = Math.max(captionLineHeight, defaultCaptionHeight);
      caption.computeTextWrap(maxWidth, maxHeight);
    };
    const computeX = (align) => {
      if (align === "left") {
        return newShrinkRect.x;
      } else if (align === "right") {
        return newShrinkRect.x + newShrinkRect.width;
      } else if (align !== "center") {
        Logger.error(`invalid textAlign value: ${align}`);
      }
      return newShrinkRect.x + newShrinkRect.width / 2;
    };
    const positionTopAndShrinkBBox = (caption, spacing) => {
      const baseY = newShrinkRect.y;
      caption.node.x = computeX(caption.textAlign);
      caption.node.y = baseY;
      caption.node.textBaseline = "top";
      updateCaption(caption);
      const bbox = caption.node.computeBBox();
      const bboxHeight = Math.ceil(bbox.y - baseY + bbox.height + spacing);
      newShrinkRect.shrink(bboxHeight, "top");
    };
    const positionBottomAndShrinkBBox = (caption, spacing) => {
      const baseY = newShrinkRect.y + newShrinkRect.height;
      caption.node.x = computeX(caption.textAlign);
      caption.node.y = baseY;
      caption.node.textBaseline = "bottom";
      updateCaption(caption);
      const bbox = caption.node.computeBBox();
      const bboxHeight = Math.ceil(baseY - bbox.y + spacing);
      newShrinkRect.shrink(bboxHeight, "bottom");
    };
    if (subtitle) {
      subtitle.node.visible = (_a = subtitle.enabled) != null ? _a : false;
    }
    if (title) {
      title.node.visible = title.enabled;
      if (title.node.visible) {
        const defaultTitleSpacing = (subtitle == null ? void 0 : subtitle.node.visible) ? Caption.SMALL_PADDING : Caption.LARGE_PADDING;
        const spacing = (_b = title.spacing) != null ? _b : defaultTitleSpacing;
        positionTopAndShrinkBBox(title, spacing);
      }
    }
    if (subtitle && subtitle.node.visible) {
      positionTopAndShrinkBBox(subtitle, (_c = subtitle.spacing) != null ? _c : 0);
    }
    if (footnote) {
      footnote.node.visible = footnote.enabled;
      if (footnote.node.visible) {
        positionBottomAndShrinkBBox(footnote, (_d = footnote.spacing) != null ? _d : 0);
      }
    }
    return { shrinkRect: newShrinkRect };
  }
};

// packages/ag-charts-community/src/chart/updateService.ts
var UpdateService = class extends Listeners {
  constructor(updateCallback) {
    super();
    this.updateCallback = updateCallback;
  }
  update(type = 0 /* FULL */, { forceNodeDataRefresh = false, skipAnimations = false } = {}) {
    this.updateCallback(type, { forceNodeDataRefresh, skipAnimations });
  }
  dispatchUpdateComplete(minRect) {
    const event = { type: "update-complete", minRect };
    this.dispatch("update-complete", event);
  }
};

// packages/ag-charts-community/src/chart/chart.ts
function initialiseSpecialOverrides(opts) {
  let globalWindow;
  if (opts.window != null) {
    globalWindow = opts.window;
  } else if (typeof window !== "undefined") {
    globalWindow = window;
  } else if (typeof global !== "undefined") {
    globalWindow = global.window;
  } else {
    throw new Error("AG Charts - unable to resolve global window");
  }
  let globalDocument;
  if (opts.document != null) {
    globalDocument = opts.document;
  } else if (typeof document !== "undefined") {
    globalDocument = document;
  } else if (typeof global !== "undefined") {
    globalDocument = global.document;
  } else {
    throw new Error("AG Charts - unable to resolve global document");
  }
  return {
    document: globalDocument,
    window: globalWindow,
    overrideDevicePixelRatio: opts.overrideDevicePixelRatio,
    sceneMode: opts.sceneMode
  };
}
var SeriesArea = class {
  constructor() {
    this.clip = void 0;
    this.padding = new Padding$1(0);
  }
};
__decorateClass([
  Validate(BOOLEAN, { optional: true })
], SeriesArea.prototype, "clip", 2);
var chartsInstances = /* @__PURE__ */ new WeakMap();
var Chart = class extends Observable {
  constructor(specialOverrides, resources) {
    var _a;
    super();
    this.id = createId$1(this);
    this.processedOptions = {};
    this.userOptions = {};
    this.queuedUserOptions = [];
    this.seriesRoot = new Group({ name: `${this.id}-Series-root` });
    this.debug = Debug.create();
    this.extraDebugStats = {};
    this.container = void 0;
    this.data = [];
    this._firstAutoSize = true;
    this.padding = new Padding$1(20);
    this._seriesArea = new SeriesArea();
    this.title = void 0;
    this.subtitle = void 0;
    this.footnote = void 0;
    this.mode = "standalone";
    this._destroyed = false;
    this._destroyFns = [];
    this.modules = /* @__PURE__ */ new Map();
    // TODO shouldn't be public
    this.legends = /* @__PURE__ */ new Map();
    this.processors = [];
    this._pendingFactoryUpdatesCount = 0;
    this._performUpdateNoRenderCount = 0;
    this._performUpdateType = 6 /* NONE */;
    this._performUpdateSkipAnimations = false;
    this.updateShortcutCount = 0;
    this.seriesToUpdate = /* @__PURE__ */ new Set();
    this.updateMutex = new Mutex();
    this.updateRequestors = {};
    this.performUpdateTrigger = debouncedCallback((_0) => __async(this, [_0], function* ({ count: count2 }) {
      if (this._destroyed)
        return;
      this.updateMutex.acquire(() => __async(this, null, function* () {
        try {
          yield this.performUpdate(count2);
        } catch (error) {
          this._lastPerformUpdateError = error;
          Logger.error("update error", error);
        }
      }));
    }));
    this._axes = [];
    this._series = [];
    this.lastInteractionEvent = void 0;
    this.pointerScheduler = debouncedAnimationFrame(() => {
      if (this.lastInteractionEvent) {
        this.handlePointer(this.lastInteractionEvent);
      }
      this.lastInteractionEvent = void 0;
    });
    this.onSeriesNodeClick = (event) => {
      const seriesNodeClickEvent = __spreadProps(__spreadValues({}, event), {
        type: "seriesNodeClick"
      });
      Object.defineProperty(seriesNodeClickEvent, "series", {
        enumerable: false,
        // Should display the deprecation warning
        get: () => event.series
      });
      this.fireEvent(seriesNodeClickEvent);
    };
    this.onSeriesNodeDoubleClick = (event) => {
      const seriesNodeDoubleClick = __spreadProps(__spreadValues({}, event), {
        type: "seriesNodeDoubleClick"
      });
      this.fireEvent(seriesNodeDoubleClick);
    };
    this.specialOverrides = initialiseSpecialOverrides(specialOverrides);
    const { window: window2, document: document2 } = this.specialOverrides;
    const scene = resources == null ? void 0 : resources.scene;
    const element2 = (_a = resources == null ? void 0 : resources.element) != null ? _a : document2.createElement("div");
    const container = resources == null ? void 0 : resources.container;
    const root = new Group({ name: "root" });
    root.visible = false;
    root.append(this.seriesRoot);
    this.axisGridGroup = new Group({ name: "Axes-Grids", layer: true, zIndex: 1 /* AXIS_GRID_ZINDEX */ });
    root.appendChild(this.axisGridGroup);
    this.axisGroup = new Group({ name: "Axes", layer: true, zIndex: 2 /* AXIS_ZINDEX */ });
    root.appendChild(this.axisGroup);
    this.element = element2;
    element2.classList.add("ag-chart-wrapper");
    element2.style.position = "relative";
    this.scene = scene != null ? scene : new Scene(this.specialOverrides);
    this.scene.root = root;
    this.scene.container = element2;
    this.autoSize = true;
    this.chartEventManager = new ChartEventManager();
    this.cursorManager = new CursorManager(element2);
    this.highlightManager = new HighlightManager();
    this.interactionManager = new InteractionManager(element2, document2, window2);
    this.zoomManager = new ZoomManager();
    this.layoutService = new LayoutService();
    this.updateService = new UpdateService(
      (type = 0 /* FULL */, { forceNodeDataRefresh, skipAnimations }) => this.update(type, { forceNodeDataRefresh, skipAnimations })
    );
    this.seriesStateManager = new SeriesStateManager();
    this.seriesLayerManager = new SeriesLayerManager(this.seriesRoot);
    this.callbackCache = new CallbackCache();
    this.animationManager = new AnimationManager(this.interactionManager, this.updateMutex);
    this.animationManager.skip();
    this.animationManager.play();
    this.processors = [new BaseLayoutProcessor(this, this.layoutService)];
    this.tooltip = new Tooltip(this.scene.canvas.element, document2, window2, document2.body);
    this.tooltipManager = new TooltipManager(this.tooltip, this.interactionManager);
    this.overlays = new ChartOverlays(this.element);
    this.highlight = new ChartHighlight();
    this.container = container;
    SizeMonitor.observe(this.element, (size) => this.rawResize(size));
    this._destroyFns.push(
      this.interactionManager.addListener("click", (event) => this.onClick(event)),
      this.interactionManager.addListener("dblclick", (event) => this.onDoubleClick(event)),
      this.interactionManager.addListener("hover", (event) => this.onMouseMove(event)),
      this.interactionManager.addListener("leave", (event) => this.onLeave(event)),
      this.interactionManager.addListener("page-left", () => this.destroy()),
      this.interactionManager.addListener("wheel", () => this.disablePointer()),
      // Block redundant and interfering attempts to update the hovered element during dragging.
      this.interactionManager.addListener("drag-start", () => this.disablePointer()),
      this.animationManager.addListener("animation-frame", (_) => {
        this.update(5 /* SCENE_RENDER */);
      }),
      this.highlightManager.addListener("highlight-change", (event) => this.changeHighlightDatum(event)),
      this.zoomManager.addListener(
        "zoom-change",
        (_) => this.update(1 /* PROCESS_DATA */, { forceNodeDataRefresh: true, skipAnimations: true })
      )
    );
    this.attachLegend("category", Legend);
    this.legend = this.legends.get("category");
  }
  static getInstance(element2) {
    return chartsInstances.get(element2);
  }
  getOptions() {
    var _a;
    const { queuedUserOptions } = this;
    const lastUpdateOptions = (_a = queuedUserOptions[queuedUserOptions.length - 1]) != null ? _a : this.userOptions;
    return jsonClone(lastUpdateOptions);
  }
  autoSizeChanged(value) {
    const { style } = this.element;
    if (value) {
      style.display = "block";
      style.width = "100%";
      style.height = "100%";
      if (!this._lastAutoSize) {
        return;
      }
      this.resize(void 0, void 0, "autoSize option");
    } else {
      style.display = "inline-block";
      style.width = "auto";
      style.height = "auto";
    }
  }
  download(fileName, fileFormat) {
    this.scene.download(fileName, fileFormat);
  }
  get seriesArea() {
    return this._seriesArea;
  }
  set seriesArea(newArea) {
    if (!newArea) {
      this._seriesArea = new SeriesArea();
    } else {
      this._seriesArea = newArea;
    }
  }
  get destroyed() {
    return this._destroyed;
  }
  addModule(module) {
    if (this.modules.has(module.optionsKey)) {
      throw new Error(`AG Charts - module already initialised: ${module.optionsKey}`);
    }
    const moduleInstance = new module.instanceConstructor(this.getModuleContext());
    if (module.type === "legend") {
      const legend = moduleInstance;
      this.legends.set(module.identifier, legend);
      legend.attachLegend(this.scene.root);
    }
    this.modules.set(module.optionsKey, moduleInstance);
  }
  removeModule(module) {
    var _a;
    if (module.type === "legend") {
      this.legends.delete(module.identifier);
    }
    (_a = this.modules.get(module.optionsKey)) == null ? void 0 : _a.destroy();
    this.modules.delete(module.optionsKey);
  }
  attachLegend(legendType, legendConstructor) {
    const legend = new legendConstructor(this.getModuleContext());
    this.legends.set(legendType, legend);
    legend.attachLegend(this.scene.root);
  }
  isModuleEnabled(module) {
    return this.modules.has(module.optionsKey);
  }
  getModuleContext() {
    const {
      scene,
      animationManager,
      chartEventManager,
      cursorManager,
      highlightManager,
      interactionManager,
      tooltipManager,
      zoomManager,
      layoutService,
      updateService,
      seriesStateManager,
      seriesLayerManager,
      callbackCache,
      specialOverrides: { window: window2, document: document2 }
    } = this;
    return {
      window: window2,
      document: document2,
      scene,
      animationManager,
      chartEventManager,
      cursorManager,
      highlightManager,
      interactionManager,
      tooltipManager,
      zoomManager,
      chartService: this,
      layoutService,
      updateService,
      seriesStateManager,
      seriesLayerManager,
      callbackCache
    };
  }
  destroy(opts) {
    if (this._destroyed) {
      return;
    }
    const keepTransferableResources = opts == null ? void 0 : opts.keepTransferableResources;
    let result;
    this._performUpdateType = 6 /* NONE */;
    this._destroyFns.forEach((fn) => fn());
    this.processors.forEach((p) => p.destroy());
    this.tooltipManager.destroy();
    this.tooltip.destroy();
    this.legends.forEach((legend) => legend.destroy());
    this.legends.clear();
    this.overlays.destroy();
    SizeMonitor.unobserve(this.element);
    for (const { instance: moduleInstance } of Object.values(this.modules)) {
      this.removeModule(moduleInstance);
    }
    this.interactionManager.destroy();
    this.animationManager.stop();
    if (keepTransferableResources) {
      this.scene.strip();
      result = { container: this.container, scene: this.scene, element: this.element };
    } else {
      this.scene.destroy();
      this.container = void 0;
    }
    this.removeAllSeries();
    this.seriesLayerManager.destroy();
    this.axes.forEach((a) => a.destroy());
    this.axes = [];
    this.callbackCache.invalidateCache();
    this._destroyed = true;
    return result;
  }
  disablePointer(highlightOnly = false) {
    if (!highlightOnly) {
      this.tooltipManager.removeTooltip(this.id);
    }
    this.highlightManager.updateHighlight(this.id);
    if (this.lastInteractionEvent) {
      this.lastInteractionEvent = void 0;
    }
  }
  requestFactoryUpdate(cb) {
    this._pendingFactoryUpdatesCount++;
    this.updateMutex.acquire(() => __async(this, null, function* () {
      yield cb();
      this._pendingFactoryUpdatesCount--;
    }));
  }
  get performUpdateType() {
    return this._performUpdateType;
  }
  get lastPerformUpdateError() {
    return this._lastPerformUpdateError;
  }
  update(type = 0 /* FULL */, opts) {
    var _a, _b;
    const {
      forceNodeDataRefresh = false,
      skipAnimations,
      seriesToUpdate = this.series,
      newAnimationBatch
    } = opts != null ? opts : {};
    if (forceNodeDataRefresh) {
      this.series.forEach((series) => series.markNodeDataDirty());
    }
    for (const series of seriesToUpdate) {
      this.seriesToUpdate.add(series);
    }
    if (skipAnimations) {
      this.animationManager.skipCurrentBatch();
      this._performUpdateSkipAnimations = true;
    }
    if (newAnimationBatch) {
      if (this.animationManager.isActive()) {
        this._performUpdateSkipAnimations = true;
      } else {
        (_a = this._performUpdateSkipAnimations) != null ? _a : this._performUpdateSkipAnimations = false;
      }
    }
    if (Debug.check(true)) {
      let stack = (_b = new Error().stack) != null ? _b : "<unknown>";
      stack = stack.replace(/\([^)]*/g, "");
      this.updateRequestors[stack] = type;
    }
    if (type < this._performUpdateType) {
      this._performUpdateType = type;
      this.performUpdateTrigger.schedule(opts == null ? void 0 : opts.backOffMs);
    }
  }
  performUpdate(count2) {
    return __async(this, null, function* () {
      var _a;
      const { _performUpdateType: performUpdateType, extraDebugStats } = this;
      const seriesToUpdate = [...this.seriesToUpdate];
      this._performUpdateType = 6 /* NONE */;
      this.seriesToUpdate.clear();
      if (this.updateShortcutCount === 0 && performUpdateType < 5 /* SCENE_RENDER */) {
        this.animationManager.startBatch(this._performUpdateSkipAnimations);
      }
      this.debug("Chart.performUpdate() - start", ChartUpdateType[performUpdateType]);
      const splits = { start: performance.now() };
      switch (performUpdateType) {
        case 0 /* FULL */:
        case 1 /* PROCESS_DATA */:
          yield this.processData();
          this.disablePointer(true);
          splits["\u{1F3ED}"] = performance.now();
        case 2 /* PERFORM_LAYOUT */:
          if (this.checkUpdateShortcut(2 /* PERFORM_LAYOUT */))
            break;
          if (!this.checkFirstAutoSize(seriesToUpdate))
            break;
          yield this.processLayout();
          splits["\u2316"] = performance.now();
        case 3 /* SERIES_UPDATE */:
          if (this.checkUpdateShortcut(3 /* SERIES_UPDATE */))
            break;
          const { seriesRect } = this;
          const seriesUpdates = [...seriesToUpdate].map((series) => series.update({ seriesRect }));
          yield Promise.all(seriesUpdates);
          splits["\u{1F914}"] = performance.now();
        case 4 /* TOOLTIP_RECALCULATION */:
          if (this.checkUpdateShortcut(4 /* TOOLTIP_RECALCULATION */))
            break;
          const tooltipMeta = this.tooltipManager.getTooltipMeta(this.id);
          const isHovered = ((_a = tooltipMeta == null ? void 0 : tooltipMeta.event) == null ? void 0 : _a.type) === "hover";
          if (performUpdateType <= 3 /* SERIES_UPDATE */ && isHovered) {
            this.handlePointer(tooltipMeta.event);
          }
          splits["\u2196"] = performance.now();
        case 5 /* SCENE_RENDER */:
          if (this.checkUpdateShortcut(5 /* SCENE_RENDER */))
            break;
          extraDebugStats["updateShortcutCount"] = this.updateShortcutCount;
          yield this.scene.render({ debugSplitTimes: splits, extraDebugStats });
          this.extraDebugStats = {};
        case 6 /* NONE */:
          this.updateShortcutCount = 0;
          this.updateRequestors = {};
          this._performUpdateSkipAnimations = void 0;
          this.animationManager.endBatch();
      }
      this.updateService.dispatchUpdateComplete(this.getMinRect());
      const end = performance.now();
      this.debug("Chart.performUpdate() - end", {
        chart: this,
        durationMs: Math.round((end - splits["start"]) * 100) / 100,
        count: count2,
        performUpdateType: ChartUpdateType[performUpdateType]
      });
    });
  }
  checkUpdateShortcut(checkUpdateType) {
    const maxShortcuts = 3;
    if (this.updateShortcutCount > maxShortcuts) {
      Logger.warn(
        `exceeded the maximum number of simultaneous updates (${maxShortcuts + 1}), discarding changes and rendering`,
        this.updateRequestors
      );
      return false;
    }
    if (this.performUpdateType <= checkUpdateType) {
      this.updateShortcutCount++;
      return true;
    }
    return false;
  }
  checkFirstAutoSize(seriesToUpdate) {
    if (this.autoSize && !this._lastAutoSize) {
      const count2 = this._performUpdateNoRenderCount++;
      const backOffMs = (count2 ^ 2) * 10;
      if (count2 < 8) {
        this.update(2 /* PERFORM_LAYOUT */, { seriesToUpdate, backOffMs });
        this.debug("Chart.checkFirstAutoSize() - backing off until first size update", backOffMs);
        return false;
      }
      this.debug("Chart.checkFirstAutoSize() - timeout for first size update.");
    }
    this._performUpdateNoRenderCount = 0;
    return true;
  }
  set axes(values) {
    const removedAxes = /* @__PURE__ */ new Set();
    this._axes.forEach((axis) => {
      axis.detachAxis(this.axisGroup, this.axisGridGroup);
      removedAxes.add(axis);
    });
    this._axes = values.filter((a) => !a.linkedTo).concat(values.filter((a) => a.linkedTo));
    this._axes.forEach((axis) => {
      axis.attachAxis(this.axisGroup, this.axisGridGroup);
      removedAxes.delete(axis);
    });
    this.zoomManager.updateAxes(this._axes);
    removedAxes.forEach((axis) => axis.destroy());
  }
  get axes() {
    return this._axes;
  }
  set series(values) {
    this.removeAllSeries();
    this.seriesLayerManager.setSeriesCount(values.length);
    values.forEach((series) => this.addSeries(series));
  }
  get series() {
    return this._series;
  }
  addSeries(series) {
    const { series: allSeries } = this;
    const canAdd = allSeries.indexOf(series) < 0;
    if (canAdd) {
      allSeries.push(series);
      if (series.rootGroup.parent == null) {
        this.seriesLayerManager.requestGroup(series);
      }
      this.initSeries(series);
      return true;
    }
    return false;
  }
  initSeries(series) {
    const chart = this;
    series.chart = {
      get mode() {
        return chart.mode;
      },
      get seriesRect() {
        return chart.seriesRect;
      },
      placeLabels() {
        return chart.placeLabels();
      }
    };
    series.setChartData(this.data);
    this.addSeriesListeners(series);
    series.addChartEventListeners();
  }
  removeAllSeries() {
    this.series.forEach((series) => {
      series.removeEventListener("nodeClick", this.onSeriesNodeClick);
      series.removeEventListener("nodeDoubleClick", this.onSeriesNodeDoubleClick);
      series.destroy();
      series.chart = void 0;
    });
    this._series = [];
  }
  addSeriesListeners(series) {
    if (this.hasEventListener("seriesNodeClick")) {
      series.addEventListener("nodeClick", this.onSeriesNodeClick);
    }
    if (this.hasEventListener("seriesNodeDoubleClick")) {
      series.addEventListener("nodeDoubleClick", this.onSeriesNodeDoubleClick);
    }
  }
  updateAllSeriesListeners() {
    this.series.forEach((series) => {
      series.removeEventListener("nodeClick", this.onSeriesNodeClick);
      series.removeEventListener("nodeDoubleClick", this.onSeriesNodeDoubleClick);
      this.addSeriesListeners(series);
    });
  }
  assignSeriesToAxes() {
    this.axes.forEach((axis) => {
      axis.boundSeries = this.series.filter((s) => {
        const seriesAxis = s.axes[axis.direction];
        return seriesAxis === axis;
      });
    });
  }
  assignAxesToSeries() {
    const directionToAxesMap = {};
    this.axes.forEach((axis) => {
      var _a;
      const direction = axis.direction;
      const directionAxes = (_a = directionToAxesMap[direction]) != null ? _a : directionToAxesMap[direction] = [];
      directionAxes.push(axis);
    });
    this.series.forEach((series) => {
      series.directions.forEach((direction) => {
        const directionAxes = directionToAxesMap[direction];
        if (!directionAxes) {
          Logger.warnOnce(
            `no available axis for direction [${direction}]; check series and axes configuration.`
          );
          return;
        }
        const seriesKeys = series.getKeys(direction);
        const newAxis = this.findMatchingAxis(directionAxes, seriesKeys);
        if (!newAxis) {
          Logger.warnOnce(
            `no matching axis for direction [${direction}] and keys [${seriesKeys}]; check series and axes configuration.`
          );
          return;
        }
        series.axes[direction] = newAxis;
      });
    });
  }
  findMatchingAxis(directionAxes, directionKeys) {
    for (const axis of directionAxes) {
      const axisKeys = axis.keys;
      if (!axisKeys.length) {
        return axis;
      }
      if (!directionKeys) {
        continue;
      }
      for (const directionKey of directionKeys) {
        if (axisKeys.indexOf(directionKey) >= 0) {
          return axis;
        }
      }
    }
  }
  rawResize(size) {
    var _a;
    let { width, height } = size;
    width = Math.floor(width);
    height = Math.floor(height);
    if (!this.autoSize) {
      return;
    }
    if (width === 0 && height === 0) {
      return;
    }
    const [autoWidth = 0, authHeight = 0] = (_a = this._lastAutoSize) != null ? _a : [];
    if (autoWidth === width && authHeight === height) {
      return;
    }
    this._lastAutoSize = [width, height];
    this.resize(void 0, void 0, "SizeMonitor");
  }
  resize(width, height, source) {
    var _a, _b, _c, _d;
    width != null ? width : width = (_b = this.width) != null ? _b : this.autoSize ? (_a = this._lastAutoSize) == null ? void 0 : _a[0] : this.scene.canvas.width;
    height != null ? height : height = (_d = this.height) != null ? _d : this.autoSize ? (_c = this._lastAutoSize) == null ? void 0 : _c[1] : this.scene.canvas.height;
    this.debug(`Chart.resize() from ${source}`, { width, height, stack: new Error().stack });
    if (!width || !height || !Number.isFinite(width) || !Number.isFinite(height))
      return;
    if (this.scene.resize(width, height)) {
      this.disablePointer();
      this.animationManager.reset();
      let skipAnimations = true;
      if (this.autoSize && this._firstAutoSize) {
        skipAnimations = false;
        this._firstAutoSize = false;
      }
      this.update(2 /* PERFORM_LAYOUT */, { forceNodeDataRefresh: true, skipAnimations });
    }
  }
  processData() {
    return __async(this, null, function* () {
      if (this.series.some((s) => s.canHaveAxes)) {
        this.assignAxesToSeries();
        this.assignSeriesToAxes();
      }
      const dataController = new DataController(this.mode);
      const seriesPromises = this.series.map((s) => s.processData(dataController));
      yield dataController.execute();
      yield Promise.all(seriesPromises);
      yield this.updateLegend();
    });
  }
  placeLabels() {
    const visibleSeries = [];
    const data = [];
    for (const series of this.series) {
      if (!series.visible) {
        continue;
      }
      const labelData = series.getLabelData();
      if (!(labelData && isPointLabelDatum(labelData[0]))) {
        continue;
      }
      data.push(labelData);
      visibleSeries.push(series);
    }
    const { seriesRect } = this;
    const { top, right, bottom, left } = this.seriesArea.padding;
    const labels = seriesRect && data.length > 0 ? placeLabels(data, {
      x: -left,
      y: -top,
      width: seriesRect.width + left + right,
      height: seriesRect.height + top + bottom
    }) : [];
    return new Map(labels.map((l, i) => [visibleSeries[i], l]));
  }
  updateLegend() {
    return __async(this, null, function* () {
      this.legends.forEach((legend, legendType) => {
        const isCategoryLegendData = (data) => data.every((d) => d.legendType === "category");
        const legendData = this.series.filter((s) => s.properties.showInLegend).flatMap((s) => s.getLegendData(legendType));
        if (isCategoryLegendData(legendData)) {
          this.validateCategoryLegendData(legendData);
        }
        legend.data = legendData;
      });
    });
  }
  validateCategoryLegendData(legendData) {
    const labelMarkerFills = {};
    legendData.forEach((d) => {
      var _a, _b, _c, _d, _e;
      const seriesType = (_a = this.series.find((s) => s.id === d.seriesId)) == null ? void 0 : _a.type;
      if (!seriesType)
        return;
      (_b = labelMarkerFills[seriesType]) != null ? _b : labelMarkerFills[seriesType] = {};
      (_e = (_c = labelMarkerFills[seriesType])[_d = d.label.text]) != null ? _e : _c[_d] = /* @__PURE__ */ new Set();
      if (d.marker.fill != null) {
        labelMarkerFills[seriesType][d.label.text].add(d.marker.fill);
      }
    });
    for (const seriesMarkers of Object.values(labelMarkerFills)) {
      for (const [name, fills] of Object.entries(seriesMarkers)) {
        if (fills.size > 1) {
          Logger.warnOnce(
            `legend item '${name}' has multiple fill colors, this may cause unexpected behaviour.`
          );
        }
      }
    }
  }
  processLayout() {
    return __async(this, null, function* () {
      var _a;
      const oldRect = this.animationRect;
      yield this.performLayout();
      if (oldRect && !((_a = this.animationRect) == null ? void 0 : _a.equals(oldRect))) {
        this.animationManager.skipCurrentBatch();
      }
      this.handleOverlays();
      this.debug("Chart.performUpdate() - seriesRect", this.seriesRect);
    });
  }
  performLayout() {
    return __async(this, null, function* () {
      if (this.scene.root) {
        this.scene.root.visible = true;
      }
      const { width, height } = this.scene;
      let ctx = { shrinkRect: new BBox(0, 0, width, height) };
      ctx = this.layoutService.dispatchPerformLayout("start-layout", ctx);
      ctx = this.layoutService.dispatchPerformLayout("before-series", ctx);
      return ctx.shrinkRect;
    });
  }
  // x/y are local canvas coordinates in CSS pixels, not actual pixels
  pickSeriesNode(point, exactMatchOnly, maxDistance) {
    var _a, _b;
    const start = performance.now();
    const pickModes = exactMatchOnly ? [0 /* EXACT_SHAPE_MATCH */] : void 0;
    const reverseSeries = [...this.series].reverse();
    let result;
    for (const series of reverseSeries) {
      if (!series.visible || !series.rootGroup.visible) {
        continue;
      }
      const { match, distance } = (_a = series.pickNode(point, pickModes)) != null ? _a : {};
      if (!match || distance == null) {
        continue;
      }
      if ((!result || result.distance > distance) && distance <= (maxDistance != null ? maxDistance : Infinity)) {
        result = { series, distance, datum: match };
      }
      if (distance === 0) {
        break;
      }
    }
    this.extraDebugStats["pickSeriesNode"] = Math.round(
      ((_b = this.extraDebugStats["pickSeriesNode"]) != null ? _b : 0) + (performance.now() - start)
    );
    return result;
  }
  onMouseMove(event) {
    this.lastInteractionEvent = event;
    this.pointerScheduler.schedule();
    this.extraDebugStats["mouseX"] = event.offsetX;
    this.extraDebugStats["mouseY"] = event.offsetY;
    this.update(5 /* SCENE_RENDER */);
  }
  onLeave(event) {
    if (this.tooltip.pointerLeftOntoTooltip(event)) {
      return;
    }
    this.disablePointer();
    this.update(5 /* SCENE_RENDER */);
  }
  handlePointer(event) {
    const { lastPick, hoverRect } = this;
    const { offsetX, offsetY } = event;
    const disablePointer = (highlightOnly = false) => {
      if (lastPick) {
        this.disablePointer(highlightOnly);
      }
    };
    if (!(hoverRect == null ? void 0 : hoverRect.containsPoint(offsetX, offsetY))) {
      disablePointer();
      return;
    }
    this.handlePointerTooltip(event, disablePointer);
    this.handlePointerNode(event);
  }
  handlePointerTooltip(event, disablePointer) {
    const { lastPick, tooltip } = this;
    const { range: range3 } = tooltip;
    const { offsetX, offsetY } = event;
    let pixelRange;
    if (typeof range3 === "number" && Number.isFinite(range3)) {
      pixelRange = range3;
    }
    const pick = this.pickSeriesNode({ x: offsetX, y: offsetY }, range3 === "exact", pixelRange);
    if (!pick) {
      this.tooltipManager.removeTooltip(this.id);
      if (this.highlight.range === "tooltip")
        disablePointer(true);
      return;
    }
    const isNewDatum = this.highlight.range === "node" || !lastPick || lastPick.datum !== pick.datum;
    let html;
    if (isNewDatum) {
      html = pick.series.getTooltipHtml(pick.datum);
      if (this.highlight.range === "tooltip") {
        this.highlightManager.updateHighlight(this.id, pick.datum);
      }
    } else if (lastPick) {
      lastPick.event = event.sourceEvent;
    }
    const isPixelRange = pixelRange != null;
    const tooltipEnabled = this.tooltip.enabled && pick.series.properties.tooltip.enabled;
    const exactlyMatched = range3 === "exact" && pick.distance === 0;
    const rangeMatched = range3 === "nearest" || isPixelRange || exactlyMatched;
    const shouldUpdateTooltip = tooltipEnabled && rangeMatched && (!isNewDatum || html !== void 0);
    const meta = TooltipManager.makeTooltipMeta(event, this.scene.canvas, pick.datum, this.specialOverrides.window);
    if (shouldUpdateTooltip) {
      this.tooltipManager.updateTooltip(this.id, meta, html);
    }
  }
  handlePointerNode(event) {
    const found = this.checkSeriesNodeRange(event, (series, datum) => {
      if (series.hasEventListener("nodeClick") || series.hasEventListener("nodeDoubleClick")) {
        this.cursorManager.updateCursor("chart", "pointer");
      }
      if (this.highlight.range === "node") {
        this.highlightManager.updateHighlight(this.id, datum);
      }
    });
    if (!found) {
      this.cursorManager.updateCursor("chart");
      if (this.highlight.range === "node") {
        this.highlightManager.updateHighlight(this.id);
      }
    }
  }
  onClick(event) {
    if (this.checkSeriesNodeClick(event)) {
      this.update(3 /* SERIES_UPDATE */);
      return;
    }
    this.fireEvent({
      type: "click",
      event: event.sourceEvent
    });
  }
  onDoubleClick(event) {
    if (this.checkSeriesNodeDoubleClick(event)) {
      this.update(3 /* SERIES_UPDATE */);
      return;
    }
    this.fireEvent({
      type: "doubleClick",
      event: event.sourceEvent
    });
  }
  checkSeriesNodeClick(event) {
    return this.checkSeriesNodeRange(event, (series, datum) => series.fireNodeClickEvent(event.sourceEvent, datum));
  }
  checkSeriesNodeDoubleClick(event) {
    return this.checkSeriesNodeRange(
      event,
      (series, datum) => series.fireNodeDoubleClickEvent(event.sourceEvent, datum)
    );
  }
  checkSeriesNodeRange(event, callback) {
    const nearestNode = this.pickSeriesNode({ x: event.offsetX, y: event.offsetY }, false);
    const datum = nearestNode == null ? void 0 : nearestNode.datum;
    const nodeClickRange = datum == null ? void 0 : datum.series.properties.nodeClickRange;
    let pixelRange;
    if (typeof nodeClickRange === "number" && Number.isFinite(nodeClickRange)) {
      pixelRange = nodeClickRange;
    }
    let pickedNode = this.pickSeriesNode({ x: event.offsetX, y: event.offsetY }, true);
    if (pickedNode) {
      this.highlightManager.updatePicked(this.id, pickedNode.datum);
    } else {
      this.highlightManager.updatePicked(this.id);
    }
    if (datum && nodeClickRange === "nearest") {
      callback(datum.series, datum);
      return true;
    }
    if (nodeClickRange !== "exact") {
      pickedNode = this.pickSeriesNode({ x: event.offsetX, y: event.offsetY }, false, pixelRange);
    }
    if (!pickedNode)
      return false;
    const isPixelRange = pixelRange != null;
    const exactlyMatched = nodeClickRange === "exact" && pickedNode.distance === 0;
    if (isPixelRange || exactlyMatched) {
      callback(pickedNode.series, pickedNode.datum);
      return true;
    }
    return false;
  }
  changeHighlightDatum(event) {
    var _a, _b;
    const seriesToUpdate = /* @__PURE__ */ new Set();
    const { series: newSeries = void 0, datum: newDatum } = (_a = event.currentHighlight) != null ? _a : {};
    const { series: lastSeries = void 0, datum: lastDatum } = (_b = event.previousHighlight) != null ? _b : {};
    if (lastSeries) {
      seriesToUpdate.add(lastSeries);
    }
    if (newSeries) {
      seriesToUpdate.add(newSeries);
    }
    if ((lastSeries == null ? void 0 : lastSeries.properties.cursor) && lastDatum) {
      this.cursorManager.updateCursor(lastSeries.id);
    }
    if ((newSeries == null ? void 0 : newSeries.properties.cursor) && newDatum) {
      this.cursorManager.updateCursor(newSeries.id, newSeries.properties.cursor);
    }
    this.lastPick = event.currentHighlight ? { datum: event.currentHighlight } : void 0;
    const updateAll = newSeries == null || lastSeries == null;
    if (updateAll) {
      this.update(3 /* SERIES_UPDATE */);
    } else {
      this.update(3 /* SERIES_UPDATE */, { seriesToUpdate });
    }
  }
  waitForUpdate(timeoutMs = 5e3) {
    return __async(this, null, function* () {
      const start = performance.now();
      if (this._pendingFactoryUpdatesCount > 0) {
        yield this.updateMutex.waitForClearAcquireQueue();
      }
      while (this._performUpdateType !== 6 /* NONE */) {
        if (performance.now() - start > timeoutMs) {
          throw new Error("waitForUpdate() timeout reached.");
        }
        yield sleep(5);
      }
      yield this.updateMutex.waitForClearAcquireQueue();
    });
  }
  handleOverlays() {
    const hasNoData = !this.series.some((s) => s.hasData());
    this.toggleOverlay(this.overlays.noData, hasNoData);
    if (!hasNoData) {
      const hasNoVisibleSeries = !this.series.some((series) => series.visible);
      this.toggleOverlay(this.overlays.noVisibleSeries, hasNoVisibleSeries);
    }
  }
  toggleOverlay(overlay, visible) {
    if (visible && this.seriesRect) {
      overlay.show(this.seriesRect);
    } else {
      overlay.hide();
    }
  }
  getMinRect() {
    const minRects = this.series.map((series) => series.getMinRect()).filter((rect) => rect !== void 0);
    if (!minRects.length)
      return void 0;
    return new BBox(
      0,
      0,
      minRects.reduce((max, rect) => Math.max(max, rect.width), 0),
      minRects.reduce((max, rect) => Math.max(max, rect.height), 0)
    );
  }
};
__decorateClass([
  ActionOnSet({
    newValue(value) {
      if (this.destroyed)
        return;
      value.setAttribute("data-ag-charts", "");
      value.appendChild(this.element);
      chartsInstances.set(value, this);
    },
    oldValue(value) {
      value.removeAttribute("data-ag-charts");
      value.removeChild(this.element);
      chartsInstances.delete(value);
    }
  })
], Chart.prototype, "container", 2);
__decorateClass([
  ActionOnSet({
    newValue(value) {
      var _a;
      (_a = this.series) == null ? void 0 : _a.forEach((series) => {
        series.setChartData(value);
      });
    }
  })
], Chart.prototype, "data", 2);
__decorateClass([
  ActionOnSet({
    newValue(value) {
      this.resize(value, void 0, "width option");
    }
  })
], Chart.prototype, "width", 2);
__decorateClass([
  ActionOnSet({
    newValue(value) {
      this.resize(void 0, value, "height option");
    }
  })
], Chart.prototype, "height", 2);
__decorateClass([
  ActionOnSet({
    changeValue(value) {
      this.autoSizeChanged(value);
    }
  }),
  Validate(BOOLEAN)
], Chart.prototype, "autoSize", 2);
__decorateClass([
  ActionOnSet({
    newValue(value) {
      var _a;
      (_a = this.scene.root) == null ? void 0 : _a.appendChild(value.node);
    },
    oldValue(oldValue) {
      var _a;
      (_a = this.scene.root) == null ? void 0 : _a.removeChild(oldValue.node);
    }
  })
], Chart.prototype, "title", 2);
__decorateClass([
  ActionOnSet({
    newValue(value) {
      var _a;
      (_a = this.scene.root) == null ? void 0 : _a.appendChild(value.node);
    },
    oldValue(oldValue) {
      var _a;
      (_a = this.scene.root) == null ? void 0 : _a.removeChild(oldValue.node);
    }
  })
], Chart.prototype, "subtitle", 2);
__decorateClass([
  ActionOnSet({
    newValue(value) {
      var _a;
      (_a = this.scene.root) == null ? void 0 : _a.appendChild(value.node);
    },
    oldValue(oldValue) {
      var _a;
      (_a = this.scene.root) == null ? void 0 : _a.removeChild(oldValue.node);
    }
  })
], Chart.prototype, "footnote", 2);
__decorateClass([
  Validate(UNION(["standalone", "integrated"], "a chart mode"))
], Chart.prototype, "mode", 2);

// packages/ag-charts-community/src/chart/series/dataModelSeries.ts
var DataModelSeries = class extends Series {
  isContinuous() {
    var _a, _b;
    const isContinuousX = ContinuousScale.is((_a = this.axes["x" /* X */]) == null ? void 0 : _a.scale);
    const isContinuousY = ContinuousScale.is((_b = this.axes["y" /* Y */]) == null ? void 0 : _b.scale);
    return { isContinuousX, isContinuousY };
  }
  getModulePropertyDefinitions() {
    return this.moduleMap.mapValues((mod2) => mod2.getPropertyDefinitions(this.isContinuous())).flat();
  }
  // Request data, but with message dispatching to series-options (modules).
  requestDataModel(dataController, data, opts) {
    return __async(this, null, function* () {
      opts.props.push(...this.getModulePropertyDefinitions());
      const { dataModel, processedData } = yield dataController.request(this.id, data != null ? data : [], opts);
      this.dataModel = dataModel;
      this.processedData = processedData;
      this.dispatch("data-processed", { dataModel, processedData });
      return { dataModel, processedData };
    });
  }
  isProcessedDataAnimatable() {
    var _a, _b;
    const validationResults = (_b = (_a = this.processedData) == null ? void 0 : _a.reduced) == null ? void 0 : _b.animationValidation;
    if (!validationResults) {
      return true;
    }
    const { orderedKeys, uniqueKeys } = validationResults;
    return orderedKeys && uniqueKeys;
  }
  checkProcessedDataAnimatable() {
    if (!this.isProcessedDataAnimatable()) {
      this.ctx.animationManager.skipCurrentBatch();
    }
  }
};

// packages/ag-charts-community/src/chart/series/seriesProperties.ts
var SeriesItemHighlightStyle = class extends BaseProperties {
  constructor() {
    super(...arguments);
    this.fill = "rgba(255,255,255, 0.33)";
    this.stroke = `rgba(0, 0, 0, 0.4)`;
    this.strokeWidth = 2;
  }
};
__decorateClass([
  Validate(COLOR_STRING, { optional: true })
], SeriesItemHighlightStyle.prototype, "fill", 2);
__decorateClass([
  Validate(RATIO, { optional: true })
], SeriesItemHighlightStyle.prototype, "fillOpacity", 2);
__decorateClass([
  Validate(COLOR_STRING, { optional: true })
], SeriesItemHighlightStyle.prototype, "stroke", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], SeriesItemHighlightStyle.prototype, "strokeWidth", 2);
__decorateClass([
  Validate(RATIO, { optional: true })
], SeriesItemHighlightStyle.prototype, "strokeOpacity", 2);
__decorateClass([
  Validate(LINE_DASH, { optional: true })
], SeriesItemHighlightStyle.prototype, "lineDash", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], SeriesItemHighlightStyle.prototype, "lineDashOffset", 2);
var SeriesHighlightStyle = class extends BaseProperties {
};
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], SeriesHighlightStyle.prototype, "strokeWidth", 2);
__decorateClass([
  Validate(RATIO, { optional: true })
], SeriesHighlightStyle.prototype, "dimOpacity", 2);
__decorateClass([
  Validate(BOOLEAN, { optional: true })
], SeriesHighlightStyle.prototype, "enabled", 2);
var TextHighlightStyle = class extends BaseProperties {
  constructor() {
    super(...arguments);
    this.color = "black";
  }
};
__decorateClass([
  Validate(COLOR_STRING, { optional: true })
], TextHighlightStyle.prototype, "color", 2);
var HighlightStyle = class extends BaseProperties {
  constructor() {
    super(...arguments);
    this.item = new SeriesItemHighlightStyle();
    this.series = new SeriesHighlightStyle();
    this.text = new TextHighlightStyle();
  }
};
__decorateClass([
  Validate(OBJECT)
], HighlightStyle.prototype, "item", 2);
__decorateClass([
  Validate(OBJECT)
], HighlightStyle.prototype, "series", 2);
__decorateClass([
  Validate(OBJECT)
], HighlightStyle.prototype, "text", 2);
var SeriesProperties = class extends BaseProperties {
  constructor() {
    super(...arguments);
    this.visible = true;
    this.showInLegend = true;
    this.cursor = "default";
    this.nodeClickRange = "exact";
    this.highlightStyle = new HighlightStyle();
  }
};
__decorateClass([
  Validate(STRING, { optional: true })
], SeriesProperties.prototype, "id", 2);
__decorateClass([
  Validate(BOOLEAN)
], SeriesProperties.prototype, "visible", 2);
__decorateClass([
  Validate(BOOLEAN)
], SeriesProperties.prototype, "showInLegend", 2);
__decorateClass([
  Validate(STRING)
], SeriesProperties.prototype, "cursor", 2);
__decorateClass([
  Validate(INTERACTION_RANGE)
], SeriesProperties.prototype, "nodeClickRange", 2);
__decorateClass([
  Validate(OBJECT)
], SeriesProperties.prototype, "highlightStyle", 2);

// packages/ag-charts-community/src/chart/series/cartesian/cartesianSeries.ts
var DEFAULT_DIRECTION_KEYS = {
  ["x" /* X */]: ["xKey"],
  ["y" /* Y */]: ["yKey"]
};
var DEFAULT_DIRECTION_NAMES = {
  ["x" /* X */]: ["xName"],
  ["y" /* Y */]: ["yName"]
};
var CartesianSeriesNodeClickEvent = class extends SeriesNodeClickEvent {
  constructor(type, nativeEvent, datum, series) {
    super(type, nativeEvent, datum, series);
    this.xKey = series.properties.xKey;
    this.yKey = series.properties.yKey;
  }
};
var CartesianSeriesProperties = class extends SeriesProperties {
};
__decorateClass([
  Validate(STRING, { optional: true })
], CartesianSeriesProperties.prototype, "legendItemName", 2);
var CartesianSeries = class extends DataModelSeries {
  constructor(_a) {
    var _b = _a, {
      pathsPerSeries = 1,
      hasMarkers = false,
      hasHighlightedLabels = false,
      pathsZIndexSubOrderOffset = [],
      directionKeys = DEFAULT_DIRECTION_KEYS,
      directionNames = DEFAULT_DIRECTION_NAMES,
      datumSelectionGarbageCollection = true,
      markerSelectionGarbageCollection = true,
      animationResetFns
    } = _b, otherOpts = __objRest(_b, [
      "pathsPerSeries",
      "hasMarkers",
      "hasHighlightedLabels",
      "pathsZIndexSubOrderOffset",
      "directionKeys",
      "directionNames",
      "datumSelectionGarbageCollection",
      "markerSelectionGarbageCollection",
      "animationResetFns"
    ]);
    super(__spreadValues({
      directionKeys,
      directionNames,
      useSeriesGroupLayer: true,
      canHaveAxes: true
    }, otherOpts));
    this._contextNodeData = [];
    this.NodeClickEvent = CartesianSeriesNodeClickEvent;
    this.highlightSelection = Selection.select(
      this.highlightNode,
      () => this.opts.hasMarkers ? this.markerFactory() : this.nodeFactory()
    );
    this.highlightLabelSelection = Selection.select(this.highlightLabel, Text);
    this.annotationSelections = /* @__PURE__ */ new Set();
    this.subGroups = [];
    this.subGroupId = 0;
    this.debug = Debug.create();
    this.opts = {
      pathsPerSeries,
      hasMarkers,
      hasHighlightedLabels,
      pathsZIndexSubOrderOffset,
      directionKeys,
      directionNames,
      animationResetFns,
      datumSelectionGarbageCollection,
      markerSelectionGarbageCollection
    };
    this.animationState = new StateMachine(
      "empty",
      {
        empty: {
          update: {
            target: "ready",
            action: (data) => this.animateEmptyUpdateReady(data)
          }
        },
        ready: {
          updateData: "waiting",
          clear: "clearing",
          highlight: (data) => this.animateReadyHighlight(data),
          highlightMarkers: (data) => this.animateReadyHighlightMarkers(data),
          resize: (data) => this.animateReadyResize(data)
        },
        waiting: {
          update: {
            target: "ready",
            action: (data) => this.animateWaitingUpdateReady(data)
          }
        },
        clearing: {
          update: {
            target: "empty",
            action: (data) => this.animateClearingUpdateEmpty(data)
          }
        }
      },
      () => this.checkProcessedDataAnimatable()
    );
  }
  get contextNodeData() {
    return this._contextNodeData.slice();
  }
  addChartEventListeners() {
    this.destroyFns.push(
      this.ctx.chartEventManager.addListener("legend-item-click", (event) => this.onLegendItemClick(event)),
      this.ctx.chartEventManager.addListener(
        "legend-item-double-click",
        (event) => this.onLegendItemDoubleClick(event)
      )
    );
  }
  destroy() {
    super.destroy();
    this._contextNodeData.splice(0, this._contextNodeData.length);
    this.subGroups.splice(0, this.subGroups.length);
  }
  update(_0) {
    return __async(this, arguments, function* ({ seriesRect }) {
      var _a, _b;
      const { visible, _contextNodeData: previousContextData } = this;
      const { series } = (_b = (_a = this.ctx.highlightManager) == null ? void 0 : _a.getActiveHighlight()) != null ? _b : {};
      const seriesHighlighted = series ? series === this : void 0;
      const resize = this.checkResize(seriesRect);
      const highlightItems = yield this.updateHighlightSelection(seriesHighlighted);
      yield this.updateSelections(visible);
      yield this.updateNodes(highlightItems, seriesHighlighted, visible);
      const animationData = this.getAnimationData(seriesRect, previousContextData);
      if (resize) {
        this.animationState.transition("resize", animationData);
      }
      this.animationState.transition("update", animationData);
    });
  }
  updateSelections(anySeriesItemEnabled) {
    return __async(this, null, function* () {
      if (!anySeriesItemEnabled && this.ctx.animationManager.isSkipped()) {
        return;
      }
      if (!this.nodeDataRefresh && !this.isPathOrSelectionDirty()) {
        return;
      }
      if (this.nodeDataRefresh) {
        this.nodeDataRefresh = false;
        this.debug(`CartesianSeries.updateSelections() - calling createNodeData() for`, this.id);
        this._contextNodeData = yield this.createNodeData();
        const animationValid = this.isProcessedDataAnimatable();
        this._contextNodeData.forEach((nodeData) => {
          var _a;
          (_a = nodeData.animationValid) != null ? _a : nodeData.animationValid = animationValid;
        });
        yield this.updateSeriesGroups();
        const { dataModel, processedData } = this;
        if (dataModel !== void 0 && processedData !== void 0) {
          this.dispatch("data-update", { dataModel, processedData });
        }
      }
      yield Promise.all(this.subGroups.map((g, i) => this.updateSeriesGroupSelections(g, i)));
    });
  }
  updateSeriesGroupSelections(subGroup, seriesIdx, seriesHighlighted) {
    return __async(this, null, function* () {
      const { datumSelection, labelSelection, markerSelection, paths } = subGroup;
      const contextData = this._contextNodeData[seriesIdx];
      const { nodeData, labelData, itemId } = contextData;
      yield this.updatePaths({ seriesHighlighted, itemId, contextData, paths, seriesIdx });
      subGroup.datumSelection = yield this.updateDatumSelection({ nodeData, datumSelection, seriesIdx });
      subGroup.labelSelection = yield this.updateLabelSelection({ labelData, labelSelection, seriesIdx });
      if (markerSelection) {
        subGroup.markerSelection = yield this.updateMarkerSelection({ nodeData, markerSelection, seriesIdx });
      }
    });
  }
  markerFactory() {
    const MarkerShape = getMarker$1();
    return new MarkerShape();
  }
  updateSeriesGroups() {
    return __async(this, null, function* () {
      const {
        _contextNodeData: contextNodeData,
        contentGroup,
        subGroups,
        opts: { pathsPerSeries, hasMarkers, datumSelectionGarbageCollection, markerSelectionGarbageCollection }
      } = this;
      if (contextNodeData.length === subGroups.length) {
        return;
      }
      if (contextNodeData.length < subGroups.length) {
        subGroups.splice(contextNodeData.length).forEach(({ dataNodeGroup, markerGroup, labelGroup, paths }) => {
          contentGroup.removeChild(dataNodeGroup);
          if (markerGroup) {
            contentGroup.removeChild(markerGroup);
          }
          if (labelGroup) {
            contentGroup.removeChild(labelGroup);
          }
          for (const path of paths) {
            contentGroup.removeChild(path);
          }
        });
      }
      const totalGroups = contextNodeData.length;
      while (totalGroups > subGroups.length) {
        const layer = false;
        const subGroupId = this.subGroupId++;
        const dataNodeGroup = new Group({
          name: `${this.id}-series-sub${subGroupId}-dataNodes`,
          layer,
          zIndex: 4 /* SERIES_LAYER_ZINDEX */,
          zIndexSubOrder: this.getGroupZIndexSubOrder("data", subGroupId)
        });
        const markerGroup = hasMarkers ? new Group({
          name: `${this.id}-series-sub${this.subGroupId++}-markers`,
          layer,
          zIndex: 4 /* SERIES_LAYER_ZINDEX */,
          zIndexSubOrder: this.getGroupZIndexSubOrder("marker", subGroupId)
        }) : void 0;
        const labelGroup = new Group({
          name: `${this.id}-series-sub${this.subGroupId++}-labels`,
          layer,
          zIndex: 7 /* SERIES_LABEL_ZINDEX */,
          zIndexSubOrder: this.getGroupZIndexSubOrder("labels", subGroupId)
        });
        contentGroup.appendChild(dataNodeGroup);
        contentGroup.appendChild(labelGroup);
        if (markerGroup) {
          contentGroup.appendChild(markerGroup);
        }
        const paths = [];
        for (let index = 0; index < pathsPerSeries; index++) {
          paths[index] = new Path();
          paths[index].zIndex = 4 /* SERIES_LAYER_ZINDEX */;
          paths[index].zIndexSubOrder = this.getGroupZIndexSubOrder("paths", index);
          contentGroup.appendChild(paths[index]);
        }
        subGroups.push({
          paths,
          dataNodeGroup,
          markerGroup,
          labelGroup,
          labelSelection: Selection.select(labelGroup, Text),
          datumSelection: Selection.select(
            dataNodeGroup,
            () => this.nodeFactory(),
            datumSelectionGarbageCollection
          ),
          markerSelection: markerGroup ? Selection.select(markerGroup, () => this.markerFactory(), markerSelectionGarbageCollection) : void 0
        });
      }
    });
  }
  getGroupZIndexSubOrder(type, subIndex = 0) {
    var _a;
    const result = super.getGroupZIndexSubOrder(type, subIndex);
    if (type === "paths") {
      const pathOffset = (_a = this.opts.pathsZIndexSubOrderOffset[subIndex]) != null ? _a : 0;
      const superFn = result[0];
      if (typeof superFn === "function") {
        result[0] = () => +superFn() + pathOffset;
      } else {
        result[0] = +superFn + pathOffset;
      }
    }
    return result;
  }
  updateNodes(highlightedItems, seriesHighlighted, anySeriesItemEnabled) {
    return __async(this, null, function* () {
      var _a;
      const {
        highlightSelection,
        highlightLabelSelection,
        opts: { hasMarkers, hasHighlightedLabels }
      } = this;
      const animationEnabled = !this.ctx.animationManager.isSkipped();
      const visible = this.visible && ((_a = this._contextNodeData) == null ? void 0 : _a.length) > 0 && anySeriesItemEnabled;
      this.rootGroup.visible = animationEnabled || visible;
      this.contentGroup.visible = animationEnabled || visible;
      this.highlightGroup.visible = (animationEnabled || visible) && !!seriesHighlighted;
      const subGroupOpacity = this.getOpacity();
      if (hasMarkers) {
        yield this.updateMarkerNodes({
          markerSelection: highlightSelection,
          isHighlight: true,
          seriesIdx: -1
        });
        this.animationState.transition("highlightMarkers", highlightSelection);
      } else {
        yield this.updateDatumNodes({
          datumSelection: highlightSelection,
          isHighlight: true,
          seriesIdx: -1
        });
        this.animationState.transition("highlight", highlightSelection);
      }
      if (hasHighlightedLabels) {
        yield this.updateLabelNodes({ labelSelection: highlightLabelSelection, seriesIdx: -1 });
      }
      yield Promise.all(
        this.subGroups.map((subGroup, seriesIdx) => __async(this, null, function* () {
          const {
            dataNodeGroup,
            markerGroup,
            datumSelection,
            labelSelection,
            markerSelection,
            paths,
            labelGroup
          } = subGroup;
          const { itemId } = this.contextNodeData[seriesIdx];
          const subGroupVisible = visible;
          dataNodeGroup.opacity = subGroupOpacity;
          dataNodeGroup.visible = animationEnabled || subGroupVisible;
          labelGroup.visible = subGroupVisible;
          if (markerGroup) {
            markerGroup.opacity = subGroupOpacity;
            markerGroup.zIndex = dataNodeGroup.zIndex >= 4 /* SERIES_LAYER_ZINDEX */ ? dataNodeGroup.zIndex : dataNodeGroup.zIndex + 1;
            markerGroup.visible = subGroupVisible;
          }
          if (labelGroup) {
            labelGroup.opacity = subGroupOpacity;
          }
          yield this.updatePathNodes({
            seriesHighlighted,
            itemId,
            paths,
            seriesIdx,
            opacity: subGroupOpacity,
            visible: subGroupVisible,
            animationEnabled
          });
          if (!dataNodeGroup.visible) {
            return;
          }
          yield this.updateDatumNodes({ datumSelection, highlightedItems, isHighlight: false, seriesIdx });
          yield this.updateLabelNodes({ labelSelection, seriesIdx });
          if (hasMarkers && markerSelection) {
            yield this.updateMarkerNodes({ markerSelection, isHighlight: false, seriesIdx });
          }
        }))
      );
    });
  }
  getHighlightLabelData(labelData, highlightedItem) {
    const labelItems = labelData.filter(
      (ld) => ld.datum === highlightedItem.datum && ld.itemId === highlightedItem.itemId
    );
    return labelItems.length !== 0 ? labelItems : void 0;
  }
  getHighlightData(_nodeData, highlightedItem) {
    return highlightedItem ? [highlightedItem] : void 0;
  }
  updateHighlightSelection(seriesHighlighted) {
    return __async(this, null, function* () {
      var _a;
      const { highlightSelection, highlightLabelSelection, _contextNodeData: contextNodeData } = this;
      const highlightedDatum = (_a = this.ctx.highlightManager) == null ? void 0 : _a.getActiveHighlight();
      const item = seriesHighlighted && (highlightedDatum == null ? void 0 : highlightedDatum.datum) ? highlightedDatum : void 0;
      let labelItems;
      let highlightItems;
      if (item != null) {
        const labelsEnabled = this.isLabelEnabled();
        for (const { labelData, nodeData } of contextNodeData) {
          highlightItems = this.getHighlightData(nodeData, item);
          labelItems = labelsEnabled ? this.getHighlightLabelData(labelData, item) : void 0;
          if ((!labelsEnabled || labelItems != null) && highlightItems != null) {
            break;
          }
        }
      }
      this.highlightSelection = yield this.updateHighlightSelectionItem({
        items: highlightItems,
        highlightSelection
      });
      this.highlightLabelSelection = yield this.updateHighlightSelectionLabel({
        items: labelItems,
        highlightLabelSelection
      });
      return highlightItems;
    });
  }
  pickNodeExactShape(point) {
    var _a;
    const result = super.pickNodeExactShape(point);
    if (result) {
      return result;
    }
    const { x, y } = point;
    const {
      opts: { hasMarkers }
    } = this;
    for (const { dataNodeGroup, markerGroup } of this.subGroups) {
      let match2 = dataNodeGroup.pickNode(x, y);
      if (!match2 && hasMarkers) {
        match2 = markerGroup == null ? void 0 : markerGroup.pickNode(x, y);
      }
      if (match2) {
        break;
      }
    }
    {
      for (const mod2 of this.moduleMap.modules) {
        const { datum } = (_a = mod2.pickNodeExact(point)) != null ? _a : {};
        if (datum !== void 0) {
          return { datum, distance: 0 };
        }
      }
    }
  }
  pickNodeClosestDatum(point) {
    var _a, _b;
    const { x, y } = point;
    const { axes, rootGroup, _contextNodeData: contextNodeData } = this;
    const xAxis = axes["x" /* X */];
    const yAxis = axes["y" /* Y */];
    const hitPoint = rootGroup.transformPoint(x, y);
    let minDistance = Infinity;
    let closestDatum;
    for (const context of contextNodeData) {
      for (const datum of context.nodeData) {
        const { point: { x: datumX = NaN, y: datumY = NaN } = {} } = datum;
        if (isNaN(datumX) || isNaN(datumY)) {
          continue;
        }
        const isInRange = (xAxis == null ? void 0 : xAxis.inRange(datumX)) && (yAxis == null ? void 0 : yAxis.inRange(datumY));
        if (!isInRange) {
          continue;
        }
        const distance = Math.max(__pow(hitPoint.x - datumX, 2) + __pow(hitPoint.y - datumY, 2), 0);
        if (distance < minDistance) {
          minDistance = distance;
          closestDatum = datum;
        }
      }
    }
    for (const mod2 of this.moduleMap.modules) {
      const modPick = mod2.pickNodeNearest(point);
      if (modPick !== void 0 && modPick.distanceSquared < minDistance) {
        minDistance = modPick.distanceSquared;
        closestDatum = modPick.datum;
        break;
      }
    }
    if (closestDatum) {
      const distance = Math.max(Math.sqrt(minDistance) - ((_b = (_a = closestDatum.point) == null ? void 0 : _a.size) != null ? _b : 0), 0);
      return { datum: closestDatum, distance };
    }
  }
  pickNodeMainAxisFirst(point, requireCategoryAxis) {
    var _a, _b;
    const { x, y } = point;
    const { axes, rootGroup, _contextNodeData: contextNodeData } = this;
    const xAxis = axes["x" /* X */];
    const yAxis = axes["y" /* Y */];
    const directions2 = [xAxis, yAxis].filter((a) => a instanceof CategoryAxis).map((a) => a.direction);
    if (requireCategoryAxis && directions2.length === 0) {
      return;
    }
    const [primaryDirection = "x" /* X */] = directions2;
    const hitPoint = rootGroup.transformPoint(x, y);
    const hitPointCoords = primaryDirection === "x" /* X */ ? [hitPoint.x, hitPoint.y] : [hitPoint.y, hitPoint.x];
    const minDistance = [Infinity, Infinity];
    let closestDatum;
    for (const context of contextNodeData) {
      for (const datum of context.nodeData) {
        const { point: { x: datumX = NaN, y: datumY = NaN } = {} } = datum;
        if (isNaN(datumX) || isNaN(datumY)) {
          continue;
        }
        const isInRange = (xAxis == null ? void 0 : xAxis.inRange(datumX)) && (yAxis == null ? void 0 : yAxis.inRange(datumY));
        if (!isInRange) {
          continue;
        }
        const point2 = primaryDirection === "x" /* X */ ? [datumX, datumY] : [datumY, datumX];
        let newMinDistance = true;
        for (let i = 0; i < point2.length; i++) {
          const dist = Math.abs(point2[i] - hitPointCoords[i]);
          if (dist > minDistance[i]) {
            newMinDistance = false;
            break;
          }
          if (dist < minDistance[i]) {
            minDistance[i] = dist;
            minDistance.fill(Infinity, i + 1, minDistance.length);
          }
        }
        if (newMinDistance) {
          closestDatum = datum;
        }
      }
    }
    if (closestDatum) {
      let closestDistanceSquared = Math.max(
        __pow(minDistance[0], 2) + __pow(minDistance[1], 2) - ((_b = (_a = closestDatum.point) == null ? void 0 : _a.size) != null ? _b : 0),
        0
      );
      for (const mod2 of this.moduleMap.modules) {
        const modPick = mod2.pickNodeMainAxisFirst(point);
        if (modPick !== void 0 && modPick.distanceSquared < closestDistanceSquared) {
          closestDatum = modPick.datum;
          closestDistanceSquared = modPick.distanceSquared;
          break;
        }
      }
      return { datum: closestDatum, distance: Math.sqrt(closestDistanceSquared) };
    }
  }
  onLegendItemClick(event) {
    const { legendItemName } = this.properties;
    const { enabled, itemId, series } = event;
    const matchedLegendItemName = legendItemName != null && legendItemName === event.legendItemName;
    if (series.id === this.id || matchedLegendItemName) {
      this.toggleSeriesItem(itemId, enabled);
    }
  }
  onLegendItemDoubleClick(event) {
    const { enabled, itemId, series, numVisibleItems } = event;
    const { legendItemName } = this.properties;
    const matchedLegendItemName = legendItemName != null && legendItemName === event.legendItemName;
    if (series.id === this.id || matchedLegendItemName) {
      this.toggleSeriesItem(itemId, true);
    } else if (enabled && numVisibleItems === 1) {
      this.toggleSeriesItem(itemId, true);
    } else {
      this.toggleSeriesItem(itemId, false);
    }
  }
  isPathOrSelectionDirty() {
    return false;
  }
  getLabelData() {
    return [];
  }
  shouldFlipXY() {
    return false;
  }
  /**
   * Get the minimum bounding box that contains any adjacent two nodes. The axes are treated independently, so this
   * may not represent the same two points for both directions. The dimensions represent the greatest distance
   * between any two adjacent nodes.
   */
  getMinRect() {
    const [context] = this._contextNodeData;
    if (!(context == null ? void 0 : context.nodeData.length)) {
      return;
    }
    const width = context.nodeData.map(({ midPoint }) => {
      var _a;
      return (_a = midPoint == null ? void 0 : midPoint.x) != null ? _a : 0;
    }).sort((a, b) => a - b).reduce((max, x, i, array) => i > 0 ? Math.max(max, x - array[i - 1]) : max, 0);
    const height = context.nodeData.map(({ midPoint }) => {
      var _a;
      return (_a = midPoint == null ? void 0 : midPoint.y) != null ? _a : 0;
    }).sort((a, b) => a - b).reduce((max, y, i, array) => i > 0 ? Math.max(max, y - array[i - 1]) : max, 0);
    return new BBox(0, 0, width, height);
  }
  updateHighlightSelectionItem(opts) {
    return __async(this, null, function* () {
      const {
        opts: { hasMarkers }
      } = this;
      const { items, highlightSelection } = opts;
      const nodeData = items != null ? items : [];
      if (hasMarkers) {
        const markerSelection = highlightSelection;
        return this.updateMarkerSelection({ nodeData, markerSelection, seriesIdx: -1 });
      } else {
        return this.updateDatumSelection({
          nodeData,
          datumSelection: highlightSelection,
          seriesIdx: -1
        });
      }
    });
  }
  updateHighlightSelectionLabel(opts) {
    return __async(this, null, function* () {
      var _a;
      return this.updateLabelSelection({
        labelData: (_a = opts.items) != null ? _a : [],
        labelSelection: opts.highlightLabelSelection,
        seriesIdx: -1
      });
    });
  }
  updateDatumSelection(opts) {
    return __async(this, null, function* () {
      return opts.datumSelection;
    });
  }
  updateDatumNodes(_opts) {
    return __async(this, null, function* () {
    });
  }
  updateMarkerSelection(opts) {
    return __async(this, null, function* () {
      return opts.markerSelection;
    });
  }
  updateMarkerNodes(_opts) {
    return __async(this, null, function* () {
    });
  }
  updatePaths(opts) {
    return __async(this, null, function* () {
      opts.paths.forEach((p) => p.visible = false);
    });
  }
  updatePathNodes(opts) {
    return __async(this, null, function* () {
      const { paths, opacity, visible } = opts;
      for (const path of paths) {
        path.opacity = opacity;
        path.visible = visible;
      }
    });
  }
  resetAllAnimation(data) {
    var _a, _b;
    const { path, datum, label, marker } = (_b = (_a = this.opts) == null ? void 0 : _a.animationResetFns) != null ? _b : {};
    this.ctx.animationManager.stopByAnimationGroupId(this.id);
    if (path) {
      data.paths.forEach((paths) => {
        resetMotion(paths, path);
      });
    }
    if (datum) {
      resetMotion(data.datumSelections, datum);
    }
    if (label) {
      resetMotion(data.labelSelections, label);
    }
    if (marker) {
      resetMotion(data.markerSelections, marker);
    }
    if (data.contextData.some((d) => d.animationValid === false)) {
      this.ctx.animationManager.skipCurrentBatch();
    }
  }
  animateEmptyUpdateReady(data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation(data);
  }
  animateWaitingUpdateReady(data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation(data);
  }
  animateReadyHighlight(data) {
    var _a, _b;
    const { datum } = (_b = (_a = this.opts) == null ? void 0 : _a.animationResetFns) != null ? _b : {};
    if (datum) {
      resetMotion([data], datum);
    }
  }
  animateReadyHighlightMarkers(data) {
    var _a, _b;
    const { marker } = (_b = (_a = this.opts) == null ? void 0 : _a.animationResetFns) != null ? _b : {};
    if (marker) {
      resetMotion([data], marker);
    }
  }
  animateReadyResize(data) {
    this.resetAllAnimation(data);
  }
  animateClearingUpdateEmpty(data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation(data);
  }
  animationTransitionClear() {
    this.animationState.transition("clear", this.getAnimationData());
  }
  getAnimationData(seriesRect, previousContextData) {
    const animationData = {
      datumSelections: this.subGroups.map(({ datumSelection }) => datumSelection),
      markerSelections: this.subGroups.filter(({ markerSelection }) => markerSelection !== void 0).map(({ markerSelection }) => markerSelection),
      labelSelections: this.subGroups.map(({ labelSelection }) => labelSelection),
      annotationSelections: [...this.annotationSelections],
      contextData: this._contextNodeData,
      previousContextData,
      paths: this.subGroups.map(({ paths }) => paths),
      seriesRect
    };
    return animationData;
  }
  calculateScaling() {
    const result = {};
    const addScale = (direction) => {
      const axis = this.axes[direction];
      if (!axis)
        return;
      if (axis.scale instanceof LogScale) {
        const { range: range3, domain } = axis.scale;
        result[direction] = {
          type: "log",
          convert: (domain2) => axis.scale.convert(domain2),
          domain: [domain[0], domain[1]],
          range: [range3[0], range3[1]]
        };
      } else if (axis.scale instanceof ContinuousScale) {
        const { range: range3 } = axis.scale;
        const domain = axis.scale.getDomain();
        result[direction] = {
          type: "continuous",
          domain: [domain[0], domain[1]],
          range: [range3[0], range3[1]]
        };
      } else if (axis.scale) {
        const { domain } = axis.scale;
        result[direction] = {
          type: "category",
          domain,
          range: domain.map((d) => axis.scale.convert(d))
        };
      }
    };
    addScale("x" /* X */);
    addScale("y" /* Y */);
    return result;
  }
};

// packages/ag-charts-community/src/chart/cartesianChart.ts
var directions = ["top", "right", "bottom", "left"];
var _CartesianChart = class _CartesianChart extends Chart {
  constructor(specialOverrides, resources) {
    super(specialOverrides, resources);
    /** Integrated Charts feature state - not used in Standalone Charts. */
    this.paired = true;
    this._lastCrossLineIds = void 0;
    this._lastAxisWidths = {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    };
    this._lastVisibility = {
      crossLines: true,
      series: true
    };
  }
  performLayout() {
    return __async(this, null, function* () {
      const shrinkRect = yield __superGet(_CartesianChart.prototype, this, "performLayout").call(this);
      const { animationRect, seriesRect, visibility, clipSeries } = this.updateAxes(shrinkRect);
      this.seriesRoot.visible = visibility.series;
      this.seriesRect = seriesRect;
      this.animationRect = animationRect;
      this.seriesRoot.translationX = Math.floor(seriesRect.x);
      this.seriesRoot.translationY = Math.floor(seriesRect.y);
      const {
        seriesArea: { padding }
      } = this;
      const seriesPaddedRect = seriesRect.clone().grow({
        top: padding.top,
        right: padding.right,
        bottom: padding.bottom,
        left: padding.left
      });
      this.hoverRect = seriesPaddedRect;
      this.layoutService.dispatchLayoutComplete({
        type: "layout-complete",
        chart: { width: this.scene.width, height: this.scene.height },
        clipSeries,
        series: {
          rect: seriesRect,
          paddedRect: seriesPaddedRect,
          visible: visibility.series,
          shouldFlipXY: this.shouldFlipXY()
        },
        axes: this.axes.map((axis) => __spreadValues({ id: axis.id }, axis.getLayoutState()))
      });
      return shrinkRect;
    });
  }
  updateAxes(inputShrinkRect) {
    var _a;
    const crossLineIds = this.axes.flatMap((axis) => {
      var _a2;
      return (_a2 = axis.crossLines) != null ? _a2 : [];
    }).map((crossLine) => crossLine.id);
    const axesValid = this._lastCrossLineIds != null && this._lastCrossLineIds.length === crossLineIds.length && this._lastCrossLineIds.every((id, index) => crossLineIds[index] === id);
    let axisWidths;
    let visibility;
    if (axesValid) {
      axisWidths = __spreadValues({}, this._lastAxisWidths);
      visibility = __spreadValues({}, this._lastVisibility);
    } else {
      axisWidths = { top: 0, bottom: 0, left: 0, right: 0 };
      visibility = { crossLines: true, series: true };
      this._lastCrossLineIds = crossLineIds;
    }
    const liveAxisWidths = new Set(this._axes.map((a) => a.position));
    for (const position of Object.keys(axisWidths)) {
      if (!liveAxisWidths.has(position)) {
        delete axisWidths[position];
      }
    }
    const stableOutputs = (otherAxisWidths, otherVisibility) => {
      if (Object.keys(otherAxisWidths).some((k) => axisWidths[k] == null)) {
        return false;
      }
      return visibility.crossLines === otherVisibility.crossLines && visibility.series === otherVisibility.series && // Check for existing axis positions and equality.
      Object.entries(axisWidths).every(([p, w]) => {
        const otherW = otherAxisWidths[p];
        if (w != null || otherW != null) {
          return w === otherW;
        }
        return true;
      });
    };
    const ceilValues = (records) => {
      return Object.entries(records).reduce((out, [key, value]) => {
        if (value && Math.abs(value) === Infinity) {
          value = 0;
        }
        out[key] = value != null ? Math.ceil(value) : value;
        return out;
      }, {});
    };
    let lastPassAxisWidths = {};
    let lastPassVisibility = {};
    let clipSeries = false;
    let seriesRect = (_a = this.seriesRect) == null ? void 0 : _a.clone();
    let count2 = 0;
    let primaryTickCounts = {};
    do {
      Object.assign(axisWidths, lastPassAxisWidths);
      Object.assign(visibility, lastPassVisibility);
      const result = this.updateAxesPass(axisWidths, inputShrinkRect.clone(), seriesRect);
      lastPassAxisWidths = ceilValues(result.axisWidths);
      lastPassVisibility = result.visibility;
      clipSeries = result.clipSeries;
      seriesRect = result.seriesRect;
      primaryTickCounts = result.primaryTickCounts;
      if (count2++ > 10) {
        Logger.warn("unable to find stable axis layout.");
        break;
      }
    } while (!stableOutputs(lastPassAxisWidths, lastPassVisibility));
    this.axes.forEach((axis) => {
      const { direction } = axis;
      const primaryTickCount = primaryTickCounts[direction];
      axis.update(primaryTickCount);
    });
    const clipRectPadding = 5;
    this.axes.forEach((axis) => {
      axis.setCrossLinesVisible(visibility.crossLines);
      if (!seriesRect) {
        return;
      }
      axis.clipGrid(
        seriesRect.x,
        seriesRect.y,
        seriesRect.width + clipRectPadding,
        seriesRect.height + clipRectPadding
      );
      switch (axis.position) {
        case "left":
        case "right":
          axis.clipTickLines(
            inputShrinkRect.x,
            seriesRect.y,
            inputShrinkRect.width + clipRectPadding,
            seriesRect.height + clipRectPadding
          );
          break;
        case "top":
        case "bottom":
          axis.clipTickLines(
            seriesRect.x,
            inputShrinkRect.y,
            seriesRect.width + clipRectPadding,
            inputShrinkRect.height + clipRectPadding
          );
          break;
      }
    });
    this._lastAxisWidths = axisWidths;
    this._lastVisibility = visibility;
    return { seriesRect, animationRect: inputShrinkRect, visibility, clipSeries };
  }
  updateAxesPass(axisWidths, bounds, lastPassSeriesRect) {
    const { axes } = this;
    const visited = {};
    const newAxisWidths = {};
    const visibility = {
      series: true,
      crossLines: true
    };
    let clipSeries = false;
    const primaryTickCounts = {};
    const paddedBounds = this.applySeriesPadding(bounds);
    const crossLinePadding = lastPassSeriesRect ? this.buildCrossLinePadding(axisWidths) : {};
    const axisBound = this.buildAxisBound(paddedBounds, axisWidths, crossLinePadding, visibility);
    const seriesRect = this.buildSeriesRect(axisBound, axisWidths);
    axes.forEach((axis) => {
      var _a, _b;
      const { position = "left" } = axis;
      const {
        clipSeries: newClipSeries,
        axisThickness,
        axisOffset
      } = this.calculateAxisDimensions({
        axis,
        seriesRect,
        paddedBounds,
        axisWidths,
        newAxisWidths,
        primaryTickCounts,
        clipSeries,
        addInterAxisPadding: ((_a = visited[position]) != null ? _a : 0) > 0
      });
      visited[position] = ((_b = visited[position]) != null ? _b : 0) + 1;
      clipSeries = clipSeries || newClipSeries;
      this.positionAxis({
        axis,
        axisBound,
        axisOffset,
        axisThickness,
        axisWidths,
        primaryTickCounts,
        seriesRect
      });
    });
    return { clipSeries, seriesRect, axisWidths: newAxisWidths, visibility, primaryTickCounts };
  }
  buildCrossLinePadding(axisWidths) {
    var _a;
    const crossLinePadding = {};
    this.axes.forEach((axis) => {
      if (axis.crossLines) {
        axis.crossLines.forEach((crossLine) => {
          crossLine.calculatePadding(crossLinePadding);
        });
      }
    });
    for (const [side, padding = 0] of Object.entries(crossLinePadding)) {
      crossLinePadding[side] = Math.max(padding - ((_a = axisWidths[side]) != null ? _a : 0), 0);
    }
    return crossLinePadding;
  }
  applySeriesPadding(bounds) {
    const paddedRect = bounds.clone();
    const reversedAxes = this.axes.slice().reverse();
    directions.forEach((dir) => {
      const padding = this.seriesArea.padding[dir];
      const axis = reversedAxes.find((axis2) => axis2.position === dir);
      if (axis) {
        axis.seriesAreaPadding = padding;
      } else {
        paddedRect.shrink(padding, dir);
      }
    });
    return paddedRect;
  }
  buildAxisBound(bounds, axisWidths, crossLinePadding, visibility) {
    var _a, _b, _c, _d;
    const result = bounds.clone();
    const { top = 0, right = 0, bottom = 0, left = 0 } = crossLinePadding;
    const horizontalPadding = left + right;
    const verticalPadding = top + bottom;
    const totalWidth = ((_a = axisWidths.left) != null ? _a : 0) + ((_b = axisWidths.right) != null ? _b : 0) + horizontalPadding;
    const totalHeight = ((_c = axisWidths.top) != null ? _c : 0) + ((_d = axisWidths.bottom) != null ? _d : 0) + verticalPadding;
    if (result.width <= totalWidth || result.height <= totalHeight) {
      visibility.crossLines = false;
      visibility.series = false;
      return result;
    }
    result.x += left;
    result.y += top;
    result.width -= horizontalPadding;
    result.height -= verticalPadding;
    return result;
  }
  buildSeriesRect(axisBound, axisWidths) {
    const result = axisBound.clone();
    const { top, bottom, left, right } = axisWidths;
    result.x += left != null ? left : 0;
    result.y += top != null ? top : 0;
    result.width -= (left != null ? left : 0) + (right != null ? right : 0);
    result.height -= (top != null ? top : 0) + (bottom != null ? bottom : 0);
    result.width = Math.max(0, result.width);
    result.height = Math.max(0, result.height);
    return result;
  }
  clampToOutsideSeriesRect(seriesRect, value, dimension, direction) {
    const { x, y, width, height } = seriesRect;
    const clampBounds = [x, y, x + width, y + height];
    const fn = direction === 1 ? Math.min : Math.max;
    const compareTo = clampBounds[(dimension === "x" ? 0 : 1) + (direction === 1 ? 0 : 2)];
    return fn(value, compareTo);
  }
  calculateAxisDimensions(opts) {
    var _a, _b, _c, _d, _e;
    const { axis, seriesRect, paddedBounds, axisWidths, newAxisWidths, primaryTickCounts, addInterAxisPadding } = opts;
    let { clipSeries } = opts;
    const { position = "left", direction } = axis;
    const axisLeftRightRange = (axis2) => {
      if (axis2 instanceof CategoryAxis || axis2 instanceof GroupedCategoryAxis) {
        return [0, seriesRect.height];
      }
      return [seriesRect.height, 0];
    };
    const axisOffset = (_a = newAxisWidths[position]) != null ? _a : 0;
    switch (position) {
      case "top":
      case "bottom":
        axis.range = [0, seriesRect.width];
        axis.gridLength = seriesRect.height;
        break;
      case "right":
      case "left":
        axis.range = axisLeftRightRange(axis);
        axis.gridLength = seriesRect.width;
        break;
    }
    const zoom = this.zoomManager.getAxisZoom(axis.id);
    const { min = 0, max = 1 } = zoom != null ? zoom : {};
    axis.visibleRange = [min, max];
    const rangeClipped = axis.dataDomain.clipped || axis.visibleRange[0] > 0 || axis.visibleRange[1] < 1;
    clipSeries || (clipSeries = rangeClipped);
    let primaryTickCount = axis.nice ? primaryTickCounts[direction] : void 0;
    const paddedBoundsCoefficient = 0.3;
    if (axis.thickness != null && axis.thickness > 0) {
      axis.maxThickness = axis.thickness;
    } else if (direction === "y" /* Y */) {
      axis.maxThickness = paddedBounds.width * paddedBoundsCoefficient;
    } else {
      axis.maxThickness = paddedBounds.height * paddedBoundsCoefficient;
    }
    const layout = axis.calculateLayout(primaryTickCount);
    primaryTickCount = layout.primaryTickCount;
    primaryTickCounts[direction] = (_b = primaryTickCounts[direction]) != null ? _b : primaryTickCount;
    let axisThickness = 0;
    if (axis.thickness != null && axis.thickness > 0) {
      axisThickness = axis.thickness;
    } else {
      const { bbox } = layout;
      axisThickness = direction === "x" /* X */ ? bbox.height : bbox.width;
    }
    const axisPadding = 15;
    if (addInterAxisPadding) {
      axisThickness += axisPadding;
    }
    axisThickness = Math.ceil(axisThickness);
    newAxisWidths[position] = ((_c = newAxisWidths[position]) != null ? _c : 0) + axisThickness;
    axis.gridPadding = ((_d = axisWidths[position]) != null ? _d : 0) - ((_e = newAxisWidths[position]) != null ? _e : 0);
    return { clipSeries, axisThickness, axisOffset, primaryTickCount };
  }
  positionAxis(opts) {
    var _a, _b, _c, _d;
    const { axis, axisBound, axisWidths, seriesRect, axisOffset, axisThickness } = opts;
    const { position } = axis;
    switch (position) {
      case "top":
        axis.translation.x = axisBound.x + ((_a = axisWidths.left) != null ? _a : 0);
        axis.translation.y = this.clampToOutsideSeriesRect(
          seriesRect,
          axisBound.y + 1 + axisOffset + axisThickness,
          "y",
          1
        );
        break;
      case "bottom":
        axis.translation.x = axisBound.x + ((_b = axisWidths.left) != null ? _b : 0);
        axis.translation.y = this.clampToOutsideSeriesRect(
          seriesRect,
          axisBound.y + axisBound.height + 1 - axisThickness - axisOffset,
          "y",
          -1
        );
        break;
      case "left":
        axis.translation.y = axisBound.y + ((_c = axisWidths.top) != null ? _c : 0);
        axis.translation.x = this.clampToOutsideSeriesRect(
          seriesRect,
          axisBound.x + axisOffset + axisThickness,
          "x",
          1
        );
        break;
      case "right":
        axis.translation.y = axisBound.y + ((_d = axisWidths.top) != null ? _d : 0);
        axis.translation.x = this.clampToOutsideSeriesRect(
          seriesRect,
          axisBound.x + axisBound.width - axisThickness - axisOffset,
          "x",
          -1
        );
        break;
    }
    axis.updatePosition({ rotation: toRadians(axis.rotation), sideFlag: axis.label.getSideFlag() });
  }
  shouldFlipXY() {
    return !this.series.some((series) => !(series instanceof CartesianSeries && series.shouldFlipXY()));
  }
};
_CartesianChart.className = "CartesianChart";
_CartesianChart.type = "cartesian";

// packages/ag-charts-community/src/util/secondaryAxisTicks.ts
function calculateNiceSecondaryAxis(domain, primaryTickCount, reverse) {
  let start = Math.floor(Math.min(domain[0], domain[1]));
  let stop = Math.max(domain[0], domain[1]);
  start = calculateNiceStart(start, stop, primaryTickCount);
  const step = getTickStep(start, stop, primaryTickCount);
  const segments = primaryTickCount - 1;
  stop = start + segments * step;
  const d = reverse ? [stop, start] : [start, stop];
  const ticks = getTicks(start, step, primaryTickCount);
  return [d, ticks];
}
function calculateNiceStart(a, b, count2) {
  const rawStep = Math.abs(b - a) / (count2 - 1);
  const order = Math.floor(Math.log10(rawStep));
  const magnitude = Math.pow(10, order);
  return Math.floor(a / magnitude) * magnitude;
}
function getTicks(start, step, count2) {
  const stepPower = Math.floor(Math.log10(step));
  const fractionDigits = step > 0 && step < 1 ? Math.abs(stepPower) : 0;
  const f = Math.pow(10, fractionDigits);
  const ticks = createNumericTicks(fractionDigits);
  for (let i = 0; i < count2; i++) {
    const tick = start + step * i;
    ticks[i] = Math.round(tick * f) / f;
  }
  return ticks;
}
function getTickStep(start, stop, count2) {
  const segments = count2 - 1;
  const rawStep = (stop - start) / segments;
  return calculateNextNiceStep(rawStep);
}
function calculateNextNiceStep(rawStep) {
  const order = Math.floor(Math.log10(rawStep));
  const magnitude = Math.pow(10, order);
  const step = rawStep / magnitude * 10;
  if (step > 0 && step <= 1) {
    return magnitude / 10;
  }
  if (step > 1 && step <= 2) {
    return 2 * magnitude / 10;
  }
  if (step > 1 && step <= 5) {
    return 5 * magnitude / 10;
  }
  if (step > 5 && step <= 10) {
    return 10 * magnitude / 10;
  }
  if (step > 10 && step <= 20) {
    return 20 * magnitude / 10;
  }
  if (step > 20 && step <= 40) {
    return 40 * magnitude / 10;
  }
  if (step > 40 && step <= 50) {
    return 50 * magnitude / 10;
  }
  if (step > 50 && step <= 100) {
    return 100 * magnitude / 10;
  }
  return step;
}

// packages/ag-charts-community/src/chart/axis/numberAxis.ts
var NumberAxisTick = class extends AxisTick {
  constructor() {
    super(...arguments);
    this.maxSpacing = NaN;
  }
};
__decorateClass([
  Validate(OR(AND(NUMBER.restrict({ min: 1 }), GREATER_THAN("minSpacing")), NAN)),
  Default(NaN)
], NumberAxisTick.prototype, "maxSpacing", 2);
var NumberAxis = class extends CartesianAxis {
  constructor(moduleCtx, scale2 = new LinearScale$1()) {
    super(moduleCtx, scale2);
    this.min = NaN;
    this.max = NaN;
  }
  normaliseDataDomain(d) {
    const { min, max } = this;
    const { extent: extent2, clipped } = normalisedExtentWithMetadata(d, min, max);
    return { domain: extent2, clipped };
  }
  formatDatum(datum) {
    if (typeof datum === "number") {
      return datum.toFixed(2);
    } else {
      Logger.warnOnce(
        "data contains Date objects which are being plotted against a number axis, please only use a number axis for numbers."
      );
      return String(datum);
    }
  }
  createTick() {
    return new NumberAxisTick();
  }
  updateSecondaryAxisTicks(primaryTickCount) {
    if (this.dataDomain == null) {
      throw new Error("AG Charts - dataDomain not calculated, cannot perform tick calculation.");
    }
    if (this.dataDomain.domain.length === 0)
      return [];
    const [d, ticks] = calculateNiceSecondaryAxis(this.dataDomain.domain, primaryTickCount != null ? primaryTickCount : 0, this.reverse);
    this.scale.nice = false;
    this.scale.domain = d;
    this.scale.update();
    return ticks;
  }
};
NumberAxis.className = "NumberAxis";
NumberAxis.type = "number";
__decorateClass([
  Validate(AND(NUMBER_OR_NAN, LESS_THAN("max"))),
  Default(NaN)
], NumberAxis.prototype, "min", 2);
__decorateClass([
  Validate(AND(NUMBER_OR_NAN, GREATER_THAN("min"))),
  Default(NaN)
], NumberAxis.prototype, "max", 2);

// packages/ag-charts-community/src/chart/axis/logAxis.ts
var NON_ZERO_NUMBER = predicateWithMessage((value) => isNumber$4(value) && value !== 0, "a non-zero number");
var LogAxis = class extends NumberAxis {
  constructor(moduleCtx) {
    super(moduleCtx, new LogScale());
    this.min = NaN;
    this.max = NaN;
  }
  normaliseDataDomain(d) {
    const { min, max } = this;
    const { extent: extent2, clipped } = normalisedExtentWithMetadata(d, min, max);
    const isInverted = extent2[0] > extent2[1];
    const crossesZero = extent2[0] < 0 && extent2[1] > 0;
    const hasZeroExtent = extent2[0] === 0 && extent2[1] === 0;
    const invalidDomain = isInverted || crossesZero || hasZeroExtent;
    if (invalidDomain) {
      d = [];
      if (crossesZero) {
        Logger.warn(
          `the data domain crosses zero, the chart data cannot be rendered. See log axis documentation for more information.`
        );
      } else if (hasZeroExtent) {
        Logger.warn(`the data domain has 0 extent, no data is rendered.`);
      }
    }
    if (extent2[0] === 0) {
      extent2[0] = 1;
    }
    if (extent2[1] === 0) {
      extent2[1] = -1;
    }
    return { domain: extent2, clipped };
  }
  set base(value) {
    this.scale.base = value;
  }
  get base() {
    return this.scale.base;
  }
};
LogAxis.className = "LogAxis";
LogAxis.type = "log";
__decorateClass([
  Validate(AND(NUMBER_OR_NAN, NON_ZERO_NUMBER, LESS_THAN("max"))),
  Default(NaN)
], LogAxis.prototype, "min", 2);
__decorateClass([
  Validate(AND(NUMBER_OR_NAN, NON_ZERO_NUMBER, GREATER_THAN("min"))),
  Default(NaN)
], LogAxis.prototype, "max", 2);

// packages/ag-charts-community/src/chart/axis/timeAxis.ts
var MAX_SPACING2 = OR(AND(NUMBER.restrict({ min: 1 }), GREATER_THAN("minSpacing")), NAN);
var TimeAxisTick = class extends AxisTick {
  constructor() {
    super(...arguments);
    this.maxSpacing = NaN;
  }
};
__decorateClass([
  Validate(MAX_SPACING2),
  Default(NaN)
], TimeAxisTick.prototype, "maxSpacing", 2);
var TimeAxis = class extends CartesianAxis {
  constructor(moduleCtx) {
    super(moduleCtx, new TimeScale$1());
    this.datumFormat = "%m/%d/%y, %H:%M:%S";
    this.min = void 0;
    this.max = void 0;
    const { scale: scale2 } = this;
    this.refreshScale();
    this.datumFormatter = scale2.tickFormat({
      specifier: this.datumFormat
    });
  }
  normaliseDataDomain(d) {
    var _a;
    let { min, max } = this;
    let clipped = false;
    if (typeof min === "number") {
      min = new Date(min);
    }
    if (typeof max === "number") {
      max = new Date(max);
    }
    if (d.length > 2) {
      d = ((_a = extent$4(d)) != null ? _a : [0, 1e3]).map((x) => new Date(x));
    }
    if (min instanceof Date) {
      clipped || (clipped = min > d[0]);
      d = [min, d[1]];
    }
    if (max instanceof Date) {
      clipped || (clipped = max < d[1]);
      d = [d[0], max];
    }
    if (d[0] > d[1]) {
      d = [];
    }
    return { domain: d, clipped };
  }
  createTick() {
    return new TimeAxisTick();
  }
  onLabelFormatChange(ticks, format2) {
    if (format2) {
      super.onLabelFormatChange(ticks, format2);
    } else {
      this.labelFormatter = this.scale.tickFormat({ ticks });
    }
  }
  formatDatum(datum) {
    var _a;
    return (_a = this.moduleCtx.callbackCache.call(this.datumFormatter, datum)) != null ? _a : String(datum);
  }
  calculatePadding(_min, _max, reverse) {
    return reverse ? [1, 0] : [0, 1];
  }
};
TimeAxis.className = "TimeAxis";
TimeAxis.type = "time";
__decorateClass([
  Validate(AND(DATE_OR_DATETIME_MS, LESS_THAN("max")), { optional: true })
], TimeAxis.prototype, "min", 2);
__decorateClass([
  Validate(AND(DATE_OR_DATETIME_MS, GREATER_THAN("min")), { optional: true })
], TimeAxis.prototype, "max", 2);

// packages/ag-charts-community/src/chart/factory/axisTypes.ts
var AXIS_CONSTRUCTORS = {
  [NumberAxis.type]: NumberAxis,
  [CategoryAxis.type]: CategoryAxis,
  [TimeAxis.type]: TimeAxis,
  [GroupedCategoryAxis.type]: GroupedCategoryAxis,
  [LogAxis.type]: LogAxis
};
var AXIS_TYPES = {
  has(axisType) {
    return Object.hasOwn(AXIS_CONSTRUCTORS, axisType);
  },
  get axesTypes() {
    return Object.keys(AXIS_CONSTRUCTORS);
  }
};
var AXIS_THEME_TEMPLATES = {};
function getAxisThemeTemplate(axisType) {
  var _a;
  return (_a = AXIS_THEME_TEMPLATES[axisType]) != null ? _a : {};
}
var LEGEND_THEME_TEMPLATES = {};
function getLegendThemeTemplates() {
  return LEGEND_THEME_TEMPLATES;
}

// packages/ag-charts-community/src/chart/background/background.ts
var Background = class extends BaseModuleInstance {
  constructor(ctx) {
    var _a;
    super();
    this.node = new Group({ name: "background", zIndex: 0 /* SERIES_BACKGROUND_ZINDEX */ });
    this.rectNode = new Rect();
    this.visible = true;
    this.fill = "white";
    // placeholder for enterprise module
    this.image = void 0;
    this.node.appendChild(this.rectNode);
    (_a = ctx.scene.root) == null ? void 0 : _a.appendChild(this.node);
    this.destroyFns.push(
      () => {
        var _a2;
        return (_a2 = ctx.scene.root) == null ? void 0 : _a2.removeChild(this.node);
      },
      ctx.layoutService.addListener("layout-complete", (e) => this.onLayoutComplete(e))
    );
  }
  onLayoutComplete(e) {
    const { width, height } = e.chart;
    this.rectNode.width = width;
    this.rectNode.height = height;
  }
};
__decorateClass([
  Validate(BOOLEAN),
  ProxyPropertyOnWrite("node", "visible")
], Background.prototype, "visible", 2);
__decorateClass([
  Validate(COLOR_STRING, { optional: true }),
  ProxyPropertyOnWrite("rectNode", "fill")
], Background.prototype, "fill", 2);

// packages/ag-charts-community/src/chart/background/backgroundModule.ts
var BackgroundModule = {
  type: "root",
  optionsKey: "background",
  packageType: "community",
  chartTypes: ["cartesian", "polar", "hierarchy"],
  instanceConstructor: Background
};

// packages/ag-charts-community/src/chart/navigator/navigatorHandle.ts
var NavigatorHandle = class {
  set fill(value) {
    this.rh.fill = value;
  }
  get fill() {
    return this.rh.fill;
  }
  set stroke(value) {
    this.rh.stroke = value;
  }
  get stroke() {
    return this.rh.stroke;
  }
  set strokeWidth(value) {
    this.rh.strokeWidth = value;
  }
  get strokeWidth() {
    return this.rh.strokeWidth;
  }
  set width(value) {
    this.rh.width = value;
  }
  get width() {
    return this.rh.width;
  }
  set height(value) {
    this.rh.height = value;
  }
  get height() {
    return this.rh.height;
  }
  set gripLineGap(value) {
    this.rh.gripLineGap = value;
  }
  get gripLineGap() {
    return this.rh.gripLineGap;
  }
  set gripLineLength(value) {
    this.rh.gripLineLength = value;
  }
  get gripLineLength() {
    return this.rh.gripLineLength;
  }
  constructor(rangeHandle) {
    this.rh = rangeHandle;
  }
};

// packages/ag-charts-community/src/chart/navigator/navigatorMask.ts
var NavigatorMask = class {
  set fill(value) {
    this.rm.fill = value;
  }
  get fill() {
    return this.rm.fill;
  }
  set stroke(value) {
    this.rm.stroke = value;
  }
  get stroke() {
    return this.rm.stroke;
  }
  set strokeWidth(value) {
    this.rm.strokeWidth = value;
  }
  get strokeWidth() {
    return this.rm.strokeWidth;
  }
  set fillOpacity(value) {
    this.rm.fillOpacity = value;
  }
  get fillOpacity() {
    return this.rm.fillOpacity;
  }
  constructor(rangeMask) {
    this.rm = rangeMask;
  }
};

// packages/ag-charts-community/src/chart/navigator/shapes/rangeHandle.ts
var RangeHandle = class extends Path {
  constructor() {
    super(...arguments);
    this._fill = "#f2f2f2";
    this._stroke = "#999999";
    this._strokeWidth = 1;
    this._lineCap = "square";
    this._centerX = 0;
    this._centerY = 0;
    this._width = 8;
    this._gripLineGap = 2;
    this._gripLineLength = 8;
    this._height = 16;
  }
  set centerX(value) {
    if (this._centerX !== value) {
      this._centerX = value;
      this.dirtyPath = true;
    }
  }
  get centerX() {
    return this._centerX;
  }
  set centerY(value) {
    if (this._centerY !== value) {
      this._centerY = value;
      this.dirtyPath = true;
    }
  }
  get centerY() {
    return this._centerY;
  }
  set width(value) {
    if (this._width !== value) {
      this._width = value;
      this.dirtyPath = true;
    }
  }
  get width() {
    return this._width;
  }
  set gripLineGap(value) {
    if (this._gripLineGap !== value) {
      this._gripLineGap = value;
      this.dirtyPath = true;
    }
  }
  get gripLineGap() {
    return this._gripLineGap;
  }
  set gripLineLength(value) {
    if (this._gripLineLength !== value) {
      this._gripLineLength = value;
      this.dirtyPath = true;
    }
  }
  get gripLineLength() {
    return this._gripLineLength;
  }
  set height(value) {
    if (this._height !== value) {
      this._height = value;
      this.dirtyPath = true;
    }
  }
  get height() {
    return this._height;
  }
  computeBBox() {
    const { centerX, centerY, width, height } = this;
    const x = centerX - width / 2;
    const y = centerY - height / 2;
    return new BBox(x, y, width, height);
  }
  isPointInPath(x, y) {
    const point = this.transformPoint(x, y);
    const bbox = this.computeBBox();
    return bbox.containsPoint(point.x, point.y);
  }
  updatePath() {
    const { path, centerX, centerY, width, height } = this;
    path.clear();
    const x = centerX - width / 2;
    const y = centerY - height / 2;
    const ax = this.align(x);
    const ay = this.align(y);
    const axw = ax + this.align(x, width);
    const ayh = ay + this.align(y, height);
    path.moveTo(ax, ay);
    path.lineTo(axw, ay);
    path.lineTo(axw, ayh);
    path.lineTo(ax, ayh);
    path.lineTo(ax, ay);
    const dx = this.gripLineGap / 2;
    const dy = this.gripLineLength / 2;
    path.moveTo(this.align(centerX - dx), this.align(centerY - dy));
    path.lineTo(this.align(centerX - dx), this.align(centerY + dy));
    path.moveTo(this.align(centerX + dx), this.align(centerY - dy));
    path.lineTo(this.align(centerX + dx), this.align(centerY + dy));
  }
};
RangeHandle.className = "RangeHandle";
__decorateClass([
  Validate(COLOR_STRING)
], RangeHandle.prototype, "_fill", 2);
__decorateClass([
  Validate(COLOR_STRING)
], RangeHandle.prototype, "_stroke", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], RangeHandle.prototype, "_strokeWidth", 2);
__decorateClass([
  Validate(LINE_CAP)
], RangeHandle.prototype, "_lineCap", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], RangeHandle.prototype, "_width", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], RangeHandle.prototype, "_gripLineGap", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], RangeHandle.prototype, "_gripLineLength", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], RangeHandle.prototype, "_height", 2);

// packages/ag-charts-community/src/chart/navigator/shapes/rangeMask.ts
function markDirtyOnChange(newValue, oldValue) {
  if (newValue !== oldValue) {
    this.dirtyPath = true;
  }
}
var RangeMask = class extends Path {
  constructor() {
    super(...arguments);
    this.x = 0;
    this.y = 0;
    this.width = 200;
    this.height = 30;
    this.minRange = 0.05;
    this._min = 0;
    this._max = 1;
  }
  set min(value) {
    var _a;
    value = clamp2(0, value, this.max - this.minRange);
    if (this._min !== value && !isNaN(value)) {
      this._min = value;
      this.dirtyPath = true;
      (_a = this.onRangeChange) == null ? void 0 : _a.call(this);
    }
  }
  get min() {
    return this._min;
  }
  set max(value) {
    var _a;
    value = clamp2(this.min + this.minRange, value, 1);
    if (this._max !== value && !isNaN(value)) {
      this._max = value;
      this.dirtyPath = true;
      (_a = this.onRangeChange) == null ? void 0 : _a.call(this);
    }
  }
  get max() {
    return this._max;
  }
  computeBBox() {
    const { x, y, width, height } = this;
    return new BBox(x, y, width, height);
  }
  computeVisibleRangeBBox() {
    const { x, y, width, height, min, max } = this;
    const minX = x + width * min;
    const maxX = x + width * max;
    return new BBox(minX, y, maxX - minX, height);
  }
  updatePath() {
    const { path, x, y, width, height, min, max } = this;
    path.clear();
    const ax = this.align(x);
    const ay = this.align(y);
    const axw = ax + this.align(x, width);
    const ayh = ay + this.align(y, height);
    path.moveTo(ax, ay);
    path.lineTo(axw, ay);
    path.lineTo(axw, ayh);
    path.lineTo(ax, ayh);
    path.lineTo(ax, ay);
    const minX = this.align(x + width * min);
    const maxX = this.align(x + width * max);
    path.moveTo(minX, ay);
    path.lineTo(minX, ayh);
    path.lineTo(maxX, ayh);
    path.lineTo(maxX, ay);
    path.lineTo(minX, ay);
  }
};
RangeMask.className = "RangeMask";
__decorateClass([
  ActionOnSet({ changeValue: markDirtyOnChange }),
  Validate(POSITIVE_NUMBER)
], RangeMask.prototype, "x", 2);
__decorateClass([
  ActionOnSet({ changeValue: markDirtyOnChange }),
  Validate(POSITIVE_NUMBER)
], RangeMask.prototype, "y", 2);
__decorateClass([
  ActionOnSet({ changeValue: markDirtyOnChange }),
  Validate(POSITIVE_NUMBER)
], RangeMask.prototype, "width", 2);
__decorateClass([
  ActionOnSet({ changeValue: markDirtyOnChange }),
  Validate(POSITIVE_NUMBER)
], RangeMask.prototype, "height", 2);
__decorateClass([
  Validate(NUMBER)
], RangeMask.prototype, "_min", 2);
__decorateClass([
  Validate(NUMBER)
], RangeMask.prototype, "_max", 2);

// packages/ag-charts-community/src/chart/navigator/shapes/rangeSelector.ts
var _RangeSelector = class _RangeSelector extends Group {
  constructor() {
    super({ name: "rangeSelectorGroup" });
    this.minHandle = new RangeHandle();
    this.maxHandle = new RangeHandle();
    this.mask = (() => {
      const { x, y, width, height, min, max } = _RangeSelector.defaults;
      const mask = new RangeMask();
      mask.x = x;
      mask.y = y;
      mask.width = width;
      mask.height = height;
      mask.min = min;
      mask.max = max;
      const { minHandle, maxHandle } = this;
      minHandle.centerX = x;
      maxHandle.centerX = x + width;
      minHandle.centerY = maxHandle.centerY = y + height / 2;
      this.append([mask, minHandle, maxHandle]);
      mask.onRangeChange = () => {
        var _a;
        this.updateHandles();
        (_a = this.onRangeChange) == null ? void 0 : _a.call(this);
      };
      return mask;
    })();
    this._x = _RangeSelector.defaults.x;
    this._y = _RangeSelector.defaults.y;
    this._width = _RangeSelector.defaults.width;
    this._height = _RangeSelector.defaults.height;
    this._min = _RangeSelector.defaults.min;
    this._max = _RangeSelector.defaults.max;
    this.isContainerNode = true;
  }
  set x(value) {
    this.mask.x = value;
    this.updateHandles();
  }
  get x() {
    return this.mask.x;
  }
  set y(value) {
    this.mask.y = value;
    this.updateHandles();
  }
  get y() {
    return this.mask.y;
  }
  set width(value) {
    this.mask.width = value;
    this.updateHandles();
  }
  get width() {
    return this.mask.width;
  }
  set height(value) {
    this.mask.height = value;
    this.updateHandles();
  }
  get height() {
    return this.mask.height;
  }
  set min(value) {
    this.mask.min = value;
  }
  get min() {
    return this.mask.min;
  }
  set max(value) {
    this.mask.max = value;
  }
  get max() {
    return this.mask.max;
  }
  updateHandles() {
    const { minHandle, maxHandle, x, y, width, height, mask } = this;
    minHandle.centerX = x + width * mask.min;
    maxHandle.centerX = x + width * mask.max;
    minHandle.centerY = maxHandle.centerY = y + height / 2;
  }
  computeBBox() {
    return this.mask.computeBBox();
  }
  computeVisibleRangeBBox() {
    return this.mask.computeVisibleRangeBBox();
  }
  render(renderCtx) {
    const { ctx, forceRender, stats } = renderCtx;
    if (this.dirty === 0 /* NONE */ && !forceRender) {
      if (stats)
        stats.nodesSkipped++;
      return;
    }
    this.computeTransformMatrix();
    this.matrix.toContext(ctx);
    const { mask, minHandle, maxHandle } = this;
    [mask, minHandle, maxHandle].forEach((child) => {
      if (child.visible && (forceRender || child.dirty > 0 /* NONE */)) {
        ctx.save();
        child.render(__spreadProps(__spreadValues({}, renderCtx), { ctx, forceRender }));
        ctx.restore();
      }
    });
    this.markClean({ force: true });
    if (stats)
      stats.nodesRendered++;
  }
};
_RangeSelector.className = "Range";
_RangeSelector.defaults = {
  x: 0,
  y: 0,
  width: 200,
  height: 30,
  min: 0,
  max: 1
};
var RangeSelector = _RangeSelector;

// packages/ag-charts-community/src/chart/navigator/navigator.ts
var Navigator = class extends BaseModuleInstance {
  constructor(ctx) {
    var _a;
    super();
    this.ctx = ctx;
    this.rs = new RangeSelector();
    // Wrappers to allow option application to the scene graph nodes.
    this.mask = new NavigatorMask(this.rs.mask);
    this.minHandle = new NavigatorHandle(this.rs.minHandle);
    this.maxHandle = new NavigatorHandle(this.rs.maxHandle);
    this.minHandleDragging = false;
    this.maxHandleDragging = false;
    this.panHandleOffset = NaN;
    this.enabled = false;
    this.margin = 10;
    this._visible = true;
    this.rs.onRangeChange = () => ctx.zoomManager.updateZoom("navigator", {
      x: { min: this.rs.min, max: this.rs.max },
      y: { min: 0, max: 1 }
    });
    (_a = ctx.scene.root) == null ? void 0 : _a.appendChild(this.rs);
    const interactionOpts = { bypassPause: ["animation"] };
    this.destroyFns.push(
      ctx.interactionManager.addListener("drag-start", (event) => this.onDragStart(event), interactionOpts),
      ctx.interactionManager.addListener("drag", (event) => this.onDrag(event), interactionOpts),
      ctx.interactionManager.addListener("hover", (event) => this.onDrag(event), interactionOpts),
      ctx.interactionManager.addListener("drag-end", () => this.onDragStop(), interactionOpts),
      ctx.layoutService.addListener("before-series", (event) => this.layout(event)),
      ctx.layoutService.addListener("layout-complete", (event) => this.layoutComplete(event)),
      () => {
        var _a2;
        return (_a2 = ctx.scene.root) == null ? void 0 : _a2.removeChild(this.rs);
      },
      () => this.ctx.zoomManager.updateZoom("navigator")
    );
    this.updateGroupVisibility();
  }
  set width(value) {
    this.rs.width = value;
  }
  get width() {
    return this.rs.width;
  }
  set height(value) {
    this.rs.height = value;
  }
  get height() {
    return this.rs.height;
  }
  set min(value) {
    this.rs.min = value;
  }
  get min() {
    return this.rs.min;
  }
  set max(value) {
    this.rs.max = value;
  }
  get max() {
    return this.rs.max;
  }
  set visible(value) {
    this._visible = value;
    this.updateGroupVisibility();
  }
  get visible() {
    return this._visible;
  }
  updateGroupVisibility() {
    const visible = this.enabled && this.visible;
    this.rs.visible = visible;
    if (visible) {
      this.ctx.zoomManager.updateZoom("navigator", {
        x: { min: this.rs.min, max: this.rs.max },
        y: { min: 0, max: 1 }
      });
    } else {
      this.ctx.zoomManager.updateZoom("navigator");
    }
  }
  layout({ shrinkRect }) {
    if (this.enabled) {
      const navigatorTotalHeight = this.rs.height + this.margin;
      shrinkRect.shrink(navigatorTotalHeight, "bottom");
      this.rs.y = shrinkRect.y + shrinkRect.height + this.margin;
    }
    return { shrinkRect };
  }
  layoutComplete({ series: { rect, visible } }) {
    if (this.enabled && visible) {
      this.rs.x = rect.x;
      this.rs.width = rect.width;
    }
    this.visible = visible;
  }
  onDragStart(offset4) {
    if (!this.enabled) {
      return;
    }
    const { offsetX, offsetY } = offset4;
    const { rs } = this;
    const { minHandle, maxHandle, x, width, min } = rs;
    const visibleRange = rs.computeVisibleRangeBBox();
    if (!(this.minHandleDragging || this.maxHandleDragging)) {
      if (minHandle.containsPoint(offsetX, offsetY)) {
        this.minHandleDragging = true;
      } else if (maxHandle.containsPoint(offsetX, offsetY)) {
        this.maxHandleDragging = true;
      } else if (visibleRange.containsPoint(offsetX, offsetY)) {
        this.panHandleOffset = (offsetX - x) / width - min;
      }
    }
  }
  onDrag(offset4) {
    if (!this.enabled) {
      return;
    }
    const { rs, panHandleOffset } = this;
    const { x, y, width, height, minHandle, maxHandle } = rs;
    const { offsetX, offsetY } = offset4;
    const minX = x + width * rs.min;
    const maxX = x + width * rs.max;
    const visibleRange = new BBox(minX, y, maxX - minX, height);
    const getRatio = () => Math.min(Math.max((offsetX - x) / width, 0), 1);
    if (minHandle.containsPoint(offsetX, offsetY) || maxHandle.containsPoint(offsetX, offsetY)) {
      this.ctx.cursorManager.updateCursor("navigator", "ew-resize");
    } else if (visibleRange.containsPoint(offsetX, offsetY)) {
      this.ctx.cursorManager.updateCursor("navigator", "grab");
    } else {
      this.ctx.cursorManager.updateCursor("navigator");
    }
    if (this.minHandleDragging) {
      rs.min = getRatio();
    } else if (this.maxHandleDragging) {
      rs.max = getRatio();
    } else if (!isNaN(panHandleOffset)) {
      const span = rs.max - rs.min;
      const min = Math.min(getRatio() - panHandleOffset, 1 - span);
      if (min <= rs.min) {
        rs.min = min;
        rs.max = rs.min + span;
      } else {
        rs.max = min + span;
        rs.min = rs.max - span;
      }
    }
  }
  onDragStop() {
    this.stopHandleDragging();
  }
  stopHandleDragging() {
    this.minHandleDragging = this.maxHandleDragging = false;
    this.panHandleOffset = NaN;
  }
};
__decorateClass([
  ActionOnSet({
    changeValue(newValue) {
      if (newValue) {
        this.min = 0;
        this.max = 1;
      }
      this.updateGroupVisibility();
    }
  }),
  Validate(BOOLEAN)
], Navigator.prototype, "enabled", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], Navigator.prototype, "margin", 2);

// packages/ag-charts-community/src/util/theme.ts
var singleSeriesPaletteFactory = ({ takeColors }) => {
  const {
    fills: [fill],
    strokes: [stroke]
  } = takeColors(1);
  return { fill, stroke };
};
var markerPaletteFactory = (params) => {
  const { fill, stroke } = singleSeriesPaletteFactory(params);
  return { marker: { fill, stroke } };
};

// packages/ag-charts-community/src/chart/themes/constants.ts
var FONT_SIZE = {
  SMALL: 12,
  MEDIUM: 13,
  LARGE: 17
};
var FONT_WEIGHT2 = {
  NORMAL: "normal",
  BOLD: "bold",
  BOLDER: "bolder",
  LIGHTER: "lighter"
};
var BOTTOM = "bottom";
var LEFT = "left";
var TOP = "top";
var CARTESIAN_AXIS_POSITIONS = {
  BOTTOM,
  LEFT,
  TOP
};
var CATEGORY = "category";
var NUMBER2 = "number";
var TIME = "time";
var LOG = "log";
var CARTESIAN_AXIS_TYPES = {
  CATEGORY,
  NUMBER: NUMBER2,
  TIME,
  LOG
};
var ANGLE_CATEGORY = "angle-category";
var ANGLE_NUMBER = "angle-number";
var RADIUS_CATEGORY = "radius-category";
var RADIUS_NUMBER = "radius-number";
var POLAR_AXIS_TYPES = {
  ANGLE_CATEGORY,
  ANGLE_NUMBER,
  RADIUS_CATEGORY,
  RADIUS_NUMBER
};
var CIRCLE = "circle";

// packages/ag-charts-community/src/chart/factory/chartTypes.ts
var TYPES = {};
var DEFAULTS = {};
var CHART_TYPES = {
  has(seriesType) {
    return Object.hasOwn(TYPES, seriesType);
  },
  isCartesian(seriesType) {
    return TYPES[seriesType] === "cartesian";
  },
  isPolar(seriesType) {
    return TYPES[seriesType] === "polar";
  },
  isHierarchy(seriesType) {
    return TYPES[seriesType] === "hierarchy";
  },
  get seriesTypes() {
    return Object.keys(TYPES);
  },
  get cartesianTypes() {
    return this.seriesTypes.filter((t) => this.isCartesian(t));
  },
  get polarTypes() {
    return this.seriesTypes.filter((t) => this.isPolar(t));
  },
  get hierarchyTypes() {
    return this.seriesTypes.filter((t) => this.isHierarchy(t));
  }
};
function getChartDefaults(chartType2) {
  var _a;
  return (_a = DEFAULTS[chartType2]) != null ? _a : {};
}

// packages/ag-charts-community/src/chart/mapping/defaults.ts
({
  axes: [
    {
      type: CARTESIAN_AXIS_TYPES.NUMBER,
      position: CARTESIAN_AXIS_POSITIONS.LEFT
    },
    {
      type: CARTESIAN_AXIS_TYPES.CATEGORY,
      position: CARTESIAN_AXIS_POSITIONS.BOTTOM
    }
  ]
});

// packages/ag-charts-community/src/chart/themes/symbols.ts
var EXTENDS_CHART_DEFAULTS = Symbol("extends-chart-defaults");
var EXTENDS_LEGEND_DEFAULTS = Symbol("extends-legend-defaults");
var EXTENDS_LEGEND_ITEM_DEFAULTS = Symbol("extends-legend-item-defaults");
var EXTENDS_LEGEND_ITEM_MARKER_DEFAULTS = Symbol("extends-legend-item-marker-defaults");
var EXTENDS_AXES_DEFAULTS = Symbol("extends-axes-defaults");
var EXTENDS_AXES_LABEL_DEFAULTS = Symbol("extends-axes-label-defaults");
var EXTENDS_AXES_LINE_DEFAULTS = Symbol("extends-axes-line-defaults");
var EXTENDS_AXES_TICK_DEFAULTS = Symbol("extends-axes-tick-defaults");
var EXTENDS_AXES_GRID_LINE_DEFAULTS = Symbol("extends-axes-grid-line-defaults");
var EXTENDS_SERIES_DEFAULTS = Symbol("extends-series-defaults");
var EXTENDS_CARTESIAN_MARKER_DEFAULTS = Symbol("extends-cartesian-marker-defaults");
var OVERRIDE_SERIES_LABEL_DEFAULTS = Symbol("override-series-label-defaults");
var DEFAULT_FONT_FAMILY = Symbol("default-font");
var DEFAULT_LABEL_COLOUR = Symbol("default-label-colour");
var DEFAULT_INVERTED_LABEL_COLOUR = Symbol("default-inverted-label-colour");
var DEFAULT_INSIDE_SERIES_LABEL_COLOUR = Symbol("default-inside-series-label-colour");
var DEFAULT_MUTED_LABEL_COLOUR = Symbol("default-muted-label-colour");
var DEFAULT_AXIS_GRID_COLOUR = Symbol("default-axis-grid-colour");
var DEFAULT_AXIS_LINE_COLOUR = Symbol("default-axis-line-colour");
var DEFAULT_CROSS_LINES_COLOUR = Symbol("default-cross-lines-colour");
var DEFAULT_BACKGROUND_COLOUR = Symbol("default-background-colour");
var DEFAULT_SHADOW_COLOUR = Symbol("default-shadow-colour");
var DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS = Symbol(
  "default-waterfall-series-positive-colors"
);
var DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS = Symbol(
  "default-waterfall-series-negative-colors"
);
var DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS = Symbol(
  "default-waterfall-series-total-colors"
);
var DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE = Symbol(
  "default-waterfall-series-connector-line-stroke"
);
var DEFAULT_POLAR_SERIES_STROKE = Symbol("default-polar-series-stroke");
var DEFAULT_DIVERGING_SERIES_COLOUR_RANGE = Symbol(
  "default-diverging-series-colour-range"
);
var DEFAULT_HIERARCHY_FILLS = Symbol("default-hierarchy-fills");
var DEFAULT_HIERARCHY_STROKES = Symbol("default-hierarchy-strokes");

// packages/ag-charts-community/src/motion/pathMotion.ts
function pathMotion(groupId, subId, animationManager, paths, fns) {
  const { defaultDuration } = animationManager;
  const { addPhaseFn, updatePhaseFn, removePhaseFn } = fns;
  const animate = (phase, path, updateFn) => {
    animationManager.animate({
      id: `${groupId}_${subId}_${path.id}_${phase}`,
      groupId,
      from: 0,
      to: 1,
      ease: easeOut,
      onUpdate(ratio, preInit) {
        if (preInit && phase !== "removed")
          return;
        path.path.clear({ trackChanges: true });
        updateFn(ratio, path);
        path.checkPathDirty();
      },
      onStop() {
        if (phase !== "added")
          return;
        path.path.clear({ trackChanges: true });
        updateFn(1, path);
        path.checkPathDirty();
      },
      duration: FROM_TO_MIXINS[phase].animationDuration * defaultDuration,
      delay: FROM_TO_MIXINS[phase].animationDelay * defaultDuration
    });
  };
  for (const path of paths) {
    if (!animationManager.isSkipped()) {
      animate("removed", path, removePhaseFn);
      animate("updated", path, updatePhaseFn);
    }
    animate("added", path, addPhaseFn);
  }
}

// packages/ag-charts-community/src/chart/series/seriesLabelUtil.ts
function seriesLabelFadeInAnimation({ id }, subId, animationManager, labelSelections) {
  staticFromToMotion(id, subId, animationManager, labelSelections, { opacity: 0 }, { opacity: 1 }, LABEL_PHASE);
}
function seriesLabelFadeOutAnimation({ id }, subId, animationManager, labelSelections) {
  staticFromToMotion(id, subId, animationManager, labelSelections, { opacity: 1 }, { opacity: 0 }, LABEL_PHASE);
}
function resetLabelFn(_node) {
  return { opacity: 1 };
}

// packages/ag-charts-community/src/chart/series/seriesMarker.ts
var MARKER_SHAPE = predicateWithMessage(
  (value) => isMarkerShape(value) || Object.getPrototypeOf(value) === Marker,
  `a marker shape keyword such as 'circle', 'diamond' or 'square' or an object extending the Marker class`
);
var SeriesMarker = class extends BaseProperties {
  constructor() {
    super(...arguments);
    this.enabled = true;
    this.shape = Circle;
    this.size = 6;
    this.fillOpacity = 1;
    this.strokeWidth = 1;
    this.strokeOpacity = 1;
  }
  getStyle() {
    const { size, fill, fillOpacity, stroke, strokeWidth, strokeOpacity } = this;
    return { size, fill, fillOpacity, stroke, strokeWidth, strokeOpacity };
  }
  getDiameter() {
    return this.size + this.strokeWidth;
  }
};
__decorateClass([
  Validate(BOOLEAN),
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], SeriesMarker.prototype, "enabled", 2);
__decorateClass([
  Validate(MARKER_SHAPE),
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], SeriesMarker.prototype, "shape", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER),
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], SeriesMarker.prototype, "size", 2);
__decorateClass([
  Validate(COLOR_STRING, { optional: true }),
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], SeriesMarker.prototype, "fill", 2);
__decorateClass([
  Validate(RATIO),
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], SeriesMarker.prototype, "fillOpacity", 2);
__decorateClass([
  Validate(COLOR_STRING, { optional: true }),
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], SeriesMarker.prototype, "stroke", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER),
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], SeriesMarker.prototype, "strokeWidth", 2);
__decorateClass([
  Validate(RATIO),
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], SeriesMarker.prototype, "strokeOpacity", 2);
__decorateClass([
  Validate(FUNCTION, { optional: true }),
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], SeriesMarker.prototype, "formatter", 2);

// packages/ag-charts-community/src/chart/series/seriesTooltip.ts
var SeriesTooltipInteraction = class extends BaseProperties {
  constructor() {
    super(...arguments);
    this.enabled = false;
  }
};
__decorateClass([
  Validate(BOOLEAN)
], SeriesTooltipInteraction.prototype, "enabled", 2);
var SeriesTooltip = class extends BaseProperties {
  constructor() {
    super(...arguments);
    this.enabled = true;
    this.interaction = new SeriesTooltipInteraction();
    this.position = new TooltipPosition();
  }
  toTooltipHtml(defaults, params) {
    if (this.renderer) {
      return toTooltipHtml$1(this.renderer(params), defaults);
    }
    return toTooltipHtml$1(defaults);
  }
};
__decorateClass([
  Validate(BOOLEAN)
], SeriesTooltip.prototype, "enabled", 2);
__decorateClass([
  Validate(BOOLEAN, { optional: true })
], SeriesTooltip.prototype, "showArrow", 2);
__decorateClass([
  Validate(FUNCTION, { optional: true })
], SeriesTooltip.prototype, "renderer", 2);
__decorateClass([
  Validate(OBJECT)
], SeriesTooltip.prototype, "interaction", 2);
__decorateClass([
  Validate(OBJECT)
], SeriesTooltip.prototype, "position", 2);

// packages/ag-charts-community/src/chart/series/cartesian/areaSeriesProperties.ts
var AreaSeriesProperties = class extends CartesianSeriesProperties {
  constructor() {
    super(...arguments);
    this.xName = void 0;
    this.fill = "#c16068";
    this.fillOpacity = 1;
    this.stroke = "#874349";
    this.strokeWidth = 2;
    this.strokeOpacity = 1;
    this.lineDash = [0];
    this.lineDashOffset = 0;
    this.shadow = new DropShadow();
    this.marker = new SeriesMarker();
    this.label = new Label$1();
    this.tooltip = new SeriesTooltip();
    this.connectMissingData = false;
  }
};
__decorateClass([
  Validate(STRING)
], AreaSeriesProperties.prototype, "xKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], AreaSeriesProperties.prototype, "xName", 2);
__decorateClass([
  Validate(STRING)
], AreaSeriesProperties.prototype, "yKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], AreaSeriesProperties.prototype, "yName", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], AreaSeriesProperties.prototype, "normalizedTo", 2);
__decorateClass([
  Validate(COLOR_STRING)
], AreaSeriesProperties.prototype, "fill", 2);
__decorateClass([
  Validate(RATIO)
], AreaSeriesProperties.prototype, "fillOpacity", 2);
__decorateClass([
  Validate(COLOR_STRING)
], AreaSeriesProperties.prototype, "stroke", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], AreaSeriesProperties.prototype, "strokeWidth", 2);
__decorateClass([
  Validate(RATIO)
], AreaSeriesProperties.prototype, "strokeOpacity", 2);
__decorateClass([
  Validate(LINE_DASH)
], AreaSeriesProperties.prototype, "lineDash", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], AreaSeriesProperties.prototype, "lineDashOffset", 2);
__decorateClass([
  Validate(OBJECT)
], AreaSeriesProperties.prototype, "shadow", 2);
__decorateClass([
  Validate(OBJECT)
], AreaSeriesProperties.prototype, "marker", 2);
__decorateClass([
  Validate(OBJECT)
], AreaSeriesProperties.prototype, "label", 2);
__decorateClass([
  Validate(OBJECT)
], AreaSeriesProperties.prototype, "tooltip", 2);
__decorateClass([
  Validate(BOOLEAN)
], AreaSeriesProperties.prototype, "connectMissingData", 2);

// packages/ag-charts-community/src/chart/series/cartesian/markerUtil.ts
function markerFadeInAnimation({ id }, animationManager, markerSelections, status = "unknown") {
  const params = __spreadValues({}, FROM_TO_MIXINS[status]);
  staticFromToMotion(id, "markers", animationManager, markerSelections, { opacity: 0 }, { opacity: 1 }, params);
  markerSelections.forEach((s) => s.cleanup());
}
function markerScaleInAnimation({ id }, animationManager, markerSelections) {
  staticFromToMotion(
    id,
    "markers",
    animationManager,
    markerSelections,
    { scalingX: 0, scalingY: 0 },
    { scalingX: 1, scalingY: 1 }
  );
  markerSelections.forEach((s) => s.cleanup());
}
function markerSwipeScaleInAnimation({ id, nodeDataDependencies }, animationManager, markerSelections) {
  const seriesWidth = nodeDataDependencies.seriesRectWidth;
  const fromFn = (_, datum) => {
    var _a, _b;
    const x = (_b = (_a = datum.midPoint) == null ? void 0 : _a.x) != null ? _b : seriesWidth;
    const delay = clamp2(0, inverseEaseOut(x / seriesWidth), 1);
    const animationDuration = Debug.check("animationImmediateMarkerSwipeScaleIn") ? 0 : QUICK_TRANSITION;
    return { scalingX: 0, scalingY: 0, animationDelay: delay, animationDuration };
  };
  const toFn = () => {
    return { scalingX: 1, scalingY: 1 };
  };
  fromToMotion(id, "markers", animationManager, markerSelections, { fromFn, toFn });
}
function resetMarkerFn(_node) {
  return { opacity: 1, scalingX: 1, scalingY: 1 };
}
function resetMarkerPositionFn(_node, datum) {
  var _a, _b, _c, _d;
  return {
    translationX: (_b = (_a = datum.point) == null ? void 0 : _a.x) != null ? _b : NaN,
    translationY: (_d = (_c = datum.point) == null ? void 0 : _c.y) != null ? _d : NaN
  };
}
function prepareMarkerAnimation(pairMap, parentStatus) {
  const readFirstPair = (xValue, type) => {
    const val = pairMap[type][xValue];
    return Array.isArray(val) ? val[0] : val;
  };
  const markerStatus = (datum) => {
    const { xValue } = datum;
    if (pairMap.moved[xValue]) {
      return { point: readFirstPair(xValue, "moved"), status: "updated" };
    } else if (pairMap.removed[xValue]) {
      return { point: readFirstPair(xValue, "removed"), status: "removed" };
    } else if (pairMap.added[xValue]) {
      return { point: readFirstPair(xValue, "added"), status: "added" };
    }
    return { status: "unknown" };
  };
  const fromFn = (marker, datum) => {
    var _a, _b, _c, _d, _e, _f;
    const { status, point } = markerStatus(datum);
    if (status === "unknown")
      return { opacity: 0 };
    const defaults = __spreadValues({
      translationX: (_b = (_a = point == null ? void 0 : point.from) == null ? void 0 : _a.x) != null ? _b : marker.translationX,
      translationY: (_d = (_c = point == null ? void 0 : point.from) == null ? void 0 : _c.y) != null ? _d : marker.translationY,
      opacity: marker.opacity
    }, FROM_TO_MIXINS[status]);
    if (parentStatus === "added") {
      return __spreadValues(__spreadProps(__spreadValues({}, defaults), {
        opacity: 0,
        translationX: (_e = point == null ? void 0 : point.to) == null ? void 0 : _e.x,
        translationY: (_f = point == null ? void 0 : point.to) == null ? void 0 : _f.y
      }), FROM_TO_MIXINS["added"]);
    }
    if (status === "added") {
      defaults.opacity = 0;
    }
    return defaults;
  };
  const toFn = (_marker, datum) => {
    var _a, _b;
    const { status, point } = markerStatus(datum);
    if (status === "unknown")
      return { opacity: 0 };
    const defaults = __spreadValues({
      translationX: datum.point.x,
      translationY: datum.point.y,
      opacity: 1
    }, FROM_TO_MIXINS[status]);
    if (status === "removed" || parentStatus === "removed") {
      return __spreadValues(__spreadProps(__spreadValues({}, defaults), {
        translationX: (_a = point == null ? void 0 : point.to) == null ? void 0 : _a.x,
        translationY: (_b = point == null ? void 0 : point.to) == null ? void 0 : _b.y,
        opacity: 0
      }), FROM_TO_MIXINS["removed"]);
    }
    return defaults;
  };
  return { fromFn, toFn };
}

// packages/ag-charts-community/src/chart/series/cartesian/pathUtil.ts
function minMax(nodeData) {
  return nodeData.reduce(
    ({ min, max }, node) => {
      if (min == null || min.point.x > node.point.x) {
        min = node;
      }
      if (max == null || max.point.x < node.point.x) {
        max = node;
      }
      return { min, max };
    },
    {}
  );
}
function intersectionOnLine(a, b, targetX) {
  const m = (b.y - a.y) / (b.x - a.x);
  const y = (targetX - a.x) * m + a.y;
  return { x: targetX, y };
}
function backfillPathPoint(results, process, skip, processFn) {
  let prevMarkerIdx = -1, nextMarkerIdx = 0;
  const toProcess = [];
  while (nextMarkerIdx < results.length) {
    if (results[nextMarkerIdx].change === process) {
      toProcess.push(results[nextMarkerIdx]);
      nextMarkerIdx++;
      continue;
    }
    if (results[nextMarkerIdx].change === skip) {
      nextMarkerIdx++;
      continue;
    }
    if (toProcess.length > 0) {
      processFn(toProcess, prevMarkerIdx, nextMarkerIdx);
      toProcess.length = 0;
    }
    prevMarkerIdx = nextMarkerIdx;
    nextMarkerIdx++;
  }
  if (toProcess.length > 0) {
    processFn(toProcess, prevMarkerIdx, nextMarkerIdx);
  }
}
function backfillPathPointData(result, splitMode) {
  backfillPathPoint(result, "out", "in", (toProcess, sIdx, eIdx) => {
    var _a, _b;
    if (sIdx === -1 && result[eIdx]) {
      toProcess.forEach((d) => d.to = result[eIdx].from);
    } else if (eIdx === result.length && result[sIdx]) {
      toProcess.forEach((d) => d.to = result[sIdx].from);
    } else if (splitMode === "intersect" && ((_a = result[sIdx]) == null ? void 0 : _a.from) && ((_b = result[eIdx]) == null ? void 0 : _b.from)) {
      toProcess.forEach((d) => d.to = intersectionOnLine(result[sIdx].from, result[eIdx].from, d.from.x));
    } else {
      toProcess.forEach((d) => d.to = d.from);
    }
  });
  backfillPathPoint(result, "in", "out", (toProcess, sIdx, eIdx) => {
    var _a, _b;
    if (sIdx === -1 && result[eIdx]) {
      toProcess.forEach((d) => d.from = result[eIdx].to);
    } else if (eIdx === result.length && result[sIdx]) {
      toProcess.forEach((d) => d.from = result[sIdx].to);
    } else if (splitMode === "intersect" && ((_a = result[sIdx]) == null ? void 0 : _a.to) && ((_b = result[eIdx]) == null ? void 0 : _b.to)) {
      toProcess.forEach((d) => d.from = intersectionOnLine(result[sIdx].to, result[eIdx].to, d.to.x));
    } else {
      toProcess.forEach((d) => d.from = d.to);
    }
  });
}
function calculatePoint(from, to, ratio) {
  const x1 = isNaN(from.x) ? to.x : from.x;
  const y1 = isNaN(from.y) ? to.y : from.y;
  const xd = to.x - from.x;
  const yd = to.y - from.y;
  const xr = isNaN(xd) ? 0 : xd * ratio;
  const yr = isNaN(yd) ? 0 : yd * ratio;
  return {
    x: x1 + xr,
    y: y1 + yr
  };
}
function renderPartialPath(pairData, ratios, path) {
  const { path: linePath } = path;
  let previousTo;
  for (const data of pairData) {
    const ratio = ratios[data.change];
    if (ratio == null)
      continue;
    const { from, to } = data;
    if (from == null || to == null)
      continue;
    const { x, y } = calculatePoint(from, to, ratio);
    if (data.moveTo === false) {
      linePath.lineTo(x, y);
    } else if (data.moveTo === true || !previousTo) {
      linePath.moveTo(x, y);
    } else if (previousTo) {
      const moveToRatio = data.moveTo === "in" ? ratio : 1 - ratio;
      const { x: midPointX, y: midPointY } = calculatePoint(previousTo, { x, y }, moveToRatio);
      linePath.lineTo(midPointX, midPointY);
      linePath.moveTo(x, y);
    }
    previousTo = { x, y };
  }
}
function pathSwipeInAnimation({ id, visible }, animationManager, paths) {
  staticFromToMotion(
    id,
    "path_properties",
    animationManager,
    paths,
    { clipScalingX: 0 },
    { clipScalingX: 1 },
    {
      start: { clipMode: "normal", visible },
      finish: { clipMode: void 0, visible }
    }
  );
}
function pathFadeInAnimation({ id }, subId, animationManager, selection) {
  staticFromToMotion(id, subId, animationManager, selection, { opacity: 0 }, { opacity: 1 }, LABEL_PHASE);
}
function pathFadeOutAnimation({ id }, subId, animationManager, selection) {
  staticFromToMotion(id, subId, animationManager, selection, { opacity: 1 }, { opacity: 0 }, LABEL_PHASE);
}
function buildResetPathFn(opts) {
  return (_node) => {
    return { opacity: opts.getOpacity(), clipScalingX: 1, clipMode: void 0 };
  };
}
function updateClipPath({ nodeDataDependencies }, path) {
  var _a, _b;
  const { seriesRectHeight: height, seriesRectWidth: width } = nodeDataDependencies;
  if (path.clipPath == null) {
    path.clipPath = new Path2D();
    path.clipScalingX = 1;
    path.clipScalingY = 1;
  }
  (_a = path.clipPath) == null ? void 0 : _a.clear({ trackChanges: true });
  (_b = path.clipPath) == null ? void 0 : _b.rect(-25, -25, toReal(width) + 50, toReal(height) + 50);
}

// packages/ag-charts-community/src/chart/series/cartesian/scaling.ts
function isContinuousScaling(scaling) {
  return scaling.type === "continuous" || scaling.type === "log";
}
function isCategoryScaling(scaling) {
  return scaling.type === "category";
}
function areEqual(a, b) {
  return a.domain.length === b.domain.length && a.range.length === b.range.length && a.domain.every((val, index) => val === b.domain[index]) && a.range.every((val, index) => val === b.range[index]);
}
function areScalingEqual(a, b) {
  if (a === void 0 || b === void 0) {
    return a !== void 0 || b !== void 0;
  }
  if (isContinuousScaling(a) && isContinuousScaling(b)) {
    return a.type === b.type && areEqual(a, b);
  }
  if (isCategoryScaling(a) && isCategoryScaling(b)) {
    return areEqual(a, b);
  }
  return false;
}

// packages/ag-charts-community/src/chart/series/cartesian/lineUtil.ts
function scale(val, scaling) {
  if (!scaling)
    return NaN;
  if (val instanceof Date) {
    val = val.getTime();
  }
  if (scaling.type === "continuous" && typeof val === "number") {
    const domainRatio = (val - scaling.domain[0]) / (scaling.domain[1] - scaling.domain[0]);
    return domainRatio * (scaling.range[1] - scaling.range[0]) + scaling.range[0];
  }
  if (scaling.type === "log" && typeof val === "number") {
    return scaling.convert(val);
  }
  const matchingIndex = scaling.domain.findIndex((d) => d === val);
  if (matchingIndex >= 0) {
    return scaling.range[matchingIndex];
  }
  return NaN;
}
function scalesChanged(newData, oldData) {
  return !areScalingEqual(newData.scales.x, oldData.scales.x) || !areScalingEqual(newData.scales.y, oldData.scales.y);
}
function closeMatch(a, b) {
  const an = Number(a);
  const bn = Number(b);
  if (!isNaN(an) && !isNaN(bn)) {
    return Math.abs(bn - an) < 0.25;
  }
  return a === b;
}
function calculateMoveTo(from = false, to = false) {
  if (from === to) {
    return !!from;
  }
  return from ? "in" : "out";
}
function pairContinuousData(newData, oldData, opts = {}) {
  var _a, _b, _c, _d;
  const { backfillSplitMode = "intersect" } = opts;
  const toNewScale = (oldDatum) => {
    var _a2, _b2;
    return {
      x: scale((_a2 = oldDatum.xValue) != null ? _a2 : NaN, newData.scales.x),
      y: scale((_b2 = oldDatum.yValue) != null ? _b2 : NaN, newData.scales.y)
    };
  };
  const toOldScale = (newDatum) => {
    var _a2, _b2;
    return {
      x: scale((_a2 = newDatum.xValue) != null ? _a2 : NaN, oldData.scales.x),
      y: scale((_b2 = newDatum.yValue) != null ? _b2 : NaN, oldData.scales.y)
    };
  };
  const result = [];
  const resultMap = {
    added: {},
    moved: {},
    removed: {}
  };
  const pairUp = (from, to, xValue, change = "move") => {
    if (from && (isNaN(from.point.x) || isNaN(from.point.y))) {
      from = to;
    }
    const resultPoint = {
      from: from == null ? void 0 : from.point,
      to: to == null ? void 0 : to.point,
      moveTo: calculateMoveTo(from == null ? void 0 : from.point.moveTo, to == null ? void 0 : to.point.moveTo),
      change
    };
    if (change === "move") {
      resultMap.moved[xValue] = resultPoint;
      oldIdx++;
      newIdx++;
    } else if (change === "in") {
      resultMap.added[xValue] = resultPoint;
      newIdx++;
    } else if (change === "out") {
      resultMap.removed[xValue] = resultPoint;
      oldIdx++;
    }
    result.push(resultPoint);
  };
  const { min: minFromNode, max: maxFromNode } = minMax(oldData.nodeData);
  const { min: minToNode, max: maxToNode } = minMax(newData.nodeData);
  let oldIdx = 0;
  let newIdx = 0;
  while (oldIdx < oldData.nodeData.length || newIdx < newData.nodeData.length) {
    const from = oldData.nodeData[oldIdx];
    const to = newData.nodeData[newIdx];
    const fromShifted = from ? toNewScale(from) : void 0;
    const toUnshifted = to ? toOldScale(to) : void 0;
    const NA = void 0;
    if (fromShifted && closeMatch(fromShifted.x, to == null ? void 0 : to.point.x)) {
      pairUp(from, to, to.xValue, "move");
    } else if (fromShifted && fromShifted.x < ((_a = minToNode == null ? void 0 : minToNode.point.x) != null ? _a : -Infinity)) {
      pairUp(from, NA, from.xValue, "out");
    } else if (fromShifted && fromShifted.x > ((_b = maxToNode == null ? void 0 : maxToNode.point.x) != null ? _b : Infinity)) {
      pairUp(from, NA, from.xValue, "out");
    } else if (toUnshifted && toUnshifted.x < ((_c = minFromNode == null ? void 0 : minFromNode.point.x) != null ? _c : -Infinity)) {
      pairUp(NA, to, to.xValue, "in");
    } else if (toUnshifted && toUnshifted.x > ((_d = maxFromNode == null ? void 0 : maxFromNode.point.x) != null ? _d : Infinity)) {
      pairUp(NA, to, to.xValue, "in");
    } else if (fromShifted && fromShifted.x < (to == null ? void 0 : to.point.x)) {
      pairUp(from, NA, from.xValue, "out");
    } else if (toUnshifted && toUnshifted.x < (from == null ? void 0 : from.point.x)) {
      pairUp(NA, to, to.xValue, "in");
    } else if (from) {
      pairUp(from, NA, from.xValue, "out");
    } else if (to) {
      pairUp(NA, to, to.xValue, "in");
    } else {
      throw new Error("Unable to process points");
    }
  }
  backfillPathPointData(result, backfillSplitMode);
  return { result, resultMap };
}
function pairCategoryData(newData, oldData, diff2, opts = {}) {
  var _a, _b, _c;
  const { backfillSplitMode = "intersect", multiDatum = false } = opts;
  const result = [];
  const resultMapSingle = {
    added: {},
    moved: {},
    removed: {}
  };
  const resultMapMulti = {
    added: {},
    moved: {},
    removed: {}
  };
  let previousResultPoint = void 0;
  let previousXValue = void 0;
  const addToResultMap = (xValue, result2) => {
    var _a2, _b2;
    const type = result2.change === "move" ? "moved" : result2.change === "in" ? "added" : "removed";
    if (multiDatum) {
      (_b2 = (_a2 = resultMapMulti[type])[xValue]) != null ? _b2 : _a2[xValue] = [];
      resultMapMulti[type][xValue].push(result2);
    } else {
      resultMapSingle[type][xValue] = result2;
    }
    previousResultPoint = result2;
    previousXValue = xValue;
  };
  let oldIndex = 0;
  let newIndex = 0;
  let isXUnordered = false;
  while (oldIndex < oldData.nodeData.length || newIndex < newData.nodeData.length) {
    const before = oldData.nodeData[oldIndex];
    const after = newData.nodeData[newIndex];
    let resultPoint;
    if ((before == null ? void 0 : before.xValue) === (after == null ? void 0 : after.xValue)) {
      resultPoint = {
        change: "move",
        moveTo: calculateMoveTo((_a = before.point.moveTo) != null ? _a : false, after.point.moveTo),
        from: before.point,
        to: after.point
      };
      addToResultMap(before.xValue, resultPoint);
      oldIndex++;
      newIndex++;
    } else if (diff2 !== void 0 && diff2.removed.indexOf(before == null ? void 0 : before.xValue) >= 0) {
      resultPoint = {
        change: "out",
        moveTo: (_b = before.point.moveTo) != null ? _b : false,
        from: before.point
      };
      addToResultMap(before.xValue, resultPoint);
      oldIndex++;
    } else if (diff2 !== void 0 && diff2.added.indexOf(after == null ? void 0 : after.xValue) >= 0) {
      resultPoint = {
        change: "in",
        moveTo: (_c = after.point.moveTo) != null ? _c : false,
        to: after.point
      };
      addToResultMap(after.xValue, resultPoint);
      newIndex++;
    } else if (multiDatum && previousResultPoint && previousXValue === (before == null ? void 0 : before.xValue)) {
      resultPoint = __spreadValues({}, previousResultPoint);
      addToResultMap(before.xValue, resultPoint);
      oldIndex++;
    } else if (multiDatum && previousResultPoint && previousXValue === (after == null ? void 0 : after.xValue)) {
      resultPoint = __spreadValues({}, previousResultPoint);
      addToResultMap(after.xValue, resultPoint);
      newIndex++;
    } else {
      isXUnordered = true;
      break;
    }
    result.push(resultPoint);
  }
  let previousX = -Infinity;
  isXUnordered || (isXUnordered = result.some((pathPoint) => {
    const { change: marker, to: { x = -Infinity } = {} } = pathPoint;
    if (marker === "out")
      return;
    const result2 = x < previousX;
    previousX = x;
    return result2;
  }));
  if (isXUnordered) {
    return { result: void 0, resultMap: void 0 };
  }
  backfillPathPointData(result, backfillSplitMode);
  if (multiDatum) {
    return { result, resultMap: resultMapMulti };
  }
  return { result, resultMap: resultMapSingle };
}
function determinePathStatus(newData, oldData) {
  let status = "updated";
  const visible = (data) => {
    return data.visible;
  };
  if (!visible(oldData) && visible(newData)) {
    status = "added";
  } else if (visible(oldData) && !visible(newData)) {
    status = "removed";
  }
  return status;
}
function prepareLinePathPropertyAnimation(status, visibleToggleMode) {
  const phase = visibleToggleMode === "none" ? "updated" : status;
  const result = {
    fromFn: (_path) => {
      let mixin;
      if (status === "removed") {
        mixin = { finish: { visible: false } };
      } else if (status === "added") {
        mixin = { start: { visible: true } };
      } else {
        mixin = {};
      }
      return __spreadValues(__spreadValues({}, FROM_TO_MIXINS[phase]), mixin);
    },
    toFn: (_path) => {
      return __spreadValues({}, FROM_TO_MIXINS[phase]);
    }
  };
  if (visibleToggleMode === "fade") {
    return {
      fromFn: (path) => {
        const opacity = status === "added" ? 0 : path.opacity;
        return __spreadValues({ opacity }, result.fromFn(path));
      },
      toFn: (path) => {
        const opacity = status === "removed" ? 0 : 1;
        return __spreadValues({ opacity }, result.toFn(path));
      }
    };
  }
  return result;
}
function prepareLinePathAnimationFns(newData, oldData, pairData, visibleToggleMode, render) {
  const status = determinePathStatus(newData, oldData);
  const removePhaseFn = (ratio, path) => {
    render(pairData, { move: 0, out: ratio }, path);
  };
  const updatePhaseFn = (ratio, path) => {
    render(pairData, { move: ratio }, path);
  };
  const addPhaseFn = (ratio, path) => {
    render(pairData, { move: 1, in: ratio }, path);
  };
  const pathProperties = prepareLinePathPropertyAnimation(status, visibleToggleMode);
  return { status, path: { addPhaseFn, updatePhaseFn, removePhaseFn }, pathProperties };
}
function prepareLinePathAnimation(newData, oldData, diff2) {
  var _a, _b;
  const isCategoryBased = ((_a = newData.scales.x) == null ? void 0 : _a.type) === "category";
  const { result: pairData, resultMap: pairMap } = isCategoryBased ? pairCategoryData(newData, oldData, diff2) : pairContinuousData(newData, oldData);
  let status = "updated";
  if (oldData.visible && !newData.visible) {
    status = "removed";
  } else if (!oldData.visible && newData.visible) {
    status = "added";
  }
  if (pairData === void 0 || pairMap === void 0) {
    return;
  }
  const hasMotion = ((_b = diff2 == null ? void 0 : diff2.changed) != null ? _b : true) || scalesChanged(newData, oldData);
  const pathFns = prepareLinePathAnimationFns(newData, oldData, pairData, "fade", renderPartialPath);
  const marker = prepareMarkerAnimation(pairMap, status);
  return __spreadProps(__spreadValues({}, pathFns), { marker, hasMotion });
}

// packages/ag-charts-community/src/chart/series/cartesian/areaUtil.ts
var AreaSeriesTag = /* @__PURE__ */ ((AreaSeriesTag2) => {
  AreaSeriesTag2[AreaSeriesTag2["Fill"] = 0] = "Fill";
  AreaSeriesTag2[AreaSeriesTag2["Stroke"] = 1] = "Stroke";
  AreaSeriesTag2[AreaSeriesTag2["Marker"] = 2] = "Marker";
  AreaSeriesTag2[AreaSeriesTag2["Label"] = 3] = "Label";
  return AreaSeriesTag2;
})(AreaSeriesTag || {});
function splitFillPoints(context) {
  const { points } = context.fillData;
  return { top: points.slice(0, points.length / 2), bottom: points.slice(points.length / 2).reverse() };
}
function prepPoints(key, ctx, points) {
  return {
    scales: ctx.scales,
    nodeData: points[key],
    visible: ctx.visible
  };
}
function pairFillCategoryData(newData, oldData, diff2) {
  const oldPoints = splitFillPoints(oldData);
  const newPoints = splitFillPoints(newData);
  const pairOpts = { multiDatum: true };
  return {
    top: pairCategoryData(
      prepPoints("top", newData, newPoints),
      prepPoints("top", oldData, oldPoints),
      diff2,
      pairOpts
    ),
    bottom: pairCategoryData(
      prepPoints("bottom", newData, newPoints),
      prepPoints("bottom", oldData, oldPoints),
      diff2,
      pairOpts
    )
  };
}
function pairFillContinuousData(newData, oldData) {
  const oldPoints = splitFillPoints(oldData);
  const newPoints = splitFillPoints(newData);
  return {
    top: pairContinuousData(prepPoints("top", newData, newPoints), prepPoints("top", oldData, oldPoints)),
    bottom: pairContinuousData(prepPoints("bottom", newData, newPoints), prepPoints("bottom", oldData, oldPoints))
  };
}
function prepareAreaPathAnimation(newData, oldData, diff2) {
  var _a;
  const isCategoryBased = ((_a = newData.scales.x) == null ? void 0 : _a.type) === "category";
  let status = "updated";
  if (oldData.visible && !newData.visible) {
    status = "removed";
  } else if (!oldData.visible && newData.visible) {
    status = "added";
  }
  const prepareMarkerPairs = () => {
    if (isCategoryBased) {
      return pairCategoryData(newData, oldData, diff2, { backfillSplitMode: "static", multiDatum: true });
    }
    return pairContinuousData(newData, oldData, { backfillSplitMode: "static" });
  };
  const prepareFillPairs = () => {
    if (isCategoryBased) {
      return pairFillCategoryData(newData, oldData, diff2);
    }
    return pairFillContinuousData(newData, oldData);
  };
  const { resultMap: markerPairMap } = prepareMarkerPairs();
  const { top, bottom } = prepareFillPairs();
  if (markerPairMap === void 0 || top.result === void 0 || bottom.result === void 0) {
    return;
  }
  const pairData = [...top.result, ...bottom.result.reverse()];
  const fill = prepareLinePathAnimationFns(newData, oldData, pairData, "none", renderPartialPath);
  const marker = prepareMarkerAnimation(markerPairMap, status);
  return { fill, marker };
}

// packages/ag-charts-community/src/chart/series/cartesian/areaSeries.ts
var _AreaSeries = class _AreaSeries extends CartesianSeries {
  constructor(moduleCtx) {
    super({
      moduleCtx,
      pathsPerSeries: 2,
      pathsZIndexSubOrderOffset: [0, 1e3],
      hasMarkers: true,
      markerSelectionGarbageCollection: false,
      pickModes: [1 /* NEAREST_BY_MAIN_AXIS_FIRST */, 0 /* EXACT_SHAPE_MATCH */],
      animationResetFns: {
        path: buildResetPathFn({ getOpacity: () => this.getOpacity() }),
        label: resetLabelFn,
        marker: (node, datum) => __spreadValues(__spreadValues({}, resetMarkerFn()), resetMarkerPositionFn(node, datum))
      }
    });
    this.properties = new AreaSeriesProperties();
  }
  processData(dataController) {
    return __async(this, null, function* () {
      if (this.data == null || !this.properties.isValid()) {
        return;
      }
      const { data, visible, seriesGrouping: { groupIndex = this.id, stackCount = 1 } = {} } = this;
      const { xKey, yKey, connectMissingData, normalizedTo } = this.properties;
      const animationEnabled = !this.ctx.animationManager.isSkipped();
      const { isContinuousX, isContinuousY } = this.isContinuous();
      const ids = [
        `area-stack-${groupIndex}-yValues`,
        `area-stack-${groupIndex}-yValues-trailing`,
        `area-stack-${groupIndex}-yValues-prev`,
        `area-stack-${groupIndex}-yValues-trailing-prev`,
        `area-stack-${groupIndex}-yValues-marker`
      ];
      const extraProps = [];
      if (isDefined(normalizedTo)) {
        extraProps.push(normaliseGroupTo(this, [ids[0], ids[1], ids[4]], normalizedTo, "range"));
        extraProps.push(normaliseGroupTo(this, [ids[2], ids[3]], normalizedTo, "range"));
      }
      if (!isContinuousX && animationEnabled && this.processedData) {
        extraProps.push(diff(this.processedData));
      }
      if (animationEnabled) {
        extraProps.push(animationValidation(this));
      }
      const common = { invalidValue: null };
      if (connectMissingData && stackCount > 1) {
        common.invalidValue = 0;
      }
      if (!visible) {
        common.forceValue = 0;
      }
      yield this.requestDataModel(dataController, data, {
        props: [
          keyProperty(this, xKey, isContinuousX, { id: "xValue" }),
          valueProperty(this, yKey, isContinuousY, __spreadValues({ id: `yValueRaw` }, common)),
          ...groupAccumulativeValueProperty(this, yKey, isContinuousY, "window", "current", __spreadProps(__spreadValues({
            id: `yValueEnd`
          }, common), {
            groupId: ids[0]
          })),
          ...groupAccumulativeValueProperty(this, yKey, isContinuousY, "window-trailing", "current", __spreadProps(__spreadValues({
            id: `yValueStart`
          }, common), {
            groupId: ids[1]
          })),
          ...groupAccumulativeValueProperty(this, yKey, isContinuousY, "window", "last", __spreadProps(__spreadValues({
            id: `yValuePreviousEnd`
          }, common), {
            groupId: ids[2]
          })),
          ...groupAccumulativeValueProperty(this, yKey, isContinuousY, "window-trailing", "last", __spreadProps(__spreadValues({
            id: `yValuePreviousStart`
          }, common), {
            groupId: ids[3]
          })),
          ...groupAccumulativeValueProperty(this, yKey, isContinuousY, "normal", "current", __spreadProps(__spreadValues({
            id: `yValueCumulative`
          }, common), {
            groupId: ids[4]
          })),
          ...extraProps
        ],
        groupByKeys: true,
        groupByData: false
      });
      this.animationState.transition("updateData");
    });
  }
  getSeriesDomain(direction) {
    const { processedData, dataModel, axes } = this;
    if (!processedData || !dataModel || processedData.data.length === 0)
      return [];
    const xAxis = axes["x" /* X */];
    const yAxis = axes["y" /* Y */];
    const keyDef = dataModel.resolveProcessedDataDefById(this, `xValue`);
    const keys = dataModel.getDomain(this, `xValue`, "key", processedData);
    const yExtent = dataModel.getDomain(this, `yValueEnd`, "value", processedData);
    if (direction === "x" /* X */) {
      if ((keyDef == null ? void 0 : keyDef.def.type) === "key" && keyDef.def.valueType === "category") {
        return keys;
      }
      return fixNumericExtent(extent$4(keys), xAxis);
    } else if (yAxis instanceof LogAxis || yAxis instanceof TimeAxis) {
      return fixNumericExtent(yExtent, yAxis);
    } else {
      const fixedYExtent = [yExtent[0] > 0 ? 0 : yExtent[0], yExtent[1] < 0 ? 0 : yExtent[1]];
      return fixNumericExtent(fixedYExtent, yAxis);
    }
  }
  createNodeData() {
    return __async(this, null, function* () {
      var _a;
      const { axes, data, processedData: { data: groupedData } = {}, dataModel } = this;
      const xAxis = axes["x" /* X */];
      const yAxis = axes["y" /* Y */];
      if (!xAxis || !yAxis || !data || !dataModel || !this.properties.isValid()) {
        return [];
      }
      const {
        yKey,
        xKey,
        marker,
        label,
        fill: seriesFill,
        stroke: seriesStroke,
        connectMissingData
      } = this.properties;
      const { scale: xScale } = xAxis;
      const { scale: yScale } = yAxis;
      const continuousY = ContinuousScale.is(yScale);
      const xOffset = ((_a = xScale.bandwidth) != null ? _a : 0) / 2;
      const defs = dataModel.resolveProcessedDataDefsByIds(this, [
        `yValueStart`,
        `yValueEnd`,
        `yValueRaw`,
        `yValuePreviousStart`,
        `yValuePreviousEnd`,
        `yValueCumulative`
      ]);
      const createMovePoint = (plainPoint) => {
        const _a2 = plainPoint, { point } = _a2, stroke = __objRest(_a2, ["point"]);
        return __spreadProps(__spreadValues({}, stroke), { point: __spreadProps(__spreadValues({}, point), { moveTo: true }) });
      };
      const createPathCoordinates = (xValue, lastYEnd, yEnd) => {
        const x = xScale.convert(xValue) + xOffset;
        const prevYCoordinate = yScale.convert(lastYEnd);
        const currYCoordinate = yScale.convert(yEnd);
        return [
          { point: { x, y: currYCoordinate }, yValue: yEnd, xValue },
          { point: { x, y: prevYCoordinate }, yValue: lastYEnd, xValue }
        ];
      };
      const createMarkerCoordinate = (xDatum, yEnd, rawYDatum) => {
        let currY;
        if (isDefined(this.properties.normalizedTo) ? continuousY && isContinuous(rawYDatum) : !isNaN(rawYDatum)) {
          currY = yEnd;
        }
        return {
          x: xScale.convert(xDatum) + xOffset,
          y: yScale.convert(currY),
          size: marker.size
        };
      };
      const itemId = yKey;
      const labelData = [];
      const markerData = [];
      const context = {
        itemId,
        fillData: { itemId, points: [] },
        strokeData: { itemId, points: [] },
        labelData,
        nodeData: markerData,
        scales: __superGet(_AreaSeries.prototype, this, "calculateScaling").call(this),
        visible: this.visible
      };
      const fillPoints = context.fillData.points;
      const fillPhantomPoints = [];
      const strokePoints = context.strokeData.points;
      let datumIdx = -1;
      let lastXDatum;
      let lastYDatum = -Infinity;
      groupedData == null ? void 0 : groupedData.forEach((datumGroup) => {
        const {
          keys,
          keys: [xDatum],
          datum: datumArray,
          values: valuesArray
        } = datumGroup;
        valuesArray.forEach((values, valueIdx) => {
          var _a2, _b, _c;
          datumIdx++;
          const seriesDatum = datumArray[valueIdx];
          const dataValues = dataModel.resolveProcessedDataDefsValues(defs, { keys, values });
          const { yValueRaw: yDatum, yValueCumulative } = dataValues;
          let { yValueStart, yValueEnd, yValuePreviousStart, yValuePreviousEnd } = dataValues;
          const validPoint = yDatum != null;
          const point = createMarkerCoordinate(xDatum, +yValueCumulative, yDatum);
          if (validPoint && marker) {
            markerData.push({
              index: datumIdx,
              series: this,
              itemId,
              datum: seriesDatum,
              midPoint: { x: point.x, y: point.y },
              cumulativeValue: yValueEnd,
              yValue: yDatum,
              xValue: xDatum,
              yKey,
              xKey,
              point,
              fill: (_a2 = marker.fill) != null ? _a2 : seriesFill,
              stroke: (_b = marker.stroke) != null ? _b : seriesStroke,
              strokeWidth: (_c = marker.strokeWidth) != null ? _c : this.getStrokeWidth(this.properties.strokeWidth)
            });
          }
          if (validPoint && label) {
            const labelText = this.getLabelText(
              label,
              {
                value: yDatum,
                datum: seriesDatum,
                xKey,
                yKey,
                xName: this.properties.xName,
                yName: this.properties.yName
              },
              (value) => isNumber2(value) ? value.toFixed(2) : String(value)
            );
            labelData.push({
              index: datumIdx,
              series: this,
              itemId: yKey,
              datum: seriesDatum,
              x: point.x,
              y: point.y,
              label: labelText ? {
                text: labelText,
                fontStyle: label.fontStyle,
                fontWeight: label.fontWeight,
                fontSize: label.fontSize,
                fontFamily: label.fontFamily,
                textAlign: "center",
                textBaseline: "bottom",
                fill: label.color
              } : void 0
            });
          }
          const xValid = lastXDatum != null && xDatum != null;
          const yValid = lastYDatum != null && validPoint;
          if (!yValid) {
            yValueStart = yValueStart != null ? yValueStart : 0;
            yValueEnd = yValueStart != null ? yValueStart : 0;
            yValuePreviousStart = yValuePreviousStart != null ? yValuePreviousStart : 0;
            yValuePreviousEnd = yValuePreviousStart != null ? yValuePreviousStart : 0;
          }
          const [prevTop, prevBottom] = createPathCoordinates(lastXDatum, yValuePreviousStart, yValuePreviousEnd);
          const [top, bottom] = createPathCoordinates(xDatum, yValueStart, yValueEnd);
          if (xValid && (!connectMissingData || yValid)) {
            fillPoints.push(prevTop);
            fillPhantomPoints.push(prevBottom);
            fillPoints.push(top);
            fillPhantomPoints.push(bottom);
          }
          if (yValid && datumIdx > 0) {
            strokePoints.push(createMovePoint(prevTop));
            strokePoints.push(top);
          }
          lastXDatum = xDatum;
          lastYDatum = yDatum;
        });
      });
      if (strokePoints.length > 0) {
        strokePoints[0] = createMovePoint(strokePoints[0]);
      }
      fillPhantomPoints.reverse();
      fillPoints.push(...fillPhantomPoints);
      return [context];
    });
  }
  isPathOrSelectionDirty() {
    return this.properties.marker.isDirty();
  }
  markerFactory() {
    const { shape } = this.properties.marker;
    const MarkerShape = getMarker$1(shape);
    return new MarkerShape();
  }
  updatePathNodes(opts) {
    return __async(this, null, function* () {
      const { opacity, visible, animationEnabled } = opts;
      const [fill, stroke] = opts.paths;
      const strokeWidth = this.getStrokeWidth(this.properties.strokeWidth);
      stroke.setProperties({
        tag: 1 /* Stroke */,
        fill: void 0,
        lineJoin: stroke.lineCap = "round",
        pointerEvents: 1 /* None */,
        stroke: this.properties.stroke,
        strokeWidth,
        strokeOpacity: this.properties.strokeOpacity,
        lineDash: this.properties.lineDash,
        lineDashOffset: this.properties.lineDashOffset,
        opacity,
        visible
      });
      fill.setProperties({
        tag: 0 /* Fill */,
        stroke: void 0,
        lineJoin: "round",
        pointerEvents: 1 /* None */,
        fill: this.properties.fill,
        fillOpacity: this.properties.fillOpacity,
        lineDash: this.properties.lineDash,
        lineDashOffset: this.properties.lineDashOffset,
        strokeOpacity: this.properties.strokeOpacity,
        fillShadow: this.properties.shadow,
        opacity,
        visible: visible || animationEnabled,
        strokeWidth
      });
      updateClipPath(this, stroke);
      updateClipPath(this, fill);
    });
  }
  updatePaths(opts) {
    return __async(this, null, function* () {
      this.updateAreaPaths([opts.paths], [opts.contextData]);
    });
  }
  updateAreaPaths(paths, contextData) {
    this.updateFillPath(paths, contextData);
    this.updateStrokePath(paths, contextData);
  }
  updateFillPath(paths, contextData) {
    contextData.forEach(({ fillData }, contextDataIndex) => {
      const [fill] = paths[contextDataIndex];
      const { path: fillPath } = fill;
      fillPath.clear({ trackChanges: true });
      for (const { point } of fillData.points) {
        if (point.moveTo) {
          fillPath.moveTo(point.x, point.y);
        } else {
          fillPath.lineTo(point.x, point.y);
        }
      }
      fillPath.closePath();
      fill.checkPathDirty();
    });
  }
  updateStrokePath(paths, contextData) {
    contextData.forEach(({ strokeData }, contextDataIndex) => {
      const [, stroke] = paths[contextDataIndex];
      const { path: strokePath } = stroke;
      strokePath.clear({ trackChanges: true });
      for (const { point } of strokeData.points) {
        if (point.moveTo) {
          strokePath.moveTo(point.x, point.y);
        } else {
          strokePath.lineTo(point.x, point.y);
        }
      }
      stroke.checkPathDirty();
    });
  }
  updateMarkerSelection(opts) {
    return __async(this, null, function* () {
      const { nodeData, markerSelection } = opts;
      if (this.properties.marker.isDirty()) {
        markerSelection.clear();
        markerSelection.cleanup();
      }
      return markerSelection.update(this.properties.marker.enabled ? nodeData : []);
    });
  }
  updateMarkerNodes(opts) {
    return __async(this, null, function* () {
      const { markerSelection, isHighlight: highlighted } = opts;
      const { xKey, yKey, marker, fill, stroke, strokeWidth, fillOpacity, strokeOpacity, highlightStyle } = this.properties;
      const baseStyle = mergeDefaults(highlighted && highlightStyle.item, marker.getStyle(), {
        fill,
        stroke,
        strokeWidth,
        fillOpacity,
        strokeOpacity
      });
      markerSelection.each((node, datum) => {
        this.updateMarkerStyle(node, marker, { datum, highlighted, xKey, yKey }, baseStyle);
      });
      if (!highlighted) {
        this.properties.marker.markClean();
      }
    });
  }
  updateLabelSelection(opts) {
    return __async(this, null, function* () {
      const { labelData, labelSelection } = opts;
      return labelSelection.update(labelData, (text) => {
        text.tag = 3 /* Label */;
      });
    });
  }
  updateLabelNodes(opts) {
    return __async(this, null, function* () {
      const { labelSelection } = opts;
      const { enabled: labelEnabled, fontStyle, fontWeight, fontSize, fontFamily, color } = this.properties.label;
      labelSelection.each((text, datum) => {
        const { x, y, label } = datum;
        if (label && labelEnabled && this.visible) {
          text.fontStyle = fontStyle;
          text.fontWeight = fontWeight;
          text.fontSize = fontSize;
          text.fontFamily = fontFamily;
          text.textAlign = label.textAlign;
          text.textBaseline = label.textBaseline;
          text.text = label.text;
          text.x = x;
          text.y = y - 10;
          text.fill = color;
          text.visible = true;
        } else {
          text.visible = false;
        }
      });
    });
  }
  getTooltipHtml(nodeDatum) {
    const { id: seriesId, axes, dataModel } = this;
    const { xKey, xName, yName, tooltip, marker } = this.properties;
    const { yKey, xValue, yValue, datum } = nodeDatum;
    const xAxis = axes["x" /* X */];
    const yAxis = axes["y" /* Y */];
    if (!this.properties.isValid() || !(xAxis && yAxis && isNumber2(yValue)) || !dataModel) {
      return "";
    }
    const xString = xAxis.formatDatum(xValue);
    const yString = yAxis.formatDatum(yValue);
    const title = sanitizeHtml(yName);
    const content = sanitizeHtml(xString + ": " + yString);
    const baseStyle = mergeDefaults({ fill: this.properties.fill }, marker.getStyle(), {
      stroke: this.properties.stroke,
      strokeWidth: this.properties.strokeWidth
    });
    const { fill: color } = this.getMarkerStyle(
      marker,
      { datum: nodeDatum, xKey, yKey, highlighted: false },
      baseStyle
    );
    return tooltip.toTooltipHtml(
      { title, content, backgroundColor: color },
      {
        datum,
        xKey,
        xName,
        yKey,
        yName,
        color,
        title,
        seriesId
      }
    );
  }
  getLegendData(legendType) {
    var _a, _b, _c, _d, _e, _f;
    if (!((_a = this.data) == null ? void 0 : _a.length) || !this.properties.isValid() || legendType !== "category") {
      return [];
    }
    const { yKey, yName, fill, stroke, fillOpacity, strokeOpacity, strokeWidth, lineDash, marker, visible } = this.properties;
    return [
      {
        legendType,
        id: this.id,
        itemId: yKey,
        seriesId: this.id,
        enabled: visible,
        label: {
          text: yName != null ? yName : yKey
        },
        marker: {
          shape: marker.shape,
          fill: (_b = marker.fill) != null ? _b : fill,
          stroke: (_c = marker.stroke) != null ? _c : stroke,
          fillOpacity: (_d = marker.fillOpacity) != null ? _d : fillOpacity,
          strokeOpacity: (_e = marker.strokeOpacity) != null ? _e : strokeOpacity,
          strokeWidth: (_f = marker.strokeWidth) != null ? _f : 0,
          enabled: marker.enabled || strokeWidth <= 0
        },
        line: {
          stroke,
          strokeOpacity,
          strokeWidth,
          lineDash
        }
      }
    ];
  }
  animateEmptyUpdateReady(animationData) {
    const { markerSelections, labelSelections, contextData, paths } = animationData;
    const { animationManager } = this.ctx;
    this.updateAreaPaths(paths, contextData);
    pathSwipeInAnimation(this, animationManager, paths.flat());
    resetMotion(markerSelections, resetMarkerPositionFn);
    markerSwipeScaleInAnimation(this, animationManager, markerSelections);
    seriesLabelFadeInAnimation(this, "labels", animationManager, labelSelections);
  }
  animateReadyResize(animationData) {
    const { contextData, paths } = animationData;
    this.updateAreaPaths(paths, contextData);
    super.animateReadyResize(animationData);
  }
  animateWaitingUpdateReady(animationData) {
    var _a, _b;
    const { animationManager } = this.ctx;
    const { markerSelections, labelSelections, contextData, paths, previousContextData } = animationData;
    super.resetAllAnimation(animationData);
    if (contextData.length === 0 || !previousContextData || previousContextData.length === 0) {
      animationManager.skipCurrentBatch();
      this.updateAreaPaths(paths, contextData);
      return;
    }
    const [[fill, stroke]] = paths;
    const [newData] = contextData;
    const [oldData] = previousContextData;
    const fns = prepareAreaPathAnimation(newData, oldData, (_b = (_a = this.processedData) == null ? void 0 : _a.reduced) == null ? void 0 : _b.diff);
    if (fns === void 0) {
      animationManager.skipCurrentBatch();
      this.updateAreaPaths(paths, contextData);
      return;
    }
    fromToMotion(this.id, "marker_update", animationManager, markerSelections, fns.marker);
    fromToMotion(this.id, "fill_path_properties", animationManager, [fill], fns.fill.pathProperties);
    pathMotion(this.id, "fill_path_update", animationManager, [fill], fns.fill.path);
    this.updateStrokePath(paths, contextData);
    pathFadeInAnimation(this, "stroke", animationManager, [stroke]);
    seriesLabelFadeInAnimation(this, "labels", animationManager, labelSelections);
  }
  isLabelEnabled() {
    return this.properties.label.enabled;
  }
  nodeFactory() {
    return new Group();
  }
};
_AreaSeries.className = "AreaSeries";
_AreaSeries.type = "area";

// packages/ag-charts-community/src/chart/series/cartesian/abstractBarSeries.ts
var AbstractBarSeriesProperties = class extends CartesianSeriesProperties {
  constructor() {
    super(...arguments);
    this.direction = "vertical";
  }
};
__decorateClass([
  Validate(DIRECTION)
], AbstractBarSeriesProperties.prototype, "direction", 2);
var AbstractBarSeries = class extends CartesianSeries {
  getBandScalePadding() {
    return { inner: 0.2, outer: 0.1 };
  }
  shouldFlipXY() {
    return !this.isVertical();
  }
  isVertical() {
    return this.properties.direction === "vertical";
  }
  getBarDirection() {
    return this.shouldFlipXY() ? "x" /* X */ : "y" /* Y */;
  }
  getCategoryDirection() {
    return this.shouldFlipXY() ? "y" /* Y */ : "x" /* X */;
  }
  getValueAxis() {
    const direction = this.getBarDirection();
    return this.axes[direction];
  }
  getCategoryAxis() {
    const direction = this.getCategoryDirection();
    return this.axes[direction];
  }
};

// packages/ag-charts-community/src/chart/series/cartesian/barSeriesProperties.ts
var BarSeriesLabel = class extends Label$1 {
  constructor() {
    super(...arguments);
    this.placement = "inside";
  }
};
__decorateClass([
  Validate(PLACEMENT)
], BarSeriesLabel.prototype, "placement", 2);
var BarSeriesProperties = class extends AbstractBarSeriesProperties {
  constructor() {
    super(...arguments);
    this.fill = "#c16068";
    this.fillOpacity = 1;
    this.stroke = "#874349";
    this.strokeWidth = 1;
    this.strokeOpacity = 1;
    this.lineDash = [0];
    this.lineDashOffset = 0;
    this.cornerRadius = 0;
    this.shadow = new DropShadow();
    this.label = new BarSeriesLabel();
    this.tooltip = new SeriesTooltip();
  }
};
__decorateClass([
  Validate(STRING)
], BarSeriesProperties.prototype, "xKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], BarSeriesProperties.prototype, "xName", 2);
__decorateClass([
  Validate(STRING)
], BarSeriesProperties.prototype, "yKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], BarSeriesProperties.prototype, "yName", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], BarSeriesProperties.prototype, "stackGroup", 2);
__decorateClass([
  Validate(NUMBER, { optional: true })
], BarSeriesProperties.prototype, "normalizedTo", 2);
__decorateClass([
  Validate(COLOR_STRING)
], BarSeriesProperties.prototype, "fill", 2);
__decorateClass([
  Validate(RATIO)
], BarSeriesProperties.prototype, "fillOpacity", 2);
__decorateClass([
  Validate(COLOR_STRING)
], BarSeriesProperties.prototype, "stroke", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], BarSeriesProperties.prototype, "strokeWidth", 2);
__decorateClass([
  Validate(RATIO)
], BarSeriesProperties.prototype, "strokeOpacity", 2);
__decorateClass([
  Validate(LINE_DASH)
], BarSeriesProperties.prototype, "lineDash", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], BarSeriesProperties.prototype, "lineDashOffset", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], BarSeriesProperties.prototype, "cornerRadius", 2);
__decorateClass([
  Validate(FUNCTION, { optional: true })
], BarSeriesProperties.prototype, "formatter", 2);
__decorateClass([
  Validate(OBJECT, { optional: true })
], BarSeriesProperties.prototype, "shadow", 2);
__decorateClass([
  Validate(OBJECT)
], BarSeriesProperties.prototype, "label", 2);
__decorateClass([
  Validate(OBJECT)
], BarSeriesProperties.prototype, "tooltip", 2);

// packages/ag-charts-community/src/chart/series/cartesian/barUtil.ts
function updateRect({ rect, config }) {
  const {
    crisp = true,
    fill,
    stroke,
    strokeWidth,
    fillOpacity,
    strokeOpacity,
    lineDash,
    lineDashOffset,
    fillShadow,
    cornerRadius = 0,
    topLeftCornerRadius,
    topRightCornerRadius,
    bottomRightCornerRadius,
    bottomLeftCornerRadius,
    cornerRadiusBbox,
    visible = true
  } = config;
  rect.crisp = crisp;
  rect.fill = fill;
  rect.stroke = stroke;
  rect.strokeWidth = strokeWidth;
  rect.fillOpacity = fillOpacity;
  rect.strokeOpacity = strokeOpacity;
  rect.lineDash = lineDash;
  rect.lineDashOffset = lineDashOffset;
  rect.fillShadow = fillShadow;
  rect.topLeftCornerRadius = topLeftCornerRadius ? cornerRadius : 0;
  rect.topRightCornerRadius = topRightCornerRadius ? cornerRadius : 0;
  rect.bottomRightCornerRadius = bottomRightCornerRadius ? cornerRadius : 0;
  rect.bottomLeftCornerRadius = bottomLeftCornerRadius ? cornerRadius : 0;
  rect.cornerRadiusBbox = cornerRadiusBbox;
  rect.visible = visible;
}
function getRectConfig(_a) {
  var _b = _a, {
    datum,
    isHighlighted,
    style,
    highlightStyle,
    formatter,
    seriesId,
    ctx: { callbackCache }
  } = _b, opts = __objRest(_b, [
    "datum",
    "isHighlighted",
    "style",
    "highlightStyle",
    "formatter",
    "seriesId",
    "ctx"
  ]);
  var _a2, _b2, _c, _d, _e, _f;
  const { fill, fillOpacity, stroke, strokeWidth } = mergeDefaults(isHighlighted && highlightStyle, style);
  const {
    strokeOpacity,
    fillShadow,
    lineDash,
    lineDashOffset,
    cornerRadius = 0,
    topLeftCornerRadius = true,
    topRightCornerRadius = true,
    bottomRightCornerRadius = true,
    bottomLeftCornerRadius = true,
    cornerRadiusBbox
  } = style;
  let format2;
  if (formatter) {
    format2 = callbackCache.call(formatter, __spreadValues({
      datum: datum.datum,
      xKey: datum.xKey,
      fill,
      stroke,
      strokeWidth,
      cornerRadius,
      highlighted: isHighlighted,
      seriesId
    }, opts));
  }
  return {
    fill: (_a2 = format2 == null ? void 0 : format2.fill) != null ? _a2 : fill,
    stroke: (_b2 = format2 == null ? void 0 : format2.stroke) != null ? _b2 : stroke,
    strokeWidth: (_c = format2 == null ? void 0 : format2.strokeWidth) != null ? _c : strokeWidth,
    fillOpacity: (_d = format2 == null ? void 0 : format2.fillOpacity) != null ? _d : fillOpacity,
    strokeOpacity: (_e = format2 == null ? void 0 : format2.strokeOpacity) != null ? _e : strokeOpacity,
    lineDash,
    lineDashOffset,
    fillShadow,
    // @ts-expect-error Remove once corner radius is officially added
    cornerRadius: (_f = format2 == null ? void 0 : format2.cornerRadius) != null ? _f : cornerRadius,
    topLeftCornerRadius,
    topRightCornerRadius,
    bottomRightCornerRadius,
    bottomLeftCornerRadius,
    cornerRadiusBbox
  };
}
function checkCrisp(visibleRange = []) {
  const [visibleMin, visibleMax] = visibleRange;
  const isZoomed = visibleMin !== 0 || visibleMax !== 1;
  return !isZoomed;
}
function collapsedStartingBarPosition(isVertical, axes) {
  const { startingX, startingY } = getStartingValues(isVertical, axes);
  const isDatumNegative = (datum) => {
    var _a;
    return isNegative((_a = datum["yValue"]) != null ? _a : 0);
  };
  const calculate = (datum, prevDatum) => {
    let x = isVertical ? datum.x : startingX;
    let y = isVertical ? startingY : datum.y;
    let width = isVertical ? datum.width : 0;
    let height = isVertical ? 0 : datum.height;
    if (prevDatum && (isNaN(x) || isNaN(y))) {
      ({ x, y } = prevDatum);
      width = isVertical ? prevDatum.width : 0;
      height = isVertical ? 0 : prevDatum.height;
      if (isVertical && !isDatumNegative(prevDatum)) {
        y += prevDatum.height;
      } else if (!isVertical && isDatumNegative(prevDatum)) {
        x += prevDatum.width;
      }
    }
    return { x, y, width, height };
  };
  return { isVertical, calculate };
}
function midpointStartingBarPosition(isVertical) {
  return {
    isVertical,
    calculate: (datum) => {
      return {
        x: isVertical ? datum.x : datum.x + datum.width / 2,
        y: isVertical ? datum.y + datum.height / 2 : datum.y,
        width: isVertical ? datum.width : 0,
        height: isVertical ? 0 : datum.height
      };
    }
  };
}
function prepareBarAnimationFunctions(initPos) {
  const isRemoved = (datum) => datum == null || isNaN(datum.x) || isNaN(datum.y);
  const fromFn = (rect, datum, status) => {
    if (status === "updated" && isRemoved(datum)) {
      status = "removed";
    } else if (status === "updated" && isRemoved(rect.previousDatum)) {
      status = "added";
    }
    let source = { x: rect.x, y: rect.y, width: rect.width, height: rect.height };
    if (status === "unknown" || status === "added") {
      source = initPos.calculate(datum, rect.previousDatum);
    }
    return __spreadValues(__spreadValues({}, source), FROM_TO_MIXINS[status]);
  };
  const toFn = (rect, datum, status) => {
    if (status === "removed" || isRemoved(datum)) {
      return initPos.calculate(datum, rect.previousDatum);
    }
    return { x: datum.x, y: datum.y, width: datum.width, height: datum.height };
  };
  return { toFn, fromFn };
}
function getStartingValues(isVertical, axes) {
  const axis = axes[isVertical ? "y" /* Y */ : "x" /* X */];
  let startingX = Infinity;
  let startingY = 0;
  if (!axis) {
    return { startingX, startingY };
  }
  if (isVertical) {
    startingY = axis.scale.convert(ContinuousScale.is(axis.scale) ? 0 : Math.max(...axis.range));
  } else {
    startingX = axis.scale.convert(ContinuousScale.is(axis.scale) ? 0 : Math.min(...axis.range));
  }
  return { startingX, startingY };
}
function resetBarSelectionsFn(_node, { x, y, width, height }) {
  return { x, y, width, height };
}

// packages/ag-charts-community/src/chart/series/cartesian/labelUtil.ts
function updateLabelNode(textNode, label, labelDatum) {
  if (label.enabled && labelDatum) {
    const { x, y, text, textAlign, textBaseline } = labelDatum;
    const { color: fill, fontStyle, fontWeight, fontSize, fontFamily } = label;
    textNode.setProperties({
      visible: true,
      x,
      y,
      text,
      fill,
      fontStyle,
      fontWeight,
      fontSize,
      fontFamily,
      textAlign,
      textBaseline
    });
  } else {
    textNode.visible = false;
  }
}
function adjustLabelPlacement({
  isPositive,
  isVertical,
  placement,
  padding = 0,
  rect
}) {
  let x = rect.x + rect.width / 2;
  let y = rect.y + rect.height / 2;
  let textAlign = "center";
  let textBaseline = "middle";
  switch (placement) {
    case "start": {
      if (isVertical) {
        y = isPositive ? rect.y + rect.height + padding : rect.y - padding;
        textBaseline = isPositive ? "top" : "bottom";
      } else {
        x = isPositive ? rect.x - padding : rect.x + rect.width + padding;
        textAlign = isPositive ? "start" : "end";
      }
      break;
    }
    case "outside":
    case "end": {
      if (isVertical) {
        y = isPositive ? rect.y - padding : rect.y + rect.height + padding;
        textBaseline = isPositive ? "bottom" : "top";
      } else {
        x = isPositive ? rect.x + rect.width + padding : rect.x - padding;
        textAlign = isPositive ? "start" : "end";
      }
      break;
    }
  }
  return { x, y, textAlign, textBaseline };
}

// packages/ag-charts-community/src/chart/series/cartesian/barSeries.ts
var _BarSeries = class _BarSeries extends AbstractBarSeries {
  constructor(moduleCtx) {
    super({
      moduleCtx,
      pickModes: [0 /* EXACT_SHAPE_MATCH */],
      pathsPerSeries: 0,
      hasHighlightedLabels: true,
      datumSelectionGarbageCollection: false,
      animationResetFns: {
        datum: resetBarSelectionsFn,
        label: resetLabelFn
      }
    });
    this.properties = new BarSeriesProperties();
    /**
     * Used to get the position of bars within each group.
     */
    this.groupScale = new BandScale$5();
    this.smallestDataInterval = void 0;
  }
  resolveKeyDirection(direction) {
    if (this.getBarDirection() === "x" /* X */) {
      if (direction === "x" /* X */) {
        return "y" /* Y */;
      }
      return "x" /* X */;
    }
    return direction;
  }
  processData(dataController) {
    return __async(this, null, function* () {
      var _a, _b, _c, _d;
      if (!this.properties.isValid() || !this.data) {
        return;
      }
      const { seriesGrouping: { groupIndex = this.id } = {}, data = [] } = this;
      const { xKey, yKey, normalizedTo } = this.properties;
      const animationEnabled = !this.ctx.animationManager.isSkipped();
      const normalizedToAbs = Math.abs(normalizedTo != null ? normalizedTo : NaN);
      const isContinuousX = ContinuousScale.is((_a = this.getCategoryAxis()) == null ? void 0 : _a.scale);
      const isContinuousY = ContinuousScale.is((_b = this.getValueAxis()) == null ? void 0 : _b.scale);
      const stackGroupName = `bar-stack-${groupIndex}-yValues`;
      const stackGroupTrailingName = `${stackGroupName}-trailing`;
      const normaliseTo = normalizedToAbs && isFinite(normalizedToAbs) ? normalizedToAbs : void 0;
      const extraProps = [];
      if (normaliseTo) {
        extraProps.push(normaliseGroupTo(this, [stackGroupName, stackGroupTrailingName], normaliseTo, "range"));
      }
      if (animationEnabled && this.processedData) {
        extraProps.push(diff(this.processedData));
      }
      if (animationEnabled) {
        extraProps.push(animationValidation(this));
      }
      const visibleProps = !this.visible ? { forceValue: 0 } : {};
      const { processedData } = yield this.requestDataModel(dataController, data, {
        props: [
          keyProperty(this, xKey, isContinuousX, { id: "xValue" }),
          valueProperty(this, yKey, isContinuousY, __spreadValues({ id: `yValue-raw`, invalidValue: null }, visibleProps)),
          ...groupAccumulativeValueProperty(this, yKey, isContinuousY, "normal", "current", __spreadValues({
            id: `yValue-end`,
            rangeId: `yValue-range`,
            invalidValue: null,
            missingValue: 0,
            groupId: stackGroupName,
            separateNegative: true
          }, visibleProps)),
          ...groupAccumulativeValueProperty(this, yKey, isContinuousY, "trailing", "current", __spreadValues({
            id: `yValue-start`,
            invalidValue: null,
            missingValue: 0,
            groupId: stackGroupTrailingName,
            separateNegative: true
          }, visibleProps)),
          ...isContinuousX ? [SMALLEST_KEY_INTERVAL] : [],
          ...extraProps
        ],
        groupByKeys: true,
        groupByData: false
      });
      this.smallestDataInterval = {
        x: (_d = (_c = processedData.reduced) == null ? void 0 : _c.smallestKeyInterval) != null ? _d : Infinity,
        y: Infinity
      };
      this.animationState.transition("updateData");
    });
  }
  getSeriesDomain(direction) {
    var _a;
    const { processedData, dataModel } = this;
    if (!processedData || !dataModel || processedData.data.length === 0)
      return [];
    const { reduced: { [SMALLEST_KEY_INTERVAL.property]: smallestX } = {} } = processedData;
    const categoryAxis = this.getCategoryAxis();
    const valueAxis = this.getValueAxis();
    const keyDef = dataModel.resolveProcessedDataDefById(this, `xValue`);
    const keys = dataModel.getDomain(this, `xValue`, "key", processedData);
    const yExtent = dataModel.getDomain(this, `yValue-end`, "value", processedData);
    if (direction === this.getCategoryDirection()) {
      if ((keyDef == null ? void 0 : keyDef.def.type) === "key" && (keyDef == null ? void 0 : keyDef.def.valueType) === "category") {
        return keys;
      }
      const scalePadding = smallestX != null && isFinite(smallestX) ? smallestX : 0;
      const keysExtent = (_a = extent$4(keys)) != null ? _a : [NaN, NaN];
      const isReversed = categoryAxis == null ? void 0 : categoryAxis.isReversed();
      if (direction === "y" /* Y */) {
        const d02 = keysExtent[0] + (isReversed ? 0 : -scalePadding);
        const d12 = keysExtent[1] + (isReversed ? scalePadding : 0);
        return fixNumericExtent([d02, d12], categoryAxis);
      }
      const d0 = keysExtent[0] + (isReversed ? -scalePadding : 0);
      const d1 = keysExtent[1] + (isReversed ? 0 : scalePadding);
      return fixNumericExtent([d0, d1], categoryAxis);
    } else if (this.getValueAxis() instanceof LogAxis) {
      return fixNumericExtent(yExtent, valueAxis);
    } else {
      const fixedYExtent = [yExtent[0] > 0 ? 0 : yExtent[0], yExtent[1] < 0 ? 0 : yExtent[1]];
      return fixNumericExtent(fixedYExtent, valueAxis);
    }
  }
  createNodeData() {
    return __async(this, null, function* () {
      const { dataModel } = this;
      const xAxis = this.getCategoryAxis();
      const yAxis = this.getValueAxis();
      if (!(dataModel && xAxis && yAxis && this.properties.isValid())) {
        return [];
      }
      const xScale = xAxis.scale;
      const yScale = yAxis.scale;
      const {
        groupScale,
        processedData,
        smallestDataInterval,
        ctx: { seriesStateManager }
      } = this;
      const { xKey, yKey, xName, yName, fill, stroke, strokeWidth, cornerRadius, legendItemName, label } = this.properties;
      const yReversed = yAxis.isReversed();
      const xBandWidth = ContinuousScale.is(xScale) ? xScale.calcBandwidth(smallestDataInterval == null ? void 0 : smallestDataInterval.x) : xScale.bandwidth;
      const domain = [];
      const { index: groupIndex, visibleGroupCount } = seriesStateManager.getVisiblePeerGroupIndex(this);
      for (let groupIdx = 0; groupIdx < visibleGroupCount; groupIdx++) {
        domain.push(String(groupIdx));
      }
      groupScale.domain = domain;
      groupScale.range = [0, xBandWidth != null ? xBandWidth : 0];
      if (xAxis instanceof CategoryAxis) {
        groupScale.paddingInner = xAxis.groupPaddingInner;
      } else if (xAxis instanceof GroupedCategoryAxis) {
        groupScale.padding = 0.1;
      } else {
        groupScale.padding = 0;
      }
      groupScale.round = groupScale.padding !== 0;
      const barWidth = groupScale.bandwidth >= 1 ? (
        // Pixel-rounded value for low-volume bar charts.
        groupScale.bandwidth
      ) : (
        // Handle high-volume bar charts gracefully.
        groupScale.rawBandwidth
      );
      const xIndex = dataModel.resolveProcessedDataIndexById(this, `xValue`).index;
      const yRawIndex = dataModel.resolveProcessedDataIndexById(this, `yValue-raw`).index;
      const yStartIndex = dataModel.resolveProcessedDataIndexById(this, `yValue-start`).index;
      const yEndIndex = dataModel.resolveProcessedDataIndexById(this, `yValue-end`).index;
      const yRangeIndex = dataModel.resolveProcessedDataDefById(this, `yValue-range`).index;
      const animationEnabled = !this.ctx.animationManager.isSkipped();
      const contexts = [];
      processedData == null ? void 0 : processedData.data.forEach(({ keys, datum: seriesDatum, values, aggValues }) => {
        values.forEach((value, contextIndex) => {
          var _a, _b;
          (_a = contexts[contextIndex]) != null ? _a : contexts[contextIndex] = {
            itemId: yKey,
            nodeData: [],
            labelData: [],
            scales: __superGet(_BarSeries.prototype, this, "calculateScaling").call(this),
            visible: this.visible || animationEnabled
          };
          const xValue = keys[xIndex];
          const x = xScale.convert(xValue);
          const currY = +value[yEndIndex];
          const prevY = +value[yStartIndex];
          const yRawValue = value[yRawIndex];
          const isPositive = yRawValue >= 0;
          const isUpward = isPositive !== yReversed;
          const yRange = (_b = aggValues == null ? void 0 : aggValues[yRangeIndex][isPositive ? 1 : 0]) != null ? _b : 0;
          const barX = x + groupScale.convert(String(groupIndex));
          if (isNaN(currY)) {
            return;
          }
          const y = yScale.convert(currY);
          const bottomY = yScale.convert(prevY);
          const barAlongX = this.getBarDirection() === "x" /* X */;
          const bboxHeight = yScale.convert(yRange);
          const bboxBottom = yScale.convert(0);
          const cornerRadiusBbox = new BBox(
            barAlongX ? Math.min(bboxBottom, bboxHeight) : barX,
            barAlongX ? barX : Math.min(bboxBottom, bboxHeight),
            barAlongX ? Math.abs(bboxBottom - bboxHeight) : barWidth,
            barAlongX ? barWidth : Math.abs(bboxBottom - bboxHeight)
          );
          const rect = {
            x: barAlongX ? Math.min(y, bottomY) : barX,
            y: barAlongX ? barX : Math.min(y, bottomY),
            width: barAlongX ? Math.abs(bottomY - y) : barWidth,
            height: barAlongX ? barWidth : Math.abs(bottomY - y),
            cornerRadiusBbox
          };
          const {
            fontStyle: labelFontStyle,
            fontWeight: labelFontWeight,
            fontSize: labelFontSize,
            fontFamily: labelFontFamily,
            color: labelColor,
            placement
          } = label;
          const labelText = this.getLabelText(
            this.properties.label,
            {
              datum: seriesDatum[contextIndex],
              value: yRawValue,
              xKey,
              yKey,
              xName,
              yName,
              legendItemName
            },
            (value2) => isNumber2(value2) ? value2.toFixed(2) : ""
          );
          const labelDatum = labelText ? __spreadValues({
            text: labelText,
            fill: labelColor,
            fontStyle: labelFontStyle,
            fontWeight: labelFontWeight,
            fontSize: labelFontSize,
            fontFamily: labelFontFamily
          }, adjustLabelPlacement({
            isPositive,
            isVertical: !barAlongX,
            placement,
            rect
          })) : void 0;
          const lengthRatioMultiplier = this.shouldFlipXY() ? rect.height : rect.width;
          const nodeData = {
            series: this,
            itemId: yKey,
            datum: seriesDatum[contextIndex],
            cumulativeValue: currY,
            xValue,
            yValue: yRawValue,
            yKey,
            xKey,
            capDefaults: {
              lengthRatioMultiplier,
              lengthMax: lengthRatioMultiplier
            },
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
            midPoint: { x: rect.x + rect.width / 2, y: rect.y + rect.height / 2 },
            fill,
            stroke,
            strokeWidth,
            cornerRadius,
            topLeftCornerRadius: !(barAlongX === isUpward),
            topRightCornerRadius: isUpward,
            bottomRightCornerRadius: barAlongX === isUpward,
            bottomLeftCornerRadius: !isUpward,
            cornerRadiusBbox,
            label: labelDatum
          };
          contexts[contextIndex].nodeData.push(nodeData);
          contexts[contextIndex].labelData.push(nodeData);
        });
      });
      return contexts;
    });
  }
  nodeFactory() {
    return new Rect();
  }
  updateDatumSelection(opts) {
    return __async(this, null, function* () {
      return opts.datumSelection.update(
        opts.nodeData,
        (rect) => {
          rect.tag = 0 /* Bar */;
        },
        (datum) => datum.xValue
      );
    });
  }
  updateDatumNodes(opts) {
    return __async(this, null, function* () {
      if (!this.properties.isValid()) {
        return;
      }
      const {
        yKey,
        stackGroup,
        fill,
        fillOpacity,
        stroke,
        strokeWidth,
        strokeOpacity,
        lineDash,
        lineDashOffset,
        formatter,
        shadow,
        highlightStyle: { item: itemHighlightStyle }
      } = this.properties;
      const xAxis = this.axes["x" /* X */];
      const crisp = checkCrisp(xAxis == null ? void 0 : xAxis.visibleRange);
      const categoryAlongX = this.getCategoryDirection() === "x" /* X */;
      opts.datumSelection.each((rect, datum) => {
        const style = {
          fill,
          stroke,
          fillOpacity,
          strokeOpacity,
          lineDash,
          lineDashOffset,
          fillShadow: shadow,
          strokeWidth: this.getStrokeWidth(strokeWidth),
          cornerRadius: datum.cornerRadius,
          topLeftCornerRadius: datum.topLeftCornerRadius,
          topRightCornerRadius: datum.topRightCornerRadius,
          bottomRightCornerRadius: datum.bottomRightCornerRadius,
          bottomLeftCornerRadius: datum.bottomLeftCornerRadius,
          cornerRadiusBbox: datum.cornerRadiusBbox
        };
        const visible = categoryAlongX ? datum.width > 0 : datum.height > 0;
        const config = getRectConfig({
          datum,
          ctx: this.ctx,
          seriesId: this.id,
          isHighlighted: opts.isHighlight,
          highlightStyle: itemHighlightStyle,
          yKey,
          style,
          formatter,
          stackGroup
        });
        config.crisp = crisp;
        config.visible = visible;
        updateRect({ rect, config });
      });
    });
  }
  updateLabelSelection(opts) {
    return __async(this, null, function* () {
      const data = this.isLabelEnabled() ? opts.labelData : [];
      return opts.labelSelection.update(data, (text) => {
        text.tag = 1 /* Label */;
        text.pointerEvents = 1 /* None */;
      });
    });
  }
  updateLabelNodes(opts) {
    return __async(this, null, function* () {
      opts.labelSelection.each((textNode, datum) => {
        updateLabelNode(textNode, this.properties.label, datum.label);
      });
    });
  }
  getTooltipHtml(nodeDatum) {
    var _a;
    const {
      id: seriesId,
      processedData,
      ctx: { callbackCache }
    } = this;
    const xAxis = this.getCategoryAxis();
    const yAxis = this.getValueAxis();
    if (!processedData || !this.properties.isValid() || !xAxis || !yAxis) {
      return "";
    }
    const { xKey, yKey, xName, yName, fill, stroke, strokeWidth, tooltip, formatter, stackGroup } = this.properties;
    const { xValue, yValue, datum } = nodeDatum;
    const xString = xAxis.formatDatum(xValue);
    const yString = yAxis.formatDatum(yValue);
    const title = sanitizeHtml(yName);
    const content = sanitizeHtml(xString + ": " + yString);
    let format2;
    if (formatter) {
      format2 = callbackCache.call(formatter, {
        seriesId,
        datum,
        xKey,
        yKey,
        stackGroup,
        fill,
        stroke,
        strokeWidth: this.getStrokeWidth(strokeWidth),
        highlighted: false
      });
    }
    const color = (_a = format2 == null ? void 0 : format2.fill) != null ? _a : fill;
    return tooltip.toTooltipHtml(
      { title, content, backgroundColor: color },
      __spreadValues({
        seriesId,
        datum,
        xKey,
        yKey,
        xName,
        yName,
        stackGroup,
        title,
        color
      }, this.getModuleTooltipParams())
    );
  }
  getLegendData(legendType) {
    var _a, _b;
    const { showInLegend } = this.properties;
    if (legendType !== "category" || !((_a = this.data) == null ? void 0 : _a.length) || !this.properties.isValid() || !showInLegend) {
      return [];
    }
    const { yKey, yName, fill, stroke, strokeWidth, fillOpacity, strokeOpacity, legendItemName, visible } = this.properties;
    return [
      {
        legendType: "category",
        id: this.id,
        itemId: yKey,
        seriesId: this.id,
        enabled: visible,
        label: { text: (_b = legendItemName != null ? legendItemName : yName) != null ? _b : yKey },
        marker: { fill, fillOpacity, stroke, strokeWidth, strokeOpacity },
        legendItemName
      }
    ];
  }
  animateEmptyUpdateReady({ datumSelections, labelSelections, annotationSelections }) {
    const fns = prepareBarAnimationFunctions(collapsedStartingBarPosition(this.isVertical(), this.axes));
    fromToMotion(this.id, "nodes", this.ctx.animationManager, datumSelections, fns);
    seriesLabelFadeInAnimation(this, "labels", this.ctx.animationManager, labelSelections);
    seriesLabelFadeInAnimation(this, "annotations", this.ctx.animationManager, annotationSelections);
  }
  animateWaitingUpdateReady(data) {
    var _a, _b;
    const { datumSelections, labelSelections, annotationSelections } = data;
    this.ctx.animationManager.stopByAnimationGroupId(this.id);
    const diff2 = (_b = (_a = this.processedData) == null ? void 0 : _a.reduced) == null ? void 0 : _b.diff;
    const fns = prepareBarAnimationFunctions(collapsedStartingBarPosition(this.isVertical(), this.axes));
    fromToMotion(
      this.id,
      "nodes",
      this.ctx.animationManager,
      datumSelections,
      fns,
      (_, datum) => String(datum.xValue),
      diff2
    );
    seriesLabelFadeInAnimation(this, "labels", this.ctx.animationManager, labelSelections);
    seriesLabelFadeInAnimation(this, "annotations", this.ctx.animationManager, annotationSelections);
  }
  isLabelEnabled() {
    return this.properties.label.enabled;
  }
};
_BarSeries.className = "BarSeries";
_BarSeries.type = "bar";
var BarSeries = _BarSeries;

// packages/ag-charts-community/src/chart/series/cartesian/barSeriesModule.ts
({
  type: "series",
  optionsKey: "series[]",
  packageType: "community",
  chartTypes: ["cartesian"],
  identifier: "bar",
  instanceConstructor: BarSeries,
  stackable: true,
  groupable: true,
  seriesDefaults: {
    axes: [
      {
        type: CARTESIAN_AXIS_TYPES.NUMBER,
        position: CARTESIAN_AXIS_POSITIONS.LEFT
      },
      {
        type: CARTESIAN_AXIS_TYPES.CATEGORY,
        position: CARTESIAN_AXIS_POSITIONS.BOTTOM
      }
    ]
  },
  swapDefaultAxesCondition: (series) => (series == null ? void 0 : series.direction) === "horizontal",
  themeTemplate: {
    __extends__: EXTENDS_SERIES_DEFAULTS,
    fillOpacity: 1,
    strokeWidth: 0,
    lineDash: [0],
    lineDashOffset: 0,
    label: {
      enabled: false,
      fontStyle: void 0,
      fontWeight: FONT_WEIGHT2.NORMAL,
      fontSize: 12,
      fontFamily: DEFAULT_FONT_FAMILY,
      color: DEFAULT_INSIDE_SERIES_LABEL_COLOUR,
      formatter: void 0,
      placement: "inside"
    },
    shadow: {
      enabled: false,
      color: DEFAULT_SHADOW_COLOUR,
      xOffset: 3,
      yOffset: 3,
      blur: 5
    }
  },
  enterpriseThemeTemplate: {
    errorBar: {
      cap: {
        lengthRatio: 0.3
      }
    }
  },
  paletteFactory: singleSeriesPaletteFactory
});

// packages/ag-charts-community/src/chart/series/cartesian/bubbleSeriesProperties.ts
var BubbleSeriesMarker = class extends SeriesMarker {
  constructor() {
    super(...arguments);
    this.maxSize = 30;
  }
};
__decorateClass([
  Validate(POSITIVE_NUMBER),
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], BubbleSeriesMarker.prototype, "maxSize", 2);
__decorateClass([
  Validate(NUMBER_ARRAY, { optional: true }),
  SceneChangeDetection({ redraw: 3 /* MAJOR */ })
], BubbleSeriesMarker.prototype, "domain", 2);
var BubbleSeriesProperties = class extends CartesianSeriesProperties {
  constructor() {
    super(...arguments);
    this.colorRange = ["#ffff00", "#00ff00", "#0000ff"];
    this.marker = new BubbleSeriesMarker();
    this.label = new Label$1();
    this.tooltip = new SeriesTooltip();
  }
};
__decorateClass([
  Validate(STRING)
], BubbleSeriesProperties.prototype, "xKey", 2);
__decorateClass([
  Validate(STRING)
], BubbleSeriesProperties.prototype, "yKey", 2);
__decorateClass([
  Validate(STRING)
], BubbleSeriesProperties.prototype, "sizeKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], BubbleSeriesProperties.prototype, "labelKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], BubbleSeriesProperties.prototype, "colorKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], BubbleSeriesProperties.prototype, "xName", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], BubbleSeriesProperties.prototype, "yName", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], BubbleSeriesProperties.prototype, "sizeName", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], BubbleSeriesProperties.prototype, "labelName", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], BubbleSeriesProperties.prototype, "colorName", 2);
__decorateClass([
  Validate(NUMBER_ARRAY, { optional: true })
], BubbleSeriesProperties.prototype, "colorDomain", 2);
__decorateClass([
  Validate(COLOR_STRING_ARRAY)
], BubbleSeriesProperties.prototype, "colorRange", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], BubbleSeriesProperties.prototype, "title", 2);
__decorateClass([
  Validate(OBJECT)
], BubbleSeriesProperties.prototype, "marker", 2);
__decorateClass([
  Validate(OBJECT)
], BubbleSeriesProperties.prototype, "label", 2);
__decorateClass([
  Validate(OBJECT)
], BubbleSeriesProperties.prototype, "tooltip", 2);

// packages/ag-charts-community/src/chart/series/cartesian/bubbleSeries.ts
var BubbleSeriesNodeClickEvent = class extends CartesianSeriesNodeClickEvent {
  constructor(type, nativeEvent, datum, series) {
    super(type, nativeEvent, datum, series);
    this.sizeKey = series.properties.sizeKey;
  }
};
var _BubbleSeries = class _BubbleSeries extends CartesianSeries {
  constructor(moduleCtx) {
    super({
      moduleCtx,
      pickModes: [
        2 /* NEAREST_BY_MAIN_CATEGORY_AXIS_FIRST */,
        3 /* NEAREST_NODE */,
        0 /* EXACT_SHAPE_MATCH */
      ],
      pathsPerSeries: 0,
      hasMarkers: true,
      markerSelectionGarbageCollection: false,
      animationResetFns: {
        label: resetLabelFn,
        marker: resetMarkerFn
      }
    });
    this.NodeClickEvent = BubbleSeriesNodeClickEvent;
    this.properties = new BubbleSeriesProperties();
    this.sizeScale = new LinearScale$1();
    this.colorScale = new ColorScale();
  }
  processData(dataController) {
    return __async(this, null, function* () {
      var _a, _b;
      if (!this.properties.isValid() || this.data == null) {
        return;
      }
      const { isContinuousX, isContinuousY } = this.isContinuous();
      const { xKey, yKey, sizeKey, labelKey, colorDomain, colorRange, colorKey, marker } = this.properties;
      const { dataModel, processedData } = yield this.requestDataModel(dataController, this.data, {
        props: [
          keyProperty(this, xKey, isContinuousX, { id: "xKey-raw" }),
          keyProperty(this, yKey, isContinuousY, { id: "yKey-raw" }),
          ...labelKey ? [keyProperty(this, labelKey, false, { id: `labelKey-raw` })] : [],
          valueProperty(this, xKey, isContinuousX, { id: `xValue` }),
          valueProperty(this, yKey, isContinuousY, { id: `yValue` }),
          valueProperty(this, sizeKey, true, { id: `sizeValue` }),
          ...colorKey ? [valueProperty(this, colorKey, true, { id: `colorValue` })] : [],
          ...labelKey ? [valueProperty(this, labelKey, false, { id: `labelValue` })] : []
        ],
        dataVisible: this.visible
      });
      const sizeKeyIdx = dataModel.resolveProcessedDataIndexById(this, `sizeValue`).index;
      const processedSize = (_a = processedData.domain.values[sizeKeyIdx]) != null ? _a : [];
      this.sizeScale.domain = marker.domain ? marker.domain : processedSize;
      if (colorKey) {
        const colorKeyIdx = dataModel.resolveProcessedDataIndexById(this, `colorValue`).index;
        this.colorScale.domain = (_b = colorDomain != null ? colorDomain : processedData.domain.values[colorKeyIdx]) != null ? _b : [];
        this.colorScale.range = colorRange;
        this.colorScale.update();
      }
      this.animationState.transition("updateData");
    });
  }
  getSeriesDomain(direction) {
    const { dataModel, processedData } = this;
    if (!processedData || !dataModel)
      return [];
    const id = direction === "x" /* X */ ? `xValue` : `yValue`;
    const dataDef = dataModel.resolveProcessedDataDefById(this, id);
    const domain = dataModel.getDomain(this, id, "value", processedData);
    if ((dataDef == null ? void 0 : dataDef.def.type) === "value" && (dataDef == null ? void 0 : dataDef.def.valueType) === "category") {
      return domain;
    }
    const axis = this.axes[direction];
    return fixNumericExtent(extent$4(domain), axis);
  }
  createNodeData() {
    return __async(this, null, function* () {
      var _a, _b, _c;
      const { axes, dataModel, processedData, colorScale, sizeScale } = this;
      const { xKey, yKey, sizeKey, labelKey, xName, yName, sizeName, labelName, label, colorKey, marker, visible } = this.properties;
      const xAxis = axes["x" /* X */];
      const yAxis = axes["y" /* Y */];
      if (!(dataModel && processedData && visible && xAxis && yAxis)) {
        return [];
      }
      const xDataIdx = dataModel.resolveProcessedDataIndexById(this, `xValue`).index;
      const yDataIdx = dataModel.resolveProcessedDataIndexById(this, `yValue`).index;
      const sizeDataIdx = sizeKey ? dataModel.resolveProcessedDataIndexById(this, `sizeValue`).index : -1;
      const colorDataIdx = colorKey ? dataModel.resolveProcessedDataIndexById(this, `colorValue`).index : -1;
      const labelDataIdx = labelKey ? dataModel.resolveProcessedDataIndexById(this, `labelValue`).index : -1;
      const xScale = xAxis.scale;
      const yScale = yAxis.scale;
      const xOffset = ((_a = xScale.bandwidth) != null ? _a : 0) / 2;
      const yOffset = ((_b = yScale.bandwidth) != null ? _b : 0) / 2;
      const nodeData = [];
      sizeScale.range = [marker.size, marker.maxSize];
      const font = label.getFont();
      for (const { values, datum } of (_c = processedData.data) != null ? _c : []) {
        const xDatum = values[xDataIdx];
        const yDatum = values[yDataIdx];
        const x = xScale.convert(xDatum) + xOffset;
        const y = yScale.convert(yDatum) + yOffset;
        const labelText = this.getLabelText(label, {
          value: labelKey ? values[labelDataIdx] : yDatum,
          datum,
          xKey,
          yKey,
          sizeKey,
          labelKey,
          xName,
          yName,
          sizeName,
          labelName
        });
        const size = HdpiCanvas.getTextSize(String(labelText), font);
        const markerSize = sizeKey ? sizeScale.convert(values[sizeDataIdx]) : marker.size;
        const fill = colorKey ? colorScale.convert(values[colorDataIdx]) : void 0;
        nodeData.push({
          series: this,
          itemId: yKey,
          yKey,
          xKey,
          datum,
          xValue: xDatum,
          yValue: yDatum,
          sizeValue: values[sizeDataIdx],
          point: { x, y, size: markerSize },
          midPoint: { x, y },
          fill,
          label: __spreadValues({ text: labelText }, size)
        });
      }
      return [
        {
          itemId: yKey,
          nodeData,
          labelData: nodeData,
          scales: __superGet(_BubbleSeries.prototype, this, "calculateScaling").call(this),
          visible: this.visible
        }
      ];
    });
  }
  isPathOrSelectionDirty() {
    return this.properties.marker.isDirty();
  }
  getLabelData() {
    var _a;
    return (_a = this.contextNodeData) == null ? void 0 : _a.reduce((r, n) => r.concat(n.labelData), []);
  }
  markerFactory() {
    const { shape } = this.properties.marker;
    const MarkerShape = getMarker$1(shape);
    return new MarkerShape();
  }
  updateMarkerSelection(opts) {
    return __async(this, null, function* () {
      const { nodeData, markerSelection } = opts;
      if (this.properties.marker.isDirty()) {
        markerSelection.clear();
        markerSelection.cleanup();
      }
      const data = this.properties.marker.enabled ? nodeData : [];
      return markerSelection.update(data, void 0, (datum) => this.getDatumId(datum));
    });
  }
  updateMarkerNodes(opts) {
    return __async(this, null, function* () {
      const { markerSelection, isHighlight: highlighted } = opts;
      const { xKey, yKey, sizeKey, labelKey, marker } = this.properties;
      const baseStyle = mergeDefaults(highlighted && this.properties.highlightStyle.item, marker.getStyle());
      this.sizeScale.range = [marker.size, marker.maxSize];
      markerSelection.each((node, datum) => {
        this.updateMarkerStyle(node, marker, { datum, highlighted, xKey, yKey, sizeKey, labelKey }, baseStyle);
      });
      if (!highlighted) {
        this.properties.marker.markClean();
      }
    });
  }
  updateLabelSelection(opts) {
    return __async(this, null, function* () {
      var _a, _b;
      const placedLabels = this.properties.label.enabled ? (_b = (_a = this.chart) == null ? void 0 : _a.placeLabels().get(this)) != null ? _b : [] : [];
      return opts.labelSelection.update(
        placedLabels.map((v) => __spreadProps(__spreadValues({}, v.datum), {
          point: {
            x: v.x,
            y: v.y,
            size: v.datum.point.size
          }
        }))
      );
    });
  }
  updateLabelNodes(opts) {
    return __async(this, null, function* () {
      const { label } = this.properties;
      opts.labelSelection.each((text, datum) => {
        var _a, _b, _c, _d;
        text.text = datum.label.text;
        text.fill = label.color;
        text.x = (_b = (_a = datum.point) == null ? void 0 : _a.x) != null ? _b : 0;
        text.y = (_d = (_c = datum.point) == null ? void 0 : _c.y) != null ? _d : 0;
        text.fontStyle = label.fontStyle;
        text.fontWeight = label.fontWeight;
        text.fontSize = label.fontSize;
        text.fontFamily = label.fontFamily;
        text.textAlign = "left";
        text.textBaseline = "top";
      });
    });
  }
  getTooltipHtml(nodeDatum) {
    var _a;
    const xAxis = this.axes["x" /* X */];
    const yAxis = this.axes["y" /* Y */];
    if (!this.properties.isValid() || !xAxis || !yAxis) {
      return "";
    }
    const { xKey, yKey, sizeKey, labelKey, xName, yName, sizeName, labelName, marker, tooltip } = this.properties;
    const title = (_a = this.properties.title) != null ? _a : yName;
    const baseStyle = mergeDefaults(
      { fill: nodeDatum.fill, strokeWidth: this.getStrokeWidth(marker.strokeWidth) },
      marker.getStyle()
    );
    const { fill: color = "gray" } = this.getMarkerStyle(
      marker,
      { datum: nodeDatum, highlighted: false, xKey, yKey, sizeKey, labelKey },
      baseStyle
    );
    const {
      datum,
      xValue,
      yValue,
      sizeValue,
      label: { text: labelText }
    } = nodeDatum;
    const xString = sanitizeHtml(xAxis.formatDatum(xValue));
    const yString = sanitizeHtml(yAxis.formatDatum(yValue));
    let content = `<b>${sanitizeHtml(xName != null ? xName : xKey)}</b>: ${xString}<br><b>${sanitizeHtml(yName != null ? yName : yKey)}</b>: ${yString}`;
    if (sizeKey) {
      content += `<br><b>${sanitizeHtml(sizeName != null ? sizeName : sizeKey)}</b>: ${sanitizeHtml(String(sizeValue))}`;
    }
    if (labelKey) {
      content = `<b>${sanitizeHtml(labelName != null ? labelName : labelKey)}</b>: ${sanitizeHtml(labelText)}<br>` + content;
    }
    return tooltip.toTooltipHtml(
      { title, content, backgroundColor: color },
      {
        datum,
        xKey,
        xName,
        yKey,
        yName,
        sizeKey,
        sizeName,
        labelKey,
        labelName,
        title,
        color,
        seriesId: this.id
      }
    );
  }
  getLegendData() {
    var _a, _b;
    if (!((_a = this.data) == null ? void 0 : _a.length) || !this.properties.isValid()) {
      return [];
    }
    const { yKey, yName, title, marker, visible } = this.properties;
    const { shape, fill, stroke, fillOpacity, strokeOpacity, strokeWidth } = marker;
    return [
      {
        legendType: "category",
        id: this.id,
        itemId: yKey,
        seriesId: this.id,
        enabled: visible,
        label: {
          text: (_b = title != null ? title : yName) != null ? _b : yKey
        },
        marker: {
          shape,
          fill: fill != null ? fill : "rgba(0, 0, 0, 0)",
          stroke: stroke != null ? stroke : "rgba(0, 0, 0, 0)",
          fillOpacity: fillOpacity != null ? fillOpacity : 1,
          strokeOpacity: strokeOpacity != null ? strokeOpacity : 1,
          strokeWidth: strokeWidth != null ? strokeWidth : 0
        }
      }
    ];
  }
  animateEmptyUpdateReady({ markerSelections, labelSelections }) {
    markerScaleInAnimation(this, this.ctx.animationManager, markerSelections);
    seriesLabelFadeInAnimation(this, "labels", this.ctx.animationManager, labelSelections);
  }
  getDatumId(datum) {
    return createDatumId([`${datum.xValue}`, `${datum.yValue}`, datum.label.text]);
  }
  isLabelEnabled() {
    return this.properties.label.enabled;
  }
  nodeFactory() {
    return new Group();
  }
};
_BubbleSeries.className = "BubbleSeries";
_BubbleSeries.type = "bubble";
var BubbleSeries = _BubbleSeries;

// packages/ag-charts-community/src/chart/series/cartesian/bubbleSeriesModule.ts
({
  type: "series",
  optionsKey: "series[]",
  packageType: "community",
  chartTypes: ["cartesian"],
  identifier: "bubble",
  instanceConstructor: BubbleSeries,
  seriesDefaults: {
    axes: [
      { type: CARTESIAN_AXIS_TYPES.NUMBER, position: CARTESIAN_AXIS_POSITIONS.BOTTOM },
      { type: CARTESIAN_AXIS_TYPES.NUMBER, position: CARTESIAN_AXIS_POSITIONS.LEFT }
    ]
  },
  themeTemplate: {
    __extends__: EXTENDS_SERIES_DEFAULTS,
    tooltip: {
      position: {
        type: "node"
      }
    },
    marker: {
      __extends__: EXTENDS_CARTESIAN_MARKER_DEFAULTS,
      maxSize: 30,
      fillOpacity: 0.8
    },
    label: {
      enabled: false,
      fontStyle: void 0,
      fontWeight: void 0,
      fontSize: 12,
      fontFamily: DEFAULT_FONT_FAMILY,
      color: DEFAULT_LABEL_COLOUR
    }
  },
  paletteFactory: markerPaletteFactory
});

// packages/ag-charts-community/src/chart/series/cartesian/histogramSeriesProperties.ts
var HistogramSeriesProperties = class extends CartesianSeriesProperties {
  constructor() {
    super(...arguments);
    this.fillOpacity = 1;
    this.strokeWidth = 1;
    this.strokeOpacity = 1;
    this.lineDash = [0];
    this.lineDashOffset = 0;
    this.areaPlot = false;
    this.aggregation = "sum";
    this.shadow = new DropShadow();
    this.label = new Label$1();
    this.tooltip = new SeriesTooltip();
  }
};
__decorateClass([
  Validate(STRING)
], HistogramSeriesProperties.prototype, "xKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], HistogramSeriesProperties.prototype, "yKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], HistogramSeriesProperties.prototype, "xName", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], HistogramSeriesProperties.prototype, "yName", 2);
__decorateClass([
  Validate(COLOR_STRING, { optional: true })
], HistogramSeriesProperties.prototype, "fill", 2);
__decorateClass([
  Validate(RATIO)
], HistogramSeriesProperties.prototype, "fillOpacity", 2);
__decorateClass([
  Validate(COLOR_STRING, { optional: true })
], HistogramSeriesProperties.prototype, "stroke", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], HistogramSeriesProperties.prototype, "strokeWidth", 2);
__decorateClass([
  Validate(RATIO)
], HistogramSeriesProperties.prototype, "strokeOpacity", 2);
__decorateClass([
  Validate(LINE_DASH)
], HistogramSeriesProperties.prototype, "lineDash", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], HistogramSeriesProperties.prototype, "lineDashOffset", 2);
__decorateClass([
  Validate(BOOLEAN)
], HistogramSeriesProperties.prototype, "areaPlot", 2);
__decorateClass([
  Validate(ARRAY, { optional: true })
], HistogramSeriesProperties.prototype, "bins", 2);
__decorateClass([
  Validate(UNION(["count", "sum", "mean"], "a histogram aggregation"))
], HistogramSeriesProperties.prototype, "aggregation", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], HistogramSeriesProperties.prototype, "binCount", 2);
__decorateClass([
  Validate(OBJECT)
], HistogramSeriesProperties.prototype, "shadow", 2);
__decorateClass([
  Validate(OBJECT)
], HistogramSeriesProperties.prototype, "label", 2);
__decorateClass([
  Validate(OBJECT)
], HistogramSeriesProperties.prototype, "tooltip", 2);

// packages/ag-charts-community/src/chart/series/cartesian/histogramSeries.ts
var defaultBinCount = 10;
var _HistogramSeries = class _HistogramSeries extends CartesianSeries {
  constructor(moduleCtx) {
    super({
      moduleCtx,
      pickModes: [0 /* EXACT_SHAPE_MATCH */],
      datumSelectionGarbageCollection: false,
      animationResetFns: {
        datum: resetBarSelectionsFn,
        label: resetLabelFn
      }
    });
    this.properties = new HistogramSeriesProperties();
    this.calculatedBins = [];
  }
  // During processData phase, used to unify different ways of the user specifying
  // the bins. Returns bins in format[[min1, max1], [min2, max2], ... ].
  deriveBins(xDomain) {
    if (this.properties.binCount) {
      return this.calculateNiceBins(xDomain, this.properties.binCount);
    }
    const binStarts = ticks_default(xDomain[0], xDomain[1], defaultBinCount);
    const binSize = tickStep(xDomain[0], xDomain[1], defaultBinCount);
    const [firstBinEnd] = binStarts;
    const expandStartToBin = (n) => [n, n + binSize];
    return [[firstBinEnd - binSize, firstBinEnd], ...binStarts.map(expandStartToBin)];
  }
  calculateNiceBins(domain, binCount) {
    const startGuess = Math.floor(domain[0]);
    const stop = domain[1];
    const segments = binCount || 1;
    const { start, binSize } = this.calculateNiceStart(startGuess, stop, segments);
    return this.getBins(start, stop, binSize, segments);
  }
  getBins(start, stop, step, count2) {
    const bins = [];
    const precision = this.calculatePrecision(step);
    for (let i = 0; i < count2; i++) {
      const a = Math.round((start + i * step) * precision) / precision;
      let b = Math.round((start + (i + 1) * step) * precision) / precision;
      if (i === count2 - 1) {
        b = Math.max(b, stop);
      }
      bins[i] = [a, b];
    }
    return bins;
  }
  calculatePrecision(step) {
    let precision = 10;
    if (isReal(step) && step > 0) {
      while (step < 1) {
        precision *= 10;
        step *= 10;
      }
    }
    return precision;
  }
  calculateNiceStart(a, b, segments) {
    const binSize = Math.abs(b - a) / segments;
    const order = Math.floor(Math.log10(binSize));
    const magnitude = Math.pow(10, order);
    const start = Math.floor(a / magnitude) * magnitude;
    return {
      start,
      binSize
    };
  }
  processData(dataController) {
    return __async(this, null, function* () {
      var _a;
      const { xKey, yKey, areaPlot, aggregation } = this.properties;
      const props = [keyProperty(this, xKey, true), SORT_DOMAIN_GROUPS];
      if (yKey) {
        let aggProp = groupCount(this, "groupCount");
        if (aggregation === "count") ; else if (aggregation === "sum") {
          aggProp = groupSum(this, "groupAgg");
        } else if (aggregation === "mean") {
          aggProp = groupAverage(this, "groupAgg");
        }
        if (areaPlot) {
          aggProp = area(this, "groupAgg", aggProp);
        }
        props.push(valueProperty(this, yKey, true, { invalidValue: void 0 }), aggProp);
      } else {
        let aggProp = groupCount(this, "groupAgg");
        if (areaPlot) {
          aggProp = area(this, "groupAgg", aggProp);
        }
        props.push(aggProp);
      }
      const groupByFn = (dataSet) => {
        var _a2;
        const xExtent = fixNumericExtent(dataSet.domain.keys[0]);
        if (xExtent.length === 0) {
          dataSet.domain.groups = [];
          return () => [];
        }
        const bins = (_a2 = this.properties.bins) != null ? _a2 : this.deriveBins(xExtent);
        const binCount = bins.length;
        this.calculatedBins = [...bins];
        return (item) => {
          const xValue = item.keys[0];
          for (let i = 0; i < binCount; i++) {
            const nextBin = bins[i];
            if (xValue >= nextBin[0] && xValue < nextBin[1]) {
              return nextBin;
            }
            if (i === binCount - 1 && xValue <= nextBin[1]) {
              return nextBin;
            }
          }
          return [];
        };
      };
      if (!this.ctx.animationManager.isSkipped() && this.processedData) {
        props.push(diff(this.processedData, false));
      }
      yield this.requestDataModel(dataController, (_a = this.data) != null ? _a : [], {
        props,
        dataVisible: this.visible,
        groupByFn
      });
      this.animationState.transition("updateData");
    });
  }
  getSeriesDomain(direction) {
    var _a, _b, _c, _d;
    const { processedData, dataModel } = this;
    if (!processedData || !dataModel || !this.calculatedBins.length)
      return [];
    const yDomain = dataModel.getDomain(this, `groupAgg`, "aggregate", processedData);
    const xDomainMin = (_a = this.calculatedBins) == null ? void 0 : _a[0][0];
    const xDomainMax = (_d = this.calculatedBins) == null ? void 0 : _d[((_c = (_b = this.calculatedBins) == null ? void 0 : _b.length) != null ? _c : 0) - 1][1];
    if (direction === "x" /* X */) {
      return fixNumericExtent([xDomainMin, xDomainMax]);
    }
    return fixNumericExtent(yDomain);
  }
  createNodeData() {
    return __async(this, null, function* () {
      var _a;
      const {
        id: seriesId,
        axes,
        processedData,
        ctx: { callbackCache }
      } = this;
      const xAxis = axes["x" /* X */];
      const yAxis = axes["y" /* Y */];
      if (!this.visible || !xAxis || !yAxis || !processedData || processedData.type !== "grouped") {
        return [];
      }
      const { scale: xScale } = xAxis;
      const { scale: yScale } = yAxis;
      const { xKey, yKey, xName, yName, fill, stroke, strokeWidth } = this.properties;
      const {
        formatter: labelFormatter = (params) => String(params.value),
        fontStyle: labelFontStyle,
        fontWeight: labelFontWeight,
        fontSize: labelFontSize,
        fontFamily: labelFontFamily,
        color: labelColor
      } = this.properties.label;
      const nodeData = [];
      processedData.data.forEach((group2) => {
        var _a2;
        const {
          aggValues: [[negativeAgg, positiveAgg]] = [[0, 0]],
          datum,
          datum: { length: frequency },
          keys: domain,
          keys: [xDomainMin, xDomainMax]
        } = group2;
        const xMinPx = xScale.convert(xDomainMin);
        const xMaxPx = xScale.convert(xDomainMax);
        const total = negativeAgg + positiveAgg;
        const yZeroPx = yScale.convert(0);
        const yMaxPx = yScale.convert(total);
        const w = Math.abs(xMaxPx - xMinPx);
        const h = Math.abs(yMaxPx - yZeroPx);
        const x = Math.min(xMinPx, xMaxPx);
        const y = Math.min(yZeroPx, yMaxPx);
        const selectionDatumLabel = total !== 0 ? {
          text: (_a2 = callbackCache.call(labelFormatter, {
            value: total,
            datum,
            seriesId,
            xKey,
            yKey,
            xName,
            yName
          })) != null ? _a2 : String(total),
          fontStyle: labelFontStyle,
          fontWeight: labelFontWeight,
          fontSize: labelFontSize,
          fontFamily: labelFontFamily,
          fill: labelColor,
          x: x + w / 2,
          y: y + h / 2
        } : void 0;
        const nodeMidPoint = {
          x: x + w / 2,
          y: y + h / 2
        };
        nodeData.push({
          series: this,
          datum,
          // required by SeriesNodeDatum, but might not make sense here
          // since each selection is an aggregation of multiple data.
          aggregatedValue: total,
          frequency,
          domain,
          yKey,
          xKey,
          x,
          y,
          xValue: xMinPx,
          yValue: yMaxPx,
          width: w,
          height: h,
          midPoint: nodeMidPoint,
          fill,
          stroke,
          strokeWidth,
          label: selectionDatumLabel
        });
      });
      return [
        {
          itemId: (_a = this.properties.yKey) != null ? _a : this.id,
          nodeData,
          labelData: nodeData,
          scales: __superGet(_HistogramSeries.prototype, this, "calculateScaling").call(this),
          animationValid: true,
          visible: this.visible
        }
      ];
    });
  }
  nodeFactory() {
    return new Rect();
  }
  updateDatumSelection(opts) {
    return __async(this, null, function* () {
      const { nodeData, datumSelection } = opts;
      return datumSelection.update(
        nodeData,
        (rect) => {
          rect.tag = 0 /* Bin */;
          rect.crisp = true;
        },
        (datum) => datum.domain.join("_")
      );
    });
  }
  updateDatumNodes(opts) {
    return __async(this, null, function* () {
      const { isHighlight: isDatumHighlighted } = opts;
      const {
        fillOpacity: seriesFillOpacity,
        strokeOpacity,
        lineDash,
        lineDashOffset,
        shadow,
        highlightStyle: {
          item: {
            fill: highlightedFill,
            fillOpacity: highlightFillOpacity = seriesFillOpacity,
            stroke: highlightedStroke,
            strokeWidth: highlightedDatumStrokeWidth
          }
        }
      } = this.properties;
      opts.datumSelection.each((rect, datum, index) => {
        var _a, _b;
        const strokeWidth = isDatumHighlighted && highlightedDatumStrokeWidth !== void 0 ? highlightedDatumStrokeWidth : datum.strokeWidth;
        const fillOpacity = isDatumHighlighted ? highlightFillOpacity : seriesFillOpacity;
        rect.fill = (_a = isDatumHighlighted ? highlightedFill : void 0) != null ? _a : datum.fill;
        rect.stroke = (_b = isDatumHighlighted ? highlightedStroke : void 0) != null ? _b : datum.stroke;
        rect.fillOpacity = fillOpacity;
        rect.strokeOpacity = strokeOpacity;
        rect.strokeWidth = strokeWidth;
        rect.lineDash = lineDash;
        rect.lineDashOffset = lineDashOffset;
        rect.fillShadow = shadow;
        rect.zIndex = isDatumHighlighted ? Series.highlightedZIndex : index;
        rect.visible = datum.height > 0;
      });
    });
  }
  updateLabelSelection(opts) {
    return __async(this, null, function* () {
      const { labelData, labelSelection } = opts;
      return labelSelection.update(labelData, (text) => {
        text.tag = 1 /* Label */;
        text.pointerEvents = 1 /* None */;
        text.textAlign = "center";
        text.textBaseline = "middle";
      });
    });
  }
  updateLabelNodes(opts) {
    return __async(this, null, function* () {
      const labelEnabled = this.isLabelEnabled();
      opts.labelSelection.each((text, datum) => {
        const label = datum.label;
        if (label && labelEnabled) {
          text.text = label.text;
          text.x = label.x;
          text.y = label.y;
          text.fontStyle = label.fontStyle;
          text.fontWeight = label.fontWeight;
          text.fontSize = label.fontSize;
          text.fontFamily = label.fontFamily;
          text.fill = label.fill;
          text.visible = true;
        } else {
          text.visible = false;
        }
      });
    });
  }
  getTooltipHtml(nodeDatum) {
    const xAxis = this.axes["x" /* X */];
    const yAxis = this.axes["y" /* Y */];
    if (!this.properties.isValid() || !xAxis || !yAxis) {
      return "";
    }
    const { xKey, yKey, xName, yName, fill: color, aggregation, tooltip } = this.properties;
    const {
      aggregatedValue,
      frequency,
      domain: [rangeMin, rangeMax]
    } = nodeDatum;
    const title = `${sanitizeHtml(xName != null ? xName : xKey)}: ${xAxis.formatDatum(rangeMin)} - ${xAxis.formatDatum(rangeMax)}`;
    let content = yKey ? `<b>${sanitizeHtml(yName != null ? yName : yKey)} (${aggregation})</b>: ${yAxis.formatDatum(aggregatedValue)}<br>` : "";
    content += `<b>Frequency</b>: ${frequency}`;
    const defaults = {
      title,
      backgroundColor: color,
      content
    };
    return tooltip.toTooltipHtml(defaults, {
      datum: {
        data: nodeDatum.datum,
        aggregatedValue: nodeDatum.aggregatedValue,
        domain: nodeDatum.domain,
        frequency: nodeDatum.frequency
      },
      xKey,
      xName,
      yKey,
      yName,
      color,
      title,
      seriesId: this.id
    });
  }
  getLegendData(legendType) {
    var _a, _b;
    if (!((_a = this.data) == null ? void 0 : _a.length) || legendType !== "category") {
      return [];
    }
    const { xKey, yName, fill, fillOpacity, stroke, strokeWidth, strokeOpacity, visible } = this.properties;
    return [
      {
        legendType: "category",
        id: this.id,
        itemId: xKey,
        seriesId: this.id,
        enabled: visible,
        label: {
          text: (_b = yName != null ? yName : xKey) != null ? _b : "Frequency"
        },
        marker: {
          fill: fill != null ? fill : "rgba(0, 0, 0, 0)",
          stroke: stroke != null ? stroke : "rgba(0, 0, 0, 0)",
          fillOpacity,
          strokeOpacity,
          strokeWidth
        }
      }
    ];
  }
  animateEmptyUpdateReady({ datumSelections, labelSelections }) {
    const fns = prepareBarAnimationFunctions(collapsedStartingBarPosition(true, this.axes));
    fromToMotion(this.id, "datums", this.ctx.animationManager, datumSelections, fns);
    seriesLabelFadeInAnimation(this, "labels", this.ctx.animationManager, labelSelections);
  }
  animateWaitingUpdateReady(data) {
    var _a, _b;
    const diff2 = (_b = (_a = this.processedData) == null ? void 0 : _a.reduced) == null ? void 0 : _b.diff;
    const fns = prepareBarAnimationFunctions(collapsedStartingBarPosition(true, this.axes));
    fromToMotion(
      this.id,
      "datums",
      this.ctx.animationManager,
      data.datumSelections,
      fns,
      (_, datum) => this.getDatumId(datum),
      diff2
    );
    seriesLabelFadeInAnimation(this, "labels", this.ctx.animationManager, data.labelSelections);
  }
  getDatumId(datum) {
    return createDatumId(datum.domain.map((d) => `${d}`));
  }
  isLabelEnabled() {
    return this.properties.label.enabled;
  }
};
_HistogramSeries.className = "HistogramSeries";
_HistogramSeries.type = "histogram";
var HistogramSeries = _HistogramSeries;

// packages/ag-charts-community/src/chart/series/cartesian/histogramSeriesModule.ts
({
  type: "series",
  optionsKey: "series[]",
  packageType: "community",
  chartTypes: ["cartesian"],
  identifier: "histogram",
  instanceConstructor: HistogramSeries,
  seriesDefaults: {
    axes: [
      { type: CARTESIAN_AXIS_TYPES.NUMBER, position: CARTESIAN_AXIS_POSITIONS.BOTTOM },
      { type: CARTESIAN_AXIS_TYPES.NUMBER, position: CARTESIAN_AXIS_POSITIONS.LEFT }
    ]
  },
  themeTemplate: {
    __extends__: EXTENDS_SERIES_DEFAULTS,
    strokeWidth: 1,
    fillOpacity: 1,
    strokeOpacity: 1,
    lineDash: [0],
    lineDashOffset: 0,
    label: {
      enabled: false,
      fontStyle: void 0,
      fontWeight: void 0,
      fontSize: 12,
      fontFamily: DEFAULT_FONT_FAMILY,
      color: DEFAULT_INSIDE_SERIES_LABEL_COLOUR,
      formatter: void 0
    },
    shadow: {
      enabled: false,
      color: DEFAULT_SHADOW_COLOUR,
      xOffset: 3,
      yOffset: 3,
      blur: 5
    }
  },
  paletteFactory: ({ takeColors }) => {
    const {
      fills: [fill],
      strokes: [stroke]
    } = takeColors(1);
    return { fill, stroke };
  }
});

// packages/ag-charts-community/src/chart/series/cartesian/lineSeriesProperties.ts
var LineSeriesProperties = class extends CartesianSeriesProperties {
  constructor() {
    super(...arguments);
    this.stroke = "#874349";
    this.strokeWidth = 2;
    this.strokeOpacity = 1;
    this.lineDash = [0];
    this.lineDashOffset = 0;
    this.marker = new SeriesMarker();
    this.label = new Label$1();
    this.tooltip = new SeriesTooltip();
    this.connectMissingData = false;
  }
};
__decorateClass([
  Validate(STRING)
], LineSeriesProperties.prototype, "xKey", 2);
__decorateClass([
  Validate(STRING)
], LineSeriesProperties.prototype, "yKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], LineSeriesProperties.prototype, "xName", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], LineSeriesProperties.prototype, "yName", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], LineSeriesProperties.prototype, "title", 2);
__decorateClass([
  Validate(COLOR_STRING)
], LineSeriesProperties.prototype, "stroke", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], LineSeriesProperties.prototype, "strokeWidth", 2);
__decorateClass([
  Validate(RATIO)
], LineSeriesProperties.prototype, "strokeOpacity", 2);
__decorateClass([
  Validate(LINE_DASH)
], LineSeriesProperties.prototype, "lineDash", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], LineSeriesProperties.prototype, "lineDashOffset", 2);
__decorateClass([
  Validate(OBJECT)
], LineSeriesProperties.prototype, "marker", 2);
__decorateClass([
  Validate(OBJECT)
], LineSeriesProperties.prototype, "label", 2);
__decorateClass([
  Validate(OBJECT)
], LineSeriesProperties.prototype, "tooltip", 2);
__decorateClass([
  Validate(BOOLEAN)
], LineSeriesProperties.prototype, "connectMissingData", 2);

// packages/ag-charts-community/src/chart/series/cartesian/lineSeries.ts
var _LineSeries = class _LineSeries extends CartesianSeries {
  constructor(moduleCtx) {
    super({
      moduleCtx,
      hasMarkers: true,
      pickModes: [
        2 /* NEAREST_BY_MAIN_CATEGORY_AXIS_FIRST */,
        3 /* NEAREST_NODE */,
        0 /* EXACT_SHAPE_MATCH */
      ],
      markerSelectionGarbageCollection: false,
      animationResetFns: {
        path: buildResetPathFn({ getOpacity: () => this.getOpacity() }),
        label: resetLabelFn,
        marker: (node, datum) => __spreadValues(__spreadValues({}, resetMarkerFn()), resetMarkerPositionFn(node, datum))
      }
    });
    this.properties = new LineSeriesProperties();
  }
  processData(dataController) {
    return __async(this, null, function* () {
      if (!this.properties.isValid() || this.data == null) {
        return;
      }
      const { xKey, yKey } = this.properties;
      const animationEnabled = !this.ctx.animationManager.isSkipped();
      const { isContinuousX, isContinuousY } = this.isContinuous();
      const props = [];
      if (!isContinuousX) {
        props.push(keyProperty(this, xKey, isContinuousX, { id: "xKey" }));
        if (animationEnabled && this.processedData) {
          props.push(diff(this.processedData));
        }
      }
      if (animationEnabled) {
        props.push(animationValidation(this, isContinuousX ? ["xValue"] : []));
      }
      props.push(
        valueProperty(this, xKey, isContinuousX, { id: "xValue" }),
        valueProperty(this, yKey, isContinuousY, { id: "yValue", invalidValue: void 0 })
      );
      yield this.requestDataModel(dataController, this.data, { props });
      this.animationState.transition("updateData");
    });
  }
  getSeriesDomain(direction) {
    const { axes, dataModel, processedData } = this;
    if (!processedData || !dataModel)
      return [];
    const xAxis = axes["x" /* X */];
    const yAxis = axes["y" /* Y */];
    const xDef = dataModel.resolveProcessedDataDefById(this, `xValue`);
    if (direction === "x" /* X */) {
      const domain = dataModel.getDomain(this, `xValue`, "value", processedData);
      if ((xDef == null ? void 0 : xDef.def.type) === "value" && xDef.def.valueType === "category") {
        return domain;
      }
      return fixNumericExtent(extent$4(domain), xAxis);
    } else {
      const domain = dataModel.getDomain(this, `yValue`, "value", processedData);
      return fixNumericExtent(domain, yAxis);
    }
  }
  createNodeData() {
    return __async(this, null, function* () {
      var _a, _b, _c;
      const { processedData, dataModel, axes } = this;
      const xAxis = axes["x" /* X */];
      const yAxis = axes["y" /* Y */];
      if (!processedData || !dataModel || !xAxis || !yAxis) {
        return [];
      }
      const { xKey, yKey, xName, yName, marker, label, connectMissingData } = this.properties;
      const xScale = xAxis.scale;
      const yScale = yAxis.scale;
      const xOffset = ((_a = xScale.bandwidth) != null ? _a : 0) / 2;
      const yOffset = ((_b = yScale.bandwidth) != null ? _b : 0) / 2;
      const nodeData = [];
      const size = marker.enabled ? marker.size : 0;
      const xIdx = dataModel.resolveProcessedDataIndexById(this, `xValue`).index;
      const yIdx = dataModel.resolveProcessedDataIndexById(this, `yValue`).index;
      let moveTo = true;
      let nextPoint;
      for (let i = 0; i < processedData.data.length; i++) {
        const { datum, values } = nextPoint != null ? nextPoint : processedData.data[i];
        const xDatum = values[xIdx];
        const yDatum = values[yIdx];
        if (yDatum === void 0) {
          moveTo = !connectMissingData;
        } else {
          const x = xScale.convert(xDatum) + xOffset;
          if (isNaN(x)) {
            moveTo = !connectMissingData;
            nextPoint = void 0;
            continue;
          }
          nextPoint = ((_c = processedData.data[i + 1]) == null ? void 0 : _c.values[yIdx]) === void 0 ? void 0 : processedData.data[i + 1];
          const y = yScale.convert(yDatum) + yOffset;
          const labelText = this.getLabelText(
            label,
            { value: yDatum, datum, xKey, yKey, xName, yName },
            (value) => isNumber2(value) ? value.toFixed(2) : String(value)
          );
          nodeData.push({
            series: this,
            datum,
            yKey,
            xKey,
            point: { x, y, moveTo, size },
            midPoint: { x, y },
            yValue: yDatum,
            xValue: xDatum,
            capDefaults: { lengthRatioMultiplier: this.properties.marker.getDiameter(), lengthMax: Infinity },
            label: labelText ? {
              text: labelText,
              fontStyle: label.fontStyle,
              fontWeight: label.fontWeight,
              fontSize: label.fontSize,
              fontFamily: label.fontFamily,
              textAlign: "center",
              textBaseline: "bottom",
              fill: label.color
            } : void 0
          });
          moveTo = false;
        }
      }
      return [
        {
          itemId: yKey,
          nodeData,
          labelData: nodeData,
          scales: __superGet(_LineSeries.prototype, this, "calculateScaling").call(this),
          visible: this.visible
        }
      ];
    });
  }
  isPathOrSelectionDirty() {
    return this.properties.marker.isDirty();
  }
  markerFactory() {
    const { shape } = this.properties.marker;
    const MarkerShape = getMarker$1(shape);
    return new MarkerShape();
  }
  updatePathNodes(opts) {
    return __async(this, null, function* () {
      const {
        paths: [lineNode],
        opacity,
        visible,
        animationEnabled
      } = opts;
      lineNode.setProperties({
        fill: void 0,
        lineJoin: "round",
        pointerEvents: 1 /* None */,
        opacity,
        stroke: this.properties.stroke,
        strokeWidth: this.getStrokeWidth(this.properties.strokeWidth),
        strokeOpacity: this.properties.strokeOpacity,
        lineDash: this.properties.lineDash,
        lineDashOffset: this.properties.lineDashOffset
      });
      if (!animationEnabled) {
        lineNode.visible = visible;
      }
      updateClipPath(this, lineNode);
    });
  }
  updateMarkerSelection(opts) {
    return __async(this, null, function* () {
      let { nodeData } = opts;
      const { markerSelection } = opts;
      const { shape, enabled } = this.properties.marker;
      nodeData = shape && enabled ? nodeData : [];
      if (this.properties.marker.isDirty()) {
        markerSelection.clear();
        markerSelection.cleanup();
      }
      return markerSelection.update(nodeData, void 0, (datum) => this.getDatumId(datum));
    });
  }
  updateMarkerNodes(opts) {
    return __async(this, null, function* () {
      const { markerSelection, isHighlight: highlighted } = opts;
      const { xKey, yKey, stroke, strokeWidth, strokeOpacity, marker, highlightStyle } = this.properties;
      const baseStyle = mergeDefaults(highlighted && highlightStyle.item, marker.getStyle(), {
        stroke,
        strokeWidth,
        strokeOpacity
      });
      const applyTranslation = this.ctx.animationManager.isSkipped();
      markerSelection.each((node, datum) => {
        this.updateMarkerStyle(node, marker, { datum, highlighted, xKey, yKey }, baseStyle, { applyTranslation });
      });
      if (!highlighted) {
        marker.markClean();
      }
    });
  }
  updateLabelSelection(opts) {
    return __async(this, null, function* () {
      return opts.labelSelection.update(this.isLabelEnabled() ? opts.labelData : []);
    });
  }
  updateLabelNodes(opts) {
    return __async(this, null, function* () {
      const { enabled, fontStyle, fontWeight, fontSize, fontFamily, color } = this.properties.label;
      opts.labelSelection.each((text, datum) => {
        const { point, label } = datum;
        if (datum && label && enabled) {
          text.fontStyle = fontStyle;
          text.fontWeight = fontWeight;
          text.fontSize = fontSize;
          text.fontFamily = fontFamily;
          text.textAlign = label.textAlign;
          text.textBaseline = label.textBaseline;
          text.text = label.text;
          text.x = point.x;
          text.y = point.y - 10;
          text.fill = color;
          text.visible = true;
        } else {
          text.visible = false;
        }
      });
    });
  }
  getTooltipHtml(nodeDatum) {
    var _a;
    const xAxis = this.axes["x" /* X */];
    const yAxis = this.axes["y" /* Y */];
    if (!this.properties.isValid() || !xAxis || !yAxis) {
      return "";
    }
    const { xKey, yKey, xName, yName, strokeWidth, marker, tooltip } = this.properties;
    const { datum, xValue, yValue } = nodeDatum;
    const xString = xAxis.formatDatum(xValue);
    const yString = yAxis.formatDatum(yValue);
    const title = sanitizeHtml((_a = this.properties.title) != null ? _a : yName);
    const content = sanitizeHtml(xString + ": " + yString);
    const baseStyle = mergeDefaults({ fill: marker.stroke }, marker.getStyle(), { strokeWidth });
    const { fill: color } = this.getMarkerStyle(
      marker,
      { datum: nodeDatum, xKey, yKey, highlighted: false },
      baseStyle
    );
    return tooltip.toTooltipHtml(
      { title, content, backgroundColor: color },
      __spreadValues({
        datum,
        xKey,
        xName,
        yKey,
        yName,
        title,
        color,
        seriesId: this.id
      }, this.getModuleTooltipParams())
    );
  }
  getLegendData(legendType) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i;
    if (!(((_a = this.data) == null ? void 0 : _a.length) && this.properties.isValid() && legendType === "category")) {
      return [];
    }
    const { yKey, yName, stroke, strokeOpacity, strokeWidth, lineDash, title, marker, visible } = this.properties;
    const color0 = "rgba(0, 0, 0, 0)";
    return [
      {
        legendType: "category",
        id: this.id,
        itemId: yKey,
        seriesId: this.id,
        enabled: visible,
        label: {
          text: (_b = title != null ? title : yName) != null ? _b : yKey
        },
        marker: {
          shape: marker.shape,
          fill: (_c = marker.fill) != null ? _c : color0,
          stroke: (_e = (_d = marker.stroke) != null ? _d : stroke) != null ? _e : color0,
          fillOpacity: (_f = marker.fillOpacity) != null ? _f : 1,
          strokeOpacity: (_h = (_g = marker.strokeOpacity) != null ? _g : strokeOpacity) != null ? _h : 1,
          strokeWidth: (_i = marker.strokeWidth) != null ? _i : 0,
          enabled: marker.enabled
        },
        line: {
          stroke: stroke != null ? stroke : color0,
          strokeOpacity,
          strokeWidth,
          lineDash
        }
      }
    ];
  }
  updatePaths(opts) {
    return __async(this, null, function* () {
      this.updateLinePaths([opts.paths], [opts.contextData]);
    });
  }
  updateLinePaths(paths, contextData) {
    contextData.forEach(({ nodeData }, contextDataIndex) => {
      const [lineNode] = paths[contextDataIndex];
      const { path: linePath } = lineNode;
      linePath.clear({ trackChanges: true });
      for (const data of nodeData) {
        if (data.point.moveTo) {
          linePath.moveTo(data.point.x, data.point.y);
        } else {
          linePath.lineTo(data.point.x, data.point.y);
        }
      }
      lineNode.checkPathDirty();
    });
  }
  animateEmptyUpdateReady(animationData) {
    const { markerSelections, labelSelections, annotationSelections, contextData, paths } = animationData;
    const { animationManager } = this.ctx;
    this.updateLinePaths(paths, contextData);
    pathSwipeInAnimation(this, animationManager, paths.flat());
    resetMotion(markerSelections, resetMarkerPositionFn);
    markerSwipeScaleInAnimation(this, animationManager, markerSelections);
    seriesLabelFadeInAnimation(this, "labels", animationManager, labelSelections);
    seriesLabelFadeInAnimation(this, "annotations", animationManager, annotationSelections);
  }
  animateReadyResize(animationData) {
    const { contextData, paths } = animationData;
    this.updateLinePaths(paths, contextData);
    super.animateReadyResize(animationData);
  }
  animateWaitingUpdateReady(animationData) {
    var _a, _b;
    const { animationManager } = this.ctx;
    const { markerSelections, labelSelections, annotationSelections, contextData, paths, previousContextData } = animationData;
    super.resetAllAnimation(animationData);
    if (contextData.length === 0 || !previousContextData || previousContextData.length === 0) {
      animationManager.skipCurrentBatch();
      this.updateLinePaths(paths, contextData);
      return;
    }
    const [path] = paths;
    const [newData] = contextData;
    const [oldData] = previousContextData;
    const fns = prepareLinePathAnimation(newData, oldData, (_b = (_a = this.processedData) == null ? void 0 : _a.reduced) == null ? void 0 : _b.diff);
    if (fns === void 0) {
      animationManager.skipCurrentBatch();
      this.updateLinePaths(paths, contextData);
      return;
    }
    fromToMotion(this.id, "marker", animationManager, markerSelections, fns.marker);
    fromToMotion(this.id, "path_properties", animationManager, path, fns.pathProperties);
    pathMotion(this.id, "path_update", animationManager, path, fns.path);
    if (fns.hasMotion) {
      seriesLabelFadeInAnimation(this, "labels", animationManager, labelSelections);
      seriesLabelFadeInAnimation(this, "annotations", animationManager, annotationSelections);
    }
  }
  getDatumId(datum) {
    return createDatumId([`${datum.xValue}`]);
  }
  isLabelEnabled() {
    return this.properties.label.enabled;
  }
  getBandScalePadding() {
    return { inner: 1, outer: 0.1 };
  }
  nodeFactory() {
    return new Group();
  }
};
_LineSeries.className = "LineSeries";
_LineSeries.type = "line";

// packages/ag-charts-community/src/chart/series/cartesian/scatterSeriesProperties.ts
var ScatterSeriesProperties = class extends CartesianSeriesProperties {
  constructor() {
    super(...arguments);
    this.colorRange = ["#ffff00", "#00ff00", "#0000ff"];
    this.marker = new SeriesMarker();
    this.label = new Label$1();
    this.tooltip = new SeriesTooltip();
  }
};
__decorateClass([
  Validate(STRING)
], ScatterSeriesProperties.prototype, "xKey", 2);
__decorateClass([
  Validate(STRING)
], ScatterSeriesProperties.prototype, "yKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], ScatterSeriesProperties.prototype, "labelKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], ScatterSeriesProperties.prototype, "colorKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], ScatterSeriesProperties.prototype, "xName", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], ScatterSeriesProperties.prototype, "yName", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], ScatterSeriesProperties.prototype, "labelName", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], ScatterSeriesProperties.prototype, "colorName", 2);
__decorateClass([
  Validate(NUMBER_ARRAY, { optional: true })
], ScatterSeriesProperties.prototype, "colorDomain", 2);
__decorateClass([
  Validate(COLOR_STRING_ARRAY)
], ScatterSeriesProperties.prototype, "colorRange", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], ScatterSeriesProperties.prototype, "title", 2);
__decorateClass([
  Validate(OBJECT)
], ScatterSeriesProperties.prototype, "marker", 2);
__decorateClass([
  Validate(OBJECT)
], ScatterSeriesProperties.prototype, "label", 2);
__decorateClass([
  Validate(OBJECT)
], ScatterSeriesProperties.prototype, "tooltip", 2);

// packages/ag-charts-community/src/chart/series/cartesian/scatterSeries.ts
var _ScatterSeries = class _ScatterSeries extends CartesianSeries {
  constructor(moduleCtx) {
    super({
      moduleCtx,
      pickModes: [
        2 /* NEAREST_BY_MAIN_CATEGORY_AXIS_FIRST */,
        3 /* NEAREST_NODE */,
        0 /* EXACT_SHAPE_MATCH */
      ],
      pathsPerSeries: 0,
      hasMarkers: true,
      markerSelectionGarbageCollection: false,
      animationResetFns: {
        marker: resetMarkerFn,
        label: resetLabelFn
      }
    });
    this.properties = new ScatterSeriesProperties();
    this.colorScale = new ColorScale();
  }
  processData(dataController) {
    return __async(this, null, function* () {
      var _a;
      if (!this.properties.isValid() || this.data == null) {
        return;
      }
      const { isContinuousX, isContinuousY } = this.isContinuous();
      const { xKey, yKey, labelKey, colorKey, colorDomain, colorRange } = this.properties;
      const { dataModel, processedData } = yield this.requestDataModel(dataController, this.data, {
        props: [
          keyProperty(this, xKey, isContinuousX, { id: "xKey-raw" }),
          keyProperty(this, yKey, isContinuousY, { id: "yKey-raw" }),
          ...labelKey ? [keyProperty(this, labelKey, false, { id: `labelKey-raw` })] : [],
          valueProperty(this, xKey, isContinuousX, { id: `xValue` }),
          valueProperty(this, yKey, isContinuousY, { id: `yValue` }),
          ...colorKey ? [valueProperty(this, colorKey, true, { id: `colorValue` })] : [],
          ...labelKey ? [valueProperty(this, labelKey, false, { id: `labelValue` })] : []
        ],
        dataVisible: this.visible
      });
      if (colorKey) {
        const colorKeyIdx = dataModel.resolveProcessedDataIndexById(this, `colorValue`).index;
        this.colorScale.domain = (_a = colorDomain != null ? colorDomain : processedData.domain.values[colorKeyIdx]) != null ? _a : [];
        this.colorScale.range = colorRange;
        this.colorScale.update();
      }
      this.animationState.transition("updateData");
    });
  }
  getSeriesDomain(direction) {
    const { dataModel, processedData } = this;
    if (!processedData || !dataModel)
      return [];
    const id = direction === "x" /* X */ ? `xValue` : `yValue`;
    const dataDef = dataModel.resolveProcessedDataDefById(this, id);
    const domain = dataModel.getDomain(this, id, "value", processedData);
    if ((dataDef == null ? void 0 : dataDef.def.type) === "value" && (dataDef == null ? void 0 : dataDef.def.valueType) === "category") {
      return domain;
    }
    const axis = this.axes[direction];
    return fixNumericExtent(extent$4(domain), axis);
  }
  createNodeData() {
    return __async(this, null, function* () {
      var _a, _b, _c;
      const { axes, dataModel, processedData, colorScale } = this;
      const { xKey, yKey, labelKey, colorKey, xName, yName, labelName, marker, label, visible } = this.properties;
      const xAxis = axes["x" /* X */];
      const yAxis = axes["y" /* Y */];
      if (!(dataModel && processedData && visible && xAxis && yAxis)) {
        return [];
      }
      const xDataIdx = dataModel.resolveProcessedDataIndexById(this, `xValue`).index;
      const yDataIdx = dataModel.resolveProcessedDataIndexById(this, `yValue`).index;
      const colorDataIdx = colorKey ? dataModel.resolveProcessedDataIndexById(this, `colorValue`).index : -1;
      const labelDataIdx = labelKey ? dataModel.resolveProcessedDataIndexById(this, `labelValue`).index : -1;
      const xScale = xAxis.scale;
      const yScale = yAxis.scale;
      const xOffset = ((_a = xScale.bandwidth) != null ? _a : 0) / 2;
      const yOffset = ((_b = yScale.bandwidth) != null ? _b : 0) / 2;
      const nodeData = [];
      const font = label.getFont();
      for (const { values, datum } of (_c = processedData.data) != null ? _c : []) {
        const xDatum = values[xDataIdx];
        const yDatum = values[yDataIdx];
        const x = xScale.convert(xDatum) + xOffset;
        const y = yScale.convert(yDatum) + yOffset;
        const labelText = this.getLabelText(label, {
          value: labelKey ? values[labelDataIdx] : yDatum,
          datum,
          xKey,
          yKey,
          labelKey,
          xName,
          yName,
          labelName
        });
        const size = HdpiCanvas.getTextSize(labelText, font);
        const fill = colorKey ? colorScale.convert(values[colorDataIdx]) : void 0;
        nodeData.push({
          series: this,
          itemId: yKey,
          yKey,
          xKey,
          datum,
          xValue: xDatum,
          yValue: yDatum,
          capDefaults: { lengthRatioMultiplier: marker.getDiameter(), lengthMax: Infinity },
          point: { x, y, size: marker.size },
          midPoint: { x, y },
          fill,
          label: __spreadValues({ text: labelText }, size)
        });
      }
      return [
        {
          itemId: yKey,
          nodeData,
          labelData: nodeData,
          scales: __superGet(_ScatterSeries.prototype, this, "calculateScaling").call(this),
          visible: this.visible
        }
      ];
    });
  }
  isPathOrSelectionDirty() {
    return this.properties.marker.isDirty();
  }
  getLabelData() {
    var _a;
    return (_a = this.contextNodeData) == null ? void 0 : _a.reduce((r, n) => r.concat(n.labelData), []);
  }
  markerFactory() {
    const { shape } = this.properties.marker;
    const MarkerShape = getMarker$1(shape);
    return new MarkerShape();
  }
  updateMarkerSelection(opts) {
    return __async(this, null, function* () {
      const { nodeData, markerSelection } = opts;
      if (this.properties.marker.isDirty()) {
        markerSelection.clear();
        markerSelection.cleanup();
      }
      return markerSelection.update(this.properties.marker.enabled ? nodeData : []);
    });
  }
  updateMarkerNodes(opts) {
    return __async(this, null, function* () {
      const { markerSelection, isHighlight: highlighted } = opts;
      const { xKey, yKey, labelKey, marker, highlightStyle } = this.properties;
      const baseStyle = mergeDefaults(highlighted && highlightStyle.item, marker.getStyle());
      markerSelection.each((node, datum) => {
        this.updateMarkerStyle(node, marker, { datum, highlighted, xKey, yKey, labelKey }, baseStyle);
      });
      if (!highlighted) {
        marker.markClean();
      }
    });
  }
  updateLabelSelection(opts) {
    return __async(this, null, function* () {
      var _a, _b;
      const placedLabels = this.isLabelEnabled() ? (_b = (_a = this.chart) == null ? void 0 : _a.placeLabels().get(this)) != null ? _b : [] : [];
      return opts.labelSelection.update(
        placedLabels.map(({ datum, x, y }) => __spreadProps(__spreadValues({}, datum), {
          point: { x, y, size: datum.point.size }
        })),
        (text) => {
          text.pointerEvents = 1 /* None */;
        }
      );
    });
  }
  updateLabelNodes(opts) {
    return __async(this, null, function* () {
      const { label } = this.properties;
      opts.labelSelection.each((text, datum) => {
        var _a, _b, _c, _d;
        text.text = datum.label.text;
        text.fill = label.color;
        text.x = (_b = (_a = datum.point) == null ? void 0 : _a.x) != null ? _b : 0;
        text.y = (_d = (_c = datum.point) == null ? void 0 : _c.y) != null ? _d : 0;
        text.fontStyle = label.fontStyle;
        text.fontWeight = label.fontWeight;
        text.fontSize = label.fontSize;
        text.fontFamily = label.fontFamily;
        text.textAlign = "left";
        text.textBaseline = "top";
      });
    });
  }
  getTooltipHtml(nodeDatum) {
    const xAxis = this.axes["x" /* X */];
    const yAxis = this.axes["y" /* Y */];
    if (!this.properties.isValid() || !xAxis || !yAxis) {
      return "";
    }
    const { xKey, yKey, labelKey, xName, yName, labelName, title = yName, marker, tooltip } = this.properties;
    const { datum, xValue, yValue, label } = nodeDatum;
    const baseStyle = mergeDefaults(
      { fill: nodeDatum.fill, strokeWidth: this.getStrokeWidth(marker.strokeWidth) },
      marker.getStyle()
    );
    const { fill: color = "gray" } = this.getMarkerStyle(
      marker,
      { datum: nodeDatum, highlighted: false, xKey, yKey, labelKey },
      baseStyle
    );
    const xString = sanitizeHtml(xAxis.formatDatum(xValue));
    const yString = sanitizeHtml(yAxis.formatDatum(yValue));
    let content = `<b>${sanitizeHtml(xName != null ? xName : xKey)}</b>: ${xString}<br><b>${sanitizeHtml(yName != null ? yName : yKey)}</b>: ${yString}`;
    if (labelKey) {
      content = `<b>${sanitizeHtml(labelName != null ? labelName : labelKey)}</b>: ${sanitizeHtml(label.text)}<br>` + content;
    }
    return tooltip.toTooltipHtml(
      { title, content, backgroundColor: color },
      __spreadValues({
        datum,
        xKey,
        xName,
        yKey,
        yName,
        labelKey,
        labelName,
        title,
        color,
        seriesId: this.id
      }, this.getModuleTooltipParams())
    );
  }
  getLegendData(legendType) {
    var _a, _b, _c, _d, _e, _f;
    const { yKey, yName, title, marker, visible } = this.properties;
    const { fill, stroke, fillOpacity, strokeOpacity, strokeWidth } = marker;
    if (!((_a = this.data) == null ? void 0 : _a.length) || !this.properties.isValid() || legendType !== "category") {
      return [];
    }
    return [
      {
        legendType: "category",
        id: this.id,
        itemId: yKey,
        seriesId: this.id,
        enabled: visible,
        label: {
          text: (_b = title != null ? title : yName) != null ? _b : yKey
        },
        marker: {
          shape: marker.shape,
          fill: (_d = (_c = marker.fill) != null ? _c : fill) != null ? _d : "rgba(0, 0, 0, 0)",
          stroke: (_f = (_e = marker.stroke) != null ? _e : stroke) != null ? _f : "rgba(0, 0, 0, 0)",
          fillOpacity: fillOpacity != null ? fillOpacity : 1,
          strokeOpacity: strokeOpacity != null ? strokeOpacity : 1,
          strokeWidth: strokeWidth != null ? strokeWidth : 0
        }
      }
    ];
  }
  animateEmptyUpdateReady(data) {
    const { markerSelections, labelSelections, annotationSelections } = data;
    markerScaleInAnimation(this, this.ctx.animationManager, markerSelections);
    seriesLabelFadeInAnimation(this, "labels", this.ctx.animationManager, labelSelections);
    seriesLabelFadeInAnimation(this, "annotations", this.ctx.animationManager, annotationSelections);
  }
  isLabelEnabled() {
    return this.properties.label.enabled;
  }
  nodeFactory() {
    return new Group();
  }
};
_ScatterSeries.className = "ScatterSeries";
_ScatterSeries.type = "scatter";
var ScatterSeries = _ScatterSeries;

// packages/ag-charts-community/src/chart/series/cartesian/scatterSeriesModule.ts
({
  type: "series",
  optionsKey: "series[]",
  packageType: "community",
  chartTypes: ["cartesian"],
  identifier: "scatter",
  instanceConstructor: ScatterSeries,
  seriesDefaults: {
    axes: [
      { type: CARTESIAN_AXIS_TYPES.NUMBER, position: CARTESIAN_AXIS_POSITIONS.BOTTOM },
      { type: CARTESIAN_AXIS_TYPES.NUMBER, position: CARTESIAN_AXIS_POSITIONS.LEFT }
    ]
  },
  themeTemplate: {
    __extends__: EXTENDS_SERIES_DEFAULTS,
    tooltip: {
      position: {
        type: "node"
      }
    },
    marker: {
      __extends__: EXTENDS_CARTESIAN_MARKER_DEFAULTS,
      fillOpacity: 0.8
    },
    label: {
      enabled: false,
      fontStyle: void 0,
      fontWeight: void 0,
      fontSize: 12,
      fontFamily: DEFAULT_FONT_FAMILY,
      color: DEFAULT_LABEL_COLOUR
    }
  },
  enterpriseThemeTemplate: {
    errorBar: {
      cap: {
        lengthRatio: 1
      }
    }
  },
  paletteFactory: markerPaletteFactory
});

// packages/ag-charts-community/src/chart/themes/defaultColors.ts
var DEFAULT_FILLS = {
  BLUE: "#5090dc",
  ORANGE: "#ffa03a",
  GREEN: "#459d55",
  CYAN: "#34bfe1",
  YELLOW: "#e1cc00",
  VIOLET: "#9669cb",
  GRAY: "#b5b5b5",
  MAGENTA: "#bd5aa7",
  BROWN: "#8a6224",
  RED: "#ef5452"
};
var DEFAULT_STROKES = {
  BLUE: "#2b5c95",
  ORANGE: "#cc6f10",
  GREEN: "#1e652e",
  CYAN: "#18859e",
  YELLOW: "#a69400",
  VIOLET: "#603c88",
  GRAY: "#575757",
  MAGENTA: "#7d2f6d",
  BROWN: "#4f3508",
  RED: "#a82529"
};

// packages/ag-charts-community/src/chart/series/polar/pieSeriesProperties.ts
var PieTitle = class extends Caption {
  constructor() {
    super(...arguments);
    this.showInLegend = false;
  }
};
__decorateClass([
  Validate(BOOLEAN)
], PieTitle.prototype, "showInLegend", 2);
var DoughnutInnerLabel = class extends Label$1 {
  constructor() {
    super(...arguments);
    this.margin = 2;
  }
  set(properties, _reset) {
    return super.set(properties);
  }
};
__decorateClass([
  Validate(STRING)
], DoughnutInnerLabel.prototype, "text", 2);
__decorateClass([
  Validate(NUMBER)
], DoughnutInnerLabel.prototype, "margin", 2);
var DoughnutInnerCircle = class extends BaseProperties {
  constructor() {
    super(...arguments);
    this.fill = "transparent";
    this.fillOpacity = 1;
  }
};
__decorateClass([
  Validate(COLOR_STRING)
], DoughnutInnerCircle.prototype, "fill", 2);
__decorateClass([
  Validate(RATIO)
], DoughnutInnerCircle.prototype, "fillOpacity", 2);
var PieSeriesCalloutLabel = class extends Label$1 {
  constructor() {
    super(...arguments);
    this.offset = 3;
    this.minAngle = 0;
    this.minSpacing = 4;
    this.maxCollisionOffset = 50;
    this.avoidCollisions = true;
  }
};
__decorateClass([
  Validate(POSITIVE_NUMBER)
], PieSeriesCalloutLabel.prototype, "offset", 2);
__decorateClass([
  Validate(DEGREE)
], PieSeriesCalloutLabel.prototype, "minAngle", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], PieSeriesCalloutLabel.prototype, "minSpacing", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], PieSeriesCalloutLabel.prototype, "maxCollisionOffset", 2);
__decorateClass([
  Validate(BOOLEAN)
], PieSeriesCalloutLabel.prototype, "avoidCollisions", 2);
var PieSeriesSectorLabel = class extends Label$1 {
  constructor() {
    super(...arguments);
    this.positionOffset = 0;
    this.positionRatio = 0.5;
  }
};
__decorateClass([
  Validate(NUMBER)
], PieSeriesSectorLabel.prototype, "positionOffset", 2);
__decorateClass([
  Validate(RATIO)
], PieSeriesSectorLabel.prototype, "positionRatio", 2);
var PieSeriesCalloutLine = class extends BaseProperties {
  constructor() {
    super(...arguments);
    this.length = 10;
    this.strokeWidth = 1;
  }
};
__decorateClass([
  Validate(COLOR_STRING_ARRAY, { optional: true })
], PieSeriesCalloutLine.prototype, "colors", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], PieSeriesCalloutLine.prototype, "length", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], PieSeriesCalloutLine.prototype, "strokeWidth", 2);
var PieSeriesProperties = class extends SeriesProperties {
  constructor() {
    super(...arguments);
    this.fills = Object.values(DEFAULT_FILLS);
    this.strokes = Object.values(DEFAULT_STROKES);
    this.fillOpacity = 1;
    this.strokeOpacity = 1;
    this.lineDash = [0];
    this.lineDashOffset = 0;
    this.rotation = 0;
    this.outerRadiusOffset = 0;
    this.outerRadiusRatio = 1;
    this.innerRadiusOffset = 0;
    this.innerRadiusRatio = 1;
    this.strokeWidth = 1;
    this.sectorSpacing = void 0;
    this.innerLabels = new PropertiesArray(DoughnutInnerLabel);
    this.title = new PieTitle();
    this.innerCircle = new DoughnutInnerCircle();
    this.shadow = new DropShadow();
    this.calloutLabel = new PieSeriesCalloutLabel();
    this.sectorLabel = new PieSeriesSectorLabel();
    this.calloutLine = new PieSeriesCalloutLine();
    this.tooltip = new SeriesTooltip();
    this.__BACKGROUND_COLOR_DO_NOT_USE = void 0;
  }
};
__decorateClass([
  Validate(STRING)
], PieSeriesProperties.prototype, "angleKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], PieSeriesProperties.prototype, "angleName", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], PieSeriesProperties.prototype, "radiusKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], PieSeriesProperties.prototype, "radiusName", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], PieSeriesProperties.prototype, "radiusMin", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], PieSeriesProperties.prototype, "radiusMax", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], PieSeriesProperties.prototype, "calloutLabelKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], PieSeriesProperties.prototype, "calloutLabelName", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], PieSeriesProperties.prototype, "sectorLabelKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], PieSeriesProperties.prototype, "sectorLabelName", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], PieSeriesProperties.prototype, "legendItemKey", 2);
__decorateClass([
  Validate(COLOR_STRING_ARRAY)
], PieSeriesProperties.prototype, "fills", 2);
__decorateClass([
  Validate(COLOR_STRING_ARRAY)
], PieSeriesProperties.prototype, "strokes", 2);
__decorateClass([
  Validate(RATIO)
], PieSeriesProperties.prototype, "fillOpacity", 2);
__decorateClass([
  Validate(RATIO)
], PieSeriesProperties.prototype, "strokeOpacity", 2);
__decorateClass([
  Validate(LINE_DASH)
], PieSeriesProperties.prototype, "lineDash", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], PieSeriesProperties.prototype, "lineDashOffset", 2);
__decorateClass([
  Validate(FUNCTION, { optional: true })
], PieSeriesProperties.prototype, "formatter", 2);
__decorateClass([
  Validate(DEGREE)
], PieSeriesProperties.prototype, "rotation", 2);
__decorateClass([
  Validate(NUMBER)
], PieSeriesProperties.prototype, "outerRadiusOffset", 2);
__decorateClass([
  Validate(RATIO)
], PieSeriesProperties.prototype, "outerRadiusRatio", 2);
__decorateClass([
  Validate(NUMBER)
], PieSeriesProperties.prototype, "innerRadiusOffset", 2);
__decorateClass([
  Validate(RATIO)
], PieSeriesProperties.prototype, "innerRadiusRatio", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER)
], PieSeriesProperties.prototype, "strokeWidth", 2);
__decorateClass([
  Validate(POSITIVE_NUMBER, { optional: true })
], PieSeriesProperties.prototype, "sectorSpacing", 2);
__decorateClass([
  Validate(OBJECT_ARRAY)
], PieSeriesProperties.prototype, "innerLabels", 2);
__decorateClass([
  Validate(OBJECT)
], PieSeriesProperties.prototype, "title", 2);
__decorateClass([
  Validate(OBJECT)
], PieSeriesProperties.prototype, "innerCircle", 2);
__decorateClass([
  Validate(OBJECT)
], PieSeriesProperties.prototype, "shadow", 2);
__decorateClass([
  Validate(OBJECT)
], PieSeriesProperties.prototype, "calloutLabel", 2);
__decorateClass([
  Validate(OBJECT)
], PieSeriesProperties.prototype, "sectorLabel", 2);
__decorateClass([
  Validate(OBJECT)
], PieSeriesProperties.prototype, "calloutLine", 2);
__decorateClass([
  Validate(OBJECT)
], PieSeriesProperties.prototype, "tooltip", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], PieSeriesProperties.prototype, "__BACKGROUND_COLOR_DO_NOT_USE", 2);

// packages/ag-charts-community/src/chart/series/polar/pieUtil.ts
function preparePieSeriesAnimationFunctions(initialLoad, rotationDegrees, scaleFn, oldScaleFn) {
  const scale2 = [scaleFn.convert(0), scaleFn.convert(1)];
  const oldScale = [oldScaleFn.convert(0), oldScaleFn.convert(1)];
  const rotation = Math.PI / -2 + toRadians(rotationDegrees);
  const scaleToNewRadius = ({ radius }) => {
    return { innerRadius: scale2[0], outerRadius: scale2[0] + (scale2[1] - scale2[0]) * radius };
  };
  const scaleToOldRadius = ({ radius }) => {
    return { innerRadius: oldScale[0], outerRadius: oldScale[0] + (oldScale[1] - oldScale[0]) * radius };
  };
  const fromFn = (sect, datum, status, { prevFromProps }) => {
    var _a, _b, _c, _d, _e, _f;
    let { startAngle, endAngle, innerRadius, outerRadius } = sect;
    let { fill, stroke } = datum.sectorFormat;
    if (status === "unknown" || status === "added" && !prevFromProps) {
      startAngle = rotation;
      endAngle = rotation;
      innerRadius = datum.innerRadius;
      outerRadius = datum.outerRadius;
    } else if (status === "added" && prevFromProps) {
      startAngle = (_a = prevFromProps.endAngle) != null ? _a : rotation;
      endAngle = (_b = prevFromProps.endAngle) != null ? _b : rotation;
      innerRadius = (_c = prevFromProps.innerRadius) != null ? _c : datum.innerRadius;
      outerRadius = (_d = prevFromProps.outerRadius) != null ? _d : datum.outerRadius;
    }
    if (status === "added" && !initialLoad) {
      const radii = scaleToOldRadius(datum);
      innerRadius = radii.innerRadius;
      outerRadius = radii.outerRadius;
    }
    if (status === "updated") {
      fill = (_e = sect.fill) != null ? _e : fill;
      stroke = (_f = sect.stroke) != null ? _f : stroke;
    }
    return { startAngle, endAngle, innerRadius, outerRadius, fill, stroke };
  };
  const toFn = (_sect, datum, status, { prevLive }) => {
    var _a, _b;
    let { startAngle, endAngle, innerRadius, outerRadius } = datum;
    const { stroke, fill } = datum.sectorFormat;
    if (status === "removed" && prevLive) {
      startAngle = (_a = prevLive.datum) == null ? void 0 : _a.endAngle;
      endAngle = (_b = prevLive.datum) == null ? void 0 : _b.endAngle;
    } else if (status === "removed" && !prevLive) {
      startAngle = rotation;
      endAngle = rotation;
    }
    if (status === "removed") {
      const radii = scaleToNewRadius(datum);
      innerRadius = radii.innerRadius;
      outerRadius = radii.outerRadius;
    }
    return { startAngle, endAngle, outerRadius, innerRadius, stroke, fill };
  };
  const innerCircle = {
    fromFn: (node, _datum) => {
      var _a, _b, _c;
      return { size: (_c = (_b = (_a = node.previousDatum) == null ? void 0 : _a.radius) != null ? _b : node.size) != null ? _c : 0 };
    },
    toFn: (_node, datum) => {
      var _a;
      return { size: (_a = datum.radius) != null ? _a : 0 };
    }
  };
  return { nodes: { toFn, fromFn }, innerCircle };
}
function resetPieSelectionsFn(_node, datum) {
  return {
    startAngle: datum.startAngle,
    endAngle: datum.endAngle,
    innerRadius: datum.innerRadius,
    outerRadius: datum.outerRadius,
    fill: datum.sectorFormat.fill,
    stroke: datum.sectorFormat.stroke
  };
}

// packages/ag-charts-community/src/chart/series/polar/polarSeries.ts
var PolarSeries = class extends DataModelSeries {
  constructor(_a) {
    var _b = _a, {
      useLabelLayer = false,
      pickModes = [0 /* EXACT_SHAPE_MATCH */],
      canHaveAxes = false,
      animationResetFns
    } = _b, opts = __objRest(_b, [
      "useLabelLayer",
      "pickModes",
      "canHaveAxes",
      "animationResetFns"
    ]);
    super(__spreadProps(__spreadValues({}, opts), {
      useLabelLayer,
      pickModes,
      contentGroupVirtual: false,
      directionKeys: {
        ["x" /* X */]: ["angleKey"],
        ["y" /* Y */]: ["radiusKey"]
      },
      directionNames: {
        ["x" /* X */]: ["angleName"],
        ["y" /* Y */]: ["radiusName"]
      },
      canHaveAxes
    }));
    this.sectorGroup = this.contentGroup.appendChild(new Group());
    this.itemSelection = Selection.select(
      this.sectorGroup,
      () => this.nodeFactory(),
      false
    );
    this.labelSelection = Selection.select(this.labelGroup, Text, false);
    this.highlightSelection = Selection.select(
      this.highlightGroup,
      () => this.nodeFactory()
    );
    /**
     * The center of the polar series (for example, the center of a pie).
     * If the polar chart has multiple series, all of them will have their
     * center set to the same value as a result of the polar chart layout.
     * The center coordinates are not supposed to be set by the user.
     */
    this.centerX = 0;
    this.centerY = 0;
    /**
     * The maximum radius the series can use.
     * This value is set automatically as a result of the polar chart layout
     * and is not supposed to be set by the user.
     */
    this.radius = 0;
    this.sectorGroup.zIndexSubOrder = [() => this._declarationOrder, 1];
    this.animationResetFns = animationResetFns;
    this.animationState = new StateMachine(
      "empty",
      {
        empty: {
          update: {
            target: "ready",
            action: (data) => this.animateEmptyUpdateReady(data)
          }
        },
        ready: {
          updateData: "waiting",
          clear: "clearing",
          highlight: (data) => this.animateReadyHighlight(data),
          highlightMarkers: (data) => this.animateReadyHighlightMarkers(data),
          resize: (data) => this.animateReadyResize(data)
        },
        waiting: {
          update: {
            target: "ready",
            action: (data) => this.animateWaitingUpdateReady(data)
          }
        },
        clearing: {
          update: {
            target: "empty",
            action: (data) => this.animateClearingUpdateEmpty(data)
          }
        }
      },
      () => this.checkProcessedDataAnimatable()
    );
  }
  getLabelData() {
    return [];
  }
  computeLabelsBBox(_options, _seriesRect) {
    return null;
  }
  resetAllAnimation() {
    var _a;
    const { item, label } = (_a = this.animationResetFns) != null ? _a : {};
    this.ctx.animationManager.stopByAnimationGroupId(this.id);
    if (item) {
      resetMotion([this.itemSelection, this.highlightSelection], item);
    }
    if (label) {
      resetMotion([this.labelSelection], label);
    }
    this.itemSelection.cleanup();
    this.labelSelection.cleanup();
    this.highlightSelection.cleanup();
  }
  animateEmptyUpdateReady(_data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation();
  }
  animateWaitingUpdateReady(_data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation();
  }
  animateReadyHighlight(_data) {
    var _a;
    const { item } = (_a = this.animationResetFns) != null ? _a : {};
    if (item) {
      resetMotion([this.highlightSelection], item);
    }
  }
  animateReadyHighlightMarkers(_data) {
  }
  animateReadyResize(_data) {
    this.resetAllAnimation();
  }
  animateClearingUpdateEmpty(_data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation();
  }
  animationTransitionClear() {
    this.animationState.transition("clear", this.getAnimationData());
  }
  getAnimationData(seriesRect) {
    return { seriesRect };
  }
};

// packages/ag-charts-community/src/chart/series/polar/pieSeries.ts
var PieSeriesNodeClickEvent = class extends SeriesNodeClickEvent {
  constructor(type, nativeEvent, datum, series) {
    super(type, nativeEvent, datum, series);
    this.angleKey = series.properties.angleKey;
    this.radiusKey = series.properties.radiusKey;
    this.calloutLabelKey = series.properties.calloutLabelKey;
    this.sectorLabelKey = series.properties.sectorLabelKey;
  }
};
var PieSeries = class extends PolarSeries {
  constructor(moduleCtx) {
    super({
      moduleCtx,
      useLabelLayer: true,
      animationResetFns: { item: resetPieSelectionsFn, label: resetLabelFn }
    });
    this.properties = new PieSeriesProperties();
    this.previousRadiusScale = new LinearScale$1();
    this.radiusScale = new LinearScale$1();
    // The group node that contains the background graphics.
    this.backgroundGroup = this.rootGroup.appendChild(
      new Group({
        name: `${this.id}-background`,
        layer: true,
        zIndex: 0 /* SERIES_BACKGROUND_ZINDEX */
      })
    );
    // AG-6193 If the sum of all datums is 0, then we'll draw 1 or 2 rings to represent the empty series.
    this.zerosumRingsGroup = this.backgroundGroup.appendChild(new Group({ name: `${this.id}-zerosumRings` }));
    this.zerosumOuterRing = this.zerosumRingsGroup.appendChild(new Circle());
    this.zerosumInnerRing = this.zerosumRingsGroup.appendChild(new Circle());
    this.innerCircleGroup = this.backgroundGroup.appendChild(new Group({ name: `${this.id}-innerCircle` }));
    this.nodeData = [];
    // When a user toggles a series item (e.g. from the legend), its boolean state is recorded here.
    this.seriesItemEnabled = [];
    this.surroundingRadius = void 0;
    this.NodeClickEvent = PieSeriesNodeClickEvent;
    this.angleScale = new LinearScale$1();
    this.angleScale.domain = [0, 1];
    this.angleScale.range = [-Math.PI, Math.PI].map((angle) => angle + Math.PI / 2);
    const pieCalloutLabels = new Group({ name: "pieCalloutLabels" });
    const pieSectorLabels = new Group({ name: "pieSectorLabels" });
    const innerLabels = new Group({ name: "innerLabels" });
    this.labelGroup.append(pieCalloutLabels);
    this.labelGroup.append(pieSectorLabels);
    this.labelGroup.append(innerLabels);
    this.calloutLabelSelection = Selection.select(pieCalloutLabels, Group);
    this.sectorLabelSelection = Selection.select(pieSectorLabels, Text);
    this.innerLabelsSelection = Selection.select(innerLabels, Text);
    this.innerCircleSelection = Selection.select(this.innerCircleGroup, Circle);
    for (const circle of [this.zerosumInnerRing, this.zerosumOuterRing]) {
      circle.fillOpacity = 0;
      circle.stroke = this.properties.calloutLabel.color;
      circle.strokeWidth = 1;
      circle.strokeOpacity = 1;
    }
  }
  addChartEventListeners() {
    var _a;
    (_a = this.ctx.chartEventManager) == null ? void 0 : _a.addListener("legend-item-click", (event) => this.onLegendItemClick(event));
  }
  visibleChanged() {
    this.processSeriesItemEnabled();
  }
  get visible() {
    return this.seriesItemEnabled.length ? this.seriesItemEnabled.some((visible) => visible) : super.visible;
  }
  processSeriesItemEnabled() {
    var _a;
    const { data, visible } = this;
    this.seriesItemEnabled = (_a = data == null ? void 0 : data.map(() => visible)) != null ? _a : [];
  }
  nodeFactory() {
    return new Sector();
  }
  getSeriesDomain(direction) {
    if (direction === "x" /* X */) {
      return this.angleScale.domain;
    } else {
      return this.radiusScale.domain;
    }
  }
  processData(dataController) {
    return __async(this, null, function* () {
      var _a, _b, _c, _d, _e;
      if (this.data == null || !this.properties.isValid()) {
        return;
      }
      let { data } = this;
      const { seriesItemEnabled } = this;
      const { angleKey, radiusKey, calloutLabelKey, sectorLabelKey, legendItemKey } = this.properties;
      const animationEnabled = !this.ctx.animationManager.isSkipped();
      const extraKeyProps = [];
      const extraProps = [];
      if (legendItemKey) {
        extraKeyProps.push(keyProperty(this, legendItemKey, false, { id: `legendItemKey` }));
      } else if (calloutLabelKey) {
        extraKeyProps.push(keyProperty(this, calloutLabelKey, false, { id: `calloutLabelKey` }));
      } else if (sectorLabelKey) {
        extraKeyProps.push(keyProperty(this, sectorLabelKey, false, { id: `sectorLabelKey` }));
      }
      if (radiusKey) {
        extraProps.push(
          rangedValueProperty(this, radiusKey, {
            id: "radiusValue",
            min: (_a = this.properties.radiusMin) != null ? _a : 0,
            max: this.properties.radiusMax
          }),
          valueProperty(this, radiusKey, true, { id: `radiusRaw` }),
          // Raw value pass-through.
          normalisePropertyTo(
            this,
            { id: "radiusValue" },
            [0, 1],
            1,
            (_b = this.properties.radiusMin) != null ? _b : 0,
            this.properties.radiusMax
          )
        );
      }
      if (calloutLabelKey) {
        extraProps.push(valueProperty(this, calloutLabelKey, false, { id: `calloutLabelValue` }));
      }
      if (sectorLabelKey) {
        extraProps.push(valueProperty(this, sectorLabelKey, false, { id: `sectorLabelValue` }));
      }
      if (legendItemKey) {
        extraProps.push(valueProperty(this, legendItemKey, false, { id: `legendItemValue` }));
      }
      if (animationEnabled && this.processedData && extraKeyProps.length > 0) {
        extraProps.push(diff(this.processedData));
      }
      extraProps.push(animationValidation(this));
      data = data.map((d, idx) => seriesItemEnabled[idx] ? d : __spreadProps(__spreadValues({}, d), { [angleKey]: 0 }));
      yield this.requestDataModel(dataController, data, {
        props: [
          ...extraKeyProps,
          accumulativeValueProperty(this, angleKey, true, { id: `angleValue`, onlyPositive: true }),
          valueProperty(this, angleKey, true, { id: `angleRaw` }),
          // Raw value pass-through.
          normalisePropertyTo(this, { id: "angleValue" }, [0, 1], 0, 0),
          ...extraProps
        ]
      });
      for (const valueDef of (_e = (_d = (_c = this.processedData) == null ? void 0 : _c.defs) == null ? void 0 : _d.values) != null ? _e : []) {
        const { id, missing, property } = valueDef;
        if (id !== "angleRaw" && missing !== void 0 && missing > 0) {
          Logger.warnOnce(
            `no value was found for the key '${String(property)}' on ${missing} data element${missing > 1 ? "s" : ""}`
          );
        }
      }
      this.animationState.transition("updateData");
    });
  }
  maybeRefreshNodeData() {
    return __async(this, null, function* () {
      if (!this.nodeDataRefresh)
        return;
      const [{ nodeData = [] } = {}] = yield this.createNodeData();
      this.nodeData = nodeData;
      this.nodeDataRefresh = false;
    });
  }
  getProcessedDataIndexes(dataModel) {
    const angleIdx = dataModel.resolveProcessedDataIndexById(this, `angleValue`).index;
    const radiusIdx = this.properties.radiusKey ? dataModel.resolveProcessedDataIndexById(this, `radiusValue`).index : -1;
    const calloutLabelIdx = this.properties.calloutLabelKey ? dataModel.resolveProcessedDataIndexById(this, `calloutLabelValue`).index : -1;
    const sectorLabelIdx = this.properties.sectorLabelKey ? dataModel.resolveProcessedDataIndexById(this, `sectorLabelValue`).index : -1;
    const legendItemIdx = this.properties.legendItemKey ? dataModel.resolveProcessedDataIndexById(this, `legendItemValue`).index : -1;
    return { angleIdx, radiusIdx, calloutLabelIdx, sectorLabelIdx, legendItemIdx };
  }
  createNodeData() {
    return __async(this, null, function* () {
      const { id: seriesId, processedData, dataModel, angleScale } = this;
      const { rotation } = this.properties;
      if (!processedData || !dataModel || processedData.type !== "ungrouped")
        return [];
      const { angleIdx, radiusIdx, calloutLabelIdx, sectorLabelIdx, legendItemIdx } = this.getProcessedDataIndexes(dataModel);
      let currentStart = 0;
      let sum2 = 0;
      const nodeData = processedData.data.map((group2, index) => {
        var _a;
        const { datum, values } = group2;
        const currentValue = values[angleIdx];
        const startAngle = angleScale.convert(currentStart) + toRadians(rotation);
        currentStart = currentValue;
        sum2 += currentValue;
        const endAngle = angleScale.convert(currentStart) + toRadians(rotation);
        const span = Math.abs(endAngle - startAngle);
        const midAngle = startAngle + span / 2;
        const angleValue = values[angleIdx + 1];
        const radius = radiusIdx >= 0 ? (_a = values[radiusIdx]) != null ? _a : 1 : 1;
        const radiusValue = radiusIdx >= 0 ? values[radiusIdx + 1] : void 0;
        const legendItemValue = legendItemIdx >= 0 ? values[legendItemIdx] : void 0;
        const labels = this.getLabels(
          datum,
          midAngle,
          span,
          true,
          values[calloutLabelIdx],
          values[sectorLabelIdx],
          legendItemValue
        );
        const sectorFormat = this.getSectorFormat(datum, index, false);
        return __spreadValues({
          itemId: index,
          series: this,
          datum,
          index,
          angleValue,
          midAngle,
          midCos: Math.cos(midAngle),
          midSin: Math.sin(midAngle),
          startAngle,
          endAngle,
          sectorFormat,
          radiusValue,
          radius,
          innerRadius: Math.max(this.radiusScale.convert(0), 0),
          outerRadius: Math.max(this.radiusScale.convert(radius), 0),
          legendItemValue
        }, labels);
      });
      this.zerosumOuterRing.visible = sum2 === 0;
      this.zerosumInnerRing.visible = sum2 === 0 && this.properties.innerRadiusRatio !== 1 && this.properties.innerRadiusRatio > 0;
      return [{ itemId: seriesId, nodeData, labelData: nodeData }];
    });
  }
  getLabels(datum, midAngle, span, skipDisabled, calloutLabelValue, sectorLabelValue, legendItemValue) {
    const { calloutLabel, sectorLabel, legendItemKey } = this.properties;
    const calloutLabelKey = !skipDisabled || calloutLabel.enabled ? this.properties.calloutLabelKey : void 0;
    const sectorLabelKey = !skipDisabled || sectorLabel.enabled ? this.properties.sectorLabelKey : void 0;
    if (!calloutLabelKey && !sectorLabelKey && !legendItemKey) {
      return {};
    }
    const labelFormatterParams = {
      datum,
      angleKey: this.properties.angleKey,
      angleName: this.properties.angleName,
      radiusKey: this.properties.radiusKey,
      radiusName: this.properties.radiusName,
      calloutLabelKey: this.properties.calloutLabelKey,
      calloutLabelName: this.properties.calloutLabelName,
      sectorLabelKey: this.properties.sectorLabelKey,
      sectorLabelName: this.properties.sectorLabelName,
      legendItemKey: this.properties.legendItemKey
    };
    const result = {};
    if (calloutLabelKey && span > toRadians(calloutLabel.minAngle)) {
      result.calloutLabel = __spreadProps(__spreadValues({}, this.getTextAlignment(midAngle)), {
        text: this.getLabelText(calloutLabel, __spreadProps(__spreadValues({}, labelFormatterParams), {
          value: calloutLabelValue
        })),
        hidden: false,
        collisionTextAlign: void 0,
        collisionOffsetY: 0,
        box: void 0
      });
    }
    if (sectorLabelKey) {
      result.sectorLabel = {
        text: this.getLabelText(sectorLabel, __spreadProps(__spreadValues({}, labelFormatterParams), {
          value: sectorLabelValue
        }))
      };
    }
    if (legendItemKey != null && legendItemValue != null) {
      result.legendItem = { key: legendItemKey, text: legendItemValue };
    }
    return result;
  }
  getTextAlignment(midAngle) {
    const quadrantTextOpts = [
      { textAlign: "center", textBaseline: "bottom" },
      { textAlign: "left", textBaseline: "middle" },
      { textAlign: "center", textBaseline: "hanging" },
      { textAlign: "right", textBaseline: "middle" }
    ];
    const midAngle180 = normalizeAngle180(midAngle);
    const quadrantStart = -3 * Math.PI / 4;
    const quadrantOffset = midAngle180 - quadrantStart;
    const quadrant = Math.floor(quadrantOffset / (Math.PI / 2));
    const quadrantIndex = mod(quadrant, quadrantTextOpts.length);
    return quadrantTextOpts[quadrantIndex];
  }
  getSectorFormat(datum, formatIndex, highlight) {
    var _a, _b, _c, _d, _e;
    const { callbackCache, highlightManager } = this.ctx;
    const { angleKey, radiusKey, fills, strokes, formatter, sectorSpacing, __BACKGROUND_COLOR_DO_NOT_USE } = this.properties;
    const highlightedDatum = highlightManager.getActiveHighlight();
    const isDatumHighlighted = highlight && (highlightedDatum == null ? void 0 : highlightedDatum.series) === this && formatIndex === highlightedDatum.itemId;
    const { fill, fillOpacity, stroke, strokeWidth, strokeOpacity } = mergeDefaults(
      isDatumHighlighted && this.properties.highlightStyle.item,
      {
        fill: fills.length > 0 ? fills[formatIndex % fills.length] : void 0,
        fillOpacity: this.properties.fillOpacity,
        // @todo(AG-10275) Remove sectorSpacing null case
        stroke: sectorSpacing != null ? strokes.length > 0 ? strokes[formatIndex % strokes.length] : void 0 : strokes.length > 0 ? strokes[formatIndex % strokes.length] : __BACKGROUND_COLOR_DO_NOT_USE,
        strokeWidth: this.getStrokeWidth(this.properties.strokeWidth),
        strokeOpacity: this.getOpacity()
      }
    );
    let format2;
    if (formatter) {
      format2 = callbackCache.call(formatter, {
        datum,
        angleKey,
        radiusKey,
        fill,
        stroke,
        fills,
        strokes,
        strokeWidth,
        highlighted: isDatumHighlighted,
        seriesId: this.id
      });
    }
    return {
      fill: (_a = format2 == null ? void 0 : format2.fill) != null ? _a : fill,
      fillOpacity: (_b = format2 == null ? void 0 : format2.fillOpacity) != null ? _b : fillOpacity,
      stroke: (_c = format2 == null ? void 0 : format2.stroke) != null ? _c : stroke,
      strokeWidth: (_d = format2 == null ? void 0 : format2.strokeWidth) != null ? _d : strokeWidth,
      strokeOpacity: (_e = format2 == null ? void 0 : format2.strokeOpacity) != null ? _e : strokeOpacity
    };
  }
  getInnerRadius() {
    const { radius } = this;
    const { innerRadiusRatio, innerRadiusOffset } = this.properties;
    const innerRadius = radius * innerRadiusRatio + innerRadiusOffset;
    if (innerRadius === radius || innerRadius < 0) {
      return 0;
    }
    return innerRadius;
  }
  getOuterRadius() {
    return Math.max(this.radius * this.properties.outerRadiusRatio + this.properties.outerRadiusOffset, 0);
  }
  updateRadiusScale(resize) {
    const newRange = [this.getInnerRadius(), this.getOuterRadius()];
    this.radiusScale.range = newRange;
    if (resize) {
      this.previousRadiusScale.range = newRange;
    }
    this.nodeData = this.nodeData.map((_a) => {
      var _b = _a, { radius } = _b, d = __objRest(_b, ["radius"]);
      return __spreadProps(__spreadValues({}, d), {
        radius,
        innerRadius: Math.max(this.radiusScale.convert(0), 0),
        outerRadius: Math.max(this.radiusScale.convert(radius), 0)
      });
    });
  }
  getTitleTranslationY() {
    var _a, _b;
    const outerRadius = Math.max(0, this.radiusScale.range[1]);
    if (outerRadius === 0) {
      return NaN;
    }
    const spacing = (_b = (_a = this.properties.title) == null ? void 0 : _a.spacing) != null ? _b : 0;
    const titleOffset = 2 + spacing;
    const dy = Math.max(0, -outerRadius);
    return -outerRadius - titleOffset - dy;
  }
  update(_0) {
    return __async(this, arguments, function* ({ seriesRect }) {
      const { title } = this.properties;
      const newNodeDataDependencies = {
        seriesRectWidth: seriesRect == null ? void 0 : seriesRect.width,
        seriesRectHeight: seriesRect == null ? void 0 : seriesRect.height
      };
      const resize = jsonDiff(this.nodeDataDependencies, newNodeDataDependencies) != null;
      if (resize) {
        this._nodeDataDependencies = newNodeDataDependencies;
      }
      yield this.maybeRefreshNodeData();
      this.updateTitleNodes();
      this.updateRadiusScale(resize);
      this.contentGroup.translationX = this.centerX;
      this.contentGroup.translationY = this.centerY;
      this.highlightGroup.translationX = this.centerX;
      this.highlightGroup.translationY = this.centerY;
      this.backgroundGroup.translationX = this.centerX;
      this.backgroundGroup.translationY = this.centerY;
      if (this.labelGroup) {
        this.labelGroup.translationX = this.centerX;
        this.labelGroup.translationY = this.centerY;
      }
      if (title) {
        const dy = this.getTitleTranslationY();
        const titleBox = title.node.computeBBox();
        title.node.visible = title.enabled && isFinite(dy) && !this.bboxIntersectsSurroundingSeries(titleBox, 0, dy);
        title.node.translationY = isFinite(dy) ? dy : 0;
      }
      this.updateNodeMidPoint();
      yield this.updateSelections();
      yield this.updateNodes(seriesRect);
    });
  }
  updateTitleNodes() {
    var _a, _b;
    const { oldTitle } = this;
    const { title } = this.properties;
    if (oldTitle !== title) {
      if (oldTitle) {
        (_a = this.labelGroup) == null ? void 0 : _a.removeChild(oldTitle.node);
      }
      if (title) {
        title.node.textBaseline = "bottom";
        (_b = this.labelGroup) == null ? void 0 : _b.appendChild(title.node);
      }
      this.oldTitle = title;
    }
  }
  updateNodeMidPoint() {
    this.nodeData.forEach((d) => {
      const radius = d.innerRadius + (d.outerRadius - d.innerRadius) / 2;
      d.midPoint = {
        x: d.midCos * Math.max(0, radius),
        y: d.midSin * Math.max(0, radius)
      };
    });
  }
  updateSelections() {
    return __async(this, null, function* () {
      yield this.updateGroupSelection();
      this.updateInnerCircleSelection();
    });
  }
  updateGroupSelection() {
    return __async(this, null, function* () {
      const { itemSelection, highlightSelection, calloutLabelSelection, sectorLabelSelection, innerLabelsSelection } = this;
      const update = (selection, clone) => {
        let nodeData = this.nodeData;
        if (clone) {
          nodeData = nodeData.map((datum) => __spreadProps(__spreadValues({}, datum), { sectorFormat: __spreadValues({}, datum.sectorFormat) }));
        }
        selection.update(nodeData);
        if (this.ctx.animationManager.isSkipped()) {
          selection.cleanup();
        }
      };
      update(itemSelection, false);
      update(highlightSelection, true);
      calloutLabelSelection.update(this.nodeData, (group2) => {
        const line = new Line();
        line.tag = 1 /* Callout */;
        line.pointerEvents = 1 /* None */;
        group2.appendChild(line);
        const text = new Text();
        text.tag = 2 /* Label */;
        text.pointerEvents = 1 /* None */;
        group2.appendChild(text);
      });
      sectorLabelSelection.update(this.nodeData, (node) => {
        node.pointerEvents = 1 /* None */;
      });
      innerLabelsSelection.update(this.properties.innerLabels, (node) => {
        node.pointerEvents = 1 /* None */;
      });
    });
  }
  updateInnerCircleSelection() {
    const { innerCircle } = this.properties;
    let radius = 0;
    const innerRadius = this.getInnerRadius();
    if (innerRadius > 0) {
      const circleRadius = Math.min(innerRadius, this.getOuterRadius());
      const antiAliasingPadding = 1;
      radius = Math.ceil(circleRadius * 2 + antiAliasingPadding);
    }
    const datums = innerCircle ? [{ radius }] : [];
    this.innerCircleSelection.update(datums);
  }
  updateNodes(seriesRect) {
    return __async(this, null, function* () {
      const highlightedDatum = this.ctx.highlightManager.getActiveHighlight();
      const isVisible = this.seriesItemEnabled.indexOf(true) >= 0;
      this.rootGroup.visible = isVisible;
      this.backgroundGroup.visible = isVisible;
      this.contentGroup.visible = isVisible;
      this.highlightGroup.visible = isVisible && (highlightedDatum == null ? void 0 : highlightedDatum.series) === this;
      if (this.labelGroup) {
        this.labelGroup.visible = isVisible;
      }
      this.contentGroup.opacity = this.getOpacity();
      this.innerCircleSelection.each((node, { radius }) => {
        var _a, _b;
        node.setProperties({
          fill: (_a = this.properties.innerCircle) == null ? void 0 : _a.fill,
          opacity: (_b = this.properties.innerCircle) == null ? void 0 : _b.fillOpacity,
          size: radius
        });
      });
      const updateSectorFn = (sector, datum, _index, isDatumHighlighted) => {
        const format2 = this.getSectorFormat(datum.datum, datum.itemId, isDatumHighlighted);
        datum.sectorFormat.fill = format2.fill;
        datum.sectorFormat.stroke = format2.stroke;
        const animationDisabled = this.ctx.animationManager.isSkipped();
        if (animationDisabled) {
          sector.startAngle = datum.startAngle;
          sector.endAngle = datum.endAngle;
          sector.innerRadius = datum.innerRadius;
          sector.outerRadius = datum.outerRadius;
        }
        if (isDatumHighlighted || animationDisabled) {
          sector.fill = format2.fill;
          sector.stroke = format2.stroke;
        }
        sector.strokeWidth = format2.strokeWidth;
        sector.fillOpacity = format2.fillOpacity;
        sector.strokeOpacity = this.properties.strokeOpacity;
        sector.lineDash = this.properties.lineDash;
        sector.lineDashOffset = this.properties.lineDashOffset;
        sector.fillShadow = this.properties.shadow;
        sector.inset = this.properties.sectorSpacing != null ? (this.properties.sectorSpacing + (format2.stroke != null ? format2.strokeWidth : 0)) / 2 : 0;
        sector.lineJoin = this.properties.sectorSpacing != null ? "miter" : "round";
      };
      this.itemSelection.each((node, datum, index) => updateSectorFn(node, datum, index, false));
      this.highlightSelection.each((node, datum, index) => {
        const isDatumHighlighted = (highlightedDatum == null ? void 0 : highlightedDatum.series) === this && node.datum.itemId === highlightedDatum.itemId;
        updateSectorFn(node, datum, index, isDatumHighlighted);
        node.visible = isDatumHighlighted;
      });
      this.updateCalloutLineNodes();
      this.updateCalloutLabelNodes(seriesRect);
      this.updateSectorLabelNodes();
      this.updateInnerLabelNodes();
      this.updateZerosumRings();
      this.animationState.transition("update");
    });
  }
  updateCalloutLineNodes() {
    var _a;
    const { calloutLine } = this.properties;
    const calloutLength = calloutLine.length;
    const calloutStrokeWidth = calloutLine.strokeWidth;
    const calloutColors = (_a = calloutLine.colors) != null ? _a : this.properties.strokes;
    const { offset: offset4 } = this.properties.calloutLabel;
    this.calloutLabelSelection.selectByTag(1 /* Callout */).forEach((line, index) => {
      const datum = line.datum;
      const { calloutLabel: label, outerRadius } = datum;
      if ((label == null ? void 0 : label.text) && !label.hidden && outerRadius !== 0) {
        line.visible = true;
        line.strokeWidth = calloutStrokeWidth;
        line.stroke = calloutColors[index % calloutColors.length];
        line.fill = void 0;
        const x1 = datum.midCos * outerRadius;
        const y1 = datum.midSin * outerRadius;
        let x2 = datum.midCos * (outerRadius + calloutLength);
        let y2 = datum.midSin * (outerRadius + calloutLength);
        const isMoved = label.collisionTextAlign || label.collisionOffsetY !== 0;
        if (isMoved && label.box != null) {
          const box = label.box;
          let cx = x2;
          let cy = y2;
          if (x2 < box.x) {
            cx = box.x;
          } else if (x2 > box.x + box.width) {
            cx = box.x + box.width;
          }
          if (y2 < box.y) {
            cy = box.y;
          } else if (y2 > box.y + box.height) {
            cy = box.y + box.height;
          }
          const dx = cx - x2;
          const dy = cy - y2;
          const length = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
          const paddedLength = length - offset4;
          if (paddedLength > 0) {
            x2 = x2 + dx * paddedLength / length;
            y2 = y2 + dy * paddedLength / length;
          }
        }
        line.x1 = x1;
        line.y1 = y1;
        line.x2 = x2;
        line.y2 = y2;
      } else {
        line.visible = false;
      }
    });
  }
  getLabelOverflow(text, box, seriesRect) {
    const seriesLeft = seriesRect.x - this.centerX;
    const seriesRight = seriesRect.x + seriesRect.width - this.centerX;
    const seriesTop = seriesRect.y - this.centerY;
    const seriesBottom = seriesRect.y + seriesRect.height - this.centerY;
    const errPx = 1;
    let visibleTextPart = 1;
    if (box.x + errPx < seriesLeft) {
      visibleTextPart = (box.x + box.width - seriesLeft) / box.width;
    } else if (box.x + box.width - errPx > seriesRight) {
      visibleTextPart = (seriesRight - box.x) / box.width;
    }
    const hasVerticalOverflow = box.y + errPx < seriesTop || box.y + box.height - errPx > seriesBottom;
    const textLength = visibleTextPart === 1 ? text.length : Math.floor(text.length * visibleTextPart) - 1;
    const hasSurroundingSeriesOverflow = this.bboxIntersectsSurroundingSeries(box);
    return { textLength, hasVerticalOverflow, hasSurroundingSeriesOverflow };
  }
  bboxIntersectsSurroundingSeries(box, dx = 0, dy = 0) {
    const { surroundingRadius } = this;
    if (surroundingRadius == null) {
      return false;
    }
    const corners = [
      { x: box.x + dx, y: box.y + dy },
      { x: box.x + box.width + dx, y: box.y + dy },
      { x: box.x + box.width + dx, y: box.y + box.height + dy },
      { x: box.x + dx, y: box.y + box.height + dy }
    ];
    const sur2 = __pow(surroundingRadius, 2);
    return corners.some((corner) => __pow(corner.x, 2) + __pow(corner.y, 2) > sur2);
  }
  computeCalloutLabelCollisionOffsets() {
    const { radiusScale } = this;
    const { calloutLabel, calloutLine } = this.properties;
    const { offset: offset4, minSpacing } = calloutLabel;
    const innerRadius = radiusScale.convert(0);
    const shouldSkip = (datum) => {
      const label = datum.calloutLabel;
      return !label || datum.outerRadius === 0;
    };
    const fullData = this.nodeData;
    const data = this.nodeData.filter((t) => !shouldSkip(t));
    data.forEach((datum) => {
      const label = datum.calloutLabel;
      if (label == null)
        return;
      label.hidden = false;
      label.collisionTextAlign = void 0;
      label.collisionOffsetY = 0;
    });
    if (data.length <= 1) {
      return;
    }
    const leftLabels = data.filter((d) => d.midCos < 0).sort((a, b) => a.midSin - b.midSin);
    const rightLabels = data.filter((d) => d.midCos >= 0).sort((a, b) => a.midSin - b.midSin);
    const topLabels = data.filter((d) => {
      var _a;
      return d.midSin < 0 && ((_a = d.calloutLabel) == null ? void 0 : _a.textAlign) === "center";
    }).sort((a, b) => a.midCos - b.midCos);
    const bottomLabels = data.filter((d) => {
      var _a;
      return d.midSin >= 0 && ((_a = d.calloutLabel) == null ? void 0 : _a.textAlign) === "center";
    }).sort((a, b) => a.midCos - b.midCos);
    const tempTextNode = new Text();
    const getTextBBox = (datum) => {
      var _a;
      const label = datum.calloutLabel;
      if (label == null)
        return new BBox(0, 0, 0, 0);
      const labelRadius = datum.outerRadius + calloutLine.length + offset4;
      const x = datum.midCos * labelRadius;
      const y = datum.midSin * labelRadius + label.collisionOffsetY;
      tempTextNode.text = label.text;
      tempTextNode.x = x;
      tempTextNode.y = y;
      tempTextNode.setFont(this.properties.calloutLabel);
      tempTextNode.setAlign({
        textAlign: (_a = label.collisionTextAlign) != null ? _a : label.textAlign,
        textBaseline: label.textBaseline
      });
      return tempTextNode.computeBBox();
    };
    const avoidNeighbourYCollision = (label, next, direction) => {
      const box = getTextBBox(label).grow(minSpacing / 2);
      const other = getTextBBox(next).grow(minSpacing / 2);
      const collidesOrBehind = box.x < other.x + other.width && box.x + box.width > other.x && (direction === "to-top" ? box.y < other.y + other.height : box.y + box.height > other.y);
      if (collidesOrBehind) {
        const dy = direction === "to-top" ? box.y - other.y - other.height : box.y + box.height - other.y;
        next.calloutLabel.collisionOffsetY = dy;
      }
    };
    const avoidYCollisions = (labels) => {
      const midLabel = labels.slice().sort((a, b) => Math.abs(a.midSin) - Math.abs(b.midSin))[0];
      const midIndex = labels.indexOf(midLabel);
      for (let i = midIndex - 1; i >= 0; i--) {
        const prev = labels[i + 1];
        const next = labels[i];
        avoidNeighbourYCollision(prev, next, "to-top");
      }
      for (let i = midIndex + 1; i < labels.length; i++) {
        const prev = labels[i - 1];
        const next = labels[i];
        avoidNeighbourYCollision(prev, next, "to-bottom");
      }
    };
    const avoidXCollisions = (labels) => {
      const labelsCollideLabelsByY = data.some((datum) => datum.calloutLabel.collisionOffsetY !== 0);
      const boxes = labels.map((label) => getTextBBox(label));
      const paddedBoxes = boxes.map((box) => box.clone().grow(minSpacing / 2));
      let labelsCollideLabelsByX = false;
      for (let i = 0; i < paddedBoxes.length && !labelsCollideLabelsByX; i++) {
        const box = paddedBoxes[i];
        for (let j = i + 1; j < labels.length; j++) {
          const other = paddedBoxes[j];
          if (box.collidesBBox(other)) {
            labelsCollideLabelsByX = true;
            break;
          }
        }
      }
      const sectors = fullData.map((datum) => {
        const { startAngle, endAngle, outerRadius } = datum;
        return { startAngle, endAngle, innerRadius, outerRadius };
      });
      const labelsCollideSectors = boxes.some((box) => {
        return sectors.some((sector) => boxCollidesSector(box, sector));
      });
      if (!labelsCollideLabelsByX && !labelsCollideLabelsByY && !labelsCollideSectors) {
        return;
      }
      labels.filter((d) => d.calloutLabel.textAlign === "center").forEach((d) => {
        const label = d.calloutLabel;
        if (d.midCos < 0) {
          label.collisionTextAlign = "right";
        } else if (d.midCos > 0) {
          label.collisionTextAlign = "left";
        } else {
          label.collisionTextAlign = "center";
        }
      });
    };
    avoidYCollisions(leftLabels);
    avoidYCollisions(rightLabels);
    avoidXCollisions(topLabels);
    avoidXCollisions(bottomLabels);
  }
  updateCalloutLabelNodes(seriesRect) {
    const { radiusScale } = this;
    const { calloutLabel, calloutLine } = this.properties;
    const calloutLength = calloutLine.length;
    const { offset: offset4, color } = calloutLabel;
    const tempTextNode = new Text();
    this.calloutLabelSelection.selectByTag(2 /* Label */).forEach((text) => {
      var _a;
      const { datum } = text;
      const label = datum.calloutLabel;
      const radius = radiusScale.convert(datum.radius);
      const outerRadius = Math.max(0, radius);
      if (!(label == null ? void 0 : label.text) || outerRadius === 0 || label.hidden) {
        text.visible = false;
        return;
      }
      const labelRadius = outerRadius + calloutLength + offset4;
      const x = datum.midCos * labelRadius;
      const y = datum.midSin * labelRadius + label.collisionOffsetY;
      const align = {
        textAlign: (_a = label.collisionTextAlign) != null ? _a : label.textAlign,
        textBaseline: label.textBaseline
      };
      tempTextNode.text = label.text;
      tempTextNode.x = x;
      tempTextNode.y = y;
      tempTextNode.setFont(this.properties.calloutLabel);
      tempTextNode.setAlign(align);
      const box = tempTextNode.computeBBox();
      let displayText = label.text;
      let visible = true;
      if (calloutLabel.avoidCollisions) {
        const { textLength, hasVerticalOverflow } = this.getLabelOverflow(label.text, box, seriesRect);
        displayText = label.text.length === textLength ? label.text : `${label.text.substring(0, textLength)}\u2026`;
        visible = !hasVerticalOverflow;
      }
      text.text = displayText;
      text.x = x;
      text.y = y;
      text.setFont(this.properties.calloutLabel);
      text.setAlign(align);
      text.fill = color;
      text.visible = visible;
    });
  }
  computeLabelsBBox(options, seriesRect) {
    return __async(this, null, function* () {
      const { calloutLabel, calloutLine } = this.properties;
      const calloutLength = calloutLine.length;
      const { offset: offset4, maxCollisionOffset, minSpacing } = calloutLabel;
      if (!calloutLabel.avoidCollisions) {
        return null;
      }
      yield this.maybeRefreshNodeData();
      this.updateRadiusScale(false);
      this.computeCalloutLabelCollisionOffsets();
      const textBoxes = [];
      const text = new Text();
      let titleBox;
      const { title } = this.properties;
      if ((title == null ? void 0 : title.text) && title.enabled) {
        const dy = this.getTitleTranslationY();
        if (isFinite(dy)) {
          text.text = title.text;
          text.x = 0;
          text.y = dy;
          text.setFont(title);
          text.setAlign({
            textBaseline: "bottom",
            textAlign: "center"
          });
          titleBox = text.computeBBox();
          textBoxes.push(titleBox);
        }
      }
      this.nodeData.forEach((datum) => {
        var _a;
        const label = datum.calloutLabel;
        if (!label || datum.outerRadius === 0) {
          return null;
        }
        const labelRadius = datum.outerRadius + calloutLength + offset4;
        const x = datum.midCos * labelRadius;
        const y = datum.midSin * labelRadius + label.collisionOffsetY;
        text.text = label.text;
        text.x = x;
        text.y = y;
        text.setFont(this.properties.calloutLabel);
        text.setAlign({
          textAlign: (_a = label.collisionTextAlign) != null ? _a : label.textAlign,
          textBaseline: label.textBaseline
        });
        const box = text.computeBBox();
        label.box = box;
        if (Math.abs(label.collisionOffsetY) > maxCollisionOffset) {
          label.hidden = true;
          return;
        }
        if (titleBox) {
          const seriesTop = seriesRect.y - this.centerY;
          const titleCleanArea = new BBox(
            titleBox.x - minSpacing,
            seriesTop,
            titleBox.width + 2 * minSpacing,
            titleBox.y + titleBox.height + minSpacing - seriesTop
          );
          if (box.collidesBBox(titleCleanArea)) {
            label.hidden = true;
            return;
          }
        }
        if (options.hideWhenNecessary) {
          const { textLength, hasVerticalOverflow, hasSurroundingSeriesOverflow } = this.getLabelOverflow(
            label.text,
            box,
            seriesRect
          );
          const isTooShort = label.text.length > 2 && textLength < 2;
          if (hasVerticalOverflow || isTooShort || hasSurroundingSeriesOverflow) {
            label.hidden = true;
            return;
          }
        }
        label.hidden = false;
        textBoxes.push(box);
      });
      if (textBoxes.length === 0) {
        return null;
      }
      return BBox.merge(textBoxes);
    });
  }
  updateSectorLabelNodes() {
    const { radiusScale } = this;
    const innerRadius = radiusScale.convert(0);
    const { fontSize, fontStyle, fontWeight, fontFamily, positionOffset, positionRatio, color } = this.properties.sectorLabel;
    const isDoughnut = innerRadius > 0;
    const singleVisibleSector = this.seriesItemEnabled.filter(Boolean).length === 1;
    this.sectorLabelSelection.each((text, datum) => {
      const { sectorLabel, outerRadius } = datum;
      let isTextVisible = false;
      if (sectorLabel && outerRadius !== 0) {
        const labelRadius = innerRadius * (1 - positionRatio) + outerRadius * positionRatio + positionOffset;
        text.fill = color;
        text.fontStyle = fontStyle;
        text.fontWeight = fontWeight;
        text.fontSize = fontSize;
        text.fontFamily = fontFamily;
        text.text = sectorLabel.text;
        const shouldPutTextInCenter = !isDoughnut && singleVisibleSector;
        if (shouldPutTextInCenter) {
          text.x = 0;
          text.y = 0;
        } else {
          text.x = datum.midCos * labelRadius;
          text.y = datum.midSin * labelRadius;
        }
        text.textAlign = "center";
        text.textBaseline = "middle";
        const bbox = text.computeBBox();
        const corners = [
          [bbox.x, bbox.y],
          [bbox.x + bbox.width, bbox.y],
          [bbox.x + bbox.width, bbox.y + bbox.height],
          [bbox.x, bbox.y + bbox.height]
        ];
        const { startAngle, endAngle } = datum;
        const sectorBounds = { startAngle, endAngle, innerRadius, outerRadius };
        if (corners.every(([x, y]) => isPointInSector(x, y, sectorBounds))) {
          isTextVisible = true;
        }
      }
      text.visible = isTextVisible;
    });
  }
  updateInnerLabelNodes() {
    const textBBoxes = [];
    const margins = [];
    this.innerLabelsSelection.each((text, datum) => {
      const { fontStyle, fontWeight, fontSize, fontFamily, color } = datum;
      text.fontStyle = fontStyle;
      text.fontWeight = fontWeight;
      text.fontSize = fontSize;
      text.fontFamily = fontFamily;
      text.text = datum.text;
      text.x = 0;
      text.y = 0;
      text.fill = color;
      text.textAlign = "center";
      text.textBaseline = "alphabetic";
      textBBoxes.push(text.computeBBox());
      margins.push(datum.margin);
    });
    const getMarginTop = (index) => index === 0 ? 0 : margins[index];
    const getMarginBottom = (index) => index === margins.length - 1 ? 0 : margins[index];
    const totalHeight = textBBoxes.reduce((sum2, bbox, i) => {
      return sum2 + bbox.height + getMarginTop(i) + getMarginBottom(i);
    }, 0);
    const totalWidth = Math.max(...textBBoxes.map((bbox) => bbox.width));
    const innerRadius = this.getInnerRadius();
    const labelRadius = Math.sqrt(Math.pow(totalWidth / 2, 2) + Math.pow(totalHeight / 2, 2));
    const labelsVisible = labelRadius <= (innerRadius > 0 ? innerRadius : this.getOuterRadius());
    const textBottoms = [];
    for (let i = 0, prev = -totalHeight / 2; i < textBBoxes.length; i++) {
      const bbox = textBBoxes[i];
      const bottom = bbox.height + prev + getMarginTop(i);
      textBottoms.push(bottom);
      prev = bottom + getMarginBottom(i);
    }
    this.innerLabelsSelection.each((text, _datum, index) => {
      text.y = textBottoms[index];
      text.visible = labelsVisible;
    });
  }
  updateZerosumRings() {
    this.zerosumOuterRing.size = this.getOuterRadius() * 2;
    this.zerosumInnerRing.size = this.getInnerRadius() * 2;
  }
  getDatumLegendName(nodeDatum) {
    const { angleKey, calloutLabelKey, sectorLabelKey, legendItemKey } = this.properties;
    const { sectorLabel, calloutLabel, legendItem } = nodeDatum;
    if (legendItemKey && legendItem !== void 0) {
      return legendItem.text;
    } else if (calloutLabelKey && calloutLabelKey !== angleKey && (calloutLabel == null ? void 0 : calloutLabel.text) !== void 0) {
      return calloutLabel.text;
    } else if (sectorLabelKey && sectorLabelKey !== angleKey && (sectorLabel == null ? void 0 : sectorLabel.text) !== void 0) {
      return sectorLabel.text;
    }
  }
  getTooltipHtml(nodeDatum) {
    var _a;
    if (!this.properties.isValid()) {
      return "";
    }
    const {
      datum,
      angleValue,
      sectorFormat: { fill: color }
    } = nodeDatum;
    const title = sanitizeHtml((_a = this.properties.title) == null ? void 0 : _a.text);
    const content = isNumber2(angleValue) ? toFixed(angleValue) : String(angleValue);
    const labelText = this.getDatumLegendName(nodeDatum);
    return this.properties.tooltip.toTooltipHtml(
      {
        title: title != null ? title : labelText,
        content: title && labelText ? `${labelText}: ${content}` : content,
        backgroundColor: color
      },
      {
        datum,
        title,
        color,
        seriesId: this.id,
        angleKey: this.properties.angleKey,
        angleName: this.properties.angleName,
        radiusKey: this.properties.radiusKey,
        radiusName: this.properties.radiusName,
        calloutLabelKey: this.properties.calloutLabelKey,
        calloutLabelName: this.properties.calloutLabelName,
        sectorLabelKey: this.properties.sectorLabelKey,
        sectorLabelName: this.properties.sectorLabelName
      }
    );
  }
  getLegendData(legendType) {
    var _a, _b, _c, _d, _e;
    const { processedData, dataModel } = this;
    if (!dataModel || !(processedData == null ? void 0 : processedData.data.length) || legendType !== "category") {
      return [];
    }
    const { angleKey, calloutLabelKey, sectorLabelKey, legendItemKey } = this.properties;
    if (!legendItemKey && (!calloutLabelKey || calloutLabelKey === angleKey) && (!sectorLabelKey || sectorLabelKey === angleKey))
      return [];
    const { calloutLabelIdx, sectorLabelIdx, legendItemIdx } = this.getProcessedDataIndexes(dataModel);
    const titleText = ((_a = this.properties.title) == null ? void 0 : _a.showInLegend) && this.properties.title.text;
    const legendData = [];
    for (let index = 0; index < processedData.data.length; index++) {
      const { datum, values } = processedData.data[index];
      const labelParts = [];
      if (titleText) {
        labelParts.push(titleText);
      }
      const labels = this.getLabels(
        datum,
        2 * Math.PI,
        2 * Math.PI,
        false,
        values[calloutLabelIdx],
        values[sectorLabelIdx],
        values[legendItemIdx]
      );
      if (legendItemKey && labels.legendItem !== void 0) {
        labelParts.push(labels.legendItem.text);
      } else if (calloutLabelKey && calloutLabelKey !== angleKey && ((_b = labels.calloutLabel) == null ? void 0 : _b.text) !== void 0) {
        labelParts.push((_c = labels.calloutLabel) == null ? void 0 : _c.text);
      } else if (sectorLabelKey && sectorLabelKey !== angleKey && ((_d = labels.sectorLabel) == null ? void 0 : _d.text) !== void 0) {
        labelParts.push((_e = labels.sectorLabel) == null ? void 0 : _e.text);
      }
      if (labelParts.length === 0)
        continue;
      const sectorFormat = this.getSectorFormat(datum, index, false);
      legendData.push({
        legendType: "category",
        id: this.id,
        itemId: index,
        seriesId: this.id,
        enabled: this.seriesItemEnabled[index],
        label: {
          text: labelParts.join(" - ")
        },
        marker: {
          fill: sectorFormat.fill,
          stroke: sectorFormat.stroke,
          fillOpacity: this.properties.fillOpacity,
          strokeOpacity: this.properties.strokeOpacity,
          strokeWidth: this.properties.strokeWidth
        }
      });
    }
    return legendData;
  }
  onLegendItemClick(event) {
    const { enabled, itemId, series } = event;
    if (series.id === this.id) {
      this.toggleSeriesItem(itemId, enabled);
    } else if (series.type === "pie") {
      this.toggleOtherSeriesItems(series, itemId, enabled);
    }
  }
  toggleSeriesItem(itemId, enabled) {
    this.seriesItemEnabled[itemId] = enabled;
    this.nodeDataRefresh = true;
  }
  toggleOtherSeriesItems(series, itemId, enabled) {
    var _a, _b;
    if (!this.properties.legendItemKey || !this.dataModel) {
      return;
    }
    const datumToggledLegendItemValue = series.properties.legendItemKey && ((_a = series.data) == null ? void 0 : _a.find((_, index) => index === itemId)[series.properties.legendItemKey]);
    if (!datumToggledLegendItemValue) {
      return;
    }
    const legendItemIdx = this.dataModel.resolveProcessedDataIndexById(this, `legendItemValue`).index;
    (_b = this.processedData) == null ? void 0 : _b.data.forEach(({ values }, datumItemId) => {
      if (values[legendItemIdx] === datumToggledLegendItemValue) {
        this.toggleSeriesItem(datumItemId, enabled);
      }
    });
  }
  animateEmptyUpdateReady(_data) {
    const { animationManager } = this.ctx;
    const fns = preparePieSeriesAnimationFunctions(
      true,
      this.properties.rotation,
      this.radiusScale,
      this.previousRadiusScale
    );
    fromToMotion(this.id, "nodes", animationManager, [this.itemSelection, this.highlightSelection], fns.nodes);
    fromToMotion(this.id, `innerCircle`, animationManager, [this.innerCircleSelection], fns.innerCircle);
    seriesLabelFadeInAnimation(this, "callout", animationManager, [this.calloutLabelSelection]);
    seriesLabelFadeInAnimation(this, "sector", animationManager, [this.sectorLabelSelection]);
    seriesLabelFadeInAnimation(this, "inner", animationManager, [this.innerLabelsSelection]);
    this.previousRadiusScale.range = this.radiusScale.range;
  }
  animateWaitingUpdateReady() {
    var _a, _b, _c, _d, _e, _f;
    const { itemSelection, highlightSelection, processedData, radiusScale, previousRadiusScale } = this;
    const { animationManager } = this.ctx;
    const diff2 = (_a = processedData == null ? void 0 : processedData.reduced) == null ? void 0 : _a.diff;
    this.ctx.animationManager.stopByAnimationGroupId(this.id);
    const supportedDiff = ((_b = diff2 == null ? void 0 : diff2.moved.length) != null ? _b : 0) === 0 && (diff2 == null ? void 0 : diff2.addedIndices.every((i) => !diff2.removedIndices.includes(i)));
    const hasKeys = ((_c = processedData == null ? void 0 : processedData.defs.keys.length) != null ? _c : 0) > 0;
    const hasUniqueKeys = (_f = (_e = (_d = processedData == null ? void 0 : processedData.reduced) == null ? void 0 : _d.animationValidation) == null ? void 0 : _e.uniqueKeys) != null ? _f : true;
    if (!supportedDiff || !hasKeys || !hasUniqueKeys) {
      this.ctx.animationManager.skipCurrentBatch();
    }
    const fns = preparePieSeriesAnimationFunctions(
      false,
      this.properties.rotation,
      radiusScale,
      previousRadiusScale
    );
    fromToMotion(
      this.id,
      "nodes",
      animationManager,
      [itemSelection, highlightSelection],
      fns.nodes,
      (_, datum) => this.getDatumId(datum),
      diff2
    );
    fromToMotion(this.id, `innerCircle`, animationManager, [this.innerCircleSelection], fns.innerCircle);
    seriesLabelFadeInAnimation(this, "callout", this.ctx.animationManager, [this.calloutLabelSelection]);
    seriesLabelFadeInAnimation(this, "sector", this.ctx.animationManager, [this.sectorLabelSelection]);
    seriesLabelFadeInAnimation(this, "inner", this.ctx.animationManager, [this.innerLabelsSelection]);
    this.previousRadiusScale.range = this.radiusScale.range;
  }
  animateClearingUpdateEmpty() {
    const { itemSelection, highlightSelection, radiusScale, previousRadiusScale } = this;
    const { animationManager } = this.ctx;
    const fns = preparePieSeriesAnimationFunctions(
      false,
      this.properties.rotation,
      radiusScale,
      previousRadiusScale
    );
    fromToMotion(this.id, "nodes", animationManager, [itemSelection, highlightSelection], fns.nodes);
    fromToMotion(this.id, `innerCircle`, animationManager, [this.innerCircleSelection], fns.innerCircle);
    seriesLabelFadeOutAnimation(this, "callout", this.ctx.animationManager, [this.calloutLabelSelection]);
    seriesLabelFadeOutAnimation(this, "sector", this.ctx.animationManager, [this.sectorLabelSelection]);
    seriesLabelFadeOutAnimation(this, "inner", this.ctx.animationManager, [this.innerLabelsSelection]);
    this.previousRadiusScale.range = this.radiusScale.range;
  }
  getDatumIdFromData(datum) {
    var _a, _b, _c;
    const { calloutLabelKey, sectorLabelKey, legendItemKey } = this.properties;
    if (!((_c = (_b = (_a = this.processedData) == null ? void 0 : _a.reduced) == null ? void 0 : _b.animationValidation) == null ? void 0 : _c.uniqueKeys)) {
      return;
    }
    if (legendItemKey) {
      return datum[legendItemKey];
    } else if (calloutLabelKey) {
      return datum[calloutLabelKey];
    } else if (sectorLabelKey) {
      return datum[sectorLabelKey];
    }
  }
  getDatumId(datum) {
    var _a;
    const { index } = datum;
    return (_a = this.getDatumIdFromData(datum.datum)) != null ? _a : `${index}`;
  }
  onDataChange() {
    this.processSeriesItemEnabled();
  }
};
PieSeries.className = "PieSeries";
PieSeries.type = "pie";

// packages/ag-charts-community/src/chart/series/polar/pieSeriesModule.ts
({
  type: "series",
  optionsKey: "series[]",
  packageType: "community",
  chartTypes: ["polar"],
  identifier: "pie",
  instanceConstructor: PieSeries,
  seriesDefaults: {},
  themeTemplate: {
    __extends__: EXTENDS_SERIES_DEFAULTS,
    title: {
      enabled: true,
      fontStyle: void 0,
      fontWeight: FONT_WEIGHT2.NORMAL,
      fontSize: 14,
      fontFamily: DEFAULT_FONT_FAMILY,
      color: DEFAULT_MUTED_LABEL_COLOUR,
      spacing: 5
    },
    calloutLabel: {
      enabled: true,
      fontStyle: void 0,
      fontWeight: void 0,
      fontSize: 12,
      fontFamily: DEFAULT_FONT_FAMILY,
      color: DEFAULT_LABEL_COLOUR,
      offset: 3,
      minAngle: 0
    },
    sectorLabel: {
      enabled: true,
      fontStyle: void 0,
      fontWeight: FONT_WEIGHT2.NORMAL,
      fontSize: 12,
      fontFamily: DEFAULT_FONT_FAMILY,
      color: DEFAULT_INSIDE_SERIES_LABEL_COLOUR,
      positionOffset: 0,
      positionRatio: 0.5
    },
    calloutLine: {
      length: 10,
      strokeWidth: 2
    },
    fillOpacity: 1,
    strokeOpacity: 1,
    strokeWidth: 1,
    lineDash: [0],
    lineDashOffset: 0,
    rotation: 0,
    outerRadiusOffset: 0,
    innerRadiusOffset: 0,
    // @todo(AG-10275) Uncomment this
    // sectorSpacing: 1,
    shadow: {
      enabled: false,
      color: DEFAULT_SHADOW_COLOUR,
      xOffset: 3,
      yOffset: 3,
      blur: 5
    },
    innerLabels: {
      fontStyle: void 0,
      fontWeight: void 0,
      fontSize: 12,
      fontFamily: DEFAULT_FONT_FAMILY,
      color: DEFAULT_LABEL_COLOUR,
      margin: 2
    },
    // @todo(AG-10275) Remove this
    // @ts-expect-error
    __BACKGROUND_COLOR_DO_NOT_USE: DEFAULT_BACKGROUND_COLOUR
  },
  paletteFactory: ({ takeColors, colorsCount, userPalette }) => {
    const { fills, strokes } = takeColors(colorsCount);
    return {
      fills,
      strokes: userPalette ? strokes : [],
      calloutLine: {
        colors: strokes
      }
    };
  }
});

// packages/ag-charts-community/src/module-support.ts
var module_support_exports = {};
__export(module_support_exports, {
  ADD_PHASE: () => ADD_PHASE,
  AGG_VALUES_EXTENT: () => AGG_VALUES_EXTENT,
  AND: () => AND,
  ARRAY: () => ARRAY,
  ARRAY_OF: () => ARRAY_OF,
  AbstractBarSeries: () => AbstractBarSeries,
  AbstractBarSeriesProperties: () => AbstractBarSeriesProperties,
  ActionOnSet: () => ActionOnSet,
  Animation: () => Animation,
  AnimationManager: () => AnimationManager,
  AreaSeriesTag: () => AreaSeriesTag,
  Axis: () => Axis,
  AxisLabel: () => AxisLabel,
  AxisTick: () => AxisTick,
  BOOLEAN: () => BOOLEAN,
  BOOLEAN_ARRAY: () => BOOLEAN_ARRAY,
  Background: () => Background,
  BackgroundModule: () => BackgroundModule,
  BaseModuleInstance: () => BaseModuleInstance,
  BaseProperties: () => BaseProperties,
  COLOR_STRING: () => COLOR_STRING,
  COLOR_STRING_ARRAY: () => COLOR_STRING_ARRAY,
  CartesianAxis: () => CartesianAxis,
  CartesianSeries: () => CartesianSeries,
  CartesianSeriesNodeClickEvent: () => CartesianSeriesNodeClickEvent,
  CartesianSeriesProperties: () => CartesianSeriesProperties,
  CategoryAxis: () => CategoryAxis,
  ChartAxisDirection: () => ChartAxisDirection,
  ChartEventManager: () => ChartEventManager,
  ChartUpdateType: () => ChartUpdateType,
  CursorManager: () => CursorManager,
  DATE: () => DATE,
  DATE_ARRAY: () => DATE_ARRAY,
  DATE_OR_DATETIME_MS: () => DATE_OR_DATETIME_MS,
  DEGREE: () => DEGREE,
  DIRECTION: () => DIRECTION,
  DataController: () => DataController,
  DataModel: () => DataModel,
  Default: () => Default,
  Deprecated: () => Deprecated,
  DeprecatedAndRenamedTo: () => DeprecatedAndRenamedTo,
  FONT_STYLE: () => FONT_STYLE,
  FONT_WEIGHT: () => FONT_WEIGHT,
  FROM_TO_MIXINS: () => FROM_TO_MIXINS,
  FUNCTION: () => FUNCTION,
  GREATER_THAN: () => GREATER_THAN,
  HierarchyNode: () => HierarchyNode,
  HierarchySeries: () => HierarchySeries,
  HierarchySeriesProperties: () => HierarchySeriesProperties,
  HighlightManager: () => HighlightManager,
  HighlightStyle: () => HighlightStyle,
  INITIAL_LOAD: () => INITIAL_LOAD,
  INTERACTION_RANGE: () => INTERACTION_RANGE,
  InteractionManager: () => InteractionManager,
  LABEL_PHASE: () => LABEL_PHASE,
  LESS_THAN: () => LESS_THAN,
  LINE_CAP: () => LINE_CAP,
  LINE_DASH: () => LINE_DASH,
  LINE_JOIN: () => LINE_JOIN,
  Layers: () => Layers,
  LayoutService: () => LayoutService,
  MATCHING_CROSSLINE_TYPE: () => MATCHING_CROSSLINE_TYPE,
  MAX_SPACING: () => MAX_SPACING,
  MIN_SPACING: () => MIN_SPACING,
  MODULE_CONFLICTS: () => MODULE_CONFLICTS,
  Motion: () => easing_exports,
  NAN: () => NAN,
  NODE_UPDATE_PHASES: () => NODE_UPDATE_PHASES,
  NUMBER: () => NUMBER,
  NUMBER_ARRAY: () => NUMBER_ARRAY,
  NUMBER_OR_NAN: () => NUMBER_OR_NAN,
  OBJECT: () => OBJECT,
  OBJECT_ARRAY: () => OBJECT_ARRAY,
  OR: () => OR,
  OVERFLOW_STRATEGY: () => OVERFLOW_STRATEGY,
  PLACEMENT: () => PLACEMENT,
  POLAR_AXIS_SHAPE: () => POLAR_AXIS_SHAPE,
  POSITION: () => POSITION,
  POSITIVE_NUMBER: () => POSITIVE_NUMBER,
  PolarAxis: () => PolarAxis,
  PolarSeries: () => PolarSeries,
  PropertiesArray: () => PropertiesArray,
  ProxyOnWrite: () => ProxyOnWrite,
  ProxyProperty: () => ProxyProperty,
  ProxyPropertyOnWrite: () => ProxyPropertyOnWrite,
  QUICK_TRANSITION: () => QUICK_TRANSITION,
  RATIO: () => RATIO,
  REGISTERED_MODULES: () => REGISTERED_MODULES,
  REMOVE_PHASE: () => REMOVE_PHASE,
  RepeatType: () => RepeatType,
  SMALLEST_KEY_INTERVAL: () => SMALLEST_KEY_INTERVAL,
  SORT_DOMAIN_GROUPS: () => SORT_DOMAIN_GROUPS,
  STRING: () => STRING,
  STRING_ARRAY: () => STRING_ARRAY,
  Series: () => Series,
  SeriesItemHighlightStyle: () => SeriesItemHighlightStyle,
  SeriesMarker: () => SeriesMarker,
  SeriesNodeClickEvent: () => SeriesNodeClickEvent,
  SeriesNodePickMode: () => SeriesNodePickMode,
  SeriesProperties: () => SeriesProperties,
  SeriesTooltip: () => SeriesTooltip,
  StateMachine: () => StateMachine,
  TEXT_ALIGN: () => TEXT_ALIGN,
  TEXT_WRAP: () => TEXT_WRAP,
  Tags: () => Tags,
  TooltipManager: () => TooltipManager,
  UNION: () => UNION,
  UPDATE_PHASE: () => UPDATE_PHASE,
  UpdateService: () => UpdateService,
  VERTICAL_ALIGN: () => VERTICAL_ALIGN,
  Validate: () => Validate,
  ZoomManager: () => ZoomManager,
  __FORCE_MODULE_DETECTION: () => __FORCE_MODULE_DETECTION2,
  accumulateGroup: () => accumulateGroup,
  accumulatedValue: () => accumulatedValue,
  accumulativeValueProperty: () => accumulativeValueProperty,
  adjustLabelPlacement: () => adjustLabelPlacement,
  animationValidation: () => animationValidation,
  area: () => area,
  arraysEqual: () => arraysEqual,
  assignJsonApplyConstructedArray: () => assignJsonApplyConstructedArray,
  average: () => average,
  backfillPathPointData: () => backfillPathPointData,
  buildResetPathFn: () => buildResetPathFn,
  checkCrisp: () => checkCrisp,
  clamp: () => clamp2,
  clampArray: () => clampArray,
  collapsedStartingBarPosition: () => collapsedStartingBarPosition,
  count: () => count,
  countFractionDigits: () => countFractionDigits,
  createDatumId: () => createDatumId,
  createDeprecationWarning: () => createDeprecationWarning,
  deconstructSelectionsOrNodes: () => deconstructSelectionsOrNodes,
  deepMerge: () => deepMerge,
  determinePathStatus: () => determinePathStatus,
  diff: () => diff,
  enterpriseModule: () => enterpriseModule,
  extent: () => extent$4,
  extractDecoratedProperties: () => extractDecoratedProperties,
  fixNumericExtent: () => fixNumericExtent,
  fromToMotion: () => fromToMotion,
  getRectConfig: () => getRectConfig,
  groupAccumulativeValueProperty: () => groupAccumulativeValueProperty,
  groupAverage: () => groupAverage,
  groupCount: () => groupCount,
  groupSum: () => groupSum,
  hasRegisteredEnterpriseModules: () => hasRegisteredEnterpriseModules,
  injectStyle: () => injectStyle,
  invertShapeDirection: () => invertShapeDirection,
  isArray: () => isArray,
  isBoolean: () => isBoolean,
  isDate: () => isDate$1,
  isDecoratedObject: () => isDecoratedObject,
  isDefined: () => isDefined,
  isEqual: () => isEqual,
  isFiniteNumber: () => isFiniteNumber,
  isFunction: () => isFunction,
  isHtmlElement: () => isHtmlElement,
  isNegative: () => isNegative,
  isNumber: () => isNumber$4,
  isObject: () => isObject,
  isObjectLike: () => isObjectLike,
  isPlainObject: () => isPlainObject,
  isProperties: () => isProperties,
  isReal: () => isReal,
  isString: () => isString$1,
  isValidDate: () => isValidDate,
  keyProperty: () => keyProperty,
  listDecoratedProperties: () => listDecoratedProperties,
  markerFadeInAnimation: () => markerFadeInAnimation,
  markerPaletteFactory: () => markerPaletteFactory,
  markerScaleInAnimation: () => markerScaleInAnimation,
  markerSwipeScaleInAnimation: () => markerSwipeScaleInAnimation,
  mergeDefaults: () => mergeDefaults,
  midpointStartingBarPosition: () => midpointStartingBarPosition,
  minMax: () => minMax,
  mod: () => mod,
  normaliseGroupTo: () => normaliseGroupTo,
  normalisePropertyTo: () => normalisePropertyTo,
  normalisedExtent: () => normalisedExtent,
  normalisedExtentWithMetadata: () => normalisedExtentWithMetadata,
  pairCategoryData: () => pairCategoryData,
  pairContinuousData: () => pairContinuousData,
  partialAssign: () => partialAssign,
  pathFadeInAnimation: () => pathFadeInAnimation,
  pathFadeOutAnimation: () => pathFadeOutAnimation,
  pathSwipeInAnimation: () => pathSwipeInAnimation,
  predicateWithMessage: () => predicateWithMessage,
  prepareAreaPathAnimation: () => prepareAreaPathAnimation,
  prepareAxisAnimationContext: () => prepareAxisAnimationContext,
  prepareAxisAnimationFunctions: () => prepareAxisAnimationFunctions,
  prepareBarAnimationFunctions: () => prepareBarAnimationFunctions,
  prepareLinePathAnimation: () => prepareLinePathAnimation,
  prepareLinePathAnimationFns: () => prepareLinePathAnimationFns,
  prepareMarkerAnimation: () => prepareMarkerAnimation,
  range: () => range2,
  rangedValueProperty: () => rangedValueProperty,
  registerModule: () => registerModule,
  registerModuleConflicts: () => registerModuleConflicts,
  renderPartialPath: () => renderPartialPath,
  resetAxisGroupFn: () => resetAxisGroupFn,
  resetAxisLabelSelectionFn: () => resetAxisLabelSelectionFn,
  resetAxisLineSelectionFn: () => resetAxisLineSelectionFn,
  resetAxisSelectionFn: () => resetAxisSelectionFn,
  resetBarSelectionsFn: () => resetBarSelectionsFn,
  resetIds: () => resetIds,
  resetLabelFn: () => resetLabelFn,
  resetMarkerFn: () => resetMarkerFn,
  resetMarkerPositionFn: () => resetMarkerPositionFn,
  resetMotion: () => resetMotion,
  round: () => round,
  seriesLabelFadeInAnimation: () => seriesLabelFadeInAnimation,
  seriesLabelFadeOutAnimation: () => seriesLabelFadeOutAnimation,
  singleSeriesPaletteFactory: () => singleSeriesPaletteFactory,
  staticFromToMotion: () => staticFromToMotion,
  stringify: () => stringify,
  sum: () => sum,
  toArray: () => toArray,
  toFixed: () => toFixed,
  toReal: () => toReal,
  trailingAccumulatedValue: () => trailingAccumulatedValue,
  trailingAccumulatedValueProperty: () => trailingAccumulatedValueProperty,
  trailingValue: () => trailingValue,
  trailingValueProperty: () => trailingValueProperty,
  unique: () => unique,
  updateClipPath: () => updateClipPath,
  updateLabelNode: () => updateLabelNode,
  updateRect: () => updateRect,
  validateCrossLineValues: () => validateCrossLineValues,
  valueProperty: () => valueProperty
});

// packages/ag-charts-community/src/util/shapes.ts
function invertShapeDirection(...supportedShapes) {
  for (const shape of supportedShapes) {
    if (shape instanceof Rect) {
      const { x, y, width, height } = shape;
      shape.setProperties({ x: y, y: x, width: height, height: width });
    } else if (shape instanceof Line) {
      const { x1, y1, x2, y2 } = shape;
      shape.setProperties({ x1: y1, y1: x1, x2: y2, y2: x2 });
    }
  }
}

// packages/ag-charts-community/src/chart/series/hierarchy/hierarchySeries.ts
var _HierarchyNode = class _HierarchyNode {
  constructor(series, index, datum, size, colorValue, fill, stroke, sumSize, depth, parent, children) {
    this.series = series;
    this.index = index;
    this.datum = datum;
    this.size = size;
    this.colorValue = colorValue;
    this.fill = fill;
    this.stroke = stroke;
    this.sumSize = sumSize;
    this.depth = depth;
    this.parent = parent;
    this.children = children;
    this.midPoint = { x: 0, y: 0 };
  }
  contains(other) {
    let current = other;
    while (current != null && current.index >= this.index) {
      if (current === this) {
        return true;
      }
      current = current.parent;
    }
    return false;
  }
  walk(callback, order = _HierarchyNode.Walk.PreOrder) {
    if (order === _HierarchyNode.Walk.PreOrder) {
      callback(this);
    }
    this.children.forEach((child) => {
      child.walk(callback, order);
    });
    if (order === _HierarchyNode.Walk.PostOrder) {
      callback(this);
    }
  }
  *[Symbol.iterator]() {
    yield this;
    for (const child of this.children) {
      yield* __yieldStar(child);
    }
  }
};
_HierarchyNode.Walk = {
  PreOrder: 0,
  PostOrder: 1
};
var HierarchyNode = _HierarchyNode;
var HierarchySeries = class extends Series {
  constructor(moduleCtx) {
    super({
      moduleCtx,
      pickModes: [0 /* EXACT_SHAPE_MATCH */],
      contentGroupVirtual: false
    });
    this.rootNode = new HierarchyNode(
      this,
      0,
      void 0,
      0,
      void 0,
      void 0,
      void 0,
      0,
      void 0,
      void 0,
      []
    );
    this.colorDomain = [0, 0];
    this.maxDepth = 0;
    this.animationState = new StateMachine(
      "empty",
      {
        empty: {
          update: {
            target: "ready",
            action: (data) => this.animateEmptyUpdateReady(data)
          }
        },
        ready: {
          updateData: "waiting",
          clear: "clearing",
          highlight: (data) => this.animateReadyHighlight(data),
          resize: (data) => this.animateReadyResize(data)
        },
        waiting: {
          update: {
            target: "ready",
            action: (data) => this.animateWaitingUpdateReady(data)
          }
        },
        clearing: {
          update: {
            target: "empty",
            action: (data) => this.animateClearingUpdateEmpty(data)
          }
        }
      },
      () => this.checkProcessedDataAnimatable()
    );
  }
  hasData() {
    return Array.isArray(this.data) && this.data.length > 0;
  }
  processData() {
    return __async(this, null, function* () {
      var _a, _b;
      const { childrenKey, sizeKey, colorKey, fills, strokes, colorRange } = this.properties;
      let index = 0;
      const getIndex = () => {
        index += 1;
        return index;
      };
      let maxDepth = 0;
      let minColor = Infinity;
      let maxColor = -Infinity;
      const colors = new Array(((_b = (_a = this.data) == null ? void 0 : _a.length) != null ? _b : 0) + 1).fill(void 0);
      const createNode = (datum, parent) => {
        const index2 = getIndex();
        const depth = parent.depth != null ? parent.depth + 1 : 0;
        const children = childrenKey != null ? datum[childrenKey] : void 0;
        const isLeaf = children == null || children.length === 0;
        let size = sizeKey != null ? datum[sizeKey] : void 0;
        if (Number.isFinite(size)) {
          size = Math.max(size, 0);
        } else {
          size = isLeaf ? 1 : 0;
        }
        const sumSize = size;
        maxDepth = Math.max(maxDepth, depth);
        const color = colorKey != null ? datum[colorKey] : void 0;
        if (typeof color === "number") {
          colors[index2] = color;
          minColor = Math.min(minColor, color);
          maxColor = Math.max(maxColor, color);
        }
        return appendChildren(
          new HierarchyNode(
            this,
            index2,
            datum,
            size,
            color,
            void 0,
            void 0,
            sumSize,
            depth,
            parent,
            []
          ),
          children
        );
      };
      const appendChildren = (node, data) => {
        data == null ? void 0 : data.forEach((datum) => {
          const child = createNode(datum, node);
          node.children.push(child);
          node.sumSize += child.sumSize;
        });
        return node;
      };
      const rootNode = appendChildren(
        new HierarchyNode(
          this,
          0,
          void 0,
          0,
          void 0,
          void 0,
          void 0,
          0,
          void 0,
          void 0,
          []
        ),
        this.data
      );
      const colorDomain = [minColor, maxColor];
      let colorScale;
      if (colorRange != null && Number.isFinite(minColor) && Number.isFinite(maxColor)) {
        colorScale = new ColorScale();
        colorScale.domain = colorDomain;
        colorScale.range = colorRange;
        colorScale.update();
      }
      rootNode.children.forEach((child, index2) => {
        child.walk((node) => {
          let fill;
          const color = colors[node.index];
          if (color != null) {
            fill = colorScale == null ? void 0 : colorScale.convert(color);
          }
          fill != null ? fill : fill = fills == null ? void 0 : fills[index2 % fills.length];
          node.fill = fill;
          node.stroke = colorScale == null ? strokes == null ? void 0 : strokes[index2 % strokes.length] : "rgba(0, 0, 0, 0.2)";
        });
      });
      this.rootNode = rootNode;
      this.maxDepth = maxDepth;
      this.colorDomain = colorDomain;
    });
  }
  update(_0) {
    return __async(this, arguments, function* ({ seriesRect }) {
      yield this.updateSelections();
      yield this.updateNodes();
      const animationData = this.getAnimationData();
      const resize = this.checkResize(seriesRect);
      if (resize) {
        this.animationState.transition("resize", animationData);
      }
      this.animationState.transition("update", animationData);
    });
  }
  resetAllAnimation(data) {
    var _a;
    const datum = (_a = this.animationResetFns) == null ? void 0 : _a.datum;
    this.ctx.animationManager.stopByAnimationGroupId(this.id);
    if (datum != null) {
      resetMotion(data.datumSelections, datum);
    }
  }
  animateEmptyUpdateReady(data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation(data);
  }
  animateWaitingUpdateReady(data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation(data);
  }
  animateReadyHighlight(data) {
    var _a;
    const datum = (_a = this.animationResetFns) == null ? void 0 : _a.datum;
    if (datum != null) {
      resetMotion([data], datum);
    }
  }
  animateReadyResize(data) {
    this.resetAllAnimation(data);
  }
  animateClearingUpdateEmpty(data) {
    this.ctx.animationManager.skipCurrentBatch();
    this.resetAllAnimation(data);
  }
  animationTransitionClear() {
    this.animationState.transition("clear", this.getAnimationData());
  }
  getAnimationData() {
    const animationData = {
      datumSelections: [this.groupSelection]
    };
    return animationData;
  }
  isProcessedDataAnimatable() {
    return true;
  }
  checkProcessedDataAnimatable() {
    if (!this.isProcessedDataAnimatable()) {
      this.ctx.animationManager.skipCurrentBatch();
    }
  }
  getLabelData() {
    return [];
  }
  getSeriesDomain() {
    return [NaN, NaN];
  }
  getLegendData(legendType) {
    const { colorKey, colorName, colorRange, visible } = this.properties;
    return legendType === "gradient" && colorKey != null && colorRange != null ? [
      {
        legendType: "gradient",
        enabled: visible,
        seriesId: this.id,
        colorName,
        colorRange,
        colorDomain: this.colorDomain
      }
    ] : [];
  }
  getDatumIdFromData(node) {
    return `${node.index}`;
  }
  getDatumId(node) {
    return this.getDatumIdFromData(node);
  }
};

// packages/ag-charts-community/src/chart/series/hierarchy/hierarchySeriesProperties.ts
var HierarchySeriesProperties = class extends SeriesProperties {
  constructor() {
    super(...arguments);
    this.childrenKey = "children";
    this.fills = Object.values(DEFAULT_FILLS);
    this.strokes = Object.values(DEFAULT_STROKES);
  }
};
__decorateClass([
  Validate(STRING)
], HierarchySeriesProperties.prototype, "childrenKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], HierarchySeriesProperties.prototype, "sizeKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], HierarchySeriesProperties.prototype, "colorKey", 2);
__decorateClass([
  Validate(STRING, { optional: true })
], HierarchySeriesProperties.prototype, "colorName", 2);
__decorateClass([
  Validate(COLOR_STRING_ARRAY)
], HierarchySeriesProperties.prototype, "fills", 2);
__decorateClass([
  Validate(COLOR_STRING_ARRAY)
], HierarchySeriesProperties.prototype, "strokes", 2);
__decorateClass([
  Validate(COLOR_STRING_ARRAY, { optional: true })
], HierarchySeriesProperties.prototype, "colorRange", 2);

// packages/ag-charts-community/src/chart/axis/polarAxis.ts
var POLAR_AXIS_SHAPE = UNION(["polygon", "circle"], "a polar axis shape");
var PolarAxis = class extends Axis {
  constructor() {
    super(...arguments);
    this.shape = "polygon";
    this.innerRadiusRatio = 0;
    this.defaultTickMinSpacing = 20;
  }
  computeLabelsBBox(_options, _seriesRect) {
    return null;
  }
};
__decorateClass([
  Validate(POLAR_AXIS_SHAPE)
], PolarAxis.prototype, "shape", 2);
__decorateClass([
  Validate(RATIO)
], PolarAxis.prototype, "innerRadiusRatio", 2);

// packages/ag-charts-community/src/chart/legendDatum.ts
var __FORCE_MODULE_DETECTION2 = 0;
var SERIES_THEME_TEMPLATES = {};
var ENTERPRISE_SERIES_THEME_TEMPLATES = {};
function getSeriesThemeTemplate(chartType2) {
  if (hasRegisteredEnterpriseModules()) {
    return ENTERPRISE_SERIES_THEME_TEMPLATES[chartType2];
  }
  return SERIES_THEME_TEMPLATES[chartType2];
}

// packages/ag-charts-community/src/chart/hierarchyChart.ts
var _HierarchyChart = class _HierarchyChart extends Chart {
  constructor(specialOverrides, resources) {
    super(specialOverrides, resources);
    this._data = {};
  }
  performLayout() {
    return __async(this, null, function* () {
      const shrinkRect = yield __superGet(_HierarchyChart.prototype, this, "performLayout").call(this);
      const {
        seriesArea: { padding },
        seriesRoot
      } = this;
      const fullSeriesRect = shrinkRect.clone();
      shrinkRect.shrink(padding.left, "left");
      shrinkRect.shrink(padding.top, "top");
      shrinkRect.shrink(padding.right, "right");
      shrinkRect.shrink(padding.bottom, "bottom");
      this.seriesRect = shrinkRect;
      this.animationRect = shrinkRect;
      this.hoverRect = shrinkRect;
      seriesRoot.translationX = Math.floor(shrinkRect.x);
      seriesRoot.translationY = Math.floor(shrinkRect.y);
      yield Promise.all(
        this.series.map((series) => __async(this, null, function* () {
          yield series.update({ seriesRect: shrinkRect });
        }))
      );
      seriesRoot.visible = this.series[0].visible;
      seriesRoot.setClipRectInGroupCoordinateSpace(
        new BBox(shrinkRect.x, shrinkRect.y, shrinkRect.width, shrinkRect.height)
      );
      this.layoutService.dispatchLayoutComplete({
        type: "layout-complete",
        chart: { width: this.scene.width, height: this.scene.height },
        clipSeries: false,
        series: { rect: fullSeriesRect, paddedRect: shrinkRect, visible: true },
        axes: []
      });
      return shrinkRect;
    });
  }
};
_HierarchyChart.className = "HierarchyChart";
_HierarchyChart.type = "hierarchy";

// packages/ag-charts-community/src/chart/themes/chartTheme.ts
var DEFAULT_BACKGROUND_FILL = "white";
var palette = {
  fills: Array.from(Object.values(DEFAULT_FILLS)),
  strokes: Array.from(Object.values(DEFAULT_STROKES))
};
var CHART_TYPE_CONFIG = {
  get cartesian() {
    return { seriesTypes: CHART_TYPES.cartesianTypes, commonOptions: ["zoom", "navigator"] };
  },
  get polar() {
    return { seriesTypes: CHART_TYPES.polarTypes, commonOptions: [] };
  },
  get hierarchy() {
    return { seriesTypes: CHART_TYPES.hierarchyTypes, commonOptions: [] };
  }
};
var CHART_TYPE_SPECIFIC_COMMON_OPTIONS = Object.values(CHART_TYPE_CONFIG).reduce((r, { commonOptions }) => [...r, ...commonOptions], []);
function resolvePartialPalette(partialPalette, basePalette) {
  var _a, _b;
  if (partialPalette == null)
    return null;
  return {
    fills: (_a = partialPalette.fills) != null ? _a : basePalette.fills,
    strokes: (_b = partialPalette.strokes) != null ? _b : basePalette.strokes
  };
}
var _ChartTheme = class _ChartTheme {
  getPalette() {
    return palette;
  }
  static getAxisDefaults() {
    return {
      top: {},
      right: {},
      bottom: {},
      left: {},
      title: {
        enabled: false,
        text: "Axis Title",
        spacing: 25,
        fontStyle: void 0,
        fontWeight: FONT_WEIGHT2.NORMAL,
        fontSize: FONT_SIZE.MEDIUM,
        fontFamily: DEFAULT_FONT_FAMILY,
        color: DEFAULT_LABEL_COLOUR
      },
      label: {
        fontStyle: void 0,
        fontWeight: void 0,
        fontSize: FONT_SIZE.SMALL,
        fontFamily: DEFAULT_FONT_FAMILY,
        padding: 5,
        rotation: void 0,
        color: DEFAULT_LABEL_COLOUR,
        formatter: void 0,
        avoidCollisions: true
      },
      line: {
        enabled: true,
        width: 1,
        color: DEFAULT_AXIS_LINE_COLOUR
      },
      tick: {
        enabled: false,
        width: 1,
        color: DEFAULT_AXIS_LINE_COLOUR
      },
      gridLine: {
        enabled: true,
        style: [
          {
            stroke: DEFAULT_AXIS_GRID_COLOUR,
            lineDash: []
          }
        ]
      },
      crossLines: {
        enabled: false,
        fill: DEFAULT_CROSS_LINES_COLOUR,
        stroke: DEFAULT_CROSS_LINES_COLOUR,
        fillOpacity: 0.1,
        strokeWidth: 1,
        label: {
          enabled: false,
          fontStyle: void 0,
          fontWeight: void 0,
          fontSize: FONT_SIZE.SMALL,
          fontFamily: DEFAULT_FONT_FAMILY,
          padding: 5,
          color: DEFAULT_LABEL_COLOUR
        }
      }
    };
  }
  static getSeriesDefaults() {
    return {
      tooltip: {
        enabled: true,
        renderer: void 0
      },
      visible: true,
      showInLegend: true,
      highlightStyle: {
        item: {
          fill: "rgba(255,255,255, 0.33)",
          stroke: `rgba(0, 0, 0, 0.4)`,
          strokeWidth: 2
        },
        series: {
          dimOpacity: 1
        },
        text: {
          color: "black"
        }
      },
      nodeClickRange: "exact"
    };
  }
  static getCartesianSeriesMarkerDefaults() {
    return {
      enabled: true,
      shape: "circle",
      size: 7,
      strokeWidth: 1,
      formatter: void 0
    };
  }
  static getLegendItemMarkerDefaults() {
    return {
      shape: void 0,
      size: 15,
      padding: 8
    };
  }
  static getCaptionWrappingDefaults() {
    return "hyphenate";
  }
  static getChartDefaults() {
    return {
      background: {
        visible: true,
        fill: DEFAULT_BACKGROUND_COLOUR
      },
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      title: {
        enabled: false,
        text: "Title",
        fontStyle: void 0,
        fontWeight: FONT_WEIGHT2.NORMAL,
        fontSize: FONT_SIZE.LARGE,
        fontFamily: DEFAULT_FONT_FAMILY,
        color: DEFAULT_LABEL_COLOUR,
        wrapping: _ChartTheme.getCaptionWrappingDefaults()
      },
      subtitle: {
        enabled: false,
        text: "Subtitle",
        spacing: 20,
        fontStyle: void 0,
        fontWeight: void 0,
        fontSize: FONT_SIZE.MEDIUM,
        fontFamily: DEFAULT_FONT_FAMILY,
        color: DEFAULT_MUTED_LABEL_COLOUR,
        wrapping: _ChartTheme.getCaptionWrappingDefaults()
      },
      footnote: {
        enabled: false,
        text: "Footnote",
        spacing: 20,
        fontStyle: void 0,
        fontWeight: void 0,
        fontSize: FONT_SIZE.MEDIUM,
        fontFamily: DEFAULT_FONT_FAMILY,
        color: "rgb(140, 140, 140)",
        wrapping: _ChartTheme.getCaptionWrappingDefaults()
      },
      legend: {
        position: BOTTOM,
        spacing: 30,
        listeners: {},
        item: {
          paddingX: 16,
          paddingY: 8,
          marker: _ChartTheme.getLegendItemMarkerDefaults(),
          label: {
            color: DEFAULT_LABEL_COLOUR,
            fontStyle: void 0,
            fontWeight: void 0,
            fontSize: FONT_SIZE.SMALL,
            fontFamily: DEFAULT_FONT_FAMILY,
            formatter: void 0
          }
        },
        reverseOrder: false,
        pagination: {
          marker: {
            size: 12
          },
          activeStyle: {
            fill: DEFAULT_LABEL_COLOUR
          },
          inactiveStyle: {
            fill: DEFAULT_MUTED_LABEL_COLOUR
          },
          highlightStyle: {
            fill: DEFAULT_LABEL_COLOUR
          },
          label: {
            color: DEFAULT_LABEL_COLOUR
          }
        }
      },
      tooltip: {
        enabled: true,
        range: "nearest",
        delay: 0
      },
      listeners: {}
    };
  }
  constructor(options) {
    var _a;
    options = deepMerge({}, options != null ? options : {});
    const { overrides = null, palette: palette11 = null } = options;
    const defaults = this.createChartConfigPerChartType(this.getDefaults());
    if (overrides) {
      const { common } = overrides;
      const applyOverrides = (seriesTypes, overrideOpts) => {
        if (!overrideOpts)
          return;
        for (const s of seriesTypes) {
          const seriesType = s;
          defaults[seriesType] = deepMerge(defaults[seriesType], overrideOpts);
        }
      };
      for (const [, { seriesTypes, commonOptions }] of Object.entries(CHART_TYPE_CONFIG)) {
        const cleanedCommon = __spreadValues({}, common);
        for (const commonKey of CHART_TYPE_SPECIFIC_COMMON_OPTIONS) {
          if (!commonOptions.includes(commonKey)) {
            delete cleanedCommon[commonKey];
          }
        }
        applyOverrides(seriesTypes, cleanedCommon);
      }
      CHART_TYPES.seriesTypes.forEach((s) => {
        const seriesType = s;
        if (overrides[seriesType]) {
          defaults[seriesType] = deepMerge(defaults[seriesType], overrides[seriesType]);
        }
      });
    }
    const basePalette = this.getPalette();
    this.palette = (_a = resolvePartialPalette(palette11, basePalette)) != null ? _a : basePalette;
    this.config = Object.freeze(this.templateTheme(defaults));
  }
  createChartConfigPerChartType(config) {
    Object.entries(CHART_TYPE_CONFIG).forEach(([nextType, { seriesTypes }]) => {
      const typeDefaults = getChartDefaults(nextType);
      seriesTypes.forEach((next) => {
        const alias = next;
        if (!config[alias]) {
          config[alias] = {};
          deepMerge(config[alias], typeDefaults);
        }
      });
    });
    return config;
  }
  getDefaults() {
    let defaults = {};
    const getChartTypeDefaults = (chartType2) => {
      return __spreadValues(__spreadValues(__spreadValues({}, getLegendThemeTemplates()), _ChartTheme.getChartDefaults()), getChartDefaults(chartType2));
    };
    const getOverridesByType = (chartType2, seriesTypes) => {
      var _a, _b, _c, _d;
      const chartDefaults = getChartTypeDefaults(chartType2);
      const result = {};
      for (const seriesType of seriesTypes) {
        (_a = result[seriesType]) != null ? _a : result[seriesType] = deepMerge({}, chartDefaults);
        const axes = (_c = (_b = result[seriesType]).axes) != null ? _c : _b.axes = {};
        const template = getSeriesThemeTemplate(seriesType);
        if (template) {
          result[seriesType].series = deepMerge(result[seriesType].series, template);
        }
        for (const axisType of AXIS_TYPES.axesTypes) {
          const template2 = getAxisThemeTemplate(axisType);
          if (chartType2 === "cartesian") {
            axes[axisType] = deepMerge(
              axes[axisType],
              (_d = _ChartTheme.cartesianAxisDefault[axisType]) != null ? _d : {}
            );
          }
          if (template2) {
            axes[axisType] = deepMerge(axes[axisType], template2);
          }
        }
      }
      return result;
    };
    defaults = deepMerge(defaults, getOverridesByType("cartesian", CHART_TYPES.cartesianTypes));
    defaults = deepMerge(defaults, getOverridesByType("polar", CHART_TYPES.polarTypes));
    defaults = deepMerge(defaults, getOverridesByType("hierarchy", CHART_TYPES.hierarchyTypes));
    return defaults;
  }
  templateTheme(themeTemplate) {
    const themeInstance = jsonClone(themeTemplate);
    const { extensions, properties } = this.getTemplateParameters();
    jsonWalk(themeInstance, (node) => {
      if (node["__extends__"]) {
        const key = node["__extends__"];
        const source = extensions.get(key);
        if (source == null) {
          throw new Error(`AG Charts - no template variable provided for: ${key}`);
        }
        Object.keys(source).forEach((key2) => {
          if (!(key2 in node)) {
            node[key2] = source[key2];
          }
        });
        delete node["__extends__"];
      }
      if (node["__overrides__"]) {
        const key = node["__overrides__"];
        const source = extensions.get(key);
        if (source == null) {
          throw new Error(`AG Charts - no template variable provided for: ${key}`);
        }
        Object.assign(node, source);
        delete node["__overrides__"];
      }
      if (Array.isArray(node)) {
        for (let i = 0; i < node.length; i++) {
          const symbol = node[i];
          if (properties.has(symbol)) {
            node[i] = properties.get(symbol);
          }
        }
      } else {
        for (const [name, value] of Object.entries(node)) {
          if (properties.has(value)) {
            node[name] = properties.get(value);
          }
        }
      }
    });
    return themeInstance;
  }
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: DEFAULT_FILLS.BLUE,
      stroke: DEFAULT_STROKES.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: DEFAULT_FILLS.ORANGE,
      stroke: DEFAULT_STROKES.ORANGE
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: DEFAULT_FILLS.GRAY,
      stroke: DEFAULT_STROKES.GRAY
    };
  }
  getTemplateParameters() {
    const extensions = /* @__PURE__ */ new Map();
    extensions.set(EXTENDS_CHART_DEFAULTS, _ChartTheme.getChartDefaults());
    extensions.set(EXTENDS_AXES_DEFAULTS, _ChartTheme.getAxisDefaults());
    extensions.set(EXTENDS_LEGEND_DEFAULTS, _ChartTheme.getChartDefaults().legend);
    extensions.set(EXTENDS_LEGEND_ITEM_DEFAULTS, _ChartTheme.getChartDefaults().legend.item);
    extensions.set(EXTENDS_LEGEND_ITEM_MARKER_DEFAULTS, _ChartTheme.getLegendItemMarkerDefaults());
    extensions.set(EXTENDS_AXES_LABEL_DEFAULTS, _ChartTheme.getAxisDefaults().label);
    extensions.set(EXTENDS_AXES_LINE_DEFAULTS, _ChartTheme.getAxisDefaults().line);
    extensions.set(EXTENDS_AXES_TICK_DEFAULTS, _ChartTheme.getAxisDefaults().tick);
    extensions.set(EXTENDS_AXES_GRID_LINE_DEFAULTS, _ChartTheme.getAxisDefaults().gridLine);
    extensions.set(EXTENDS_SERIES_DEFAULTS, _ChartTheme.getSeriesDefaults());
    extensions.set(OVERRIDE_SERIES_LABEL_DEFAULTS, {});
    extensions.set(EXTENDS_CARTESIAN_MARKER_DEFAULTS, _ChartTheme.getCartesianSeriesMarkerDefaults());
    const properties = /* @__PURE__ */ new Map();
    properties.set(DEFAULT_FONT_FAMILY, "Verdana, sans-serif");
    properties.set(DEFAULT_LABEL_COLOUR, "rgb(70, 70, 70)");
    properties.set(DEFAULT_INVERTED_LABEL_COLOUR, "white");
    properties.set(DEFAULT_MUTED_LABEL_COLOUR, "rgb(140, 140, 140)");
    properties.set(DEFAULT_AXIS_GRID_COLOUR, "rgb(224,234,241)");
    properties.set(DEFAULT_AXIS_LINE_COLOUR, "rgb(195, 195, 195)");
    properties.set(DEFAULT_CROSS_LINES_COLOUR, "rgb(70, 70, 70)");
    properties.set(DEFAULT_INSIDE_SERIES_LABEL_COLOUR, DEFAULT_BACKGROUND_FILL);
    properties.set(DEFAULT_BACKGROUND_COLOUR, DEFAULT_BACKGROUND_FILL);
    properties.set(DEFAULT_SHADOW_COLOUR, "rgba(0, 0, 0, 0.5)");
    properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE, [
      DEFAULT_FILLS.ORANGE,
      DEFAULT_FILLS.YELLOW,
      DEFAULT_FILLS.GREEN
    ]);
    properties.set(DEFAULT_HIERARCHY_FILLS, ["#ffffff", "#e0e5ea", "#c1ccd5", "#a3b4c1", "#859cad"]);
    properties.set(DEFAULT_HIERARCHY_STROKES, ["#ffffff", "#c5cbd1", "#a4b1bd", "#8498a9", "#648096"]);
    properties.set(DEFAULT_POLAR_SERIES_STROKE, DEFAULT_BACKGROUND_FILL);
    properties.set(DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS, _ChartTheme.getWaterfallSeriesDefaultPositiveColors());
    properties.set(DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS, _ChartTheme.getWaterfallSeriesDefaultNegativeColors());
    properties.set(DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS, _ChartTheme.getWaterfallSeriesDefaultTotalColors());
    properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE,
      _ChartTheme.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return {
      extensions,
      properties
    };
  }
};
_ChartTheme.cartesianAxisDefault = {
  number: __spreadProps(__spreadValues({}, _ChartTheme.getAxisDefaults()), {
    line: __spreadProps(__spreadValues({}, _ChartTheme.getAxisDefaults().line), {
      enabled: false
    })
  }),
  log: __spreadProps(__spreadValues({}, _ChartTheme.getAxisDefaults()), {
    base: 10,
    line: __spreadProps(__spreadValues({}, _ChartTheme.getAxisDefaults().line), {
      enabled: false
    })
  }),
  category: __spreadProps(__spreadValues({}, _ChartTheme.getAxisDefaults()), {
    groupPaddingInner: 0.1,
    label: __spreadProps(__spreadValues({}, _ChartTheme.getAxisDefaults().label), {
      autoRotate: true
    }),
    gridLine: __spreadProps(__spreadValues({}, _ChartTheme.getAxisDefaults().gridLine), {
      enabled: false
    })
  }),
  "grouped-category": __spreadValues({}, _ChartTheme.getAxisDefaults()),
  time: __spreadProps(__spreadValues({}, _ChartTheme.getAxisDefaults()), {
    gridLine: __spreadProps(__spreadValues({}, _ChartTheme.getAxisDefaults().gridLine), {
      enabled: false
    })
  })
};
var ChartTheme = _ChartTheme;

// packages/ag-charts-community/src/chart/themes/darkTheme.ts
var DEFAULT_DARK_BACKGROUND_FILL = "#192232";
var DEFAULT_DARK_FILLS = {
  BLUE: "#5090dc",
  ORANGE: "#ffa03a",
  GREEN: "#459d55",
  CYAN: "#34bfe1",
  YELLOW: "#e1cc00",
  VIOLET: "#9669cb",
  GRAY: "#b5b5b5",
  MAGENTA: "#bd5aa7",
  BROWN: "#8a6224",
  RED: "#ef5452"
};
var DEFAULT_DARK_STROKES = {
  BLUE: "#74a8e6",
  ORANGE: "#ffbe70",
  GREEN: "#6cb176",
  CYAN: "#75d4ef",
  YELLOW: "#f6e559",
  VIOLET: "#aa86d8",
  GRAY: "#a1a1a1",
  MAGENTA: "#ce7ab9",
  BROWN: "#997b52",
  RED: "#ff7872"
};
var palette2 = {
  fills: Array.from(Object.values(DEFAULT_DARK_FILLS)),
  strokes: Array.from(Object.values(DEFAULT_DARK_STROKES))
};
var DarkTheme = class _DarkTheme extends ChartTheme {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: DEFAULT_DARK_FILLS.BLUE,
      stroke: DEFAULT_DARK_STROKES.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: DEFAULT_DARK_FILLS.ORANGE,
      stroke: DEFAULT_DARK_STROKES.ORANGE
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: DEFAULT_DARK_FILLS.GRAY,
      stroke: DEFAULT_DARK_STROKES.GRAY
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS,
      _DarkTheme.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS,
      _DarkTheme.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS, _DarkTheme.getWaterfallSeriesDefaultTotalColors());
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE,
      _DarkTheme.getWaterfallSeriesDefaultTotalColors().stroke
    );
    result.properties.set(DEFAULT_POLAR_SERIES_STROKE, DEFAULT_DARK_BACKGROUND_FILL);
    result.properties.set(DEFAULT_LABEL_COLOUR, "white");
    result.properties.set(DEFAULT_MUTED_LABEL_COLOUR, "#7D91A0");
    result.properties.set(DEFAULT_AXIS_GRID_COLOUR, "#545A6E");
    result.properties.set(DEFAULT_CROSS_LINES_COLOUR, "white");
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE, [
      DEFAULT_DARK_FILLS.ORANGE,
      DEFAULT_DARK_FILLS.YELLOW,
      DEFAULT_DARK_FILLS.GREEN
    ]);
    result.properties.set(DEFAULT_HIERARCHY_FILLS, ["#192834", "#253746", "#324859", "#3f596c", "#4d6a80"]);
    result.properties.set(DEFAULT_HIERARCHY_STROKES, ["#192834", "#3b5164", "#496275", "#577287", "#668399"]);
    result.properties.set(DEFAULT_BACKGROUND_COLOUR, DEFAULT_DARK_BACKGROUND_FILL);
    result.properties.set(DEFAULT_INSIDE_SERIES_LABEL_COLOUR, DEFAULT_DARK_BACKGROUND_FILL);
    return result;
  }
  getPalette() {
    return palette2;
  }
  constructor(options) {
    super(options);
  }
};

// packages/ag-charts-community/src/chart/themes/materialDark.ts
var MATERIAL_DARK_FILLS = {
  BLUE: "#2196F3",
  ORANGE: "#FF9800",
  GREEN: "#4CAF50",
  CYAN: "#00BCD4",
  YELLOW: "#FFEB3B",
  VIOLET: "#7E57C2",
  GRAY: "#9E9E9E",
  MAGENTA: "#F06292",
  BROWN: "#795548",
  RED: "#F44336"
};
var MATERIAL_DARK_STROKES = {
  BLUE: "#90CAF9",
  ORANGE: "#FFCC80",
  GREEN: "#A5D6A7",
  CYAN: "#80DEEA",
  YELLOW: "#FFF9C4",
  VIOLET: "#B39DDB",
  GRAY: "#E0E0E0",
  MAGENTA: "#F48FB1",
  BROWN: "#A1887F",
  RED: "#EF9A9A"
};
var palette3 = {
  fills: Array.from(Object.values(MATERIAL_DARK_FILLS)),
  strokes: Array.from(Object.values(MATERIAL_DARK_STROKES))
};
var MaterialDark = class _MaterialDark extends DarkTheme {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: MATERIAL_DARK_FILLS.BLUE,
      stroke: MATERIAL_DARK_STROKES.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: MATERIAL_DARK_FILLS.RED,
      stroke: MATERIAL_DARK_STROKES.RED
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: MATERIAL_DARK_FILLS.GRAY,
      stroke: MATERIAL_DARK_STROKES.GRAY
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS,
      _MaterialDark.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS,
      _MaterialDark.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS,
      _MaterialDark.getWaterfallSeriesDefaultTotalColors()
    );
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE, [
      MATERIAL_DARK_FILLS.ORANGE,
      MATERIAL_DARK_FILLS.YELLOW,
      MATERIAL_DARK_FILLS.GREEN
    ]);
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE,
      _MaterialDark.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return result;
  }
  getPalette() {
    return palette3;
  }
};

// packages/ag-charts-community/src/chart/themes/materialLight.ts
var MATERIAL_LIGHT_FILLS = {
  BLUE: "#2196F3",
  ORANGE: "#FF9800",
  GREEN: "#4CAF50",
  CYAN: "#00BCD4",
  YELLOW: "#FFEB3B",
  VIOLET: "#7E57C2",
  GRAY: "#9E9E9E",
  MAGENTA: "#F06292",
  BROWN: "#795548",
  RED: "#F44336"
};
var MATERIAL_LIGHT_STROKES = {
  BLUE: "#1565C0",
  ORANGE: "#E65100",
  GREEN: "#2E7D32",
  CYAN: "#00838F",
  YELLOW: "#F9A825",
  VIOLET: "#4527A0",
  GRAY: "#616161",
  MAGENTA: "#C2185B",
  BROWN: "#4E342E",
  RED: "#B71C1C"
};
var palette4 = {
  fills: Array.from(Object.values(MATERIAL_LIGHT_FILLS)),
  strokes: Array.from(Object.values(MATERIAL_LIGHT_STROKES))
};
var MaterialLight = class _MaterialLight extends ChartTheme {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: MATERIAL_LIGHT_FILLS.BLUE,
      stroke: MATERIAL_LIGHT_STROKES.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: MATERIAL_LIGHT_FILLS.RED,
      stroke: MATERIAL_LIGHT_STROKES.RED
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: MATERIAL_LIGHT_FILLS.GRAY,
      stroke: MATERIAL_LIGHT_STROKES.GRAY
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS,
      _MaterialLight.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS,
      _MaterialLight.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS,
      _MaterialLight.getWaterfallSeriesDefaultTotalColors()
    );
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE, [
      MATERIAL_LIGHT_FILLS.ORANGE,
      MATERIAL_LIGHT_FILLS.YELLOW,
      MATERIAL_LIGHT_FILLS.GREEN
    ]);
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE,
      _MaterialLight.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return result;
  }
  getPalette() {
    return palette4;
  }
};

// packages/ag-charts-community/src/chart/themes/polychromaDark.ts
var POLYCHROMA_DARK_FILLS = {
  BLUE: "#436ff4",
  PURPLE: "#9a7bff",
  MAGENTA: "#d165d2",
  PINK: "#f0598b",
  RED: "#f47348",
  ORANGE: "#f2a602",
  YELLOW: "#e9e201",
  GREEN: "#21b448",
  CYAN: "#00b9a2",
  MODERATE_BLUE: "#00aee4"
};
var POLYCHROMA_DARK_STROKES = {
  BLUE: "#6698ff",
  PURPLE: "#c0a3ff",
  MAGENTA: "#fc8dfc",
  PINK: "#ff82b1",
  RED: "#ff9b70",
  ORANGE: "#ffcf4e",
  YELLOW: "#ffff58",
  GREEN: "#58dd70",
  CYAN: "#51e2c9",
  MODERATE_BLUE: "#4fd7ff"
};
var POLYCHROMA_DARK_FILL_GRAY = "#bbbbbb";
var POLYCHROMA_DARK_STROKE_GRAY = "#eeeeee";
var palette5 = {
  fills: Array.from(Object.values(POLYCHROMA_DARK_FILLS)),
  strokes: Array.from(Object.values(POLYCHROMA_DARK_STROKES))
};
var PolychromaDark = class _PolychromaDark extends DarkTheme {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: POLYCHROMA_DARK_FILLS.BLUE,
      stroke: POLYCHROMA_DARK_STROKES.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: POLYCHROMA_DARK_FILLS.RED,
      stroke: POLYCHROMA_DARK_STROKES.RED
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: POLYCHROMA_DARK_FILL_GRAY,
      stroke: POLYCHROMA_DARK_STROKE_GRAY
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS,
      _PolychromaDark.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS,
      _PolychromaDark.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS,
      _PolychromaDark.getWaterfallSeriesDefaultTotalColors()
    );
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE, [
      POLYCHROMA_DARK_FILLS.BLUE,
      POLYCHROMA_DARK_FILLS.RED
    ]);
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE,
      _PolychromaDark.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return result;
  }
  getPalette() {
    return palette5;
  }
};

// packages/ag-charts-community/src/chart/themes/polychromaLight.ts
var POLYCHROMA_LIGHT_FILLS = {
  BLUE: "#436ff4",
  PURPLE: "#9a7bff",
  MAGENTA: "#d165d2",
  PINK: "#f0598b",
  RED: "#f47348",
  ORANGE: "#f2a602",
  YELLOW: "#e9e201",
  GREEN: "#21b448",
  CYAN: "#00b9a2",
  MODERATE_BLUE: "#00aee4"
};
var POLYCHROMA_LIGHT_STROKES = {
  BLUE: "#2346c9",
  PURPLE: "#7653d4",
  MAGENTA: "#a73da9",
  PINK: "#c32d66",
  RED: "#c84b1c",
  ORANGE: "#c87f00",
  YELLOW: "#c1b900",
  GREEN: "#008c1c",
  CYAN: "#00927c",
  MODERATE_BLUE: "#0087bb"
};
var POLYCHROMA_LIGHT_FILL_GRAY = "#bbbbbb";
var POLYCHROMA_LIGHT_STROKE_GRAY = "#888888";
var palette6 = {
  fills: Array.from(Object.values(POLYCHROMA_LIGHT_FILLS)),
  strokes: Array.from(Object.values(POLYCHROMA_LIGHT_STROKES))
};
var PolychromaLight = class _PolychromaLight extends ChartTheme {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: POLYCHROMA_LIGHT_FILLS.BLUE,
      stroke: POLYCHROMA_LIGHT_STROKES.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: POLYCHROMA_LIGHT_FILLS.RED,
      stroke: POLYCHROMA_LIGHT_STROKES.RED
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: POLYCHROMA_LIGHT_FILL_GRAY,
      stroke: POLYCHROMA_LIGHT_STROKE_GRAY
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS,
      _PolychromaLight.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS,
      _PolychromaLight.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS,
      _PolychromaLight.getWaterfallSeriesDefaultTotalColors()
    );
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE, [
      POLYCHROMA_LIGHT_FILLS.BLUE,
      POLYCHROMA_LIGHT_FILLS.RED
    ]);
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE,
      _PolychromaLight.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return result;
  }
  getPalette() {
    return palette6;
  }
};

// packages/ag-charts-community/src/chart/themes/sheetsDark.ts
var SHEETS_DARK_FILLS = {
  BLUE: "#4472C4",
  ORANGE: "#ED7D31",
  GRAY: "#A5A5A5",
  YELLOW: "#FFC000",
  MODERATE_BLUE: "#5B9BD5",
  GREEN: "#70AD47",
  DARK_GRAY: "#7B7B7B",
  DARK_BLUE: "#264478",
  VERY_DARK_GRAY: "#636363",
  DARK_YELLOW: "#997300"
};
var SHEETS_DARK_STROKES = {
  BLUE: "#6899ee",
  ORANGE: "#ffa55d",
  GRAY: "#cdcdcd",
  YELLOW: "#ffea53",
  MODERATE_BLUE: "#82c3ff",
  GREEN: "#96d56f",
  DARK_GRAY: "#a1a1a1",
  DARK_BLUE: "#47689f",
  VERY_DARK_GRAY: "#878787",
  DARK_YELLOW: "#c0993d"
};
var palette7 = {
  fills: Array.from(Object.values(SHEETS_DARK_FILLS)),
  strokes: Array.from(Object.values(SHEETS_DARK_STROKES))
};
var SheetsDark = class _SheetsDark extends DarkTheme {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: SHEETS_DARK_FILLS.BLUE,
      stroke: SHEETS_DARK_STROKES.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: SHEETS_DARK_FILLS.ORANGE,
      stroke: SHEETS_DARK_STROKES.ORANGE
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: SHEETS_DARK_FILLS.GRAY,
      stroke: SHEETS_DARK_STROKES.GRAY
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS,
      _SheetsDark.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS,
      _SheetsDark.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS,
      _SheetsDark.getWaterfallSeriesDefaultTotalColors()
    );
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE, [
      SHEETS_DARK_FILLS.ORANGE,
      SHEETS_DARK_FILLS.YELLOW,
      SHEETS_DARK_FILLS.GREEN
    ]);
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE,
      _SheetsDark.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return result;
  }
  getPalette() {
    return palette7;
  }
};

// packages/ag-charts-community/src/chart/themes/sheetsLight.ts
var SHEETS_LIGHT_FILLS = {
  BLUE: "#5281d5",
  ORANGE: "#ff8d44",
  GRAY: "#b5b5b5",
  YELLOW: "#ffd02f",
  MODERATE_BLUE: "#6aabe6",
  GREEN: "#7fbd57",
  DARK_GRAY: "#8a8a8a",
  DARK_BLUE: "#335287",
  VERY_DARK_GRAY: "#717171",
  DARK_YELLOW: "#a98220"
};
var SHEETS_LIGHT_STROKES = {
  BLUE: "#214d9b",
  ORANGE: "#c25600",
  GRAY: "#7f7f7f",
  YELLOW: "#d59800",
  MODERATE_BLUE: "#3575ac",
  GREEN: "#4b861a",
  DARK_GRAY: "#575757",
  DARK_BLUE: "#062253",
  VERY_DARK_GRAY: "#414141",
  DARK_YELLOW: "#734f00"
};
var palette8 = {
  fills: Array.from(Object.values(SHEETS_LIGHT_FILLS)),
  strokes: Array.from(Object.values(SHEETS_LIGHT_STROKES))
};
var SheetsLight = class _SheetsLight extends ChartTheme {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: SHEETS_LIGHT_FILLS.BLUE,
      stroke: SHEETS_LIGHT_STROKES.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: SHEETS_LIGHT_FILLS.ORANGE,
      stroke: SHEETS_LIGHT_STROKES.ORANGE
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: SHEETS_LIGHT_FILLS.GRAY,
      stroke: SHEETS_LIGHT_STROKES.GRAY
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS,
      _SheetsLight.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS,
      _SheetsLight.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS,
      _SheetsLight.getWaterfallSeriesDefaultTotalColors()
    );
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE, [
      SHEETS_LIGHT_FILLS.ORANGE,
      SHEETS_LIGHT_FILLS.YELLOW,
      SHEETS_LIGHT_FILLS.GREEN
    ]);
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE,
      _SheetsLight.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return result;
  }
  getPalette() {
    return palette8;
  }
};

// packages/ag-charts-community/src/chart/themes/vividDark.ts
var VIVID_DARK_FILLS = {
  BLUE: "#0083ff",
  ORANGE: "#ff6600",
  GREEN: "#00af00",
  CYAN: "#00ccff",
  YELLOW: "#f7c700",
  VIOLET: "#ac26ff",
  GRAY: "#a7a7b7",
  MAGENTA: "#e800c5",
  BROWN: "#b54300",
  RED: "#ff0000"
};
var VIVID_DARK_STROKES = {
  BLUE: "#67b7ff",
  ORANGE: "#ffc24d",
  GREEN: "#5cc86f",
  CYAN: "#54ebff",
  VIOLET: "#c18aff",
  YELLOW: "#fff653",
  GRAY: "#aeaeae",
  MAGENTA: "#f078d4",
  BROWN: "#ba8438",
  RED: "#ff726e"
};
var palette9 = {
  fills: Array.from(Object.values(VIVID_DARK_FILLS)),
  strokes: Array.from(Object.values(VIVID_DARK_STROKES))
};
var VividDark = class _VividDark extends DarkTheme {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: VIVID_DARK_FILLS.BLUE,
      stroke: VIVID_DARK_STROKES.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: VIVID_DARK_FILLS.ORANGE,
      stroke: VIVID_DARK_STROKES.ORANGE
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: VIVID_DARK_FILLS.GRAY,
      stroke: VIVID_DARK_STROKES.GRAY
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS,
      _VividDark.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS,
      _VividDark.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS, _VividDark.getWaterfallSeriesDefaultTotalColors());
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE, [
      VIVID_DARK_FILLS.ORANGE,
      VIVID_DARK_FILLS.YELLOW,
      VIVID_DARK_FILLS.GREEN
    ]);
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE,
      _VividDark.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return result;
  }
  getPalette() {
    return palette9;
  }
};

// packages/ag-charts-community/src/chart/themes/vividLight.ts
var VIVID_FILLS = {
  BLUE: "#0083ff",
  ORANGE: "#ff6600",
  GREEN: "#00af00",
  CYAN: "#00ccff",
  YELLOW: "#f7c700",
  VIOLET: "#ac26ff",
  GRAY: "#a7a7b7",
  MAGENTA: "#e800c5",
  BROWN: "#b54300",
  RED: "#ff0000"
};
var VIVID_STROKES = {
  BLUE: "#0f68c0",
  ORANGE: "#d47100",
  GREEN: "#007922",
  CYAN: "#009ac2",
  VIOLET: "#bca400",
  YELLOW: "#753cac",
  GRAY: "#646464",
  MAGENTA: "#9b2685",
  BROWN: "#6c3b00",
  RED: "#cb0021"
};
var palette10 = {
  fills: Array.from(Object.values(VIVID_FILLS)),
  strokes: Array.from(Object.values(VIVID_STROKES))
};
var VividLight = class _VividLight extends ChartTheme {
  static getWaterfallSeriesDefaultPositiveColors() {
    return {
      fill: VIVID_FILLS.BLUE,
      stroke: VIVID_STROKES.BLUE
    };
  }
  static getWaterfallSeriesDefaultNegativeColors() {
    return {
      fill: VIVID_FILLS.ORANGE,
      stroke: VIVID_STROKES.ORANGE
    };
  }
  static getWaterfallSeriesDefaultTotalColors() {
    return {
      fill: VIVID_FILLS.GRAY,
      stroke: VIVID_STROKES.GRAY
    };
  }
  getTemplateParameters() {
    const result = super.getTemplateParameters();
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS,
      _VividLight.getWaterfallSeriesDefaultPositiveColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS,
      _VividLight.getWaterfallSeriesDefaultNegativeColors()
    );
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS,
      _VividLight.getWaterfallSeriesDefaultTotalColors()
    );
    result.properties.set(DEFAULT_DIVERGING_SERIES_COLOUR_RANGE, [
      VIVID_FILLS.ORANGE,
      VIVID_FILLS.YELLOW,
      VIVID_FILLS.GREEN
    ]);
    result.properties.set(
      DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE,
      _VividLight.getWaterfallSeriesDefaultTotalColors().stroke
    );
    return result;
  }
  getPalette() {
    return palette10;
  }
};

// packages/ag-charts-community/src/chart/mapping/themes.ts
var lightTheme = () => new ChartTheme();
var darkTheme = () => new DarkTheme();
var lightThemes = {
  undefined: lightTheme,
  null: lightTheme,
  "ag-default": lightTheme,
  "ag-sheets": () => new SheetsLight(),
  "ag-polychroma": () => new PolychromaLight(),
  "ag-vivid": () => new VividLight(),
  "ag-material": () => new MaterialLight()
};
var darkThemes = {
  undefined: darkTheme,
  null: darkTheme,
  "ag-default-dark": darkTheme,
  "ag-sheets-dark": () => new SheetsDark(),
  "ag-polychroma-dark": () => new PolychromaDark(),
  "ag-vivid-dark": () => new VividDark(),
  "ag-material-dark": () => new MaterialDark()
};
var themes = __spreadValues(__spreadValues({}, darkThemes), lightThemes);
function validateChartThemeObject(unknownObject) {
  if (unknownObject === null) {
    return void 0;
  }
  let valid = true;
  const { baseTheme, palette: palette11, overrides } = unknownObject;
  if (baseTheme !== void 0 && typeof baseTheme !== "string" && typeof baseTheme !== "object") {
    Logger.warn(`invalid theme.baseTheme type ${typeof baseTheme}, expected (string | object).`);
    valid = false;
  }
  if (overrides !== void 0 && typeof overrides !== "object") {
    Logger.warn(`invalid theme.overrides type ${typeof overrides}, expected object.`);
    valid = false;
  }
  if (typeof palette11 === "object") {
    if (palette11 !== null) {
      const { fills, strokes } = palette11;
      if (fills !== void 0 && !Array.isArray(fills)) {
        Logger.warn(`theme.overrides.fills must be undefined or an array`);
        valid = false;
      }
      if (strokes !== void 0 && !Array.isArray(strokes)) {
        Logger.warn(`theme.overrides.strokes must be undefined or an array`);
        valid = false;
      }
    }
  } else if (palette11 !== void 0) {
    Logger.warn(`invalid theme.palette type ${typeof palette11}, expected object.`);
    valid = false;
  }
  if (valid) {
    return unknownObject;
  }
  return void 0;
}
function validateChartTheme(value) {
  if (value === void 0 || typeof value === "string" || value instanceof ChartTheme) {
    return value;
  }
  if (typeof value === "object") {
    return validateChartThemeObject(value);
  }
  Logger.warn(`invalid theme value type ${typeof value}, expected object.`);
  return void 0;
}
function getChartTheme(unvalidatedValue) {
  var _a;
  let value = validateChartTheme(unvalidatedValue);
  if (value instanceof ChartTheme) {
    return value;
  }
  if (value === void 0 || typeof value === "string") {
    const stockTheme = themes[value];
    if (stockTheme) {
      return stockTheme();
    }
    Logger.warnOnce(`the theme [${value}] is invalid, using [ag-default] instead.`);
    return lightTheme();
  }
  const overrides = [];
  let palette11;
  while (typeof value === "object") {
    overrides.push((_a = value.overrides) != null ? _a : {});
    if (value.palette && palette11 == null) {
      palette11 = value.palette;
    }
    value = value.baseTheme;
  }
  overrides.reverse();
  const flattenedTheme = __spreadValues({
    baseTheme: value,
    overrides: jsonMerge(overrides)
  }, palette11 ? { palette: palette11 } : {});
  const baseTheme = flattenedTheme.baseTheme ? getChartTheme(flattenedTheme.baseTheme) : lightTheme();
  return new baseTheme.constructor(flattenedTheme);
}

// packages/ag-charts-community/src/chart/polarChart.ts
var _PolarChart = class _PolarChart extends Chart {
  constructor(specialOverrides, resources) {
    super(specialOverrides, resources);
    this.padding = new Padding$1(40);
    this.axisGroup.zIndex = 5 /* AXIS_FOREGROUND_ZINDEX */;
  }
  performLayout() {
    return __async(this, null, function* () {
      const shrinkRect = yield __superGet(_PolarChart.prototype, this, "performLayout").call(this);
      const fullSeriesRect = shrinkRect.clone();
      this.computeSeriesRect(shrinkRect);
      yield this.computeCircle(shrinkRect);
      this.axes.forEach((axis) => axis.update());
      this.hoverRect = shrinkRect;
      this.layoutService.dispatchLayoutComplete({
        type: "layout-complete",
        chart: { width: this.scene.width, height: this.scene.height },
        clipSeries: false,
        series: { rect: fullSeriesRect, paddedRect: shrinkRect, visible: true },
        axes: []
      });
      return shrinkRect;
    });
  }
  updateAxes(cx, cy, radius) {
    var _a, _b;
    const angleAxis = this.axes.find((axis) => axis.direction === "x" /* X */);
    const radiusAxis = this.axes.find((axis) => axis.direction === "y" /* Y */);
    if (!(angleAxis instanceof PolarAxis) || !(radiusAxis instanceof PolarAxis)) {
      return;
    }
    const angleScale = angleAxis.scale;
    const angles = (_a = angleScale.ticks) == null ? void 0 : _a.call(angleScale).map((value) => angleScale.convert(value));
    const innerRadiusRatio = radiusAxis.innerRadiusRatio;
    angleAxis.innerRadiusRatio = innerRadiusRatio;
    (_b = angleAxis.computeRange) == null ? void 0 : _b.call(angleAxis);
    angleAxis.gridLength = radius;
    radiusAxis.gridAngles = angles;
    radiusAxis.gridRange = angleAxis.range;
    radiusAxis.range = [radius, radius * innerRadiusRatio];
    [angleAxis, radiusAxis].forEach((axis) => {
      axis.translation.x = cx;
      axis.translation.y = cy;
      axis.calculateLayout();
    });
  }
  computeSeriesRect(shrinkRect) {
    const {
      seriesArea: { padding }
    } = this;
    shrinkRect.shrink(padding.left, "left");
    shrinkRect.shrink(padding.top, "top");
    shrinkRect.shrink(padding.right, "right");
    shrinkRect.shrink(padding.bottom, "bottom");
    this.seriesRect = shrinkRect;
    this.animationRect = shrinkRect;
  }
  computeCircle(seriesBox) {
    return __async(this, null, function* () {
      const polarSeries = this.series.filter((series) => {
        return series instanceof PolarSeries;
      });
      const polarAxes = this.axes.filter((axis) => {
        return axis instanceof PolarAxis;
      });
      const setSeriesCircle = (cx, cy, r) => {
        this.updateAxes(cx, cy, r);
        polarSeries.forEach((series) => {
          series.centerX = cx;
          series.centerY = cy;
          series.radius = r;
        });
        const pieSeries = polarSeries.filter((s) => s instanceof PieSeries);
        if (pieSeries.length > 1) {
          const innerRadii = pieSeries.map((series) => {
            const innerRadius = series.getInnerRadius();
            return { series, innerRadius };
          }).sort((a, b) => a.innerRadius - b.innerRadius);
          innerRadii[innerRadii.length - 1].series.surroundingRadius = void 0;
          for (let i = 0; i < innerRadii.length - 1; i++) {
            innerRadii[i].series.surroundingRadius = innerRadii[i + 1].innerRadius;
          }
        }
      };
      const centerX = seriesBox.x + seriesBox.width / 2;
      const centerY = seriesBox.y + seriesBox.height / 2;
      const initialRadius = Math.max(0, Math.min(seriesBox.width, seriesBox.height) / 2);
      let radius = initialRadius;
      setSeriesCircle(centerX, centerY, radius);
      const shake = (..._0) => __async(this, [..._0], function* ({ hideWhenNecessary = false } = {}) {
        const labelBoxes = [];
        for (const series of [...polarAxes, ...polarSeries]) {
          const box = yield series.computeLabelsBBox({ hideWhenNecessary }, seriesBox);
          if (box) {
            labelBoxes.push(box);
          }
        }
        if (labelBoxes.length === 0) {
          setSeriesCircle(centerX, centerY, initialRadius);
          return;
        }
        const labelBox = BBox.merge(labelBoxes);
        const refined = this.refineCircle(labelBox, radius, seriesBox);
        setSeriesCircle(refined.centerX, refined.centerY, refined.radius);
        if (refined.radius === radius) {
          return;
        }
        radius = refined.radius;
      });
      yield shake();
      yield shake();
      yield shake();
      yield shake({ hideWhenNecessary: true });
      yield shake({ hideWhenNecessary: true });
      return { radius, centerX, centerY };
    });
  }
  refineCircle(labelsBox, radius, seriesBox) {
    const minCircleRatio = 0.5;
    const circleLeft = -radius;
    const circleTop = -radius;
    const circleRight = radius;
    const circleBottom = radius;
    let padLeft = Math.max(0, circleLeft - labelsBox.x);
    let padTop = Math.max(0, circleTop - labelsBox.y);
    let padRight = Math.max(0, labelsBox.x + labelsBox.width - circleRight);
    let padBottom = Math.max(0, labelsBox.y + labelsBox.height - circleBottom);
    padLeft = padRight = Math.max(padLeft, padRight);
    padTop = padBottom = Math.max(padTop, padBottom);
    const availCircleWidth = seriesBox.width - padLeft - padRight;
    const availCircleHeight = seriesBox.height - padTop - padBottom;
    let newRadius = Math.min(availCircleWidth, availCircleHeight) / 2;
    const minHorizontalRadius = minCircleRatio * seriesBox.width / 2;
    const minVerticalRadius = minCircleRatio * seriesBox.height / 2;
    const minRadius = Math.min(minHorizontalRadius, minVerticalRadius);
    if (newRadius < minRadius) {
      newRadius = minRadius;
      const horizontalPadding = padLeft + padRight;
      const verticalPadding = padTop + padBottom;
      if (2 * newRadius + verticalPadding > seriesBox.height) {
        const padHeight = seriesBox.height - 2 * newRadius;
        if (Math.min(padTop, padBottom) * 2 > padHeight) {
          padTop = padHeight / 2;
          padBottom = padHeight / 2;
        } else if (padTop > padBottom) {
          padTop = padHeight - padBottom;
        } else {
          padBottom = padHeight - padTop;
        }
      }
      if (2 * newRadius + horizontalPadding > seriesBox.width) {
        const padWidth = seriesBox.width - 2 * newRadius;
        if (Math.min(padLeft, padRight) * 2 > padWidth) {
          padLeft = padWidth / 2;
          padRight = padWidth / 2;
        } else if (padLeft > padRight) {
          padLeft = padWidth - padRight;
        } else {
          padRight = padWidth - padLeft;
        }
      }
    }
    const newWidth = padLeft + 2 * newRadius + padRight;
    const newHeight = padTop + 2 * newRadius + padBottom;
    return {
      centerX: seriesBox.x + (seriesBox.width - newWidth) / 2 + padLeft + newRadius,
      centerY: seriesBox.y + (seriesBox.height - newHeight) / 2 + padTop + newRadius,
      radius: newRadius
    };
  }
};
_PolarChart.className = "PolarChart";
_PolarChart.type = "polar";

// packages/ag-charts-community/src/integrated-charts-theme.ts
var integrated_charts_theme_exports = {};
__export(integrated_charts_theme_exports, {
  BOTTOM: () => BOTTOM,
  CARTESIAN_AXIS_POSITIONS: () => CARTESIAN_AXIS_POSITIONS,
  CARTESIAN_AXIS_TYPES: () => CARTESIAN_AXIS_TYPES,
  CIRCLE: () => CIRCLE,
  ChartTheme: () => ChartTheme,
  DEFAULT_AXIS_GRID_COLOUR: () => DEFAULT_AXIS_GRID_COLOUR,
  DEFAULT_AXIS_LINE_COLOUR: () => DEFAULT_AXIS_LINE_COLOUR,
  DEFAULT_BACKGROUND_COLOUR: () => DEFAULT_BACKGROUND_COLOUR,
  DEFAULT_CROSS_LINES_COLOUR: () => DEFAULT_CROSS_LINES_COLOUR,
  DEFAULT_DIVERGING_SERIES_COLOUR_RANGE: () => DEFAULT_DIVERGING_SERIES_COLOUR_RANGE,
  DEFAULT_FONT_FAMILY: () => DEFAULT_FONT_FAMILY,
  DEFAULT_HIERARCHY_FILLS: () => DEFAULT_HIERARCHY_FILLS,
  DEFAULT_HIERARCHY_STROKES: () => DEFAULT_HIERARCHY_STROKES,
  DEFAULT_INSIDE_SERIES_LABEL_COLOUR: () => DEFAULT_INSIDE_SERIES_LABEL_COLOUR,
  DEFAULT_INVERTED_LABEL_COLOUR: () => DEFAULT_INVERTED_LABEL_COLOUR,
  DEFAULT_LABEL_COLOUR: () => DEFAULT_LABEL_COLOUR,
  DEFAULT_MUTED_LABEL_COLOUR: () => DEFAULT_MUTED_LABEL_COLOUR,
  DEFAULT_POLAR_SERIES_STROKE: () => DEFAULT_POLAR_SERIES_STROKE,
  DEFAULT_SHADOW_COLOUR: () => DEFAULT_SHADOW_COLOUR,
  DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE: () => DEFAULT_WATERFALL_SERIES_CONNECTOR_LINE_STROKE,
  DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS: () => DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS,
  DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS: () => DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS,
  DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS: () => DEFAULT_WATERFALL_SERIES_TOTAL_COLOURS,
  EXTENDS_AXES_DEFAULTS: () => EXTENDS_AXES_DEFAULTS,
  EXTENDS_AXES_GRID_LINE_DEFAULTS: () => EXTENDS_AXES_GRID_LINE_DEFAULTS,
  EXTENDS_AXES_LABEL_DEFAULTS: () => EXTENDS_AXES_LABEL_DEFAULTS,
  EXTENDS_AXES_LINE_DEFAULTS: () => EXTENDS_AXES_LINE_DEFAULTS,
  EXTENDS_AXES_TICK_DEFAULTS: () => EXTENDS_AXES_TICK_DEFAULTS,
  EXTENDS_CARTESIAN_MARKER_DEFAULTS: () => EXTENDS_CARTESIAN_MARKER_DEFAULTS,
  EXTENDS_CHART_DEFAULTS: () => EXTENDS_CHART_DEFAULTS,
  EXTENDS_LEGEND_DEFAULTS: () => EXTENDS_LEGEND_DEFAULTS,
  EXTENDS_LEGEND_ITEM_DEFAULTS: () => EXTENDS_LEGEND_ITEM_DEFAULTS,
  EXTENDS_LEGEND_ITEM_MARKER_DEFAULTS: () => EXTENDS_LEGEND_ITEM_MARKER_DEFAULTS,
  EXTENDS_SERIES_DEFAULTS: () => EXTENDS_SERIES_DEFAULTS,
  FONT_SIZE: () => FONT_SIZE,
  FONT_WEIGHT: () => FONT_WEIGHT2,
  OVERRIDE_SERIES_LABEL_DEFAULTS: () => OVERRIDE_SERIES_LABEL_DEFAULTS,
  POLAR_AXIS_TYPES: () => POLAR_AXIS_TYPES,
  getChartTheme: () => getChartTheme,
  themes: () => themes2
});
var themes2 = Object.entries(themes).reduce(
  (obj, [name, factory]) => {
    obj[name] = factory();
    return obj;
  },
  {}
);

// packages/ag-charts-community/src/sparklines-scale.ts
var sparklines_scale_exports = {};
__export(sparklines_scale_exports, {
  BandScale: () => BandScale$5,
  ColorScale: () => ColorScale,
  ContinuousScale: () => ContinuousScale,
  Invalidating: () => Invalidating,
  LinearScale: () => LinearScale$1,
  TimeScale: () => TimeScale$1
});

var defaultTooltipCss = "\n.ag-sparkline-tooltip-wrapper {\n    position: absolute;\n    user-select: none;\n    pointer-events: none;\n}\n\n.ag-sparkline-tooltip {\n    position: relative;\n    font: 12px arial,sans-serif;\n    border-radius: 2px;\n    box-shadow: 0 1px 3px rgb(0 0 0 / 20%), 0 1px 1px rgb(0 0 0 / 14%);\n    line-height: 1.7em;\n    overflow: hidden;\n    white-space: nowrap;\n    z-index: 99999;\n    background-color: rgb(255, 255, 255);\n    color: rgba(0,0,0, 0.67);\n}\n\n.ag-sparkline-tooltip-content {\n    padding: 0 7px;\n    opacity: 1;\n}\n\n.ag-sparkline-tooltip-title {\n    padding-left: 7px;\n    opacity: 1;\n}\n\n.ag-sparkline-tooltip-wrapper-hidden {\n    top: -10000px !important;\n}\n\n.ag-sparkline-wrapper {\n    box-sizing: border-box;\n    overflow: hidden;\n}\n";

var __values = (undefined && undefined.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var extent$3 = sparklines_util_exports.extent, isNumber$3 = sparklines_util_exports.isNumber, isString = sparklines_util_exports.isString, isStringObject = sparklines_util_exports.isStringObject, isDate = sparklines_util_exports.isDate, createId = sparklines_util_exports.createId, Padding = sparklines_util_exports.Padding;
var LinearScale = sparklines_scale_exports.LinearScale, BandScale$4 = sparklines_scale_exports.BandScale, TimeScale = sparklines_scale_exports.TimeScale;
/**
 * Constants to declare the expected nominal zIndex for nodes in a sparkline rendering.
 */
var ZINDICIES;
(function (ZINDICIES) {
    ZINDICIES[ZINDICIES["SERIES_FILL_ZINDEX"] = 50] = "SERIES_FILL_ZINDEX";
    ZINDICIES[ZINDICIES["AXIS_LINE_ZINDEX"] = 500] = "AXIS_LINE_ZINDEX";
    ZINDICIES[ZINDICIES["SERIES_STROKE_ZINDEX"] = 1000] = "SERIES_STROKE_ZINDEX";
    ZINDICIES[ZINDICIES["SERIES_LABEL_ZINDEX"] = 1500] = "SERIES_LABEL_ZINDEX";
    ZINDICIES[ZINDICIES["CROSSHAIR_ZINDEX"] = 2000] = "CROSSHAIR_ZINDEX";
    ZINDICIES[ZINDICIES["SERIES_MARKERS_ZINDEX"] = 2500] = "SERIES_MARKERS_ZINDEX";
})(ZINDICIES || (ZINDICIES = {}));
var SparklineAxis = /** @class */ (function () {
    function SparklineAxis() {
        this.type = 'category';
        this.stroke = 'rgb(204, 214, 235)';
        this.strokeWidth = 1;
    }
    return SparklineAxis;
}());
var Sparkline = /** @class */ (function () {
    function Sparkline() {
        this.id = createId(this);
        this.seriesRect = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        };
        this._context = undefined;
        this._container = undefined;
        this._data = undefined;
        this.padding = new Padding(3);
        this.xKey = 'x';
        this.yKey = 'y';
        this.dataType = undefined;
        this.xData = [];
        this.yData = [];
        // Minimum y value in provided data.
        this.min = undefined;
        // Maximum y value in provided data.
        this.max = undefined;
        this.yScale = new LinearScale();
        this.axis = new SparklineAxis();
        this.highlightStyle = {
            size: 6,
            fill: 'yellow',
            stroke: 'silver',
            strokeWidth: 1,
        };
        this._width = 100;
        this._height = 100;
        this.smallestInterval = undefined;
        this.layoutId = 0;
        this.defaultDateFormatter = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        });
        this._onMouseMove = this.onMouseMove.bind(this);
        this._onMouseOut = this.onMouseOut.bind(this);
        var root = new integrated_charts_scene_exports.Group();
        this.rootGroup = root;
        var element = document.createElement('div');
        element.setAttribute('class', 'ag-sparkline-wrapper');
        // initialise scene
        var scene = new integrated_charts_scene_exports.Scene({ window: window, document: document });
        this.scene = scene;
        this.canvasElement = scene.canvas.element;
        // set scene properties
        scene.root = root;
        scene.container = element;
        this.resizeAndSetDimensions(this.width, this.height);
        // one style element for tooltip styles per document
        if (!Sparkline.tooltipDocuments.includes(document)) {
            this.initialiseTooltipStyles();
        }
        this.setupDomEventListeners(this.canvasElement);
    }
    Object.defineProperty(Sparkline.prototype, "context", {
        get: function () {
            return this._context;
        },
        set: function (value) {
            if (this._context !== value) {
                this._context = value;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Sparkline.prototype, "container", {
        get: function () {
            return this._container;
        },
        set: function (value) {
            if (this._container !== value) {
                var parentNode = this.canvasElement.parentNode;
                if (parentNode != null) {
                    parentNode.removeChild(this.canvasElement);
                }
                if (value) {
                    value.appendChild(this.canvasElement);
                }
                this._container = value;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Sparkline.prototype, "data", {
        get: function () {
            return this._data;
        },
        set: function (value) {
            if (this._data !== value) {
                this._data = value;
                this.processData();
                if (this.mouseMoveEvent && this.highlightedDatum) {
                    this.updateHitPoint(this.mouseMoveEvent);
                }
            }
        },
        enumerable: false,
        configurable: true
    });
    Sparkline.prototype.resizeAndSetDimensions = function (width, height) {
        this.scene.resize(width, height);
        this.seriesRect.width = width;
        this.seriesRect.height = height;
    };
    Sparkline.prototype.initialiseTooltipStyles = function () {
        var styleElement = document.createElement('style');
        styleElement.innerHTML = defaultTooltipCss;
        document.head.insertBefore(styleElement, document.head.querySelector('style'));
        Sparkline.tooltipDocuments.push(document);
    };
    Object.defineProperty(Sparkline.prototype, "width", {
        get: function () {
            return this._width;
        },
        set: function (value) {
            if (this._width !== value) {
                this._width = value;
                this.scene.resize(value, this.height);
                this.scheduleLayout();
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Sparkline.prototype, "height", {
        get: function () {
            return this._height;
        },
        set: function (value) {
            if (this._height !== value) {
                this._height = value;
                this.scene.resize(this.width, value);
                this.scheduleLayout();
            }
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Generate node data from processed data.
     * Produce data joins.
     * Update selection's nodes using node data.
     */
    Sparkline.prototype.update = function () { };
    // Update y scale based on processed data.
    Sparkline.prototype.updateYScale = function () {
        this.updateYScaleRange();
        this.updateYScaleDomain();
    };
    // Update y scale domain based on processed data.
    Sparkline.prototype.updateYScaleDomain = function () { };
    // Update y scale range based on height and padding (seriesRect).
    Sparkline.prototype.updateYScaleRange = function () {
        var _a = this, yScale = _a.yScale, seriesRect = _a.seriesRect;
        yScale.range = [seriesRect.height, 0];
    };
    // Update x scale based on processed data.
    Sparkline.prototype.updateXScale = function () {
        var type = this.axis.type;
        this.xScale = this.getXScale(type);
        this.updateXScaleRange();
        this.updateXScaleDomain();
    };
    // Update x scale range based on width and padding (seriesRect).
    Sparkline.prototype.updateXScaleRange = function () {
        this.xScale.range = [0, this.seriesRect.width];
    };
    // Update x scale domain based on processed data and type of scale.
    Sparkline.prototype.updateXScaleDomain = function () {
        var _a = this, xData = _a.xData, xScale = _a.xScale;
        var xMinMax;
        if (xScale instanceof LinearScale || xScale instanceof TimeScale) {
            xMinMax = extent$3(xData);
        }
        this.xScale.domain = xMinMax ? xMinMax.slice() : xData;
    };
    /**
     * Return xScale instance based on the provided type or return a `BandScale` by default.
     * The default type is `category`.
     * @param type
     */
    Sparkline.prototype.getXScale = function (type) {
        if (type === void 0) { type = 'category'; }
        switch (type) {
            case 'number':
                return new LinearScale();
            case 'time':
                return new TimeScale();
            case 'category':
            default:
                return new BandScale$4();
        }
    };
    // Update axis line.
    Sparkline.prototype.updateAxisLine = function () { };
    // Update X and Y scales and the axis line.
    Sparkline.prototype.updateAxes = function () {
        this.updateYScale();
        this.updateXScale();
        this.updateAxisLine();
    };
    // Update horizontal and vertical crosshair lines.
    Sparkline.prototype.updateCrosshairs = function () {
        this.updateXCrosshairLine();
        this.updateYCrosshairLine();
    };
    // Using processed data, generate data that backs visible nodes.
    Sparkline.prototype.generateNodeData = function () {
        return [];
    };
    // Returns persisted node data associated with the sparkline's data.
    Sparkline.prototype.getNodeData = function () {
        return [];
    };
    // Update the selection's nodes.
    Sparkline.prototype.updateNodes = function () { };
    // Update the vertical crosshair line.
    Sparkline.prototype.updateXCrosshairLine = function () { };
    // Update the horizontal crosshair line.
    Sparkline.prototype.updateYCrosshairLine = function () { };
    Sparkline.prototype.highlightDatum = function (closestDatum) {
        this.updateNodes();
    };
    Sparkline.prototype.dehighlightDatum = function () {
        this.highlightedDatum = undefined;
        this.updateNodes();
        this.updateCrosshairs();
    };
    /**
     * Highlight closest datum and display tooltip if enabled.
     * Only update if necessary, i.e. only update if the highlighted datum is different from previously highlighted datum,
     * or if there is no previously highlighted datum.
     * @param event
     */
    Sparkline.prototype.onMouseMove = function (event) {
        this.mouseMoveEvent = event;
        this.updateHitPoint(event);
    };
    Sparkline.prototype.updateHitPoint = function (event) {
        var _a, _b, _c;
        var closestDatum = this.pickClosestSeriesNodeDatum(event.offsetX, event.offsetY);
        if (!closestDatum) {
            return;
        }
        var oldHighlightedDatum = this.highlightedDatum;
        this.highlightedDatum = closestDatum;
        if ((this.highlightedDatum && !oldHighlightedDatum) ||
            (this.highlightedDatum && oldHighlightedDatum && this.highlightedDatum !== oldHighlightedDatum)) {
            this.highlightDatum(closestDatum);
            this.updateCrosshairs();
            this.scene.render().catch(function (e) { return console.error("AG Grid - chart rendering failed", e); });
        }
        var tooltipEnabled = (_c = (_b = (_a = this.processedOptions) === null || _a === void 0 ? void 0 : _a.tooltip) === null || _b === void 0 ? void 0 : _b.enabled) !== null && _c !== void 0 ? _c : true;
        if (tooltipEnabled) {
            this.handleTooltip(event, closestDatum);
        }
    };
    /**
     * Dehighlight all nodes and remove tooltip.
     * @param event
     */
    Sparkline.prototype.onMouseOut = function (event) {
        this.dehighlightDatum();
        this.tooltip.toggle(false);
        this.scene.render().catch(function (e) { return console.error("AG Grid - chart rendering failed", e); });
    };
    // Fetch required values from the data object and process them.
    Sparkline.prototype.processData = function () {
        var _this = this;
        var _a = this, data = _a.data, yData = _a.yData, xData = _a.xData;
        if (!data || this.invalidData(this.data)) {
            return;
        }
        yData.length = 0;
        xData.length = 0;
        var n = data.length;
        var dataType = this.getDataType(data);
        this.dataType = dataType;
        var xValueType = this.axis.type;
        var xType = xValueType !== 'number' && xValueType !== 'time' ? 'category' : xValueType;
        var isContinuousX = xType === 'number' || xType === 'time';
        var setSmallestXInterval = function (curr, prev) {
            if (_this.smallestInterval == undefined) {
                _this.smallestInterval = { x: Infinity, y: Infinity };
            }
            var x = _this.smallestInterval.x;
            var interval = Math.abs(curr - prev);
            if (interval > 0 && interval < x) {
                _this.smallestInterval.x = interval;
            }
        };
        var prevX;
        if (dataType === 'number') {
            for (var i = 0; i < n; i++) {
                var xDatum = i;
                var yDatum = data[i];
                var x = this.getDatum(xDatum, xType);
                var y = this.getDatum(yDatum, 'number');
                if (isContinuousX) {
                    setSmallestXInterval(x, prevX);
                }
                xData.push(x);
                yData.push(y);
                prevX = x;
            }
        }
        else if (dataType === 'array') {
            for (var i = 0; i < n; i++) {
                var datum = data[i];
                if (Array.isArray(datum)) {
                    var xDatum = datum[0];
                    var yDatum = datum[1];
                    var x = this.getDatum(xDatum, xType);
                    var y = this.getDatum(yDatum, 'number');
                    if (x == undefined) {
                        continue;
                    }
                    if (isContinuousX) {
                        setSmallestXInterval(x, prevX);
                    }
                    xData.push(x);
                    yData.push(y);
                    prevX = x;
                }
            }
        }
        else if (dataType === 'object') {
            var _b = this, yKey = _b.yKey, xKey = _b.xKey;
            for (var i = 0; i < n; i++) {
                var datum = data[i];
                if (typeof datum === 'object' && !Array.isArray(datum)) {
                    var xDatum = datum[xKey];
                    var yDatum = datum[yKey];
                    var x = this.getDatum(xDatum, xType);
                    var y = this.getDatum(yDatum, 'number');
                    if (x == undefined) {
                        continue;
                    }
                    if (isContinuousX) {
                        setSmallestXInterval(x, prevX);
                    }
                    xData.push(x);
                    yData.push(y);
                    prevX = x;
                }
            }
        }
        this.updateAxes();
        this.immediateLayout();
    };
    /**
     * Return the type of data provided to the sparkline based on the first truthy value in the data array.
     * If the value is not a number, array or object, return `undefined`.
     * @param data
     */
    Sparkline.prototype.getDataType = function (data) {
        var e_1, _a;
        try {
            for (var data_1 = __values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                var datum = data_1_1.value;
                if (datum != undefined) {
                    if (isNumber$3(datum)) {
                        return 'number';
                    }
                    else if (Array.isArray(datum)) {
                        return 'array';
                    }
                    else if (typeof datum === 'object') {
                        return 'object';
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /**
     * Return the given value depending on the type of axis.
     * Return `undefined` if the value is invalid for the given axis type.
     * @param value
     */
    Sparkline.prototype.getDatum = function (value, type) {
        if ((type === 'number' && isNumber$3(value)) || (type === 'time' && (isNumber$3(value) || isDate(value)))) {
            return value;
        }
        else if (type === 'category') {
            if (isString(value) || isDate(value) || isNumber$3(value)) {
                return { toString: function () { return String(value); } };
            }
            else if (isStringObject(value)) {
                return value;
            }
        }
    };
    Object.defineProperty(Sparkline.prototype, "layoutScheduled", {
        /**
         * Only `true` while we are waiting for the layout to start.
         * This will be `false` if the layout has already started and is ongoing.
         */
        get: function () {
            return !!this.layoutId;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Execute update method on the next available screen repaint to make changes to the canvas.
     * If we are waiting for a layout to start and a new layout is requested,
     * cancel the previous layout using the non 0 integer (this.layoutId) returned from requestAnimationFrame.
     */
    Sparkline.prototype.scheduleLayout = function () {
        var _this = this;
        if (this.layoutId) {
            cancelAnimationFrame(this.layoutId);
        }
        this.layoutId = requestAnimationFrame(function () {
            _this.immediateLayout();
            _this.layoutId = 0;
        });
    };
    Sparkline.prototype.immediateLayout = function () {
        this.setSparklineDimensions();
        if (this.invalidData(this.data)) {
            return;
        }
        // update axes ranges
        this.updateXScaleRange();
        this.updateYScaleRange();
        // update axis line
        this.updateAxisLine();
        // produce data joins and update selection's nodes
        this.update();
        this.scene.render().catch(function (e) { return console.error("AG Grid - chart rendering failed", e); });
    };
    Sparkline.prototype.setSparklineDimensions = function () {
        var _a = this, width = _a.width, height = _a.height, padding = _a.padding, seriesRect = _a.seriesRect, rootGroup = _a.rootGroup;
        var shrunkWidth = width - padding.left - padding.right;
        var shrunkHeight = height - padding.top - padding.bottom;
        seriesRect.width = shrunkWidth;
        seriesRect.height = shrunkHeight;
        seriesRect.x = padding.left;
        seriesRect.y = padding.top;
        rootGroup.translationX = seriesRect.x;
        rootGroup.translationY = seriesRect.y;
    };
    /**
     * Return the closest data point to x/y canvas coordinates.
     * @param x
     * @param y
     */
    Sparkline.prototype.pickClosestSeriesNodeDatum = function (x, y) {
        var minDistance = Infinity;
        var closestDatum;
        var hitPoint = this.rootGroup.transformPoint(x, y);
        var nodeData = this.getNodeData();
        for (var i = 0; i < nodeData.length; i++) {
            var datum = nodeData[i];
            if (!datum.point) {
                return;
            }
            var distance = this.getDistance(hitPoint, datum.point);
            if (distance <= minDistance) {
                minDistance = distance;
                closestDatum = datum;
            }
        }
        return closestDatum;
    };
    /**
     * Return the relevant distance between two points.
     * The distance will be calculated based on the x value of the points for all sparklines except bar sparkline, where the distance is based on the y values.
     * @param x
     * @param y
     */
    Sparkline.prototype.getDistance = function (p1, p2) {
        return Math.abs(p1.x - p2.x);
    };
    /**
     * calculate x/y coordinates for tooltip based on coordinates of highlighted datum, position of canvas and page offset.
     * @param datum
     */
    Sparkline.prototype.handleTooltip = function (event, datum) {
        var _a, _b;
        var seriesDatum = datum.seriesDatum;
        var canvasElement = this.canvasElement;
        var clientX = event.clientX, clientY = event.clientY;
        var tooltipOptions = (_a = this.processedOptions) === null || _a === void 0 ? void 0 : _a.tooltip;
        var meta = {
            pageX: clientX,
            pageY: clientY,
            position: {
                xOffset: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.xOffset,
                yOffset: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.yOffset,
            },
            container: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.container,
        };
        // confine tooltip to sparkline width if tooltip container not provided.
        if (meta.container == undefined) {
            meta.container = canvasElement;
        }
        var yValue = seriesDatum.y;
        var xValue = seriesDatum.x;
        // check if tooltip is enabled for this specific data point
        var enabled = (_b = tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.enabled) !== null && _b !== void 0 ? _b : true;
        var tooltipRenderer = tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.renderer;
        if (tooltipRenderer) {
            var tooltipRendererResult = tooltipRenderer({
                context: this.context,
                datum: seriesDatum,
                yValue: yValue,
                xValue: xValue,
            });
            enabled =
                typeof tooltipRendererResult !== 'string' && tooltipRendererResult.enabled !== undefined
                    ? tooltipRendererResult.enabled
                    : enabled;
        }
        var html = enabled && seriesDatum.y !== undefined && this.getTooltipHtml(datum);
        if (html) {
            this.tooltip.show(meta, html);
        }
    };
    Sparkline.prototype.formatNumericDatum = function (datum) {
        return String(Math.round(datum * 10) / 10);
    };
    // locale.format('%m/%d/%y, %H:%M:%S');
    Sparkline.prototype.formatDatum = function (datum) {
        var type = this.axis.type || 'category';
        if (type === 'number' && typeof datum === 'number') {
            return this.formatNumericDatum(datum);
        }
        else if (type === 'time' && (datum instanceof Date || isNumber$3(datum))) {
            return this.defaultDateFormatter.format(datum);
        }
        else {
            return String(datum);
        }
    };
    Sparkline.prototype.setupDomEventListeners = function (chartElement) {
        chartElement.addEventListener('mousemove', this._onMouseMove);
        chartElement.addEventListener('mouseout', this._onMouseOut);
    };
    Sparkline.prototype.cleanupDomEventListeners = function (chartElement) {
        chartElement.removeEventListener('mousemove', this._onMouseMove);
        chartElement.removeEventListener('mouseout', this._onMouseOut);
    };
    Sparkline.prototype.invalidData = function (data) {
        return !data || !Array.isArray(data) || data.length === 0;
    };
    /**
     * Cleanup and remove canvas element from the DOM.
     */
    Sparkline.prototype.destroy = function () {
        this.scene.container = undefined;
        // remove canvas element from the DOM
        this.container = undefined;
        this.cleanupDomEventListeners(this.scene.canvas.element);
    };
    Sparkline.tooltipDocuments = [];
    return Sparkline;
}());

function toTooltipHtml(input, defaults) {
    var _a, _b, _c;
    if (typeof input === 'string') {
        return input;
    }
    defaults = defaults !== null && defaults !== void 0 ? defaults : {};
    var _d = input.content, content = _d === void 0 ? (_a = defaults.content) !== null && _a !== void 0 ? _a : '' : _d, _e = input.title, title = _e === void 0 ? (_b = defaults.title) !== null && _b !== void 0 ? _b : undefined : _e, _f = input.color, color = _f === void 0 ? defaults.color : _f, _g = input.backgroundColor, backgroundColor = _g === void 0 ? defaults.backgroundColor : _g, _h = input.opacity, opacity = _h === void 0 ? (_c = defaults.opacity) !== null && _c !== void 0 ? _c : 1 : _h;
    var titleHtml;
    var contentHtml;
    if (color) {
        titleHtml = title
            ? "<span class=\"".concat(SparklineTooltip.class, "-title\"; style=\"color: ").concat(color, "\">").concat(title, "</span>")
            : '';
        contentHtml = "<span class=\"".concat(SparklineTooltip.class, "-content\" style=\"color: ").concat(color, "\">").concat(content, "</span>");
    }
    else {
        titleHtml = title ? "<span class=\"".concat(SparklineTooltip.class, "-title\">").concat(title, "</span>") : '';
        contentHtml = "<span class=\"".concat(SparklineTooltip.class, "-content\">").concat(content, "</span>");
    }
    var style = "opacity: ".concat(opacity);
    if (backgroundColor) {
        style += "; background-color: ".concat(backgroundColor.toLowerCase());
    }
    return "<div class=\"".concat(SparklineTooltip.class, "\" style=\"").concat(style, "\">\n                ").concat(titleHtml, "\n                ").concat(contentHtml, "\n            </div>");
}
var SparklineTooltip = /** @class */ (function () {
    function SparklineTooltip() {
        this.element = document.createElement('div');
        var tooltipRoot = document.body;
        tooltipRoot.appendChild(this.element);
    }
    SparklineTooltip.prototype.isVisible = function () {
        var element = this.element;
        if (element.classList) {
            return !element.classList.contains("".concat(SparklineTooltip.class, "-wrapper-hidden"));
        }
        // IE11
        var classes = element.getAttribute('class');
        if (classes) {
            return classes.split(' ').indexOf("".concat(SparklineTooltip.class, "-wrapper-hidden")) < 0;
        }
        return false;
    };
    SparklineTooltip.prototype.updateClass = function (visible) {
        var classList = ["".concat(SparklineTooltip.class, "-wrapper")];
        if (visible !== true) {
            classList.push("".concat(SparklineTooltip.class, "-wrapper-hidden"));
        }
        this.element.setAttribute('class', classList.join(' '));
    };
    SparklineTooltip.prototype.show = function (meta, html) {
        var _a, _b, _c, _d;
        this.toggle(false);
        var element = this.element;
        if (html !== undefined) {
            element.innerHTML = html;
        }
        else if (!element.innerHTML) {
            return;
        }
        var xOffset = (_b = (_a = meta.position) === null || _a === void 0 ? void 0 : _a.xOffset) !== null && _b !== void 0 ? _b : 10;
        var yOffset = (_d = (_c = meta.position) === null || _c === void 0 ? void 0 : _c.yOffset) !== null && _d !== void 0 ? _d : 0;
        var left = meta.pageX + xOffset;
        var top = meta.pageY + yOffset;
        var tooltipRect = element.getBoundingClientRect();
        var maxLeft = window.innerWidth - tooltipRect.width;
        if (meta.container) {
            var containerRect = meta.container.getBoundingClientRect();
            maxLeft = containerRect.left + (containerRect.width - tooltipRect.width);
        }
        if (left > maxLeft) {
            left = meta.pageX - element.clientWidth - xOffset;
        }
        if (typeof scrollX !== 'undefined') {
            left += scrollX;
        }
        if (typeof scrollY !== 'undefined') {
            top += scrollY;
        }
        element.style.left = "".concat(Math.round(left), "px");
        element.style.top = "".concat(Math.round(top), "px");
        this.toggle(true);
    };
    SparklineTooltip.prototype.toggle = function (visible) {
        this.updateClass(visible);
    };
    SparklineTooltip.prototype.destroy = function () {
        var parentNode = this.element.parentNode;
        if (parentNode) {
            parentNode.removeChild(this.element);
        }
    };
    SparklineTooltip.class = 'ag-sparkline-tooltip';
    return SparklineTooltip;
}());

function getMarker(shape) {
    switch (shape) {
        case 'circle':
            return integrated_charts_scene_exports.Circle;
        case 'square':
            return integrated_charts_scene_exports.Square;
        case 'diamond':
            return integrated_charts_scene_exports.Diamond;
        default:
            return integrated_charts_scene_exports.Circle;
    }
}

function getLineDash(lineCap, lineDash) {
    if (lineDash === void 0) { lineDash = 'solid'; }
    var buttOrNull = {
        solid: [],
        dash: [4, 3],
        dot: [1, 3],
        dashDot: [4, 3, 1, 3],
        dashDotDot: [4, 3, 1, 3, 1, 3],
        shortDot: [1, 1],
        shortDash: [3, 1],
        shortDashDot: [3, 1, 1, 1],
        shortDashDotDot: [3, 1, 1, 1, 1, 1],
        longDash: [8, 3],
        longDashDot: [8, 3, 1, 3],
        longDashDotDot: [8, 3, 1, 3, 1, 3]
    };
    var roundOrSquare = {
        solid: [],
        dash: [3, 3],
        dot: [0, 3],
        dashDot: [3, 3, 0, 3],
        dashDotDot: [3, 3, 0, 3, 0, 3],
        shortDot: [0, 2],
        shortDash: [2, 2],
        shortDashDot: [2, 2, 0, 2],
        shortDashDotDot: [2, 2, 0, 2, 0, 2],
        longDash: [7, 3],
        longDashDot: [7, 3, 0, 3],
        longDashDotDot: [7, 3, 0, 3, 0, 3]
    };
    if (lineCap === 'round' || lineCap === 'square') {
        if (roundOrSquare[lineDash] == undefined) {
            console.warn("'".concat(lineDash, "' is not a valid 'lineDash' option."));
            return roundOrSquare.solid;
        }
        return roundOrSquare[lineDash];
    }
    if (buttOrNull[lineDash] == undefined) {
        console.warn("'".concat(lineDash, "' is not a valid 'lineDash' option."));
        return buttOrNull.solid;
    }
    return buttOrNull[lineDash];
}

var __extends$6 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var extent$2 = sparklines_util_exports.extent;
var BandScale$3 = sparklines_scale_exports.BandScale;
var SparklineMarker$1 = /** @class */ (function () {
    function SparklineMarker() {
        this.enabled = true;
        this.shape = 'circle';
        this.size = 0;
        this.fill = 'rgb(124, 181, 236)';
        this.stroke = 'rgb(124, 181, 236)';
        this.strokeWidth = 1;
        this.formatter = undefined;
    }
    return SparklineMarker;
}());
var SparklineLine$1 = /** @class */ (function () {
    function SparklineLine() {
        this.stroke = 'rgb(124, 181, 236)';
        this.strokeWidth = 1;
    }
    return SparklineLine;
}());
var SparklineCrosshairs$1 = /** @class */ (function () {
    function SparklineCrosshairs() {
        this.xLine = {
            enabled: true,
            stroke: 'rgba(0,0,0, 0.54)',
            strokeWidth: 1,
            lineDash: 'solid',
            lineCap: undefined,
        };
        this.yLine = {
            enabled: false,
            stroke: 'rgba(0,0,0, 0.54)',
            strokeWidth: 1,
            lineDash: 'solid',
            lineCap: undefined,
        };
    }
    return SparklineCrosshairs;
}());
var AreaSparkline = /** @class */ (function (_super) {
    __extends$6(AreaSparkline, _super);
    function AreaSparkline() {
        var _this = _super.call(this) || this;
        _this.fill = 'rgba(124, 181, 236, 0.25)';
        _this.strokePath = new integrated_charts_scene_exports.Path();
        _this.fillPath = new integrated_charts_scene_exports.Path();
        _this.xCrosshairLine = new integrated_charts_scene_exports.Line();
        _this.yCrosshairLine = new integrated_charts_scene_exports.Line();
        _this.areaSparklineGroup = new integrated_charts_scene_exports.Group();
        _this.xAxisLine = new integrated_charts_scene_exports.Line();
        _this.markers = new integrated_charts_scene_exports.Group();
        _this.markerSelection = integrated_charts_scene_exports.Selection.select(_this.markers, function () { return _this.markerFactory(); });
        _this.markerSelectionData = [];
        _this.marker = new SparklineMarker$1();
        _this.line = new SparklineLine$1();
        _this.crosshairs = new SparklineCrosshairs$1();
        _this.rootGroup.append(_this.areaSparklineGroup);
        _this.xAxisLine.zIndex = ZINDICIES.AXIS_LINE_ZINDEX;
        _this.fillPath.zIndex = ZINDICIES.SERIES_FILL_ZINDEX;
        _this.strokePath.zIndex = ZINDICIES.SERIES_STROKE_ZINDEX;
        _this.xCrosshairLine.zIndex = ZINDICIES.CROSSHAIR_ZINDEX;
        _this.yCrosshairLine.zIndex = ZINDICIES.CROSSHAIR_ZINDEX;
        _this.markers.zIndex = ZINDICIES.SERIES_MARKERS_ZINDEX;
        _this.areaSparklineGroup.append([
            _this.fillPath,
            _this.xAxisLine,
            _this.strokePath,
            _this.xCrosshairLine,
            _this.yCrosshairLine,
            _this.markers,
        ]);
        return _this;
    }
    AreaSparkline.prototype.markerFactory = function () {
        var shape = this.marker.shape;
        var MarkerShape = getMarker(shape);
        return new MarkerShape();
    };
    AreaSparkline.prototype.getNodeData = function () {
        return this.markerSelectionData;
    };
    AreaSparkline.prototype.update = function () {
        var data = this.generateNodeData();
        if (!data) {
            return;
        }
        var nodeData = data.nodeData, fillData = data.fillData, strokeData = data.strokeData;
        this.markerSelectionData = nodeData;
        this.updateSelection(nodeData);
        this.updateNodes();
        this.updateStroke(strokeData);
        this.updateFill(fillData);
    };
    AreaSparkline.prototype.updateYScaleDomain = function () {
        var _a = this, yData = _a.yData, yScale = _a.yScale;
        var yMinMax = extent$2(yData);
        var yMin = 0;
        var yMax = 1;
        if (yMinMax !== undefined) {
            yMin = this.min = yMinMax[0];
            yMax = this.max = yMinMax[1];
        }
        // if yMin is positive, set yMin to 0
        yMin = yMin < 0 ? yMin : 0;
        // if yMax is negative, set yMax to 0
        yMax = yMax < 0 ? 0 : yMax;
        yScale.domain = [yMin, yMax];
    };
    AreaSparkline.prototype.generateNodeData = function () {
        var _a = this, data = _a.data, yData = _a.yData, xData = _a.xData, xScale = _a.xScale, yScale = _a.yScale;
        if (!data) {
            return;
        }
        var continuous = !(xScale instanceof BandScale$3);
        var offsetX = !continuous ? xScale.bandwidth / 2 : 0;
        var n = yData.length;
        var nodeData = [];
        var fillData = [];
        var strokeData = [];
        var firstValidX;
        var lastValidX;
        var previousX;
        var nextX;
        var yZero = yScale.convert(0);
        for (var i = 0; i < n; i++) {
            var yDatum = yData[i];
            var xDatum = xData[i];
            var x = xScale.convert(continuous ? xScale.toDomain(xDatum) : xDatum) + offsetX;
            var y = yDatum === undefined ? NaN : yScale.convert(yDatum);
            // if this iteration is not the last, set nextX using the next value in the data array
            if (i + 1 < n) {
                nextX = xScale.convert(continuous ? xScale.toDomain(xData[i + 1]) : xData[i + 1]) + offsetX;
            }
            // set stroke data regardless of missing/ undefined values. Undefined values will be handled in the updateStroke() method
            strokeData.push({
                seriesDatum: { x: xDatum, y: yDatum },
                point: { x: x, y: y },
            });
            if (yDatum === undefined && previousX !== undefined) {
                // if yDatum is undefined and there is a valid previous data point, add a phantom point at yZero
                // if a next data point exists, add a phantom point at yZero at the next X
                fillData.push({ seriesDatum: undefined, point: { x: previousX, y: yZero } });
                if (nextX !== undefined) {
                    fillData.push({ seriesDatum: undefined, point: { x: nextX, y: yZero } });
                }
            }
            else if (yDatum !== undefined) {
                fillData.push({
                    seriesDatum: { x: xDatum, y: yDatum },
                    point: { x: x, y: y },
                });
                // set node data only if yDatum is not undefined. These values are used in the updateSelection() method to update markers
                nodeData.push({
                    seriesDatum: { x: xDatum, y: yDatum },
                    point: { x: x, y: y },
                });
                firstValidX = firstValidX !== undefined ? firstValidX : x;
                lastValidX = x;
            }
            previousX = x;
        }
        // phantom points for creating closed area
        fillData.push({ seriesDatum: undefined, point: { x: lastValidX, y: yZero } }, { seriesDatum: undefined, point: { x: firstValidX, y: yZero } });
        return { nodeData: nodeData, fillData: fillData, strokeData: strokeData };
    };
    AreaSparkline.prototype.updateAxisLine = function () {
        var _a = this, xScale = _a.xScale, yScale = _a.yScale, axis = _a.axis, xAxisLine = _a.xAxisLine;
        xAxisLine.x1 = xScale.range[0];
        xAxisLine.x2 = xScale.range[1];
        xAxisLine.y1 = xAxisLine.y2 = 0;
        xAxisLine.stroke = axis.stroke;
        xAxisLine.strokeWidth = axis.strokeWidth;
        var yZero = yScale.convert(0);
        xAxisLine.translationY = yZero;
    };
    AreaSparkline.prototype.updateSelection = function (selectionData) {
        this.markerSelection.update(selectionData);
    };
    AreaSparkline.prototype.updateNodes = function () {
        var _this = this;
        var _a = this, highlightedDatum = _a.highlightedDatum, highlightStyle = _a.highlightStyle, marker = _a.marker;
        var highlightSize = highlightStyle.size, highlightFill = highlightStyle.fill, highlightStroke = highlightStyle.stroke, highlightStrokeWidth = highlightStyle.strokeWidth;
        var markerFormatter = marker.formatter;
        this.markerSelection.each(function (node, datum, index) {
            var point = datum.point, seriesDatum = datum.seriesDatum;
            if (!point) {
                return;
            }
            var highlighted = datum === highlightedDatum;
            var markerFill = highlighted && highlightFill !== undefined ? highlightFill : marker.fill;
            var markerStroke = highlighted && highlightStroke !== undefined ? highlightStroke : marker.stroke;
            var markerStrokeWidth = highlighted && highlightStrokeWidth !== undefined ? highlightStrokeWidth : marker.strokeWidth;
            var markerSize = highlighted && highlightSize !== undefined ? highlightSize : marker.size;
            var markerFormat;
            if (markerFormatter) {
                var first = index === 0;
                var last = index === _this.markerSelectionData.length - 1;
                var min = seriesDatum.y === _this.min;
                var max = seriesDatum.y === _this.max;
                markerFormat = markerFormatter({
                    datum: datum,
                    xValue: seriesDatum.x,
                    yValue: seriesDatum.y,
                    min: min,
                    max: max,
                    first: first,
                    last: last,
                    fill: markerFill,
                    stroke: markerStroke,
                    strokeWidth: markerStrokeWidth,
                    size: markerSize,
                    highlighted: highlighted,
                });
            }
            node.size = markerFormat && markerFormat.size != undefined ? markerFormat.size : markerSize;
            node.fill = markerFormat && markerFormat.fill != undefined ? markerFormat.fill : markerFill;
            node.stroke = markerFormat && markerFormat.stroke != undefined ? markerFormat.stroke : markerStroke;
            node.strokeWidth =
                markerFormat && markerFormat.strokeWidth != undefined ? markerFormat.strokeWidth : markerStrokeWidth;
            node.translationX = point.x;
            node.translationY = point.y;
            node.visible =
                markerFormat && markerFormat.enabled != undefined
                    ? markerFormat.enabled
                    : marker.enabled && node.size > 0;
        });
    };
    AreaSparkline.prototype.updateStroke = function (strokeData) {
        var _a = this, strokePath = _a.strokePath, yData = _a.yData, line = _a.line;
        if (yData.length < 2) {
            return;
        }
        var path = strokePath.path;
        var n = strokeData.length;
        var moveTo = true;
        path.clear();
        for (var i = 0; i < n; i++) {
            var _b = strokeData[i], point = _b.point, seriesDatum = _b.seriesDatum;
            var x = point.x;
            var y = point.y;
            if (seriesDatum.y == undefined) {
                moveTo = true;
            }
            else {
                if (moveTo) {
                    path.moveTo(x, y);
                    moveTo = false;
                }
                else {
                    path.lineTo(x, y);
                }
            }
        }
        strokePath.lineJoin = strokePath.lineCap = 'round';
        strokePath.fill = undefined;
        strokePath.stroke = line.stroke;
        strokePath.strokeWidth = line.strokeWidth;
    };
    AreaSparkline.prototype.updateFill = function (areaData) {
        var _a = this, fillPath = _a.fillPath, yData = _a.yData, fill = _a.fill;
        var path = fillPath.path;
        var n = areaData.length;
        path.clear();
        if (yData.length < 2) {
            return;
        }
        for (var i = 0; i < n; i++) {
            var point = areaData[i].point;
            var x = point.x;
            var y = point.y;
            if (i > 0) {
                path.lineTo(x, y);
            }
            else {
                path.moveTo(x, y);
            }
        }
        path.closePath();
        fillPath.lineJoin = 'round';
        fillPath.stroke = undefined;
        fillPath.fill = fill;
    };
    AreaSparkline.prototype.updateXCrosshairLine = function () {
        var _a;
        var _b = this, yScale = _b.yScale, xCrosshairLine = _b.xCrosshairLine, highlightedDatum = _b.highlightedDatum, xLine = _b.crosshairs.xLine;
        if (!xLine.enabled || highlightedDatum == undefined) {
            xCrosshairLine.strokeWidth = 0;
            return;
        }
        xCrosshairLine.y1 = yScale.range[0];
        xCrosshairLine.y2 = yScale.range[1];
        xCrosshairLine.x1 = xCrosshairLine.x2 = 0;
        xCrosshairLine.stroke = xLine.stroke;
        xCrosshairLine.strokeWidth = (_a = xLine.strokeWidth) !== null && _a !== void 0 ? _a : 1;
        xCrosshairLine.lineCap = xLine.lineCap === 'round' || xLine.lineCap === 'square' ? xLine.lineCap : undefined;
        var lineDash = xLine.lineDash;
        xCrosshairLine.lineDash = Array.isArray(lineDash)
            ? lineDash
            : getLineDash(xCrosshairLine.lineCap, xLine.lineDash);
        xCrosshairLine.translationX = highlightedDatum.point.x;
    };
    AreaSparkline.prototype.updateYCrosshairLine = function () {
        var _a;
        var _b = this, xScale = _b.xScale, yCrosshairLine = _b.yCrosshairLine, highlightedDatum = _b.highlightedDatum, yLine = _b.crosshairs.yLine;
        if (!yLine.enabled || highlightedDatum == undefined) {
            yCrosshairLine.strokeWidth = 0;
            return;
        }
        yCrosshairLine.x1 = xScale.range[0];
        yCrosshairLine.x2 = xScale.range[1];
        yCrosshairLine.y1 = yCrosshairLine.y2 = 0;
        yCrosshairLine.stroke = yLine.stroke;
        yCrosshairLine.strokeWidth = (_a = yLine.strokeWidth) !== null && _a !== void 0 ? _a : 1;
        yCrosshairLine.lineCap = yLine.lineCap === 'round' || yLine.lineCap === 'square' ? yLine.lineCap : undefined;
        var lineDash = yLine.lineDash;
        yCrosshairLine.lineDash = Array.isArray(lineDash)
            ? lineDash
            : getLineDash(yCrosshairLine.lineCap, yLine.lineDash);
        yCrosshairLine.translationY = highlightedDatum.point.y;
    };
    AreaSparkline.prototype.getTooltipHtml = function (datum) {
        var _a, _b;
        var dataType = this.dataType;
        var seriesDatum = datum.seriesDatum;
        var yValue = seriesDatum.y;
        var xValue = seriesDatum.x;
        var content = this.formatNumericDatum(yValue);
        var title = dataType === 'array' || dataType === 'object' ? this.formatDatum(xValue) : undefined;
        var defaults = {
            content: content,
            title: title,
        };
        var tooltipRenderer = (_b = (_a = this.processedOptions) === null || _a === void 0 ? void 0 : _a.tooltip) === null || _b === void 0 ? void 0 : _b.renderer;
        if (tooltipRenderer) {
            return toTooltipHtml(tooltipRenderer({
                context: this.context,
                datum: seriesDatum,
                yValue: yValue,
                xValue: xValue,
            }), defaults);
        }
        return toTooltipHtml(defaults);
    };
    AreaSparkline.className = 'AreaSparkline';
    return AreaSparkline;
}(Sparkline));

var __extends$5 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var extent$1 = sparklines_util_exports.extent;
var BandScale$2 = sparklines_scale_exports.BandScale;
var SparklineMarker = /** @class */ (function () {
    function SparklineMarker() {
        this.enabled = true;
        this.shape = 'circle';
        this.size = 0;
        this.fill = 'rgb(124, 181, 236)';
        this.stroke = 'rgb(124, 181, 236)';
        this.strokeWidth = 1;
        this.formatter = undefined;
    }
    return SparklineMarker;
}());
var SparklineLine = /** @class */ (function () {
    function SparklineLine() {
        this.stroke = 'rgb(124, 181, 236)';
        this.strokeWidth = 1;
    }
    return SparklineLine;
}());
var SparklineCrosshairs = /** @class */ (function () {
    function SparklineCrosshairs() {
        this.xLine = {
            enabled: true,
            stroke: 'rgba(0,0,0, 0.54)',
            strokeWidth: 1,
            lineDash: 'solid',
            lineCap: undefined,
        };
        this.yLine = {
            enabled: false,
            stroke: 'rgba(0,0,0, 0.54)',
            strokeWidth: 1,
            lineDash: 'solid',
            lineCap: undefined,
        };
    }
    return SparklineCrosshairs;
}());
var LineSparkline = /** @class */ (function (_super) {
    __extends$5(LineSparkline, _super);
    function LineSparkline() {
        var _this = _super.call(this) || this;
        _this.linePath = new integrated_charts_scene_exports.Path();
        _this.xCrosshairLine = new integrated_charts_scene_exports.Line();
        _this.yCrosshairLine = new integrated_charts_scene_exports.Line();
        _this.lineSparklineGroup = new integrated_charts_scene_exports.Group();
        _this.markers = new integrated_charts_scene_exports.Group();
        _this.markerSelection = integrated_charts_scene_exports.Selection.select(_this.markers, function () { return _this.markerFactory(); });
        _this.markerSelectionData = [];
        _this.marker = new SparklineMarker();
        _this.line = new SparklineLine();
        _this.crosshairs = new SparklineCrosshairs();
        _this.rootGroup.append(_this.lineSparklineGroup);
        _this.linePath.zIndex = ZINDICIES.SERIES_STROKE_ZINDEX;
        _this.xCrosshairLine.zIndex = ZINDICIES.CROSSHAIR_ZINDEX;
        _this.yCrosshairLine.zIndex = ZINDICIES.CROSSHAIR_ZINDEX;
        _this.markers.zIndex = ZINDICIES.SERIES_MARKERS_ZINDEX;
        _this.lineSparklineGroup.append([_this.linePath, _this.xCrosshairLine, _this.yCrosshairLine, _this.markers]);
        return _this;
    }
    LineSparkline.prototype.getNodeData = function () {
        return this.markerSelectionData;
    };
    LineSparkline.prototype.markerFactory = function () {
        var shape = this.marker.shape;
        var MarkerShape = getMarker(shape);
        return new MarkerShape();
    };
    /**
     * If marker shape is changed, this method should be called to remove the previous marker nodes selection.
     */
    LineSparkline.prototype.onMarkerShapeChange = function () {
        this.markerSelection = this.markerSelection.clear();
        this.scheduleLayout();
    };
    LineSparkline.prototype.update = function () {
        var nodeData = this.generateNodeData();
        if (!nodeData) {
            return;
        }
        this.markerSelectionData = nodeData;
        this.updateSelection(nodeData);
        this.updateNodes();
        this.updateLine();
    };
    LineSparkline.prototype.updateYScaleDomain = function () {
        var _a = this, yData = _a.yData, yScale = _a.yScale;
        var yMinMax = extent$1(yData);
        var yMin = 0;
        var yMax = 1;
        if (yMinMax !== undefined) {
            yMin = this.min = yMinMax[0];
            yMax = this.max = yMinMax[1];
        }
        if (yMin === yMax) {
            // if all values in the data are the same, yMin and yMax will be equal, need to adjust the domain with some padding
            var padding = Math.abs(yMin * 0.01);
            yMin -= padding;
            yMax += padding;
        }
        yScale.domain = [yMin, yMax];
    };
    LineSparkline.prototype.generateNodeData = function () {
        var _a = this, data = _a.data, yData = _a.yData, xData = _a.xData, xScale = _a.xScale, yScale = _a.yScale;
        if (!data) {
            return;
        }
        var continuous = !(xScale instanceof BandScale$2);
        var offsetX = !continuous ? xScale.bandwidth / 2 : 0;
        var nodeData = [];
        for (var i = 0; i < yData.length; i++) {
            var yDatum = yData[i];
            var xDatum = xData[i];
            if (yDatum == undefined) {
                continue;
            }
            var x = xScale.convert(continuous ? xScale.toDomain(xDatum) : xDatum) + offsetX;
            var y = yDatum === undefined ? NaN : yScale.convert(yDatum);
            nodeData.push({
                seriesDatum: { x: xDatum, y: yDatum },
                point: { x: x, y: y },
            });
        }
        return nodeData;
    };
    LineSparkline.prototype.updateSelection = function (selectionData) {
        this.markerSelection.update(selectionData);
    };
    LineSparkline.prototype.updateNodes = function () {
        var _this = this;
        var _a = this, highlightedDatum = _a.highlightedDatum, highlightStyle = _a.highlightStyle, marker = _a.marker;
        var highlightSize = highlightStyle.size, highlightFill = highlightStyle.fill, highlightStroke = highlightStyle.stroke, highlightStrokeWidth = highlightStyle.strokeWidth;
        var markerFormatter = marker.formatter;
        this.markerSelection.each(function (node, datum, index) {
            var highlighted = datum === highlightedDatum;
            var markerFill = highlighted && highlightFill !== undefined ? highlightFill : marker.fill;
            var markerStroke = highlighted && highlightStroke !== undefined ? highlightStroke : marker.stroke;
            var markerStrokeWidth = highlighted && highlightStrokeWidth !== undefined ? highlightStrokeWidth : marker.strokeWidth;
            var markerSize = highlighted && highlightSize !== undefined ? highlightSize : marker.size;
            var markerFormat;
            var seriesDatum = datum.seriesDatum, point = datum.point;
            if (markerFormatter) {
                var first = index === 0;
                var last = index === _this.markerSelectionData.length - 1;
                var min = seriesDatum.y === _this.min;
                var max = seriesDatum.y === _this.max;
                markerFormat = markerFormatter({
                    datum: datum,
                    xValue: seriesDatum.x,
                    yValue: seriesDatum.y,
                    min: min,
                    max: max,
                    first: first,
                    last: last,
                    fill: markerFill,
                    stroke: markerStroke,
                    strokeWidth: markerStrokeWidth,
                    size: markerSize,
                    highlighted: highlighted,
                });
            }
            node.size = markerFormat && markerFormat.size != undefined ? markerFormat.size : markerSize;
            node.fill = markerFormat && markerFormat.fill != undefined ? markerFormat.fill : markerFill;
            node.stroke = markerFormat && markerFormat.stroke != undefined ? markerFormat.stroke : markerStroke;
            node.strokeWidth =
                markerFormat && markerFormat.strokeWidth != undefined ? markerFormat.strokeWidth : markerStrokeWidth;
            node.translationX = point.x;
            node.translationY = point.y;
            node.visible =
                markerFormat && markerFormat.enabled != undefined
                    ? markerFormat.enabled
                    : marker.enabled && node.size > 0;
        });
    };
    LineSparkline.prototype.updateLine = function () {
        var _a = this, linePath = _a.linePath, yData = _a.yData, xData = _a.xData, xScale = _a.xScale, yScale = _a.yScale, line = _a.line;
        if (yData.length < 2) {
            return;
        }
        var continuous = !(xScale instanceof BandScale$2);
        var path = linePath.path;
        var n = yData.length;
        var offsetX = !continuous ? xScale.bandwidth / 2 : 0;
        var moveTo = true;
        path.clear();
        for (var i = 0; i < n; i++) {
            var xDatum = xData[i];
            var yDatum = yData[i];
            var x = xScale.convert(continuous ? xScale.toDomain(xDatum) : xDatum) + offsetX;
            var y = yDatum === undefined ? NaN : yScale.convert(yDatum);
            if (yDatum == undefined) {
                moveTo = true;
            }
            else {
                if (moveTo) {
                    path.moveTo(x, y);
                    moveTo = false;
                }
                else {
                    path.lineTo(x, y);
                }
            }
        }
        linePath.fill = undefined;
        linePath.stroke = line.stroke;
        linePath.strokeWidth = line.strokeWidth;
    };
    LineSparkline.prototype.updateXCrosshairLine = function () {
        var _a;
        var _b = this, yScale = _b.yScale, xCrosshairLine = _b.xCrosshairLine, highlightedDatum = _b.highlightedDatum, xLine = _b.crosshairs.xLine;
        if (!xLine.enabled || highlightedDatum == undefined) {
            xCrosshairLine.strokeWidth = 0;
            return;
        }
        xCrosshairLine.y1 = yScale.range[0];
        xCrosshairLine.y2 = yScale.range[1];
        xCrosshairLine.x1 = xCrosshairLine.x2 = 0;
        xCrosshairLine.stroke = xLine.stroke;
        xCrosshairLine.strokeWidth = (_a = xLine.strokeWidth) !== null && _a !== void 0 ? _a : 1;
        xCrosshairLine.lineCap = xLine.lineCap === 'round' || xLine.lineCap === 'square' ? xLine.lineCap : undefined;
        var lineDash = xLine.lineDash;
        xCrosshairLine.lineDash = Array.isArray(lineDash)
            ? lineDash
            : getLineDash(xCrosshairLine.lineCap, xLine.lineDash);
        xCrosshairLine.translationX = highlightedDatum.point.x;
    };
    LineSparkline.prototype.updateYCrosshairLine = function () {
        var _a;
        var _b = this, xScale = _b.xScale, yCrosshairLine = _b.yCrosshairLine, highlightedDatum = _b.highlightedDatum, yLine = _b.crosshairs.yLine;
        if (!yLine.enabled || highlightedDatum == undefined) {
            yCrosshairLine.strokeWidth = 0;
            return;
        }
        yCrosshairLine.x1 = xScale.range[0];
        yCrosshairLine.x2 = xScale.range[1];
        yCrosshairLine.y1 = yCrosshairLine.y2 = 0;
        yCrosshairLine.stroke = yLine.stroke;
        yCrosshairLine.strokeWidth = (_a = yLine.strokeWidth) !== null && _a !== void 0 ? _a : 1;
        yCrosshairLine.lineCap = yLine.lineCap === 'round' || yLine.lineCap === 'square' ? yLine.lineCap : undefined;
        var lineDash = yLine.lineDash;
        yCrosshairLine.lineDash = Array.isArray(lineDash)
            ? lineDash
            : getLineDash(yCrosshairLine.lineCap, yLine.lineDash);
        yCrosshairLine.translationY = highlightedDatum.point.y;
    };
    LineSparkline.prototype.getTooltipHtml = function (datum) {
        var _a, _b;
        var dataType = this.dataType;
        var seriesDatum = datum.seriesDatum;
        var yValue = seriesDatum.y;
        var xValue = seriesDatum.x;
        var content = this.formatNumericDatum(yValue);
        var title = dataType === 'array' || dataType === 'object' ? this.formatDatum(xValue) : undefined;
        var defaults = {
            content: content,
            title: title,
        };
        var tooltipRenderer = (_b = (_a = this.processedOptions) === null || _a === void 0 ? void 0 : _a.tooltip) === null || _b === void 0 ? void 0 : _b.renderer;
        if (tooltipRenderer) {
            return toTooltipHtml(tooltipRenderer({
                context: this.context,
                datum: seriesDatum,
                yValue: yValue,
                xValue: xValue,
            }), defaults);
        }
        return toTooltipHtml(defaults);
    };
    LineSparkline.className = 'LineSparkline';
    return LineSparkline;
}(Sparkline));

var Label = /** @class */ (function () {
    function Label() {
        this.enabled = true;
        this.fontSize = 8;
        this.fontFamily = 'Verdana, sans-serif';
        this.fontStyle = undefined;
        this.fontWeight = undefined;
        this.color = 'rgba(70, 70, 70, 1)';
    }
    return Label;
}());

var __extends$4 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var extent = sparklines_util_exports.extent;
var BarColumnNodeTag;
(function (BarColumnNodeTag) {
    BarColumnNodeTag[BarColumnNodeTag["Rect"] = 0] = "Rect";
    BarColumnNodeTag[BarColumnNodeTag["Label"] = 1] = "Label";
})(BarColumnNodeTag || (BarColumnNodeTag = {}));
var BarColumnLabelPlacement;
(function (BarColumnLabelPlacement) {
    BarColumnLabelPlacement["InsideBase"] = "insideBase";
    BarColumnLabelPlacement["InsideEnd"] = "insideEnd";
    BarColumnLabelPlacement["Center"] = "center";
    BarColumnLabelPlacement["OutsideEnd"] = "outsideEnd";
})(BarColumnLabelPlacement || (BarColumnLabelPlacement = {}));
var BarColumnLabel = /** @class */ (function (_super) {
    __extends$4(BarColumnLabel, _super);
    function BarColumnLabel() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.formatter = undefined;
        _this.placement = BarColumnLabelPlacement.InsideEnd;
        return _this;
    }
    return BarColumnLabel;
}(Label));
var BarColumnSparkline = /** @class */ (function (_super) {
    __extends$4(BarColumnSparkline, _super);
    function BarColumnSparkline() {
        var _this = _super.call(this) || this;
        _this.fill = 'rgb(124, 181, 236)';
        _this.stroke = 'silver';
        _this.strokeWidth = 0;
        _this.paddingInner = 0.1;
        _this.paddingOuter = 0.2;
        _this.valueAxisDomain = undefined;
        _this.formatter = undefined;
        _this.axisLine = new integrated_charts_scene_exports.Line();
        _this.bandWidth = 0;
        _this.sparklineGroup = new integrated_charts_scene_exports.Group();
        _this.rectGroup = new integrated_charts_scene_exports.Group();
        _this.labelGroup = new integrated_charts_scene_exports.Group();
        _this.rectSelection = integrated_charts_scene_exports.Selection.select(_this.rectGroup, integrated_charts_scene_exports.Rect);
        _this.labelSelection = integrated_charts_scene_exports.Selection.select(_this.labelGroup, integrated_charts_scene_exports.Text);
        _this.nodeSelectionData = [];
        _this.label = new BarColumnLabel();
        _this.rootGroup.append(_this.sparklineGroup);
        _this.rectGroup.zIndex = ZINDICIES.SERIES_FILL_ZINDEX;
        _this.axisLine.zIndex = ZINDICIES.AXIS_LINE_ZINDEX;
        _this.labelGroup.zIndex = ZINDICIES.SERIES_LABEL_ZINDEX;
        _this.sparklineGroup.append([_this.rectGroup, _this.axisLine, _this.labelGroup]);
        _this.axisLine.lineCap = 'round';
        _this.label.enabled = false;
        return _this;
    }
    BarColumnSparkline.prototype.getNodeData = function () {
        return this.nodeSelectionData;
    };
    BarColumnSparkline.prototype.update = function () {
        this.updateSelections();
        this.updateNodes();
    };
    BarColumnSparkline.prototype.updateSelections = function () {
        var nodeData = this.generateNodeData();
        if (!nodeData) {
            return;
        }
        this.nodeSelectionData = nodeData;
        this.updateRectSelection(nodeData);
        this.updateLabelSelection(nodeData);
    };
    BarColumnSparkline.prototype.updateNodes = function () {
        this.updateRectNodes();
        this.updateLabelNodes();
    };
    BarColumnSparkline.prototype.calculateStep = function (range) {
        var _a;
        var _b = this, xScale = _b.xScale, paddingInner = _b.paddingInner, paddingOuter = _b.paddingOuter, smallestInterval = _b.smallestInterval;
        // calculate step
        var domainLength = xScale.domain[1] - xScale.domain[0];
        var intervals = domainLength / ((_a = smallestInterval === null || smallestInterval === void 0 ? void 0 : smallestInterval.x) !== null && _a !== void 0 ? _a : 1) + 1;
        // The number of intervals/bands is used to determine the width of individual bands by dividing the available range.
        // Allow a maximum of 50 bands to ensure the step (width of individual bands + padding) does not fall below a certain number of pixels.
        // If the number of intervals exceeds 50, calculate the step for 50 bands within the given range.
        // This means there could be some overlap of the bands in the sparkline.
        var maxBands = 50;
        var bands = Math.min(intervals, maxBands);
        var gaps = bands - 1; // number of gaps (padding between bands)
        var step = range / Math.max(1, 2 * paddingOuter + gaps * paddingInner + bands); // step width is a combination of band width and gap width
        return step;
    };
    BarColumnSparkline.prototype.updateYScaleDomain = function () {
        var _a = this, yScale = _a.yScale, yData = _a.yData, valueAxisDomain = _a.valueAxisDomain;
        var yMinMax = extent(yData);
        var yMin = 0;
        var yMax = 1;
        if (yMinMax !== undefined) {
            yMin = this.min = yMinMax[0];
            yMax = this.max = yMinMax[1];
        }
        // if yMin is positive, set yMin to 0
        yMin = yMin < 0 ? yMin : 0;
        // if yMax is negative, set yMax to 0
        yMax = yMax < 0 ? 0 : yMax;
        if (valueAxisDomain) {
            if (valueAxisDomain[1] < yMax) {
                valueAxisDomain[1] = yMax;
            }
            if (valueAxisDomain[0] > yMin) {
                valueAxisDomain[0] = yMin;
            }
        }
        yScale.domain = valueAxisDomain ? valueAxisDomain : [yMin, yMax];
    };
    BarColumnSparkline.prototype.updateRectSelection = function (selectionData) {
        this.rectSelection.update(selectionData);
    };
    BarColumnSparkline.prototype.updateRectNodes = function () {
        var _this = this;
        var _a = this, highlightedDatum = _a.highlightedDatum, nodeFormatter = _a.formatter, fill = _a.fill, stroke = _a.stroke, strokeWidth = _a.strokeWidth;
        var _b = this.highlightStyle, highlightFill = _b.fill, highlightStroke = _b.stroke, highlightStrokeWidth = _b.strokeWidth;
        this.rectSelection.each(function (node, datum, index) {
            var highlighted = datum === highlightedDatum;
            var nodeFill = highlighted && highlightFill !== undefined ? highlightFill : fill;
            var nodeStroke = highlighted && highlightStroke !== undefined ? highlightStroke : stroke;
            var nodeStrokeWidth = highlighted && highlightStrokeWidth !== undefined ? highlightStrokeWidth : strokeWidth;
            var nodeFormat;
            var x = datum.x, y = datum.y, width = datum.width, height = datum.height, seriesDatum = datum.seriesDatum;
            if (nodeFormatter) {
                var first = index === 0;
                var last = index === _this.nodeSelectionData.length - 1;
                var min = seriesDatum.y === _this.min;
                var max = seriesDatum.y === _this.max;
                nodeFormat = nodeFormatter({
                    datum: datum,
                    xValue: seriesDatum.x,
                    yValue: seriesDatum.y,
                    width: width,
                    height: height,
                    min: min,
                    max: max,
                    first: first,
                    last: last,
                    fill: nodeFill,
                    stroke: nodeStroke,
                    strokeWidth: nodeStrokeWidth,
                    highlighted: highlighted,
                });
            }
            node.fill = (nodeFormat && nodeFormat.fill) || nodeFill;
            node.stroke = (nodeFormat && nodeFormat.stroke) || nodeStroke;
            node.strokeWidth = (nodeFormat && nodeFormat.strokeWidth) || nodeStrokeWidth;
            node.x = node.y = 0;
            node.width = width;
            node.height = height;
            node.visible = node.height > 0;
            node.translationX = x;
            node.translationY = y;
            // shifts bars upwards?
            // node.crisp = true;
        });
    };
    BarColumnSparkline.prototype.updateLabelSelection = function (selectionData) {
        this.labelSelection.update(selectionData, function (text) {
            text.tag = BarColumnNodeTag.Label;
            text.pointerEvents = integrated_charts_scene_exports.PointerEvents.None;
        });
    };
    BarColumnSparkline.prototype.updateLabelNodes = function () {
        var _a = this.label, labelEnabled = _a.enabled, fontStyle = _a.fontStyle, fontWeight = _a.fontWeight, fontSize = _a.fontSize, fontFamily = _a.fontFamily, color = _a.color;
        this.labelSelection.each(function (text, datum) {
            var label = datum.label;
            if (label && labelEnabled) {
                text.fontStyle = fontStyle;
                text.fontWeight = fontWeight;
                text.fontSize = fontSize;
                text.fontFamily = fontFamily;
                text.textAlign = label.textAlign;
                text.textBaseline = label.textBaseline;
                text.text = label.text;
                text.x = label.x;
                text.y = label.y;
                text.fill = color;
                text.visible = true;
            }
            else {
                text.visible = false;
            }
        });
    };
    BarColumnSparkline.prototype.getTooltipHtml = function (datum) {
        var _a, _b;
        var dataType = this.dataType;
        var seriesDatum = datum.seriesDatum;
        var yValue = seriesDatum.y;
        var xValue = seriesDatum.x;
        var content = this.formatNumericDatum(yValue);
        var title = dataType === 'array' || dataType === 'object' ? this.formatDatum(xValue) : undefined;
        var defaults = {
            content: content,
            title: title,
        };
        var tooltipRenderer = (_b = (_a = this.processedOptions) === null || _a === void 0 ? void 0 : _a.tooltip) === null || _b === void 0 ? void 0 : _b.renderer;
        if (tooltipRenderer) {
            return toTooltipHtml(tooltipRenderer({
                context: this.context,
                datum: seriesDatum,
                yValue: yValue,
                xValue: xValue,
            }), defaults);
        }
        return toTooltipHtml(defaults);
    };
    BarColumnSparkline.prototype.formatLabelValue = function (value) {
        return value % 1 !== 0 ? value.toFixed(1) : value.toFixed(0);
    };
    return BarColumnSparkline;
}(Sparkline));

var __extends$3 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var isNumber$2 = sparklines_util_exports.isNumber;
var BandScale$1 = sparklines_scale_exports.BandScale;
var BarSparkline = /** @class */ (function (_super) {
    __extends$3(BarSparkline, _super);
    function BarSparkline() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BarSparkline.prototype.updateYScaleRange = function () {
        var _a = this, seriesRect = _a.seriesRect, yScale = _a.yScale;
        yScale.range = [0, seriesRect.width];
    };
    BarSparkline.prototype.updateXScaleRange = function () {
        var _a = this, xScale = _a.xScale, seriesRect = _a.seriesRect, paddingOuter = _a.paddingOuter, paddingInner = _a.paddingInner;
        if (xScale instanceof BandScale$1) {
            xScale.range = [0, seriesRect.height];
            xScale.paddingInner = paddingInner;
            xScale.paddingOuter = paddingOuter;
        }
        else {
            // last node will be clipped if the scale is not a band scale
            // subtract last band width from the range so that the last band is not clipped
            var step = this.calculateStep(seriesRect.height);
            // PaddingOuter and paddingInner are fractions of the step with values between 0 and 1
            var padding = step * paddingOuter; // left and right outer padding
            this.bandWidth = step * (1 - paddingInner);
            xScale.range = [padding, seriesRect.height - padding - this.bandWidth];
        }
    };
    BarSparkline.prototype.updateAxisLine = function () {
        var _a = this, yScale = _a.yScale, axis = _a.axis, axisLine = _a.axisLine, seriesRect = _a.seriesRect;
        var strokeWidth = axis.strokeWidth;
        axisLine.x1 = 0;
        axisLine.x2 = 0;
        axisLine.y1 = 0;
        axisLine.y2 = seriesRect.height;
        axisLine.stroke = axis.stroke;
        axisLine.strokeWidth = strokeWidth + (strokeWidth % 2 === 1 ? 1 : 0);
        var yZero = yScale.convert(0);
        axisLine.translationX = yZero;
    };
    BarSparkline.prototype.generateNodeData = function () {
        var _a = this, data = _a.data, yData = _a.yData, xData = _a.xData, xScale = _a.xScale, yScale = _a.yScale, fill = _a.fill, stroke = _a.stroke, strokeWidth = _a.strokeWidth, label = _a.label;
        if (!data) {
            return;
        }
        var labelFontStyle = label.fontStyle, labelFontWeight = label.fontWeight, labelFontSize = label.fontSize, labelFontFamily = label.fontFamily, labelColor = label.color, labelFormatter = label.formatter, labelPlacement = label.placement;
        var nodeData = [];
        var yZero = yScale.convert(0);
        var continuous = !(xScale instanceof BandScale$1);
        for (var i = 0, n = yData.length; i < n; i++) {
            var yDatum = yData[i];
            var xDatum = xData[i];
            var invalidDatum = yDatum === undefined;
            if (invalidDatum) {
                yDatum = 0;
            }
            var y = xScale.convert(continuous ? xScale.toDomain(xDatum) : xDatum);
            var x = Math.min(yDatum === undefined ? NaN : yScale.convert(yDatum), yZero);
            var bottom = Math.max(yDatum === undefined ? NaN : yScale.convert(yDatum), yZero);
            // if the scale is a band scale, the width of the rects will be the bandwidth, otherwise the width of the rects will be the range / number of items in the data
            var height = !continuous ? xScale.bandwidth : this.bandWidth;
            var width = bottom - x;
            var midPoint = {
                x: yZero,
                y: y,
            };
            var labelText = void 0;
            if (labelFormatter) {
                labelText = labelFormatter({ value: yDatum });
            }
            else {
                labelText = yDatum !== undefined && isNumber$2(yDatum) ? this.formatLabelValue(yDatum) : '';
            }
            var labelY = y + height / 2;
            var labelX = void 0;
            var labelTextBaseline = 'middle';
            var labelTextAlign = void 0;
            var isPositiveY = yDatum !== undefined && yDatum >= 0;
            var labelPadding = 4;
            if (labelPlacement === BarColumnLabelPlacement.Center) {
                labelX = x + width / 2;
                labelTextAlign = 'center';
            }
            else if (labelPlacement === BarColumnLabelPlacement.OutsideEnd) {
                labelX = x + (isPositiveY ? width + labelPadding : -labelPadding);
                labelTextAlign = isPositiveY ? 'start' : 'end';
            }
            else if (labelPlacement === BarColumnLabelPlacement.InsideEnd) {
                labelX = x + (isPositiveY ? width - labelPadding : labelPadding);
                labelTextAlign = isPositiveY ? 'end' : 'start';
                var textSize = integrated_charts_scene_exports.HdpiCanvas.getTextSize(labelText, labelFontFamily);
                var textWidth = textSize.width || 20;
                var positiveBoundary = yZero + textWidth;
                var negativeBoundary = yZero - textWidth;
                var exceedsBoundaries = (isPositiveY && labelX < positiveBoundary) || (!isPositiveY && labelX > negativeBoundary);
                if (exceedsBoundaries) {
                    // if labelX exceeds the boundary, labels should be positioned at `insideBase`.
                    labelX = yZero + labelPadding * (isPositiveY ? 1 : -1);
                    labelTextAlign = isPositiveY ? 'start' : 'end';
                }
            }
            else {
                // if labelPlacement === BarColumnLabelPlacement.InsideBase
                labelX = yZero + labelPadding * (isPositiveY ? 1 : -1);
                labelTextAlign = isPositiveY ? 'start' : 'end';
            }
            nodeData.push({
                x: x,
                y: y,
                width: width,
                height: height,
                fill: fill,
                stroke: stroke,
                strokeWidth: strokeWidth,
                seriesDatum: { x: xDatum, y: invalidDatum ? undefined : yDatum },
                point: midPoint,
                label: {
                    x: labelX,
                    y: labelY,
                    text: labelText,
                    fontStyle: labelFontStyle,
                    fontWeight: labelFontWeight,
                    fontSize: labelFontSize,
                    fontFamily: labelFontFamily,
                    textAlign: labelTextAlign,
                    textBaseline: labelTextBaseline,
                    fill: labelColor,
                },
            });
        }
        return nodeData;
    };
    BarSparkline.prototype.getDistance = function (p1, p2) {
        return Math.abs(p1.y - p2.y);
    };
    BarSparkline.className = 'BarSparkline';
    return BarSparkline;
}(BarColumnSparkline));

var __extends$2 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var isNumber$1 = sparklines_util_exports.isNumber;
var BandScale = sparklines_scale_exports.BandScale;
var ColumnSparkline = /** @class */ (function (_super) {
    __extends$2(ColumnSparkline, _super);
    function ColumnSparkline() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ColumnSparkline.prototype.updateYScaleRange = function () {
        var _a = this, seriesRect = _a.seriesRect, yScale = _a.yScale;
        yScale.range = [seriesRect.height, 0];
    };
    ColumnSparkline.prototype.updateXScaleRange = function () {
        var _a = this, xScale = _a.xScale, seriesRect = _a.seriesRect, paddingOuter = _a.paddingOuter, paddingInner = _a.paddingInner;
        if (xScale instanceof BandScale) {
            xScale.range = [0, seriesRect.width];
            xScale.paddingInner = paddingInner;
            xScale.paddingOuter = paddingOuter;
        }
        else {
            // last node will be clipped if the scale is not a band scale
            // subtract last band width from the range so that the last band is not clipped
            var step = this.calculateStep(seriesRect.width);
            // PaddingOuter and paddingInner are fractions of the step with values between 0 and 1
            var padding = step * paddingOuter; // left and right outer padding
            this.bandWidth = step * (1 - paddingInner);
            xScale.range = [padding, seriesRect.width - padding - this.bandWidth];
        }
    };
    ColumnSparkline.prototype.updateAxisLine = function () {
        var _a = this, yScale = _a.yScale, axis = _a.axis, axisLine = _a.axisLine, seriesRect = _a.seriesRect;
        var strokeWidth = axis.strokeWidth;
        axisLine.x1 = 0;
        axisLine.x2 = seriesRect.width;
        axisLine.y1 = 0;
        axisLine.y2 = 0;
        axisLine.stroke = axis.stroke;
        axisLine.strokeWidth = strokeWidth + (strokeWidth % 2 === 1 ? 1 : 0);
        var yZero = yScale.convert(0);
        axisLine.translationY = yZero;
    };
    ColumnSparkline.prototype.generateNodeData = function () {
        var _a = this, data = _a.data, yData = _a.yData, xData = _a.xData, xScale = _a.xScale, yScale = _a.yScale, fill = _a.fill, stroke = _a.stroke, strokeWidth = _a.strokeWidth, label = _a.label;
        if (!data) {
            return;
        }
        var labelFontStyle = label.fontStyle, labelFontWeight = label.fontWeight, labelFontSize = label.fontSize, labelFontFamily = label.fontFamily, labelColor = label.color, labelFormatter = label.formatter, labelPlacement = label.placement;
        var nodeData = [];
        var yZero = yScale.convert(0);
        var continuous = !(xScale instanceof BandScale);
        for (var i = 0, n = yData.length; i < n; i++) {
            var yDatum = yData[i];
            var xDatum = xData[i];
            var invalidDatum = yDatum === undefined;
            if (invalidDatum) {
                yDatum = 0;
            }
            var y = Math.min(yDatum === undefined ? NaN : yScale.convert(yDatum), yZero);
            var x = xScale.convert(continuous ? xScale.toDomain(xDatum) : xDatum);
            var bottom = Math.max(yDatum === undefined ? NaN : yScale.convert(yDatum), yZero);
            // if the scale is a band scale, the width of the rects will be the bandwidth, otherwise the width of the rects will be the range / number of items in the data
            var width = !continuous ? xScale.bandwidth : this.bandWidth;
            var height = bottom - y;
            var midPoint = {
                x: x + width / 2,
                y: yZero,
            };
            var labelText = void 0;
            if (labelFormatter) {
                labelText = labelFormatter({ value: yDatum });
            }
            else {
                labelText = yDatum !== undefined && isNumber$1(yDatum) ? this.formatLabelValue(yDatum) : '';
            }
            var labelX = x + width / 2;
            var labelY = void 0;
            var labelTextAlign = 'center';
            var labelTextBaseline = void 0;
            var isPositiveY = yDatum !== undefined && yDatum >= 0;
            var labelPadding = 2;
            if (labelPlacement === BarColumnLabelPlacement.Center) {
                labelY = y + height / 2;
                labelTextBaseline = 'middle';
            }
            else if (labelPlacement === BarColumnLabelPlacement.OutsideEnd) {
                labelY = y + (isPositiveY ? -labelPadding : height + labelPadding);
                labelTextBaseline = isPositiveY ? 'bottom' : 'top';
            }
            else if (labelPlacement === BarColumnLabelPlacement.InsideEnd) {
                labelY = y + (isPositiveY ? labelPadding : height - labelPadding);
                labelTextBaseline = isPositiveY ? 'top' : 'bottom';
                var textSize = integrated_charts_scene_exports.HdpiCanvas.getTextSize(labelText, labelFontFamily);
                var textHeight = textSize.height || 10;
                var positiveBoundary = yZero - textHeight;
                var negativeBoundary = yZero + textHeight;
                var exceedsBoundaries = (isPositiveY && labelY > positiveBoundary) || (!isPositiveY && labelY < negativeBoundary);
                if (exceedsBoundaries) {
                    // if labelY exceeds the y boundary, labels should be positioned at the insideBase
                    labelY = yZero + labelPadding * (isPositiveY ? -1 : 1);
                    labelTextBaseline = isPositiveY ? 'bottom' : 'top';
                }
            }
            else {
                // if labelPlacement === BarColumnLabelPlacement.InsideBase
                labelY = yZero + labelPadding * (isPositiveY ? -1 : 1);
                labelTextBaseline = isPositiveY ? 'bottom' : 'top';
            }
            nodeData.push({
                x: x,
                y: y,
                width: width,
                height: height,
                fill: fill,
                stroke: stroke,
                strokeWidth: strokeWidth,
                seriesDatum: { x: xDatum, y: invalidDatum ? undefined : yDatum },
                point: midPoint,
                label: {
                    x: labelX,
                    y: labelY,
                    text: labelText,
                    fontStyle: labelFontStyle,
                    fontWeight: labelFontWeight,
                    fontSize: labelFontSize,
                    fontFamily: labelFontFamily,
                    textAlign: labelTextAlign,
                    textBaseline: labelTextBaseline,
                    fill: labelColor,
                },
            });
        }
        return nodeData;
    };
    ColumnSparkline.className = 'ColumnSparkline';
    return ColumnSparkline;
}(BarColumnSparkline));

var isNumber = sparklines_util_exports.isNumber;
var AgSparkline = /** @class */ (function () {
    function AgSparkline() {
    }
    AgSparkline.create = function (options, tooltip) {
        // avoid mutating user provided options
        options = sparklines_util_exports.jsonMerge([options]);
        var sparkline = getSparklineInstance(options.type);
        if (tooltip) {
            sparkline.tooltip = tooltip;
        }
        initSparkline(sparkline, options);
        initSparklineByType(sparkline, options);
        if (options.data) {
            sparkline.data = options.data;
        }
        sparkline.processedOptions = options;
        return sparkline;
    };
    return AgSparkline;
}());
function getSparklineInstance(type) {
    if (type === void 0) { type = 'line'; }
    switch (type) {
        case 'column':
            return new ColumnSparkline();
        case 'bar':
            return new BarSparkline();
        case 'area':
            return new AreaSparkline();
        case 'line':
        default:
            return new LineSparkline();
    }
}
function initSparklineByType(sparkline, options) {
    switch (options.type) {
        case 'bar':
            initBarColumnSparkline(sparkline, options);
            break;
        case 'column':
            initBarColumnSparkline(sparkline, options);
            break;
        case 'area':
            initAreaSparkline(sparkline, options);
            break;
        case 'line':
        default:
            initLineSparkline(sparkline, options);
            break;
    }
}
function initSparkline(sparkline, options) {
    setValueIfPropertyExists(sparkline, 'context', options.context, options);
    setValueIfPropertyExists(sparkline, 'width', options.width, options);
    setValueIfPropertyExists(sparkline, 'height', options.height, options);
    setValueIfPropertyExists(sparkline, 'container', options.container, options);
    setValueIfPropertyExists(sparkline, 'xKey', options.xKey, options);
    setValueIfPropertyExists(sparkline, 'yKey', options.yKey, options);
    if (options.padding) {
        initPaddingOptions(sparkline.padding, options.padding);
    }
    if (options.axis) {
        initAxisOptions(sparkline.axis, options.axis);
    }
    if (options.highlightStyle) {
        initHighlightStyleOptions(sparkline.highlightStyle, options.highlightStyle);
    }
}
function initLineSparkline(sparkline, options) {
    if (options.marker) {
        initMarkerOptions(sparkline.marker, options.marker);
    }
    if (options.line) {
        initLineOptions(sparkline.line, options.line);
    }
    if (options.crosshairs) {
        initCrosshairsOptions(sparkline.crosshairs, options.crosshairs);
    }
}
function initAreaSparkline(sparkline, options) {
    setValueIfPropertyExists(sparkline, 'fill', options.fill, options);
    if (options.marker) {
        initMarkerOptions(sparkline.marker, options.marker);
    }
    if (options.line) {
        initLineOptions(sparkline.line, options.line);
    }
    if (options.crosshairs) {
        initCrosshairsOptions(sparkline.crosshairs, options.crosshairs);
    }
}
function initBarColumnSparkline(sparkline, options) {
    setValueIfPropertyExists(sparkline, 'valueAxisDomain', options.valueAxisDomain, options);
    setValueIfPropertyExists(sparkline, 'fill', options.fill, options);
    setValueIfPropertyExists(sparkline, 'stroke', options.stroke, options);
    setValueIfPropertyExists(sparkline, 'strokeWidth', options.strokeWidth, options);
    setValueIfPropertyExists(sparkline, 'paddingInner', options.paddingInner, options);
    setValueIfPropertyExists(sparkline, 'paddingOuter', options.paddingOuter, options);
    setValueIfPropertyExists(sparkline, 'formatter', options.formatter, options);
    if (options.label) {
        initLabelOptions(sparkline.label, options.label);
    }
}
function initPaddingOptions(target, options) {
    setValueIfPropertyExists(target, 'top', options.top, options);
    setValueIfPropertyExists(target, 'right', options.right, options);
    setValueIfPropertyExists(target, 'bottom', options.bottom, options);
    setValueIfPropertyExists(target, 'left', options.left, options);
}
function initMarkerOptions(target, options) {
    setValueIfPropertyExists(target, 'enabled', options.enabled, options);
    setValueIfPropertyExists(target, 'size', options.size, options);
    setValueIfPropertyExists(target, 'shape', options.shape, options);
    setValueIfPropertyExists(target, 'fill', options.fill, options);
    setValueIfPropertyExists(target, 'stroke', options.stroke, options);
    setValueIfPropertyExists(target, 'strokeWidth', options.strokeWidth, options);
    setValueIfPropertyExists(target, 'formatter', options.formatter, options);
}
function initLabelOptions(target, options) {
    setValueIfPropertyExists(target, 'enabled', options.enabled, options);
    setValueIfPropertyExists(target, 'fontStyle', options.fontStyle, options);
    setValueIfPropertyExists(target, 'fontWeight', options.fontWeight, options);
    setValueIfPropertyExists(target, 'fontSize', options.fontSize, options);
    setValueIfPropertyExists(target, 'fontFamily', options.fontFamily, options);
    setValueIfPropertyExists(target, 'textAlign', options.textAlign, options);
    setValueIfPropertyExists(target, 'textBaseline', options.textBaseline, options);
    setValueIfPropertyExists(target, 'color', options.color, options);
    setValueIfPropertyExists(target, 'formatter', options.formatter, options);
    setValueIfPropertyExists(target, 'placement', options.placement, options);
}
function initLineOptions(target, options) {
    setValueIfPropertyExists(target, 'stroke', options.stroke, options);
    setValueIfPropertyExists(target, 'strokeWidth', options.strokeWidth, options);
}
function initAxisOptions(target, options) {
    setValueIfPropertyExists(target, 'type', options.type, options);
    setValueIfPropertyExists(target, 'stroke', options.stroke, options);
    setValueIfPropertyExists(target, 'strokeWidth', options.strokeWidth, options);
}
function initHighlightStyleOptions(target, options) {
    setValueIfPropertyExists(target, 'fill', options.fill, options);
    setValueIfPropertyExists(target, 'size', options.size, options);
    setValueIfPropertyExists(target, 'stroke', options.stroke, options);
    setValueIfPropertyExists(target, 'strokeWidth', options.strokeWidth, options);
}
function initCrosshairsOptions(target, options) {
    if (target.xLine && options.xLine) {
        initCrosshairLineOptions(target.xLine, options.xLine);
    }
    if (target.yLine && options.yLine) {
        initCrosshairLineOptions(target.yLine, options.yLine);
    }
}
function initCrosshairLineOptions(target, options) {
    setValueIfPropertyExists(target, 'enabled', options.enabled, options);
    setValueIfPropertyExists(target, 'stroke', options.stroke, options);
    setValueIfPropertyExists(target, 'strokeWidth', options.strokeWidth, options);
    setValueIfPropertyExists(target, 'lineDash', options.lineDash, options);
    setValueIfPropertyExists(target, 'lineCap', options.lineCap, options);
}
var doOnceFlags = {};
/**
 * If the key was passed before, then doesn't execute the func
 * @param {Function} func
 * @param {string} key
 */
function doOnce(func, key) {
    if (doOnceFlags[key]) {
        return;
    }
    func();
    doOnceFlags[key] = true;
}
var offsetValidator = function (property, value, defaultOffset) {
    if (isNumber(value)) {
        return true;
    }
    var message = "AG Charts: ".concat(property, " must be a number, the value you provided is not a valid number. Using the default of ").concat(defaultOffset, "px.");
    doOnce(function () { return console.warn(message); }, "".concat(property, " not a number"));
    return false;
};
var validators = {
    xOffset: offsetValidator,
    yOffset: offsetValidator,
};
function setValueIfPropertyExists(target, property, value, options) {
    if (property in options) {
        if (property in target) {
            var validator = validators[property];
            var isValid = validator ? validator(property, value, target[property]) : true;
            if (isValid && target[property] !== value) {
                // only set property if the value is different to new value
                target[property] = value;
            }
        }
        else {
            console.warn("Property ".concat(property, " does not exist on the target object."));
        }
    }
}

var __extends$1 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (undefined && undefined.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate$1 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var SparklineCellRenderer = /** @class */ (function (_super) {
    __extends$1(SparklineCellRenderer, _super);
    function SparklineCellRenderer() {
        return _super.call(this, SparklineCellRenderer.TEMPLATE) || this;
    }
    SparklineCellRenderer.prototype.init = function (params) {
        var _this = this;
        var firstTimeIn = true;
        var updateSparkline = function () {
            var _a = _this.getGui(), clientWidth = _a.clientWidth, clientHeight = _a.clientHeight;
            if (clientWidth === 0 || clientHeight === 0) {
                return;
            }
            if (firstTimeIn) {
                var options = __assign({ data: params.value, width: clientWidth, height: clientHeight, context: {
                        data: params.data,
                    } }, params.sparklineOptions);
                // create new instance of sparkline
                _this.sparkline = AgSparkline.create(options, _this.sparklineTooltipSingleton.getSparklineTooltip());
                // append sparkline canvas to cell renderer element
                _this.eSparkline.appendChild(_this.sparkline.canvasElement);
                firstTimeIn = false;
            }
            else {
                _this.sparkline.width = clientWidth;
                _this.sparkline.height = clientHeight;
            }
        };
        var unsubscribeFromResize = this.resizeObserverService.observeResize(this.getGui(), updateSparkline);
        this.addDestroyFunc(function () { return unsubscribeFromResize(); });
    };
    SparklineCellRenderer.prototype.refresh = function (params) {
        if (this.sparkline) {
            this.sparkline.data = params.value;
            return true;
        }
        return false;
    };
    SparklineCellRenderer.prototype.destroy = function () {
        if (this.sparkline) {
            this.sparkline.destroy();
        }
        _super.prototype.destroy.call(this);
    };
    SparklineCellRenderer.TEMPLATE /* html */ = "<div class=\"ag-sparkline-wrapper\">\n            <span ref=\"eSparkline\"></span>\n        </div>";
    __decorate$1([
        RefSelector('eSparkline')
    ], SparklineCellRenderer.prototype, "eSparkline", void 0);
    __decorate$1([
        Autowired('resizeObserverService')
    ], SparklineCellRenderer.prototype, "resizeObserverService", void 0);
    __decorate$1([
        Autowired('sparklineTooltipSingleton')
    ], SparklineCellRenderer.prototype, "sparklineTooltipSingleton", void 0);
    return SparklineCellRenderer;
}(Component));

var __extends = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * This 'bean' creates a single sparkline tooltip that is bound to the grid lifecycle.
 */
var SparklineTooltipSingleton = /** @class */ (function (_super) {
    __extends(SparklineTooltipSingleton, _super);
    function SparklineTooltipSingleton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SparklineTooltipSingleton.prototype.postConstruct = function () {
        this.tooltip = new SparklineTooltip();
    };
    SparklineTooltipSingleton.prototype.getSparklineTooltip = function () {
        return this.tooltip;
    };
    SparklineTooltipSingleton.prototype.destroyTooltip = function () {
        if (this.tooltip) {
            this.tooltip.destroy();
        }
    };
    __decorate([
        PostConstruct
    ], SparklineTooltipSingleton.prototype, "postConstruct", null);
    __decorate([
        PreDestroy
    ], SparklineTooltipSingleton.prototype, "destroyTooltip", null);
    SparklineTooltipSingleton = __decorate([
        Bean('sparklineTooltipSingleton')
    ], SparklineTooltipSingleton);
    return SparklineTooltipSingleton;
}(BeanStub));

// DO NOT UPDATE MANUALLY: Generated from script during build time
var VERSION = '31.0.2';

var SparklinesModule = {
    version: VERSION,
    moduleName: ModuleNames.SparklinesModule,
    beans: [SparklineTooltipSingleton],
    userComponents: [{ componentName: 'agSparklineCellRenderer', componentClass: SparklineCellRenderer }],
    dependantModules: [EnterpriseCoreModule],
};

const AllEnterpriseModules = [
    AdvancedFilterModule,
    ClipboardModule,
    ColumnsToolPanelModule,
    ExcelExportModule,
    FiltersToolPanelModule,
    GridChartsModule,
    MasterDetailModule,
    MenuModule,
    MultiFilterModule,
    RangeSelectionModule,
    RichSelectModule,
    RowGroupingModule,
    ServerSideRowModelModule,
    SetFilterModule,
    SideBarModule,
    StatusBarModule,
    ViewportRowModelModule,
    SparklinesModule
];
const AllModules = AllCommunityModules.concat(AllEnterpriseModules);

ModuleRegistry.registerModules(AllEnterpriseModules);

export { AdvancedFilterModule, AllEnterpriseModules, AllModules, ClipboardModule, ColumnsToolPanelModule, EnterpriseCoreModule, ExcelExportModule, FiltersToolPanelModule, GridChartsModule, GridLicenseManager as LicenseManager, MasterDetailModule, MenuModule, MultiFilter, MultiFilterModule, PivotDropZonePanel, PrimaryColsPanel, RangeSelectionModule, RichSelectModule, RowGroupDropZonePanel, RowGroupingModule, ServerSideRowModelModule, SetFilter, SetFilterModule, SideBarModule, SparklinesModule, StatusBarModule, ToolPanelColDefService, ValuesDropZonePanel, ViewportRowModelModule, __FORCE_MODULE_DETECTION_AG_GRID_CORE_EXT, agCharts, exportMultipleSheetsAsExcel, getMultipleSheetsAsExcel };
