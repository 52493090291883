import { Model } from '../core/base.model';
import { Employee } from '../employee/employee.model';

/**
 * A Document Acknowledgment is created when a Company Document of type "acknowledgement"
 * is assigned to an Employee.
 *
 * When the Employee acknowledges the Document Acknowledgment assigned to them,
 * we set the acknowledgedAt timestamp.
 */
export class DocumentAcknowledgement extends Model {
    protected static _resource = 'companyDocuments/:companyDocument/documentAcknowledgements';
    protected static _version = 'v2';
    protected static _serializeAttributes = ['acknowledgedAt'];
    protected static _datetimes = ['acknowledgedAt', 'createdAt', 'updatedAt'];

    get employeeId(): number {
        return this._attributes['employeeId'];
    }

    get acknowledgedAt(): Date {
        return this._attributes['acknowledgedAt'];
    }

    set acknowledgedAt(val: Date) {
        this._attributes['acknowledgedAt'] = val;
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    get companyDocumentId(): number {
        return this._attributes['companyDocumentId'];
    }

    isComplete(): boolean {
        return !!this.acknowledgedAt;
    }
}
