<div class="error-container">
    <div *ngIf="invalidFileTypesSelected" class="error">
        <img appIcon="Exclamation" /> {{ 'components.file-drop.fileNotSupported' | translate }}
    </div>
    <div *ngIf="invalidAmountOfFiles" class="error">
        <img appIcon="Exclamation" /> {{ 'components.file-drop.multipleFilesNotSupported' | translate }}
    </div>
    <div *ngIf="invalidFileSize" class="error">
        <img appIcon="Exclamation" /> {{ 'components.file-drop.fileTooLarge' | translate }}
    </div>
    <div *ngFor="let error of externalErrors" class="error"><img appIcon="Exclamation" /> {{ error }}</div>
</div>

<ng-container *ngIf="!inlineView; else inlineViewTemplate">
    <div
        class="image-upload-container"
        [class.drag-over]="dragOver"
        (drop)="dragOver = false; onDrop($event)"
        (dragenter)="resetValidation(); dragOver = true"
        (dragleave)="dragOver = false"
        (dragover)="onDrag($event)"
    >
        <p class="hint">
            {{ 'components.file-drop.dragAndDrop' | translate }}
            <span *ngIf="showAllowableTypes">({{ allowedTypes }})</span>
            <span class="separator">{{ 'components.file-drop.or' | translate }}</span>
        </p>
        <ui-button type="outline" (click)="fileInput.click()">{{
            'components.file-drop.browseFiles' | translate
        }}</ui-button>
        <input
            name="fileSelector"
            #fileInput
            type="file"
            [multiple]="allowMultiple"
            [accept]="getAllowedTypes()"
            (change)="onFilesSelected(fileInput.files)"
        />
    </div>
</ng-container>

<!--Attach File Template-->
<ng-template #inlineViewTemplate>
    <img class="paper-clip" appIcon="GreenPaperclip" />

    <label
        class="inline-view-container"
        [class.drag-over]="dragOver"
        (drop)="dragOver = false; onDrop($event)"
        (dragenter)="resetValidation(); dragOver = true"
        (dragleave)="dragOver = false"
        (dragover)="onDrag($event)"
    >
        <span id="attach-file" [class.required]="required">{{ 'components.file-drop.attachFile' | translate }}</span>
        <input
            name="fileSelector"
            aria-labelledby="attach-file"
            #fileInput
            [attr.required]="required"
            type="file"
            [multiple]="allowMultiple"
            [accept]="getAllowedTypes()"
            (change)="onFilesSelected(fileInput.files)"
        />
    </label>
</ng-template>
