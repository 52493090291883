<!--Header-->
<div class="flex-container">
    <!--Title (Always left)-->
    <h2 [id]="id" [class.selectable]="collapsible" [class.contextMenu]="contextMenu" (click)="toggle()">
        {{ title | appTranslate }}

        <!-- Pill -->
        <ng-container *ngIf="pillOptions">
            <ui-pill [type]="pillOptions.type"> {{ pillOptions.label | appTranslate }} </ui-pill>
        </ng-container>

        <ng-content select="ui-info"></ng-content>
        <!--Subtitle (always below south)-->
        <div class="sub header" *ngIf="subtitle">{{ subtitle | appTranslate }}</div>
    </h2>

    <!-- Buttons (always right) -->
    <div class="ui right floated buttons-container">
        <ng-content select=".buttons"></ng-content>
    </div>

    <!-- Tab Menu (usually right, sometimes middle)-->
    <div *ngIf="tabMenu" class="ui text menu">
        <ng-content select="app-tab-item"></ng-content>
    </div>

    <!--Edit button (always right) -->
    <ng-container *permission="editPermission; all: true">
        <ui-button type="link" *ngIf="showEdit" (click)="emitEdit()" class="edit">{{
            editLabel | appTranslate
        }}</ui-button>
    </ng-container>

    <ng-container *ngIf="editFlag">
        <ui-button type="link" *ngIf="showEdit" (click)="emitEdit()" class="edit">{{
            editLabel | appTranslate
        }}</ui-button>
    </ng-container>

    <!--Create button (always right) -->
    <ng-container *permission="createPermission; all: true">
        <ui-button type="link" (click)="emitCreate()" *ngIf="showCreate" class="edit">{{
            createLabel | appTranslate
        }}</ui-button>
    </ng-container>

    <ng-container *ngIf="createFlag">
        <ui-button type="link" (click)="emitCreate()" *ngIf="showCreate" class="edit">{{
            createLabel | appTranslate
        }}</ui-button>
    </ng-container>

    <!--Collapsible chevrons (always right)-->
    <ng-container *ngIf="collapsible">
        <mat-icon *ngIf="collapsed" (click)="expand()" svgIcon="chevronRight"></mat-icon>
        <mat-icon *ngIf="!collapsed" (click)="collapse()" svgIcon="chevronDown"></mat-icon>
    </ng-container>

    <!-- Semantic Context Menu -->
    <div
        *ngIf="contextMenu && contextMenuType === 'semantic'"
        class="context-menu ui icon dropdown basic button"
        role="button"
        aria-haspopup="true"
    >
        <mat-icon svgIcon="meatballHorizontal"></mat-icon>
        <div class="left menu">
            <ng-container [ngTemplateOutlet]="dropdownMenu"></ng-container>
        </div>
    </div>

    <!-- Material Context Menu -->
    <ng-container *ngIf="contextMenu && contextMenuType === 'material'">
        <ui-button class="context-menu" type="ghost" [matMenu]="contextMatMenu">
            <div class="sr-only">{{ title | appTranslate }} {{ 'screen-reader-only.moreActions' | translate }}</div>
            <mat-icon svgIcon="meatballHorizontal"></mat-icon>
        </ui-button>
    </ng-container>

    <!--Paginator (always right) -->
    <app-pagination
        *ngIf="paginator"
        [total]="paginator.total"
        [current]="paginator.page"
        [limit]="paginator.pageSize"
        (pageChange)="paginator.page = $event"
    >
    </app-pagination>
</div>

<mat-menu #contextMatMenu>
    <ng-container [ngTemplateOutlet]="dropdownMenu"></ng-container>
</mat-menu>

<ng-template #dropdownMenu>
    <ng-content></ng-content>
</ng-template>
