/**
 * @deprecated Use Shape Icons - see src/app/constants/icons.ts
 */
export enum Icons {
    Missing = '/assets/icons/missing.svg',
    Empty = '/assets/icons/empty.svg',

    /**
     * Outline Icons (IconName + Style)
     */
    // Communication
    AnnouncementOutline = '/assets/icons/outline-svg/Communication/announcement.svg',
    ConversationOutline = '/assets/icons/outline-svg/Communication/conversation.svg',
    // Interface
    PlusOutline = '/assets/icons/outline-svg/Interface/plus.svg',
    CrossUiOutline = '/assets/icons/outline-svg/Interface/cross-ui.svg',
    CrossBlueUiOutline = '/assets/icons/outline-svg/Interface/cross-blue.svg',
    CrossGreyUiOutline = '/assets/icons/outline-svg/Interface/cross-grey.svg',
    CrossGreenUiOutline = '/assets/icons/outline-svg/Interface/cross-green.svg',
    UiComposeOutline = '/assets/icons/outline-svg/Interface/ui-compose.svg',
    CheckOutline = '/assets/icons/outline-svg/Interface/check-ui.svg',
    UserPlusOutline = '/assets/icons/outline-svg/User/user-plus.svg',
    TrashOutline = '/assets/icons/outline-svg/Interface/trash.svg',
    CloudOutline = '/assets/icons/outline-svg/Weather/cloud.svg',
    CloudyDayOutline = '/assets/icons/outline-svg/Weather/cloudy-day.svg',
    PenWriting = '/assets/icons/outline-svg/Writing/pen-writing.svg',
    AlertSign = '/assets/icons/outline-svg/Interface/alert-sign.svg',
    PencilGreyOutline = '/assets/icons/outline-svg/Interface/pencil-grey.svg',
    ExitFullscreen = '/assets/icons/outline-svg/Interface/exit-fullscreen.svg',
    Fullscreen = '/assets/icons/outline-svg/Interface/fullscreen.svg',
    ClockOutline = '/assets/icons/outline-svg/Time/time.svg',
    CaretDown = '/assets/icons/caret-down.svg',
    CaretDownInactive = '/assets/icons/caret-down-inactive.svg',
    CaretDownActive = '/assets/icons/caret-down-active.svg',
    Search = '/assets/icons/search.svg',

    // Arrows
    ArrowTurnOutline = '/assets/icons/outline-svg/Arrows/arrow-turn-up.svg',
    ChevronTopOutline = '/assets/icons/outline-svg/Arrows/chevron-top.svg',
    ChevronBottomOutline = '/assets/icons/outline-svg/Arrows/chevron-bottom.svg',
    ChevronRightOutline = '/assets/icons/outline-svg/Arrows/chevron-right.svg',
    ChevronLeftOutline = '/assets/icons/outline-svg/Arrows/chevron-left.svg',
    // Business
    ProjectFolderOutline = '/assets/icons/outline-svg/Business/project-folder.svg',
    BusinessChecklistOutline = '/assets/icons/outline-svg/Business/business-checklist.svg',
    // Communication
    HappyStickerOutline = '/assets/icons/outline-svg/Communication/happy-sticker.svg',
    MadOutline = '/assets/icons/outline-svg/Communication/mad.svg',
    SadStickerOutline = '/assets/icons/outline-svg/Communication/sad-sticker.svg',
    // Design
    ClipboardOutline = '/assets/icons/outline-svg/Design/clipboard.svg',
    // Interface
    UploadOutline = '/assets/icons/outline-svg/Interface/upload.svg',
    LockOutline = '/assets/icons/outline-svg/Interface/lock.svg',
    UnlockOutline = '/assets/icons/outline-svg/Interface/unlock.svg',
    // Music
    FilterOutline = '/assets/icons/outline-svg/Music/equalizer.svg',
    // Task
    TaskOutline = '/assets/icons/outline-svg/Task/task.svg',
    TaskShared = 'assets/icons/outline-svg/Task/task-shared.svg',
    // Sport
    WhiteFlagOutline = '/assets/icons/outline-svg/Sport/white-flag.svg',
    // Time
    AlertOutline = '/assets/icons/outline-svg/Time/alert.svg',
    BellOutline = '/assets/icons/outline-svg/Time/bell.svg',
    CalendarPlusOutline = '/assets/icons/outline-svg/Time/calendar-plus.svg',
    TomorrowOutline = '/assets/icons/outline-svg/Time/tomorrow.svg',
    ForwardTimeOutline = '/assets/icons/outline-svg/Time/forward-time.svg',
    // Writing
    EditOutline = '/assets/icons/outline-svg/Writing/edit.svg',
    ForwardPageOutline = '/assets/icons/outline-svg/Writing/forward-page.svg',
    TextBoxOutline = '/assets/icons/outline-svg/Writing/text-box.svg',

    /**
     * Solid Icons (IconName + Style)
     */
    TagWhiteSolid = '/assets/icons/solid-svg/Ecommerce/tag-white.svg',
    TagGreySolid = '/assets/icons/solid-svg/Ecommerce/tag-grey.svg',
    JustifyLeft = '/assets/icons/solid-svg/Layout/justify-left.svg',
    MeatballSolid = '/assets/icons/solid-svg/Interface/meatball.svg',
    PenSolid = '/assets/icons/solid-svg/Writing/pen.svg',
    BlowingWindSolid = '/assets/icons/solid-svg/Weather/blowing-wind.svg',
    CloudSolid = '/assets/icons/solid-svg/Weather/cloud.svg',
    CloudyDaySolid = '/assets/icons/solid-svg/Weather/cloudy-day.svg',
    DaySolid = '/assets/icons/solid-svg/Weather/day.svg',
    LightningSolid = '/assets/icons/solid-svg/Weather/lightning.svg',
    PouringSolid = '/assets/icons/solid-svg/Weather/pouring.svg',
    RainingDaySolid = '/assets/icons/solid-svg/Weather/raining-day.svg',
    SnowingSolid = '/assets/icons/solid-svg/Weather/snowing.svg',

    /**
     * Shaded Icons (IconName + Style)
     */
    DropdownShaded = '/assets/icons/shaded-svg/Interface/dropdown.svg',
    DropdownShadedActive = '/assets/icons/shaded-svg-active/Interface/dropdown.svg',
    LocationPinShaded = '/assets/icons/shaded-svg/Travel/location-pin.svg',
    OfficeBagShaded = '/assets/icons/shaded-svg/Business/office-bag-c.svg',

    FileFavouriteShaded = '/assets/icons/shaded-svg/Files/file-favorite.svg',
    FileInformationShaded = '/assets/icons/shaded-svg/Files/file-information.svg',
    FileTickShaded = '/assets/icons/shaded-svg/Files/file-tick.svg',
    FileUploadShaded = '/assets/icons/shaded-svg/Files/file-upload.svg',

    /**
     * Sidebar Icons Both Inactive & Active (Custom Names)
     * @type {String}
     */
    // Inactive
    DashboardOutline = '/assets/icons/outline-svg/Interface/home.svg',
    CompanyOutline = '/assets/icons/outline-svg/Building/hotel-tower.svg',
    EmployeesOutline = '/assets/icons/outline-svg/User/shared-user.svg',
    PayrollOutline = '/assets/icons/outline-svg/Ecommerce/dollar-paper-a.svg',
    TimeOffOutline = '/assets/icons/outline-svg/Time/events.svg',
    PerformanceOutline = '/assets/icons/outline-svg/Interface/dashboard.svg',
    RecruitingOutline = '/assets/icons/outline-svg/User/user-card.svg',
    BenefitsOutline = '/assets/icons/outline-svg/Tech/security-guard.svg',
    TimeAndAttendanceOutline = '/assets/icons/outline-svg/Time/forward-time.svg',
    ReportingOutline = '/assets/icons/outline-svg/Finance/pie-chart.svg',
    SettingsOutline = '/assets/icons/outline-svg/Interface/cog.svg',
    ProfileOutline = '/assets/icons/outline-svg/User/user-b.svg',
    DocumentsOutline = '/assets/icons/outline-svg/File/file.svg',

    /**
     * New Sidebar Icons (normal, selected, active)
     * @type {string}
     */
    // Normal
    NavBenefits = '/assets/icons/navigation/Benefits.svg',
    NavCalendar = '/assets/icons/navigation/Calendar.svg',
    NavCompany = '/assets/icons/navigation/Company.svg',
    NavDocuments = '/assets/icons/navigation/Documents.svg',
    NavEmployees = '/assets/icons/navigation/Employees.svg',
    NavHome = '/assets/icons/navigation/Home.svg',
    NavPayroll = '/assets/icons/navigation/Payroll.svg',
    NavPerformance = '/assets/icons/navigation/Performance.svg',
    NavProfile = '/assets/icons/navigation/Profile.svg',
    NavRecruiting = '/assets/icons/navigation/Recruiting.svg',
    NavReporting = '/assets/icons/navigation/Reporting.svg',
    NavSettings = '/assets/icons/navigation/Settings.svg',
    NavTraining = '/assets/icons/navigation/Training.svg',
    NavSurveys = '/assets/icons/navigation/Surveys.svg',
    NavTimeOff = '/assets/icons/navigation/TimeOff.svg',
    // Selected
    NavBenefitsSelect = '/assets/icons/navigation/Selected/Benefits.svg',
    NavCalendarSelect = '/assets/icons/navigation/Selected/Calendar.svg',
    NavCompanySelect = '/assets/icons/navigation/Selected/Company.svg',
    NavDocumentsSelect = '/assets/icons/navigation/Selected/Documents.svg',
    NavEmployeesSelect = '/assets/icons/navigation/Selected/Employees.svg',
    NavHomeSelect = '/assets/icons/navigation/Selected/Home.svg',
    NavPayrollSelect = '/assets/icons/navigation/Selected/Payroll.svg',
    NavPerformanceSelect = '/assets/icons/navigation/Selected/Performance.svg',
    NavProfileSelect = '/assets/icons/navigation/Selected/Profile.svg',
    NavRecruitingSelect = '/assets/icons/navigation/Selected/Recruiting.svg',
    NavReportingSelect = '/assets/icons/navigation/Selected/Reporting.svg',
    NavSettingsSelect = '/assets/icons/navigation/Selected/Settings.svg',
    NavTrainingSelect = '/assets/icons/navigation/Selected/Training.svg',
    NavSurveysSelect = '/assets/icons/navigation/Selected/Surveys.svg',
    NavTimeOffSelect = '/assets/icons/navigation/Selected/TimeOff.svg',
    // Active
    NavBenefitsActive = '/assets/icons/navigation/Activated/Benefits.svg',
    NavCalendarActive = '/assets/icons/navigation/Activated/Calendar.svg',
    NavCompanyActive = '/assets/icons/navigation/Activated/Company.svg',
    NavDocumentsActive = '/assets/icons/navigation/Activated/Documents.svg',
    NavEmployeesActive = '/assets/icons/navigation/Activated/Employees.svg',
    NavHomeActive = '/assets/icons/navigation/Activated/Home.svg',
    NavPayrollActive = '/assets/icons/navigation/Activated/Payroll.svg',
    NavPerformanceActive = '/assets/icons/navigation/Activated/Performance.svg',
    NavProfileActive = '/assets/icons/navigation/Activated/Profile.svg',
    NavRecruitingActive = '/assets/icons/navigation/Activated/Recruiting.svg',
    NavReportingActive = '/assets/icons/navigation/Activated/Reporting.svg',
    NavSettingsActive = '/assets/icons/navigation/Activated/Settings.svg',
    NavTrainingActive = '/assets/icons/navigation/Activated/Training.svg',
    NavSurveysActive = '/assets/icons/navigation/Activated/Surveys.svg',
    NavTimeOffActive = '/assets/icons/navigation/Activated/TimeOff.svg',

    // Active
    // Interface
    LoginOutlineActive = '/assets/icons/outline-svg-active/Interface/login.svg',
    // General
    DashboardOutlineActive = '/assets/icons/outline-svg-active/Interface/home.svg',
    CompanyOutlineActive = '/assets/icons/outline-svg-active/Building/hotel-tower.svg',
    EmployeesOutlineActive = '/assets/icons/outline-svg-active/User/shared-user.svg',
    PayrollOutlineActive = '/assets/icons/outline-svg-active/Ecommerce/dollar-paper-a.svg',
    TimeOffOutlineActive = '/assets/icons/outline-svg-active/Time/events.svg',
    PerformanceOutlineActive = '/assets/icons/outline-svg-active/Interface/dashboard.svg',
    RecruitingOutlineActive = '/assets/icons/outline-svg-active/User/user-card.svg',
    BenefitsOutlineActive = '/assets/icons/outline-svg-active/Tech/security-guard.svg',
    TimeAndAttendanceOutlineActive = '/assets/icons/outline-svg-active/Time/forward-time.svg',
    ReportingOutlineActive = '/assets/icons/outline-svg-active/Finance/pie-chart.svg',
    SettingsOutlineActive = '/assets/icons/outline-svg-active/Interface/cog.svg',
    ProfileOutlineActive = '/assets/icons/outline-svg-active/User/user-b.svg',
    DocumentsOutlineActive = '/assets/icons/outline-svg-active/File/file.svg',
    // Music
    FilterOutlineActive = '/assets/icons/outline-svg-active/Music/equalizer.svg',
    // Tech
    ChromecastOutlineActive = '/assets/icons/outline-svg-active/Tech/chromecast.svg',

    /** Notifications */
    // Active (Blue)
    NotificationGeneral = '/assets/icons/solid-svg/NotificationCategories/general.svg',
    NotificationDocuments = '/assets/icons/solid-svg/NotificationCategories/documents.svg',
    NotificationPerformance = '/assets/icons/solid-svg/NotificationCategories/performance.svg',
    NotificationRecruiting = '/assets/icons/solid-svg/NotificationCategories/recruiting.svg',
    NotificationReporting = '/assets/icons/solid-svg/NotificationCategories/reporting.svg',
    // Inactive (Grey)
    NotificationGeneralInactive = '/assets/icons/solid-svg/NotificationCategories/general-inactive.svg',
    NotificationDocumentsInactive = '/assets/icons/solid-svg/NotificationCategories/documents-inactive.svg',
    NotificationPerformanceInactive = '/assets/icons/solid-svg/NotificationCategories/performance-inactive.svg',
    NotificationRecruitingInactive = '/assets/icons/solid-svg/NotificationCategories/recruiting-inactive.svg',
    NotificationReportingInactive = '/assets/icons/solid-svg/NotificationCategories/reporting-inactive.svg',

    // Sorting
    SortInactive = '/assets/icons/sort.svg',
    SortAscending = '/assets/icons/sort_ascending.svg',
    SortDescending = '/assets/icons/sort_descending.svg',

    // Stars
    StarDefault = '/assets/icons/stars/star-default.svg',
    StarActive = '/assets/icons/stars/star-active.svg',
    StarHovered = '/assets/icons/stars/star-hovered.svg',

    // Check Mark
    WhiteCheckMark = '/assets/icons/white-check.svg',
    GreyCheckMark = '/assets/icons/grey/check-grey.svg',
    BlueCheckMark = '/assets/icons/blue/check.svg',

    RedCross = '/assets/icons/red/cross.svg',

    // Arrows
    ArrowUp = '/assets/icons/arrow-up.svg',
    ArrowDown = '/assets/icons/arrow-down.svg',
    ArrowForward = '/assets/icons/arrow-forward.svg',
    ArrowForwardBlue = '/assets/icons/arrow-forward-blue.svg',

    BluePaperclip = '/assets/icons/blue/blue-paperclip.svg',
    Paperclip = '/assets/icons/grey/paperclip.svg',
    GreenPaperclip = '/assets/icons/green/paperclip.svg',
    Trash = '/assets/icons/grey/trash.svg',
    TrashDark = '/assets/icons/grey/trash-dark.svg',

    Exclamation = '/assets/icons/white/exclamation.svg',
    Spinner = '/assets/icons/black/spinner.svg',

    // Yellow
    YellowRefresh = '/assets/icons/yellow/refresh.svg',

    // Eyes
    Eye = '/assets/icons/grey/eye.svg',
    MaskedEye = '/assets/icons/grey/masked-eye.svg',

    // Info
    Info = '/assets/icons/info.svg',
    FilledBell = '/assets/icons/filled-bell.svg',
    LightObject = '/assets/icons/light-object.svg',
}
