import { EmployeeHour } from '@app/models/payroll/company/employee-hour.model';
import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { MultiPillColumn } from '@app/modules/table/classes/multi-pill-column.class';
import { PillDisplay } from '@app/modules/table/interfaces/pill-display.interface';
import { isBefore } from 'date-fns';

const options = [
    {
        label: 'pending',
        value: 'pending',
    },
    {
        label: 'approved',
        value: 'approved',
    },
];

export class StatusColumn extends MultiPillColumn<EmployeeHour> {
    title = 'table.employeeHour.status';
    property = 'status';
    sortField = 'status';
    sortable = true;

    constructor(
        private payrollStartDate: string,
        private showLatePill = true
    ) {
        super();

        this.filter = new CheckListFilter('status', options);
    }

    getMultiPillDisplay(employeeHour: EmployeeHour): PillDisplay[] {
        const pills: PillDisplay[] = [
            employeeHour.status.toLowerCase() === 'approved'
                ? {
                      type: 'success',
                      label: 'approved',
                  }
                : {
                      type: 'primary',
                      label: 'pending',
                  },
        ];

        if (this.showLatePill && isBefore(employeeHour.dateUsed, this.payrollStartDate)) {
            pills.push({ type: 'warning', label: 'table.employeeHour.late' });
        }

        if (employeeHour.new) {
            pills.push({ type: 'default', label: 'table.employeeHour.new' });
        }

        return pills;
    }
}
