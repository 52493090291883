import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
} from '@angular/core';
import { DragService } from '@app/services';
import { DraggableData } from '@app/types/drag-and-drop.type';
import { DroppableEventObject } from '@app/interfaces';

/**
 * Make the element draggable using our drag/drop logic. Designed to be used to transfer data, rather than HTML
 * between components (for this, use angular Drag/Drop CDK). Use in conjunction with appDroppable.
 *
 * To function, the component must have the `draggableData` attribute.
 */
@Directive({
    selector: '[appDraggable]',
})
export class DraggableDirective implements OnInit, OnDestroy, OnChanges {
    @Input() dragEnabled = true;
    @Input() draggableData: DraggableData | DraggableData[] | null;
    @Input() droppableZone: string = DragService.DEFAULT_ZONE;

    @Output() dragStart: EventEmitter<DroppableEventObject> = new EventEmitter<DroppableEventObject>();

    private onDragStart;
    private onDragEnd;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private service: DragService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dragEnabled && changes.dragEnabled.currentValue !== changes.dragEnabled.previousValue) {
            // update draggable functionality based on isEnabled or not
            changes.dragEnabled.currentValue ? this.setupDragging() : this.teardownDragging();
        }
    }

    setupDragging(): void {
        this.addDragEvents();
        this.renderer.setProperty(this.elementRef.nativeElement, 'draggable', true);
        this.renderer.addClass(this.elementRef.nativeElement, 'app-draggable');
    }

    teardownDragging(): void {
        this.renderer.setProperty(this.elementRef.nativeElement, 'draggable', false);
        this.renderer.removeClass(this.elementRef.nativeElement, 'app-draggable');
    }

    ngOnInit(): void {
        if (this.dragEnabled) {
            this.setupDragging();
        }
    }

    ngOnDestroy(): void {
        // Remove events
        this.onDragStart ? this.onDragStart() : null;
        this.onDragEnd ? this.onDragEnd() : null;
    }

    private addDragEvents(): void {
        this.onDragStart = this.renderer.listen(
            this.elementRef.nativeElement,
            'dragstart',
            (event: DragEvent): void => {
                this.dragStart.emit({ data: this.draggableData });

                this.service.registerDraggedZone(this.droppableZone);
                event.dataTransfer.effectAllowed = 'copyMove';

                const elem = this.makeDragElement();
                document.body.appendChild(elem);
                event.dataTransfer.setDragImage(elem, 0, 0);

                event.dataTransfer.setData('Text', this.service.storeData(this.draggableData));
            }
        );

        this.onDragEnd = this.renderer.listen(this.elementRef.nativeElement, 'dragend', (): void => {
            this.service.removeDraggedZone();
        });
    }

    /**
     * Create the dragged "item" thats attached to the mouse.
     * Implement using hard coded but could also achieve this using another directive and a styled element - but
     * this should be ok for now.
     */
    private makeDragElement(): HTMLDivElement {
        let itemCount = 1;
        if (Array.isArray(this.draggableData)) {
            itemCount = this.draggableData.length;
        }

        const elem = document.createElement('div');
        elem.id = 'drag-ghost';
        elem.classList.add('blue-box-on-drag', 'padding-2');
        elem.innerText = 'Moving ' + itemCount + ' item' + (itemCount > 1 ? 's' : '');
        elem.style.position = 'absolute';
        elem.style.top = '-1000px';
        elem.style.border = '2px solid #74afb7';
        elem.style.backgroundColor = '#d6faff';
        elem.style.fontWeight = '600';
        return elem;
    }
}
