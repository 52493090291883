import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auth0Action, RouteData } from '@app/interfaces/route-options.interface';
import { AuthService as HumiAuthService } from '@app/services/auth.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export const AUTH_ERROR = 'auth-error';

// We add lottie via a script tag in the index.html rather than through NPM, therefore, we need to declare the package manually here to avoid TS errors
declare let lottie: {
    loadAnimation: ({
        container,
        renderer,
        loop,
        autoplay,
        path,
    }: {
        container: HTMLElement;
        renderer: string;
        loop: boolean;
        autoplay: boolean;
        path: string;
    }) => void;
};

// This component encapsulates all behaviour related to a user's Auth0 state
// Visually the view only contains a loading Humi animation, but will perform different actions based on the action provided
@Component({
    template: ``,
    // This style is a direct copy of the spinner defined in index.html
    styles: [
        `
            :host {
                position: absolute;
                max-width: 5%;
                margin: 15% auto;
                left: 0;
                right: 0;
            }
        `,
    ],
})
export class Auth0Component {
    constructor(
        private humiAuthService: HumiAuthService,
        element: ElementRef,
        route: ActivatedRoute
    ) {
        lottie.loadAnimation({
            container: element.nativeElement,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/assets/humi-logos/loading-animation.json',
        });
        (route.data as Observable<RouteData>).pipe(take(1)).subscribe((data) => {
            const auth0Action: Auth0Action | undefined = data.auth0Action;
            if (auth0Action === 'LOGIN') {
                this.auth0Login();
            }

            if (auth0Action === 'LOGOUT') {
                this.humiAuthService.logout();
            }

            if (auth0Action === 'CALLBACK') {
                /* This is a dummy route that Auth0 will return to immediately after a successful login.
                It intentionally does nothing.

                Auth0 uses handleRedirectCallback to try to restore the previous "appState" after a login,
                meaning it will automatically handle the redirect to the user's previous page (or '/' on first login)

                Since this route does not contain an authGuard or any logic, it gives Auth0 time to execute the callback.

                BE CAUTIOUS BEFORE ADDING ANY CODE HERE - It will likely be interrupted by Auth0's redirect, leading to possible race conditions.
                */
            }
        });
    }

    private async auth0Login(): Promise<void> {
        return this.humiAuthService.redirectToLogin({
            target: '/',
        });
    }
}
