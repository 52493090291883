<div class="reports-table-container" [attr.data-scrollable]="scrollable">
    <!-- Pagination-->
    <div *ngIf="paginationData" class="actions top sticky">
        <app-pagination
            [total]="paginationData.total"
            [current]="paginationData.page"
            [limit]="paginationData.perPage"
            (pageChange)="onPageChange($event)"
        >
        </app-pagination>
    </div>

    <div class="table-wrapper" [class.empty]="!hasRows()">
        <!-- Loader -->
        <div *ngIf="isLoading" class="ui active inverted dimmer">
            <div class="ui loader"></div>
        </div>

        <table mat-table [dataSource]="report.sheet.rows" *ngIf="hasRows(); else empty">
            <!-- Columns -->
            <ng-container
                *ngFor="let column of report.sheet.columns; let i = index"
                [matColumnDef]="column.title"
                [sticky]="column.sticky"
            >
                <th mat-header-cell *matHeaderCellDef [class]="'col-type-' + column.type">
                    <div (click)="column.sortable && onSort(column.sortField)" [class.sortable]="column.sortable">
                        {{ column.title }}
                        <span class="icon" *ngIf="column.sortable && sortingField === column.sortField">
                            <mat-icon [svgIcon]="sortingDirection === 'DESC' ? 'arrowUp' : 'arrowDown'"></mat-icon>
                        </span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let row">
                    <div [class]="'cell-content cell-type-' + column.type">
                        <ng-container [ngSwitch]="row.cells[i].type">
                            <ng-container *ngSwitchCase="'date'">
                                {{ displayDateValue(row.cells[i].value) }}
                            </ng-container>
                            <ng-container *ngSwitchCase="'number_decimal'">
                                {{ row.cells[i].value | number: decimalPlacesForPipe }}
                            </ng-container>
                            <ng-container *ngSwitchCase="'currency'">
                                {{ row.cells[i].value | currency }}
                            </ng-container>
                            <ng-container *ngSwitchDefault> {{ row.cells[i].value }} </ng-container>
                        </ng-container>
                    </div>
                </td>
            </ng-container>

            <!-- Header and Row Declarations -->
            <tr mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()"></tr>
        </table>

        <ng-template #empty>
            <app-empty-state [label]="emptyText"></app-empty-state>
        </ng-template>
    </div>
</div>
