import { Injectable } from '@angular/core';
import { FeatureFlag } from '@app/enums';
import { CAN_MOCK_FEATURE_FLAG_KEY } from '@app/meta/feature-flag.meta';
import { FeatureInterface } from '@interfaces/feature.interface';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { of } from 'rxjs';

interface LocalStorageFeatureInterface {
    setFlag: (flag: FeatureFlag, value?: boolean) => void;
}

@Injectable({
    providedIn: 'root',
})
export class LocalStorageFeatureService implements FeatureInterface, LocalStorageFeatureInterface {
    readonly isClientReady = of(true); // Mocks the LaunchDarkly client as always being ready to get flag value
    readonly isLocal = true;
    private warnedFlags: Set<FeatureFlag> = new Set();

    all(): Promise<LDFlagSet> {
        const featureFlags = Object.values(FeatureFlag).reduce((flags: LDFlagSet, key: string): LDFlagSet => {
            flags[key] = window.localStorage.getItem(`flag.${key}`) === 'true';
            return flags;
        }, {});

        return Promise.resolve(featureFlags);
    }

    has(flag: FeatureFlag): Promise<boolean> {
        return Promise.resolve(this.getFlag(flag));
    }

    hasMany(...flags: FeatureFlag[]): Promise<boolean[]> {
        return Promise.resolve(flags.map((flag) => this.getFlag(flag)));
    }

    setFlag(flag: FeatureFlag, value = true): void {
        window.localStorage.setItem(`flag.${flag.toString()}`, value.toString());
    }

    private getFlag(flag: FeatureFlag): boolean {
        if (!this.warnedFlags.has(flag)) {
            console.warn(
                `You are using a mock version of the "${flag}" feature flag.
If you wish to call LD directly, remove "${CAN_MOCK_FEATURE_FLAG_KEY}" from local storage.`
            );
            this.warnedFlags.add(flag);
        }
        const featureFlagItem = window.localStorage.getItem(`flag.${flag.toString()}`);
        return !!featureFlagItem && featureFlagItem === 'true';
    }
}
