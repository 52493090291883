/**
 * Our system colors.
 * @notice When updating this file please remember to run `yarn build-platform-colors`
 */
export const Colors = {
    primaryDark: '#136570',
    primary: '#167d8a',
    primaryLight: '#74afb7',
    primaryLighter: '#a8d7dd',
    primaryLightest: '#d6faff',
    primaryMaxLightest: '#f3f9f9',

    // Secondary Palette, neutral
    black: '#000000',
    neutralDarkest: '#272a31',
    neutralDarker: '#505664',
    neutralDark: '#727779',
    neutral: '#b2b5bb',
    neutralLight: '#dedede',
    neutralLighter: '#f1f1f1',
    neutralLightest: '#fcfbf9',
    white: '#ffffff',

    primaryFont: '#272a31',
    secondaryFont: '#727779',

    /** Accents **/
    // Highlight - Sunset
    highlightDark: '#cd481a',
    highlight: '#ed521d',
    highlightLight: '#ef6637',
    highlightLighter: '#f17a51',
    highlightLightest: '#f38e6b',

    // Success - Green
    successDark: '#37773f',
    success: '#5e9e6d',
    successLight: '#89bd96',
    successLighter: '#b8dbc2',
    successLightest: '#e9fbee',

    // Error - Red
    errorDark: '#410400',
    error: '#770c06',
    errorLight: '#a12621',
    errorLighter: '#e48180',
    errorLightest: '#ffebeb',

    // Warning - Orange
    warningDark: '#694f00',
    warning: '#ffb300',
    warningLight: '#ffca28',
    warningLighter: '#ffe082',
    warningLightest: '#fff8e1',

    // Other Main Colors
    secondary: '#b2b5bb',
    tertiary: '#ffffff',
};

/**
 * RGBA hex values for Opacities
 */
export const OpacityValues: { [key: string]: string } = {
    o95: 'f2',
    o90: 'e6',
    o85: 'd9',
    o80: 'cc',
    o75: 'bf',
    o70: 'b3',
    o65: 'a6',
    o60: '99',
    o55: '8c',
    o50: '80',
    o45: '73',
    o40: '66',
    o35: '59',
    o30: '4d',
    o25: '40',
    o20: '33',
    o15: '26',
    o10: '1a',
    o5: '0d',
};
