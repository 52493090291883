export enum AnalyticEvents {
    LoginWithGoogle = 'Login with Google',
    AssignRole = 'Assign Role',

    // Onboarding
    EmployeeOnboardingSignUpUsingEmailFromActivationEmail = 'Self Profile - Onboarding - Join Humi - Click Arrow to Proceed',
    EmployeeOnboardingAcceptTerms = 'Self Profile - Onboarding - Step 1 Welcome - Click Next',
    EmployeeOnboardingCompletePersonalInfo = 'Self Profile - Onboarding - Step 2 Personal - Click Next',
    EmployeeOnboardingReviewedDocuments = 'Self Profile - Onboarding - Step 3 Documents - Click Next',
    EmployeeOnboardingCompleteEmploymentInfo = 'Self Profile - Onboarding - Step 4 Employment - Click Next',
    EmployeeOnboardingCompleteSurvey = 'Self Profile - Onboarding - Step 5 Survey - Click Next',
    EmployeeOnboardingCompleteGetStarted = 'Self Profile - Onboarding - Step 6 Get Started - Click Get Started',

    // Custom Field Groups (New Flow)
    SettingsModuleCreateCustomFieldGroup = 'Settings - Custom Fields - Create Field Group - Save',
    SettingsModuleEditCustomFieldGroup = 'Settings - Custom Fields - Field Group - Meatball - Edit - Save',
    SettingsModuleDeleteCustomFieldGroup = 'Settings - Custom Fields - Field Group - Meatball - Delete - Click Delete to Save',

    // Custom Fields (New Flow)
    SettingsModuleCreateCustomField = 'Settings - Custom Fields - Create Field - Save and Assign',
    SettingsModuleEditCustomField = 'Settings - Custom Fields - Edit Custom Field - Save',
    SettingsModuleDeleteCustomField = 'Settings - Custom Fields - Individual Custom Field - Meatball - Delete - Click Delete to Save',
    SettingsModuleAssignCustomField = 'Settings - Custom Fields - Individual Custom Field - Add or Remove Employees - Employees Updated',
    SettingsModuleUpdateCustomFieldValue = 'Settings - Custom Fields - Individual Custom Field - Add or Remove Employees - Field Updated',

    SettingsModuleReorderCustomFields = 'Settings - Custom Fields - Fields - Edit Field - Rearrange Fields - Save',
    SettingsModuleReorderCustomFieldGroups = 'Settings - Custom Fields - Field Groups - Rearrange Field Groups - Save',

    // Importer
    VisitEmployeeImporter = 'Importer - Employee - Visit Employee Importer',
    ImporterNextStep = 'Importer - Employee - Go to Next Step',
    ImporterPreviousStep = 'Importer - Employee - Go to Previous Step',
    ImporterStep1 = 'Importer - Employee - Step 1',
    ImporterStep2 = 'Importer - Employee - Step 2',
    ImporterStep3 = 'Importer - Employee - Step 3',
    ImporterStep4 = 'Importer - Employee - Step 4',
    ImportSuccess = 'Importer - Employee - Import Success',
    ImportFail = 'Importer - Employee - Import Fail',
    ImporterCustomFieldCreated = 'Importer - Employee - Custom Field Created',
    ImporterOfficeCreated = 'Importer - Employee - Office Created',
    ImporterRowDeleted = 'Importer - Employee - Row Deleted',
    ImporterErrorCount = 'Importer - Employee - Error Count',
    ImporterRowErrorCorrected = 'Importer - Employee - Error Corrected',
    ImporterStep1FileExport = 'Importer - Employee - Step 1 - Click Export Employee Data',
    ImporterStep2ColumnRemapped = 'Importer - Employee - Step 2 - Column Remapped',
    ImporterStep3ValueRemapped = 'Importer - Employee - Step 3 - Value Remapped',
    ImporterStep4FullScreenOpened = 'Importer - Employee - Step 4 - Click Open Fullscreen',
    ImporterStep4FullScreenClosed = 'Importer - Employee - Step 4 - Click Exit Fullscreen',
    ImporterStep4NavigateToNextError = 'Importer - Employee - Step 4 - Next Error',
    ImporterStep4NavigateToPreviousError = 'Importer - Employee - Step 4 - Previous Error',
    ImporterDependentFieldModalOpened = 'Importer - Employee - Step 4 - Click Why Do Some Fields Show Error',

    // Onboarding Settings
    PeopleSettingsOnboardingResendInvites = 'People - Settings - Onboarding - Resend Invites',

    // Offboarding Tasks
    OffboardingTaskTemplateCreated = 'Offboarding - Task Template - Created',
    OffboardingTaskTemplateEdited = 'Offboarding - Task Template - Edited',
    OffboardingTaskTemplateClicked = 'Offboarding - Task Template - Clicked',
    OffboardingTaskTemplateDeleted = 'People - Offboarding Tasks - Meatball - Delete - Click Delete to Save',
    OffboardingTaskTemplateCancelled = 'Offboarding - Task Template - Cancelled',
    OffboardingTaskLearnMore = 'Offboarding - Task Template - LearnMore',
    OffboardingTaskApplyModal = 'Offboarding - Task - ModalOpen',
    OffboardingTaskApply = 'Offboarding - Task - Apply',

    // Onboarding Tasks
    OnboardingTaskTemplateCreated = 'Onboarding - Task Template - Created',
    OnboardingTaskTemplateEdited = 'Onboarding - Task Template - Edited',
    OnboardingTaskTemplateClicked = 'Onboarding - Task Template - Clicked',
    OnboardingTaskTemplateDeleted = 'People - Onboarding Tasks - Meatball - Delete - Click Delete to Save',
    OnboardingTaskTemplateCancelled = 'Onboarding - Task Template - Cancelled',
    OnboardingTaskLearnMore = 'Onboarding - Task Template - LearnMore',
    OnboardingTaskApplyModal = 'Onboarding - Task - ModalOpen',
    OnboardingTaskApply = 'Onboarding - Task - Apply',

    // Onboarding Tasks for Employee Profile
    EmployeeProfileOnboardingTaskCreated = 'Employee Profile - Onboarding Tasks - Create New Task - Save',
    EmployeeProfileOnboardingTaskRemind = 'Employee Profile - Onboarding Tasks - Meatball - Remind',
    EmployeeProfileOnboardingTaskEdited = 'Employee Profile - Onboarding Tasks - Meatball - Edit - Save',
    EmployeeProfileOnboardingTaskDeleted = 'Employee Profile - Onboarding Tasks - Meatball - Delete - Click Delete to Save',
    EmployeeProfileOnboardingAddExistingTask = 'Employee Profile - Onboarding Tasks - Add Existing Task - Click Apply to Save',

    // Offboarding Tasks for Employee Profile
    EmployeeProfileOffboardingTaskCreated = 'Employee Profile - Offboarding Tasks - Click Offboard Employee - Click Complete to Save',
    EmployeeProfileOffboardingTaskRemind = 'Employee Profile - Offboarding Tasks - Meatball - Remind',
    EmployeeProfileOffboardingTaskEdited = 'Employee Profile - Offboarding Tasks - Meatball - Edit - Save',
    EmployeeProfileOffboardingTaskDeleted = 'Employee Profile - Offboarding Tasks - Meatball - Delete - Click Delete to Save',
    EmployeeProfileOffboardingAddExistingTask = 'Employee Profile - Offboarding Tasks - Add Existing Task - Click Apply to Save',

    // Dashboard Task Notifications
    DashboardTaskNotificationReminded = 'Dashboard - Task Notifications - Meatball - Remind',
    DashboardTaskNotificationEdited = 'Dashboard - Task Notifications - Meatball - Edit - Save',
    DashboardTaskNotificationDeleted = 'Dashboard - Task Notifications - Meatball - Delete - Click Delete to Save',
    DashboardTaskNotificationViewed = 'Dashboard - Task Notifications - Click to View',
    DashboardTaskNotificationViewedCompletedTasks = 'Dashboard - Task Notifications - Meatball - View Completed Tasks',

    // Tasks Improvements
    DashboardTaskNotificationsAllTasks = 'Dashboard - Task Notifications - All Tasks',
    ApplyTaskTemplateEventFromTaskTemplateDetails = 'People - Tasks - Task Template Details - Create Task from Template - Save',
    AddTasksDuringNewHireFlow = 'People - Directory - Create Employee - Hire - Add Tasks - Click Select to Save',
    AddTasksDuringTerminationFlow = 'Employee Profile - Terminate - Add Offboarding Tasks - Click Apply to Save',
    MarkSubtaskAsCompleteInTaskDetailsModal = 'Tasks - Task Modal - Mark Subtask as Complete',
    MarkTaskAsCompleteInTaskDetailsModal = 'Tasks - Task Modal - Mark Task as Complete',
    MarkSubtaskAsIncompleteInTaskDetailsModal = 'Tasks - Task Modal - Mark Subtask as incomplete',
    MarkTaskAsIncompleteInTaskDetailsModal = 'Tasks - Task Modal - Mark Task as incomplete',
    RemindToCompleteInTaskDetailsModal = 'Tasks - Task Modal - Remind to complete',
    ViewTemplateInTaskDetailsModal = 'Tasks - Task Modal - View template',
    EditEventInTaskDetailsModal = 'Tasks - Task Modal - Edit',
    EmployeeProfileViewTaskDetailsModal = 'Employee Profile - Tasks - Click to View Task',
    PeopleModuleViewTaskDetailsModal = 'People - Tasks - All Tasks - Click to View Task',
    EmployeeProfileTasksApplyTaskTemplate = 'Employee Profile - Tasks - Apply Task Template - Click Apply to Save',
    EmployeeProfileTaskDeleted = 'Employee Profile - Tasks - Meatball - Delete - Click Delete to Save',
    EmployeeProfileTaskEdited = 'Employee Profile - Tasks - Meatball - Edit - Save',
    EmployeeProfileTaskReminded = 'Employee Profile - Tasks - Meatball - Remind',
    PeopleTasksTaskTemplateEditCancel = 'People - Tasks - Task Templates - Edit Template - Cancel',
    PeopleTasksTaskTemplateEditSave = 'People - Tasks - Task Templates - Edit - Save',
    PeopleTasksTaskTemplatesCreateTaskTemplate = 'People - Tasks - Task Templates - Click Create Task Template',
    PeopleTasksTaskTemplatesCreateSave = 'People - Tasks - Task Templates - Create Task Template - Save',
    PeopleTasksTaskTemplatesDeleted = 'People - Tasks - Task Templates - Delete - Click Delete to Save',
    PeopleTasksTaskTemplatesEditLearnMore = 'People - Tasks - Task Templates - Edit Template - Learn More',

    // Payroll
    PayrollRunPayrollClickCreateOffCyclePayroll = 'Payroll - Run Payroll - Click Create Off-Cycle Payroll',
    PayrollRecurringPayrollEditStepAddReimbursementAutosave = 'Payroll - Recurring Payroll - Edit Step - Add Reimbursement - Autosave',
    PayrollRecurringPayrollEditStepUploadHoursDownload = 'Payroll - Recurring Payroll - Edit Step - Upload Hours - Download',
    PayrollRecurringPayrollEditStepUploadHoursSave = 'Payroll - Recurring Payroll - Edit Step - Upload Hours - Save',
    PayrollRecurringPayrollEditStepUploadAdditionalIncomesDownload = 'Payroll - Recurring Payroll - Edit Step - Upload Additional Incomes - Download',
    PayrollRecurringPayrollEditStepUploadAdditionalIncomesSave = 'Payroll - Recurring Payroll - Edit Step - Upload Additional Incomes - Save',
    PayrollEditStepOtherIncomeDoubleOvertimeSave = 'Payroll - Edit Step - Other Income - Double Overtime - Save',
    PayrollEditStepOtherIncomeExtraPaySave = 'Payroll - Edit Step - Other Income - Extra Pay - Save',
    PayrollEditStepOtherIncomeBonusWorkRelatedSave = 'Payroll - Edit Step - Other Income - Bonus Work Related - Save',
    PayrollEditStepOtherIncomeBonusDiscretionarySave = 'Payroll - Edit Step - Other Income - Bonus Discretionary - Save',
    PayrollEditStepOtherIncomeSickPaySave = 'Payroll - Edit Step - Other Income - Sick Pay - Save',
    PayrollEditStepOtherIncomeComissionSave = 'Payroll - Edit Step - Other Income - Comission - Save',
    PayrollEditStepOtherIncomeCarAllowanceSave = 'Payroll - Edit Step - Other Income - Car Allowance - Save',
    PayrollEditStepOtherIncomeVacationPayoutSave = 'Payroll - Edit Step - Other Income - Vacation Payout - Save',
    PayrollEditStepOtherIncomeCellPhoneAllowanceSave = 'Payroll - Edit Step - Other Income - Cell Phone Allowance - Save',
    PayrollEditStepOtherIncomeHolidayPaySave = 'Payroll - Edit Step - Other Income - Holiday Pay - Save',
    PayrollEditStepOtherIncomeTimeWorkedOnHolidaySave = 'Payroll - Edit Step - Other Income - Time Worked On Holiday - Save',
    PayrollEditStepOtherIncomeRetroactivePaySave = 'Payroll - Edit Step - Other Income - Retroactive Pay - Save',
    PayrollEditStepOtherIncomeRetroactivePayHourlySave = 'Payroll - Edit Step - Other Income - Retroactive Pay Hourly - Save',
    PayrollEditStepOtherIncomeControlledTipsSave = 'Payroll - Edit Step - Other Income - Controlled Tips - Save',
    PayrollEditStepOtherIncomeOtherPaySave = 'Payroll - Edit Step - Other Income - Other Pay - Save',
    PayrollEditStepOtherIncomeSeveranceSave = 'Payroll - Edit Step - Other Income - Severance Pay - Save',
    PayrollEditStepOtherIncomePayInLieuOfNoticeSave = 'Payroll - Edit Step - Other Income - Pay In Lieu Of Notice - Save',
    PayrollEditStepOtherIncomeVacationPayoutTimeOffSave = 'Payroll - Edit Step - Other Income - Vacation Payout Time Off - Save',
    PayrollRecurringPayrollReviewStepExpandEmployee = 'Payroll - Recurring Payroll - Review Step - Expand Employee',
    PayrollRecurringPayrollReviewStepCompletedPayrollClickViewPayrollRegister = 'Payroll - Recurring Payroll - Review Step - Completed Payroll - Click View Payroll Register',
    PayrollRecurringPayrollReviewStepCompletedPayrollClickReturnToHome = 'Payroll - Recurring Payroll - Review Step - Completed Payroll - Click Return To Home',
    PayrollOffCycleEditStepAddBonusSave = 'Payroll - Off-Cycle - Edit Step - Add Bonus - Save',
    PayrollOffCycleEditStepAddCommissionAutosave = 'Payroll - Off-Cycle - Edit Step - Add Commission - Autosave',
    PayrollOffCycleEditStepAddOtherIncomeSave = 'Payroll - Off-Cycle - Edit Step - Add Other Income - Save',
    PayrollOffCycleEditStepAddReimbursementAutosave = 'Payroll - Off-Cycle - Edit Step - Add Reimbursement - Autosave',
    PayrollOffCycleReviewStepPayNow = 'Payroll - Off-Cycle - Review Step - Pay Now',
    PayrollOffCycleReviewStepCompletedPayrollClickViewPayrollRegister = 'Payroll - Off-Cycle - Review Step - Completed Payroll - Click View Payroll Register',
    PayrollOffCycleReviewStepCompletedPayrollClickReturnToHome = 'Payroll - Off-Cycle - Review Step - Completed Payroll - Click Return To Home',
    PayrollQuebecReviewStepPayNow = 'Payroll - Quebec - Review Step - Pay Now',
    PayrollBenefitsEmployeesUploadTemplateSave = 'Payroll - Benefits - Employees - Upload Template - Save',
    PayrollBenefitsEmployeesBulkAdjustmentSave = 'Payroll - Benefits - Employees - Bulk Adjustment - Save',
    PayrollBenefitsEmployeesChangeEffectiveDateInTableAutosaves = 'Payroll - Benefits - Employees - Change Effective Date in Table - Autosaves',
    PayrollDeductionsEmployeesUploadTemplateSave = 'Payroll - Deductions - Employees - Upload Template - Save',
    PayrollDeductionsEmployeesBulkAdjustmentSave = 'Payroll - Deductions - Employees - Bulk Adjustment - Save',
    PayrollDeductionsEmployeesChangeEffectiveDateInTableAutosaves = 'Payroll - Deductions - Employees - Change Effective Date in Table - Autosaves',

    // Recruiting
    RecruitingFunnelStageChange = 'Recruiting - Funnel - Stage Change',
    RecruitingJobPostingApplicantsChangeApplicantFunnelStage = 'Recruiting - Job Posting - Applicants - Change Applicant Funnel Stage',
    RecruitingAllApplicantsChangeApplicantFunnelStage = 'Recruiting - All Applicants - Change Applicant Funnel Stage',
    RecruitingApplicantProfileChangeApplicantFunnelStage = 'Recruiting - Applicant Profile - Change Applicant Funnel Stage',
    RecruitingApplicantNavigation = 'Recruiting - Applicant - Navigation Change',
    RecruitingArchiveJob = 'Recruiting - Job Posting - Archived',
    RecruitingPauseJob = 'Recruiting - Job Posting - Paused',
    RecruitingAddedIntegrationJob = 'Recruiting - Job Posting - Added integration',
    RecruitingRemovedIntegrationJob = 'Recruiting - Job Posting - Removed integration',
    RecruitingArchiveApplicant = 'Recruiting - Applicant - Archive',
    RecruitingUnarchiveApplicant = 'Recruiting - Applicant Profile - Click Unarchive to Save',
    RecruitingDeleteApplicant = 'Recruiting - Applicant - Delete',
    RecruitingJobPostingCreateButton = 'Recruiting - Job Posting - Create Button',
    RecruitingAddDocument = 'Recruiting - Job Posting - Add Document',
    RecruitingRemoveDocument = 'Recruiting - Job Posting - Remove Document',
    RecruitingMakeDocumentRequired = 'Recruiting - Job Posting - Make Document Required',
    RecruitingJobPostingSave = 'Recruiting - Job Posting - Save Job',
    RecruitingApplicantDiscussionComment = 'Recruiting - Applicant Discussion - Post Comment',
    RecruitingApplicantProfileMiniPanelTabComment = 'Recruiting - Applicant Profile - Click Post Comment (Mini-Panel)',
    RecruitingApplicantProfileCommentsTab = 'Recruiting - Applicant Profile - Click Post Comment (Comments tab)',
    RecruitingJobPostingCreateHiringTeam = 'Recruiting - Job Posting Create - Hiring Team Assignment',
    RecruitingJobPostingInfoHiringTeam = 'Recruiting - Job Information - Hiring Team Assignment',
    RecruitingApplyFunnelTemplate = 'Recruiting - Funnel - Applied Template',
    RecruitingSaveFunnelTemplate = 'Change Name Test',
    RecruitingApplyPipelineFunnelTemplate = 'Recruiting - Pipeline - Applied Template',
    RecruitingSavePipelineFunnelTemplate = 'Recruiting - Pipeline - Saved Template',
    RecruitingCreateFunnelTemplate = 'Recruiting - Funnel Templates - Create Template',
    RecruitingAllEmailsSendTest = 'Recruiting - All Emails - Send Test',
    RecruitingApplicantProfileEmailApplicantSend = 'Recruiting - Applicant Profile - Email Applicant - Send',
    RecruitingEmailsAllBulkEmailsSend = 'Recruiting - Emails - All Bulk Emails - Send',
    RecruitingApplicantProfileClickEmailHistory = 'Recruiting - Applicant Profile - Click Email History',
    RecruitingApplicantProfileEmailHistoryResend = 'Recruiting - Applicant Profile - Email History - Resend',
    RecruitingJobPostingEditJobDescriptionSave = 'Recruiting - Job Posting - Edit Job Description - Save',
    RecruitingApplicantProfileAddDocumentClickAddToSave = 'Recruiting - Applicant Profile - Add Document - Click Add to Save',
    RecruitingApplicantProfileDocumentMeatballDeleteClickDeleteToSave = 'Recruiting - Applicant Profile - Document - Meatball - Delete - Click Delete to Save',
    RecruitingJobPostingFunnelMeatballClickAddEmailAutomation = 'Recruiting - Job Posting - Funnel - Meatball - Click Add Email Automation',
    RecruitingJobPostingFunnelMeatballAddEmailAutomationSave = 'Recruiting - Job Posting - Funnel - Meatball - Add Email Automation - Save',
    RecruitingJobPostingFunnelMeatballAddEmailAutomationSaveAndSendSave = 'Recruiting - Job Posting - Funnel - Meatball - Add Email Automation - Save and Send - Save',
    RecruitingJobPostingFunnelMeatballClickEditEmailAutomation = 'Recruiting - Job Posting - Funnel - Meatball - Click Edit Email Automation',
    RecruitingJobPostingFunnelMeatballEditEmailAutomationSave = 'Recruiting - Job Posting - Funnel - Meatball - Edit Email Automation - Save',
    RecruitingJobPostingFunnelMeatballEditEmailAutomationRemoveClickRemoveToSave = 'Recruiting - Job Posting - Funnel - Meatball - Edit Email Automation - Remove - Click Remove to Save',
    RecruitingJobPostingFunnelChangeApplicantFunnelStageClickSendEmail = 'Recruiting - Job Posting - Funnel - Change Applicant Funnel Stage - Click Send Email',
    RecruitingJobPostingFunnelChangeApplicantFunnelStageClickSkipEmail = 'Recruiting - Job Posting - Funnel - Change Applicant Funnel Stage - Click Skip Email',
    RecruitingFunnelTemplatesMeatballEditMeatballAddEmailAutomationSave = 'Recruiting - Funnel Templates - Meatball - Edit - Meatball - Add Email Automation - Save',
    RecruitingFunnelTemplatesMeatballEditMeatballEditEmailAutomationSave = 'Recruiting - Funnel Templates - Meatball - Edit - Meatball - Edit Email Automation - Save',
    RecruitingFunnelTemplatesMeatballEditMeatballEditEmailAutomationRemoveClickRemoveToSave = 'Recruiting - Funnel Templates - Meatball - Edit - Meatball - Edit Email Automation - Remove - Click Remove to Save',
    RecruitingApplicantProfileOtherApplicationsClickHyperlinkForSisterProfile = 'Recruiting - Applicant Profile - Other Applications - Click Hyperlink for Sister Profile',
    RecruitingJobPostingMeatballClickDuplicate = 'Recruiting - Job Posting - Meatball - Click Duplicate',
    RecruitingJobPostingMeatballCreateDuplicateSave = 'Recruiting - Job Posting - Meatball - Create Duplicate - Save',
    RecruitingApplicantProfileMeatballClickHire = 'Recruiting - Applicant Profile - Meatball - Click Hire',
    RecruitingApplicantProfileMeatballHireClickCompleteToSave = 'Recruiting - Applicant Profile - Meatball - Hire - Click Complete to Save',
    RecruitingJobPostingInformationEditSave = 'Recruiting - Job Posting - Information - Edit - Save',
    RecruitingJobPostingClickJobPostingURL = 'Recruiting - Job Posting - Click Job Posting URL',
    RecruitingJobPostingFunnelChangeFunnel = 'Recruiting - Job Posting - Funnel - Change Funnel',
    RecruitingJobBoardYourJobBoardEditSave = 'Recruiting - Job Board - Your Job Board - Edit - Save',
    RecruitingJobBoardBrandAssetsEditSave = 'Recruiting - Job Board - Brand Assets - Edit - Save',
    RecruitingJobBoardAdvancedStylesEditSave = 'Recruiting - Job Board - Advanced Styles - Edit - Save',
    RecruitingJobBoardClickJobBoardAddressURL = 'Recruiting - Job Board - Click Job Board Address URL',
    RecruitingApplicantProfileMeatballMoveApplicantClickConfirmToSave = 'Recruiting - Applicant Profile - Meatball - Move Applicant - Click Confirm to Save',
    RecruitingApplicantProfileMeatballCopyApplicantClickConfirmToSave = 'Recruiting - Applicant Profile - Meatball - Copy Applicant - Click Confirm to Save',
    RecruitingApplicantsSearch = 'Recruiting - Applicants - Search',
    RecruitingApplicantsClearFilters = 'Recruiting - Applicants - Clear Filters',
    RecruitingApplicantsFilterAppliedJobPosting = 'Recruiting - Applicants - Filter Applied - Job Posting',
    RecruitingApplicantsFilterAppliedStatus = 'Recruiting - Applicants - Filter Applied - Status',
    RecruitingApplicantsFilterAppliedRating = 'Recruiting - Applicants - Filter Applied - Rating',
    RecruitingApplicantsFilterAppliedJobFunnel = 'Recruiting - Applicants - Filter Applied - Job Funnel',
    RecruitingApplicantsExportCSV = 'Recruiting - Applicants - Export - CSV',
    RecruitingApplicantsExportXLSX = 'Recruiting - Applicants - Export - XLSX',
    RecruitingJobPostingInterviewsCreateInterviewFromExisting = 'Recruiting - Job Posting - Interviews - Create Interview - From Existing',
    RecruitngJobPostingInterviewsCreateInterviewFromScratch = 'Recruiting - Job Posting - Interviews - Create Interview - From Scratch',
    RecruitingJobPostingInterviewsCreateInterviewClickCompleteToSave = 'Recruiting - Job Posting - Interviews - Create Interview - Click Complete to Save',
    RecruitngJobPostingInterviewsCreateInterviewAddQuestionMultipleChoice = 'Recruiting - Job Posting - Interviews - Create Interview - Add Question - Multiple Choice',
    RecruitingJobPostingInterviewsCreateInterviewAddQuestionOpnionScale = 'Recruiting - Job Posting - Interviews - Create Interview - Add Question - Opinion Scale',
    RecruitingJobPostingInterviewsCreateInteviewAddQuestionOpenQuestion = 'Recruiting - Job Posting - Interviews - Create Interview - Add Question - Open Question',
    RecruitngJobPostingInterviewsCreateInterviewAddQuestionYesNo = 'Recruiting - Job Posting - Interviews - Create Interview - Add Question - Yes/No',
    RecruitingJobPostingInterviewsAssignInterviewClickCompleteToSave = 'Recruiting - Job Posting - Interviews - Assign Interview - Click Complete to Save',
    RecruitingJobPostingInterviewsEditInterviewClickCompleteToSave = 'Recruiting - Job Posting - Interviews - Edit Interview - Click Complete to Save',
    RecruitingJobPostingInterviewsDeleteInterview = 'Recruiting - Job Posting - Interviews - Delete Interview',
    RecruitngJobPostingInterviewsViewApplicantInterview = 'Recruiting - Job Posting - Interviews - View Applicant Interview',
    RecrutingApplciantInterviewsAssignInterviewClickAssignToSave = 'Recruiting - Applicant Profile - Interviews - Assign Interview - Click Assign to Save',
    RecruitingJobPostingInterviewsApplicantInterviewDeleteInterviewInstance = 'Recruiting - Job Posting - Interviews - Applicant Interview - Delete Interview Instance',
    RecruitingJobPostingInterviewsApplicantInterviewEditInterviewInstanceClickSave = 'Recruiting - Job Posting - Interviews - Applicant Interview - Edit Interview Instance - Click Save',
    RecruitingJobPostingInterviewsApplicantInterviewClickOpenToAccess = 'Recruiting - Job Posting - Interviews - Applicant Interview - Click Open to Access',
    RecruitingMyInterviewClickOpenToAccess = 'Recruiting - My Interview - Click Open to Access',
    RecruitingInterviewEmailNotificationClickToOpenToAccess = 'Recruiting - Interview Email Notification - Click Open to Access',
    RecruitingMyInterviewClickViewToAccess = 'Recruiting - My Interview - Click View to Access',
    RecruitingJobPostingInterviewsApplicantInterviewInterviewFeedbackSubmit = 'Recruiting - Job Posting - Interviews - Applicant Interview - Interview Feedback - Submit',
    RecruitingCreateJobPostingAnyStepClickSaveDraft = 'Recruiting - Create Job Posting - Any Step - Click Save Draft',
    RecruitingDuplicateJobPostingAnyStepClickSaveDraft = 'Recruiting - Duplicate Job Posting - Any Step - Click Save Draft',
    RecruitingJobPostingMeatballDeleteJobPostingDraftDelete = 'Recruiting - Job Posting - Meatball - Delete Job Posting Draft - Delete',
    RecruitingJobPostingEditDraft = 'Recruiting - Job Posting - Edit Draft',
    RecruitingCreateJobPostingUnsavedChangesClickSaveDraft = 'Recruiting - Create Job Posting - Unsaved Changes - Click Save Draft',
    RecruitingCreateJobPostingUnsavedChangesClickDiscardChanges = 'Recruiting - Create Job Posting - Unsaved Changes - Click Discard Changes',
    RecruitingDuplicateJobPostingUnsavedChangesClickSaveDraft = 'Recruiting - Duplicate Job Posting - Unsaved Changes - Click Save Draft',
    RecruitingDuplicateJobPostingUnsavedChangesClickDiscardChanges = 'Recruiting - Duplicate Job Posting - Unsaved Changes - Click Discard Changes',

    RecruitingJobPostingApplicantsSearch = 'Recruiting - Job Posting - Applicants - Search',
    RecruitingJobPostingApplicantsClearFilters = 'Recruiting - Job Posting - Applicants - Clear Filters',
    RecruitingJobPostingApplicantsFilterAppliedJobPosting = 'Recruiting - Job Posting - Applicants - Filter Applied - Job Posting',
    RecruitingJobPostingApplicantsFilterAppliedStatus = 'Recruiting - Job Posting - Applicants - Filter Applied - Status',
    RecruitingJobPostingApplicantsFilterAppliedRating = 'Recruiting - Job Posting - Applicants - Filter Applied - Rating',
    RecruitingJobPostingApplicantsFilterAppliedJobFunnel = 'Recruiting - Job Posting - Applicants - Filter Applied - Job Funnel',
    RecruitingJobPostingApplicantsExportCSV = 'Recruiting - Job Posting - Applicants - Export - CSV',
    RecruitingJobPostingApplicantsExportXLSX = 'Recruiting - Job Posting - Applicants - Export - XLSX',

    RecruitingJobPostingInformationSubscribe = 'Recruiting - Job Posting - Information - Subscribe',
    RecruitingJobPostingInformationUnsubscribe = 'Recruiting - Job Posting - Information - Unsubscribe',

    PeopleDirectoryCreateEmployeeHireAssignWorkScheduleClickSelectToSave = 'People - Directory - Create Employee - Hire - Assign Work Schedules - Click Select to Save',

    // Simply Benefits
    SimplyBenefitsAccessAdminPortal = 'Click Benefits Module - Simply Benefits Admin Login',
    SimplyBenefitsEmployeeProfileThenAccessAdminPortal = 'Employee Profile - Click View More Benefits Details - Simply Benefits Admin Login',
    SimplyBenefitsOnboardingEmployeeAssignClass = 'People - Directory - Create Employee - Hire - Assign Simply Benefit Class - Save',
    SimplyBenefitsAccessEmployeePortal = 'Click Benefits Module - Simply Benefits Employee Login',

    // Time Off
    TimeOffTypesAndPoliciesCreatingPolicyAndSaving = 'Time Off - Types & Policies - Creating a Policy - Save',
    TimeOffTypesAndPoliciesCreatingWorkSchedule = 'Time Off - Creating a Work Schedule - Save',
    TimeOffTypesAndPoliciesCreatingPolicyType = 'Time Off - Types & Policies - Creating a Type - Save',
    TimeOffTypesAndPoliciesAssignPolicy = 'Time Off - Types & Policies - Assign a Policy - Click Save',
    TimeOffTypesAndPoliciesCreatingApprovalFlow = 'Time Off - Creating an Approval Flow - Save',
    TimeOffCreatingRequest = 'Time Off - Creating a Request - Request and Save',
    TimeOffManageRequestClickApprove = 'Time Off - Manage Request - Click Approve',
    TimeOffManageRequestClickDeny = 'Time Off - Manage Request - Click Deny',
    TimeOffManageRequestMeatBallEdit = 'Time Off - Manage Request - Meatball - Edit and Save',
    TimeOffManageRequestMeatBallDelete = 'Time Off - Manage Request - Meatball - Delete and Save',
    TimeOffManageRequestMeatBallReset = 'Time Off - Manage Request - Meatball - Reset and Save',
    TimeOffManageRequestMeatBallForceApprove = 'Time Off - Manage Request - Meatball - Force Approve and Save',
    TimeOffManageRequestMeatBallForceDeny = 'Time Off - Manage Request - Meatball - Force Deny and Save',
    TimeOffRequestsMeatballApprove = 'Time Off - Requests - Meatball - Click Approve',
    TimeOffRequestsMeatballDeny = 'Time Off - Requests - Meatball - Click Deny',
    TimeOffSettingsOverallSettingsBackupApproverLink = 'Time Off - Settings - Overall Settings - Backup Approver - Click Learn more about Backup Approvers',
    TimeOffManageARequestApprovalFlowLink = 'Time Off - Manage a Request - Approval Flow - Click Learn more about Backup Approvers',
    TimeOffSettingsOverallSettingsUpdateBackupApproverField = 'Time Off - Settings - Overall Settings - Update Backup Approver Field - Autosave',
    TimeOffManageRequestClickCreateNewRequest = 'Time Off - Manage Request - Click Create New Request',
    TimeOffRequestsMeatballDenyAddCommentClickDenyRequestToSave = 'Time Off - Requests - Meatball - Deny - Add Comment - Click Deny Request to Save',
    TimeOffManageRequestMeatballForceDenyAddCommentClickForceDenyRequestToSave = 'Time Off - Manage Request - Meatball - Force Deny - Add Comment - Click Force Deny Request to Save',
    TimeOffManageRequestMeatballDenyAddCommentClickDenyRequestToSave = 'Time Off - Manage Request - Deny - Add Comment - Click Deny Request to Save',
    TimeOffEditWorkScheduleAddStatHolidaysSave = 'Time Off - Edit Work Schedule - Add Stat Holidays - Save',
    TimeOffEditWorkScheduleAddCustomHolidaysSave = 'Time Off - Edit Work Schedule - Add Custom Holidays - Save',
    TimeOffEditWorkScheduleDeleteHolidaysSave = 'Time Off - Edit Work Schedule - Delete',
    TimeOffEditHolidaySave = 'Time Off - Edit Work Schedule - Edit Holidays - Save',

    // Employee Profile
    EmployeeProfileTimeOffPolicyCardMeatballViewDetailsDone = 'Employee Profile - Time Off Policy Card - Meatball - View Details - Click Done',
    EmployeeProfileTimeOffPolicyCardMeatballCalculateBalanceDone = 'Employee Profile - Time Off Policy Card - Meatball - Calculate Balance - Click Done',
    EmployeeProfileTimeOffPolicyCardMeatballMakeAdjustment = 'Employee Profile - Time Off Policy Card - Meatball - Make Adjustment - Click Save',
    EmployeeProfileTimeOffPolicyCardMeatballEraseAccruals = 'Employee Profile - Time Off Policy Card - Meatball - Erase Accruals and Save”',
    EmployeeProfileTimeOffPolicyCardMeatballChangePolicy = 'Employee Profile - Time Off Policy Card - Meatball - Change Policy - Click Save',
    EmployeeProfilePersonalTabBasicInformationSave = 'Employee Profile - Personal - Basic Information - Edit - Save',
    EmployeeProfilePersonalTabPersonalInformationSave = 'Employee Profile - Personal - Personal Information - Edit - Save',
    EmployeeProfilePersonalTabAddressSave = 'Employee Profile - Personal - Address - Edit - Save',
    EmployeeProfilePersonalTabEmergencyContactsSave = 'Employee Profile - Personal - Emergency Contacts - Edit - Save',
    EmployeeProfilePersonalTabCustomFieldSave = 'Employee Profile - Personal - Custom Field - Edit - Save',

    // Employee Profile - Job & Pay Tab
    EmployeeProfileJobAndPayTabEmploymentInformationHireDateSave = 'Employee Profile - Job & Pay - Employment Information - Edit Hire Date - Save',
    EmployeeProfileJobAndPayTabEmploymentInformationReportsToSave = 'Employee Profile - Job & Pay - Employment Information - Edit Reports To - Save',
    EmployeeProfileJobAndPayTabEmploymentInformationDepartmentSave = 'Employee Profile - Job & Pay - Employment Information - Edit Department - Save',
    EmployeeProfileJobAndPayTabEmploymentInformationPositionSave = 'Employee Profile - Job & Pay - Employment Information - Edit Position - Save',
    EmployeeProfileJobAndPayTabEmploymentInformationOfficeNameSave = 'Employee Profile - Job & Pay - Employment Information - Edit Office Name - Save',
    EmployeeProfileJobAndPayTabEmploymentInformationEmploymentTypeSave = 'Employee Profile - Job & Pay - Employment Information - Edit Employment Type - Save',
    EmployeeProfileJobAndPayTabEmploymentInformationStockOptionsSave = 'Employee Profile - Job & Pay - Employment Information - Edit Stock Options - Save',
    EmployeeProfileJobAndPayTabEmploymentInformationAdditionalTermsSave = 'Employee Profile - Job & Pay - Employment Information - Edit Additional Terms - Save',
    EmployeeProfileJobAndPayTabEmploymentInformationAddToPayroll = 'Employee Profile - Job & Pay - Employment Information - Pay - Click Add To Payroll to Save',
    EmployeeProfileJobAndPayTabBankAccountSave = 'Employee Profile - Job & Pay - Bank Account - Edit - Save',
    EmployeeProfilePerformanceClickCreateGoal = 'Employee Profile - Performance - Click Create Goal',
    EmployeeProfilePerformanceCreateGoalsClickCompleteToSave = 'Employee Profile - Performance - Create Goals - Click Complete to Save',
    EmployeeProfileJobAndPayTabHistoricalCompensationCreateClick = 'Employee Profile - Job & Pay - Historical Compensation - Click Create Compensation',
    EmployeeProfileJobAndPayTabHistoricalCompensationCreateNewSave = 'Employee Profile - Job & Pay - Historical Compensation - Create New Compensation - Save',
    EmployeeProfileJobAndPayTabHistoricalCompensationCreateAdditionalSave = 'Employee Profile - Job & Pay - Historical Compensation - Create Additional Compensation - Save',
    EmployeeProfileJobAndPayTabHistoricalCompensationEditSave = 'Employee Profile - Job & Pay - Historical Compensation - Meatball - Edit - Save',
    EmployeeProfileJobAndPayTabHistoricalCompensationDelete = 'Employee Profile - Job & Pay - Historical Compensation - Meatball - Delete - Delete Compensation',
    EmployeeProfileJobAndPayTabCompensationCreateOverlapError = 'Employee Profile - Job & Pay - Create Compensation - Form - Create - Create Compensation Overlap Error',
    EmployeeProfileJobAndPayTabCompensationUpdateOverlapError = 'Employee Profile - Job & Pay - Update Compensation - Form - Update - Update Compensation Overlap Error',

    // Employee Profile - Benefits Tab
    EmployeeProfileBenefitsTabCreateNewPlan = 'Employee Profile - Benefits - All Paths to Add New Plan -  Save',
    EmployeeProfileBenefitsTabEditExistingPlan = 'Employee Profile - Benefits - Edit Existing Plan -  Save',
    EmployeeProfileBenefitsTabClickCarrierPortal = 'Employee Profile - Benefits - Click Carrier Portal',

    // Employee Profile - Paystubs Tab
    EmployeeProfilePaystubsTabClickView = 'Employee Profile - Paystubs - Click View',
    EmployeeProfilePaystubsTabClickDownload = 'Employee Profile - Paystubs - Click Download',

    // Employee Profile - Account Tab
    EmployeeProfileAccountTabClickSignInAsEmployee = 'Employee Profile - Account - Click Sign in as Employee',
    EmployeeProfileAccountTabClickLockAccount = 'Employee Profile - Account - Click Lock Account',
    EmployeeProfileAccountTabClickUnlock = 'Employee Profile - Account - Click Unlock',
    EmployeeProfileAccountTabAddNewEmail = 'Employee Profile - Account - Emails - Add New Email - Save',
    EmployeeProfileAccountTabClickMakePrimary = 'Employee Profile - Account - Emails - Add New Email - Save',
    EmployeeProfileAccountTabDeleteEmail = 'Employee Profile - Account - Emails - Add New Email - Save',
    // Employee Profile - Reminders Tab
    EmployeeProfileRemindersTabCreateReminder = 'Employee Profile - Reminders - Create Reminder - Save',
    EmployeeProfileRemindersTabEditReminder = 'Employee Profile - Reminders - Meatball - Edit - Save',
    EmployeeProfileRemindersTabDeleteReminder = 'Employee Profile - Reminders - Meatball - Delete - Click Delete to Save',

    // Employee Profile - Records Tab
    EmployeeProfileRecordsTabCreateRecord = 'Employee Profile - Records - Create Record - Save',
    EmployeeProfileRecordsTabEditRecord = 'Employee Profile - Records - Meatball - Edit - Save',
    EmployeeProfileRecordsTabDeleteRecord = 'Employee Profile - Records - Meatball - Delete - Click Delete to Save',

    // Employee Profile - Journey Tab
    EmployeeProfileJourneyTabSalarySave = 'Employee Profile - Journey - Create Journey Item - Salary - Save',
    EmployeeProfileJourneyTabDepartmentSave = 'Employee Profile - Journey - Create Journey Item - Department - Save',
    EmployeeProfileJourneyTabStockOptionsSave = 'Employee Profile - Journey - Create Journey Item - Stock Options - Save',
    EmployeeProfileJourneyTabCompensationTypeSave = 'Employee Profile - Journey - Create Journey Item - Compensation Type - Save',
    EmployeeProfileJourneyTabEmploymentTypeSave = 'Employee Profile - Journey - Create Journey Item - Employment Type - Save',
    EmployeeProfileJourneyTabPositionSave = 'Employee Profile - Journey - Create Journey Item - Position - Save',
    EmployeeProfileJourneyTabDeleteJourney = 'Employee Profile - Journey - Delete - Click Delete to Save',

    // Employee Profile - Performance
    EmployeeProfilePerformanceClickCreateReview = 'Employee Profile - Performance - Click Create Review',
    EmployeeProfilePerformanceCreateReviewClickCompleteToSave = ' Employee Profile - Performance - Create Review - Click Complete to Save',
    EmployeeProfilePerformanceClickCreateFeedback = 'Employee Profile - Performance - Click Create Feedback',

    // Employee Profile - Meatball
    EmployeeProfileMeatballPermanentlyDelete = 'Employee Profile - Meatball - Delete - Click Permanently Delete to Save',
    EmployeeProfileMeatballTerminate = 'Employee Profile - Meatball - Terminate - Click Complete to Save',
    EmployeeProfileMeatballRehire = 'Employee Profile - Meatball - Rehire - Click Complete to Save',
    EmployeeProfileMeatballResendInvite = 'Employee Profile - Meatball - Click Resend Invite',

    // Self Profile - Personal Tab
    SelfProfilePersonalTabEditBasicInfo = 'Self Profile - Personal - Edit Basic Information - Save',
    SelfProfilePersonalTabEditPersonalInfo = 'Self Profile - Personal - Edit Personal Information - Save',
    SelfProfilePersonalTabEditAddress = 'Self Profile - Personal - Edit Address - Save',
    SelfProfilePersonalTabEditEmergencyContacts = 'Self Profile - Personal - Edit Emergency Contacts - Save',
    SelfProfilePersonalTabEditCustomField = 'Self Profile - Personal - Edit Custom Field - Save',

    // Self Profile - Job & Pay Tab
    SelfProfileJobAndPayTabEditBankAccount = 'Self Profile - Job & Pay - Edit Bank Account - Save',
    SelfProfileJobAndPayTabEditCustomField = 'Self Profile - Job & Pay - Edit Custom Field - Save',

    // Self Profile - Reminders Tab
    SelfProfileRemindersTabCreateReminder = 'Self Profile - Reminders - Create Reminder - Save',
    SelfProfileRemindersTabEditReminder = 'Self Profile - Reminders - Meatball - Edit - Save',
    SelfProfileRemindersTabDeleteReminder = 'Self Profile - Reminders - Meatball - Delete - Click Delete to Save',

    // Self Profile - Account Tab
    SelfProfileAccountTabEditInfo = 'Self Profile - Account - Edit Information - Save',
    SelfProfileAccountTabChangePassword = 'Self Profile - Account - Edit Password - Save',
    SelfProfileAccountTabAddEmail = 'Self Profile - Account - Emails - Add New Email - Save',
    SelfProfileAccountTabMakeEmailPrimary = 'Self Profile - Account - Emails - Meatball - Make Primary - Click Confirm to Save',
    SelfProfileAccountTabDeleteEmail = 'Self Profile - Account - Emails - Meatball - Delete - Click Confirm to Save',
    SelfProfileAccountTabResendEmailVerification = 'Self Profile - Account - Emails - Meatball - Click Resend Verification',

    // Reporting
    ReportingDataImportTimeOffRequestsImport = 'Reporting - Data Import - Time Off Requests - Click Import',
    ReportingDataImportTimeOffBalancesImport = 'Reporting - Data Import - Time Off Balances - Click Import',
    ReportingDataImportTimeOffAdjustmentsImport = 'Reporting - Data Import - Time Off Adjustments - Click Import',
    ReportingGeneralTimeOffBalancesSaveFilter = 'Reporting - General - Time Off Balances - Save Filter',
    ReportingGeneralTimeOffRequestsSaveFilter = 'Reporting - General - Time Off Requests - Save Filter',
    ReportingPayrollPayrollRegisterClick = 'Reporting - Payroll - Click Payroll Register',
    ReportingPayrollPayrollRegisterSaveFilter = 'Reporting - Payroll - Payroll Register - Save Filter',
    ReportingPayrollPayrollRegisterExportExcel = 'Reporting - Payroll - Payroll Register - Export as Excel',
    ReportingPayrollPayrollRegisterExportPDF = 'Reporting - Payroll - Payroll Register - Export as PDF',
    ReportingPayrollYTDReportClick = 'Reporting - Payroll - Click Year-to-date Report',
    ReportingPayrollYTDReportSaveFilter = 'Reporting - Payroll - Year-to-date Report - Save Filter',
    ReportingPayrollYTDReportExportExcel = 'Reporting - Payroll - Year-to-date Report - Click Export Excel',
    ReportingPayrollRemittanceReportClick = 'Reporting - Payroll - Click Remittance Report',
    ReportingPayrollRemittanceReportSaveFilter = 'Reporting - Payroll - Remittance Report - Save Filter',
    ReportingPayrollRemittanceReportExportExcel = 'Reporting - Payroll - Remittance Report - Click Export Excel',
    ReportingPayrollJournalEntryReportClick = 'Reporting - Payroll - Click Journal Entry Report',
    ReportingPayrollJournalEntryReportSaveFilter = 'Reporting - Payroll - Journal Entry - Save Filter Report',
    ReportingPayrollJournalEntryReportExportExcel = 'Reporting - Payroll - Journal Entry Report - Export as Excel',
    ReportingPayrollJournalEntryReportExportPDF = 'Reporting - Payroll - Journal Entry Report - Export as PDF',
    ReportingPayrollJournalEntryReportExportCSV = 'Reporting - Payroll - Journal Entry Report - Export as CSV',
    ReportingPayrollBenefitsReportClick = 'Reporting - Payroll - Click Benefits Report',
    ReportingPayrollBenefitsReportSaveFilter = 'Reporting - Payroll - Benefits Report - Save Filter',
    ReportingPayrollBenefitsReportExportExcel = 'Reporting - Payroll - Benefits Report - Click Export Excel',
    ReportingPayrollBenefitsReportExportCSV = 'Reporting - Payroll - Benefits Report - Click Export CSV',

    // People
    PeopleCreatingNewHireAssignTimeOffPolicy = 'People - Creating New Hire - Assign Time Off Policy - Click Save',

    // Performance
    PerformanceOverviewClickCreateReview = 'Performance - Overview - Click Create Review',
    PerformanceOverviewCreateReviewClickCompleteToSave = ' Performance - Overview - Create Review - Click Complete to Save',
    PerformanceReviewClickCreateReview = 'Performance - Review - Click Create Review',
    PerformanceReviewCreateReviewClickCompleteToSave = 'Performance - Review - Create Review - Click Complete to Save',
    PerformanceAllPathsToCreateReviewSelectMakeResponsesVisibleToEmployee = 'Performance - All Paths to Create Review - Select Make Responses Visible to the Employee',
    PerformanceAllPathsToCreateReviewSelectMakeResponsesVisibleToOtherReviewParticipants = 'Performance - All Paths to Create Review - Select Make Responses Visible to Other Review Participants',
    PerformanceAllPathsToCreateReviewStep1ClickNext = 'Performance - All Paths to Create Review - Step 1 - Click Next',
    PerformanceAllPathsToCreateReviewStep2ClickPrevious = 'Performance - All Paths to Create Review - Step 2 - Click Previous',
    PerformanceAllPathsToCreateReviewStep2ClickNext = 'Performance - All Paths to Create Review - Step 2 - Click Next',
    PerformanceAllPathsToCreateReviewStep3SelectTheReviewSubjectAsAParticipant = 'Performance - All Paths to Create Review - Step 3 - Select the Review Subject as a Participant',
    PerformanceAllPathsToCreateReviewStep3SelectManagersAsParticipants = 'Performance - All Paths to Create Review - Step 3 - Select Managers as Participants',
    PerformanceAllPathsToCreateReviewStep3SelectDirectReportsAsParticipants = 'Performance - All Paths to Create Review - Step 3 - Select Direct Reports as Participants',
    PerformanceAllPathsToCreateReviewStep3SelectPeersAsParticipants = 'Performance - All Paths to Create Review - Step 3 - Select Peers as Participants',
    PerformanceAllPathsToCreateReviewStep3ClickNext = 'Performance - All Paths to Create Review - Step 3 - Click Next',
    PerformanceAllPathsToCreateReviewStep3ClickPrevious = 'Performance - All Paths to Create Review - Step 3 - Click Previous',
    PerformanceAllPathsToCreateReviewStep4ClickPrevious = 'Performance - All Paths to Create Review - Step 4 - Click Previous',
    SurveysIndividualSurveyClickViewResultsAggregatedDataClickPrint = 'Surveys - Individual Survey - Click View Results - Aggregated Data - Click Print',
    IndividualSurveyClickViewResultsRawDataExportAsCSV = 'Individual Survey - Click View Results - Raw Data - Export as CSV',
    SurveysIndividualSurveyClickViewResultsRawDataExportAsExcel = 'Surveys - Individual Survey - Click View Results - Raw Data - Export as Excel',

    // Performance - Goals
    PerformanceOverviewClickCreateGoal = 'Performance - Overview - Click Create Goal',
    PerformanceOverviewCreateGoalClickCompleteToSave = 'Performance - Overview - Create Goal - Click Complete to Save',
    PerformanceGoalsClickCreateGoal = 'Performance - Goals - Click Create Goal',
    PerformanceGoalsCreateGoalClickCompleteToSave = 'Performance - Goals - Create Goal - Click Complete to Save',
    PerformanceAllPathstoCreateGoalTypeSelectSharedGoal = 'Performance - All Paths to Create Goal - Type - Select Shared Goal',
    PerformanceAllPathsToCreateGoalTypeSelectPerEmployee = 'Performance - All Paths to Create Goal - Type - Select Per Employee',
    PerformanceAllPathsToCreateGoalStep1ClickNext = 'Performance - All Paths to Create Goal - Step 1 - Click Next',
    PerformanceAllPathsToCreateGoalStep2ClickNext = 'Performance - All Paths to Create Goal - Step 2 - Click Next',
    PerformanceAllPathsToCreateGoalStep2ClickPrevious = 'Performance - All Paths to Create Goal - Step 2 - Click Previous',
    PerformanceAllPathsToCreateGoalStep3SelectYourselfAsParticipant = 'Performance - All Paths to Create Goal - Step 3 - Select Yourself as a Participant',
    PerformanceAllPathsToCreateGoalStep3SelectDirectReportsAsParticipants = 'Performance - All Paths to Create Goal - Step 3 - Select Direct Reports as Participants',
    PerformanceAllPathsToCreateGoalStep3SelectPeersAsParticipants = 'Performance - All Paths to Create Goal - Step 3 - Select Peers as Participants',
    PerformanceAllPathsToCreateGoalStep3ClickNext = 'Performance - All Paths to Create Goal - Step 3 - Click Next',
    PerformanceAllPathsToCreateGoalStep3ClickPrevious = 'Performance - All Paths to Create Goal - Step 3 - Click Previous',
    PerformanceAllPathsToCreateGoalStep4ClickPrevious = 'Performance - All Paths to Create Goal - Step 4 - Click Previous',

    // Performance - Feedback
    PerformanceFeedbackClickCreateFeedback = 'Performance - Feedback - Click Create Feedback',
    PerformanceCreatingFeedbackAllSave = 'Performance - Creating Feedback (All) - Save',

    // Performance - Templates
    PerformanceCreateTemplateSave = 'Performance - Templates - Create Template - Save',

    // Notification
    NotificationsClicked = 'Dashboard - Notification - Clicked',
    NotificationsMarkAsRead = 'Dashboard - Notification - Mark As Read',
    NotificationsMarkAsUnread = 'Dashboard - Notification - Mark As Unread',
    NotificationsMarkAllRead = 'Dashboard - Notification - Mark All Read',
    NotificationsMarkAsArchived = 'Dashboard - Notification - Archive',
    NotificationsMarkAsUnarchived = 'Archived Notifications - Send to Inbox',
    NotificationsGoToArchived = 'Dashboard - Notification - View Archived',
    NotificationsArchivedClicked = 'Archived Notifications - Clicked',
    NotificationsMarkArchivedAsRead = 'Archived Notifications - Mark As Read',
    NotificationsMarkArchivedAsUnread = 'Archived Notifications - Mark As Unread',
    NotificationsMarkAllArchived = 'Dashboard - Notification - Archive All',
    NotificationsMarkAllArchivedRead = 'Archived Notifications - Mark All Read',
    NotificationsMarkAllUnarchived = 'Archived Notifications - Move All to Inbox',
    NotificationsModalArchived = 'Dashboard - Notification - Modal - Archive',
    NotificationsModalClose = 'Dashboard - Notification - Modal - Close',

    // Offer Letter
    OfferLetterCreateOfferLetterTemplate = 'Documents - Create Offer Letter Template',
    OfferLetterSendOfferLetterViaNewEmployeeFlow = 'Offer Letters - Send Offer Letter Via New Hire Flow',
    OfferLetterSendOfferLetterViaTemplateView = 'Offer Letters - Send Offer Letter Via Template View',
    OfferLetterSendOfferLetterViaOffersSection = 'Offer Letters - Send Offer Letter Via Offers Section',
    OfferLetterHireCandidate = 'Offer Letters - Hire Candidate',
    OfferLetterAddCandidateNote = 'Offer Letters - Add Candidate Note',
    OfferLetterEditCandidateNote = 'Offer Letters - Edit Candidate Note',
    OfferLetterWithdrawOfferLetter = 'Offer Letters - Withdraw Offer Letter',
    OfferLetterDeclinedOfferLetter = 'Offer Letters - Declined Offer Letter',
    OfferLetterReviseOfferLetter = 'Offer Letters - Revise Offer Letter',
    OfferLetterCandidateReviewOfferLetter = 'Offer Letters - Candidate Review Offer Letter',
    OfferLetterCandidateSignedOfferLetter = 'Offer Letters - Candidate Signed Offer Letter',

    // Offer Letter Previews
    OfferLetterTemplatePreviewed = 'Offer Letters - Preview',
    OfferLetterTemplatePreviewSend = 'Offer Letters - Preview - Send',

    // Settings - Roles
    RoleUpdated = 'Settings - Role Updated',
    RoleAssignmentControlChanged = 'Settings - Role Assignment Control Changed',

    // Genders & Pronouns
    GendersAddedDuringOnboardingFlow = 'Genders Added During Onboarding',
    PronounsAddedDuringOnboardingFlow = 'Pronouns Added During Onboarding',

    EmployeeProfileEditPronouns = 'Employee Profile - Personal - Edit Pronoun - Save',
    SelfProfileEditPronouns = 'Self Profile - Personal - Edit Pronoun - Save',
    EmployeeProfileEditGender = 'Employee Profile - Personal - Edit Gender - Save',
    SelfProfileEditGender = 'Self Profile - Personal - Edit Gender - Save',

    // Indeed API Integration
    LinkingAnExistingCompany = 'Settings - Integrations - Indeed - Connect - Select Employer Account - Click Next',
    CreateANewCompany = 'Settings - Integrations - Indeed - Connect - Create Employer Account - Click Next',
    CompleteSponsor = 'Recruiting - Job Posting - Information - Indeed Integration - Sponsor Job - Click Confirm to Save',
    NoPaymentMethodOnAccount = 'Settings - Integrations - Indeed - Connect - Select Employer Account - Error Posting Jobs - Click Okay to Save',
    BulkSyncError = 'Settings - Integrations - Indeed - Connect - Select Employer Account - Error Posting Jobs - Click Okay to Save',
    PostingJobToIndeedErrorDuringCreationOfJobPostingCancel = 'Recruiting - Create Job Posting - Indeed Integration On - Fail to Post - Click Cancel',
    PostingJobToIndeedErrorDuringCreationOfJobPostingConfirm = 'Recruiting - Create Job Posting - Indeed Integration On - Fail to Post - Click Confirm to Save',
    PostingJobToIndeedErrorDuringIntegrationToggle = 'Recruiting - Job Posting - Information - Turn On Indeed Integration - Fail to Post - Click Okay to Save',
    EditPostingInformationErrorConfirm = 'Recruiting - Job Posting - Information - Edit - Fail to Update on Indeed - Click Confirm to Save',
    EditPostingInformationErrorCancel = 'Recruiting - Job Posting - Information - Edit - Fail to Update on Indeed - Click Cancel',

    // Signing Letter Documents
    SigningTemplateCreatedWithNoCosigners = 'Documents - Create Signing Template - No Cosigners - Click Create to Save',
    SigningTemplateCreatedWithCosigners = 'Documents - Create Signing Template - With Cosigners - Click Create to Save',

    BulkUploaderAddViewOnlyDocumentError = 'Documents - Create View Only - Add Document - Error',
    BulkUploaderViewOnlyDocumentReset = 'Documents - Create View Only - Add Document - Click Upload More Files',
    BulkUploaderViewOnlyDocumentSaved = 'Documents - Create View Only - Click Upload to Save',
    BulkUploaderViewOnlyDocumentRemoved = 'Documents - Create View Only - Add Document - File Removed',

    BulkUploaderEmployeeProfileDocumentError = 'Employee Profile - Documents  - Add Document - Error',
    BulkUploaderEmployeeProfileDocumentReset = 'Employee Profile - Documents  - Add Document - Click Upload More Files',
    BulkUploaderEmployeeProfileDocumentSaved = 'Employee Profile - Documents  - Add Document - Click Upload to Save',
    BulkUploaderEmployeeProfileDocumentRemoved = 'Employee Profile - Documents  - Add Document - File removed',

    BulkUploaderSelfProfileDocumentError = 'Self Profile - Documents  - Add Document - Error',
    BulkUploaderSelfProfileDocumentReset = 'Self Profile - Documents  - Add Document - Click Upload More Files',
    BulkUploaderSelfProfileDocumentSaved = 'Self Profile - Documents  - Add Document - Click Upload to Save',
    BulkUploaderSelfProfileDocumentRemoved = 'Self Profile - Documents  - Add Document - File removed',

    // Document Assignments
    BulkAssignFromDocPreview = 'Documents - Select Doc - Doc Preview - Assign - Click Assign to Save',
    BulkAssignFromEmployeesAssigned = 'Documents - Select Doc - Employees Assigned - Assign - Click Assign to Save',
    MultiAssignFromDocPreview = 'Documents - Select Multi-Sign Doc - Doc Preview - Assign - Save',
    MultiAssignFromEmployeesAssigned = 'Documents - Select Multi-Sign Doc - Employees Assigned - Assign - Save',

    // Self Employee Document Uploading
    SelfProfileDocumentsDownloaded = 'Self Profile - Documents - My Docs - Click Download',
    SelfProfileDocumentsMeatballDocDownloaded = 'Self Profile - Documents - My Docs - Select Doc - Meatball - Download',
    SelfProfileDocumentsMeatballOpenedDetails = 'Self Profile - Documents - My Docs - Select Doc - Meatball - Details',
    SelfProfileDocumentsMeatballEdited = 'Self Profile - Documents - My Docs - Select Doc - Meatball - Edit - Save',
    SelfProfileDocumentsMeatballDeleted = 'Self Profile - Documents - My Docs - Select Doc - Meatball - Delete - Click Delete to Save',
    SelfProfilePublicDocumentsDownloaded = 'Self Profile - Documents - Public Docs - Select Doc - Meatball - Download',
    SelfProfilePublicDocumentsOpenedDetails = 'Self Profile - Documents - Public Docs - Select Doc - Meatball - Document Details',

    // Employee Profile Documents
    EmployeeProfileDocumentRemind = 'Employee Profile - Documents - Meatball - Remind',
    EmployeeProfileDocumentDeleted = 'Employee Profile - Documents - Meatball - Delete',
    EmployeeProfileDocumentDownloaded = 'Employee Profile - Documents - Meatball - Download',
    EmployeeProfileDocumentShowDownloaded = 'Employee Profile - Documents - Select a Doc - Meatball - Download',
    EmployeeProfileDocumentShowOpenedDetails = 'Employee Profile - Documents - Select a Doc - Meatball - Details',
    EmployeeProfileDocumentShowEdited = 'Employee Profile - Documents - Select a Doc - Meatball - Edit - Save',
    EmployeeProfileDocumentShowDeleted = 'Employee Profile - Documents - Select a Doc - Meatball - Delete - Click Delete to Save',

    // Org Chart
    OrgChartNavigateToEmployeeProfile = 'People - Org Chart - Click Employee to Reach Profile',
    OrgChartEnterListView = 'People - Org Chart - Click List View Icon',
    OrgChartEnterPyramidView = 'People - Org Chart - Click Pyramid View Icon',
    OrgChartDownloadImage = 'People - Org Chart - Click Download',
    OrgChartAppliedFilter = 'People - Org Chart - Use Filter',

    CreateTerminatedEmployee = 'People - Directory - Create Employee - Terminated - Click Complete to Save',

    // New Hire
    CreateEmployeeClickHireEmployee = 'People - Directory - Create Employee - Click Hire Employee',
    CreateEmployeeAddDocuments = 'People - Directory - Create Employee - Hire - Add Document - Click Select to Save',
    CreateEmployeeDocumentFiltersAdded = 'People - Directory - Create Employee - Hire - Add Documents - Select Tag Filters',
    CreateEmployeeAddCustomFields = 'People - Directory - Create Employee - Hire - Add Custom Fields - Click Select to Save',
    CreateEmployeeCustomFieldFiltersAdded = 'People - Directory - Create Employee - Hire - Add Custom Fields - Select Group Filters',
    CreateEmployeeAddReminders = 'People - Directory - Create Employee - Hire - Add Reminders - Click Select to Save',
    CreateEmployeeAddRolesAndPermissions = 'People - Directory - Create Employee - Hire - Add Roles & Permissions - Click Select to Save',
    CreateEmployeeAddTrainingPrograms = 'People - Directory - Create Employee - Hire - Add Training Programs - Click Select to Save',
    CreateEmployeeAddBenefitPlan = 'People - Directory - Create Employee - Hire - Add Benefit Plan - Click Select to Save',
    CreateEmployeeClickComplete = 'People - Directory - Create Employee - Hire - Click Complete',

    // Surveys
    SurveysCreateTemplateSave = 'Surveys - Templates - Create Template - Save',
    SurveysDeleteTemplateConfirm = 'Surveys - Templates - Individual Template - Meatball - Delete - Click Confirm to Save',
    SurveysEditTemplateAutoSave = 'Surveys - Templates - Individual Template - Edit - Autosave',
    SurveysCreateSurveySave = 'Surveys - Create Survey - Click Complete to Save',
    SurveysCreateSurveySelectOrRemoveParticipants = 'Surveys - Create Survey - Select or Remove Participants',
    SurveysEditSurveySave = 'Surveys - Individual Survey - Meatball - Edit - Save',
    SurveysDeleteSurveyConfirm = 'Surveys - Individual Survey - Meatball - Delete - Click Delete Survey to Save',
    SurveysCompleteSurveySubmit = 'Surveys - Individual Survey - Click Submit Survey to Save',
    SurveysAssignParticipants = 'Surveys - Individual Survey - Edit Participants - Click Assign to Save',
    SurveysRemindParticipants = 'Surveys - Individual Survey - Meatball - Click Remind Participants to Save',
    SurveysForceComplete = 'Surveys - Individual Survey - Meatball - Click Force Complete to Save',
    SurveysViewResults = 'Surveys - Individual Survey - Click View Results',
    SurveysExportResults = 'Surveys - Individual Survey - Meatball - Click Export',

    // Dashboard
    DashboardCardForPeopleReportDismissCard = 'Dashboard - Card for People Report - Dismiss Card',
    DashboardCardForPeopleReportClickReadMore = 'Dashboard - Card for People Report - Click Read More',

    // Add-ons
    ClickAddOnsModule = 'Click Add-ons module',
    AddOnsBenefitsLearnMore = 'Add-ons - Benefits - Learn More',
    AddOnsBenefitsBookConsult = 'Add-ons - Benefits - Book Consult ',
    AddOnsTimeOffLearnMore = 'Add-ons - Time Off - Learn More',
    AddOnsTimeOffBookDemo = 'Add-ons - Time Off - Book Demo',
    AddOnsRecruitingLearnMore = 'Add-ons - Recruiting - Learn More',
    AddOnsRecruitingBookDemo = 'Add-ons - Recruiting - Book Demo',
    AddOnsPerformanceLearnMore = 'Add-ons - Performance - Learn More',
    AddOnsPerformanceBookDemo = 'Add-ons - Performance - Book Demo',
    AddOnsPayrollLearnMore = 'Add-ons - Payroll - Learn More',
    AddOnsPayrollBookDemo = 'Add-ons - Payroll - Book Demo',
    AddOnsBusinessInsuranceLearnMore = 'Add-ons - Business Insurance - Learn More',
    AddOnsBusinessInsuranceBookConsult = 'Add-ons - Business Insurance - Book Consult',
    RecruitingLearnMore = 'Recruiting - Learn More',
    TimeOffLearnMore = 'Time Off - Learn More',
    PayrollLearnMore = 'Payroll - Learn More',
    PerformanceLearnMore = 'Performance - Learn More',
    TimeTrackingLearnMore = 'Time Tracking - Learn More',

    // Connect Employee
    ClickTimeToFillReportExport = 'Reporting - Time to Fill And Hire - Click Export',

    // Document Folders
    FiltersDocumentsAndFoldersViaTags = 'Documents - Folders View - Tags Filter',
    FiltersDocumentsAndFoldersViaSearchBar = 'Documents - Folders View - Search Event',
    SingleDropEventForDocumentFolders = 'Documents - Folders View - Drop Event - Single',
    MultiDropEventForDocumentFolders = 'Documents - Folders View - Drop Event - Multi',
    SingleDragEventForDocumentFolders = 'Documents - Folders View - Drag Event - Single',
    MultiDragEventForDocumentFolders = 'Documents - Folders View - Drag Event - Multi',

    // Name Pronunciation
    NamePronunciationBasicInformationCreated = 'Employee Profile - Basic Information - Recording Created',
    NamePronunciationOnboardingCreaeted = 'Employee Onboarding - Recording Created',
    EmployeeNamePronunciationBasicInformationDeleted = 'Employee Profile - Basic Information - Recording Deleted',
    EmployeeNamePronunciationBasicInformationPlayed = 'Employee Profile - Basic Information - Recording Played',
    EmployeeNamePronunciationSidebarPlayed = 'Employee Profile - Sidebar - Recording Played',

    // Hiring Permissions - New Job Posting
    AddAdditionalQuestionFromScratchOnNewJobPosting = 'Recruiting - Create Job Posting - Add Additional Question from Scratch - Click Add to Save',
    AddAdditionalQuestionFromPreviousOnNewJobPosting = 'Recruiting - Create Job Posting - Add Additional Question from Previous - Click Add to Save',
    EditAdditionalQuestionOnNewJobPosting = 'Recruiting - Create Job Posting - Edit Additional Question - Click Save',
    AddAutoArchiveQuestionFromScratchOnNewJobPosting = 'Recruiting - Create Job Posting - Add Auto Archiving Question from Scratch - Click Add to Save',
    AddAutoArchiveQuestionFromPreviousOnNewJobPosting = 'Recruiting - Create Job Posting - Add Auto Archiving Question from Previous - Click Add to Save',
    EditAutoArchiveQuestionOnNewJobPosting = 'Recruiting - Create Job Posting - Edit Auto Archiving Question - Click Save',

    // Hiring Permissions - Existing Job Posting
    AddAdditionalQuestionFromScratchOnExistingPosting = 'Recruiting - Job Posting - Add Additional Question from Scratch - Click Add to Save',
    AddAdditionalQuestionFromPreviousOnExistingPosting = 'Recruiting - Job Posting - Add Additional Question from Previous - Click Add to Save',
    EditAdditionalQuestionOnExistingPosting = 'Recruiting - Job Posting - Edit Additional Question - Click Save',
    AddAutoArchiveQuestionFromScratchOnExistingPosting = 'Recruiting - Job Posting - Add Auto Archiving Question from Scratch - Click Add to Save',
    AddAutoArchiveQuestionFromPreviousOnExistingPosting = 'Recruiting - Job Posting - Add Auto Archiving Question from Previous - Click Add to Save',
    EditAutoArchiveQuestionOnExistingPosting = 'Recruiting - Job Posting - Edit Auto Archiving Question - Click Save',

    // Company Logo
    SettingsCompanyLogoUpload = 'Settings - Company Logo - Upload',
    SettingsCompanyLogoPublish = 'Settings - Company Logo - Publish',
    SettingsCompanyLogoDelete = 'Settings - Company Logo - Delete',

    // Optional SIN
    EmployeeCreatedWithOptionalSIN = 'People - Directory - Create Employee - Hire - SIN Optional (HR only)',
    EmployeeCreatedWithRequiredSIN = 'People - Directory - Create Employee - Hire - SIN Required (HR only)',

    EmployeeCreatedWithNotOnPayrollAndOptionalSIN = 'People - Directory - Create Employee - Hire - SIN Optional (Payroll)',
    EmployeeCreatedWithOnPayrollAndRequiredSIN = 'People - Directory - Create Employee - Hire - SIN Required (Payroll)',

    // On Leave
    EmployeeProfileMeatballPlaceOnLeaveClickCompleteToSave = 'Employee Profile - Meatball - Place On Leave - Click Complete to Save',
    EmployeeProfileMeatballLeaveDetails = 'Employee Profile - Meatball - Leave Details',
    EmployeeProfileMeatballLeaveDetailsEdit = 'Employee Profile - Meatball - Leave Details > Edit',

    // Self Serve
    TimeOffSelfServeGetStarted = 'Time Off - Self Serve - Get Started',
    TimeOffSelfServeCloseGuide = 'Time Off - Self Serve - Close Guide',
    TimeOffSelfServeOpenGuide = 'Time Off - Self Serve - Open Guide',
    TimeOffSSWorkScheduleClickDone = 'Time Off - SS Work Schedule - Click Done',
    TimeOffSSTimeOffTypeClickDone = 'Time Off - SS Time Off Type - Click Done',
    TimeOffSSTimeOffPolicyClickDone = 'Time Off - SS Time Off Policy - Click Done',
    TimeOffSSAssignPolicyClickDone = 'Time Off - SS Assign Policy - Click Done',
    TimeOffSSImportDataClickDone = 'Time Off - SS Import Data - Click Done',
    TimeOffSelfServeClickComplete = 'Time Off - Self Serve - Click Complete',
    TimeOffSelfServeClickContactUs = 'Time Off - Self Serve - Click Contact Us',
    TimeOffSelfServeClickHelpCentre = 'Time Off - Self Serve - Click Help Centre',

    // General Quickstart
    SelfServeEditPayrollSituationClickEdit = 'Self-Serve - Edit Payroll Situation - Click Edit',
    SelfServeGetReadyToPayYourTeamClickGo = 'Self-Serve - Get Ready To Pay Your Team - Click Go',
    SelfServeBookYourPayrollCallClickGo = 'Self-Serve - Book Your Payroll Call - Click Go',
    SelfServePrepareDocumentsClickGo = 'Self-Serve - Prepare Documents - Click Go',
    SelfServeTimeOffClickSetItUp = 'Self-Serve - Time Off - Click Set It Up',
    SelfServeInboxClickInbox = 'Self-Serve - Inbox - Click Inbox',

    // Self Serve - Get ready to pay your team
    SelfServeAddCRAClickLater = 'Self-Serve - Add CRA - Click Later',
    SelfServeAddCRAClickSave = 'Self-Serve - Add CRA - Click Save',
    SelfServeAddBankAccountClickLater = 'Self-Serve - Add Bank Account - Click Later',
    SelfServeAddBankAccountClickSave = 'Self-Serve - Add Bank Account - Click Save',
    SelfServeCompanyProfileClickSave = 'Self-Serve - Company Profile - Click Save',
    SelfServeYourProfileClickUploadAvatar = 'Self-Serve - Your Profile - Click Upload Avatar',
    SelfServeYourProfileClickLater = 'Self-Serve - Your Profile - Click Later',
    SelfServeYourProfileClickSave = 'Self-Serve - Your Profile - Click Save',
    SelfServeAddTeamClickInviteCheckbox = 'Self-Serve - Add Team - Click Invite Checkbox',
    SelfServeAddTeamClickSaveEmployee = 'Self-Serve - Add Team - Click Save Employee',
    SelfServeAddTeamClickSaveTerminated = 'Self-Serve - Add Team - Click Save Terminated',
    SelfServeAddTeamClickEditEmployee = 'Self-Serve - Add Team - Click Edit Employee',
    SelfServeAddTeamClickSendInvites = 'Self-Serve - Add Team - Click Send Invites',
    SelfServeAddTeamClickLater = 'Self-Serve - Add Team - Click Later',

    // Self Serve - HR & Timeoff steps
    SelfServeAddCompanyDetailsClickContinue = 'Self-Serve - Add Company Details - Click Continue',
    SelfServeAddTeamClickGo = 'Self-Serve - Add Team - Click Go',
    SelfServeSetUpTimeOffClickGo = 'Self-Serve - Set Up Time Off - Click Go',
    SelfServeInviteYourTeamClickGo = 'Self-Serve - Invite Your Team - Click Go',

    // Self Serve - Prepare Payroll Documents
    SelfServePayrollDocumentsClickAuthorizationTab = 'Self-Serve - Payroll Documents - Click Authorization Tab',
    SelfServePayrollDocumentsClickViewAuthorizationDocuments = 'Self-Serve - Payroll Documents - Click View Authorization Documents',
    SelfServePayrollDocumentsClickSupportingDocumentsTab = 'Self-Serve - Payroll Documents - Click Supporting Documents Tab',
    SelfServePayrollDocumentsClickViewSupportingDocuments = 'Self-Serve - Payroll Documents - Click View Supporting Documents',

    // Self Serve : HR & Timeoff
    SelfServeAddCompanyDetailsClickSave = 'Self-Serve - Add Company Details - Click Save',
    SelfServeMyProfileClickUploadAvatar = 'Self-Serve - My Profile - Click Upload Avatar',
    SelfServeMyProfileClickLater = 'Self-Serve - My Profile - Click Later',
    SelfServeMyProfileClickSave = 'Self-Serve - My Profile - Click Save',
    SelfServeAddEmployeeClickSave = 'Self-Serve - Add Employee - Click Save',
    SelfServeAddEmployeeClickEditEmployee = 'Self-Serve - Add Employee - Click Edit Employee',

    // Book Call with Humi's Payroll Experts
    SelfServeBookYourCallClickBook = 'Self-Serve - Book Your Call - Click Book',
    SelfServeBookYourCallClickChangeTimeline = 'Self-Serve - Book Your Call - Click Change Timeline',
    SelfServeBookYourCallClickChangePayment = 'Self-Serve - Book Your Call - Click Change Payment',
    SelfServeBookYourCallClickContactUs = 'Self-Serve - Book Your Call - Click Contact Us',

    // Time Off Improvements
    TimeOffAllRequests = 'Time Off - All Requests',
    TimeOffTypesPolicies = 'Time Off - Types & Policies',
    TimeOffSettings = 'Time Off - Settings',
    TimeOffTimeOffPolicyCreateTimeOffPolicy = 'Time Off - Time Off Policy - Create Time Off Policy',
    TimeOffTimeOffPolicyDeleteTimeOffType = 'Time Off - Time Off Policy - Delete Time Off Type',
    TimeOffTimeOffPolicyEditTimeOffType = 'Time Off - Time Off Policy - Edit Time Off Type',
    TimeOffTimeOffPolicyDeleteTimeOffPolicy = 'Time Off - Time Off Policy - Delete Time Off Policy',
    TimeOffTimeOffPolicyEditTimeOffPolicy = ' Time Off - Time Off Policy - Edit Time Off Policy',
    TimeOffTimeOffPolicyViewTimeOffPolicy = 'Time Off - Time Off Policy - View Time Off Policy',
    TimeOffSettingsEditOrCreateWorkSchedule = 'Time Off - Settings - Edit or Create Work Schedule',
    TimeOffCreateApprovalFlow = 'Time Off - Create Approval Flow',
    TimeOffEditApprovalFlow = 'Time Off - Edit Approval Flow',

    // Data Importers - Time Off Settings
    ReportingDataImporterTimeOffRequests = 'Reporting - Data Importer - Time Off Requests',
    ReportingDataImporterTimeOffBalances = 'Reporting - Data Importer - Time Off Balances',
    ReportingDataImporterTimeOffAdjustments = 'Reporting - Data Importer - Time Off Adjustments',

    TimeTrackingGeneralSettingsEnableFutureTimeTracking = 'Time Tracking - General Settings - Enable Future Time Tracking',
    TimeTrackingGeneralSettingsDisableFutureTimeTracking = 'Time Tracking - General Settings - Disable Future Time Tracking',

    //Inbox View
    InboxAllClicked = 'Inbox - All - Clicked',
    InboxUnreadOnlyClicked = 'Inbox - Unread Only - Clicked',
    InboxFilterByDateRangeAll = 'Inbox - Filter - By Date Range - All',
    InboxFilterByDateRangeLast7 = 'Inbox - Filter - By Date Range - Last 7',
    InboxFilterByDateRangeLast30 = 'Inbox - Filter - By Date Range - Last 30',
    InboxFilterByCategoryAll = 'Inbox - Filter - By Category - All',
    InboxFilterByCategoryOption = 'Filter - By Category - {{name}}',

    //Track Event type filters
    EventsFilterByAll = 'Events - Filter By - All',
    EventsFilterByAway = 'Events - Filter By - Away',
    EventsFilterByBirthday = 'Events - Filter By - Birthday',
    EventsFilterByAnniversary = 'Events - Filter By - Anniversary',
    EventsFilterByFirstDay = 'Events - Filter By - First Day',
    EventsViewCalendar = 'Events - View Calendar',
    EventsViewMore = 'Events - View More',

    //Benefits Upsell
    SelfServeBenefitsCardClickLink = 'Self-Serve - Benefits Card - Click Link',
    SelfServeLandingPageAddPlan = 'Self-Serve - Landing Page - Add Plan',
    SelfServeLandingPageLearnMore = 'Self-Serve - Landing Page - Learn More',
}
