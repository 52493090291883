import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import Quill from 'quill';
import Delta from 'quill-delta';
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'ui-rich-text',
    templateUrl: './rich-text.template.html',
    styleUrls: ['./rich-text.styles.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: RichTextComponent,
        },
    ],
    host: {
        '[id]': 'id',
        '[attr.aria-describedby]': 'describedBy',
    },
})
export class RichTextComponent implements OnInit, OnChanges, ControlValueAccessor, MatFormFieldControl<string> {
    @Output() changed: EventEmitter<any> = new EventEmitter();
    @ViewChild('container', { read: ElementRef }) container: ElementRef;
    static nextId = 0;
    stateChanges = new Subject<void>();
    editor: Quill;
    focused = false;
    touched = false;
    controlType = 'rich-text';
    onChange = (_: any) => {};
    onTouched = () => {
        this.touched = true;
    };
    id = `rich-text-input-${RichTextComponent.nextId++}`;
    describedBy = '';

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input()
    readOnly = false;

    @Input() displayToolbar = true;

    @Input() useMiniToolbar = false;

    @Input() displayColorOptions = false;

    @Input() stripNewlines = false;

    @Input() get errorState(): boolean {
        return this._defaultErrorStateMatcher.isErrorState(this.ngControl.control as FormControl, this._parentForm);
    }

    @Input()
    get required() {
        return this._required;
    }

    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    @Input()
    get placeholder() {
        return this._placeholder;
    }

    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        value ? this.editor.disable() : this.editor.enable();
        this.stateChanges.next();
    }

    @Input()
    get height(): string {
        return this._height;
    }
    set height(value: string) {
        this._height = value;
        this.stateChanges.next();
    }

    get value(): any {
        return this._value;
    }
    set value(value: any) {
        this._value = value;
        this.editor.setContents(this._value);
        this.onChange(value);
        this.stateChanges.next();
    }

    get empty(): boolean {
        return this.editor?.getText().trim() ? false : true;
    }

    private _value: any;
    private _placeholder: string;
    private _required = false;
    private _disabled = false;
    private _height = '200px';
    private _subscriptions: Subscription[] = [];

    defaultToolbarElements = [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        ['link'],
    ];

    miniToolbarElements = [['bold', 'italic'], ['link']];

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() public _parentForm: NgForm,
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        private fm: FocusMonitor,
        private elementRef: ElementRef
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }

        // For MatFormField focus behaviour
        const fmMonitorSub = this.fm.monitor(elementRef.nativeElement, true).subscribe((origin) => {
            this.focused = !!origin;
            this.stateChanges.next();
        });

        // Need to do this to show mat-errors when form is submitted.
        const formSubscription = this._parentForm.ngSubmit.subscribe(() => {
            this.stateChanges.next();
        });

        this._subscriptions = [fmMonitorSub, formSubscription];
    }

    ngOnInit(): void {
        const editor = this.elementRef.nativeElement.querySelector('.editor');

        // This will make Quill use <div> instead of <p> tag for text.
        // The reason why we are adding this is because Quill formatting is not showing properly for gmail.
        if (this.stripNewlines) {
            const block = Quill.import('blots/block');
            block.tagName = 'div';
            Quill.register(block, true);
        }

        if (this.useMiniToolbar) {
            this.defaultToolbarElements = this.miniToolbarElements;
        }
        const toolbar: Array<Array<string | Record<string, primitive | primitive[]>>> = this.defaultToolbarElements;
        if (this.displayColorOptions) {
            toolbar.push([{ color: [] }]);
        }

        this.editor = new Quill(editor, {
            modules: {
                toolbar: this.displayToolbar ? toolbar : false,
            },
            theme: 'snow',
            placeholder: this.placeholder ? this.placeholder : null,
            formats: [
                'background',
                'bold',
                'color',
                'font',
                'code',
                'italic',
                'link',
                'size',
                'strike',
                'script',
                'underline',
                'blockquote',
                'header',
                'indent',
                'list',
                'align',
                'direction',
                'code-block',
                'formula',
                // 'image'
                // 'video'
            ],
        });

        this.editor.on('editor-change', () => {
            this.onChange(this.getValue());
        });
    }

    writeValue(contents: string): void {
        if (contents) {
            this.editor.root.innerHTML = contents;
        }

        this._value = contents;
    }

    registerOnChange(fn: (v: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() != 'div') {
            this.elementRef.nativeElement.querySelector('div').focus();
        }
    }

    ngOnChanges(): void {
        if (this.editor) {
            this.editor.setContents(this.value);
        }
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this._subscriptions.forEach((s) => s.unsubscribe());
    }

    private getValue(): string | void {
        if (!this.editor) {
            return;
        }

        const delta = this.editor.getContents();
        if (this.isEmpty(delta)) {
            return;
        }

        return this.editor.root.innerHTML;
    }

    private isEmpty(contents: Delta): boolean {
        if (contents.ops?.length > 1) {
            return false;
        }

        const opsTypes: string[] = Object.keys(contents.ops[0]);

        if (opsTypes.length > 1) {
            return false;
        }

        if (opsTypes[0] !== 'insert') {
            return false;
        }

        if (contents.ops[0].insert !== '\n') {
            return false;
        }

        return true;
    }
}
