import { KeyValue } from '@angular/common';
import moment from 'moment';

export const DAILY = 'daily';
export const WEEKLY = 'weekly';
export const BIWEEKLY = 'bi-weekly';
export const SEMIMONTHLY = 'semi-monthly';
export const MONTHLY = 'monthly';
export const QUARTERLY = 'quarterly';
export const SEMIANNUALLY = 'semi-annually';
export const ANNUALLY = 'annually';

export const AccrualFrequencies: { [frequency: string]: number } = {
    daily: 365,
    weekly: 52,
    'bi-weekly': 26,
    'semi-monthly': 24,
    monthly: 12,
    quarterly: 4,
    // 'semi-annually': 2, // Not supporting this at this time
    annually: 1,
};

// Function to sort keys according to the specified order
export function CompareAccrualFrequencies(a: KeyValue<string, number>, b: KeyValue<string, number>): number {
    const order = [DAILY, WEEKLY, BIWEEKLY, SEMIMONTHLY, MONTHLY, QUARTERLY, ANNUALLY];
    return order.indexOf(a.key) - order.indexOf(b.key);
}

export const SemiMonthlyIntervals: { label: string; firstDay: number; secondDay: number }[] = [
    { label: '1st and 15th', firstDay: 1, secondDay: 15 },
    { label: '1st and 16th', firstDay: 1, secondDay: 16 },
    { label: '2nd and 16th', firstDay: 2, secondDay: 16 },
    { label: '3rd and 17th', firstDay: 3, secondDay: 17 },
    { label: '4th and 18th', firstDay: 4, secondDay: 18 },
    { label: '5th and 19th', firstDay: 5, secondDay: 19 },
    { label: '6th and 20th', firstDay: 6, secondDay: 20 },
    { label: '7th and 21st', firstDay: 7, secondDay: 21 },
    { label: '8th and 22nd', firstDay: 8, secondDay: 22 },
    { label: '9th and 23rd', firstDay: 9, secondDay: 23 },
    { label: '10th and 24th', firstDay: 10, secondDay: 24 },
    { label: '11th and 25th', firstDay: 11, secondDay: 25 },
    { label: '12th and 26th', firstDay: 12, secondDay: 26 },
    { label: '13th and 27th', firstDay: 13, secondDay: 27 },
    { label: '14th and 28th', firstDay: 14, secondDay: 28 },
    { label: '15th and Last', firstDay: 15, secondDay: -1 },
];

export const MonthlyIntervals: {
    label: string;
    day: number;
}[] = [
    { label: '1st of month', day: 1 },
    { label: '2nd of month', day: 2 },
    { label: '3rd of month', day: 3 },
    { label: '4th of month', day: 4 },
    { label: '5th of month', day: 5 },
    { label: '6th of month', day: 6 },
    { label: '7th of month', day: 7 },
    { label: '8th of month', day: 8 },
    { label: '9th of month', day: 9 },
    { label: '10th of month', day: 10 },
    { label: '11th of month', day: 11 },
    { label: '12th of month', day: 12 },
    { label: '13th of month', day: 13 },
    { label: '14th of month', day: 14 },
    { label: '15th of month', day: 15 },
    { label: '16th of month', day: 16 },
    { label: '17th of month', day: 17 },
    { label: '18th of month', day: 18 },
    { label: '19th of month', day: 19 },
    { label: '20th of month', day: 20 },
    { label: '21st of month', day: 21 },
    { label: '22nd of month', day: 22 },
    { label: '23rd of month', day: 23 },
    { label: '24th of month', day: 24 },
    { label: '25th of month', day: 25 },
    { label: '26th of month', day: 26 },
    { label: '27th of month', day: 27 },
    { label: '28th of month', day: 28 },
    { label: 'Last of month', day: -1 },
];

export const QuarterlyPeriods: {
    label: string;
    firstQuarter: number;
    secondQuarter: number;
    thirdQuarter: number;
    fourthQuarter: number;
}[] = [
    { label: 'Jan, Apr, Jul, Oct', firstQuarter: 0, secondQuarter: 3, thirdQuarter: 6, fourthQuarter: 9 },
    { label: 'Feb, May, Aug, Nov', firstQuarter: 1, secondQuarter: 4, thirdQuarter: 7, fourthQuarter: 10 },
    { label: 'Mar, Jun, Sept, Dec', firstQuarter: 2, secondQuarter: 5, thirdQuarter: 8, fourthQuarter: 11 },
];

export const MonthlyDaysForDropdown: { label: string; day: number }[] = MonthlyIntervals.map(({ label, day }) => {
    return { label: label.replace(/ of month/, ''), day };
});

export const MonthsForDropdown: { label: string; value: number }[] = moment.months().map((month, index) => {
    return { label: month, value: index };
});

export const TimeOffDateFormatSlashes = 'DD/MM/YYYY';
export const TimeOffDateFormatDashes = 'YYYY-MM-DD';
export const TimeOffDateFormatMonthDayYear = 'MMMM D, YYYY';
export const TimeOffDateFormatMonthDay = 'MMMM D';
export const TimeOffDateFormatMonthDayComma = 'MMM, D';
export const TimeOffDateFormatDayMonthDashes = 'MM-DD';

// These formats are for the angular date pipe
export const TimeOffDateMonthDayYearDatePipeFormat = 'MMMM d, y';
export const TimeOffDateMonthDayDateShortCommaPipeFormat = 'MMM, d';
export const TimeOffDateMonthDayDatePipeFormat = 'MMMM d';
export const TimeOffDateMonthDayDateShortPipeFormat = 'MMM d';

export const ListOfAccrualPreviewParams: string[] = [
    'isUnlimited',
    'accrualTiming',
    'accrualFrequency',
    'dayOfMonthOne',
    'dayOfMonthTwo',
    'startOfWeek',
    'weeklyFrequenciesAnchor',
    'dayOfYearOne',
    'dayOfYearTwo',
    'dayOfYearThree',
    'dayOfYearFour',
];

export const Days: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const END_OF_MONTH = -1;

export const LAST_DAY_OF_THE_MONTH = -1;

// We only do 28 to help us avoid cases with leap years
export const NUMBER_OF_DAYS_IN_MONTH = 28;

export const DECIMAL_PLACES = 2;

export const DECIMAL_PLACES_FOR_PIPE = '1.2-2';

export const WEEK_DAYS_SHORT_FORMAT = [
    'time-off-v3.yearCalendar.sun',
    'time-off-v3.yearCalendar.mon',
    'time-off-v3.yearCalendar.tue',
    'time-off-v3.yearCalendar.wed',
    'time-off-v3.yearCalendar.thu',
    'time-off-v3.yearCalendar.fri',
    'time-off-v3.yearCalendar.sat',
];

export type TimeUnit = 'hours' | 'days';

export const MONTH_SHORT = [
    'time-off-v3.yearCalendar.jan',
    'time-off-v3.yearCalendar.feb',
    'time-off-v3.yearCalendar.mar',
    'time-off-v3.yearCalendar.apr',
    'time-off-v3.yearCalendar.may',
    'time-off-v3.yearCalendar.jun',
    'time-off-v3.yearCalendar.jul',
    'time-off-v3.yearCalendar.aug',
    'time-off-v3.yearCalendar.sep',
    'time-off-v3.yearCalendar.oct',
    'time-off-v3.yearCalendar.nov',
    'time-off-v3.yearCalendar.dec',
];

export const TimeOffIcons = [
    'addRepeat',
    'birthdayCake',
    'airplane',
    'alarm',
    'book',
    'briefcase',
    'bug',
    'calendarDetail',
    'coin',
    'foot',
    'gavel',
    'gift',
    'group',
    'heart',
    'home',
    'profile',
];

export const TimeOffBalanceDateSetting = 'timeOffBalance.today';
