import { T4Summary } from '@app/models/payroll/t4-summary.model';
import { PillColumn } from '@app/modules/table/classes/pill-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { PillDisplay } from '@app/modules/table/interfaces/pill-display.interface';

export class NameColumn extends PillColumn<T4Summary> {
    title = 'table.employerPackage.name';
    property = 'name';
    sortField = 'name';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    getPillDisplay(t4Summary: T4Summary): PillDisplay | undefined {
        if (t4Summary.rptTcd !== 'A') {
            return {
                type: 'success',
                label: 'table.employerPackage.original',
            };
        }

        return {
            type: 'default',
            label: 'table.employerPackage.amendment',
        };
    }

    shouldShowPill(): boolean {
        return true;
    }
}
