import { CalendarEvent } from '@models/common/calendar-event.model';
import { format, isSameDay, parse } from 'date-fns';
import moment, { Moment } from 'moment';

interface TimeOffCalendarPayload {
    description?: string;
    startAt: Moment | Date;
    endAt: Moment | Date;
    entityColor: string;
    timeOffTypeName: string;
    isPublic?: boolean;
    status?: string;
    avatarId?: number;
    employeeId?: number;
    employeeFullName?: string;
    firstName?: string;
    lastName?: string;
    hasMultipleDays?: boolean;
    totalAmount?: number;
    totalAmountInHours?: number;
    hoursPerDay?: number;
    displayInHours?: boolean;
    type: string;
}

export class TimeOffRequestCalendarEvent extends CalendarEvent {
    eventType = 'request';
    private payload: TimeOffCalendarPayload;

    constructor(res: TimeOffCalendarPayload) {
        super(res);
        this.payload = res;
    }

    get isPublic(): boolean {
        return this.payload.isPublic;
    }

    get monthCalendarTooltipDescription(): string {
        return this.payload.timeOffTypeName;
    }

    get monthCalendarTitle(): string {
        return this.payload.employeeFullName;
    }

    get upcomingEventDescription(): string {
        const timeOffTypeName = this.payload.timeOffTypeName;

        const startDate = moment(this.payload.startAt);
        const endDate = moment(this.payload.endAt);
        const sameDay = isSameDay(this.startAt, this.endAt);

        if (sameDay) {
            return this.getSameDayDescription();
        }

        const multiDaysAppend = this.payload.hasMultipleDays
            ? ` from ${startDate.format('MMM DD, YYYY')} to ${endDate.format('MMM DD, YYYY')}`
            : '';

        return `${timeOffTypeName}${multiDaysAppend}`;
    }

    get upcomingEventTitle(): string {
        const lessThanADayAppend = this.payload.totalAmount < 1 ? '  for part of the day' : '';

        return `${this.payload.employeeFullName} is away${lessThanADayAppend}`;
    }

    get employeeId(): number {
        return this.payload.employeeId;
    }

    get dateDescription(): string {
        const sameDay = isSameDay(this.startAt, this.endAt);

        if (sameDay) {
            return this.getSameDayDescription();
        }

        return `From ${format(this.startAt, 'MMM DD')} to ${format(this.endAt, 'MMM DD')}`;
    }

    get isMultiDayEvent(): boolean {
        return !isSameDay(this.startAt, this.endAt);
    }

    get end(): Date {
        return parse(this.endAt);
    }

    getSameDayDescription(): string {
        const value = this.payload.displayInHours ? this.payload.totalAmountInHours : this.payload.totalAmount;
        const prefixText = this.isPublic ? `${this.payload.timeOffTypeName} for ` : 'Away for ';
        const message = prefixText + value?.toFixed(2);

        return this.payload.displayInHours
            ? message + this.getPluralOrSingularHourlyUnit()
            : message + this.getPluralOrSingularDayUnit();
    }

    getPluralOrSingularHourlyUnit(): string {
        return this.payload.totalAmountInHours ?? 0 > 1 ? ' hours' : ' hour';
    }

    getPluralOrSingularDayUnit(): string {
        return this.payload.totalAmount === 1 ? ' day' : ' days';
    }
}
