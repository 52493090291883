import { Component, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadedImage } from '@app/classes';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.template.html',
    styleUrls: ['./image-upload.style.scss'],
})
export class ImageUploadComponent {
    @Output() imageUploaded: EventEmitter<UploadedImage> = new EventEmitter<UploadedImage>();
    image: UploadedImage;
    dragOver = false;

    constructor(protected sanitizer: DomSanitizer) {}

    processFile(files: FileList): void {
        const file: File = files[0];
        const reader = new FileReader();

        reader.addEventListener('load', (event: any) => {
            const safeUrl = this.sanitizer.bypassSecurityTrustUrl(event.target.result);
            this.image = new UploadedImage(safeUrl, file);
            this.imageUploaded.emit(this.image);
        });

        reader.readAsDataURL(file);
    }
}
