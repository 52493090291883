import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
} from '@angular/core';
import {
    AnimationEvent,
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    ChartData,
    ChartOptions,
    ChartType,
    Filler,
    Legend,
    LineController,
    LineElement,
    LinearScale,
    PieController,
    PointElement,
    Tooltip,
    TooltipItem,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent implements AfterViewInit, OnChanges {
    @Input() report: any;
    @Input() chartData: ChartData;
    @Input() chartId = 'myChart';
    @Input() chartOptions: ChartOptions;
    @Input() height = 400;
    @Input() isPrinting = false;
    @Output() getImage: EventEmitter<string> = new EventEmitter<string>();

    myChart: Chart;

    constructor() {
        Chart.register(
            PieController,
            BarController,
            LineController,
            BarElement,
            ArcElement,
            PointElement,
            LineElement,
            CategoryScale,
            LinearScale,
            Legend,
            Tooltip,
            ChartDataLabels,
            Filler
        );
    }

    ngOnChanges(): void {
        if (this.myChart && this.chartData) {
            this.myChart.data.labels = this.chartData.labels;
            this.myChart.data.datasets = this.chartData.datasets;
            this.myChart.update();
        }
    }

    ngAfterViewInit(): void {
        this.newChart(this.chartData);
    }

    // Functions for number formatting
    addCommas(nStr: number | string): string {
        nStr += '';
        const x = (nStr as string).split('.');
        let x1 = x[0];
        const x2 = x.length > 1 ? '.' + x[1] : '';
        const rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        return x1 + x2;
    }

    // Required so that charts don't pile up on top of each other and behave unexpectedly
    private resetCanvas(): void {
        $(`#${this.chartId}`).remove();
        $(`#graph-container-${this.chartId}`).append(
            `<canvas id=${this.chartId} class='reportingChart' style='width:100%; height:${this.height}px;'></canvas>`
        );
    }

    private newChart(chartData: any): void {
        // Delete old canvas to make way for new data or message if data not available
        this.resetCanvas();

        this.generateChart(
            this.report.chartType,
            this.report.showLegend,
            this.report.isStacked,
            this.report.showCurrency,
            chartData
        );
    }

    // Function to generate the chart
    private generateChart(
        type: ChartType,
        showLegend: boolean,
        isStacked: boolean,
        showCurrency: boolean,
        chartData: any
    ): void {
        // Generate the chart
        const ctx = $(`#${this.chartId}`);
        let options: ChartOptions;

        if (type === 'bar' || type === 'line') {
            const showYGridLines = false;
            const showXGridLines = false;

            options = {
                elements: {
                    line: {
                        fill: true,
                    },
                },
                maintainAspectRatio: false,
                responsive: false,
                plugins: { legend: { display: showLegend }, datalabels: { display: false } },
                scales: {
                    x: {
                        grid: { display: showXGridLines },
                        stacked: isStacked,
                    },
                    y: {
                        stacked: isStacked,
                        grid: { display: showYGridLines },
                        beginAtZero: true,
                        ticks: {
                            callback: function (label: any): any {
                                if (Math.floor(label) === label) {
                                    return label;
                                }
                            },
                        },
                    },
                },
            };
            // Adding percentage signs to values if dealing with attrition rates
            if (this.report.legacySlug === 'turnover') {
                options.plugins = {
                    tooltip: {
                        callbacks: {
                            label: function (tooltipItem: TooltipItem<'bar'>): string {
                                return tooltipItem.parsed + '%';
                            },
                        },
                    },
                };
            }
        } else if (type === 'pie') {
            if (showCurrency) {
                options = {
                    maintainAspectRatio: false,
                    responsive: false,
                    plugins: {
                        legend: { display: true }, // changed from false
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem: TooltipItem<'pie'>): string =>
                                    tooltipItem.label + ': $' + tooltipItem.formattedValue,
                            },
                        },
                    },
                };
            } else {
                options = {
                    maintainAspectRatio: false,
                    responsive: false,
                    plugins: {
                        legend: { display: showLegend },
                    },
                };
            }
        }

        // Actually make the chart
        this.myChart = new Chart(ctx, {
            plugins: [ChartDataLabels],
            type: type,
            data: chartData,
            options: this.chartOptions
                ? {
                      ...this.chartOptions,
                      animation: {
                          onComplete: (animation: AnimationEvent): void => {
                              // This is for prepping the img tag to print if needed.
                              const img = document.getElementById(`img-${this.chartId}`) as HTMLImageElement;
                              const dataURL = animation.chart.toBase64Image();

                              if (img) {
                                  img.src = dataURL;
                              }

                              this.getImage.emit(dataURL);
                          },
                      },
                  }
                : options,
        });
    }
}
