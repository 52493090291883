import { AppDatePipe } from '@app/pipes';
import { ALL_PROVINCES } from '@app/types/province';
import { T4 } from '@models/payroll/t4.model';
import { TranslateService } from '@ngx-translate/core';
import { CheckListFilter } from '@table/classes/column-filters/check-list/check-list-filter.class';
import { Column } from '@table/classes/column.class';
import { PillColumn } from '@table/classes/pill-column.class';
import { CellDisplay } from '@table/enums/cell-display.enum';
import { PillDisplay } from '@table/interfaces/pill-display.interface';
import { getMonth, isFirstDayOfMonth, isLastDayOfMonth } from 'date-fns';

export class NameColumn extends Column {
    title = 'table.employeeTaxForms.name';
    type = CellDisplay.avatar;
    property = 'employee';
    sortField = 'lastName';
    sortable = true;
}

// This only shows `active`, `onboarding` or `terminated` for now. If we
// want to add `on leave`, we would need to add `withOnLeave` to the
// employees query in the T4Controller in the backend.
export class EmployeeStatusColumn extends Column {
    title = 'table.employeeTaxForms.employeeStatus';
    type = CellDisplay.string;
    property = 'employee.status';
    sortable = true;
    sortField = 'terminated';
    filters = [
        new CheckListFilter('terminated', [
            { label: 'active', value: 0 },
            { label: 'terminated', value: 1 },
        ]),
    ];
}

// This is hardcoded right now, but will need to be updated when we add RL-1s
export class FormTypeColumn extends Column {
    title = 'table.employeeTaxForms.formType';
    type = CellDisplay.string;
    sortable = true;
    filters = [
        // This also does nothing right now
        new CheckListFilter('formType', [{ label: 'T4', value: 't4' }]),
    ];

    getDisplayPropertyValue(_t4: T4): string {
        return 'T4';
    }
}

export class ApplicableDateRangeColumn extends Column {
    title = 'table.employeeTaxForms.applicableDateRange';
    type = CellDisplay.string;
    sortField = 'startDate';
    sortable = true;

    constructor(private datePipe: AppDatePipe) {
        super();
    }

    getDisplayPropertyValue(t4: T4): string {
        if (t4.startDate === undefined || t4.endDate === undefined) {
            return t4.year.toString();
        }

        if (
            getMonth(t4.startDate) === 0 &&
            isFirstDayOfMonth(t4.startDate) &&
            getMonth(t4.endDate) === 11 &&
            isLastDayOfMonth(t4.endDate)
        ) {
            return t4.year.toString();
        }

        return `${this.datePipe.transform(t4.startDate)} - ${this.datePipe.transform(t4.endDate)}`;
    }
}

export class ProvinceOfEmploymentColumn extends Column {
    title = 'table.employeeTaxForms.provinceOfEmployment';
    type = CellDisplay.string;
    property = 'emptProvCd';
    sortable = true;

    filters = [
        new CheckListFilter(
            'emptProvCd',
            ALL_PROVINCES.map((prov) => ({ label: `provinces.${prov}`, value: prov }))
        ),
    ];

    constructor(private translateService: TranslateService) {
        super();
    }

    getDisplayPropertyValue(t4: T4): string {
        return this.translateService.instant(`provinces.${t4.emptProvCd}`);
    }
}

export class FormStatusColumn extends PillColumn<T4> {
    title = 'table.employeeTaxForms.formStatus';
    property = 'state';
    sortable = true;
    type = CellDisplay.pill;

    filters = [
        new CheckListFilter('state', [
            { label: 'table.employeeTaxForms.open', value: 'open' },
            { label: 'table.employeeTaxForms.error', value: 'error' },
            { label: 'table.employeeTaxForms.edited', value: 'edited' },
            // These next two are not actual T4 states, they're T619 states that are denormalized
            // onto the T4 at runtime. For actual query purposes, we need to use the
            // `sent` state (which never actually appears on the frontend)
            { label: 'table.employeeTaxForms.processing', value: 'sent' },
            { label: 'table.employeeTaxForms.complete', value: 'sent' },
        ]),
    ];

    getPillDisplay(t4: T4): PillDisplay | undefined {
        return t4.statePill();
    }
}
