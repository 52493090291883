import { Directive, ElementRef } from '@angular/core';
import { Colors } from '@app/constants/platform-colors';
import { css } from 'emotion';

const linkStyle = css`
    font-weight: 500;
    color: ${Colors.primary};
    transition: 0.2s;
    border-bottom: 1px solid transparent;

    &:hover,
    &:focus {
        color: ${Colors.primary};
        border-color: ${Colors.primary};
    }

    &:active {
        color: ${Colors.primaryDark};
        border-color: ${Colors.primaryDark};
    }
`;

@Directive({
    selector: '[uiLink]',
})
export class LinkDirective {
    constructor(el: ElementRef<HTMLAnchorElement>) {
        el.nativeElement.classList.add(linkStyle);
    }
}
