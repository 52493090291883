<div class="selector">
    <a class="logo" routerLink="/" attr.aria-label="{{ 'sidebar.home' | translate }}">
        <img [attr.src]="company?.brandLogoFileId ? (getBrandLogoUrl() | secure) : logoPath" alt="" />
    </a>

    <div *ngIf="!hasMultipleCompanies" class="company-name" [ngClass]="getCompanyNameVisibility()">
        <h4>{{ company?.name }}</h4>
    </div>

    <div *ngIf="hasMultipleCompanies" class="company-name" [ngClass]="getCompanyNameVisibility()">
        <button class="only-small" mat-button (click)="onToggleMobileCompanyDrawer()">
            <div>{{ currentTokenRole.companyName }}</div>
            <mat-icon svgIcon="chevronDown"></mat-icon>
        </button>
        <button class="hide-small" mat-button [matMenuTriggerFor]="companiesMenu">
            <div>{{ currentTokenRole.companyName }}</div>
            <mat-icon svgIcon="chevronDown"></mat-icon>
        </button>

        <mat-menu xPosition="before" #companiesMenu>
            <button
                mat-menu-item
                *ngFor="let tokenRole of uniqueCompanyTokenRoles"
                (click)="changeRole(tokenRole.roleId)"
                [disabled]="currentTokenRole.companyId === tokenRole.companyId"
            >
                {{ tokenRole.companyName }}
            </button>
        </mat-menu>
    </div>
</div>
