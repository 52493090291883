<div class="flex-container content">
    <div *ngFor="let entity of entities">
        <mat-checkbox
            class="column"
            [checked]="isChecked(entity)"
            [disabled]="isDisabled(entity)"
            (change)="onToggle(entity, $event)"
        >
            <span class="text-color">{{ entity[labelProperty] }}</span>
        </mat-checkbox>
    </div>
</div>
