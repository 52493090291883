import { Location } from '@angular/common';
import { AfterContentInit, Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { MenuItem, PillOptions } from '@app/interfaces';
import { MenuService } from '@app/services/menu/menu.service';
import { Translatable } from '@app/types/translatable.type';
import { css } from 'emotion';

type ContextMenuType = 'semantic' | 'material';

const bottomMarginOnHost = css`
    margin-bottom: 16px;
`;

@Component({
    selector: 'app-header',
    templateUrl: './header.template.html',
    styleUrls: ['./header.style.scss'],
})
export class HeaderComponent implements AfterContentInit, OnInit {
    @Input() menuService: MenuService;
    @Input() menu: MenuItem[];
    @Input() title: Translatable = 'components.header.title';
    @Input() noTitle = false;
    @Input() subtitle: Translatable;
    @Input() back = false;
    @Input() backRoute: string | string[];
    @Input() contextMenu = false;
    @Input() contextMenuType: ContextMenuType = 'semantic';
    @Input() pillOptions?: PillOptions;
    @Input() hideOverflow = false;
    @Input() onBackClick?: () => void;
    @Input() withMargin = true; // !DEPRECATED: set to false where possible. Spacing and layout of the header should be handled by the parent

    // makes our title attribute not show up in the tooltip
    // https://stackoverflow.com/a/74510560
    @HostBinding('attr.title') get getTitle(): null {
        return null;
    }

    date: Date = new Date();

    constructor(
        private element: ElementRef<HTMLElement>,
        private location: Location
    ) {}

    ngOnInit(): void {
        if (this.withMargin) {
            // Adds a bottom margin to the header element if spacing is not provided by the parent.
            // This shouldn't be used, but is necessary on pages not yet utilizing the .page-container class
            this.element.nativeElement.classList.add(bottomMarginOnHost);
        }
    }

    onBack(): void {
        // Allows for custom back behaviour that might not necessarily be a router event (ie. changing steps in a steps component)
        if (this.onBackClick) {
            this.onBackClick();
            return;
        }
        this.location.back();
    }

    ngAfterContentInit(): void {
        setTimeout(() => {
            $(this.element.nativeElement).find('.ui.dropdown').dropdown();
        });
    }
}
