import { Component, Input } from '@angular/core';

/**
 * Tip is a non-interactive component used to provide additional information to the user.
 *
 * It looks like this:
 *
 *   💡️ Tip: You can select more than one topping.
 *
 * It's gorgeous.
 */
@Component({
    selector: 'app-tip',
    templateUrl: './tip.template.html',
    styleUrls: ['./tip.style.scss'],
})
export class Tip {
    @Input() isWarning = false;
    @Input() icon: 'info' | 'bulb' = 'bulb';
}
