import { T4Summary } from '@app/models/payroll/t4-summary.model';
import { Column } from '@app/modules/table/classes/column.class';
import { PillColumn } from '@app/modules/table/classes/pill-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { PillDisplay } from '@app/modules/table/interfaces/pill-display.interface';
import { TranslateService } from '@ngx-translate/core';

export class TitleColumn extends Column {
    type = CellDisplay.string;
    title = 'table.taxReturns.taxReturn';

    constructor(
        private year: number,
        private translateService: TranslateService
    ) {
        super();
    }

    getDisplayPropertyValue(object: T4Summary): string {
        return this.translateService.instant(
            object.isAmendment() ? 'payroll.taxReturns.annualReturnAmended' : 'payroll.taxReturns.annualReturn',
            { year: this.year }
        );
    }
}

export class GeneratedOnColumn extends Column {
    type = CellDisplay.date;
    property = 'createdAt';
    title = 'table.taxReturns.generatedOn';
}

export class StatusColumn extends PillColumn<T4Summary> {
    type = CellDisplay.pill;
    title = 'table.taxReturns.status';

    getPillDisplay(row: T4Summary): PillDisplay | undefined {
        if (row.createdAt === undefined) {
            return { label: 'table.taxReturns.notGenerated', type: 'default' };
        }

        return row.statePill();
    }
}
