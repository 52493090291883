import { T4Summary } from '@app/models/payroll/t4-summary.model';
import { PillColumn } from '@app/modules/table/classes/pill-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { PillDisplay } from '@app/modules/table/interfaces/pill-display.interface';

export class StatusColumn extends PillColumn<T4Summary> {
    title = 'table.employerPackage.status';
    property = 'state';
    sortField = 'state';
    sortable = true;
    type: CellDisplay = CellDisplay.pill;

    getPillDisplay(t4Summary: T4Summary): PillDisplay | undefined {
        return {
            type: t4Summary.isOpen() ? 'primary' : 'success',
            label: t4Summary.state === 'open' ? 'table.employerPackage.open' : 'table.employerPackage.finalized',
        };
    }
}
