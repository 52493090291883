import { Roe } from '@app/models/payroll/roe.model';
import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { PillColumn } from '@app/modules/table/classes/pill-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { PillDisplay } from '@app/modules/table/interfaces/pill-display.interface';

export class RoeStatusColumn extends PillColumn<Roe> {
    title = 'table.roes.status';
    property = 'state';
    sortField = 'state';
    sortable = true;
    filters = [
        new CheckListFilter('state', [
            { label: 'table.roes.statusOptions.open', value: 'open', initiallyActive: false },
            { label: 'table.roes.statusOptions.pending', value: 'pending', initiallyActive: false },
            { label: 'table.roes.statusOptions.submitted', value: 'submitted', initiallyActive: false },
            { label: 'table.roes.statusOptions.failed', value: 'failed', initiallyActive: false },
        ]),
    ];
    type: CellDisplay = CellDisplay.pill;

    getPillDisplay(roe: Roe): PillDisplay | undefined {
        const pillTypeAndLabel = this.getRoePillTypeAndLabel(roe?.state);
        return pillTypeAndLabel;
    }

    getRoePillTypeAndLabel(roeState: string): PillDisplay {
        switch (roeState) {
            case 'open':
                return {
                    type: 'default',
                    label: 'table.roes.statusOptions.open',
                };
            case 'pending':
                return {
                    type: 'alert',
                    label: 'table.roes.statusOptions.pending',
                };
            case 'processing':
                return {
                    type: 'alert',
                    label: 'table.roes.statusOptions.pending',
                };
            case 'submitted':
                return {
                    type: 'success',
                    label: 'table.roes.statusOptions.submitted',
                };
            case 'failed':
                return {
                    type: 'danger',
                    label: 'table.roes.statusOptions.failed',
                };
        }
    }
}
