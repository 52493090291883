import { Model } from '@app/interfaces';
import { CellDisplay } from '../enums/cell-display.enum';
import { PillDisplay } from '../interfaces/pill-display.interface';
import { Column } from './column.class';

export abstract class MultiPillColumn<T extends Model> extends Column {
    type = CellDisplay.multiPill;

    /**
     * Return an array of PillDisplay types with the text that should be within each pill,
     * and the pill type to use.
     */
    abstract getMultiPillDisplay(row: T): PillDisplay[];
}
