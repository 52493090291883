import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { FileHelperService } from '@app/services/file-helper.service';
import { spacing } from '@app/styles/theme';
import { css } from 'emotion';

export interface Avatarable {
    fullName: string;
    firstName: string | null;
    first_name?: string;
    lastName: string | null;
    last_name?: string;
    avatarId?: number;
    avatar_id?: number;
}

export type Size =
    | 'icon' // 28px
    | 'font-fixed' // 32px - for in copy/text
    | 'profile' // 140px
    | 'large' // 88px
    | 'medium' // 40px
    | 'inline' // 60px
    | 'mobile-shrink'; // icon (28px) on desktop, shrinks to 20px on mobile

export const DefaultIconSize: Size = 'icon';

const withMarginStyle = css`
    &:not(:last-child) {
        margin-right: ${spacing(1)};
    }

    .ui.circular.image.profile {
        margin-top: ${spacing(1)};
    }
`;

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.template.html',
    styleUrls: ['./avatar.style.scss'],
})
export class AvatarComponent implements OnInit {
    @Input() employee?: Avatarable;
    @Input() shape = 'circular';
    @Input() size: Size = DefaultIconSize;
    @Input() faded = false;
    @Input() name = false;
    @Input() fullName?: string;
    @Input() showTitle = true;
    @Input() showBorder = false;
    @Input() withMargin = true; // !DEPRECATED: set to false where possible. Spacing and layout should be handled by the parent

    avatarId: number | null = null;

    constructor(
        private element: ElementRef<HTMLElement>,
        private fileHelper: FileHelperService
    ) {}

    ngOnInit(): void {
        $(this.element.nativeElement).find('img').css({ opacity: 0 });
        if (this.withMargin) {
            this.element.nativeElement.classList.add(withMarginStyle);
        }
    }

    getInitial(name?: string): string {
        if (name && name.length) {
            return name.substring(0, 1);
        }
        return '?';
    }

    @HostBinding('attr.aria-label')
    @HostBinding('title')
    get title(): string {
        return this.showTitle ? this.fullName ?? this.employee?.firstName + ' ' + this.employee?.lastName : '';
    }

    onLoad(): void {
        setTimeout(() => {
            const width = $(this.element.nativeElement).find('img').width();
            const height = $(this.element.nativeElement).find('img').height();

            if (width > height) {
                $(this.element.nativeElement).find('img').css({ height: '100%', width: 'auto' });
            } else if (width < height) {
                $(this.element.nativeElement).find('img').css({ width: '100%', height: 'auto' });
            } else {
                $(this.element.nativeElement).find('img').css({ width: '100%', height: '100%' });
            }

            $(this.element.nativeElement).find('img').animate({ opacity: 1 }, 250);
        });
    }

    get avatarUrl(): string | undefined {
        const avatarId = this.employee?.avatarId || this.employee?.avatar_id;
        if (avatarId) {
            return this.fileHelper.serve(avatarId);
        }
        return undefined;
    }
}
