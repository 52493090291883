import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PillType } from './types';
@Component({
    selector: 'ui-pill',
    templateUrl: './pill.template.html',
    styleUrls: ['./pill.style.scss'],
})
export class PillComponent implements OnChanges {
    @Input() disabled = false;
    @Input() type: PillType = 'default';

    constructor(private element: ElementRef) {}

    get pillClass(): string {
        return this.disabled ? 'disabled' : this.type;
    }

    ngOnChanges(changes: SimpleChanges): void {
        const classList = this.element.nativeElement.classList;
        const disabled = 'disabled';

        if (disabled in changes && changes.disabled.currentValue === true) {
            classList.add(disabled);

            return;
        }

        classList.remove(disabled);
    }
}
