import { Injectable } from '@angular/core';
import { QueryFetcher } from '@app/models/core/query-fetcher.model';
import { Paystub } from '@app/models/payroll/company/paystub.model';
import { Payroll } from '@app/models/payroll/payroll.model';
import { SortDirection } from '@app/types';
import { PayrollFilterCategories } from '@payroll/interfaces/payroll-filters.interface';
import { BehaviorSubject } from 'rxjs';

type FilterValues = Partial<PayrollFilterCategories>;

const PAYSTUB_INCLUDES = [
    'employee',
    'deduction_items',
    'additional_pay_items',
    'hourly_pay_items',
    'salary_pay_items',
    'reimbursement_item',
    'content_directories',
    'scheduled_vacation_payout',
];

const PAYSTUB_LIMIT = 10;

@Injectable()
export class PaystubLazyLoadService {
    payroll: Payroll;
    payStubs: BehaviorSubject<Paystub[]> = new BehaviorSubject<Paystub[]>([]);

    searchQuery: string | null = null;
    sortDirection: SortDirection = 'ASC';
    activeFilters: FilterValues | null = null;

    hasMore = true;

    private currentPage = 0;

    resetQuery(): void {
        this.searchQuery = null;
        this.sortDirection = 'ASC';
        this.activeFilters = null;
    }

    setPayroll(payroll: Payroll): void {
        this.payroll = payroll;
    }

    setSearchQuery(searchQuery?: string): void {
        if (!searchQuery?.length) {
            this.searchQuery = null;
            return;
        }

        this.searchQuery = searchQuery;
    }

    setSortDirection(sortDirection: SortDirection): void {
        this.sortDirection = sortDirection;
    }

    setActiveFilters(activeFilters: FilterValues): void {
        if (!Object.keys(activeFilters)?.length) {
            this.activeFilters = null;
            return;
        }

        this.activeFilters = activeFilters;
    }

    findByEmployeeName(name: string): Paystub | null {
        return this.payStubs.getValue().find((p) => p.employeeName === name);
    }

    findById(id: number): Paystub | null {
        return this.payStubs.getValue().find((p) => p.id === id);
    }

    async load(): Promise<void> {
        const query = this.buildQuery();

        const [paystubs, meta] = await query.get();

        this.currentPage = +meta.page.number;
        this.hasMore = this.currentPage < +meta.page.totalNumber;

        this.payStubs.next(paystubs);
    }

    async loadMore(): Promise<void> {
        const query = this.buildQuery();

        const pageToLoad = this.currentPage + 1;

        const [paystubs, meta] = await query.page(pageToLoad).get();

        this.currentPage = +meta.page.number;
        this.hasMore = this.currentPage < +meta.page.totalNumber;

        const newPaystubs = [...this.payStubs.getValue(), ...paystubs];

        this.payStubs.next(newPaystubs);
    }

    async refreshPaystub(id: number): Promise<void> {
        const paystubs = this.payStubs.getValue();
        const paystub = await Paystub.param('payroll', this.payroll.id).with(PAYSTUB_INCLUDES).find(id);

        const stalePaystubIndex = this.payStubs.getValue().findIndex((p) => p.id === id);
        paystubs.splice(stalePaystubIndex, 1, paystub);

        this.payStubs.next(paystubs);
    }

    async save(paystub: Paystub): Promise<void> {
        paystub.employeeId = paystub.employee.id;
        await paystub.param('payroll', this.payroll.id).save();
    }

    async delete(paystub: Paystub): Promise<void> {
        this.payStubs.next(this.payStubs.getValue().filter((p) => p.id !== paystub.id));

        paystub.employeeId = paystub.employee.id;
        await paystub.param('payroll', this.payroll.id).delete();
    }

    private buildQuery(): QueryFetcher {
        const query = Paystub.param('payroll', this.payroll.id);

        if (this.activeFilters) {
            for (const field in this.activeFilters) {
                query.where(field, this.activeFilters[field]);
            }
        }

        if (this.searchQuery) {
            query.where('employeeName', this.searchQuery);
        }

        if (this.sortDirection) {
            query.orderBy('employee', this.sortDirection);
        }

        return query.with(PAYSTUB_INCLUDES).limit(PAYSTUB_LIMIT);
    }
}
