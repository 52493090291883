<div class="ui page dimmer">
    <div class="content" role="dialog" aria-labelledby="dialog-header">
        <div class="ui segment" [class.loading]="isLoading">
            <h2 id="dialog-header">{{ headerText | appTranslate }}</h2>
            <div class="margin-left-4 margin-right-4">
                <ng-content></ng-content>
            </div>
            <div class="content">
                <!-- Banner -->
                <ng-container
                    *ngIf="displayBannerBasedOnMaxItems && maxItemsToSelect && selectItems?.length >= maxItemsToSelect"
                >
                    <ui-banner [variant]="bannerType"> {{ bannerContent | appTranslate }} </ui-banner>
                </ng-container>

                <form class="ui form" #form="ngForm">
                    <div class="ui stackable grid">
                        <!-- Items Top -->
                        <div class="eight wide column items top-section">
                            <app-deprecated-filter
                                [filters]="filters"
                                [filterValues]="filterValues"
                                [activeFilters]="activeFilters"
                                (filter)="onFilter($event)"
                                (scrolledToBottom)="paginateFilter($event)"
                            ></app-deprecated-filter>

                            <app-search
                                placeholder="components.bulk-select-employee-dialog.search"
                                (onSearch)="onSearch($event)"
                            ></app-search>
                        </div>

                        <!-- Selected Top -->
                        <div class="eight wide column top-section">
                            <h4 class="ui header">
                                {{ 'components.bulk-select-employee-dialog.selected' | appTranslate }}
                            </h4>
                            <ui-button
                                type="link"
                                *ngIf="allowRemoval"
                                (click)="removeAll()"
                                [disabled]="!selectItems?.length && !allSelected"
                                class="bulk-action"
                                >{{ 'components.bulk-select-employee-dialog.removeAll' | appTranslate }}</ui-button
                            >
                        </div>

                        <!-- Items Body -->
                        <div class="eight wide column results">
                            <div class="ui middle aligned selection list" role="list">
                                <div
                                    class="item"
                                    role="listitem"
                                    [tabindex]="maxItemsToSelect && selectItems?.length >= maxItemsToSelect ? -1 : 0"
                                    *ngIf="items?.length && items?.length > 1"
                                    [class.selected]="allSelected"
                                    [class.disabled]="maxItemsToSelect && selectItems?.length >= maxItemsToSelect"
                                    (click)="selectAll()"
                                >
                                    <app-avatar
                                        [fullName]="
                                            {
                                                key: 'components.bulk-select-employee-dialog.allFilteredItems',
                                                params: { count: totalResults },
                                            } | appTranslate
                                        "
                                        [name]="true"
                                    ></app-avatar>
                                </div>
                                <ng-container *ngFor="let item of items; let index = index">
                                    <div
                                        class="item"
                                        role="listitem"
                                        [attr.aria-labelledby]="'unselected-' + index"
                                        [tabindex]="
                                            maxItemsToSelect && selectItems?.length >= maxItemsToSelect ? -1 : 0
                                        "
                                        [class.selected]="isSelected(item)"
                                        [class.disabled]="maxItemsToSelect && selectItems?.length >= maxItemsToSelect"
                                        (click)="select(item)"
                                    >
                                        <app-avatar
                                            [id]="'unselected-' + index"
                                            [employee]="item"
                                            [name]="true"
                                        ></app-avatar>
                                        <ui-info
                                            *ngIf="tooltipTextMaker && tooltipTextMaker(item)"
                                            text="{{ tooltipTextMaker(item) }}"
                                        ></ui-info>
                                    </div>
                                </ng-container>
                                <div *ngIf="!items?.length" class="item disabled">
                                    {{ 'components.bulk-select-employee-dialog.noResults' | appTranslate }}
                                </div>
                            </div>
                        </div>

                        <!-- Selected Body -->
                        <div class="eight wide column selection">
                            <div class="ui middle aligned selection list" role="list">
                                <div
                                    class="item"
                                    role="listitem"
                                    tabindex="0"
                                    *ngIf="allSelected"
                                    (click)="removeAll()"
                                >
                                    <app-avatar
                                        [fullName]="'All ' + totalResults + ' Filtered Items'"
                                        [name]="true"
                                    ></app-avatar>
                                    <div class="job-title"></div>
                                </div>
                                <ng-container *ngFor="let item of selectItems; let index = index">
                                    <div class="item" role="listitem" tabindex="0" (click)="remove(item)">
                                        <app-avatar [employee]="item" [name]="true"></app-avatar>
                                        <ui-info
                                            *ngIf="tooltipTextMaker && tooltipTextMaker(item)"
                                            text="{{ tooltipTextMaker(item) }}"
                                        ></ui-info>
                                        <div class="job-title">{{ item?.job?.title }}</div>
                                    </div>
                                </ng-container>
                                <div *ngIf="!selectItems?.length && !allSelected" class="item disabled">
                                    {{ 'components.bulk-select-employee-dialog.noSelections' | appTranslate }}
                                </div>
                            </div>
                        </div>

                        <!-- Items bottom -->
                        <div class="eight wide column bottom-section">
                            <app-pagination
                                *ngIf="items?.length"
                                [total]="totalResults"
                                [current]="currentPage"
                                [fixed]="false"
                                (pageChange)="onPageChange($event)"
                            >
                            </app-pagination>
                            <a
                                (click)="selectPage()"
                                [class.disabled]="!items?.length"
                                [class.hidden]="hideSelectPage"
                                class="bulk-action"
                                >{{ 'components.bulk-select-employee-dialog.selectPage' | appTranslate }}</a
                            >
                        </div>

                        <!-- Selected Bottom -->
                        <div class="eight wide column bottom-section selected-items">
                            <ui-button type="primary" (click)="approve()" [disabled]="!selection">
                                {{ label | appTranslate }}
                            </ui-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
