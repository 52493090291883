<div
    class="benefitsCardContainer"
    [style.background-color]="'#' + (benefits?.plan?.provider?.colour || benefits?.benefitPlan?.benefitProvider?.color)"
>
    <div class="benefitsCardWhiteOverlay"></div>
    <div class="benefitsCardHeader">
        <div
            class="benefitsCardCarrierLogo"
            [style.background-image]="'url(/assets/img/brands/' + benefits?.benefitPlan?.benefitProvider?.logoUrl + ')'"
        ></div>
        <p class="benefitsCardCompanyTitle rightText darkText smallText boldText">{{ employee?.account?.fullName }}</p>
        <p class="benefitsCardCompanySubTitle rightText grayText smallText">
            {{ 'components.benefits-card.employeeBenefitsCard' | translate }}
        </p>
    </div>

    <!-- Manual Card details -->
    <div class="benefitsCardFloater">
        <p class="benefitsFloaterTitle leftText darkText smallText">
            {{ benefits?.card_title_1 || benefits?.cardTitlePrimary }}
        </p>
        <p class="benefitsFloaterValue leftText grayText tinyText">
            {{ benefits?.card_value_1 || benefits?.cardValuePrimary }}
        </p>
    </div>
    <div class="benefitsCardFloater">
        <p class="benefitsFloaterTitle leftText darkText smallText">
            {{ benefits?.card_title_2 || benefits?.cardTitleSecondary }}
        </p>
        <p class="benefitsFloaterValue leftText grayText tinyText">
            {{ benefits?.card_value_2 || benefits?.cardValueSecondary }}
        </p>
    </div>
    <div class="benefitsCardFloater">
        <p class="benefitsFloaterTitle leftText darkText smallText">
            {{ benefits?.card_title_3 || benefits?.cardTitleTertiary }}
        </p>
        <p class="benefitsFloaterValue leftText grayText tinyText">
            {{ benefits?.card_value_3 || benefits?.cardValueTertiary }}
        </p>
    </div>
</div>
