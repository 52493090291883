import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { Components } from '@app/components';
import { Directives } from '@app/directives';
import { Pipes } from '@app/pipes';
import { environment } from '@env/environment';
import { HotTableModule } from '@handsontable/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { registerAllModules } from 'handsontable/registry';
import moment from 'moment';
import { MaterialModules, MaterialProviders } from './material-config';

registerAllModules();
LicenseManager.setLicenseKey(environment.agGridEnterpriseKey);

export function momentAdapterFactory(): DateAdapter {
    return adapterFactory(moment);
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ...MaterialModules,
        ScrollingModule,
        DragDropModule,
        MatTreeModule,
        HotTableModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
        AgGridModule,
        TranslateModule.forChild(),
        PortalModule,
    ],
    declarations: [...Directives, ...Pipes, ...Components],
    providers: [MaterialProviders],
    exports: [
        ...MaterialModules,
        ScrollingModule,
        DragDropModule,
        MatTreeModule,
        HotTableModule,
        AgGridModule,
        CalendarModule,
        ...Directives,
        ...Pipes,
        ...Components,
    ],
})
export class PlatformModule {}
