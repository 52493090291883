import { HttpErrorResponse } from '@angular/common/http';
import { JsonApiError } from '@app/errors/json-api.error';
import * as Sentry from '@sentry/browser';
import { isNil } from 'lodash-es';

interface HumiHttpErrorResponse extends HttpErrorResponse {
    errors: JsonApiError[];
    jsonapi: string;
}

type NestedError = { error: UnknownError };

type UnknownError = { errors?: unknown[]; jsonapi?: string; message?: string };

type ErrorMap = { [key: string]: string };

/* We only want to include the source if is included in a pointer
 * or if there is a 422 error.
 * We are trying to make our error messages user readable.
 */
function getSource(error: JsonApiError): string {
    let source = error.status === 422 ? 'unknown property' : '';
    if (error.source?.pointer) {
        source = error.source.pointer.replace('data.attributes.', '').replace('/data/attributes/', '');
    }

    const sourceContainsFilePath = source.includes('/') || source.includes('www');
    if (sourceContainsFilePath) {
        source = '';
    }
    return source;
}

function errorToString(error: JsonApiError): string {
    const source = getSource(error);

    let detail = 'caused an error';
    if (error.detail) {
        detail = error.detail.replace('data.attributes.', '').replace('/data/attributes/', '');
    }

    const errorMessage = source ? source + ' ' + detail + '.' : detail + '.';

    const formattedErrorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);

    return formattedErrorMessage;
}

const isNestedError = (error: Record<string, unknown> | HttpErrorResponse): error is NestedError =>
    Boolean(error.error);

const isHumiHttpErrorResponse = (error: UnknownError): error is HumiHttpErrorResponse =>
    !(!error || !error.jsonapi || !error.errors);

export class ErrorParser {
    static parse(json: Record<string, unknown> | HttpErrorResponse): string {
        if (isNil(json)) {
            Sentry.captureException('null or undefined error sent to ErrorParser');
        } else {
            const error = isNestedError(json) ? json.error : (json as UnknownError);

            if (isHumiHttpErrorResponse(error)) {
                return error.errors.map(errorToString).join('</br>');
            }

            if (error.errors && error.errors.length) {
                return error.errors.join(',');
            }

            if (typeof error === 'string') {
                return error;
            }

            if (error.message) {
                return error.message;
            }

            Sentry.captureException(json);
        }

        return 'Could not process error response';
    }

    static parseAsMap(json: HumiHttpErrorResponse): ErrorMap {
        if (json.error && json.status) {
            json = json.error;
        }

        // validateResponseFields returns a string if the fields are in the incorrect format, in which case nothing more can be done here so return an empty map
        if (this.getInvalidResponseFields(json)) {
            return {};
        }
        return json.errors.reduce((errorMap: ErrorMap, error: JsonApiError) => {
            let source = 'unknown property';
            if (error.source && error.source.pointer) {
                source = error.source.pointer.replace('data.attributes.', '').replace('/data/attributes/', '');
            }

            let detail = 'caused an error';
            if (error.detail) {
                detail = error.detail.replace('data.attributes.', '').replace('/data/attributes/', '');
            }

            errorMap[source] = detail;
            return errorMap;
        }, {});
    }

    /**
     * check if the error itself contains all the necessary fields
     * this is looking for 'errors' and 'jsonapi' and for the err.raw.error to not be empty
     */
    static getInvalidResponseFields(json: HumiHttpErrorResponse): string | undefined {
        if (!json?.jsonapi || !json?.errors) {
            if (json.errors && json.errors.length) {
                return json.errors.join(',');
            }

            Sentry.captureException(json);

            return 'Could not process error response';
        }
        return undefined;
    }

    static parseJsonApiError(error: JsonApiError): string | undefined {
        if (error.source?.pointer) {
            return this.getSanitizedMessage(error.source.pointer, error.detail);
        }
        if (error.code && error.detail) {
            return `${error.detail} - ${error.code}`;
        }
    }

    private static getSanitizedMessage(pointer: string, detail: string): string {
        const cause = this.sanitizeMessage(pointer);
        const message = this.sanitizeMessage(detail);
        return `${cause} ${message}`;
    }

    private static sanitizeMessage(message: string): string {
        return message.replace(/\/?data[.|/]attributes[.|/]/g, '');
    }
}
