import { css } from 'emotion';
import { colors } from '@app/styles/theme';
import { createPager, PagerProps } from '@app/functions/pager';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, HostListener } from '@angular/core';

@Component({
    selector: 'app-pdf-viewer-dialog-controls',
    template: `
        <div class="flex align-items-center padding-x-4 padding-y-1" [ngClass]="[styles.toolbar]">
            <div class="flex-1 margin-right-auto flex align-items-center">
                <h4 class="margin-0 font-bold">{{ name }}</h4>

                <div class="flex align-items-center margin-left-4 flex-1">
                    <p class="margin-y-0 margin-right-2">Page {{ currentPage }} of {{ pager.pages }}</p>
                    <a
                        role="button"
                        (click)="handlePage('prev')"
                        [attr.disabled]="currentPage === 1"
                        [ngClass]="[currentPage === 1 ? styles.disabled : '']"
                    >
                        <mat-icon svgIcon="chevronUp"></mat-icon>
                    </a>
                    <a
                        role="button"
                        (click)="handlePage('next')"
                        [attr.disabled]="pages === currentPage"
                        [ngClass]="[pages === currentPage ? styles.disabled : '']"
                    >
                        <mat-icon svgIcon="chevronDown"></mat-icon>
                    </a>
                </div>
                <div class="margin-left-auto">
                    <ui-button class="margin-right-4" *ngIf="buttonLabel" type="primary" (click)="buttonClicked.emit()">
                        {{ buttonLabel }}</ui-button
                    >
                </div>
            </div>
            <span [ngClass]="[styles.closeButton]" role="button" (click)="closing.emit()">
                <mat-icon color="white" svgIcon="close"></mat-icon>
            </span>
        </div>
    `,
})
export class PdfViewerDialogControlsComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() pages: number;
    @Input() page: number;
    @Input() buttonLabel: string | null = null;
    @Output() pageChange: EventEmitter<number> = new EventEmitter();
    @Output() buttonClicked: EventEmitter<void> = new EventEmitter();
    @Output() closing: EventEmitter<void> = new EventEmitter();

    styles = {
        closeButton: css`
            cursor: pointer;
        `,
        disabled: css`
            opacity: 0.3;
        `,
        toolbar: css`
            height: 100%;
            h4,
            p,
            .item,
            mat-icon {
                color: ${colors.white} !important;
            }

            span[role='button'] {
                cursor: pointer;
            }
        `,
        controlsHidden: css`
            min-width: 100%;
            * {
                visibility: hidden;
            }
        `,
    };

    pager: PagerProps;

    ngOnInit(): void {
        this.pager = createPager({
            pages: this.pages,
            onPageChange: ({ getCurrentPage }) => {
                const currentPage = getCurrentPage();
                this.pageChange.emit(currentPage);
                return currentPage;
            },
        });
    }

    ngOnDestroy(): void {
        delete this.pager;
    }

    handlePage(type: 'next' | 'prev'): void {
        this.pager[type]();
    }

    get currentPage(): number {
        return this.pager.getCurrentPage();
    }

    @HostListener('window:keydown', ['$event'])
    handleKey(event: KeyboardEvent): void {
        if (event.key.startsWith('Arrow')) {
            event.preventDefault();
        }
        switch (event.key) {
            case 'ArrowDown':
                this.handlePage('next');
                break;
            case 'ArrowUp':
                this.handlePage('prev');
        }
    }
}
