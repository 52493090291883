import { Injectable } from '@angular/core';
import { FeatureFlag } from '@app/enums';
import { AuthService } from '@app/services/auth.service';
import { environment } from '@env/environment';
import { FeatureInterface } from '@interfaces/feature.interface';
import * as Sentry from '@sentry/browser';
import { initialize, LDClient, LDFlagSet, LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, takeWhile, timeout } from 'rxjs/operators';

const FLAG_TIMEOUT = 10000; // 10 seconds
const SYSTEM_USER = 'system_user';

@Injectable({
    providedIn: 'root',
})
export class LaunchDarklyFeatureService implements FeatureInterface {
    /**
     * Determines if the service is being used locally or by a LaunchDarkly client.
     * This is used to identify if an unauthenticated component/view can use the LD client
     */
    readonly isLocal = false;
    private ldClient?: LDClient;
    private flags: LDFlagSet = {};
    private _isClientReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private auth: AuthService) {
        this.initializeClient();
    }

    get isClientReady(): Observable<boolean> {
        return this._isClientReady$.asObservable();
    }

    /**
     * Determines if current user has access to feature flag
     * Will wait until connection to LD is established. Otherwise it defaults to false if time-out occurs
     */
    async has(flag: FeatureFlag): Promise<boolean> {
        const isClientReady = await this.waitForClientReady(flag);
        if (isClientReady) {
            return this.getFlag(flag);
        } else {
            // TODO: Change to a Promise rejection.
            // This currently returns false due to pre-existing code relying on this resolution
            // The # of timeouts is being tracked in Sentry, if it's determined not to be an issue, this can be converted to a rejection
            return false;
        }
    }

    /**
     * Determines if current user has access to many feature flags
     */
    async hasMany(...flags: FeatureFlag[]): Promise<boolean[]> {
        const isClientReady = await this.waitForClientReady(...flags);
        if (isClientReady) {
            return flags.map((flag) => this.getFlag(flag));
        } else {
            return flags.map(() => false);
        }
    }

    async all(): Promise<LDFlagSet> {
        const isClientReady = await this.waitForClientReady();
        if (isClientReady) {
            return this.flags;
        } else {
            return Promise.reject();
        }
    }

    private openClient(): void {
        // Client is already open
        if (this._isClientReady$.getValue()) {
            return;
        }

        const user = this.getLDUser();
        this.ldClient = initialize(environment.launchDarkly, user, { diagnosticOptOut: true });
        this.ldClient.on('change', this.getFlags, this);
        this.ldClient.waitUntilReady().then(() => {
            this.getFlags();
            this._isClientReady$.next(true);
        });
    }

    private getFlags(): void {
        this.flags = this.ldClient?.allFlags() ?? {};
    }
    private initializeClient(): void {
        // When the login status changes we need to re-identify the user with their new context (unauthenticated user to authenticated and vice-versa)
        this.auth.onLoginStatusChange.subscribe(() => {
            // We first signal that the client is not ready, this will block all flag calls until the authenticated flags are set
            this._isClientReady$.next(false);
            this.ldClient?.identify(this.getLDUser()).then(() => {
                // After we refresh the flag values, all "has" calls can now return
                this.getFlags();
                this._isClientReady$.next(true);
            });
        });
        this.openClient();
    }

    private getLDUser(): LDSingleKindContext {
        if (!this.auth.hydrated) {
            return {
                kind: 'user',
                key: SYSTEM_USER,
            };
        }
        return {
            kind: 'user',
            key: this.auth.account.id.toString(),
            firstName: this.auth.account.legalFirstName,
            lastName: this.auth.account.legalLastName,
            email: this.auth.account.email,
            companyId: this.auth.company.id,
            companyName: this.auth.company.name,
        };
    }

    private getFlag(flag: FeatureFlag): boolean {
        return flag in this.flags ? this.flags[flag] : false;
    }

    private waitForClientReady(...flags: FeatureFlag[]): Promise<boolean> {
        // If the client is ready, return immediately
        if (this._isClientReady$.getValue()) {
            return Promise.resolve(true);
        }

        // Wait for the observable to be triggered by openClient() above
        return this._isClientReady$
            .pipe(
                takeWhile((isClientReady) => !isClientReady, true),
                timeout(FLAG_TIMEOUT), // if it does not occur within the timeout, an error will be thrown,
                // TODO: Remove this catchError, we SHOULD reject when we can't retrieve a flag value
                catchError(() => {
                    Sentry.captureException(
                        `Application timed out while attempting to retrieve ${flags?.join(',')} flag(s)`
                    );
                    return of(false);
                })
            )
            .toPromise();
    }
}
