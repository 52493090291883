import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Paginator } from '@app/classes/paginator';
import { PillOptions } from '@app/interfaces';

/**
 * IMPORTANT
 * This import must remain relative to prevent circular import issues
 * with parent components.
 *
 * Inspection suppressed to prevent automatic replacement.
 */
// noinspection ES6PreferShortImport
import { Translatable } from '@app/types/translatable.type';
import { TabItemComponent } from '../tab-item/tab-item.component';

type ContextMenuType = 'semantic' | 'material';

@Component({
    selector: 'app-segment-header',
    templateUrl: './segment-header.template.html',
    styleUrls: ['./segment-header.style.scss'],
})
export class SegmentHeaderComponent implements OnInit, OnChanges {
    get activeTab(): TabItemComponent {
        return this._activeTab;
    }

    set activeTab(val: TabItemComponent) {
        this._activeTab = val;
        this.tab.emit(this._activeTab);
    }

    @Input() title: Translatable = 'components.segment-header.pageHeader';
    @Input() subtitle: Translatable;
    @Input() paginator: Paginator;
    @Input() back = false;
    @Input() collapsible = false;
    @Input() collapsed = false;
    @Input() editPermission: string | string[] = null;
    @Input() editFlag: boolean;
    @Input() editLabel: Translatable = 'edit';
    @Input() createPermission: string | string[] = null;
    @Input() createFlag: boolean;
    @Input() createLabel: Translatable = 'components.segment-header.create';
    @Input() allowEdit = true;
    @Input() tabMenu = false;
    @Input() initialTab: TabItemComponent;
    @Input() contextMenu = false;
    @Input() contextMenuType: ContextMenuType = 'semantic';
    @Input() id?: string;

    @Input() pillOptions?: PillOptions;

    @Output() tab: EventEmitter<TabItemComponent> = new EventEmitter<TabItemComponent>();
    @Output() edit: EventEmitter<null> = new EventEmitter<null>();
    @Output() create: EventEmitter<null> = new EventEmitter<null>();

    // makes our title attribute not show up in the tooltip
    // https://stackoverflow.com/a/74510560
    @HostBinding('attr.title') get getTitle(): null {
        return null;
    }

    showEdit = false;
    showCreate = false;

    private _activeTab: TabItemComponent;

    constructor(private element: ElementRef) {
        if (this.editFlag !== undefined) {
            this.editPermission = ['never'];
        }
        if (this.createFlag !== undefined) {
            this.createPermission = ['never'];
        }
    }

    ngAfterContentInit(): void {
        setTimeout(() => {
            $(this.element.nativeElement).find('.ui.dropdown').dropdown();
        });
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.showEdit = this.allowEdit && this.edit.observers.length > 0;
        this.showCreate = this.create.observers.length > 0;
    }

    ngOnInit(): void {
        if (this.collapsible && this.collapsed) {
            this.collapse();
        }

        if (this.initialTab) {
            this._activeTab = this.initialTab;
        }
    }

    emitEdit(): void {
        this.edit.emit();
    }

    emitCreate(): void {
        this.create.emit();
    }

    toggle(): void {
        if (!this.collapsible) {
            return;
        }

        this.collapsed ? this.expand() : this.collapse();
    }

    expand(): void {
        $(this.element.nativeElement).siblings('.content, .table').show();
        $(this.element.nativeElement).removeClass('collapsed');
        this.collapsed = false;
    }

    collapse(): void {
        $(this.element.nativeElement).siblings('.content, .table').hide();
        $(this.element.nativeElement).addClass('collapsed');
        this.collapsed = true;
    }
}
