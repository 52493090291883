import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmployeeOnLeaveOrTerminated, InsurableEarningsOptions } from '@app/interfaces';
import { PendingPayrollStatus } from '@app/interfaces/pending-payroll-status.interface';
import { PayrollEmployee } from '@app/models/payroll/payroll-employee.model';
import { Company } from '@models/company/company.model';
import { Roe } from '@models/payroll/roe.model';
import { PayrollResources } from '@payroll/payroll.resources';
import { parse } from 'date-fns';
import { map } from 'rxjs/operators';

@Injectable()
export class PayrollService {
    constructor(private http: HttpClient) {}

    getPendingPayrollStatus(): Promise<PendingPayrollStatus> {
        const url = `/v2/commonComponents/status/pending-payroll-status`;

        return this.http
            .get(url)
            .pipe(
                map((res: any) => {
                    const {
                        data: {
                            attributes: { exists, startDate: maybeStartDate, endDate: maybeEndDate },
                        },
                    } = res;

                    const startDate = maybeStartDate ? parse(maybeStartDate) : null;
                    const endDate = maybeEndDate ? parse(maybeEndDate) : null;

                    return { exists, startDate, endDate };
                })
            )
            .toPromise();
    }

    getPayrollBenefitsSetUpStatus(): Promise<{ isSetUp: boolean }> {
        const url = `/v2/commonComponents/status/payroll-benefits-set-up-status`;

        return this.http
            .get(url)
            .pipe(
                map((res: any) => {
                    const {
                        data: {
                            attributes: { isSetUp },
                        },
                    } = res;

                    return { isSetUp };
                })
            )
            .toPromise();
    }

    getPayrollHasBeenPaidStatus(): Promise<{ payrollHasBeenPaid: boolean }> {
        const url = `/v2/commonComponents/status/payroll-has-been-paid-status`;

        return this.http
            .get(url)
            .pipe(
                map((res: any) => {
                    const {
                        data: {
                            attributes: { payrollHasBeenPaid },
                        },
                    } = res;

                    return { payrollHasBeenPaid };
                })
            )
            .toPromise();
    }

    getPayrollDigestSettings(): Promise<any> {
        const endpoint = '/v1/payroll/digest/settings';
        return this.http.get(endpoint).toPromise().then(this.returnJson).catch(this.throwError);
    }

    updatePayrollDigestSettings(settings: any): Promise<any> {
        const endpoint = '/v1/payroll/digest/settings';
        return this.http.post(endpoint, settings).toPromise().then(this.returnJson).catch(this.throwError);
    }

    archivePayrollDigestItem(itemId: number): Promise<any> {
        const endpoint = `/v1/payroll/digest/${itemId}/archive`;
        return this.http.put(endpoint, {}).toPromise().then(this.returnJson).catch(this.throwError);
    }

    unarchivePayrollDigestItem(itemId: number): Promise<any> {
        const endpoint = `/v1/payroll/digest/${itemId}/unarchive`;
        return this.http.put(endpoint, {}).toPromise().then(this.returnJson).catch(this.throwError);
    }

    submitRoe(company: Company, roe: Roe): Promise<any> {
        const path = PayrollResources.SubmitRoe.replace(':company', company.id.toString()).replace(
            ':roe',
            roe.id.toString()
        );

        return this.http
            .get(path)
            .toPromise()
            .then(() => Roe.param('company', company.id.toString()).find(roe.id.toString()))
            .then(this.returnJson)
            .catch(this.throwError);
    }

    insurableEarnings(company: Company, roe: Roe, options: InsurableEarningsOptions): Promise<any> {
        const queryString = Object.keys(options)
            .map((key) => key + '=' + options[key])
            .join('&');
        const path =
            PayrollResources.InsurableEarnings.replace(':company', company.id.toString()).replace(
                ':roe',
                roe.id.toString()
            ) +
            '?' +
            queryString;

        return this.http.get(path).toPromise().then(this.returnJson).catch(this.throwError);
    }

    exportPayrollDigest(): any {
        return this.http.get('/v1/payroll/digest/export', { responseType: 'blob' });
    }

    async getIsTimeOffYTDUpdatable(companyId: number): Promise<boolean> {
        const url = `/v2/payroll/companies/${companyId}/timeOffYtdsUpdatable`;
        return await this.http
            .get(url)
            .pipe(
                map((res: any) => {
                    return res.data.attributes.timeOffYtdsUpdatable;
                })
            )
            .toPromise();
    }

    async getLastRecurringPayrollEndDate(companyId: number): Promise<Date | null> {
        const url = PayrollResources.LastRecurringPayrollEndDate.replace(':company', companyId.toString());
        const lastRecurringPayrollEndDate = await this.http
            .get(url)
            .pipe(
                map((res: any) => {
                    return res.data.attributes.endDate;
                })
            )
            .toPromise();
        return lastRecurringPayrollEndDate;
    }

    async fetchPendingPayrollStatus(): Promise<PendingPayrollStatus> {
        const url = `/v2/commonComponents/status/pending-payroll-status`;

        const pendingPayrollStatusResponse = await this.http
            .get(url)
            .pipe(
                map((res: any) => {
                    const {
                        data: {
                            attributes: { exists, startDate: maybeStartDate, endDate: maybeEndDate },
                        },
                    } = res;

                    const startDate = maybeStartDate ? parse(maybeStartDate) : null;
                    const endDate = maybeEndDate ? parse(maybeEndDate) : null;

                    return { exists, startDate, endDate };
                })
            )
            .toPromise();
        return pendingPayrollStatusResponse;
    }

    /**
     * Check if the employee is on leave or terminated on the date provided or will be at a later date
     */
    checkEmployeeOnLeaveOrTerminated(
        company: Company,
        prEmployee: PayrollEmployee,
        date: string
    ): Promise<EmployeeOnLeaveOrTerminated> {
        return this.http
            .get<{ data: { attributes: EmployeeOnLeaveOrTerminated } }>(
                PayrollResources.EmployeeOnLeaveOrTerminated.replace(':company', company.id.toString()).replace(
                    ':employee',
                    prEmployee.hrEmployeeId.toString()
                ),
                { params: { date } }
            )
            .pipe(map(({ data: { attributes } }) => attributes))
            .toPromise();
    }

    private returnJson(res: any): Promise<any> {
        try {
            return Promise.resolve(res);
        } catch (e) {
            console.error('Failed to parse the JSON response from the server', res);
            return Promise.reject(res);
        }
    }

    private throwError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
