import { Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { Day } from '@app/classes/day';
import { numericRange } from '@app/functions/numeric-range';
import { LegendItem } from '@app/interfaces/legend-item.interface';
import { Model } from '@app/models/core/base.model';
import { AuthService } from '@app/services';
import { Translatable } from '@app/types/translatable.type';
import { CalendarEvent } from '@models/common/calendar-event.model';
import { Employee } from '@models/employee/employee.model';
import { TimeOffDay } from '@models/time-off-v3/time-off-day.model';
import { TimeOffRequestCalendarEvent } from '@models/time-off-v3/time-off-request-calendar-event.model';
import { TimeOffRequest, TimeOffRequestStatus } from '@models/time-off-v3/time-off-request.model';
import { MONTH_SHORT, TimeOffDateFormatDashes } from '@time-off-v3/meta/time-off-meta';
import moment from 'moment';

const DEFAULT_FUTURE_YEARS_RANGE = 3;
const DEFAULT_PAST_YEARS_RANGE = 11;

@Component({
    selector: 'app-year-calendar-v3',
    templateUrl: './year-calendar.template.html',
    styleUrls: ['./year-calendar.style.scss'],
})
export class YearCalendarComponentV3 implements OnInit {
    @Input() year: number = new Date().getFullYear();
    @Input() title: Translatable = 'components.year-calendar-v3.untitledCalendar';
    @Input() eventTypes: (typeof CalendarEvent)[] = [];
    @Input() legend: LegendItem[] = [];
    @Input() includeHolidays = true;
    @Input() futureYearsRange: number = DEFAULT_FUTURE_YEARS_RANGE;
    @Input() pastYearsRange: number = DEFAULT_PAST_YEARS_RANGE;

    /**
     * Entities that impact the scoping of the events
     * Ex: passing in an Employee can scope time off requests
     */
    @Input() relatedEntities: Model[] = [];
    days: Day[] = [];
    startAt: Date;
    endAt: Date;
    months = MONTH_SHORT;

    // Translation keys used for the days of the week appearing on the calendar
    // Since mobile has more horizontal space, all short keys are displayed, while on desktop only M,W,F is displayed
    daysOfTheWeek = [
        { mobile: 'sun' },
        { desktop: 'm', mobile: 'mon' },
        { mobile: 'tue' },
        { desktop: 'w', mobile: 'wed' },
        { mobile: 'thu' },
        { desktop: 'f', mobile: 'fri' },
        { mobile: 'sat' },
    ];
    futureYears: number[] = [];
    pastYears: number[] = [];
    years: number[] = [];

    constructor(
        private auth: AuthService,
        private element: ElementRef,
        private ngZone: NgZone
    ) {}

    /**
     * Look through the related entites for this calendar
     * If one of them is an employee, use it as the "related employee"
     * Otherwise, use the current user's employee
     *
     * Currently the calendar doesn't have related entities that aren't the employee
     * but it supports this functionality
     */
    get relatedEmployee(): Employee {
        const employee = this.relatedEntities.find((entity) => {
            const entityType = entity.constructor['_resource'].split('/').pop();
            const isEmployee = entityType.toLowerCase().includes('employee');
            return isEmployee;
        });

        return (employee as Employee) || this.auth.employee;
    }

    ngOnInit(): void {
        // Slice the current year to remove duplicate
        this.futureYears = numericRange(this.futureYearsRange)
            .map((offset: number) => this.year + offset)
            .slice(1);
        this.pastYears = numericRange(this.pastYearsRange)
            .map((offset: number) => this.year - offset)
            .reverse();
        this.years = [...this.pastYears, ...this.futureYears].sort();
        this.refresh();
    }

    onSwitchYear(year: number): void {
        this.year = year;
        this.refresh();
    }

    refresh(): void {
        this.renderCalendar();
        this.loadEvents();
    }

    private loadEvents(): void {
        const employee = this.relatedEmployee;
        this.loadTimeOffRequestsAndDays(employee);
    }

    private async loadTimeOffRequestsAndDays(employee: Employee): Promise<void> {
        const [timeOffDays] = await TimeOffDay.where('workScheduleId', employee.workScheduleId)
            .where('date', { from: this.startAt, to: this.endAt })
            .get();

        TimeOffRequest.with(['timeOffPolicy.timeOffType'])
            .where('employeeId', employee.id)
            .where('startAt', { to: this.endAt })
            .where('endAt', { from: this.startAt })
            .where('status', TimeOffRequestStatus.approved)
            .where('includeTerminated', true)
            .all()
            .then(([timeOffRequests]) => timeOffRequests.map((timeOffRequest) => timeOffRequest.toCalendarEvent()))
            .then((timeOffRequestCalendarEvents) => {
                this.addEventsToCalendar([...timeOffRequestCalendarEvents], timeOffDays);
            });
    }

    private renderCalendar(): void {
        $(this.element.nativeElement).find('.year').css({ opacity: 0 });
        const firstDayOfCalendarYear = new Date(this.year, 0, 1);
        const lastDayOfCalendarYear = moment(moment(firstDayOfCalendarYear).add(1, 'years'))
            .subtract(1, 'days')
            .toDate();

        const numberOfVisibleDaysFromPreviousYear = moment(firstDayOfCalendarYear).isoWeekday();
        const numberOfVisibleDaysFromNextYear = 6 - moment(lastDayOfCalendarYear).isoWeekday();

        this.startAt = moment(firstDayOfCalendarYear).subtract(numberOfVisibleDaysFromPreviousYear, 'days').toDate();
        this.endAt = moment(lastDayOfCalendarYear).add(numberOfVisibleDaysFromNextYear, 'days').toDate();

        this.days = this.eachDay(this.startAt, this.endAt).map((date) => new Day(date, this.year));
    }

    private eachDay(startAt: Date, endAt: Date): Date[] {
        const dayDiff = moment(endAt).diff(moment(startAt), 'days');
        const days: Date[] = [];
        for (let i = 0; i <= dayDiff; i++) {
            days.push(moment(startAt).add(i, 'days').toDate());
        }
        return days;
    }

    private makeCalendarDaysSquare(): void {
        const year = $(this.element.nativeElement).find('.year');
        const daysOfTheWeek = $(this.element.nativeElement).find('.days-of-the-week');
        const day = $(this.element.nativeElement).find('.day');
        const dayWidth = $(day).outerWidth();
        const weekWidth = dayWidth * 7;
        const yearHeight = weekWidth;
        $(year).height(yearHeight);
        $(daysOfTheWeek).height(yearHeight);
        $(year).animate({ opacity: 1 });
    }

    private addEventsToCalendar(calendarEvents: TimeOffRequestCalendarEvent[], timeOffDays: TimeOffDay[]): void {
        calendarEvents.forEach((calendarEvent) => {
            const diffInDays = Math.abs(moment(calendarEvent.startAt).diff(moment(calendarEvent.endAt), 'day'));
            // If the event / request is more than one day we have to 1 to the range since it's zero indexed based
            const rangeExtender = diffInDays > 0 ? 1 : 0;
            const days = numericRange(diffInDays + rangeExtender);

            // In case it's a single day time off we need to add an element to the range
            if (days.length === 0) {
                days.push(0);
            }

            days.forEach((offset) => {
                const startDate = moment(calendarEvent.startAt).add(offset, 'day').toDate();

                const day = this.days.find(
                    (day) =>
                        moment(day.date).format(TimeOffDateFormatDashes) ===
                        moment(startDate).format(TimeOffDateFormatDashes)
                );

                if (!day) {
                    return;
                }

                const [timeOffDay] = timeOffDays.filter((timeOffDay) =>
                    moment(timeOffDay.date).isSame(moment(day.date))
                );

                if (timeOffDay) {
                    day.makeHoliday(timeOffDay.toHolidayCalendarEvent());
                }

                day.injectEvent(calendarEvent);
            });
        });

        this.ngZone.runOutsideAngular(() => {
            $(window).resize(() => {
                this.makeCalendarDaysSquare();
            });
            this.makeCalendarDaysSquare();
        });
    }
}
