import { SimpleEntityActions } from '@app/classes/simple-entity-actions.class';
import { PillType } from '@app/components/platform/pill/types';
import { StatusColors } from '@app/modules/performance/enums/status-colors';
import { StatusPillTypes } from '@app/modules/performance/enums/status-pill-types';
import { Model } from '@models/core/base.model';
import { Employee } from '@models/employee/employee.model';
import { GoalAssignment } from '@models/performance/goal-assignment.model';
import { GoalUpdate } from '@models/performance/goal-update.model';
import { Status } from '../../modules/performance/enums/status.enum';

export class Goal extends Model {
    protected static _resource = 'performanceManagement/goals';

    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt', 'startAt', 'dueAt', 'completedAt'];

    protected static _serializeAttributes = [
        'attach',
        'detach',
        'isShared',
        'title',
        'description',
        'metricType',
        'metricValue',
        'metricMilestones',
        'reoccuring',
        'startAt',
        'dueAt',
        'completedAt',
    ];

    static permission = new SimpleEntityActions('goal');

    get updates(): GoalUpdate[] {
        return this.hasMany(GoalUpdate, 'updates');
    }

    get creator(): Employee {
        return this.hasOne(Employee, 'creator');
    }

    get userIsParticipant(): boolean {
        return this._attributes['userIsParticipant'];
    }

    get individualProgress(): any {
        return this._attributes['individualProgress'];
    }

    get currentMilestones(): any {
        return this._attributes['currentMilestones'];
    }

    set currentMilestones(val: any) {
        this._attributes['currentMilestones'] = val;
    }

    get assignments(): GoalAssignment[] {
        return this.hasMany(GoalAssignment, 'assignments');
    }

    set assignments(val: GoalAssignment[] | null) {
        this.setMany('assignments', val);
    }

    get participants(): Employee[] {
        return this.hasMany(Employee, 'participants');
    }

    set participants(val: Employee[] | null) {
        this.setMany('participants', val);
    }

    /* Timestamps */
    get createdAt(): Date {
        return this._attributes['createdAt'];
    }

    get updatedAt(): Date {
        return this._attributes['updatedAt'];
    }

    get deletedAt(): Date {
        return this._attributes['deletedAt'] || null;
    }

    /**
     * Ids
     */
    get companyId(): number {
        return this._attributes['companyId'];
    }

    get createdBy(): number {
        return this._attributes['createdBy'];
    }

    get reoccuringId(): number {
        return this._attributes['reoccuringId'];
    }

    /**
     * Dates
     */
    get startAt(): Date {
        return this._attributes['startAt'];
    }

    set startAt(value: Date) {
        this._attributes['startAt'] = value;
    }

    get dueAt(): Date {
        return this._attributes['dueAt'];
    }

    set dueAt(value: Date) {
        this._attributes['dueAt'] = value;
    }

    get completedAt(): Date {
        return this._attributes['completedAt'];
    }

    set completedAt(value: Date) {
        this._attributes['completedAt'] = value;
    }

    get isShared(): boolean {
        return this._attributes['isShared'];
    }

    set isShared(val: boolean) {
        this._attributes['isShared'] = val;
    }

    get isComplete(): boolean {
        return this._attributes['isComplete'];
    }

    get title(): string {
        return this._attributes['title'];
    }

    set title(value: string) {
        this._attributes['title'] = value;
    }

    get description(): string {
        return this._attributes['description'];
    }

    set description(value: string) {
        this._attributes['description'] = value;
    }

    get reoccuring(): string {
        return this._attributes['reoccuring'];
    }
    set reoccuring(val: string) {
        this._attributes['reoccuring'] = val;
    }

    get metricType(): string {
        return this._attributes['metricType'];
    }

    set metricType(value: string) {
        this._attributes['metricType'] = value;
    }

    get metricValue(): number {
        return this._attributes['metricValue'];
    }

    set metricValue(value: number) {
        this._attributes['metricValue'] = value;
    }

    get metricMilestones(): any[] {
        return this._attributes['metricMilestones'] || [];
    }

    set metricMilestones(value: any[]) {
        this._attributes['metricMilestones'] = value;
    }

    get dueIn(): number {
        return this._attributes['dueIn'];
    }

    get sharedMetricValue(): number {
        return this._attributes['sharedMetricValue'];
    }

    get currentValue(): number {
        return this._attributes['currentValue'];
    }

    get statusId(): number {
        return this._attributes['statusId'];
    }
    get status(): string {
        return Status[this.statusId].replace(/([A-Z])/g, ' $1');
    }

    get statusPillType(): PillType {
        return StatusPillTypes[Status[this.statusId]];
    }

    get statusColor(): string {
        return StatusColors[Status[this.statusId]];
    }
}
