/* eslint-disable no-restricted-globals */
/**
 * Polyfill for requestAnimationFrame.
 *
 * @param {Function} callback The function to call when it's time.
 * @returns {number}
 */
export function requestAnimationFrame(callback) {
  return window.requestAnimationFrame(callback);
}

/**
 * Polyfill for cancelAnimationFrame.
 *
 * @param {number} id The request Id, generated by `requestAnimationFrame`.
 */
export function cancelAnimationFrame(id) {
  window.cancelAnimationFrame(id);
}

/**
 * @returns {boolean}
 */
export function isTouchSupported() {
  return 'ontouchstart' in window;
}

/**
 * Checks if the environment that the code runs in is a browser.
 *
 * @returns {boolean}
 */
export function isCSR() {
  return typeof window !== 'undefined';
}
let comparisonFunction;

/**
 * Get string comparison function for sorting purposes. It supports multilingual string comparison base on Internationalization API.
 *
 * @param {string} [language] The language code used for phrases sorting.
 * @param {object} [options] Additional options for sort comparator.
 * @returns {*}
 */
export function getComparisonFunction(language) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (comparisonFunction) {
    return comparisonFunction;
  }
  if (typeof Intl === 'object') {
    comparisonFunction = new Intl.Collator(language, options).compare;
  } else if (typeof String.prototype.localeCompare === 'function') {
    comparisonFunction = (a, b) => `${a}`.localeCompare(b);
  } else {
    comparisonFunction = (a, b) => {
      if (a === b) {
        return 0;
      }
      return a > b ? -1 : 1;
    };
  }
  return comparisonFunction;
}