import { Model } from '@app/interfaces';

import { CellDisplay } from '../enums/cell-display.enum';
import { PillDisplay } from '../interfaces/pill-display.interface';
import { Column } from './column.class';
import { Translatable } from '@app/types/translatable.type';

export abstract class PillColumn<T extends Model> extends Column {
    type = CellDisplay.pill;

    /**
     * Return a PillDisplay type with the text that should be within the pill,
     * and the pill type to use.
     *
     * If undefined is returned, no pill will show.
     */
    abstract getPillDisplay(row: T): PillDisplay | undefined;

    /**
     * Cell text can appear beside the pill if necessary. If so, the pill will float a bit
     * to the right of the cell text.
     */
    getCellText(_: T): Translatable | null {
        return null;
    }
}
