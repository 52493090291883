import { SimpleEntityActions } from '@app/classes/simple-entity-actions.class';
import { PillType } from '@app/components/platform/pill/types';
import { StatusColors } from '@app/modules/performance/enums/status-colors';
import { StatusPillTypes } from '@app/modules/performance/enums/status-pill-types';
import { Model } from '@models/core/base.model';
import { Employee } from '@models/employee/employee.model';
import { ReviewAssignment } from '@models/performance/review-assignment.model';
import { ReviewResponse } from '@models/performance/review-response.model';
import { Template } from '@models/performance/template.model';
import { Status } from '../../modules/performance/enums/status.enum';

export class Review extends Model {
    protected static _resource = 'performanceManagement/reviews';

    protected static _version = 'v2';

    protected static _datetimes = [
        'createdAt',
        'updatedAt',
        'deletedAt',
        'periodStart',
        'periodEnd',
        'dueAt',
        'scheduleAt',
        'completedAt',
    ];

    static permission = new SimpleEntityActions('review');

    // We aren't automatially serializing this data in ReviewService.onSave
    // be mindful when adding attributes here.
    protected static _serializeAttributes = [
        'attach',
        'description',
        'detach',
        'dueAt',
        'periodEnd',
        'periodStart',
        'reoccuring',
        'scheduleAt',
        'subjectId',
        'templateId',
        'instruction',
        'title',
        'note',
        'subjectCanViewResults',
        'participantsCanViewResults',
        'viewResultsBeforeCompletion',
        'subjectCanViewNote',
        'participantsCanViewNote',
    ];

    static maxCharactersForNote: number = 900;

    get isReady(): boolean {
        return this._attributes['isReady'];
    }

    get subjectCanViewResults(): boolean {
        return this._attributes['subjectCanViewResults'];
    }
    set subjectCanViewResults(val: boolean) {
        this._attributes['subjectCanViewResults'] = val;
    }
    get participantsCanViewResults(): boolean {
        return this._attributes['participantsCanViewResults'];
    }
    set participantsCanViewResults(val: boolean) {
        this._attributes['participantsCanViewResults'] = val;
    }

    get viewResultsBeforeCompletion(): boolean {
        return this._attributes['viewResultsBeforeCompletion'];
    }
    set viewResultsBeforeCompletion(val: boolean) {
        this._attributes['viewResultsBeforeCompletion'] = val;
    }

    get userIsParticipant(): boolean {
        return this._attributes['userIsParticipant'];
    }
    get userAssignment(): any {
        return this._attributes['userAssignment'];
    }
    get userResponse(): ReviewResponse | null {
        return this.hasOne(ReviewResponse, 'userResponse');
    }
    set userResponse(val: ReviewResponse | null) {
        this.setOne('userResponse', val);
    }

    get subject(): Employee {
        return this.hasOne(Employee, 'subject');
    }
    set subject(val: Employee) {
        this.setOne('subject', val, 'subjectId');
    }

    get creator(): Employee {
        return this.hasOne(Employee, 'creator');
    }
    set creator(val: Employee) {
        this.setOne('creator', val);
    }

    get assignments(): ReviewAssignment[] {
        return this.hasMany(ReviewAssignment, 'assignments');
    }
    set assignments(val: ReviewAssignment[]) {
        this.setMany('assignments', val);
    }

    get responses(): ReviewResponse[] {
        return this.hasMany(ReviewResponse, 'responses');
    }

    get participants(): Employee[] {
        return this.hasMany(Employee, 'participants');
    }

    set participants(val: Employee[] | null) {
        this.setMany('participants', val);
    }

    /* Timestamps */
    get createdAt(): Date {
        return this._attributes['createdAt'];
    }

    get updatedAt(): Date {
        return this._attributes['updatedAt'];
    }

    get deletedAt(): Date {
        return this._attributes['deletedAt'] || null;
    }

    /**
     * Ids
     */
    get companyId(): number {
        return this._attributes['companyId'];
    }

    get createdBy(): number {
        return this._attributes['createdBy'];
    }

    get reoccuringId(): number {
        return this._attributes['reoccuringId'];
    }

    get templateId(): number {
        return this._attributes['templateId'];
    }
    set templateId(val: number) {
        this._attributes['templateId'] = val;
    }

    get subjectId(): number {
        return this._attributes['subjectId'];
    }
    set subjectId(val: number) {
        this._attributes['subjectId'] = val;
    }

    /**
     * Dates
     */
    get periodStart(): Date {
        return this._attributes['periodStart'];
    }

    set periodStart(value: Date) {
        this._attributes['periodStart'] = value;
    }

    get periodEnd(): Date {
        return this._attributes['periodEnd'];
    }

    set periodEnd(value: Date) {
        this._attributes['periodEnd'] = value;
    }

    get dueAt(): Date {
        return this._attributes['dueAt'];
    }

    set dueAt(value: Date) {
        this._attributes['dueAt'] = value;
    }

    get scheduleAt(): Date {
        return this._attributes['scheduleAt'];
    }

    set scheduleAt(value: Date) {
        this._attributes['scheduleAt'] = value;
    }

    get completedAt(): Date {
        return this._attributes['completedAt'];
    }

    set completedAt(value: Date) {
        this._attributes['completedAt'] = value;
    }

    /**
     * Title
     */
    get title(): string {
        return this._attributes['title'];
    }

    set title(value: string) {
        this._attributes['title'] = value;
    }

    get instruction(): string {
        return this._attributes['instruction'];
    }

    set instruction(value: string) {
        this._attributes['instruction'] = value;
    }

    get note(): string {
        return this._attributes['note'];
    }
    set note(value: string) {
        this._attributes['note'] = value;
    }

    get subjectCanViewNote(): boolean {
        return this._attributes['subjectCanViewNote'];
    }
    set subjectCanViewNote(val: boolean) {
        this._attributes['subjectCanViewNote'] = val;
    }

    get participantsCanViewNote(): boolean {
        return this._attributes['participantsCanViewNote'];
    }
    set participantsCanViewNote(val: boolean) {
        this._attributes['participantsCanViewNote'] = val;
    }

    /**
     * Reoccurring
     */
    get reoccuring(): string {
        return this._attributes['reoccuring'];
    }
    set reoccuring(val: string) {
        this._attributes['reoccuring'] = val;
    }

    /**
     * Score
     */
    get score(): number {
        return this._attributes['score'];
    }

    set score(value: number) {
        this._attributes['score'] = value;
    }

    /**
     * Cache Team Score
     */
    get cacheTeamScore(): number {
        return this._attributes['cacheTeamScore'];
    }

    set cacheTeamScore(value: number) {
        this._attributes['cacheTeamScore'] = value;
    }

    /**
     * Is Complete
     */
    get isComplete(): boolean {
        return this._attributes['isComplete'];
    }

    /**
     * Overdue
     */
    get overdue(): boolean {
        return this._attributes['overdue'];
    }

    /**
     * Status
     */
    get statusId(): number {
        return this._attributes['statusId'];
    }
    get status(): string {
        return Status[this.statusId].replace(/([A-Z])/g, ' $1');
    }

    get statusPillType(): PillType {
        return StatusPillTypes[Status[this.statusId]];
    }

    get statusColor(): string {
        return StatusColors[Status[this.statusId]];
    }

    get template(): Template {
        return this.hasOne(Template, 'template');
    }

    force(): Promise<any> {
        return Model.performGet(Review._version + '/' + Review._resource + '/' + this.id + '/force');
    }

    remind(): Promise<any> {
        return Model.performGet(Review._version + '/' + Review._resource + '/' + this.id + '/remind');
    }

    employeeIsParticipant(employee: Employee): boolean {
        return this.participants.some((participant) => participant.is(employee));
    }

    employeeIsSubject(employee: Employee): boolean {
        return this.subject.is(employee);
    }

    getResponseAccessMessage(futureTense = false): string {
        const labels = this.getResponseAccessLabels();
        const last = labels.pop();
        const tense = futureTense ? 'will be' : 'are';
        if (!labels.length) {
            return 'Review responses ' + tense + ' visible to ' + last + '.';
        }
        return 'Review responses ' + tense + ' visible to ' + labels.join(', ') + ' and ' + last + '.';
    }

    /**
     * List of types of user who can view the results, based on settings and if subject has a manager
     */
    protected getResponseAccessLabels(): string[] {
        const name = this.subject?.firstName;
        const managerLabel = this.subject?.reportsTo ? name + "'s manager" : null;

        let result = [];
        if (this.subjectCanViewResults && this.participantsCanViewResults) {
            result = [name, managerLabel, 'admins', 'other participants'];
        } else if (this.subjectCanViewResults) {
            result = [name, managerLabel, 'admins'];
        } else if (this.participantsCanViewResults) {
            result = [managerLabel, 'admins', 'other participants'];
        } else {
            result = [managerLabel, 'admins'];
        }
        // if subject has no manager, remove null from set
        return result.filter((e: string | null) => e !== null);
    }
}
