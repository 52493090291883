import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorParser } from '@app/classes/error-parser.class';
import { FeatureFlag } from '@app/enums';
import { AuthService, FileHelperService, NotifyService } from '@app/services';
import { FeatureService } from '@app/services/feature.service';
import { PayrollResources } from '../payroll.resources';

@Injectable()
export class ImportAdditionalIncomesService {
    constructor(
        private auth: AuthService,
        private fileHelper: FileHelperService,
        private http: HttpClient,
        private notify: NotifyService,
        private featureService: FeatureService
    ) {}

    async downloadTemplate(payrollId: number): Promise<void> {
        const filename = await this.getfileName();
        const path = await this.getDownloadPath(payrollId);
        return new Promise((resolve, reject) => {
            this.fileHelper.savePayrollFile(path, filename, 'csv', null, (e?: Record<string, unknown>) => {
                if (e) {
                    this.notify.error(ErrorParser.parse(e));
                    reject(e);
                } else {
                    resolve();
                }
            });
        });
    }

    async uploadAdditionalIncomes(payrollId: number, file: File): Promise<void> {
        const uploadAdditionalIncomeData = new FormData();
        uploadAdditionalIncomeData.append('file', file);
        const url = PayrollResources.UploadAdditionalIncomes.replace(
            ':companyId',
            this.auth.company.id.toString()
        ).replace(':payrollId', payrollId.toString());
        const uploadAdditionalIncomesResultPromise = await this.http.post(url, uploadAdditionalIncomeData).toPromise();
        const uploadAdditionalIncomesResult = uploadAdditionalIncomesResultPromise['data']['attributes'];
        this.notify.success(uploadAdditionalIncomesResult?.message || 'Successfully uploaded employee incomes');
    }

    private async getDownloadPath(payrollId: number): Promise<string> {
        return `${this.auth.company.id}/payrolls/${payrollId}/downloadAdditionalIncomes`;
    }

    private async getfileName(): Promise<string> {
        if (await this.featureService.has(FeatureFlag.reimbursementOnIncomeImporter)) {
            return 'dollars_template';
        }

        return 'income_template';
    }
}
