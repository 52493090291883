import { CalendarEvent } from '@models/common/calendar-event.model';

export class HolidayCalendarEvent extends CalendarEvent {
    protected static _resource = 'timeOff/companies/:company/employees/:employee/holidays';
    eventType = 'holiday';

    hasToolTip = false;
    color = {
        background: '#ffffff',
        text: '#2693ff',
        hoverBackground: '#2693ff',
        hoverText: '#ffffff',
    };

    hasHover = false;
}
