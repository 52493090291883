<div class="wrapper">
    <div class="menu only-small">
        <div (click)="onToggleMenu()">
            <i class="far fa-bars fa-fw"></i>
        </div>
    </div>

    <app-company-selector />

    <div class="right-container">
        <!-- Beamer -->
        <div class="beamer hide-small" [class.show-unread]="showBeamerUnread">
            <mat-icon svgIcon="rocket"></mat-icon>
        </div>

        <!-- Employee Search -->
        <div class="search-container hide-small">
            <a class="icon" (click)="onOpenSearch(); employeeSearch.focus()">
                <mat-icon svgIcon="search"></mat-icon>
            </a>

            <!-- There is a 200ms delay attached to this autocomplete panel opening.
            This is because there is a 200ms css width transition on ui-employee-autocomplete.showing.
            If the panel attempts to open prior to the animation completing, it will calculate the starting position of the panel based on that of the un-expanded search bar
            This value should always be equal to $transition found in the _variables.scss file
            -->
            <ui-employee-autocomplete
                #employeeSearch
                (blur)="onCloseSearch()"
                [class.showing]="showSearch"
                [ngModel]="null"
                [customQuery]="employeeSearchCustomQuery"
                (ngModelChange)="goToEmployeeProfile($event); employeeSearch.clear()"
                placeholder="{{ 'searchPeople' | translate }}"
                [delayOpen]="200"
            ></ui-employee-autocomplete>
        </div>

        <!-- Calendar Link -->
        <div *ngIf="canAccessCalendar" class="calendar-link hide-small">
            <a routerLink="/calendar/company/time-off">
                <mat-icon svgIcon="calendar"></mat-icon>
            </a>
        </div>

        <!-- User Roles Menu -->
        <div class="employee" [matMenuTriggerFor]="userMenu">
            <div class="name-and-role-container">
                <!-- Limiting this to 50 characters. Currently the longest name in the system is 44 characters, but you never know. -->
                <!-- A long name could break the UI -->
                <h4>{{ employee.firstName | limitTo: 50 }}</h4>
                <span class="employee-role" *ngIf="currentCompanyTokenRoles.length > 1">
                    {{ currentTokenRole.roleName | limitTo: 30 }}
                </span>
            </div>
            <span class="dropdown-icon"><mat-icon svgIcon="chevronDown"></mat-icon></span>
        </div>

        <mat-menu #userMenu xPosition="before">
            <div class="only-small mobile-menu-items">
                <button *ngIf="canAccessCalendar" mat-menu-item>
                    <a routerLink="/calendar/company/time-off">{{ 'topbar.menu.calendar' | translate }}</a>
                </button>
                <button class="beamer" mat-menu-item>{{ 'topbar.menu.whatsNew' | translate }}</button>
            </div>
            <button [matMenuTriggerFor]="rolesMenu" mat-menu-item [disabled]="!hasMultipleRolesAtCurrentCompany">
                {{ 'roles' | translate }}
            </button>
            <button [matMenuTriggerFor]="languageMenu" mat-menu-item *ngIf="hasLanguageToggle">
                {{ 'language' | translate }}
            </button>
            <button mat-menu-item *ngIf="hasAsyncSystemTasksFlag">
                <a routerLink="/backgroundJobs">{{ 'backgroundJobs' | translate }}</a>
            </button>
            <button mat-menu-item *ngIf="hasTranslationDebugFlag">
                <mat-slide-toggle
                    [ngModel]="translationsDebugEnabled"
                    (ngModelChange)="toggleTranslationDebug()"
                    (click)="$event.stopImmediatePropagation()"
                    >Debug Translations</mat-slide-toggle
                >
            </button>
            <button mat-menu-item *ngIf="hasTranslationDebugFlag">
                <mat-slide-toggle
                    [ngModel]="transifexEnabled"
                    (ngModelChange)="toggleTransifex()"
                    (click)="$event.stopImmediatePropagation()"
                    >Transifex Enabled</mat-slide-toggle
                >
            </button>
            <button mat-menu-item (click)="logout()">{{ 'logout' | translate }}</button>
        </mat-menu>

        <mat-menu xPosition="before" #rolesMenu>
            <button
                mat-menu-item
                *ngFor="let tokenRole of currentCompanyTokenRoles"
                (click)="changeRole(tokenRole.roleId)"
                [disabled]="currentTokenRole.roleId === tokenRole.roleId"
            >
                {{ tokenRole.roleName }}
            </button>
        </mat-menu>

        <mat-menu xPosition="before" #languageMenu>
            <button
                mat-menu-item
                *ngFor="let language of languages | keys"
                (click)="chooseLanguage(language.value)"
                [disabled]="employee.language === language.value"
            >
                {{ language.key | appTranslate }}
            </button>
        </mat-menu>
    </div>
</div>
