import { Chippable } from '@app/interfaces';
import { Model } from '../core/base.model';

export class DocumentTag extends Model implements Chippable {
    protected static _resource = 'documentTags';
    protected static _version = 'v2';
    protected static _serializeAttributes = ['name'];
    protected static _type = 'documentTags';

    static async saveNewTags(documentTags: DocumentTag[]): Promise<DocumentTag[]> {
        return Promise.all(documentTags.map((documentTag: DocumentTag): Promise<DocumentTag> => documentTag.save()));
    }

    get name(): string | null {
        return this._attributes['name'] || null;
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get companyId(): number {
        return this._attributes['companyId'];
    }

    get chipLabel(): string {
        return this.name;
    }
}
