import { ApiValidationService } from '@app/services/api-validation.service';
import { ApprovalInstanceService } from '@app/services/approvals/approval-instance.service';
import { ApprovalWorkflowService } from '@app/services/approvals/approval-workflow.service';
import { AudioFileService } from '@app/services/audio-file.service';
import { DragService } from '@app/services/drag.service';
import { UserAccessService } from '@app/services/user-access.service';
import { AbilityService } from './ability.service';
import { AnalyticService } from './analytic.service';
import { AuthService } from './auth.service';
import { CacheService } from './cache.service';
import { CurrencyService } from './currency.service';
import { DialogService } from './dialog.service';
import { EmployeeFilterPreviewService } from './employee-filter-preview.service';
import { EmployeeModuleAssignmentService } from './employee-module-assignment.service';
import { FeatureAcknowledgementService } from './feature-acknowledgement.service';
import { FileHelperService } from './file-helper.service';
import { HolidaysService } from './holidays.service';
import { IconsService } from './icons.service';
import { LanguageService } from './language.service';
import { NotificationService } from './notification.service';
import { NotifyService } from './notify.service';
import { OfferLetterSigningService } from './offer-letter-signing.service';
import { PayrollSettingsService } from './payroll-settings.service';
import { PayrollService } from './payroll.service';
import { SettingsService } from './settings/settings.service';
import { SocketService } from './socket.service';
import { TaskSubtasksService } from './task-sub-tasks.service';
import { TaskTemplateSubtasksService } from './task-template-sub-tasks.service';
import { TasksService } from './tasks.service';
import { TokenService } from './token.service';
import { TranslationStorageService } from './translation-storage.service';
import { UrlHelperService } from './url-helper.service';
import { ZenDeskService } from './zendesk.service';

/** Order is important, do not reshuffle these */
const CoreServices = [TokenService, AuthService, AnalyticService];

const OtherServices = [
    CacheService,
    CurrencyService,
    AbilityService,
    FileHelperService,
    HolidaysService,
    IconsService,
    NotificationService,
    NotifyService,
    PayrollService,
    SocketService,
    UrlHelperService,
    ApiValidationService,
    UserAccessService,
    SettingsService,
    PayrollSettingsService,
    EmployeeFilterPreviewService,
    OfferLetterSigningService,
    TasksService,
    TaskSubtasksService,
    TaskTemplateSubtasksService,
    FeatureAcknowledgementService,
    TranslationStorageService,
    DragService,
    ApprovalInstanceService,
    ApprovalWorkflowService,
    AudioFileService,
    LanguageService,
    DialogService,
    EmployeeModuleAssignmentService,
    ZenDeskService,
];

export {
    AbilityService,
    AnalyticService,
    ApiValidationService,
    ApprovalInstanceService,
    ApprovalWorkflowService,
    AudioFileService,
    AuthService,
    CacheService,
    CoreServices,
    CurrencyService,
    DialogService,
    DragService,
    EmployeeFilterPreviewService,
    EmployeeModuleAssignmentService,
    FeatureAcknowledgementService,
    FileHelperService,
    HolidaysService,
    IconsService,
    LanguageService,
    NotificationService,
    NotifyService,
    OfferLetterSigningService,
    OtherServices,
    PayrollService,
    PayrollSettingsService,
    SettingsService,
    SocketService,
    TaskSubtasksService,
    TaskTemplateSubtasksService,
    TasksService,
    TokenService,
    TranslationStorageService,
    UrlHelperService,
    UserAccessService,
    ZenDeskService,
};
