import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { PillColumn } from '@app/modules/table/classes/pill-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { PillDisplay } from '@app/modules/table/interfaces/pill-display.interface';
import { TimeOffv3StatusDisplayOptions } from '@app/types/translatables/time-off-v3-display.options';
import { TimeOffRequest } from '@models/time-off-v3/time-off-request.model';

export class StatusColumn extends PillColumn<TimeOffRequest> {
    title = 'time-off-v3.status';
    property = 'status';
    sortField = 'status';
    sortable = true;
    type: CellDisplay = CellDisplay.pill;

    constructor() {
        super();

        const options = [
            { label: 'time-off-v3.pending', value: 'pending' },
            { label: 'time-off-v3.approved', value: 'approved' },
            { label: 'time-off-v3.denied', value: 'denied' },
        ];

        this.filter = new CheckListFilter('status', options, this.title);
    }

    getPillDisplay(request: TimeOffRequest): PillDisplay | undefined {
        return {
            type: request.getPillType(),
            label: TimeOffv3StatusDisplayOptions[request.status] ?? request.status.toUpperCase(),
        };
    }
}
