import { CalendarEvent } from '@models/common/calendar-event.model';
import { Moment } from 'moment';
import { Colors } from '@app/constants/platform-colors';

interface TimeOffDayPayload {
    description?: string;
    startAt: Moment | Date;
    endAt: Moment | Date;
}

export class TimeOffDayCalendarEvent extends CalendarEvent {
    eventType = 'day';
    hasToolTip = false;
    hasHover = false;

    color = {
        background: Colors.white,
        text: Colors.primary,
        hoverBackground: Colors.white,
        hoverText: Colors.primary,
    };

    private payload: TimeOffDayPayload;

    constructor(res: TimeOffDayPayload) {
        super(res);
        this.payload = res;
    }

    get monthCalendarTitle(): string {
        return this.payload.description;
    }
}
