import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DialogConfig } from '@app/services/dialog.service';
import { TranslatableKey } from '@app/types/translatable.type';
import { Subscription } from 'rxjs';
import { ButtonTypes } from '../button/button.component';
import { overlayAnimationTime } from '../overlay.component';
import { PillType } from '../pill/types';
import { Sizes } from './dialog.types';

// Ideally we would use this format to reduce the many many inputs in this component
// It's only used in the Header Button currently, but is a possible future state for all buttons
export type ButtonConfig = {
    label: string;
    onPress: ($event?: Event) => void;
    type?: ButtonTypes;
};

// For now, Input is all we want to submit on. Subject to change.
const submittableHtmlTags = ['INPUT'];

/**
 * Dialog component that can be used to display a dialog with a header, content and footer.
 *
 * If you are looking for a simple confirmation dialog, use the ConfirmationDialogComponent instead.
 * ConfirmationDialogComponent is a wrapper around this component, but it allows for awaiting the result of the dialog.
 */
@Component({
    selector: 'ui-dialog',
    styleUrls: ['./dialog.styles.scss'],
    template: `
        <ng-template #customFooter>
            <ng-content select="ui-dialog-footer"></ng-content>
        </ng-template>

        <ui-overlay [scrollable]="scrollable" class="dialog-wrapper flex" (onClick)="closeDialog()" [open]="open">
            <div
                tabIndex="0"
                [ngClass]="size"
                class="{{ stickyButtons || stickyHeader ? 'sticky-content' : 'padding-4' }} flex col dialog"
                role="dialog"
                aria-labelledby="dialog-header"
                #dialog
            >
                <div
                    *ngIf="showHeader"
                    class="flex align-items-center justify-space-between margin-bottom-3 dialog-header {{
                        stickyHeader ? 'sticky-header' : ''
                    }}"
                >
                    <h2
                        id="dialog-header"
                        class="margin-bottom-0 margin-right-auto"
                        [class.header-padding]="applyHeaderPadding"
                    >
                        {{ header | appTranslate }}
                        <ui-pill class="margin-left-2" *ngIf="headerPill" [type]="headerPill.type">
                            {{ headerPill.translationKey | translate }}
                        </ui-pill>
                    </h2>

                    <ng-container *ngIf="headerButton">
                        <ui-button
                            (click)="$event.preventDefault(); headerButton.onPress()"
                            [type]="headerButton.type ?? 'link'"
                            >{{ headerButton.label | appTranslate }}</ui-button
                        >
                    </ng-container>

                    <ng-container *ngIf="meatballMenu">
                        <ui-button type="ghost" [matMenu]="contextMatMenu">
                            <mat-icon svgIcon="meatballHorizontal"></mat-icon>
                        </ui-button>
                        <mat-menu #contextMatMenu>
                            <ng-container [ngTemplateOutlet]="dropdownMenu"></ng-container>
                        </mat-menu>
                        <ng-template #dropdownMenu>
                            <ng-content select=".meatballMenuItems"></ng-content>
                        </ng-template>
                    </ng-container>

                    <button
                        type="button"
                        class="closeButton"
                        tabIndex="0"
                        (click)="$event.preventDefault(); closeDialog()"
                        *ngIf="isCloseable && !meatballMenu"
                    >
                        <mat-icon svgIcon="close"></mat-icon>
                    </button>
                </div>

                <ng-content></ng-content>

                <ui-dialog-footer
                    class="{{ stickyButtons ? 'sticky-buttons' : '' }}"
                    *ngIf="primaryButtonLabel || secondaryButtonLabel; else customFooter"
                >
                    <ui-button
                        *ngIf="secondaryButtonLabel"
                        (click)="$event.preventDefault(); onSecondaryButtonPressed()"
                        [type]="secondaryButtonType"
                        >{{ secondaryButtonLabel | appTranslate }}</ui-button
                    >

                    <ui-button
                        [submit]="true"
                        *ngIf="primaryButtonLabel"
                        (click)="$event.preventDefault(); onPrimaryButtonPressed()"
                        [disabled]="actionsLocked"
                        [type]="primaryButtonType"
                        >{{ primaryButtonLabel | appTranslate }}</ui-button
                    >
                </ui-dialog-footer>
            </div>
        </ui-overlay>
    `,
})
export class DialogComponent implements OnInit {
    @Input() size: Sizes = 'medium';
    @Input() form?: NgForm;
    @Input() primaryButtonLabel: string | null = null;
    @Input() primaryButtonType: ButtonTypes = 'primary';
    @Input() secondaryButtonLabel: string | null = null;
    @Input() secondaryButtonType: ButtonTypes = 'ghost';
    @Input() primaryButtonDisabledTimeout: undefined | number = undefined;
    @Input() scrollable = false;
    @Input() isCloseable = true;
    @Input() stickyButtons = false;
    @Input() stickyHeader = false;
    @Input() applyHeaderPadding = false;
    @Input() meatballMenu = false;
    @Input() headerButton?: ButtonConfig;
    @Input() headerPill?: { type: PillType; translationKey: TranslatableKey };
    @Input() showHeader = true;

    @Output() primaryButtonPressed: EventEmitter<void> = new EventEmitter<void>();
    @Output() secondaryButtonPressed: EventEmitter<void> = new EventEmitter<void>();

    @Output() closing = new EventEmitter();
    @Output() opening = new EventEmitter();

    @ViewChild('dialog') dialog: ElementRef<HTMLDivElement>;

    _actionsLocked = false;
    _header: string | undefined;
    _open = false;
    formChangesSubscription?: Subscription;

    @Input() set actionsLocked(isLocked: boolean) {
        this._actionsLocked = isLocked;
        // Allow actions to become unlocked again after time has passed
        if (isLocked && this.primaryButtonDisabledTimeout) {
            setTimeout(() => {
                this._actionsLocked = false;
            }, this.primaryButtonDisabledTimeout);
        }
    }

    get actionsLocked(): boolean {
        return this._actionsLocked;
    }

    constructor(
        protected elementRef: ElementRef,
        private trapFocus: ConfigurableFocusTrapFactory
    ) {}

    @Input() set dialogConfig(config: DialogConfig) {
        Object.assign(this, config);
    }

    @Input() set header(str: string) {
        this._header = str;
    }
    get header(): string {
        return this._header;
    }

    @Input() set open(x: boolean) {
        this._open = x;

        if (this._open) {
            this.subscribeToFormChanges();
            // Need to unlock actions when the dialog opens.
            this.actionsLocked = false;
            // Perform focus locking as animation ends
            setTimeout(() => {
                this.onOpening();
            }, overlayAnimationTime);
            return;
        }

        this.closeDialog(false);
    }
    get open(): boolean {
        return this._open;
    }

    /**
     * We want to "submit" the dialog when there is specific element focused
     *   > ie: user opens a confirmation dialog, hits enter
     * We want to "submit" the dialog when the user hits enter within an input
     * element in a form, but *not* a text area.
     */
    @HostListener('window:keydown.enter', ['$event'])
    handleKey(): void {
        const focusedElement = this.elementRef.nativeElement.querySelector(':focus');
        const dialogElement = this.dialog.nativeElement;
        const dialogIsFocused = focusedElement === dialogElement;

        if (dialogIsFocused || submittableHtmlTags.includes(focusedElement?.tagName)) {
            this.onPrimaryButtonPressed();
        }
    }

    ngOnInit(): void {
        if (this.header === undefined) {
            throw new Error(
                `@Input \`header\` is required.  Make sure the check the implementation of your component for a [heading] input`
            );
        }
    }

    closeDialog(emit = true): void {
        this.unSubscribeFromFormChanges();
        this.onClosing();
        this.actionsLocked = true;
        if (emit && this.isCloseable) {
            this.closing.emit();
        }
    }

    onPrimaryButtonPressed(): void {
        // Prevent successive triggering of submission
        if (this.actionsLocked) {
            return;
        }

        this.primaryButtonPressed.emit();

        // If no form, limit to one possible submission
        if (!this.form) {
            this.actionsLocked = true;
            return;
        }

        // Validate the form
        this.form.onSubmit(null);
        // if the form is valid, lock the submission to prevent multiple saves and such
        this.actionsLocked = this.form.valid;
    }

    onSecondaryButtonPressed(): void {
        if (this.secondaryButtonPressed.observers.length === 0) {
            this.closeDialog(true);
            return;
        }

        this.secondaryButtonPressed.emit();
    }

    // If the form becomes invalid after initial validation (from an async error), the submit button needs to become unlocked
    private subscribeToFormChanges(): void {
        if (this.form) {
            this.formChangesSubscription = this.form.statusChanges?.subscribe((change: 'VALID' | 'INVALID') => {
                if (change === 'INVALID') {
                    this.actionsLocked = false;
                }
            });
        }
    }

    private unSubscribeFromFormChanges(): void {
        if (this.formChangesSubscription) {
            this.formChangesSubscription.unsubscribe();
            this.formChangesSubscription = undefined;
        }
    }

    private onOpening(): void {
        this.trapFocus.create(this.dialog.nativeElement);
        this.opening.emit();
    }

    private onClosing(): void {
        // do nothing (placeholder)
    }
}
