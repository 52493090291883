import { Component, Optional, Self, ElementRef } from '@angular/core';
import { Country } from '@app/models/common/country.model';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { CountryService } from '@app/services/country.service';
import { BaseAutocompleteComponent, Option as BaseOption } from '../base-autocomplete/base-autocomplete.component';
import { debounceTime, tap } from 'rxjs/operators';
import { NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { FocusMonitor } from '@angular/cdk/a11y';

export interface Option extends BaseOption<string> {
    code: string;
}

@Component({
    selector: 'ui-country-autocomplete',
    templateUrl: './country-autocomplete.template.html',
    styleUrls: ['./country-autocomplete.styles.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: CountryAutocompleteComponent }],
    host: {
        '[id]': 'id',
        '[attr.aria-describedby]': 'describedBy',
        '[attr.required]': 'required',
    },
})
export class CountryAutocompleteComponent extends BaseAutocompleteComponent<string> {
    controlType = 'country-autocomplete';
    id = `country-autocomplete-${CountryAutocompleteComponent.nextId++}`;
    countries: Option[];

    constructor(
        private countryService: CountryService,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() public _parentForm: NgForm,
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        protected elementRef: ElementRef<HTMLElement>,
        protected fm: FocusMonitor
    ) {
        super(ngControl, _parentForm, _defaultErrorStateMatcher, elementRef, fm);
        // Get new results on typing
        const searchControlSub = this.searchControl.valueChanges
            .pipe(
                tap(() => (this.loading = true)),
                debounceTime(500)
            )
            .subscribe((searchTerm: string) => (this.countries ? this._setDisplayOptions(searchTerm) : {}));

        this._subscriptions.push(searchControlSub);
    }

    displayFn = (item: string): string => {
        return item;
    };

    ngOnInit(): void {
        this._inputElement = this.elementRef.nativeElement.querySelector('input');

        this.loading = true;
        const countryServiceSubscription = this.countryService.allCountries.subscribe((countries: Country[]) => {
            this.countries = countries.map((country: Country) => {
                return {
                    label: country.name,
                    code: country.code,
                    value: country.name,
                };
            });
            this._setDisplayOptions();
        });
        this._subscriptions.push(countryServiceSubscription);
    }

    getValueOptionFlagCode(): string | null {
        if (this.value === undefined) {
            return;
        }

        return this.options.find((option: Option) => option.value === this.value)?.code;
    }

    protected _setDisplayOptions(searchTerm = ''): void {
        let countries = this.countries;

        if (searchTerm) {
            countries = countries.filter((country) => country.value.toLowerCase().includes(searchTerm.toLowerCase()));
        }

        this.options = countries;

        this.loading = false;
    }
}
