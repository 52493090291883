<!-- Material menus open under the trigger by default, in this case,
    we want it to appear outside of the sidebar, so we have to use this. -->
<button class="menu-anchor" #rolesMenuTrigger [matMenuTriggerFor]="rolesMenu"></button>

<button class="menu-anchor" #companiesMenuTrigger [matMenuTriggerFor]="companiesMenu"></button>

<div
    (mouseenter)="isCollapseButtonVisible = true"
    (mouseleave)="isCollapseButtonVisible = false"
    class="wrapper"
    role="navigation"
    [attr.aria-label]="sideBarNavigationLabel"
    [class.collapse]="!isDesktopExpanded"
    [class.show-mobile]="isMobileExpanded"
>
    <ng-container>
        <div class="navigation">
            <!-- company name and close button-->
            <div class="header only-small">
                <app-company-selector hideName="never" (toggleMobileCompanyDrawer)="onToggleMobileCompanyDrawer()" />
                <div class="mobile-close" (click)="onToggleMobileDrawer()">
                    <i class="far fa-times fa-fw"></i>
                </div>
                <div class="company-drawer" [ngClass]="getCompanyDrawerExpandedState()">
                    <button
                        mat-button
                        class="nav-item"
                        *ngFor="let tokenRole of uniqueCompanyTokenRoles"
                        (click)="currentTokenRole.companyId !== tokenRole.companyId && changeRole(tokenRole.roleId)"
                        [class.active]="currentTokenRole.companyId === tokenRole.companyId"
                    >
                        <div class="company-drawer-name">
                            <span> {{ tokenRole.companyName }} </span>
                            <mat-icon
                                *ngIf="currentTokenRole.companyId === tokenRole.companyId"
                                svgIcon="check"
                            ></mat-icon>
                        </div>
                    </button>
                </div>
            </div>

            <!-- You can inject your own sidebar content here using the mobile sidenav service -->
            <!-- The assumption is that you will only be replacing the content of the sidebar on mobile, -->
            <!-- but there's nothing stopping you from replacing the sidenav on non-mobile, except good design sense. -->
            <div class="mobile-sidebar-portal">
                <ng-container *cdkPortalOutlet />
            </div>

            <ng-container *ngIf="!portalHasContent">
                <div *ngFor="let navGroup of navigation" class="group">
                    <a
                        *ngFor="let navItem of navGroup"
                        [ngClass]="{ 'flex-this': navItem.showIndicator && navItem.key === 'sidebar.addOns' }"
                        class="nav-item"
                        (click)="isMobile && onToggleMobileDrawer(); trackAddOnsEvent(navItem.key)"
                        [routerLink]="navItem.link"
                        routerLinkActive="active"
                        [target]="navItem.openInNewTab ? '_blank' : null"
                        >{{ navItem.key | translate
                        }}<span
                            *ngIf="
                                (navItem.showIndicator && navItem.key !== 'sidebar.addOns') ||
                                (navItem.key === 'sidebar.home' && hasUnread)
                            "
                            class="unread-marker"
                            attr.aria-label="{{ 'screen-reader-only.sidebar.unreadNotifications' | translate }}"
                        ></span
                        ><span
                            *ngIf="navItem.showIndicator && navItem.key === 'sidebar.addOns'"
                            class="margin-left-1 sidebar-notification"
                        >
                            {{ 'new' | translate }}
                        </span>
                        <mat-icon
                            *ngIf="navItem.openInNewTab"
                            class="margin-left-1"
                            style="height: 16px; width: 16px"
                            svgIcon="openInNew"
                        ></mat-icon>
                    </a>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="!portalHasContent">
            <app-feature-request *ngIf="zenDeskService.isEnabled | async; else drawer" class="feature-request" />
            <ng-template #drawer>
                <div class="extras-drawer" [class.open]="isExtrasExpanded">
                    <div class="drawer-trigger" (click)="onToggleExtras()">
                        {{ 'sidebar.supportAndMore' | translate }}
                        <mat-icon svgIcon="chevronUp" *ngIf="!isExtrasExpanded"></mat-icon>
                        <mat-icon svgIcon="chevronDown" *ngIf="isExtrasExpanded"></mat-icon>
                    </div>

                    <div class="drawer-item">
                        <a href="{{ supportTicketUrl }}" target="_blank">
                            <span class="drawer-icon support-ticket">
                                <mat-icon svgIcon="wrench"></mat-icon>
                            </span>
                            {{ 'sidebar.supportTicket' | translate }}
                        </a>
                    </div>

                    <div class="drawer-item">
                        <a href="{{ helpCenterUrl }}" target="_blank">
                            <span class="drawer-icon help-center">
                                <mat-icon svgIcon="questionMark"></mat-icon>
                            </span>
                            {{ 'sidebar.helpCenter' | translate }}
                        </a>
                    </div>

                    <div class="drawer-item">
                        <app-feature-request />
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </ng-container>
    <button
        [attr.aria-label]="(isDesktopExpanded ? 'Collapse' : 'Expand') + ' ' + sideBarNavigationLabel"
        [attr.aria-expanded]="isDesktopExpanded"
        [@fadeInOut]="isCollapseButtonVisible || !isDesktopExpanded ? 'fadeIn' : 'fadeOut'"
        (click)="onToggleDesktopDrawer()"
        (focus)="isCollapseButtonVisible = true"
        (blur)="isCollapseButtonVisible = false"
        class="collapse-button"
        mat-fab
    >
        <mat-icon
            class="collapse-button-icon"
            [svgIcon]="isDesktopExpanded ? 'chevronLeft' : 'chevronRight'"
            [ngClass]="isDesktopExpanded ? 'left' : 'right'"
        ></mat-icon>
    </button>
</div>

<mat-menu style="margin-left: 160px" xPosition="after" #rolesMenu>
    <button
        mat-menu-item
        *ngFor="let tokenRole of currentCompanyTokenRoles"
        (click)="changeRole(tokenRole.roleId)"
        [disabled]="currentTokenRole.roleId === tokenRole.roleId"
    >
        {{ tokenRole.roleName }}
    </button>
</mat-menu>

<mat-menu style="margin-left: 160px" xPosition="after" #companiesMenu>
    <button
        mat-menu-item
        *ngFor="let tokenRole of uniqueCompanyTokenRoles"
        (click)="changeRole(tokenRole.roleId)"
        [disabled]="currentTokenRole.companyId === tokenRole.companyId"
    >
        {{ tokenRole.companyName }}
    </button>
</mat-menu>
