import { Platform } from '@app/classes/platform.class';
import { FeatureFlag } from '@app/enums';
import { MenuItemGroup, MenuItemNames } from '@app/interfaces';
import { CompanyDocument } from '@app/models/documents/company-document.model';
import { TrainingProgram } from '@app/models/training/training-program.model';
import { Company } from '@models/company/company.model';

export const AdminNavMenu: MenuItemGroup[] = [
    [
        {
            key: 'sidebar.home',
            link: ['/dashboard'],
            showWhen: ({ onboardingStatusService }) => !onboardingStatusService.hasActiveQuickstart(),
        },
        {
            key: 'sidebar.home',
            link: ['/company-onboarding'],
            showWhen: ({ onboardingStatusService }) => onboardingStatusService.hasActiveQuickstart(),
        },
        {
            key: 'sidebar.profile',
            link: ['/account'],
        },
    ],

    [
        {
            key: 'sidebar.people',
            link: ['/employees'],
            can: Platform.people.permission.view,
        },
        {
            key: 'sidebar.payroll',
            link: ['/payroll', 'self-onboarding'],
            can: Platform.payroll.permission.view,
            module: Platform.modules.payroll,
            unableTo: 'payroll',
            hideIfNoTenantId: true,
        },
        {
            key: 'sidebar.payroll',
            link: ['/payroll'],
            can: Platform.payroll.permission.view,
            module: Platform.modules.payroll,
            ableTo: 'payroll',
        },
        {
            key: 'sidebar.payroll',
            link: ['/company-onboarding-payroll-highlights'],
            showWhen: ({ authService }): boolean => {
                // we show payroll highlights for admins of companies that are
                // onboarding into payroll through self serve, but haven't yet synced payroll.
                const showPayrollHighlights =
                    authService.isAdmin() &&
                    authService.company.isSelfServeWithPayroll &&
                    !authService.company.isPayrollSyncEnabled &&
                    !authService.company.prTenantId;

                return showPayrollHighlights;
            },
        },
        {
            key: 'sidebar.timeTracking',
            link: ['/time-tracking'],
            module: Platform.modules.timeTracking,
            can: Platform.timeTracking.permission.view,
        },
        {
            key: 'sidebar.timeOff',
            link: ['/time-off', 'v3'],
            can: Platform.timeOff.permission.view,
            module: Platform.modules.timeOff,
        },
    ],
    [
        {
            key: 'sidebar.recruiting',
            link: ['/recruiting'],
            can: [Platform.recruiting.permission.access],
            module: Platform.modules.recruiting,
        },
        {
            key: 'sidebar.documents',
            link: ['/documents/center/folders'],
            can: CompanyDocument.permission.all,
            hideIfHasFeatureFlag: FeatureFlag.documentsV2,
        },
        {
            key: 'sidebar.documents',
            link: ['/documents-v2/center/folders'],
            can: CompanyDocument.permission.all,
            showIfHasFeatureFlag: FeatureFlag.documentsV2,
        },
        {
            key: 'sidebar.training',
            link: ['/training'],
            can: [TrainingProgram.managePermission],
        },
    ],

    // Group 3
    [
        {
            key: 'sidebar.surveys',
            link: ['/surveys/center'],
            can: Platform.performance.permission.view,
            module: Platform.modules.performance,
            hideIfHasFeatureFlag: FeatureFlag.performanceModuleSplit,
        },
        {
            key: 'sidebar.surveys',
            link: ['/surveys/center'],
            can: Platform.survey.permission.view,
            module: Platform.modules.survey,
            showIfHasFeatureFlag: FeatureFlag.performanceModuleSplit,
        },
        {
            key: 'sidebar.performance',
            link: ['/performance'],
            can: Platform.performance.permission.view,
            module: Platform.modules.performance,
        },
    ],

    [
        {
            key: 'sidebar.reporting',
            link: ['/reporting'],
            can: Platform.reporting.permission.view,
        },
        // Removed the need for admins to require `accessBenefits` permission. All Admins should be able to see the module regardless.
        {
            key: 'sidebar.benefits',
            link: ['/benefits'],
            module: Platform.modules.benefits,
            showIfCompanyHasSimplyBenefits: false,
        },
        {
            // link to Simply Benefits Admin Portal
            key: 'sidebar.benefits',
            link: ['/benefits/admin'],
            openInNewTab: true,
            can: Platform.benefits.permission.view,
            module: Platform.modules.benefits,
            showIfCompanyHasSimplyBenefits: true,
            // once simplyBenefits flag is deleted you can safely delete the following line and this comment. You're welcome
            showIfHasFeatureFlag: FeatureFlag.simplyBenefits,
        },
        {
            // this is an upsell page we only show to admins who do not have benefits
            key: 'sidebar.benefits',
            link: ['/benefits/info'],
            showIfHasFeatureFlag: FeatureFlag.benefitsUpsell,
            showWhenModuleDisabled: Platform.modules.benefits,
            name: MenuItemNames.benefitsUpsell,
        },
        {
            key: 'sidebar.company',
            link: ['/company'],
            can: Company.permission.some('view'),
        },
        {
            key: 'sidebar.settings',
            link: ['/settings'],
            can: Platform.settings.permission.view,
        },
    ],
    [
        {
            key: 'sidebar.addOns',
            link: ['/add-ons'],
            can: Platform.permission.administrate,
            name: MenuItemNames.inAppUpsell,
            showIfHasFeatureFlag: FeatureFlag.upsell,
        },
    ],
];
