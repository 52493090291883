import { T4 } from '@app/models/payroll/t4.model';
import { PillColumn } from '@app/modules/table/classes/pill-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { PillDisplay } from '@app/modules/table/interfaces/pill-display.interface';

export class DisplayPendingStateColumn extends PillColumn<T4> {
    title = '';
    type = CellDisplay.pill;
    displayWidth = 28;

    constructor() {
        super();
    }

    getPillDisplay(row: T4): PillDisplay | undefined {
        if (row.displayPendingState) {
            return { type: 'warning', label: 'table.t4Index.pending' };
        }
    }
}
