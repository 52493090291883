export enum PayrollResources {
    Setting = '/v2/payroll/companies/:company/settings',
    InitializeRoe = '/v2/payroll/employees/:employee/roes/initialize',
    NewRoe = '/v2/payroll/employees/:employee/roes/new',
    StoreRoe = '/v2/payroll/employees/:employee/roes',
    SubmitRoe = '/v2/payroll/companies/:company/roes/:roe/submit',
    Schedule = '/v2/payroll/companies/:company/schedule',
    LockPayrollHours = '/v2/payroll/companies/:company/payrolls/:payroll/lockHours',
    UnlockPayrollHours = '/v2/payroll/companies/:company/payrolls/:payroll/unlockHours',
    RefreshPayrollHours = '/v2/payroll/refreshEmployeeHours',
    HasTimeIntegrations = '/v2/payroll/hasTimeIntegrations',
    BulkAssignPayrollHours = '/v2/payroll/payrolls/:payroll/payrollHours/bulkAssign',
    RunPayroll = '/v2/payroll/companies/:company/payrolls/:payroll/run',
    RunPayrollAsync = '/v2/payroll/companies/:company/payrolls/:payroll/asyncRun',
    RunPayrollHoursAsync = '/v2/payroll/companies/:company/payrolls/:payroll/asyncRunHours',
    ResetPayroll = '/v2/payroll/companies/:company/payrolls/:payroll/reset',
    PayrollReviewTimeoff = '/v2/payroll/companies/:company/payrolls/:payroll/reviewTimeoff',
    PayrollCancelReview = '/v2/payroll/companies/:company/payrolls/:payroll/cancelReview',
    PayrollFinishTimeoff = '/v2/payroll/companies/:company/payrolls/:payroll/finishTimeoff',
    PayrollResetTimeoff = '/v2/payroll/companies/:company/payrolls/:payroll/resetTimeoff',
    PayPayroll = '/v2/payroll/companies/:company/payrolls/:payroll/pay',
    PayPayrollAsync = '/v2/payroll/companies/:company/payrolls/:payroll/asyncPay',
    Sync = '/v2/payroll/sync',
    SyncCompensation = '/v2/payroll/syncCompensations',
    SyncEmployee = '/v2/payroll/syncEmployees',
    Companies = '/v2/payroll/companies/:companyId',
    Tenant = '/v2/payroll/tenant',
    InsurableEarnings = '/v2/payroll/companies/:company/roes/:roe/insurableEarnings',
    T4EmployeeList = '/v2/payroll/companies/:company/t4s/employeeList',
    T4Download = '/v2/payroll/companies/:company/t4s/:t4/download',
    T4Email = '/v2/payroll/companies/:company/t4s/:t4/email',
    T4Revert = '/v2/payroll/companies/:company/t4s/:t4/revert',
    T4Preview = '/v2/payroll/companies/:company/t4s/:t4/preview',
    T4SummaryBulkEmail = '/v2/payroll/companies/:company/t4Summaries/:t4_summary/bulk_email',
    T4SummaryNotifications = '/v2/payroll/companies/:company/t4Summaries/:t4_summary/notifications',
    TaxFormSets = '/v2/payroll/companies/:company/taxFormSets',
    VacationYTD = '/v2/payroll/employees/:employee/timeOffYtds',
    JurisdictionYTD = '/v2/payroll/employees/:employee/payStubs/:payStubId',
    TimeOffIntegratedCompensationVacationPay = '/v2/payroll/employees/:employeeId/compensationCard',
    TimeOffIntegratedCompensationVacationPaySettings = '/v2/payroll/employees/:employeeId/settings',
    JournalEntryV2Reports = '/v2/payroll/journalEntryReports',
    UploadHoursImporter = '/v2/payroll/companies/:companyId/payrolls/:payrollId/uploadHours',
    UploadAdditionalIncomes = '/v2/payroll/companies/:companyId/payrolls/:payrollId/uploadAdditionalIncomes',
    BenefitCsvImporter = '/v2/payroll/companies/:companyId/benefits/:benefitId/uploadCsv',
    DeductionCsvImporter = '/v2/payroll/companies/:companyId/deductions/:deductionId/uploadCsv',
    LatestVacationYtdAdjustment = '/v2/payroll/employees/:employee/adjustments/latestYtdBalance',
    MakeVacationYtdAdjustment = '/v2/payroll/employees/:employee/adjustments',
    TimeOffVacationPayout = '/v2/payroll/employees/:employee/paystubs/:paystub/scheduledVacationPayouts',
    UpdateTimeOffVacationPayout = '/v2/payroll/employees/:employee/paystubs/:paystub/scheduledVacationPayouts/:scheduledVacationPayout',
    SelfOnboardingCompanies = '/v2/payroll/selfOnboarding/companies/:companyId',
    SelfOnboardingDocumentUpload = '/v2/payroll/companies/selfOnboarding/documents',
    SelfOnboardingDocumentDownload = 'selfOnboarding/documents/downloadDocumentTemplate',
    SelfOnboardingDocumentDownloadSignedForm = 'selfOnboarding/documents/:documentId/download',
    SelfOnboardingStatus = '/v2/payroll/selfOnboarding/status',
    LastRecurringPayrollEndDate = '/v2/payroll/companies/:company/payrolls/end_date_of_last_paid_recurring',
    TaxAdjusterTable = '/v2/payroll/yearEndAdjustments/table',
    UpdatedTaxTableRow = '/v2/payroll/yearEndAdjustments/row',
    TaxAdjusterTableEditable = '/v2/payroll/yearEndAdjustments/tableEditable',
    DeductionItemsSave = '/v2/payroll/companies/:company/payrolls/deductionItems',
    PayabaleItemsSave = '/v2/payroll/payableItems',
    AdditionalPayItemsSave = '/v2/payroll/companies/:company/payrolls/additionalPayItems',
    CompanyContirbutionBenefitItemsSave = '/v2/payroll/employees/:employee/employeeBenefits/:employeeBenefitId/benefitItems',
    EmployeeBenefitDeductionSave = '/v2/payroll/employees/:employee/employeeBenefits/:employeeBenefitId/deductionItems',
    EmployeeDeductionDeductionSave = '/v2/payroll/employees/:employee/employeeDeductions/:employeeDeductionId/deductionItems',
    BulkAdditionalPayItemsCheckSubtractSalary = '/v2/payroll/payrolls/:payrollId/payStubs/:paystubId/bulkAdditionalPayItems/checkSubtractSalary',
    BulkAdditionalPayItemsUpsert = '/v2/payroll/payrolls/:payrollId/payStubs/:paystubId/bulkAdditionalPayItems/upsert',
    TimeWorkedImportStatus = '/v2/payroll/companies/:company_id/payrolls/:payroll_id/time_worked_import',
    WcbBackfillStatus = '/v2/payroll/payrolls/:payroll_id/wcbBackfillStatus',
    AttachDetachWorkersCompensationEmployees = '/v2/payroll/companies/:company/workersCompensations/:workersCompensation/attachDetachEmployees',
    WorkersCompensationPerLineItemReport = '/v2/payroll/companies/:company/workersCompensations/perLineItemReport',
    EmployeeOnLeaveOrTerminated = '/v2/payroll/employees/:employee/onLeaveOrTerminated',
    GenericT4Box = '/v2/payroll/genericT4Box',
    EPTSummariesByJurisdiction = '/v2/payroll/companies/:company/ept_summaries/jurisdictions',
    YtdColumnSettings = '/v2/payroll/companies/:company/ytdColumns',
    YtdHeader = '/v2/payroll/employees/ytds/headers',
    YtdTable = '/v2/payroll/employees/ytds/table',
}
