import { AutoColumnSize } from "./autoColumnSize/index.mjs";
import { Autofill } from "./autofill/index.mjs";
import { AutoRowSize } from "./autoRowSize/index.mjs";
import { BasePlugin } from "./base/index.mjs";
import { BindRowsWithHeaders } from "./bindRowsWithHeaders/index.mjs";
import { CollapsibleColumns } from "./collapsibleColumns/index.mjs";
import { ColumnSorting } from "./columnSorting/index.mjs";
import { ColumnSummary } from "./columnSummary/index.mjs";
import { Comments } from "./comments/index.mjs";
import { ContextMenu } from "./contextMenu/index.mjs";
import { CopyPaste } from "./copyPaste/index.mjs";
import { CustomBorders } from "./customBorders/index.mjs";
import { DragToScroll } from "./dragToScroll/index.mjs";
import { DropdownMenu } from "./dropdownMenu/index.mjs";
import { ExportFile } from "./exportFile/index.mjs";
import { Filters } from "./filters/index.mjs";
import { Formulas } from "./formulas/index.mjs";
import { HiddenColumns } from "./hiddenColumns/index.mjs";
import { HiddenRows } from "./hiddenRows/index.mjs";
import { ManualColumnFreeze } from "./manualColumnFreeze/index.mjs";
import { ManualColumnMove } from "./manualColumnMove/index.mjs";
import { ManualColumnResize } from "./manualColumnResize/index.mjs";
import { ManualRowMove } from "./manualRowMove/index.mjs";
import { ManualRowResize } from "./manualRowResize/index.mjs";
import { MergeCells } from "./mergeCells/index.mjs";
import { MultiColumnSorting } from "./multiColumnSorting/index.mjs";
import { MultipleSelectionHandles } from "./multipleSelectionHandles/index.mjs";
import { NestedHeaders } from "./nestedHeaders/index.mjs";
import { NestedRows } from "./nestedRows/index.mjs";
import { PersistentState } from "./persistentState/index.mjs";
import { Search } from "./search/index.mjs";
import { TouchScroll } from "./touchScroll/index.mjs";
import { TrimRows } from "./trimRows/index.mjs";
import { UndoRedo } from "./undoRedo/index.mjs";
import { registerPlugin } from "./registry.mjs";
/**
 * Registers all available plugins.
 */
export function registerAllPlugins() {
  registerPlugin(AutoColumnSize);
  registerPlugin(Autofill);
  registerPlugin(AutoRowSize);
  registerPlugin(BindRowsWithHeaders);
  registerPlugin(CollapsibleColumns);
  registerPlugin(ColumnSorting);
  registerPlugin(ColumnSummary);
  registerPlugin(Comments);
  registerPlugin(ContextMenu);
  registerPlugin(CopyPaste);
  registerPlugin(CustomBorders);
  registerPlugin(DragToScroll);
  registerPlugin(DropdownMenu);
  registerPlugin(ExportFile);
  registerPlugin(Filters);
  registerPlugin(Formulas);
  registerPlugin(HiddenColumns);
  registerPlugin(HiddenRows);
  registerPlugin(ManualColumnFreeze);
  registerPlugin(ManualColumnMove);
  registerPlugin(ManualColumnResize);
  registerPlugin(ManualRowMove);
  registerPlugin(ManualRowResize);
  registerPlugin(MergeCells);
  registerPlugin(MultiColumnSorting);
  registerPlugin(MultipleSelectionHandles);
  registerPlugin(NestedHeaders);
  registerPlugin(NestedRows);
  registerPlugin(PersistentState);
  registerPlugin(Search);
  registerPlugin(TouchScroll);
  registerPlugin(TrimRows);
  registerPlugin(UndoRedo);
}
export { AutoColumnSize, Autofill, AutoRowSize, BasePlugin, BindRowsWithHeaders, CollapsibleColumns, ColumnSorting, ColumnSummary, Comments, ContextMenu, CopyPaste, CustomBorders, DragToScroll, DropdownMenu, ExportFile, Filters, Formulas, HiddenColumns, HiddenRows, ManualColumnFreeze, ManualColumnMove, ManualColumnResize, ManualRowMove, ManualRowResize, MergeCells, MultiColumnSorting, MultipleSelectionHandles, NestedHeaders, NestedRows, PersistentState, Search, TouchScroll, TrimRows, UndoRedo };
export { getPlugin, getPluginsNames, registerPlugin } from "./registry.mjs";