import { T4 } from '@app/models/payroll/t4.model';
import { PillColumn } from '@app/modules/table/classes/pill-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { PillDisplay } from '@app/modules/table/interfaces/pill-display.interface';

export class EmployeeColumn extends PillColumn<T4> {
    title = 'table.t4Index.name';
    property = 'employee';
    sortField = 'lastName';
    sortable = true;
    type: CellDisplay = CellDisplay.avatar;
    displayWidth = 33;

    getPillDisplay(t4: T4): PillDisplay | undefined {
        if (!t4.terminated) {
            return;
        }

        return {
            type: 'danger',
            label: 'table.t4Index.terminated',
        };
    }

    shouldShowPill(t4: T4): boolean {
        return t4.terminated;
    }
}
