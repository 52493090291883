import { Model } from '../core/base.model';

export class BenefitCategory extends Model {
    protected static _resource = 'benefitManagement/benefitCategories';

    protected static _version = 'v2';

    get name(): string {
        return this._attributes['name'];
    }
}
